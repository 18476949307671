import React, { Component } from "react";
import _ from "lodash";
import { BsFillTrash3Fill } from "react-icons/bs";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import InputSteppers from "components/InputSteppers";

import defaultImage from "assets/images/iqi_logo_only.png";
import isEmptyValue from "utils/isEmpty";

class Content extends Component {
  onClickDeleteActions = (param) => this.props.deleteClick(param);

  renderCardContent = () => {
    const { rowItem } = this.props;
    const { name, price = 0 } = rowItem;
    const data = !isEmptyValue(rowItem.data) ? rowItem.data : {};

    const market_product = !isEmptyValue(rowItem.market_product)
      ? rowItem.market_product
      : {
          type_id: "",
          photo_url: "",
          unit_measurement: "unit",
          attached_product: "",
        };

    const ticket_title = !isEmptyValue(data.ticket_title)
      ? ` (${data.ticket_title})`
      : "";

    return (
      <div className="at-mycart-card_table-item_cont grid_gap-2 d-flex">
        <img
          className="at-mycart-card_table-img"
          src={market_product.photo_url || defaultImage}
        />
        <div className={"w-100"}>
          <h4 className="fw-600 mb-1">{`${name}${ticket_title}`}</h4>
          <p className="mb-1">
            1 {market_product.unit_measurement}{" "}
            {rowItem.isProductVariant ? rowItem.title : ""}
          </p>
          <p style={{ color: "#C2410C" }}>RM {price}</p>
          <div className="d-flex align-items-end justify-content-between mt-2">
            <div className={"add-to-cart-action mt-0"}>
              {!rowItem.is_quantity_fixed && this.props.can_update && (
                <InputSteppers
                  itemId={rowItem.id}
                  containerStyle={{
                    borderRadius: 8,
                    width: 120,
                    border: "1px solid #6B7280",
                  }}
                  inputStyle={{ fontSize: 12 }}
                  value={rowItem.quantity}
                  buttonClassName={"btn-new at-product__quantity-btn"}
                  negativeButtonContent={
                    <AtlasIcon
                      svgHref={"atlas-minus-linear"}
                      style={{ fill: "#6B7280" }}
                    />
                  }
                  positiveButtonContent={
                    <AtlasIcon
                      svgHref={"atlas-add-linear"}
                      style={{ fill: "#F0631D" }}
                    />
                  }
                  onChangeInputValue={this.props.onChangeInputStepperValue}
                  onClickPositiveButton={this.props.onChangeInputStepperValue}
                  onClickNegativeButton={this.props.onChangeInputStepperValue}
                />
              )}
            </div>
            <CustomButton
              disabled={this.props.disabledDelete}
              className={"btn-new"}
              children={
                <span className="at-mycart-card_table-delete ">
                  <BsFillTrash3Fill />
                  Remove
                </span>
              }
              tooltip={true}
              tooltipChildren={<span>Remove</span>}
              tooltipPosition={"bottom"}
              onClick={() => this.onClickDeleteActions(rowItem)}
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => this.renderCardContent();
}

export default Content;
