import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import CustomCheckbox from "components/Checkbox";
import EditContent from "./components/editModal";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import ConfirmationModal from "components/Modal/confirmation";
import GenerateConfirmationForm from "components/generateForm";
import AtPaginatedTable from "components/NewPaginatedTable";
import ModalDialog from "components/Modal/ModalDialog";

import ConfirmationFormsHOC from "./actions";
import FormHOC from "../actions";
import VersionCheckHOC from "actions/versionCheck";
import { types as subsalesType } from "dictionary/subsales";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";
import "./index.scss";

const ColumnData = [
  {
    header: "Form Number",
    columnStyle: { width: "80%" },
    accessor: "transaction_number",
  },

  {
    header: "Agent",
    renderColumn: ({ agent }) => (
      <>
        <p>{agent.full_name || "N/A"}</p>
        <p>{agent.mobile_contact_number || "N/A"}</p>
        <p>{agent.email || "N/A"}</p>
        <p>{agent.team_name || "N/A"}</p>
      </>
    ),
  },
  { header: "Confirmation Date", accessor: "confirmation_date" },
  {
    header: "Property Address",
    accessor: "property_address",
    columnStyle: { width: "150%" },
  },
  {
    header: "Submitted",
    renderColumn: ({ is_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_claimed ? "Green" : "Red") }}
      >
        {is_claimed ? "Yes" : "No"}
      </div>
    ),
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
  },
  {
    header: "Is Owner Claimed",
    renderColumn: ({ is_owner_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_owner_claimed ? "Green" : "Red") }}
      >
        {is_owner_claimed ? "Yes" : "No"}
      </div>
    ),
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "70%" },
  },
  {
    header: "Is Buyer Claimed",
    renderColumn: ({ is_buyer_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_buyer_claimed ? "Green" : "Red") }}
      >
        {is_buyer_claimed ? "Yes" : "No"}
      </div>
    ),
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "70%" },
  },
];

const typeOptions = [
  { label: "Sales", value: "1" },
  { label: "Rental", value: "2" },
];

class ConfirmationFormManagement extends Component {
  componentDidMount = () => {
    this.props.getAllDictionaryData();
    this.props.getInvoicingBranches();
    this.props.getConfirmationForms(1, "");

    const id = this.props.history?.location?.state?.id;

    if (id) {
      this.props.getConfirmationForm(id);
    }
    initGA("/dashboard/admin/forms/confirmation-forms-management");
  };

  onClickUnlock = (val) => {
    this.props.onChangeConfirmationFormHOC("selectedConfirmationForm", val);
    this.props.onChangeConfirmationFormHOC("showConfirmToggleStatus", true);
  };

  onClickDetails = (val) => this.props.getConfirmationForm(val.id);

  renderEditModal = () => {
    const { selectedConfirmationForm, data, showEditModal } = this.props;
    const { can_update } = permissionsChecker("Forms", data);
    let tmpDisabled = !can_update;
  
    const { id, job_title_name } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    const tempRolePermission =
      job_title_name === "Super Admin" ||
      (job_title_name === "Admin" && id === selectedConfirmationForm.user_id);
    return (
      <AtlasDialog withFloatPanel={true} open={showEditModal}>
        <AtlasCard
          containerStyle={{ background: "#F3F4F6" }}
          className="h-100 at-form__mngmt-card-padding"
          cardContent={<EditContent closedEditing={tmpDisabled && tempRolePermission} {...this.props} />}
        />
      </AtlasDialog>
    );
  };

  renderUnlockConfirmationModal = () => {
    return (
      <ConfirmationModal
        open={this.props.showConfirmToggleStatus}
        title={"Release the form"}
        loading={this.props.onLoadConfirmationForms}
        message={"Are you sure you want to release the form ?"}
        positiveAction={() =>
          this.props.unlockConfirmationForm(
            this.props.selectedConfirmationForm.id,
          )
        }
        negativeAction={() =>
          this.props.onChangeConfirmationFormHOC(
            "showConfirmToggleStatus",
            false,
          )
        }
        positiveText={"Yes"}
        negativeText={"No"}
      />
    );
  };

  renderResetDownloadCount = () => {
    const {
      resetContent,
      showResetConfirmation,
      onLoadConfirmationForms,
      selectedConfirmationForm,

      resetDownloadCount,
      onChangeConfirmationFormHOC,
    } = this.props;

    const onChangeCheckbox = (val, context) => {
      let temp = _.cloneDeep(resetContent);
      temp[context] = val;
      onChangeConfirmationFormHOC("resetContent", temp);
    };

    return (
      <ModalDialog
        title={"Reset Download Count"}
        onLoad={onLoadConfirmationForms}
        responsiveSize="sm"
        maxWidth="sm"
        onClose={() => onChangeConfirmationFormHOC("showResetConfirmation", false)}
        children={
          <>
            <p className="fs-2 mb-3">Which download count do you want to reset ?</p>
            <div className="d-flex flex-column g-1">
              <CustomCheckbox
                content={"Confirmation Form"}
                checked={resetContent.confirmation_form}
                labelClassname="mt-10"
                onChangeCheckboxValue={(e) =>
                  onChangeCheckbox(
                    !resetContent.confirmation_form,
                    "confirmation_form",
                  )
                }
              />
              <CustomCheckbox
                content={"Temp. Receipt"}
                checked={resetContent.temp_receipt}
                labelClassname="mt-10"
                onChangeCheckboxValue={(e) =>
                  onChangeCheckbox(!resetContent.temp_receipt, "temp_receipt")
                }
              />
            </div>
          </>
        }
        footer={
          <div className="d-flex align-items-center g-3">
            <button
              className="btn-new btn-new--primary"
              disabled={_.values(resetContent).every((x) => !x)}
              onClick={() => resetDownloadCount(selectedConfirmationForm.id)}>
              Reset Now
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangeConfirmationFormHOC("showResetConfirmation", false)}>
              Cancel
            </button>
          </div>
        } />
    );
  };

  selectSubsaleType = (params) => {
    let tempType = _.find(subsalesType, { name: params });

    this.props.onChangeCreateConfirmationHOC(
      "type_id",
      tempType ? tempType.id : "",
    );
    this.props.onChangeCreateConfirmationHOC("type", params);
  };

  generateConfirmationForm = (dataToSubmit) => {
    this.props.onChangeConfirmationFormHOC("disableSubmitButton", true);
    this.props.createConfirmationForm(dataToSubmit);
  };

  renderGenerateForm = () => {
    const {
      agentList,
      getAgentsBySearch,
      onChangeConfirmationFormHOC,
    } = this.props;

    return (
      <GenerateConfirmationForm
        disableSubmitButton={this.props.disableSubmitButton}
        title={"New Confirmation Form"}
        invoicingBranch={this.props.data.branchReducer.invoicingBranch}
        submitButtonText={"Create Now"}
        onLoad={this.props.onLoadConfirmationForms}
        agentList={agentList}
        typeOptions={typeOptions}
        getAgentsBySearch={getAgentsBySearch}
        loading={this.props.onLoadConfirmationForms}
        onSelectBranch={(val) =>
          onChangeConfirmationFormHOC(val.id, "createInvoiceId")
        }
        generateForm={this.generateConfirmationForm}
        onChangeConfirmationFormHOC={onChangeConfirmationFormHOC}
        onClickCloseFormGenerator={() =>
          this.props.onChangeConfirmationFormHOC("showCreationForm", false)
        }
      />
    );
  };

  renderCloneConfirmationModal = () => {
    const {
      cloneConfirmationForm,
      selectedCloneId,
      onChangeConfirmationFormHOC,
      showCloneConfirmationModal,
    } = this.props;
    return (
      <ConfirmationModal
        open={showCloneConfirmationModal}
        loading={this.props.onLoadConfirmationForms}
        message={
          "Are you sure you want to clone this form data into a new form ?"
        }
        positiveAction={() => cloneConfirmationForm(selectedCloneId)}
        negativeAction={() =>
          onChangeConfirmationFormHOC("showCloneConfirmationModal", false)
        }
        positiveText={"Yes"}
        negativeText={"No"}
      />
    );
  };

  renderCardContent = () => {
    const { onLoadConfirmationForms, showDownloadConfirmation } = this.props;

    const { can_update, can_create } = permissionsChecker(
      "Forms",
      this.props.data,
    );
    const { job_title_name } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Confirmation Forms"}
          atlasIcon={"atlas-confirmation-form"}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 8 }} />
              ),
              onClick: () =>
                this.props.onChangeConfirmationFormHOC(
                  "showCreationForm",
                  true,
                ),
            },
          ]}
        />
        <AtlasCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={this.props.confirmationForms.data}
              meta={this.props.confirmationForms.meta}
              headerClass={"at-form_mngmt__table-header"}
              columns={ColumnData}
              actionColumnContent={[
                {
                  name: "details",
                  iconClass: "at-table-new-icon-button ",
                  iconStyle: { width: 18, height: 18, fill: "#007bff" },
                  onClick: (rowData) => this.onClickDetails(rowData),
                },
                ...(job_title_name === "Super Admin" ||
                (job_title_name === "Admin" && can_create)
                  ? [
                      {
                        name: "duplicate",
                        iconClass: "at-table-new-icon-button ",
                        iconStyle: { width: 18, height: 18, fill: "#6B7280" },
                        onClick: (rowData) =>
                          Promise.all([
                            this.props.onChangeConfirmationFormHOC(
                              "selectedCloneId",
                              rowData.id,
                            ),
                          ]).then(() => {
                            this.props.onChangeConfirmationFormHOC(
                              "showCloneConfirmationModal",
                              true,
                            );
                          }),
                      },
                    ]
                  : []),
              ]}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeConfirmationFormHOC("searchParams", val)
              }
              getListAPI={this.props.getConfirmationForms}
              totalPages={this.props.totalPages}
            />
          }
        />
        {this.renderUnlockConfirmationModal()}
        {this.renderEditModal()}
        {this.renderCloneConfirmationModal()}
        {this.props.showResetConfirmation && this.renderResetDownloadCount()}
        {this.props.showCreationForm && (
          <ModalDialog
            title={"New Confirmation Form"}
            children={this.renderGenerateForm()}
            onClose={() =>
              this.props.onChangeConfirmationFormHOC("showCreationForm", false)
            }
          />
        )}
        {onLoadConfirmationForms && <LoadingModal />}
      </>
    );
  };

  render = () => this.renderCardContent();
}

export default compose(
  ConfirmationFormsHOC,
  FormHOC,
  VersionCheckHOC,
)(ConfirmationFormManagement);
