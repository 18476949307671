import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Delete } from "utils/axios";
import { bedroom_options, currency } from "dictionary/subsales";
import {
  categories as CategoriesDictionary,
  types,
  categories,
  statuses,
  measurements,
  directions,
  occupancies,
  furnishing_statuses,
  tenure_types,
  title_types,
  unit_types,
} from "dictionary/subsales";

const SearchParams = (countries, currencies, groupType) => [
  {
    label: "Code",
    value: "estate_of_Listing_type_code_cont",
    type: "input",
    param: "",
    col: 12,
  },
  {
    label: "Property Name",
    value: "estate_of_Listing_type_property_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Type",
    value: "estate_of_Listing_type_property_type_name_cont",
    type: "typeahead",
    param: "",
    options: groupType,
  },
  {
    label: "Country",
    value: "estate_of_Listing_type_country_id_eq",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "estate_of_Listing_type_state_id_eq",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "Currency",
    value: "currency",
    type: "select",
    withoutPrefix: true,
    param: "",
    options: currencies.name,
    col: 12,
  },
  {
    label: "Listing Price Greater Than",
    value: "min_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Listing Price Less Than",
    value: "max_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Bedroom",
    value: "estate_of_Listing_type_bedrooms_eq",
    type: "select",
    param: "",
    options: bedroom_options,
  },
  {
    label: "Measurement",
    value: "estate_of_Listing_type_measurement_id",
    type: "select",
    param: "",
    options: [
      { value: 1, label: "Squarefeet" },
      { value: 2, label: "Squaremeter" },
      { value: 3, label: "Acres" },
      { value: 4, label: "Hectare" },
    ],
  },
  {
    label: "Built up size Greater than",
    value: "estate_of_Listing_type_built_up_gteq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Less than",
    value: "estate_of_Listing_type_built_up_lteq",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showListingEditModal: false,
      showListingRemoveConfirmation: false,

      listings: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedListing: null,
      listingPages: [0],

      estateListingIndexSearch: {
        currentPage: 1,
        searchParams: "",
      },
      estatetListingIndexSearchParams: [],
    };

    componentDidMount = () => {
      const { countries, currencies } = this.props.data.dictionaryReducer;
      let tempGroup = [];

      CategoriesDictionary.map(({ group_types }) => {
        group_types.map((item) => {
          tempGroup.push(...item.children);
        });
      });
      this.setState({
        estatetListingIndexSearchParams: SearchParams(countries, currencies, tempGroup),
      });
    };

    load = (param) => this.setState({ loading: param });

    onChangeListingHOC = (val, context) => this.setState({ [context]: val });

    getListings = (presentationId, page, search) => {
      this.setState(
        {
          estateListingIndexSearch: {
            currentPage: page,
            searchParams: search,
          },
        },
        () => {
          const { role } =
            window.location.href.indexOf("/admin-impersonate") > -1
              ? this.props.data.currentSignInProfileReducer
              : this.props.data.profileReducer;

          return Get(
            `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates?q[estate_type_eq]=Listing&${search}page=${page} `,
            this.getListingsSuccess,
            this.getListingsError,
            this.load
          );
        }
      );
    };
    getListingsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpType = _.find(types, { id: item.estate.type_id });
          let tmpCategory = _.find(categories, { id: item.estate.category_id });
          let tempStatus = _.find(statuses, { id: item.estate.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.estate.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempTitleType = _.find(title_types, {
            id: item.estate.title_type_id,
          });
          let tempDirection = _.find(directions, {
            id: item.estate.direction_id,
          });
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.estate.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.estate.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, {
            id: item.estate.occupancy_id,
          });
          let tempTenure = _.find(tenure_types, { id: item.estate.tenure_id });
          let tempUnitType = _.find(unit_types, {
            id: item.estate.unit_type_id,
          });

          let featureArray = [
            {
              label: "Direction",
              value:
                tempDirection &&
                tempDirection.name &&
                tempDirection.name.toLowerCase() !== "unknown"
                  ? tempDirection.name
                  : "N/A",
            },
            {
              label: "Occupancy",
              value:
                tempOccupancy &&
                tempOccupancy.name &&
                tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure &&
                tempTenure.name &&
                tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
            {
              label: "Unit Type",
              value:
                tempUnitType &&
                tempUnitType.name &&
                tempUnitType.name.toLowerCase() !== "unknown"
                  ? tempUnitType.name
                  : "N/A",
            },
            {
              label: "Title Type",
              value:
                tempTitleType &&
                tempTitleType.name &&
                tempTitleType.name.toLowerCase() !== "unknown"
                  ? tempTitleType.name
                  : "N/A",
            },
          ];

          let data = {
            ...item.estate,
            estate: true,
            estate_id: item.estate_id,
            featureArray: featureArray,
            type: tmpType && tmpType.name ? tmpType.name : "N/A",
            property:
              tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
                ? tmpCatGroupPropertyType.name
                : "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          };
          tempData.push(data);
        });
      payload.data = tempData;
      this.setState({
        listings: payload,
        listingPages: tmpTotalPages,
      });
    };
    getListingsError = (error) => requestError(error);

    removeListing = (presentationId, estateId, callback) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Delete(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates/remove?type_id=1&estate_id=${estateId}`,
        () => this.removeListingSuccess(presentationId, callback),
        this.removeListingError,
        this.load
      );
    };
    removeListingSuccess = (presentationId, callback) => {
      const {
        currentPage: presentationPage,
        searchParams: presentationSearch,
      } = this.props.data.lastViewReducer.lastView;
      const {
        currentPage: estateListingPage,
        searchParams: estateListingSearchParams,
      } = this.state.estateListingIndexSearch;

      callback && callback();
      requestSuccess("Listing removed successfully.");
      this.props.getSelectedPresentation(presentationId);
      this.props.getPresentation(presentationPage, presentationSearch);
      this.getListings(
        presentationId,
        estateListingPage,
        estateListingSearchParams
      );
      this.setState({ showListingRemoveConfirmation: false });
    };
    removeListingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            listings={this.state.listings}
            listingPages={this.state.listingPages}
            onLoadListing={this.state.loading}
            showListingEditModal={this.state.showListingEditModal}
            selectedListing={this.state.selectedListing}
            estateListingIndexSearch={this.state.estateListingIndexSearch}
            estatetListingIndexSearchParams={
              this.state.estatetListingIndexSearchParams
            }
            showListingRemoveConfirmation={
              this.state.showListingRemoveConfirmation
            }
            onChangeListingHOC={this.onChangeListingHOC}
            getListings={this.getListings}
            removeListing={this.removeListing}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
