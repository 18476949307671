import React, { Component, createRef } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import DeleteConfirmationModal from "components/Modal/delete";

import LeadsUpdateModal from "./components/edit";
import CreateLeadsModal from "./components/create";
import ImportContent from "./components/importContent";
import FacebookLeads from "./components/facebookLeads";
import LeadsQuickUpdateModal from "./components/quickUpdate";
import LeadsBulkUpdateModal from "./components/bulkUpdate";
import AssignDialog from "./components/AssignDialog";
import ExportModal from "./components/exportModal";

import LeadsHOC from "./actions";
import LeadsDelete from "./actions/delete";
import LeadsExportHOC from "./actions/export";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

import {
  SuperAdminHeaderData,
  AdminHeaderData,
  AgentHeaderData,
} from "./assets";
import { initGA } from "utils/ga";
import "./index.scss";
import ModalDialog from "components/Modal/ModalDialog";

class Leads extends Component {
  componentDidMount = () => {
    const { role, id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { superAdminSearchParams, adminSearchParams } = this.props;
    this.props.getLeads(1, "q[status_id_in][]=1&");

    if (role === "Super Admin") {
      this.props.onChangeLeadsHOC(superAdminSearchParams(id), "searchParams");
    } else if (role === "Admin") {
      this.props.onChangeLeadsHOC(adminSearchParams(id), "searchParams");
    }

    if (role === "Super Admin" && role === "Admin") {
      initGA("/dashboard/admin/lead-management");
    } else {
      initGA("/dashboard/personal/lead-management");
    }

    this.cardRef = createRef();
  };

  onHandleCheckBox = (id) => {
    let tempIndex = this.props.checkedArray.indexOf(id);
    let temp = _.cloneDeep(this.props.checkedArray);

    tempIndex === -1 ? temp.push(id) : temp.splice(tempIndex, 1);

    this.props.onChangeLeadsHOC(temp, "checkedArray");
  };

  onHandleCheckAllBox = () => {
    let temp = [];
    const { leads, checkedArray } = this.props;

    if (checkedArray.length < leads.data.length) {
      leads.data.map((item) => {
        temp.push(item.id);
      });
    }

    return this.props.onChangeLeadsHOC(temp, "checkedArray");
  };

  onClickStatusTab = (status_id, page) => {
    let search = "";
    let temp = _.cloneDeep(this.props.searchParams);
    let tempIndex = temp.findIndex((item) => item.value === "status_id_eq");
    if (status_id === 1) {
      temp[tempIndex].param = "-";

      temp.map((item) => {
        search =
          item.value !== "status_id_eq" && item.param && item.param !== ""
            ? search + `q[${item.value}]=${item.param}&`
            : search;
      });
      search = search + "q[status_id_in][]=1&";
    } else if (status_id === 2) {
      temp[tempIndex].param = "-";
      temp.map((item) => {
        search =
          item.value !== "status_id_eq" && item.param && item.param !== ""
            ? search + `q[${item.value}]=${item.param}&`
            : search;
      });
      search =
        search +
        "q[status_id_not_in][]=1&q[status_id_not_in][]=3&q[status_id_not_in][]=4&q[status_id_not_in][]=7&q[status_id_not_in][]=9&";
    } else {
      temp[tempIndex].param = "-";
      temp.map((item) => {
        search =
          item.value !== "status_id_eq" && item.param && item.param !== ""
            ? search + `q[${item.value}]=${item.param}&`
            : search;
      });
      search =
        search +
        "q[status_id_in][]=3&q[status_id_in][]=4&q[status_id_in][]=7&q[status_id_in][]=9&";
    }
    this.props.onChangeLeadsHOC(temp, "searchParams");
    this.props.onChangeLeadsHOC(status_id, "selectedTab");
    this.props.getLeads(page, search);
  };

  renderCheckBoxActions = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { checkedArray, leads, selectedTab } = this.props;
    const { can_update, can_destroy } = permissionsChecker(
      "Leads",
      this.props.data,
    );
    const stickToTop =
      this.cardRef?.current?.getBoundingClientRect()?.top < -200 &&
      window.innerWidth < 768;

    return (
      <div className="d-block w-100">
        <div className="at-lead-table__status-tab">
          <CustomButton
            children={"New"}
            className={
              selectedTab === 1 ? "at-lead-table__status-selected" : ""
            }
            onClick={() => this.onClickStatusTab(1, 1)}
          />
          <CustomButton
            children={"In Progress"}
            className={
              selectedTab === 2 ? "at-lead-table__status-selected" : ""
            }
            onClick={() => this.onClickStatusTab(2, 1)}
          />
          <CustomButton
            children={"Completed"}
            className={
              selectedTab === 3 ? "at-lead-table__status-selected" : ""
            }
            onClick={() => this.onClickStatusTab(3, 1)}
          />
        </div>
        <div
          className={
            stickToTop
              ? "at-table--checkbox-actions-sticky"
              : "at-table--checkbox-actions mt-3 mt-lg-0"
          }
        >
          <div
            style={{ padding: 2 }}
            className="at-mobile-view__controller mb-1 mr-1"
          >
            <CustomCheckbox
              contentStyle={{ fontWeight: 500 }}
              labelClassname={"at-table-tab-checkbox_responsive"}
              checkedArray={checkedArray}
              content={
                checkedArray.length > 0 &&
                checkedArray.length === leads.data.length
                  ? "Deselect All"
                  : "Select All"
              }
              checked={
                checkedArray.length > 0 &&
                checkedArray.length === leads.data.length
              }
              onChangeCheckboxValue={() => this.onHandleCheckAllBox()}
            />
          </div>
          <div className="d-flex flex-wrap align-items-center mr-2">
            {["Admin", "Super Admin"].indexOf(role) > -1 && can_update && (
              <button
                disabled={checkedArray.length < 1}
                className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
                style={{ fontSize: 14, padding: "5px 10px" }}
                onClick={() => {
                  this.props.onChangeLeadsHOC(true, "showAssignDialog");
                  this.props.onChangeLeadsHOC("admin", "selectedAssignType");
                }}
              >
                Assign Admin
              </button>
            )}
            {can_update && (
              <>
                <button
                  disabled={checkedArray.length < 1}
                  className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
                  style={{ fontSize: 14, padding: "5px 10px" }}
                  onClick={() => {
                    this.props.onChangeLeadsHOC(true, "showAssignDialog");
                    this.props.onChangeLeadsHOC("agent", "selectedAssignType");
                  }}
                >
                  Assign Agent
                </button>
                <button
                  disabled={checkedArray.length < 1}
                  className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
                  style={{ fontSize: 14, padding: "5px 10px" }}
                  onClick={() =>
                    this.props.onChangeLeadsHOC(true, "showBulkUpdateModal")
                  }
                >
                  Update Status
                </button>
              </>
            )}
            {["Admin", "Super Admin"].indexOf(role) > -1 && can_destroy && (
              <button
                disabled={checkedArray.length < 1}
                className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
                style={{ fontSize: 14, padding: "5px 10px" }}
                onClick={() =>
                  this.props.onChangeLeadsHOC(true, "showBulkDeleteModal")
                }
              >
                Delete Lead
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderCardContent = () => {
    const { leads, checkedArray } = this.props;
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { can_update, can_destroy } = permissionsChecker(
      "Leads",
      this.props.data,
    );

    let ColumnData = AgentHeaderData;

    if (role === "Admin") {
      ColumnData = AdminHeaderData;
    }
    if (role === "Super Admin") {
      ColumnData = SuperAdminHeaderData;
    }

    return (
      <AtPaginatedTable
        columns={ColumnData({
          leadData: leads.data || [],
          checkedArray,
          can_update: can_update,
          onHandleCheckBox: this.onHandleCheckBox,
          onHandleCheckAllBox: this.onHandleCheckAllBox,
        })}
        rowData={leads.data ? leads.data : []}
        meta={leads.meta && leads.meta}
        onChangeSearchParams={(val) =>
          this.props.onChangeLeadsHOC(val, "searchParams")
        }
        searchParams={this.props.searchParams}
        getListAPI={(page, search) => {
          let tempStatus = _.find(this.props.searchParams, {
            value: "status_id_eq",
          }).param;
          if (search === "") {
            this.props.onChangeLeadsHOC(1, "selectedTab");
            this.props.getLeads(1, "q[status_id_in][]=1&");
          } else if (typeof tempStatus === "number") {
            if (tempStatus === 1) {
              this.props.onChangeLeadsHOC(1, "selectedTab");
            } else if ([3, 4, 7, 9].indexOf(tempStatus) > -1) {
              this.props.onChangeLeadsHOC(3, "selectedTab");
            } else {
              this.props.onChangeLeadsHOC(2, "selectedTab");
            }
            this.props.getLeads(page, search);
          } else {
            this.onClickStatusTab(this.props.selectedTab, page);
          }
        }}
        totalPages={this.props.leadsPages}
        renderCheckBoxActions={
          (can_update || can_destroy) && this.renderCheckBoxActions
        }
        actionColumnContent={[
          {
            name: "view",
            tooltipContent: "View Details",
            onClick: (rowData) =>
              this.props.getSelectedLead(rowData.id, "view"),
          },
          {
            name: "edit",
            tooltipContent: "Quick Edit",
            onShow: () => can_update,
            onClick: (rowData) =>
              this.props.getSelectedLead(rowData.id, "edit"),
          },
          ...(["Admin", "Super Admin"].indexOf(role) !== -1
            ? [
                {
                  name: "delete",
                  color: "#F04438",
                  onShow: () => can_destroy,
                  onClick: (rowData) => {
                    this.props.onChangeLeadsHOC(true, "showDeleteModal");
                    this.props.onChangeLeadsHOC(rowData.id, "toDeleteLeadID");
                  },
                },
              ]
            : []),
        ]}
      />
    );
  };

  render = () => {
    const {
      checkedArray,
      onLoadLeads,
      onLoadUpdate,
      onLoadExport,
      showAssignDialog,
      selectedAssignType,
      showExportModal,
      showUpdateDialog,
      showBulkUpdateModal,
      showQuickUpdateModal,
      exportLeads,
      onChangeExportHOC,
      onChangeLeadsHOC,
    } = this.props;
    const { can_create } = permissionsChecker("Leads", this.props.data);
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div className="at-leads_container">
        <ModuleHeader
          title={"Leads"}
          atlasIcon={"atlas-personalcard"}
          showPABadge={role === "Personal Assistant"}
          actionButton={[
            {
              label: "New Lead",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeLeadsHOC(true, "showCreateModal"),
            },
            {
              label: "Export",
              onShow: true,
              className: "btn-new btn-new--secondary",
              icon: (
                <AtlasIcon
                  svgHref={"atlas-export-3"}
                  style={{ height: 20, width: 20, fill: "white" }}
                />
              ),
              onClick: () =>
                this.props.onChangeExportHOC(true, "showExportModal"),
            },
            {
              label: "Import",
              onShow: ["Super Admin", "Admin"].indexOf(role) > -1 && can_create,
              className: "btn-new btn-new--outline-secondary",
              icon: (
                <AtlasIcon
                  svgHref={"atlas-import-2"}
                  style={{ height: 20, width: 20 }}
                />
              ),
              onClick: () =>
                this.props.onChangeLeadsHOC(true, "showImportModal"),
            },
            {
              label: "Facebook Lead Ads",
              onShow:
                ["Super Admin", "Admin"].indexOf(role) === -1 && can_create,
              className: "btn-new btn-new--outline-secondary",
              icon: (
                <AtlasIcon
                  svgHref={"atlas-facebook"}
                  style={{ height: 20, width: 20 }}
                />
              ),
              onClick: () =>
                this.props.onChangeLeadsHOC(true, "showFacebookLeadAds"),
            },
          ]}
        />
        <AtlasCard ref={this.cardRef} cardContent={this.renderCardContent()} />
        {this.props.showCreateModal && (
          <ModalDialog
            title={"New Lead"}
            fullWidth={true}
            onClose={() =>
              this.props.onChangeLeadsHOC(false, "showCreateModal")
            }
            children={
              <CreateLeadsModal
                closeButton={() =>
                  this.props.onChangeLeadsHOC(false, "showCreateModal")
                }
                onChangeLeadsHOC={this.props.onChangeLeadsHOC}
                getLeads={this.props.getLeads}
                getSelectedLead={this.props.getSelectedLead}
              />
            }
          />
        )}
        {this.props.showUpdateDialog && (
          <ModalDialog
            title={"Edit Lead"}
            fullWidth={true}
            onClose={() =>
              this.props.onChangeLeadsHOC(false, "showUpdateDialog")
            }
            children={
              <LeadsUpdateModal
                permissionsChecker={permissionsChecker(
                  "Leads",
                  this.props.data,
                )}
                selectedAgentInfo={this.props.selectedAgentInfo}
                getLeads={this.props.getLeads}
                getSelectedLead={this.props.getSelectedLead}
                onChangeLeadsHOC={this.props.onChangeLeadsHOC}
                closeButton={() =>
                  this.props.onChangeLeadsHOC(false, "showUpdateDialog")
                }
                selectedLead={this.props.selectedLead}
                onLoadLeads={this.props.onLoadLeads}
                selectedAgent={this.props.selectedAgent}
                role={role}
              />
            }
          />
        )}
        {this.props.showFacebookLeadAds && (
          <ModalDialog
            title={"Facebook Lead Ads"}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeLeadsHOC(false, "showFacebookLeadAds")
            }
            children={<FacebookLeads />}
          />
        )}
        {this.props.showImportModal && (
          <ImportContent
            onLoadLeads={this.props.onLoadLeads}
            getLeads={this.props.getLeads}
            getSelectedLead={this.props.getSelectedLead}
            onChangeLeadsHOC={this.props.onChangeLeadsHOC}
          />
        )}
        <DeleteConfirmationModal
          open={this.props.showBulkDeleteModal}
          loading={this.props.onLoadLeads}
          positiveAction={() =>
            this.props.bulkDeleteLead({
              lead_ids: checkedArray,
            })
          }
          negativeAction={() =>
            this.props.onChangeLeadsHOC(false, "showBulkDeleteModal")
          }
          message={`Are you sure to delete the selected leads ? You will not able to recover the data`}
        />
        <DeleteConfirmationModal
          open={this.props.showDeleteModal}
          loading={this.props.onLoadDelete}
          positiveAction={() =>
            this.props.deleteSelectedLead(this.props.toDeleteLeadID)
          }
          negativeAction={() =>
            this.props.onChangeLeadsHOC(false, "showDeleteModal")
          }
          message={`You will not able to recover the data`}
        />
        {showQuickUpdateModal && (
          <LeadsQuickUpdateModal
            onLoad={onLoadLeads || onLoadUpdate}
            selectedLead={this.props.selectedLead}
            getLeads={this.props.getLeads}
            onChangeLeadsHOC={this.props.onChangeLeadsHOC}
          />
        )}
        {showBulkUpdateModal && (
          <LeadsBulkUpdateModal
            onLoad={onLoadLeads}
            leads={checkedArray}
            bulkUpdateLead={(val) => {
              if (["Admin", "Super Admin"].indexOf(role) > -1) {
                this.props.bulkUpdateLead(val);
              } else {
                this.props.agentBulkUpdateLead(val);
              }
            }}
            onChangeLeadsHOC={onChangeLeadsHOC}
          />
        )}
        {showAssignDialog && (
          <AssignDialog
            data={this.props.data}
            context={selectedAssignType || ""}
            loading={onLoadLeads}
            onClose={() => onChangeLeadsHOC(false, "showAssignDialog")}
            assignAgent={(id) =>
              this.props.bulkAssignLead({
                lead_ids: checkedArray,
                downline_id: id,
              })
            }
          />
        )}
        {showExportModal && (
          <ExportModal
            onChangeExportHOC={onChangeExportHOC}
            exportLeads={exportLeads}
          />
        )}
        {(onLoadLeads || onLoadExport) && <LoadingModal />}
      </div>
    );
  };
}

export default compose(
  LeadsHOC,
  LeadsDelete,
  LeadsExportHOC,
  VersionCheckHOC,
)(Leads);
