import { Component } from "react";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const withSubareas = (WrappedComponent) => {
  class SubareasHOC extends Component {
    state = {
      subareas: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getSubareasByTownshipName = (townshipName) =>
      Get(
        `/sub_areas?township_name=${townshipName}`,
        this.getSubareasByTownshipNameSuccess,
        this.getSubareasByTownshipNameError,
        this.load,
      );
    getSubareasByTownshipNameSuccess = (payload) =>
      this.setState({ subareas: payload });
    getSubareasByTownshipNameError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          subareas={this.state.subareas}
          onLoadSubareas={this.state.loading}
          getSubareasByTownshipName={this.getSubareasByTownshipName}
        />
      );
    };
  }

  return SubareasHOC;
};

export default withSubareas;
