import React, { useCallback } from "react";
import _ from "lodash";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";

export default function Form({
  dataCobrokes,
  openForInternalCoBroke,
  getLocalised,
  onChangeNewHOC,
  dataBasicInfo,
}) {
  const FieldData = [
    { label: getLocalised("label.details.co_broke.commission", "Commission"), value: "total_portion" },
    { label: getLocalised("label.details.co_broke.listing_agent_portion", "Listing Agent Portion"), value: "my_portion" },
    { label: getLocalised("label.details.co_broke.co_broke_agent_portion", "Co-broke Agent Portion"), value: "balance" },
  ];

  const onChangeField = useCallback(
    (key, val) => {
      onChangeNewHOC(
        {
          ...dataCobrokes,
          [key]: val,
        },
        "dataCobrokes",
      );
    },
    [dataCobrokes],
  );

  return (
    <div className="at-form__content">
      <div className="at-subsales-listing__view-title mb-20">
        <AtlasIcon
          svgHref={"atlas-shake-hand"}
          style={{ fill: "#F0631D", marginRight: 10 }}
        />
        {getLocalised("label.details.co_broke.title", "Co-broke")}
      </div>
      <CustomCheckbox
        checked={openForInternalCoBroke}
        content={getLocalised("label.details.checkbox.co_broke.message", "Open for Internal Co-broke")}
        onChangeCheckboxValue={() =>
          onChangeNewHOC(!openForInternalCoBroke, "openForInternalCoBroke")
        }
      />
      <p className="fs-2 my-2">
        {getLocalised(
          "label.details.co_broke.subtitle",
          `Commission information will be used by the Co-broke Centre when you send
          or receive internal co-broke requests.`
        )}
      </p>
      <AlertBox
        mode={"info"}
        hideIcon={true}
        containerStyle={{ marginBottom: "8px" }}
        description={
          <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_1", 
                `The Total Agency Fee is the total percentage or amount of
                professional fee that IQI will receive.`
              )}
            </li>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_2", 
                `Atlas will automatically calculate the Co-broke Agent Portion.`
              )}
            </li>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_3", 
                `For example, if the Total Agency Fee is 2% and you are willing to
                co-broke on an equal 50:50 basis then enter 1% in My Portion and
                Atlas will auto fill the Co-broke Agent Portion as 1%.`
              )}
            </li>
          </ul>
        }
      />
      {openForInternalCoBroke && (
        <section className="grid-full-col">
          <AtlasRadioGroup
            horizontal={true}
            containerClassName={"w-100"}
            checkedValue={dataCobrokes.co_broke_portion_by}
            selectedRadioValue={(val) =>
              onChangeField("co_broke_portion_by", val)
            }
            options={[
              { value: "percentage", label: getLocalised("label.details.button.co_broke.by_percentage", "By Percentage")},
              {
                value: "amount",
                label: getLocalised("label.details.button.co_broke.by_amount", "By Amount") + (_.keys(dataBasicInfo.selectedCurrency).length > 0 ? `(${dataBasicInfo.selectedCurrency.label})` : ""),
              },
            ]}
          />
          {FieldData.map(({ label: fieldLabel, value: fieldValue }) => (
            <div className={"my-3"}>
              <div className="d-flex align-items-end">
                <h2
                  className="at-form-input__title"
                  required
                >{`${fieldLabel} ${dataCobrokes.co_broke_portion_by === "percentage" ? "%" : ""}`}</h2>
              </div>
              <CustomFormInput
                type="text"
                numericOnly={true}
                value={dataCobrokes[fieldValue]}
                onChangeValue={(val) => {
                  let tempVal = val.replace(/[^$0-9.]/g, "");
                  let temp = _.cloneDeep(dataCobrokes);
                  temp[fieldValue] = tempVal;

                  if (
                    ["total_portion", "my_portion"].indexOf(fieldValue) > -1 &&
                    temp.total_portion &&
                    temp.my_portion
                  ) {
                    temp["balance"] =
                      fieldValue === "my_portion"
                        ? temp.total_portion - tempVal
                        : tempVal - temp.my_portion;
                  }

                  onChangeNewHOC(temp, "dataCobrokes");
                }}
                required={true}
              />
            </div>
          ))}
        </section>
      )}
    </div>
  );
}
