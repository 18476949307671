import React, { useState, useCallback } from "react";
import _ from "lodash";
import {
  Dialog,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import OrderTable from "./OrderTable";
import defaultAvatar from "assets/images/av-male.jpg";

import "./orderDetail.scss";

const agentDetails = [
  { label: "Full Name", value: "full_name" },
  { label: "Email", value: "email" },
  { label: "Mobile Contact", value: "mobile_contact_number" },
  { label: "Team Name", value: "team_name" },
  { label: "Branch Name", value: "branch_name" },
  { label: "Via Recruitment Campaign", value: "via_recruitment_campaign_text" },
];

const OrderDetails = ({ selectedOrder, onClose }) => {
  const [showNameCardModal, setshowNameCardModal] = useState(false);

  const renderCardContent = useCallback(() => {
    const {
      market_order_items = [],
      order_number = "N/A",
      total_amount = 0,
      avatar_url,
    } = selectedOrder;
    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => onClose()} />
          <h4 className="at-card__title">Order Details</h4>
        </div>
        <h2 className="at-form-input__title">
          {`Order No.`}
          <div className="at-namecard-order-number ml-2">{order_number}</div>
        </h2>
        <div className="row">
          <div
            className="at-namecard__order-item col-sm-8 col-lg-9"
            style={{ paddingRight: "15px" }}
          >
            <ExpansionPanel
              expanded={true}
              classes={{ root: "at-namecard-expansion-panel" }}
            >
              <ExpansionPanelSummary
                classes={{
                  root: "at-namecard-panel-summary panel-disabled p-0",
                  content: "at-namecard-panel-summary-content",
                }}
              >
                <h2 className="at-form-input__title px-2">{`Order Items`}</h2>
                <h2
                  className="at-form-input__title d-flex px-2"
                  style={{ marginLeft: "auto" }}
                >{`Total Amount: RM ${total_amount.toFixed(2)}`}</h2>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{ root: "at-namecard-panel-details" }}
              >
                <OrderTable
                  pagination={true}
                  rowData={market_order_items}
                  originalRowData={market_order_items}
                  onClickViewNameCard={() => setshowNameCardModal(true)}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="at-namecard__agent-info col-sm-4 col-lg-3">
            <ExpansionPanel
              expanded={true}
              classes={{ root: "at-namecard-expansion-panel" }}
            >
              <ExpansionPanelSummary
                classes={{
                  root: "at-namecard-panel-summary panel-disabled",
                  content: "at-namecard-panel-summary-content",
                }}
              >
                <h2 className="at-form-input__title">{`Agent Information`}</h2>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{ root: "at-namecard-panel-details" }}
              >
                <div className="at-namecard-personal-details">
                  <div className="at-namecard-order-img__block">
                    <img
                      src={
                        avatar_url && !_.includes(avatar_url, "missing.png")
                          ? avatar_url
                          : defaultAvatar
                      }
                      className={"at-namecard-order-img"}
                    />
                  </div>
                  <div className="at-namecard-personal__info">
                    {agentDetails.map((item, index) => (
                      <div className={"mb-1"} key={index}>
                        {item.label == "Full Name" && (
                          <p className="fw-600 mb-1">
                            {selectedOrder[item.value]}
                          </p>
                        )}
                        {item.label == "Via Recruitment Campaign" && (
                          <p>
                            <span className="fw-600">{item.label}: </span>
                            {selectedOrder[item.value]}
                          </p>
                        )}
                        {item.label != "Full Name" &&
                          item.label != "Via Recruitment Campaign" && (
                            <p>{selectedOrder[item.value]}</p>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
      </>
    );
  }, [selectedOrder]);

  return (
    <>
      <CustomCard className={`h-100`} cardContent={renderCardContent()} />
      <Dialog open={showNameCardModal}>
        <CustomCard
          containerStyle={{ minHeight: 300, minWidth: 300, padding: 0 }}
          cardContent={
            <>
              <AtlasCloseButton
                containerStyle={{ position: "absolute", top: 10, right: 10 }}
                onClick={() => setshowNameCardModal(false)}
              />
              <img
                src={selectedOrder.name_card_photo_url}
                style={{ width: "100%", height: "100%" }}
              />
            </>
          }
        />
      </Dialog>
    </>
  );
};

export default OrderDetails;
