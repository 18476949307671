import React, { Component } from "react";
import _ from "lodash";
import { Dialog } from "@material-ui/core";
import { AiOutlineImport } from "react-icons/ai";

import SvgIcon from "components/Icon/new";
import CustomCard from "components/Card";
import AtlasTable from "components/NewTable";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import UploadContent from "./components/uploadContent";
import UploadStatusModal from "./components/uploadStatusModal";
import ConfirmationModal from "components/Modal/delete";
import ModalDialog from "components/Modal/ModalDialog";

import DocsFinanceHOC from "./actions";

const ColumnData = [
  { header: "Title", accessor: "title", searchFlag: "title" },
  {
    header: "File Name",
    accessor: "document_file_name",
    searchFlag: "document_file_name",
  },
  { header: "Type", searchFlag: "type", renderColumn: ({ type }) => type },
  {
    header: "Issue To",
    searchFlag: "issue_to_string",
    accessor: "issue_to_string",
  },
  {
    header: "Status",
    searchFlag: "status_id",
    renderColumn: ({ status }) => status,
  },
];

class DocsFinance extends Component {
  componentDidMount = () =>
    this.props.getFinanceDocs(this.props.selected_claim.id);

  renderTableHeading = () => {
    const { can_create } = this.props.permissionChecker;

    return (
      <div className={`at-table_heading`}>
        <SvgIcon
          svgHref="confirmation-form_document-text"
          className="at-icon_svg-table_heading-title"
        />
        <h5 className="fw-600">Uploaded Documents</h5>
        {can_create && (
          <CustomButton
            className={"btn-new btn-new--primary"}
            children={
              <>
                <AiOutlineImport style={{ width: 18, height: 18 }} />
                Import
              </>
            }
            onClick={() =>
              this.props.onChangeFinanceDocsHOC(true, "showUploadModal")
            }
          />
        )}
      </div>
    );
  };
  render = () => {
    const {
      docsFinance,
      selected_claim,
      onLoadDocsFinance,
      selectedDeleteDoc,
      permissionChecker,
      showDeleteConfirmation,
    } = this.props;
    const { can_destroy } = permissionChecker;

    return (
      <>
        <AtlasTable
          mode={"new"}
          className={"mb-100"}
          hideSearch={true}
          pagination={true}
          rowData={docsFinance || []}
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "download",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 18, height: 18, fill: "#007bff" },
              onClick: (rowData) => window.open(rowData.url, "_blank"),
            },
            {
              name: "delete",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 18, height: 18, fill: "#EB3134" },
              onShow: (rowData) => can_destroy && rowData.doc_type !== "pv doc",
              onClick: (rowData) => {
                this.props.onChangeFinanceDocsHOC(
                  true,
                  "showDeleteConfirmation",
                );
                this.props.onChangeFinanceDocsHOC(rowData, "selectedDeleteDoc");
              },
            },
          ]}
          renderExtraContent={this.renderTableHeading}/>
        {this.props.showUploadModal && (
          <ModalDialog
            title={"Upload Document"}
            onClose={() =>
              this.props.onChangeFinanceDocsHOC(false, "showUploadModal")
            }
            children={
              <UploadContent
                documents={this.props.documents}
                onLoadDocsFinance={onLoadDocsFinance}
                uploadFinanceDocs={this.props.uploadFinanceDocs}
                onChangeFinanceDocsHOC={this.props.onChangeFinanceDocsHOC}
              />
            }
          />
        )}
        <ConfirmationModal
          title={"Delete Docs"}
          loading={onLoadDocsFinance}
          open={showDeleteConfirmation}
          message={`Are you sure you want to remove this document ?`}
          positiveAction={() =>
            this.props.deleteFinanceDocs(
              selected_claim.id,
              selectedDeleteDoc.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeFinanceDocsHOC(false, "showDeleteConfirmation")
          }/>
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={this.props.showUploadLoading}>
          <CustomCard
            containerStyle={{ background: "#F3F4F6" }}
            cardContent={
              <UploadStatusModal
                uploadInProgress={this.props.uploadInProgress}
                tempUploadDocument={this.props.tempUploadDocument}
                onClose={() => {
                  this.props.onChangeFinanceDocsHOC(false, "showUploadLoading");
                  this.props.onChangeFinanceDocsHOC(false, "showUploadModal");
                  this.props.getFinanceDocs(this.props.selected_claim.id);
                }}
              />
            }/>
        </Dialog>
        {onLoadDocsFinance && <LoadingModal />}
      </>
    );
  };
}

export default DocsFinanceHOC(DocsFinance);
