import React, { useEffect } from "react";
import _ from "lodash";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import DrawingBoard from "components/DrawingBoard";
import ConfirmationModal from "components/Modal/confirmation";
import ElectronicTNCContent from "assets/electronic-tnc";

import DigitalHOC from "./actions/digitalSign";

import "./digital.scss";

const DigitalSign = ({
  checkedTNC,
  showElectronicTNCModal,
  showDrawingBoard,
  showDeleteConfirmation,
  onLoadDigitalSign,
  selectedDeleteId,

  deleteDigitalSign,
  signedDigitalSign,
  getDigitalSign,
  onChangeDigitalSignHOC,
  createDigitalSign,
}) => {
  useEffect(() => {
    getDigitalSign();
  }, []);

  useEffect(() => {
    onChangeDigitalSignHOC(false, "checkedTNC");
  }, [showElectronicTNCModal]);

  return (
    <>
      <div className="at-form__content at-profile__digital-signature">
        <h2 style={{ fontSize: 18, fontWeight: 600, color: "#111827" }}>
          Digital Signature
        </h2>
        <p className="my-3">
          Create your digital signature here so that you can apply this
          signature in any of the e-signing contracts.
        </p>
        {_.values(signedDigitalSign).length > 0 && (
          <div
            className="at-form__content position-relative"
            style={{ width: "fit-content" }}
          >
            <img alt="digital-sign" src={signedDigitalSign.signature_url} />
            <AtlasButton
              tooltip={true}
              tooltipPosition={"bottom"}
              tooltipID={"delete"}
              tooltipChildren={"Delete Signature"}
              containerClass={"at-profile__digital-signature-delete"}
              children={
                <BsTrash3Fill
                  style={{ width: 20, height: 20, color: "#F04438" }}
                />
              }
              onClick={() => {
                onChangeDigitalSignHOC(true, "showDeleteConfirmation");
                onChangeDigitalSignHOC(
                  signedDigitalSign.id,
                  "selectedDeleteId",
                );
              }}
            />
          </div>
        )}
        {_.values(signedDigitalSign).length < 1 && (
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <h5 className={"fw-600"}>No signature is available now</h5>
              <button
                className={"btn-new btn-new--primary mt-20"}
                onClick={() =>
                  onChangeDigitalSignHOC(true, "showElectronicTNCModal")
                }
              >
                Create Signature now
              </button>
            </CardContent>
          </Card>
        )}
      </div>
      <Dialog
        open={showElectronicTNCModal}
        maxWidth={"lg"}
        fullWidth={true}
        classes={{ paper: "at-digital-sign_modal" }}
      >
        <DialogContent>
          <ElectronicTNCContent />
        </DialogContent>
        <DialogActions
          className={"d-flex justify-content-between flex-wrap p-2"}
        >
          <CustomCheckbox
            checked={checkedTNC}
            content="I agree to use electronic records & signature"
            onChangeCheckboxValue={() =>
              onChangeDigitalSignHOC(!checkedTNC, "checkedTNC")
            }
          />
          <div className="d-flex mt-20">
            <button
              disabled={!checkedTNC}
              className={"btn-new btn-new--success mr-10"}
              onClick={() => {
                onChangeDigitalSignHOC(false, "showElectronicTNCModal");
                onChangeDigitalSignHOC(true, "showDrawingBoard");
              }}
            >
              Accept
            </button>
            <button
              className={"btn-new btn-new--danger"}
              onClick={() =>
                onChangeDigitalSignHOC(false, "showElectronicTNCModal")
              }
            >
              Reject
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <DrawingBoard
        paperClass={"at-digital-sign_modal"}
        canvasClass={"at-profile__digital-canvas"}
        open={showDrawingBoard}
        title={`Create Digital Signature`}
        loading={onLoadDigitalSign}
        onClickClose={() => onChangeDigitalSignHOC(false, "showDrawingBoard")}
        onClickSubmit={createDigitalSign}
      />
      <ConfirmationModal
        title={"Delete Signature"}
        mode={"alert"}
        open={showDeleteConfirmation}
        loading={onLoadDigitalSign}
        message={`Are you sure you want to delete this digital signature ?`}
        positiveAction={() => deleteDigitalSign(selectedDeleteId)}
        negativeAction={() =>
          onChangeDigitalSignHOC(false, "showDeleteConfirmation")
        }
      />
      {onLoadDigitalSign && <LoadingModal />}
    </>
  );
};

export default DigitalHOC(DigitalSign);
