import React, { Component } from "react";
import _ from "lodash";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      languages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeLanguageHOC = (val, context) => this.setState({ [context]: val });

    getLanguages = (id) =>
      Get(
        `/module_countries?module_id=${id}`,
        this.getLanguagesSuccess,
        this.getLanguagesError,
        this.load,
      );
    getLanguagesSuccess = (payload) => {
      let tempData = [];
      payload.data &&
        payload.data.map(({ country }) => {
          tempData.push({
            ...country,
          });
        });
      this.setState({ languages: tempData });
    };
    getLanguagesError = (error) => requestError(error);

    render = () => (
      <WrappedComponent
        {...this.props}
        languages={this.state.languages}
        onLoadLanguage={this.state.loading}
        onChangeLanguageHOC={this.onChangeLanguageHOC}
        getLanguages={this.getLanguages}
      />
    );
  }
  return WithHOC;
};

export default HOC;
