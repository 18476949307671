import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";

const LeadSettingHOC = (WrappedComponent) => {
  class LeadsWrappedComponent extends Component {
    state = {
      loading: false,
      userAccount: [],
      leadGenForm: [],
      atlasAccount: [],
      longLiveToken: "",
      selectedAccount: "",
      selectedLeadForm: [],
      existingLeadForm: [],
      selectedEditLeadForm: {},

      showLeadsModal: false,
      showRemoveModal: false,
      showMappingModal: false,
      showEditLeadForm: false,
      showFormOptions: false,
      showAccountOptions: false,
      showCreateTestForm: false,
      showLoginValidModal: false,
      showDisconnectModal: false,
      showLoginExpiredModal: false,
      showUnsubscribeModal: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeLeadSetting = (val, context) => this.setState({ [context]: val });

    checkFacebookLogin = () => {
      const { access_token } = this.state.longLiveToken;

      this.load(true);
      try {
        window.FB.api(`/me?access_token=${access_token}`, (response) => {
          if (response.error) {
            this.load(false);
            this.setState({ showLoginExpiredModal: true });
          } else if (response.id) {
            this.setState({ showLoginValidModal: true });
            this.getLongLiveToken();
          } else {
            this.load(false);
            this.setState({ showLoginExpiredModal: true });
          }
        });
      } catch (err) {
        this.load(false);
        console.log(err);
      }
    };

    facebookLogin = async () => {
      this.load(true);
      this.setState({ showLoginExpiredModal: false });
      try {
        window.FB.login(
          (response) => {
            if (response.status === "connected") {
              this.setState(
                {
                  longLiveToken: {
                    user_id: response.authResponse.userID,
                    access_token: response.authResponse.accessToken,
                  },
                },
                () => {
                  this.createLongLiveToken();
                },
              );
            } else {
              this.load(false);
            }
          },
          {
            scope:
              "public_profile,ads_management,pages_manage_ads,pages_read_engagement,pages_show_list,pages_manage_metadata,leads_retrieval,email",
          },
        );
      } catch (err) {
        this.load(false);
        console.log(err);
      }
    };

    getConnectedAccount = async (token) => {
      let tempData = [];

      window.FB.api(
        `/${this.state.longLiveToken.fb_id}/accounts`,
        {
          access_token: token,
        },
        (response) => {
          this.load(false);
          console.log(response);
          if (response && !response.error) {
            response.data.map((item) => {
              tempData.push({
                ...item,
                label: `Facebook Lead Ads page for ${item.name}`,
              });
            });

            this.setState(
              {
                userAccount: tempData,
                selectedAccount: {
                  ...tempData[0],
                  is_subscribed: _.find(this.state.atlasAccount, {
                    fb_id: tempData[0].id,
                  })?.is_subscribed,
                },
              },
              () => this.getConnectedLeadForm(),
            );
          } else {
            console.log(response);
          }
        },
      );
    };

    getLeadGenForm = async (param) => {
      let tempData = [];

      this.load(true);
      window.FB.api(
        "/me/leadgen_forms",
        "GET",
        {
          access_token: param,
          // access_token: 'EAArhQCfkAGoBO9rAGyh8vBjz7mz5fKflUCaaZBrPu4zE9SIJugQR65bQetNxHtnorHwnAjNutJiIc2HVtjVkW26tYRsqZBAQggsWyK1m2rUILBpyz9bAW4fD8PssOypLkljr3ZBZCtMUZADOZCXZA0lxqJNfbRAkmz7PLoZCl9ZCEjNRxDenI5IxxdZCgZB3tZB2Qi4ZD',
          fields:
            "name,created_time,questions,leads{created_time,field_data,ad_name,adset_name,campaign_name}",
        },
        (response) => {
          this.load(false);
          if (response && !response.error) {
            if (response.data && response.data.length > 0) {
              response.data.map((item) => {
                if (
                  _.findIndex(this.state.existingLeadForm, {
                    form_id: item.id,
                  }) === -1
                ) {
                  let tempQuestion = {
                    type_id: null,
                  };
                  item.questions = item.questions.map((question) => {
                    tempQuestion[question.key] = null;
                    return { label: question.label, key: question.key };
                  });

                  tempData.push({
                    ...item,
                    expand: false,
                    questions: [
                      ...item.questions,
                      { key: "type_id", label: "Lead Type" },
                    ],
                    requiredError: false,
                    columnMapping: tempQuestion,
                    leads: item.leads ? item.leads.data : [],
                  });
                }
              });
              this.setState({ leadGenForm: tempData, showLeadsModal: true });
            } else {
              requestError("No Lead Form found in the selected Facebook Page.");
            }
          } else {
            requestError(response.error.message);
          }
        },
      );
    };

    subscribePage = (page_id) =>
      Post(
        `/webhooks/facebook/subscribe`,
        { page_id: page_id },
        this.subscribePageSuccess,
        this.subscribePageFailed,
        this.load,
      );
    subscribePageSuccess = () => {
      this.getLongLiveToken();
      requestSuccess("You have subscribed to the selected Facebook Page.");
    };
    subscribePageFailed = (error) => requestError(error);

    unsubscribePage = (page_id) =>
      Delete(
        `/webhooks/facebook/unsubscribe?page_id=${page_id}`,
        this.unsubscribePageSuccess,
        this.unsubscribePageFailed,
        this.load,
      );
    unsubscribePageSuccess = () => {
      this.setState(
        {
          showUnsubscribeModal: false,
          existingLeadForm: [],
          selectedLeadForm: [],
          selectedAccount: "",
        },
        () => {
          this.getLongLiveToken();
        },
      );
      requestSuccess("You have unsubscribed from the selected Facebook Page.");
    };
    unsubscribePageFailed = (error) => requestError(error);

    createLongLiveToken = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/agents/${id}/facebook_tokens`,
        {
          user_id: this.state.longLiveToken.user_id,
          access_token: this.state.longLiveToken.access_token,
        },
        this.createLongLiveTokenSuccess,
        this.createLongLiveTokenFailed,
        this.load,
      );
    };
    createLongLiveTokenSuccess = (payload) =>
      this.setState(
        {
          longLiveToken: payload,
        },
        () => {
          this.getLongLiveToken();
        },
      );
    createLongLiveTokenFailed = (error) => requestError(error);

    getLongLiveToken = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Get(
        `/agents/${id}/facebook_tokens`,
        this.getLongLiveTokenSuccess,
        this.getLongLiveTokenFailed,
        this.load,
      );
    };
    getLongLiveTokenSuccess = (payload) => {
      let tempPage = [];
      let tempUser = {};
      if (payload.data.length > 0) {
        payload.data.map((item) => {
          if (item.token_type === "User") {
            tempUser = item;
          } else {
            tempPage.push(item);
          }
        });
        this.setState(
          {
            atlasAccount: tempPage,
            longLiveToken: tempUser,
          },
          () => {
            console.log(tempUser.accessToken);
            this.getConnectedAccount(tempUser.access_token);
          },
        );
      }
    };
    getLongLiveTokenFailed = (error) => requestError(error);

    removeLongLiveToken = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      if (this.state.longLiveToken.id) {
        Delete(
          `/agents/${id}/facebook_tokens/${this.state.longLiveToken.id}`,
          this.removeLongLiveTokenSuccess,
          this.removeLongLiveTokenFailed,
          this.load,
        );
      } else {
        requestError("No Long Live Token found.");
      }
    };
    removeLongLiveTokenSuccess = () => {
      this.setState({
        userAccount: [],
        leadGenForm: [],
        longLiveToken: "",
        selectedAccount: "",
        existingLeadForm: [],
        selectedLeadForm: [],
        showDisconnectModal: false,
      });
      requestSuccess("You have logged out from the Facebook Account.");
    };
    removeLongLiveTokenFailed = (error) => requestError(error);

    updateConnectedLeadForm = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Put(
        `/agents/${id}/facebook_leadgen_forms/${this.state.selectedEditLeadForm.id}`,
        {
          form_id: this.state.selectedEditLeadForm.form_id,
          form_name: this.state.selectedEditLeadForm.form_name,
          column_mapping: this.state.selectedEditLeadForm.column_mapping,
        },
        this.updateConnectedLeadFormSuccess,
        this.updateConnectedLeadFormFailed,
        this.load,
      );
    };
    updateConnectedLeadFormSuccess = () => {
      this.setState({ showEditLeadForm: false });
      this.getConnectedLeadForm();
      requestSuccess("Selected Lead Form has been updated");
    };
    updateConnectedLeadFormFailed = (error) => requestError(error);

    updateNotificationSetting = (dataToSubmit) =>
      Put(
        `/entity_settings/${dataToSubmit.id}`,
        {
          entity_type: "FacebookLeadgenForm",
          key: "incoming.lead.notification",
          settings: {
            notify_via: dataToSubmit.notify_via,
          },
          entity_id: dataToSubmit.entity_id,
        },
        this.updateNotificationSettingSuccess,
        this.updateNotificationSettingFailed,
        this.load,
      );
    updateNotificationSettingSuccess = () => this.updateConnectedLeadForm();
    updateNotificationSettingFailed = (error) => requestError(error);

    getConnectedLeadForm = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Get(
        `/agents/${id}/facebook_leadgen_forms`,
        this.getConnectedLeadFormSuccess,
        this.getConnectedLeadFormFailed,
        this.load,
      );
    };
    getConnectedLeadFormSuccess = (payload) => {
      let temp = [];
      if (payload.data.length > 0) {
        payload.data.map((item) => {
          temp.push({
            ...item,
            questions: item.questions?.data ?? [],
          });
        });
      }

      this.setState({
        selectedLeadForm: [],
        existingLeadForm: temp,
      });
    };
    getConnectedLeadFormFailed = (error) => requestError(error);

    createConnectedLeadForm = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/agents/${id}/facebook_leadgen_forms`,
        { form: this.state.selectedLeadForm },
        this.createConnectedLeadFormSuccess,
        this.createConnectedLeadFormFailed,
        this.load,
      );
    };
    createConnectedLeadFormSuccess = (payload) => {
      requestSuccess("Selected Lead Form has been connected");
      this.setState(
        {
          showLeadsModal: false,
          selectedLeadForm: [],
        },
        () => {
          this.getConnectedLeadForm();
        },
      );
    };
    createConnectedLeadFormFailed = (error) => requestError(error);

    createTestLeadForm = (dataToSubmit) =>
      Post(
        `/leads/create_test_lead`,
        dataToSubmit,
        this.createTestLeadFormSuccess,
        this.createTestLeadFormFailed,
        this.load,
      );
    createTestLeadFormSuccess = () => {
      this.setState({ showCreateTestForm: false });
      requestSuccess("Test Lead has been created");
    };
    createTestLeadFormFailed = (error) => requestError(error);

    removeConnectedLeadForm = (form_id) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Delete(
        `/agents/${id}/facebook_leadgen_forms/${form_id}`,
        this.removeConnectedLeadFormSuccess,
        this.removeConnectedLeadFormFailed,
        this.load,
      );
    };
    removeConnectedLeadFormSuccess = () => {
      this.getLongLiveToken();
      this.setState({ showRemoveModal: false });
      requestSuccess("Selected Lead Form has been disconnected");
    };
    removeConnectedLeadFormFailed = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadLeadSetting={this.state.loading}
            facebookLogin={this.facebookLogin}
            subscribePage={this.subscribePage}
            getLeadGenForm={this.getLeadGenForm}
            unsubscribePage={this.unsubscribePage}
            getLongLiveToken={this.getLongLiveToken}
            createTestLeadForm={this.createTestLeadForm}
            checkFacebookLogin={this.checkFacebookLogin}
            removeLongLiveToken={this.removeLongLiveToken}
            getConnectedAccount={this.getConnectedAccount}
            getConnectedLeadForm={this.getConnectedLeadForm}
            updateConnectedLeadForm={this.updateConnectedLeadForm}
            createConnectedLeadForm={this.createConnectedLeadForm}
            removeConnectedLeadForm={this.removeConnectedLeadForm}
            updateNotificationSetting={this.updateNotificationSetting}
            onChangeLeadSetting={this.onChangeLeadSetting}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(LeadsWrappedComponent);
};

export default LeadSettingHOC;
