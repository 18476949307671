import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Avatar } from "@material-ui/core";

import AtlasButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";

const SubsalesAdminStatus = [
  { id: 1, name: "On Call" },
  { id: 2, name: "Offline" },
  { id: 3, name: "Disabled" },
];

const Form = ({
  data,
  selectedData,
  onClose,
  onClickSubmit,

  branchOptions = [],
  onLoadSubsalesAdmins,
}) => {
  const [statusId, onChangeStatus] = useState(1);
  const [branchIDs, onChangeBranchID] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);

  useEffect(() => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (selectedData) {
      onChangeStatus(selectedData.status_id);
      onChangeBranchID(selectedData.branch_ids);
      if (
        selectedData.user_id === tmpProfileReducer.id ||
        tmpProfileReducer.role === "Super Admin"
      ) {
        setUpdatePermission(true);
      }
    }
  }, []);

  return (
    <ModalDialog
      title={"Edit Admin Role"}
      onLoad={onLoadSubsalesAdmins}
      responsiveSize="md"
      onClose={onClose}
      children={
        <div className="row">
          <section className="col-md-4 d-flex align-items-center">
            <Avatar
              style={{ width: 32, height: 32, backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={`atlas-profile-circle`}
                style={{ width: 16, height: 16, fill: "#F0631D" }}
              />
            </Avatar>
            <div className="d-flex flex-column ml-2">
              <h2 className="at-form-label">Select An Admin</h2>
              <p className="fs-2">{selectedData.display_name}</p>
            </div>
          </section>
          <section className="col-md-4 d-flex align-items-center">
            <Avatar
              style={{ width: 32, height: 32, backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={`atlas-user-octogan`}
                style={{ width: 16, height: 16, fill: "#F0631D" }}
              />
            </Avatar>
            <div className="d-flex flex-column ml-2">
              <h2 className="at-form-label">Roles</h2>
              <p className="fs-2">{selectedData.type}</p>
            </div>
          </section>
          <section className="col-md-4">
            <h2 className="at-form-label">Status</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              labelKey={"name"}
              horizontal={true}
              disabled={!updatePermission}
              checkedValue={statusId}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeStatus(val)}
              options={SubsalesAdminStatus}
            />
          </section>
          <div className="col-md-12">
            <h2 className="at-form-label">Manage Branches Below</h2>
            <div className="row mt-3">
              {branchOptions.map((item) => (
                <CustomCheckbox
                  containerClass="col-6 col-md-4 col-lg-2"
                  labelClassname={"mx-0"}
                  labelStyle={{ marginBottom: 12 }}
                  content={item.name}
                  disabled={!updatePermission}
                  checked={branchIDs.indexOf(item.id) > -1}
                  onChangeCheckboxValue={() => {
                    let tmp = _.cloneDeep(branchIDs);
                    if (branchIDs.indexOf(item.id) > -1) {
                      tmp.splice(tmp.indexOf(item.id), 1);
                    } else {
                      tmp.push(item.id);
                    }
                    onChangeBranchID(tmp);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          {updatePermission && (
            <AtlasButton
              className={"btn-new btn-new--success"}
              children={"Update"}
              onClick={() =>
                onClickSubmit(selectedData.id, {
                  status_id: statusId,
                  branch_ids: branchIDs,
                })
              }
            />
          )}
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      }
    />
  );
};

export default Form;
