import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineImport } from "react-icons/ai";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import ImportExcelDialog from "./components/importExcelModal";
import BatchDetail from "./components/batchDetail";

import NameCardMngmtHOC from "./actions";
import ExportPendingHOC from "./actions/exportPending";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

const columnData = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Product Type",
    renderColumn: (rowData) => {
      if (rowData.type_id === 0) {
        return "Starter Kit";
      } else if (rowData.type_id === 1) {
        return "Re-Order";
      } else {
        return "N/A";
      }
    },
  },
  {
    header: "Export Date Time",
    accessor: "created_at",
  },
  {
    header: "Total Items",
    accessor: "order_batch_items_count",
  },
  {
    header: "By",
    accessor: "by_user_name",
  },
];

class AdminNameCardManagement extends Component {
  state = {
    generateType: 0,
  };

  componentDidMount = () => this.props.getBatch(1, "");

  renderCardContent = () => {
    return (
      <AtPaginatedTable
        rowData={this.props.batchData || []}
        meta={this.props.batchMeta}
        columns={columnData}
        actionColumnContent={[
          {
            name: "details",
            onClick: (rowData) => {
              this.props.getSelectedBatch(rowData.id, 1, "");
              this.props.onChangeNameCardMngmtHOC(rowData, "selectedBatch");
            },
          },
        ]}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeNameCardMngmtHOC(val, "searchParams")
        }
        getListAPI={this.props.getBatch}
        totalPages={this.props.batchPage}
      />
    );
  };

  render = () => {
    const {
      showImportExcelModal,
      showDetailsDialog,
      selectedBatch,
      selectedBatchOrder,
      selectedBatchOrderPage,
      onLoadNameCardMngmt,
      onLoadExportPending,
      onChangeNameCardMngmtHOC,
      getSelectedBatch,
    } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Atlas Store Name Card Batches",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={`Name Card Batches`}
          moduleIcon={"icon-name-card-mngmt"}
          actionButton={[
            {
              label: "Generate New Batch",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeNameCardMngmtHOC(true, "showConfirmation"),
            },
            {
              label: "Import Item Status",
              onShow: can_update,
              className: "btn-new btn-new--secondary",
              icon: (
                <AiOutlineImport
                  style={{ height: 18, width: 18, marginRight: 10 }}
                />
              ),
              onClick: () =>
                this.props.onChangeNameCardMngmtHOC(
                  true,
                  "showImportExcelModal",
                ),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={showDetailsDialog}>
          <BatchDetail
            getSelectedBatch={getSelectedBatch}
            selectedBatch={selectedBatch}
            selectedBatchOrder={selectedBatchOrder}
            selectedBatchOrderPage={selectedBatchOrderPage}
            onLoadNameCardMngmt={onLoadNameCardMngmt}
            onChangeNameCardMngmtHOC={onChangeNameCardMngmtHOC}
            onClose={() => onChangeNameCardMngmtHOC(false, "showDetailsDialog")}
          />
        </AtlasDialog>
        <ConfirmationModal
          title={"Generate Name Card Batch"}
          loading={onLoadNameCardMngmt || onLoadExportPending}
          open={this.props.showConfirmation}
          message={
            <div className="w-100">
              <span>Choose the type that you want to generate.</span>
              <CustomRadioGroup
                horizontal={true}
                checkedValue={this.state.generateType}
                containerClassName={"mx-5 my-2"}
                options={[
                  { value: 0, label: "Starter Kit" },
                  { value: 1, label: "Re-Order" },
                ]}
                selectedRadioValue={(val) =>
                  this.setState({ generateType: val })
                }
              />
            </div>
          }
          negativeText={"Cancel"}
          positiveText={"Generate Now"}
          positiveAction={() =>
            this.props.exportPending(this.state.generateType)
          }
          negativeAction={() =>
            onChangeNameCardMngmtHOC(false, "showConfirmation")
          }
        />
        <AtlasDialog open={showImportExcelModal}>
          <CustomCard
            className={`h-100`}
            cardContent={
              <ImportExcelDialog
                selectedBatch={selectedBatch}
                getSelectedBatch={getSelectedBatch}
                onChangeNameCardMngmtHOC={this.props.onChangeNameCardMngmtHOC}
                onClose={() =>
                  this.props.onChangeNameCardMngmtHOC(
                    false,
                    "showImportExcelModal",
                  )
                }
              />
            }
          />
        </AtlasDialog>
        {(onLoadNameCardMngmt || onLoadExportPending) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  NameCardMngmtHOC,
  ExportPendingHOC,
  VersionCheckHOC,
)(AdminNameCardManagement);
