import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import { refreshToken } from "actions/login";

const CalendarHOC = (WrappedComponent) => {
  class EventsWrappedComponent extends Component {
    state = {
      calendarData: [],
      selectedData: {},
      selected_data_original: {},
      defaultModalImage: "",
      showDetailsDialog: false,
      filterBranchRegion: { label: "All", value: 0 },
      branchRegions: [],
      deliveryBranches: [],

      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCalendarHOC = (val, context) => this.setState({ [context]: val });

    onToggleEventDetailsOverlay = (param) =>
      this.setState({ showDetailsDialog: param });

    getBranchRegions = () =>
      Get(
        `/branch_regions`,
        this.getBranchRegionsSuccess,
        this.getBranchRegionsError,
        this.load,
      );
    getBranchRegionsSuccess = (payload) => {
      let temp = [{ label: "All", value: "9999", reference_id: "9999", id: "9999" }];
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          temp.push({
            id: `${item.id}`,
            reference_id: `${item.reference_id}`,
            value: `${item.id}`,
            label: item.name,
          });
        });
      this.setState({ branchRegions: temp });
    };
    getBranchRegionsError = (error) => requestError(error);

    getDeliveryBranches = () =>
      Get(
        `/branches/?[q][branch_selections_type_id_eq]=2`,
        this.getDeliveryBranchesSuccess,
        this.getDeliveryBranchesError,
        this.load,
      );
    getDeliveryBranchesSuccess = (payload) =>
      this.setState({ deliveryBranches: payload });
    getDeliveryBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedData={this.state.selectedData}
            defaultModalImage={this.state.defaultModalImage}
            calendarData={this.state.calendarData}
            branchRegions={this.state.branchRegions}
            deliveryBranches={this.state.deliveryBranches}
            filterBranchRegion={this.state.filterBranchRegion}
            selected_data_original={this.state.selected_data_original}
            showDetailsDialog={this.state.showDetailsDialog}
            onLoadCalendar={this.state.loading}
            getBranchRegions={this.getBranchRegions}
            getDeliveryBranches={this.getDeliveryBranches}
            onChangeCalendarHOC={this.onChangeCalendarHOC}
            onToggleEventDetailsOverlay={this.onToggleEventDetailsOverlay}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(EventsWrappedComponent);
};

export default CalendarHOC;
