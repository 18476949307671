import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { FaRegChartBar } from "react-icons/fa";
import { BiTable } from "react-icons/bi";
import { Bar } from "react-chartjs-2";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomSearch from "../components/search";

import { numberWithCommas } from "utils/thousandSeparator";

const columnData = [
  { header: "Date", accessor: "date", searchFlag: "date" },
  { header: "Web", accessor: "web_total", searchFlag: "web_total" },
  { header: "Mobile App", accessor: "app_total", searchFlag: "app_total" },
];

const SearchParams = ({ dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          col: 6,
          initialValue: "Last 7 Days",
          param: `start_date=${Moment().subtract(7, "day").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
];

const MOTDStats = ({
  data,
  motdStats,

  getMOTDStats,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getMOTDStats(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        dateRangeMode,
      }),
    );

    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  return (
    <>
      <div className="mb-10">
        <CustomSearch
          searchParams={searchParams}
          dateRangeMode={dateRangeMode}
          showSearchContent={showSearchContent}
          getListAPI={getMOTDStats}
          setSearchParams={setSearchParams}
          setDateRangeMode={setDateRangeMode}
          setShowSearchContent={setShowSearchContent}
        />
      </div>
      <div className="d-flex mb-10 justify-content-end">
        <button
          className={`at-table-search__additional-btn ${visual === "chart" ? "at-table-search__additional--selected" : ""}`}
          onClick={() => setVisual("chart")}
          children={<FaRegChartBar style={{ width: "20px", height: "20px" }} />}
        />
        <button
          onClick={() => setVisual("table")}
          className={`at-table-search__additional-btn ${visual === "table" ? "at-table-search__additional--selected" : ""}`}
          children={<BiTable style={{ width: "20px", height: "20px" }} />}
        />
      </div>
      {motdStats.data && motdStats.data.length > 0 && visual === "table" && (
        <CustomTable
          pagination={true}
          hideSearch={true}
          rowData={motdStats.data}
          columns={columnData}
        />
      )}
      {motdStats.data && motdStats.data.length > 0 && visual === "chart" && (
        <div style={{ height: `${window.innerHeight * 0.55}px`}}>
          <Bar
            data={motdStats.chart}
            options={{
              plugins: {
                datalabels: window.innerWidth < 820 ? false : {
                  anchor: "end",
                  align: "end",
                  formatter: (value) => numberWithCommas(value),
                }
              },
              maintainAspectRatio: false,
              aspectRatio: 0.2,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: 10,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 12,
                      callback: (value) => numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />
        </div>
      )}
      {!(motdStats.data && motdStats.data.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={
            visual === "chart" ? (
              <AtlasIcon svgHref="atlas-chart-3" />
            ) : (
              <AtlasIcon svgHref="atlas-document-text" />
            )
          }
        />
      )}
    </>
  );
};

export default MOTDStats;
