export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_SUCCESS = "AJAX_CALL_SUCCESS";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
export const BEGIN_AJAX_CALL_BG = "BEGIN_AJAX_CALL_BG";
export const AJAX_CALL_SUCCESS_BG = "AJAX_CALL_SUCCESS_BG";
export const AJAX_CALL_ERROR_BG = "AJAX_CALL_ERROR_BG";
export const BEGIN_AJAX_CALL_DRIVE = "BEGIN_AJAX_CALL_DRIVE";
export const AJAX_CALL_SUCCESS_DRIVE = "AJAX_CALL_SUCCESS_DRIVE";
export const AJAX_CALL_ERROR_DRIVE = "AJAX_CALL_ERROR_DRIVE";
export const BEGIN_AJAX_CALL_IMPERSONATE = "BEGIN_AJAX_CALL_IMPERSONATE";
export const AJAX_CALL_SUCCESS_IMPERSONATE = "AJAX_CALL_SUCCESS_IMPERSONATE";
export const AJAX_CALL_ERROR_IMPERSONATE = "AJAX_CALL_ERROR_IMPERSONATE";
export const BEGIN_AJAX_CALL_TOKEN = "BEGIN_AJAX_CALL_TOKEN";
export const AJAX_CALL_TOKEN_SUCCESS = "AJAX_CALL_TOKEN_SUCCESS";
export const AJAX_CALL_TOKEN_ERROR = "AJAX_CALL_TOKEN_ERROR";

export const AUTH_USER = "AUTH_USER";
export const AUTH_ADMIN_LOGIN = "AUTH_ADMIN_LOGIN";
export const AUTH_ADMIN_LOGOUT = "AUTH_ADMIN_LOGOUT";

export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const STORE_REGISTER_PATH_FROM = "STORE_REGISTER_PATH_FROM";
export const STORE_PAYMENT_ID = "STORE_PAYMENT_ID";

export const FORGET_PASSWORD = "FORGET_PASSWORD";

export const CURRENT_USER_PROFILE = "CURRENT_USER_PROFILE";
export const UPDATE_CURRENT_USER_PROFILE = "UPDATE_CURRENT_USER_PROFILE";

// impersonate actions
export const CURRENT_SIGN_IN_PROFILE = "CURRENT_SIGN_IN_PROFILE";
export const UPDATE_CURRENT_SIGN_IN_PROFILE = "UPDATE_CURRENT_SIGN_IN_PROFILE";

export const FCM_STORE_USER_TOKEN = "FCM_STORE_USER_TOKEN";

export const STORE_PV_START_DATE = "STORE_PV_START_DATE";
export const STORE_PV_END_DATE = "STORE_PV_END_DATE";

export const STORE_SELECTED_AGENT_INFO = "STORE_SELECTED_AGENT_INFO";

export const GET_DATA_DICTIONARY = "GET_DATA_DICTIONARY";
export const GET_COUNTRY_DICTIONARY = "GET_COUNTRY_DICTIONARY";

// branches related actions
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_INVOICING_BRANCHES = "GET_INVOICING_BRANCHES";

// agents related actions
export const GET_ALL_AGENCIES = "GET_ALL_AGENCIES";

// app status
export const IS_FIRST_TIME_LOGIN = "IS_FIRST_TIME_LOGIN";
export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const UPDATE_APP_SETTING = "UPDATE_APP_SETTING";

// iqi drive related actions
export const IQI_DRIVE_GET_DIRECTORY = "IQI_DRIVE_GET_DIRECTORY";
export const STORE_IQI_DRIVE_DATA = "STORE_IQI_DRIVE_DATA";

// Subsales related Actions
export const STORE_SELECTED_SUBSALES = "STORE_SELECTED_SUBSALES";
export const STORE_WATERMARK = "STORE_WATERMARK";
export const STORE_SUBSALES_MESSAGE = "STORE_SUBSALES_MESSAGE";
export const STORE_SUBSALES_ADDRESS = "STORE_SUBSALES_ADDRESS";

//store table pagination and search params
export const STORE_LAST_VIEW = "STORE_LAST_VIEW";

//get my cart
export const GET_MY_CART = "GET_MY_CART";
export const UPDATE_CART_ITEM_QTY = "UPDATE_CART_ITEM_QTY";

//get permissions
export const GET_AA_PERMISSIONS = "GET_AA_PERMISSIONS";
export const GET_PA_PERMISSIONS = "GET_PA_PERMISSIONS";
export const GET_ADMIN_PERMISSIONS = `GET_ADMIN_PERMISSIONS`;
export const GET_PM_PERMISSIONS = `GET_PM_PERMISSIONS`;

export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

// get motd date
export const UPDATE_MOTD_OPEN_DATETIME = "UPDATE_MOTD_OPEN_DATETIME";

// New Feature Guide
export const FEATURE_GUIDE = "FEATURE_GUIDE";

// Team Sales Date
export const STORE_TEAM_SALES_DATE = "STORE_TEAM_SALES_DATE";

// Preferred Language
export const STORE_LANGUAGE = "STORE_LANGUAGE";
