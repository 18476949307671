import React, { useEffect } from "react";
import _ from "lodash";
import Moment from "moment";

import CustomSelect from "components/Select";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasCalendar from "components/Calendar/CompanyEvent";
import RoomBookingForm from "../RoomBooking/form";

import CalendarHOC from "../actions/calendar";
import AtlasIcon from "components/Icon/atlasIcon";

const BookingCalendar = ({
  branches,
  meetingRooms,
  calendarEvent,
  calendarBooking,
  meetingRoomsByBranch,
  storeForArrangedCalendarData,
  onLoadBookingCalendar,

  selectedBranch,
  showUpdateModal,
  selectedRoomBooking,
  selectedMeetingRoom,

  getMeetingRooms,
  getCalendarEvent,
  updateRoomBooking,
  getCalendarBooking,
  getSelectedRoomBooking,
  onChangeBookingCalendar,
}) => {
  useEffect(() => {
    getMeetingRooms();
    getCalendarBooking();
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(meetingRooms);
    temp = _.filter(temp, (room) => room.branch_id === selectedBranch);
    onChangeBookingCalendar(temp, "meetingRoomsByBranch");
  }, [selectedBranch]);

  useEffect(() => {
    reArrageCalendarData();
  }, [calendarBooking, selectedBranch, selectedMeetingRoom]);

  const reArrageCalendarData = (filter) => {
    let tmp = _.cloneDeep(calendarBooking);
    if (selectedBranch)
      tmp = _.filter(
        tmp,
        (data) => data.meeting_room_branch_id === selectedBranch,
      );
    if (selectedMeetingRoom)
      tmp = _.filter(
        tmp,
        (data) => data.meeting_room_id === selectedMeetingRoom,
      );
    if (filter)
      tmp = _.filter(tmp, (data) => data.event_subject.includes(filter));

    try {
      _.map(tmp, (dataItem) => {
        let dayOfStartDate = Moment(dataItem.start_date_time).date();
        let monthOfStartDate = Moment(dataItem.start_date_time).month();
        let dayOfEndDate = Moment(dataItem.end_date_time).date();
        let monthOfEndDate = Moment(dataItem.end_date_time).month();
        let lastDayOfMonth = Moment(dataItem.start_date_time)
          .endOf("month")
          .date();
        handleUndefinedArray(tmp, dataItem.date);
        for (
          let d = dayOfStartDate, m = monthOfStartDate;
          d <= dayOfEndDate && m <= monthOfEndDate;
          d++
        ) {
          if (!tmp[m][d]) {
            tmp[m][d] = [];
          }
          tmp[m][d].push(dataItem);
          if (d === lastDayOfMonth) {
            m++;
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    return onChangeBookingCalendar(tmp, "storeForArrangedCalendarData");
  };

  const handleUndefinedArray = (calendarData, date) => {
    if (calendarData[Moment(date).month()] === undefined) {
      calendarData[Moment(date).month()] = [];
    }
    if (calendarData[Moment(date).month()][Moment(date).date()] === undefined) {
      calendarData[Moment(date).month()][Moment(date).date()] = [];
    }
  };

  const renderAdditionalFilter = () => (
    <div className="at-calendar__month-filter">
      <CustomSelect
        selectItems={branches}
        valueKey={"id"}
        customStyle={{ minWidth: 150 }}
        placeholder={"Select Branch"}
        currentlySelected={
          selectedBranch
            ? _.find(branches, (branch) => branch.id === selectedBranch)
            : ""
        }
        updateSelect={(val) =>
          onChangeBookingCalendar(val.id, "selectedBranch")
        }
      />
      <CustomSelect
        selectItems={meetingRoomsByBranch}
        valueKey={"id"}
        customStyle={{ minWidth: 150 }}
        placeholder={"Select Meeting Room"}
        currentlySelected={
          selectedMeetingRoom
            ? _.find(
                meetingRoomsByBranch,
                (room) => room === selectedMeetingRoom,
              )
            : ""
        }
        updateSelect={(val) =>
          onChangeBookingCalendar(val.id, "selectedMeetingRoom")
        }
      />
    </div>
  );

  return (
    <>
      <AtlasCalendar
        data={calendarBooking}
        storeForArrangedEventData={storeForArrangedCalendarData}
        renderTooltip={(item) => (
          <div className="at-meeting-room__calendar">
            <label>{item.event_subject}</label>
            <div>
              <AtlasIcon svgHref={"atlas-clock"} />
              {Moment(item.start_time).format("hh:mmA")} -{" "}
              {Moment(item.end_time).format("hh:mmA")}
            </div>
            <div>
              <AtlasIcon svgHref={"atlas-location"} />
              {item.meeting_room_location}, {item.meeting_room_name}
            </div>
          </div>
        )}
        onClickEventDetails={(data) => getSelectedRoomBooking(data.id)}
        renderAdditionalFilter={renderAdditionalFilter}
        onToggleSearch={reArrageCalendarData}
      />
      {showUpdateModal && (
        <ModalDialog
          title={"Update Booking"}
          onLoad={onLoadBookingCalendar}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onChangeBookingCalendar(false, "showUpdateModal")}
          children={
            <RoomBookingForm
              mode={"update"}
              meetingRooms={meetingRooms}
              calendarEvent={calendarEvent}
              selectedRoomBooking={selectedRoomBooking}
              getCalendarEvent={getCalendarEvent}
              updateRoomBooking={updateRoomBooking}
              onClose={() => onChangeBookingCalendar(false, "showUpdateModal")}
            />
          }
        />
      )}
      {onLoadBookingCalendar && <LoadingModal />}
    </>
  );
};

export default CalendarHOC(BookingCalendar);
