import React from "react";
import {
  Dialog,
  DialogContent,
  SnackbarContent,
  TextField,
} from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/close";

const Delete = ({
  showDeleteModal,
  captcha,
  selectedDeleteSubsaleCode,
  selectedDeleteSubsaleId,
  onLoadDeleteSubsale,

  onChangeSubsalesHOC,
  onChangeDeleteHOC,
  onClickDeleteSubsale,
}) => {
  const renderCardContent = () => {
    return (
      <div>
        <SnackbarContent
          style={{ marginBottom: 20, backgroundColor: "red" }}
          message={"Unexpected bad things will happen if you don't read this!"}
        />
        <p className="mb-20">
          This action cannot be undone. This will permanently delete the listing
          and remove the listing from IQI Website ( if you have published the
          listing ) which you may already shared to social media.
        </p>
        <p className="mb-20">
          Please type
          <span style={{ fontWeight: 500 }}>
            {" "}
            {selectedDeleteSubsaleCode && selectedDeleteSubsaleCode}{" "}
          </span>
          to confirm.
        </p>
        <TextField
          variant={"outlined"}
          style={{ width: "100%", marginBottom: 20 }}
          fullWidth={true}
          value={captcha}
          onChange={(e) => onChangeDeleteHOC(e.target.value, "captcha")}
        />
        <button
          className="btn at-btn--primary mb-10"
          style={{ width: "100%" }}
          onClick={() => onClickDeleteSubsale(selectedDeleteSubsaleId, captcha)}
        >
          I understand the consequences, delete this listing
        </button>
      </div>
    );
  };

  return (
    <Dialog
      maxWidth={"lg"}
      classes={{ paper: "paper" }}
      style={{ padding: 20 }}
      open={showDeleteModal}
      fullWidth={true}
      onClose={() => onChangeSubsalesHOC(false, "showDeleteModal")}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
          marginBottom: 20,
        }}
      >
        <h4 className="at-card__title">Are you absolutely sure?</h4>
        <AtlasCloseButton
          containerStyle={{ display: "flex", marginLeft: "auto" }}
          onClick={() => onChangeSubsalesHOC(false, "showDeleteModal")}
        />
      </div>
      <DialogContent>{renderCardContent()}</DialogContent>
      {onLoadDeleteSubsale && <LoadingModal />}
    </Dialog>
  );
};

export default Delete;
