import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import ProjectTypeTypahead from "components/Typeahead/projectType";

import { Get, Post } from "utils/axios";
import {
  car_park_options,
  bedroom_options,
  bathroom_options,
} from "dictionary/subsales";
import { requestSuccess, requestError } from "utils/requestHandler";

import {
  categories as CategoriesDictionary,
  types,
  statuses,
  measurements,
  directions,
  occupancies,
  furnishing_statuses,
  tenure_types,
  title_types,
  unit_types,
} from "dictionary/subsales";

const SearchParams = (countries, groupType, currencies) => [
  {
    label: "Country",
    value: "country_id_eq",
    type: "typeahead",
    placeholder: 'All Country',
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: countries,
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "typeahead",
    placeholder: 'All State',
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Area",
    value: "township_cont",
    type: "typeahead",
    placeholder: 'All Area',
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Transaction Type",
    value: "type_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: 1, label: "Subsales" },
      { value: 2, label: "Subrent" },
    ],
  },
  {
    label: "Property Name",
    value: "property_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Type",
    value: "property_type_name_cont",
    param: "",
    type: "project-type",
    options: groupType,
    renderField: ({ item, index, searchParams, onChangeSearchParams }) => (
      <ProjectTypeTypahead
        selectedValue={item.param ? [item.param] : []}
        options={item.options || []}
        multiple={false}
        onSelect={(val) => {
          if (val && val.length > 0) {
            let tmp = _.cloneDeep(searchParams);
            tmp[index].param = val[0];
            return onChangeSearchParams(tmp);
          } 
        }}
        onSearch={(val) => { 
          let tmp = _.cloneDeep(searchParams);
          tmp[index].param = val;
          return onChangeSearchParams(tmp);
        }}
        filterBy={["name"]}
      />
    ),
  },
  {
    label: "Currency",
    value: "asking_price_currency_eq",
    type: "select",
    withoutPrefix: true,
    param: "",
    options: currencies,
  },
  {
    label: "Minimum Price",
    value: "asking_price_cents_lteq",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Maximum Price",
    value: "asking_price_cents_gteq",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Bedroom",
    value: "bedrooms_in",
    type: "select-multi",
    param: [],
    options: bedroom_options,
  },
  {
    label: "Carpark",
    value: "car_parks_in",
    type: "select-multi",
    param: [],
    options: car_park_options,
  },
  {
    label: "Bathroom",
    value: "bathrooms_in",
    type: "select-multi",
    param: [],
    options: bathroom_options,
  },
  {
    label: "Measurement",
    value: "measurement_id_eq",
    type: "select",
    param: "",
    options: [
      { value: 1, label: "Squarefeet" },
      { value: 2, label: "Squaremeter" },
      { value: 3, label: "Acres" },
      { value: 4, label: "Hectare" },
    ],
  },
  {
    label: "Built up size Less than",
    value: "built_up_lteq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Greater than",
    value: "built_up_gteq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Listing Code",
    value: "code_cont",
    type: "input",
    param: "",
  },
];

const StatusLib = [
  { id: 1, label: "Not Sent" },
  { id: 2, label: "Sent" },
  { id: 3, label: "Approved" },
  { id: 4, label: "Rejected" },
  { id: 5, label: "Cancelled" },
  { id: 6, label: "New Commission Rate" },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showDetailsModal: false,
      showRequestConfirmation: false,

      listings: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedListing: null,
      listingPages: [0],

      estateListingIndexSearch: {
        currentPage: 1,
        searchParams: "",
      },
      estatetListingIndexSearchParams: [],
    };

    componentDidMount = () => {
      const { countries, currencies } = this.props.data.dictionaryReducer;
      let tempGroup = [];

      CategoriesDictionary.map(({ group_types }) => {
        group_types.map((item) => {
          tempGroup.push(...item.children);
        });
      });

      this.setState({
        estatetListingIndexSearchParams: SearchParams(
          countries,
          tempGroup,
          currencies.name || [],
        ),
      });
    };

    load = (param) => this.setState({ loading: param });

    onChangeListingHOC = (val, context) => this.setState({ [context]: val });

    getListings = (page, search) => {
      this.setState(
        {
          estateListingIndexSearch: {
            currentPage: page,
            searchParams: search,
          },
        },
        () => {
          let tempSearch = _.cloneDeep(search);
          if (tempSearch.includes("q[country_id_eq]")){
            let countryIndex = _.findIndex(this.state.estatetListingIndexSearchParams, { value: "country_id_eq" });
            tempSearch = tempSearch.replace(
              `q[country_id_eq]=${this.state.estatetListingIndexSearchParams[countryIndex].param.name}`,
              `q[country_id_eq]=${this.state.estatetListingIndexSearchParams[countryIndex].param.id}`);
          }
    
          if (tempSearch.includes("q[state_id_eq]")){
            let stateIndex = _.findIndex(this.state.estatetListingIndexSearchParams, { value: "state_id_eq" });
            tempSearch = tempSearch.replace(
              `q[state_id_eq]=${this.state.estatetListingIndexSearchParams[stateIndex].param.name}`,
              `q[state_id_eq]=${this.state.estatetListingIndexSearchParams[stateIndex].param.id}`);
          }

          Get(
            `/sub_sales/open_for_co_broke?${tempSearch}page=${page} `,
            this.getListingsSuccess,
            this.getListingsError,
            this.load,
          )
        }
      );
    };
    getListingsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tempRequestId =
            item.co_broke_requests_info.length > 0
              ? item.co_broke_requests_info[0].status_id
              : 1;
          let tmpType = _.find(types, { id: item.type_id });
          let tmpCategory = _.find(CategoriesDictionary, {
            id: item.category_id,
          });
          let tempStatus = _.find(statuses, { id: item.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempTitleType = _.find(title_types, { id: item.title_type_id });
          let tempDirection = _.find(directions, { id: item.direction_id });
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, { id: item.occupancy_id });
          let tempTenure = _.find(tenure_types, { id: item.tenure_id });
          let tempUnitType = _.find(unit_types, { id: item.unit_type_id });

          let featureArray = [
            {
              label: "Direction",
              value:
                tempDirection &&
                tempDirection.name &&
                tempDirection.name.toLowerCase() !== "unknown"
                  ? tempDirection.name
                  : "N/A",
            },
            {
              label: "Occupancy",
              value:
                tempOccupancy &&
                tempOccupancy.name &&
                tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure &&
                tempTenure.name &&
                tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
            {
              label: "Unit Type",
              value:
                tempUnitType &&
                tempUnitType.name &&
                tempUnitType.name.toLowerCase() !== "unknown"
                  ? tempUnitType.name
                  : "N/A",
            },
            {
              label: "Title Type",
              value:
                tempTitleType &&
                tempTitleType.name &&
                tempTitleType.name.toLowerCase() !== "unknown"
                  ? tempTitleType.name
                  : "N/A",
            },
          ];
          let data = {
            ...item,
            featureArray: featureArray,
            type: tmpType && tmpType.name ? tmpType.name : "N/A",
            property:
              tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
                ? tmpCatGroupPropertyType.name
                : "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
            requestStatusId: tempRequestId,
            requestStatus: _.find(
              StatusLib,
              (item) => tempRequestId === item.id,
            ).label,
          };
          tempData.push(data);
        });
      payload.data = tempData;
      this.setState({
        listings: payload,
        listingPages: tmpTotalPages,
      });
    };
    getListingsError = (error) => requestError(error);

    sendRequest = (dataToSubmit) =>
      Post(
        `/co_broke_requests`,
        dataToSubmit,
        this.sendRequestSuccess,
        this.sendRequestError,
        this.load,
      );
    sendRequestSuccess = () => {
      const { currentPage, searchParams } = this.state.estateListingIndexSearch;
      this.getListings(currentPage, searchParams);
      this.setState({ showRequestConfirmation: false });

      requestSuccess(
        `The Co-broke Request has been sent successfully. Please wait for the Listing Agent to 
        either accept or decline your request. You can check the status of your requests in the Sent Request section of the Co-broke 
        Centre. You may also click on their contact details to get in touch with them directly.`,
      );
    };
    sendRequestError = (error) => requestError(error);

    getSelectedTownShip = (state_id) =>
      Get(
        `/townships${state_id ?`?state_id=${state_id}` : ""}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) => {
      let temp = [];
      let tempSearch = _.cloneDeep(this.state.estatetListingIndexSearchParams);
      let townShipIndex = _.findIndex(tempSearch, { value: "township_cont" });
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = {
            id: item.id,
            value: item.name,
            name: item.name,
            label: item.name,
          };
          temp.push(tempObj);
        });
      tempSearch[townShipIndex].options = temp;
      this.setState({ estatetListingIndexSearchParams: tempSearch });
    };
    getSelectedTownShipError = (error) => requestError(error && error);

    getAllStates = () => 
      Get(
        `/states`,
        this.getAllStatesSuccess,
        this.getAllStatesError,
        () => {},
      );
    getAllStatesSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.estatetListingIndexSearchParams);
      let stateIndex = _.findIndex(temp, { value: "state_id_eq" });
      temp[stateIndex].options = payload.states?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      this.setState({ estatetListingIndexSearchParams: temp });
    }
    getAllStatesError = (error) => requestError(error);

    getAllTownShips = (name) =>
      Get(
        `/townships${name ? `?q[name_cont]=${name}` : ""}`,
        this.getAllTownShipsSuccess,
        this.getAllTownShipsError,
        () => {},
      );
    getAllTownShipsSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.estatetListingIndexSearchParams);
      let townShipIndex = _.findIndex(temp, { value: "township_cont" });
      temp[townShipIndex].options = payload?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      this.setState({ estatetListingIndexSearchParams: temp });
    }
    getAllTownShipsError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          listings={this.state.listings}
          listingPages={this.state.listingPages}
          onLoadListing={this.state.loading}
          showRequestConfirmation={this.state.showRequestConfirmation}
          showDetailsModal={this.state.showDetailsModal}
          selectedListing={this.state.selectedListing}
          estateListingIndexSearch={this.state.estateListingIndexSearch}
          estatetListingIndexSearchParams={this.state.estatetListingIndexSearchParams}
          sendRequest={this.sendRequest}
          getListings={this.getListings}
          getAllStates={this.getAllStates}
          getAllTownShips={this.getAllTownShips}
          getSelectedTownShip={this.getSelectedTownShip}
          onChangeListingHOC={this.onChangeListingHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
