import FormInputs from "../formInputs";

const InternationalPersonalInfoForm = ({
  branch_option,
  branch_name,
  onChangeField,
  updateNewAgent,
}) => {
  const inputConfig = [
    {
      label: "Display Name",
      id: "display_name",
      placeholder: "Subsales listing personal display name",
      required: true,
      isReplaceNumeric: true,
    },
    {
      type: "select",
      label: "Gender",
      id: "gender_id",
      required: true,
      options: [
        { id: 1, value: "Male" },
        { id: 2, value: "Female" },
        { id: 3, value: "Other" },
      ],
    },
    {
      type: "date",
      label: "Date of Birth",
      id: "date_of_birth",
      required: true,
    },
    branch_name
    ? {
        type: "custom-output",
        label: "Branch Name",
        id: "branch_name",
        content: () => <p>{branch_name}</p>,
        disabled: true,
      }
    : {
        type: "select",
        label: "Branch Name",
        id: "branch_id",
        options: branch_option.map(option => ({ ...option, value: option.name })),
      },
  ];

  return (
    <>
      <p className={"mb-4"}>
        Please complete your basic information stated below.
      </p>
      <div className={"container-fluid p-0"}>
        <FormInputs
          payload={updateNewAgent}
          inputConfig={inputConfig}
          onChange={onChangeField}
        />
      </div>
    </>
  );
};

export default InternationalPersonalInfoForm;
