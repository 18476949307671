import React, { useState, useEffect, useCallback } from "react";

import NameCardContent from "./NameCardContent";
import ProfileContent from "./ProfileContent";
import AtlasImageEditor from "components/ImageEditor/index";
import ModalDialog from "components/Modal/ModalDialog";

import { convertImgToBase64URL } from "utils/imgBase64";

const PersonalInfo = ({
  from,
  updateAgentInfo,
  displayNameError,
  currentAddressError,
  nameCardDisplayNameError,
  emergencyPersonNameError,
  emergencyContactError,
  onLoadUpdateProfile,

  role,
  onChangeState,
  onClickUpdateProfileInfo,
  onChangeUpdateAgent,
  onClickClose,
  onChangeProfileHOC,
}) => {
  const disableSubmit =
    from === "Profile"
      ? (role !== "Agent" || role !== "Associated Agent")
        ? updateAgentInfo.display_name === ""
        : updateAgentInfo.display_name === "" ||
          updateAgentInfo.current_address === "" ||
          updateAgentInfo.agent_registration_attributes
            .emergency_person_name === "" ||
          updateAgentInfo.agent_registration_attributes
            .emergency_contact_number === ""
      : updateAgentInfo.name_card_attributes.name_card_display_name === "";
  const [showEditor, onToggleEditor] = useState(false);
  const [selectedEdit, onChangeEdit] = useState(null);
  const [tempFile, onChangeFile] = useState(null);

  useEffect(() => {
    if (selectedEdit) {
      onToggleEditor(true);
      onChangeProfileHOC(false, "loading");
    }
  }, [selectedEdit]);

  useEffect(() => {
    !showEditor && onChangeEdit(null);
  }, [showEditor]);

  const onHandleEdit = useCallback((file) => {
    onChangeProfileHOC(true, "loading");
    onChangeFile(file);
    if (!file) {
      if (from === "Profile") {
        onChangeUpdateAgent(file, "avatar_url")
      } else {
        onChangeUpdateAgent(file, "name_card_attributes", null, "photo")
      }
      onChangeProfileHOC(false, "loading");
    } else {
      convertImgToBase64URL(file, (val) => onChangeEdit(val), "img/png");
    }
  }, []);

  let onHandleAppendImage = useCallback(
    (file) => {
      if (from === "Profile") {
        onChangeUpdateAgent(file, "avatar_url");
      } else {
        onChangeUpdateAgent(file, "name_card_attributes", null, "photo");
      }
    },
    [from]
  );

  return (
    <>
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        {from === "Profile" && (
          <ProfileContent
            role={role}
            updateAgentInfo={updateAgentInfo}
            displayNameError={displayNameError}
            currentAddressError={currentAddressError}
            emergencyPersonNameError={emergencyPersonNameError}
            emergencyContactError={emergencyContactError}
            onChangeUpdateAgent={onChangeUpdateAgent}
            onHandleEdit={onHandleEdit}
          />
        )}
        {from === "NameCard" && (
          <NameCardContent
            updateAgentInfo={updateAgentInfo}
            nameCardDisplayNameError={nameCardDisplayNameError}
            onChangeUpdateAgent={onChangeUpdateAgent}
            onHandleEdit={onHandleEdit}
            onChangeState={onChangeState}
          />
        )}
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          disabled={disableSubmit}
          className="btn-new btn-new--success"
          onClick={() => onClickUpdateProfileInfo()}
        >
          Update
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClickClose()}
        >
          Close
        </button>
      </div>
      {showEditor && (
        <ModalDialog
          title={"Image Editor"}
          onLoad={onLoadUpdateProfile}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onToggleEditor(false)}
          children={
            <AtlasImageEditor
              aspectRatio={from === "Profile" ? 1 : 9 / 16}
              onClickSave={(file) => {
                onToggleEditor(false);
                onHandleAppendImage(file);
              }}
              onClickProceedWithoutCropping={() => {
                onToggleEditor(false);
                onHandleAppendImage(tempFile);
              }}
              imagePath={selectedEdit}
              imageFile={"temp"}
              ImageEditorTheme={{
                "header.display": "none",
                "submenu.normalIcon.color": "#8a8a8a",
                "submenu.activeIcon.color": "#e9e9e9",
              }}
              ImageEditorMenu={["crop"]}
            />
          }
        />
      )}
    </>
  );
};

export default PersonalInfo;
