import _ from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";

import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const SetAppointment = ({
  onLoadCobroke,
  selectedCobroke,

  onChangeCobrokeHOC,
  updateProjectSalesCycle,
}) => {
  const [appointment, onChangeAppointment] = useState({
    appointment_date: "",
    contact_name: "",
    contact_mobile_number: "",
    contact_email: "",
  });

  useEffect(() => {
    if (
      selectedCobroke.project_sale_cycle &&
      selectedCobroke.project_sale_cycle.appointment_date
    ) {
      onChangeAppointment({
        appointment_date: Moment(
          selectedCobroke.project_sale_cycle.appointment_date,
        ).format("YYYY-MM-DDTHH:mm"),
        contact_name: selectedCobroke.project_sale_cycle.contact_name,
        contact_mobile_number:
          selectedCobroke.project_sale_cycle.contact_mobile_number,
        contact_email: selectedCobroke.project_sale_cycle.contact_email,
      });
    }
  }, []);

  const onChangeField = (val, context) => {
    let temp = _.clone(appointment);
    temp[context] = val;
    onChangeAppointment(temp);
  };

  return (
    <>
      <ModalDialog
        title={"Set Appointment"}
        children={
          <>
            <p className="my-3">
              Explain why request agent need to fill in the contact information
              here and etc.
            </p>
            <div className="grid-control">
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Appointment Date</h2>
                <div className="at-form-input">
                  <input
                    className="at-form-input__input"
                    type="datetime-local"
                    format={"DD MMM YYYY, HH:mm"}
                    value={appointment.appointment_date}
                    onChange={(e) =>
                      onChangeField(e.target.value, "appointment_date")
                    }
                  />
                </div>
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Contact Name</h2>
                <CustomFormInput
                  type="text"
                  value={appointment.contact_name}
                  onChangeValue={(val) => onChangeField(val, "contact_name")}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Contact Mobile Number</h2>
                <p className="fs-2 mb-2">
                  This information will not be shared with the project partner
                </p>
                <CustomFormInput
                  type="text"
                  value={appointment.contact_mobile_number}
                  onChangeValue={(val) =>
                    onChangeField(val, "contact_mobile_number")
                  }
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Contact Email</h2>
                <p className="fs-2 mb-2">
                  This information will not be shared with the project partner
                </p>
                <CustomFormInput
                  type="text"
                  value={appointment.contact_email}
                  onChangeValue={(val) => onChangeField(val, "contact_email")}
                />
              </section>
            </div>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              className="btn-new btn-new--primary"
              disabled={_.values(appointment).some((x) => !x || x === "")}
              onClick={() =>
                updateProjectSalesCycle({
                  status_id: 2,
                  id: selectedCobroke.project_sale_cycle.id,
                  appointment_date: appointment.appointment_date,
                  contact_name: appointment.contact_name,
                  contact_mobile_number: appointment.contact_mobile_number,
                  contact_email: appointment.contact_email,
                })
              }
            >
              Save
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangeCobrokeHOC(false, "showSetAppointment")}
            >
              Close
            </button>
          </div>
        }
        onClose={() => onChangeCobrokeHOC(false, "showSetAppointment")}
      />
      {onLoadCobroke && <LoadingModal />}
    </>
  );
};

export default SetAppointment;
