import React, { Component, createRef } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import SideBarIcon from "components/Icon/sideBarIcon";
import CustomFormInput from "components/Input/formInput";
import CustomNewAccordion from "./newAccordion";

import { SidebarMenuData } from "./routingData/agent";
import { AdminRoutingData } from "./routingData/admin";
import { PMRoutingData } from "./routingData/projectManager";
import { PARoutingData } from "./routingData/personalAssistant";
import { SuperAdminRoutingData } from "./routingData/superadmin";
import { AssociatedAgentRoutingData } from "./routingData/associatedAgent";

import MotdHOC from "actions/motd";
import packageJSON from "../../../package.json";
import IQILogo from "assets/images/iqi_logo_only.png";
import { getTranslation } from "assets/translation";
import { getItem } from "utils/tokenStore";

import "stylesheets/components/sidebar/index.scss";

const HideMyPA = [{ name: "My PA" }];

const HiddenInactiveMenu = [{ name: "IQI Academy" }, { name: "My PA" }];

const iconGradients = [
  {
    deg: 15,
    stops: [
      { offset: "21.93%", stopColor: "#FF7E5A" },
      { offset: "99.47%", stopColor: "#C53617" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "35.64%", stopColor: "#FFB018" },
      { offset: "89.57%", stopColor: "#EC5D0D" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "32.77%", stopColor: "#61DAA7" },
      { offset: "93.31%", stopColor: "#0A8449" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "35.2%", stopColor: "#8EB2FF" },
      { offset: "98.52%", stopColor: "#2657C0" },
    ],
  },
];

class Sidebar extends Component {
  state = {
    filteredPAMenu: [],
    filteredAAMenu: [],
    filteredAdminMenu: [],
    filterPMMenu: [],
    filteredSearchMenu: [],
    searchKeyword: "",
    showTempInfo: true,
    selectionParentMenu: "",
    activeParentMenu: "",
    active_link_menu: "",
  };
  inputRef = createRef();

  componentDidUpdate = (pp) => {
    const { role, supervisor_designation } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (
      this.props.data.permissionsReducer !== pp.data.permissionsReducer ||
      this.props.data.appSettingReducer !== pp.data.appSettingReducer
    ) {
      if (role === "Personal Assistant") {
        const tempFilter = [];
        const tempRead = _.filter(
          this.props.data.permissionsReducer.pa_permissions,
          (o) => o.can_read
        );
        if (
          [
            "VP",
            "Group VP",
            "HOT",
            "Team Leader",
            "Leader",
            "Team Manager",
          ].indexOf(supervisor_designation.job_title) > -1
        ) {
          tempRead.push({
            module_id: 99,
            module_name: "Leader Team Sales Ranking",
          });
        }
        PARoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", tempRead, menuData);
          temp && tempFilter.push(temp);
        });
        this.setState({ filteredPAMenu: tempFilter });
      } else if (role === "Associated Agent") {
        const tempFilter = [];
        const tempRead = _.filter(
          this.props.data.permissionsReducer.aa_permissions,
          (o) => o.can_read
        );

        AssociatedAgentRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", tempRead, menuData);
          temp && tempFilter.push(temp);
        });
        this.setState({ filteredAAMenu: tempFilter });
      } else if (role === "Admin") {
        const tempFilter = [];
        const tempRead = _.filter(
          this.props.data.permissionsReducer.admin_permissions,
          (o) => o.can_read
        );
        AdminRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", tempRead, menuData);
          temp && tempFilter.push(temp);
        });
        this.setState({ filteredAdminMenu: tempFilter });
      } else if (role === "Project Manager") {
        const tempFilter = [];
        const tempRead = _.filter(
          this.props.data.permissionsReducer.pm_permissions,
          (o) => o.can_read
        );

        PMRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", tempRead, menuData);
          temp && tempFilter.push(temp);
        });
        this.setState({ filterPMMenu: tempFilter });
      }
    }
  };

  processItemToShow = (itemChild) => {
    const { role, status_id, is_superior } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role !== "Agent") return false;
    else if (status_id === 2)
      return _.differenceBy(itemChild, HiddenInactiveMenu, "name");
    else if (!is_superior) return _.differenceBy(itemChild, HideMyPA, "name");
    else return itemChild;
  };

  onSelectMenu = (param, url, href) => {
    if (href) {
      if (param === "IQI Academy") {
        return this.props.getSSOToken("https://academy.iqiglobal.com");
      } else {
        this.props.getMOTD();
        return window.open(href, "_blank");
      }
    }
    this.setState({ active_link_menu: param, activeParentMenu: "" });
    return this.props.history.push(`${this.props.idPath}${url}`);
  };

  filterRoutingData = (mode, tempRead, menuData) => {
    if (menuData.children && !menuData.id) {
      const tempContent = [];

      menuData.children.forEach((menu) => {
        const temp = this.filterRoutingData(mode, tempRead, menu);

        temp && tempContent.push(temp);
      });

      if (tempContent.length > 0) {
        return {
          ...menuData,
          children: tempContent,
        };
      }
      return false;
    }

    const tempModules = _.find(tempRead, (menu) => {
      if (mode === "access") {
        if (typeof menuData.id === "number") {
          return menu.module_id === menuData.id;
        } else {
          return menuData.id ? menuData.id.includes(menu.module_id) : true;
        }
      } else if (mode === "forbidden") {
        return menu.name !== menuData.name;
      } else if (mode === "search" && menuData.name) {
        return getTranslation(menuData.key, this.props.data.languageReducer.language)?.toLowerCase()?.includes(menu.name.toLowerCase());
      }
    });

    return tempModules ? menuData : false;
  };

  onHandleAgentMenu = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate") > -1 
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const token = window.location.href.indexOf("/admin-impersonate") > -1
      ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
      : getItem("IQI_ATLAS_JWT_TOKEN");
    const AgentRoutingData = _.cloneDeep(SidebarMenuData(token));

    if (
      [
        "VP",
        "Group VP",
        "HOT",
        "Team Leader",
        "Leader",
        "Team Manager",
      ].indexOf(tmpProfileReducer.job_title_name) > -1
    ) {
      return AgentRoutingData;
    }

    const tempFilter = [];

    AgentRoutingData?.forEach((menuData) => {
      const temp = this.filterRoutingData(
        "forbidden",
        [{ name: "Leader Team Sales Ranking" }],
        menuData
      );
      temp && tempFilter.push(temp);
    });
    return tempFilter;
  };

  assignRoutingData = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    switch (tmpProfileReducer.role) {
      case "Super Admin":
        return SuperAdminRoutingData;
      case "Admin":
        return this.state.filteredAdminMenu;
      case "Agent":
        return this.onHandleAgentMenu();
      case "Personal Assistant":
        return this.state.filteredPAMenu;
      case `Associated Agent`:
        return this.state.filteredAAMenu;
      case "Project Manager":
        return this.state.filterPMMenu;

      default:
        return [];
    }
  };

  onChangeSearchValue = (val, routingData) => {
    const tempFilter = [];
    const tempRoutingData = _.cloneDeep(routingData);
    const searchSelectionParentMenu = [];
    this.setState({ searchKeyword: val }, () => {
      if (val.length > 0) {
        tempRoutingData.forEach((menuData) => {
          const temp = this.filterRoutingData(
            "search",
            [{ name: val }],
            menuData
          );
          if (temp) {
            tempFilter.push({ ...temp, open: true });
            searchSelectionParentMenu.push(temp.name);
          }
        });
        this.setState({
          filteredSearchMenu: tempFilter,
          selectionParentMenu: searchSelectionParentMenu,
        });
      } else {
        this.setState({
          filteredSearchMenu: routingData,
          selectionParentMenu: [],
        });
      }
    });
  };

  renderMenuItem = (item, index) => {
    const { activeParentMenu, selectionParentMenu } = this.state;
    const { idPath, history, cartCount, data } = this.props;
    const isActivateLinkMenu = this.state.active_link_menu === item.name;

    if (item.children) {
      return (
        <CustomNewAccordion
          key={`${item.name}-${index}`}
          expand={selectionParentMenu.includes(item.name)}
          data={data}
          item={item}
          history={history}
          idPath={idPath}
          cartCount={cartCount}
          searchKeyword={this.state.searchKeyword}
          itemToShow={this.processItemToShow(item.children)}
          activeParentMenu={activeParentMenu}
          selectionParentMenu={selectionParentMenu}
          onSeletedMenu={(key, val) => this.setState({ [key]: val })}
          onCloseMenu={() => {}}
          currentSelected={item.children.find((o) =>
            this.props.history.location.pathname.endsWith(o.navigateTo)
          )}
          getSSOToken={this.props.getSSOToken}
        />
      );
    } else {
      return (
        <button
          key={item.name}
          className={`justify-content-start at-sidebar_link_menu-cont
            ${isActivateLinkMenu ? "router-link-exact-active active" : ""}`}
          onClick={() =>
            this.onSelectMenu(item.name, item.navigateTo, item.href)
          }
        >
          {isActivateLinkMenu && (
            <AtlasIcon svgHref={item.icon} className={"at-sidebar__icon"} />
          )}
          {!isActivateLinkMenu && (
            <SideBarIcon
              svgHref={item.icon}
              className={"at-sidebar__icon"}
              gradient={item.gradient}
            />
          )}
          <span className={"at-sidebar__name"}>{getTranslation(item.key, data.languageReducer.language) || item.name}</span>
          <div className="at-sidebar__list-backdrop"></div>
        </button>
      );
    }
  };

  addGradient = (menu) => {
    let x = 0;
    return menu.map((item) => {
      let newMenu = { ...item, gradient: iconGradients[x] };
      if (item.children) {
        let y = x;
        let newChild = [];
        y = (y < 3) ? (y + 1) : 0;
        item.children.map((child) => {
          newChild.push({ ...child, gradient: iconGradients[y] });
          y = (y < 3) ? (y + 1) : 0;
        });
        x = (x < 3) ? (x + 1) : 0;
        newMenu = { ...newMenu, children: newChild };
      } else {
        x = (x < 3) ? (x + 1) : 0;
      }
      return newMenu;
    });
  };

  render = () => {
    const { expand } = this.props;
    const tempRoutingData = this.addGradient(this.assignRoutingData());

    return (
      <div className={`at-sidebar ${expand ? "" : "hide-sidebar"}`}>
        <div className="at-sidebar__avatar-cont" style={{ margin: 15 }}>
          <img src={IQILogo} alt={"user avatar icon"} />
          <h4>
            ATLAS
            <p style={{ fontSize: 11 }}>{`Version ${packageJSON.version}`}</p>
          </h4>
        </div>
        <CustomFormInput
          type="text"
          inputRef={this.inputRef}
          className={"at-sidebar__search"}
          containerStyle={{ marginBottom: 16 }}
          value={this.state.searchKeyword}
          showClearButton={true}
          onChangeValue={(val) =>
            this.onChangeSearchValue(val, tempRoutingData)
          }
          prefix={
            <SideBarIcon
              svgHref={"atlas-search-normal"}
              style={{ width: 18, height: 18 }}
            />
          }
          placeholder={getTranslation("label.search_menu_keyword", this.props.data.languageReducer.language)}
        />

        <div className="at-sidebar__sidebar">
          {this.state.searchKeyword.length > 0 && (
            <>
              {this.state.filteredSearchMenu.length > 0 &&
                this.state.filteredSearchMenu.map((item, index) => {
                  return this.renderMenuItem(item, index);
                })}
              {!this.state.filteredSearchMenu.length > 0 && (
                <span className="at-sidebar__no-result">No results found</span>
              )}
            </>
          )}
          {!this.state.searchKeyword.length > 0 &&
            tempRoutingData.map((item, index) => {
              return this.renderMenuItem(item, index);
            })}
        </div>
      </div>
    );
  };
}

export default MotdHOC(Sidebar);
