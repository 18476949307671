import React, { Component } from "react";
import { compose } from "redux";

import AtlasIcon from "components/Icon/atlasIcon";
import AtlasTable from "components/NewTable";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";

import TownShipHOC from "actions/township";
import { ClaimDetails } from "./assets"
import DefaultImage from "assets/images/av-male.jpg";

class SubsalesClaim extends Component {
  componentDidMount = () => {
    if(this.props.subsalesClaim.property_location.state_id) {
      this.props.getSelectedTownShip(this.props.subsalesClaim.property_location.state_id);
    }
  };
  componentDidUpdate = prevProps => {
    if(prevProps.subsalesClaim.property_location.state_id !== this.props.subsalesClaim.property_location.state_id && this.props.subsalesClaim.property_location.state_id) {
      this.props.getSelectedTownShip(this.props.subsalesClaim.property_location.state_id);
    }
  }

  renderTableHeading = section => {
    const { subsalesClaim, ContactPartyTypes } = this.props

    let tmpContactPartyType = ContactPartyTypes?.find(contactParty => contactParty.id === (this.props.subsalesClaim.representative_id === 1 ? 2 : 1) )
    return (
      <>
        <div className="at-table_heading">
          <div className="d-flex grid_gap-2 align-items-center flex-wrap">
            <span className="at-icon_svg-table_heading-title">
              <AtlasIcon svgHref={section.icon}/>
            </span>
            <div className="at-form__label">
              <p className="at-form__label-title">{section.renderTitle(subsalesClaim)}</p>
            </div>
          </div>
          {
            section.description && (
              <AlertBox
                title={""}
                disabledClose={true}
                containerClassName="w-100"
                mode={"info"}
                description={section.description}
              />
            )
          }
        </div>
        {
          section.canCreate && (
            <div className="at-table_heading">
              <div className="w-100 d-flex grid_gap-1 flex-wrap">
                <CustomButton
                  className="btn-new btn-new--secondary"
                  disabled={this.props.subsalesClaim.is_locked}
                  onClick={() => {
                    Promise.all([
                      this.props.onChangeAccessCaseFile( `${tmpContactPartyType.id}`, "newTypeId" )
                    ]).then( () => {
                      this.props.onChangeAccessCaseFile( `${tmpContactPartyType.id}`, "paticipantTypeId" )
                    })
                    this.props.onChangeOppositeParticipantHOC(true, "showCreateParticipant" )
                  }}>
                    +<span className="ml-1">Add {this.props.subsalesClaim.opposite_representative_party ?? "N/A"}</span>
                  </CustomButton>
                  
                <CustomButton
                  className="btn-new btn-new--secondary"
                  disabled={this.props.subsalesClaim.is_locked}
                  onClick={() => {
                    Promise.all([
                      this.props.onChangeAccessCaseFile( `${tmpContactPartyType.lawFirmId}`, "newTypeId" )
                    ]).then( () => {
                      this.props.onChangeAccessCaseFile( `${tmpContactPartyType.id}`, "paticipantTypeId" )
                    })
                    this.props.onChangeOppositeParticipantHOC(true, "showCreateParticipant")
                  }}>
                    +<span className="ml-1" >Add Law Firm</span>
                  </CustomButton>
              </div>
            </div>
          )
        }
      </>
    )
  }

  render = () => {
    const { subsalesClaim, getOppositePartyDetails } = this.props
    const {
      avatar_url,
      confirmation_form_number,
      opposite_representative_party,
    } = subsalesClaim
    return (
      <>
        <div 
          className="d-flex flex-column align-items-center justify-content-center" 
          style={{ maxWidth: 1000 }}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="at-party-title_info">{confirmation_form_number || "N/A"}</p>
            <p className="fs-2 mt-3 text-center">Please review the information below for the specified case form stated above.</p>
            <p className="fs-2 text-center">Your assistance is needed to update the information for {opposite_representative_party?.toLowerCase()}s. If this is your case form and the booking and property details are correct,</p>
            <p className="fs-2 text-center">simply navigate to the "{opposite_representative_party}s" section and choose either "Add" or "Edit" to modify the necessary details correctly.</p>
          </div>
          <div>
            {
              ClaimDetails.map( section => {
                if(section.type === "listing") {
                  return (
                    <div key={section.title} className="at-form__content at-new_container-16 mt-4">
                      <div className="at-form__label">
                        <div className="at-form__label-icon">
                          <AtlasIcon svgHref={section.icon}/>
                        </div>
                        <div className="at-form__label-title">
                          { section.title }
                        </div>
                      </div>
                      {
                        section.title === "Agent" && (
                          <div className="at-form_fields_cont">
                            <div className="at-form_field-col-2"
                              style={{
                                maxWidth: 120,
                              }}>
                              <img alt="avatar" 
                                src={ avatar_url || DefaultImage }
                                style={{ width: 120, height: 120, borderRadius: "50%" }}
                                onError={(e) => {
                                  if(e.target) {
                                    e.target.src = DefaultImage;
                                  }
                                }}/>
                            </div>
                            <div className="at-form_field-col-10"
                              style={{
                                maxWidth: "calc(100% - 120px)"
                              }}>
                              {
                                section.listing_props.map( prop => {
                                  const {
                                    value = "",
                                    className = "",
                                    icon = "",
                                    typoClassName = "",
                                  } = prop
                                  return value && (
                                    <div key={prop.value} className={`d-flex flex-wrap grid_gap-1 at-access_file-party-agent_info ${className || ""}`}>
                                      { icon && <AtlasIcon svgHref={icon}/> }
                                      <p className={ typoClassName || "" }>
                                        { section?.objValue 
                                          ? subsalesClaim?.[section.objValue]?.[value] ?? "N/A"
                                          : subsalesClaim?.[value] ?? "N/A"
                                        }
                                      </p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                      }
                      {
                        (section.title !== "Agent" && section.listing_props) && (
                          <div key={section.title} className="at-access_file-party-info_listing-cont at-form_fields_cont">
                            {
                              section.listing_props.map( prop => {
                                const {
                                  label = "",
                                  value = "",
                                  className = "",
                                  typoClassName = "",
                                } = prop
                                return (
                                  <div key={prop.value} className={`${className || ""} at-access_file-party-info_listing at-form_field-col-4`}>
                                    <p className={ typoClassName || "at-form-input__title" }>{ label }</p>
                                    <p className={ typoClassName || "at-new_typograph" }>
                                      { 
                                        typeof prop?.renderValue !== "function" && (
                                          section?.objValue 
                                            ? subsalesClaim?.[section.objValue]?.[value] ?? "N/A"
                                            : subsalesClaim?.[value] ?? "N/A"
                                        )
                                      }
                                      {
                                        typeof prop?.renderValue === "function" && prop.renderValue({
                                          ...this.props.data.dictionaryReducer,
                                          township: this.props.township,
                                          ...subsalesClaim
                                        })
                                      }
                                    </p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      }
                    </div>
                  )
                }
                if(section.type === "column") {
                  const { is_locked } = this.props.subsalesClaim
                  return (
                    <AtlasTable
                      key={section.renderTitle(subsalesClaim)}
                      className="mt-4"
                      hideSearch={true}
                      hidePagination={true}
                      columns={section.columnData}
                      rowData={subsalesClaim[section.columnValue] || []}
                      pagination={true}
                      actionColumnContent={ is_locked ? [] : section.getColumnActions(getOppositePartyDetails)}
                      renderExtraContent={ () => this.renderTableHeading(section) }
                    />
                  )
                }
              })
            }
          </div>
          {
            !this.props.subsalesClaim.is_locked && (
              <button 
                className="btn-new btn-new--primary align-self-start mt-4"
                onClick={ () => this.props.onChangeOppositeParticipantHOC(true, "showUpdateConfirmation") }>
                <AtlasIcon 
                  style={{ width: 20, height: 20, fill: "#FFFFFF" }} 
                  svgHref={"atlas-check-lg"}/>
                Complete Update
              </button>
            )
          }
        </div>
      </>
    );
  };
}

export default compose(TownShipHOC)(SubsalesClaim);
