export const PMRoutingData = [
  {
    id: 3,
    name: "Project Centre",
    key: "label.sidemenu.project_management.project_centre",
    icon: "atlas-building-4",
    navigateTo: "/dashboard/project-listing",
  },
  {
    id: 3,
    name: "Projects",
    key: "label.sidemenu.project_management.projects",
    icon: "icon-manage-project",
    navigateTo: "/dashboard/admin/project-management",
  },
];
