import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      admins: [],
      senior_admins: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    getAdmins = (id) =>
      Get(
        `/admin/admin_roles/search?submission_type_id=${id}`,
        this.getAdminsSuccess,
        this.getAdminsError,
        this.load,
      );
    getAdminsSuccess = (payload) => {
      Object.keys(payload).map((key) => {
        let temp = _.cloneDeep(payload[key]);
        temp = _.sortBy(temp, (i) => i.name.toLowerCase());
        if (key === "admin") {
          this.setState({ admins: temp });
        } else {
          this.setState({ senior_admins: temp });
        }
      });
    };
    getAdminsError = (error) => requestError(error);

    render = () => (
      <>
        <WrappedComponent
          {...this.props}
          admins={this.state.admins}
          senior_admins={this.state.senior_admins}
          onLoadAdmins={this.state.loading}
          getAdmins={this.getAdmins}
        />
      </>
    );
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
