import { MenuItem } from '@material-ui/core';
import Slider from 'react-input-slider';

import ProjectCard from 'components/Card/projectCard';
import AtlasRadioGroup from 'components/RadioGroup';
import AtlasSelect from 'components/Select/new';

const PropertyDetailsSettings = ({
  configuration,
  isPortrait,
  onChangeVideoConfiguration,
  onBulkChangeVideoConfiguration
}) => {
  return (
    <ProjectCard icon='building' title='Property Details Settings'>
      <div className={!configuration.hidePropertyDetails ? 'mb-3' : ''}>
        <label className='at-form-input__title'>Exclude property details</label>
        <AtlasRadioGroup
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          checkedValue={configuration.hidePropertyDetails}
          selectedRadioValue={val => onChangeVideoConfiguration('hidePropertyDetails', val)}
        />
      </div>
      {!configuration.hidePropertyDetails && (
        <>
          <div className='mb-3'>
            <label className='at-form-input__title'>Template</label>
            <AtlasSelect
              value={configuration?.propertyDetailsTemplateId}
              onChange={e => onChangeVideoConfiguration('propertyDetailsTemplateId', parseInt(e.target.value))}
            >
              <MenuItem value={1}>White box</MenuItem>
              <MenuItem value={2}>Transparent box</MenuItem>
              <MenuItem value={3}>Trapeziums</MenuItem>
              <MenuItem value={4}>Pills</MenuItem>
              <MenuItem value={5}>Black box</MenuItem>
              <MenuItem value={6}>Black pills</MenuItem>
            </AtlasSelect>
          </div>
          <div className='mb-3'>
            <label className='at-form-input__title'>
              Background transparency
            </label>
            <div>
              <Slider
                styles={{
                  active: { backgroundColor: '#3B82F6' },
                  thumb: {
                    border: '1px solid #9ca3af',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
                  }
                }}
                x={configuration?.propertyDetailsOpacity * 100}
                onChange={({ x }) => onChangeVideoConfiguration('propertyDetailsOpacity', x / 100)}
                xstep={10}
              />
            </div>
            <p>{configuration?.propertyDetailsOpacity * 100}%</p>
          </div>
          <div>
            <label className='at-form-input__title'>Position</label>
            <AtlasRadioGroup
              options={[
                { label: 'Left', value: 'left' },
                { label: 'Right', value: 'right' }
              ]}
              checkedValue={configuration?.propertyDetailsPosition}
              selectedRadioValue={val => {
                if (isPortrait) {
                  onBulkChangeVideoConfiguration({
                    propertyDetailsPosition: val,
                    agentDetailsPosition: val
                  });
                } else {
                  onChangeVideoConfiguration('propertyDetailsPosition', val);
                }
              }}
            />
          </div>
        </>
      )}
    </ProjectCard>
  );
};

export default PropertyDetailsSettings;
