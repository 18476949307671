import React, { useState } from 'react'
import _ from 'lodash'

import CustomSelect from "components/Select";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const CreateTranslation = props => {
  const [formData, setFormData] = useState({
    module: "",
    key: "",
    default_translation: "",
  })

  return (
    <ModalDialog
      title="New Translation"
      onLoad={props.onLoadLocalised}
      onClose={() => props.onChangeLocalisedHOC(false, "showCreateModal")}
      children={
        <div className='grid-control'>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Module</h2>
            <CustomSelect
              required={true}
              emptyOption={true}
              placeholder='Module'
              selectItems={props.modules}
              currentlySelected={formData.module}
              updateSelect={(val) => setFormData({ ...formData, module: val })} />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Key</h2>
            <CustomFormInput
              required={true}
              placeholder='Key'
              value={formData.key}
              onChangeValue={(val) => setFormData({ ...formData, key: val })} />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Default Translation</h2>
            <CustomFormInput
              required={true}
              placeholder='Default Translation'
              value={formData.default_translation}
              onChangeValue={(val) => setFormData({ ...formData, default_translation: val })} />
          </section>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--primary"
            disabled={_.values(formData).some(val => val === "")}
            onClick={() => props.createTranslation({
              module_id: formData.module?.value ?? null,
              key: formData.key,
              default_translation: formData.default_translation
            })}>
            Create
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeLocalisedHOC(false, "showCreateModal")}>
            Cancel
          </button>
        </div>
      } />
  )
}

export default CreateTranslation