import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Tooltip } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import UpdatePaperForm from "./UpdatePaperForm";
import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasDialog from "components/Dialog";
import SVGIcon from "components/Icon";
import LoadingModal from "components/LoadingModal";
import CreateDialog from "./createPaperForm";
import AtlasCloseButton from "components/Button/prev";

import permissionsChecker from "utils/permissionsChecker";

import PaperFormHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";

const legendsArray = [
  {
    colorName: "Blue",
    colorCode: "#FFFFFF", // Based on status_color,
    value: "Pending",
    desc: "Pending to Claim",
  },
  {
    colorName: "Green",
    colorCode: "#FAD25A", // Based on status_color,
    value: "Submitted",
    desc: "Submitted to Claim",
  },
  {
    colorName: "Grey",
    colorCode: "#D3D3D3", // Based on status_color
    value: "Cancelled",
    desc: "Form Cancelled",
  },
];

const headerData = [
  { label: "Form Number", value: "tracking_number", width: "50%" },
  { label: "Type", value: "type", width: "20%" },
  { label: "Form type", value: "form_type", width: "30%" },
  { label: "Agency", value: "agency_name", width: "40%" },
  { label: "Remark", value: "remark", width: "100%" },
];

class PaperForm extends Component {
  componentDidMount = () => {
    this.props.getPaperForms(1, "");
    initGA("/dashboard/personal/forms/paper-form");
  };

  componentDidUpdate = (pp) => {
    if (
      pp.showCreateDialog !== this.props.showCreateDialog &&
      !this.props.showCreateDialog
    ) {
      let temp = {
        tracking_number: "",
        type_id: "",
        form_type_id: "2",
      };
      this.props.onChangePaperFormHOC(temp, "newPaperForm");
      this.props.onChangePaperFormHOC(null, "coAgencyId");
    }
  };

  renderCreateDialog = () => (
    <AtlasDialog open={this.props.showCreateDialog}>
      <CustomCard
        containerStyle={{ height: "100%" }}
        cardContent={
          <CreateDialog
            disableSubmitButton={this.props.disableSubmitButton}
            onLoadPaperForms={this.props.onLoadPaperForms}
            coAgencyId={this.props.coAgencyId}
            renewalAgencyID={this.props.renewalAgencyID}
            agency={this.props.data.agencyReducer.agencies}
            onChangePaperFormHOC={this.props.onChangePaperFormHOC}
            generateFormNumber={this.props.generateFormNumber}
            generateRenewalFormNumber={this.props.generateRenewalFormNumber}
            newPaperForm={this.props.newPaperForm}
            createPaperForm={this.props.createPaperForm}
          />
        }
      />
    </AtlasDialog>
  );

  renderUpdate = () => (
    <AtlasDialog open={this.props.showUpdate}>
      <CustomCard
        containerStyle={{ height: "100%" }}
        cardContent={
          <UpdatePaperForm
            legendsArray={legendsArray}
            selectedPaperForm={this.props.selectedPaperForm}
            onChangePaperFormHOC={this.props.onChangePaperFormHOC}
            updatePaperForm={this.props.updatePaperForm}
          />
        }
      />
    </AtlasDialog>
  );

  render = () => {
    let { can_create } = permissionsChecker("Forms", this.props.data);
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <AtlasCloseButton
          onClick={() => this.props.history.goBack()}
          containerStyle={{ marginBottom: 10 }}
        />
        <div className="d-flex align-items-center">
          <h4 className="at-card__title mr-10">Physical Forms</h4>
          {role === "Personal Assistant" && (
            <Tooltip
              placement={"bottom"}
              title={`You are acting on behalf of your superior in this section.`}
            >
              <div style={{ marginRight: 10 }}>
                <SVGIcon
                  style={{ fill: "#f18e03", width: 26, height: 30 }}
                  svgHref={"icon-superior"}
                />
              </div>
            </Tooltip>
          )}
          {can_create && (
            <CustomButton
              isIconButton={true}
              style={{ background: "#F18E05", borderRadius: "50%", padding: 4 }}
              children={<IoMdAdd style={{ height: 18, color: "#FFFF" }} />}
              containerStyle={{ display: "inline" }}
              className={"d-flex"}
              onClick={() =>
                this.props.onChangePaperFormHOC(true, "showCreateDialog")
              }
              tooltip={true}
              tooltipPosition={"bottom"}
              tooltipID={"create"}
              tooltipChildren={"New form"}
            />
          )}
        </div>
        {this.renderCreateDialog()}
        {this.renderUpdate()}
        {this.props.onLoadPaperForms && <LoadingModal />}
      </>
    );
  };
}

export default compose(PaperFormHOC, VersionCheckHOC)(PaperForm);
