import React from "react";
import { Collapse } from "@material-ui/core";

import CustomFormInput from "components/Input/formInput";
import AtCurrecyInput from "components/Input/currencyInput";
import AtlasIcon from "components/Icon/atlasIcon";

import { PaymentTypes } from "./index";

const subsaleForm = ({ address = [] }) => [
  {
    label: "Property address",
    value: "property_address",
    type: "autosuggest",
    options: address,
    disabled: true,
    required: true,
  },
  {
    label: "Confirmation date",
    value: "confirmation_date",
    type: "date",
    required: true,
  },
  {
    label: "Professional fee (%)",
    value: "professional_fee_percentage",
    type: "number",
    required: false,
    renderIconLabel: ({ onChangeHOC }) => {
      return (
        <AtlasIcon
          tooltipChildren="Amount Converter"
          tooltipPosition="top"
          onClick={() => onChangeHOC("showAmountConverterModal", true)}
          svgHref="atlas-currency_exchange"
          className="at-icon-amount_converter at-subsales-form_view"/>
      )
    }
  },
  {
    label: "Sales & Service Tax (%)",
    value: "sales_tax_percentage",
    type: "number",
    required: true,
  },
  {
    label: "Purchase price (RM)",
    value: "purchase_price",
    type: "custom",
    required: true,
    renderCustomForm: ({
      index,
      data,
      can_update,
      onChangeFocus,
      onChangeBlur,
      onChange
    }) => {
      const { professional_fee_percentage = 0.0, purchase_price = 0.0 } = data;

      return (
        <li key={index} className="at-form_mngmt-form_list grid-half-col">
          <h2 className="at-form-input__title" required>Purchase Price (RM)</h2>
          <div>
            <AtCurrecyInput
              disabled={!can_update}
              value={purchase_price}
              required={true}
              style={{ paddingLeft: "0.2rem" }}
              onChangeFocus={() => onChangeFocus("purchase_price")}
              onChangeBlur={() => onChangeBlur("purchase_price")}
              onChange={(value) => onChange("purchase_price", value)}/>
          </div>
          <Collapse
            style={{ marginTop: "1rem" }}
            unmountOnExit
            timeout="auto"
            in={professional_fee_percentage && purchase_price}>
            <div>
              <p
                className="at-form-input__title"
                style={{
                  color: "#959595",
                }}>
                Potential commission: [ autocalculated & non-editable ]
              </p>
              <p
                className="at-form-input__title"
                style={{
                  color: "#959595",
                }}>
                Your potential commission from this case is RM{" "}
                {((professional_fee_percentage / 100) * purchase_price).toFixed(2)}
              </p>
            </div>
          </Collapse>
        </li>
      );
    },
  },
  {
    label: "Earnest deposit (%)",
    value: "down_payment_percentage",
    type: "number",
    automated: true,
    required: false,
    iconLabelTooltip: `If the agreed downpayment is not equivalent to 10%, toggle to turn off the auto-calculation function of the Earnest Deposit (%) and Balance Deposit (%) fields.`,
    renderIconLabel: ({ states, closedEditing, onChange }) => {
      const { autoCalculated } = states;
      return (
        <span
          style={{ display: "inline-flex" }}
          className={`at-form-switch_button ${ !closedEditing && (autoCalculated ? "checked" : "") }`}>
          <button style={{ width: "initial" }}>
            <input type="checkbox" id="toggle" />
            <label
              htmlFor="toggle"
              onClick={() => {
                if (!closedEditing) {
                  onChange("TriggerIcon", !autoCalculated);
                }
              }}></label>
          </button>
        </span>
      );
    },
  },
  {
    label: "Earnest deposit/Down payment amount (RM)",
    value: "down_payment_amount",
    type: "currency",
    automated: true,
    required: true,
    style: { marginTop: "auto" },
  },
  {
    label: "Balance deposit (%)",
    value: "balance_deposit_percentage",
    type: "number",
    automated: true,
    required: false,
  },
  {
    label: "Amount of balance deposit (RM)",
    value: "balance_deposit_amount",
    type: "currency",
    automated: true,
    required: true,
  },
  {
    label: "Balance purchase price (%)",
    value: "balance_purchase_price_percentage",
    type: "number",
    automated: true,
    required: false,
  },
  {
    label: "Balance purchase price (RM)",
    value: "balance_purchase_price",
    type: "currency",
    automated: true,
    required: true,
  },
  {
    label: `Balance purchase price to be paid within (days)`,
    value: "balance_pay_within_days",
    type: "number",
    required: false,
  },
  {
    label: "Extension period (days)",
    value: "balance_pay_within_another_days",
    type: "number",
    required: false,
  },
  {
    label: "Interest rate (%)",
    value: "chargeable_interest_percentage",
    type: "number",
    required: false,
  },
  {
    label: "Execution of S&P agreement within (days) ",
    value: "execution_days",
    type: "number",
    required: true,
  },
  {
    label: "Working days",
    value: "vacant_posession_day",
    type: "number",
    required: false,
  },
  {
    label: "Earnest Deposit paid by Purchaser (RM)",
    value: "earnest_deposit_amount",
    type: "currency",
    required: true,
  },
  {
    label: "Deposit payment method",
    value: "deposit_payment_method_id",
    type: "select",
    required: true,
    options: PaymentTypes,
    contents: [
      {
        id: 1,
        show: ({ deposit_payment_method_id = "" }) =>
          deposit_payment_method_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const { deposit_cheque_number = "" } = data;
          return (
            <div className="at-form__content at-new_container-16 mt-4 mb-0">
              <div>
                <h2 className="at-form-input__title">Cheque Number</h2>
                <input
                  className="at-form-input"
                  style={{ width: "100%" }}
                  value={deposit_cheque_number || ""}
                  type={"text"}
                  onChange={(e) =>
                    onChange("deposit_cheque_number", e.target.value)
                  }
                  disabled={!can_update}/>
              </div>
            </div>
          );
        },
      },
    ],
  },
  {
    label: "Payee",
    value: "stake_holder_id",
    type: "radio",
    required: true,
    options: [
      {
        id: 1,
        label: "IQI REALTY SDN BHD",
        value: 1,
      },
      {
        id: 2,
        label: "Lawyer",
        value: 2,
      },
    ],
    contents: [
      {
        id: 1,
        show: ({ stake_holder_id }) => stake_holder_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const { stake_holder_company, stake_holder_registration_number } = data;
          return (
            <div
              className="at-form__content at-new_container-16"
              style={{
                margin: "0.5rem 0 0 0",
              }}>
              <div className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title" required> Lawyer Company Name: </h2>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_company || ""}
                  onChangeValue={(val) => onChange("stake_holder_company", val)}
                  disabled={!can_update}/>
              </div>
              <div className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title" required>
                  Lawyer Company Registration Number:
                </h2>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_registration_number || ""}
                  onChangeValue={(val) =>
                    onChange("stake_holder_registration_number", val)
                  }
                  disabled={!can_update}/>
              </div>
            </div>
          );
        },
      },
    ],
  },

  {
    label: "Additional conditions",
    value: "special_conditions",
    type: "custom",
    renderCustomForm: ({ data, can_update, onChange }) => {
      const { has_extra_conditions, special_conditions } = data;
      return (
        <li
          className="at-form_mngmt-form_list grid-full-col"
          style={{ width: "100%" }}>
          <h2 className="at-form-input__title"> Additional Conditions </h2>
          <CustomFormInput
            type="text"
            placeholder={
              "If the condition is short enough to put in one line, write it here"
            }
            value={special_conditions}
            onChangeValue={(val) =>
              !has_extra_conditions && onChange("special_conditions", val)
            }
            disabled={has_extra_conditions || !can_update}/>
        </li>
      );
    },
  },
];

export default subsaleForm;
