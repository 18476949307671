import React, { Component, useCallback, createRef, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";

const weekData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const getLabelByType = (type) => {
  switch (type) {
    case "birthday":
      return "Birthday";
    case "event":
      return "Event";
    case "training":
      return "Training";
    default:
      return "";
  }
};
class DayGrid extends Component {
  state = {
    showDetails: false,
    eventArray: [],
    detailListingArray: [],
    showMoreEvents: false,
  };

  componentDidMount = () => {
    this.wrapperRef = createRef();

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);

    this.setEventArray();
  };

  componentDidUpdate = (prevProps) => {
    let tmpCheck =
      prevProps.storeForArrangedEventData !==
      this.props.storeForArrangedEventData;
    let tmpItemCheck = prevProps.item !== this.props.item;
    let tmpCurrentDisplayMonthCheck =
      prevProps.currentDisplayMonth !== this.props.currentDisplayMonth;
    if (tmpCheck || tmpItemCheck || tmpCurrentDisplayMonthCheck) {
      this.setEventArray();
    }

    if (prevProps.showDetailsDialog !== this.props.showDetailsDialog) {
      if (prevProps.showDetailsDialog && !this.state.showMoreEvents) {
        this.setState({ showMoreEvents: true });
      } else {
        this.setState({ showMoreEvents: this.props.showDetailsDialog });
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleClickOutside);
  };

  setEventArray = () => {
    const { item, repeat, storeForArrangedEventData, currentDisplayMonth } =
      this.props;

    let tmpData = storeForArrangedEventData[currentDisplayMonth.month()];
    let tmpEventArray = [];
    let tmpShowMoreListingArray = [];
    let currentYear = Moment().year();

    if (tmpData && tmpData[item.date]) {
      _.map(tmpData[item.date], (rowData) => {
        let itemDateNumber = Moment(rowData.date).date();
        let itemDateUnix = Moment(item.full_date).unix();
        let startDateUnix = Moment(item.start_date_time).startOf("day").unix();
        let endDateUnix = Moment(item.end_date_time).endOf("day").unix();
        let eventCheck =
          rowData.type !== "birthday" &&
          Moment(rowData.date).year() === currentDisplayMonth.year();
        let eventType = `${rowData.is_payment_needed ? "paid-" : ""}${rowData.type}`;

        let futureFilter =
          Moment().endOf("month").add(1, "years").unix() >=
            currentDisplayMonth.startOf("day").unix() &&
          Moment().unix() < currentDisplayMonth.startOf("day").unix();
        let currentMonthFilter =
          Moment().startOf("day").unix() <
            Moment(rowData.date).year(currentYear).startOf("day").unix() &&
          Moment().year() === currentDisplayMonth.year();

        let birthDateFilter = currentMonthFilter || futureFilter;

        let birthdayCheck = rowData.type === "birthday" && birthDateFilter;
        if (repeat) {
          if (itemDateNumber === item.date) {
            tmpEventArray.push(rowData);
          }
        } else {
          if (
            (itemDateNumber === item.date ||
              (startDateUnix <= itemDateUnix && endDateUnix >= itemDateUnix)) &&
            (eventCheck || birthdayCheck)
          ) {
            tmpEventArray.push(rowData);
            tmpShowMoreListingArray.push(
              <Tooltip
                key={rowData.id}
                enterTouchDelay={50}
                placement={"top-start"}
                title={getLabelByType(rowData.type)}
              >
                <span
                  className={`at-calendar__affair at-calendar__affair-${eventType}`}
                  onClick={() => this.onSelectEvent(rowData)}
                >
                  {rowData.title}
                  {rowData.type === "birthday" ? "'s birthday" : ""}
                </span>
              </Tooltip>,
            );
          }
        }
      });
    }

    this.setState({
      eventArray: tmpEventArray,
      detailListingArray: tmpShowMoreListingArray,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.showDetails && !this.state.showMoreEvents) {
      if (
        this.wrapperRef.current &&
        !this.wrapperRef.current.contains(event.target)
      ) {
        this.toggleShowDetails(false);
      }
    }
  };

  onSelectEvent = (item) => {
    this.toggleShowDetails(false);
    this.props.onClickEventDetails({
      ...item,
      currentSelectedYear: this.props.currentDisplayMonth.year(),
    });
  };

  toggleShowDetails = (val) => {
    this.setState({
      showDetails: val,
    });
  };

  renderNormalEventListing = (item, index, eventDay) => {
    const eventType = `${item.is_payment_needed ? "paid-" : ""}${item.type}`;

    if (index < 3) {
      return (
        <Tooltip
          key={item.id}
          enterTouchDelay={50}
          placement={"top-start"}
          title={
            this.props.renderTooltip
              ? this.props.renderTooltip(item)
              : getLabelByType(item.type)
          }
        >
          <span
            className={`at-calendar__affair at-calendar__affair-${eventType}`}
            onClick={() => this.onSelectEvent(item)}
          >
            {item.title}
            {item.type === "birthday" ? "'s birthday" : ""}
          </span>
        </Tooltip>
      );
    } else if (index === 3) {
      return (
        <span
          key={"more item"}
          className="at-calendar__affair at-calendar__affair--load-more"
          onClick={() => this.toggleShowDetails(true)}
        >
          {`${eventDay.length - 3} more events...`}
        </span>
      );
    }
  };

  renderEventDetailsListing = (dateNumber, show) => {
    const { currentDisplayMonth } = this.props;
    const renderDateNumber = (param) => {
      switch (param % 10) {
        case 1:
          return `${param}st`;
        case 2:
          return `${param}nd`;
        case 3:
          return `${param}rd`;
        default:
          return `${param}th`;
      }
    };
    return (
      <div
        ref={this.wrapperRef}
        className={`at-calendar__day-display ${show ? "" : "at-calendar__day-complete_list_hide"}`}
      >
        <p
          style={{
            width: "fit-content",
            display: "inline-block",
          }}
        >
          {
            weekData[
              Moment().date(dateNumber).month(currentDisplayMonth.month()).day()
            ]
          }
        </p>
        <p style={{ marginBottom: "0.5rem" }}>{renderDateNumber(dateNumber)}</p>
        <div className="at-calendar__affair-cont at-calendar__day-display-affair">
          {this.state.detailListingArray}
        </div>
      </div>
    );
  };

  renderEventListing = () => {
    const { eventArray } = this.state;
    return (
      <div className="at-calendar__affair-cont at-calendar__cal-affair">
        {eventArray &&
          _.map(eventArray, (item, index) => {
            return this.renderNormalEventListing(item, index, eventArray);
          })}
        {eventArray && eventArray.length !== 0 && (
          <span
            className="at-calendar__affair--responsive-load"
            onClick={() => this.toggleShowDetails(true)}
          >
            <AtlasIcon svgHref="atlas-calendar-2" />
          </span>
        )}
      </div>
    );
  };

  render = () => {
    const { item } = this.props;
    return (
      <div
        className={`at-calendar__day at-calendar__day--${item.type} ${this.state.showDetails ? "at-calendar__day--detail" : ""}`}
      >
        <span className="at-calendar__affair-day_text">{item.date}</span>
        {!this.state.showDetails && this.renderEventListing()}
        {this.renderEventDetailsListing(item.date, this.state.showDetails)}
      </div>
    );
  };
}

export default DayGrid;
