import React, { Component } from "react";
import { compose } from "redux";

import ModalDialog from "components/Modal/ModalDialog";
import AtlasCloseButton from "components/Button/prev";
import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/confirmation";
import ProfileContent from "./components/profile";
import PermissionsContent from "./components/permissions";
import CustomTab from "components/Tab";

import EditHOC from "./actions/edit";
import PermissionHOC from "./actions/permission";

class Edit extends Component {
  componentDidMount = () =>
    this.props.getPermissions(this.props.selectedPersonalAssistant.id);

  renderProfileContent = () => {
    return (
      <ProfileContent
        branchArray={this.props.branchArray}
        selectedPersonalAssistant={this.props.selectedPersonalAssistant}
        onChangeEditHOC={this.props.onChangeEditHOC}
        editPersonalAssistant={this.props.editPersonalAssistant}
        fullNameError={this.props.fullNameError}
        displayNameError={this.props.displayNameError}
        emailError={this.props.emailError}
        contactError={this.props.contactError}
        onClose={() => this.props.onChangeHOC(false, "showEditModal")}
        onClickUpdatePersonalAssistant={
          this.props.onClickUpdatePersonalAssistant
        }
      />
    );
  };

  renderPermissionContent = () => {
    return (
      <PermissionsContent
        permissions={this.props.permissions}
        onToggleCheckBox={this.props.onToggleCheckBox}
        onChangeEditHOC={this.props.onChangeEditHOC}
        onClose={() => this.props.onChangeHOC(false, "showEditModal")}
        selectedPersonalAssistant={this.props.selectedPersonalAssistant}
      />
    );
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        open={this.props.showConfirmationModal}
        message={
          'This personal assistant will be logged out after his / her permissions are updated. Please click "Yes" to confirm the update.'
        }
        positiveAction={() => {
          this.props.updatePermissions(this.props.permissions);
          this.props.onChangeEditHOC(false, "showConfirmationModal");
        }}
        negativeAction={() =>
          this.props.onChangeEditHOC(false, "showConfirmationModal")
        }
      />
    );
  };

  renderCardContent = () => {
    return (
      <>
        <CustomTab
          sections={["Profile", "Permission"]}
          onSelectSection={(item) =>
            this.props.onChangeEditHOC(item, "selectedSection")
          }
          selectedSection={this.props.selectedSection}
        />
        {this.props.selectedSection === "Profile" &&
          this.renderProfileContent()}
        {this.props.selectedSection === "Permission" &&
          this.renderPermissionContent()}
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModalDialog
          title={"Edit Personal Assistant"}
          maxWidth="xl"
          responsiveSize="lg"
          onLoad={this.props.onLoadEditPA}
          fullHeight={true}
          onClose={() => this.props.onChangeHOC(false, "showEditModal")}
          children={this.renderCardContent()}
        />
        {this.renderConfirmationModal()}
      </>
    );
  };
}

export default compose(EditHOC, PermissionHOC)(Edit);
