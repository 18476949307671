import React, { Component } from "react";
import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      videos: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getVideos = (project_id) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let isAdmin = ["Admin", "Super Admin"].indexOf(role) > -1;
      Get(
        `/projects/${project_id}/project_videos`,
        this.getVideosSuccess,
        this.getVideosError,
        this.load,
      );
    };
    getVideosSuccess = (payload) =>
      this.setState({ videos: payload.project_videos });
    getVideosError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            getVideos={this.getVideos}
            videos={this.state.videos}
            onLoadVideos={this.state.loading}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
