import React, { Component } from "react";
import _ from "lodash";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import Moment from "moment";

import { Get } from "utils/axios";
import getDomainURL from "utils/getDomainURL";
import { requestError } from "utils/requestHandler";
import { getItem } from "utils/tokenStore";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      reports: [],
      startDate: Moment().startOf("year").format("YYYY-MM-DD"),
      endDate: Moment(new Date()).format("YYYY-MM-DD"),
      view: "Project",
      visual: "chart",
      projectPage: null,
      projectData: [],
      showSearchTips: false,
      searchParams: {
        param: "",
        options: [],
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeProjectReportsHOC = (key, val) => this.setState({ [key]: val });

    getProjectReports = (startDate, endDate, branch) =>
      Get(
        `/connector/sales/ranking?type_id=1&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}&branch_id=${branch ? branch : ""}`,
        this.getProjectReportsSuccess,
        this.getProjectReportsError,
        this.load,
      );
    getProjectReportsSuccess = (payload) =>
      this.setState({ projectData: payload });
    getProjectReportsError = (error) => requestError(error);

    exportProjectReports = (startDate, endDate, searchParams, view) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";
      let token = getItem(tokenName);
      let headers = new Headers();
      let searchstartDate =
        startDate !== "" ? Moment(startDate).format("YYYY-MM-DD") : "";
      let searchEndDate =
        endDate !== "" ? Moment(endDate).format("YYYY-MM-DD") : "";
      let saleType = view === "Project" ? "1" : "2";
      headers.append("Authorization", `JWT ${token}`);
      fetch(
        `${getDomainURL()}/connector/sales/ranking/export?type_id=${saleType}&start_date=${searchstartDate}&end_date=${searchEndDate}&branch_id=${searchParams.param}`,
        { headers },
      )
        .then((response) => {
          return response.blob();
        })
        .then((blobby) => {
          FileSaver.saveAs(
            blobby,
            `${view} (${searchstartDate}-${searchEndDate}).xls`,
          );
        })
        .catch((error) => {
          requestError("Failed to download file. Please try again later.");
        });
    };

    getBranches = () =>
      Get(
        `/branches`,
        this.getBranchesSuccess,
        this.getBranchesError,
        this.load,
      );
    getBranchesSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        tmpArray.push({
          value: 0,
          label: "All",
        });
        payload.map((item) => {
          let data = {
            value: item.id,
            label: item.name,
          };
          tmpArray.push(data);
        });
        return this.setState({
          searchParams: {
            param: tmpArray[0].value,
            options: tmpArray,
          },
        });
      }
    };
    getBranchesError = (error) => requestError(error);

    exportBtn = (projectData, subsalesData, view) => {
      if (!_.isEmpty(projectData) && view === "Project") {
        return true;
      } else if (!_.isEmpty(subsalesData) && view === "Subsales") {
        return true;
      } else {
        return false;
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadReports={this.state.loading}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          view={this.state.view}
          visual={this.state.visual}
          projectData={this.state.projectData}
          searchParams={this.state.searchParams}
          showSearchTips={this.state.showSearchTips}
          exportBtn={this.exportBtn}
          getProjectReports={this.getProjectReports}
          exportProjectReports={this.exportProjectReports}
          getBranches={this.getBranches}
          onChangeProjectReportsHOC={this.onChangeProjectReportsHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
