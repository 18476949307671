import React, { useEffect, createRef, useRef, useState } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";
import { GrDocumentPdf } from "react-icons/gr";
import { AiFillCloseCircle } from "react-icons/ai";

import SVGIcon from "components/Icon";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/delete";
import PhotoModal from "components/PhotoModal";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import RemarkHOC from "../actions/remark";
import permissionsChecker from "utils/permissionsChecker";

const Remark = ({
  id,
  data,
  remarks,
  newRemark,
  onLoadRemark,
  selectedImage,
  selectedRemark,
  selectedAttachment,
  showEnlargeImageModal,
  showCreateRemarkModal,
  showDeleteRemarkConfirmation,
  showDeleteAttachmentConfirmation,

  getRemarks,
  deleteRemark,
  createRemarks,
  deleteRemarkDocument,
  appendRemarkAttachments,
  onChangeRemarkHOC,
}) => {
  const { can_create, can_update } = permissionsChecker("Users", data);

  let filePond = useRef(null);
  let [updateFilesRef, setUpdateFilesRef] = useState(null);

  useEffect(() => {
    getRemarks(id);
  }, []);

  useEffect(() => {
    setUpdateFilesRef((fileRef) =>
      Array(remarks.length)
        .fill()
        .map((item) => (item = createRef())),
    );
  }, [remarks]);

  const onChangeFormData = (val, context) => {
    let temp = _.cloneDeep(newRemark);
    temp[context] = val;
    onChangeRemarkHOC(temp, "newRemark");
  };

  const uploadFile = (event) => {
    try {
      let tmpFileItems = _.cloneDeep(event.target.files);

      if (event.target.files && event.target.files.length > 0) {
        let tmp = _.cloneDeep(newRemark);
        Object.keys(tmpFileItems).map((key) => {
          let reader = new FileReader();
          reader.onload = (e) => {
            tmp.remark_documents_attributes.push({
              document: e.target.result,
              document_file_name: tmpFileItems[key].name,
            });
            onChangeRemarkHOC(tmp, "newRemark");
          };
          reader.readAsDataURL(tmpFileItems[key]);
        });
      }
    } catch (e) {}
  };

  const appendFiles = (fileItems, documentInfo) => {
    try {
      if (fileItems && fileItems.length > 0) {
        let tmp = [];
        Object.keys(fileItems).map((key) => {
          let reader = new FileReader();
          reader.onload = (e) => {
            tmp.push({
              document: e.target.result,
              document_file_name: fileItems[key].name,
            });
            if (tmp.length === fileItems.length) {
              appendRemarkAttachments({
                attachment: tmp,
                user_id: id,
                remark_id: documentInfo.id,
              });
            }
          };
          reader.readAsDataURL(fileItems[key]);
        });
      }
    } catch (e) {}
  };

  const renderCreateModal = () => {
    const { remark_documents_attributes } = newRemark;

    return (
      <>
        <div className="d-flex flex-column g-3">
          <section>
            <h2 className="at-form-input__title">Remark</h2>
            <AtlasTextarea
              rows={10}
              disabled={!can_update}
              value={newRemark.content}
              onChangeValue={(val) => onChangeFormData(val, "content")}
            />
          </section>
          <section className="d-flex flex-wrap g-3">
            <div
              className="at-image-upload"
              style={{ width: 120, height: 120 }}
              onClick={() => filePond.click()}
            >
              <input
                ref={(ref) => (filePond = ref)}
                type={"file"}
                multiple={true}
                onChange={(event) => uploadFile(event)}
                style={{ display: "none" }}
                accept="image/jpeg, image/jpg, image/png, application/pdf"
              />
              <EmptyState
                renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
                title={"Click to upload"}
              />
            </div>
            {remark_documents_attributes &&
              remark_documents_attributes.length > 0 &&
              remark_documents_attributes.map((item, index) => {
                return (
                  <div
                    key={`remark-doc-${index}`}
                    className="at-remark__attachment-cont"
                  >
                    <button
                      className="at-remark__remove-attachment"
                      onClick={() => {
                        let temp = _.cloneDeep(newRemark);
                        temp.remark_documents_attributes.splice(index, 1);
                        onChangeRemarkHOC(temp, "newRemark");
                      }}
                    >
                      <AiFillCloseCircle
                        style={{ width: 25, height: 25, color: "red" }}
                      />
                    </button>
                    {!item.document.endsWith(".pdf") && (
                      <img
                        src={item.document ? item.document : ""}
                        alt={`remark-doc-${index}`}
                      />
                    )}
                    {item.document.endsWith(".pdf") && (
                      <GrDocumentPdf style={{ width: 80, color: "#9b9b9b" }} />
                    )}
                  </div>
                );
              })}
          </section>
        </div>
        <div className="at-modal_dialog-container-footer g-3">
          <button
            className="btn-new btn-new--primary"
            onClick={() =>
              createRemarks({
                user_id: id,
                remark: newRemark,
              })
            }
          >
            Submit
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() =>
              Promise.all([
                onChangeRemarkHOC(false, "showCreateRemarkModal"),
              ]).then(() => {
                onChangeRemarkHOC(
                  {
                    content: "",
                    remark_documents_attributes: [],
                  },
                  "newRemark",
                );
              })
            }
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  const renderEditRemark = () => {
    return (
      remarks.length > 0 &&
      remarks.map((remark, index) => (
        <div
          data-remark={remark}
          key={`${remark.content}-${remark.id}`}
          className={"at-remark__card mt-10"}
        >
          <h2 className="at-remark__card-title">
            {remark.created_date} by {remark.created_by}
          </h2>
          <p className="at-remark__card-content">{remark.content}</p>
          <div className="d-flex flex-wrap g-3">
            <div
              className="at-image-upload"
              style={{ width: 120, height: 120 }}
              onClick={() => updateFilesRef[index].click()}
            >
              <input
                ref={(ref) => (updateFilesRef[index] = ref)}
                type={"file"}
                multiple={true}
                onChange={(event) => appendFiles(event.target.files, remark)}
                style={{ display: "none" }}
                accept="image/jpeg, image/jpg, image/png, application/pdf"
              />
              <EmptyState
                renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
                title={"Click to upload"}
              />
            </div>
            {remark &&
              remark.remark_documents &&
              remark.remark_documents.map((document, index) => (
                <div key={index} className="at-remark__attachment-cont">
                  <button
                    className="at-remark__remove-attachment"
                    onClick={() =>
                      Promise.all([
                        onChangeRemarkHOC(document, "selectedAttachment"),
                      ]).then(() => {
                        onChangeRemarkHOC(
                          true,
                          "showDeleteAttachmentConfirmation",
                        );
                      })
                    }
                  >
                    <AiFillCloseCircle
                      style={{ width: 25, height: 25, color: "red" }}
                    />
                  </button>
                  {document &&
                    document.document_content_type &&
                    document.document_content_type.endsWith("pdf") && (
                      <GrDocumentPdf
                        onClick={() => window.open(document.url, "_blank")}
                        style={{
                          width: 80,
                          color: "#9b9b9b",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  {!(
                    document &&
                    document.document_content_type &&
                    document.document_content_type.endsWith("pdf")
                  ) && (
                    <img
                      src={document.url}
                      alt={document.document_file_name}
                      onClick={() =>
                        Promise.all([
                          onChangeRemarkHOC(document.url, "selectedImage"),
                        ]).then(() => {
                          onChangeRemarkHOC(true, "showEnlargeImageModal");
                        })
                      }
                    />
                  )}
                </div>
              ))}
          </div>
          <p
            className="at-remark__card-remove"
            onClick={() =>
              Promise.all([onChangeRemarkHOC(remark, "selectedRemark")]).then(
                () => {
                  onChangeRemarkHOC(true, "showDeleteRemarkConfirmation");
                },
              )
            }
          >
            Delete
          </p>
        </div>
      ))
    );
  };

  return (
    <>
      <div className="at-card__header">
        {can_create && (
          <CustomButton
            className={"btn-new btn-new--primary"}
            children={
              <>
                <IoMdAdd style={{ height: 18, width: 18, color: "#FFFF" }} />
                New Remark
              </>
            }
            onClick={() => onChangeRemarkHOC(true, "showCreateRemarkModal")}
          />
        )}
      </div>
      {renderEditRemark()}
      {remarks.length === 0 && (
        <EmptyState
          title={`You don't have any remarks`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      )}
      {showCreateRemarkModal && (
        <ModalDialog
          title={"New Remark"}
          onLoad={onLoadRemark}
          responsiveSize="lg"
          onClose={() => onChangeRemarkHOC(false, "showCreateRemarkModal")}
          children={renderCreateModal()}
        />
      )}
      <ConfirmationModal
        open={showDeleteRemarkConfirmation}
        message={"Are you sure to delete this remark?"}
        title={"Delete Remark"}
        showLoading={onLoadRemark}
        positiveAction={() => deleteRemark(selectedRemark.id)}
        negativeAction={() =>
          onChangeRemarkHOC(false, "showDeleteRemarkConfirmation")
        }
      />
      <ConfirmationModal
        open={showDeleteAttachmentConfirmation}
        message={"Are you sure to delete this attachment?"}
        title={"Delete Attachment"}
        showLoading={onLoadRemark}
        positiveAction={() =>
          deleteRemarkDocument(
            selectedAttachment.remark_id,
            selectedAttachment.id,
          )
        }
        negativeAction={() =>
          onChangeRemarkHOC(false, "showDeleteAttachmentConfirmation")
        }
      />
      <PhotoModal
        showPhotoTabModal={showEnlargeImageModal}
        tempImgSrc={selectedImage}
        togglePhotoTabModal={() =>
          onChangeRemarkHOC(false, "showEnlargeImageModal")
        }
      />
      {onLoadRemark && <LoadingModal />}
    </>
  );
};

export default RemarkHOC(Remark);
