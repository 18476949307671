import React, { useEffect } from 'react'
import _ from 'lodash'

import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CreateModal from "./forms";
import UpdateModal from "./forms";

import WithHOC from "../../actions/setting";
import { getColorBadge } from "dictionary/badgeColor";
import { PhotoshopPicker } from 'react-color';

const columnData = [
  {
    header: "Name",
    accessor: "language_name",
  },
  {
    header: "Language Code",
    accessor: "language_code",
  },
  {
    header: "Status",
    renderColumn: (rowData) => (
      <span
        style={{ ...getColorBadge(rowData.status_id === 1 ? 'Green' : 'Red') }}
        className={`at-status_badge`}>
        {rowData.status_id === 1 ? "Active" : "Inactive"}
      </span>
    )
  },
]

const Settings = props => {
  const renderCheckBoxActions = () => (
    <div className="d-flex align-items-center my-3 ml-3 grid_gap-2">
      <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>Languages</p>
      <button
        className='btn-new btn-new--primary'
        onClick={() => props.onChangeLocalisedSettingHOC(true, "showCreateModal")}>
        <AtlasIcon svgHref={'atlas-add-linear'} style={{ height: 18, width: 18, fill: 'white' }} />
        New
      </button>
    </div>
  )

  return (
    <>
      <ModalDialog
        title="Localised Centre Settings"
        onLoad={props.onLoadLocalised}
        onClose={() => props.onChangeLocalisedHOC(false, "showSettingsModal")}
        fullWidth={true}
        fullHeight={true}
        children={
          <CustomTable
            columns={columnData}
            rowData={props.settings || []}
            hideSearch={true}
            pagination={true}
            renderCheckBoxActions={renderCheckBoxActions}
            actionColumnContent={[{
              name: "edit",
              onClick: (record) => Promise.all([
                props.onChangeLocalisedSettingHOC(record, "selectedLanguage")
              ]).then(() => props.onChangeLocalisedSettingHOC(true, "showUpdateModal"))
            }]}
          />
        } />
        {props.showCreateModal && (
          <CreateModal
            onLoadLocalisedSetting={props.onLoadLocalisedSetting}
          
            createLocalisedSetting={props.createLocalisedSetting}
            onClose={() => props.onChangeLocalisedSettingHOC(false, "showCreateModal")}
            onChangeLocalisedSettingHOC={props.onChangeLocalisedSettingHOC} />
        )}
        {props.showUpdateModal && (
          <UpdateModal
            selectedLanguage={props.selectedLanguage}
            onLoadLocalisedSetting={props.onLoadLocalisedSetting}

            updateLocalisedSetting={props.updateLocalisedSetting}
            onClose={() => props.onChangeLocalisedSettingHOC(false, "showUpdateModal")}
            onChangeLocalisedSettingHOC={props.onChangeLocalisedSettingHOC} />
        )}
    </>
  )
}

export default WithHOC(Settings)