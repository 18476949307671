import React from "react";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/close";
import LoadingModal from "components/LoadingModal";

import Confirmation from "./containers/confirmation";
import BuyerInfo from "./containers/buyerInfo";
import PaymentModal from "./containers/payment";
import CompleteModal from "./containers/complete";

const Reservation = ({
  data,
  onLoad,
  selectedUnit,
  selectedProject,
  selectedReservation,
  progress,

  putReservation,
  uploadPaymentSlip,
  createReservation,
  getReservation,
  getProjectUnits,
  onChangeStoreyPlanHOC,
}) => {
  const renderConfrimation = () => (
    <Confirmation
      selectedUnit={selectedUnit}
      selectedProject={selectedProject}
      putReservation={putReservation}
      onChangeStoreyPlanHOC={onChangeStoreyPlanHOC}
    />
  );

  const renderPaymenSlip = () => (
    <PaymentModal
      selectedUnit={selectedUnit}
      selectedProject={selectedProject}
      uploadPaymentSlip={uploadPaymentSlip}
      getProjectUnits={getProjectUnits}
      onClose={() => onChangeStoreyPlanHOC("showReserveModal", false)}
    />
  );

  const renderBuyerInfo = () => (
    <BuyerInfo
      data={data}
      onLoad={onLoad}
      selectedUnit={selectedUnit}
      selectedProject={selectedProject}
      selectedReservation={selectedReservation}
      createReservation={createReservation}
      getReservation={getReservation}
      getProjectUnits={getProjectUnits}
      onClose={() => onChangeStoreyPlanHOC("showReserveModal", false)}
    />
  );

  const renderCompleteModal = () => (
    <CompleteModal
      selectedUnit={selectedUnit}
      selectedProject={selectedProject}
      onClose={() => onChangeStoreyPlanHOC("showReserveModal", false)}
    />
  );

  const renderCardContent = () => {
    return (
      <>
        <AtlasCloseButton
          containerClass={"at-storey-plan__close"}
          onClick={() => onChangeStoreyPlanHOC("showReserveModal", false)}
        />
        <CustomCard
          containerStyle={{
            backgroundColor: "white",
            boxShadow: "none",
          }}
          cardContent={
            <>
              {progress === "Confirmation" && renderConfrimation()}
              {progress === "PaymentSlip" && renderPaymenSlip()}
              {progress === "BuyerInfo" && renderBuyerInfo()}
              {progress === "Complete" && renderCompleteModal()}
            </>
          }
        />
      </>
    );
  };

  return (
    <>
      <CustomCard
        className={"at-storey-plan__content-container"}
        cardContent={renderCardContent()}
      />
      {onLoad && <LoadingModal />}
    </>
  );
};

export default Reservation;
