import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";

import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";
import Condition from "../components/Condition";

import convertConditions from "utils/convertConditions";

const Create = ({
  onChangeEmailListsHOC,
  teamsList,
  countriesList,
  branchesList,
  getTeamsList,
  getCountriesList,
  getBranchesList,
  checkEmailList,
  createEmailList,
}) => {
  const [name, setName] = useState("");
  const [conditions, setConditions] = useState([
    {
      id: 1,
      field: "",
      comparison: "",
      value: "",
      selected: [],
    },
  ]);

  useEffect(() => {
    getTeamsList();
    getCountriesList();
    getBranchesList();
  }, []);

  const handleClose = () => {
    onChangeEmailListsHOC("emailListDialog", "");
    setName("");
    setConditions([
      {
        id: 1,
        field: "",
        comparison: "",
        value: "",
        selected: [],
      },
    ]);
  };

  const addCondition = () => {
    setConditions([
      ...conditions,
      {
        id: conditions.length + 1,
        field: "",
        comparison: "",
        value: "",
        selected: [],
      },
    ]);
  };

  const removeCondition = (id) => () => {
    setConditions(conditions.filter((condition) => condition.id !== id));
  };

  const handleCheck = async () => {
    checkEmailList({
      task: "check",
      sql_conditions: await convertConditions(conditions),
    });
  };

  const handleSave = async () => {
    createEmailList({
      task: "save",
      name,
      sql_conditions: await convertConditions(conditions),
    });
  };

  return (
    <ModalDialog
      title={"Create Email List"}
      onClose={handleClose}
      footer={
        <div className={"d-flex align-items-center g-2"}>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--primary"}
            onClick={handleCheck}
          >
            Check
          </AtlasButton>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--success"}
            onClick={handleSave}
            disabled={name === ""}
          >
            Save
          </AtlasButton>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={handleClose}
          >
            Cancel
          </AtlasButton>
        </div>
      }
    >
      <div className={"mb-3"}>
        <h2 className={"at-form-input__title text-capitalize"} required>
          List Name
        </h2>

        <CustomFormInput
          type={"text"}
          value={name}
          onChangeValue={setName}
          maxLength={120}
          required={true}
        />
      </div>

      <div className={"mb-3"}>
        <h2 className={"at-form-input__title text-capitalize"}>Conditions</h2>

        <p className={"mb-1"} style={{ fontSize: 14 }}>
          Add conditions to filter users for the list
        </p>

        {conditions.map((condition) => (
          <Condition
            key={condition.id}
            condition={condition}
            conditions={conditions}
            setConditions={setConditions}
            teamsList={teamsList}
            countriesList={countriesList}
            branchesList={branchesList}
            removeCondition={removeCondition}
          />
        ))}
      </div>
      
      <AtlasButton
        type={"button"}
        className={"btn-new btn-new--secondary"}
        onClick={addCondition}
      >
        <IoMdAdd style={{ height: 18, width: 18 }} />
        Add Condition
      </AtlasButton>
    </ModalDialog>
  );
};

export default Create;
