import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";

import SVGIcon from "components/Icon";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasDialog from "components/Dialog";
import ReserveModal from "./Reservation";

import StoreyPlanHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";
import { numberWithCommas } from "utils/thousandSeparator";

import "./index.scss";

const type = [
  { label: "Total", value: "total_units", color: "#747474" },
  { label: "Available", value: "available", color: "#ffffff" },
  { label: "Reserved", value: "reserved", color: "#ffeb64" },
  { label: "Booked", value: "booked", color: "#ff6767" },
  { label: "Sold", value: "sold", color: "#ff6767" },
  { label: "Pending Approval", value: "pending_approval", color: "#c5c5c5" },
];

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const calculateTimeLimit = (setting) => {
  let multiplier = 0;
  switch (setting.unit) {
    case "seconds":
      multiplier = 1000;
      break;
    case "minutes":
      multiplier = 60000;
      break;
    case "hours":
      multiplier = 3600000;
      break;
  }
  return Number(setting.time) * multiplier;
};

const StoreyPlan = ({
  data,
  onLoadPlan,
  storeyPlan,
  fetchTime,
  progress,
  selectedUnit,
  selectedProject,
  selectedReservation,
  showReserveModal,
  showConfirmationModal,
  showUploadModal,

  getLiveSales,
  establishConnection,
  getSelectedUnit,
  getSelectedProject,
  createReservation,
  getReservation,
  uploadPaymentSlip,
  putReservation,
  onChangeStoreyPlanHOC,
  getCountryDictionary,
  toggleToast,
  ...props
}) => {
  const { role, id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const { can_create } = permissionsChecker("Projects - Reservations", data);
  const [chartData, setChartData] = useState({});
  const [maxFloorNumber, setMaxFloorNumber] = useState([]);

  useEffect(() => {
    let search = props.location.search;
    let project_id = search.substring(search.indexOf("=") + 1, search.length);
    getCountryDictionary();
    getSelectedProject(project_id);
    getLiveSales(project_id);
  }, []);

  useEffect(() => {
    processData();
  }, [storeyPlan]);

  const processUnitNumber = (num) => {
    let stringNumb = num.toString();
    if (_.includes(stringNumb, "4")) {
      stringNumb = _.replace(stringNumb, "4", "3A");
    }
    return stringNumb;
  };

  const processData = () => {
    let data = _.cloneDeep(storeyPlan.data);
    let maxFloorNumber = [];

    Object.keys(data).map((block) => {
      let blockObject = data[block];
      let maxUnit = 0;
      let maxFloor = [];
      Object.keys(blockObject).map((floor) => {
        let floorObject = blockObject[floor];
        if (floorObject.length > maxUnit) {
          maxUnit = floorObject.length;
          maxFloor = floorObject;
        }
        if (!_.includes(maxFloorNumber, floor)) {
          if (_.includes(floor, "A")) {
            let temp = floor.substring(0, floor.indexOf("A"));
            let position = maxFloorNumber.indexOf(temp);
            maxFloorNumber.splice(position + 1, 0, floor);
          } else {
            maxFloorNumber.push(floor);
          }
        }
      });
      blockObject.maxUnit = maxUnit;
      blockObject.maxFloor = maxFloor;
    });
    maxFloorNumber.sort((a, b) => (a > b ? 1 : -1));
    setMaxFloorNumber(maxFloorNumber);
    setChartData(data);
  };

  const permissionCheck = (unit) => {
    let isTimeOut = false;
    if (unit.status === "Reserved") {
      if (
        selectedProject.reservation_time_limit &&
        selectedProject.reservation_time_limit.settings.has_time_limit
      ) {
        let timeLimit = calculateTimeLimit(
          unit.has_submitted_payment
            ? selectedProject.reservation_time_limit.settings.buyer_info
            : selectedProject.reservation_time_limit.settings.payment_slip,
        );
        let reserveTime = new Date(unit.reservation_time || null);
        let difference = reserveTime - +new Date();
        difference = timeLimit + difference;
        if (difference > 0) isTimeOut = false;
        else isTimeOut = true;
      }
    }

    if (
      can_create &&
      !isTimeOut &&
      role !== "Super Admin" &&
      (unit.status === "Available" ||
        (unit.status === "Reserved" && unit.agent_id === id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderUnitType = () => (
    <div className="d-flex flex-wrap">
      {type.map((item, index) => {
        if (item.label !== "Booked" && item.label !== "Pending Approval") {
          return (
            <div
              className="at-storey-plan__legend"
              key={index}
              style={{ backgroundColor: item.color }}
            >
              <p>{`${item.label}: ${storeyPlan[item.value]}`}</p>
            </div>
          );
        } else if (
          item.label === "Pending Approval" &&
          selectedProject.reservation_settings &&
          selectedProject.reservation_settings.settings.need_approval
        ) {
          return (
            <div
              className="at-storey-plan__legend"
              key={index}
              style={{ backgroundColor: item.color }}
            >
              <p>{`${item.label}: ${storeyPlan[item.value]}`}</p>
            </div>
          );
        }
      })}
    </div>
  );

  return (
    <div className="m-2">
      <div className="at-storey-plan__title">
        {role === "Personal Assistant" && (
          <DarkTooltip
            placement={"right"}
            style={{ marginRight: 10 }}
            title={`You are acting on behalf of your superior in this section.`}
          >
            <div>
              <SVGIcon
                style={{ fill: "#f18e03", width: 26, height: 26 }}
                svgHref={"icon-superior"}
              />
            </div>
          </DarkTooltip>
        )}
        <div>
          <h4>{selectedProject.internal_name}</h4>
          <p>{storeyPlan.location}</p>
        </div>
      </div>
      <hr />
      {renderUnitType()}
      <div className="at-storey-plan__table-container">
        <table className="mb-2">
          <thead>
            <tr>
              <td rowspan="3" className="at-storey-plan__header"></td>
              {Object.keys(chartData).map((block, index) => (
                <th
                  style={{ fontSize: 14 }}
                  key={index}
                  colSpan={chartData[block].maxUnit}
                >
                  {block}
                </th>
              ))}
            </tr>
            <tr>
              {Object.keys(chartData).map((block) =>
                chartData[block].maxFloor.map((item) => (
                  <th className="at-storey-plan__box">{item.facing}</th>
                )),
              )}
            </tr>
            <tr>
              {Object.keys(chartData).map((block) =>
                chartData[block].maxFloor.map((item, index) => (
                  <th
                    style={{ fontWeight: 600 }}
                    className="at-storey-plan__box"
                  >
                    {processUnitNumber(index + 1)}
                  </th>
                )),
              )}
            </tr>
          </thead>
          <tbody>
            {maxFloorNumber.map((floorNumb, index) => (
              <tr key={index}>
                <td className="at-storey-plan__header">{floorNumb}</td>
                {Object.keys(storeyPlan.data).map((block) => {
                  let floorObject = storeyPlan.data[block];
                  let nullFloor = true;
                  return (
                    <>
                      {Object.keys(floorObject).map((floor, index) => {
                        return (
                          <>
                            {floor === floorNumb &&
                              floorObject[floor].map((unit, index) => {
                                nullFloor = false;
                                return (
                                  <td
                                    key={index}
                                    className="at-storey-plan__box"
                                    style={{
                                      backgroundColor: _.find(
                                        type,
                                        (item) => item.label === unit.status,
                                      )
                                        ? _.find(
                                            type,
                                            (item) =>
                                              item.label === unit.status,
                                          ).color
                                        : "#ffffff",
                                      ...(unit.onChange && {
                                        animationName: "blink",
                                        animationDuration: "1s",
                                        animationIterationCount: 4,
                                      }),
                                    }}
                                  >
                                    <CustomButton
                                      className="at-storey-plan__box-button"
                                      style={{
                                        cursor: permissionCheck(unit)
                                          ? "pointer"
                                          : "default",
                                      }}
                                      tooltip={permissionCheck(unit)}
                                      tooltipChildren={
                                        <span>
                                          {unit.status === "Booked"
                                            ? "Sold"
                                            : unit.status}
                                        </span>
                                      }
                                      tooltipPosition={"top"}
                                      tooltipID={`reserve-${unit.unit_no}-${index}`}
                                      onClick={() =>
                                        permissionCheck(unit)
                                          ? getSelectedUnit(
                                              unit.id,
                                              "Confirmation",
                                            )
                                          : {}
                                      }
                                      children={
                                        <>
                                          <p style={{ fontSize: 10 }}>
                                            {unit.unit_no}
                                          </p>
                                          <p>Type: {unit.unit_type}</p>
                                          {unit.status === "Available" && (
                                            <>
                                              <p
                                                style={{ color: "grey" }}
                                              >{`${unit.floor_size} ${unit.floor_size_unit}`}</p>
                                              <p style={{ fontSize: 10 }}>
                                                {
                                                  selectedProject.net_price_currency
                                                }{" "}
                                                {numberWithCommas(unit.price)}
                                              </p>
                                            </>
                                          )}
                                        </>
                                      }
                                    />
                                  </td>
                                );
                              })}
                          </>
                        );
                      })}
                      {nullFloor &&
                        chartData[block].maxFloor.map(() => (
                          <td style={{ backgroundColor: "#FAF7F7" }}>
                            <div
                              className={"at-storey-plan__box-button"}
                              style={{ content: "" }}
                            ></div>
                          </td>
                        ))}
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <p className="at-storey-plan__fetch-text">Fetch At: {fetchTime}</p>
      </div>
      <AtlasDialog
        open={showReserveModal}
        className={"at-storey-plan__reserve-dialog"}
        children={
          <ReserveModal
            data={data}
            onLoad={onLoadPlan}
            progress={progress}
            selectedProject={selectedProject}
            selectedUnit={selectedUnit}
            selectedReservation={selectedReservation}
            putReservation={putReservation}
            uploadPaymentSlip={uploadPaymentSlip}
            createReservation={createReservation}
            getReservation={getReservation}
            onChangeStoreyPlanHOC={onChangeStoreyPlanHOC}
            onClose={() => onChangeStoreyPlanHOC("showReserveModal", false)}
          />
        }
      />
      <ToastContainer autoClose={10000} style={{ minWidth: 350 }} limit={3} />
      {/* <ConfirmationModal
        mode={ 'alert' }
        open={ showConfirmationModal }
        loading={ onLoadPlan }
        title={ `Unit Reservation (${ selectedUnit.unit_no })` }
        message={ selectedProject.reservation_time_limit && `Once you Confirm Reservation, the unit will be Reserved and you will get 
          ${ timeLimit } to submit the Booking Fee Payment Slip. If you do not submit the payment slip within 
          ${ timeLimit} , then the unit will be released.` }
        positiveAction={ }
        positiveText={ 'Confirm Reservation' }
        negativeAction={ () => onChangeStoreyPlanHOC( 'showConfirmationModal', false )}
        negativeText={ 'Cancel' }
      /> */}
      {onLoadPlan && <LoadingModal />}
    </div>
  );
};

export default StoreyPlanHOC(StoreyPlan);
