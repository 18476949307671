import React, { Component } from "react";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Delete, GetFileURL } from "utils/axios";

const Documents = (WrappedComponent) => {
  class DocumentsHOC extends Component {
    state = {
      documents: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getDocuments = (submission_id) =>
      Get(
        `/admin/submissions/${submission_id}/documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) =>
      this.setState({ documents: payload.submission_documents });
    getDocumentsError = (error) => requestError(error);

    uploadDocument = (submission_id, document, document_file_name, issue_to) =>
      Post(
        `/admin/submissions/${submission_id}/documents`,
        {
          document,
          document_file_name,
          issue_to,
        },
        () => this.uploadDocumentSuccess(submission_id),
        this.uploadDocumentError,
        this.load,
      );
    uploadDocumentSuccess = (submission_id) => this.getDocuments(submission_id);
    uploadDocumentError = (error) => requestError(error);

    deleteDocument = (submission_id, id) =>
      Delete(
        `/admin/submissions/${submission_id}/documents/${id}`,
        () => this.deleteDocumentSuccess(submission_id),
        this.deleteDocumentError,
        this.load,
      );
    deleteDocumentSuccess = (submission_id) => {
      requestSuccess("Document removed successfully.");
      this.getDocuments(submission_id);
    };
    deleteDocumentError = (error) => requestError(error);

    downloadDocument = (url, filename) =>
      GetFileURL(url, filename, this.downloadDocumentError, this.load);
    downloadDocumentError = (error) => requestError(error);

    sendNotification = (id) =>
      Post(
        `/admin/submissions/${this.props.selectedSubmission.id}/documents/${id}/notify`,
        {},
        this.sendNotificationSuccess,
        this.sendNotificationError,
        this.load,
      );
    sendNotificationSuccess = (success) => requestSuccess(success.message);
    sendNotificationError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            documents={this.state.documents}
            onLoadDocuments={this.state.loading}
            getDocuments={this.getDocuments}
            deleteDocument={this.deleteDocument}
            uploadDocument={this.uploadDocument}
            downloadDocument={this.downloadDocument}
            sendNotification={this.sendNotification}
          />
        </>
      );
    };
  }
  return DocumentsHOC;
};

export default Documents;
