import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import ReactHTMLParser from "react-html-parser";
import { Tooltip } from "@material-ui/core";

import PhotoModal from "components/PhotoModal";
import AtlasIcon from "components/Icon/atlasIcon";
import EmptyState from "components/EmptyState";
import CustomUpload from "components/Input/upload";
import LoadingModal from "components/LoadingModal";
import CustomImageDisplay from "../../components/image";
import UploadModalContent from "../../components/UploadModalContent";

import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiFloorPlanImg from "assets/images/juwaiImages/floorPlan.jpg";
import iqiLogo from "assets/images/iqi_logo_only.png";
import iqiFloorPlanImg from "assets/images/subsaleImage/floorPlan.jpg";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import juwaiAsiaFloorPlanImg from "assets/images/juwaiAsiaImages/subsalesImages.jpg";
import edgePropFloorPlanImg from "assets/images/juwaiAsiaImages/subsalesImages.jpg";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";

const PlatformExhibition = [
  {
    Name: "IQI Logo",
    imgSrc: iqiFloorPlanImg,
    logoSrc: iqiLogo,
  },
  {
    Name: "Juwai Logo",
    imgSrc: juwaiFloorPlanImg,
    logoSrc: juwaiLogo,
  },
  {
    Name: "Juwai Asia Logo",
    imgSrc: juwaiAsiaFloorPlanImg,
    logoSrc: juwaiAsiaLogo,
  },
  {
    Name: "Edge Prop Logo",
    imgSrc: edgePropFloorPlanImg,
    logoSrc: edgePropLogo,
  },
];

const FloorPlan = ({
  data,
  subsalesEntryCreationProps,
  from,
  floorPlans,
  uploadedFloorPlans,
  onLoadFloorPlans,
  showPhotoTabModal,
  tempImgSrc,
  showFloorPlanUpload,
  uploadingFloorPlan,

  getLocalised,
  onChangeSubsalesHOC,
  onChangeFloorPlanHOC,
  getFloorPlans,
  requestSuccess,
  onSelectUploadFloorPlans,
  onClickRemoveFloorPlans,
  onClickUploadFloorPlans,
  removeFloorPlans,
}) => {
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const { can_create, can_destroy } = permissionsChecker(
    "Listing Centre",
    data,
  );

  useEffect(() => {
    const { ajaxCallProgress, ajaxErrorMessage, ajaxError, ajaxSuccess } =
      data.ajaxStatusReducer;

    if (ajaxCallProgress) {
      onChangeFloorPlanHOC(true, "loading");
    } else {
      onChangeFloorPlanHOC(false, "loading");
      if (ajaxError && from === "UPLOAD_FLOOR_PLAN") {
        getFloorPlans(subsalesEntryCreationProps.id);
        onChangeFloorPlanHOC(
          ReactHTMLParser(ajaxErrorMessage),
          "floorPlanSnackbarContent",
        );
        onChangeFloorPlanHOC(true, "showFloorPlanSnackbar");
      }
      if (ajaxSuccess && from === "UPLOAD_FLOOR_PLAN") {
        onChangeFloorPlanHOC("", "from");
        onChangeFloorPlanHOC([], "floorPlans");
        getFloorPlans(subsalesEntryCreationProps.id);
        requestSuccess(this.props.getLocalised("success.upload_floor_plan", "All floor plans uploaded successfully!"));
      }
    }
  }, [data.ajaxStatusReducer.ajaxCallProgress]);

  const onClickRemoveFile = (index) => {
    let temp = _.cloneDeep(floorPlans);
    temp.splice(index, 1);
    onChangeFloorPlanHOC(temp, "floorPlans");
  };

  const onChangePhoto = (images) => {
    let temp = images.map((item) => {
      return {
        file: item.source,
        name: item.name,
      };
    });
    onChangeFloorPlanHOC(temp, "floorPlans");
  };

  const renderUploadFields = () => {
    return (
      <>
        <CustomUpload
          multiple={true}
          disabled={!can_create}
          accepts={"image/*"}
          hidePreview={true}
          imageRestriction={true}
          files={floorPlans}
          style={{ backgroundColor: "#F9FAFB", border: "1px dashed #D1D5DB" }}
          labelText={"PNG, JPG, JPEG or GIF (min. 640 x 480)"}
          uploadImage={(val) => onChangePhoto(val)}
          language={data.languageReducer.language}
        />
        {floorPlans && floorPlans.length > 0 && (
          <div className="row">
            {floorPlans.map((item, index) => (
              <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
                <CustomImageDisplay
                  showRemoveButton={true}
                  onClickShowImage={() => {}}
                  imgStyle={{ width: "100%" }}
                  showPrimaryLabel={false}
                  showSetPrimaryButton={false}
                  key={`Floor-Plan-${index}`}
                  tooltipID={`Floor-Plan-${index}`}
                  imgSrc={item.file && item.file}
                  tooltipChildren={getTranslation("remove_image", data.languageReducer.language)}
                  onClickRemoveImage={() => onClickRemoveFile(index)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="d-flex mt-20 g-3">
          <button
            disabled={!can_create || !floorPlans.length > 0}
            className="btn-new btn-new--primary"
            onClick={() => onClickUploadFloorPlans()}
          >
            {getTranslation("upload", selectedLocalised)}
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onChangeSubsalesHOC(false, "showEditModal")}
          >
            {getTranslation("close", selectedLocalised)}
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-grid-3"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.floor_plan.form.title", "Upload Floor Plans")}
          {PlatformExhibition.map((item, index) => (
            <Tooltip
              key={index}
              classes={{ popper: "popper", tooltip: "tool-tip" }}
              placement={"top-end"}
              title={<img src={item.imgSrc} />}
            >
              <button
                style={{ width: 20, height: 20, marginLeft: 10 }}
                className={"btn-tooltip"}
                children={
                  <img
                    src={item.logoSrc}
                    style={{
                      borderRadius: item.Name === "Edge Prop Logo" ? "50%" : "",
                    }}
                  />
                }
              />
            </Tooltip>
          ))}
        </div>
        <p className="my-2 at-form-input__title"> {getLocalised("label.floor_plan.form.upload_image", "Upload Image(s)")}</p>
        {renderUploadFields()}
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-grid-3"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.floor_plan.floor_plans.title", "Floor Plans")}
        </div>
        <p className="fs-2">{getLocalised("label.floor_plan.floor_plans.subtitle", "View and manage your floor plan images.")}</p>
        <hr />
        {uploadedFloorPlans && uploadedFloorPlans.length > 0 && (
          <div className="row">
            {uploadedFloorPlans.map((item, index) => (
              <div className="col-md-6" key={index}>
                <CustomImageDisplay
                  showRemoveButton={can_destroy}
                  imgStyle={{ width: "100%" }}
                  showPrimaryLabel={false}
                  showSetPrimaryButton={false}
                  key={`Floor-Plan-${index}`}
                  tooltipID={`Floor-Plan-${index}`}
                  imgSrc={item.photo ? item.photo : item.large_image_url}
                  tooltipChildren={getTranslation("remove_image", data.languageReducer.language)}
                  onClickShowImage={(val) => {
                    onChangeFloorPlanHOC(true, "showPhotoTabModal");
                    onChangeFloorPlanHOC(val, "tempImgSrc");
                  }}
                  onClickRemoveImage={() => removeFloorPlans(item.id)}
                />
              </div>
            ))}
          </div>
        )}
        {(!uploadedFloorPlans || uploadedFloorPlans.length < 1) && (
          <EmptyState
            title={getLocalised("label.floor_plan.floor_plans.empty_message", "No floor plans have been uploaded")}
            renderIcon={<AtlasIcon svgHref="atlas-grid-3" />}
          />
        )}
      </div>
      {onLoadFloorPlans && <LoadingModal />}
      {showFloorPlanUpload && (
        <UploadModalContent
          uploadingFiles={uploadingFloorPlan}
          getLocalised={getLocalised}
          onClose={() => onChangeFloorPlanHOC(false, "showFloorPlanUpload")}
        />
      )}
      <PhotoModal
        showPhotoTabModal={showPhotoTabModal}
        tempImgSrc={tempImgSrc}
        togglePhotoTabModal={() =>
          onChangeFloorPlanHOC(false, "showPhotoTabModal")
        }
      />
    </>
  );
};

export default FloorPlan;
