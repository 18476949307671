import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoIosHelpCircle } from "react-icons/io";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { getColorBadge } from "dictionary/badgeColor";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

export const StatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Approved", colorName: "Green" },
  { value: "Rejected", colorName: "Red" },
];

export const ColumnData = [
  {
    header: "Agent",
    renderColumn: ({ agent, branch_name, branch }) => (
      <>
        <p>{agent.display_name ? agent.display_name : ""}</p>
        <p>{agent.email ? agent.email : ""}</p>
        <p>{agent.mobile_number ? agent.mobile_number : ""}</p>
        <p>{branch_name || ""}</p>
        <p>{branch ? branch.name : ""}</p>
      </>
    ),
  },
  { header: "Request Qty", accessor: "quantity" },
  { header: "Current Balance", accessor: "current_balance" },
  { header: "Unclaimed Qty", accessor: "unclaimed_quantity" },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div className={"d-flex justify-content-center"}>
          <div
            className="at-status_badge mt-1"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData.status}
          </div>
          {rowData.status === "Rejected" && (
            <DarkTooltip
              enterTouchDelay={50}
              classes={{ tooltip: "tooltip-arrow top" }}
              placement={"top"}
              title={rowData.remark}
            >
              <AiFillInfoCircle
                style={{
                  width: 18,
                  height: 18,
                  marginLeft: 5,
                  color: rowData.remark ? "#FB404B" : "rgba( 0,0,0,0.4 )",
                }}
              />
            </DarkTooltip>
          )}
        </div>
      );
    },
  },
  { header: "Processed By", accessor: "process_by" },
  {
    header: "Reason",
    renderColumn: (rowData) => (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        placement={"top"}
        enterTouchDelay={50}
        title={rowData.reason}
      >
        <IoIosHelpCircle
          style={{
            width: 21,
            height: 21,
            color: rowData.reason ? "#447DF7" : "rgba( 0,0,0,0.4 )",
          }}
        />
      </DarkTooltip>
    ),
  },
];

export const UnclaimedColumn = [
  {
    header: "Invoicing Branch",
    searchFlag: "invoicing_branch",
    accessor: "invoicing_branch",
  },
  { header: "Type", searchFlag: "type", accessor: "type" },
  {
    header: "Form Number",
    searchFlag: "transaction_number",
    accessor: "transaction_number",
  },
  {
    header: "Confirmation Date",
    searchFlag: "confirmation_date",
    accessor: "confirmation_date",
  },
  {
    header: "Property Address",
    searchFlag: "property_address",
    accessor: "property_address",
  },
];
