import React, { Component } from "react";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import LandmarkDetail from "./Detail";
import LandmarkHOC from "./actions";
import DefaultImage from "assets/images/default_image.png";

import "./index.scss";

const ColumnData = [
  {
    header: "Landmark",
    searchFlag: "landmark_image_url",
    renderColumn: (landmark) => (
      <img
        style={{height: 80, borderRadius: 2}}
        alt={landmark.name}
        src={
          landmark.landmark_image_url
            ? landmark.landmark_image_url
            : DefaultImage
        }
      />
    ),
  },
  {
    header: "Country",
    accessor: "name",
    searchFlag: "name",
  },
];

class CountryLandmarkManagement extends Component {
  componentDidMount = () => {
    this.props.getAllCountry();
  };

  renderCardContent = () => {
    return (
      <AtPaginatedTable
        rowData={this.props.allCountry.data}
        meta={this.props.allCountry.meta}
        showCloseButton={false}
        showCreateButton={false}
        columns={ColumnData}
        enableSort={true}
        actionColumnContent={[
          {
            name: "edit",
            onClick: (rowData) => {
              Promise.all([this.props.getSelectedCountry(rowData.id)]).then(
                () => {
                  this.props.onChangeLandmarkHOC(true, "showDetailsModal");
                },
              );
            },
          },
        ]}
        searchParams={[]}
        onChangeSearchParams={(val) => {}}
        getListAPI={this.props.getAllCountry}
        totalPages={this.props.allCountryPage}
      />
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={`Landmark Management`}
          atlasIcon={"atlas-landmark-management"}
        />
        <AtlasCard cardContent={this.renderCardContent()} />
        {this.props.showDetailsModal && (
          <LandmarkDetail
            selectedCountry={this.props.selectedCountry}
            bulkUpdate={this.props.bulkUpdate}
            updateCountry={this.props.updateCountry}
            batchUpdateState={this.props.batchUpdateState}
            onChangeLandmarkHOC={this.props.onChangeLandmarkHOC}
          />
        )}
        {this.props.onLoadLandmark && <LoadingModal />}
      </>
    );
  };
}

export default LandmarkHOC(CountryLandmarkManagement);
