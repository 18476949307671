import React, { Component, createRef } from "react";
import { compose } from "redux";
import _ from "lodash";

import CustomTab from "components/Tab";
import AtlasCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasDetailsDialog from "./components/Modal/details";
import AtlasPaymentDetail from "./components/Modal/paymentDetail";
import AtlasReasignContent from "./components/Modal/reassign";
import AtlasRejectDialog from "./components/Modal/reject";

import { ColumnData } from "./assets";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import AdminApprovalHOC from "./actions";
import AdminHOC from "./actions/admin";
import ApprovalModalHOC from "./actions/approval";

import { initGA } from "utils/ga";

import "./index.scss";

class AdminApproval extends Component {
  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getPendingNewREN(1, "");

    tmpProfileReducer.role === "Super Admin" && this.props.getAdmins();
    initGA("/dashboard/admin/admin-agent-approval");

    this.cardRef = createRef();
  };

  renderCheckBoxActions = () => {
    const { totalCheckable, selectedUsers, tabActions, onClickSelectAll } =
      this.props;

    const { can_update } = permissionsChecker(
      "New Agent Approvals",
      this.props.data,
    );
    const stickToTop =
      this.cardRef?.current?.getBoundingClientRect()?.top < -200 &&
      window.innerWidth < 768;

    return (
      <div className="d-block w-100">
        <CustomTab
          sections={["In Progress", "Completed"]}
          containerClass={"new-agent-approval__form-tab"}
          selectedSection={this.props.selectedTab}
          onSelectSection={(val) =>
            Promise.all([
              this.props.onChangeApprovalHOC(val, "selectedTab"),
            ]).then(() => {
              this.props.getPendingNewREN(1, "");
            })
          }
        />
        <div
          className={
            stickToTop
              ? "at-table--checkbox-actions-sticky"
              : "at-table--checkbox-actions"
          }
        >
          {can_update && (
            <div
              style={{ padding: 2 }}
              className="at-mobile-view__controller mb-1 mr-1">
              <CustomCheckbox
                contentStyle={{ fontWeight: 500 }}
                labelClassname={"at-table-tab-checkbox_responsive"}
                disabled={totalCheckable === 0}
                content={
                  selectedUsers.length === totalCheckable &&
                  totalCheckable !== 0
                    ? "Deselect All"
                    : "Select All"
                }
                checked={
                  selectedUsers.length === totalCheckable &&
                  totalCheckable !== 0
                }
                onChangeCheckboxValue={() => onClickSelectAll()}
              />
            </div>
          )}
          <div className="d-flex flex-wrap align-items-center mr-2">
            {tabActions.map((item, index) => (
              <button
                key={`action-tab-${index}`}
                disabled={selectedUsers.length === 0}
                className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
                style={{ fontSize: 14, padding: "6px 10px" }}
                onClick={() =>
                  this.props.onChangeApprovalHOC(true, item.modalProps)
                }>
                {item.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderCardContent = () => {
    const {
      selectedUsers,
      users,
      totalPages,
      totalCheckable,
      searchParams,
      getPaymentDetail,
      getPendingNewREN,
      getSelectedPendingNewREN,
      onClickSelectAll,
      onChangeApprovalHOC,
      onClickCheckBox,
    } = this.props;
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { can_update } = permissionsChecker(
      "New Agent Approvals",
      this.props.data,
    );
    return (
      <AtPaginatedTable
        columns={ColumnData({
          adminAction: can_update,
          selectedUsers,
          onClickSelectAll,
          onClickCheckBox,
          totalCheckable,
          getPaymentDetail,
          profileId: tmpProfileReducer.id,
          role: tmpProfileReducer.role,
        })}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeApprovalHOC(val, "searchParams")}
        rowData={users.data ? users.data : []}
        meta={users.meta ? users.meta : null}
        getListAPI={getPendingNewREN}
        totalPages={totalPages}
        renderCheckBoxActions={can_update && this.renderCheckBoxActions}
        actionColumnContent={[
          {
            name: "details",
            onClick: ({ id }) => getSelectedPendingNewREN(id),
          },
          {
            name: "payment proof",
            onShow: () => window.innerWidth < 800,
            onClick: (rowData) => {
              if (rowData.payment_method_id === 2) {
                getPaymentDetail(rowData.agent_registration_id);
              } else {
                window.open(
                  rowData[
                    rowData.payment_method_id === 1
                      ? "agent_payment_proof"
                      : "employment_proof"
                  ],
                );
              }
            },
          },
        ]}
      />
    );
  };

  renderConfirmationDialog = () => {
    return (
      <AtlasConfirmationDialog
        open={this.props.showConfirmationModal}
        loading={this.props.onLoadApproval}
        title={"Approve Selected New REN"}
        message={"Are you sure to approve all the selected new REN?"}
        positiveText={"Approve"}
        negativeText={"Cancel"}
        positiveAction={() => this.props.approveNewRENS()}
        negativeAction={() =>
          this.props.onChangeApprovalHOC(false, "showConfirmationModal")
        }
      />
    );
  };

  renderDetailsDialog = () => (
    <AtlasDetailsDialog
      {...this.props}
      open={this.props.showDetailsModal}
      onToggleDialog={() =>
        this.props.onChangeApprovalHOC(
          !this.props.showDetailsModal,
          "showDetailsModal",
        )
      }
    />
  );

  renderRejectDialog = () => {
    return (
      <ModalDialog
        title={"Reject Selected New REN"}
        responsiveSize="lg"
        onClose={() =>
          this.props.onChangeApprovalHOC(false, "showRejectDialog")
        }
        children={
          <AtlasRejectDialog
            selectedUsers={this.props.selectedUsers}
            getPendingNewREN={this.props.getPendingNewREN}
            onChangeApprovalHOC={this.props.onChangeApprovalHOC}
          />
        }
      />
    );
  };

  renderPaymentDetail = () => {
    return (
      <AtlasDialog open={this.props.showPaymentdetail}>
        <AtlasPaymentDetail
          paymentDetail={this.props.paymentDetail}
          onClose={() =>
            this.props.onChangeApprovalHOC(false, "showPaymentdetail")
          }
        />
      </AtlasDialog>
    );
  };

  renderRessignDialog = () => {
    const { selectedUsers } = this.props;

    return (
      <ModalDialog
        title={"Reassign Admin"}
        responsiveSize="lg"
        onClose={() =>
          this.props.onChangeApprovalHOC(false, "showReassignModal")
        }
        children={
          <AtlasReasignContent
            admins={this.props.admins}
            onLoadReassign={this.props.onLoadReassign}
            onClickReassign={(val) =>
              this.props.reassignAdmin(selectedUsers, val)
            }
            closeModal={() =>
              this.props.onChangeApprovalHOC(false, "showReassignModal")
            }
          />
        }
      />
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"New Agent Approvals"}
          moduleIcon={"icon-new-agent"}
          description={
            'New sign-ups need to be approved by our admin team and the referrer/leader of the new agent. To view those awaiting approval, expand the "More Filter" function, choose "Waiting Approval" from the "Registration Status" drop-down box and click Search.'
          }
          renderAlertContent={
            <AlertBox
              mode={"info"}
              title={
                "Before approving new agent signups, please check and confirm the following items."
              }
              description={
                <>
                  1) The registered name is the same as on the NRIC/Passport.
                  <br />
                  2) The bank account name is the same as on the NRIC/Passport.
                  <br />
                  2) Using the LPPEH search site (
                  <a
                    style={{ color: "#2463eb", fontWeight: 500 }}
                    href="http://search.lppeh.gov.my/"
                    target="_blank"
                  >
                    http://search.lppeh.gov.my/
                  </a>
                  ) search for the agent using the IC number. If the agent is an
                  existing agent, then they should exist in the search.
                  <br />
                  3) Check "Former Employee Company Name" section.
                  <br />
                  4) If the signup is from a REN or person who was previously
                  attached to another agency, property developer or property
                  sector, they must have a resignation letter. Ensure this
                  exists and look legitimate.
                  <br />
                  5) Valid payment: If the registration fee was paid through a
                  manual bank transfer, click on "View Docs" and ensure that the
                  payment looks valid. Check with finance to ensure that the
                  funds have been received in the account. If the payment was
                  via the online payment method, view the payment just to ensure
                  that it was successful.
                </>
              }
            />
          }
        />
        <AtlasCard ref={this.cardRef} cardContent={this.renderCardContent()} />
        {this.props.showRejectDialog && this.renderRejectDialog()}
        {this.props.showReassignModal && this.renderRessignDialog()}
        {this.renderConfirmationDialog()}
        {this.renderDetailsDialog()}
        {this.renderPaymentDetail()}
        {(this.props.onLoadAdminApproval || this.props.onLoadReassign) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AdminApprovalHOC,
  ApprovalModalHOC,
  AdminHOC,
  VersionCheckHOC,
)(AdminApproval);
