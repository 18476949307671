import React, { Component } from "react";

class BadgeSVG extends Component {
  renderBadge = () => {
    const { badge } = this.props;
    switch (badge) {
      case "Trooper":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-01.svg"
            }
            alt="Trooper"
          />
        );
      case "Valiant":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-02.svg"
            }
            alt="Valiant"
          />
        );
      case "Constable":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-03.svg"
            }
            alt="Constable"
          />
        );
      case "Corporal":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-04.svg"
            }
            alt="Sergeant"
          />
        );
      case "Sergeant":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-05.svg"
            }
            alt="Leutenant"
          />
        );
      case "Lieutenant":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-06.svg"
            }
            alt="General"
          />
        );
      case "Commander":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-07.svg"
            }
            alt="Corporal"
          />
        );
      case "General":
        return (
          <img
            src={
              "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/Army+badge_JIQI_FINAL_CO_400x400-08.svg"
            }
            alt="Commander"
          />
        );
    }
  };

  render() {
    const { className } = this.props;

    return <div className={`${className}`}>{this.renderBadge()}</div>;
  }
}

export default BadgeSVG;
