import Axios from "axios";

import getDomainURL from "utils/getDomainURL";
import { storeItem } from "utils/tokenStore";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const isImpersonating = window.location.href.includes("/admin-impersonate");
    const tokenName = isImpersonating
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const refreshTokenName = isImpersonating
      ? "IQI_ATLAS_REFRESH_AGENT_TOKEN"
      : "IQI_ATLAS_REFRESH_TOKEN";

    const originalRequest = error.config;
    if (error.config.url.indexOf("/refresh") > -1) {
      if (error.response.data.internal_code === 460) {
        window.localStorage.removeItem(tokenName);
        window.localStorage.removeItem(refreshTokenName);
        if (isImpersonating) {
          window.open(`${window.location.origin}/`, "_self");
        } else {
          alert(
            "Due to inactivity, your session has expired, please login again.",
          );
          storeItem("LAST_VIEW_URL", window.location.href);
          if (window.location.href.includes("storey-plan")) {
            window.open(`${window.location.origin}/#/login`, "_self");
          } else {
            window.location = window.location.href.split("?")[0];
            window.location.reload();
          }
        }
      }
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "JWT " + token;
            return Axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      return new Promise((resolve, reject) => {
        Axios.post(`${getDomainURL()}/tokens/refresh`, {
          refresh_token: window.localStorage.getItem(refreshTokenName),
        })
          .then((response) => {
            window.localStorage.setItem(tokenName, response.data.access_token);
            window.localStorage.setItem(
              refreshTokenName,
              response.data.refresh_token,
            );
            Axios.defaults.headers = {
              common: {
                Authorization: `JWT ${response.data.access_token}`,
              },
            };
            originalRequest.headers["Authorization"] =
              "JWT " + response.data.access_token;
            processQueue(null, response.data.access_token);
            resolve(Axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  },
);
