import React, { Component } from "react";

import Moment from "moment";
import { connect } from "react-redux";
import { requestError } from "utils/requestHandler";
import { GetFile } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showExportModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeExportHOC = (val, context) => this.setState({ [context]: val });

    exportLeads = (startDate, endDate) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.setState({ showExportModal: false });
      GetFile(
        role === "Admin" || role === "Super Admin"
          ? `/admin/leads/download?from=${startDate}&to=${endDate}`
          : `/leads/download?from=${startDate}&to=${endDate}`,
        `leadExport${startDate}-${endDate}`,
        () => {},
        this.exportLeadsError,
        this.load,
      );
    };
    exportLeadsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadExport={this.state.loading}
            showExportModal={this.state.showExportModal}
            exportLeads={this.exportLeads}
            onChangeExportHOC={this.onChangeExportHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
