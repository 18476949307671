import React, { Fragment, Component } from "react";
import { Grid, Card, Dialog, CardContent, IconButton } from "@material-ui/core";
import { AiOutlineZoomIn } from "react-icons/ai";
import { connect } from "react-redux";

import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/close";

const nameCardData = [
  {
    grid: 4,
    className: "",
    data: [
      { header: "Name", value: "name_card_display_name" },
      { header: "Name in local language", value: "additional_display_name" },
      { header: "Email", value: "email" },
      { header: "Primary Mobile Number", value: "mobile_contact_number" },
      { header: "Branch Name", value: "branch_name" },
    ],
  },
  {
    grid: 5,
    className: "mb-4",
    data: [
      { header: "License Number", value: "license_number" },
      { header: "Include profile photo", value: "include_profile_photo" },
      { header: "Designation", value: "designation" },
      { header: "Additional Mobile Number", value: "name_card_mobile" },
      { header: "Title of Academy Achievement", value: "qualification" },
    ],
  },
];
const defaultPhoto =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/default_namecard.png";
const samplePhoto =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/namecard_photo_sample.png";

class NameCard extends Component {
  state = {
    showSamplePhoto: false,
    showOriginalPhoto: false,
  };

  renderNamecardContent = () => {
    const {
      name_card,
      email,
      branch_name,
      license_number,
      mobile_contact_number,
    } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let tempNameCard = {
      ...name_card,
      include_profile_photo: name_card.include_profile_photo ? "Yes" : "No",
      email: email,
      branch_name: branch_name,
      license_number: license_number,
      mobile_contact_number: mobile_contact_number,
      name_card_mobile: name_card.mobile_number,
    };
    let showNameCardPhoto = name_card.include_profile_photo && name_card.photo;

    return (
      <Grid container spacing={16}>
        <Grid item md={4} xs={12}>
          <p className={"mb-10"} style={{ textDecoration: "underline" }}>
            Your Photo on Name Card
          </p>
          <div
            style={{
              position: "relative",
              width: 220,
              marginBottom: 20,
              borderRadius: 10,
            }}
          >
            {showNameCardPhoto && (
              <IconButton
                style={{
                  position: "absolute",
                  bottom: 7,
                  right: 7,
                  padding: 3,
                  backgroundColor: "black",
                  opacity: 0.8,
                }}
                onClick={() => this.setState({ showOriginalPhoto: true })}
              >
                <AiOutlineZoomIn style={{ width: 14, fill: "white" }} />
              </IconButton>
            )}
            <img
              src={showNameCardPhoto ? name_card.photo : defaultPhoto}
              style={{ objectFit: "contain", borderRadius: 10 }}
            />
          </div>
          <a
            onClick={() => this.setState({ showSamplePhoto: true })}
            style={{
              color: "#F18E05",
              display: "block",
              fontSize: 12,
              textDecoration: "underline",
            }}
          >
            Click here to see a good example of name card.
          </a>
        </Grid>
        <Grid className="position-relative pl-4" item md={8} xs={12}>
          <p className={"mb-10"} style={{ textDecoration: "underline" }}>
            Your Name Card Info
          </p>
          <Grid container spacing={16}>
            {nameCardData.map((item, index) => (
              <Grid key={index} className={item.className} item md={item.grid}>
                {item.data.map((data) => {
                  return (
                    <Fragment key={data.value}>
                      <p
                        style={{
                          fontStyle: "italic",
                          color: "rgba(0, 0, 0, 0.4 )",
                          ...this.props.contentStyle,
                        }}
                      >
                        {data.header === "License Number"
                          ? tempNameCard.real_estate_type || "Board Reg. No."
                          : data.header}
                      </p>
                      <p
                        style={{ ...this.props.contentStyle, minHeight: 19 }}
                        className={"mb-10"}
                      >
                        {`${
                          tempNameCard[data.value]
                            ? tempNameCard[data.value]
                            : "N/A"
                        }`}
                      </p>
                    </Fragment>
                  );
                })}
              </Grid>
            ))}
          </Grid>
          {this.props.onShowProfileUpdate && (
            <button
              onClick={this.props.onShowProfileUpdate}
              className={"btn-new btn-new--secondary"}
            >
              Edit Name card
            </button>
          )}
        </Grid>
      </Grid>
    );
  };

  render = () => {
    const { name_card } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <Card style={this.props.cardStyle}>
          <CardContent>{this.renderNamecardContent()}</CardContent>
        </Card>
        <Dialog open={this.state.showSamplePhoto}>
          <CustomCard
            cardContent={
              <>
                <div className="at-card__header">
                  <AtlasCloseButton
                    mode={"new"}
                    onClick={() => this.setState({ showSamplePhoto: false })}
                  />
                  <h4 className="at-card__title">{"Sample name card photo"}</h4>
                </div>
                <img className={"mb-10"} src={samplePhoto} />
                <p>Your photo must:</p>
                <p>
                  {" "}
                  - Be in colour, facing forwards and looking straight at the
                  camera
                </p>
                <p> - Not have anything covering your face</p>
                <p> - Contain no other objects or people</p>
                <p style={{ marginTop: 10, color: "#F18E05" }}>
                  * Do not wear sunglasses or tinted glasses. You can wear other
                  glasses if you need to, but your eyes must be visible without
                  any glare or reflection.
                </p>
              </>
            }
          />
        </Dialog>
        <Dialog open={this.state.showOriginalPhoto}>
          <CustomCard
            containerStyle={{ minHeight: 300, minWidth: 300, padding: 0 }}
            cardContent={
              <>
                <AtlasCloseButton
                  containerStyle={{ position: "absolute", top: 10, right: 10 }}
                  onClick={() => this.setState({ showOriginalPhoto: false })}
                />
                <img
                  src={name_card.photo}
                  style={{ height: "100%", width: "100%" }}
                />
              </>
            }
          />
        </Dialog>
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(NameCard);
