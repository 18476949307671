import React from "react";
import { Tooltip } from "@material-ui/core";
import { IoMdDocument } from "react-icons/io";

import AtlasCloseButton from "components/Button/close";

const DocumentElement = ({
  doc,
  docIndex,
  onClickOpenDoc,
  onRemoveDoc,
  isUnsaved,
  showRemoveButton,
  docNameProps,
}) => {
  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      <Tooltip title={"Click to view uploaded document."} placement={"top"}>
        <div style={{ position: "relative " }}>
          <IoMdDocument
            style={{
              maxHeight: 100,
              cursor: "pointer",
              color: isUnsaved ? "#F18E05" : "#fffff",
            }}
            onClick={() => onClickOpenDoc()}
          />
          {isUnsaved && (
            <p
              className={"w-100"}
              style={{
                fontSize: "0.7em",
                top: "50%",
                color: "#FFFF",
                position: "absolute",
              }}
            >
              Unsaved
            </p>
          )}
        </div>
      </Tooltip>
      {showRemoveButton && (
        <AtlasCloseButton
          containerStyle={{
            position: "absolute",
            top: 0,
            right: "30%",
          }}
          closeIconStyle={{ width: 22 }}
          onClick={() => onRemoveDoc(docIndex)}
        />
      )}
      <p style={{ fontSize: "0.8em" }}>
        {docNameProps ? doc[docNameProps] : doc.document_file_name}
      </p>
    </div>
  );
};

export default DocumentElement;
