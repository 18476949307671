import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put } from "utils/axios";
import { getInvoicingBranches } from "actions/branch";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { types as subsalesType } from "dictionary/subsales";

const searchParams = [
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Processed By",
    value: "admin_display_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    options: [
      {
        label: "Pending",
        value: "1",
      },
      {
        label: "Approved",
        value: "2",
      },
      {
        label: "Rejected",
        value: "3",
      },
    ],
  },
];

const FormsRequestHOC = (WrappedComponent) => {
  class FormsWrappedComponent extends Component {
    state = {
      requests: [],
      requestsPages: null,
      selected_request: {
        user: {
          display_name: "",
        },
      },
      initial_request: {
        user: {
          display_name: "",
        },
      },
      loading: false,
      showEditRequestDialog: false,

      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRequestsHOC = (val, context) => this.setState({ [context]: val });

    getRequests = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/forms/confirmation_form_requests?${search}page=${page}`,
        this.getRequestsSuccess,
        this.getRequestsError,
        this.load,
      );
    };
    getRequestsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let data = {
            ...item,
            process_by: item.admin_display_name
              ? item.admin_display_name
              : "N/A",
          };
          tmp.push(data);
        });
      payload.data = tmp;
      this.setState({
        requests: payload,
        requestsPages: tmpTotalPages,
      });
    };
    getRequestsError = (error) => requestError(error);

    getSelectedRequest = (id) =>
      Get(
        `/admin/forms/confirmation_form_requests/${id}`,
        this.getSelectedRequestSuccess,
        this.getSelectedRequestError,
        this.load,
      );
    getSelectedRequestSuccess = (payload) => {
      let tmp = {
        ...payload,
        agent_name: payload.agent.display_name || "",
        confirmation_forms: payload.confirmation_forms.map((item) => {
          let tmpInvoicingBranch = _.find(
            this.props.data.branchReducer.invoicingBranch,
            { id: item.invoicing_branch_id },
          );
          let tmpType = _.find(subsalesType, { id: item.type_id });
          let data = {
            ...item,
            confirmation_date: item.confirmation_date
              ? Moment(item.confirmation_date).format("DD MMM YYYY")
              : "N/A",
            invoicing_branch: tmpInvoicingBranch
              ? tmpInvoicingBranch.name
              : "N/A",
            type: tmpType.name,
          };
          return data;
        }),
      };
      return this.setState({
        selected_request: tmp,
        initial_request: tmp,
        showEditRequestDialog: true,
      });
    };
    getSelectedRequestError = (error) => requestError(error);

    updateSelectedRequest = (id, data) =>
      Put(
        `/admin/forms/confirmation_form_requests/${id}`,
        data,
        this.updateSelectedRequestSuccess,
        this.updateSelectedRequestError,
        this.load,
      );
    updateSelectedRequestSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getRequests(currentPage, searchParams);
      this.getSelectedRequest(this.state.selected_request.id);
      requestSuccess("Request updated successfully!");
    };
    updateSelectedRequestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            requests={this.state.requests}
            requestsPages={this.state.requestsPages}
            searchParams={this.state.searchParams}
            selected_request={this.state.selected_request}
            initial_request={this.state.initial_request}
            onLoadConfirmationFormRequests={this.state.loading}
            showEditRequestDialog={this.state.showEditRequestDialog}
            getRequests={this.getRequests}
            onChangeRequestsHOC={this.onChangeRequestsHOC}
            getSelectedRequest={this.getSelectedRequest}
            updateSelectedRequest={this.updateSelectedRequest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getInvoicingBranches,
    storeLastView,
    refreshToken,
  })(FormsWrappedComponent);
};

export default FormsRequestHOC;
