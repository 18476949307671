export const Region = [
  {
    id: 1,
    value: "North",
    label: "North",
  },
  {
    id: 2,
    value: "Central",
    label: "Central",
  },
  {
    id: 3,
    value: "South",
    label: "South",
  },
];

export const Status = [
  {
    id: 0,
    value: "Taken",
    label: "Taken",
  },
  {
    id: 1,
    value: "Submitted",
    label: "Submitted",
  },
  {
    id: 2,
    value: "Lost",
    label: "Lost",
  },
  {
    id: 3,
    value: "Cancelled",
    label: "Cancelled",
  },
];

export const Type = [
  {
    id: "1",
    value: "LOCR",
    label: "LOCR",
  },
  {
    id: "2",
    value: "LOCS",
    label: "LOCS",
  },
];

export const FormType = [
  {
    id: "1",
    value: "Physical Form",
    label: "Physical Form",
  },
  {
    id: "2",
    value: "Co-agency Form",
    label: "Co-agency Form",
  },
  {
    id: "3",
    value: "Renewal Form",
    label: "Renewal Form",
  },
];
