const TransparentBoxTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    flex: 1,
    padding: 4,
    fontSize: 9,
    fontWeight: 600,
    border: '1px solid #fff',
    color: '#fff',
    borderTopWidth: 0
  };

  const titleStyle = {
    color: '#ffc85f',
    fontSize: 7,
    fontWeight: 700,
    marginBottom: 1
  };

  return (
    <div style={{ width: 280 }}>
      <div
        style={{
          padding: 4,
          border: '1px solid #fff',
          backgroundColor: `rgba(255, 255, 255, ${configuration?.propertyDetailsOpacity})`,
          textAlign: 'center',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600
        }}
      >
        {unitType}
      </div>
      <div
        style={{
          display: 'flex',
          backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`
        }}
      >
        <div style={detailStyle}>
          <p style={titleStyle}>BUILT UP</p>
          <span>
            {property.min_built_up?.toLocaleString()} -{' '}
            {property.max_built_up?.toLocaleString()} Sqft
          </span>
        </div>
        <div style={{ ...detailStyle, borderLeftWidth: 0 }}>
          <p style={titleStyle}>BEDROOMS</p>
          <span>{property.bedroom_display || 0} rooms</span>
        </div>
        <div style={{ ...detailStyle, borderLeftWidth: 0 }}>
          <p style={titleStyle}>BATHROOMS</p>
          <span>{property.bathroom_display || 0} bathrooms</span>
        </div>
      </div>
      <div
        style={{
          ...detailStyle,
          backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`
        }}
      >
        <p style={titleStyle}>FEATURES</p>
        {property.project_features
          ?.slice(0, 3)
          ?.map(feature => feature.name?.trim())
          ?.join(', ')}
      </div>
    </div>
  );
};

export default TransparentBoxTemplate;
