import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import AtlasCloseButton from "components/Button/close";
import CustomFormInput from "components/Input/formInput";
import CustomFormTextArea from "components/Input/textarea";
import AtlasRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";

import { StatusLib, TypeLib } from "../asset";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
);

const FieldData = (context, category) => [
  { label: "Title", value: "title", type: "text" },
  { label: "Graphic Type", value: "type_id", type: "radio", options: TypeLib },
  {
    label: "Category",
    value: "category_list",
    type: "typeahead",
    options: category,
  },
  { label: "Sample", value: "sample", type: "file" },
  { label: "Description", value: "description", type: "textarea" },
  ...(context === "edit"
    ? [
        {
          label: "Status",
          value: "status_id",
          type: "radio",
          options: StatusLib,
        },
      ]
    : []),
];

export default function TitleDescContent({
  formData,
  category,
  context,
  sampleGraphic,
  onChangeFile,

  onChangeField,
}) {
  return (
    <>
      <div className={"grid-control"}>
        {FieldData(context, category).map((item) => (
          <section key={item.value} className={"grid-full-col"}>
            <div className="d-flex">
              <h2 className="at-form-input__title text-capitalize" required>
                {item.label}
              </h2>
            </div>
            {item.type === "textarea" && (
              <CustomFormTextArea
                required={true}
                value={formData[item.value]}
                onChangeValue={(val) => onChangeField(val, item.value)}
                placeholder={`Please enter graphic's ${item.value}`}
                rows={5}
              />
            )}
            {item.type === "text" && (
              <CustomFormInput
                type={"text"}
                numericOnly={item.input_type === "number"}
                value={formData[item.value]}
                required={true}
                onChangeValue={(val) => onChangeField(val, item.value)}
                placeholder={`Please enter graphic's ${item.value}`}
              />
            )}
            {item.type === "radio" && (
              <AtlasRadioGroup
                valueKey={"id"}
                horizontal={true}
                checkedValue={formData[item.value]}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => onChangeField(val, item.value)}
                options={item.options}
              />
            )}
            {item.type === "typeahead" && (
              <CustomTypeahead
                typeaheadId={"create-graphic-studio-category"}
                selectedValue={formData[item.value] || []}
                options={item.options || []}
                labelKey={"name"}
                onSearch={(val) => onChangeField([{ name: val }], item.value)}
                onSelect={(val) =>
                  val && val.length > 0 && onChangeField([val[0]], item.value)
                }
                childrenHead={(rowItem) => (
                  <p className="d-flex align-items-center">{rowItem.name}</p>
                )}
              />
            )}
            {item.type === "file" && (
              <>
                {!sampleGraphic && (
                  <div style={{ maxWidth: 480 }}>
                    <FilePond
                      allowMultiple={false}
                      fullWidth
                      maxFiles={1}
                      acceptedFileTypes={["image/jpeg", "image/png"]}
                      onupdatefiles={(fileItems) => onChangeFile(fileItems)}
                    />
                  </div>
                )}
                {sampleGraphic && (
                  <div
                    className="mt-1"
                    style={{
                      position: "relative",
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    <AtlasCloseButton
                      containerStyle={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                      }}
                      onClick={() => onChangeFile([])}
                    />
                    <img
                      src={sampleGraphic}
                      style={{ height: 250, objectFit: "contain" }}
                    />
                  </div>
                )}
              </>
            )}
          </section>
        ))}
      </div>
    </>
  );
}
