import React from "react";

import CustomCheckbox from "components/Checkbox";

const Notifications = ({ current_setting, handleChange }) => {
  let { notify_of_new_announcement } = current_setting;
  return (
    <div className="at-form__content">
      <h4 className="at-settings_card-title">System Wide</h4>
      <CustomCheckbox
        checked={notify_of_new_announcement}
        content={"Notify me when there is a new announcement published."}
        onChangeCheckboxValue={() =>
          handleChange(
            "notify_of_new_announcement",
            !notify_of_new_announcement,
          )
        }
      />
    </div>
  );
};

export default Notifications;
