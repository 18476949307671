import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Dialog } from "@material-ui/core";

import AtlasCloseButton from "components/Button/close";

import defaultImage from "assets/images/iqi_logo_only.png";

const SliderArrow = ({ className, onClick }) => {
  return (
    <div
      className={`${className} at-subsales__slick-slider-arrow`}
      onClick={onClick}
    />
  );
};

const settings = {
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  centerPadding: "60px",
  swipeToSlide: true,
  lazyLoad: false,
  className: "at-subsales__slick-initialized",
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />,
};

const PhotoModal = ({
  showPhotoTabModal,
  selectedImages = [],
  id = 0,
  initialImage,

  setShowPhoto,
}) => {
  return (
    <Dialog
      fullWidth={true}
      classes={{ paper: "at-subsales_listing__view-photo_modal" }}
      maxWidth={"xl"}
      open={showPhotoTabModal}
    >
      <AtlasCloseButton
        containerStyle={{ position: "absolute", right: 15, top: 15, zIndex: 2 }}
        onClick={() => setShowPhoto(false)}
      />
      <Slider {...settings} initialSlide={initialImage}>
        {selectedImages &&
          typeof selectedImages !== "string" &&
          selectedImages.length > 0 &&
          selectedImages.map((item, index) => {
            return (
              <div
                className="at-subsales_listing__view-img_cont"
                key={`img-${id}-${index}`}
              >
                <img
                  style={{ margin: "auto" }}
                  className={`at-subsales_listing__view-img`}
                  src={item}
                  alt={`Project SlideShow Image` + index}
                />
              </div>
            );
          })}
        {selectedImages && typeof selectedImages === "string" && (
          <div
            className="at-subsales_listing__view-img_cont"
            key={`img-${id}-0`}
          >
            <img
              style={{
                height: "100%",
                backgroundColor: "#FFF",
                margin: "auto",
              }}
              className={`at-subsales_listing__view-img`}
              src={defaultImage}
            />
          </div>
        )}
      </Slider>
    </Dialog>
  );
};

const PhotoSlider = ({ id = 0, images = [], floorPlanListing = [], getLocalised }) => {
  const [showPhotoTabModal, setShowPhoto] = useState(false);
  const [initialImage, setInitialImage] = useState(null);
  const [selectedTab, setTab] = useState("ListingPhoto"); // 1. ListingPhoto 2. FloorPlan
  const [selectedImages, setImages] = useState(defaultImage);

  useEffect(() => {
    if (selectedTab === "ListingPhoto") {
      images.length > 0 && setImages(images);
    }
  }, [images]);

  return (
    <div className="at-subsales_listing__view-photo_slider">
      <Slider {...settings}>
        {selectedImages &&
          typeof selectedImages !== "string" &&
          selectedImages.length > 0 &&
          selectedImages.map((item, index) => {
            return (
              <div
                className="at-subsales_listing__view-img_cont"
                key={`img-${id}-${index}`}
              >
                <img
                  style={{ width: "100%", margin: "auto" }}
                  className={`at-subsales_listing__view-img`}
                  src={item}
                  alt={`Project SlideShow Image` + index}
                  onClick={() => {
                    setInitialImage(index);
                    setShowPhoto(true);
                  }}
                />
              </div>
            );
          })}
        {selectedImages && typeof selectedImages === "string" && (
          <div
            className="at-subsales_listing__view-img_cont"
            key={`img-${id}-0`}
          >
            <img
              style={{ height: "100%", margin: "auto" }}
              className={`at-subsales_listing__view-img`}
              src={selectedImages}
              onClick={() => {
                setInitialImage(0);
                setShowPhoto(true);
              }}
            />
          </div>
        )}
      </Slider>
      <div className="at-subsales_listing__view-slider_tab">
        <button
          className={`${selectedTab === "ListingPhoto" ? "at-selected-button" : ""}`}
          disabled={selectedTab === "ListingPhoto"}
          onClick={() => {
            setTab("ListingPhoto");
            setImages(images.length ? images : defaultImage);
          }}
        >
          {getLocalised("label.photos.title", "Photos")}
        </button>
        <button
          className={`${selectedTab === "FloorPlan" ? "at-selected-button" : ""}`}
          disabled={selectedTab === "FloorPlan"}
          style={{ marginLeft: 8 }}
          onClick={() => {
            setTab("FloorPlan");
            setImages(
              floorPlanListing.length ? floorPlanListing : defaultImage,
            );
          }}
        >
          {getLocalised("label.floor_plan.floor_plans.title", "Floor Plan")}
        </button>
      </div>
      <PhotoModal
        id={id}
        initialImage={initialImage}
        showPhotoTabModal={showPhotoTabModal}
        selectedImages={selectedImages}
        setShowPhoto={setShowPhoto}
      />
    </div>
  );
};

export default PhotoSlider;
