import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { BsHouseFill } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";
import { subsalesType, propertyType } from "./assets";

const AnalyticCenterHOC = (WrappedComponent) => {
  class HOCWrappedComponent extends Component {
    state = {
      loading: false,

      searchParams: [
        {
          label: "Transaction Type",
          value: "sub_sale_type_id",
          type: "radio",
          param: "",
          col: 12,
          icon: (
            <BsHouseFill style={{ width: 14, height: 14, marginRight: 6 }} />
          ),
          options: subsalesType,
        },
        {
          label: "Address/Property Name",
          value: "address",
          type: "input",
          col: 12,
          param: "",
          icon: (
            <BsHouseFill style={{ width: 14, height: 14, marginRight: 6 }} />
          ),
        },
        {
          label: "Refined Search",
          value: "refined_address",
          helpText:
            "Narrow down your search to a more specific result by a keyword from the result",
          type: "input",
          col: 12,
          param: "",
          icon: (
            <BsHouseFill style={{ width: 14, height: 14, marginRight: 6 }} />
          ),
        },
        {
          label: "Property Type",
          value: "property_type_id",
          type: "select",
          col: 12,
          param: "",
          options: propertyType,
          icon: (
            <BsHouseFill style={{ width: 14, height: 14, marginRight: 6 }} />
          ),
        },
        {
          label: "Price Range (Min)",
          value: "min_price",
          type: "input",
          col: 6,
          param: "",
          icon: (
            <AtlasIcon
              svgHref={"atlas-dollar"}
              style={{ width: 14, height: 14, marginRight: 6, fill: "white" }}
            />
          ),
        },
        {
          label: "Price Range (Max)",
          value: "max_price",
          type: "input",
          col: 6,
          param: "",
          icon: (
            <AtlasIcon
              svgHref={"atlas-dollar"}
              style={{ width: 14, height: 14, marginRight: 6, fill: "white" }}
            />
          ),
        },
        {
          label: "Transaction Date Range (Min)",
          value: "start_date",
          type: "date",
          col: 6,
          param: "",
          icon: (
            <AtlasIcon
              svgHref={"atlas-calendar-2"}
              style={{ width: 14, height: 14, marginRight: 6, fill: "white" }}
            />
          ),
        },
        {
          label: "Transaction Date Range (Max)",
          value: "end_date",
          type: "date",
          col: 6,
          param: "",
          icon: (
            <AtlasIcon
              svgHref={"atlas-calendar-2"}
              style={{ width: 14, height: 14, marginRight: 6, fill: "white" }}
            />
          ),
        },
      ],
      sorting: {},
      transactionResult: {
        data: [],
        summary: [],
      },
      trensactionResultMeta: {},
      trensactionResulPages: [],
      transactionResultGraph: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeAnalyticHOC = (val, context) => this.setState({ [context]: val });

    onClickSortAction = (column, order) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      let temp = _.cloneDeep(searchParams);
      if (order) {
        temp = temp + `sort_by_column=${column}&`;
        temp = temp + `sort_order=${order}&`;
      }
      this.setState({
        sorting: {
          column: column,
          order: order,
        },
      });
      this.getSearchTransaction(currentPage, temp);
    };

    processSearchParams = (page) => {
      let temp = "";
      this.state.searchParams.map((item) => {
        temp = temp + `${item.value}=${item.param}&`;
      });

      if (this.state.sorting.column && this.state.sorting.order) {
        temp = temp + `sort_by_column=${this.state.sorting.column}&`;
        temp = temp + `sort_order=${this.state.sorting.order}&`;
      }

      this.setState(
        {
          transactionResult: [],
          trensactionResultMeta: {},
          trensactionResulPages: [],
          transactionResultGraph: {},
        },
        () => {
          let lastView = {
            currentPage: page,
            searchParams: temp,
          };
          this.props.storeLastView(lastView);

          this.getSearchTransaction(page, temp);
        },
      );
    };

    getSearchTransaction = (page, search) =>
      Get(
        `/sub_sales/search_transactions?${search}page=${page}`,
        this.getSearchTransactionSuccess,
        this.getSearchTransactionError,
        this.load,
      );
    getSearchTransactionSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.metadata.totalPages; i++) {
        tmpTotalPages.push(i);
      }

      let graphDataset = {
        label: [],
        price: [],
        transaction: [],
      };
      if (payload.chartSummary && payload.chartSummary.length > 0) {
        payload.chartSummary.map((item) => {
          let date = `${item.monthName}  ${item.year}`;
          graphDataset.label.push(date);
          graphDataset.price.push(item.totalPrice);
          graphDataset.transaction.push(item.transactionVolume);
        });
      } else {
        graphDataset = {};
      }

      this.setState({
        transactionResult: payload,
        trensactionResultMeta: {
          page: payload.metadata.currentPage,
          pages: payload.metadata.totalPages,
          count: payload.metadata.totalCount,
          from:
            (payload.metadata.currentPage - 1) * 12 > 0
              ? (payload.metadata.currentPage - 1) * 12
              : 1,
          to:
            payload.metadata.currentPage * 12 > payload.metadata.totalCount
              ? payload.metadata.totalCount
              : payload.metadata.currentPage * 12,
        },
        trensactionResulPages: tmpTotalPages,
        transactionResultGraph: !_.isEmpty(graphDataset)
          ? {
              labels: graphDataset.label,
              datasets: [
                {
                  type: "line",
                  label: "Volume",
                  data: graphDataset.transaction,
                  borderColor: "#F04438",
                  backgroundColor: "#F04438",
                  fill: false,
                  yAxisID: "y-axis-2",
                },
                {
                  type: "bar",
                  label: "Price",
                  data: graphDataset.price,
                  borderColor: "#3B82F6",
                  backgroundColor: "#3B82F6",
                  hoverBackgroundColor: "#1E3A8A",
                  yAxisID: "y-axis-1",
                },
              ],
            }
          : {},
      });
    };
    getSearchTransactionError = (error) =>
      requestError(typeof error === "string" ? error : error.title);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadAnalysis={this.state.loading}
            sorting={this.state.sorting}
            progress={this.state.progress}
            buyerInfo={this.state.buyerInfo}
            heatMapData={this.state.heatMapData}
            subsalesInfo={this.state.subsalesInfo}
            searchParams={this.state.searchParams}
            transactionResult={this.state.transactionResult}
            trensactionResultMeta={this.state.trensactionResultMeta}
            trensactionResulPages={this.state.trensactionResulPages}
            transactionResultGraph={this.state.transactionResultGraph}
            getSubsalesInfo={this.getSubsalesInfo}
            onClickSortAction={this.onClickSortAction}
            processSearchParams={this.processSearchParams}
            getSearchTransaction={this.getSearchTransaction}
            onChangeAnalyticHOC={this.onChangeAnalyticHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(HOCWrappedComponent);
};

export default AnalyticCenterHOC;
