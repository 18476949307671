import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import { commissionTimelines } from "../timelineData";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      onLoadTopPerformer: false,

      personalSales: {},
      commissionYData: [],
      commissionXData: [],
      leaderBoardData: [],
      selectedCommTimelineType: commissionTimelines[0],
    };

    load = (param) => this.setState({ loading: param });
    onLoadTopPerformer = (param) => this.setState({ onLoadTopPerformer: param });

    getPersonalSales = (encoded_mobile) =>
      Get(
        encoded_mobile
          ? `/connector/personal_sales?identity_id=${encoded_mobile}`
          : `/connector/personal_sales`,
        this.getPersonalSalesSuccess,
        this.getPersonalSalesError,
        this.load,
      );
    getPersonalSalesSuccess = (payload) =>
      this.setState({ personalSales: payload }, () =>
        this.processPersonalSalesData(commissionTimelines[0].value),
      );
    getPersonalSalesError = (error) => requestError(error);

    getLeaderBoardData = () =>
      Get(
        `/connector/top_performers`,
        this.getLeaderBoardDataSuccess,
        this.getLeaderBoardDataError,
        this.load,
      );
    getLeaderBoardDataSuccess = (payload) =>
      this.setState({ leaderBoardData: payload });
    getLeaderBoardDataError = (error) => requestError(error);

    processPersonalSalesData = (param) => {
      let tmpXData = [];
      let tmpYData = [];
      let data = this.state.personalSales;
      data = _.filter(data, (o) => o.saleType === param);
      data = _.orderBy(data, "saleDate", "asc");
      data.map((item) => {
        let tmpDate = new Date(item.saleDate);
        tmpXData.push(
          `${tmpDate.getFullYear()}/${monthNames[tmpDate.getMonth()]}`,
        );
        tmpYData.push(item.commTotal);
      });
      return this.setState({
        commissionYData: tmpYData,
        commissionXData: tmpXData,
      });
    };

    updateSelectCommTimeline = (param) =>
      this.setState({ selectedCommTimelineType: param }, () =>
        this.processPersonalSalesData(param.value),
      );

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadPersonalSales={this.state.loading}
            onLoadTopPerformer={this.state.onLoadTopPerformer}
            personalSales={this.state.personalSales}
            commissionXData={this.state.commissionXData}
            commissionYData={this.state.commissionYData}
            leaderBoardData={this.state.leaderBoardData}
            selectedCommTimelineType={this.state.selectedCommTimelineType}
            getPersonalSales={this.getPersonalSales}
            getLeaderBoardData={this.getLeaderBoardData}
            updateSelectCommTimeline={this.updateSelectCommTimeline}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
