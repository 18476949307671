import Moment from "moment";

import { STORE_PV_START_DATE, STORE_PV_END_DATE } from "actions/type";

let initialState = {
  selectedStartDate: Moment()
    .year(new Date().getFullYear())
    .month(0)
    .date(1)
    .format("YYYY-MM-DD"),
  selectedEndDate: Moment(new Date()).format("YYYY-MM-DD"),
};

export default function paymentVoucherReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_PV_START_DATE:
      return {
        ...state,
        selectedStartDate: action.payload,
      };
    case STORE_PV_END_DATE:
      return {
        ...state,
        selectedEndDate: action.payload,
      };
    default:
      return state;
  }
}
