import React, { Component } from "react";
import _ from "lodash";

import FPXIcon from "assets/ipay88/fpx.png";
import BoostIcon from "assets/ipay88/boost.png";
import GrabPayIcon from "assets/ipay88/grabpay.png";
import TNGIcon from "assets/ipay88/tng.png";
import MaybankQRPayIcon from "assets/ipay88/maybank_qrpay.png";
import ShopeePayIcon from "assets/ipay88/shopee_pay.png";
import VisaIcon from "assets/ipay88/visa.png";
import MastercardIcon from "assets/ipay88/master.png";

import "./index.scss";

const PaymentsMethods = [
  {
    label: "Visa and Mastercards",
    value: 2,
    images: [
      {
        src: VisaIcon,
        className: "mr-10 icon",
      },
      {
        src: MastercardIcon,
        className: "icon",
      },
    ],
  },
  {
    label: "FPX",
    value: 16,
    buttonClass: "icon",
    images: [
      {
        src: FPXIcon,
        className: "icon",
      },
    ],
  },
  {
    label: "Boost",
    value: 210,
    images: [
      {
        src: BoostIcon,
        width: 110,
      },
    ],
  },
  {
    label: "GrabPay",
    value: 523,
    images: [
      {
        src: GrabPayIcon,
        className: "p-0",
        width: 180,
      },
    ],
  },
  {
    label: "TNG",
    value: 538,
    buttonClass: "icon",
    images: [
      {
        src: TNGIcon,
        className: "icon",
      },
    ],
  },
  {
    label: "MaybankQRPay",
    value: 542,
    buttonClass: "icon",
    images: [
      {
        src: MaybankQRPayIcon,
        className: "icon",
      },
    ],
  },
  {
    label: "ShopeePay",
    value: 801,
    images: [
      {
        src: ShopeePayIcon,
        width: 180,
      },
    ],
  },
];

class PaymentGateway extends Component {
  renderImageButton = (item) => {
    const { buttonClass, images } = item;
    return (
      <button
        className={`btn-icon mr-10 ${this.props.selected ? "active" : ""} ${buttonClass || ""}`}
        disabled={this.props.disabled}
        onClick={() => this.props.onClickSelect(item)}
      >
        {images.map((image, index) => (
          <img
            key={`${item.value}-${index}`}
            width={image.width}
            src={image.src}
            className={`display-inline-block ${image.className || ""}`}
          />
        ))}
      </button>
    );
  };

  renderPayButton = (paymentMethod, index) => {
    let tmp = "";

    if (!this.props.hideIcon) {
      if (paymentMethod) {
        tmp = this.renderImageButton(paymentMethod, index);
      } else {
        tmp = (
          <button
            className="btn-new btn-new--primary w-100"
            disabled={this.props.disabled}
            onClick={() => this.props.onClickSelect(paymentMethod)}
          >
            Checkout
          </button>
        );
      }
    } else {
      tmp = (
        <button
          className="btn-new btn-new--primary w-100"
          disabled={this.props.disabled}
          onClick={() => this.props.onClickSelect({ paymentMethod })}
        >
          {this.props.buttonTitle || "Checkout"}
        </button>
      );
    }

    return tmp;
  };

  render = () => {
    const paymentMethod = PaymentsMethods.find(
      (item) => item.value === this.props.payment_id,
    );
    const tmpIndex = PaymentsMethods.findIndex(
      (item) => item.value === this.props.payment_id,
    );

    return (
      <div
        style={{
          zIndex: tmpIndex !== -1 ? PaymentsMethods.length - tmpIndex : 1,
        }}
        className={`payment-form display-inline-block checkout-payment-btn`}
      >
        {this.renderPayButton(paymentMethod, tmpIndex)}
      </div>
    );
  };
}

export default PaymentGateway;
