import React, { Component } from "react";
import _, { cloneDeep } from "lodash";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import JSZip from "jszip";
import moment from "moment";

import { Get, Post, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { getDirectory } from "actions/iqidrive";
import { getItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";
import { requestSuccess, requestError } from "utils/requestHandler";
import { PreviewPermissions, HeaderData } from "../assets";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      headerData: HeaderData,
      title: "",
      driveDataLists: {},
      downloadList: [],
      downloadedFilesCount: 0,
      showFavouriteLists: false,
      ids: [],
      directoryLayerCount: 0,
      path: "",
      downloading: false,
      openSnackBar: false,
      snackBarMessage: "",
      showDownloadedFiles: false,
      showPreviewModal: false,
      previewLoading: false,
      selectedFile: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeDriveHOC = (value, context) => {
      this.setState({ [context]: value });
    };

    onSelectDirectory = (item) =>
      this.setState(
        {
          path: item.path,
          downloadList: [],
        },
        () => {
          let tmpPath = `/${this.state.ids[this.state.ids.length - 1]}?file_path=${item.path}`;
          const tmpLastView = {
            pageParams: tmpPath,
          };
          this.props.storeLastView(tmpLastView);
          this.props.getDirectory(tmpPath);
        },
      );

    onChangeCheckboxValue = (item) => {
      const tmpList = [...this.state.downloadList];
      const index = _.findIndex(this.state.downloadList, { id: item.id });

      if (index === -1) {
        tmpList.push(item);
      } else {
        tmpList.splice(index, 1);
      }
      this.setState({ downloadList: tmpList });
    };

    getFileUrl = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      return `${getDomainURL()}/nextcloud/directories/${this.state.ids[this.state.ids.length - 1]}?file_path=${item.path}&download=1`;
    };

    //File Preview
    onSelectFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);

      if (item.file_type.toLowerCase() !== ".pdf") {
        this.setState({ previewLoading: true });
        return fetch(
          `${getDomainURL()}/nextcloud/directories/${this.state.ids[this.state.ids.length - 1]}?file_path=${item.path}&download=1`,
          { headers },
        )
          .then((response) => {
            if (response.status === 200) {
              response.blob().then((blobby) => {
                let url = URL.createObjectURL(blobby);
                let tmp = {
                  ...item,
                  url: url,
                  link: this.getFileUrl(item),
                };
                this.setState({
                  previewLoading: false,
                  showPreviewModal:
                    PreviewPermissions.indexOf(item.file_type.toLowerCase()) >
                    -1,
                  selectedFile: tmp,
                });
                if (
                  PreviewPermissions.indexOf(item.file_type.toLowerCase()) ===
                  -1
                ) {
                  FileSaver.saveAs(blobby, item.display_name);
                }
              });
            } else {
              this.setState({ previewLoading: false });
              requestError(response.statusText);
            }
          })
          .catch((error) => {
            requestError("Failed to download file. Please try again later.");
          });
      } else {
        let tmp = {
          ...item,
          link: this.getFileUrl(item),
        };

        return this.setState({
          selectedFile: tmp,
          showPreviewModal: true,
        });
      }
    };

    onDownloadFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);

      return this.setState({ downloading: true }, () => {
        return fetch(
          `${getDomainURL()}/nextcloud/directories/${this.state.ids[this.state.ids.length - 1]}?file_path=${item.path}&download=1`,
          { headers },
        )
          .then((response) => {
            return response.blob();
          })
          .then((blobby) => {
            return this.setState({ downloading: false }, () => {
              return FileSaver.saveAs(blobby, item.display_name);
            });
          })
          .catch((error) => {
            requestError("Failed to download file. Please try again later.");
          });
      });
    };

    onPressUpDirectory = () => {
      let tmpIds = this.state.ids;
      let tmpPathArray = this.state.path.split("/");
      let tmpPathArrayModified = [];

      tmpPathArray.map((item) => {
        item !== "" && tmpPathArrayModified.push(item);
      });
      tmpIds.pop();
      tmpPathArrayModified.pop();

      let tmpPath = `${tmpPathArrayModified.join("/")}/`;
      return this.setState(
        {
          ids: tmpIds,
          path: tmpPath,
        },
        () => {
          const tmpLastView = {
            pageParams: `/${this.state.ids[this.state.ids.length - 1]}?file_path=${this.state.ids.length <= 1 ? "projects-lite/" : tmpPath}`,
          };
          this.props.storeLastView(tmpLastView);

          if (this.state.showFavouriteLists && tmpIds.length === 1) {
            return this.getAllFavourites();
          } else {
            return this.props.getDirectory(
              `/${this.state.ids[this.state.ids.length - 1]}?file_path=${this.state.ids.length <= 1 ? "projects-lite/" : tmpPath}`,
              requestError,
            );
          }
        },
      );
    };

    closeDownloadedFilesList = () => {
      let tmpDownloadList = _.cloneDeep(this.state.downloadList);
      tmpDownloadList = _.filter(tmpDownloadList, (item) => !item.success);

      this.setState({
        downloadList: tmpDownloadList,
        downloadedFilesCount: 0,
        showDownloadedFiles: false,
      });
    };

    downloadMultipleFiles = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);

      let tmpDownloadedDocs = _.cloneDeep(this.state.downloadList);

      if (this.state.downloadList && this.state.downloadList.length > 0) {
        let zip = new JSZip();
        this.setState(
          { loading: true, showDownloadedFiles: true },
          async () => {
            for (
              let index = 0;
              index < this.state.downloadList.length;
              index++
            ) {
              await fetch(
                `${getDomainURL()}/nextcloud/directories/${this.state.ids[this.state.ids.length - 1]}?file_path=${tmpDownloadedDocs[index].path}&download=1`,
                { headers },
              )
                .then((response) => {
                  return response.blob();
                })
                .then((blobby) => {
                  let tmpDownloadedDocsCount = _.cloneDeep(
                    this.state.downloadedFilesCount,
                  );
                  tmpDownloadedDocs[index] = {
                    ...tmpDownloadedDocs[index],
                    done: true,
                    success: true,
                    errorMessage: "",
                    loading: false,
                  };
                  this.setState({
                    downloadedFilesCount: tmpDownloadedDocsCount + 1,
                    downloadList: tmpDownloadedDocs,
                  });

                  return this.setState({ downloading: false }, async () => {
                    zip.file(tmpDownloadedDocs[index].display_name, blobby);

                    if (index === this.state.downloadList.length - 1) {
                      zip
                        .generateAsync({ type: "blob" })
                        .then((content) => {
                          FileSaver.saveAs(
                            content,
                            `IQI Drive ${moment().format("DD MM YYYY hh:mm:ss")}`,
                          );
                        })
                        .catch((error) =>
                          requestError(
                            error.response.data.message ||
                              "Failed to zip the files. Please try again later.",
                          ),
                        );
                    }
                  });
                })
                .catch((error) => {
                  let tmpDownloadedDocsCount = _.cloneDeep(
                    this.state.downloadedFilesCount,
                  );
                  let tmpDownloadedDocs = _.cloneDeep(this.state.downloadList);
                  tmpDownloadedDocs[index] = {
                    ...tmpDownloadedDocs[index],
                    done: true,
                    success: false,
                    errorMessage: error.response
                      ? error.response.data.message
                      : "You might be disconnected from the internet because the system is unable to access to the server.",
                    loading: false,
                  };
                  this.setState({
                    downloadedFilesCount: tmpDownloadedDocsCount + 1,
                    downloadList: tmpDownloadedDocs,
                  });
                  if (error && error.response && error.response.status) {
                    if (error.response.status === 401) {
                      if (error.response.data.internal_code === 460) {
                        return requestError(error.response.data.message);
                      }
                    } else {
                      return requestError(
                        error.response.data.message ||
                          "Failed to download file. Please try again later.",
                      );
                    }
                  }
                  this.load(false);
                });
            }
            this.setState({ loading: false });
          },
        );
      }
    };

    copyURLToClipboard = () => {
      this.setState({
        openSnackBar: true,
        snackBarMessage:
          "Data copied into clipboard. You can now paste the data.",
      });
    };

    getAllFavourites = () =>
      Get(
        `/nextcloud/directories/favourite`,
        this.getAllFavouritesSucess,
        this.getAllFavouritesError,
        this.load,
      );
    getAllFavouritesSucess = (payload) => {
      this.setState({
        driveDataLists: { contents: payload },
        directoryLayerCount: 1,
        ids: ["679"],
        path: "projects-lite/",
      });
      this.props.storeLastView({ pageParams: "" });
    };
    getAllFavouritesError = (error) => requestError(error);

    onClickFavourite = (query, id) =>
      Post(
        `/nextcloud/directories/toggle_favourite/?${query}`,
        {},
        (payload) => this.onClickFavouriteSuccess(payload, id),
        this.onClickFavouriteError,
        this.load,
      );
    onClickFavouriteSuccess = (payload, id) => {
      this.setState({
        openSnackBar: true,
        snackBarMessage: payload.message,
      });

      let tmpContents = cloneDeep(this.state.driveDataLists.contents);
      let tmpIndex = _.findIndex(tmpContents, (item) => item.id === id);
      tmpContents[tmpIndex].is_favourite = !tmpContents[tmpIndex].is_favourite;

      this.setState({
        driveDataLists: {
          ...this.state.driveDataLists,
          contents: tmpContents,
        },
      });
    };
    onClickFavouriteError = (error) => requestError(error);

    clearDriveCache = (file_path, action) =>
      Put(
        `/admin/nextcloud/directories/reset_cache`,
        { file_path: file_path },
        (success) => this.clearDriveCacheSuccess(success, action),
        this.clearDriveCacheError,
        this.load,
      );
    clearDriveCacheSuccess = (success, action) => {
      const { lastView = {} } = this.props.data.lastViewReducer;
      const { pageParams } = lastView;
      if (success && typeof success.message === "string") {
        requestSuccess(success.message);
      }

      if (action === "refresh") {
        this.props.getDirectory(pageParams || "");
      }
    };
    clearDriveCacheError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            headerData={this.state.headerData}
            title={this.state.title}
            driveDataLists={this.state.driveDataLists}
            downloadList={this.state.downloadList}
            showFavouriteLists={this.state.showFavouriteLists}
            ids={this.state.ids}
            directoryLayerCount={this.state.directoryLayerCount}
            downloading={this.state.downloading}
            openSnackBar={this.state.openSnackBar}
            snackBarMessage={this.state.snackBarMessage}
            showPreviewModal={this.state.showPreviewModal}
            showDownloadedFiles={this.state.showDownloadedFiles}
            previewLoading={this.state.previewLoading}
            selectedFile={this.state.selectedFile}
            onLoadIQIDrive={this.state.loading}
            onChangeDriveHOC={this.onChangeDriveHOC}
            onClickFavourite={this.onClickFavourite}
            getAllFavourites={this.getAllFavourites}
            onSelectDirectory={this.onSelectDirectory}
            onSelectFile={this.onSelectFile}
            onDownloadFile={this.onDownloadFile}
            closeDownloadedFilesList={this.closeDownloadedFilesList}
            getFileUrl={this.getFileUrl}
            onPressUpDirectory={this.onPressUpDirectory}
            copyURLToClipboard={this.copyURLToClipboard}
            downloadMultipleFiles={this.downloadMultipleFiles}
            clearDriveCache={this.clearDriveCache}
            onChangeCheckboxValue={this.onChangeCheckboxValue}
            requestError={requestError}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
    getDirectory,
  })(WithHOC);
};

export default HOC;
