import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { numberWithCommas } from "utils/thousandSeparator";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      recruitmentGrowthData: [],
      showGrowthResult: false,
      infoboxExpand: false,
      numberValue: 30,
      periodData: {
        targetSales: {},
        grossSales: {},
        newAgent: {},
        newPerformingAgent: {},
      },
      lineChartData: {
        labels: [],
        datasets: [],
      },
      summaryData: {
        targetSales: "",
        difference: "",
        newAgent: "",
        additionalPerformingAgent: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeRecruitmentHOC = (val, context) =>
      this.setState({ [context]: val });

    getRecruitmentGrowth = (mode, params, id) =>
      Post(
        `/connector/recruitment_growth/${params}?identity_id=${id}`,
        {},
        (payload) => this.getRecruitmentGrowthSuccess(payload, mode),
        this.getRecruitmentGrowthError,
        this.load,
      );
    getRecruitmentGrowthSuccess = (payload, mode) =>
      this.setState(
        {
          recruitmentGrowthData: payload,
          showGrowthResult: mode === "CALC",
        },
        () => {
          if (mode === "CALC") {
            this.processDataForOutcomeTable();
            this.processDataForSummaryTable();
          }
        },
      );
    getRecruitmentGrowthError = (error) => requestError(error);

    processDataForOutcomeTable = () => {
      const { recruitmentStrategies } = this.state.recruitmentGrowthData;

      let periodDataTmpStore = {
        targetSales: {},
        grossSales: {},
        newAgent: {},
        newPerformingAgent: {},
      };

      periodDataTmpStore.newAgent = {
        yearly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newAgent.yearly,
        monthly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newAgent.monthly,
        weekly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newAgent.weekly,
      };
      periodDataTmpStore.newPerformingAgent = {
        yearly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newPerformingAgent.yearly,
        monthly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newPerformingAgent.monthly,
        weekly: _.isEmpty(recruitmentStrategies)
          ? "-"
          : recruitmentStrategies.newPerformingAgent.weekly,
      };
      periodDataTmpStore.targetSales = {
        yearly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesAmount.yearly,
        ),
        monthly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesAmount.monthly,
        ),
        weekly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesAmount.weekly,
        ),
      };
      periodDataTmpStore.grossSales = {
        yearly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesGross.yearly,
        ),
        monthly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesGross.monthly,
        ),
        weekly: numberWithCommas(
          !_.isEmpty(recruitmentStrategies) &&
            recruitmentStrategies.newSalesGross.weekly,
        ),
      };
      this.setState({ periodData: periodDataTmpStore });
    };

    processDataForSummaryTable = () => {
      const {
        newAgent,
        salesAmount,
        newSalesAmount,
        recruitmentStrategies,
        difference,
        newPerformingAgent,
      } = this.state.recruitmentGrowthData;

      this.setState({
        lineChartData: {
          labels: ["2022", "2023"],
          datasets: [
            {
              fill: false,
              label: "Target Sales",
              data: [salesAmount, newSalesAmount],
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgb(53, 162, 235)",
            },
          ],
        },
        summaryData: {
          targetSales: numberWithCommas(
            !_.isEmpty(recruitmentStrategies) &&
              recruitmentStrategies.newSalesAmount.yearly,
          ),
          difference: numberWithCommas(difference),
          newAgent: newAgent,
          additionalPerformingAgent: newPerformingAgent,
        },
      });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadRecruitment={this.state.loading}
          numberValue={this.state.numberValue}
          periodData={this.state.periodData}
          summaryData={this.state.summaryData}
          lineChartData={this.state.lineChartData}
          infoboxExpand={this.state.infoboxExpand}
          showGrowthResult={this.state.showGrowthResult}
          recruitmentGrowthData={this.state.recruitmentGrowthData}
          getRecruitmentGrowth={this.getRecruitmentGrowth}
          onChangeRecruitmentHOC={this.onChangeRecruitmentHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
