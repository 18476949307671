import React, { useState } from "react";
import _ from "lodash";

import Tabs from "components/Tab";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import PermittedItems from "./PermittedItems";
import PermissionSettings from "./Settings";
import Permissions from "./Permissions";
import Users from "./Users";

const Update = ({
  onClose,
  onLoadPermissionGroupUsers,
  showCreatePermissionGroup,
  showUpdatePermissionGroup,
  selectedPermissionGroup,
  updateSelectedPermissionGroup,
  onChangePermissionGroupUsersHOC,
  ...props
}) => {
  const { role } = window.location.href.includes("/admin-impersonate")
    ? props.data.currentSignInProfileReducer
    : props.data.profileReducer;
  const [selectedSection, updateSelectedSection] = useState("Overview");

  const onChangeSelectedPermissionGroup = (context, val) => {
    let tmp = _.cloneDeep(selectedPermissionGroup);
    tmp = {
      ...tmp,
      [context]: val,
    };
    onChangePermissionGroupUsersHOC("selectedPermissionGroup", tmp);
  };

  const onToggleClose = () => {
    updateSelectedSection("Permission Modules");
    onChangePermissionGroupUsersHOC("showUpdatePermissionGroup", false);
  };

  return (
    <ModalDialog
      title={"Edit Permission Group"}
      onLoad={onLoadPermissionGroupUsers}
      fullWidth={true}
      fullHeight={true}
      onClose={() => onToggleClose()}
      children={
        <>
          <Tabs
            sections={
              role === "Super Admin"
                ? ["Overview", "Permission Modules", "Permitted Items", "Users"]
                : ["Overview", "Permitted Items"]
            }
            selectedSection={selectedSection}
            onSelectSection={(val) => updateSelectedSection(val)}
          />
          {selectedSection === "Overview" && (
            <>
              <form
                className="grid-control"
                onSubmit={(e) => e.preventDefault()}
              >
                <section className="grid-full-col">
                  <h4 className="fs-4 fw-600">Details</h4>
                </section>
                <section className="grid-full-col">
                  <h2 className="at-form-input__title" required>
                    Name
                  </h2>
                  <CustomFormInput
                    type="text"
                    required={true}
                    disabled={role !== "Super Admin"}
                    inputError={
                      selectedPermissionGroup.name
                        ? ""
                        : "The entry cannot be empty."
                    }
                    placeholder={"Enter Name"}
                    value={selectedPermissionGroup.name}
                    onChangeValue={(name) => {
                      onChangeSelectedPermissionGroup("name", name);
                    }}
                  />
                </section>
                <section className="grid-full-col">
                  <h2 className="at-form-input__title" required>
                    Description
                  </h2>
                  <CustomFormInput
                    type="text"
                    required={true}
                    disabled={role !== "Super Admin"}
                    inputError={
                      selectedPermissionGroup.description
                        ? ""
                        : "The entry cannot be empty."
                    }
                    placeholder={"Enter Description"}
                    value={selectedPermissionGroup.description}
                    onChangeValue={(description) => {
                      onChangeSelectedPermissionGroup(
                        "description",
                        description,
                      );
                    }}
                  />
                </section>
                {selectedPermissionGroup.entity_settings.length > 0 && (
                  <>
                    <section className="grid-full-col">
                      <h4 className="fs-4 fw-600">Settings</h4>
                    </section>
                    <section className="grid-full-col">
                      <PermissionSettings
                        {...props}
                        selectedPermissionGroup={selectedPermissionGroup}
                        onChangePermissionGroupUsersHOC={
                          onChangePermissionGroupUsersHOC
                        }
                      />
                    </section>
                  </>
                )}
              </form>
              {role === "Super Admin" && (
                <div className="at-modal_dialog-container-footer g-3">
                  <button
                    type={"button"}
                    className="btn-new btn-new--success"
                    disabled={
                      !selectedPermissionGroup.name ||
                      !selectedPermissionGroup.description
                    }
                    onClick={() => updateSelectedPermissionGroup()}
                  >
                    Update Details
                  </button>
                  <button
                    type={"button"}
                    className="btn-new btn-new--outline-secondary"
                    onClick={() => onToggleClose()}
                  >
                    Close
                  </button>
                </div>
              )}
            </>
          )}
          {selectedSection === "Permission Modules" && (
            <Permissions
              {...props}
              onClose={() => onToggleClose()}
              selectedPermissionGroup={selectedPermissionGroup}
              updateSelectedPermissionGroup={updateSelectedPermissionGroup}
              onChangePermissionGroupUsersHOC={onChangePermissionGroupUsersHOC}
            />
          )}
          {selectedSection === "Permitted Items" && (
            <PermittedItems
              {...props}
              selectedPermissionGroup={selectedPermissionGroup}
              onChangePermissionGroupUsersHOC={onChangePermissionGroupUsersHOC}
            />
          )}
          {selectedSection === "Users" && (
            <Users
              {...props}
              selectedPermissionGroup={selectedPermissionGroup}
              onChangePermissionGroupUsersHOC={onChangePermissionGroupUsersHOC}
            />
          )}
          {!["Permission Modules", "Overview"].includes(selectedSection) && (
            <div className="at-modal_dialog-container-footer">
              <button
                className="btn-new btn-new--outline-secondary"
                onClick={() => onToggleClose()}
              >
                Close
              </button>
            </div>
          )}
        </>
      }
    />
  );
};

export default Update;
