import React from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FaUserTie } from "react-icons/fa";

import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";

import { getColorBadge } from "dictionary/badgeColor";

export const StatusColorVRC = [
  { value: "Yes", colorName: "Green" },
  { value: "No", colorName: "Red" },
  { value: undefined, colorName: "" },
];

export const RegistrationStatusColor = [
  { value: "Payment Made", colorName: "Lime" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Signing", colorName: "Violet" },
  { value: "Approved", colorName: "Green" },
  { value: "Waiting For Approval", colorName: "Blue" },
  { value: "Profile Incomplete", colorName: "Grey" },
  { value: undefined, colorName: "Grey" },
];
export const ApprovalStatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Rejected", colorName: "Grey" },
  { value: "Approved", colorName: "Green" },
  { value: "Referrer Transfer", colorName: "Yellow" },
  { value: undefined, colorName: "Grey" },
];

export const ReffererStatusColor = [
  { value: "Auto Approved", colorName: "Yellow" },
  { value: "Pending", colorName: "Grey" },
  { value: "Rejected", colorName: "Grey" },
  { value: "Approved", colorName: "Green" },
  { value: undefined, colorName: "Grey" },
];

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

export const ColumnData = ({
  role,
  profileId,
  adminAction,
  selectedUsers,
  totalCheckable,

  getPaymentDetail,
  onClickSelectAll,
  onClickCheckBox,
}) => {
  const isMobile = window.innerWidth < 800;

  return [
    ...(adminAction
      ? [
          {
            header: () => (
              <span className="at-table-head-checkbox_responsive">
                <DarkTooltip
                  classes={{ tooltip: "tooltip-arrow left" }}
                  placement={"right"}
                  enterTouchDelay={0}
                  title={
                    selectedUsers.length === totalCheckable &&
                    totalCheckable !== 0
                      ? "Deselect All"
                      : "Select All"
                  }>
                  <CustomCheckbox
                    labelStyle={{ marginBottom: 10 }}
                    checked={
                      selectedUsers.length === totalCheckable &&
                      totalCheckable !== 0
                    }
                    disabled={totalCheckable === 0}
                    onChangeCheckboxValue={() => onClickSelectAll()}
                  />
                </DarkTooltip>
              </span>
            ),
            renderColumn: (rowData) => (
              <>
                {(rowData.user_id === profileId || role === "Super Admin") &&
                  rowData.status_id === 1 && (
                    <CustomCheckbox
                      checked={_.includes(selectedUsers, rowData.id)}
                      onChangeCheckboxValue={() => onClickCheckBox(rowData.id)}
                    />
                  )}
              </>
            ),
            columnClass: "at-table--checkbox-column",
            columnStyle: { width: "10%", gridColumn: "1/-1" },
          },
        ]
      : []),
    {
      header: "Agent",
      columnStyle: { width: "140%" },
      columnClass: "at-table-head__hide-mobile",
      renderColumn: (rowData) => (
        <>
          {(rowData.user_id === profileId || role === "Super Admin") &&
            rowData.status_id === 1 && (
              <CustomCheckbox
                checked={_.includes(selectedUsers, rowData.id)}
                labelClassname={"at-table--checkbox-mobile"}
                onChangeCheckboxValue={() => onClickCheckBox(rowData.id)}
              />
            )}
          <UserInfo
            name={rowData.full_name || "N/A"}
            email={rowData.email || "N/A"}
            team_name={rowData.team || "N/A"}
            phone_number={rowData.mobile_contact_number || "N/A"}
            location={rowData.branch || "N/A"}
            created_at={
              rowData.created_at
                ? Moment(rowData.created_at, "DD/MM/YYYY").format("DD MMM YYYY")
                : "N/A"
            }
            hideRedirect={true}
          />
        </>
      ),
    },
    {
      header: "Payment Method",
      contentClass: "at-column-responsive_center",
      customClass: "at-column-responsive_center",
      columnStyle: { width: isMobile ? "100%" : "70%" },
      renderColumn: (rowData) => {
        const {
          payment_method,
          payment_method_id,
          agent_payment_proof,
          employment_proof,
          agent_registration_id,
          via_recruitment_campaign,
        } = rowData;

        return (
          <div className="new-agent-approval__payment-proof">
            <p>{payment_method}</p>
            {((payment_method_id === 1 && agent_payment_proof) ||
              (payment_method_id === 3 && employment_proof) ||
              payment_method_id === 2) && (
              <CustomButton
                className={"new-agent-approval__payment-proof-btn"}
                children={<AtlasIcon svgHref={"atlas-receipt-text"} />}
                tooltip={true}
                tooltipChildren={<span className="fs-2">Payment Proof</span>}
                tooltipPosition={"top"}
                onClick={() => {
                  if (payment_method_id === 2) {
                    getPaymentDetail(agent_registration_id);
                  } else {
                    window.open(
                      rowData[
                        payment_method_id === 1
                          ? "agent_payment_proof"
                          : "employment_proof"
                      ],
                    );
                  }
                }}
              />
            )}
            {via_recruitment_campaign === "Yes" && (
              <div
                className="at-status_badge w-100"
                style={{
                  backgroundColor: "#FFF",
                  color: "#3B82F6",
                  borderColor: "#3B82F6",
                  fontSize: "0.6rem",
                }}
              >
                {`Via Recruitment Campaign`}
              </div>
            )}
          </div>
        );
      },
    },
    {
      header: "Referrer",
      contentClass: "at-column-responsive_center",
      customClass:
        "new-agent-approval__refferal-admin at-column-responsive_center",
      renderColumn: (rowData) => {
        const { colorName = "" } =
          _.find(ReffererStatusColor, { value: rowData.referer_side_status }) ||
          {};

        return (
          <>
            {rowData.referer_full_name && (
              <>
                <p className="d-flex align-items-center">
                  <FaUserTie
                    style={{
                      width: 15,
                      height: 15,
                      fill: "#F0631D",
                      marginRight: 4,
                    }}
                  />
                  {rowData.referer_full_name || "N/A"}
                </p>
                <div
                  className="at-status_badge mt-1"
                  style={{ ...getColorBadge(colorName) }}
                >
                  {rowData.referer_side_status}
                </div>
              </>
            )}
            {!rowData.referer_full_name && <span>N/A</span>}
          </>
        );
      },
    },
    {
      header: "Assigned Admin",
      contentClass: "at-column-responsive_center",
      customClass:
        "new-agent-approval__refferal-admin at-column-responsive_center",
      columnStyle: { width: isMobile ? "100%" : "80%" },
      renderColumn: (rowData) => {
        const { colorName = "" } =
          _.find(ApprovalStatusColor, {
            value: rowData.assignee_side_status,
          }) || {};

        return (
          <>
            <p className="d-flex align-items-center">
              <AtlasIcon
                svgHref={"atlas-profile-circle"}
                style={{
                  width: 18,
                  height: 18,
                  fill: "#F0631D",
                  marginRight: 4,
                }}
              />
              {rowData.assignee_full_name}
            </p>
            <div
              className="at-status_badge mt-1"
              style={{ ...getColorBadge(colorName) }}
            >
              {rowData.assignee_side_status}
            </div>
            {(['Pending', 'Rejected'].includes(rowData.assignee_side_status) && rowData.admin_remark) && 
              <div className="new-agent-approval__reject-reason">{rowData.admin_remark}</div>}
          </>
        );
      },
    },
    {
      header: "Registration Status",
      contentClass: "at-column-responsive_center",
      customClass:
        "new-agent-approval__refferal-admin at-column-responsive_center",
      columnStyle: { width: isMobile ? "100%" : "70%" },
      renderColumn: (rowData) => {
        const { colorName = "" } =
          _.find(RegistrationStatusColor, {
            value: rowData.agent_registration_status,
          }) || {};

        return (
          <>
            {rowData.agent_registration_status && (
              <>
                <div
                  className="at-status_badge my-1"
                  style={{ ...getColorBadge(colorName) }}
                >
                  {rowData.agent_registration_status}
                </div>
                <p style={{ fontSize: 12, color: "#4B5563" }}>
                  *{rowData.export_status || "N/A"}
                </p>
              </>
            )}
            {!rowData.agent_registration_status && <p>N/A</p>}
          </>
        );
      },
    },
  ];
};
