import { useEffect, useState } from "react";

import CustomFormInput from "components/Input/formInput";
import Phone from "../components/Phone";

const From = ({
  isActive,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  handleAccordionChange,
  handleAccordionClose,
}) => {
  const [values, setValues] = useState({
    sender_email: "",
    sender_name: "",
    subject: "",
    preview_text: "",
  });

  useEffect(() => {
    if (isChecked) {
      setValues({
        sender_email: selectedEmailCampaign.sender_email,
        sender_name: selectedEmailCampaign.sender_name,
        subject: selectedEmailCampaign.subject,
        preview_text: selectedEmailCampaign.preview_text,
      });
    } else {
      setValues({
        sender_email: "",
        sender_name: "",
        subject: "",
        preview_text: "",
      });
    }
  }, [isActive]);

  const handleClose = () => {
    setValues({
      sender_email: "",
      sender_name: "",
      subject: "",  
      preview_text: "",
    });

    handleAccordionClose();
  };

  const handleChange = (key, val) => {
    setValues((values) => ({ ...values, [key]: val }));
    onChangeEmailCampaignsHOC("selectedEmailCampaign", {...selectedEmailCampaign, [key]: val});
  };

  const handleSave = () => {
    const newEmailCampaign = {
      ...selectedEmailCampaign,
      sender_email: values.sender_email,
      sender_name: values.sender_name,
      subject: values.subject,
      preview_text: values.preview_text,
      status: 1,
    };

    onChangeEmailCampaignsHOC("selectedEmailCampaign", newEmailCampaign);
    updateEmailCampaign(newEmailCampaign);
    handleAccordionClose();
  };

  const isChecked = selectedEmailCampaign.sender_email;

  return (
    <div className={"grid-control mt-3"}>
      <section className="grid-half-col">
        <div className={"mb-3"}>
          <h2 className={"at-form-input__title text-capitalize"} required>Email Address</h2>
          <CustomFormInput
            type={"email"}
            value={values.sender_email}
            onChangeValue={(val) => handleChange("sender_email", val)}
            placeholder={"your.email@example.com"}
            required={true}
          />
        </div>

        <div className={"mb-3"}>
          <h2 className={"at-form-input__title text-capitalize"} required>Name</h2>
          <CustomFormInput
            type={"text"}
            value={values.sender_name}
            onChangeValue={(val) => handleChange("sender_name", val)}
            placeholder={"Your name"}
            required={true}
          />
        </div>

        <div className={"mb-3"}>
          <h2 className={"at-form-input__title text-capitalize"} required>Subject Line</h2>
          <CustomFormInput
            type={"text"}
            value={values.subject}
            onChangeValue={(val) => handleChange("subject", val)}
            placeholder={"Your subject"}
            required={true}
          />
        </div>

        <div className={"mb-3"}>
          <h2 className={"at-form-input__title text-capitalize"} required>Preview Text</h2>
          <CustomFormInput
            type={"text"}
            value={values.preview_text}
            onChangeValue={(val) => handleChange("preview_text", val)}
            placeholder={"Your preview text"}
            required={true}
          />
        </div>
      </section>

      <section className={"grid-half-col text-center"}>
        <small className={"text-muted tiny-text"}>
          Actual email preview may vary depending on the email client.
        </small>

        <Phone
          selectedEmailCampaign={selectedEmailCampaign}
          values={values}
        />
      </section>
    </div>
  );
};

export default From;
