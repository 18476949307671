import _ from "lodash";

export default function permissionsChecker(modulesName, data) {
  let tmp = {
    can_create: true,
    can_read: true,
    can_update: true,
    can_destroy: true,
  };
  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  if (
    tmpProfileReducer.role === "Personal Assistant" &&
    data.permissionsReducer.pa_permissions.length > 0
  ) {
    tmp = _.find(data.permissionsReducer.pa_permissions, {
      module_name: modulesName,
    });
  } else if (
    tmpProfileReducer.role === "Associated Agent" &&
    data.permissionsReducer.aa_permissions.length > 0
  ) {
    tmp = _.find(data.permissionsReducer.aa_permissions, {
      module_name: modulesName,
    });
  } else if (
    tmpProfileReducer.role === "Admin" &&
    data.permissionsReducer.admin_permissions
  ) {
    tmp = _.find(data.permissionsReducer.admin_permissions, {
      module_name: modulesName,
    });
  } else if (
    data.profileReducer.role === "Project Manager" &&
    data.permissionsReducer.pm_permissions
  ) {
    tmp = _.find(data.permissionsReducer.pm_permissions, {
      module_name: modulesName,
    });
  }
  return (
    tmp || {
      can_create: false,
      can_read: false,
      can_update: false,
      can_destroy: false,
    }
  );
}
