import { STORE_PV_START_DATE, STORE_PV_END_DATE } from "./type";

export function storeSelectedStartDate(payload) {
  return {
    type: STORE_PV_START_DATE,
    payload,
  };
}

export function storeSelectedEndDate(payload) {
  return {
    type: STORE_PV_END_DATE,
    payload,
  };
}
