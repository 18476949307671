import React, { Component } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import AtlasCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import MobilePreview from "./components/mobilePreview";
import AppTheme from "./components/appTheme";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import AppThemeHOC from "./action";

import "./index.scss";

const styles = {
  tooltip: {
    minWidth: "700px",
    display: "flex",
    justifyContent: "center",
  },
  popper: {
    opacity: 1,
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);

export const StatusColor = [
  { value: "Yes", colorName: "Green" },
  { value: "No", colorName: "Red" },
];

const columnData = [
  {
    header: "Background Image",
    renderColumn: (data) => {
      return (
        <>
          <CustomTooltip
            placement={"right"}
            title={
              <img
                className="at-app-theme__tooltip-image"
                src={data.background_image_url}
                alt={data.name + " Background Image"}
              />
            }
          >
            <img src={data.thumbnail_image_url} alt={data.name} />
          </CustomTooltip>
        </>
      );
    },
  },
  { header: "App Theme Name", accessor: "name" },
  {
    header: "Icon Colour",
    renderColumn: (data) => {
      return (
        <div className={"d-flex"}>
          {!_.isEmpty(data.icon_colour) && (
            <div
              className={"at-app-theme_color-box mr-3"}
              style={{ backgroundColor: data.icon_colour }}
            />
          )}
          <p className={"my-auto"}>
            {!_.isEmpty(data.icon_colour) ? data.icon_colour : "N/A"}
          </p>
        </div>
      );
    },
  },
  {
    header: "Icon BG Colour",
    renderColumn: (data) => {
      return (
        <div className={"d-flex"}>
          {!_.isEmpty(data.bgcolour) && (
            <div
              className={"at-app-theme_color-box mr-3"}
              style={{ backgroundColor: data.bgcolour }}
            />
          )}
          <p className={"my-auto"}>
            {!_.isEmpty(data.bgcolour) ? data.bgcolour : "N/A"}
          </p>
        </div>
      );
    },
  },
  {
    header: "Current Theme",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const isCurrentTheme = rowData.is_current_theme ? "Yes" : "No";
      const { colorName = "" } =
        _.find(StatusColor, { value: isCurrentTheme }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {isCurrentTheme}
        </div>
      );
    },
  },
  { header: "Publish On", accessor: "publish_on" },
];

class AppThemeManagement extends Component {
  actionColumnContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "App Themes",
      this.props.data,
    );

    return [
      {
        name: "preview",
        onClick: (val) => {
          Promise.all([
            this.props.onChangeAppThemeManagementHOC(true, "showMobilePreview"),
          ]).then(() => {
            this.props.onChangeAppThemeManagementHOC(val, "mobilePreview");
          });
        },
      },
      {
        name: "edit",
        onShow: () => can_update,
        onClick: (val) => {
          this.props.getSelectedAppTheme(val.id);
        },
      },
      {
        name: "delete",
        color: "#F04438",
        onShow: () => can_destroy,
        onClick: (val) => {
          Promise.all([
            this.props.onChangeAppThemeManagementHOC(
              true,
              "showDeleteConfirmation",
            ),
          ]).then(() => {
            this.props.onChangeAppThemeManagementHOC(
              val.id,
              "selectedDeleteAppTheme",
            );
          });
        },
      },
    ];
  };

  componentDidMount = () => {
    this.props.getAppTheme(1, "");
  };

  renderDeleteConfirmation = () => {
    return (
      <ConfirmationModal
        open={this.props.showDeleteConfirmation}
        message={"Are you sure to delete this app theme?"}
        title={"Delete App Theme"}
        showLoading={this.props.onLoadAppTheme}
        positiveAction={() => {
          this.props.deleteAppTheme(this.props.selectedDeleteAppTheme);
        }}
        negativeAction={() =>
          this.props.onChangeAppThemeManagementHOC(
            false,
            "showDeleteConfirmation",
          )
        }
      />
    );
  };

  renderCardContent = () => {
    return (
      <AtPaginatedTable
        columns={columnData}
        actionColumnContent={this.actionColumnContent()}
        rowData={this.props.appTheme.data ? this.props.appTheme.data : []}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeAppThemeManagementHOC(val, "searchParams")
        }
        meta={this.props.appTheme.meta}
        totalPages={this.props.appThemePage}
        getListAPI={this.props.getAppTheme}
      />
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("App Themes", this.props.data);

    return (
      <>
        <ModuleHeader
          title={`App Themes`}
          moduleIcon={"icon-app-theme"}
          actionButton={[
            {
              label: "New App Theme",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeAppThemeManagementHOC(
                  true,
                  "showCreateAppThemeModal",
                ),
            },
          ]}
        />
        <AtlasCard cardContent={this.renderCardContent()} />
        <AppTheme
          selectedAppTheme={this.props.selectedAppTheme}
          open={this.props.showCreateAppThemeModal}
          onChangeAppThemeManagementHOC={
            this.props.onChangeAppThemeManagementHOC
          }
          createAppTheme={this.props.createAppTheme}
          updateAppTheme={this.props.updateAppTheme}
          onLoadAppTheme={this.props.onLoadAppTheme}
        />
        {this.renderDeleteConfirmation()}
        {this.props.showMobilePreview && (
          <MobilePreview
            mobilePreview={this.props.mobilePreview}
            onChangeAppThemeManagementHOC={
              this.props.onChangeAppThemeManagementHOC
            }
          />
        )}
        {this.props.onLoadAppTheme && <LoadingModal />}
      </>
    );
  };
}

export default AppThemeHOC(AppThemeManagement);
