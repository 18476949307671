const getApiKey = () => {
  if (window.location.host.indexOf("localhost") !== -1) {
    return "e53f89b159cc4b4c837c87e9962dfccc";
  } else if (
    window.location.host.indexOf("atlasfestaging8.iqiglobal.com") !== -1
  ) {
    return "9204831c531844e0af81ba5c4304357f";
  } else if (
    window.location.host.indexOf("atlasfestaging7.iqiglobal.com") !== -1
  ) {
    return "1dfa9ea7e14e42c3a4cddb66142ba4fa";
  } else if (
    window.location.host.indexOf("atlasfestaging5.iqiglobal.com") !== -1
  ) {
    return "67e7a0b314d84327ad4553566bdf6d83";
  } else if (
    window.location.host.indexOf("atlasfestaging4.iqiglobal.com") !== -1
  ) {
    return "7bd4379ca13749ddb37e9ce4bbf098dc";
  } else if (
    window.location.host.indexOf("atlasfestaging3.iqiglobal.com") !== -1
  ) {
    return "ed576bc5bd754603b5b9ddf2cfb8198c";
  } else if (
    window.location.host.indexOf("atlasfestaging2.iqiglobal.com") !== -1
  ) {
    return "4bc7901083d54c728cf75ddda78c9e58";
  } else if (
    window.location.host.indexOf("atlasfestaging1.iqiglobal.com") !== -1
  ) {
    return "d85163a8baad4c8a8624ed13d97353f3";
  } else if (
    window.location.host.indexOf("atlasfestaging.iqiglobal.com") !== -1
  ) {
    return "e0410317e03d4bc2807eb5b6e8376e0f";
  } else if (window.location.host.indexOf("atlas.iqiglobal.com") !== -1) {
    return "afcd49d162114c23964ca737bb2e4aa6";
  } else {
    return "e53f89b159cc4b4c837c87e9962dfccc";
  }
};
let ADOBE_PDF_EMBEDDED_API_CLIENT_ID = getApiKey();

export default ADOBE_PDF_EMBEDDED_API_CLIENT_ID;
