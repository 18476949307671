export const types = [
  {
    id: 1,
    name: "Sub-Sale",
    value: 1,
    label: "Sub-Sale",
  },
  {
    id: 2,
    name: "Sub-Rent",
    value: 2,
    label: "Sub-Rent",
  },
];

export const statuses = [
  {
    id: 0,
    name: "Draft",
    value: 0,
    label: "Draft",
    key: "option.statuses.draft",
  },
  {
    id: 1,
    name: "Active",
    value: 1,
    label: "Active",
    key: "option.statuses.active",
  },
  {
    id: 2,
    name: "Inactive/Expired",
    value: 2,
    label: "Inactive/Expired",
    key: "option.statuses.inactive_expired",
  },
  {
    id: 4,
    name: "Sold/Rent Out",
    value: 4,
    label: "Sold/Rent Out",
    key: "option.statuses.sold_rent_out",
  },
  {
    id: 5,
    name: "Deleted",
    value: 5,
    label: "Deleted",
    key: "option.statuses.deleted",
  },
];

export const measurements = [
  {
    id: 0,
    name: "",
    value: 0,
    label: "",
  },
  {
    id: 1,
    name: "Square Feet",
    value: 1,
    label: "Square Feet",
    key: "option.measurements.sqft",
  },
  {
    id: 2,
    name: "Square Meter",
    value: 2,
    label: "Square Meter",
    key: "option.measurements.sqm",
  },
  {
    id: 3,
    name: "Acres",
    value: 3,
    label: "Acres",
    key: "option.measurements.acre",
  },
  {
    id: 4,
    name: "Hectare",
    value: 4,
    label: "Hectare",
    key: "option.measurements.hectare",
  },
];

export const tenure_types = [
  {
    id: 1,
    name: "Freehold",
    value: 1,
    label: "Freehold",
    key: "option.tenure_types.freehold",
  },
  {
    id: 2,
    name: "Lease Tenancy",
    value: 2,
    label: "Lease Tenancy",
    key: "option.tenure_types.lease_tenancy",
  },
  {
    id: 3,
    name: "Leasehold",
    value: 3,
    label: "Leasehold",
    key: "option.tenure_types.leasehold",
  },
  {
    id: 4,
    name: "Malay Reserved Land",
    value: 4,
    label: "Malay Reserved Land",
    key: "option.tenure_types.malay_reserved_land",
  },
  {
    id: 5,
    name: "Unknown",
    value: 5,
    label: "Unknown",
    key: "option.unknown",
  },
];

export const title_types = [
  {
    id: 1,
    name: "Individual",
    value: 1,
    label: "Individual",
    key: "option.title_types.individual",
  },
  {
    id: 2,
    name: "Strata",
    value: 2,
    label: "Strata",
    key: "option.title_types.strata",
  },
  {
    id: 3,
    name: "Unknown",
    value: 3,
    label: "Unknown",
    key: "option.unknown",
  },
];

export const furnishing_statuses = [
  {
    id: 1,
    name: "Fully Furnished",
    value: 1,
    label: "Fully Furnished",
    key: "option.furnishing_statuses.fully_furnished",
  },
  {
    id: 5,
    name: "Fully Fitted",
    value: 5,
    label: "Fully Fitted",
    key: "option.furnishing_statuses.fully_fitted",
  },
  {
    id: 2,
    name: "Partially Furnished",
    value: 2,
    label: "Partially Furnished",
    key: "option.furnishing_statuses.partially_furnished",
  },
  {
    id: 3,
    name: "Unfurnished",
    value: 3,
    label: "Unfurnished",
    key: "option.furnishing_statuses.unfurnished",
  },
  {
    id: 4,
    name: "Unknown",
    value: 4,
    label: "Unknown",
    key: "option.unknown",
  },
];

export const directions = [
  {
    id: 1,
    name: "North",
    value: 1,
    label: "North",
    key: "option.directions.north",
  },
  {
    id: 2,
    name: "NorthEast",
    value: 2,
    label: "NorthEast",
    key: "option.directions.northeast",
  },
  {
    id: 3,
    name: "East",
    value: 3,
    label: "East",
    key: "option.directions.east",
  },
  {
    id: 4,
    name: "SouthEast",
    value: 4,
    label: "SouthEast",
    key: "option.directions.southeast",
  },
  {
    id: 5,
    name: "South",
    value: 5,
    label: "South",
    key: "option.directions.south",
  },
  {
    id: 6,
    name: "SouthWest",
    value: 6,
    label: "SouthWest",
    key: "option.directions.southwest",
  },
  {
    id: 7,
    name: "West",
    value: 7,
    label: "West",
    key: "option.directions.west",
  },
  {
    id: 8,
    name: "NorthWest",
    value: 8,
    label: "NorthWest",
    key: "option.directions.northwest",
  },
  {
    id: 9,
    name: "Unknown",
    value: 9,
    label: "Unknown",
    key: "option.unknown"
  },
];

export const occupancies = [
  {
    id: 1,
    name: "Owner Occupied",
    value: 1,
    label: "Owner Occupied",
    key: "option.occupancies.owner_occupied",
  },
  {
    id: 2,
    name: "Tenanted",
    value: 2,
    label: "Tenanted",
    key: "option.occupancies.tenanted",
  },
  {
    id: 4,
    name: "Vacant",
    value: 4,
    label: "Vacant",
    key: "option.occupancies.vacant",
  },
  {
    id: 3,
    name: "Unknown",
    value: 3,
    label: "Unknown",
    key: "option.unknown",
  },
];

export const unit_types = [
  {
    id: 0,
    name: "",
    value: 0,
    label: "",
  },
  {
    id: 1,
    name: "Corner",
    value: 1,
    label: "Corner",
    key: "option.unit_types.corner",
  },
  {
    id: 2,
    name: "Duplex",
    value: 2,
    label: "Duplex",
    key: "option.unit_types.duplex",
  },
  {
    id: 3,
    name: "Endlot",
    value: 3,
    label: "Endlot",
    key: "option.unit_types.endlot",
  },
  {
    id: 4,
    name: "Intermediate",
    value: 4,
    label: "Intermediate",
    key: "option.unit_types.intermediate",
  },
  {
    id: 5,
    name: "Penthouse",
    value: 5,
    label: "Penthouse",
    key: "option.unit_types.penthouse",
  },
  {
    id: 6,
    name: "Studio",
    value: 6,
    label: "Studio",
    key: "option.unit_types.studio",
  },
  {
    id: 7,
    name: "Triplex",
    value: 7,
    label: "Triplex",
    key: "option.unit_types.triplex",
  },
  {
    id: 8,
    name: "Unknown",
    value: 8,
    label: "Unknown",
    key: "option.unknown",
  },
  {
    id: 9,
    name: "SOHO",
    value: 9,
    label: "SOHO",
    key: "option.unit_types.soho",
  },
];

export const bedroom_options = [
  {
    id: 54,
    name: "N/A",
    value: 54,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "1+1",
    value: 2,
    label: "1+1",
    quantity: 2,
  },
  {
    id: 3,
    name: "1+2",
    value: 3,
    label: "1+2",
    quantity: 3,
  },
  {
    id: 4,
    name: "2",
    value: 4,
    label: "2",
    quantity: 2,
  },
  {
    id: 5,
    name: "2+1",
    value: 5,
    label: "2+1",
    quantity: 3,
  },
  {
    id: 6,
    name: "2+2",
    value: 6,
    label: "2+2",
    quantity: 4,
  },
  {
    id: 7,
    name: "3",
    value: 7,
    label: "3",
    quantity: 3,
  },
  {
    id: 8,
    name: "3+1",
    value: 8,
    label: "3+1",
    quantity: 4,
  },
  {
    id: 9,
    name: "3+2",
    value: 9,
    label: "3+2",
    quantity: 5,
  },
  {
    id: 10,
    name: "4",
    value: 10,
    label: "4",
    quantity: 4,
  },
  {
    id: 11,
    name: "4+1",
    value: 11,
    label: "4+1",
    quantity: 5,
  },
  {
    id: 12,
    name: "4+2",
    value: 12,
    label: "4+2",
    quantity: 6,
  },
  {
    id: 13,
    name: "5",
    value: 13,
    label: "5",
    quantity: 5,
  },
  {
    id: 14,
    name: "5+1",
    value: 14,
    label: "5+1",
    quantity: 6,
  },
  {
    id: 15,
    name: "5+2",
    value: 15,
    label: "5+2",
    quantity: 7,
  },
  {
    id: 16,
    name: "6",
    value: 16,
    label: "6",
    quantity: 6,
  },
  {
    id: 17,
    name: "6+1",
    value: 17,
    label: "6+1",
    quantity: 7,
  },
  {
    id: 18,
    name: "6+",
    value: 18,
    label: "6+",
    quantity: 6,
  },
  {
    id: 19,
    name: "7",
    value: 19,
    label: "7",
    quantity: 7,
  },
  {
    id: 20,
    name: "7+1",
    value: 20,
    label: "7+1",
    quantity: 8,
  },
  {
    id: 21,
    name: "7+",
    value: 21,
    label: "7+",
    quantity: 7,
  },
  {
    id: 22,
    name: "8",
    value: 22,
    label: "8",
    quantity: 8,
  },
  {
    id: 23,
    name: "8+1",
    value: 23,
    label: "8+1",
    quantity: 9,
  },
  {
    id: 24,
    name: "8+",
    value: 24,
    label: "8+",
    quantity: 8,
  },
  {
    id: 25,
    name: "9",
    value: 25,
    label: "9",
    quantity: 9,
  },
  {
    id: 26,
    name: "9+1",
    value: 26,
    label: "9+1",
    quantity: 10,
  },
  {
    id: 27,
    name: "9+",
    value: 27,
    label: "9+",
    quantity: 9,
  },
  {
    id: 28,
    name: "10",
    value: 28,
    label: "10",
    quantity: 10,
  },
  {
    id: 29,
    name: "10+1",
    value: 29,
    label: "10+1",
    quantity: 11,
  },
  {
    id: 30,
    name: "10+",
    value: 30,
    label: "10+",
    quantity: 10,
  },
  {
    id: 31,
    name: "11",
    value: 31,
    label: "11",
    quantity: 11,
  },
  {
    id: 32,
    name: "11+1",
    value: 32,
    label: "11+1",
    quantity: 12,
  },
  {
    id: 33,
    name: "11+",
    value: 33,
    label: "11+",
    quantity: 11,
  },
  {
    id: 34,
    name: "12",
    value: 34,
    label: "12",
    quantity: 12,
  },
  {
    id: 35,
    name: "12+1",
    value: 35,
    label: "12+1",
    quantity: 13,
  },
  {
    id: 36,
    name: "12+",
    value: 36,
    label: "12+",
    quantity: 12,
  },
  {
    id: 37,
    name: "13",
    value: 37,
    label: "13",
    quantity: 13,
  },
  {
    id: 38,
    name: "13+1",
    value: 38,
    label: "13+1",
    quantity: 14,
  },
  {
    id: 39,
    name: "13+",
    value: 39,
    label: "13+",
    quantity: 13,
  },
  {
    id: 40,
    name: "14",
    value: 40,
    label: "14",
    quantity: 14,
  },
  {
    id: 41,
    name: "14+1",
    value: 41,
    label: "14+1",
    quantity: 15,
  },
  {
    id: 42,
    name: "14+",
    value: 42,
    label: "14+",
    quantity: 14,
  },
  {
    id: 43,
    name: "15",
    value: 43,
    label: "15",
    quantity: 15,
  },
  {
    id: 44,
    name: "15+",
    value: 44,
    label: "15+",
    quantity: 15,
  },
  {
    id: 45,
    name: "16",
    value: 45,
    label: "16",
    quantity: 16,
  },
  {
    id: 46,
    name: "16+",
    value: 46,
    label: "16+",
    quantity: 16,
  },
  {
    id: 47,
    name: "17",
    value: 47,
    label: "17",
    quantity: 17,
  },
  {
    id: 48,
    name: "17+",
    value: 48,
    label: "17+",
    quantity: 17,
  },
  {
    id: 49,
    name: "18",
    value: 49,
    label: "18",
    quantity: 18,
  },
  {
    id: 50,
    name: "18+",
    value: 50,
    label: "18+",
    quantity: 18,
  },
  {
    id: 51,
    name: "19",
    value: 51,
    label: "19",
    quantity: 19,
  },
  {
    id: 52,
    name: "19+",
    value: 52,
    label: "19+",
    quantity: 19,
  },
  {
    id: 53,
    name: "20 Above",
    value: 53,
    label: "20 Above",
    quantity: 20,
  },
];

export const car_park_options = [
  {
    id: 11,
    name: "N/A",
    value: 11,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 0,
    name: "0",
    value: 0,
    label: "0",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "2",
    value: 2,
    label: "2",
    quantity: 2,
  },
  {
    id: 3,
    name: "3",
    value: 3,
    label: "3",
    quantity: 3,
  },
  {
    id: 4,
    name: "4",
    value: 4,
    label: "4",
    quantity: 4,
  },
  {
    id: 5,
    name: "5",
    value: 5,
    label: "5",
    quantity: 5,
  },
  {
    id: 6,
    name: "6",
    value: 6,
    label: "6",
    quantity: 6,
  },
  {
    id: 7,
    name: "7",
    value: 7,
    label: "7",
    quantity: 7,
  },
  {
    id: 8,
    name: "8",
    value: 8,
    label: "8",
    quantity: 8,
  },
  {
    id: 9,
    name: "9",
    value: 9,
    label: "9",
    quantity: 9,
  },
  {
    id: 10,
    name: "10",
    value: 10,
    label: "10",
    quantity: 10,
  },
];

export const bathroom_options = [
  {
    id: 11,
    name: "N/A",
    value: 11,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 0,
    name: "0",
    value: 0,
    label: "0",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "2",
    value: 2,
    label: "2",
    quantity: 2,
  },
  {
    id: 3,
    name: "3",
    value: 3,
    label: "3",
    quantity: 3,
  },
  {
    id: 4,
    name: "4",
    value: 4,
    label: "4",
    quantity: 4,
  },
  {
    id: 5,
    name: "5",
    value: 5,
    label: "5",
    quantity: 5,
  },
  {
    id: 6,
    name: "6",
    value: 6,
    label: "6",
    quantity: 6,
  },
  {
    id: 7,
    name: "7",
    value: 7,
    label: "7",
    quantity: 7,
  },
  {
    id: 8,
    name: "8",
    value: 8,
    label: "8",
    quantity: 8,
  },
  {
    id: 9,
    name: "9",
    value: 9,
    label: "9",
    quantity: 9,
  },
  {
    id: 10,
    name: "10",
    value: 10,
    label: "10",
    quantity: 10,
  },
];

export const document_types = [
  { id: 1, name: "Appointment Letter" },
  {
    id: 2,
    name: "Confirmation Letter / Co-Agent Booking Form / Physical Form / Renewal Form",
  },
  { id: 3, name: "Official Receipt" },
  { id: 4, name: "Bank In Slip / Cheque copy / Online transfer slip" },
  {
    id: 5,
    name: "Sales & Purchase Agreement (full signed set)",
  },
  {
    id: 6,
    name: "Tenancy Agreement/Renewal Letter (full signed set)",
  },
  { id: 7, name: "Authorization Letter / Power Attorney Letter" },
  { id: 8, name: "Undertaking Letter" },
  { id: 11, name: "Co Broke Letter" },
  { id: 12, name: "Sijil Setem" },
  { id: 18, name: "Non Stamping / Stamping Exemption Letter" },
  { id: 14, name: "Tax Invoice" },
  { id: 15, name: "Referral NRIC" },
  { id: 9, name: "Authorization Letter for Release/Forfeit" },
  { id: 16, name: "Miscellaneous / Others" },
];

export const claim_statuses = [
  { id: 1, name: "Submitted" },
  { id: 2, name: "Processing" },
  { id: 3, name: "Cancelled" },
  { id: 4, name: "Paid" },
  { id: 5, name: "Completed" },
  { id: 6, name: "Rejected" },
  { id: 7, name: "Reviewing" },
  { id: 8, name: "Bank In Processing" },
];

export const payment_types = [
  { id: 1, name: "Cash" },
  { id: 2, name: "Cheque" },
  { id: 6, name: "Bank Transfer" },
];

export const co_broker_representative_types = (sub_sale_type_id) => [
  {
    id: 1,
    name: sub_sale_type_id === 2 ? "Represent Landlord" : "Represent Vendor",
  },
  {
    id: 2,
    name: sub_sale_type_id === 2 ? "Represent Tenant" : "Represent Purchaser",
  },
];

export const listing_contact_types = [
  { id: 1, name: "Owner" },
  { id: 2, name: "Purchaser/Tenant" },
  { id: 3, name: "Owner's Lawyer" },
  { id: 4, name: "Purchaser/Tenant's Lawyer" },
];

export const claim_types = [
  { id: 1, name: "Claim Commission" },
  { id: 2, name: "Refund" },
  { id: 3, name: "Forfeit" },
  { id: 4, name: "Renewal" },
  { id: 5, name: "Release" },
  { id: 6, name: "Cancel Form" },
  { id: 7, name: "Request Invoice" },
  { id: 8, name: "Request Official Receipt" },
  { id: 9, name: "Request TA Stamping" },
];

export const categories = [
  {
    id: 1,
    name: "Residential",
    value: 1,
    label: "Residential",
    key: "option.categories.residential.residential",
    group_types: [
      {
        name: "Apartment/Flat",
        key: "option.categories.residential.apartment_flat",
        children: [
          {
            id: 2,
            name: "Apartment",
            key: "option.categories.residential.apartment",
          },
          {
            id: 3,
            name: "Apartment Duplex",
            key: "option.categories.residential.apartment_duplex",
          },
          {
            id: 1,
            name: "Flat",
            key: "option.categories.residential.flat",
          },
        ],
      },
      {
        name: "Condo/Serviced Residence",
        key: "option.categories.residential.condo_serviced_residence",
        children: [
          {
            id: 4,
            name: "Condominium",
            key: "option.categories.residential.condo_serviced_residence",
          },
          {
            id: 7,
            name: "Duplex",
            key: "option.categories.residential.duplex",
          },
          {
            id: 10,
            name: "Penthouse",
            key: "option.categories.residential.penthouse",
          },
          {
            id: 5,
            name: "Serviced Apartment",
            key: "option.categories.residential.serviced_apartment",
          },
          {
            id: 6,
            name: "Serviced Residence",
            key: "option.categories.residential.serviced_residence",
          },
          {
            id: 9,
            name: "Soho",
            key: "option.categories.residential.soho",
          },
          {
            id: 11,
            name: "Townhouse Condo",
            key: "option.categories.residential.townhouse_condo",
          },
          {
            id: 8,
            name: "Triplex",
            key: "option.categories.residential.triplex",
          },
        ],
      },
      {
        name: "Residential Land",
        key: "option.categories.residential.residential_land",
        children: [
          {
            id: 29,
            name: "Residential Land",
            key: "option.categories.residential.residential_land",
          },
        ],
      },
      {
        name: "Semi-D/Bungalow/Villas",
        key: "option.categories.residential.semi_d_bungalow_villas",
        children: [
          {
            id: 22,
            name: "Bungalow",
            key: "option.categories.residential.bungalow",
          },
          {
            id: 23,
            name: "Bungalow Land",
            key: "option.categories.residential.bungalow_land",
          },
          {
            id: 28,
            name: "Cluster House",
            key: "option.categories.residential.cluster_house",
          },
          {
            id: 88,
            name: "Detached House",
            key: "option.categories.residential.detached_house",
          },
          {
            id: 89,
            name: "Estate House",
            key: "option.categories.residential.estate_house",
          },
          {
            id: 24,
            name: "Link Bungalow",
            key: "option.categories.residential.link_bungalow",
          },
          {
            id: 21,
            name: "Semi-detached House",
            key: "option.categories.residential.semi_detached_house",
          },
          {
            id: 26,
            name: "Twin courtyard villa",
            key: "option.categories.residential.twin_courtyard_villa",
          },
          {
            id: 25,
            name: "Twin villas",
            key: "option.categories.residential.twin_villas",
          },
          {
            id: 90,
            name: "Villas",
            key: "option.categories.residential.villas",
          },
          {
            id: 27,
            name: "Zero-lot Bungalow",
            key: "option.categories.residential.zero_lot_bungalow",
          },
        ],
      },
      {
        name: "Terrace/Link/Townhouse",
        key: "option.categories.residential.terrace_link_townhouse",
        children: [
          {
            id: 13,
            name: "1.5-sty Terrace/Link House",
            key: "option.categories.residential.one_half_sty_terrace_link_house",
          },
          {
            id: 12,
            name: "1-sty Terrace/Link House",
            key: "option.categories.residential.one_sty_terrace_link_house",
          },
          {
            id: 15,
            name: "2.5-sty Terrace/Link House",
            key: "option.categories.residential.two_half_sty_terrace_link_house",
          },
          {
            id: 14,
            name: "2-sty Terrace/Link House",
            key: "option.categories.residential.two_sty_terrace_link_house",
          },
          {
            id: 18,
            name: "3.5-sty Terrace/Link House",
            key: "option.categories.residential.three_half_sty_terrace_link_house",
          },
          {
            id: 74,
            name: "3 Storey Superlink Homes",
            key: "option.categories.residential.three_storey_superlink_homes",
          },
          {
            id: 16,
            name: "3-sty Terrace/Link House",
            key: "option.categories.residential.three_sty_terrace_link_house",
          },
          {
            id: 20,
            name: "4.5-sty Terrace/Link House",
            key: "option.categories.residential.four_half_sty_terrace_link_house",
          },
          {
            id: 19,
            name: "4-sty Terrace/Link House",
            key: "option.categories.residential.four_sty_terrace_link_house",
          },
          {
            id: 73,
            name: "Double Storey Superlink Homes",
            key: "option.categories.residential.double_storey_superlink_homes",
          },
          {
            id: 17,
            name: "Townhouse",
            key: "option.categories.residential.townhouse",
          },
        ],
      },
      {
        name: "HDB",
        key: "option.categories.residential.hdb",
        children: [
          {
            id: 75,
            name: "HDB 1 RM",
            key: "option.categories.residential.hdb_one_rm"
          },
          {
            id: 76,
            name: "HDB 2 RM",
            key: "option.categories.residential.hdb_two_rm"
          },
          {
            id: 77,
            name: "HDB 3 RM",
            key: "option.categories.residential.hdb_three_rm"
          },
          {
            id: 78,
            name: "HDB 4 RM",
            key: "option.categories.residential.hdb_four_rm"
          },
          {
            id: 79,
            name: "HDB 5 RM",
            key: "option.categories.residential.hdb_five_rm"
          },
          {
            id: 80,
            name: "HDB Executive Apartment",
            key: "option.categories.residential.hdb_executive_apartment"
          },
          {
            id: 81,
            name: "HDB Executive Maisonette",
            key: "option.categories.residential.hdb_executive_maisonette"
          },
          {
            id: 82,
            name: "HDB Jumbo",
            key: "option.categories.residential.hdb_jumbo"
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Commercial",
    value: 2,
    label: "Commercial",
    key: "option.categories.commercial.commercial",
    group_types: [
      {
        name: "Commercial Land",
        key: "option.categories.commercial.commercial_land",
        children: [
          {
            id: 43,
            name: "Commercial Land",
            key: "option.categories.commercial.commercial_land",
          },
        ],
      },
      {
        name: "Hotel/Resort",
        key: "option.categories.commercial.hotel_resort",
        children: [
          {
            id: 42,
            name: "Hotel/Resort",
            key: "option.categories.commercial.hotel_resort",
          },
        ],
      },
      {
        name: "Shop/Office/Retail Space",
        key: "option.categories.commercial.shop_office_retail_space",
        children: [
          {
            id: 38,
            name: "Business Centre",
            key: "option.categories.commercial.business_centre",
          },
          {
            id: 39,
            name: "Commercial Bungalow",
            key: "option.categories.commercial.commercial_bungalow",
          },
          {
            id: 41,
            name: "Commercial Semi-D",
            key: "option.categories.commercial.commercial_semi_d",
          },
          {
            id: 40,
            name: "Design Suites",
            key: "option.categories.commercial.design_suites",
          },
          {
            id: 83,
            name: "Dormitory",
            key: "option.categories.commercial.dormitory",
          },
          {
            id: 84,
            name: "F & B Space",
            key: "option.categories.commercial.f_b_space",
          },
          {
            id: 85,
            name: "HDB Hawker Stall",
            key: "option.categories.commercial.hdb_hawker_stall",
          },
          {
            id: 30,
            name: "Office",
            key: "option.categories.commercial.office",
          },
          {
            id: 37,
            name: "Retail-Office",
            key: "option.categories.commercial.retail_office",
          },
          {
            id: 35,
            name: "Retail Space",
            key: "option.categories.commercial.retail_space",
          },
          {
            id: 31,
            name: "Shop House",
            key: "option.categories.commercial.shop_house",
          },
          {
            id: 87,
            name: "Shop Office",
            key: "option.categories.commercial.shop_office",
          },
          {
            id: 86,
            name: "Shop Space (Mall)",
            key: "option.categories.commercial.shop_space_mall",
          },
          {
            id: 34,
            name: "Sofo",
            key: "option.categories.commercial.sofo",
          },
          {
            id: 33,
            name: "Soho",
            key: "option.categories.commercial.soho",
          },
          {
            id: 36,
            name: "Sovo",
            key: "option.categories.commercial.sovo",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Industrial",
    value: 3,
    label: "Industrial",
    key: "option.categories.industrial.industrial",
    group_types: [
      {
        name: "Factory/Warehouse",
        key: "option.categories.industrial.factory_warehouse",
        children: [
          {
            id: 47,
            name: "Detached factory",
            key: "option.categories.industrial.detached_factory",
          },
          {
            id: 44,
            name: "Factory",
            key: "option.categories.industrial.factory",
          },
          {
            id: 49,
            name: "Light Industrial",
            key: "option.categories.industrial.light_industrial",
          },
          {
            id: 48,
            name: "Link Factory",
            key: "option.categories.industrial.link_factory",
          },
          {
            id: 45,
            name: "Semi-D Factory",
            key: "option.categories.industrial.semi_d_factory",
          },
          {
            id: 46,
            name: "Warehouse",
            key: "option.categories.industrial.warehouse",
          },
        ],
      },
      {
        name: "Industrial Land",
        key: "option.categories.industrial.industrial_land",
        children: [
          {
            id: 50,
            name: "Industrial Land",
            key: "option.categories.industrial.industrial_land",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Agricultural",
    value: 4,
    label: "Agricultural",
    key: "option.categories.agricultural.agricultural",
    group_types: [
      {
        name: "Agricultural Land",
        key: "option.categories.agricultural.agricultural_land",
        children: [
          {
            id: 51,
            name: "Agricultural Land",
            key: "option.categories.agricultural.agricultural_land",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "Unknown",
    value: 5,
    label: "Unknown",
    key: "option.unknown",
    group_types: [
      {
        name: "Unknown",
        key: "option.unknown",
        children: [
          {
            id: 52,
            name: "Unknown",
            key: "option.unknown",
          },
        ],
      },
    ],
  },
];

export const currency = [
  { value: "MYR", label: "MYR" },
  { value: "USD", label: "USD" },
  { value: "AED", label: "AED" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "CNY", label: "CNY" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "INR", label: "INR" },
  { value: "JPY", label: "JPY" },
  { value: "KHR", label: "KHR" },
  { value: "PHP", label: "PHP" },
  { value: "SGD", label: "SGD" },
  { value: "THB", label: "THB" },
  { value: "TRY", label: "TRY" },
  { value: "VND", label: "VND" },
];

export const statusColor = [
  { id: 1, value: "Submitted", colorName: "Blue" },
  { id: 2, value: "Processing", colorName: "Cyan" },
  { id: 3, value: "Cancelled", colorName: "Red" },
  { id: 4, value: "Paid", colorName: "Lime" },
  { id: 5, value: "Completed", colorName: "Green" },
  { id: 6, value: "Rejected", colorName: "Red" },
  { id: 7, value: "Reviewing", colorName: "Fuchsia" },
  { id: 8, value: "Checking", colorName: "Yellow" },
];

export const edgePropDataMapping = [
  { edgeProp: "Penang", atlas: "Pulau Pinang" },
  { edgeProp: "Labuan", atlas: "Wilayah Persekutuan Lubuan" },
  { edgeProp: "Kuala Lumpur", atlas: "Wilayah Persekutuan Kuala Lumpur" },
  { edgeProp: "Putrajaya", atlas: "Wilayah Persekutuan Putrajaya" },
]
export const facilties = [
  {
    name: "All Day Security",
    props: "all_day_security",
    key: "option.facilities.all_day_security",
  },
  {
    name: "BBQ",
    props: "bbq",
    key: "option.facilities.bbq",
  },
  {
    name: "Business Centre",
    props: "business_centre",
    key: "option.facilities.business_centre",
  },
  {
    name: "Cafeteria",
    props: "cafetria",
    key: "option.facilities.cafeteria",
  },
  {
    name: "Club House",
    props: "club_house",
    key: "option.facilities.club_house",
  },
  {
    name: "Gymnasium",
    props: "gymnasium",
    key: "option.facilities.gymnasium",
  },
  {
    name: "Jacuzzi",
    props: "jacuzzi",
    key: "option.facilities.jacuzzi",
  },
  {
    name: "Jogging Track",
    props: "jogging_track",
    key: "option.facilities.jogging_track",
  },
  {
    name: "Mini Market",
    props: "mini_market",
    key: "option.facilities.mini_market",
  },
  {
    name: "Nursery",
    props: "nursery",
    key: "option.facilities.nursery",
  },
  {
    name: "Parking",
    props: "parking",
    key: "option.facilities.parking",
  },
  {
    name: "Playground",
    props: "playground",
    key: "option.facilities.playground",
  },
  {
    name: "Salon",
    props: "salon",
    key: "option.facilities.salon",
  },
  {
    name: "Sauna",
    props: "sauna",
    key: "option.facilities.sauna",
  },
  {
    name: "Squash Court",
    props: "squash_court",
    key: "option.facilities.squash_court",
  },
  {
    name: "Swimming Pool",
    props: "swimming_pool",
    key: "option.facilities.swimming_pool",
  },
  {
    name: "Tennis Court",
    props: "tennis_court",
    key: "option.facilities.tennis_court",
  },
  {
    name: "Wading Pool",
    props: "wading_pool",
    key: "option.facilities.wading_pool",
  },
];