import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import CompanyEventsBeta from "../../CompanyEventsBeta";
import TeamHierarchy from "./HierarchySales";
import TeamMembers from "./TeamMembers";

import permissionsChecker from "utils/permissionsChecker";

class MyTeam extends Component {
  state = {
    tabSection: [],
    selectedSection: "",
  };

  componentDidMount = () => {
    const { can_read: membersRead } = permissionsChecker(
      "Team Members",
      this.props.data,
    );
    const { can_read: hierarchyRead } = permissionsChecker(
      "My Hierarchy",
      this.props.data,
    );
    const { can_read: birthdayRead } = permissionsChecker(
      "Team Birthdays",
      this.props.data,
    );

    if (membersRead) {
      this.setState((ps) => ({
        tabSection: [
          ...ps.tabSection,
          { icon: "atlas-profile2user", label: "Members" },
        ],
      }));
    }

    if (hierarchyRead) {
      this.setState((ps) => ({
        tabSection: [
          ...ps.tabSection,
          { icon: "atlas-my-hierarchy", label: "Hierarchy" },
        ],
      }));
    }

    if (birthdayRead) {
      this.setState((ps) => ({
        tabSection: [
          ...ps.tabSection,
          { icon: "atlas-cake", label: "Birthdays" },
        ],
      }));
    }
  };

  componentDidUpdate = (prevProp, prevState) => {
    if (prevState.tabSection !== this.state.tabSection) {
      this.setState({ selectedSection: this.state.tabSection[0].label });
    }
  };

  renderCardContent = () => (
    <>
      <CustomTab
        sections={this.state.tabSection}
        selectedSection={this.state.selectedSection}
        onSelectSection={(val) => this.setState({ selectedSection: val })}
      />
      {this.state.selectedSection === "Members" && <TeamMembers />}
      {this.state.selectedSection === "Hierarchy" && <TeamHierarchy />}
      {this.state.selectedSection === "Birthdays" && (
        <CompanyEventsBeta
          fromTeam={true}
          isBirthday={true}
          location={window.location}
        />
      )}
    </>
  );

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"My Team"}
          atlasIcon={"atlas-profile-circle"}
          showPABadge={role === "Personal Assistant"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return { data: state };
};

export default connect(mapStateToProps)(MyTeam);
