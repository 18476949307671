import React, { Component } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import AtlasNewPaginatedTable from "components/NewPaginatedTable";
import CreateNewApiModal from "./Create";

import ApiKeyManagementHOC from "./actions";

const ColumnData = [
  {
    header: "App Name",
    accessor: "app_name",
    columnStyle: {
      width: "120%",
    },
  },
  {
    header: "Token",
    accessor: "token",
    columnStyle: {
      width: "120%",
    },
  },
  {
    header: "Created At",
    accessor: "created_at",
    columnStyle: {
      width: "40%",
    },
  },
];

class ApiKeyManagement extends Component {
  componentDidMount = () => {
    this.props.getApiKeys(1, "");
  };

  createEntity = (data) => this.props.createApiKey(data);
  deleteSelectedEntity = () =>
    this.props.deleteApiKey(this.props.toBeDeletedItem.id);

  renderCreateApiKeyDialog = () => (
    <AtlasDialog open={this.props.showCreateApiKey}>
      <AtlasCard
        className={"h-100"}
        cardContent={
          <CreateNewApiModal
            title={"New"}
            onLoad={this.props.onLoadApiKey}
            submitData={(data) => this.createEntity(data)}
            onClose={() =>
              this.props.onChangeApiKeysHOC(false, "showCreateApiKey")
            }
          />
        }
      />
    </AtlasDialog>
  );

  render = () => {
    const {
      searchParams,
      allApiKeys,
      totalPages,
      getApiKeys,
      onChangeApiKeysHOC,
    } = this.props;

    return (
      <>
        <ModuleHeader
          title={`API Keys`}
          moduleIcon={"icon-api-key-mngt"}
          actionButton={[
            {
              label: "New API Key",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () => onChangeApiKeysHOC(true, "showCreateApiKey"),
            },
          ]}
        />
        <AtlasCard
          cardContent={
            <AtlasNewPaginatedTable
              columns={ColumnData}
              rowData={allApiKeys.data ? allApiKeys.data : []}
              meta={allApiKeys.meta ? allApiKeys.meta : null}
              actionColumnContent={[
                {
                  name: "delete",
                  onClick: (rowData) => {
                    this.props.onChangeApiKeysHOC(rowData, "toBeDeletedItem");
                    this.props.onChangeApiKeysHOC(true, "showDeleteApiKey");
                  },
                },
              ]}
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeApiKeysHOC(val, "searchParams")
              }
              getListAPI={getApiKeys}
              totalPages={totalPages}
            />
          }
        />
        {this.props.showCreateApiKey && this.renderCreateApiKeyDialog()}
        <DeleteConfirmationModal
          open={this.props.showDeleteApiKey}
          loading={this.props.onLoadApiKey}
          positiveAction={this.deleteSelectedEntity}
          negativeAction={() => onChangeApiKeysHOC(false, "showDeleteApiKey")}
          message={`You will not be able to recover the data once deleted.`}
        />
        {this.props.onLoadApiKey && <LoadingModal />}
      </>
    );
  };
}

export default ApiKeyManagementHOC(ApiKeyManagement);
