import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post, Put, Delete } from "utils/axios";

const SubmissionHOC = (WrappedComponent) => {
  class SubmissionWrappedComponent extends Component {
    state = {
      submissions: [],
      loading: false,
      onLoadClaimChecking: false,
      selectedSubmission: {
        type_id: "",
        description: "",
        remark: "",
        claim_party_id: 0,
      },
      initialSubmission: {
        type_id: "",
        description: "",
        remark: "",
        claim_party_id: 0,
      },
      toDeleteSubmission: {
        subsales_claim_id: "",
        submission_id: "",
      },
      showConfirmCreateSubmission: false,
      showResubmitSubmission: false,
      showViewSubmission: false,
      showUpdateSubmission: false,
      showDeleteSubmission: false,
      showCreateSubmission: false,
      showRejectSubmissionReason: false,
      showApproveConfirmation: false,
      showCompleteConfirmation: false,
      showSeniorApproveConfirmation: false,
      showReassignModal: false,
      refundParticipants: [],

      rejectReason: "",
      disabledApprove: false,
      showCloseConfirmation: false,
      isCreateSubmissionError: false,
      countDownTime: null,
      approvalCountDownTime: null,
    };

    constructor(props) {
      super(props);

      this.createCountDown = null;
      this.approveCountDown = null;
    }

    load = (param) => this.setState({ loading: param });

    onChangeSubmissionHOC = (key, val) => this.setState({ [key]: val });

    onCloseCopyRemarkConfirmation = () => {
      this.setState({ 
        showCloseConfirmation: false,
        isCreateSubmissionError: false,
        showCreateSubmission: false
      });
    }

    getSubmissions = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/submissions`,
        this.getSubmissionsSuccess,
        this.getSubmissionsError,
        this.load,
      );
    getSubmissionsSuccess = (payload) => {
      let tempData = _.map(payload.sub_sale_claim_submissions, (item) => ({
        ...item,
        submitted_date: item.submitted_date
          ? Moment(item.submitted_date, "DD-MM-YYYY HH:mm").format(
              "DD MMM YYYY HH:mm",
            )
          : "N/A",
      }));
      this.setState({ submissions: tempData });
    };
    getSubmissionsError = (error) => requestError(error);

    // only call when status is Submitted, after call successful the status updated to Checking  
    checkingSubmission = (claim_id, submission_id) => {
      this.setState({ onLoadClaimChecking: true });
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/check`,
        {},
        () => this.checkingSubmissionSuccess(claim_id, submission_id),
        this.checkingSubmissionError,
        () => {},
      );
    }
    checkingSubmissionSuccess = (claim_id, submission_id) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      Promise.all([
        this.props.getSubmittedSubsaleClaims(currentPage, searchParams),
        this.props.getSelectedClaim(claim_id),
        this.getSubmissions(claim_id),
        this.getSelectedSubmission(claim_id, submission_id)
      ]).then(() => {
        this.setState({ onLoadClaimChecking: false });
      })
    };
    checkingSubmissionError = (error) => {
      this.setState({ onLoadClaimChecking: false });
      requestError(error)
    };

    getSelectedSubmission = (claim_id, submission_id, actionType = "edit") =>
      Get(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}`,
        (payload) => this.getSelectedSubmissionSuccess(payload, actionType),
        this.getSelectedSubmissionError,
        this.load,
      );
    getSelectedSubmissionSuccess = (payload, actionType) =>
      this.setState({
        selectedSubmission: payload,
        initialSubmission: payload,
        ...(actionType !== "edit"
          ? { showViewSubmission: true }
          : { showUpdateSubmission: true }),
      });
    getSelectedSubmissionError = (error) => requestError(error);

    deleteSelectedSubmission = () => {
      const { subsales_claim_id, submission_id } =
        this.state.toDeleteSubmission;
      Delete(
        `/admin/sub_sale_claims/${subsales_claim_id}/submissions/${submission_id}`,
        (payload) =>
          this.deleteSelectedSubmissionSuccess(payload, subsales_claim_id),
        this.deleteSelectedSubmissionError,
        this.load,
      );
    };
    deleteSelectedSubmissionSuccess = (payload, claim_id) => {
      requestSuccess("Submission is removed successfully.");
      this.onChangeSubmissionHOC("showDeleteSubmission", false);
      this.getSubmissions(claim_id);
    };
    deleteSelectedSubmissionError = (error) => requestError(error);

    createSubmission = (id, data) => {
      let temp = _.cloneDeep(data);
      temp.type_id !== 1 && delete temp.claim_party_id;
      Post(
        `/admin/sub_sale_claims/${id}/submissions`,
        temp,
        () => this.createSubmissionSuccess(id),
        error => this.createSubmissionError(error, data),
        this.load,
      );
    };
    createSubmissionSuccess = (id) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.setState({
        showCreateSubmission: false,
        showConfirmCreateSubmission: false,
        countDownTime: 5
      });
      this.createCountDown = setInterval(() => {
        let tempCountDown = _.cloneDeep(this.state.countDownTime);
        if (tempCountDown > 0) {
          this.setState({ countDownTime: tempCountDown - 1 });
        } else {
          clearInterval(this.createCountDown);
          this.setState({ countDownTime: null });
        }
      }, 1000);
      requestSuccess("Submission is created successfully.");
      this.getSubmissions(id);
      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
    };
    createSubmissionError = (error, data) => {
      requestError(error);
      this.setState({
        showConfirmCreateSubmission: false,
        isCreateSubmissionError: data?.description?.trim() !=="" && [2, 3, 5].indexOf(data.type_id) === -1 // Except refund and release submission, this flag used to determined whether to show "Copy Remark & Close" confirmation dialog
      })
    };

    updateSubmission = (claim_id, submission_id, data) => {
      let temp = _.cloneDeep(data);
      temp.type_id !== 1 && delete temp.claim_party_id;
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}`,
        temp,
        () => this.updateSubmissionSuccess(claim_id, submission_id),
        this.updateSubmissionError,
        this.load,
      );
    };
    updateSubmissionSuccess = (claim_id, submission_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Submission is updated successfully.");
      this.getSelectedSubmission(claim_id, submission_id);
      this.getSubmissions(claim_id);
      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
    };
    updateSubmissionError = (error) => requestError(error);

    approveSubmission = (claim_id, submission_id) =>
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/approve`,
        {},
        (payload) =>
          this.approveSubmissionSuccess(claim_id, submission_id, payload),
        this.approveSubmissionError,
        this.load,
      );
    approveSubmissionSuccess = (claim_id, submission_id, payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      if (_.includes([7, 8, 9], payload.type_id)) {
        requestSuccess("Submission has been approved.");
      } else if (payload.type_id === 6) {
        requestSuccess("Claim form has been cancelled successfully.");
      } else {
        requestSuccess("Submission has been sent for reviewing.");
      }
      this.getSubmissions(claim_id);
      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);

      this.setState(
        {
          showApproveConfirmation: false,
          showUpdateSubmission: false,
          approvalCountDownTime: 30,
        },
        () => {
          this.approveCountDown = setInterval(() => {
            let tempCountDown = _.cloneDeep(this.state.approvalCountDownTime);
            if (tempCountDown > 0) {
              this.setState({ approvalCountDownTime: tempCountDown - 1 });
            } else {
              clearInterval(this.approveCountDown);
              this.setState({
                approvalCountDownTime: null,
                disabledApprove: false,
              });
            }
          }, 1000);
        },
      );
    };
    approveSubmissionError = (error) => {
      requestError(error);
      this.setState({ disabledApprove: false });
    };

    rejectSubmission = (claim_id, submission_id) =>
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/reject`,
        { reason: this.state.rejectReason },
        () => this.rejectSubmissionSuccess(claim_id, submission_id),
        this.rejectSubmissionError,
        this.load,
      );
    rejectSubmissionSuccess = (claim_id, submission_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Submission has been rejected.");
      this.getSubmissions(claim_id);
      this.setState({
        rejectReason: "",
        showRejectSubmissionReason: false,
        showUpdateSubmission: false,
      });
      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
    };
    rejectSubmissionError = (error) => requestError(error);

    // for senior admin to approve submissions
    seniorApproveSubmission = (claim_id, submission_id) =>
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/senior_admin_approve`,
        {},
        () => this.seniorApproveSubmissionSuccess(claim_id, submission_id),
        this.seniorApproveSubmissionError,
        this.load,
      );
    seniorApproveSubmissionSuccess = (claim_id, submission_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Submission has been exported to ERP.");
      this.setState({
        showSeniorApproveConfirmation: false,
        countDownTime: 5,
      });
      this.createCountDown = setInterval(() => {
        let tempCountDown = _.cloneDeep(this.state.countDownTime);
        if (tempCountDown > 0) {
          this.setState({ countDownTime: tempCountDown - 1 });
        } else {
          clearInterval(this.createCountDown);
          this.setState({ countDownTime: null, disabledApprove: false });
        }
      }, 1000);
      this.getSelectedSubmission(claim_id, submission_id);
      this.getSubmissions(claim_id);
      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
    };
    seniorApproveSubmissionError = (error) => {
      requestError(error);
      this.setState({ disabledApprove: false });
    };

    completeSubmission = (claim_id, submission_id) =>
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/close`,
        {},
        () => this.completeSubmissionSuccess(claim_id, submission_id),
        this.completeSubmissionError,
        this.load,
      );
    completeSubmissionSuccess = (claim_id, submission_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSubmissions(claim_id);
      this.setState({
        showCompleteConfirmation: false,
        showUpdateSubmission: false,
      });
      requestSuccess("Submission has been completed.");
    };
    completeSubmissionError = (error) => requestError(error);

    resubmitSubmission = (claim_id, submission_id) => {
      Put(
        `/admin/sub_sale_claims/${claim_id}/submissions/${submission_id}/resubmit`,
        {},
        () => this.resubmitSubmissionSuccess(claim_id),
        this.resubmitSubmissionError,
        this.load,
      );
    };
    resubmitSubmissionSuccess = (claim_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSubmissions(claim_id);

      this.setState({
        showResubmitSubmission: false,
        showUpdateSubmission: false,
      });
      requestSuccess("Submission has been resubmitted successfully.");
    };
    resubmitSubmissionError = (error) => requestError(error);

    getRefundParticipants = (id, submission_type_id) =>
      Get(
        `/admin/sub_sale_claims/${id}/refund_participants/?submission_type_id=${submission_type_id}`,
        this.getRefundParticipantsSuccess,
        this.getRefundParticipantsError,
        this.load,
      );
    getRefundParticipantsSuccess = (payload) => this.setState({ refundParticipants: payload });
    getRefundParticipantsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeSubmissionHOC={this.onChangeSubmissionHOC}
            getSubmissions={this.getSubmissions}
            checkingSubmission={this.checkingSubmission}
            getSelectedSubmission={this.getSelectedSubmission}
            createSubmission={this.createSubmission}
            updateSubmission={this.updateSubmission}
            approveSubmission={this.approveSubmission}
            rejectSubmission={this.rejectSubmission}
            seniorApproveSubmission={this.seniorApproveSubmission}
            completeSubmission={this.completeSubmission}
            deleteSelectedSubmission={this.deleteSelectedSubmission}
            resubmitSubmission={this.resubmitSubmission}
            getRefundParticipants={this.getRefundParticipants}
            onCloseCopyRemarkConfirmation={this.onCloseCopyRemarkConfirmation}
            showConfirmCreateSubmission={this.state.showConfirmCreateSubmission}
            onLoadClaimChecking={this.state.onLoadClaimChecking}
            showCloseConfirmation={this.state.showCloseConfirmation}
            isCreateSubmissionError={this.state.isCreateSubmissionError}
            refundParticipants={this.state.refundParticipants}
            disabledApprove={this.state.disabledApprove}
            showReassignModal={this.state.showReassignModal}
            showResubmitSubmission={this.state.showResubmitSubmission}
            showViewSubmission={this.state.showViewSubmission}
            approvalCountDownTime={this.state.approvalCountDownTime}
            countDownTime={this.state.countDownTime}
            selectedSubmission={this.state.selectedSubmission}
            initialSubmission={this.state.initialSubmission}
            submissions={this.state.submissions}
            rejectReason={this.state.rejectReason}
            onLoadSubmissions={this.state.loading}
            showUpdateSubmission={this.state.showUpdateSubmission}
            showDeleteSubmission={this.state.showDeleteSubmission}
            showCreateSubmission={this.state.showCreateSubmission}
            showSeniorApproveConfirmation={
              this.state.showSeniorApproveConfirmation
            }
            showRejectSubmissionReason={this.state.showRejectSubmissionReason}
            showApproveConfirmation={this.state.showApproveConfirmation}
            showCompleteConfirmation={this.state.showCompleteConfirmation}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(SubmissionWrappedComponent);
};

export default SubmissionHOC;
