import React from "react";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
);

const UploadContent = ({
  documents,
  onLoadDocsFinance,
  uploadFinanceDocs,
  onChangeFinanceDocsHOC,
}) => (
  <>
    <FilePond
      acceptedFileTypes={["application/pdf"]}
      labelIdle={"Click to upload"}
      allowMultiple={true}
      fullWidth
      maxFiles={10}
      onremovefile={() => onChangeFinanceDocsHOC([], "documents")}
      onupdatefiles={(fileItems) => {
        let temp = [];
        fileItems.length > 0 &&
          fileItems.map((fileItem) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              temp.push({
                document: e.target.result,
                document_file_name: fileItem.file.name,
                loading: true,
                result: null,
                messages: "",
              });
              onChangeFinanceDocsHOC(temp, "documents");
            };
            reader.readAsDataURL(fileItem.file);
          });
      }}
    />
    <div className="d-flex at-modal_dialog-container-footer">
      <button
        disabled={documents.length === 0}
        className={"btn-new btn-new--primary"}
        onClick={() => uploadFinanceDocs(documents)}
      >
        Upload
      </button>
      <button
        type={"button"}
        className="btn-new btn-new--outline-secondary ml-2"
        onClick={() => onChangeFinanceDocsHOC(false, "showUploadModal")}
      >
        Cancel
      </button>
    </div>
    {onLoadDocsFinance && <LoadingModal />}
  </>
);

export default UploadContent;
