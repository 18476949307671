import React, { useEffect, useState } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";

import ReassignHOC from "./actions/reassignSubmission";

const typeDictionary = [
  { id: "1", label: "Assigned Admin" },
  { id: "2", label: "Reviewing Admin" },
];

const Reassign = ({
  adminOptions,
  profileId,
  role,
  onLoadReassign,
  selectedSubmission,

  submitReassign,
  getAdminRoles,
  onClose,
}) => {
  const [reassignForm, onChangeForm] = useState({
    type_id: "1",
    from_admin_remark: "",
    to_admin_id: null,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(reassignForm);
    temp[context] = val;

    onChangeForm( prev => temp);
  };

  useEffect(() => {
    let temp = null;
    if (
      profileId === selectedSubmission.assignee_id &&
      profileId === selectedSubmission.senior_assignee_id
    ) {
      temp = "3";
    } else {
      if (profileId === selectedSubmission.assignee_id) {
        temp = "1";
      } else if (profileId === selectedSubmission.senior_assignee_id) {
        temp = "2";
      }
      getAdminRoles(temp === "3" ? "5" : "1");
      onChangeField(temp, "type_id");
    }
  }, []);

  return (
    <>
      <ModalDialog
        title={"Reassign Task"}
        onClose={onClose}
        children={
          <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Type</h2>
              <AtlasRadioGroup
                valueKey={"id"}
                horizontal={true}
                checkedValue={reassignForm.type_id}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => {
                  if (role === "Super Admin") onChangeField(val, "type_id");
                }}
                options={typeDictionary}
              />
            </section>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Assignee</h2>
              <CustomTypeahead
                showClearButton
                selectedValue={
                  reassignForm &&
                  _.find(adminOptions, {
                    id: reassignForm.to_admin_id,
                  })
                    ? [
                        _.find(adminOptions, {
                          id: reassignForm.to_admin_id,
                        }),
                      ]
                    : []
                }
                typeaheadId={"admin"}
                options={adminOptions || []}
                filterBy={["name"]}
                labelKey={"name"}
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    onChangeField(val[0].id, "to_admin_id");
                  }
                }}
                onClear={(val) => {
                  onChangeField('', "to_admin_id");
                }}
                childrenHead={(rowItem) => <p>{rowItem.name}</p>}
              />
            </section>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Remark</h2>
              <AtlasTextarea
                value={reassignForm.from_admin_remark}
                onChangeValue={(val) => onChangeField(val, "from_admin_remark")}
              />
            </section>
          </form>
        }
        footer={
          <button
            className="btn-new btn-new--primary"
            disabled={_.values(reassignForm).some((item) => !item)}
            onClick={() => {
              submitReassign({
                ...reassignForm,
                to_admin_id: reassignForm.to_admin_id,
                sub_sale_claim_submission_id: selectedSubmission.id,
              });
            }}
          >
            Send Request Now
          </button>
        }
      />
      {onLoadReassign && <LoadingModal />}
    </>
  );
};

export default ReassignHOC(Reassign);
