import React, { useEffect, useState } from "react";
import Moment from "moment";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

const Pagination = ({
  meta,
  searchParams,

  getListAPI,
  totalPages,
}) => {
  const [interval, onChangeInterval] = useState(10);
  const [paginationSize, setPaginationSize] = useState(10);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setPaginationSize(3);
      onChangeInterval(3);
    } else {
      setPaginationSize(10);
      onChangeInterval(10);
    }
  };

  const getSearchParamString = (item) => {
    if(item.param) {
      if(item.value) {
        if(item.withoutPrefix) {
          return `${item.value}=${
            item.type === "typeahead"
              ? item.param.name
              : item.type === "date_time"
                ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                : item.type === "date"
                  ? Moment(item.param).format("YYYY-MM-DD")
                  : item.param
          }&`
        } else {
          return `q[${item.value}]=${
            item.type === "typeahead"
              ? item.param.name
              : item.type === "date_time"
                ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                : item.type === "date"
                  ? Moment(item.param).format("YYYY-MM-DD")
                  : item.param
          }&`
        }
      } else {
        return `q${item.param}&`
      }
    }
    return ""
  }

  return (
    <>
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams?.forEach((item) => {
              tmp = tmp + getSearchParamString(item)
            });
            getListAPI(1, tmp);
            onChangeInterval(paginationSize);
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams?.forEach((item) => {
              tmp = tmp + getSearchParamString(item)
            });
            getListAPI(meta.prev, tmp);
            onChangeInterval(
              Math.ceil(meta.prev / paginationSize) * paginationSize,
            );
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${interval <= paginationSize || !meta ? "disabled" : ""}`}
          onClick={() => onChangeInterval(interval - paginationSize)}
        >
          ...
        </button>
        {totalPages &&
          totalPages.map((item) => {
            if (item + 1 <= interval && item + 1 > interval - paginationSize) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${meta && meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    searchParams.map((item) => {
                      if(item.param) {
                        if(item.value) {
                          if(item.withoutPrefix) {
                            tmp = tmp + `${item.value}=${
                              item.type === "typeahead"
                                ? item.param.name
                                : item.type === "date_time"
                                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                                  : item.type === "date"
                                    ? Moment(item.param).format("YYYY-MM-DD")
                                    : item.param
                            }&`
                          } else {
                            tmp = tmp + `q[${item.value}]=${
                              item.type === "typeahead"
                                ? item.param.name
                                : item.type === "date_time"
                                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                                  : item.type === "date"
                                    ? Moment(item.param).format("YYYY-MM-DD")
                                    : item.param
                            }&`
                          }
                        } else {
                          tmp = tmp + `q${item.param}&`
                        }
                      }
                    });
                    getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            } else {
              return null;
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && interval >= meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => onChangeInterval(interval + paginationSize)}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams?.forEach((item) => {
              tmp = tmp + getSearchParamString(item)
            });
            getListAPI(meta.next, tmp);
            onChangeInterval(
              Math.ceil(meta.next / paginationSize) * paginationSize,
            );
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams?.forEach((item) => {
              tmp = tmp + getSearchParamString(item)
            });
            getListAPI(meta.pages, tmp);
            onChangeInterval(meta.pages);
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    </>
  );
};

export default Pagination;
