import React, { Component } from "react";

import CustomFormInput from "components/Input/formInput";

class ResetPassword extends Component {
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      return this.props.onClickSubmitPassword();
    }
  };

  render() {
    return (
      <div
        className="login-form-container"
        tabIndex="0"
        onKeyPress={(event) => this.handleKeyPress(event)}
      >
        <h4 className="at-form-input__title">Password</h4>
        <CustomFormInput
          type="password"
          value={this.props.password}
          disabled={!this.props.resetTokenValid}
          onChangeValue={(val) => this.props.onChangeHOC(val, "password")}
          placeholder={"Password"}
        />
        <h4 className="at-form-input__title mt-20">Confirmed Password</h4>
        <CustomFormInput
          type="password"
          value={this.props.confirmedPassword}
          disabled={!this.props.resetTokenValid}
          onChangeValue={(val) =>
            this.props.onChangeHOC(val, "confirmedPassword")
          }
          placeholder={"Confirmed Password"}
        />
        <div className="pad-ver">
          <a onClick={() => this.props.onClickForgetPassword()}>
            Forgot Password?
          </a>
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            disabled={!this.props.resetTokenValid}
            className="btn-new btn-new--primary text-uppercase w-100"
            type="submit"
            onClick={() => this.props.onClickSubmitPassword()}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
