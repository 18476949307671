import React, { Component } from "react";
import _ from "lodash";

const sections = ["Name Card", "T-Shirt"];

class Tab extends Component {
  onSelectSectionButton = (item) => this.props.onSelectSection(item);

  renderTabs = () => {
    const { selectedSection } = this.props;
    return sections.map((item, index) => (
      <button
        key={index}
        className={`at-btn`}
        style={{
          marginRight: 10,
          borderRadius: "0.25rem 0.25rem 0px 0px",
          backgroundColor: selectedSection === item ? "#F18E05" : "#808080",
        }}
        onClick={() => this.onSelectSectionButton(item)}
      >
        {item}
      </button>
    ));
  };

  render = () => {
    return <div className="subsales-claim-info-tabs">{this.renderTabs()}</div>;
  };
}

export default Tab;
