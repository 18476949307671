import React from "react";
import { Card } from "@material-ui/core";
import { RiGroup2Fill } from "react-icons/ri";

import AtlasIcon from "components/Icon/atlasIcon";

import checkValid from "utils/checkValid";

import defaultAvatar from "assets/images/av-male.jpg";

export default function UserCard({
  cardStyles,
  cardAction,
  selectedAgent,
  onClickAction,
}) {
  return (
    <>
      <Card style={{ padding: 12, ...cardStyles }}>
        <div className="d-flex align-items-center">
          <img
            src={selectedAgent.avatar_url || defaultAvatar}
            style={{
              width: 100,
              borderRadius: "50%",
              height: 100,
              objectFit: "contain",
            }}
          />
          <div className="d-flex flex-column ml-3">
            <h2 className="at-form-input__title">
              {checkValid(selectedAgent.full_name)}
            </h2>
            <div className="d-flex flex-wrap">
              <RiGroup2Fill
                style={{
                  width: 16,
                  height: 16,
                  color: "#F0631D",
                  marginRight: 10,
                }}
              />
              <span>{checkValid(selectedAgent.team_name)}</span>
            </div>
            <div className="d-flex flex-wrap">
              <AtlasIcon
                svgHref={"atlas-sms"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              <span>{checkValid(selectedAgent.email)}</span>
            </div>
            <div className="d-flex flex-wrap">
              <AtlasIcon
                svgHref={"atlas-call"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              <span>{checkValid(selectedAgent.mobile_contact_number)}</span>
            </div>
          </div>
          {cardAction && (
            <>
              <hr />
              <button
                className="btn-new btn-new--primary d-flex ml-auto"
                onClick={() => onClickAction(selectedAgent)}
              >
                {cardAction}
              </button>
            </>
          )}
        </div>
      </Card>
    </>
  );
}
