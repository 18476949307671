import React, { Component } from "react";
import Axios from "axios";
import _ from "lodash";
import { sha256 } from "js-sha256";

import StatusModal from "components/Modal/status";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      refNo: "",
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      errorMessage: "",
      amountString: "0.00",
      currencyCode: "",

      bankName: "",
      bankOwnerName: "",
      bankAccountNumber: "",
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error, callback) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }, callback),
        statusModalMessage: error,
      });

    onChangeIPay88HOC = (key, val) => this.setState({ [key]: val });

    getPrice = (id, branch_id) =>
      Get(
        `/registrations/fee?type_id=${id}&branch_id=${branch_id}`,
        this.getPriceSuccess,
        this.getPriceError,
        this.load
      );
    getPriceSuccess = (payload) => {
      this.setState(
        {
          amountString: payload?.agent_registration_fee
            ? `${payload?.agent_registration_fee}`
            : "",
          currencyCode: payload?.currency_code || "",
          bankName: payload.bank_name || "N/A",
          bankOwnerName: payload.bank_owner_name || "N/A",
          bankAccountNumber: payload.bank_account_number || "N/A",
        },
        () => {
          this.savePaymentApproval({
            provider: "Ipay88",
            type: "AgentRegistration",
            amount: payload?.agent_registration_fee
              ? `${payload?.agent_registration_fee}`
              : "",
            description: "Paid for agent registration",
            user_id: this.props.userID,
            product_id: this.props.agentRegistrationID,
          });
        }
      );
    };
    getPriceError = (error) =>
      this.requestError(
        "Failed to get registration price, please contact system admin."
      );

    savePaymentApproval = (data) => {
      Axios.post(
        window.location.host.indexOf("atlas.iqiglobal.com") !== -1
          ? `https://api.iqiglobal.com/api/v1/payments`
          : `https://atlaspg.iqiglobal.com/api/v1/payments`,
        data
      )
        .then((res) => {
          this.savePaymentApprovalSuccess(res.data);
        })
        .catch((error) => {
          this.savePaymentApprovalError(error);
        });
    };
    savePaymentApprovalSuccess = (payload) =>
      this.setState({ refNo: payload.payment_id });
    savePaymentApprovalError = (error) =>
      this.requestError(error, () => window.location.reload());

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            refNo={this.state.refNo}
            currencyCode={this.state.currencyCode}
            errorMessage={this.state.errorMessage}
            amountString={this.state.amountString}
            bankName={this.state.bankName}
            bankOwnerName={this.state.bankOwnerName}
            bankAccountNumber={this.state.bankAccountNumber}
            onChangeIPay88HOC={this.onChangeIPay88HOC}
            savePaymentApproval={this.savePaymentApproval}
            getPrice={this.getPrice}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
