export const numberWithCommas = (x, decimalPoint) => {
  if (x) {
    var parts = decimalPoint
      ? x.toFixed(decimalPoint).toString().split(".")
      : x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return x.toString().indexOf(".") > -1
      ? parts[0] + "." + parts[1]
      : parts[0] + (decimalPoint ? ".".padEnd(decimalPoint + 1, "0") : "");
  } else {
    return decimalPoint ? (0).toFixed(decimalPoint) : 0;
  }
};
