import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ModalDialog from "components/Modal/ModalDialog";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";

import { numberWithCommas } from "utils/thousandSeparator";
import { subSaleColumn, projectColumn } from "../../assets";

import "../../index.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

class SalesEligibility extends Component {
  state = {
    showTerm: false,
  };

  calculateCombineValues = (key) => {
    return this.props.selectedPromotion.reduce((total, currentObj) => {
      let value = 0;
      switch (key) {
        case "totalUnitSold":
          value = parseFloat(currentObj.percentage);
          break;
        case "totalAllocatedAmount":
          value = parseFloat(currentObj.allocatedAmount);
          break;
        case "netPricePortion":
          let numberWithoutCommas = currentObj.netPricePort.replace(/,/g, "");
          value = parseFloat(numberWithoutCommas);
          break;
        case "sumAgencyCommPortion":
          value = parseFloat(currentObj.agencyCommissionPortion);
          break;
        default:
          value = 0;
      }
      return total + value;
    }, 0);
  };

  render() {
    const { onClickClose, selectPromotion, selectedPromotion, agentName } =
      this.props;

    const title = selectPromotion.promotionName;
    const totalUnitsSold = this.calculateCombineValues("totalUnitSold");
    const sumNetPricePortion = this.calculateCombineValues("netPricePortion");
    const allocatedAmount = this.calculateCombineValues("totalAllocatedAmount");
    const sumAgencyCommPortion = this.calculateCombineValues(
      "sumAgencyCommPortion",
    );
    const dialogTitle =
      agentName === undefined
        ? `${title} - Qualified Sales`
        : `${title} - Qualified Sales for ${agentName}`;

    return (
      <>
        <ModalDialog
          title={dialogTitle}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onClickClose()}
          children={
            <>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 4,
                  marginBottom: 16,
                  color: "#2563EB",
                  textAlign: "left",
                }}
                onClick={() => this.setState({ showTerm: true })}
              >
                <AtlasIcon
                  svgHref={"atlas-info-circle-fill"}
                  style={{
                    width: 14,
                    height: 14,
                    marginRight: 4,
                    fill: "#3B82F6",
                  }}
                />
                <span style={{ fontSize: 14 }}> Term & Conditions </span>
              </button>

              {selectPromotion.campaignType === "Project" && (
                <div className="at-campagin-tracker__stat-cont">
                  <div className="at-form__content d-flex align-items-center w-100 mb-2">
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ background: "#F79009" }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-cup"}
                        style={{ fill: "white", width: 32, height: 32 }}
                      />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>
                        Campaign Progress / Target
                        <DarkTooltip
                          classes={{ tooltip: "tooltip-arrow top" }}
                          placement={"top"}
                          enterTouchDelay={0}
                          title={
                            <div className="at-campaign-target_tooltip">
                              Campaign Progress can differ from the total net
                              price per portion or units sold due to the
                              campaign's Terms and Conditions.
                            </div>
                          }
                        >
                          <span>
                            <AtlasIcon
                              className={"ml-1"}
                              svgHref={"atlas-info-circle-fill"}
                              style={{
                                fill: "#6B7280",
                                width: 14,
                                height: 15,
                                verticalAlign: "baseline",
                              }}
                            />
                          </span>
                        </DarkTooltip>
                      </p>
                      <span>
                        {selectPromotion.currentProgress} /{" "}
                        {selectPromotion.campaignTarget}
                      </span>
                    </div>
                  </div>

                  <div className="at-form__content d-flex align-items-center w-100 mb-2">
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ background: "#6875F5" }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-building-2"}
                        style={{ fill: "white", width: 32, height: 32 }}
                      />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>Total Units Sold</p>
                      <span>{totalUnitsSold.toFixed(2) || "0.00"}</span>
                    </div>
                  </div>

                  <div className="at-form__content d-flex align-items-center w-100 mb-2">
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ background: "#14B8A6" }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-moneys"}
                        style={{ fill: "white", width: 32, height: 32 }}
                      />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>Total Net Price Portion</p>
                      <span>
                        {numberWithCommas(
                          sumNetPricePortion.toFixed(2) || "0.00",
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="at-form__content d-flex align-items-center w-100 mb-2">
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ background: "#D946EF" }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-money-4"}
                        style={{ fill: "white", width: 32, height: 32 }}
                      />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>Total Allocated Amount</p>
                      <span>{allocatedAmount.toFixed(2) || "0.00"}</span>
                    </div>
                  </div>
                </div>
              )}
              {selectPromotion.campaignType === "Subsales" && (
                <div className="at-campagin-tracker__stat-cont">
                  <div className="at-form__content d-flex align-items-center w-100 mb-2">
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ background: "#F79009" }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-home"}
                        style={{ fill: "white", width: 32, height: 32 }}
                      />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>Total Agency Commission Portion</p>
                      <span>{sumAgencyCommPortion.toFixed(2) || 0.0}</span>
                    </div>
                  </div>
                </div>
              )}
              <CustomTable
                className={"team-sales__table mb-10 mt-10"}
                columns={
                  selectPromotion.campaignType === "Subsales"
                    ? subSaleColumn
                    : projectColumn
                }
                rowData={selectedPromotion}
                emptyStateMessage={"You don't have any sales"}
                pagination={true}
                hideSearch={true}
                hidePagination={false}
              />
            </>
          }
        />
        {this.state.showTerm && (
          <ModalDialog
            title={"Campaign Terms & Conditions"}
            maxWidth="md"
            onClose={() => this.setState({ showTerm: false })}
            children={
              <>
                <span style={{ fontSize: 16, fontWeight: 600 }}>
                  {selectPromotion.promotionName}
                </span>
                <div
                  style={{ margin: "20px 0" }}
                  dangerouslySetInnerHTML={{
                    __html: selectPromotion.termsAndConditions,
                  }}
                ></div>
                <div className="d-flex flex-wrap mt-10">
                  <button
                    type={"button"}
                    className="btn-new btn-new--outline-secondary"
                    onClick={() => this.setState({ showTerm: false })}
                  >
                    Close
                  </button>
                </div>
              </>
            }
          />
        )}
      </>
    );
  }
}

export default SalesEligibility;
