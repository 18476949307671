import React from "react";
import { MdFilterList } from "react-icons/md";

import IOSButton from "components/Button/toggle";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";

const SearchParams = ({
  searchParams,
  dateRangeMode,
  showSearchContent,

  getListAPI,
  setSearchParams,
  setDateRangeMode,
  setShowSearchContent,
}) => {
  const renderSearchMode = () => (
    <div className="at-analytic-centre__mode-toggle">
      Quick Select
      <IOSButton
        className={"ml-2"}
        currentState={!dateRangeMode}
        onToggleButton={() => setDateRangeMode(!dateRangeMode)}
      />
      Date Range
    </div>
  );

  return (
    <>
      <button
        className={
          "btn-new btn-new--outline-secondary at-mobile-view__controller"
        }
        style={{ width: "100%" }}
        onClick={() => setShowSearchContent((prevState) => !prevState)}
      >
        <MdFilterList style={{ width: 20 }} />
        <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
          Filter & Search
        </h5>
      </button>
      <div className="at-table__search-cont at-desktop-view__controller ">
        <TableSearchParams
          mode={"desktop"}
          showResetButton={false}
          searchParams={searchParams}
          renderSearchMode={renderSearchMode()}
          onChangeSearchParams={setSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={getListAPI}
        />
      </div>
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          showResetButton={false}
          searchParams={searchParams}
          renderSearchMode={renderSearchMode()}
          onChangeSearchParams={setSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={getListAPI}
        />
      )}
    </>
  );
};

export default SearchParams;
