import React, { Component } from "react";
import FileSaver from "file-saver";
import Moment from "moment";

import CustomButton from "components/Button";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";

import { getItem } from "utils/tokenStore";
import { requestError } from "utils/requestHandler";
import { numberWithCommas } from "utils/thousandSeparator";

import "./index.scss";

const columnData = [
  {
    header: "Description",
    accessor: "description",
    searchFlag: "description",
    columnStyle: { width: "300%" },
  },
  {
    header: "Amount Excl. Tax",
    accessor: "preAmount",
    searchFlag: "preAmount",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
  },
  {
    header: "Tax",
    accessor: "tax",
    searchFlag: "tax",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
  },
  {
    header: "Amount Incl. Tax",
    accessor: "postAmount",
    searchFlag: "postAmount",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
  },
];

class Details extends Component {
  state = {
    tableData: [],
    statsData: {
      excludedTax: 0.0,
      taxAmount: 0.0,
      includedTax: 0.0,
    },
    showLoadingModal: false,
    showStatusModal: false,
  };

  componentDidMount = () => {
    let tmpPreAmtTotal = 0.0;
    let tmpTaxTotal = 0.0;
    let tmpPostAmtTotal = 0.0;
    let tmpPayments = [];
    const { payments = [] } = this.props.selectedVoucher;

    payments.map((item) => {
      tmpPreAmtTotal = tmpPreAmtTotal + parseFloat(item.preAmount);
      tmpTaxTotal = tmpTaxTotal + parseFloat(item.taxAmount);
      tmpPostAmtTotal = tmpPostAmtTotal + parseFloat(item.postAmount);
      tmpPayments.push({
        description: item.description,
        preAmount: numberWithCommas(item.preAmount),
        tax: numberWithCommas(item.taxAmount),
        postAmount: numberWithCommas(item.postAmount),
      });
    });

    this.setState({
      tableData: tmpPayments,
      statsData: {
        excludedTax: numberWithCommas(tmpPreAmtTotal.toFixed(2)),
        taxAmount: numberWithCommas(tmpTaxTotal.toFixed(2)),
        includedTax: numberWithCommas(tmpPostAmtTotal.toFixed(2)),
      },
    });
  };

  onClickDownloadPDFButton = () => {
    const PDFDLLink = this.props.selectedVoucher.pdfLink;
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";

    let token = getItem(tokenName);
    let headers = new Headers();
    headers.append("Authorization", `JWT ${token}`);
    this.setState({ showLoadingModal: true }, () => {
      return fetch(PDFDLLink, { headers })
        .then((response) => {
          return response.blob();
        })
        .then((blobby) => {
          return this.setState({ showLoadingModal: false }, () => {
            return FileSaver.saveAs(
              blobby,
              `${Moment(new Date(this.props.selectedVoucher.pvDate))
                .format("YYYY-MM-DD")
                .toString()}-${this.props.selectedVoucher.pvNumber}.pdf`,
            );
          });
        })
        .catch((error) => {
          requestError("Download is failed, please try again.");
        });
    });
  };

  render = () => (
    <ModalDialog
      title={"Payment Voucher Details"}
      onLoad={this.state.showLoadingModal}
      fullWidth={true}
      fullHeight={true}
      onClose={() => this.props.onClose()}
      children={
        <>
          <div className="at-form__content at-pv--detail-info">
            <div className="d-flex align-items-center flex-wrap">
              <div
                className="at-badge-icon-sm at-badge-icon-primary"
                style={{ marginRight: 12 }}
              >
                <AtlasIcon svgHref={"atlas-receipt-item"} />
              </div>
              <div style={{ marginRight: 12 }}>
                <h1>{`PV No: ${this.props.selectedVoucher.pvNumber}`}</h1>
                <p style={{ color: "#374151" }}>
                  {this.props.selectedVoucher.pvDate}
                </p>
              </div>
              <CustomButton
                containerClass={"at-pv__download-button"}
                className="btn-new btn-new--primary w-100"
                onClick={() => this.onClickDownloadPDFButton()}
                children={"Download"}
              />
            </div>
            <hr />
            <div className="at-pv__stat-cont">
              <div className="d-flex align-items-center">
                <div className="at-badge-icon-sm at-badge-icon-warning">
                  <AtlasIcon svgHref={"atlas-document-text"} />
                </div>
                <div>
                  <label>Cheque Number</label>
                  <p>{this.props.selectedVoucher.chequeNo}</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="at-badge-icon-sm at-badge-icon-warning">
                  <AtlasIcon svgHref={"atlas-frame"} />
                </div>
                <div>
                  <label>Payee Name</label>
                  <p>{this.props.selectedVoucher.payeeName}</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="at-badge-icon-sm at-badge-icon-warning">
                  <AtlasIcon svgHref={"atlas-bank"} />
                </div>
                <div>
                  <label>Account</label>
                  <p>{this.props.selectedVoucher.payeeAccount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="at-pv__stat-cont my-3">
            <div className={"at-form__content d-flex align-items-center mb-0"}>
              <div
                className="at-card--stat__general-icon-cont"
                style={{ backgroundColor: "#6875F5" }}
              >
                <AtlasIcon svgHref={`atlas-money`} />
              </div>
              <div className="at-card--stat__general-category mb-0">
                <p>Total (Excl. Tax)</p>
                <span>{this.state.statsData.excludedTax}</span>
              </div>
            </div>
            <div className={"at-form__content d-flex align-items-center mb-0"}>
              <div
                className="at-card--stat__general-icon-cont"
                style={{ backgroundColor: "#06B6D4" }}
              >
                <AtlasIcon svgHref={`atlas-receipt-discount`} />
              </div>
              <div className="at-card--stat__general-category mb-0">
                <p>Tax Amount</p>
                <span>{this.state.statsData.taxAmount}</span>
              </div>
            </div>
            <div className={"at-form__content d-flex align-items-center mb-0"}>
              <div
                className="at-card--stat__general-icon-cont"
                style={{ backgroundColor: "#14B8A6" }}
              >
                <AtlasIcon svgHref={`atlas-moneys`} />
              </div>
              <div className="at-card--stat__general-category mb-0">
                <p>Total (Incl. Tax)</p>
                <span>{this.state.statsData.includedTax}</span>
              </div>
            </div>
          </div>
          <CustomTable
            columns={columnData}
            rowData={this.state.tableData}
            hideSearch={true}
            hidePagination={true}
          />
        </>
      }
    />
  );
}

export default Details;
