import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Grid } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { BsPieChartFill } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";

import { getColorBadge } from "dictionary/badgeColor";
import { numberWithCommas } from "utils/thousandSeparator";

import "./index.scss";

const PriceField = [
  { label: "Full SPA Price", value: "fullSpaPrice", icon: "atlas-tag" },
  { label: "Full Nett Price", value: "fullNetPrice", icon: "atlas-tag-linear" },
  {
    label: "Net Price Portion",
    value: "netPricePortion",
    icon: <BsPieChartFill />,
  },
];

const CommField = (mode) => [
  ...(mode === "project"
    ? [
        {
          label: "Est. Comm.",
          value: "estimatedTotalComm",
          icon: "atlas-percentage-square",
        },
        { label: "Paid Comm.", value: "commPaid", icon: "atlas-tick-circle" },
        { label: "Pending Comm.", value: "commPending", icon: "atlas-clock" },
      ]
    : []),
  ...(mode === "subsales"
    ? [
        {
          label: "Agency Comm.",
          value: "grossCommission",
          icon: "atlas-tick-circle",
        },
      ]
    : []),
];

class Details extends Component {
  state = {
    commData: null,
    personalSalesDetails: [],
    detailType: "",
    primaryAgent: [],
    coAgent: [],
  };

  componentDidMount = () => {
    this.setState(
      {
        ...this.props.personalSalesDetails,
        detailType: this.props.detailType ? this.props.detailType : "project",
      },
      () => {
        this.setState({
          primaryAgent: _.filter(this.state.cobrokeAgents, (item) => {
            return item.isPrimary;
          }),
        });
        this.setState({
          coAgent: _.filter(this.state.cobrokeAgents, (item) => {
            return !item.isPrimary;
          }),
        });
      },
    );
  };

  renderFieldContent = (params) => (
    <div className="d-flex align-items-center">
      <div className={`at-badge-icon-sm at-badge-icon-${params.iconColor}`}>
        <AtlasIcon svgHref={params.icon} />
      </div>
      <div>
        <label>{params.label}</label>
        <p>{params.value}</p>
      </div>
    </div>
  );

  render = () => {
    const coAgentPercentage =
      this.state.coAgent.length > 0 &&
      this.state.coAgent.reduce((accumulator, item) => {
        return accumulator + item.cobrokePercentage;
      }, 0);
    const totalCobrokePercentage =
      (this.state.primaryAgent.length > 0
        ? this.state.primaryAgent[0].cobrokePercentage
        : 0) + coAgentPercentage;

    return (
      <>
        <Grid container spacing={16}>
          <Grid item id={"sale-detail"} md={12} xs={12}>
            <div className="at-form__content at-details__container-bg mb-0">
              <div>
                <div className="at-details__title-tag">
                  <p
                    className={"at-status_badge"}
                    style={{ ...getColorBadge("Green") }}
                  >
                    {this.state.statusName}
                  </p>
                  {this.state.bookingDate
                    ? Moment(this.state.bookingDate).format("DD MMM YYYY")
                    : "N/A"}
                </div>
                {this.renderFieldContent({
                  label: this.state.projectName,
                  value: `Unit No.: ${this.state.unitNumber}`,
                  icon: "atlas-building-4",
                  iconColor: "warning",
                })}
                {this.renderFieldContent({
                  label: "Buyer",
                  value: this.state.buyers,
                  icon: "atlas-user",
                  iconColor: "warning",
                })}
              </div>
              <div
                className="at-form__content at-details__timeline mb-0"
                style={{ width: "50%" }}
              >
                <label>
                  <span
                    style={{
                      color:
                        this.state.bookingDate && !this.state.spaSignedDate
                          ? "#F0631D"
                          : "",
                    }}
                  >
                    {this.state.bookingDate
                      ? Moment(this.state.bookingDate).format("DD MMM YYYY")
                      : "-"}
                  </span>
                  Booked
                </label>
                <label>
                  <span
                    style={{
                      color:
                        this.state.spaSignedDate && !this.state.laSignedDate
                          ? "#F0631D"
                          : "",
                    }}
                  >
                    {this.state.spaSignedDate
                      ? Moment(this.state.spaSignedDate).format("DD MMM YYYY")
                      : "-"}
                  </span>
                  SPA Signed
                </label>
                <label>
                  <span
                    style={{
                      color:
                        this.state.laSignedDate &&
                        !this.state.balanceDepositPaidDate
                          ? "#F0631D"
                          : "",
                    }}
                  >
                    {this.state.laSignedDate
                      ? Moment(this.state.laSignedDate).format("DD MMM YYYY")
                      : "-"}
                  </span>
                  LA Signed
                </label>
                <label>
                  <span
                    style={{
                      color: this.state.balanceDepositPaidDate ? "#F0631D" : "",
                    }}
                  >
                    {this.state.balanceDepositPaidDate
                      ? Moment(this.state.balanceDepositPaidDate).format(
                          "DD MMM YYYY",
                        )
                      : "-"}
                  </span>
                  Balanced Deposit Paid
                </label>
              </div>
            </div>
          </Grid>
          <Grid item id={"sale-price"} md={6} xs={12}>
            <div className="at-form__content h-100">
              <ModuleFormHeader
                title={"Price"}
                className={"mb-3"}
                moduleIcon={"atlas-money"}
              />
              {PriceField.map((item, index) => (
                <div key={index} className="at-details__item">
                  {typeof item.icon === "string" ? (
                    <AtlasIcon svgHref={item.icon} />
                  ) : (
                    item.icon
                  )}
                  <label>{item.label}</label>
                  <p>
                    {this.state[item.value]
                      ? numberWithCommas(this.state[item.value])
                      : "0.00"}
                  </p>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item id={"sale-cobroke"} md={6} xs={12}>
            <div className="at-form__content h-100">
              <ModuleFormHeader
                title={"Co-broke"}
                className={"mb-3"}
                moduleIcon={"atlas-cobroke-centre"}
              />
              <div className="d-flex align-items-center">
                <div className="at-details__cobroke">
                  {this.state.primaryAgent.length > 0 &&
                    this.renderFieldContent({
                      label: "Primary Agent",
                      value: (
                        <>
                          <span>
                            {this.state.primaryAgent[0].cobrokePercentage * 100}{" "}
                            %
                          </span>
                          {this.state.primaryAgent[0].agentName}
                        </>
                      ),
                      icon: "atlas-user",
                      iconColor: "primary",
                    })}
                  {this.state.coAgent.length > 0 &&
                    this.state.coAgent.map((item) =>
                      this.renderFieldContent({
                        label: "Co-Agent",
                        value: (
                          <>
                            <span>{item.cobrokePercentage * 100} %</span>
                            {item.agentName}
                          </>
                        ),
                        icon: "atlas-user",
                        iconColor: "yellow",
                      }),
                    )}
                </div>
                <div className="at-card--stat__doughnut-cont">
                  <Doughnut
                    data={{
                      labels: ["Primary Agent", "Co-Agent"],
                      datasets: [
                        {
                          data: [
                            ((this.state.primaryAgent.length > 0
                              ? this.state.primaryAgent[0].cobrokePercentage
                              : 0) /
                              totalCobrokePercentage) *
                              100,
                            (coAgentPercentage / totalCobrokePercentage) * 100,
                          ],
                          backgroundColor: ["#648FFF", "#FFB000"],
                          hoverBorderColor: ["#648FFF", "#FFB000"],
                          hoverBorderWidth: 8,
                        },
                      ],
                    }}
                    options={{
                      legend: { display: false },
                      cutoutPercentage: 60,
                      plugins: {
                        tooltips: { enabled: false },
                        datalabels: { display: false },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item id={"sale-status"} md={6} xs={12}>
            <div className="at-form__content h-100">
              <ModuleFormHeader
                title={"Status"}
                className={"mb-3"}
                moduleIcon={"atlas-message-text"}
              />
              <div className="at-details__item">
                <AtlasIcon svgHref={"atlas-calendar"} />
                <label>
                  {this.state.summaryStatusDate &&
                  !_.includes(this.state.summaryStatusDate, "1900-01-01")
                    ? Moment(this.state.summaryStatusDate).format("DD MMM YYYY")
                    : "N/A"}
                </label>
                <p>
                  {this.state.summaryStatus ? this.state.summaryStatus : "N/A"}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item id={"sale-comm"} md={6} xs={12}>
            <div className="at-form__content h-100">
              <ModuleFormHeader
                title={"Commission"}
                className={"mb-3"}
                moduleIcon={"atlas-receipt-discount"}
              />
              {CommField(this.state.detailType).map((item, index) => (
                <div key={index} className="at-details__item">
                  {typeof item.icon === "string" ? (
                    <AtlasIcon svgHref={item.icon} />
                  ) : (
                    item.icon
                  )}
                  <label>{item.label}</label>
                  <p>
                    {this.state[item.value]
                      ? numberWithCommas(this.state[item.value])
                      : "0.00"}
                  </p>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        {this.props.onLoadMySales && <LoadingModal />}
      </>
    );
  };
}

export default Details;
