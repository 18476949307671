import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";

import AtPaginatedTable from "components/NewPaginatedTable";
import DescriptionList from "components/DescriptionList";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";
import ModalDialog from "components/Modal/ModalDialog";
import CustomSearch from "../components/search";

import { actionList, columnData } from "../assets";

const SearchParams = ({ country, dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          param: `start_date=${Moment().subtract(1, "years").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Admin Email",
    value: "admin_email",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "agent_email",
    type: "input",
    param: "",
  },
  {
    label: "Activity",
    value: "activity",
    param: "",
    type: "typeahead",
    labelKey: "label",
    filterBy: ["label"],
    typeaheadId: "activity_typeahead",
    showClearButton: true,
    options: actionList,
    typeaheadChildren: (rowItem) => <p>{rowItem.label}</p>,
  },
];

const AdminActivitiesContent = ({
  data,
  impersonateActivity,
  getImpersonateActivity,
  onAdminActivitiesState,
  showDetailsModal,
  selectedData,
  requestsPages,
}) => {
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      country: data.dictionaryReducer.countries,
      dateRangeMode,
    })
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getImpersonateActivity(1, tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        country: data.dictionaryReducer.countries,
        dateRangeMode,
      })
    );

    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  const renderDetailsDialog = () => (
    <ModalDialog
      title={"Details"}
      maxWidth={"md"}
      onClose={() =>
        onAdminActivitiesState({
          showDetailsModal: false,
        })
      }
      children={
        <div style={{ marginTop: 5 }}>
          <DescriptionList
            data={[
              {
                title: "Admin",
                description: (
                  <UserInfo
                    name={selectedData.admin_name || "N/A"}
                    email={selectedData.impersonator_email || "N/A"}
                    hideRedirect={true}
                  />
                ),
              },
              {
                title: "Agent",
                description: (
                  <UserInfo
                    name={selectedData.name || "N/A"}
                    team_name={selectedData.team || "N/A"}
                    email={selectedData.email || "N/A"}
                    location={selectedData.branch || "N/A"}
                    phone_number={selectedData.mobile_number || "N/A"}
                    hideRedirect={true}
                  />
                ),
              },
              {
                title: "Activity",
                description: selectedData.activity,
              },
              {
                title: "Date Time",
                description: selectedData.action_at
                  ? Moment(selectedData.action_at).format("DD MMM YYYY hh:mm a")
                  : "N/A",
              },
              {
                title: "Details",
                description: (
                  <DescriptionList
                    isShowDivider={false}
                    data={Object.keys(selectedData.details).map((key) => ({
                      title: key,
                      description: selectedData.details[key],
                    }))}
                  />
                ),
              },
            ]}
          />
        </div>
      }
    />
  );

  return (
    <>
      <div className="mb-10">
        <CustomSearch
          searchParams={searchParams}
          dateRangeMode={dateRangeMode}
          showSearchContent={showSearchContent}
          getListAPI={getImpersonateActivity}
          setSearchParams={setSearchParams}
          setDateRangeMode={setDateRangeMode}
          setShowSearchContent={setShowSearchContent}
        />
      </div>

      <AtPaginatedTable
        rowData={impersonateActivity.data || []}
        meta={impersonateActivity.meta}
        columns={columnData}
        enableSort={true}
        actionColumnContent={[
          {
            name: "details",
            onClick: (rowData) => {
              onAdminActivitiesState({
                selectedData: rowData,
                showDetailsModal: true,
              });
            },
          },
        ]}
        searchParams={[]}
        getListAPI={getImpersonateActivity}
        totalPages={requestsPages}
      />
      {showDetailsModal && renderDetailsDialog()}
    </>
  );
};

export default AdminActivitiesContent;
