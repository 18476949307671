import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ConfirmationModalContent from "../ConfirmationModalContent";
import AtPaginatedTable from "../Table";
import ViewModalContent from "./View";

import ListingHOC from "../../actions/listing";

import permissionsChecker from "utils/permissionsChecker";

class ListingContent extends Component {
  componentDidMount = () => {
    this.props.getAllStates()
    this.props.getAllTownShips()
    this.props.getListings(1, "")
    this.debounceService = _.debounce(this.debounceService, 300);
  }

  onChangeSearchParams = (val) => {
    const { countries } = this.props.data.dictionaryReducer;
    let countryIndex = _.findIndex(this.props.estatetListingIndexSearchParams, { value: "country_id_eq" });
    let stateIndex = _.findIndex(this.props.estatetListingIndexSearchParams, { value: "state_id_eq" });
    let townIndex = _.findIndex(this.props.estatetListingIndexSearchParams, { value: "township_cont" });

    if (val[countryIndex].param
      && ((typeof(val[countryIndex].param) === "string" && (val[countryIndex].param !== this.props.estatetListingIndexSearchParams[countryIndex].param)
       || typeof(val[countryIndex].param) === "object" && (val[countryIndex].param.id !== this.props.estatetListingIndexSearchParams[countryIndex].param.id)))
    ) {
      let states = typeof(val[countryIndex].param) === "string"
        ? _.find(countries, { name: val[countryIndex].param })?.states || []
        : val[countryIndex].param?.states || [];
      states = _.orderBy(states, ["name"], ["asc"]);
      val[stateIndex].options = states;
      val[stateIndex].param = "";
      val[townIndex].options = [];
      val[townIndex].param = "";
    }

    if (val?.[stateIndex]?.param
      && (typeof(val[stateIndex].param) === "object" && (val[stateIndex].param.id !== this.props.estatetListingIndexSearchParams[stateIndex].param.id))
    ) {
      val[townIndex].options = [];
      val[townIndex].param = "";
      this.props.getSelectedTownShip(typeof(val[stateIndex].param) === "string" ? val[stateIndex].param : val[stateIndex].param?.id);
    }

    if (val[townIndex].param && !val[stateIndex].param) {
      this.debounceService(val[townIndex].param);
    }

    if(val[townIndex].param === "" && val[stateIndex].param === "" && val[countryIndex].param === "") {
      this.props.getAllStates()
      this.props.getAllTownShips()
    }
    this.props.onChangeListingHOC(val, "estatetListingIndexSearchParams");
  };

  debounceService = (searchVal) => {
    this.props.getAllTownShips(searchVal);
  };

  render = () => {
    const {
      data,
      listings,
      listingPages,
      showRequestConfirmation,
      estatetListingIndexSearchParams,

      onLoadListing,
    } = this.props;

    const { can_destroy } = permissionsChecker("Presentation Centre", data);

    return (
      <>
        <AtPaginatedTable
          rowData={listings.data || []}
          meta={listings.meta && listings.meta}
          onClickRequest={(val) => {
            Promise.all([
              this.props.onChangeListingHOC(val, "selectedListing"),
            ]).then(() => {
              this.props.onChangeListingHOC(true, "showRequestConfirmation");
            });
          }}
          onClickDetails={(val) =>
            Promise.all([
              this.props.onChangeListingHOC(val, "selectedListing"),
            ]).then(() => {
              this.props.onChangeListingHOC(true, "showDetailsModal");
            })
          }
          searchParams={estatetListingIndexSearchParams}
          actionTypes={can_destroy ? ["remove"] : []}
          onChangeSearchParams={(val) => this.onChangeSearchParams(val)}
          getListAPI={(page, search) => this.props.getListings(page, search)}
          totalPages={listingPages}
        />
        <Dialog
          open={showRequestConfirmation}
          classes={{ paper: "m-0" }}
          maxWidth={"md"}
        >
          <ConfirmationModalContent
            context={"send"}
            selectedListing={this.props.selectedListing}
            onClickSubmit={({ id: listingId, agent_info }) =>
              this.props.sendRequest({
                agent_id: agent_info.id,
                listing_id: listingId,
                sender_id: window.location.href.includes("/admin-impersonate")
                  ? this.props.data.currentSignInProfileReducer.id
                  : this.props.data.profileReducer.id,
                status_id: 2,
                request_from: "CoBrokeCenter",
              })
            }
            profileId={
              window.location.href.includes("/admin-impersonate")
                ? this.props.data.currentSignInProfileReducer.id
                : this.props.data.profileReducer.id
            }
            onLoad={onLoadListing}
            onClickClose={() =>
              this.props.onChangeListingHOC(false, "showRequestConfirmation")
            }
          />
        </Dialog>
        <AtlasDialog open={this.props.showDetailsModal}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <ViewModalContent
                {...this.props}
                onClose={() =>
                  this.props.onChangeListingHOC(false, "showDetailsModal")
                }
              />
            }
          />
        </AtlasDialog>
        {onLoadListing && <LoadingModal />}
      </>
    );
  };
}

export default compose(ListingHOC)(ListingContent);
