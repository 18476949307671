import React, { Component } from "react";
import { Line } from "react-chartjs-2";

import CustomSelect from "components/Select";
import { commissionTimelines } from "../timelineData";
import { numberWithCommas } from "utils/thousandSeparator";

class SalesChartContent extends Component {
  render = () => {
    const {
      commissionXData,
      commissionYData,
      selectedCommTimelineType,
      updateSelectCommTimeline,
    } = this.props;
    const data = () => {
      return {
        labels: commissionXData,
        datasets: [
          {
            label: "Commission",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "#785ef059",
            borderColor: "#785EF0",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            borderWidth: 2,
            pointRadius: 3,
            pointBorderColor: "#785EF0",
            pointBackgroundColor: "#785EF0",
            pointBorderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#785EF0",
            pointHoverBorderColor: "#785EF0",
            pointHoverBorderWidth: 2,
            pointHitRadius: 10,
            data: commissionYData,
            responsive: true,
            maintainAspectRatio: true,
          },
        ],
      };
    };
    return (
      <div className="at-form__content">
        <div className="d-flex align-items-center">
          <h2
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: "#111827",
              marginRight: 16,
              flex: "1 0 0",
            }}
          >
            Total Commission
          </h2>
          <div
            className="d-flex align-items-center"
            style={{ borderRadius: 8, border: "1px solid #D1D5DB" }}
          >
            {commissionTimelines.map((item, index) => (
              <button
                key={`commission-timeline-${index}`}
                style={{
                  padding: "6px 10px",
                  fontSize: 14,
                  fontWeight: 500,
                  borderRadius:
                    index === 0
                      ? "8px 0 0 8px"
                      : index === commissionTimelines.length - 1
                        ? "0 8px 8px 0"
                        : 0,
                  borderLeft: index === 0 ? "none" : "1px solid #D1D5DB",
                  color:
                    selectedCommTimelineType === item ? "white" : "#1F2937",
                  backgroundColor:
                    selectedCommTimelineType === item ? "#F0631D" : "white",
                }}
                onClick={() => updateSelectCommTimeline(item)}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
        <div style={{ position: "relative", height: "50vh", marginTop: 20 }}>
          <Line
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: false,
                },
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) =>
                    "Total Commission: " +
                    numberWithCommas(
                      data.datasets[0].data[tooltipItems.index].toFixed(0),
                    ),
                },
              },
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                    ticks: {
                      callback: (value, index, values) =>
                        numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    );
  };
}

export default SalesChartContent;
