import _ from "lodash";
import React from "react";
import { FaUserAlt } from "react-icons/fa";

export const TypeLib = [
  { value: "", label: "All" },
  { value: "1", label: "Name Card" },
  { value: "2", label: "Poster" },
  { value: "3", label: "Social Media" },
];

export const columnData = (isAdmin) => [
  {
    header: "Thumbnail",
    columnStyle: { width: "100%", maxWidth: 300 },
    columnClass: "at-table-head__hide-mobile",
    renderColumn: (rowData) => (
      <img src={rowData.thumbnail_url} style={{ height: 200 }} alt="" />
    ),
  },
  ...(isAdmin
    ? [
        {
          header: "Agent",
          columnStyle: { width: "90%" },
          renderColumn: (rowData) => (
            <>
              <FaUserAlt style={{ width: 15, height: 15 }} className={"mr-2"} />
              <span style={{ fontSize: 12 }}>{rowData.agent_name}</span>
            </>
          ),
        },
      ]
    : []),
  {
    header: "Title",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => (
      <p style={{ fontSize: 15 }}>{rowData.title || "-"}</p>
    ),
  },
  ...(!isAdmin
    ? [
        {
          header: "Type",
          columnStyle: { width: "50%" },
          renderColumn: (rowData) =>
            rowData.graphic_template.type_id
              ? _.find(TypeLib, {
                  value: `${rowData.graphic_template.type_id}`,
                }).label
              : "N/A",
        },
        {
          header: "Category",
          columnStyle: { width: "80%" },
          renderColumn: (rowData) =>
            rowData.graphic_template.category_list[0] || "N/A",
        },
      ]
    : []),
];
