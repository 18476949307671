import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateForm from "./Create";
import UpdateForm from "./Update";

import SubsalesAccessibilityHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";

const columnData = [
  { header: "Full Name", accessor: "agent_full_name" },
  { header: "Email", accessor: "agent_email" },
  { header: "Mobile Contact", accessor: "agent_mobile_contact_number" },
  { header: "Team", accessor: "agent_team_name" },
  {
    header: "Created Time",
    renderColumn: (rowData) => (
      <span style={{ whiteSpace: "break-spaces" }}>
        {rowData.created_datetime}
      </span>
    ),
  },
  { header: "Valid Until", accessor: "expiry_date" },
];

class SubsalesAccessibility extends Component {
  componentDidMount = () => this.props.getSubsalesAccessibility(1, "");

  renderCardContent = () => {
    const { can_destroy, can_update } = permissionsChecker(
      "Agent's Subsales Accessibility",
      this.props.data,
    );

    return (
      <AtPaginatedTable
        rowData={this.props.subsalesAccessibilityLists.data || []}
        meta={this.props.subsalesAccessibilityLists.meta}
        createTooltipContent={"Add Agent"}
        columns={columnData}
        actionColumnContent={
          can_destroy || can_update
            ? [
                {
                  name: "edit",
                  onShow: () => can_update,
                  onClick: (rowData) => {
                    this.props.onChangeSubsalesAccessibilityHOC(
                      true,
                      "showUpdateModal",
                    );
                    this.props.onChangeSubsalesAccessibilityHOC(
                      rowData,
                      "selectedAgentAccessibility",
                    );
                  },
                },
                {
                  name: "delete",
                  color: "#F04438",
                  onShow: () => can_destroy,
                  onClick: (rowData) => {
                    this.props.onChangeSubsalesAccessibilityHOC(
                      true,
                      "showDeleteModal",
                    );
                    this.props.onChangeSubsalesAccessibilityHOC(
                      rowData.id,
                      "selectedAgentId",
                    );
                  },
                },
              ]
            : []
        }
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeSubsalesAccessibilityHOC(val, "searchParams")
        }
        getListAPI={this.props.getSubsalesAccessibility}
        totalPages={this.props.subsalesAccessibilityPages}
      />
    );
  };

  render = () => {
    const { can_create } = permissionsChecker(
      "Agent's Subsales Accessibility",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={`Agent's Subsales Accessibility`}
          moduleIcon={"icon-subsales-access"}
          actionButton={[
            {
              label: "Add Agent",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeSubsalesAccessibilityHOC(
                  true,
                  "showCreateModal",
                ),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={this.props.showCreateModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <CreateForm
                onClose={() =>
                  this.props.onChangeSubsalesAccessibilityHOC(
                    false,
                    "showCreateModal",
                  )
                }
                createSubsalesAccessibility={
                  this.props.createSubsalesAccessibility
                }
                onLoadSubsalesAccessibility={
                  this.props.onLoadSubsalesAccessibility
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={this.props.showUpdateModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <UpdateForm
                onClose={() =>
                  this.props.onChangeSubsalesAccessibilityHOC(
                    false,
                    "showUpdateModal",
                  )
                }
                selectedAgentAccessibility={
                  this.props.selectedAgentAccessibility
                }
                updateSubsalesAccessibility={
                  this.props.updateSubsalesAccessibility
                }
                onLoadSubsalesAccessibility={
                  this.props.onLoadSubsalesAccessibility
                }
              />
            }
          />
        </AtlasDialog>
        <ConfirmationModal
          open={this.props.showDeleteModal}
          title={"Delete subsales accessibility"}
          loading={this.props.onLoadSubsalesAccessibility}
          message={"Are you sure you want to delete this agent ?"}
          positiveAction={() =>
            this.props.deleteSubsalesAccessibility(this.props.selectedAgentId)
          }
          negativeAction={() =>
            this.props.onChangeSubsalesAccessibilityHOC(
              false,
              "showDeleteModal",
            )
          }
        />
        {this.props.onLoadSubsalesAccessibility && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  SubsalesAccessibilityHOC,
  VersionCheckHOC,
)(SubsalesAccessibility);
