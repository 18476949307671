import React, { Component } from "react";
import Moment from "moment";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import EmptyState from "components/EmptyState";
import TimelineComponent from "components/Timeline";
import AtlasCloseButton from "components/Button/close";
import AtPaginatedTable from "components/NewPaginatedTable";

const columnData = [
  {
    header: "Updated At",
    renderColumn: (rowData) =>
      Moment(rowData.updated_at).format("DD MMM YYYY hh:mm"),
  },
  { header: "Mobile No", accessor: "mobile_number" },
  { header: "Status", accessor: "status" },
];

class SubsaleClaimActivity extends Component {
  state = {
    selectedActivity: {},
    showMessageLog: false,
  };

  componentDidMount = () =>
    this.props.getActivity(this.props.selected_claim.id);

  renderCardContent = () => (
    <>
      <div className="d-flex">
        <h2 className="at-form-input__title">Message Logs</h2>
        <AtlasCloseButton
          containerStyle={{ display: "flex", marginLeft: "auto" }}
          onClick={() => this.setState({ showMessageLog: false })}
        />
      </div>
      <hr />
      <AtPaginatedTable
        columns={columnData}
        rowData={this.state.selectedActivity.message_logs || []}
        meta={null}
        searchParams={[]}
        totalPages={[]}
      />
    </>
  );

  render = () => {
    const { claim_activities } = this.props;
    return (
      <>
        <div>
          {claim_activities &&
            claim_activities.length > 0 &&
            claim_activities.map((item) => {
              return (
                <section className="timeline" style={{ marginBottom: "15px" }}>
                  <TimelineComponent
                    key={item.id}
                    content={item.content}
                    messageLogs={
                      item.message_logs && item.message_logs.length > 0
                    }
                    onClickLoadMore={() =>
                      this.setState({
                        selectedActivity: item,
                        showMessageLog: true,
                      })
                    }
                    createdAt={item.created_at}
                  />
                </section>
              );
            })}
          {!(claim_activities && claim_activities.length > 0) && (
            <EmptyState title={"No data presence"} />
          )}
        </div>
        <Dialog
          maxWidth={"xl"}
          classes={{ paper: "m-0" }}
          open={this.state.showMessageLog}
        >
          <AtlasCard
            containerStyle={{ minWidth: 300, width: "80vw" }}
            cardContent={this.renderCardContent()}
          />
        </Dialog>
      </>
    );
  };
}

export default SubsaleClaimActivity;
