import React, { useState, useRef } from "react";
import { DialogActions } from "@material-ui/core";

import DrawingBoard from "components/DrawingBoard";
import LoadingModal from "components/LoadingModal";

const buttonStyling = `
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font: 400 13.3333px Arial;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  margin-left: 10px
`;

const SignConfirmationForm = ({
  content,
  elementId,
  contractId,
  onLoadAppointmentLetter,
  onClickClose,
  onClickSubmitSign,
}) => {
  const iframeRef = useRef(null);
  const [showDrawingBoard, onToggleDrawing] = useState(false);
  const [digitalSign, onChangeSign] = useState(null);

  const EditIframeContent = () => {
    let triggerSignButton = document.createElement("button");
    triggerSignButton.onclick = () => onToggleDrawing(true);
    triggerSignButton.innerHTML = "Click here to sign";
    triggerSignButton.style.cssText = buttonStyling;

    let tempContentWindow = iframeRef.current.contentWindow;
    if (tempContentWindow.document.body.innerHTML.length > 0) {
      let element = tempContentWindow.document.getElementById(elementId);

      element.removeChild(element.childNodes[0]);
      element.appendChild(triggerSignButton);
    }
  };

  return (
    <>
      <iframe
        className={"w-100"}
        style={{ height: "calc(100vh - 275px)" }}
        ref={iframeRef}
        srcDoc={content}
        onLoad={() => EditIframeContent()}
      />
      <DrawingBoard
        paperClass={"at-digital-sign_modal"}
        canvasClass={"at-profile__digital-canvas"}
        open={showDrawingBoard}
        title={`Sign Appointment Letter`}
        positiveButtonLabel={"Adopt Signature"}
        onClickClose={() => onToggleDrawing(false)}
        onClickSubmit={(val) => {
          onToggleDrawing(false);
          let tempContentWindow = iframeRef.current.contentWindow;
          let element = tempContentWindow.document.getElementById(elementId);
          let contract = tempContentWindow.document.getElementById(contractId);

          let signImage = document.createElement("img");
          signImage.src = val;
          signImage.style = "width: 150px;";

          let tempButton = element.getElementsByTagName("button")[0];
          element.innerHTML = "";
          element.appendChild(signImage);
          element.appendChild(tempButton);

          if (contract) {
            let signImage2 = document.createElement("img");
            signImage2.src = val;
            signImage2.style = "width: 150px;";

            contract.innerHTML = "";
            contract.appendChild(signImage2);
          }
          onChangeSign(val);
        }}
      />
      {onLoadAppointmentLetter && <LoadingModal />}
      <DialogActions>
        <div className="d-flex">
          <button
            className={"btn-new btn-new--danger"}
            onClick={() => onClickClose()}
          >
            Decline Appointment Letter
          </button>
          <button
            disabled={!digitalSign}
            className={"btn-new btn-new--success ml-2"}
            onClick={() => onClickSubmitSign(digitalSign)}
          >
            Accept Appointment Letter
          </button>
        </div>
      </DialogActions>
    </>
  );
};

export default SignConfirmationForm;
