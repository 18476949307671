import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      publishedTrainings: null,
    };

    load = (param) => this.setState({ loading: param });

    getPublishedTrainings = () =>
      Get(
        `/admin/exams/all_published_trainings`,
        this.getPublishedTrainingsSuccess,
        this.getPublishedTrainingsError,
        this.load,
      );
    getPublishedTrainingsSuccess = (payload) => {
      let temp = [];
      payload.map((item) => {
        temp.push({
          ...item,
          end_date_time: Moment(item.end_date_time).format("YYYY-MM-DD"),
        });
      });
      this.setState({ publishedTrainings: temp });
    };
    getPublishedTrainingsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            publishedTrainings={this.state.publishedTrainings}
            onLoadPublished={this.state.loading}
            getPublishedTrainings={this.getPublishedTrainings}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
