import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const AtlasShareButton = ({
  shareLink,
  title = "",
  media = "",
  tooltipContent,
  tooltipPlacment,

  containerStyle,
  containerClass,
  style,
}) => {
  const capitalized = `${media.charAt(0).toUpperCase()}${media.slice(1)}`;

  return (
    <DarkTooltip
      classes={{ tooltip: "tooltip-arrow left" }}
      placement={tooltipPlacment || "right"}
      enterTouchDelay={0}
      title={tooltipContent || `Share Link to ${capitalized}`}
    >
      <div
        style={{ width: "fit-content", ...containerStyle }}
        className={`${containerClass || ""}`}
      >
        {media === "facebook" && (
          <FacebookShareButton style={style} title={title} url={shareLink}>
            <FacebookIcon fill="#7f7f7f" size={32} round />
          </FacebookShareButton>
        )}
        {media === "twitter" && (
          <TwitterShareButton style={style} title={title} url={shareLink}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        )}
        {media === "linkedin" && (
          <LinkedinShareButton style={style} title={title} url={shareLink}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        )}
        {media === "email" && (
          <EmailShareButton style={style} title={title} url={shareLink}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        )}
        {media === "whatsapp" && (
          <WhatsappShareButton style={style} title={title} url={shareLink}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        )}
      </div>
    </DarkTooltip>
  );
};

export default AtlasShareButton;
