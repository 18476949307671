import { GET_DATA_DICTIONARY, GET_COUNTRY_DICTIONARY } from "actions/type";

let initialState = {
  contact: {},
  country: [],
  currencies: [],
  notification: {},
  project: {},
  team: [],
  banks: [],
  user: {},
  feedback: {},

  subsales: {},
};

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_DICTIONARY:
      const {
        countries,
        currencies,
        notification,
        team,
        banks,
        admin_roles,
        tutorial_categories,
      } = action.payload;

      // todo: refactor this
      let tempCurrencies = [];
      currencies.name.map((item) => {
        let data = {
          value: item,
          label: item,
        };
        return tempCurrencies.push(data);
      });
      return {
        ...state,
        countries: countries.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.currencies = {
            value: item.currencies,
            label: item.currencies,
          };
          item.states.map((state) => {
            state.value = state.id;
            state.label = state.name;
            return item;
          });
          return item;
        }),
        currencies: {
          name: tempCurrencies,
        },
        notification,
        team: team.map((item) => {
          item.value = item.id;
          item.label = item.name;
          return item;
        }),
        banks,
        admin_roles,
        tutorial_categories,
      };

    case GET_COUNTRY_DICTIONARY:
      return {
        ...state,
        countries: action.payload.countries.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.states.map((state) => {
            state.value = state.id;
            state.label = state.name;
            return item;
          });
          return item;
        }),
      };
    default:
      return state;
  }
}
