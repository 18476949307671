import Axios from "axios";

import { GET_MY_CART, UPDATE_CART_ITEM_QTY } from "./type";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export const getMyCart = (page, search) => {
  return (dispatch) => {
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const token = getItem(tokenName);
    Axios.defaults.headers = {
      common: {
        Authorization: `JWT ${token}`,
      },
      "Access-Control-Allow-Origin": "*",
    };
    dispatch(beginAjaxCall(true));
    Axios.get(`${getDomainURL()}/market_carts/my?${search}page=${page}`)
      .then((response) => {
        dispatch(ajaxCallSuccess());
        dispatch(getMyCartSuccess(response.data));
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
      });
  };
};

const getMyCartSuccess = (payload) => ({
  type: GET_MY_CART,
  payload,
});

export const updateCartItemQuantity = (payload) => ({
  type: UPDATE_CART_ITEM_QTY,
  payload,
});
