import React, { Component } from "react";
import { compose } from "redux";
import { numberWithCommas } from "utils/thousandSeparator";

import AtlasCard from "components/Card";
import AlertBox from "components/AlertBox";
import ModuleHeader from "components/ModuleHeader";
import CustomSearch from "./components/search";
import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";

import AmlaReportHOC from "../AdminAmlaReport/actions";

const columnData = [
  {
    header: "Transaction Date",
    accessor: "approved_date",
    searchFlag: "approved_date",
    columnStyle: { width: "60%" },
  },
  {
    header: "Name",
    accessor: "name",
    searchFlag: "name",
    columnStyle: { width: "40%" },
  },
  {
    header: "Email",
    accessor: "email",
    searchFlag: "email",
    columnStyle: { width: "60%" },
  },
  {
    header: "Mobile",
    accessor: "contact_number",
    searchFlag: "contact_number",
    columnStyle: { width: "60%" },
  },
  {
    header: "Type",
    accessor: "type",
    searchFlag: "type",
    columnStyle: { width: "30%" },
  },
  {
    header: "Form No",
    accessor: "form_no",
    searchFlag: "form_no",
    columnStyle: { width: "50%" },
  },
  {
    header: "Transaction Type",
    accessor: "claim_type",
    searchFlag: "claim_type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Property Type",
    accessor: "property_type",
    searchFlag: "property_type",
    contentClass: "at-column-responsive_left",
    columnClass: "at-column-responsive_left",
    columnStyle: { width: "50%" },
  },
  {
    header: "Transacted Amount",
    accessor: "price",
    searchFlag: "price",
    columnStyle: { width: "60%" },
    renderColumn: (rowData) => numberWithCommas(rowData.price.toFixed(2)),
  },
];

class AdminAmlaReport extends Component {
  componentDidMount = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    const last_day = new Date(currentYear, currentMonth, 0).getDate();
    this.props.getAmlaReport(
      `q[start_date]=${currentYear}-${formattedMonth}-01&q[end_date]=${currentYear}-${formattedMonth}-${last_day}&`,
    );
  };

  renderCardContent = () => {
    const {
      showSearchContent,
      searchParams,
      onChangeAmlaReportHOC,
      claim_submitted_count,
      claim_rejected_count,
      buyer_count,
      owner_count,
      rejected_amla,
      getAmlaReport,
      downloadReport,
    } = this.props;

    return (
      <>
        <CustomSearch
          searchParams={searchParams}
          showSearchContent={showSearchContent}
          onChangeAmlaReportHOC={onChangeAmlaReportHOC}
          downloadLink={downloadReport}
          getListAPI={getAmlaReport}
        />
        {(rejected_amla && rejected_amla.length > 0) && (
          <div className="mt-20">
            <AlertBox
              hideIcon={true}
              disabledClose={true}
              containerStyle={{
                background: "#FFFFFF",
                border: "1px solid #D1D5DB",
                marginBottom: 16,
              }}
              description={
                <>
                  Transactions are filtered by the submission types, which are
                  "Claim Commission", "Forfeit", "Release" and "Renewal".
                  <br />
                  There might be more than one buyer/tenant or owner in a
                  transaction that refuse to provide the AMLA details, which is
                  shown in the table below.
                  <br />
                  "Transaction Date" is the date when Admin approved the first
                  submission with any types mentioned above and export to ERP
                  within the date range selected.
                  <br />
                  "Transacted Amount" is the selling price for Sale type
                  transaction or rental for Rent type transaction.
                  <br />
                </>
              }
            />

            <div className="at-table-sum">
              <div className="at-table-col">
                <div className="at-sum-box-col">
                  <span
                    className="at-sum-icon"
                    style={{ background: "#F568CE", flex: 1 }}
                  >
                    <AtlasIcon
                      svgHref={"atlas-house-2"}
                      style={{ fill: "white", width: 32, height: 32 }}
                    />
                  </span>
                </div>
                <div className="at-sum-box-col" style={{ padding: "12px 0" }}>
                  <span className="at-sum-box-col__label">
                    Total Transactions without AMLA / Total Transactions
                  </span>
                  <span className="at-sum-box-col__value">{`${claim_rejected_count} / ${claim_submitted_count}`}</span>
                </div>
              </div>
              <div className="at-table-col">
                <div className="at-sum-box-col">
                  <span
                    className="at-sum-icon"
                    style={{ background: "#6875F5", flex: 1 }}
                  >
                    <AtlasIcon
                      svgHref={"atlas-user-person"}
                      style={{ fill: "white", width: 32, height: 32 }}
                    />
                  </span>
                </div>
                <div className="at-sum-box-col" style={{ padding: "12px 0" }}>
                  <span className="at-sum-box-col__label">
                    Total Buyers / Tenants without AMLA
                  </span>
                  <span className="at-sum-box-col__value">{buyer_count}</span>
                </div>
              </div>
              <div className="at-table-col">
                <div className="at-sum-box-col">
                  <span
                    className="at-sum-icon"
                    style={{ background: "#374151", flex: 1 }}
                  >
                    <AtlasIcon
                      svgHref={"atlas-user-tie"}
                      style={{ fill: "white", width: 32, height: 32 }}
                    />
                  </span>
                </div>
                <div className="at-sum-box-col" style={{ padding: "12px 0" }}>
                  <span className="at-sum-box-col__label">
                    Total Owners without AMLA
                  </span>
                  <span className="at-sum-box-col__value">{owner_count}</span>
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center grid_gap-2 mx-12"
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              <p style={{ fontWeight: 600, fontSize: 18 }}>
                Owner/Buyer/Tenant list without AMLA
              </p>
              <button
                className="btn-new btn-new--outline-secondary"
                onClick={downloadReport}
              >
                <AtlasIcon
                  svgHref={"atlas-xlsx-file"}
                  style={{
                    fill: "#208E4D",
                    width: 20,
                    height: 20,
                  }}
                />
                Export
              </button>
            </div>

            <CustomTable
              pagination={true}
              hideSearch={true}
              rowData={rejected_amla}
              columns={columnData}
            />
          </div>
        )}
        {!(rejected_amla && rejected_amla.length > 0) && (
          <EmptyState
            title={`No results found`}
            description={`Try adjusting your search to find what you are looking for`}
            renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
            containerClassName={"mt-3"}
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={"AMLA Report"} moduleIcon={"icon-report"} />
        <AtlasCard cardContent={this.renderCardContent()} />
        {this.props.loading && <LoadingModal />}
      </>
    );
  };
}

export default compose(AmlaReportHOC)(AdminAmlaReport);
