import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";

const attendeeData = [
  { label: "Total Registered", value: "total_registered", icon: 'atlas-profile2user', color: '#6875F5' },
  { label: "Total Present", value: "total_present", icon: 'atlas-tick-circle', color: '#14B8A6' },
  { label: "Total Pending", value: "total_pending", icon: 'atlas-refresh', color: '#F79009' },
  { label: "Total Absent", value: "total_absent", icon: 'atlas-close-square', color: '#E74694' },
  { label: "Total Resit", value: "total_resit", icon: 'atlas-repeat-circle', color: '#06B6D4' },
];

const AttendeeSummary = ({
  selectedEvent,
  summaryLoading,
  attendeeSummary,
  getAttendeeSummary,
}) => {
  return (
    <>
      <div className="d-flex align-items-center g-2">
        <div className='at-badge-icon-sm at-badge-icon-warning' >
          <AtlasIcon svgHref={ 'atlas-element-equal' } />
        </div>
        <p style={{ fontSize: 18, fontWeight: 600, color: '#1F2937' }}>Attendees Summary</p>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => getAttendeeSummary(selectedEvent.id)}>
          <AtlasIcon svgHref={ 'atlas-refresh-2-linear' } style={{ width: 16, height: 16, fill: '#1F2937', marginRight: 8 }} />
          Refresh
        </button>
      </div>
      <div className="at-attendee-summary__stat-cont my-3">
        {attendeeData.map((item, index) => (
          <div
            key={index}
            style={{ padding: 12 }}
            className="at-form__content d-flex align-items-center w-100 mb-2"
          >
            <div
              className="at-card--stat__general-icon-cont"
              style={{ backgroundColor: item.color }}
            >
              <AtlasIcon svgHref={item.icon} />
            </div>
            <div className="at-card--stat__general-category mb-0">
              <p>{item.label}</p>
              <span>{attendeeSummary[item.value]}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AttendeeSummary;
