import React, { useRef, useEffect } from "react";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";

import "./index.scss";

export default function AtlasImageEditor({
  onClickSave,
  onClickProceedWithoutCropping,
  imagePath,
  imageFile,
  aspectRatio,

  ImageEditorTheme,
  ImageEditorMenu,
}) {
  let editorRef = useRef(null);

  useEffect(() => {
    if (editorRef && editorRef.current.imageEditorInst) {
      setTimeout(() => {
        editorRef.current.imageEditorInst.setCropzoneRect(aspectRatio || 1);
      }, 100);
    }
  }, [editorRef]);

  return (
    <>
      <ImageEditor
        ref={editorRef}
        includeUI={{
          loadImage: {
            path: imagePath,
            name: imageFile,
          },
          theme: ImageEditorTheme,
          menu: ImageEditorMenu,
          initMenu: "crop",
          menuBarPosition: "right",
          uiSize: {
            width: "100%",
            height: "75vh",
          },
        }}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
      />
      <div className="d-flex bg-dark p-2 flex-row-reverse justify-content-center">
        <button
          className="at-btn at-btn--secondary ml-2"
          onClick={() => {
            let tempApply = document.getElementsByClassName(
              "tui-image-editor-button apply",
            );
            tempApply[0].click();

            setTimeout(() => {
              const imageEditorInst = editorRef.current.imageEditorInst;
              const data = imageEditorInst.toDataURL();
              onClickSave(data, data.split(";")[0]);
            }, 50);
          }}
        >
          Proceed with cropped image
        </button>
        <button
          className="at-btn at-btn--danger"
          onClick={() => onClickProceedWithoutCropping()}
        >
          Proceed Without Cropping
        </button>
      </div>
    </>
  );
}
