import { IS_FIRST_TIME_LOGIN, GET_APP_CONFIG } from "actions/type";

let initialState = {
  isFirstTime: true,
  remoteConfig: {},
};

export default function appStatusReducer(state = initialState, action) {
  switch (action.type) {
    case IS_FIRST_TIME_LOGIN:
      return {
        ...state,
        isFirstTime: action.payload,
      };
    case GET_APP_CONFIG:
      return {
        ...state,
        remoteConfig: action.payload,
      };
    default:
      return state;
  }
}
