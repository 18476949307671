import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import ReaRenewalDetails from "./Details";

import ReaRenewalHOC from "./actions";
import { getColorBadge } from "dictionary/badgeColor";

const statusColor = [
  { value: "Open", colorName: "Green" },
  { value: "Closed", colorName: "Red" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Processing", colorName: "Blue" },
  { value: "Submit to Finance", colorName: "Violet" },
  { value: "Payment Made", colorName: "Cyan" },
  { value: "Board Approved", colorName: "Pink" },
  { value: "Tag Arrived", colorName: "Teal" },
  { value: "Tag Collected", colorName: "Green" },
  { value: undefined, colorName: "Grey" },
];

const columnData = [
  {
    header: "Renewal Year",
    renderColumn: (rowData) => rowData.licence_renewal.renewal_year,
  },
  {
    header: "Cut-off Date",
    renderColumn: (rowData) =>
      Moment(rowData.licence_renewal.last_submit_date).format("DD MMM YYYY"),
  },
  {
    header: "Registration Status",
    renderColumn: (rowData) => {
      const { colorName } = _.find(statusColor, {
        value: rowData.licence_renewal_status,
      });

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.licence_renewal_status}
        </div>
      );
    },
  },
  {
    header: "Renewal Status",
    renderColumn: (rowData) => {
      const { colorName } = _.find(statusColor, {
        value: rowData.applicant_status_name,
      });
      return (
        <>
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData.applicant_status_name}
          </div>
          {rowData.applicant_status_name === "Approved" && (
            <p className="fw-500 fs-2 text-danger">
              Please be patient for board to proceed REA tag
            </p>
          )}
        </>
      );
    },
  },
];

class ReaRenewal extends Component {
  componentDidMount = () => {
    this.props.getReaRenewalApplications(1, "");
  };

  renderCardContent = () => (
    <AtPaginatedTable
      rowData={this.props.renewalApplications.data || []}
      meta={this.props.renewalApplications.meta || []}
      columns={columnData}
      searchParams={[]}
      actionColumnContent={[
        {
          name: "info",
          onShow: (rowData) => rowData.applicant_status_name !== "Pending",
          onClick: (rowData) =>
            this.props.getSelectedReaRenewalApplication(rowData.id),
        },
        {
          name: "edit",
          onShow: (rowData) => rowData.applicant_status_name === "Pending",
          onClick: (rowData) =>
            this.props.getSelectedReaRenewalApplication(rowData.id),
        },
      ]}
      totalPages={this.props.renewalApplicationsPage || []}
      getListAPI={this.props.getReaRenewalApplications}
    />
  );

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"License Renewal"}
          atlasIcon={"atlas-personalcard"}
        />
        <CustomCard
          className="at-card--profile"
          cardContent={this.renderCardContent()}
        />
        <AtlasDialog open={this.props.showRenewalDetails}>
          <ReaRenewalDetails
            statusColor={statusColor}
            selectedRenewal={this.props.selectedRenewal}
            onChangeRenewalHOC={this.props.onChangeRenewalHOC}
            getSelectedReaRenewalApplication={
              this.props.getSelectedReaRenewalApplication
            }
          />
        </AtlasDialog>
        {this.props.onLoadReaRenewal && <LoadingModal />}
      </>
    );
  };
}

export default ReaRenewalHOC(ReaRenewal);
