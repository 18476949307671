import SVGIcon from "components/Icon";

import "stylesheets/components/checkbox/index.scss";

const CustomCheckbox = ({
  disabled,
  containerClass,
  labelClassname,
  checkboxClassname,
  content,
  translation,
  contentStyle,
  labelStyle,
  checked,
  checkedArray,
  outerContent, //make checkbox only check or uncheck when click on checkbox, not label
  onChangeCheckboxValue,
  getLocalised
}) => {
  return (
    <div className={`d-flex align-items-center ${containerClass || ""}`}>
      <label
        className={`at-checkbox${disabled ? "-disabled" : ""} grid_gap-1 ${labelClassname || ""}`}
        style={labelStyle}
      >
        <input
          type="checkbox"
          disabled={disabled}
          className={`at-checkbox__input ${checkboxClassname || ""}`}
          checked={checked}
          onChange={onChangeCheckboxValue}
        />
        <span className="at-checkbox__span-box">
          <SVGIcon
            className={"at-checkbox__tick svg-icon-tick"}
            svgHref={"icon-tick"}
          />
        </span>
        {content && (
          <p className="at-checkbox__label-text" style={contentStyle}>
            {getLocalised ? getLocalised(translation, content) : content}
            {checkedArray && checkedArray.length > 0 && (
              <span className="text-secondary ml-3">
                {checkedArray.length} item(s) selected
              </span>
            )}
          </p>
        )}
      </label>
      {outerContent && (
        <p className="at-checkbox__label-text" style={contentStyle}>
          {outerContent}
        </p>
      )}
    </div>
  );
};

export default CustomCheckbox;
