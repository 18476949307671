import React from "react";
import _ from "lodash";

import ModalDialog from 'components/Modal/ModalDialog';
import EventTrainingForm from './form'

import CreateEventHOC from "../actions/createEvent";

const CreateEventContent = ({
  data,
  createEvent,
  onChangeEventHOC,
  createNewEvent,
  branchRegions,
  onChangeFileCreate,
  onChangeFieldCreate,
  onLoadCreateEvent,
}) => {
  const onChangeField = (val, context) => {
    if (context === "type_id") {
      if (val === 1) {
        onChangeFieldCreate(null, "training_type_id");
      }
    }
    if (context === "branch_region_id") {
      let tmpBranchRegion = _.find(branchRegions, { id: val });
      onChangeFieldCreate(tmpBranchRegion.address, "location");
      onChangeFieldCreate(tmpBranchRegion.google_map_url, "location_url");
    }
    onChangeFieldCreate(val, context);
  };

  return (
    <ModalDialog
      title={ 'New Event' }
      onLoad={ onLoadCreateEvent }
      fullHeight={ true }
      fullWidth={ true }
      onClose={ () => onChangeEventHOC( false, 'showCreateEventDialog' ) }
      children={
        <EventTrainingForm
          mode={ 'create' }
          data={ data }
          eventDetails={ createNewEvent }
          submitEventForm={ () => createEvent( createNewEvent ) }
          type_id = { createNewEvent.type_id }
          branchRegions={ branchRegions }
          onChangeFile = { onChangeFileCreate }
          onChangeField = { onChangeField }
          onClose={ () => onChangeEventHOC( false, 'showCreateEventDialog' ) } />
      } />
  )
}

export default CreateEventHOC(CreateEventContent);
