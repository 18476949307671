import React, { Component } from "react";
import { Post, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

import { connect } from "react-redux";

const oppositePaticipant = (WrappedComponent) => {
  class OppositePaticipantComponentHOC extends Component {
    state = {
      loading: false,
      infoPassword: "",
      showShareLink: false,
      showSendEmailLink: false,
      disabledShareButtons: true,
      recipientEmail: "",
      currentTypeId: "",
      showRemoveShareLinkConfirmation: false,
    }

    load = param => this.setState({ loading: param });

    onChangeOppositePaticipantHOC = (value, context) => {
      this.setState({ [context]: value });
    }

    updateOppositeInfoProvided = (id, data) => {
      Put(
        `/sub_sale_claims/${id}/update_opposite_info_provided`,
        data,
        (payload) => this.updateOppositeInfoProvidedSuccess(payload, id),
        this.updateOppositeInfoProvidedError,
        this.load,
      );
    };
    updateOppositeInfoProvidedSuccess = (payload, id) => {
      this.setState({
        showRemoveShareLinkConfirmation: false,
        infoPassword: payload.opposite_info_password
      })
      requestSuccess("Info updated successfully.");
      this.props.getSelectedClaim(id);
      this.props.getAssignedContactType(id, this.state.currentTypeId);

    };
    updateOppositeInfoProvidedError = (error) => requestError(error);

    sendOppositeInfoRequestEmail = (id, data) => {
      Post(
        `/sub_sale_claims/${id}/send_opposite_info_request_email`,
        { recipients: data.recipients },
        (payload) => this.sendOppositeInfoRequestEmailSuccess(payload, id),
        this.sendOppositeInfoRequestEmailError,
        this.load,
      );
    };
    sendOppositeInfoRequestEmailSuccess = (payload, id) => {
      this.setState({ recipientEmail: "" });
      requestSuccess("Info submitted successfully.");
      this.props.getSelectedClaim(id);
      this.props.getAssignedContactType(id, this.state.currentTypeId);
    };
    sendOppositeInfoRequestEmailError = (error) => requestError(error);
    
    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          showRemoveShareLinkConfirmation={this.state.showRemoveShareLinkConfirmation}
          showShareLink={this.state.showShareLink}
          recipientEmail={this.state.recipientEmail}
          infoPassword={this.state.infoPassword}
          disabledShareButtons={this.state.disabledShareButtons}
          showSendEmailLink={this.state.showSendEmailLink}
          onChangeOppositePaticipantHOC={this.onChangeOppositePaticipantHOC}
          updateOppositeInfoProvided={this.updateOppositeInfoProvided}
          sendOppositeInfoRequestEmail={this.sendOppositeInfoRequestEmail}
          onLoadOppositeParticipant={this.state.loading}
        />
      );
    };
  }

    const mapStateToProps = (state) => ({ data: state });
    return connect(mapStateToProps)(OppositePaticipantComponentHOC);
}

export default oppositePaticipant;