import { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Post } from "utils/axios";
import objToFormData from "utils/objToFormData";
import { requestError, requestSuccess } from "utils/requestHandler";

const withCreateProject = (WrappedComponent) => {
  class CreateProjectHOC extends Component {
    state = {
      loading: false,
      newProject: {
        background_image: "",
        bathroom_display: "",
        bedroom_display: "",
        completion_date: "",
        country_id: 1,
        description: "",
        developer: "",
        display_subtitle: "",
        features_description: "",
        gmap: "",
        has_live_sales: false,
        is_visible_on_web: false,
        hd_map_image: "",
        internal_name: "",
        is_featured: false,
        is_exclusive: false,
        land_area: 0,
        latitude: "",
        location: "",
        location_description: "",
        longitude: "",
        map_image: "",
        max_bathroom: "",
        max_bedroom: "",
        max_built_up: "",
        max_price_cents: "",
        max_unit: "",
        min_bathroom: "",
        min_bedroom: "",
        min_built_up: "",
        name: "",
        measurement_id: 1,
        net_price_cents: "",
        net_price_currency: "",
        overview_description: "",
        overview_image: "",
        phase: "",
        project_features_image: "",
        project_image: "",
        project_thumbnail: "",
        telegram_link: "",
        project_title_id: "",
        state_id: "",
        township: "",
        status_id: "",
        subarea: "",
        tenure_type_id: "",
        title_description: "",
        type_id: "",
        display_name_type: "",
        reference_id: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeCreateHOC = (val, context) => this.setState({ [context]: val });

    createProject = () => {
      let data = _.cloneDeep(this.state.newProject);

      data.display_name_type && data.display_name_type === "internal"
        ? (data.show_internal_name_as_display_name = true)
        : (data.show_internal_name_as_display_name = false);

      delete data.display_name_type;

      Post(
        `/admin/projects`,
        objToFormData(data, "project"),
        this.createProjectSuccess,
        this.createProjectError,
        this.load,
      );
    };
    createProjectSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getProject(payload.id);
      this.props.getAllProjects(currentPage, searchParams);
      this.props.onChangeProjectManagementHOC(false, "showNewProjectDialog");
      requestSuccess("Project was created successfully!");
    };
    createProjectError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          newProject={this.state.newProject}
          onLoadCreate={this.state.loading}
          onChangeCreateHOC={this.onChangeCreateHOC}
          createProject={this.createProject}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(CreateProjectHOC);
};

export default withCreateProject;
