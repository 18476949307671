import { Component } from "react";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const withTownships = (WrappedComponent) => {
  class TownshipsHOC extends Component {
    state = {
      townships: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getTownshipsByStateId = (stateId) =>
      Get(
        `/townships?state_id=${stateId}`,
        this.getTownshipsByStateIdSuccess,
        this.getTownshipsByStateIdError,
        this.load,
      );
    getTownshipsByStateIdSuccess = (payload) =>
      this.setState({ townships: payload });
    getTownshipsByStateIdError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          townships={this.state.townships}
          onLoadTownships={this.state.loading}
          getTownshipsByStateId={this.getTownshipsByStateId}
        />
      );
    };
  }

  return TownshipsHOC;
};

export default withTownships;
