import React from "react";
import _ from "lodash";
import { IoIosReturnLeft } from "react-icons/io";

import { numberWithCommas } from "utils/thousandSeparator";

const priceHandler = (val) => {
  if (val > 1000000) {
    return `${(val / 1000000).toFixed(2)}M`;
  }

  return numberWithCommas(val, 2);
};

export default function TransactionContent({
  transaction_info,
  onSetTransaction,
}) {
  let tempSale = _.find(transaction_info, { transaction_type: "Sale" });
  let tempRent = _.find(transaction_info, { transaction_type: "Rental" });

  let tempRentRange = tempRent ? Math.trunc(tempRent.total / 25) : null;
  let tempSaleRange = tempSale ? Math.trunc(tempSale.total / 25) : null;

  return (
    <>
      <div className="at-transaction-summary">
        <IoIosReturnLeft onClick={() => onSetTransaction(false)} />
        <div className="at-transaction-summary_body">
          {tempSale && (
            <div>
              <p>Price Range For Sale</p>
              <strong>{`RM ${priceHandler(tempSale.min_price)} - RM ${priceHandler(tempSale.max_price)}`}</strong>
              <span>{`${tempSaleRange * 25 + 1} - ${(tempSaleRange + 1) * 25} Sold`}</span>
            </div>
          )}
          {tempRent && (
            <div>
              <p>Price Range For Rental</p>
              <strong>{`RM ${priceHandler(tempRent.min_price)} - RM ${priceHandler(tempRent.max_price)}`}</strong>
              <span>{`${tempRentRange * 25 + 1} - ${(tempRentRange + 1) * 25} Rented`}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
