import React from "react";
import { MdCached } from "react-icons/md";
import { Tooltip } from "@material-ui/core";

import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomToggle from "components/Button/toggle";

const DriveTableTopBar = ({
  layout,
  title,
  path,
  parent_path,
  directoryLayerCount,
  disablePathNavigation,
  downloadList,
  showFavouriteListsFunction,
  showFavouriteLists,
  showResetCacheFunction,
  sortingMode,
  sortingName,
  checkableItems,

  onSortModifiedData,
  onSortName,
  setLayout,
  onPressUpDirectory,
  getAllFavourites,
  downloadMultipleFiles,
  clearDriveCache,
  onChangeHOC,
}) => {
  return (
    <>
      <div className="at-drive_table-top_bar">
        <div className="d-flex aligh-items-center">
          {!disablePathNavigation && directoryLayerCount === 1 && (
            <>
              <div className="at-badge-icon-sm at-badge-icon-warning">
                <AtlasIcon svgHref={"atlas-folder"} />
              </div>
              <Tooltip
                classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                placement="top"
                title={"Current Folder"}
              >
                <span className="at-drive_table-top_bar-path_name">
                  {title || "IQI Drive"}
                </span>
              </Tooltip>
              {showResetCacheFunction && (
                <Tooltip
                  classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                  placement={"top"}
                  title={"Reset Cache"}
                >
                  <button
                    className="mx-3"
                    style={{ height: "2.6rem" }}
                    onClick={() => clearDriveCache("projects-lite", "refresh")}
                  >
                    <MdCached style={{ width: "25px", height: "30px" }} />
                  </button>
                </Tooltip>
              )}
            </>
          )}
          {
            //Back to previous directory
            !disablePathNavigation && directoryLayerCount >= 2 && (
              <>
                <Tooltip placement="top" title={"Back to Previous Folder"}>
                  <button
                    className="at-drive_table-top_bar-directory_path_back_icon"
                    onClick={onPressUpDirectory}
                  >
                    <AtlasIcon svgHref={"atlas-arrow-left-linear"} />
                  </button>
                </Tooltip>
                <span className="at-drive_table-top_bar-path_name">
                  IQI Drive
                  {path.map((item, index) => (
                    <span
                      key={index}
                      className="d-flex align-items-center fs-2 fw-500"
                      style={
                        index + 1 === path.length ? { color: "#F0631D" } : {}
                      }
                    >
                      <AtlasIcon svgHref={"atlas-arrow-right-1-linear"} />
                      {item}
                    </span>
                  ))}
                </span>
                {showResetCacheFunction && (
                  <Tooltip
                    classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                    placement={"top"}
                    title={"Reset Cache"}
                  >
                    <button
                      className="mx-3"
                      style={{ height: "2.6rem" }}
                      onClick={() => clearDriveCache(parent_path, "refresh")}
                    >
                      <MdCached style={{ width: "25px", height: "30px" }} />
                    </button>
                  </Tooltip>
                )}
              </>
            )
          }
        </div>
        <div className="at-drive_table-top_bar-right">
          {downloadList.length > 0 && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              containerClass={"at-drive-table__download-desktop"}
              onClick={downloadMultipleFiles}
            >
              <AtlasIcon
                svgHref={"atlas-document-download"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "white",
                }}
              />
              Download
            </CustomButton>
          )}
          {showFavouriteListsFunction && (
            <div className="at-drive_table-top_bar-row_item-show_favourite_buttons">
              <CustomToggle
                className={"at-toggle__secondary mt-1"}
                currentState={!showFavouriteLists}
                onToggleButton={() => getAllFavourites()}
              />
              <span>My Favourite</span>
            </div>
          )}

          <div className="at-drive_float-cont">
            <button
              className={`btn-float ${layout === "List" ? "btn-float-selected" : ""}`}
              onClick={() => setLayout("List")}
            >
              <AtlasIcon
                svgHref="atlas-menu-1"
                style={{
                  width: 20,
                  height: 20,
                  fill: "#374151",
                }}
              />
              List
            </button>
            <button
              className={`btn-float ${layout === "Grid" ? "btn-float-selected" : ""}`}
              onClick={() => setLayout("Grid")}
            >
              <AtlasIcon
                svgHref="atlas-element-3"
                style={{
                  width: 20,
                  height: 20,
                  fill: "#374151",
                }}
              />
              Grid
            </button>
          </div>
        </div>
      </div>
      <div className={`at-drive_table-sorting-cont`}>
        {checkableItems.length > 0 && (
          <Tooltip placement="top" title={"Download selected files"}>
            <div>
              <CustomCheckbox
                content={"Select All"}
                labelClassname={"at-drive-table__checkbox m-0"}
                checked={
                  checkableItems.length > 0 &&
                  checkableItems.length === downloadList.length
                }
                onChangeCheckboxValue={(event) => {
                  onChangeHOC(
                    event.target.checked ? checkableItems : [],
                    "downloadList",
                  );
                }}
              />
            </div>
          </Tooltip>
        )}
        <div>
          <span className="at-drive_table-sort-by">Sort by</span>
          <div
            className={`btn-new--outline-secondary`}
            style={{ padding: "6px 12px", borderRadius: 8 }}
            onClick={() => onSortName()}
          >
            <strong>Name</strong>
            <AtlasIcon
              svgHref={"atlas-arrow-3-linear"}
              style={{ width: 16, height: 16, marginLeft: 6, fill: "#374151" }}
            />
          </div>
          <div
            className={`btn-new--outline-secondary`}
            style={{ padding: "6px 12px", borderRadius: 8 }}
            onClick={() => onSortModifiedData()}
          >
            <strong>Modified</strong>
            <AtlasIcon
              svgHref={"atlas-arrow-3-linear"}
              style={{ width: 16, height: 16, marginLeft: 6, fill: "#374151" }}
            />
          </div>
        </div>
      </div>
      {downloadList.length > 0 && (
        <CustomButton
          className={"btn-new btn-new--primary"}
          containerClass={"at-drive-table__download-mobile mb-3"}
          onClick={downloadMultipleFiles}
        >
          <AtlasIcon
            svgHref={"atlas-document-download"}
            style={{ width: 16, height: 16, fill: "white" }}
          />
          Download
        </CustomButton>
      )}
    </>
  );
};

export default DriveTableTopBar;
