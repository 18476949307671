import React, { Component } from "react";
import _ from "lodash";

import CustomPagination from "components/Pagination";
import CustomTableHeader from "./header";
import CustomTableContent from "./content";

import "stylesheets/components/table/index.scss";

class CustomTable extends Component {
  constructor(p) {
    super();
    this.state = {
      searching: false,
      selectPerPageOption: 10,
      totalPaginationCount: 0,
      totalPages: [1],
      clickPageNumber: 1,
      rowData: [],
      initialRowData: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData !== prevProps.rowData) {
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption,
          ),
      );
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;
        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          Object.keys(item).map((key) => {
            if (tmp[key] !== item[key]) {
              dataUpdated = true;
            }
          });
        });
        if (dataUpdated) {
          return this.setState(
            {
              rowData: this.props.rowData,
              initialRowData: this.props.rowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption,
              ),
          );
        }
      }
    }
  };

  componentDidMount = () => {
    this.handleResize();

    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    return () => window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ selectPerPageOption: 3 });
    } else {
      this.setState({ selectPerPageOption: this.state.selectPerPageOption });
    }
    this.processPagesData(this.state.rowData, this.state.selectPerPageOption);
  };

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  selectPerPageOption = (val) =>
    this.setState(
      {
        clickPageNumber: 1,
        selectPerPageOption: val.value,
      },
      () => this.processPagesData(this.state.rowData, val.value),
    );

  renderHeader = () => {
    const { headerData } = this.props;
    return <CustomTableHeader data={headerData} />;
  };

  renderSubtotalContent = () => {
    if (this.state.rowData && this.state.rowData.length > 0) {
      return (
        <CustomTableContent
          headerData={this.props.headerData}
          isSubtotalContent={true}
          subtotalValue={this.props.subtotalValue}
          rowData={this.props.rowData}
          isPriceData={this.props.isPriceData}
          onClickViewNameCard={this.props.onClickViewNameCard}
        />
      );
    }
  };

  renderTableContent = () => {
    if (this.state.rowData && this.state.rowData.length > 0) {
      return this.state.rowData.map((rowItem, index) => {
        if (
          index + 1 >
            (this.state.clickPageNumber - 1) * this.state.selectPerPageOption &&
          index + 1 <=
            this.state.clickPageNumber * this.state.selectPerPageOption
        ) {
          return (
            <CustomTableContent
              headerData={this.props.headerData}
              key={index}
              rowItem={rowItem}
              isPriceData={this.props.isPriceData}
              onClickViewNameCard={this.props.onClickViewNameCard}
              onCheckItemCheckBoxValue={this.props.onCheckItemCheckBoxValue}
            />
          );
        }
      });
    }
  };

  renderPaginationText = () => {
    const { rowData } = this.props;

    return (
      <p className={`at-table__pagination-info`} style={{ marginLeft: 20 }}>
        Showing a total of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  renderPagination = () => {
    return (
      <CustomPagination
        totalPages={this.state.totalPages}
        chosenPageNumber={this.state.selectPerPageOption}
        onClickPageNumber={(val) => this.onClickPageNumber(val)}
        currentPageNumber={this.state.clickPageNumber}
      />
    );
  };

  render = () => {
    return (
      <div className="at-table at-table--set at-order_table">
        {this.renderHeader()}
        {this.renderTableContent()}
        {this.renderSubtotalContent()}
        {this.renderPaginationText()}
      </div>
    );
  };
}

export default CustomTable;
