import { CircularProgress } from "@material-ui/core";

import AtlasFormInput from "components/Input/formInput";

const CustomFormInput = ({
  type,
  className,
  placeholder,
  prefix,
  value,
  onChange,
  required,
  disabled,
  isLoading,
  successMessage,
  errorMessage,
  maxLength,
}) => {
  const feedbackStyle = {
    width: required ? "calc( 100% - 60px )" : "100%",
  };

  return (
    <>
      <div className={"position-relative"}>
        <AtlasFormInput
          type={type || "text"}
          maxLength={maxLength}
          className={className || "mb-0"}
          containerStyle={{ height: 40 }}
          placeholder={placeholder}
          prefix={prefix}
          value={value}
          onChangeValue={onChange}
          required={required}
          disabled={disabled}
          inputSuccess={successMessage}
          inputError={errorMessage}
        />
        {isLoading && (
          <CircularProgress
            size={24}
            className={"register-container__spinner"}
            color={"inherit"}
          />
        )}
      </div>
      {successMessage && (
        <div
          className={"valid-feedback register-container__feedback"}
          style={feedbackStyle}
        >
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div
          className={"invalid-feedback register-container__feedback"}
          style={feedbackStyle}
        >
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default CustomFormInput;
