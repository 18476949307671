import React, { useState } from "react";
import _ from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import MyUploadAdapter from "./UploadAdapter";

import "./index.scss";

const CKTextEditor = ({
  className,
  placeholder,
  hideToolbar,
  disabled,
  data = "",
  onChange,
}) => {
  const [enabledOnChange, setEnableOnChange] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(!data);

  return (
    <div
      className={`at-custom_ckeditor ${className || ""} ${disabled ? "at-custom_ckeditor-disabled" : ""} ${hideToolbar ? "at-custom_ckeditor-hide_toolbar" : ""}`}
    >
      {showPlaceholder && !disabled && (
        <p className="at-custom_ckeditor-placeholder">
          {placeholder || "Type your message here..."}
        </p>
      )}
      <CKEditor
        disabled={disabled}
        editor={ClassicEditor}
        config={{
          image: {
            upload: { types: ["png", "jpeg", "heicc"] }, // Image upload feature options.
          },
          extraPlugins: [
            function (editor) {
              editor.plugins.get("FileRepository").createUploadAdapter = (
                loader,
              ) => {
                return new MyUploadAdapter(loader);
              };
            },
          ],
          link: {
            addTargetToExternalLinks: true,
            decorators: [
              {
                mode: "manual",
                label: "Downloadable",
                attributes: {
                  download: "download",
                },
              },
            ],
          },
        }}
        data={data}
        onChange={(event, editor) => {
          if (enabledOnChange) {
            onChange(event, editor);
          }
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          setShowPlaceholder(_.isEmpty(data));
          setEnableOnChange(false);
        }}
        onFocus={(event, editor) => {
          setShowPlaceholder(false);
          setEnableOnChange(true);
        }}
      />
    </div>
  );
};

export default CKTextEditor;
