import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      deliveryBranches: [],
    };

    load = (param) => this.setState({ loading: param });

    getDeliveryBranches = () =>
      Get(
        `/branches/?[q][branch_selections_type_id_eq]=2`,
        this.getDeliveryBranchesSuccess,
        this.getDeliveryBranchesError,
        this.load,
      );
    getDeliveryBranchesSuccess = (payload) =>
      this.setState({ deliveryBranches: payload });
    getDeliveryBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadDeliveryBranches={this.state.loading}
            deliveryBranches={this.state.deliveryBranches}
            getDeliveryBranches={this.getDeliveryBranches}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
