import React from "react";
import Moment from "moment";

import AtlasTable from "components/NewTable";
import JsonPrettifier from "components/JsonPrettifier";
import isEmptyValue from "utils/isEmpty";

import { connect } from "react-redux";

const columnData = [
  {
    header: "Created At",
    accessor: "created_at",
    searchFlag: "created_at",
    columnStyle: { width: "100%" },
    renderColumn: (rowData) => (
      <p>{Moment(rowData.created_at).format("DD MMM YYYY")}</p>
    ),
  },
  {
    header: "Description",
    accessor: "description",
    searchFlag: "description",
    columnStyle: { width: "100%" },
  },
  {
    header: "Paid amount / Pricing",
    accessor: "pricing",
    hideFromSearch: true,
    searchFlag: "pricing",
    columnStyle: { width: "100%" },
    renderColumn: (rowData) => (
      <span>
        {`${isEmptyValue(rowData.amount) ? "N/A" : rowData.amount} 
          / 
          ${isEmptyValue(rowData.pricing) ? "N/A" : rowData.pricing}`}
      </span>
    ),
  },
  {
    header: "Gateway",
    accessor: "gateway",
    searchFlag: "gateway",
    columnStyle: { width: "100%" },
  },
  {
    header: "Request IP",
    accessor: "request_ip",
    searchFlag: "request_ip",
    columnStyle: { width: "100%" },
  },
  {
    header: "Response",
    accessor: "response",
    searchFlag: "response",
    renderColumn: (rowData) => (
      <p>
        {!isEmptyValue(rowData.response) &&
          JsonPrettifier(JSON.parse(rowData.response))}
      </p>
    ),
    columnStyle: { width: "100%" },
  },
  {
    header: "Status",
    accessor: "status",
    searchFlag: "status",
    columnStyle: { width: "100%" },
  },
];

const PaymentDetail = ({
  attendeePaymentDetail
}) => {
  return (
    <AtlasTable
      className={"mb-100"}
      columns={columnData}
      pagination={true}
      rowData={attendeePaymentDetail || []}
      actionColumnContent={[]}
    />
  );
};

export default PaymentDetail;