import React, { Component } from "react";
import _ from "lodash";
import { MdFilterList } from "react-icons/md";
import { connect } from "react-redux";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import SearchParamsContent from "components/NewPaginatedTable/SearchParams";
import TableContent from "./content";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";
import { project as projectDictionary } from "dictionary";
import "./index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchParams: false,
  };

  renderTableContent = () => {
    const { title, type, tenure } = projectDictionary;

    const processProjectParams = (param, props) => {
      let data = _.find(props, { id: param });

      return data ? data.name : "";
    };

    if (this.props.rowData && this.props.rowData.length > 0) {
      return (
        <ul className="at-presentation_centre-edit_project at-project__container mt-2">
          {this.props.rowData.map((item) => {
            return (
              <TableContent
                rowItem={{
                  ...item,
                  imgSrc: item.background_image_url,
                  title: item.name,
                  projectTitleType: processProjectParams(
                    item.project_title_id,
                    title,
                  ),
                  projectType: processProjectParams(item.type_id, type),
                  tenureType: processProjectParams(item.tenure_type_id, tenure),
                  measurement: `${item.min_built_up || "N/A"}-${item.max_built_up || "N/A"}`,
                }}
                key={item.id}
                onShowRemoveIcon={this.props.onShowRemoveIcon}
                onShowAddIcon={this.props.onShowAddIcon}
                actionsColumns={this.props.actionsColumns}
                keyRef={`ProjectListing-${item.id}`}
                onClickAdd={() => this.props.onClickAdd(item)}
                onClickRemove={() => this.props.onClickRemove(item)}
                onClickDetails={() => this.props.onClickDetails(item)}
              />
            );
          })}
        </ul>
      );
    } else {
      return (
        <EmptyState
          title={`You don't have any project yet.`}
          description={
            <>
              Click <b>Add Project</b> to add new project to this presentation
            </>
          }
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? item.withoutPrefix
                    ? tmp + `${item.value}=${item.param}&`
                    : tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? item.withoutPrefix
                    ? tmp + `${item.value}=${item.param}&`
                    : tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp =
                        item.param !== ""
                          ? item.withoutPrefix
                            ? tmp + `${item.value}=${item.param}&`
                            : tmp + `q[${item.value}]=${item.param}&`
                          : tmp;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? item.withoutPrefix
                    ? tmp + `${item.value}=${item.param}&`
                    : tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? item.withoutPrefix
                    ? tmp + `${item.value}=${item.param}&`
                    : tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    const { data, meta, searchParams, onChangeSearchParams, getListAPI } =
      this.props;
    const { sideMenuExpand } = data.sideMenuReducer;

    return (
      <div
        className={`at-table__options mb-10 at-project-table ${
          sideMenuExpand ? "menu-expand" : "menu-shrink"
        }`}
      >
        {searchParams.length > 0 && (
          <div className="mb-10">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() =>
                this.setState({
                  showSearchParams: !this.state.showSearchParams,
                })
              }
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller ">
              <SearchParamsContent
                mode={"desktop"}
                searchParams={searchParams}
                onChangeSearchParams={onChangeSearchParams}
                getListAPI={getListAPI}
              />
            </div>
          </div>
        )}
        <div className="at-table at-table--set">
          {this.renderTableContent()}
        </div>
        {meta && meta.count > 0 && (
          <div className="at-pagination__cont">
            {this.renderTablePagination()}
            {this.renderPaginationText()}
          </div>
        )}
        {this.state.showSearchParams && (
          <SearchParamsContent
            mode={"mobile"}
            searchParams={searchParams}
            onToggleSearchModal={() =>
              this.setState({ showSearchParams: !this.state.showSearchParams })
            }
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(PaginatedTable);
