import React from "react";

import AtlasTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";

import { applicationColumnData } from "../assets";

const Details = ({ totalCPD, selectedApplication }) => {
  return (
    <>
      {selectedApplication.licence_renewal_details.length > 0 && (
        <div className="at-rea-mngm__card-cont">
          <div className="at-form__content grid_gap-2">
            <AtlasIcon
              svgHref={"atlas-clock"}
              style={{ fill: "#FFFFFF", backgroundColor: "#F79009" }}
            />
            <div className="ml-2">
              <label>Accumulated CPD Hours</label>
              <p>{totalCPD}</p>
            </div>
          </div>
        </div>
      )}
      <AtlasTable
        hideSearch={true}
        columns={applicationColumnData}
        emptyStateMessage={`You don't have any event yet`}
        emptyStateDescription={`Click New Events to create event`}
        rowData={selectedApplication.licence_renewal_details || []}
        renderCheckBoxActions={() => (
          <div className="d-flex align-items-center my-3 ml-3 grid_gap-2">
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={"atlas-note-add"}
                style={{ fill: "#EA580C" }}
              />
            </div>
            <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
              Events
            </p>
          </div>
        )}
        actionColumnContent={[
          {
            name: "view-doc",
            disabled: (rowData) => rowData.licence_image.includes("missing"),
            onClick: (rowData) => window.open(rowData.licence_image, "_blank"),
          },
        ]}
      />
    </>
  );
};

export default Details;
