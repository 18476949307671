import React, { Component } from "react";
import { cloneDeep, sortBy, findIndex } from "lodash";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import JSZip from "jszip";
import Moment from "moment";
import _ from "lodash";

import { requestSuccess, requestError } from "utils/requestHandler";
import { Get, Post, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { getItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";
import { countChar } from "utils/charCounter";
import { PreviewPermissions, HeaderData } from "../../assets";

const searchParams = [
  {
    label: "File/folder Name",
    value: "name",
    type: "input",
    param: "",
    col: 12,
    autoFocus: true,
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      searchDirectoryMeta: {},
      searchResult: [],
      parentPath: "",
      searchHeaderData: HeaderData,
      searchIds: ["679"],
      searchDirectoryLayerCount: 1,
      searchPath: "projects-lite/",
      searchTitle: "",
      downloadList: [],
      downloading: false,
      showSearchContent: false,
      showPreviewModal: false,
      showDownloadedFiles: false,
      downloadedFilesCount: 0,
      previewLoading: false,
      selectedSearchFile: {},
      showSearchResultModal: false,
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSearchHOC = (value, context) => {
      this.setState({ [context]: value });
    };

    getFileUrl = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      return `${getDomainURL()}/nextcloud/directories/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${item.path}&download=1`;
    };

    onChangeCheckboxValue = (item) => {
      const tmpList = [...this.state.downloadList];
      const index = _.findIndex(this.state.downloadList, { id: item.id });

      if (index === -1) {
        tmpList.push(item);
      } else {
        tmpList.splice(index, 1);
      }
      this.setState({ downloadList: tmpList });
    };

    downloadMultipleFiles = () => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);

      let tmpDownloadedDocs = _.cloneDeep(this.state.downloadList);

      if (this.state.downloadList && this.state.downloadList.length > 0) {
        let zip = new JSZip();
        this.setState(
          { loading: true, showDownloadedFiles: true },
          async () => {
            for (
              let index = 0;
              index < this.state.downloadList.length;
              index++
            ) {
              await fetch(
                `${getDomainURL()}/nextcloud/directories/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${tmpDownloadedDocs[index].path}&download=1`,
                { headers },
              )
                .then((response) => {
                  return response.blob();
                })
                .then((blobby) => {
                  let tmpDownloadedDocsCount = _.cloneDeep(
                    this.state.downloadedFilesCount,
                  );
                  tmpDownloadedDocs[index] = {
                    ...tmpDownloadedDocs[index],
                    done: true,
                    success: true,
                    errorMessage: "",
                    loading: false,
                  };
                  this.setState({
                    downloadedFilesCount: tmpDownloadedDocsCount + 1,
                    downloadList: tmpDownloadedDocs,
                  });

                  return this.setState({ downloading: false }, async () => {
                    zip.file(tmpDownloadedDocs[index].display_name, blobby);

                    if (index === this.state.downloadList.length - 1) {
                      zip
                        .generateAsync({ type: "blob" })
                        .then((content) => {
                          FileSaver.saveAs(
                            content,
                            `IQI Drive ${Moment().format("DD MM YYYY hh:mm:ss")}`,
                          );
                        })
                        .catch((error) =>
                          requestError(
                            error.response.data.message ||
                              "Failed to zip the files. Please try again later.",
                          ),
                        );
                    }
                  });
                })
                .catch((error) => {
                  let tmpDownloadedDocsCount = _.cloneDeep(
                    this.state.downloadedFilesCount,
                  );
                  let tmpDownloadedDocs = _.cloneDeep(this.state.downloadList);
                  tmpDownloadedDocs[index] = {
                    ...tmpDownloadedDocs[index],
                    done: true,
                    success: false,
                    errorMessage: error.response
                      ? error.response.data.message
                      : "You might be disconnected from the internet because the system is unable to access to the server.",
                    loading: false,
                  };
                  this.setState({
                    downloadedFilesCount: tmpDownloadedDocsCount + 1,
                    downloadList: tmpDownloadedDocs,
                  });
                  if (error && error.response && error.response.status) {
                    if (error.response.status === 401) {
                      if (error.response.data.internal_code === 460) {
                        return requestError(error.response.data.message);
                      }
                    } else {
                      return requestError(
                        error.response.data.message ||
                          "Failed to download file. Please try again later.",
                      );
                    }
                  }
                  this.load(false);
                });
            }
            this.setState({ loading: false });
          },
        );
      }
    };

    refreshDirectory = () => {
      const { searchParams, pageParams } =
        this.props.data.lastViewReducer.lastView;

      if (this.state.searchIds.length > 1) {
        Get(
          `/nextcloud/directories${pageParams}`,
          this.refreshDirectorySuccess,
          (error) => requestError(error),
          this.load,
        );
      } else {
        this.searchDrive(searchParams);
      }
    };
    refreshDirectorySuccess = (payload) => {
      let tmpResult = payload.contents.map((item) => {
        let tmpDisplayName = item.display_name;
        let indexOfDot = tmpDisplayName.lastIndexOf(".");
        let tmpFileType =
          indexOfDot !== -1
            ? (tmpFileType = tmpDisplayName.substr(
                indexOfDot,
                tmpDisplayName.length - 1,
              ))
            : "";

        return {
          ...item,
          last_modified: Moment(item.last_modified)
            .format("DD MMM YYYY")
            .toString(),
          file_type: tmpFileType,
          link: this.getFileUrl(item),
        };
      });

      tmpResult = sortBy(tmpResult, [
        "file_type",
        "resource_type",
        "display_name",
      ]);
      this.setState({
        searchResult: tmpResult,
        parentPath: payload.path,
      });
    };

    onSelectDirectory = (item) =>
      this.setState(
        {
          searchPath: item.path,
          downloadList: [],
        },
        () => {
          const { searchParams, pageParams } =
            this.props.data.lastViewReducer.lastView;
          const tmpPath = `/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${item.path || ""}`;

          const tmpLastView = {
            pageParams: pageParams,
            searchParams: searchParams,
            searchPageParams: tmpPath,
          };
          this.props.storeLastView(tmpLastView);
          Get(
            `/nextcloud/directories${tmpPath}`,
            this.onSelectDirectorySuccess,
            this.onSelectDirectoryError,
            this.load,
          );
        },
      );
    onSelectDirectorySuccess = (payload) => {
      let tmpIds = this.state.searchIds;
      let directoryLayerCount = countChar(payload.path, "/");
      let tmpDirectoryMeta = cloneDeep(payload);
      delete tmpDirectoryMeta.contents;

      if (tmpIds.indexOf(payload.id) === -1) {
        tmpIds.push(payload.id);
      }
      this.setState(
        {
          searchIds: tmpIds,
          searchDirectoryLayerCount: directoryLayerCount,
          searchDirectoryMeta: tmpDirectoryMeta,
        },
        () => {
          let tmpResult = payload.contents.map((item) => {
            let tmpDisplayName = item.display_name;
            let indexOfDot = tmpDisplayName.lastIndexOf(".");
            let tmpFileType =
              indexOfDot !== -1
                ? (tmpDisplayName.substr(indexOfDot, tmpDisplayName.length - 1))
                : "";

            return {
              ...item,
              last_modified: Moment(item.last_modified)
                .format("DD MMM YYYY")
                .toString(),
              file_type: tmpFileType,
              link: this.getFileUrl(item),
            };
          });
          tmpResult = sortBy(tmpResult, [
            "file_type",
            "resource_type",
            "display_name",
          ]);
          this.setState({
            searchResult: tmpResult,
            parentPath: payload.path,
          });
        },
      );
    };
    onSelectDirectoryError = (error) => requestError(error);

    //File Preview
    onSelectFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      this.setState({ previewLoading: true });
      fetch(
        `${getDomainURL()}/nextcloud/directories/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${item.path}&download=1`,
        { headers },
      )
        .then((response) => {
          return response.blob();
        })
        .then((blobby) => {
          let url = URL.createObjectURL(blobby);
          let tmp = {
            ...item,
            url: url,
            link: this.getFileUrl(item),
          };
          this.onChangeSearchHOC(false, "previewLoading");
          this.onChangeSearchHOC(tmp, "selectedSearchFile");
          if (PreviewPermissions.indexOf(item.file_type) === -1) {
            FileSaver.saveAs(blobby, item.display_name);
          } else {
            this.onChangeSearchHOC(true, "showPreviewModal");
          }
        });
    };

    onDownloadFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      return this.setState({ downloading: true }, () => {
        return fetch(
          `${getDomainURL()}/nextcloud/directories/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${item.searchPath}&download=1`,
          { headers },
        )
          .then((response) => {
            return response.blob();
          })
          .then((blobby) => {
            return this.setState({ downloading: false }, () => {
              return FileSaver.saveAs(blobby, item.display_name);
            });
          })
          .catch((error) => {
            requestError("Failed to download file. Please try again later.");
          });
      });
    };

    onPressUpDirectory = () => {
      let tmpIds = this.state.searchIds;
      let tmpPathArray = this.state.searchPath.split("/");
      let tmpPathArrayModified = [];
      tmpPathArray.map((item) => {
        if (item !== "") tmpPathArrayModified.push(item);
      });
      tmpIds.pop();
      tmpPathArrayModified.pop();

      let tmpPath = `${tmpPathArrayModified.join("/")}/`;
      return this.setState(
        {
          searchIds: tmpIds,
          searchPath: tmpPath,
        },
        () => {
          const { searchParams, pageParams } =
            this.props.data.lastViewReducer.lastView;
          if (this.state.searchIds.length <= 1) {
            this.searchDrive(searchParams);
          } else {
            const callPath = `/${this.state.searchIds[this.state.searchIds.length - 1]}?file_path=${this.state.searchIds.length <= 1 ? "projects-lite/" : tmpPath}`;
            const tmpLastView = {
              pageParams: pageParams,
              searchParams: searchParams,
              searchPageParams: callPath,
            };
            this.props.storeLastView(tmpLastView);
            Get(
              `/nextcloud/directories${callPath}`,
              this.onPressUpDirectorySuccess,
              this.onPressUpDirectoryError,
              this.load,
            );
          }
        },
      );
    };
    onPressUpDirectorySuccess = (payload) => {
      let tmpDirectoryMeta = cloneDeep(payload);
      delete tmpDirectoryMeta.contents;

      let tmpResult = payload.contents.map((item) => {
        let tmpDisplayName = item.display_name;
        let indexOfDot = tmpDisplayName.lastIndexOf(".");
        let tmpFileType =
          indexOfDot !== -1
            ? tmpDisplayName.substr(indexOfDot, tmpDisplayName.length - 1)
            : "";
        return {
          ...item,
          last_modified: Moment(item.last_modified)
            .format("DD MMM YYYY")
            .toString(),
          last_modified_unix: Moment(item.last_modified).unix(),
          file_type: tmpFileType,
          link: this.getFileUrl(item),
        };
      });
      tmpResult = sortBy(tmpResult, [
        "file_type",
        "resource_type",
        "display_name",
      ]);

      this.setState({
        searchDirectoryMeta: tmpDirectoryMeta,
        searchResult: tmpResult,
        parentPath: payload.path,
      });
    };
    onPressUpDirectoryError = (error) => requestError(error);

    closeDownloadedFilesList = () => {
      let tmpDownloadList = _.cloneDeep(this.state.downloadList);
      tmpDownloadList = _.filter(tmpDownloadList, (item) => !item.success);

      this.setState({
        downloadList: tmpDownloadList,
        downloadedFilesCount: 0,
        showDownloadedFiles: false,
      });
    };

    copyURLToClipboard = () => {
      this.props.onChangeDriveHOC(
        "Data copied into clipboard. You can now paste the data",
        "snackBarMessage",
      );
      this.props.onChangeDriveHOC(true, "openSnackBar");
    };

    searchDrive = (searchParam, onChange) => {
      this.setState({ showSearchResultModal: true });
      Post(
        `/nextcloud/directories/search?${searchParam}`,
        {},
        (payload) => this.searchDriveSuccess(payload, searchParam, onChange),
        this.searchDriveError,
        this.load,
      );
    };
    searchDriveSuccess = (payload, searchParam, onChange) => {
      let tmpResult = payload.map((item) => {
        let tmpDisplayName = item.display_name;
        let indexOfDot = tmpDisplayName.lastIndexOf(".");
        let tmpFileType;

        if (indexOfDot !== -1) {
          tmpFileType = tmpDisplayName.substr(
            indexOfDot,
            tmpDisplayName.length - 1,
          );
        } else {
          tmpFileType = "";
        }
        onChange && onChange(false);

        return {
          ...item,
          last_modified: Moment(item.last_modified)
            .format("DD MMM YYYY")
            .toString(),
          last_modified_unix: Moment(item.last_modified).unix(),
          file_type: tmpFileType,
          link: this.getFileUrl(item),
        };
      });
      tmpResult = sortBy(tmpResult, [
        "file_type",
        "resource_type",
        "display_name",
      ]);
      this.setState({
        searchResult: tmpResult,
        searchIds: ["679"],
        searchDirectoryLayerCount: 1,
      });

      const { pageParams } = this.props.data.lastViewReducer.lastView;
      let tmpLastView = {
        pageParams: pageParams,
        searchParams: searchParam,
      };
      this.setState({ showSearchContent: false });
      this.props.storeLastView(tmpLastView);
    };
    searchDriveError = (error) => {
      this.setState({ showSearchResultModal: false });
      requestError(error);
    };

    onClickFavourite = (query, id) =>
      Post(
        `/nextcloud/directories/toggle_favourite/?${query}`,
        {},
        (payload) => this.onClickFavouriteSuccess(payload, id),
        this.onClickFavouriteError,
        this.load,
      );
    onClickFavouriteSuccess = (payload, id) => {
      this.props.onChangeDriveHOC(payload.message, "snackBarMessage");
      this.props.onChangeDriveHOC(true, "openSnackBar");

      let tmpContents = cloneDeep(this.state.searchResult);
      let tmpIndex = findIndex(tmpContents, (item) => item.id === id);
      tmpContents[tmpIndex].is_favourite = !tmpContents[tmpIndex].is_favourite;

      this.setState({
        searchResult: tmpContents,
      });
      const { pageParams } = this.props.data.lastViewReducer.lastView;
      this.props.getDirectory(pageParams, requestError);
    };
    onClickFavouriteError = (error) => requestError(error);

    clearDriveCache = (file_path) =>
      Put(
        `/admin/nextcloud/directories/reset_cache`,
        { file_path: file_path },
        this.clearDriveCacheSuccess,
        (error) => requestError(error),
        this.load,
      );
    clearDriveCacheSuccess = (success) => {
      if (success && typeof success.message === "string") {
        requestSuccess(success.message);
      }
      this.refreshDirectory();
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            searchDirectoryMeta={this.state.searchDirectoryMeta}
            searchResult={this.state.searchResult}
            searchHeaderData={this.state.searchHeaderData}
            searchIds={this.state.searchIds}
            searchDirectoryLayerCount={this.state.searchDirectoryLayerCount}
            searchTitle={this.state.searchTitle}
            parentPath={this.state.parentPath}
            downloading={this.state.downloading}
            showPreviewModal={this.state.showPreviewModal}
            previewLoading={this.state.previewLoading}
            selectedSearchFile={this.state.selectedSearchFile}
            showSearchContent={this.state.showSearchContent}
            showSearchResultModal={this.state.showSearchResultModal}
            onLoadSearchDrive={this.state.loading}
            downloadList={this.state.downloadList}
            showDownloadedFiles={this.state.showDownloadedFiles}
            onChangeCheckboxValue={this.onChangeCheckboxValue}
            downloadMultipleFiles={this.downloadMultipleFiles}
            searchDrive={this.searchDrive}
            onClickFavourite={this.onClickFavourite}
            onChangeSearchHOC={this.onChangeSearchHOC}
            onSelectDirectory={this.onSelectDirectory}
            onSelectFile={this.onSelectFile}
            onDownloadFile={this.onDownloadFile}
            onPressUpDirectory={this.onPressUpDirectory}
            copyURLToClipboard={this.copyURLToClipboard}
            clearDriveCache={this.clearDriveCache}
            closeDownloadedFilesList={this.closeDownloadedFilesList}
            requestError={requestError}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(WithHOC);
};

export default HOC;
