import React, { Component } from "react";
import _ from "lodash";

import "./style.scss";

class UnderlineTab extends Component {
  state = {
    sections: [],
  };

  componentDidMount() {
    this.setState({
      sections: this.props.sections,
    });
  }

  onSelectSectionButton(item) {
    this.props.onSelectSection(item);
  }

  renderTabComponent() {
    const { className = "" } = this.props;

    return this.props.sections.map((item) => {
      if (typeof item === "string") {
        return (
          <button
            key={item}
            className={`at-tab_underline__button ${className} ${this.props.selectedSection === item ? "at-tab_underline__selected" : ""}`}
            onClick={() => this.onSelectSectionButton(item)}
          >
            {item}
          </button>
        );
      } else {
        return (
          <button
            key={item.label}
            className={`at-tab_underline__button ${className} ${this.props.selectedSection === item.label ? "at-tab_underline__selected" : ""}`}
            onClick={() => this.onSelectSectionButton(item.label)}
          >
            {item.icon}
            <span>{item.label}</span>
          </button>
        );
      }
    });
  }

  render = () => {
    return (
      <div className="at-tab_underline-cont overflow-auto">
        <div
          className={`at-tab_underline-outline ${this.props.containerClass || ""}`}
          style={this.props.containerStyle || {}}
        >
          {this.renderTabComponent()}
        </div>
      </div>
    );
  };
}

export default UnderlineTab;
