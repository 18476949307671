import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Post } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    exportPending = (type_id) =>
      Post(
        `/admin/order_batches/batch_process`,
        { type_id: type_id },
        this.exportPendingSuccess,
        this.exportPendingError,
        this.load,
      );
    exportPendingSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess(payload.message);
      this.props.onChangeNameCardMngmtHOC(false, "showConfirmation");
      this.props.getBatch(currentPage, searchParams);
    };
    exportPendingError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadExportPending={this.state.loading}
          exportPending={this.exportPending}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
