import React, { useState, useEffect, useRef } from "react";
import _, { cloneDeep } from "lodash";
import ClipboardJS from "clipboard";

import CustomPagination from "components/Pagination";
import AtlasCard from "components/Card";
import TableTopBar from "./topBar";
import TableHead from "./header";
import TableContent from "./content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "./index.scss";
import "./list.scss";
import "./grid.scss";

const IQIDriveTable = ({
  isSearchTable,
  classContainer = "",
  headerData = [],
  ids = "",
  directoryLayerCount = "",
  data = [],
  downloadList,
  title,
  display_name = "",
  parent_path,
  disablePathNavigation,
  clipboardName = "",
  showFavouriteLists,
  showFavouriteFunction,
  showCopyUrlFunction,
  showFavouriteListsFunction,
  showResetCacheFunction,

  onChangeCheckboxValue,
  onChangeHOC,
  onPressUpDirectory,
  onClickFavourite,
  onSelectDirectory,
  onSelectFile,
  copyURLToClipboard,
  clearDriveCache,
  getAllFavourites,
  downloadMultipleFiles,
}) => {
  const tableContRef = useRef(null);
  const [checkableItems, onChangeCheckableItems] = useState([]);
  const [layout, setLayout] = useState("List"); // 1. Grid 2. List
  const [paginationMetaData, setTablePagination] = useState({
    selectPerPageOption: 120,
    totalPages: [],
    clickPageNumber: 0,
    headerRearrangeData: {},
    rowData: [],
  });
  const [sortingMode, onChangeSortMode] = useState("none");
  const [sortingName, onChangeSortName] = useState("none");
  const [path, setPath] = useState([]);

  let totalIndex = 0;

  const { rowData, selectPerPageOption, totalPages, clickPageNumber } =
    paginationMetaData;

  useEffect(() => {
    let copyClipboard = new ClipboardJS(
      `.${clipboardName || "copy_url-clipboard"}`,
    );

    copyClipboard.on("success", function (e) {
      e &&
        e.text &&
        navigator &&
        navigator.clipboard &&
        navigator.clipboard.writeText(e.text);
    });
    copyClipboard.on("error", function (e) {
      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);
    });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      processPagesData();

      onChangeCheckableItems(
        data
          .filter((item) => item.resource_type === "file")
          .map((item) => ({
            ...item,
            done: false,
            success: false,
            errorMessage: "",
            loading: true,
          })),
      );
    }
  }, [data]);

  const scroll2Top = () => {
    if (tableContRef.current) {
      tableContRef.current.scrollTop = 0;
    }
  };

  const onToggleBack = () => {
    let tmpPath = _.cloneDeep(path);
    tmpPath.pop();
    setPath(tmpPath);
    onPressUpDirectory();
  };

  const processPagesData = () => {
    let tmpObject = cloneDeep(paginationMetaData);
    let tmpData = _.sortBy(data, ["resource_type"]);

    tmpObject = {
      ...tmpObject,
      rowData: _.cloneDeep(tmpData),
      initialRowData: _.cloneDeep(data),
    };

    if (tmpObject.rowData) {
      let paginationSections =
        tmpObject.rowData.length / tmpObject.selectPerPageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);

      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }

      return setTablePagination({
        ...tmpObject,
        totalPages: pagesArrayData,
      });
    }
  };

  const onSortModifiedData = () => {
    let mode =
      sortingMode === "none" ? "asc" : sortingMode === "asc" ? "desc" : "none";

    let tempRow = _.cloneDeep(paginationMetaData.initialRowData);

    tempRow =
      mode !== "none"
        ? _.orderBy(tempRow, ["last_modified_unix"], mode)
        : tempRow;

    setTablePagination((prev) => ({
      ...prev,
      rowData: tempRow,
    }));
    onChangeSortMode(mode);
    onChangeSortName("none");
  };

  const onSortName = () => {
    let mode =
      sortingName === "none" ? "asc" : sortingName === "asc" ? "desc" : "none";

    let tempRow = _.cloneDeep(paginationMetaData.initialRowData);
    tempRow =
      mode !== "none" ? _.orderBy(tempRow, ["display_name"], mode) : tempRow;

    setTablePagination((prev) => ({
      ...prev,
      rowData: tempRow,
    }));
    onChangeSortName(mode);
    onChangeSortMode("none");
  };

  const renderTableTopBar = () => {
    return (
      <TableTopBar
        layout={layout}
        title={title}
        path={path}
        setPath={setPath}
        parent_path={parent_path}
        sortingMode={sortingMode}
        sortingName={sortingName}
        downloadList={downloadList}
        checkableItems={checkableItems}
        onSortModifiedData={onSortModifiedData}
        onSortName={onSortName}
        directoryLayerCount={directoryLayerCount}
        disablePathNavigation={disablePathNavigation}
        showFavouriteLists={showFavouriteLists}
        showFavouriteListsFunction={showFavouriteListsFunction}
        showResetCacheFunction={showResetCacheFunction}
        setLayout={setLayout}
        clearDriveCache={clearDriveCache}
        onPressUpDirectory={onToggleBack}
        getAllFavourites={getAllFavourites}
        downloadMultipleFiles={downloadMultipleFiles}
        onChangeHOC={onChangeHOC}
      />
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead
        headerData={headerData}
        sortingMode={sortingMode}
        sortingName={sortingName}
        downloadList={downloadList}
        checkableItems={checkableItems}
        onSortModifiedData={onSortModifiedData}
        onSortName={onSortName}
        showCopyUrlFunction={showCopyUrlFunction}
        showFavouriteFunction={showFavouriteFunction}
        onChangeHOC={onChangeHOC}
      />
    );
  };

  const renderTableContent = () => {
    return (
      <div ref={tableContRef} className="at-drive_table-content_cont">
        {rowData &&
          rowData.length > 0 &&
          rowData.map((item, index) => {
            if (item.resource_type === "collection") {
              return (
                <TableContent
                  key={`content-${totalIndex++}`}
                  index={totalIndex}
                  type={"directory"}
                  title={item.display_name}
                  last_modified={item.last_modified}
                  is_favourite={item.is_favourite}
                  fileSize={parseInt(item.size)}
                  metadata={item}
                  downloadList={downloadList}
                  clipboardURL={{
                    ids: ids,
                    path: `/${ids[ids.length - 1]}?file_path=${item.path}`,
                  }}
                  clipboardName={clipboardName}
                  showCopyUrlFunction={showCopyUrlFunction}
                  showFavouriteFunction={showFavouriteFunction}
                  showResetCacheFunction={showResetCacheFunction}
                  onChangeCheckboxValue={onChangeCheckboxValue}
                  onClickContent={() => {
                    scroll2Top();
                    setPath([...path, item.display_name]);
                    onSelectDirectory(item);
                  }}
                  onClickCopy={() => copyURLToClipboard()}
                  clearDriveCache={clearDriveCache}
                  onClickFavourite={onClickFavourite}
                />
              );
            }
          })}
        {rowData &&
          rowData.length > 0 &&
          rowData.map((item, index) => {
            if (item.content_type || item.resource_type === "file") {
              return (
                <TableContent
                  key={`content-${totalIndex++}`}
                  index={totalIndex}
                  type={"file"}
                  title={item.display_name}
                  last_modified={item.last_modified}
                  is_favourite={item.is_favourite}
                  fileSize={parseInt(item.size)}
                  downloadList={downloadList}
                  metadata={{
                    ...item,
                    done: false,
                    success: false,
                    errorMessage: "",
                    loading: true,
                  }}
                  clipboardURL={{
                    ids: ids,
                    ...item,
                  }}
                  clipboardName={clipboardName}
                  showCopyUrlFunction={showCopyUrlFunction}
                  showFavouriteFunction={showFavouriteFunction}
                  showResetCacheFunction={
                    showResetCacheFunction &&
                    item.resource_type === "collection"
                  }
                  onChangeCheckboxValue={onChangeCheckboxValue}
                  onClickContent={() => onSelectFile(item)}
                  onClickCopy={() => copyURLToClipboard()}
                  onClickFavourite={onClickFavourite}
                />
              );
            }
          })}
      </div>
    );
  };

  return (
    <AtlasCard
      className={`${layout === "List" ? "at-drive-table_list" : "at-drive-table_grid"} ${classContainer}`}
      containerStyle={{ overflow: "none" }}
      cardContent={
        <>
          {renderTableTopBar()}
          {layout === "List" && renderTableHead()}
          {data.length > 0 && renderTableContent()}
          {data && data.length < 1 && (
            <>
              {isSearchTable && (
                <div className="at-empty-state">
                  <ul className="at-table-row grid-controller">
                    <li className="at-table-row__item at-table-row__item-empty">
                      <p>No result found</p>
                      <span>
                        Try to adjust your search or filter options to find
                        something you're looking for
                      </span>
                      <br />
                    </li>
                  </ul>
                </div>
              )}
              {!isSearchTable && (
                <EmptyState
                  title={`You don't have any documents`}
                  renderIcon={<AtlasIcon svgHref="atlas-drive" />}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default IQIDriveTable;
