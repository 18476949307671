import _ from "lodash";
import React, { useEffect, useState } from "react";

import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import StepIndicator from "components/StepIndicator";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import PaginatedTable from "components/PaginatedProfileCardTable";
import SearchProjectName from "../components/Table/searchProjectName";

import { percentageGuideline, counterInstruction } from "../assets";
import SendCobrokeReqeuestIcon from "assets/images/send_cobroke_request.svg";
import ProjectPartnerHOC, { SearchParams } from "../actions/projectPartner";

const headerData = [
  {
    label: "Full Name",
    value: "full_name",
  },
  {
    label: "Display Name",
    value: "display_name",
  },
  {
    label: "Team",
    value: "team_name",
    icon: "atlas-profile2user",
  },
  {
    label: "Branch",
    value: "branch_region_name",
    icon: "atlas-location",
  },
  {
    label: "Sold Unit",
    value: "total_units_sold",
    icon: "atlas-house-2",
  },
];

const ProjectPartnerContent = ({
  data,
  projectList,
  branchRegion,
  searchParams,
  selectedAgent,
  onLoadProject,
  projectPartner,
  selectedProject,
  onLoadProjectPartner,
  showRequestConfirmation,

  getBranchRegions,
  getProjectPartner,
  getProjectListing,
  getAllDictionaryData,
  onChangeProjectPartnerHOC,
  createProjectPartnerRequest,
}) => {
  const [showInfoBox, onToggleInfoBox] = useState(false);
  const [projectNameModal, setProjectNameModal] = useState(false);

  useEffect(() => {
    getBranchRegions();
    getProjectListing();
    getAllDictionaryData();
    if (selectedProject) {
      getProjectPartner("", `q[project_id]=${selectedProject.id}`);
    } else {
      getProjectPartner(1, "");
    }
  }, []);

  useEffect(() => {
    let temp = SearchParams({
      teamList: data.dictionaryReducer.team,
      branchRegion: branchRegion,
      initialSearchParams: searchParams,
      setProjectNameModal: setProjectNameModal,
    });

    if (selectedProject) {
      temp[0].param = selectedProject.internal_name;
    }

    onChangeProjectPartnerHOC(temp, "searchParams");
  }, [branchRegion]);

  return (
    <>
      {selectedProject && (
        <div className="at-cobroke__project-partner-instruction mb-3">
          <h4 style={{ fontSize: 18, fontWeight: 600 }}>
            Project Partner for {selectedProject.internal_name}
          </h4>
          <p className="mt-10">
            With Atlas's Project Partner you can unlock the value within your
            network of contacts and earn more commission from property sales. If
            you are a secondary market expert or not yet an expert on primary
            project (new build) sales, partner with one or more top performers
            from primary sales and work with them to sell new projects them -
            and earn commission based on your co-broked contribution.
          </p>
          <div className="at-form__content mt-20">
            <p className="fw-500">How it works?</p>
            <StepIndicator
              className={"mt-3"}
              enableProgressive={true}
              stepList={counterInstruction}
              onClick={() => {}}
            />
          </div>
          <CustomInfobox
            expand={showInfoBox}
            inactiveContent={"Sales Process and Co-broke Commission"}
            activeContent={
              <>
                Sales Process and Co-broke Commission
                <p style={{ fontWeight: 500, marginTop: 20 }}>
                  You and your project partner will agree on the commission
                  split, but by default the split will be as follows:
                </p>
                <div className="d-flex justify-content-around my-2">
                  {percentageGuideline.map((item, index) => (
                    <div
                      key={`percent-guideline-${index}`}
                      className={"at-project-partner__commision-percent"}
                    >
                      <p>{item.percent}</p>
                      {item.label}
                    </div>
                  ))}
                </div>
                <label className="mt-2">Get Started</label>
                <p style={{ fontWeight: 500 }}>
                  1. Search for a Project Partner using the fields below. Atlas
                  will show you the top selling agents for your choice of
                  project, team and/or area. It's best to find agents who are
                  specialists in the project or area you think your contact will
                  buy.
                  <br />
                  2. Choose an agent and send arequest. You wil need to agree to
                  the terms of the co-broke agreement.
                </p>
              </>
            }
            onClickExpandInfo={(val) => onToggleInfoBox(val)}
          />
        </div>
      )}
      <PaginatedTable
        headerData={headerData}
        rowData={projectPartner.result || []}
        searchParams={searchParams}
        display_message={projectPartner.display_message}
        onClickSendRequest={(val) =>
          Promise.all([onChangeProjectPartnerHOC(val, "selectedAgent")]).then(
            () => {
              onChangeProjectPartnerHOC(true, "showRequestConfirmation");
            },
          )
        }
        onChangeSearchParams={(val) =>
          onChangeProjectPartnerHOC(val, "searchParams")
        }
        getListAPI={getProjectPartner}
        renderAdditionalContent={
          <div className="d-flex align-items-center grid_gap-2 mb-3 fs-4 fw-600">
            <div className="at-badge-icon at-badge-icon-warning">
              <AtlasIcon svgHref={"atlas-cobroke-centre"} />
            </div>
            Results
          </div>
        }
      />
      {projectPartner.suggested_partners &&
        projectPartner.suggested_partners.length > 0 && (
          <>
            <div className="d-flex align-items-center my-3 fs-4 fw-600">
              <div className="at-badge-icon at-badge-icon-warning">
                <AtlasIcon svgHref={"atlas-cobroke-centre"} />
              </div>
              Suggested Result
            </div>
            <PaginatedTable
              headerData={headerData}
              rowData={projectPartner.suggested_partners}
              onClickSendRequest={(val) =>
                Promise.all([
                  onChangeProjectPartnerHOC(val, "selectedAgent"),
                ]).then(() => {
                  onChangeProjectPartnerHOC(true, "showRequestConfirmation");
                })
              }
            />
          </>
        )}
      {projectNameModal && (
        <SearchProjectName
          projectList={projectList}
          searchParams={searchParams}
          onLoadProject={onLoadProject}
          getProjectListing={getProjectListing}
          onClose={() => setProjectNameModal(false)}
          onChangeSearchParams={(val) =>
            onChangeProjectPartnerHOC(val, "searchParams")
          }
        />
      )}
      <ConfirmationModal
        open={showRequestConfirmation}
        title={true}
        hideIcon={true}
        positiveText={"Send Request"}
        positiveAction={() =>
          createProjectPartnerRequest(
            selectedProject
              ? selectedProject.id
              : selectedAgent
                ? selectedAgent.atlas_project_id
                : "",
          )
        }
        negativeText={"Cancel"}
        negativeAction={() =>
          onChangeProjectPartnerHOC(false, "showRequestConfirmation")
        }
        message={
          <div className="fs-2 text-center">
            <div className="d-flex justify-content-center mb-3">
              <img
                src={SendCobrokeReqeuestIcon}
                alt={"send_cobroke_reuest_icon"}
              />
            </div>
            <p className="fs-4 fw-600" style={{ marginBottom: 8 }}>
              Send Request to Project Partner
            </p>
            You will send a request to {selectedAgent.full_name} asking them to
            be a Project Partner. <br />
            By sending this request, you agree to co-broke with the Project
            Partner in the event of a successful sale.
          </div>
        }
      />
      {onLoadProjectPartner && <LoadingModal />}
    </>
  );
};

export default ProjectPartnerHOC(ProjectPartnerContent);
