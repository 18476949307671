import React, { Component } from "react";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";
import { compose } from "redux";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import EditListing from "./Edit";
import EstateDetails from "./Details";
import AtPaginatedTable from "../ListingTable";

import permissionsChecker from "utils/permissionsChecker";
import EstateDetailsHOC from "./actions/details";
import ListingHOC from "./actions/index";

class ListingContent extends Component {
  componentDidMount = () =>
    this.props.getListings(this.props.selectedPresentation.id, 1, "");

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.estatetListingIndexSearchParams);
    let countryIndex = _.findIndex(temp, {
      value: "estate_of_Listing_type_country_id_eq",
    });
    let stateIndex = _.findIndex(temp, {
      value: "estate_of_Listing_type_state_id_eq",
    });
    if (
      pp.estatetListingIndexSearchParams[countryIndex] &&
      temp[countryIndex] &&
      pp.estatetListingIndexSearchParams[countryIndex].param !==
        temp[countryIndex].param
    ) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: temp[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      this.props.onChangeListingHOC(temp, "estatetListingIndexSearchParams");
    }
  };

  render = () => {
    const {
      data,
      listings,
      listingPages,
      showListingEditModal,
      showEstateDetailsModal,
      showListingRemoveConfirmation,
      selectedPresentation,
      selectedListing,
      estateListingIndexSearch,

      selectedEstateDetails,

      onLoadListing,
      onLoadPresentation,
      onLoadEstateDetails,
    } = this.props;
    const {
      currentPage: estateListingPage = 1,
      searchParams: estateListingSearchParams = "",
    } = estateListingIndexSearch;

    const { can_create, can_destroy } = permissionsChecker(
      "Presentation Centre",
      data,
    );

    return (
      <>
        <div className={"d-flex align-items-center flex-wrap grid_gap-1"}>
          <ModuleFormHeader
            title={"Listing Management"}
            moduleIcon={"atlas-building-3"}
          />
          {can_create && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              onClick={() =>
                this.props.onChangeListingHOC(true, "showListingEditModal")
              }
              children={
                <>
                  <IoMdAdd
                    style={{
                      height: 18,
                      width: 18,
                      color: "#FFFF",
                    }}
                  />
                  Add Listing
                </>
              }
            />
          )}
        </div>
        <p className="fs-2 mt-10">
          These are the listings that will be shows as part of this
          presentation. You can add your own listings or any listings that other
          agents have added in Atlas. Use the add and remove buttons to add a
          new listing or remove one from your presentation. Click on the info
          icon to have a quick look at the listing.
        </p>
        <div className="at-heading_card_table">
          <AtPaginatedTable
            rowData={listings.data || []}
            meta={listings.meta && listings.meta}
            onClickDetails={(val) =>
              this.props.getSelectedEstate(
                val.estate_id,
                this.props.selectedPresentation.id,
              )
            }
            searchParams={[]}
            actionTypes={can_destroy ? ["remove"] : []}
            onChangeSearchParams={(val) =>
              this.props.onChangeListingHOC(
                val,
                "estatetListingIndexSearchParams",
              )
            }
            onClickRemove={(val) => {
              Promise.all([
                this.props.onChangeListingHOC(val, "selectedListing"),
              ]).then();
              this.props.onChangeListingHOC(
                true,
                "showListingRemoveConfirmation",
              );
            }}
            getListAPI={(page, search) =>
              this.props.getListings(selectedPresentation.id, page, search)
            }
            totalPages={listingPages}
          />
        </div>
        {showListingEditModal && (
          <ModalDialog
            title={"Co-Broke Centre"}
            onLoad={onLoadPresentation || onLoadListing}
            fullWidth={true}
            fullHeight={true}
            children={
              <EditListing
                selectedPresentation={selectedPresentation}
                onLoadPresentation={onLoadPresentation}
                onLoadListing={onLoadListing}
                getListings={() =>
                  this.props.getListings(
                    this.props.selectedPresentation.id,
                    estateListingPage,
                    estateListingSearchParams,
                  )
                }
                getSelectedPresentation={this.props.getSelectedPresentation}
                getPresentation={this.props.getPresentation}
                removeListing={this.props.removeListing}
              />
            }
            onClose={() =>
              this.props.onChangeListingHOC(false, "showListingEditModal")
            }
          />
        )}
        {showEstateDetailsModal && (
          <ModalDialog
            title={"Listing Details"}
            onLoad={onLoadPresentation || onLoadListing}
            fullWidth={true}
            fullHeight={true}
            children={
              <EstateDetails selectedEstateDetails={selectedEstateDetails} />
            }
            onClose={() =>
              this.props.onChangeEstateDetailsHOC(
                false,
                "showEstateDetailsModal",
              )
            }
          />
        )}
        <AtlasConfirmationDialog
          open={showListingRemoveConfirmation}
          loading={onLoadListing}
          mode={"alert"}
          message={"Are you sure to delete the selected listing ?"}
          positiveAction={() =>
            this.props.removeListing(
              selectedPresentation.id,
              selectedListing.estate_id,
            )
          }
          negativeAction={() =>
            this.props.onChangeListingHOC(
              false,
              "showListingRemoveConfirmation",
            )
          }
        />
        {(onLoadListing || onLoadEstateDetails) && <LoadingModal />}
      </>
    );
  };
}

export default compose(EstateDetailsHOC, ListingHOC)(ListingContent);
