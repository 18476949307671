import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import CustomForm from "components/Form";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from "components/RadioGroup";
import SectionBookingDetails from "../ClaimFormSections/bookingDetails";
import PropertyInfo from "../ClaimFormSections/propertyInfo";
import SectionAgencyFee from "../ClaimFormSections/agencyFee";
import SectionStampingFee from "../ClaimFormSections/stampingFee";
import permissionsChecker from "utils/permissionsChecker";

import defaultProfile from "assets/images/av-male.jpg";
import "./index.scss";

/* 
contact_type_id:
  OWNER  = 1
  CLIENT = 2
  OWNER_LAWYER = 3
  BUYER_LAWYER = 4
*/
/* 
type_id:
  OWNERS      = 1
  CLIENTS     = 2
  AGENTS      = 3
  REFERRALS   = 4
*/
const subsalesType = [
  {
    id: 1,
    value: 1,
    name: "Sale",
    label: "Sale",
  },
  {
    id: 2,
    value: 2,
    name: "Rental",
    label: "Rental",
  },
];

const SubsaleClaimForm = ({
  data,
  menuExpand,
  storedAddresses,
  selected_claim,
  unsavedChanges,
  showTransferModal,
  onLoadSubsaleClaims,
  autoSuggestOptions,
  autoSuggestLoading,
  townshipsList,

  getSelectedTownShip,
  transferConfirmation,
  setShowExternalAgent,
  onClickNav,
  onChangeUpdateSelectedClaim,
  updateSelectedClaim,
  onChangeClaimsHOC,
  edgeAutoSuggest,
  onCloseForm,
}) => {
  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const { can_update } = permissionsChecker("Subsales Claims", data);

  const { user = {}, is_main_info_locked } = selected_claim;

  return (
    <>
      <CustomForm
        formSection={[
          {
            formIcon: <AtlasIcon svgHref={"atlas-profile2user"} />,
            formTitle: "Submitting Agent",
            formContent: (
              <div
                className="row profile-card at-subsales_claim-form"
                style={{ maxWidth: "100%", border: "none" }}
              >
                <div
                  className="col-lg-5 col-12 mb-10 p-0"
                  style={{ textAlign: "center" }}
                >
                  <img
                    alt={`Cobroker`}
                    src={
                      user.avatar_url &&
                      !_.includes(user.avatar_url, "missing.png")
                        ? user.avatar_url
                        : defaultProfile
                    }
                    style={{
                      borderRadius: "50%",
                      width: 140,
                      height: 140,
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className={"col-lg-7 col-12 mt-10"}>
                  <p className="fs-3 fw-600">{user.full_name}</p>
                  <p>{user.email}</p>
                  <p>{user.mobile_contact_number}</p>
                  <p>{user.team_name ? `${user.team_name} team` : ""}</p>
                  <p
                    className="at-status_badge"
                    style={{
                      backgroundColor:
                        user.status === "Active" ? "#D1FADF" : "#FEE4E2",
                      color: user.status === "Active" ? "#027A48" : "#B42318",
                    }}
                  >
                    {user.status}
                  </p>
                </div>
              </div>
            ),
          },
          {
            formIcon: <AtlasIcon svgHref={"atlas-calendar-ranged"} />,
            formTitle: "Booking Details",
            formContent: (
              <>
                <div className="at-form_fields_cont mb-3">
                  <div className="at-form_field-col-6">
                    <h2 className="at-form-input__title">
                      {"Transaction Type"}
                    </h2>
                    <i>
                      {selected_claim.sub_sale_type_id === 1
                        ? "Sub-Sale"
                        : "Sub-Rent"}
                    </i>
                  </div>
                </div>
                <SectionBookingDetails
                  permissionsChecker={permissionsChecker(
                    "Subsales Claims",
                    data,
                  )}
                  selected_claim={selected_claim}
                  storedAddresses={storedAddresses}
                  showTransferModal={showTransferModal}
                  onLoadSubsaleClaims={onLoadSubsaleClaims}
                  onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                  transferConfirmation={transferConfirmation}
                  onChangeClaimsHOC={onChangeClaimsHOC}
                />
              </>
            ),
          },
          {
            formIcon: <AtlasIcon svgHref="atlas-landed-building" />,
            formTitle: "Property Info",
            formContent: (
              <PropertyInfo
                data={data}
                autoSuggestLoading={autoSuggestLoading}
                disableSubmitButton={!can_update || is_main_info_locked}
                loading={onLoadSubsaleClaims}
                onChange={onChangeUpdateSelectedClaim}
                formData={selected_claim}
                townshipsList={townshipsList}
                getSelectedTownShip={getSelectedTownShip}
                autoSuggestOptions={autoSuggestOptions}
                edgeAutoSuggest={edgeAutoSuggest}
                onClickCloseForm={() =>
                  onChangeClaimsHOC("showUpdateClaimFormModal", false)
                }
              />
            ),
          },
          {
            formIcon: <AtlasIcon svgHref={"atlas-coin-1"} />,
            formTitle: "SST",
            formContent: (
              <>
                <h2 className="at-form-input__title mb-2">Select the relevant percentage to change the SST applied to this case</h2>
                <CustomRadioGroup
                  horizontal={true}
                  checkedValue={selected_claim.sales_tax_percentage}
                  options={[
                    { value: 6, label: "6%" },
                    { value: 8, label: "8%" },
                  ]}
                  selectedRadioValue={(val) => onChangeUpdateSelectedClaim('sales_tax_percentage', val) } />
              </>
            )
          },
          {
            formIcon: <AtlasIcon svgHref={"atlas-percentage-square"} />,
            formTitle: "Agency Fee",
            formContent: (
              <SectionAgencyFee
                permissionsChecker={permissionsChecker("Subsales Claims", data)}
                selected_claim={selected_claim}
                setShowExternalAgent={setShowExternalAgent}
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
              />
            ),
          },
          {
            formIcon: <AtlasIcon svgHref={"atlas-receipt-disscount"} />,
            formTitle: "Tenancy Agreement & Stamping Fee",
            formShow: () => selected_claim.sub_sale_type_id === 2,
            formContent: (
              <SectionStampingFee
                permissionsChecker={permissionsChecker("Subsales Claims", data)}
                selected_claim={selected_claim}
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
              />
            )
          }
        ]}
        alertMessage={
          <>
            {unsavedChanges && (
              <h2 className="at-form-input__title text-danger">
                You have unsaved changes. Please click save button to save the
                changes.
              </h2>
            )}
            {!can_update && (
              <h2 className="at-form-input__title" style={{ color: "red" }}>
                You do not have the access right to edit this claim
              </h2>
            )}
          </>
        }
      />
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        <button
          className="btn-new btn-new--success"
          disabled={!can_update}
          onClick={() => updateSelectedClaim(selected_claim.id, selected_claim, true)}>
          Save Information
        </button>
        <button
          className="btn-new btn-new--secondary at-btn_next"
          onClick={() => onClickNav("Participants")}>
          <p>Participants</p>
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onCloseForm()}>
          Close
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(SubsaleClaimForm);
