import { Component } from 'react';
import { connect } from 'react-redux';

import { getCountryDictionary } from 'actions/dictionary';
import { storeLastView } from 'actions/lastView';

import { searchParams } from '../assets';

import { Delete, Get, Post, Put } from 'utils/axios';
import { requestError, requestSuccess } from 'utils/requestHandler';

const withVideoTemplates = WrappedComponent => {
  class VideoTemplatesHOC extends Component {
    state = {
      onLoadVideoTemplates: false,
      onLoadProjectList: false,

      searchParams: searchParams(
        this.props?.data?.dictionaryReducer?.countries
      ),
      selectedProject: {},
      selectedVideoTemplate: {},
      selectedId: '',
      projectList: [],
      videoTemplates: {},
      videoTemplatePages: [],

      showCreateVideoTemplate: false,
      showUpdateVideoTemplate: false,
      showDeleteVideoTemplate: false
    };

    load = param => this.setState({ onLoadVideoTemplates: param });
    projectLoad = param => this.setState({ onLoadProjectList: param });

    onChangeVideoTemplatesHOC = (key, val) => this.setState({ [key]: val });

    getVideoTemplates = (pageNumber = 1, queryString = '') => {
      this.props.storeLastView({
        currentPage: pageNumber,
        searchParams: queryString
      });
      Get(
        `/admin/video_templates?${queryString}page=${pageNumber}`,
        this.getVideoTemplatesSuccess,
        this.getVideoTemplatesError,
        this.load
      );
    };
    getVideoTemplatesSuccess = payload => {
      const totalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        totalPages.push(i);
      }

      this.setState({
        videoTemplates: payload,
        videoTemplatePages: totalPages
      });
    };
    getVideoTemplatesError = error => requestError(error);

    getSelectedVideoTemplate = id => {
      Get(
        `/admin/video_templates/${id}`,
        this.getSelectedVideoTemplateSuccess,
        this.getSelectedVideoTemplateError,
        this.load
      );
    };
    getSelectedVideoTemplateSuccess = payload => {
      this.setState({
        selectedVideoTemplate: payload,
        showUpdateVideoTemplate: true
      });
    };
    getSelectedVideoTemplateError = error => requestError(error);

    getProjectListing = project => {
      Get(
        `/projects/active_project_listings?q[internal_name_cont]=${project}&q[is_visible_on_web_eq]=true&is_searching=1`,
        this.getProjectListingSuccess,
        this.getProjectListingError,
        this.projectLoad
      );
    };
    getProjectListingSuccess = payload => {
      this.setState({ projectList: payload.projects });
    };
    getProjectListingError = error => requestError(error);

    getSelectedProject = id => {
      Get(
        `/projects/${id}`,
        this.getSelectedProjectSuccess,
        this.getSelectedProjectError,
        this.load
      );
    };
    getSelectedProjectSuccess = payload => {
      this.setState({ selectedProject: payload });
    };
    getSelectedProjectError = error => requestError(error);

    createVideoTemplate = data => {
      const formData = new FormData();

      Object.entries(data).forEach(([key, val]) => formData.append(key, val));

      Post(
        `/admin/video_templates`,
        formData,
        this.createVideoTemplateSuccess,
        this.createVideoTemplateError,
        this.load
      );
    };
    createVideoTemplateSuccess = () => {
      const { currentPage, searchParams } = this.props.data?.lastViewReducer?.lastView;
      requestSuccess('Video template was created successfully!');
      this.getVideoTemplates(currentPage, searchParams);
      this.setState({
        showCreateVideoTemplate: false,
        projectList: [],
        selectedProject: {}
      });
    };
    createVideoTemplateError = error => requestError(error);

    updateVideoTemplate = data => {
      Put(
        `/admin/video_templates/${data.id}`,
        data,
        this.updateVideoTemplateSuccess,
        this.updateVideoTemplateError,
        this.load
      );
    };
    updateVideoTemplateSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data?.lastViewReducer?.lastView;
      requestSuccess('Video template was updated successfully!');
      this.getVideoTemplates(currentPage, searchParams);
      this.setState({
        showUpdateVideoTemplate: false,
        projectList: [],
        selectedProject: {}
      });
    };
    updateVideoTemplateError = error => requestError(error);

    deleteVideoTemplate = id =>
      Delete(
        `/admin/video_templates/${id}`,
        this.deleteVideoTemplateSuccess,
        this.deleteVideoTemplateError,
        this.load
      );
    deleteVideoTemplateSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data?.lastViewReducer?.lastView;
      requestSuccess('Video template was deleted successfully!');
      this.getVideoTemplates(currentPage, searchParams);
      this.setState({ showDeleteVideoTemplate: false });
    };
    deleteVideoTemplateError = error => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeVideoTemplatesHOC={this.onChangeVideoTemplatesHOC}
          getVideoTemplates={this.getVideoTemplates}
          getSelectedVideoTemplate={this.getSelectedVideoTemplate}
          getProjectListing={this.getProjectListing}
          getSelectedProject={this.getSelectedProject}
          createVideoTemplate={this.createVideoTemplate}
          updateVideoTemplate={this.updateVideoTemplate}
          deleteVideoTemplate={this.deleteVideoTemplate}
        />
      );
    };
  }

  const mapStateToProps = state => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary
  })(VideoTemplatesHOC);
};

export default withVideoTemplates;
