import React from "react";

import "../index.scss";

const progressBar = ({ totalValue, data }) => {
  return (
    <div style={{ width: "100%", textAlign: "-webkit-center" }}>
      <div className="at-sale-progress-bar">
        <div
          style={{
            width: "100%",
            borderRadius: 25,
            background: data.backgroundColor,
            display: "flex",
            overflow: "hidden",
          }}
          data-for={`tooltip-id-${data.id}`}
        >
          <div
            style={{
              width:
                data.value > 0
                  ? `calc(100%/${Number(totalValue * 10)}*${Number(data.value * 10)})`
                  : "0px",
              background: data.barColor,
              content: "",
              height: 10,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default progressBar;
