import React from "react";
import _ from "lodash";
import { BsTrophyFill } from "react-icons/bs";

import CustomTab from "components/Tab";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import GeneralDataContent from "../../Statistic/components/generalDataContent";
import ProjectSalesContent from "../../Statistic/components/projectSalesContent";
import SubsalesContent from "../../Statistic/components/subsalesContent";
import projectSaleRank from "assets/images/projectSaleRank.png";
import subsalesSaleRank from "assets/images/subsalesSaleRank.png";
import subsalesRentalRank from "assets/images/subsalesRentalRank.png";
import defaultAvatar from "assets/images/av-male.jpg";

import { numberWithCommas } from "utils/thousandSeparator";

const TeamViewSummary = ({
  data,
  agentSalesSummary,
  teamViewSummary,
  leaderBoardData,
  onChangeMysalesHOC,
  openImage,
}) => {
  const { id, large_avatar_url } = agentSalesSummary;

  const renderAgentDetails = (gridArea) => (
    <div style={{ gridArea: gridArea }} className="at-form__content">
      <div className=" d-flex align-items-center">
        <img
          style={{
            width: 56,
            height: 56,
            borderRadius: "50%",
            objectFit: "contain",
            cursor: "pointer",
          }}
          onClick={() => openImage()}
          src={
            large_avatar_url && !_.includes(large_avatar_url, "missing.png")
              ? large_avatar_url
              : defaultAvatar
          }
        />
        <div className="ml-3">
          <div className="fw-600" style={{ fontSize: 16 }}>
            {agentSalesSummary.accountName || "N/A"}
          </div>
          <div className="fs-2">
            <AtlasIcon
              svgHref={"atlas-sms"}
              style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 8 }}
            />
            {agentSalesSummary.email || "N/A"}
          </div>
          <div className="fs-2">
            <AtlasIcon
              svgHref={"atlas-call"}
              style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 8 }}
            />
            {agentSalesSummary.mobilePhone || "N/A"}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <CustomButton
          className={"btn-new btn-new--outline-secondary w-100"}
          containerStyle={{ width: "32%" }}
          disabled={!agentSalesSummary.email}
          onClick={() => window.open(`mailto:${agentSalesSummary.email}`)}
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-sms-edit"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#3B82F6",
                  marginRight: 8,
                }}
              />
              Email
            </>
          }
        />
        <CustomButton
          className={"btn-new btn-new--outline-secondary w-100"}
          containerStyle={{ width: "32%" }}
          disabled={!agentSalesSummary.mobilePhone}
          onClick={() =>
            window.open(
              `https://wa.me/${agentSalesSummary.mobilePhone}`,
              "_blank",
            )
          }
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-whatsapp"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#12B76A",
                  marginRight: 8,
                }}
              />
              Message
            </>
          }
        />
        <CustomButton
          className={"btn-new btn-new--outline-secondary w-100"}
          containerStyle={{ width: "32%" }}
          disabled={!agentSalesSummary.mobilePhone}
          onClick={() => window.open(`tel:${agentSalesSummary.mobilePhone}`)}
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-call-calling"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#3B82F6",
                  marginRight: 8,
                }}
              />
              Call
            </>
          }
        />
      </div>
    </div>
  );

  const renderRanking = () => {
    const projectSalesRanking = leaderBoardData
      ? _.find(leaderBoardData?.projectUnit, { id: id })
      : {};
    const subsalesSalesRanking = leaderBoardData
      ? _.find(leaderBoardData?.sales, { id: id })
      : {};
    const subsalesRentalRanking = leaderBoardData
      ? _.find(leaderBoardData?.rental, { id: id })
      : {};

    const fieldData = [
      {
        title: "PROJECT",
        image: projectSaleRank,
        value: projectSalesRanking,
        background: "linear-gradient(180deg, #2563EB 0%, #1E3A8A 100%)",
        labelBackground:
          "linear-gradient(270deg, rgba(16, 61, 171, 0.00) 0%, #103DAB 49.57%, rgba(16, 61, 171, 0.00) 100%)",
      },
      {
        title: "SUBSALES - SALE",
        image: subsalesSaleRank,
        value: subsalesSalesRanking,
        background: "linear-gradient(180deg, #7C3AED 0%, #4C1D95 100%)",
        labelBackground:
          "linear-gradient(270deg, rgba(77, 29, 151, 0.00) 0%, #4D1D97 48.01%, rgba(77, 29, 151, 0.00) 100%)",
      },
      {
        title: "SUBSALES - RENT",
        image: subsalesRentalRank,
        value: subsalesRentalRanking,
        background: "linear-gradient(180deg, #5850EC 0%, #42389D 100%)",
        labelBackground:
          "linear-gradient(270deg, rgba(66, 56, 157, 0.00) 0%, #42389D 48.01%, rgba(66, 56, 157, 0.00) 100%)",
      },
    ];

    return (
      <div className="at-sale__ranking">
        {fieldData.map((item, index) => (
          <div
            key={index}
            className="at-form__content mb-0 py-0 px-2 w-100 text-center"
            style={{ background: item.background }}
          >
            <label
              className="at-statistic_leaderboard-title"
              style={{ background: item.labelBackground }}
            >
              {item.title}
            </label>
            <div className="at-sales__ranking-info">
              <div>
                <BsTrophyFill
                  style={{ width: 16, height: 16, color: "#FEC84B" }}
                />
                <div>
                  {item.title === "PROJECT" ? (
                    <p className="fs-2 fw-600 text-white">
                      RM{" "}
                      {item.value?.total_amount
                        ? numberWithCommas(item.value.total_amount.toFixed(2))
                        : "0.00"}
                    </p>
                  ) : (
                    <p className="fs-2 fw-600 text-white">RM *****</p>
                  )}
                  <p
                    className="text-lg-center text-left mt-1 text-white"
                    style={{ fontSize: 12 }}
                  >
                    sales
                  </p>
                </div>
              </div>
              <div style={{ display: "block" }}>
                <p className="fs-1 fw-600 mb-1 text-white">Rank</p>
                <div
                  className="d-flex align-items-center bg-white fw-600"
                  style={{
                    fontSize: 12,
                    borderRadius: 25,
                    width: "fit-content",
                    margin: "auto",
                    padding: "4px 8px",
                  }}
                >
                  <AtlasIcon
                    svgHref={`atlas-medal`}
                    style={{
                      width: 12,
                      height: 12,
                      fill: "#F0631D",
                      marginRight: 5,
                    }}
                  />
                  {item.value?.rank ?? "N/A"}
                </div>
              </div>
            </div>
            <img src={item.image} alt="rank" className="mt-2" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <CustomTab
        sections={["General", "Project Sales", "Subsales Sales"]}
        onSelectSection={(item) => {
          Promise.all([
            onChangeMysalesHOC(item === "General" ? "" : item, "view"),
          ]).then(() => {
            onChangeMysalesHOC(item, "teamViewSummary");
          });
        }}
        selectedSection={teamViewSummary}
      />
      {teamViewSummary === "General" && (
        <>
          <GeneralDataContent
            selectedAgent={agentSalesSummary}
            renderAgentDetails={renderAgentDetails}
          />
          <SubsalesContent
            data={data}
            fromTeam={true}
            isAdminView={false}
            leaderBoardData={leaderBoardData}
            selectedAgent={agentSalesSummary}
            agentRanking={() => renderRanking()}
            projectSalesContent={() => (
              <ProjectSalesContent
                data={data}
                fromTeam={true}
                leaderBoardData={leaderBoardData}
                selectedAgent={agentSalesSummary}
              />
            )}
            avatar_url={
              large_avatar_url && !_.includes(large_avatar_url, "missing.png")
                ? large_avatar_url
                : defaultAvatar
            }
          />
        </>
      )}
    </>
  );
};

export default TeamViewSummary;
