import React, { useEffect, useState, useCallback, useMemo } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { Tooltip, MenuItem } from "@material-ui/core";
import { IoMdHelpCircle } from "react-icons/io";
import { withStyles } from "@material-ui/core/styles";

import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CountryCodeContent from "components/country_code";
import CustomCheckbox from "components/Checkbox";

import TownShipHOC from "actions/township";
import { requestError } from "utils/requestHandler";
import { personalFieldData, companyFieldData, occupationOptions } from "./assets";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000",
    fontSize: 16,
  },
}))(Tooltip);

const GenderOptions = [
  { id: "M", label: "Male", value: "M" },
  { id: "F", label: "Female", value: "F" },
];

const appendYearPrefix = (DOB) => {
  let checkAbove00Gen = new RegExp(/[0-3]/);
  let checkBelow90Gen = new RegExp(/[4-9]/);
  let firstDigit = DOB[0];

  if (checkAbove00Gen.test(firstDigit)) {
    return `20${DOB}`;
  } else if (checkBelow90Gen.test(firstDigit)) {
    return `19${DOB}`;
  }
};

const defaultFormData = {
  signee_type_id: "0",
  full_name: "",
  email: "",
  mobile_contact_number: "",
  identification_number: "",
  current_address: "",
  town: "",
  state: "",
  postcode: "",
  country: "",
  skip_mobile_contact_number: false,
  skip_email: false,
  occupation: ""
};

const CreateAssignContact = ({
  data,
  township,
  onLoadParty,
  selectedTitle,
  nationalities,
  selected_form,
  selectedPartyId,
  form_type,

  createParty,
  onChangePartyHOC,
  getSelectedTownShip,
}) => {
  const countries = _.map(data.dictionaryReducer.countries, (country) => {
    return { ...country, value: country.name };
  });

  const [tempFieldData, onChangeFieldData] = useState(personalFieldData);
  const [createObj, onChangeCreateObj] = useState(defaultFormData);
  const [states, setStates] = useState([]);
  const [showCountryCodeModal, onToggleCountryCode] = useState(false);

  const checkDisabledButton = useMemo(() => {
    let tmpFormFields = createObj.signee_type_id === "0"
      ? personalFieldData
      : companyFieldData
    return tmpFormFields.some((field) => {
      if((field.type !== "radio" && field.required) || (typeof field.onRequired === "function" && field.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))){
        return _.isEmpty(createObj[field.value])
      }
      return false
    })
  },[createObj, tempFieldData, selectedPartyId])

  const onCheckError = (val, context, dob) => {
    let tempStatusList = []
    let tmpObject = _.cloneDeep(createObj);
    const checkOccupation = val || _.find(occupationOptions, {
      value: val ?? "",
    }) 
    const checkNationality = _.find(nationalities, { value: val }) && tmpObject.signee_type_id === "0";
    
    tempStatusList = tempFieldData.map(field => {
      if((context === "identification_number" && field.value === "date_of_birth")) {
        return {
          ...field,
          isError: !dob || dob === null,
          errorCount: (!val && field.errorCount > -1) ? field.errorCount+1 : 0
        }
      } 
      if(context === field.value) {
        let tmpOptions = ["country", "state"].indexOf(field.value) === -1 
          ? field?.options || renderOptions(field.value) 
          : renderOptions(field.value)
        let tmpRequired = field.type !== "radio" && (field.required || (typeof field.onRequired === "function" && field.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId})))
  
         if(["nationality", "occupation"].indexOf(field.value) > -1) {
          let tmpIsError = (field.value === "nationality" && !checkNationality) || (field.value === "occupation" && !checkOccupation) 
          return {
            ...field,
            isError: tmpIsError,
            errorCount: (tmpIsError && field.errorCount > -1) ? field.errorCount+1 : 0
          }
        } else if(["select", "typeahead", "autosuggest"].indexOf(field.type) > -1) {
          const selectedObj = tmpRequired
            ? (val && _.find(tmpOptions, item => item.value === val))
            : (!val || _.find(tmpOptions, item => item.value === val));
        return {
            ...field,
            isError: !selectedObj,
            errorCount: (!selectedObj && field.errorCount > -1) ? field.errorCount+1 : 0
          };
        } else if(tmpRequired) {
          return {
            ...field,
            isError: _.isEmpty(val),
            errorCount: (_.isEmpty(val) && field.errorCount > -1) ? field.errorCount+1 : 0
          }
        } else {
          return field
        }
      } else {
        return field
      }
    })
    onChangeFieldData(tempStatusList)
  }

  useEffect(() => {
    let temp = _.find(
      countries,
      (country) => country.value === createObj.country,
    );
    if (temp) {
      let tempStates = _.map(temp.states, (state) => {
        return { ...state, value: state.name };
      });
      setStates(_.sortBy(tempStates, (i) => i.name.toLowerCase()));
    }
  }, [createObj.country]);

  useEffect(() => {
    let temp = _.find(states, (state) => state.value === createObj.state);
    if (temp) {
      getSelectedTownShip(temp.id);
    }
  }, [createObj.state]);

  useEffect(() => {
    let tempData = {};
    Object.keys(defaultFormData).map(
      (key) => (tempData[key] = createObj[key] || ""),
    );

    if (createObj.signee_type_id === "1" && selectedPartyId % 2 !== 0) {
      onChangeCreateObj({
        company_name: "",
        company_registration_no: "",
        ...tempData,
      });
      onChangeFieldData(companyFieldData);
    } else if (createObj.signee_type_id === "0") {
      onChangeCreateObj({
        ...tempData,
        nationality: "",
      });
      onChangeFieldData(personalFieldData);
    }
  }, [createObj.signee_type_id]);

  const onChangeCreateValue = (val, context) => {
    let tempCreateObj = _.cloneDeep(createObj);
    tempCreateObj[context] = val;

    if (
      context === "identification_number" &&
      tempCreateObj.signee_type_id !== "1"
    ) {
      tempCreateObj["date_of_birth"] = dobGenerator(val) || null;
    }
    onCheckError(val, context, dobGenerator(val) || null)
    onChangeCreateObj(tempCreateObj);
  };

  const dobGenerator = (val) => {
    let tmpVal = val.toString();
    let digitLength = tmpVal.length;
    let nricChecking = /^\d{6}-\d{2}-\d{4}$/;
    let tmpBirthday = tmpVal.substr(0, 6);
    tmpBirthday = appendYearPrefix(tmpBirthday);
    let birthday = Moment(tmpBirthday, "YYYYMMDD");
    let isValid = birthday.isValid();

    if ((!isNaN(tmpVal) && digitLength >= 6) || nricChecking.test(tmpVal)) {
      return isValid ? birthday.format("DD-MM-YYYY") : "";
    }
  };
  const debounceService = useCallback(
    _.debounce((val) => {
      return dobGenerator(val);
    }, 400),
    [],
  );

  const renderOptions = (value) => {
    switch (value) {
      case "country":
        return countries || [];
      case "state":
        return states || [];
      case "town":
        return township || [];
      case "nationality":
        return nationalities || [];
    }
  };

  return (
    <>
      <ModalDialog
        title={`Add ${selectedTitle}`}
        contentClassName={"overflow-auto"}
        cardClassName={"overflow-visible"}
        containerClasses={{paperScrollPaper: "overflow-visible"}}
        fullHeight={true}
        onClose={() => onChangePartyHOC(false, "showCreateContactModal")}
        children={
          <form
            className="grid-control grid_gap-y-2"
            onSubmit={(e) => e.preventDefault()}
          >
            {selectedPartyId % 2 !== 0 && (
              <section className="grid-full-col mb-3">
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={createObj.signee_type_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeCreateValue(val, "signee_type_id")
                  }
                  options={[
                    { value: "0", label: "Personal" },
                    { value: "1", label: "Company" },
                  ]}
                />
              </section>
            )}
            {tempFieldData.map((item) => {
              return (
                <section key={item.value} className={`grid-half-col`}>
                  <div className="d-flex">
                    <h2 className="at-form-input__title" required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}>
                      {item.title}
                    </h2>
                    {item.value === "mobile_contact_number" && (
                      <DarkTooltip
                        title={
                          "You may refer the country code provided by clicking this button."
                        }
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gridGap: 4 }}
                        >
                          <IoMdHelpCircle
                            style={{
                              cursor: "pointer",
                              marginLeft: 8,
                              width: "auto",
                              height: "18px",
                              color: "#3C82F6",
                              marginBottom: "6px",
                            }}
                            onClick={() => onToggleCountryCode(true)}
                          />
                          <p
                            className="at-form-input__title"
                            style={{ color: "#3C82F6", cursor: "pointer" }}
                            onClick={() => onToggleCountryCode(true)}
                          >
                            Country Code
                          </p>
                        </div>
                      </DarkTooltip>
                    )}
                  </div>
                  {item.type === "autosuggest" && (
                    <AtlasAutosuggest
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}
                      containerClass="mb-3"
                      className={`${item.isError ? "at-form-input--error" : ""}`}
                      placeholder={item.placeholder}
                      value={createObj[item.value] || ""}
                      updateValue={(val) => onChangeCreateValue(val, item.value)}
                      options={item?.options || renderOptions(item.value)}
                    />
                  )}
                  {item.type === "radio" && (
                    <AtlasRadioGroup
                      wrapperClass="mb-3"
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}
                      options={GenderOptions}
                      checkedValue={createObj[item.value] || ""}
                      selectedRadioValue={(val) =>
                        onChangeCreateValue(val, item.value)
                      }
                    />
                  )}
                  {item.type === "calendar" && (
                    <div className={`at-form-input mb-3 ${item.isError ? "at-form-input--error" : ""}`}>
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}
                        placeholderText={item.placeholder}
                        showYearDropdown={true}
                        className="at-form-input__input"
                        dateFormat="DD-MM-YYYY"
                        maxDate={new Date(Moment().subtract(18, "years"))}
                        value={createObj[item.value] || null}
                        onChange={(val) =>
                          onChangeCreateValue(
                            Moment(val).format("DD-MM-YYYY"),
                            item.value,
                          )
                        }
                      />
                      { (item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))) && <div className="at-form-input__required">required *</div>}
                    </div>
                  )}
                  {item.type === "select" && (
                    <AtSelectNew
                      rootClass={`${item.isError ? "at-form-input--error" : ""}`}
                      placeholder={item.placeholder}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}
                      value={createObj[item.value]}
                      onChange={(e) =>
                        onChangeCreateValue(e.target.value, item.value)
                      }
                    >
                      {["country", "state"].indexOf(item.value) === -1 && item?.options?.map((option) => (
                        <MenuItem
                          classes={{ root: `at-select__dropdown-item` }}
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                      {
                        ["country", "state"].indexOf(item.value) > -1 && renderOptions(item.value)?.map(option => (
                          <MenuItem
                            classes={{ root: `at-select__dropdown-item` }}
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      }
                    </AtSelectNew>
                  )}
                  {["text", "alphanumeric", "number"].includes(item.type) && (
                    <CustomFormInput
                      type={"text"}
                      containerClass="mb-3"
                      className={`${item.isError ? "at-form-input--error" : ""}`}
                      disabled={item.checkBoxValue && createObj[item.checkBoxValue]}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...createObj, form_type: form_type, party_id: selectedPartyId}))}
                      style={{ paddingLeft: 10 }}
                      value={createObj[item.value]}
                      onChangeValue={(val) => {
                        let temp = "";

                        switch (item.type) {
                          case "alphanumeric":
                            temp = val.replace(/[^$0-9a-zA-Z]/g, "");
                            break;

                          case "number":
                            temp = val.replace(/[^$0-9]/g, "");
                            break;

                          default:
                            temp = val;
                            break;
                        }
                        onChangeCreateValue(temp, item.value);

                        if (item.value === "identification_number") {
                          debounceService(val);
                        }
                      }}
                      inputError={""}
                      placeholder={item.placeholder || item.title}
                    />
                  )}
                  {item.instruction && (
                    <p
                      style={{
                        fontSize: "0.8rem",
                        color: "red",
                        maxWidth: "calc(100% - 60px)",
                        fontStyle: "italic",
                        transform: "translate(0px, -14px)",
                        height: "fit-content",
                      }}
                    >
                      {item.instruction}
                    </p>
                  )}
                  { item.checkBoxValue && (
                    <CustomCheckbox
                      labelClassname={"mb-2"}
                      labelStyle={{transform: 'translate(0px, -0.5rem)'}}
                      checked={createObj[item.checkBoxValue]}
                      content={
                        <span className="fw-500">
                          {item.checkBoxDescription}
                        </span>
                      }
                      onChangeCheckboxValue={() => onChangeCreateValue(!createObj[item.checkBoxValue], item.checkBoxValue)}
                    />
                  ) }
                </section>
              )
            })}
          </form>
        }
        footer={
          <div className="d-flex g-3">
            <button
              type={"button"}
              disabled={ checkDisabledButton }
              className="btn-new btn-new--primary"
              onClick={() => {
                let temp = _.cloneDeep(createObj);

                const checkOccupation = createObj?.occupation !== '' 
                  ?  _.find(occupationOptions, {
                    value: createObj?.occupation ?? "",
                  }) 
                  : true;
                const checkNationality = _.find(nationalities, { value: temp.nationality });

                if (!checkNationality && createObj.signee_type_id === "0") {
                  requestError("Please select nationality from the list");
                } else if (
                  !checkOccupation &&
                  createObj.signee_type_id === "0"
                ) {
                  requestError("Please select occupation from the list.");
                } else {
                  createParty(
                    {
                      ...createObj,
                      party_id: selectedPartyId,
                    },
                    selected_form.id,
                  );
                }
              }}
            >
              {`Create and Assign as ${selectedTitle}`}
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangePartyHOC(false, "showCreateContactModal")}
            >
              Cancel
            </button>
          </div>
        }
      />
      {showCountryCodeModal && (
        <CountryCodeContent onClose={() => onToggleCountryCode(false)} />
      )}
      {onLoadParty && <LoadingModal />}
    </>
  );
};

export default TownShipHOC(CreateAssignContact);