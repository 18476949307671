import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoIosClose, IoMdAdd } from "react-icons/io";
import { Snackbar, SnackbarContent } from "@material-ui/core";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasSubsalesClaimsTable from "components/NewPaginatedTable";
import CreateClaimForm from "./containers/CreateClaim/index";
import UpdateClaimForm from "./components/updateClaimForm";
import LoadingModal from "components/LoadingModal";
import AlertBox from "components/AlertBox";

import BankHOC from "actions/banks";
import SubsalesClaimsHOC from "./actions";
import AgenciesHOC from "./actions/agency";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesStatusHOC from "actions/subsalesStatus";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

import { ColumnData } from "./assets";

import "./index.scss";

class SubsalesClaims extends Component {
  state = {
    copyStatus: false,
  };

  componentDidMount = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getBanks(country_id);
    this.props.getBranches();
    this.props.getAgencies();
    this.props.getCommRate();
    this.props.getClaimsList(1, "");
    this.props.getAllDictionaryData();
    initGA("/dashboard/personal/subsales-claims");

    const { claim_id, id } = this.props.location?.state || {};
    const tmpId = claim_id || id;
    if (tmpId) {
      this.props.getSelectedClaim(tmpId);
    }
  };

  renderClaimFormDialog = () => {
    return (
      <AtlasDialog
        withFloatPanel={true}
        open={this.props.showUpdateClaimFormModal}>
        <UpdateClaimForm {...this.props} />
      </AtlasDialog>
    );
  };

  renderCreateForm = () => {
    return (
      <ModalDialog
        fullWidth={true}
        responsiveSize={"md"}
        title={"New Claim Form"}
        fullHeight={true}
        onClose={() => this.props.onChangeSubsaleClaimsHOC(false, "showCreate")}
        children={
          <CreateClaimForm
            {...this.props}
            onClose={() =>
              this.props.onChangeSubsaleClaimsHOC(false, "showCreate")
            }/>
        }
      />
    );
  };

  renderCardContent = () => {
    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const status_permissions =
      (status_id === 1 && this.props.completed_trainings) ||
      status_id === 2 ||
      this.props.whitelisted;

    return (
      <div className="at-card-container">
        <AtlasSubsalesClaimsTable
          columns={ColumnData({
            status_id: status_id,
            getSelectedClaim: this.props.getSelectedClaim,
            status_permissions: status_permissions,
            onClickCopy: (val) =>
              this.setState({ copyStatus: true }, () => {
                navigator.clipboard.writeText(val);
              }),
          })}
          rowData={this.props.claims.data ? this.props.claims.data : []}
          meta={this.props.claims.meta && this.props.claims.meta}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeSubsaleClaimsHOC(val, "searchParams")
          }
          actionColumnContent={
            status_permissions
              ? [
                  {
                    name: "edit",
                    onClick: (rowData) =>
                      this.props.getSelectedClaim(rowData.id),
                  },
                ]
              : []
          }
          getListAPI={this.props.getClaimsList}
          totalPages={this.props.totalPages}
        />
      </div>
    );
  };

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={this.state.copyStatus}
      autoHideDuration={6000}
      onClose={() => this.setState({ copyStatus: !this.state.copyStatus })}
    >
      <SnackbarContent
        message={
          <span>Data copied into clipboard. You can now paste the data.</span>
        }
        action={
          <button
            onClick={() =>
              this.setState({ copyStatus: !this.state.copyStatus })
            }
          >
            <IoIosClose style={{ color: "#eee", width: 25, height: 25 }} />
          </button>
        }
      />
    </Snackbar>
  );

  renderAlertContent = () => {
    const { data, commRate, completed_trainings } = this.props;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (
      (status_id !== 2 && !completed_trainings) ||
      (status_id !== 2 && !commRate)
    ) {
      return (
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              containerClassName={(status_id !==2 && !commRate) ? 'mb-3' : ''}
              title={"Training not completed."}
              description={
                "You have not completed your training. You are required to finish training before continuing. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              containerClassName="mb-0"
              title={"No commision rate set."}
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const {
      data,
      onLoadClaims,
      onLoadSubsalesStatus,
      onLoadAgent,
      onLoadCommRate,
    } = this.props;
    const { can_create } = permissionsChecker("Subsales Claims", data);
    const { role, status_id } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const status_permissions =
      (status_id === 1 && this.props.completed_trainings) ||
      status_id === 2 ||
      this.props.whitelisted;

    return (
      <>
        <ModuleHeader
          title={"Subsales Claim Forms"}
          atlasIcon={"atlas-subsales-claims"}
          showPABadge={role === "Personal Assistant"}
          actionButton={[
            {
              label: "New Claim Form",
              onShow: can_create && status_permissions,
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, color: "#FFFF" }} />
              ),
              onClick: () =>
                this.props.onChangeSubsaleClaimsHOC(true, "showCreate"),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.renderClaimFormDialog()}
        {this.props.showCreate && this.renderCreateForm()}
        {this.renderSnackBar()}
        {(onLoadClaims ||
          onLoadSubsalesStatus ||
          onLoadAgent ||
          onLoadCommRate) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  BankHOC,
  AgenciesHOC,
  SubsalesClaimsHOC,
  SubsalesStatusHOC,
  VersionCheckHOC,
)(SubsalesClaims);
