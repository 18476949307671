import { FEATURE_GUIDE } from "actions/type";

let initialState = {
  featureGuideId: "",
};

export default function featureGuideReducer(state = initialState, action) {
  switch (action.type) {
    case FEATURE_GUIDE:
      return {
        ...state,
        featureGuideId: action.payload.featureGuideId,
      };
    default:
      return state;
  }
}
