import React, { Component } from "react";
import _ from "lodash";

import { Get, Post, Put, Delete } from "utils/axios";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";
import { requestSuccess, requestError } from "utils/requestHandler";

const productTypeDictionary = [
  { id: 1, label: "Starter Kit", value: "1" },
  { id: 2, label: "Name Card", value: "2" },
  { id: 3, label: "Event Ticket", value: "3" },
  { id: 4, label: "Regular", value: "4" },
];

const statusDictionary = [
  { id: 1, value: "1", label: "Active" },
  { id: 2, value: "2", label: "Inactive" },
];

const searchParams = [
  {
    label: "Product Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Product Type",
    value: "type_id_eq",
    type: "select",
    param: "",
    options: productTypeDictionary,
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Active" },
      { value: "2", label: "Inactive" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateDialog: false,
      showEditDialog: false,
      showDeleteConfirmation: false,

      products: {},
      productsPage: null,
      selectedProduct: {},
      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeProductMngmtHOC = (val, context) =>
      this.setState({ [context]: val });

    // get all product management data
    getProductMngmt = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/market_products?${search}page=${page}`,
        this.getProductMngmtSuccess,
        this.getProductMngmtError,
        this.load,
      );
    };
    getProductMngmtSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      payload.data.length > 0 &&
        payload.data.map((item) => {
          tempData.push({
            ...item,
            status:
              _.find(statusDictionary, { id: item.status_id })?.label ?? "",
            type:
              _.find(productTypeDictionary, { id: item.type_id })?.label ?? "",
            fixed_quantity: item.is_quantity_fixed ? "Yes" : "No",
            price: item.price.toFixed(2),
          });
        });
      payload.data = tempData;
      this.setState({ products: payload, productsPage: tmpTotalPages });
    };
    getProductMngmtError = (error) => requestError(error);

    getSelectedProductMngmt = (id) =>
      Get(
        `/admin/market_products/${id}`,
        this.getSelectedProductMngmtSuccess,
        this.getSelectedProductMngmtError,
        this.load,
      );
    getSelectedProductMngmtSuccess = (payload) => {
      let temp = { ...payload };
      temp.type_id = `${temp.type_id}`;
      temp.status_id = `${payload.status_id}`;
      temp.country_id = _.find(this.props.data.dictionaryReducer.countries, {
        id: temp.country_id,
      });
      temp.photoSource = payload.photo_url;
      delete temp.photo_url;

      this.setState({
        selectedProduct: {
          ...temp,
          market_product_attached_items_attributes:
            temp.market_product_attached_items
              ? temp.market_product_attached_items.map((item) => ({
                  ...item,
                  edited: false,
                }))
              : [],
        },
        showEditDialog: true,
      });
    };
    getSelectedProductMngmtError = (error) => requestError(error);

    createProduct = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      temp.type_id = dataToSubmit.type_id;
      temp.status_id = +dataToSubmit.status_id;
      temp.country_id = dataToSubmit.country_id.id;

      Post(
        `/admin/market_products`,
        temp,
        this.createProductSuccess,
        this.createProductError,
        this.load,
      );
    };
    createProductSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getProductMngmt(currentPage, searchParams);
      this.getSelectedProductMngmt(payload.id);
      this.setState({ showCreateDialog: false });
      requestSuccess("New product created successfully.");
    };
    createProductError = (error) => requestError(error);

    updateProduct = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      temp.type_id = dataToSubmit.type_id;
      temp.status_id = parseInt(dataToSubmit.status_id);
      temp.country_id = dataToSubmit.country_id.id;
      temp.product_variants_attributes =
        dataToSubmit.product_variants &&
        dataToSubmit.product_variants.length > 0
          ? dataToSubmit.product_variants.map((item) => {
              return { id: item.id, price: item.price };
            })
          : [];

      if (
        dataToSubmit.photoSource &&
        dataToSubmit.photoSource.indexOf("http") > -1
      ) {
        temp.photo_url = temp.photoSource;
      } else {
        temp.photo = temp.photoSource;
      }
      delete temp.photoSource;

      Put(
        `/admin/market_products/${dataToSubmit.id}`,
        temp,
        this.updateProductSuccess,
        this.updateProductError,
        this.load,
      );
    };
    updateProductSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getProductMngmt(currentPage, searchParams);
      this.getSelectedProductMngmt(payload.id);

      requestSuccess("Product information updated successfully.");
    };
    updateProductError = (error) => requestError(error);

    deleteProduct = (id) =>
      Delete(
        `/admin/market_products/${id}`,
        this.deleteProductSuccess,
        this.deleteProductError,
        this.load,
      );
    deleteProductSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getProductMngmt(currentPage, searchParams);

      this.setState({ showDeleteConfirmation: false });

      requestSuccess(payload.message);
    };
    deleteProductError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          products={this.state.products}
          productsPage={this.state.productsPage}
          selectedProduct={this.state.selectedProduct}
          searchParams={this.state.searchParams}
          onLoadProductMngmt={this.state.loading}
          showCreateDialog={this.state.showCreateDialog}
          showEditDialog={this.state.showEditDialog}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          onChangeProductMngmtHOC={this.onChangeProductMngmtHOC}
          getSelectedProductMngmt={this.getSelectedProductMngmt}
          getProductMngmt={this.getProductMngmt}
          updateProduct={this.updateProduct}
          createProduct={this.createProduct}
          deleteProduct={this.deleteProduct}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
