import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import EditRequestForm from "./components/form";

import ConfirmationFormsRequestHOC from "./actions";
import BranchesHOC from "./actions/branches";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";
import { ColumnData } from "./asset";

class ConfirmationFormsRequest extends Component {
  componentDidMount = () => {
    this.props.getInvoicingBranches();
    this.props.getBranches();
    this.props.getRequests(1, "");
    initGA("/dashboard/admin/forms/confirmation-forms-requests");
  };

  renderEditConfirmationRequestDialog = () => (
    <AtlasDialog open={this.props.showEditRequestDialog}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <EditRequestForm
              data={this.props.data}
              permissionsChecker={permissionsChecker("Forms", this.props.data)}
              branches={this.props.branches}
              closeFormDialog={() =>
                this.props.onChangeRequestsHOC(false, "showEditRequestDialog")
              }
              selected_request={this.props.selected_request}
              current_status_id={this.props.initial_request.status_id}
              onClickSend={this.props.updateSelectedRequest}
              onLoadConfirmationFormRequests={
                this.props.onLoadConfirmationFormRequests
              }
            />
            {this.props.onLoadConfirmationFormRequests && <LoadingModal />}
          </>
        }
      />
    </AtlasDialog>
  );

  renderCardContent = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);

    return (
      <AtPaginatedTable
        columns={ColumnData}
        actionColumnContent={[
          {
            name: can_update ? "edit" : "view",
            onClick: (rowData) => this.props.getSelectedRequest(rowData.id),
          },
        ]}
        rowData={this.props.requests.data ? this.props.requests.data : []}
        meta={this.props.requests.meta && this.props.requests.meta}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeRequestsHOC(val, "searchParams")
        }
        getListAPI={this.props.getRequests}
        totalPages={this.props.requestsPages}
      />
    );
  };

  render = () => (
    <>
      <ModuleHeader
        moduleIcon={"icon-confirmation-req"}
        title={"Confirmation Forms Requests"}
      />
      <CustomCard
        className={`h-100`}
        containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
        cardContent={this.renderCardContent()} 
      />
      {this.renderEditConfirmationRequestDialog()}
      {this.props.onLoadConfirmationFormRequests && <LoadingModal />}
    </>
  );
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
  connect(mapStateToProps),
  ConfirmationFormsRequestHOC,
  BranchesHOC,
  VersionCheckHOC,
)(ConfirmationFormsRequest);
