import _ from "lodash";

const searchParamHandler = (item) => {
  if (item.param && item.param.toString().length > 0) {
    switch (item.type) {
      case "project-type": {
        return `q[${item.value}]=${item.param.name}&`;
      }

      case "select-multi": {
        let tmp = "";
        _.map(item.param, (param) => {
          tmp += `q[${item.value}][]=${param}&`;
        });
        return tmp;
      }

      case "typeahead": {
        return `q[${item.value}]=${item.param.name}&`;
      }
      
      default:
        return `q[${item.value}]=${item.param}&`;
    }
  }

  return null;
};

export default searchParamHandler;
