import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Grid } from "@material-ui/core";
import { PulseLoader } from "react-spinners";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";

import PublishedHOC from "../actions/published";
import AgentSearchHOC from "actions/agentSearch";

const StatusLib = [
  { id: "0", label: "Pending" },
  { id: "1", label: "Absent" },
  { id: "2", label: "Present" },
  { id: "3", label: "Resit" },
];

const CreateAttendance = ({
  agentList,
  publishedTrainings,
  createAttendance,
  getPublishedTrainings,
  onCloseModal,
  onLoadPublished,
  onLoadAgent,
  onLoadTrainings,

  onChangeAgentHOC,
  getAgentsBySearch,
}) => {
  useEffect(() => {
    getPublishedTrainings();
  }, []);

  const [createData, onChangeCreateData] = useState({
    status_id: null,
    event_id: null,
    user: null,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createData);
    temp[context] = val;
    onChangeCreateData(temp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onCloseModal()} />
        <h4 className="at-card__title">New Attendance</h4>
      </div>
      <Grid container spacing={16}>
        <Grid item md={6} xs={12}>
          <div className="d-flex">
            <h2 className="at-form-input__title">Select an Agent</h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={onLoadAgent}
            typeaheadId={"internal_agent"}
            options={agentList ? agentList : []}
            selectedValue={createData.user ? [createData.user] : []}
            labelKey={"full_name"}
            helpText={"e.g. Full Name, Email, Mobile Number"}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            onSearch={(val) => {
              onChangeField(null, "user");
              onChangeTypeaheadSearch(val);
            }}
            onSelect={(val) => {
              if (val && val.length > 0) {
                onChangeField(val[0], "user");
              }
            }}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <h2 className="at-form-input__title">Training</h2>
          <CustomTypeahead
            typeaheadId={"training"}
            filterBy={["name"]}
            options={publishedTrainings ? publishedTrainings : []}
            selectedValue={
              createData.event_id
                ? [_.find(publishedTrainings, { id: createData.event_id })]
                : []
            }
            labelKey={"name"}
            onSelect={(val) =>
              val && val.length > 0 && onChangeField(val[0].id, "event_id")
            }
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            highlighterData={[
              (option) => option.end_date_time,
              <br />,
              (option) => `Event description: ${option.description}`,
            ]}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <h2 className="at-form-input__title">Status</h2>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            checkedValue={createData.status_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeField(val, "status_id")}
            options={StatusLib}
          />
        </Grid>
      </Grid>
      <div className="d-flex mt-20">
        <AtlasButton
          disabled={_.values(createData).some((val) => !val)}
          className="btn-new btn-new--success"
          children={"Create"}
          onClick={() =>
            createAttendance({
              user_id: createData.user.id,
              event_id: createData.event_id,
              status_id: parseInt(createData.status_id),
            })
          }
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onCloseModal()}
        >
          Cancel
        </button>
      </div>
      {(onLoadPublished || onLoadTrainings) && <LoadingModal />}
    </>
  );
};

export default compose(AgentSearchHOC, PublishedHOC)(CreateAttendance);
