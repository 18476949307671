import React, { useState } from "react";
import { FormControl } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import CustomButton from "components/Button";

// contact_type_id
// 1 => Owner
// 2 => Buyer
// 3 => Owner Lawyer
// 4 => Buyer Lawyer

// Participants id
// 1 => Owner
// 2 => Buyer
// 3 => Internal Agent
// 4 => Referral

const AssignContactContent = ({
  formType,
  assignContact,
  selected_form,
  onChangePartyHOC,
  contacts,
  selectedParticipantTypeId, // Only used for Subsales Claim
  selectedPartyId,
  selectedTitle,
  onLoadParty,
}) => {
  const [selectedContact, onChangeContact] = useState("");
  
  const getContactTypeId = () => {
    if(selectedParticipantTypeId === 1) {
      return 1
    } else if(selectedParticipantTypeId === 2)  {
      return 2
    }
    return ""
  }

  // Only for Non-Law Firm
  const [contactTypeId, onChangeContactTypeId] = useState(getContactTypeId());
  
  return (
    <>
      <ModalDialog
        title={"Assign from Contacts"}
        size="md"
        onClose={() => onChangePartyHOC(false, "showAssignContactModal")}
        children={
          <>
            <div className={"d-flex mb-20"}>
              <FormControl style={{ width: "100%", height: 300 }}>
                <label className="at-form-input__title">Contacts</label>
                <CustomTypeahead
                  options={contacts || []}
                  onSearch={(val) => onChangeContact("")}
                  onSelect={(val) =>
                    val && val.length > 0 && onChangeContact(val[0])
                  }
                  labelKey={"full_name"}
                  filterBy={[
                    "full_name",
                    "email",
                    "company",
                    "company_registration_number",
                  ]}
                  helpText={
                    "e.g. Full Name, Email, Company, Company Registration Number"
                  }
                  childrenHead={(rowItem) => {
                    if(rowItem.type_id === 1) {
                      return (
                        <p className="d-flex align-items-center">
                          <AtlasIcon
                            svgHref={"atlas-icon-user"}
                            style={{
                              width: 14,
                              height: 14,
                              color: "#f0631d",
                              fill: "#f0631d",
                              marginRight: 10,
                            }}
                          />
                          {rowItem.full_name}
                        </p>
                      )
                    
                    } else {
                      return (
                        <p className="d-flex align-items-center">
                          <AtlasIcon
                            svgHref={"atlas-building-company"}
                            style={{
                              width: 14,
                              height: 14,
                              color: "#f0631d",
                              marginRight: 10,
                            }}
                          />
                          {rowItem.company} ({" "}
                          {rowItem.company_registration_number || "N/A"} ){" "}
                          {rowItem.full_name}
                        </p>
                      )
                    }
                  }}
                  highlighterData={[
                    (option) => `Email: ${option.email || "N/A"}`,
                  ]}/>
              </FormControl>
            </div>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <CustomButton
              className="btn-new btn-new--primary"
              disabled={!selectedContact}
              onClick={() => {
                if(formType !== "Claim Form") {
                  assignContact(
                    {
                      party_id: selectedPartyId,
                      contact_id: selectedContact.id,
                    },
                    selected_form.id,
                    selectedTitle,
                  )
                } else {
                  assignContact(
                    {
                      contact_id: selectedContact.id,
                      ...selectedParticipantTypeId !== 4 ? {contact_type_id: contactTypeId} : {}
                    }, 
                    selectedParticipantTypeId
                  )
                }
              }}>
              {`Assign as ${selectedTitle}`}
            </CustomButton>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangePartyHOC(false, "showAssignContactModal")}>
              Cancel
            </button>
          </div>
        }
      />
      {onLoadParty && <LoadingModal />}
    </>
  );
};

export default AssignContactContent;
