import React, { Component } from "react";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AdminActivitiesContent from "./containers/adminActivities";

import AdminActivitiesHOC from "./actions";
import "./index.scss";

class AdminActivities extends Component {
  render = () => {
    return (
      <>
        <ModuleHeader title={"Admin Activities"} moduleIcon={"icon-events"} />
        <CustomCard
          containerStyle={{ background: "#F3F4F6", overflowX: "none" }}
          cardContent={
            <AdminActivitiesContent
              data={this.props.data}
              impersonateActivity={this.props.impersonateActivity}
              getImpersonateActivity={this.props.getImpersonateActivity}
              onAdminActivitiesState={this.props.onAdminActivitiesState}
              showDetailsModal={this.props.showDetailsModal}
              selectedData={this.props.selectedData}
              requestsPages={this.props.requestsPages}
            />
          }
        />
        {this.props.onLoadAnalysis && <LoadingModal />}
      </>
    );
  };
}

export default AdminActivitiesHOC(AdminActivities);
