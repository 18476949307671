import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      paperForms: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getUnclaimPaperForm = () => {
      let url = `/forms/sub_sales_forms/unclaimed`;

      Get(
        url,
        this.getUnclaimPaperFormSuccess,
        this.getUnclaimPaperFormError,
        this.load,
      );
    };
    getUnclaimPaperFormSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.tracking_number,
            value: item.id,
          });
        });
        this.setState({ paperForms: tmp });
      }
    };
    getUnclaimPaperFormError = (error) => {
      this.setState({ paperForms: [] });
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            paperForms={this.state.paperForms}
            onLoadPaperForms={this.state.loading}
            getUnclaimPaperForm={this.getUnclaimPaperForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
