import React, { Component, Fragment } from "react";
import _ from "lodash";
import { MdClose } from "react-icons/md";

import CustomCheckbox from "components/Checkbox";

class Content extends Component {
  renderTableContentGrid = (
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
    moduleName
  ) => {
    const renderHighlightColumn = (param) => {
      if (param && param.columnValue === columnValue) {
        return "grid-top-object";
      }
      return "";
    };
    const renderPermissionIcon = (
      index,
      columnValue,
      content,
      columnLabel,
      columnWidth,
      moduleName
    ) => {
      const { modulesPermissions, rowItem, selectedPermissionGroup } = this.props;
      const shouldShowMdClose = selectedPermissionGroup.name === "Associated Agent" && moduleName === "Analytics Centre";
      return (
        <Fragment key={index}>
          {modulesPermissions.map((e, index) => {
            const isCheckboxChecked = _.includes(e.checkboxes, columnValue);
            if (e.moduleName === moduleName) {
              return (
                <li key={index} className={`at-table-row__item at-table__${index}`}>
                  <span className="at-table-row__item-label">{columnLabel}</span>
                  <div style={{ marginLeft: 8 }}>
                    {shouldShowMdClose || !isCheckboxChecked ? (
                      <MdClose style={{ width: 20 }} />
                    ) : (
                      <CustomCheckbox
                        checked={content}
                        onChangeCheckboxValue={() =>
                          this.props.onToggleCheckBox(rowItem.id, columnValue, content)
                        }
                      />
                    )}
                  </div>
                </li>
              );
            }
            return null
          })}
        </Fragment>
      );
    };

    const { actionColumnContent = [] } = this.props;

    return (
      <Fragment key={index}>
        {typeof content !== "boolean" ? (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              minWidth: 100,
              width: columnWidth ? columnWidth : "100%",
            }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            <p>{content}</p>
          </li>
        ) : (
          renderPermissionIcon(
            index,
            columnValue,
            content,
            columnLabel,
            columnWidth,
            moduleName
          )
        )}
      </Fragment>
    );
  };

  render = () => {
    const { headerData, rowItem } = this.props;
    let moduleName;
    return (
      <div className="at-table-row-cont">
        <div className="at-table__height-control">
          <ul className="at-table-row grid-controller">
            {headerData.map((columnItem, index) => {
              if (index === 0) {
                if (rowItem[columnItem.value])
                  moduleName = rowItem[columnItem.value];
              }

              if (moduleName)
                return this.renderTableContentGrid(
                  index,
                  columnItem.value,
                  rowItem[columnItem.value],
                  columnItem.label,
                  columnItem.width,
                  moduleName
                );
            })}
          </ul>
        </div>
      </div>
    );
  };
}

export default Content;
