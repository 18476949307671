import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";

import ConfirmationModal from "components/Modal/confirmation";

import CallImage from "assets/images/call_support.png";
import { getTranslation } from "assets/translation";

import "./index.scss";

const CallButton = ({ language }) => {
  const [showCallConfirmation, setShowCallConfirmation] = useState(false);

  return (
    <>
      <Tooltip placement={"left"} title={getTranslation("label.helptext.phone", language)}>
        <button
          className="at-support-group-btn telegram"
          onMouseDown={() => setShowCallConfirmation(true)}
          onTouchEnd={(event) => event.preventDefault()}
          onTouchStart={() => setShowCallConfirmation(true)}
        >
          <img src={CallImage} style={{ width: "40px", height: "40px" }} />
        </button>
      </Tooltip>
      <ConfirmationModal
        mode={"alert"}
        open={showCallConfirmation}
        title={getTranslation("label.phone_confirmation.title", language)}
        message={getTranslation("label.phone_confirmation.description", language)}
        loading={false}
        positiveText={
          <p>
            <FaPhoneAlt style={{ width: 14, height: 14, marginRight: 10 }} />
            {getTranslation("call", language)}
          </p>
        }
        positiveAction={() => {
          Promise.all([(window.location.href = "tel:+1800222155")]).then(() => {
            setShowCallConfirmation(false);
          });
        }}
        negativeText={getTranslation("cancel", language)}
        negativeAction={() => setShowCallConfirmation(false)}
      />
    </>
  );
};

export default CallButton;
