import React, { Component, useCallback, useState } from "react";

const ArrowContent = ({ onClickRearrangeData, columnContent, rowData }) => {
  const [sortStatus, setSortStatus] = useState("");

  const onToggleSortMode = useCallback(
    (param, value) => {
      setSortStatus(param);
      onClickRearrangeData({
        mode: param,
        columnValue: value,
      });
    },
    [columnContent, sortStatus, rowData],
  );

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("desc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("asc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
};

class TableHeader extends Component {
  onClickRearrangeData({ mode, columnValue }) {
    this.props.onClickRearrangeData({ mode, columnValue });
  }

  render() {
    const { data, actionColumn } = this.props;
    return (
      <ul className="at-table-row at-table-head">
        {data.map((item, index) => {
          return (
            <li
              key={item.label}
              className={`at-table-row__item at-table__${index}`}
              style={{ width: item.width && item.width }}>
              <span className="at-table-head__title">
                {item.label}
                <div style={{ maxWidth: 0, maxHeight: 0, position: "relative" }}>
                  <ArrowContent
                    columnContent={item.value}
                    onClickRearrangeData={(headerData) =>
                      this.onClickRearrangeData(headerData)
                    }
                  />
                </div>
              </span>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default TableHeader;
