import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasEmailModal from "components/Modal/email";
import ConfirmationModal from "components/Modal/confirmation";
import CobrokeLetter from "./components/TempCobrokeLetter";
import PreviewCobrokeLetterModal from "components/Modal/previewCobrokeLetterModal";
import ModuleFormHeader from "components/Form/header";

import ContactHOC from "actions/contact";
import BankHOC from "actions/banks";
import CobrokeLetterHOC from "./actions";
import AgencyHOC from "../../actions/agency";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesStatusHOC from "actions/subsalesStatus";
import InvoicingBranchesHOC from "../../actions/invoicingBranches";
import permissionsChecker from "utils/permissionsChecker";
import isEmptyValue from "utils/isEmpty";
import { initGA } from "utils/ga";

import "../../index.scss";
import ModalDialog from "components/Modal/ModalDialog";

const columnData = [
  {
    header: "ID",
    accessor: "id",
    columnStyle: { width: "30%" },
  },
  {
    header: "Letter Date",
    accessor: "letter_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Type",
    accessor: "type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Agency Name",
    renderColumn: (rowData) => (
      <>
        <strong className={"mb-2"}>{rowData.agency_name || "N/A"}</strong>
        <p>{rowData.agency_address || "N/A"}</p>
      </>
    ),
    columnStyle: { width: "130%" },
  },
  {
    header: "Property Address",
    accessor: "property_address",
    columnStyle: { width: "170%" },
  },
];

class CobrokeLetterForm extends Component {
  componentDidMount = () => {
    this.initComponent();
    initGA("/dashboard/personal/forms/cobroke-letter");
  };

  initComponent = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getCommRate();
    this.props.getAgencies();
    this.props.getBanks(country_id);
    this.props.getCobrokeLetters(1, "");
    this.props.getInvoicingBranches();
    this.props.getCurrentUserContactList();
  };

  componentDidUpdate = (pp) => {
    const { branch_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (
      this.props.showCreateCobrokeLetterDialog !==
        pp.showCreateCobrokeLetterDialog &&
      !this.props.showCreateCobrokeLetterDialog
    ) {
      const temp = _.find(this.props.invoicing_branches, {
        branch_id: branch_id,
      });
      if (temp) {
        this.props.resetCreateCobroke(temp.id);
      } else {
        this.props.resetCreateCobroke();
      }
    }
  };

  onChangeCreateFormValue = (context, val) => {
    const temp = _.cloneDeep(this.props.createCobrokeObj);

    if (context === "selected_agency" && val.length > 0) {
      temp.agency_id = val[0].id;
    }
    temp[context] = val;

    this.props.onChangeCobrokeHOC(temp, "createCobrokeObj");
  };

  submitEmail = (recipient) =>
    this.props.sendCobrokeLetter(this.props.selectedCobrokeLetter.id, {
      recipients: recipient,
    });

  renderConfirmationModal = () => (
    <ConfirmationModal
      loading={this.props.onLoadCobrokeLetter}
      open={this.props.showConfirmationModal}
      message={`
        You are about to download the co-broke letter and email it to the co-agency. Click "Yes" to proceed. Once you confirm, you will not be able to edit the letter.
      `}
      positiveAction={() =>
        this.props.submitCobrokeLetter(this.props.selectedCobrokeLetter.id)
      }
      negativeAction={() =>
        this.props.onChangeCobrokeHOC(false, "showConfirmationModal")
      }
    />
  );

  renderPreviewCobrokeLetterModal = () => {
    return (
      <>
        {this.props.showPreviewCobrokeLetterModal && (
          <PreviewCobrokeLetterModal
            open={this.props.showPreviewCobrokeLetterModal}
            onClickClose={this.props.onChangeCobrokeHOC}
            {...this.props.initialSelectedCobrokeLetter}
          />
        )}
      </>
    );
  };

  renderEditCobrokeLetterForm = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { id } = this.props.selectedCobrokeLetter;
    const tmpId = `${!isEmptyValue(id) ? `/ ${id}` : ""}`;
    const disableSubmitButton =
      !can_update || this.props.selectedCobrokeLetter.closed_editing;

    return (
      <ModalDialog
        title={`${this.props.selectedCobrokeLetter.closed_editing ? "View" : "Edit"} Letter ${tmpId}`}
        fullWidth={true}
        onClose={() =>
          this.props.onChangeCobrokeHOC(false, "showEditCobrokeLetterDialog")
        }
        children={
          <>
            {this.props.agencies.length > 0 && (
              <CobrokeLetter
                can_update={true}
                agencies={this.props.agencies}
                invoicing_branches={this.props.invoicing_branches}
                onLoadCobrokeLetter={this.props.onLoadCobrokeLetter}
                onChange={this.props.onChangeEditCobrokeLetterVal}
                storedAddresses={this.props.data.addressReducer.address}
                {...this.props.selectedCobrokeLetter}
              />
            )}

            <div className="at-form__content">
              <ModuleFormHeader
                title={"Actions"}
                className={"mb-3"}
                moduleIcon={"atlas-more-circle"}
              />
              <p className={"d-block"}>
                Please make sure that you have saved the data by clicking the "Save" button in T&C before proceeding any actions below.
              </p>
              {!this.props.selectedCobrokeLetter.closed_editing && (
                <div className="form-action">
                  <div style={{ width: "100%" }}></div>
                  <button
                    className="btn-new btn-new--secondary"
                    onClick={() =>
                      this.props.onChangeCobrokeHOC(
                        true,
                        "showPreviewCobrokeLetterModal",
                      )
                    }
                  >
                    Preview Cobroke Letter
                  </button>
                  <button
                    className="btn-new btn-new--primary"
                    onClick={() =>
                      this.props.onChangeCobrokeHOC(
                        true,
                        "showConfirmationModal",
                      )
                    }
                    disabled={!can_update}
                  >
                    Submit Now
                  </button>
                </div>
              )}
              {this.props.selectedCobrokeLetter.closed_editing && (
                <div className="form-action">
                  <div style={{ width: "100%" }}></div>
                  <button
                    className="btn-new btn-new--primary"
                    onClick={() =>
                      this.props.onChangeCobrokeHOC(
                        true,
                        "showPreviewCobrokeLetterModal",
                      )
                    }>
                    {`Preview Cobroke Letter`}
                  </button>
                  <button
                    className="btn-new btn-new--secondary"
                    onClick={() =>
                      this.props.downloadCobrokeLetter(
                        this.props.selectedCobrokeLetter.id,
                      )
                    }>
                    {`Download Cobroke Letter`}
                  </button>
                  <button
                    className="btn-new btn-new--secondary"
                    onClick={() =>
                      this.props.onChangeCobrokeHOC(true, "showSendEmail")
                    }>
                    {`Send Cobroke Letter`}
                  </button>
                </div>
              )}
            </div>
          </>
        }
        footer={
          <div className="d-flex">
            {can_update && (
              <button
                disabled={disableSubmitButton}
                className={`btn-new btn-new--success mr-10`}
                onClick={() =>
                  this.props.updateCobrokeLetter(
                    this.props.selectedCobrokeLetter,
                  )
                }>
                Save
              </button>
            )}
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() =>
                this.props.onChangeCobrokeHOC(
                  false,
                  "showEditCobrokeLetterDialog",
                )
              }>
              Close
            </button>
          </div>
        }
      />
    );
  };

  renderCreateCobrokeLetterForm = () => {
    return (
      <ModalDialog
        title={"New Co-Broke Letter"}
        onClose={() =>
          this.props.onChangeCobrokeHOC(false, "showCreateCobrokeLetterDialog")
        }
        children={
          this.props.agencies.length > 0 && (
            <CobrokeLetter
              agencies={this.props.agencies}
              invoicing_branches={this.props.invoicing_branches}
              onLoadCobrokeLetter={this.props.onLoadCobrokeLetter}
              disableSubmitButton={this.props.disableSubmitButton}
              onChange={this.onChangeCreateFormValue}
              storedAddresses={this.props.data.addressReducer.address}
              {...this.props.createCobrokeObj}
            />
          )
        }
        footer={
          <div className="d-flex">
            <button
              disabled={this.props.disableSubmitButton}
              className={`btn-new btn-new--primary mr-10`}
              onClick={() => {
                this.props.onChangeCobrokeHOC(true, "disableSubmitButton");
                this.props.createCobrokeLetter(this.props.createCobrokeObj);
              }}
            >
              Submit
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() =>
                this.props.onChangeCobrokeHOC(
                  false,
                  "showCreateCobrokeLetterDialog",
                )
              }
            >
              Cancel
            </button>
          </div>
        }
      />
    );
  };

  renderGenerateCobrokeLetterDialog = () => (
    <AtlasDialog open={this.props.showCreateCobrokeLetterDialog}>
      <CustomCard
        className={`h-100`}
        cardContent={this.renderCreateCobrokeLetterForm()}
      />
    </AtlasDialog>
  );

  renderAlertContent = () => {
    const { data, commRate, completed_trainings } = this.props;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (
      (status_id !== 2 && !completed_trainings) ||
      (status_id !== 2 && !commRate) ||
      status_id === 2
    ) {
      return (
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={"Training not completed."}
              containerClassName={((status_id !== 2 && !commRate) || status_id === 2) ? 'mb-3' : ''}
              description={
                "You have not completed your training. You are required to finish training before accessing Appointment Letter, Confirmation Form, Cobroke Letter & Authorisation Forms To Secure Property. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              title={"No commision rate set."}
              containerClassName={ status_id === 2 ? 'mb-3' : '' }
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const {
      data,
      whitelisted,
      searchParams,
      cobrokeLetters,
      completed_trainings,
      getCobrokeLetters,
      cobrokeLettersPage,
      onChangeCobrokeHOC,
    } = this.props;
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    const { can_create } = permissionsChecker("Forms", data);
    const status_permissions =
      (status_id !== 2 && completed_trainings) || whitelisted;

    return (
      <>
        <ModuleHeader
          title={"Cobroke Letters"}
          atlasIcon={"atlas-cobroke-letter"}
          showPABadge={role === "Personal Assistant"}
          backButton={{
            onClick: () => this.props.history.goBack(),
          }}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeCobrokeHOC(
                  true,
                  "showCreateCobrokeLetterDialog",
                ),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <>
              <AtPaginatedTable
                rowData={cobrokeLetters.data || []}
                meta={cobrokeLetters.meta}
                columns={columnData}
                actionColumnContent={
                  status_permissions
                    ? [
                        {
                          name: "edit",
                          onClick: (rowData) =>
                            this.props.getCobrokeLetter(rowData.id),
                        },
                      ]
                    : []
                }
                searchParams={searchParams}
                onChangeSearchParams={(val) =>
                  onChangeCobrokeHOC(val, "searchParams")
                }
                getListAPI={getCobrokeLetters}
                totalPages={cobrokeLettersPage}
              />
              <AtlasEmailModal
                title={"Send Cobroke Letter"}
                showSendEmail={this.props.showSendEmail}
                loading={this.props.onLoadCobrokeLetter}
                email={this.props.selectedCobrokeLetter.email}
                closeSendEmail={() =>
                  this.props.onChangeCobrokeHOC(false, "showSendEmail")
                }
                submitEmail={(recipients) => this.submitEmail(recipients)}
              />
              {this.renderPreviewCobrokeLetterModal()}
              {this.renderGenerateCobrokeLetterDialog()}
              {this.props.showEditCobrokeLetterDialog &&
                this.renderEditCobrokeLetterForm()}
              {this.renderConfirmationModal()}
              {(this.props.onLoadCobrokeLetter ||
                this.props.onLoadAgencies ||
                this.props.onLoadInvoicingBranches) && <LoadingModal />}
            </>
          }
        />
      </>
    );
  };
}

export default compose(
  BankHOC,
  AgencyHOC,
  CobrokeLetterHOC,
  SubsalesStatusHOC,
  InvoicingBranchesHOC,
  ContactHOC,
  VersionCheckHOC,
)(CobrokeLetterForm);
