import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { requestError } from "utils/requestHandler";

import { Get, Delete } from "utils/axios";
import { document_types } from "dictionary/subsales";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      documents: [],
      selectedSubsaleClaimID: 0,
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getDocuments = (id) =>
      Get(
        `/sub_sale_claims/${id}/documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) => {
      let tmp = [];

      payload.map((item) => {
        let doc = _.find(document_types, { id: item.type_id });
        let data = {
          ...item,
          doc_type: doc ? doc.name : "",
          created_at: Moment(item.created_at).format("DD MMM YYYY HH:mm"),
        };
        tmp.push(data);
      });
      return this.setState({ documents: tmp });
    };
    getDocumentsError = (error) => requestError(error);

    deleteDocument = (id, doc_id) => {
      Delete(
        `/sub_sale_claims/${id}/documents/${doc_id}`,
        this.deleteDocumentSuccess,
        this.deleteDocumentError,
        this.load,
      );
      return this.setState({ selectedSubsaleClaimID: id });
    };
    deleteDocumentSuccess = () =>
      this.getDocuments(this.state.selectedSubsaleClaimID);
    deleteDocumentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            documents={this.state.documents}
            onLoadDocuments={this.state.loading}
            deleteDocument={this.deleteDocument}
            getDocuments={this.getDocuments}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
