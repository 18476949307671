import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import LoadingModal from "components/LoadingModal";
import CustomTypeahead from "components/Typeahead/new";
import AtlasRadioGroup from "components/RadioGroup";

const GenerateForm = ({
  title,
  agentList,
  onLoad,
  invoicingBranch,
  typeOptions,
  generateForm,
  submitButtonText,
  getAgentsBySearch,
  disableSubmitButton,
  onClickCloseFormGenerator,
  onChangeConfirmationFormHOC,
}) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [createForm, onChangeForm] = useState({
    agent_id: null,
    invoicing_branch_id: null,
    type_id: null,
  });

  const onChangeField = useCallback(
    (val, context) => {
      onChangeForm({
        ...createForm,
        [context]: val,
      });
    },
    [createForm],
  );

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeConfirmationFormHOC("agentList", []);
    }, 600),
    [],
  );

  let disabledCreate = useMemo(() => {
    return _.values(createForm).some((item) => !item);
  }, [createForm]);

  return (
    <>
      <div className="grid-control">
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Select agent</h2>
          <CustomTypeahead
            typeaheadId={"internal_agent"}
            placeholder={"e.g. Agent Full Name, Email, Mobile Number"}
            options={agentList || []}
            selectedValue={selectedAgent ? [selectedAgent] : []}
            labelKey={"full_name"}
            onSearch={(val) => {
              setSelectedAgent(null);
              onChangeTypeaheadSearch(val);
            }}
            onSelect={(val) => {
              if (val && val.length > 0) {
                onChangeField(val[0]["id"], "agent_id");
                setSelectedAgent(val[0]);
              }
            }}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Select a branch</h2>
          {invoicingBranch && invoicingBranch.length > 0 ? (
            <CustomSelect
              className="w-100"
              placeholder={"Select a branch"}
              defaultValue={""}
              selectItems={invoicingBranch}
              currentlySelected={selectedBranch || {}}
              updateSelect={(val) => {
                const tmpEmpty = {
                  id: null,
                  value: null,
                  name: "",
                  registration_number: "",
                  contact_details: "",
                  label: ""
                }
                setSelectedBranch(val || tmpEmpty);
                onChangeField(val?.id || null, "invoicing_branch_id");
              }}
            />
          ) : (
            <h4 className="at-card__title mb-20">{"Loading branches"}</h4>
          )}
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Type</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={createForm.type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeField(val, "type_id")}
            options={typeOptions}
          />
        </section>
        {onLoad && <LoadingModal />}
      </div>
      <section className="at-modal_dialog-container-footer">
        <div className="d-flex flex-wrap grid_gap-1">
          <button
            type={"button"}
            className="btn btn-new btn-new--primary"
            disabled={disableSubmitButton || disabledCreate}
            onClick={() =>
              generateForm({
                user_id: createForm.agent_id,
                invoicing_branch_id: createForm.invoicing_branch_id,
                type_id: createForm.type_id,
              })
            }
          >
            {submitButtonText || "Create"}
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={onClickCloseFormGenerator}
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default GenerateForm;