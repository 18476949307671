import React from "react";
import _ from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import CustomUpload from "components/Upload";
import CustomFormInput from "components/Input/formInput";

import defaultAvatar from "assets/images/av-male.jpg";
import { board_registration_options } from "dictionary/index";

const ProfileContent = ({
  role,
  updateAgentInfo,
  displayNameError,
  currentAddressError,
  emergencyPersonNameError,
  emergencyContactError,

  onHandleEdit,
  onChangeUpdateAgent,
}) => {
  const renderBoardRegistrationPrefix = () => {
    switch (updateAgentInfo.board_registration_type_id) {
      case 1:
        return "REN";
      case 2:
        return "PEA";
      case 3:
        return "E";
      case 4:
        return "PV";
      case 5:
        return "V";
      default:
        return "";
    }
  };

  return (
    <>
      <section className="grid-full-col at-profile__avatars">
        <h4 className="at-form-input__title">Avatar</h4>
        <div className="at-profile__avatar-images">
          <CustomUpload
            className="at-profile__main-avatar"
            imageUrl={
              updateAgentInfo.avatar_url &&
              !_.includes(updateAgentInfo.avatar_url, "missing.png")
                ? updateAgentInfo.avatar_url
                : defaultAvatar
            }
            accept={`image/png,image/jpg,image/jpeg'`}
            uploadImage={(val) => onHandleEdit(val)}
          />
        </div>
      </section>

      <section className="grid-full-col mb-20">
        <p className="at-avatar-note">
          <strong>Note:</strong> The avatar will be displayed on the website.
        </p>
      </section>

      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Display Name
        </h2>
        <CustomFormInput
          type="text"
          value={updateAgentInfo.display_name}
          onChangeValue={(val) =>
            onChangeUpdateAgent(val, "display_name", "displayNameError")
          }
          required={true}
          inputError={displayNameError}
          placeholder={"Enter your display name"}
        />
      </section>
      {(role === "Agent" || role === "Associated Agent") && (
        <section className="grid-half-col">
          <h2 className="at-form-input__title"> WeChat ID </h2>
          <CustomFormInput
            type="text"
            value={updateAgentInfo.wechat_id}
            required={false}
            onChangeValue={(val) => onChangeUpdateAgent(val, "wechat_id")}
            placeholder={"Enter your WeChat ID"}
          />
        </section>
      )}
      {role === "Agent" && (
        <>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Current Address
            </h2>
            <CustomFormInput
              type="text"
              value={updateAgentInfo.current_address}
              required={true}
              inputError={currentAddressError}
              onChangeValue={(val) =>
                onChangeUpdateAgent(
                  val,
                  "current_address",
                  "currentAddressError"
                )
              }
              placeholder={"Enter your current address."}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              {" "}
              Emergency Person Name{" "}
            </h2>
            <CustomFormInput
              type="text"
              value={
                updateAgentInfo.agent_registration_attributes
                  .emergency_person_name
              }
              inputError={emergencyPersonNameError}
              required={true}
              onChangeValue={(val) =>
                onChangeUpdateAgent(
                  val,
                  "agent_registration_attributes",
                  "emergencyPersonNameError",
                  "emergency_person_name"
                )
              }
              placeholder={"Enter your emergency person name"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              {" "}
              Emergency Contact No.{" "}
            </h2>
            <CustomFormInput
              type="text"
              value={
                updateAgentInfo.agent_registration_attributes
                  .emergency_contact_number
              }
              inputError={emergencyContactError}
              required={true}
              onChangeValue={(val) =>
                onChangeUpdateAgent(
                  val,
                  "agent_registration_attributes",
                  "emergencyContactError",
                  "emergency_contact_number"
                )
              }
              placeholder={"Enter your emergency contact number"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Youtube Playlist Link</h2>
            <CustomFormInput
              type="text"
              value={updateAgentInfo.youtube_playlist_url}
              onChangeValue={(val) =>
                onChangeUpdateAgent(val, "youtube_playlist_url")
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title"> Facebook Link </h2>
            <CustomFormInput
              type="text"
              value={updateAgentInfo.facebook_url}
              required={false}
              onChangeValue={(val) => onChangeUpdateAgent(val, "facebook_url")}
              placeholder={"Enter your Facebook Link"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title"> Telegram Link </h2>
            <CustomFormInput
              type="text"
              value={updateAgentInfo.telegram_url}
              required={false}
              onChangeValue={(val) => onChangeUpdateAgent(val, "telegram_url")}
              placeholder={"Enter your Telegram Link"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title"> Instagram Link </h2>
            <CustomFormInput
              type="text"
              value={updateAgentInfo.instagram_url}
              required={false}
              onChangeValue={(val) => onChangeUpdateAgent(val, "instagram_url")}
              placeholder={"Enter your Telegram Link"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Board Registration Type</h2>
            <CustomFormInput
              type="text"
              disabled={true}
              value={
                _.find(board_registration_options, {
                  id: updateAgentInfo.board_registration_type_id,
                })
                  ? _.find(board_registration_options, {
                      id: updateAgentInfo.board_registration_type_id,
                    }).name
                  : "N/A"
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Board Registration Number</h2>
            <CustomFormInput
              type="text"
              prefix={renderBoardRegistrationPrefix()}
              disabled={true}
              value={updateAgentInfo.board_registration_number || "N/A"}
            />
          </section>
        </>
      )}
      {role === "Agent" && (
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Biography</h2>
          <CKEditor
            editor={ClassicEditor}
            data={updateAgentInfo.description}
            config={{
              toolbar: [[]],
            }}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeUpdateAgent(data, "description");
            }}
          />
        </section>
      )}
    </>
  );
};

export default ProfileContent;
