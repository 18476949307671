import React, { Component } from "react";
import _ from "lodash";

import { Post } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    exportErp = (id) =>
      Post(
        `/admin/registrations/export/${id}`,
        {},
        this.exportErpSuccess,
        this.exportErpError,
        this.load,
      );
    exportErpSuccess = () =>
      requestSuccess("Record exported to ERP successfully.");
    exportErpError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadERP={this.state.loading}
          exportErp={this.exportErp}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
