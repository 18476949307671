import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      dataSubsalesReport: {
        labels: [],
        datasets: [],
      },
      subsalesData: {},
      allSubsalesData: {
        sale_ranking: [],
        rent_ranking: [],
        total_ranking: [],
      },
      subsalesType: "all",
      chartConfig: {
        barPercentage: 0.5,
        barThickness: 15,
        maxBarThickness: 15,
        minBarLength: 8,
      },
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    onChangeSubsalesReportsHOC = (key, val) => this.setState({ [key]: val });

    getSubsalesReports = (startDate, endDate, branch) =>
      Get(
        `/connector/sales/ranking?type_id=2&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}&branch_id=${branch ? branch : ""}`,
        this.getSubsalesReportsSuccess,
        this.getSubsalesReportsError,
        this.load,
      );
    getSubsalesReportsSuccess = (payload) => {
      let allSubsales = [];
      Object.keys(payload).map((key) => {
        payload[key].map((item) => {
          allSubsales.push({
            ...item,
            type: key.toString(),
          });
        });
      });
      let tempArray = _.cloneDeep(payload.total_ranking);
      let chartTemp = {
        labels: [],
        datasets: [],
      };
      let tmpLabels = [];
      let tmpDatasets1 = [];
      let tmpDatasets2 = [];
      tempArray.map((item) => {
        tmpLabels.push(item.Agent);
        tmpDatasets1.push(parseInt(item.GrandTotalAgencyCommission));
        tmpDatasets2.push(parseInt(item.TotalAgencyCommission));
      });
      chartTemp["labels"] = tmpLabels;
      chartTemp["datasets"] = [
        {
          label: "Total Sales Amount (Sale + Rental)",
          data: tmpDatasets1,
          backgroundColor: "rgba(255,99,132,0.4)",
          hoverBackgroundColor: "rgba(250,99,132, 1)",
          ...this.state.chartConfig,
        },
        {
          label: "Total Converted Amount (Sale + Rental) ",
          data: tmpDatasets2,
          backgroundColor: "rgba(54,162,235,0.4)",
          hoverBackgroundColor: "rgba(54,162,235,1)",
          ...this.state.chartConfig,
        },
      ];
      this.setState({
        subsalesData: tempArray,
        allSubsalesData: payload,
        dataSubsalesReport: chartTemp,
        subsalesType: "all",
      });
    };
    getSubsalesReportsError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadReports={this.state.loading}
          dataSubsalesReport={this.state.dataSubsalesReport}
          subsalesData={this.state.subsalesData}
          subsalesType={this.state.subsalesType}
          allSubsalesData={this.state.allSubsalesData}
          chartConfig={this.state.chartConfig}
          getSubsalesReports={this.getSubsalesReports}
          onChangeSubsalesReportsHOC={this.onChangeSubsalesReportsHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
