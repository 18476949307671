import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      paperForms: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getUnclaimPaperForm = (agent_id) =>
      Get(
        `/admin/forms/sub_sales_forms/unclaimed?user_id=${agent_id}`,
        this.getUnclaimPaperFormSuccess,
        this.getUnclaimPaperFormError,
        this.load,
      );
    getUnclaimPaperFormSuccess = (payload) => {
      let tmp = [];
      if (payload && payload.length > 0) {
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.tracking_number,
            value: item.id,
          });
        });
      }
      this.setState({ paperForms: tmp });
    };
    getUnclaimPaperFormError = (error) => {
      this.setState({ paperForms: [] });
      requestError(error);
    };

    onChangePaperFormHOC = (value, context) =>
      this.setState({ [context]: value });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            paperForms={this.state.paperForms}
            onLoadPaperForms={this.state.loading}
            onChangePaperFormHOC={this.onChangePaperFormHOC}
            getUnclaimPaperForm={this.getUnclaimPaperForm}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
