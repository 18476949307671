import React, { Component } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { MdLiveHelp } from "react-icons/md";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import PartyCard from "components/Card/partyCard";
import AlertBox from "components/AlertBox";
import ESigningContent from "./history";
import ESigningTips from "./tips";
import ProcessSection from "./process";

class EprocessContent extends Component {
  state = {
    showEsigningTipsModal: false,
  };

  renderPendingProcessContent = () => {
    const { eProcessPartyData = [] } = this.props;
    return (
      <>
        {eProcessPartyData.length > 0 && (
          <div className="party-card_cont mb-3">
            {eProcessPartyData.map((partyData, index) => (
              <PartyCard
                key={index}
                containerClass={"party-card-new"}
                type={"appointment"}
                index={index}
                data={partyData}
              />
            ))}
          </div>
        )}
        {eProcessPartyData.length < 1 && (
          <AlertBox
            title={""}
            containerStyle={{ marginBottom: 10 }}
            description={`Please add the contract participants for each section before start the e-Signing process`}
          />
        )}
        <div
          className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}
        >
          {this.props.renderNavButton()}
        </div>
      </>
    );
  };

  renderInitiatedProcessContent = () => {
    const {
      signingStatusId,
      signingStatus,
      selected_appointment_letter,
      eProcessPartyData,
      downloadCompletedForm,
    } = this.props;

    let temp = _.filter(eProcessPartyData, (item) => item.status_id === 2);
    let tempPercentage = eProcessPartyData.length > 0 ? ((temp.length / eProcessPartyData.length) * 100) : 100;
    return (
      <>
        <Grid container spacing={0}>
          <div className="d-flex flex-wrap grid_gap-1 mb-3">
            {signingStatusId === `2` && (
              <button
                className="btn-new btn-new--secondary"
                onClick={() =>
                  downloadCompletedForm(
                    selected_appointment_letter.id,
                    `${selected_appointment_letter.transaction_number}_e_process.pdf`,
                  )
                }
              >
                {`Download Appointment Letter`}
              </button>
            )}
            {signingStatusId === "1" && (
              <button
                className={"btn-new btn-new--secondary"}
                onClick={() => {
                  this.props.getParty(
                    this.props.selected_appointment_letter.id,
                  );
                  this.props.getSigningStatus(
                    this.props.selected_appointment_letter.id,
                  );
                }}>
                Check Status
              </button>
            )}
          </div>
          <Grid item md={12} xs={12}>
            <ProcessSection
              status={signingStatus}
              tempPercentage={tempPercentage}
              selected_appointment_letter={selected_appointment_letter}
              eProcessPartyData={eProcessPartyData}
              onClickViewActivity={() =>
                this.props.onChangePartyHOC(true, "showSigningHistory")
              }
              onClickRefresh={() => {
                this.props.getParty(this.props.selected_appointment_letter.id);
                this.props.getSigningStatus(
                  this.props.selected_appointment_letter.id,
                );
              }}/>
            <div className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
              {this.props.renderNavButton()}
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  renderModalContent = () => {
    const {
      onChangePartyHOC,
      selected_appointment_letter,
      showSigningHistory,
    } = this.props;
    return (
      <AtlasDialog open={showSigningHistory}>
        {/* Signing History */}
        <CustomCard
          className={"h-100"}
          cardContent={
            <ESigningContent
              formId={selected_appointment_letter.id}
              onChangePartyHOC={onChangePartyHOC}
            />
          }
        />
      </AtlasDialog>
    );
  };

  render = () => {
    const {
      onLoadParty,
      onLoadDraftEmail,
      onLoadAuthorisation,

      eProcessContentId,
    } = this.props;

    return (
      <>
        <CustomButton
          containerClass={"mb-3"}
          className={"at-tip__button"}
          children={
            <>
              <MdLiveHelp />
              e-Signing Process Explain
            </>
          }
          onClick={() => this.setState({ showEsigningTipsModal: true })}
        />
        {eProcessContentId === 1 && this.renderPendingProcessContent()}
        {eProcessContentId === 2 && this.renderInitiatedProcessContent()}
        {(onLoadAuthorisation || onLoadParty) && <LoadingModal />}

        {/* ConfirmationModal */}
        {this.renderModalContent()}
        {this.state.showEsigningTipsModal && (
          <ESigningTips
            onClose={() => this.setState({ showEsigningTipsModal: false })}
          />
        )}
        {onLoadDraftEmail && <LoadingModal />}
      </>
    );
  };
}

export default EprocessContent;
