import React, { Component } from "react";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      menuURL: "",
    };

    load = (param) => this.setState({ loading: param });

    getMenu = () =>
      Get(`/cafes/jiqi`, this.getMenuSuccess, this.getMenuError, this.load);
    getMenuSuccess = (payload) => this.setState({ menuURL: payload.order_url });
    getMenuError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            menuURL={this.state.menuURL}
            onLoadJIQICafe={this.state.loading}
            getMenu={this.getMenu}
          />
        </>
      );
    };
  }

  return WithHOC;
};

export default HOC;
