import Axios from "axios";

import { IQI_DRIVE_GET_DIRECTORY, STORE_IQI_DRIVE_DATA } from "./type";
import {
  beginAjaxCallIQIDrive,
  ajaxCallErrorDrive,
  ajaxCallSuccessDrive,
} from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export const getDirectory = (param) => (dispatch) => {
  const tokenName = window.location.href.includes("/admin-impersonate")
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const token = getItem(tokenName);
  Axios.defaults.headers = {
    common: {
      Authorization: `JWT ${token}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  dispatch(beginAjaxCallIQIDrive());
  Axios.get(`${getDomainURL()}/nextcloud/directories${param ? param : ""}`)
    .then((response) => {
      dispatch(getDirectorySuccess(response.data));
      dispatch(ajaxCallSuccessDrive());
    })
    .catch((error) => {
      dispatch(getDirectoryError({}));
      dispatch(ajaxCallErrorDrive(error));
    });
};

const getDirectorySuccess = (payload) => ({
  type: IQI_DRIVE_GET_DIRECTORY,
  payload,
});
const getDirectoryError = () => ({
  type: IQI_DRIVE_GET_DIRECTORY,
  payload: "",
});

export const storeIQIDriveData = (payload) => ({
  type: STORE_IQI_DRIVE_DATA,
  payload,
});
