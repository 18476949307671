import React, { Component } from "react";
import _ from "lodash";

import "bootstrap/dist/css/bootstrap.css";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { statusColor } from "dictionary/subsales";
import { numberWithCommas } from "utils/thousandSeparator";
import { getColorBadge } from "dictionary/badgeColor";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

class Content extends Component {
  state = {
    radioChecked: false,
  };

  renderTableContentGrid(
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
    styling,
    rowData,
  ) {
    const { booleanColumn, thousandSeparatorColumn, secondLayerRow } =
      this.props;

    const contentProcess = (param) => {
      if (booleanColumn === columnValue) {
        if (content === 0) {
          return "No";
        } else {
          return "Yes";
        }
      } else {
        if (thousandSeparatorColumn) {
          if (thousandSeparatorColumn.indexOf(columnValue) > -1) {
            param = numberWithCommas(param);
          }
        }
        return param || "-";
      }
    };
    const renderHighlightColumn = (param) => {
      if (param) {
        if (param.columnValue === columnValue) {
          return "grid-top-object";
        }
      }
    };

    return (
      <>
        {index !== "Actions" &&
          columnValue !== "sub_sale_claim_submissions" &&
          columnValue !== "confirmation_form_number" && (
            <li
              key={index}
              className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
              style={{ width: columnWidth || "100%", ...styling }}
            >
              <span className="at-table-row__item-label">{columnLabel}</span>
              <p>{contentProcess(content)}</p>
            </li>
          )}
        {columnValue === "confirmation_form_number" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{ width: columnWidth || "100%", ...styling }}
          >
            <div>
              <span className="at-table-row__item-label">{columnLabel}</span>
              <p>{contentProcess(content)}</p>
              <DarkTooltip
                enterTouchDelay={50}
                classes={{ tooltip: "tooltip-arrow top" }}
                placement={"top"}
                title={"Unit ID"}
              >
                <span
                  className="badge badge-primary d-block mt-10 mb-10"
                  style={{ fontSize: "0.75rem", width: "max-content" }}
                >
                  {rowData.unit_id}
                </span>
              </DarkTooltip>
              <div>
                <span
                  className="badge color-white mt-2"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    backgroundColor: rowData["case_closed"]
                      ? "#FB404B"
                      : "#87CB16",
                  }}
                >
                  {rowData["case_closed"] ? "Close" : "Open"}
                </span>
              </div>
            </div>
          </li>
        )}
        {secondLayerRow &&
          typeof content === "object" &&
          columnValue !== "booking_date" && (
            <li
              className={`at-table-row__item at-table__${index}`}
              style={{ width: columnWidth || "100%", ...styling }}
            >
              <span className="at-table-row__item-label">{columnLabel}</span>
              <div
                className="at-nested_table-second_layer"
                style={{ width: "100%" }}
              >
                <div className="at-nested_table-content">
                  {content &&
                    content.map((item) => {
                      const { colorName = "" } =
                        _.find(statusColor, { value: item.status }) || {};

                      return (
                        <>
                          <div
                            className="d-flex align-items-center"
                            style={{ padding: "10px 5px 0px 5px" }}
                          >
                            <p style={{ fontWeight: 600, marginRight: 10 }}>
                              {item.type || ""}
                            </p>
                            <div
                              className="at-status_badge"
                              style={{
                                ...getColorBadge(colorName),
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.status}
                            </div>
                          </div>
                          <ul className={`at-nested_table-content_row`}>
                            {this.props.secondLayerHeaderData.map(
                              (i, index) => {
                                let objKey = Object.keys(item);
                                if (objKey.includes(i.value)) {
                                  return (
                                    <li
                                      key={index}
                                      className={`at-nested_table-row_item`}
                                      style={{
                                        width: i.width || "100%",
                                        ...i.contentStyle,
                                      }}
                                    >
                                      <span className="at-nested_table-item_label">
                                        {i.label}
                                      </span>
                                      <p>{contentProcess(item[i.value])}</p>
                                    </li>
                                  );
                                }
                              },
                            )}
                          </ul>
                        </>
                      );
                    })}
                </div>
              </div>
            </li>
          )}
      </>
    );
  }

  render = () => {
    const { headerData, actionColumn, rowItem } = this.props;
    return (
      <div className="at-table-row-cont">
        <div
          className="at-table__height-control"
          style={{ height: this.state.radioChecked ? "370px" : "initial" }}
        >
          <ul className="at-table-row grid-controller">
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                columnItem.value,
                rowItem[columnItem.value],
                columnItem.label,
                columnItem.width,
                columnItem.containerStyle,
                rowItem,
              );
            })}
            {actionColumn
              ? this.renderTableContentGrid("Actions", "Actions", rowItem)
              : ""}
          </ul>
        </div>
      </div>
    );
  };
}

export default Content;
