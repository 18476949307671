import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Grid, Select, MenuItem, FormHelperText } from "@material-ui/core";
import _ from "lodash";
import { compose } from "redux";
import { PulseLoader } from "react-spinners";
import { Highlighter } from "react-bootstrap-typeahead";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";
import ConfirmationModal from "components/Modal/confirmation";

import ConfirmationFormHOC from "../../actions/confirmationForm";
import PaperFormHOC from "../../actions/paperForm";
import AdminHOC from "../../actions/admin";
import AgentSearchHOC from "actions/agentSearch";
import { RepresentativeInfo } from "../../assets";
import "./index.scss";

const ClaimTypeOptions = [
  {
    id: 0,
    name: "Without Submission for now",
    value: 0,
    label: "Without Submission for now",
  },
  { id: 1, name: "Claim Commission", value: 1, label: "Claim Commission" },
  { id: 2, name: "Refund", value: 2, label: "Refund" },
  { id: 3, name: "Forfeit", value: 3, label: "Forfeit" },
  { id: 4, name: "Renewal", value: 4, label: "Renewal" },
  { id: 5, name: "Release", value: 5, label: "Release" },
  { id: 6, name: "Cancel Form", value: 6, label: "Cancel Form" },
  // { id: 7, name: 'Request Invoice', value: 7, label: 'Request Invoice' },
  // { id: 8, name: 'Request Official Receipt', value: 8, label: 'Request Official Receipt' },
  {
    id: 9,
    name: "Request TA Stamping",
    value: 9,
    label: "Request TA Stamping",
  },
];

const FormTypeLib = [
  { label: "Digital Form", value: "1", id: "formtype-digital-form" },
  { label: "Physical Form", value: "2", id: "formtype-physical-form" },
  { label: "Co-Agency Form", value: "3", id: "formtype-co-agency-form" },
  { label: "Renewal Form", value: "4", id: "formtype-renewal-form" },
];

const ClaimTypes = [
  { label: "Joint Claim", value: "joint", id: "joint-claim-type" },
  { label: "Separate Claim", value: "separate", id: "separate-claim-type" }
] 

const Type = [
  {
    id: "1",
    label: "Sale",
  },
  {
    id: "2",
    label: "Rental",
  },
];

const CreateFormOptions = [{ value: "2", label: "Generate new form number" }];

const AssignTo = [
  { label: "Auto Assign", value: 1 },
  { label: "Assign to me", value: 0 },
];

const typeDictionary = [
  { id: 1, label: "Residential" },
  { id: 2, label: "Commercial" },
  { id: 3, label: "Industrial" },
  { id: 4, label: "Agricultural" },
  { id: 5, label: "Unknown" },
];

const tenureTypeDictionary = [
  { id: 1, label: "Freehold" },
  { id: 3, label: "Leasehold" },
];

const CreateClaimForm = ({
  data,
  agentList,
  agencies,
  admins,
  paperForms,
  unclaimedConfirmationForms,
  onLoadSubsaleClaims,
  onLoadConfirmationForms,
  onLoadCreateClaim,
  onLoadAdmins,
  onLoadAgent,
  disableCreateButton,
  showSubmitConfirmation,

  onClose,
  postClaim,
  getAdmins,
  onChangeAgentHOC,
  onChangePaperFormHOC,
  onChangeClaimsHOC,
  onChangeConfirmationFormHOC,
  getAgentsBySearch,
  getUnclaimPaperForm,
  getUnclaimConfirmationForms,
}) => {
  const { role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const [subsales_type_id, onChangeTypeId] = useState("");
  const [selectedAgent, onChangeAgent] = useState({});
  const [createClaim, onChangeCreateClaim] = useState({
    form_type_id: "1",
    confirmation_form_number: "",
    representative_id: "",
    confirmation_form_id: "",
    submission_type_id: 0,
    agency_id: null,
    user_id: "",
    sub_sale_type_id: null,
    form_method_id: "1",
    ...(role === "Super Admin"
      ? {
          assignee_id: "",
        }
      : {
          assign_option_id: 1,
        })
  });
  const [selectedClaimType, onSelectClaimType] = useState("")

  const getPartyLabel = (representative_id) => {
    if(representative_id === 1) {
      return createClaim.sub_sale_type_id === "1" ? "Vendor" : "Landlord"
    } else {
      return createClaim.sub_sale_type_id === "1" ? "Purchaser" : "Tenant"
    }
  }
  const onChangeCreateField = (val, context) => {
    const nonPropertyFields = [
      "form_type_id",
      "agency_id",
      "sub_sale_type_id",
      "form_method_id",
      "submission_type_id",
      "assignee_id",
      "representative_id",
    ];

    let temp = _.cloneDeep(createClaim);

    temp[context] = val;

    if (context === "form_type_id") {
      temp.form_method_id = "2";
      temp.confirmation_form_id = "";
      temp.confirmation_form_number = "";
      temp.representative_id = "";
      temp.agency_id = "";
      temp.sub_sale_type_id = "";
      onSelectClaimType("")
      onChangeAgent({});

      if (val !== "1") {
        onChangeConfirmationFormHOC([], "unclaimedConfirmationForms");
      }
      if (val !== "2") {
        onChangePaperFormHOC([], "paperForms");
      }
    }
    if (context === "representative_id") {
      if (form_type_id === "1") {
        temp.confirmation_form_id = "";
        temp.confirmation_form_number = "";
      }
      onChangeConfirmationFormHOC([], "unclaimedConfirmationForms");
    }
    onChangeCreateClaim(temp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const {
    form_type_id,
    agency_id,
    sub_sale_type_id,
    submission_type_id,
    form_method_id,
    representative_id,
    confirmation_form_id,
  } = createClaim;

  let tempAgency = _.find(agencies, { id: agency_id });

  useEffect(() => {
    if (!_.isEmpty(selectedAgent)) {
      if (representative_id && form_type_id === "1") {
        getUnclaimConfirmationForms(selectedAgent.id, representative_id);
      } else if (form_type_id === "2") {
        getUnclaimPaperForm(selectedAgent.id);
      }
    }
  }, [representative_id, selectedAgent, form_type_id]);

  const onDisableCreate = useMemo(() => {
    const {
      agency_id,
      sub_sale_type_id,
      form_type_id,
      confirmation_form_id
    } = createClaim;
    let tmpDisabled = false;

    if (["1", "2"].indexOf(form_type_id) > -1) {
      tmpDisabled =
        !confirmation_form_id ||
        _.isEmpty(selectedAgent) ||
        !representative_id ||
        tmpDisabled;
    } else if (form_type_id === "3") {
      tmpDisabled =
        !representative_id ||
        _.isEmpty(selectedAgent) ||
        !agency_id ||
        !sub_sale_type_id ||
        tmpDisabled;
      tmpDisabled = !`${submission_type_id}` || tmpDisabled;
    } else if (form_type_id === "4") {
      tmpDisabled = _.isEmpty(selectedAgent) || tmpDisabled;
    }

    if (role === "Admin" && submission_type_id !== 0) {
      tmpDisabled = !`${createClaim.assign_option_id}` || tmpDisabled;
    }
    if (role === "Super Admin" && submission_type_id !== 0) {
      tmpDisabled = !`${createClaim.assignee_id}` || tmpDisabled;
    }
    return tmpDisabled;
  }, [createClaim, selectedAgent]);

  let tmpShowSelectRepresentative = false
  if(!(form_type_id === "3" && form_method_id === "2")) {
    if(["1", "2"].indexOf(form_type_id) > -1) {
      tmpShowSelectRepresentative = selectedClaimType === "separate"
    } else if(["4"].indexOf(form_type_id) > -1) {
      tmpShowSelectRepresentative = false
    } else {
      tmpShowSelectRepresentative = true
    }
  }

  const getRepresentativePartyName = (representative_id, type_id) => {
    switch(type_id) {
      case 1:
        return representative_id === 1 ? "Vendor" : "Purchaser";
      case 2:
        return representative_id === 1 ? "Landlord" : "Tenant";
      default:
        return "N/A"
    }
  }

  return (
    <CustomCard
      className={"h-100"}
      containerStyle={{ background: "#F3F4F6" }}
      cardContent={
        <>
          <div className="at-card__header">
            <AtlasCloseButton
              containerClass={"btn-close-create-claim"}
              onClick={onClose}
            />
            <h4 className="at-card__title p-0">{"New Claim Form"}</h4>
          </div>
          <div style={{ minHeight: "60vh" }}>
            <div className="at-profile__container grid-control grid_gap-x-2">
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Select Form Type"}</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={form_type_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) => onChangeCreateField(val, "form_type_id")}
                  options={FormTypeLib}
                />
              </section>
              {
                ["1", "2"].indexOf(form_type_id) > -1 && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Select Claim Type"}</h2>
                    <AtlasRadioGroup
                      horizontal={true}
                      checkedValue={selectedClaimType}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) => {
                        onSelectClaimType(val)
                        if(val === "joint") {
                          onChangeCreateField(3, "representative_id")
                        } else {
                          onChangeCreateField("", "representative_id")
                        }
                      }}
                      options={ClaimTypes}
                    />
                  </section>
                ) 
              }
              {
                tmpShowSelectRepresentative && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Select Representative"}</h2>
                    <AtlasRadioGroup
                      horizontal={true}
                      checkedValue={representative_id}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) => {
                        onChangeCreateField(val, "representative_id");
                      }}
                      options={RepresentativeInfo(false)}
                    />
                  </section>
                )}
              <section className="grid-full-col">
                <div className="d-flex">
                  <h2 className="at-form-input__title mr-10">
                    Select an Agent
                  </h2>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </div>
                <CustomTypeahead
                  required
                  containerClass={"responsive"}
                  showClearButton
                  disabled={onLoadAgent}
                  typeaheadId={"internal_agent"}
                  options={agentList || []}
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  selectedValue={
                    !_.isEmpty(selectedAgent) ? [selectedAgent] : []
                  }
                  labelKey={"full_name"}
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  onSearch={(val) => {
                    if (form_type_id === "1") {
                      onChangeConfirmationFormHOC([], "unclaimedConfirmationForms");
                    }
                    if (form_type_id === "2") {
                      onChangePaperFormHOC([], "paperForms");
                    }
                    onChangeCreateField("", "confirmation_form_id");
                    onChangeAgent({});
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) => {
                    if (val && val.length) {
                      onChangeAgent(val[0]);
                      onChangeCreateField("", "confirmation_form_id");
                    }
                  }}
                  onClear={() => {
                    if (form_type_id === "1") {
                      onChangeConfirmationFormHOC([], "unclaimedConfirmationForms");
                    }
                    if (form_type_id === "2") {
                      onChangePaperFormHOC([], "paperForms");
                    }
                    onChangeCreateField("", "confirmation_form_id");
                    onChangeAgent({});
                  }}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                />
              </section>
              {form_type_id === "1" && (
                <section className="grid-full-col">
                  <h2 className="at-form-input__title" required>
                    {"Form Number"}
                  </h2>
                  <CustomTypeahead
                    typeaheadId={"confirmation_form_number"}
                    options={unclaimedConfirmationForms}
                    selectedValue={_.find(unclaimedConfirmationForms, { id: confirmation_form_id })
                      ? [_.find(unclaimedConfirmationForms, { id: confirmation_form_id })]
                      : []}
                    labelKey={"transaction_number"}
                    onSelect={(val) => {
                      const value = val?.[0]
                      let tmp = _.cloneDeep(createClaim);
                      tmp.confirmation_form_number = value?.transaction_number ?? "";
                      tmp.confirmation_form_id = value?.id ?? "";
                      onChangeTypeId(value?.type_id ?? "")
                      onChangeCreateClaim(tmp);
                    }}
                    filterBy={["transaction_number", "property_address", "owner", "buyer"]}
                    helpText={"e.g. Form Number, Property Address, Owner, Buyer"}
                    childrenHead={(rowItem, props) => (
                      <div>
                        <p className="at-form_number-typeahead-head">{rowItem?.transaction_number}</p>
                        <div className="at-form_number-typeahead-highlight">
                          <p className="at-form_number-typeahead-highlight-address"><Highlighter search={props.text}>{rowItem?.property_address || "-"}</Highlighter></p>
                          <p className="at-form_number-typeahead-highlight-party">
                            <span className="at-form_number-typeahead-highlight-owner"><Highlighter search={props.text}>{`${rowItem.type_id === 1 ? "Vendor" : "Landlord"}: ${rowItem?.owner || "-"}`}</Highlighter></span>
                            <span className="at-form_number-typeahead-highlight-buyer"><Highlighter search={props.text}>{`${rowItem.type_id === 1 ? "Purchaser" : "Tenant"}: ${rowItem?.buyer || "-"}`}</Highlighter></span>
                          </p>
                        </div>                      
                      </div>
                    )}
                    highlighterData={[]}
                  />
                </section>
              )}
              {(form_type_id === "2" ||
                (["3", "4"].indexOf(form_type_id) !== -1 &&
                  form_method_id === "1")) && (
                <section className="grid-full-col">
                  <h2 className="at-form-input__title" required>
                    {"Form Number"}
                  </h2>
                  <CustomTypeahead
                    typeaheadId={"confirmation_form_number"}
                    options={paperForms}
                    selectedValue={_.find(paperForms, { id: confirmation_form_id })
                      ? [_.find(paperForms, { id: confirmation_form_id })]
                      : []}
                    labelKey={"tracking_number"}
                    onSelect={(val) => {
                      const value = val?.[0]
                      let tmp = _.cloneDeep(createClaim);
                      tmp.confirmation_form_number = value?.tracking_number ?? "";
                      tmp.confirmation_form_id = value?.id ?? "";
                      onChangeTypeId(value?.type_id ?? "")
                      onChangeCreateClaim(tmp);
                    }}
                    filterBy={["tracking_number"]}
                    childrenHead={(rowItem) => <p>{rowItem.tracking_number}</p>}
                  />
                </section>
              )}
              {(form_type_id === "3" && form_method_id === "2") && (
                <>
                  <section className="grid-full-col">
                    <h4 className="at-form-input__title">Choose an Agency</h4>
                    <CustomTypeahead
                      required
                      showClearButton
                      footerStyle={{ marginBottom: 0 }}
                      containerClass="responsive"
                      typeaheadId={"agencies"}
                      options={agencies}
                      selectedValue={tempAgency ? [tempAgency] : []}
                      labelKey={"label"}
                      filterBy={["label"]}
                      onSelect={(val) => {
                        if (val && val.length > 0) {
                          onChangeCreateField(val[0].id, "agency_id");
                        }
                      }}
                      onSearch={(val) => onChangeCreateField(val, "agency_id")}
                      onClear={() => onChangeCreateField("", "agency_id")}
                      childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                      highlighterData={[
                        (option) =>
                          `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
                        <br />,
                        (option) => option.address,
                      ]}
                    />
                  </section>
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Select Co-agency Representative"}</h2>
                    <AtlasRadioGroup
                      horizontal={true}
                      checkedValue={representative_id}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) => {
                        onChangeCreateField(val, "representative_id");
                      }}
                      options={RepresentativeInfo(false)}
                    />
                    <FormHelperText>Selecting Co-agency Representation will result in Atlas choosing the opposing party for your claim.</FormHelperText>
                  </section>
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Type"}</h2>
                    <AtlasRadioGroup
                      valueKey={"id"}
                      horizontal={true}
                      checkedValue={sub_sale_type_id}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) =>
                        onChangeCreateField(val, "sub_sale_type_id")
                      }
                      options={Type}
                    />
                  </section>
                </>
              )}
              <section className="grid-full-col">
                <hr className="mt-0" />
                {form_type_id === "1" && (
                  <>
                    <h5 className="mt-1">
                      <b>Where can I find the Digital Form Number?</b>
                    </h5>
                    <div className="mt-2 form-admin-description-img"></div>
                    <p className="mt-10">
                      The form number can be found on the top right corner of
                      your <b>Letter of confirmation</b>.
                    </p>
                  </>
                )}
                <p className="mt-20">
                  <b>Gentle Reminder:</b>
                </p>
                <p>
                  1. Choosing the wrong form number can delay the progress of
                  submission approval.{" "}
                </p>
                <p>
                  2. If you are unable to find the form number on the list and
                  you are sure you have not yet submitted that confirmation form
                  number for claim before, please contact your branch admin for
                  assistance.
                </p>
              </section>
              <section className="grid-full-col">
                <hr />
                <h2 className="at-form-input__title">{"Submission Type"}</h2>
                <Grid container spacing={16} style={{ marginTop: 0 }}>
                  {ClaimTypeOptions.map((item) => (
                    <Grid
                      key={item.id}
                      item
                      md={4}
                      xs={12}
                      style={{ padding: "0px 8px" }}
                    >
                      <input
                        className="at-radio__input"
                        type="radio"
                        key={item.id}
                        id={item.value}
                        value={item.value}
                        checked={item.value === submission_type_id}
                        readOnly={true}
                      />
                      <label
                        className="at-radio__label m-0"
                        onClick={() => {
                          let temp = _.cloneDeep(createClaim);
                          temp.submission_type_id = item.value;
                          if (item.value === "0") {
                            if (role === "Super Admin") {
                              temp.assignee_id = "";
                            } else if (role === "Admin") {
                              temp.assign_option_id = "";
                            }
                          }
                          if (item.value !== 0) {
                            getAdmins(item.value);
                          }
                          onChangeCreateClaim(temp);
                        }}>
                        <div className="at-radio__checker"></div>
                        <p className="at-radio__content">{item.label}</p>
                      </label>
                    </Grid>
                  ))}
                </Grid>
              </section>
              <section className="grid-full-col">
                {role === "Super Admin" && submission_type_id !== 0 && (
                  <>
                    <h2 className="at-form-input__title">{`Assign Admin`}</h2>
                    <Select
                      required
                      value={createClaim.assignee_id}
                      onChange={(e) =>
                        onChangeCreateField(e.target.value, "assignee_id")
                      }
                    >
                      {admins &&
                        admins.length > 0 &&
                        admins.map((item) => {
                          return (
                            <MenuItem className="d-flex align-items-center" key={item.id} value={item.id}>
                              <span
                                className="at-submission__online-status"
                                style={{
                                  backgroundColor:
                                    item.online_status === 1 ? "green" : "red",
                                }}
                              ></span>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </>
                )}
                {role === "Admin" && submission_type_id !== 0 && (
                  <AtlasRadioGroup
                    horizontal={true}
                    checkedValue={createClaim.assign_option_id}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) =>
                      onChangeCreateField(Number(val), "assign_option_id")
                    }
                    options={AssignTo}
                  />
                )}
              </section>
            </div>
          </div>
          <div className="d-flex mt-3">
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={"New Claim Form"}
              disabled={disableCreateButton || onDisableCreate}
              onClick={() => {
                if(createClaim.form_type_id === "4") {
                  postClaim({
                    ...createClaim,
                    user_id: !_.isEmpty(selectedAgent) ? selectedAgent.id : null
                  });
                } else {
                  onChangeClaimsHOC("showSubmitConfirmation", true)
                }
              }}
            />
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary ml-2"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
          {/* Confirmation Dialog for CoAgency Form */}
          <ConfirmationModal
            mode={"info"}
            open={showSubmitConfirmation && createClaim.form_type_id === "3"}
            title={ "Co-Agency Claim Form" }
            message={
              <div>
                <p className="text-left fs-2">Based on your selection, please confirm if the following is correct for this case:</p>
                <div className="d-flex justify-content-center mt-3">
                  <ul style={{listStyleType: "disc", paddingLeft: 20}}>
                    <li className="text-left fs-2 mb-1">Transaction Type: {createClaim?.sub_sale_type_id === "1" ? "Sale" : "Rental"}</li>
                    <li className="text-left fs-2 mb-1">Co-Agency: {getPartyLabel(createClaim.representative_id)}</li>
                    <li className="text-left fs-2 mb-1">IQI (you): {getPartyLabel(createClaim.representative_id === 1 ? 2 : 1)}</li>
                  </ul>
                </div>
              </div>                         
            }
            loading={onLoadCreateClaim}
            positiveText={"Confirm"}
            positiveAction={() => {
              postClaim({
                ...createClaim,
                user_id: !_.isEmpty(selectedAgent) ? selectedAgent.id : null
              });
            }}
            negativeText={"Cancel"}
            negativeAction={() =>
              onChangeClaimsHOC("showSubmitConfirmation", false)
            }
          />
          {/* Confirmation Dialog for Digital Form and Physical Form */}
          <ConfirmationModal
            mode={"info"}
            open={showSubmitConfirmation && ["1", "2"].indexOf(createClaim.form_type_id) > -1}
            title={ "Closing Deal" }
            message={
              [1, 2].indexOf(createClaim.representative_id) > -1
                ? (
                  <div>
                    <p className="text-left fs-2">
                      You’ve chosen Separate Claim. <b>This means you’ll be managing the claim for your { getRepresentativePartyName(createClaim.representative_id, subsales_type_id) }.</b> By choosing this:
                    </p>
                    <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                      <li>You can only submit and manage claims for <b>your own representing client.</b></li>
                      <li>You won’t be able to request anything on behalf of the other party involved.(Request Invoice/Official Receipt, etc.)</li>
                    </ul>
                    <p className="text-left fs-2 mb-2">Not sure? Click Back and choose “Joint Claim” if you want to manage claims for both parties.</p>
                    <p className="fs-2">Are you sure you want to proceed with Separate Claim?</p>
                  </div>
                )
                : (
                  <div>
                    <p className="text-left fs-2">You’ve chosen Joint Claim. This means you’ll manage the submissions for both parties, which can involve:</p>
                    <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                      <li><b>You represent both clients.</b></li>
                      <li><b>Engaging in a Co-Agent Deal:{" "}</b>partnering with another agent (internal or external) and are responsible for submitting and managing the claim on behalf of both parties.</li>
                      <li>In this option, you are able to request documents like invoices or receipts on behalf of both parties.</li>
                    </ul>
                    <p className="fs-2">Are you sure you want to proceed?</p>
                  </div>
                )           
            }
            loading={onLoadCreateClaim}
            positiveText={"Confirm"}
            positiveAction={() => {
              postClaim({
                ...createClaim,
                user_id: !_.isEmpty(selectedAgent) ? selectedAgent.id : null
              });
            }}
            negativeText={"Cancel"}
            negativeAction={() =>
              onChangeClaimsHOC("showSubmitConfirmation", false)
            }
          />
          {(onLoadConfirmationForms ||
            onLoadCreateClaim ||
            onLoadAdmins ||
            onLoadSubsaleClaims) && <LoadingModal />}
        </>
      }
    />
  );
};

export default compose(
  AdminHOC,
  ConfirmationFormHOC,
  PaperFormHOC,
  AgentSearchHOC,
)(CreateClaimForm);
