import React from "react";

export default function Empty({ totalRecommendation, onClick, refProps }) {
  return (
    <>
      <div
        ref={refProps}
        className="at-recommendation-empty-card"
        onClick={() => onClick()}
      >
        <h4>{totalRecommendation}</h4>
        <span>Click to view all recommendations.</span>
      </div>
    </>
  );
}
