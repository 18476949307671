import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import base64ToDownloadUrl from "utils/base64ToDownloadUrl";

const getType = (type_id) => {
  switch (type_id) {
    case 1:
      return "Official Receipt";
    case 2:
      return "Invoice";
    case 3:
      return "Manual Official Receipt";
    case 4:
      return "Manual Invoice";
    default:
      return "N/A";
  }
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showUploadModal: false,
      showDeleteConfirmation: false,

      docsFinance: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeFinanceDocsHOC = (val, context) =>
      this.setState({ [context]: val });

    getFinanceDocs = (id) =>
      Get(
        `/sub_sale_claims/${id}/finance_documents`,
        this.getFinanceDocsSuccess,
        this.getFinanceDocsError,
        this.load,
      );
    getFinanceDocsSuccess = (payload) =>
      this.setState({
        docsFinance: [
          ...payload.finance_documents.map((item) => ({
            ...item,
            url: item.document_url,
            doc_type: "finance doc",
          })),
          ...payload.pv_documents.map((item) => ({
            ...item,
            url: base64ToDownloadUrl(item.file),
            doc_type: "pv doc",
          })),
        ],
      });
    getFinanceDocsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            title={this.state.title}
            document_file_name={this.state.document_file_name}
            document={this.state.document}
            docsFinance={this.state.docsFinance}
            onLoadDocsFinance={this.state.loading}
            showUploadModal={this.state.showUploadModal}
            selectedDeleteDoc={this.state.selectedDeleteDoc}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            getFinanceDocs={this.getFinanceDocs}
            onChangeFinanceDocsHOC={this.onChangeFinanceDocsHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
