import { Paper, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

import withEmailLists from "../actions/lists";

import { getColorBadge } from "dictionary/badgeColor";
import { statusOptions } from "../assets";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const Summary = ({
  selectedEmailCampaign,
  selectedEmailList,
  getSelectedEmailList,
}) => {
  const [emailLists, setEmailLists] = useState([]);

  const {
    id,
    status,
    subject,
    sender_name,
    sender_email,
    scheduled_at,
    email_list_ids,
    recipient_count,
    processed_count,
    deferred_count,
    delivered_count,
    dropped_count,
    bounced_count,
  } = selectedEmailCampaign;

  useEffect(() => {
    setEmailLists([]);
  }, []);

  useEffect(() => {
    if (email_list_ids && email_list_ids.length > 0) {
      email_list_ids.forEach(getSelectedEmailList);
    }
  }, [email_list_ids]);

  useEffect(() => {
    const { id, name } = selectedEmailList;

    if (
      selectedEmailList.hasOwnProperty("id") &&
      !emailLists.some((list) => list.id === id)
    ) {
      setEmailLists([...emailLists, { id, name }]);
    }
  }, [selectedEmailList, emailLists]);

  const getStatusProperty = (key) =>
    statusOptions.find((item) => item.value === status)?.[key];

  return (
    <div className={"at-email-campaigns__preview-summary"}>
      <div className={"d-flex justify-content-around flex-column flex-md-row"}>
        <div className="col-md-4">
          <Paper className={"at-email-campaigns__summary-item px-3 py-3"} elevation={0}>
            <div className={"col-md-12 mb-4 mb-md-0"}>
              <div className={"form-group"}>
                <label>Status</label>
                <span
                  className={`at-status_badge`}
                  style={{ ...getColorBadge(getStatusProperty("colorName")) }}
                >
                  {getStatusProperty("label")}
                </span>
              </div>
              <div className={"form-group"}>
                <label>Subject</label>
                <p>{subject}</p>
              </div>

              <div className={"form-group"}>
                <label>From</label>
                <p>{sender_name}</p>
                <p>{sender_email}</p>
              </div>

              <div className={"form-group"}>
                <label>Reply to</label>
                <p>{sender_email}</p>
              </div>

              <div className={"form-group"}>
                <label>Included Lists</label>
                {emailLists && emailLists.length > 0 && (
                  <div className={"d-flex align-items-center"}>
                    <p>
                      {emailLists && emailLists.length} list
                      {emailLists && emailLists.length !== 1 ? "s" : ""}
                    </p>

                    <DarkTooltip
                      classes={{ tooltip: "tooltip-arrow top" }}
                      placement={"top"}
                      enterTouchDelay={50}
                      title={emailLists.map((list) => (
                        <p key={list.id}>{list.name}</p>
                      ))}
                    >
                      <FaInfoCircle
                        className={"ml-1"}
                        style={{ width: 21, height: 21, color: "#447DF7" }}
                      />
                    </DarkTooltip>
                  </div>
                )}
                {emailLists && emailLists.length === 0 && <p>N/A</p>}
              </div>

              <div className={"form-group"}>
                <label>Delivered on</label>
                {scheduled_at && (
                  <p>{moment(scheduled_at).format("dddd DD-MM-YYYY, HH:mm")}</p>
                )}
                {!scheduled_at && <p>N/A</p>}
              </div>

              <div className={"form-group mb-0"}>
                <label>Campaign Number</label>
                <p>{id}</p>
              </div>
            </div>
          </Paper>
        </div>

        <div className={"col-md-8 mt-4 mt-md-0 ml-md-2"}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <Paper className={"at-email-campaigns__summary-item mb-2 p-4 text-center"} elevation={0}>
                <div
                  className={"d-flex align-items-center mb-3"}
                  style={{ gap: 16 }}
                >
                  <p>0%</p>

                  <div
                    className={"progress mb-0 flex-fill"}
                    style={{ height: 8 }}
                  >
                    <div
                      className={"progress-bar bg-primary"}
                      role={"progressbar"}
                      style={{
                        width: `${recipient_count > 0
                          ? Math.round(
                            (processed_count / recipient_count) * 100,
                          )
                          : 0
                          }%`,
                      }}
                    ></div>
                  </div>

                  <p>100%</p>
                </div>

                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <h4 className={"mr-1"} >
                    <strong style={{ color: "#3B82F6" }}>{processed_count}</strong>
                  </h4>

                  <p className={"summary-item__disabled"}>
                    {recipient_count > 0
                      ? Math.round((processed_count / recipient_count) * 100)
                      : 0}
                    %
                  </p>
                </div>
                <label>Processed</label>
              </Paper>
            </div>

            <div className={"col-md-6"}>
              <Paper className={"at-email-campaigns__summary-item mb-2 p-4 text-center"} elevation={0}>
                <div
                  className={"d-flex align-items-center mb-3"}
                  style={{ gap: 16 }}
                >
                  <p>0%</p>

                  <div
                    className={"progress mb-0 flex-fill"}
                    style={{ height: 8 }}
                  >
                    <div
                      className={"progress-bar"}
                      role={"progressbar"}
                      style={{
                        width: `${recipient_count > 0
                          ? Math.round(
                            (deferred_count / recipient_count) * 100,
                          )
                          : 0
                          }%`,
                        backgroundColor: "#F79009",
                      }}
                    ></div>
                  </div>

                  <p>100%</p>
                </div>

                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <h4 className={"mr-1"}>
                    <strong style={{ color: "#F79009" }}>{deferred_count}</strong>
                  </h4>

                  <p className={"summary-item__disabled"}>
                    {recipient_count > 0
                      ? Math.round((deferred_count / recipient_count) * 100)
                      : 0}
                    %
                  </p>
                </div>

                <label>Deferred</label>
              </Paper>
            </div>
          </div>
          <div className={"row"}>
            <Paper className={"at-email-campaigns__summary-item p-4 ml-2 mr-2 w-100"} elevation={0}>
              <div>
                <div className={"form-row"}>
                  <div className={"col-md-3 col-6 mb-3 mb-md-0 d-flex flex-column justify-content-center align-items-center"}>
                    <h5 className={"mb-0"}>
                      <strong>{recipient_count}</strong>
                    </h5>

                    <label>Recipients</label>
                  </div>

                  <div className={"col-md-3 col-6 mb-3 mb-md-0 d-flex flex-column justify-content-center align-items-center"}>
                    <div className={"d-flex align-items-center"}>
                      <h5 className={"text-primary mr-1"}>
                        <strong style={{ color: "#12B76A" }}>{delivered_count}</strong>
                      </h5>

                      <p className={"summary-item__disabled"}>
                        {recipient_count > 0
                          ? Math.round((delivered_count / recipient_count) * 100)
                          : 0}
                        %
                      </p>
                    </div>

                    <label>Delivered</label>
                  </div>

                  <div className={"col-md-3 col-6 mb-3 mb-md-0 d-flex flex-column justify-content-center align-items-center"}>
                    <div className={"d-flex align-items-center"}>
                      <h5 className={"mr-1"} style={{ color: "#B54707" }}>
                        <strong style={{ color: "#F04438" }}>{dropped_count}</strong>
                      </h5>

                      <p className={"summary-item__disabled"}>
                        {recipient_count > 0
                          ? Math.round((dropped_count / recipient_count) * 100)
                          : 0}
                        %
                      </p>
                    </div>

                    <label>Dropped</label>
                  </div>

                  <div className={"col-md-3 col-6 mb-3 mb-md-0 d-flex flex-column justify-content-center align-items-center"}>
                    <div className={"d-flex align-items-center"}>
                      <h5 className={"text-danger mr-1"}>
                        <strong style={{ color: "#F04438" }}>{bounced_count}</strong>
                      </h5>

                      <p className={"summary-item__disabled"}>
                        {recipient_count > 0
                          ? Math.round((bounced_count / recipient_count) * 100)
                          : 0}
                        %
                      </p>
                    </div>

                    <label>Bounced</label>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withEmailLists(Summary);
