import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";

import DayGrid from "./dayGrid";

class MonthGrid extends Component {
  state = {
    arrayCurrentMonth: [],
  };

  componentDidMount = () => {
    this.setArrayCurrentMonth();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setArrayCurrentMonth();
    }
  };

  setArrayCurrentMonth = () => {
    let tmpArrayCurrentMonth = [];
    let endDateOfCurrentMonth = this.props.currentDisplayMonth
      .endOf("month")
      .date();

    for (let i = 1; i <= endDateOfCurrentMonth; i++) {
      tmpArrayCurrentMonth.push({
        date: i,
        month: this.props.currentDisplayMonth.month() + 1,
        year: this.props.currentDisplayMonth.year(),
        fulldate: Moment()
          .date(i)
          .month(this.props.currentDisplayMonth.month())
          .year(this.props.currentDisplayMonth.year()),
        type: this.checkRenderDateTimeline(
          Moment()
            .date(i)
            .month(this.props.currentDisplayMonth.month())
            .year(this.props.currentDisplayMonth.year()),
        ),
      });
    }

    this.setState({
      arrayCurrentMonth: tmpArrayCurrentMonth,
    });
  };

  checkRenderDateTimeline = (param) => {
    if (param.unix() < this.props.dateToday.startOf("day").unix()) {
      return "past";
    } else if (
      param.unix() >= this.props.dateToday.startOf("day").unix() &&
      param.unix() <= this.props.dateToday.endOf("day").unix()
    ) {
      return "today";
    } else if (param.unix() > this.props.dateToday.endOf("day").unix()) {
      return "future";
    }
  };

  onSelectEvent = (val) => {
    this.props.onClickEventDetails(val);
  };

  render = () => {
    return _.map(this.state.arrayCurrentMonth, (item, index) => (
      <DayGrid
        key={index}
        renderTooltip={this.props.renderTooltip}
        showDetailsDialog={this.props.showDetailsDialog}
        storeForArrangedEventData={this.props.storeForArrangedEventData}
        currentDisplayMonth={this.props.currentDisplayMonth}
        dateToday={this.props.dateToday}
        item={item}
        onClickEventDetails={(val) => this.onSelectEvent(val)}
        repeat={this.props.repeat}
      />
    ));
  };
}

export default MonthGrid;
