import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomFormInput from "components/Input/formInput";

const CreateForm = ({
  onLoadPresentation,

  onClickClose,
  onClickSubmit,
}) => {
  const [createFormData, onChangeFormData] = useState({
    title: "",
    expiry_date: null,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  return (
    <>
      <p className="fs-2 mt-10 mb-20" style={{ maxWidth: 800 }}>
        Enter a title for your presentation. This title will be used for your
        own reference only. Then, choose an automatic expiry date for the
        presentation and click on <strong> Create </strong> to create your
        presentation.
      </p>
      <form className="grid-control">
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            Title
          </h2>
          <CustomFormInput
            type="text"
            value={createFormData.title}
            required={true}
            onChangeValue={(val) => onChangeField(val, "title")}
            placeholder={"Enter your presentation title"}
          />
        </section>
        <section className="grid-full-col" style={{ marginBottom: 250 }}>
          <h2 className="at-form-input__title">Automatic Expiry:</h2>
          <div className="at-form-input">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              className="at-form-input__input"
              value={createFormData.expiry_date}
              onChange={(val) =>
                onChangeField(Moment(val).format("DD MMM YYYY"), "expiry_date")
              }
              placeholderText={"DD MMM YYYY"}
            />
          </div>
        </section>
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          disabled={_.values(createFormData).some(
            (item) => !item || item.length < 1,
          )}
          onClick={() => onClickSubmit(createFormData)}
          className={"btn-new btn-new--primary"}
        >
          Create
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClickClose()}
        >
          Cancel
        </button>
      </div>
      {onLoadPresentation && <LoadingModal />}
    </>
  );
};

export default CreateForm;
