import React, { Component } from "react";
import _ from "lodash";
import { Post } from "utils/axios";

import StatusModal from "components/Modal/status";

import { getTranslation } from "assets/translation";

const HOC = (WrappedComponent) => {
  class TemplateWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      notificationMessage: "",
      email: "",
      validEmail: false,
      error: null,

      cooldownTiming: 0,
      cooldownInterval: null,
      showSuccessMessage: false,
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    verificationTimeOutCounting = () => {
      if (this.state.cooldownTiming !== 0) {
        this.setState((prevState) => ({
          cooldownTiming: prevState.cooldownTiming - 1,
        }));
      } else {
        clearInterval(this.state.cooldownInterval);
      }
    };

    onStartCountDown = () => {
      let temp = setInterval(() => {
        this.verificationTimeOutCounting();
      }, 1000);

      this.setState({ cooldownInterval: temp });
    };

    onSubmitRecoveryAccount = (val, language) =>
      Post(
        `/passwords?locale=${language}`,
        val,
        this.onSubmitRecoveryAccountSuccess,
        this.onSubmitRecoveryAccountError,
        this.load,
      );
    onSubmitRecoveryAccountSuccess = (payload) => {
      this.setState(
        {
          showSuccessMessage: true,
          notificationMessage: payload.message,
          cooldownTiming: 5,
        },
        () => {
          this.onStartCountDown();
        },
      );
    };
    onSubmitRecoveryAccountError = (error) =>
      this.setState({
        notificationMessage: error,
      });

    onClickSubmitEmail = (language) => {
      if (this.state.validEmail) {
        this.onSubmitRecoveryAccount({ email: this.state.email }, language);
      } else {
        this.setState({
          notificationMessage:
            getTranslation("label.wrong_email", language)
        });
      }
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            cooldownTiming={this.state.cooldownTiming}
            error={this.state.error}
            onLoadForgetPassword={this.state.loading}
            email={this.state.email}
            validEmail={this.state.validEmail}
            showSuccessMessage={this.state.showSuccessMessage}
            notificationMessage={this.state.notificationMessage}
            onChangeHOC={this.onChangeHOC}
            onClickSubmitEmail={this.onClickSubmitEmail}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  return TemplateWrappedComponent;
};

export default HOC;
