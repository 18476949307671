import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Branch Name",
    value: "branch_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Quantity",
    value: "quantity_eq",
    type: "input",
    param: "",
  },
  {
    label: "Processed By",
    value: "admin_display_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "select",
    param: "",
    options: [
      {
        label: "Pending",
        value: "1",
      },
      {
        label: "Approved",
        value: "2",
      },
      {
        label: "Rejected",
        value: "3",
      },
    ],
  },
];

const ConfirmationFormRequestHOC = (WrappedComponent) => {
  class ConfirmationFormWrappedComponent extends Component {
    state = {
      confirmationRequests: [],
      confirmationRequestsPages: null,
      loading: false,
      showCreateRequestForm: false,

      searchParamsReqeust: searchParams,

      branch_id: '',
      reason: "",
      quantity: 0,
    };

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    closeFormDialog = () => {
      this.setState({ 
        showCreateRequestForm: false, 
        branch_id: '',
        reason: '',
        quantity: 0
      });
    }

    componentDidMount = () => {
      const { branch_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.setState({ branch_id: branch_id })
    }

    onChangeConfirmationRequestsHOC = (val, context) =>
      this.setState({ [context]: val });

    resetCreateValue = () => {
      const { branch_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.setState({
        branch_id: branch_id,
        reason: "",
        quantity: 0,
      });
    }

    getConfirmationRequests = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/forms/confirmation_form_requests?${search}page=${page}`,
        this.getConfirmationRequestsSuccess,
        this.getConfirmationRequestsError,
        this.load,
      );
    };
    getConfirmationRequestsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let data = {
            ...item,
            branch_name: item.branch ? item.branch.name : "",
            process_by: item.admin_display_name
              ? item.admin_display_name
              : "N/A",
          };
          tmp.push(data);
        });
      payload.data = tmp;
      this.setState({
        confirmationRequests: payload,
        confirmationRequestsPages: tmpTotalPages,
      });
    };
    getConfirmationRequestsError = (error) => requestError(error);

    postConfirmationRequest = (data) =>
      Post(
        `/forms/confirmation_form_requests`,
        data,
        this.postConfirmationRequestSuccess,
        this.postConfirmationRequestError,
        this.load,
      );
    postConfirmationRequestSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getConfirmationRequests(currentPage, searchParams);
      this.setState({ 
        showCreateRequestForm: false, 
        branch_id: '',
        reason: '',
        quantity: 0
      });
      requestSuccess("Request is sent successfully.");
    };
    postConfirmationRequestError = (error) => requestError(error);

    render = () => {
      // after creation need to clear state
      return (
        <>
          <WrappedComponent
            {...this.props}
            branch_id={this.state.branch_id}
            reason={this.state.reason}
            quantity={this.state.quantity}
            confirmationRequests={this.state.confirmationRequests}
            searchParamsReqeust={this.state.searchParamsReqeust}
            confirmationRequestsPages={this.state.confirmationRequestsPages}
            showCreateRequestForm={this.state.showCreateRequestForm}
            onChangeHOC={this.onChangeHOC}
            resetCreateValue={this.resetCreateValue}
            onChangeConfirmationRequestsHOC={
              this.onChangeConfirmationRequestsHOC
            }
            closeFormDialog={this.closeFormDialog}
            getConfirmationRequests={this.getConfirmationRequests}
            postConfirmationRequest={this.postConfirmationRequest}
            onLoadConfirmationFormRequest={this.state.loading}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    refreshToken,
  })(ConfirmationFormWrappedComponent);
};

export default ConfirmationFormRequestHOC;
