import React, { Component } from "react";
import { connect } from "react-redux";

import { requestSuccess, requestError } from "utils/requestHandler";
import { Get, Put, Post, Delete } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";

const Attachments = (WrappedComponent) => {
  class AttachmentHOC extends Component {
    state = {
      loading: false,
      showShareModal: false,
      showCreateModal: false,
      showEditModal: false,
      showConfirmDeleteDialog: false,
      selectedAttachment: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeAttachmentHOC = (value, context) =>
      this.setState({ [context]: value });

    getAttachment = (event_id) =>
      Get(
        `/admin/events/${event_id}/attachments`,
        this.getAttachmentSuccess,
        this.getAttachmentError,
        this.load,
      );
    getAttachmentSuccess = (payload) =>
      this.setState({
        selectedAttachment: payload,
        showEditModal: true,
      });
    getAttachmentError = (error) => requestError(error);

    createAttachment = (data) =>
      Post(
        `/admin/events/${this.props.selectedEventId}/attachments`,
        data,
        this.createAttachmentSuccess,
        this.createAttachmentError,
        this.load,
      );
    createAttachmentSuccess = (payload) => {
      this.props.getAttachments(this.props.selectedEventId);
      this.setState({ showCreateModal: false });
      requestSuccess("Attachment created successfully!");
    };
    createAttachmentError = (error) => requestError(error);

    updateAttachment = (data) =>
      Put(
        `/admin/events/${this.props.selectedEventId}/attachments/${this.state.selectedAttachment.id}`,
        data,
        this.updateAttachmentSuccess,
        this.updateAttachmentError,
        this.load,
      );
    updateAttachmentSuccess = (payload) => {
      this.props.getAttachments(this.props.selectedEventId);
      this.setState({ showEditModal: false });
      requestSuccess("Attachment updated successfully!");
    };
    updateAttachmentError = (error) => requestError(error);

    deleteAttachment = (id) =>
      Delete(
        `/admin/events/${this.props.selectedEventId}/attachments/${id}`,
        this.deleteAttachmentSuccess,
        this.deleteAttachmentError,
        this.load,
      );
    deleteAttachmentSuccess = (payload) => {
      this.props.getAttachments(this.props.selectedEventId);
      this.setState({ showConfirmDeleteDialog: false });
      requestSuccess("Attachment deleted successfully!");
    };
    deleteAttachmentError = (error) => requestError(error);

    regenerateQrCode = (id) =>
      Put(
        `/admin/events/${this.props.selectedEventId}/attachments/${id}/generate_qr_code`,
        {},
        this.regenerateQrCodeSuccess,
        this.regenerateQrCodeError,
        this.load,
      );
    regenerateQrCodeSuccess = (payload) => {
      this.setState({ selectedAttachment: payload });
      requestSuccess("QR generated successfully!");
      this.props.getAttachments(this.props.selectedEventId);
    };
    regenerateQrCodeError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showShareModal={this.state.showShareModal}
            showCreateModal={this.state.showCreateModal}
            showEditModal={this.state.showEditModal}
            showViewImage={this.state.showViewImage}
            showConfirmDeleteDialog={this.state.showConfirmDeleteDialog}
            selectedAttachment={this.state.selectedAttachment}
            onChangeAttachmentHOC={this.onChangeAttachmentHOC}
            createAttachment={this.createAttachment}
            getAttachment={this.getAttachment}
            regenerateQrCode={this.regenerateQrCode}
            updateAttachment={this.updateAttachment}
            deleteAttachment={this.deleteAttachment}
            onLoadAttachment={this.state.loading}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    refreshToken,
  })(AttachmentHOC);
};

export default Attachments;
