import React from "react";
import Moment from 'moment'
import { Radio } from '@material-ui/core'
import isEmptyValue from 'utils/isEmpty';
import { getColorBadge } from 'dictionary/badgeColor'

export const EventSearchParams = [
  {
    label: "Event Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Start Date from",
    value: "start_date_time_dategteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Start Date to",
    value: "start_date_time_datelteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Event Type",
    value: "type_id_eq",
    type: "radio",
    param: "",
    col: 6,
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Event" },
      { value: "2", label: "Training" },
    ],
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 6,
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Draft" },
      { value: "2", label: "Published" },
      { value: "3", label: "Cancelled" },
    ],
  },
  {
    label: "Training Type",
    value: "training_type_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { label: "Orientation", value: "1" },
      { label: "Project", value: "2" },
      { label: "Subsales", value: "3" },
      { label: "NCC", value: "4" },
      { label: "CDP", value: "6" },
      { label: "Digital Empowerment", value: "5" },
    ],
  },
];

export const TicketPricingFields = [
  {
    id: 0,
    label: "Pricing Title",
    value: "title",
    type: "text",
  },
  {
    id: 1,
    label: "Price",
    value: "price",
    type: "number",
  },
  {
    id: 2,
    label: "Transaction Start Date",
    value: "start_date",
    type: "date",
  },
  {
    id: 3,
    label: "Transaction End Date",
    value: "end_date",
    type: "date",
  },
  {
    id: 4,
    label: "Allow to joint tickets",
    value: "allowed_join_ticket",
    value_type: "boolean",
    type: "radio",
    options: [
      {
        id: 0,
        label: "Yes",
        value: true,
      },
      {
        id: 1,
        label: "No",
        value: false,
      },
    ],
  },
];

export const createTicketPricingLib = [
  {
    label: "Title",
    value: "title",
    type: "text",
    onDisabled: (data) => isEmptyValue(data["title"]),
    onShow: () => true,
  },
  {
    label: "Type",
    value: "type_id",
    type: "radio",
    value_type: "number",
    options: [
      { label: "General", value: 1 },
      { label: "VIP", value: 2 },
    ],
    onDisabled: (data) => isEmptyValue(data["type_id"]),
    onShow: () => true,
  },
  {
    label: "Joint Ticket",
    value: "is_joint_ticket",
    type: "radio",
    value_type: "boolean",
    options: [
      { id: 0, label: "Yes", value: true },
      { id: 1, label: "No", value: false },
    ],
    onDisabled: (data) => isEmptyValue(data["is_joint_ticket"]),
    onShow: () => true,
  },
  {
    label: "Is Seat Limited?",
    value: "is_seat_limited",
    type: "radio",
    value_type: "boolean",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    onDisabled: (data) => isEmptyValue(data["is_seat_limited"]),
    onShow: () => true,
  },
  {
    label: "Maximum Seat",
    value: "maximum_seat",
    type: "number",
    onDisabled: (data) => {
      if (data["is_seat_limited"]) {
        return isEmptyValue(data["maximum_seat"]);
      } else {
        return false;
      }
    },
    onShow: (data) => data.is_seat_limited,
  },
  {
    label: "Remarks",
    value: "remarks",
    type: "text",
    onDisabled: () => false,
    onShow: () => true,
  },
];

export const ticketTypeColumnData = [
  {
    header: "Title",
    accessor: "title",
    searchFlag: "title",
    columnStyle: { width: "70%" },
  },
  { 
    header: 'Ticket Type',
    searchFlag: 'type', 
    columnStyle: { width: '60%' },
    renderColumn: rowData => (
      <div
        className={ 'at-status_badge' }
        style={{ ...getColorBadge( rowData.type === 'General' ? 'Blue' : 'Yellow' ) }}>
        { rowData.type }
      </div>
    ),
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
  },
  { 
    header: 'Is Seat Limited?', 
    renderColumn: rowData => (
      <div
      className={ 'at-status_badge' }
      style={{ ...getColorBadge( rowData.is_seat_limited ? 'Green' : 'Yellow' ) }}>
        { rowData.is_seat_limited ? 'Yes' : 'No' }
      </div>
    ),
    searchFlag: 'is_seat_limited', 
    columnStyle: { width: '60%' },
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
  },
  {
    header: "Pricing Set",
    accessor: "ticket_pricings",
    searchFlag: "ticket_pricings",
    renderColumn: (rowData) =>
      rowData &&
      rowData.ticket_pricings.map((item) => (
        <p>{`${item.title || "N/A"} - RM ${item.price || "N/A"}`}</p>
      )),
    columnStyle: { width: "160%" },
  },
  {
    header: "Remarks",
    accessor: "remarks",
    searchFlag: "remarks",
    columnStyle: { width: "70%" },
  },
];

export const TicketPricingColumnData = [
  {
    header: "Pricing Title",
    accessor: "title",
    searchFlag: "title",
    columnStyle: { width: "100%" },
  },
  {
    header: "Price",
    accessor: "price",
    searchFlag: "price",
    renderColumn: (rowData) => `RM ${rowData.price || "N/A"}`,
    columnStyle: { width: "70%" },
  },
  {
    header: "Start Date",
    renderColumn: (rowData) => (
      <>
        {rowData.start_date
          ? Moment(rowData.start_date, "YYYY-MM-DD").format("DD MMM YYYY")
          : "N/A"}
      </>
    ),
    searchFlag: "start_date",
    columnStyle: { width: "70%" },
  },
  {
    header: "End Date",
    renderColumn: (rowData) => (
      <>
        {rowData.end_date
          ? Moment(rowData.end_date, "YYYY-MM-DD").format("DD MMM YYYY")
          : "N/A"}
      </>
    ),
    searchFlag: "end_date",
    columnStyle: { width: "70%" },
  },
  {
    header: "Allowed for Joint Ticket",
    renderColumn: (rowData) => (
      <>{rowData.allowed_join_ticket ? "Yes" : "No"}</>
    ),
    searchFlag: "allowed_join_tickete",
    columnStyle: { width: "70%" },
  },
];

export const JointTicketColumnData = [
  {
    header: "Main Ticket",
    accessor: "primary_ticket_title",
    searchFlag: "primary_ticket_title",
    columnStyle: { width: "100%" },
  },
  {
    header: "Event of Secondary Ticket",
    accessor: "secondary_ticket_event_name",
    searchFlag: "secondary_ticket_event_name",
    columnStyle: { width: "100%" },
  },
  {
    header: "Secondary Ticket",
    accessor: "secondary_ticket_title",
    searchFlag: "secondary_ticket_title",
    columnStyle: { width: "100%" },
  },
];

export const paymentMethodOptions = [
  { label: "None", value: 0 },
  { label: "Payment Gateway", value: 1 },
  { label: "Bank In", value: 2 },
  { label: "Cash", value: 3 },
  { label: "Complimentary", value: 4 },
];

export const approveOptions = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

export const statusOptions = [
  { label: "Pending", value: 0 },
  { label: "Absent", value: 1 },
  { label: "Present", value: 2 },
  { label: "Resit", value: 3 },
];

export const addAttendeeFieldData = (event) => [
  {
    label: "Select An Agent",
    value: "agent",
    type: "typeahead",
  },
  ...(event.is_payment_needed
    ? [
        {
          label: "Payment Method",
          value: "payment_method_id",
          type: "radio",
          options: paymentMethodOptions,
        },
        {
          label: "Ticket Type",
          value: "ticket_pricing_id",
          type: "table",
          column: (props) => [
            {
              header: "",
              columnClass: "at-table--checkbox-column",
              columnStyle: { width: "10%", gridColumn: "1/-1" },
              renderColumn: (rowData) => (
                <Radio
                  color={"primary"}
                  value={`${rowData.id}`}
                  name={rowData.title}
                  checked={rowData.id === props.formData.ticket_pricing_id}
                  onChange={(e) =>
                    props.onChangeFieldData(e.target.value, "ticket_pricing_id")
                  }
                />
              ),
            },
            {
              header: "Pricing Title",
              renderColumn: rowData => (
                <div className="at-attendee-ticket__radio-mobile">
                  <Radio
                    color={"primary"}
                    value={`${rowData.id}`}
                    className="at-table--checkbox-mobile"
                    checked={rowData.id === props.formData.ticket_pricing_id}
                    onChange={(e) =>
                      props.onChangeFieldData(e.target.value, "ticket_pricing_id")
                    }
                  />
                  {rowData.title}
                </div>
              )
            },
            {
              header: "Price",
              renderColumn: (rowData) => `RM ${rowData.price}`,
              columnClass: "align-items-center",
            },
            {
              header: "Start Date",
              accessor: "start_date",
              columnClass: "align-items-center",
            },
            {
              header: "End Date",
              accessor: "end_date",
              columnClass: "align-items-center",
            },
          ],
        },
      ]
    : []),
  {
    label: "Approval Status",
    value: "is_approved",
    type: "radio",
    options: approveOptions,
  },
  {
    label: "Status",
    value: "status_id",
    type: "radio",
    options: statusOptions,
  },
];
