export default function () {
  if (window.location.host.indexOf("localhost") !== -1) {
    return "wss://apitest3.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging5.iqiglobal.com") !== -1
  ) {
    return "wss://apitest2.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging4.iqiglobal.com") !== -1
  ) {
    return "wss://apitest2.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging3.iqiglobal.com") !== -1
  ) {
    return "wss://apistaging.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging2.iqiglobal.com") !== -1
  ) {
    return "wss://apitest3.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging1.iqiglobal.com") !== -1
  ) {
    return "wss://atlastest.iqiglobal.com";
  } else if (
    window.location.host.indexOf("atlasfestaging.iqiglobal.com") !== -1
  ) {
    return "wss://atlastest.iqiglobal.com";
  } else if (window.location.host.indexOf("atlas.iqiglobal.com") !== -1) {
    return "wss://api.iqiglobal.com";
  } else {
    return "wss://apitest2.iqiglobal.com";
  }
}
