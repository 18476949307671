import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      selectedAgent: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeAgentHOC = (val, context) => this.setState({ [context]: val });

    searchAgent = (mobile_contact_number) =>
      Post(
        `/agents/search`,
        { mobile_contact_number },
        this.searchAgentSuccess,
        this.searchAgentError,
        this.load,
      );
    searchAgentSuccess = (payload) => this.setState({ selectedAgent: payload });
    searchAgentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedAgent={this.state.selectedAgent}
            onLoadAgent={this.state.loading}
            searchAgent={this.searchAgent}
            onChangeAgentHOC={this.onChangeAgentHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
