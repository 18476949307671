import React, { useEffect, useState } from "react";
import Moment from "moment";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasTextarea from "components/Input/textarea";
import ConfirmationModal from "components/Modal/delete";

const SingleNote = ({
  confirmation_form_id,
  can_create,
  can_update,
  can_destroy,
  rowData,
  updateInternalNote,
  onChangeDeleteConfirmation,
}) => {
  const { id, note } = rowData;
  const [editedNote, onChangeNote] = useState(note);
  const [isEditing, onEdit] = useState(false);

  useEffect(() => {
    !isEditing && onChangeNote(note);
  }, [isEditing]);

  useEffect(() => {
    onEdit(false);
  }, [rowData]);

  return (
    <>
      <div className="at-form__content">
        <div className="d-flex flex-column" style={{ gridGap: 12 }}>
          <div>
            <p style={{ fontSize: 14, marginBottom: "12px", color: "#6B7280" }}>
              {Moment(rowData.updated_at).format("DD MMM YYYY hh:MMa")}
            </p>
            <div>
              {isEditing && (
                <div>
                  <AtlasTextarea
                    rows={5}
                    value={editedNote}
                    onChangeValue={(val) => onChangeNote(val)}
                    disabled={!can_create}
                  />
                  <div className="d-flex grid_gap-1 mt-3">
                    <CustomButton
                      className="btn-new btn-new--success"
                      children={"Update"}
                      onClick={() =>
                        updateInternalNote(editedNote, confirmation_form_id, id)
                      }/>
                    <CustomButton
                      className="btn-new btn-new--outline-secondary"
                      children={"Cancel"}
                      onClick={() => onEdit(false)}
                    />
                  </div>
                </div>
              )}
              {!isEditing && (
                <p className="fs-2" style={{ flex: "1 0 70%" }}>
                  {note}
                </p>
              )}
            </div>
          </div>
          {!isEditing && (
            <div className="d-flex grid_gap-1" style={{ flex: "1 0 30%" }}>
              {can_update && (
                <CustomButton
                  className="btn-new btn-new-sm fs-2"
                  style={{ padding: "6px 10px", color: "#1F2937" }}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-bootstrap-pencil-square"}
                        style={{ width: 20, height: 20, color: "#007BFF" }}
                      />
                      Edit
                    </>
                  }
                  onClick={() => onEdit(true)}
                />
              )}
              {can_destroy && (
                <CustomButton
                  className="btn-new btn-new-sm fs-2"
                  style={{ padding: "6px 10px", color: "#1F2937" }}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"svg-react-icons-trash"}
                        style={{ width: 20, height: 20, color: "red" }}
                      />
                      Delete
                    </>
                  }
                  onClick={() => onChangeDeleteConfirmation(true)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const InternalNotes = ({
  newNote,
  permissions,
  confirmation_form_id,
  noteList,
  showDeleteConfirmation,
  onLoadInternalNotes,
  onChangeInternalNotesHOC,
  submitInternalNote,
  updateInternalNote,
  deleteInternalNote,
}) => {
  const [toDeleteId, onChangeDeleteId] = useState("");
  const { can_create, can_update, can_destroy } = permissions;

  const renderNote = (rowData) => {
    return (
      <SingleNote
        key={rowData.id}
        confirmation_form_id={confirmation_form_id}
        rowData={rowData}
        can_create={can_create}
        can_update={can_update}
        can_destroy={can_destroy}
        updateInternalNote={updateInternalNote}
        onChangeDeleteConfirmation={(val) => {
          onChangeInternalNotesHOC(val, "showDeleteConfirmation");
          onChangeDeleteId(rowData.id);
        }}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="at-form__content">
          <AtlasTextarea
            rows={5}
            value={newNote}
            onChangeValue={(val) => onChangeInternalNotesHOC(val, "newNote")}
            disabled={!can_create}
          />
          {can_create && (
            <CustomButton
              disabled={!newNote}
              className="btn-new btn-new--primary mt-3"
              children={"Submit"}
              onClick={() => submitInternalNote(newNote, confirmation_form_id)}
            />
          )}
        </div>
        {noteList && noteList.map((item) => renderNote(item))}
      </div>
      <ConfirmationModal
        title={""}
        loading={onLoadInternalNotes}
        open={showDeleteConfirmation}
        message={`Are you sure you want to delete?`}
        positiveAction={() =>
          deleteInternalNote(confirmation_form_id, toDeleteId)
        }
        negativeAction={() =>
          onChangeInternalNotesHOC(false, "showDeleteConfirmation")
        }
      />
    </div>
  );
};

export default InternalNotes;
