/*
  SEC configurations
  =======================
  Current role available:
  Super Admin
  Admin
  Agent
  Associated Agent
  Personal Assistant
  =======================
  */
export const SecConfigData = [
  {
    role: "Super Admin",
    accessSidebar: [
      "Profile",
      "Dashboard",
      "Global Network",
      "Presentation Centre",
      "Project Centre",
      "Superadmin Sidemenu Data",
      "Administrator",
      "Marketing Centre",
      "Company Events",
      "IQI Stores",
      "IQI Drive",
    ],
    accessAdministratorSidemenu: [
      "Users Management",
      "Projects Management",
      "Training Management",
      "Valuations",
      "Global Network Management",
      "Teams Management",
      "Admin Roles Management",
      "Subsales Management",
      "Event & Training",
      "Tutorials",
      "New Agent Approvals",
      "IQI Academy",
    ],
    accessMarketPlaceComponent: [
      "Product Management",
      "Order Management",
      "Coupon Management",
      "Name Card Management",
    ],
    accessDashboardComponent: [
      {
        name: "Feedback",
        access: ["view", "update"],
      },
    ],
  },
  {
    role: "Admin",
    accessSidebar: [
      "Profile",
      "Dashboard",
      "Global Network",
      "Admin Sidemenu Data",
      "Presentation Centre",
      "Project Centre",
      "Administrator",
      "Marketing Centre",
      "Company Events",
      "IQI Stores",
      "IQI Drive",
    ],
    accessAdministratorSidemenu: [
      "Projects Management",
      "Subsales Management",
      "Event & Training",
      "Admin Roles Management",
      "Tutorials",
      "New Agent Approvals",
      "IQI Academy",
    ],
    accessMarketPlaceComponent: [
      "Product Management",
      "Order Management",
      "Coupon Management",
      "Name Card Management",
    ],
    accessDashboardComponent: [
      {
        name: "Feedback",
        access: ["view", "update"],
      },
    ],
  },
  {
    role: "Agent",
    accessSidebar: [
      "Profile",
      "Dashboard",
      "Global Network",
      "Project Centre",
      "Presentation Centre",
      "Team",
      "Personal",
      "Marketing Centre",
      "Training Centre",
      "Valuations",
      "Reports",
      "Company Events",
      "IQI Stores",
      "IQI Drive",
      "Tutorials",
    ],
    accessDashboardComponent: [
      {
        name: "Feedback",
        access: ["view", "create"],
      },
    ],
    accessMarketPlaceComponent: [
      "Products",
      "My Coupons",
      "My Cart",
      "Order History",
    ],
    accessReportComponent: [
      "Top Agent Group Sales - Projects",
      "Top Agent Group Sales - Subsales",
      "Top Agent Group Sales - Rental",
    ],
    accessTrainingCentreComponent: ["My Trainings", "IQI Academy"],
    accessValuationComponent: ["Real Estate/Corporate"],
    accessPersonalComponent: [
      "My Sales",
      "Payment Vouchers",
      "Forms",
      "Listing Centre",
      "Subsales Claims",
      "My Contacts",
      "Leads",
      "Order History",
      "My Coupons / Event Tickets",
      "New Project Sales",
    ],
    accessVPPersonalComponent: [
      "My Sales",
      "Payment Vouchers",
      "Forms",
      "Listing Centre",
      "Subsales Claims",
      "My Contacts",
      "My PA",
      "Leads",
      "New Project Sales",
    ],
    accessVPTeamComponent: [
      "Team Sales",
      "Team Members",
      "My Hierarchy",
      "Project Sales",
      "Team Performance",
      "Team Birthdays",
      "Recruitment Growth Target",
      "New Agent Approvals",
    ],
    accessTeamCompomponent: [
      "Team Sales",
      "Team Members",
      "My Hierarchy",
      "Project Sales",
      "Team Performance",
      "Team Birthdays",
      "Recruitment Growth Target",
      "New Agent Approvals",
    ],
  },
  {
    role: "Personal Assistant",
    accessSidebar: [
      "Profile",
      "Dashboard",
      "Global Network",
      "Presentation Centre",
      "Project Centre",
      "Team",
      "Personal",
      "Marketing Centre",
      "Training Centre",
      "Valuations",
      "Reports",
      "Company Events",
      "IQI Stores",
      "IQI Drive",
      "Tutorials",
      "Leads",
    ],
    accessMarketPlaceComponent: [
      "Products",
      "My Coupons",
      "My Cart",
      "Order History",
    ],
    accessReportComponent: [
      "Top Agent Group Sales - Projects",
      "Top Agent Group Sales - Subsales",
      "Top Agent Group Sales - Rental",
    ],
    accessValuationComponent: ["Real Estate/Corporate"],
  },
  {
    role: "Associated Agent",
    accessSidebar: [
      "Profile",
      "Dashboard",
      "Global Network",
      "Presentation Centre",
      "Project Centre",
      "Team",
      "Personal",
      "Marketing Centre",
      "Training Centre",
      "Valuations",
      "Reports",
      "Company Events",
      "IQI Stores",
      "IQI Drive",
      "Tutorials",
      "Leads",
    ],
    accessMarketPlaceComponent: [
      "Products",
      "My Coupons",
      "My Cart",
      "Order History",
    ],
    accessReportComponent: [
      "Top Agent Group Sales - Projects",
      "Top Agent Group Sales - Subsales",
      "Top Agent Group Sales - Rental",
    ],
    accessTrainingCentreComponent: ["My Trainings", "IQI Academy"],
    accessValuationComponent: ["Real Estate/Corporate"],
  },
];
