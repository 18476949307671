import React, { useEffect, useState } from "react";
import _ from "lodash";

import IOSButton from "components/Button/toggle";

const Settings = ({ selectedPermissionGroup, updatePermissionSettings }) => {
  const [permissionSettings, setPermissionSettings] = useState({
    project_centre_full_access: true,
    iqi_drive_full_access: true,
  });

  useEffect(() => {
    setPermissionSettings({
      project_centre_full_access:
        selectedPermissionGroup?.entity_settings[0]?.settings
          ?.project_centre_full_access ?? false,
      iqi_drive_full_access:
        selectedPermissionGroup?.entity_settings[0]?.settings
          ?.iqi_drive_full_access ?? false,
    });
  }, [selectedPermissionGroup]);

  const onClickToggle = (context) => {
    let tmp = _.cloneDeep(permissionSettings);
    tmp[context] = !tmp[context];
    setPermissionSettings(tmp);
    updatePermissionSettings(tmp);
  };

  return (
    selectedPermissionGroup?.entity_settings[0]?.settings && (
      <div className="d-flex flex-column g-3">
        <div className="d-flex align-items-center">
          <IOSButton
            className={{ root: "mt-2" }}
            currentState={!permissionSettings.iqi_drive_full_access}
            onToggleButton={() => onClickToggle("iqi_drive_full_access")}
          />
          <h2
            style={{
              fontSize: 14,
              marginLeft: 10,
              marginBottom: 10,
              fontWeight: 500,
            }}
          >
            IQI Drive Full Access Permission
          </h2>
        </div>
        <div className="d-flex align-items-center mt-2">
          <IOSButton
            className={{ root: "mt-2" }}
            currentState={!permissionSettings.project_centre_full_access}
            onToggleButton={() => onClickToggle("project_centre_full_access")}
          />
          <h2
            style={{
              fontSize: 14,
              marginLeft: 10,
              marginBottom: 10,
              fontWeight: 500,
            }}
          >
            Project Center Full Access Permission
          </h2>
        </div>
      </div>
    )
  );
};

export default Settings;
