import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

import { storeLastView } from "actions/lastView";

const searchParams = [
  {
    label: "Recipient",
    value: `recipient_cont`,
    type: "input",
    param: "",
  },
  {
    label: "Code",
    value: "code_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      searchParams: searchParams,
      verifications: [],
      verificationPage: null,
      showDetailsModal: false,
      selectedVerification: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeVerificationHOC = (val, context) =>
      this.setState({ [context]: val });

    getVerifications = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/otp_verifications?${search}page=${page}`,
        this.getVerificationsSuccess,
        this.getVerificationsError,
        this.load,
      );
    };
    getVerificationsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        updated_datetime: item.updated_datetime
          ? Moment(item.updated_datetime, "DD-MM-YYYY, LT").format(
              "DD MMM YYYY HH:mm",
            )
          : "N/A",
      }));
      this.setState({
        verifications: {
          data: tempData,
          meta: payload.meta,
        },
        verificationPage: tmpTotalPages,
      });
    };
    getVerificationsError = (error) => requestError(error);

    getSelectedVerification = (id) =>
      Get(
        `/admin/otp_verifications/${id}`,
        this.getSelectedVerificationSuccess,
        this.getSelectedVerificationError,
        this.load,
      );
    getSelectedVerificationSuccess = (payload) => {
      let tempLogs = [];
      payload.sms_logs &&
        payload.sms_logs.map((item) => {
          tempLogs.push({
            service_type: item.service_type || "N/A",
            provider_response: item.provider_response || "N/A",
            status: item.status || "N/A",
            content: item.content || "N/A",
            created_at: item.created_at || "N/A",
            updated_at: item.updated_at || "N/A",
            updated_at: Moment(item.updated_at).format("DD-MM-YYYY, hh:mm a"),
            created_at: Moment(item.created_at).format("DD-MM-YYYY, hh:mm a"),
          });
        });

      this.setState({
        showDetailsModal: true,
        selectedVerification: {
          ...payload,
          sms_logs: tempLogs,
        },
      });
    };
    getSelectedVerificationError = (error) => requestError(error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            showDetailsModal={this.state.showDetailsModal}
            selectedVerification={this.state.selectedVerification}
            verifications={this.state.verifications}
            verificationPage={this.state.verificationPage}
            searchParams={this.state.searchParams}
            onLoadVerification={this.state.loading}
            getSelectedVerification={this.getSelectedVerification}
            getVerifications={this.getVerifications}
            onChangeVerificationHOC={this.onChangeVerificationHOC}
          />
        </Fragment>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
