import React, { Component } from "react";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import "stylesheets/components/pagination/index.scss";

class Pagination extends Component {
  state = {
    interval: 10,
  };

  renderPagination = () => {
    const {
      totalPages,
      totalItemPerPage,
      updateCurrentPageNumber,
      currentPageNumber,
    } = this.props;
    return (
      <div className="at-pagination">
        <button
          className={`at-pagination__button at-pagination__button-arrow ${currentPageNumber === 1 ? "disabled" : ""}`}
          onClick={() => {
            updateCurrentPageNumber(1);
            this.setState({ interval: 10 });
          }}
          disabled={currentPageNumber === 1}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button at-pagination__button-arrow ${currentPageNumber === 1 ? "disabled" : ""}`}
          onClick={() => updateCurrentPageNumber(currentPageNumber - 1)}
          disabled={currentPageNumber === 1}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${this.state.interval === 10 ? "disabled" : ""}`}
          onClick={() => this.setState({ interval: this.state.interval - 10 })}
        >
          ...
        </button>

        {totalPages &&
          totalPages.length > 0 &&
          totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            )
              return (
                <button
                  key={item}
                  className={`at-pagination__button ${currentPageNumber === item ? "active" : ""}`}
                  onClick={() => updateCurrentPageNumber(item)}
                >
                  {item}
                </button>
              );
          })}

        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval >= totalPages.length ? "disabled" : ""}`}
          onClick={() => this.setState({ interval: this.state.interval + 10 })}
        >
          {" "}
          ...
        </button>

        <button
          className={`at-pagination__button at-pagination__button-arrow ${currentPageNumber === totalPages.length ? "disabled" : ""}`}
          onClick={() => updateCurrentPageNumber(currentPageNumber + 1)}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button at-pagination__button-arrow ${currentPageNumber === totalPages.length ? "disabled" : ""}`}
          onClick={() => {
            updateCurrentPageNumber(totalPages.length);
            this.setState({ interval: totalPages.length + 1 });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.renderPagination()}
      </div>
    );
  };
}

export default Pagination;
