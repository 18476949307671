import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";

export const featureProperty = [
  {
    label: "Bedrooms",
    value: "bedrooms_label",
    key: "label.details.specification.bedrooms",
    icon: "atlas-bedroom",
  },
  {
    label: "Bathrooms",
    value: "bathrooms_label",
    key: "label.details.specification.bathrooms",
    icon: "atlas-bathroom",
  },
  {
    label: "Car Parks",
    value: "carparks_label",
    key: "label.details.specification.carparks",
    icon: "atlas-car",
  },
];

export const detailsSection = [
  {
    label: "Status",
    value: "status",
    key: "label.details.status.status",
    icon: "atlas-frame-2",
  },
  {
    label: "Available Date",
    value: "formatted_available_date",
    key: "label.details.status.available_date",
    icon: "atlas-calendar-2",
  },
  {
    label: "Updated On",
    value: "updated_on",
    key: "label.details.details.updated_on",
    icon: "atlas-calendar-edit",
  },
  {
    label: "Type",
    value: "type",
    key: "label.details.details.type",
    icon: "atlas-building-3",
  },
  {
    label: "Listing Category",
    value: "category",
    key: "label.details.details.listing_category",
    icon: "atlas-buildings",
  },
  {
    label: "Property Type",
    value: "property",
    key: "label.details.details.property_type",
    icon: "atlas-house",
  },
];

export const listingAgent = [
  {
    class: "agent_name",
    value: "agent_full_name",
  },
  {
    class: "agent_email",
    value: "agent_email",
  },
  {
    class: "agent_contact",
    value: "agent_mobile_number",
  },
  {
    class: "agent_team",
    value: "agent_team",
  },
  {
    class: "agent_branch",
    value: "agent_branch",
  },
];

export const publishPlatformLib = (getTranslation, selectedLocalised) => [
    {
      label: "IQI Global", 
      tooltipTitle: getTranslation("view_listing_iqi", selectedLocalised),
      linkSrc: "exported_iqi_link",
      published: "published_to_iqi",
      exported: "",
      style: {
        padding: "0.1rem",
      },
      logoSrc: iqiLogo,
    },
    {
      label: "Juwai",
      tooltipTitle: getTranslation("view_listing_juwai", selectedLocalised),
      linkSrc: "juwai_link",
      published: "published_to_juwai",
      exported: "exported_to_juwai",
      style: {
        padding: "0.1rem",
      },
      logoSrc: juwaiLogo,
    },
    {
      label: "Juwai Asia",
      tooltipTitle: getTranslation("view_listing_juwai_asia", selectedLocalised),
      linkSrc: "exported_juwai_asia_link",
      published: "published_to_juwai",
      exported: "exported_to_juwai",
      style: {},
      logoSrc: juwaiAsiaLogo,
    },
    {
      label: "EdgeProp",
      tooltipTitle: getTranslation("view_listing_edgeprop", selectedLocalised),
      linkSrc: "edge_prop_url",
      published: "published_to_edge_prop",
      exported: "exported_to_edgeprop",
      style: { borderRadius: "50%", overflow: "hidden" },
      logoSrc: edgePropLogo,
    },
  ];
