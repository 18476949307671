import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import Card from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import GlobalNetworkTable from "./cardTable";
import CustomConfirmationDialog from "components/Modal/delete";
import CreateGlobalNetworkContent from "./Create";
import EditGlobalNetworkModal from "./Edit";
import SearchContent from "./Search";

import GlobalNetworkHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";

class GlobalNetwork extends Component {
  componentDidMount = () => {
    this.props.getGlobalNetwork();
    initGA("/dashboard/global-network");
  };

  render = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const accessPermissions =
      tmpProfileReducer.role.includes("Admin") &&
      permissionsChecker("Global Network", this.props.data);
    const { can_create, can_update, can_destroy } = accessPermissions;

    return (
      <>
        <ModuleHeader
          title={"Global Network"}
          atlasIcon={"atlas-global"}
          actionButton={[
            {
              label: "New Global Network",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeGlobalNetworkHOC(
                  true,
                  "showCreateGlobalNetworkModal",
                ),
            },
          ]}
        />
        <Card
          cardContent={
            <>
              <SearchContent
                searchParams={this.props.searchParams}
                getListAPI={this.props.getGlobalNetwork}
                onChangeSearchParams={(val) => this.props.onChangeGlobalNetworkHOC(val, "searchParams") } />
              <GlobalNetworkTable
                rowData={this.props.branches || []}
                actionColumnContent={
                  can_update || can_destroy
                    ? [
                        {
                          name: "edit",
                          onShow: () => can_update,
                          onClick: (rowData) =>
                            this.props.getSelectedBranch(rowData.id),
                        },
                        {
                          name: "delete",
                          color: "#F04438",
                          onShow: () => can_destroy,
                          onClick: (rowData) => {
                            this.props.onChangeGlobalNetworkHOC(
                              true,
                              "showConfirmationModal",
                            );
                            this.props.onChangeGlobalNetworkHOC(
                              rowData.id,
                              "selectedBranch",
                            );
                          },
                        },
                      ]
                    : []
                }
              />
              {this.props.showCreateGlobalNetworkModal && (
                <CreateGlobalNetworkContent
                  onLoadGlobalNetwork={this.props.onLoadGlobalNetwork}
                  createBranch={this.props.createBranch}
                  onClose={() => this.props.onChangeGlobalNetworkHOC(false, "showCreateGlobalNetworkModal")} />
              )}
              {this.props.showEditGlobalNetworkModal && (
                <EditGlobalNetworkModal
                  selectedBranch={this.props.selectedBranch}
                  onLoadGlobalNetwork={this.props.onLoadGlobalNetwork}
                  updateBranch={this.props.updateBranch}
                  onClose={() => this.props.onChangeGlobalNetworkHOC(false, "showEditGlobalNetworkModal")} />
              )}
              <CustomConfirmationDialog
                open={this.props.showConfirmationModal}
                loading={this.props.onLoadGlobalNetwork}
                message={"Are you sure to remove this global network?"}
                positiveAction={() =>
                  this.props.removeBranch(this.props.selectedBranch)
                }
                negativeAction={() =>
                  this.props.onChangeGlobalNetworkHOC(
                    false,
                    "showConfirmationModal",
                  )
                }
              />
            </>
          }
        />
        {this.props.onLoadGlobalNetwork && <LoadingModal />}
      </>
    );
  };
}

export default compose(VersionCheckHOC, GlobalNetworkHOC)(GlobalNetwork);
