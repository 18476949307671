import React from "react";
import _ from "lodash";

import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";

import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Grey" },
  { value: "Suspended", colorName: "Red" },
  { value: "Deleted", colorName: "Red" },
  { value: "Resigned", colorName: "Grey" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Terminated", colorName: "Red" },
  { value: "Waiting Approval", colorName: "Yellow" },
];

const columnData = [
  {
    header: "Agent",
    searchFlag: "display_name",
    renderColumn: (rowData) => (
      <>
        <p>{rowData.display_name}</p>
        <p>{rowData.email}</p>
      </>
    ),
  },
  {
    header: "Team",
    accessor: "team",
    searchFlag: "team",
  },
  {
    header: "Role",
    accessor: "role",
    searchFlag: "role",
  },
  {
    header: "Branch",
    accessor: "branch",
    searchFlag: "branch",
  },
  {
    header: "Status",
    searchFlag: "status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

const AgentList = ({
  searchParams,
  licenseAgents,
  onLoadParkLicense,
  selectedParkLicense,

  onChangeParkLicenseHOC,
}) => {
  return (
    <ModalDialog
      title={`Agent License under ${licenseAgents.licence_number}`}
      onLoad={onLoadParkLicense}
      fullWidth={true}
      fullHeight={true}
      children={
        <AtPaginatedTable
          rowData={licenseAgents.licence_attach_branch_users || []}
          meta={{}}
          enableSort={true}
          columns={columnData}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeParkLicenseHOC(val, "searchParams")
          }
          actionColumnContent={[]}
          totalPages={["1"]}
        />
      }
      onClose={() => onChangeParkLicenseHOC(false, "showAgentListModal")}
    />
  );
};

export default AgentList;
