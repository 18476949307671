import React from "react";
import { BsTelegram } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";
import ProjectSpecifications from "./specifications";
import ProjectDetails from "./projectDetails";

const ProjectFeatures = ({
  selectedProject,
  visualisations,
  videos,
}) => {
  const { key_features, project_features } = selectedProject;
  
  const renderFeatures = (data, stateName) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {data && data.length !== 0 ? (
          data.map((item, index) => (
            <div
              className="at-project__detail-feature-item"
              key={`ProjectKeyFeatures-${index}`}
            >
              <AtlasIcon svgHref={`atlas-arrow-right-1`} />
              {item[stateName]}
            </div>
          ))
        ) : (
          <span style={{ color: "#1F2937" }}>N/A</span>
        )}
      </div>
    );
  };

  const renderVisualisation = () => (
    <div className="at-project-listing__project-360">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-3d-rotate" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>360 Visualisations</h1>
      </div>
      {visualisations.map((item) => {
        return (
          <div
            key={item.id}
            className="at-project__details-link"
            onClick={() => window.open(item.url, "_blank")}
          >
            <AtlasIcon
              svgHref={`atlas-link-2-linear`}
              style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
            />
            {item.title}
          </div>
        );
      })}
    </div>
  );

  const renderUnits = () => {
    return (
      <div className="at-project-listing__project-unit">
        <div className="at-project__detail-header">
          <AtlasIcon svgHref="atlas-grid-5" />
          <h1 style={{ fontSize: 18, fontWeight: 600 }}>Units</h1>
        </div>
        {selectedProject.project_units.map((item, index) => {
          return (
            <div
              className="at-project__detail-feature-item"
              key={`units-${index}`}
            >
              <AtlasIcon svgHref={`atlas-arrow-right-2`} />
              {item.title}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTelegramLink = () => (
    <div className="at-project-listing__project-telegram">
      <div className="at-project__detail-header">
        <BsTelegram />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Telegram Link</h1>
      </div>
      <div
        className="at-project__details-link"
        onClick={() => window.open(selectedProject.telegram_link, "_blank")}
      >
        <AtlasIcon
          svgHref={`atlas-link-2-linear`}
          style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
        />
        {selectedProject.telegram_link}
      </div>
    </div>
  );

  const renderVideoSections = () => (
    <div className="at-project-listing__project-video">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-video-circle" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Tutorial Video</h1>
      </div>
      {videos.map((item) => {
        return (
          <div
            key={item.id}
            className="at-project__details-link"
            onClick={() => this.toggleVideo(item.streaming_code)}
          >
            <AtlasIcon
              svgHref={`atlas-link-2-linear`}
              style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
            />
            {item.title}
          </div>
        );
      })}
    </div>
  );

  const renderDeveloper = () => {
    const dataArray = [
      { label: "Developer", value: "developer", icon: "building" },
      { label: "Phase", value: "phase", icon: "signpost" },
      {
        label: "Expected Completion",
        value: "completion_quater",
        icon: "calendar",
      },
    ];

    return (
      <>
        {dataArray.map((item, index) => (
          <div key={index} className={`at-project__details-item`}>
            <AtlasIcon svgHref={`atlas-${item.icon}`} />
            <label>{item.label}</label>
            <span>
              {selectedProject[item.value]
                ? selectedProject[item.value]
                : `N/A`}
            </span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="at-project-listing__details-features">
      <ProjectDetails selectedProject={selectedProject} />
      <ProjectSpecifications selectedProject={selectedProject} />
      <div className="at-project-listing__project-developer">
        <div className="at-project__detail-header">
          <AtlasIcon svgHref="atlas-buildings" />
          <h1 style={{ fontSize: 18, fontWeight: 600 }}>Developer</h1>
        </div>
        {renderDeveloper()}
      </div>
      <div className="at-project-listing__project-features">
        <div className="at-project__detail-header">
          <AtlasIcon svgHref="atlas-building" />
          <h1 style={{ fontSize: 18, fontWeight: 600 }}>Key Features</h1>
        </div>
        {renderFeatures(key_features, "description")}
      </div>
      <div className="at-project-listing__project-features">
        <div className="at-project__detail-header">
          <AtlasIcon svgHref="atlas-building" />
          <h1 style={{ fontSize: 18, fontWeight: 600 }}>Project Features</h1>
        </div>
        {renderFeatures(project_features, "name", "project-feature-block")}
      </div>
      {visualisations && visualisations.length > 0 && renderVisualisation()}
      {selectedProject.project_units.length > 0 && renderUnits()}
      {selectedProject.telegram_link && renderTelegramLink()}
      {videos && videos.length > 0 && renderVideoSections()}
    </div>
  );
};

export default ProjectFeatures;
