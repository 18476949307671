import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";

const ProjectDocs = ({ documents, storeIQIDriveData, onDownloadFile }) => {
  const onClickDocument = (item) => {
    if (item.document_url.indexOf("path") === -1) {
      window.open(item.document_url, "_blank");
    } else {
      let tmp = JSON.parse(item.document_url);
      if (item.type === "Base path") {
        storeIQIDriveData({ ...tmp, type: "directory" });
        window.open(
          `${window.location.origin}/#/dashboard/iqi-drive/custom`,
          "_blank",
        );
      } else {
        onDownloadFile(tmp);
      }
    }
  };

  const renderDocument = (item) => {
    switch(item.type_id) {
      case 1:
        return {
          title: 'IQI Drive Folder',
          icon: 'atlas-folder-2',
          iconBackgroud: '#F0631D'
        }
      case 2:
        return {
          title: 'Summary',
          icon: 'atlas-receive-square',
          iconBackgroud: '#6875F5'
        }
      case 3:
        return {
          title: 'Sales Kit',
          icon: 'atlas-element-equal',
          iconBackgroud: '#3B82F6'
        }
      case 4:
        return {
          title: 'Sales Chart',
          icon: 'atlas-chart-3',
          iconBackgroud: '#E74694'
        }
      case 5:
        return {
          title: 'Project Briefing',
          icon: 'atlas-video-play',
          iconBackgroud: '#06B6D4'
        }
      default:
        return {}
    }
  }

  return (
    <>
      <div className="d-flex align-items-center g-3 mb-3">
        <div className="at-badge-icon-sm at-badge-icon-warning">
          <AtlasIcon svgHref="atlas-folder" />
        </div>
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Documents</h1>
      </div>
      <div className="row">
        {documents.map((item) => {
          const { title, icon, iconBackgroud } = renderDocument(item);

          return (
            <div className="col-sm-6 col-md-4 col-lg-3" key={item.id}>
              <div
                onClick={() => onClickDocument(item)}
                className="at-form__content at-project__details-document"
              >
                <div
                  className="at-project__details-icon-cont"
                  style={{ backgroundColor: iconBackgroud }}
                >
                  <AtlasIcon svgHref={icon} />
                </div>
                <span style={{ fontSize: 14, color: '#111827' }}>{title}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProjectDocs;
