import React, { Component } from "react";
import ReactModal from "react-modal";
import ReactLottie from "react-lottie";
import ReactHTMLParser from "react-html-parser";

import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/close";

import TickIcon from "assets/lottie/confirmation_icon.json";
import CrossIcon from "assets/lottie/error_cross.json";
import WarningIcon from "assets/lottie/warning.json";
import ExclaimationIcon from "assets/lottie/exclaimation_icon.json";

import "./status.scss";

const TickOptions = {
  loop: false,
  autoplay: true,
  animationData: TickIcon,
};

const CrossOptions = {
  loop: false,
  autoplay: true,
  animationData: CrossIcon,
};

const WarningOptions = {
  loop: false,
  autoplay: true,
  animationData: WarningIcon,
};

const ExclaimationOptions = {
  loop: false,
  autoplay: true,
  animationData: ExclaimationIcon,
};

const customStyles = {
  overlay: {
    zIndex: 1301, // because material ui dialog is having 1300 as z index
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingRight: "40px",
    maxWidth: "45em",
    width: "90%",
  },
};

const lottieStyle = {
  overflow: "visible",
  display: "inline-block",
  marginRight: 5,
};

ReactModal.setAppElement("#root");

class StatusModal extends Component {
  state = {
    isOpen: false,
    isPaused: false,
  };

  componentDidUpdate = (pp) => {
    if (this.props.isOpen !== pp.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  };

  onClickCloseModal() {
    this.setState({ isOpen: false });
  }

  renderLottieIcon(type) {
    if (type === "positive") {
      return (
        <ReactLottie
          width={30}
          height={30}
          style={{ ...lottieStyle }}
          options={TickOptions}
          isPaused={this.state.isPaused}
        />
      );
    }
    if (type === "negative") {
      return (
        <ReactLottie
          width={30}
          height={30}
          style={{ ...lottieStyle }}
          options={CrossOptions}
          isPaused={this.state.isPaused}
        />
      );
    }
    if (type === "warning") {
      return (
        <ReactLottie
          width={30}
          height={30}
          style={{ ...lottieStyle }}
          options={WarningOptions}
          isPaused={this.state.isPaused}
        />
      );
    }
    if (type === "warning-custom") {
      return (
        <ReactLottie
          width={30}
          height={30}
          style={{ ...lottieStyle }}
          options={ExclaimationOptions}
          isPaused={this.state.isPaused}
        />
      );
    }
  }

  renderModalContent() {
    const { message, onClick, type, onClickCancel, containerStyle } =
      this.props;
    return (
      <div>
        <div className={"at-status-modal__body"} style={{ ...containerStyle }}>
          {this.renderLottieIcon(type)}
          {ReactHTMLParser(message)}
        </div>
        <hr />
        {type !== "warning" && (
          <CustomButton
            containerStyle={{ float: "right" }}
            className={"at-btn at-btn--secondary"}
            children={"OK"}
            onClick={onClick}
          />
        )}
        {type === "warning" && (
          <div style={{ float: "right" }}>
            <CustomButton
              containerStyle={{ display: "inline-block" }}
              className={"at-btn at-btn--danger mr-10"}
              children={"OK"}
              onClick={onClick}
            />
            <CustomButton
              containerStyle={{ display: "inline-block" }}
              className={"at-btn at-btn--secondary"}
              children={"Cancel"}
              onClick={onClickCancel}
            />
          </div>
        )}
      </div>
    );
  }

  render = () => {
    return (
      <ReactModal
        isOpen={this.state.isOpen}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <div className="at-dropbear-panel">
          {this.props.showCloseButton && (
            <div
              className="at-dropbear-panel__header"
              style={{ padding: 0, paddingTop: 10 }}
            >
              <AtlasCloseButton
                containerStyle={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                }}
                onClick={() => this.onClickCloseModal()}
              />
            </div>
          )}
          {this.renderModalContent()}
        </div>
      </ReactModal>
    );
  };
}

export default StatusModal;
