import { AiFillMail } from 'react-icons/ai';
import { PiPhoneFill } from 'react-icons/pi';

const WhiteBoxTemplate = ({ agent, configuration }) => {
  const iconStyle = {
    width: 14,
    height: 14,
    backgroundColor: '#000',
    color: '#fff',
    padding: 2,
    marginRight: 4,
    borderRadius: 99
  };

  return (
    <div style={{ minWidth: 280, width: 'max-content' }}>
      <div style={{ position: 'relative' }}>
        <img
          alt={agent.display_name}
          src={agent.avatar_url}
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            width: 60,
            height: 60,
            border: '1px solid #bebebe',
            objectFit: 'cover',
            objectPosition: 'top',
            backgroundColor: '#eee'
          }}
        />
        <div
          style={{
            marginLeft: 40,
            padding: '8px 8px 8px 32px',
            border: '2px solid #000',
            backgroundColor: `rgba(255, 255, 255, ${configuration?.agentDetailsOpacity})`,
            fontSize: 8,
            fontWeight: 600
          }}
        >
          <span>{agent.license_number}</span>
          <br />
          <span style={{ fontSize: 14 }}>{agent.display_name}</span>
          <div
            style={{
              margin: '8px 0',
              width: 45,
              height: 1,
              backgroundColor: '#000'
            }}
          />
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}
          >
            <PiPhoneFill style={iconStyle} />
            {agent.mobile_contact_number}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AiFillMail style={iconStyle} />
            {agent.email}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteBoxTemplate;
