import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import {
  Select,
  MenuItem,
  Dialog
} from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import { AiFillThunderbolt } from "react-icons/ai";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import RequestTable from "../../components/RequestTable";
import IssueToModal from "./components/issueToModal";
import RejectModal from "./components/rejectModal";
import ReassignContent from "./Reassign";
import AtlasSimpleTable from "components/NewTable/simple";

import PayoutsHOC from "./actions/payouts";
import { statusColor } from "dictionary/subsales";
import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import { statusColor as SubsalesStatus } from "dictionary/subsales";

import "stylesheets/containers/subsales-claims/index.scss";
import "./Update.scss";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const getRecipients = (representative_id, sub_sale_type_id, external_agency_id) => {
  let tmp = [];
  if ([1, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_owner", label: sub_sale_type_id === 1 ? "Vendor" : "Landlord" });
  }
  if ([2, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_client", label: sub_sale_type_id === 1 ? "Purchaser" : "Tenant" });
  }

  if (external_agency_id && external_agency_id !== null) {
    tmp.push({ value: "issue_to_agency", label: "Co-Agency" });
  }

  return tmp;
};

const ClaimTypeOptions = [
  { id: 1, name: "Claim Commission", value: 1, label: "Claim Commission" },
  { id: 2, name: "Refund", value: 2, label: "Refund" },
  { id: 3, name: "Forfeit", value: 3, label: "Forfeit" },
  { id: 4, name: "Renewal", value: 4, label: "Renewal" },
  { id: 5, name: "Release", value: 5, label: "Release" },
  { id: 6, name: "Cancel Form", value: 6, label: "Cancel Form" },
  { id: 7, name: "Request Invoice", value: 7, label: "Request Invoice" },
  {
    id: 8,
    name: "Request Official Receipt",
    value: 8,
    label: "Request Official Receipt",
  },
  {
    id: 9,
    name: "Request TA Stamping",
    value: 9,
    label: "Request TA Stamping",
  },
];

const ClaimParty = (sub_sale_type_id) => [
  {
    id: 1,
    value: 1,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
  },
  {
    id: 2,
    value: 2,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
  }
]

const refundHeaderData = [
  {
    header: "Name",
    accessor: "participant_name",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Amount",
    accessor: "amount",
  },
  {
    header: "Remark",
    accessor: "remark",
  },
];

class Update extends Component {
  state = {
    header: [
      {
        label: "File Name",
        value: "document_file_name",
      },
      {
        label: "Issue To",
        value: "issue_to_string",
      },
      {
        label: "Status",
        value: "status_id",
      },
    ],
    fileItem: {},
    fileName: "",
    issueToArray: [],
    actionButton: [],
    showUploadDocument: false,
  };

  componentDidMount = () => {
    this.initComponent();
    this.props.getAdmins(this.props.selectedSubmission.type_id);
    if ([1, 3, 4].indexOf(this.props.selectedSubmission.type_id) !== -1) {
      this.props.getPayouts(this.props.selectedSubmission.id);
    }
  };

  componentDidUpdate = (pp, prevState) => {
    if (prevState.fileItem !== this.state.fileItem) {
      this.setState({ showUploadDocument: true });
    }
    if (
      pp.selectedSubmission.type_id !== this.props.selectedSubmission.type_id
    ) {
      this.initComponent();
    }
  };

  initComponent = () => {
    if ([7, 8].indexOf(this.props.selectedSubmission.type_id > -1)) {
      this.setState({
        issueToArray: getRecipients(
          this.props.selected_claim.representative_id,
          this.props.selected_claim.sub_sale_type_id,
          this.props.selected_claim.external_agency_id
        )
      });
    }
  };

  checkDisabledEdit = () => {
    let tmp = true;
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (tmpProfileReducer.id === this.props.selectedSubmission.assignee_id) {
      tmp = false;
    } else if (
      ["Submitted", "Reviewing", "Processing", "Rejected"].indexOf(
        this.props.selectedSubmission.status,
      ) !== -1
    ) {
      tmp = false;
    } else if (tmpProfileReducer.role === "Super Admin") {
      tmp = false;
    }
    return tmp;
  };

  onChangeSubmission = (val, context) => {
    let tmp = _.cloneDeep(this.props.selectedSubmission);
    tmp[context] = val;
    return this.props.onChangeSubmissionHOC("selectedSubmission", tmp);
  };

  browseFile = () => {
    setTimeout(() => {
      this.pond.browse();
    }, 400);
  };

  onChangeType = (val) => {
    let tmp = _.cloneDeep(this.props.selectedSubmission);
    tmp.type_id = val;
    tmp.issue_to_owner = false;
    tmp.issue_to_client = false;
    tmp.issue_to_agency = false;

    if (val === 1) {
      this.props.getPayouts(this.props.selectedSubmission.id);
    }
    return this.props.onChangeSubmissionHOC("selectedSubmission", tmp);
  };

  onSelectDoc = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          fileItem: e.target.result,
          fileName: fileItems[0].file.name,
        });
      };
      reader.readAsDataURL(fileItems[0].file);
    } else {
      this.setState({
        fileItem: {},
        fileName: "",
      });
    }
  };

  renderRejectModal = () => {
    return (
      <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
        <RejectModal
          rejectReason={this.props.rejectReason}
          onChangeSubmissionHOC={this.props.onChangeSubmissionHOC}
          onSubmit={() =>
            this.props.rejectSubmission(
              this.props.selected_claim.id,
              this.props.selectedSubmission.id,
            )
          }
        />
      </Dialog>
    );
  };

  renderCountDownText = () => {
    const { approvalCountDownTime } = this.props;

    if (
      this.buttonPermission() === "Admin" &&
      approvalCountDownTime &&
      approvalCountDownTime > 0
    ) {
      return (
        <p style={{ marginTop: 5 }}>
          {`Please wait for ${approvalCountDownTime} seconds cooling-off period to approve another submission.`}
        </p>
      );
    }
  };

  adminFilter = (key) => {
    const { id: profileId } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { senior_assignee_id, assignee_id } = this.props.selectedSubmission;

    if(key === "senior_admin") {
      return profileId === senior_assignee_id
    } else if(key === "assigned_admin") {
      return profileId === assignee_id
    } else if(key === "either_admin") {
      return profileId === senior_assignee_id || profileId === assignee_id
    } else if(key === "both_admin") {
      return profileId === senior_assignee_id && profileId === assignee_id
    }
    return false
  }

  // Permission buttons 
  buttonPermission = () => {
    let tempPermission = false;
    const { type_id, status } = this.props.selectedSubmission;

    if (
      this.adminFilter("either_admin") && ![7, 8].includes(type_id)
    ) {
      if (this.adminFilter("both_admin")) {
        if (status === "Reviewing") {
          tempPermission = "Senior admin";
        } else if (status === "Checking" && [1, 2, 3, 4, 5, 9].includes(type_id)) {
          tempPermission = "Admin";
        } else if (status === "Submitted" && type_id === 6) {
          tempPermission = "Admin";
        } else {
          tempPermission = false;
        }
      } else if (this.adminFilter("senior_admin")) {
        if(status === "Reviewing") {
          tempPermission = "Senior admin"
        } else {
          tempPermission = false
        }
      } else if (this.adminFilter("assigned_admin")) {
        if(status === "Checking" && [1, 2, 3, 4, 5, 9].includes(type_id)) {
          tempPermission = "Admin"
        } else if(status === "Submitted" && type_id === 6) {
          tempPermission = "Admin"
        } else {
          tempPermission = false
        }
      }
    } else {
      tempPermission = false;
    }

    return tempPermission;
  };

  // Permission button for Submitted status 
  // Only apply to Claim Commission, Refund, Forfeit, Renewal, Release, Request TA Stamping
  checkingButtonPermission = () => {
    let tempPermission = false;
    const { type_id, status } = this.props.selectedSubmission;

    if (
      this.adminFilter("either_admin") && ![6, 7, 8].includes(type_id)
    ) {
      if (this.adminFilter("both_admin")) {
       if (status === "Submitted") {
          tempPermission = "Admin";
        } else {
          tempPermission = false;
        }
      } else if (this.adminFilter("senior_admin")) {
        tempPermission = false
      } else if (this.adminFilter("assigned_admin")) {
        if(status === "Submitted") {
          tempPermission = "Admin"
        } else {
          tempPermission = false
        }
      }
    } else {
      tempPermission = false;
    }

    return tempPermission;
  }

  reassignButtonPermission = () => {
    let tempPermission = false;
    const { type_id, status } = this.props.selectedSubmission;

    if (
      this.adminFilter("either_admin") && ![7, 8].includes(type_id)
    ) {
      if (this.adminFilter("both_admin")) {
        if (status === "Reviewing") {
          tempPermission = "Senior admin";
        } else if(status === "Submitted" || status === "Checking") {
          tempPermission = "Admin";
        } else {
          tempPermission = false;
        }
      } else if(this.adminFilter("senior_admin")) {
        if(status === "Reviewing") {
          tempPermission = "Senior admin"
        } else {
          tempPermission = false
        }
      } else if(this.adminFilter("assigned_admin")) {
        if(status === "Submitted" || status === "Checking") {
          tempPermission = "Admin"
        } else {
          tempPermission = false
        }
      }
    } else {
      tempPermission = false;
    }

    return tempPermission
  }

  renderDocuments = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { can_create, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const processActionColumn = () => {
      let temp = ["view-doc"];
      if (can_update) temp.push("notify");
      if (role === "Super Admin") temp.push("delete");

      return temp;
    };

    return (
      <>
        <RequestTable
          className={"mb-10 mt-3"}
          headerData={this.state.header}
          pagination={true}
          totalPaginationCount={0}
          actionColumn={true}
          actionColumnContent={processActionColumn()}
          viewDocTooltip={"Download"}
          rowData={this.props.documents}
          originalRowData={this.props.documents}
          deleteClick={(val) =>
            this.props.deleteDocument(this.props.selectedSubmission.id, val.id)
          }
          onClickViewDoc={(val) => window.open(val.url, "_blank")}
          sendNotification={this.props.sendNotification}
          searchWithField={"name"}
          searchPlaceholder={"name"}
          rowKey={"id"}
          renderExtraContent={() => (
            <div className={`at-table_heading`}>
              <div className={"d-flex flex-wrap grid_gap-2"}>
                <AtlasIcon
                  svgHref="atlas-document-text"
                  className="at-icon_svg-table_heading-title"
                />
                <h5 className="fw-600">{"Uploaded Documents"}</h5>
                {can_create && (
                  <CustomButton
                    className={"btn-new btn-new--primary"}
                    children={
                      <>
                        <IoMdAdd style={{ height: 18, width: 18 }} />
                        Upload Document
                      </>
                    }
                    onClick={() => this.browseFile()}
                  />
                )}
              </div>
            </div>
          )}
        />
        <div style={{ display: "none" }}>
          <FilePond
            ref={(ref) => (this.pond = ref)}
            allowMultiple={false}
            labelIdle={"Click here to upload documents"}
            acceptedFileTypes={["application/pdf"]}
            fullWidth
            onupdatefiles={(fileItems) => this.onSelectDoc(fileItems)}
          />
        </div>
      </>
    );
  };

  render = () => {
    const { role, id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    const { colorName = "" } =
      _.find(statusColor, { value: this.props.selectedSubmission.status }) ||
      {};
    const { refund_details } = this.props.selectedSubmission;

    const { label: typeLabel = "" } =
      _.find(ClaimTypeOptions, {
        value: this.props.selectedSubmission.type_id,
      }) || {};

    return (
      <>
        <ModalDialog
          onLoad={this.props.onLoadSubsaleClaims || this.props.onLoadClaimChecking || this.props.onLoadSubmissions || this.props.onLoadDocuments || this.props.onLoadAdmins}
          title={"Update Claim Submission"}
          responsiveSize='lg'
          onClose={() =>
            this.props.onChangeSubmissionHOC("showUpdateSubmission", false)
          }
          children={
            <>
              <form
                className="at-profile__container grid-control grid_gap-x-2"
                onSubmit={(e) => e.preventDefault()}>
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">
                    {"Submission Type"}
                  </h2>
                  <p className="at-radio__content">
                    {this.props.selectedSubmission.is_urgent && (
                      <AiFillThunderbolt
                        style={{
                          width: "1.5rem",
                          height: "fit-content",
                          color: "red",
                        }}
                      />
                    )}
                    {typeLabel}
                  </p>
                </section>
                <section className="grid-full-col">
                  <div className="at-form_fields_cont">
                    <div className="at-form_field-col-4">
                      <h4 className={"at-form-input__title"}>{"Status"}</h4>
                      {role === "Super Admin" && (
                        <Select
                          className="fs-2"
                          value={this.props.selectedSubmission.status_id}
                          onChange={(e) =>
                            this.onChangeSubmission(e.target.value, "status_id")
                          }>
                          {SubsalesStatus.map((item) => {
                            return (
                              <MenuItem key={item.id} className="fs-2" value={item.id}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      {role === "Admin" && (
                        <div
                          className="at-status_badge"
                          style={{
                            ...getColorBadge(colorName),
                            whiteSpace: "nowrap",
                          }}>
                          {`${this.props.selectedSubmission.status}`}
                        </div>
                      )}
                    </div>
                    {role === "Super Admin" && (
                      <>
                        <div className="at-form_field-col-4">
                          <h4 className={"at-form-input__title"}>
                            {"Assigned Admin"}
                          </h4>
                          <Select
                            className="fs-2"
                            value={this.props.selectedSubmission.assignee_id}
                            onChange={(e) =>
                              this.onChangeSubmission(
                                e.target.value,
                                "assignee_id",
                              )
                            }>
                            {this.props.admins &&
                              this.props.admins.length > 0 &&
                              this.props.admins.map((item) => {
                                return (
                                  <MenuItem className="d-flex align-items-center fs-2" key={item.id} value={item.id}>
                                    <span
                                      className="at-submission__online-status"
                                      style={{
                                        backgroundColor:
                                          item.online_status === 1
                                            ? "green"
                                            : "red",
                                      }}
                                    ></span>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>
                        {[6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) === -1 && (
                          <div className="at-form_field-col-4">
                            <h4 className={"at-form-input__title"}>
                              {"Reviewing Admin"}
                            </h4>
                            <Select
                              className="fs-2"
                              value={
                                this.props.selectedSubmission.senior_assignee_id
                              }
                              onChange={(e) =>
                                this.onChangeSubmission(
                                  e.target.value,
                                  "senior_assignee_id",
                                )
                              }>
                              {this.props.senior_admins &&
                                this.props.senior_admins.length > 0 &&
                                this.props.senior_admins.map((item) => {
                                  return (
                                    <MenuItem className="fs-2 d-flex align-items-center" key={item.id} value={item.id}>
                                      <span
                                        className="at-submission__online-status"
                                        style={{
                                          backgroundColor:
                                            item.online_status === 1
                                              ? "green"
                                              : "red",
                                        }}
                                      ></span>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </div>
                        )}
                      </>
                    )}
                    {role === "Admin" && (
                      <>
                        <div className="at-form_field-col-4">
                          <h4 className={"at-form-input__title"} >{`Assigned Admin`}</h4>
                          <p>
                            {this.props.selectedSubmission.admin &&
                              this.props.selectedSubmission.admin}
                            {!this.props.selectedSubmission.admin &&
                              ([7, 8].includes(
                                this.props.selectedSubmission.type_id,
                              ) &&
                              this.props.selectedSubmission.status_id === 1 ? (
                                <div
                                  className="at-status_badge at-subsales_claims-no-admin-assigned"
                                  style={{ fontSize: 12 }}>
                                  {this.props.selectedSubmission.type_id === 7
                                    ? "Your invoice is in process now"
                                    : "Your receipt is in process now"}
                                </div>
                              ) : (
                                "N/A"
                              ))}
                          </p>
                        </div>
                        {[6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) === -1 && (
                          <div className="at-form_field-col-4">
                            <h4 className={"at-form-input__title"}>{`Reviewing Admin`}</h4>
                            <p>{this.props.selectedSubmission.senior_admin}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </section>
                {(this.props.selectedSubmission.type_id && this.props.selectedSubmission.type_id === 1) && (
                  <section className="grid-full-col mt-10">
                    <h2 className="at-form-input__title">{"Claim From"}</h2>
                    <AtlasRadioGroup
                      mode="old"
                      options={ClaimParty(this.props.selected_claim.sub_sale_type_id)}
                      disabled={!can_update}
                      checkedValue={
                        this.props.selectedSubmission.claim_party_id
                      }
                      selectedRadioValue={(val) =>
                        this.onChangeSubmission(val, "claim_party_id")
                      }/>
                  </section>
                )}
                {[7, 8].indexOf(this.props.selectedSubmission.type_id) !== -1 && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">Issue To</h2>
                    {this.state.issueToArray.map((item) => (
                      <CustomCheckbox
                        disabled={!can_update}
                        checked={this.props.selectedSubmission[item.value]}
                        onChangeCheckboxValue={(event) => this.onChangeSubmission(event.target.checked, item.value)}
                        content={item.label}/>
                    ))}
                  </section>
                )}
                {!_.isEmpty(refund_details) && (
                  <section className="grid-full-col mt-10">
                    <h4
                      className={"at-form-input__title"}
                    >{`Refund Details`}</h4>
                    <AtlasSimpleTable
                      className={"mb-100"}
                      columns={refundHeaderData}
                      rowData={refund_details}
                      hideSearch={true}
                      pagination={true}
                      emptyStateMessage="You don't have any participants."/>
                  </section>
                )}
                {_.isEmpty(refund_details) && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Remark"}</h2>
                    <AtlasTextarea
                      rows={5}
                      disabled={!can_update}
                      value={this.props.selectedSubmission.description}
                      onChangeValue={(val) =>
                        this.onChangeSubmission(val, "description")
                      }/>
                  </section>
                )}
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{"Admin Remark"}</h2>
                  <AtlasTextarea
                    rows={5}
                    disabled={!can_update}
                    value={this.props.selectedSubmission.remark}
                    onChangeValue={(val) =>
                      this.onChangeSubmission(val, "remark")
                    }/>
                </section>
                <section className="grid-full-col">
                  <CustomCheckbox
                    disabled={!can_update}
                    content={"Mark this claim submission as Urgent"}
                    checked={this.props.selectedSubmission.is_urgent}
                    onChangeCheckboxValue={(e) =>
                      this.onChangeSubmission(e.target.checked, "is_urgent")
                    }/>
                </section>
                {can_update && (
                  <button
                    style={{ display: "inline-block" }}
                    className="btn-new btn-new--success"
                    onClick={() =>
                      this.props.updateSubmission(
                        this.props.selected_claim.id,
                        this.props.selectedSubmission.id,
                        this.props.selectedSubmission,
                      )
                    }
                    disabled={this.checkDisabledEdit()}>
                    Save
                  </button>
                )}
                <section className="at-modal_dialog-container-footer d-flex grid_gap-1">
                  {this.props.selectedSubmission.status_id === 6 
                  && (role === "Super Admin" || (role === "Admin" && id === this.props.selectedSubmission.assignee_id)) && (
                    <button
                      style={{ display: "inline-block" }}
                      className="btn-new btn-new--secondary"
                      onClick={() =>
                        this.props.onChangeSubmissionHOC("showResubmitSubmission", true)
                      }>
                      Resubmit
                    </button>
                  )}
                  {
                    can_update && (
                      <>
                        {(this.checkingButtonPermission() === "Admin" && !this.props.approvalCountDownTime) && (
                          <button className="btn-new btn-new--primary"
                            onClick={() => this.props.checkingSubmission(this.props.selected_claim.id, this.props.selectedSubmission.id)}>
                            Checking
                          </button>
                        )}
                        {(this.buttonPermission() === "Admin" 
                          && !this.props.approvalCountDownTime 
                          && [6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) !== -1
                        ) && (
                          <button
                            style={{ display: "inline-block" }}
                            className="btn-new btn-new--primary"
                            disabled={this.props.disabledApprove}
                            onClick={() => this.props.onChangeSubmissionHOC("showApproveConfirmation", true)}>
                            Approve
                          </button>
                        )}
                        {(this.buttonPermission() === "Admin" 
                          && !this.props.approvalCountDownTime 
                          && [6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) === -1 
                        ) && (
                          <button
                            style={{ display: "inline-block" }}
                            className="btn-new btn-new--primary"
                            disabled={this.props.disabledApprove}
                            onClick={() => this.props.onChangeSubmissionHOC("showApproveConfirmation", true)}>
                            Send for Review
                          </button>
                        )}
                        {this.buttonPermission() === "Senior admin" && (
                          <button
                            style={{ display: "inline-block" }}
                            className="btn-new btn-new--primary"
                            disabled={this.props.disabledApprove}
                            onClick={() =>
                              this.props.onChangeSubmissionHOC("showSeniorApproveConfirmation", true)
                            }>
                            Export To ERP
                          </button>
                        )}
                        {(this.reassignButtonPermission()) && (
                          <button
                            style={{ display: "inline-block" }}
                            className="btn-new btn-new--secondary"
                            onClick={() =>
                              this.props.onChangeSubmissionHOC("showReassignModal", true)
                            }>
                            Re-Assign
                          </button>
                        )}
                        {this.buttonPermission() && (
                          <button
                            style={{ display: "inline-block" }}
                            className="btn-new btn-new--danger"
                            onClick={() => this.props.onChangeSubmissionHOC("showRejectSubmissionReason", true)}>
                            Reject
                          </button>
                        )}
                        {(this.props.selectedSubmission.status_id === 2 &&
                          this.props.selectedSubmission.type_id === 9 &&
                          this.props.selectedSubmission.assignee_id === id 
                        )&& (
                            <button
                              style={{ display: "inline-block" }}
                              className="btn-new btn-new--secondary"
                              onClick={() =>
                                this.props.onChangeSubmissionHOC(
                                  "showCompleteConfirmation",
                                  true,
                                )
                              }
                              disabled={false}>
                              Complete
                            </button>
                          )}
                      </>
                    )
                  }
                  <button
                    type={"button"}
                    className="btn-new btn-new--outline-secondary"
                    onClick={() =>
                      this.props.onChangeSubmissionHOC(
                        "showUpdateSubmission",
                        false,
                      )
                    }>
                    Close
                  </button>
                </section>
                {(this.props.selectedSubmission.type_id === 1 ||
                  this.props.selectedSubmission.type_id === 3 ||
                  this.props.selectedSubmission.type_id === 4) && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{`Claim percentage for this submission`}</h2>
                    <AtlasTable
                      hideSearch={true}
                      hidePagination={true}
                      columns={[
                        {
                          header: "#",
                          hideSort: true,
                          columnStyle: { width: "100%" },
                          renderColumn: (rowData, index) => {
                            return index + 1;
                          }
                        },
                        {
                          header: "Agent",
                          accessor: "agent",
                          hideSort: true,
                          columnStyle: { width: "100%" },
                        },
                        {
                          header: "Claim Percentage",
                          hideSort: true,
                          renderColumn: (rowData, index) => {
                            return (
                              <input
                                type={"number"}
                                disabled={!can_update}
                                className="form-control"
                                pattern="[0-9]*"
                                value={`${rowData.claim_percentage}`}
                                onChange={(e) => {
                                  let tmp = _.cloneDeep(this.props.payouts);
                                  tmp[index]["claim_percentage"] =
                                    e.target.value;
                                  return this.props.onChangePayoutsHOC(
                                    "payouts",
                                    tmp,
                                  );
                                }}
                              />
                            )
                          },
                          columnStyle: { width: "100%" },
                        }
                      ]}
                      rowData={this.props.payouts || []}
                      pagination={true}
                      actionColumnContent={[]}/>
                    {this.props.payouts &&
                      this.props.payouts.length > 0 && (
                        <button
                          className="btn-new btn-new--success mt-20"
                          onClick={() => {
                            let tmpPayouts = _.cloneDeep(
                              this.props.payouts,
                            );
                            let tmp = {
                              payouts: [],
                            };
                            tmpPayouts.map((item) => {
                              tmp.payouts.push({
                                id: item.id,
                                claim_percentage: item.claim_percentage,
                              });
                            });
                            return this.props.updatePayouts(
                              this.props.selectedSubmission.id,
                              tmp,
                            );
                          }}
                          disabled={!can_update}>
                          Update All
                        </button>
                      )}
                  </section>
                )}
              </form>
              {[7, 8, 9].indexOf(this.props.selectedSubmission.type_id) !== -1 && this.renderDocuments()}
            </>
          }
        />
        <ConfirmationModal
          open={this.props.showApproveConfirmation}
          loading={this.props.onLoadSubmissions || this.props.onLoadDocuments}
          disabledPositive={this.props.disabledApprove}
          message={
            <span>
              {[7, 8, 9].indexOf(this.props.selectedSubmission.type_id) !==
                -1 && "Are you sure you want to process the submission now ?"}
              {[6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) ===
                -1 && "Are you sure you want to send for reviewing now ?"}
              {this.props.selectedSubmission.type_id === 6 &&
                "Are you sure you want to cancel the claim form ?"}
            </span>
          }
          positiveAction={() => {
            this.props.onChangeSubmissionHOC("disabledApprove", true);
            this.props.approveSubmission(this.props.selected_claim.id, this.props.selectedSubmission.id);
          }}
          negativeAction={() =>
            this.props.onChangeSubmissionHOC("showApproveConfirmation", false)
          }/>

        <ConfirmationModal
          open={this.props.showSeniorApproveConfirmation}
          loading={this.props.onLoadSubmissions || this.props.onLoadDocuments}
          disabledPositive={this.props.disabledApprove}
          message={"Are you sure you want to approve and export to ERP now ?"}
          positiveAction={() => {
            this.props.seniorApproveSubmission(
              this.props.selected_claim.id,
              this.props.selectedSubmission.id,
            );
            this.props.onChangeSubmissionHOC("disabledApprove", true);
          }}
          negativeAction={() =>
            this.props.onChangeSubmissionHOC(
              "showSeniorApproveConfirmation",
              false,
            )
          }/>
        <ConfirmationModal
          mode={"alert"}
          open={this.props.showResubmitSubmission}
          loading={this.props.onLoadSubmissions}
          message={"Are you sure you want to resubmit this claims submission?"}
          positiveAction={() =>
            this.props.resubmitSubmission(
              this.props.selected_claim.id,
              this.props.selectedSubmission.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeSubmissionHOC("showResubmitSubmission", false)
          }/>
        {this.props.showReassignModal && (
          <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
            <ReassignContent
              profileId={id}
              role={role}
              selectedSubmission={this.props.selectedSubmission}
              getPendingReassign={this.props.getPendingReassign}
              closeUpdateModal={() =>
                this.props.onChangeSubmissionHOC("showUpdateSubmission", false)
              }
              onClose={() =>
                this.props.onChangeSubmissionHOC("showReassignModal", false)
              }/>
          </Dialog>
        )}
        {this.state.showUploadDocument && (
          <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
            <IssueToModal
              selected_claim={ this.props.selected_claim }
              filename={this.state.fileName}
              file={this.state.fileItem}
              selectedSubmission={this.props.selectedSubmission}
              uploadDocument={this.props.uploadDocument}
              onClickClose={() => this.setState({ showUploadDocument: false })}/>
          </Dialog>
        )}
        <ConfirmationModal
          open={this.props.showCompleteConfirmation}
          message={
            "Are you sure you want to mark this submission status as completed ?"
          }
          positiveAction={() =>
            this.props.completeSubmission(
              this.props.selected_claim.id,
              this.props.selectedSubmission.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeSubmissionHOC("showCompleteConfirmation", false)
          }/>
        {this.props.showRejectSubmissionReason && this.renderRejectModal()}
        {(this.props.onLoadSubsaleClaims ||
          this.props.onLoadClaimChecking ||
          this.props.onLoadSubmissions ||
          this.props.onLoadDocuments ||
          this.props.onLoadAdmins) && <LoadingModal />}
      </>
    );
  };
}

export default compose(PayoutsHOC)(Update);
