import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Delete } from "utils/axios";
import {
  types,
  categories,
  statuses,
  measurements,
  directions,
  occupancies,
  furnishing_statuses,
  tenure_types,
  title_types,
  unit_types,
} from "dictionary/subsales";

export const SearchParams = ({ countries, currencies }) => [
  {
    label: "Project Name",
    value: "estate_of_Project_type_name_or_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Developer",
    value: "estate_of_Project_type_developer_cont",
    type: "input",
    param: "",
  },
  {
    label: "Country",
    value: "estate_of_Project_type_country_id_eq",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "estate_of_Project_type_state_id_eq",
    type: "select",
    placeholder: "Please choose a country first.",
    param: "",
    options: [],
  },
  {
    label: "Currency",
    value: "currency",
    type: "select",
    withoutPrefix: true,
    param: "",
    options: currencies.name,
    col: 12,
  },
  {
    label: "Project Price Greater Than",
    value: "min_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Project Price Less Than",
    value: "max_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Bedroom No. Greater than",
    value: "estate_of_Project_type_min_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Bedroom No. Less than",
    value: "estate_of_Project_type_max_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Greater than",
    value: "estate_of_Project_type_min_built_up_eq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Less than",
    value: "estate_of_Project_type_max_built_up_eq",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showProjectEditModal: false,
      showProjectRemoveConfirmation: false,

      estatetProjectIndexSearchParams: SearchParams(
        this.props.data.dictionaryReducer
      ),
      estatetProjectIndexSearch: {
        currentPage: 1,
        searchParams: "",
      },
      projects: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      projectPages: [0],
    };

    load = (param) => this.setState({ loading: param });

    onChangeProjectHOC = (val, context) => this.setState({ [context]: val });

    getProjects = (presentationId, page, search) =>
      this.setState(
        {
          estatetProjectIndexSearch: {
            currentPage: page,
            searchParams: search,
          },
        },
        () => {
          const { role } =
            window.location.href.indexOf("/admin-impersonate") > -1
              ? this.props.data.currentSignInProfileReducer
              : this.props.data.profileReducer;

          return Get(
            `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates?q[estate_type_eq]=Project&${search}page=${page} `,
            this.getProjectsSuccess,
            this.getProjectsError,
            this.load
          );
        }
      );
    getProjectsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tmpSubsalesCurrentUser = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpType = _.find(types, { id: item.estate.type_id });
          let tmpCategory = _.find(categories, { id: item.estate.category_id });
          let tempStatus = _.find(statuses, { id: item.estate.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.estate.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempTitleType = _.find(title_types, {
            id: item.estate.title_type_id,
          });
          let tempDirection = _.find(directions, {
            id: item.estate.direction_id,
          });
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.estate.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.estate.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, {
            id: item.estate.occupancy_id,
          });
          let tempTenure = _.find(tenure_types, { id: item.estate.tenure_id });
          let tempUnitType = _.find(unit_types, {
            id: item.estate.unit_type_id,
          });

          let featureArray = [
            {
              label: "Direction",
              value:
                tempDirection && tempDirection.name.toLowerCase() !== "unknown"
                  ? tempDirection.name
                  : "N/A",
            },
            {
              label: "Occupancy",
              value:
                tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure && tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
            {
              label: "Unit Type",
              value:
                tempUnitType && tempUnitType.name.toLowerCase() !== "unknown"
                  ? tempUnitType.name
                  : "N/A",
            },
            {
              label: "Title Type",
              value:
                tempTitleType && tempTitleType.name.toLowerCase() !== "unknown"
                  ? tempTitleType.name
                  : "N/A",
            },
          ];

          let data = {
            ...item.estate,
            ...item,
            featureArray: featureArray,
            type: tmpType && tmpType.name ? tmpType.name : "N/A",
            property:
              tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
                ? tmpCatGroupPropertyType.name
                : "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          };
          return tmpSubsalesCurrentUser.push(data);
        });
      payload.data = tmpSubsalesCurrentUser;
      this.setState({
        projects: payload,
        projectPages: tmpTotalPages,
      });
    };
    getProjectsError = (error) => requestError(error);

    removeProject = (presentationId, estateId, callback) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Delete(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates/remove?type_id=2&estate_id=${estateId}`,
        () => this.removeProjectSuccess(presentationId, callback),
        this.removeProjectError,
        this.load
      );
    };
    removeProjectSuccess = (presentationId, callback) => {
      const {
        currentPage: presentationPage,
        searchParams: presentationSearch,
      } = this.props.data.lastViewReducer.lastView;
      const {
        currentPage: estateProjectPage,
        searchParams: estateProjectSearch,
      } = this.state.estatetProjectIndexSearch;

      callback && callback();
      requestSuccess("Project removed successfully.");
      this.props.getSelectedPresentation(presentationId);
      this.getProjects(presentationId, estateProjectPage, estateProjectSearch);
      this.props.getPresentation(presentationPage, presentationSearch);
      this.setState({ showProjectRemoveConfirmation: false });
    };
    removeProjectError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            projects={this.state.projects}
            onLoadProject={this.state.loading}
            projectPages={this.state.projectPages}
            showProjectEditModal={this.state.showProjectEditModal}
            estatetProjectIndexSearch={this.state.estatetProjectIndexSearch}
            showProjectRemoveConfirmation={
              this.state.showProjectRemoveConfirmation
            }
            estatetProjectIndexSearchParams={
              this.state.estatetProjectIndexSearchParams
            }
            onChangeProjectHOC={this.onChangeProjectHOC}
            getProjects={this.getProjects}
            removeProject={this.removeProject}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
