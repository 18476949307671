import ReactPlayer from 'react-player';

import Dialog from '@material-ui/core/Dialog';
import AtlasCloseButton from 'components/Button/close';

import 'stylesheets/components/videoplayer/index.scss';

const VideoPlayer = ({ videoURL, onClickClose }) => {
  return (
    <Dialog
      open
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          maxWidth: 'none',
          margin: 0
        }
      }}
    >
      <div className='at-video-player__container'>
        <AtlasCloseButton
          containerClass='at-video-player_close'
          onClick={onClickClose}
        />
        <ReactPlayer
          className='at-video-player_player'
          url={videoURL}
          playing
          controls
        />
      </div>
    </Dialog>
  );
};

export default VideoPlayer;
