import React, { useState, useCallback } from "react";
import _ from "lodash";
import EmailValidator from "email-validator";

import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation";

const FormDataLib = [
  {
    label: "Valuation",
    value: "type_id",
    col: 12,
    type: "radio",
    optional: false,
    inputError: false,
    errorMessage: "Cannot be empty",
  },
  {
    label: "Client/Company's Name",
    value: "client_name",
    col: 6,
    type: "text",
    optional: false,
    inputError: false,
    errorMessage: "Cannot be empty",
    formType: "text",
  },
  {
    label: "Contact Person",
    value: "contact_person",
    col: 6,
    type: "text",
    optional: false,
    inputError: false,
    errorMessage: "Cannot be empty",
    formType: "text",
  },
  {
    label: "Contact No.",
    value: "contact_number",
    col: 6,
    type: "text",
    optional: false,
    inputError: false,
    errorMessage: "Cannot be empty",
    formType: "text",
  },
  {
    label: "Email Address",
    value: "email",
    col: 6,
    type: "text",
    optional: false,
    inputError: false,
    errorMessage: "Please fill correct email",
    formType: "email",
  },
  {
    label: "Property Title Number (Optional)",
    value: "property_title_number",
    col: 6,
    type: "text",
    optional: true,
    inputError: false,
    errorMessage: "",
    formType: "number",
  },
];

const CreateFormOptions = [
  { value: "1", label: "Property Valuation" },
  {
    value: "2",
    label: "Corporate Valuation (e.g. to value a company or other asset)",
  },
];

const CreateForm = ({
  showCreateConfirmation,
  onLoad,

  onChangeValuationsHOC,
  createValuation,
  onClose,
}) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [formData, setFormData] = useState(FormDataLib);
  const [newValuation, setNewValuation] = useState({
    client_name: "",
    contact_number: "",
    contact_person: "",
    email: "",
    property_title_number: "",
    type_id: "",
  });

  const onCheckError = (context, data) => {
    let tmpFormData = _.cloneDeep(formData);
    let tmpInputError = true;
    let tmpDisabled = false;
    tmpFormData = tmpFormData.map((item) => {
      if (!item.optional) {
        tmpInputError = false;
        if (item.value === "email" && item.value === context) {
          tmpInputError = !(
            data[context] && EmailValidator.validate(data[item.value])
          );
        } else {
          tmpInputError = !data[item.value];
        }
        if (tmpInputError) {
          tmpDisabled = true;
        } else if (!data[item.value]) {
          tmpDisabled = true;
        }

        setIsCreateDisabled(tmpDisabled);

        if (item.value === context) {
          return {
            ...item,
            inputError: tmpInputError,
          };
        }
      }
      return item;
    });
    setFormData(tmpFormData);
  };

  const delayErrorChecking = useCallback(
    _.debounce((context, val) => onCheckError(context, val), 400),
    [],
  );

  const onChangeForm = (val, context) => {
    const temp = _.cloneDeep(newValuation);
    temp[context] = val;
    setNewValuation(temp);
    delayErrorChecking(context, temp);
  };

  return (
    <>
      <h4 className="fs-2 fw-400 mb-20">
        Get an indicative valuation for a property or a corporate valuation with
        IIV, Irhamy International Valuers. To get started, fill in the details
        below and a member of the team will get in touch with you straight away.
      </h4>
      <div className="grid-control">
        {formData.map((item) => (
          <section
            className={`${item.col === 6 ? "grid-half-col" : "grid-full-col"}`}
          >
            {item.type === "radio" && (
              <>
                <label className="at-form-input__title" required>
                  {item.label}
                </label>
                <AtlasRadioGroup
                  horizontal={true}
                  containerClassName={"w-100"}
                  checkedValue={newValuation[item.value]}
                  selectedRadioValue={(val) => onChangeForm(val, item.value)}
                  options={CreateFormOptions}
                />
              </>
            )}
            {item.type === "text" && (
              <>
                <label
                  className="at-form-input__title"
                  required={!item.optional}
                >
                  {item.label}
                </label>
                <CustomFormInput
                  type={item.formType}
                  placeholder={item.label}
                  value={newValuation[item.value]}
                  inputError={
                    !item.optional && item.inputError && item.errorMessage
                  }
                  required={!item.optional}
                  onChangeValue={(val) => {
                    let tmpValue = "";
                    tmpValue =
                      item.value === "contact_number"
                        ? val.replace(/[^0-9]/g, "")
                        : val;
                    onChangeForm(tmpValue, item.value);
                  }}
                />
              </>
            )}
          </section>
        ))}
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          className="btn-new btn-new--primary"
          disabled={isCreateDisabled}
          onClick={() => onChangeValuationsHOC(true, "showCreateConfirmation")}
        >
          Submit
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
      <ConfirmationModal
        open={showCreateConfirmation}
        title={"Are you sure?"}
        loading={onLoad}
        mode={"alert"}
        message={"Are you sure you want to submit now?"}
        positiveAction={() => createValuation(newValuation)}
        negativeAction={() =>
          onChangeValuationsHOC(false, "showCreateConfirmation")
        }
      />
      {onLoad && <LoadingModal />}
    </>
  );
};

export default CreateForm;
