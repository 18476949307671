import React, { Component } from "react";
import _ from "lodash";
import { GetFile } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    exportExcel = (id) =>
      GetFile(
        `/admin/order_batches/${id}/batch_download`,
        `Namecard_Batch_Export_${id}`,
        () => {},
        this.exportExcelError,
        this.load,
      );
    exportExcelError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadExportExcel={this.state.loading}
          exportExcel={this.exportExcel}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
