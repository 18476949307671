import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";

import AtlasButton from "components/Button";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import CustomRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";

import { inputField } from "./assets";

const Details = ({
  mode,
  banks,
  countries,
  branchRegions,
  selectedBranch,

  onClickSubmit,
  onChangeBranchHOC,
}) => {
  const [fieldData, setFieldData] = useState({
    name: "",
    company_reg_no: "",
    branch_region_id: "",
    country_id: "",
    door_access_domain_name: "",
    reference_id: "",
    is_registration_fee_required: false,
    registration_fee_currency: "",
    agent_registration_fee: "",
    bank_id: "",
    bank_account_number: "",
    bank_owner_name: "",
    is_visible: "",
    status: "",
  });
  const inputFieldList = inputField(branchRegions, countries, banks);

  useEffect(() => {
    if (mode === "update") setFieldData(selectedBranch);
  }, []);

  const onChangeCreateField = (val, context) => {
    let temp = _.cloneDeep(fieldData);
    temp[context] = val;
    setFieldData(temp);
  };

  const renderFieldContent = (item, index) => {
    switch (item.type) {
      case "radio":
        return (
          <CustomRadioGroup
            options={item.options}
            checkedValue={`${fieldData[item.value]}`}
            selectedRadioValue={(val) =>
              onChangeCreateField(val === "true", item.value)
            }
          />
        );
      case "select":
        return (
          <CustomSelect
            valueKey={"value"}
            className="w-100"
            required={true}
            selectItems={item.options}
            currentlySelected={_.find(item.options, {
              value: fieldData[item.value],
            })}
            updateSelect={(val) => onChangeCreateField(val.value, item.value)}
            placeholder={item.label}
          />
        );
      case "checkbox":
        return (
          <CustomCheckbox
            content={item.label}
            checked={fieldData[item.value]}
            onChangeCheckboxValue={(event) =>
              onChangeCreateField(event.target.checked, item.value)
            }
          />
        );
      default:
        return (
          <CustomFormInput
            type={item.type}
            required={true}
            value={fieldData[item.value]}
            onChangeValue={(val) => onChangeCreateField(val, item.value)}
            placeholder={item.label}
          />
        );
    }
  };

  return (
    <>
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <h4 className="fs-4 fw-600">Details</h4>
        </section>
        {inputFieldList.map((item, index) => (
          <Fragment key={index}>
            {item.children && (
              <>
                <section className="grid-full-col">
                  {(item.label === "Agent Registration" ||
                    fieldData.is_registration_fee_required) && (
                    <h4 className="fs-4 fw-600">{item.label}</h4>
                  )}
                </section>
                {item.children.map((subItem, index) => {
                  if (
                    subItem.value === "is_registration_fee_required" ||
                    fieldData.is_registration_fee_required
                  ) {
                    return (
                      <section className={`grid-${subItem.col || "third"}-col`}>
                        {subItem.value !== "is_registration_fee_required" && (
                          <h2 className="at-form-input__title">
                            {subItem.label}
                          </h2>
                        )}
                        {renderFieldContent(subItem, index)}
                      </section>
                    );
                  }
                })}
              </>
            )}
            {!item.children && (
              <section className="grid-third-col">
                <h2 className="at-form-input__title">{item.label}</h2>
                {renderFieldContent(item, index)}
              </section>
            )}
          </Fragment>
        ))}
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          className={`btn-new btn-new--${mode === "create" ? "primary" : "success"}`}
          children={mode === "create" ? "Submit" : "Update"}
          onClick={() => onClickSubmit(fieldData)}
        />
        <AtlasButton
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() =>
            onChangeBranchHOC(
              false,
              mode === "create" ? "showCreateModal" : "showUpdateModal",
            )
          }
        />
      </div>
    </>
  );
};

export default Details;
