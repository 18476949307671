import React, { Component } from "react";
import { IoIosTrash } from "react-icons/io";

import SVGIcon from "components/Icon";
import CustomButton from "components/Button";

class CustomCheckbox extends Component {
  state = {
    checked: false,
  };

  toggleChecked = () => {
    this.props.onChangeCheckboxValue(!this.props.checked);
  };

  onChangeCheckboxValue = (event) => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };

  render = () => {
    const { disabled, onClickDelete, onChangeText, value, labelStyle } =
      this.props;
    return (
      <div
        className={`at-item_list`}
        style={{
          display: "flex",
          margin: "1rem 0rem",
          gridGap: "1rem",
          ...labelStyle,
        }}
      >
        <CustomButton
          className={"btn-new btn-new--danger"}
          style={{ padding: "5px 10px" }}
          onClick={onClickDelete}
        >
          <IoIosTrash style={{ width: 23, height: 23 }} />
        </CustomButton>
        <input
          type="text"
          disabled={disabled}
          value={value}
          style={{ padding: "0.2rem 0.5rem" }}
          onChange={(event) => onChangeText(event.target.value)}
        />
      </div>
    );
  };
}

export default CustomCheckbox;
