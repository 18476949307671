import { AiFillMail } from 'react-icons/ai';
import { PiPhoneFill } from 'react-icons/pi';

const BlackPillsTemplate = ({ agent, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    backgroundColor: '#000',
    padding: 2,
    marginRight: 4,
    borderRadius: 99
  };

  return (
    <div style={{ color: '#fff', minWidth: '30.5%', width: 'max-content' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          padding: '8px 12px',
          borderRadius: 8,
          backgroundColor: `rgba(0, 0, 0, ${configuration?.agentDetailsOpacity})`
        }}
      >
        <img
          alt={agent.display_name}
          src={agent.avatar_url}
          style={{
            width: 60,
            height: 60,
            objectFit: 'cover',
            objectPosition: 'top',
            borderRadius: 99,
            backgroundColor: '#eee'
          }}
        />
        <div>
          <p style={{ fontSize: 10, fontWeight: 600, marginBottom: 1 }}>
            {agent.license_number}
          </p>
          <p style={{ fontSize: 14, fontWeight: 600, marginBottom: 6 }}>
            {agent.display_name}
          </p>
          <p style={{ ...detailStyle, marginBottom: 2 }}>
            <PiPhoneFill style={iconStyle} />
            {agent.mobile_contact_number}
          </p>
          <p style={detailStyle}>
            <AiFillMail style={iconStyle} />
            {agent.email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlackPillsTemplate;
