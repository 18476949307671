import React, { Component } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { HiArrowCircleLeft } from "react-icons/hi";
import { FaInfoCircle } from "react-icons/fa";

import SVGIcon from "components/Icon";
import AtlasIcon from "components/Icon/atlasIcon";

import defaultAvatar from "assets/images/av-male.jpg";
import { getColorBadge } from "dictionary/badgeColor";
import { numberWithCommas } from "utils/thousandSeparator";

const styles = {
  popper: {
    opacity: 1,
  },
};

const TierColor = [
  { value: 0, color: "#EA580C" },
  { value: 1, color: "#2563EB" },
  { value: 2, color: "#0D9488" },
  { value: 3, color: "#7E3AF2" },
  { value: 4, color: "#D61F69" },
  { value: 5, color: "#CA8A04" },
  { value: 6, color: "#0EA5E9" },
  { value: 7, color: "#65A30D" },
  { value: 8, color: "#5850EC" },
  { value: 9, color: "#C026D3" },
];

const statusBadge = [
  { id: 1, value: "ACTIVE", colorName: "Green" },
  { id: 2, value: "DELETED", colorName: "Red" },
  { id: 3, value: "TERMINATED", colorName: "Grey" },
  { id: 4, value: "RESIGNED", colorName: "Blue" },
  { id: 5, value: "INACTIVE", colorName: "Grey" },
  { id: 6, value: "UNDER OBSERVATION", colorName: "Yellow" },
];

const CustomTooltip = withStyles(styles)(Tooltip);

class TreeViewChild extends Component {
  state = {
    tooltipShow: false,
  };

  hoverActions(param) {
    this.setState({ tooltipShow: param === "enter" });
  }

  mobileCheck = () => {
    return window.innerWidth <= 576;
  };

  filterPersonalSales = () => {
    const {
      projectPersonalBookedSales,
      projectPersonalConvertedSales,
      subsalesPersonalBookedSales,
      subsalesPersonalConvertedSales,
      rentalPersonalBookedSales,
      rentalPersonalConvertedSales,
    } = this.props.item;
    let tmp = 0;
    for (let i = 0; i < this.props.filter.length; i++) {
      switch (this.props.filter[i]) {
        case "Projects":
          tmp =
            tmp + projectPersonalBookedSales + projectPersonalConvertedSales;
          break;
        case "Sales":
          tmp =
            tmp + subsalesPersonalBookedSales + subsalesPersonalConvertedSales;
          break;
        case "Rent":
          tmp = tmp + rentalPersonalBookedSales + rentalPersonalConvertedSales;
          break;
      }
    }
    return tmp;
  };

  filterGroupSales = () => {
    const {
      projectGroupBookedSales,
      projectGroupConvertedSales,
      subsalesGroupBookedSales,
      subsalesGroupConvertedSales,
      rentalGroupBookedSales,
      rentalGroupConvertedSales,
    } = this.props.item;
    let tmp = 0;
    for (let i = 0; i < this.props.filter.length; i++) {
      switch (this.props.filter[i]) {
        case "Projects":
          tmp = tmp + projectGroupBookedSales + projectGroupConvertedSales;
          break;
        case "Sales":
          tmp = tmp + subsalesGroupBookedSales + subsalesGroupConvertedSales;
          break;
        case "Rent":
          tmp = tmp + rentalGroupBookedSales + rentalGroupConvertedSales;
          break;
      }
    }
    return tmp;
  };

  renderModalInfo = (item) => {
    const { firstElementID, filter } = this.props;
    return (
      <div
        className={`agent-details-tooltip`}
        style={{
          pointerEvents: "none",
          display: this.state.tooltipShow ? "block" : "none",
          top: firstElementID === item.agentID ? "20px" : "auto",
          bottom: firstElementID === item.agentID ? "auto" : "20px",
        }}
      >
        <p className="tooltip-text tooltip-text-title">{`${item.agentName} - ${item.status}`}</p>
        <p className="tooltip-text">{`Job Title: ${item.jobTitleName}`}</p>
        <p className="tooltip-text">{`Contact: ${item.mobileNumber}`}</p>
        {filter.length > 0 && <hr className="tooltip-hr" />}
        {_.includes(filter, "Projects") && (
          <>
            <p className="tooltip-text tooltip-text-title">{"Project Sales"}</p>
            <p className="tooltip-text">{`Personal Booked: ${item.projectPersonalBookedSales ? numberWithCommas(item.projectPersonalBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Personal Converted: ${item.projectPersonalConvertedSales ? numberWithCommas(item.projectPersonalConvertedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Booked: ${item.projectGroupBookedSales ? numberWithCommas(item.projectGroupBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Converted: ${item.projectGroupConvertedSales ? numberWithCommas(item.projectGroupConvertedSales.toFixed(0)) : "N/A"}`}</p>
          </>
        )}
        {_.includes(filter, "Sales") && (
          <>
            <p className="tooltip-text tooltip-text-title">
              {"Subsales - Sale"}
            </p>
            <p className="tooltip-text">{`Personal Booked: ${item.subsalesPersonalBookedSales ? numberWithCommas(item.subsalesPersonalBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Personal Converted: ${item.subsalesPersonalConvertedSales ? numberWithCommas(item.subsalesPersonalConvertedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Personal Agency Commision: ${item.subsalesPersonalAgencyCommission ? numberWithCommas(item.subsalesPersonalAgencyCommission.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Booked: ${item.subsalesGroupBookedSales ? numberWithCommas(item.subsalesGroupBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Converted: ${item.subsalesGroupConvertedSales ? numberWithCommas(item.subsalesGroupConvertedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Agency Commision: ${item.subsalesGroupAgencyCommission ? numberWithCommas(item.subsalesGroupAgencyCommission.toFixed(0)) : "N/A"}`}</p>
          </>
        )}
        {_.includes(filter, "Rent") && (
          <>
            <p className="tooltip-text tooltip-text-title">
              {"Subsales - Rental"}
            </p>
            <p className="tooltip-text">{`Personal Booked: ${item.rentalPersonalBookedSales ? numberWithCommas(item.rentalPersonalBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Personal Converted: ${item.rentalPersonalConvertedSales ? numberWithCommas(item.rentalPersonalConvertedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Personal Agency Commision: ${item.rentalPersonalAgencyCommission ? numberWithCommas(item.rentalPersonalAgencyCommission.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Booked: ${item.rentalGroupBookedSales ? numberWithCommas(item.rentalGroupBookedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Converted: ${item.rentalGroupConvertedSales ? numberWithCommas(item.rentalGroupConvertedSales.toFixed(0)) : "N/A"}`}</p>
            <p className="tooltip-text">{`Group Agency Commision: ${item.rentalGroupAgencyCommission ? numberWithCommas(item.rentalGroupAgencyCommission.toFixed(0)) : "N/A"}`}</p>
          </>
        )}
      </div>
    );
  };

  render = () => {
    const {
      item,
      refs,
      searchResult,
      onClickPersonal,
      onClickTeam,
      currentSearchId,
    } = this.props;

    const renderLiStyle = () => {
      let teirColorValue = (item.tier % 5) + 1;
      let backgroundColor = _.find(TierColor, { value: teirColorValue }).color;

      return {
        borderLeft:
          item.isExpand && item.children && item.children.length > 0
            ? `2px solid ${backgroundColor}`
            : "2px solid transparent",
      };
    };

    const renderTier = () => {
      let teirColorValue = (item.tier % 5) + 1;
      let backgroundColor = _.find(TierColor, { value: teirColorValue }).color;

      return (
        <small className="agent-badge ml-2" style={{ backgroundColor }}>
          T{item.tier}
        </small>
      );
    };

    const renderTeamCount = () => {
      if (item.children && item.children.length > 0) {
        return (
          <span className="agent-badge" style={{ backgroundColor: "#111827" }}>
            <AtlasIcon svgHref={"atlas-profile2user"} />
            {item.children.length}
          </span>
        );
      }
    };

    const renderExpandIcon = () => {
      let teirColorValue = (item.tier % 5) + 1;
      let fill = _.find(TierColor, { value: teirColorValue }).color;

      return (
        <div
          className="agent-expand-icon"
          onClick={() => this.props.toggleExpand(item.agentID)}
        >
          {item.children?.length > 0 && item.isExpand ? (
            <AtlasIcon svgHref={"atlas-arrow-down"} style={{ fill }} />
          ) : (
            <AtlasIcon svgHref={"atlas-record-circle"} style={{ fill }} />
          )}
        </div>
      );
    };

    const renderStatus = () => {
      const { colorName = "" } =
        _.find(statusBadge, { value: item.status }) || {};

      return (
        <div
          className="at-status_badge ml-2"
          style={{
            fontSize: 10,
            padding: "0px 6px",
            ...getColorBadge(colorName),
          }}
        >
          {item.status}
        </div>
      );
    };

    return (
      <li className="tree-branch" style={renderLiStyle()}>
        <div className="agent-details">
          <div className="d-flex flex-wrap align-items-start">
            {renderExpandIcon()}
            <div
              className="agent-name-container"
              ref={(ref) => (refs[item.agentID] = ref)}
              onClick={() => this.props.toggleExpand(item.agentID)}
              onMouseOver={
                this.mobileCheck() ? () => {} : () => this.hoverActions("enter")
              }
              onMouseOut={
                this.mobileCheck() ? () => {} : () => this.hoverActions("leave")
              }
            >
              {this.renderModalInfo(item)}
              <img
                src={defaultAvatar}
                alt="avatar"
                style={{ borderRadius: "50%", width: 40, height: 40 }}
              />
              <div className="ml-2">
                <p className="at-form-input__title">
                  {item.agentName}
                  {renderTier()}
                  {renderTeamCount()}
                </p>
                <p
                  className="d-flex align-items-center"
                  style={{ color: "#374151", fontSize: 12 }}
                >
                  {item.jobTitleName}
                  {renderStatus()}
                </p>
              </div>
              {currentSearchId && currentSearchId === item.agentID && (
                <Tooltip title={"Current Search"} placement={"bottom"}>
                  <HiArrowCircleLeft
                    style={{
                      width: 30,
                      height: 30,
                      marginLeft: 15,
                      color: "#EA580C",
                    }}
                  />
                </Tooltip>
              )}
            </div>
            {searchResult.length > 0 && item.agentID === searchResult[0] && (
              <div className="agent-filter-tag">
                <span>First result</span>
              </div>
            )}
            {searchResult.length > 0 &&
              item.agentID === _.last(searchResult) && (
                <div className="agent-filter-tag">
                  <span>Last result</span>
                </div>
              )}
          </div>
          <div className="agent-commission-container">
            <span className="agent-commission">
              <AtlasIcon
                className={"agent-commission-icon svg-icon-call"}
                svgHref={"atlas-call"}
              />
              {item.mobileNumber || "N/A"}
            </span>
            <CustomTooltip
              placement={"left"}
              title={<span style={{ fontSize: 14 }}>Personal Sales</span>}
            >
              <span
                className="agent-commission"
                onClick={() => onClickPersonal(item)}
              >
                <AtlasIcon
                  className={"agent-commission-icon svg-icon-person"}
                  svgHref={"atlas-frame"}
                />
                <span>
                  {numberWithCommas(this.filterPersonalSales().toFixed(0))}
                </span>
              </span>
            </CustomTooltip>
            <CustomTooltip
              placement={"left"}
              title={<span style={{ fontSize: 14 }}>Team Sales</span>}
            >
              <span
                className="agent-commission"
                onClick={() => onClickTeam(item)}
              >
                <AtlasIcon
                  className={"agent-commission-icon svg-icon-team"}
                  svgHref={"atlas-profile2user"}
                />
                <span>
                  {numberWithCommas(this.filterGroupSales().toFixed(0))}
                </span>
              </span>
            </CustomTooltip>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 70 }}
            >
              {this.mobileCheck() ? (
                <button
                  className={`btn-new btn-new--secondary w-100`}
                  onClick={() => onClickPersonal(item)}
                >
                  Sales Summary
                </button>
              ) : (
                <button
                  className={`at-table-icon-button`}
                  onClick={() => onClickPersonal(item)}
                >
                  <AtlasIcon
                    className="svg-icon-info"
                    svgHref={"atlas-info-circle"}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        {item.isExpand && this.props.childContent}
      </li>
    );
  };
}

export default TreeViewChild;
