import React, { useState, useMemo } from "react";
import _ from "lodash";
import { compose } from "redux";

import CustomTabs from "components/Tab/new";
import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import SubsalesActivityLogs from "../containers/Activity";
import SubsalesSubmissions from "../containers/Submission";
import SubsalesParticipants from "../containers/Participants";
import SubsalesClaimForm from "../containers/ClaimForm";
import SubsalesDocuments from "../containers/Document";
import SubsalesMessages from "../containers/Message";
import DocsFinance from "../containers/DocsFinance";
import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";
import FormChart from "components/Chart/form";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import SectionExternalAgent from "../containers/ClaimForm/ClaimFormSections/externalAgent";

import { getColorBadge } from "dictionary/badgeColor";
import isEmptyValue from "utils/isEmpty";
import ApptLetterHOC from "../actions/appointmentLetter";
import ContactHOC from "actions/contact"

const sections = [
  { label: "Claim Form", icon: <AtlasIcon svgHref="atlas-building-3" /> },
  {
    label: "Participants",
    icon: <SvgIcon svgHref="confirmation-form_participants" />,
  },
  {
    label: "Documents",
    icon: <SvgIcon svgHref="confirmation-form_document-text" />,
  },
  { label: "Submit Claims", icon: <AtlasIcon svgHref="atlas-receipt-1" /> },
  {
    label: "Docs from Finance",
    icon: <AtlasIcon svgHref="svg-iconsax-receipt" />,
  },
  { label: "Messages", icon: <AtlasIcon svgHref="svg-iconsax-message-text" /> },
  { label: "Activity", icon: <AtlasIcon svgHref="svg-iconsax-chart" /> },
];

const subsaleRepresentativeBadge = [
  {
    id: 1,
    value: 1,
    label: "Represent Vendor",
    badgeColor: "Yellow",
  },
  {
    id: 2,
    value: 2,
    label: "Represent Purchaser",
    badgeColor: "Green",
  },
  {
    id: 3,
    value: 3,
    label: "Represent Both",
    badgeColor: "Blue",
  },
];
const subrentRepresentativeBadge = [
  {
    id: 1,
    value: 1,
    label: "Represent Landlord",
    badgeColor: "Yellow",
  },
  {
    id: 2,
    value: 2,
    label: "Represent Tenant",
    badgeColor: "Green",
  },
  {
    id: 3,
    value: 3,
    label: "Represent Both",
    badgeColor: "Blue",
  },
];

const UpdateClaimForm = ({
  contacts,
  banks,
  data,
  showSelectForm,
  enableValidateBank,
  agencies,
  selected_claim,
  history,
  onLoadApptLetter,
  commRate,
  townshipsList,
  showExternalAgent,
  showAssignContactModal,
  unattachedAppointmentLetters,
  selectedAttachedApptLetter,
  showContactLoadingModal,
  
  getBanks,
  submitApptForm,
  onChangeSubsaleClaimsHOC,
  onChangeUpdateSelectedClaim,
  updateSelectedClaim,
  submitSelectedClaim,
  getCurrentUserContactList,
  getClaimsList,
  getSelectedClaim,
  getSelectedTownShip,
  getUnattachedApptLetters,
  onChangeAppointmentLetterHOC,
}) => {
  const [selectedSection, onSelectSection] = useState("Claim Form");
  const {
    confirmation_form_number,
    id,
    representative_id,
    sub_sale_type_id,
  } = selected_claim;
  const tmpConfitmationFormNumber = `${!isEmptyValue(confirmation_form_number) ? `/ ${confirmation_form_number}` : ""}`;
  const setShowExternalAgent = (val) => onChangeSubsaleClaimsHOC(val, "showExternalAgent");

  const representativeEntity = useMemo(() => {
    if (representative_id !== null) {
      const tmpRepresentative =
        sub_sale_type_id === 1
          ? _.find(subsaleRepresentativeBadge, { value: representative_id })
          : _.find(subrentRepresentativeBadge, { value: representative_id });
      return tmpRepresentative || {};
    }

    return {};
  }, [sub_sale_type_id, representative_id]);

  const { label: representativeLabel, badgeColor } = representativeEntity;

  const onChangeSelect = (value) => {
    onSelectSection(value);
    if (value === "Claim Form") {
      getSelectedClaim(id);
    }
  };

  const renderFormsChart = () => {
    const { forms_chart, invoicing_branch_id, type_id } = selected_claim;

    return (
      forms_chart !== null && (
        <FormChart
          history={history}
          infoData={{
            invoicing_branch_id: invoicing_branch_id,
            type_id: type_id
          }}
          currentFormType={"Claim Form"}
          forms_chart={forms_chart}
          onClickButton={(data) => {
            if ((data.type = "Appointment Letter")) {
              getUnattachedApptLetters(selected_claim.id);
            }
          }}
        />
      )
    );
  };

  const renderAttachFormModal = () => {
    const tmpSelectedAttachedForm = _.find(unattachedAppointmentLetters, {
      id: selectedAttachedApptLetter?.id,
    });

    if (showSelectForm) {
      return (
        <ModalDialog
          title={"Attach Appointment Letter"}
          fullHeight={false}
          onClose={() => onChangeAppointmentLetterHOC(false, "showSelectForm")}
          children={
            <div className="at-apt_letter-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose an option to attach appointment letter:
              </p>
              <div className="mt-3 mb-4" style={{ height: 250 }}>
                <h2 className="at-form-input__title mr-10">Form Number</h2>
                <CustomTypeahead
                  showClearButton
                  typeaheadId={"unclaimedAppointmentForm"}
                  options={unattachedAppointmentLetters || []}
                  selectedValue={
                    tmpSelectedAttachedForm ? [tmpSelectedAttachedForm] : []
                  }
                  labelKey={"transaction_number"}
                  filterBy={["transaction_number", "property_address"]}
                  onSelect={(val) => {
                    if (val && val.length > 0) {
                      return onChangeAppointmentLetterHOC(val[0], 'selectedAttachedApptLetter');
                    }
                    return onChangeAppointmentLetterHOC("", 'selectedAttachedApptLetter');
                  }}
                  childrenHead={(rowItem) => (
                    <p>{rowItem?.transaction_number}</p>
                  )}
                  highlighterData={[
                    (option = {}) => option?.property_address,
                  ]}
                />
              </div>
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                <button
                  disabled={isEmptyValue(selectedAttachedApptLetter)}
                  className="btn-new btn-new--primary"
                  onClick={() => submitApptForm(selected_claim.id, selectedAttachedApptLetter.id)}>
                  Submit
                </button>
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => onChangeAppointmentLetterHOC(false, "showSelectForm")}>
                  Cancel
                </button>
              </div>
            </div>
          }
        />
      );
    }
  };

  const { sideMenuExpand } = data.sideMenuReducer;

  return (
    <>
      <CustomCard
        className={"h-100 px-1 p-sm-4"}
        containerStyle={{ background: "#F3F4F6" }}
        cardContent={
          <div style={{ height: "auto", minHeight: "80vh" }}>
            <div className="at-card__header">
              <AtlasCloseButton
                containerClass={"subsaleclaim-close-button"}
                onClick={() =>
                  onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")
                }
              />
              <h4 className="at-card__title">{`Edit Claim Form ${tmpConfitmationFormNumber}`}</h4>
              {representative_id && representative_id !== null && (
                <div
                  className="at-status_badge text-center"
                  style={{ ...getColorBadge(badgeColor) }}
                >
                  {representativeLabel}
                </div>
              )}
            </div>
            {renderFormsChart()}
            <CustomTabs
              sections={sections}
              selectedSection={selectedSection}
              onSelectSection={onChangeSelect}
            />
            <div>
              {selectedSection === "Claim Form" && (
                <SubsalesClaimForm
                  banks={banks}
                  agencies={agencies}
                  selected_claim={selected_claim}
                  menuExpand={sideMenuExpand}
                  history={history}
                  onSelectSection={onChangeSelect}
                  townshipsList={townshipsList}
                  getSelectedTownShip={getSelectedTownShip}
                  getBanks={getBanks}
                  onCloseForm={() =>
                    onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")
                  }
                  onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                  onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
                  getSelectedClaim={getSelectedClaim}
                  updateSelectedClaim={updateSelectedClaim}
                  onClickNav={(val) => onChangeSelect(val)}
                  setShowExternalAgent={setShowExternalAgent}
                />
              )}
              {selectedSection === "Participants" && (
                <SubsalesParticipants
                  data={data}
                  banks={banks}
                  selected_claim={selected_claim}
                  showAssignContactModal={showAssignContactModal}
                  menuExpand={sideMenuExpand}
                  contacts={contacts}
                  showContactLoadingModal={showContactLoadingModal}
                  getCurrentUserContactList={getCurrentUserContactList}
                  onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                  onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
                  onClickNav={(val) => onChangeSelect(val)}
                  onCloseForm={() =>
                    onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")
                  }
                  getBanks={getBanks}
                  getSelectedClaim={getSelectedClaim}
                  updateSelectedClaim={updateSelectedClaim}
                />
              )}
              {selectedSection === "Documents" && (
                <SubsalesDocuments
                  menuExpand={sideMenuExpand}
                  selected_claim={selected_claim}
                  submitSelectedClaim={submitSelectedClaim}
                  onClickNav={(val) => onChangeSelect(val)}
                  onCloseForm={() =>
                    onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")
                  }
                />
              )}
              {selectedSection === "Submit Claims" && (
                <SubsalesSubmissions
                  menuExpand={sideMenuExpand}
                  commRate={commRate}
                  selected_claim={selected_claim}
                  getClaimsList={getClaimsList}
                  onClickNav={(val) => onChangeSelect(val)}
                  onCloseForm={() =>
                    onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")
                  }
                />
              )}
              {selectedSection === "Messages" && (
                <SubsalesMessages selected_claim={selected_claim} />
              )}
              {selectedSection === "Activity" && (
                <SubsalesActivityLogs
                  selected_claim={selected_claim}
                />
              )}
              {selectedSection === "Docs from Finance" && (
                <DocsFinance selected_claim={selected_claim} />
              )}
            </div>
            {showExternalAgent && (
              <ModalDialog
                title={"Add Co-Agency"}
                fullWidth={true}
                onClose={() => {
                  getSelectedClaim(id)
                  setShowExternalAgent(false)
                }}
                children={
                  <SectionExternalAgent
                    from={"Agency Fee"}
                    modalForm={true}
                    agencies={agencies}
                    banks={banks}
                    enableValidateBank={enableValidateBank}
                    selected_claim={selected_claim}
                    onToggleClose={disableRefreshClaim => {
                      if(!disableRefreshClaim) {
                        getSelectedClaim(id)
                      }
                      setShowExternalAgent(false)
                    }}
                    onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                    onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
                    updateSelectedClaim={updateSelectedClaim}
                  />
                } />
            )}
          </div>
        }
      />
      {renderAttachFormModal()}
      {onLoadApptLetter && <LoadingModal />}
    </>
  );
};

export default compose(ApptLetterHOC, ContactHOC)(UpdateClaimForm);
