import _ from "lodash";

const isEmptyValue = (value) => {
  if (value !== undefined && value !== null) {
    if (typeof value === "number") {
      return isNaN(value);
    } else if (typeof value === "string") {
      return value.trim().length === 0;
    } else if (typeof value === "object") {
      return _.isEmpty(value);
    } else if (typeof value === "boolean") {
      return false;
    }
  }
  return true;
};

export default isEmptyValue;
