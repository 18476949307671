import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtlasTable from "components/NewPaginatedTable";
import AtlasConfirmationDialog from "components/Modal/delete";
import CreateForm from "./Create";
import EditForm from "./Edit";

import AdminHOC from "./actions/admin";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesAdminsHOC from "./actions/subsalesAdmin";
import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";

export const StatusColor = [
  { value: "On Call", colorName: "Green" },
  { value: "Offline", colorName: "Red" },
  { value: "Disabled", colorName: "Yellow" },
];

const HeaderData = [
  { header: "Admin", accessor: "admin_name" },
  { header: "Total assigned cases", accessor: "assigned_count" },
  { header: "Type", accessor: "type" },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge mt-1"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
  { header: "Assigned Branch", accessor: "assigned_branches" },
];

class SubsalesAdminManagement extends Component {
  componentDidMount = () => {
    this.props.getAdmins();
    this.props.getSubsalesAdmins(1, "");
    this.props.getAllDictionaryData();
    this.props.getBranches();
    initGA("/dashboard/admin/forms/admin-roles-management");
  };

  renderEditSubsaleAdminDialog = () => {
    if (this.props.showEditSubsaleAdminDialog) {
      return (
        <EditForm
          data={this.props.data}
          selectedData={this.props.selected_subsales_admin}
          onClose={() =>
            this.props.onChangeSubsalesAdminHOC(
              false,
              "showEditSubsaleAdminDialog",
            )
          }
          onClickSubmit={this.props.updateSubsalesAdmin}
          branchOptions={
            this.props.data.branchReducer
              ? this.props.data.branchReducer.branch
              : []
          }
          onLoadSubsalesAdmins={this.props.onLoadSubsalesAdmins}
        />
      );
    }
  };

  renderNewSubsaleAdminDialog = () => {
    if (this.props.showNewSubsaleAdminDialog) {
      return (
        <CreateForm
          context={"create"}
          admins={this.props.admins}
          admin_roles={this.props.data.dictionaryReducer.admin_roles}
          onClose={() =>
            this.props.onChangeSubsalesAdminHOC(
              false,
              "showNewSubsaleAdminDialog",
            )
          }
          onClickSubmit={this.props.createSubsalesAdmin}
          branchOptions={
            this.props.data.branchReducer
              ? this.props.data.branchReducer.branch
              : []
          }
          onLoadSubsalesAdmins={this.props.onLoadSubsalesAdmins}
        />
      );
    }
  };

  renderCardContent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div className="at-card-container">
        <AtlasTable
          className={"mb-20 at-table--personal-sales"}
          showCreateButton={false}
          showCloseButton={false}
          columns={HeaderData}
          actionColumn={true}
          rowData={this.props.subsales_admins.data}
          actionColumnContent={[
            {
              name: role === "Super Admin" ? "edit" : "view",
              disabled: () => role !== "Super Admin",
              tooltipContent: role !== "Super Admin" && (
                <p style={{ maxWidth: 100 }}>
                  Please request senior admin for updating the role status.
                </p>
              ),
              onClick: (val) => {
                this.props.getSelectedSubsaleAdmin(val.id);
              },
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: () => role === "Super Admin",
              onClick: (val) => {
                Promise.all([
                  this.props.onChangeSubsalesAdminHOC(
                    val,
                    "selected_subsales_admin",
                  ),
                ]).then(() => {
                  this.props.onChangeSubsalesAdminHOC(
                    true,
                    "showDeleteConfirmation",
                  );
                });
              },
            },
          ]}
          meta={this.props.subsales_admins.meta}
          searchParams={role === "Super Admin" ? this.props.searchParams : []}
          onChangeSearchParams={(val) =>
            this.props.onChangeSubsalesAdminHOC(val, "searchParams")
          }
          totalPages={this.props.subsales_admins_total_page}
          getListAPI={this.props.getSubsalesAdmins}
        />
        <AtlasConfirmationDialog
          open={this.props.showDeleteConfirmation}
          title={"Remove Admin"}
          message={"Are you sure to remove the selected admin?"}
          positiveText={"Remove"}
          positiveAction={() =>
            this.props.deleteSubsalesAdmin(
              this.props.selected_subsales_admin.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeSubsalesAdminHOC(false, "showDeleteConfirmation")
          }
        />
      </div>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Admin Roles"}
          moduleIcon={"icon-admin-roles"}
          actionButton={[
            {
              label: "Assign Admin to Role",
              onShow: role === "Super Admin",
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeSubsalesAdminHOC(
                  true,
                  "showNewSubsaleAdminDialog",
                ),
            },
          ]}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={this.renderCardContent()}
        />
        {this.renderNewSubsaleAdminDialog()}
        {this.renderEditSubsaleAdminDialog()}
        {(this.props.onLoadAdmins || this.props.onLoadSubsalesAdmins) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AdminHOC,
  VersionCheckHOC,
  SubsalesAdminsHOC,
)(SubsalesAdminManagement);
