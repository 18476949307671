import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, GetFile, Post, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showCreateModal: false,
      showDetailsModal: false,
      showStatModal: false,
      showEmailDeliveryModal: false,

      searchParams: [],

      presentations: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedPresentation: null,
      presentationPages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangePresentationHOC = (val, context) =>
      this.setState({ [context]: val });

    getPresentation = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations?${search}page=${page}`,
        this.getPresentationSuccess,
        this.getPresentationError,
        this.load,
      );
    };
    getPresentationSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      let tempData = _.map(payload.data, (item) => ({
        ...item,
        expiry_date: item.expiry_date
          ? Moment(item.expiry_date).format("DD MMM YYYY")
          : null,
      }));

      this.setState({
        presentations: {
          data: tempData,
          meta: payload.meta,
        },
        presentationPages: tmpTotalPages,
      });
    };
    getPresentationError = (error) => requestError(error);

    onDownloadPresentationPDF = (data) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return GetFile(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${data.id}/download`,
        data.transaction_number,
        this.onDownloadPresentationPDFSuccess,
        this.onDownloadPresentationPDFError,
        this.load,
      );
    };
    onDownloadPresentationPDFSuccess = () => {};
    onDownloadPresentationPDFError = (error) => requestError(error);

    createPresentation = (dataToSubmit) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Post(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations`,
        dataToSubmit,
        this.createPresentationSuccess,
        this.createPresentationError,
        this.load,
      );
    };
    createPresentationSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getPresentation(currentPage, searchParams);
      this.getSelectedPresentation(payload.id);
      this.setState({ showCreateModal: false });
    };
    createPresentationError = (error) => requestError(error);

    getSelectedPresentation = (id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${id}`,
        this.getSelectedPresentationSuccess,
        this.getSelectedPresentationError,
        this.load,
      );
    };
    getSelectedPresentationSuccess = (payload) => {
      let tmpClients = [...(payload.clients || [])];
      let uniqueClients = [];
      tmpClients = tmpClients.filter((client) => {
        if (!uniqueClients.find((item) => item.id === client.id)) {
          return uniqueClients.push(client);
        }
      });
      this.setState({
        showDetailsModal: true,
        selectedPresentation: {
          ...payload,
          clients: tmpClients,
          expiry_date: Moment(payload.expiry_date).format("DD MMM YYYY"),
          isPresentationPublished: payload.status === "Published",
        },
      });
    };
    getSelectedPresentationError = (error) => requestError(error);

    updatePresentation = (dataToSubmit) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Put(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${dataToSubmit.id}`,
        dataToSubmit,
        this.updatePresentationSuccess,
        this.updatePresentationError,
        this.load,
      );
    };
    updatePresentationSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("The presentation has been saved.");
      this.getSelectedPresentation(payload.id);
      this.getPresentation(currentPage, searchParams);
    };
    updatePresentationError = (error) => requestError(error);

    deliverPresentationEmail = (presentationId, client_ids, language) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Post(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/deliver`,
        { client_ids, language },
        this.deliverPresentationEmailSuccess,
        this.deliverPresentationEmailError,
        this.load,
      );
    };
    deliverPresentationEmailSuccess = (payload) => {
      requestSuccess("Email has been sent to selected clients");
      this.setState({ showEmailDeliveryModal: false });
    };
    deliverPresentationEmailError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            presentationPages={this.state.presentationPages}
            presentations={this.state.presentations}
            selectedPresentation={this.state.selectedPresentation}
            onLoadPresentation={this.state.loading}
            showCreateModal={this.state.showCreateModal}
            showDetailsModal={this.state.showDetailsModal}
            showEmailDeliveryModal={this.state.showEmailDeliveryModal}
            showStatModal={this.state.showStatModal}
            getPresentation={this.getPresentation}
            createPresentation={this.createPresentation}
            updatePresentation={this.updatePresentation}
            onDownloadPresentationPDF={this.onDownloadPresentationPDF}
            deliverPresentationEmail={this.deliverPresentationEmail}
            getSelectedPresentation={this.getSelectedPresentation}
            onChangePresentationHOC={this.onChangePresentationHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
