import React from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";

const typeOptions = [
  { label: "Sale", value: "1" },
  { label: "Rental", value: "2" },
];

const GenerateForm = ({
  title,
  loading,
  onSelectBranch,
  selectSubsaleType,
  invoicingBranch,
  invoicing_branch_id,
  type,
  generateForm,
  disableSubmitButton,
  onClickCloseFormGenerator,
}) => {
  return (
    <>
      <div className="grid-control grid_gap-2">
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Select a branch</h2>
          {invoicingBranch.length > 0 && (
            <CustomSelect
              className="w-100 mb-0"
              selectItems={invoicingBranch}
              defaultValue={""}
              placeholder={"Select a branch"}
              currentlySelected={_.find(invoicingBranch, {
                id: invoicing_branch_id ?? '',
              }) || {} }
              updateSelect={(val) => onSelectBranch(val ?? { id: "" })}
            />
          )}
          {invoicingBranch.length < 1 && (
            <h4 className="at-card__title mb-20">{"Loading branches"}</h4>
          )}
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Type</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={type}
            selectedRadioValue={(val) => selectSubsaleType(val)}
            options={typeOptions}
          />
        </section>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          disabled={disableSubmitButton}
          className={"btn-new btn-new--primary"}
          onClick={generateForm}
        >
          Create Now
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={onClickCloseFormGenerator}
        >
          Cancel
        </button>
      </div>
      {loading && <LoadingModal />}
    </>
  );
};

export default GenerateForm;
