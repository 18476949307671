import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      similarProperties: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      totalPages: [],
      search: [
        {
          label: "Property Address",
          value: "property_address_cont",
          type: "input",
          param: "",
        },
      ],
      loading: false,
      showSearchContent: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeSimilarPropertiesHOC = (key, val) => this.setState({ [key]: val });

    getSimilarProperties = (id, page, search) =>
      Get(
        `/admin/sub_sale_claims/${id}/similar_properties?${search}page=${page}`,
        this.getSimilarPropertiesSuccess,
        this.getSimilarPropertiesError,
        this.load,
      );
    getSimilarPropertiesSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      this.setState({
        similarProperties: payload,
        totalPages: tmpTotalPages,
      });
    };
    getSimilarPropertiesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            similarProperties={this.state.similarProperties}
            search={this.state.search}
            totalPages={this.state.totalPages}
            showSearchContent={this.state.showSearchContent}
            onLoadSimilarProperties={this.state.loading}
            getSimilarProperties={this.getSimilarProperties}
            onChangeSimilarPropertiesHOC={this.onChangeSimilarPropertiesHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
