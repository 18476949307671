import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Post } from "utils/axios";

const AgentHOC = (WrappedComponent) => {
  class AgentWrappedComponent extends Component {
    state = {
      agent: {},
      loading: false,
    };

    onChangeAgentHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    searchAgents = (contact) =>
      Post(
        `/registrations/search_referrer/${contact}`,
        "",
        this.searchAgentsSuccess,
        this.searchAgentsError,
        this.load,
      );
    searchAgentsSuccess = (payload) => this.setState({ agent: payload });
    searchAgentsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            agent={this.state.agent}
            searchAgents={this.searchAgents}
            onChangeAgentHOC={this.onChangeAgentHOC}
            onLoadAgent={this.state.loading}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(AgentWrappedComponent);
};

export default AgentHOC;
