import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      unclaimedForms: [],
    };

    load = param => this.setState({ loading: param });

    getUnclaimTransferForm = (id, representative_id) => {
      Get(
        `/admin/forms/confirmation_forms/${id}/unclaimed?representative_id=${representative_id}`,
        this.getUnclaimTransferFormSuccess,
        this.getUnclaimTransferFormError,
        this.load
      );
    };
    getUnclaimTransferFormSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.transaction_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedForms: tmp });
      }
    };
    getUnclaimTransferFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            unclaimedForms={this.state.unclaimedForms}
            onLoadUnclaim={this.state.loading}
            getUnclaimTransferForm={this.getUnclaimTransferForm}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
