import React, { useCallback } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from "components/RadioGroup";
import CustomSelect from "components/Select";
import CustomFormInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";
import SubsalesTooltip from "../components/tooltip";
import AtlasMultiSelect from "components/Select/multi";
import AtlasAutosuggest from "components/Autosuggest";
import CustomTypeahead from "components/Typeahead/new";

import { edgePropStateMap } from "../assets";
import {
  categories as CategoriesDictionary,
  unit_types,
  measurements,
  bedroom_options,
  bathroom_options,
  car_park_options,
  tenure_types,
  title_types,
  furnishing_statuses,
  directions,
  occupancies,
} from "dictionary/subsales";

const iPropertyType = [
  1, 2, 3, 4, 5, 6, 10, 11, 75, 76, 77, 78, 79, 80, 81, 82,
];

const SimpleForm = ({
  data,
  dataBasicInfo,
  autoSuggestOptions,
  autoSuggestLoading,

  getLocalised,
  townshipArray,
  subAreaArray,
  getSelectedTownShip,
  getSelectedSubArea,
  edgeAutoSuggest,
  onChangeNewHOC,
  onChangeSubsalesHOC,
}) => {
  const { countries, currencies } = data.dictionaryReducer;
  const subsaleTypeOptions = [
    { value: "rent", label: getLocalised('option.details.details.type_1', 'Sale') },
    { value: "sell", label: getLocalised('option.details.details.type_2', 'Rental') }
  ];
  const listingCategoryOptions = [
    {
      value: 1,
      label: getLocalised("option.categories.residential.residential", "Residential"),
    },
    {
      value: 2,
      label: getLocalised("option.categories.commercial.commercial", "Commercial")
    },
    {
      value: 3,
      label: getLocalised("option.categories.industrial.industrial", "Industrial"),
    },
    {
      value: 4,
      label: getLocalised("option.categories.agricultural.agricultural", "Agricultural"),
    },
    {
      value: 5,
      label: getLocalised("option.unknown", "Unknown"),
    },
  ];
  const detailsInfo = [
    {
      label: getLocalised('label.details.details.type', 'Type'),
      value: "subsalesType",
      type: "radio",
      col: "full",
      options: subsaleTypeOptions,
    },
    {
      label: getLocalised('label.details.details.listing_category', 'Listing Category'),
      value: "selectedListingCategory",
      type: "radio",
      col: "full",
      showToolTip: true,
      options: listingCategoryOptions,
      tooltip: "listingCategory",
    },
    {
      label: getLocalised('label.details.details.property_type', 'Property Type'),
      value: "selectedPropertyType",
      type: "multi-select",
      options: "tmpPropertyTypes",
    },
    {
      label: getLocalised('label.details.details.unit_type', 'Unit Type'),
      value: "selectedUnitType",
      type: "select",
      options: unit_types,
      showToolTip: true,
      tooltip: "unitType",
    },
    {
      label: getLocalised('label.details.details.tenure', 'Tenure'),
      options: tenure_types,
      value: "selectedTenure",
      type: "select",
      showToolTip: true,
      tooltip: "tenure",
      showJuwaiToolTip: false,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised('label.details.details.title_type', 'Title Type'),
      options: title_types,
      value: "selectedTitleType",
      type: "select",
    },
    {
      label: getLocalised('label.details.details.furnishing', 'Furnishing'),
      options: furnishing_statuses,
      value: "selectedFurnishing",
      type: "select",
      showToolTip: true,
      tooltip: "furnishing",
      showJuwaiToolTip: false,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised('label.details.details.occupancy', 'Occupancies'),
      options: occupancies,
      value: "selectedOccupied",
      type: "select",
      showToolTip: true,
      tooltip: "occupancies",
    },
  ];

  const addressInfo = [
    {
      label: getLocalised("label.details.address.property_name", "Property Name"),
      value: "property_name",
      renderContent: () => (
        <section className="grid-full-col position-relative">
          <div className={"d-flex"}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.address.property_name", "Property Name")}
            </h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={autoSuggestLoading}
            />
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showEdgeTooltip: true,
                tooltip: "propertyName",
              }}
            />
          </div>
          <i style={{ fontSize: 13, marginBottom: 10, color: "red" }}>
            {getLocalised(
              "label.details.helptext.address.property_name", 
              `Select the official name of the project/township from the drop-down list, or type it in manually if it isn"t in the list.`
            )}
          </i>
          <CustomTypeahead
            typeaheadId={"property_name"}
            options={autoSuggestOptions || []}
            containerStyle={{ marginTop: 6 }}
            filterBy={[
              "name_s_lower",
              "state_s_lower",
              "street_name_s_lower",
              "area_s_lower",
            ]}
            labelKey={"name_s_lower"}
            required={true}
            onSearch={(val) => onChangePropertyName(val)}
            onSelect={(val) =>
              val && val.length > 0 && onSelectPropertyName(val[0])
            }
            childrenHead={(rowItem) => <p>{rowItem.name_s_lower}</p>}
            highlighterData={[
              (option) =>
                `Area: ${option.area_s_lower} | State: ${option.state_s_lower}`,
            ]}
          />
        </section>
      ),
    },
    {
      label: getLocalised("label.details.address.property_address", "Property Address"),
      value: "address",
      type: "text",
      col: "full",
      error: "addressError",
      placeholder: getLocalised("label.details.placeholder.address.property_address", "Enter your address here"),
    },
    {
      label: getLocalised("label.details.address.hidden_address", "Hidden Address"),
      value: "hiddenAddress",
      type: "text",
      col: "full",
      error: "hiddenAddressError",
      placeholder: getLocalised("label.details.placeholder.address.hidden_address", "Enter your hidden address here"),
      helpText:
        "Please enter the actual address of the property, the address shown will not be made public unless approved by you. <br/> Your listing will not be able to utilise the recommendation engine if you leave blank or the address is not accurate.",
    },
    {
      label: getLocalised("label.details.address.country", "Country"),
      value: "selectedCountry",
      type: "select",
      col: "half",
      options: countries,
      showJuwaiToolTip: true,
      showJuwaiAsiaToolTip: true,
      tooltip: "country",
    },
    {
      label: getLocalised("label.details.address.state_province", "State / Province"),
      value: "selectedState",
      type: "select",
      col: "half",
      options: "tmpStates",
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
      tooltip: "state",
    },
    {
      label: getLocalised("label.details.address.area_city", "Area / City"),
      value: "township",
      renderContent: () => (
        <section className="grid-half-col">
          <div className={"d-flex"}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.address.area_city", "Area / City")}
            </h2>
            <SubsalesTooltip
              data={{
                showJuwaiAsiaToolTip: true,
                showJuwaiToolTip: true,
                showEdgeTooltip: true,
                tooltip: "townShip",
              }}
            />
          </div>
          <CustomTypeahead
            typeaheadId={"township"}
            options={townshipArray}
            labelKey={"name"}
            filterBy={["name"]}
            required={true}
            onSelect={(val) =>
              onChangeTownships(val && val.length > 0 ? val[0].name : "")
            }
            selectedValue={
              _.find(townshipArray, { name: dataBasicInfo.township })
                ? [_.find(townshipArray, { name: dataBasicInfo.township })]
                : []
            }
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
          />
        </section>
      ),
    },
    {
      label: getLocalised("label.details.address.sub_area", "Sub Area"),
      value: "sub_area",
      type: "autosuggest",
      col: "half",
      tooltip: "subArea",
      options: subAreaArray,
    },
    {
      label: getLocalised("label.details.address.postal_code", "Postal Code"),
      value: "postalCode",
      type: "text",
      col: "half",
      error: "postalCodeError",
      placeholder: getLocalised("label.details.placeholder.address.postal_code", "Enter your postal code here"),
    },
  ];

  const priceInfo = [
    {
      label: getLocalised("label.details.price.currency", "Currency"),
      value: "selectedCurrency",
      type: "select",
      col: "half",
      options: _.orderBy(currencies.name, ["label"], ["asc"]),
      showToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "currency",
    },
    {
      label: getLocalised("label.details.price.asking_price", "Asking Price"),
      value: "askingPrice",
      type: "text",
      col: "half",
      error: "askingPriceError",
      placeholder: getLocalised("label.details.placeholder.price.asking_price", "Enter your asking price here"),
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "askingPrice",
    },
    {
      label: 
      getLocalised(
        `label.details.price.asking_price_per_${
          dataBasicInfo.selectedMeasurement.name === "Square Feet" ? "square_feet" :
          dataBasicInfo.selectedMeasurement.name === "Square Meter" ? "square_meter" :
          dataBasicInfo.selectedMeasurement.name === "Hectare" ? "hectare" :
          "acres"
        }_unit`, 
       `Asking Price Per ${dataBasicInfo.selectedMeasurement.name} Measurement Unit`
      ),
      value: "askingPricePerMeasurementUnit",
      type: "text",
      col: "half",
      error: "askingPricePerMeasurementUnitError",
      placeholder: getLocalised("label.details.placeholder.price.asking_price_measurement", "Enter your asking price per measurement unit here"),
    },
    {
      label: getLocalised("label.details.price.monthly_maintenance_fee", "Monthly Maintenance Fee"),
      renderContent: () => (
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.price.monthly_maintenance_fee", "Monthly Maintenance Fee")}
          </h2>
          <CustomFormInput
            type="number"
            value={dataBasicInfo.monthlyMaintenanceFee}
            onChangeValue={(val) =>
              onChangeBasicFormField(val, "monthlyMaintenanceFee")
            }
            required={true}
          />
          <CustomCheckbox
            checked={dataBasicInfo.isMonthlyMaintainanceFeePerMeasurementUnit}
            content={
              getLocalised(
              `label.details.price.monthly_maintenance_fee_${
                dataBasicInfo.selectedMeasurement.name === "Square Feet" ? "sqft" :
                dataBasicInfo.selectedMeasurement.name === "Square Meter" ? "sqm" :
                dataBasicInfo.selectedMeasurement.name === "Hectare" ? "hectare" :
                "acres"
              }`, 
              `Monthly Maintenance Fee is per ${dataBasicInfo.selectedMeasurement.name} unit`
            )}            
            onChangeCheckboxValue={() =>
              onChangeBasicFormField(
                !dataBasicInfo.isMonthlyMaintainanceFeePerMeasurementUnit,
                "isMonthlyMaintainanceFeePerMeasurementUnit",
              )
            }
          />
        </section>
      ),
    },
  ];

  const specificationsInfo = [
    {
      label: getLocalised("label.details.specification.measurement_unit", "Measurement Unit"),
      value: "selectedMeasurement",
      type: "select",
      col: "half",
      options: measurements,
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "measurementUnit",
    },
    {
      label: getLocalised("label.details.specification.built_up_utilised_area", "Built-up / Utilised Area"),
      value: "builtUp",
      type: "text",
      col: "half",
      error: "builtUpError",
      placeholder: getLocalised("label.details.placeholder.specification.built_up_utilised_area", "Enter your built up value here"),
      showToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "builtUp",
    },
    {
      label: getLocalised("label.details.specification.land_area", "Land Area"),
      value: "landArea",
      type: "text",
      col: "half",
      error: "landAreaError",
      placeholder: getLocalised("label.details.placeholder.specification.land_area", "Enter your land area value here"),
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      tooltip: "landArea",
    },
    {
      label: getLocalised("label.details.specification.carparks", "Carparks"),
      value: "selectedCarpark",
      type: "select",
      col: "half",
      options: car_park_options,
      showJuwaiToolTip: true,
      tooltip: "carPark",
    },
    {
      label: getLocalised("label.details.specification.bedrooms", "Bedrooms"),
      value: "selectedBedroom",
      type: "select",
      col: "half",
      options: bedroom_options,
      showToolTip: true,
      tooltip: "bedRoom",
      showJuwaiToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised("label.details.specification.bathrooms", "Bathrooms"),
      value: "selectedBathroom",
      type: "select",
      col: "half",
      options: bathroom_options,
      showToolTip: true,
      tooltip: "bathRoom",
      showJuwaiToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised("label.details.specification.direction", "Direction"),
      value: "selectedDirection",
      type: "select",
      col: "half",
      options: directions,
      showToolTip: true,
      showJuwaiToolTip: true,
      tooltip: "direction",
      showJuwaiAsiaToolTip: true,
    },
  ];

  const debounceAutoSuggest = useCallback(
    _.debounce((val) => edgeAutoSuggest(val), 600),
    [],
  );
  const onChangePropertyName = (val) => {
    onChangeBasicFormField(val, "property_name");
    if (val) {
      debounceAutoSuggest(val);
    }
  };

  const onChangeBasicFormField = (val, context) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp[context] = val;
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const onChangeListingCategory = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    let tmpCategoryTypes = _.find(CategoriesDictionary, { id: val });
    let tmpPropertyTypes = tmpCategoryTypes.group_types
      ? tmpCategoryTypes.group_types.map((item) => {
          let children = item.children.map((child) => {
            let tmpData = {
              id: child.id,
              name: child.name,
              label: child.name,
              value: child.id,
              key: child.key,
              groupName: item.name,
            };
            return tmpData;
          });
          let tmpParent = {
            name: item.name,
            key: item.key,
            children: children,
          };
          return tmpParent;
        })
      : [];
    temp.selectedListingCategory = val;
    temp.tmpPropertyTypes = tmpPropertyTypes;
    temp.selectedPropertyType = {};
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const onChangeMultiSelect = (val, context) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp[context] = val;

    if (
      context === "selectedPropertyType" &&
      iPropertyType.indexOf(val.id) === -1
    ) {
      temp.property_name = "";
      onChangeSubsalesHOC([], context);
    }
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const onSelectPropertyName = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    if (val) {
      let tempCountry = _.find(countries, { name: "Malaysia" });
      let tempStateMapping = _.find(edgePropStateMap, { id: val.state_id_i });
      let tempState = _.find(tempCountry.states, {
        id: tempStateMapping.map_id,
      });
      temp.address = val.street_name_s_lower;
      temp.hiddenAddress = val.street_name_s_lower;
      temp.edge_prop_asset_id = val.asset_id_i;
      temp.property_name = val.name_s_lower;
      temp.postalCode = val.postal_code_i;
      temp.selectedCountry = tempCountry || {};
      temp.tmpStates = tempCountry.states || [];
      temp.selectedState = tempState || {};
      temp.township = val.area_s_lower;

      getSelectedTownShip(tempState.id);
      onChangeNewHOC(temp, "dataBasicInfo");
    }
  };

  const onChangeCountry = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.selectedCountry = val;
    temp.tmpStates = val.states;
    temp.township = "";
    temp.sub_area = "";
    temp.selectedState = {};

    if (val.id !== 1) {
      onChangeSubsalesHOC([], "propertyOptions");
    }
    onChangeNewHOC(temp, "dataBasicInfo");
    onChangeNewHOC([], "townshipArray");
    onChangeNewHOC([], "subAreaArray");
  };

  const onChangeState = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.selectedState = val;
    temp.township = "";

    getSelectedTownShip(val.id);
    onChangeNewHOC(temp, "dataBasicInfo");
    onChangeNewHOC([], "subAreaArray");
  };

  const onChangeTownships = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.sub_area = "";
    temp.township = val;
    onChangeNewHOC(temp, "dataBasicInfo");
    getSelectedSubArea(val);
  };

  const onChangeBuiltUp = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.builtUp = val;
    if (val !== "" && dataBasicInfo.askingPrice !== "") {
      let tmpAskingPricePerUnit = calcAskingPricePerUnit(
        dataBasicInfo.askingPrice,
        val,
      );
      temp.askingPricePerMeasurementUnit = tmpAskingPricePerUnit;
    }
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const onChangeAskingPrice = (val) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.askingPrice = val;
    if (val !== "" && dataBasicInfo.builtUp !== "") {
      let tmpAskingPricePerUnit = calcAskingPricePerUnit(
        val,
        dataBasicInfo.builtUp,
      );
      temp.askingPricePerMeasurementUnit = tmpAskingPricePerUnit;
    }
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const calcAskingPricePerUnit = (askingPrice, builtUp) =>
    askingPrice / builtUp;

  const renderInput = (item) => (
    <section key={item.value} className={`grid-${item.col || "half"}-col`}>
      <div className={"d-flex"}>
        <h2
          className="at-form-input__title"
          required={!["radio", "autosuggest"].includes(item.type)}
        >
          {item.label}
        </h2>
        {item.tooltip && (
          <SubsalesTooltip
            data={{
              tooltip: item.tooltip,
              showToolTip: item.showToolTip,
              showEdgeTooltip: item.showEdgeTooltip,
              showJuwaiToolTip: item.showJuwaiToolTip,
              showJuwaiAsiaToolTip: item.showJuwaiAsiaToolTip,
            }}
          />
        )}
      </div>
      {item.type === "radio" && (
        <CustomRadioGroup
          getLocalised={getLocalised}
          checkedValue={dataBasicInfo[item.value]}
          options={item.options}
          selectedRadioValue={(val) =>
            item.value === "selectedListingCategory"
              ? onChangeListingCategory(val)
              : onChangeBasicFormField(val, item.value)
          }
        />
      )}
      {item.type === "select" && (
        <CustomSelect
          getLocalised={getLocalised}
          className="w-100"
          required={true}
          selectItems={
            typeof item.options === "string"
              ? dataBasicInfo[item.options]
              : item.options
          }
          currentlySelected={dataBasicInfo[item.value]}
          updateSelect={(val) => {
            if (item.value === "selectedCountry") {
              onChangeCountry(val);
            } else if (item.value === "selectedState") {
              onChangeState(val);
            } else {
              onChangeBasicFormField(val, item.value);
            }
          }}
        />
      )}
      {item.type === "multi-select" && (
        <AtlasMultiSelect
          getLocalised={getLocalised}
          required={true}
          selectType={"section"}
          className="w-100"
          selectItems={dataBasicInfo[item.options]}
          updateSelect={(val) => onChangeMultiSelect(val, item.value)}
          currentlySelected={dataBasicInfo[item.value]}
        />
      )}
      {item.type === "autosuggest" && (
        <AtlasAutosuggest
          className={"at-listing__info-autosuggest"}
          value={dataBasicInfo[item.value]}
          inputStyle={{ height: 40, borderColor: "#e0e0e0", fontSize: 15 }}
          updateValue={(val) => onChangeBasicFormField(val, item.value)}
          placeholder=" "
          options={item.options}
          disabled={false}
        />
      )}
      {item.type === "text" && (
        <CustomFormInput
          type="text"
          value={dataBasicInfo[item.value]}
          onChangeValue={(val) => {
            if (item.value === "askingPrice") {
              onChangeAskingPrice(val);
            } else if (item.value === "builtUp") {
              onChangeBuiltUp(val);
            } else {
              onChangeBasicFormField(val, item.value);
            }
          }}
          required={true}
          inputError={dataBasicInfo[item.error] || ""}
          placeholder={item.placeholder || item.label}
        />
      )}
    </section>
  );

  return (
    <>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-building-3"}
            style={{ fill: "#F0631D", marginRight: 8 }}
          />
          {getLocalised("label.details.details.title", "Details")}
        </div>
        <div className="grid-control">
          {detailsInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-location"}
            style={{ fill: "#F0631D", marginRight: 8 }}
          />
          {getLocalised("label.details.address.title", "Address")}
        </div>
        <div className="grid-control">
          {addressInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-home"}
            style={{ fill: "#F0631D", marginRight: 8 }}
          />
          {getLocalised("label.details.specification.title", "Specification")}
        </div>
        <div className="grid-control">
          {specificationsInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-dollar-circle"}
            style={{ fill: "#F0631D", marginRight: 8 }}
          />
          {getLocalised("label.details.price.title", "Price")}
        </div>
        <div className="grid-control">
          {priceInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(SimpleForm);
