import React, { Component } from "react";
import _ from "lodash";

import CustomCheckbox from "components/Checkbox";

import { numberWithCommas } from "utils/thousandSeparator";
import defaultImage from "assets/images/iqi_logo_only.png";
import isEmptyValue from "utils/isEmpty";
import "./index.scss";

const statusDictionary = [
  { id: 0, label: "Pending" },
  { id: 1, label: "Processing" },
  { id: 2, label: "Arrived" },
  { id: 3, label: "Collected" },
  { id: 4, label: "Rejected" },
];

class CustomCard extends Component {
  renderProductNameColumn = () => {
    const { rowItem } = this.props;
    const {
      redeem_date,
      attached_items,
      selected_market_product_attached_product: product,
      selected_market_product_attached_product_type: product_type,
    } = rowItem;

    const { name, type_id, photo_url, description } = rowItem.market_product;

    const {
      name_card_display_name,
      mobile_number,
      additional_display_name,
      designation,
      qualification,
      include_profile_photo,
      license_number,
      t_shirt_gender,
      t_shirt_size,
    } = rowItem.payload || {};

    const orderDetail = [
      { value: name_card_display_name, label: "Name on card" },
      { value: additional_display_name, label: "Name in local language" },
      { value: mobile_number, label: "Additional Mobile Number" },
      { value: designation, label: "Designation" },
      { value: qualification, label: "Title of Academy Achievement" },
      { value: include_profile_photo, label: "Include profile photo" },
      { value: t_shirt_gender, label: "T-shirt Gender" },
      { value: t_shirt_size, label: "Name on card" },
      { value: license_number, label: "License Number" },
      { value: name_card_display_name, label: "T-shirt Size" },
      { value: name_card_display_name, label: "Status" },
      { value: redeem_date, label: "Redeem Date" },
    ];

    const tmpTicketTitle =
      !isEmptyValue(product) && product_type === "TicketPricing"
        ? ` (${product.title})`
        : "";
    const tmpVariantTitle =
      !isEmptyValue(product) && product.options
        ? product.options.map(
            (option, index) =>
              `${option.value}${index + 1 !== product.options.length ? " - " : ""}`,
          )
        : "";

    return (
      <div className="row no-gutters w-100">
        <div className="col-12 col-sm-3">
          <img
            className="bg-white bd-rd-5 border-white"
            style={{
              objectFit: "cover",
              boxShadow: `
                1px 1px 5px 0px rgba(190, 190, 190, 1),
                3px 3px 5px 0px rgba(200, 200, 200, 1)
              `,
            }}
            onError={(e) => {
              if (e.target) {
                e.target.src = defaultImage;
              }
            }}
            src={photo_url || defaultImage}
          />
        </div>
        <div class="at-order-mngmt-table-content col-12 col-sm-9">
          <h5 className={"fw-400"}>{name}</h5>
          <p style={{ fontSize: 12, fontWeight: 500, color: "#9CA3AF" }}>
            {tmpTicketTitle}
            {tmpVariantTitle}
          </p>
          <p className={"fw-400"}>{description}</p>
          {type_id === 1 && (
            <>
              {!isEmptyValue(attached_items) && attached_items.length > 0 && (
                <>
                  <hr />
                  <div className={"d-flex flex-column grid_gap-1"}>
                    {attached_items.map((item, index) => {
                      return (
                        <CustomCheckbox
                          key={index}
                          checked={item.is_collected}
                          content={item.item_name}
                          onChangeCheckboxValue={(e) => {
                            let tmpMarketAttchedItems = [
                              ...rowItem.attached_items,
                            ];
                            tmpMarketAttchedItems[index].is_collected =
                              e.target.checked;

                            return this.props.onCheckItemCheckBoxValue(
                              {
                                ...rowItem,
                                tmpMarketAttchedItems,
                              },
                              e.target.checked,
                              index,
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
          {type_id === 2 && (
            <>
              <hr />
              <div style={{ display: "flex", width: "100%" }}>
                <table className={"w-100"}>
                  {orderDetail.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="at-order-mngmt-list-content_title">
                            {item.label} :
                          </span>
                        </td>
                        {item.value === include_profile_photo && (
                          <td>{`${item.value ? "Yes" : "No"}`}</td>
                        )}
                        {item.value !== include_profile_photo && (
                          <td>{`${item.value ? item.value : "N/A"}`}</td>
                        )}
                      </tr>
                    );
                  })}
                </table>
              </div>
              {include_profile_photo && (
                <a
                  onClick={() => this.props.onClickViewNameCard()}
                  style={{
                    color: "#F18E05",
                    display: "block",
                    fontSize: 12,
                    marginTop: 15,
                    textDecoration: "underline",
                  }}
                >
                  Click here to view namecard photo.
                </a>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  renderTableContentGrid = (
    index,
    columnValue,
    content,
    columnLabel,
    containerStyle = {},
    containerClass = "",
  ) => {
    const { booleanColumn, thousandSeparatorColumn, isPriceData } = this.props;

    const contentProcess = (param) => {
      if (booleanColumn === columnValue) {
        if (content === 0) {
          return "No";
        } else {
          return "Yes";
        }
      } else if (columnValue === "status_id") {
        if (content === 1) {
          return "Completed";
        } else if (content === 2) {
          return "Cancelled";
        }
      } else {
        if (thousandSeparatorColumn) {
          if (thousandSeparatorColumn.indexOf(columnValue) > -1) {
            param = numberWithCommas(param);
          }
        }
        return param;
      }
    };

    return (
      <li
        key={index}
        className={`at-table-row__item at-table__${index} ${containerClass}`}
        style={{ width: "100%", ...containerStyle }}
      >
        <span className="at-table-row__item-label">{columnLabel}</span>
        {columnValue !== "product_name" &&
          isPriceData.indexOf(columnValue) === -1 && (
            <p>{contentProcess(content)}</p>
          )}
        {columnValue !== "product_name" &&
          isPriceData.indexOf(columnValue) > -1 && (
            <p>
              {content !== "N/A" && `RM ${content.toFixed(2)}`}
              {content === "N/A" && content}
            </p>
          )}
        {columnValue === "product_name" && this.renderProductNameColumn()}
      </li>
    );
  };

  renderSubtotalColumn = (
    index,
    columnLabel,
    length,
    containerStyle = {},
    containerClass = "",
  ) => {
    const { subtotalValue } = this.props;
    return (
      <li
        key={index}
        className={`at-table-row__item at-table__${index} ${containerClass}`}
        style={{ width: "100%", ...containerStyle }}
      >
        {index === length - 2 && <p> Subtotal </p>}
        {index === length - 1 && <p> RM {subtotalValue.toFixed(2)} </p>}
      </li>
    );
  };

  render = () => {
    const { headerData, rowItem, isSubtotalContent, subtotalValue } =
      this.props;

    return (
      <div className="at-table-row-cont">
        {!isSubtotalContent && (
          <div className="at-table__height-control">
            <ul className="grid-controller">
              {headerData.map((columnItem, index) => {
                return this.renderTableContentGrid(
                  index,
                  columnItem.value,
                  rowItem[columnItem.value],
                  columnItem.label,
                  columnItem.containerStyle,
                  columnItem.containerClass,
                );
              })}
            </ul>
          </div>
        )}
        {isSubtotalContent && (
          <>
            <div className="at-table__height-control subtotal-section-desktop">
              <ul className="grid-controller">
                {headerData.map((columnItem, index) => {
                  return this.renderSubtotalColumn(
                    index,
                    columnItem.label,
                    headerData.length,
                    columnItem.containerStyle,
                    columnItem.containerClass,
                  );
                })}
              </ul>
            </div>
            <div
              className="at-table__height-control subtotal-section-mobile"
              style={{
                minHeight: "unset",
                height: "70px",
                paddingLeft: "16px",
                paddingTop: "10px",
              }}
            >
              <ul>
                <p> Subtotal </p>
                <p> RM {subtotalValue.toFixed(2)} </p>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  };
}

export default CustomCard;
