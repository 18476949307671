import React, { Component } from "react";

class TableHeader extends Component {
  onClickRearrangeData({ mode, columnValue }) {
    this.props.onClickRearrangeData({ mode, columnValue });
  }

  render() {
    const { data, actionColumn, actionColumnContent } = this.props;
    return (
      <ul className="at-table-row at-table-head at-form_mngmt__table-header">
        {data.map((item, index) => {
          return (
            <li
              style={{
                width: item.width ? item.width : "100%",
                minWidth: item.minWidth && item.minWidth,
                ...item.containerStyle,
                ...item.headerStyle,
              }}
              key={item.label}
              className={`at-table-row__item at-table__${index} ${item.containerClass} ${item.headerClass}`}
            >
              <span className="at-table-head__title">{item.label}</span>
            </li>
          );
        })}
        {actionColumn ? (
          <li
            style={{ width: `${actionColumnContent.length * 35}%` }}
            className="at-table-row__item at-table__actions"
          >
            <span className="at-table-head__title">Actions</span>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
}

export default TableHeader;
