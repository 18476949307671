import React from "react";
import { BsDash } from "react-icons/bs";

import CustomButton from "components/Button";

import "./index.scss";

const TeaserImage = ({ src, containerStyle, onRemoveImage, can_update }) => {
  return (
    <div
      style={{
        position: "relative",
        height: 300,
        border: "3px solid #f18e03",
        borderRadius: 5,
        ...containerStyle,
      }}
    >
      <img className={"teaser-img"} src={src} />
      {can_update && (
        <CustomButton
          style={{ background: "red", borderRadius: "50%", padding: 4 }}
          containerStyle={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => onRemoveImage()}
          isIconButton={true}
          className={"d-flex"}
          children={
            <BsDash style={{ height: 18, width: 18, color: "#FFFF" }} />
          }
        />
      )}
    </div>
  );
};

export default TeaserImage;
