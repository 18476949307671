import React, { useState } from "react";
import _ from "lodash";
import { FaExclamationCircle } from "react-icons/fa";

import CustomButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";

const inputField = [
  { label: "Comission", value: "total_portion" },
  { label: "Lister portion", value: "my_portion" },
  { label: "Co-broke portion", value: "balance" },
];

const ReviseCommissionModal = ({
  selectedCobroke,
  setCobroke,
  onToggleReviseConfirmation,
  onClickClose,
}) => {
  const {
    total_portion = null,
    my_portion,
    balance = null,
    co_broke_portion_by = null,
  } = selectedCobroke.co_broke_portion;

  const [cobrokePortion, setCobrokePortion] = useState({
    total_portion: total_portion,
    my_portion: my_portion,
    balance: balance,
    co_broke_portion_by: co_broke_portion_by,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(cobrokePortion);
    if (context === "my_portion") {
      temp[context] = val;
      temp["balance"] = (temp["total_portion"] - val).toFixed(2);
    } else {
      temp[context] = val;
    }
    setCobrokePortion(temp);
  };

  const onSubmit = () => {
    Promise.all([
      setCobroke({
        ...selectedCobroke,
        co_broke_portion: cobrokePortion,
      }),
    ]).then(() => {
      onToggleReviseConfirmation(true);
      onClickClose();
    });
  };

  return (
    <ModalDialog
      title={"Revise Commission Rate"}
      onClose={onClickClose}
      children={
        <>
          <p className="at-cobroke__revise-alert">
            <FaExclamationCircle
              style={{
                width: 14,
                height: 14,
                marginRight: 10,
                color: "#F18E05",
              }}
            />
            Revisions limited to 3 times only.
          </p>
          <AtlasRadioGroup
            horizontal={true}
            containerClassName={"w-100"}
            checkedValue={cobrokePortion.co_broke_portion_by}
            selectedRadioValue={(val) =>
              onChangeField(val, "co_broke_portion_by")
            }
            options={[
              { value: "percentage", label: "By Percentage" },
              { value: "amount", label: "By Amount" },
            ]}
          />
          <div className="d-flex">
            <div className="p-2 border-0 rounded at-cobroke__commission-container">
              {inputField.map((item, index) => (
                <div
                  key={index}
                  className="d-flex flex-wrap align-items-center mb-2"
                >
                  <strong>{item.label}: </strong>
                  <input
                    className="at-cobroke__revise-input"
                    value={cobrokePortion[item.value]}
                    onChange={(e) => onChangeField(e.target.value, item.value)}
                  />
                  <i>
                    {cobrokePortion.co_broke_portion_by === "amount"
                      ? selectedCobroke.currency
                      : "%"}
                  </i>
                </div>
              ))}
            </div>
          </div>
        </>
      }
      footer={
        <CustomButton
          disabled={_.values(cobrokePortion).some((x) => !x)}
          containerClass="d-flex"
          className="btn-new btn-new--success mt-2 ml-auto"
          onClick={() => onSubmit()}
        >
          Submit
        </CustomButton>
      }
    />
  );
};

export default ReviseCommissionModal;
