import React, { useState } from 'react'
import _ from 'lodash'

import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import ModalDialog from "components/Modal/ModalDialog";
import CustomUpload from "components/Input/upload";

const ModifyTranslationList = props => {
  const [formData, setFormData] = useState({
    module: "",
    language: "",
    translation_list_file: null,
    overwrite_translation: false,
  })

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0) {
      setFormData({ ...formData, translation_list_file: fileItems[0] })
    } else {
      setFormData({ ...formData, translation_list_file: null })
    }
  }

  const onToggleSubmit = () => {
    const submitData = new FormData()
    submitData.append('module_id', formData.module?.value)
    submitData.append('language', formData.language?.language_code)
    submitData.append('file', formData.translation_list_file)
    submitData.append('overwrite_translation', formData.overwrite_translation)
    props.modifyTranslationList(submitData)
  }

  return (
    <ModalDialog
      title="Modify Translation List"
      onLoad={props.onLoadLocalised}
      onClose={() => props.onChangeLocalisedHOC(false, "showModifyTranslation")}
      children={
        <div className='grid-control'>
          <section className='grid-full-col'>
            <h4 className='fs-3 fw-600'>Step 1: Download Translation List</h4>
          </section>
          <section className='grid-half-col'>
            <h2 className='at-form-input__title'>Module</h2>
            <CustomSelect
              emptyOption={true}
              placeholder='Module'
              selectItems={props.modules}
              currentlySelected={formData.module}
              updateSelect={(val) => setFormData({ ...formData, module: val })} />
          </section>
          <section className='grid-full-col'>
            <h4 className='fs-3 fw-600'>Step 2: Upload Translated File</h4>
          </section>
          <section className='grid-half-col'>
            <h2 className='at-form-input__title'>Language</h2>
            <CustomSelect
              emptyOption={true}
              placeholder='Language'
              selectItems={props.settings?.map((setting) => ({ ...setting, value: setting.id, label: `${setting.language_name}(${setting.language_code})` }))}
              currentlySelected={formData.language}
              updateSelect={(val) => setFormData({ ...formData, language: val })} />
          </section>
          <section className='grid-half-col d-flex'>
            <button
              disabled={!formData.module}
              className='btn-new btn-new--secondary mt-auto'
              onClick={() => props.downloadTranslationList(formData.module, formData.language)}>
              Download
            </button>
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Translation File</h2>
            <CustomUpload
              multiple={false}
              accepts={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
              className="at-localised-center__upload-list"
              labelText={'CSV, XLS, XLSX'}
              returnRawFiles={true}
              imageUrl={formData.translation_list_file}
              uploadImage={(files) => onChangeFile(files)}
            />
          </section>
          <section className='grid-full-col'>
            <CustomCheckbox
              content={
                <div className='d-flex flex-column'>
                  <span className='fw-500'>Overwrite Translation</span>
                  <span>If the translation already exists, it will be overwrite with the one in the uploaded file.</span>
                </div>
              }
              checked={formData.overwrite_translation}
              onChangeCheckboxValue={event => setFormData({ ...formData, overwrite_translation: event.target.checked })} />
          </section>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--primary"
            disabled={_.values(formData).some(val => val === "" || val === null)}
            onClick={() => onToggleSubmit()}>
            Submit
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeLocalisedHOC(false, "showModifyTranslation")}>
            Cancel
          </button>
        </div>
      }
    />
  )
}

export default ModifyTranslationList