import React, { useState } from "react";

import CKEditor from "components/CKEditor";

const SpecialCondition = ({
  conditionText,
  type,
  status,
  additionalContent,
  updateConditionText,
  closedEditing,
}) => {
  const [editorState, onChange] = useState(conditionText);

  const renderTitle = () => {
    if (status === "disabled") {
      return "Special Condition";
    } else if (type === "subsale") {
      return "Edit your special conditions here";
    } else if (type === "subrent") {
      return "Edit your extra conditions here";
    }
  };

  return (
    <div>
      <h5 className="fw-600">{renderTitle()}</h5>
      <hr className="mb-3" />
      {additionalContent && additionalContent}
      <div className="row no-gutters p-0">
        <div className="col-md-12 col-lg-12 col-xl-12 mb-0 px-0">
          <CKEditor
            disabled={status === "disabled" || closedEditing}
            placeholder={"Type your special conditions here"}
            hideToolbar={true}
            data={editorState}
            onChange={(event, editor) => {
              const data = editor.getData();
              updateConditionText(data);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecialCondition;
