import React, { Component } from "react";
import { Tooltip, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FaArrowCircleDown } from "react-icons/fa";
import { compose } from "redux";
import _ from "lodash";

import UnderlineTab from "components/Tab/new";
import DuoTab from "components/Tab/duo";
import AlertBox from "components/AlertBox";
import LoadingModal from "components/LoadingModal";
import AtlasBackButton from "components/Button/prev";
import ConfirmationModal from "components/Modal/confirmation";
import EProcessContent from "./eProcessContent";
import EmailDraftContent from "./emailDraftContent";
import UnlockRequestContent from "./TempUnlockRequestForm";
import PartyContent from "../../../components/PartyContent";
import PropertyContent from "containers/Dashboard/Forms/components/propertyTabContent";
import SubsaleConfirmationForm from "./TempSubsaleConfirmationForm";
import SubrentConfirmationForm from "./TempSubrentConfirmationForm";
import InventoryList from "./inventoryList";
import InternalNotes from "./InternalNotes";
import TenancyAgreement from "./tenancyAgreement";
import FormChart from "components/Chart/form";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasRadioGroup from "components/RadioGroup";

import ContactHOC from "actions/contact";
import BankHOC from "actions/banks";
import DraftEmailHOC from "../actions/draftEmail";
import PartyHOC from "../actions/party";
import InventoryHOC from "../actions/inventory";
import RefundHOC from "../actions/refund";
import ListingHOC from "../actions/listing";
import InternalNotesHOC from "../actions/internalNotes";

import permissionsChecker from "utils/permissionsChecker";
import { checkIsNumber } from "utils/checkNull";
import isEmptyValue from "utils/isEmpty";
import { NavTabs, partyDictionary } from "../assets/index";
import "../index.scss";

const FormTabs = ["Contract View", "Form View"];
const SigningTabs = ["e-Signing", "Manual Signing"];

const RepresentativeInfo = (representative_ids) => {
  let tmpRepresentative_ids = representative_ids?.sort((a,b) => b-a)
  let tmp = []
  if(tmpRepresentative_ids && tmpRepresentative_ids !== null) {
    tmpRepresentative_ids.map((id) => {
      switch(id) {
        case 1:
        case 2:
          tmp.push({
            id: id,
            label: `Separate Claim`,
            value: id,
          })
          break;
        case 3:
          tmp.push({
            id: 3,
            label: `Joint Claim`,
            value: 3,
          })
          break;
        default: break
      }
    })
  }
  
  return tmp
}
  
const getRepresentativePartyName = (representative_id, type_id) => {
  switch(representative_id) {
    case 1:
      return type_id === 1 ? "Vendor" : "Landlord";
    case 2:
      return type_id === 1 ? "Purchaser" : "Tenant";
  }
}

const getStampingFee = (
  monthly_rental_amount = 0,
  intended_tenure_period = 0,
  intended_tenure_unit_id
) => {
  let feeByStandard = +monthly_rental_amount * 12 - 2400;
  let kiloValue = Math.trunc(feeByStandard / 1000);
  let beforeRoundedUpValue = feeByStandard - Math.trunc(kiloValue) * 1000;
  let afterRoundedUpValue = 0;
  let roundUpFactors = [0, 250, 500, 750, 1000];

  afterRoundedUpValue = roundUpFactors.find(
    (factor) => beforeRoundedUpValue <= factor,
  );

  let roundedUpFee = kiloValue.toFixed(0) * 1000 + afterRoundedUpValue;

  // Convert Period to Year
  const tmpIntendedTenurePeriod = intended_tenure_unit_id === "1" 
    ? intended_tenure_period / 12
    : intended_tenure_period;

  if (tmpIntendedTenurePeriod > 0 && tmpIntendedTenurePeriod <= 1) {
    return roundedUpFee / 250 + 10;
  } else if (tmpIntendedTenurePeriod > 1 && tmpIntendedTenurePeriod <= 3) {
    return (roundedUpFee / 250) * 2 + 10;
  } else if (tmpIntendedTenurePeriod > 3) {
    return (roundedUpFee / 250) * 4 + 10;
  } else {
    return  0
  }
};

const RadioOptions = ({ can_buyer_submit, can_owner_submit }) => {
  let tmpRadioOptons = [];
  if (can_buyer_submit) {
    tmpRadioOptons.push({ label: "Tenant/Purchaser", value: "2" });
  }
  if (can_owner_submit) {
    tmpRadioOptons.push({ label: "Landlord/Vendor", value: "1" });
  }
  return tmpRadioOptons;
};

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

class EditFormModalContent extends Component {
  state = {
    autoCalculatedList: [],
    onAutoCalculateDownPaymentPercentage: false,
    onScreen: false,
    toSubmitData: {},
    showDownloadReview: false,
  };

  componentDidMount = () => {
    this.editSection = null;
    const { selected_confirmation_form } = this.props;
    
    // check if e_process is not null then, call api
    this.props.tempEProcess &&
      this.props.getSigningStatus(selected_confirmation_form.id);
  };

  componentDidUpdate = (pp) => {
    const {
      refundStatusId,
      selectedSection,
      selected_confirmation_form,
    } = this.props;

    if (pp.refundStatusId !== refundStatusId && refundStatusId !== null) {
      this.props.getRefundForm(selected_confirmation_form.id);
    }

    if (pp.selectedSection !== selectedSection) {
      const { selected_confirmation_form } = this.props;

      if(this.props.showConfirmationForm) {
        this.props.getConfirmationForm(selected_confirmation_form.id);
      }

      if([ "Participants", "Contract Signing", "Temp. Receipt", "Tenancy Agreement"].indexOf(selectedSection) > -1) {
        this.props.getParty(selected_confirmation_form.id);

        if(selectedSection === "Participants") {
          this.props.getCurrentUserContactList();
        }
      }
      if(selectedSection === "Inventory Checklist") {
        this.props.getInventoryItems();
      }
      if(selectedSection === "Internal Notes") {
        this.props.getInternalNotes(selected_confirmation_form.id);
      }
      if([ "Contract Signing", "Tenancy Agreement"].indexOf(selectedSection) > -1) {
        const { country_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
  
        this.props.getBanks(country_id);
      }
    }
  };

  onChangeEditConfirmationForm = (context, value) => {
    this.setState({
      [context]: value,
    });
  };

  onClickTop = () => {
    document.getElementsByClassName(
      "at-form__mngmt-card-padding",
    )[0].scrollTop = 0;
    this.setState({ onScreen: false });
  };

  onClickBottom = () => {
    let tmp = document.getElementsByClassName("at-form__mngmt-card-padding")[0];
    tmp.scrollTop = tmp.scrollHeight;
    this.setState({ onScreen: true });
  };

  onChangeEditSubsale = (context, value) => {
    const { selected_confirmation_form, onChangeConfirmationHOC } = this.props;

    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selected_confirmation_form);

    if (context === "TriggerIcon") {
      this.setState({ onAutoCalculateDownPaymentPercentage: value });
    } else {
      tmp[context] = value;
      const { purchase_price = 0, professional_fee_percentage = 0 } = tmp;

      if (context === "purchase_price" && checkIsNumber(purchase_price)) {
        tmp.down_payment_amount = (
          (purchase_price * tmp.down_payment_percentage) /
          100
        ).toFixed(2);
        tmp.balance_deposit_amount = (
          (purchase_price * tmp.balance_deposit_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push(
          "down_payment_amount",
          "balance_deposit_amount",
        );
      }
      
      if (context === "professional_fee_percentage" && checkIsNumber(professional_fee_percentage)) {
        tmp.professional_fee_amount = null
      }
      
      if (
        context === "down_payment_percentage" &&
        checkIsNumber(tmp.down_payment_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (tmp.down_payment_percentage <= 10) {
          tmp.balance_deposit_percentage = (
            10 - tmp.down_payment_percentage
          ).toFixed(2);
          tmp.balance_deposit_amount = (
            (purchase_price * tmp.balance_deposit_percentage) /
            100
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_deposit_percentage",
            "balance_deposit_amount",
          );
        }
      }
      if (
        context === "down_payment_percentage" &&
        checkIsNumber(tmp.down_payment_percentage)
      ) {
        tmp.down_payment_amount = (
          (purchase_price * tmp.down_payment_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("down_payment_amount");

        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_percentage" &&
        checkIsNumber(tmp.balance_deposit_percentage)
      ) {
        tmp.balance_deposit_amount = (
          (purchase_price * tmp.balance_deposit_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_deposit_amount");
        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_percentage" &&
        checkIsNumber(tmp.balance_deposit_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (tmp.balance_deposit_percentage <= 10) {
          tmp.down_payment_percentage = (
            10 - tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.down_payment_amount = (
            (purchase_price * tmp.down_payment_percentage) /
            100
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "down_payment_percentage",
            "down_payment_amount",
          );
        }
      }

      if (
        context === "down_payment_amount" &&
        checkIsNumber(tmp.down_payment_amount) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.down_payment_percentage = (
          (tmp.down_payment_amount / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("down_payment_percentage");
        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_amount" &&
        checkIsNumber(tmp.balance_deposit_amount) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.balance_deposit_percentage = (
          (tmp.balance_deposit_amount / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_deposit_percentage");

        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_purchase_price" &&
        checkIsNumber(tmp.balance_purchase_price) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.balance_purchase_price_percentage = (
          (tmp.balance_purchase_price / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_purchase_price_percentage");
      }
      if (
        (context === "purchase_price" || context === "balance_purchase_price_percentage") && this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (
          checkIsNumber(purchase_price) &&
          checkIsNumber(tmp.balance_purchase_price_percentage)
        ) {
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push("balance_purchase_price");
        }
      }

      this.setState({ autoCalculatedList: tmpAutoCalculatedList });
      return onChangeConfirmationHOC(tmp, "selected_confirmation_form");
    }
  };

  onChangeEditSubrent = (context, value) => {
    const { selected_confirmation_form, onChangeConfirmationHOC } = this.props;

    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selected_confirmation_form);

    tmp[context] = value;

    const {
      monthly_rental_amount = 0,
      security_deposit_months = 0,
      utility_deposit_months = 0,
      intended_tenure_period = 0,
      advanced_rental_months = 0,
      intended_tenure_unit_id
    } = tmp;

    
    if(context === "intended_tenure_unit_id" || context === "renewal_tenure_unit_id") {
      tmp.intended_tenure_unit_id = value
      tmp.renewal_tenure_unit_id = value
    }

    if(context === "monthly_rental_amount") {
      tmp["one_month_earnest_deposit"] = (+monthly_rental_amount)?.toFixed(2)
      tmpAutoCalculatedList.push("one_month_earnest_deposit")
    }
    
    if(tmp.include_advanced_rental) {
      if(context === "monthly_rental_amount" || context === "advanced_rental_months") {
        if(checkIsNumber(monthly_rental_amount) && checkIsNumber(advanced_rental_months)) {
          tmp["advanced_rental_amount"] = (advanced_rental_months * monthly_rental_amount)?.toFixed(2)
          tmpAutoCalculatedList.push("advanced_rental_amount");
        }
      }
    }

    if (
      context === "monthly_rental_amount" ||
      context === "security_deposit_months"
    ) {
      if (
        checkIsNumber(monthly_rental_amount) &&
        checkIsNumber(security_deposit_months)
      ) {
        tmp.security_deposit_amount = (
          monthly_rental_amount * security_deposit_months
        ).toFixed(2);
        tmpAutoCalculatedList.push("security_deposit_amount");
      }
    }
    if (
      context === "monthly_rental_amount" ||
      context === "utility_deposit_months"
    ) {
      if (
        checkIsNumber(
          monthly_rental_amount && checkIsNumber(utility_deposit_months),
        )
      ) {
        tmp.utility_deposit_amount = (
          monthly_rental_amount * utility_deposit_months
        ).toFixed(2);
        tmpAutoCalculatedList.push("utility_deposit_amount");
      }
    }
    if (
      context === "monthly_rental_amount" ||
      context === "intended_tenure_period" ||
      context === "intended_tenure_unit_id"
    ) {
      if (checkIsNumber(monthly_rental_amount) && intended_tenure_period > 0) {
        tmp.stamping_fee = getStampingFee(monthly_rental_amount, intended_tenure_period, intended_tenure_unit_id)?.toFixed(2);
        tmpAutoCalculatedList.push("stamping_fee");
      }
    }
    this.setState({ autoCalculatedList: tmpAutoCalculatedList });
    return onChangeConfirmationHOC(tmp, "selected_confirmation_form");
  };

  onChangeBlur = (context) => {
    let tmp = _.cloneDeep(this.props.selected_confirmation_form);
    if (!tmp[context] || tmp[context] == "") {
      tmp[context] = 0;
    }
    this.props.onChangeConfirmationHOC(tmp, "selected_confirmation_form");
  };

  onChangeFocus = (context) => {
    let tmp = _.cloneDeep(this.props.selected_confirmation_form);
    if (!tmp[context] || tmp[context] == 0) {
      tmp[context] = "";
    }
    this.props.onChangeConfirmationHOC(tmp, "selected_confirmation_form");
  };

  renderNavButton = (selectedTab) => {
    const {
      selected_confirmation_form,
      onChangePartyHOC,
      onChangeConfirmationHOC,
    } = this.props;
    let tmpTabs = NavTabs(selected_confirmation_form.type_id);

    let tmpIndex = tmpTabs.findIndex((item) => item.label === selectedTab);
    let lastIndex =
      tmpTabs.findIndex((item) => item.label === "Contract Signing") + 1;

    return (
      <>
        {tmpIndex - 1 >= 0 && (
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() =>
              onChangePartyHOC(tmpTabs[tmpIndex - 1].label, "selectedSection")
            }
          >
            <p>{tmpTabs[tmpIndex - 1].label}</p>
          </button>
        )}
        {tmpIndex + 1 < lastIndex && (
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() =>
              onChangePartyHOC(tmpTabs[tmpIndex + 1].label, "selectedSection")
            }
          >
            <p>{tmpTabs[tmpIndex + 1].label}</p>
          </button>
        )}
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeConfirmationHOC(false, "showConfirmationForm")}
        >
          Close
        </button>
      </>
    );
  };

  renderSigningTabContent = () => {
    const {
      signingStatus,
      selectedSigningTab,
      selectedSection,

      onChangePartyHOC,
      downloadAckReceipt,
      onChangeConfirmationHOC,
    } = this.props;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;

    return (
      <>
        <div className="at-form__content at-new_container-20">
          <h5 className="at-card_content-title">{"Contract Signing"}</h5>
          <p className="fs-2">
            How do you want to sign this contract? Choose only one method.
          </p>
        </div>
        <DuoTab
          sections={SigningTabs}
          selectedSection={selectedSigningTab}
          onSelectSection={(val) => onChangePartyHOC(val, "selectedSigningTab")}
        />
        {selectedSigningTab === "Manual Signing" && (
          <>
            <AlertBox
              containerClassName="mb-3"
              containerStyle={{
                backgroundColor: "#FFFAEA",
                borderColor: "#FCB122",
              }}
              iconStyle={{ color: "#F79008" }}
              description={`Please make sure that you have saved the data by clicking the "Save Terms & Conditions" button in T&C before proceeding any actions below.`}
            />
            <div className="at-form__content">
              <h5 className="at-card_content-title mb-3">{"Manual Signing"}</h5>
              <p className="fs-2">
                You can offer your client two options when signing the
                confirmation form:
              </p>
              <br />
              <p className="fs-2">
                1. You can print out the confirmation form and cross out any
                inapplicable phrases or mistakes before your client signs the
                printed form.
              </p>
              <br />
              <p className="fs-2">
                2. Should your client wish to use a digital signature for the
                confirmation form, you will need to attach a screenshot of the
                client sending you the confirmation form (via E-mail, WhatsApp,
                WeChat or any other platform) as proof of submission for claims.
              </p>
              <br />
              <br />
              <p className="fs-2">
                Download Adobe Fill & Sign mobile app to sign PDF documents
                digitally.
              </p>
              <div className="d-flex flex-wrap grid_gap-1 mt-2">
                <button
                  disabled={signingStatus === "Completed"}
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    onChangeConfirmationHOC(
                      true,
                      "showDownloadConfirmationModal",
                    )
                  }
                >
                  {`Download Confirmation Form`}
                </button>
              </div>
            </div>
            <div
              className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}
            >
              {this.renderNavButton(selectedSection)}
            </div>
          </>
        )}
        {selectedSigningTab === "e-Signing" && (
          <EProcessContent
            {...this.props}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
            renderNavButton={() => this.renderNavButton(selectedSection)}
          />
        )}
      </>
    );
  };

  renderDraftVersion = () => {
    const { selected_confirmation_form, selectedSection } = this.props;
    const { can_create } = permissionsChecker("Forms", this.props.data);
    const { sideMenuExpand } = this.props.data.sideMenuReducer;
    const { is_buyer_claimed, is_claimed, is_owner_claimed } =
      selected_confirmation_form;

    const tmpDisabled = is_buyer_claimed || is_claimed || is_owner_claimed;

    return (
      <>
        <div className="at-form__content">
          <h5 className="at-content-title mb-3">
            {"Draft version for review"}
          </h5>
          <AlertBox
            mode={"info"}
            containerClassName="mb-3"
            description={
              "You can send a draft copy of the contract to the recipients for review before initiating the e-signing process to ensure that all of the terms and conditions are agreed upon by both parties."
            }
          />
          <div className="d-flex flex-wrap grid_gap-1">
            <button
              disabled={
                this.props.signingStatus === "Processing" || tmpDisabled
              }
              className={"btn-new btn-new--secondary"}
              onClick={() => {
                if (selected_confirmation_form.type_id === 2 && selected_confirmation_form.include_inventory) {
                  this.setState({ showDownloadReview: true })
                } else {
                  this.props.downloadDraftEmail(
                    selected_confirmation_form.id,
                    `${selected_confirmation_form.transaction_number}_draft_doc`,
                  )
                }
              }}>
              Download
            </button>
            {can_create && (
              <button
                disabled={
                  this.props.signingStatus === "Processing" || tmpDisabled
                }
                className={"btn-new btn-new--secondary"}
                onClick={() =>
                  this.props.onChangeDraftHOC(true, "showEmailDraftModal")
                }
              >
                Email
              </button>
            )}
          </div>
        </div>
        <div
          className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}
        >
          {this.renderNavButton(selectedSection)}
        </div>
        <ConfirmationModal
          open={this.state.showDownloadReview}
          title={"Download for Review"}
          message={
            <>
              You’ve chosen to include the inventory checklist with the confirmation form. <br />
              Should the draft also include client consent to use the e-signature process for the inventory list?
              <ul className={'text-left fs-2 px-4'} style={{listStyleType: 'disc'}}>
                <li>Yes, include e-signature: The agreement will reflect the e-signing of both documents.</li>
                <li>No, follow standard signing: The agreement will maintain the standard procedure of separate signing for the inventory list during property handover.</li>
              </ul>
            </>
          }
          onClose={() => this.setState({ showDownloadReview: false })}
          positiveText={"Yes, include e-signature"}
          positiveAction={() => {
            this.setState({ showDownloadReview: false })
            this.props.downloadDraftEmail(
              selected_confirmation_form.id,
              `${selected_confirmation_form.transaction_number}_draft_doc`,
              true
            )
          }}
          negativeText={"No, follow standard signing"}
          negativeAction={() => {
            this.setState({ showDownloadReview: false })
            this.props.downloadDraftEmail(
              selected_confirmation_form.id,
              `${selected_confirmation_form.transaction_number}_draft_doc`
            )
          }} />
      </>
    );
  };

  renderContractParticipants = () => {
    const { selected_confirmation_form, selectedSection, closedEditing } = this.props;

    return (
      <PartyContent
        {...this.props}
        mode={"new"}
        closedEditing={ closedEditing }
        form_type={"confirmation"}
        partyDictionary={partyDictionary}
        selected_form={selected_confirmation_form}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
        renderNavButton={() => this.renderNavButton(selectedSection)}
      />
    );
  };

  renderConfirmationForm = () => {
    const {
      showAmountConverterModal,
      selected_confirmation_form,
      selectedSection,
      closedEditing,
      onChangeConfirmationHOC,
      saveTnC,
      onLoadConfirmationForm,
      inventoryItems
    } = this.props;

    return (
      <>
        {selected_confirmation_form.type_id === 1 && (
          <SubsaleConfirmationForm
            showAmountConverterModal={showAmountConverterModal}
            saveTnC={saveTnC}
            FormTabs={FormTabs}
            inventoryItems={inventoryItems}
            onClose={() => onChangeConfirmationHOC(false, "showConfirmationForm")}
            autoCalculatedList={this.state.autoCalculatedList}
            onAutoCalculateDownPaymentPercentage={this.state.onAutoCalculateDownPaymentPercentage}
            onChangeEditConfirmationForm={this.onChangeEditConfirmationForm}
            onChangeBlur={this.onChangeBlur}
            onChangeFocus={this.onChangeFocus}
            onChange={(context, value) => this.onChangeEditSubsale(context, value)}
            onChangeConfirmationHOC={onChangeConfirmationHOC}
            onLoadConfirmationForm={onLoadConfirmationForm}
            closedEditing={closedEditing}
            selected_confirmation_form={selected_confirmation_form}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
            renderNavButton={() => this.renderNavButton(selectedSection)}
          />
        )}
        {selected_confirmation_form.type_id !== 1 && (
          <SubrentConfirmationForm
            saveTnC={saveTnC}
            FormTabs={FormTabs}
            inventoryItems={inventoryItems}
            closedEditing={closedEditing}
            autoCalculatedList={this.state.autoCalculatedList}
            onChangeBlur={this.onChangeBlur}
            onChangeFocus={this.onChangeFocus}
            onClose={() =>
              onChangeConfirmationHOC(false, "showConfirmationForm")
            }
            onChange={(context, value) =>
              this.onChangeEditSubrent(context, value)
            }
            selected_confirmation_form={selected_confirmation_form}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
            renderNavButton={() => this.renderNavButton(selectedSection)}
          />
        )}
      </>
    );
  };

  renderInventoryChecklist = () => {
    const {
      selected_confirmation_form,
      selectedSection,
      closedEditing,
      inventoryItems,
      updateInventoryList,
    } = this.props;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;
    return (
      <InventoryList
        selected_confirmation_form={selected_confirmation_form}
        inventoryItems={inventoryItems}
        menuExpand={sideMenuExpand}
        updateInventoryList={updateInventoryList}
        closedEditing={closedEditing}
        onChange={(context, value) => this.onChangeEditSubrent(context, value)}
        renderNavButton={() => this.renderNavButton(selectedSection)}
      />
    );
  };

  renderPropertyTabContent = () => {
    const {
      selectedSection,
      onLoadForms,
      onLoadConfirmationForm,
      onLoadContacts,
      onLoadDraftEmail,
      onLoadRefund,
      closedEditing
    } = this.props;

    return (
      <PropertyContent
        formType={"ConfirmationForm"}
        data={this.props.data}
        closedEditing={closedEditing}
        loading={
          onLoadConfirmationForm ||
          onLoadForms ||
          onLoadContacts ||
          onLoadDraftEmail ||
          onLoadRefund
        }
        townshipsList={this.props.townshipsList}
        onChange={this.props.onChangeConfirmationHOC}
        formData={this.props.selected_form_property}
        autoSuggestOptions={this.props.autoSuggestOptions}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
        autoSuggestLoading={this.props.autoSuggestLoading}
        updateForm={this.props.updatePropertyListing}
        getSelectedTownShip={this.props.getSelectedTownShip}
        edgeAutoSuggest={this.props.edgeAutoSuggest}
        onClickCloseForm={() =>
          this.props.onChangeConfirmationHOC(false, "showConfirmationForm")
        }
        renderNavButton={() => this.renderNavButton(selectedSection)}
      />
    );
  };

  renderDownloadConfirmation = () => {
    const {
      partyData,
      totalBuyerCount,
      totalSellerCount,
      selected_confirmation_form,
      onChangeConfirmationHOC,
    } = this.props;
    const buyerCount = _.find(partyData, (party) => party.id === 1);
    const sellerCount = _.find(partyData, (party) => party.id === 3);

    const onChangeCount = (value, context, action) => {
      let temp = _.cloneDeep(value);
      if (action === "increment") temp++;
      if (action === "decrement") temp--;
      onChangeConfirmationHOC(temp, context);
    };

    return (
      <ConfirmationModal
        open={this.props.showDownloadConfirmationModal}
        title={"Download for manual signing"}
        message={
          <>
            Please indicate the number of signature box needed for each party
            below.
            <Card className="row p-10 m-2">
              <div className="col-md-6">
                <h2 className="at-form-input__title">
                  {selected_confirmation_form.type_id === 1
                    ? "Purchaser"
                    : "Tenant"}
                </h2>
                <div className="d-flex justify-content-center align-items-center mt-10">
                  <button
                    style={{ width: 30, height: 30, padding: 0 }}
                    disabled={
                      (buyerCount &&
                        totalBuyerCount === buyerCount.data.length) ||
                      totalBuyerCount < 2
                    }
                    className="btn-new btn-new--secondary mx-1"
                    onClick={() =>
                      onChangeCount(
                        totalBuyerCount,
                        "totalBuyerCount",
                        "decrement",
                      )
                    }
                  >
                    -
                  </button>
                  <input
                    value={totalBuyerCount}
                    className={
                      "at-form-input__new at-form__signature-box-input"
                    }
                    disabled={true}
                  />
                  <button
                    style={{ width: 30, height: 30, padding: 0 }}
                    disabled={totalBuyerCount > 4}
                    className="btn-new btn-new--secondary mx-1"
                    onClick={() =>
                      onChangeCount(
                        totalBuyerCount,
                        "totalBuyerCount",
                        "increment",
                      )
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="at-form-input__title">
                  {selected_confirmation_form.type_id === 1
                    ? "Vendor"
                    : "Landlord"}
                </h2>
                <div className="d-flex justify-content-center align-items-center mt-10">
                  <button
                    style={{ width: 30, height: 30, padding: 0 }}
                    disabled={
                      (sellerCount &&
                        totalSellerCount === sellerCount.data.length) ||
                      totalSellerCount < 2
                    }
                    className="btn-new btn-new--secondary mx-1"
                    onClick={() =>
                      onChangeCount(
                        totalSellerCount,
                        "totalSellerCount",
                        "decrement",
                      )
                    }
                  >
                    -
                  </button>
                  <input
                    value={totalSellerCount}
                    className={
                      "at-form-input__new at-form__signature-box-input"
                    }
                    disabled={true}
                  />
                  <button
                    style={{ width: 30, height: 30, padding: 0 }}
                    disabled={totalSellerCount > 4}
                    className="btn-new btn-new--secondary mx-1"
                    onClick={() =>
                      onChangeCount(
                        totalSellerCount,
                        "totalSellerCount",
                        "increment",
                      )
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            </Card>
            <p className="text-danger">
              You may only download the confirmation form twice, please ensure
              all the data is what you expected before you proceed.
            </p>
          </>
        }
        positiveAction={() => {
          this.props.downloadConfirmationForm(
            this.props.selected_confirmation_form.id,
            `${this.props.selected_confirmation_form.transaction_number}`,
          );
          this.props.onChangeConfirmationHOC(
            false,
            "showDownloadConfirmationModal",
          );
        }}
        negativeAction={() =>
          this.props.onChangeConfirmationHOC(
            false,
            "showDownloadConfirmationModal",
          )
        }
        positiveText={"Confirm"}
        negativeText={"Cancel"}
      />
    );
  };

  renderClosingFormModal = () => {
    const tmpRepresentativeOptions = RepresentativeInfo(this.state.toSubmitData.representativeIds)

    if (this.props.showSubmitClaimForm) {
      return (
        <ModalDialog
          title={"Submit Confirmation Form"}
          fullHeight={false}
          onClose={() =>
            this.props.onChangeConfirmationHOC(false, "showSubmitClaimForm")
          }
          children={
            <div className="at-apt_letter-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose a claim type to submit the confirmation form.
              </p>
              {
                tmpRepresentativeOptions.length > 0 && (
                  <AtlasRadioGroup
                    horizontal={false}
                    mode="old"
                    checkedValue={this.state.toSubmitData.representative_id}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => {
                      this.setState({
                        toSubmitData: {
                          ...this.state.toSubmitData,
                          representative_id: val,
                        },
                      });
                    }}
                    options={tmpRepresentativeOptions}
                  />
                )
              }
              {
                tmpRepresentativeOptions.length === 0 && "N/A"
              }
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() =>
                    this.props.onChangeConfirmationHOC(
                      false,
                      "showSubmitClaimForm",
                    )
                  }>
                  Cancel
                </button>
                <button
                  disabled={!this.state.toSubmitData.representative_id}
                  className="btn-new btn-new--secondary"
                  onClick={() => {
                    this.props.onChangeConfirmationHOC(
                      false,
                      "showSubmitClaimForm"
                    )
                    this.props.onChangeConfirmationHOC(
                      true,
                      "showSubmitConfirmationClaimForm",
                    )
                  }}>
                  Next
                </button>
              </div>
            </div>
          }
        />
      );
    }
  };

  renderTenancyAgreement = () => (
    <TenancyAgreement
      data={this.props.data}
      banks={this.props.banks}
      partyData={this.props.partyData}

      onClose={() => this.props.onChangeConfirmationHOC(false, "showConfirmationForm")}
      getConfirmationForm={this.props.getConfirmationForm}
      selected_form_property={this.props.selected_form_property}
      eProcessInitialPartyData={this.props.eProcessInitialPartyData}
      selected_confirmation_form={this.props.selected_confirmation_form}
      changeTabToPaticipant={() => this.props.onChangePartyHOC('Participants', "selectedSection")} />
  );

  renderInternalNotes = () => {
    const { selected_confirmation_form } = this.props;
    return (
      <InternalNotes
        {...this.props}
        permissions={permissionsChecker("Forms", this.props.data)}
        confirmation_form_id={selected_confirmation_form.id}
      />
    );
  };

  renderScrollToPartner = () => (
    <DarkTooltip
      classes={{ tooltip: "tooltip-arrow top" }}
      aria-label={"action-button"}
      placement={"top"}
      title={this.state.onScreen ? "Scroll to top" : "Scroll to bottom"}
    >
      <button
        className="at-listing__partner-platform-btn"
        style={{
          position: "fixed",
          bottom: 30,
          transform: this.state.onScreen
            ? "rotate( 180deg )"
            : "rotate( 0deg )",
        }}
        onClick={() =>
          this.state.onScreen ? this.onClickTop() : this.onClickBottom()
        }
      >
        <FaArrowCircleDown />
      </button>
    </DarkTooltip>
  );

  renderFormsChart = () => {
    const {
      forms_chart,
      invoicing_branch_id,
      type_id,
      id = "",
    } = this.props.selected_confirmation_form;

    return forms_chart !== null && (
      <FormChart
        infoData={{
          id: id,
          representative_id: 1,
          invoicing_branch_id: invoicing_branch_id,
          type_id: type_id,
        }}
        history={this.props.history}
        currentFormType={"Confirmation Form"}
        forms_chart={forms_chart}
        onClickButton={(data) => {
          this.props.onChangeConfirmationHOC(true, "showSubmitClaimForm");
          this.setState({ toSubmitData: {
            ...data,
            representative_id: data.representativeIds.length === 1
              ? data.representativeIds[0]
              : ''
          }});
        }}
      />
    );
  };

  render = () => {
    const {
      selectedSection,

      selected_confirmation_form,
      onChangeConfirmationHOC,
      sendDraftEmail,
      showEmailDraftModal,
      eProcessPartyData,

      onLoadForms,
      onLoadConfirmationForm,
      onLoadContacts,
      onLoadDraftEmail,
      onLoadRefund,
      onLoadInternalNotes,
    } = this.props;
    const { transaction_number } = selected_confirmation_form;
    const tmpTransactionNumber = `${!isEmptyValue(transaction_number) ? `/ ${transaction_number}` : ""}`;

    return (
      <div ref={(el) => (this.editSection = el)}>
        <div className="at-card__header">
          <AtlasBackButton
            onClick={() =>
              onChangeConfirmationHOC(false, "showConfirmationForm")
            }
          />
          <h5 className="at-card__title">
            {`Edit Form ${tmpTransactionNumber}`}
          </h5>
        </div>
        {this.renderFormsChart()}
        <UnderlineTab
          sections={NavTabs(selected_confirmation_form.type_id)}
          selectedSection={selectedSection}
          onSelectSection={(val) =>
            this.props.onChangePartyHOC(val, "selectedSection")
          }
        />
        <div>
          {selectedSection === "Temp. Receipt" && (
            <UnlockRequestContent
              {...this.props}
              menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
              renderNavButton={() => this.renderNavButton(selectedSection)}
            />
          )}
          {selectedSection === "Property" && this.renderPropertyTabContent()}
          {selectedSection === "Participants" &&
            this.renderContractParticipants()}
          {selectedSection === "T&C" && this.renderConfirmationForm()}
          {selectedSection === "Inventory Checklist" &&
            this.renderInventoryChecklist()}
          {selectedSection === "Review" && this.renderDraftVersion()}
          {selectedSection === "Contract Signing" &&
            this.renderSigningTabContent()}
          {selectedSection === "Tenancy Agreement" &&
            this.renderTenancyAgreement()}
          {selectedSection === "Internal Notes" && this.renderInternalNotes()}
        </div>
        {showEmailDraftModal && (
          <EmailDraftContent
            formId={selected_confirmation_form.id}
            fromTypeId={selected_confirmation_form.type_id}
            includeInventory={selected_confirmation_form.include_inventory}
            eProcessPartyData={eProcessPartyData}
            sendDraftEmail={sendDraftEmail}
            showLoading={onLoadDraftEmail}
            onClose={() =>
              this.props.onChangeDraftHOC(false, "showEmailDraftModal")
            }
          />
        )}
        {this.renderClosingFormModal()}
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmationClaimForm}
          title={"Closing Deal"}
          message={
            [1,2].indexOf(this.state.toSubmitData.representative_id) > -1 
              ? (
                <div>
                  <p className="text-left fs-2">
                    You’ve chosen Separate Claim. <b>This means you’ll be managing the claim for your { getRepresentativePartyName(this.state.toSubmitData.representative_id, this.props.selected_confirmation_form.type_id) }.</b> By choosing this:
                  </p>
                  <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                    <li>You can only submit and manage claims for <b>your own representing client.</b></li>
                    <li>You won’t be able to request anything on behalf of the other party involved.(Request Invoice/Official Receipt, etc.)</li>
                  </ul>
                  <p className="text-left mb-2 fs-2">Not sure? Click Back and choose “Joint Claim” if you want to manage claims for both parties.</p>
                  <p className="fs-2">Are you sure you want to proceed with Separate Claim?</p>
                </div>
              )
              : (
                <div>
                  <p className="text-left fs-2">You’ve chosen Joint Claim. This means you’ll manage the submissions for both parties, which can involve:</p>
                  <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                    <li><b>You represent both clients.</b></li>
                    <li><b>Engaging in a Co-Agent Deal:{" "}</b>partnering with another agent (internal or external) and are responsible for submitting and managing the claim on behalf of both parties.</li>
                    <li>In this option, you are able to request documents like invoices or receipts on behalf of both parties.</li>
                  </ul>
                  <p className="fs-2">Are you sure you want to proceed?</p>
                </div>
              )
          }
          loading={onLoadConfirmationForm || onLoadForms}
          positiveText={"Submit"}
          icon={<AtlasIcon svgHref={"svg-document-tick"} />}
          positiveAction={() =>
            this.props.submitForm(
              this.state.toSubmitData.id,
              "ConfirmationForm",
              { representative_id: this.state.toSubmitData.representative_id },
            )
          }
          negativeText={"Back"}
          negativeAction={() => {
            this.props.onChangeConfirmationHOC(
              true,
              "showSubmitClaimForm",
            )
            this.props.onChangeConfirmationHOC(
              false,
              "showSubmitConfirmationClaimForm",
            )
          }}
        />
        {this.renderDownloadConfirmation()}
        {(onLoadConfirmationForm ||
          onLoadForms ||
          onLoadContacts ||
          onLoadDraftEmail ||
          onLoadRefund ||
          onLoadInternalNotes) && <LoadingModal />}
      </div>
    );
  };
}

export default compose(
  BankHOC,
  ContactHOC,
  PartyHOC,
  DraftEmailHOC,
  RefundHOC,
  InventoryHOC,
  ListingHOC,
  InternalNotesHOC,
)(EditFormModalContent);
