import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import CustomSelect from 'components/Select'
import CustomTable from "components/NewTable";
import AtlasIcon from 'components/Icon/atlasIcon'
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation";

const BulkUpdate = props => {
  const [formData, setFormData] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  
  const columnData = ({onChangeFormData}) => [
    {
      header: "Key",
      accessor: "key",
      searchFlag: "key",
      label: "Key",
    },
    {
      header: "Default Translation",
      accessor: "default_translation",
      searchFlag: "default_translation",
      label: "Default Translation",
    },
    {
      header: "Translation",
      renderColumn: (rowData) => (
        <CustomFormInput
          value={rowData.translation}
          onChangeValue={(val) => onChangeFormData(rowData.id, val)} />
      ),
      searchFlag: "translation",
      label: "Translation",
    },
  ];

  useEffect(() => {
    if (props.modules.length > 0) {
      setSelectedModule(props.modules[0])
    }
  }, [props.modules])

  useEffect(() => {
    if (props.moduleKeys.length > 0) {
      setFormData(props.moduleKeys)
    } else {
      setFormData([])
    }
  }, [props.moduleKeys])

  useEffect(() => {
    if (selectedModule && props.selectedTranslation) {
      props.getModuleKeys(selectedModule.value, props.selectedTranslation.language_code)
    }
  }, [selectedModule, props.selectedTranslation])

  const onChangeFormData = (id, value) => {
    let temp = _.cloneDeep(formData);
    const rowIndex = temp.findIndex(row => row.id === id);
    if (rowIndex !== -1) {
      temp[rowIndex].translation = value;
      setFormData(temp);
    }
  };

  const onToggleUpdate = () => {
    let temp = _.filter(formData, (data) => (data.translation && data.translation !== null))
    temp = _.map(temp, (data) => ({
      id: data.id,
      translations: {
        [props.selectedTranslation.language_code]: data.translation
      }
    }))
    props.bulkUpdateTranslation(temp)
  }

  const renderCheckBoxActions = () => (
    <div className="at-localised-center_table-title">
      <div className='d-flex align-items-center g-2'>
        <div
          className="at-renewal-details__icon-badge"
          style={{ backgroundColor: "#FFEDD5" }}
        >
          <AtlasIcon
            svgHref={"atlas-translate-linear"}
            style={{ fill: "#EA580C" }}
          />
        </div>
        <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
          Translate to {props.selectedTranslation?.language_name}
        </p>
      </div>
    </div>
  )

  return (
    <>
    <ModalDialog
      title="Bulk Edit Translation"
      fullWidth={true}
      fullHeight={true}
      onLoad={props.onLoadLocalised}
      onClose={() => props.onChangeLocalisedHOC(false, "showBulkUpdate")}
      children={
        <div className='grid-control'>
          <section className='grid-half-col d-flex flex-column'>
            <h2 className='at-form-input__title'>Module</h2>
            <div className='d-flex align-items-center g-2'>
              <CustomSelect
                emptyOption={true}
                placeholder='Module'
                disabled={!_.isEqual(formData, props.moduleKeys)}
                selectItems={props.modules}
                currentlySelected={selectedModule}
                updateSelect={(val) => setSelectedModule(val)} />
              {!_.isEqual(formData, props.moduleKeys) && (
                <button
                  className='btn-new btn-new--outline-secondary'
                  onClick={() => setShowConfirmation(true)}>
                  Discard
                </button>
              )}
            </div>
          </section>
          {selectedModule && (
            <section className='grid-full-col'>
              <CustomTable
                rowData={formData || []}
                columns={columnData({ onChangeFormData })}
                resetRef={false}
                pagination={true}
                renderCheckBoxActions={renderCheckBoxActions} />
            </section>
          )}
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--success"
            disabled={_.isEqual(formData, props.moduleKeys)}
            onClick={() => onToggleUpdate()}>
            Update
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeLocalisedHOC(false, "showBulkUpdate")}>
            Cancel
          </button>
        </div>
      } />
      <ConfirmationModal
        open={showConfirmation}
        title={"Discard Changes"}
        message={"Are you sure to discard unsaved changes?"}
        loading={props.onLoadLocalised}
        positiveText={"Discard"}
        positiveAction={() => {
          setFormData(props.moduleKeys)
          setShowConfirmation(false)
        }}
        negativeText={"Cancel"}
        negativeAction={() => setShowConfirmation(false)}
      />
    </>
  )
}

export default BulkUpdate