import React, { Component } from "react";
import { PulseLoader } from "react-spinners";

import "stylesheets/components/loader/index.scss";

class LoadingOverlay extends Component {
  state = {
    isPaused: false,
  };

  render() {
    const { showLoadingOverlay } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: showLoadingOverlay ? "flex" : "none",
          background: "rgba(0,0,0,0.8)",
          pointerEvents: "none",
          zIndex: 7,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PulseLoader
          className={"mt-10"}
          sizeunit={"px"}
          size={30}
          width={500}
          color={"#FFFFFF"}
          loading={this.state.searching}
        />
      </div>
    );
  }
}

export default LoadingOverlay;
