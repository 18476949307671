import React, { Component } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
import { RiCheckDoubleLine } from "react-icons/ri";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import NotificationIcon from "containers/Dashboard/Notification/icons";

import "stylesheets/components/topbar/index.scss";
import "./notifications.scss";
class Notification extends Component {
  state = {
    panelData: [],
    pageNumber: 1,
  };

  shouldComponentUpdate(nextProps, nextState) {
    let compareArray = _.isEqual(
      this.state.panelData.sort(),
      nextProps.panelData.sort(),
    );
    if (!compareArray) {
      this.setState({
        panelData: nextProps.panelData,
      });
    }
    return true;
  }

  onClickNavigateToNotifications() {
    const tmpPath = window.location.href.includes("/admin-impersonate")
      ? this.props.prefix_path
      : "";

    this.props.history.push(`${tmpPath}/dashboard/notification`);
    this.props.onClickCloseNotification();
  }

  onSelectNotification = (dataToSubmit) => {
    this.props.markNotificationAsRead(dataToSubmit);
  };

  renderPanelContent = (param) => {
    if (param.length > 0) {
      return (
        <>
          {param.map((item, index) => {
            return (
              <div
                id={`NotificationsDropbear-${index}`}
                key={`NotificationsDropbear-${index}`}
                style={{ cursor: "pointer" }}
                className={`at-notification__dropdown-panel ${item.read ? "" : "info-read"}`}
                onClick={() => this.onSelectNotification(item)}
              >
                <NotificationIcon iconType={item.notification.type} />
                <div className="w-100">
                  <h1 className="at-notification__dropdown-title">
                    {item.notification.title}
                  </h1>
                  <p>{item.notification.created_at}</p>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <div className="at-notification__dropdown-info" style={{ height: 150 }}>
          <p className="at-notification__dropdown-title">
            There are no new notification.
          </p>
        </div>
      );
    }
  };

  render() {
    const { className } = this.props;

    return (
      <div className={`at-notification__dropdown ${className}`}>
        <div className="at-notification__dropdown-header">
          <h1>{"Notifications"}</h1>
          <div className="d-flex align-items-center g-3">
            <span
              className="at-notification__dropbear__link"
              onClick={() => this.props.markAllNotificationAsRead()}
            >
              <RiCheckDoubleLine
                style={{ width: 20, height: 20, marginRight: 5 }}
              />
              Mark All as Read
            </span>
            <CustomButton
              className={"btn-new btn-new--outline-secondary"}
              style={{ minHeight: 30, width: 30, padding: 0 }}
              children={
                <AtlasIcon
                  svgHref={"atlas-X"}
                  style={{ width: 14, height: 14, color: "#374151" }}
                />
              }
              onClick={() => this.props.onClickCloseNotification()}
            />
          </div>
        </div>
        {this.state.panelData && this.state.panelData.length >= 0 && (
          <div className="at-notification__dropdown-body">
            {this.renderPanelContent(this.state.panelData)}
            <div className="d-flex justify-content-center">
              <PulseLoader
                className={"mt-10"}
                sizeunit={"px"}
                size={10}
                color={"#16232c"}
                loading={this.props.onLoadModalNotification}
              />
              {!this.props.onLoadModalNotification &&
                this.state.pageNumber !== this.props.totalPages && (
                  <button
                    className="btn-new-sm btn-new--outline-secondary"
                    style={{ padding: "5px 10px", marginBottom: 10 }}
                    onClick={() => {
                      let temp = this.state.pageNumber + 1;
                      this.setState({ pageNumber: temp }, () =>
                        this.props.getListAPI(temp, ""),
                      );
                    }}
                  >
                    Load More
                  </button>
                )}
            </div>
          </div>
        )}
        <div className="at-notification__dropdown-footer">
          <span
            className="at-notification__dropbear__link"
            onClick={() => this.onClickNavigateToNotifications()}
          >
            Show All Notifications
          </span>
        </div>
      </div>
    );
  }
}

export default Notification;
