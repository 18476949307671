import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post, Put, GetFile } from "utils/axios";
import { getCurrentUserProfile } from "actions/profile";
import { getAllDictionaryData } from "actions/dictionary";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { storeSubsalesAddress } from "actions/subsales/address";
import { refreshToken } from "actions/login";
import { storeLastView } from "actions/lastView";
import { dateValidation } from "utils/checkNull";

const SubsaleType = [
  { id: 1, name: "Sale" },
  { id: 2, name: "Rental" },
];

const searchParams = [
  {
    label: "Form Number",
    value: "transaction_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "claim_status",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "pending", label: "Pending" },
      { value: "submitted", label: "Submitted" },
      { value: "cancelled", label: "Cancelled" },
    ],
  },
];

const initialPropertyData = {
  unit_no: "",
  address: "",
  name_s_lower: "",
  property_name: "",
  postcode: "",
  built_up: 0,
  land_area: 0,
  category_id: "",
  property_type_id: "",
  tenure_type_id: "",
  unit_type_id: "",
  area_s_lower: "",
  state_s_lower: "",
  street_name_s_lower: "",
  address: "",
  country_id: "",
  id: "",
  source_id: "",
  source_type: "",
  state_id: "",
  township_id: "",
};

const ConfirmationFormHOC = (WrappedComponent) => {
  class ConfirmationFormWrappedComponent extends Component {
    state = {
      searchParams: searchParams,
      confirmation_forms: {},
      confirmationFormPage: null,
      initialConfirmationForm: null,
      selected_confirmation_form: {
        id: 0,
        invoicing_branch_id: null,
        transaction_number: "",
        confirmation_date: null,
        execution_days: 0,
        buyers_name: "",
        buyers_nric: "",
        buyers_contact: "",
        buyers_address: "",
        owners_name: "",
        owners_nric: "",
        owners_contact: "",
        owners_address: "",
        property_address: "",
        stake_holder_id: 1,
        stake_holder_company: "",
        stake_holder_address: "",
        stake_holder_registration_number: "",
        stake_holder_contact_number: "",
        stake_holder_bank_id: 1,
        stake_holder_bank_account_number: "",
        earnest_deposit_amount: 0,
        deposit_cheque_number: "",
        deposit_payment_method_id: 1,
        monthly_rental_amount: 0,
        one_month_earnest_deposit: 0,
        advanced_rental_months: 0,
        advanced_rental_amount: 0,
        security_deposit_amount: 0,
        security_deposit_months: 0,
        utility_deposit_amount: 0,
        utility_deposit_months: 0,
        disbursement_and_stamping_fee: 0,
        before_handover_amount: 0,
        intended_tenure_years: 0,
        renewal_tenure_years: 0,
        commencement_date: null,
        other_conditions: "",
        has_extra_conditions: false,
        extra_conditions: "",
        special_conditions: "",
        sales_tax_percentage: 0,
        sales_tax_amount: 0,
        agency_fee_months: 0,
        agency_fee_amount: 0,
        purchase_price: 0,
        down_payment_percentage: 0,
        down_payment_amount: 0,
        balance_pay_within_days: 0,
        balance_pay_within_another_days: 0,
        balance_deposit_amount: 0,
        balance_deposit_wording: "",
        balance_deposit_percentage_wording: "",
        balance_deposit_percentage: 0,
        balance_purchase_price: 0,
        balance_purchase_price_wording: "",
        balance_purchase_price_percentage_wording: "",
        balance_purchase_price_percentage: 0,
        chargeable_interest_percentage: 0,
        professional_fee_percentage: 0,
        type_id: 1,
        is_claimed: false,
        is_wizard_form: false,
        down_payment_wording: "",
        vacant_posession_day: 0,
        expiration_day: 0,
        annum_interest_rate: 0,
      },
      selected_form_property: initialPropertyData,
      purchaserNTenant: false,
      totalBuyerCount: 1,
      totalSellerCount: 1,
      disableSubmitButton: false,
      showCreationForm: false,
      showCloneConfirmationModal: false,
      showConfirmationForm: false,
      showSubmitClaimForm: false,
      showSubmitConfirmationClaimForm: false,
      showDownloadConfirmationModal: false,
      showLoadingModal: false,
      showSpecialConditionRTE: false,
      showAmountConverterModal: false,

      selectedCloneId: null,
      tempEProcess: null,
      townshipsList: [],
    };

    componentDidMount = () =>
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeConfirmationHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeConfirmationFormValue = (title, context) => {
      let tmp = _.cloneDeep(this.state.selected_confirmation_form);
      tmp[title] = context;
      return this.setState({ selected_confirmation_form: tmp });
    };

    saveTnC = ({from, professional_fee_percentage, professional_fee_amount}) => {
      let tmpSelectedConfirmationForm = _.cloneDeep(this.state.selected_confirmation_form)

      if (tmpSelectedConfirmationForm.owners_address !== "") {
        this.props.storeSubsalesAddress(
          tmpSelectedConfirmationForm.owners_address,
        );
      }
      if (tmpSelectedConfirmationForm.buyers_address !== "") {
        this.props.storeSubsalesAddress(
          tmpSelectedConfirmationForm.buyers_address,
        );
      }
      if (tmpSelectedConfirmationForm.property_address !== "") {
        this.props.storeSubsalesAddress(
          tmpSelectedConfirmationForm.property_address,
        );
      }
      if (tmpSelectedConfirmationForm.stake_holder_address !== "") {
        this.props.storeSubsalesAddress(
          tmpSelectedConfirmationForm.stake_holder_address,
        );
      }

      if(from === "amountConverterModal") {
        tmpSelectedConfirmationForm.professional_fee_percentage = professional_fee_percentage
        tmpSelectedConfirmationForm.professional_fee_amount = professional_fee_amount
      }
      
      this.updateConfirmationForm(
        {
          ...tmpSelectedConfirmationForm,
          expiration_day: tmpSelectedConfirmationForm.balance_pay_within_days
        },
        from
      );
    };

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmationClaimForm: false,
        showSubmitClaimForm: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    onTriggerSpecialConditionModal = (extra_conditions, special_conditions) => {
      this.onChangeConfirmationFormValue("extra_conditions", extra_conditions);
      this.onChangeConfirmationFormValue(
        "special_conditions",
        special_conditions,
      );
    };

    getConfirmationForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      let searchTmp = _.cloneDeep(search);
      if (searchTmp.includes("q[claim_status]")) {
        searchTmp = _.replace(searchTmp, "q[claim_status]", "claim_status");
      }
      Get(
        `/forms/confirmation_forms?${searchTmp}page=${page}`,
        this.getConfirmationFormsSuccess,
        this.getConfirmationFormsError,
        this.load,
      );
    };
    getConfirmationFormsSuccess = ({ data = [], meta }) => {
      let tmpData = [];
      let tmpTotalPages = [];

      data.map((item) => {
        let type = _.find(SubsaleType, { id: item.type_id });
        let temp = {
          ...item,
          confirmation_date: dateValidation(
            item.confirmation_date,
            "YYYY-MM-DD",
            "DD MMM YYYY",
          ),
          type: type ? type.name : "",
          is_claimed: item.is_claimed ? "Yes" : "No",
          is_wizard_form: true,
        };
        return tmpData.push(temp);
      });

      for (let i = 0; i < meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      this.setState({
        confirmation_forms: {
          data: tmpData,
          meta,
        },
        confirmationFormPage: tmpTotalPages,
      });
    };
    getConfirmationFormsError = (error) => requestError(error);

    getConfirmationForm = (id) =>
      Get(
        `/forms/confirmation_forms/${id}`,
        this.getConfirmationFormSuccess,
        this.getConfirmationFormError,
        this.load,
      );
    getConfirmationFormSuccess = (payload) => {
      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_confirmation", "rent_confirmation"].find((type) => {
        if (tmpPayload[type] && tmpPayload[type] !== null)
          return tmpPayload[type];
      });

      let tmpSelectedConfirmationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: payload.id
      };

      if(payload.type_id === 1) {
        tmpSelectedConfirmationForm = {
          ...tmpSelectedConfirmationForm,
          purchase_price: parseFloat(tmpPayload?.sale_confirmation?.purchase_price ?? 0)?.toFixed(2),
          down_payment_amount: parseFloat(tmpPayload?.sale_confirmation?.down_payment_amount ?? 0)?.toFixed(2),
          balance_deposit_amount: parseFloat(tmpPayload?.sale_confirmation?.balance_deposit_amount ?? 0)?.toFixed(2),
          balance_purchase_price: parseFloat(tmpPayload?.sale_confirmation?.balance_purchase_price ?? 0)?.toFixed(2),
          earnest_deposit_amount: parseFloat(tmpPayload?.earnest_deposit_amount ?? 0)?.toFixed(2),
        }
      } else if(payload.type_id === 2) {
        tmpSelectedConfirmationForm = {
          ...tmpSelectedConfirmationForm,
          monthly_rental_amount: parseFloat(tmpPayload?.rent_confirmation?.monthly_rental_amount ?? 0)?.toFixed(2),
          advanced_rental_amount: parseFloat(tmpPayload?.rent_confirmation?.advanced_rental_amount ?? 0)?.toFixed(2),
          one_month_earnest_deposit: parseFloat(tmpPayload?.rent_confirmation?.one_month_earnest_deposit ?? 0)?.toFixed(2),
          security_deposit_amount: parseFloat(tmpPayload?.rent_confirmation?.security_deposit_amount ?? 0)?.toFixed(2),
          utility_deposit_amount: parseFloat(tmpPayload?.rent_confirmation?.utility_deposit_amount ?? 0)?.toFixed(2),
          stamping_fee: parseFloat(tmpPayload?.rent_confirmation?.stamping_fee ?? 0)?.toFixed(2),
          disbursement_amount: parseFloat(tmpPayload?.rent_confirmation?.disbursement_amount ?? 0)?.toFixed(2),
          access_key_and_car_park_deposit_amount: parseFloat(tmpPayload?.rent_confirmation?.access_key_and_car_park_deposit_amount ?? 0)?.toFixed(2),
          before_handover_amount: parseFloat(tmpPayload?.rent_confirmation?.before_handover_amount ?? 0)?.toFixed(2),
          earnest_deposit_amount: parseFloat(tmpPayload?.earnest_deposit_amount ?? 0)?.toFixed(2),
          agency_fee_amount: parseFloat(tmpPayload?.rent_confirmation?.agency_fee_amount ?? 0)?.toFixed(2),
        }
      }

      delete tmpSelectedConfirmationForm.sale_confirmation;
      delete tmpSelectedConfirmationForm.rent_confirmation;

      for (const name in tmpSelectedConfirmationForm) {
        if (name.includes("date")) {
          tmpSelectedConfirmationForm[name] = dateValidation(
            tmpSelectedConfirmationForm[name],
            "YYYY-MM-DD",
            "DD MMM YYYY",
          );
        }
      }
      let tmpPropertLocation = payload.property_location ?? initialPropertyData;
      let tmpPropertyData = {};
      if (tmpPropertLocation) {
        tmpPropertyData = {
          ...tmpPropertLocation,
          property_name: tmpPropertLocation.property_name,
          postcode: tmpPropertLocation.postcode,
          built_up: tmpPropertLocation.built_up,
          land_area: tmpPropertLocation.land_area,
          category_id: tmpPropertLocation.category_id,
          property_type_id: tmpPropertLocation.property_type_id,
          tenure_type_id: tmpPropertLocation.tenure_type_id,
          unit_type_id: tmpPropertLocation.unit_type_id,
          address: tmpPropertLocation.address,
          country_id: tmpPropertLocation.country_id,
          id: tmpPropertLocation.id,
          source_id: tmpPropertLocation.source_id,
          source_type: tmpPropertLocation.source_type,
          state_id: tmpPropertLocation.state_id,
          township_id: tmpPropertLocation.township_id,
        };
      }
      this.setState({
        selected_form_property: tmpPropertyData,
      });

      return this.setState({
        tempEProcess: payload.e_process,
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selected_confirmation_form: tmpSelectedConfirmationForm,
        totalBuyerCount: 1,
        totalSellerCount: 1,
        showConfirmationForm: true,
      });
    };
    getConfirmationFormError = (error) => requestError(error);

    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        townshipsList: _.map(payload, (town) => {
          return {
            ...town,
            value: town.name,
          };
        }),
      });
    getSelectedTownShipError = (error) => requestError(error);

    updateConfirmationForm = (dataToSubmit, from) => {
      let temp = _.cloneDeep(dataToSubmit);
      if (temp.is_wizard_form) {
        delete temp.inventory_groups;
        delete temp.include_inventory;
      }

      Put(
        `/forms/confirmation_forms/${temp.id}`,
        temp,
        payload => this.updateConfirmationFormSuccess(payload, from),
        this.updateConfirmationFormError,
        this.load,
      );
    };
    updateConfirmationFormSuccess = (payload, from) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_confirmation", "rent_confirmation"].find((type) => {
        if (tmpPayload[type] && tmpPayload[type] !== null)
          return tmpPayload[type];
      });

      let tmpSelectedConfirmationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: payload.id,
      };

      delete tmpSelectedConfirmationForm.sale_confirmation;
      delete tmpSelectedConfirmationForm.rent_confirmation;

      for (const name in tmpSelectedConfirmationForm) {
        if (name.includes("date")) {
          tmpSelectedConfirmationForm[name] = dateValidation(
            tmpSelectedConfirmationForm[name],
            "YYYY-MM-DD",
            "DD MMM YYYY",
          );
        }
      }
      if(from === "amountConverterModal") {
        this.setState({showAmountConverterModal: false});
      }

      this.setState({
        tempEProcess: payload.e_process,
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selected_confirmation_form: tmpSelectedConfirmationForm,
      });

      this.getConfirmationForms(currentPage, searchParams);
      this.getConfirmationForm(payload.id)
      requestSuccess("Confirmation form updated successfully.");
    };
    updateConfirmationFormError = (error) => requestError(error);

    updateViewForm = (dataToSubmit) =>
      Put(
        `/forms/confirmation_forms/${dataToSubmit.id}/toggle_is_wizard_form`,
        {},
        () => {},
        () => {},
        () => {},
      );

    updateInventoryList = (dataToSubmit) =>
      Put(
        `/forms/confirmation_forms/${dataToSubmit.id}/update_inventory`,
        dataToSubmit,
        (payload) => this.updateInventoryListSuccess(payload, dataToSubmit),
        this.updateInventoryListError,
        this.load,
      );
    updateInventoryListSuccess = (payload, dataToSubmit) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      let tmpSelectedConfirmationForm = {
        ...this.state.selected_confirmation_form,
        include_inventory: payload.rent_confirmation.include_inventory,
        inventory_groups: payload.rent_confirmation.inventory_groups,
      };
      this.setState({
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selected_confirmation_form: tmpSelectedConfirmationForm,
      });
      this.getConfirmationForms(currentPage, searchParams);
      if (dataToSubmit.inventory_groups) {
        requestSuccess("Inventory List saved successfully.");
      }
    };
    updateInventoryListError = (error) => requestError(error);

    downloadConfirmationForm = (id, filename) =>
      GetFile(
        `/forms/confirmation_forms/${id}/download?with_data=true&total_buyer_count=${this.state.totalBuyerCount}&total_seller_count=${this.state.totalSellerCount}`,
        filename,
        this.downloadConfirmationFormSuccess,
        this.downloadConfirmationFormError,
        this.load,
      );
    downloadConfirmationFormSuccess = () => {
      this.getConfirmationForm(this.state.selected_confirmation_form.id);
      this.checkDownLoad(this.state.selected_confirmation_form.id, 1);
    };
    downloadConfirmationFormError = (error) => requestError(error);

    updateClosedEditing = (dataToSubmit) =>
      Put(
        `/forms/confirmation_forms/${dataToSubmit.id}/closing`,
        {},
        this.updateClosedEditingSuccess,
        this.updateClosedEditingError,
        this.load,
      );
    updateClosedEditingSuccess = () => {
      requestSuccess("Data submitted successfully.");
      this.getConfirmationForm(this.state.selected_confirmation_form.id);
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
    };
    updateClosedEditingError = (error) => requestError(error);

    updatePropertyListing = (propertyData) =>
      Put(
        `/forms/confirmation_forms/${this.state.selected_confirmation_form.id}/property_location`,
        propertyData,
        this.updatePropertyListingSuccess,
        this.updatePropertyListingError,
        this.load,
      );
    updatePropertyListingSuccess = () => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getConfirmationForms(currentPage, searchParams)
      this.getConfirmationForm(this.state.selected_confirmation_form.id);
      requestSuccess("Data updated successfully.");
    };
    updatePropertyListingError = (error) => requestError(error);

    downloadAckReceipt = (id, filename) =>
      GetFile(
        `/forms/confirmation_forms/${id}/receipt`,
        filename,
        this.downloadAckReceiptSucess,
        this.downloadAckReceiptError,
        this.load,
      );
    downloadAckReceiptSucess = () =>
      this.checkDownLoad(this.state.selected_confirmation_form.id, 2);
    downloadAckReceiptError = (error) => requestError(error);

    checkDownLoad = (id, type) =>
      Put(
        `/forms/confirmation_forms/${id}/download_completed`,
        { id: id, type_id: type },
        (payload) => this.checkDownLoadSuccess(payload, id),
        this.checkDownLoadError,
        this.load,
      );
    checkDownLoadSuccess = (payload, id) => {
      this.getConfirmationForm(id);
      requestSuccess(payload.message);
    };
    checkDownLoadError = (error) => requestError(error);

    sendAckReceipt = (id, recipient) =>
      Post(
        `/forms/confirmation_forms/${id}/deliver_receipt`,
        recipient,
        this.sendAckReceiptSuccess,
        this.sendAckReceiptError,
        this.load,
      );
    sendAckReceiptSuccess = (payload) =>
      requestSuccess("Acknowledgement Receipt sent successfully.");
    sendAckReceiptError = (error) => requestError(error);

    sealConfirmationForm = (dataToSubmit) =>
      Put(
        `/forms/confirmation_forms/${dataToSubmit.id}/seal_form`,
        "",
        this.sealConfirmationFormSuccess,
        this.sealConfirmationFormError,
        this.load,
      );
    sealConfirmationFormSuccess = () =>
      this.getConfirmationForm(this.state.selected_confirmation_form.id);
    sealConfirmationFormError = (error) => requestError(error);

    cloneConfirmationForm = (id) =>
      Post(
        `/forms/confirmation_forms/${id}/copy`,
        "",
        this.cloneConfirmationFormSuccess,
        this.cloneConfirmationFormError,
        this.load,
      );
    cloneConfirmationFormSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getConfirmationForms(currentPage, searchParams);

      requestSuccess("New form has been created successfully");
      this.getConfirmationForm(payload.id);
      this.setState({ showCloneConfirmationModal: false });
    };
    cloneConfirmationFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            tempEProcess={this.state.tempEProcess}
            searchParams={this.state.searchParams}
            totalBuyerCount={this.state.totalBuyerCount}
            totalSellerCount={this.state.totalSellerCount}
            purchaserNTenant={this.state.purchaserNTenant}
            selectedCloneId={this.state.selectedCloneId}
            confirmation_forms={this.state.confirmation_forms}
            initialConfirmationForm={this.state.initialConfirmationForm}
            selected_confirmation_form={this.state.selected_confirmation_form}
            selected_form_property={this.state.selected_form_property}
            showCreationForm={this.state.showCreationForm}
            disableSubmitButton={this.state.disableSubmitButton}
            showDownloadConfirmationModal={this.state.showDownloadConfirmationModal}
            confirmationFormPage={this.state.confirmationFormPage}
            showCloneConfirmationModal={this.state.showCloneConfirmationModal}
            onSelectSection={this.onSelectSection}
            onLoadConfirmationForm={this.state.showLoadingModal}
            showConfirmationForm={this.state.showConfirmationForm}
            townshipsList={this.state.townshipsList}
            showSubmitClaimForm={this.state.showSubmitClaimForm}
            showSubmitConfirmationClaimForm={this.state.showSubmitConfirmationClaimForm}
            showAmountConverterModal={this.state.showAmountConverterModal}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            onChangeConfirmationHOC={this.onChangeConfirmationHOC}
            updateInventoryList={this.updateInventoryList}
            sealConfirmationForm={this.sealConfirmationForm}
            getConfirmationForms={this.getConfirmationForms}
            getConfirmationForm={this.getConfirmationForm}
            updateConfirmationForm={this.updateConfirmationForm}
            downloadAckReceipt={this.downloadAckReceipt}
            sendAckReceipt={this.sendAckReceipt}
            updateViewForm={this.updateViewForm}
            cloneConfirmationForm={this.cloneConfirmationForm}
            onTriggerSpecialConditionModal={this.onTriggerSpecialConditionModal}
            downloadConfirmationForm={this.downloadConfirmationForm}
            updateClosedEditing={this.updateClosedEditing}
            updatePropertyListing={this.updatePropertyListing}
            saveTnC={this.saveTnC}
            onChangeConfirmationFormValue={this.onChangeConfirmationFormValue}
            getSelectedTownShip={this.getSelectedTownShip}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
    getAllDictionaryData,
    storeSubsalesAddress,
    refreshToken,
    storeLastView,
  })(ConfirmationFormWrappedComponent);
};

export default ConfirmationFormHOC;
