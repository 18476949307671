import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Put } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      password: "",
      password_confirmation: "",
      passwordError: "",
      passwordConfirmationError: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangePWHOC = (val, context) => this.setState({ [context]: val });

    onSubmitPassword = (dataToSubmit) =>
      Put(
        `/profiles/change_password`,
        dataToSubmit,
        this.onSubmitPasswordSuccess,
        this.onSubmitPasswordError,
        this.load,
      );
    onSubmitPasswordSuccess = () => {
      requestSuccess("Password changed successfully.");
      this.setState({
        password: "",
        password_confirmation: "",
      });
    };
    onSubmitPasswordError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadPW={this.state.loading}
          password={this.state.password}
          password_confirmation={this.state.password_confirmation}
          passwordError={this.state.passwordError}
          passwordConfirmationError={this.state.passwordConfirmationError}
          onChangePWHOC={this.onChangePWHOC}
          onSubmitPassword={this.onSubmitPassword}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
