import React from "react";
import { Tooltip } from "@material-ui/core";

import EmailImage from "assets/images/email_support.png";
import { getTranslation } from "assets/translation";

import "./index.scss";

const EmailButton = ({ language }) => {
  return (
    <Tooltip placement={"left"} title={getTranslation("label.helptext.email", language)}>
      <button
        className="at-support-group-btn telegram"
        onMouseDown={() =>
          (window.location.href = "mailto:helpdesk@iqiglobal.com")
        }
        onTouchStart={() =>
          (window.location.href = "mailto:helpdesk@iqiglobal.com")
        }
      >
        <img src={EmailImage} style={{ width: "40px", height: "40px" }} />
      </button>
    </Tooltip>
  );
};

export default EmailButton;
