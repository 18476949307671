import React from "react";
import { Card, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FaUserTie } from "react-icons/fa";

import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";

import IQILogo from "assets/images/iqi_logo_only.png";
import "stylesheets/components/card/party.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

const backgroundStyling = (status) => {
  switch (status) {
    case 1:
      return "#F18E05";
    case 2:
      return "#2ECC71";
    case 3:
      return "#D93335";
    case 4:
      return "#23ccef";
    default:
      return "#4B5563";
  }
};

const renderBadgeIcon = ({ party_id, signee_type_id, contactable_type_id }) => {
  let tempBadgeName = "";
  let tempIcon = null;

  if (contactable_type_id === 2) {
    tempIcon = <img src={IQILogo} alt="" />;
    tempBadgeName = "Internal Agent";
  } else if (contactable_type_id === 1 && party_id % 2 === 0) {
    tempBadgeName = "External Agent";
    tempIcon = <FaUserTie />;
  } else if (contactable_type_id === 1 && party_id % 2 > 0) {
    if (signee_type_id === 1) {
      tempBadgeName = "Company";
      tempIcon = (
        <AtlasIcon
          svgHref={"atlas-building-company"}
          style={{
            width: 14,
            height: 14,
            color: "#f0631d",
            fill: "#f0631d",
            marginRight: 10,
          }}
        />
    )
    } else {
      tempIcon = (
        <AtlasIcon
          svgHref={"atlas-icon-user"}
          style={{
            width: 14,
            height: 14,
            fill: "#f0631d",
            color: "#f0631d",
            marginRight: 10,
          }}
        />
      );
      tempBadgeName = "Personal";
    }
  }

  return (
    <DarkTooltip
      classes={{tooltip: `tooltip-arrow top`}}
      placement={"top"}
      enterTouchDelay={50}
      title={tempBadgeName}
    >
      <div className="party-card__badge-title-icon">{tempIcon}</div>
    </DarkTooltip>
  );
};

const PartyCard = ({
  type,
  data,
  index,
  can_create,
  containerClass,
  resendContract,
  onClickRefresh,
  onClickShare,
}) => {
  let tempStatusId = type === "refund" ? "refund_status_id" : "status_id";
  let tempStatus = type === "refund" ? "refund_status" : "status";

  return (
    <div key={index} className={`d-flex flex-wrap ${containerClass || ""}`}>
      <div className="d-flex flex-wrap">
        <div className={"party-card-title_label"}>
          <span className={"party-card-index_label"}>{index + 1}</span>
          {data.title}
        </div>
        {can_create && data[tempStatusId] === 1 && (
          <>
            <Tooltip title={"Resend Contract via email"}>
              <button
                className="at-desktop-view__controller party-card-action-button"
                onClick={() => resendContract()}
              >
                <SvgIcon svgHref="confirmation-form_mail" />
              </button>
            </Tooltip>
            {data.signing_link && (
              <Tooltip title={"Share"}>
                <button
                  className="at-desktop-view__controller party-card-action-button"
                  onClick={() => onClickShare(data)}
                >
                  <SvgIcon svgHref="confirmation-form_send" />
                </button>
              </Tooltip>
            )}
            <Tooltip title={"Refresh Status"}>
              <button
                className="at-desktop-view__controller party-card-action-button"
                onClick={() => onClickRefresh()}
              >
                <SvgIcon svgHref="confirmation-form_refresh-cycle" />
              </button>
            </Tooltip>
          </>
        )}
      </div>
      <Card className={"party-card-body w-100"}>
        <div className="d-flex">
          <div className="party-card-body_content-container">
            <div className="party-card-body-name d-flex align-items-center">
              {renderBadgeIcon(data)}
              <div>
                <label>Name</label>
                {
                  Array.isArray(data.participants_name)
                    ? data.participants_name.map(info => <p>{info}</p>)
                    : <p>{data.participants_name}</p>
                }
              </div>
            </div>
            <div className="party-card-body-email d-flex align-items-center">
              <SvgIcon svgHref="confirmation-form_mail" />
              <div>
                <label>Email</label>
                <p>{data.email}</p>
              </div>
            </div>
            <div className="party-card-body-identity d-flex align-items-center">
              <SvgIcon svgHref="confirmation-form_personal-card" />
              <div>
                <label>NRIC/Passport No.</label>
                <p>{data.identification_number}</p>
              </div>
            </div>
            <div className="party-card-body-contact d-flex align-items-center">
              <SvgIcon svgHref="confirmation-form_mobile" />
              <div>
                <label>Mobile No.</label>
                <p>{data.mobile_contact_number}</p>
              </div>
            </div>
          </div>
          <div
            className={"party-card-status_label"}
            style={{
              background: backgroundStyling(data[tempStatusId]),
            }}>
            <p>{data[tempStatus]}</p>
          </div>
        </div>
        {can_create && data[tempStatusId] === 1 && (
          <div className="at-mobile-view__controller">
            <hr style={{ marginBottom: 0 }} />
            <div className="d-flex">
              <button
                className="party-card-action-button-mobile"
                onClick={() => resendContract()}>
                <SvgIcon svgHref="confirmation-form_mail" />
                Resend Email
              </button>
              {data.signing_link && (
                <button
                  className="party-card-action-button-mobile"
                  onClick={() => onClickShare(data)}>
                  <SvgIcon svgHref="confirmation-form_send" />
                  Share
                </button>
              )}
              <button
                className="party-card-action-button-mobile"
                onClick={() => onClickRefresh()}>
                <SvgIcon svgHref="confirmation-form_refresh-cycle" />
                Refresh
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PartyCard;