import React, { useState, useEffect, useCallback, useRef } from "react";
import { AiOutlineCopy, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdCached } from "react-icons/md";

const DriveDropDown = ({
  metadata,
  searchQueryPropNames,
  clipboardURL,
  clipboardName,
  showCopyUrlFunction,
  showResetCacheFunction,
  onClickCopy,
  clearDriveCache,
}) => {
  const [dropMenuContent, setDropMenuContent] = useState(false);

  const wrapperRef = useRef(null);

  // const outsideClickCloseMenuContent = useCallback(( ref, onCloseMenu ) => {
  //   const handleClickOutside = event => {
  //     if( !dropMenuContent ) {
  //       if ( ref.current && !ref.current.contains( event.target )) {
  //         onCloseMenu()
  //         return;
  //       }
  //     }
  //   }

  //   useEffect(() => {
  //       try {
  //         // Bind the event listener
  //         document.addEventListener( "mousedown", handleClickOutside );
  //         document.addEventListener( "touchstart", handleClickOutside );
  //         return () => {
  //           // Unbind the event listener on clean up
  //           document.removeEventListener( "mousedown", handleClickOutside );
  //           document.removeEventListener( "touchstart", handleClickOutside )
  //         };
  //       } catch (err) {
  //         console.log( err )
  //       }
  //     }, [ onCloseMenu, ref.current ]);
  // }, [])

  // outsideClickCloseMenuContent( wrapperRef, () => setDropMenuContent( false ) );

  return (
    <>
      <p>•••</p>
      <input
        id="iqi_drive-actions"
        type="checkbox"
        onClick={() => setDropMenuContent(!dropMenuContent)}
      />
      <ul
        ref={wrapperRef}
        className={`${dropMenuContent ? "open" : "hide"}-iqi_drive-dropdown_menu`}
      >
        {showCopyUrlFunction && (
          <li
            className={`at-drive_table-row__item-action—copy_url ${clipboardName ? clipboardName : "copy_url-clipboard"}`}
            data-clipboard-text={JSON.stringify(clipboardURL)}
            onClick={() => {
              onClickCopy();
              setDropMenuContent(false);
            }}
          >
            <AiOutlineCopy />
            <p>Copy</p>
          </li>
        )}
        {showResetCacheFunction && (
          <li
            className={`at-drive_table-row__item-action-clear_cache`}
            onClick={clearDriveCache}
          >
            <MdCached />
            <p>Reset Cache</p>
          </li>
        )}
      </ul>
    </>
  );
};

export default DriveDropDown;
