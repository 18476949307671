import { useEffect, useState } from "react";

import { Grid, MenuItem } from "@material-ui/core";
import { BsSearch } from "react-icons/bs";

import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import Pagination from "components/NewPaginatedTable/Pagination";
import AtlasSelect from "components/Select/new";
import PreviewEmailTemplate from "./PreviewEmailTemplate";

import { getColorBadge } from "dictionary/badgeColor";

const SelectEmailTemplate = ({
  handleClose,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  emailTemplates,
  getEmailTemplates,
  categories,
  getCategories,
}) => {
  const [category, setCategory] = useState("");
  const [dialog, setDialog] = useState("");
  const [selectedId, setSelectedId] = useState(0);

  const { data, meta } = emailTemplates;

  useEffect(() => {
    getEmailTemplates(1, "status=2&");
    getCategories();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;

    setCategory(value);
    getEmailTemplates(1, `category=${value}&status=2&`);
  };

  const selectTemplate = (id) => () => {
    const emailCampaign = {
      ...selectedEmailCampaign,
      email_template_id: id,
      status: 1,
    };

    onChangeEmailCampaignsHOC("selectedEmailCampaign", emailCampaign);
    updateEmailCampaign(emailCampaign);
    handleClose();
  };

  const previewTemplate = (id) => () => {
    setSelectedId(id);
    setDialog("preview");
  };

  const getTotalPages = () => {
    const pages = [];

    if (meta && meta.pages) {
      for (let i = 0; i < meta.pages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const renderPreviewEmailTemplateDialog = () => (
    <PreviewEmailTemplate id={selectedId} handleClose={() => setDialog("")} />
  );

  return (
    <ModalDialog
      title={"Select Email Template"}
      onClose={handleClose}
      fullWidth={true}
      fullHeight={true}>
      <div className={"at-email-campaigns__select-category"}>
        <h2 className="at-form-input__title" required>Categories</h2>
        <AtlasSelect value={category} onChange={handleChange}>
          <MenuItem
            classes={{ root: `at-select__dropdown-item` }}
            value=""
            style={{ fontSize: 14 }}
          >
            All categories
          </MenuItem>

          {categories &&
            categories.map((category) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={category}
                value={category}
                style={{ fontSize: 14 }}
              >
                {category}
              </MenuItem>
            ))}
        </AtlasSelect>
      </div>

      {data && data.length > 0 && (
        <Grid container={true} spacing={24}>
          {data.map((template) => (
            <Grid key={template.id} item={true} xs={12} sm={6} md={4} lg={3}>
              <div className={"at-email-templates__card"}>
                <div className="at-email-campaigns__image-container">
                  <div className="at-email-campaigns__button-container d-flex align-items-center g-2 flex-column">
                    <AtlasButton
                      className={"btn-new btn-new--primary"}
                      onClick={selectTemplate(template.id)}
                      children={"Use template"}
                    />
                    <AtlasButton
                      className={"btn-new btn-new--outline-secondary"}
                      onClick={previewTemplate(template.id)}>
                      <BsSearch />
                      Preview
                    </AtlasButton>
                  </div>
                  <img
                    alt={template.name}
                    src={template.thumbnail_url}
                    title={template.name}
                    height={300}
                    className={"at-email-templates__card-media"}
                  />
                </div>
                <div className={"at-email-templates__card-content"}>
                  <div className="d-flex flex-wrap align-items-center g-2">
                    {template.sort_list.length > 0 && template.sort_list.map((category, index) => (
                      <span key={index} className={"at-status_badge"}
                        style={{ ...getColorBadge("Blue") }}>
                        {category}
                      </span>
                    ))}
                  </div>
                  <h5 className={"at-email-templates__card-title"}>{template.name}</h5>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {data && data.length === 0 && (
        <div
          className={
            "flex-fill d-flex flex-column justify-content-center align-items-center"
          }
        >
          <h5>
            <strong>No results found</strong>
          </h5>

          <p>
            Try adjusting your search or filter options to find what you're
            looking for
          </p>
        </div>
      )}
      {meta && meta.count > 0 && (
        <div className={"modal-footer"} style={{ paddingTop: "0.75rem" }}>
          <Pagination
            meta={meta}
            searchParams={category}
            getListAPI={getEmailTemplates}
            totalPages={getTotalPages()}
          />
        </div>
      )}

      {dialog === "preview" && renderPreviewEmailTemplateDialog()}
    </ModalDialog>
  );
};

export default SelectEmailTemplate;
