import _ from "lodash";
import { PulseLoader } from "react-spinners";
import React, { useState, useEffect, useCallback } from "react";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";

const columnData = ({ onToggleRemoveAgent }) => [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div>
        <p>{rowData.full_name}</p>
        <p>{rowData.mobile_contact_number}</p>
        <p>{rowData.email}</p>
      </div>
    ),
  },
  { header: "Team", accessor: "team_name" },
  { header: "Role", accessor: "role" },
  {
    header: "Branch",
    renderColumn: (rowData) => (
      <div>
        <p>{rowData.branch_name}</p>
        <p>{rowData.country_name}</p>
      </div>
    ),
  },
];

const PreOrderNameList = ({
  agentList,
  onLoadAgent,
  searchParams,
  showAddNameList,
  showRemoveNameList,
  onLoadEditProduct,
  editProductForm,
  preOrderNameList,
  preOrderNameListPages,
  onLoadProductMngmt,

  onChangeAgentHOC,
  getOrderNameList,
  getAgentsBySearch,
  createPreOrderName,
  removePreOrderName,
  onChangeEditProductHOC,
}) => {
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    getAgentsBySearch("");
    getOrderNameList(editProductForm.id, 1, "");
  }, []);

  useEffect(() => {
    setSelectedAgent(null);
  }, [showAddNameList]);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const onToggleRemoveAgent = (param) =>
    Promise.all([setSelectedAgent(param)]).then(() => {
      onChangeEditProductHOC(true, "showRemoveNameList");
    });

  return (
    <>
      <AtPaginatedTable
        columns={columnData({
          onToggleRemoveAgent,
        })}
        rowData={preOrderNameList.data}
        meta={preOrderNameList.meta}
        searchParams={[]}
        renderCheckBoxActions={() => (
          <div className="at-table--checkbox-actions">
            <CustomButton
              className={"btn-new-sm btn-new--primary my-2"}
              children={"Add Name List"}
              onClick={() => onChangeEditProductHOC(true, "showAddNameList")}
            />
          </div>
        )}
        actionColumnContent={[
          {
            name: "delete",
            color: "#F04438",
            onClick: (rowData) => onToggleRemoveAgent(rowData),
          },
        ]}
        onChangeSearchParams={(val) =>
          onChangeEditProductHOC(val, "searchParams")
        }
        getListAPI={(page, search) =>
          getOrderNameList(editProductForm.id, page, search)
        }
        totalPages={preOrderNameListPages}
      />
      {showAddNameList && (
        <ModalDialog
          title={"Add Name List"}
          onLoad={onLoadEditProduct}
          onClose={() => onChangeEditProductHOC(false, "showAddNameList")}
          children={
            <div className="grid-control grid_gap-2">
              <section className="grid-full-col">
                <div className="d-flex">
                  <h2 className="at-form-input__title">{"Select an Agent"}</h2>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </div>
                <CustomTypeahead
                  disabled={onLoadAgent}
                  typeaheadId={"internal_agent"}
                  options={agentList || []}
                  selectedValue={selectedAgent ? [selectedAgent] : []}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    setSelectedAgent(null);
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && setSelectedAgent(val[0])
                  }
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                />
              </section>
              <section className="grid-full-col">
                <CustomButton
                  style={{ marginTop: 100 }}
                  className={"btn-new btn-new--success"}
                  children={"Submit Now"}
                  onClick={() =>
                    createPreOrderName(editProductForm.id, selectedAgent.id)
                  }
                />
              </section>
            </div>
          }
        />
      )}
      <ConfirmationModal
        title={"Delete Agent from Name List"}
        loading={onLoadEditProduct}
        open={showRemoveNameList}
        message={`Are you sure you want to remove this agent from the namelist?`}
        positiveAction={() =>
          removePreOrderName(editProductForm.id, selectedAgent.id)
        }
        negativeAction={() =>
          onChangeEditProductHOC(false, "showRemoveNameList")
        }
      />
      {onLoadProductMngmt && <LoadingModal />}
    </>
  );
};

export default PreOrderNameList;
