import React from "react";
import CustomCheckbox from "components/Checkbox";

const DriveTableHead = ({
  headerData = [],
  sortingMode,
  sortingName,
  downloadList,
  checkableItems,
  onSortModifiedData,
  onSortName,
  onChangeHOC,
}) => {
  return (
    <div className={`at-drive_table-row_cont at-drive_table-header_row`}>
      <div className={"at-table__height-control at-drive_table_height-control"}>
        <ul className={`at-table-row_item_cont drive_grid-controller`}>
          {headerData.length > 0 &&
            headerData.map((item, index) => (
              <li
                key={`header_column-${index}`}
                className={`at-drive_table-row__item${item.classNameWord ? "-" + item.classNameWord : ""} 
                  at-drive_table-row_item-column_${index}`}
              >
                <span>{item.showName ? item.label : ""}</span>
                {checkableItems.length > 0 && item.label === "Checkbox" && (
                  <CustomCheckbox
                    checked={checkableItems.length === downloadList.length}
                    onChangeCheckboxValue={(event) => {
                      onChangeHOC(
                        event.target.checked ? checkableItems : [],
                        "downloadList",
                      );
                    }}
                  />
                )}
                {(item.label === "Modified" || item.label === "Name") && (
                  <div
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      item.label === "Modified"
                        ? onSortModifiedData()
                        : onSortName()
                    }
                  >
                    <span
                      style={{
                        color:
                          item.label === "Modified"
                            ? sortingMode === "desc"
                              ? "#f18e03"
                              : "black"
                            : sortingName === "desc"
                              ? "#f18e03"
                              : "black",
                        fontSize: 15,
                      }}
                    >
                      ↑
                    </span>
                    <span
                      style={{
                        color:
                          item.label === "Modified"
                            ? sortingMode === "asc"
                              ? "#f18e03"
                              : "black"
                            : sortingName === "asc"
                              ? "#f18e03"
                              : "black",
                        fontSize: 15,
                      }}
                    >
                      ↓
                    </span>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default DriveTableHead;
