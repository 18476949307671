import React, { Component } from "react";
import _ from "lodash";
import { MdFilterList } from "react-icons/md";

import LoadingModal from "components/LoadingModal";
import CustomTableHeader from "./components/header";
import CustomTableContent from "./components/content";
import TablePagination from "components/NewPaginatedTable/Pagination";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

class SimpleTable extends Component {
  state = {
    rowData: [],
    headerRearrangeData: {},
  };

  componentDidMount = () => this.setState({ rowData: this.props.rowData });

  componentDidUpdate = (pp) => {
    pp.rowData !== this.props.rowData &&
      this.setState({
        rowData: this.props.rowData,
      });
  };

  renderTableContent = () => {
    const {
      headerData,
      clearFilterAction,
      actionColumn,
      rowKey,
      actionColumnContent,
      booleanColumn,
      thousandSeparatorColumn,
      sendEmailDocType,
      downloadDocType,
      disabledDelete,
      secondLayerHeaderData,
      secondLayerRow,
    } = this.props;

    if (this.state.rowData && this.state.rowData.length > 0) {
      return this.state.rowData.map((rowItem, index) => {
        return (
          <CustomTableContent
            key={index}
            disabledDelete={disabledDelete}
            headerData={headerData}
            secondLayerHeaderData={secondLayerHeaderData}
            actionColumn={actionColumn}
            actionColumnContent={actionColumnContent}
            rowItem={rowItem}
            detailsClick={(param) => this.props.detailsClick(param)}
            deleteClick={(param) => this.props.deleteClick(param)}
            sendEmail={(param) => this.props.sendDocClick(param)}
            downloadClick={(param) => this.props.downloadClick(param)}
            rowKey={rowKey}
            headerRearrangeData={this.state.headerRearrangeData}
            booleanColumn={booleanColumn}
            thousandSeparatorColumn={thousandSeparatorColumn}
            sendEmailDocType={sendEmailDocType}
            downloadDocType={downloadDocType}
            secondLayerRow={secondLayerRow}
          />
        );
      });
    } else if (
      this.props.search.some((item) => item.param) &&
      this.state.rowData.length === 0
    ) {
      return (
        <div className="at-empty-state">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p>No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
              <br />
              {clearFilterAction && (
                <button onClick={() => clearFilterAction()}>
                  Clear Filters
                </button>
              )}
            </li>
          </ul>
        </div>
      );
    } else if (this.state.rowData && this.state.rowData.length === 0) {
      return (
        <EmptyState
          title={`No data presence`}
          description={""}
          renderIcon={
            <AtlasIcon
              style={{ width: 25, height: 25 }}
              svgHref="atlas-chart-1"
            />
          }
        />
      );
    }
  };

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      }
    });

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  render = () => {
    const {
      search,
      className,
      headerData,
      actionColumn,
      selected_claim,
      actionColumnContent,
      getSimilarProperties,
      onLoadSimilarProperties,
      onChangeSimilarPropertiesHOC,
    } = this.props;
    return (
      <div className={`mb-3 ${className}`}>
        <div className="mb-10">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() =>
              this.props.onChangeSimilarPropertiesHOC(
                "showSearchContent",
                !this.props.showSearchContent,
              )
            }
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          <div className="at-table__search-cont at-desktop-view__controller ">
            <TableSearchParams
              mode={"desktop"}
              searchParams={search}
              onChangeSearchParams={(val) =>
                onChangeSimilarPropertiesHOC("search", val)
              }
              getListAPI={(page, searchField) =>
                getSimilarProperties(selected_claim.id, page, searchField)
              }
            />
          </div>
        </div>
        <div className="at-table at-table--set mt-10">
          <CustomTableHeader
            data={headerData}
            actionColumnContent={actionColumnContent}
            actionColumn={actionColumn}
            onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
          />
          {this.renderTableContent()}
        </div>
        {this.props.meta && this.props.meta.count > 0 && (
          <div className="at-pagination__cont">
            <TablePagination
              meta={this.props.meta}
              searchParams={search}
              getListAPI={(page, searchField) =>
                getSimilarProperties(selected_claim.id, page, searchField)
              }
              totalPages={this.props.totalPages}
            />
            {this.renderPaginationText()}
          </div>
        )}
        {this.props.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            searchParams={search}
            onToggleSearchModal={() =>
              this.props.onChangeSimilarPropertiesHOC(
                "showSearchContent",
                !this.props.showSearchContent,
              )
            }
            onChangeSearchParams={(val) =>
              onChangeSimilarPropertiesHOC("search", val)
            }
            getListAPI={(page, searchField) =>
              getSimilarProperties(selected_claim.id, page, searchField)
            }
          />
        )}
        {onLoadSimilarProperties && <LoadingModal />}
      </div>
    );
  };
}

export default SimpleTable;
