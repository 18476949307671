import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Post, Put, Get, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateModal: false,
      showUpdateModal: false,
      showDuplicateModal: false,
      showDeleteConfirmation: false,
      showDuplicatedConfirmation: false,

      holidays: [],
      holidayMeta: {},
      holidayPage: "",
      selectedHoliday: {},

      searchParams: [
        {
          label: "Name",
          value: `name_cont`,
          type: "input",
          param: "",
        },
        {
          label: "Date From",
          value: `date_gteq`,
          type: "date",
          param: "",
        },
        {
          label: "Date To",
          value: `date_lteq`,
          type: "date",
          param: "",
        },
        {
          label: "Country",
          value: "country_id_eq",
          type: "select",
          param: "",
          options: this.props.data.dictionaryReducer.countries,
        },
      ],
    };

    load = (param) => this.setState({ loading: param });
    onChangeHolidayHOC = (val, context) => this.setState({ [context]: val });

    getHolidays = (page, search) =>
      Get(
        `/admin/public_holidays?${search}&page=${page}`,
        this.getHolidaysSuccess,
        this.getHolidaysError,
        this.load,
      );
    getHolidaysSuccess = (payload) => {
      let temp = [];
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      payload.data?.map((item) => {
        const selectedCountry =
          this.props.data.dictionaryReducer.countries?.find(
            (country) => country.id === item.country_id,
          );
        temp.push({
          ...item,
          country: selectedCountry?.name,
          state_ids: item.state_ids || [],
          region: item.state_ids
            ?.map(
              (state) =>
                selectedCountry?.states?.find((region) => region.id === state)
                  ?.name,
            )
            .join(", "),
        });
      });

      this.setState({
        holidays: temp,
        holidayMeta: payload.meta,
        holidayPage: tempPages,
      });
    };
    getHolidaysError = (err) => requestError(err);

    createHoliday = (dataToSubmit) =>
      Post(
        "/admin/public_holidays",
        { public_holidays: dataToSubmit },
        this.createHolidaySuccess,
        this.createHolidayError,
        this.load,
      );
    createHolidaySuccess = (payload) => {
      this.setState({
        showCreateModal: false,
        showDuplicateModal: false,
      });
      this.getHolidays(1, "");
      requestSuccess("Holiday created successfully");
    };
    createHolidayError = (err) => requestError(err);

    updateHoliday = (dataToSubmit) =>
      Put(
        `/admin/public_holidays/${dataToSubmit.id}`,
        { public_holiday: dataToSubmit },
        this.updateHolidaySuccess,
        this.updateHolidayError,
        this.load,
      );
    updateHolidaySuccess = (payload) => {
      this.setState({ showUpdateModal: false });
      this.getHolidays(1, "");
      requestSuccess("Holiday updated successfully");
    };
    updateHolidayError = (err) => requestError(err);

    deleteHoliday = (id) =>
      Delete(
        `/admin/public_holidays/${id}`,
        this.deleteHolidaySuccess,
        this.deleteHolidayError,
        this.load,
      );
    deleteHolidaySuccess = (payload) => {
      this.setState({ showDeleteConfirmation: false });
      this.getHolidays(1, "");
      requestSuccess("Holiday deleted successfully");
    };
    deleteHolidayError = (err) => requestError(err);

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          onLoadPublicHoliday={this.state.loading}
          getHolidays={this.getHolidays}
          createHoliday={this.createHoliday}
          updateHoliday={this.updateHoliday}
          deleteHoliday={this.deleteHoliday}
          onChangeHolidayHOC={this.onChangeHolidayHOC}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
