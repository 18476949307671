import React, { Component } from "react";
import _ from "lodash";

import defaultImage from "assets/images/iqi_logo_only.png";
import "../../index.scss";

const redeemedStamp =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/redeemed_stamp.png";

class CustomCard extends Component {
  render = () => {
    const { is_used, expiry_date, code, market_product, redeem_date } =
      this.props.data;

    return (
      <li className={`at-atlas_store_ticket_table-item_cont at-coupon__item`}>
        <div className="at-atlas_store_ticket_table-item_left">
          <div className="top"></div>
          <div className="rip"></div>
          <div className="bottom"></div>
        </div>
        <div className="at-atlas_store_ticket_table-item_right"></div>
        <div className="at-atlas_store_ticket_table-content">
          <div className="at-ticket__img-cont">
            <img
              className={"at-ticket__image"}
              src={
                market_product && market_product.photo_url
                  ? market_product.photo_url
                  : defaultImage
              }
            />
          </div>
          <div className="at-coupon__details-cont w-100">
            {is_used && (
              <img
                className="at-coupon-redeemed_image"
                src={redeemedStamp}
              ></img>
            )}
            <h4>{`${market_product && market_product.name ? market_product.name : "N/A"}`}</h4>
            <span>{`Coupon No: ${code}`}</span>
            <hr className={"w-100 my-2"} />
            <div className="row">
              <div className={`${is_used ? "col-6" : "col-12"}`}>
                <label>{`Expiry Date:`}</label>
                <p className="color-danger">{expiry_date || "-"}</p>
              </div>
              {is_used && (
                <div className="col-6">
                  <label>{`Redeemed On:`}</label>
                  <p>{redeem_date || "-"}</p>
                </div>
              )}
            </div>
            <button
              type={"button"}
              className={`btn-new ${is_used ? "btn-new--secondary" : "btn-new--primary"}`}
              onClick={() => this.props.onClickRedeem(this.props.data)}
            >
              {is_used ? "View Details" : "Redeem"}
            </button>
          </div>
        </div>
      </li>
    );
  };
}

export default CustomCard;
