import React from "react";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
};

const ProjectPhotoSlides = ({
  internal_name,
  display_subtitle,
  project_photos,
}) => {
  return (
    <div className="d-flex flex-column project-detail__image">
      <div className={"slider-wrap position-relative"}>
        <Slider {...settings}>
          {project_photos.map((item, index) => {
            return (
              <div key={index} className="slider-item position-relative">
                {item.url && (
                  <img
                    style={{ width: "100%" }}
                    className={`slider-image`}
                    key={`ProjectSlideshowImage-${index}`}
                    src={item.url}
                    alt={`ProjectSlideshowImage-${index}`}
                  />
                )}
                <div className={`slider-text-background`}></div>
                <div className="container">
                  <div className="slider-content">
                    <div className="text-center w-100">
                      <h2 className={"text-white"}>{internal_name}</h2>
                      <h5 className={"text-white"}>{display_subtitle}</h5>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ProjectPhotoSlides;
