import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import CustomCard from "components/Card";
import CustomTabs from "components/Tab";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import BlockerTutorial from "components/BlockerTutorial";
import MyTrainingContent from "./components/myTrainingContent";
import AtlasSimpleTable from "./components/Table";

import MyTrainingHOC from "./actions";
import TokenHOC from "./actions/token";
import MyExamHOC from "./actions/myExam";
import VersionCheckHOC from "actions/versionCheck";
import { getColorBadge } from "dictionary/badgeColor";

import "./index.scss";

const ColumnData = [
  {
    label: "Exam",
    value: "title",
  },
  {
    label: "Status",
    value: "status",
    width: "30%",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <div
        className="at-status_badge"
        style={{
          ...getColorBadge(rowData.status === "Pass" ? "Green" : "Red"),
        }}
      >
        <AtlasIcon
          style={{ width: 20, height: 20, marginRight: 4 }}
          svgHref={
            rowData.status === "Pass"
              ? "atlas-tick-circle"
              : "atlas-close-circle"
          }
        />
        {rowData.status}
      </div>
    ),
  },
];

class MyTraining extends Component {
  componentDidMount = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    this.props.getMyTraining();
    this.props.getMyExam();
    this.props.getCurrentExam();
    this.props.getTrainingCompletionStatus();

    if (role === "Associated Agent") {
      if (this.props.data.permissionsReducer) {
        let tmp = _.find(this.props.data.permissionsReducer.aa_permissions, {
          module_name: "My Trainings",
        });
        this.props.onChangeMyTrainingHOC(tmp.can_create, "can_create");
      }
    } else {
      this.props.onChangeMyTrainingHOC(true, "can_create");
    }
  };

  renderCardContent = () => {
    const {
      myExamData,
      myCurrentExam,
      selectedSection,
      myTrainingData,
      myTrainingCompletion,
      upcomingTraining,
      upcomingRegisteredTraining,
      can_create,
      getSSOToken,
      onLoadMyTraining,
      showConfirmationModal,
      onChangeMyTrainingHOC,
    } = this.props;
    return (
      <>
        {selectedSection === "My Trainings" && (
          <MyTrainingContent
            can_create={can_create}
            myTrainingData={myTrainingData}
            onLoadMyTraining={onLoadMyTraining}
            showConfirmationModal={showConfirmationModal}
            onChangeMyTrainingHOC={onChangeMyTrainingHOC}
            myTrainingCompletion={myTrainingCompletion}
            getUpcomingTraining={this.props.getUpcomingTraining}
            getMyTraining={this.props.getMyTraining}
            getUpcomingRegisteredTraining={
              this.props.getUpcomingRegisteredTraining
            }
            upcomingRegisteredTraining={upcomingRegisteredTraining}
            upcomingTraining={upcomingTraining}
          />
        )}
        {selectedSection !== "My Trainings" && (
          <>
            {!_.isEmpty(myCurrentExam) && (
              <div className="at-form__content">
                <h3 className="fs-4 fw-600">{myCurrentExam.exam_title}</h3>
                <CustomButton
                  className={"btn-new btn-new--primary mt-2"}
                  children={
                    <div className="d-flex g-2">
                      <AtlasIcon
                        svgHref={"atlas-edit-2"}
                        style={{ width: 20, height: 20, fill: "white" }}
                      />
                      Open Exam Link
                    </div>
                  }
                  onClick={() => getSSOToken(myCurrentExam.exam_link)}
                />
              </div>
            )}
            <AtlasSimpleTable
              headerData={ColumnData}
              rowData={myExamData || []}
              renderTableTitle={() => (
                <div className="training-table__header">
                  <div className="at-badge-icon-sm at-badge-icon-warning mr-2">
                    <AtlasIcon svgHref={"atlas-edit"} />
                  </div>
                  <h4 className={"fs-4 fw-600"}>Exam Result</h4>
                </div>
              )}
              renderNoData={() => (
                <>
                  <div className="at-badge-icon-lg at-badge-icon-light-gray my-3 mx-auto">
                    <AtlasIcon svgHref={"atlas-edit"} />
                  </div>
                  <p style={{ marginBottom: 8 }}>No Exam Result available</p>
                </>
              )}
            />
          </>
        )}
      </>
    );
  };

  render = () => {
    const {
      selectedSection,
      showBlockerTutorial,
      onChangeTokenHOC,
      onChangeMyTrainingHOC,
    } = this.props;
    return (
      <>
        <ModuleHeader title={"My Trainings"} atlasIcon={"atlas-book"} />
        <CustomCard
          cardContent={
            <>
              <CustomTabs
                sections={["My Trainings", "My Exams"]}
                selectedSection={selectedSection}
                onSelectSection={(val) =>
                  onChangeMyTrainingHOC(val, "selectedSection")
                }
              />
              {this.renderCardContent()}
            </>
          }
        />
        {showBlockerTutorial && (
          <BlockerTutorial
            onClose={() => onChangeTokenHOC(false, "showBlockerTutorial")}
          />
        )}
        {this.props.onLoadMyTraining && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  MyTrainingHOC,
  MyExamHOC,
  TokenHOC,
  VersionCheckHOC,
)(MyTraining);
