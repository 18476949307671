import {
  BEGIN_AJAX_CALL,
  AJAX_CALL_ERROR,
  AJAX_CALL_SUCCESS,
  BEGIN_AJAX_CALL_BG,
  AJAX_CALL_SUCCESS_BG,
  AJAX_CALL_ERROR_BG,
  BEGIN_AJAX_CALL_IMPERSONATE,
  AJAX_CALL_SUCCESS_IMPERSONATE,
  AJAX_CALL_ERROR_IMPERSONATE,
  BEGIN_AJAX_CALL_DRIVE,
  AJAX_CALL_SUCCESS_DRIVE,
  AJAX_CALL_ERROR_DRIVE,
  BEGIN_AJAX_CALL_TOKEN,
  AJAX_CALL_TOKEN_SUCCESS,
  AJAX_CALL_TOKEN_ERROR,
} from "actions/type";

let initialState = {
  ajaxCallProgress: 0,
  ajaxErrorMessage: "",
  ajaxSuccessMessage: "",
  ajaxError: false,
  ajaxSuccess: false,

  ajaxCallProgressBg: 0,
  ajaxErrorMessageBg: "",
  ajaxSuccessMessageBg: "",
  ajaxErrorBg: false,
  ajaxSuccessBg: false,

  ajaxCallProgressImpersonate: 0,
  ajaxErrorMessageImpersonate: "",
  ajaxSuccessMessageImpersonate: "",
  ajaxErrorImpersonate: false,
  ajaxSuccessImpersonate: false,

  ajaxCallProgressDrive: 0,
  ajaxErrorMessageDrive: "",
  ajaxSuccessMessageDrive: "",
  ajaxErrorDrive: false,
  ajaxSuccessDrive: false,

  ajaxCallProgressToken: 0,
  ajaxErrorToken: false,
  ajaxSuccessToken: false,
};

export default function ajaxStatusReducer(state = initialState, action) {
  switch (action.type) {
    case BEGIN_AJAX_CALL:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress + 1,
        ajaxError: false,
        ajaxSuccess: false,
      };

    case AJAX_CALL_ERROR:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress - 1,
        ajaxErrorMessage: action.payload,
        ajaxError: true,
        ajaxSuccess: false,
      };

    case AJAX_CALL_SUCCESS:
      return {
        ...state,
        ajaxCallProgress: state.ajaxCallProgress - 1,
        ajaxSuccessMessage: action.payload,
        ajaxError: false,
        ajaxSuccess: true,
      };

    case BEGIN_AJAX_CALL_BG:
      return {
        ...state,
        ajaxCallProgressBg: state.ajaxCallProgressBg + 1,
        ajaxErrorBg: false,
        ajaxSuccessBg: false,
      };

    case AJAX_CALL_ERROR_BG:
      return {
        ...state,
        ajaxCallProgressBg: state.ajaxCallProgressBg - 1,
        ajaxErrorMessageBg: action.payload,
        ajaxErrorBg: true,
        ajaxSuccessBg: false,
      };

    case AJAX_CALL_SUCCESS_BG:
      return {
        ...state,
        ajaxCallProgressBg: state.ajaxCallProgressBg - 1,
        ajaxSuccessMessageBg: action.payload,
        ajaxErrorBg: false,
        ajaxSuccessBg: true,
      };

    case BEGIN_AJAX_CALL_IMPERSONATE:
      return {
        ...state,
        ajaxCallProgressImpersonate: state.ajaxCallProgressImpersonate + 1,
        ajaxErrorImpersonate: false,
        ajaxSuccessImpersonate: false,
      };

    case AJAX_CALL_SUCCESS_IMPERSONATE:
      return {
        ...state,
        ajaxCallProgressImpersonate: state.ajaxCallProgressImpersonate - 1,
        ajaxSuccessMessageImpersonate: action.payload,
        ajaxErrorImpersonate: false,
        ajaxSuccessImpersonate: true,
      };

    case AJAX_CALL_ERROR_IMPERSONATE:
      return {
        ...state,
        ajaxCallProgressImpersonate: state.ajaxCallProgressImpersonate - 1,
        ajaxErrorMessageImpersonate: action.payload,
        ajaxErrorImpersonate: true,
        ajaxSuccessImpersonate: false,
      };

    case BEGIN_AJAX_CALL_DRIVE:
      return {
        ...state,
        ajaxCallProgressDrive: state.ajaxCallProgressDrive + 1,
        ajaxErrorDrive: false,
        ajaxSuccessDrive: false,
      };

    case AJAX_CALL_SUCCESS_DRIVE:
      return {
        ...state,
        ajaxCallProgressDrive: state.ajaxCallProgressDrive - 1,
        ajaxSuccessMessageDrive: action.payload,
        ajaxErrorDrive: false,
        ajaxSuccessDrive: true,
      };

    case AJAX_CALL_ERROR_DRIVE:
      return {
        ...state,
        ajaxCallProgressDrive: state.ajaxCallProgressDrive - 1,
        ajaxErrorMessageDrive: action.payload,
        ajaxErrorDrive: true,
        ajaxSuccessDrive: false,
      };

    case BEGIN_AJAX_CALL_TOKEN:
      return {
        ...state,
        ajaxCallProgressToken: state.ajaxCallProgressToken + 1,
        ajaxSuccessToken: false,
        ajaxErrorToken: false,
      };

    case AJAX_CALL_TOKEN_SUCCESS:
      return {
        ...state,
        ajaxCallProgressToken: state.ajaxCallProgressToken - 1,
        ajaxSuccessToken: true,
        ajaxErrorToken: false,
      };

    case AJAX_CALL_TOKEN_ERROR:
      return {
        ...state,
        ajaxCallProgressToken: state.ajaxCallProgressToken - 1,
        ajaxSuccessToken: false,
        ajaxErrorToken: true,
      };

    default:
      return state;
  }
}
