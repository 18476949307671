import React, { Component } from "react";
import { connect } from "react-redux";

import { Put } from "utils/axios";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";

import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = { loading: false };

    load = (param) => this.setState({ loading: param });

    onChangeProfileHOC = (val, context) => this.setState({ [context]: val });

    updateCurrentUserProfile = (params) => {
      let user = new FormData();
      if (params && Object.keys(params).length > 0) {
        Object.keys(params) &&
          Object.keys(params).length > 0 &&
          Object.keys(params).map((key) => {
            if (typeof params[key] === "object") {
              Object.keys(params[key]) &&
                Object.keys(params[key]).length > 0 &&
                Object.keys(params[key]).map((childObj) => {
                  user.append(`${key}[${childObj}]`, params[key][childObj]);
                });
            } else {
              user.append(`${key}`, params[key]);
            }
          });
      }
      Put(
        `/profiles/me`,
        user,
        this.updateCurrentUserProfileSuccess,
        this.updateCurrentUserProfileError,
        this.load,
      );
    };
    updateCurrentUserProfileSuccess = () => {
      requestSuccess("Profile updated successfully!");
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
      this.props.onClickClose();
    };
    updateCurrentUserProfileError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          updateCurrentUserProfile={this.updateCurrentUserProfile}
          onChangeProfileHOC={this.onChangeProfileHOC}
          onLoadUpdateProfile={this.state.loading}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
  })(WithHOC);
};

export default HOC;
