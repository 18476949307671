import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";

import CustomTableHeader from "./header";
import CustomTableContent from "./content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/table/index.scss";

const CustomTable = ({
  mode,
  emptyStateMessage,
  emptyStateDescription,
  emptyStateIcon,
  className,
  rowData: initialRowData,
  columns,
  actionColumnContent,
  renderExtraContent,
}) => {
  const [rowData, setRowData] = useState([]);
  const [headerRearrangeData, setHeaderRearrangeData] = useState({});

  useEffect(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  const onClickRearrangeData = useCallback(
    (val) => {
      Promise.all([setHeaderRearrangeData(val)]).then(() => {
        if (val.columnValue) {
          let tempOrder = _.orderBy(
            rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          );

          setRowData(tempOrder);
        }
      });
    },
    [rowData],
  );

  const renderTableContent = () => {
    return rowData && rowData.length > 0 ? (
      rowData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          columns={columns}
          index={index}
          headerRearrangeData={headerRearrangeData}
          actionColumnContent={actionColumnContent}
          rowData={rowItem}
        />
      ))
    ) : (
      <EmptyState
        title={emptyStateMessage || `No data found`}
        description={emptyStateDescription || ""}
        renderIcon={
          emptyStateIcon || <AtlasIcon svgHref="atlas-document-text" />
        }
      />
    );
  };

  return (
    <div className={`mb-3 ${className}`}>
      {renderExtraContent && renderExtraContent()}
      <div className="at-table at-table--set">
        <CustomTableHeader
          mode={mode}
          onClickRearrangeData={onClickRearrangeData}
          columns={columns}
          rowData={rowData}
          actionColumnContent={actionColumnContent}
        />
        {renderTableContent()}
      </div>
    </div>
  );
};

export default CustomTable;