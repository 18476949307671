import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { EventSearchParams } from "../assets";
import { TrainingTypes } from "dictionary/eventTrainingType";

const AudienceData = [
  { id: 1, name: "Audience" },
  { id: 2, name: "Buyer" },
  { id: 3, name: "Other" },
];

const EventStatus = [
  { id: 1, name: "Draft" },
  { id: 2, name: "Published" },
  { id: 3, name: "Cancelled" },
];

const EventType = [
  { id: 1, name: "Event" },
  { id: 2, name: "Training" },
];

const EventsHOC = (WrappedComponent) => {
  class EventsWrappedComponent extends Component {
    state = {
      loading: false,
      showCreateEventDialog: false,
      showEditEventDialog: false,
      showExportConfirmation: false,
      eventID: "",

      eventQr: "",
      eventList: [],
      eventPages: null,
      selectedEvent: {
        type_id: 1,
        reference_id: null,
        audience: "1",
        total_people: 0,
        date: "",
        event: "",
        description: "",
        entry_pass: "",
        price: "",
        startDate: "",
        endDate: "",
        location: "",
        location_url: "",
        latitude: "",
        longitude: "",
        photo: "",
        photo_file_name: "",
        speaker: "",
        host_info: "",
        need_approval: false,
        is_limited_seat: false,
        training_type_id: null,
        clock_in_required: true,
        branch_region_id: 0,
        is_payment_needed: false,
        enable_bank_in: false,
        enable_payment_gateway: false,
        is_eligible_for_ims: false,
        ims_points: 0,
        minimum_perc_of_attendance: 0,
        status_id: 0,
        open_for_registration: false,
      },
      attachments: {},
      ticketTypeList: [],
      searchParams: EventSearchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeEventHOC = (val, context) => this.setState({ [context]: val });

    onChangeFileEdit = (fileItems, stateName) => {
      if (fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        try {
          reader.onload = (e) => {
            let tmp = this.state.selectedEvent;
            tmp[stateName] = e.target.result;
            tmp["photo_file_name"] = fileItems[0].file.name;
            this.setState({ selectedEvent: tmp });
          };
          reader.readAsDataURL(fileItems[0].file);
        } catch (e) {
          console.log(e);
        }
      }
    };

    onChangeFieldEdit = (val, stateName) => {
      let tmp = this.state.selectedEvent;
      tmp[stateName] = val;
      return this.setState({ selectedEvent: tmp });
    };

    getEventLists = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/events?${search}page=${page}`,
        this.getEventListsSuccess,
        this.getEventListsError,
        this.load,
      );
    };
    getEventListsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpAudience = _.find(AudienceData, { id: item.audience_id });
          let tmpStatus = _.find(EventStatus, { id: item.status_id });
          let tmpType = _.find(EventType, { id: item.type_id });
          let tempEndDateTime = Moment(item.end_date_time).format(
            "DD MMM YYYY",
          );
          let tempStartDateTime = Moment(item.start_date_time).format(
            "DD MMM YYYY",
          );
          let data = {
            ...item,
            start_date_time:
              tempStartDateTime === "Invalid date" ? "N/A" : tempStartDateTime,
            end_date_time:
              tempEndDateTime === "Invalid date" ? "N/A" : tempEndDateTime,
            status: tmpStatus ? tmpStatus.name : "N/A",
            audience: tmpAudience ? tmpAudience.name : "N/A",
            type: tmpType ? tmpType.name : "N/A",
            pending: (item.registration_count - item.event_attendee).toString(),
            registration_count: item.registration_count.toString(),
            event_attendee: item.event_attendee.toString(),
          };
          tmp.push(data);
        });
      this.setState({
        eventList: {
          data: tmp,
          meta: payload.meta,
        },
        eventPages: tmpTotalPages,
      });
    };
    getEventListsError = (error) => requestError(error);

    getTicketTypeList = (event_id) => {
      Get(
        `/admin/events/${event_id}/ticket_types`,
        this.getTicketTypeListSuccess,
        this.getTicketTypeListError,
        this.load,
      );
    };
    getTicketTypeListSuccess = (payload) =>
      this.setState({ ticketTypeList: payload });
    getTicketTypeListError = (error) => requestError(error);

    getSelectedEvent = (id, modalType) =>
      Get(
        `/admin/events/${id}`,
        (payload) => this.getSelectedEventSuccess(payload, modalType, id),
        this.getSelectedEventError,
        this.load,
      );
    getSelectedEventSuccess = (payload, modalType, id) => {
      this.getAttachments(id);
      this.getTicketTypeList(id);
      let tmpAudience = _.find(AudienceData, { id: payload.audience_id });
      let tmpStatus = _.find(EventStatus, { id: payload.status_id });
      let tmpType = _.find(EventType, { id: payload.type_id });
      let tmpTrainingType = _.find(TrainingTypes, {
        id: payload.training_type_id,
      });
      let tempEndDateTime = Moment(payload.end_date_time).format(
        "YYYY-MM-DDTHH:mm",
      );
      let tempStartDateTime = Moment(payload.start_date_time).format(
        "YYYY-MM-DDTHH:mm",
      );

      let tmp = {
        ...payload,
        start_date_time:
          tempStartDateTime === "Invalid date" ? "N/A" : tempStartDateTime,
        end_date_time:
          tempEndDateTime === "Invalid date" ? "N/A" : tempEndDateTime,
        audience: tmpAudience ? tmpAudience.name : "N/A",
        status: tmpStatus ? tmpStatus.name : "N/A",
        type: tmpType ? tmpType.name : "N/A",
        training_type: tmpTrainingType ? tmpTrainingType.name : "N/A",
        pending: payload.registration_count - payload.event_attendee,
        export_to_unit_admin: false,
      };
      this.setState({
        selectedEvent: tmp,
        eventID: payload.id,
        eventQr: "",
        [modalType]: true,
      });
    };
    getSelectedEventError = (error) => requestError(error);

    getAttachments = (event_id) =>
      Get(
        `/admin/events/${event_id}/attachments`,
        this.getAttachmentsSuccess,
        this.getAttachmentsError,
        this.load,
      );
    getAttachmentsSuccess = (payload) =>
      this.setState({ attachments: payload });
    getAttachmentsError = (error) => requestError(error);

    exportToUnitAdmin = (event_id) =>
      Post(
        `/admin/events/${event_id}/export`,
        {},
        this.exportToUnitAdminSuccess,
        this.exportToUnitAdminError,
        this.load,
      );
    exportToUnitAdminSuccess = () => {
      const { currentPage, searchParams } =
        this.props.this.props.data.lastViewReducer.lastView;

      this.getEventLists(currentPage, searchParams);
      this.setState({ showExportConfirmation: false });
      requestSuccess("Event is exported successfully to unit admin.");
    };
    exportToUnitAdminError = (error) => requestError(error);

    getEventQrCode = (id) =>
      Get(
        `/admin/events/${id}/qr_code`,
        this.getEventQrCodeSuccess,
        this.getEventQrCodeError,
        this.load,
      );
    getEventQrCodeSuccess = (payload) =>
      this.setState({ eventQr: payload.qr_image });
    getEventQrCodeError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            eventQr={this.state.eventQr}
            eventPages={this.state.eventPages}
            searchParams={this.state.searchParams}
            onLoadEventTraining={this.state.loading}
            showCreateEventDialog={this.state.showCreateEventDialog}
            showEditEventDialog={this.state.showEditEventDialog}
            showExportConfirmation={this.state.showExportConfirmation}
            eventList={this.state.eventList}
            selectedEvent={this.state.selectedEvent}
            attachments={this.state.attachments}
            ticketTypeList={this.state.ticketTypeList}
            eventID={this.state.eventID}
            getEventLists={this.getEventLists}
            getEventQrCode={this.getEventQrCode}
            getAttachments={this.getAttachments}
            getTicketTypeList={this.getTicketTypeList}
            getSelectedEvent={this.getSelectedEvent}
            exportToUnitAdmin={this.exportToUnitAdmin}
            onChangeEventHOC={this.onChangeEventHOC}
            onChangeFieldEdit={this.onChangeFieldEdit}
            onChangeFileEdit={this.onChangeFileEdit}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(EventsWrappedComponent);
};

export default EventsHOC;
