import React, { Component } from "react";
import { compose } from "redux";

import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";

import VersionCheckHOC from "actions/versionCheck";
import PasswordHOC from "./actions/password";

import { initGA } from "utils/ga";

class ChangePassword extends Component {
  componentDidMount = () => initGA("/dashboard/profile/change-password");

  onChangeNewPassword = (val) => {
    Promise.all([this.props.onChangePWHOC(val, "password")]).then(() => {
      if (!val) {
        return this.props.onChangePWHOC(
          val ? "" : "Please fill in the password field.",
          "passwordError"
        );
      }
      this.props.onChangePWHOC(
        val === this.props.password_confirmation
          ? ""
          : "Password and confirm password must be the same.",
        "passwordConfirmationError"
      );
      this.props.onChangePWHOC(
        val === this.props.password_confirmation
          ? ""
          : "Password and confirm password must be the same.",
        "passwordError"
      );
    });
  };

  onChangeConfirmNewPassword = (val) => {
    Promise.all([this.props.onChangePWHOC(val, "password_confirmation")]).then(
      () => {
        if (!val) {
          return this.props.onChangePWHOC(
            val ? "" : "Please fill in the password field.",
            "passwordConfirmationError"
          );
        }
        this.props.onChangePWHOC(
          val === this.props.password
            ? ""
            : "Password and confirm password must be the same.",
          "passwordConfirmationError"
        );
        this.props.onChangePWHOC(
          val === this.props.password
            ? ""
            : "Password and confirm password must be the same.",
          "passwordError"
        );
      }
    );
  };

  onClickUpdatePassword() {
    if (
      this.props.password !== "" &&
      this.props.password_confirmation !== "" &&
      !this.props.passwordError &&
      !this.props.passwordConfirmationError
    ) {
      this.props.onSubmitPassword({
        password: this.props.password,
        password_confirmation: this.props.password_confirmation,
      });
    }
  }

  render = () => {
    return (
      <div className="at-form__content at-profile__change-password">
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          <section className="grid-full-col">
            <h2 style={{ fontSize: 18, fontWeight: 600, color: "#111827" }}>
              Change Password
            </h2>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              New Password
            </h2>
            <CustomFormInput
              type="password"
              value={this.props.password}
              onChangeValue={(val) => this.onChangeNewPassword(val)}
              inputError={this.props.passwordError}
              errorMessagePlain
              placeholder={"Enter your new password"}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Confirm New Password
            </h2>
            <CustomFormInput
              type="password"
              value={this.props.password_confirmation}
              onChangeValue={(val) => this.onChangeConfirmNewPassword(val)}
              required={true}
              inputError={this.props.passwordConfirmationError}
              errorMessagePlain
              placeholder={"Confirm your new password"}
            />
          </section>
          <section className="grid-full-col">
            <button
              type={"button"}
              disabled={
                !this.props.password ||
                !this.props.password_confirmation ||
                this.props.passwordError ||
                this.props.passwordConfirmationError
              }
              className="btn-new btn-new--success"
              onClick={() => this.onClickUpdatePassword()}
            >
              Update Password
            </button>
          </section>
        </form>
        {this.props.onLoadPW && <LoadingModal />}
      </div>
    );
  };
}

export default compose(PasswordHOC, VersionCheckHOC)(ChangePassword);
