import React, { Component } from "react";
import { Typography, Card, CardContent } from "@material-ui/core";
import _ from "lodash";
import { compose } from "redux";

import AlertBox from "components/AlertBox";
import AtlasFormInput from "components/Input/formInput";
import AtlasCobrokerCard from "../components/cobrokerCard";
import LoadingModal from "components/LoadingModal";
import permissionsChecker from "utils/permissionsChecker";
import DeleteConfirmationModal from "components/Modal/delete";

import "stylesheets/containers/subsales-claims/index.scss";
import defaultAvatar from "assets/images/av-male.jpg";

import ContactTypeHOC from "../../../actions/contactType";
import AgentHOC from "../../../actions/agent";

class InternalAgent extends Component {
  state = {
    searchInternalAgentValue: "",
    searchResult: [],
    inputError: "",
    showConfirmationModal: false,
    toRemoveItem: {},
  };

  componentDidMount = () =>
    this.props.getAssignedContactType(this.props.selected_claim.id, 3);

  componentDidUpdate = (pp) => {
    if (this.props.internal_cobrokers.length !== pp.internal_cobrokers.length) {
      this.setState({ searchInternalAgentValue: "" });
      this.props.updateInternalAgentCount(this.props.internal_cobrokers.length);
  
      this.setState({ inputError: "" });
    }
  };

  onChangeValue = (context, value) =>
    this.setState({
      [context]: value,
      inputError: "",
      searchResult: [],
    });

  search = () => {
    let tmp = _.find(this.props.internal_cobrokers, {
      co_broker: {
        user: {
          mobile_contact_number: this.state.searchInternalAgentValue,
        },
      },
    });
    if (tmp) {
      this.setState({ inputError: "User is already assigned." });
    } else {
      this.props.searchAgent(this.state.searchInternalAgentValue);
    }
  };

  assign = (data) => {
    let tmp = {
      co_broker: {
        commission_type_id: 0,
        commission: 0,
        extra_collection_amount: 0,
        co_broker_id: data.id,
        is_internal: true,
      },
    };
    this.props.onChangeAgentHOC({}, "selectedAgent");
    return this.props.createEntity(tmp, this.props.selected_claim.id, 3);
  };

  onClickSaveCobrokerCard = (data, updatedValues) => {
    let tmp = {
      co_broker: {
        ...data,
        ...updatedValues,
      },
    };
    return this.props.updateSelectedEntity(
      tmp,
      this.props.selected_claim.id,
      3,
      data.id,
    );
  };

  onClickRemoveCobrokerCard = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        this.state.toRemoveItem.type_id,
        this.state.toRemoveItem.id,
      );
    });

  closeConfirmationModal = () =>
    this.setState({ showConfirmationModal: false });

  render = () => {
    const { internal_cobrokers, agencies, selected_claim, selectedAgent } =
      this.props;
    const { can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    return (
      <div className="at-form__content pb-0">
        <AlertBox
          mode={"info"}
          containerClassName="mb-3"
          description={`Search for internal agent (If you have cobroke with IQI agent, add the agent by searching his mobile number)`}
        />
        <div className="row grid_gap-2">
          <div className="col-lg-6 mb-0">
            <h2 className="at-form-input__title">Agent Mobile Number</h2>
            <div className="d-flex w-100">
              <AtlasFormInput
                inputError={this.state.inputError}
                containerClass={"w-100 mb-0"}
                type={"text"}
                value={`${this.state.searchInternalAgentValue}`}
                onChangeValue={(val) =>
                  this.onChangeValue("searchInternalAgentValue", val)
                }
                placeholder={"e.g. 60123456789"}
              />
              <button
                disabled={_.isEmpty(this.state.searchInternalAgentValue)}
                className="btn-new btn-new--secondary ml-2 "
                style={{ maxHeight: "40px" }}
                onClick={() => this.search()}
              >
                Search
              </button>
            </div>
          </div>
          {selectedAgent && !_.isEmpty(selectedAgent) && (
            <div className="col-md-6 mb-0">
              <div className="at-form__content cobroke-agent">
                <img
                  alt="agent info"
                  src={
                    (selectedAgent && selectedAgent.avatar_url) ||
                    defaultAvatar
                  }
                  onError={(e) => {
                    if (e.target) {
                      e.target.src = defaultAvatar;
                    }
                  }}
                />
                <div className="d-flex flex-column g-2">
                  <p className="fs-3 fw-600">{selectedAgent.full_name || "N/A"}</p>
                  <p className="fs-2">{selectedAgent.email || "N/A"}</p>
                  <p className="fs-2">{selectedAgent.mobile_contact_number || "N/A"}</p>
                  <p className="fs-2">{selectedAgent.team_name || "N/A"}</p>
                  <button
                    style={{ marginTop: "auto" }}
                    disabled={selected_claim.status_id > 1 || !can_create}
                    className="btn-new btn-new--primary mt-10"
                    onClick={() => this.assign(selectedAgent)}>
                    Assign as Cobroker
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-3">
          {internal_cobrokers && internal_cobrokers.length > 0 ? (
            internal_cobrokers.map((item, index) => {
              return (
                <div key={index} className="col-lg-6 col-xl-4">
                  <AtlasCobrokerCard
                    key={item.id}
                    data={item}
                    agencies={agencies}
                    onClickRemoveCobrokerCard={this.onClickRemoveCobrokerCard}
                    onClickSaveCobrokerCard={this.onClickSaveCobrokerCard}
                    disableEdit={false}
                  />
                </div>
              );
            })
          ) : (
            <div></div>
          )}
          {this.props.onLoadAgent ||
            (this.props.onLoadContactType && <LoadingModal />)}
        </div>
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={this.closeConfirmationModal}
          message={`You will not able to recover the data`}
        />
      </div>
    );
  };
}

export default compose(ContactTypeHOC, AgentHOC)(InternalAgent);
