import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import { Post, Get, GetFile, Delete, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const partyDictionary = [
  {
    id: 3,
    subsalesTitle: "Vendor",
    subrentTitle: "Landlord",
  },
  {
    id: 4,
    subsalesTitle: "Vendor's Representative",
    subrentTitle: "Landlord's Representative",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedSigningTab: "e-Signing",
      selectedContact: null,

      showDownloadFormDialog: false,
      showStartProcessModal: false,
      showEditPartyModal: false,
      showDeletePartyModal: false,
      showSigningHistory: false,
      showAssignMyselfModal: false,
      showCreateContactModal: false,
      showAssignContactModal: false,
      showAssignInternalModal: false,
      showAssignSuperiorModal: false,
      showResetConfirmationModal: false,
      showCompleteConfirmationModal: false,
      showSubmitClaimConfirmationModal: false,

      signingStatus: null,
      signingStatusId: `0`,

      partyData: [],
      eProcessPartyData: [],
      eProcessInitialPartyData: [],
      eProcessContentId: 1,

      signingOption: "0",
    };

    load = (param) => this.setState({ loading: param });

    onChangePartyHOC = (val, context) => this.setState({ [context]: val });

    getParty = (id) =>
      Get(
        `/forms/form_contacts?type_id=3&form_id=${id}`,
        this.getPartySuccess,
        this.getPartyError,
        this.load,
      );
    getPartySuccess = (payload) => {
      let tempSellerData = [];
      let tempSellerAgentData = [];
      let tempOriginalData = [];

      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let temp = _.find(partyDictionary, { id: item.party_id });
          let tempData = {
            ...item,
            participants_name:
              item.signee_type_id === 1
                ? [item.company_name, `(${item.company_registration_no})`, item.full_name]
                : item.full_name,
            draggableId: item.id.toString(),
            title:
              this.props.selected_appointment_letter.type_id === 1
                ? temp.subsalesTitle
                : temp.subrentTitle,
            form_contact_id: item.id,
            copies_info: item.copies_info ?? { identity_copy_url: null },
            date_of_birth: Moment(item.date_of_birth, "YYYY-MM-DD").isValid()
              ? Moment(item.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
              : item.date_of_birth
          };
          tempOriginalData.push(tempData);
          switch (item.party_id) {
            case 3:
              tempSellerData.push(tempData);
              break;
            case 4:
              tempSellerAgentData.push(tempData);
          }
        });

      let tempParty = [
        {
          id: 3,
          data: tempSellerData,
        },
        {
          id: 4,
          data: tempSellerAgentData,
        },
      ];

      this.setState({
        partyData: tempParty,
        eProcessPartyData: tempOriginalData,
        eProcessInitialPartyData: tempOriginalData,
      });
    };
    getPartyError = (error) => requestError(error);

    createParty = (dataToSubmit, id) =>
      Post(
        `/forms/form_contacts?type_id=3&form_id=${id}`,
        dataToSubmit,
        this.createPartySuccess,
        this.createPartyError,
        this.load,
      );
    createPartySuccess = (payload) => {
      this.getParty(payload.form_id);
      requestSuccess("New contact created successfully.");
      this.props.getCurrentUserContactList()
      this.setState({ 
        showCreateContactModal: false, 
        showEditPartyModal: true,
        selectedContact: { 
          ...payload, 
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {}
        }
      });
    };
    createPartyError = (error) => requestError(error);

    // delete party contact
    deletePartyContact = (formId, contactId) =>
      Delete(
        `/forms/form_contacts/${contactId}?type_id=3&form_id=${formId}`,
        () => this.deletePartyContactSuccess(formId),
        this.deletePartyContactError,
        this.load,
      );
    deletePartyContactSuccess = (id) => {
      this.getParty(id);
      requestSuccess("Record deleted successfully.");
      this.setState({ showDeletePartyModal: false });
    };
    deletePartyContactError = (error) => requestError(error);

    updatePartyContact = (dataToSubmit) =>
      Put(
        `/forms/form_contacts/${dataToSubmit.id}?type_id=3`,
        dataToSubmit,
        this.updatePartyContactSuccess,
        this.updatePartyContactError,
        this.load,
      );
    updatePartyContactSuccess = (payload) => {
      requestSuccess("Contact's information is updated successfully.");
      this.getParty(payload.form_id);
    };
    updatePartyContactError = (error) => requestError(error);

    assignContact = (dataToSubmit, id, title) =>
      Post(
        `/forms/form_contacts/assign_contact?type_id=3&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignContactSuccess(payload, title),
        this.assignContactError,
        this.load,
      );
    assignContactSuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully.`);
      this.setState({ 
        showAssignContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: true
      });
      this.getParty(payload.form_id);
      this.props.getAppointmentLetter(payload.form_id)
    };
    assignContactError = (error) => requestError(error);

    initiateSigningProcess = (dataToSubmit) =>
      Post(
        `/e_processes/start`,
        dataToSubmit,
        this.initiateSigningProcessSuccess,
        this.initiateSigningProcessError,
        this.load,
      );
    initiateSigningProcessSuccess = () => {
      this.setState({
        eProcessContentId: 2,
        showStartProcessModal: false,
      });
      requestSuccess("E-process has been started successfully.");
      this.props.getAppointmentLetter(this.props.selected_appointment_letter.id)
      this.getParty(this.props.selected_appointment_letter.id);
      this.getSigningStatus(this.props.selected_appointment_letter.id);
    };
    initiateSigningProcessError = (error) => requestError(error);

    getSigningStatus = (id) =>
      Get(
        `/e_processes/check_status?form_type=3&form_id=${id}`,
        this.getSigningStatusSuccess,
        this.getSigningStatusError,
        this.load,
      );
    getSigningStatusSuccess = (payload) =>
      this.setState({
        signingStatus: payload.status,
        signingStatusId: `${payload.status_id}`,
        eProcessContentId: payload.status_id === 0 ? 1 : 2,
      });
    getSigningStatusError = (error) => requestError(error);

    resetSigning = (dataToSubmit) =>
      Put(
        `/e_processes/reset_e_process`,
        dataToSubmit,
        (payload) => this.resetSigningSuccess(payload, dataToSubmit.form_id),
        this.resetSigningError,
        this.load,
      );
    resetSigningSuccess = (payload, id) => {
      this.getParty(id);
      this.getSigningStatus(id);
      this.props.getAppointmentLetter(id)

      this.setState({
        showResetConfirmationModal: false,
      });
      requestSuccess(payload.message);
    };
    resetSigningError = (error) => requestError(error);

    completeEsigning = (dataToSubmit) =>
      Put(
        `/e_processes/complete`,
        dataToSubmit,
        (payload) =>
          this.completeEsigningSuccess(payload, dataToSubmit.form_id),
        this.completeEsigningError,
        this.load,
      );
    completeEsigningSuccess = (payload, form_id) => {
      this.setState({ showCompleteConfirmationModal: false });
      this.getParty(form_id);
      this.getSigningStatus(form_id);
      this.props.getAppointmentLetter(form_id)

      requestSuccess(payload.message);
    };
    completeEsigningError = (error) => requestError(error);

    downloadCompletedForm = (formId, filename) =>
      GetFile(
        `/e_processes/download?form_id=${formId}&form_type=3`,
        filename,
        () => {},
        this.downloadCompletedFormError,
        this.load,
      );
    downloadCompletedFormError = (error) => requestError(error);

    validateForm = (dataToSubmit) =>
      Post(
        `/e_processes/validate`,
        dataToSubmit,
        this.validateFormSuccess,
        this.validateFormError,
        this.load,
      );
    validateFormSuccess = () => this.setState({ showStartProcessModal: true });
    validateFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            signingStatus={this.state.signingStatus}
            signingStatusId={this.state.signingStatusId}
            signingOption={this.state.signingOption}
            onLoadParty={this.state.loading}
            partyData={this.state.partyData}
            eProcessContentId={this.state.eProcessContentId}
            showSigningHistory={this.state.showSigningHistory}
            eProcessPartyData={this.state.eProcessPartyData}
            eProcessInitialPartyData={this.state.eProcessInitialPartyData}
            selectedContact={this.state.selectedContact}
            selectedSigningTab={this.state.selectedSigningTab}
            showDownloadFormDialog={this.state.showDownloadFormDialog}
            showStartProcessModal={this.state.showStartProcessModal}
            showDeletePartyModal={this.state.showDeletePartyModal}
            showEditPartyModal={this.state.showEditPartyModal}
            showAssignMyselfModal={this.state.showAssignMyselfModal}
            showCreateContactModal={this.state.showCreateContactModal}
            showAssignContactModal={this.state.showAssignContactModal}
            showAssignInternalModal={this.state.showAssignInternalModal}
            showAssignSuperiorModal={this.state.showAssignSuperiorModal}
            showResetConfirmationModal={this.state.showResetConfirmationModal}
            showCompleteConfirmationModal={
              this.state.showCompleteConfirmationModal
            }
            getParty={this.getParty}
            createParty={this.createParty}
            resetSigning={this.resetSigning}
            validateForm={this.validateForm}
            assignContact={this.assignContact}
            completeEsigning={this.completeEsigning}
            getSigningStatus={this.getSigningStatus}
            deletePartyContact={this.deletePartyContact}
            updatePartyContact={this.updatePartyContact}
            downloadCompletedForm={this.downloadCompletedForm}
            initiateSigningProcess={this.initiateSigningProcess}
            onChangePartyHOC={this.onChangePartyHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
