import { useEffect } from 'react';
import { IoMdAdd } from 'react-icons/io';

import AtlasCard from 'components/Card';
import LoadingModal from 'components/LoadingModal';
import DeleteConfirmationModal from 'components/Modal/delete';
import ModuleHeader from 'components/ModuleHeader';
import PaginatedTable from 'components/NewPaginatedTable';
import VideoPlayer from 'components/VideoPlayer';
import CreateVideoTemplate from './Create';
import UpdateVideoTemplate from './Update';

import withVideoTemplates from './actions';

import permissionsChecker from 'utils/permissionsChecker';
import { columnData } from './assets';

import './index.scss';

const VideoTemplates = props => {
  const {
    onLoadVideoTemplates,
    data,
    videoTemplates,
    videoTemplatePages,
    selectedVideoTemplate,
    selectedId,
    searchParams,
    showCreateVideoTemplate,
    showUpdateVideoTemplate,
    showDeleteVideoTemplate,
    showVideoPlayer,

    onChangeVideoTemplatesHOC,
    getCountryDictionary,
    getVideoTemplates,
    getSelectedVideoTemplate,
    deleteVideoTemplate
  } = props;

  useEffect(() => {
    getCountryDictionary();
    getVideoTemplates();
  }, []);

  const { can_create, can_update, can_destroy } = permissionsChecker(
    'Video Creator',
    data
  );

  const renderCardContent = () => {
    return (
      <PaginatedTable
        tableTitle=''
        rowData={videoTemplates.data}
        meta={videoTemplates.meta}
        columns={columnData({ onChangeVideoTemplatesHOC })}
        showCreateButton={false}
        searchParams={searchParams}
        onChangeSearchParams={val =>
          onChangeVideoTemplatesHOC('searchParams', val)
        }
        getListAPI={getVideoTemplates}
        totalPages={videoTemplatePages}
        actionColumnContent={[
          {
            name: can_update ? 'edit' : 'view',
            onClick: rowData => getSelectedVideoTemplate(rowData.id)
          },
          {
            name: 'delete',
            onShow: () => can_destroy,
            onClick: async rowData => {
              await onChangeVideoTemplatesHOC('selectedId', rowData.id);
              await onChangeVideoTemplatesHOC('showDeleteVideoTemplate', true);
            },
            disabled: rowData => rowData.version === 1
          }
        ]}
      />
    );
  };

  return (
    <>
      <ModuleHeader
        title='Video Templates'
        moduleIcon='icon-video-creator'
        actionButton={[
          {
            label: 'New Video Template',
            onShow: can_create,
            className: 'btn-new btn-new--primary',
            icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
            onClick: () =>
              onChangeVideoTemplatesHOC('showCreateVideoTemplate', true),
            hideTooltip: true
          }
        ]}
      />
      <AtlasCard cardContent={renderCardContent()} />
      {showCreateVideoTemplate && <CreateVideoTemplate {...props} />}
      {showUpdateVideoTemplate && (
        <UpdateVideoTemplate {...props} can_update={can_update} />
      )}
      {showVideoPlayer && (
        <VideoPlayer
          videoURL={selectedVideoTemplate.url}
          onClickClose={() =>
            onChangeVideoTemplatesHOC('showVideoPlayer', false)
          }
        />
      )}
      <DeleteConfirmationModal
        open={showDeleteVideoTemplate}
        loading={onLoadVideoTemplates}
        title='Delete video template'
        message='Are you sure you want to delete this video template?'
        positiveAction={() => deleteVideoTemplate(selectedId)}
        negativeAction={() =>
          onChangeVideoTemplatesHOC('showDeleteVideoTemplate', false)
        }
      />
      {onLoadVideoTemplates && <LoadingModal />}
    </>
  );
};

export default withVideoTemplates(VideoTemplates);
