import React, { useEffect } from "react";
import _ from "lodash";
import Moment from "moment";

import AtlasIcon from 'components/Icon/atlasIcon'
import LoadingModal from 'components/LoadingModal';
import ConfirmationModal from 'components/Modal/delete'
import AtPaginatedTable from 'components/NewPaginatedTable'
import CreateParkLicense from './Create'
import TransferModal from './transfer'
import AgentList from './agentList';

import ParkLicenseHOC from "./actions";

const columnData = [
  {
    header: "License Number",
    accessor: "licence_number",
    searchFlag: "licence_number",
  },
  {
    header: "Created At",
    searchFlag: "created_at",
    renderColumn: (rowData) => Moment(rowData.created_at).format("DD MMM YYYY"),
  },
];

const ParkedLicenseNo = ({
  branches,
  searchParams,
  attachLicense,
  licenseAgents,
  selectedBranch,
  onLoadParkLicense,
  selectedParkLicense,

  showCreateModal,
  showTransferModal,
  showAgentListModal,
  showDeleteConfirmation,

  getAllBranch,
  createLicense,
  viewLicenseAgent,
  deleteParkLicense,
  transferParkLicense,
  getBranchAttachLicense,
  onChangeParkLicenseHOC,
}) => {
  useEffect(() => {
    getAllBranch();
    getBranchAttachLicense(selectedBranch.id);
  }, []);

  const renderCheckBoxActions = () => (
    <div className='at-table__head-title'>
      <h4>License Records</h4>
      <button
        className={"btn-new btn-new--primary ml-2"}
        onClick={() => onChangeParkLicenseHOC(true, "showCreateModal")}
      >
        <AtlasIcon svgHref={"atlas-add-linear"} />
        New
      </button>
    </div>
  );

  return (
    <>
      <AtPaginatedTable
        rowData={attachLicense || []}
        meta={{}}
        enableSort={true}
        columns={columnData}
        searchParams={[]}
        renderCheckBoxActions={renderCheckBoxActions}
        actionColumnContent={[
          {
            name: "info",
            onClick: (rowData) =>
              viewLicenseAgent(selectedBranch.id, rowData.id),
          },
          {
            name: "transfer",
            onClick: (rowData) =>
              Promise.all([
                onChangeParkLicenseHOC(rowData, "selectedParkLicense"),
              ]).then(() => {
                onChangeParkLicenseHOC(true, "showTransferModal");
              }),
          },
          {
            name: "delete",
            color: "#F04438",
            onClick: (rowData) =>
              Promise.all([
                onChangeParkLicenseHOC(rowData, "selectedParkLicense"),
              ]).then(() => {
                onChangeParkLicenseHOC(true, "showDeleteConfirmation");
              }),
          },
        ]}
        totalPages={["1"]}
      />
      {showCreateModal && (
        <CreateParkLicense
          selectedBranch={selectedBranch}
          onLoadParkLicense={onLoadParkLicense}
          createLicense={createLicense}
          onChangeParkLicenseHOC={onChangeParkLicenseHOC}
        />
      )}
      {showTransferModal && (
        <TransferModal
          branches={branches}
          selectedBranch={selectedBranch}
          onLoadParkLicense={onLoadParkLicense}
          selectedParkLicense={selectedParkLicense}
          transferParkLicense={transferParkLicense}
          onChangeParkLicenseHOC={onChangeParkLicenseHOC}
        />
      )}
      {showAgentListModal && (
        <AgentList
          searchParams={searchParams}
          licenseAgents={licenseAgents}
          onLoadParkLicense={onLoadParkLicense}
          selectedParkLicense={selectedParkLicense}
          onChangeParkLicenseHOC={onChangeParkLicenseHOC}
        />
      )}
      <ConfirmationModal
        open={showDeleteConfirmation}
        message={
          "Are you sure you want to remove the license number from parking at the branch?"
        }
        title={""}
        showLoading={onLoadParkLicense}
        positiveAction={() =>
          deleteParkLicense(selectedBranch.id, selectedParkLicense.id)
        }
        negativeAction={() =>
          onChangeParkLicenseHOC(false, "showDeleteConfirmation")
        }
      />
      {onLoadParkLicense && <LoadingModal />}
    </>
  );
};

export default ParkLicenseHOC(ParkedLicenseNo);
