import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import DatePicker from "react-datepicker";

import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import AssignDialog from "./AssignDialog";
import AgentInfoContent from "./agentInfo";

import LeadUpdateHOC from "../actions/update";
import { priorityTypes } from "../assets";
import { AgentLeadType, LeadType, LeadStatus } from "dictionary/lead";

import "./styles.scss";

const leadInformationData = [
  { label: "Name", value: "buyer_name", icon: "atlas-profile-circle" },
  { label: "Email", value: "buyer_email", icon: "atlas-sms" },
  { label: "Phone Number", value: "buyer_phone_number", icon: "atlas-call" },
  { label: "Wechat", value: "buyer_wechat", icon: "atlas-wechat" },
];

const leadInformationData1 = [
  { label: "Current City", value: "current_city", icon: "atlas-location" },
  { label: "Current State", value: "current_state", icon: "atlas-signpost" },
  { label: "Current Country", value: "current_country", icon: "atlas-global" },
  { label: "Language", value: "language", icon: "atlas-language-circle" },
];
const leadInformationData2 = [
  { label: "Type", value: "type_id", icon: "atlas-flag" },
  { label: "Source", value: "lead_source", icon: "atlas-presentation-chart" },
  { label: "Inquiry Date", value: "inquiry_date", icon: "atlas-calendar" },
  { label: "Assigned Admin", value: "admin_name", icon: "atlas-user-octagon" },
  {
    label: "Assigned Agent",
    value: "agent_name",
    icon: "atlas-profile-circle",
  },
  { label: "Assigned Date", value: "assign_date", icon: "atlas-calendar-2" },
];
const leadInformationData3 = [
  {
    label: "Target City",
    value: "target_city",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Target State",
    value: "target_state",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Target Country",
    value: "target_country",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Property Category",
    value: "property_category",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Purchase Timing",
    value: "purchase_timing",
    type: "text",
    requiredFilled: false,
  },
  { label: "Budget", value: "budget", type: "text", requiredFilled: false },
  {
    label: "Motivation",
    value: "motivation",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Initial Comments",
    value: "initial_comments",
    grid: 12,
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Additional Comments",
    value: "additional_comments",
    grid: 12,
    type: "text",
    requiredFilled: false,
  },
];

const leadStatusData = [
  { label: "Status", value: "status_id", type: "select", grid: 4 },
  { label: "Close Date", value: "close_date", type: "date", grid: 4 },
  { label: "", value: "", type: "", grid: 4 },
  {
    label: "Priority",
    value: "priority_id",
    type: "radio",
    options: priorityTypes,
    grid: 12,
  },
  { label: "Remarks", value: "remark", type: "textarea", grid: 12 },
];

const UserTypes = [
  { value: "admin", label: "Admin", match_key: "admin_name" },
  { value: "agent", label: "Agent", match_key: "agent_name" },
];

class Edit extends Component {
  state = {
    leadsDetails: {},
    showAgentInfo: false,
  };

  componentDidMount = () => {
    this.setState({ leadsDetails: this.props.selectedLead });

    window.addEventListener("click", (e) => {
      if (e.target.id !== "agent-info") {
        this.setState({ showAgentInfo: false });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedLead !== prevProps.selectedLead) {
      this.setState({ leadsDetails: this.props.selectedLead });
    }
  };

  onBindData = (val, context) => {
    let temp = { ...this.state.leadsDetails };
    temp[context] = val;
    this.setState({ leadsDetails: temp });
  };

  renderInputField = (dataField) => {
    const { type_id } = this.state.leadsDetails;
    const { can_update } = this.props.permissionsChecker;
    const StatusOptions = type_id
      ? Object.keys(LeadStatus[type_id]).map((key) => ({
          label: LeadStatus[type_id][key],
          value: parseInt(key),
        }))
      : [];

    return dataField.map((item, index) => (
      <Grid
        item
        key={index}
        id={item.value}
        md={item.grid ? item.grid : 4}
        sm={6}
        xs={12}
      >
        <h2 className="at-form-input__title">{item.label || ""}</h2>
        {item.type === "select" && (
          <CustomSelect
            selectItems={
              item.value === "status_id" ? StatusOptions : item.options
            }
            required={item.requiredFilled}
            disabled={!can_update}
            currentlySelected={_.find(
              item.value === "status_id" ? StatusOptions : item.options,
              { value: this.state.leadsDetails[item.value] },
            )}
            updateSelect={(val) => this.onBindData(val.value, item.value)}
          />
        )}
        {item.type === "date" && (
          <div className="at-form-input">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              showYearDropdown
              disabled={!can_update}
              dateFormat={"YYYY-MM-DD"}
              className="at-form-input__input"
              value={this.state.leadsDetails[item.value] || ""}
              placeholderText={"YYYY-MM-DD"}
              onChange={(val) =>
                this.onBindData(Moment(val).format("YYYY-MM-DD"), item.value)
              }
            />
            {item.requiredFilled && (
              <div className="at-form-input__required">required *</div>
            )}
          </div>
        )}
        {item.type === "radio" && (
          <AtlasRadioGroup
            horizontal={true}
            disabled={!can_update}
            checkedValue={this.state.leadsDetails[item.value] || ""}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => this.onBindData(val, item.value)}
            options={item.options}
          />
        )}
        {["text", "textarea"].indexOf(item.type) > -1 && (
          <CustomFormInput
            type={item.type}
            disabled={!can_update}
            required={item.requiredFilled}
            value={this.state.leadsDetails[item.value] || ""}
            onChangeValue={(val) => this.onBindData(val, item.value)}
          />
        )}
      </Grid>
    ));
  };

  render = () => {
    let {
      role,
      permissionsChecker,
      closeButton,
      selectedLead,
      onLoadLeads,
      onLoadUpdate,
      assignModal,
      selectedUserType,
      selectedAgentInfo,

      updateSelectedLead,
      onChangeLeadUpdateHOC,
    } = this.props;

    const { can_update } = permissionsChecker;

    return (
      <>
        <Grid container spacing={16}>
          <Grid item id={"lead-basic-info"} md={6} xs={12}>
            <div className="at-form__content at-view-lead__buyer-info-bg h-100 mb-0">
              <div className="d-flex align-items-center mb-3">
                <div className="at-badge-icon at-badge-icon-warning">
                  <AtlasIcon svgHref={"atlas-personalcard"} />
                </div>
                <h2 className="fw-600 fs-3 ml-2">Lead Details</h2>
              </div>
              {leadInformationData.map((item) => (
                <div key={item.value} className="at-view-lead__detail-item">
                  <AtlasIcon svgHref={item.icon} />
                  <label className="fw-600">{item.label}</label>
                  <CustomFormInput
                    type={"text"}
                    className={"m-0 w-100"}
                    disabled={!can_update}
                    value={this.state.leadsDetails[item.value] || ""}
                    onChangeValue={(val) => this.onBindData(val, item.value)}
                  />
                </div>
              ))}
              <div className="at-view-lead__buyer-info-action ">
                {this.props.selectedLead.buyer_email && (
                  <CustomButton
                    containerClass={"w-100"}
                    className={"btn-new btn-new--outline-secondary w-100"}
                    onClick={() =>
                      window.open(
                        `mailto:${this.props.selectedLead.buyer_email}`,
                        "__blank",
                      )
                    }
                    children={
                      <>
                        <AtlasIcon
                          svgHref={"atlas-sms-edit"}
                          style={{ fill: "#3B82F6" }}
                        />
                        Email
                      </>
                    }
                  />
                )}
                {this.props.selectedLead.buyer_phone_number && (
                  <>
                    <CustomButton
                      containerClass={"w-100"}
                      className={"btn-new btn-new--outline-secondary w-100"}
                      onClick={() =>
                        window.open(
                          `https://api.whatsapp.com/send?phone=${this.props.selectedLead.buyer_phone_number}`,
                          "__blank",
                        )
                      }
                      children={
                        <>
                          <AtlasIcon
                            svgHref={"atlas-whatsapp"}
                            style={{ fill: "#12B76A" }}
                          />
                          Message
                        </>
                      }
                    />
                    <CustomButton
                      containerClass={"w-100"}
                      className={"btn-new btn-new--outline-secondary w-100"}
                      onClick={() =>
                        window.open(
                          `tel:${this.props.selectedLead.buyer_phone_number}`,
                          "__blank",
                        )
                      }
                      children={
                        <>
                          <AtlasIcon
                            svgHref={"atlas-call-calling"}
                            style={{ fill: "#3B82F6" }}
                          />
                          Call
                        </>
                      }
                    />
                  </>
                )}
              </div>
              <hr />
              {leadInformationData1.map((item) => (
                <div key={item.value} className="at-view-lead__detail-item">
                  <AtlasIcon svgHref={item.icon} />
                  <label className="fw-600">{item.label}</label>
                  <CustomFormInput
                    type={"text"}
                    className={"m-0 w-100"}
                    disabled={!can_update}
                    value={this.state.leadsDetails[item.value] || ""}
                    onChangeValue={(val) => this.onBindData(val, item.value)}
                  />
                </div>
              ))}
            </div>
          </Grid>
          <Grid item id={"lead-details"} md={6} xs={12}>
            <div className="at-form__content h-100 mb-0">
              <div className="d-flex align-items-center mb-3">
                <div className="at-badge-icon at-badge-icon-warning">
                  <AtlasIcon svgHref={"atlas-note-text"} />
                </div>
                <h2 className="fw-600 fs-3 ml-2">Lead Source</h2>
              </div>
              {leadInformationData2.map((item) => (
                <div
                  key={item.value}
                  className="at-view-lead__detail-item"
                  style={{
                    flexWrap: "nowrap",
                    borderBottom: "1px solid #D1D5DB",
                    margin: "12px 0px",
                  }}
                >
                  <AtlasIcon svgHref={item.icon} />
                  <label className="fw-600">{item.label}</label>
                  {item.value === "type_id" && (
                    <CustomSelect
                      selectItems={
                        ["Super Admin", "Admin"].indexOf(role) > -1
                          ? LeadType
                          : AgentLeadType
                      }
                      disabled={!can_update}
                      currentlySelected={_.find(
                        ["Super Admin", "Admin"].indexOf(role) > -1
                          ? LeadType
                          : AgentLeadType,
                        { value: this.state.leadsDetails[item.value] },
                      )}
                      updateSelect={(val) =>
                        this.onBindData(val.value, item.value)
                      }
                    />
                  )}
                  {item.value !== "type_id" && (
                    <span>{this.state.leadsDetails[item.value] || "N/A"}</span>
                  )}
                  {(["Super Admin", "Admin"].indexOf(role) > -1 &&
                    this.state.leadsDetails.assigned_agent && item.value === "agent_name") && (
                      <div id="agent-info" className="position-relative">
                        <button
                          id="agent-info"
                          className={"btn-new--outline-secondary ml-2"}
                          style={{
                            padding: 5,
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                          }}
                          onClick={() =>
                            this.setState((prevState) => ({
                              showAgentInfo: !prevState.showAgentInfo,
                            }))
                          }
                        >
                          <AtlasIcon
                            id="agent-info"
                            svgHref={"atlas-info-circle"}
                            style={{
                              width: 24,
                              height: 24,
                              fill: "#3B82F6",
                              marginRight: 0,
                            }}
                          />
                        </button>
                        {this.state.showAgentInfo && (
                          <AgentInfoContent
                            selectedData={this.state.leadsDetails.assigned_agent}
                          />
                        )}
                      </div>
                    )}
                  {((["Super Admin", "Admin"].indexOf(role) > -1 &&
                    item.value === "admin_name") ||
                    item.value === "agent_name") && (
                    <CustomButton
                      className={"btn-new--outline-secondary ml-2"}
                      style={{
                        padding: 5,
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                      }}
                      children={
                        <AtlasIcon
                          svgHref={"atlas-user-circle-add"}
                          style={{
                            width: 24,
                            height: 24,
                            fill: "#3B82F6",
                            marginRight: 0,
                          }}
                        />
                      }
                      onClick={() => {
                        let tmpUser = UserTypes.find(
                          (i) => i.match_key === item.value,
                        );
                        onChangeLeadUpdateHOC(true, "assignModal");
                        onChangeLeadUpdateHOC(tmpUser, "selectedUserType");
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Grid>
          <Grid item id={"interest"} md={12}>
            <div className="at-form__content h-100 mb-0">
              <div className="d-flex align-items-center mb-3">
                <div className="at-badge-icon at-badge-icon-warning">
                  <AtlasIcon
                    svgHref={"atlas-building-3"}
                    style={{ fill: "#F0631D" }}
                  />
                </div>
                <h2 className="fw-600 fs-3 ml-2">Interest</h2>
              </div>
              <Grid container spacing={16}>
                {this.renderInputField(leadInformationData3)}
              </Grid>
            </div>
          </Grid>
          <Grid item id={"lead-status"} md={12}>
            <div className="at-form__content h-100">
              <div className="d-flex align-items-center mb-3">
                <div className="at-badge-icon at-badge-icon-warning">
                  <AtlasIcon
                    svgHref={"atlas-notification-status"}
                    style={{ fill: "#F0631D" }}
                  />
                </div>
                <h2 className="fw-600 fs-3 ml-2">Lead Status</h2>
              </div>
              <Grid container spacing={16}>
                {this.renderInputField(leadStatusData)}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className="at-modal_dialog-container-footer g-3">
          {can_update && (
            <button
              className={"btn-new btn-new--success"}
              onClick={() => {
                let temp = {
                  ...this.state.leadsDetails,
                  priority_id: parseInt(this.state.leadsDetails.priority_id),
                };
                updateSelectedLead(selectedLead.id, temp);
              }}
            >
              Update
            </button>
          )}
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={closeButton}
          >
            Close
          </button>
        </div>
        {assignModal && (
          <AssignDialog
            context={selectedUserType ? selectedUserType.value : ""}
            loading={onLoadUpdate}
            selectedLead={selectedLead}
            onClose={() => onChangeLeadUpdateHOC(false, "assignModal")}
            assignAgent={(id) =>
              this.props.assignAgent(selectedLead.id, { downline_id: id })
            }
          />
        )}
        {(onLoadLeads || onLoadUpdate) && <LoadingModal />}
      </>
    );
  };
}

export default compose(LeadUpdateHOC)(Edit);
