import Moment from "moment";
export const checkNull = (val) => {
  if (val === null) {
    return 0;
  } else {
    return val;
  }
};

export const checkNumberNull = (val) => {
  return !val || val.length === 0 || val === "0";
};

export const checkIsNumber = (val) => {
  return val !== null && ([0, "0"].indexOf(val) > -1 || val);
};

export const validateNumber = (val, decimalPt = 0) => {
  if (typeof val !== "boolean" && !isNaN(Number(val))) {
    return parseFloat(val).toFixed(decimalPt);
  } else {
    return 0;
  }
};

export const validationCheck = (val, validatedType) => {
  if (typeof val === "boolean" || [0, "0"].indexOf(val) > -1) {
    return val;
  } else if (val === null || !val) {
    if (validatedType === "string" || validatedType === "text") {
      return "";
    } else {
      return 0;
    }
  } else {
    return val;
  }
};

export const dateValidation = (val, format = "", resultFormat) => {
  if (val && val !== null)
    if (resultFormat) {
      return format
        ? Moment(val, format).format(resultFormat)
        : Moment(val).format(resultFormat);
    } else {
      return format ? Moment(val, format) : Moment(val);
    }
  else return null;
};
