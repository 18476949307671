import React from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import ModuleFormHeader from "components/Form/header";
import RENCard from "./RENCard";

import { StatusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";

const middleData = [
  { label: "Project Type", value: "type", icon: "atlas-building-3" },
  { label: "Booking Date", value: "booking_date", icon: "atlas-calendar" },
  { label: "Buyer", value: "buyer_name", icon: "atlas-frame" },
  { label: "Assigned To", value: "assigned_admin", icon: "atlas-profile-add" },
];

const dataList = [
  { label: "SPA Price", value: "purchase_price" },
  { label: "Net Price", value: "nett_price" },
  { label: "Package", value: "package" },
  { label: "Booking Form", value: "booking_form" },
  { label: "Size", value: "size" },
  { label: "Remark", value: "remark" },
];

const ViewSale = ({ selectedSale }) => {
  let owner = selectedSale.agent.full_name;

  let totalRENValue = 0;

  let tempNonDestroyAgent = _.filter(
    selectedSale.project_sale_agents_attributes,
    (item) => item._destroy !== "1",
  );

  tempNonDestroyAgent.map((item) => {
    let intVal = item.percentage ? parseFloat(item.percentage) : 0;
    totalRENValue = totalRENValue + intVal;
  });

  const { colorName = "" } =
    _.find(StatusColor, { value: selectedSale.status }) || {};

  return (
    <>
      <div className="at-new-sales__details">
        <h1>
          {selectedSale.project_name}
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {selectedSale.status}
          </div>
        </h1>
        <p className="mt-2">Unit Number: {selectedSale.unit_number}</p>
        <div className="at-new-sales__details-middle">
          {middleData.map((item, index) => (
            <div key={index} className="d-flex align-items-center">
              <div className="at-badge-icon-sm at-badge-icon-warning">
                <AtlasIcon svgHref={item.icon} />
              </div>
              <div>
                <label>{item.label}</label>
                <p>{selectedSale[item.value]}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="at-form__content at-new-sales__details-bottom">
          {dataList.map((item, index) => (
            <div key={index} className="at-new-sales__details-item">
              <label>{item.label}</label>
              <p style={{ marginTop: 2 }}>{selectedSale[item.value]}</p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <ModuleFormHeader
        title={"Agent Information"}
        className={"my-3"}
        moduleIcon={"atlas-user-tie"}
      />
      {selectedSale.project_sale_agents_attributes.length < 1 && (
        <p>No agent info is available at the moment.</p>
      )}
      {!(selectedSale.project_sale_agents_attributes.length < 1) && (
        <>
          <h2
            className="at-form-input__title"
            style={{ color: totalRENValue !== 100 ? "#FB404B" : "#000" }}
          >
            {`Total percentage: ${totalRENValue}% out of total 100%`}
          </h2>
          <div className="row">
            {selectedSale.project_sale_agents_attributes.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-xs-12">
                <RENCard
                  index={index}
                  item={item}
                  owner={owner}
                  mode={"view"}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ViewSale;
