import React from "react";
import { Line } from "react-chartjs-2";
import _ from "lodash";

const UnitSoldChart = ({ data }) => {
  const processData = () => {
    let tmpX = [];
    let tmpYProjectSoldUnits = [];
    let tmpYSubsaleSoldUnits = [];
    data.projectsSoldUnits &&
      data.projectsSoldUnits.map((child) => {
        Object.keys(child).map((key) => {
          tmpX.push(key);
          tmpYProjectSoldUnits.push(child[key]);
        });
      });
    data.subsalesSoldUnits &&
      data.subsalesSoldUnits.map((child) => {
        Object.keys(child).map((key) => tmpYSubsaleSoldUnits.push(child[key]));
      });
    return {
      tmpX: _.reverse(tmpX),
      tmpYProjectSoldUnits: _.reverse(tmpYProjectSoldUnits),
      tmpYSubsaleSoldUnits: _.reverse(tmpYSubsaleSoldUnits),
    };
  };
  const result = (canvas) => {
    return {
      labels: processData().tmpX,
      datasets: [
        {
          label: "Project Units Sold",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "#648FFF59",
          borderColor: "#648FFF",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          borderWidth: 2,
          pointRadius: 3,
          pointBorderColor: "#648FFF",
          pointBackgroundColor: "#648FFF",
          pointBorderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#648FFF",
          pointHoverBorderColor: "#648FFF",
          pointHoverBorderWidth: 2,
          pointHitRadius: 10,
          data: processData().tmpYProjectSoldUnits,
          responsive: true,
          maintainAspectRatio: true,
        },
        {
          label: "Subsales Units Sold",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "#FFB00059",
          borderColor: "#FFB000",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          borderWidth: 2,
          pointRadius: 3,
          pointBorderColor: "#FFB000",
          pointBackgroundColor: "#FFB000",
          pointBorderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#FFB000",
          pointHoverBorderColor: "#FFB000",
          pointHoverBorderWidth: 2,
          pointHitRadius: 10,
          data: processData().tmpYSubsaleSoldUnits,
          responsive: true,
          maintainAspectRatio: true,
        },
      ],
    };
  };
  return (
    <div className="grid-half-col">
      <div className="at-form__content mb-0">
        <h2
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#111827",
            marginBottom: 16,
          }}
        >
          Number of units sold
        </h2>
        <div style={{ position: "relative", height: "50vh", marginTop: 20 }}>
          <Line
            data={result}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: { display: false },
              plugins: {
                datalabels: {
                  display: false,
                },
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UnitSoldChart;
