import React, { Component } from "react";
import _ from "lodash";

import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      projectListings: [],
      projectLocations: [],
    };

    load = (param) => this.setState({ loading: param });

    getActiveProjects = (refId, type_id) =>
      Get(
        `/projects/erp?country_reference_id=${refId}${type_id ? `&type_id=${type_id}` : ""}`,
        this.getActiveProjectsSuccess,
        this.getActiveProjectsError,
        this.load,
      );
    getActiveProjectsSuccess = (payload) => {
      let tempOptions = [];

      payload.length > 0 &&
        payload.map((item) => {
          tempOptions.push({ ...item, value: item.name, id: item.reference_id });
        });
      this.setState({ projectListings: tempOptions });
    };
    getActiveProjectsError = (error) => requestError(error);

    getProjectLocations = (id) =>
      Get(
        `/projects/locations?country_reference_id=${id}`,
        this.getProjectLocationsSuccess,
        this.getProjectLocationsError,
        this.load,
      );
    getProjectLocationsSuccess = (payload) =>
      this.setState({ projectLocations: payload });
    getProjectLocationsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            projectLocations={this.state.projectLocations}
            projectListings={this.state.projectListings}
            onLoadActiveProjects={this.state.loading}
            getActiveProjects={this.getActiveProjects}
            getProjectLocations={this.getProjectLocations}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
