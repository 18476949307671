import React, { Component } from "react";
import _ from "lodash";
import { Collapse } from "@material-ui/core";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import TableHeader from "./Header";
import TableContent from "./Content";
import TableSearchParams from "./SearchParams";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchContent: false,
  };

  componentDidMount = () => {
    let tmp = _.cloneDeep(this.props.searchParams);
    tmp.map((item) => {
      item.param = "";
    });
    this.props.onChangeSearchParams(tmp);
  };

  renderTableContent = () => {
    if (this.props.rowData && this.props.rowData.length > 0) {
      return this.props.rowData.map((item) => (
        <TableContent
          key={item.id}
          centerAlignColumns={this.props.centerAlignColumns}
          actionColumnContent={this.props.actionColumnContent}
          disableRowAction={this.props.disableRowAction}
          headerData={this.props.headerData}
          rowItem={item}
          actionColumn={this.props.actionColumn}
          onClickApprove={this.props.onClickApprove}
          onClickResubmit={this.props.onClickResubmit}
          onClickReject={this.props.onClickReject}
        />
      ));
    } else {
      return (
        <EmptyState
          title={`You don't have any items`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp =
                        item.param !== ""
                          ? tmp + `q[${item.value}]=${item.param}&`
                          : tmp;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    const { tableTitle, onClickClose } = this.props;
    return (
      <div className="at-table__options">
        <div className="at-card__header">
          <AtlasCloseButton onClick={onClickClose} />
          <h4 className="at-card__title">{tableTitle}</h4>
        </div>
        {this.props.searchParams.length > 0 && (
          <button
            className={"btn mb-10 mt-10 p-0"}
            style={{ width: "max-content" }}
            onClick={() =>
              this.setState((prevState) => ({
                showSearchContent: !prevState.showSearchContent,
              }))
            }
          >
            <h5 style={{ fontSize: 16, fontWeight: 500, marginLeft: 5 }}>
              Search
              <AtlasIcon
                className={"at-table__search-icon"}
                svgHref={"atlas-search-normal"}
              />
            </h5>
          </button>
        )}
        <Collapse
          in={this.state.showSearchContent}
          timeout="auto"
          unmountOnExit
        >
          <TableSearchParams
            searchParams={this.props.searchParams}
            onChangeSearchParams={this.props.onChangeSearchParams}
            getListAPI={this.props.getListAPI}
          />
        </Collapse>
        <div className="at-table at-table--set">
          {this.props.rowData && this.props.rowData.length > 0 && (
            <TableHeader
              centerAlignColumns={this.props.centerAlignColumns}
              data={this.props.headerData}
              actionColumnContent={this.props.actionColumnContent}
              actionColumn={this.props.actionColumn}
              onClickRearrangeData={(val) => {}}
            />
          )}
          {this.renderTableContent()}
        </div>
        {this.props.rowData && this.props.rowData.length > 0 && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.props.meta &&
              this.props.meta.count > 0 &&
              this.renderPaginationText()}
            {this.renderTablePagination()}
          </div>
        )}
      </div>
    );
  };
}

export default PaginatedTable;
