export const getLocalisedKeyPrefix = (label) => {
  switch (label) {
    case "Tenure":
      return "option.tenure_types";
    case "Title Type":
      return "option.title_types";
    case "Furnishing":
      return "option.furnishing_statuses";
    case "Direction":
      return "option.directions";
    case "Occupancy":
      return "option.occupancies";
    case "Unit Type":
      return "option.unit_types";
    case "Type":
      return "option.types";
    case "Facilities":
      return "option.facilities";
    case "Residential":
    case "Listing Category":
    case "Property Type":
      return "option.categories.residential";
    case "Commercial":
      return "option.categories.commercial";
    case "Industrial":
      return "option.categories.industrial";
    case "Agricultural":
      return "option.categories.agricultural";
    case "Square Feet":
      return "option.measurements.sqft";
    case "Square Meter":
      return "option.measurements.sqm";
    case "Acre":
      return "option.measurements.acre";
    case "Hectare":
      return "option.measurements.hectare";

    default:
      return "option.unknown";
  }
};

export const getLocalisedDate = (dateValue, language) => {
  const localeMap = {
    ja: 'ja-JP',
    en: 'en-US',
    zh: 'zh-CN',
    ms: 'ms-MY'
  };
  const locale = localeMap[language] || 'en-US';

  const parseDate = (dateStr) => {
    const parts = dateStr?.split('-');
    if (parts && parts.length === 3) {
      const [day, month, year] = parts.map(part => parseInt(part, 10));
      return new Date(year, month - 1, day);
    } else {
      return new Date(dateStr);
    }
  };

  const date = parseDate(dateValue);
  return isNaN(date.getTime()) ? "N/A" : date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const processLocalisedKey = (param) => {
  return param.replace(/[-\s\/]/g, '_').toLowerCase()
};