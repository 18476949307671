import React, { useState } from "react";

import AtlasFormInput from "components/Input/formInput";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

const CreateApiKeyDialog = ({ title, onLoad, onClose, submitData }) => {
  const [newApiKey, setNewApiKey] = useState({ app_name: "" });

  const onChangeValue = (value, context) => {
    setNewApiKey((prev) => ({
      ...prev,
      [context]: value,
    }));
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={onClose} />
        <h4 className="at-card__title p-0">{`${title} API Key`}</h4>
      </div>
      <form className="row">
        <div className={`col-md-6`}>
          <h2 className="at-form-input__title">App Name </h2>
          <AtlasFormInput
            type="text"
            disabled={false}
            value={`${newApiKey["app_name"] || ""}`}
            onChangeValue={(val) => onChangeValue(val, "app_name")}
          />
        </div>
        <div className="col-md-6"></div>
        <div className="col-12 d-flex mt-10">
          <button
            className="btn-new btn-new--success"
            onClick={() => submitData(newApiKey)}
          >
            Add Now
          </button>
          <button
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </form>
      {onLoad && <LoadingModal />}
    </>
  );
};

export default CreateApiKeyDialog;
