import React, { Component } from "react";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get, GetFileURL } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      documents: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getDocuments = (submission_id) =>
      Get(
        `/submissions/${submission_id}/documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) =>
      this.setState({ documents: payload.submission_documents });
    getDocumentsError = (error) => requestError(error);

    downloadDocument = (url, filename) =>
      GetFileURL(url, filename, this.downloadDocumentError, this.load);
    downloadDocumentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            documents={this.state.documents}
            getDocuments={this.getDocuments}
            downloadDocument={this.downloadDocument}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
