import { combineReducers } from "redux";

import ajaxStatusReducer from "./ajaxStatus";
import profileReducer from "./profile";
import dictionaryReducer from "./dictionary";
import firebaseReducer from "./firebase";
import paymentVoucherReducer from "./paymentVoucher";
import branchReducer from "./branch";
import agencyReducer from "./agency";
import salesReducer from "./sales";
import subsalesReducer from "./subsales";
import iqiDriveReducer from "./iqidrive";
import appStatusReducer from "./appStatus";
import messageReducer from "./message";
import addressReducer from "./address";
import lastViewReducer from "./lastView";
import registerReducer from "./registration";
import authReducer from "./auth";
import permissionsReducer from "./permissions";
import myCartReducer from "./myCart";
import sideMenuReducer from "./sideMenu";
import motdReducer from "./motd";
import languageReducer from "./language";
import adminSignInReducer from "./adminSignIn";
import teamSalesDateReducer from "./teamSalesDate";
import appSettingReducer from "./appSetting";
import featureGuideReducer from "./featureGuide";
import currentSignInProfileReducer from "./currentSignInProfile";

export default combineReducers({
  // non cached reducer
  ajaxStatusReducer,
  profileReducer,
  // cached reducer
  dictionaryReducer,
  firebaseReducer,

  paymentVoucherReducer,
  salesReducer,
  subsalesReducer,

  branchReducer,

  agencyReducer,
  languageReducer,
  iqiDriveReducer,
  appStatusReducer,
  messageReducer,
  addressReducer,
  registerReducer,
  lastViewReducer,
  authReducer,
  adminSignInReducer,
  teamSalesDateReducer,
  currentSignInProfileReducer,
  permissionsReducer,
  myCartReducer,
  sideMenuReducer,
  motdReducer,
  appSettingReducer,
  featureGuideReducer,
});
