import React from "react";
import { BsCurrencyExchange } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";

const projectDetailsArray = [
  { label: "Project Title", value: "project_title", icon: "building-4" },
  { label: "Project Type", value: "project_type", icon: "buildings-2" },
  { label: "Tenure Type", value: "tenure_type", icon: "building" },
  {
    label: "Currency",
    value: "net_price_currency",
    icon: <BsCurrencyExchange />,
  },
  { label: "Minimum Price", value: "net_price_cents", icon: "coin-1-linear" },
  { label: "Maximum Price", value: "max_price_cents", icon: "coin-1" },
  { label: "Country", value: "country", icon: "global" },
  { label: "State", value: "state", icon: "route-square" },
  { label: "Area/City", value: "township", icon: "location" },
];

const ProjectDetails = ({ selectedProject }) => {
  const statusColor = selectedProject.status === "Active" ? "Green" : "Red";

  return (
    <div className="at-project-listing__project-details-cont">
      <div className="at-project__detail-title">
        <h1
          style={{
            fontSize: 22,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedProject.internal_name}
          <span
            style={{
              marginLeft: 12,
              fontSize: 14,
              borderRadius: 50,
              padding: "2px 8px",
              border: "1px solid",
              ...getColorBadge(statusColor),
            }}
          >
            {selectedProject.status}
          </span>
        </h1>
        <p style={{ fontSize: 14, color: "#374151", marginBottom: 8 }}>
          Project Name: {selectedProject.name}
        </p>
        <p style={{ fontSize: 14, color: "#1F2937", marginBottom: 12 }}>
          <AtlasIcon
            svgHref="atlas-location"
            style={{ width: 20, height: 20, marginRight: 4, fill: "#F0631D" }}
          />
          {selectedProject.location}
        </p>
      </div>
      <div className="at-project__details-cont" style={{ padding: "8px 20px" }}>
        {projectDetailsArray.map((item, index) => (
          <div key={index} className={`at-project__details-item`}>
            {typeof item.icon === "string" ? (
              <AtlasIcon svgHref={`atlas-${item.icon}`} />
            ) : (
              item.icon
            )}
            <label>{item.label}</label>
            <span>
              {selectedProject[item.value]
                ? selectedProject[item.value].toLocaleString()
                : `N/A`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetails;
