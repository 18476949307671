import React from "react";
import { Tooltip } from "@material-ui/core";

const TooltipWrapper = ({ child, title, placement }) => {
  return (
    <Tooltip
      enterTouchDelay={50}
      classes={{ tooltip: placement ? "tooltip-arrow" : "tooltip-arrow top" }}
      placement={placement || "top"}
      title={title}
    >
      {child}
    </Tooltip>
  );
};

export default TooltipWrapper;
