import Axios from "axios";
import _ from "lodash";

import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "../ajax";
import getDomainURL from "utils/getDomainURL";
import objToFormData from "utils/objToFormData";
import { STORE_SELECTED_SUBSALES, STORE_WATERMARK } from "../type";
import { FCM_SERVER_KEY_DEV, FCM_SERVER_KEY_PROD } from "utils/config";

const FCM_HEADER = {
  headers: {
    Authorization:
      window.location.host.indexOf("atlas.iqiglobal.com") !== -1
        ? FCM_SERVER_KEY_PROD
        : FCM_SERVER_KEY_DEV,
    "Content-Type": "application/json",
  },
};

export function getSelectedSubsales(id) {
  return (dispatch) => {
    dispatch(beginAjaxCall(true));
    Axios.get(`${getDomainURL()}/sub_sales/${id}`)
      .then((response) => {
        dispatch(getSelectedSubsalesSuccess(response.data));
        dispatch(ajaxCallSuccess());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error, () => dispatch(getSelectedSubsales(id))));
      });
  };
}

function getSelectedSubsalesSuccess(payload) {
  return {
    type: STORE_SELECTED_SUBSALES,
    payload,
  };
}

export const uploadSubsalesPhoto = (
  id,
  data,
  waterMarkInfo,
  role,
  reloadListingAPIs,
) => {
  const temp = [];
  data.map((image) => {
    temp.push(
      Axios.post(
        `${getDomainURL()}/sub_sales/${id}/photos`,
        objToFormData(image),
      ),
    );
  });
  return (dispatch) => {
    dispatch(beginAjaxCall(true));
    Promise.allSettled(temp).then((response) => {
      waterMarkInfo.watermark && dispatch(storePhotoWaterMark(waterMarkInfo));
      let check = _.find(response, { status: "rejected" });
      if (check) {
        dispatch(ajaxCallError(check.reason.response));
      } else {
        reloadListingAPIs();
        dispatch(ajaxCallSuccess());
      }
    });
  };
};

export function storePhotoWaterMark(payload) {
  return {
    type: STORE_WATERMARK,
    payload,
  };
}

export function sendFirebaseSubsalesMessage(receiverFirebaseToken, msg) {
  return (dispatch) => {
    Axios.post(
      `https://fcm.googleapis.com/fcm/send`,
      {
        notification: {
          title: "IQI Atlas Subsales Chat Message",
          body: msg,
        },
        to: receiverFirebaseToken,
      },
      FCM_HEADER,
    )
      .then((response) => {
        // tell sender and receiver message had been sent
      })
      .catch((error) => {
        // fail to send message
      });
  };
}
