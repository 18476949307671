import React, { useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import { createTicketPricingLib } from "../assets";

const CreateTicketType = ({ onClose, createTicketType, onLoadTicketType }) => {
  const [ticketData, onChangeTicketData] = useState({
    title: "",
    remarks: "",
    type_id: "",
    is_seat_limited: "",
    maximum_seat: "",
    is_joint_ticket: "",
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(ticketData);
    temp[context] = val;

    onChangeTicketData(temp);
  };

  return (
    <>
      <ModalDialog
        title={ 'New Ticket Type' }
        onClose={ onClose }
        responsiveSize={ 'sm' }
        onLoad={ onLoadTicketType }
        children={
          <div className={ 'row' }>
            {
              createTicketPricingLib.map( item => (
                <>
                  {
                    ( item.type === "radio" && item.onShow( ticketData ) ) && (
                      <div 
                        key={ item.value } 
                        className={ `col-md-12` }>
                        <label className="at-form-input__title" required>{ item.label }</label>
                        <br/>
                        <AtlasRadioGroup
                          horizontal={ true }
                          checkedValue={ ticketData[ item.value ] }
                          selectedRadioValue={ val => onChangeField( val, item.value ) }
                          options={ item.options } />
                      </div>
                    )
                  }
                  {
                    ( item.type !== "radio" && item.onShow( ticketData ) ) && (
                      <div 
                        key={ item.value } 
                        className={ `col-md-12` }>
                        <label className="at-form-input__title" required={ item.value !== 'remarks' }>{ item.label }</label>
                        <br/>
                        <CustomFormInput
                          type={ item.type }
                          required={ item.value !== 'remarks' }
                          value={ ticketData[ item.value ] }
                          onChangeValue={ val => onChangeField( val, item.value ) } />
                      </div>
                    )
                  }
                </>
              ))
            }
          </div>
        }
        footer={
          <div className="d-flex g-3">
            <CustomButton
              onClick={ () => createTicketType( ticketData ) }
              disabled={ createTicketPricingLib.some( item => item.onDisabled( ticketData )) }
              className={ 'd-flex btn-new btn-new--primary' }
              children={ 'Submit' } />
            <CustomButton
              onClick={onClose}
              className={"btn-new btn-new--outline-secondary"}
              children={"Cancel"}
            />
          </div>
        }
      />
    </>
  );
};

export default CreateTicketType;
