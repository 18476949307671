import React, { useState } from "react";
import _ from "lodash";

import AtlasButton from "components/Button";
import CustomTextarea from "components/Input/textarea";

const EventTrainingForm = ({ onClose, confirmReject }) => {
  const [reject_reason, onChangeReject] = useState("");

  return (
    <>
      <h2 className="at-form-input__title" required>Reject Reason</h2>
      <CustomTextarea
        rows="10"
        type="text"
        value={reject_reason}
        required={true}
        placeholder={"Write your reject reason"}
        onChangeValue={(value) => onChangeReject(value)} />
      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          disabled={_.isEmpty(reject_reason)}
          className={"btn-new btn-new--danger"}
          onClick={() => confirmReject(reject_reason)}
          children={"Reject"}
        />
        <AtlasButton
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() => onClose()}
        />
      </div>
    </>
  );
};

export default EventTrainingForm;
