import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import AtlasCard from "components/Card";
import IQIDriveTable from "./components/Table";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasSnackbar from "./components/snackbar";
import AtlasDownloadFilesModal from "./downloadFilesStatus";
import IQIDriveSearchModal from "./SearchResult";
import PreviewModal from "./Preview";

import IQIDriveHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { countChar } from "utils/charCounter";
import { initGA } from "utils/ga";

class IQIDrive extends Component {
  state = {
    can_update: false,
  };

  componentDidMount = () => {
    initGA("/dashboard/iqi-drive");
    this.initComponent();
  };

  beforeUnloadListener(event) {
    event.preventDefault();
    event.returnValue = `Are you sure you want to leave?`;
  }

  initComponent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { can_update: updateDrive } = permissionsChecker(
      "IQI Drive",
      this.props.data,
    );

    if (role === "Admin") {
      this.setState({ can_update: updateDrive });
    } else {
      this.setState({ can_update: false });
    }

    this.props.storeLastView({});
    if (this.props.match.params.type === "list") {
      this.props.getDirectory("", this.props.requestError);
    } else if (this.props.match.params.type === "custom") {
      this.props.onChangeDriveHOC(
        _.cloneDeep(this.props.data.iqiDriveReducer.data.ids),
        "ids",
      );
      if (this.props.data.iqiDriveReducer.data.type === "directory") {
        this.props.getDirectory(
          this.props.data.iqiDriveReducer.data.path,
          this.props.requestError,
        );
      } else {
        this.props.onDownloadFile(this.props.data.iqiDriveReducer.data);
      }
    }

    if (role === "Super Admin" || role === "Admin") {
      let tmpHeaderData = [
        ...this.props.headerData,
        {
          label: "Actions",
          showName: true,
          classNameWord: "actions",
        },
      ];
      this.props.onChangeDriveHOC(tmpHeaderData, "headerData");
    }
    if (!this.props.showFavouriteLists) {
      this.props.onChangeDriveHOC(
        _.cloneDeep(this.props.data.iqiDriveReducer.directory),
        "driveDataLists",
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    const { showPreviewModal, getFileUrl } = this.props;
    let {
      ajaxCallProgressDrive,
      ajaxErrorDrive,
      ajaxSuccessDrive,
      ajaxErrorMessageDrive,
    } = this.props.data.ajaxStatusReducer;
    if (
      ajaxCallProgressDrive !==
      prevProps.data.ajaxStatusReducer.ajaxCallProgressDrive
    ) {
      if (ajaxCallProgressDrive === 0) {
        if (ajaxSuccessDrive) {
          let tmpIds = this.props.ids;

          if (
            this.props.data.iqiDriveReducer &&
            this.props.data.iqiDriveReducer.directory &&
            this.props.data.iqiDriveReducer.directory.path
          ) {
            let directoryLayerCount = countChar(
              this.props.data.iqiDriveReducer.directory.path,
              "/",
            );
            if (
              tmpIds.indexOf(this.props.data.iqiDriveReducer.directory.id) ===
              -1
            ) {
              tmpIds.push(this.props.data.iqiDriveReducer.directory.id);
            }
            let tmpDirectory = _.cloneDeep(
              this.props.data.iqiDriveReducer.directory,
            );

            tmpDirectory = {
              ...tmpDirectory,
              contents: tmpDirectory.contents.map((item) => ({
                ...item,
                link: getFileUrl(item),
              })),
            };
            this.props.onChangeDriveHOC(tmpDirectory, "driveDataLists");
            this.props.onChangeDriveHOC(tmpIds, "ids");
            return this.props.onChangeDriveHOC(
              directoryLayerCount,
              "directoryLayerCount",
            );
          }
        }
        if (ajaxErrorDrive) {
          this.props.requestError(ajaxErrorMessageDrive);
        }
      }
    }
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.props.onChangeDriveHOC([], "ids");
      this.props.getDirectory("", this.props.requestError);
    }
    if (prevProps.showFavouriteLists !== this.props.showFavouriteLists) {
      if (!this.props.showFavouriteLists) {
        this.props.onChangeDriveHOC(
          _.cloneDeep(this.props.data.iqiDriveReducer.directory),
          "driveDataLists",
        );
      }
    }
    if (prevProps.showPreviewModal !== showPreviewModal) {
      if (showPreviewModal) {
        return window.addEventListener(
          "beforeunload",
          this.beforeUnloadListener,
          true,
        );
      }
      return window.removeEventListener(
        "beforeunload",
        this.beforeUnloadListener,
        true,
      );
    }
  };

  renderCardContent = () => {
    const { ajaxCallProgressDrive } = this.props.data.ajaxStatusReducer;
    const { directory } = this.props.data.iqiDriveReducer;
    const { display_name } = directory;
    const {
      headerData,
      title,
      driveDataLists,
      showFavouriteLists,
      ids,
      previewLoading,
      directoryLayerCount,
      downloadList,
      data,
      onLoadIQIDrive,
      openSnackBar,
      snackBarMessage,
      showPreviewModal,
      showDownloadedFiles,
      selectedFile,
      downloading,

      getAllFavourites,
      onClickFavourite,
      onChangeDriveHOC,
      onSelectDirectory,
      onSelectFile,
      getDirectory,
      downloadMultipleFiles,
      onPressUpDirectory,
      copyURLToClipboard,
      onDownloadFile,
      clearDriveCache,
      onChangeCheckboxValue,
      closeDownloadedFilesList,
      requestError,
    } = this.props;

    const { role } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    return (
      <>
        <IQIDriveSearchModal
          onChangeDriveHOC={onChangeDriveHOC}
          getDirectory={getDirectory}
        />
        <IQIDriveTable
          classContainer="at-drive-table_cont"
          headerData={headerData}
          ids={ids}
          role={role}
          parent_path={driveDataLists.path}
          data={driveDataLists.contents}
          directoryLayerCount={directoryLayerCount}
          downloadList={downloadList}
          title={title}
          display_name={display_name}
          showFavouriteLists={showFavouriteLists}
          showFavouriteFunction={true}
          showCopyUrlFunction={role === "Super Admin" || role === "Admin"}
          showResetCacheFunction={
            role === "Super Admin" || this.state.can_update
          }
          showFavouriteListsFunction={true}
          getAllFavourites={() => {
            let tmpShowFavouriteLists = !showFavouriteLists;
            onChangeDriveHOC(tmpShowFavouriteLists, "showFavouriteLists");
            if (tmpShowFavouriteLists) {
              onChangeDriveHOC("My Favourites", "title");
              getAllFavourites();
            } else {
              onChangeDriveHOC("", "title");
              getDirectory("", requestError);
            }
          }}
          onChangeHOC={onChangeDriveHOC}
          downloadMultipleFiles={downloadMultipleFiles}
          clearDriveCache={clearDriveCache}
          onClickFavourite={onClickFavourite}
          onSelectDirectory={onSelectDirectory}
          onSelectFile={onSelectFile}
          onChangeCheckboxValue={onChangeCheckboxValue}
          onPressUpDirectory={onPressUpDirectory}
          copyURLToClipboard={copyURLToClipboard}
        />
        <AtlasSnackbar
          open={openSnackBar}
          handleClose={() => onChangeDriveHOC(false, "openSnackBar")}
          message={snackBarMessage}
        />
        <PreviewModal
          showPreviewModal={showPreviewModal}
          onClosePreviewModal={() =>
            onChangeDriveHOC(false, "showPreviewModal")
          }
          selectedFile={selectedFile}
          downloading={downloading}
          onDownloadFile={() => {
            onDownloadFile(selectedFile);
          }}
        />
        {showDownloadedFiles && (
          <AtlasDownloadFilesModal
            downloadedFiles={downloadList}
            closeDownloadedFilesList={closeDownloadedFilesList}
          />
        )}
        {(ajaxCallProgressDrive > 0 || previewLoading || onLoadIQIDrive) && (
          <LoadingModal
            message={
              previewLoading
                ? `Please wait for the download to complete. It might take a while depending on the size of the file and your internet connection speed.`
                : `Please wait while loading`
            }
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={"IQI Drive"} atlasIcon={"atlas-folder"} />
        <AtlasCard cardContent={this.renderCardContent()} />
      </>
    );
  };
}

export default compose(IQIDriveHOC, VersionCheckHOC)(IQIDrive);
