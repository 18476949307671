import React, { useState } from "react";
import { Card } from "@material-ui/core";
import _ from "lodash";

import CustomTab from "components/Tab";
import AtlasCloseButton from "components/Button/prev";
import BulkUpdateContent from "./bulkUpdateContent";
import ManualUpdateContent from "./manualUpdateContent";

const UpdateExamContent = ({ onCloseModal, getExams }) => {
  const [tabContent, onChangeTab] = useState("Bulk Update");
  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onCloseModal()} />
        <h4 className="at-card__title">{"Edit Exam Result"}</h4>
      </div>
      <CustomTab
        sections={["Bulk Update", "Manual Update"]}
        onSelectSection={(item) => onChangeTab(item)}
        selectedSection={tabContent}
      />
      <Card style={{ padding: 15, marginBottom: 20, height: "100%" }}>
        {tabContent === "Bulk Update" && (
          <BulkUpdateContent getExams={getExams} onCloseModal={onCloseModal} />
        )}
        {tabContent === "Manual Update" && (
          <ManualUpdateContent
            getExams={getExams}
            onCloseModal={onCloseModal}
          />
        )}
      </Card>
    </>
  );
};

export default UpdateExamContent;
