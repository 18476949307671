import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Get, Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateUnlockRequestModal: false,
      showUnlockRequestDetailsModal: false,

      unlockRequests: [],
      selectedUnlockRequest: {},
      createRequestReason: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeUnlockHOC = (val, context) => this.setState({ [context]: val });

    // get unlock request of a confirmation form
    getUnlockRequest = (confirmationId) =>
      Get(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests`,
        this.getUnlockRequestSuccess,
        this.getUnlockRequestError,
        this.load,
      );
    getUnlockRequestSuccess = (payload) => {
      let tempData = _.map(payload, (item) => ({
        ...item,
        requested_datetime: item.requested_datetime
          ? Moment(item.requested_datetime, "DD-MM-YYYY HH:mm").format(
              "DD MMM YYYY HH:mm",
            )
          : "N/A",
      }));
      this.setState({ unlockRequests: tempData });
    };
    getUnlockRequestError = (error) => requestError(error);

    // get selected unlock request
    getSelectedUnlockRequest = (confirmationId, requestId) =>
      Get(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}`,
        this.getSelectedUnlockRequestSuccess,
        this.getSelectedUnlockRequestError,
        this.load,
      );
    getSelectedUnlockRequestSuccess = (payload) =>
      this.setState({
        selectedUnlockRequest: {
          ...payload,
          requested_datetime: payload.requested_datetime
            ? Moment(payload.requested_datetime, "DD-MM-YYYY HH:mm").format(
                "DD MMM YYYY HH:mm",
              )
            : "N/A",
        },
        showUnlockRequestDetailsModal: true,
      });
    getSelectedUnlockRequestError = (error) => requestError(error);

    // create new unlock request
    createUnlockRequest = (confirmationId, reason) =>
      Post(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests`,
        { reason: reason },
        this.createUnlockRequestSuccess,
        this.createUnlockRequestError,
        this.load,
      );
    createUnlockRequestSuccess = (payload) => {
      this.getUnlockRequest(this.props.selected_confirmation_form.id);
      this.getSelectedUnlockRequest(
        this.props.selected_confirmation_form.id,
        payload.id,
      );
      this.setState({ showCreateUnlockRequestModal: false });
      requestSuccess(
        "New unlock confirmation form request created successfully.",
      );
    };
    createUnlockRequestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadUnlock={this.state.loading}
            unlockRequests={this.state.unlockRequests}
            createRequestReason={this.state.createRequestReason}
            showCreateUnlockRequestModal={
              this.state.showCreateUnlockRequestModal
            }
            showUnlockRequestDetailsModal={
              this.state.showUnlockRequestDetailsModal
            }
            selectedUnlockRequest={this.state.selectedUnlockRequest}
            onChangeUnlockHOC={this.onChangeUnlockHOC}
            getUnlockRequest={this.getUnlockRequest}
            createUnlockRequest={this.createUnlockRequest}
            getSelectedUnlockRequest={this.getSelectedUnlockRequest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
