import React from "react";

export default function ElectronicTNCContent() {
  return (
    <>
      <h5
        className={"d-block mb-4 fw-600"}
        style={{ textDecoration: "underline" }}
      >
        PRIVACY POLICY
      </h5>
      <ol className={"esgin-ordered-list"} type={"1"}>
        <li>
          <strong>INTRODUCTION</strong>
          <p>
            IQI is committed to protecting your privacy. Please read this
            Privacy Policy carefully to learn more about how we collect and use
            your information. This Privacy Policy when read together with the
            Terms of Use governs your interactions with IQI and the IQI
            Services.
          </p>
        </li>
        <li>
          <strong>DEFINITIONS</strong>
          <p className={"mb-3"}>
            The following expressions shall, unless the otherwise expressly
            stated, bear the following meaning:
          </p>
          <p>
            <strong>IQI, Our, Us, We </strong> refers to IQI Realty Sdn Bhd and
            its Related Corporation(s)
          </p>
          <p>
            IQI Website means sign.iqiglobal.com and any other websites or other
            web address(es) as may be used and/or adopted by IQI from time to
            time.
          </p>
          <p>
            <strong>IQI Systems</strong> means all online and/or mobile
            platform(s) and any other platforms maintained and operated by IQI
            where IQI provides services primarily: (1) to help consumers buy,
            sell or rent properties; (2) to help sellers and landlords to find
            prospective purchasers/tenants to purchase or rent their properties
            through IQI’s Services; (3) to provide a platform for registered
            real estate agents to publish, modify, and manage their listings in
            furtherance of the purposes as set out in (1) and (2); and (4) to
            generate relevant documents for electronic signing by relevant
            parties.
          </p>
          <p>
            <strong>Non-PII</strong> means any information that cannot be used
            to identify a particular individual.
          </p>
          <p>
            <strong>Personal Data</strong> means any information that either
            jointly with other data or on its own can be used to identify a
            natural person.
          </p>
          <p>
            <strong>Privacy Policy</strong> shall mean this policy and shall
            include all such further and future amendments.
          </p>
          <p>
            <strong>Process, Processing</strong> shall mean any dealings by us
            with the Personal Data including but without limitation to the
            collection, recording, holding, storing, use and disclosure of the
            Personal Data and shall further include, but not be limited to, the
            organisation, adaptation, alteration of the Personal Data, the
            access to, retrieval, consultation or use of the Personal Data, the
            disclosure of the Personal Data by transmission, transfer,
            dissemination or otherwise making available or the alignment,
            combination, correction, erasure or the destruction of the Personal
            Data. <strong>Related Corporation</strong> in relation to any
            corporation means any subsidiary or holding/parent company of that
            corporation or any subsidiary of that holding/parent company, or any
            other affiliated legal entity with whom we are under common
            corporate control, which has agreed to comply with the terms of this
            Privacy Policy.
          </p>
          <p>
            IQI Services means any services and/or products and/or a combination
            of both provided by IQI, our Related Corporation(s), authorised
            agents, authorised affiliated partners or associate and shall
            include our “online” (e.g., IQI’s Website, mobile applications of
            IQI System however accessed and/or used, whether via personal
            computers, mobile devices, tablets or otherwise) and “offline” (e.g,
            collection of data through mailings, telephone, or in person)
            activities owned, operated, provided, or made available by IQI. Any
            references to “IQI Services” in this Privacy Policy shall mean all
            the IQI Services or any of them as the context requires.
          </p>
          <p className={"mt-3"}>
            A reference to <strong>“person(s)”</strong> shall include any
            company, partnership, individual person, firm, joint venture,
            association, corporation, statutory body, unincorporated body of
            persons, trust, bureau, minister, agency, instrument, court,
            regulatory body, government or state, any authority or other body
            corporate and vice versa.
          </p>
          <p>
            A reference to you or the user is a reference to a user of any of
            the IQI Service.
          </p>
        </li>
        <li>
          <strong>HOW DO WE COLLECT YOUR INFORMATION?</strong>
          <p>
            We collect your information in various ways including but not
            limited to those described below. If you wish to stop and/or
            prohibit us from collecting your Personal Data, we will no longer be
            able to provide the relevant IQI Services to you.
          </p>
          <ol type={"a"} className={"mt-3"}>
            <li>
              <strong>During the signing-up/registration process</strong> - any
              personal information provided by you whenever you sign up or
              register for any of the IQI Services or fill in or submit any
              registration forms/questionnaires, survey, agreements and whether
              by way of telephone conversation, online submission, or through
              IQI Website and/or IQI System and/or any other mode of
              telecommunications.
            </li>
            <li>
              <strong>From your use of the IQI Services</strong> – when using
              the IQI Services we may ask you, among other things, to provide
              your Personal Data such as your preferred language, your mobile
              number or other unique device identifier assigned to your mobile
              device, your social security particulars (if available), the image
              of your property, the location of your property, your desired
              selling price (if applicable), your contact particulars, your
              occupation, other information in relation to your property, your
              salary/income. In addition to this Personal Data and in accordance
              with applicable law, we and/or our third-party service providers
              may use a variety of technologies that automatically (or
              passively) collect certain Personal Data and Non-PII whenever you
              visit or interact with the IQI System and/or IQI Website. Such
              information may include the browser that you are using, the IP
              address of your mobile device or other apparatus, areas of IQI
              Services that you visit, time of the day of your visit, your image
              by way of video surveillance logs installed in some of our
              installers’ locations and/or our office as part of our security
              infrastructure (when applicable). In addition, tracking
              information may be collected as you navigate or use IQI Services.
              For users who are using IQI Services and switch on the location
              services, the location of your mobile device/other apparatus and
              movement thereof may be captured to enable us to find a listing
              which is in your vicinity or location preference. You may be
              required to switch on the location, camera services/settings when
              using the IQI Services. We collect this information for various
              purpose including to provide you with support, to send you
              promotions and offers, to notify you when there is a listing which
              you may be interested in in your vicinity, to enhance IQI Services
              and for our internal business purposes.
            </li>
            <li>
              <strong>Use of “Cookies”</strong> - some information will be
              gathered through the use of “cookies”. “Cookies” are small bits of
              information that are automatically stored on a person’s web
              browser. We use cookies to enhance your experience visiting our
              sites and our partner sites, for example, cookies allow IQI to
              save preferences for you so that you will not have to re-enter
              them the next time you visit. Cookies also help us to collect
              anonymous click stream data for tracking user trends and patterns.
              IQI may also use the anonymous clock stream data to help our
              advertisers deliver better-targeted advertisements (if
              applicable). The use of “cookies” is common in most websites. If
              you wish, you may reset your browser either to notify you when you
              have received a cookie, or to refuse to accept cookies. Please
              take note that areas of certain sites will not function properly
              if you set your browser to not accept cookies.
              <br />
              You should be aware that IQI cannot control the use of cookies (or
              the resulting information) by third-parties and use of third-party
              cookies is not covered by this Privacy Policy.
            </li>
            <li>
              <strong>Use of Web Beacons and other Technologies</strong> - small
              graphic images or other web programming code called web beacons
              (also known as “1×1 GIFs” or “clear GIFs”) or other similar
              technologies may be included in our web site and mobile pages and
              messages.  The web beacons are tiny graphics with a unique
              identifier, similar in function to cookies, and are used to track
              the online movements of web users. In contrast to cookies, which
              are stored in a user’s computer hard drive, web beacons are
              embedded invisibly on Web pages. Web beacons or similar
              technologies help us to better manage content on IQI Services by
              informing us what content is effective, count users of IQI
              Services, monitor how users navigate IQI Services, count how many
              e-mails that we send were actually opened or to count how many
              particular articles or links were actually viewed.
            </li>
            <li>
              <strong>Embedded Scripts</strong> - an embedded script is
              programming code that is designed to collect information about
              your interactions with IQI Services, such as the links you click
              on. The code is temporarily downloaded onto your device from our
              web server or a third-party service provider, is active only while
              you are connected to IQI Services and is deactivated or deleted
              thereafter.
            </li>
            <li>
              <strong>Social Media and Widgets</strong> – IQI Services may
              include social media features, such as the Facebook Like button,
              Twitter, Pinterest, Google+, and widgets such as a “Share This”
              button, or interactive mini-programs that run on our online and
              mobile IQI Services. These features may collect your IP address,
              which page you are visiting on our online or mobile IQI Services
              and may set a cookie to enable the feature to function properly.
              Social media features and widgets are either hosted by a third
              party or hosted directly on our online IQI Services. We have no
              control over such linked sites, applications and widgets and we
              cannot accept responsibility for the conduct of such company(ies).
              Your interactions with these features and widgets are governed by
              the privacy policy of the company(ies) providing them. Before
              disclosing your Personal Data on any other website or using these
              applications or widgets we advise you to examine the terms and
              conditions of using that website and the relevant third-party’s
              data collection practices and control in their respective privacy
              policies.
            </li>
            <li>
              <strong>Marketing Communications & Postings</strong> – We may
              collect your Personal Data through direct communications with you
              (including by way of SMS, MMS, any form of text, e-mails,
              telephone conversations and any other mode of telecommunications)
              and when you respond to requests from us (such as contests,
              questionnaires or surveys). We may also collect and process any
              postings/views/comments/opinions from you which you posted on IQI
              Website or on any IQI Service. The Personal Data will be collected
              with your knowledge and consent and is provided by you
              voluntarily. If you do not agree to this information being
              collected and used by us then you should not provide or post it,
              which in turn means that you may not be able to participate in or
              receive the full benefit of such communications, contest, polls,
              questionnaires or surveys.
            </li>
            <li>
              <strong>Non-PII</strong> –Specific or aggregate information may be
              collected to help maintain accurate accounts, assist in marketing
              of our products and services and to help make our websites more
              useful to you. IQI may provide aggregated statistical information
              about user behaviour to reputable third parties. For avoidance of
              doubt, such information collected or collated as part of a
              statistic, is not Personal Data.
            </li>
          </ol>
        </li>
        <li>
          <strong>HOW WE PROCESS PERSONAL DATA</strong>
          <p>
            Your Personal Data may be processed for various purposes, including
            but not limited to the following purposes:
          </p>
          <ol type={"a"} className={"mt-3"}>
            <li>
              to provide the IQI Services to you and for the performance of any
              agreement between you and IQI;
            </li>
            <li>
              to contact you, manage any account you may have with us and to
              promote and market to you (including but not limited to by way of
              direct mail, bill inserts, telemarketing, SMS, MMS, any form of
              text, e-mail and/or any other mode of telecommunications) other
              services or products provided by us or our Related Corporation(s)
              and/or strategic partners, products, services, promotion, special
              offers of third parties whose products and services we think may
              be of interest to you;
            </li>
            <li>to locate you;</li>
            <li>
              to help us to monitor and improve the performance of our services
              including but not limited to our customer relations and other
              service providers etc;
            </li>
            <li>
              to maintain and develop our services, business systems and
              infrastructure, including testing and upgrading of the system;
            </li>
            <li>
              to gain an understanding of you in order for us to enhance our
              services or to improve your overall experience in using IQI
              Services;
            </li>
            <li>
              to conduct research and development and statistical analysis in
              connection with IQI Services and to identify your needs,
              preference and market trend;
            </li>
            <li>
              to investigate and resolve any service issues, complaints or other
              enquiries regarding IQI Services;
            </li>
            <li>to detect and prevent fraudulent or criminal activity;</li>
            <li>
              to comply with any law, rule or regulation to which we are
              subject;
            </li>
            <li>
              to verify your identity and to conduct background checks and/or
              credit worthiness checks on you (if required);
            </li>
            <li>
              for the entry into of a contract or the performance of a contract
              to which you are a party; and
            </li>
            <li>any other uses to which you have explicitly consented; and</li>
            <li>
              such other uses as we may deem reasonably necessary to provide the
              IQI Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>TRANSFER AND STORAGE OF PERSONAL DATA OVERSEAS</strong>
          <p>
            Please note that all of your information may be transferred to and
            maintained on computer networks which may be located outside of the
            state, province, country or other governmental jurisdiction in which
            you reside, and the countries in which these computer networks are
            located may not have privacy laws as protective as the laws in your
            country. When you provide us with your Personal Data, you also
            consent to your Personal Data being transferred overseas.
          </p>
          <p className={"mt-2"}>
            We may also share your Personal Data with our service providers and
            other third parties located outside of the country in which you
            reside so that they can provide you with any products or services
            requested by you, including contacting you in relation to the
            products or services. As these entities are located outside of the
            country in which you reside, the legal framework in relation to
            personal data protection or privacy protection may vary in degree in
            different countries/jurisdictions.
          </p>
        </li>
        <li>
          <strong>DISCLOSURE TO THIRD PARTIES</strong>
          <p>
            Your Personal Data or non-PII may be disclosed to third parties,
            including but without limitation to the following persons:
          </p>
          <ol type={"a"} className={"mt-3"}>
            <li>any of our Related Corporation(s);</li>
            <li>
              any person engaged/contracted/appointed by us (including, but not
              limited to, our employees, workers, staff, shareholders,
              directors, agents, contractors, business or strategic partners or
              associates) to facilitate the performance and provision of IQI
              Services and/or any other purpose which is necessary or related to
              the provision of IQI Services to you;
            </li>
            <li>
              real estate agents or agencies, property developers, builders,
              contractors, retirement community operators, proprietors/owners,
              potential buyers, tenants, valuers, landlords and any other person
              that have listings or advertising on IQI System and which you have
              requested information from by submitting an enquiry or get into
              contact with through IQI System;
            </li>
            <li>
              if you have subscribed to a service through the operating system
              on your mobile device (including, but not limited to Google’s
              “Google Now” service), we will disclose information such as your
              search history to Google. You can opt out of us providing such
              information to Google through the operating system on your mobile
              device;
            </li>
            <li>
              payment channels including but not limited to financial
              institutions;
            </li>
            <li>
              any person for the performance of a contract to which you are a
              party;
            </li>
            <li>
              any person as required by law, rule or regulation to which we are
              subject;
            </li>
            <li>
              any person for the assessment or collection of any tax or duty or
              any other imposition of a similar nature;
            </li>
            <li>
              any person for the purpose of responding to services or technical
              issues or marketing activities;
            </li>
            <li>
              our professional advisors on a need to know basis for the purpose
              of those advisors providing advice to us;
            </li>
            <li>
              any person which acquires all or part of the assets or business of
              IQI;
            </li>
            <li>
              any person when there is a complaint, dispute or conflict,
              including an accident;
            </li>
            <li>
              any person in order to enforce the terms and conditions governing
              the agreement between you and IQI or terms of use governing your
              use of IQI Services;
            </li>
            <li>
              any person if it is necessary, in our sole discretion, to protect
              the safety, rights, property or security of IQI, IQI Services or
              any third party;
            </li>
            <li>
              any person in order to detect, prevent or otherwise address fraud,
              security or technical issues and/or to prevent or stop any
              activity which in our sole discretion is deemed to be illegal,
              unethical, unlawful, or poses a serious risk to the society in
              general; and
            </li>
            <li>otherwise as permitted under the applicable law.</li>
          </ol>
        </li>
        <li>
          <strong>YOUR OBLIGATIONS</strong>
          <p>
            You are responsible for updating your Personal Data with us and you
            hereby confirm that all Personal Data furnished to us is true,
            accurate, complete, not misleading and kept up-to-date at all time.
            You shall immediately report and notify us in the event of any error
            or necessary update in the Personal Data which you have submitted
            and/or provided to us and/or otherwise collected by us whether
            through your usage of IQI Services or otherwise.
          </p>
          <p>
            If you do not wish to receive certain information from us whether
            via SMS, MMS, or any form of text, e-mail or otherwise you shall
            notify us and request to be excluded from such services, failing
            which you shall deemed to have accepted the services voluntarily.
          </p>
          <p>
            We shall not be responsible for any Personal Data made available by
            you on any third-party sites which may have a link on an IQI
            Website.
          </p>
        </li>
        <li>
          <strong>SECURITY</strong>
          <p>
            We take reasonable precautionary measures to safeguard your Personal
            Data against loss, theft, unauthorised access or use. However,
            please note that we cannot guarantee the security of your Personal
            Data and we do not assume or accept any liability for any improper
            access or use of it or any theft or loss of it.
          </p>
        </li>
        <li>
          <strong>YOUR DECLARATION</strong>
          <p>
            BY AGREEING TO THIS PRIVACY POLICY AND/OR BY REGISTERING FOR OR
            CONTINUING TO USE THE IQI SERVICES, YOU ACCEPT AND AGREE TO YOUR
            PERSONAL DATA BEING COLLECTED, PROCESSED AND DISCLOSED BY US AS SET
            OUT IN THIS PRIVACY POLICY. YOU ALSO EXPRESSLY CONSENT TO IQI’S USE
            OF LOCATION-BASED SERVICES.
          </p>
          <p>
            PLEASE TAKE NOTE THAT YOU MAY REFUSE TO PROVIDE US WITH ANY PERSONAL
            DATA AND TO TERMINATE YOUR RELEVANT IQI SERVICES AGREEMENT WITH US
            AT ANY TIME.
          </p>
        </li>
        <li>
          <strong>RELEASE OF LIABILITY</strong>
          <p>
            You waive and release IQI from any and all liability, claims, causes
            of action, losses, expenses or damages arising from your use of the
            IQI Services.
          </p>
        </li>
        <li>
          <strong>YOUR RIGHTS</strong>
          <ul style={{ marginTop: 5, paddingInlineStart: 20 }}>
            <li>
              <strong>Right to be informed & right to access:</strong> You are
              entitled to make a written request to us for information of the
              Personal Data that is being processed by or on behalf of IQI upon
              payment of a prescribed fee. You may also request for a copy of
              the Personal Data upon payment of a prescribed fee.
            </li>
            <li>
              <strong>Right to correct your Personal Data:</strong> You may also
              request for correction and/or modification in relation to any of
              your Personal Data. If you are concerned with the confidentiality
              of any information given or you wish to correct any Personal Data
              provided by you to us, kindly contact us at pdpa@iqiglobal.com.
            </li>
            <li>
              <strong>
                Right to prevent processing for direct marketing purpose:
              </strong>{" "}
              If you do not wish to receive certain information from us whether
              via SMS, MMS, or any form of text, e-mail or otherwise you shall
              notify us at pdpa@iqiglobal.com and request to be excluded from
              such services or click the “unsubscribe” link at the bottom of any
              marketing email, failing which you shall deemed to have accepted
              the services.
            </li>
            <li>
              <strong>
                Right to prevent processing of data likely to cause distress &
                right to withdraw consent:
              </strong>{" "}
              You are entitled to inform us in writing should you wish us to
              stop processing your data but we may not be able to provide the
              IQI Services to you.
            </li>
          </ul>
        </li>
        <li>
          <strong>AMENDMENTS TO THIS PRIVACY POLICY</strong>
          <p>
            IQI reserves all rights to amend, modify, vary, terminate, delete
            and/or add any part or all of this Privacy Policy at any time and
            from time to time and any notice in relation to any such amendment,
            modification, variation, termination, deletion and/or addition shall
            be posted on the IQI Website. Your continued usage of any IQI
            Service after being notified of any changes to this Privacy Policy
            shall be deemed to be an agreement by you to such changes. If you do
            not agree to any such changes, you may terminate any agreement
            between you and IQI in relation to your use of the IQI Services.
          </p>
        </li>
      </ol>
      <hr />
      <h5
        className={"d-block mb-4 fw-600"}
        style={{ textDecoration: "underline" }}
      >
        TERMS OF USE
      </h5>
      <ol className={"esgin-ordered-list"}>
        <li>
          <strong>INTRODUCTION</strong>
          <p>
            Please read these terms and conditions carefully as they contain
            important information about your rights and obligations when using
            the IQI Website and/or the IQI Services. These terms of use and our
            Privacy Policy form the basis on which you may access and use the
            IQI Services including the IQI Website.
          </p>
        </li>
        <li>
          <strong>DEFINITIONS</strong>
          <p className={"mb-3"}>
            The following expressions shall, unless the otherwise expressly
            stated, have the following meaning:
          </p>
          <p>
            <strong>Agreement</strong> shall have the meaning set out in clause
            3(a) below.{" "}
          </p>
          <p>
            <strong>Confidential Information</strong> means any information,
            whether oral or written, relating to the business of IQI and the
            existence and contents of any Agreement and the fact that any
            discussions are taking place in relation to any Agreement.
          </p>
          <p>
            <strong>IQI, Our, Us, We</strong> refers to IQI Realty Sdn Bhd and
            its Related Corporation(s).
          </p>
          <p>
            <strong>IQI Website</strong> means sign.iqiglobal.com and any other
            websites or other web address(es) as may be used and/or adopted by
            IQI from time to time.
          </p>
          <p>
            <strong>IQI Systems</strong> means all online and/or mobile
            platform(s) and any other platforms maintained and operated by IQI
            where IQI provides services primarily: (1) to help consumers buy,
            sell or rent properties; (2) to help sellers and landlords to find
            prospective purchasers/tenants to purchase or rent their properties
            through IQI’s Services; (3) to provide a platform for registered
            real estate agents to publish, modify, and manage their listings in
            furtherance of the purposes as set out in (1) and (2); and (4) to
            generate relevant documents for electronic signing by relevant
            parties.
          </p>
          <p>
            <strong>Party</strong> means a party to an Agreement.
          </p>
          <p>
            <strong>Related Corporation</strong> in relation to any corporation
            means any subsidiary or holding/parent company of that corporation
            or any subsidiary of that holding/parent company, or any other
            affiliated legal entity with whom we are under common corporate
            control, which has agreed to comply with the terms of this Privacy
            Policy.
          </p>
          <p>
            <strong>IQI Services</strong> means any services and/or products
            and/or a combination of both provided by IQI, our Related
            Corporation(s), authorised agents, authorised affiliated partners or
            associate and shall include our “online” (e.g., IQI’s Website,
            mobile applications of IQI System however accessed and/or used,
            whether via personal computers, mobile devices, tablets or
            otherwise) and “offline” (e.g, collection of data through mailings,
            telephone, or in person) activities owned, operated, provided, or
            made available by IQI. Any references to “IQI Services” in the Terms
            shall mean all the IQI Services or any of them as the context
            requires.
          </p>
          <p className={"mt-3"}>
            A reference to <strong> “person(s)”</strong> shall include any
            company, partnership, individual person, firm, joint venture,
            association, corporation, statutory body, unincorporated body of
            persons, trust, bureau, minister, agency, instrument, court,
            regulatory body, government or state, any authority or other body
            corporate and vice versa.
          </p>
          <p>
            A reference to you or the user is a reference to a user of any of
            the IQI Services.
          </p>
        </li>
        <li>
          <strong>AGREEMENT</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              The IQI Website is intended to generate relevant documents for
              signing by relevant parties in connection with the IQI Services
              (the <strong>Agreements</strong> ).
            </li>
            <li>
              We recommend that you seek independent professional, legal,
              financial or real estate advice before signing any Agreement.
            </li>
            <li>
              By using the IQI Website or otherwise accessing the IQI Services,
              you hereby agree to be bound by the terms and conditions contained
              in these Terms of Use and our Privacy Policy (the{" "}
              <strong>Terms</strong> ). If you do not agree with these Terms,
              you should stop using the IQI Website or the IQI Services
              immediately.
            </li>
            <li>
              These Terms of Use read together with the Privacy Policy shall
              constitute a valid and binding agreement made between you and IQI.
            </li>
            <li>
              We reserve all rights to change any or all of these Terms of Use
              at any time and we will post the updated and new Terms of Use on
              the IQI Website. Your continued use of the IQI Website and/or IQI
              Services after the posting of any amended Terms of Use shall
              constitute your agreement to be bound by any such changes.
            </li>
          </ol>
        </li>
        <li>
          <strong>USER WARRANTIES, ACKNOLWEDGEMENTS AND UNDERTAKINGS</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              <p>
                By using the IQI Website and/or the IQI Services, you hereby
                acknowledge, represent and warrant that:
              </p>
              <ol type={"i"}>
                <li>you are at least eighteen (18) years of age;</li>
                <li>you are at least eighteen (18) years of age;</li>
                <li>
                  you have all necessary authority, rights and permissions to
                  enter into any Agreement; and
                </li>
                <li>
                  you have sought or will seek independent advice before entry
                  into any Agreement.
                </li>
              </ol>
            </li>
            <li>
              You represent and warrant that you have read and fully understood
              all the terms and conditions contained herein and the Privacy
              Policy.
            </li>
            <li>You undertake to fully comply with the Terms.</li>
            <li>
              You undertake to ensure that the mobile phone/device and/or
              computer used by you to access the IQI Website and/or IQI Services
              is legally owned, possessed or used by you at all times and you
              undertake not to allow any person other than you to access or
              manipulate the IQI Website using your mobile phone, device and/or
              computer. If an Agreement is signed by a mobile phone/device
              and/or computer which is linked to you, you will be deemed to have
              entered into the Agreement.
            </li>
            <li>
              You undertake to only use the IQI Website for lawful purposes and
              the purpose for which it is intended.
            </li>
            <li>
              You acknowledge that all software programming contained in the IQI
              Website and/or the IQI Services (the <strong>Software</strong> )
              is owned by IQI and that any unauthorised access to, reproduction,
              redistribution, publication, display or other use of the Software
              would infringe the intellectual property rights of IQI.
            </li>
            <li>
              You undertake not to: (i) modify, translate, create or attempt to
              create derivative copies of or copy of the Software; (ii) reverse
              engineer, decompile, disassemble or otherwise reduce the object
              code of the IQI Website: (iii) create any unauthorised downloads
              or copies of the Agreement; and (iv) use the IQI Website in any
              manner which would infringe the intellectual property rights of
              IQI.
            </li>
            <li>
              You acknowledge that the trade mark(s), service mark(s), trade
              name(s), logo(s), symbol(s), brand name(s) and other proprietary
              mark or any combination of the aforesaid (the{" "}
              <strong>Marks</strong> ) contained on or in the IQI Website and/or
              the IQI Services (save and except for any marks of advertisers)
              are owned by IQI. You undertake not to use, copy, edit, vary,
              reproduce, publish, display, distribute, store, transmit,
              translate, commercially exploit or disseminate any Marks without
              the prior written consent of IQI. You further undertake never to
              claim ownership or rights to the Marks.
            </li>
            <li>
              You acknowledge that the IQI Website or the IQI Services may
              contain links to websites operated by third parties{" "}
              <strong>(Third Party Websites)</strong>. You further acknowledge
              that IQI does not have any influence or control over the Third
              Party Websites and is not responsible for such Third Party
              Websites. You are advised to check the terms of use and privacy
              policies of such Third Party Websites.
            </li>
            <li>
              You acknowledge that the IQI Website and/or IQI Services are
              provided on a reasonable efforts basis only and that IQI shall not
              be liable for any unavailability or malfunction of the IQI Website
              or IQI Services for any reason.
            </li>
          </ol>
        </li>
        <li>
          <strong>INDEMNITY</strong>
          <p>
            You agree to fully indemnify, release, defend and hold IQI, its
            officers, directors, employees, and representatives harmless from
            and against all claims, demands, lawsuits, liability, loss,
            judgments or other expenses (including, but not limited to, legal
            fees) suffered or incurred by IQI arising, directly or indirectly,
            from: (i) your use of (or failure to use) the IQI Website and/or the
            IQI Services; (ii) any reliance on the contents of the IQI Website
            or the IQI Services; (ii) your breach of the Terms; and (iii) your
            unauthorised use of the Software.
          </p>
        </li>
        <li>
          <strong>IQI RIGHTS</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              IQI may modify, suspend, discontinue or restrict the use of any
              portion of the IQI Website and/or the IQI Services without notice
              or liability to you.
            </li>
            <li>
              IQI has the absolute discretion and right to reject any person
              from using the IQI Website and/or IQI Services or any part
              thereof.
            </li>
            <li>
              IQI reserves the right not to permit you access to the IQI Website
              or the IQI Services should your device not be compatible or
              authorised.
            </li>
          </ol>
        </li>
        <li>
          <strong>IQI DISCLAIMERS</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              We do not warrant that the IQI Website or the IQI Services or the
              content therein will be available and accessible to you at all
              times.
            </li>
            <li>
              We make no warranty as to the accuracy of the Agreement. You must
              check the terms of the Agreement yourself to ensure they reflect
              your understanding of any agreement you have reached.
            </li>
            <li>
              Once you have executed the Agreement, you are deemed to have
              accepted the terms and conditions of such Agreement. Any failure
              by the IQI Website to circulate a fully executed copy of the
              Agreement to any Party shall not invalidate the Agreement.
            </li>
            <li>
              We make no warranty as to accuracy or reliability of any
              information, advice, opinion, statement of any content on the IQI
              Website or the IQI Services.
            </li>
            <li>
              The IQI Website facilitates the execution of the Agreements
              between the Parties. Nothing contained in the IQI Website or the
              IQI Services shall be construed as an inducement to enter into an
              Agreement.
            </li>
            <li>
              IQI and our directors, officers and agents disclaim all liability
              and responsibility for any direct or indirect loss or damage which
              may be suffered by you through relying on anything contained in or
              omitted from the IQI Website or the IQI Services.
            </li>
            <li>
              You expressly agree that use of the IQI Website and the IQI
              Services and reliance on its content is at your own risk. IQI does
              not make any representations or warranties of any kind regarding
              the IQI Website and/or the IQI Services, the Software, the
              content, the terms of the Agreements or the results that may be
              obtained from use of any of the foregoing. The IQI website and the
              IQI Services are provided on an "as is, as available" basis, and
              IQI specifically disclaims any and all express or implied
              warranties.
            </li>
            <li>
              All warranties, representations, guarantees, conditions and terms
              other than those expressly set out in these Terms of Use, whether
              express or implied by statute, common law, trade usage or
              otherwise, and whether written or oral, are hereby, to the fullest
              extent permitted by law, expressly excluded from these Terms of
              Use.
            </li>
          </ol>
        </li>
        <li>
          <strong>LIMITATION OF LIABILITY</strong>
          <p>
            IQI and its respective directors, employees, agents and contractors
            shall in no event be liable for any damages or losses including,
            without limitation, direct, indirect, consequential, special,
            incidental or punitive damages, or any loss of profit, loss of
            opportunity, loss of goodwill, loss of anticipated saving, loss of
            revenue and/or any loss resulting from or caused by the IQI Website,
            the IQI Services (and the content therein), the Software or these
            Terms (including, without limitation, damages resulting from
            negligence) and/or any suspension, cancellation or termination or
            refusal of your use of the IQI Website, the IQI Services or any
            content or material thereof.
          </p>
        </li>
        <li>
          <strong>CONFIDENTIALITY</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              You agree to keep the Confidential Information confidential and
              not to disclose it to any third parties (other than your
              professional advisors who are advising you in connection with the
              Agreement) without the prior written consent of IQI.
            </li>
            <li>
              IQI shall keep the Confidential Information confidential but it
              may disclose such Confidential Information to its Related
              Corporations and its directors, agents, employees, advisors,
              shareholders, banks, investors or any third party who acquires the
              business or assets of IQI in whole or in part.
            </li>
          </ol>
        </li>
        <li>
          <strong>MISCELLANEOUS</strong>
          <ol type={"a"} className={"mt-3"}>
            <li>
              These Terms of Use, and any non-contractual obligations arising
              out of or in connection with them, shall be governed and construed
              by the laws of Malaysia. Any dispute arising out of or in
              connection with these Terms of Use (including as to the existence,
              validity, interpretation, performance, breach or termination
              thereof) or any dispute regarding non-contractual obligations
              arising out of or in connection with the Terms of Use will be
              subject to the exclusive jurisdiction of the Malaysian Courts.
            </li>
            <li>
              Any term, condition, provision or undertaking in these Terms of
              Use which is illegal, void, prohibited or unenforceable shall be
              ineffective to the extent of such illegality, voidness,
              prohibition or unenforceability, without invalidating the
              remaining provisions hereof, and any such illegality, voidness,
              prohibition or unenforceability shall not invalidate or render
              illegal, void, or unenforceability any other term, condition,
              provision or undertaking herein contained.
            </li>
            <li>
              Failure by IQI to enforce any provision of these Terms of Use will
              not be deemed a waiver of future enforcement of that or any other
              provision of these Terms of Use.
            </li>
            <li>
              Neither these Terms of Use, nor any terms and conditions contained
              herein, shall be construed as creating a partnership, joint
              venture or agency relationship or granting a franchise between you
              and IQI.
            </li>
            <li>
              IQI may assign or novate all or part of our rights under this
              agreement provided we ensure that your rights under this agreement
              are not prejudiced. You may not assign or novate your rights or
              liabilities under these Terms without our prior written consent.
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}
