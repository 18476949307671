import React, { Component } from "react";
import _ from "lodash";
import { Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedTraining: {},
      showConfirmationModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRegisterHOC = (val, context) => this.setState({ [context]: val });

    registerTraining = (id, eventId) =>
      Post(
        `/trainings/register?event_id=${eventId}&meeting_id=${id}`,
        { event_id: eventId },
        this.registerTrainingSuccess,
        this.registerTrainingError,
        this.load,
      );
    registerTrainingSuccess = (payload) => {
      this.props.getMyTraining();
      this.props.getUpcomingTraining();
      requestSuccess(payload.message);
    };
    registerTrainingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadRegisterTraining={this.state.loading}
            selectedTraining={this.state.selectedTraining}
            showConfirmationModal={this.state.showConfirmationModal}
            registerTraining={this.registerTraining}
            onChangeRegisterHOC={this.onChangeRegisterHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
