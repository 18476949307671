import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import EditProject from "./Edit";
import DetailsContent from "./Details";
import AtPaginatedTable from "../ProjectTable";

import ProjectDetailsHOC from "./actions/details";
import ProjectHOC from "./actions/index";

import permissionsChecker from "utils/permissionsChecker";

class ProjectContent extends Component {
  componentDidMount = () =>
    this.props.getProjects(this.props.selectedPresentation.id, 1, "");

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.estatetProjectIndexSearchParams);
    let countryIndex = _.findIndex(temp, {
      value: "estate_of_Project_type_country_id_eq",
    });
    let stateIndex = _.findIndex(temp, {
      value: "estate_of_Project_type_state_id_eq",
    });

    if (
      pp.estatetProjectIndexSearchParams[countryIndex].param !==
      temp[countryIndex].param
    ) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: temp[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      this.props.onChangeProjectHOC(temp, "estatetProjectIndexSearchParams");
    }
  };

  onClickCard = (estateId) => {
    this.props.getSelectedProject(estateId);
    this.props.getVideos(estateId);
    this.props.getDocuments(estateId);
    this.props.getVisualisations(estateId);
  };

  render = () => {
    const {
      data,
      projects,
      projectPages,

      showProjectDetails,
      showProjectEditModal,
      showProjectRemoveConfirmation,
      selectedPresentation,
      selectedProject,
      estatetProjectIndexSearch,
      estatetProjectIndexSearchParams,

      onLoadProject,
      onLoadProjectDetails,
      onLoadPresentation,
    } = this.props;
    const {
      currentPage: estateProjectPage = 1,
      searchParams: estateProjectSearchParams = "",
    } = estatetProjectIndexSearch;

    const { can_create, can_destroy } = permissionsChecker(
      "Presentation Centre",
      data,
    );

    return (
      <>
        <div className={"d-flex align-items-center flex-wrap grid_gap-1"}>
          <ModuleFormHeader
            title={"Project Management"}
            moduleIcon={"atlas-building-4"}
          />
          {can_create && (
            <CustomButton
              className={"btn-new btn-new--primary ml-3"}
              onClick={() =>
                this.props.onChangeProjectHOC(true, "showProjectEditModal")
              }
              children={
                <>
                  <IoMdAdd style={{ height: 18, width: 18, color: "#FFFF" }} />
                  Add Project
                </>
              }
            />
          )}
        </div>
        <p className="fs-2 mt-10">
          These are the projects that will be shows as part of this
          presentation. Use the add and remove buttons to add a new project or
          remove one from your presentation. Click on the info icon to have a
          quick look at the project.
        </p>
        <div className="at-heading_card_table">
          <AtPaginatedTable
            actionsColumns={["details", ...(can_destroy ? ["remove"] : [])]}
            onShowRemoveIcon={"estate_id"}
            onClickDetails={(val) => this.onClickCard(val.estate_id)}
            customEmptyState={{
              title: "You don't have any projects yet",
              description: (
                <>
                  Click <b>New Project</b> to create project
                </>
              ),
            }}
            onClickRemove={(val) => {
              Promise.all([
                this.props.onChangeProjectHOC(
                  true,
                  "showProjectRemoveConfirmation",
                ),
              ]).then(() => {
                this.props.onChangeProjectDetailsHOC(val, "selectedProject");
              });
            }}
            rowData={projects.data || []}
            meta={projects.meta && projects.meta}
            searchParams={[]}
            onChangeSearchParams={(val) =>
              this.props.onChangeProjectHOC(
                val,
                "estatetProjectIndexSearchParams",
              )
            }
            getListAPI={(page, search) =>
              this.props.getProjects(
                this.props.selectedPresentation.id,
                page,
                search,
              )
            }
            totalPages={projectPages}
          />
        </div>
        {showProjectEditModal && (
          <ModalDialog
            title={"Select from Project Centre"}
            onLoad={onLoadPresentation || onLoadProject}
            fullWidth={true}
            fullHeight={true}
            children={
              <EditProject
                onLoadPresentation={onLoadPresentation}
                onLoadProject={onLoadProject}
                getProjects={() =>
                  this.props.getProjects(
                    this.props.selectedPresentation.id,
                    estateProjectPage,
                    estateProjectSearchParams,
                  )
                }
                getSelectedPresentation={this.props.getSelectedPresentation}
                getPresentation={this.props.getPresentation}
                selectedPresentation={selectedPresentation}
                removeProject={this.props.removeProject}
              />
            }
            onClose={() =>
              this.props.onChangeProjectHOC(false, "showProjectEditModal")
            }
          />
        )}
        {showProjectDetails && (
          <ModalDialog
            title={"Project Details"}
            fullWidth={true}
            fullHeight={true}
            children={
              <DetailsContent
                selectedProject={this.props.selectedProject}
                documents={this.props.documents}
                onDownloadFile={this.props.onDownloadFile}
                visualisations={this.props.visualisations}
                videos={this.props.videos}
              />
            }
            onClose={() =>
              this.props.onChangeProjectDetailsHOC(false, "showProjectDetails")
            }
          />
        )}
        <AtlasConfirmationDialog
          open={showProjectRemoveConfirmation}
          loading={onLoadProject}
          mode={"alert"}
          message={"Are you sure to delete the selected project ?"}
          positiveAction={() =>
            this.props.removeProject(
              selectedPresentation.id,
              selectedProject.estate_id,
            )
          }
          negativeAction={() =>
            this.props.onChangeProjectHOC(
              false,
              "showProjectRemoveConfirmation",
            )
          }
        />
        {(onLoadProject || onLoadProjectDetails) && <LoadingModal />}
      </>
    );
  };
}

export default compose(ProjectDetailsHOC, ProjectHOC)(ProjectContent);
