import React, { Component } from "react";
import _ from "lodash";
import { Grid, Card } from "@material-ui/core";
import { MdLiveHelp } from "react-icons/md";

import CustomButton from "components/Button";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import PartyCard from "components/Card/partyCard";
import AlertBox from "components/AlertBox";
import ESigningContent from "./history";
import ESigningTips from "./tips";
import ProcessSection from "./process";

class EprocessContent extends Component {
  state = {
    showEsigningTipsModal: false,
  };

  renderPendingProcessContent = () => {
    const { eProcessPartyData, renderNavButton } = this.props;

    return (
      <>
        {eProcessPartyData.length > 0 && (
          <div className="party-card_cont mb-3">
            {eProcessPartyData.map((partyData, index) => (
              <PartyCard
                key={index}
                containerClass={"party-card-new"}
                type={"authorisation"}
                index={index}
                data={partyData}
                can_create={true}
              />
            ))}
            <div
              className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
              {renderNavButton()}
            </div>
          </div>
        )}
        {eProcessPartyData.length < 1 && (
          <AlertBox
            title={""}
            containerStyle={{ marginBottom: 10 }}
            description={`Please add the contract participants for each section before start the e-Signing process`}
          />
        )}
      </>
    );
  };

  renderInitiatedProcessContent = () => {
    const {
      signingStatus,
      selectedAuthorisationForm,

      eProcessPartyData,
      downloadCompletedForm,
      renderNavButton,
    } = this.props;

    let temp = _.filter(eProcessPartyData, (item) => item.status_id === 2);
    let tempPercentage = eProcessPartyData.length > 0 ? ((temp.length / eProcessPartyData.length) * 100) : 100;

    const { e_process } = selectedAuthorisationForm;
    const { status_id = 0 } = e_process ?? {}
    
    return (
      <>
        <Grid container spacing={16}>
          <Grid item md={12} xs={12}>
            <div className="d-flex flex-wrap grid_gap-1">
              {status_id === 2 && (
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    downloadCompletedForm(
                      selectedAuthorisationForm.id,
                      `${selectedAuthorisationForm.transaction_number}_e_process.pdf`,
                    )
                  }
                >
                  {`Download Authorisation Form`}
                </button>
              )}
              {status_id === 1 && (
                <button
                  className={"btn-new btn-new--secondary"}
                  onClick={() => {
                    this.props.getParty(this.props.selectedAuthorisationForm.id);
                    this.props.getSigningStatus(this.props.selectedAuthorisationForm.id);
                  }}
                >
                  Check Status
                </button>
              )}
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <ProcessSection
              status={signingStatus}
              tempPercentage={tempPercentage}
              selectedAuthorisationForm={selectedAuthorisationForm}
              eProcessPartyData={eProcessPartyData}
              onClickViewActivity={() =>
                this.props.onChangePartyHOC(true, "showSigningHistory")
              }
              onClickRefresh={() => {
                this.props.getParty(this.props.selectedAuthorisationForm.id);
                this.props.getSigningStatus(this.props.selectedAuthorisationForm.id);
              }}
            />
            <div className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
              {renderNavButton()}
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  renderModalContent = () => {
    const {
      onChangePartyHOC,

      selectedAuthorisationForm,
      showSigningHistory,
    } = this.props;
    return (
      <AtlasDialog open={showSigningHistory}>
        {/* Signing History */}
        <CustomCard
          className={"h-100"}
          cardContent={
            <ESigningContent
              formId={selectedAuthorisationForm.id}
              onChangePartyHOC={onChangePartyHOC}
            />
          }
        />
      </AtlasDialog>
    );
  };

  render = () => {
    const {
      onLoadParty,
      onLoadDraftEmail,
      onLoadAuthorisation,

    eProcessContentId,
    } = this.props;

    return (
      <>
        <CustomButton
          containerClass={"mb-3"}
          className={"at-tip__button"}
          children={
            <>
              <MdLiveHelp />
              e-Signing Process Explain
            </>
          }
          onClick={() => this.setState({ showEsigningTipsModal: true })}
        />
      {eProcessContentId === 1 && this.renderPendingProcessContent()}
      {eProcessContentId === 2 && this.renderInitiatedProcessContent()}
        {this.state.showEsigningTipsModal && (
          <ESigningTips
            onClose={this.setState({ showEsigningTipsModal: true })}
          />
        )}
        {(onLoadAuthorisation || onLoadParty || onLoadDraftEmail) && (
          <LoadingModal />
        )}

        {/* ConfirmationModal */}
        {this.renderModalContent()}
      </>
    );
  };
}

export default EprocessContent;
