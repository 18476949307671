import _ from "lodash";
import React, { Component } from "react";
import { Dialog } from "@material-ui/core";

import "stylesheets/components/topbar/index.scss";

class TopMenuModal extends Component {
  onToggleDarkMode = () => {
    const temp = _.cloneDeep(this.props.appSetting);
    temp.darkMode = !temp.darkMode;
    this.props.updateAppSetting(temp);
  };

  render = () => {
    const tmpPath = window.location.href.includes("/admin-impersonate")
      ? this.props.prefix_path
      : "";

    return (
      <Dialog
        style={{ background: "transparent" }}
        classes={{
          container: "at-topbar__dropbear--modal-root",
          paper: "at-topbar__dropbear-paper",
        }}
        BackdropProps={{
          classes: { root: `at-topbar__dropbear-backdrop` },
        }}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div
          className={"at-topbar__dropbear--list"}
          style={{ minWidth: 300, maxWidth: 300 }}
        >
          <button
            className={"justify-content-between"}
            onClick={() => {
              this.props.onClose();
              this.props.history.push(`${tmpPath}/dashboard/profile`);
            }}
          >
            Profile
          </button>
          <button
            className={"justify-content-between"}
            onClick={() => {
              this.props.onClose();
              this.props.history.push(`${tmpPath}/dashboard/settings`);
            }}
          >
            Settings
          </button>
          {/* For Future Revamp. Dun remove */}
          {/* <button className={ 'justify-content-between align-items-center' }>
            Dark Mode
            <IOSButton
              className={ 'ml-2' }
              currentState={ !this.props.appSetting.darkMode }
              onToggleButton={ () => this.onToggleDarkMode() } />
          </button> */}
          <button
            id={"btn-logout"}
            className={"justify-content-between"}
            onClick={() => this.props.onClickSignOut()}
          >
            Log out
          </button>
        </div>
      </Dialog>
    );
  };
}

export default TopMenuModal;
