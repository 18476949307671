import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Agent Name",
    value: "agent_registration_user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "agent_registration_user_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Mobile Number",
    value: "agent_registration_user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Registration Status",
    value: "agent_registration_status_id_eq",
    type: "select",
    param: "",
    options: [
      { value: "0", label: "Pending" },
      { value: "1", label: "Payment Made" },
      { value: "2", label: "Waiting Approval" },
      { value: "3", label: "Signing" },
      { value: "4", label: "Approved" },
    ],
  },
  {
    label: "Approval Status",
    value: "status_id_eq",
    type: "select",
    param: "",
    options: [
      { value: "1", label: "Pending" },
      { value: "2", label: "Approved" },
      { value: "3", label: "Rejected" },
      { value: "4", label: "Referrer Transfer" },
    ],
  },
];

const TabActions = [
  {
    label: "Approve selected new REN",
    modalProps: "showConfirmationModal",
  },
  {
    label: "Reject selected new REN",
    modalProps: "showRejectDialog",
  },
];

const AdminApprovalHOC = (WrappedComponent) => {
  class AdminApprovalWrappedComponent extends Component {
    state = {
      selectedUsers: [],
      users: [],
      totalPages: [],
      totalCheckable: 0,
      currentPaymentProof: "",
      showConfirmationModal: false,
      showResetConfirmation: false,
      showRecruitmentConfirmation: false,
      showRejectDialog: false,
      showReassignModal: false,
      showDetailsModal: false,
      showPaymentdetail: false,
      showEditProfileModal: false,

      loading: false,
      selectedTab: "In Progress",
      searchParams: searchParams,
      detailData: {},
      profileData: window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer,

      tabActions: [],
      paymentDetail: [],
    };

    componentDidMount = () => {
      this.setState({
        tabActions: [
          ...TabActions,
          ...(this.state.profileData.role === "Super Admin"
            ? [
                {
                  label: "Reassign Admin",
                  modalProps: "showReassignModal",
                },
              ]
            : []),
        ],
      });
    };

    load = (param) => this.setState({ loading: param });

    onClickSelectAll = () => {
      if (this.state.selectedUsers.length !== this.state.totalCheckable) {
        if (this.state.users.data && this.state.users.data.length > 0) {
          let tmp = [];
          this.state.users.data.map((item) => {
            if (
              (item.user_id === this.state.profileData.id ||
                this.state.profileData.role === "Super Admin") &&
              item.status_id === 1
            ) {
              tmp.push(item.id);
            }
          });
          this.setState({ selectedUsers: tmp });
        }
      } else {
        this.setState({ selectedUsers: [] });
      }
    };

    onClickCheckBox = (param) => {
      let tmp = _.cloneDeep(this.state.selectedUsers);
      if (this.state.selectedUsers.indexOf(param) > -1) {
        tmp.splice(this.state.selectedUsers.indexOf(param), 1);
      } else {
        tmp.push(param);
      }
      this.setState({ selectedUsers: tmp });
    };

    onChangeApprovalHOC = (val, context) => this.setState({ [context]: val });

    getPendingNewREN = (page, search) => {
      let query = "";

      if (search) {
        this.state.searchParams?.map((item) => {
          query =
            item.param && item.param !== ""
              ? item.value
                ? query + `q[${item.value}]=${item.param}&`
                : query + `q${item.param}&`
              : query;
        });
      }

      if (query?.includes("agent_registration_status_id_eq")) {
        this.setState({ selectedTab: "" });
      } else if (this.state.selectedTab === "Completed") {
        query =
          query +
          "q[agent_registration_status_id_in][]=4&q[agent_registration_status_id_in][]=5&";
      } else {
        this.setState({ selectedTab: "In Progress" });
        query =
          query +
          "q[agent_registration_status_id_not_in][]=4&q[agent_registration_status_id_not_in][]=5&";
      }

      Get(
        `/admin/registrations/pending_approvals?${query}page=${page}`,
        (payload) => this.getPendingNewRENSuccess(payload, page, query),
        this.getPendingNewRENFailed,
        this.load,
      );
    };
    getPendingNewRENSuccess = (payload, page, search) => {
      const { id: profileId, role } = this.state.profileData;

      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      let tempSelected = null;
      let tmpPages = [];
      let tempData = [];
      let tmpTotalCheckable = 0;

      if (payload.meta.pages && payload.meta.pages > 0) {
        for (let i = 0; i < payload.meta.pages; i++) {
          tmpPages.push(i);
        }
      }
      payload.data.map((i) => {
        if (
          (i.user_id === profileId || role === "Super Admin") &&
          i.status_id === 1
        ) {
          tmpTotalCheckable++;
        }
      });

      payload.data.map((item) => {
        let tempProcess = {
          ...item,
          tempEmployeeNumber: "",
          via_recruitment_campaign: item.via_recruitment_campaign
            ? "Yes"
            : "No",
        };
        tempData.push(tempProcess);

        if (
          this.state.showDetailsModal &&
          item.id === this.state.detailData.id
        ) {
          tempSelected = tempProcess;
        }
      });
      this.setState({
        selectedUsers: [],
        totalCheckable: tmpTotalCheckable,
        users: {
          data: tempData,
          meta: payload.meta,
        },
        totalPages: tmpPages,
        ...(tempSelected && {
          detailData: tempSelected,
        }),
      });
    };
    getPendingNewRENFailed = (error) => requestError(error);

    getSelectedPendingNewREN = (id) =>
      Get(
        `/admin/registrations/${id}`,
        this.getSelectedPendingNewRENSuccess,
        this.getSelectedPendingNewRENError,
        this.load,
      );
    getSelectedPendingNewRENSuccess = (payload) => {
      this.setState({
        detailData: {
          ...payload,
          via_recruitment_campaign: payload.via_recruitment_campaign
            ? "Yes"
            : "No",
        },
        showDetailsModal: true,
      });
    };
    getSelectedPendingNewRENError = (error) => requestError(error);

    updatePendingNewREN = (data) => {
      const updatedREN = {
        full_name: data.full_name,
        identification_number: data.ren_identification_number,
        email: data.email,
        mobile_contact_number: data.mobile_contact_number,
        current_address: data.current_address,
        bank_account_number: data.ren_bank_account,
        bank_owner_name: data.ren_bank_owner_name,
        agent_registration_attributes: {
          id: data.agent_registration_id,
          previous_company_job_title: data.previous_company_job_title,
          previous_company: data.previous_company || "",
          completed_spm: data.completed_spm === "Yes",
          is_undischarged_bankrupt_person: data.is_undischarged_bankrupt_person === "Yes",
          has_been_convicted_crime: data.has_been_convicted_crime === "Yes",
          emergency_person_name: data.ren_emergency_person_name,
          emergency_contact_number: data.ren_emergency_contact_number,
          via_recruitment_campaign: data.via_recruitment_campaign === "Yes",
        },
        name_card_attributes: {
          include_qr_photo: data.name_card_qr_code === "Yes",
          include_profile_photo:
            data.name_card_profile_photo === "Yes" ? true : false,
          name_card_display_name: data.name_card_display_name,
        },
      };

      Put(
        `/admin/registrations/${data.id}`,
        updatedREN,
        this.updatePendingNewRENSuccess,
        this.updatePendingNewRENError,
        this.load,
      );
    };
    updatePendingNewRENSuccess = (payload) => {
      requestSuccess("The agent's profile was updated successfully!");
      this.getSelectedPendingNewREN(payload.id);
      this.setState({ showEditProfileModal: false });
    };
    updatePendingNewRENError = (error) => {
      if (error.errors?.length > 0) {
        requestError(error.errors?.map((error) => <p>{error}</p>));
      } else if (error.message) {
        requestError(error.message);
      } else {
        requestError(error);
      }
    };

    getPaymentDetail = (id) =>
      Get(
        `/admin/registrations/${id}/payments`,
        this.getPaymentDetailSuccess,
        this.getPaymentDetailError,
        this.load,
      );
    getPaymentDetailSuccess = (payload) => {
      let tmpAttendeePaymentDetail = [];

      payload.map((item) =>
        item.payments.map((paymentItem) => {
          tmpAttendeePaymentDetail.push(paymentItem);
        }),
      );

      this.setState({
        paymentDetail: tmpAttendeePaymentDetail,
        showPaymentdetail: true,
      });
    };
    getPaymentDetailError = (error) => requestError(error);

    resetToPending = (id) =>
      Put(
        `/admin/registrations/${id}/reset_pending`,
        {},
        this.resetToPendingSuccess,
        this.resetToPendingError,
        this.load,
      );
    resetToPendingSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getPendingNewREN(currentPage, searchParams);
      requestSuccess(
        "The record has been reset back pending status successfully.",
      );
      this.setState({
        showResetConfirmation: false,
        showDetailsModal: false,
      });
    };
    resetToPendingError = (error) => requestError(error);

    reassignAdmin = (userIds, adminId) =>
      Put(
        `/admin/registrations/admin/reassign`,
        { approval_ids: userIds, user_id: adminId },
        this.reassignAdminSuccess,
        this.reassignAdminError,
        this.load,
      );
    reassignAdminSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getPendingNewREN(currentPage, searchParams);
      this.setState({ showReassignModal: false });

      requestSuccess(payload.message);
    };
    reassignAdminError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          selectedTab={this.state.selectedTab}
          tabActions={this.state.tabActions}
          users={this.state.users}
          detailData={this.state.detailData}
          paymentDetail={this.state.paymentDetail}
          totalCheckable={this.state.totalCheckable}
          currentPaymentProof={this.state.currentPaymentProof}
          selectedUsers={this.state.selectedUsers}
          showPaymentdetail={this.state.showPaymentdetail}
          showResetConfirmation={this.state.showResetConfirmation}
          showRecruitmentConfirmation={this.state.showRecruitmentConfirmation}
          showRejectDialog={this.state.showRejectDialog}
          showConfirmationModal={this.state.showConfirmationModal}
          showReassignModal={this.state.showReassignModal}
          onLoadAdminApproval={this.state.loading}
          totalPages={this.state.totalPages}
          searchParams={this.state.searchParams}
          showDetailsModal={this.state.showDetailsModal}
          showEditProfileModal={this.state.showEditProfileModal}
          reassignAdmin={this.reassignAdmin}
          resetToPending={this.resetToPending}
          getPaymentDetail={this.getPaymentDetail}
          getPendingNewREN={this.getPendingNewREN}
          getSelectedPendingNewREN={this.getSelectedPendingNewREN}
          updatePendingNewREN={this.updatePendingNewREN}
          onChangeApprovalHOC={this.onChangeApprovalHOC}
          onClickSelectAll={this.onClickSelectAll}
          onClickCheckBox={this.onClickCheckBox}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(AdminApprovalWrappedComponent);
};

export default AdminApprovalHOC;
