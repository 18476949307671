import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getTranslation } from "assets/translation";

import "./index.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

class CustomButton extends Component {
  render() {
    const {
      className,
      additionalContent,
      children,
      onClick = () => {},
      onClickContainer,
      tooltip,
      tooltipClass,
      tooltipPopperClass,
      tooltipChildren,
      tooltipPosition,
      tooltipID,
      style = {},
      containerStyle = {},
      containerClass,
      type,
      disabled,
      isIconButton,
      language 
    } = this.props;
    return (
      <>
        {tooltip && (
          <DarkTooltip
            classes={{
              tooltip: `tooltip-arrow ${tooltipClass || ""} ${tooltipPosition || "right"}`,
              popper: `${tooltipPopperClass || ""}`,
            }}
            placement={tooltipPosition || "right"}
            enterTouchDelay={50}
            title={tooltipChildren || getTranslation("back_to_previous_page", language)}>
            <div 
              style={{ 
                ...(!disabled && onClickContainer) ? {cursor: "pointer"} : {}, 
                ...containerStyle 
              }} 
              className={containerClass || ""} 
              onClick={(!disabled && onClickContainer) ? onClickContainer : () => {} }>
              <span
                data-tip={tooltip ? tooltip : ""}
                data-for={tooltipID ? tooltipID.replace(/\s/g, "") : ""}>
                <button
                  className={`${className || ""} ${isIconButton ? "at-icon-responsive" : ""}`}
                  style={{ ...style }}
                  onClick={onClick}
                  type={type}
                  disabled={disabled}>
                  {children}
                </button>
              </span>
              {additionalContent}
            </div>
          </DarkTooltip>
        )}
        {!tooltip && (
          <div 
            style={{
              ...(!disabled && onClickContainer) ? {cursor: "pointer"} : {}, 
              ...containerStyle
            }} 
            className={containerClass || ""}
            onClick={(!disabled && onClickContainer) ? onClickContainer : () => {} }>
            <span
              data-tip={tooltip ? tooltip : ""}
              data-for={tooltipID ? tooltipID.replace(/\s/g, "") : ""}>
              <button
                className={`${className || ""} ${isIconButton ? "at-icon-responsive" : ""}`}
                style={{ ...style }}
                onClick={onClick}
                type={type}
                disabled={disabled}>
                {children}
              </button>
            </span>
            {additionalContent}
          </div>
        )}
      </>
    );
  }
}

export default CustomButton;
