import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import AtlasButton from 'components/Button';
import AtlasFormInput from 'components/Input/formInput';
import AtlasTextarea from 'components/Input/textarea';
import ModalDialog from 'components/Modal/ModalDialog';
import AtlasRadioGroup from 'components/RadioGroup';
import AtlasSelect from 'components/Select';
import AtlasTypeahead from 'components/Typeahead/new';
import AtlasUpload from 'components/Upload';

import isEmptyValue from 'utils/isEmpty';
import { inputFields } from './assets';

const CreateVideoTemplate = ({
  onLoadVideoTemplates,
  onLoadProjectList,
  selectedProject,
  projectList,
  data,
  onChangeVideoTemplatesHOC,
  getProjectListing,
  getSelectedProject,
  createVideoTemplate
}) => {
  const [values, setValues] = useState({
    type_id: 1,
    project_id: '',
    unit_type: 'Type ',
    title: '',
    description: '',
    country_id: '',
    status_id: 1,
    video: '',
    video_file_name: '',
    thumbnail: '',
    thumbnail_file_name: ''
  });
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    setValues(values => ({
      ...values,
      country_id: !isEmptyValue(selectedProject)
        ? selectedProject.country_id
        : ''
    }));
  }, [selectedProject]);

  const countries = data.dictionaryReducer?.countries;
  const visibleInputFields = inputFields(2, 'Create', values.type_id);

  const isButtonDisabled = () => {
    const requiredFields = ['project_id', 'unit_type', 'title', 'country_id', 'video'];

    if (values.type_id === 1) {
      return requiredFields.some(field => values[field] === '');
    } else {
      return requiredFields.slice(2).some(field => values[field] === '');
    }
  };

  const handleClose = async () => {
    await onChangeVideoTemplatesHOC('showCreateVideoTemplate', false);
    await onChangeVideoTemplatesHOC('projectList', []);
    await onChangeVideoTemplatesHOC('selectedProject', {});
  };

  const handleChange = (key, val) => {
    setValues(values => ({ ...values, [key]: val }));

    if (key === 'unit_type') {
      debounceAutofill(val);
    }
  };

  const debounceAutofill = useCallback(
    _.debounce(val => {
      setValues(values => ({
        ...values,
        title: `${selectedProject.internal_name} - ${val}`,
        description: `In this video, we take a tour of ${val} unit of ${selectedProject.internal_name}`
      }));
    }, 1000),
    [selectedProject.internal_name]
  );

  const handleTypeaheadChange = _.debounce(val => {
    onChangeVideoTemplatesHOC('selectedProject', {});

    return val.length > 0
      ? getProjectListing(val)
      : onChangeVideoTemplatesHOC('projectList', []);
  }, 1000);

  const uploadProps = item => {
    if (item.type === 'video') {
      return {
        videoUrl,
        uploadVideo: async (file, videoUrl) => {
          setValues(values => ({
            ...values,
            video: file,
            video_file_name: file.name
          }));
          setVideoUrl(videoUrl);
        }
      };
    } else {
      return {
        imageUrl: values[item.value],
        uploadImage: (imageUrl, fileName) => {
          setValues(values => ({
            ...values,
            thumbnail: imageUrl,
            thumbnail_file_name: fileName
          }));
        }
      };
    }
  };

  const renderFooter = () => (
    <div className='d-flex'>
      <AtlasButton
        className='btn-new btn-new--primary mr-10'
        onClick={() => createVideoTemplate(values)}
        disabled={isButtonDisabled()}
      >
        Create
      </AtlasButton>
      <AtlasButton
        className='btn-new btn-new--outline-secondary'
        onClick={handleClose}
      >
        Cancel
      </AtlasButton>
    </div>
  );

  return (
    <ModalDialog
      title='Create Video Template'
      fullWidth
      fullHeight
      responsiveSize=''
      contentClassName='at-video-templates__dialog-content'
      onClose={handleClose}
      onLoad={onLoadVideoTemplates}
      footer={renderFooter()}
    >
      <div className='form-row'>
        {visibleInputFields
          .filter(
            item => !item.hidden && !['video', 'thumbnail'].includes(item.value)
          )
          .map(item => (
            <div
              key={item.value}
              className={item.className || 'col-lg-4 col-sm-6'}
            >
              <label className='at-form-input__title' required={item.required}>
                {item.label}
              </label>
              {item.type === 'text' && (
                <AtlasFormInput
                  className='mb-0'
                  value={item.value === 'country_id' ? countries?.find(({ id }) => id === values[item.value])?.name : values[item.value]}
                  onChangeValue={val => handleChange(item.value, val)}
                  required={item.required}
                  disabled={item.value === 'unit_type' ? isEmptyValue(values.project_id) : item.disabled}
                />
              )}
              {item.type === 'typeahead' && (
                <AtlasTypeahead
                  containerClassName='mt-0'
                  typeaheadId={item.value}
                  isLoading={onLoadProjectList}
                  options={projectList}
                  labelKey='internal_name'
                  filterBy={['internal_name']}
                  selectedValue={
                    !isEmptyValue(selectedProject) ? [selectedProject] : []
                  }
                  onSearch={handleTypeaheadChange}
                  onSelect={async val => {
                    if (val?.length > 0) {
                      const { id } = val[0];
                      await getSelectedProject(id);
                      await handleChange(item.value, id);
                    } else {
                      onChangeVideoTemplatesHOC('selectedProject', {});
                      handleChange(item.value, '');
                    }
                  }}
                  childrenHead={rowItem => <p>{rowItem.internal_name}</p>}
                  highlighterData={[
                    option => `Project ID: ${option.id}`,
                    <br />,
                    option => `Slug: ${option.slug}`
                  ]}
                  showClearButton={selectedProject?.id}
                  required
                  disabled={item.disabled}
                />
              )}
              {item.type === 'textarea' && (
                <AtlasTextarea
                  style={{
                    cursor: item.disabled ? 'not-allowed' : 'auto'
                  }}
                  value={values[item.value] || ''}
                  onChangeValue={val => handleChange(item.value, val)}
                  disabled={item.disabled}
                />
              )}
              {item.type === 'select' && (
                <AtlasSelect
                  valueKey='id'
                  selectItems={countries}
                  currentlySelected={countries?.find(
                    ({ id }) => id === values[item.value]
                  )}
                  updateSelect={val => handleChange(item.value, val.id)}
                  required={item.required}
                  disabled={item.disabled}
                />
              )}
              {item.type === 'radio' && (
                <AtlasRadioGroup
                  options={item.options}
                  checkedValue={values[item.value]}
                  selectedRadioValue={val => handleChange(item.value, val)}
                  disabled={item.disabled}
                />
              )}
            </div>
          ))}
      </div>
      <div className='form-row'>
        {visibleInputFields.slice(10).map(item => (
          <div
            key={item.value}
            className={`col-lg-4 col-sm-6 ${item.className}`}
          >
            <label className='at-form-input__title' required={item.required}>
              {item.label}
            </label>
            <AtlasUpload
              containerClass='at-video-templates__upload'
              containerStyle={{
                pointerEvents: item.disabled ? 'none' : 'auto'
              }}
              uploadType={item.type}
              description={item.description}
              accepts={item.accepts}
              style={{ width: '100%' }}
              {...uploadProps(item)}
              required={item.required}
              disabled={item.disabled}
            />
          </div>
        ))}
      </div>
    </ModalDialog>
  );
};

export default CreateVideoTemplate;
