import React, { Component } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import CustomPagination from "./pagination";
import RecommendationCard from "components/Card/RecommendationCard";

import "stylesheets/components/table/index.scss";

const StatusLib = [
  { value: 0, label: "All" },
  { value: 1, label: "New Request" },
  { value: 2, label: "Request Sent" },
  { value: 3, label: "Request Accepted" },
  { value: 4, label: "Request Rejected" },
];

const SortMode = [
  { value: 0, label: "Recommended" },
  { value: 1, label: "Recent Transaction" },
  { value: 2, label: "Matched Properties - Hight to Low" },
];

class CustomTable extends Component {
  state = {
    selectPerPageOption: 20,
    selectHeaderData: "",
    totalPages: [1],
    clickPageNumber: 1,
    rowData: [],
    initialRowData: [],

    sortMode: null,
    statusFilter: null,
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData.length !== prevProps.rowData.length) {
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption,
          ),
      );
    }
  };

  componentDidMount = () =>
    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = Math.ceil(paginationSections).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        clickPageNumber: 1,
        totalPages: pagesArrayData,
      });
    }
  };

  renderTableContent = () => {
    if (this.state.rowData && this.state.rowData.length > 0) {
      return this.state.rowData.map((rowItem, index) => {
        if (
          index + 1 >
            (this.state.clickPageNumber - 1) * this.state.selectPerPageOption &&
          index + 1 <=
            this.state.clickPageNumber * this.state.selectPerPageOption
        ) {
          return (
            <RecommendationCard
              key={rowItem.id}
              showActionButton={this.props.showActionButton}
              onClickRequest={() => {
                this.props.onToggleConfirmation(true);
                this.props.onChangeSelectedRCC(rowItem.cobrokeRequestId);
              }}
              mode={"grid"}
              {...rowItem}
            />
          );
        }
      });
    }
  };

  onChangeStatusFilter = (val) => {
    let result = _.filter(this.state.initialRowData, (data) => {
      if (val.value === 0) {
        return data;
      }
      return data.status_id === val.value;
    });

    if (this.state.sortMode && this.state.sortMode.value > 0) {
      result = _.orderBy(result, (item) => item.total_transaction, "desc");
    }

    return this.setState(
      {
        rowData: result,
      },
      () =>
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        ),
    );
  };

  onChangeSortMode = (val) => {
    this.setState(
      {
        sortMode: val,
      },
      () => {
        let tempRowData = _.cloneDeep(this.state.initialRowData);

        if (val.value === 2) {
          tempRowData = _.orderBy(
            this.state.rowData,
            (item) => item.total_transaction,
            "desc",
          );
        }
        if (val.value === 1) {
          tempRowData = _.orderBy(
            this.state.rowData,
            (item) => item.last_transacted_unix,
            "desc",
          );
        }
        this.setState({ rowData: tempRowData });
      },
    );
  };

  renderPaginationText = () => {
    const { pagination, hidePagination } = this.props;
    let { rowData } = this.state;

    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > this.state.rowData.length) {
        pageNumTmp = this.state.rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <p
        className={`at-table__pagination-info ${hidePagination ? "d-none" : ""}`}
      >
        Showing
        <b>
          {" "}
          {` ${pagination ? processFloorPaginationCount(this.state.clickPageNumber) : ""} `}{" "}
        </b>
        to
        <b>
          {" "}
          {` ${pagination ? processCeilingPaginationCount(this.state.selectPerPageOption) : ""} `}{" "}
        </b>
        from a total of
        <b> {rowData ? rowData.length : 0} </b>
        row
      </p>
    );
  };

  render = () => {
    const { className } = this.props;
    return (
      <div className={`mb-20 ${className}`}>
        <div className="d-flex mb-20">
          <div className={"mr-10"}>
            <label className={"d-block"}>Sort By</label>
            <CustomSelect
              className="mt-10"
              customStyle={{ width: 300 }}
              selectItems={SortMode}
              currentlySelected={this.state.sortMode}
              updateSelect={(val) => this.onChangeSortMode(val)}
            />
          </div>
          <div>
            <label className="d-block">Status Filter</label>
            <CustomSelect
              className="mt-10 "
              customStyle={{ width: 200 }}
              selectItems={StatusLib}
              currentlySelected={this.state.statusFilter}
              updateSelect={(val) => this.onChangeStatusFilter(val)}
            />
          </div>
        </div>
        <div className="at-table at-table--set">
          <div className={`at-subsales_listing__view-recommendation-grid`}>
            {this.renderTableContent()}
          </div>
          {this.renderPaginationText()}
          <CustomPagination
            totalPages={this.state.totalPages}
            chosenPageNumber={this.state.selectPerPageOption}
            onClickPageNumber={(val) => this.setState({ clickPageNumber: val })}
            currentPageNumber={this.state.clickPageNumber}
          />
        </div>
      </div>
    );
  };
}

export default CustomTable;
