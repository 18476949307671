import React from "react";
import ReactHtmlParser from "react-html-parser";

import ProjectImages from "./projectImages";
import ProjectPhotoSlides from "./photoSlides";

const ProjectOverview = ({ selectedProject }) => {
  const { project_photos, is_featured, title_description, internal_name } =
    selectedProject;

  return (
    <>
      {(!project_photos || project_photos.length < 1) && (
        <h4 className="at-card__title mr-3 mt-5">{internal_name}</h4>
      )}
      {is_featured && (
        <span className="project-featured-tag">{"Featured"}</span>
      )}
      {project_photos && project_photos.length > 0 && (
        <div
          className={`section-wrapper pt-0 pb-0`}
          style={{ borderRadius: 8, overflow: "hidden" }}
        >
          <ProjectPhotoSlides {...selectedProject} />
        </div>
      )}
      {title_description && !title_description.includes("<p></p>") && (
        <div className={`title-description`}>
          <span
            className={`${title_description.length > 121 ? `justify-text` : `project-center text-center`}`}
          >
            {ReactHtmlParser(title_description)}
          </span>
        </div>
      )}
      <div className={`section-wrapper p-0`}>
        <ProjectImages selectedProject={selectedProject} />
      </div>
    </>
  );
};

export default ProjectOverview;
