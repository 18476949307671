import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { FiEdit } from "react-icons/fi";
import { Tooltip } from "@material-ui/core";
import { BsInfoCircleFill, BsTrash3Fill } from "react-icons/bs";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import PhotoSlider from "./PhotoSlider";
import SVGIcon from "./icon";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import { getTranslation } from "assets/translation";
import { numberWithCommas } from "utils/thousandSeparator";
import { getLocalisedDate, getLocalisedKeyPrefix } from "utils/processLocalised";
import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const CustomCard = ({
  data,
  index,
  disableAction,
  status_permissions,
  can_destroy,

  onClickEdit,
  onClickCard,
  onClickDelete,
  onClickFavourite,
  getLocalised
}) => {
  const {
    id,
    bedrooms_label,
    bathrooms_label,
    measurement,
    carparks_label,
    land_area,
    built_up,

    status,
    status_id,

    code,
    address,
    property,
    category,
    type,
    asking_price_currency,
    asking_price_cents,
    images,
    property_name,
    featureArray,
    is_favourite,
    updated_on,

    published_to_iqi,
    exported_iqi_link,
    published_to_juwai,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    exported_juwai_asia_link,
  } = data;
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const can_delete = [0, 2, 4].includes(status_id) && can_destroy;
  const [showPublishingPlatforms, setShowPublishingPlatforms] = useState(false);
  const publishPlatformLib = [
    {
      label: "IQI Global", 
      tooltipTitle: getTranslation("view_listing_iqi", selectedLocalised),
      linkSrc: "exported_iqi_link",
      published: "published_to_iqi",
      exported: "",
      style: {
        padding: "0.1rem",
      },
      logoSrc: iqiLogo,
    },
    {
      label: "Juwai",
      tooltipTitle: getTranslation("view_listing_juwai", selectedLocalised),
      linkSrc: "juwai_link",
      published: "published_to_juwai",
      exported: "exported_to_juwai",
      style: {
        padding: "0.1rem",
      },
      logoSrc: juwaiLogo,
    },
    {
      label: "Juwai Asia",
      tooltipTitle: getTranslation("view_listing_juwai_asia", selectedLocalised),
      linkSrc: "exported_juwai_asia_link",
      published: "published_to_juwai",
      exported: "exported_to_juwai",
      style: {},
      logoSrc: juwaiAsiaLogo,
    },
    {
      label: "EdgeProp",
      tooltipTitle: getTranslation("view_listing_edgeprop", selectedLocalised),
      linkSrc: "edge_prop_url",
      published: "published_to_edge_prop",
      exported: "exported_to_edgeprop",
      style: { borderRadius: "50%", overflow: "hidden" },
      logoSrc: edgePropLogo,
    },
  ];

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ tooltipTitle, linkSrc, logoSrc, style, label }) => {
      return (
        <Tooltip
          enterTouchDelay={0}
          classes={{
            popper: "popper",
            tooltip: "tooltip-arrow",
            tooltipPlacementTop: "top",
            tooltipPlacementBottom: "bottom",
          }}
          placement={"top"}
          title={tooltipTitle}
        >
          <div
            style={{
              ...style,
              padding: 12,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => window.open(linkSrc)}
          >
            <img
              src={logoSrc}
              style={{ width: 16, height: 16, marginRight: 10 }}
            />
            <p className="fs-2">{label}</p>
          </div>
        </Tooltip>
      );
    };
    return (
      <div className="at-subsales-published_platforms">
        {publishPlatformLib.map((item, index) => {
          let tmpCheckingLink =
            data[item.linkSrc] &&
            data[item.linkSrc].length > 0 &&
            data[item.published] &&
            (item.exported ? data[item.exported] : true);
          return (
            tmpCheckingLink && (
              <LogoPlatform
                {...item}
                key={index}
                linkSrc={data[item.linkSrc]}
              />
            )
          );
        })}
      </div>
    );
  };
  
  const renderFeature = () => {
    return (
      featureArray &&
      featureArray.length > 0 &&
      featureArray.map((item, index) => {
        if (item.value === "N/A") return null;
  
        const localisedKey = `${getLocalisedKeyPrefix(item.label)}.${item.value.replace(/[-\s]/g, '_').toLowerCase()}`;

        return (
          <Fragment key={"feature-" + index + "-" + id}>
            <Tooltip
              enterTouchDelay={0}
              classes={{
                tooltip: "tooltip-arrow",
                tooltipPlacementTop: "top",
                tooltipPlacementBottom: "bottom",
              }}
              aria-label={item.value}
              placement={"top"}
              title={getLocalised(
                `label.details.${item.label === "Direction" ? "specification" : "details"}.${item.label.replace(/[-\s]/g, '_').toLowerCase()}`, 
                item.label
              )}
            >
              <span>{getLocalised(localisedKey, item.value)}</span>
            </Tooltip>
          </Fragment>
        );
      })
    );
  };
  
  const renderSpecs = () => {
    return (
      <div className="at-subsales__specs">
        <div className="at-subsales__props-features">
          {
            <SVGIcon
              itemID={`${id}-${bedrooms_label}-bedrooms`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bed mr-2`}
              iconName={`icon-bed`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">{getLocalised("label.details.specification.bedrooms", "Bedrooms")}</span>
                </>
              }
              children={
                <span className="fw-400">{bedrooms_label || "N/A"}</span>
              }
            />
          }
          {
            <SVGIcon
              itemID={`${id}-${bathrooms_label}-bathrooms`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bathroom mr-2`}
              iconName={`icon-bathroom`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">{getLocalised("label.details.specification.bathrooms", "Bathrooms")}</span>
                </>
              }
              children={
                <span className="fw-400">{bathrooms_label || "N/A"}</span>
              }
            />
          }
          {
            <SVGIcon
              itemID={`${id}-${carparks_label}-carparks`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-carpark mr-2`}
              iconName={`icon-carpark`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">{getLocalised("label.details.specification.carparks", "Carparks")}</span>
                </>
              }
              children={
                <span className="fw-400">{carparks_label || "N/A"}</span>
              }
            />
          }
        </div>
        <div className={`at-subsales__props-built_up__land_area`}>
          <SVGIcon
            itemID={`${id}-${measurement}-builtUp`}
            containerClassName={`d-flex at-subsales__icon`}
            tooltip={true}
            iconClassName={`svg-icon-squarefeet mr-2`}
            iconName={`icon-squarefeet`}
            tooltipLabel={
              <>
                <span className="at-subsales_tooltip-title">
                  {getLocalised("label.details.specification.built_up_utilised_area", "Built Up / Land Area")}
                </span>
              </>
            }
            children={
              <>
                <span className="fw-400">
                  {built_up ? built_up + " " : "N/A"}
                  {land_area ? " / " + land_area + " " + getLocalised(getLocalisedKeyPrefix(measurement), measurement) : "/ N/A"}
                </span>
              </>
            }
          />
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    const { colorName } = _.find(StatusColor, { value: status }) || {};

    return (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(colorName || "Grey") }}
      >
        {getLocalised(`option.statuses.${status.replace(/[-\s\/]/g, '_').toLowerCase()}`, status)}
      </div>
    );
  };

  return (
    <li className={`at-subsales__item`}>
      <PhotoSlider id={id} images={images} />
      <div className="at-subsales__details-upper_cont color-black">
        <div className="at-subsales__details-upper_cont-cont">
          <h6 className="at-subsales__code">{code}</h6>
          {status_permissions && (
            <CustomButton
              className="at-subsales__actions-favourite"
              onClick={() => onClickFavourite(id)}
            >
              {is_favourite ? (
                <AtlasIcon
                  svgHref={"atlas-heart"}
                  style={{ fill: "#F43F5E" }}
                />
              ) : (
                <AtlasIcon
                  svgHref={"atlas-heart-linear"}
                  style={{ fill: "#F43F5E" }}
                />
              )}
            </CustomButton>
          )}
        </div>
      </div>
      <div className="at-subsales__details-lower_cont">
        <div className="at-subsales__details-information">
          <Tooltip
            enterTouchDelay={0}
            key={"property_name"}
            classes={{
              tooltip: "tooltip-arrow",
              tooltipPlacementTop: "top",
              tooltipPlacementBottom: "bottom",
            }}
            aria-label={"property_name"}
            placement={"top-start"}
            title={property_name}
          >
            <h5 className="fw-600 at-subsales__title">{`${property_name}`}</h5>
          </Tooltip>
          <Tooltip
            enterTouchDelay={0}
            key={"address_name"}
            classes={{
              tooltip: "tooltip-arrow",
              tooltipPlacementTop: "top",
              tooltipPlacementBottom: "bottom",
            }}
            aria-label={"address_name"}
            placement={"top-start"}
            title={address}
          >
            <div className="d-flex align-items-center">
              <AtlasIcon
                svgHref={"atlas-location"}
                style={{
                  minWidth: 14,
                  width: 14,
                  height: 14,
                  fill: "#F0631D",
                  marginRight: 4,
                }}
              />
              <h6 className="at-subsales__address">{`${address}`}</h6>
            </div>
          </Tooltip>
          <p className="at-subsales__price">
            <span className="mr-2" style={{ color: "#111827" }}>
              {asking_price_currency}
            </span>
            {asking_price_cents && asking_price_cents > 0
              ? numberWithCommas(asking_price_cents)
              : "N/A"}
          </p>
          <div className="at-subsales-props__cont">
            {renderSpecs()}
            <div className="at-subsales__info">
              {type !== "N/A" && (
                <Tooltip
                  enterTouchDelay={0}
                  key={"Subsales_Type-" + id}
                  classes={{
                    tooltip: "tooltip-arrow",
                    tooltipPlacementTop: "top",
                    tooltipPlacementBottom: "bottom",
                  }}
                  aria-label={"subsales_type"}
                  placement={"top"}
                  title={getLocalised("label.details.details.type", "Type")}
                >
                  <span>{getLocalised(`option.types.${type.replace(/[-\s]/g, '_').toLowerCase()}`, type)}</span>
                </Tooltip>
              )}
              {renderFeature()}
              {category &&
                category !== "N/A" &&
                property &&
                property !== "N/A" && (
                  <Tooltip
                    enterTouchDelay={0}
                    key={"property-1-" + id}
                    classes={{
                      tooltip: "tooltip-arrow",
                      tooltipPlacementTop: "top",
                      tooltipPlacementBottom: "bottom",
                    }}
                    aria-label={"Property Type"}
                    placement={"top"}
                    title={getLocalised("label.details.details.property_type", "Property Type")}
                  >
                    <span>
                      {getLocalised(`${getLocalisedKeyPrefix(category)}`, category)} - {getLocalised(`${getLocalisedKeyPrefix(category)}.${property.replace(/[-\s]/g, '_').toLowerCase()}`, property)}
                    </span>
                  </Tooltip>
                )}
            </div>
          </div>
        </div>
        <div className="at-subsales__footer">
          <div className="at-subsales__status">
            {renderStatus()}
            <div className="at-subsales-available_date mr-2">
              {updated_on && <span>{getLocalised("label.date.updated", "Updated")} {getLocalisedDate(updated_on, selectedLocalised)}</span>}
            </div>
            {status_id === 1 &&
              ((published_to_iqi && exported_iqi_link) ||
                (published_to_juwai &&
                  exported_to_juwai &&
                  (juwai_link || exported_juwai_asia_link)) ||
                (exported_to_edgeprop && published_to_edge_prop)) && (
                <CustomButton
                  className="at-subsales__actions-view"
                  containerClass="ml-auto"
                  children={
                    <>
                      {getLocalised("title.details.view", "View Listing")}
                      <AtlasIcon svgHref={"atlas-export-2-linear"} />
                    </>
                  }
                  onClick={() =>
                    setShowPublishingPlatforms(!showPublishingPlatforms)
                  }
                />
              )}
          </div>
          <div className="at-subsales__actions">
            {status_permissions && (
              <CustomButton
                className="btn-new btn-new--secondary w-100"
                containerStyle={{ width: "42%" }}
                onClick={() => onClickEdit(data)}
                children={
                  <>
                    <FiEdit />
                    {getTranslation("edit", selectedLocalised)}
                  </>
                }
              />
            )}
            <CustomButton
              className="btn-new btn-new--outline-secondary w-100"
              containerStyle={{ width: "42%" }}
              onClick={() => onClickCard(data)}
              children={
                <>
                  <BsInfoCircleFill style={{ color: "#3B82F6" }} />
                  {getTranslation("details", selectedLocalised)}
                </>
              }
            />
            {status_permissions && (
              <CustomButton
                className="btn-new btn-new--outline-secondary"
                disabled={!can_delete}
                tooltip={!can_delete}
                enableHTML={true}
                tooltipChildren={getLocalised("action.helptext.delete", `To delete, please set the status to Inactive/Expired or Sold/Rent Out.`)}
                tooltipPosition={"top"}
                tooltipID={`subsales-delete-${id}`}
                children={
                  <BsTrash3Fill
                    style={{
                      color: can_delete ? "#F04438" : "#FECDCA",
                      marginRight: 0,
                    }}
                  />
                }
                onClick={() => onClickDelete(data)}
              />
            )}
          </div>
          {showPublishingPlatforms && (
            <div className={"at-subsales__dropbear-modal-root"}>
              {renderPublishingPlatforms()}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default CustomCard;
