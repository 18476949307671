import React, { useCallback } from "react";
import _ from "lodash";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";

export default function Form({
  selectedCurrency,
  open_for_internal_co_broke,
  co_broke_settings,
  getLocalised,
  onChangeDetailsHOC
}) {
  const FieldData = [
    { label: getLocalised("label.details.co_broke.commission", "Commission"), value: "total_portion" },
    { label: getLocalised("label.details.co_broke.listing_agent_portion", "Listing Agent Portion"), value: "my_portion" },
    { label: getLocalised("label.details.co_broke.co_broke_agent_portion", "Co-broke Agent Portion"), value: "balance" },
  ];
  
  const onChangeField = useCallback(
    (key, val) => {
      onChangeDetailsHOC(
        {
          ...co_broke_settings,
          [key]: val,
        },
        "co_broke_settings",
      );
    },
    [co_broke_settings],
  );

  return (
    <>
      <div className="grid-full-col mb-10">
        <CustomCheckbox
          checked={open_for_internal_co_broke}
          content={getLocalised("label.details.checkbox.co_broke.message", "Open for Internal Co-broke")}
          onChangeCheckboxValue={() =>
            onChangeDetailsHOC(
              !open_for_internal_co_broke,
              "open_for_internal_co_broke",
            )
          }
        />
      </div>
      <AlertBox
        mode={"info"}
        hideIcon={true}
        containerStyle={{ marginBottom: "8px" }}
        description={
          <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_1", 
                `The Total Agency Fee is the total percentage or amount of
                professional fee that IQI will receive.`
              )}
            </li>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_2", 
                `Atlas will automatically calculate the Co-broke Agent Portion.`
              )}
            </li>
            <li>
              {getLocalised(
                "label.details.helptext.co_broke.message_3", 
                `For example, if the Total Agency Fee is 2% and you are willing to
                co-broke on an equal 50:50 basis then enter 1% in My Portion and
                Atlas will auto fill the Co-broke Agent Portion as 1%.`
              )}
            </li>
          </ul>
        }
      />
      {open_for_internal_co_broke && (
        <>
          <AtlasRadioGroup
            horizontal={true}
            containerClassName={"w-100"}
            checkedValue={co_broke_settings.co_broke_portion_by}
            selectedRadioValue={(val) =>
              onChangeField("co_broke_portion_by", val)
            }
            options={[
              { value: "percentage", label: getLocalised("label.details.button.co_broke.by_percentage", "By Percentage")},
              {
                value: "amount",
                label: getLocalised("label.details.button.co_broke.by_amount", "By Amount") + (_.keys(selectedCurrency).length > 0 ? `(${selectedCurrency.label})` : ""),
              },
            ]}
          />
          {FieldData.map(({ label: fieldLabel, value: fieldValue }) => (
            <div className={"my-3"}>
              <div className="d-flex align-items-end">
                <h2
                  className="at-form-input__title"
                  required
                >{`${fieldLabel} ${co_broke_settings.co_broke_portion_by === "percentage" ? "%" : ""}`}</h2>
              </div>
              <CustomFormInput
                type="text"
                numericOnly={true}
                value={co_broke_settings[fieldValue]}
                onChangeValue={(val) => {
                  let tempVal = val.replace(/[^$0-9.]/g, "");
                  let temp = _.cloneDeep(co_broke_settings);
                  temp[fieldValue] = tempVal;

                  if (
                    ["total_portion", "my_portion"].indexOf(fieldValue) > -1 &&
                    temp.total_portion &&
                    temp.my_portion
                  ) {
                    temp["balance"] =
                      fieldValue === "my_portion"
                        ? temp.total_portion - tempVal
                        : tempVal - temp.my_portion;
                  }
                  onChangeDetailsHOC(temp, "co_broke_settings");
                }}
                required={true}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
}
