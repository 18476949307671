const convertConditions = async (conditions) => {
  return await conditions.map((condition) => {
    const { field, comparison, selected, value } = condition;

    let query = `${field} ${comparison}`;

    if (comparison.includes("NULL")) {
      return query;
    }

    if (field.includes("_id")) {
      if (comparison.includes("=")) {
        query += ` ${selected[0].id}`;
      } else {
        query += ` (${selected.map((option) => option.id)})`;
      }
    } else {
      if (comparison.includes("=")) {
        query += ` '${value}'`;
      } else if (comparison.includes("IN")) {
        query += ` (${value
          .split(",")
          .map((val) => `'${val.trim()}'`)
          .join(",")})`;
      }
    }

    return query;
  });
};

export default convertConditions;
