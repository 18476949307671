import Moment from "moment";
import { BsClockFill } from "react-icons/bs";
import { RiMailFill, RiMailSendFill, RiPencilFill } from "react-icons/ri";

import { getColorBadge } from "dictionary/badgeColor";

export const statusOptions = [
  {
    value: "draft",
    label: "Draft",
    colorName: "Grey",
    icon: <RiPencilFill />,
    followUpText: "edited on",
  },
  {
    value: "scheduled",
    label: "Scheduled",
    colorName: "Blue",
    icon: <BsClockFill />,
    followUpText: "for",
  },
  {
    value: "running",
    label: "Running",
    colorName: "Yellow",
    icon: <RiMailSendFill />,
    followUpText: "on",
  },
  {
    value: "sent",
    label: "Sent",
    colorName: "Green",
    icon: <RiMailFill />,
    followUpText: "on",
  },
];

export const fieldOptions = [
  { label: "Select a field", value: "", disabled: true },
  { label: "team", value: "team_id" },
  { label: "country", value: "country_id" },
  { label: "status", value: "status_id" },
  { label: "branch", value: "branch_id" },
  { label: "email", value: "email" },
  { label: "mobile number", value: "mobile_contact_number" },
];

export const comparisonOptions = [
  { label: "Select a comparison", value: "", disabled: true },
  { label: "is equal to", value: "=" },
  { label: "is present", value: "IS NOT NULL" },
  { label: "includes", value: "IN" },
  { label: "is not equal to", value: "!=" },
  { label: "is blank", value: "IS NULL" },
  { label: "does not include", value: "NOT IN" },
];

export const columnData = () => {
  const getStatusProperty = (status, prop) =>
    statusOptions.find((item) => item.value === status)[prop];

  const getPercentageValue = (row, chosen_count) => (
    <p className={"text-secondary"}>
      {row.recipient_count > 0
        ? Math.round((row[chosen_count] / row.recipient_count) * 100)
        : 0}
      %
    </p>
  );

  return [
    {
      header: "Campaigns",
      renderColumn: (row) => (
        <div className={"d-flex flex-column g-1"}>
          <b>{row.name}</b>
          <p className={"text-secondary"}>
            {getStatusProperty(row.status, "followUpText")}{" "}
            {row.scheduled_at
              ? Moment(row.scheduled_at).format("MMM DD, YYYY hh:mm A")
              : Moment(row.updated_at).format("MMM DD, YYYY hh:mm A")}
          </p>
        </div>
      ),
    },
    {
      header: "Status",
      contentClass: "at-table-row__item__responsive",
      columnClass: "d-flex align-items-lg-center",
      renderColumn: ({ status }) => (
        <span
          className={`at-status_badge mx-0 mx-lg-auto`}
          style={{ ...getColorBadge(getStatusProperty(status, "colorName")) }}
        >
          {status.charAt(0).toUpperCase() + status.substring(1)}
        </span>
      ),
    },
    {
      header: "Recipients",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong>{row.recipient_count}</strong>
        </>
      ),
    },
    {
      header: "Processed",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong style={{ color: "#1C4ED8" }}>{row.processed_count}</strong>
          {getPercentageValue(row, "processed_count")}
        </>
      ),
    },
    {
      header: "Delivered",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong style={{ color: "#027A48" }}>{row.delivered_count}</strong>
          {getPercentageValue(row, "delivered_count")}
        </>
      ),
    },
    {
      header: "Deferred",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong style={{ color: "#B54707" }}>{row.deferred_count}</strong>
          {getPercentageValue(row, "deferred_count")}
        </>
      ),
    },
    {
      header: "Dropped",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong className={"text-danger"}>{row.dropped_count}</strong>
          {getPercentageValue(row, "dropped_count")}
        </>
      ),
    },
    {
      header: "Bounced",
      contentClass: "at-table-row__item__responsive",
      columnClass: "text-left text-lg-center at-table-row__item__responsive__hidden",
      renderColumn: (row) => (
        <>
          <strong className={"text-danger"}>{row.bounced_count}</strong>
          {getPercentageValue(row, "bounced_count")}
        </>
      ),
    },
    {
      header: "",
      contentClass: "at-table-row__item__responsive__mobile",
      columnClass: "text-right at-table-row__item__responsive__mobile",
      renderColumn: (row) => (
        <div className="at-table-row__item__table">
          <div className="table-cell">
            <span  className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Recipients</span>
            <strong>{row.recipient_count}</strong>
          </div>
          <div className="table-cell">
            <span className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Processed</span>
            <strong style={{ color: "#1C4ED8" }}>{row.processed_count}</strong>
            {getPercentageValue(row, "processed_count")}
          </div>
          <div className="table-cell">
            <span className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Delivered</span>
            <strong style={{ color: "#027A48" }}>{row.delivered_count}</strong>
            {getPercentageValue(row, "delivered_count")}
          </div>
          <div className="table-cell">
            <span className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Deferred</span>
            <strong style={{ color: "#B54707" }}>{row.deferred_count}</strong>
            {getPercentageValue(row, "deferred_count")}
          </div>
          <div className="table-cell">
            <span className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Dropped</span>
            <strong className={"text-danger"}>{row.dropped_count}</strong>
            {getPercentageValue(row, "dropped_count")}
          </div>
          <div className="table-cell">
            <span className="at-table-head__title justify-content-center" style ={{ fontWeight: 600 }}>Bounced</span>
            <strong className={"text-danger"}>{row.bounced_count}</strong>
            {getPercentageValue(row, "bounced_count")}
          </div>
        </div>
      ),
    }
  ];
};

export const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const minutes = ["00", "15", "30", "45"];
