import Resizer from "react-image-file-resizer";

const imageResizer = async (file, options, callback) => {
  try {
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        ...(options || [
          300, //maxWidth
          300, //maxHeight
          "JPEG", //compressFormat
          100, //quality
          0, //rotation
        ]),
        (uri) => {
          resolve(uri)
        },
        "base64"
      );
    }).then((uri) => {
      return callback(uri);
    });
  } catch (err) {
    console.log(err);
  }
}

export default imageResizer;