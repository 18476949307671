import React, { Component } from "react";
import _ from "lodash";
import { Put, Get, Post } from "utils/axios";
import { numberWithCommas } from "utils/thousandSeparator";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      recommendationSetting: null,
      isNewSetting: false,
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success, callback) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () => {
          this.setState({ showStatusModal: false });
          callback && callback();
        },
        statusModalMessage: success,
      });

    onChangeRecommendationHOC = (val, context) =>
      this.setState({ [context]: val });

    getRecomendationSetting = (id) =>
      Get(
        `/entity_settings/${id}?locale=${this.props.data.languageReducer.language}`,
        this.getRecomendationSettingSuccess,
        this.getRecomendationSettingError,
        this.load,
      );
    getRecomendationSettingSuccess = (payload) => {
      const { settings } = payload;

      this.setState({
        recommendationSetting: payload
          ? {
              notify_via: settings.notify_via,
              radius: numberWithCommas(
                `${settings.radius.replace(/[^$0-9.]/g, "")}`,
              ),
              min_price: numberWithCommas(
                `${settings.min_price.replace(/[^$0-9.]/g, "")}`,
              ),
              max_price: numberWithCommas(
                `${settings.max_price.replace(/[^$0-9.]/g, "")}`,
              ),
            }
          : null,
        isNewSetting: _.values(payload).length < 1,
      });
    };
    getRecomendationSettingError = (error) => requestError(error);

    updateRecommendation = ({ settings, id }) => {
      Put(
        `/entity_settings/${id}?locale=${this.props.data.languageReducer.language}`,
        {
          settings: {
            notify_via: settings.notify_via,
            radius: settings.radius.replace(/[^$0-9.]/g, ""),
            min_price: settings.min_price.replace(/[^$0-9.]/g, ""),
            max_price: settings.max_price.replace(/[^$0-9.]/g, ""),
          },
        },
        this.updateRecommendationSuccess,
        this.updateRecommendationError,
        this.load,
      );
    };
    updateRecommendationSuccess = (payload) => {
      this.getRecomendationSetting(payload.id);
      requestSuccess(this.props.getLocalised(
        "success.update_cobroke_settings",
        `The Co-broke Recommendation Engine settings for this listing have been updated 
        and the recommendations will soon be refreshed after applying your engines. 
        This might take a short while so please check again later if you don't see any changes. 
        Please bear in mind that there might not be any changes if the recommendation engine 
        has not found any other agents.`,
      ));
    };
    updateRecommendationError = (error) => requestError(error);

    createRecomendationSetting = ({ settings, entity_id }) =>
      Post(
        `/entity_settings?locale=${this.props.data.languageReducer.language}`,
        {
          entity_id,
          entity_type: "Listing",
          key: "engine.settings",
          settings: {
            notify_via: settings.notify_via,
            radius: settings.radius.replace(/[^$0-9.]/g, ""),
            min_price: settings.min_price.replace(/[^$0-9.]/g, ""),
            max_price: settings.max_price.replace(/[^$0-9.]/g, ""),
          },
        },
        this.createRecomendationSettingSuccess,
        this.createRecomendationSettingError,
        this.load,
      );
    createRecomendationSettingSuccess = (payload) => {
      this.getRecomendationSetting(payload.id);
      requestSuccess(this.props.getLocalised(
        "success.update_cobroke_settings",
        `The Co-broke Recommendation Engine settings for this listing have been updated 
        and the recommendations will soon be refreshed after applying your engines. 
        This might take a short while so please check again later if you don't see any changes. 
        Please bear in mind that there might not be any changes if the recommendation engine 
        has not found any other agents.`,
      ));
    };
    createRecomendationSettingError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          recommendationSetting={this.state.recommendationSetting}
          onLoadRecommendation={this.state.loading}
          isNewSetting={this.state.isNewSetting}
          onChangeRecommendationHOC={this.onChangeRecommendationHOC}
          getRecomendationSetting={this.getRecomendationSetting}
          updateRecommendation={this.updateRecommendation}
          createRecomendationSetting={this.createRecomendationSetting}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
