import React from "react";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import Moment from "moment";

import FormDialog from "../../components/formDialog";

import subrentForms from "../assets/subrentForms";

const TempSubrentAuthorisationForm = ({
  data,
  switchMode,

  onChange,
  closedEditing,
}) => {
  const {
    commencement_date,
    expiring_date,
    appoint_day,
    professional_fee,
    property_type,
    property_address,
    government_tax,
    payment_amount,
    others,
    month_of_rental,
    number_of_rooms_and_baths,
    transaction_number,
  } = data;

  const renderContractLayout = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <h5 className="fw-600">Authorisation To Secure Property For Rent</h5>
          <h5 className="fw-600">{transaction_number || ""}</h5>
        </div>
        <hr />
        <div style={{ marginBottom: 10, fontSize: 14 }}>
          Tenant(s) :
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section) `}
          </span>
        </div>
        <div style={{ marginBottom: 10, fontSize: 14 }}>
          NRIC/Passport No. :
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section) `}
          </span>
        </div>
        <div style={{ marginBottom: 10, fontSize: 14 }}>
          Tenant Address :
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section) `}
          </span>
        </div>
        <div className="mb-10 mt-10 fs-2">
          Do hereby appoint you
          <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
          as my/our AGENT for a period of
          <input
            type={"text"}
            disabled={closedEditing}
            className="subsales-letter-input"
            value={appoint_day}
            onChange={(e) => onChange("appoint_day", e.target.value)}
          />
          days/ calendar months* commencing from the
          {/* Date format submit follows BE returns' format YYYY-MM-DD */}
          {/* Make sure date format not affect date input form field view */}
          <DatePicker
            wrapperClassName="subsales-letter-input_wrapper"
            className="subsales-letter-input"
            value={
              Moment(commencement_date).isValid()
                ? Moment(commencement_date).format("DD MMM YYYY")
                : commencement_date
            }
            onChange={(val) =>
              onChange("commencement_date", Moment(val).format("YYYY-MM-DD"))
            }
            placeholderText={"DD MMM YYYY"}
            disabled={closedEditing}
            popperPlacement={"bottom-end"}
          />
          and expiring on the
          <DatePicker
            wrapperClassName="subsales-letter-input_wrapper"
            className="subsales-letter-input"
            value={
              Moment(expiring_date).isValid()
                ? Moment(expiring_date).format("DD MMM YYYY")
                : expiring_date
            }
            onChange={(val) =>
              onChange("expiring_date", Moment(val).format("YYYY-MM-DD"))
            }
            placeholderText={"DD MMM YYYY"}
            disabled={closedEditing}
            popperPlacement={"bottom-end"}
          />
          ( "Agency Period ") to secure a property for rent ( "said Property" )
          at a total consideration for the said Property which shall not exceed
          RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"payment_amount"}
            defaultValue={1000}
            disabled={closedEditing}
            value={payment_amount}
            onValueChange={(value) => onChange("payment_amount", value)}
          />
          or other such sum as may be subsequently agreed by the Prospective
          Tenant ( "Rental Price ") subject to the following terms and
          conditions.
        </div>
        <ol type="1" className="mt-20 fs-2">
          <li>
            The Prospective Tenant hereby authorizes
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            to utilize any form of advertisement to promote the acquisition of
            the said Property, including but not limited to sending out flyers,
            or advertising in any form of media.
          </li>
          <li>
            A brief description of the Prospective Tenant's requirement is as
            follows:
            <ol type="a">
              <li>
                Type of Property:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={property_type}
                  onChange={(e) => onChange("property_type", e.target.value)}
                />
              </li>
              <li>
                Location of Property:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={property_address}
                  onChange={(e) => onChange("property_address", e.target.value)}
                />
              </li>
              <li>
                No. of room and bath:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={number_of_rooms_and_baths}
                  onChange={(e) =>
                    onChange("number_of_rooms_and_baths", e.target.value)
                  }
                />
              </li>
              <li>
                Others:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={others}
                  onChange={(e) => onChange("others", e.target.value)}
                />
              </li>
            </ol>
          </li>
          <li>
            This instruction contained herein is given to
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            in my/our capacity as the Prospective Tenant. This authorization to
            secure a property for rent shall remain valid during the said Agency
            Period.
          </li>
          <li>
            The Prospective Tenant hereby agrees to pay
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            a professional fee a RM
            <CurrencyInput
              className="subsales-letter-input"
              style={{ paddingLeft: "0.2rem" }}
              name={"professional_fee"}
              defaultValue={1000}
              disabled={closedEditing}
              value={professional_fee}
              onValueChange={(value) => onChange("professional_fee", value)}
            />
            or a professional fee equivalent to
            <input
              type={"number"}
              disabled={closedEditing}
              className="subsales-letter-input"
              value={month_of_rental}
              onChange={(e) =>
                onChange("month_of_rental", parseFloat(e.target.value))
              }
            />
            month(s)' Rental, plus
            <input
              type={"number"}
              disabled={closedEditing}
              className="subsales-letter-input"
              value={government_tax}
              onChange={(e) => onChange("government_tax", e.target.value)}
            />
            Sales and Service Tax ( "SST" ) on the professional fees upon the
            rental of the said Property ( even if it differs from Clause 2 )
            from any Landlord(s) procured and/or introduced by
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            during the Agency Period on the terms specified herein and upon the
            execution of the Tenancy Agreement ("TA").
          </li>
          <li>
            In the event the Earnest Deposit has been paid and the rental
            transaction is aborted by the Prospective Tenant or by the
            Landlord(s) before the execution of the TA the Prospective Tenant
            agrees to pay IQI REALTY SDN BHD a professional fee equivalent to
            50% of the Earnest Deposit or forfeitable deposit or 50% of the
            agreed full fees, whichever is lesser plus the relevant applicable
            SST.
          </li>
          <li>
            The Prospective Tenant agrees to indemnify
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            against all losses/ damages or claims by the Landlord(s) if the
            Prospective Tenant for any reasons whatsoever fail to execute, or
            does not proceed with the execution of, the TA after the Earnest
            Deposit has been paid or collected and the Prospective Tenant's
            offer to rent has been accepted by the Landlord(s).
          </li>
          <li>
            All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
            Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
            at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
          </li>
        </ol>
        <hr />
      </>
    );
  };

  const renderFormListLayout = () => {
    return (
      <FormDialog
        formData={subrentForms}
        data={data}
        closedEditing={closedEditing}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      {switchMode === "Contract View" && renderContractLayout()}
      {switchMode === "Form View" && renderFormListLayout()}
    </>
  );
};

export default TempSubrentAuthorisationForm;
