import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { storeLastView } from "actions/lastView";

import { Get, Post, Put, GetFile } from "utils/axios";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Agent Name/Email",
    value: "user_full_name_or_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Form Number",
    value: "transaction_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Tenant/Purchase Name",
    value: "tenant_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Submitted",
    value: "is_claimed_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const AuthorisationFormHOC = (WrappedComponent) => {
  class AuthorisationFormWrappedComponent extends Component {
    state = {
      agentList: [],
      authorisationForm: [],
      contactList: [],
      authorisationFormPage: null,
      selectedAuthorisationForm: {},
      disableSubmitButton: false,

      showSendEmail: false,
      showEditAuthorisationForm: false,
      showLoadingModal: false,
      showCreationForm: false,

      searchParams: searchParams,
      showSubmitConfirmation: false,
      showDetachConfirmation: false,
      showSelectForm: false,

      createTypeId: null,
      createInvoiceId: null,

      tempEProcess: null,

      selectedSigningTab: "e-Signing",
      showDownloadFormDialog: false,
    };

    componentDidMount = () =>
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeAuthorisationHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeAuthorisationFormValue = (context, val) => {
      let tmp = _.cloneDeep(this.state.selectedAuthorisationForm);
      tmp[context] = val;

      return this.setState({ selectedAuthorisationForm: tmp });
    };

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmation: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    getAuthorisationForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/forms/authorisation_forms?${search}page=${page}`,
        this.getAuthorisationFormsSuccess,
        this.getAuthorisationFormsError,
        this.load,
      );
    };
    getAuthorisationFormsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.map((item) => {
          item.type = item.type_id === 1 ? "Subsales" : "Subrent";
          item.claim_status = item.is_claimed === true ? "true" : "false";
        });
      this.setState({
        authorisationForm: payload,
        authorisationFormPage: tmpTotalPages,
      });
    };
    getAuthorisationFormsError = (error) => requestError(error);

    getCurrentFormContactList = (form_id) =>
      Get(
        `/admin/forms/form_contacts/search_contact?type_id=4&form_id=${form_id}`,
        (payload) => this.setState({ contactList: payload }),
        this.getCurrentFormContactListError,
        this.load,
      );
    getCurrentFormContactListError = (error) => requestError(error);

    getSelectedAuthorisationForms = (id) =>
      Get(
        `/admin/forms/authorisation_forms/${id}`,
        this.getSelectedAuthorisationFormsSuccess,
        this.getSelectedAuthorisationFormsError,
        this.load,
      );
    getSelectedAuthorisationFormsSuccess = (payload) => {
      const tmpIsLocked = _.some(payload?.forms_chart || [], form => {
        if(Array.isArray(form)) {
          return _.some(form || [], nestedForm => nestedForm.type === "Claim Form" && nestedForm.id !== null )
        } else {
          return form.type === "Claim Form" && form.id !== null
        }
      });
      // Only lock for editing the Appointment letter after agent submit for claim form

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_authorisation", "rent_authorisation"].find(
        (type) => {
          return tmpPayload[type] && tmpPayload[type] !== null;
        },
      );

      tmpPayload.payment_amount = parseFloat(tmpPayload?.payment_amount ?? 0)?.toFixed(2)
      tmpPayload.professional_fee = parseFloat(tmpPayload?.professional_fee ?? 0)?.toFixed(2)
      
      let tmpSelectedAuthorisationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        isLocked: tmpIsLocked,
        id: tmpPayload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmpSelectedAuthorisationForm.sale_authorisation;
      delete tmpSelectedAuthorisationForm.rent_authorisation;

      this.setState({
        selectedAuthorisationForm: tmpSelectedAuthorisationForm,
        showEditAuthorisationForm: true,
        tempEProcess: payload.e_process,
      });
    };
    getSelectedAuthorisationFormsError = (error) => requestError(error);

    downloadAuthorisationForm = (id, filename) =>
      GetFile(
        `/admin/forms/authorisation_forms/${id}/download?with_data=true`,
        filename,
        () => {},
        this.downloadAuthorisationFormError,
        this.load,
      );
    downloadAuthorisationFormError = (error) => requestError(error);

    sendAuthorisationForm = (id, recipient) =>
      Post(
        `/admin/forms/authorisation_forms/${id}/deliver`,
        recipient,
        this.sendAuthorisationFormSuccess,
        this.sendAuthorisationFormError,
        this.load,
      );
    sendAuthorisationFormSuccess = (payload) => {
      this.setState({ showSendEmail: false });
      requestSuccess(payload.message);
    };
    sendAuthorisationFormError = (error) => requestError(error);

    updateAuthorisationForm = () =>
      Put(
        `/admin/forms/authorisation_forms/${this.state.selectedAuthorisationForm.id}`,
        this.state.selectedAuthorisationForm,
        this.updateAuthorisationFormSuccess,
        this.updateAuthorisationFormError,
        this.load,
      );
    updateAuthorisationFormSuccess = (payload) => {
      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_authorisation", "rent_authorisation"].find(
        (type) => {
          return tmpPayload[type] && tmpPayload[type] !== null;
        },
      );
      let tmpSelectedAuthorisationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: tmpPayload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmpSelectedAuthorisationForm.sale_authorisation;
      delete tmpSelectedAuthorisationForm.rent_authorisation;

      this.setState({
        selectedAuthorisationForm: tmpSelectedAuthorisationForm,
        tempEProcess: payload.e_process,
      });
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAuthorisationForms(currentPage, searchParams);

      requestSuccess("Authorisation form updated successfully.");
    };
    updateAuthorisationFormError = (error) => requestError(error);

    claimedAuthorisationForm = () =>
      Put(
        `/admin/forms/authorisation_forms/${this.state.selectedAuthorisationForm.id}/toggle_is_claimed`,
        {},
        this.claimedAuthorisationFormSuccess,
        this.claimedAuthorisationFormError,
        this.load,
      );
    claimedAuthorisationFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      Promise.all([
        this.getSelectedAuthorisationForms(
          this.state.selectedAuthorisationForm.id,
        ),
        this.getAuthorisationForms(currentPage, searchParams),
      ]).then(() => {
        requestSuccess(
          this.state.selectedAuthorisationForm.is_claimed
            ? "Authorisation form marked as submitted to claim."
            : "Authorisation form marked as pending to claim.",
        );
      });
    };
    claimedAuthorisationFormError = (error) => {
      let tmp = {
        ...this.state.selectedAuthorisationForm,
        is_claimed: false,
        tmp_claimed: false,
      };
      this.onChangeAuthorisationHOC(tmp, "selectedAuthorisationForm");
      requestError(error);
    };

    getAgentsBySearch = (val) =>
      Get(
        `/admin/users/search?type=1&name=${val}`,
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ agentList: payload.agent_selections });
    getAgentsBySearchError = (error) => requestError(error);

    createAuthorisationForm = (dataToSubmit) =>
      Post(
        `/admin/forms/authorisation_forms`,
        dataToSubmit,
        this.createAuthorisationFormSuccess,
        this.createAuthorisationFormError,
        this.load,
      );
    createAuthorisationFormSuccess = (payload) => {
      this.setState({
        showCreationForm: false,
        disableSubmitButton: false,
      });
      this.getAuthorisationForms(1, "");
      this.getSelectedAuthorisationForms(payload.id);
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
    };
    createAuthorisationFormError = (error) => {
      requestError(error);
      this.setState({ disableSubmitButton: false });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            contactList={this.state.contactList}
            authorisationFormPage={this.state.authorisationFormPage}
            selectedSigningTab={this.state.selectedSigningTab}
            agentList={this.state.agentList}
            showDownloadFormDialog={this.state.showDownloadFormDialog}
            tempEProcess={this.state.tempEProcess}
            createTypeId={this.state.createTypeId}
            createInvoiceId={this.state.createInvoiceId}
            authorisationForm={this.state.authorisationForm}
            selectedAuthorisationForm={this.state.selectedAuthorisationForm}
            disableSubmitButton={this.state.disableSubmitButton}
            showSendEmail={this.state.showSendEmail}
            showCreationForm={this.state.showCreationForm}
            showEditAuthorisationForm={this.state.showEditAuthorisationForm}
            onLoadAuthorisation={this.state.showLoadingModal}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            showDetachConfirmation={this.state.showDetachConfirmation}
            showSelectForm={this.state.showSelectForm}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            updateAuthorisationForm={this.updateAuthorisationForm}
            claimedAuthorisationForm={this.claimedAuthorisationForm}
            onChangeAuthorisationHOC={this.onChangeAuthorisationHOC}
            getAuthorisationForms={this.getAuthorisationForms}
            getSelectedAuthorisationForms={this.getSelectedAuthorisationForms}
            downloadAuthorisationForm={this.downloadAuthorisationForm}
            onChangeAuthorisationFormValue={this.onChangeAuthorisationFormValue}
            getAgentsBySearch={this.getAgentsBySearch}
            createAuthorisationForm={this.createAuthorisationForm}
            getCurrentFormContactList={this.getCurrentFormContactList}
            sendAuthorisationForm={this.sendAuthorisationForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
    refreshToken,
    storeLastView,
  })(AuthorisationFormWrappedComponent);
};

export default AuthorisationFormHOC;
