import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import { Bar } from "react-chartjs-2";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomFormHeader from "components/Form/header";
import AtPaginatedTable from "./components/Table";
import TableSearchParams from "./components/SearchParams";

import { propertyType } from "./assets";
import { numberWithCommas } from "utils/thousandSeparator";
import SubsalesStatusHOC from "actions/subsalesStatus";
import AnalyticCenterHOC from "./actions";

import "./index.scss";
import AtlasIcon from "components/Icon/atlasIcon";

const columnData = [
  {
    header: "Booking Date",
    searchFlag: "bookingDate",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) =>
      Moment(rowData.bookingDate).format("DD MMM YYYY"),
  },
  {
    header: "Address",
    accessor: "address",
    searchFlag: "address",
    columnStyle: { width: "150%" },
  },
  {
    header: "Net Price",
    searchFlag: "netPrice",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => `RM ${numberWithCommas(rowData.netPrice)}`,
  },
  {
    header: "Property Type",
    searchFlag: "propertyTypeId",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) =>
      _.find(propertyType, { value: `${rowData.propertyTypeId}` })
        ? _.find(propertyType, { value: `${rowData.propertyTypeId}` }).label
        : "N/A",
  },
];

class AnalyticCenter extends Component {
  state = {
    showSearchContent: true,
  };

  componentDidMount = () => {
    this.props.getCommRate();
  };

  onClearFilter = () => {
    let temp = _.cloneDeep(this.props.searchParams);
    temp.map((item) => (item.param = ""));
    this.props.onChangeAnalyticHOC(temp, "searchParams");
    this.props.onChangeAnalyticHOC({}, "sorting");
    this.setState({ showSearchContent: true });
  };

  renderSearchResult = () => {
    const { summary, data } = this.props.transactionResult;

    return (
      <>
        <CustomButton
          className={"btn-new btn-new--outline-secondary mb-10"}
          onClick={() => this.setState({ showSearchContent: true })}
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-arrow-left-2"}
                style={{ width: 20, height: 20 }}
              />
              Return To Search
            </>
          }
        />
        <CustomFormHeader
          title={"Search Attributes"}
          className={"mb-2 mt-3"}
          moduleIcon={"atlas-search-status"}
        />
        <div className="d-flex flex-wrap mb-10">
          {this.props.searchParams.map((item, index) => {
            if (item.param) {
              let temp = item.param;
              if (
                ["sub_sale_type_id", "property_type_id"].includes(item.value)
              ) {
                temp = _.find(item.options, { value: item.param }).label;
              }
              return (
                <span key={index} className="at-analytic-centre__badge">
                  {item.icon}
                  {item.label} : {temp}
                </span>
              );
            }
          })}
        </div>
        <div className="d-flex justify-content-between">
          {summary &&
            summary.length > 0 &&
            summary.map((item, index) => (
              <div
                key={index}
                className={"at-form__content at-analytic-centre__summary-card"}
              >
                <div className="d-flex align-items-center mb-10">
                  <div className="at-badge-icon-sm at-badge-icon-primary">
                    <AtlasIcon svgHref={"atlas-building-3"} />
                  </div>
                  <h2
                    style={{
                      fontWeight: 500,
                      fontSize: 18,
                      lineHeight: "130%",
                      color: "#111827",
                    }}
                  >
                    {item.subsaleType}
                  </h2>
                </div>
                <p>
                  <span>Range :</span>
                  RM{numberWithCommas(item.priceRangeMin.toFixed(2))} - RM
                  {numberWithCommas(item.priceRangeMax.toFixed(2))}
                </p>
                <p>
                  <span>Average :</span>
                  RM{numberWithCommas(item.priceRangeAvg.toFixed(2))}
                </p>
              </div>
            ))}
        </div>
        <AtPaginatedTable
          headerClass={"at-form_mngmt__table-header"}
          enableSort={true}
          columns={columnData}
          rowData={data || []}
          wrapPagination={true}
          meta={this.props.trensactionResultMeta}
          searchParams={[]}
          sorting={this.props.sorting}
          onClickSortAction={this.props.onClickSortAction}
          clearFilterAction={this.onClearFilter}
          getListAPI={(page) => this.props.processSearchParams(page)}
          totalPages={this.props.trensactionResulPages}
        />
        {!_.isEmpty(this.props.transactionResultGraph) && (
          <div className="at-analytic-centre__graph-cont">
            <div className="d-flex align-items-center mb-10">
              <div className="at-badge-icon-sm at-badge-icon-gray">
                <AtlasIcon svgHref={"atlas-chart-3"} />
              </div>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: "130%",
                  color: "#111827",
                }}
              >
                Trend
              </h2>
            </div>
            <Bar
              data={this.props.transactionResultGraph}
              options={{
                responsive: true,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      let label =
                        data.datasets[tooltipItem.datasetIndex].label || "";
                      return `${label}: ${numberWithCommas(tooltipItem.yLabel)}`;
                    },
                  },
                },
                scales: {
                  yAxes: [
                    {
                      type: "linear",
                      display: true,
                      position: "left",
                      id: "y-axis-1",
                      ticks: {
                        callback: (value) => numberWithCommas(value),
                      },
                    },
                    {
                      type: "linear",
                      display: true,
                      position: "right",
                      id: "y-axis-2",
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        )}
      </>
    );
  };

  render = () => {
    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const status_permissions = status_id !== 2 || this.props.whitelisted;

    return (
      <>
        <ModuleHeader
          title={"Analytics Centre - Secondary Market Transactions"}
          atlasIcon={"atlas-diagram"}
          description={`Search through and analyse the most recent sales and rental transactions and better inform your client.`}
          renderAlertContent={
            status_id === 2 && (
              <AlertBox
                mode={"danger"}
                description={
                  "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
                }
              />
            )
          }
        />
        <CustomCard
          containerStyle={{ background: "#F3F4F6", minHeight: "100%" }}
          cardContent={
            <>
              {status_permissions && this.state.showSearchContent && (
                <TableSearchParams
                  searchParams={this.props.searchParams}
                  getListAPI={() =>
                    Promise.all([this.props.processSearchParams(1)]).then(
                      () => {
                        this.setState({ showSearchContent: false });
                      },
                    )
                  }
                  onClearFilter={this.onClearFilter}
                  onChangeSearchParams={(val) =>
                    this.props.onChangeAnalyticHOC(val, "searchParams")
                  }
                />
              )}
              {!this.state.showSearchContent && this.renderSearchResult()}
            </>
          }
        />
        {this.props.onLoadAnalysis && <LoadingModal />}
      </>
    );
  };
}

export default compose(AnalyticCenterHOC, SubsalesStatusHOC)(AnalyticCenter);
