import Axios from "axios";

import { IS_FIRST_TIME_LOGIN, GET_APP_CONFIG } from "./type";

const CONFIG_SECRET_KEY =
  "$2a$10$A1n5KrFydhP9j0Nicm52zus9mogduSNSNR01NDJJoG19CFC5H6ugS";

const instance = Axios.create({
  timeout: 8000,
  headers: { "secret-key": CONFIG_SECRET_KEY },
});

export function storeFirstTime(payload) {
  return {
    type: IS_FIRST_TIME_LOGIN,
    payload,
  };
}

export const getAppConfig = () => (dispatch) => {
  instance
    .get(`https://api.jsonbin.io/b/5cad77ea85438b0272f3b206/latest`)
    .then((response) => {
      dispatch(getAppConfigSuccess(response.data));
    })
    .catch((error) => console.dir(error));
};

const getAppConfigSuccess = (payload) => ({
  type: GET_APP_CONFIG,
  payload,
});
