import React from "react";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasSelect from "components/Select/new";
import AlertBox from "components/AlertBox";

import { co_broker_representative_types } from "dictionary/subsales";
import permissionsChecker from "utils/permissionsChecker";
import "stylesheets/containers/subsales-claims/index.scss";

const ExternalAgent = ({
  from,
  banks,
  data,
  modalForm,
  enableValidateBank,
  agencies = [],
  selected_claim,
  onToggleClose,
  onChangeUpdateSelectedClaim,
  onChangeSubsaleClaimsHOC,
  updateSelectedClaim,
}) => {
  const { can_update } = permissionsChecker("Subsales Claims", data);

  let selectedAgency = _.find(agencies, {
    id: selected_claim.external_agency_id,
  });
  let tmpDictionaryRepresentative = co_broker_representative_types(selected_claim.sub_sale_type_id)
    ? co_broker_representative_types(selected_claim.sub_sale_type_id).map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }))
    : [];
  let tmpSelectedAgency = selectedAgency ? [selectedAgency] : [];

  const renderSaveButton = () => {
    if (selected_claim.claim_status === "Draft" || selected_claim.claim_status === "Rejected") {
      return (
        <CustomButton
          className={"btn-new btn-new--success"}
          tooltip={false}
          onClick={() =>
            updateSelectedClaim(
              selected_claim.id, 
              selected_claim,
              false
            )
          }
          children={"Save Changes"}
        />
      )
    }
  }

  return (
    <>
      <div className="at-form__content grid-control" style={{ gridGap: 16 }}>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Agency"}</h2>
          <CustomTypeahead
            placeholder="Enter Agency"
            className={"subsaleclaim-claimform-externalagent-selectagency"}
            typeaheadId={"agencies"}
            options={agencies}
            selectedValue={tmpSelectedAgency}
            labelKey={(option) => `${option.label}`}
            filterBy={["label"]}
            onSelect={(val) => {
              if (val && val.length > 0) {
                onChangeUpdateSelectedClaim("external_agency_id", val[0].id);
              } else {
                onChangeUpdateSelectedClaim("external_agency_id", "");
              }
            }}
            childrenHead={(rowItem) => <p key={rowItem.label}>{rowItem.label}</p>}
            highlighterData={[
              (option) =>
                `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
              <br />,
              (option) => option.address,
            ]}
            showClearButton={true}
            onClear={() => {
              let tmp = _.cloneDeep(selected_claim);
              tmp.external_agency_fee_amount = 0;
              tmp.external_agency_bank_id = 0;
              tmp.external_agency_bank_account_number = "";
              tmp.external_agency_representative_id = "";
              tmp.external_agency_id = "";
              return onChangeSubsaleClaimsHOC(tmp, "selected_claim");
            }}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Represents"}</h2>
          <AtlasRadioGroup
            containerClassName="subsaleclaim-claimform-externalagent-agencyrepresents"
            // Coagency typed form not allowed to change agency once new form has been created
            disabled={!can_update || selected_claim.form_type_id === 3} 
            checkedValue={selected_claim.external_agency_representative_id}
            selectedRadioValue={(val) =>
              onChangeUpdateSelectedClaim(
                "external_agency_representative_id",
                val,
              )
            }
            options={tmpDictionaryRepresentative}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Agency Bank Name"}</h2>
          <AtlasSelect
            rootClass={(enableValidateBank && !(selected_claim.external_agency_bank_id > 0)) ? "at-form-input--error" : ""}
            inputError={(enableValidateBank && !(selected_claim.external_agency_bank_id > 0)) ? "Cannot be empty" : ""}
            value={selected_claim.external_agency_bank_id || ""}
            disabled={!can_update}
            onChange={(e) => onChangeUpdateSelectedClaim("external_agency_bank_id", e.target.value || 0)}>
            <MenuItem
              classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
              key={0}
              value={""}
            >
              Select Agency Bank Name
            </MenuItem>
            {banks?.map((item) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.id}
                value={item.id}
              >
                {item.name} - {item.country?.name ?? "N/A"}
              </MenuItem>
            ))}
          </AtlasSelect>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">
            {"Agency Bank Account Number"}
          </h2>
          <AtlasInput
            placeholder="Enter Agency Bank Account Number"
            inputError={(enableValidateBank && selected_claim.external_agency_bank_account_number === "") ? "Cannot be empty" : ""}
            className="subsaleclaim-claimform-externalagent-agencybankaccnumber"
            type="text"
            disabled={!can_update}
            value={`${selected_claim.external_agency_bank_account_number}`}
            onChangeValue={(val) =>
              onChangeUpdateSelectedClaim(
                "external_agency_bank_account_number",
                val,
              )
            }
          />
        </section>
        {(!modalForm && can_update) && (
          <section className="grid-full-col">
            {renderSaveButton()}
          </section>
        )}
        {(modalForm && can_update) && (
          <div className="at-modal_dialog-container-footer g-3">
            {renderSaveButton()}
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onToggleClose(from === 'Agency Fee')}>
              Close
            </button>
          </div>
        )}
      </div>
      <AlertBox
        mode={"info"}
        containerClass="my-3"
        description={
          <>
            Search and select the Agency from the dropdown list (If it’s not available, ask your branch admin to add the co-agency). 
            Then, choose who the co-agent represents. <b>Enter the Agency Bank details if IQI needs to pay a commission or issue a refund to the co-agency.</b> Lastly, click “Save Changes” to complete.
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(ExternalAgent);
