import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/delete";
import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import Tabs from "components/Tab";

const SettingsDictionary = [
  {
    id: 1,
    label: "Skip referrer approval for registration?",
    value: "skip_referrer_approval_for_registration",
  },
  {
    id: 2,
    label: "Is payment required?",
    value: "is_payment_required",
  },
];

const Edit = ({
  selectedTeam = {
    personal_assistants: [],
    team_setting: {
      skip_referrer_approval_for_registration: false,
      is_payment_required: false,
    },
  },
  showDeleteConfirmation,
  onLoadTeams,

  onChangeTeamsHOC,
  createPersonalAssistant,
  updatePersonalAssistant,
  deletePersonalAssistant,
  updateTeamSettings,
  updateTeam,
}) => {
  const [selectedSection, setSelectedSection] = useState("Personal Assistant");
  const [teamData, onChangeTeam] = useState({ display_name: "" });

  useEffect(() => {
    onChangeTeam(selectedTeam);
  }, []);

  const onChangeDisplayName = useCallback(
    (val) => {
      let tmp = _.cloneDeep(teamData);
      tmp.display_name = val;

      onChangeTeam(tmp);
    },
    [teamData],
  );

  const onChangePAInfo = useCallback(
    (context, val, index) => {
      let tmp = _.cloneDeep(selectedTeam);
      tmp.personal_assistants[index][context] = val;
      onChangeTeamsHOC("selectedTeam", tmp);
    },
    [selectedTeam],
  );

  const addNewPersonalAssistant = () => {
    let tmp = _.cloneDeep(selectedTeam);
    tmp.personal_assistants.push({
      name: "",
      email: "",
      mobile_contact_number: "",
      new: true,
    });
    onChangeTeamsHOC("selectedTeam", tmp);
  };

  const removeNewPersonalAssistant = (index) => {
    let tmp = _.cloneDeep(selectedTeam);
    tmp.personal_assistants.splice(index, 1);
    onChangeTeamsHOC("selectedTeam", tmp);
  };

  const onDeletePersonalAssistant = (item) => {
    Promise.all([onChangeTeamsHOC("selectedPA", item)]).then(() => {
      onChangeTeamsHOC("showDeleteConfirmation", true);
    });
  };

  const onToggleUpdate = () => {
    switch (selectedSection) {
      case "Overview":
        return updateTeam(teamData);
      case "Setting":
        let tmpUpdateSetting = _.cloneDeep(selectedTeam);
        delete tmpUpdateSetting.personal_assistants;
        return updateTeamSettings(tmpUpdateSetting);
      default:
        return null;
    }
  };

  const renderEditTeamDisplayName = () => (
    <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
      <section className="grid-full-col">
        <h2 className="at-form-input__title"> Code </h2>
        <p className="mt-10">{teamData.name}</p>
      </section>
      <section className="grid-full-col">
        <h2 className="at-form-input__title">Visible</h2>
        <AtlasRadioGroup
          horizontal={true}
          containerClassName={"w-100"}
          checkedValue={teamData.is_visible}
          selectedRadioValue={(val) => {
            let tmp = _.cloneDeep(teamData);
            tmp.is_visible = val;
            onChangeTeam(tmp);
          }}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
      </section>
      <section className="grid-full-col">
        <h2 className="at-form-input__title" required>
          {" "}
          Display Name{" "}
        </h2>
        <CustomFormInput
          type="text"
          value={teamData.display_name}
          onChangeValue={(val) => onChangeDisplayName(val)}
          required={true}
          inputError={
            teamData.display_name === ""
              ? "Team display name cannot be empty."
              : ""
          }
          placeholder={"Enter your display name"}
        />
      </section>
    </form>
  );

  const renderEditPersonalAssistant = () => (
    <>
      <button
        className="btn-new btn-new--primary mb-3"
        onClick={() => addNewPersonalAssistant()}
      >
        <IoMdAdd style={{ width: 18, height: 18 }} />
        New Personal Assistant
      </button>
      {selectedTeam.personal_assistants &&
        selectedTeam.personal_assistants.length > 0 &&
        selectedTeam.personal_assistants.map((item, index) => (
          <div className="at-form__content" key={index}>
            <div className="row">
              <div className="col-md-4">
                <label className="at-form-input__title" required>
                  {" "}
                  Name:{" "}
                </label>
                <CustomFormInput
                  type="text"
                  value={item.name}
                  required={true}
                  onChangeValue={(val) => onChangePAInfo("name", val, index)}
                />
              </div>
              <div className="col-md-4">
                <label className="at-form-input__title" required>
                  {" "}
                  Email Address:{" "}
                </label>
                <CustomFormInput
                  type="text"
                  value={item.email}
                  required={true}
                  onChangeValue={(val) => onChangePAInfo("email", val, index)}
                />
              </div>
              <div className="col-md-4">
                <label className="at-form-input__title" required>
                  {" "}
                  Phone Number:{" "}
                </label>
                <CustomFormInput
                  type="text"
                  required={true}
                  value={item.mobile_contact_number}
                  onChangeValue={(val) =>
                    onChangePAInfo("mobile_contact_number", val, index)
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center g-3">
              <button
                className={`btn-new ${item.new ? "btn-new--secondary" : "btn-new--success"}`}
                onClick={() =>
                  item.new
                    ? createPersonalAssistant(item)
                    : updatePersonalAssistant(item)
                }
              >
                {item.new ? "Create" : "Update"}
              </button>
              <button
                className="btn-new btn-new--outline-secondary"
                style={{ color: "#F04438" }}
                onClick={() =>
                  item.new
                    ? removeNewPersonalAssistant(index)
                    : onDeletePersonalAssistant(item)
                }
              >
                Delete
              </button>
            </div>
          </div>
        ))}
    </>
  );

  const renderTeamSetting = () => {
    const { team_setting } = selectedTeam;
    return (
      <div className="at-form_fields_cont">
        {SettingsDictionary.map((item) => (
          <div className="at-form_field-col-12">
            <h2 className="at-form-input__title">{item.label}</h2>
            <AtlasRadioGroup
              horizontal={true}
              containerClassName={"w-100"}
              checkedValue={team_setting[item.value]}
              selectedRadioValue={(val) => {
                let tmp = _.cloneDeep(selectedTeam);
                tmp["team_setting"][item.value] = val;
                onChangeTeamsHOC("selectedTeam", tmp);
              }}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <ModalDialog
        title={"Edit Team"}
        responsiveSize="lg"
        fullHeight={true}
        onClose={() => onChangeTeamsHOC("showUpdateTeam", false)}
        children={
          <>
            <Tabs
              containerClass="mt-2"
              sections={["Overview", "Personal Assistant", "Setting"]}
              selectedSection={selectedSection}
              onSelectSection={setSelectedSection}
            />
            {selectedSection === "Overview" && renderEditTeamDisplayName()}
            {selectedSection === "Personal Assistant" &&
              renderEditPersonalAssistant()}
            {selectedSection === "Setting" && renderTeamSetting()}
            <div className="at-modal_dialog-container-footer g-3">
              {selectedSection !== "Personal Assistant" && (
                <button
                  className="btn-new btn-new--success"
                  disabled={
                    selectedSection === "Overview" && !teamData.display_name
                  }
                  onClick={() => onToggleUpdate()}
                >
                  {selectedSection === "Overview" ? "Update Details" : "Update"}
                </button>
              )}
              <button
                className="btn-new btn-new--outline-secondary"
                onClick={() => onChangeTeamsHOC("showUpdateTeam", false)}
              >
                Close
              </button>
            </div>
          </>
        }
      />
      <ConfirmationModal
        title={"Delete Personal Assistant"}
        loading={onLoadTeams}
        open={showDeleteConfirmation}
        message={`Are you sure you want to remove this personal assistant?`}
        positiveAction={() => deletePersonalAssistant()}
        negativeAction={() => onChangeTeamsHOC("showDeleteConfirmation", false)}
      />
      {onLoadTeams && <LoadingModal />}
    </>
  );
};

export default Edit;
