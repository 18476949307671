import React, { Component } from "react";
import SVGIcon from "components/Icon";
import _ from "lodash";

import "stylesheets/components/pagination/index.scss";

class Pagination extends Component {
  state = {
    currentPage: 1,
    listNumber: 0,
    totalPages: [1],
    totalSplitPages: [1],
    splitPages: [1],
    disableFirstPageButton: true,
    disablePreviousButton: false,
    disableNextList: false,
    disablePrevList: true,
    disableNextButton: false,
    disableLastPageButton: false,
  };

  componentDidUpdate = (pp) => {
    if (pp.totalPages !== this.props.totalPages) {
      let arrays = [];
      const totalPagesArray = _.cloneDeep(this.props.totalPages);
      while (totalPagesArray.length > 0) {
        arrays.push(totalPagesArray.splice(0, 10));
      }
      const tempArray = _.cloneDeep(arrays[0]);
      this.setState({
        listNumber: 0,
        totalPages: this.props.totalPages,
        totalSplitPages: arrays,
        splitPages: tempArray,
        currentPage: 1,
        disablePreviousButton: true,
        disableFirstPageButton: true,
        disableNextList: arrays.true,
        disableNextList: arrays.length < 2 ? true : false,
        disableNextButton: this.props.totalPages.length <= 1,
        disableLastPageButton: this.props.totalPages.length <= 1,
      });
    }
  };

  nextList = () => {
    this.setState((prevState) => ({
      listNumber: prevState.listNumber + 1,
      splitPages: this.state.totalSplitPages[prevState.listNumber + 1],
      disablePrevList: false,
      disableNextList:
        prevState.listNumber + 1 === this.state.totalSplitPages.length - 1
          ? true
          : false,
    }));
  };

  prevList = () => {
    this.setState((prevState) => ({
      listNumber: prevState.listNumber - 1,
      splitPages: this.state.totalSplitPages[prevState.listNumber - 1],
      disableNextList: false,
      disablePrevList: prevState.listNumber - 1 === 0 ? true : false,
    }));
  };

  goToFirstPage = () => {
    this.setState(
      {
        listNumber: 0,
        currentPage: 1,
        splitPages: this.state.totalSplitPages[0],
        disableFirstPageButton: true,
        disablePreviousButton: true,
        disableLastPageButton: false,
        disableNextButton: false,
        disablePrevList: true,
        disableNextList: this.state.totalSplitPages.length < 2 ? true : false,
      },
      () => {
        this.props.onClickPageNumber(this.state.currentPage);
      },
    );
  };

  updateCurrentPage = (type) => {
    if (type === "previous") {
      this.setState(
        (prevState) => ({
          currentPage: prevState.currentPage - 1,
          disableFirstPageButton: prevState.currentPage - 1 === 1,
          disablePreviousButton: prevState.currentPage - 1 === 1,
          disableLastPageButton: false,
          disableNextButton: false,
        }),
        () => {
          this.props.onClickPageNumber(this.state.currentPage);
        },
      );
    } else if (type === "next") {
      this.setState(
        (prevState) => ({
          currentPage: prevState.currentPage + 1,
          disableFirstPageButton: false,
          disablePreviousButton: false,
          disableLastPageButton:
            prevState.currentPage + 1 === this.state.totalPages.length,
          disableNextButton:
            prevState.currentPage + 1 === this.state.totalPages.length,
        }),
        () => {
          this.props.onClickPageNumber(this.state.currentPage);
        },
      );
    }
  };

  goToLastPage = () => {
    this.setState(
      {
        listNumber: this.state.totalSplitPages.length - 1,
        splitPages:
          this.state.totalSplitPages[this.state.totalSplitPages.length - 1],
        currentPage: this.state.totalPages.length,
        disableNextButton: true,
        disableLastPageButton: true,
        disableFirstPageButton: false,
        disablePreviousButton: false,
        disablePrevList: this.state.totalSplitPages.length < 2 ? true : false,
        disableNextList: true,
      },
      () => {
        this.props.onClickPageNumber(this.state.currentPage);
      },
    );
  };

  onClickPageNumber = (item) => {
    this.setState({
      currentPage: item,
      disableFirstPageButton: item === 1,
      disablePreviousButton: item === 1,
      disableLastPageButton: item === this.state.totalPages.length,
      disableNextButton: item === this.state.totalPages.length,
    });
    this.props.onClickPageNumber(item);
  };

  render = () => {
    const { totalPages } = this.props;
    const highlightPageButton = (param) => {
      if (param === this.state.currentPage) {
        return "active";
      }
    };
    return (
      <div className={`at-pagination ${this.props.className || ""}`}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableFirstPageButton ? "disabled" : ""}`}
          onClick={() => this.goToFirstPage()}
        >
          <SVGIcon
            className="at-pagination__arrow rotate-left"
            svgHref={"icon-double-chevron"}
          />
        </button>
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disablePreviousButton ? "disabled" : ""}
          `}
          onClick={() => this.updateCurrentPage("previous")}
        >
          <SVGIcon
            className="at-pagination__arrow rotate-left"
            svgHref={"icon-chevron"}
          />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.disablePrevList ? "disabled" : ""}`}
          onClick={() => this.prevList()}
        >
          ...
        </button>
        {this.state.splitPages &&
          this.state.splitPages.map((item) => {
            return (
              <button
                key={item}
                className={`at-pagination__button ${highlightPageButton(item)}`}
                onClick={() => this.onClickPageNumber(item)}
              >
                {item}
              </button>
            );
          })}
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableNextList ? "disabled" : ""}
          `}
          onClick={() => this.nextList()}
        >
          ...
        </button>
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableNextButton ? "disabled" : ""}
          `}
          onClick={() => this.updateCurrentPage("next")}
        >
          <SVGIcon
            className="at-pagination__arrow rotate-right"
            svgHref={"icon-chevron"}
          />
        </button>
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableLastPageButton ? "disabled" : ""}
          `}
          onClick={() => this.goToLastPage()}
        >
          <SVGIcon
            className="at-pagination__arrow rotate-right"
            svgHref={"icon-double-chevron"}
          />
        </button>
      </div>
    );
  };
}

export default Pagination;
