import { STORE_SELECTED_AGENT_INFO } from "actions/type";

let initialState = {
  selectedAgent: {},
};

export default function salesReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_SELECTED_AGENT_INFO:
      return {
        ...state,
        selectedAgent: action.payload,
      };
    default:
      return state;
  }
}
