import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    resubmitOrderItem = (dataToSubmit) =>
      Post(
        `/market_order_items/${dataToSubmit.id}/resubmit`,
        dataToSubmit,
        this.resubmitOrderItemSuccess,
        this.resubmitOrderItemError,
        this.load,
      );
    resubmitOrderItemSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getOrderHistory(currentPage, searchParams);

      this.props.onChangeOrderHistoryHOC(false, "showResubmitConfirmation");
      requestSuccess(payload.message);
    };
    resubmitOrderItemError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadResubmit={this.state.loading}
            resubmitOrderItem={this.resubmitOrderItem}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
