import React, { useState } from "react";
import _ from "lodash";
import { FaEdit } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";

import AtlasDialog from "components/Dialog";
import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import RenewalDetail from "./renewalDetails";
import RenewalDetailHOC from "./actions";

import { getColorBadge } from "dictionary/badgeColor";
import { statusColor } from "../assets";
import { columnData } from "./assets";

const ReaRenewalManagementDetail = ({
  searchParams,
  selectedRenewal,
  selectedApplication,
  showRenewalApplication,
  onLoadRenewalApplication,

  getSelectedRenewal,
  getSelectedReaRenewal,
  onChangeRenewalDetailsHOC,
  onChangeRenewalManagementHOC,
  getSelectedRenewalApplication,
  ...props
}) => {
  const { colorName } = _.find(statusColor, { value: selectedRenewal.status });
  const [showInfoBox, onToggleInfoBox] = useState(false);

  return (
    <>
      <ModalDialog
        title={`License Renewal Details`}
        fullWidth={true}
        fullHeight={true}
        onClose={() =>
          onChangeRenewalManagementHOC(false, "showRenewalDetails")
        }
        children={
          <div className="d-flex flex-column g-3">
            <div className="d-flex align-items-center g-3">
              <div className="at-badge-icon at-badge-icon-warning">
                <AtlasIcon svgHref={"atlas-personalcard"} />
              </div>
              <h2 className="at-card__title">
                REA Renewal - {selectedRenewal.renewal_year}
              </h2>
              <div
                className="at-status_badge"
                style={{ ...getColorBadge(colorName) }}
              >
                {selectedRenewal.status}
              </div>
            </div>
            <div className="d-flex flex-column g-2">
              <div
                className="d-flex align-items-center fw-500"
                style={{ fontSize: 14, color: "#1F2937" }}
              >
                <AtlasIcon
                  svgHref={"atlas-calendar-2"}
                  style={{
                    width: 20,
                    height: 20,
                    fill: "#EA580C",
                    marginRight: 10,
                  }}
                />
                Renewal Year: {selectedRenewal.renewal_year}
              </div>
              <div
                className="d-flex align-items-center fw-500"
                style={{ fontSize: 14, color: "#1F2937" }}
              >
                <AtlasIcon
                  svgHref={"atlas-clock"}
                  style={{
                    width: 20,
                    height: 20,
                    fill: "#EA580C",
                    marginRight: 10,
                  }}
                />
                Last Submitted Date: {selectedRenewal.last_submit_date}
              </div>
            </div>
            {selectedRenewal.licence_renewal_settings.length > 0 && (
              <CustomInfobox
                expand={showInfoBox}
                expandIcon={<BsCheck />}
                collapseIcon={<BsCheck />}
                inactiveContent={"Age and CPD credit hours guideline"}
                onClickExpandInfo={onToggleInfoBox}
                activeContent={
                  <>
                    Age and CPD credit hours guideline
                    <div className="mt-3">
                      {selectedRenewal.licence_renewal_settings.map(
                        (item, index) => (
                          <p
                            style={{
                              color: "#374151",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            {item.description}
                          </p>
                        ),
                      )}
                    </div>
                  </>
                }
              />
            )}
            <div className="at-rea-mngm__card-cont">
              <div className="at-form__content mb-0">
                <AtlasIcon
                  svgHref={"atlas-more-2"}
                  style={{ fill: "#FFFFFF", backgroundColor: "#F79009" }}
                />
                <div className="ml-3">
                  <label>Pending</label>
                  <p>{selectedRenewal.total_pending_count}</p>
                </div>
              </div>
              <div className="at-form__content mb-0">
                <AtlasIcon
                  svgHref={"atlas-rotate-right"}
                  style={{ fill: "#FFFFFF", backgroundColor: "#06B6D4" }}
                />
                <div className="ml-3">
                  <label>In Progress</label>
                  <p>{selectedRenewal.total_processing_count}</p>
                </div>
              </div>
              <div className="at-form__content mb-0">
                <AtlasIcon
                  svgHref={"atlas-tick-square"}
                  style={{ fill: "#FFFFFF", backgroundColor: "#12B76A" }}
                />
                <div className="ml-3">
                  <label>Collected</label>
                  <p>{selectedRenewal.total_collected_count}</p>
                </div>
              </div>
            </div>
            <AtPaginatedTable
              rowData={selectedRenewal.licence_renewal_applicants || []}
              meta={{}}
              columns={columnData}
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeRenewalDetailsHOC(val, "searchParams")
              }
              actionColumnContent={[
                {
                  name: "details",
                  onClick: (rowData) =>
                    getSelectedRenewalApplication(
                      selectedRenewal.id,
                      rowData.id,
                    ),
                },
              ]}
              totalPages={["1"]}
              getListAPI={(page, search) => getSelectedRenewal(search)}
            />
          </div>
        }
      />
      {showRenewalApplication && (
        <ModalDialog
          title={"REA Renewal Application Details"}
          fullWidth={true}
          fullHeight={true}
          onClose={() =>
            onChangeRenewalDetailsHOC(false, "showRenewalApplication")
          }
          children={
            <RenewalDetail
              {...props}
              selectedRenewal={selectedRenewal}
              selectedApplication={selectedApplication}
              onLoadRenewalApplication={onLoadRenewalApplication}
              onChangeRenewalDetailsHOC={onChangeRenewalDetailsHOC}
            />
          }
        />
      )}
    </>
  );
};

export default RenewalDetailHOC(ReaRenewalManagementDetail);
