import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { BsTelegram } from "react-icons/bs";
import ReactHtmlParser from "react-html-parser";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { Dialog, DialogContent } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import CrossButton from "components/Button/close";
import LoadingModal from "components/LoadingModal";

import Heading from "./heading";
import ProjectDocs from "./documents";
import ProjectDetailsFooter from "./footer";
import ProjectImages from "./projectImages";
import ProjectDetails from "./projectDetails";
import ProjectPhotoSlides from "./photoSlides";
import ProjectSpecifications from "./specifications";

import { storeIQIDriveData } from "actions/iqidrive";

import "../projects.scss";

const script =
  '<script src="https://unpkg.com/s3bubble-javascript/s3bubble.min.js"></script>';

const link = `<link rel="stylesheet" href="/scss/style.css"></link>
  <link rel="stylesheet" href="/scss/font-awesome.css"></link>
  <link rel="stylesheet" href="/scss/components/common/index.css"></link>
  <link rel="stylesheet" href="/scss/components/pages/index.css"></link>
  <link rel="stylesheet" href="/scss/components/sections/index.css"></link>
  <link rel="stylesheet" href="/scss/index.css"></link>

  <!--Preload-->
  <link rel="preload" href="/assets/fonts/fontawesome-webfont.woff2?v=4.7.0" as="font" crossorigin>
  <link rel="preload" href="/assets/fonts/opal-icon.woff2" as="font" crossorigin>

  <!--Google font-->
  <link href="https://fonts.googleapis.com/" rel="preconnect" crossorigin>
  <link href="https://fonts.googleapis.com/css?family=Marcellus+SC%7CMontserrat:300,400,500,600,700&display=swap" rel="stylesheet">

  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.12.0/css/all.css">
  <link rel="stylesheet" href="https://unpkg.com/s3bubble-javascript/s3bubble.min.css" ' />`;

class Details extends Component {
  state = {
    dataCode: "",
    openVideo: false,
  };

  toggleVideo = (code) =>
    this.setState((prevState) => ({
      openVideo: !prevState.openVideo,
      dataCode: code ? code : "",
    }));

  renderFrameHeight = () => {
    let iframe = document.getElementById("project-frame");
    iframe.height = iframe.contentWindow.document.body.scrollHeight;
  };

  renderFeatures = (data, stateName) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {data && data.length !== 0 ? (
          data.map((item, index) => (
            <div
              className="at-project__detail-feature-item"
              key={`ProjectKeyFeatures-${index}`}
            >
              <AtlasIcon svgHref={`atlas-arrow-right-1`} />
              {item[stateName]}
            </div>
          ))
        ) : (
          <span style={{ color: "#1F2937" }}>N/A</span>
        )}
      </div>
    );
  };

  renderVideoDialog = () => (
    <Dialog
      classes={{ paper: "at-project-video-paper" }}
      style={{ padding: 20 }}
      open={this.state.openVideo}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <CrossButton
        containerClass={"at-close-video__button"}
        containerStyle={{ position: "absolute", right: 0 }}
        tooltip={false}
        onClick={() => this.setState({ openVideo: false })}
      />
      <DialogContent
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Frame
          className={`frame-container at-video__frame__container`}
          initialContent={`<!DOCTYPE html><html style="background-color: black" class="at-project-video"><head>${link}</head><body><div id="video-container"></div>${'<script src="https://unpkg.com/s3bubble-javascript/s3bubble.min.js"></script>'}</body></html>`}
          mountTarget={"#video-container"}
        >
          <FrameContextConsumer>
            {
              // Callback is invoked with iframe's window and document instances
              ({ document, window }) => {
                return (
                  <div
                    className="s3bubble at-project-video"
                    data-code={this.state.dataCode}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                );
              }
            }
          </FrameContextConsumer>
        </Frame>
      </DialogContent>
    </Dialog>
  );

  renderVisualisation = () => (
    <div className="at-project-listing__project-360">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-3d-rotate" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>360 Visualisations</h1>
      </div>
      {this.props.visualisations.map((item) => {
        return (
          <div
            key={item.id}
            className="at-project__details-link"
            onClick={() => window.open(item.url, "_blank")}
          >
            <AtlasIcon
              svgHref={`atlas-link-2-linear`}
              style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
            />
            {item.title}
          </div>
        );
      })}
    </div>
  );

  renderTelegramLink = () => (
    <div className="at-project-listing__project-telegram">
      <div className="at-project__detail-header">
        <BsTelegram />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Telegram Link</h1>
      </div>
      <div
        className="at-project__details-link"
        onClick={() =>
          window.open(this.props.selectedProject.telegram_link, "_blank")
        }
      >
        <AtlasIcon
          svgHref={`atlas-link-2-linear`}
          style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
        />
        {this.props.selectedProject.telegram_link}
      </div>
    </div>
  );

  renderVideoSections = () => (
    <div className="at-project-listing__project-video">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-video-circle" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Tutorial Video</h1>
      </div>
      {this.props.videos.map((item) => {
        return (
          <div
            key={item.id}
            className="at-project__details-link"
            onClick={() => this.toggleVideo(item.streaming_code)}
          >
            <AtlasIcon
              svgHref={`atlas-link-2-linear`}
              style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
            />
            {item.title}
          </div>
        );
      })}
    </div>
  );

  renderDeveloper = () => {
    const dataArray = [
      { label: "Developer", value: "developer", icon: "building" },
      { label: "Phase", value: "phase", icon: "signpost" },
      {
        label: "Expected Completion",
        value: "completion_date",
        icon: "calendar",
      },
    ];

    return (
      <>
        {dataArray.map((item, index) => (
          <div key={index} className={`at-project__details-item`}>
            <AtlasIcon svgHref={`atlas-${item.icon}`} />
            <label>{item.label}</label>
            <span>
              {this.props.selectedProject[item.value]
                ? this.props.selectedProject[item.value]
                : `N/A`}
            </span>
          </div>
        ))}
      </>
    );
  };

  render = () => {
    const { documents, selectedProject, storeIQIDriveData } = this.props;
    const {
      name,
      key_features,
      project_features,
      project_photos,
      is_featured,
      title_description,
    } = this.props.selectedProject;

    return (
      <div className={`detail-content-block`}>
        <div className="at-project__detail-title">
          <h4>{selectedProject.internal_name}</h4>
          <p>{selectedProject.location}</p>
        </div>
        <Frame
          className={`frame-container`}
          id={"project-frame"}
          contentDidMount={this.renderFrameHeight}
          contentDidUpdate={this.renderFrameHeight}
          style={{ width: "100%", border: "none" }}
          initialContent={`<!DOCTYPE html><html><head>${link}</head><body><div id="project-container"></div>${script}</body></html>`}
          mountTarget={"#project-container"}
        >
          <FrameContextConsumer>
            {
              // Callback is invoked with iframe's window and document instances
              () => {
                return (
                  <>
                    {(!project_photos || project_photos.length < 1) && (
                      <h4 className="at-card__title mr-3 mt-5">{name}</h4>
                    )}
                    {is_featured && (
                      <span className="project-featured-tag">{"Featured"}</span>
                    )}
                    {project_photos && project_photos.length > 0 && (
                      <div className={`section-wrapper pt-0 pb-0`}>
                        <ProjectPhotoSlides {...selectedProject} />
                      </div>
                    )}
                    {title_description &&
                      !title_description.includes("<p></p>") && (
                        <div
                          className={`title-description`}
                          style={{ textAlign: "justify", padding: "15px 15px" }}
                        >
                          <span
                            className={`${title_description.length > 121 ? `justify-text` : `project-center text-center`}`}
                          >
                            {ReactHtmlParser(title_description)}
                          </span>
                        </div>
                      )}
                    <ProjectImages selectedProject={selectedProject} />
                    <div className="at-project-listing__details-features">
                      <ProjectDetails selectedProject={selectedProject} />
                      <ProjectSpecifications
                        selectedProject={selectedProject}
                      />
                      <div className="at-project-listing__project-developer">
                        <div className="at-project__detail-header">
                          <AtlasIcon svgHref="atlas-buildings" />
                          <h1 style={{ fontSize: 18, fontWeight: 600 }}>
                            Developer
                          </h1>
                        </div>
                        {this.renderDeveloper()}
                      </div>
                      <div className="at-project-listing__project-features">
                        <div className="at-project__detail-header">
                          <AtlasIcon svgHref="atlas-building" />
                          <h1 style={{ fontSize: 18, fontWeight: 600 }}>
                            Key Features
                          </h1>
                        </div>
                        {this.renderFeatures(key_features, "description")}
                      </div>
                      <div className="at-project-listing__project-features">
                        <div className="at-project__detail-header">
                          <AtlasIcon svgHref="atlas-building" />
                          <h1 style={{ fontSize: 18, fontWeight: 600 }}>
                            Project Features
                          </h1>
                        </div>
                        {this.renderFeatures(
                          project_features,
                          "name",
                          "project-feature-block",
                        )}
                      </div>
                      {this.props.visualisations &&
                        this.props.visualisations.length > 0 &&
                        this.renderVisualisation()}
                      {this.props.documents &&
                        this.props.documents.length > 0 && (
                          <ProjectDocs
                            documents={documents}
                            storeIQIDriveData={storeIQIDriveData}
                          />
                        )}
                      {this.props.videos &&
                        this.props.videos.length > 0 &&
                        this.renderVideoSections()}
                    </div>
                    <ProjectDetailsFooter />
                    {this.props.onLoadProject && <LoadingModal />}
                  </>
                );
              }
            }
          </FrameContextConsumer>
        </Frame>
        {this.renderVideoDialog()}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, {
  storeIQIDriveData,
})(Details);
