import React, { useCallback } from "react";

import AtlasCloseButton from "components/Button/close";
import {
  IoIosHome,
  IoIosPerson,
  IoIosCalendar,
  IoIosNotifications,
  IoMdQrScanner,
} from "react-icons/io";
import { ImGoogleDrive } from "react-icons/im";
import { BiMessageDetail } from "react-icons/bi";
import SVGIcon from "components/Icon/new";

const generalItem = [
  { label: "Gloabl Network", icon: "icon-globe" },
  { label: "Project Centre", icon: "icon-project-centre" },
];

const teamItem = [
  { label: "Team Sales", icon: "icon-team-sales" },
  { label: "Team Member", icon: "icon-team" },
  { label: "My Hierarchy", icon: "icon-hierarchy" },
  { label: "Project Sales", icon: "icon-project-sales" },
  { label: "Team Performance", icon: "icon-team-performance" },
  { label: "Team Birthday", icon: "icon-team-birthday" },
  { label: "New Agent Approval", icon: "icon-new-agent" },
];

const MobilePreview = ({ mobilePreview, onChangeAppThemeManagementHOC }) => {
  const renderHeader = useCallback(() => {
    return (
      <>
        <div className="at-app-theme__mobile-preview-header">
          <p className="col-4"></p>
          <p className="col-4">Dashboard</p>
          <IoIosNotifications
            className="col-4"
            style={{ maxWidth: "55px", color: "white", marginLeft: "auto" }}
          />
        </div>
        <div className="at-app-theme__mobile-preview-tab">
          <p className="col-4">Newfeed</p>
          <p className="col-4 selected-tab">Menu</p>
          <p className="col-4">Data</p>
        </div>
      </>
    );
  }, []);

  const renderBody = useCallback(() => {
    return (
      <>
        <div className="d-flex py-3">
          <div className="col-7 at-app-theme__mobile-preview-user-info">
            <p>
              <img src="/static/media/iqi_logo_only.befee8ec.png" alt="iqi" />
              Welcome
            </p>
            <p className="username">User Preview</p>
            <p>preview@example.com</p>
            <p className="message-of-the-day">
              <BiMessageDetail
                style={{ width: "15px", margin: "0 10px", color: "#f18e05" }}
              />
              Message of the day
            </p>
          </div>
          <img
            className="col-5 at-app-theme__mobile-preview-user-image"
            src="https://atlaspg.iqiglobal.com/img/av1.png"
            alt="User Preview"
          />
        </div>
        <div
          className="at-app-theme__mobile-preview-section-conatiner px-4"
          style={{
            backgroundImage: `url(${mobilePreview.background_image_url})`,
          }}
        >
          {generalItem.map((item) => {
            return (
              <div className="col-4 at-app-theme__mobile-preview-item-conatiner">
                <SVGIcon
                  className={`svg-${item.icon} mt-3`}
                  svgHref={item.icon}
                  style={{ fill: mobilePreview.icon_colour }}
                />
                <p
                  className="my-2"
                  style={{ color: mobilePreview.icon_colour }}
                >
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
        <div
          className="row at-app-theme__mobile-preview-section-conatiner px-4"
          style={{
            backgroundImage: `url(${mobilePreview.background_image_url})`,
          }}
        >
          {teamItem.map((item) => {
            return (
              <div className="col-4 at-app-theme__mobile-preview-item-conatiner">
                <SVGIcon
                  className={`svg-${item.icon} mt-3`}
                  svgHref={item.icon}
                  style={{ fill: mobilePreview.icon_colour }}
                />
                <p
                  className="my-2"
                  style={{ color: mobilePreview.icon_colour }}
                >
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
      </>
    );
  }, [mobilePreview]);

  const renderFooter = useCallback(() => {
    return (
      <div className="at-app-theme__mobile-preview-footer">
        <IoIosHome
          style={{ width: "25px", margin: " auto", color: "#fff037" }}
        />
        <IoIosPerson
          style={{ width: "25px", margin: " auto", color: "#fff037" }}
        />
        <IoMdQrScanner className="at-app-theme__mobile-preview-scanner" />
        <IoIosCalendar
          style={{ width: "25px", margin: " auto", color: "#fff037" }}
        />
        <ImGoogleDrive
          style={{ width: "25px", margin: " auto", color: "#fff037" }}
        />
      </div>
    );
  }, []);

  return (
    <div className="d-flex at-app-theme__mobile-preview-overlay">
      <div className="at-app-theme__mobile-preview-container">
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
        <AtlasCloseButton
          containerStyle={{
            position: "absolute",
            top: "-15px",
            right: "-20px",
          }}
          onClick={() =>
            onChangeAppThemeManagementHOC(false, "showMobilePreview")
          }
        />
      </div>
    </div>
  );
};

export default MobilePreview;
