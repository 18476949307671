import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";

import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomToggle from "components/Button/toggle";

import "./search.scss";

class SearchForm extends Component {
  state = {
    yearDropdownOption: [],
    selectedYear: "",
    hideAgentsWithoutSales: false,
    selectedStartDate: null,
    selectedEndDate: null,
  };

  componentDidMount() {
    this.initYearSelectOptions();
  }

  initYearSelectOptions() {
    const { selectedYear } = this.props;
    const currentYear = parseInt(Moment().format("YYYY"), 10);
    const yearDropdownOption = [];
    let tmpDate = new Date();
    for (let i = 0; i < 10; i++) {
      yearDropdownOption.push({
        id: i,
        label:
          tmpDate.getMonth() === 11 ? currentYear + 1 - i : currentYear - i,
        value:
          tmpDate.getMonth() === 11 ? currentYear + 1 - i : currentYear - i,
      });
    }
    return this.setState({ yearDropdownOption, selectedYear: selectedYear });
  }

  selectYear(item) {
    this.setState({ selectedYear: item.value }, () => {
      this.props.onSelectYear(item.value);
    });
  }

  onSelectStartDate(val) {
    this.props.onSelectStartDate(val);
  }

  onSelectEndDate(val) {
    this.props.onSelectEndDate(val);
  }

  onClickRefreshDate() {
    this.props.onClickSubmit();
  }

  onTickHideAgents(val) {
    this.props.onClickHideAgentsWithoutSales();
  }

  onClickDownloadButton() {
    this.props.onClickDownload();
  }

  render() {
    const {
      dateFormat,
      showHideAgentsCheckbox,
      yearOnly,
      hideSearch,
      containerClass,
      downloadBtn,
      exportBtn,
    } = this.props;
    return (
      <>
        <div className={`at-datepicker ${containerClass || ""}`}>
          {!yearOnly && (
            <section>
              <p className="title">From</p>
              <div className="at-form-input mb-0">
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-2-linear"}
                />
                <DatePicker
                  showYearDropdown
                  dateFormat={dateFormat || "yyyy-MM-DD"}
                  className="at-form-input__input"
                  value={this.props.selectedStartDate}
                  maxDate={new Date()}
                  selected={
                    this.props.selectedStartDate
                      ? new Date(this.props.selectedStartDate)
                      : this.props.selectedStartDate
                  }
                  onChange={(val) =>
                    this.onSelectStartDate(
                      Moment(val).format(dateFormat || "yyyy-MM-DD"),
                    )
                  }
                />
              </div>
            </section>
          )}
          {!yearOnly && (
            <section>
              <p className="title">To</p>
              <div className="at-form-input mb-0">
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-2-linear"}
                />
                <DatePicker
                  showYearDropdown
                  dateFormat={dateFormat || "yyyy-MM-DD"}
                  className="at-form-input__input"
                  value={this.props.selectedEndDate}
                  maxDate={new Date()}
                  selected={
                    this.props.selectedEndDate
                      ? new Date(this.props.selectedEndDate)
                      : this.props.selectedEndDate
                  }
                  onChange={(val) =>
                    this.onSelectEndDate(
                      Moment(val).format(dateFormat || "yyyy-MM-DD"),
                    )
                  }
                />
              </div>
            </section>
          )}
          {yearOnly && (
            <section>
              <CustomSelect
                className={"at-datepicker__select"}
                placeholder={this.state.selectedYear}
                selectItems={this.state.yearDropdownOption}
                currentlySelected={{ label: this.state.selectedYear }}
                updateSelect={(item) => this.selectYear(item)}
              />
            </section>
          )}
          <div className="at-datepicker_actions">
            <CustomButton
              tooltip={false}
              className={
                "btn-new btn-new--outline-secondary at-datepicker__btn mr-2"
              }
              onClick={() => {
                this.onSelectStartDate(
                  Moment()
                    .year(new Date().getFullYear())
                    .month(0)
                    .date(1)
                    .format("YYYY-MM-DD"),
                );
                this.onSelectEndDate(Moment(new Date()).format("YYYY-MM-DD"));
                this.onClickRefreshDate();
              }}
              children={"Reset"}
            />
            {!hideSearch && (
              <CustomButton
                tooltip={false}
                className={"btn-new btn-new--secondary at-datepicker__btn mr-2"}
                onClick={() => this.onClickRefreshDate()}
                children={"Search"}
              />
            )}
            {downloadBtn && (
              <CustomButton
                tooltip={false}
                className={"btn-new btn-new--secondary at-datepicker__btn"}
                onClick={() => this.onClickDownloadButton()}
                children={"Download"}
              />
            )}
          </div>
        </div>
        {showHideAgentsCheckbox && (
          <div className="d-flex">
            <CustomToggle
              className={"at-toggle__small mt-1"}
              currentState={this.props.showAgentsWithoutSales}
              onToggleButton={() => this.onTickHideAgents()}
            />
            <div className="at-hide-agent">
              <p className="subtitle">Hide Agents Without Any Sales</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SearchForm;
