import React, { Component } from "react";
import _ from "lodash";
import { Chip, FormHelperText } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";

import ProfileImage1 from "assets/images/av-male.jpg";

class CobrokerCard extends Component {
  state = {
    unsaveChanges: false,
    selectedAgency: [],

    valid_from: new Date(),
    valid_to: new Date(),
    commission_type_id: 1,
    claim_percentage: 0,
    agency_id: 0,
    agency_bank_id: 0,
    agency_account_number: "",
    agency_payable_name: "",
    representative_id: 0,
    extra_collection_amount: 0,
  };

  componentDidMount = () => this.setStateForCard();

  componentDidUpdate = (pp) => {
    const { data } = this.props;
    Object.keys(data).map((key) => {
      if (data[key] !== pp.data[key]) {
        this.setStateForCard();
      }
    });
  };

  setStateForCard = () => {
    const { data } = this.props;
    const { is_internal } = data;
    if (is_internal) {
      this.setState({
        claim_percentage: data.claim_percentage,
        extra_collection_amount: data.extra_collection_amount,
        administration_fee_amount: data.administration_fee_amount,
        stamping_fee_amount: data.stamping_fee_amount,
      });
    }
  };

  onSelectStartDate = (val) =>
    this.setState({ valid_from: val ? new Date(val) : "" });

  onSelectEndDate = (val) =>
    this.setState({ valid_to: val ? new Date(val) : "" });

  onChange = (val, context) =>
    this.setState({ [context]: val, unsaveChanges: true });

  onClickRemoveCobrokerCard = (data) =>
    this.props.onClickRemoveCobrokerCard(data);

  onClickSaveCobrokerCard = (id) => {
    const { data } = this.props;
    const { is_internal } = data;
    if (is_internal) {
      let tmp = {
        claim_percentage: this.state.claim_percentage,
        extra_collection_amount: this.state.extra_collection_amount,
        administration_fee_amount: this.state.administration_fee_amount,
        stamping_fee_amount: this.state.stamping_fee_amount,
      };
      this.props.onClickSaveCobrokerCard(data, tmp);
    }
    this.setState({ unsaveChanges: false });
  };

  renderUserProfileSection = () => {
    const { data, onClickEditProfile, disableEdit, isOppositeClaim } = this.props;
    const { is_internal, is_myself } = data;
    const renderEditProfileButton = () => (
      <button
        className="btn-new btn-new--success mt-3"
        onClick={() => onClickEditProfile(data.co_broker_id)}
        disabled={disableEdit}
      >
        Edit Contact
      </button>
    );
    return (
      <div className="at-subsales_claims-cobroke-agent">
        <div className="d-flex align-items-center">
          <img
            alt={`Cobroker`}
            className={"at-subsales_claims-cobroke-avatar"}
            src={
              data.co_broker.user.avatar_url
                ? data.co_broker.user.avatar_url
                : ProfileImage1
            }
            onError={(e) => {
              if (e.target) {
                e.target.src = ProfileImage1;
              }
            }}
          />
          {is_internal && (
            <div className={"col-xl-7 col-lg-12 col-12 mt-10"}>
              {is_myself && (
                <Chip
                  label={ isOppositeClaim ? "Primary" : "Myself" }
                  classes={{ root: "at-subsales_claims-cobroke-badge" }}
                  style={{
                    backgroundColor: "#FFEDD5",
                    color: "#C2410C",
                    border: "1px solid #FDBA74",
                  }}
                />
              )}
              {!is_myself && (
                <Chip
                  label={"Co-broke Agent"}
                  classes={{ root: "at-subsales_claims-cobroke-badge" }}
                  style={{
                    backgroundColor: "#DBEAFE",
                    color: "#1D4ED8",
                    border: "1px solid #1D4ED8",
                  }}
                />
              )}
              <label>{`${data.co_broker.user.display_name}`}</label>
            </div>
          )}
        </div>
        <p>
          <AtlasIcon svgHref={"atlas-sms"} />
          {`${data.co_broker.user.email}`}
        </p>
        <p>
          <AtlasIcon svgHref={"atlas-call"} />
          {`${data.co_broker.user.mobile_contact_number}`}
        </p>
        <p>
          <AtlasIcon svgHref={"atlas-profile2user"} />
          {data.co_broker.user.team_name
            ? `${data.co_broker.user.team_name} team`
            : ""}
        </p>
        {!is_internal && renderEditProfileButton()}
      </div>
    );
  };

  renderCobrokerDataSection = () => {
    const { data, disableEdit, can_destroy } = this.props;
    const { is_internal, is_myself } = data;
    const renderRemoveSaveButton = () => (
      <div className="d-flex my-3">
        <button
          className="btn-new btn-new--success w-100"
          onClick={() => this.onClickSaveCobrokerCard(data.id)}
          disabled={disableEdit}
        >
          Update
        </button>
        {can_destroy && (
          <button
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => this.onClickRemoveCobrokerCard(data)}
            disabled={is_myself ? is_myself : disableEdit}
          >
            <AtlasIcon
              svgHref={"atlas-trash"}
              style={{ fill: "#F04438", margin: "auto" }}
            />
          </button>
        )}
      </div>
    );
    const renderInputCommissionValue = () => (
      <div style={{ paddingTop: "15px", margin: "10px 0px" }}>
        <p className="at-card--profile-item-title">Claim Percentage(%)</p>
        <CustomFormInput
          type="number"
          value={`${this.state.claim_percentage}`}
          onChangeValue={(val) => this.onChange(val, "claim_percentage")}
          disabled={disableEdit}
        />
      </div>
    );

    const renderRefundAmount = () => (
      <div style={{ margin: "10px 0px" }}>
        <p className="at-card--profile-item-title mb10">
          Extra/Short Collection (RM)
        </p>
        <CustomFormInput
          type="number"
          value={`${this.state.extra_collection_amount}`}
          onChangeValue={(val) => this.onChange(val, "extra_collection_amount")}
          disabled={disableEdit}
        />
        <FormHelperText>Put negative amount if short collection</FormHelperText>
      </div>
    );
    const renderStampingFeeAmount = () => (
      <div style={{ margin: "10px 0px" }}>
        <p className="at-card--profile-item-title">Stamping Fee Amount (RM)</p>
        <CustomFormInput
          type="number"
          value={`${this.state.stamping_fee_amount}`}
          onChangeValue={(val) => this.onChange(val, "stamping_fee_amount")}
          disabled={disableEdit}
        />
      </div>
    );
    const renderAdminFeeAmount = () => (
      <div style={{ margin: "10px 0px" }}>
        <p className="at-card--profile-item-title">Admin Fee Amount (RM)</p>
        <CustomFormInput
          type="number"
          value={`${this.state.administration_fee_amount}`}
          onChangeValue={(val) =>
            this.onChange(val, "administration_fee_amount")
          }
          disabled={disableEdit}
        />
      </div>
    );
    return (
      <>
        {this.state.unsaveChanges && (
          <p style={{ fontWeight: "600" }}>{"**You have unsaved changes."}</p>
        )}
        {renderInputCommissionValue()}
        { is_internal && renderRefundAmount() }
        {renderStampingFeeAmount()}
        {renderAdminFeeAmount()}
        {renderRemoveSaveButton()}
      </>
    );
  };

  render = () => {
    const { data } = this.props;
    const { is_myself } = data;
    return (
      <div className={"at-subsales_claims-cobroke-card"}>
        {this.renderUserProfileSection()}
        {this.renderCobrokerDataSection()}
      </div>
    );
  };
}

export default CobrokerCard;
