import React from "react";
import _ from "lodash";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomToggle from "components/Button/toggle";

import "stylesheets/containers/subsales-claims/index.scss";

const stampingOptions = [
  {
    value: true,
    label: "Stamping fee by IQI",
  },
  {
    value: false,
    label: "Stamping fee by Agent",
  },
];

const renderOppositeClaimInfo = ({
  representative_id,
  sub_sale_type_id,
  owner_stamping_fee_amount,
  owner_total_collection_amount,
  owner_admin_fee_amount,
  owner_extra_collection_amount,
  buyer_total_collection_amount,
  buyer_stamping_fee_amount,
  buyer_admin_fee_amount,
  buyer_extra_collection_amount,
  sales_tax_percentage,
  SSTFormula
}) => {
  return (
    <div className="at-form_field-col-6">
      <div className="d-flex grid_gap-1 mb-3">
        <AtlasIcon
          className="svg-icon-chevron"
          svgHref={
            representative_id === 1 ? "svg-user-landlord" : "svg-user-tenant"
          }
        />
        <h2 className="at-card__title">
          {representative_id === 1 
            ? sub_sale_type_id === 2 ? `Landlord` : `Vendor` 
            : sub_sale_type_id === 2 ? `Tenant` : `Purchaser`
          }
        </h2>
      </div>
      <div className="at-form_fields_cont">
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Stamping Fee`}</h2>
          <p>
            {`RM ${representative_id === 1
              ? owner_stamping_fee_amount
              : buyer_stamping_fee_amount
              }`
          }</p>
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`SST amount for total disbursement`}</h2>
          {representative_id === 1 && (
            <p>
              {`RM ${
                Math.abs(
                  owner_total_collection_amount -
                    owner_total_collection_amount / SSTFormula,
                ).toFixed(2)
              }`}
            </p>
          )}
          {representative_id === 2 && (
            <p>
              {`RM ${
                Math.abs(
                  buyer_total_collection_amount -
                    buyer_total_collection_amount / SSTFormula,
                ).toFixed(2)
              }`}
            </p>
          )}
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Admin Fee`}</h2>
          <p>{`RM ${representative_id === 1 ? owner_admin_fee_amount : buyer_admin_fee_amount}`}</p>
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Extra / Short Collection`}</h2>
          <p>{`RM ${
            representative_id === 1 ? owner_extra_collection_amount : buyer_extra_collection_amount
          }`}</p>
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">
            {`
              Total Collection on disbursement of TA (inclusive ${sales_tax_percentage}% SST): `}
          </h2>
          <p>
            {`RM ${representative_id === 1 ? owner_total_collection_amount : buyer_total_collection_amount}`}
          </p>
        </div>
      </div>
    </div>
  );
};

const renderFooter = ({
  can_update,
  isPrimaryClaim,
  intended_tenure_years = 0,
  stamping_fee_by_company,
  onChange,
}) => {
  if( isPrimaryClaim ) {
    return (
      <div className="at-form_field-col-12">
        <CustomRadioGroup
          containerClassName="mb-3"
          disabled={ !can_update }
          checkedValue={stamping_fee_by_company}
          options={stampingOptions}
          selectedRadioValue={(val) => onChange("stamping_fee_by_company", val)}
          status={true}
        />
        <div className="col-md-6 p-0 mb-0">
          <h2 className="at-form-input__title">{`Intended Tenure Years`}</h2>
          <CustomFormInput
            type="number"
            value={`${intended_tenure_years}`}
            onChangeValue={(val) => onChange("intended_tenure_years", val)}
            disabled={ !can_update }
          />
        </div>
      </div>
    );
  } else {
    const {label: tmpStampingFeeByCompanyLabel = ''} = stampingOptions.find(item => item.value === stamping_fee_by_company) || {}
    return (
      <div className="at-form_field-col-12">
        <hr className="mt-0"/>
        { tmpStampingFeeByCompanyLabel && <span>{ tmpStampingFeeByCompanyLabel }</span>}
        { <span>{`${tmpStampingFeeByCompanyLabel && ','} intended tenure ${intended_tenure_years} years`}</span>}
      </div>
    )
  }
};

const renderEditPartyCard = ({
  representative_id,
  sub_sale_type_id,
  can_update,
  isPrimaryClaim,
  owner_stamping_fee_amount,
  owner_total_collection_amount,
  owner_admin_fee_amount,
  owner_extra_collection_amount,
  buyer_total_collection_amount,
  buyer_stamping_fee_amount,
  buyer_admin_fee_amount,
  buyer_extra_collection_amount,
  sales_tax_percentage,
  onChange,
  SSTFormula
}) => {
  return (
    <div className="at-form_field-col-6 at-subsales_claims-agency-fee-cont p-3">
      <div className="d-flex grid_gap-1 mb-3">
        <AtlasIcon
          className="svg-icon-chevron"
          svgHref={
            representative_id === 1 ? "svg-user-landlord" : "svg-user-tenant"
          }
        />
        <h2 className="at-card__title">
          {representative_id === 1 
            ? sub_sale_type_id === 2 ? `Landlord` : `Vendor` 
            : sub_sale_type_id === 2 ? `Tenant` : `Purchaser`
          }
        </h2>
      </div>
      <div className="at-form_fields_cont">
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Stamping Fee (RM)`}</h2>
          <CustomFormInput
            type="number"
            disabled={!can_update}
            value={`${representative_id === 1 ? owner_stamping_fee_amount : buyer_stamping_fee_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_stamping_fee_amount"
                  : "buyer_stamping_fee_amount",
                val
              )
            }
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`SST amount for total disbursement (RM)`}</h2>
          {representative_id === 1 && (
            <CustomFormInput
              type="number"
              disabled={true}
              value={Math.abs(
                owner_total_collection_amount -
                  owner_total_collection_amount / SSTFormula,
              ).toFixed(2)}
            />
          )}
          {representative_id === 2 && (
            <CustomFormInput
              type="number"
              value={Math.abs(
                buyer_total_collection_amount -
                  buyer_total_collection_amount / SSTFormula,
              ).toFixed(2)}
              disabled={true}
            />
          )}
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Admin Fee (RM)`}</h2>
          <CustomFormInput
            type="number"
            value={`${representative_id === 1 ? owner_admin_fee_amount : buyer_admin_fee_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_admin_fee_amount"
                  : "buyer_admin_fee_amount",
                val,
              )
            }
            disabled={!can_update}
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Extra / Short Collection (RM)`}</h2>
          <CustomFormInput
            type="number"
            value={
              representative_id === 1
                ? owner_extra_collection_amount
                : buyer_extra_collection_amount
            }
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_extra_collection_amount"
                  : "buyer_extra_collection_amount",
                val,
              )
            }
            disabled={!can_update}
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">
            {`
              Total Collection on disbursement of TA (inclusive ${sales_tax_percentage}% SST): `}
          </h2>
          <CustomFormInput
            type="number"
            disabled={!can_update}
            value={`${representative_id === 1 ? owner_total_collection_amount : buyer_total_collection_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_total_collection_amount"
                  : "buyer_total_collection_amount",
                val,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

const StampingFee = ({
  permissionsChecker,
  selected_claim,
  onChangeUpdateSelectedClaim,
}) => {
  const { can_update } = permissionsChecker;
  const SSTFormula = (selected_claim?.sales_tax_percentage / 100) + 1;

  const {
    is_iqi_panel_law_firm,
    representative_id,
    opposite_claim = {},
  } = selected_claim;

  return (
    <>
      <div className="d-flex align-items-basline">
        <CustomToggle
          className={"at-toggle__secondary"}
          currentState={!is_iqi_panel_law_firm}
          onToggleButton={() => onChangeUpdateSelectedClaim("is_iqi_panel_law_firm", !is_iqi_panel_law_firm)}
        />
        <h2 className="at-form-input__title mb-3">Use IQI panel lawyer</h2>
      </div>
      <div className="at-form_fields_cont">
        {renderEditPartyCard({
          ...selected_claim,
          isPrimaryClaim: true,
          can_update,
          onChange: onChangeUpdateSelectedClaim,
          representative_id: [null, 3].indexOf(representative_id) > -1 
            ? 1 
            : representative_id,
          showFooter: false,
          SSTFormula: SSTFormula
        })}
        {renderEditPartyCard({
          ...selected_claim,
          isPrimaryClaim: true,
          can_update,
          onChange: onChangeUpdateSelectedClaim,
          representative_id: [null, 3].indexOf(representative_id) > -1 
            ? 2 
            : representative_id === 2
              ? 1
              : 2,
          showFooter: false,
          SSTFormula: SSTFormula
        })}
        {renderFooter({
          ...selected_claim,
          isPrimaryClaim: true,
          can_update,
          onChange: onChangeUpdateSelectedClaim
        })}
      </div>
      {(opposite_claim &&
        opposite_claim !== null) &&
          <div className="at-form_fields_cont at-subsales_claims-stamping-fee-cont-disabled mt-3 p-3">
            {
              renderOppositeClaimInfo({
                ...selected_claim,
                ...opposite_claim,
                representative_id: opposite_claim.representative_id,
                SSTFormula: SSTFormula
              })
            }
            {
              renderOppositeClaimInfo({
                ...selected_claim,
                ...opposite_claim,
                representative_id: opposite_claim.representative_id === 1 ? 2 : 1,
                SSTFormula: SSTFormula
              })
            }
            {renderFooter({
              ...opposite_claim,
              isPrimaryClaim: false,
              can_update: false,
              onChange: () => {}
            })}
          </div>
      }
      </>
  );
};

export default StampingFee;