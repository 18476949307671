import Moment from "moment";

export const TypeLib = [
  { id: 1, value: "1", label: "Name Card" },
  { id: 2, value: "2", label: "Poster" },
  { id: 3, value: "3", label: "Social Media" },
];

export const StatusLib = [
  { id: 1, label: "Unpublished" },
  { id: 2, label: "Published" },
];

export const columnData = [
  {
    header: "Thumbnail",
    columnStyle: { width: "100%", maxWidth: 300 },
    renderColumn: (rowData) => (
      <img
        src={rowData.thumbnail_url}
        style={{ height: 200, objectFit: "contain" }}
        alt={rowData.title}
      />
    ),
  },
  {
    header: "Title",
    renderColumn: (rowData) => <p style={{ fontSize: 15 }}>{rowData.title}</p>,
  },
  {
    header: "Type",
    columnStyle: { width: "50%" },
    accessor: "typeLabel",
  },
  {
    header: "Category",
    columnStyle: { width: "50%" },
    accessor: "category",
  },
  {
    header: "Download / Created Count",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => (
      <p>{`${rowData.download_count || 0}/${rowData.create_count || 0}`}</p>
    ),
  },
  {
    header: "Created At",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => (
      <p>{Moment(rowData.created_at).format("DD MMM YYYY hh:mm")}</p>
    ),
  },
  {
    header: "Published",
    columnStyle: { width: "50%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <>
        <p
          style={{ fontSize: 22 }}
          className={`text-center ${rowData.status_id === 2 ? "text-success" : "text-danger"}`}
        >
          {rowData.status_id === 2 ? "✓" : "×"}
        </p>
      </>
    ),
  },
];
