import React, { Component } from "react";
import _ from "lodash";
import { BsInfoCircleFill } from "react-icons/bs";

import AtButton from "components/Button";

const processActions = (content, onClickAction, type, disabled) => {
  return (
    <AtButton
      key={content.id}
      className={"at-table-icon-button"}
      children={
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#3B82F6" }} />
      }
      tooltip={true}
      tooltipChildren={<span>Detail</span>}
      tooltipPosition={"bottom"}
      onClick={() => onClickAction(content)}
    />
  );
};

const productTypeDictionary = [
  { id: 1, label: "Starter Kit" },
  { id: 2, label: "Name Card" },
];

class TableContent extends Component {
  render = () => {
    let {
      onClickView,
      headerData,
      rowItem,
      actionColumn,
      actionColumnContent,
    } = this.props;
    let tempProduct = _.find(productTypeDictionary, {
      id: rowItem.product_type_id,
    });
    return (
      <div className="at-table-row-cont">
        <div className="at-table__height-control">
          <ul className="at-table-row grid-controller">
            {headerData.map((item, index) => {
              return (
                <li
                  key={`table-${index}-${item.label}`}
                  className={`at-table-row__item at-table__${index}`}
                  style={{
                    width: item.width ? item.width : "100%",
                    minWidth: item.minWidth && item.minWidth,
                  }}
                >
                  <span className="at-table-row__item-label">{item.label}</span>
                  <p
                    className={`${item.containerClass ?? ""} ${item.contentClass ?? ""}`}
                  >
                    {item.value === "product" && (
                      <p className="fw-600 fs-1 badge badge-pill py-1 px-2 bg-primary color-white inline-flex-align-center">
                        {tempProduct ? tempProduct?.label : ""}
                      </p>
                    )}
                    {item.value &&
                    rowItem[item.value] &&
                    typeof rowItem[item.value] === "string" &&
                    rowItem[item.value].toString().indexOf("\n") > -1 ? (
                      rowItem[item.value].split("\n").map((child, index) => (
                        <div key={index} style={{ display: "block" }}>
                          {child}
                        </div>
                      ))
                    ) : (
                      <span>{rowItem[item.value]}</span>
                    )}
                    {item.renderColumn && item.renderColumn(rowItem)}
                  </p>
                </li>
              );
            })}
            {actionColumn && (
              <li
                className="at-table-row__item at-table__actions"
                style={{ width: `${actionColumnContent.length * 75}%` }}
              >
                <span className="at-table-row__item-label">{"Actions"}</span>
                {this.props.actionColumnContent.map((item) => {
                  switch (item) {
                    case "details":
                      return processActions(
                        rowItem,
                        onClickView,
                        "details",
                        this.props.disableRowAction,
                      );
                    default:
                      break;
                  }
                })}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };
}

export default TableContent;
