import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import { MdFilterList } from "react-icons/md";

import SearchParams from "./searchParams";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import PreviewModal from "../Preview";
import IQIDriveTable from "../components/Table";
import AtlasDownloadFilesModal from "../downloadFilesStatus";

import permissionsChecker from "utils/permissionsChecker";
import DriveSearchHOC from "./actions";
import "./index.scss";

const SearchResultTable = ({
  downloading,
  showPreviewModal,
  showSearchResultModal,
  selectedSearchFile,
  searchParams,
  searchResult,
  searchDirectoryMeta,
  searchHeaderData,
  searchIds,
  searchDirectoryLayerCount,
  searchTitle,
  parentPath,
  data,
  onLoadSearchDrive,
  previewLoading,
  downloadList,
  showDownloadedFiles,
  showSearchContent,

  onChangeSearchHOC,
  searchDrive,
  onClickFavourite,
  onDownloadFile,
  onSelectDirectory,
  onSelectFile,
  onPressUpDirectory,
  copyURLToClipboard,
  clearDriveCache,
  downloadMultipleFiles,
  onChangeCheckboxValue,
  closeDownloadedFilesList,
}) => {
  const [can_update, onSetUpdatePermissions] = useState(false);
  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const setShowSearchContent = (val) =>
    onChangeSearchHOC(val, "showSearchContent");

  useEffect(() => {
    if (["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) > -1) {
      let tmpHeaderData = cloneDeep(searchHeaderData);
      tmpHeaderData.push({
        label: "Actions",
        showName: true,
        classNameWord: "actions",
      });

      onChangeSearchHOC(tmpHeaderData, "searchHeaderData");

      const { can_update: updateDrive } = permissionsChecker("IQI Drive", data);
      onSetUpdatePermissions(updateDrive);
    } else {
      onSetUpdatePermissions(false);
    }
  }, []);

  return (
    <>
      <div className="mb-10">
        <SearchParams
          form_col={8}
          button_col={4}
          mode={"desktop"}
          searchParams={searchParams}
          onChangeSearchParams={(val, name) => {
            onChangeSearchHOC(val, "searchParams");
            onChangeSearchHOC(name, "searchTitle");
          }}
          onToggleSearchModal={() => setShowSearchContent(!showSearchContent)}
          getResultAPI={searchDrive}
        />
      </div>
      <AtlasDialog
        open={showSearchResultModal}
        children={
          <CustomCard
            className={"h-100"}
            cardContent={
              <>
                <div className="at-card__header">
                  <AtlasCloseButton
                    onClick={() => {
                      onChangeSearchHOC(false, "showSearchResultModal");
                      onChangeSearchHOC([], "searchResult");
                    }}
                  />
                  <h4 className="at-card__title">Search IQI Drive</h4>
                </div>
                <IQIDriveTable
                  isSearchTable={true}
                  classContainer="at-drive-search_result"
                  headerData={searchHeaderData}
                  ids={searchIds}
                  data={searchResult}
                  downloadList={downloadList}
                  directoryLayerCount={searchDirectoryLayerCount}
                  title={
                    <>
                      <span style={{ marginRight: "0.8rem" }}>
                        Search Result for
                      </span>
                      <span
                        className="at-drive-search_title"
                        style={{
                          fontStyle: "italic",
                          color: "#717171",
                        }}
                      >
                        {searchTitle}
                      </span>
                    </>
                  }
                  parent_path={parentPath}
                  display_name={searchDirectoryMeta.display_name}
                  disablePathNavigation={false}
                  clipboardName={"dialog-copy_url-clipboard"}
                  showFavouriteFunction={true}
                  showResetCacheFunction={can_update}
                  downloadMultipleFiles={downloadMultipleFiles}
                  showCopyUrlFunction={
                    tmpProfileReducer.role === "Super Admin" ||
                    tmpProfileReducer.role === "Admin"
                  }
                  onClickFavourite={onClickFavourite}
                  onSelectDirectory={onSelectDirectory}
                  onSelectFile={onSelectFile}
                  onPressUpDirectory={onPressUpDirectory}
                  onChangeCheckboxValue={onChangeCheckboxValue}
                  copyURLToClipboard={copyURLToClipboard}
                  clearDriveCache={clearDriveCache}
                  onChangeHOC={onChangeSearchHOC}
                />
                <PreviewModal
                  showPreviewModal={showPreviewModal}
                  onClosePreviewModal={() =>
                    onChangeSearchHOC(false, "showPreviewModal")
                  }
                  selectedFile={selectedSearchFile}
                  downloading={downloading}
                  onDownloadFile={() => {
                    onDownloadFile(selectedSearchFile);
                  }}
                />
                {(onLoadSearchDrive || previewLoading) && (
                  <LoadingModal
                    message={
                      previewLoading
                        ? `Please wait for the download to complete. It might take a while depending on the size of the file and your internet connection speed.`
                        : `Please wait while loading`
                    }
                  />
                )}
              </>
            }
          />
        }
      />
      {showDownloadedFiles && (
        <AtlasDownloadFilesModal
          downloadedFiles={downloadList}
          closeDownloadedFilesList={closeDownloadedFilesList}
        />
      )}
      {showSearchContent && (
        <SearchParams
          mode={"mobile"}
          onToggleSearchModal={() => setShowSearchContent(!showSearchContent)}
          searchParams={searchParams}
          onChangeSearchParams={(val, name) => {
            onChangeSearchHOC(val, "searchParams");
            onChangeSearchHOC(name, "searchTitle");
          }}
          getResultAPI={searchDrive}
        />
      )}
    </>
  );
};
export default DriveSearchHOC(SearchResultTable);
