import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import EditAuthorisationContent from "./components/editContent";
import GenerateAuthorisationForm from "components/generateForm";
import ModalDialog from "components/Modal/ModalDialog";

import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import AuthorisationHOC from "./actions";
import FormManagementHOC from "../actions";

import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";

const columnData = [
  { header: "Form Number", accessor: "transaction_number" },
  {
    header: "Agent",
    renderColumn: ({ agent }) => (
      <>
        <p>{agent.full_name || "N/A"}</p>
        <p>{agent.mobile_contact_number || "N/A"}</p>
        <p>{agent.email || "N/A"}</p>
        <p>{agent.team_name || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Form Type",
    accessor: "type",
    columnStyle: { width: "60%" },
  },
  {
    header: "Tenant/Purchaser",
    accessor: "tenant_name",
    columnStyle: { width: "140%" },
  },
  {
    header: "Remark",
    accessor: "description",
    columnStyle: { width: "160%" },
  },
  {
    header: "Submited",
    renderColumn: ({ is_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_claimed ? "Green" : "Red") }}
      >
        {is_claimed ? "Yes" : "No"}
      </div>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
  },
];

const typeOptions = [
  { label: "Sales", value: "1" },
  { label: "Rental", value: "2" },
];
class AuthorisationForm extends Component {
  componentDidMount = () => {
    this.props.getAuthorisationForms(1, "");
    initGA("/dashboard/admin/forms/authorisation-form");

    const id = this.props.history?.location?.state?.id;

    if (id) {
      this.props.getSelectedAuthorisationForms(id);
    }
  };

  renderAuthorisationForm = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { e_process, isLocked } = this.props.selectedAuthorisationForm;

    const { status_id } = e_process ?? {}
    const eProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))  

    return (
      <AtlasDialog
        withFloatPanel={true}
        open={this.props.showEditAuthorisationForm}>
        <CustomCard
          className="h-100"
          containerStyle={{ background: "#F3F4F6" }}
          cardContent={<EditAuthorisationContent 
            {...this.props} 
            closedEditing={ !can_update || isLocked || eProcessDisabledChecking }/>}
        />
      </AtlasDialog>
    );
  }

  generateAuthorisationForm = (dateToSubmit) => {
    this.props.onChangeAuthorisationHOC(true, "disableSubmitButton");
    this.props.createAuthorisationForm(dateToSubmit);
  };

  renderFormGenerator = () => {
    const {
      agentList,
      showCreationForm,
      getAgentsBySearch,
      onChangeAuthorisationHOC,
    } = this.props;

    if (showCreationForm) {
      return (
        <ModalDialog
          title={"New Authorisation Form"}
          children={
            <GenerateAuthorisationForm
              title={"New Authorisation Form"}
              agentList={agentList}
              typeOptions={typeOptions}
              getAgentsBySearch={getAgentsBySearch}
              onSelectBranch={(val) =>
                onChangeAuthorisationHOC(val.id, "createInvoiceId")
              }
              selectSubsaleType={(val) =>
                onChangeAuthorisationHOC(val, "createTypeId")
              }
              invoicingBranch={this.props.data.branchReducer.invoicingBranch}
              invoicing_branch_id={this.props.createInvoiceId}
              generateForm={this.generateAuthorisationForm}
              onClickCloseFormGenerator={() =>
                onChangeAuthorisationHOC(false, "showCreationForm")
              }
              submitButtonText={"Create Now"}
            />
          }
          onClose={() =>
            this.props.onChangeAuthorisationHOC(false, "showCreationForm")
          }
        />
      );
    }
  };

  render = () => {
    const { can_create, can_update } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={"Authorisation Forms To Secure Property"}
          atlasIcon={"atlas-authorisation-form"}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeAuthorisationHOC(true, "showCreationForm"),
            },
          ]}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={this.props.authorisationForm.data || []}
              meta={this.props.authorisationForm.meta}
              columns={columnData}
              actionColumnContent={[
                {
                  name: can_update ? "edit" : "view",
                  onClick: (rowData) =>
                    this.props.getSelectedAuthorisationForms(rowData.id),
                },
              ]}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeAuthorisationHOC(val, "searchParams")
              }
              getListAPI={this.props.getAuthorisationForms}
              totalPages={this.props.authorisationFormPage}
            />
          }
        />
        {this.renderFormGenerator()}
        {this.renderAuthorisationForm()}
        {(this.props.onLoadAuthorisation || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AuthorisationHOC,
  FormManagementHOC,
  VersionCheckHOC,
)(AuthorisationForm);
