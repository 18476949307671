import React from "react";
import _ from "lodash";
import { FiEdit } from "react-icons/fi";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleFormHeader from "components/Form/header";

import { contact as ContactDictionary } from "dictionary/index";
import { contactInformation, personalInformation, bankInformation, representativeInformation } from "./assets";
import "./index.scss";

const View = ({ banks, selectedContact, onChangeContactHOC }) => {
  const getContactTypeTag = (id, dictionary) => {
    const contactType = dictionary.find(entry => entry.id === id);
    return contactType ? contactType.name : null;
  };

  return (
    <>
      <div className="at-contact_details_header__cont">
        <div className="row">
          <div className="col-12 d-flex mb-0">
            <div className="col-8">
              <div className={"at_form_header--cont "} style={{ alignItems: "flex-start" }}>
                <div className={"form-header-icon at-contact_details__icon"}>
                  {selectedContact.type_id === 1 
                    ? <AtlasIcon svgHref={"atlas-user"} />
                    : <AtlasIcon svgHref={"atlas-building-company"}/>
                  }
                </div>
                <div className="d-flex flex-column">
                  {selectedContact.type_id === 1 
                    ? <p>{selectedContact.full_name}</p>
                    : <>
                        <p>{selectedContact.company && selectedContact.company}</p>
                        <p>{selectedContact.company_registration_number && selectedContact.company_registration_number}</p>
                        <p>{selectedContact.full_name}</p>
                      </>
                  }
                  <div className="d-flex flex-wrap">
                    <div className="at-status_badge at-status_type-badge mt-1 mr-1 mb-1">
                      {getContactTypeTag(selectedContact.type_id, ContactDictionary.types)}
                    </div>
                    {selectedContact.tags.map(item => (
                      <div className="at-status_badge at-status_tag-badge mt-1 mr-1 mb-1">
                        {getContactTypeTag(item, ContactDictionary.tags)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div
                className="at-contact_details__view-header-button_cont"
                style={{ display: "inline" }}>
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() => {
                    onChangeContactHOC(true, "showEditContactModal");
                    onChangeContactHOC(false, "showViewContactModal");
                  }}>
                  <FiEdit style={{ color: "#FFFFFF", marginRight: 10 }} />
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-0">
            <div className="col-12">
              <div className="fs-2 mb-2">
                <AtlasIcon
                  svgHref={"atlas-sms"}
                  style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 8 }}
                />
                {selectedContact.email || "N/A"}
              </div>
              <div className="fs-2 mb-2">
                <AtlasIcon
                  svgHref={"atlas-call"}
                  style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 8 }}
                />
                {selectedContact.mobile_contact_number || "N/A"}
              </div>
            </div>
            <div className="col-12">
              <div className="at-contact_details__view-header-button_cont">
                <CustomButton
                  className={"btn-new btn-new--outline-secondary w-100"}
                  containerClass={"flex-container"}
                  containerStyle={{ flex: "1" }}
                  disabled={!selectedContact.email}
                  onClick={() => window.open(`mailto:${selectedContact.email}`)}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-sms-edit"}
                        style={{ width: 16, height: 16, fill: "#3B82F6", marginRight: 8 }}
                      />
                      Email
                    </>
                  }
                />
                <CustomButton
                  className={"btn-new btn-new--outline-secondary w-100"}
                  containerClass={"flex-container"}
                  containerStyle={{ flex: "1" }}
                  disabled={!selectedContact.mobile_contact_number}
                  onClick={() => window.open(`https://wa.me/${selectedContact.mobile_contact_number}`, "_blank")}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-whatsapp"}
                        style={{ width: 16, height: 16, fill: "#12B76A", marginRight: 8 }}
                      />
                      Message
                    </>
                  }
                />
                <CustomButton
                  className={"btn-new btn-new--outline-secondary w-100"}
                  containerClass={"flex-container"}
                  containerStyle={{ flex: "1" }}
                  disabled={!selectedContact.mobile_contact_number}
                  onClick={() => window.open(`tel:${selectedContact.mobile_contact_number}`)}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-call-calling"}
                        style={{ width: 16, height: 16, fill: "#3B82F6", marginRight: 8 }}
                      />
                      Call
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 mb-0">
            <div className="row">
              {contactInformation.map((field, index) => (
                <div className="at-contact_details col-md-4 col-6" key={index}>
                  <label>{field.label}</label>
                  <span>{selectedContact[field.value] || "-"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="at-contact_details__cont">
        <div className="row">
          <div className="col-md-12 mb-0">
            <ModuleFormHeader
              title={"Personal Information"}
              className={"mb-3"}
              moduleIcon={"atlas-profile-circle"}
            />
          </div>
          <div className="col-md-12 mb-0">
            <div className="row">
              {personalInformation.map((field, index) => (
                <div className="at-contact_details col-md-4 col-sm-12" key={index}>
                  <label>{field.label}</label>
                  <span>{selectedContact[field.value] || "-"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="at-contact_details__cont ">
        <div className="row">
          <div className="col-md-12 mb-0">
            <ModuleFormHeader
              title={"Bank Details"}
              className={"mb-3"}
              moduleIcon={"atlas-bank"}
            />
          </div>
          <div className="col-md-12 mb-0">
            <div className="row">
              {bankInformation.map((field, index) => (
                <div className="at-contact_details col-md-4 col-sm-12" key={index}>
                  <label>{field.label}</label>
                  <span>
                    {field.value === "bank_id"
                      ? _.find(banks, { id: selectedContact[field.value] })?.name || "-"
                      : selectedContact[field.value] || "-"}
                  </span>               
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="at-contact_details__cont">
        <div className="row">
          <div className="col-md-12 mb-0">
            <ModuleFormHeader
              title={"Remark"}
              className={"mb-3"}
              moduleIcon={"atlas-message-text"}
            />
          </div>
          <div className="col-md-12 mb-0">
            <div className="row">
              <div className="at-contact_details col-12">
                <label>Remark</label>
                <span>{selectedContact.remark || "No remark"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedContact.has_representative && (
        <div className="at-contact_details__cont">
          <div className="row">
            <div className="col-md-12 mb-0">
              <ModuleFormHeader
                title={"Representative"}
                className={"mb-3"}
                moduleIcon={"atlas-user-tie"}
              />
            </div>
          </div>
          <div className="col-md-12 mb-0">
            <div className="row">
              {representativeInformation.map((field, index) => (
                <div className="at-contact_details col-md-4 col-sm-12" key={index}>
                  <label>{field.label}</label>
                  <span>{selectedContact[field.value] || "-"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default View;
