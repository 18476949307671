import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import PersonalInfo from "./PersonalInfo";

import { initGA } from "utils/ga";
import { requestSuccess } from "utils/requestHandler";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { getCurrentUserProfile } from "actions/profile";
import VersionCheckHOC from "actions/versionCheck";
import { refreshToken } from "actions/login";
import WithProfileUpdate from "./actions";

import "stylesheets/containers/profile-update/index.scss";

class ProfileUpdate extends Component {
  state = {
    ajaxCallProgress: 0,

    updateAgentInfo: {
      display_name: "",
      email: "",
      mobile_contact_number: "",
      description: "",
      avatar_url: "",
      current_address: "",
      youtube_playlist_url: "",
      wechat_id: "",
      facebook_url: "",
      telegram_url: "",
      instagram_url: "",
      board_registration_type_id: "",
      board_registration_number: "",
      license_number: "",
      agent_registration_attributes: {
        id: "",
        emergency_person_name: "",
        emergency_contact_number: "",
      },
      name_card_attributes: {
        id: "",
        name_card_display_name: "",
        additional_display_name: "",
        include_profile_photo: false,
        photo: "",
      },
    },

    from: "",
    nameCardDisplayNameError: "",
    displayNameError: "",
    currentAddressError: "",
    emergencyContactError: "",
    emergencyPersonNameError: "",
    emailError: "",
  };

  componentDidMount = () => {
    initGA("/dashboard/profile/update");
    window.location.href.includes("/admin-impersonate")
      ? this.props.getCurrentSignInProfile()
      : this.props.getCurrentUserProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      ajaxCallProgress,
      ajaxSuccess,
      ajaxCallProgressImpersonate,
      ajaxSuccessImpersonate,
    } = this.props.data.ajaxStatusReducer;

    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const isAjaxCallProgressChanged = window.location.href.includes("/admin-impersonate")
      ? ajaxCallProgressImpersonate !==
        prevProps.data.ajaxStatusReducer.ajaxCallProgressImpersonate
      : ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress;

    const isProfileReducerChanged = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer !==
        prevProps.data.currentSignInProfileReducer
      : this.props.data.profileReducer !== prevProps.data.profileReducer;

    const tmpAjaxCallProgress = window.location.href.includes("/admin-impersonate")
      ? ajaxCallProgressImpersonate
      : ajaxCallProgress;

    const tmpAjaxSuccess = window.location.href.includes("/admin-impersonate")
      ? ajaxSuccessImpersonate
      : ajaxSuccess;

    if (prevState.updateAgentInfo !== this.state.updateAgentInfo) {
      if (tmpProfileReducer.role === "Agent") {
        return this.setState({
          disableSubmit:
            this.props.from === "NameCard"
              ? this.state.nameCardDisplayNameError
              : this.state.displayNameError ||
                this.state.emailError ||
                this.state.currentAddressError ||
                this.state.emergencyContactError ||
                this.state.emergencyPersonNameError,
        });
      }

      return this.setState({ disableSubmit: this.state.displayNameError });
    }

    if (isAjaxCallProgressChanged) {
      this.props.onChangeProfileHOC(tmpAjaxCallProgress, "loading");

      if (!tmpAjaxCallProgress && tmpAjaxSuccess) {
        if (this.state.from === "submit edited user profile") {
          requestSuccess("Profile updated successfully.");
          this.setState({ from: "" });
        }
      }
    }

    if (
      this.props.data.currentSignInProfileReducer !==
        prevProps.data.currentSignInProfileReducer ||
      this.props.data.profileReducer !== prevProps.data.profileReducer
    ) {
      const {
        display_name,
        current_address,
        email,
        mobile_contact_number,
        description,
        avatar_url,
        agent_registration_id,
        agent_emergency_contact_number,
        agent_emergency_person_name,
        name_card,
        job_title_display_name,
        youtube_playlist_url,
        license_number,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        board_registration_type_id,
        board_registration_number,
      } = tmpProfileReducer;
      let temp = _.cloneDeep(this.state.updateAgentInfo);

      temp = {
        ...temp,
        display_name: display_name || "",
        email,
        mobile_contact_number,
        description: description || "",
        avatar_url,
        job_title_display_name: job_title_display_name || "",
        agent_registration_attributes: {
          id: agent_registration_id || "",
        },
        ...(tmpProfileReducer.role === "Agent" && {
          youtube_playlist_url,
          current_address,
          license_number,
          board_registration_type_id: board_registration_type_id || "",
          board_registration_number: board_registration_number || "",
          wechat_id: wechat_id || "",
          facebook_url: facebook_url || "",
          telegram_url: telegram_url || "",
          instagram_url: instagram_url || "",
          agent_registration_attributes: {
            id: agent_registration_id || "",
            emergency_person_name: agent_emergency_person_name || "",
            emergency_contact_number: agent_emergency_contact_number || "",
          },
        }),
        ...(tmpProfileReducer.role !== "Personal Assistant" &&
          name_card && {
            name_card_attributes: {
              id: name_card.id,
              include_profile_photo: name_card.include_profile_photo,
              name_card_display_name: name_card.name_card_display_name || "",
              designation: name_card.designation,
              additional_display_name: name_card.additional_display_name || "",
              photo: name_card.photo || "",
              agent_tag_number: name_card.agent_tag_number || "",
              qualification: name_card.qualification || "",
              mobile_number: name_card.mobile_number || "",
              real_estate_type_id: name_card.real_estate_type_id || "",
            },
          }),
      };
      this.setState({
        updateAgentInfo: temp,
        currentAddressError: current_address
          ? ""
          : "Please fill in the required field",
        displayNameError: display_name
          ? ""
          : "Please fill in the required field",
        nameCardDisplayNameError:
          name_card && name_card.name_card_display_name
            ? ""
            : "Please fill in the required field.",
        emergencyContactError: agent_emergency_contact_number
          ? ""
          : "Please fill in the required field",
        emergencyPersonNameError: agent_emergency_person_name
          ? ""
          : "Please fill in the required field",
      });
    }
  };

  onChangeUpdateAgent = (val, context, errorContext, childContext) => {
    let temp = _.cloneDeep(this.state.updateAgentInfo);

    if (childContext) {
      temp[context][childContext] = val;
    } else {
      temp[context] = val;
    }

    this.setState({
      updateAgentInfo: temp,
      ...(errorContext && {
        [errorContext]: val ? "" : "Please fill in the required field",
      }),
    });
  };

  submitImagesCheck = (dataToSubmit) => {
    if (
      this.state.updateAgentInfo.avatar_url &&
      this.state.updateAgentInfo.avatar_url.indexOf("https") < 0
    ) {
      dataToSubmit = {
        ...dataToSubmit,
        avatar: this.state.updateAgentInfo.avatar_url,
      };
    }
    if (
      dataToSubmit.name_card_attributes.photo &&
      dataToSubmit.name_card_attributes.photo.indexOf("base64") < 0
    ) {
      delete dataToSubmit.name_card_attributes.photo;
    }
    this.setState({ from: "submit edited user profile" }, () => {
      this.props.updateCurrentUserProfile(dataToSubmit);
    });
  };

  onClickUpdateProfileInfo = () => {
    let dataToSubmit = {
      agent_registration_attributes:
        this.state.updateAgentInfo.agent_registration_attributes,
      name_card_attributes: this.state.updateAgentInfo.name_card_attributes,
      current_address: this.state.updateAgentInfo.current_address,
      display_name: this.state.updateAgentInfo.display_name,
      email: this.state.updateAgentInfo.email,
      mobile_contact_number: this.state.updateAgentInfo.mobile_contact_number,
      description: this.state.updateAgentInfo.description,
      youtube_playlist_url: this.state.updateAgentInfo.youtube_playlist_url,
      wechat_id: this.state.updateAgentInfo.wechat_id,
      facebook_url: this.state.updateAgentInfo.facebook_url,
      telegram_url: this.state.updateAgentInfo.telegram_url,
      instagram_url: this.state.updateAgentInfo.instagram_url,
    };
    this.submitImagesCheck(dataToSubmit);
  };

  render = () => (
    <>
      <PersonalInfo
        from={this.props.from}
        role={
          window.location.href.includes("/admin-impersonate")
            ? this.props.data.currentSignInProfileReducer.role
            : this.props.data.profileReducer.role
        }
        updateAgentInfo={this.state.updateAgentInfo}
        onChangeState={(key, val) => this.setState({ [key]: val })}
        displayNameError={this.state.displayNameError}
        nameCardDisplayNameError={this.state.nameCardDisplayNameError}
        currentAddressError={this.state.currentAddressError}
        emergencyPersonNameError={this.state.emergencyPersonNameError}
        emergencyContactError={this.state.emergencyContactError}
        onLoadUpdateProfile={this.props.onLoadUpdateProfile}
        onChangeProfileHOC={this.props.onChangeProfileHOC}
        onClickUpdateProfileInfo={this.onClickUpdateProfileInfo}
        onChangeUpdateAgent={this.onChangeUpdateAgent}
        onClickClose={() => this.props.onClose()}
      />
      {this.props.onLoadUpdateProfile > 0 && <LoadingModal />}
    </>
  );
}

const mapStateToProps = (state) => ({ data: state });

export default compose(
  connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
    refreshToken,
  }),
  WithProfileUpdate,
  VersionCheckHOC
)(ProfileUpdate);
