import React, { Component } from "react";
import _ from "lodash";
import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import CustomTextarea from "components/Input/textarea";

import { Type, FormType } from "../../components/options";

const statusBadge = [
  {
    id: 1,
    value: "Submitted",
    colorName: "Green",
  },
  {
    id: 2,
    value: "Pending",
    colorName: "Blue",
  },
  {
    id: 3,
    value: "Cancelled",
    colorName: "Grey",
  },
];

class Update extends Component {
  updateSelectedPaperForm = (key, val) => {
    let tmp = _.cloneDeep(this.props.selectedPaperForm);
    tmp[key] = val;
    return this.props.onChangePaperFormHOC(tmp, "selectedPaperForm");
  };

  render = () => {
    const { claim_status } = this.props.selectedPaperForm;

    let { colorName } = _.find(this.props.legendsArray, {
      value: claim_status,
    });

    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 10 }}
          onClick={() => this.props.onChangePaperFormHOC(false, "showUpdate")}
        />
        <h4 className="at-card__title">{`Edit Form`}</h4>
        <hr />
        <div className="grid-control">
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Form Number"}</h2>
            <span>{this.props.selectedPaperForm.tracking_number}</span>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Type"}</h2>
            <span>
              {Type.map((option) =>
                this.props.selectedPaperForm.type_id === option.id
                  ? option.label
                  : "",
              )}
            </span>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Form Type"}</h2>
            <span>
              {FormType.map((formItem) =>
                formItem.id === this.props.selectedPaperForm.form_type_id
                  ? formItem.label
                  : "",
              )}
            </span>
          </section>
          <section className="grid-half-col">
            {this.props.selectedPaperForm.form_type_id === "2" && (
              <>
                <h4 className="at-form-input__title" style={{ fontSize: 16 }}>
                  Co-agency
                </h4>
                <span>{this.props.selectedPaperForm.agency_name}</span>
              </>
            )}
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Status"}</h2>
            {
              <div
                className={"legend-sign mr-10"}
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  backgroundColor: colorName || "#FFFFFF",
                }}
              />
            }
            <span>
              {this.props.legendsArray.map((statusItem) => {
                return statusItem.value === claim_status ? statusItem.desc : "";
              })}
            </span>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Date Taken"}</h2>
            <span>{this.props.selectedPaperForm.date_taken}</span>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Remark"}</h2>
            <CustomTextarea
              value={this.props.selectedPaperForm.remark}
              onChangeValue={(val) =>
                this.updateSelectedPaperForm("remark", val)
              }
              placeholder={"Remark"}
            />
          </section>
          <section className="d-flex justify-content-end">
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={"Update"}
              onClick={() =>
                this.props.updatePaperForm(this.props.selectedPaperForm.id, {
                  remark: this.props.selectedPaperForm.remark,
                })
              }
            />
          </section>
        </div>
        {this.props.onLoadAgents && <LoadingModal />}
      </>
    );
  };
}

export default Update;
