import React, { Component } from "react";
import _ from "lodash";
import { MdFilterList } from "react-icons/md";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import TableHeader from "./components/header";
import TableContent from "./components/content";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchContent: false,
  };

  componentDidMount = () => {
    this.handleResize();

    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    return () => window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ interval: 3 });
    } else {
      this.setState({ interval: 10 });
    }
  };

  onToggleSearchModal = () =>
    this.setState((prevState) => ({
      showSearchContent: !prevState.showSearchContent,
    }));

  renderTableContent = () => {
    if (this.props.rowData && this.props.rowData.length > 0) {
      return this.props.rowData.map((item) => {
        return (
          <TableContent
            key={item.id}
            centerAlignColumns={this.props.centerAlignColumns}
            checkedArray={this.props.checkedArray}
            onHandleCheckBox={this.props.onHandleCheckBox}
            actionColumnContent={this.props.actionColumnContent}
            disableRowAction={this.props.disableRowAction}
            headerData={this.props.headerData}
            rowItem={item}
            actionColumn={this.props.actionColumn}
            onClickView={this.props.onClickView}
          />
        );
      });
    } else {
      return (
        <EmptyState
          title={"No result found"}
          renderIcon={<AtlasIcon svgHref="atlas-profile2user" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp =
                        item.param !== ""
                          ? tmp + `q[${item.value}]=${item.param}&`
                          : tmp;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    return (
      <div className="at-table__options mb-10">
        <div className="mb-10">
          {this.props.searchParams.length > 0 && (
            <div className="mb-10">
              <button
                className={
                  "btn-new btn-new--outline-secondary at-mobile-view__controller"
                }
                style={{ width: "100%" }}
                onClick={() => this.onToggleSearchModal()}
              >
                <MdFilterList style={{ width: 20 }} />
                <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                  Filter & Search
                </h5>
              </button>
              <div className="at-table__search-cont at-desktop-view__controller ">
                <TableSearchParams
                  mode={"desktop"}
                  searchParams={this.props.searchParams}
                  onChangeSearchParams={this.props.onChangeSearchParams}
                  getListAPI={this.props.getListAPI}
                />
              </div>
            </div>
          )}
          {this.state.showSearchContent && (
            <TableSearchParams
              mode={"mobile"}
              searchParams={this.props.searchParams}
              onToggleSearchModal={this.onToggleSearchModal}
              onChangeSearchParams={this.props.onChangeSearchParams}
              getListAPI={this.props.getListAPI}
            />
          )}
        </div>
        <div className="at-table at-table--set">
          <TableHeader
            data={this.props.headerData}
            centerAlignColumns={this.props.centerAlignColumns}
            actionColumnContent={this.props.actionColumnContent}
            actionColumn={this.props.actionColumn}
            onClickRearrangeData={(val) => {}}
          />
          {this.renderTableContent()}
          {this.props.meta && this.props.meta.count > 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.renderTablePagination()}
              {this.renderPaginationText()}
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default PaginatedTable;
