import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onSubmitRecruitment = (agent_registration_id) =>
      Put(
        `/admin/registrations/${agent_registration_id}/recruitment_campaign/toggle`,
        {},
        this.onSubmitRecruitmentSuccess,
        this.onSubmitRecruitmentError,
        this.load,
      );
    onSubmitRecruitmentSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getPendingNewREN(currentPage, searchParams);

      this.props.onChangeApprovalHOC(false, "showRecruitmentConfirmation");
      requestSuccess("Recruitment Campaign status updated successfully");
    };
    onSubmitRecruitmentError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadRecruitment={this.state.loading}
          onSubmitRecruitment={this.onSubmitRecruitment}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
