import { currency } from "dictionary/subsales";
import _ from "lodash";

export const inputField = (regionList, countryList, banks) => [
  {
    label: "Branch Name",
    type: "text",
    col: "col-md-6",
    value: "name",
  },
  {
    label: "Company Reg. No.",
    type: "text",
    col: "col-md-6",
    value: "company_reg_no",
  },
  {
    label: "Country",
    type: "select",
    col: "col-md-6",
    value: "country_id",
    options: countryList,
  },
  {
    label: "Region",
    type: "select",
    col: "col-md-6",
    value: "branch_region_id",
    options: _.map(regionList, (item) => ({
      value: item.id,
      label: item.name,
    })),
  },
  {
    label: "Door access domain name",
    type: "text",
    col: "col-md-6",
    value: "door_access_domain_name",
  },
  {
    label: "ERP ID",
    type: "text",
    col: "col-md-6",
    value: "reference_id",
  },
  {
    label: "Visible",
    type: "radio",
    value: "is_visible",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Status",
    type: "radio",
    value: "active",
    options: [
      { label: "Active", value: "true" },
      { label: "Inactive", value: "false" },
    ],
  },
  {
    label: "Accessible to All Agents",
    type: "radio",
    value: "accessible_to_all_agents",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Agent Registration",
    children: [
      {
        col: "full",
        label: "Registration Fee Required",
        type: "checkbox",
        value: "is_registration_fee_required",
      },
      {
        label: "Select currency",
        type: "select",
        value: "registration_fee_currency",
        options: currency,
      },
      {
        label: "Registration Fee",
        type: "number",
        value: "agent_registration_fee",
      },
    ],
  },
  {
    label: "Bank Account",
    children: [
      {
        label: "Bank",
        type: "select",
        value: "bank_id",
        options: _.map(banks, (item) => ({
          value: item.id,
          label: `${item.name} - ${item.country?.name}`,
        })),
      },
      {
        label: "Account Number",
        type: "number",
        value: "bank_account_number",
      },
      {
        label: "Account Holder",
        type: "text",
        value: "bank_owner_name",
      },
    ],
  },
];
