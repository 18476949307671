import React from "react";
import { AiFillFileImage } from "react-icons/ai";

export default function Download({ selectedGraphic }) {
  const downloadHandler = (context) => {
    const href = selectedGraphic.image_urls[context];
    window.open(href);
  };
  return (
    <>
      <div
        className="d-flex justify-content-between flex-wrap"
        style={{ width: 200 }}
      >
        <div className="at-graphic-download_button mb-20 mr-20">
          <AiFillFileImage
            onClick={() => {
              downloadHandler("a4");
            }}
          />
          <p>A4</p>
        </div>
        <div className="at-graphic-download_button mb-20">
          <AiFillFileImage
            onClick={() => {
              downloadHandler("a5");
            }}
          />
          <p>A5</p>
        </div>
      </div>
    </>
  );
}
