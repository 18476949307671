import { STORE_LANGUAGE } from "actions/type";

let initialState = {
  language: 'en'
};

export const setLanguage = (language) => ({
  type: STORE_LANGUAGE,
  payload: language,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};