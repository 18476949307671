import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const BankHOC = (WrappedComponent) => {
  class BankWrappedComponent extends Component {
    state = {
      banks: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getBanks = (country_id) =>
      Get(
        `/banks${country_id ? "?q[country_id_eq]=" + country_id : ""}`,
        this.getBanksSuccess,
        this.getBanksError,
        this.load,
      );
    getBanksSuccess = (payload) => this.setState({ banks: payload });
    getBanksError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            banks={this.state.banks}
            getBanks={this.getBanks}
            onLoadBanks={this.state.loading}
          />
        </>
      );
    };
  }
  return BankWrappedComponent;
};

export default BankHOC;
