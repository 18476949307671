import { Grid } from "@material-ui/core";

import AtlasButton from "components/Button"
import VerifyInput from "./verifyInput";

import { getTranslation } from "../assets";

const Verification = ({
  data,
  onClickSubmitTAC,
  onClickSubmitOTP,
  onChangeField,
  onClickBackStep,
  onSubmitCreateAgent,
  getTACCode,
  getOTPCode,
  email,
  mobile_contact_number,
  createNewAgent,
  mobileVerification,
  emailVerification,
  countryMobile,
  verificationTacTimeOut,
  verificationOtpTimeOut,
  tacVerificationMessage,
  otpVerificationMessage,
}) => {
  const language = createNewAgent?.is_japan_team ? "jp" : "en";

  return (
    <div className={"container pt-4"} style={{ maxWidth: 768 }}>
      <p className={"mb-4"} style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>
        {getTranslation("agent.signup.verify_email_mobile", language)}
      </p>
      <Grid container spacing={16}>
        <Grid item xs={12} className="mb-2">
          <VerifyInput
            id={"email_tac"}
            label={getTranslation("agent.signup.email", language)}
            labelValue={email}
            inputLabel={getTranslation("agent.signup.verify_email_label", language)}
            value={createNewAgent.email_tac}
            onChange={onChangeField}
            language={language}
            disabled={emailVerification}
            verifyMessage={tacVerificationMessage}
            verifyConfig={{
              onVerify: () =>
                onClickSubmitTAC(
                  createNewAgent.email,
                  createNewAgent.email_tac,
                  createNewAgent.country_id
                ),
              timeoutCounter: verificationTacTimeOut,
              onResend: () => getTACCode(createNewAgent.email),
            }}
          />
        </Grid>
        <Grid item xs={12} className="mb-2">
          <VerifyInput
            id={"phone_otp"}
            label={getTranslation("agent.signup.mobile_no", language)}
            labelValue={countryMobile + mobile_contact_number}
            inputLabel={getTranslation("agent.signup.verify_mobile_label", language)}
            value={createNewAgent.phone_otp}
            onChange={onChangeField}
            language={language}
            disabled={mobileVerification}
            verifyMessage={otpVerificationMessage}
            verifyConfig={{
              onVerify: () =>
                onClickSubmitOTP(
                  countryMobile,
                  createNewAgent.mobile_contact_number,
                  createNewAgent.phone_otp,
                  createNewAgent.country_id
                ),
              timeoutCounter: verificationOtpTimeOut,
              onResend: () =>
                getOTPCode(countryMobile, createNewAgent.mobile_contact_number),
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className={"g-3 mt-4"}
      >
        <AtlasButton
          className={"btn-new btn-new--outline-secondary"}
          onClick={() => onClickBackStep()}
        >
          {getTranslation("back", language)}
        </AtlasButton>
        <AtlasButton
          className={"btn-new btn-new--primary"}
          onClick={onSubmitCreateAgent}
          disabled={
            createNewAgent?.branch_id === 0 ||
            !emailVerification ||
            !mobileVerification ||
            createNewAgent.team_id === 0 ||
            createNewAgent.agent_registration_attributes.direct_leader_id ===
              "" ||
            data.ajaxStatusReducer.ajaxCallProgress > 0
          }
        >
          {getTranslation("submit", language)}
        </AtlasButton>
      </div>
    </div>
  );
};

export default Verification;
