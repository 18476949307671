import React, { Component } from "react";
import _ from "lodash";
import Draggable from "react-draggable";
import { Tooltip, Menu, MenuItem } from "@material-ui/core";

import WhatsappSupportButton from "components/Button/support";
import TelegramSupportButton from "components/Button/telegram";
import CallSupportButton from "components/Button/call_support";
import EmailSupportButton from "components/Button/email_support";

import SupportIcon from "assets/images/support-icon.png";
import { getTranslation } from "assets/translation";

import "./index.scss";

class SupportButtonGroups extends Component {
  state = {
    activeDrags: 0,
    anchorEl: null,
  };
  onStart = () => this.setState({ activeDrags: ++this.state.activeDrags });

  onStop = () => this.setState({ activeDrags: --this.state.activeDrags });

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  defaultPosition = (windowHeight) => {
    if (windowHeight < 400) return 200;
    else if (windowHeight > 400 && windowHeight < 700)
      return windowHeight - 100;
    else return windowHeight - 200;
  };

  render = () => {
    const language = this.props.language;
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    return (
      <>
        <div className="at-support-btn-boundary">
          <Draggable
            defaultPosition={{
              x: 0,
              y: this.defaultPosition(window.innerHeight),
            }}
            bounds="parent"
            axis="y"
            // { ...dragHandlers }
          >
            <div
              className="cursor-y"
              style={{
                borderTopLeftRadius: _.isNull(this.state.anchorEl)
                  ? "inherit"
                  : "0px",
                borderBottomLeftRadius: _.isNull(this.state.anchorEl)
                  ? "inherit"
                  : "0px",
                overflow: "hidden",
                width: 60,
                height: 50,
              }}
              onClick={this.handleClick}
              onTouchStart={this.handleClick}
            >
              <Tooltip placement={"left"} title={getTranslation("label.helptext.iqi_support", language)}>
                <button style={{ float: "right" }}>
                  <img
                    draggable={false}
                    src={SupportIcon}
                    style={{ width: "50px", height: "50px" }}
                  />
                </button>
              </Tooltip>
              <div
                className="at-support-pointer"
                style={{ opacity: _.isNull(this.state.anchorEl) ? "0" : "1" }}
              ></div>
            </div>
          </Draggable>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          open={!_.isNull(this.state.anchorEl)}
          classes={{ paper: "at-support-paper" }}
          PaperProps={{
            classes: { root: "at-support-paper" },
          }}
          onClose={this.handleClose}
          onClick={this.handleClose}
          variant={"menu"}
          transformOrigin={{ horizontal: "right", vertical: "center" }}
          anchorOrigin={{ horizontal: "right", vertical: "center" }}
          disableAutoFocusItem={true}
        >
          <MenuItem style={{ height: 40 }}>
            <WhatsappSupportButton language={language}/>
          </MenuItem>
          <MenuItem style={{ height: 40 }}>
            <TelegramSupportButton language={language}/>
          </MenuItem>
          <MenuItem style={{ height: 40 }}>
            <CallSupportButton language={language}/>
          </MenuItem>
          <MenuItem style={{ height: 40 }}>
            <EmailSupportButton language={language}/>
          </MenuItem>
        </Menu>
      </>
    );
  };
}

export default SupportButtonGroups;
