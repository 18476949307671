import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import StatusModal from "components/Modal/status";

import { Post, Get } from "utils/axios";

import { storePathFrom, storePaymentID } from "actions/registration";

const PaymentGatewayHOC = (WrappedComponent) => {
  class PaymentGatewayWrappedComponent extends Component {
    state = {
      info: {},
      status: {},
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    getServiceInfo = (product_id, user_id) => {
      Post(
        `/payments`,
        {
          provider: "Eghl",
          type: "AgentRegistration",
          product_id,
          user_id,
          description: "Payment for agent registration fees.",
        },
        this.getServiceInfoSuccess,
        this.getServiceInfoError,
        this.load,
      );
    };
    getServiceInfoSuccess = (payload) => this.setState({ info: payload });
    getServiceInfoError = (error) => this.requestError(error);

    getServiceStatus = (payment_id, product_id) => {
      Get(
        `/payments/${payment_id}?product_id=${product_id}`,
        this.getServiceStatusSuccess,
        this.getServiceStatusError,
        this.load,
      );
    };
    getServiceStatusSuccess = (payload) =>
      this.setState({ status: payload.status });
    getServiceStatusError = (error) => this.requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadPayment={this.state.loading}
            info={this.state.info}
            status={this.state.status}
            getServiceInfo={this.getServiceInfo}
            getServiceStatus={this.getServiceStatus}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storePathFrom,
    storePaymentID,
  })(PaymentGatewayWrappedComponent);
};

export default PaymentGatewayHOC;
