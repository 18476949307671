import React, { useCallback } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import AtlasMultiSelect from "components/Select/multi";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";
import SubsalesTooltip from "../../../../components/tooltip";
import { edgePropStateMap } from "../../../../assets";

import "../../../../index.scss";

const BasicInfo = ({
  can_update,

  township,
  property_name,
  selectedCountry,
  selectedCategory,
  selectedPropertyType,
  townshipArray,
  selectedMeasurement,
  monthlyMaintainanceFee,
  askingPricePerMeasurementUnit,
  isMonthlyMaintainanceFeePerMeasurementUnit,

  getLocalised,
  edgeAutoSuggest,
  onChangeValue,
  onCheckCheckboxValue,
  ...props
}) => {
  const subsaleTypeOptions = [
    { value: 1, label: getLocalised('option.details.details.type_1', 'Sale') },
    { value: 2, label: getLocalised('option.details.details.type_2', 'Rental') }
  ];

  const basicInfo = [
    {
      label: `${getLocalised('label.details.details.code', 'Listing Code')}: ${props.code}`,
      tooltip: "code",
      col: "full",
      showToolTip: true,
    },
    {
      label: getLocalised('label.details.details.type', 'Type'),
      tooltip: "type",
      col: "full",
      type: "radio",
      options: subsaleTypeOptions,
      value: props.type_id,
      context: "Subsale Type",
    },
    {
      label: getLocalised('label.details.details.listing_category', 'Listing Category'),
      tooltip: "listingCategory",
      showToolTip: true,
      col: "full",
      type: "radio",
      options: props.categories,
      value: selectedCategory,
      context: "Listing Category",
    },
    {
      label: getLocalised('label.details.details.property_type', 'Property Type'),
      type: "multiSelect",
      col: "half",
      options: props.tmpPropertyTypes,
      value: selectedPropertyType,
      context: "Property Types",
    },
    {
      label: getLocalised('label.details.details.unit_type', 'Unit Type'),
      tooltip: "unitType",
      showToolTip: true,
      col: "half",
      type: "select",
      options: props.unit_types,
      value: props.selectedUnitType,
      context: "Unit Type",
    },
    {
      label: getLocalised('label.details.details.tenure', 'Tenure'),
      tooltip: "tenure",
      showToolTip: true,
      showEdgeTooltip: true,
      col: "half",
      type: "select",
      options: props.tenure_types,
      value: props.selectedTenureType,
      context: "Tenure",
    },
    {
      label: getLocalised('label.details.details.title_type', 'Title Type'),
      col: "half",
      type: "select",
      options: props.title_types,
      value: props.selectedTitleType,
      context: "Title Type",
    },
    {
      label: getLocalised('label.details.details.furnishing', 'Furnishing'),
      tooltip: "furnishing",
      showToolTip: true,
      showEdgeTooltip: true,
      col: "half",
      type: "select",
      options: props.furnishing_statuses,
      value: props.selectedFurnishingStatus,
      context: "Furnishing Status",
    },
    {
      label: getLocalised('label.details.details.occupancy', 'Occupancy'),
      tooltip: "occupancies",
      showToolTip: true,
      col: "half",
      type: "select",
      options: props.occupancies,
      value: props.selectedOccupancies,
      context: "Occupancies",
    },
  ];

  const addressInfo = [
    {
      label: getLocalised("label.details.address.property_name", "Property Name"),
      renderContent: () => (
        <section className="grid-full-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.address.property_name", "Property Name")}
            </h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showEdgeTooltip: true,
                tooltip: "propertyName",
              }}
            />
          </div>
          <i style={{ fontSize: 13, marginBottom: 10, color: "red" }}>
            {getLocalised(
              "label.details.helptext.address.property_name", 
              `Select the official name of the project/township from the drop-down list, or type it in manually if it isn"t in the list.`
            )}
          </i>
          <CustomTypeahead
            disabled={!can_update}
            required={true}
            typeaheadId={"property_name"}
            containerStyle={{ marginTop: 6 }}
            options={props.autoSuggestOptions || []}
            selectedValue={property_name ? [property_name] : []}
            filterBy={[
              "name_s_lower",
              "state_s_lower",
              "street_name_s_lower",
              "area_s_lower",
            ]}
            labelKey={"name_s_lower"}
            onSearch={(val) => onChangePropertyName(val)}
            onSelect={(val) =>
              val && val.length > 0 && onSelectPropertyName(val[0])
            }
            childrenHead={(rowItem) => <p>{rowItem.name_s_lower}</p>}
            highlighterData={[
              (option) =>
                `Area: ${option.area_s_lower || "N/A"} | State: ${option.state_s_lower || "N/A"}`,
            ]}
          />
        </section>
      ),
    },
    {
      label: getLocalised("label.details.address.property_address", "Property Address"),
      col: "full",
      type: "text",
      value: props.address,
      context: "Address",
    },
    {
      label: getLocalised("label.details.address.hidden_address", "Hidden Address"),
      col: "full",
      type: "text",
      value: props.hidden_address,
      context: "Hidden Address",
      helpText:
        "Please enter the actual address of the property, the address shown will not be made public unless approved by you. Your listing will not be able to utilise the recommendation engine if you leave blank or the address is not accurate.",
    },
    {
      label: getLocalised("label.details.address.country", "Country"),
      col: "half",
      type: "select",
      options: props.countries,
      value: selectedCountry,
      context: "Country",
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      tooltip: "country",
    },
    {
      label: getLocalised("label.details.address.state_province", "State / Province"),
      col: "half",
      type: "select",
      options:
        selectedCountry && selectedCountry.states
          ? _.orderBy(selectedCountry.states, ["name"], ["asc"])
          : [],
      value: props.selectedState,
      context: "State",
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
      tooltip: "state",
    },
    {
      label: getLocalised("label.details.address.area_city", "Area / City"),
      renderContent: () => (
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title">{getLocalised("label.details.address.area_city", "Area / City")}</h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showJuwaiAsiaToolTip: true,
                showJuwaiToolTip: true,
                showEdgeTooltip: true,
                tooltip: "townShip",
              }}
            />
          </div>
          <CustomTypeahead
            disabled={!can_update}
            selectedValue={
              _.find(townshipArray, { name: township })
                ? [_.find(townshipArray, { name: township })]
                : []
            }
            options={townshipArray}
            labelKey={"name"}
            filterBy={["name"]}
            typeaheadId={"township"}
            onSelect={(val) =>
              onChangeValue(
                "Township",
                val && val.length > 0 ? val[0].name : "",
              )
            }
            required={true}
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
          />
        </section>
      ),
    },
    {
      label: getLocalised("label.details.address.sub_area", "Sub Area"),
      col: "half",
      type: "autosuggest",
      options: props.subAreaArray,
      value: props.sub_area,
      context: "Sub Area",
      disabled: props.onLoadSubarea
    },
    {
      label: getLocalised("label.details.address.postal_code", "Postal Code"),
      col: "half",
      type: "text",
      value: props.postalCode,
      context: "Post Code",
    },
  ];

  const priceInfo = [
    {
      label: getLocalised("label.details.price.currency", "Currency"),
      value: props.selectedCurrency,
      type: "select",
      col: "half",
      context: "Currency",
      options: props.currencies,
      showToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "currency",
    },
    {
      label: getLocalised("label.details.price.asking_price", "Asking Price"),
      value: props.askingPrice,
      type: "text",
      col: "half",
      context: "Asking Price",
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "askingPrice",
    },
    {
      label: 
        getLocalised(
          `label.details.price.asking_price_per_${
            selectedMeasurement.name === "Square Feet" ? "square_feet" :
            selectedMeasurement.name === "Square Meter" ? "square_meter" :
            selectedMeasurement.name === "Hectare" ? "hectare" :
            "acres"
          }_unit`, 
          `Asking Price Per ${selectedMeasurement.name} Measurement Unit`
        ),
      value: askingPricePerMeasurementUnit,
      type: "text",
      col: "half",
      context: `Asking Price Per ${selectedMeasurement.name} Measurement Unit`,
    },
    {
      label: getLocalised("label.details.price.monthly_maintenance_fee", "Monthly Maintenance Fee"),
      renderContent: () => (
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.price.monthly_maintenance_fee", "Monthly Maintenance Fee")}
          </h2>
          <CustomFormInput
            type="number"
            disabled={!can_update}
            required={true}
            value={monthlyMaintainanceFee}
            onChangeValue={(val) =>
              onChangeValue("Monthly Maintenance Fee", val)
            }
          />
          <CustomCheckbox
            disabled={!can_update}
            labelClassname="mb-20"
            checked={isMonthlyMaintainanceFeePerMeasurementUnit}
            content={
              getLocalised(
              `label.details.price.monthly_maintenance_fee_${
                selectedMeasurement.name === "Square Feet" ? "sqft" :
                selectedMeasurement.name === "Square Meter" ? "sqm" :
                selectedMeasurement.name === "Hectare" ? "hectare" :
                "acres"
              }`, 
              `Monthly Maintenance Fee is per ${selectedMeasurement.name} unit`
            )}
            onChangeCheckboxValue={() =>
              onCheckCheckboxValue(
                "Monthly maintainance fee per measurement unit",
              )
            }
          />
        </section>
      ),
    },
  ];

  const specificationsInfo = [
    {
      label: getLocalised("label.details.specification.measurement_unit", "Measurement Unit"),
      value: selectedMeasurement,
      type: "select",
      col: "half",
      context: "Measurement Unit",
      options: props.measurements,
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "measurementUnit",
    },
    {
      label: getLocalised("label.details.specification.built_up_utilised_area", "Built-up / Utilised Area"),
      value: props.builtUp,
      type: "text",
      col: "half",
      context: "Built Up",
      showToolTip: true,
      showJuwaiToolTip: true,
      showEdgeTooltip: true,
      tooltip: "builtUp",
    },
    {
      label: getLocalised("label.details.specification.land_area", "Land Area"),
      value: props.landArea,
      type: "text",
      col: "half",
      context: "Land Area",
      showToolTip: true,
      showJuwaiAsiaToolTip: true,
      showJuwaiToolTip: true,
      tooltip: "landArea",
    },
    {
      label: getLocalised("label.details.specification.carparks", "Carparks"),
      value: props.selectedCarparkOption,
      type: "select",
      col: "half",
      context: "Carparks",
      options: props.car_park_options,
      showJuwaiToolTip: true,
      tooltip: "carPark",
    },
    {
      label: getLocalised("label.details.specification.bedrooms", "Bedrooms"),
      value: props.selectedBedroomOption,
      type: "select",
      col: "half",
      context: "Bedrooms",
      options: props.bedroom_options,
      showToolTip: true,
      tooltip: "bedRoom",
      showJuwaiToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised("label.details.specification.bathrooms", "Bathrooms"),
      value: props.selectedBathroomOption,
      type: "select",
      col: "half",
      context: "Bathrooms",
      options: props.bathroom_options,
      showToolTip: true,
      tooltip: "bathRoom",
      showJuwaiToolTip: true,
      showJuwaiAsiaToolTip: true,
      showEdgeTooltip: true,
    },
    {
      label: getLocalised("label.details.specification.direction", "Direction"),
      value: props.selectedDirection,
      type: "select",
      col: "half",
      context: "Direction",
      options: props.directions,
      showToolTip: true,
      showJuwaiToolTip: true,
      tooltip: "direction",
      showJuwaiAsiaToolTip: true,
    },
  ];

  const debounceAutoSuggest = useCallback(
    _.debounce((param) => edgeAutoSuggest(param), 600),
    [],
  );
  const onChangePropertyName = (query) => {
    onChangeValue("Property Name", query);
    if (query) {
      debounceAutoSuggest({ query, selectedCategory, selectedPropertyType });
    }
  };

  const onSelectPropertyName = (val) => {
    if (val) {
      let tempCountry = _.find(props.countries, { name: "Malaysia" });
      let tempStateMapping = _.find(edgePropStateMap, { id: val.state_id_i });
      let tempState = _.find(tempCountry.states, {
        id: tempStateMapping.map_id,
      });
      onChangeValue("Address", val.street_name_s_lower);
      onChangeValue("Hidden Address", val.street_name_s_lower);
      onChangeValue("Edge Prop Asset ID", val.asset_id_i);
      onChangeValue("Property Name", val.name_s_lower);
      onChangeValue("Post Code", val.postal_code_i);
      onChangeValue("Country", tempCountry || {});
      onChangeValue("State", tempState || {});
      onChangeValue("Township", val.area_s_lower);
    }
  };

  const renderInput = (item, index) => (
    <section className={`grid-${item.col}-col`} key={index}>
      <div className="d-flex">
        <h2
          className="at-form-input__title"
          required={!["radio", "autosuggest"].includes(item.type)}
        >
          {item.label}
        </h2>
        {item.tooltip && (
          <SubsalesTooltip
            data={{
              tooltip: item.tooltip,
              showToolTip: item.showToolTip,
              showEdgeTooltip: item.showEdgeTooltip,
              showJuwaiToolTip: item.showJuwaiToolTip,
              showJuwaiAsiaToolTip: item.showJuwaiAsiaToolTip,
            }}
          />
        )}
      </div>
      {item.type === "select" && (
        <CustomSelect
          getLocalised={getLocalised}
          disabled={!can_update}
          required={true}
          className="w-100"
          selectItems={item.options || []}
          currentlySelected={item.value}
          updateSelect={(val) => onChangeValue(item.context, val)}
        />
      )}
      {item.type === "multiSelect" && (
        <AtlasMultiSelect
          getLocalised={getLocalised}
          disabled={!can_update}
          required={true}
          selectType={"section"}
          className="w-100"
          selectItems={item.options || []}
          updateSelect={(val) => onChangeValue(item.context, val)}
          currentlySelected={item.value}
        />
      )}
      {item.type === "radio" && (
        <CustomRadioGroup
          getLocalised={getLocalised}
          disabled={!can_update}
          checkedValue={
            item.context === "Listing Category"
              ? selectedCategory?.value ?? ""
              : item.value || ""
          }
          options={item.options}
          selectedRadioValue={(val) =>
            item.context === "Listing Category"
              ? props.selectedListingCategory(val)
              : onChangeValue(item.context, val)
          }
        />
      )}
      {item.type === "autosuggest" && (
        <AtlasAutosuggest
          className={"at-listing__info-autosuggest"}
          value={item.value || ""}
          inputStyle={{ height: 40, borderColor: "#e0e0e0", fontSize: 15 }}
          updateValue={(val) => onChangeValue(item.context, val)}
          placeholder={item.placeholder || " "}
          options={item.options}
          disabled={!can_update || item.disabled}
        />
      )}
      {item.type === "text" && (
        <CustomFormInput
          type="text"
          required={true}
          disabled={!can_update}
          value={item.value}
          onChangeValue={(val) => onChangeValue(item.context, val)}
        />
      )}
    </section>
  );

  return (
    <>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-building-3"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.details.details.title", "Details")}
        </div>
        <div className="grid-control">
          {basicInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-location"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.details.address.title", "Address")}
        </div>
        <div className="grid-control">
          {addressInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-home"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.details.specification.title", "Specification")}
        </div>
        <div className="grid-control">
          {specificationsInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-dollar-circle"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.details.price.title", "Price")}
        </div>
        <div className="grid-control">
          {priceInfo.map((item, index) => {
            if (item.renderContent) {
              return item.renderContent(item, index);
            } else {
              return renderInput(item, index);
            }
          })}
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
