import React, { Component } from "react";
import { compose } from "redux";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import PersonalCampaigns from "./components/PersonalCampaigns";
import LoadingModal from "components/LoadingModal";
import TeamCampaigns from "./components/TeamCampaigns";
import CustomButton from "components/Button";

import VersionCheckHOC from "actions/versionCheck";
import CampaignTrackersHOC from "./actions";
import { initGA } from "utils/ga";

class CampaignTrackers extends Component {
  componentDidMount = () => {
    initGA("/dashboard/reports/campaign-trackers");
    this.props.getPersonalSummary(false);
    this.props.getTeamSummary();
  };

  renderTabContent = () => {
    const { selectedTab } = this.props;
    if (selectedTab === "Personal") {
      return <PersonalCampaigns {...this.props} />;
    } else if (selectedTab === "Team") {
      return <TeamCampaigns {...this.props} />;
    }
  };

  renderCardContent = () => {
    return (
      <>
        <div className="at-campaign-tracker__tab-options">
          <CustomButton
            children={"Personal"}
            className={
              this.props.selectedTab === "Personal"
                ? "at-campaign-tracker__tab-selected"
                : ""
            }
            onClick={() => {
              this.props.resetParamsYear()
              this.props.onChangeCampaignTrackerHOC("Personal", "selectedTab")
              this.props.getPersonalSummary(false)
            }}
          />
          <CustomButton
            children={"Team"}
            className={
              this.props.selectedTab === "Team"
                ? "at-campaign-tracker__tab-selected"
                : ""
            }
            onClick={() => {
              this.props.resetParamsYear()
              this.props.onChangeCampaignTrackerHOC("Team", "selectedTab")
              this.props.getTeamSummary()
            }}
          />
        </div>
        {this.renderTabContent()}
      </>
    );
  };

  render = () => {
    const { onLoadCampaign } = this.props;
    return (
      <>
        <ModuleHeader
          title={"Campaign Tracker"}
          atlasIcon={"icon-bold-gift"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {onLoadCampaign && <LoadingModal />}
      </>
    );
  };
}

export default compose(CampaignTrackersHOC, VersionCheckHOC)(CampaignTrackers);
