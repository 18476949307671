import React, { useEffect, useState } from "react";
import Moment from "moment";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from 'components/RadioGroup'
import ModalDialog from "components/Modal/ModalDialog";
import LoadingModal from "components/LoadingModal";

const DownloadCP58 = ({
  cp58List,
  cp58Year,
  onLoadAppointmentLetter,

  onClose,
  getCP58,
  downloadCP58,
  onChangeProfileHOC
}) => {
  const [countDown, setCountDown] = useState(false);

  useEffect(() => {
    if (cp58Year) {
      getCP58(cp58Year);
    }
  }, [cp58Year])

  const renderTime = ({ remainingTime }) => {
    return <p style={{ fontSize: 10, fontWeight: 500 }}>{remainingTime}</p>;
  };

  return (
    <ModalDialog
      title={"My CP58"}
      responsiveSize={"sm"}
      onClose={onClose}
      children={
        <>
          <CustomRadioGroup
            checkedValue={cp58Year}
            containerClassName={"mb-3"}
            options={[
              {value: Moment().subtract(1, 'years').format('YYYY'), label: Moment().subtract(1, 'years').format('YYYY')},
              {value: Moment().subtract(2, 'years').format('YYYY'), label: Moment().subtract(2, 'years').format('YYYY')},
            ]}
            selectedRadioValue={(val) => onChangeProfileHOC(val, "cp58Year")}
          />
          {cp58List.map((item, index) => (
            <div
              key={`download-cp58-${index}`}
              className={"at-download-cp58__document"}
            >
              <AtlasIcon svgHref={"atlas-document-text"} />
              {item.name}
            </div>
          ))}
          <CustomButton
            disabled={countDown}
            className={"btn-new btn-new--primary"}
            children={
              <>
                {countDown && (
                  <div className="mr-2">
                    <CountdownCircleTimer
                      isPlaying
                      size={20}
                      strokeWidth={2}
                      colors={["#ffffff"]}
                      duration={10}
                      initialRemainingTime={10}
                      onComplete={() => setCountDown(false)}
                      trailColor={[["#e4560f"]]}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                )}
                Download
              </>
            }
            onClick={() => downloadCP58(() => setCountDown(true))}
          />
          {onLoadAppointmentLetter && <LoadingModal />}
        </>
      }
    />
  );
};

export default DownloadCP58;
