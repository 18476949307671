import React, { Component } from "react";
import Moment from "moment";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { Post, Put, Get, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateModal: false,
      showUpdateModal: false,
      showPaymentModal: false,
      showDeleteConfirmation: false,
      showApproveModal: false,
      showRejectModal: false,
      showCancelModal: false,

      roomBookings: [],
      roomBookingsMeta: {},
      roomBookingsPage: "",

      searchParams: [
        {
          label: "Meeting Room Name",
          value: `meeting_room_name_cont`,
          type: "input",
          param: "",
        },
        {
          label: "Event Subject",
          value: `event_subject_cont`,
          type: "input",
          param: "",
        },
        {
          label: "Meeting Date",
          value: `meeting_date_eq`,
          type: "date",
          param: "",
        },
        {
          label: "Email",
          value: "user_email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Mobile Number",
          value: "user_mobile_contact_number_cont",
          type: "input",
          param: "",
        },
        {
          label: "Branch",
          value: "meeting_room_branch_id_eq",
          type: "select",
          param: "",
          options: [],
        },
        {
          label: "Room Type",
          value: "meeting_room_meeting_room_type_id_eq",
          type: "radio",
          param: "",
          options: [
            { label: "Business Lounge", value: 2 },
            { label: "Meeting Room", value: 1 },
          ]
        },
        {
          col: 8,
          label: "Status",
          value: "status_id_eq",
          type: "radio",
          param: "",
          options: [
            { label: "Confirmed", value: 1 },
            { label: "In Use", value: 2 },
            { label: "Completed", value: 3 },
            { label: "Cancelled", value: 4 },
          ],
        },
      ],
      meetingRooms: [],
      calendarEvent: [],
      selectedRoomBooking: {},
    };

    load = (param) => this.setState({ loading: param });
    onChangeRoomBooking = (val, context) => this.setState({ [context]: val });

    bigCalendarViewObj = (booking) => {
      let tmp = [];
      booking.map((item) => {
        tmp.push({
          title: item.event_subject,
          start: Moment(item.start_time).toDate(),
          end: Moment(item.end_time).toDate(),
        });
      });
      return tmp;
    };

    getMeetingRooms = () =>
      Get(
        `/admin/meeting_rooms?full_list=true&request_from=booking_history`,
        this.getMeetingRoomsSuccess,
        this.getMeetingRoomsError,
        this.load,
      );
    getMeetingRoomsSuccess = (payload) =>
      this.setState({ meetingRooms: payload });
    getMeetingRoomsError = (err) => requestError(err);

    getRoomBooking = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/bookings?${search}page=${page}`,
        this.getRoomBookingSuccess,
        this.getRoomBookingError,
        this.load,
      );
    }
    getRoomBookingSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        roomBookings: payload.data,
        roomBookingsMeta: payload.meta,
        roomBookingsPage: tempPages,
      });
    };
    getRoomBookingError = (err) => requestError(err);

    getSelectedRoomBooking = (id) => {
      Get(
        `/admin/bookings/${id}`,
        this.getSelectedRoomBookingSuccess,
        this.getSelectedRoomBookingError,
        this.load,
      );
    };
    getSelectedRoomBookingSuccess = (payload) =>
      this.setState({
        selectedRoomBooking: {
          ...payload,
          meeting_room_id: payload.meeting_room_id,
        },
        showUpdateModal: true,
      });
    getSelectedRoomBookingError = (err) => requestError(err);

    approveRoomBooking = (id) => {
      Put(
        `/admin/bookings/${id}`,
        {status_id: 1},
        this.approveRoomBookingSuccess,
        this.approveRoomBookingError,
        this.load,
      ); 
    }
    approveRoomBookingSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess("Successfully approved room booking");
      this.setState({ showApproveModal: false, selectedRoomBooking: payload });
      this.getRoomBooking(currentPage, searchParams);
    }
    approveRoomBookingError = (err) => requestError(err);

    cancelRoomBooking = (id) => {
      Put(
        `/admin/bookings/${id}`,
        {status_id: 4},
        this.cancelRoomBookingSuccess,
        this.cancelRoomBookingError,
        this.load,
      );
    }
    cancelRoomBookingSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess("Successfully cancel room booking");
      this.setState({ showCancelModal: false, selectedRoomBooking: payload });
      this.getRoomBooking(currentPage, searchParams);
    }
    cancelRoomBookingError = (err) => requestError(err);

    rejectRoomBooking = (id,rejectReason) => {
      Put(
        `/admin/bookings/${id}`,
        {status_id: 7, rejection_reason: rejectReason},
        this.rejectRoomBookingSuccess,
        this.rejectRoomBookingError,
        this.load,
      );
    }
    rejectRoomBookingSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess("Successfully rejected room booking");
      this.setState({ showRejectModal: false, selectedRoomBooking: payload });
      this.getRoomBooking(currentPage, searchParams);
    }
    rejectRoomBookingError = (err) => requestError(err);

    createRoomBooking = (dataToSubmit) =>
      Post(
        `/admin/bookings`,
        dataToSubmit,
        this.createRoomBookingSuccess,
        this.createRoomBookingError,
        this.load,
      );
    createRoomBookingSuccess = payload => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Successfully created room booking");
      this.setState({ showCreateModal: false });
      this.getRoomBooking(currentPage, searchParams);
      this.getSelectedRoomBooking(payload.id);
    };
    createRoomBookingError = (err) => requestError(err);

    updateRoomBooking = (dataToSubmit) =>
      Put(
        `/admin/bookings/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateRoomBookingSuccess,
        this.updateRoomBookingError,
        this.load,
      );
    updateRoomBookingSuccess = () => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Successfully updated room booking");
      this.setState({
        showUpdateModal: false,
        selectedRoomBooking: {}
      });
      this.getRoomBooking(currentPage, searchParams);
    };
    updateRoomBookingError = (err) => requestError(err);

    deleteRoomBooking = (id) =>
      Delete(
        `/admin/bookings/${id}`,
        this.deleteRoomBookingSuccess,
        this.deleteRoomBookingError,
        this.load,
      );
    deleteRoomBookingSuccess = () => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Successfully deleted room booking");
      this.setState({ showDeleteConfirmation: false });
      this.getRoomBooking(currentPage, searchParams);
    };
    deleteRoomBookingError = (err) => requestError(err);

    getCalendarEvent = (id, date) =>
      Get(
        `/meeting_rooms/${id}?booking_date=${date}&request_from=booking_history`,
        this.getCalendarEventSuccess,
        this.getCalendarEventError,
        this.load,
      );
    getCalendarEventSuccess = (payload) => {
      let tmp = [];
      if (payload.bookings && payload.bookings.length > 0) {
        tmp = this.bigCalendarViewObj(payload.bookings);
      }
      this.setState({ calendarEvent: tmp });
      this.setState({ selectedRoomBooking : {
        ...this.state.selectedRoomBooking,
        branch_id: payload.branch_id,
        location: payload.location,
        capacity: payload.capacity,
        business_lounge_pricings: payload.business_lounge_pricings,
        room_add_ons: payload.add_ons,
        bookings: payload.bookings,
       }});

    };
    getCalendarEventError = (error) => requestError(error);

    createManualPayment = dataToSubmit =>
      Post(
        `/admin/booking_payments`,
        {payment: dataToSubmit},
        this.createManualPaymentSuccess,
        this.createManualPaymentError,
        this.load,
      );
    createManualPaymentSuccess = (payload) => {
      requestSuccess("Successfully created payment");
      this.setState({ showPaymentModal: false });
      this.getSelectedRoomBooking(payload.booking_id);
    }
    createManualPaymentError = (err) => requestError(err);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadRoomBooking={this.state.loading}
            getRoomBooking={this.getRoomBooking}
            getMeetingRooms={this.getMeetingRooms}
            getCalendarEvent={this.getCalendarEvent}
            createRoomBooking={this.createRoomBooking}
            updateRoomBooking={this.updateRoomBooking}
            deleteRoomBooking={this.deleteRoomBooking}
            approveRoomBooking={this.approveRoomBooking}
            rejectRoomBooking={this.rejectRoomBooking}
            cancelRoomBooking={this.cancelRoomBooking}
            showCancelModal={this.state.showCancelModal}
            createManualPayment={this.createManualPayment}
            getSelectedRoomBooking={this.getSelectedRoomBooking}
            onChangeRoomBooking={this.onChangeRoomBooking}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {storeLastView})(WithHOC);
};

export default HOC;
