import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";

import AtlasButton from "components/Button";
import Spinner from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import DeleteConfirmationDialog from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CustomCheckbox from "components/Checkbox";
import CreateEmailList from "./Create";
import UpdateEmailList from "./Update";

import withEmailLists from "containers/Dashboard/EmailCampaigns/actions/lists";
import { requestError } from "utils/requestHandler";
import { columnData } from "./assets";

const To = ({
  isActive,
  checkedArray,
  setCheckedArray,
  handleAccordionChange,
  handleAccordionClose,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  onLoadEmailLists,
  emailLists,
  emailListsPages,
  searchParams,
  selectedEmailList,
  teamsList,
  countriesList,
  branchesList,
  emailListDialog,
  onChangeEmailListsHOC,
  getEmailLists,
  getSelectedEmailList,
  getTeamsList,
  getCountriesList,
  getBranchesList,
  checkEmailList,
  createEmailList,
  updateEmailList,
  deleteEmailList,
}) => {
  useEffect(() => {
    getEmailLists();
  }, []);

  useEffect(() => {
    if (
      selectedEmailCampaign.email_list_ids &&
      selectedEmailCampaign.email_list_ids.length > 0 &&
      emailLists.data &&
      emailLists.data.length > 0
    ) {
      setCheckedArray(
        emailLists.data
          .filter((list) =>
            selectedEmailCampaign.email_list_ids.includes(list.id),
          )
          .map((list) => list.id),
      );
    } else {
      setCheckedArray([]);
    }
  }, [selectedEmailCampaign, emailLists]);

  const isChecked = selectedEmailCampaign.email_list_ids;

  const handleClose = () => {
    if (
      selectedEmailCampaign.email_list_ids &&
      selectedEmailCampaign.email_list_ids.length > 0 &&
      emailLists.data &&
      emailLists.data.length > 0
    ) {
      setCheckedArray(
        emailLists.data
          .filter((list) =>
            selectedEmailCampaign.email_list_ids.includes(list.id),
          )
          .map((list) => list.id),
      );
    } else {
      setCheckedArray([]);
    }

    handleAccordionClose();
  };

  const onHandleCheckAllBox = () => {
    if (checkedArray.length < emailLists.data.length) {
      setCheckedArray(emailLists.data.map((list) => list.id));
    } else {
      setCheckedArray([]);
    }
  };

  const onHandleCheckBox = (id) => {
    if (checkedArray.includes(id)) {
      setCheckedArray(checkedArray.filter((item) => item !== id));
    } else {
      setCheckedArray([...checkedArray, id]);
    }
  };

  const handleSave = () => {
    if (checkedArray.length === 0) {
      requestError("Select at least one email list");
    } else {
      const newEmailCampaign = {
        ...selectedEmailCampaign,
        email_list_ids: checkedArray,
        status: 1,
      };

      onChangeEmailCampaignsHOC("selectedEmailCampaign", newEmailCampaign);
      updateEmailCampaign(newEmailCampaign);
      handleAccordionClose();
    }
  };

  const renderCreateDialog = () => (
    <CreateEmailList
      onChangeEmailListsHOC={onChangeEmailListsHOC}
      teamsList={teamsList}
      countriesList={countriesList}
      branchesList={branchesList}
      getTeamsList={getTeamsList}
      getCountriesList={getCountriesList}
      getBranchesList={getBranchesList}
      checkEmailList={checkEmailList}
      createEmailList={createEmailList}
    />
  );

  const renderUpdateDialog = () => (
    <UpdateEmailList
      onChangeEmailListsHOC={onChangeEmailListsHOC}
      selectedEmailList={selectedEmailList}
      teamsList={teamsList}
      countriesList={countriesList}
      branchesList={branchesList}
      getTeamsList={getTeamsList}
      getCountriesList={getCountriesList}
      getBranchesList={getBranchesList}
      checkEmailList={checkEmailList}
      updateEmailList={updateEmailList}
    />
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={emailListDialog === "delete"}
      title={"Delete email list"}
      message={"Are you sure you want to delete this list?"}
      negativeAction={() => onChangeEmailListsHOC("emailListDialog", "")}
      positiveText={"Yes"}
      positiveAction={deleteEmailList}
    />
  );

  return (
    <>
      <div className="d-flex flex-wrap align-items-center g-3 pb-3">
        <ModuleFormHeader
          title={"Recipient Details"}
          moduleIcon={"atlas-user-edit"} />
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--primary"}
          onClick={() =>
            onChangeEmailListsHOC("emailListDialog", "create")
          }
        >
          <IoMdAdd style={{ height: 18, width: 18 }} />
          New Email List
        </AtlasButton>
      </div>
      <AtPaginatedTable
        columns={columnData({
          checkedArray,
          emailListsData: emailLists.data || [],
          onHandleCheckBox,
          onHandleCheckAllBox,
        })}
        paginationClassName={"pb-0"}
        getListAPI={getEmailLists}
        rowData={emailLists.data}
        meta={emailLists.meta}
        totalPages={emailListsPages}
        enableSort={true}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeEmailListsHOC("searchParams", val)}
        actionColumnContent={[
          {
            name: "edit",
            onClick: (rowData) => {
              getSelectedEmailList(rowData.id);
              onChangeEmailListsHOC("emailListDialog", "edit");
            },
          },
          {
            name: "delete",
            onClick: (rowData) => {
              onChangeEmailListsHOC("selectedId", rowData.id);
              onChangeEmailListsHOC("emailListDialog", "delete");
            },
          },
        ]}
        renderCheckBoxActions={() => {
          return (
            <div
              style={{ padding: 2 }}
              className="at-mobile-view__controller mb-1 mr-1">
              <CustomCheckbox
                contentStyle={{ fontWeight: 500 }}
                labelClassname={"at-table-tab-checkbox_responsive"}
                checkedArray={checkedArray}
                content={
                  checkedArray.length > 0 &&
                  checkedArray.length === emailLists.data.length
                    ? "Deselect All"
                    : "Select All"
                }
                checked={
                  checkedArray.length > 0 &&
                  checkedArray.length === emailLists.data.length
                }
                onChangeCheckboxValue={() => onHandleCheckAllBox()}
              />
            </div>
          )}
        }
      />

      {emailListDialog === "create" && renderCreateDialog()}
      {emailListDialog === "edit" && renderUpdateDialog()}
      {renderDeleteDialog()}
      {onLoadEmailLists && <Spinner />}
    </>
  );
};

export default withEmailLists(To);
