import { Grid } from "@material-ui/core";
import FormInput from "./formInput";

const FormInputs = ({ payload = [], inputConfig = [], onChange, language }) => {
  return (
    <Grid container spacing={16}>
      {inputConfig.filter((item) => !item.hidden).map((config, index) => (
        <FormInput
          language={language}
          payload={config.payload || payload}
          config={config}
          index={index}
          onChange={onChange}
        />
      ))}
    </Grid>
  );
};

export default FormInputs;
