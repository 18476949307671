import React, { Component, Fragment } from "react";
import { Get, Put } from "utils/axios";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      permissions: [],
    };

    load = (param) => this.setState({ loading: param });

    // get pa permissions
    getPermissions = (id) =>
      Get(
        `/user_permissions?user_id=${id}`,
        this.getPermissionsSuccess,
        this.getPermissionsError,
        this.load,
      );
    getPermissionsSuccess = (payload) => {
      let tempPermission = _.orderBy(payload, ["module_name"], ["asc"]);

      this.setState({ permissions: tempPermission });
    };
    getPermissionsError = (error) => requestError(error);

    // update permission
    updatePermissions = (data) =>
      Put(
        `/user_permissions/update`,
        {
          permission: data,
          user_id: this.props.selectedPersonalAssistant.id,
        },
        this.updatePermissionsSuccess,
        this.updatePermissionsError,
        this.load,
      );
    updatePermissionsSuccess = () =>
      requestSuccess("Permissions updated successfully.");
    updatePermissionsError = (error) => requestError(error);

    onToggleCheckBox = (columnValue, id, val) => {
      let temp = _.cloneDeep(this.state.permissions);
      let tempObj = _.find(temp, { id: id });
      let index = temp.indexOf(tempObj);
      temp[index][columnValue] = val;

      this.setState({ permissions: temp });
    };

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            permissions={this.state.permissions}
            onLoadPermission={this.state.loading}
            getPermissions={this.getPermissions}
            onChangePermissionHOC={this.onChangePermissionHOC}
            onToggleCheckBox={this.onToggleCheckBox}
            updatePermissions={this.updatePermissions}
          />
        </Fragment>
      );
    };
  }
  return WithHOC;
};

export default HOC;
