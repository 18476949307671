import { PiBathtubFill, PiBedFill, PiHouseLineFill } from 'react-icons/pi';
import { RiRuler2Line } from 'react-icons/ri';

const WhiteBoxTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 8,
    fontWeight: 600
  };

  const iconStyle = {
    minWidth: 14,
    width: 14,
    height: 14,
    marginRight: 4
  };

  return (
    <div style={{ width: 280 }}>
      <div
        style={{
          padding: '8px 12px',
          border: '2px solid #fff',
          textAlign: 'center',
          color: '#fff',
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 4
        }}
      >
        {unitType}
      </div>
      <div
        style={{
          backgroundColor: `rgba(255, 255, 255, ${configuration?.propertyDetailsOpacity})`,
          padding: '8px 12px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px 8px',
            marginBottom: 4
          }}
        >
          <div style={detailStyle}>
            <RiRuler2Line style={iconStyle} />
            <span>
              {property.min_built_up?.toLocaleString()} -{' '}
              {property.max_built_up?.toLocaleString()} Sqft
            </span>
          </div>
          <div style={detailStyle}>
            <PiBedFill style={iconStyle} />
            <span>{property.bedroom_display || 0} bedrooms</span>
          </div>
          <div style={detailStyle}>
            <PiBathtubFill style={iconStyle} />
            <span>{property.bathroom_display || 0} bathrooms</span>
          </div>
        </div>
        <div style={detailStyle}>
          <PiHouseLineFill style={iconStyle} />
          {property.project_features
            ?.slice(0, 3)
            ?.map(feature => feature.name?.trim())
            ?.join(', ')}
        </div>
      </div>
    </div>
  );
};

export default WhiteBoxTemplate;
