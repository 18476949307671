import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showDeleteConfirmation: false,
      showEditAppThemeModal: false,
      showCreateAppThemeModal: false,
      showMobilePreview: false,

      appTheme: {},
      appThemePage: null,
      selectedAppTheme: null,
      selectedDeleteAppTheme: "",
      mobilePreview: {},
      searchParams: [
        {
          label: "App Theme Name",
          value: "name_cont",
          type: "input",
          col: 6,
          param: "",
        },
        {
          label: "Current Theme",
          value: "is_current_theme_true",
          type: "radio",
          param: "",
          col: 6,
          options: [
            { value: "", label: "All" },
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
          ],
        },
      ],
    };

    load = (param) => this.setState({ loading: param });

    onChangeAppThemeManagementHOC = (val, context) =>
      this.setState({ [context]: val });

    getAppTheme = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/app_themes?${search}page=${page}`,
        this.getAppThemeSuccess,
        this.getAppThemeError,
        this.load,
      );
    };
    getAppThemeSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        appTheme: payload,
        appThemePage: tmpTotalPages,
      });
    };
    getAppThemeError = (error) => requestError(error);

    getSelectedAppTheme = (id) =>
      Get(
        `/admin/app_themes/${id}`,
        this.getSelectedAppThemeSuccess,
        this.getSelectedAppThemeError,
        this.load,
      );
    getSelectedAppThemeSuccess = (payload) =>
      this.setState({
        selectedAppTheme: {
          ...payload,
          is_current_theme: payload.is_current_theme ? "1" : "2",
        },
        showCreateAppThemeModal: true,
      });
    getSelectedAppThemeError = (error) => requestError(error);

    createAppTheme = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);

      Post(
        `/admin/app_themes`,
        {
          ...temp,
          is_current_theme: temp.is_current_theme === "1",
          icon_colour: temp.icon_colour.hex,
          bgcolour: temp.bgcolour.hex,
        },
        this.createAppThemeSuccess,
        this.createAppThemeError,
        this.load,
      );
    };
    createAppThemeSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.setState({ showCreateAppThemeModal: false }, () => {
        this.getAppTheme(currentPage, searchParams);
      });
      requestSuccess(`App theme has been created successfully.`);
    };
    createAppThemeError = (error) => requestError(error);

    updateAppTheme = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      temp.is_current_theme = temp.is_current_theme === "1";
      temp.icon_colour = temp.icon_colour.hex;
      temp.bgcolour = temp.bgcolour.hex;

      Put(
        `/admin/app_themes/${dataToSubmit.id}`,
        {
          name: temp.name,
          icon_colour: temp.icon_colour,
          bgcolour: temp.bgcolour,
          is_current_theme: temp.is_current_theme,
          publish_on: temp.publish_on ? temp.publish_on : "",
          ...(temp.changeImage && {
            background_image: temp.background_image,
            background_image_file_name: temp.background_image_file_name,
          }),
        },
        this.updateAppThemeSuccess,
        this.updateAppThemeError,
        this.load,
      );
    };
    updateAppThemeSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState(
        {
          selectedAppTheme: null,
          showCreateAppThemeModal: false,
        },
        () => {
          this.getAppTheme(currentPage, searchParams);
        },
      );
      requestSuccess(`App theme has been updated successfully.`);
    };
    updateAppThemeError = (error) => requestError(error);

    deleteAppTheme = (id) =>
      Delete(
        `/admin/app_themes/${id}`,
        this.deleteAppThemeSuccss,
        this.deleteAppThemeError,
        this.load,
      );
    deleteAppThemeSuccss = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.setState(
        {
          selectedDeleteAppTheme: "",
          showDeleteConfirmation: false,
        },
        () => {
          this.getAppTheme(currentPage, searchParams);
        },
      );
      requestSuccess(`App theme has been delete successfully.`);
    };
    deleteAppThemeError = (error) => {
      requestError(error);
      this.setState({ showDeleteConfirmation: false });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            appTheme={this.state.appTheme}
            appThemePage={this.state.appThemePage}
            selectedAppTheme={this.state.selectedAppTheme}
            searchParams={this.state.searchParams}
            mobilePreview={this.state.mobilePreview}
            selectedDeleteAppTheme={this.state.selectedDeleteAppTheme}
            showCreateAppThemeModal={this.state.showCreateAppThemeModal}
            showEditAppThemeModal={this.state.showEditAppThemeModal}
            showMobilePreview={this.state.showMobilePreview}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            onLoadAppTheme={this.state.loading}
            onChangeAppThemeManagementHOC={this.onChangeAppThemeManagementHOC}
            getAppTheme={this.getAppTheme}
            getSelectedAppTheme={this.getSelectedAppTheme}
            createAppTheme={this.createAppTheme}
            updateAppTheme={this.updateAppTheme}
            deleteAppTheme={this.deleteAppTheme}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
