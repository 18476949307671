import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomConfirmationDialog from "components/Modal/confirmation";
import ResubmitConfirmationModal from "./components/resubmitConfirmation";
import PaymentGatewayFeedback from "../PaymentGatewayFeedback";
import ManageTicket from "./components/manageTicket";
import OrderTable from "./components/Table";

import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import DeliverBranchesHOC from "./actions/deliveryBranch";
import OrderResubmitHOC from "./actions/resubmit";
import OrderHistoryHOC from "./actions";

const paymentStatus = [
  { id: 0, label: "Pending", colorName: "Yellow" },
  { id: 1, label: "Processing", colorName: "Blue" },
  { id: 2, label: "Cancelled", colorName: "Red" },
  { id: 3, label: "Completed", colorName: "Green" },
  { id: 4, label: "Void", colorName: "Red" },
];

const headerData = [
  {
    label: "Order Number",
    renderColumn: (columnItem) => {
      const { data, showItems, onToggleExpand } = columnItem;
      return (
        <p className="d-flex align-items-center fw-500">
          <AtlasIcon
            svgHref={"atlas-arrow-circle-down"}
            className={`${showItems ? "at-chevron_up" : `at-chevron_down`}`}
            onClick={() => onToggleExpand()}
          />
          #{data.order_number}
        </p>
      );
    },
    style: { width: "20%", minWidth: "130px" },
  },
  {
    label: "Order Date",
    value: "created_at",
    style: { width: "20%" },
  },
  {
    label: "Total Item",
    renderColumn: (columnItem) => {
      const { market_order_items = [] } = columnItem.data;

      return (
        <p>
          {market_order_items.reduce((acc, item) => acc + item.quantity, 0)}
        </p>
      );
    },
    style: { width: "15%", textAlign: "center" },
  },
  {
    label: "Total Amount",
    renderColumn: (columnItem) => {
      const { total_amount = 0 } = columnItem.data;
      return <p>{`RM ${total_amount.toFixed(2)}`}</p>;
    },
    style: { width: "15%" },
  },
  {
    label: "Status",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
    renderColumn: ({ data }) => {
      const { colorName = "", label = "" } =
        _.find(paymentStatus, { id: data.status_id }) || {};

      return (
        <div className="d-flex">
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >{`${label}`}</div>
        </div>
      );
    },
    style: { width: "25%" },
  },
];

class OrderHistory extends Component {
  componentDidMount = () => {
    this.props.getOrderHistory(1, "");
    this.props.getDeliveryBranches();
    if (window.location.href.indexOf("/payment-feedback") > -1) {
      this.props.onChangeOrderHistoryHOC(true, "showPaymentFeedback");
    }
  };

  renderCardContent = () => {
    const { can_create, can_update } = permissionsChecker(
      "Atlas Store",
      this.props.data,
    );
    const { state = {} } = this.props.history.location;
    const tmpToPayId = !isEmptyValue(state.id) ? state.id : "";

    return (
      <OrderTable
        toPayId={tmpToPayId}
        headerData={headerData}
        can_create={can_create}
        can_update={can_update}
        rowData={this.props.orders.data || []}
        statusDictionary={paymentStatus}
        meta={this.props.orders.meta && this.props.orders.meta}
        onChangeOrderHistoryHOC={this.props.onChangeOrderHistoryHOC}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeOrderHistoryHOC(val, "searchParams")
        }
        redoPayment={() => this.props.redoPayment(this.props.repayOrder)}
        onToggleResubmit={async (item) => {
          await this.props.onChangeOrderHistoryHOC(item, "resubmitOrder");
          await this.props.onChangeOrderHistoryHOC(
            true,
            "showResubmitConfirmation",
          );
        }}
        getListAPI={this.props.getOrderHistory}
        totalPages={this.props.ordersPage}
        {...this.props}
      />
    );
  };

  render = () => {
    const {
      data,
      resubmitOrder,
      resubmitOrderItem,
      showManageTicket,
      showPaymentFeedback,
      showResubmitConfirmation,
      onLoadOrderHistory,
      onLoadResubmit,
      onChangeOrderHistoryHOC,
      onLoadDeliveryBranches,
      deliveryBranches,
    } = this.props;

    const { can_update } = permissionsChecker("Atlas Store", data);

    return (
      <>
        {this.renderCardContent()}
        {showManageTicket && (
          <ManageTicket {...this.props} can_update={can_update} />
        )}
        <PaymentGatewayFeedback
          open={showPaymentFeedback}
          onClose={() => onChangeOrderHistoryHOC(false, "showPaymentFeedback")}
        />
        <CustomConfirmationDialog
          open={this.props.showCancelPaymentConfirmation}
          title="Cancel Order"
          loading={this.props.onLoadOrderHistory}
          message={"Are you sure to cancel the order?"}
          mode="alert"
          positiveAction={() =>
            this.props.cancelPayment(this.props.selectedOrderHistory.id)
          }
          negativeAction={() =>
            this.props.onChangeOrderHistoryHOC(
              false,
              "showCancelPaymentConfirmation",
            )
          }
        />
        <ResubmitConfirmationModal
          resubmitOrder={resubmitOrder}
          title={"Resubmit Confirmation"}
          loading={onLoadResubmit}
          open={showResubmitConfirmation}
          positiveText={"Resubmit"}
          deliveryBranches={deliveryBranches}
          onShowProfileUpdate={() =>
            onChangeOrderHistoryHOC(true, "showProfileUpdate")
          }
          onChangeOrderHistoryHOC={onChangeOrderHistoryHOC}
          message={`Please ensure all details are correct and ready to be submitted once again.`}
          positiveAction={() => resubmitOrderItem(resubmitOrder)}
          negativeAction={() =>
            onChangeOrderHistoryHOC(false, "showResubmitConfirmation")
          }
        />
        {(onLoadOrderHistory || onLoadDeliveryBranches) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  OrderHistoryHOC,
  OrderResubmitHOC,
  DeliverBranchesHOC,
)(OrderHistory);
