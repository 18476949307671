import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from "components/RadioGroup";
import CustomTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";

import { documentType } from "../assets";

const columnData = [
  { header: "Uploaded At", accessor: "created_at", searchFlag: "created_at" },
  {
    header: "Document Type",
    accessor: "snapshot_document_type",
    searchFlag: "snapshot_document_type",
  },
];

const ReaDocumnet = ({
  selectedRenewal,
  showGenerateFormD,
  uploadedDocuments,
  selectedApplication,
  onLoadRenewalApplication,

  generateFormD,
  getUploadedDocument,
  createUploadDocument,
  removeUploadedDocument,
  onChangeRenewalDetailsHOC,
}) => {
  let filePond = useRef(null);

  const [documentOptions, setDocumentOptions] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  useEffect(() => {
    getUploadedDocument(selectedRenewal.id, selectedApplication.id);
  }, []);

  useEffect(() => {
    if (uploadedDocuments.data) {
      let temp = [];
      documentType.map((item) => {
        let checker = _.findIndex(uploadedDocuments.data, {
          snapshot_document_type: item.label,
        });
        temp.push({ ...item, uploaded: checker > -1 });
      });
      setDocumentOptions(temp);
    }
  }, [uploadedDocuments]);

  const uploadFile = (fileItems) => {
    if (fileItems) {
      Object.keys(fileItems).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let temp = {
            id: selectedApplication.id,
            rea_id: selectedRenewal.id,
            type_id: selectedDocType,
            snapshot: e.target.result,
            snapshot_file_name: fileItems[key].name,
          };
          createUploadDocument(temp);
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  return (
    <>
      <div className="mb-30 mt-30">
        <div className="at-form__content">
          <div className="d-flex align-items-center mb-3 grid_gap-2">
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={"atlas-document-upload"}
                style={{ fill: "#EA580C" }}
              />
            </div>
            <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
              Upload Documents
            </p>
          </div>
          <CustomRadioGroup
            mode={"old"}
            containerStyle={{ marginBottom: "15px" }}
            checkedValue={selectedDocType}
            options={documentOptions}
            selectedRadioValue={(val) => {
              setSelectedDocType(val);
              filePond.click();
            }}
            specialChecker={(uploaded) => (
              <AtlasIcon
                svgHref={"atlas-document-upload"}
                style={{ fill: uploaded && "#12B76A" }}
                className={"at-document__checker"}
              />
            )}
            status={true}
            mandatory={[]}
          />
          <button
            className="btn-new btn-new--primary mt-10"
            onClick={() => onChangeRenewalDetailsHOC(true, "showGenerateFormD")}
          >
            {uploadedDocuments.data &&
            _.find(uploadedDocuments.data, { snapshot_document_type: "Form D" })
              ? "Regenerate Form D"
              : "Generate Form D"}
          </button>
          <div style={{ display: "none" }}>
            <input
              ref={(ref) => (filePond = ref)}
              type="file"
              accept="image/jpeg, image/jpg, image/png, application/pdf"
              multiple={false}
              onChange={(e) => uploadFile(e.target.files)}
            />
          </div>
        </div>
        <div style={{ paddingTop: "15px" }}>
          <CustomTable
            rowData={uploadedDocuments.data || []}
            meta={[]}
            searchParams={[]}
            columns={columnData}
            totalPages={[]}
            renderCheckBoxActions={() => (
              <div className="d-flex align-items-center my-3 ml-3 grid_gap-2">
                <div
                  className="at-renewal-details__icon-badge"
                  style={{ backgroundColor: "#FFEDD5" }}
                >
                  <AtlasIcon
                    svgHref={"atlas-document-text"}
                    style={{ fill: "#EA580C" }}
                  />
                </div>
                <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
                  Document
                </p>
              </div>
            )}
            actionColumnContent={[
              {
                name: "view",
                onClick: (rowData) => window.open(rowData.snapshot, "_blank"),
              },
              {
                name: "delete",
                color: "#F04438",
                onClick: (rowData) =>
                  Promise.all([setSelectedDocument(rowData)]).then(() => {
                    setShowRemoveConfirmation(true);
                  }),
              },
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        loading={false}
        open={showRemoveConfirmation}
        title={"Delete Document"}
        message={`Are you sure you want to remove this document ?`}
        positiveAction={() =>
          Promise.all([
            removeUploadedDocument(
              selectedRenewal.id,
              selectedApplication.id,
              selectedDocument.id,
            ),
          ]).then(() => {
            setShowRemoveConfirmation(false);
          })
        }
        negativeAction={() => setShowRemoveConfirmation(false)}
      />
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showGenerateFormD}
        title={"Generate Form D"}
        message={`Are you sure you want to generate Form D ?`}
        positiveText={"Confirm"}
        positiveAction={() =>
          generateFormD(selectedRenewal.id, selectedApplication.id)
        }
        negativeText={"Cancel"}
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showGenerateFormD")
        }
      />
    </>
  );
};

export default ReaDocumnet;
