import React, { Component } from "react";
import _ from "lodash";

import SvgIcon from "components/Icon/new";
import AtlasTable from "components/NewTable";
import LoadingModal from "components/LoadingModal";

import DocsFinanceHOC from "./actions";

const ColumnData = [
  {
    header: "Title",
    searchFlag: "title",
    accessor: "title",
  },
  {
    header: "File Name",
    searchFlag: "document_file_name",
    accessor: "document_file_name",
  },
  {
    header: "Type",
    searchFlag: "type",
    accessor: "type",
  },
  {
    header: "Issue To",
    searchFlag: "issue_to_string",
    accessor: "issue_to_string",
  },
];

class DocsFinance extends Component {
  componentDidMount = () =>
    this.props.getFinanceDocs(this.props.selected_claim.id);

  render = () => {
    const { docsFinance, onLoadDocsFinance } = this.props;

    return (
      <>
        <AtlasTable
          mode={"new"}
          className={"mb-100 mt-10"}
          columns={ColumnData}
          hideSearch={true}
          pagination={true}
          rowData={docsFinance || []}
          actionColumnContent={[
            {
              name: "download",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 18, height: 18, fill: "#007bff" },
              onClick: (rowData) => window.open(rowData.url, "_blank"),
            },
          ]}
          renderExtraContent={() => (
            <div className={`at-table_heading`}>
              <div className="d-flex grid_gap-2">
                <SvgIcon
                  svgHref="confirmation-form_document-text"
                  className="at-icon_svg-table_heading-title"
                />
                <h5 className="fw-600">Uploaded Document</h5>
              </div>
            </div>
          )}
          emptyStateMessage={"No uploaded docs are found."}
        />
        {onLoadDocsFinance && <LoadingModal />}
      </>
    );
  };
}

export default DocsFinanceHOC(DocsFinance);
