import React, { useState, useEffect } from "react";
import _ from "lodash";
import { IoIosMenu } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import AtlasDialog from "components/Dialog";
import AtlasCloseButton from "components/Button/prev";
import AtlasCard from "components/Card";
import AlertBox from "components/AlertBox";
import LoadingModal from "components/LoadingModal";
import CustomDraggableSort from "components/DraggableSort";

const NewConfirmationModal = ({
  open,
  showLoading,
  eProcessPartyData,
  onChangePartyHOC,

  positiveAction,
  negativeAction,
}) => {
  const [signParty, setSignParty] = useState([]);

  useEffect(() => {
    processParty();
  }, [eProcessPartyData]);

  const processParty = () => {
    let tempParty = _.cloneDeep(eProcessPartyData);
    let tempVendor = [];
    let tempVendorRepresent = [];
    tempParty.map((item) => {
      if (item.party === "Tenant/Purchaser") 
        tempVendor.push(item);
      else 
        tempVendorRepresent.push(item);
    });

    setSignParty([
      { 
        id: 1, 
        data: tempVendor, 
        role: tempVendor[0]?.title
      },
      {
        id: 2,
        data: tempVendorRepresent,
        role: tempVendorRepresent[0]?.title,
      },
    ]);
  };

  const renderParticipant = (partyData, disabled, lastItem) => (
    <div className={`d-flex ${ !lastItem ? 'py-2' : 'pt-2' }`}
      style={{ borderBottom: !lastItem ? "1px solid #D1D5DB" : "" }}>
      {disabled && <div className="at-form__draggable-icon"></div>}
      {!disabled && <IoIosMenu className="at-form__draggable-icon" />}
      <div className="row at-form__participant-card">
        <div className="d-flex">
          <AtlasIcon
            svgHref={ partyData.signee_type_id === 0 ? "atlas-icon-user" : "atlas-building-company"}
            style={{ fill: "#F0631D", color: "#F0631D" }}
          />
          <div>
            <label className="at-form-input__title">Name</label>
            {partyData.signee_type_id === 1 && (
              <>
                <p>{`${partyData.company_name}`}</p> 
                <p>{`(${partyData.company_registration_no})`}</p>
              </>            )}
            <p>{partyData.full_name}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon
            svgHref={"atlas-personalcard"}
          />
          <div>
            <label className="at-form-input__title">NRIC</label>
            <p>{partyData.identification_number}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon 
            className="svg-icon-envelope" svgHref={"atlas-sms"} 
          />
          <div>
            <label className="at-form-input__title">Email</label>
            <p style={{ wordBreak: "break-all" }}>{partyData.email}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon
            svgHref={"atlas-mobile"}
          />
          <div>
            <label className="at-form-input__title">Mobile</label>
            <p style={{ wordBreak: "break-all" }}>
              {partyData.mobile_contact_number}
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );

  return (
    <AtlasDialog open={open}>
      <AtlasCard
        className={"at-section_card h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton onClick={() => negativeAction()} />
              <h5 className="at-card__title">
                {"Confirmation to start e-signing process"}{" "}
              </h5>
            </div>
            <AlertBox
              title={""}
              mode={"info"}
              containerClassName="mb-3"
              iconStyle={{ color: "#34B2FF" }}
              description={
                <p className={"at-card__description fw-400"}>
                  Please confirm the participants' email, mobile number, and
                  NRIC/Passport No. are correct before starting e-signing.
                  <br />
                  <div className="d-flex align-items-center">
                    <IoIosMenu 
                      className="at-form__draggable-icon" 
                      style={{
                        width: 25,
                        height: 25,
                        minWidth: 25,
                        marginTop: 4
                      }}/>
                    This icon indicates you can drag and drop participants
                    within the same category to change the signing order.
                  </div>
                </p>
              }
            />
            {signParty.length > 0 &&
              signParty.map((party, index) => (
                <div key={index} className="at-form__content">
                  <p className="at-form-input__title">
                    Step {index + 1}: Signed By {party.role}
                  </p>
                  <hr className='mb-2'/>
                  <CustomDraggableSort
                    data={party.data}
                    grid={0}
                    disabled={party.data.length < 2}
                    background={"white"}
                    containerClass={"w-100"}
                    onChangeOrder={(val) => {
                      let temp = _.cloneDeep(signParty);
                      temp[0].data = val;
                      let partyData = [];
                      temp.map((party) => {
                        party.data.map((item) => {
                          partyData.push(item);
                        });
                      });
                      partyData.map(
                        (party, index) => (party.position = index + 1),
                      );
                      onChangePartyHOC(partyData, "eProcessPartyData");
                    }}
                    renderContent={(partyData, index) =>
                      renderParticipant(partyData, party.data.length < 2, index === party.data.length - 1)
                    }
                  />
                </div>
              ))}
            <div className="at-form__content">
              <h4 className="at-card__title">Proceed e-Signing</h4>
              <p class="my-3 fs-2">
                Please ensure that all the information are correct before
                proceeding.
              </p>
              <button
                className={"btn-new btn-new--primary"}
                onClick={() => positiveAction()}
              >
                Confirm Proceed
              </button>
            </div>
          </>
        }
      />
      {showLoading && <LoadingModal />}
    </AtlasDialog>
  );
};

export default NewConfirmationModal;
