import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";

import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      inventoryList: [],
      inventoryPages: [],
      selectedInventory: {},

      showEditInventory: false,
      showCreateInventory: false,
      showDeleteInventory: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeInventoryHOC = (val, context) => this.setState({ [context]: val });

    getInventoryList = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/inventory_items?${search}page=${page}`,
        this.getInventoryListSuccess,
        this.getInventoryListError,
        this.load,
      );
    };
    getInventoryListSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        inventoryList: payload,
        inventoryPages: tempPages,
      });
    };
    getInventoryListError = (error) => requestError(error);

    getSelectedInventory = (id) =>
      Get(
        `/admin/inventory_items/${id}`,
        this.getSelectedInventorySuccess,
        this.getSelectedInventoryError,
        this.load,
      );
    getSelectedInventorySuccess = (payload) =>
      this.setState({ selectedInventory: payload });
    getSelectedInventoryError = (error) => requestError(error);

    getGroupTag = () =>
      Get(
        `/tags?type=groups`,
        this.getGroupTagSuccess,
        this.getGroupTagError,
        this.load,
      );
    getGroupTagSuccess = (payload) => this.setState({ groupTag: payload });
    getGroupTagError = (error) => requestError(error);

    createInventory = (dataToSubmit) =>
      Post(
        `/admin/inventory_items`,
        dataToSubmit,
        this.createInventorySuccess,
        this.createInventoryError,
        this.load,
      );
    createInventorySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getInventoryList(currentPage, searchParams);
      requestSuccess("Inventory create succesful");
      this.setState({ showCreateInventory: false });
    };
    createInventoryError = (error) => requestError(error);

    updateInventory = (dataToSubmit) =>
      Put(
        `/admin/inventory_items/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateInventorySuccess,
        this.updateInventoryError,
        this.load,
      );
    updateInventorySuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getInventoryList(currentPage, searchParams);
      this.setState({ showEditInventory: false });
      requestSuccess("Inventory update successful");
    };
    updateInventoryError = (error) => requestError(error);

    deleteInventory = (id) =>
      Delete(
        `/admin/inventory_items/${id}`,
        this.deleteInventorySuccess,
        this.deleteInventoryError,
        this.load,
      );
    deleteInventorySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getInventoryList(currentPage, searchParams);
      requestSuccess(payload.message);
      this.setState({ showDeleteInventory: false });
    };
    deleteInventoryError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.state}
            {...this.props}
            onLoadInventory={this.state.loading}
            getGroupTag={this.getGroupTag}
            createInventory={this.createInventory}
            updateInventory={this.updateInventory}
            deleteInventory={this.deleteInventory}
            getInventoryList={this.getInventoryList}
            getSelectedInventory={this.getSelectedInventory}
            onChangeInventoryHOC={this.onChangeInventoryHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
