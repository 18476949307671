import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CreateDialogContent from "./components/createDialog";
import EditDialogContent from "./components/editDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/delete";
import CustomDialog from "components/Dialog";

import ProductMngmtHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

import "./index.scss";

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Red" },
];

const columnData = [
  {
    header: "Product Name",
    accessor: "name",
    columnStyle: { width: "160%" },
  },
  {
    header: "Product Type",
    accessor: "type",
    columnStyle: { width: "120%" },
  },
  {
    header: "Unit Price",
    accessor: "price",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
    columnStyle: { width: "60%" },
  },
  {
    header: "Quantity Fixed",
    accessor: "fixed_quantity",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

class ProductMngmt extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getProductMngmt(1, "");
  };

  renderCardContent = () => {
    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Atlas Store Products",
      this.props.data,
    );

    return (
      <AtPaginatedTable
        rowData={this.props.products.data || []}
        meta={this.props.products.meta}
        columns={columnData}
        actionColumnContent={[
          {
            name: can_update ? "edit" : "view",
            onClick: (rowData) =>
              this.props.getSelectedProductMngmt(rowData.id),
          },
          {
            name: "delete",
            color: "#F04438",
            onShow: () => can_destroy,
            onClick: (rowData) => {
              this.props.onChangeProductMngmtHOC(rowData, "selectedProduct");
              this.props.onChangeProductMngmtHOC(
                true,
                "showDeleteConfirmation",
              );
            },
          },
        ]}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeProductMngmtHOC(val, "searchParams")
        }
        getListAPI={this.props.getProductMngmt}
        totalPages={this.props.productsPage}
      />
    );
  };

  render = () => {
    const {
      showEditDialog,
      showCreateDialog,
      onLoadProductMngmt,
      showDeleteConfirmation,
      selectedProduct,

      createProduct,
      deleteProduct,
      onChangeProductMngmtHOC,
      deleteProductSubItem,
    } = this.props;
    const { can_create } = permissionsChecker(
      "Atlas Store Products",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={`Products`}
          moduleIcon={"icon-products"}
          actionButton={[
            {
              label: "New Product",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeProductMngmtHOC(true, "showCreateDialog"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <CustomDialog open={showCreateDialog}>
          <CustomCard
            className={`h-100`}
            cardContent={
              <CreateDialogContent
                {...this.props.data}
                createProduct={createProduct}
                onLoadProductMngmt={onLoadProductMngmt}
                onClose={() =>
                  onChangeProductMngmtHOC(false, "showCreateDialog")
                }
              />
            }
          />
        </CustomDialog>
        <CustomDialog open={showEditDialog}>
          <CustomCard
            className={`h-100`}
            cardContent={
              <EditDialogContent
                {...this.props.data}
                permissionsChecker={permissionsChecker(
                  "Atlas Store Products",
                  this.props.data,
                )}
                updateProduct={this.props.updateProduct}
                onLoadProductMngmt={onLoadProductMngmt}
                selectedProduct={selectedProduct}
                deleteProductSubItem={deleteProductSubItem}
                getSelectedProductMngmt={this.props.getSelectedProductMngmt}
                onClose={() => onChangeProductMngmtHOC(false, "showEditDialog")}
              />
            }
          />
        </CustomDialog>
        <ConfirmationModal
          title={"Delete Product"}
          loading={onLoadProductMngmt}
          open={showDeleteConfirmation}
          message={`Are you sure you want to delete this product from the market hub ?`}
          positiveAction={() => deleteProduct(selectedProduct.id)}
          negativeAction={() =>
            onChangeProductMngmtHOC(false, "showDeleteConfirmation")
          }
        />
        {onLoadProductMngmt && <LoadingModal />}
      </>
    );
  };
}

export default compose(ProductMngmtHOC, VersionCheckHOC)(ProductMngmt);
