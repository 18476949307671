import { Dialog, DialogContent } from "@material-ui/core";
import { fabric } from "fabric";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FiAlertOctagon } from "react-icons/fi";
import { RiImage2Fill } from "react-icons/ri";

import AtlasCloseButton from "components/Button/close";
import CustomCard from "components/Card";
import TooltipWrapper from "components/TooltipWrapper";
import DownloadContent from "./Download";
import TextModalContent from "./textModal";

import { useFabric } from "context/fabric";

import "../index.scss";

export default function Fabric({
  tempTemplate,
  title,
  initialJSONData,
  selectedGraphic,

  onClickSubmit,
  onClickClose,
  updateDownloadCount,
  data,
}) {
  const [showDownloadModal, setDownloadModal] = useState(false);

  const { role } =
    window.location.href.indexOf("/admin-impersonate") > -1
      ? data.currentSignInProfileReducer
      : data.profileReducer;

  const isAdmin = ["Super Admin", "Admin"].indexOf(role) > -1;

  const {
    selectedTemplate,
    setSelectedTemplate,

    filePond,
    initEvent,
    tempClipID,
    canvas,
    resizeCanvas,
    addImage,

    fabricDblClick,
    ungroup,
    postUngroupHandler,
    setTextModal,
    showTextModal,
    tempEditTextObj,
  } = useFabric();

  useEffect(() => {
    tempTemplate && setSelectedTemplate(tempTemplate);

    if (tempTemplate?.type_id) {
      resizeCanvas(tempTemplate.type_id);
    }
  }, [tempTemplate]);

  useEffect(() => {
    if (!showTextModal && canvas) {
      canvas.getObjects().forEach((obj) => {
        if (obj.type === "textbox") {
          obj.exitEditing();
        }
      });
    }
  }, [showTextModal, canvas]);

  useEffect(() => {
    const handleResize = () => {
      resizeCanvas(selectedTemplate.type_id);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // Handle double click event and clippath for textbox when editing graphic
  useEffect(() => {
    if (initialJSONData) {
      const clipPath = [];
      const json = !selectedTemplate ? initialJSONData.objects : [];

      if (selectedTemplate) {
        _.map(initialJSONData.objects, (initialObj) => {
          if (initialObj.type === "group" && initialObj.shapeType === "img") {
            selectedTemplate.content.objects.map(
              (templateObj) =>
                templateObj.id === initialObj.id && json.push(initialObj)
            );
          } else {
            json.push(initialObj);
          }
        });

        // Insert new container if there's update on template
        selectedTemplate.content.objects.forEach((templateObj) => {
          initialJSONData.clipPathID.indexOf(templateObj.id) < 0 &&
            clipPath.push(templateObj);
        });
      }

      canvas &&
        canvas.loadFromJSON(
          {
            ...initialJSONData,
            objects: [...json, ...clipPath],
          },
          () => canvas.renderAll(),
          (o, obj) => {
            if (obj.shapeType === "text" || obj.type === "image") {
              if (obj.shapeType === "text") {
                if (
                  (obj.clipPath && obj.clipPath.type === "group") ||
                  !obj.clipPath
                ) {
                  const clipPathJSON = obj.clipPath ? obj.clipPath : obj;

                  const isRectShape = obj.clipPath
                    ? obj.clipPath.id.indexOf("rect") > -1
                    : obj._objects[0].type === "rect";

                  const shape = isRectShape
                    ? new fabric.Rect({
                        ...clipPathJSON,
                        type: "rect",
                        absolutePositioned: true,
                      })
                    : new fabric.Circle({
                        ...clipPathJSON,
                        type: "circle",
                        absolutePositioned: true,
                      });

                  obj.clipPath = shape;
                }

                obj.setControlsVisibility({
                  mtr: true,
                  mt: false,
                  mb: false,
                  tr: true,
                  tl: true,
                  br: true,
                  bl: true,
                  ml: false,
                  mr: false,
                });

                obj.on(
                  "mousedown",
                  fabricDblClick(obj, (obj) => {
                    postUngroupHandler(ungroup(obj), obj);
                  })
                );
              }

              if (selectedTemplate) {
                const { objects } = selectedTemplate.content;
                const match = _.find(objects, { id: obj.clipPath.id });

                obj.shapeType === "text" &&
                  obj.setControlsVisibility({ deleteControl: !match });

                if (match) {
                  obj.clipPath.set({
                    angle: match.angle,
                    left:
                      match.id.indexOf("circle") > -1
                        ? match.left + match.objects[0].radius * match.scaleX
                        : match.left,
                    top:
                      match.id.indexOf("circle") > -1
                        ? match.top + match.objects[0].radius * match.scaleY
                        : match.top,
                    scaleX: match.scaleX,
                    scaleY: match.scaleY,
                  });
                }
              }

              if (isAdmin) {
                obj.selectable = false;
                obj.evented = false;
              }

              canvas.renderAll();
            }
          }
        );
    }
  }, [canvas, initialJSONData, selectedTemplate]);

  useEffect(() => {
    canvas && initEvent(canvas);
  }, [canvas]);

  const onChangeFilePond = (fileItems) => {
    if (fileItems) {
      const clip = _.find(
        selectedTemplate.content?.objects,
        (clip) => clip.id === tempClipID
      );

      Object.keys(fileItems).forEach((key) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          addImage(e.target.result, clip);
          filePond.current.value = "";
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  return (
    <>
      <input
        multiple={false}
        type={"file"}
        className={"d-none"}
        ref={filePond}
        onChange={(e) => onChangeFilePond(e.target.files)}
        accept={"image/png, image/jpg, image/jpeg"}
      />
      <p className={"mb-3"} style={{ fontSize: 13 }}>
        <FiAlertOctagon
          style={{ width: 20, height: 20 }}
          className={"text-warning mr-2"}
        />{" "}
        Click update before downloading.
      </p>
      {selectedTemplate && selectedTemplate.sample_url && (
        <TooltipWrapper
          title={
            <img
              style={{ height: 300 }}
              alt={selectedTemplate.title}
              src={selectedTemplate.sample_url}
            />
          }
          child={
            <div
              className={"p-2 bg-warning rounded text-center"}
              style={{
                marginLeft: "auto",
                width: "fit-content",
                marginRight: 30,
                marginBottom: -30,
              }}
            >
              <RiImage2Fill
                style={{
                  width: 40,
                  height: 40,
                  color: "white",
                  cursor: "pointer",
                }}
              />
              <p className={"color-white mt-1"}>Sample</p>
            </div>
          }
        />
      )}
      <div
        id={"canvas-editor"}
        className={"d-flex flex-column align-items-center p-3"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", height: 800 }}
      >
        <div
          className={"fabric-canvas-wrapper"}
          style={{ transform: "scale( 0.4 )", transformOrigin: "top center" }}
        >
          <canvas id={"canvas"}></canvas>
        </div>
      </div>
      {!isAdmin && (
        <div className="at-modal_dialog-container-footer g-3">
          <button
            disabled={!title}
            onClick={() => {
              onClickSubmit({
                title: title,
                graphic_template_id: selectedTemplate.id,
                content: {
                  ...canvas.toJSON([
                    "id",
                    "shapeType",
                    "shapeLabel",
                    "selectable",
                    "hoverCursor",
                    "_controlsVisibility",
                  ]),
                },
                thumbnail: canvas.toDataURL({ format: "png", quality: 1 }),
              });
            }}
            className={"btn-new btn-new--success"}
          >
            Update
          </button>
          <button
            className={"btn-new btn-new--secondary"}
            style={{ fontWeight: 400, fontSize: 14 }}
            onClick={() => {
              updateDownloadCount(selectedGraphic.id);
              window.open(selectedGraphic.thumbnail_url, "_blank");
            }}
          >
            Download
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClickClose()}
          >
            Close
          </button>
        </div>
      )}
      <Dialog maxWidth={"md"} open={showTextModal}>
        <CustomCard
          cardContent={
            <TextModalContent
              tempEditTextObj={tempEditTextObj}
              onClickAdd={(val) => {
                setTextModal(false);

                const objects = canvas.getObjects();
                const shapeIndex = _.findIndex(
                  objects,
                  (obj) => obj.type === "circle" || obj.type === "rect"
                );

                objects.forEach((obj) => {
                  if (obj.type === "textbox") {
                    obj.set({
                      fontWeight: val.fontWeight,
                      text: val.text,
                      fontStyle: val.fontStyle,
                      fill: val.fill,
                      textAlign: val.textAlign,
                      underline: val.underline,
                      width:
                        objects[shapeIndex].width * objects[shapeIndex].scaleX,
                      height:
                        objects[shapeIndex].height * objects[shapeIndex].scaleY,
                    });

                    canvas.renderAll();
                  }
                });
              }}
              onClickClose={() => setTextModal(false)}
            />
          }
        />
      </Dialog>
      <Dialog maxWidth={"sm"} open={showDownloadModal}>
        <DialogContent>
          <div className={"d-flex"}>
            <AtlasCloseButton
              containerStyle={{
                display: "flex",
                marginLeft: "auto",
                marginBottom: 20,
              }}
              onClick={() => setDownloadModal(false)}
            />
          </div>
          <DownloadContent selectedGraphic={selectedGraphic} />
        </DialogContent>
      </Dialog>
    </>
  );
}
