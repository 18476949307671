import Moment from "moment";
import { STORE_TEAM_SALES_DATE } from "actions/type";

let initialState = {
  startDate: Moment()
    .year(new Date().getFullYear())
    .month(0)
    .date(1)
    .format("YYYY-MM-DD"),
  endDate: Moment(new Date()).format("YYYY-MM-DD"),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_TEAM_SALES_DATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
