import { PiBathtubFill, PiBedFill } from 'react-icons/pi';
import { RiRuler2Line } from 'react-icons/ri';

const BlackBoxTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    marginRight: 4
  };

  return (
    <div
      style={{
        backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`,
        padding: 8,
        color: '#fff',
        minWidth: '28.5%',
        width: 'max-content'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <div
          style={{
            width: 4,
            height: 18,
            backgroundColor: '#ff6341',
            marginRight: 6
          }}
        />
        <p style={{ fontSize: 14, fontWeight: 700, color: '#ff6341' }}>
          {unitType}
        </p>
      </div>
      <div style={{ display: 'flex', gap: 8 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <p style={detailStyle}>
            <RiRuler2Line style={iconStyle} />
            <span>
              {property.min_built_up?.toLocaleString()} -{' '}
              {property.max_built_up?.toLocaleString()} Sqft
            </span>
          </p>
          <p style={detailStyle}>
            <PiBedFill style={iconStyle} />
            <span>{property.bedroom_display || 0} bedrooms</span>
          </p>
          <p style={detailStyle}>
            <PiBathtubFill style={iconStyle} />
            <span>{property.bathroom_display || 0} bathrooms</span>
          </p>
        </div>
        <div style={{ width: 1, height: 'auto', backgroundColor: '#ff6341' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {property.project_features?.slice(0, 3)?.map(feature => (
            <p key={feature.name} style={detailStyle}>
              {feature.name?.trim()}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlackBoxTemplate;
