import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import _ from "lodash";

import "react-bootstrap-typeahead/css/Typeahead.css";

class CustomTypeahead extends Component {
  _renderMenuItemChildren = (option) => {
    return [
      <div key="company-address">
        <p>{option.name}</p>
      </div>,
    ];
  };

  render() {
    const { options, onSelect, onSearch, multiple, selectedValue, filterBy, className } =
      this.props;
    return (
      <div className="at-form-input">
        <Typeahead
          className={`${className || ""} w-100`}
          inputProps={{
            style: { border: 0 },
            className: "at-form-input__input",
          }}
          labelKey={"name"}
          id={"project_type"}
          selected={selectedValue}
          multiple={multiple}
          options={options}
          onChange={onSelect}
          onInputChange={onSearch}
          renderMenuItemChildren={this._renderMenuItemChildren}
          filterBy={filterBy}
        />
      </div>
    );
  }
}

export default CustomTypeahead;
