import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";

import CustomCard from "components/Card";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";
import CustomDatepicker from "components/Input/datetimepicker";

const durationOptions = [
  { label: "Mins", value: 30 },
  { label: "Mins", value: 60 },
  { label: "Mins", value: 90 },
  { label: "Mins Max", value: 120 },
];

class Edit extends Component {
  state = {
    calendarDate: "",
  };

  componentDidMount = () => {
    let selectedDate = Moment(this.props.selectedBooking.start_time);
    this.props.getCalendarEvent(
      this.props.selectedBooking.meeting_room_id,
      selectedDate.format("YYYY-MM-DD"),
    );
    this.setState({ calendarDate: selectedDate });
  };

  componentDidUpdate = (pp) => {
    const { event_subject, start_time, end_time } = this.props.selectedBooking;
    if (event_subject && start_time && end_time) {
      this.props.onChangeMyBooking(false, "disableUpdate");
    } else {
      this.props.onChangeMyBooking(true, "disableUpdate");
    }
  };

  handleNavigate = (newDate) => {
    this.setState({ calendarDate: newDate });
  };

  renderCardContent = () => {
    const {
      calendarEvent,
      selectedBooking,
      disableUpdate,
      updateMyBooking,
      getCalendarEvent,
      onChangeMyBooking,
    } = this.props;

    return (
      <ModalDialog
        title={"Book Meeting Room "}
        fullWidth={true}
        onClose={() => onChangeMyBooking(false, "showEditMyBookingModal")}
        children={
          <>
            <div className="row px-3">
              <div className="col-md-6 pr-2">
                <div className="at-card-booking-form_room_name">
                  {" "}
                  {selectedBooking.meeting_room_name}{" "}
                </div>
                <div className="at-card-booking-form_room_detail">
                  <div className="at-card--meeting__detail">
                    <AtlasIcon
                      svgHref={"atlas-location"}
                      style={{ marginRight: 2 }}
                    />
                    {selectedBooking.meeting_room_location}
                  </div>
                  <div className="at-card--meeting__detail">
                    <AtlasIcon
                      svgHref={"atlas-user"}
                      style={{ marginRight: 2 }}
                    />
                    {selectedBooking.meeting_room_capacity} seats
                  </div>
                </div>
                <form
                  className="grid-control"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <section key="edit-booking-name" className="grid-full-col">
                    <h2 className="at-form-input__title" required>
                      Event Subject
                    </h2>
                    <CustomFormInput
                      type="text"
                      className={"mt-2"}
                      value={selectedBooking.event_subject}
                      onChangeValue={(value) => {
                        let tmp = _.cloneDeep(selectedBooking);
                        tmp["event_subject"] = value;
                        onChangeMyBooking(tmp, "selectedBooking");
                      }}
                      placeholder="Event Subject"
                    />
                  </section>
                  <section
                    key="edit-booking-start-time"
                    className="grid-half-col"
                  >
                    <h2 className="at-form-input__title" required>
                      Start Date Time
                    </h2>
                    <div className="at-form-input">
                      <CustomDatepicker
                        id={"edit_start_date_time"}
                        type="datetime-local"
                        value={this.state.calendarDate}
                        minDateTime={
                          this.state.calendarDate
                            ? Moment(this.state.calendarDate).set({
                                hour: 9,
                                minute: 0,
                                second: 0,
                              })
                            : Moment()
                        }
                        maxDateTime={Moment(this.state.calendarDate).set({
                          hour: 20,
                          minute: 30,
                          second: 0,
                        })}
                        minutesStep={30}
                        onChange={(val) => {
                          let temp = _.cloneDeep(this.props.selectedBooking);
                          temp["end_time"] = "";
                          temp["duration"] = 0;
                          if (Moment(val).isValid()) {
                            temp["start_time"] = val;
                            getCalendarEvent(
                              selectedBooking.meeting_room_id,
                              Moment(val).format("YYYY-MM-DD"),
                            );
                            this.setState({ calendarDate: val });
                          } else {
                            temp["start_time"] = "";
                            this.setState({ calendarDate: "" });
                          }
                          onChangeMyBooking(temp, "selectedBooking");
                          onChangeMyBooking(true, "disableUpdate");
                        }}
                      />
                    </div>
                  </section>
                  <section
                    key="edit-booking-end-time"
                    className="grid-half-col"
                  >
                    <h2 className="at-form-input__title">End Date Time</h2>
                    <div
                      className="at-form-input"
                      style={{ background: "#F3F4F6" }}
                    >
                      <CustomDatepicker
                        id={"end_date_time"}
                        type="datetime-local"
                        textFieldClass={
                          "at-custom-datetimepicker-root at-form-input disabled"
                        }
                        value={selectedBooking.end_time}
                        disabled={true}
                      />
                    </div>
                  </section>
                  <section
                    key="edit-booking-duration"
                    className="grid-full-col"
                  >
                    <h2 className="at-form-input__title" required>
                      Meeting Duration
                    </h2>
                    <div className={"d-flex flex-wrap g-2"}>
                      {durationOptions.map((option, optionIndex) => {
                        let withinDuration = true;
                        if (this.state.calendarDate !== "") {
                          let meetingEndTime = Moment(
                            this.state.calendarDate,
                          ).set({ hour: 21, minute: 0, second: 0 });
                          if (
                            Moment(this.state.calendarDate)
                              .add(option.value, "minutes")
                              .isSameOrBefore(meetingEndTime)
                          ) {
                            withinDuration = false;
                          }
                        }
                        return (
                          <button
                            key={`paginated_table-radio_option-${optionIndex}`}
                            className={`btn-radio ${selectedBooking.duration === option.value ? "btn-radio-selected" : "btn-radio-outline"}`}
                            style={{
                              textAlign: "center",
                              padding: "8px 12px",
                              width: 98,
                              height: 68,
                            }}
                            disabled={withinDuration}
                            onClick={() => {
                              let tmpEndTime = Moment(
                                selectedBooking.start_time,
                              ).add(option.value, "minutes");
                              let temp = _.cloneDeep(
                                this.props.selectedBooking,
                              );
                              temp["end_time"] = tmpEndTime;
                              temp["duration"] = option.value;
                              onChangeMyBooking(temp, "selectedBooking");
                            }}
                          >
                            <div>
                              <div className="at-card-booking-form_duration_value">
                                {option.value}
                              </div>
                              <div className="at-card-booking-form_duration_label">
                                {option.label}
                              </div>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </section>
                </form>
              </div>
              <div className="col-md-6 pr-3">
                <div className="at-calendar-cont">
                  <div className="at-calendar-header_date">
                    {this.state.calendarDate
                      ? Moment(this.state.calendarDate).format("DD MMM YYYY")
                      : Moment().format("DD MMM YYYY")}
                  </div>
                  <Calendar
                    localizer={momentLocalizer(Moment)}
                    defaultView="day"
                    toolbar={false}
                    date={
                      this.state.calendarDate
                        ? Moment(this.state.calendarDate)
                        : Moment()
                    }
                    onNavigate={this.handleNavigate}
                    min={
                      this.state.calendarDate
                        ? Moment(this.state.calendarDate.toDate()).set({
                            hour: 8,
                            minute: 0,
                            second: 0,
                          })
                        : Moment().set({ hour: 8, minute: 0, second: 0 })
                    }
                    max={
                      this.state.calendarDate
                        ? Moment(this.state.calendarDate.toDate()).set({
                            hour: 22,
                            minute: 0,
                            second: 0,
                          })
                        : Moment().set({ hour: 22, minute: 0, second: 0 })
                    }
                    events={this.state.calendarDate ? calendarEvent : []}
                    startAccessor="start"
                    endAccessor="end"
                  />
                </div>
              </div>
            </div>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              type={"button"}
              className="btn-new btn-new--success"
              disabled={disableUpdate}
              onClick={() => updateMyBooking(selectedBooking)}
            >
              Update
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangeMyBooking(false, "showEditMyBookingModal")}
            >
              Close
            </button>
          </div>
        }
      />
    );
  };

  render = () => {
    return (
      <>
        <CustomCard cardContent={this.renderCardContent()} />
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(Edit);
