import React, { useState, useEffect, useMemo } from "react";
import ReactHTMLParser from "react-html-parser";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import PhotoSlider from "./PhotoSlider";
import defaultImage from "assets/images/iqi_logo_only.png";

import { getColorBadge } from "dictionary/badgeColor";
import { numberWithCommas } from "utils/thousandSeparator";
import MediaHOC from "../../../actions/media";

import { featureProperty, detailsSection, publishPlatformLib } from "./assets";

const cobrokeSetting = [
  {
    label: "Commission",
    value: "total_portion",
    icon: "atlas-percentage-circle",
    colorName: "Teal",
  },
  {
    label: "My Portion ",
    value: "my_portion",
    icon: "atlas-profile-circle",
    colorName: "Blue",
  },
  {
    label: "Co-broke Agent Portion",
    value: "balance",
    icon: "atlas-shake-hand",
    colorName: "Fuchsia",
  },
];

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const View = ({
  mediaLink = [],
  floorPlanListing,
  descriptionTab,

  onLoadMediaLink,
  onChangeListingHOC,
  getMediaLinks,
  selectedListing,
  onClose,
}) => {
  const {
    code,
    status,
    agent_info,
    status_id,
    asking_price_cents,
    asking_price_currency,
    id,
    address,
    property_name,
    images,
    description,
    zh_description,
    facilities = [],
    other_facilities,
    built_up,
    land_area,
    measurement,
    featureArray,
    published_to_iqi,
    exported_iqi_link,
    published_to_juwai,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    exported_juwai_asia_link,
    co_broke_settings,
  } = selectedListing;

  useEffect(() => {
    getMediaLinks(id);
  }, []);

  const [customStyle, setCustomStyle] = useState({
    gridTemplateAreas: `
      "header"
      "gallery"
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
  `,
  });

  useEffect(() => {
    let tmpStyle = `
      "header"
      "gallery"
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
    `;
    if (!published_to_iqi || !exported_iqi_link) {
      if (
        !published_to_juwai ||
        (!exported_to_juwai && !juwai_link && !exported_juwai_asia_link)
      ) {
        tmpStyle = tmpStyle.replace(`"subsalesPublishedPlatforms"`, "");
      }
    }

    if (!_.some(facilities, { value: true })) {
      tmpStyle = tmpStyle.replace(`"facilities"`, "");
    }

    if (!_.some(featureArray, { value: true })) {
      tmpStyle = tmpStyle.replace(`"subsalesFeatures"`, "");
    }

    if (!_.some(cobrokeSetting, { value: true })) {
      tmpStyle = tmpStyle.replace(`"cobrokeSettings"`, "");
    }

    setCustomStyle(`grid-template-areas: ${tmpStyle}`);
  }, [selectedListing]);

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ title, logoSrc, linkSrc }) => {
      return (
        <a
          href={linkSrc}
          target="_blank"
          className="at-subsales_listing_view-published_platforms-item"
        >
          <div className="d-flex align-items-center">
            <img src={logoSrc} />
            <span>{title}</span>
          </div>
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
        </a>
      );
    };

    return (
      ((published_to_iqi && exported_iqi_link) ||
        (published_to_juwai &&
          exported_to_juwai &&
          (juwai_link || exported_juwai_asia_link)) ||
        (exported_to_edgeprop && published_to_edge_prop)) && (
        <div className="at-subsales_listing__view-published_platforms">
          <div className="at-subsales-listing__view-title">
            <AtlasIcon
              svgHref={"atlas-global"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            Published Platforms
          </div>
          {publishPlatformLib.map((item, index) => {
            let tmpCheckingLink =
              selectedListing[item.linkSrc] &&
              selectedListing[item.linkSrc].length > 0 &&
              selectedListing[item.published] &&
              (item.exported ? selectedListing[item.exported] : true);
            return (
              tmpCheckingLink && (
                <LogoPlatform
                  {...item}
                  key={index}
                  linkSrc={selectedListing[item.linkSrc]}
                />
              )
            );
          })}
        </div>
      )
    );
  };

  const renderStyling = () => {
    return (
      <style>
        {`.at-subsales_listing__view {
              ${customStyle}
            }

            @media (min-width: 992px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  "left right" !important;
              }
            }
          
            @media (min-width: 1200px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  "left left right"
                  "listingTeam listingTeam" !important;
              }
            }
          `}
      </style>
    );
  };

  return (
    <ModalDialog
      title={"Listing Details"}
      onLoad={onLoadMediaLink}
      onClose={onClose}
      fullWidth={true}
      fullHeight={true}
      children={
        <div className="at-subsales_listing__view">
          <div className="at-subsales_listing__view-header">
            <div className="at-subsales_listing__view-header-topleft">
              <div className="at-subsales_listing__view-header-topleft_content">
                <h6 className="at-subsales__code mb-3">{code}</h6>
                <p className="at-subsales_listing__view-address_title ml-2">
                  {property_name}
                </p>
                {address && (
                  <div className="d-flex align-items-center ml-2">
                    <AtlasIcon
                      svgHref={"atlas-location"}
                      style={{
                        minWidth: 14,
                        width: 14,
                        height: 14,
                        fill: "#F0631D",
                        marginRight: 10,
                      }}
                    />
                    <h6 className="at-subsales_listing__view-address_subtitle">{`${address}`}</h6>
                  </div>
                )}
                <p className="at-subsales__price ml-2">
                  <span className="mr-2" style={{ color: "#111827" }}>
                    {asking_price_currency}
                  </span>
                  {asking_price_cents && asking_price_cents > 0
                    ? numberWithCommas(asking_price_cents)
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="at-subsales_listing__view-header-right">
              <div className="at-subsales_listing__feature_property">
                {featureProperty.map((item, index) => (
                  <div
                    key={index}
                    className="at-subsales_listing__feature-item"
                  >
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ fill: "#F0631D" }}
                    />
                    <div>
                      <div className="at-subsales_listing_title">
                        {item.label}
                      </div>
                      <div className="at-subsales_listing_content">
                        {selectedListing[item.value] || "-"}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="at-subsales_listing__feature-item">
                  <AtlasIcon
                    svgHref={"atlas-layer"}
                    style={{ fill: "#F0631D" }}
                  />
                  <div>
                    <div className="at-subsales_listing_title">Built Up</div>
                    <div className="at-subsales_listing_content">{`${numberWithCommas(built_up)} ${measurement}`}</div>
                  </div>
                </div>
                <div className="at-subsales_listing__feature-item">
                  <AtlasIcon
                    svgHref={"atlas-miximize-3-linear"}
                    style={{ fill: "#F0631D" }}
                  />
                  <div>
                    <div className="at-subsales_listing_title">Land Area</div>
                    <div className="at-subsales_listing_content">{`${numberWithCommas(land_area)} ${measurement}`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="at-subsales_listing__view-left_column">
            <div className="at-subsales_listing__view-photo_slider_cont">
              <PhotoSlider
                id={id}
                images={images && images.length > 0 ? images : defaultImage}
                floorPlanListing={floorPlanListing}
              />
            </div>
            <div className="at-subsales_listing__view-description">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-send-2"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Description
              </div>
              <div className="at-subsales_listing__view-description_tab">
                <button
                  className={
                    descriptionTab === "Eng"
                      ? "at-subsales_listing_view-selected_description"
                      : ""
                  }
                  onClick={() => onChangeListingHOC("Eng", "descriptionTab")}
                >
                  English
                </button>
                <button
                  className={
                    descriptionTab === "Zh"
                      ? "at-subsales_listing_view-selected_description"
                      : ""
                  }
                  onClick={() => onChangeListingHOC("Zh", "descriptionTab")}
                >
                  Chinese
                </button>
              </div>
              <div className="at-subsales_listing__view-description-content">
                {descriptionTab === "Eng" && (
                  <div
                    className={`at-subsales_listing__view-description_eng at-subsales_listing_view-selected_description`}
                  >
                    {description ? ReactHTMLParser(description) : "N/A"}
                  </div>
                )}
                {descriptionTab === "Zh" && (
                  <div
                    className={`at-subsales_listing__view-description_zh at-subsales_listing_view-selected_description`}
                  >
                    {zh_description ? ReactHTMLParser(zh_description) : "N/A"}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="at-subsales_listing__view-right_column">
            <div className="at-subsales_listing__view-details">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-note-text"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Details
              </div>
              {detailsSection.map((item, index) => {
                const { colorName = "" } =
                  _.find(StatusColor, { value: status }) || {};

                return (
                  <div
                    key={index}
                    className="at-subsales-listing__view-details-item"
                  >
                    <label>
                      <AtlasIcon svgHref={item.icon} />
                      {item.label}
                    </label>
                    {item.value === "status" && (
                      <div
                        className="at-status_badge mt-1"
                        style={{ ...getColorBadge(colorName) }}
                      >
                        {status}
                      </div>
                    )}
                    {item.value !== "status" &&
                      (selectedListing[item.value] || "N/A")}
                  </div>
                );
              })}
            </div>
            {featureArray && _.some(featureArray, { value: true }) && (
              <div className="at-subsales_listing__view-features">
                <div className="at-subsales-listing__view-title">
                  <AtlasIcon
                    svgHref={"atlas-building-3"}
                    style={{ fill: "#F0631D", marginRight: 10 }}
                  />
                  Features
                </div>
                {featureArray.map((item, index) => (
                  <div
                    key={index}
                    className="at-subsales-listing__view-details-item"
                  >
                    <label>
                      <AtlasIcon svgHref={item.icon} />
                      {item.label}
                    </label>
                    {item.value}
                  </div>
                ))}
              </div>
            )}
            {facilities && _.some(facilities, { value: true }) && (
              <div className="at-subsales_listing__view-facilities">
                <div className="at-subsales-listing__view-title">
                  <AtlasIcon
                    svgHref={"atlas-building-4"}
                    style={{ fill: "#F0631D", marginRight: 10 }}
                  />
                  Facilities
                </div>
                <ul>
                  {facilities.map(
                    (item, index) =>
                      item.value && <li key={index}>{item.label}</li>,
                  )}
                </ul>
                {other_facilities && (
                  <div className="at-subsales_listing__view-">
                    <div className="at-subsales-listing__view-title">
                      <AtlasIcon
                        svgHref={"atlas-note-text"}
                        style={{ fill: "#F0631D", marginRight: 10 }}
                      />
                      Other Facilities
                    </div>
                    <p styl={{ marginTop: 8 }}>{other_facilities}</p>
                  </div>
                )}
              </div>
            )}
            {mediaLink.length > 0 && (
              <div className="at-subsales_listing__view-media-link">
                <div className="at-subsales-listing__view-title">
                  <AtlasIcon
                    svgHref={"atlas-video-play"}
                    style={{ fill: "#F0631D", marginRight: 10 }}
                  />
                  Media Link
                </div>
                {mediaLink.map((item, index) => (
                  <a key={index} href={item.url} target="_blank">
                    <span className="mr-1">{item.title}</span>
                    <AtlasIcon svgHref={"atlas-link-2-linear"} />
                  </a>
                ))}
              </div>
            )}
            {status_id === 1 && renderPublishingPlatforms()}
            {
              <div className="at-subsales-listing__view-cobroke-settings">
                <div className="at-subsales-listing__view-title">
                  <AtlasIcon
                    svgHref={"atlas-shake-hand"}
                    style={{ fill: "#F0631D", marginRight: 10 }}
                  />
                  Cobroke Settings
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mt-2"
                  style={{ height: 180 }}
                >
                  {cobrokeSetting.map((item, index) => (
                    <div
                      key={index}
                      className="at-subsales-listing__view-cobroke-settings-item"
                    >
                      <AtlasIcon
                        svgHref={item.icon}
                        style={{ ...getColorBadge(item.colorName) }}
                      />
                      <label>{item.label}</label>
                      {co_broke_settings && co_broke_settings[item.value]
                        ? `${co_broke_settings[item.value]} ${co_broke_settings.co_broke_portion_by === "amount" ? asking_price_currency : "%"}`
                        : "N/A"}
                    </div>
                  ))}
                </div>
              </div>
            }
            {(agent_info.avatar_url ||
              agent_info.full_name ||
              agent_info.email) && (
              <div className="at-form__content">
                <div className="at-subsales-listing__view-title">
                  <AtlasIcon
                    svgHref={"atlas-profile-circle"}
                    style={{ fill: "#F0631D", marginRight: 10 }}
                  />
                  Listing Agent
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src={agent_info.avatar_url}
                    style={{ width: 56, height: 56, borderRadius: "50%" }}
                  />
                  <div className="ml-3">
                    <div className="fw-600" style={{ fontSize: 16 }}>
                      {agent_info.full_name}
                    </div>
                    <div className="fs-2">
                      <AtlasIcon
                        svgHref={"atlas-profile2user"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#F0631D",
                          marginRight: 10,
                        }}
                      />
                      {agent_info.team_name}
                    </div>
                    <div className="fs-2">
                      <AtlasIcon
                        svgHref={"atlas-sms"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#F0631D",
                          marginRight: 10,
                        }}
                      />
                      {agent_info.email}
                    </div>
                    <div className="fs-2">
                      <AtlasIcon
                        svgHref={"atlas-call"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#F0631D",
                          marginRight: 10,
                        }}
                      />
                      {agent_info.mobile_contact_number}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary w-100"}
                    containerStyle={{ width: "32%" }}
                    onClick={() => window.open(`mailto:${agent_info.email}`)}
                    children={
                      <>
                        <AtlasIcon
                          svgHref={"atlas-sms-edit"}
                          style={{
                            width: 16,
                            height: 16,
                            fill: "#3B82F6",
                            marginRight: 10,
                          }}
                        />
                        Email
                      </>
                    }
                  />
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary w-100"}
                    containerStyle={{ width: "32%" }}
                    onClick={() =>
                      window.open(
                        `https://wa.me/${agent_info.mobile_contact_number}`,
                        "_blank",
                      )
                    }
                    children={
                      <>
                        <AtlasIcon
                          svgHref={"atlas-whatsapp"}
                          style={{
                            width: 16,
                            height: 16,
                            fill: "#12B76A",
                            marginRight: 10,
                          }}
                        />
                        Message
                      </>
                    }
                  />
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary w-100"}
                    containerStyle={{ width: "32%" }}
                    onClick={() =>
                      window.open(`tel:${agent_info.mobile_contact_number}`)
                    }
                    children={
                      <>
                        <AtlasIcon
                          svgHref={"atlas-call-calling"}
                          style={{
                            width: 16,
                            height: 16,
                            fill: "#3B82F6",
                            marginRight: 10,
                          }}
                        />
                        Call
                      </>
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default MediaHOC(View);
