import React from "react";
import { FormHelperText } from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import PaymentForm from "containers/iPay88";
import CustomButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";

import iPayLogo from "assets/ipay88/ipay88.png";

const PaymentModal = ({
  data,
  selectedRoom,
  paymentDetails,

  cancelPayment,
}) => {
  const { payment_desc, payment_id, amount } = paymentDetails;
  const countDown = 600;
  const tmpRemarks = `Booking Payment for Business Lounge - ${selectedRoom.title}`;
  const userInfo = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const formatRemainingTime = (time) => {
    const minutes =
      Math.floor((time / 60) % 60) < 10
        ? `0${Math.floor((time / 60) % 60)}`
        : Math.floor((time / 60) % 60);
    const seconds =
      Math.floor(time % 60) < 10
        ? `0${Math.floor(time % 60)}`
        : Math.floor(time % 60);

    return `${minutes}:${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <p style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}>
          Time Out
        </p>
      );
    }

    return (
      <p style={{ fontSize: 14, fontWeight: 500 }}>
        {formatRemainingTime(remainingTime)}
      </p>
    );
  };

  return (
    <ModalDialog
      title={"Payment"}
      fullWidth={true}
      fullHeight={true}
      children={
        <>
          <div
            className="position-absolute bg-white"
            style={{ top: 10, right: 10 }}
          >
            <CountdownCircleTimer
              isPlaying
              size={window.innerWidth < 767 ? 60 : 80}
              strokeWidth={window.innerWidth < 767 ? 4 : 6}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              isLinearGradient={true}
              duration={countDown}
              initialRemainingTime={countDown}
              colorsTime={[countDown / 10, countDown / 5, countDown / 3, 0]}
              onComplete={() => cancelPayment()}
              trailColor={[["#dbdbdb"]]}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="payment-container mx-4 mt-1">
              <img
                src={iPayLogo}
                className="ipay-logo"
                style={{ width: "20%", minWidth: 100 }}
              />
              <div className="ipay-frame d-flex flex-wrap justify-content-center">
                <FormHelperText className="my-4 w-100 text-center">
                  Choose a payment method. You will then be redirected to a secure page to complete the payment.
                </FormHelperText>
                <PaymentForm
                  payment_id={2}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={16}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={210}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={523}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={538}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={542}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
                <PaymentForm
                  payment_id={801}
                  productDesc={payment_desc}
                  userInfo={userInfo}
                  refNo={`${payment_id}`}
                  amount={amount.toString()}
                  amountSHA256={amount.toString().replace(".", "")}
                  remark={tmpRemarks}
                />
              </div>
            </div>
            <CustomButton
              className="btn-new btn-new--danger mt-4"
              onClick={() => cancelPayment()}
              children={"Cancel Payment"}
            />
          </div>
        </>
      }
    />
  );
};

export default PaymentModal;