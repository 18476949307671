import React, { Component } from "react";
import { compose } from "redux";
import { BiEdit } from "react-icons/bi";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import UpdateExamContent from "./components/updateExamContent";
import EditExamContent from "./components/editExamContent";

import ExamHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Pass", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Fail", colorName: "Red" },
];

const columnData = [
  {
    header: "Exam Date",
    accessor: "start_date",
  },
  {
    header: "Quiz ID",
    accessor: "quiz_id",
  },
  {
    header: "Agent ",
    renderColumn: (rowData) => {
      const { agent } = rowData;
      return (
        <>
          <p>{(agent && agent.full_name) || "N/A"}</p>
          <p>{(agent && agent.email) || "N/A"}</p>
          <p>{(agent && agent.mobile_number) || "N/A"}</p>
          <p>{(agent && agent.branch) || "N/A"}</p>
        </>
      );
    },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

class ExamMngmt extends Component {
  componentDidMount = () => this.props.getExams(1, "");

  renderCardContent = () => {
    const {
      data,
      exams,
      getExams,
      examPages,
      selectedExam,
      searchParams,
      showBulkUpdate,
      showUpdateModal,
      getSelectedExam,
      onChangeExamHOC,
    } = this.props;

    const { can_update } = permissionsChecker("Exam Results", data);

    return (
      <>
        <AtPaginatedTable
          columns={columnData}
          rowData={exams.data || []}
          meta={exams.meta || {}}
          actionColumnContent={
            can_update
              ? [
                  {
                    name: "edit",
                    disabled: () => {},
                    onClick: (val) => getSelectedExam(val.id),
                  },
                ]
              : []
          }
          onChangeSearchParams={(val) => onChangeExamHOC(val, "searchParams")}
          searchParams={searchParams}
          getListAPI={getExams}
          totalPages={examPages}
        />
        {/* Modal for bulk update */}
        <AtlasDialog open={showBulkUpdate}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <UpdateExamContent
                getExams={getExams}
                onCloseModal={() =>
                  this.props.onChangeExamHOC(false, "showBulkUpdate")
                }
              />
            }
          />
        </AtlasDialog>
        {/* Modal for edit action */}
        <AtlasDialog open={showUpdateModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <EditExamContent
                selectedExam={selectedExam}
                getExams={getExams}
                getSelectedExam={getSelectedExam}
                onCloseModal={() =>
                  this.props.onChangeExamHOC(false, "showUpdateModal")
                }
              />
            }
          />
        </AtlasDialog>
      </>
    );
  };

  render = () => {
    const { can_update } = permissionsChecker("Exam Results", this.props.data);

    return (
      <>
        <ModuleHeader
          title={"Exam Results"}
          moduleIcon={"icon-exam"}
          actionButton={[
            {
              label: "Update Exam Result",
              onShow: can_update,
              className: "btn-new btn-new--primary",
              icon: (
                <BiEdit style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () => this.props.onChangeExamHOC(true, "showBulkUpdate"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadExam && <LoadingModal />}
      </>
    );
  };
}

export default compose(ExamHOC, VersionCheckHOC)(ExamMngmt);
