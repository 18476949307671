import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      currentQuiz: {
        settings: {
          start_date: null,
          end_date: null,
        },
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeExamIntegrationHOC = (val, context) =>
      this.setState({ [context]: val });

    getCurrentQuiz = () =>
      Get(
        `/admin/exams/current_quiz`,
        this.getCurrentQuizSuccess,
        this.getCurrentQuizError,
        this.load,
      );
    getCurrentQuizSuccess = (payload) =>
      this.setState({
        currentQuiz: {
          ...payload,
          settings: {
            ...payload.settings,
            start_date: Moment(payload.settings.start_date).format(
              "YYYY-MM-DDTHH:mm",
            ),
            end_date: Moment(payload.settings.end_date).format(
              "YYYY-MM-DDTHH:mm",
            ),
          },
        },
      });
    getCurrentQuizError = (error) => requestError(error);

    updateCurrentQuiz = (dataToSubmit) =>
      Put(
        `/entity_settings/${dataToSubmit.id}`,
        {
          settings: {
            ...dataToSubmit.settings,
            end_date: Moment(dataToSubmit.settings.end_date).format(
              "YYYY-MM-DDTHH:mm:00Z",
            ),
            start_date: Moment(dataToSubmit.settings.start_date).format(
              "YYYY-MM-DDTHH:mm:00Z",
            ),
          },
        },
        this.updateCurrentQuizSuccess,
        this.updateCurrentQuizError,
        this.load,
      );
    updateCurrentQuizSuccess = (payload) => {
      this.setState({
        currentQuiz: {
          ...payload,
          settings: {
            ...payload.settings,
            start_date: Moment(payload.settings.start_date).format(
              "YYYY-MM-DDTHH:mm",
            ),
            end_date: Moment(payload.settings.end_date).format(
              "YYYY-MM-DDTHH:mm",
            ),
          },
        },
      });
      requestSuccess("Update Success");
    };
    updateCurrentQuizError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadExamIntegration={this.state.loading}
            getCurrentQuiz={this.getCurrentQuiz}
            updateCurrentQuiz={this.updateCurrentQuiz}
            onChangeExamIntegrationHOC={this.onChangeExamIntegrationHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
