import React, { useState } from "react";

import CustomButton from "components/Button";
import CustomTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";

const CancellationModal = ({
  title,
  message,

  onClose,
  onSubmit,
}) => {
  const [rejectReason, onChangeRejectReason] = useState("");

  return (
    <ModalDialog
      title={title}
      onClose={onClose}
      children={
        <>
          <p>{message}</p>
          <CustomTextarea
            className={"mt-10"}
            placeholder={"Enter Reason"}
            value={rejectReason}
            onChangeValue={(value) => onChangeRejectReason(value)}
            style={{ width: "100%", display: "block" }}
          />
        </>
      }
      footer={
        <div className="d-flex">
          <CustomButton
            disabled={!rejectReason}
            className={"btn-new btn-new--primary mr-2"}
            children={"Cancel Request"}
            onClick={() => onSubmit(rejectReason)}
          />
          <CustomButton
            className={"btn-new btn-new--outline-secondary"}
            children={"Close"}
            onClick={onClose}
          />
        </div>
      }
    />
  );
};

export default CancellationModal;
