export default function base64ToDownloadUrl(dataB64) {
  const byteCharacters = atob(dataB64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blobData = new Blob([byteArray], { type: "application/pdf" });

  return URL.createObjectURL(blobData);
}
