import React, { useMemo, useState } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";

const CreatePermittedItem = ({
  onLoadPermittedItem,
  newPermittedItem,
  selectedType,
  projectLists,
  getProjectListings,
  onChangePermittedItemsHOC,
  createPermittedItem,
}) => {
  const [inputError, setInputError] = useState("");

  const memoizedValue = useMemo(() => {
    let tmp = _.find(projectLists, { id: newPermittedItem.project_id });

    return tmp ? [tmp] : [];
  }, [newPermittedItem]);

  const onShowError = _.debounce((val) => {
    if (!val) {
      setInputError("The entry cannot be empty.");
    } else {
      setInputError("");
    }
  }, 750);

  const onChangeCreatePermittedItem = (key, value) => {
    let tmp = _.cloneDeep(newPermittedItem);

    tmp = {
      ...tmp,
      [key]: value,
    };
    onChangePermittedItemsHOC("newPermittedItem", tmp);
  };

  const onChangeTypeaheadSearch = _.debounce(
    (val) => onHandleTypeaheadSearch(val),
    1000,
  );

  const onHandleTypeaheadSearch = (val) => {
    onChangeCreatePermittedItem("project_id", null);

    return val.length > 0
      ? getProjectListings(val)
      : onChangePermittedItemsHOC("projectLists", []);
  };

  const onClickClose = () => {
    onChangePermittedItemsHOC("showCreatePermittedItem", false);
    onChangePermittedItemsHOC("selectedType", 1);
    onChangeCreatePermittedItem("link", "");
    onChangeCreatePermittedItem("project_id", null);
  };

  // onChangeInputError
  const renderRadioContent = () => {
    return (
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <label className="at-form-input__title">Type</label>
          <CustomRadioGroup
            checkedValue={selectedType}
            containerClassName={"w-100"}
            options={[
              { label: "IQI Drive", value: 1 },
              { label: "Project Listing", value: 2 },
            ]}
            selectedRadioValue={(val) => {
              onChangePermittedItemsHOC("selectedType", val);
              if (val === 1) {
                onChangeCreatePermittedItem("project_id", "");
              } else if (val === 2) {
                onChangeCreatePermittedItem("link", "");
              }
            }}
          />
        </section>
        <section className="grid-full-col">
          <label className="at-form-input__title" required>
            Link
          </label>
          {selectedType === 1 && (
            <section>
              <CustomFormInput
                type="text"
                required={true}
                inputError={inputError}
                placeholder={"Copy a link from IQI Drive and paste over here"}
                value={newPermittedItem.link}
                onChangeValue={(val) => {
                  let tmp = _.cloneDeep(newPermittedItem);
                  tmp = {
                    ...tmp,
                    type_id: 1,
                    link: val,
                  };
                  onChangePermittedItemsHOC("newPermittedItem", tmp);
                  onShowError(val);
                }}
              />
            </section>
          )}
          {selectedType === 2 && (
            <CustomTypeahead
              typeaheadId={"project"}
              options={projectLists || []}
              filterBy={["label"]}
              labelKey={"label"}
              onSelect={(val) => {
                if (val && val.length > 0) {
                  let tmp = _.cloneDeep(newPermittedItem);
                  tmp = {
                    ...tmp,
                    type_id: 2,
                    project_id: val[0].id,
                  };
                  onChangePermittedItemsHOC("newPermittedItem", tmp);
                }
              }}
              selectedValue={memoizedValue}
              childrenHead={(rowItem) => <p>{rowItem.label}</p>}
              disabled={onLoadPermittedItem}
              showClearButton={true}
              required={true}
              onClear={() => onChangeCreatePermittedItem("project_id", null)}
              onSearch={(val) => onChangeTypeaheadSearch(val)}
              highlighterData={[(option) => `${option.name}`]}
            />
          )}
        </section>
      </form>
    );
  };

  return (
    <>
      <ModalDialog
        title={"New Permitted Item"}
        onClose={() => onClickClose()}
        responsiveSize="lg"
        children={renderRadioContent()}
        footer={
          <div className="d-flex">
            <button
              className={`btn-new btn-new--primary`}
              disabled={
                (selectedType === 2 && !newPermittedItem.project_id) ||
                (selectedType === 1 && !newPermittedItem.link)
              }
              type={"button"}
              onClick={() => {
                if (selectedType === 2 && newPermittedItem.project_id) {
                  createPermittedItem();
                } else if (selectedType === 1 && newPermittedItem.link) {
                  createPermittedItem();
                }
              }}
            >
              Create
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary ml-2"
              onClick={() => onClickClose()}
            >
              Cancel
            </button>
          </div>
        }
      />
      {onLoadPermittedItem && <LoadingModal />}
    </>
  );
};

export default CreatePermittedItem;
