import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import CustomButton from "components/Button";

import AtlasIcon from "components/Icon/atlasIcon";

import { numberWithCommas } from "utils/thousandSeparator";

class TableContent extends Component {
  render = () => {
    const { headerData, rowItem, booleanColumn, thousandSeparatorColumn } =
      this.props;

    const contentProcess = (param, value) => {
      if (booleanColumn === value) {
        if (param === 0) {
          return "No";
        } else {
          return "Yes";
        }
      } else {
        if (thousandSeparatorColumn) {
          if (thousandSeparatorColumn.indexOf(value) > -1) {
            param = numberWithCommas(param);
          }
        }
        return param;
      }
    };

    return (
      <div className="at-profile__card__table-row-cont">
        <div className="at-profile__card__table__height-control">
          <ul className="at-profile__card__table-row lgrid-controller">
            {headerData.map((item, index) => {
              if (item.value !== "avatar_url" && item.value !== "status") {
                return (
                  <li
                    key={`table-${index}-${item.label}`}
                    className={`at-profile__card__table-row__item at-profile__card__table__${index} ${item.icon ? "with-icon" : ""}`}
                  >
                    <Tooltip
                      className={"at-tooltip"}
                      place={"top"}
                      type="dark"
                      effect="solid"
                      id={`${item.label}-${rowItem.agentID}`.replace(/\s/g, "")}
                    >
                      {item.label}
                    </Tooltip>
                    {item.icon && (
                      <>
                        <div
                          data-tip={item.value}
                          data-for={`${item.label}-${rowItem.agentID}`.replace(
                            /\s/g,
                            "",
                          )}
                          className="at-profile__card__table-row__item-icon"
                        >
                          <AtlasIcon svgHref={item.icon} />
                        </div>
                        <p style={{ textAlign: "left" }}>
                          {contentProcess(rowItem[item.value], item)}
                        </p>
                        {item.value === "mobileNumber" && (
                          <CustomButton
                            className="at-profile__card__table-row__item-action"
                            tooltip={true}
                            tooltipPosition={"top"}
                            tooltipChildren={"Whatsapp"}
                            onClick={() =>
                              window.open(
                                `https://api.whatsapp.com/send?phone=${rowItem[item.value]}`,
                                "_blank",
                              )
                            }
                          >
                            <AtlasIcon
                              svgHref={"atlas-whatsapp"}
                              style={{ width: 18, height: 18, fill: "#32D583" }}
                            />
                          </CustomButton>
                        )}
                        {item.value === "email" && (
                          <CustomButton
                            className="at-profile__card__table-row__item-action"
                            tooltip={true}
                            tooltipPosition={"top"}
                            tooltipChildren={"Email"}
                            onClick={() =>
                              window.open(
                                `mailto:${rowItem[item.value]}`,
                                "_blank",
                              )
                            }
                          >
                            <AtlasIcon
                              svgHref={"atlas-sms"}
                              style={{ width: 18, height: 18, fill: "#3B82F6" }}
                            />
                          </CustomButton>
                        )}
                      </>
                    )}
                    {!item.icon && (
                      <p
                        data-tip={item.value}
                        data-for={`${item.label}-${rowItem.agentID}`.replace(
                          /\s/g,
                          "",
                        )}
                      >
                        {item.value === "branch" && (
                          <AtlasIcon
                            svgHref={"atlas-location"}
                            style={{
                              width: 18,
                              height: 18,
                              fill: "#3B82F6",
                              marginRight: 5,
                            }}
                          />
                        )}
                        {item.renderColumn &&
                          item.renderColumn(rowItem[item.value], item)}
                        {!item.renderColumn &&
                          contentProcess(rowItem[item.value], item)}
                      </p>
                    )}
                  </li>
                );
              }
            })}
            {this.props.onClickDetails && (
              <li className="d-flex m-auto align-items-center w-100">
                <CustomButton
                  className={"btn-new btn-new--secondary my-3 w-100"}
                  containerClass={"w-100"}
                  children={"Sales Summary"}
                  onClick={() => this.props.onClickDetails(rowItem)}
                />
              </li>
            )}
            {this.props.onClickSendRequest && (
              <li className="d-flex w-100 m-auto align-items-center">
                {!rowItem.request_sent && (
                  <div className="w-100 my-3">
                    <CustomButton
                      className={"btn-new btn-new--secondary w-100"}
                      children={"Send Request"}
                      onClick={() => this.props.onClickSendRequest(rowItem)}
                    />
                    {rowItem.has_ongoing_project && (
                      <p style={{ color: "#1E3A8A" }}>
                        Already have ongoing project
                      </p>
                    )}
                  </div>
                )}
                {rowItem.request_sent && (
                  <span className="btn-new btn-new--outline-secondary w-100 my-3">
                    Request Sent
                  </span>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };
}

export default TableContent;
