import React, { useEffect, useState } from "react";
import _ from "lodash";

const ProgressBar = ({ progress, has_time_limit }) => {
  const [progressBarWidth, setProgressWidth] = useState(0);
  const [stepCompleted, setStepCompleted] = useState(0);
  const [stepIndicator, setStepIndicator] = useState(
    has_time_limit
      ? ["Reserve", "Payment Details", "Buyer", "Complete"]
      : ["Buyer", "Complete"],
  );

  useEffect(() => {
    let progressWidth = 0;
    if (has_time_limit) {
      let stepCompleted = _.findIndex(
        stepIndicator,
        (item) => item === progress,
      );
      setStepCompleted(stepCompleted);
      progressWidth = 33.33 * stepCompleted;
    } else {
      let stepCompleted = _.findIndex(
        stepIndicator,
        (item) => item === progress,
      );
      setStepCompleted(stepCompleted);
      progressWidth = 100 * stepCompleted;
    }
    setProgressWidth(progressWidth);
  }, [progress]);

  return (
    <div className="d-flex justify-content-center">
      {stepIndicator.map((step, index) => {
        let highlight = index <= stepCompleted;
        return (
          <div
            className={"at-storey-plan__progress-button"}
            style={{
              ...(highlight && {
                color: "#08958f",
              }),
            }}
          >
            <p
              className="at-storey-plan__progress-number"
              style={{
                ...(highlight && {
                  border: "1px solid #08958f",
                  backgroundColor: "#00b5ad",
                  color: "white",
                }),
              }}
            >
              {index + 1}
            </p>
            <p>{step}</p>
          </div>
        );
      })}
      <div className="at-storey-plan__progress-div">
        <div
          className="at-storey-plan__progress-cont"
          style={{
            width: !has_time_limit && 100,
          }}
        >
          <div
            className="at-storey-plan__progress-bar"
            style={{ width: `${progressBarWidth}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
