import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put, Delete, Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      teams: [],
      selectedTeam: {},
      selectedPA: {},
      showUpdateTeam: false,
      showDeleteConfirmation: false,
    };

    onChangeTeamsHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getTeams = () =>
      Get(`/admin/teams`, this.getTeamsSuccess, this.getTeamsError, this.load);
    getTeamsSuccess = (payload) =>
      this.setState({ teams: payload.splice(25, 65) });
    getTeamsError = (error) => requestError(error);

    getSelectedTeam = (id, mode) =>
      Get(
        `/admin/teams/${id}`,
        (payload) => this.getSelectedTeamSuccess(payload, mode),
        this.getSelectedTeamError,
        this.load,
      );
    getSelectedTeamSuccess = (payload) => {
      let tmpTeamSetting = payload.team_setting
        ? _.cloneDeep(payload.team_setting)
        : {};
      tmpTeamSetting.skip_referrer_approval_for_registration =
        tmpTeamSetting.skip_referrer_approval_for_registration || false;
      tmpTeamSetting.is_payment_required =
        tmpTeamSetting.is_payment_required || false;

      this.setState({
        selectedTeam: {
          ...payload,
          team_setting: tmpTeamSetting,
        },
        showUpdateTeam: true,
      });
    };
    getSelectedTeamError = (error) => requestError(error);

    updateTeam = (data) =>
      Put(
        `/admin/teams/${data.id}`,
        data,
        this.updateTeamSuccess,
        this.updateTeamError,
        this.load,
      );
    updateTeamSuccess = () => {
      requestSuccess("Team is updated successfully.");
      this.getSelectedTeam(this.state.selectedTeam.id, "details");
      this.getTeams();
    };
    updateTeamError = (error) => requestError(error);

    createPersonalAssistant = ({ name, email, mobile_contact_number }) =>
      Post(
        `/admin/teams/${this.state.selectedTeam.id}/personal_assistants/`,
        {
          name: name,
          email: email,
          mobile_contact_number: mobile_contact_number,
        },
        this.createPersonalAssistantSuccess,
        this.createPersonalAssistantError,
        this.load,
      );
    createPersonalAssistantSuccess = () => {
      requestSuccess("Personal Assistant Created.");
      this.getSelectedTeam(this.state.selectedTeam.id);
    };
    createPersonalAssistantError = (error) => requestError(error);

    updatePersonalAssistant = ({ id, name, email, mobile_contact_number }) =>
      Put(
        `/admin/teams/${this.state.selectedTeam.id}/personal_assistants/${id}`,
        {
          name: name,
          email: email,
          mobile_contact_number: mobile_contact_number,
        },
        this.updatePersonalAssistantSuccess,
        this.updatePersonalAssistantError,
        this.load,
      );
    updatePersonalAssistantSuccess = (payload) => {
      requestSuccess("Personal Assistant Updated");
      this.getSelectedTeam(this.state.selectedTeam.id);
    };
    updatePersonalAssistantError = (error) => requestError(error);

    updateTeamSettings = (dataToSubmit) =>
      Put(
        `/admin/teams/${dataToSubmit.id}/update_team_setting/${dataToSubmit.team_setting.id || ""}`,
        dataToSubmit.team_setting,
        this.updateTeamSettingsSuccess,
        this.updateTeamSettingsError,
        this.load,
      );
    updateTeamSettingsSuccess = (payload) =>
      requestSuccess("Team Settings Updated");
    updateTeamSettingsError = (error) => requestError(error);

    deletePersonalAssistant = () =>
      Delete(
        `/admin/teams/${this.state.selectedTeam.id}/personal_assistants/${this.state.selectedPA.id}`,
        this.deletePersonalAssistantSuccess,
        this.deletePersonalAssistantError,
        this.load,
      );
    deletePersonalAssistantSuccess = (payload) => {
      requestSuccess("Personal Assistant Deleted.");
      this.setState({ showDeleteConfirmation: false });
      this.getSelectedTeam(this.state.selectedTeam.id);
    };
    deletePersonalAssistantError = (error) => {
      this.setState({ showDeleteConfirmation: false });
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeTeamsHOC={this.onChangeTeamsHOC}
            getTeams={this.getTeams}
            getSelectedTeam={this.getSelectedTeam}
            updateTeam={this.updateTeam}
            createPersonalAssistant={this.createPersonalAssistant}
            updatePersonalAssistant={this.updatePersonalAssistant}
            updateTeamSettings={this.updateTeamSettings}
            deletePersonalAssistant={this.deletePersonalAssistant}
            onLoadTeams={this.state.loading}
            teams={this.state.teams}
            selectedTeam={this.state.selectedTeam}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showUpdateTeam={this.state.showUpdateTeam}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
