import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Select, OutlinedInput, MenuItem } from "@material-ui/core";

import CustomCheckbox from "components/Checkbox";
import NamecardContent from "components/Card/namecard";
import ModalDialog from "components/Modal/ModalDialog";
import ShirtCardContent from "./shirtCardContent";
import CustomTabs from "./tabs";

import isEmptyValue from "utils/isEmpty";

const ConfirmationModal = ({
  open,
  loading,
  message,
  title,
  positiveAction,
  negativeAction,
  positiveText,
  negativeText,
  resubmitOrder = "",
  onShowProfileUpdate,
  onChangeOrderHistoryHOC,
  deliveryBranches,
}) => {
  const [confirmedCheckbox, updateConfirmedCheckbox] = useState(false);
  const [productCardContent, updateCardContent] = useState("Name Card");
  const [shirtGender, updateShirtGender] = useState("");
  const [shirtSize, updateShirtSize] = useState("");

  useEffect(() => {
    if (!isEmptyValue(resubmitOrder.payload)) {
      updateShirtGender(resubmitOrder.payload.t_shirt_gender);
      updateShirtSize(resubmitOrder.payload.t_shirt_size);
    }
  }, [resubmitOrder]);

  const renderCardContent = () => {
    return (
      <>
        <p>{message}</p>
        {!isEmptyValue(resubmitOrder.payload) &&
          resubmitOrder.product_type_id === 1 && (
            <>
              <CustomTabs
                selectedSection={productCardContent}
                onSelectSection={(val) => updateCardContent(val)}
              />
              {productCardContent === "Name Card" && (
                <NamecardContent
                  nameContentStyle={{ fontSize: 18 }}
                  contentStyle={{ fontSize: 14 }}
                  cardStyle={{
                    maxWidth: 1000,
                    marginBottom: 10,
                  }}
                  onShowProfileUpdate={() => onShowProfileUpdate(true)}
                />
              )}
              {productCardContent === "T-Shirt" && (
                <ShirtCardContent
                  shirtSize={shirtSize}
                  shirtGender={shirtGender}
                  shirtType={resubmitOrder.payload.payment_amount_type}
                  updateShirtSize={(val) => updateShirtSize(val)}
                  updateShirtGender={(val) => updateShirtGender(val)}
                />
              )}
            </>
          )}
        {resubmitOrder && resubmitOrder.product_type_id < 3 && (
          <>
            <p className={"mb-10"}>
              I will collect the item at the selected branch office below:
            </p>
            <Select
              className={"mb-20"}
              value={resubmitOrder.delivery_branch_id || ""}
              fullWidth
              autoWidth={true}
              input={<OutlinedInput labelWidth={0} />}
              MenuProps={{
                classes: { paper: `at-select__dropdown-paper` },
                getContentAnchorEl: null,
                anchorOrigin: { horizontal: "left", vertical: "center" },
                MenuListProps: {
                  classes: { root: `at-select__dropdown-paper-list` },
                },
                disableAutoFocus: true,
              }}
              onChange={(e) => {
                let temp = _.cloneDeep(resubmitOrder);
                temp.delivery_branch_id = e.target.value;
                onChangeOrderHistoryHOC(temp, "resubmitOrder");
              }}
            >
              {deliveryBranches.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <CustomCheckbox
          content={`With the checkbox being checked, I've agreed to proceed with the resubmit.`}
          checked={confirmedCheckbox}
          onChangeCheckboxValue={() =>
            updateConfirmedCheckbox(!confirmedCheckbox)
          }
        />
      </>
    );
  };

  const renderFooter = () => (
    <div className="d-flex">
      <button
        disabled={!confirmedCheckbox}
        className="btn-new btn-new--success mr-10"
        onClick={() =>
          positiveAction({
            id: resubmitOrder.id,
            shirtGender: shirtGender,
            shirtSize: shirtSize,
            delivery_branch_id: resubmitOrder.delivery_branch_id,
          })
        }
      >
        {`${positiveText || "Yes"}`}
      </button>
      <button
        type={"button"}
        className="btn-new btn-new--outline-secondary"
        onClick={() => negativeAction()}
      >
        {`${negativeText || "Close"}`}
      </button>
    </div>
  );

  return (
    <>
      {open && (
        <ModalDialog
          title={title || "Notification"}
          onLoad={loading}
          children={renderCardContent()}
          footer={renderFooter()}
          onClose={negativeAction}
        />
      )}
    </>
  );
};

export default ConfirmationModal;
