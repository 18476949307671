export const LeadStatus = {
  1: {
    1: "New",
    2: "Contacted & Pending Response",
    3: "Completed",
    4: "Not Interested",
    8: "Pending",
    9: "Not Qualified",
    10: "Reconnect",
    11: "Keep in View",
  },
  2: {
    1: "New",
    2: "Contacted & Pending Response",
    3: "Completed",
    4: "Not Interested",
    5: "Scheduled Interview",
    6: "Interviewed",
    7: "Hired",
    8: "Pending",
    9: "Not Qualified",
    10: "Reconnect",
    11: "Keep in View",
  },
  3: {
    1: "New",
    8: "Pending",
    9: "Not Qualified",
    10: "Reconnect",
    11: "Keep in View",
  },
  4: {
    1: "New",
    8: "Pending",
    9: "Not Qualified",
    10: "Reconnect",
    11: "Keep in View",
  },
  5: {
    1: "New",
    8: "Pending",
    9: "Not Qualified",
    10: "Reconnect",
    11: "Keep in View",
  },
  8: {
    1: "New",
    2: "Contacted & Pending Response",
    3: "Completed",
    8: "Pending",
    10: "Reconnect",
  },
  9: {
    1: "New",
    2: "Contacted & Pending Response",
    3: "Completed",
    8: "Pending",
    10: "Reconnect",
  },
  10: {
    1: "New",
    2: "Contacted & Pending Response",
    3: "Completed",
    8: "Pending",
    10: "Reconnect",
  },
};

export const LeadType = [
  {
    value: 1,
    label: "Investment",
  },
  {
    value: 2,
    label: "Recruitment",
  },
  {
    value: 3,
    label: "Partnership",
  },
  {
    value: 4,
    label: "Service Provider",
  },
  {
    value: 5,
    label: "Enquiry",
  },
  {
    value: 8,
    label: "Rental",
  },
  {
    value: 9,
    label: "Resale",
  },
  {
    value: 10,
    label: "Renovation",
  },
];

export const AgentLeadType = [
  {
    value: 1,
    label: "Investment",
  },
  {
    value: 2,
    label: "Recruitment",
  },
  {
    value: 5,
    label: "Enquiry",
  },
  {
    value: 10,
    label: "Renovation",
  },
];
