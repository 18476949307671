import React, { Component } from "react";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

import AtSelectNew from "components/Select/new";
import CustomTableContent from "./components/content";

import isEmptyValue from "utils/isEmpty";
import "stylesheets/components/table/index.scss";
import "./index.scss";

class PaginatedTable extends Component {
  renderTableContent = () => {
    const { headerData, selectColumn, can_update, disabledDelete } = this.props;
    if (this.props.rowData && this.props.rowData.length > 0) {
      return this.props.rowData.map((rowItem, index) => {
        return (
          <CustomTableContent
            selectColumn={selectColumn}
            key={index}
            can_update={can_update}
            disabledDelete={disabledDelete}
            headerData={headerData}
            checkedArray={this.props.checkedArray}
            rowItem={rowItem}
            onChangeInputStepperValue={this.props.onChangeInputStepperValue}
            approveClick={(param) => this.props.approveClick(param)}
            detailsClick={(param) => this.props.detailsClick(param)}
            editClick={(param) => this.props.editClick(param)}
            deleteClick={(param) => this.props.deleteClick(param)}
            updateFixedClick={(param) => this.props.updateFixedClick(param)}
            updateCalendarClick={(param) =>
              this.props.updateCalendarClick(param)
            }
            updateAcceptedClick={(param) =>
              this.props.updateAcceptedClick(param)
            }
            checkOutClick={(param) => this.props.checkOutClick(param)}
            onHandleCheckBox={(param) => this.props.onHandleCheckBox(param)}
          />
        );
      });
    }
  };

  render = () => {
    const { rowData, can_create, deliveryBranches, deliveryBranchId } =
      this.props;

    let tempAmount = 0;
    let tempQuantity = 0;

    rowData.map((item) => {
      const {
        data,
        price = 0,
        selected_market_product_attached_product: attached_product,
      } = item;
      const marketProduct = !isEmptyValue(item.market_product)
        ? item.market_product
        : {
            type_id: "",
            attached_product: "",
          };
      tempQuantity += item.quantity;
      tempAmount +=
        item.quantity *
        (marketProduct.type_id === 3
          ? attached_product?.price ?? marketProduct.price
          : price);
    });

    return (
      <div className="at-mycart-table at-table at-table--set mb-10">
        <div className={"row"}>
          <div className={"col-md-8 mb-10"}>
            <h4 className="at-card-heading">
              {this.props.rowData.length || 0} items
            </h4>
            <hr />
            {rowData.length > 0 && this.renderTableContent()}
            {!rowData.length > 0 && (
              <h4>{`No item can be found in the cart.`}</h4>
            )}
          </div>
          <div className={"col-md-4"}>
            <div className="at-form__content">
              <h1>Order Summary</h1>
              <p>Total Item Quantity: {tempQuantity}</p>
              <p>Total Amount: RM {tempAmount.toFixed(2)}</p>
              <hr />
              <div className="mt-2">
                {rowData.some(
                  (item) => item.market_product.delivery_branch_selectable,
                ) && (
                  <>
                    <h4>Delivery Branch</h4>
                    <p>
                      Please select a delivery branch before proceeding to
                      checkout.
                    </p>
                    <AtSelectNew
                      value={deliveryBranchId}
                      onChange={(e) =>
                        this.props.onChangeMyCartHOC(
                          e.target.value,
                          "deliveryBranchId",
                        )
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 12,
                      }}
                    >
                      {deliveryBranches.map((branch) => {
                        return (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        );
                      })}
                    </AtSelectNew>
                  </>
                )}
                <hr />
                {can_create && (
                  <button
                    style={{ marginBottom: 12 }}
                    disabled={
                      rowData.length < 1 ||
                      (rowData.some(
                        (item) =>
                          item.market_product.delivery_branch_selectable,
                      ) &&
                        !deliveryBranchId)
                    }
                    onClick={() => this.props.onClickCheckout()}
                    className={"btn-new btn-new--primary w-100"}
                  >
                    Proceed to Checkout
                  </button>
                )}
                <button
                  onClick={() => this.props.onClose()}
                  className={"btn-new btn-new--outline-secondary w-100"}
                >
                  Continue shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default PaginatedTable;
