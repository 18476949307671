import Slider from 'react-input-slider';

import ProjectCard from 'components/Card/projectCard';
import AtlasRadioGroup from 'components/RadioGroup';

import IqiLogoDark from 'assets/images/iqi_logo_dark.png';
import IqiLogoLight from 'assets/images/iqi_logo_light.png';

const LogoSettings = ({ configuration, onChangeVideoConfiguration }) => {
  return (
    <ProjectCard icon='global' title='Logo Settings'>
      <div className={!configuration.hideIqiLogo ? 'mb-3' : ''}>
        <label className='at-form-input__title'>Exclude logo</label>
        <AtlasRadioGroup
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          checkedValue={configuration.hideIqiLogo}
          selectedRadioValue={val =>
            onChangeVideoConfiguration('hideIqiLogo', val)
          }
        />
      </div>
      {!configuration.hideIqiLogo && (
        <>
          <div className='mb-3'>
            <label className='at-form-input__title'>Logo</label>
            <AtlasRadioGroup
              options={[
                {
                  label: (
                    <img
                      alt='IQI logo dark'
                      src={IqiLogoDark}
                      style={{ height: 40 }}
                    />
                  ),
                  value:
                    'https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/iqi_logo_dark.png'
                },
                {
                  label: (
                    <img
                      alt='IQI logo light'
                      src={IqiLogoLight}
                      style={{
                        height: 40,
                        filter: 'drop-shadow(1px 1px 2px #000)'
                      }}
                    />
                  ),
                  value:
                    'https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/iqi_logo_light.png'
                }
              ]}
              checkedValue={configuration?.logo}
              selectedRadioValue={val =>
                onChangeVideoConfiguration('logo', val)
              }
            />
          </div>
          <div className='mb-3'>
            <label className='at-form-input__title'>Transparency</label>
            <div>
              <Slider
                styles={{
                  active: { backgroundColor: '#3B82F6' },
                  thumb: {
                    border: '1px solid #9ca3af',
                    boxShadow:
                      '0 4px 6px -1px rgba( 0, 0, 0, 0.1 ), 0 2px 4px -2px rgba( 0, 0, 0, 0.1 )'
                  }
                }}
                x={configuration?.logoOpacity * 100}
                onChange={({ x }) =>
                  onChangeVideoConfiguration('logoOpacity', x / 100)
                }
                xstep={10}
              />
            </div>
            <p>{configuration?.logoOpacity * 100}%</p>
          </div>
          <div>
            <label className='at-form-input__title'>Position</label>
            <AtlasRadioGroup
              options={[
                { label: 'Left', value: 'left' },
                { label: 'Right', value: 'right' }
              ]}
              checkedValue={configuration?.logoPosition}
              selectedRadioValue={val =>
                onChangeVideoConfiguration('logoPosition', val)
              }
            />
          </div>
        </>
      )}
    </ProjectCard>
  );
};

export default LogoSettings;
