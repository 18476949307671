import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import MyTraining from "./MyTraining";

class PersonalRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={"/dashboard/training-centre/my-training"}
          component={MyTraining}
        />
        <Route
          exact
          path={
            "/admin-impersonate/:profileId/dashboard/training-centre/my-training"
          }
          component={MyTraining}
        />
      </Switch>
    );
  }
}

export default withRouter(PersonalRouter);
