import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: [],
      loading: false,

      selectedPresentationStat: {
        viewed_count: 0,
        estates: [],
      },
      presentationMortgage: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      mortgagePages: [],
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    onChangeSampleHOC = (val, context) => this.setState({ [context]: val });

    getPresentationStats = (id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${id}/statistic`,
        this.getPresentationStatsSuccess,
        this.getPresentationStatsError,
        this.load,
      );
    };
    getPresentationStatsSuccess = (payload) =>
      this.setState({
        selectedPresentationStat: {
          ...payload,
          estates: payload.estates.map((item) => {
            let tmp = { ...item };
            return {
              ...tmp,
              id: item.estate_id,
            };
          }),
        },
      });
    getPresentationStatsError = (error) => requestError(error);

    getPresentationMortgage = (id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${id}/mortgage_stats`,
        this.getPresentationMortgageSuccess,
        this.getPresentationMortgageError,
        this.load,
      );
    };

    getPresentationMortgageSuccess = (payload) => {
      let tempPages = [];
      let tempData = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          tempData.push({
            ...item,
            created_at: item?.created_at
              ? Moment(item.created_at).format("DD MMM YYYY hh:mmA")
              : "N/A",
          });
        });

      this.setState({
        presentationMortgage: {
          ...payload,
          data: tempData,
        },
        mortgagePages: tempPages,
      });
    };
    getPresentationMortgageError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            mortgagePages={this.state.mortgagePages}
            presentationMortgage={this.state.presentationMortgage}
            selectedPresentationStat={this.state.selectedPresentationStat}
            onLoadStats={this.state.loading}
            onChangeSampleHOC={this.onChangeSampleHOC}
            getPresentationStats={this.getPresentationStats}
            getPresentationMortgage={this.getPresentationMortgage}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
