import React, { Component } from "react";
import _ from "lodash";

import CustomPagination from "components/Pagination";
import CustomTableContent from "./content";

import "stylesheets/components/table/index.scss";

class CustomTable extends Component {
  constructor(p) {
    super();
    this.state = {
      searching: false,
      selectPerPageOption: 10,
      totalPaginationCount: 0,
      totalPages: [1],
      clickPageNumber: 1,
      rowData: [],
      initialRowData: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData.length !== prevProps.rowData.length) {
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption,
          ),
      );
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;
        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          Object.keys(item).map((key) => {
            if (tmp[key] !== item[key]) {
              dataUpdated = true;
            }
          });
        });
        if (dataUpdated) {
          return this.setState(
            {
              rowData: this.props.rowData,
              initialRowData: this.props.rowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption,
              ),
          );
        }
      }
    }
  };

  componentDidMount = () => {
    this.handleResize();
    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    return () => window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ selectPerPageOption: 3 });
    } else {
      this.setState({ selectPerPageOption: this.props.selectPerPageOption || 10 });
    }
    this.processPagesData(this.state.rowData, this.state.selectPerPageOption);
  };

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  selectPerPageOption = (val) =>
    this.setState(
      {
        clickPageNumber: 1,
        selectPerPageOption: val.value,
      },
      () => this.processPagesData(this.state.rowData, val.value),
    );

  renderTableContent = () => {
    if (this.state.rowData) {
      return this.state.rowData.map((rowItem, index) => {
        if (
          index + 1 >
            (this.state.clickPageNumber - 1) * this.state.selectPerPageOption &&
          index + 1 <=
            this.state.clickPageNumber * this.state.selectPerPageOption
        ) {
          return (
            <CustomTableContent
              key={index}
              data={rowItem}
              onClickViewNameCard={this.props.onClickViewNameCard}
            />
          );
        }
      });
    }
  };

  renderPaginationText = () => {
    const { pagination, rowData } = this.props;
    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > this.state.rowData.length) {
        pageNumTmp = this.state.rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <p className={`at-table__pagination-info ml-2`}>
        Showing
        <span>
          {" "}
          {pagination
            ? processFloorPaginationCount(this.state.clickPageNumber)
            : ""}{" "}
        </span>
        -
        <span>
          {" "}
          {pagination
            ? processCeilingPaginationCount(this.state.selectPerPageOption)
            : ""}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  renderPagination = () => {
    return (
      <CustomPagination
        totalPages={this.state.totalPages}
        chosenPageNumber={this.state.selectPerPageOption}
        onClickPageNumber={(val) => this.onClickPageNumber(val)}
        currentPageNumber={this.state.clickPageNumber}
      />
    );
  };

  render = () => {
    const { className } = this.props;
    return (
      <div className="at-table at-table--set m-0" style={{ width: "100%" }}>
        {this.renderTableContent()}
        {this.renderPaginationText()}
        {this.renderPagination()}
      </div>
    );
  };
}

export default CustomTable;
