import React, { useState } from "react";
import _ from "lodash";
import { Typography } from "@material-ui/core";

import ModalDialog from "components/Modal/ModalDialog";
import CustomInput from "components/Input/formInput";
import { PulseLoader } from "react-spinners";

import "./index.scss";

const ReassignAgentModal = ({
  open,
  message,
  header,
  agent,
  onLoadAgent,
  searchAgents,
  reassign,
  closeModal,
  onChangeAgentHOC,
}) => {
  const [contact, updateContact] = useState("");

  const renderCardContent = () => {
    return (
      <>
        <p className="at-form-input__title">{message}</p>
        <CustomInput
          className={"col-md-6"}
          placeholder={"Mobile Number"}
          value={contact}
          onChangeValue={(val) => updateContact(val)}
        />
        {onLoadAgent && (
          <PulseLoader loading={true} sizeunit={"px"} size={30} />
        )}
        {!onLoadAgent && (
          <div className="d-flex mt-20 mb-20 g-3">
            <button
              className="btn-new btn-new--primary"
              onClick={() => searchAgents(contact)}
            >
              Search
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </div>
        )}
        {!_.isEmpty(agent) && (
          <div className="at-form__content">
            <div className="grid-control mt-3">
              <section className="grid-half-col mb-2 mb-md-0">
                <img
                  src={agent.avatar_url}
                  className="at-agent-approval-profile_photo"
                />
              </section>
              <section className="grid-half-col mt-2 mt-md-0">
                <div className="mt-2">
                  <Typography className={"at-agent-approval-title"}>
                    Display Name
                  </Typography>
                  <Typography className={"at-agent-approval-content"}>
                    {agent.full_name}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Typography className={"at-agent-approval-title"}>
                    Team Name
                  </Typography>
                  <Typography className={"at-agent-approval-content"}>
                    {agent.team_name}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Typography className={"at-agent-approval-title"}>
                    Email
                  </Typography>
                  <Typography className={"at-agent-approval-content"}>
                    {agent.email}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Typography className={"at-agent-approval-title"}>
                    Contact Number
                  </Typography>
                  <Typography className={"at-agent-approval-content"}>
                    {agent.mobile_contact_number}
                  </Typography>
                </div>
              </section>
            </div>
            <button
              className="btn-new btn-new--primary"
              style={{ marginTop: 20 }}
              onClick={() => reassign(agent.id)}
            >
              Reassign
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {open && (
        <ModalDialog
          title={header}
          children={renderCardContent()}
          onClose={() => {
            closeModal();
            onChangeAgentHOC({}, "agent");
          }}
        />
      )}
    </>
  );
};

export default ReassignAgentModal;
