import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomPagination from "components/Pagination";
import CustomTableContent from "components/NewTable/content";
import CustomTableHeader from "components/NewTable/header";

import "stylesheets/components/table/index.scss";

const CustomTable = ({
  className,
  rowData: initialRowData,
  columns,
  selectPerPageOption,
  actionColumnContent,
  pagination,
}) => {
  const [rowData, setRowData] = useState([]);
  const [searchKeyword, setKeyWord] = useState("");
  const [clickPageNumber, setPageNum] = useState(1);
  const [totalPages, setTotalPage] = useState([1]);
  const [currentPageOption, onChangePerPage] = useState(10);
  const [searchWithField, onChangeSeachWithField] = useState("");
  const [searchWithFieldDisplay, onChangeSeachWithFieldDisplay] = useState("");
  const [headerRearrangeData, setHeaderRearrangeData] = useState({});
  const [currentPageData, setCurrentPageData] = useState([]);

  useEffect(() => {
    selectPerPageOption && onChangePerPage(selectPerPageOption);
  }, []);

  useEffect(() => {
    setRowData(initialRowData);
    setPageNum(1);
    setKeyWord("");
    processPagesData(initialRowData, selectPerPageOption || 10);
  }, [initialRowData]);

  useEffect(() => {
    for (let index = 0; index < columns.length; index++) {
      if (!columns[index].hideFromSearch) {
        onChangeSeachWithField(columns[index].searchFlag);
        onChangeSeachWithFieldDisplay(columns[index].header);

        break;
      }
    }
  }, [initialRowData]);

  useEffect(() => {
    let tmp = rowData.filter((item, index) => {
      if (
        index + 1 > (clickPageNumber - 1) * currentPageOption &&
        index + 1 <= clickPageNumber * currentPageOption
      ) {
        return item;
      }
    });
    setCurrentPageData(tmp);
  }, [clickPageNumber, currentPageOption, rowData]);

  const processPagesData = (rowData, pageOption) => {
    if (rowData.length > 0) {
      let paginationSections = rowData.length / pageOption;

      let pagesArrayData = [];
      if (paginationSections > Math.floor(paginationSections)) {
        paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      }

      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      setTotalPage(pagesArrayData);
    }
  };

  const debounceService = (searchVal) => {
    let result = _.filter(initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });

    Promise.all([setRowData(result)]).then(() => {
      processPagesData(result, currentPageOption);
    });
  };

  const onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    setKeyWord(searchVal);

    if (searchVal === "") {
      setRowData(initialRowData);
      processPagesData(initialRowData, currentPageOption);
    } else {
      debounceService(searchVal);
    }
  };

  const onClickRearrangeData = useCallback(
    (val) => {
      Promise.all([setHeaderRearrangeData(val)]).then(() => {
        if (val.columnValue) {
          let tempOrder = _.orderBy(
            rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (columnData !== "Infinity" && !isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(
                    "columnData.match(/^(d{2})-(d{2})-(d{4})$/)",
                    columnData,
                  );
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          );

          setRowData(tempOrder);
        }
      });
    },
    [rowData],
  );

  const renderTableContent = () => {
    if (currentPageData && currentPageData.length > 0) {
      return currentPageData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          columns={columns}
          index={index}
          headerRearrangeData={headerRearrangeData}
          actionColumnContent={actionColumnContent}
          rowData={rowItem}
        />
      ));
    } else if (!searchKeyword) {
      return (
        <EmptyState
          title={`You don't have any teams`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    } else {
      return (
        <EmptyState
          title={`You don't have any teams`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    }
  };

  const renderPaginationText = () => {
    const processFloorPaginationCount = () => {
      let pageNumTmp = clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * currentPageOption + 1;
      }
      return pageNumTmp;
    };

    const processCeilingPaginationCount = () => {
      let pageNumTmp = currentPageOption;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowData.length) {
        pageNumTmp = rowData.length;
      }
      return pageNumTmp;
    };

    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span>
          {" "}
          {pagination ? processFloorPaginationCount(clickPageNumber) : ""}{" "}
        </span>
        -
        <span>
          {" "}
          {pagination
            ? processCeilingPaginationCount(currentPageOption)
            : ""}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  return (
    <div className={`d-flex flex-column mb-20 ${className}`}>
      <div className="grid-control w-100">
        <div className="grid-half-col">
          <div className="at-select__search-cont">
            <CustomSelect
              className="at-select_new-interface mb-3"
              selectItems={
                _.filter(columns, (item) => !item.hideFromSearch) || []
              }
              labelKey={"header"}
              valueKey={"searchFlag"}
              currentlySelected={{ searchFlag: searchWithField }}
              updateSelect={(val) => {
                onChangeSeachWithField(val.searchFlag);
                onChangeSeachWithFieldDisplay(val.header);
              }}
            />
            <input
              type="search"
              key={"searchInput"}
              value={searchKeyword}
              className={"at-table__search-input"}
              placeholder={`Search with ${searchWithFieldDisplay}`}
              onChange={(event) => onChangeSearchValue(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="at-table at-table--set">
        {rowData && rowData.length > 0 && (
          <CustomTableHeader
            onClickRearrangeData={onClickRearrangeData}
            columns={columns}
            rowData={rowData}
            actionColumnContent={actionColumnContent}
            currentPageData={currentPageData}
          />
        )}
        {renderTableContent()}
      </div>
      {rowData.length > 0 && (
        <div className="at-pagination__cont">
          <CustomPagination
            totalPages={totalPages}
            chosenPageNumber={currentPageOption}
            onClickPageNumber={(val) => setPageNum(val)}
            currentPageNumber={clickPageNumber}
          />
          {renderPaginationText()}
        </div>
      )}
    </div>
  );
};

export default CustomTable;
