import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Get, Delete } from "utils/axios";
import Axios from "axios";
import getDomainURL from "utils/getDomainURL";
import objToFormData from "utils/objToFormData";

import AtlasSnackbar from "components/Snackbar";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showFloorPlanSnackbar: false,
      showPhotoTabModal: false,

      floorPlanSnackbarContent: "",
      floorPlans: [],
      uploadedFloorPlans: [],
      floorPlanListing: [],
      tempImgSrc: "",

      uploadingFloorPlan: [],
      showFloorPlanUpload: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeFloorPlanHOC = (val, context) => this.setState({ [context]: val });

    // get all floor plans for a listing
    getFloorPlans = (id) =>
      Get(
        `/sub_sales/${id}/floorplans?locale=${this.props.data.languageReducer.language}`,
        this.getFloorPlansSuccess,
        this.getFloorPlansError,
        this.load,
      );
    getFloorPlansSuccess = (payload) =>
      this.setState({ uploadedFloorPlans: payload }, () => {
        let tmp = payload.map((i) => i.large_image_url);
        this.setState({ floorPlanListing: tmp });
      });
    getFloorPlansError = (error) => requestError(error && error);

    //delete uploaded floor plans
    removeFloorPlans = (id) =>
      Delete(
        `/sub_sales/${this.props.subsalesEntryCreationProps.id}/floorplans/${id}?locale=${this.props.data.languageReducer.language}`,
        this.removeFloorPlansSuccess,
        this.removeFloorPlansError,
        this.load,
      );
    removeFloorPlansSuccess = () =>
      this.getFloorPlans(this.props.subsalesEntryCreationProps.id);
    removeFloorPlansError = (error) => requestError(error);

    onSelectUploadFloorPlans = (event) => {
      let files = event.target.files;
      let tempFile = _.cloneDeep(this.state.floorPlans);

      for (var i = 0; i < files.length; i++) {
        let tempReader = new FileReader();
        let tmp = { name: "", file: "" };
        tmp.name = files[i].name;
        tempReader.onload = (e) => {
          tmp.file = e.target.result;
          tempFile.push(tmp);
          setTimeout(() => {
            this.setState({ floorPlans: tempFile });
          }, 100);
        };
        tempReader.readAsDataURL(event.target.files[i]);
      }
    };

    // remove floor plans to be upload
    onClickRemoveFloorPlans = (index) => {
      var array = _.cloneDeep(this.state.floorPlans);
      array = array
        .slice(0, index)
        .concat(array.slice(index + 1, array.length));
      this.setState({ floorPlans: array });
    };

    // filter uploaded and upload failed floorplan photos
    filterUploadedFloorPlans = () => {
      let tempFailed = [];
      const { floorPlans, uploadingFloorPlan } = this.state;

      uploadingFloorPlan.map((item, index) => {
        item.status === "failed" && tempFailed.push(floorPlans[index]);
      });

      this.setState({ floorPlans: tempFailed });
    };

    // upload floorplans
    onClickUploadFloorPlans = () => {
      this.load(true);
      if (this.state.floorPlans.length > 0) {
        let temp = [];
        let tempUploading = [];
        this.state.floorPlans.map(({ file: imgFile, name: imgName }) => {
          let dataToSubmit = {
            image: imgFile,
            image_file_name: imgName,
          };
          tempUploading.push({ ...dataToSubmit, status: "loading" });
          temp.push(dataToSubmit);
        });
        this.setState(
          {
            uploadingFloorPlan: tempUploading,
            showFloorPlanUpload: true,
          },
          () => {
            this.uploadFloorPlans(
              this.props.subsalesEntryCreationProps.id,
              temp,
            );
          },
        );
      } else {
        requestError(this.props.getLocalised("error.upload_image", "Please drop your 1 or more images to upload."));
      }
    };

    uploadFloorPlans = async (id, uploadFiles) => {
      for (let i = 0; i < uploadFiles.length; i++) {
        let tempRes = null;
        try {
          tempRes = await Axios.post(
            `${getDomainURL()}/sub_sales/${id}/floorplans?locale=${this.props.data.languageReducer.language}`,
            objToFormData(uploadFiles[i], "floorplan"),
          );
        } catch (error) {
          tempRes = { status: 422, message: error.response.data.message };
        }

        let tempLoading = _.cloneDeep(this.state.uploadingFloorPlan);
        tempLoading[i] = {
          ...tempLoading[i],
          status: tempRes.status === 200 ? "success" : "failed",
          ...(tempRes.status === 422 && {
            message: tempRes.message,
          }),
        };
        tempLoading[i].status = tempRes.status === 200 ? "success" : "failed";
        this.setState({ uploadingFloorPlan: tempLoading });
      }

      this.getFloorPlans(this.props.subsalesEntryCreationProps.id);
      this.filterUploadedFloorPlans();
      this.load(false);
    };

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            tempImgSrc={this.state.tempImgSrc}
            floorPlans={this.state.floorPlans}
            onLoadFloorPlans={this.state.loading}
            showPhotoTabModal={this.state.showPhotoTabModal}
            uploadedFloorPlans={this.state.uploadedFloorPlans}
            floorPlanListing={this.state.floorPlanListing}
            showFloorPlanUpload={this.state.showFloorPlanUpload}
            uploadingFloorPlan={this.state.uploadingFloorPlan}
            requestSuccess={requestSuccess}
            getFloorPlans={this.getFloorPlans}
            removeFloorPlans={this.removeFloorPlans}
            onChangeFloorPlanHOC={this.onChangeFloorPlanHOC}
            onClickRemoveFloorPlans={this.onClickRemoveFloorPlans}
            onClickUploadFloorPlans={this.onClickUploadFloorPlans}
            onSelectUploadFloorPlans={this.onSelectUploadFloorPlans}
          />

          <AtlasSnackbar
            open={this.state.showFloorPlanSnackbar}
            message={this.state.floorPlanSnackbarContent}
            handleClose={() => this.setState({ showFloorPlanSnackbar: false })}
          />
        </Fragment>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
