import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import ReactHTMLParser from "react-html-parser";

import StatusModal from "components/Modal/status";
import ConfirmationModal from "components/Modal/confirmation";

import getDomainURL from "utils/getDomainURL";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      // newTransaction: {
      //   MerchantCode: 'M27432',
      //   PaymentId: '',
      //   RefNo: '',
      //   Amount: '250.00',
      //   Currency: 'MYR',
      //   ProdDesc: 'IQI agent registration.',
      //   UserName: '',
      //   UserEmail: '',
      //   UserContact: '',
      //   Remark: 'Payment to IQI for agent registration.',
      //   Lang: 'UTF-8',
      //   SignatureType: 'SHA256',
      //   Signature: '',
      //   ResponseURL:  `https://${ window.location.host.indexOf( 'atlas.iqiglobal.com' ) !== -1 ? 'api' : 'atlastest' }.iqiglobal.com/api/payment_gateway/redirect`,
      //   BackendURL: `https://${ window.location.host.indexOf( 'atlas.iqiglobal.com' ) !== -1 ? 'api' : 'atlastest' }.iqiglobal.com/api/payment_gateway/callbacks`
      // },
      refNo: "",
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      amountString: "0.00",
      currencyCode: "",
      bankName: "",
      bankOwnerName: "",
      bankAccountNumber: "",

      showConfirmationModal: false,
      showConfirmationModalTitle: "",
      showConfirmationModalMode: "",
      showConfirmationModalMessage: "",
      onClickConfirmationModalButton: () => {},
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }, () =>
            window.location.reload()
          ),
        statusModalMessage: error,
      });

    onChangeIPay88HOC = (key, val) => this.setState({ [key]: val });

    getPrice = (id, branch_id) =>
      Get(
        `/registrations/fee?type_id=${id}&branch_id=${branch_id}`,
        this.getPriceSuccess,
        this.getPriceError,
        this.load
      );
    getPriceSuccess = (payload) => {
      this.setState(
        {
          amountString: `${payload.agent_registration_fee}`,
          currencyCode: payload.registration_fee_currency,
          bankName: payload.bank_name || "N/A",
          bankOwnerName: payload.bank_owner_name || "N/A",
          bankAccountNumber: payload.bank_account_number || "N/A",
        },
        () => {
          this.savePaymentApproval({
            provider: "Ipay88",
            type: "AgentRegistration",
            amount: payload.agent_registration_fee,
            description: "Paid for agent registration",
            user_id: this.props.userID,
            product_id: this.props.agentRegistrationID,
          });
        }
      );
    };
    getPriceError = (error) =>
      this.requestError(
        "Failed to get registration price, please contact system admin."
      );

    savePaymentApproval = (data) => {
      Axios.post(`${getDomainURL()}/payments`, data)
        .then((res) => {
          this.savePaymentApprovalSuccess(res.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.data)
            this.savePaymentApprovalError(error.response.data.message);
          else this.savePaymentApprovalError(error);
        });
    };
    savePaymentApprovalSuccess = (payload) =>
      this.setState({ refNo: payload.payment_id });
    savePaymentApprovalError = (error) => {
      this.setState({
        showConfirmationModal: true,
        showConfirmationModalTitle: "Error",
        showConfirmationModalMode: "alert",
        showConfirmationModalMessage: error,
        onClickConfirmationModalButton: () => {
          this.setState({ showConfirmationModal: false });
        },
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            refNo={this.state.refNo}
            currencyCode={this.state.currencyCode}
            amountString={this.state.amountString}
            bankName={this.state.bankName}
            bankOwnerName={this.state.bankOwnerName}
            bankAccountNumber={this.state.bankAccountNumber}
            onChangeIPay88HOC={this.onChangeIPay88HOC}
            savePaymentApproval={this.savePaymentApproval}
            getPrice={this.getPrice}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
          <ConfirmationModal
            open={this.state.showConfirmationModal}
            title={this.state.showConfirmationModalTitle}
            message={ReactHTMLParser(this.state.showConfirmationModalMessage)}
            mode={this.state.showConfirmationModalMode}
            positiveText={"Ok"}
            positiveAction={this.state.onClickConfirmationModalButton}
            hideNegative
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
