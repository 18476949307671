import React, { Component } from "react";

import { connect } from "react-redux";
import { Delete, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: [
        {
          label: "Full Name",
          value: "user_full_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Email",
          value: "user_email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Mobile Number",
          value: "user_mobile_contact_number_cont",
          type: "input",
          param: "",
        },
      ],
      loading: false,
      showDeleteConfirmation: false,

      agreementsUser: {},
      selectedAgreementUser: {},
      agreementUserPages: [],
    };

    onChangeAgreementUserHOC = (val, context) =>
      this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    getAgreementUser = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/agreements/${this.props.selectedAgreement.id}/user_agreements?${search}page=${page}`,
        this.getAgreementUserSucesss,
        this.getAgreementUserError,
        this.load,
      );
    };
    getAgreementUserSucesss = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        agreementsUser: payload,
        agreementUserPages: tempPages,
      });
    };
    getAgreementUserError = (error) => requestError(error);

    deleteAgreementUser = (agreement_id, user_agreement_id) => {
      this.setState({ showDeleteConfirmation: false });
      Delete(
        `/admin/agreements/${agreement_id}/user_agreements/${user_agreement_id}`,
        () => this.deleteAgreementUserSuccess(agreement_id),
        this.deleteAgreementUserError,
        this.load,
      );
    };
    deleteAgreementUserSuccess = (id) => {
      this.getAgreementUser(id);
      requestSuccess("User agreement is deleted successfully.");
    };
    deleteAgreementUserError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadAgreementUser={this.state.loading}
          agreementsUser={this.state.agreementsUser}
          agreementUserPages={this.state.agreementUserPages}
          searchParams={this.state.searchParams}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          selectedAgreementUser={this.state.selectedAgreementUser}
          getAgreementUser={this.getAgreementUser}
          deleteAgreementUser={this.deleteAgreementUser}
          onChangeAgreementUserHOC={this.onChangeAgreementUserHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
