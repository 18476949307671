import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import _ from "lodash";

import ReactLottie from "react-lottie";

import ReactHTMLParser from "react-html-parser";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";

import AtlasCard from "components/Card";
import CustomButton from "components/Button";

import LoadingIcon from "assets/lottie/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingIcon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ModalUploadedDocs = ({ downloadedFiles, closeDownloadedFilesList }) => {
  return (
    <Dialog open={true} classes={{ paper: "atlas-dialog-custom mx-auto" }}>
      <AtlasCard
        className={"atlas-card-custom"}
        cardContent={
          <>
            <div className="d-flex flex-wrap mb-20">
              <h4 className="at-card__title">
                {_.findIndex(downloadedFiles, (item) => !item.done) > -1
                  ? "Processing ..."
                  : "Download process done"}
              </h4>

              {_.findIndex(downloadedFiles, (item) => !item.done) > -1 && (
                <p>
                  The files you selected are being compressed to make it easier
                  to download. You can see the progress of the files below.
                </p>
              )}
            </div>
            <Table style={{ tableLayout: "fixed" }}>
              {downloadedFiles.map((item, index) => {
                if (item.loading && !item.done) {
                  return (
                    <tr
                      key={index}
                      className="d-flex atlas-upload_status_modal-list"
                    >
                      <div style={{ width: "90%" }}>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.display_name}
                        </p>
                        <div className="atlas-upload_status_modal-list-status">
                          {item.success
                            ? ""
                            : ReactHTMLParser(item.errorMessage)}
                        </div>
                      </div>
                      <div style={{ width: 30, height: 30 }}>
                        <ReactLottie
                          width={30}
                          height={30}
                          style={{ overflow: "visible" }}
                          options={defaultOptions}
                        />
                      </div>
                    </tr>
                  );
                }
              })}
              {downloadedFiles.map((item, index) => {
                if (!item.loading && item.done) {
                  if (!item.success) {
                    return (
                      <tr
                        key={index}
                        className="d-flex atlas-upload_status_modal-list"
                      >
                        <div style={{ width: "85%" }}>
                          <p
                            className="text-danger"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.display_name}
                          </p>
                          <div className="atlas-upload_status_modal-list-status">
                            {item.success
                              ? ""
                              : ReactHTMLParser(item.errorMessage)}
                          </div>
                        </div>
                        <div style={{ width: 30, height: 30 }}>
                          <AiFillCloseCircle
                            style={{ fontSize: "10px" }}
                            color="red"
                          />
                        </div>
                      </tr>
                    );
                  }
                }
              })}
              {downloadedFiles.map((item, index) => {
                if (!item.loading && item.done) {
                  if (item.success) {
                    return (
                      <tr
                        key={index}
                        className="d-flex atlas-upload_status_modal-list"
                      >
                        <div style={{ width: "90%" }}>
                          <p
                            style={{
                              color: "#008001",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.display_name}
                          </p>
                          <div className="atlas-upload_status_modal-list-status">
                            {item.success
                              ? ""
                              : ReactHTMLParser(item.errorMessage)}
                          </div>
                        </div>
                        <div style={{ width: 30, height: 30 }}>
                          <AiFillCheckCircle
                            style={{ fontSize: "10px" }}
                            color="green"
                          />
                        </div>
                      </tr>
                    );
                  }
                }
              })}
            </Table>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {_.findIndex(downloadedFiles, { loading: true }) < 0 && (
                <CustomButton
                  className={"btn-new btn-new--primary mt-10"}
                  children={"Close"}
                  disabled={false}
                  onClick={() => closeDownloadedFilesList()}
                />
              )}
            </div>
          </>
        }
      />
    </Dialog>
  );
};

export default ModalUploadedDocs;
