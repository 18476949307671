import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";
import CreateValuation from "./components/Create";

import ValuationHOC from "./actions/index";
import SubsalesStatusHOC from "actions/subsalesStatus";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const headerData = [
  { header: "ID", accessor: "id", columnStyle: { width: "10%" } },
  { header: "Type", accessor: "type", columnStyle: { width: "50%" } },
  { header: "Client/Company", accessor: "client_name" },
  {
    header: "Contact Person",
    renderColumn: (param) => (
      <UserInfo
        name={param.contact_person || "N/A"}
        email={param.email || "N/A"}
        phone_number={param.contact_number || "N/A"}
      />
    ),
  },
  { header: "Submitted on", accessor: "created_at" },
  { header: "Property Title Number", accessor: "property_title_number" },
];

const adminViewHeaderData = [
  { header: "ID", accessor: "id", columnStyle: { width: "5%" } },
  {
    header: "Agent",
    renderColumn: ({ agent_name, agent_mobile_number, agent_email }) => {
      return (
        <>
          <p>{agent_name || "N/A"}</p>
          <p>{agent_mobile_number || "N/A"}</p>
          <p>{agent_email || "N/A"}</p>
        </>
      );
    },
  },
  { header: "Type", accessor: "type", columnStyle: { width: "60%" } },
  { header: "Client/Company", accessor: "client_name" },
  {
    header: "Contact Person",
    renderColumn: ({ contact_person, contact_number, email }) => {
      return (
        <>
          <p>{contact_person || "N/A"}</p>
          <p>{contact_number || "N/A"}</p>
          <p>{email || "N/A"}</p>
        </>
      );
    },
  },
  { header: "Submitted on", accessor: "created_at" },
  { header: "Property Title Number", accessor: "property_title_number" },
];

class RECorporate extends Component {
  componentDidMount = () => {
    initGA("/dashboard/valuations/corporate");
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getCommRate();
    this.props.getValuations(1, "");

    let tmpSearchParams = _.cloneDeep(this.props.searchParams);
    tmpSearchParams = _.remove(tmpSearchParams, (i) => {
      if (role !== "Super Admin") {
        return i.label !== "Agent Email";
      }
      return true;
    });
    this.props.onChangeValuationsHOC(tmpSearchParams, "searchParams");
  };

  renderAlertContent = () => {
    const { data, completed_trainings } = this.props;

    const { profileReducer, currentSignInProfileReducer } = data;
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? currentSignInProfileReducer
      : profileReducer;

    if (
      (role === "Agent" && status_id === 2) ||
      (role === "Agent" && status_id !== 2 && !completed_trainings)
    ) {
      return (
        <>
          {role === "Agent" && status_id === 2 && (
            <AlertBox
              mode={"danger"}
              containerClassName={(role === "Agent" && status_id !== 2 && !completed_trainings) ? 'mb-3' : ''}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
          {role === "Agent" && status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={"Training not completed."}
              description={
                "You have not completed your training. You are required to finish training before accessing. Please contact support if you have any questions."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const { profileReducer, currentSignInProfileReducer } = this.props.data;
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? currentSignInProfileReducer
      : profileReducer;

    const { can_create } = permissionsChecker("Valuations", this.props.data);
    const status_permissions =
      (status_id !== 2 && this.props.completed_trainings) ||
      this.props.whitelisted ||
      role !== "Agent";

    return (
      <>
        <ModuleHeader
          title={"Valuations - Real Estate/Corporate"}
          atlasIcon={"atlas-valuations"}
          showPABadge={role === "Personal Assistant"}
          actionButton={[
            {
              label: "New Valuation",
              onShow:
                ["Super Admin", "Admin"].indexOf(role) === -1 &&
                can_create &&
                status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeValuationsHOC(true, "showCreate"),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard
          cardContent={
            <AtPaginatedTable
              columns={
                role.indexOf("Admin") > -1 ? adminViewHeaderData : headerData
              }
              rowData={this.props.variationsData.data || []}
              meta={
                this.props.variationsData.meta && this.props.variationsData.meta
              }
              searchParams={this.props.searchParams || []}
              onChangeSearchParams={(val) =>
                this.props.onChangeValuationsHOC(val, "searchParams")
              }
              getListAPI={(page, search) =>
                this.props.getValuations(page, search)
              }
              totalPages={this.props.variationsPage}
            />
          }
        />
        {this.props.showCreate && (
          <ModalDialog
            title={"New Valuation Request"}
            onClose={() =>
              this.props.onChangeValuationsHOC(false, "showCreate")
            }
            children={
              <CreateValuation
                showCreateConfirmation={this.props.showCreateConfirmation}
                onLoad={this.props.onLoad}
                onChangeValuationsHOC={this.props.onChangeValuationsHOC}
                createValuation={this.props.createValuation}
                onClose={() =>
                  this.props.onChangeValuationsHOC(false, "showCreate")
                }
              />
            }
          />
        )}
        {this.props.onLoad && <LoadingModal />}
      </>
    );
  };
}

export default compose(ValuationHOC, SubsalesStatusHOC)(RECorporate);
