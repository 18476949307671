import React, { useState } from "react";

import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";

import { StatusOptions } from "../utils";

const BulkUpdate = ({
  leads,
  onLoad,

  bulkUpdateLead,
  onChangeLeadsHOC,
}) => {
  const [status, setStatus] = useState({ value: "", label: "" });

  return (
    <ModalDialog
      title={"Update Leads Status"}
      children={
        <>
          <p className="fs-2 mb-20" style={{ color: "#1F2937" }}>
            Change the selected lead to status below.
          </p>
          <h2 className="at-form-input__title">Status</h2>
          <CustomSelect
            className="mb-30 w-100"
            selectItems={StatusOptions}
            currentlySelected={status}
            updateSelect={(val) => setStatus(val)}
          />
        </>
      }
      footer={
        <div className="d-flex g-3">
          <CustomButton
            disabled={!status.value}
            className={"btn-new btn-new--success"}
            children={"Update"}
            onClick={() =>
              bulkUpdateLead({ lead_ids: leads, status_id: status.value })
            }
          />
          <CustomButton
            className={"btn-new btn-new--outline-secondary"}
            children={"Cancel"}
            onClick={() => onChangeLeadsHOC(false, "showBulkUpdateModal")}
          />
        </div>
      }
      onLoad={onLoad}
      onClose={() => onChangeLeadsHOC(false, "showBulkUpdateModal")}
    />
  );
};

export default BulkUpdate;
