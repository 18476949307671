import React from "react";

import AtlasCloseButton from "components/Button/close";
import { Dialog } from "@material-ui/core";

const PhotoTabModal = ({
  dialogClasses,
  dialogStyle,
  fullScreen,
  fullWidth,
  maxWidth,
  tempImgSrc,

  showPhotoTabModal,
  togglePhotoTabModal,
}) => {
  return (
    <Dialog
      classes={{ ...dialogClasses }}
      style={{ ...dialogStyle }}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={showPhotoTabModal}
      onClose={() => togglePhotoTabModal()}
    >
      <AtlasCloseButton
        containerStyle={{ position: "absolute", right: 15, top: 15 }}
        onClick={() => togglePhotoTabModal()}
      />
      <img src={tempImgSrc} />
    </Dialog>
  );
};

export default PhotoTabModal;
