export const acceptedFileTypes = [
  "application/epub+zip", // .epub
  "application/msword", // .doc
  "application/octet-stream", // .mobi, .msg
  "application/pdf", // .pdf
  "application/postscript", // .eps
  "application/rtf", // .rtf
  "application/vnd.ms-excel", // .xls
  "application/vnd.ms-powerpoint", // .ppt
  "application/vnd.oasis.opendocument.spreadsheet", // .ods
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "image/gif", // .gif
  "image/jpeg", // .jpeg, .jpg
  "image/png", // .png
  "image/tiff", // .tif, .tiff
  "text/calendar", // .ics
  "text/csv", // .csv
  "text/plain", // .txt
];

export const status = [
  {
    value: 1,
    label: "Draft",
    colorName: "Yellow",
  },
  {
    value: 2,
    label: "Published",
    colorName: "Green",
  },
  {
    value: 3,
    label: "Disabled",
    colorName: "Grey",
  },
];
