import React, { Component } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { MdLiveHelp } from "react-icons/md";

import CustomButton from "components/Button";
import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import PartyCard from "components/Card/partyCard";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtConfirmationDialog from "components/Modal/confirmation";
import CustomConfirmationDialog from "../confirmationModal";
import ESigningContent from "./history";
import ProcessSection from "./process";
import ESigningTips from "./tips";

import permissionsChecker from "utils/permissionsChecker";

class EprocessContent extends Component {
  state = {
    showEsigningTipsModal: false,
  };

  renderPendingProcessContent = () => {
    const {
      eProcessInitialPartyData,
      partyData,
      selectedAuthorisationForm,
      signingOption,
      renderNavButton,
    } = this.props;
    const { can_create } = permissionsChecker("Forms", this.props.data);
    return (
      <>
        {eProcessInitialPartyData.length > 0 && (
          <Grid container spacing={16}>
            <Grid item md={12} xs={12}>
              <div className="party-card_cont mb-3">
                {eProcessInitialPartyData.map((partyData, index) => (
                  <PartyCard
                    key={index}
                    containerClass={"party-card-new"}
                    type={"authorisation"}
                    index={index}
                    data={partyData}
                    can_create={can_create}
                  />
                ))}
              </div>
              {can_create && (
                <>
                  <div className="at-form__content">
                    <AtlasRadioGroup
                      mode="old"
                      horizontal={false}
                      checkedValue={signingOption}
                      disabled={selectedAuthorisationForm.isLocked}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) =>
                        this.props.onChangePartyHOC(val, "signingOption")
                      }
                      options={[
                        {
                          value: "0",
                          label:
                            "I want to sign this contract in the e-signing platform.",
                        },
                        {
                          value: "1",
                          label:
                            "I want to apply my adopted digital signature in my profile in this e-signing process automatically.",
                        },
                      ]}
                    />
                  </div>
                  {(selectedAuthorisationForm.isLocked ||
                    partyData.some((item) => item.data.length < 1)) && (
                    <AlertBox
                      title={
                        "To start e-signing process, please resolve the requirements below first"
                      }
                      containerStyle={{
                        backgroundColor: "#FFFAEA",
                        borderColor: "#FCB122",
                      }}
                      iconStyle={{ color: "#F79008" }}
                      description={
                        <div className="at-eprocess__error-message">
                          {selectedAuthorisationForm.isLocked && (
                            <p>* Form is claimed</p>
                          )}
                          {!selectedAuthorisationForm.isLocked &&
                            partyData.some((item) => item.data.length < 1) &&
                            partyData.map((party) => {
                              if (party.data.length < 1) {
                                switch (party.id) {
                                  case 1:
                                    return <p>* { selectedAuthorisationForm.type_id === 1 ? 'Vendor' : 'Landlord' } is missing</p>;
                                  case 2:
                                    return (
                                      <p>
                                        * { selectedAuthorisationForm.type_id === 1 ? 'Vendor' : 'Landlord' }'s Representative is
                                        missing
                                      </p>
                                    );
                                  default: 
                                    return ''
                                }
                              }
                            })}
                        </div>
                      }
                    />
                  )}
                  <div
                    className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}
                  >
                    <button
                      className={"btn-new btn-new--primary"}
                      disabled={
                        partyData.some((item) => item.data.length < 1) ||
                        selectedAuthorisationForm.isLocked
                      }
                      onClick={() => {
                        Promise.all([
                          this.props.validateForm({
                            form_id: selectedAuthorisationForm.id,
                            form_type: 4,
                            signing_type_id: signingOption,
                          }),
                        ]).then(() => {
                          this.props.getParty(
                            this.props.selectedAuthorisationForm.id,
                          );
                        });
                      }}
                    >
                      Start E-Signing
                    </button>
                    {renderNavButton()}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        )}
        {eProcessInitialPartyData.length < 1 && (
          <AlertBox
            title={""}
            containerStyle={{ marginBottom: 10 }}
            description={`Please add the contract participants for each section before start the e-Signing process`}
          />
        )}
      </>
    );
  };

  renderInitiatedProcessContent = () => {
    const {
      signingStatusId,
      signingStatus,
      selectedAuthorisationForm,

      eProcessPartyData,
      onChangePartyHOC,
      downloadCompletedForm,
      renderNavButton,
    } = this.props;

    const { can_create, can_update } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    let temp = _.filter(eProcessPartyData, (item) => item.status_id === 2);
    let tempPercentage = eProcessPartyData.length > 0 ? ((temp.length / eProcessPartyData.length) * 100) : 100;

    return (
      <>
        <Grid container spacing={16}>
          <Grid item md={12} xs={12}>
            <div className="d-flex flex-wrap grid_gap-1">
              {(signingStatusId === `3` || signingStatusId === `1`) &&
                can_update && (
                  <button
                    className={"btn-new btn-new--outline-danger"}
                    onClick={() =>
                      onChangePartyHOC(true, "showResetConfirmationModal")
                    }
                  >
                    Reset e-Signing process
                  </button>
                )}

              {signingStatusId === `2` && (
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    downloadCompletedForm(
                      selectedAuthorisationForm.id,
                      `${selectedAuthorisationForm.transaction_number}_e_process.pdf`,
                    )
                  }
                >
                  {`Download Authorisation Form`}
                </button>
              )}
              {signingStatusId === "1" && (
                <button
                  className={"btn-new btn-new--secondary"}
                  onClick={() => {
                    this.props.getParty(
                      this.props.selectedAuthorisationForm.id,
                    );
                    this.props.getSigningStatus(
                      this.props.selectedAuthorisationForm.id,
                    );
                  }}
                >
                  Check Status
                </button>
              )}
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <ProcessSection
              status={signingStatus}
              can_create={can_create}
              tempPercentage={tempPercentage}
              selectedAuthorisationForm={selectedAuthorisationForm}
              onClickViewActivity={() =>
                this.props.onChangePartyHOC(true, "showSigningHistory")
              }
              onClickRefresh={() => {
                this.props.getParty(this.props.selectedAuthorisationForm.id);
                this.props.getSigningStatus(
                  this.props.selectedAuthorisationForm.id,
                );
              }}
              eProcessPartyData={eProcessPartyData}
            />
            <div
              className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}
            >
              {signingStatusId === `1` && can_update && (
                <button
                  disabled={
                    selectedAuthorisationForm.isLocked ||
                    !_.values(eProcessPartyData).some(
                      (val) => val.status_id === 2,
                    )
                  }
                  className={"btn-new btn-new--success"}
                  onClick={() =>
                    onChangePartyHOC(true, "showCompleteConfirmationModal")
                  }
                >
                  Complete e-Signing Process
                </button>
              )}
              {renderNavButton()}
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  renderModalContent = () => {
    const {
      onChangePartyHOC,
      resetSigning,
      completeEsigning,

      signingOption,
      eProcessPartyData,
      selectedAuthorisationForm,

      onLoadParty,
      onLoadAuthorisation,
      showSigningHistory,
      showStartProcessModal,
      showResetConfirmationModal,
      showCompleteConfirmationModal,
    } = this.props;
    return (
      <>
        {/* Restart Confirmation Form EProcess */}
        <AtConfirmationDialog
          context={"confirmation"}
          title={"Reset e-Signing process"}
          loading={onLoadAuthorisation || onLoadParty}
          open={showResetConfirmationModal}
          message={
            <>
              If you choose to reset the process, all the already signed
              participants will have to sign again.
              <br /> <br />
              Are you sure?
            </>
          }
          positiveAction={() =>
            resetSigning({
              form_id: selectedAuthorisationForm.id,
              form_type: 4,
            })
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showResetConfirmationModal")
          }
        />

        {/* Confirmation to initiate confirmation eprocess */}
        <CustomConfirmationDialog
          type_id={selectedAuthorisationForm.type_id}
          open={showStartProcessModal}
          showLoading={onLoadAuthorisation || onLoadParty}
          eProcessPartyData={eProcessPartyData}
          onChangePartyHOC={onChangePartyHOC}
          message={
            "Please ensure that all the information are correct before proceeding. Are you sure you want to proceed now ?"
          }
          positiveAction={() => {
            this.props.initiateSigningProcess({
              form_id: selectedAuthorisationForm.id,
              form_type: 4,
              signing_type_id: signingOption,
              participants: eProcessPartyData,
            });
            this.props.getParty(this.props.selectedAuthorisationForm.id);
          }}
          negativeAction={() =>
            this.props.onChangePartyHOC(false, "showStartProcessModal")
          }
        />

        {/* Complete Confirmation Form EProcess */}
        <AtConfirmationDialog
          context={"confirmation"}
          title={"Complete e-Signing process"}
          loading={onLoadAuthorisation || onLoadParty}
          open={showCompleteConfirmationModal}
          message={`Click yes to complete the e-Signing and download the form in PDF for the rest of the party to sign on the print out copy.`}
          positiveAction={() =>
            completeEsigning({
              form_id: selectedAuthorisationForm.id,
              form_type: 4,
            })
          }
          negativeAction={() =>
            this.props.onChangePartyHOC(false, "showCompleteConfirmationModal")
          }
        />

        {/* Signing History */}
        <AtlasDialog open={showSigningHistory}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <ESigningContent
                formId={selectedAuthorisationForm.id}
                onChangePartyHOC={onChangePartyHOC}
              />
            }
          />
        </AtlasDialog>
      </>
    );
  };

  render = () => {
    const {
      onLoadParty,
      onLoadDraftEmail,
      onLoadAuthorisation,

      eProcessContentId,
    } = this.props;

    return (
      <>
        <CustomButton
          containerClass={"mb-3"}
          className={"at-tip__button"}
          children={
            <>
              <MdLiveHelp />
              e-Signing Process Explain
            </>
          }
          onClick={() => this.setState({ showEsigningTipsModal: true })}
        />
        {eProcessContentId === 1 && this.renderPendingProcessContent()}
        {eProcessContentId === 2 && this.renderInitiatedProcessContent()}
        {this.state.showEsigningTipsModal && (
          <ESigningTips
            onClose={() => this.setState({ showEsigningTipsModal: false })}
          />
        )}
        {(onLoadAuthorisation || onLoadParty) && <LoadingModal />}

        {/* ConfirmationModal */}
        {this.renderModalContent()}
        {onLoadDraftEmail && <LoadingModal />}
      </>
    );
  };
}

export default EprocessContent;
