import React, { Component } from "react";
import { GetFile, Get } from "utils/axios";
import _ from "lodash";
import Moment from "moment";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    downloadProcessingList = () =>
      GetFile(
        `/admin/market_order_items/download`,
        "processing_list",
        () => {},
        this.downloadProcessingListError,
        this.load,
      );
    downloadProcessingListError = (error) => requestError(error);

    onClickDownloadExcel = () => {
      const { searchParams = "" } = this.props.data.lastViewReducer.lastView;

      GetFile(
        `/admin/market_order_items/download/?${searchParams}`,
        `Orders ${Moment().format("YYYY-MM-DD")}`,
        () => {},
        this.onClickDownloadExcelError,
        this.load,
      );
    };
    onClickDownloadExcelError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadOrderDownload={this.state.loading}
          onClickDownloadExcel={this.onClickDownloadExcel}
          downloadProcessingList={this.downloadProcessingList}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
