import React, { useEffect } from "react";
import { compose } from "redux";
import { AiFillFileText } from "react-icons/ai";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import BlockerTutorial from "components/BlockerTutorial";

import TokenHOC from "../actions/token";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesStatusHOC from "actions/subsalesStatus";

import { initGA } from "utils/ga";

const ButtonData = [
  {
    class: "at-btn--primary",
    route: "/dashboard/personal/forms/appointment-letter",
    label: "Appointment Letter",
  },
  {
    class: "at-btn--secondary",
    route: "/dashboard/personal/forms/confirmation-form",
    label: "Confirmation Form",
  },
  {
    class: "at-btn--danger",
    route: "/dashboard/personal/forms/cobroke-letter",
    label: "Cobroke Letter",
  },
  {
    class: "at-btn--success",
    route: "/dashboard/personal/forms/authorisation-form",
    label: (
      <>
        Authorisation Form <br /> To Secure Property
      </>
    ),
  },
];

const Menu = ({
  data,
  history,
  commRate,
  completed_trainings,
  showBlockerTutorial,
  onLoadCommRate,

  getSSOToken,
  getCommRate,
  onChangeTokenHOC,
}) => {
  useEffect(() => {
    getCommRate();
    initGA("/dashboard/personal/forms/menu");
  }, []);

  const { role, status_id } = window.location.href.includes(
    "/admin-impersonate",
  )
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const tmpPath = window.location.href.includes("/admin-impersonate")
    ? data.adminSignInReducer.prefix_path
    : "";

  return (
    <CustomCard
      className={`h-100`}
      containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
      cardContent={
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={"Training not completed."}
              containerClassName={((status_id !== 2 && !commRate) || status_id === 2) ? 'mb-3' : ''}
              description={
                "You have not completed your training. You are required to finish training before accessing Appointment Letter, Confirmation Form, Cobroke Letter & Authorisation Forms To Secure Property. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              title={"No commision rate set."}
              containerClassName={ status_id === 2 ? 'mb-3' : '' }
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
          <div className="d-flex justify-content-center m-auto flex-wrap">
            {ButtonData.map((item, index) => (
              <div key={index} className="at-form__menu">
                <AtlasButton
                  style={{
                    width: 200,
                    height: 200,
                    borderRadius: 10,
                  }}
                  className={`at-btn ${item.class}`}
                  onClick={() => history.push(`${tmpPath}${item.route}`)}
                  children={
                    <>
                      <AiFillFileText
                        style={{
                          width: "100%",
                          height: "100px",
                          marginBottom: 5,
                        }}
                      />
                      <p style={{ fontSize: "0.8rem" }}>{item.label}</p>
                    </>
                  }
                />
              </div>
            ))}
            {["Agent", "Personal Assistant"].includes(role) && (
              <div className="at-form__menu">
                <AtlasButton
                  style={{
                    width: 200,
                    height: 200,
                    borderRadius: 10,
                  }}
                  className={`at-btn at-btn--purple`}
                  onClick={() =>
                    getSSOToken("https://agentforms.iqiglobal.com")
                  }
                  children={
                    <>
                      <AiFillFileText
                        style={{
                          width: "100%",
                          height: "100px",
                          marginBottom: 5,
                        }}
                      />
                      <p style={{ fontSize: "0.8rem" }}>Requests Form</p>
                    </>
                  }
                />
              </div>
            )}
          </div>
          {showBlockerTutorial && (
            <BlockerTutorial
              onClose={() => onChangeTokenHOC(false, "showBlockerTutorial")}
            />
          )}
          {onLoadCommRate && <LoadingModal />}
        </>
      }
    />
  );
};

export default compose(TokenHOC, VersionCheckHOC, SubsalesStatusHOC)(Menu);
