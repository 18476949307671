import React, { Component } from "react";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import AtlasButton from "components/Button";
import CustomUpload from "components/Input/upload";
import AtlasRadioGroup from "components/RadioGroup";
import LoadingModal from "components/LoadingModal";
import CustomTypeahead from "components/Typeahead/new";

import AgentsHOC from "../actions/agents";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
);

const TransferTypes = [
  { label: "Event", value: 1, id: "transfer-event-form" },
  { label: "Participant", value: 2, id: "transfer-participant-form" },
];

class TransferTicket extends Component {
  filepond = null;

  state = {
    newEventId: "",
    transferTicket: {
      to_user_id: "",
      statement_of_approval: "",
      statement_of_approval_file_name: "",
    },
  };

  onChangeTypeaheadSearch = _.debounce((val) => {
    val.length > 0
      ? this.props.getAgentsBySearch(val)
      : this.props.onChangeAgentsHOC([], "agentList");
  }, 600);

  onChangeTypeaheadEventSearch = _.debounce((val) => {
    val.length > 0
      ? this.props.getEventBySearch(val)
      : this.props.onChangeTransferTicketHOC("eventList", []);
  }, 600);

  onSelectUploadImage = (fileItems) => {
    let tempFile = _.cloneDeep(this.state.transferTicket);
    tempFile.file = fileItems;
    tempFile.statement_of_approval_file_name = fileItems?.[0]?.name;
    tempFile.statement_of_approval = fileItems?.[0]?.source;
    this.setState({transferTicket: tempFile});
  };

  onClickRemoveUploadImage = (image) => {
    let tempFile = _.cloneDeep(this.state.transferTicket);

    tempFile.statement_of_approval = "";
    tempFile.statement_of_approval_file_name = "";

    this.setState({ transferTicket: tempFile });
  };

  renderUploadPaymentProof = () => {
    return (
      <CustomUpload
        multiple={false}
        accepts={"image/*"}
        imageRestriction={true}
        files={this.state.transferTicket?.file}
        style={{ backgroundColor: "#F9FAFB", border: "1px dashed #D1D5DB" }}
        labelText={"PNG, JPG, JPEG or GIF (min. 640 x 480)"}
        onRemoveFile={() => this.onClickRemoveUploadImage()}
        uploadImage={(fileItems) => this.onSelectUploadImage(fileItems)} />
    );
  };

  render = () => {
    return (
      <div className="grid-control grid_gap-2">
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Transfer Type</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={this.props.transferType}
            selectedRadioValue={(val) =>
              this.props.onChangeTransferTicketHOC("transferType", val)
            }
            options={TransferTypes}
          />
        </section>
        {this.props.transferType === 1 && (
          <>
            <section className="grid-full-col" style={{ height: 100 }}>
              <h2 className="at-form-input__title">Transfer to the selected event</h2>
              <CustomTypeahead
                typeaheadId={"new_event"}
                showClearButton={true}
                onClear={() => this.setState({ newEventId: "" })}
                options={this.props.eventList || []}
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    this.setState({ newEventId: val[0].id });
                  }
                }}
                selectedValue={
                  _.find(this.props.eventList, { id: this.state.newEventId })
                    ? [
                        _.find(this.props.eventList, {
                          id: this.state.newEventId,
                        }),
                      ]
                    : []
                }
                labelKey={"name"}
                filterBy={["name"]}
                onSearch={(val) => {
                  this.setState({ newEventId: "" });
                  this.onChangeTypeaheadEventSearch(val);
                }}
                childrenHead={(rowItem) => (
                  <div className="at-attendee__agent-detail-info">
                    <p>{rowItem.name}</p>
                    <p style={{ fontSize: "unset" }}>
                      Event Date: {rowItem.eventDate}
                    </p>
                    <br />
                  </div>
                )}
              />
            </section>
          </>
        )}
        {this.props.transferType === 2 && (
          <>
            <section className="grid-full-col" style={{ height: 100 }}>
              <h2 className="at-form-input__title">
                {"Transfer to the selected agent below"}
              </h2>
              <CustomTypeahead
                showClearButton={true}
                onClear={() => {
                  let tmp = _.cloneDeep(this.state.transferTicket);
                  tmp.to_user_id = "";
                  this.setState({ transferTicket: tmp });
                }}
                labelKey={"full_name"}
                typeaheadId={"agent"}
                filterBy={["full_name", "email", "mobile_contact_number"]}
                options={this.props.agentList || []}
                onSearch={(val) => {
                  let tmp = _.cloneDeep(this.state.transferTicket);
                  tmp.to_user_id = "";
                  this.setState({ transferTicket: tmp });
                  this.onChangeTypeaheadSearch(val);
                }}
                selectedValue={
                  _.find(this.props.agentList, {
                    id: this.state.transferTicket.to_user_id,
                  })
                    ? [
                        _.find(this.props.agentList, {
                          id: this.state.transferTicket.to_user_id,
                        }),
                      ]
                    : []
                }
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    let tmp = _.cloneDeep(this.state.transferTicket);
                    tmp.to_user_id = val[0].id;
                    return this.setState({ transferTicket: tmp });
                  }
                }}
                helpText={"e.g. Full Name, Email, Mobile Number"}
                childrenHead={(rowItem) => <p>{rowItem.name}</p>}
                highlighterData={[
                  (option) =>
                    `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                ]}
              />
            </section>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">
                Upload proof of payment
              </h2>
              {this.renderUploadPaymentProof()}
            </section>
          </>
        )}
        <div className="at-modal_dialog-container-footer g-3">
            <AtlasButton
              className={"btn-new btn-new--success"}
              children={"Transfer Now"}
              disabled={this.props.transferType === 1
                ? (_.find(this.props.eventList, { id: this.state.newEventId }) ? false : true)
                : Object.values(this.state.transferTicket).some((value) => !value)
              }
              onClick={() => this.props.transferType === 1
                ? this.props.transferTicketEventRequest(this.props.selectedAttendee.id,this.state.newEventId)
                : this.props.transferTicketRequest(this.props.selectedAttendee.id, this.state.transferTicket)
              }
            />
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => {
                this.setState({ newEventId: "" });
                this.props.onChangeTransferTicketHOC("eventList", []);
                this.props.onChangeTransferTicketHOC("showTransferTicket", false);
                this.props.onChangeTransferTicketHOC("transferType", 1);
              }}
            >
              Cancel
            </button>
          </div>
        {(this.props.onLoadTransferTicket ||
          this.props.onLoadInternalAgents) && <LoadingModal />}
      </div>
    );
  };
}

export default AgentsHOC(TransferTicket);
