import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    updateExamResult = (id, dataToSubmit) =>
      Put(
        `/admin/exams/${id}`,
        dataToSubmit,
        this.updateExamResultSuccess,
        this.updateExamResultError,
        this.load,
      );
    updateExamResultSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getExams(currentPage, searchParams);
      this.props.getSelectedExam(payload.id);
      requestSuccess("Exam Result updated succesfully.");
    };
    updateExamResultError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadEdit={this.state.loading}
            updateExamResult={this.updateExamResult}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
