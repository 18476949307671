import React from "react";
import { BiPaint } from "react-icons/bi";
import { IoMdDownload } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";

export const PaymentTypes = [
  {
    id: 6,
    name: "Bank Transfer",
    value: 6,
  },
  {
    id: 2,
    name: "Cheque",
    value: 2,
  },
  {
    id: 1,
    name: "Cash",
    value: 1,
  },
];

export const DisbursementCost = [
  {
    id: 0,
    label:
      "Tenant to bear all costs incurred in the preparation and stamping of the Tenancy Agreement.",
    value: 0,
  },
  {
    id: 2,
    label:
      "Landlord to bear all costs incurred in the preparation and stamping of the Tenancy Agreement.",
    value: 2,
  },
  {
    id: 1,
    label:
      "Both parties sharing equally for all costs incurred in the preparation of the Tenancy Agreement:",
    value: 1,
  },
];

export const NavTabs = (type_id) =>
  type_id === 1
    ? [
        { label: "Property", icon: <AtlasIcon svgHref="atlas-building-3" /> },
        {
          label: "Participants",
          icon: <SvgIcon svgHref="confirmation-form_participants" />,
        },
        {
          label: "T&C",
          icon: <SvgIcon svgHref="confirmation-form_document-text" />,
        },
        {
          label: "Contract Signing",
          icon: <SvgIcon svgHref="confirmation-form_esign" />,
        },
        {
          label: "Downloads",
          icon: (
            <AtlasIcon
              className="svg-icon-info"
              svgHref={"atlas-document-download"}
            />
          ),
        },
        {
          label: "Temp. Receipt",
          icon: <AtlasIcon svgHref="atlas-receipt-text" />,
        },
      ]
    : [
        { label: "Property", icon: <AtlasIcon svgHref="atlas-building-3" /> },
        {
          label: "Participants",
          icon: <SvgIcon svgHref="confirmation-form_participants" />,
        },
        {
          label: "T&C",
          icon: <SvgIcon svgHref="confirmation-form_document-text" />,
        },
        {
          label: "Inventory Checklist",
          icon: <AtlasIcon svgHref={"svg-iconsax-lamp"} />,
        },
        {
          label: "Contract Signing",
          icon: <SvgIcon svgHref="confirmation-form_esign" />,
        },
        {
          label: "Downloads",
          icon: (
            <AtlasIcon
              className="svg-icon-info"
              svgHref={"atlas-document-download"}
            />
          ),
        },
        {
          label: "Temp. Receipt",
          icon: <AtlasIcon svgHref="atlas-receipt-text" />,
        },
        {
          label: "Tenancy Agreement",
          icon: <AtlasIcon svgHref="svg-iconsax-document-text-1" />,
        },
      ];

export const tenancyArray = [
  { label: "Tenancy Agreement (Residential)", value: 1 },
  { label: "Tenancy Agreement (Residential for Expat)", value: 2 },
  { label: "Tenancy Agreement (Commercial)", value: 3 },
];

export const partyDictionary = [
  {
    id: 1,
    subsalesTitle: "Purchaser",
    subrentTitle: "Tenant",
  },
  {
    id: 2,
    subsalesTitle: "Purchaser's Representative",
    subrentTitle: "Tenant's Representative",
    positionLeft: "-9rem",
  },
  {
    id: 3,
    subsalesTitle: "Vendor",
    subrentTitle: "Landlord",
  },
  {
    id: 4,
    subsalesTitle: "Vendor's Representative",
    subrentTitle: "Landlord's Representative",
    positionLeft: "-10rem",
  },
];
