import React, { Component } from "react";
import Moment from "moment";
import ReactHTMLParser from "react-html-parser";
import ReactPlayer from "react-player";

import NewsfeedTable from "./Table";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import VideoPlayer from "components/VideoPlayer";
import AtlasIcon from "components/Icon/atlasIcon";
import ShareButton from "components/Button/share";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";

import NewsfeedHOC from "../actions/newsFeed";

import StayTuned from "assets/images/staytuned.png";
import "../index.scss";

const shareMedia = [
  {
    id: 0,
    value: "facebook",
  },
  {
    id: 1,
    value: "twitter",
  },
  {
    id: 2,
    value: "linkedin",
  },
  {
    id: 3,
    value: "email",
  },
];

class NewsfeedContent extends Component {
  componentDidMount = () => this.props.getNewsFeed(1);

  componentDidUpdate = (pp) => {
    if (
      pp.onLoadNewsFeed !== this.props.onLoadNewsFeed &&
      this.props.selectedFeed.content
    ) {
      let aTag = document.getElementsByTagName("a");
      if (aTag.length > 0) {
        for (let i = 0; i < aTag.length; i++) {
          if (aTag[i].href.includes("www.youtube.com")) {
            aTag[i].style.display = "none";
            let element = document.createElement("button");
            element.innerHTML = aTag[i].innerHTML;
            element.style.color = "#007bff";
            element.onclick = () => {
              Promise.all([
                this.props.onChangeNewsFeedHOC(aTag[i].href, "selectedVideo"),
              ]).then(() => {
                this.props.onChangeNewsFeedHOC(true, "showVideoPlayer");
              });
            };
            aTag[i].after(element);
          } else {
            aTag[i].target = "_blank";
          }
          aTag[i].style.color = "#007bff";
        }
      }
    }
  };

  renderCardContent = () => {
    return (
      <div
        style={{
          position: "relative",
          margin: -20,
          padding: 20,
          background:
            "linear-gradient(180deg, #F3F4F6 0%, #F3F4F6 250px, white 250px, white 100%)",
        }}
      >
        <AtlasCloseButton
          containerStyle={{
            position: "sticky",
            top: 0,
            left: 0,
            width: 40,
            height: 40,
            marginBottom: 10,
          }}
          onClick={() =>
            this.props.onChangeNewsFeedHOC(false, "showSelectedFeed")
          }
        />
        <div style={{ maxWidth: 800, margin: "20px auto" }}>
          {this.props.selectedFeed.youtube_link && (
            <ReactPlayer
              url={this.props.selectedFeed.youtube_link}
              playing={true}
              controls={true}
              className={"at-newsfeed-video-player"}
              style={{ backgroundColor: "black" }}
            />
          )}
          {!this.props.selectedFeed.youtube_link && (
            <img
              style={{
                borderRadius: 5,
                marginBottom: 10,
                aspectRatio: "16/9",
              }}
              src={this.props.selectedFeed.teaser_image_url}
              alt={`teaser-image-${this.props.selectedFeed.title}`}
            />
          )}
          <h2 className="at-card__title mb-2">
            {this.props.selectedFeed.title}
          </h2>
          <p
            className="d-flex algin-items-center mb-20"
            style={{ color: "#374151" }}
          >
            <AtlasIcon
              svgHref={"atlas-calendar-2"}
              style={{ width: 20, height: 20, marginRight: 8, fill: "#3B82F6" }}
            />
            {Moment(this.props.selectedFeed.created_at, "DD-MM-YYYY").format(
              "DD MMM YYYY",
            )}
          </p>
          {ReactHTMLParser(this.props.selectedFeed.content)}
          {this.renderShareButtons(this.props.selectedFeed)}
        </div>
      </div>
    );
  };

  renderShareButtons = (selectedFeed) => {
    if (selectedFeed.shareable_link) {
      return (
        <div className="mt-3">
          <p className="at-card__title mb-1">SHARE THIS ARTICLE</p>
          <div className="d-flex grid_gap-2">
            {shareMedia.map((item) => (
              <ShareButton
                containerClass={"at-share_btn_mono"}
                containerStyle={{ display: "inline-block" }}
                key={item.id}
                shareLink={selectedFeed.shareable_link}
                media={item.value}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  render = () => {
    const {
      newsFeedData,
      newsFeedMeta,
      getNewsFeed,
      getSelectedFeed,
      newsFeedPages,
      onLoadNewsFeed,
      showSelectedFeed,
    } = this.props;
    return (
      <>
        {newsFeedData && newsFeedData.length > 0 && (
          <NewsfeedTable
            onClick={(val) => getSelectedFeed(val.id)}
            rowData={newsFeedData || []}
            meta={newsFeedMeta || {}}
            getListAPI={getNewsFeed}
            totalPages={newsFeedPages}
          />
        )}
        {newsFeedData && newsFeedData.length < 1 && (
          <img src={StayTuned} className={"w-100"} />
        )}
        <AtlasDialog open={showSelectedFeed}>
          <CustomCard
            className={"h-100 bg-white"}
            cardContent={this.renderCardContent()}
          />
        </AtlasDialog>
        {this.props.showVideoPlayer && (
          <VideoPlayer
            videoURL={this.props.selectedVideo}
            onClickClose={() =>
              this.props.onChangeNewsFeedHOC(false, "showVideoPlayer")
            }
          />
        )}
        {onLoadNewsFeed && (
          <LoadingModal
            loaderContainerClass={"at-statisic__loader-container"}
            loaderClassName={"at-statisic__loader"}
          />
        )}
      </>
    );
  };
}

export default NewsfeedHOC(NewsfeedContent);
