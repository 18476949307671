import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post, Put, Delete, GetFile } from "utils/axios";
import { refreshToken } from "actions/login";
import { storeLastView } from "actions/lastView";

const internalNotesHOC = (WrappedComponent) => {
  class InternalNotesWrappedComponent extends Component {
    state = {
      newNote: "",
      noteList: [],
      showLoadingModal: false,
      showDeleteConfirmation: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeInternalNotesHOC = (val, context) =>
      this.setState({ [context]: val });

    getInternalNotes = (confirmation_form_id) =>
      Get(
        `/forms/confirmation_forms/${confirmation_form_id}/internal_notes`,
        this.getInternalNotesSuccess,
        this.getInternalNotesError,
        this.load,
      );
    getInternalNotesSuccess = (payload) =>
      this.setState({
        noteList: payload,
      });
    getInternalNotesError = (error) => requestError(error);

    submitInternalNote = (data, confirmation_form_id) =>
      Post(
        `/forms/confirmation_forms/${confirmation_form_id}/internal_notes`,
        { note: data },
        () => this.submitInternalNoteSuccess(confirmation_form_id),
        this.submitInternalNoteError,
        this.load,
      );
    submitInternalNoteSuccess = (confirmation_form_id) => {
      requestSuccess("Internal note submitted successfully.");
      this.setState({ newNote: "" });
      this.getInternalNotes(confirmation_form_id);
    };
    submitInternalNoteError = (error) => requestError(error);

    updateInternalNote = (dataToSubmit, confirmation_form_id, id) =>
      Put(
        `/forms/confirmation_forms/${confirmation_form_id}/internal_notes/${id}`,
        { note: dataToSubmit },
        (payload) => this.updateInternalNoteSuccess(confirmation_form_id),
        this.updateInternalNoteError,
        this.load,
      );
    updateInternalNoteSuccess = (confirmation_form_id) => {
      this.getInternalNotes(confirmation_form_id);
      requestSuccess("Internal note updated successfully.");
    };
    updateInternalNoteError = (error) => requestError(error);

    deleteInternalNote = (confirmation_form_id, id) =>
      Delete(
        `/forms/confirmation_forms/${confirmation_form_id}/internal_notes/${id}`,
        () => this.deleteInternalNoteSuccess(confirmation_form_id),
        this.deleteInternalNoteError,
        this.load,
      );
    deleteInternalNoteSuccess = (confirmation_form_id) => {
      this.getInternalNotes(confirmation_form_id);
      this.setState({ showDeleteConfirmation: false });
      requestSuccess("Internal Note delete successfully.");
    };
    deleteInternalNoteError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            newNote={this.state.newNote}
            noteList={this.state.noteList}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            onLoadInternalNotes={this.state.showLoadingModal}
            onChangeInternalNotesHOC={this.onChangeInternalNotesHOC}
            getInternalNotes={this.getInternalNotes}
            submitInternalNote={this.submitInternalNote}
            updateInternalNote={this.updateInternalNote}
            deleteInternalNote={this.deleteInternalNote}
          />
        </>
      );
    };
  }

  return InternalNotesWrappedComponent;
};

export default internalNotesHOC;
