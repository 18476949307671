import React from "react";
import CustomSelect from "components/Select";
import _ from "lodash";

import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";

export const CalendarTabs = ({
  tabLibrary,
  selectedTab,
  eventFilter,
  branchRegions,
  filterBranchRegion,
  selectPerPageOption,

  setTab,
  setEventFilter,
  onChangeCalendarHOC,
}) => {
  const renderTabs = () => {
    return (
      <AtlasRadioGroup
        horizontal={true}
        checkedValue={selectedTab}
        selectedRadioValue={(val) => setTab(val)}
        options={tabLibrary}
      />
    );
  };

  const renderOptionFilters = () => {
    const getClassName = (param) => {
      switch (param) {
        case 0:
          return "events";
        case 1:
          return "trainings";
        case 2:
          return "team_birthday";
        case 3:
          return "paid-events";
        case 4:
          return "paid-trainings";
      }
    };
    return (
      <div className="d-flex flex-wrap mt-2">
        {selectedTab === "all" &&
          _.map(tabLibrary[0].checkbox_options, (checkbox_item) => (
            <div
              key={checkbox_item.value}
              className="at-company__affairs-form_control"
            >
              <CustomCheckbox
                labelClassname={`at-company__affairs-checkbox-${getClassName(checkbox_item.value)}`}
                checked={eventFilter.indexOf(checkbox_item.value) > -1}
                contentStyle={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#1F2937",
                }}
                onChangeCheckboxValue={() =>
                  setEventFilter(checkbox_item.value)
                }
                content={checkbox_item.label}
              />
            </div>
          ))}
      </div>
    );
  };
  return (
    <div className="at-company__affairs-header_tab">
      <div className="d-flex flex-column flex-wrap">
        {renderTabs()}
        {renderOptionFilters()}
      </div>
      {selectedTab !== "Team Birthdays" && (
        <div className="at-company__affairs-filter">
          <p>Filter by Region:</p>
          <CustomSelect
            className="at-table__select"
            selectItems={branchRegions}
            currentlySelected={filterBranchRegion}
            updateSelect={(val) =>
              onChangeCalendarHOC(val, "filterBranchRegion")
            }
            placeholder={selectPerPageOption}
          />
        </div>
      )}
    </div>
  );
};
