import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoMdAlert } from "react-icons/io";
import { FaRegBell, FaExclamationCircle } from "react-icons/fa";
import { AiFillBell, AiOutlineClose } from "react-icons/ai";

import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import ConfirmationModalContent from "./ConfirmationModalContent";
import ReviseCommissionModal from "./ReviseCommissionModal";
import RateProjectPartner from "./RatePartner";
import ProjectDetails from "./ProjectDetails";
import SetProjectModal from "./SetProject";
import NotesModal from "./NotesModal";
import Activity from "./Activity";

import defaultSearch from "../assets/searchParams";
import ReceivedColumnData from "../assets/receivedColumnData";
import ReceivedPartnerColumn from "../assets/receivedPartner";
import { categories as CategoriesDictionary } from "dictionary/subsales";
import permissionsChecker from "utils/permissionsChecker";

const countryIndex = 2;
const stateIndex = 3;

export default function CobrokeRequest({
  data,
  primaryTab,
  currentTab,
  projectList,
  requestMode,
  showActivity,
  showNotesModal,
  showRatePartner,
  cobrokeRequests,
  showConcludeModal,
  showProjectDetails,
  showSelectProject,
  cobrokeRequestPages,
  onLoadCobroke,
  whitelisted,
  completed_trainings,

  createNote,
  updateNote,
  deleteNote,
  concludeCobroke,
  downloadCobroke,
  getProjectListing,
  onChangeCobrokeHOC,
  getPropertyDetails,
  updateCobrokeStatus,
  updateCobrokeProject,
  updateCommissionRate,
  updateReviceCommStatus,
  updateProjectSalesCycle,
  getReceivedCobrokeRequest,
  getReceivedPartnerRequest,
}) {
  const { id, status_id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const { can_update } = permissionsChecker("Co Broke Center", data);
  const [searchParams, onChangeSearchParams] = useState(
    defaultSearch(data.dictionaryReducer.countries),
  );
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showApprovalConfirmation, onToggleApprovalConfirmation] =
    useState(null);
  const [showRejectConfirmation, onToggleRejectConfirmation] = useState(false);
  const [showReviseCommission, onToggleReviseCommission] = useState(false);
  const [showReviseConfirmation, onToggleReviseConfirmation] = useState(false);
  const [confirmationMode, setConfirmationMode] = useState("");
  const [showConfirmationModal, onToggleConfirmationModal] = useState(false);
  const [selectedCobroke, setCobroke] = useState(null);

  const columnData = (param) =>
    primaryTab === "Project Partner"
      ? ReceivedPartnerColumn(param)
      : ReceivedColumnData(param);

  const getReceivedRequest = (page, search) =>
    primaryTab === "Project Partner"
      ? getReceivedPartnerRequest(id, page, search)
      : getReceivedCobrokeRequest(id, page, search);

  useEffect(() => {
    let tempGroup = [];

    CategoriesDictionary.map(({ group_types }) => {
      group_types.map((item) => {
        tempGroup.push(...item.children);
      });
    });
    onChangeSearchParams(
      defaultSearch(data.dictionaryReducer.countries, tempGroup),
    );
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(searchParams);

    if (searchParams.length > 0 && temp[countryIndex].param) {
      let selectedCountry = _.find(data.dictionaryReducer.countries, {
        name: temp[countryIndex].param,
      });

      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      onChangeSearchParams(temp);
    }
  }, [searchParams[countryIndex].param]);

  useEffect(() => {
    if (primaryTab === "Project Partner") {
      onChangeCobrokeHOC("ProjectCentre", "requestMode");
    } else {
      onChangeCobrokeHOC("CoBrokeCenter", "requestMode");
    }

    onChangeCobrokeHOC(
      {
        data: [],
        meta: [],
      },
      "cobrokeRequests",
    );
    getReceivedRequest(1, "");
  }, [currentTab, primaryTab]);

  const getProjectDetails = (project_id) => {
    setSelectedProjectId(project_id);
    onChangeCobrokeHOC(true, "showProjectDetails");
  };

  const submitUpdateProject = (cobroke_id, project_id) => {
    const { currentPage, searchParams } = data.lastViewReducer.lastView;

    Promise.all([updateCobrokeProject(cobroke_id, project_id)]).then(() => {
      getReceivedRequest(currentPage, searchParams);
    });
  };

  const submitProjectSalesCycle = (dataToSubmit) => {
    const { currentPage, searchParams } = data.lastViewReducer.lastView;

    Promise.all([updateProjectSalesCycle(dataToSubmit)]).then(() => {
      getReceivedRequest(currentPage, searchParams);
    });
  };

  const renderDescription = () => {
    if (primaryTab === "Secondary Market") {
      return (
        <>
          {currentTab === 2 && (
            <div className="at-form__content at-cobroke__tab-description">
              You have received Co-broke requests through Co-broke Centre for
              your property listings that was consented to internal Co-broke in
              the Listing Centre. Review the details of each request and the
              property it refers to
              <br />
              Click <strong>Accept</strong> the request to Co-broke and get in
              touch with the Co-broke agent to take the deal further.
            </div>
          )}
          {currentTab !== 2 && (
            <div className="at-form__content at-cobroke__tab-description">
              {`Your new and existing co-broke requests from the Co-broke Centre 
                  are shown below. Review the details of each 
                  request and the property it refers to.`}
              <ol
                type={"1"}
                className={"mt-2 mb-0"}
                style={{ paddingInlineStart: 20 }}
              >
                <li>
                  If you have a potential counterparty for the deal such as a
                  buyer or tenant, accept the request and get in touch with the
                  co-broke agent to take the deal further.
                </li>
                <li>
                  If you are unable to fulfil the request such as not having a
                  buyer or tenant for the property, decline the request.
                </li>
              </ol>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      {renderDescription()}
      <AtPaginatedTable
        showCreateButton={false}
        columns={columnData({
          getProjectDetails,
          onToggleApprovalConfirmation,
          onToggleRejectConfirmation,
          onToggleReviseCommission,
          onToggleConfirmationModal,
          setConfirmationMode,
          onToggleConclude: () => onChangeCobrokeHOC(true, "showConcludeModal"),
          setCobroke,
          currentTab,
          can_update,
          getPropertyDetails,
          disabled: !(status_id !== 2 && completed_trainings) && !whitelisted,
          onToggleConclude: () => onChangeCobrokeHOC(true, "showConcludeModal"),
          onChangeCobrokeHOC,
          downloadCobroke,
        })}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeSearchParams(val)}
        meta={cobrokeRequests.meta}
        rowData={cobrokeRequests.data}
        totalPages={cobrokeRequestPages}
        getListAPI={getReceivedRequest}
      />
      {showActivity && (
        <Activity
          selectedCobroke={selectedCobroke}
          onClose={() => onChangeCobrokeHOC(false, "showActivity")}
        />
      )}
      {showApprovalConfirmation && (
        <ConfirmationModalContent
          context={"received"}
          primaryTab={primaryTab}
          selectedListing={selectedCobroke}
          onClickSubmit={() =>
            Promise.all([
              updateCobrokeStatus(selectedCobroke.id, 3, requestMode),
            ]).then(() => {
              onToggleApprovalConfirmation(false);
            })
          }
          profileId={id}
          onLoad={onLoadCobroke}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
          onClickClose={() => onToggleApprovalConfirmation(false)}
        />
      )}
      {showReviseCommission && (
        <ReviseCommissionModal
          selectedCobroke={selectedCobroke}
          setCobroke={(val) => setCobroke(val)}
          onToggleReviseConfirmation={onToggleReviseConfirmation}
          onClickClose={() => onToggleReviseCommission(false)}
        />
      )}
      {showSelectProject && (
        <SetProjectModal
          open={showSelectProject}
          projectList={projectList}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          getProjectListing={getProjectListing}
          updateCobrokeProject={submitUpdateProject}
          onClose={() => onChangeCobrokeHOC(false, "showSelectProject")}
        />
      )}
      {showRatePartner && (
        <RateProjectPartner
          currentTab={currentTab}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
          updateProjectSalesCycle={submitProjectSalesCycle}
          onClose={() => onChangeCobrokeHOC(false, "showRatePartner")}
        />
      )}
      {showNotesModal && (
        <NotesModal
          data={data}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getListAPI={getReceivedRequest}
          setSelectedCobroke={(val) => setCobroke(val)}
          onClose={() => onChangeCobrokeHOC(false, "showNotesModal")}
        />
      )}
      {showProjectDetails && (
        <ProjectDetails
          project_id={selectedProjectId}
          showProjectDetails={showProjectDetails}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
        />
      )}
      <ConfirmationModal
        mode={"alert"}
        open={showReviseConfirmation}
        loading={onLoadCobroke}
        message={
          <div className="at-cobroke__revise-confirmation">
            You are about to change the commission proportion of this cobroke
            request. The followings will occur:
            <p>
              <FaExclamationCircle />
              This request status will be set to Pending.
            </p>
            <p>
              <FaRegBell />A notification will be sent to the Request Sender.
            </p>
            <p>
              <FaExclamationCircle />
              Please wait for the Request Sender to either accept or decline the
              new commission proportion.
            </p>
          </div>
        }
        positiveAction={() =>
          Promise.all([updateCommissionRate(selectedCobroke)]).then(() => {
            onToggleReviseConfirmation(false);
          })
        }
        negativeAction={() => onToggleReviseConfirmation(false)}
      />
      <ConfirmationModal
        mode={"alert"}
        open={showConfirmationModal}
        loading={onLoadCobroke}
        message={
          <div className="at-cobroke__revise-confirmation">
            You are about to{" "}
            <b>{confirmationMode === "accept" ? "Accept" : "Reject"}</b> the
            revised commission offered for this co-broke request. The followings
            will occur:
            <p>
              <FaRegBell style={{ width: 14, height: 14, marginRight: 10 }} />
              This request status will be set to{" "}
              <b>{confirmationMode === "accept" ? "Accepted" : "Decline"}</b>.
            </p>
            {confirmationMode === "reject" && (
              <p>
                <FaExclamationCircle
                  style={{ width: 14, height: 14, marginRight: 10 }}
                />
                The accepted Internal Co-broke Agreement will be
                revoked/cancelled.
              </p>
            )}
            {confirmationMode === "accept" && (
              <p>
                <FaExclamationCircle
                  style={{ width: 14, height: 14, marginRight: 10 }}
                />
                The agreed revision of the commission rate will be updated in
                the Internal Cobroke Agreement.
              </p>
            )}
          </div>
        }
        positiveAction={() =>
          Promise.all([
            updateReviceCommStatus(
              selectedCobroke.id,
              confirmationMode === "accept" ? 3 : 4,
            ),
          ]).then(() => {
            onToggleConfirmationModal(false);
          })
        }
        negativeAction={() => onToggleConfirmationModal(false)}
      />
      <ConfirmationModal
        mode={"alert"}
        title={"Reject Co-broke"}
        open={showRejectConfirmation}
        showLoading={onLoadCobroke}
        fieldLabel={primaryTab === "Project Partner" ? "Reject Reason" : ""}
        message={`Are you sure you want to reject to Co-Broke now ?`}
        positiveAction={(reason) =>
          Promise.all([
            updateCobrokeStatus(selectedCobroke.id, 4, requestMode, reason),
          ]).then(() => {
            onToggleRejectConfirmation(false);
          })
        }
        negativeAction={() => onToggleRejectConfirmation(false)}
      />
      <ConfirmationModal
        open={showConcludeModal}
        maxWidth={"md"}
        mode={"alert"}
        message={
          <>
            <p>
              You are about to conclude the deal with this Co-broke agent. The
              following will occur:
            </p>
            <p className={"mt-3"}>
              <IoMdAlert
                className={"color-warning"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              This listing status will be set to <strong>Sold/Rent Out</strong>.
            </p>
            <p className={"mt-2"}>
              <AiOutlineClose
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              All other Co-broke agreement will be set to{" "}
              <strong>No Longer Available</strong>
            </p>
            <p className={"mt-2"}>
              <AiFillBell
                className={"color-warning"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              A notification will be sent to any existing Co-broke agents for
              this listing.
            </p>
          </>
        }
        positiveAction={() => concludeCobroke(selectedCobroke.id)}
        negativeAction={() => onChangeCobrokeHOC(false, "showConcludeModal")}
      />
      {onLoadCobroke && <LoadingModal />}
    </>
  );
}
