import React from 'react';
import useSelectedLocalised from 'utils/hooks/useSelectedLocalised';

const withSelectedLocalised = (WrappedComponent) => {
  return (props) => {
    const language = useSelectedLocalised();
    return <WrappedComponent {...props} language={language} />;
  };
};

export default withSelectedLocalised;