import React, { Component } from "react";
import { Get, Put } from "utils/axios";
import _ from "lodash";
import { connect } from "react-redux";

import StatusModal from "components/Modal/status";

import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";

const PaymentHOC = (WrappedComponent) => {
  class PaymentWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showLoadingModal: false,
      showStatusModal: false,
      showPaymentProofModal: false,
      showEmployementProofModal: false,
      showPolicyRefundModal: false,
      showPrivacyPolicyModal: false,
      showPaymentGateway: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      tabContentType: "Online Payment",

      payment_proof_file_name: "",
      payment_proof: {},
      paymentMethod: "",
      paymentMethodId: null,
      uploadedPaymentUrl: "",
      payment_proof_file_type: "",
      modalContentType: "selection",

      employmentUrl: "",
      employment_proof: {},
      employment_proof_file_name: "",
      employment_proof_file_type: "",

      countryId: null,
    };

    load = (param) => this.setState({ loading: param });

    submitLoad = (param) => this.setState({ showLoadingModal: param });

    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        statusModalMessage: success,
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
      });

    getPaymentStatus = (val) =>
      Get(
        `/registrations/payment_method/${val}`,
        this.getPaymentStatusSuccess,
        this.getPaymentStatusFailed,
        this.load,
      );
    getPaymentStatusSuccess = (payload) => {
      const {
        payment_url,
        show_payment_gateway,
        via_recruitment_campaign,
        payment_method,
        payment_method_id,
        employment_proof_url,
        country_id,
      } = payload;

      let tmp = "Bank in";
      if (via_recruitment_campaign) {
        tmp = "Recruitment Campaign";
      } else if (show_payment_gateway || payment_method_id === 2) {
        tmp = "Online Payment";
      }

      this.setState({
        showPaymentGateway: show_payment_gateway,
        uploadedPaymentUrl: payment_url,
        paymentMethod: payment_method,
        paymentMethodId: payment_method_id,
        employmentUrl: employment_proof_url,
        tabContentType: tmp,
        countryId: country_id,
      });
    };
    getPaymentStatusFailed = (error) => this.requestError(error);

    onChangeFile = (fileItems, uploadType) => {
      if (fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (uploadType === "Employment") {
            this.setState({
              employment_proof_file_name: fileItems[0].file.name,
              employment_proof: e.target.result,
              employment_proof_file_type: fileItems[0].file.type
                .split("/")
                .pop(),
            });
          } else {
            this.setState({
              payment_proof_file_name: fileItems[0].file.name,
              payment_proof: e.target.result,
              payment_proof_file_type: fileItems[0].file.type.split("/").pop(),
            });
          }
        };
        reader.readAsDataURL(fileItems[0].file);
      } else {
        this.setState({
          payment_proof_file_name: {},
          payment_proof: "",
          payment_proof_file_type: "",
        });
      }
    };

    onClickSubmitPaymentProof = (amountString, uploadType) => {
      let tmp = {};
      tmp.amount = amountString;
      tmp.id = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer.id
        : this.props.data.profileReducer.id;

      if (uploadType === "Employment") {
        tmp.employment_proof = this.state.employment_proof;
        tmp.employment_proof_file_name = this.state.employment_proof_file_name;
        tmp.employment_proof_file_type = this.state.employment_proof_file_type;
      } else {
        tmp.payment_proof = this.state.payment_proof;
        tmp.payment_proof_file_name = this.state.payment_proof_file_name;
        tmp.payment_proof_file_type = this.state.payment_proof_file_type;
      }
      Put(
        `/registrations/payment`,
        tmp,
        this.onClickSubmitPaymentProofSuccess,
        this.onClickSubmitPaymentProofFailed,
        this.load,
      );
    };
    onClickSubmitPaymentProofSuccess = (payload) => {
      this.getPaymentStatus(
        window.location.href.includes("/admin-impersonate")
          ? this.props.data.currentSignInProfileReducer.id
          : this.props.data.profileReducer.id,
      );
      this.setState({
        statusModalMessage: payload.message,
        showStatusModal: true,
        statusModalType: "positive",
        payment_proof: {},
        employment_proof: {},
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
      });
    };
    onClickSubmitPaymentProofFailed = (error) =>
      this.requestError(error ? error : "");

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            countryId={this.state.countryId}
            showPaymentGateway={this.state.showPaymentGateway}
            showPolicyRefundModal={this.state.showPolicyRefundModal}
            showPrivacyPolicyModal={this.state.showPrivacyPolicyModal}
            showPaymentProofModal={this.state.showPaymentProofModal}
            showEmployementProofModal={this.state.showEmployementProofModal}
            onLoadPayment={this.state.loading}
            tabContentType={this.state.tabContentType}
            uploadedPaymentUrl={this.state.uploadedPaymentUrl}
            modalContentType={this.state.modalContentType}
            payment_proof={this.state.payment_proof}
            paymentMethod={this.state.paymentMethod}
            paymentMethodId={this.state.paymentMethodId}
            employmentUrl={this.state.employmentUrl}
            employment_proof={this.state.employment_proof}
            employment_proof_file_type={this.state.employment_proof_file_type}
            employment_proof_file_name={this.state.employment_proof_file_name}
            onChangeFile={this.onChangeFile}
            onChangeHOC={this.onChangeHOC}
            getPaymentStatus={this.getPaymentStatus}
            onClickSubmitPaymentProof={this.onClickSubmitPaymentProof}
          />
          {
            <StatusModal
              isOpen={this.state.showStatusModal}
              type={this.state.statusModalType}
              message={
                this.state.statusModalMessage
                  ? this.state.statusModalMessage
                  : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
              }
              onClick={this.state.onClickStatusModalButton}
            />
          }
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
  })(PaymentWrappedComponent);
};

export default PaymentHOC;
