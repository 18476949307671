import { Tooltip } from "@material-ui/core";

export default function TooltipWrapper({
  child,
  title,
  enterTouchDelay,
  customStyle,
  placement,
}) {
  return (
    <Tooltip
      enterTouchDelay={enterTouchDelay || 50}
      classes={{ tooltip: placement ? "tooltip-arrow" : "tooltip-arrow top" }}
      placement={placement || "top"}
      style={{ ...customStyle }}
      title={title}
    >
      {child}
    </Tooltip>
  );
}
