import React, { Component } from "react";

import AtlasIcon from "components/Icon/atlasIcon";

class Heading extends Component {
  render() {
    const { subTitle, showLine } = this.props;
    return (
      <div
        className={`${this.props.className ? this.props.className + " " : ""}heading-title`}
      >
        {showLine !== "hide" ? (
          <div className="at-badge-icon" style={{ backgroundColor: "#CBBFA0" }}>
            <AtlasIcon
              svgHref={"atlas-link-2-linear"}
              style={{ width: 16, height: 16, fill: "white" }}
            />
          </div>
        ) : (
          ""
        )}
        <h3 className="text-uppercase">{this.props.children}</h3>
        {subTitle ? <h4 className={`sub-title`}>{subTitle}</h4> : ""}
      </div>
    );
  }
}

export default Heading;
