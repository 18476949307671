import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Slider from "react-slick";

const CarouselPrevArrow = ({ className, onClick }) => (
  <IoIosArrowDropleftCircle className={className} onClick={onClick} />
);

const CarouselNextArrow = ({ className, onClick }) => (
  <IoIosArrowDroprightCircle className={className} onClick={onClick} />
);

const Carousel = ({ children, getTableData }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: true,
    prevArrow: <CarouselPrevArrow />,
    nextArrow: <CarouselNextArrow />,
    afterChange: (current) => {
      if (current < children.length && current > (children.length - 10) && children.length % 30 === 0) {
        getTableData((children.length / 30) + 1);
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
