import React, { useEffect, useState } from "react";
import _ from "lodash";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";
import SubsalesTooltip from "../components/tooltip";

import { unit_types, measurements } from "dictionary/subsales";
import CustomButton from "components/Button";

let facilties = [
  {
    name: "All Day Security",
    state: "all_day_security",
    key: "option.facilities.all_day_security",
  },
  {
    name: "BBQ",
    state: "bbq",
    key: "option.facilities.bbq",
  },
  {
    name: "Business Centre",
    state: "business_centre",
    key: "option.facilities.business_centre",
  },
  {
    name: "Cafeteria",
    state: "cafetria",
    key: "option.facilities.cafeteria",
  },
  {
    name: "Club House",
    state: "club_house",
    key: "option.facilities.club_house",
  },
  {
    name: "Gymnasium",
    state: "gymnasium",
    key: "option.facilities.gymnasium",
  },
  {
    name: "Jacuzzi",
    state: "jacuzzi",
    key: "option.facilities.jacuzzi",
  },
  {
    name: "Jogging Track",
    state: "jogging_track",
    key: "option.facilities.jogging_track",
  },
  {
    name: "Mini Market",
    state: "mini_market",
    key: "option.facilities.mini_market",
  },
  {
    name: "Nursery",
    state: "nursery",
    key: "option.facilities.nursery",
  },
  {
    name: "Parking",
    state: "parking",
    key: "option.facilities.parking",
  },
  {
    name: "Playground",
    state: "playground",
    key: "option.facilities.playground",
  },
  {
    name: "Salon",
    state: "salon",
    key: "option.facilities.salon",
  },
  {
    name: "Sauna",
    state: "sauna",
    key: "option.facilities.sauna",
  },
  {
    name: "Squash Court",
    state: "squash_court",
    key: "option.facilities.squash_court",
  },
  {
    name: "Swimming Pool",
    state: "swimming_pool",
    key: "option.facilities.swimming_pool",
  },
  {
    name: "Tennis Court",
    state: "tennis_court",
    key: "option.facilities.tennis_court",
  },
  {
    name: "Wading Pool",
    state: "wading_pool",
    key: "option.facilities.wading_pool",
  },
];

const FacilitiesForm = ({
  facilitiesStates,
  dataBasicInfo,
  descriptionType,

  getLocalised,
  renderSpecialMessage,
  otherFacilities,
  onChangeNewHOC,
}) => {
  const { address, askingPrice, landArea, selectedBathroom, selectedBedroom } =
    dataBasicInfo;
  const [RTEMounted, setRTEMounted] = useState(true);

  useEffect(() => {
    return () => {
      setRTEMounted(false);
    };
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp.selectedMeasurement = measurements[0];
    temp.selectedUnitType = unit_types[0];
    onChangeNewHOC(temp, "dataBasicInfo");

    return () => {
      setRTEMounted(false);
    };
  }, [RTEMounted]);

  const onCheckCheckboxValue = (val) => {
    let temp = _.cloneDeep(facilitiesStates);

    if (temp.indexOf(val) > -1) {
      _.remove(temp, (item) => item === val);
    } else {
      temp.push(val);
    }

    onChangeNewHOC(temp, "dataFacilitiesInfo");
  };

  const onChangeBasicFormField = (val, context) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp[context] = val;
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const renderChineseDesc = () => {
    return (
      renderSpecialMessage(getLocalised(
        "label.details.helptext.description.message_ch", 
        `Please take note that the following Chinese description is only
        applicable and will be shown in listings exported to juwai.com and will
        not apply to listings published in iqiglobal.com. There are three ways
        to export the Chinese description of your listing’s to Juwai. The first
        method will be prioritised, followed by the second, and third.


        The 2 methods are as follows:
        1. You can opt to write your own Chinese description for the listing
        which will overwrite the existing Chinese description when exporting
        your listing to Juwai.
        2. You may choose to translate your listings English description into
        the Chinese language via professional translation services provided by
        Juwai, on condition that you have subscribed to any of the Juwai
        packages. Should you wish to choose this method, you may leave the
        Chinese description blank.`
    )))
  }

  const renderEnglishDesc = () => {
    return (
      <p>
        {getLocalised(
          "label.details.helptext.description.message_en", 
          `Please write the description of your listing in English here. The
          listing will not be imported into Juwai if there are any Chinese
          characters in the description. Please use the Chinese description
          segment, if you wish to key in Chinese characters for your listing.`
        )}
      </p>
    );
  };

  const renderHiddenDesc = () => {
    return (
      <p>
        {renderSpecialMessage(getLocalised("label.details.helptext.description.message_hidden", 
          `This section is only visible to you, it will not appear on Co-Broke Centre, IQI Web, Juwai platform or any other partner platforms.
          <br />
          You can put your personal notes about this listing here.`
        ))}
      </p>
    );
  };

  const renderDesc = (type) => {
    switch (type) {
      case "English":
        return renderEnglishDesc();
      case "Chinese":
        return renderChineseDesc();
      case "Hidden":
        return renderHiddenDesc();
    }
  };

  return (
    <>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-weight"}
            style={{ fill: "#F0631D", marginRight: 8 }}
          />
          {getLocalised("label.details.facilities.title", "Facilities")}
        </div>
        <form className="grid-control">
          <section className="grid-full-col">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h2 className="at-form-input__title">{getLocalised("label.details.facilities.title", "Facilities")}</h2>
              <SubsalesTooltip
                data={{
                  showToolTip: true,
                  showJuwaiToolTip: true,
                  showJuwaiAsiaToolTip: true,
                  tooltip: "facilities",
                }}
              />
            </div>
          </section>
          {facilties.map((item) => (
            <section className="grid-third-col" key={item.name}>
              <CustomCheckbox
                getLocalised={getLocalised}
                checked={facilitiesStates.indexOf(item.state) > -1}
                content={item.name}
                translation={item.key}
                onChangeCheckboxValue={() => onCheckCheckboxValue(item.state)}
              />
            </section>
          ))}
          <section className="grid-full-col mt-10">
            <h2 className="at-form-input__title">{getLocalised("label.details.facilities.other_facilities", "Other Facilities")}</h2>
            <CustomFormInput
              type="text"
              value={otherFacilities}
              onChangeValue={(val) => onChangeNewHOC(val, "otherFacilities")}
              required={false}
              placeholder={getLocalised("label.details.placeholder.facilities.other_facilities", "Enter your facilities here")}
            />
          </section>
        </form>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-message-text-1"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.details.description.title", "Description")}
        </div>
        <div className="at-listing__description-tab-cont">
          <button
            className={
              descriptionType === "English"
                ? "at-listing__description-selected-tab"
                : ""
            }
            onClick={() => onChangeNewHOC("English", "descriptionType")}
          >
            {getLocalised("label.details.tab.description.en", "English")}
          </button>
          <button
            className={
              descriptionType === "Chinese"
                ? "at-listing__description-selected-tab"
                : ""
            }
            onClick={() => onChangeNewHOC("Chinese", "descriptionType")}
          >
            {getLocalised("label.details.tab.description.zh", "Chinese")}
          </button>
          <button
            className={
              descriptionType === "Hidden"
                ? "at-listing__description-selected-tab"
                : ""
            }
            onClick={() => onChangeNewHOC("Hidden", "descriptionType")}
          >
            {getLocalised("label.details.tab.description.hidden", "Hidden")}
          </button>
        </div>
        {(descriptionType === "English" || descriptionType === "Chinese") && (
          <div className="d-flex align-items-center">
            <h2 className="at-form-input__title">
              {descriptionType === "English"
                ? getLocalised("label.details.description.subtitle_1_en", "Please write the description of your listing in English here.")
                : getLocalised("label.details.description.subtitle_1_zh", "Please write the description of your listing in Chinese here.")}
              <SubsalesTooltip
                data={{
                  showToolTip: true,
                  showJuwaiAsiaToolTip: true,
                  showJuwaiToolTip: true,
                  showEdgeTooltip: true,
                  tooltip: "description",
                }}
              />
            </h2>
          </div>
        )}
        {(descriptionType === "English" || descriptionType === "Chinese") && (
          <p className="fs-2 my-2">
            {descriptionType === "English"
              ? getLocalised("label.details.description.subtitle_2_en", "Please write the description of your listing in English here.")
              : getLocalised("label.details.description.subtitle_2_zh", "Please write the description of your listing in Chinese here.")}
          </p>
        )}
        <AlertBox mode={"info"} description={renderDesc(descriptionType)} />
        {(descriptionType === "English" || descriptionType === "Chinese") && (
          <CustomButton
            disabled={
              !address ||
              !askingPrice ||
              !landArea ||
              !selectedBedroom ||
              !selectedBathroom
            }
            className={"btn-new btn-new--outline-secondary mb-2"}
            children={
              <>
                <AtlasIcon
                  svgHref={"atlas-ai-generation"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#3B82F6",
                    marginRight: 10,
                  }}
                />
                {getLocalised("label.details.description.generate_description", "Generate Description")}
              </>
            }
            onClick={() => onChangeNewHOC(true, "showGenerateModal")}
          />
        )}
        {RTEMounted && descriptionType === "English" && (
          <CKEditor
            editor={ClassicEditor}
            style={{ borderRadius: 8 }}
            data={dataBasicInfo.description}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeBasicFormField(data, "description");
            }}
            config={{
              toolbar: [],
            }}
          />
        )}
        {RTEMounted && descriptionType === "Chinese" && (
          <CKEditor
            editor={ClassicEditor}
            data={dataBasicInfo.zh_description}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeBasicFormField(data, "zh_description");
            }}
            config={{
              toolbar: [],
            }}
          />
        )}
        {RTEMounted && descriptionType === "Hidden" && (
          <CKEditor
            editor={ClassicEditor}
            data={dataBasicInfo.zh_description}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeBasicFormField(data, "hidden_description");
            }}
            config={{
              toolbar: [],
            }}
          />
        )}
      </div>
    </>
  );
};

export default FacilitiesForm;
