import React from "react";
import { CgPlayListRemove } from "react-icons/cg";

import "./index.scss";

const EmptyState = ({
  containerClassName = "",
  renderIcon,
  title,
  size,
  description,
  buttonAction,
  learnMoreAction,
}) => {
  return (
    <div className={`at-empty-state ${containerClassName}`}>
      <div className={`at-empty-state__icon${size || ""}`}>
        {renderIcon || <CgPlayListRemove />}
      </div>
      <h4 className="title">{title || `You don't have any item yet`}</h4>
      <p className="description">{description}</p>
      {buttonAction && (
        <button
          className={`btn-new btn-new--primary ${buttonAction.className || ""}`}
          onClick={buttonAction.onClick}
        >
          {buttonAction.label}
        </button>
      )}
      {learnMoreAction && (
        <a href={learnMoreAction.href} target={"__blank"}>
          {learnMoreAction.label}
        </a>
      )}
    </div>
  );
};

export default EmptyState;