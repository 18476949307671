import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import Moment from "moment";

import Tabs from "components/Tab";
import CustomCard from "components/Card";
import CustomButton from "components/Button";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import CustomSearchForm from "components/Form/search";
import CustomFormHeader from "components/Form/header";
import ConfirmationModal from "components/Modal/confirmation";
import PaymentVoucherDetails from "./Details";
import DownloadCP58 from './CP58';

import PaymentVouchersHOC from "./action";
import VersionCheckHOC from "actions/versionCheck";
import { downloadLogsHeader, ColumnData } from "./assets";
import {
  storeSelectedStartDate,
  storeSelectedEndDate,
} from "actions/paymentVoucher";
import { initGA } from "utils/ga";

class PaymentVouchers extends Component {
  state = {
    title: "",
    selectedStartDate: "",
    selectedEndDate: "",
  };

  componentDidMount = () => {
    initGA("/dashboard/personal/payment-vouchers");
    this.props.getDownloadLogs();
    const { role, display_name } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    this.setState(
      {
        title:
          ["Admin", "Super Admin"].indexOf(role) !== -1
            ? `${display_name}'s Payment Vouchers`
            : "Payment Vouchers",
        selectedStartDate: Moment()
          .year(new Date().getFullYear())
          .month(0)
          .date(1)
          .format("YYYY-MM-DD"),
        selectedEndDate: Moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.loadPV(this.state.selectedStartDate, this.state.selectedEndDate);
      },
    );
  };

  loadPV = (start_date, end_date) => {
    const { role, encoded_identity } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getPaymentVoucher(
      ["Super Admin", "Admin"].indexOf(role) !== -1
        ? this.props.encoded_identity
        : encoded_identity,
      start_date,
      end_date,
    );
  };

  onSubmitDateFilter = () => {
    this.props.onChangePaymentVoucherHOC(true, "loading");
    this.loadPV(this.state.selectedStartDate, this.state.selectedEndDate);
  };

  onSelectStartDate = (val) => {
    let date = val
      ? Moment(val).format("YYYY-MM-DD")
      : Moment()
          .year(new Date().getFullYear())
          .month(0)
          .date(1)
          .format("YYYY-MM-DD");

    this.setState({ selectedStartDate: date }, () => {
      this.props.storeSelectedStartDate(date);
    });
  };

  onSelectEndDate = (val) => {
    let date = val
      ? Moment(val).format("YYYY-MM-DD")
      : Moment(new Date()).format("YYYY-MM-DD");

    this.setState({ selectedEndDate: date }, () => {
      this.props.storeSelectedEndDate(date);
    });
  };

  renderDownloadConfirmModal = () => {
    return (
      <ConfirmationModal
        open={this.props.showDownloadConfirmation}
        message={`Are you sure to download all PVs from ${Moment(this.state.selectedStartDate).format("DD-MM-YYYY")} 
        to ${Moment(this.state.selectedEndDate).format("DD-MM-YYYY")}.`}
        title={"Batch Download PVs"}
        positiveAction={() => {
          Promise.all([
            this.props.onChangePaymentVoucherHOC(
              false,
              "showDownloadConfirmation",
            ),
          ]).then(() => {
            this.props.bulkExport(
              this.state.selectedStartDate,
              this.state.selectedEndDate,
            );
          });
        }}
        negativeAction={() =>
          this.props.onChangePaymentVoucherHOC(
            false,
            "showDownloadConfirmation",
          )
        }
      />
    );
  };

  renderTabFilter = () => (
    <div className="d-flex w-100 px-3 py-2">
      <CustomFormHeader title={"History"} moduleIcon={"atlas-document-text"} />
      <CustomButton
        className={"btn-new btn-new--outline-secondary ml-2 my-1"}
        onClick={() => this.props.getDownloadLogs()}
        children={
          <>
            <AtlasIcon
              svgHref="atlas-refresh-circle"
              style={{
                width: 20,
                height: 20,
                fill: "#374151",
                marginRight: 10,
              }}
            />
            Refresh
          </>
        }
      />
    </div>
  );

  renderCardContent = () => {
    const { downloadLogs, paymentVoucher, selectedTab } = this.props;
    const { role, encoded_identity } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <Tabs
          sections={["Payment Vouchers", "Download History", "CP58"]}
          selectedSection={selectedTab}
          onSelectSection={(val) =>
            this.props.onChangePaymentVoucherHOC(val, "selectedTab")
          }
        />
        {selectedTab === 'CP58' && (
          <DownloadCP58
            onLoad={this.props.onLoadPV}
            cp58Year={this.props.cp58Year}
            cp58List={this.props.cp58List}

            getCP58={this.props.getCP58}
            downloadCP58={this.props.downloadCP58}
            onChangePaymentVoucherHOC={this.props.onChangePaymentVoucherHOC} />
        )}
        {selectedTab !== 'CP58' && (
          <>
            <div className="at-form__content" style={{ padding: 15 }}>
              <CustomSearchForm
                containerClass={"mb-0"}
                showHideAgentsCheckbox={false}
                onSelectStartDate={(val) => this.onSelectStartDate(val)}
                onSelectEndDate={(val) => this.onSelectEndDate(val)}
                selectedStartDate={this.state.selectedStartDate}
                selectedEndDate={this.state.selectedEndDate}
                hideSearch={selectedTab === "Download History"}
                downloadBtn={selectedTab === "Download History"}
                onClickSubmit={() => this.onSubmitDateFilter()}
                onClickDownload={() =>
                  this.props.onChangePaymentVoucherHOC(
                    true,
                    "showDownloadConfirmation",
                  )
                }
              />
            </div>
            <CustomTable
              className={"team-sales__table"}
              columns={
                selectedTab === "Payment Vouchers" ? ColumnData : downloadLogsHeader
              }
              rowData={
                selectedTab === "Payment Vouchers" ? paymentVoucher : downloadLogs
              }
              pagination={true}
              hideSearch={selectedTab === "Download History"}
              actionColumnContent={
                selectedTab === "Payment Vouchers"
                  ? [
                      {
                        name: "details",
                        onClick: (rowData) =>
                          this.props.getSelectedVoucher(
                            ["Super Admin", "Admin"].indexOf(role) > -1
                              ? this.props.encoded_identity
                              : encoded_identity,
                            rowData.pvNumber,
                          ),
                      },
                    ]
                  : [
                      {
                        name: "document",
                        disabled: (rowData) => rowData.status !== "Completed",
                        onClick: (rowData) => window.open(rowData.document_link),
                      },
                    ]
              }
              {...(selectedTab === "Download History" && {
                renderTabFilter: this.renderTabFilter,
              })}
            />
          </>
        )}
        {(this.props.onLoadPV || this.props.onLoadLogs) && <LoadingModal />}
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={this.state.title}
          atlasIcon={"atlas-receipt-item"}
          description={`You can easily access and manage your payment vouchers here.
            Simply specify date ranges to find and download vouchers. 
            Alternatively, export all your PVs as a single PDF via Download History. 
            Just select the date ranges and click Download. 
            Additionally, access CP58 for your annual income tax statements by selecting the statement year and clicking download`}
          showPABadge={role === "Personal Assistant"}
          backButton={{
            onShow: ["Super Admin", "Admin"].indexOf(role) !== -1,
            onClick: () => this.props.clickBack(),
          }}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showDetailModal && (
          <PaymentVoucherDetails
            onClose={() =>
              this.props.onChangePaymentVoucherHOC(false, "showDetailModal")
            }
            selectedVoucher={this.props.selectedVoucher}
          />
        )}
        {this.renderDownloadConfirmModal()}
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default compose(
  connect(mapStateToProps, {
    storeSelectedEndDate,
    storeSelectedStartDate,
  }),
  PaymentVouchersHOC,
  VersionCheckHOC,
)(PaymentVouchers);
