import React from "react";

const ActionData = [
  { label: "Mark as Pending", id: 0 },
  { label: "Mark as Processing", id: 1 },
  { label: "Mark as Arrived", id: 2 },
  { label: "Mark as Collected", id: 3 },
  { label: "Mark as Reject", id: 4 },
  { label: "Mark as Voided", id: 5 },
];

const tabs = ({
  checkedArray,
  onChangeItemStatus,
  onClickDownloadExcel,
  onClickReject,
  onClickCancel,
}) => {
  return (
    <>
      {ActionData.map((item) => (
        <button
          key={item.id}
          disabled={checkedArray.length < 1}
          className={`btn-new-sm btn-new--secondary mb-1 mr-1`}
          style={{ fontSize: 14, padding: "5px 10px" }}
          onClick={() => {
            if (item.id === 4) {
              return onClickReject(4);
            }
            if (item.id === 5) {
              return onClickCancel(5);
            }
            onChangeItemStatus(item.id);
          }}
        >
          {item.label}
        </button>
      ))}
      <div style={{ display:"flex" }}>
        <span style={{ padding: "8px" }}>
          {" "}
          {"Download in "}
          <a
            style={{
              color: "#007bff",
              display: "inline",
              textDecoration: "underline",
            }}
            onClick={() => onClickDownloadExcel()}
          >
            Excel
          </a>
        </span>
      </div>
    </>
  );
};

export default tabs;
