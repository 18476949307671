import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const RegionHOC = (WrappedComponent) => {
  class RegionWrappedComponent extends Component {
    state = {
      regions: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getRegions = () =>
      Get(`/regions`, this.getRegionsSuccess, this.getRegionsError, this.load);
    getRegionsSuccess = (payload) => {
      let tmp = [];
      payload.map((item) => {
        let data = { ...item, label: item.name };
        tmp.push(data);
      });
      this.setState({ regions: tmp });
    };
    getRegionsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            regions={this.state.regions}
            getRegions={this.getRegions}
            onLoadRegions={this.state.loading}
          />
        </>
      );
    };
  }
  return RegionWrappedComponent;
};

export default RegionHOC;
