import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import { QRCode } from "react-qrcode-logo";
import Dialog from "@material-ui/core/Dialog";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import AtlasCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import EventCheckout from "./components/EventCheckout";
import ModalDialog from "components/Modal/ModalDialog";
import InformativeModal from "components/Modal/informative";
import AtlasCalendar from "components/Calendar/CompanyEvent";
import AtlasEventDetailsOverlay from "components/Modal/cal_events";
import AtlasConfirmationModal from "components/Modal/confirmation";
import { CalendarTabs } from "./components/tabs";

import { eventListKeys, birthdayListKeys } from "./assets";
import { initGA } from "utils/ga";
import CalendarHOC from "./actions";
import EventsHOC from "./actions/events";
import BirthdaysHOC from "./actions/birthday";
import VersionCheckHOC from "actions/versionCheck";
import IQILogo from "assets/images/iqi_logo_only.png";
import TicketJIQI from "assets/images/ticket_jiqi.png";

import "./index.scss";
import "./list.scss";

const tabParams = (role) => [
  {
    label: "All",
    value: "all",
    style: { flexGrow: 0 },
    checkbox_options: [
      {
        id: 0,
        value: 0,
        label: "Events",
      },
      {
        id: 3,
        value: 3,
        label: "Paid Events",
      },
      {
        id: 1,
        value: 1,
        label: "Trainings",
      },
      {
        id: 4,
        value: 4,
        label: "Paid Training",
      },
      ...(role !== "Super Admin" && role !== "Admin"
        ? [
            {
              id: 2,
              value: 2,
              label: "Team Birthday",
            },
          ]
        : []),
    ],
  },
  ...(role !== "Super Admin" && role !== "Admin"
    ? [
        {
          label: "My Registered Events",
          value: "registered_events",
          style: { flexGrow: 1 },
        },
      ]
    : []),
];

const handleUndefinedArray = (calendarData, start_date_time, end_date_time) => {
  if (calendarData[Moment(start_date_time).month()] === undefined) {
    calendarData[Moment(start_date_time).month()] = [];
  }
  if (calendarData[Moment(start_date_time).month()][Moment(start_date_time).date()] === undefined) {
    calendarData[Moment(start_date_time).month()][Moment(start_date_time).date()] = [];
  }
  if (calendarData[Moment(end_date_time).month()] === undefined) {
    calendarData[Moment(end_date_time).month()] = [];
  }
  if (calendarData[Moment(end_date_time).month()][Moment(end_date_time).date()] === undefined) {
    calendarData[Moment(end_date_time).month()][Moment(end_date_time).date()] = [];
  }
};

class CompanyEvents extends Component {
  state = {
    selectedTab: "all",
    // 0. Events, 1. Trainings, 2. Team Birthday
    eventFilter:
      ["Super Admin", "Admin"].indexOf(
        window.location.href.includes("/admin-impersonate")
          ? this.props.data.currentSignInProfileReducer.role
          : this.props.data.profileReducer.role,
      ) === -1
        ? [0, 1, 2, 3, 4]
        : [0, 1, 3, 4],
    listview: false,
    toJoinEventID: 0,
    showConfirmationModal: false,
    showPaymentStatusModal: false,
    paymentStatus: "",
    filter: "",
  };

  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    let nonAdminRoleCheck =
      ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) === -1;
    this.props.getEvents();
    this.props.getBranchRegions();
    this.props.getDeliveryBranches();
    if (
      tmpProfileReducer.role !== "Super Admin" &&
      tmpProfileReducer.role !== "Admin"
    ) {
      this.props.getTeamMemberBirthday();
    }

    if (this.props.isBirthday && nonAdminRoleCheck) {
      initGA("/dashboard/iqi-calendar/birthday");
      this.setState({
        eventFilter: [2],
      });
    } else if (_.includes(this.props.location.pathname, "/events")) {
      let query = new URLSearchParams(this.props.location.search);
      let queryValue = query.get("event_id");
      this.setState({ toJoinEventID: queryValue });
      if (_.includes(this.props.location.pathname, "/fail")) {
        this.setState({
          paymentStatus: "FAIL",
          showPaymentStatusModal: true,
        });
      } else if (_.includes(this.props.location.pathname, "/success")) {
        this.setState({
          paymentStatus: "SUCCESS",
          showPaymentStatusModal: true,
        });
      } else if (queryValue && queryValue.length > 0) {
        this.props.getSelectedEvent(queryValue);
      }
    }
    initGA("/dashboard/iqi-calendar");
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { initialEvents, teamMemberBirthday, filterBranchRegion, data } =
      this.props;
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    let tmpStoreForArrangedCalendarData = [];
    let tempEvent = _.cloneDeep(initialEvents);
    let tempBirthday = _.cloneDeep(teamMemberBirthday);
    let eventChangeCheck = prevProps.initialEvents !== initialEvents;
    let birthdayChangeCheck = prevProps.teamMemberBirthday.length !== teamMemberBirthday.length;
    let branchFilterChange = prevProps.filterBranchRegion !== filterBranchRegion;
    let tabChangeCheck = prevState.selectedTab !== this.state.selectedTab;
    let EventFilterCheck = prevState.eventFilter !== this.state.eventFilter;
    let layoutChangeCheck = prevState.listview !== this.state.listview;
    let FilterCheck = prevState.filter !== this.state.filter;

    let nonAdminRoleCheck =
      ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) === -1;
    let onlyBirthdayTabCheck =
      this.state.selectedTab === "all" &&
      this.state.eventFilter.indexOf(2) > -1;

    if (
      eventChangeCheck ||
      birthdayChangeCheck ||
      branchFilterChange ||
      tabChangeCheck ||
      EventFilterCheck ||
      layoutChangeCheck ||
      FilterCheck
    ) {
      this.props.onChangeCalendarHOC(true, "loading");

      if (this.props.filterBranchRegion.value && this.props.filterBranchRegion.value !== "9999") {
        tempEvent = _.filter(tempEvent, (o) => {
          return `${o.branch_region_id}` === this.props.filterBranchRegion.reference_id;
        });
      }

      if (this.state.selectedTab === "registered_events") {
        tempEvent = tempEvent.filter((i) => i.is_registered);
      }

      if (this.state.filter.length > 0) {
        tempEvent = tempEvent.filter((i) =>
          i.title.toLowerCase().includes(this.state.filter.toLowerCase()),
        );
        tempBirthday = tempBirthday.filter((i) =>
          i.title.toLowerCase().includes(this.state.filter.toLowerCase()),
        );
      }

      if (this.state.selectedTab === "all") {
        const tmpEventCheck = (param) =>
          this.state.eventFilter.indexOf(0) > -1
            ? param.type_id === 1 && !param.is_payment_needed
            : "";
        const tmpTrainingCheck = (param) =>
          this.state.eventFilter.indexOf(1) > -1
            ? param.type_id === 2 && !param.is_payment_needed
            : "";
        const tmpPaidEventCheck = (param) =>
          this.state.eventFilter.indexOf(3) > -1
            ? param.type_id === 1 && param.is_payment_needed
            : "";
        const tmpPaidTrainingCheck = (param) =>
          this.state.eventFilter.indexOf(4) > -1
            ? param.type_id === 2 && param.is_payment_needed
            : "";

        tempEvent = tempEvent.filter((item) => {
          return (
            tmpEventCheck(item) ||
            tmpTrainingCheck(item) ||
            tmpPaidEventCheck(item) ||
            tmpPaidTrainingCheck(item)
          );
        });
      }

      if (
        (this.state.selectedTab === "all" &&
          !this.state.eventFilter.indexOf(2) > -1) ||
        this.state.selectedTab === "registered_events"
      ) {
        tmpStoreForArrangedCalendarData = this.rearrangeCalendarData(
          tmpStoreForArrangedCalendarData,
          tempEvent,
        );
      }

      if (nonAdminRoleCheck && onlyBirthdayTabCheck) {
        tmpStoreForArrangedCalendarData = this.rearrangeCalendarData(
          tmpStoreForArrangedCalendarData,
          tempBirthday,
        );
      }

      this.props.onChangeCalendarHOC(
        tmpStoreForArrangedCalendarData,
        "calendarData",
      );
      this.props.onChangeCalendarHOC(false, "loading");
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (nonAdminRoleCheck && this.props.isBirthday) {
        this.setState({
          eventFilter: [2], // Only filter 'Team Birthdays'
        });
      } else {
        this.setState({
          // 0. Events, 1. Trainings, 2. Team Birthday
          eventFilter:
            ["Super Admin", "Admin"].indexOf(
              window.location.href.includes("/admin-impersonate")
                ? this.props.data.currentSignInProfileReducer.role
                : this.props.data.profileReducer.role,
            ) === -1
              ? [0, 1, 2, 3, 4]
              : [0, 1, 3, 4],
        });
      }
    }
  };

  setEventFilter = (param) => {
    let tmpEventFilter = _.cloneDeep(this.state.eventFilter);

    tmpEventFilter.indexOf(param) > -1
      ? _.remove(tmpEventFilter, (o) => o === param)
      : (tmpEventFilter = [...tmpEventFilter, param]);

    this.setState({ eventFilter: tmpEventFilter });
  };

  rearrangeCalendarData = (storeForArrangedCalendarData, initialData) => {
    let tmpStoreForArrangedCalendarData = _.cloneDeep(
      storeForArrangedCalendarData,
    );
    try {
      _.map(initialData, (dataItem) => {
        // Compare Year
        let dayOfStartDate = Moment(dataItem.start_date_time).date();
        let monthOfStartDate = Moment(dataItem.start_date_time).month();
        let dayOfEndDate = Moment(dataItem.end_date_time).date();
        let monthOfEndDate = Moment(dataItem.end_date_time).month();
        let lastDayOfMonth = Moment(dataItem.start_date_time)
          .endOf("month")
          .date();
        handleUndefinedArray(tmpStoreForArrangedCalendarData, dataItem.start_date_time, dataItem.end_date_time );
        for (
          let d = dayOfStartDate, m = monthOfStartDate;
          (m < monthOfEndDate) || (m === monthOfEndDate && d <= dayOfEndDate);
          d++
        ) {
          if (!tmpStoreForArrangedCalendarData[m][d]) {
            tmpStoreForArrangedCalendarData[m][d] = [];
          }
          tmpStoreForArrangedCalendarData[m][d].push(dataItem);
          if (d === lastDayOfMonth) {
            d = 0;
            m++;
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    return tmpStoreForArrangedCalendarData;
  };

  toggleView = () => {
    this.setState((prevState) => {
      return {
        listview: !prevState.listview,
      };
    });
  };

  toggleConfirmationModal = (param) =>
    this.setState({ showConfirmationModal: param });

  joinSelectedEvent = (data) => {
    if (this.props.selected_data_original.is_payment_needed) {
      this.setState({ toJoinEventID: data.id }, () =>
        this.props.onChangeCompanyEventsHOC(true, "showEventCheckoutDialog"),
      );
    } else {
      this.setState({
        toJoinEventID: data.id,
        showConfirmationModal: true,
      });
    }
  };

  confirmedJoin = () =>
    this.setState({ showConfirmationModal: false }, () =>
      this.props.joinSelectedEvent(this.state.toJoinEventID),
    );

  renderEventDetailsOverlay = () => (
    <>
      <AtlasEventDetailsOverlay
        data={this.props.data}
        onLoadEvents={this.props.onLoadEvents}
        selectedData={this.props.selectedData}
        listKeys={
          this.props.selectedData["Type"] === "birthday"
            ? birthdayListKeys
            : eventListKeys(this.props.selected_data_original.training_type_id)
        }
        placeholderImage={this.props.defaultModalImage}
        showCheckInDialog={this.props.showCheckInDialog}
        selected_event_original={this.props.selected_data_original}
        onClickCheckIn={(param) => {
          this.props.onChangeCompanyEventsHOC(param, "selectedTicketType");
          this.props.onChangeCompanyEventsHOC(true, "showCheckInDialog");
        }}
        onClickJoinEvent={this.joinSelectedEvent}
        onClickCloseOverlay={() =>
          this.props.onToggleEventDetailsOverlay(false)
        }
      />
      <AtlasConfirmationModal
        title={this.props.selected_data_original.name}
        mode={"alert"}
        open={this.state.showConfirmationModal}
        loading={this.props.onLoadEvents}
        message={`Are you sure you want to register the event?`}
        positiveAction={() => this.confirmedJoin()}
        negativeAction={() => this.toggleConfirmationModal(false)}
      />
    </>
  );

  renderViewType = () => (
    <div className="at-calendar__toggle">
      <button
        className={`btn-float ${!this.state.listview ? "btn-float-selected" : ""}`}
        onClick={() => this.setState({ listview: false })}
      >
        <AtlasIcon
          svgHref="atlas-element-3"
          style={{ width: 16, height: 16, marginRight: 10, fill: "#374151" }}
        />
        Calendar
      </button>
      <button
        className={`btn-float ${this.state.listview ? "btn-float-selected" : ""}`}
        onClick={() => this.setState({ listview: true })}
      >
        <AtlasIcon
          svgHref="atlas-menu-1"
          style={{ width: 16, height: 16, marginRight: 10, fill: "#374151" }}
        />
        Timeline
      </button>
    </div>
  );

  renderEventDetailsDialog = () => (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={this.props.showDetailsDialog}
    >
      {this.renderEventDetailsOverlay()}
    </Dialog>
  );

  renderCardContent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <div className="at-company__affairs-card">
          <CalendarTabs
            tabLibrary={tabParams(role)}
            selectedTab={this.state.selectedTab}
            eventFilter={this.state.eventFilter}
            branchRegions={this.props.branchRegions}
            filterBranchRegion={this.props.filterBranchRegion}
            setEventFilter={this.setEventFilter}
            setTab={(param) =>
              this.setState({
                selectedTab: param,
                eventFilter:
                  param === "all"
                    ? ["Super Admin", "Admin"].indexOf(
                        window.location.href.includes("/admin-impersonate")
                          ? this.props.data.currentSignInProfileReducer.role
                          : this.props.data.profileReducer.role,
                      ) === -1
                      ? [0, 1, 2, 3, 4]
                      : [0, 1, 3, 4]
                    : [],
              })
            }
            onChangeCalendarHOC={this.props.onChangeCalendarHOC}
          />
          <AtlasCalendar
            data={this.props.data}
            filter={this.state.filter}
            currentDisplayMonth={this.props.currentDisplayMonth}
            storeForArrangedEventData={this.props.calendarData}
            showDetailsDialog={this.props.showDetailsDialog}
            listview={this.state.listview}
            renderViewType={this.renderViewType}
            onToggleSearch={(val) => this.setState({ filter: val })}
            onChangeCalendarHOC={this.props.onChangeCalendarHOC}
            onClickEventDetails={(data) => {
              if (data.type === "birthday") {
                this.props.onClickBirthdayDetails(data);
              } else {
                this.props.getSelectedEvent(data.id);
              }
            }}
            repeat={false}
          />
          {this.renderEventDetailsDialog()}
        </div>
      </>
    );
  };

  render = () => {
    const { display_name, email, full_name, referrer_code } =
      window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    return (
      <>
        {!this.props.isBirthday && (
          <ModuleHeader title={"IQI Calendar"} atlasIcon={"atlas-calendar"} />
        )}
        {this.props.fromTeam && this.renderCardContent()}
        {!this.props.fromTeam && (
          <AtlasCard
            containerStyle={{ overflow: "initial" }}
            cardContent={this.renderCardContent()}
          />
        )}
        {this.props.showEventCheckoutDialog && (
          <EventCheckout
            data={this.props.data}
            deliveryBranches={this.props.deliveryBranches}
            onLoadEvents={this.props.onLoadEvents}
            paymentReference={this.props.paymentReference}
            showPaymentMethod={this.props.showPaymentMethod}
            showErrorMessage={this.props.showErrorMessage}
            errorPaymentMessage={this.props.errorPaymentMessage}
            selected_event_original={this.props.selected_data_original}
            savePaymentApproval={this.props.savePaymentApproval}
            updatePaymentApproval={this.props.updatePaymentApproval}
            joinSelectedEvent={this.props.joinSelectedEvent}
            onClickClose={() => {
              this.props.onChangeCompanyEventsHOC(
                false,
                "showEventCheckoutDialog",
              );
              this.props.onChangeCompanyEventsHOC(false, "showErrorMessage");
              this.props.onChangeCompanyEventsHOC(false, "showPaymentMethod");
            }}
          />
        )}
        {this.props.showCheckInDialog && (
          <ModalDialog
            title={"Event Ticket"}
            contentClasses={{ root: "at-calendar__ticket-qr__modal" }}
            onClose={() => {
              this.props.onChangeCompanyEventsHOC({}, "selectedTicketType");
              this.props.onChangeCompanyEventsHOC(false, "showCheckInDialog");
              this.props.getSelectedEvent(this.props.selected_data_original.id);
            }}
            children={
              <div className="at-calendar__ticket-qr">
                <QRCode
                  size={200}
                  logoWidth={50}
                  logoHeight={50}
                  logoImage={IQILogo}
                  value={JSON.stringify({
                    email: email,
                    display_name: display_name,
                    referrer_code: referrer_code,
                    ticket_id: this.props.selectedTicketType.ticket_type_id,
                  })}
                />
                <p>{this.props.selectedTicketType?.title}</p>
                <div className="at-calendar__ticket-qr__footer">
                  <img src={TicketJIQI} alt="ticket_jiqi" />
                  <div className="at-calendar__ticket-qr__footer-bg" />
                </div>
              </div>
            }
          />
        )}
        <InformativeModal
          open={this.props.showRegistrationSuccess}
          loading={this.props.onLoadCalendar}
          message={
            <>
              <p>Hi {full_name}, your registration is in process.</p>
              <p>
                Our team is checking the payment and will confirm your
                registration as soon as possible.
              </p>
            </>
          }
          renderIcon={
            <AiOutlineCheckCircle
              style={{ width: 120, color: "#36cc71", marginBottom: 10 }}
            />
          }
          buttonOnClick={() =>
            this.props.onChangeCompanyEventsHOC(
              false,
              "showRegistrationSuccess",
            )
          }
        />
        <InformativeModal
          open={this.state.showPaymentStatusModal}
          loading={this.props.onLoadCalendar}
          message={
            this.state.paymentStatus === "SUCCESS" ? (
              <>
                <p className="fw-600">Congratulations!</p>
                <br />
                <p>
                  You have successfully registered for the event. We can't wait
                  to see you there!
                </p>
                <br />
                <p>
                  Show your IQI ID through the Atlas app for entry to the event.
                </p>
              </>
            ) : (
              <p>
                The payment was not successful and you have not been registered
                for this event. Please try again.
              </p>
            )
          }
          renderIcon={
            this.state.paymentStatus === "SUCCESS" ? (
              <AiOutlineCheckCircle style={{ width: 120, color: "#36cc71" }} />
            ) : (
              <AiOutlineCloseCircle style={{ width: 120, color: "red" }} />
            )
          }
          buttonOnClick={() =>
            this.setState({ showPaymentStatusModal: false }, () =>
              this.props.getSelectedEvent(this.state.toJoinEventID),
            )
          }
        />
        {(this.props.onLoadEvents ||
          this.props.onLoadBirthday ||
          this.props.onLoadCalendar) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  CalendarHOC,
  EventsHOC,
  BirthdaysHOC,
  VersionCheckHOC,
)(CompanyEvents);
