import React from "react";
import _ from "lodash";

import defaultImage from "assets/images/iqi_logo_only.png";

import isEmptyValue from "utils/isEmpty";
import "../../index.scss";

const CustomCard = ({ data, onClickEdit }) => {
  const { ticket_pricings = [] } = !isEmptyValue(data.attached_product)
    ? data.attached_product
    : {};
  const ticketPrices = () => {
    let pricString = "";
    let tempPricing = _.filter(ticket_pricings, (item) => item.still_selling);

    tempPricing.map((item, index) => {
      const price = !isEmptyValue(item.price) ? item.price.toFixed(2) : "0.00";
      pricString += `RM ${price}${index !== tempPricing.length - 1 ? ", " : " "}`;
    });
    return pricString;
  };

  return (
    <div className={`at-table_card-item`} onClick={() => onClickEdit(data)}>
      <img
        className={"at-table_card-image"}
        src={data.photo_url || defaultImage}
      />
      <div className="at-table_card-details">
        <h5 className={"at-table_card-title"}>{`${data.name}`}</h5>
        <p className={"at-table_card-description"}>{data.description}</p>
        <p className={"at-table_card-price"}>
          {data.type_id === 3
            ? ticketPrices()
            : data.has_variants
              ? `RM ${data.product_variants[0].price.toFixed(2)} - RM ${data.product_variants[data.product_variants.length - 1].price.toFixed(2)}`
              : `RM ${data.price.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
};

export default CustomCard;
