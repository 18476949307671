import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/delete";

import WithSessions from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import { ColumnData } from "./assets";

import { initGA } from "utils/ga";

class Sessions extends Component {
  componentDidMount = () => {
    this.props.getSessions(1, "");
    initGA("/dashboard/admin/sessions");
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        open={this.props.showConfirmationModal}
        loading={this.props.onLoadSessions}
        title={"Delete Selected Sessions"}
        message={"Are you sure you want to delete this session?"}
        positiveAction={() =>
          this.props.deleteSelectedSessionIds(this.props.selectedSessions)
        }
        negativeAction={() =>
          this.props.onChangeSessionHOC(false, "showConfirmationModal")
        }
      />
    );
  };

  render = () => {
    const {
      sessions,
      searchParams,
      selectedSessions,
      sessionsPages,
      selectAllSessionIdLength,
      getSessions,
      onChangeSessionHOC,
    } = this.props;
    return (
      <>
        <ModuleHeader title={"Sessions"} atlasIcon={"atlas-sessions"} />
        <CustomCard
          cardContent={
            <AtPaginatedTable
              columns={ColumnData({ ...this.props })}
              renderCheckBoxActions={() => (
                <div className="at-table--checkbox-actions">
                  <div
                    style={{ padding: 2 }}
                    className="at-mobile-view__controller mb-1 mr-1"
                  >
                    <CustomCheckbox
                      contentStyle={{ fontWeight: 500 }}
                      labelClassname={"at-table-tab-checkbox_responsive"}
                      content={
                        selectedSessions.length > 0 &&
                        selectedSessions.length == selectAllSessionIdLength
                          ? "Deselect All"
                          : "Select All"
                      }
                      checked={
                        selectedSessions.length > 0 &&
                        selectedSessions.length == selectAllSessionIdLength
                      }
                      onChangeCheckboxValue={(e) =>
                        this.props.onClickSelectAll()
                      }
                    />
                  </div>
                  <CustomButton
                    disabled={selectedSessions.length < 1}
                    className={
                      "btn-new-sm btn-new--outline-secondary mb-1 mr-1"
                    }
                    style={{
                      fontSize: 14,
                      padding: "6px 10px",
                      color: "#F04438",
                    }}
                    onClick={() =>
                      onChangeSessionHOC(true, "showConfirmationModal")
                    }
                    children={"Delete Selected Sessions"}
                  />
                </div>
              )}
              rowData={sessions.data ? sessions.data : []}
              meta={sessions.meta && sessions.meta}
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeSessionHOC(val, "searchParams")
              }
              getListAPI={getSessions}
              totalPages={sessionsPages}
            />
          }
        />
        {this.renderConfirmationModal()}
        {this.props.onLoadSessions && <LoadingModal />}
      </>
    );
  };
}

export default compose(WithSessions, VersionCheckHOC)(Sessions);
