import React, { useState, useEffect } from "react";
import _ from "lodash";

import AtlasButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";
import AtPaginatedTable from "components/NewPaginatedTable";

import BulkUpdateHOC from "../actions/bulkUpdate";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Present", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Reset", colorName: "Yellow" },
  { value: "Absent", colorName: "Red" },
];

const ColumnData = ({
  selectedAgent,
  trainingParticipants,

  onClickSelectAll,
  onClickCheckBox,
}) => [
  {
    header: () => (
      <span className="at-table-head-checkbox_responsive">
        <CustomCheckbox
          disabled={trainingParticipants.data.length === 0}
          checked={
            trainingParticipants.data.length > 0 &&
            selectedAgent.length === trainingParticipants.data.length
          }
          onChangeCheckboxValue={() => onClickSelectAll()}
        />
      </span>
    ),
    renderColumn: (rowData) => (
      <CustomCheckbox
        checked={_.includes(selectedAgent, rowData.user_id)}
        onChangeCheckboxValue={() => onClickCheckBox(rowData.user_id)}
      />
    ),
    columnStyle: { width: "10%", gridColumn: "1/-1" },
  },
  { header: "Agent", accessor: "user_name" },
  { header: "Email", accessor: "email" },
  { header: "Mobile contact", accessor: "mobile_contact_number" },
  {
    header: "Attendance Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.attendance_status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.attendance_status}
        </div>
      );
    },
  },
];

const BulkUpdateContent = ({
  bulkUpdate,
  searchParams,
  onCloseModal,
  onLoadBulkUpdate,
  publishedTrainings,
  onChangeBulkHOC,
  getEventParticipants,
  getPublishedTrainings,
  trainingParticipants,
  trainingParticipantsPages,
}) => {
  const [examStatus, toggleExamStatus] = useState("");
  const [selectedEvent, onChangeEvent] = useState(null);
  const [selectedAgent, onCheckAgent] = useState([]);

  useEffect(() => {
    onCheckAgent([]);
    toggleExamStatus("");
    !selectedEvent && getPublishedTrainings();
    selectedEvent && getEventParticipants(selectedEvent.id, 1, "");
  }, [selectedEvent]);

  const onClickCheckBox = (id) => {
    let tmp = _.cloneDeep(selectedAgent);
    if (selectedAgent.indexOf(id) > -1) {
      tmp.splice(selectedAgent.indexOf(id), 1);
    } else {
      tmp.push(id);
    }
    onCheckAgent(tmp);
  };

  const onClickSelectAll = () => {
    if (selectedAgent.length > 0) {
      onCheckAgent([]);
    } else {
      let tmp = [];
      trainingParticipants.data.map((item) => tmp.push(item.user_id));
      onCheckAgent(tmp);
    }
  };

  return (
    <>
      <h2 className="at-form-input__title">Training Event</h2>
      <div style={{ maxWidth: 400, marginBottom: 20 }}>
        <CustomTypeahead
          typeaheadId={"training"}
          options={publishedTrainings ? publishedTrainings : []}
          selectedValue={selectedEvent ? [selectedEvent] : []}
          labelKey={"name"}
          filterBy={["name"]}
          onSelect={(val) => val && val.length > 0 && onChangeEvent(val[0])}
          childrenHead={(rowItem) => <p>{rowItem.name}</p>}
          highlighterData={[
            (option) => option.end_date_time,
            <br />,
            (option) => `Event description: ${option.description}`,
          ]}
        />
      </div>
      <hr />
      <div className="row mb-10">
        {searchParams &&
          searchParams.map((item, index) => {
            return (
              <div key={item.value} className={`col-md-6`}>
                <label>{item.label}</label>
                <AtInput
                  value={item.param}
                  onChangeValue={(value) => {
                    let tmp = _.cloneDeep(searchParams);
                    tmp[index].param = value;
                    onChangeBulkHOC(tmp, "searchParams");
                  }}
                />
              </div>
            );
          })}
      </div>
      <div className="d-flex" style={{ marginBottom: 15 }}>
        <button
          className="btn-new btn-new--secondary"
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            getEventParticipants(selectedEvent.id, 1, tmp);
          }}
        >
          Search
        </button>
        <button
          className="btn-new btn-new--danger"
          style={{ marginLeft: 10 }}
          onClick={() => {
            let tmp = _.cloneDeep(searchParams);
            tmp.map((item) => {
              item.param = "";
            });
            onChangeBulkHOC(tmp, "searchParams");
            getEventParticipants(selectedEvent.id, 1, "");
          }}
        >
          Reset
        </button>
      </div>
      <label className={"mt-10"}>Exam status</label>
      <AtlasRadioGroup
        horizontal={true}
        checkedValue={examStatus}
        containerClassName={"w-100"}
        selectedRadioValue={(val) => toggleExamStatus(val)}
        options={[
          { value: "Pass", label: "Pass" },
          { value: "Fail", label: "Fail" },
        ]}
      />
      <AtPaginatedTable
        columns={ColumnData({
          selectedAgent,
          trainingParticipants,
          onClickSelectAll,
          onClickCheckBox,
        })}
        searchParams={[]}
        onChangeSearchParams={() => {}}
        rowData={trainingParticipants.data ? trainingParticipants.data : []}
        meta={trainingParticipants.meta ? trainingParticipants.meta : null}
        getListAPI={(page, search) =>
          getEventParticipants(selectedEvent.id, page, search)
        }
        totalPages={trainingParticipantsPages}
      />
      <div className="d-flex mt-20 mb-20">
        <AtlasButton
          disabled={examStatus === "" || !selectedEvent}
          className="btn-new btn-new--success"
          children={"Update"}
          onClick={() =>
            bulkUpdate({
              event_id: selectedEvent.id,
              user_ids: selectedAgent,
              status_id: examStatus === "Pass" ? 1 : 2,
            })
          }
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onCloseModal()}
        >
          Cancel
        </button>
      </div>
      {onLoadBulkUpdate && <LoadingModal />}
    </>
  );
};

export default BulkUpdateHOC(BulkUpdateContent);
