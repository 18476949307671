import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { storeSelectedAgent } from "actions/sales";

let tmpSearchResult = [];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    constructor(p) {
      super();
      this.state = {
        loading: false,

        refs: {},
        currentSearchId: null,
        searchIndex: 0,
        searchResult: [],
        infoboxExpand: false,
        hideAgentsWithoutSales: false,
        selectedStartDate: new Date(new Date().getFullYear(), 0, 1),
        selectedEndDate: new Date(),
        searchAgent: "",
        title: "",
        adminView: false,
        hierarchyData: [],
        filter: ["Projects", "Sales", "Rent"],

        initialExpandedHierarchy: [],
        initialCollapsedHierarchy: [],
        expandedsWithoutSalesHierarchy: [],
        collapsesWithoutSalesHierarchy: [],

        expandMode: "expand",
        searchParams: [
          {
            label: "Team Sales From",
            value: "start_date",
            type: "date",
            param: "",
          },
          {
            label: "Team Sales To",
            value: "end_date",
            type: "date",
            param: "",
          },
        ],
      };
      this.debounceService = _.debounce(this.debounceService, 200);
    }

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    getHierarchy = (id, search) => {
      let query = search.replace(/q\[(.*?)\]/g, "$1");

      Get(
        `/connector/users/date_hierarchy?${query}identity_id=${id}`,
        this.getHierarchySuccess,
        this.getHierarchyError,
        this.load,
      );
    };
    getHierarchySuccess = (payload) => {
      let tempExpandedWithoutSales = _.cloneDeep(payload);
      let tempCollapseWithoutSales = _.cloneDeep(payload);
      let tempPayload = _.cloneDeep(payload);

      tempExpandedWithoutSales.map((item) =>
        this.rearrangeHierarchyChild(item, true, []),
      );
      tempCollapseWithoutSales.map((item) =>
        this.rearrangeHierarchyChild(item, false, []),
      );

      let tempExpandedData = this.processHierarchyData(tempPayload, true);
      let tempCollapseData = this.processHierarchyData(payload, false);
      let temp = this.processHierarchyRefs(payload, {});

      this.setState({
        hierarchyData: tempExpandedData,
        initialExpandedHierarchy: tempExpandedData,
        initialCollapsedHierarchy: tempCollapseData,
        expandedsWithoutSalesHierarchy: tempExpandedWithoutSales,
        collapsesWithoutSalesHierarchy: tempCollapseWithoutSales,
        refs: temp,
      });
    };
    getHierarchyError = (error) => requestError(error);

    processHierarchyRefs = (data, total) => {
      data.map((item) => {
        total[item.agentID] = React.createRef();
        if (item.children.length > 0) {
          this.processHierarchyRefs(item.children, total);
        }
      });
      return total;
    };

    processHierarchyData = (data, val) => {
      const addProps = (param, total) => {
        param.map((item) => {
          item.isExpand = val;
          item.upperLevels = total;

          if (item.children.length > 0) {
            addProps(item.children, [...total, item.agentID]);
          }
        });
      };
      addProps(data, []);

      return data;
    };

    onChangeCheckboxHideAgentsWithoutSales = () => {
      this.setState(
        (prevState) => ({
          hideAgentsWithoutSales: !prevState.hideAgentsWithoutSales,
          expandMode: "expand",
        }),
        () => {
          let tmpHierarchyData = [];

          tmpHierarchyData = this.state.hideAgentsWithoutSales
            ? this.state.expandedsWithoutSalesHierarchy
            : this.state.initialExpandedHierarchy;

          this.setState({
            searchAgent: "",
            currentSearchId: null,
            hierarchyData: tmpHierarchyData,
            searchResult: [],
            refs: this.processHierarchyRefs(tmpHierarchyData, {}),
          });
        },
      );
    };

    rearrangeHierarchyChild = (param, val, total) => {
      param.isExpand = val;
      param.upperLevels = total;

      if (param.children.length > 0) {
        total.push(param.agentID);

        param.children = this.rearrangeHierarchyParent(param.children);
        param.children.map((item) => {
          return this.rearrangeHierarchyChild(item, val, total);
        });
      }
    };

    rearrangeHierarchyParent = (param) => {
      let result = [];
      param.map((item) => {
        if (item.totalGroupSales > 0) {
          return result.push(item);
        }
      });
      return result;
    };

    debounceService = () => {
      let temp =
        this.state.expandMode === "expand"
          ? this.state.initialExpandedHierarchy
          : this.state.initialCollapsedHierarchy;

      let tempWithoutSales =
        this.state.expandMode === "expand"
          ? this.state.expandedsWithoutSalesHierarchy
          : this.state.collapsesWithoutSalesHierarchy;

      if (this.state.searchAgent === "") {
        this.setState({
          searchResult: [],
          currentSearchId: null,
        });
      } else {
        tmpSearchResult = [];
        if (this.state.hideAgentsWithoutSales) {
          this.setHierarchyData(tempWithoutSales, this.state.searchAgent);
        } else {
          this.setHierarchyData(temp, this.state.searchAgent);
        }
      }
    };

    onChangeValueSearch = (val) =>
      this.setState({ searchAgent: val }, () => {
        this.debounceService();
      });

    setHierarchyData = (param, keyword) =>
      this.setState(
        {
          hierarchyData: param,
        },
        () => {
          this.state.hierarchyData.map((item) => {
            this.processSearchData(item, keyword);
          });

          if (tmpSearchResult.length > 0) {
            let tempUpperLevels = this.findUpperLevels(tmpSearchResult[0]);

            this.toggleExpand(tempUpperLevels, true, () => {
              this.setState({
                searchResult: tmpSearchResult || [],
                searchIndex: 0,
                currentSearchId: null,
              });
            });
          }
        },
      );

    processSearchData = (param, keyword) => {
      if (param.agentName) {
        let tmpAgentName = param.agentName.toLowerCase();
        let tmpKeyword = keyword.toLowerCase();
        if (tmpAgentName.indexOf(tmpKeyword) > -1) {
          tmpSearchResult.push(param.agentID);
        }
        if (_.size(param.children) > 0) {
          param.children.map((child) => {
            this.processSearchData(child, keyword);
          });
        }
      }
    };

    findUpperLevels = (id) => {
      let tempRecord = null;

      const findRecord = (data) => {
        data.map((item) => {
          if (id === item.agentID) {
            tempRecord = item.upperLevels;
          } else if (item.children.length > 0) {
            findRecord(item.children);
          }
        });
      };
      findRecord(this.state.hierarchyData);

      return tempRecord;
    };

    toggleExpand = (agentIDArray, val, callback) => {
      let temp = _.cloneDeep(this.state.hierarchyData);
      const findRecord = (data) => {
        data.map((item) => {
          if (agentIDArray.indexOf(item.agentID) !== -1) {
            item.isExpand = val ? val : !item.isExpand;
          }
          if (item.children.length > 0) {
            findRecord(item.children);
          }
        });
      };
      findRecord(temp);

      this.setState({ hierarchyData: temp }, () => {
        callback && callback();
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            refs={this.state.refs}
            isTreeExpand={this.state.isTreeExpand}
            currentSearchId={this.state.currentSearchId}
            searchIndex={this.state.searchIndex}
            onLoadHierarchy={this.state.loading}
            searchResult={this.state.searchResult}
            infoboxExpand={this.state.infoboxExpand}
            hideAgentsWithoutSales={this.state.hideAgentsWithoutSales}
            selectedStartDate={this.state.selectedStartDate}
            selectedEndDate={this.state.selectedEndDate}
            searchAgent={this.state.searchAgent}
            title={this.state.title}
            adminView={this.state.adminView}
            hierarchyData={this.state.hierarchyData}
            filter={this.state.filter}
            searchParams={this.state.searchParams}
            initialExpandedHierarchy={this.state.initialExpandedHierarchy}
            initialCollapsedHierarchy={this.state.initialCollapsedHierarchy}
            expandedsWithoutSalesHierarchy={
              this.state.expandedsWithoutSalesHierarchy
            }
            collapsesWithoutSalesHierarchy={
              this.state.collapsesWithoutSalesHierarchy
            }
            expandMode={this.state.expandMode}
            findUpperLevels={this.findUpperLevels}
            processHierarchyData={this.processHierarchyData}
            getHierarchy={this.getHierarchy}
            onChangeHOC={this.onChangeHOC}
            toggleExpand={this.toggleExpand}
            onChangeValueSearch={this.onChangeValueSearch}
            onChangeCheckboxHideAgentsWithoutSales={
              this.onChangeCheckboxHideAgentsWithoutSales
            }
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeSelectedAgent })(WithHOC);
};

export default HOC;
