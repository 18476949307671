import React, { Component } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomPagination from "./pagination";

import "stylesheets/components/table/index.scss";

class Clients extends Component {
  state = {
    selectPerPageOption: 3,
    totalPaginationCount: 0,
    totalPages: [1],
    clickPageNumber: 1,
    rowData: [],
  };

  componentDidMount = () => this.onHandleRowData();

  componentDidUpdate = (pp) => {
    if (pp.rowData !== this.props.rowData) {
      this.onHandleRowData();
    }
  };

  onHandleRowData = () =>
    this.setState(
      {
        rowData: this.props.rowData,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];

      paginationSections =
        paginationSections % 1 > 0
          ? (Math.floor(paginationSections) + 1).toFixed(0)
          : paginationSections;

      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  renderPaginationText = () => {
    const { rowData } = this.state;

    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowData.length) {
        pageNumTmp = rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <span className={"at-presentation-text"}>
        Showing
        <span> {processFloorPaginationCount(this.state.clickPageNumber)} </span>
        -
        <span>
          {" "}
          {processCeilingPaginationCount(this.state.selectPerPageOption)}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </span>
    );
  };

  renderPagination = () => (
    <CustomPagination
      className={"at-sm-pagination-section"}
      totalPages={this.state.totalPages}
      chosenPageNumber={this.state.selectPerPageOption}
      onClickPageNumber={(val) => this.onClickPageNumber(val)}
      currentPageNumber={this.state.clickPageNumber}
    />
  );

  render = () => {
    const { rowData, context } = this.props;

    return (
      <>
        <div>
          {rowData.length < 1 && (
            <div className={`at-presentation-card__content-null-listing`}>
              <span>No record is available</span>
            </div>
          )}
        </div>
        {rowData.length > 0 && (
          <div className={`at-presentation-card__content-listing`}>
            <div>
              {rowData.map(
                (item, index) =>
                  index + 1 >
                    (this.state.clickPageNumber - 1) *
                      this.state.selectPerPageOption &&
                  index + 1 <=
                    this.state.clickPageNumber *
                      this.state.selectPerPageOption && (
                    <div className="at-presentation-listing-card">
                      <div
                        data-tab={context}
                        className="at-presentation-listing-card-icon"
                      >
                        {context === "Clients" && (
                          <AtlasIcon svgHref={"atlas-frame"} />
                        )}
                        {context === "Properties" &&
                          item.estate_type === "Listing" && (
                            <AtlasIcon svgHref={"atlas-building-3"} />
                          )}
                        {context === "Properties" &&
                          item.estate_type === "Project" && (
                            <AtlasIcon svgHref={"atlas-building-4"} />
                          )}
                      </div>
                      <div className="d-block" style={{ maxWidth: "90%" }}>
                        <p className="at-presentation-listing-card-name">
                          {item[context === "Clients" ? "full_name" : "name"]}
                        </p>
                        <p className="at-presentation-listing-card-info">
                          {context === "Clients" && item["email"]}
                          {context === "Properties" &&
                            `${item["currency"]} ${item["price"]}`}
                        </p>
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div className="at-presentation-card__content-listing-pagination_cont">
              {this.renderPaginationText()}
              {this.renderPagination()}
            </div>
          </div>
        )}
      </>
    );
  };
}

export default Clients;
