import React, { Component } from "react";

class TableHeader extends Component {
  onClickRearrangeData = ({ mode, columnValue }) => {
    this.props.onClickRearrangeData({ mode, columnValue });
  };

  render = () => {
    const { data } = this.props;
    return (
      <ul className="at-table-row at-table-head">
        {data.map((item, index) => {
          return (
            <li
              style={{ cursor: "auto", width: "100%", ...item.containerStyle }}
              key={item.label}
              className={`at-table-row__item at-table__${index} ${item.containerClass}`}
            >
              <p className="at-table-head__title" style={{ ...item.style }}>
                {item.label}
              </p>
            </li>
          );
        })}
      </ul>
    );
  };
}

export default TableHeader;
