import React, { Component } from "react";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put, Delete } from "utils/axios";

const searchParams = [];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showClientsDeleteModal: false,

      showClientsCreateModal: false,
      showClientsEditModal: false,
      showAssignContactModal: false,
      showCreateDropDown: false,
      searchParams: searchParams,

      clients: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedClients: null,
      clientPages: [],

      navPage: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeClientsHOC = (val, context) => this.setState({ [context]: val });

    getClients = (presentationId, page) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;
      this.setState({ navPage: page });

      Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/clients?page=${page} `,
        this.getClientsSuccess,
        this.getClientsError,
        this.load,
      );
    };
    getClientsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      this.setState({
        clients: payload,
        clientPages: tmpTotalPages,
      });
    };
    getClientsError = (error) => requestError(error);

    createClients = (presentationId, dataToSubmit) =>
      Post(
        `/presentations/${presentationId}/clients`,
        dataToSubmit,
        this.createClientsSuccess,
        this.createClientsError,
        this.load,
      );
    createClientsSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getPresentation(currentPage, searchParams);
      this.getClients(payload.presentation_id, this.state.navPage);
      this.props.getSelectedPresentation(payload.presentation_id);
      requestSuccess("Client has been created successfully.");
      this.setState({ showClientsCreateModal: false });
    };
    createClientsError = (error) => requestError(error);

    getSelectedClients = (presentationId, clientId) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/clients/${clientId}`,
        this.getSelectedClientsSuccess,
        this.getSelectedClientsError,
        this.load,
      );
    };
    getSelectedClientsSuccess = (payload) =>
      this.setState({
        showClientsEditModal: true,
        selectedClients: payload,
      });
    getSelectedClientsError = (error) => requestError(error);

    updateClients = (presentationId, dataToSubmit) =>
      Put(
        `/presentations/${presentationId}/clients/${dataToSubmit.id}`,
        dataToSubmit,
        () => this.updateClientsSuccess(dataToSubmit),
        this.updateClientsError,
        this.load,
      );

    updateClientsSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Client details has been updated successfully.");
      this.setState({ showClientsEditModal: false });
      this.props.getPresentation(currentPage, searchParams);
      this.getSelectedClients(payload.presentation_id, payload.id);
      this.getClients(payload.presentation_id, this.state.navPage);
      this.props.getSelectedPresentation(payload.presentation_id);
    };
    updateClientsError = (error) => requestError(error);

    deleteClient = (presentationId, clientId) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Delete(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/clients/${clientId}`,
        () => this.deleteClientSuccess(presentationId),
        this.deleteClientError,
        this.load,
      );
    };
    deleteClientSuccess = (presentationId) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.onChangeClientsHOC(false, "showClientsDeleteModal");

      this.props.getPresentation(currentPage, searchParams);
      this.props.getSelectedPresentation(presentationId);
      requestSuccess("Client has been deleted successfully.");
      this.getClients(presentationId, this.state.navPage);
    };
    deleteClientError = (error) => requestError(error);

    assignContact = (presentationId, contactId) =>
      Post(
        `/presentations/${presentationId}/clients/assign_client?contact_id=${contactId}`,
        "",
        this.assignContactSuccess,
        this.assignContactSuccessError,
        this.load,
      );
    assignContactSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState({ showAssignContactModal: false });
      this.props.getPresentation(currentPage, searchParams);
      this.props.getSelectedPresentation(payload.presentation_id);
      this.getClients(payload.presentation_id, this.state.navPage);
    };
    assignContactSuccessError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            clients={this.state.clients}
            clientPages={this.state.clientPages}
            selectedClients={this.state.selectedClients}
            onLoadClients={this.state.loading}
            showCreateDropDown={this.state.showCreateDropDown}
            showAssignContactModal={this.state.showAssignContactModal}
            showClientsCreateModal={this.state.showClientsCreateModal}
            showClientsEditModal={this.state.showClientsEditModal}
            showClientsDeleteModal={this.state.showClientsDeleteModal}
            getClients={this.getClients}
            createClients={this.createClients}
            updateClients={this.updateClients}
            assignContact={this.assignContact}
            deleteClient={this.deleteClient}
            onChangeClientsHOC={this.onChangeClientsHOC}
            getSelectedClients={this.getSelectedClients}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
