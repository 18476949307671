import React, { useState } from "react";
import { HiDotsHorizontal, HiDotsVertical, HiLockClosed } from "react-icons/hi";

import Tab from "components/Tab";
import AlertBox from "components/AlertBox";
import ModalDialog from "components/Modal/ModalDialog";

const tabOptions = [
  { label: "Google Chrome" },
  { label: "Microsoft Edge" },
  { label: "Firefox" },
  { label: "Safari" },
];

const BlockerTutorial = ({ onClose }) => {
  const [selectedSection, setSelectedSection] = useState("Google Chrome");

  const renderGoogleChromeTutorial = () => (
    <>
      <div className="at-blocker-tutorial__cont">
        <h2 className="at-content-title">Computer (Windows/Mac)</h2>
        <ol>
          <li>On your computer, open Chrome.</li>
          <li>
            At the left of the address bar, click <b>More</b>{" "}
            <HiDotsVertical style={{ width: 20, height: 20, color: "grey" }} />{" "}
            and then <b>Settings</b>.
          </li>
          <li>
            Click <b>Privacy and security</b> and then <b>Site Settings</b>.
          </li>
          <li>
            Click <b>Pop-ups and redirects</b>.
          </li>
          <li>
            Click <b>Add</b> same column with{" "}
            <b>Allowed to send pop-ups and use redirects</b>.
          </li>
          <li>
            In the modal, enter <b>iqiglobal.com</b> in the <b>Site</b> input
            and the click <b>Add</b>.
          </li>
        </ol>
        <h2 className="at-content-title mt-10">Android</h2>
        <ol>
          <li>On your Android phone or tablet, open the Chrome app.</li>
          <li>
            To the right of the address bar, tap <b>More</b>{" "}
            <HiDotsVertical style={{ width: 20, height: 20, color: "grey" }} />{" "}
            and then <b>Settings</b>.
          </li>
          <li>
            Tap <b>Site Settings</b> and then <b>Pop-ups and redirects</b>.
          </li>
          <li>
            Turn off <b>Pop-ups and redirects</b>.
          </li>
        </ol>
        <h2 className="at-content-title mt-10">Iphone & Ipad</h2>
        <ol>
          <li>On your iPhone or iPad, open the Chrome app.</li>
          <li>
            Tap <b>More</b>{" "}
            <HiDotsHorizontal
              style={{ width: 20, height: 20, color: "grey" }}
            />{" "}
            and then <b>Settings</b>.
          </li>
          <li>
            Tap <b>Content Settings </b> and then <b>Block Pop-ups</b>.
          </li>
          <li>
            Turn off <b>Block Pop-ups</b>.
          </li>
        </ol>
        <h2 className="at-content-title">Turn Off Blocker on Atlas</h2>
        <ol>
          <li>
            At the left of the address bar, click{" "}
            <HiLockClosed style={{ width: 20, height: 20, color: "grey" }} />.
          </li>
          <li>
            Turn off <b>Pop-ups and redirects</b>.
          </li>
        </ol>
      </div>
    </>
  );

  const renderMicrosoftEdgeTutorial = () => (
    <>
      <div className="at-blocker-tutorial__cont">
        <h2 className="at-content-title">Mircrosoft Edge</h2>
        <ol>
          <li>
            In Edge, go to <b>Settings and more</b>{" "}
            <HiDotsHorizontal
              style={{ width: 20, height: 20, color: "grey" }}
            />{" "}
            at the top of your browser.
          </li>
          <li>
            Select <b>Settings</b> and then <b>Cookies and site permissions</b>.
          </li>
          <li>
            Under <b>All permissions</b>, select <b>Pop-ups and redirects</b>.
          </li>
          <li>
            Turn off the <b>Block</b> toggle.
          </li>
        </ol>
        <h2 className="at-content-title">Turn Off Blocker on Atlas</h2>
        <ol>
          <li>
            At the left of the address bar, click{" "}
            <HiLockClosed style={{ width: 20, height: 20, color: "grey" }} />.
          </li>
          <li>
            Change the options of <b>Pop-ups and redirects</b> to <b>Allow</b>.
          </li>
        </ol>
      </div>
    </>
  );

  const renderFirefoxTutorial = () => (
    <>
      <div className="at-blocker-tutorial__cont">
        <h2 className="at-content-title">Firefox</h2>
        <ol>
          <li>
            When blocking a pop-up, Firefox displays an information bar and an
            icon
            <img
              src={
                "https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2017-10-19-02-15-43-1de510.png"
              }
              alt={"firefox-icon"}
            />
            in the address bar.
          </li>
          <img
            src={
              "https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2021-08-29-07-02-57-268cef.png"
            }
            alt={"firefox-info-bar"}
          />
          <li>
            Click the <b>Preferences</b> button in the info bar, a menu is
            displayed with the following choices:
          </li>
          <ul>
            <li>Allow pop-ups for atlas.iqiglobal.com</li>
            <li>Manage pop-up settings...</li>
            <li>Don't show this message when pop-ups are blocked</li>
            <li>Show 'pop-up url'</li>
          </ul>
          <li>
            Select <b>Allow pop-ups for atlas.iqiglobal.com</b>, to allow
            pop-ups for Atlas permanently.
          </li>
          <li>
            Select <b>Show 'pop-up url'</b> to open the pop-up.
          </li>
        </ol>
        <h2 className="at-content-title">Turn Off Blocker on Atlas</h2>
        <ol>
          <li>
            At the left of the address bar, click{" "}
            <img
              src={
                "https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2017-10-19-02-15-43-1de510.png"
              }
              alt={"firefox-icon"}
            />
            .
          </li>
          <li>
            Change the options of <b>Open pop-ups windows</b> to <b>Allow</b>.
          </li>
        </ol>
      </div>
    </>
  );

  const renderSafariTutorial = () => (
    <>
      <div className="at-blocker-tutorial__cont">
        <h2 className="at-content-title">Safari</h2>
        <ol>
          <li>In the Safari app on your Mac, go to the website.</li>
          <li>
            Choose <b>Safari</b>, click <b>Preferences...</b>, then click{" "}
            <b>Websites</b>.
          </li>
          <li>
            Click <b>Pop-up Windows</b> on the left.
          </li>
          <li>
            At the bottom of the window, select <b>Allow</b> in the column{" "}
            <b>When visiting other websites</b>.
          </li>
        </ol>
      </div>
    </>
  );

  return (
    <ModalDialog
      title={"Unblock Pop Up Window"}
      children={
        <>
          <AlertBox
            mode={"danger"}
            description={
              "Look like you browser is blocking the pop out window, follow the instruction below to allow the pop out window according to your browser."
            }
          />
          <Tab
            sections={tabOptions}
            selectedSection={selectedSection}
            onSelectSection={(val) => setSelectedSection(val)}
          />
          {selectedSection === "Google Chrome" && renderGoogleChromeTutorial()}
          {selectedSection === "Microsoft Edge" &&
            renderMicrosoftEdgeTutorial()}
          {selectedSection === "Firefox" && renderFirefoxTutorial()}
          {selectedSection === "Safari" && renderSafariTutorial()}
        </>
      }
      footer={
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Close
        </button>
      }
      onClose={onClose}
    />
  );
};

export default BlockerTutorial;
