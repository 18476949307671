import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";


import IconSprite from "assets/stylesheets/atlasIcon.svg";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

const AtlasIcon = ({
  tooltipClass,
  tooltipPosition,
  tooltipPopperClass,
  tooltipChildren,
  id,
  className,
  svgHref,
  style,
  onClick
}) => {
  return (
    <DarkTooltip
      classes={{
        tooltip: `tooltip-arrow ${tooltipClass || ""} ${tooltipPosition || "right"}`,
        popper: `${tooltipPopperClass || ""}`,
      }}
      placement={tooltipPosition || "right"}
      enterTouchDelay={50}
      title={tooltipChildren || ""}>
      <svg id={id} className={className} style={style} onClick={onClick}>
        <use
          id={id}
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
        />
      </svg>
    </DarkTooltip>
  )
}

export default AtlasIcon;
