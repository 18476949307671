import React, { Component } from "react";
import _ from "lodash";

import { Get, Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const ViewEditPasswordHOC = (WrappedComponent) => {
  class ViewEditPasswordWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,

      newPassword: "",
      passwordError: "",
      password_confirmation: "",
      passwordConfirmationError: "",
    };

    load = (param) => this.setState({ loading: param });
    onChangeEditPasswordHOC = (val, context) =>
      this.setState({ [context]: val });

    onSubmitNewPassword = (val) => {
      let temp = { password: this.state.newPassword };
      Put(
        `/admin/users/${val}/change_password`,
        temp,
        this.onSubmitNewPasswordSuccess,
        this.onSubmitNewPasswordError,
        this.load
      );
    };
    onSubmitNewPasswordSuccess = (payload) => {
      requestSuccess("New password changed successfully! ");
      this.setState({ newPassword: "", password_confirmation: "" });
    };

    onSubmitNewPasswordError = (error) => {
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onSubmitNewPassword={this.onSubmitNewPassword}
            onChangeEditPasswordHOC={this.onChangeEditPasswordHOC}
          />
        </>
      );
    };
  }
  return ViewEditPasswordWrappedComponent;
};

export default ViewEditPasswordHOC;
