import React, { Component } from "react";
import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      visualisations: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getVisualisations = (project_id) =>
      Get(
        `/projects/${project_id}/visualisations`,
        this.getVisualisationsSuccess,
        this.getVisualisationsError,
        this.load,
      );
    getVisualisationsSuccess = (payload) =>
      this.setState({ visualisations: payload.project_visualisations });
    getVisualisationsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            visualisations={this.state.visualisations}
            onLoadVisualisations={this.state.loading}
            getVisualisations={this.getVisualisations}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
