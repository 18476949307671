import React, { useState } from "react";
import { MdFilterList } from "react-icons/md";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import searchParamHandler from "./searchParamHandler";
import TablePagination from "./Pagination";
import TableContent from "./content";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

const PaginatedTable = ({
  rowData,
  sideMenuExpand,
  meta,
  searchParams,
  totalPages,

  onClickRequest,
  onClickDetails,
  onChangeSearchParams,
  getListAPI,
}) => {
  const [showSearchContent, onToggleSearch] = useState(false);
  const [interval, onChangeInterval] = useState(10);

  const renderTableContent = () => {
    if (rowData && rowData.length > 0) {
      return (
        <ul className="at-subsale_listing__container row no-gutters">
          {rowData.map((item) => {
            return (
              <TableContent
                key={item.id}
                rowItem={item}
                onClickRequest={onClickRequest}
                onClickDetails={onClickDetails}
                sideMenuExpand={sideMenuExpand}
              />
            );
          })}
        </ul>
      );
    } else {
      return (
        <EmptyState
          title={`No result found`}
          renderIcon={<AtlasIcon svgHref="atlas-building-3" />}
        />
      );
    }
  };

  const renderPaginationText = () => {
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };
  return (
    <>
      <div className="at-table__options mb-10">
        <div className="my-2">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() => onToggleSearch(!showSearchContent)}
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          <div className="at-table__search-cont at-desktop-view__controller ">
            <TableSearchParams
              mode={"desktop"}
              searchParams={searchParams}
              onChangeSearchParams={onChangeSearchParams}
              getListAPI={(page, search) => {
                onChangeInterval(10);
                if (search) {
                  let tmp = "";
                  searchParams.map((item) => {
                    tmp = searchParamHandler(item)
                      ? tmp + searchParamHandler(item)
                      : tmp;
                  });
                  getListAPI(page, tmp);
                } else {
                  getListAPI(page, search);
                }
              }}
            />
          </div>
        </div>
        <div>{renderTableContent()}</div>
        {rowData && rowData.length > 0 && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {meta && meta.count > 0 && renderPaginationText()}
            <TablePagination
              meta={meta}
              searchParams={searchParams}
              interval={interval}
              onChangeInterval={onChangeInterval}
              getListAPI={getListAPI}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          onToggleSearchModal={() => onToggleSearch(!showSearchContent)}
          onChangeSearchParams={onChangeSearchParams}
          getListAPI={(page, search) => {
            onChangeInterval(10);
            if (search) {
              let tmp = "";
              searchParams.map((item) => {
                tmp = searchParamHandler(item)
                  ? tmp + searchParamHandler(item)
                  : tmp;
              });
              getListAPI(page, tmp);
            } else {
              getListAPI(page, search);
            }
          }}
        />
      )}
    </>
  );
};

export default PaginatedTable;
