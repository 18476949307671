import React, { useState, useCallback } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";

const formData = [
  { label: "Agency Name", value: "name", col: 6, type: "text" },
  {
    label: "Board Registration No.",
    value: "registration_number",
    col: 6,
    type: "text",
  },
  { label: "Address", value: "address", col: 12, type: "textarea" },
  { label: "Phone Number", value: "phone_number", col: 6, type: "text" },
  { label: "Fax No.", value: "fax_number", col: 6, type: "text" },
  { label: "Email", value: "email", col: 12, type: "text" },
];

const CreateForm = ({
  onLoadCoAgency,

  onClose,
  createAgency,
}) => {
  const [createForm, onChangeForm] = useState({
    address: "",
    email: "",
    fax_number: "",
    name: "",
    phone_number: "",
    registration_number: "",
  });

  const onChangeField = useCallback(
    (val, context) => {
      let temp = _.cloneDeep(createForm);
      temp[context] = val;

      onChangeForm(temp);
    },
    [createForm],
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={onClose} />
        <h4 className="at-card__title">New Agency</h4>
      </div>
      <Grid container spacing={16}>
        {formData.map((item) => (
          <Grid item md={item.col} xs={12}>
            <label className="at-form-label">{item.label}</label>
            <CustomFormInput
              type={item.type}
              value={createForm[item.value]}
              onChangeValue={(val) => onChangeField(val, item.value)}
            />
          </Grid>
        ))}
      </Grid>
      <div className="d-flex mt-4">
        <button
          type={"button"}
          className="btn-new btn-new--primary"
          onClick={() => createAgency(createForm)}
        >
          Submit
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      {onLoadCoAgency && <LoadingModal />}
    </>
  );
};

export default CreateForm;
