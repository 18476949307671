import React, { Component } from "react";
import _ from "lodash";
import FileSaver from "file-saver";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";
import { project as ProjectDictionary } from "dictionary/index";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showProjectDetails: false,
      documents: [],
      videos: [],
      visualisations: [],
      selectedProject: null,
    };

    onChangeProjectDetailsHOC = (val, context) =>
      this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    getDocuments = (project_id) =>
      Get(
        `/projects/${project_id}/project_documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) =>
      this.setState({ documents: payload.project_documents });
    getDocumentsError = (error) => requestError(error);

    getVideos = (project_id) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let isAdmin = ["Admin", "Super Admin"].indexOf(role) > -1;
      Get(
        `/projects/${project_id}/project_videos`,
        this.getVideosSuccess,
        this.getVideosError,
        this.load,
      );
    };
    getVideosSuccess = (payload) =>
      this.setState({ videos: payload.project_videos });
    getVideosError = (error) => requestError(error);

    getVisualisations = (project_id) =>
      Get(
        `/projects/${project_id}/visualisations`,
        this.getVisualisationsSuccess,
        this.getVisualisationsError,
        this.load,
      );
    getVisualisationsSuccess = (payload) =>
      this.setState({ visualisations: payload.project_visualisations });
    getVisualisationsError = (error) => requestError(error);

    getSelectedProject = (id) =>
      Get(
        `/projects/${id}`,
        this.getSelectedProjectSuccess,
        this.getSelectedProjectError,
        this.load,
      );
    getSelectedProjectSuccess = (payload) => {
      const { countries } = this.props.data.dictionaryReducer;
      let dataProjectTitle = _.find(ProjectDictionary.title, {
        id: payload.project_title_id,
      });
      let dataProjectType = _.find(ProjectDictionary.type, {
        id: payload.type_id,
      });
      let dataTenureType = _.find(ProjectDictionary.tenure, {
        id: payload.tenure_type_id,
      });
      let dataStatus = _.find(ProjectDictionary.status, {
        id: payload.status_id,
      });
      let dataCountry = _.find(countries, { id: payload.country_id });
      let dataState = _.find(dataCountry ? dataCountry.states : [], {
        id: payload.state_id,
      });

      this.setState({
        selectedProject: {
          ...payload,
          hd_map_description: payload.location,
          project_title: dataProjectTitle ? dataProjectTitle.name : "",
          project_type: dataProjectType ? dataProjectType.name : "",
          tenure_type: dataTenureType ? dataTenureType.name : "",
          status: dataStatus ? dataStatus.name : "",
          country: dataCountry ? dataCountry.name : "",
          state: dataState ? dataState.name : "",
          landing_page: payload.landing_page,
        },
        showProjectDetails: true,
      });
    };
    getSelectedProjectError = (error) => requestError(error);

    onDownloadFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";
      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      return this.setState({ downloading: true }, () => {
        return fetch(
          `${getDomainURL()}/nextcloud/directories/${item.ids[item.ids.length - 1]}?file_path=${item.path}&download=1`,
          { headers },
        )
          .then((response) => {
            return response.blob();
          })
          .then((blobby) => {
            return this.setState({ downloading: false }, () => {
              return FileSaver.saveAs(blobby, item.display_name);
            });
          })
          .catch((error) => {
            requestError("Failed to download file. Please try again later.");
          });
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showProjectDetails={this.state.showProjectDetails}
            onLoadProjectDetails={this.state.loading}
            documents={this.state.documents}
            videos={this.state.videos}
            visualisations={this.state.visualisations}
            selectedProject={this.state.selectedProject}
            getDocuments={this.getDocuments}
            getVideos={this.getVideos}
            getVisualisations={this.getVisualisations}
            getSelectedProject={this.getSelectedProject}
            onDownloadFile={this.onDownloadFile}
            onChangeProjectDetailsHOC={this.onChangeProjectDetailsHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
