import { Avatar } from "@material-ui/core";
import { BsBatteryFull, BsWifi } from "react-icons/bs";
import { FaSignal } from "react-icons/fa";
import { FiArrowLeft, FiMoreVertical } from "react-icons/fi";

const EmailListItem = ({ email }) => {
  return (
    <li className={`list-item${email.opacity || ""}`}>
      <Avatar className={`${email.color}-avatar`}>
        {email.name && email.name.charAt(0).toUpperCase()}
      </Avatar>

      <div className={"email-text"}>
        <div className={"email-header"}>
          <p className={"email-name"}>
            <strong>{email.name}</strong>
          </p>

          <p className={"email-date"}>
            <strong>{email.date}</strong>
          </p>
        </div>

        <p className={"email-subject"}>
          <strong>{email.subject}</strong>
        </p>

        <p className={"email-preview"}>{email.preview}</p>
      </div>
    </li>
  );
};

const Phone = ({ selectedEmailCampaign, values }) => {
  const currentTime = (showAmPm = true) => {
    const date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    minutes = minutes < 10 ? "0" + minutes : minutes;

    if (showAmPm) {
      const ampm = hours >= 12 ? "PM" : "AM";
      const shortHour = hours % 12;

      hours = shortHour ? shortHour : 12;

      return `${hours}:${minutes} ${ampm}`;
    } else {
      return `${hours}:${minutes}`;
    }
  };

  const emails = [
    {
      id: 1,
      color: "blue",
      name:
        values.sender_name ||
        selectedEmailCampaign.sender_name ||
        "Your company",
      subject:
        values.subject || selectedEmailCampaign.subject || "Your subject",
      preview:
        values.preview_text ||
        selectedEmailCampaign.preview_text ||
        "Your preview text",
      date: currentTime(),
    },
    {
      id: 2,
      color: "purple",
      opacity: " opacity-50",
      name: "Sendinblue",
      subject: "Need to increase your open rates?",
      preview:
        'Choose "send at the best time" when scheduling your campaign and be amazed.',
      date: "Oct 19",
    },
    {
      id: 3,
      color: "blue",
      opacity: " opacity-50",
      name: "Sendinblue Academy",
      subject: "Free email marketing course",
      preview: "Learn how to create an email marketing strategy.",
      date: "Sep 6",
    },
  ];

  return (
    <div className={"phone-wrapper"}>
      <div className={"phone-frame"}>
        <div className={"left-buttons"}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={"phone-screen"}>
          <div className={"phone-top"}>
            <div className={"phone-speaker"}></div>
            <div className={"phone-selfie-camera"}></div>
          </div>

          <div className={"status-bar"}>
            <p className={"time"}>{currentTime(false)}</p>

            <div className={"icons"}>
              <FaSignal />
              <BsWifi />
              <BsBatteryFull />
            </div>
          </div>

          <div className={"app-bar"}>
            <FiArrowLeft />
            <p>Inbox</p>
            <FiMoreVertical />
          </div>

          <div className={"inbox"}>
            <ul className={"list"}>
              {emails.map((email) => (
                <EmailListItem key={email.id} email={email} />
              ))}
            </ul>
          </div>
        </div>

        <div className={"right-button"}></div>
      </div>
    </div>
  );
};

export default Phone;
