import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import AtlasTable from "components/NewTable";
import ModuleHeader from "components/ModuleHeader";
import AtlasLoadingModal from "components/LoadingModal";
import ConfirmDeleteModal from "components/Modal/delete";
import EditAdminTutorialForm from "./components/edit";
import NewAdminTutorialForm from "./components/new";

import AdminTutorialHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Title",
    accessor: "title",
    searchFlag: "title",
    columnStyle: { width: "200%" },
  },
  {
    header: "Category",
    accessor: "category",
    searchFlag: "category",
    columnStyle: { width: "70%" },
  },
];

class AdminTutorial extends Component {
  state = {
    toRemoveItemID: "",
  };

  componentDidMount = () => {
    this.props.getTutorials();
    initGA("/dashboard/admin/tutorials");
  };

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Tutorials",
      this.props.data,
    );

    return (
      <>
        <AtlasTable
          className={"mb-100"}
          columns={columnData}
          pagination={true}
          rowData={this.props.tutorials || []}
          actionColumnContent={[
            {
              name: "download",
              onClick: (rowData) => window.open(rowData.document_url, "_blank"),
            },
            {
              name: "edit",
              onShow: () => can_update,
              onClick: (rowData) => this.props.getSelectedTutorial(rowData.id),
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: () => can_destroy,
              onClick: (rowData) => {
                this.props.onChangeAdminHOC(true, "showConfirmDeleteDialog");
                this.setState({ toRemoveItemID: rowData.id });
              },
            },
          ]}
        />
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Tutorials", this.props.data);

    return (
      <>
        <ModuleHeader
          title={"Tutorials"}
          moduleIcon={"icon-tutorials"}
          actionButton={[
            {
              label: "New Tutorial",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () => this.props.onChangeAdminHOC(true, "showNewDialog"),
            },
          ]}
        />
        <AtlasCard
          cardContent={
            <>
              {this.renderCardContent()}
              {this.props.showNewDialog && (
                <NewAdminTutorialForm
                  data={this.props.newTutorial}
                  onChange={this.props.onChangeNewTutorial}
                  onClose={() =>
                    this.props.onChangeAdminHOC(false, "showNewDialog")
                  }
                  tutorial_categories={
                    this.props.data.dictionaryReducer.tutorial_categories
                  }
                  onUploadDocument={this.props.onUploadDocument}
                  document={this.props.uploadedDocument}
                  onSubmit={this.props.createTutorial}
                  onLoadTutorial={this.props.onLoadTutorial}
                />
              )}
              {this.props.showEditTutorialDialog && (
                <EditAdminTutorialForm
                  data={this.props.selectedTutorial}
                  onChange={this.props.onChangeEditTutorial}
                  onClose={() =>
                    this.props.onChangeAdminHOC(false, "showEditTutorialDialog")
                  }
                  tutorial_categories={
                    this.props.data.dictionaryReducer.tutorial_categories
                  }
                  onUploadDocument={this.props.onUploadEditDocument}
                  onDeleteDocument={this.props.onDeleteDocument}
                  document={this.props.uploadedDocument}
                  onSubmit={() =>
                    this.props.updateTutorial(this.props.selectedTutorial.id)
                  }
                  onLoadTutorial={this.props.onLoadTutorial}
                />
              )}
              <ConfirmDeleteModal
                open={this.props.showConfirmDeleteDialog}
                loading={this.props.onLoadTutorial}
                message={"Are you sure to remove the selected tutorial?"}
                positiveAction={() =>
                  this.props.deleteTutorial(this.state.toRemoveItemID)
                }
                negativeAction={() =>
                  this.props.onChangeAdminHOC(false, "showConfirmDeleteDialog")
                }
              />
              {this.props.onLoadTutorial && <AtlasLoadingModal />}
            </>
          }
        />
      </>
    );
  };
}

export default compose(AdminTutorialHOC, VersionCheckHOC)(AdminTutorial);
