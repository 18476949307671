import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import AuthorisationFormManagement from "./AuthorisationFormManagement";
import AppointmentLetterManagement from "./AppointmentLetterManagement";
import ConfirmationFormManagement from "./ConfirmationFormManagement";
import CobrokeLetterManagement from "./CobrokeLetterManagement";
import PaperFormManagement from "./PaperFormManagement";
import SubsalesConfirmationFormsRequest from "../ConfirmationFormsRequest";
import SubsalesAdminsManagement from "../SubsalesAdminManagement";
import "stylesheets/containers/personal/appointment-letter.scss";

class FormsManagement extends Component {
  render = () => {
    return (
      <div className="at-card-container">
        <Switch>
          <Route
            exact
            path={"/dashboard/admin/forms/authorisation-form-management"}
            component={AuthorisationFormManagement}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/appointment-letter-management"}
            component={AppointmentLetterManagement}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/cobroke-letter-management"}
            component={CobrokeLetterManagement}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/confirmation-forms-management"}
            component={ConfirmationFormManagement}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/paper-forms-management"}
            component={PaperFormManagement}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/confirmation-forms-requests"}
            component={SubsalesConfirmationFormsRequest}
          />
          <Route
            exact
            path={"/dashboard/admin/forms/admin-roles-management"}
            component={SubsalesAdminsManagement}
          />

          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/authorisation-form-management"
            }
            component={AuthorisationFormManagement}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/appointment-letter-management"
            }
            component={AppointmentLetterManagement}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/cobroke-letter-management"
            }
            component={CobrokeLetterManagement}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/confirmation-forms-management"
            }
            component={ConfirmationFormManagement}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/paper-forms-management"
            }
            component={PaperFormManagement}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/confirmation-forms-requests"
            }
            component={SubsalesConfirmationFormsRequest}
          />
          <Route
            exact
            path={
              "/admin-impersonate/:profileId/dashboard/admin/forms/admin-roles-management"
            }
            component={SubsalesAdminsManagement}
          />
        </Switch>
      </div>
    );
  };
}

export default withRouter(FormsManagement);
