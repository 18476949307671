import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";
import checkValid from "utils/checkValid";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put } from "utils/axios";
import { refreshToken } from "actions/login";
import { LeadStatus } from "dictionary/lead";
import { storeLastView } from "actions/lastView";
import {
  superAdminSearchParams,
  adminSearchParams,
  searchParams,
} from "../utils";

const LeadsHOC = (WrappedComponent) => {
  class LeadsWrappedComponent extends Component {
    state = {
      leads: {},
      leadsPages: [],
      originalLeads: [],
      checkedArray: [],

      selectedLead: {},
      selectedTab: 1,
      loading: false,
      showBulkDeleteModal: false,
      showBulkUpdateModal: false,
      showQuickUpdateModal: false,

      showCreateModal: false,
      showImportModal: false,
      showDeleteModal: false,
      showAssignDialog: false,
      showFacebookLeadAds: false,
      selectedAssignType: null,

      showUpdateDialog: false,
      searchParams: searchParams,
      toDeleteLeadID: 0,

      leadStatus: [],

      post_wip: false,
      selectedAgentInfo: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeLeadsHOC = (val, context) => this.setState({ [context]: val });

    // index page
    getLeads = (page, search) => {
      let url = "";
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      switch (role) {
        case "Agent":
        case "Personal Assistant":
        case "Associated Agent":
          url = `/leads?${search}page=${page}`;
          break;

        default:
          url = `/admin/leads?${search}page=${page}`;
          break;
      }
      Get(url, this.getLeadsSuccess, this.getLeadsError, this.load);
    };
    getLeadsSuccess = (payload) => {
      let tempPages = [];
      let tmp = [];

      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tempAssignDate = Moment(item.assign_date);
          let tempInquiryDate = Moment(item.inquiry_date);

          tmp.push({
            ...item,
            leads:
              checkValid(item.buyer_name) +
              "\n" +
              checkValid(item.buyer_email) +
              "\n" +
              checkValid(item.buyer_phone_number) +
              "\n",
            creation_date: Moment(item.creation_date)
              ? Moment(item.creation_date).format("DD MMM YYYY")
              : "N/A",
            assign_date: tempAssignDate.isValid()
              ? tempAssignDate.format("DD MMM YYYY")
              : "N/A",
            inquiry_date: tempInquiryDate.isValid()
              ? tempInquiryDate.format("DD MMM YYYY")
              : "N/A",
          });
        });
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        leads: {
          data: tmp,
          meta: payload.meta,
        },
        originalLeads: tmp,
        leadsPages: tempPages,
        checkedArray: [],
      });
    };
    getLeadsError = (error) => requestError(error);

    // get particular lead
    getSelectedLead = (id, mode) => {
      let url = "";
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      switch (role) {
        case "Agent":
        case "Personal Assistant":
        case "Associated Agent":
          url = `/leads/${id}`;
          break;

        default:
          url = `/admin/leads/${id}`;
          break;
      }
      Get(
        url,
        (payload) => this.getSelectedLeadSuccess(payload, mode),
        this.getSelectedLeadError,
        this.load,
      );
    };
    getSelectedLeadSuccess = async (payload, mode) => {
      let tmp = _.cloneDeep(payload);
      let tmpKey = Object.keys(tmp);
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      tmpKey.map((item) => {
        if (["assign_date", "inquiry_date", "close_date"].indexOf(item) > -1) {
          let tempData = Moment(tmp[item]);
          tmp[item] =
            tmp[item] && tempData.isValid()
              ? tempData.format("DD MMM YYYY")
              : "";
        } else if (
          item !== "type_id" &&
          item !== "status_id" &&
          item !== "priority_id"
        ) {
          tmp[item] = tmp[item] || "";
        }
      });

      let tempSelectedStatus = LeadStatus[payload.type_id];
      let tmpStatus = _.map(Object.keys(tempSelectedStatus || {}), (item) => {
        return { label: tempSelectedStatus[item], value: item };
      });

      this.setState({
        selectedLead: tmp,
        ...(payload.user_id === "N/A" && {
          selectedAgentInfo: null,
        }),
        ...(mode === "view" && {
          showUpdateDialog: true,
        }),
        ...(mode === "edit" && {
          showQuickUpdateModal: true,
        }),
        leadStatus: tmpStatus,
      });
    };
    getSelectedLeadError = (error) => requestError(error);

    bulkAssignLead = (dataToSubmit) => {
      if (this.state.post_wip) return false;

      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let isAdmin = ["Admin", "Super Admin"].indexOf(role) > -1;

      this.setState(
        {
          post_wip: true,
        },
        () => {
          Put(
            `${isAdmin ? "/admin" : ""}/leads/bulk_assign`,
            dataToSubmit,
            this.bulkAssignLeadSuccess,
            this.bulkAssignLeadError,
            this.load,
          );
        },
      );
    };
    bulkAssignLeadSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getLeads(currentPage, searchParams);

      this.setState({ post_wip: false });
      requestSuccess("Leads records assigned successfully.");
      this.setState({ showAssignDialog: false });
    };
    bulkAssignLeadError = (error) => {
      this.setState({ post_wip: false });
      requestError(error);
    };

    bulkDeleteLead = (dataToSubmit) =>
      Put(
        `/admin/leads/bulk_delete`,
        dataToSubmit,
        this.bulkDeleteLeadSuccess,
        this.bulkDeleteLeadError,
        this.load,
      );
    bulkDeleteLeadSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getLeads(currentPage, searchParams);

      requestSuccess("Selected Leads have been delete successfully.");
      this.setState({ showBulkDeleteModal: false });
    };
    bulkDeleteLeadError = (error) => requestError(error);

    agentBulkUpdateLead = (dataToSubmit) =>
      Put(
        `/leads/bulk_update`,
        dataToSubmit,
        this.bulkUpdateLeadSuccess,
        this.bulkUpdateLeadError,
        this.load,
      );

    bulkUpdateLead = (dataToSubmit) =>
      Put(
        `/admin/leads/bulk_update`,
        dataToSubmit,
        this.bulkUpdateLeadSuccess,
        this.bulkUpdateLeadError,
        this.load,
      );
    bulkUpdateLeadSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getLeads(currentPage, searchParams);

      requestSuccess("Selected Leads have been updated successfully.");
      this.setState({ showBulkUpdateModal: false });
    };
    bulkUpdateLeadError = (error) => requestError(error);

    getAgentInfo = (id) =>
      Get(
        `/admin/users/${id}`,
        this.getAgentInfoSuccess,
        this.getAgentInfoError,
        this.load,
      );
    getAgentInfoSuccess = (payload) =>
      this.setState({ selectedAgentInfo: payload });
    getAgentInfoError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            leads={this.state.leads}
            selectedTab={this.state.selectedTab}
            selectedAssignType={this.state.selectedAssignType}
            checkedArray={this.state.checkedArray}
            onLoadLeads={this.state.loading}
            showBulkDeleteModal={this.state.showBulkDeleteModal}
            showBulkUpdateModal={this.state.showBulkUpdateModal}
            showQuickUpdateModal={this.state.showQuickUpdateModal}
            showAssignDialog={this.state.showAssignDialog}
            showCreateModal={this.state.showCreateModal}
            showImportModal={this.state.showImportModal}
            showUpdateDialog={this.state.showUpdateDialog}
            showDeleteModal={this.state.showDeleteModal}
            showFacebookLeadAds={this.state.showFacebookLeadAds}
            superAdminSearchParams={superAdminSearchParams}
            adminSearchParams={adminSearchParams}
            searchParams={this.state.searchParams}
            selectedLead={this.state.selectedLead}
            leadsPages={this.state.leadsPages}
            toDeleteLeadID={this.state.toDeleteLeadID}
            selectedAgentInfo={this.state.selectedAgentInfo}
            leadStatus={this.state.leadStatus}
            getLeads={this.getLeads}
            getAgentInfo={this.getAgentInfo}
            bulkUpdateLead={this.bulkUpdateLead}
            bulkDeleteLead={this.bulkDeleteLead}
            bulkAssignLead={this.bulkAssignLead}
            getSelectedLead={this.getSelectedLead}
            onChangeLeadsHOC={this.onChangeLeadsHOC}
            agentBulkUpdateLead={this.agentBulkUpdateLead}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(LeadsWrappedComponent);
};

export default LeadsHOC;
