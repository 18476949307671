import React, { Component } from "react";
import { connect } from "react-redux";

import { Post, Put, Get, Delete, GetFile } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const RenewalDetailsHOC = (WrappedComponent) => {
  class RenewalDetailsWrappedComponent extends Component {
    state = {
      searchParams: [
        {
          label: "Agent Name",
          value: "user_full_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Email",
          value: "user_email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Mobile Number",
          value: "user_mobile_contact_number_cont",
          type: "input",
          param: "",
        },
        {
          label: "Status",
          value: "status_id_eq",
          type: "radio",
          col: 12,
          param: "",
          options: [
            { value: "1", label: "Pending" },
            { value: "2", label: "Processing" },
            { value: "3", label: "Submit to Finance" },
            { value: "4", label: "Payment Made" },
            { value: "5", label: "Board Approved" },
            { value: "6", label: "Tag Arrived" },
            { value: "7", label: "Collected" },
          ],
        },
      ],

      loading: false,
      uploadedDocuments: {
        data: [],
        meta: [],
      },
      message: [],
      totalCPD: 0,
      activities: [],
      selectedApplication: {},

      showGenerateFormD: false,
      showRenewalApplication: false,
      showFinanceConfirmation: false,
      showPaymentConfirmation: false,
      showApproveConfirmation: false,
      showArrivedConfirmation: false,
      showCollectionConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRenewalDetailsHOC = (val, context) =>
      this.setState({ [context]: val });

    getSelectedRenewalApplication = (rea_id, application_id) =>
      Get(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}`,
        this.getSelectedRenewalApplicationSuccess,
        this.getSelectedRenewalApplicationError,
        this.load,
      );
    getSelectedRenewalApplicationSuccess = (payload) => {
      let totalCPDHour = 0;
      if (payload.licence_renewal_details.length > 0) {
        payload.licence_renewal_details.map((item) => {
          totalCPDHour = totalCPDHour + Number(item.cpd_hour);
        });
      }

      this.setState({
        totalCPD: totalCPDHour,
        selectedApplication: payload,
        showRenewalApplication: true,
      });
    };
    getSelectedRenewalApplicationError = (error) => requestError(error);

    updateRenewalApplication = (dataToSubmit) => {
      const { rea_id, application_id } = dataToSubmit;

      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}`,
        {
          status_id: 1,
        },
        this.updateRenewalApplicationSuccess,
        this.updateRenewalApplicationError,
        this.load,
      );
    };
    updateRenewalApplicationSuccess = () =>
      requestSuccess("Rea Renewal application updated");
    updateRenewalApplicationError = (error) => requestError(error);

    markApplicationSubmitFinance = (rea_id, application_id) =>
      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/submit_to_finance`,
        {},
        this.markApplicationSubmitFinanceSuccess,
        this.markApplicationSubmitFinanceError,
        this.load,
      );
    markApplicationSubmitFinanceSuccess = (payload) => {
      this.setState({ showFinanceConfirmation: false });
      this.getSelectedRenewalApplication(
        payload.licence_renewal_id,
        payload.id,
      );
      requestSuccess("Rea Renewal application is submit to finance");
    };
    markApplicationSubmitFinanceError = (error) => requestError(error);

    markAsPaymentMade = (rea_id, application_id) =>
      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/mark_as_payment_made`,
        {},
        this.markAsPaymentMadeSuccess,
        this.markAsPaymentMadeError,
        this.load,
      );
    markAsPaymentMadeSuccess = (payload) => {
      this.setState({ showPaymentConfirmation: false });
      this.getSelectedRenewalApplication(
        payload.licence_renewal_id,
        payload.id,
      );
      requestSuccess("Rea Renewal appliocation payment is made");
    };
    markAsPaymentMadeError = (error) => requestError(error);

    markApplicationApprove = (rea_id, application_id) =>
      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/mark_as_board_approved`,
        {},
        this.markApplicationApproveSuccess,
        this.markApplicationApproveError,
        this.load,
      );
    markApplicationApproveSuccess = (payload) => {
      this.setState({ showApproveConfirmation: false });
      this.getSelectedRenewalApplication(
        payload.licence_renewal_id,
        payload.id,
      );
      requestSuccess("Rea Renewal application is approve");
    };
    markApplicationApproveError = (error) => requestError(error);

    markApplicationTagCollect = (rea_id, application_id) =>
      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/mark_as_collected`,
        {},
        this.markApplicationTagCollectSuccess,
        this.markApplicationTagCollectError,
        this.load,
      );
    markApplicationTagCollectSuccess = (payload) => {
      this.setState({ showCollectionConfirmation: false });
      this.getSelectedRenewalApplication(
        payload.licence_renewal_id,
        payload.id,
      );
      requestSuccess("Rea Renewal Tag is Collected");
    };
    markApplicationTagCollectError = (error) => requestError(error);

    markApplicationAsArrived = (rea_id, application_id) =>
      Put(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/mark_as_arrived`,
        {},
        this.markApplicationAsArrivedSuccess,
        this.markApplicationAsArrivedError,
        this.load,
      );
    markApplicationAsArrivedSuccess = (payload) => {
      this.setState({ showArrivedConfirmation: false });
      this.getSelectedRenewalApplication(
        payload.licence_renewal_id,
        payload.id,
      );
      requestSuccess("Rea Renewal badge is arrived");
    };
    markApplicationAsArrivedError = (error) => requestError(error);

    getUploadedDocument = (rea_id, id) =>
      Get(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${id}/licence_renewal_documents`,
        this.getUploadedDocumentSuccess,
        this.getUploadedDocumentError,
        this.load,
      );
    getUploadedDocumentSuccess = (payload) =>
      this.setState({ uploadedDocuments: payload });
    getUploadedDocumentError = (error) => requestError(error);

    createUploadDocument = (dataToSubmit) =>
      Post(
        `/admin/licence_renewals/${dataToSubmit.rea_id}/licence_renewal_applicants/${dataToSubmit.id}/licence_renewal_documents`,
        dataToSubmit,
        this.createUploadDocumentSuccess,
        this.createUploadDocumentError,
        this.load,
      );
    createUploadDocumentSuccess = () => {
      this.getUploadedDocument(
        this.props.selectedRenewal.id,
        this.state.selectedApplication.id,
      );
      requestSuccess("Document uploaded");
    };
    createUploadDocumentError = (error) => requestError(error);

    removeUploadedDocument = (rea_id, application_id, document_id) =>
      Delete(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${application_id}/licence_renewal_documents/${document_id}`,
        this.removeUploadedDocumentSuccess,
        this.removeUploadedDocumentError,
        this.load,
      );
    removeUploadedDocumentSuccess = () => {
      this.getUploadedDocument(
        this.props.selectedRenewal.id,
        this.state.selectedApplication.id,
      );
      requestSuccess("Document Deleted");
    };
    removeUploadedDocumentError = (error) => requestError(error);

    getActivity = (id) =>
      Get(
        `/licence_renewal_applicants/${id}/activities`,
        this.getActivitySuccess,
        this.getActivityError,
        this.load,
      );
    getActivitySuccess = (payload) => this.setState({ activities: payload });
    getActivityError = (error) => requestError(error);

    getMessage = (id) =>
      Get(
        `/licence_renewal_applicants/${id}/messages`,
        this.getMessageSuccess,
        this.getMessageError,
        this.load,
      );
    getMessageSuccess = (payload) => this.setState({ message: payload });
    getMessageError = (error) => requestError(error);

    putMessage = (dataToSubmit) =>
      Post(
        `/licence_renewal_applicants/${dataToSubmit.id}/messages`,
        dataToSubmit,
        () => this.putMessageSuccess(dataToSubmit.id),
        this.putMessageError,
        this.load,
      );
    putMessageSuccess = (id) => {
      requestSuccess("Message Sent");
      this.getMessage(id);
    };
    putMessageError = (error) => requestError(error);

    generateFormD = (rea_id, id) =>
      Post(
        `/admin/licence_renewals/${rea_id}/licence_renewal_applicants/${id}/generate_form_d`,
        {},
        (payload) => this.generateFormDSuccess(payload, rea_id, id),
        this.generateFormDError,
        this.load,
      );
    generateFormDSuccess = (payload, rea_id, id) => {
      this.getUploadedDocument(rea_id, id);
      this.setState({ showGenerateFormD: false });
      requestSuccess(payload.message);
    };
    generateFormDError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            message={this.state.message}
            totalCPD={this.state.totalCPD}
            activities={this.state.activities}
            searchParams={this.state.searchParams}
            onLoadRenewalApplication={this.state.loading}
            uploadedDocuments={this.state.uploadedDocuments}
            selectedApplication={this.state.selectedApplication}
            showGenerateFormD={this.state.showGenerateFormD}
            showRenewalApplication={this.state.showRenewalApplication}
            showFinanceConfirmation={this.state.showFinanceConfirmation}
            showPaymentConfirmation={this.state.showPaymentConfirmation}
            showApproveConfirmation={this.state.showApproveConfirmation}
            showArrivedConfirmation={this.state.showArrivedConfirmation}
            showCollectionConfirmation={this.state.showCollectionConfirmation}
            getMessage={this.getMessage}
            putMessage={this.putMessage}
            getActivity={this.getActivity}
            generateFormD={this.generateFormD}
            markAsPaymentMade={this.markAsPaymentMade}
            markApplicationAsArrived={this.markApplicationAsArrived}
            getUploadedDocument={this.getUploadedDocument}
            createUploadDocument={this.createUploadDocument}
            removeUploadedDocument={this.removeUploadedDocument}
            markApplicationApprove={this.markApplicationApprove}
            markApplicationSubmitFinance={this.markApplicationSubmitFinance}
            markApplicationTagCollect={this.markApplicationTagCollect}
            onChangeRenewalDetailsHOC={this.onChangeRenewalDetailsHOC}
            getSelectedRenewalApplication={this.getSelectedRenewalApplication}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {})(RenewalDetailsWrappedComponent);
};

export default RenewalDetailsHOC;
