import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";

import Tab from "components/Tab";
import CustomTableHeader from "components/NewTable/header";
import CustomPagination from "components/Pagination";
import CustomTableContent from "components/NewTable/content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import { numberWithCommas } from "utils/thousandSeparator";

import "stylesheets/components/table/index.scss";

const CustomTable = ({
  className,
  type,
  onChangeForm,
  rowData: initialRowData,
  columns,
  selectPerPageOption,
  actionColumnContent,
  hidePagination,
  pagination,
  hideSearch,
  updateSimpleTable,
  estimatedCommProps,
  agencyCommProps,
  renderAdditionalContent,
  downloadReport,
}) => {
  const [rowData, setRowData] = useState([]);
  const [isSearching, onChangeSearching] = useState(false);
  const [searchKeyword, setKeyWord] = useState("");
  const [clickPageNumber, setPageNum] = useState(1);
  const [totalPages, setTotalPage] = useState([1]);
  const [currentPageOption, onChangePerPage] = useState(10);
  const [searchWithField, onChangeSeachWithField] = useState("");
  const [searchWithFieldDisplay, onChangeSeachWithFieldDisplay] = useState("");
  const [headerRearrangeData, setHeaderRearrangeData] = useState({});
  const typeTagLabels = {personal: "Personal",company: "Company"}

  useEffect(() => {
    selectPerPageOption && onChangePerPage(selectPerPageOption);
  }, []);

  useEffect(() => {
    setRowData(initialRowData);
    setPageNum(1);
    setKeyWord("");
    processPagesData(initialRowData, selectPerPageOption || 10);
  }, [initialRowData]);

  useEffect(() => {
    for (let index = 0; index < columns.length; index++) {
      if (!columns[index].hideFromSearch) {
        onChangeSeachWithField(columns[index].searchFlag);
        onChangeSeachWithFieldDisplay(columns[index].header);
        break;
      }
    }
  }, [initialRowData]);

  useEffect(() => {
    let estimated_comm = 0;
    let agency_comm = 0;
    rowData &&
      rowData.length > 0 &&
      rowData.map((item) => {
        estimated_comm = estimated_comm + (item[estimatedCommProps] || 0);
        agency_comm = agency_comm + item[agencyCommProps];
      });
    updateSimpleTable(
      numberWithCommas(estimated_comm.toFixed(2)),
      numberWithCommas(agency_comm.toFixed(2)),
    );
  }, [rowData]);

  const processPagesData = (rowData, pageOption) => {
    if (rowData.length > 0) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      setTotalPage(pagesArrayData);
    }
  };

  const debounceService = (searchVal) => {
    onChangeSearching(searchVal ? true : false);
    let result = _.filter(initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });

    Promise.all([setRowData(result)]).then(() => {
      processPagesData(result, currentPageOption);
    });
  };

  const onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    setKeyWord(searchVal);
    if (searchVal === "") {
      setRowData(initialRowData);
      processPagesData(initialRowData, currentPageOption);
    } else {
      debounceService(searchVal);
    }
  };

  const onClickRearrangeData = useCallback(
    (val) => {
      Promise.all([setHeaderRearrangeData(val)]).then(() => {
        if (val.columnValue) {
          let tempOrder = _.orderBy(
            rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (columnData !== "Infinity" && !isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          );
          setRowData(tempOrder);
        }
      });
    },
    [rowData],
  );

  const renderTableContent = () => {
    if (rowData && rowData.length > 0) {
      return rowData.map((rowItem, index) => (
        <>
          {index + 1 > (clickPageNumber - 1) * currentPageOption &&
            index + 1 <= clickPageNumber * currentPageOption && (
              <CustomTableContent
                key={index}
                columns={columns}
                index={index}
                headerRearrangeData={headerRearrangeData}
                actionColumnContent={actionColumnContent}
                rowData={rowItem}
              />
            )}
        </>
      ));
    } else {
      if (isSearching) {
        return (
          <div className="at-empty-state m-top-round-corner">
            <ul className="at-table-row grid-controller">
              <li className="at-table-row__item at-table-row__item-empty">
                <p>No result found</p>
                <span>
                  Try to adjust your search or filter options to find something
                  you're looking for
                </span>
              </li>
            </ul>
          </div>
        );
      }
      return (
        <div style={{background:"white", border:"1px solid #d1d5db", padding: "0px 0.5rem"}}>
          <EmptyState
            title={`Data not found`}
            containerClassName={"no-border"}
            renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
          />
        </div>
      );
    }
  };

  const renderPaginationText = () => {
    const processFloorPaginationCount = () => {
      let pageNumTmp = clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * currentPageOption + 1;
      }
      return pageNumTmp;
    };

    const processCeilingPaginationCount = () => {
      let pageNumTmp = currentPageOption;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowData.length) {
        pageNumTmp = rowData.length;
      }
      return pageNumTmp;
    };

    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span>
          {" "}
          {pagination ? processFloorPaginationCount(clickPageNumber) : ""}{" "}
        </span>
        -
        <span>
          {" "}
          {pagination
            ? processCeilingPaginationCount(currentPageOption)
            : ""}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  return (
    <div className={`mb-20 ${className}`}>
      <div className="at-table at-table--set">
        <div className="cdd-title_header">
          <div className="cdd-header_body">
            <p style={{fontSize: 18,fontWeight: 600}}>Customer Due Diligence </p>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={downloadReport}
            >
              <AtlasIcon
                svgHref={"atlas-xlsx-file"}
                style={{
                  fill: "#208E4D",
                  width: 20,
                  height: 20,
                }}
              />
              Export
            </button>
          </div>
        </div>
        <Tab
          sections={['Personal','Company']}
          containerClass="cdd-header_tab"
          containerStyle={{background:"white", marginBottom: 0, borderBottom: 0, paddingTop:12, borderRight: "1px solid $d1d5db"}}
          selectedSection={type}
          onSelectSection={(val) => onChangeForm(val,'type')}
        />
        <CustomTableHeader
          onClickRearrangeData={onClickRearrangeData}
          columns={columns}
          rowData={rowData}
          actionColumnContent={actionColumnContent}
        />
        {renderTableContent()}
      </div>
      {!hidePagination && rowData.length > 0 && (
        <div className="at-pagination__cont">
          <CustomPagination
            totalPages={totalPages}
            chosenPageNumber={currentPageOption}
            onClickPageNumber={(val) => setPageNum(val)}
            currentPageNumber={clickPageNumber}
          />
          {renderPaginationText()}
        </div>
      )}
    </div>
  );
};

export default CustomTable;
