import React, { useEffect, useState } from "react";

import AtlasButton from "components/Button";
import ModuleFormHeader from "components/Form/header";
import withEmailTemplates from "containers/Dashboard/Administrator/EmailTemplates/actions";
import SelectEmailTemplate from "./SelectEmailTemplate";
import NoTemplate from "assets/images/no_email_template.png";

const Content = ({
  isActive,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  handleAccordionChange,
  handleAccordionClose,
  emailTemplates,
  metadata,
  selectedEmailTemplate,
  getEmailTemplates,
  getSelectedEmailTemplate,
  categories,
  getCategories,
}) => {
  const [showEmailTemplateDialog, setShowEmailTemplateDialog] = useState(false);

  useEffect(() => {
    if (isChecked) {
      getSelectedEmailTemplate(selectedEmailCampaign.email_template_id);
    }
  }, [selectedEmailCampaign.email_template_id]);

  const isChecked = selectedEmailCampaign.email_template_id;

  const toggleEmailTemplateDialog = () =>
    setShowEmailTemplateDialog(!showEmailTemplateDialog);

  const renderSelectEmailTemplateDialog = () => (
    <SelectEmailTemplate
      handleClose={toggleEmailTemplateDialog}
      selectedEmailCampaign={selectedEmailCampaign}
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
      updateEmailCampaign={updateEmailCampaign}
      emailTemplates={emailTemplates}
      metadata={metadata}
      getEmailTemplates={getEmailTemplates}
      categories={categories}
      getCategories={getCategories}
    />
  );

  return (
    <>
      <div className="d-flex flex-wrap align-items-center g-3 mb-3">
        <ModuleFormHeader
          title={"Email Template"}
          moduleIcon={"atlas-sms-tracking"} />
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--secondary mx-auto"}
          onClick={toggleEmailTemplateDialog}
          children={'Select Template'}
        />
      </div>
      <img
        alt={selectedEmailTemplate.name || ""}
        src={selectedEmailTemplate.thumbnail_url || NoTemplate}
        title={selectedEmailTemplate.name || ""}
        width={selectedEmailTemplate.thumbnail_url ? 300 : "100%"}
        height={selectedEmailTemplate.thumbnail_url ? 200 : "100%"}
        className={"border p-1 at-email-campaigns__template-thumbnail"}
      />

      {showEmailTemplateDialog && renderSelectEmailTemplateDialog()}
    </>
  );
};

export default withEmailTemplates(Content);
