import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomUnitSoldChart from "./components/unitsSoldChart";
import CustomOverridingsFigureChart from "./components/overridingsFigureChart";
import GeneralDataContent from "./components/generalDataContent";
import ProjectSalesContent from "./components/projectSalesContent";
import SubsalesContent from "./components/subsalesContent";
import SalesChartContent from "./components/salesChartContent";
import NewsFeedContent from "./components/newsFeedContent";
import Tabs from "components/Tab";
import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";
import { initializeFirebase } from "utils/firebase";
import { getAllDictionaryData } from "actions/dictionary";

import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { getBranches, getInvoicingBranches } from "actions/branch";
import { getAgencies } from "actions/agency";
import { storeFirstTime, getAppConfig } from "actions/appStatus";
import { refreshToken } from "actions/login";

import StatsHOC from "./actions";
import UnitAdminHOC from "./actions/unitAdmin";
import CommissionHOC from "./actions/commission";
import PersonalSalesHOC from "./actions/personalSales";
import AnnouncementHOC from "actions/announcement";
import VersionCheckHOC from "actions/versionCheck";
import CampaignTrackerHoc from "../Reports/CampaignTrackers/actions";
import SalesEligibility from "../Reports/CampaignTrackers/components/Modal/SalesEligibility";

import { getItem, clearItem } from "utils/tokenStore";

import "./index.scss";

class Statistic extends Component {
  state = {
    isAdminView: false,
    title: "My Dashboard",
    navButtons: [],
    viewTeamSale: false,
  };

  componentDidMount = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    initGA("/dashboard/statistic");
    if (getItem("LAST_VIEW_URL") !== null) {
      let temp = _.cloneDeep(getItem("LAST_VIEW_URL"));
      let lastViewURL = temp.substring(temp.indexOf("#") + 1, temp.length);

      clearItem("LAST_VIEW_URL");
      if (
        (lastViewURL.includes("admin") &&
          ["Super Admin", "Admin"].includes(role)) ||
        (!lastViewURL.includes("admin") &&
          !["Super Admin", "Admin"].includes(role))
      ) {
        this.props.history.push(lastViewURL);
      }
    } else this.getAPIs();
    initializeFirebase();
  };

  initComponent = () => {
    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getCommRate();
    if (status_id !== 6) {
      this.props.getAllDictionaryData();
      this.props.getBranches();
      this.props.getAgencies();
    } else {
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
      this.props.getAnnouncements();
    }
  };

  handleRoleTab = (tempRole, dashboardRead) => {
    let tempNavTabs = [
      { icon: "atlas-message-text", label: "Newsfeed" },
      { icon: "atlas-buildings-2", label: "Summary" },
      { icon: "atlas-building-4", label: "Project" },
      { icon: "atlas-building-3", label: "Subsales" },
    ];

    if (["Associated Agent", "Personal Assistant"].indexOf(tempRole) > -1) {
      tempNavTabs = dashboardRead
        ? tempNavTabs
        : [{ icon: "atlas-message-text", label: "Newsfeed" }];
    }

    if (
      tempRole === "Agent" ||
      (tempRole === "Personal Assistant" && dashboardRead)
    ) {
      this.setState({ viewTeamSale: true });
    }

    if (tempRole === "Admin" || tempRole === "Super Admin") {
      tempNavTabs = [{ icon: "atlas-message-text", label: "Newsfeed" }];
    }

    return tempNavTabs;
  };

  getAPIs = () => {
    let tempNavTabs = [
      { icon: "atlas-message-text", label: "Newsfeed" },
      { icon: "atlas-buildings-2", label: "Summary" },
      { icon: "atlas-building-4", label: "Project" },
      { icon: "atlas-building-3", label: "Subsales" },
    ];
    const { id, role, encoded_identity } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { selectedUserRole } = this.props;
    const { can_read: dashboardRead } = permissionsChecker(
      "Dashboard",
      this.props.data,
    );
    const { can_read: paymentVoucherRead } = permissionsChecker(
      "Payment Vouchers",
      this.props.data,
    );

    if (["Super Admin, Admin"].indexOf(role) !== -1) {
      this.props.getUnitAdminProfile(this.props.encoded_identity);
      this.props.getPersonalSales(this.props.encoded_identity);
      this.props.getCommStats(this.props.encoded_identity);
      this.props.getLeaderBoardData();
      this.setState({
        isAdminView: true,
        title: `${this.props.display_name}'s Sales Report`,
      });
      tempNavTabs = this.handleRoleTab(selectedUserRole, true);
    } else {
      this.initComponent();

      if (
        ["Associated Agent", "Admin", "Super Admin", "Project Manager"].indexOf(
          role,
        ) < 0
      ) {
        this.props.getUnitAdminProfile(encoded_identity);
        if (
          role !== "Personal Assistant" ||
          (role === "Personal Assistant" && paymentVoucherRead && dashboardRead)
        ) {
          this.props.getCommStats(encoded_identity);
          this.props.getPersonalSales(encoded_identity);
          this.props.getPersonalSummary(true);
          this.props.getLeaderBoardData();
        }
      }
      if (role === "Project Manager") {
        this.props.history.push("/dashboard/project-listing");
      }
      if (role !== "Associated Agent") {
        tempNavTabs = this.handleRoleTab(role, dashboardRead);
      }
    }
    this.setState({ navButtons: tempNavTabs });
  };

  renderCardContent = () => {
    return (
      <>
        <Tabs
          containerClass={"statistic-tabs"}
          sections={this.state.navButtons}
          selectedSection={this.props.view}
          onSelectSection={(val) => this.props.onChangeStatHOC(val, "view")}
        />
        {this.renderContent()}
      </>
    );
  };

  renderContent = () => {
    const {
      data,
      view,
      selectedAgent,
      commissionStats,
      commissionXData,
      commissionYData,
      leaderBoardData,
      selectedCommTimelineType,
      updateSelectCommTimeline,
      commRate,
      onLoadCommRate,
      onLoadUnitAdmin,
      onLoadCommision,
      onLoadTopPerformer,
      personalSummary,
      getSelectedPromotion,
      showSaleEligibility,
      onChangeCampaignTrackerHOC,
      onLoadCampaign,
      selectPromotion,
      selectedPromotion,
    } = this.props;

    const { country_name } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    const { can_read } = permissionsChecker("Payment Vouchers", data);
    switch (view) {
      case "Newsfeed":
        return <NewsFeedContent />;

      case "Summary":
        return (
          <>
            {(onLoadUnitAdmin || onLoadCommision || onLoadCampaign) && (
              <LoadingModal
                loaderContainerClass={"at-statisic__loader-container"}
                loaderClassName={"at-statisic__loader"}
              />
            )}
            {!(onLoadUnitAdmin || onLoadCommision || onLoadCampaign) && (
              <>
                <GeneralDataContent
                  selectedAgent={selectedAgent}
                  viewTeamSale={this.state.viewTeamSale}
                  getSelectedPromotion={getSelectedPromotion}
                  personalSummary={personalSummary}
                  onChangeCampaignTrackerHOC={onChangeCampaignTrackerHOC}
                  onLoadCampaign={onLoadCampaign}
                />
                {showSaleEligibility && (
                  <SalesEligibility
                    selectedPromotion={selectedPromotion}
                    selectPromotion={selectPromotion}
                    onClickClose={() =>
                      onChangeCampaignTrackerHOC(false, "showSaleEligibility")
                    }
                  />
                )}
                {can_read && (
                  <SalesChartContent
                    commissionXData={commissionXData}
                    commissionYData={commissionYData}
                    selectedCommTimelineType={selectedCommTimelineType}
                    updateSelectCommTimeline={updateSelectCommTimeline}
                  />
                )}
                {commissionStats && (
                  <div className="grid-control p-0">
                    <CustomUnitSoldChart data={commissionStats} />
                    {can_read && (
                      <CustomOverridingsFigureChart data={commissionStats} />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        );

      case "Project":
        return (
          <>
            {onLoadUnitAdmin ? (
              <LoadingModal
                loaderContainerClass={"at-statisic__loader-container"}
                loaderClassName={"at-statisic__loader"}
              />
            ) : (
              <ProjectSalesContent
                selectedAgent={selectedAgent}
                leaderBoardData={leaderBoardData}
                country_name={country_name}
              />
            )}
          </>
        );

      case "Subsales":
        return (
          <>
            {onLoadUnitAdmin || onLoadCommRate ? (
              <LoadingModal
                loaderContainerClass={"at-statisic__loader-container"}
                loaderClassName={"at-statisic__loader"}
              />
            ) : (
              <SubsalesContent
                fromTeam={false}
                commRate={commRate}
                selectedAgent={selectedAgent}
                leaderBoardData={leaderBoardData}
                isAdminView={this.state.isAdminView}
                country_name={country_name}
              />
            )}
          </>
        );

      default:
        break;
    }
  };

  render = () => {
    const { onLoadStats } = this.props;
    return (
      <>
        <ModuleHeader
          title={this.state.title}
          atlasIcon={"atlas-grid-5"}
          backButton={{
            onShow: this.state.isAdminView,
            onClick: () => this.props.clickBack(),
          }}
        />
        <CustomCard
          className={`at-card-mobile`}
          containerStyle={{ minHeight: "100%" }}
          cardContent={this.renderCardContent()}
        />
        {onLoadStats && <LoadingModal />}
      </>
    );
  };
}
const mapStateToProps = (state) => ({ data: state });

export default compose(
  connect(mapStateToProps, {
    getAllDictionaryData,
    getBranches,
    getInvoicingBranches,
    getAgencies,
    storeFirstTime,
    getAppConfig,
    getCurrentUserProfile,
    getCurrentSignInProfile,
    refreshToken,
  }),
  VersionCheckHOC,
  AnnouncementHOC,
  StatsHOC,
  UnitAdminHOC,
  PersonalSalesHOC,
  CommissionHOC,
  CampaignTrackerHoc,
)(Statistic);
