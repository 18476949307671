import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put } from "utils/axios";

import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const BranchManagementHOC = (WrappedComponent) => {
  class BranchManagementWrappedComponent extends Component {
    state = {
      searchParams: [
        {
          label: "Branch Name",
          value: "name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Company Reg. No.",
          value: "company_reg_no_cont",
          type: "input",
          param: "",
        },
        {
          label: "Status",
          value: "active_true",
          type: "radio",
          param: "",
          options: [
            { value: "true", label: "Active" },
            { value: "false", label: "Inactive" },
          ],
        },
      ],

      loading: false,
      branches: [],
      branchesPage: [],
      selectedBranch: {},
      showCreateModal: false,
      showUpdateModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeBranchHOC = (val, context) => this.setState({ [context]: val });

    getBranch = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/branches?${search}page=${page}`,
        this.getBranchSuccess,
        this.getBranchError,
        this.load,
      );
    };
    getBranchSuccess = (payload) => {
      let tmpTotalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      this.setState({
        branches: payload,
        branchesPage: tmpTotalPages,
      });
    };
    getBranchError = (error) => requestError(error);

    getSelectedBranch = (id) =>
      Get(
        `/admin/branches/${id}`,
        this.getSelectedBranchSuccess,
        this.getSelectedBranchError,
        this.load,
      );
    getSelectedBranchSuccess = (payload) =>
      this.setState({
        selectedBranch: payload,
        showUpdateModal: true,
      });
    getSelectedBranchError = (error) => requestError(error);

    createBranch = (dataToSubmit) =>
      Post(
        `/admin/branches`,
        dataToSubmit,
        this.createBranchSuccess,
        this.createBranchError,
        this.load,
      );
    createBranchSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getBranch(currentPage, searchParams);
      this.setState({ showCreateModal: false });
    };
    createBranchError = (error) => requestError(error);

    updateBranch = (dataToSubmit) =>
      Put(
        `/admin/branches/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateBranchSuccess,
        this.updateBranchError,
        this.load,
      );
    updateBranchSuccess = () => {
      requestSuccess("Branch updated");
      this.setState({ showUpdateModal: false });
    };
    updateBranchError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadBranches={this.state.loading}
            getBranch={this.getBranch}
            createBranch={this.createBranch}
            updateBranch={this.updateBranch}
            getSelectedBranch={this.getSelectedBranch}
            onChangeBranchHOC={this.onChangeBranchHOC}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(BranchManagementWrappedComponent);
};

export default BranchManagementHOC;
