import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import {
  IoMdCloseCircle,
  IoIosCheckmarkCircle,
  IoIosRedo,
  IoMdInformationCircle,
} from "react-icons/io";

import AtButton from "components/Button";
import SVGIcon from "components/Icon";
import { getColorBadge } from "dictionary/badgeColor";

const StatusBadge = [
  { id: 1, colorName: "Yellow" },
  { id: 2, colorName: "Green" },
  { id: 3, colorName: "Red" },
];

const processIconType = (param) => {
  switch (param) {
    case "approve":
      return <IoIosCheckmarkCircle className={"text-success"} />;
    case "reject":
      return <IoMdCloseCircle className={"text-danger"} />;
    case "resubmit":
      return <IoIosRedo style={{ width: 28 }} className={"text-primary"} />;
    default:
      return <SVGIcon className="svg-icon-info" svgHref={"icon-info"} />;
  }
};

const processActions = (content, onClickAction, type) => {
  return (
    <AtButton
      key={type}
      className={"svg-icon-info"}
      children={processIconType(type)}
      tooltip={true}
      tooltipChildren={
        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      }
      tooltipPosition={"top"}
      tooltipID={`details-${type}-${content.id}`}
      onClick={() => onClickAction(content)}
    />
  );
};

class TableContent extends Component {
  processContent = (item) => {
    const { rowItem } = this.props;
    const { colorName = "" } =
      _.find(StatusBadge, { id: rowItem.status_id }) || {};

    if (item.value === "status") {
      return (
        <h6
          className={`at-status_badge text-uppercase`}
          style={{
            fontSize: "100%",
            marginTop: 5,
            ...getColorBadge(colorName),
          }}
        >
          {rowItem.status}
        </h6>
      );
    }
    return (
      <span>
        {item.value === "from_admin" && rowItem.from_admin_remark && (
          <Tooltip
            title={
              <span style={{ fontSize: 15 }}>{rowItem.from_admin_remark}</span>
            }
          >
            <IoMdInformationCircle style={{ width: 20, marginRight: 5 }} />
          </Tooltip>
        )}
        {item.value === "to_admin" && rowItem.to_admin_remark && (
          <Tooltip
            title={
              <span style={{ fontSize: 15 }}>{rowItem.from_admin_remark}</span>
            }
          >
            <IoMdInformationCircle style={{ width: 20, marginRight: 5 }} />
          </Tooltip>
        )}
        {rowItem[item.value]}
      </span>
    );
  };

  render = () => {
    let {
      onClickApprove,
      onClickResubmit,
      onClickReject,

      headerData,
      rowItem,
      actionColumn,
      actionColumnContent,
    } = this.props;
    const { id: userId } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div className="at-table-row-cont">
        <div className="at-table__height-control">
          <ul className="at-table-row grid-controller">
            {headerData.map((item, index) => {
              return (
                <li
                  key={`table-${index}-${item.label}`}
                  className={`at-table-row__item at-table__${index}`}
                  style={{
                    width: item.width ? item.width : "100%",
                    minWidth: item.minWidth && item.minWidth,
                  }}
                >
                  <span className="at-table-row__item-label">{item.label}</span>
                  {this.processContent(item)}
                </li>
              );
            })}
            {actionColumn && (
              <li
                className="at-table-row__item at-table__actions"
                style={{ width: `${actionColumnContent.length * 35}%` }}
              >
                <span className="at-table-row__item-label">{"Actions"}</span>
                {this.props.actionColumnContent.map((item) => {
                  if (
                    item === "approve" &&
                    rowItem.status_id === 1 &&
                    userId === rowItem.to_admin_id
                  ) {
                    return processActions(rowItem, onClickApprove, item);
                  } else if (
                    item === "reject" &&
                    rowItem.status_id === 1 &&
                    userId === rowItem.to_admin_id
                  ) {
                    return processActions(rowItem, onClickReject, item);
                  } else if (
                    item === "resubmit" &&
                    rowItem.status_id === 3 &&
                    userId === rowItem.from_admin_id
                  ) {
                    return processActions(rowItem, onClickResubmit, item);
                  }
                })}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(TableContent);
