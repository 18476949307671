import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { requestError } from "utils/requestHandler";
import { Get, Put } from "utils/axios";

const typeLib = [
  {
    id: 1,
    value: "1",
    label: "Event",
  },
  {
    id: 2,
    value: "2",
    label: "Report",
  },
  {
    id: 3,
    value: "3",
    label: "Reminder",
  },
  {
    id: 4,
    value: "4",
    label: "New REN Registration",
  },
  {
    id: 5,
    value: "5",
    label: "Sub-sales",
  },
  {
    id: 6,
    value: "6",
    label: "Marketing Centre",
  },
  {
    id: 7,
    value: "7",
    label: "Project Sales",
  },
  {
    id: 8,
    value: "8",
    label: "Presentation",
  },
  {
    id: 9,
    value: "9",
    label: "Co-broke Request",
  },
];

const searchParams = [
  {
    label: "Title",
    value: "notification_title_cont",
    type: "input",
    col: 4,
    main: true,
    param: "",
  },
  {
    label: "",
    value: "",
    param: "",
  },
  {
    label: "",
    value: "",
    param: "",
  },
  {
    label: "Notification Types",
    value: "notification_type_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      {
        id: 0,
        value: "",
        label: "All",
      },
      ...typeLib,
    ],
  },
  {
    label: "Read",
    value: "read_eq",
    param: "",
    additional: true,
    options: [
      {
        label: "All",
        value: "",
      },
      {
        label: "Unread",
        value: "false",
      },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      onLoadModalNotification: false,

      showDetailModal: false,
      showUnreadDetailModal: false,

      notifications: {},
      searchParams: searchParams,
      notificationsPage: null,
      selectedNotification: {},

      unreadNotification: {
        data: [],
      },
      unreadNotificationPage: null,
      selectedUnreadNotification: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeNotificationHOC = (val, context) =>
      this.setState({ [context]: val });

    getUnreadNotifications = (page) =>
      Get(
        `/notifications?page=${page}`,
        this.getUnreadNotificationsSuccess,
        this.getUnreadNotificationsError,
        (param) => this.setState({ onLoadModalNotification: param }),
      );
    getUnreadNotificationsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = _.cloneDeep(this.state.unreadNotification.data);
      _.map(payload.data, (item) => {
        let itemIndex = _.findIndex(tempData, { id: item.id });
        if (itemIndex > -1) {
          tempData[itemIndex] = {
            ...item,
            created_at: item.created_at
              ? Moment(item.created_at, "DD-MM-YYYY HH:mm").format(
                  "DD MMM YYYY HH:mm",
                )
              : "N/A",
          };
        } else {
          tempData.push({
            ...item,
            created_at: item.created_at
              ? Moment(item.created_at, "DD-MM-YYYY HH:mm").format(
                  "DD MMM YYYY HH:mm",
                )
              : "N/A",
          });
        }
      });
      let temp = {
        data: tempData,
        unread_count: payload.meta.unread_count,
        meta: payload.meta.pagy,
      };
      for (let i = 0; i < payload.meta.pagy.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        unreadNotification: temp,
        unreadNotificationPage: tmpTotalPages,
      });
    };
    getUnreadNotificationsError = (error) => requestError(error);

    getNotifications = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/notifications?${search}page=${page}`,
        this.getNotificationsSuccess,
        this.getNotificationsError,
        this.load,
      );
    };
    getNotificationsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        created_at: item.created_at
          ? Moment(item.created_at, "DD-MM-YYYY HH:mm").format(
              "DD MMM YYYY   HH:mm",
            )
          : "N/A",
      }));
      for (let i = 0; i < payload.meta.pagy.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        notifications: {
          data: tempData,
          meta: payload.meta.pagy,
        },
        notificationsPage: tmpTotalPages,
      });
    };
    getNotificationsError = (error) => requestError(error);

    getSelectedNotification = (id) =>
      Get(
        `/notifications/${id}`,
        this.getSelectedNotificationSuccess,
        this.getSelectedNotificationError,
        this.load,
      );
    getSelectedNotificationSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getUnreadNotifications(1);
      this.getNotifications(currentPage, searchParams);
      this.setState({
        selectedNotification: payload.notification,
        showDetailModal: true,
      });
    };
    getSelectedNotificationError = (error) => requestError(error);

    markNotificationAsRead = (dataToSubmit) => {
      this.setState(
        {
          selectedUnreadNotification: dataToSubmit.notification,
          showUnreadDetailModal: true,
        },
        () => {
          Put(
            `/notifications/mark_read`,
            { ids: [dataToSubmit.id] },
            this.markNotificationAsReadSuccess,
            this.markNotificationAsReadError,
            this.load,
          );
        },
      );
    };
    markNotificationAsReadSuccess = () => this.getUnreadNotifications(1);
    markNotificationAsReadError = (error) => {};

    markAllNotificationAsRead = () =>
      Put(
        `/notifications/mark_read`,
        {},
        this.markAllNotificationAsReadSuccess,
        this.markAllNotificationAsReadError,
        this.load,
      );
    markAllNotificationAsReadSuccess = () => this.getUnreadNotifications(1);
    markAllNotificationAsReadError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          notifications={this.state.notifications}
          unreadNotification={this.state.unreadNotification}
          showDetailModal={this.state.showDetailModal}
          notificationsPage={this.state.notificationsPage}
          onLoadNotification={this.state.loading}
          onLoadModalNotification={this.state.onLoadModalNotification}
          searchParams={this.state.searchParams}
          showUnreadDetailModal={this.state.showUnreadDetailModal}
          selectedNotification={this.state.selectedNotification}
          unreadNotificationPage={this.state.unreadNotificationPage}
          selectedUnreadNotification={this.state.selectedUnreadNotification}
          getNotifications={this.getNotifications}
          getUnreadNotifications={this.getUnreadNotifications}
          markNotificationAsRead={this.markNotificationAsRead}
          markAllNotificationAsRead={this.markAllNotificationAsRead}
          onChangeNotificationHOC={this.onChangeNotificationHOC}
          getSelectedNotification={this.getSelectedNotification}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
