import { AbsoluteFill, Sequence, Video } from 'remotion';

import AgentDetails from './AgentDetails';
import Logo from './Logo';
import PropertyDetails from './PropertyDetails';

import isEmptyValue from 'utils/isEmpty';

const AgentVideo = ({ selectedVideoTemplate, property, agent, isPortrait }) => {
  const { url, configuration, unit_type } = selectedVideoTemplate;

  const renderClassName = () => {
    if (
      configuration.propertyDetailsPosition === 'left' &&
      configuration.agentDetailsPosition === 'left'
    ) {
      return 'flex-column justify-content-end';
    } else if (
      configuration.propertyDetailsPosition === 'right' &&
      configuration.agentDetailsPosition === 'right'
    ) {
      return 'flex-column justify-content-end align-items-end';
    } else if (
      configuration.hidePropertyDetails &&
      configuration.agentDetailsPosition === 'left'
    ) {
      return 'align-items-end';
    } else if (
      configuration.hidePropertyDetails &&
      configuration.agentDetailsPosition === 'right'
    ) {
      return 'justify-content-end align-items-end';
    } else if (
      configuration.propertyDetailsPosition === 'left' &&
      configuration.agentDetailsPosition === 'right'
    ) {
      return 'justify-content-between align-items-end';
    } else if (
      configuration.propertyDetailsPosition === 'right' &&
      configuration.agentDetailsPosition === 'left'
    ) {
      return 'flex-row-reverse justify-content-between align-items-end';
    }
  };

  return (
    <AbsoluteFill>
      <Video src={url} />
      {!configuration.hideIqiLogo && (
        <Sequence from={0}>
          <Logo configuration={configuration} isPortrait={isPortrait} />
        </Sequence>
      )}
      <Sequence
        from={0}
        className={`p-2 ${renderClassName()}`}
        style={{
          gap: 8,
          transform: isPortrait ? 'scale(1.9)' : 'none',
          transformOrigin: isPortrait
            ? configuration.propertyDetailsPosition === 'left'
              ? 'bottom left'
              : 'bottom right'
            : '50% 50% 0'
        }}
      >
        {!configuration.hidePropertyDetails && !isEmptyValue(property) && (
          <PropertyDetails
            property={property}
            unitType={unit_type?.toUpperCase()}
            configuration={configuration}
          />
        )}
        <AgentDetails agent={agent} configuration={configuration} />
      </Sequence>
    </AbsoluteFill>
  );
};

export default AgentVideo;
