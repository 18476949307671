import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      inventoryItems: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getInventoryItems = () =>
      Get(
        `/inventory_items`,
        this.getInventoryItemsSuccess,
        this.getInventoryItemsError,
        this.load,
      );
    getInventoryItemsSuccess = (payload) => {
      this.setState({ inventoryItems: payload });
    };
    getInventoryItemsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadInventory={this.state.loading}
            inventoryItems={this.state.inventoryItems}
            getInventoryItems={this.getInventoryItems}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
