import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      meetingRooms: [],
      meetingRoomsByBranch: [],
      calendarBooking: [],
      storeForArrangedCalendarData: [],

      calendarEvent: [],
      selectedBranch: "",
      selectedRoomBooking: {},
      selectedMeetingRoom: "",
      showUpdateModal: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeBookingCalendar = (val, context) =>
      this.setState({ [context]: val });

    bigCalendarViewObj = (booking) => {
      let tmp = [];
      booking.map((item) => {
        tmp.push({
          title: item.event_subject,
          start: Moment(item.start_time).toDate(),
          end: Moment(item.end_time).toDate(),
        });
      });
      return tmp;
    };

    getMeetingRooms = () =>
      Get(
        `/admin/meeting_rooms?full_list=true`,
        this.getMeetingRoomsSuccess,
        this.getMeetingRoomsError,
        () => {},
      );
    getMeetingRoomsSuccess = (payload) =>
      this.setState({
        meetingRooms: payload.map((room) => ({
          ...room,
          label: room.name,
        })),
      });
    getMeetingRoomsError = (err) => requestError(err);

    getCalendarBooking = () =>
      Get(
        `/admin/bookings/calendar`,
        this.getCalendarBookingSuccess,
        this.getCalendarBookingError,
        this.load,
      );
    getCalendarBookingSuccess = (payload) =>
      this.setState({
        calendarBooking: payload.map((booking) => ({
          ...booking,
          title: `${Moment(booking.start_time).format("hh:mmA")} ${booking.event_subject}`,
          start_date_time: booking.start_time,
          end_date_time: booking.end_time,
          is_payment_needed: [2, 4].includes(booking.status_id),
          type: booking.meeting_room_type_id === 2
           ? [1, 2].includes(booking.status_id) ? "paid-event" : "paid-training"
           : [1, 2].includes(booking.status_id) ? "event" : "training",
        })),
      });
    getCalendarBookingError = (err) => requestError(err);

    getSelectedRoomBooking = (id) => {
      Get(
        `/admin/bookings/${id}`,
        this.getSelectedRoomBookingSuccess,
        this.getSelectedRoomBookingError,
        this.load,
      );
    };
    getSelectedRoomBookingSuccess = (payload) =>
      this.setState({
        selectedRoomBooking: {
          ...payload,
          meeting_room_id: payload.meeting_room_id,
        },
        showUpdateModal: true,
      });
    getSelectedRoomBookingError = (err) => requestError(err);

    updateRoomBooking = (dataToSubmit) =>
      Put(
        `/admin/bookings/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateRoomBookingSuccess,
        this.updateRoomBookingError,
        this.load,
      );
    updateRoomBookingSuccess = () => {
      requestSuccess("Successfully updated room booking");
      this.setState({ showUpdateModal: false });
      this.getCalendarBooking();
    };
    updateRoomBookingError = (err) => requestError(err);

    getCalendarEvent = (id, date) =>
      Get(
        `/meeting_rooms/${id}?booking_date=${date}`,
        this.getCalendarEventSuccess,
        this.getCalendarEventError,
        this.load,
      );
    getCalendarEventSuccess = (payload) => {
      let tmp = [];
      if (payload.bookings && payload.bookings.length > 0) {
        tmp = this.bigCalendarViewObj(payload.bookings);
      }
      this.setState({ calendarEvent: tmp });
    };
    getCalendarEventError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadBookingCalendar={this.state.loading}
          getMeetingRooms={this.getMeetingRooms}
          getCalendarEvent={this.getCalendarEvent}
          updateRoomBooking={this.updateRoomBooking}
          getCalendarBooking={this.getCalendarBooking}
          getSelectedRoomBooking={this.getSelectedRoomBooking}
          onChangeBookingCalendar={this.onChangeBookingCalendar}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
