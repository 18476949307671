import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { FormControl } from "@material-ui/core";
import DatePicker from "react-datepicker";

import AtlasButton from "components/Button";
import CustomSelect from "components/Select";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasFormInput from "components/Input/formInput";
import CustomImageUploader from "components/Input/imageUpload.js";

import { newEventInputField } from "./assets";

const Event = ({
  mode,
  selectedEvent,

  onClose,
  submitData,
}) => {
  const [doc, setDoc] = useState({});
  const [newEvent, setNewEvent] = useState({
    cpd_event_date: "",
    cpd_event_name: "",
    organiser: "",
    venue_id: "",
    cpd_hour: 0,
  });

  useEffect(() => {
    if (selectedEvent && mode === "Edit") {
      setNewEvent({
        id: selectedEvent.id,
        cpd_event_date: selectedEvent.cpd_event_date,
        cpd_event_name: selectedEvent.cpd_event_name,
        organiser: selectedEvent.organiser,
        venue_id: selectedEvent.venue_id,
        cpd_hour: selectedEvent.cpd_hour,
      });
    }
  }, [selectedEvent]);

  const onChangeFile = (fileItems) => {
    let temp = {
      certificate_filename: "",
      certificate_file: "",
    };

    if (fileItems.length > 0 && fileItems[0].file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          temp.certificate_filename = fileItems[0].file.name;
          temp.certificate_file = e.target.result;
          setDoc(temp);
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      setDoc({});
    }
  };

  const onRemoveFile = () => setDoc({});

  const onChangeNewEvent = (val, context) => {
    let temp = _.cloneDeep(newEvent);
    temp[context] = val;
    setNewEvent(temp);
  };

  return (
    <ModalDialog
      title={mode === "Create" ? "New Event" : "Event Detail"}
      onClose={onClose}
      fullWidth={true}
      children={
        <>
          <div className="row">
            <div className="col-md-6">
              {newEventInputField.map((item, index) => {
                if (item.type === "date") {
                  return (
                    <div key={index} variant="outlined" className="w-100 mb-2">
                      <h2 className="at-form-label mb-2">Event Date</h2>
                      <div className="at-form-input">
                        <AtlasIcon
                          className={"at-form-input__calendar"}
                          svgHref={"atlas-calendar2"}
                        />
                        <DatePicker
                          showYearDropdown
                          dateFormat={"DD MMM YYYY"}
                          className="at-form-input__input"
                          value={newEvent.cpd_event_date}
                          placeholderText={"Select event date"}
                          onChange={(val) =>
                            onChangeNewEvent(
                              Moment(val).format("DD MMM YYYY"),
                              "cpd_event_date",
                            )
                          }
                        />
                        <div className="at-form-input__required">
                          required *
                        </div>
                      </div>
                    </div>
                  );
                }
                if (item.type === "select") {
                  return (
                    <div key={index} className="w-100 mb-2">
                      <h2 className="at-form-label mb-2">{item.label}</h2>
                      <CustomSelect
                        className="w-100"
                        required={true}
                        selectItems={item.options}
                        currentlySelected={
                          newEvent[item.value]
                            ? _.find(item.options, {
                                value: newEvent[item.value],
                              })
                            : ""
                        }
                        updateSelect={(val) =>
                          onChangeNewEvent(val.value, item.value)
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="w-100 mb-2">
                      <h2 className="at-form-label mb-2">{item.label}</h2>
                      <AtlasFormInput
                        type="text"
                        required={true}
                        value={newEvent[item.value]}
                        onChangeValue={(val) =>
                          onChangeNewEvent(val, item.value)
                        }
                        placeholder={item.label}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className="col-md-6">
              <FormControl className="w-100 mb-2">
                <h2 className="at-form-label mb-2">Upload Certificate</h2>
                <CustomImageUploader
                  fullWidth
                  required={true}
                  files={
                    doc.certificate_file && doc.certificate_filename
                      ? [
                          {
                            source: doc.certificate_file,
                            name: doc.certificate_filename,
                          },
                        ]
                      : []
                  }
                  allowMultiple={false}
                  acceptedFileTypes={["image/*", "application/pdf"]}
                  accept="image/*, application/pdf"
                  maxFiles={1}
                  onRemoveFile={onRemoveFile}
                  onChangeFile={(fileItems) => onChangeFile(fileItems)}
                />
              </FormControl>
            </div>
          </div>
          <div className="at-modal_dialog-container-footer g-3">
            <AtlasButton
              disabled={_.values(newEvent).some(
                (x) => x === "" || x === null || x === 0,
              )}
              className={ mode === "Create" ? "btn-new btn-new--primary" : "btn-new btn-new--success"}
              children={mode === "Create" ? "Create" : "Update"}
              onClick={() =>
                submitData({
                  ...newEvent,
                  ...(doc.certificate_file &&
                    doc.certificate_filename && {
                      image: doc.certificate_file,
                      image_file_name: doc.certificate_filename,
                    }),
                })
              }
            />
            <AtlasButton
              className="btn-new btn-new--outline-secondary"
              children={"Cancel"}
              onClick={() => onClose()}
            />
          </div>
        </>
      }
    />
  );
};

export default Event;
