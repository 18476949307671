import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { BiTable } from "react-icons/bi";
import { MenuItem } from "@material-ui/core";
import { MdFilterList } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";
import { HorizontalBar } from "react-chartjs-2";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtSelectNew from "components/Select/new";
import IOSButton from "components/Button/toggle";
import AtlasIcon from "components/Icon/atlasIcon";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";

import { numberWithCommas } from "utils/thousandSeparator";

const intervalOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const columnData = [
  {
    header: "File Path",
    accessor: "file_path",
    searchFlag: "file_path",
    columnStyle: { width: "150%" },
  },
  {
    header: "Total Hit",
    accessor: "total_hits",
    searchFlag: "total_hits",
    columnStyle: { width: "60%" },
  },
  {
    header: "Total Unique User",
    accessor: "total_unique_users",
    searchFlag: "total_unique_users",
    columnStyle: { width: "60%" },
  },
];

const SearchParams = ({ country, dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          param: `start_date=${Moment().subtract(1, "years").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Country",
    value: "country",
    param: { name: "Malaysia" },
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    typeaheadId: "country_typeahead",
    showClearButton: true,
    options: country,
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Query",
    value: "query",
    param: "",
    type: "input",
  },
  {
    label: "View By",
    value: "interval",
    type: "radio",
    param: "month",
    options: intervalOptions,
  },
];

const FolderClick = ({
  data,
  folderClick,
  folderClickFilter,
  folderClickOptions,
  selectedFolderClick,

  getFolderClick,
  processFolderClick,
  onChangeAnalyticHOC,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      country: data.dictionaryReducer.countries,
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getFolderClick(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        country: data.dictionaryReducer.countries,
        dateRangeMode,
      }),
    );
    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  const renderSearchMode = () => (
    <div className="at-analytic-centre__mode-toggle">
      Quick Select
      <IOSButton
        className={"ml-2"}
        currentState={!dateRangeMode}
        onToggleButton={() => setDateRangeMode(!dateRangeMode)}
      />
      Date Range
    </div>
  );

  return (
    <>
      <div className="mb-10">
        <button
          className={
            "btn-new btn-new--outline-secondary at-mobile-view__controller"
          }
          style={{ width: "100%" }}
          onClick={() => setShowSearchContent((prevState) => !prevState)}
        >
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5>
        </button>
        <div className="at-table__search-cont at-desktop-view__controller ">
          <TableSearchParams
            mode={"desktop"}
            showResetButton={false}
            searchParams={searchParams}
            renderSearchMode={renderSearchMode()}
            onChangeSearchParams={setSearchParams}
            onToggleSearchModal={() =>
              setShowSearchContent((prevState) => !prevState)
            }
            getListAPI={(page, search) => getFolderClick(search)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center mb-10">
        <div style={{ width: 300, marginRight: 10 }}>
          <AtSelectNew
            value={folderClickFilter}
            style={{ display: "flex", flexDirection: "column" }}
            onChange={(e) =>
              Promise.all([
                onChangeAnalyticHOC(e.target.value, "folderClickFilter"),
              ]).then(() => {
                processFolderClick(folderClick);
              })
            }
          >
            {folderClickOptions.length > 0 &&
              folderClickOptions.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
          </AtSelectNew>
        </div>
        <div className="d-flex">
          <button
            className={`at-table-search__additional-btn ${visual === "chart" ? "at-table-search__additional--selected" : ""}`}
            onClick={() => setVisual("chart")}
            children={
              <FaRegChartBar style={{ width: "20px", height: "20px" }} />
            }
          />
          <button
            onClick={() => setVisual("table")}
            className={`at-table-search__additional-btn ${visual === "table" ? "at-table-search__additional--selected" : ""}`}
            children={<BiTable style={{ width: "20px", height: "20px" }} />}
          />
        </div>
      </div>
      {folderClick && folderClick.length > 0 && visual === "table" && (
        <CustomTable
          pagination={true}
          hideSearch={true}
          rowData={selectedFolderClick.data}
          columns={columnData}
        />
      )}
      {folderClick && folderClick.length > 0 && visual === "chart" && (
        <div
          style={{
            height: `${selectedFolderClick.chart.labels ? selectedFolderClick.chart.labels.length * 40 + "px" : "50vh"}`,
          }}
        >
          <HorizontalBar
            data={selectedFolderClick.chart}
            options={{
              maintainAspectRatio: false,
              tooltips: {
                callbacks: {
                  title: (tooltipItem, data) => {
                    return data.labels[tooltipItem[0].index];
                  },
                },
              },
              plugins: {
                datalabels: {
                  anchor: "end",
                  align: "end",
                  formatter: (value, context) =>
                    numberWithCommas(context.dataset.data[context.dataIndex]),
                },
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: 14,
                      callback: (value, index, values) =>
                        numberWithCommas(value),
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 12,
                      callback: (label) => {
                        if (window.innerWidth > 1024 && label.length > 90) {
                          return `${label.substring(0, 90)}...`;
                        } else if (
                          window.innerWidth <= 1024 &&
                          label.length > 20
                        ) {
                          return `${label.substring(0, 20)}...`;
                        } else {
                          return label;
                        }
                      },
                    },
                  },
                ],
              },
            }}
          />
        </div>
      )}
      {!(folderClick && folderClick.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={
            visual === "chart" ? (
              <AtlasIcon svgHref="atlas-chart-3" />
            ) : (
              <AtlasIcon svgHref="atlas-document-text" />
            )
          }
        />
      )}
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          showResetButton={false}
          searchParams={searchParams}
          renderSearchMode={renderSearchMode()}
          onChangeSearchParams={setSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={(page, search) => getFolderClick(search)}
        />
      )}
    </>
  );
};

export default FolderClick;
