import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Delete, Put } from "utils/axios";
import { refreshToken } from "actions/login";

const AdminTutorialHOC = (WrappedComponent) => {
  class AdminTutorialWrappedComponent extends Component {
    state = {
      tutorials: [],
      selectedTutorial: {
        title: "",
        category_id: "",
        document_file_name: "",
        document: "",
      },
      newTutorial: {
        title: "",
        category_id: "",
        document_file_name: "",
        document: "",
      },
      uploadedDocument: {},
      showNewDialog: false,
      showEditTutorialDialog: false,
      showConfirmDeleteDialog: false,
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeAdminHOC = (val, context) => this.setState({ [context]: val });

    onChangeNewTutorial = (title, val) => {
      let tmp = this.state.newTutorial;
      tmp[title] = val;
      return this.setState({ newTutorial: tmp });
    };
    onUploadDocument = (param) => {
      if (param.length > 0 && param[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let tmp = this.state.newTutorial;
          tmp["document"] = e.target.result;
          tmp["document_file_name"] = param[0].file.name;
          this.setState({ newTutorial: tmp });
        };
        reader.readAsDataURL(param[0].file);
      }
    };
    onChangeEditTutorial = (title, val) => {
      let tmp = this.state.selectedTutorial;
      tmp[title] = val;
      return this.setState({ selectedTutorial: tmp });
    };
    onUploadEditDocument = (param) => {
      if (param.length > 0 && param[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let tmp = this.state.selectedTutorial;
          tmp["document_url"] = "";
          tmp["document"] = e.target.result;
          tmp["document_file_name"] = param[0].file.name;
          this.setState({ selectedTutorial: tmp });
        };
        reader.readAsDataURL(param[0].file);
      }
    };

    onDeleteDocument = () => {
      let tmp = this.state.selectedTutorial;
      tmp["document"] = {};
      tmp["document_file_name"] = "";
      delete tmp.document_url;
      return this.setState({ selectedTutorial: tmp });
    };

    getTutorials = () =>
      Get(
        `/admin/tutorials`,
        this.getTutorialsSuccess,
        this.getTutorialsError,
        this.load,
      );
    getTutorialsSuccess = (payload) => this.setState({ tutorials: payload });
    getTutorialsError = (error) => requestError(error);

    createTutorial = () =>
      Post(
        `/admin/tutorials`,
        this.state.newTutorial,
        this.createTutorialSuccess,
        this.createTutorialError,
        this.load,
      );
    createTutorialSuccess = (payload) => {
      this.getTutorials();
      requestSuccess("Tutorial created successfully.");
      this.setState({
        showNewDialog: false,
        newTutorial: {
          title: "",
          category_id: "",
          document_file_name: "",
          document: "",
        },
      });
    };
    createTutorialError = (error) => requestError(error);

    getSelectedTutorial = (id) =>
      Get(
        `/admin/tutorials/${id}`,
        this.getSelectedTutorialSuccess,
        this.getSelectedTutorialError,
        this.load,
      );
    getSelectedTutorialSuccess = (payload) =>
      this.setState({
        selectedTutorial: payload,
        showEditTutorialDialog: true,
      });
    getSelectedTutorialError = (error) => requestError(error);

    deleteTutorial = (id) =>
      Delete(
        `/admin/tutorials/${id}`,
        this.deleteTutorialSuccess,
        this.deleteTutorialError,
        this.load,
      );
    deleteTutorialSuccess = (payload) => {
      this.getTutorials();
      this.setState({ showConfirmDeleteDialog: false });
      requestSuccess("Tutorial is deleted successfully.");
    };
    deleteTutorialError = (error) => requestError(error);

    updateTutorial = (id) =>
      Put(
        `/admin/tutorials/${id}`,
        this.state.selectedTutorial,
        this.updateTutorialSuccess,
        this.updateTutorialError,
        this.load,
      );
    updateTutorialSuccess = (payload) => {
      this.getTutorials();
      requestSuccess("Tutorial updated successfully!");
      this.getSelectedTutorial(payload.id, "edit");
    };
    updateTutorialError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          getTutorials={this.getTutorials}
          onChangeNewTutorial={this.onChangeNewTutorial}
          onChangeEditTutorial={this.onChangeEditTutorial}
          onUploadDocument={this.onUploadDocument}
          onUploadEditDocument={this.onUploadEditDocument}
          createTutorial={this.createTutorial}
          getSelectedTutorial={this.getSelectedTutorial}
          deleteTutorial={this.deleteTutorial}
          updateTutorial={this.updateTutorial}
          onChangeAdminHOC={this.onChangeAdminHOC}
          tutorials={this.state.tutorials}
          selectedTutorial={this.state.selectedTutorial}
          onLoadTutorial={this.state.loading}
          showNewDialog={this.state.showNewDialog}
          newTutorial={this.state.newTutorial}
          uploadedDocument={this.state.uploadedDocument}
          onDeleteDocument={this.onDeleteDocument}
          showEditTutorialDialog={this.state.showEditTutorialDialog}
          showConfirmDeleteDialog={this.state.showConfirmDeleteDialog}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(AdminTutorialWrappedComponent);
};

export default AdminTutorialHOC;
