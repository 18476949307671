import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, GetFile, Post } from "utils/axios";

import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";

const ProfileHOC = (WrappedComponent) => {
  class ProfileWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,

      showDownloadModal: false,
      showAppointmentLetterModal: false,
      appointmentModalContentTypeId: 0,
      appointmentLetterRecipient: "",

      showLoadingModal: false,
      showCopySnackBar: false,
      showForceNDAModal: false,
      refCode: "",
      showAgentQR: false,
      showIQIID: false,
      showReferalAgent: false,
      showNDAModal: false,
      showElectricalTNC: false,
      showProfileUpdate: false,
      showNameCardUpdate: false,

      NDAgreement: "",
      selectedAgent: {},
      cp58List: [],
      cp58Year: Moment().subtract(1, "years").format("YYYY"),
      selectedSection: "Profile",
    };

    load = (param) => this.setState({ loading: param });

    onChangeProfileHOC = (val, context) => this.setState({ [context]: val });

    downloadDoc = () =>
      GetFile(
        `/profiles/download_appointment_nda_pdf`,
        `${this.props.data.profileReducer.full_name}_NDA_Appointment`,
        () => {},
        this.downloadDocFailed,
        this.load
      );
    downloadDocFailed = (error) => requestError(error);

    sendEmail = (val) =>
      Post(
        `/profiles/deliver`,
        {},
        this.sendEmailSuccess,
        this.sendEmailError,
        this.load
      );
    sendEmailSuccess = (payload) => {
      requestSuccess(payload.message);
      this.setState({
        appointmentLetterRecipient: "",
        showAppointmentLetterModal: true,
        appointmentModalContentTypeId: 0,
      });
    };
    sendEmailError = (error) => requestError(error);

    checkDigitalSign = () =>
      Get(
        `/agreements/version`,
        this.checkDigitalSignSuccess,
        this.checkDigitalSignError,
        this.load
      );
    checkDigitalSignSuccess = (payload) =>
      this.setState({
        [payload.is_signed
          ? "showAppointmentLetterModal"
          : "showElectricalTNC"]: true,
        NDAgreement: payload.content,
      });
    checkDigitalSignError = (error) => requestError(error);

    createDigitalSign = (dataToSubmit) =>
      Post(
        `/agreements`,
        { signature: dataToSubmit },
        this.createDigitalSignSuccess,
        this.createDigitalSignError,
        this.load
      );
    createDigitalSignSuccess = () => {
      this.checkDigitalSign();
      this.setState({ showNDAModal: false });
      requestSuccess("Congratulation! Appointment Letter has been signed.");
    };
    createDigitalSignError = (error) => requestError(error);

    getUnitAdminProfile = (encoded_mobile) =>
      Get(
        encoded_mobile
          ? `/connector/profile?identity_id=${encoded_mobile}`
          : `/connector/profile`,
        this.getUnitAdminProfileSuccess,
        this.getUnitAdminProfileError,
        this.load
      );
    getUnitAdminProfileSuccess = (payload) => {
      const { referrer_code } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.setState({
        selectedAgent: payload,
        refCode:
          window.location.host.indexOf("atlas.iqiglobal.com") !== -1
            ? `https://atlas.iqiglobal.com/#/register/ref=${referrer_code}`
            : `http://${window.location.host}/#/register/ref=${referrer_code}`,
      });
    };
    getUnitAdminProfileError = (error) => requestError(error);

    getCP58 = (year) =>
      Get(
        `/profiles/cp58?year=${year}`,
        this.getCP58Success,
        this.getCP58Error,
        this.load
      );
    getCP58Success = (payload) => {
      if (payload.length > 0) {
        this.setState({ cp58List: payload });
      } else {
        this.setState({ cp58List: [] });
      }
    };
    getCP58Error = (error) => requestError(error);

    downloadCP58 = (onSuccess) => {
      const { full_name } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      GetFile(
        `/profiles/cp58?download=1&year=${this.state.cp58Year}`,
        `${full_name.replaceAll(" ", "_")}_cp58.zip`,
        () => this.downloadCP58Success(onSuccess),
        this.downloadCP58Error,
        this.load
      );
    };
    downloadCP58Success = (onSuccess) => {
      onSuccess();
      requestSuccess("CP58 downloaded successful");
    };
    downloadCP58Error = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedAgent={this.state.selectedAgent}
            onLoadAppointmentLetter={this.state.loading}
            appointmentLetterRecipient={this.state.appointmentLetterRecipient}
            showAppointmentLetterModal={this.state.showAppointmentLetterModal}
            appointmentModalContentTypeId={
              this.state.appointmentModalContentTypeId
            }
            showLoadingModal={this.state.showLoadingModal}
            showCopySnackBar={this.state.showCopySnackBar}
            refCode={this.state.refCode}
            cp58List={this.state.cp58List}
            cp58Year={this.state.cp58Year}
            showAgentQR={this.state.showAgentQR}
            showIQIID={this.state.showIQIID}
            showForceNDAModal={this.state.showForceNDAModal}
            showDownloadModal={this.state.showDownloadModal}
            showElectricalTNC={this.state.showElectricalTNC}
            showReferalAgent={this.state.showReferalAgent}
            showNDAModal={this.state.showNDAModal}
            NDAgreement={this.state.NDAgreement}
            showProfileUpdate={this.state.showProfileUpdate}
            showNameCardUpdate={this.state.showNameCardUpdate}
            selectedSection={this.state.selectedSection}
            getCP58={this.getCP58}
            sendEmail={this.sendEmail}
            downloadDoc={this.downloadDoc}
            downloadCP58={this.downloadCP58}
            checkDigitalSign={this.checkDigitalSign}
            createDigitalSign={this.createDigitalSign}
            onChangeProfileHOC={this.onChangeProfileHOC}
            getUnitAdminProfile={this.getUnitAdminProfile}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
  })(ProfileWrappedComponent);
};

export default ProfileHOC;
