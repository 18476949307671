import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      signingHistory: [],
      selectedHistory: {},
      showMessageLogs: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeHistoryHOC = (val, context) => this.setState({ [context]: val });

    getSigningHistory = (id) =>
      Get(
        `/e_processes/activity_logs?form_type=4&form_id=${id}`,
        this.getSigningHistorySuccess,
        this.getSigningHistoryError,
        this.load,
      );
    getSigningHistorySuccess = (payload) => {
      let tempData = [];
      payload.e_process_logs.length > 0 &&
        payload.e_process_logs.map((item) => {
          tempData.push({
            ...item,
            created_at: Moment(item.created_at).format("YYYY-MM-DD HH:mm"),
          });
        });
      this.setState({ signingHistory: tempData });
    };
    getSigningHistoryError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadHistory={this.state.loading}
            signingHistory={this.state.signingHistory}
            selectedHistory={this.state.selectedHistory}
            showMessageLogs={this.state.showMessageLogs}
            getSigningHistory={this.getSigningHistory}
            onChangeHistoryHOC={this.onChangeHistoryHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
