import React, { useEffect, useState } from "react";
import Moment from "moment";
import _ from "lodash";
import { MenuItem, Collapse } from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import DatePicker from "react-datepicker";
import CustomDatepicker from "../Input/datetimepicker";

import AtSelectNew from "components/Select/new";
import AtInput from "components/Input/formInput";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";
import DayRangePicker from "components/DayRangePicker";
import RenderSearchReset from "./RenderSearchReset";

import { getTranslation } from "assets/translation";
import useSelectedLocalised from 'utils/hooks/useSelectedLocalised';

const SearchContent = ({
  mode,
  searchLabel,
  searchParams,
  showResetButton = true,

  getListAPI,
  renderSearchMode,
  onToggleSearchModal,
  onChangeSearchParams,
  renderAdditionalFilterActions,
  getLocalised,
  getFilterKey = (val) => val,
}) => {
  const selectedLocalised = useSelectedLocalised();
  const [showMoreFilter, onToggleShow] = useState(false);
  const [mainSearchLength, onChangeSearchLength] = useState(3);

  useEffect(() => {
    const maxColumnLength = 12
    let count = 0
    let reducer = 0
    for(let item of searchParams) {
      reducer = reducer + (item?.col || 4) 
      if(reducer <= maxColumnLength) {
        ++count; 
      } else {
        break
      }
    }
    onChangeSearchLength(count); // To get the first N search params that fit in 12 columns
  }, [searchParams])
  
  const onChangeFilter = (index, value) => {
    let tmp = _.cloneDeep(searchParams);
    tmp[index].param = value;
    return onChangeSearchParams(tmp);
  };

  const renderInputForm = (item, index) => (
    <>
      {item.type === "input" && (
        <AtInput
          containerClass={"mb-0"}
          value={item.param}
          placeholder={getLocalised ? getLocalised(item.key, item.label) : item.label}
          style={{ marginBottom: 0 }}
          onChangeValue={(value) => onChangeFilter(index, value)}
        />
      )}
      {item.type === "select" && (
        <AtSelectNew
          value={item.param}
          onChange={(e) => onChangeFilter(index, e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options &&
            item.options.map((option) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.valueKey ? option[item.valueKey] : option.value}
                value={item.valueKey ? option[item.valueKey] : option.value}
                style={{ fontSize: 14 }}
              >
                {getLocalised ? getLocalised(option.key, option.label) : option.label}
              </MenuItem>
            ))}
        </AtSelectNew>
      )}
      {item.type === "radio" && (
        <div className={"d-flex flex-wrap g-2"}>
          {item.options &&
            item.options.map((option, optionIndex) => {
              return (
                <button
                  key={`paginated_table-radio_option-${optionIndex}`}
                  className={`btn-radio ${item.param === option.value ? "btn-radio-selected" : "btn-radio-outline"}`}
                  onClick={() => onChangeFilter(index, option.value)}
                >
                  {getLocalised ? getLocalised(option.key, option.label) : option.label}
                </button>
              );
            })}
        </div>
      )}
      {item.type === "typeahead" && (
        <CustomTypeahead
          filterBy={item.filterBy}
          showClearButton={item.showClearButton}
          containerClassName={"mt-0"}
          typeaheadId={`paginated-typeahead-${item.value}`}
          placeholder={item.placeholder || (getLocalised ? getLocalised("label.details.address.township", item.label) : item.label)}
          options={item.options || []}
          containerStyle={{ ...item.containerStyle, marginBottom: 0 }}
          selectedValue={item.param ? [item.param] : []}
          labelKey={item.labelKey}
          onSelect={(val) => {
            if (val && val.length > 0) {
              onChangeFilter(index, val[0]);
            } else {
              onChangeFilter(index, "");
            }
          }}
          childrenHead={(rowItem) =>
            item.typeaheadChildren ? item.typeaheadChildren(rowItem) : false
          }
        />
      )}
      {item.type === "select-multi" && (
        <AtSelectNew
          value={item.param}
          multiple={true}
          onChange={(e) => onChangeFilter(index, e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options &&
            item.options.map((option) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.valueKey ? option[item.valueKey] : option.value}
                value={item.valueKey ? option[item.valueKey] : option.value}
                style={{ fontSize: 14 }}
              >
                {getLocalised ? getLocalised(option.key, option.label) : option.label}
              </MenuItem>
            ))}
        </AtSelectNew>
      )}
      {item.renderField &&
        item.renderField({
          item,
          searchParams: searchParams,
          index: index,
          onChangeSearchParams: onChangeSearchParams,
        })}
      {item.type === "day-range" && (
        <DayRangePicker
          searchParams={searchParams}
          endDateKey={item.endDateKey || "end_date"}
          startDateKey={item.startDateKey || "start_date"}
          initialValue={item.initialValue}
          onChange={(val) => onChangeFilter(index, val)}
        />
      )}
      {item.type === "date" && (
        <div className="at-form-input">
          <AtlasIcon
            className={"at-form-input__calendar"}
            svgHref={"atlas-calendar-input"}
          />
          <DatePicker
            withPortal
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dateFormat={item.format || "YYYY-MM-DD"}
            className="at-form-input__input"
            value={item.param}
            selected={
              item.param && item.param !== ""
                ? new Date(item.param)
                : item.param
            }
            placeholderText={item.label}
            onChange={(val) =>
              onChangeFilter(
                index,
                item.format === "dd-MM-yyyy" ? val : Moment(val).format(item.format || "YYYY-MM-DD")
              )
            }
          />
        </div>
      )}
      {item.type === "date_time" && (
        <div className="at-form-input">
          <CustomDatepicker
            id={"search_date_time"}
            type="datetime-local"
            value={item.param || item.default}
            minDateTime={item.minDate}
            maxDateTime={item.maxDate}
            textFieldClass={
              "at-search-form-input at-custom-datetimepicker-root"
            }
            customClearBtnStyle={{ height: 36 }}
            onChange={(val) =>
              onChangeFilter(
                index,
                val ? Moment(val).format("YYYY-MM-DDTHH:mm") : "",
              )
            }
          />
        </div>
      )}
    </>
  );

  const handleSearch = () => {
    let tmp = "";
    searchParams.map((item) => {
      if(item.param) {
        if(item.value) {
          if(item.withoutPrefix) {
            tmp = tmp + `${item.value}=${
              item.type === "typeahead"
                ? getFilterKey(item.param.name)
                : item.type === "date_time"
                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                  : getFilterKey(item.param)
            }&`
          } else {
            tmp = tmp + `q[${item.value}]=${
              item.type === "typeahead"
                ? getFilterKey(item.param.name)
                : item.type === "date_time"
                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                  : getFilterKey(item.param)
            }&`
          }
        } else {
          tmp = tmp + `q${item.param}&`
        }
      }
    });
    getListAPI(1, tmp);

    if (mode === "mobile") {
      onToggleSearchModal(false);
    }
  };

  const renderDesktopMode = () => (
    <>
      <div className={"at-form_fields_cont mb-3"}>
        {renderSearchMode && (
          <div className="at-form_field-col-12">{renderSearchMode}</div>
        )}
        {searchParams &&
          searchParams.map((item, index) => {
            if (index < mainSearchLength && !item.additional) {
              return (
                <div
                  key={`${item.value}-${index}`}
                  className={`at-form_field-col-${item.col || 4}`}
                >
                  <label
                    style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}
                  >
                    {getLocalised ? getLocalised(item.key, item.label) : item.label}
                  </label>
                  <br />
                  {renderInputForm(item, index)}
                </div>
              );
            }
            return ""
          })}
      </div>
      {searchParams && searchParams.length > mainSearchLength && (
        <Collapse in={showMoreFilter} timeout="auto" unmountOnExit>
          <div className={"at-form_fields_cont"} style={{ marginBottom: 16 }}>
            {searchParams &&
              searchParams.map((item, index) => {
                if (index >= mainSearchLength && !item.additional) {
                  return (
                    <div
                      key={`${item.value}-${index}`}
                      className={`at-form_field-col-${item.col || 4}`}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {getLocalised ? getLocalised(item.key, item.label) : item.label}
                      </label>
                      <br />
                      {renderInputForm(item, index)}
                    </div>
                  );
                }
                return ""
              })}
          </div>
        </Collapse>
      )}
      <div className="d-flex mt-3">
        {searchParams && searchParams.length > 3 && (
          <button
            className="btn-new mr-2"
            style={{ color: "#2563EB" }}
            onClick={() => onToggleShow(!showMoreFilter)}
          >
            {showMoreFilter && (getTranslation("hide_filter", selectedLocalised))}
            {!showMoreFilter && (getTranslation("more_filter", selectedLocalised))}
            <BsChevronDown
              className="at-table-search__chevron"
              style={{ transform: showMoreFilter ? "rotateZ(-180deg)" : "" }}
            />
          </button>
        )}
        <RenderSearchReset
          showResetButton={showResetButton}
          searchParams={searchParams}
          onChangeSearchParams={onChangeSearchParams}
          getListAPI={getListAPI}
          mode={mode}
          onToggleSearchModal={onToggleSearchModal}
          searchLabel={searchLabel}
          handleSearch={handleSearch}
          renderAdditionalFilterActions={renderAdditionalFilterActions}
          getLocalised={getLocalised}
          selectedLocalised={selectedLocalised}
        />  
      </div>
    </>
  );

  return (
    <>
      {mode === "desktop" && renderDesktopMode()}
      {mode === "mobile" && (
        <ModalDialog
          title={"Filter & Search"}
          onClose={() => onToggleSearchModal(false)}
          children={
            <div className="row mb-10">
              {renderSearchMode && (
                <div className="col-md-12">{renderSearchMode}</div>
              )}
              {searchParams &&
                searchParams.map((item, index) => {
                  if (!item.additional) {
                    return (
                      <div
                        key={item.value}
                        className={`col-md-${item.col ? item.col : 6} mb-3`}
                      >
                        <label
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: 5,
                          }}
                        >
                          {item.label}
                        </label>
                        <br />
                        {renderInputForm(item, index)}
                      </div>
                    );
                  }
                })}
            </div>
          }
          footer={
            <div className="at-table__search-action">
              <RenderSearchReset
                showResetButton={showResetButton}
                searchParams={searchParams}
                onChangeSearchParams={onChangeSearchParams}
                getListAPI={getListAPI}
                mode={mode}
                onToggleSearchModal={onToggleSearchModal}
                searchLabel={searchLabel}
                handleSearch={handleSearch}
                renderAdditionalFilterActions={renderAdditionalFilterActions}
              />  
            </div>
          }
        />
      )}
      {!mode && (
        <>
          <div className="form-row gy-3 mb-10">
            {searchParams &&
              searchParams.map((item, index) => {
                if (!item.additional) {
                  return (
                    <div
                      key={item.value}
                      className={`col-md-${item.col ? item.col : 6} mb-3`}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {item.label}
                      </label>
                      <br />
                      {renderInputForm(item, index)}
                    </div>
                  );
                }
              })}
          </div>
          <RenderSearchReset
            showResetButton={showResetButton}
            searchParams={searchParams}
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
            mode={mode}
            onToggleSearchModal={onToggleSearchModal}
            searchLabel={searchLabel}
            handleSearch={handleSearch}
            renderAdditionalFilterActions={renderAdditionalFilterActions}
          />  
        </>
      )}
    </>
  );
};

export default SearchContent;