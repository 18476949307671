import Moment from "moment";
import React, { Component } from "react";
import { Card, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AiTwotoneCalendar } from "react-icons/ai";

import AtlasIcon from "components/Icon/atlasIcon";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

class TableContent extends Component {
  render = () => {
    let { rowItem, onClick } = this.props;
    return (
      <Card
        classes={{ root: "at-newsfeed-item_card" }}
        onClick={() => onClick(rowItem)}
      >
        <div
          className={"d-flex"}
          style={{ backgroundColor: "#FFFF", position: "relative" }}
        >
          <img
            alt="newsfeed"
            src={rowItem.teaser_image_url}
            className="at-newsfeed-image"
          />
          {rowItem.is_new && (
            <div
              className={"position-absolute at-newsfeed-title_new_badge"}
              style={{ top: 10, left: 10 }}
            >
              <AtlasIcon
                svgHref={"atlas-cd"}
                style={{
                  width: 14,
                  height: 14,
                  fill: "#C2410C",
                  marginRight: 5,
                }}
              />
              New
            </div>
          )}
        </div>
        <div className="at-newsfeed-item_details">
          <h4 className="at-newsfeed-title">{rowItem.title}</h4>
          <div className="d-flex justify-content-between align-items-center">
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              placement={"top"}
              title={`Publish Date`}
            >
              <p
                style={{
                  fontSize: 14,
                  color: "#374151",
                  marginTop: "auto",
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-calendar-2"}
                  style={{
                    width: 18,
                    height: 18,
                    fill: "#3B82F6",
                    marginRight: 5,
                  }}
                />
                {Moment(rowItem.publish_at, "YYYY-MM-DD").isValid()
                  ? Moment(rowItem.publish_at, "YYYY-MM-DD").format(
                      "DD MMM YYYY",
                    )
                  : "N/A"}
              </p>
            </DarkTooltip>
          </div>
        </div>
      </Card>
    );
  };
}

export default TableContent;
