export const StatusLib = [
  {
    id: "0",
    name: "Draft",
  },
  {
    id: "1",
    name: "Active",
  },
];

export const AgreementType = [
  { id: "1", name: "REN Appointment NDA Letter" },
  { id: "2", name: "PEA Appointment NDA Letter" },
];
