import { useEffect, useState } from "react";
import Frame from "react-frame-component";
import ReactHtmlParser from "react-html-parser";

import ModalDialog from "components/Modal/ModalDialog";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const PreviewEmailTemplate = ({ id, handleClose }) => {
  const [htmlCode, setHtmlCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id !== 0) {
      Get(
        `/admin/email_templates/${id}`,
        (data) => setHtmlCode(data.html_code),
        (err) => requestError(err),
        setLoading,
      );
    }
  }, [id]);

  return (
    <ModalDialog
      title={"Preview Template"}
      onLoad={loading}
      onClose={handleClose}
    >
      <div className={"at-email-campaigns__preview-dialog"}>
        {!loading && (
          <div className={"email-preview__body"}>
            <Frame className={"iframe"}>
              {htmlCode && ReactHtmlParser(htmlCode)}
            </Frame>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};

export default PreviewEmailTemplate;
