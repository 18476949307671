import { fabric } from "fabric";
import _ from "lodash";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const FabricContext = createContext();

const Direction = {
  LEFT: 0,
  UP: 1,
  RIGHT: 2,
  DOWN: 3,
};

export const FabricProvider = ({ children }) => {
  const canvas = useFabricProvider();

  return (
    <FabricContext.Provider value={canvas}>{children}</FabricContext.Provider>
  );
};

export const useFabric = () => useContext(FabricContext);

const useFabricProvider = () => {
  const [canvas, setCanvas] = useState("");
  const [tempClipID, setClipID] = useState(null);
  const [tempEditTextObj, setTextObj] = useState(null);
  const [showTextModal, setTextModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isSpanRotation, setIsSpanRotation] = useState(false);

  const filePond = useRef(null);

  useEffect(() => {
    const canvas = new fabric.Canvas("canvas", {
      width: 1240,
      height: 1754,
      opacity: 0.9,
      backgroundColor: "white",
    });

    setCanvas(canvas);
  }, []);

  useEffect(() => {
    if (canvas) {
      onHandlingDistortion();
    }
  }, [canvas]);

  useEffect(() => {
    snapRotation();
  }, [isSpanRotation]);

  useEffect(() => {
    controlHandler();

    if (canvas && selectedTemplate) {
      if (selectedTemplate.type_id === 3) {
        canvas.setDimensions({ width: 1754, height: 1754 });
      } else {
        canvas.setDimensions({ width: 1240, height: 1754 });
      }
    }
  }, [canvas, selectedTemplate]);

  const resizeCanvas = (typeId, heightOffset = 0) => {
    const isSocialMediaGraphic = typeId === 3;
    const windowWidth = window.innerWidth;
    const containerWidth = document.querySelector(".fabric-canvas-wrapper");
    const canvasEditor = document.querySelector("#canvas-editor");

    let scaleFactor = 0.4,
      editorHeight = 734;

    if (!isSocialMediaGraphic && windowWidth <= 576) {
      scaleFactor = 0.24;
      editorHeight = 456;
    } else if (isSocialMediaGraphic && windowWidth <= 576) {
      scaleFactor = 0.17;
      editorHeight = 334;
    } else if (isSocialMediaGraphic && windowWidth <= 786) {
      scaleFactor = 0.28;
      editorHeight = 526;
    }

    containerWidth.style.transform = `scale( ${scaleFactor} )`;
    canvasEditor.style.height = `${editorHeight + heightOffset}px`;
  };

  const resetZoomView = () => {
    if (canvas) {
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    }
  };

  const controlHandler = () => {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "blue";
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 30;

    customDeleteIcon();
  };

  const onHandlingDistortion = () => {
    canvas.on("object:scaling", (event) => {
      if (event.target._objects) {
        const {
          scaleX,
          scaleY,
          width: targetWidth,
          height: targetHeight,
        } = event.target;

        if (event.target) {
          event.target._objects.forEach((objChild) => {
            if (objChild.type === "text" || objChild.type === "textbox") {
              objChild.set({
                scaleX: 1 / scaleX,
                scaleY: 1 / scaleY,
                originX: "center",
                originY: "center",
                ...(objChild.type === "textbox" && {
                  width: targetWidth * scaleX,
                  height: targetHeight * scaleY,
                }),
              });
              canvas.renderAll();
            }
          });
        }
      }
    });
  };

  const customDeleteIcon = () => {
    const deleteIcon =
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
    const img = document.createElement("img");
    img.src = deleteIcon;

    function renderIcon(ctx, left, top, _, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    }

    const deleteObject = (_, transform) => {
      const target = transform.target;

      canvas.remove(target);
      canvas.requestRenderAll();

      if (target.type === "image") {
        let restored = null;

        selectedTemplate.content.objects.forEach((item) => {
          if (item.id === target.clipPath.id) {
            restored = item;
          }
        });

        if (restored) {
          const style = {
            ...restored.objects[0],
            hoverCursor: "pointer",
            absolutePositioned: true,
            selectable: false,
          };

          const text = new fabric.Text(restored.objects[1].text, {
            ...restored.objects[1],
          });

          const shape =
            restored.id.indexOf("rect") > -1
              ? new fabric.Rect(style)
              : new fabric.Circle(style);

          const group = new fabric.Group([shape, text], {
            ...restored,
          });

          canvas.add(group);
          canvas.renderAll();
        }
      }
    };

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -50,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 50,
    });
  };

  const addShapeClipPath = (index, context, label, type) => {
    const canvasCenter = canvas.getCenter();
    const tempStyle = {
      left: canvasCenter.left,
      top: canvasCenter.top,
      originX: "center",
      originY: "center",
      opacity: 0.5,
      fill: type === "img" ? "black" : "#f18e03",
    };

    // Clip path shape handler
    const shape =
      context === "circle"
        ? new fabric.Circle({
            ...tempStyle,
            radius: 180,
          })
        : new fabric.Rect({
            ...tempStyle,
            ...(type === "img"
              ? { width: 500, height: 500 }
              : { width: 500, height: 250 }),
          });

    // Text handler for image/text container
    const text =
      type === "img"
        ? new fabric.Text(label, {
            fontSize: 50,
            fill: "white",
            fontWeight: 500,
            left: shape.left,
            top: shape.top,
            textAlign: "center",
            originX: "center",
            originY: "center",
          })
        : new fabric.Textbox(label, {
            fontSize: 50,
            width: context === "circle" ? 360 : 500,
            fill: "white",
            strokeUniform: true,
            textAlign: "center",
            originX: "center",
            originY: "center",
            hasBorders: false,
            editable: true,
            left: shape.left,
            top: shape.top,
          });

    type === "text" &&
      text.setControlsVisibility({
        mtr: true,
        mt: false,
        mb: false,
        tr: false,
        tl: false,
        br: false,
        bl: false,
        ml: true,
        mr: true,
      });

    // Grouping text and shape
    const group = new fabric.Group([shape, text], {
      id: `shape_${index}_${type}_${context}`,
      shapeType: type,
      shapeLabel: label,
    });

    canvas.add(group);
    canvas.renderAll();
  };

  const setBGImg = (url) => {
    canvas &&
      fabric.Image.fromURL(url, (img) => {
        const canvasCenter = canvas.getCenter();

        canvas.backgroundImage = img;
        img.scaleToHeight(1754);
        img.originX = "center";
        img.originY = "center";
        img.left = canvasCenter.left;
        img.top = canvasCenter.top;

        canvas.requestRenderAll();
      });
  };

  // Add image within clip path
  const addImage = (base64, clipPathData) => {
    fabric.Image.fromURL(base64, (img) => {
      const { top, left, id: clipID } = clipPathData;
      const tempLeftToCenter =
        left + (clipPathData.objects[0].width / 2) * clipPathData.scaleX;
      const tempTopToCenter =
        top + (clipPathData.objects[0].height / 2) * clipPathData.scaleY;

      const tempStyle = {
        ...clipPathData.objects[0],
        id: clipID,
        absolutePositioned: true,
        scaleX: clipPathData.scaleX,
        scaleY: clipPathData.scaleY,
        left: tempLeftToCenter,
        top: tempTopToCenter,
      };
      const clipPath =
        clipID.indexOf("circle") > -1
          ? new fabric.Circle({
              type: "circle",
              ...tempStyle,
            })
          : new fabric.Rect({
              ...clipPathData,
              type: "rect",
              absolutePositioned: true,
            });

      const oImg = img.set(
        {
          left: tempLeftToCenter,
          top: tempTopToCenter,
          originX: "center",
          originY: "center",
          clipPath,
        },
        { crossOrigin: "Anonymous" },
      );

      oImg.scaleToWidth(clipPathData.width * clipPath.scaleX, false);

      canvas.getObjects().forEach((objChild) => {
        objChild.id === clipID && canvas.remove(objChild);
      });

      canvas.add(oImg);
      canvas.requestRenderAll();
    });
  };

  // Keyboard move action
  const moveSelected = (direction) => {
    if (canvas) {
      const activeObject = canvas.getActiveObject();

      if (activeObject) {
        switch (direction) {
          case Direction.LEFT:
            activeObject.left -= 10;
            break;
          case Direction.UP:
            activeObject.top -= 10;
            break;
          case Direction.RIGHT:
            activeObject.left += 10;
            break;
          case Direction.DOWN:
            activeObject.top += 10;
            break;
          default:
            break;
        }

        activeObject.setCoords();
        canvas.renderAll();
      } else {
        console.warn("No object selected");
      }
    }
  };

  const mouseEvents = () => {
    fabric.util.addListener(document.body, "keydown", (options) => {
      if (!options.repeat) {
        const key = options.which || options.keyCode;

        switch (key) {
          case 37:
            moveSelected(Direction.LEFT);
            break;
          case 38:
            moveSelected(Direction.UP);
            break;
          case 39:
            moveSelected(Direction.RIGHT);
            break;
          case 40:
            moveSelected(Direction.DOWN);
            break;
          default:
            break;
        }
      }
    });
  };

  const onWheelZoomEvent = () => {
    if (canvas) {
      canvas.on("mouse:wheel", (opt) => {
        const activeObject = canvas.getActiveObject();
        let zoom = canvas.getZoom();
        const delta = opt.e.deltaY;

        zoom *= 0.999 ** delta;

        if (zoom > 20) {
          zoom = 20;
        }

        if (zoom < 0.01) {
          zoom = 0.01;
        }

        if (activeObject) {
          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        } else {
          canvas.setZoom(zoom);
        }

        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }
  };

  const snapRotation = (deg = 45) => {
    if (canvas) {
      const angle = isSpanRotation ? deg : 1;

      fabric.Object.prototype.set({
        snapThreshold: Math.round(deg / 2),
        snapAngle: angle,
      });
    }
  };

  const adminToolEvents = () => {
    mouseEvents();
    onWheelZoomEvent();
  };

  const initEvent = (canvas) => {
    canvas.__eventListeners = {};
    canvas.on("mouse:up", (e) => {
      if (e.currentTarget) {
        const { id, shapeType } = e.currentTarget;

        setClipID(id);

        if (shapeType === "img") {
          filePond.current.click();
        }
      }
    });
  };

  // Double click handler
  const fabricDblClick = (obj, handler) => {
    return () => {
      if (obj.clicked) {
        handler(obj);
      } else {
        obj.clicked = true;
        setTimeout(() => (obj.clicked = false), 500);
      }
    };
  };

  const ungroup = (group) => {
    const items = group._objects;

    group._restoreObjectsState();
    canvas.remove(group);

    for (let i = 0; i < items.length; i++) {
      canvas.add(items[i]);
    }

    canvas.renderAll();

    return items;
  };

  const postUngroupHandler = (tempUngroup, tempObj) => {
    canvas.setActiveObject(tempUngroup[1]);
    tempUngroup[1].enterEditing();
    tempUngroup[1].selectAll();
    setTextObj(tempUngroup[1]);
    setTextModal(true);

    tempUngroup[1].on("editing:exited", () => {
      const items = [];

      canvas.forEachObject((obj) => {
        if (
          obj.type === "textbox" ||
          obj.type === "rect" ||
          obj.type === "circle"
        ) {
          items.push(obj);
          canvas.remove(obj);
        }
      });

      if (items.length > 0) {
        const grp = new fabric.Group(items, {
          id: tempUngroup.id,
          shapeLabel: tempUngroup.shapeLabel,
          shapeType: "text",
          clipPath: tempObj.clipPath,
        });

        const { objects } = selectedTemplate.content;
        const tempMatch = _.find(objects, { id: tempObj.clipPath.id });

        grp.setControlsVisibility({ deleteControl: !tempMatch });

        canvas.add(grp);
        grp.on(
          "mousedown",
          fabricDblClick(grp, () => {
            postUngroupHandler(ungroup(grp), grp);
          }),
        );
        canvas.renderAll();
      }
    });
  };

  return {
    canvas,
    filePond,
    tempClipID,

    setIsSpanRotation,
    isSpanRotation,

    showTextModal,
    tempEditTextObj,
    selectedTemplate,
    setSelectedTemplate,
    setTextModal,
    ungroup,
    postUngroupHandler,
    setClipID,
    fabricDblClick,
    initEvent,
    addShapeClipPath,
    setBGImg,
    addImage,
    adminToolEvents,
    snapRotation,
    resetZoomView,
    resizeCanvas,
  };
};
