import React, { Component } from 'react'
import Moment from 'moment'

import AtlasButton from 'components/Button'
import AtlasIcon from 'components/Icon/atlasIcon'
import LoadingModal from 'components/LoadingModal'
import AtlasRadioGroup from 'components/RadioGroup'
import CustomFormHeader from 'components/Form/header'
import CustomFormInput from 'components/Input/formInput'

import CardBackground from 'assets/images/card-bg.png'
import permissionsChecker from 'utils/permissionsChecker'

const RadioOptions = [
  { label: "On", value: "On" },
  { label: "Off", value: "Off" },
];

const WaitingRoomOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export default class CreateZoomContent extends Component {
  state = {
    passcode: "",
    waitingRoom: true,
    hostFlag: "Off",
    participantsFlag: "On",
    process_status: null,
  };

  componentDidMount = () => this.props.selectedEventZoom && this.initState();

  componentDidUpdate = (pp) => {
    if (
      pp.selectedEventZoom !== this.props.selectedEventZoom &&
      Object.keys(this.props.selectedEventZoom).length > 0
    ) {
      this.initState();
    }
  };

  initState = () => {
    const {
      password,
      waiting_room,
      host_video,
      participant_video,
      process_status,
    } = this.props.selectedEventZoom;

    this.setState({
      passcode: password,
      waitingRoom: waiting_room,
      hostFlag: host_video,
      participantsFlag: participant_video,
      process_status,
    });
  };

  renderEventDetails = () => {
    const { name, start_date_time, end_date_time, description } =
      this.props.selectedEvent;

    let tempStartDate =
      start_date_time === "N/A"
        ? "N/A"
        : Moment(start_date_time).format("YYYY-MM-DD LT");
    let tempEndDate =
      end_date_time === "N/A"
        ? "N/A"
        : Moment(end_date_time).format("YYYY-MM-DD LT");

    return (
      <div className="at-form__content" style={{ background: `#fff url('${ CardBackground }') right top/auto no-repeat` }}>
        <div className="grid-control">
          <section className="grid-full-col d-flex align-items-center">
            <AtlasIcon svgHref={ 'atlas-zoom' } style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 10, fill: "#3B82F6" }} />
            <div className={ 'ml-2' } style={{ width: 'calc(100% - 40px)' }}>
              <h4 className="at-form-input__title">{ name }</h4>
              <p>{ description }</p>
            </div>
          </section>
          <section className="grid-third-col d-flex align-items-center">
            <div className='at-badge-icon-sm at-badge-icon-warning' >
              <AtlasIcon svgHref={ 'atlas-calendar' } />
            </div>
            <div className={ 'ml-2' } style={{ width: 'calc(100% - 40px)' }}>
              <h4 className="at-form-input__title">{ `Start Datetime` }</h4>
              <p>{ tempStartDate }</p>
            </div>
          </section>
          <section className="grid-third-col d-flex align-items-center">
            <div className='at-badge-icon-sm at-badge-icon-warning' >
              <AtlasIcon svgHref={ 'atlas-calendar' } />
            </div>
            <div className={ 'ml-2' } style={{ width: 'calc(100% - 40px)' }}>
              <h4 className="at-form-input__title">{ `End Datetime` }</h4>
              <p>{ tempEndDate }</p>
            </div>
          </section>
          {
            this.state.process_status && (
              <section className="grid-third-col d-flex align-items-center">
                <div className='at-badge-icon-sm at-badge-icon-warning' >
                  <AtlasIcon svgHref={ 'atlas-frame' } />
                </div>
                <div className={ 'ml-2' } style={{ width: 'calc(100% - 40px)' }}>
                  <h4 className="at-form-input__title">{ `Attendance Update Status` }</h4>
                  <p>{ this.state.process_status }</p>
                </div>
              </section>
            ) 
          }
        </div>
      </div>
    );
  };

  renderSettingsContent = () => {
    const { mode } = this.props;
    const { can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );
    return (
      <> 
        <CustomFormHeader
          title={ 'Settings' }
          className={ 'mb-3' }
          moduleIcon={ 'atlas-setting-2' } />
        <div className="grid-control">
          <div className='grid-half-col at-form__content'>
            <h2 className='fs-4 fw-600'>Security</h2>
            <h4 className="at-form-input__title mt-3" required>Passcode</h4>
            <CustomFormInput
              disabled={ mode === 'edit' && !can_update }
              type="text"
              containerStyle={{ maxWidth: 1000 }}
              value={ this.state.passcode }
              onChangeValue={ val => this.setState({ passcode: val }) }
              required={ true }
              placeholder={ 'Auto-generated if left blank.' } />
            <h4 className="at-form-input__title mt-3" required>Waiting Room</h4>
            <AtlasRadioGroup
              horizontal={ true }
              disabled={ mode === 'edit' && !can_update }
              checkedValue={ this.state.waitingRoom }
              containerClassName={ 'w-100' }
              selectedRadioValue={ val => this.setState({ waitingRoom: val }) }
              options={ WaitingRoomOptions } />
          </div>
          <div className='grid-half-col at-form__content'>
            <h2 className='fs-4 fw-600'>Video</h2>
            <h4 className="at-form-input__title mt-3" required>Host</h4>
            <AtlasRadioGroup
              horizontal={ true }
              disabled={ mode === 'edit' && !can_update }
              checkedValue={ this.state.hostFlag }
              containerClassName={ 'w-100' }
              selectedRadioValue={ val => this.setState({ hostFlag: val }) }
              options={ RadioOptions } />
            <h4 className="at-form-input__title mt-3" required>Participants</h4>
            <AtlasRadioGroup
              horizontal={ true }
              disabled={ mode === 'edit' && !can_update }
              checkedValue={ this.state.participantsFlag }
              containerClassName={ 'w-100' }
              selectedRadioValue={ val => this.setState({ participantsFlag: val }) }
              options={ RadioOptions } />
          </div>
        </div>
      </>
    );
  };

  renderCardContent = () => {
    const {
      mode,
      selectedEvent,
      selectedEventZoom,
      getStartZoomLink,
      updateAttendance,
      refreshProcessingStatus,
    } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );
    return (
      <>
        { this.renderEventDetails() }
        { this.renderSettingsContent() }
        {
          mode === 'create' && (
            <div className="at-modal_dialog-container-footer g-3">
              {
                ( mode === 'create' && can_create ) && (
                  <AtlasButton
                    className={ 'btn-new btn-new--primary' }
                    children={ 'Create' }
                    onClick={ () => {
                      let temp = {
                        password: this.state.passcode,
                        waiting_room: this.state.waitingRoom,
                        host_video: this.state.hostFlag === 'On',
                        participant_video: this.state.participantsFlag === 'On'
                      }
                      mode === "create"
                        ? this.props.createEventZoomSession( selectedEvent.id, temp )
                        : this.props.editEventZoomSession( selectedEvent.id, temp )
                    } } />
                )
              }
              <button
                type={ 'button' }
                className="btn-new btn-new--outline-secondary"
                onClick={ () => this.props.onClose() }>
                Cancel
              </button>
            </div>
          )
        }
        {
          ( mode === 'edit' && can_update ) && (
            <div className={ 'at-modal_dialog-container-footer g-3' }>
              <button
                className={ 'btn-new btn-new--primary' }
                onClick={ () => getStartZoomLink( selectedEvent.id ) }>
                View Meetings
              </button>
              {
                can_update && (
                  <>
                    <button
                      disabled={ selectedEventZoom.process_status !== 'Pending' }
                      className={ 'btn-new btn-new--secondary' }
                      onClick={ () => updateAttendance( selectedEventZoom.event_id ) }>
                      Sync Attendance
                    </button>
                    <button
                      disabled={ selectedEventZoom.process_status === 'Processed' }
                      className={ 'btn-new btn-new--secondary' }
                      onClick={ () => refreshProcessingStatus( selectedEventZoom.event_id ) }>
                      Refresh status
                    </button>
                  </>
                )
              }
              <button
                type={ 'button' }
                className="btn-new btn-new--outline-secondary"
                onClick={ () => this.props.onClose() }>
                Close
              </button>
            </div>
          )
        }
      </>
    );
  };

  render = () => {
    return (
      <>
        {this.renderCardContent()}
        {this.props.onLoadZoom && <LoadingModal />}
      </>
    );
  };
}
