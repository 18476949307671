import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      activities: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getActivities = (id) =>
      Get(
        `/project_sales/${id}/activities`,
        this.getActivitiesSuccess,
        this.getActivitiesError,
        this.load,
      );
    getActivitiesSuccess = (payload) => this.setState({ activities: payload });
    getActivitiesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            activities={this.state.activities}
            onLoadActivity={this.state.loading}
            getActivities={this.getActivities}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
