import React, { Component } from "react";
import _ from "lodash";
import { Grid, Snackbar, Card } from "@material-ui/core";

import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasTextarea from "components/Input/textarea";
import DocumentElement from "./documentElement";

import checkValid from "utils/checkValid";

const ClaimTypeOptions = [
  { id: 1, name: "Booking Form", value: 1, uploadLabel: "Booking Form upload" },
  {
    id: 2,
    name: "Extension",
    value: 2,
    uploadLabel: "Extension Form document upload",
  },
  {
    id: 3,
    name: "Add Name",
    value: 3,
    uploadLabel: "Add Name Form/IC COLOUR copy upload",
  },
  {
    id: 4,
    name: "Change Unit",
    value: 4,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 5,
    name: "Cancellation",
    value: 5,
    uploadLabel: "Cancellation Form/Rejection Letter upload",
  },
  {
    id: 6,
    name: "EPF",
    value: 6,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 7,
    name: "Deferment",
    value: 7,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 8,
    name: "Reimbursement",
    value: 8,
    uploadLabel: "Letter of Authorization Form upload",
  },
  {
    id: 9,
    name: "Change Contact Details",
    value: 9,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 10,
    name: "Others",
    value: 10,
    uploadLabel: "Others (optional for document)",
  },
];

class Create extends Component {
  state = {
    showSnackbar: false,
    showDocumentModal: false,
    selectedDocument: {
      document: "",
      document_file_name: "",
    },
    createRequestForm: {
      type_id: null,
      remark: "",
      project_sale_request_documents_attributes: [],
    },
  };

  onChangeNewRequest = (val, context) => {
    let tmp = _.cloneDeep(this.state.createRequestForm);
    tmp[context] = val;

    this.setState({ createRequestForm: tmp });
  };

  onRemoveDoc = (index) => {
    let tmp = _.cloneDeep(this.state.createRequestForm);
    _.remove(
      tmp.project_sale_request_documents_attributes,
      (_, docIndex) => docIndex === index,
    );

    this.setState({ createRequestForm: tmp });
  };

  onChangeDoc = (fileItems) => {
    let tmp = _.cloneDeep(this.state.createRequestForm);

    if (
      tmp.project_sale_request_documents_attributes.length +
        Object.keys(fileItems).length >
      5
    ) {
      return this.setState({ showSnackbar: true });
    }

    fileItems &&
      Object.keys(fileItems).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          tmp.project_sale_request_documents_attributes = [
            ...tmp.project_sale_request_documents_attributes,
            {
              document: e.target.result,
              document_file_name: fileItems[key].name,
            },
          ];
          this.setState({ createRequestForm: tmp });
        };
        reader.readAsDataURL(fileItems[key]);
      });
  };

  renderDocumentContent = () => {
    const { createRequestForm } = this.state;
    let tempUploadTitle = _.find(ClaimTypeOptions, {
      id: createRequestForm.type_id,
    });

    return (
      <>
        <h2 className="at-form-input__title">
          {tempUploadTitle ? tempUploadTitle.uploadLabel : "Upload"}
        </h2>
        {createRequestForm.project_sale_request_documents_attributes.length <
          5 && (
          <button
            className="btn-new btn-new--secondary mb-10"
            onClick={() => this.pond.click()}
          >
            Add Document
          </button>
        )}
        <input
          multiple={true}
          type="file"
          className={"d-none"}
          ref={(ref) => (this.pond = ref)}
          onChange={(e) => this.onChangeDoc(e.target.files)}
          accept="application/pdf, image/*"
        />
        {createRequestForm.project_sale_request_documents_attributes.length >
          0 && (
          <Card style={{ padding: 15 }}>
            <Grid spacing={16} container>
              {createRequestForm.project_sale_request_documents_attributes.map(
                (doc, index) => {
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <DocumentElement
                        doc={doc}
                        docIndex={index}
                        isUnsaved={true}
                        showRemoveButton={true}
                        onClickOpenDoc={() =>
                          this.setState({
                            showDocumentModal: true,
                            selectedDocument: doc,
                          })
                        }
                        onRemoveDoc={() => this.onRemoveDoc(index)}
                      />
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Card>
        )}
        {createRequestForm.project_sale_request_documents_attributes.length <
          1 && (
          <i className={"d-block"}>
            Please drop your document to be upload. 5 files is the maximum
            quantity for upload.
          </i>
        )}
      </>
    );
  };

  render = () => {
    const { selectedSale, showConfirmCreateRequest } = this.props;
    let { createRequestForm } = this.state;

    return (
      <>
        <ModalDialog
          title={"Create Request"}
          onClose={() =>
            this.props.onChangeRequestHOC("showCreateRequest", false)
          }
          children={
            <>
              <div className="at-card__description mb-10">
                Create a request in order for the admins to facilitate the
                needed attention on this specific sale. Select which request
                type, upload documents that are needed by the admins (at least 1
                document upload is needed), and type any remarks that you may
                need to add for the admins to take note.
              </div>
              <form
                className="grid-control"
                onSubmit={(e) => e.preventDefault()}
              >
                <section className="grid-full-col">
                  <h2 className="at-form-input__title mb-2">{"Request Type"}</h2>
                  <Grid container spacing={16} style={{ marginTop: 0 }}>
                    {ClaimTypeOptions.map((item) => {
                      return (
                        <Grid
                          key={item.id}
                          item
                          md={4}
                          xs={12}
                          style={{ padding: "0px 8px" }}
                        >
                          <input
                            className="at-radio__input"
                            type="radio"
                            value={item.value}
                            checked={item.value === createRequestForm.type_id}
                          />
                          <label
                            className="at-radio__label"
                            onClick={() =>
                              this.onChangeNewRequest(item.value, "type_id")
                            }
                          >
                            <div className="at-radio__checker"></div>
                            <p className="at-radio__content">{item.name}</p>
                          </label>
                        </Grid>
                      );
                    })}
                  </Grid>
                </section>
                <section className="grid-full-col mt-20">
                  {this.renderDocumentContent()}
                </section>
                <section className="grid-full-col mt-20">
                  <h2 className="at-form-input__title mb-2">{"Remark"}</h2>
                  <AtlasTextarea
                    value={createRequestForm.remark}
                    onChangeValue={(val) =>
                      this.onChangeNewRequest(val, "remark")
                    }
                  />
                </section>
              </form>
            </>
          }
          footer={
            <div className="d-flex g-3">
              <button
                className="btn-new btn-new--primary"
                disabled={
                  (createRequestForm.type_id !== 10 &&
                    createRequestForm.project_sale_request_documents_attributes
                      .length < 1) ||
                  !createRequestForm.type_id
                }
                onClick={() => {
                  this.props.onChangeRequestHOC(
                    "showConfirmCreateRequest",
                    true,
                  );
                }}
              >
                Submit to Admin Now
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={() =>
                  this.props.onChangeRequestHOC("showCreateRequest", false)
                }
              >
                Cancel
              </button>
            </div>
          }
        />
        <ConfirmationModal
          open={showConfirmCreateRequest}
          title={"Submit to Admin"}
          message={"Are you sure you want to submit to Admin now ?"}
          loading={this.props.onLoadRequests}
          positiveText={"Submit"}
          negativeText={"Cancel"}
          positiveAction={() =>
            this.props.createRequest(selectedSale.id, createRequestForm)
          }
          negativeAction={() =>
            this.props.onChangeRequestHOC("showConfirmCreateRequest", false)
          }
        />
        {this.state.showDocumentModal && (
          <ModalDialog
            title={this.state.selectedDocument.document_file_name}
            onClose={() => this.setState({ showDocumentModal: false })}
            children={
              <>
                {checkValid(this.state.selectedDocument.document).indexOf(
                  "pdf",
                ) > -1 && (
                  <iframe
                    className={"w-100 h-100"}
                    src={this.state.selectedDocument.document}
                  ></iframe>
                )}
                {checkValid(this.state.selectedDocument.document).indexOf(
                  "pdf",
                ) === -1 && (
                  <img
                    src={this.state.selectedDocument.document}
                    alt="booking form image"
                  />
                )}
              </>
            }
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ showSnackbar: false })}
          message="Maximum file number can be 5 only."
        />
      </>
    );
  };
}

export default Create;
