import React, { Component } from "react";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import MeetingRooms from "./MeetingRooms";
import MyBookings from "./MyBookings";

import MeetingHubHOC from "./actions";
import "./index.scss";

const tabSection = [
  { value: 1, label: "Meeting Rooms" },
  { value: 2, label: "My Bookings" },
];

class MeetingHub extends Component {
  state = {
    showFailureModal: false,
    showSuccessModal: false,
    selectedSection: "Meeting Rooms",
  };

  componentDidMount = () => {
    if (window.location.href.includes("/fail")) {
      this.setState({ showFailureModal: true });
    } else if (window.location.href.includes("/success")) {
      this.setState({ showSuccessModal: true });
    }
    this.props.getBranches();
  }

  renderCardContent = () => (
    <>
      <CustomTab
        sections={tabSection}
        selectedSection={this.state.selectedSection}
        onSelectSection={(val) => this.setState({ selectedSection: val })}
      />
      {this.state.selectedSection === "Meeting Rooms" && (
        <MeetingRooms {...this.props} />
      )}
      {this.state.selectedSection === "My Bookings" && (
        <MyBookings {...this.props} />
      )}
    </>
  );

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Meeting Hub"}
          atlasIcon={"atlas-people-linear"}
          showPABadge={role === "Personal Assistant"}
          description={
            <>
              Effortlessly make and manage your room reservations with Meeting
              Hub! <br />
              To start booking, head to the Meeting Rooms section, use filters
              to find all the available rooms by your selected date & time.
              Click “Book Now” on your chosen room, and
              proceed to book by providing meeting details, start date & time,
              and your meeting duration. Manage your reservations easily in “My
              Bookings.” Choose to edit room details or cancel your booking.
            </>
          }
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.state.showFailureModal && (
          <ModalDialog
            children={
              <div className="d-flex flex-column g-3">
                <div className={"text-center"}>
                  <div className="at-confirmation_modal-icon">
                    <AtlasIcon
                      svgHref={"atlas-danger"}
                      style={{ fill: "#F04438", backgroundColor: "#FEE4E2" }}
                    />
                  </div>
                  <br />
                  <h4 className="fs-4 fw-600">Booking Payment Fail</h4>
                  <p className={"fs-2 mt-10 mb-20"}>The booking payment has failed. Please try again or contact our support team for assistance.</p>
                </div>
                <button
                  className="btn-new btn-new--primary w-100"
                  onClick={() => this.setState({ showFailureModal: false })}>
                  OK
                </button>
              </div>
            }
          />
        )}
        {this.state.showSuccessModal && (
          <ModalDialog
            maxWidth="sm"
            responsiveSize="sm"
            children={
              <div className="d-flex flex-column g-3">
                <div className={"text-center"}>
                  <div className="at-confirmation_modal-icon">
                    <AtlasIcon
                      svgHref={"atlas-tick-circle"}
                      style={{ fill: "#12B76A", backgroundColor: "#D1FADF" }}
                    />
                  </div>
                  <br />
                  <h4 className="fs-4 fw-600">Payment Successful!</h4>
                  <p className={"fs-2 mt-10 mb-20"}>Thank you for your booking! Please note that your booking is currently 
                    pending the admin’s approval. You should receive an update within 7 working days.
                  </p>
                  <p className={"fs-2 mt-10 mb-20"}>Your deposit for the booking is refundable and will be refunded to 
                    your bank account registered in Atlas within 14 working days after the event concludes. 
                    If you would like to make any changes, please get in touch with&nbsp;
                    <a style={{color: '#2563EB'}} href='mailto:events@iqiglobal.com' target='_blank'>
                      events@iqiglobal.com
                    </a>
                  </p>
                </div>
                <button
                  className="btn-new btn-new--primary w-100"
                  onClick={() => this.setState({ showSuccessModal: false })}>
                  OK
                </button>
              </div>
            }
          />
        )}
        {this.props.onLoadMeetingHub && <LoadingModal />}
      </>
    );
  };
}

export default MeetingHubHOC(MeetingHub);
