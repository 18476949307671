export const getTranslation = (key, language) => {
  const translation = japaneseTranslation.find((translation) => translation.key === key);
  if (translation) {
    return translation[language];
  }
  return key;
}

export const japaneseTranslation = [
  {
    key: "yes",
    en: "Yes",
    jp: "はい",
  },
  {
    key: "no",
    en: "No",
    jp: "いいえ",
  },
  {
    key: "submit",
    en: "Submit",
    jp: "提出する",
  },
  {
    key: 'clear',
    en: 'Clear',
    jp: '消去',
  },
  {
    key: "agent.appointment.registration_approved",
    en: "Registration Approved",
    jp: "登録が承認されました",
  },
  {
    key: "agent.appointment.checkbox_text",
    en: "I've acknowledge that I have read and agree to the above Terms and Conditions",
    jp: "上記の利用規約を読んで同意したことを認めます",
  },
  {
    key: "agent.appointment.drawing_board_title",
    en: "Sign Appointment Letter",
    jp: "任命状に署名する",
  },
  {
    key: "agent.appointment.drawing_board_adopt",
    en: "Adopt Signature",
    jp: "サインを採用する",
  },
]