import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoIosMenu } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomDraggableSort from "components/DraggableSort";
import RefundForm from "./refundForm";

const partyOptions = type_id => [
  { value: "1", label: (type_id === 1) ? 'Purchaser Sign First' : 'Tenant Sign First' },
  { value: "2", label: (type_id === 1) ? 'Vendor Sign First' : 'Landlord Sign First' },
]
const NewConfirmationModal = ({
  open,
  context,
  formId,
  type_id,
  showLoading,
  positiveText,
  signingType,
  include_inventory,
  eProcessPartyData = [],
  refundPartyInfo,
  refundFormData,
  getRefundForm,

  positiveAction,
  negativeAction,
  onChangePartyHOC,
}) => {
  const [signParty, setSignParty] = useState([]);
  const [checkedIncludeInventory, setCheckedIncludeInventory] = useState(false);

  useEffect(() => {
    processParty(signingType);
    setCheckedIncludeInventory(false);
  }, [eProcessPartyData]);

  const onChangeSigningType = (val) => {
    let tempParty = _.cloneDeep(eProcessPartyData);
    let tempBuyer = [];
    let tempSeller = [];
    tempParty.map((item) => {
      if (["Tenant/Purchaser", "Tenant/Purchaser's representative"].includes(item.party)) {
        tempBuyer.push(item);
        tempBuyer.sort((x, y) => (x.party === "Tenant/Purchaser" ? -1 : 1));
      } else {
        tempSeller.push(item);
        tempSeller.sort((x, y) => (x.party === "Landlord/Vendor" ? -1 : 1));
      }
    });
    if (val === "1") {
      tempParty = tempBuyer.concat(tempSeller);
    } else {
      tempParty = tempSeller.concat(tempBuyer);
    }
    tempParty.map((party, index) => (party.position = index + 1));
    onChangePartyHOC(tempParty, "eProcessPartyData");
    onChangePartyHOC(val, "signingType");
  };

  const processParty = (val) => {
    let tempParty = _.cloneDeep(eProcessPartyData);
    let tempBuyer = [];
    let tempBuyerRepresent = [];
    let tempSeller = [];
    let tempSellerRepresent = [];
    tempParty.map((item) => {
      if (item.party === "Tenant/Purchaser") 
        tempBuyer.push(item);
      else if (item.party === "Tenant/Purchaser's representative")
        tempBuyerRepresent.push(item);
      else if (item.party === "Landlord/Vendor") 
        tempSeller.push(item);
      else tempSellerRepresent.push(item);
    });
    setSignParty([
      {
        id: 1,
        data: val === "1" ? tempBuyer : tempSeller,
        role: val === "1" ? tempBuyer[0]?.title :tempSeller[0]?.title,
      },
      {
        id: 2,
        data: val === "1" ? tempBuyerRepresent : tempSellerRepresent,
        role:
          val === "1"
            ? tempBuyerRepresent[0]?.title
            : tempSellerRepresent[0]?.title,
      },
      {
        id: 3,
        data: val === "1" ? tempSeller : tempBuyer,
        role: val === "1" ?tempSeller[0]?.title : tempBuyer[0]?.title,
      },
      {
        id: 4,
        data: val === "1" ? tempSellerRepresent : tempBuyerRepresent,
        role:
          val === "1"
            ? tempSellerRepresent[0]?.title
            : tempBuyerRepresent[0]?.title,
      },
    ]);
  };

  const renderParticipant = (partyData, disabled, lastItem) => (
    <div
      className={`d-flex ${ !lastItem ? 'py-2' : 'pt-2' }`}
      style={{ borderBottom: !lastItem ? "1px solid #D1D5DB" : "" }}>
      {disabled && <div className="at-form__draggable-icon"></div>}
      {!disabled && <IoIosMenu className="at-form__draggable-icon" />}
      <div className="row at-form__participant-card">
        <div className="d-flex">
          <AtlasIcon
            svgHref={ partyData.signee_type_id === 0 ? "atlas-icon-user" : "atlas-building-company"}
            style={{ fill: "#F0631D", color: "#F0631D" }}
          />      
          <div className="d-flex flex-column">
            <label>Name</label>
            {partyData.signee_type_id === 1 && (
              <>
                <p>{`${partyData.company_name}`}</p> 
                <p>{`(${partyData.company_registration_no})`}</p>
              </>
            )}
            <p>{partyData.full_name}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon svgHref={"atlas-personalcard"}/>
          <div className="d-flex flex-column">
            <label>NRIC</label>
            <p>{partyData.identification_number}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon svgHref={"atlas-sms"}/>
          <div className="d-flex flex-column">
            <label>Email</label>
            <p style={{ wordBreak: "break-all" }}>{partyData.email}</p>
          </div>
        </div>
        <div className="d-flex">
          <AtlasIcon svgHref={"atlas-mobile"}/>
          <div className="d-flex flex-column">
            <label>Mobile</label>
            <p style={{ wordBreak: "break-all" }}>
              {partyData.mobile_contact_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <AtlasDialog open={open}>
      <AtlasCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton mode="new" onClick={() => negativeAction()} />
              <h5 className="at-card__title">
                {"Confirmation to start e-signing process"}{" "}
              </h5>
            </div>
            <AlertBox
              mode={"info"}
              containerClassName="mb-3"
              description={
                <p className={"at-card__description fw-400"}>
                  Please confirm the participants' email, mobile number, and
                  NRIC/Passport No. are correct before starting e-signing.
                  {context !== "refund" && (
                    <>
                      <br />
                      <div className="d-flex align-items-center">
                        <IoIosMenu
                          className="at-form__draggable-icon"
                          style={{
                            width: 25,
                            height: 25,
                            minWidth: 25,
                            marginTop: 4,
                          }}
                        />
                        This icon indicates you can drag and drop participants
                        within the same category to change the signing order.
                      </div>
                    </>
                  )}
                </p>
              }
            />
            {context !== "refund" && (
              <>
                <h2 className="at-form-input__title">
                  Choose which party to sign first
                </h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={signingType}
                  containerClassName={"w-100 mb-3"}
                  selectedRadioValue={(val) => onChangeSigningType(val)}
                  options={partyOptions(type_id)}
                />
              </>
            )}
            {signParty.map((party, index) => (
              <div className="at-form__content">
                <p className="at-form__sign-party-title">
                  Step {index + 1}: Signed By {party.role}
                </p>
                <CustomDraggableSort
                  data={party.data}
                  grid={0}
                  disabled={party.data.length < 2 || context === "refund"}
                  background={"white"}
                  containerClass={"w-100"}
                  onChangeOrder={(val) => {
                    let temp = _.cloneDeep(signParty);
                    temp[index].data = val;
                    let partyData = [];
                    temp.map((party) => {
                      party.data.map((item) => {
                        partyData.push(item);
                      });
                    });
                    partyData.map(
                      (party, index) => (party.position = index + 1),
                    );
                    onChangePartyHOC(partyData, "eProcessPartyData");
                  }}
                  renderContent={(partyData, index) =>
                    renderParticipant(
                      partyData,
                      party.data.length < 2 || context === "refund",
                      index === party.data.length - 1
                    )
                  }
                />
              </div>
            ))}
            {context === "refund" && (
              <>
                <h5 className="at-card__title mt-20 mb-20">{"Refund Form"} </h5>
                <RefundForm
                  mode={"view"}
                  formId={formId}
                  eProcessPartyData={eProcessPartyData}
                  refundPartyInfo={refundPartyInfo}
                  refundFormData={refundFormData}
                  getRefundForm={getRefundForm}
                  onClickClose={() =>
                    this.props.onChangePartyHOC(false, "showRefundFormModal")
                  }
                />
              </>
            )}
            <div className="at-form__content at-form__confirmation">
              <h4 className="at-card__title">Proceed e-Signing</h4>
              <p className="d-flex flex-column fs-2">
                Please ensure that all the information are correct before
                proceeding.
              </p>
              {(context !== "refund" && type_id === 2 && include_inventory) && (
                <div className="d-flex flex-column mb-2">
                  <CustomCheckbox
                    checked={checkedIncludeInventory}
                    onChangeCheckboxValue={(event) => setCheckedIncludeInventory(event.target.checked)}
                    content={"Include client e-Signature from the Confirmation Form for the Inventory List"}
                    contentStyle={{ fontWeight: 600 }}
                    labelClassname={"ml-0 mb-0"} />
                  <span className="fs-2 fw-400 ml-4">
                    By ticking this box, clients agree to use their e-signature from the Confirmation Form for the Inventory List. 
                    This also confirms their agreement to the e-signing terms specific to the Inventory List, usually signed physically upon handing over possession.
                  </span>
                </div>
              )}
              <button
                className="btn-new btn-new--primary"
                style={{ width: "fit-content" }}
                onClick={() => positiveAction(context !== "refund" ? {checkedIncludeInventory: checkedIncludeInventory} : {})}>
                {positiveText || "Confirm Proceed"}
              </button>
            </div>
          </>
        }
      />
      {showLoading && <LoadingModal />}
    </AtlasDialog>
  );
};

export default NewConfirmationModal;
