import React, { Component } from "react";
import _ from "lodash";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      formData: {
        type_id: "1",
        buyer_name: "",
        booking_date: null,
        project_name: "",
        country_id: "",
        size: null,
        unit_number: "",
        purchase_price: null,
        package: null,
        nett_price: null,
        remark: "",

        booking_form: null,
        booking_form_file_name: null,
        project_sale_agents_attributes: [],
        project_location_id: null,
      },
      showAssignModal: false,
      showPDFModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeEditHOC = (val, context) => this.setState({ [context]: val });

    onChangeField = (val, context) => {
      let temp = _.cloneDeep(this.state.formData);
      temp[context] = val;

      this.props.onChangeSalesHOC(true, "unsavedChanges");
      this.setState({ formData: temp });
    };

    onChangeFile = (fileItems) => {
      let temp = _.cloneDeep(this.state.formData);

      if (fileItems && fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          temp.booking_form = e.target.result;
          temp.booking_form_file_name = fileItems[0].file.name;
          this.setState({ formData: temp });
        };
        reader.readAsDataURL(fileItems[0].file);
      } else {
        temp.booking_form = null;
        temp.booking_form_file_name = null;

        this.setState({ formData: temp });
      }
      this.props.onChangeSalesHOC(true, "formFileChanges");
      this.props.onChangeSalesHOC(true, "unsavedChanges");
    };

    onChangeRENPercentage = (val, index) => {
      let temp = _.cloneDeep(this.state.formData);
      temp.project_sale_agents_attributes[index].percentage = val;

      this.props.onChangeSalesHOC(true, "unsavedChanges");
      this.setState({ formData: temp });
    };

    onDeleteREN = (val) => {
      let temp = _.cloneDeep(this.state.formData);
      _.remove(temp.project_sale_agents_attributes, {
        user_id: val,
      });
      this.props.onChangeSalesHOC(true, "unsavedChanges");
      this.setState({ formData: temp });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            formData={this.state.formData}
            showPDFModal={this.state.showPDFModal}
            showAssignModal={this.state.showAssignModal}
            onChangeField={this.onChangeField}
            onChangeEditHOC={this.onChangeEditHOC}
            onChangeFile={this.onChangeFile}
            onChangeRENPercentage={this.onChangeRENPercentage}
            onDeleteREN={this.onDeleteREN}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
