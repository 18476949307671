import React from "react";

const TermsAndConditions = ({isBussinessLounge}) => {
  return (
    <>
      <p className={"text-justify"}>
        <span>
          When you book the {isBussinessLounge ? "business lounge" : "meeting room" }, you agree to the following terms and
          conditions:
        </span>
      </p>

      <ol className={"mt-2 text-justify"} style={{paddingRight: "2rem"}}>
        <li>
          Your booking is confirmed once you successfully complete the booking
          process. You can check your booking in &ldquo;My Booking&rdquo;. A
          &ldquo;Confirmed&rdquo; status indicates that your booking is
          successful.
        </li>
        <li>
          Please follow your booking time slots strictly by starting and ending
          your meeting on time.
        </li>
        <li>
          If you would like to cancel or make amendments to your booking, please
          do so 15 minutes before your booking or it might affect your future
          booking.
        </li>
        <li>
          Keep the room clean and tidy. Any damage caused during your booking
          will be your responsibility and may incur additional charges.
        </li>
        <li>
          Handle the equipment in the meeting room with care. Any misuse or
          damage to the equipment may result in suspension of booking privilege.
        </li>
        <li>Food is not allowed in the meeting room.</li>
        <li>
          Take care of your personal belongings. Do not leave them unattended.
        </li>
        <li>
          Please keep the discussion volume down to avoid disturbing other users
          outside the meeting room.
        </li>
        { isBussinessLounge && 
          <>
            <li>
              A refundable deposit is required to secure your booking.
            </li>
            <li>
            The full deposit will be returned within 14 days after the conclusion 
            of the event, provided that there is no damage (beyond normal wear 
            and tear) to the premises or equipment. In the event of damage such 
            as broken or malfunctioning equipment due to misuse, missing or lost 
            venue inventory (e.g., microphone, chairs), marks or scratches on 
            walls, doors, or furniture or excessive dirt or staining of flooring, 
            carpets, furniture, or linens, the Company reserves the right to use 
            the deposit to cover the cost of repairs or replacement.
            </li>
            <li>
              In the event of any damage, the replacement cost or the equipment's 
              fair market value, whichever is higher, will be charged.
            </li>
            <li>
              Refunds will be made to the bank account registered under your booking 
              with Atlas. To ensure a smooth refund process, please verify the 
              accuracy of your account information.
            </li>
          </>
        }
        <li>
          The management reserves the right to cancel bookings due to operational 
          requirements.
        </li>
        <li>
          The management reserves the right to update and amend these terms and
          conditions at any time without prior notice.
        </li>
      </ol>
    </>
  );
};

export default TermsAndConditions;
