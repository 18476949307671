import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import EventEditContent from "./components/editEvent";
import CreateEventContent from "./components/createEvent";

import EventsHOC from "./actions";
import BranchRegionHOC from "actions/branchRegion";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";

export const StatusColor = [
  { value: "Draft", colorName: "Grey" },
  { value: "Published", colorName: "Green" },
  { value: "Cancelled", colorName: "Red" },
];

const columnData = [
  {
    header: 'Date',
    accessor: 'start_date_time',
    searchFlag: 'start_date_time',
  },
  {
    header: 'Event Name',
    accessor: 'name',
    searchFlag: 'name',
    columnStyle: { width: '170%' }
  },
  {
    header: 'Type',
    accessor: 'type',
    searchFlag: 'type',
  },
  {
    header: 'Registered',
    accessor: 'registration_count',
    searchFlag: 'registration_count',
    columnStyle: { width: '90%' },
    contentClass: 'at-desktop-view__controller',
    columnClass: 'at-desktop-view__controller',
  },
  {
    header: 'Approved',
    accessor: 'event_attendee',
    searchFlag: 'event_attendee',
    columnStyle: { width: '90%' },
    contentClass: 'at-desktop-view__controller',
    columnClass: 'at-desktop-view__controller',
  },
  {
    header: 'Pending',
    accessor: 'pending',
    searchFlag: 'pending',
    columnStyle: { width: '90%' },
    contentClass: 'at-desktop-view__controller',
    columnClass: 'at-desktop-view__controller',
  },
  {
    header: 'Status',
    searchFlag: 'status',
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
    renderColumn: rowData => {
      const { colorName = '' } = _.find( StatusColor, { value: rowData.status }) || {}

      return (
        <div
          className="at-status_badge mt-1"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      )
    } 
  },
  {
    header: '',
    contentClass: 'at-mobile-view__controller',
    columnClass: 'at-mobile-view__controller',
    renderColumn: rowData => (
      <div className='at-event-training__mobile-stat'>
        <div>
          <label>Registered</label>
          <p>{ rowData.registration_count || 0 }</p>
        </div>
        <div>
          <label>Approved</label>
          <p>{ rowData.event_attendee || 0 }</p>
        </div>
        <div>
          <label>Pending</label>
          <p>{ rowData.pending || 0 }</p>
        </div>
      </div>
    )
  }
]
class EventTraining extends Component {
  componentDidMount = () => {
    this.props.getEventLists(1, "");
    this.props.getBranchRegions();
    initGA("/dashboard/admin/event-training");
  };

  onChangeFieldEdit = (val, context) => {
    if (context === "type_id") {
      if (val === 1) {
        this.props.onChangeFieldEdit(null, "training_type_id");
      }
    }
    if (context === "branch_region_id") {
      let tmpBranchRegion = _.find(this.props.branchRegions, { id: val });
      this.props.onChangeFieldEdit(tmpBranchRegion.address, "location");
      this.props.onChangeFieldEdit(
        tmpBranchRegion.google_map_url,
        "location_url",
      );
    }
    this.props.onChangeFieldEdit(val, context);
  };

  renderCardContent = () => {
    const {
      data,
      ticketTypeList,
      getEventLists,
      getEventQrCode,
      getSelectedEvent,
      onChangeEventHOC,
      branchRegions,
      onChangeFileEdit,
      onLoadEventTraining,
      showCreateEventDialog,
      showEditEventDialog,
      selectedEvent,
      getTicketTypeList,
      getAttachments,
    } = this.props;
    const { can_update } = permissionsChecker("Company Events", data);
    return (
      <>
        <AtPaginatedTable 
          rowData={ this.props.eventList.data || [] }
          meta={ this.props.eventList.meta }
          columns={ columnData }
          enableSort={ true }
          actionColumnContent={[
            {
              name: can_update ? "edit" : "view",
              onClick: (rowData) =>
                getSelectedEvent(rowData.id, "showEditEventDialog"),
            },
            {
              name: "export to ERP",
              disabled: (rowData) => rowData.reference_id > 0,
              onClick: (rowData) =>
                getSelectedEvent(rowData.id, "showExportConfirmation"),
            },
          ]}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) => onChangeEventHOC(val, "searchParams")}
          getListAPI={getEventLists}
          totalPages={this.props.eventPages}
        />
        { showCreateEventDialog && (
          <CreateEventContent
            data={ data }
            getEventLists={ getEventLists }
            getSelectedEvent={ getSelectedEvent }
            onChangeEventHOC={ onChangeEventHOC }
            branchRegions={ branchRegions }/>
        ) }
        { showEditEventDialog && (
          <EventEditContent
            data={ data }
            eventQr={ this.props.eventQr }
            eventID={ this.props.eventID }
            attachments={ this.props.attachments }
            ticketTypeList={ ticketTypeList }
            onChangeFieldEdit={ this.onChangeFieldEdit }
            getEventLists={ getEventLists }
            getEventQrCode={ getEventQrCode }
            getTicketTypeList={ getTicketTypeList }
            getSelectedEvent={ getSelectedEvent }
            onChangeEventHOC={ onChangeEventHOC }
            branchRegions={ branchRegions }
            onChangeFileEdit={ onChangeFileEdit }
            onLoadEventTraining={ onLoadEventTraining }
            selectedEvent={ selectedEvent }
            getAttachments={ getAttachments }/>
        ) }
        <ConfirmationModal
          title={"Export to ERP"}
          loading={this.props.onLoadExportUnitAdmin}
          open={this.props.showExportConfirmation}
          message={`Are you sure you want to export this event to ERP?`}
          positiveAction={() => this.props.exportToUnitAdmin(selectedEvent.id)}
          negativeAction={() =>
            this.props.onChangeEventHOC(false, "showExportConfirmation")
          }
        />
        {(this.props.onLoadEventTraining ||
          this.props.onLoadBranchRegions ||
          this.props.onLoadExportUnitAdmin) && <LoadingModal />}
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker(
      "Company Events",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={ 'Events' }
          atlasIcon={ 'atlas-calendar-circle' }
          actionButton={[{
            label: 'New Event',
            onShow: can_create,
            className: 'btn-new btn-new--primary',
            icon: <IoMdAdd style={{ height: 18, width: 18, marginRight: 0 }}/>,
            onClick: () => this.props.onChangeEventHOC( true, 'showCreateEventDialog' )
          }]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
      </>
    );
  };
}

export default compose(
  EventsHOC,
  BranchRegionHOC,
  VersionCheckHOC,
)(EventTraining);
