import React from "react";
import isEmptyValue from "utils/isEmpty";

import "./index.scss";

const renderValue = (value) => {
  let tmp;
  switch (typeof value) {
    case "string":
      return (tmp = `"${value}"`);
    case "number":
      return value;
    default:
      return `${value}`;
  }
};

const renderJsonContent = (objectData) => {
  return Object.entries(objectData).map(([key, value]) => {
    if (typeof value !== "object" || value === null) {
      return (
        <p
          key={`${key}-${value}`}
          className="ml-4"
        >{`${key}: ${renderValue(value)}`}</p>
      );
    } else {
      if (value instanceof Array) {
        return value.map((item, index) => (
          <p key={`${key}-${index}`} className="ml-4">
            <p className="ml-2">{`[`}</p>
            {JsonPrettifier(item)}
            <p>{`]`}</p>
          </p>
        ));
      } else {
        return JsonPrettifier(value);
      }
    }
  });
};

const prettyPrint = (objectData) => {
  return (
    <p className="ml-2">
      <p className="ml-2">{"{"}</p>
      {renderJsonContent(objectData)}
      <p>{"}"}</p>
    </p>
  );
};

const JsonPrettifier = (objectData) => (
  <div className="at-json_prettifier">{prettyPrint(objectData)}</div>
);

export default JsonPrettifier;
