import React, { useEffect } from "react";
import { compose } from "redux";
import { DialogContent } from "@material-ui/core";

import Tabs from "components/Tab";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";

import DetailTab from "./tabs/Details";
import PhotosTab from "./tabs/Photo";
import FloorPlanTab from "./tabs/FloorPlan";
import JuwaiSubsTab from "./tabs/JuwaiSubs";
import SettingTab from "./tabs/Settings";
import MediaLink from "./tabs/MediaLink/index";
import RecommendationSettings from "./tabs/RecommendationSettings";
import SubsalesListingPreview from "../components/subsalesListingPreview";

import EditHOC from "../actions/edit";
import DetailsHOC from "../actions/detail";

import "../index.scss";

const Edit = (props) => {
  const sections = [
    { id: "Details", label: props.getLocalised("label.details.details.title", "Details") },
    { id: "Photos", label: props.getLocalised("label.photos.title", "Photos") },
    { id: "Floor Plans", label: props.getLocalised("label.floor_plan.floor_plans.title", "Floor Plans") },
    { id: "Media Links", label: props.getLocalised("label.media_links.media_links.title", "Media Links") },
    { id: "Juwai Subscription", label: props.getLocalised("label.juwai_subscription.title", "Juwai Subscription") },
    { id: "Recommendation Settings", label: props.getLocalised("label.recommendation_settings.title", "Recommendation Settings") }
  ]

  const renderCardContent = () => {
    if (props.selectedSection === "Details") {
      return <DetailTab dictionary={props.data.dictionaryReducer} {...props} />;
    }
    if (props.selectedSection === "Photos") {
      return <PhotosTab {...props} />;
    }
    if (props.selectedSection === "Settings") {
      return <SettingTab {...props} />;
    }
    if (props.selectedSection === "Floor Plans") {
      return <FloorPlanTab {...props} />;
    }
    if (props.selectedSection === "Juwai Subscription") {
      return (
        <JuwaiSubsTab
          moduleKeys={props.moduleKeys}
          getLocalised={props.getLocalised}
          agent_juwai_account={
            props.subsalesEntryCreationProps.agent_juwai_account
          }
        />
      );
    }
    if (props.selectedSection === "Media Links") {
      return (
        <MediaLink
          {...props}
          subsalesEntryCreationProps={props.subsalesEntryCreationProps}
        />
      );
    }

    if (props.selectedSection === "Recommendation Settings") {
      return <RecommendationSettings {...props} />;
    }
  };

  return (
    <>
      <div className="p-3">
        <div className="at-card__header">
          <AtlasCloseButton 
            onClick={() => props.onChangeSubsalesHOC(false, "showEditModal")} 
            language={props.data.languageReducer.language}
          />
          <h4 className="at-card__title">{props.getLocalised("title.details.edit", "Edit Listing")}</h4>
        </div>
        <Tabs
          sections={sections}
          selectedSection={props.selectedSection}
          onSelectSection={(val) => props.onChangeEditHOC(val, "selectedSection")}
          getLocalised={props.getLocalised}
        />
        <div style={{ minHeight: "100%" }} children={renderCardContent()} />
        {(props.onLoadSubsales || props.onLoadEditHOC) && <LoadingModal />}
      </div>
      <SubsalesListingPreview {...props} />
    </>
  );
};

export default compose(
  EditHOC,
  DetailsHOC,
)(Edit);
