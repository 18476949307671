import React from "react";

import CustomCheckbox from "components/Checkbox";

const TabData = ({
  checkedArray,

  onClickTabApproval,
  onClickTabAssignDirectLeader,
}) => {
  return [
    {
      id: 2,
      label: "Approve",
      disabled: checkedArray.length < 1,
      onClick: onClickTabApproval,
      type: "button",
    },
    {
      id: 3,
      label: "Reassign",
      disabled: checkedArray.length < 1,
      onClick: onClickTabAssignDirectLeader,
      type: "button",
    },
  ];
};
const Tab = ({
  stickToTop,
  checkedArray,
  currentPageData,
  onClickSelectAll,
  onClickTabApproval,
  onClickTabAssignDirectLeader,
}) => {
  let checkableData = [];
  currentPageData.map((item) => {
    if (item.status !== "Approved") {
      checkableData.push(item);
    }
  });

  return (
    <div
      className={
        stickToTop
          ? "at-table--checkbox-actions-sticky"
          : "at-table--checkbox-actions"
      }
    >
      <CustomCheckbox
        contentStyle={{ fontWeight: 500 }}
        labelClassname={"at-table-tab-checkbox_responsive"}
        onChangeCheckboxValue={onClickSelectAll}
        checkedArray={checkedArray}
        content={
          checkedArray.length === checkableData.length &&
          checkableData.length !== 0
            ? "Deselect All"
            : "Select All"
        }
        disabled={checkableData.length === 0}
        checked={
          checkedArray.length === checkableData.length &&
          checkableData.length !== 0
        }
      />
      <div className="d-flex flex-wrap align-items-center mr-2">
        {TabData({
          checkedArray,

          onClickTabApproval,
          onClickTabAssignDirectLeader,
        }).map((item) => (
          <button
            disabled={item.disabled}
            className={`btn-new-sm btn-new--secondary mr-1`}
            style={{ fontSize: 14 }}
            onClick={() => item.onClick()}
          >
            {item.label}
          </button>
        ))}
        {checkedArray.length > 0 && (
          <p className="fs-2 ml-2 at-desktop-view__controller">
            {checkedArray.length} items selected
          </p>
        )}
      </div>
    </div>
  );
};

export default Tab;
