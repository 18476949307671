import React, { useState } from "react";
import Tab from "components/Tab";
import LoadingModal from "components/LoadingModal";

import StatsHOC from "../../actions/presentationStats";
import PresentationStats from "./presentationStats";
import PresentationMortgage from "./presentationMortgage";

import { useEffect } from "react";

const StatisticsContent = ({
  mortgagePages,
  selectedPresentation,
  selectedPresentationStat,
  presentationMortgage,
  onLoadStats,

  onClickClose,
  getPresentationStats,
  getPresentationMortgage,
}) => {
  const [activeTabs, onSwitchTabs] = useState("");

  useEffect(() => {
    onSwitchTabs("Views");
  }, []);

  useEffect(() => {
    activeTabs === "Views" && getPresentationStats(selectedPresentation.id);
    activeTabs === "Mortgage Calculator" &&
      getPresentationMortgage(selectedPresentation.id);
  }, [activeTabs]);

  return (
    <>
      <Tab
        containerClass="pl-0"
        sections={["Views", "Mortgage Calculator"]}
        selectedSection={activeTabs}
        onSelectSection={(val) => onSwitchTabs(val)}
      />
      <div>
        {activeTabs === "Views" && (
          <PresentationStats
            selectedPresentation={selectedPresentation}
            selectedPresentationStat={selectedPresentationStat}
          />
        )}
        {activeTabs === "Mortgage Calculator" && (
          <PresentationMortgage
            mortgagePages={mortgagePages}
            getPresentationMortgage={getPresentationMortgage}
            presentationMortgage={presentationMortgage}
          />
        )}
      </div>
      {onLoadStats && <LoadingModal />}
    </>
  );
};

export default StatsHOC(StatisticsContent);
