import React, { useEffect } from "react";
import Moment from "moment";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import CustomButton from "components/Button";
import CustomTable from "components/NewTable";
import LoadingModal from "components/LoadingModal";
import AtlasPrevButton from "components/Button/prev";
import AtlasCloseButton from "components/Button/close";
import AtPaginatedTable from "components/NewPaginatedTable";

import HistoryHOC from "../../actions/activityHistory";

const LogsColumnData = [
  {
    header: "Updated At",
    renderColumn: (rowData) =>
      Moment(rowData.updated_at).format("DD MMM YYYY hh:mm"),
  },
  { header: "Mobile No", accessor: "mobile_number" },
  { header: "Status", accessor: "status" },
];

const ColumnData = (props) => [
  {
    header: "Time",
    searchFlag: "created_at",
    accessor: "created_at",
    columnStyle: { width: "70%" },
  },
  {
    header: "Recipient",
    searchFlag: "full_name",
    accessor: "full_name",
  },
  {
    header: "Status",
    searchFlag: "status",
    accessor: "status",
    columnStyle: { width: "50%" },
  },
  {
    header: "Activity",
    searchFlag: "activity",
    columnStyle: { width: "180%" },
    renderColumn: (rowData) => (
      <>
        {rowData.activity}
        {rowData.message_logs && rowData.message_logs.length > 0 && (
          <CustomButton
            className={"btn-new btn-new--outline-secondary"}
            children={"Detail"}
            onClick={() => props.onClickDetail(rowData)}
          />
        )}
      </>
    ),
  },
];

const SigningHistory = ({
  formId,
  onLoadHistory,
  signingHistory,
  selectedHistory,
  showMessageLogs,
  getSigningHistory,
  onChangePartyHOC,
  onChangeHistoryHOC,
}) => {
  useEffect(() => {
    if (formId) {
      getSigningHistory(formId);
    }
  }, []);

  const renderCardContent = () => (
    <>
      <div className="d-flex">
        <h2 className="at-form-input__title">Message Logs</h2>
        <AtlasCloseButton
          containerStyle={{ display: "flex", marginLeft: "auto" }}
          onClick={() => onChangeHistoryHOC(false, "showMessageLogs")}
        />
      </div>
      <hr />
      <AtPaginatedTable
        columns={LogsColumnData}
        rowData={selectedHistory.message_logs || []}
        meta={null}
        searchParams={[]}
        totalPages={[]}
      />
    </>
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasPrevButton
          onClick={() => onChangePartyHOC(false, "showSigningHistory")}
        />
        <h5 className="at-card__title">{"E-Process Activity"} </h5>
      </div>
      <hr />
      <CustomTable
        columns={ColumnData({
          onClickDetail: (val) =>
            Promise.all([onChangeHistoryHOC(val, "selectedHistory")]).then(
              () => {
                onChangeHistoryHOC(true, "showMessageLogs");
              },
            ),
        })}
        pagination={true}
        hideSearch={true}
        rowData={signingHistory || []}
      />
      <Dialog maxWidth={"xl"} classes={{ paper: "m-0" }} open={showMessageLogs}>
        <AtlasCard
          containerStyle={{ minWidth: 300, width: "80vw" }}
          cardContent={renderCardContent()}
        />
      </Dialog>
      {onLoadHistory && <LoadingModal />}
    </>
  );
};

export default HistoryHOC(SigningHistory);
