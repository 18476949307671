import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";

const ProjectDocs = ({ documents, storeIQIDriveData, onDownloadFile }) => {
  const onClickDocument = (item) => {
    if (item.document_url.indexOf("path") === -1) {
      window.open(item.document_url, "_blank");
    } else {
      let tmp = JSON.parse(item.document_url);
      if (item.type === "Base path") {
        storeIQIDriveData({ ...tmp, type: "directory" });
        window.open(
          `${window.location.origin}/#/dashboard/iqi-drive/custom`,
          "_blank",
        );
      } else {
        onDownloadFile(tmp);
      }
    }
  };

  return (
    <div className="at-project-listing__project-document">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-folder" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Documents</h1>
      </div>
      {documents.map((item) => {
        return (
          <div
            key={item.id}
            className="at-project__details-link"
            onClick={() => onClickDocument(item)}
          >
            <AtlasIcon
              svgHref={
                item.type === "Base path"
                  ? "atlas-link-2-linear"
                  : "atlas-receive-square"
              }
              style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
            />
            {item.type === "Base path" ? "Project Folder" : item.type}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectDocs;
