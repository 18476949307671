import React, { useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import EmailValidator from "email-validator";

import CustomRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";

import isEmptyValue from "utils/isEmpty";
import { user as UserDictionary } from "dictionary/index";

const statusList = ["Active", "Inactive", "Resigned"];

const paFields = [
  {
    context: "email",
    errorContext: "emailError",
    onDisplayMessage: (val) =>
      !EmailValidator.validate(val) ? "Invalid email." : "",
  },
  {
    context: "full_name",
    errorContext: "fullNameError",
    onDisplayMessage: (val) => (val === "" ? "Name cannot be empty." : ""),
  },
  {
    context: "display_name",
    errorContext: "displayNameError",
    onDisplayMessage: (val) => (val === "" ? "Name cannot be empty." : ""),
  },
  {
    context: "mobile_contact_number",
    errorContext: "contactError",
    onDisplayMessage: (val) =>
      val === "" ? "Contact number cannot be empty." : "",
  },
];

const Profile = ({
  selectedPersonalAssistant,
  editPersonalAssistant,
  fullNameError,
  displayNameError,
  emailError,
  contactError,

  onClickUpdatePersonalAssistant,
  onChangeEditHOC,
  onClose,
}) => {
  useEffect(() => {
    let {
      id,
      full_name,
      mobile_contact_number,
      status_id,
      display_name,
      email,
    } = selectedPersonalAssistant;

    let temp = {
      id: id,
      full_name: full_name,
      mobile_contact_number: mobile_contact_number,
      status_id: status_id,
      display_name: display_name,
      email: email,
    };

    onChangeEditHOC(temp, "editPersonalAssistant");
    onChangeEditHOC("", "emailError");
    onChangeEditHOC("", "fullNameError");
    onChangeEditHOC("", "displayNameError");
    onChangeEditHOC(false, "firstImageChanged");
    onChangeEditHOC(false, "secondImageChanged");
    onChangeEditHOC(false, "firstImageChanged");
  }, [selectedPersonalAssistant]);

  const statusOptions = useMemo(() => {
    return _.filter(UserDictionary.statuses, (item) => {
      return statusList.indexOf(item.label) > -1;
    });
  }, []);

  const onDisabled = useCallback(() => {
    const ObjPA = Object.entries(editPersonalAssistant);

    const disabled = ObjPA.some(([key, value]) => {
      if (key === "email") {
        return !EmailValidator.validate(value);
      }
      return isEmptyValue(value);
    });
    return disabled;
  }, [editPersonalAssistant]);

  const onChangeField = (val, context) => {
    const tmpField = paFields.find((item) => item.context === context);
    let temp = { ...editPersonalAssistant };
    temp[context] = val;
    if (tmpField) {
      onChangeEditHOC(tmpField.onDisplayMessage(val), tmpField.errorContext);
    }
    onChangeEditHOC(temp, "editPersonalAssistant");
  };

  const renderProfileForm = () => {
    let { full_name, mobile_contact_number, status_id, display_name, email } =
      editPersonalAssistant;
    return (
      <>
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Full Name
            </h2>
            <CustomFormInput
              type="text"
              className={"mt-2"}
              value={full_name}
              onChangeValue={(val) => onChangeField(val, "full_name")}
              required={true}
              inputError={fullNameError}
              placeholder={"Enter your PA's full name"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Display Name
            </h2>
            <CustomFormInput
              type="text"
              className={"mt-2"}
              value={display_name}
              onChangeValue={(val) => onChangeField(val, "display_name")}
              required={true}
              inputError={displayNameError}
              placeholder={"Enter your display name"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Email
            </h2>
            <CustomFormInput
              type="text"
              className={"mt-2"}
              value={email}
              onChangeValue={(val) => onChangeField(val, "email")}
              required={true}
              inputError={emailError}
              placeholder={"Enter your email here"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Mobile Contact
            </h2>
            <CustomFormInput
              type="text"
              className={"mt-2"}
              value={mobile_contact_number}
              onChangeValue={(val) =>
                onChangeField(val, "mobile_contact_number")
              }
              required={true}
              inputError={contactError}
              placeholder={"Enter your mobile contact number here"}
            />
          </section>

          <section className="grid-half-col">
            <h2 className="at-form-input__title mb-2">Status</h2>
            <CustomRadioGroup
              checkedValue={status_id}
              containerClassName={"mb-20"}
              options={statusOptions}
              selectedRadioValue={(val) => onChangeField(val, "status_id")}
            />
          </section>
        </form>
        <div className="at-modal_dialog-container-footer g-3">
          <button
            type={"button"}
            className="btn-new btn-new--success"
            disabled={onDisabled()}
            onClick={(e) => onClickUpdatePersonalAssistant(e)}
          >
            Update
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </>
    );
  };

  return renderProfileForm();
};

export default Profile;
