import React from "react";
import { MdClose } from "react-icons/md";

import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";

import SVGIcon from "components/Icon";

const CustomImage = ({ tooltipID, onClickShowImage, imgSrc, imgStyle }) => {
  return (
    <div
      className="project-detail__edit-image-item mb-10"
      style={{ backgroundColor: "#1F2937", minWidth: 250 }}
    >
      <div className="at-subsales-listing__photo">
        <img
          onClick={() => onClickShowImage(imgSrc)}
          alt={tooltipID}
          src={imgSrc}
          style={imgStyle}
          className={"project-detail-image"}
        />
      </div>
    </div>
  );
};

export default CustomImage;
