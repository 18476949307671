import React, { Component } from "react";
import { Dialog } from "@material-ui/core";

import "stylesheets/components/topbar/index.scss";

import WhatsappImage from "assets/images/whatsapp.png";
import TelegramImage from "assets/images/telegram_support.png";
import CallImage from "assets/images/call_support.png";
import EmailImage from "assets/images/email_support.png";

class TopMenuModal extends Component {
  render = () => {
    const {
      open,

      onClose,
      setShowCallConfirmation,
    } = this.props;

    return (
      <Dialog
        style={{ background: "transparent" }}
        classes={{
          container:
            "at-topbar__dropbear--modal-root at-topbar__dropbear--support-modal-root",
          paper: "at-topbar__dropbear-paper",
        }}
        BackdropProps={{
          classes: { root: `at-topbar__dropbear-backdrop` },
        }}
        open={open}
        onClose={onClose}
      >
        <div className={"at-topbar__dropbear--list"}>
          <button
            onMouseDown={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=60127815559&text=Hey%20Atlas%20Support%21%20I%27m%20facing%20some%20issues%20here%20and%20need%20help%20from%20you%20%3A%29%20`,
              );
            }}
            onTouchStart={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=60127815559&text=Hey%20Atlas%20Support%21%20I%27m%20facing%20some%20issues%20here%20and%20need%20help%20from%20you%20%3A%29%20`,
              );
            }}
          >
            <img
              src={WhatsappImage}
              style={{ width: "28px", height: "28px" }}
            />
            <p style={{ marginLeft: 10, textAlign: "left" }}>
              Contact Our Support
            </p>
          </button>
          <button
            onClick={() => window.open(`https://t.me/IqiGlobalBot`)}
            onTouchStart={() => window.open(`https://t.me/IqiGlobalBot`)}
          >
            <img
              src={TelegramImage}
              style={{ width: "25px", height: "25px" }}
            />
            <p style={{ marginLeft: 10, textAlign: "left" }}>Talk to IQI Bot</p>
          </button>
          <button onClick={() => setShowCallConfirmation()}>
            <img src={CallImage} style={{ width: "25px", height: "25px" }} />
            <p style={{ marginLeft: 10, textAlign: "left" }}>Helpdesk</p>
          </button>
          <button
            onClick={() => (window.location = "mailto:helpdesk@iqiglobal.com")}
            onTouchStart={() =>
              (window.location = "mailto:helpdesk@iqiglobal.com")
            }
          >
            <img src={EmailImage} style={{ width: "25px", height: "25px" }} />
            <p style={{ marginLeft: 10, textAlign: "left" }}>
              Email IQI Support
            </p>
          </button>
        </div>
      </Dialog>
    );
  };
}

export default TopMenuModal;
