import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const searchParams = (branch) => [
  {
    label: "Order Number",
    value: "market_order_order_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Delivery Branch",
    value: "delivery_branch_id_eq",
    type: "select",
    param: "",
    options: branch,
  },
  {
    label: "Product Name",
    value: "market_product_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Product Type",
    value: "market_product_type_id_eq",
    type: "select",
    param: "",
    options: [
      { id: 1, label: "Starter Kit", value: "1" },
      { id: 2, label: "Name Card", value: "2" },
    ],
  },
  {
    label: "Agent Email",
    value: "market_order_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Branch",
    value: "market_order_user_branch_id_eq",
    type: "select",
    param: "",
    options: branch,
  },
  {
    label: "Agent Mobile Number",
    value: "market_order_user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { label: "All", value: "" },
      { label: "Pending", value: "0" },
      { label: "Processing", value: "1" },
      { label: "Arrived", value: "2" },
      { label: "Collected", value: "3" },
      { label: "Rejected", value: "4" },
      { label: "Voided", value: "5" },
      { label: "Cancelled", value: "6" },
      { label: "To Pay", value: "7" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      searchParams: searchParams(this.props.data.branchReducer.branch),

      selectedOrder: {},
      selectedNameCard: {},

      showOrderDetails: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeOrderHOC = (val, context) => this.setState({ [context]: val });

    getSelectedOrder = (id) =>
      Get(
        `/admin/market_order_items/${id}`,
        this.getSelectedOrderSuccess,
        this.getSelectedOrderError,
        this.load,
      );
    getSelectedOrderSuccess = (payload) => {
      this.setState({
        selectedOrder: {
          ...payload,
          ...payload.user,
          via_recruitment_campaign_text: payload.user.via_recruitment_campaign
            ? "Yes"
            : "No",
        },
        showOrderDetails: true,
      });
    };
    getSelectedOrderError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          selectedNameCard={this.state.selectedNameCard}
          onLoadOrder={this.state.loading}
          searchParams={this.state.searchParams}
          selectedOrder={this.state.selectedOrder}
          showOrderDetails={this.state.showOrderDetails}
          getSelectedOrder={this.getSelectedOrder}
          onChangeOrderHOC={this.onChangeOrderHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
