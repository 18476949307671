import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { IoIosClose, IoMdAdd } from "react-icons/io";
import ClipboardJS from "clipboard";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import AtSubsaleClaimTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";
import UpdateClaimForm from "./components/updateClaimForm";
import CreateClaimForm from "./containers/CreateClaim/index";
import ReassignSubmissionContent from "./containers/ReassignSubmission";

import SubsaleClaimsHOC from "./actions";
import BankHOC from "actions/banks";
import AgenciesHOC from "./actions/agency";
import VersionCheckHOC from "actions/versionCheck";
import ListingHOC from "./actions/listing";
import { ColumnData } from "./assets";
import permissionsChecker from "utils/permissionsChecker";

import { initGA } from "utils/ga";

import "./index.scss";

class SubsalesClaimsBeta extends Component {
  state = {
    copyStatus: false,
  };

  componentDidMount = () => {
    const { role, id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getBranches();
    this.props.getAgencies();
    this.props.getPendingReassign();
    this.props.getAllDictionaryData();
    this.props.getSubmittedSubsaleClaims(
      1,
      role === "Admin"
        ? `q[sub_sale_claim_submissions_assignee_id_eq]=${id}&`
        : "",
    );

    let copyClipboard = new ClipboardJS(".copy_unitID-clipboard");

    copyClipboard.on("success", function (e) {
      e &&
        e.text &&
        navigator &&
        navigator.clipboard &&
        navigator.clipboard.writeText(e.text);
    });
    copyClipboard.on("error", function (e) {
      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);
    });

    const { claim_id, id: claimId } = this.props.location?.state || {};
    const tmpId = claimId || claim_id;
    if (tmpId) {
      this.props.getSelectedClaim(tmpId);
    }
    initGA("/dashboard/admin/subsales-claims");
  };

  componentDidUpdate = (pp) => {
    if (
      pp.showUpdateClaimFormModal !== this.props.showUpdateClaimFormModal &&
      !this.props.showUpdateClaimFormModal
    ) {
      this.props.onChangeClaimsHOC("selectedSection", "Claim Form");
    }

    if (
      pp.showReassignModal !== this.props.showReassignModal &&
      !this.props.showReassignModal
    ) {
      this.props.getPendingReassign();
    }
  };

  renderCreateNewClaimDialog = () => (
    <AtlasDialog open={this.props.showCreateClaim}>
      <CreateClaimForm
        {...this.props}
        onClose={() => this.props.onChangeClaimsHOC("showCreateClaim", false)}
      />
    </AtlasDialog>
  );

  renderUpdateClaimFormDialog = () => {
    return (
      <AtlasDialog
        open={this.props.showUpdateClaimFormModal}
        withFloatPanel={true}>
        <UpdateClaimForm
          {...this.props}
          onSelectSection={(val) =>
            this.props.onChangeClaimsHOC("selectedSection", val)
          }
        />
      </AtlasDialog>
    );
  };

  renderCardContent = () => {
    const {
      data,
      onLoadSubsaleClaims,
      onLoadReassign,
      onLoadSimilarProperties,
      getSelectedClaim,
      changeClaimsState,
      searchParams,
      onChangeClaimsHOC,
    } = this.props;
    const { can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    return (
      <>
        <AtSubsaleClaimTable
          columns={ColumnData({
            data: data,
            changeClaimsState: changeClaimsState,
            onClickCopy: () => this.setState({ copyStatus: true }),
          })}
          rowData={this.props.claims.data ? this.props.claims.data : []}
          meta={this.props.claims.meta ? this.props.claims.meta : {}}
          actionColumnContent={[
            {
              name: can_update ? "edit" : "details",
              onClick: (rowData) => getSelectedClaim(rowData.id),
            },
          ]}
          searchParams={searchParams}
          onChangeSearchParams={(val) => onChangeClaimsHOC("searchParams", val)}
          getListAPI={this.props.getSubmittedSubsaleClaims}
          totalPages={this.props.totalPages}
        />
        {(onLoadSubsaleClaims || onLoadSimilarProperties || onLoadReassign) && (
          <LoadingModal />
        )}
      </>
    );
  };

  renderReassignDialog = () => {
    const { showReassignModal } = this.props;

    return (
      <AtlasDialog open={showReassignModal}>
        <CustomCard
          className={"h-100"}
          containerStyle={{ background: "#F3F4F6" }}
          cardContent={
            <ReassignSubmissionContent
              {...this.props}
              getSubmittedSubsaleClaims={this.props.getSubmittedSubsaleClaims}
              getPendingReassign={this.props.getPendingReassign}
              onClose={() =>
                this.props.onChangeClaimsHOC("showReassignModal", false)
              }
            />
          }
        />
      </AtlasDialog>
    );
  };

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={this.state.copyStatus}
      autoHideDuration={6000}
      onClose={() => this.setState({ copyStatus: !this.state.copyStatus })}
    >
      <SnackbarContent
        message={
          <span>Data copied into clipboard. You can now paste the data.</span>
        }
        action={
          <button
            onClick={() =>
              this.setState({ copyStatus: !this.state.copyStatus })
            }
          >
            <IoIosClose style={{ color: "#eee", width: 25, height: 25 }} />
          </button>
        }
      />
    </Snackbar>
  );

  render = () => {
    const { pendingRessignCount, onChangeClaimsHOC } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={"Subsales Claims"}
          moduleIcon={"icon-claims"}
          actionButton={[
            {
              label: "New Claim Form",
              onShow: can_create,
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () => onChangeClaimsHOC("showCreateClaim", true),
            },
            {
              label: "Pending Reassign Submission",
              onShow: can_update,
              className: "btn-new btn-new--secondary",
              icon: <AiOutlineUserSwitch style={{ height: 18, width: 18 }} />,
              onClick: () => onChangeClaimsHOC("showReassignModal", true),
              additionalContent: pendingRessignCount > 0 && (
                <span
                  className="position-absolute badge badge-danger rounded-circle"
                  style={{ top: -5, right: -8 }}
                >
                  {pendingRessignCount}
                </span>
              ),
            },
          ]}
        />
        <CustomCard
          containerStyle={{ background: "#F3F4F6" }}
          className="at-admin-subsales_claim-cont"
          cardContent={this.renderCardContent()}
        />
        {this.renderUpdateClaimFormDialog()}
        {this.renderCreateNewClaimDialog()}
        {this.renderReassignDialog()}
        {this.renderSnackBar()}
      </>
    );
  };
}

export default compose(
  BankHOC,
  SubsaleClaimsHOC,
  AgenciesHOC,
  ListingHOC,
  VersionCheckHOC,
)(SubsalesClaimsBeta);
