import React, { useEffect } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import AtPaginatedTable from "components/NewPaginatedTable";

const columnData = [
  {
    header: "Uploaded At",
    accessor: "created_at",
  },
  {
    header: "Document Type",
    accessor: "snapshot_document_type",
  },
];

const ReaDocumnet = ({
  documentList,
  documentPages,
  selectedRenewal,

  getDocumentList,
}) => {
  useEffect(() => {
    getDocumentList(selectedRenewal.id);
  }, []);

  return (
    <AtPaginatedTable
      rowData={documentList.data || []}
      meta={documentList.meta || []}
      columns={columnData}
      searchParams={[]}
      renderCheckBoxActions={() => (
        <div className="d-flex align-items-center my-3 ml-3 grid_gap-2">
          <div
            className="at-renewal-details__icon-badge"
            style={{ backgroundColor: "#FFEDD5" }}
          >
            <AtlasIcon
              svgHref={"atlas-document-text"}
              style={{ fill: "#EA580C" }}
            />
          </div>
          <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
            Document
          </p>
        </div>
      )}
      actionColumnContent={[
        {
          name: "view",
          onClick: (rowData) => window.open(rowData.snapshot, "_blank"),
        },
      ]}
      totalPages={documentPages || []}
      getListAPI={() => {}}
    />
  );
};

export default ReaDocumnet;
