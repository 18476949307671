import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import GenerateAppointmentLetterForm from "../../components/generateForm";
import EditAppointmentContent from "./components/editContent";
import LoadingModal from "components/LoadingModal";

import AppointmentLetterHOC from "./actions";
import FormHOC from "../../actions";
import SubsalesStatusHOC from "actions/subsalesStatus";
import InvoicingBranchesHOC from "../../actions/invoicingBranches";
import VersionCheckHOC from "actions/versionCheck";
import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Form Number",
    accessor: "transaction_number",
    columnStyle: { width: "60%" },
  },
  {
    header: "Appointment Date",
    accessor: "appointment_date",
    columnStyle: { width: "60%" },
  },
  { header: "Property Address", accessor: "property_address" },
  {
    header: "Remark",
    renderColumn: (rowData) => <p>{rowData.description || ""}</p>,
    columnStyle: { width: "90%" },
  },
  {
    header: "Submitted",
    renderColumn: ({ is_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_claimed ? "Green" : "Red") }}
      >
        {is_claimed ? "Yes" : "No"}
      </div>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "70%" },
  },
];

class AppointmentLetter extends Component {
  componentDidMount = () => {
    const id = this.props.history?.location?.state?.id;
    this.props.getCommRate();
    this.props.getAllDictionaryData();
    this.props.getInvoicingBranches();
    this.props.getAppointmentLetters(1, "");
    if (id) {
      this.props.getAppointmentLetter(id);
    }
    initGA("/dashboard/personal/forms/appointment-letter");
  };

  componentDidUpdate = (pp) => {
    const { branch_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (
      this.props.showCreationForm !== pp.showCreationForm &&
      !this.props.showCreationForm
    ) {
      const temp = _.find(this.props.invoicing_branches, {
        branch_id: branch_id,
      });
      if (temp) {
        this.props.onChangeAppointmentHOC(temp.id, "createInvoiceId");
      } else {
        this.props.onChangeAppointmentHOC(null, "createInvoiceId");
      }
      this.props.onChangeAppointmentHOC(null, "createTypeId");
    }
  };

  generateAppointmentLetter = () => {
    const dataToSubmit = {
      invoicing_branch_id: this.props.createInvoiceId,
      type_id: this.props.createTypeId,
    };
    this.props.onChangeAppointmentHOC(true, "disableSubmitButton");
    return this.props.createAppointmentLetter(dataToSubmit);
  };

  renderCreationForm = () => {
    return (
      <ModalDialog
        title={"New Appointment Letter"}
        children={
          <GenerateAppointmentLetterForm
            loading={this.props.onLoadApptLetter}
            disableSubmitButton={
              !this.props.createInvoiceId ||
              !this.props.createTypeId ||
              this.props.disableSubmitButton
            }
            type={this.props.createTypeId}
            onSelectBranch={(val) =>
              this.props.onChangeAppointmentHOC(val.id, "createInvoiceId")
            }
            selectSubsaleType={(val) =>
              this.props.onChangeAppointmentHOC(val, "createTypeId")
            }
            invoicingBranch={this.props.invoicing_branches}
            invoicing_branch_id={this.props.createInvoiceId}
            onClickCloseFormGenerator={() =>
              this.props.onChangeAppointmentHOC(false, "showCreationForm")
            }
            generateForm={this.generateAppointmentLetter}
            submitButtonText={"Create Now"}
          />
        }
        onClose={() =>
          this.props.onChangeAppointmentHOC(false, "showCreationForm")
        }
      />
    );
  };

  renderAppointmentLetter = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { e_process, isLocked } = this.props.selected_appointment_letter;
    const { status_id } = e_process ?? {}
    const eProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))

    return (
      <AtlasDialog withFloatPanel={true} open={this.props.showEditApptLetter}>
        <CustomCard
          className="h-100"
          containerStyle={{ background: "#F3F4F6" }}
          cardContent={<EditAppointmentContent 
            {...this.props}
            closedEditing={ !can_update || isLocked || eProcessDisabledChecking }
          />}
        />
      </AtlasDialog>
    );
  }

  renderAlertContent = () => {
    const { data, commRate, completed_trainings } = this.props;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (
      (status_id !== 2 && !completed_trainings) ||
      (status_id !== 2 && !commRate) ||
      status_id === 2
    ) {
      return (
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={"Training not completed."}
              containerClassName={((status_id !== 2 && !commRate) || status_id === 2) ? 'mb-3' : ''}
              description={
                "You have not completed your training. You are required to finish training before accessing Appointment Letter, Confirmation Form, Cobroke Letter & Authorisation Forms To Secure Property. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              title={"No commision rate set."}
              containerClassName={ status_id === 2 ? 'mb-3' : '' }
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const {
      data,
      appointment_letters,
      searchParams,
      whitelisted,
      completed_trainings,
      getAppointmentLetter,
      onChangeAppointmentHOC,
      getAppointmentLetters,
      appointmentLetterPage,
    } = this.props;

    const { can_create } = permissionsChecker("Forms", data);
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    const status_permissions =
      (status_id !== 2 && completed_trainings) || whitelisted;

    return (
      <>
        <ModuleHeader
          title={"Appointment Letters"}
          atlasIcon={"atlas-appointment-letter"}
          showPABadge={role === "Personal Assistant"}
          backButton={{
            onClick: () => this.props.history.goBack(),
          }}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeAppointmentHOC(true, "showCreationForm"),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={appointment_letters.data || []}
              meta={appointment_letters.meta}
              columns={columnData}
              actionColumnContent={
                status_permissions
                  ? [
                      {
                        name: "edit",
                        onClick: (rowData) =>
                          getAppointmentLetter(rowData.id),
                      },
                    ]
                  : []
              }
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeAppointmentHOC(val, "searchParams")
              }
              getListAPI={getAppointmentLetters}
              totalPages={appointmentLetterPage}
            />
          }
        />
        {this.props.showCreationForm && this.renderCreationForm()}
        {this.renderAppointmentLetter()}
        {(this.props.onLoadApptLetter || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  SubsalesStatusHOC,
  AppointmentLetterHOC,
  FormHOC,
  InvoicingBranchesHOC,
  VersionCheckHOC,
)(AppointmentLetter);
