import React, { useEffect, useState } from "react";
import _ from "lodash";

import { FabricProvider } from "context/fabric";

import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import FabricContent from "./editFabric";
import HelpInfoContent from "./helpInfo";

const EditForm = ({
  showLoading,
  selectedTemplate,

  onClickSubmit,
  onClickClose,
  selectedGraphic,
  updateDownloadCount,
  data,
}) => {
  const [title, setTitle] = useState("");
  const { role } =
    window.location.href.indexOf("/admin-impersonate") > -1
      ? data.currentSignInProfileReducer
      : data.profileReducer;
  let isAdmin = ["Super Admin", "Admin"].indexOf(role) > -1;

  useEffect(() => {
    setTitle(selectedGraphic.title);
  }, []);

  return (
    <>
      <HelpInfoContent />
      <div className="at-form__content">
        <h2 className="at-form-input__title text-capitalize" required>
          Title
        </h2>
        <CustomFormInput
          type={"text"}
          value={title}
          disabled={isAdmin}
          required={true}
          onChangeValue={(val) => setTitle(val)}
          placeholder={`Please enter graphic's title`}
        />
      </div>
      <FabricProvider>
        <FabricContent
          title={title}
          selectedGraphic={selectedGraphic}
          initialJSONData={selectedGraphic.content}
          tempTemplate={selectedTemplate}
          onClickSubmit={onClickSubmit}
          onClickClose={onClickClose}
          updateDownloadCount={updateDownloadCount}
          data={data}
        />
      </FabricProvider>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default EditForm;
