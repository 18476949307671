import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Popover } from "@material-ui/core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IoMdClose } from "react-icons/io";
import Moment from "moment";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import AtlasIcon from "components/Icon/atlasIcon";

const AtDatePicker = ({
  id = "",
  label,
  type = "date",
  className = "",
  disabled,
  value,
  defaultValue,
  withPortal,
  textFieldClass,
  minDate = "",
  maxDate = "",
  minTime = "",
  maxTime = "",
  minDateTime = "",
  maxDateTime = "",
  minutesStep = 1,
  shouldDisableTime,
  customClearBtnStyle,
  onChange,
  onAccept,
  onClose
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div className="at-custom_datepicker-cont d-flex align-items-center w-100 position-relative">
      {type === "date" && withPortal && (
        <LocalizationProvider 
          dateAdapter={AdapterMoment}
          adapterLocale="en"
          moment={Moment}>
          <MobileDatePicker
            className={className}
            open={open}
            defaultValue={defaultValue}
            onAccept={onAccept}
            onClose={onClose}
            value={value}
            onChange={onChange}
          />
        </LocalizationProvider>
      )}
      {type === "date" && !withPortal && (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="en"
          moment={Moment}>
          <TextField
            id={`at-textfield-datetimepicker-${id}`}
            className={className}
            disabled={disabled}
            type="date"
            value={value ? Moment(value).format("yyyy-MM-DD") : ""}
            onError={(e) => console.log(e)}
            classes={{ root: "at-form-input at-custom-datetimepicker-root" }}
            InputProps={{ readOnly: true }}
            inputProps={{
              style: {
                cursor: "pointer",
              },
              onClick: () => {
                if (!disabled) {
                  let elementFinder =
                    document.getElementsByClassName(
                      `at-custom-datepicker-input_adornment input_adornment-${id}`,
                    )[0]?.childNodes[0] ||
                    document.getElementsByClassName(
                      `at-custom-datepicker-${id}`,
                    )[0]?.childNodes[0];
                  elementFinder && elementFinder?.click();
                }
              },
            }}
          />
          <DatePicker
            id={`at-datetimepicker-${id}`}
            label={label || ""}
            onAccept={(value) => onChange(value)}
            className={`at-form-input at-custom-datepicker-${id} position-absolute`}
            slotProps={{
              textField: {
                className: "at-custom-datepicker-text_field",
                style: {
                  cursor: "pointer",
                  maxHeight: 0,
                  overflow: "hidden",
                },
              },
              inputAdornment: {
                className: `at-custom-datepicker-input_adornment input_adornment-${id}`,
              },
            }}
            format="DD/MM/YYYY"
            minDate={minDate ? Moment(minDate) : ""}
            maxDate={maxDate ? Moment(maxDate) : ""}
            minutesStep={minutesStep}
            minTime={minTime}
            maxTime={maxTime}
            value={value ? Moment(value) : ""}
            onChange={(value) => onChange(value)}
          />
        </LocalizationProvider>
      )}
      {type === "datetime-local" && withPortal && (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
          <MobileDateTimePicker
            id={`at-datetimepicker-${id}`}
            disabled={disabled}
            className={`at-form-input ${className}`}
            slotProps={{
              mobilePaper: {
                className: "at-custom-datetimepicker-paper_dialog",
              },
              textField: {
                className: `at-custom-datetimepicker-root at-datetimepicker-${id}`,
              },
            }}
            minutesStep={minutesStep}
            minTime={minTime}
            maxTime={maxTime}
            onChange={(val) => onChange(val)}
            value={value ? Moment(value) : ""}
          />
        </LocalizationProvider>
      )}
      {type === "datetime-local" && !withPortal && (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="en"
          moment={Moment}>
          <TextField
            id={`custom-datepicker-${id}`}
            className={className}
            disabled={true}
            type="datetime-local"
            value={value ? Moment(value).format("yyyy-MM-DDTHH:mm") : ""}
            onClick={handleClick}
            onError={(e) => console.log(e)}
            classes={{
              root:
                textFieldClass || "at-form-input at-custom-datetimepicker-root",
            }}
            InputProps={{ readOnly: true }}
            inputProps={{
              style: { cursor: "pointer" },
            }}
          />
          <Popover
            id={`custom-datepicker-${id}`}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}>
            <StaticDateTimePicker
              onAccept={handleClick}
              onClose={handleClose}
              id={`at-datetimepicker-${id}`}
              minDateTime={minDateTime ? Moment(minDateTime) : ""}
              maxDateTime={maxDateTime ? Moment(maxDateTime) : ""}
              minutesStep={minutesStep}
              shouldDisableTime={shouldDisableTime}
              onChange={(val) => onChange(val)}
              value={Moment(value)}
            />
          </Popover>
        </LocalizationProvider>
      )}
      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
          <MobileTimePicker
            id={`at-datetimepicker-${id}`}
            disabled={disabled}
            className={`at-form-input ${className}`}
            slotProps={{
              mobilePaper: {
                className: "at-custom-datetimepicker-paper_dialog",
              },
              textField: {
                className: `at-custom-datetimepicker-root at-datetimepicker-${id}`,
              },
            }}
            minutesStep={minutesStep}
            minTime={minTime}
            maxTime={maxTime}
            onClose={handleClose}
            onAccept={handleClick}
            onChange={(val) => onChange(val)}
            value={value ? Moment(value) : ""}
          />
        </LocalizationProvider>
      )}
      <AtlasIcon
        className="position-absolute"
        svgHref={"atlas-calendar-2-linear"}
        style={{
          width: 16,
          height: 16,
          marginRight: 5,
          right: disabled ? 0 : 40,
          cursor: "pointer",
        }}
        onClick={(e) => {
          if (!disabled) {
            if (type === "datetime-local" && !withPortal) {
              handleClick(e);
            } else if (type === "datetime-local" && withPortal) {
              let elementFinder = document.getElementsByClassName(
                `at-datetimepicker-${id}`,
              )[0]?.childNodes[0];
              elementFinder && elementFinder.click();
            } else {
              let elementFinder =
                document.getElementsByClassName(
                  `at-custom-datepicker-input_adornment input_adornment-${id}`,
                )[0]?.childNodes[0] ??
                document.getElementsByClassName(`at-custom-datepicker-${id}`)[0]
                  ?.childNodes[0];
              elementFinder && elementFinder.click();
            }
          }
        }}
      />
      {!disabled && (
        <div
          className="at-datetimepicker-clear_btn-cont"
          style={{ ...customClearBtnStyle }}
          onClick={() => onChange("")}>
          <IoMdClose className="at-datetimepicker-clear_btn" />
        </div>
      )}
    </div>
  );
};

export default AtDatePicker;
