import Axios from "axios";

import { GET_ALL_AGENCIES } from "./type";
import {
  beginAjaxCallBackground,
  ajaxCallErrorBackground,
  ajaxCallSuccessBackground,
} from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export function getAgencies() {
  const tokenName = window.location.href.includes("/admin-impersonate")
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const token = getItem(tokenName);
  Axios.defaults.headers = {
    common: {
      Authorization: `JWT ${token}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  return (dispatch) => {
    dispatch(beginAjaxCallBackground(true));
    Axios.get(`${getDomainURL()}/agencies`)
      .then((response) => {
        dispatch(getAgenciesSuccess(response.data));
        dispatch(ajaxCallSuccessBackground());
      })
      .catch((error) => {
        dispatch(ajaxCallErrorBackground(error, () => dispatch(getAgencies())));
      });
  };
}

function getAgenciesSuccess(payload) {
  return {
    type: GET_ALL_AGENCIES,
    payload,
  };
}
