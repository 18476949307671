export const Rules = [
  {
    duration: "Up to 3 years",
    fee: "1.25 months gross rental",
  },
  {
    duration: "Exceeding 3 years up to 4 years",
    fee: "1.50 months gross rental",
  },
  {
    duration: "Exceeding 4 years up to 5 years",
    fee: "1.75 months gross rental",
  },
  {
    duration: "Exceeding 5 years (without option for renewal)",
    fee: "1.75 months gross rental plus",
  },
  {
    duration: "Exceeding 5 years (with option for renewal)",
    fee: "1.75 months gross rental plus",
    feeExtra: "0.25 months gross rental additional year",
  },
  {
    duration: "For each Rent Review/Each Option to renew",
    fee: "50% of the fees chargeable under the above scale of fees",
  },
];

export const DurationTypeRadioOptions = [
  { id: 1, value: 1, label: "Schedule 7(C)(2) under Rule 48(a)" },
  { id: 2, value: 2, label: "Custom" },
];

export const AppointmentBasisOptions = [
  {
    id: 1,
    label: "Exclusive basis",
    value: 1,
  },
  {
    id: 2,
    label: "Ad-hoc basis",
    value: 2,
  },
];

export const Stakeholders = [
  {
    id: 1,
    label: "IQI REALTY SDN BHD",
    value: 1,
  },
  {
    id: 2,
    label: "Lawyer",
    value: 2,
  },
];
