import Axios from "axios";

import { GET_PA_PERMISSIONS } from "./type";
import { GET_AA_PERMISSIONS } from "./type";
import { GET_ADMIN_PERMISSIONS } from "./type";
import { GET_PM_PERMISSIONS } from "./type";

import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

const dispatchPermission = (role, data) => {
  switch (role) {
    case "Personal Assistant":
      return getPAPermissionsSuccess(data);
    case "Associated Agent":
      return getAAPermissionsSuccess(data);
    case "Admin":
      return getAdminPermissionsSuccess(data);
    case "Project Manager":
      return getPMPermissionsSuccess(data);
  }
};

export function getPermissions(role) {
  const tokenName = window.location.href.includes("/admin-impersonate")
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const token = getItem(tokenName);
  Axios.defaults.headers = {
    common: {
      Authorization: `JWT ${token}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  return (dispatch) => {
    dispatch(beginAjaxCall(true));
    Axios.get(`${getDomainURL()}/profiles/module_permissions`)
      .then((response) => {
        dispatch(dispatchPermission(role, response.data));
        dispatch(ajaxCallSuccess());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
      });
  };
}

function getAdminPermissionsSuccess(payload) {
  return {
    type: GET_ADMIN_PERMISSIONS,
    payload,
  };
}

function getPAPermissionsSuccess(payload) {
  return {
    type: GET_PA_PERMISSIONS,
    payload,
  };
}

function getAAPermissionsSuccess(payload) {
  return {
    type: GET_AA_PERMISSIONS,
    payload,
  };
}

function getPMPermissionsSuccess(payload) {
  return {
    type: GET_PM_PERMISSIONS,
    payload,
  };
}
