import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ConfirmationModal from "components/Modal/delete";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put, Post, Delete } from "utils/axios";

const searchParamsPermission = [
  {
    label: "Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
  {
    label: "User Email",
    value: "permission_group_users_user_email_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showConfirmationModal: false,

      permissionGroups: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },
      selectedPermissionGroup: {},
      showUpdatePermissionGroup: false,
      permittedItems: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },

      searchParamsPermission: searchParamsPermission,
      permissionPage: [1],
      showCreatePermissionGroup: false,
      showConfirmDeleteModal: false,
      toRemoveID: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangePermissionGroupUsersHOC = (key, val) =>
      this.setState({ [key]: val });

    getPermissionGroups = (page = 1, search = "") =>
      Get(
        `/admin/permission_groups?${search ? search : ""}page=${page}`,
        this.getPermissionGroupsSuccess,
        this.getPermissionGroupsError,
        this.load,
      );
    getPermissionGroupsSuccess = (payload) => {
      let tempPage = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tempPage.push(i);
      }
      this.setState({
        permissionGroups: payload,
        permissionPage: tempPage,
      });
    };
    getPermissionGroupsError = (error) => requestError(error);

    getSelectedPermissionGroup = (id) =>
      Get(
        `/admin/permission_groups/${id}`,
        this.getSelectedPermissionGroupSuccess,
        this.getSelectedPermissionGroupError,
        this.load,
      );
    getSelectedPermissionGroupSuccess = (payload) =>
      this.setState(
        {
          selectedPermissionGroup: payload,
          showUpdatePermissionGroup: true,
        },
        () => {
          this.getPermittedItems(payload.id);
        },
      );
    getSelectedPermissionGroupError = (error) => requestError(error);

    //To get permitted items
    getPermittedItems = (page = 1, search = "") => {
      let id = _.cloneDeep(this.state.selectedPermissionGroup.id);
      Get(
        `/admin/permission_groups/${id}/permitted_items?${search}page=${page}`,
        this.getPermittedItemsSuccess,
        this.getPermittedItemsError,
        this.load,
      );
    };
    getPermittedItemsSuccess = (payload) => {
      this.setState({ permittedItems: payload });
    };
    getPermittedItemsError = (errorMessage) => {
      requestError(errorMessage);
    };

    //API for Users Tab
    createPermissionGroup = (data) =>
      Post(
        `/admin/permission_groups`,
        data,
        this.createPermissionGroupSuccess,
        this.createPermissionGroupError,
        this.load,
      );
    createPermissionGroupSuccess = (payload) => {
      this.setState(
        {
          showCreatePermissionGroup: false,
        },
        () => {
          requestSuccess("Permission group is created successfully.");
          this.getSelectedPermissionGroup(payload.id);
          this.getPermissionGroups(1, "");
        },
      );
    };
    createPermissionGroupError = (error) => requestError(error);

    updateSelectedPermissionGroup = () => {
      let data = _.cloneDeep(this.state.selectedPermissionGroup);
      let id = data.id;

      Put(
        `/admin/permission_groups/${id}`,
        data,
        this.updateSelectedPermissionGroupSuccess,
        this.updateSelectedPermissionGroupError,
        this.load,
      );
    };
    updateSelectedPermissionGroupSuccess = () => {
      this.getPermissionGroups(1, "");
      requestSuccess("Permission group updated successfully.");
    };
    updateSelectedPermissionGroupError = (error) => requestError(error);

    removePermissionGroup = (id) =>
      Delete(
        `/admin/permission_groups/${id}`,
        this.removePermissionGroupSuccess,
        this.removePermissionGroupError,
        this.load,
      );
    removePermissionGroupSuccess = () => {
      this.getPermissionGroups(1, "");
      requestSuccess("The record is removed successfully.");
      this.setState({ showConfirmDeleteModal: false });
    };
    removePermissionGroupError = (error) => requestError(error);

    updatePermissionSettings = (dataToSubmit) =>
      Put(
        `/entity_settings/${this.state.selectedPermissionGroup.entity_settings[0].id}`,
        {
          settings: dataToSubmit,
        },
        this.updatePermissionSettingsSuccess,
        this.updatePermissionSettingsError,
        this.load,
      );
    updatePermissionSettingsSuccess = () => {
      this.getSelectedPermissionGroup(this.state.selectedPermissionGroup.id);
      requestSuccess("Permission Setting Updated");
    };
    updatePermissionSettingsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadPermissionGroupUsers={this.state.loading}
            permissionGroups={this.state.permissionGroups}
            permissionPage={this.state.permissionPage}
            searchParamsPermission={this.state.searchParamsPermission}
            showCreatePermissionGroup={this.state.showCreatePermissionGroup}
            showUpdatePermissionGroup={this.state.showUpdatePermissionGroup}
            selectedPermissionGroup={this.state.selectedPermissionGroup}
            permittedItems={this.state.permittedItems}
            createPermissionGroup={this.createPermissionGroup}
            getPermittedItems={this.getPermittedItems}
            getPermissionGroups={this.getPermissionGroups}
            getSelectedPermissionGroup={this.getSelectedPermissionGroup}
            updateSelectedPermissionGroup={this.updateSelectedPermissionGroup}
            updatePermissionSettings={this.updatePermissionSettings}
            onChangePermissionGroupUsersHOC={
              this.onChangePermissionGroupUsersHOC
            }
          />

          <ConfirmationModal
            open={this.state.showConfirmDeleteModal}
            loading={this.state.loading}
            message={"Are you sure to remove the selected record?"}
            positiveAction={() => {
              this.removePermissionGroup(this.state.toRemoveID);
            }}
            negativeAction={() =>
              this.setState({ showConfirmDeleteModal: false })
            }
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
