import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Region, Status, Type } from "../../../components/options";

import { Get, Post, Put } from "utils/axios";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Form Number",
    value: "tracking_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Form Type",
    value: "form_type_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "1", label: "Physical Form" },
      { value: "2", label: "Co-Agency Form" },
      { value: "3", label: "Renewal Form" },
    ],
  },
];

const PaperFormHOC = (WrappedComponent) => {
  class PaperFormWrappedComponent extends Component {
    state = {
      paperForms: [],
      paperFormPage: null,
      loading: false,

      showCreateDialog: false,
      disableSubmitButton: false,

      coAgencyId: null,
      newPaperForm: {
        region_id: "",
        agency_id: "",
        tracking_number: "",
        type_id: "",
        form_type_id: "2",
      },
      selectedPaperForm: {},
      searchParams: searchParams,
      showUpdate: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangePaperFormHOC = (val, context) => this.setState({ [context]: val });

    //generate renewal form number
    generateRenewalFormNumber = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let temp = { agent_id: id };
      Post(
        `/admin/forms/sub_sales_forms/generate_renewal_form_number`,
        temp,
        this.generateRenewalFormNumberSuccess,
        this.generateRenewalFormNumberError,
        this.load,
      );
    };
    generateRenewalFormNumberSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.newPaperForm);
      temp.tracking_number = payload.renewal_form_number;
      this.setState({ newPaperForm: temp });
    };
    generateRenewalFormNumberError = (error) => requestError(error);

    //generate form number using co-agency id
    generateFormNumber = () => {
      let temp = { id: this.state.coAgencyId };
      Post(
        `/admin/forms/sub_sales_forms/generate_form_number`,
        temp,
        this.generateFormNumberSuccess,
        this.generateFormNumberError,
        this.load,
      );
    };
    generateFormNumberSuccess = (payload) => {
      let temp = this.state.newPaperForm;
      temp.tracking_number = payload.form_number;
      this.setState({ newPaperForm: temp });
    };
    generateFormNumberError = (error) => requestError(error);

    // get all paper forms
    getPaperForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/forms/sub_sales_forms?${search}page=${page}`,
        this.getPaperFormsSuccess,
        this.getPaperFormsError,
        this.load,
      );
    };
    getPaperFormsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }
      payload.data &&
        payload.data.map((item) => {
          tmp.push({
            ...item,
            form_type:
              item.form_type_id === 1
                ? "Physical Form"
                : item.form_type_id === 2
                  ? " Co-agency Form"
                  : "Renewal Form",
            region: _.find(Region, { id: item.region_id })
              ? _.find(Region, { id: item.region_id }).label
              : "",
            type: _.find(Type, { id: item.type_id.toString() })
              ? _.find(Type, { id: item.type_id.toString() }).label
              : "",
            status: _.find(Status, { id: item.status_id })
              ? _.find(Status, { id: item.status_id }).label
              : "",
            date_taken: Moment(item.date_taken).format("YYYY-MM-DD HH:mm:ss"),
            date_submitted: item.date_submitted
              ? Moment(item.date_submitted).format("YYYY-MM-DD HH:mm:ss")
              : "",
          });
        });
      return this.setState({
        paperForms: {
          data: tmp,
          meta: payload.meta,
        },
        paperFormPage: tmpTotalPages,
      });
    };
    getPaperFormsError = (error) => requestError(error);

    getSelectedPaperForm = (id) =>
      Get(
        `/forms/sub_sales_forms/${id}`,
        this.getSelectedPaperFormSuccess,
        this.getSelectedPaperFormError,
        this.load,
      );
    getSelectedPaperFormSuccess = (payload) =>
      this.setState({
        selectedPaperForm: {
          ...payload,
          date_taken: Moment(payload.date_taken).format("YYYY-MM-DD HH:mm:ss"),
          form_type_id: payload.form_type_id.toString(),
          type_id: payload.type_id.toString(),
        },
        showUpdate: true,
      });
    getSelectedPaperFormError = (error) => requestError(error);

    //create new paper form
    createPaperForm = (data) =>
      Post(
        `/forms/sub_sales_forms`,
        {
          ...data,
          form_type_id: parseInt(data.form_type_id),
        },
        this.createPaperFormSuccess,
        this.createPaperFormError,
        this.load,
      );
    createPaperFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getPaperForms(currentPage, searchParams);
      this.setState({
        showCreateDialog: false,
        disableSubmitButton: false,
        newPaperForm: {
          region_id: "",
          tracking_number: "",
          type_id: "",
          form_type_id: "2",
        },
      });
      requestSuccess("Form is created successfully.");
    };
    createPaperFormError = (error) => {
      requestError(error);
      this.setState({ disableSubmitButton: false });
    };

    updatePaperForm = (id, data) =>
      Put(
        `/forms/sub_sales_forms/${id}`,
        data,
        this.updatePaperFormSuccess,
        this.updatePaperFormError,
        this.load,
      );
    updatePaperFormSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Form is updated successfully.");
      this.getPaperForms(currentPage, searchParams);
    };
    updatePaperFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            paperFormPage={this.state.paperFormPage}
            coAgencyId={this.state.coAgencyId}
            paperForms={this.state.paperForms}
            searchParams={this.state.searchParams}
            onLoadPaperForms={this.state.loading}
            showCreateDialog={this.state.showCreateDialog}
            newPaperForm={this.state.newPaperForm}
            disableSubmitButton={this.state.disableSubmitButton}
            selectedPaperForm={this.state.selectedPaperForm}
            showUpdate={this.state.showUpdate}
            getSelectedPaperForm={this.getSelectedPaperForm}
            getPaperForms={this.getPaperForms}
            createPaperForm={this.createPaperForm}
            generateFormNumber={this.generateFormNumber}
            onChangePaperFormHOC={this.onChangePaperFormHOC}
            generateRenewalFormNumber={this.generateRenewalFormNumber}
            updatePaperForm={this.updatePaperForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(PaperFormWrappedComponent);
};

export default PaperFormHOC;
