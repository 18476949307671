import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import CustomTable from "components/NewTable";
import AtlasIcon from 'components/Icon/atlasIcon'
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const columnData = ({onChangeFormData}) => [
  {
    header: "Key",
    accessor: "key",
    searchFlag: "key",
    label: "Key",
  },
  {
    header: "Default Translation",
    accessor: "default_translation",
    searchFlag: "default_translation",
    label: "Default Translation",
  },
  {
    header: "Translation",
    renderColumn: (rowData) => (
      <CustomFormInput
        value={rowData.translation}
        onChangeValue={(val) => onChangeFormData(rowData.id, val)} />
    ),
    searchFlag: "translation",
    label: "Translation",
  },
]

const BulkUpdate = props => {
  // Listing Centre Module Id = 14
  const [formData, setFormData] = useState([])

  useEffect(() => {
    if (props.moduleKeysToEdit.length > 0) {
      setFormData(props.moduleKeysToEdit)
    } else {
      setFormData([])
    }
  }, [props.moduleKeysToEdit])

  useEffect(() => {
    if (props.selectedTranslation) {
      props.getModuleKeys(14, props.selectedTranslation.language_code, true)
    }
  }, [props.selectedTranslation])

  const onChangeFormData = (id, value) => {
    let temp = _.cloneDeep(formData);
    const rowIndex = temp.findIndex(row => row.id === id);
    if (rowIndex !== -1) {
      temp[rowIndex].translation = value;
      setFormData(temp);
    }
  };

  const onToggleUpdate = () => {
    let temp = _.filter(formData, (data) => (data.translation && data.translation !== null))
    temp = _.map(temp, (data) => ({
      id: data.id,
      translations: {
        [props.selectedTranslation.language_code]: data.translation
      }
    }))
    props.permissibleBulkUpdate(temp, props.selectedTranslation?.language_code)
  }

  const renderCheckBoxActions = () => (
    <div className="at-localised-center_table-title">
      <div className='d-flex align-items-center g-2'>
        <div
          className="at-renewal-details__icon-badge"
          style={{ backgroundColor: "#FFEDD5" }}
        >
          <AtlasIcon
            svgHref={"atlas-translate-linear"}
            style={{ fill: "#EA580C" }}
          />
        </div>
        <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
          Translate to {props.selectedTranslation?.language_name}
        </p>
      </div>
    </div>
  )

  return (
    <>
    <ModalDialog
      title="Bulk Edit Translation"
      fullWidth={true}
      fullHeight={true}
      onLoad={props.onLoad}
      onClose={() => props.onClose()}
      children={
        <div className='grid-control'>
          <section className='grid-half-col d-flex flex-column'>
            <h2 className='at-form-input__title'>Module</h2>
            <p>{props.moduleName}</p>
          </section>
          <section className='grid-full-col'>
            <CustomTable
              rowData={formData || []}
              columns={columnData({ onChangeFormData })}
              resetRef={false}
              pagination={true}
              renderCheckBoxActions={renderCheckBoxActions}
            />
          </section>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--success"
            disabled={_.isEqual(formData, props.moduleKeysToEdit)}
            onClick={() => onToggleUpdate()}>
            Update
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onClose()}>
            Cancel
          </button>
        </div>
      } />
    </>
  )
}

export default BulkUpdate