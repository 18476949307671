import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";

import DaysInMonthGrid from "./monthGrid";
import DaysInMonthList from "./monthList";

const CalendarDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class CustomCalendar extends Component {
  state = {
    dateToday: Moment(),
    currentDisplayMonth: Moment().clone().startOf("month"),
    endDateOfLastMonth: "",
    startDayOfMonth: "",
    startDayOfNextMonth: "",
    dayBeforeStart: "",
    selectedDay: "",
    filter: "",
  };

  componentDidMount = () => {
    this.setDayOrDateOfMonth();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentDisplayMonth !== this.props.currentDisplayMonth) {
      this.setState(
        {
          currentDisplayMonth: Moment(this.props.currentDisplayMonth)
            .clone()
            .startOf("month"),
        },
        () => this.setDayOrDateOfMonth(),
      );
    }
  };

  setDayOrDateOfMonth = () => {
    this.setState({
      endDateOfLastMonth: this.state.currentDisplayMonth
        .clone()
        .add(-1, "month")
        .endOf("month")
        .date(),
      startDayOfMonth: this.state.currentDisplayMonth.startOf("month").day(),
      startDayOfNextMonth: this.state.currentDisplayMonth
        .clone()
        .add(1, "month")
        .startOf("month")
        .day(),
    });
  };

  displayMonth = () => {
    return this.state.currentDisplayMonth.format("MMMM YYYY");
  };

  onClickPreviousMonth = () => {
    this.setState(
      {
        currentDisplayMonth: this.state.currentDisplayMonth.add(-1, "M"),
      },
      () => {
        this.setDayOrDateOfMonth();
      },
    );
  };

  onClickNextMonth = () => {
    this.setState(
      {
        currentDisplayMonth: this.state.currentDisplayMonth.add(1, "M"),
      },
      () => {
        this.setDayOrDateOfMonth();
      },
    );
  };

  onChangeFilter = (val) => {
    if (val === "") {
      this.setState({ filter: "" });
      this.props.onToggleSearch("");
    } else {
      this.setState({ filter: val });
    }
  };

  renderFilter = () => {
    return (
      <div className="d-flex align-items-center g-3 w-100">
        <CustomFormInput
          type="text"
          value={this.state.filter}
          placeholder="Search Event Name"
          containerStyle={{ minWidth: 190 }}
          showClearButton={this.state.filter !== ""}
          onChangeValue={this.onChangeFilter}
        />
        <button
          style={{ padding: "8px 12px" }}
          className="btn-new btn-new--secondary"
          onClick={() => this.props.onToggleSearch(this.state.filter)}
        >
          Search
        </button>
      </div>
    );
  };

  renderCalendarMonth = (mobile) => {
    return (
      <div
        className={`d-flex align-items-center w-100 my-2 g-3
        ${
          mobile && this.props.listview
            ? "at-calendar--list-event-mobile"
            : "justify-content-around"
        }`}
        style={{ minWidth: 150 }}
      >
        {!this.props.listview && (
          <div
            className="at-calendar__month--previous"
            onClick={() => this.onClickPreviousMonth()}
          >
            <AtlasIcon svgHref="atlas-arrow-left-1-linear" />
          </div>
        )}
        <span className="fw-600 fs-5">
          {this.props.listview ? "Event List" : this.displayMonth()}
        </span>
        {!this.props.listview && (
          <div
            className="at-calendar__month--next"
            onClick={() => this.onClickNextMonth()}
          >
            <AtlasIcon svgHref="atlas-arrow-right-1-linear" />
          </div>
        )}
      </div>
    );
  };

  renderCalendarHeader = () => {
    return (
      <div className="at-calendar__day-header">
        {CalendarDays.map((item) => {
          return (
            <div key={item} className="at-calendar__weekday">
              {item}
            </div>
          );
        })}
      </div>
    );
  };

  renderCalendarGridBeforeMonth = (param) => {
    let arrayPreviousMonth = [];
    let currentDisplayMonthTimeline = this.state.currentDisplayMonth;
    currentDisplayMonthTimeline = Moment(currentDisplayMonthTimeline).add(
      -1,
      "M",
    );
    for (let i = 1; i <= param; i++) {
      let reduceDayCount = param - i;
      arrayPreviousMonth.push({
        date: this.state.endDateOfLastMonth - reduceDayCount,
        realDate: Moment(currentDisplayMonthTimeline).date(
          this.state.endDateOfLastMonth - reduceDayCount,
        ),
      });
    }
    return _.map(arrayPreviousMonth, (item) => {
      return (
        <div
          key={item.date}
          className="at-calendar__day at-calendar__day--not-this-month"
        >
          <span>{item.date}</span>
        </div>
      );
    });
  };

  renderCalendarGridAfterMonth = () => {
    let arrayNextMonth = [];
    let startDayCount = 1;
    let currentDisplayMonthTimeline = this.state.currentDisplayMonth;
    currentDisplayMonthTimeline = Moment(currentDisplayMonthTimeline).add(
      1,
      "M",
    );
    for (let i = this.state.startDayOfNextMonth; i < 7; i++) {
      arrayNextMonth.push({
        date: startDayCount,
        realDate: Moment(currentDisplayMonthTimeline).date(startDayCount),
      });
      startDayCount++;
    }
    return _.map(arrayNextMonth, (item) => {
      return (
        <div
          key={item.date}
          className="at-calendar__day at-calendar__day--not-this-month"
        >
          <span>{item.date}</span>
        </div>
      );
    });
  };

  renderGridCalendar = () => {
    return (
      <>
        {this.renderCalendarGridBeforeMonth(this.state.startDayOfMonth)}
        <DaysInMonthGrid
          renderTooltip={this.props.renderTooltip}
          currentDisplayMonth={this.state.currentDisplayMonth}
          showDetailsDialog={this.props.showDetailsDialog}
          dateToday={this.state.dateToday}
          storeForArrangedEventData={this.props.storeForArrangedEventData}
          repeat={this.props.repeat}
          onClickEventDetails={(data) => this.props.onClickEventDetails(data)}
        />
        {this.renderCalendarGridAfterMonth()}
      </>
    );
  };

  renderListCalendar = () => {
    if (
      this.props.storeForArrangedEventData &&
      this.props.storeForArrangedEventData.length !== 0
    ) {
      return (
        <DaysInMonthList
          data={this.props.data}
          currentDisplayMonth={this.state.currentDisplayMonth}
          dateToday={this.state.dateToday}
          storeForArrangedEventData={this.props.storeForArrangedEventData}
          listview={this.props.listview}
          onChangeCalendarHOC={this.props.onChangeCalendarHOC}
          onClickEventDetails={(data) => this.props.onClickEventDetails(data)}
        />
      );
    } else {
      return <p className="text-center color-lightgrey mt-4">No event found</p>;
    }
  };

  renderCalendarContent = () => {
    return (
      <>
        {!this.props.listview && (
          <div className="at-calendar__day-set-container">
            <div className="at-calendar__day-set">
              {this.renderGridCalendar()}
            </div>
          </div>
        )}
        {this.props.listview && this.renderListCalendar()}
      </>
    );
  };

  render = () => {
    return (
      <>
        <div className="at-calendar__month">
          {this.props.renderViewType && this.props.renderViewType()}
          <div className="at-desktop-view__controller">
            {this.renderCalendarMonth()}
          </div>
          <div className="at-calendar__month-filter">
            {this.props.renderAdditionalFilter &&
              this.props.renderAdditionalFilter()}
            {this.renderFilter()}
          </div>
        </div>
        <div
          className="at-company__affairs-content"
          style={
            this.props.listview
              ? {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }
              : { borderRadius: 8 }
          }
        >
          <div
            className={`at-cal ${this.props.listview ? "at-calendar--list" : ""}`}
          >
            <div className="at-mobile-view__controller">
              {this.renderCalendarMonth(true)}
            </div>
            {this.renderCalendarHeader()}
            {this.renderCalendarContent()}
          </div>
        </div>
      </>
    );
  };
}

export default CustomCalendar;
