import React, { Component } from "react";
import _ from "lodash";
import { Tooltip, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { BsInfoCircleFill, BsTrash3Fill } from "react-icons/bs";
import { MdEmail, MdAdd } from "react-icons/md";
import { FaPhone, FaUserTie } from "react-icons/fa";

import PhotoSlider from "./PhotoSlider";
import SVGIcon from "./icon";

import { numberWithCommas } from "utils/thousandSeparator";
import { getColorBadge } from "dictionary/badgeColor";
import DefaultAvatar from "assets/images/av-male.jpg";

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
];
class CustomCard extends Component {
  renderAgentContent = () => {
    const {
      avatar_url = DefaultAvatar,
      full_name = "N/A",
      mobile_contact_number = "N/A",
      email = "N/A",
      team_name = "N/A",
    } = this.props.rowItem.agent_info;
    return (
      <div className={"at-presentation__agent-section"}>
        <img
          src={avatar_url}
          onError={(e) => {
            if (e.target) {
              e.target.src = DefaultAvatar;
            }
          }}
        />
        <div>
          <small>{`Listed by `}</small>
          <strong>{full_name}</strong>
          <div className="d-flex">
            <div className="at-presentation__agent-section_sub-details">
              <FaPhone />
              <p>{mobile_contact_number}</p>
            </div>
            <div className="at-presentation__agent-section_sub-details ml-2">
              <FaUserTie />
              <p>{team_name || "N/A"}</p>
            </div>
          </div>
          <div className="at-presentation__agent-section_sub-details">
            <MdEmail />
            <p>{email}</p>
          </div>
        </div>
      </div>
    );
  };

  renderSpecs = () => {
    const {
      id,
      bedrooms_label,
      bathrooms_label,
      measurement,
      carparks_label,
      land_area,
      built_up,
      updated_on,
    } = this.props.rowItem;

    return (
      <div className="at-presentation__specs">
        <div className="at-presentation__props-available_date">
          {updated_on && (
            <SVGIcon
              itemID={`${id}-date`}
              containerClassName={`d-flex at-presentation__icon`}
              tooltip={true}
              iconClassName={`svg-icon-calendar mr-2`}
              iconName={`icon-calendar`}
              tooltipLabel={
                <span className="at-presentation_tooltip-title">
                  {"Updated On"}
                </span>
              }
              children={<span className="fw-400">{updated_on}</span>}
            />
          )}
        </div>
        <div className="at-presentation__props-features">
          {bedrooms_label && (
            <SVGIcon
              itemID={`${id}-${bedrooms_label}-bedrooms`}
              containerClassName={`d-flex at-presentation__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bed mr-2`}
              iconName={`icon-bed`}
              tooltipLabel={
                <span className="at-presentation_tooltip-title">Bedrooms</span>
              }
              children={<span className="fw-400">{bedrooms_label}</span>}
            />
          )}
          {bathrooms_label && (
            <SVGIcon
              itemID={`${id}-${bathrooms_label}-bathrooms`}
              containerClassName={`d-flex at-presentation__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bathroom mr-2`}
              iconName={`icon-bathroom`}
              tooltipLabel={
                <span className="at-presentation_tooltip-title">Bathrooms</span>
              }
              children={<span className="fw-400">{bathrooms_label}</span>}
            />
          )}
          {carparks_label && (
            <SVGIcon
              itemID={`${id}-${carparks_label}-carparks`}
              containerClassName={`d-flex at-presentation__icon`}
              tooltip={true}
              iconClassName={`svg-icon-carpark mr-2`}
              iconName={`icon-carpark`}
              tooltipLabel={
                <span className="at-presentation_tooltip-title">Car Parks</span>
              }
              children={<span className="fw-400">{carparks_label}</span>}
            />
          )}
          <span className={`at-presentation__props-built_up__land_area`}>
            <SVGIcon
              itemID={`${id}-${measurement}-builtUp`}
              containerClassName={`d-flex at-presentation__icon`}
              tooltip={true}
              iconClassName={`svg-icon-squarefeet mr-2`}
              iconName={`icon-squarefeet`}
              tooltipLabel={
                <span className="at-presentation_tooltip-title">
                  Built Up / Land Area
                </span>
              }
              children={
                <span className="fw-400">
                  {built_up ? built_up + " " : "N/A"}
                  {land_area ? " / " + land_area + " " + measurement : "/ N/A"}
                </span>
              }
            />
          </span>
        </div>
      </div>
    );
  };

  renderStatus = () => {
    const { status } = this.props.rowItem;
    const { colorName = "Red" } = _.find(StatusColor, { value: status }) || {};

    return (
      <span
        className={`at-presentation__status at-status_badge`}
        style={{ ...getColorBadge(colorName) }}
      >
        {status}
      </span>
    );
  };

  render() {
    const {
      code,
      property,
      category,
      type,
      id,
      estate,
      asking_price_currency,
      asking_price_cents,
      images,
      property_name,
      featureArray,
    } = this.props.rowItem;
    const {
      rowItem,
      actionTypes,
      sideMenuExpand,
      onClickAdd,
      onClickDetails,
      onClickRemove,
    } = this.props;
    return (
      <li
        className={`
        at-presentation__item col-xs-12 col-sm-6 col-md-6 ${
          sideMenuExpand
            ? "col-lg-6 col-xl-4 at-presentation__item-menu-expand"
            : "col-lg-4 col-xl-4 at-presentation__item-menu-shirnk"
        } 
        col-12`}
      >
        <PhotoSlider
          id={id}
          images={images}
          content={
            <>
              <div className="at-presentation__details-upper_cont color-black">
                <div className="at-presentation__details-upper_cont-cont">
                  {this.renderStatus()}
                  <h6 className="at-presentation__code">{code}</h6>
                </div>
                <div className="at-presentation__details-action_cont">
                  <DarkTooltip
                    classes={{ tooltip: "tooltip-arrow top" }}
                    aria-label={"action-button"}
                    placement={"top"}
                    title={"See estate details"}
                  >
                    <IconButton
                      classes={{ root: "btn-new--secondary" }}
                      onClick={() => onClickDetails(rowItem)}
                    >
                      <BsInfoCircleFill className={"color-white"} />
                    </IconButton>
                  </DarkTooltip>
                  {actionTypes.indexOf("remove") > -1 && estate && (
                    <DarkTooltip
                      classes={{ tooltip: "tooltip-arrow top" }}
                      aria-label={"action-button"}
                      placement={"top"}
                      title={"Remove from listing"}
                    >
                      <IconButton
                        classes={{ root: "bg-danger" }}
                        onClick={() => onClickRemove(rowItem)}
                      >
                        <BsTrash3Fill className={"color-white"} />
                      </IconButton>
                    </DarkTooltip>
                  )}
                  {actionTypes.indexOf("add") > -1 && !estate && (
                    <DarkTooltip
                      classes={{ tooltip: "tooltip-arrow top" }}
                      aria-label={"action-button"}
                      placement={"top"}
                      title={"Add to listing"}
                    >
                      <IconButton
                        classes={{ root: "btn-new--primary" }}
                        onClick={() => onClickAdd(rowItem)}
                      >
                        <MdAdd className={"color-white"} />
                      </IconButton>
                    </DarkTooltip>
                  )}
                </div>
              </div>
              <div className="at-presentation__details-lower_cont">
                <div>
                  <DarkTooltip
                    key={"property_name"}
                    classes={{ tooltip: "tooltip-arrow top" }}
                    aria-label={"property_name"}
                    placement={"top-start"}
                    title={property_name}
                  >
                    <h5 className="at-presentation__title">
                      {`${property_name}`}
                    </h5>
                  </DarkTooltip>
                  <p className="at-presentation__price">
                    {
                      <span className="color-white mr-2">
                        {asking_price_currency}
                      </span>
                    }
                    {`${
                      asking_price_cents && asking_price_cents > 0
                        ? numberWithCommas(asking_price_cents)
                        : "N/A"
                    }`}
                  </p>
                  {this.renderSpecs()}
                </div>
                <div className="at-presentation__info">
                  {type !== "N/A" && (
                    <DarkTooltip
                      key={"Subsales_Type-" + id}
                      classes={{ tooltip: "tooltip-arrow top" }}
                      aria-label={"presentation_type"}
                      placement={"top"}
                      title={"Type"}
                    >
                      <span>{type}</span>
                    </DarkTooltip>
                  )}
                  {featureArray &&
                    featureArray.length > 0 &&
                    featureArray.map((item, index) => {
                      return (
                        item.value && (
                          <DarkTooltip
                            key={"feature-" + index + "-" + id}
                            classes={{ tooltip: "tooltip-arrow top" }}
                            aria-label={item.value}
                            placement={"top"}
                            title={item.label}
                          >
                            <span>{item.value}</span>
                          </DarkTooltip>
                        )
                      );
                    })}
                  {category && category !== "N/A" && (
                    <DarkTooltip
                      key={"property-1-" + id}
                      classes={{ tooltip: "tooltip-arrow top" }}
                      aria-label={"Property Type"}
                      placement={"top"}
                      title={"Property Type"}
                    >
                      <span>{category}</span>
                    </DarkTooltip>
                  )}
                  {property && property !== "N/A" && (
                    <DarkTooltip
                      key={"property-2-" + id}
                      classes={{ tooltip: "tooltip-arrow top" }}
                      aria-label={"Property Type"}
                      placement={"top"}
                      title={"Property Type"}
                    >
                      <span>{" -  " + property}</span>
                    </DarkTooltip>
                  )}
                </div>
              </div>
              {this.renderAgentContent()}
            </>
          }
        />
      </li>
    );
  }
}

export default CustomCard;
