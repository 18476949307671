import Moment from "moment";

export const renderGeneralContent = (props) => (
  <>
    <p>
      Welcome to our website! 
    </p>
    <p style={{ paddingTop: 20 }}>
      This website is operated by IQI ATLAS team. Please read the 
      following terms and conditions carefully before accessing 
      or using our website. By accessing or using any part of the 
      website, you agree to be bound by these terms and condition. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (2) Website Content
    </p>
    <p>
      You acknowledge that the information provided on this website 
      is based on information made available to us at the time of 
      publication and may change without notice. We make no representations 
      or warranties as to the accuracy, completeness, or reliability 
      of such information. It is your responsibility to conduct your 
      own independent assessment and due diligence regarding any 
      property or investment opportunity.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (3) User Conduct
    </p>
    <p>
      You agree not to use the website for any illegal or unauthorized 
      purpose. You are responsible for all content you submit to this 
      website. We reserve the right to remove or edit any content that 
      violates these terms and conditions.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (4) Intellectual Property
    </p>
    <p>
      All content on this website, including but not limited to texts, 
      images, and logos, is protected by copyright and other intellectual 
      property laws. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (5) Limitation of Liability
    </p>
    <p>
      We are not liable for any damages arising from your use of the website, 
      including but not limited to any harm, loss or damage, however caused, 
      arising out of your use of the website, including direct, indirect, 
      special, incidental, third party or consequential damages. We shall 
      not be responsible for any detrimental reliance that you may place 
      upon the website or its contents.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (6) Indemnification
    </p>
    <p>
      You agree to indemnify and hold us harmless from any claims, damages, 
      liabilities, or expenses arising out of your use of the website or your 
      violation of these Terms and Conditions.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (7) Changes to Terms
    </p>
    <p>
      We reserve the right to modify these Terms and Conditions at any time. Notice 
      may be given to you in the form of a pop-up, writing or by posting such 
      notice on this website. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (8) Governing Law
    </p>
    <p>
      These Terms and Conditions shall be governed by and construed in accordance 
      with the laws of Malaysia. While international law principles may be considered 
      by Malaysian courts, the application of such principles shall be subject to 
      the discretion of the courts and the specific circumstances of each case.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (9) Company Policies and Handbook
    </p>
    <p>
      You agree to comply with all our company policies and procedures including 
      but not limited to the terms and conditions as outlined in our Real Estate 
      Negotiators’ Handbook, Company's Policy & Procedure Handbook on Anti-Money 
      Laundering, Countering Financing of Terrorism, and Targeted Financial Sanctions, 
      and any internal memorandums that shall be issued from time to time. These 
      documents are available on the website.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (10) Privacy Policy
    </p>
    <p>
      You authorize the Company to disclose your personal information and/or the 
      information you have submitted to the Website to relevant third parties 
      for the purpose of data processing. Such disclosure may include, but is not 
      limited to, sharing the information which you have submitted with service 
      providers, business partners, and regulatory authorities. For more details 
      on our data practices, please refer to our Privacy Policy 
      <a href="https://www.iqiglobal.com/privacy-policy" target={"_blank"} 
        style={{color: "#2563eb"}}> here
      </a>. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (11) Termination
    </p>
    <p>
      We reserve the right to terminate your access to or use of the website at 
      any time, with or without cause, and with or without notice.
    </p>
    <p style={{ paddingTop: 20 }}>
      Should you have any questions, feel free to contact our ATLAS team 
      at <spam style={{ fontWeight: "bold" }}>helpdesk@iqiglobal.com</spam>.
    </p>
  </>
)

export const renderCanadaContent = () => (
  <>
    <div className={"d-flex"}>
      <h4 style={{ fontWeight: "bold" }}>Real Estate Negotiator</h4>
    </div>
    <p>
      This Agreement made on <b>02 November 2022</b>
    </p>
    <p style={{ paddingTop: 20 }}>BETWEEN:</p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      IQI Global Real Estate Ltd.
    </p>
    <p>
      A corporation incorporated under the laws of the Province of Ontario
    </p>
    <p>(Hereinafter referred to as the "Corporation") </p>
    <p>IQI Global Real Estate Ltd, BROKERAGE,</p>
    <p style={{ paddingTop: 20 }}>-and-</p>
    <p style={{ paddingTop: 20 }}>
      you (hereinafter referred to as the “Contractor”)
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      BACKGROUND TO AGREEMENT
    </p>
    <p style={{ paddingTop: 20 }}>
      The Corporation is duly registered as a real estate broker under the
      Real Estate and Business Brokers Act of Ontario (the "Act") and owns,
      for the purpose thereof, the facilities and equipment required to
      conduct the real estate brokerage business. The Contractor wishes to
      secure access to such facilities and equipment.
    </p>
    <p>
      The Contractor is an independent contractor desiring access to such
      facilities and equipment for conducting there from a real estate
      business.
    </p>
    <p>
      Now THEREFORE in consideration of the mutual covenants and agreements
      and for other good and valuable consideration as herein provided the
      receipt and sufficiency of which is hereby acknowledged the parties
      hereto agree as follows:
    </p>
    <ol type="1">
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        APPOINTMENT
        <p>
          The Brokerage hereby retains and appoints the Contractor as an
          independent contractor per this Agreement and the Contractor hereby
          accepts such appointment as an independent contractor upon and
          subject to the terms, conditions, covenants, and provisions set
          forth herein. The parties acknowledge that the Contractor is a
          self-employed independent contractor and is not and shall not
          represent himself or herself to be a partner or employee of the
          Brokerage or to have any relationship to the Brokerage other than as
          a self-employed independent contractor.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        FACILITIES
        <p>
          The Corporation agrees to provide the Contractor with workspace in
          the real estate sales office from time to time owned or leased by
          the Corporation, secretarial services during normal business hours,
          use of telephone, reception areas, and such other facilities and
          services which may be made available from time to time. The
          Contractor is hereby given the non-exclusive right to use all such
          facilities as are maintained from time to time by the Corporation in
          common with the other salespeople contracting with the Corporation
          working from the said premises. The Contractor shall abide by the
          rules and regulations, office policies and procedures, and
          advertising standards promulgated by the Corporation from time to
          time of general application to persons contracting with the
          Corporation like this Agreement.{" "}
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        GENERAL CONDITIONS
        <ol type="1" style={{ fontWeight: 400 }}>
          <li>
            The Contractor shall govern their conduct by the Act and
            Regulations and the constitution, by-laws, Code of Ethics and
            Standards of Business Practice of the local real estate
            board/association of their membership (the "Board"), and all
            requirements of the Real Estate Council of Ontario (“RECO”) all as
            may be amended from time to time.{" "}
          </li>
          <li>
            The Contractor shall furnish their automobile and pay all expenses
            thereon and shall carry liability and property damage insurance
            satisfactory to the Brokerage. A minimum of $2,000,000.00 is
            required for liability insurance and all automobiles used for Real
            Estate sales shall be insured for “Business Use”.{" "}
          </li>
          <li>
            The Contractor shall provide proof of use of such insurance to the
            Brokerage upon request.{" "}
          </li>
          <li>
            The Contractor shall during the Term of this Agreement:
            <ol type="a" style={{ fontWeight: 400 }}>
              <li>
                maintain in good standing all registrations required by the
                Act and Regulations necessary to trade in real estate;
              </li>
              <li>be a member in good standing of RECO: and </li>
              <li>be a member in good standing of the Board(s).</li>
            </ol>
          </li>
          <li>
            The Contractor shall maintain errors and omissions and such other
            insurance as RECO may require from time to time.
          </li>
          <li>
            The Contractor shall obtain and maintain Goods and Services Tax
            registration as required by the Excise Tax Act (Canada) as amended
            from time to time.
          </li>
          <li>
            The Contractor shall not obligate the Brokerage for goods or
            services.
          </li>
          <li>
            The Contractor shall only use such real estate forms that have
            been approved by the Brokerage before their use.
          </li>
          <li>
            Agent can not directly solicit any pre construction project to
            other IQI agents, which is not approved by IQI Management
          </li>
        </ol>
        <p>
          The Brokerage may set-off the Contractor's commission or other
          amounts the Brokerage may owe the Contractor or any amount due to
          the Brokerage from the Contractor including without limitation
          payable to the Brokerage according to Article Five of this
          Agreement.
        </p>
        <p>Other contractor’s duties are as follows:</p>
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            The Contractor is to contact and follow up on all Client leads
            provided to him/her to diligently attempt to turn such a lead into
            either a Buying or Selling Client. If the contractor is not able
            to convert a lead into a client, then the corporation has the
            right to redistribute such leads to other Sales representatives
            within the Corporation;
          </li>
          <li>
            The leads/clients which the Contractor receives from the
            Corporation will belong to the Corporation and shall remain the
            property of the Corporation. If the Contractor converts any of
            those leads/clients after the termination, the Contractor will be
            liable to share the commission on a mutually agreed basis.
          </li>
          <li>
            The contractor agrees to keep in confidence any information which
            he/she obtains while working with the Corporation and will not
            share any personal or Client information with any third parties
            that are not employees or servants of, or Contractors or brokers
            for or contractors with the Corporation. The Contractor agrees to
            keep in strict confidence any marketing strategies and/or business
            models of the Corporation. The Contractor agrees and understands
            that any breach of the confidentiality provisions of this
            Agreement may not be compensable in an award for damages and that
            the Corporation may be entitled to obtain injunctive relief
            against the Contractor for breach of such confidentiality
            provisions in this Agreement.
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        REAL ESTATE LISTINGS
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            The contractor agrees that, during the currency of this Agreement,
            all listings obtained by the Contractor shall be taken in the name
            of the Corporation and immediately turned over to the Corporation.
            The Contractor further agrees that all real estate transactions in
            which he or she has an interest, directly or indirectly will be
            reported to and processed through the corporation on a timely
            basis, Further, the contractor agrees to make written disclosure
            of such interests to the other parties to the transaction as and
            when required by the Act or any applicable Code of Ethics.
          </li>
          <li>
            The Contractor agrees to deliver to the Corporation at the
            earliest opportunity original the copy of each listing obtained by
            the Contractor, including all listings received on an exclusive
            basis' The contractor acknowledges that no listing shall be
            exclusive to the contractor but that all must be shared with all
            Contractors and brokers retained by the Corporation.
          </li>
          <li>
            All cheques received by the Contractor relating to any listing
            secured by the contractor shall immediately be turned over and
            delivered to the corporation for deposit in its trust account and
            the Corporation shall in no circumstances endorse or negotiate on
            behalf of the Corporation any cheque or other instrument
            representing monies payable to the Corporation.{" "}
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        COMMISSIONS
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            The commission payable in respect of each listing secured by the
            Contractor or in respect of any property sold, leased, or referred
            by the Contractor shall be fixed by the Contractor. When any
            property is sold or leased as a result of the Contractor's
            negotiations, the Contractor will be paid its share of the real
            estate commission paid to and received by the Corporation in
            accordance with either Package A or Package B as set out in
            Schedule A respect of such sale or lease subject to the obligation
            of the Corporation to divide such commission between the listing
            and selling Contractors or otherwise as per the custom of the
            trade at the applicable time, and subject to subsection (b)
            hereof.{" "}
          </li>
          <li>
            Each of the Corporation and the Contractor acknowledges that the
            commissions from time to time payable to the Contractor will be
            received and held in trust for the Contractor and the Corporation
            by the Corporation, according to their respective interests as
            herein referred to. Any and all amounts owing to the Corporation
            by the Contractor according to this Agreement or otherwise,
            including, without limitation, all fees, late charges, interest
            and expenses, and other loss, cost, and expense of any nature or
            kind ("indebtedness") shall be deducted from such commissions
            before distribution to the Contractor, it is agreed that to the
            extent of such indebtedness the commissions from time to time
            payable to the Contractor are held in trust for the Corporation
            and may be retained by it as its property absolutely.{" "}
          </li>
          <li>
            Any commission received by the corporation for any property which
            hasn’t had a final closing (title transfer to the Purchaser) will
            be held in the commission trust account until final closing if
            discretion before the final closing then such payments will be
            considered commission advance and will be subject to recall at any
            time in the corporation sole and absolute discretions.{" "}
          </li>
          <li>
            The Contractor acknowledges that any additional income (Commission
            override, bonuses, etc.) which is being paid to the corporation by
            the seller will be for the sole benefit of the corporation and the
            contractor will have no right over such benefit and arrangements.
          </li>
          <li>
            If either the Contractor becomes a Branch Manager or anyone who
            the Contractor has introduced (the Introduced Contractor) becomes
            a Branch Manger, the Contractor acknowledges that from the date
            which either the Contractor becomes a Branch Manager or the
            Introduced Contractor becomes a Branch Manager, the Contractor
            will no longer be entitled to any commission or payments hereunder
            in respect of either itself or such Introduced Contractor.
          </li>
        </ol>
      </li>

      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        FEES
        <p>
          The Contractor agrees to pay the Corporation the sum specified in
          Schedule “A” hereto as Transaction Fee. The transaction is defined
          as services provided to Buyers and Sellers separately. The sale side
          and listing side are 2 transactions.
        </p>
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            Commercial & Residential Leases would be termed as an independent
            transaction and charges listed in the package below apply.
          </li>
          <li>
            The Contractor acknowledges that in no event shall any portion of
            any fee paid to the corporation according to this Section 6 to be
            refundable, even if this agreement is terminated before the end of
            the period to which any such payment relates.
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        EXPENSES PAYABLE BY CONTRACTOR
        <p>
          ln, the event the conduct of the Contractor requires the Corporation
          to participate in a complaint procedure at the Real Estate Council
          of Ontario, or any real estate board or in litigation proceedings or
          results in an errors or omissions insurance claim or arbitration,
          the Contractor will reimburse the Corporation for all expenses it
          incurs in its absolute and sole discretion.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        NO AUTHORITY TO BIND CORPORATION
        <p>
          The Contractor shall have no authority to bind the Corporation in
          any manner for anything other unless expressly authorized to do so
          in writing. Under no circumstances shall the Corporation be
          responsible for expenses incurred by the Contractor in the
          performance of his or her duties.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        DISPUTE TRANSACTION
        <p>
          ln the event that a transaction should involve the Corporation in a
          possible legal action or dispute, or if the commission payable
          concerning a transaction appears in the opinion of the Corporation
          to be in jeopardy, the Corporation reserves the right to withhold
          payment of the commission payable in respect of such transaction
          until the dispute is finally resolved. The Corporation reserves the
          right to perfect a settlement or compromise of any dispute in its
          sole discretion or to refer such dispute to arbitration according to
          Section 30 hereof. All monies payable in respect of any such
          settlement shall constitute a first charge against the commission
          receivable by the Contractor on such disputed transaction and the
          remainder of the commission, if any, shall be paid in accordance
          with the provisions hereof.
        </p>
      </li>

      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        TERM AND TERMINATION
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            The term of this Agreement shall commence on the date specified in
            Schedule "A" hereto and shall continue until terminated as
            hereinafter provided.
          </li>
          <li>
            The Contractor may terminate this Agreement at any time on thirty
            (30) days written notice by personally / Electronically delivering
            to the Broker of Record or any branch manager of the Corporation
            written notice of his or her intention so to do and receiving a
            receipt acknowledging such delivery. The Contractor agrees to
            return all contact information concerning the then current leads
            and referrals supplied to him/her by the Corporation immediately
            upon Termination of this Agreement. These obligations of the
            Contractor will survive the termination of this Agreement.
          </li>
          <li>
            The corporation may terminate this Agreement at any time without
            any notice if the Corporation is dissatisfied with the
            Contractor’s performance of his/her obligations hereunder.
            Otherwise, the Corporation may terminate this Agreement only by
            giving written notice to the Contractor, which notice is to be
            given at fourteen (14) days before any anniversary date of the
            commencement of the term of this Agreement. Any such termination
            by the written notice will be effective on the anniversary date
            next following the date on which the notice was given.
          </li>
          <li>
            The corporation may terminate this agreement at any time without
            notice if: (i) the Corporation is dissatisfied with the
            Contractor’s performance in its sole and absolute discretion; or
            (ii) the Contractor becomes a Branch Manager.
          </li>
          <li>
            The corporation and contractor may at any time terminate this
            agreement by mutual agreement. At the time of termination whether
            mutual or otherwise, Part (a) of Schedule A will cease to be
            applicable and will be changed to Package B of Schedule A, if it
            is not already applicable.
          </li>
          <li>
            Unless terminated as hereinbefore provided, this Agreement shall
            be automatically renewed on each anniversary date of its
            commencement for a term of one year, terminating on the next such
            anniversary date.
          </li>
          <li>
            Until the effective date of termination, all fees and expenses
            payable by the Contractor according to this Agreement shall remain
            payable notwithstanding the delivery of any notice of termination.
          </li>
          <li>
            The Contractor acknowledges and recognizes the Corporation's
            interest and exclusive right to its present and future real estate
            system, methods of operating and its distinguishing
            characteristics including, without limiting the generality of the
            foregoing, all trademarks, copyrights, and advertising copy now or
            hereafter displayed, used, or becoming a part of the Corporation's
            business. The Contractor agrees not to infringe upon, use or
            imitate said system or any of such distinguishing characteristics
            after the termination of this Agreement. The Contractor further
            agrees upon the termination of this Agreement to deliver to the
            Corporation all material in Contractor's possession containing any
            such distinguishing characteristics.{" "}
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        WAIVER OF DEFAULT
        <p>
          The Corporation or the Contractor may, in its, his or her sole
          discretion, waive any default of the other of them hereunder but no
          such waiver shall extend to or be taken in any manner whatsoever to
          affect any subsequent default or the rights resulting therefrom but
          rather shall apply only to the particular default waived, without
          restricting the generality of the foregoing, it is agreed that no
          series of waivers by the Corporation or the Contractor of any series
          of defaults relating to the same obligation shall constitute a
          waiver of any subsequent default of the same nature.{" "}
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        CORPORATION PROPERTY
        <p>
          Upon any termination of this Agreement, the Contractor will return
          to the Corporation all keys, listings, listing books, blueprints,
          signs, plans, maps, supplies sales literature and any other property
          received at any time by the Contractor from the Corporation. The
          Contractor agrees that if he or she fails to return any such items,
          the Contractor will pay forthwith to the Corporation the value to
          the Corporation of such items as are not returned to the
          Corporation.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        REPRESENTATION AGREEMENTS AND CUSTOMER SERVICE AGREEMENTS
        <p>
          All representation agreements and customer service agreements are
          the property of the Brokerage according to the Act during the term
          of this Agreement. Upon termination of this Agreement, the Brokerage
          agrees to terminate any representation agreements or customer
          service agreements procured by the Contractor if the seller or buyer
          under the agreement approves.{" "}
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        ADVERTISING
        <p>
          Any fines related to incorrect advertising will be paid by the
          Contractor at their own expense.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        LITIGATION, DISPUTE, AND ARBITRATION
        <p>
          The Contractor and the Corporation agree that if any commission
          dispute should arise between the Corporation and the Contractor or
          between the Contractor and any other person retained by the
          Corporation, such dispute will be referred to arbitration for
          determination in the following manner:
        </p>
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            If the dispute is between the Contractor and any other person
            retained by the Corporation, the Contractor will report the matter
            in dispute in writing to the broker or manager of the Contractor's
            branch and will give such broker or manager his or her full
            co-operation in the broker's or manager's efforts to settle the
            dispute in as simple and as amicable a manner as possible.
          </li>
          <li>
            If after all reasonable efforts the broker or manager should be
            unable to settle the dispute (in the case of a dispute between the
            Contractor and any other persons retained by the Corporation), or
            if the dispute is between the Corporation and the Contractor, the
            dispute will be referred to an arbitration committee. Such
            arbitration committee will consist of three members. one
            designated by the Contractor, one by the Corporation, or the other
            person. as the case may be, and the third by agreement of such
            first two members. If a mutual agreement is not reached regarding
            the third member then the corporation will designate a member of
            its management team as a third member of the committee. Each party
            to the dispute shall state his or her case in person but
            otherwise, the arbitration proceedings shall be governed by the
            provisions of the Arbitration Act of Ontario so far as applicable.
            The decision of the arbitration committee shall be final and
            binding on all parties concerned.
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        DEPOSITS
        <p>
          AII monies, documents, or property received by the Contractor in
          connection with any transaction shall be delivered to the Brokerage
          immediately. All cheques -must be made payable to the Brokerage in
          accordance with the Act.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        INDEMNITY
        <ol type="a" style={{ fontWeight: 400 }}>
          <li>
            The Contractor hereby indemnifies the Brokerage against all
            liability, loss, damages, costs, and expenses sustained, suffered,
            or incurred by the Brokerage as a result of any breach by the
            Contractor of the Contractor's obligations under this Agreement or
            as a result of any claim by a third party arising out of the
            Contractor's real estate business activities. This indemnity shall
            survive the termination of the Agreement.
          </li>
          <li>
            WITHHOLDING: The Contractor agrees that the Corporation may
            withhold such amounts payable to the Contractor by the Corporation
            which the Corporation determines, in its discretion, it may be
            found to be liable to pay because of any anticipated or actual
            loss, cost, damage, reimbursement expense, penalty or fine to or
            of or in connection with the Corporation.
          </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        SURVIVAL OF SECTIONS
        <p>
          Section 7, 12, 23, 16, 19 hereof and this Section 18 shall survive
          any termination of this agreement and remain in force indefinitely
          thereafter and shall ensure to the benefit of and be binding upon
          the parties hereto and their respective heirs, executors,
          administrator, successors, and assignors.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        ENTIRE AGREEMENT
        <p>
          This Agreement constitutes the entire agreement between the
          Corporation and the Contractor relative to the retention of the
          services of the Contractor by the Corporation and supersedes all
          understandings and prior agreements in that regard. It may not be
          changed orally but only by an agreement in writing.{" "}
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        INDEBTEDNESS
        <p>
          If and whenever according to the terms of this Agreement or
          otherwise the Contractor becomes liable to pay the Corporation any
          monies, then such monies may be deducted by the Corporation from any
          monies that may be due and owing to the Contractor. The Contractor
          hereby undertakes as follows:{" "}
        </p>
        <ol type="1" style={{ fontWeight: 400 }}>
          <li>
            In the event, any transaction has been canceled before the
            completion of the transaction and advanced commission are owing to
            a builder. Vendor or co-operation Brokerage, the Contractor shall
            immediately upon demand pay to the Brokerage, without any
            deductions, the full amount of any commissions paid in advance to
            the Contactor;
          </li>
          <li>
            In the event, the Agreement of Purchase and Sale for any
            transaction is canceled, terminated, or rescinded for any reason
            whatsoever before the completion of the transaction and advanced
            commissions are owing to a builder. Vendor or co-operation
            Brokerage, the Contractor shall immediately pay to the Brokerage,
            without any deductions, the full amounts of any commissions paid
            in advance to the Contractor;{" "}
          </li>
          <li>
            In the event the undersigned terminates employment with the
            Brokerage, the undersigned shall immediately pay to the Brokerage,
            without any deductions, the full amounts of any commissions paid
            in advance to the Contractor;
          </li>
        </ol>
        <p>
          In the event, the Contractor is unable to fulfill the above
          undertaking within seven [7] days of receiving notice from the
          corporation, the undersigned hereby authorizes the Brokerage to
          secure their interest as the Brokerage may require, including but
          not limited to one or more of the following;
        </p>
        <ol type="1" style={{ fontWeight: 400 }}>
          <li>
            Securing interest on real property owned by the undersigned in the
            form of registered Charge.
          </li>
          <li>
            Forfeiting such outstanding commission payments(s) as the
            Corporation may deem necessary in its discretion to cover any
            owing monies.{" "}
          </li>
          <li>Securing interest under the Personal Property Security Act;</li>
          <li>Securing interest in the form of a Promissory Note. </li>
        </ol>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        REMEDIES CUMULATIVE
        <p>
          No remedy herein conferred upon or reserved to the Contractor or the
          Corporation shall exclude any other remedy herein or existing at law
          or in equity or by statute, but each shall be cumulative and in
          addition to every other remedy given herein or now or hereafter
          existing.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        PARTIALLY INVALIDITY
        <p>
          If any provision of this Agreement or the application thereof to any
          person or circumstance shall, to any extent, be invalid, or
          unenforceable, the remainder of this Agreement, or the application
          of such provision or part thereof to persons or circumstances other
          than those as to which it is held invalid or unenforceable, shall
          not be affected thereby and each provision of this Agreement shall
          be valid and enforced to the fullest extent permitted by law and be
          independent of every other provision of this Agreement.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        PAYMENTS AND COMMUNICATIONS
        <p>
          All payments and communication which may be or are required to be
          given by the Contractor or the Corporation to the other of them,
          shall (in the absence of any specific provision to the contrary) be
          in writing and delivered to the Contractor or the Corporation at the
          principal address of the Corporation (provided, in the case of the
          Contractor, the Corporation may instead if it deems it appropriate
          deliver or mail the same by prepaid registered mail to the last home
          address of such Contractor appearing in the records of the
          Corporation). Any payment or communication so delivered shall be
          conclusively deemed to have been received at the time of delivery.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        INTRODUCTORY WORDS
        <p>
          The underlined words commencing the several Sections and Subsections
          of this Agreement are inserted for reference only and are not to be
          used as an aid to the interpretation of this Agreement.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        ASSIGNMENT RIGHTS
        <p>
          This Agreement is personal to the Contractor and no rights or
          obligations of the Contractor hereunder shall be assignable. The
          Corporation may assign its rights and obligations hereunder to any
          successor to the business of the Corporation or any part thereof,
          whereupon the Corporation shall be relieved of all obligations
          hereunder arising after the date of such assignment.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        PERSONAL INFORMATION CONSENT AND PRIVACY POLICY
        <p>
          The Contractor consents to the collection, use, and disclosure of
          personal information about the Contractor from time to time obtained
          by the Corporation; (a)for all purposes the Corporation considers
          necessary or desirable to implement the terms of the agreement or
          the processing of trades or compiling statistical reports including
          trades in which the Contractor is involved; (b) to business and
          associations that assist the Corporation in providing services to
          IQI clients and customers or the Contractor; (c) when permitted or
          required by law, such as in response to a subpoena or other legal
          process;(d) to a purchaser of all or part of the Corporation‘s
          business; and (e) to meet legal obligations such as franchise, tax,
          and regulatory reporting. The Corporation projects personal
          information with safeguards appropriate to the sensitivity of the
          information, The Contractor may submit a written request to access
          his or her personal information retained by the Corporation. (f) The
          contractor agrees that the transactions detail to calculate passive
          income will be shared with the referring contractors if required.
        </p>
      </li>
      <li style={{ fontWeight: "bold", paddingTop: 10 }}>
        INDEPENDENT LEGAL ADVICE
        <p>
          The Contractor acknowledges that the Corporation has encouraged the
          Contractor to seek independent legal advice before signing this
          Agreement and that the Contractor has had an adequate opportunity to
          secure such advice.
        </p>
      </li>
    </ol>
    <p style={{ paddingTop: 10, paddingBottom: 30 }}>
      IN WITNESS WHEREOF the parties hereto have signed and sealed this
      Agreement as of the date appearing on the first page of this Agreement.{" "}
    </p>
  </>
);

//Migrate to use /api/agreements/version api for easy modification using NDA Feature
// export const renderJapanContent = (param) => {
//   const bankAccountType = param.bank_account_type === "current" ? "当座預金" : "普通預金";
//   const today = Moment().format("YYYY年MM月DD日");
//   const threeYearsLater = Moment().add(3, "years").format("YYYY年MM月DD日");

//   return(
//     <>
//       <div className={"d-flex justify-content-center mb-3"}>
//         <h4 style={{ fontWeight: "bold" }}>業務委託契約書</h4>
//       </div>
//       <p>
//         IQI Japan株式会社（以下「甲」とする）」と、
//         <span className="fw-500 mx-2">{param.full_name || ""}</span>
//         （以下「乙」とする）とは、以下の通り業務委託契約書（以下「本契約」とする）を締結する. </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第1条（業務目的） </p>
//       <p>甲及び乙は、互いの業務・利益の追求において協力する目的のもと、甲は、乙に以下の業務(以下、「委託業務」という)を委託し、乙は、これを受託する。</p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２条（業務内容） </p>
//       <p>委託業務は、以下の通りとする。</p>
//       <ol>
//           <li>不動産売買及び賃貸の仲介業務及びそれに係る一切の業務。</li>
//           <li>建築工事及び内装工事等の斡旋及びそれに係る一切の業務。</li>
//           <li>設備機器及び不動産に関わるシステム並びにアプリ等の斡旋及びそれに係る一切の業務。</li>
//           <li>保険の事業者の紹介及び保険の斡旋または保険募集業務。</li>
//           <li>不動産に関わる法人の売買、紹介、斡旋業務。</li>
//           <li>その他甲が提供する商品、サービスの斡旋または紹介業務。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第3条（業務委託料及び経費）</p>
//       <ol>
//         <li>甲は、乙に対し、委託業務にかかる業務委託料について、委託業務にかかる顧客からの報酬額（以下「利益額」という）を100%として、乙に対して別表報酬表のとおり支払うものとする。振込による支払の場合、振込手数料は乙が負担するものとする。なお、委託業務に係る実質経費が発生した場合は、別紙の負担割合の通り負担する。</li>
//         <li>本条の業務委託料の支払は、顧客からの仲介手数料及び報酬の支払があった後、顧客との間での契約破棄、契約解除等により顧客に返金された場合は無効となり、乙は甲から受領した業務委託料を返金する。その返金の際の振込手数料は甲の負担とする。</li>
//         <li>乙は、委託業務において必要経費が発生するときは、事前に甲の承認を得るものとする。</li>
//         <li>乙が免税事業者の場合、日本国のインボイス制度とその経過措置に則り、甲が負担する消費税額相当分を業務委託料から控除する。</li>
//         <li>乙が宅地建物取引士である場合は、甲は、乙を甲の専任取引士として雇用契約等による指揮監督下に置き、行政庁に登録を行い、乙に従業者証を発行する。この場合、甲が乙に支払う業務委託料は賃金とする。</li>
//         <li>甲は乙に対し、別表報酬表に記載していない作業時間、作業内容、作業工程その他取り決めのない対価に関する乙からの請求については、一切支払わない。</li>
//         <li>甲は、社会的経済的情勢に合わせて業務委託料の変更を行う事ができる。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第４条（業務委託料等支払方法） </p>
//       <p>前条の業務委託料及び甲が承認した経費の支払い日は、毎月１５日及び末日（休日の時はその翌営業日）の2回とし、顧客からの入金があった日の直近の支払い日を締め日とし、翌支払日に乙の指定する口座に支払う。その際の振込手数料は乙の負担とする。 </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第５条（委託業務遂行の方法） </p>
//       <ol>
//         <li>乙は、委託業務の遂行にあたり、甲の社会的評価や信用を重視し、これを毀損しないものとする。甲は、乙に対し、委託業務の遂行につき、甲の社名及び指定された肩書を使用して業務に従事することを許諾する。</li>
//         <li>乙は、委託業務に対して自らの責任と負担において、善良な管理者の注意をもってこれを遂行する。</li>
//         <li>乙は、委託業務にかかる取引に係る顧客、物件、所有者、関係者等の情報を、取引の都度、書面又は電子メールにより遅滞なく甲に報告しなければならない。</li>
//         <li>甲は、委託業務並びに取引先並びに取引内容について、不利益や社会的信用棄損を被ると判断した場合は、委託業務及び取引並びに物件の取り扱い等を停止する事ができる。</li>
//         <li>乙は、甲が委託業務遂行上の言動について関係者及び第三者から苦情を受けた場合は、甲の請求により説明の義務を負う。</li>
//         <li>乙は、作成資料、契約書、重要事項説明書等の作成物について、発行前に甲の確認及び承諾を得る。</li>
//         <li>乙は、甲が委託業務に関わる案件は甲を通じて取引するものとし、競合他社との取引及び競合する業務を行わない。</li>
//         <li>乙が甲名義の名刺を提供されている場合、甲の承諾なく名刺の編集・複製・印刷を行ってはならない。甲の承諾を得て印刷する場合、甲は、印刷代実費を乙に請求しまたは業務委託料から相殺することができる。</li>
//         <li>甲及び乙は、互いの業務の遂行に必要な情報並びに資料等を相手方に提供する事を了承する。</li>
//         <li>乙は、甲が提供する事務所、作業場等の利用を一部有料で使用する事ができる。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第6条（契約期間）</p>
//       <p>本契約の契約期間は、{today}から{threeYearsLater}までの３年間とし、契約期間満了の1か月前までに双方のいずれかから本契約終了の意思表示が無い場合はさらに同条件で３年間自動更新されるものとし、以後も同様とする。</p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第７条（即時解除）</p>
//       <p>甲は、乙が下記の各号に当てはまる行為をしたと判断した場合は、本契約を即時に解除できるものとする。その場合、甲は、乙に対し、甲の既発生の支払債務以外の金銭を一切支払わない。 </p>
//       <ol>
//         <li>甲の関係者並びに他の甲の業務受託者及び取引先等に迷惑、不利益を与えた場合。</li>
//         <li>他者の業務の遂行を乱す行為、言動があった場合。</li>
//         <li>他社を威圧、困惑させるような言動があった場合。</li>
//         <li>その他社会的秩序を乱すような言動があった場合。</li>
//         <li>連鎖販売取引に該当する一切の行為（紹介者からの金銭の授受）。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第８条（契約解除）</p>
//       <p>甲及び乙は、以下の場合には、本契約を解除することができる。 </p>
//       <ol>
//         <li>相手方の責めに帰すべき事由により損害を被ったとき。</li>
//         <li>相手方の信用を著しく毀損したときや、相手方が本契約に違反したとき。</li>
//         <li>相手方が差押、仮差押えを受け、手形等の不渡り処分を受け、支払い停止の状態に陥り、解散もしくは清算し、または破産・特別清算・民事再生・会社更生等の申し立てを行い若しくは受けたとき。</li>
//         <li>前各号の場合に準じて取引上の信用が担保できないと判断できるとき。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第９条（損害賠償）</p>
//       <ol>
//         <li>乙が委託業務の遂行を懈怠し又はその履行が不完全であったため、甲が損害を被った場合には、乙は甲にその損害を賠償する。  </li>
//         <li>乙が本業務の遂行を懈怠し、またはその履行が不完全であるなどその責に帰すべき事由により第三者が被害を被った場合には、乙は、第三者に対してその損害の賠償をするものとし、甲に迷惑を及ぼさないものとする。また、乙が宅地建物取引士として重要事項説明書の作成と説明業務を請け負った場合においても同様とする。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１０条（契約終了後の規則）</p>
//       <ol>
//         <li>乙は、本契約の終了後3年間は、甲の書面による許可なく甲の顧客並びに関係者との間において営業活動または取引をしてはならない。 </li>
//         <li>乙は、本契約が終了した場合は、委託業務上入手した資料、甲より提供された物品及び資料は即時返却するものとし、第三者に供与及び開示してはならない。 </li>
//         <li>本契約が期間満了又は解除等の理由により終了した場合において、甲が要請した時は、乙は、甲及び甲が指定する本業務の後任者に対して、自らが実施していた本業務を引き継がせるものとする。 </li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１１条（不動産アプリ“Atlas”の利用について） </p>
//       <p>甲は、乙に独自の不動産関連アプリを提供することができ、その場合、乙は同アプリに関して以下の内容に同意した上で、これを利用できることとする。　 甲は乙に対し、甲が使用権限を有するアプリにしてIQI HOLDINGS SDN BHD: 28Floor,TowerD,Millerz,Square,357,Jln Klang Lama,58000 Kuala Lumpur,Wilayah Persekutuan Kuala Lumpur Malaysia、(以下「所有者」という)が所有及び運営する[ATLAS]（以下「アプリ」といいます。）の利用を提供する。 </p>
//       <p>アプリの利用は、ウェブサイトまたは適切なプラットフォームからダウンロードされることによって行われる。 </p>
//       <ol>
//         <li>乙は、アプリの利用中に適用されるすべての法律および規制に従わなければならない。</li>
//         <li>甲は、合理的な努力をもってアプリの提供および所有者との調整並びに維持を行うものとするが、乙に対してアプリの中断、安全、およびエラーなしの利用を保証するものではない。</li>
//         <li>甲は、アプリの機能やサービスを改善し、適切なサポートを提供するために、乙のアクセスや活動をモニタリングすることがある。</li>
//         <li>アプリおよび関連する知的財産権は、所有者に帰属する。</li>
//         <li>乙は、甲の事前の書面による許可なしに、アプリの知的財産権を使用、複製、または変更してはならない。</li>
//         <li>甲は、アプリの利用によって生じた直接または間接的な損害について一切の責任を負わない。</li>
//         <li>乙は、自己の責任においてアプリを利用し、甲に対する損害の請求から保護するものとする。</li>
//         <li>乙は、甲の書面による事前の承認なしに、アプリの利用を第三者に移転することはできない。</li>
//         <li>甲は、乙のアプリ利用の権利または利用の方法を変更する権利を有する。</li>
//         <li>甲による変更後の内容は甲のウェブサイトに掲載されるか、乙に通知された時点で効力を生じる。</li>
//         <li>アプリに対して、甲並びに所有者の予期できない不備による損害が生じた場合でも、甲及び所有者は何ら賠償請求を負わない。ただし、甲並びに所有者の意図的な操作により乙が損害を受けた場合以外はこの限りではない。なお、甲は、アプリに不備が生じた場合はすみやかに復旧し、乙に提供しなければならない。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１２条（機密保持・個人情報の保護）  </p>
//       <ol>
//         <li>甲及び乙は、業務上に知り得た機密事項及び相手方又は顧客の個人情報並びに関係者の情報を、業務の遂行以外の目的で使用し又は第三者に漏洩するなどしてはならない。</li>
//         <li>甲及び乙は、個人情報の取り扱いについて、個人情報保護に関する法律および個人情報保護に関するガイドライン等、法令及びガイドラインを遵守する事を確約する。</li>
//         <li>本条は、相手方の承諾を受けた場合を除き、本契約終了後も有効に存続する。</li>
//         <li>甲は、乙の個人情報を収集し、使用する場合、プライバシーポリシーに従うものとする。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１３条（反社会的勢力との取引排除）</p>
//       <ol>
//         <li>甲及び乙は、次に定める事項を表明し、保証する。</li>
//         <ol>
//           <li>自己及び自己の役員・株主（以下「関係者」という）が、暴力団、暴力団関係企業もしくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」という）でないこと。</li>
//           <li>自己及び自己の関係者が、反社会的勢力を利用しないこと。</li>
//           <li>自己及び自己の関係者が、反社会的勢力に資金等の提供、便宜の供給等、反社会的勢力の維持運営に協力又は関与しないこと。</li>
//           <li>自己及び自己の関係者が、反社会的勢力と関係を有しないこと。</li>
//           <li>自己が自ら又は第三者を利用して、相手方に対し、暴力的行為、詐術、脅迫的言辞を用いず、相手方の名誉や信用を毀損せず、また、相手方の業務を妨害しないこと。</li>
//         </ol>
//         <li>甲及び乙は、相手方が前項に違反したと認める場合には、通知、催告その他の手続を要しないで、直ちに本契約の全部又は一部を解除することができる。この場合、相手方は他方当事者に発生した全ての損害を直ちに賠償するものとする。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１４条（代理行為の禁止）</p>
//       <ol>
//         <li>乙は、いかなる場合においても甲の代理ではなく一事業者として委託業務を行う。</li>
//         <li>乙は、甲に対する乙の権利、義務を第三者に代理させまたは委託して委託業務を行ってはならない。 </li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１５条（宅地建物取引業の重複従事の禁止）</p>
//       <p>乙は、他社の宅地建物取引業者に正規、非正規の雇用を問わず従事してはならない。ただし、乙自らが宅地建物取引業者若しくは宅地建物取引業を経営する者又は宅地建物取引業者たる会社の過半数株式を保有する者である場合はこの限りではない。　</p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１６条（取引持ち出し行為の禁止）</p>
//       <ol>
//         <li>乙は、委託業務において得た情報、顧客、関係者等を経由し、甲以外の宅地建物取引業者との間に委託業務と同種の取引（取引持ち出し行為）をしてはならない。 </li>
//         <li>前項の事実が発覚した場合は、違約として乙は甲に前項の取引により得た売上額相当分を支払わなければならない。 </li>
//         <li>前項の場合、甲は無条件にて本契約を解除することができる。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１７条（その他の事項）</p>
//       <ol>
//         <li>甲及び乙は、事業の推進、業務の履行、金銭の授受などの行為について、関連する法令を遵守するものとする。</li>
//         <li>天災地変その他の不可抗力により、本契約に基づく本業務の履行が不能又は遅延した場合は、甲は、その責を負わない。</li>
//         <li>委託業務の履行中に乙に人心災害が生じた場合には、明らかに甲の過失と認められる原因に起因するもの以外は、甲は責を負わない。</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１８条（知的財産権の帰属）</p>
//       <p>委託業務の遂行のために甲から乙に提供されたすべての資料、情報、システム、考案、意匠、書類、データ、著作物、ビジネスモデル、動画、音楽等及び、発明、特許、実用新案、意匠、商標等の登録の権利は甲に帰属し、乙はその権利を行使できないこととする。ただし、乙自らが甲の助言、情報および資料の提供もなく考案し、作成したものの権利については、甲乙協議により定める。 </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第１９条（宅地建物取引士の資格）</p>
//       <p>乙が、宅地建物取引士の資格所有者であり、甲が乙を専従者として登録する場合（第１６条但し書きに該当する者は除外される）、その登録料として甲は乙に月額３万円を支払う。ただし、本契約が終了した場合は即時にその登録料の支払いも終了する。 </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２０条（権利の相続）</p>
//       <p>乙が死亡した場合、甲は、その相続人と協議して、本契約の承継を決することができる</p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２１条（研修等受講の義務）</p>
//       <p>乙は、甲が指定する研修（Eラーニング含む）を受講しなければならない。</p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２２条（契約に際する情報の提供） </p>
//       <p>乙は、本契約に際して以下の情報を甲に提供し、甲はこの情報を厳重に保管しなければならない。また、乙は以下に変更があった場合はすみやかに甲に報告しなければならない。 </p>
//       <ol>
//         <li>公的身分証明</li>
//         <li>住所</li>
//         <li>電話番号</li>
//         <li>緊急連絡先</li>
//         <li>報酬振込先口座番号</li>
//         <li>その他甲が必要として乙に請求するもの</li>
//       </ol>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２３条（合意管轄）</p>
//       <p>甲及び乙は、本契約に関連して生じた一切の紛争については、東京地方(簡易)裁判所を専属的な合意管轄裁判所とする。 </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>第２４条（信義誠実） </p>
//       <p>甲及び乙は、本契約に定めのない事項又は本契約に関する疑義は、信義に従い誠実に協議の上その解決に努める。</p>

//       <p style={{ paddingTop: 20 }}>以上、本契約の成立を証するため、本書2通を作成し、署名捺印の上、各自１通を保有する。</p>

//       <h5 style={{ fontWeight: "bold" }}>別表　報酬表 </h5>

//       <p style={{ paddingTop: 20 }}>◆セールスフィー：エージェントによる案件獲得に対する報酬エージェントは自らが獲得した取引の成約に対する利益額に対し、６０％の報酬を得る。 ただし、契約書の作成及び重要事項説明書の作成、契約の為の調査について甲が担当する場合は、５％の業務手数料を控除し、５５％とする。 </p>
//       <p>なお、甲が担当する契約書及び重要事項説明書の作成、契約の為の調査に係る費用（交通費、資料取得費等）の合計が２万円以上になった場合は、その費用を実費として利益額から差し引く。 </p>

//       <p style={{ paddingTop: 20, textAlign: "center"}}>
//         {`セールスフィー：自らが獲得した取引で、自らが契約書及び重要事項を作成した場合　６０％`}<br />
//         {` :自らが獲得した取引で、甲が契約書及び重要事項を作成した場合　　５５％`}<br />
//         {`※２万円以上の実費経費がかかった場合は利益額から差し引く。`}
//       </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>注：</p>
//       <ul style={{ lineStyleType: "circle" }}>
//         <li>複数エージェントが絡む場合は、エージェント同士が按分を決める。 </li>
//         <li>一部の作業について、他のエージェントに業務を依頼した場合は、自らの報酬額からそのエージェントに目安となる報酬割合を基に交渉によって配分する。（サポートフィーの配分） </li>
//         <li>エージェント自らの購入については対象外とする。 </li>
//         <li>集計は、入金日を基準とし、１５日毎（月２回の支払い日）とする。</li>
//       </ul>

//       <p style={{ paddingTop: 20 }}>◆サポートフィー：他のエージェントの案件のサポートに対する報酬エージェントは、他のエージェントのサポート業務に対する報酬を得る権利を持つ。その報酬はセールスフィーを取得するエージェント（メインエージェント）との交渉により、メインエージェントから配分される。その目安は以下の通りとする。 </p>
      
//       <p style={{ paddingTop: 20 }}>
//         {`サポートフィー：例　役所調査サポート・・・報酬額の５％+交通費 `}<br />
//         {`　　　　　　　　　　案　　　　　　内・・・報酬額の２％+交通費 `}<br />
//         {`　　　　　　　　　　写　真　　撮　影・・・報酬額の１％+交通費 `}
//       </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>注：</p>
//       <ul style={{ lineStyleType: "circle" }}>
//         <li>集計は、入金日を基準とし、１５日毎とする。  </li>
//       </ul>

//       <p style={{ paddingTop: 20 }}>◆リファーラルフィー：新規のエージェントを紹介し、そのエージェントがセールスフィーを得た時に獲得できる報酬</p>
//       <p style={{ paddingTop: 20 }}>エージェントは新規のエージェントを紹介し、そのエージェントが取引を成約させることでセールスフィーを獲得した場合、その新規エージェントの売上げた利益額に対して２％の報酬を得る。 </p>

//       <p style={{ paddingTop: 20, textAlign: "center"}}>
//         {`リファーラルフィー：自らが新規で紹介したエージェントが売り上げた利益額に対して　２％`}<br />
//         {'※この紹介エージェントが売り上げた利益額がある度に獲得できる。 '}<br />
//         {`この権利は本契約が続く限り永久に保有する。`}
//       </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>注：</p>
//       <ul style={{ lineStyleType: "circle" }}>
//         <li>集計は、入金日を基準とし、１５日毎とする。</li>
//       </ul>
      
//       <p style={{ paddingTop: 20 }}>◆タイトルフィー：自らのグループにより、下記の通りの規定を満たす事で得られる報酬エージェントは、自らの紹介によって始まるグループが下記の条件を満たした時に既定の報酬を得る。 </p>

//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>※用語の定義 </p>
//       <ul>
//         <li>「グループ」とは、自らから始まる紹介により組成した組織の事を言う。（自らを含む。）  </li>
//         <li>「系列」とは、自らが紹介した紹介者のグループの事を言う。  </li>
//         <li>「10段」とは一つの系列に対して１０人のSMまでの事を言う。  </li>
//         <li>「売上」とは、１か月間に入金された利益額の事を言う。 </li>
//         <li>「１年間」とは、６月１日～５月３１日までの事を言う。 </li>
//       </ul>

//       <p style={{ paddingTop: 20, textDecoration: "underline" }}>タイトルフィー</p>
//       <p>セールスマネージャー (SM)</p>
//       <p>自ら始まるグループの売上の3%</p>
//       <p>グループ内のSMの売上の1%（1系列10段まで）</p>
//       <p>条件:</p>
//       <ol>
//         <li>グループ人数が6人以上。</li>
//         <li>グループ全体の売上が1,200万円を達成。</li>
//         <li>上記の条件を1年間のうち3ヶ月間達成する。</li>
//         <li>自らのグループの売り上げの75%以上がひとつの系列から発生する場合、その他の系列で300万円以上の売上を達成する。</li>
//         <li>タイトル獲得後6か月間において1および2の条件を一度も達成できなかった場合は降格となる。</li>
//       </ol>

//       <p style={{ paddingTop: 20, textDecoration: "underline" }}>タイトルフィー</p>
//       <p>ディレクターオブセールス (DOS)</p>
//       <p>自らのグループ内のSM昇格者を除く売上の4%</p>
//       <p>グループ内のSMの売上の1%（1系列10段まで）</p>
//       <p>条件:</p>
//       <ol>
//         <li>グループ人数が15名以上。</li>
//         <li>グループからSMが2つの系列からそれぞれ1名（合計2名）輩出されること。</li>
//         <li>グループ内のSMを除く売上（直近SM以下のグループは対象外）が300万円（入金）以上あること。</li>
//         <li>タイトルフィーは自らのグループ内のSMを除いた売上（直近SM以下のグループは対象外）の4%となる。</li>
//         <li>グループ内のSMが降格し、それにより自らもDOS条件を達成されていない場合は降格となる。</li>
//       </ol>

//       <p style={{ paddingTop: 20, textDecoration: "underline" }}>タイトルフィー</p>
//       <p>ヴァイスプレジデント (VP)</p>
//       <p>自らのグループ内のSM昇格者を除く売上の5%</p>
//       <p>グループ内のSMの売上の1%（1系列10段まで）</p>
//       <p>条件:</p>
//       <ol>
//         <li>グループ人数が60人以上。</li>
//         <li>グループからSMが4つの系列からそれぞれ1名（合計4名）排出されること。</li>
//         <li>グループ内のSMを除く売上（直近SM以下のグループは対象外）が300万円以上あること。</li>
//         <li>タイトルフィーは自らのグループ内のSMを除いた売上（直近SM以下のグループは対象外）の5%となる。</li>
//         <li>グループ内のSMが降格し、それにより自らも</li>
//       </ol>
//       <p style={{ fontWeight: "bold", paddingTop: 20 }}>タイトルフィー：</p>
//       <ul>
//         <li>セールスマネージャ―　　　　グループ売上の３％、グループ内のSMの売上の１％（１０段まで）   </li>
//       </ul>

//       <p style={{ paddingTop: 20 }}>
//         {`ディレクターオブセールス　　グループ売上（グループ内のSM及び直近のSM以下のグループを除`} <br/>
//         {`く）の４％、グループ内のSMの売上の１％（１０段まで）`} <br/>
//         {`ヴァイスプレジデント　　　　グループ売上（グループ内のSM及び直近のSM以下のグループを除`} <br/>
//         {`く）の５％、グループ内のSMの売上の１％（１０段まで）`} 
//       </p>

//       <p style={{ paddingTop: 20 }}>◆IQI　ボーナス：下記の条件を満たす資格取得者のみが得られる報酬。 </p>

//       <p style={{ paddingTop: 20 }}>報酬 </p>
//       <p>IQIボーナス　 </p>
//       <p>DOS以上を条件に、IQI Japan株式会社の年間利益額に対して１％を資格者で分配する。 </p>
      
//       <p style={{ paddingTop: 20 }}>資格取得条件 </p>
//       <ul>
//         <li>DOS以上 </li>
//         <li>グループ内のSMが２５人以上 </li>
//       </ul>
      
//       <p style={{ paddingTop: 20 }}>分配方法 </p>
//       <p>自らのグループの１年間の売上（SM及び直近SM以下のグループを除く）について300万円を1ポイントとし、資格取得者全員の合計ポイントに対して自身のポイント数の割合を取得権利とする。 </p>
      
//       <p style={{ paddingTop: 20 }}>IQI　ボーナス：IQI Japan株式会社の１年間の利益額の１％を資格取得者で分配  </p>

//       <p className="fw-500 fs-2 text-center my-3">タイトルフィーと条件</p>
//       <table className="at-japan-agreement__table" border="1">
//         <tr>
//           <th>氏名 </th> <td>{param.full_name}</td> <th>氏名（フリガナ） </th> <td colSpan={2}>{param.furigana}</td>
//         </tr>
//         <tr>
//           <th>電話番号  </th> <td>{param.mobile_contact_number}</td> <th>メールアドレス   </th> <td colSpan={2}>{param.email}</td>
//         </tr>
//         <tr>
//           <th>住所 </th> <td colSpan={4}>{param.current_address}</td>
//         </tr>
//         <tr style={{ color: "red" }}>
//           <th>緊急連絡先  </th> <td colSpan={4}><div className="d-flex justify-content-around"><span>{param.emergency_contact_number || '該当なし'}</span> <span>{param.emergency_person_name || '該当なし'}</span> <span>{param.emergency_contact_relationship || '該当なし'}</span></div></td>
//         </tr>
//         <tr>
//           <th>インボイス番号※   </th> <td>{param.income_tax_number}</td> <th>活動地域</th> <td colSpan={2}>{param.area_of_activity}</td>
//         </tr>
//         <tr>
//           <th rowSpan={4}>振込先口座 </th> <th>銀行名 </th> <th>支店名 </th> <th>種類 </th> <th>口座番号</th>
//         </tr>
//         <tr>
//           <td>{param.bank_name}</td> <td>{param.bank_branch}</td> <td>{bankAccountType}</td> <td>{param.bank_account_number}</td>
//         </tr>
//         <tr>
//           <th colSpan={4}>振込人名義</th> 
//         </tr>
//         <tr>
//           <td colSpan={4}>{param.bank_owner_name}</td> 
//         </tr>
//       </table>

//       <p style={{ paddingTop: 20 }}> ※インボイス番号は課税事業者のみ記載をお願いいたします。  </p>
//       <p className="mb-4">こちらに記載がない場合は免税事業者とみなしますので、取得した際は必ずお申し出くださいませ。 </p>
//     </>
//   )
// }

export const renderBilingualAgreement = (props) => (
  <>
    <p>モバイルAtlasへようこそ！ </p>
    <p>Welcome to our website! </p>
    <p style={{ paddingTop: 20 }}>
      このアプリケーションはIQI ATLASチームによって運営されています。
      当社のウェブサイトにアクセスまたは使用する前に、以下の利用規約をよくお読みください。
      本アプリケーションのいずれかの部分にアクセスまたは使用することにより、
      これらの利用規約に同意したものとみなされます。 
    </p>
    <p style={{ paddingTop: 20 }}>
      This website is operated by IQI ATLAS team. Please read the 
      following terms and conditions carefully before accessing 
      or using our website. By accessing or using any part of the 
      website, you agree to be bound by these terms and condition. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (2) ウェブサイトコンテンツ
    </p>
    <p>
      本ウェブサイトに提供される情報は、公開時に入手可能な情報に基づいており、
      予告なく変更される場合があります。当社は、これらの情報の正確性、完全性、
      信頼性について一切の保証をいたしません。物件や投資機会については、ご自
      身で独自の評価とデューデリジェンスを行う責任があります。 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (2) Website Content
    </p>
    <p>
      You acknowledge that the information provided on this website 
      is based on information made available to us at the time of 
      publication and may change without notice. We make no representations 
      or warranties as to the accuracy, completeness, or reliability 
      of such information. It is your responsibility to conduct your 
      own independent assessment and due diligence regarding any 
      property or investment opportunity.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (3) ユーザー行動 
    </p>
    <p>
      あなたは本ウェブサイトを違法または無許可の目的で使用しないことに同意します。
      あなたは本ウェブサイトに送信するすべてのコンテンツに対して責任を負います。
      これらの利用規約に違反するコンテンツを削除または編集する権利を留保します。
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (3) User Conduct
    </p>
    <p>
      You agree not to use the website for any illegal or unauthorized 
      purpose. You are responsible for all content you submit to this 
      website. We reserve the right to remove or edit any content that 
      violates these terms and conditions.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (4) 知的財産権 
    </p>
    <p>
      本ウェブサイト上のすべてのコンテンツ（テキスト、画像、ロゴなどを含む）
      は、著作権およびその他の知的財産法によって保護されています。 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (4) Intellectual Property
    </p>
    <p>
      All content on this website, including but not limited to texts, 
      images, and logos, is protected by copyright and other intellectual 
      property laws. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (5) 責任の制限 
    </p>
    <p>
      当社は、お客様のウェブサイトの使用に起因するいかなる損害についても責任を負いません。
      これには、直接的、間接的、特別、付随的、第三者または結果的な損害を含みますが、
      これらに限定されません。お客様がウェブサイトまたはそのコンテンツに依存して損害を被っ
      た場合、その責任は負いません。 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (5) Limitation of Liability
    </p>
    <p>
      We are not liable for any damages arising from your use of the website, 
      including but not limited to any harm, loss or damage, however caused, 
      arising out of your use of the website, including direct, indirect, 
      special, incidental, third party or consequential damages. We shall 
      not be responsible for any detrimental reliance that you may place 
      upon the website or its contents.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (6) 補償 
    </p>
    <p>
      あなたは、本ウェブサイトの使用またはこれらの利用規約の違反により発生するすべての請求、
      損害、負債または費用から当社を補償し、無害とすることに同意します。
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (6) Indemnification
    </p>
    <p>
      You agree to indemnify and hold us harmless from any claims, damages, 
      liabilities, or expenses arising out of your use of the website or your 
      violation of these Terms and Conditions.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (7) 利用規約の変更 
    </p>
    <p>
      当社は、これらの利用規約をいつでも変更する権利を留保します。通知は、ポップアップ、書面、
      または本ウェブサイト上でその旨を掲示することによって行われる場合があります。
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (7) Changes to Terms
    </p>
    <p>
      We reserve the right to modify these Terms and Conditions at any time. Notice 
      may be given to you in the form of a pop-up, writing or by posting such 
      notice on this website. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (8) 準拠法 
    </p>
    <p>
      これらの利用規約は、マレーシアの法律に準拠し、それに従って解釈されます。国際法の原則は、
      マレーシアの裁判所によって考慮される場合がありますが、その適用は裁判所の裁量と各事例の
      具体的な状況に依存します。 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (8) Governing Law
    </p>
    <p>
      These Terms and Conditions shall be governed by and construed in accordance 
      with the laws of Malaysia. While international law principles may be considered 
      by Malaysian courts, the application of such principles shall be subject to 
      the discretion of the courts and the specific circumstances of each case.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (9) 会社方針およびハンドブック 
    </p>
    <p>
      あなたは、当社の会社方針と手続きに従うことに同意します。これには、リアルエステート交渉者ハンド
      ブック、アンチマネーロンダリング対策、テロ資金供与対策、対象金融制裁に関する会社の方針および手
      続きハンドブック、並びに適宜発行される内部メモランダムに記載された利用規約が含まれます。これら
      の文書はウェブサイトでご覧いただけます。 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (9) Company Policies and Handbook
    </p>
    <p>
      You agree to comply with all our company policies and procedures including 
      but not limited to the terms and conditions as outlined in our Real Estate 
      Negotiators’ Handbook, Company's Policy & Procedure Handbook on Anti-Money 
      Laundering, Countering Financing of Terrorism, and Targeted Financial Sanctions, 
      and any internal memorandums that shall be issued from time to time. These 
      documents are available on the website.
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (10) プライバシーポリシー 
    </p>
    <p>
      あなたは、当社がデータ処理のためにあなたの個人情報および/またはウェブサイトに提出された情報
      を関連する第三者に開示することを許可します。この開示には、提出された情報をサービスプロバイダー、
      ビジネスパートナー、および規制当局と共有することが含まれますが、これに限定されません。データ処理
      に関する詳細は、こちらの
      <a href="https://www.iqiglobal.com/privacy-policy" target={"_blank"} 
        style={{color: "#2563eb"}}>プライバシーポリシー
      </a>をご参照ください。
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (10) Privacy Policy
    </p>
    <p>
      You authorize the Company to disclose your personal information and/or the 
      information you have submitted to the Website to relevant third parties 
      for the purpose of data processing. Such disclosure may include, but is not 
      limited to, sharing the information which you have submitted with service 
      providers, business partners, and regulatory authorities. For more details 
      on our data practices, please refer to our Privacy Policy 
      <a href="https://www.iqiglobal.com/privacy-policy" target={"_blank"} 
        style={{color: "#2563eb"}}> here
      </a>. 
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (11) 利用の終了 
    </p>
    <p>
      当社は、理由の有無にかかわらず、通知の有無にかかわらず、いつでもお客様のウェブサイトへのアク
      セスまたは使用を終了する権利を留保します。
    </p>
    <p style={{ fontWeight: "bold", paddingTop: 20 }}>
      (11) Termination
    </p>
    <p>
      We reserve the right to terminate your access to or use of the website at 
      any time, with or without cause, and with or without notice.
    </p>
    <p style={{ paddingTop: 20 }}>
      ご不明な点がございましたら、IQI ATLASチームまでお気軽にお問い合わせください。
      <spam style={{ fontWeight: "bold" }}>helpdesk@iqiglobal.com</spam>
      までご連絡ください。 
    </p>
    <p style={{ paddingTop: 20 }}>
      Should you have any questions, feel free to contact our ATLAS team 
      at <spam style={{ fontWeight: "bold" }}>helpdesk@iqiglobal.com</spam>.
    </p>
  </>
)