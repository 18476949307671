import React, { Component } from "react";
import _ from "lodash";

import StatusModal from "components/Modal/status";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",

      branch_options: [],
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    getBranchInfo = (country_id) =>
      Get(
        `/branches?[q][branch_selections_type_id_eq]=1&[q][branch_selections_country_id_eq]=${country_id}`,
        this.getBranchInfoSuccess,
        this.getBranchInfoError,
        this.load,
      );
    getBranchInfoSuccess = (payload) => 
      this.setState({ branch_options: payload });
    getBranchInfoError = (error) => this.requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            branch_options={this.state.branch_options}
            onLoadBranch={this.state.loading}
            getBranchInfo={this.getBranchInfo}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
