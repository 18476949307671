import React, { Component } from "react";
import _ from "lodash";

import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      branches: [],
      branchesByCountry: [],
    };

    load = (param) => this.setState({ loading: param });

    getBranch = () =>
      Get(
        `/branches?q[is_visible_in][]=true&q[is_visible_in][]=false`,
        this.getBranchSuccess,
        this.getBranchError,
        this.load,
      );
    getBranchSuccess = (payload) => {
      let tempBranches = [];
      let tempSearch = _.cloneDeep(this.props.searchParams);
      let tempIndex = _.findIndex(tempSearch, { value: "branch_id_eq" });

      payload.map((item) =>
        tempBranches.push({
          ...item,
          value: item.id,
          label: item.name,
        }),
      );
      tempSearch[tempIndex].options = tempBranches;

      this.props.onChangeHOC(tempSearch, "searchParams");
      this.setState({ branches: payload });
    };
    getBranchError = (error) => requestError(error);

    getBranchByCountry = (country_id) =>
      Get(
        `/branches?[q][country_id_eq]=${country_id}`,
        this.getBranchByCountrySuccess,
        this.getBranchByCountryError,
        this.load,
      );
    getBranchByCountrySuccess = (payload) => {
      let temp = [];
      temp = payload.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      });

      this.setState({ branchesByCountry: payload });
    };
    getBranchByCountryError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            branches={this.state.branches}
            onLoadBranch={this.state.loading}
            branchesByCountry={this.state.branchesByCountry}
            getBranch={this.getBranch}
            getBranchByCountry={this.getBranchByCountry}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
