import { GET_MY_CART, UPDATE_CART_ITEM_QTY } from "actions/type";

let initialState = {
  cartItem: [],
  totalCount: 0,
};

export default function myCartReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_CART:
      return {
        ...state,
        cartItem: action.payload,
        totalCount: action.payload.length,
      };
    case UPDATE_CART_ITEM_QTY:
      return {
        ...state,
        cartItem: action.payload,
      };
    default:
      return state;
  }
}
