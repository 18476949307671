import React from "react";
import Moment from "moment";
import _ from "lodash";
import {
  BsFillBookmarkStarFill,
  BsFillCalendar2Fill,
  BsGeoAltFill,
  BsFillPersonFill,
  BsFillCalendarXFill,
  BsFillFileEarmarkTextFill,
  BsFillTicketPerforatedFill,
} from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";

import "../index.scss";

const eventData = [
  { label: "Event", value: "name", icon: <BsFillBookmarkStarFill /> },
  { label: "Date and Time", value: "date_time", icon: <BsFillCalendar2Fill /> },
  {
    label: "Registration Ends",
    value: "cut_off_date",
    icon: <BsFillCalendarXFill />,
  },
  { label: "Venue", value: "location", icon: <BsGeoAltFill /> },
  { label: "Speaker", value: "speaker", icon: <BsFillPersonFill /> },
  {
    label: "Description",
    value: "description",
    icon: <BsFillFileEarmarkTextFill />,
  },
  {
    label: "Entry Pass",
    value: "entry_pass",
    icon: <BsFillTicketPerforatedFill />,
  },
  {
    label: "Branch Region",
    value: "branch_region",
    icon: <AtlasIcon svgHref={"atlas-building-4"} />,
  },
];

const EventDetailsContent = ({ selectedProduct }) => {
  const {
    attached_product: { event },
  } = selectedProduct;

  let tempData = {
    name: event.name,
    date_time: `${Moment(event.start_date_time).format("DD MMM YYYY LT")} - ${Moment(event.end_date_time).format("DD MMM YYYY LT")}`,
    cut_off_date: Moment(event.cut_off_date).format("DD MMM YYYY"),
    location: event.location,
    location_url: event.location_url,
    speaker: event.speaker,
    description: event.description,
    entry_pass: event.entry_pass,
    branch_region: event.branch_region_name.name,
  };

  return (
    <div style={{ maxWidth: 800 }}>
      {eventData.map((item, index) => {
        return (
          <div key={index} className="event-details">
            {item.value === "cut_off_date" && (
              <>
                <div className="event-details-icon">{item.icon}</div>
                <div style={{ width: "90%" }}>
                  <label style={{ maxWidth: 150 }}>{item.label}</label>
                  <br />
                  <span
                    style={{ color: "red" }}
                  >{`${tempData[item.value]}`}</span>
                </div>
              </>
            )}
            {item.value !== "cut_off_date" && (
              <>
                <div className="event-details-icon">{item.icon}</div>
                <div style={{ width: "90%" }}>
                  <label style={{ maxWidth: 150 }}>{item.label}</label>
                  <br />
                  <span>{`${tempData[item.value]}`}</span>
                  {item.value === "location" && (
                    <>
                      <br />
                      <span
                        className="fw-500"
                        style={{ color: "#2563EB", cursor: "pointer" }}
                        onClick={() =>
                          window.open(tempData.location_url, "__blank")
                        }
                      >
                        Location link
                      </span>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EventDetailsContent;
