import React, { useState, useEffect } from "react";
import _ from "lodash";
import AtlasCloseButton from "components/Button/close";

import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
  AiOutlineAlignLeft,
  AiOutlineAlignCenter,
  AiOutlineAlignRight,
} from "react-icons/ai";
import { SketchPicker } from "react-color";

import CustomFormInput from "components/Input/formInput";

export default function ShapeModalContent({
  tempEditTextObj,
  onClickClose,
  onClickAdd,
}) {
  const [formData, onChangeForm] = useState({
    fontWeight: "normal",
    text: "",
    fontStyle: "normal",
    fill: "black",
    textAlign: "center",
    underline: false,
  });

  useEffect(() => {
    onChangeForm({
      text: tempEditTextObj.text,
      fontStyle: tempEditTextObj.fontStyle,
      fontWeight: tempEditTextObj.fontWeight,
      fill: tempEditTextObj.fill === "black" ? "000000" : tempEditTextObj.fill,
      textAlign: tempEditTextObj.textAlign,
      underline: tempEditTextObj.underline,
    });
  }, [tempEditTextObj]);

  const onChangeField = (context, val) => {
    let temp = _.cloneDeep(formData);
    temp[context] = val;

    onChangeForm(temp);
  };

  const toggleBrightness = (param) => ({
    filter: `brightness(${param ? 1 : 0.5})`,
  });

  return (
    <>
      <div className="d-flex">
        <h5 className="at-card__title">{"Add Shape Container"}</h5>
        <AtlasCloseButton
          containerClass={"d-flex ml-auto"}
          onClick={() => onClickClose()}
        />
      </div>
      <hr />
      <div className={"grid-control"}>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            Fill in the text
          </h2>
          <CustomFormInput
            value={formData.text}
            required={true}
            onChangeValue={(val) => onChangeField("text", val)}
            placeholder={`Please enter container's text`}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Text Color</h2>
          <SketchPicker
            width={"80%"}
            triangle={"hide"}
            color={formData.fill}
            onChangeComplete={(color) => onChangeField("fill", color.hex)}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Text Style</h2>
          <div className={"d-flex flex-wrap"} style={{ gap: "1rem 2rem" }}>
            <div className={"at-graphic__text-style-btn-group"}>
              <AiOutlineBold
                className="at-graphic__text-style-icon-btn"
                style={toggleBrightness(formData.fontWeight === "bold")}
                onClick={() =>
                  onChangeField(
                    "fontWeight",
                    formData.fontWeight === "bold" ? "normal" : "bold",
                  )
                }
                title={"Bold"}
              />
              <AiOutlineItalic
                className={"btn-group at-graphic__text-style-icon-btn"}
                style={toggleBrightness(formData.fontStyle === "italic")}
                onClick={() =>
                  onChangeField(
                    "fontStyle",
                    formData.fontStyle === "italic" ? "normal" : "italic",
                  )
                }
                title={"Italic"}
              />
              <AiOutlineUnderline
                className={"at-graphic__text-style-icon-btn"}
                style={toggleBrightness(formData.underline)}
                onClick={() => onChangeField("underline", !formData.underline)}
                title={"Underline"}
              />
            </div>
            <div className={"btn-group at-graphic__text-style-btn-group"}>
              <AiOutlineAlignLeft
                className={"at-graphic__text-style-icon-btn"}
                style={toggleBrightness(formData.textAlign === "left")}
                onClick={() => onChangeField("textAlign", "left")}
                title={"Left align"}
              />
              <AiOutlineAlignCenter
                className={"at-graphic__text-style-icon-btn"}
                style={toggleBrightness(formData.textAlign === "center")}
                onClick={() => onChangeField("textAlign", "center")}
                title={"Center align"}
              />
              <AiOutlineAlignRight
                className={"at-graphic__text-style-icon-btn"}
                style={toggleBrightness(formData.textAlign === "right")}
                onClick={() => onChangeField("textAlign", "right")}
                title={"Right align"}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="d-flex mt-20 g-3">
        <button
          disabled={!formData.text}
          onClick={() => onClickAdd(formData)}
          className={"btn-new btn-new--success"}
        >
          Update
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClickClose()}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
