import React from "react";
import _ from "lodash";
import { Card, Grid } from "@material-ui/core";

import { IoIosMail, IoMdCall } from "react-icons/io";
import { BiGroup } from "react-icons/bi";

import checkValid from "utils/checkValid";

import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";

import AgentHOC from "../actions/agent";

const SearchContent = ({
  searchValue,
  searchedAgents,
  getInternalAgent,

  onClose,
  renInfo,
  onAddREN,
  onLoadAgent,
  requestError,
  requestSuccess,
  onChangeAgentHOC,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h2 className="at-form-input__title mb-2">{"Search for agent"}</h2>
          <CustomFormInput
            className={"mb-10"}
            type={"number"}
            value={`${searchValue}`}
            onChangeValue={(val) => onChangeAgentHOC(val, "searchValue")}
            placeholder={`Please fill in mobile number to search`}
          />
          <div className="d-flex mt-10 mb-10 g-3">
            <button
              disabled={searchValue.length < 1}
              className="btn-new btn-new--secondary"
              onClick={() =>
                getInternalAgent({ mobile_contact_number: searchValue })
              }
            >
              Search
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
        {searchedAgents && (
          <div className="col-md-12">
            <Card style={{ padding: 12 }}>
              <Grid container spacing={16} classes={"align-items-center"}>
                <Grid item md={4} xs={12}>
                  <img
                    src={searchedAgents.avatar_url}
                    style={{
                      width: 100,
                      borderRadius: "50%",
                      height: 100,
                      objectFit: "contain",
                    }}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <h2 className="at-form-input__title">
                    {checkValid(searchedAgents.agent_name)}
                  </h2>
                  <div className="d-flex flex-wrap">
                    <BiGroup
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 10,
                        color: "#F0631D",
                      }}
                    />
                    <span>{checkValid(searchedAgents.teamName)}</span>
                  </div>
                  <div className="d-flex">
                    <IoIosMail
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 10,
                        color: "#F0631D",
                      }}
                    />
                    <span>{checkValid(searchedAgents.agent_email)}</span>
                  </div>
                  <div className="d-flex">
                    <IoMdCall
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 10,
                        color: "#F0631D",
                      }}
                    />
                    <span>
                      {checkValid(searchedAgents.agent_mobile_number)}
                    </span>
                  </div>
                </Grid>
              </Grid>
              <hr />
              <button
                className="btn-new btn-new--primary mt-10 d-flex ml-auto"
                onClick={() => {
                  let tempRecord = _.findIndex(renInfo, {
                    user_id: searchedAgents.user_id,
                  });

                  if (tempRecord === -1) {
                    onAddREN(searchedAgents);
                    requestSuccess(
                      "Co-broke agent has been successfully added to this deal.",
                      () => {
                        onChangeAgentHOC(null, "searchedAgents");
                        onChangeAgentHOC("", "searchValue");
                      },
                    );
                  } else {
                    requestError("This agent has been assigned.");
                  }
                }}
              >
                Assign
              </button>
            </Card>
          </div>
        )}
      </div>
      {onLoadAgent && <LoadingModal />}
    </>
  );
};

export default AgentHOC(SearchContent);
