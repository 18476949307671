import React, { useEffect } from "react";
import _ from "lodash";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import PhotoModal from "components/PhotoModal";
import LoadingModal from "components/LoadingModal";
import CustomImageDisplay from "../../components/image";
import SubsalesTooltip from "../../components/tooltip";

import PhotoHOC from "../../actions/photo";
import "../../index.scss";

const Photo = ({
  subsalesPhotos,
  onLoadPhoto,
  showPhotoTabModal,
  tempImgSrc,
  subsalesEntryCreationProps,

  getLocalised,
  getSubsalesPhotos,
  onChangePhotoHOC,
}) => {
  useEffect(() => {
    getSubsalesPhotos(subsalesEntryCreationProps.id);
  }, []);

  return (
    <>
      <section className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-gallery"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.photos.uploaded_image.title", "Uploaded Images")}
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              showEdgeTooltip: true,
              toolTip: "subsalesImages",
            }}
          />
        </div>
        <p className="fs-2 mb-2">{getLocalised("label.photos.uploaded_image.subtitle", "View and manage your listing images.")}</p>
        <hr />
        <AlertBox
          mode={"danger"}
          containerStyle={{ marginBottom: "8px" }}
          description={getLocalised(
            "helptext.photos.uploaded_image.message", 
            "Listing will not exported to www.iqiglobal.com and www.juwai.com if no uploaded images found."
          )}
        />
        <div className="row">
          {subsalesPhotos &&
            subsalesPhotos.length > 0 &&
            subsalesPhotos.map((item, index) => (
              <div className="col-md-6 col-lg-4">
                <CustomImageDisplay
                  imgStyle={{ width: "100%" }}
                  key={`Photo-Subsales-${index}`}
                  tooltipID={`Photo-Subsales-${index}`}
                  imgSrc={item.photo || item.large_image_url}
                  onClickShowImage={(val) => {
                    onChangePhotoHOC(true, "showPhotoTabModal");
                    onChangePhotoHOC(val, "tempImgSrc");
                  }}
                />
              </div>
            ))}
        </div>
        {(!subsalesPhotos || subsalesPhotos.length < 1) && (
          <p>{getLocalised("label.photos.uploaded_image.empty_message", "No images have been uploaded")}</p>
        )}
      </section>
      {onLoadPhoto && <LoadingModal />}
      <PhotoModal
        showPhotoTabModal={showPhotoTabModal}
        tempImgSrc={tempImgSrc}
        togglePhotoTabModal={() => onChangePhotoHOC(false, "showPhotoTabModal")}
      />
    </>
  );
};

export default PhotoHOC(Photo);
