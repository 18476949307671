import { useRef, useState } from "react";
import ReactEmailEditor from "react-email-editor";
import { CardActions, CardContent } from "@material-ui/core";

import AtlasButton from "components/Button";
import ConfirmationDialog from "components/Modal/confirmation";

import isEmptyValue from "utils/isEmpty";

const EmailEditor = ({
  selectedEmailTemplate,
  toggleEmailPreview,
  updateEmailTemplate,
}) => {
  const emailEditorRef = useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { attachment, categories, content, name, status_id, subject } =
    selectedEmailTemplate;

  const handleClose = () => {
    toggleEmailPreview();
    setShowConfirmationDialog(false);
  };

  const handleReady = () => {
    if (Object.keys(content).length > 0) {
      emailEditorRef.current.editor.loadDesign(content);
    }
  };

  const handleSave = () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      const formData = new FormData();

      formData.append("name", name.trim());
      formData.append("type_id", 1);
      formData.append("status_id", parseInt(status_id));
      formData.append("html_code", html);
      formData.append("content[body]", JSON.stringify(design));
      formData.append("extra[subject]", subject.trim());

      if (!isEmptyValue(attachment)) {
        if (attachment && attachment.url) {
          formData.append("new_attachment", false);
        } else {
          formData.append("new_attachment", true);
          formData.append("attachment", attachment);
        }
      } else {
        formData.append("new_attachment", true);
        formData.append("attachment", "");
      }

      if (categories.length > 0) {
        for (const category of categories) {
          formData.append(
            "sorts[]",
            category && category.customOption ? category.label : category,
          );
        }
      }

      updateEmailTemplate(formData, "editor");
    });
  };

  const toggleConfirmationDialog = () =>
    setShowConfirmationDialog(!showConfirmationDialog);

  return (
    <>
      <CardContent className={"at-email-templates__form__card-content"}>
        <ReactEmailEditor
          ref={emailEditorRef}
          projectId={105506}
          onReady={handleReady}
        />
      </CardContent>

      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--success"}
          onClick={handleSave}
          children={"Save"}
        />
        <AtlasButton
          type={"button"}
          onClick={toggleConfirmationDialog}
          children={'Close'}
          className={"btn-new btn-new--outline-secondary"}
        />
      </div>

      <ConfirmationDialog
        open={showConfirmationDialog}
        title={"Exit Editor"}
        message={
          "All the unsaved changes will be discarded. Are you sure you want to exit now?"
        }
        positiveAction={handleClose}
        negativeAction={toggleConfirmationDialog}
        positiveText={"Exit"}
        negativeText={"Cancel"}
      />
    </>
  );
};

export default EmailEditor;
