export const subsalesType = [
  { value: "2", label: "Rent/Lease" },
  { value: "1", label: "Sale" },
];

export const propertyType = [
  { value: "1", label: "Residential" },
  { value: "2", label: "Commercial" },
  { value: "3", label: "Industrial" },
  { value: "4", label: "Agricultural" },
  { value: "5", label: "Unknown" },
];
