import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { FiEdit } from "react-icons/fi";
import { BsInfoCircleFill, BsTrash3Fill } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomButton from "components/Button";

import NoPhoto from "assets/images/no_photo_available.png";
import ProfileImage from "assets/images/av-male.jpg";
import "stylesheets/components/card/gooseberry.scss";

const processIconType = (param, style) => {
  switch (param) {
    case "details":
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#007bff" }} />
      );
    case "edit":
      return <FiEdit style={{ width: 18, height: 18, color: "#007bff" }} />;
    case "delete":
      return <BsTrash3Fill style={{ width: 18, height: 18, color: "#F04438" }} />
    default:
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#3B82F6" }} />
      );
  }
};

class CustomCard extends Component {
  render() {
    const { rowItem, actionColumnContent = [] } = this.props;
    const { id, country, name, email, profileImage, countryLogo, phoneNumber } =
      rowItem;

    return (
      <div className="at-card--gooseberry">
        <div className="at-card--gooseberry__photo-background">
          <img
            alt="Profile Picture"
            className="at-card--gooseberry__photo"
            onError={(e) => {
              if (e.target) {
                e.target.src = NoPhoto;
              }
            }}
            src={countryLogo}
          />
        </div>
        <div
          data-tip={true}
          data-for={`TooltipCountry-${id}`}
          style={{
            backgroundImage: `url( ${profileImage} ), url( ${ProfileImage} )`,
          }}
          className="at-card--gooseberry__avatar"
        >
          <Tooltip
            className="at-card--gooseberry__tooltip"
            tooltipPosition={"bottom"}
            type="dark"
            effect="solid"
            id={`TooltipCountry-${id}`}
          >
            <span>{country}</span>
          </Tooltip>
        </div>
        <div className="at-card--gooseberry__lower">
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <div className="at-card--gooseberry_title">{name}</div>
                <div className="at-card--gooseberry__detail">
                  <AtlasIcon svgHref={"atlas-global"} />
                  {country}
                </div>
                <div className="at-card--gooseberry__detail">
                  <AtlasIcon svgHref={"atlas-sms"} />
                  {email}
                </div>
                <div className="at-card--gooseberry__detail">
                  <AtlasIcon svgHref={"atlas-call"} />
                  {phoneNumber}
                </div>
              </div>
              {actionColumnContent && actionColumnContent.length > 0 && (
                <>
                  <div className="card--gooseberry__detail-actions">
                    {actionColumnContent.map((action, actionIndex) => {
                      if (
                        !action.onShow ||
                        (action.onShow && action.onShow(rowItem))
                      ) {
                        return (
                          <CustomButton
                            key={actionIndex}
                            disabled={action.disabled && action.disabled(rowItem)}
                            className={`at-table-icon-button ${action.iconClass || ""}`}
                            children={processIconType(
                              action.name,
                              action.iconStyle,
                            )}
                            tooltip={true}
                            tooltipChildren={
                              <span
                                className={
                                  action.tooltipContent ? "" : "text-capitalize"
                                }
                              >
                                {action.tooltipContent || action.name}
                              </span>
                            }
                            tooltipPosition={"top"}
                            tooltipID={`details-${action.name}-${rowItem.id}`}
                            onClick={() => action.onClick(rowItem, actionIndex)}
                          />
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="at-card--gooseberry__actions">
              <CustomButton
                containerClass={"w-100"}
                className={"btn-new btn-new--outline-secondary"}
                onClick={() =>
                  window.open(
                    `mailto:${email}?subject=Hi ${name}&body=Hello ${name}`,
                  )
                }
                children={
                  <>
                    <AtlasIcon svgHref={"atlas-sms-edit"} />
                    Email
                  </>
                }
              />
              <CustomButton
                containerClass={"w-100"}
                className={"btn-new btn-new--outline-secondary"}
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hello ${name}`,
                    "_blank",
                  )
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-whatsapp"}
                      style={{ fill: "#12B76A" }}
                    />
                    Message
                  </>
                }
              />
              <CustomButton
                containerClass={"w-100"}
                className={"btn-new btn-new--outline-secondary"}
                onClick={() => window.open(`tel:${phoneNumber}`)}
                children={
                  <>
                    <AtlasIcon svgHref={"atlas-call-calling"} />
                    Call
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomCard;
