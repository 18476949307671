import React, { useState } from "react";
import _ from "lodash";
import AtlasCloseButton from "components/Button/close";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { FaSquare } from "react-icons/fa";
import { PiTextAaBold } from "react-icons/pi";
import { AiFillFileImage } from "react-icons/ai";

import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";

const customButonStyle = {
  width: 35,
  height: 35,
  cursor: "pointer",
  borderRadius: 5,
};

export default function ShapeModalContent({ onClickClose, onClickAdd }) {
  const [formData, onChangeForm] = useState({
    shape: "rect",
    label: "",
    type: "img",
  });

  const onChangeField = (context, val) => {
    let temp = _.cloneDeep(formData);
    temp[context] = val;

    onChangeForm(temp);
  };

  return (
    <ModalDialog
      title={"Add Shape Container"}
      onClose={() => onClickClose()}
      children={
        <div className={"grid-control"}>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">
              Choose the shape of the container
            </h2>
            <div className="d-flex flex-wrap mt-3">
              <FaSquare
                className="bg-dark color-white p-1 mr-2"
                style={{
                  ...customButonStyle,
                  boxShadow:
                    formData.shape === "rect" ? "0 0 0 3px #f18e03" : "",
                }}
                onClick={() => onChangeField("shape", "rect")}
              />
              <RiCheckboxBlankCircleFill
                className="bg-dark color-white p-1 mr-2"
                style={{
                  ...customButonStyle,
                  boxShadow:
                    formData.shape === "circle" ? "0 0 0 3px #f18e03" : "",
                }}
                onClick={() => onChangeField("shape", "circle")}
              />
            </div>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">
              Choose the type of the container
            </h2>
            <div className="d-flex flex-wrap mt-3">
              <PiTextAaBold
                className="bg-dark color-white p-1 mr-2"
                style={{
                  ...customButonStyle,
                  boxShadow:
                    formData.type === "text" ? "0 0 0 3px #f18e03" : "",
                }}
                onClick={() => onChangeField("type", "text")}
              />
              <AiFillFileImage
                className="bg-dark color-white p-1 mr-2"
                style={{
                  ...customButonStyle,
                  boxShadow: formData.type === "img" ? "0 0 0 3px #f18e03" : "",
                }}
                onClick={() => onChangeField("type", "img")}
              />
            </div>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Fill in the label of the container
            </h2>
            <CustomFormInput
              value={formData.label}
              required={true}
              onChangeValue={(val) => onChangeField("label", val)}
              placeholder={`Please enter container's label`}
            />
          </section>
        </div>
      }
      footer={
        <div className="d-flex">
          <button
            onClick={() => onClickAdd(formData)}
            className={"btn-new btn-new--success"}
          >
            Add
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClickClose()}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
}
