import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHTMLParser from "react-html-parser";

import { Get, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      apptLetters: [],
      showSelectForm: false,
      selectedAttachedApptLetter: {},
      showSubmitConfirmationModal: false,
      unattachedAppointmentLetters: [],
      loading: false,
      errorMessage: "",
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({ errorMessage: ReactHTMLParser(error) });

    onChangeAppointmentLetterHOC = (value, context) => {
      let tmp = {}
      if(context === 'showSelectForm' && !value) {
        tmp = { selectedAttachedApptLetter: {} }
      }
      this.setState({ 
        [context]: value,
        ...tmp
      });
    };

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmationModal: false,
        showSelectForm: false,
        selectedAttachedApptLetter: {}
      });

    getUnattachedApptLetters = (id) => {
      Get(
        `/sub_sale_claims/${id}/unattached_appointment_letters`,
        this.getUnattachedApptLettersSuccess,
        this.getUnattachedApptLettersError,
        this.load,
      );
    };
    getUnattachedApptLettersSuccess = (payload) => {
      let tmp = [];
      payload &&
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.transaction_number,
          });
        });
      return this.setState({
        unattachedAppointmentLetters: tmp,
        showSelectForm: true,
      });
    };
    getUnattachedApptLettersError = (error) => requestError(error);

    submitApptForm = (claim_id, appointment_letter_id) => {
      return Put(
        `/sub_sale_claims/${claim_id}/attach_appointment_letter`,
        { appointment_letter_id: appointment_letter_id },
        (payload) =>
          this.submitApptFormSuccess({
            claim_id,
            appointment_letter_id,
          }),
        this.submitApptFormError,
        this.load,
      );
    };
    submitApptFormSuccess = ({ claim_id, appointment_letter_id }) => {
      const { currentPage, searchParams } =
      this.props.data.lastViewReducer.lastView;
      requestSuccess("Form is submitted successfully");
  
      this.props.getSelectedClaim(claim_id);
      this.props.getClaimsList(currentPage, searchParams);

      this.closeSubmitConfirmation();
    };
    submitApptFormError = (error) => {
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedAttachedApptLetter={ this.state.selectedAttachedApptLetter }
            showSelectForm={ this.state.showSelectForm }
            apptLetters={ this.state.apptLetters }
            unattachedAppointmentLetters={this.state.unattachedAppointmentLetters }
            showSubmitConfirmationModal={ this.state.showSubmitConfirmationModal}
            getUnattachedApptLetters={ this.getUnattachedApptLetters }
            submitApptForm={ this.submitApptForm }
            onChangeAppointmentLetterHOC={ this.onChangeAppointmentLetterHOC }
            onLoadApptLetter={this.state.loading}
            errorMessage={this.state.errorMessage}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
