import React, { useEffect, useState } from "react";
import _ from "lodash";

import BadgeSVG from "components/Icon/badge";
import ModalDialog from "components/Modal/ModalDialog";

import TierBackground from "assets/images/tier-bg.png";
import { numberWithCommas } from "utils/thousandSeparator";

import "stylesheets/components/tier/index.scss";

const tier = [
  { label: "Trooper", target: 0, color: "#F9BA15", subsalesPercentage: "20%" },
  {
    label: "Valiant",
    target: 10000,
    color: "#545F42",
    subsalesPercentage: "25%",
  },
  {
    label: "Constable",
    target: 20000,
    color: "#76815E",
    subsalesPercentage: "30%",
  },
  {
    label: "Corporal",
    target: 40000,
    color: "#FFE989",
    subsalesPercentage: "35%",
  },
  {
    label: "Sergeant",
    target: 100000,
    color: "#61371F",
    subsalesPercentage: "40%",
  },
  {
    label: "Lieutenant",
    target: 200000,
    color: "#000000",
    subsalesPercentage: "45%",
  },
  {
    label: "Commander",
    target: 250000,
    color: "#23486D",
    subsalesPercentage: "48%",
  },
  {
    label: "General",
    target: 275000,
    color: "#B2292C",
    subsalesPercentage: "50%",
  },
];

const TierContent = ({ selectedAgent }) => {
  const [highestRank, setHighestRank] = useState(0);
  const [progressBarWidth, setProgressWidth] = useState(0);
  const [showAllLevels, setShowAllLevels] = useState(false);
  const [targetDiff, setTargetDiff] = useState(0);

  useEffect(() => {
    let rankIndex = _.findIndex(tier, (item) => {
      return item.label === selectedAgent.rank;
    });
    setHighestRank(rankIndex < 0 ? 0 : rankIndex);
  }, [selectedAgent.rank]);

  useEffect(() => {
    if (highestRank === 7) {
      setProgressWidth(100);
    } else {
      let targetDiff =
        selectedAgent.nextSubsaleCommTarget -
        selectedAgent.currentSubsaleAccumulatedCommission;
      let progressWidth =
        (selectedAgent.currentSubsaleAccumulatedCommission /
          selectedAgent.nextSubsaleCommTarget) *
        100;
      setTargetDiff(targetDiff);
      setProgressWidth(progressWidth);
    }
  }, [highestRank]);

  const processCurrentLevel = (val) => {
    if (val) return `${Math.ceil(val * 100).toFixed(2)}%`;
    else return `20%`;
  };

  const processNextLevel = (val) => {
    if (val) return `${Math.ceil((val - 0.4) * 100)}%`;
    else return `25%`;
  };

  return (
    <>
      <div className="at-tier">
        <div className="at-tier-current-badge">Current Progress</div>
        <div className="at-tier-current-rank">
          <div>
            <label>{tier[highestRank].label}</label>
            <div className="d-flex align-items-center">
              <p>
                RM{" "}
                {numberWithCommas(
                  selectedAgent?.currentSubsaleAccumulatedCommission?.toFixed(
                    2,
                  ),
                )}
              </p>
              <div className="at-tier-rank__badge">
                {processCurrentLevel(selectedAgent.currentSubsalePercentage)}
              </div>
            </div>
          </div>
          <div className="ml-auto mr-1">
            <BadgeSVG badge={tier[highestRank].label} />
          </div>
        </div>
        <div className="at-tier__progress-main">
          <div className="w-100 mr-2">
            <div className="at-tier__progress-cont">
              <div
                className="at-tier__progress-bar"
                style={{ width: `${progressBarWidth}%` }}
              ></div>
              <div
                className={`at-tier__progress-indicator ${
                  progressBarWidth > 10
                    ? progressBarWidth > 90
                      ? "d-flex flex-column align-items-end"
                      : "d-flex flex-column align-items-center"
                    : ""
                }`}
              >
                <p>
                  {numberWithCommas(
                    selectedAgent.currentSubsaleAccumulatedCommission,
                  )}{" "}
                  / {numberWithCommas(selectedAgent.nextSubsaleCommTarget)}
                </p>
                <div></div>
              </div>
            </div>
            <label
              style={{
                fontSize: 12,
                fontWeight: 400,
                cursor: "pointer",
                marginTop: 8,
              }}
              onClick={() => setShowAllLevels(true)}
            >
              {`All levels >`}
            </label>
          </div>
          <label style={{ fontSize: 12, fontWeight: 400, marginLeft: "auto" }}>
            {highestRank < 7 ? (
              <>
                Next Level
                <p
                  className="d-flex align-items-center"
                  style={{ fontSize: 12 }}
                >
                  {tier[highestRank + 1].label}
                  <div className="at-tier-rank__badge">
                    {processNextLevel(selectedAgent.nextSubsaleTierPercentage)}
                  </div>
                </p>
              </>
            ) : (
              <>Congratulations. You've reached the max level.</>
            )}
          </label>
        </div>
        <img
          src={TierBackground}
          alt="tier background"
          className="at-tier__background"
        />
      </div>
      <div className="at-tier-footer grid-control">
        <div className="grid-half-col">
          <label>
            Agency Comm. at{" "}
            {selectedAgent.rank ? selectedAgent.rank : "Trooper"}
          </label>
          <p>
            RM{" "}
            {numberWithCommas(
              selectedAgent.currentSubsaleAccumulatedCommission,
              2,
            )}
          </p>
        </div>
        <div className="grid-half-col">
          <label>Total Agency Comm. for Current Year</label>
          <p>
            RM{" "}
            {numberWithCommas(selectedAgent.totalAgencyCommissionThisYear, 2)}
          </p>
        </div>
      </div>
      {showAllLevels && (
        <ModalDialog
          title="All Levels"
          responsiveSize="sm"
          onClose={() => setShowAllLevels(false)}
          children={
            <div className="at-tier__all-levels">
              {tier.map((item, index) => {
                const highlight =
                  highestRank === index
                    ? "at-tier__all-levels__item--highlight"
                    : "at-tier__all-levels__item";
                return (
                  <div key={index} className={highlight}>
                    <div className="at-tier__all-levels__item__badge">
                      <BadgeSVG badge={item.label} />
                    </div>
                    <div className="at-tier__all-levels__item__label">
                      <label style={{ textTransform: "uppercase" }}>
                        {item.label}
                      </label>
                      <p>{numberWithCommas(item.target)}</p>
                    </div>
                    <div className="at-tier__all-levels__item__percentage">
                      {item.subsalesPercentage}
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />
      )}
    </>
  );
};

export default TierContent;
