import React, { useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Dialog, Select, MenuItem, OutlinedInput } from "@material-ui/core";
import { IoMdAdd, IoMdRemoveCircle } from "react-icons/io";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/close";
import AtlasPrevButton from "components/Button/prev";

import "../index.scss";

const refundType = [
  { id: 1, label: "Refund" },
  { id: 2, label: "Forfeit" },
  { id: 3, label: "Refund & Forfeit" },
];

const refundPartyData = [
  { value: "name", label: "Name" },
  { value: "nric", label: "NRIC" },
  { value: "mobile_phone", label: "Tel." },
  { value: "address", label: "Address" },
];

const fieldData = [
  {
    party: "seller",
    sectionLabel: "Refund for Seller",
    checkboxField: "refund_for_seller",
    field: [
      { label: "Bank Holder Name", value: "bank_account_holder_name" },
      { label: "Bank Name", value: "bank_id", type: "select" },
      { label: "Bank Account", value: "bank_account_number", type: "number" },
      {
        label: "Percentage of total refund amount",
        value: "percentage",
        type: "number",
      },
    ],
  },
  {
    party: "buyer",
    sectionLabel: "Refund for Buyer",
    checkboxField: "refund_for_buyer",
    field: [
      { label: "Bank Holder Name", value: "bank_account_holder_name" },
      { label: "Bank Name", value: "bank_id", type: "select" },
      { label: "Bank Account", value: "bank_account_number", type: "number" },
      {
        label: "Percentage of total refund amount",
        value: "percentage",
        type: "number",
      },
    ],
  },
];

const RefundForm = ({
  mode,
  banks,
  formId,
  refundPartyInfo,
  refundFormData,
  showRecipientsSelectModal,
  eProcessPartyData = [],
  onLoadRefund,

  getRefundForm,
  updateRefundForm,
  onChangeRefundHOC,
  onClickClose,
}) => {
  useEffect(() => {
    getRefundForm(formId);
  }, []);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(refundFormData);
    temp[context] = val;
    onChangeRefundHOC(temp, "refundFormData");
  };

  const onClickFill = (id) => {
    let temp = _.find(eProcessPartyData, { id: parseInt(id) });

    onChangeRefundHOC(
      {
        name: temp.full_name,
        nric: temp.identification_number,
        mobile_phone: temp.mobile_contact_number,
        address: temp.current_address,
      },
      "refundPartyInfo",
    );
    onChangeRefundHOC(false, "showRecipientsSelectModal");
  };

  const autoCalculatePercentage = (data, onChangeIndex, value) => {
    let temp = _.cloneDeep(data);
    let dividedValue = Number((100 - value) / (data.length - 1)).toFixed(1);
    temp.map((item, index) => {
      if (index !== onChangeIndex) {
        item.percentage = dividedValue;
      } else {
        item.percentage = value;
      }
    });
    return temp;
  };

  return (
    <>
      {mode === "edit" && (
        <>
          <div className="at-card__header">
            <AtlasPrevButton onClick={() => onClickClose()} />
            <h5 className="at-card__title">
              {"Authorisation letter to refund/forfeit earnest deposit"}
            </h5>
          </div>
          {_.values(refundPartyInfo).every((x) => x === "") && (
            <button
              className={"btn-new btn-new--secondary mb-20"}
              onClick={() =>
                onChangeRefundHOC(true, "showRecipientsSelectModal")
              }
            >
              Assign recipient from contract participant
            </button>
          )}
        </>
      )}
      {_.values(refundPartyInfo).some((x) => x !== "") && (
        <div className="at-card bg-white pb-0 mb-10">
          {refundPartyData.map((item) => (
            <div key={item.label} className={"mb-10 d-flex"}>
              <label
                className="at-form-input__title"
                style={{ minWidth: 120 }}
              >{`${item.label}:`}</label>
              <span>{refundPartyInfo[item.value]}</span>
            </div>
          ))}
          {mode === "edit" && (
            <button
              className={"btn-new btn-new--secondary mb-20"}
              style={{ width: "fit-content" }}
              onClick={() =>
                onChangeRefundHOC(true, "showRecipientsSelectModal")
              }
            >
              Change recipient
            </button>
          )}
        </div>
      )}
      <hr />
      <p className={"mt-20"}>Dear Sir/ Madam</p>
      <strong>
        Earnest Deposit Payable on sale / Lease of property addresss
      </strong>
      <div style={{ lineHeight: 1.5, fontWeight: 500 }} className={"mt-10"}>
        {refundFormData.property_address}
      </div>
      <p className={"mt-20"}>
        {`I/ We hereby authorizing `}
        <strong>IQI Realty Sdn Bhd</strong>
        {` to  `}
        {mode === "view" && refundFormData && (
          <strong>
            {_.find(refundType, { id: refundFormData.type_id }).label}
          </strong>
        )}
        {mode !== "view" && (
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            checkedValue={refundFormData.type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) =>
              onChangeField(parseInt(val), "type_id")
            }
            options={refundType}
          />
        )}
        {` the earnest deposit which has been held by `}
        <strong>IQI Realty Sdn Bhd</strong>
        {` as a stakeholder:-`}
      </p>
      <div className={"subsales-letter-points"}>
        <div className="subsales-letter-subpoints">
          {`- Purchase / Rental Price: RM ${refundFormData.price}`}
        </div>
        <div className="subsales-letter-subpoints">
          {`- Earnest Deposit: RM ${refundFormData.earnest_deposit_amount}`}
        </div>
        <div className="subsales-letter-subpoints">
          - Reason for aborting:
          <textarea
            disabled={mode === "view"}
            className="refund-letter-input mb-10"
            style={{ width: "100%", maxWidth: "100%", height: 100 }}
            value={refundFormData.abort_reason}
            onChange={(e) => onChangeField(e.target.value, "abort_reason")}
          />
        </div>
      </div>
      <hr />
      <div className={"grid-control"}>
        {fieldData.map((item, index) => (
          <section key={index} className="grid-full-col refund-party-container">
            <CustomCheckbox
              labelClassname={"d-flex align-items-center mb-10"}
              disabled={mode === "view"}
              checked={refundFormData[item.checkboxField]}
              onChangeCheckboxValue={() => {
                let temp = _.cloneDeep(refundFormData);
                temp[item.checkboxField] = !refundFormData[item.checkboxField];
                if (!refundFormData[item.checkboxField]) {
                  temp.refund_details[item.party].push({
                    bank_account_holder_name: "",
                    bank_account_number: "",
                    bank_id: 0,
                    percentage: 100,
                  });
                } else {
                  temp.refund_details[item.party] = [];
                }
                onChangeRefundHOC(temp, "refundFormData");
              }}
              content={
                <div className="d-flex align-items-center">
                  <h4 className="at-card__title">{item.sectionLabel}</h4>
                  <CustomButton
                    className={"btn-new btn-new--primary"}
                    containerStyle={{ marginLeft: "auto" }}
                    children={
                      <>
                        <IoMdAdd
                          style={{ height: 18, width: 18, color: "#FFFF" }}
                        />
                        Add
                        <span className="text-capitalize ml-2">
                          {item.party}
                        </span>
                      </>
                    }
                    onClick={() => {
                      let temp = _.cloneDeep(refundFormData.refund_details);
                      temp[item.party].push({
                        bank_account_holder_name: "",
                        bank_account_number: "",
                        bank_id: 0,
                        percentage: 0,
                      });
                      onChangeField(temp, "refund_details");
                    }}
                  />
                </div>
              }
            />
            {refundFormData.refund_details[item.party].length > 0 &&
              refundFormData.refund_details[item.party].map(
                (user, userIndex) => (
                  <div className="refund-party-member">
                    <div className="d-flex">
                      <h2 className="refund-party-member-label">
                        {item.party} {userIndex + 1}
                      </h2>
                      <button
                        className="ml-auto"
                        onClick={() => {
                          if (
                            refundFormData.refund_details[item.party].length > 0
                          ) {
                            let temp = _.cloneDeep(
                              refundFormData.refund_details,
                            );
                            temp[item.party].splice(userIndex, 1);
                            onChangeField(temp, "refund_details");
                          }
                        }}
                      >
                        <IoMdRemoveCircle
                          style={{ width: 20, height: 20, color: "red" }}
                        />
                      </button>
                    </div>
                    <div className="row">
                      {item.field.map((fieldItem) => (
                        <div key={fieldItem.label} className={"mb-10 col-md-6"}>
                          <label className="at-form-input__title" required>
                            {fieldItem.label}
                          </label>
                          {fieldItem.type === "select" && (
                            <Select
                              autoWidth={true}
                              fullWidth
                              disabled={mode === "view"}
                              input={<OutlinedInput labelWidth={0} />}
                              value={user[fieldItem.value]}
                              onChange={(e) => {
                                let temp = _.cloneDeep(
                                  refundFormData.refund_details,
                                );
                                temp[item.party][userIndex][fieldItem.value] =
                                  e.target.value;
                                onChangeField(temp, "refund_details");
                              }}
                            >
                              {banks.map((item) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                          {fieldItem.type !== "select" && (
                            <input
                              disabled={mode === "view"}
                              className="refund-letter-input d-block ml-0"
                              required={true}
                              type={fieldItem.type}
                              style={{ width: "100%", maxWidth: "100%" }}
                              value={user[fieldItem.value]}
                              onChange={(e) => {
                                let temp = _.cloneDeep(
                                  refundFormData.refund_details,
                                );
                                if (fieldItem.value === "percentage") {
                                  if (e.target.value <= 100)
                                    temp[item.party] = autoCalculatePercentage(
                                      temp[item.party],
                                      userIndex,
                                      e.target.value,
                                    );
                                } else {
                                  temp[item.party][userIndex][fieldItem.value] =
                                    e.target.value;
                                }
                                onChangeField(temp, "refund_details");
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ),
              )}
          </section>
        ))}
      </div>
      {mode === "edit" && (
        <div className="d-flex mb-20 ">
          <button
            className={"btn-new btn-new--success mr-10"}
            disabled={
              (!refundFormData.refund_for_seller &&
                !refundFormData.refund_for_buyer) ||
              !refundFormData.type_id
            }
            onClick={() =>
              updateRefundForm({
                ...refundFormData,
                form_id: formId,
              })
            }
          >
            Save
          </button>
          <button
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => onClickClose()}
          >
            Close
          </button>
        </div>
      )}
      <Dialog fullWidth={true} maxWidth={"md"} open={showRecipientsSelectModal}>
        <CustomCard
          cardContent={
            <>
              <div className={"d-flex mb-20"}>
                <h5 className="at-card__title">
                  {"Assign recipient from contract participant"}
                </h5>
                <AtlasCloseButton
                  containerStyle={{ display: "flex", marginLeft: "auto" }}
                  onClick={() =>
                    onChangeRefundHOC(false, "showRecipientsSelectModal")
                  }
                />
              </div>
              <hr />
              <AtlasRadioGroup
                valueKey={"id"}
                labelKey={"full_name"}
                checkedValue={refundFormData.refund_party_id}
                containerClassName={"w-100"}
                selectedRadioValue={(val) =>
                  onChangeField(val, "refund_party_id")
                }
                options={eProcessPartyData}
              />
              <button
                className={"btn-new btn-new--success mt-20"}
                onClick={() => onClickFill(refundFormData.refund_party_id)}
              >
                Assign with selected participant
              </button>
              <button
                className={"btn-new btn-new--outline-secondary mt-20 ml-2"}
                onClick={() =>
                  onChangeRefundHOC(false, "showRecipientsSelectModal")
                }
              >
                Close
              </button>
            </>
          }
        />
      </Dialog>
      {onLoadRefund && <LoadingModal />}
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(RefundForm);
