import React, { useEffect, useState } from "react";
import { compose } from "redux";

import ModalDialog from "components/Modal/ModalDialog";
import ProjectContent from "../../ProjectListing/Details";

import VideoHOC from "../../ProjectListing/actions/video";
import ProjectListingHOC from "../../ProjectListing/actions";
import DonwloadHOC from "../../ProjectListing/actions/download";
import DocumentHOC from "../../ProjectListing/actions/document";
import VisualisationHOC from "../../ProjectListing/actions/visualisation";

const ProjectDetails = (props) => {
  const [showProjectContent, setShowProjectContent] = useState(false);

  useEffect(() => {
    Promise.all([
      props.getSelectedProject(props.project_id),
      props.getDocuments(props.project_id),
      props.getVisualisations(props.project_id),
      props.getVideos(props.project_id),
    ]).then(() => {
      setShowProjectContent(true);
    });
  }, []);

  return (
    <ModalDialog
      title={"Project Details"}
      onLoad={!showProjectContent}
      fullWidth={true}
      fullHeight={true}
      onClose={() => props.onChangeCobrokeHOC(false, "showProjectDetails")}
      children={
        showProjectContent && (
          <ProjectContent
            selectedProject={props.selectedProject}
            getSelectedProject={props.getSelectedProject}
            hideProjectPartner={true}
            documents={props.documents}
            onDownloadFile={props.onDownloadFile}
            closeDetailsDialog={() =>
              props.onChangeCobrokeHOC(false, "showProjectDetails")
            }
            visualisations={props.visualisations}
            videos={props.videos}
          />
        )
      }
    />
  );
};

export default compose(
  VideoHOC,
  DocumentHOC,
  DonwloadHOC,
  VisualisationHOC,
  ProjectListingHOC,
)(ProjectDetails);
