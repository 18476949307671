import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";

import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      remarks: [],
      newRemark: {
        content: "",
        remark_documents_attributes: [],
      },
      selectedRemark: {},
      selectedAttachment: {},
      selectedImage: "",
      showEnlargeImageModal: false,
      showCreateRemarkModal: false,
      showDeleteAttachmentConfirmation: false,
      showDeleteRemarkConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRemarkHOC = (val, context) => this.setState({ [context]: val });

    getRemarks = (id) =>
      Get(
        `/admin/remarks?user_id=${id}`,
        this.getRemarksSuccess,
        this.getRemarksError,
        this.load,
      );
    getRemarksSuccess = (payload) =>
      this.setState({ remarks: payload.remarks });
    getRemarksError = (error) => requestError(error);

    createRemarks = (dataToSubmit) =>
      Post(
        `/admin/remarks`,
        dataToSubmit,
        () => this.createRemarksSuccess(dataToSubmit.user_id),
        this.createRemarksError,
        this.load,
      );
    createRemarksSuccess = (id) => {
      this.setState({
        showCreateRemarkModal: false,
        newRemark: {
          content: "",
          remark_documents_attributes: [],
        },
      });
      this.getRemarks(id);
      requestSuccess("Remark added successfully");
    };
    createRemarksError = (error) => requestError(error);

    deleteRemarkDocument = (remark_id, document_id) =>
      Delete(
        `/admin/remarks/${remark_id}/documents/${document_id}`,
        this.deleteRemarkDocumentSuccess,
        this.deleteRemarkDocumentError,
        this.load,
      );
    deleteRemarkDocumentSuccess = () => {
      this.setState({
        showDeleteAttachmentConfirmation: false,
      });
      this.getRemarks(this.props.id);
      requestSuccess("Selected attachment has been removed");
    };
    deleteRemarkDocumentError = (error) => requestError(error);

    deleteRemark = (id) =>
      Delete(
        `/admin/remarks/${id}`,
        this.deleteRemarkSuccess,
        this.deleteRemarkError,
        this.load,
      );
    deleteRemarkSuccess = () => {
      this.setState({ showDeleteRemarkConfirmation: false });
      this.getRemarks(this.props.id);
      requestSuccess("Selected remark has been removed");
    };
    deleteRemarkError = (error) => requestError(error);

    appendRemarkAttachments = ({ user_id, remark_id, attachment }) =>
      Post(
        `/admin/remarks/${remark_id}/documents`,
        { data: attachment },
        () => this.appendRemarkAttachmentsSuccess(user_id),
        this.appendRemarkAttachmentsError,
        this.load,
      );
    appendRemarkAttachmentsSuccess = (user_id) => {
      this.getRemarks(user_id);
      requestSuccess("The photo uploaded successfully.");
    };
    appendRemarkAttachmentsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadRemark={this.state.loading}
            remarks={this.state.remarks}
            newRemark={this.state.newRemark}
            selectedImage={this.state.selectedImage}
            selectedRemark={this.state.selectedRemark}
            selectedAttachment={this.state.selectedAttachment}
            showEnlargeImageModal={this.state.showEnlargeImageModal}
            showCreateRemarkModal={this.state.showCreateRemarkModal}
            showDeleteRemarkConfirmation={
              this.state.showDeleteRemarkConfirmation
            }
            showDeleteAttachmentConfirmation={
              this.state.showDeleteAttachmentConfirmation
            }
            getRemarks={this.getRemarks}
            deleteRemark={this.deleteRemark}
            createRemarks={this.createRemarks}
            appendRemarkAttachments={this.appendRemarkAttachments}
            deleteRemarkDocument={this.deleteRemarkDocument}
            onChangeRemarkHOC={this.onChangeRemarkHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
