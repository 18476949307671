import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import ReactHTMLParser from "react-html-parser";
import { PulseLoader } from "react-spinners";
import { AiOutlineClose } from "react-icons/ai";
import { Message } from "react-chat-ui";

import CKEditor from "components/CKEditor";
import AtlasButton from "components/Button";
import DeleteConfirmation from "components/Modal/delete";

import "stylesheets/containers/personal/subsales/components/message.scss";
import "stylesheets/components/textarea/index.scss";

import permissionsChecker from "utils/permissionsChecker";
import SubsaleMessageHOC from "../actions/message";

class SubsaleClaimMessage extends Component {
  state = {
    messages: [],
    inputMessage: "",
    pressEnterToSend: false,
    messageAgentInfo: {},
    messageAdminInfo: {},
    showDeleteConfirmation: false,
    deleteMessage: "",
  };

  textarea = "";

  componentDidMount = () => {
    this.props.getMessages(this.props.selected_claim.id);
    this.scrollToBottom();
  };

  componentDidUpdate = (pp) => {
    if (this.props.messages.length !== pp.messages.length) {
      const tmpProfileReducer = window.location.href.includes(
        "/admin-impersonate",
      )
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.scrollToBottom();
      let tmp = [];
      this.props.messages.map((item) => {
        let data = {
          id: item.from_admin ? 0 : 1,
          message: ReactHTMLParser(item.content),
        };
        tmp.push(new Message(data));
      });
      let tmpAgent = _.findLast(this.props.messages, {
        sender_name: this.props.selected_claim.agent_name,
      });
      let tmpAdmin = _.findLast(this.props.messages, {
        sender_name: tmpProfileReducer.display_name,
      });
      this.setState({
        messages: tmp,
        messageAgentInfo: tmpAgent,
        messageAdminInfo: tmpAdmin,
      });
    }
    if (this.props.onLoadMessages !== pp.onLoadMessages) {
      if (!this.props.onLoadMessages) {
        this.setState({ inputMessage: "" });
      }
    }
    if (
      this.props.clearTypeBox !== pp.clearTypeBox &&
      this.props.clearTypeBox
    ) {
      this.setState({ inputMessage: this.props.data.messageReducer.message });
    }
  };

  scrollToBottom = () =>
    this.messagesEnd.scrollIntoView({ block: "nearest", inline: "start" });

  onChangeMessageValue = (message) => {
    if (message.indexOf("<br>") === 0) {
      this.setState({ inputMessage: "" });
    } else {
      this.setState({ inputMessage: message });
    }
  };

  onClickSendMessage = () =>
    Promise.all([
      this.props.postMessage(this.props.selected_claim.id, {
        message: {
          content: this.state.inputMessage,
        },
      }),
    ]).then(() => {
      this.setState({ inputMessage: "" });
    });

  onClickLoadSavedMessage = () =>
    this.setState({
      inputMessage: this.props.data.messageReducer.message,
    });

  onClickSaveCurrentMessage = () =>
    this.props.storeSubsalesClaimsMessage(this.state.inputMessage);

  render = () => {
    const { can_create, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    const { id, role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div>
        <div className="chat-feed-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.props.messages &&
              this.props.messages.length > 0 &&
              this.props.messages.map((item) => {
                if (!item.from_admin) {
                  return (
                    <div className="at-message__coversation-cont">
                      <div className="d-flex justify-content-between my-1">
                        <p
                          style={{
                            fontSize: 14,
                            color: "#1F2937",
                            fontWeight: 500,
                          }}
                        >
                          {item.sender_name}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#4B5563",
                            marginLeft: 10,
                          }}
                        >
                          {Moment(item.created_at)
                            .format("DD MMM YYYY HH:mm")
                            .toString()}
                        </p>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          background: "#F3F4F6",
                          borderRadius: "0px 12px 12px 12px",
                          padding: "12px 16px",
                        }}
                      >
                        <p
                          className={"at-message__text-content"}
                          style={{ color: "black" }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                        {role === "Super Admin" && (
                          <AtlasButton
                            containerStyle={{
                              position: "absolute",
                              top: 0,
                              right: 5,
                            }}
                            onClick={() =>
                              this.setState({
                                showDeleteConfirmation: true,
                                deleteMessage: item,
                              })
                            }
                            children={
                              <AiOutlineClose
                                style={{
                                  width: 14,
                                  height: 14,
                                  color: "black",
                                }}
                              />
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="at-message__coversation-cont ml-auto">
                      <div className="d-flex justify-content-between my-1">
                        <p
                          style={{
                            fontSize: 14,
                            color: "#1F2937",
                            fontWeight: 500,
                          }}
                        >
                          {item.sender_name}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#4B5563",
                            marginLeft: 10,
                          }}
                        >
                          {Moment(item.created_at)
                            .format("DD MMM YYYY HH:mm")
                            .toString()}
                        </p>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          background: "#2563EB",
                          borderRadius: "12px 0px 12px 12px",
                          padding: "12px 16px",
                        }}
                      >
                        <p
                          className={"at-message__text-content"}
                          style={{ color: "white" }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                        {(role === "Super Admin" ||
                          (can_destroy && item.sender_id === id)) && (
                          <AtlasButton
                            containerStyle={{
                              position: "absolute",
                              top: 0,
                              right: 5,
                            }}
                            onClick={() =>
                              this.setState({
                                showDeleteConfirmation: true,
                                deleteMessage: item,
                              })
                            }
                            children={
                              <AiOutlineClose
                                style={{
                                  width: 14,
                                  height: 14,
                                  color: "white",
                                }}
                              />
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
        <div className="at-subsales_claims-message_editor mb-3">
          <CKEditor
            data={this.state.inputMessage}
            onChange={(event, editor) => {
              let data = `${editor.getData()}`;

              return this.onChangeMessageValue(data);
            }}
          />
        </div>
        <div className="d-flex g-3">
          {!this.props.onLoadMessages && (
            <>
              {this.state.inputMessage !== "" && (
                <button
                  className="btn-new btn-new--secondary"
                  children={"Save Current Message"}
                  onClick={this.onClickSaveCurrentMessage}
                />
              )}
              <AtlasButton
                className="btn-new btn-new--secondary"
                children={"Load Saved Message"}
                onClick={this.onClickLoadSavedMessage}
              />
              {can_create && (
                <button
                  disabled={this.state.inputMessage === ""}
                  className="btn-new btn-new--secondary"
                  children={"Send"}
                  onClick={this.onClickSendMessage}
                />
              )}
            </>
          )}
          {this.props.onLoadMessages && (
            <PulseLoader
              className={"d-flex align-items-center"}
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={this.props.onLoadMessages}
            />
          )}
        </div>
        <DeleteConfirmation
          open={this.state.showDeleteConfirmation}
          loading={this.props.onLoadMessages}
          message={"Are you sure to remove this message?"}
          title={" "}
          positiveAction={() => {
            this.props.deleteMessage(this.state.deleteMessage);
            this.setState({ showDeleteConfirmation: false });
          }}
          negativeAction={() =>
            this.setState({ showDeleteConfirmation: false })
          }
        />
      </div>
    );
  };
}

export default SubsaleMessageHOC(SubsaleClaimMessage);
