import React from "react";
import { Tooltip } from "@material-ui/core";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import { withStyles } from "@material-ui/core/styles";

import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";
import { status } from "../../assets";
import NoContent from "assets/images/no-content.png";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const CustomCard = ({
  data,
  can_update,
  can_destroy,
  onClickEdit,
  onClickDelete,
  onChangeEmailTemplatesHOC,
}) => {
  const { id, name, sort_list, status_id, thumbnail_url } = data;

  const { colorName, label } = status.find((item) => item.value === status_id);

  return (
    <li className={"at-email-templates__card"}>
      <div className="at-email-templates__image-container">
        <img
          className={"at-email-templates__card-media"}
          src={thumbnail_url || NoContent}
          alt={name}
          title={name}
          height={250}
        />
        <div className="at-email-templates__duplicate-container">
          <DarkTooltip 
            title={"Duplicate Email Template"}
            placement="top">
            <button
              className={`at-email-templates__duplicate-icon`}
              onClick={() => {
                onChangeEmailTemplatesHOC("selectedCloneId", id);
                onChangeEmailTemplatesHOC("showCloneConfirmationModal", true)
              }}
            >
              <AtlasIcon
                svgHref={"atlas-copy"}
                style={{ width: 18, height: 18, fill: "#3B82F6" }}
              />
            </button>
          </DarkTooltip>
        </div>
      </div>
      
      <div className={"at-email-templates__card-content"}>
        <div className="d-flex flex-wrap align-items-center g-2">
          <span
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}>
            {label}
          </span>
          {sort_list.length > 0 && sort_list.map((category, index) => (
            <span key={index} className={"at-status_badge"}
              style={{ ...getColorBadge("Blue") }}>
              {category}
            </span>
          ))}
        </div>
        <h5 className={"at-email-templates__card-title"}>{name}</h5>

        <div className={"d-flex algin-items-center g-2"}>
          {can_update && (
            <AtlasButton
              className={"btn-new btn-new--secondary w-100"}
              containerClass={"w-100"}
              onClick={() => onClickEdit(id)}>
              <BsPencilSquare style={{ width: 20, height: 20, marginRight: 8 }} />
              Edit
            </AtlasButton>
          )}

          {can_destroy && (
            <AtlasButton
              className={"btn-new btn-new--outline-secondary w-100"}
              containerClass={"w-100"}
              onClick={() => onClickDelete(id)}>
              <BsTrash3Fill style={{ width: 20, height: 20, color: "#F04438", marginRight: 8  }} />
              <span style={{ color: "#F04438" }}>Delete</span>
            </AtlasButton>
          )}
        </div>
      </div>
    </li>
  );
};

export default CustomCard;
