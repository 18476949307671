import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import { refreshToken } from "actions/login";
import { getAllDictionaryData, getCountryDictionary } from "actions/dictionary";
import { project as ProjectDictionary } from "dictionary/index";

const SearchParams = ({ currencies }) => [
  {
    label: "Country",
    value: "country_id_eq",
    type: "typeahead",
    placeholder: "All Country",
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "typeahead",
    placeholder: "All State",
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Area",
    value: "township_cont",
    type: "typeahead",
    placeholder: "All Area",
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Project Name",
    value: "name_or_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Project Type",
    value: "type_id_eq",
    type: "select",
    withoutPrefix: false,
    param: "",
    options: ProjectDictionary.type,
    className: "col-md-6 mb-10 pl-0",
  },
  {
    label: "Project Title",
    value: "project_title_id_eq",
    type: "select",
    withoutPrefix: false,
    param: "",
    options: ProjectDictionary.title,
    className: "col-md-6 mb-10 pl-0",
  },
  {
    label: "Developer",
    value: "developer_cont",
    type: "input",
    param: "",
  },
  {
    label: "Bedroom No. greater than",
    value: "min_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Bedroom No. less than",
    value: "max_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Currency",
    value: "currency",
    type: "select",
    withoutPrefix: false,
    param: "",
    options: currencies.name,
    className: "col-md-6 mb-10 pl-0",
  },
  {
    label: "Project Price greater than",
    value: "min_price",
    withoutPrefix: false,
    type: "input",
    param: "",
  },
  {
    label: "Project Price less than",
    value: "max_price",
    withoutPrefix: false,
    type: "input",
    param: "",
  },
  {
    label: "Tenure",
    value: "tenure_type_id_eq",
    type: "select",
    withoutPrefix: false,
    param: "",
    options: ProjectDictionary.tenure,
    className: "col-md-6 mb-10 pl-0",
  },
  {
    label: "Built up size greater than",
    value: "min_built_up_eq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size less than",
    value: "max_built_up_eq",
    type: "input",
    param: "",
  },
  {
    label: "Completion Date greater than",
    value: "completion_date_dategteq",
    withoutPrefix: true,
    type: "date",
    param: "",
  },

  {
    label: "Show Exclusive Projects Only",
    value: "is_exclusive_eq",
    type: "radio",
    param: "",
    options: [
      { value: "true", label: "Yes" },
      { value: "", label: "No" },
    ],
  },
  {
    label: "Show Project Live Chart Projects Only ",
    value: "project_units_count_gt",
    type: "radio",
    param: "",
    options: [
      { label: "Yes", value: "0" },
      { label: "No", value: "" },
    ],
  },
];

const ProjectListingHOC = (WrappedComponent) => {
  class ProjectListingWrappedComponent extends Component {
    state = {
      requestCount: 0,
      showProjectDetails: false,
      projects: [],
      selectedProject: {},
      loading: false,

      searchParams: SearchParams(this.props.data.dictionaryReducer),
      totalPages: null,
    };

    load = (param) => {
      this.setState((prevState) => {
        const newCount = param ? prevState.requestCount + 1 : prevState.requestCount - 1;
        return {
          loading: newCount > 0,
          requestCount: newCount,
        };
      });
    };

    onChangeProjectHOC = (val, context) => this.setState({ [context]: val });

    selectSearchHeader = (val) =>
      this.setState({
        searchWithField: val.value,
        searchWithFieldDisplay: val.label,
      });

    getProjectListings = (page, search) => {
      let tempSearch = _.cloneDeep(search);
      if (tempSearch.includes("q[project_units_count_gt]=0")) {
        tempSearch += "q[has_live_sales_eq]=true&";
      }

      if (tempSearch.includes("q[country_id_eq]")) {
        let countryIndex = _.findIndex(this.state.searchParams, { value: "country_id_eq" });
        tempSearch = tempSearch.replace(
          `q[country_id_eq]=${this.state.searchParams[countryIndex].param.name}`,
          `q[country_id_eq]=${this.state.searchParams[countryIndex].param.id}`
        );
      }

      if (tempSearch.includes("q[state_id_eq]")) {
        let stateIndex = _.findIndex(this.state.searchParams, { value: "state_id_eq" });
        tempSearch = tempSearch.replace(
          `q[state_id_eq]=${this.state.searchParams[stateIndex].param.name}`,
          `q[state_id_eq]=${this.state.searchParams[stateIndex].param.id}`
        );
      }

      if (tempSearch.includes("q[currency]")) {
        let currencyIndex = _.findIndex(this.state.searchParams, { value: "currency" });

        tempSearch = tempSearch.replace(
          `q[currency]=${this.state.searchParams[currencyIndex].param}`,
          `currency=${this.state.searchParams[currencyIndex].param}`
        );
      }

      Get(
        `/projects?${tempSearch}page=${page}`,
        this.getProjectListingsSuccess,
        this.getProjectListingsError,
        this.load
      );
    };
    getProjectListingsSuccess = (payload) => {
      let totalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        totalPages.push(i);
      }
      this.setState({
        projects: payload,
        totalPages,
      });
    };
    getProjectListingsError = (error) => requestError(error);

    getSelectedProject = (id) =>
      Get(
        `/projects/${id}`,
        this.getSelectedProjectSuccess,
        this.getSelectedProjectError,
        this.load
      );
    getSelectedProjectSuccess = (payload) => {
      const { countries } = this.props.data.dictionaryReducer;
      let dataProjectTitle = _.find(ProjectDictionary.title, {
        id: payload.project_title_id,
      });
      let dataProjectType = _.find(ProjectDictionary.type, {
        id: payload.type_id,
      });
      let dataTenureType = _.find(ProjectDictionary.tenure, {
        id: payload.tenure_type_id,
      });
      let dataStatus = _.find(ProjectDictionary.status, {
        id: payload.status_id,
      });
      let dataCountry = _.find(countries, { id: payload.country_id });
      let dataState = _.find(dataCountry ? dataCountry.states : [], {
        id: payload.state_id,
      });

      this.setState({
        selectedProject: {
          ...payload,
          project_units: payload.project_units || [],
          hd_map_description: payload.location,
          project_title: dataProjectTitle ? dataProjectTitle.name : "",
          project_type: dataProjectType ? dataProjectType.name : "",
          tenure_type: dataTenureType ? dataTenureType.name : "",
          status: dataStatus ? dataStatus.name : "",
          country: dataCountry ? dataCountry.name : "",
          state: dataState ? dataState.name : "",
          landing_page: payload.landing_page,
        },
        showProjectDetails: true,
      });
    };
    getSelectedProjectError = (error) => requestError(error);

    getSelectedTownShip = (state_id) =>
      Get(
        `/townships${state_id ? `?state_id=${state_id}` : ""}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load
      );
    getSelectedTownShipSuccess = (payload) => {
      let temp = [];
      let tempSearch = _.cloneDeep(this.state.searchParams);
      let townShipIndex = _.findIndex(tempSearch, { value: "township_cont" });
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = {
            id: item.id,
            value: item.name,
            name: item.name,
            label: item.name,
          };
          temp.push(tempObj);
        });
      tempSearch[townShipIndex].options = temp;
      this.setState({ searchParams: tempSearch });
    };
    getSelectedTownShipError = (error) => requestError(error && error);

    getAllStates = () =>
      Get(
        `/states`,
        this.getAllStatesSuccess,
        this.getAllStatesError,
        this.load
      );
    getAllStatesSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.searchParams);
      let stateIndex = _.findIndex(temp, { value: "state_id_eq" });
      temp[stateIndex].options = payload.states?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      this.setState({ searchParams: temp });
    };
    getAllStatesError = (error) => requestError(error);

    getAllTownShips = (name) =>
      Get(
        `/townships${name ? `?q[name_cont]=${name}` : ""}`,
        this.getAllTownShipsSuccess,
        this.getAllTownShipsError,
        this.load
      );
    getAllTownShipsSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.searchParams);
      let townShipIndex = _.findIndex(temp, { value: "township_cont" });
      temp[townShipIndex].options = payload?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      this.setState({ searchParams: temp });
    };
    getAllTownShipsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            totalPages={this.state.totalPages}
            projects={this.state.projects}
            selectedProject={this.state.selectedProject}
            searchParams={this.state.searchParams}
            onLoadProject={this.state.loading}
            showDetails={this.state.showDetails}
            showProjectDetails={this.state.showProjectDetails}
            getAllStates={this.getAllStates}
            getAllTownShips={this.getAllTownShips}
            getProjectListings={this.getProjectListings}
            getSelectedProject={this.getSelectedProject}
            getSelectedTownShip={this.getSelectedTownShip}
            selectSearchHeader={this.selectSearchHeader}
            onChangeProjectHOC={this.onChangeProjectHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    getCountryDictionary,
    getAllDictionaryData,
  })(ProjectListingWrappedComponent);
};

export default ProjectListingHOC;
