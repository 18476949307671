import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Put, Post } from "utils/axios";

import StatusModal from "components/Modal/status";

const HOC = (WrappedComponent) => {
  class TemplateWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",

      notificationMessage: "",
      password: "",
      confirmedPassword: "",
      matchedPassword: false,
      error: null,
      resetToken: "",
      resetTokenValid: false,
      showSuccessMessage: false,
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    onChangeHOC = (val, context) =>
      this.setState({ [context]: val }, () => {
        if (context === "password" || context === "confirmedPassword") {
          this.state.password !== this.state.confirmedPassword
            ? this.setState({ matchedPassword: false })
            : this.setState({ matchedPassword: true });
        }
      });

    onSubmitResetPassword = (val) =>
      Put(
        `/passwords/reset_password`,
        val,
        this.onSubmitRecoveryAccountSuccess,
        this.onSubmitRecoveryAccountError,
        this.load,
      );
    onSubmitRecoveryAccountSuccess = (payload) =>
      this.setState({
        showSuccessMessage: true,
        notificationMessage: payload.message,
      });
    onSubmitRecoveryAccountError = (error) =>
      this.setState({ notificationMessage: error });

    validateResetToken = (val) =>
      Post(
        `/passwords/validates_reset_token`,
        { token: val },
        this.validateResetTokenSuccess,
        this.validateResetTokenError,
        this.load,
      );
    validateResetTokenSuccess = () => this.setState({ resetTokenValid: true });
    validateResetTokenError = (error) =>
      this.setState({ notificationMessage: error, resetTokenValid: false });

    onClickSubmitPassword = () => {
      this.state.matchedPassword &&
      this.state.password.length > 0 &&
      this.state.confirmedPassword.length > 0
        ? this.onSubmitResetPassword({
            token: this.state.resetToken,
            password: this.state.password,
            password_confirmation: this.state.confirmedPassword,
          })
        : this.setState({
            notificationMessage:
              "Please make sure both password and confirmed password are similiar and not empty.",
          });
    };

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            password={this.state.password}
            resetTokenValid={this.state.resetTokenValid}
            confirmedPassword={this.state.confirmedPassword}
            error={this.state.error}
            matchedPassword={this.state.matchedPassword}
            onLoadResetPassword={this.state.loading}
            showSuccessMessage={this.state.showSuccessMessage}
            notificationMessage={this.state.notificationMessage}
            onChangeHOC={this.onChangeHOC}
            validateResetToken={this.validateResetToken}
            onClickSubmitPassword={this.onClickSubmitPassword}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </Fragment>
      );
    };
  }
  return TemplateWrappedComponent;
};

export default HOC;
