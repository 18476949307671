import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const ContactHOC = (WrappedComponent) => {
  class ContactWrappedComponent extends Component {
    state = {
      contacts: [],

      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getCurrentUserContactList = () =>
      Get(
        `/contacts/search`,
        this.getCurrentUserContactListSuccess,
        this.getCurrentUserContactListError,
        this.load,
      );
    getCurrentUserContactListSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            label: item.full_name,
            value: item.id,
          };
          tmpArray.push(data);
        });
        return this.setState({ contacts: tmpArray });
      }
    };
    getCurrentUserContactListError = (error) => requestError(error);

    render = () => (
      <>
        <WrappedComponent
          {...this.props}
          contacts={this.state.contacts}
          onLoadContact={this.state.loading}
          getCurrentUserContactList={this.getCurrentUserContactList}
        />
      </>
    );
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(ContactWrappedComponent);
};

export default ContactHOC;
