import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      commRate: true,
      commLoading: false,
      selectedAgent: {},
    };

    load = (param) => this.setState({ loading: param });

    getUnitAdminProfile = (encoded_mobile) =>
      Get(
        encoded_mobile
          ? `/connector/profile?identity_id=${encoded_mobile}`
          : `/connector/profile`,
        this.getUnitAdminProfileSuccess,
        this.getUnitAdminProfileError,
        this.load,
      );
    getUnitAdminProfileSuccess = (payload) =>
      this.setState({ selectedAgent: payload });
    getUnitAdminProfileError = (error) => requestError(error);

    getCommRate = () =>
      Get(
        `/profiles/sub_sales/comm_rate`,
        this.getCommRateSuccess,
        this.getCommRateError,
        this.load,
      );
    getCommRateSuccess = (payload) =>
      this.setState({ commRate: payload.subsale_comm_rate_set });
    getCommRateError = () => {};

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadUnitAdmin={this.state.loading}
            onLoadCommRate={this.state.commLoading}
            selectedAgent={this.state.selectedAgent}
            commRate={this.state.commRate}
            getCommRate={this.getCommRate}
            getUnitAdminProfile={this.getUnitAdminProfile}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
