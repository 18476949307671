import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import SVGIcon from "components/Icon";

const Icon = ({
  containerClassName,
  containerStyle,
  itemID,
  iconClassName,
  iconName,
  tooltipLabel,
  children,
}) => {
  return (
    <Tooltip
      classes={{
        tooltip: "tooltip-arrow",
        tooltipPlacementTop: "top",
        tooltipPlacementBottom: "bottom",
      }}
      enterTouchDelay={0}
      key={itemID}
      placement={"top"}
      title={tooltipLabel}
    >
      <div
        className={containerClassName}
        style={containerStyle}
        data-tip={"tooltip"}
        data-for={itemID}
      >
        <SVGIcon className={iconClassName} svgHref={iconName} />
        {children}
      </div>
    </Tooltip>
  );
};

export default Icon;
