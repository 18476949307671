import { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";

import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

import { status } from "../assets";

const searchParams = (categories) => [
  {
    label: "Name",
    value: "name",
    type: "input",
    param: "",
  },
  {
    label: "Category",
    value: "sort",
    type: "select",
    param: "",
    options: categories || [],
  },
  {
    label: "Status",
    value: "status",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Draft", colorName: "Yellow" },
      { value: "2", label: "Published", colorName: "Green" },
      { value: "3", label: "Disabled", colorName: "Grey" },
    ],
  },
];

const withEmailTemplates = (WrappedComponent) => {
  class EmailTemplatesHOC extends Component {
    state = {
      onLoadEmailTemplates: false,
      emailTemplates: {},
      emailTemplatePages: [],
      selectedEmailTemplate: {},
      selectedEmailTemplateToClone: {},
      selectedId: 0,
      selectedCloneId: null,
      searchParams: searchParams(),
      categories: [],

      showCreateModal: false,
      showUpdateModal: false,
      showDeleteModal: false,
      showCloneConfirmationModal: false,
    };

    load = (param) => this.setState({ onLoadEmailTemplates: param });

    onChangeEmailTemplatesHOC = (key, val) => this.setState({ [key]: val });

    getEmailTemplates = (page = 1, query = "") => {
      if (query !== "") {
        query = query
          .replace("[name]", "")
          .replace("q[sort]", "sort")
          .replace("q[status]", "status");
      }

      this.props.storeLastView({ page, query });

      Get(
        `/admin/email_templates?${query}page=${page}`,
        this.getEmailTemplatesSuccess,
        this.getEmailTemplatesError,
        this.load,
      );
    };
    getEmailTemplatesSuccess = (data) => {
      const pages = [];

      for (let i = 0; i < data.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        emailTemplates: data,
        emailTemplatesPages: pages,
      });
    };
    getEmailTemplatesError = (error) => requestError(error);

    getCategories = () =>
      Get(
        `/tags?type=sorts`,
        this.getCategoriesSuccess,
        this.getCategoriesError,
        this.load,
      );
    getCategoriesSuccess = (payload) => {
      this.setState({
        categories: payload.map((item) => item.name),
        searchParams: searchParams(
          payload.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          })),
        ),
      });
    };
    getCategoriesError = (error) => requestError(error);

    getSelectedEmailTemplate = (id, clone = false) =>
      Get(
        `/admin/email_templates/${id}`,
        payload => this.getSelectedEmailTemplateSuccess(payload, clone),
        this.getSelectedEmailTemplateError,
        this.load,
      );
    getSelectedEmailTemplateSuccess = (data, clone) => {
      const templateKey = clone ? 'selectedEmailTemplateToClone' : 'selectedEmailTemplate';

      this.setState({
        [templateKey] : {
          id: data.id,
          name: clone ? `${data.name} (Copy)` : data.name,
          subject: data.extra.subject,
          categories: data.sort_list.sort(),
          attachment: data.attachment,
          status_id: clone ? "1" : data.status_id,
          html_code: data.html_code,
          content: JSON.parse(data.content.body),
          thumbnail_url: data.thumbnail_url,
        },
        [clone ? 'showCreateModal' : 'showUpdateModal']: true,
      });
    };
    getSelectedEmailTemplateError = (error) => requestError(error);
    
    createEmailTemplate = (data) =>
      Post(
        `/admin/email_templates`,
        data,
        this.createEmailTemplateSuccess,
        this.createEmailTemplateError,
        this.load,
      );
    createEmailTemplateSuccess = (data) => {
      requestSuccess("Email template was created successfully!");

      this.setState({ 
        showCreateModal: false, 
        selectedEmailTemplateToClone: {} 
      });
      this.getSelectedEmailTemplate(data.id);
      this.getEmailTemplates();
      this.getCategories();
    };
    createEmailTemplateError = (error) => requestError(error);

    updateEmailTemplate = (data, from) =>
      Put(
        `/admin/email_templates/${this.state.selectedEmailTemplate.id}`,
        data,
        (data) => this.updateEmailTemplateSuccess(data, from),
        this.updateEmailTemplateError,
        this.load,
      );
    updateEmailTemplateSuccess = (data, from) => {
      requestSuccess("Email template was updated successfully!");

      if (from === "setup") {
        this.setState({
          showUpdateModal: false,
          selectedEmailTemplate: {},
        });
      } else if (from === "editor") {
        this.setState({
          selectedEmailTemplate: {
            id: data.id,
            name: data.name,
            subject: data.extra.subject,
            categories: data.sort_list.sort(),
            attachment: data.attachment,
            status_id: data.status_id,
            html_code: data.html_code,
            content: JSON.parse(data.content.body),
            thumbnail_url: data.thumbnail_url,
          },
        });
      }

      this.getEmailTemplates();
      this.getCategories();
    };
    updateEmailTemplateError = (error) => requestError(error);

    deleteEmailTemplate = (id) =>
      Delete(
        `/admin/email_templates/${id}`,
        this.deleteEmailTemplateSuccess,
        this.deleteEmailTemplateError,
        this.load,
      );
    deleteEmailTemplateSuccess = () => {
      requestSuccess("Email template was deleted successfully!");

      this.setState({ showDeleteModal: false});
      this.getEmailTemplates();
      this.getCategories();
    };
    deleteEmailTemplateError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          selectedCloneId={this.state.selectedCloneId}

          onChangeEmailTemplatesHOC={this.onChangeEmailTemplatesHOC}
          getEmailTemplates={this.getEmailTemplates}
          getCategories={this.getCategories}
          getSelectedEmailTemplate={this.getSelectedEmailTemplate}
          createEmailTemplate={this.createEmailTemplate}
          updateEmailTemplate={this.updateEmailTemplate}
          deleteEmailTemplate={this.deleteEmailTemplate}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, { storeLastView })(EmailTemplatesHOC);
};

export default withEmailTemplates;
