import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { requestSuccess, requestError } from "utils/requestHandler";
import { Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      formData: {
        type_id: "1",
        buyer_name: "",
        booking_date: null,
        project_name: "",
        size: null,
        unit_number: "",
        purchase_price: null,
        package: null,
        nett_price: null,
        remark: "",
        status_id: "1",
        status: "",
        admin_remark: "",

        booking_form: null,
        booking_form_file_name: null,
        project_sale_agents_attributes: [],
        project_location_id: null,
        agent: {},
      },
      showConfirmationModal: false,
      showPDFModal: false,
      showAssignModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeEditHOC = (val, context) => this.setState({ [context]: val });

    onChangeField = (val, context) => {
      let temp = _.cloneDeep(this.state.formData);
      temp[context] = val;

      this.setState({ formData: temp });
    };

    onUpdateProjectSaleHOC = (id) =>
      Put(
        `/admin/project_sales/${id}`,
        this.state.formData,
        this.onUpdateProjectSaleSuccess,
        this.onUpdateProjectSaleError,
        this.load,
      );
    onUpdateProjectSaleSuccess = (payload) => {
      this.props.getSelectedSales(payload.id);
      requestSuccess("Project Sale has been updated successfully");
    };
    onUpdateProjectSaleError = (error) => requestError(error);

    onChangeRENPercentage = (val, index) => {
      let temp = _.cloneDeep(this.state.formData);
      temp.project_sale_agents_attributes[index].percentage = val;
      this.setState({ formData: temp });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          formData={this.state.formData}
          showPDFModal={this.state.showPDFModal}
          showConfirmationModal={this.state.showConfirmationModal}
          showAssignModal={this.state.showAssignModal}
          requestSuccess={requestSuccess}
          requestError={requestError}
          onChangeField={this.onChangeField}
          onChangeEditHOC={this.onChangeEditHOC}
          onUpdateProjectSaleHOC={this.onUpdateProjectSaleHOC}
          onChangeRENPercentage={this.onChangeRENPercentage}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
