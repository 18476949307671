import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";

const ReaRenewalHOC = (WrappedComponent) => {
  class ReaRenewalWrappedComponent extends Component {
    state = {
      loading: false,
      renewalApplications: [],
      renewalApplicationsPage: [],
      selectedRenewal: {},

      showRenewalDetails: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRenewalHOC = (val, context) => this.setState({ [context]: val });

    getReaRenewalApplications = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/licence_renewal_applicants?${search}page=${page}`,
        this.getReaRenewalApplicationsSuccess,
        this.getReaRenewalApplicationsError,
        this.load,
      );
    };
    getReaRenewalApplicationsSuccess = (payload) => {
      let temp = [];
      let tempPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      temp = payload.data.map((item) => {
        return {
          ...item,
          licence_renewal_status: item.licence_renewal.licence_status
            ? _.capitalize(item.licence_renewal.licence_status)
            : "",
        };
      });

      this.setState({
        renewalApplications: {
          data: temp,
          meta: payload.meta,
        },
        renewalApplicationsPage: tempPages,
      });
    };
    getReaRenewalApplicationsError = (error) => requestError(error);

    getSelectedReaRenewalApplication = (id) =>
      Get(
        `/licence_renewal_applicants/${id}`,
        this.getSelectedReaRenewalApplicationSuccess,
        this.getSelectedReaRenewalApplicationError,
        this.load,
      );
    getSelectedReaRenewalApplicationSuccess = (payload) => {
      this.setState({
        selectedRenewal: {
          ...payload,
          licence_renewal_status: payload.licence_renewal.licence_status
            ? _.capitalize(payload.licence_renewal.licence_status)
            : "",
        },
        showRenewalDetails: true,
      });
    };
    getSelectedReaRenewalApplicationError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadReaRenewal={this.state.loading}
            selectedRenewal={this.state.selectedRenewal}
            showRenewalDetails={this.state.showRenewalDetails}
            renewalApplications={this.state.renewalApplications}
            renewalApplicationsPage={this.state.renewalApplicationsPage}
            detailsData={this.state.detailsData}
            data={this.state.data}
            onChangeRenewalHOC={this.onChangeRenewalHOC}
            getReaRenewalApplications={this.getReaRenewalApplications}
            getSelectedReaRenewalApplication={
              this.getSelectedReaRenewalApplication
            }
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(ReaRenewalWrappedComponent);
};

export default ReaRenewalHOC;
