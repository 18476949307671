import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { BsCheck } from "react-icons/bs";
import { Line } from "react-chartjs-2";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomFormHeader from "components/Form/header";
import CustomFormInput from "components/Input/formInput";

import RecruirtmentHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";
import { checkNumberNull } from "utils/checkNull";
import { numberWithCommas } from "utils/thousandSeparator";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

import "stylesheets/containers/team/recruitment-growth-targets/index.scss";

const statData = [
  {
    label: "Total Agents",
    value: "numberOfAgents",
    icon: "profile2user",
    color: "#8B5CF6",
  },
  {
    label: "Current Performing Agents",
    value: "numberOfAgentsSoldUnits",
    icon: "profile-tick",
    color: "#06B6D4",
  },
  {
    label: "Average sale per performing agent",
    value: "avgSalesPerPerformingAgent",
    icon: "coin-1",
    color: "#E74694",
  },
];

const statData2 = [
  {
    label: "Target Sales for Year 2023",
    value: "targetSales",
    icon: "money-receive",
    color: "#84CC16",
  },
  {
    label: "Target Sales Difference",
    value: "difference",
    icon: "money-4",
    color: "#06B6D4",
  },
  {
    label: "New Agents Needed",
    value: "newAgent",
    icon: "user-circle-add",
    color: "#D946EF",
  },
  {
    label: "Additional Performing Agents",
    value: "additionalPerformingAgent",
    icon: "profile-tick",
    color: "#3B82F6",
  },
];

const statData3 = [
  {
    label: "Target Sales for this year",
    value: "targetSales",
    icon: "money-receive",
    color: "#8B5CF6",
  },
  {
    label: "Gross sales with 65% success rate",
    value: "grossSales",
    icon: "trend-up",
    color: "#6875F5",
  },
  {
    label: "New agents required to recruit",
    value: "newAgent",
    icon: "user-circle-add",
    color: "#E74694",
  },
  {
    label: "Additional performing agents required",
    value: "newPerformingAgent",
    icon: "profile-tick",
    color: "#E74694",
  },
];

class RecruitmentGrowthTarget extends Component {
  componentDidMount = () => {
    initGA("/dashboard/team/recruitment-growth-targets");

    this.processOutcome("VIEW");
  };

  processOutcome = (mode) => {
    const { role, encoded_identity } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    let tmpIdentityID =
      ["Super Admin", "Admin"].indexOf(role) > -1
        ? this.props.encoded_identity
        : encoded_identity;

    this.props.getRecruitmentGrowth(
      mode,
      this.props.numberValue,
      tmpIdentityID,
    );
  };

  renderRecruitmentGrowthTips = () => {
    return (
      <>
        <span className="fw-500">
          How to calculate team sales growth for next year
        </span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          Recruiting high per forming agents has always been a key factor for
          annual team growt h. The following tool allows you to analyse your
          existing team's per formance while utilising t he calculated figures
          to suggest t he number of new agent recruitments required for t he
          coming year
          <ol style={{ paddingInlineStart: 15 }}>
            <li>
              Enter t he percentage of growt h you wish to see in your sale
              figure for t he coming year. (Example: Your sales figure for t he
              year 2019 has been MYR 100 Million. You wish to increase t he
              figure to MYR 130 Million in t he year 2020. The percentage you
              must enter is %30.
            </li>
            <li>You may now click "calculate" and review your result.</li>
          </ol>
        </p>
      </>
    );
  };

  renderCardContent = () => {
    const { recruitmentGrowthData, summaryData, periodData, lineChartData } =
      this.props;
    const {
      newAgent,
      numberOfAgents,
      newSalesAmount,
      recruitmentStrategies,
      numberOfAgentsSoldUnits,
      pastPerformingAgentPercentage,
    } = recruitmentGrowthData;
    const { can_update } = permissionsChecker(
      "Recruitment Growth Target",
      this.props.data,
    );

    return (
      <>
        <CustomFormHeader
          moduleIcon={"atlas-profile2user"}
          title={`Team Performance in ${new Date().getFullYear() - 1}`}
        />
        <div className="at-team-sales__stat-cont mt-3">
          <div
            style={{ padding: 12, marginBottom: 8 }}
            className="at-form__content at-recruit__card-stat-first w-100"
          >
            <div
              className="at-card--stat__general-icon-cont"
              style={{ backgroundColor: "#14B8A6" }}
            >
              <AtlasIcon svgHref={`atlas-moneys`} />
            </div>
            <div className="at-card--stat__general-category mb-0">
              <p>Your Group Sales</p>
              <span>
                {recruitmentGrowthData.salesAmount
                  ? numberWithCommas(
                      recruitmentGrowthData.salesAmount.toFixed(0),
                    )
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="at-team-sales__stat-cont">
          {statData.map((item, index) => (
            <div
              key={index}
              style={{ padding: 12, marginBottom: 8 }}
              className="at-form__content d-flex align-items-center w-100"
            >
              <div
                className="at-card--stat__general-icon-cont"
                style={{ backgroundColor: item.color }}
              >
                <AtlasIcon svgHref={`atlas-${item.icon}`} />
              </div>
              <div className="at-card--stat__general-category mb-0">
                <p>{item.label}</p>
                <span className="d-flex">
                  {recruitmentGrowthData[item.value]
                    ? numberWithCommas(
                        recruitmentGrowthData[item.value].toFixed(0),
                      )
                    : 0}
                  {item.value === "numberOfAgentsSoldUnits" && (
                    <span
                      className="at-status_badge fs-2 ml-2"
                      style={{ ...getColorBadge("Blue") }}
                    >
                      {recruitmentGrowthData.pastPerformingAgentPercentage}
                    </span>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        {can_update && (
          <div className="at-form__content mt-2">
            <CustomFormHeader
              moduleIcon={"atlas-trend-up"}
              title={
                this.props.showGrowthResult
                  ? `Recruitment Strategy ${new Date().getFullYear()}`
                  : "Expected team sales growth next year"
              }
            />
            {this.props.showGrowthResult && (
              <div>
                <p className="fs-2 mt-3">
                  In order to fulfil your expected target growth of{" "}
                  <span className="fw-600 mr-1">{this.props.numberValue}%</span>
                  which target sales for year{" "}
                  <span className="fw-600 mr-1">
                    {new Date().getFullYear()}
                  </span>
                  is <span className="fw-600">{newSalesAmount}</span> based on
                  your performing agent percentage of
                  <span className="fw-600 ml-1">
                    {pastPerformingAgentPercentage}
                  </span>{" "}
                  and with your existing
                  <span className="fw-600 ml-1">
                    {numberOfAgentsSoldUnits}
                  </span>{" "}
                  performing agents, you will need to hire an additional
                  <span className="fw-600 ml-1">
                    {!_.isEmpty(recruitmentStrategies) &&
                      recruitmentStrategies.newAgent?.yearly}
                  </span>{" "}
                  agents in total per year. Out of these
                  <span className="fw-600 ml-1">
                    {newAgent + numberOfAgents}
                  </span>{" "}
                  agents,
                  <span className="fw-600 ml-1">
                    {!_.isEmpty(recruitmentStrategies) &&
                      recruitmentStrategies.newPerformingAgent.yearly}
                  </span>{" "}
                  are expected to be performing based on your current
                  percentage.
                </p>
                <div className="at-recruit__card-stat2 mt-3">
                  <div className="at-form__content">
                    <Line
                      data={lineChartData}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            display: false,
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                callback: (value) =>
                                  numberWithCommas(value.toFixed(0)),
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </div>
                  {statData2.map((item, index) => (
                    <div
                      key={index}
                      className="at-form__content"
                      style={{ padding: 12 }}
                    >
                      <div
                        className="at-card--stat__general-icon-cont mx-auto"
                        style={{
                          backgroundColor: item.color,
                          width: "fit-content",
                        }}
                      >
                        <AtlasIcon svgHref={`atlas-${item.icon}`} />
                      </div>
                      <div className="at-card--stat__general-category mt-2 d-flex flex-column justify-content-center align-items-center mb-0">
                        <p className="text-center">{item.label}</p>
                        <span className="d-flex flex-column justify-content-center align-items-center">
                          {summaryData[item.value]}
                          {item.value === "targetSales" && (
                            <span
                              className="at-status_badge fs-2 mt-2 mx-auto"
                              style={{ ...getColorBadge("Lime") }}
                            >
                              +{this.props.numberValue}% vs last year
                            </span>
                          )}
                          {item.value === "difference" && (
                            <p className="fs-2">vs last year</p>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="at-recruit__card-stat3 mt-3">
                  {statData3.map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: 12, marginBottom: 0 }}
                      className="d-flex w-100"
                    >
                      <div
                        className="at-card--stat__general-icon-cont"
                        style={{ backgroundColor: item.color }}
                      >
                        <AtlasIcon svgHref={`atlas-${item.icon}`} />
                      </div>
                      <div className="ml-3">
                        <label className="mb-2">{item.label}</label>
                        <span>
                          <p>Yearly</p>
                          {periodData[item.value].yearly}
                        </span>
                        <span>
                          <p>Monthly</p>
                          {periodData[item.value].monthly}
                        </span>
                        <span>
                          <p>Weekly</p>
                          {periodData[item.value].weekly}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <CustomButton
                  className="btn-new btn-new--secondary mt-3 mx-auto"
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-repeat"}
                        style={{
                          height: 18,
                          width: 18,
                          marginRight: 5,
                          fill: "white",
                        }}
                      />
                      Calculate Again
                    </>
                  }
                  onClick={() =>
                    this.props.onChangeRecruitmentHOC(false, "showGrowthResult")
                  }
                />
              </div>
            )}
            {!this.props.showGrowthResult && (
              <div className="mx-auto" style={{ width: "fit-content" }}>
                <div
                  className="at-card--stat__general-icon-cont mx-auto"
                  style={{ backgroundColor: "#3B82F6", width: "fit-content" }}
                >
                  <AtlasIcon svgHref={`atlas-calculator`} />
                </div>
                <p className="at-form-input__title text-center mt-3">
                  Expected team sales growth next year (%)
                </p>
                <CustomFormInput
                  type="number"
                  className="my-2"
                  value={this.props.numberValue}
                  onChangeValue={(val) =>
                    this.props.onChangeRecruitmentHOC(val, "numberValue")
                  }
                />
                <CustomButton
                  disabled={checkNumberNull(this.props.numberValue)}
                  onClick={() => this.processOutcome("CALC")}
                  className="btn-new btn-new--primary mx-auto"
                  children={
                    <>
                      <BsCheck
                        style={{ height: 18, width: 18, marginRight: 5 }}
                      />
                      Calculate
                    </>
                  }
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Recruitment Growth Targets"}
          atlasIcon={"atlas-user-octogan"}
          description={`This report helps you to summarise your team project performance. Which will allow you to analyse your team focus, 
            strength and weakness in different type of project and target market. `}
          showPABadge={role === "Personal Assistant"}
          infoBoxInactiveContent={
            "How to calculate team sales growth for next year"
          }
          infoBoxContent={this.renderRecruitmentGrowthTips}
          backButton={{
            onShow: ["Super Admin", "Admin"].indexOf(role) > -1,
            onClick: () => this.props.clickBack(),
          }}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadRecruitment && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  VersionCheckHOC,
  RecruirtmentHOC,
)(RecruitmentGrowthTarget);
