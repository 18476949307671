import _ from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { BsThreeDots, BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import { Collapse } from "@material-ui/core";

import AlertBox from "components/AlertBox";
import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import DeleteModal from "components/Modal/delete";
import EditFacebookLeads from "./editFacebookLeads";

import { leadsOptions } from "../utils";
import { AgentLeadType } from "dictionary/lead";
import { requestError } from "utils/requestHandler";
import FacebookLeadsHOC from "../actions/facebookLeads";

const FacebookLeads = ({
  userAccount,
  leadGenForm,
  atlasAccount,
  longLiveToken,
  showLeadsModal,
  showRemoveModal,
  showEditLeadForm,
  showFormOptions,
  showAccountOptions,
  showCreateTestForm,
  showLoginValidModal,
  showDisconnectModal,
  showLoginExpiredModal,
  showUnsubscribeModal,
  selectedAccount,
  selectedLeadForm,
  existingLeadForm,
  onLoadLeadSetting,
  selectedEditLeadForm,

  facebookLogin,
  getLeadGenForm,
  subscribePage,
  unsubscribePage,
  getLongLiveToken,
  checkFacebookLogin,
  createTestLeadForm,
  removeLongLiveToken,
  getConnectedLeadForm,
  createConnectedLeadForm,
  removeConnectedLeadForm,
  updateConnectedLeadForm,
  updateNotificationSetting,
  onChangeLeadSetting,
}) => {
  const isMobile = window.innerWidth < 768;
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);

  useEffect(() => {
    getLongLiveToken();
    document.addEventListener(
      "click",
      (e) => {
        if (e.target.id !== "account-options-button") {
          onChangeLeadSetting(false, "showAccountOptions");
        }
        if (e.target.id !== "form-options-button") {
          onChangeLeadSetting(false, "showFormOptions");
        }
      },
      true,
    );
  }, []);

  const onToggleAccount = (param) => {
    if (param.value === selectedAccount.id) {
      onChangeLeadSetting([], "existingLeadForm");
      onChangeLeadSetting([], "selectedLeadForm");
      onChangeLeadSetting("", "selectedAccount");
    } else {
      let temp = _.find(userAccount, { id: param.value });
      temp.is_subscribed = _.find(atlasAccount, {
        fb_id: param.value,
      })?.is_subscribed;
      getConnectedLeadForm();
      onChangeLeadSetting(temp, "selectedAccount");
    }
  };

  const onToggleExpand = (index, val) => {
    let temp = _.cloneDeep(leadGenForm);
    temp[index].expand = val;
    onChangeLeadSetting(temp, "leadGenForm");
  };

  const onChangeSelectedLead = (param, expandIndex) => {
    let temp = _.cloneDeep(selectedLeadForm);
    let index = _.findIndex(temp, { form_id: param.id });
    if (index > -1) {
      temp.splice(index, 1);
      onToggleExpand(expandIndex, false);
    } else {
      temp.push({
        form_id: param.id,
        form_name: param.name,
        created_at: param.created_time,
        questions: { data: param.questions },
        column_mapping: param.columnMapping,
        page_id: selectedAccount.id,
      });
      onToggleExpand(expandIndex, true);
    }
    onChangeLeadSetting(temp, "selectedLeadForm");
  };

  const onChangeEditLead = (val, context) => {
    let temp = _.cloneDeep(selectedEditLeadForm);
    temp.column_mapping[context] = val;
    onChangeLeadSetting(temp, "selectedEditLeadForm");
  };

  const onToggleUnsubscribe = (page) => {
    setSelectedPage(page);
    onChangeLeadSetting(true, "showUnsubscribeModal");
  };

  const onToggleFieldMapping = (item, key, field) => {
    let temp = _.cloneDeep(leadGenForm);
    let index = _.findIndex(temp, { id: item.id });
    temp[index].columnMapping[key] = field.value;
    temp[index].requiredError = false;
    onChangeLeadSetting(temp, "leadGenForm");

    let selectedTemp = _.cloneDeep(selectedLeadForm);
    let selectedIndex = _.findIndex(selectedTemp, { form_id: item.id });
    if (selectedIndex > -1) {
      selectedTemp[selectedIndex].column_mapping[key] = field.value;
    } else {
      selectedTemp.push({
        form_id: item.id,
        form_name: item.name,
        created_at: item.created_time,
        page_id: selectedAccount.id,
        questions: { data: item.questions },
        column_mapping: {
          [key]: field.value,
        },
      });
    }
    onChangeLeadSetting(selectedTemp, "selectedLeadForm");
  };

  const onClickConnect = () => {
    let requiredChecker = _.map(selectedLeadForm, (item) => {
      return _.isNull(item.column_mapping.type_id) ? item.form_id : "";
    });

    if (_.values(requiredChecker).every((x) => x === "")) {
      createConnectedLeadForm();
    } else {
      let temp = _.cloneDeep(leadGenForm);
      temp = _.map(temp, (item) => {
        if (_.includes(requiredChecker, item.id)) {
          return {
            ...item,
            expand: true,
            requiredError: true,
          };
        }
        return item;
      });
      onChangeLeadSetting(temp, "leadGenForm");
      requestError(
        "Lead Type is required. Please select the lead type for all selected lead form.",
      );
    }
  };

  const renderDropDown = () => (
    <div
      id="account-options"
      className={"at-party__dropbear--modal-root"}
      style={{
        left: "auto",
        right: 10,
        width: 200,
        marginTop: 10,
        display: showAccountOptions ? "block" : "none",
      }}
    >
      <div className={`at-party__dropbear--list`}>
        <button onClick={() => checkFacebookLogin()}>
          <AtlasIcon
            svgHref={"atlas-mirrorring-screen"}
            style={{ width: 20, height: 20, marginRight: 10, fill: "black" }}
          />
          Test Connection
        </button>
        <button onClick={() => facebookLogin()}>
          <AtlasIcon
            svgHref={"atlas-cloud-change"}
            style={{ width: 20, height: 20, marginRight: 10, fill: "black" }}
          />
          Reconnect
        </button>
      </div>
    </div>
  );

  const renderFormDropDown = (item) => (
    <div
      id="form-options"
      className={"at-party__dropbear--modal-root"}
      style={{
        left: "auto",
        right: 10,
        width: 200,
        marginTop: 5,
        display: showFormOptions === item.id ? "block" : "none",
      }}
    >
      <div className={`at-party__dropbear--list`}>
        <button
          onClick={() =>
            Promise.all([setSelectedForm(item)]).then(() => {
              onChangeLeadSetting(true, "showCreateTestForm");
            })
          }
        >
          <AtlasIcon
            svgHref={"atlas-mirrorring-screen"}
            style={{ width: 20, height: 20, marginRight: 10, fill: "#3B82F6" }}
          />
          Create Test Lead
        </button>
        <button
          onClick={() =>
            Promise.all([
              onChangeLeadSetting(item, "selectedEditLeadForm"),
            ]).then(() => {
              onChangeLeadSetting(true, "showEditLeadForm");
            })
          }
        >
          <BsPencilSquare
            style={{ width: 20, height: 20, marginRight: 10, color: "#3B82F6" }}
          />
          Edit
        </button>
        <button
          style={{ color: "#F04438" }}
          onClick={() =>
            Promise.all([setSelectedForm(item)]).then(() => {
              onChangeLeadSetting(true, "showRemoveModal");
            })
          }
        >
          <BsTrash3Fill
            style={{ width: 20, height: 20, marginRight: 10, color: "#F04438" }}
          />
          Disconnect
        </button>
      </div>
    </div>
  );

  return (
    <>
      <p className="fs-2">
        Connect your Facebook Lead Ads account to send all new lead to Atlas.
      </p>
      <hr />
      <div className="mb-2">
        <label className="at-form-input__title mb-2">Account (required)</label>
        {showLoginValidModal && (
          <div className="position-relative">
            <button
              className="btn-new"
              style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }}
              onClick={() => onChangeLeadSetting(false, "showLoginValidModal")}
            >
              <AtlasIcon
                svgHref={"atlas-X"}
                style={{ width: 16, height: 16 }}
              />
            </button>
            <AlertBox
              mode={"success"}
              disabledClose={true}
              description={"Your Facebook Lead Ads account is connected."}
            />
          </div>
        )}
        {showLoginExpiredModal && (
          <div className="position-relative">
            <button
              className="btn-new"
              style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }}
              onClick={() =>
                onChangeLeadSetting(false, "showLoginExpiredModal")
              }
            >
              <AtlasIcon
                svgHref={"atlas-X"}
                style={{ width: 16, height: 16 }}
              />
            </button>
            <AlertBox
              mode={"danger"}
              disabledClose={true}
              description={
                "Your Facebook Lead Ads account is not connected. Please reconnect your Facebook Lead Ads account to continue."
              }
            />
          </div>
        )}
        {longLiveToken && (
          <div className={"at-lead-setting__account"}>
            <div className="d-flex align-items-center">
              <AtlasIcon
                svgHref={"atlas-facebook"}
                className={"at-lead-setting__facebook-icon"}
              />
              <h2 className="at-form-input__title mb-0">
                Facebook Lead Account {longLiveToken.fb_user_info?.name ?? ""}
              </h2>
            </div>
            <div className="d-flex align-items-center ml-auto">
              <button
                style={{ padding: "6px 12px", fontSize: 14, color: "#F04438" }}
                className="btn-new-sm btn-new--outline-secondary ml-2"
                onClick={() => onChangeLeadSetting(true, "showDisconnectModal")}
              >
                <span>Disconnect</span>
              </button>
              <button
                id="account-options-button"
                style={{ width: 32, height: 32, padding: 0 }}
                className={"btn-new-sm btn-new--outline-secondary ml-2"}
                onClick={() =>
                  onChangeLeadSetting(!showAccountOptions, "showAccountOptions")
                }
              >
                <BsThreeDots style={{ width: 16, height: 16 }} />
              </button>
              {renderDropDown()}
            </div>
          </div>
        )}
      </div>
      {longLiveToken && userAccount.length > 0 && (
        <>
          <div className="mt-3">
            <label className="at-form-input__title mb-2">Page (required)</label>
            <CustomSelect
              className={"w-100"}
              selectItems={userAccount?.map((account) => ({
                label: account.name,
                value: account.id,
                is_subscribed: _.find(atlasAccount, { fb_id: account.id })
                  ?.is_subscribed,
              }))}
              placeholder={"Select Facebook Page"}
              updateSelect={(item) => onToggleAccount(item)}
              renderOptions={(option) => (
                <div className="at-lead-setting__account-item">
                  <p>{option.label}</p>
                  <span>{option.is_subscribed ? "subscribed" : ""}</span>
                </div>
              )}
              currentlySelected={
                selectedAccount
                  ? { label: selectedAccount.name, value: selectedAccount.id }
                  : ""
              }
            />
          </div>
          {!_.isEmpty(selectedAccount) && (
            <div className="my-3">
              {!selectedAccount.is_subscribed && (
                <div className="d-flex flex-column g-3">
                  <p className="fs-2">
                    Subscribe to view and connect lead form from page above.
                  </p>
                  <button
                    style={{ width: "fit-content" }}
                    className="btn-new btn-new--primary"
                    onClick={() => subscribePage(selectedAccount.id)}
                  >
                    Subscribe
                  </button>
                </div>
              )}
              {selectedAccount.is_subscribed && (
                <>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                    <label className="at-form-input__title mr-2">
                      Connected Forms
                      <br />
                      <span style={{ fontSize: 12, fontWeight: 400 }}>
                        From {selectedAccount.name}
                      </span>
                    </label>
                    <div
                      style={{ flex: "1 1 0" }}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <button
                        style={{ width: "max-content" }}
                        className={"btn-new btn-new--secondary"}
                        onClick={() =>
                          getLeadGenForm(selectedAccount.access_token)
                        }
                      >
                        <AtlasIcon
                          svgHref={"atlas-add-linear"}
                          style={{
                            width: 18,
                            height: 18,
                            fill: "white",
                          }}
                        />
                        Connect forms
                      </button>
                      <button
                        style={{ color: "#F04438" }}
                        className="btn-new btn-new--outline-secondary"
                        onClick={() => onToggleUnsubscribe(selectedAccount)}
                      >
                        Unsubscribe
                      </button>
                    </div>
                  </div>
                  {existingLeadForm.length > 0 &&
                    existingLeadForm.map((item, index) => {
                      if (item.page_id === selectedAccount.id) {
                        return (
                          <div
                            key={`lead-form-${index}`}
                            className={"at-lead-setting__account"}
                          >
                            <div className="d-flex align-items-center">
                              <AtlasIcon
                                svgHref={"atlas-facebook"}
                                className={"at-lead-setting__facebook-icon"}
                              />
                              <div>
                                <h2
                                  className="at-form-input__title"
                                  style={{
                                    color: "#1F2937",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {item.form_name}
                                </h2>
                                <p
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#374151",
                                  }}
                                >
                                  Created at:{" "}
                                  {item.created_at
                                    ? Moment(item.created_at).format(
                                        "DD MMM YYYY, HH:mm",
                                      )
                                    : ""}
                                </p>
                              </div>
                            </div>
                            {!isMobile && (
                              <div className="d-flex align-items-center g-2">
                                <CustomButton
                                  className={"at-lead-setting__account-action"}
                                  tooltip={true}
                                  tooltipPosition={"top"}
                                  tooltipChildren={"Create Test Lead"}
                                  children={
                                    <AtlasIcon
                                      svgHref={"atlas-mirrorring-screen"}
                                      style={{
                                        width: 16,
                                        height: 16,
                                        fill: "#3B82F6",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    Promise.all([setSelectedForm(item)]).then(
                                      () => {
                                        onChangeLeadSetting(
                                          true,
                                          "showCreateTestForm",
                                        );
                                      },
                                    )
                                  }
                                />
                                <CustomButton
                                  className={"at-lead-setting__account-action"}
                                  tooltip={true}
                                  tooltipPosition={"top"}
                                  tooltipChildren={"Edit"}
                                  children={
                                    <BsPencilSquare
                                      style={{
                                        width: 16,
                                        height: 16,
                                        color: "#3B82F6",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    Promise.all([
                                      onChangeLeadSetting(
                                        item,
                                        "selectedEditLeadForm",
                                      ),
                                    ]).then(() => {
                                      onChangeLeadSetting(
                                        true,
                                        "showEditLeadForm",
                                      );
                                    })
                                  }
                                />
                                <CustomButton
                                  className={"at-lead-setting__account-action"}
                                  tooltip={true}
                                  tooltipPosition={"top"}
                                  tooltipChildren={"Disconnect"}
                                  children={
                                    <BsTrash3Fill
                                      style={{
                                        width: 16,
                                        height: 16,
                                        color: "#F04438",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    Promise.all([setSelectedForm(item)]).then(
                                      () => {
                                        onChangeLeadSetting(
                                          true,
                                          "showRemoveModal",
                                        );
                                      },
                                    )
                                  }
                                />
                              </div>
                            )}
                            {isMobile && (
                              <>
                                <button
                                  id="form-options-button"
                                  style={{ width: 32, height: 32, padding: 0 }}
                                  className={
                                    "btn-new-sm btn-new--outline-secondary ml-2"
                                  }
                                  onClick={() => {
                                    if (showFormOptions) {
                                      onChangeLeadSetting(
                                        false,
                                        "showFormOptions",
                                      );
                                    } else {
                                      onChangeLeadSetting(
                                        item.id,
                                        "showFormOptions",
                                      );
                                    }
                                  }}
                                >
                                  <BsThreeDots
                                    style={{ width: 16, height: 16 }}
                                  />
                                </button>
                                {renderFormDropDown(item)}
                              </>
                            )}
                          </div>
                        );
                      }
                    })}
                  {existingLeadForm.length === 0 && (
                    <EmptyState
                      size={"md"}
                      title={"No lead form connected"}
                      description={
                        'Get started by clicking on the "Connect forms" button'
                      }
                      renderIcon={<AtlasIcon svgHref={"atlas-document-text"} />}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
      {!longLiveToken && (
        <button
          className="btn-new btn-new--secondary mt-2"
          onClick={() => facebookLogin()}
        >
          <AtlasIcon
            svgHref={"atlas-facebook"}
            style={{ width: 20, height: 20, fill: "white" }}
          />
          <span>Sign in to Facebook Lead Ads</span>
        </button>
      )}
      {showCreateTestForm && (
        <ModalDialog
          title={"Lead Ads Testing Tool"}
          onLoad={onLoadLeadSetting}
          children={
            <>
              <p className="fs-2">
                You can create a test lead to confirm that your integration is
                set up to receive data correctly.
              </p>
              <br />
              <p className="fs-2">
                You can refresh the page and check the latest leads after your
                test lead has been sent successfully.
              </p>
              <div className="d-flex justify-content-center mt-20">
                <CustomButton
                  className="btn-new btn-new--secondary mr-2"
                  children={"Create Lead"}
                  onClick={() =>
                    createTestLeadForm({
                      form_id: selectedForm.form_id,
                      page_id: selectedForm.page_id,
                    })
                  }
                />
                <CustomButton
                  className="btn-new btn-new--outline-secondary"
                  children={"Cancel"}
                  onClick={() =>
                    onChangeLeadSetting(false, "showCreateTestForm")
                  }
                />
              </div>
            </>
          }
          onClose={() => onChangeLeadSetting(false, "showCreateTestForm")}
        />
      )}
      {showEditLeadForm && (
        <EditFacebookLeads
          onLoadLeadSetting={onLoadLeadSetting}
          selectedEditLeadForm={selectedEditLeadForm}
          onChangeEditLead={onChangeEditLead}
          onChangeLeadSetting={onChangeLeadSetting}
          updateConnectedLeadForm={updateConnectedLeadForm}
          updateNotificationSetting={updateNotificationSetting}
        />
      )}
      {showLeadsModal && (
        <ModalDialog
          title={"Connect Leads Form"}
          responsiveSize={"lg"}
          onLoad={onLoadLeadSetting}
          children={
            <>
              <p className="at-form-input__title">
                From Lead Ads Page {selectedAccount.name}
              </p>
              <p className="fs-2 mb-10">
                Please select a form from below to connect leads and send them
                to Atlas.
              </p>
              {leadGenForm.length > 0 &&
                leadGenForm.map((item, itemIndex) => {
                  let selected = _.find(selectedLeadForm, { form_id: item.id });

                  return (
                    <div
                      key={`lead-gen-form-${itemIndex}`}
                      className={`at-form__content ${selected ? "at-lead-setting__acount-selected" : ""}`}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100 mb-2">
                          <AtlasIcon
                            svgHref={"atlas-facebook"}
                            className={"at-lead-setting__facebook-icon"}
                          />
                          <div
                            className="w-100"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              onToggleExpand(itemIndex, !item.expand)
                            }
                          >
                            <h2
                              className="at-form-input__title w-100"
                              style={{ wordBreak: "break-all" }}
                            >
                              {item.name || ""}
                            </h2>
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#374151",
                              }}
                            >
                              Created at:{" "}
                              {item.created_time
                                ? Moment(item.created_time).format(
                                    "DD MMM YYYY, HH:mm",
                                  )
                                : ""}
                            </p>
                          </div>
                        </div>
                        <AtlasIcon
                          svgHref={
                            selected
                              ? "atlas-tick-circle"
                              : "atlas-tick-circle-linear"
                          }
                          onClick={() => onChangeSelectedLead(item, itemIndex)}
                          style={{
                            width: 20,
                            height: 20,
                            fill: selected ? "#3B82F6" : "#000000",
                            marginLeft: 10,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <Collapse in={item.expand} timeout="auto" unmountOnExit>
                        <hr />
                        <p className="fs-2 my-2">
                          Please select the field in Atlas where you would like
                          to store the information from your lead form field.{" "}
                          <br />
                          Lead Type and the contact information is required.
                        </p>
                        {Object.keys(item.columnMapping).map((key, index) => {
                          let temp = _.find(item.questions, { key: key });

                          return (
                            <div
                              key={`column-mapping-${index}`}
                              className="mb-1 p-1 "
                              style={{
                                backgroundColor: "#F6F7F8",
                                borderRadius: 8,
                              }}
                            >
                              <div
                                key={`column-mapping-${index}`}
                                className="row d-flex align-items-center mx-1"
                              >
                                <div className="col-sm-4 d-flex align-items-center pr-0">
                                  <span className="fs-2">{temp.label}</span>
                                  <AtlasIcon
                                    svgHref={"atlas-arrow-right-1-linear"}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      marginLeft: "auto",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-8 pr-0">
                                  <CustomSelect
                                    className={"w-100"}
                                    selectItems={
                                      key === "type_id"
                                        ? AgentLeadType
                                        : leadsOptions
                                    }
                                    placeholder={"Select"}
                                    updateSelect={(field) =>
                                      onToggleFieldMapping(item, key, field)
                                    }
                                    currentlySelected={_.find(
                                      key === "type_id"
                                        ? AgentLeadType
                                        : leadsOptions,
                                      { value: item.columnMapping[key] },
                                    )}
                                  />
                                </div>
                              </div>
                              {item.requiredError && key === "type_id" && (
                                <p className="fw-600 fs-1 mr-1 text-danger text-right">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                })}
              <div className="at-modal_dialog-container-footer g-3">
                <CustomButton
                  disabled={selectedLeadForm.length === 0}
                  className="btn-new btn-new--primary"
                  children={"Connect"}
                  onClick={() => onClickConnect()}
                />
                <CustomButton
                  className="btn-new btn-new--outline-secondary"
                  children={"Cancel"}
                  onClick={() => onChangeLeadSetting(false, "showLeadsModal")}
                />
              </div>
            </>
          }
          onClose={() => onChangeLeadSetting(false, "showLeadsModal")}
        />
      )}
      <DeleteModal
        open={showDisconnectModal}
        loading={onLoadLeadSetting}
        message={
          "All the connected forms under this Facebook account will be removed. Are you sure you want to disconnect from Facebook?"
        }
        title={"Disconnect Facebook Account"}
        positiveAction={() => removeLongLiveToken()}
        negativeAction={() => onChangeLeadSetting(false, "showDisconnectModal")}
        positiveText={"Disconnect"}
        negativeText={"Cancel"}
      />
      <DeleteModal
        open={showUnsubscribeModal}
        loading={onLoadLeadSetting}
        message={
          "All the connected forms under this Facebook Page will be removed. Are you sure you want to unsubscribe this page?"
        }
        title={"Unsubscribe Facebook Page"}
        positiveAction={() => unsubscribePage(selectedPage.id)}
        negativeAction={() =>
          onChangeLeadSetting(false, "showUnsubscribeModal")
        }
        positiveText={"Unsubscribe"}
        negativeText={"Cancel"}
      />
      <DeleteModal
        open={showRemoveModal}
        loading={onLoadLeadSetting}
        message={"Are you sure you want to disconnect this lead form?"}
        title={"Disconnect Lead Form"}
        positiveAction={() => removeConnectedLeadForm(selectedForm.id)}
        negativeAction={() => onChangeLeadSetting(false, "showRemoveModal")}
        positiveText={"Disconnect"}
        negativeText={"Cancel"}
      />
      {onLoadLeadSetting && <LoadingModal />}
    </>
  );
};

export default FacebookLeadsHOC(FacebookLeads);
