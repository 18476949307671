import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showUnlockRequestDetailsModal: false,
      showRejectRequestModal: false,

      unlockRequests: [],
      selectedUnlockRequest: {},
      rejectReason: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeUnlockHOC = (val, context) => this.setState({ [context]: val });

    getUnlockRequest = (id) =>
      Get(
        `/admin/forms/confirmation_forms/${id}/unlock_requests`,
        this.getUnlockRequestSuccess,
        this.getUnlockRequestError,
        this.load,
      );
    getUnlockRequestSuccess = (payload) =>
      this.setState({ unlockRequests: payload });
    getUnlockRequestError = (error) => requestError(error);

    getSelectedUnlockRequest = (confirmationId, requestId) =>
      Get(
        `/admin/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}`,
        this.getSelectedUnlockRequestSuccess,
        this.getSelectedUnlockRequestError,
        this.load,
      );
    getSelectedUnlockRequestSuccess = (payload) =>
      this.setState({ selectedUnlockRequest: payload }, () =>
        this.setState({ showUnlockRequestDetailsModal: true }),
      );
    getSelectedUnlockRequestError = (error) => requestError(error);

    updateUnlockRequest = (dataToSubmit, confirmationId, requestId) =>
      Put(
        `/admin/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}`,
        dataToSubmit,
        this.updateUnlockRequestSuccess,
        this.updateUnlockRequestError,
        this.load,
      );
    updateUnlockRequestSuccess = () => {
      requestSuccess("Unlock request updated successfully.");
      this.setState({ showRejectRequestModal: false });
      this.getUnlockRequest(this.props.selectedConfirmationForm.id);
      this.getSelectedUnlockRequest(
        this.props.selectedConfirmationForm.id,
        this.state.selectedUnlockRequest.id,
      )
      this.props.getConfirmationForm(this.props.selectedConfirmationForm.id);
    };
    updateUnlockRequestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadUnlock={this.state.loading}
            rejectReason={this.state.rejectReason}
            unlockRequests={this.state.unlockRequests}
            showRejectRequestModal={this.state.showRejectRequestModal}
            showUnlockRequestDetailsModal={
              this.state.showUnlockRequestDetailsModal
            }
            selectedUnlockRequest={this.state.selectedUnlockRequest}
            onChangeUnlockHOC={this.onChangeUnlockHOC}
            getUnlockRequest={this.getUnlockRequest}
            updateUnlockRequest={this.updateUnlockRequest}
            getSelectedUnlockRequest={this.getSelectedUnlockRequest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
