import React from "react";
import _ from "lodash";
import { BsInfoCircleFill } from "react-icons/bs";
import { Avatar } from "@material-ui/core";

import AlertBox from "components/AlertBox";
import CustomBox from "components/Card/projectCard";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";

import { 
  agentContents, 
  personalContents, 
  addressContents, 
  bankContents, 
  amlaPersonalContents,
} from "../assets";

const ConfirmationBuyerDetail = ({ 
  dataInfo,
  selectedForm,
}) => {

  const DataField = ({className,label,value}) => (
    <div className={className}>
      <p style={{fontSize:14,fontWeight:600}}>{label}</p>
      <p style={{fontSize:14,fontWeight:400}}>{value}</p>
    </div>
  );

  const DocumentField = ({ className, label, fileName, fileLink }) => (
    <div className={className}>
      <p style={{fontSize:14,fontWeight:600}}>{label}</p>
      {
        fileLink ? (
          <div className="d-flex" style={{alignItems: "center", gap: 8}}>
            <span style={{fontSize:14,fontWeight:400}}>{fileName}</span>
            <CustomButton
              className={"at-table-icon-button"}
              onClick={() => window.open(fileLink, '_blank')}
              children={<BsInfoCircleFill style={{ width: 20, height: 20, color: "#007BFF" }} />}
            />
          </div>
        ) : <p style={{fontSize:14,fontWeight:400}}>-</p>
      }
    </div>
  );

  const formType = selectedForm === 'Confirmation Forms' 
  ? dataInfo.form_type 
  : dataInfo.claim_type;

  const formDate = selectedForm === 'Confirmation Forms' 
    ? `Confirmation Date: ${dataInfo.confirmation_date}` 
    : `SPA Date / TA Date: ${dataInfo.agreement_date}`;

  return (
    <>
      <div className="d-flex mb-3">
        <Avatar
          style={{
            backgroundColor: "#FFEDD5",
            marginRight: 12,
            width: 32,
            height: 32,
          }}
        >
          <AtlasIcon
            svgHref={`atlas-money`}
            style={{
              width: 16,
              height: 16,
              fill: "#F0631D",
            }}
          />
        </Avatar> 
        <div className="d-flex flex-column align-items-start gap-2">
          <div className="d-flex"> 
            <spam style={{ alignSelf: "end", fontSize: 18, fontWeight:600, marginRight: 8 }}>
              {dataInfo.form_no}
            </spam>
            <div
              className="at-status_badge"
              style={{ ...getColorBadge(`${formType === 'Rental' ? "Yellow": "Blue"}`) }}
            >
              {formType}
            </div>
          </div>
          <div style={{marginTop: 8}}>
            <spam style={{fontSize: 14, fontWeight:400}}>
              {formDate}
            </spam>
          </div>
        </div>        
      </div>
      <CustomBox icon={"money"} title={"Agent Details"}>
        <div className="row at-cdd-field_col">
          {
            agentContents.map((content,index) => {
              return (
                <DataField
                  key={`agent_${content.value}`}
                  className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-3-col`}
                  label={content.label}
                  value={dataInfo["agent"][content.value] || 'N/A'}
                />
              )
            })
          }
        </div>
      </CustomBox>
      <CustomBox icon={"money"} title={"Personal Details"}>
        <div className="row at-cdd-field_col">
          {
            personalContents(selectedForm).map((content,index) => {
              let value = dataInfo[content.value]
                ? content.value === 'gender' 
                  ? ( dataInfo[content.value] === 'F' ? 'Female' : 'Male' )
                  : dataInfo[content.value]
                : 'N/A';
              return (
                <DataField
                  key={content.value}
                  className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-${selectedForm === 'Confirmation Forms' ? 2 : 3 }-col`}
                  label={content.label}
                  value={value}
                />
              )
            })
          }
        </div>
      </CustomBox>
      <CustomBox icon={"money"} title={"Current Residence Address"}>
        <div className="row at-cdd-field_col">
          {
            addressContents.map((content,index) => (
              <DataField
                key={content.value}
                className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-2-col`}
                label={content.label}
                value={dataInfo[content.value] || 'N/A'}
              />
            ))
          }
        </div>
      </CustomBox>
      {
        (selectedForm === 'Subsales Claims') && (
          <CustomBox icon={"money"} title={"Bank Details"}>
            <div className="row at-cdd-field_col">
              {
                bankContents.map((content) => (
                  <DataField
                    key={content.value}
                    className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-3-col`}
                    label={content.label}
                    value={dataInfo[content.value] || 'N/A'}
                  />
                ))
              }
            </div>
          </CustomBox>
        )
      }
      { 
        (selectedForm === 'Subsales Claims' && dataInfo['declined_to_share_info'] === "No") && (
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"} containerClass={'mb-0'}>
            <div className="row at-cdd-field_col">
              {
                amlaPersonalContents(selectedForm).map((content,index) => {
                  const value = dataInfo[content.value] || 'N/A';
                  return (
                    <DataField 
                      key={content.value} 
                      className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-3-col'} 
                      label={content.label} 
                      value={value} 
                    />
                  )
                })
              }
              <DocumentField
                className={'col-xl-4 col-lg-4 col-md-4 text-left mb-0 at-cdd-last-3-col'}
                label={'IC / Passport'}
                fileName={dataInfo['identity_copy_file_name']}
                fileLink={dataInfo['identity_copy']}
              />
            </div>
          </CustomBox>
        )
      }
      {
        (selectedForm === 'Subsales Claims' && dataInfo['declined_to_share_info'] === "Represented by Co-Agency") && (
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"}>
            <AlertBox
              mode={"info"}
              disabledClose={true}
              containerStyle={{marginBottom: 0}}
              description={"Represented by Co-Agency"}
            />
          </CustomBox>
        )
      }
      { 
        (dataInfo['declined_to_share_info'] === "Yes") && (
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"}>
            <AlertBox
              mode={"info"}
              disabledClose={true}
              containerStyle={{marginBottom: 0}}
              description={"Customer declined to share information"}
            />
            <div className="row mt-3 at-cdd-field_col">
              <DocumentField
                key={'cdd-supporting_doc'}
                className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-2-col'}
                label={'Supporting Document'}
                fileName={dataInfo['supporting_document_file_name']}
                fileLink={dataInfo['supporting_document']}
              />
            </div> 
          </CustomBox>
        )
      }

      {
        ( selectedForm === 'Confirmation Forms' && dataInfo['declined_to_share_info'] === "No") && (
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"} containerClass={'mb-0'}>
            <div className="row at-cdd-field_col">
              {
                amlaPersonalContents(selectedForm).map((content, index) => {
                  const value = dataInfo[content.value] || 'N/A';
                  return (
                    <DataField
                      key={content.value}
                      className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-3-col'}
                      label={content.label}
                      value={value}
                    />
                  );
                })
              }
              <DocumentField
                key={'cdd-identity_copy'}
                className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-3-col'}
                label={'IC/Passport'}
                fileName={dataInfo['identity_copy_file_name']}
                fileLink={dataInfo['identity_copy']}
              />
            </div>
          </CustomBox>
        )
      }
    </>
  )
}

export default ConfirmationBuyerDetail;
