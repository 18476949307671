import React, { Component } from "react";
import { compose } from "redux";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import DetailsContent from "./Details";

import OTPVerificationHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

const columnData = [
  { header: "Recipient", accessor: "recipient" },
  { header: "Type", accessor: "type" },
  { header: "Code", accessor: "code" },
  { header: "Expired at", accessor: "updated_datetime" },
];

class OTPVerification extends Component {
  componentDidMount = () => this.props.getVerifications(1, "");

  renderCardContent = () => {
    return (
      <AtPaginatedTable
        rowData={this.props.verifications.data || []}
        meta={this.props.verifications.meta}
        showCloseButton={false}
        showCreateButton={false}
        columns={columnData}
        actionColumnContent={[
          {
            name: "details",
            onClick: (rowData) =>
              this.props.getSelectedVerification(rowData.id),
          },
        ]}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeVerificationHOC(val, "searchParams")
        }
        getListAPI={this.props.getVerifications}
        totalPages={this.props.verificationPage}
      />
    );
  };

  render = () => {
    const { showDetailsModal, selectedVerification } = this.props;

    return (
      <>
        <ModuleHeader
          title={"OTP Verification"}
          atlasIcon={"atlas-shield-tick"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {showDetailsModal && (
          <ModalDialog
            title={"Verification Details"}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeVerificationHOC(false, "showDetailsModal")
            }
            children={
              <DetailsContent selectedVerification={selectedVerification} />
            }
          />
        )}
        {this.props.onLoadVerification && <LoadingModal />}
      </>
    );
  };
}

export default compose(OTPVerificationHOC, VersionCheckHOC)(OTPVerification);
