import React from "react";
import { FaFacebookF, FaTwitter, FaYoutube, FaPinterest } from "react-icons/fa";

const DetailsFooter = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-2">
            <h6
              className="heading-title footer-title"
              style={{ padding: "0 15px" }}
            >
              CONTACTS
            </h6>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-7">
                  <div className="icon-list footer-address">
                    <ul>
                      <li>
                        <span
                          className="icon-list-icon"
                          style={{ width: 100, height: "100%" }}
                        >
                          ADDRESS:
                        </span>
                        <div className="address-text">
                          <span
                            className="icon-list-text"
                            style={{ margin: 0, padding: 0, width: "100%" }}
                          >
                            26th -28th Floor, Tower D, Millerz Square,
                          </span>
                          <br />
                          <span
                            className="icon-list-text"
                            style={{ margin: 0, padding: 0, width: "100%" }}
                          >
                            No. 357, Jalan Kelang Lama, 58000 Kuala Lumpur
                          </span>
                        </div>
                      </li>
                      <li>
                        <span className="icon-list-icon" style={{ width: 100 }}>
                          PHONE:
                        </span>
                        <span
                          className="icon-list-text"
                          style={{ margin: 0, padding: 0 }}
                        >
                          +(60)3 7453 5155
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5">
                  <div className="icon-list footer-address">
                    <ul>
                      <li>
                        <span className="icon-list-icon">
                          CORPORATE ENQUIRIES:
                        </span>
                        <span className="icon-list-text">
                          info@iqiglobal.com
                        </span>
                      </li>
                      <li>
                        <span className="icon-list-icon">
                          REAL ESTATE ENQUIRIES:
                        </span>
                        <span className="icon-list-text">
                          hello@iqirealty.com
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="section-3">
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iqi-logo">
                    <a href="https://www.iqiglobal.com">
                      <img
                        alt="IQI Footer Logo"
                        src="https://www.iqiglobal.com/img/iqi-logo-light.png?1"
                      />
                    </a>
                  </div>
                  <div className="copyright">
                    <p>Copyright © IQI Global 2020</p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="social">
                    <a
                      href="https://www.facebook.com/IqiGlobal"
                      target="_blank"
                    >
                      <span className="screen-reader-text">Facebook</span>
                      <FaFacebookF />
                    </a>
                    <a href="https://twitter.com/IQIGlobal" target="_blank">
                      <span className="screen-reader-text">Twitter</span>
                      <FaTwitter />
                    </a>
                    <a
                      href="https://www.youtube.com/user/iqigroup"
                      target="_blank"
                    >
                      <span className="screen-reader-text">Youtube</span>
                      <FaYoutube />
                    </a>
                    <a
                      href="https://www.pinterest.com/iqiholdings/"
                      target="_blank"
                    >
                      <span className="screen-reader-text">Pinterest</span>
                      <FaPinterest />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default DetailsFooter;
