import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Get, Delete } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      reassignSubmissions: {},

      showDeleteConfirmation: false,
      showCreateMediaLinkModal: false,

      mediaLink: [],
      selectedDeleteLink: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeMediaLinkHOC = (val, context) => this.setState({ [context]: val });

    getMediaLinks = (id) => {
      Get(
        `/sub_sales/${id}/media_links?locale=${this.props.data.languageReducer.language}`,
        this.getMediaLinksSuccess,
        this.getMediaLinksError,
        this.load,
      );
    };
    getMediaLinksSuccess = (payload) => {
      this.setState({ mediaLink: payload });
    };
    getMediaLinksError = (error) => requestError(error);

    createMediaLink = (id, dataToSubmit) =>
      Post(
        `/sub_sales/${id}/media_links?locale=${this.props.data.languageReducer.language}`,
        dataToSubmit,
        () => this.createMediaLinkSuccess(id),
        this.createMediaLinkError,
        this.load,
      );
    createMediaLinkSuccess = (id) => {
      this.getMediaLinks(id);

      requestSuccess(this.props.getLocalised("success.create_media_link", `Media Link has been created successfully.`));
      this.setState({ showCreateMediaLinkModal: false });
    };
    createMediaLinkError = (error) => requestError(error);

    deleteMediaLink = (subsalesId, linkId) =>
      Delete(
        `/sub_sales/${subsalesId}/media_links/${linkId}?locale=${this.props.data.languageReducer.language}`,
        () => this.deleteMediaLinkSuccess(subsalesId),
        this.deleteMediaLinkError,
        this.load,
      );
    deleteMediaLinkSuccess = (id) => {
      this.getMediaLinks(id);
      requestSuccess(this.props.getLocalised("success.delete_media_link", `Media Link has been deleted successfully.`));
      this.setState({ showDeleteConfirmation: false });
    };
    deleteMediaLinkError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            mediaLink={this.state.mediaLink}
            selectedDeleteLink={this.state.selectedDeleteLink}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showCreateMediaLinkModal={this.state.showCreateMediaLinkModal}
            onLoadMediaLink={this.state.loading}
            onChangeMediaLinkHOC={this.onChangeMediaLinkHOC}
            getMediaLinks={this.getMediaLinks}
            createMediaLink={this.createMediaLink}
            deleteMediaLink={this.deleteMediaLink}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
