import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { FormHelperText, Card } from "@material-ui/core";
import { AiFillInfoCircle } from "react-icons/ai";
import { RiLinksFill } from "react-icons/ri";

import Tabs from "components/Tab";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleFormHeader from "components/Form/header";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import EmailDeliveryContent from "./EmailDeliveryContent";
import ClientsContent from "./clientsContent";
import ListingsContent from "./listingContent";
import ProjectContent from "./ProjectContent";

import permissionsChecker from "utils/permissionsChecker";
import defaultAvatar from "assets/images/av-male.jpg";

const StatusLib = [
  { id: 0, label: "Draft" },
  { id: 1, label: "Published" },
  { id: 2, label: "Inactive" },
  { id: 3, label: "Expired" },
];

const EditForm = (props) => {
  const [editFormData, onChangeFormData] = useState({
    title: "",
    expiry_date: null,
    status_id: 0,
  });
  const [selectedTab, onChangeTab] = useState("Clients");

  useEffect(() => {
    selectedPresentation &&
      onChangeFormData({
        id: selectedPresentation.id,
        title: selectedPresentation.title,
        expiry_date: selectedPresentation.expiry_date,
        status_id: selectedPresentation.status_id,
        transaction_number: selectedPresentation.transaction_number,
      });
  }, []);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(editFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  const {
    data,
    onLoadPresentation,
    selectedPresentation,
    onClickClose,
    onClickSubmit,
    showEmailDeliveryModal,

    onDownloadPresentationPDF,
    onChangePresentationHOC,
    deliverPresentationEmail,
  } = props;

  const { can_create, can_update } = permissionsChecker(
    "Presentation Centre",
    data,
  );

  const { full_name, role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  return (
    <>
      <p className="fs-2 mb-3">
        Create and edit your exciting presentation here. Enter a suitable title
        for your own reference, choose when you want the presentation to
        automatically expire and then add the listings and/or projects that you
        want to add. Next, add the clients that you want to be able to view this
        presentation. Once you have done all the changes, change status to
        <strong> Published </strong>
        and share the link with your clients.
      </p>
      {["Super Admin", "Admin"].indexOf(role) > -1 && (
        <div className="at-form__content">
          <div className={"grid-control"}>
            <section className="grid-full-col">
              <ModuleFormHeader
                title={"Presentation Owner"}
                moduleIcon={"atlas-frame"}
              />
            </section>
            <section className="grid-full-col">
              <div className="d-flex col-12 col-lg-6 g-3">
                <img
                  style={{
                    height: 120,
                    width: 120,
                    objectFit: "cover",
                    borderRadius: "50%",
                    marginRight: 20,
                  }}
                  src={
                    selectedPresentation.user_info.avatar_url || defaultAvatar
                  }
                  alt={selectedPresentation.user_info.full_name + "avatar"}
                  onError={(e) => {
                    if (e.target) {
                      e.target.src = defaultAvatar;
                    }
                  }}
                />
                <div className={"col-lg-7 col-12 mt-10"}>
                  <h5 className="fw-500 fs-3">
                    {selectedPresentation.user_info.full_name || "N/A"}
                  </h5>
                  <div className={"mt-2"}>
                    <AtlasIcon
                      svgHref={"atlas-profile2user"}
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        fill: "#EE6D4D",
                      }}
                    />
                    <span className="fs-2">
                      {selectedPresentation.user_info.team_name || "N/A"}
                    </span>
                  </div>
                  <div className={"mt-1"}>
                    <AtlasIcon
                      svgHref={"atlas-call"}
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        fill: "#EE6D4D",
                      }}
                    />
                    <span className="fs-2">
                      {selectedPresentation.user_info.mobile_contact_number ||
                        "N/A"}
                    </span>
                  </div>
                  <div className={"mt-1"}>
                    <AtlasIcon
                      svgHref={"atlas-sms"}
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        fill: "#EE6D4D",
                      }}
                    />
                    <span className="fs-2">{selectedPresentation.user_info.email || "N/A"}</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      <div className="at-form__content">
        <div className="grid-control">
          <section className="grid-full-col">
            <ModuleFormHeader
              title={"Presentation Detail"}
              moduleIcon={"atlas-icon-slides"}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Title
            </h2>
            <CustomFormInput
              type="text"
              disabled={!can_update}
              value={editFormData.title}
              required={true}
              onChangeValue={(val) => onChangeField(val, "title")}
              placeholder={"Enter your presentation title"}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Automatic Expiry:</h2>
            <div className="at-form-input">
              <AtlasIcon
                className={"at-form-input__calendar"}
                svgHref={"atlas-calendar-input"}
              />
              <DatePicker
                className="at-form-input__input"
                disabled={!can_update}
                value={editFormData.expiry_date}
                onChange={(val) =>
                  onChangeField(
                    Moment(val).format("DD MMM YYYY"),
                    "expiry_date",
                  )
                }
                placeholderText={"DD MMM YYYY"}
              />
            </div>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Status</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              disabled={!can_update}
              containerClassName={"w-100"}
              checkedValue={editFormData.status_id}
              selectedRadioValue={(val) => onChangeField(val, "status_id")}
              options={StatusLib}
            />
            <FormHelperText style={{ marginLeft: 0 }}>
              <AiFillInfoCircle
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  color: selectedPresentation.isPresentationPublished
                    ? "#F18E03"
                    : "black",
                }}
              />
              {selectedPresentation.isPresentationPublished
                ? "The presentation is live."
                : `The presentation is not live and won't be visible by clients.`}
            </FormHelperText>
          </section>
        </div>
        <div className={"d-flex flex-wrap grid_gap-1 mt-20"}>
          {can_update && (
            <button
              disabled={!editFormData.title || !editFormData.expiry_date}
              onClick={() => onClickSubmit(editFormData)}
              className={"btn-new btn-new--success"}
            >
              Save
            </button>
          )}
          <CustomButton
            className="btn-new btn-new--secondary"
            style={{ color: "#FFF" }}
            disabled={!selectedPresentation.isPresentationPublished}
            children={
              <>
                <AtlasIcon
                  svgHref={"atlas-receive-square"}
                  style={{
                    fill: "white",
                    width: 20,
                    height: 20,
                  }}
                />
                Download Brochure
              </>
            }
            tooltip={false}
            onClick={(val) => onDownloadPresentationPDF(editFormData)}
          />
          <CustomButton
            className="btn-new btn-new--secondary"
            style={{ color: "#FFF" }}
            children={
              <>
                <RiLinksFill
                  style={{
                    color: "#FFFFFF",
                  }}
                />
                Share
              </>
            }
            disabled={!selectedPresentation.isPresentationPublished}
            tooltip={true}
            tooltipChildren={
              <span>
                You can only share when the status updated to Published
              </span>
            }
            tooltipPosition={"bottom"}
            tooltipID={`at-presentation-share_button`}
            onClick={() =>
              onChangePresentationHOC(true, "showEmailDeliveryModal")
            }
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClickClose()}
          >
            Close
          </button>
        </div>
      </div>
      <Tabs
        sections={["Clients", "Listings", "Projects"]}
        selectedSection={selectedTab}
        onSelectSection={(val) => onChangeTab(val)}
      />
      {selectedTab === "Clients" && <ClientsContent {...props} />}
      {selectedTab === "Listings" && <ListingsContent {...props} />}
      {selectedTab === "Projects" && <ProjectContent {...props} />}
      {showEmailDeliveryModal && (
        <EmailDeliveryContent
          selectedPresentation={selectedPresentation}
          agentName={full_name}
          showLoading={onLoadPresentation}
          onClose={() =>
            onChangePresentationHOC(false, "showEmailDeliveryModal")
          }
          deliverPresentationEmail={deliverPresentationEmail}
        />
      )}
      {onLoadPresentation && <LoadingModal />}
    </>
  );
};

export default EditForm;
