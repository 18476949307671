import { AUTH_ADMIN_LOGOUT } from "./type";
import { updateCurrentSignInProfileSuccess } from "./currentSignInProfile";
import { clearItem } from "utils/tokenStore";

const adminLogOut = () => {
  return (dispatch) => {
    dispatch(authUserSuccess(""));
    dispatch(updateCurrentSignInProfileSuccess({}));

    clearItem("IQI_ATLAS_JWT_AGENT_TOKEN");
    clearItem("IQI_ATLAS_REFRESH_AGENT_TOKEN");
    clearItem("IQI_ATLAS_IMPERSONATOR_EMAIL");
  };
};

const authUserSuccess = (payload) => ({
  type: AUTH_ADMIN_LOGOUT,
  payload,
});

export default adminLogOut;
