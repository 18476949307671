import React from "react";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { FilePond } from "react-filepond";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasDialog from "components/Dialog";
import AtlasButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import AtlasCard from "components/Card";
import AtlasLoadingModal from "components/LoadingModal";
import CustomFormInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import SVGIcon from "components/Icon";
import isEmptyValue from "utils/isEmpty";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const New = ({
  data,
  document,
  onChange,
  onClose,
  tutorial_categories = [],
  onUploadDocument,
  onDeleteDocument,
  onSubmit,
  onLoadTutorial,
}) => {
  return (
    <AtlasDialog open={true}>
      <AtlasCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton onClick={() => onClose()} />
              <h4 className="at-card__title">{"Edit Tutorial"}</h4>
            </div>
            <div className="grid-control">
              <section className="grid-full-col">
                <label className="at-form-input__title" required>
                  Title
                </label>
                <CustomFormInput
                  type="text"
                  placeholder={"e.g. Tutorial Title"}
                  value={data.title}
                  onChangeValue={(val) => onChange("title", val)}
                  required={true}
                />
              </section>
              <section className="grid-full-col">
                <label className="at-form-input__title">
                  Tutorial Category
                </label>
                <AtSelectNew
                  value={data.category_id}
                  onChange={(e) => onChange("category_id", e.target.value)}
                >
                  {tutorial_categories.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </AtSelectNew>
              </section>

              <section className="grid-full-col d-flex mt-3">
                {data && data.document_url && (
                  <div className={"d-flex align-items-center mb-2 grid_gap-1"}>
                    <CustomFormInput
                      type={"text"}
                      containerClass={"mb-0"}
                      containerStyle={{ flex: 1 }}
                      value={data.document_file_name}
                      disabled={true}
                    />

                    <AtlasButton
                      type={"button"}
                      className={"at-table-icon-button"}
                      tooltip={true}
                      tooltipID={"view-file"}
                      tooltipPosition={"top"}
                      tooltipChildren={"View file"}
                      onClick={() => window.open(data.document_url, "_blank")}
                    >
                      <SVGIcon
                        className={"svg-icon-info"}
                        svgHref={"icon-info"}
                      />
                    </AtlasButton>

                    <AtlasButton
                      type={"button"}
                      className={"at-table-icon-button"}
                      tooltip={true}
                      tooltipID={"remove-file"}
                      tooltipPosition={"top"}
                      tooltipChildren={"Remove file"}
                      onClick={() => onDeleteDocument()}
                    >
                      <BsTrash3Fill
                        style={{ width: 20, height: 20, color: "#F04438" }}
                      />
                    </AtlasButton>
                  </div>
                )}
              </section>

              {isEmptyValue(data.document_url) && (
                <section className="grid-full-col">
                  <label className="at-form-input__title">
                    Upload Document
                  </label>
                  <FilePond
                    value={document}
                    allowMultiple={false}
                    fullWidth
                    acceptedFileTypes={["application/pdf"]}
                    maxFiles={1}
                    onupdatefiles={(file) => onUploadDocument(file)}
                  />
                  <FormHelperText>Accept PDF file types only </FormHelperText>
                </section>
              )}

              <div className="d-flex mt-20">
                <AtlasButton
                  disabled={!data.hasOwnProperty("document_url")}
                  containerStyle={{ marginLeft: "auto" }}
                  className={"btn-new btn-new--success"}
                  onClick={onSubmit}
                  children={"Submit"}
                />
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() => onClose()}
                >
                  Close
                </button>
              </div>
            </div>
            {onLoadTutorial && <AtlasLoadingModal />}
          </>
        }
      />
    </AtlasDialog>
  );
};

export default New;
