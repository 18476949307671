import React, { Component } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import CustomImageDisplay from "../../components/image";
import PhotoModal from "components/PhotoModal";
import AtlasIcon from "components/Icon/atlasIcon";
import EmptyState from "components/EmptyState";
import Tooltip from "@material-ui/core/Tooltip";

import FloorPlanHOC from "../../actions/floorplan";

import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiFloorPlanImg from "assets/images/juwaiImages/floorPlan.jpg";

import iqiLogo from "assets/images/iqi_logo_only.png";
import iqiFloorPlanImg from "assets/images/subsaleImage/floorPlan.jpg";

import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import juwaiAsiaFloorPlanImg from "assets/images/juwaiAsiaImages/subsalesImages.jpg";

const FloorPlan = ({
  tempImgSrc,
  uploadedFloorPlans,
  onLoadFloorPlans,
  showPhotoTabModal,

  getLocalised,
  onChangeFloorPlanHOC,
}) => {
  return (
    <>
      <section className="at-form__content">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="at-subsales-listing__view-title mb-20">
            <AtlasIcon
              svgHref={"atlas-grid-3"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            {getLocalised("label.floor_plan.floor_plans.title", "Floor Plans")}
          </div>
          <Tooltip
            classes={{ popper: "popper", tooltip: "tool-tip" }}
            placement={"top-end"}
            title={<img src={iqiFloorPlanImg} />}
          >
            <button
              style={{ width: 20, height: 20, marginLeft: 10 }}
              className={"btn-tooltip"}
              children={<img src={iqiLogo} />}
            />
          </Tooltip>
          <Tooltip
            classes={{ popper: "popper", tooltip: "tool-tip" }}
            placement={"top-end"}
            title={<img src={juwaiFloorPlanImg} />}
          >
            <button
              style={{ width: 20, height: 20, marginLeft: 10 }}
              className={"btn-tooltip"}
              children={<img src={juwaiLogo} />}
            />
          </Tooltip>
          <Tooltip
            classes={{ popper: "popper", tooltip: "tool-tip" }}
            placement={"top-end"}
            title={<img src={juwaiAsiaFloorPlanImg} />}
          >
            <button
              style={{ width: 20, height: 20, marginLeft: 10 }}
              className={"btn-tooltip"}
              children={<img src={juwaiAsiaLogo} />}
            />
          </Tooltip>
        </div>
        <p className="fs-2">{getLocalised("label.floor_plan.floor_plans.subtitle", "View and manage your floor plan images.")}</p>
        <hr className="mt-0 my-3" />
        {(!uploadedFloorPlans || uploadedFloorPlans.length < 1) && (
          <EmptyState
            title={getLocalised("label.floor_plan.floor_plans.empty_message", "No floor plans have been uploaded")}
            renderIcon={<AtlasIcon svgHref="atlas-grid-3" />}
          />
        )}
        {uploadedFloorPlans && uploadedFloorPlans.length > 0 && (
          <div className="row">
            {uploadedFloorPlans.map((item, index) => (
              <div className="col-md-6">
                <CustomImageDisplay
                  showRemoveButton={false}
                  imgStyle={{ width: "100%" }}
                  showPrimaryLabel={false}
                  showSetPrimaryButton={false}
                  key={`Floor-Plans-${index}`}
                  tooltipID={`Floor-Plans-${index}`}
                  imgSrc={item.photo ? item.photo : item.large_image_url}
                  onClickShowImage={(val) => {
                    onChangeFloorPlanHOC(true, "showPhotoTabModal");
                    onChangeFloorPlanHOC(val, "tempImgSrc");
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </section>
      {onLoadFloorPlans && <LoadingModal />}
      <PhotoModal
        showPhotoTabModal={showPhotoTabModal}
        tempImgSrc={tempImgSrc}
        togglePhotoTabModal={() =>
          onChangeFloorPlanHOC(false, "showPhotoTabModal")
        }
      />
    </>
  );
};

export default FloorPlan;
