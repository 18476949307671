import React from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import RENCard from "./RENCard";

import { StatusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";

const typeData = [
  { label: "Main", value: "1" },
  { label: "Ad Hoc", value: "2" },
];

const middleData = [
  { label: "Project Type", value: "type", icon: "atlas-building-3" },
  { label: "Booking Date", value: "booking_date", icon: "atlas-calendar" },
  { label: "Buyer", value: "buyer_name", icon: "atlas-frame" },
  { label: "Assigned To", value: "assigned_admin", icon: "atlas-profile-add" },
];

const dataList = [
  { label: "SPA Price", value: "purchase_price" },
  { label: "Net Price", value: "nett_price" },
  { label: "Package", value: "package" },
  { label: "Booking Form", value: "booking_form" },
  { label: "Size", value: "size" },
  { label: "Remark", value: "remark" },
];

const SummaryContent = ({
  summaryMode,
  formData,
  showLoading,
  isRejectedForm,

  onClose,
  onClickSubmit,
}) => {
  let tempFormData = {
    ...formData,
    type: _.find(typeData, { value: formData.type_id }).label,
    project_name: formData.project_name,
  };
  const { colorName = "" } =
    _.find(StatusColor, { value: formData.status }) || {};

  return (
    <>
      <div className="at-new-sales__details">
        <h1>
          {tempFormData.project_name}
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {tempFormData.status}
          </div>
        </h1>
        <p className="mt-2">Unit Number: {tempFormData.unit_number}</p>
        <div className="at-new-sales__details-middle">
          {middleData.map((item, index) => (
            <div key={index} className="d-flex align-items-center">
              <div className="at-badge-icon-sm at-badge-icon-warning">
                <AtlasIcon svgHref={item.icon} />
              </div>
              <div>
                <label>{item.label}</label>
                <p>{tempFormData[item.value] || "-"}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="at-form__content at-new-sales__details-bottom">
          {dataList.map((item, index) => (
            <div key={index} className="at-new-sales__details-item">
              <label>{item.label}</label>
              {item.value === "booking_form" && tempFormData.booking_form && (
                <a href={tempFormData[item.value]} target="_blank">
                  <p style={{ color: "#2563eb" }}>
                    {tempFormData[item.value]
                      ? tempFormData.project_name + " booking form"
                      : "-"}
                    <AtlasIcon
                      svgHref={"atlas-link-2-linear"}
                      style={{
                        width: 16,
                        height: 16,
                        marginLeft: 4,
                        fill: "#2563eb",
                      }}
                    />
                  </p>
                </a>
              )}
              {!(
                item.value === "booking_form" && tempFormData.booking_form
              ) && <p>{tempFormData[item.value] || "-"}</p>}
            </div>
          ))}
        </div>
      </div>
      <ModuleFormHeader
        title={"Agent Information"}
        className={"my-3"}
        moduleIcon={"atlas-user-tie"}
      />
      {formData.project_sale_agents_attributes.length < 1 && (
        <p>No agent info is available at the moment.</p>
      )}
      <div className="row">
        {formData.project_sale_agents_attributes.map((item, index) => (
          <div key={index} className="col-xl-4 col-md-6 col-xs-12">
            <RENCard index={index} item={item} mode={"view"} />
          </div>
        ))}
      </div>
      {summaryMode === "create" && (
        <div className="text-center" style={{ marginTop: 40 }}>
          <div className="at-confirmation_modal-icon">
            <AtlasIcon
              svgHref={"atlas-info-circle"}
              style={{ fill: "#3B82F6", backgroundColor: "#DBEAFE" }}
            />
          </div>
          <p
            className={"mt-10"}
          >{`Are you sure you want to ${isRejectedForm ? "resubmit" : "submit"} the above project sale?`}</p>
          <div className="d-flex justify-content-center mt-20">
            <button
              className="btn-new btn-new--primary mr-10"
              onClick={() => onClickSubmit()}
            >
              {isRejectedForm ? "Resubmit Now" : "Submit Now"}
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              style={{ marginRight: "20px" }}
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showLoading && <LoadingModal />}
    </>
  );
};

export default SummaryContent;
