import { UPDATE_MOTD_OPEN_DATETIME } from "actions/type";

let initialState = {
  lastOpenDateTime: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MOTD_OPEN_DATETIME:
      return {
        ...state,
        lastOpenDateTime: action.payload,
      };
    default:
      return state;
  }
};
