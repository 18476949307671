import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";
import { Get, Post } from "utils/axios";

const searchParams = [
  {
    label: "ID",
    value: "id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Client/Company Name",
    value: "client_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact Person ",
    value: "contact_person_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact No.",
    value: "contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Email Address",
    value: "email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Title Number",
    value: "property_title_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Type",
    value: "type_id_eq",
    type: "radio",
    col: 8,
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Property Valuation" },
      { value: "2", label: "Corporate Valuation" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      showCreate: false,
      showCreateConfirmation: false,

      loading: false,

      variationsData: [],
      selectedVariation: {},
      searchParams: searchParams,
      variationsPage: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeValuationsHOC = (val, context) => this.setState({ [context]: val });

    getValuations = (page, search) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/valuations?${search}page=${page}`,
        this.getValuationsSuccess,
        this.getValuationsError,
        this.load,
      );
    };
    getValuationsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tmpData = payload.data.map((item) => {
        return {
          ...item,
          created_at: item.created_at
            ? Moment(item.created_at).format("DD MMM YYYY")
            : "N/A",
        };
      });

      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }
      this.setState({
        variationsData: {
          ...payload,
          data: tmpData,
        },
        variationsPage: tmpTotalPages,
      });
    };
    getValuationsError = (error) => requestError(error);

    getSelectedVariation = (id) =>
      Get(
        `/valuation/${id}`,
        this.getSelectedVariationSuccess,
        this.getSelectedVariationError,
        this.load,
      );
    getSelectedVariationSuccess = (payload) => {
      this.setState({
        selectedVariation: payload,
      });
    };
    getSelectedVariationError = (error) => requestError(error);

    createValuation = (data) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      Post(
        `${role === "Admin" ? "/admin" : ""}/valuations`,
        data,
        this.createValuationSuccess,
        this.createValuationError,
        this.load,
      );
    };
    createValuationSuccess = () => {
      this.setState({ showCreateConfirmation: false });
      this.onChangeValuationsHOC(false, "showCreate");
      requestSuccess(
        "Thanks. The Valuation request has been received and a member of our team will get in touch with you straight away.",
      );
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getValuations(currentPage, searchParams);
    };
    createValuationError = () => requestError;

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showCreate={this.state.showCreate}
            showCreateConfirmation={this.state.showCreateConfirmation}
            onLoad={this.state.loading}
            variationsData={this.state.variationsData}
            selectedVariation={this.state.selectedVariation}
            searchParams={this.state.searchParams}
            variationsPage={this.state.variationsPage}
            onChangeValuationsHOC={this.onChangeValuationsHOC}
            createValuation={this.createValuation}
            getValuations={this.getValuations}
            getSelectedVariation={this.getSelectedVariation}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
