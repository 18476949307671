import React, { Component } from "react";
import _ from "lodash";
import EmailValidator from "email-validator";

import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import CreateHOC from "./actions/create";

const fieldData = ({ emailError, passwordConfirmationError }) => [
  { label: "Full name", value: "full_name" },
  { label: "Display Name", value: "display_name" },
  { label: "Email", value: "email", inputError: emailError },
  { label: "Primary Mobile Number", value: "mobile_contact_number" },
  { label: "Password", value: "password" },
  {
    label: "Password Confirmation",
    value: "password_confirmation",
    inputError: passwordConfirmationError,
  },
];

class Create extends Component {
  onChangeCreateFields = (val, context) => {
    let temp = _.cloneDeep(this.props.newPersonalAssistant);
    temp[context] = val;
    this.props.onChangeCreateHOC(temp, "newPersonalAssistant");
    if (context === "password_confirmation") {
      temp.password_confirmation !== temp.password
        ? this.props.onChangeCreateHOC(
            "Password and confirm password must be the same.",
            "passwordConfirmationError",
          )
        : this.props.onChangeCreateHOC("", "passwordConfirmationError");
    } else if (context === "email") {
      const tmpEmailError =
        val && !EmailValidator.validate(val) ? "Invalid Email" : "";
      this.props.onChangeCreateHOC(tmpEmailError, "emailError");
    }
  };

  render = () => {
    const {
      emailError,
      onLoadCreatePA,
      newPersonalAssistant,
      passwordConfirmationError,
      onChangeHOC,
      createNewPA,
    } = this.props;

    return (
      <ModalDialog
        title={"New Personal Assistant"}
        onLoad={onLoadCreatePA}
        onClose={() => onChangeHOC(false, "showCreateModal")}
        children={
          <>
            <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
              {fieldData({ emailError, passwordConfirmationError }).map(
                (item, index) => (
                  <section key={index} className="grid-half-col">
                    <h2 className="at-form-input__title" required>
                      {item.label}
                    </h2>
                    <CustomFormInput
                      type="text"
                      className={"mt-2"}
                      value={newPersonalAssistant[item.value]}
                      onChangeValue={(val) =>
                        this.onChangeCreateFields(val, item.value)
                      }
                      required={true}
                      inputError={item.inputError || ""}
                      placeholder={item.label}
                    />
                  </section>
                ),
              )}
            </form>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              type={"button"}
              disabled={
                _.values(newPersonalAssistant).some(
                  (x) => x === "" || x === null,
                ) || passwordConfirmationError !== ""
              }
              className="btn-new btn-new--primary"
              onClick={() => createNewPA(this.props.newPersonalAssistant)}
            >
              Create
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangeHOC(false, "showCreateModal")}
            >
              Cancel
            </button>
          </div>
        }
      />
    );
  };
}

export default CreateHOC(Create);
