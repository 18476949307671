import { Card, CardContent, Dialog } from "@material-ui/core";
import { fabric } from "fabric";
import _ from "lodash";
import { useEffect, useState } from "react";
import { RiImage2Fill } from "react-icons/ri";

import CustomCard from "components/Card";
import CustomFormInput from "components/Input/formInput";
import TooltipWrapper from "components/TooltipWrapper";
import TextModalContent from "./textModal";

import { useFabric } from "context/fabric";

import "../index.scss";

const CreateFabric = ({
  tempTemplate,

  onClickSubmit,
  onClickClose,
}) => {
  const [title, setTitle] = useState("");

  const {
    selectedTemplate,
    setSelectedTemplate,

    filePond,

    initEvent,
    tempClipID,
    canvas,
    resizeCanvas,
    addImage,

    fabricDblClick,
    ungroup,
    postUngroupHandler,
    setTextModal,
    showTextModal,
    tempEditTextObj,
  } = useFabric();

  const HEIGHT_OFFSET = 130;

  useEffect(() => {
    if (canvas) {
      initEvent(canvas);
      resizeCanvas(1, HEIGHT_OFFSET);
    }
  }, [canvas]);

  useEffect(() => {
    if (!showTextModal && canvas) {
      canvas.getObjects().forEach((obj) => {
        if (obj.type === "textbox") {
          obj.exitEditing();
        }
      });
    }
  }, [showTextModal, canvas]);

  useEffect(() => {
    tempTemplate && setSelectedTemplate(tempTemplate);

    if (canvas && tempTemplate) {
      resizeCanvas(tempTemplate.type_id, HEIGHT_OFFSET);
    }
  }, [tempTemplate]);

  useEffect(() => {
    const handleResize = () => {
      resizeCanvas(selectedTemplate?.type_id || 1, HEIGHT_OFFSET);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // Handle double click event for textbox when creating graphic
  useEffect(() => {
    if (selectedTemplate && selectedTemplate.content) {
      canvas &&
        canvas.loadFromJSON(
          selectedTemplate.content,
          () => canvas.renderAll(),
          (o, obj) => {
            if (obj.shapeType === "text") {
              const index = _.findIndex(selectedTemplate.content.objects, {
                id: obj.id,
              });

              const shape =
                selectedTemplate.content.objects[index].id.indexOf("rect") > -1
                  ? new fabric.Rect({
                      ...selectedTemplate.content.objects[index],
                      type: "rect",
                      absolutePositioned: true,
                    })
                  : new fabric.Circle({
                      ...selectedTemplate.content.objects[index].objects[0],
                      id: selectedTemplate.content.objects[index].id,
                      absolutePositioned: true,
                      left:
                        selectedTemplate.content.objects[index].left +
                        (selectedTemplate.content.objects[index].objects[0]
                          .width /
                          2) *
                          selectedTemplate.content.objects[index].scaleX,
                      top:
                        selectedTemplate.content.objects[index].top +
                        (selectedTemplate.content.objects[index].objects[0]
                          .height /
                          2) *
                          selectedTemplate.content.objects[index].scaleY,
                      radius: 80,
                      scaleX: selectedTemplate.content.objects[index].scaleX,
                      scaleY: selectedTemplate.content.objects[index].scaleY,
                      type: "circle",
                    });
              obj.setControlsVisibility({
                mtr: true,
                mt: false,
                mb: false,
                tr: true,
                tl: true,
                br: true,
                bl: true,
                ml: false,
                mr: false,
                deleteControl: false,
              });
              obj.clipPath = shape;

              obj.on(
                "mousedown",
                fabricDblClick(obj, (obj) => {
                  postUngroupHandler(ungroup(obj), obj);
                }),
              );
              canvas.renderAll();
            }
          },
        );
    }
  }, [canvas, selectedTemplate]);

  const onChangeFilePond = (fileItems) => {
    const clip = _.find(
      selectedTemplate.clipPath,
      (clip) => clip.id === tempClipID,
    );

    if (fileItems) {
      Object.keys(fileItems).forEach((key) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          filePond.current.value = "";
          addImage(e.target.result, clip);
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  return (
    <>
      <input
        multiple={false}
        type={"file"}
        className={"d-none"}
        ref={filePond}
        onChange={(e) => onChangeFilePond(e.target.files)}
        accept={"image/png, image/jpg, image/jpeg"}
      />
      {selectedTemplate && selectedTemplate.sample_url && (
        <TooltipWrapper
          title={
            <img
              style={{ height: 300 }}
              alt={selectedTemplate.title}
              src={selectedTemplate.sample_url}
            />
          }
          child={
            <div
              className={"p-2 bg-warning rounded text-center"}
              style={{
                marginLeft: "auto",
                width: "fit-content",
                marginRight: 30,
                marginBottom: -50,
                marginTop: 10,
              }}
            >
              <RiImage2Fill
                style={{
                  width: 40,
                  height: 40,
                  color: "white",
                  cursor: "pointer",
                }}
              />
              <p className={"color-white mt-1"}>Sample</p>
            </div>
          }
        />
      )}
      <div
        id={"canvas-editor"}
        className={"d-flex flex-column align-items-center p-3 mt-3"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", height: 800 }}
      >
        <Card
          className={"mb-3"}
          style={{
            overflow: "unset",
            borderRadius: 0,
            width: "100%",
            maxWidth: 495,
          }}
        >
          <CardContent style={{ padding: "16px" }}>
            <h2 className={"at-form-input__title text-capitalize"}>Title</h2>
            <CustomFormInput
              type={"text"}
              value={title}
              required={true}
              onChangeValue={setTitle}
              placeholder={"Please enter graphic's title"}
            />
          </CardContent>
        </Card>
        <div
          className={"fabric-canvas-wrapper"}
          style={{ transform: "scale( 0.4 )", transformOrigin: "top center" }}
        >
          <canvas id={"canvas"}></canvas>
        </div>
      </div>
      <div className={"at-modal_dialog-container-footer g-3"}>
        <button
          disabled={!title || !tempTemplate}
          onClick={() => {
            onClickSubmit({
              graphic_template_id: selectedTemplate.id,
              content: {
                ...canvas.toJSON([
                  "id",
                  "shapeType",
                  "shapeLabel",
                  "selectable",
                  "hoverCursor",
                  "_controlsVisibility",
                ]),
              },
              title,
              thumbnail: canvas.toDataURL({ format: "png", quality: 1 }),
            });
          }}
          className={"btn-new btn-new--success"}
        >
          Create
        </button>
        <button
          type={"button"}
          className={"btn-new btn-new--outline-secondary"}
          onClick={onClickClose}
        >
          Cancel
        </button>
      </div>
      <Dialog maxWidth={"md"} open={showTextModal}>
        <CustomCard
          cardContent={
            <TextModalContent
              tempEditTextObj={tempEditTextObj}
              onClickAdd={(val) => {
                setTextModal(false);
                const objects = canvas.getObjects();
                const shapeIndex = _.findIndex(
                  objects,
                  (obj) => obj.type === "circle" || obj.type === "rect",
                );

                objects.forEach((obj) => {
                  if (obj.type === "textbox") {
                    obj.set({
                      fontWeight: val.fontWeight,
                      text: val.text,
                      fontStyle: val.fontStyle,
                      fill: val.fill,
                      textAlign: val.textAlign,
                      underline: val.underline,
                      width:
                        objects[shapeIndex].width * objects[shapeIndex].scaleX,
                      height:
                        objects[shapeIndex].height * objects[shapeIndex].scaleY,
                    });

                    canvas.renderAll();
                  }
                });
              }}
              onClickClose={() => setTextModal(false)}
            />
          }
        />
      </Dialog>
    </>
  );
};

export default CreateFabric;
