import React, { useEffect, useState } from "react";
import ReactHTMLParser from "react-html-parser";
import _ from "lodash";
import Moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdTime } from "react-icons/io";
import {
  FormHelperText,
  Dialog,
  Tooltip,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { BiCreditCard } from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import AlertBox from "components/AlertBox";
import SVGIcon from "components/Icon/new";
import CustomCheckbox from "components/Checkbox";
import AtlasCard from "components/Card";
import PaymentForm from "containers/iPay88";
import PaymentSelect from "containers/iPay88/imageSelect";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/close";
import CustomButton from "components/Button";
import CustomInput from "components/Input/formInput";

import iPayLogo from "assets/ipay88/ipay88.png";
import isEmptyValue from "utils/isEmpty";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./index.scss";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const EventCheckout = (props) => {
  const checkoutRef = React.createRef(null);
  const userInfo = window.location.href.includes("/admin-impersonate")
    ? props.data.currentSignInProfileReducer
    : props.data.profileReducer;
  const [deliveryBranchId, setDeliveryBranchId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [digitalPayment, onSelectDigitalPayment] = useState("");
  const [paymentProof, setPaymentProof] = useState({});
  const [isChecked, onChangeCheckbox] = useState(false);
  const [showTncModal, onChangeShowTnc] = useState(false);

  const [selectedPricingTicket, onChangeSelectPricingTicket] = useState({
    ticket_type_id: "",
    id: "",
    title: "",
    price: 0,
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    const { pricing = { sold_out: true } } = props.selected_event_original;
    if (pricing.length > 0 && !pricing[0].sold_out) {
      const firstPricing = pricing[0];
      onChangeSelectPricingTicket(firstPricing);

      Promise.all([onChangeSelectPricingTicket(firstPricing)]).then(() => {
        props.savePaymentApproval({
          amount: firstPricing.price,
          description: `Paid for ${props.selected_event_original.name}.`,
          product_id: firstPricing.id,
          provider: "Ipay88",
          type: "Ticket",
          user_id: userInfo.id,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (paymentMethod) {
      setPaymentProof("");
      onSelectDigitalPayment("");
      onChangeCheckbox(false);
    }
  }, [paymentMethod]);

  useEffect(() => {
    checkoutRef?.current?.scrollIntoView({ inline: "end", behavior: "smooth" });
  }, [digitalPayment, paymentMethod, deliveryBranchId]);

  const onSubmitDeliveryBranchStatus = (isSuccess) => {
    if (!isSuccess) {
      setDeliveryBranchId("");
    }
  };

  const renderForm = () => {
    return (
      <AtlasCard
        className={"mt-1"}
        containerStyle={{ backgroundColor: "white" }}
        cardContent={
          <div>
            <div>
              <p className="at-form-input__title">Full Name (as per IC)</p>
              <CustomInput
                disabled={true}
                type={"text"}
                containerStyle={{ marginBottom: 10 }}
                placeholder=""
                value={userInfo.full_name || ""}
                onChangeValue={() => {}}
              />
            </div>
            <div>
              <p className="at-form-input__title">Identification Number</p>
              <CustomInput
                disabled={true}
                type={"text"}
                containerStyle={{ marginBottom: 10 }}
                placeholder=""
                value={userInfo.identification_number || ""}
                onChangeValue={() => {}}
              />
            </div>
            <div>
              <p className="at-form-input__title">
                Board Registration Number
              </p>
              <CustomInput
                disabled={true}
                type={"text"}
                containerStyle={{ marginBottom: 10 }}
                placeholder=""
                value={userInfo.board_registration_number || ""}
                onChangeValue={() => {}}
              />
            </div>
            <div className="mb-3">
              <p className="at-form-input__title">Branch to collect Cert</p>
              <Select
                fullWidth
                value={deliveryBranchId}
                autoWidth={true}
                input={<OutlinedInput labelWidth={0} />}
                onChange={(e) => {
                  setDeliveryBranchId(e.target.value);
                  props.updatePaymentApproval(
                    { delivery_branch_id: e.target.value },
                    onSubmitDeliveryBranchStatus,
                  );
                }}
              >
                {props.deliveryBranches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <AlertBox
              title={""}
              description={
                <>
                  <p>
                    Please make sure the information provided is correct before
                    continuing to the next step.
                  </p>
                  <p>
                    Any incorrect information may affect your registration to
                    the Board system.
                  </p>
                  <p>
                    Kindly contact our support team if you need to update your
                    information.
                  </p>
                </>
              }
            />
          </div>
        }
      />
    );
  };

  const renderPaymentGateway = () => (
    <>
      <div className="payment-container mt-1">
        <img
          src={iPayLogo}
          className="ipay-logo"
          style={{ width: "20%", minWidth: 100 }}
        />
        <div className="ipay-frame d-flex flex-wrap justify-content-center">
          <FormHelperText className="my-4 w-100 text-center">
            Choose a payment method. You will then be redirected to a secure
            page to complete the payment.
          </FormHelperText>
          {[2, 16, 210, 523, 538, 542, 801].map((id) => (
            <PaymentSelect
              payment_id={id}
              selected={id === digitalPayment}
              onClickSelect={(param) => onSelectDigitalPayment(param.value)}
            />
          ))}
        </div>
      </div>
      {digitalPayment && (
        <>
          <p className="my-3">
            <CustomCheckbox
              checked={isChecked}
              onChangeCheckboxValue={(e) => onChangeCheckbox((prev) => !prev)}
            />
            <span className="ml-2">
              I agree to the
              <Tooltip
                classes={{ popper: "popper", tooltip: "tool-tip" }}
                placement={"top"}
                title={"Click here"}
              >
                <a
                  className="cursor-y ml-2"
                  style={{ color: "#3181d6" }}
                  onClick={() => onChangeShowTnc(true)}
                >
                  terms and conditions
                </a>
              </Tooltip>
            </span>
          </p>
          <PaymentForm
            fullWidth={true}
            payment_id={digitalPayment}
            disabled={props.showErrorMessage || !isChecked}
            hideIcon={true}
            buttonTitle="Register & Pay"
            productDesc={`${props.selected_event_original.name}'s general admission ticket`}
            userInfo={userInfo}
            refNo={props.paymentReference.payment_id}
            amount={selectedPricingTicket.price.toString()}
            amountSHA256={selectedPricingTicket.price
              .toString()
              .replace(".", "")}
            remark={`Payment to ${props.selected_event_original.name}'s general admission ticket.`}
          />
        </>
      )}
    </>
  );

  const renderBankIn = () => (
    <div className="at-event__checkout-bank-in">
      <p>Upload proof of payment</p>
      <FormHelperText className="mb-2">
        Please upload a good quality picture of the payment transfer proof.
        Ensure that the transaction number, recipient and amount is clearly
        visible.
      </FormHelperText>
      <FilePond
        acceptedFileTypes={["image/*", "application/pdf"]}
        labelIdle={"Click here to upload the proof of payment"}
        allowMultiple={false}
        maxFiles={1}
        fullWidth
        onremovefile={() => setPaymentProof({})}
        onupdatefiles={(fileItems) => {
          if (fileItems.length > 0 && fileItems[0].file) {
            const reader = new FileReader();
            try {
              reader.onload = (e) => {
                let temp = {
                  file: e.target.result,
                  filename: fileItems[0].file.name,
                };
                setPaymentProof(temp);
              };
              reader.readAsDataURL(fileItems[0].file);
            } catch (e) {
              console.log(e);
            }
          }
        }}
      />
      <FormHelperText>
        Accepted file types: jpg, jpeg, png, pdf.(Required)
      </FormHelperText>
      {!_.isEmpty(paymentProof) && (
        <>
          <p className="my-3">
            <CustomCheckbox
              checked={isChecked}
              onChangeCheckboxValue={(e) => onChangeCheckbox((prev) => !prev)}
            />
            <span className="ml-2">
              I agree to the
              <Tooltip
                classes={{ popper: "popper", tooltip: "tool-tip" }}
                placement={"top"}
                title={"Click here"}
              >
                <a
                  className="cursor-y ml-2"
                  style={{ color: "#3181d6" }}
                  onClick={() => onChangeShowTnc(true)}
                >
                  terms and conditions
                </a>
              </Tooltip>
            </span>
          </p>
          <CustomButton
            className={"btn-new btn-new--secondary mt-2 w-100"}
            disabled={props.showErrorMessage || !isChecked}
            style={{ float: "right", color: "white" }}
            children={"Submit Registration"}
            onClick={() =>
              props.joinSelectedEvent(props.selected_event_original.id, {
                paymentProof,
                payment_id: props.paymentReference.payment_id,
                ticket_pricing_id: selectedPricingTicket.id,
              })
            }
          />
        </>
      )}
    </div>
  );

  const renderCardContent = () => {
    const {
      name,
      start_date_time,
      end_date_time,
      enable_bank_in,
      enable_payment_gateway,
      pricing,
      terms_and_conditions,
      training_type_id,
    } = props.selected_event_original;
    let oneDayEvent =
      start_date_time.substring(0, 10) === end_date_time.substring(0, 10);

    return (
      <>
        <AtlasCloseButton
          containerStyle={{ position: "absolute", top: 5, right: 5 }}
          onClick={() => props.onClickClose()}
        />
        <div className="at-event__checkout-event-title">
          <h3>{name}</h3>
          <p className="at-event__checkout-event-date_time_cont">
            {oneDayEvent && (
              <>
                <AiOutlineCalendar className="svg-icon-info" />
                <span className="at-event__checkout-event-date">
                  {Moment(start_date_time).format("ddd, DD MMM YYYY ")}
                </span>
                <IoMdTime className="svg-icon-info" />
                <span className="at-event__checkout-event-time">
                  {Moment(start_date_time).format("HH:mm ")}
                </span>
                —
                <span className="at-event__checkout-event-time">
                  {Moment(end_date_time).format(" HH:mm")}
                </span>
              </>
            )}
            {!oneDayEvent && (
              <>
                <AiOutlineCalendar className="svg-icon-info" />
                <span className="at-event__checkout-event-date">
                  {Moment(start_date_time).format("ddd, DD MMM YYYY ")}
                </span>
                <IoMdTime className="svg-icon-info" />
                <span className="at-event__checkout-event-time">
                  {Moment(start_date_time).format("HH:mm ")}
                </span>
                —
                <AiOutlineCalendar className="svg-icon-info" />
                <span className="at-event__checkout-event-date">
                  {Moment(end_date_time).format(" ddd, DD MMM YYYY ")}
                </span>
                <IoMdTime className="svg-icon-info" />
                <span className="at-event__checkout-event-time">
                  {Moment(end_date_time).format("HH:mm")}
                </span>
              </>
            )}
          </p>
        </div>
        <hr />
        <p style={{ fontSize: 16 }}>Step 1: Choose your ticket</p>
        {pricing instanceof Array &&
          pricing.length > 0 &&
          pricing.map((item) => {
            return (
              <div
                className={`at-event__checkout-event-ticket-container${selectedPricingTicket.sold_out ? "_disabled" : "_enabled"} 
                  my-1 ${item.id === selectedPricingTicket.id ? "active" : ""}
                `}
                onClick={() => {
                  const isDisabled =
                    item.id === selectedPricingTicket.id ||
                    selectedPricingTicket.sold_out;

                  if (!isDisabled) {
                    onChangeSelectPricingTicket(item);
                    props.savePaymentApproval({
                      amount: item.price,
                      description: `Paid for ${props.selected_event_original.name}.`,
                      product_id: item.id,
                      provider: "Ipay88",
                      type: "Ticket",
                      user_id: userInfo.id,
                    });
                  }
                }}
              >
                {selectedPricingTicket.sold_out && (
                  <div className="at-event__checkout-event-ticket-banner" />
                )}
                <div className="row at-event__checkout-event-ticket">
                  <div className="col-md-7">
                    <p className="fw-600">{item.title}</p>
                    <p className="mb-10 fw-600">RM {item.price.toFixed(2)}</p>
                    <p className="mb-10">
                      Registration ends on{" "}
                      {Moment(item.end_date, "YYYY-MM-DD").format(
                        "DD MMM YYYY",
                      )}
                    </p>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-end">
                    {item.id === selectedPricingTicket.id && (
                      <>
                        <p className="mr-3">Quantity: </p>
                        <p className="fw-600">x 1</p>
                      </>
                    )}
                  </div>
                  {selectedPricingTicket.sold_out && (
                    <div
                      className={`at-event__checkout-event-sold_out_icon d-flex col-md-2`}
                    >
                      <SVGIcon
                        className={`svg-icon-sold-out at-event__checkout-event-icon_sold_out`}
                        svgHref={"icon-sold-out"}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {pricing instanceof Array && pricing.length === 0 && (
          <div className={`at-event__checkout-event-ticket-container`}>
            <div className="row at-event__checkout-event-ticket">
              <p className="fw-600">No Ticket Available</p>
            </div>
          </div>
        )}
        <div className="at-event__checkout-event-ticket-total">
          <p style={{ marginRight: 20 }}>
            Total: RM {selectedPricingTicket.price.toFixed(2)}
          </p>
        </div>
        {[4, 6].indexOf(training_type_id) !== -1 && (
          <div className="mt-3">
            <p>Step 2: Confirm Your Information</p>
            {renderForm()}
          </div>
        )}
        {!isEmptyValue(selectedPricingTicket.id) &&
          (([4, 6].indexOf(training_type_id) !== -1 && deliveryBranchId) ||
            [4, 6].indexOf(training_type_id) === -1) && (
            <div className="mt-4">
              <p style={{ fontSize: 16 }}>
                {`Step ${[4, 6].indexOf(training_type_id) === -1 ? 2 : 3}: Choose your payment method`}
              </p>
              <div className="d-flex flex-wrap justify-content-center grid_gap-1 mt-1">
                {enable_payment_gateway && (
                  <button
                    disabled={
                      !props.selected_event_original.enable_payment_gateway ||
                      !selectedPricingTicket.id
                    }
                    className={`at-event__checkout-payment-option 
                        ${
                          paymentMethod === "1"
                            ? "at-event__checkout-payment-option-selected"
                            : ""
                        }`}
                    onClick={() => setPaymentMethod("1")}
                  >
                    <BiCreditCard style={{ width: 50, height: 30 }} />
                    Online / Credit Card
                  </button>
                )}
                {enable_bank_in && (
                  <button
                    disabled={
                      !props.selected_event_original.enable_bank_in ||
                      !selectedPricingTicket.id
                    }
                    className={`at-event__checkout-payment-option 
                        ${
                          paymentMethod === "2"
                            ? "at-event__checkout-payment-option-selected"
                            : ""
                        }`}
                    onClick={() => setPaymentMethod("2")}
                  >
                    <FaRegMoneyBillAlt style={{ width: 50, height: 30 }} />
                    Bank-In
                  </button>
                )}
              </div>
            </div>
          )}
        {props.showPaymentMethod && paymentMethod && (
          <>
            <p className="mt-3" style={{ fontSize: 16 }}>
              {`Step ${[4, 6].indexOf(training_type_id) === -1 ? 3 : 4}: Complete your payment`}
            </p>
            {paymentMethod === "1" && renderPaymentGateway()}
            {paymentMethod === "2" && renderBankIn()}
            {props.showErrorMessage && (
              <AtlasCard
                className={"mt-4"}
                containerStyle={{ backgroundColor: "white" }}
                cardContent={
                  <div style={{ textAlign: "center" }}>
                    <AiOutlineCloseCircle
                      style={{ width: 100, color: "red" }}
                    />
                    <br />
                    <p className={"mt-10 mb-10"}>
                      {ReactHTMLParser(props.errorPaymentMessage)}
                    </p>
                    <p> Please contact support. </p>
                    <br />
                    <button
                      type={"button"}
                      className="btn-new btn-new--outline-secondary ml-2"
                      onClick={() => props.onClickClose()}
                    >
                      Close
                    </button>
                  </div>
                }
              />
            )}
          </>
        )}
        <Dialog
          open={showTncModal}
          fullScreen={true}
          maxWidth={"lg"}
          classes={{ paper: "at-event__checkout-paper" }}
        >
          <AtlasCard
            className="h-100"
            cardContent={
              <>
                <h4 className="at-card__title d-inline-block">
                  Terms and Conditions
                </h4>
                <AtlasCloseButton
                  containerStyle={{
                    marginBottom: 10,
                    display: "inline-block",
                    position: "absolute",
                    right: "1rem",
                    top: "1rem",
                  }}
                  onClick={() => onChangeShowTnc(false)}
                />
                <hr />
                <AtlasCard
                  className="bg-white"
                  cardContent={
                    <>
                      <div className="mt-3">
                        {ReactHTMLParser(terms_and_conditions)}
                      </div>
                      <CustomButton
                        containerStyle={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        onClick={() => onChangeShowTnc(false)}
                        isIconButton={false}
                        className={
                          "d-flex btn-new btn-new--outline-secondary ml-1 mt-4"
                        }
                        children={"Close"}
                        tooltip={false}
                        tooltipChildren={"Cancel"}
                      />
                    </>
                  }
                />
              </>
            }
          />
        </Dialog>
        <div ref={checkoutRef} />
      </>
    );
  };

  return (
    <Dialog
      open={true}
      maxWidth={"lg"}
      classes={{ paper: "at-event__checkout-paper" }}
    >
      <AtlasCard
        className={"at-event__checkout"}
        containerStyle={{ backgroundColor: "white" }}
        cardContent={renderCardContent()}
      />
      {props.onLoadEvents && <LoadingModal />}
    </Dialog>
  );
};
export default EventCheckout;
