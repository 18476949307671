import React, { Component } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

import CustomTableHeader from "./components/header";
import CustomPagination from "components/Pagination";
import CustomTableContent from "./components/content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/table/index.scss";

class CustomTable extends Component {
  constructor(p) {
    super();
    this.state = {
      searching: false,
      selectPerPageOption: 10,
      totalPaginationCount: 0,
      totalPages: [1],
      clickPageNumber: 1,
      headerRearrangeData: {},
      rowData: [],
      initialRowData: [],
      searchWithField: "",
      searchWithFieldDisplay: "",
    };
    this.debounceService = _.debounce(this.debounceService, 300);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData.length !== prevProps.rowData.length) {
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption,
          ),
      );
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;
        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          Object.keys(item).map((key) => {
            if (tmp[key] !== item[key]) {
              dataUpdated = true;
            }
          });
        });
        if (dataUpdated) {
          return this.setState(
            {
              rowData: this.props.rowData,
              initialRowData: this.props.rowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption,
              ),
          );
        }
      }
    }
  };

  componentDidMount = () =>
    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
        searchWithField: this.props.searchWithField
          ? this.props.searchWithField
          : this.props.headerData[0].value,
        searchWithFieldDisplay: this.props.searchWithField
          ? this.props.searchPlaceholder
          : this.props.headerData[0].label,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

  debounceService = (searchVal) => {
    const { searchWithField } = this.state;
    let result = _.filter(this.state.initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });
    return this.setState(
      {
        rowData: result,
        searching: false,
      },
      () =>
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        ),
    );
  };

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  selectPerPageOption = (val) =>
    this.setState(
      {
        clickPageNumber: 1,
        selectPerPageOption: val.value,
      },
      () => this.processPagesData(this.state.rowData, val.value),
    );

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      }
    });

  selectSearchHeader = (val) =>
    this.setState({
      searchWithField: val.value,
      searchWithFieldDisplay: val.label,
    });

  renderTableContent = () => {
    const {
      headerData,
      actionColumn,
      rowKey,
      actionColumnContent,
      booleanColumn,
      thousandSeparatorColumn,
      viewDocTooltip,
      secondLayerRow,
      disabledDelete,
      disabledEdit,
    } = this.props;
    if (this.state.rowData && this.state.rowData.length > 0) {
      return this.state.rowData.map((rowItem, index) => {
        if (
          index + 1 >
            (this.state.clickPageNumber - 1) * this.state.selectPerPageOption &&
          index + 1 <=
            this.state.clickPageNumber * this.state.selectPerPageOption
        ) {
          return (
            <CustomTableContent
              key={index}
              headerData={headerData}
              actionColumn={actionColumn}
              actionColumnContent={actionColumnContent}
              rowItem={rowItem}
              viewDocTooltip={viewDocTooltip}
              disabledDelete={disabledDelete}
              disabledEdit={disabledEdit}
              approveClick={(param) => this.props.approveClick(param)}
              detailsClick={(param) => this.props.detailsClick(param)}
              onClickViewDoc={(param) => this.props.onClickViewDoc(param)}
              editClick={(param) => this.props.editClick(param)}
              deleteClick={(param) => this.props.deleteClick(param)}
              updateFixedClick={(param) => this.props.updateFixedClick(param)}
              updateCalendarClick={(param) =>
                this.props.updateCalendarClick(param)
              }
              updateAcceptedClick={(param) =>
                this.props.updateAcceptedClick(param)
              }
              checkInClick={(param) => this.props.checkInClick(param)}
              checkOutClick={(param) => this.props.checkOutClick(param)}
              transferTicket={(param) => this.props.transferTicket(param)}
              rowKey={rowKey}
              headerRearrangeData={this.state.headerRearrangeData}
              booleanColumn={booleanColumn}
              thousandSeparatorColumn={thousandSeparatorColumn}
              secondLayerRow={secondLayerRow}
            />
          );
        }
      });
    } else {
      return (
        <EmptyState
          title={"No Uploaded Documents Found"}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { pagination, hidePagination, rowData } = this.props;
    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > this.state.rowData.length) {
        pageNumTmp = this.state.rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <p
        className={`at-table__pagination-info ${hidePagination ? "d-none" : ""}`}
      >
        Showing
        <span className="mx-1">
          {pagination
            ? processFloorPaginationCount(this.state.clickPageNumber)
            : ""}
        </span>
        -
        <span className="mx-1">
          {pagination
            ? processCeilingPaginationCount(this.state.selectPerPageOption)
            : ""}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  renderPagination = () => {
    return (
      <CustomPagination
        totalPages={this.state.totalPages}
        chosenPageNumber={this.state.selectPerPageOption}
        onClickPageNumber={(val) => this.onClickPageNumber(val)}
        currentPageNumber={this.state.clickPageNumber}
      />
    );
  };

  render = () => {
    const {
      className,
      headerData,
      actionColumn,
      actionColumnContent,
      renderExtraContent,
    } = this.props;
    return (
      <div className={`mb-3 ${className}`}>
        {
          this.state.searching && (
            <div className="at-table__options">
              <PulseLoader
                className={"mt-10"}
                sizeunit={"px"}
                size={10}
                color={"#16232c"}
                loading={this.state.searching}
              />
            </div>
          )
        }
        {renderExtraContent && renderExtraContent()}
        <div className="at-table at-table--set">
          <CustomTableHeader
            data={headerData}
            actionColumn={actionColumn}
            actionColumnContent={actionColumnContent}
            onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
          />
          {this.renderTableContent()}
        </div>
        {this.state.rowData.length > 0 && (
          <div className="at-pagination__cont">
            {this.renderPagination()}
            {this.renderPaginationText()}
          </div>
        )}
      </div>
    );
  };
}

export default CustomTable;
