import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      searchParams: [
        { label: "Start Date", value: "start_date", param: "" },
        { label: "End Date", value: "end_date", param: "" },
        { label: "Leaders", value: "leader_ids", param: "" },
      ],
      nameFilter: "",
      reports: [],
      reportChart: {},
      leaderList: [],
      initialLeaderList: [],
    };

    load = (param) => this.setState({ loading: param });
    onChangeProjectReportsHOC = (val, key) => this.setState({ [key]: val });

    getLeaders = () =>
      Get(
        `/project_sales/get_leaders`,
        this.getLeadersSuccess,
        this.getLeadersError,
        this.load,
      );
    getLeadersSuccess = (payload) =>
      this.setState({
        leaderList: payload.result,
        initialLeaderList: payload.result,
      });
    getLeadersError = (error) => requestError(error);

    getSalesByLeader = (onSuccess) => {
      let temp = _.cloneDeep(this.state.searchParams);
      let search = "";
      temp.map((item) => {
        if (item.value === "leader_ids") {
          search += item.param.map((item) => `leader_ids[]=${item}&`).join("");
        } else {
          search += `${item.value}=${item.param ? Moment(item.param).format("YYYY-MM-DD HH:mm") : ""}&`;
        }
      });

      Get(
        `/project_sales/get_sales_by_leader?${search}`,
        (payload) => this.getSalesByLeaderSuccess(payload, onSuccess),
        this.getSalesByLeaderError,
        this.load,
      );
    };
    getSalesByLeaderSuccess = (payload, onSuccess) => {
      let labels = [];
      let dataSet = [];
      let tempData = [];
      if (payload.result && payload.result.length > 0) {
        tempData = payload.result.map((item) => {
          labels.push(item.agentName);
          dataSet.push(Math.round(item.totalAmount));
          return {
            ...item,
            totalAmount: Math.round(item.totalAmount),
          };
        });
      }

      this.setState(
        {
          reports: tempData,
          reportChart: {
            labels: labels,
            datasets: [
              {
                label: "Sales Rank",
                data: dataSet,
                backgroundColor: "rgb(75, 192, 192)",
              },
            ],
          },
        },
        () => {
          onSuccess();
        },
      );
    };
    getSalesByLeaderError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadReports={this.state.loading}
          getLeaders={this.getLeaders}
          getSalesByLeader={this.getSalesByLeader}
          onChangeProjectReportsHOC={this.onChangeProjectReportsHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
