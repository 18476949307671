import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showExportDialog: false,
      latestStats: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeMOTDStatisticHOC = (val, context) =>
      this.setState({ [context]: val });

    getMOTDLatest = () =>
      Get(
        `/admin/quote_audio_stats/latest`,
        this.getMOTDLatestSuccess,
        this.getMOTDLatestError,
        this.load,
      );
    getMOTDLatestSuccess = (payload) => {
      let tempKeys = _.keys(payload);
      let tempWebTotal = [];
      let tempAppTotal = [];

      tempKeys.map((key) => {
        tempWebTotal.push(payload[key].web_total);
        tempAppTotal.push(payload[key].app_total);
      });
      this.setState({
        latestStats: {
          labels: tempKeys,
          datasets: [
            {
              stack: "yAxis",
              data: tempAppTotal,
              backgroundColor: "rgba(32,182,167, 0.5 )",
              label: "Total play count on App",
              fill: true,
            },
            {
              stack: "yAxis",
              data: tempWebTotal,
              backgroundColor: "rgb(238,109,77, 0.5)",
              label: "Total play count on Web",
              fill: true,
            },
          ],
        },
      });
    };
    getMOTDLatestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadMotdStatistic={this.state.loading}
            latestStats={this.state.latestStats}
            showExportDialog={this.state.showExportDialog}
            onChangeMOTDStatisticHOC={this.onChangeMOTDStatisticHOC}
            getMOTDLatest={this.getMOTDLatest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
