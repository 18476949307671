import React, { Component } from "react";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import AtInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import AtlasRadioGroup from "components/RadioGroup";
import TableContent from "./content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "./styles.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
  };

  renderTableContent = () => {
    if (this.props.rowData && this.props.rowData.length > 0) {
      return (
        <ul className="at-atlas_store_ticket-container">
          {this.props.rowData.map((item, index) => {
            return (
              <TableContent
                key={index}
                data={item}
                onClickRedeem={this.props.onClickRedeem}
              />
            );
          })}
        </ul>
      );
    } else {
      return (
        <EmptyState
          title={`No coupons available`}
          renderIcon={<AtlasIcon svgHref="atlas-note" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp = tmp + `q[${item.value}]=${item.param}&`;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    return (
      <div className="at-table__options mb-10">
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="row mb-10">
            {this.props.searchParams &&
              this.props.searchParams.map((item, index) => {
                return (
                  <div key={item.value} className="col-md-6">
                    <label>{item.label}</label>
                    {item.type === "input" && (
                      <AtInput
                        value={item.param}
                        onChangeValue={(value) => {
                          let tmp = _.cloneDeep(this.props.searchParams);
                          tmp[index].param = value;
                          return this.props.onChangeSearchParams(tmp);
                        }}
                      />
                    )}
                    {item.type === "select" && (
                      <AtSelectNew
                        placeholder={item.placeholder ? item.placeholder : ""}
                        value={item.param}
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.props.searchParams);
                          tmp[index].param = e.target.value;
                          return this.props.onChangeSearchParams(tmp);
                        }}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {item.options &&
                          item.options.length > 0 &&
                          item.options.map((option) => {
                            return (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                style={{ width: "100%", fontSize: 14 }}
                              >
                                {option.label}
                              </MenuItem>
                            );
                          })}
                      </AtSelectNew>
                    )}
                    {item.type === "radio" && (
                      <AtlasRadioGroup
                        checkedValue={item.param}
                        containerClassName={"mb-20"}
                        options={item.options}
                        selectedRadioValue={(val) => {
                          let tmp = _.cloneDeep(this.props.searchParams);
                          tmp[index].param = val;
                          return this.props.onChangeSearchParams(tmp);
                        }}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="d-flex" style={{ marginBottom: 15 }}>
            <button
              className="btn-new btn-new--secondary"
              onClick={() => {
                let tmp = "";
                this.props.searchParams.map((item) => {
                  tmp = tmp + `q[${item.value}]=${item.param}&`;
                });
                this.setState({ interval: 10 });
                this.props.getListAPI(1, tmp);
              }}
            >
              Search
            </button>
            <button
              className="btn-new btn-new--danger"
              style={{ marginLeft: 10 }}
              onClick={() => {
                let tmp = _.cloneDeep(this.props.searchParams);
                tmp.map((item) => {
                  item.param = "";
                });
                this.setState({ interval: 10 });
                this.props.onChangeSearchParams(tmp);
                this.props.getListAPI(1, "");
              }}
            >
              Reset
            </button>
          </div>
        )}
        <div>{this.renderTableContent()}</div>
        {this.props.meta && this.props.meta.count > 0 && (
          <div className="at-pagination__cont">
            {this.renderTablePagination()}
            {this.renderPaginationText()}
          </div>
        )}
      </div>
    );
  };
}

export default PaginatedTable;
