import FormInputs from "../formInputs";

const InternationalUpload = ({
  updateNewAgent,
  onChangeField,
  onChangeProfileHOC,
}) => {
  const onChangeFile = (val, id) => {
    onChangeField(
      {
        ...updateNewAgent.agent_registration_attributes,
        [id]: val?.[0]?.source,
        [`${id}_file_name`]: val?.[0]?.name,
      },
      "agent_registration_attributes"
    );
  };

  return (
    <>
      <p className={"mb-4"}>
        Please upload your identification documents for verification purposes.
        The "Submit" button will be available after the documents have been
        uploaded.
      </p>
      <div className={"container-fluid p-0"}>
        <FormInputs
          payload={updateNewAgent.agent_registration_attributes}
          inputConfig={[
            {
              type: "file",
              label: "Identification Document (Front)",
              id: "ic_front",
              required: true,
              span: 6,
            },
            {
              type: "file",
              label: "Identification Document (Back)",
              id: "ic_back",
              span: 6,
            },
          ]}
          onChange={onChangeFile}
        />
      </div>
    </>
  );
};

export default InternationalUpload;
