import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      internalAgents: [],
      searchedAgents: null,
      searchValue: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeInternalHOC = (val, context) => this.setState({ [context]: val });

    getInternalAgent = (dataToSubmit) =>
      Post(
        `/agents/search`,
        dataToSubmit,
        this.getInternalAgentSuccess,
        this.getInternalAgentError,
        this.load,
      );
    getInternalAgentSuccess = (payload) =>
      this.setState({ searchedAgents: [payload] });
    getInternalAgentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchValue={this.state.searchValue}
            searchedAgents={this.state.searchedAgents}
            internalAgents={this.state.internalAgents}
            onLoadInternalAgents={this.state.loading}
            getInternalAgent={this.getInternalAgent}
            onChangeInternalHOC={this.onChangeInternalHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
