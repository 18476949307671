import React, { useState, useEffect } from "react";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtSelectNew from "components/Select/new";
import Carousel from "./components/Carousel";
import FabricContent from "./createFabric";
import HelpInfoContent from "./helpInfo";

import { FabricProvider } from "context/fabric";
import { TypeLib } from "./asset";

const CreateForm = ({
  categories,
  showLoading,
  template = [],

  getGraphicsTemplate,
  onChangeGraphicHOC,
  onClickSubmit,
  onClickClose,
}) => {
  const [tempTemplate, setTemplate] = useState("");
  const [tempFilter, setFilter] = useState("");
  const [tempCategory, setCategory] = useState("");

  useEffect(() => {
    getGraphicsTemplate(1, "");
  }, []);

  useEffect(() => {
    let search = "";

    onChangeGraphicHOC({data: [], meta: {}}, "template");
    if (tempFilter) search = search + `q[type_id_eq]=${tempFilter}&`;
    if (tempCategory) search = search + `category=${tempCategory}&`;
    getGraphicsTemplate(1, search);
  }, [tempFilter, tempCategory]);

  const getTableData = (page) => {
    let search = "";
    if (tempFilter) search = search + `q[type_id_eq]=${tempFilter}&`;
    if (tempCategory) search = search + `category=${tempCategory}&`;
    getGraphicsTemplate(page, search);
  };

  return (
    <>
      <HelpInfoContent />
      <div className="at-form__content" style={{ position: "relative" }}>
        <h2 className="at-form-input__title text-capitalize mt-20">
          Choose a template to create your own graphic
        </h2>
        <AtlasRadioGroup
          horizontal={true}
          checkedValue={tempFilter}
          containerClassName={"w-100"}
          selectedRadioValue={(val) => setFilter(val)}
          options={TypeLib}
        />
        <h2 className="at-form-input__title text-capitalize mt-20">Category</h2>
        <AtSelectNew
          value={tempCategory}
          onChange={(e) => setCategory(e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <MenuItem
            key={"all"}
            value={""}
            style={{ width: "100%", fontSize: 14 }}
          >
            {"All"}
          </MenuItem>
          {categories.map((option) => (
            <MenuItem
              key={option.name}
              value={option.name}
              style={{ width: "100%", fontSize: 14 }}
            >
              {option.name}
            </MenuItem>
          ))}
        </AtSelectNew>
        <div className="at-graphic__carousel">
          {template.length < 1 && (
            <p className="text-white p-3">No template was published</p>
          )}
          {template.length > 0 && (
            <Carousel getTableData={getTableData}>
              {template?.map((img) => (
                <img
                  key={img.id}
                  alt={img.title}
                  src={img.sample_url || img.thumbnail_url}
                  className={`w-auto ${
                    tempTemplate.id === img.id
                      ? "at-graphic__active-template"
                      : ""
                  }`}
                  onClick={() => setTemplate(img)}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
      <FabricProvider>
        <FabricContent
          tempTemplate={tempTemplate}
          onClickSubmit={onClickSubmit}
          onClickClose={onClickClose}
        />
      </FabricProvider>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
