import React from "react";
import _ from "lodash";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import searchParamHandler from "./searchParamHandler";

export default function Pagination({
  meta,
  searchParams,
  interval,

  onChangeInterval,
  getListAPI,
  totalPages,
}) {
  return (
    <>
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = searchParamHandler(item)
                ? tmp + searchParamHandler(item)
                : tmp;
            });
            getListAPI(1, tmp);
            onChangeInterval(10);
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = searchParamHandler(item)
                ? tmp + searchParamHandler(item)
                : tmp;
            });
            getListAPI(meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${interval <= 10 || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(interval);
            onChangeInterval(tmp - 10);
          }}
        >
          ...
        </button>
        {totalPages &&
          totalPages.map((item, index) => {
            if (item + 1 <= interval && item + 1 > interval - 10) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${meta && meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    searchParams.map((item) => {
                      tmp = searchParamHandler(item)
                        ? tmp + searchParamHandler(item)
                        : tmp;
                    });
                    getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && interval >= meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(interval);
            onChangeInterval(tmp + 10);
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = searchParamHandler(item)
                ? tmp + searchParamHandler(item)
                : tmp;
            });
            getListAPI(meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = searchParamHandler(item)
                ? tmp + searchParamHandler(item)
                : tmp;
            });
            getListAPI(meta.pages, tmp);
            onChangeInterval(meta.pages);
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    </>
  );
}
