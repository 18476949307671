import { GET_PA_PERMISSIONS } from "actions/type";
import { GET_AA_PERMISSIONS } from "actions/type";
import { GET_ADMIN_PERMISSIONS } from "actions/type";
import { GET_PM_PERMISSIONS } from "actions/type";

let initialState = {
  pa_permissions: [],
  aa_permissions: [],
  admin_permissions: [],
  pm_permissions: [],
};

export default function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PA_PERMISSIONS:
      return {
        ...state,
        pa_permissions: action.payload,
      };
    case GET_AA_PERMISSIONS:
      return {
        ...state,
        aa_permissions: action.payload.permission_group_modules,
      };
    case GET_ADMIN_PERMISSIONS:
      return {
        ...state,
        admin_permissions: action.payload.permission_group_modules,
      };
    case GET_PM_PERMISSIONS:
      return {
        ...state,
        pm_permissions: action.payload.permission_group_modules,
      };
    default:
      return state;
  }
}
