import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import BirthdayBg from "assets/images/birthday_background.jpg";
import DefaultImage from "assets/images/av-male.jpg";

import { birthdayDataKey } from "../assets";

const BirthdayHOC = (WrappedComponent) => {
  class BirthdayWrappedComponent extends Component {
    state = {
      teamMemberBirthday: [],

      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeMemberBirthdayHOC = (value, context) =>
      this.setState({ [context]: value });

    getTeamMemberBirthday = () => {
      Get(
        `/connector/users/birthday`,
        this.getTeamMemberBirthdaySuccess,
        this.getTeamMemberBirthdayError,
        this.load,
      );
    };

    getTeamMemberBirthdaySuccess = (payload) => {
      let tmpPayload = _.map(payload, (memberInfo) => {
        const { avatar_url, dob } = memberInfo;
        const birthday = dob ? Moment(dob).format("YYYY-MM-DD") : "";
        let tmpInfo = {
          start_date_time: birthday,
          end_date_time: birthday,
          isOneDayEvent: true,
        };

        birthdayDataKey.map((item) => {
          const { key, value } = item;
          return (tmpInfo[key] = memberInfo[value]);
        });

        let tmpAvatarUrl = avatar_url ? avatar_url : "";

        return {
          ...tmpInfo,
          avatar_url:
            tmpAvatarUrl.indexOf("missing.png") > -1
              ? DefaultImage
              : tmpAvatarUrl,
          type: "birthday",
        };
      });

      this.setState({
        teamMemberBirthday: tmpPayload.filter((item) => item.date !== null),
      });
    };

    getTeamMemberBirthdayError = (error) => requestError(error);

    onClickBirthdayDetails = (data) => {
      let selectedBirthday = {
        Event: `${data.title}'s birthday`,
        Date: `${Moment(data.date).date()}-${Moment(data.date).month() + 1}-${data.currentSelectedYear}`,
        Email: data.email,
        "Email Link": `mailto:${data.email}?subject=Happy Birthday From IQI&body=Happy Birthday ${data.title}! IQI wish you all the best and hope you enjoy every moment of your life.`,
        "Mobile Number": data.key,
        "Mobile Number Link": `https://api.whatsapp.com/send?phone=${data.key}&text=Happy Birthday ${data.title}! IQI wish you all the best and hope you enjoy every moment of your life.`,
        "Profile Picture": data.avatar_url || DefaultImage,
        Tier: data.tier,
        Referrer: data.referer,
        Branch: data.branch,
        Type: "birthday",
      };
      this.props.onChangeCalendarHOC(selectedBirthday, "selectedData");
      this.props.onChangeCalendarHOC(BirthdayBg, "defaultModalImage");
      this.props.onChangeCalendarHOC({}, "selected_data_original");
      return this.props.onChangeCalendarHOC(true, "showDetailsDialog");
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadBirthday={this.state.loading}
            teamMemberBirthday={this.state.teamMemberBirthday}
            getTeamMemberBirthday={this.getTeamMemberBirthday}
            onClickBirthdayDetails={this.onClickBirthdayDetails}
          />
        </>
      );
    };
  }
  return BirthdayWrappedComponent;
};

export default BirthdayHOC;
