import { forwardRef, useState, useEffect } from "react";
import { BsArrowUp } from "react-icons/bs";

const BackToTop = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  const currentRef = ref.current;

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 576) {
        setIsVisible(currentRef?.scrollTop > 0);
      }
    };

    currentRef?.addEventListener("scroll", handleScroll);

    return () => {
      currentRef?.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <button
      className={`position-fixed rounded-circle border-white ${isVisible ? "d-block" : "d-none"}`}
      style={{
        bottom: 32,
        right: 8,
        width: 40,
        height: 40,
        backgroundColor: "#F0631D",
        borderWidth: 2,
        borderStyle: "solid",
        color: "#fff",
      }}
      onClick={() => (currentRef.scrollTop = 0)}
    >
      <BsArrowUp style={{ width: 20, height: 20 }} />
    </button>
  );
});

export default BackToTop;
