import React, { useEffect } from "react";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import TimelineComponent from "components/Timeline";

const Activity = ({
  activities,
  selectedRenewal,

  getActivity,
}) => {
  useEffect(() => {
    getActivity(selectedRenewal.id);
  }, []);

  return (
    <div className="at-renewal-details__timeline-cont">
      {activities &&
        activities.length > 0 &&
        activities.map((item) => {
          return (
            <section key={item.id} className="at-renewal-details__timeline">
              <TimelineComponent
                key={item.id}
                content={item.content}
                createdAt={item.created_at}
              />
            </section>
          );
        })}
      {activities && activities.length === 0 && (
        <EmptyState
          title={"No Activity"}
          renderIcon={<AtlasIcon svgHref="atlas-receipt-text" />}
        />
      )}
    </div>
  );
};

export default Activity;
