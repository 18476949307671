import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedRequest: {
        type_id: "",
        remark: "",
        document: null,
        document_file_name: "",
      },
      showUpdateRequest: false,
      showCreateRequest: false,
      showConfirmCreateRequest: false,
      showResubmitConfirmation: false,
      requestsPages: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRequestHOC = (key, val) => this.setState({ [key]: val });

    getRequests = (id, page, search) =>
      Get(
        `/project_sales/${id}/requests?${search}page=${page}`,
        this.getRequestsSuccess,
        this.getRequestsError,
        this.load,
      );
    getRequestsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        requests: payload,
        requestsPages: tmpTotalPages,
      });
    };
    getRequestsError = (error) => requestError(error);

    getSelectedRequest = (sales_id, request_id) =>
      Get(
        `/project_sales/${sales_id}/requests/${request_id}`,
        this.getSelectedRequestSuccess,
        this.getSelectedRequestError,
        this.load,
      );
    getSelectedRequestSuccess = (payload) =>
      this.setState({
        selectedRequest: {
          ...payload,
          document: payload.document_url,
        },
        showUpdateRequest: true,
      });
    getSelectedRequestError = (error) => requestError(error);

    createRequest = (id, dataToSubmit) =>
      Post(
        `/project_sales/${id}/requests`,
        dataToSubmit,
        () => this.createRequestSuccess(id),
        this.createRequestError,
        this.load,
      );
    createRequestSuccess = (id) => {
      this.setState({
        showCreateRequest: false,
        showConfirmCreateRequest: false,
      });

      requestSuccess(
        "The project request has been successfully submitted. The admin team will now review this and process it as necessary.",
      );
      this.getRequests(id, 1, "");
    };
    createRequestError = (error) => requestError(error);

    resubmitRequest = (id, dataToSubmit) =>
      Put(
        `/project_sales/${id}/requests/${dataToSubmit.id}/resubmit`,
        dataToSubmit,
        (payload) => this.resubmitRequestSuccess(id, payload),
        this.resubmitRequestError,
        this.load,
      );
    resubmitRequestSuccess = (id, payload) => {
      this.setState({
        showUpdateRequest: false,
        showResubmitConfirmation: false,
      });
      requestSuccess("Request has been resubmitted successfully.");
      this.getRequests(id, 1, "");
    };
    resubmitRequestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeRequestHOC={this.onChangeRequestHOC}
            getRequests={this.getRequests}
            getSelectedRequest={this.getSelectedRequest}
            createRequest={this.createRequest}
            resubmitRequest={this.resubmitRequest}
            requestsPages={this.state.requestsPages}
            selectedRequest={this.state.selectedRequest}
            requests={this.state.requests}
            onLoadRequests={this.state.loading}
            showResubmitConfirmation={this.state.showResubmitConfirmation}
            showUpdateRequest={this.state.showUpdateRequest}
            showCreateRequest={this.state.showCreateRequest}
            showConfirmCreateRequest={this.state.showConfirmCreateRequest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
