import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Post, Get } from "utils/axios";

import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Full name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Ip address",
    value: "ip_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Location",
    value: "location_cont",
    type: "input",
    param: "",
  },
  {
    label: "Platform Details",
    value: "platform_detail_cont",
    type: "input",
    param: "",
  },
  {
    label: "Browser Details",
    value: "browser_detail_cont",
    type: "input",
    param: "",
  },
  {
    label: "Origin",
    value: "origin_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showConfirmationModal: false,

      selectedSectionId: null,
      sessions: {},
      sessionsPages: null,
      searchParams: searchParams,
      selectedSessions: [],
      selectAllSessionIdLength: 0,
      query: "",
    };

    onClickSelectAll = () => {
      if (
        this.state.selectedSessions.length < this.state.selectAllSessionIdLength
      ) {
        let tmp = [];

        this.state.sessions.data.map((item) => {
          tmp.push(item.id);
        });
        this.setState({ selectedSessions: tmp });
      } else {
        this.setState({ selectedSessions: [] });
      }
    };

    onChangeCheckBox = (id) => {
      let tmp = this.state.selectedSessions;
      if (this.state.selectedSessions.indexOf(id) > -1) {
        tmp.splice(this.state.selectedSessions.indexOf(id), 1);
      } else {
        tmp.push(id);
      }
      this.setState({ selectedSessions: tmp });
    };

    load = (param) => this.setState({ loading: param });

    onChangeSessionHOC = (val, context) => this.setState({ [context]: val });

    // get
    getSessions = (page, search) => {
      this.setState({ query: search });
      Get(
        `/admin/sessions?${search}page=${page}`,
        this.getSessionsSuccess,
        this.getSessionsError,
        this.load,
      );
    };
    getSessionsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        sessions: payload,
        sessionsPages: tmpTotalPages,
      });
      this.setState({
        selectAllSessionIdLength: payload.data.length,
        selectedSessions: [],
      });
    };
    getSessionsError = (error) => requestError(error);

    // delete selected session
    deleteSelectedSessionIds = (ids) => {
      let data = { session_ids: ids };
      let temp = {
        currentPage: this.state.sessions.meta.page,
        searchParams: this.state.query,
      };
      this.props.storeLastView(temp);
      Post(
        `/admin/sessions/bulk_destroy`,
        data,
        this.deleteSelectedSessionsSuccess,
        this.deleteSelectedSessionsError,
        this.load,
      );
    };
    deleteSelectedSessionsSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSessions(currentPage, searchParams);
      this.setState({
        showConfirmationModal: false,
        selectedSessions: [],
        selectAllSessionIdLength: 0,
      });
      requestSuccess(payload.message);
    };
    deleteSelectedSessionsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showConfirmationModal={this.state.showConfirmationModal}
            sessions={this.state.sessions}
            selectedSectionId={this.state.selectedSectionId}
            searchParams={this.state.searchParams}
            sessionsPages={this.state.sessionsPages}
            onLoadSessions={this.state.loading}
            selectAll={this.state.selectAll}
            selectedSessions={this.state.selectedSessions}
            selectAllSessionIdLength={this.state.selectAllSessionIdLength}
            onChangeSessionHOC={this.onChangeSessionHOC}
            deleteSelectedSessionIds={this.deleteSelectedSessionIds}
            getSessions={this.getSessions}
            onClickSelectAll={this.onClickSelectAll}
            onChangeCheckBox={this.onChangeCheckBox}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    storeLastView,
    refreshToken,
  })(WithHOC);
};

export default HOC;
