import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError } from "utils/requestHandler";

const statusOption = [
  { id: 0, label: "Pending" },
  { id: 1, label: "Processing" },
  { id: 2, label: "Arrived" },
  { id: 3, label: "Collected" },
  { id: 4, label: "Rejected" },
];

const productTypeDictionary = [
  { id: 1, label: "Starter Kit" },
  { id: 2, label: "Name Card" },
  { id: 3, label: "Event Ticket" },
];

const searchParams = (branch) => [
  {
    label: "Order Number",
    value: "market_order_items_market_order_order_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "market_order_items_market_product_type_id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "market_order_items_market_order_user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Branch",
    value: "market_order_items_market_order_user_branch_id_eq",
    type: "select",
    param: "",
    options: branch,
  },
  {
    label: "Delivery Branch",
    value: "market_order_items_delivery_branch_id_eq",
    type: "select",
    param: "",
    options: branch,
  },
  {
    label: "Product Name",
    value: "market_order_items_market_product_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Product Type",
    value: "market_order_items_market_product_type_id_eq",
    type: "select",
    param: "",
    options: [
      { id: 1, label: "Starter Kit", value: "1" },
      { id: 2, label: "Name Card", value: "2" },
    ],
  },
  {
    label: "Status",
    value: "market_order_items_status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Pending" },
      { value: "1", label: "Processing" },
      { value: "2", label: "Arrived" },
      { value: "3", label: "Collected" },
      { value: "4", label: "Rejected" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showDetailsDialog: false,
      showImportExcelModal: false,

      showConfirmation: false,

      batchData: [],
      batchMeta: [],
      batchPage: null,
      selectedBatch: {},
      selectedBatchOrder: {},
      selectedBatchOrderPage: null,
      searchParams: searchParams(this.props.data.branchReducer.branch),
    };

    load = (param) => this.setState({ loading: param });

    onChangeNameCardMngmtHOC = (val, context) =>
      this.setState({ [context]: val });

    getBatch = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/order_batches?${search}page=${page}`,
        this.getBatchSuccess,
        this.getBatchError,
        this.load,
      );
    };
    getBatchSuccess = (payload) => {
      let tmpTotalPages = [];
      let tmp = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      if (payload && payload.data.length > 0) {
        payload.data.map((item) => {
          tmp.push({
            ...item,
            created_at: item.created_at
              ? Moment(item.created_at, "DD-MM-YYYY").format("DD MMM YYYY")
              : "N/A",
          });
        });
      }
      this.setState({
        batchData: tmp,
        batchMeta: payload.meta,
        batchPage: tmpTotalPages,
      });
    };
    getBatchError = (error) => requestError(error);

    getSelectedBatch = (id, page, search) =>
      Get(
        `/admin/order_batches/${id}/items?${search}page=${page}`,
        this.getSelectedBatchSuccess,
        this.getSelectedBatchError,
        this.load,
      );
    getSelectedBatchSuccess = (payload) => {
      let tempData = [];
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.length > 0 &&
        payload.data.map((item) => {
          let tempPurchaseType = "N/A";
          if (item.payment_method === "Coupon") {
            tempPurchaseType = "Redeemed by Coupon";
          }
          if (item.payment_method === "Shopping Cart") {
            tempPurchaseType = "Purchase by Cart";
          }
          let tempType = _.find(productTypeDictionary, {
            id: item.market_product.type_id,
          });
          let isProductVariant =
            item.selected_market_product_attached_product_type ===
            "ProductVariant";
          let product_variant = isProductVariant
            ? item.selected_market_product_attached_product.options.map(
                (option, index) =>
                  `${option.value}${index + 1 !== item.selected_market_product_attached_product.options.length ? " - " : ""}`,
              )
            : "";

          tempData.push({
            ...item,
            title: product_variant,
            order_number: item.market_order.order_number,
            product_type: tempType ? tempType.label : "",
            purchase_type: tempPurchaseType,
            product: item.market_product.name || "N/A",
            agent:
              (item.market_order.user.full_name || "N/A") +
              "\n" +
              (item.market_order.user.mobile_contact_number || "N/A") +
              "\n" +
              (item.market_order.user.email || "N/A") +
              "\n" +
              (item.market_order.user.team_name || "N/A") +
              "\n" +
              (item.market_order.user.branch_name || "N/A"),
            quantity: `${item.quantity} ${item.market_product.unit_measurement}`,
            price: item.price.toFixed(2),
          });
        });
      payload.data = tempData;

      this.setState({
        selectedBatchOrder: payload,
        selectedBatchOrderPage: tmpTotalPages,
        showDetailsDialog: true,
      });
    };
    getSelectedBatchError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          batchData={this.state.batchData}
          batchMeta={this.state.batchMeta}
          batchPage={this.state.batchPage}
          selectedBatch={this.state.selectedBatch}
          onLoadNameCardMngmt={this.state.loading}
          showImportExcelModal={this.state.showImportExcelModal}
          showDetailsDialog={this.state.showDetailsDialog}
          showConfirmation={this.state.showConfirmation}
          selectedBatchOrder={this.state.selectedBatchOrder}
          selectedBatchOrderPage={this.state.selectedBatchOrderPage}
          searchParams={this.state.searchParams}
          getBatch={this.getBatch}
          getSelectedBatch={this.getSelectedBatch}
          onChangeNameCardMngmtHOC={this.onChangeNameCardMngmtHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
