import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import Tab from "components/Tab";
import AtlasCard from "components/Card";
import AlertBox from "components/AlertBox";
import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import StepIndicator from "components/StepIndicator";
import SentRequest from "./components/SentCobrokeRequest";
import ProjectPartnerContent from "./components/ProjectPartnerContent";
import ReceivedRequest from "./components/ReceivedCobrokeRequest";
import ViewPropertyContent from "./components/Listing/View";
import ListingContent from "./components/Listing/index";

import CoBrokeHOC from "./actions/index";
import SubsalesStatusHOC from "actions/subsalesStatus";
import {
  PrimaryTabOptions,
  TabOptions,
  percentageGuideline,
  counterInstruction,
} from "./assets";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";
import "./index.scss";

class CoBrokeCenter extends Component {
  state = {
    showInfoBox: false,
  };

  componentDidMount = () => {
    initGA("/dashboard/cobroke");
    this.props.getCommRate();
    this.props.getCountryDictionary();
    this.props.getRequestCount(
      window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer.id
        : this.props.data.profileReducer.id,
    );
  };

  renderTabContent = () => {
    const { primaryTab, currentTab } = this.props;

    if (primaryTab === "Secondary Market" && currentTab === 0) {
      return <ListingContent />;
    } else if (primaryTab === "Project Partner" && currentTab === 3) {
      return <ProjectPartnerContent />;
    } else if (currentTab % 2 === 0) {
      return <ReceivedRequest {...this.props} />;
    } else if (currentTab % 2 > 0) {
      return <SentRequest {...this.props} />;
    }
  };

  renderInfoBoxContent = () => (
    <>
      <div className="d-flex align-items-center mt-3">
        <span className="fw-500">How Project Partner works</span>
      </div>
      <div className="at-cobroke__project-partner-instruction">
        <StepIndicator
          className={"my-3"}
          enableProgressive={true}
          stepList={counterInstruction}
          onClick={() => {}}
        />
        <label>Sales Process and Co-broke Commission</label>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          You and your project partner will agree on the commission split, but
          by default the split will be as follows:
        </p>
        <div className="d-flex justify-content-around my-2">
          {percentageGuideline.map((item, index) => (
            <div
              key={`percent-guideline-${index}`}
              className={"at-project-partner__commision-percent"}
            >
              <p>{item.percent}</p>
              {item.label}
            </div>
          ))}
        </div>
        <label className="mt-2">Get Started</label>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          1. Search for a Project Partner using the fields below. Atlas will
          show you the top selling agents for your choice of project, team
          and/or area. It's best to find agents who are specialists in the
          project or area you think your contact will buy.
          <br />
          2. Choose an agent and send arequest. You wil need to agree to the
          terms of the co-broke agreement.
        </p>
      </div>
    </>
  );

  renderDescription = () => {
    if (this.props.primaryTab === "Secondary Market") {
      return (
        <div className="at-form__content at-cobroke__tab-description">
          Use the Atlas Co-broke Centre to find internal Co-broke opportunities
          such as property listings for your buyers or tenants. Atlas will
          manage the internal agreement process for you so you don't have to
          worry about paper agreements. Atlas will send you a notification when
          you receive a new Co-broke request.
          <br />
          To get started, click on the <strong>{` Search Listing`}</strong>{" "}
          button to search for suitable listings from internal agents.
        </div>
      );
    } else if (this.props.primaryTab === "Project Partner") {
      return (
        <div className="at-form__content">
          <div className="at-cobroke__tab-description">
            With Atlas's Project Partner you can unlock the value within your
            network of contacts and earn more commission from property sales. If
            you are a secondary market expert or not yet an expert on primary
            project (new build) sales, partner with one or more top performers
            from primary sales and work with them to sell new projects them -
            and earn commission based on your co-broked contribution.
          </div>
          <CustomInfobox
            expand={this.state.showInfoBox}
            inactiveContent={"How Project Partner works"}
            activeContent={this.renderInfoBoxContent()}
            onClickExpandInfo={(val) => this.setState({ showInfoBox: val })}
          />
        </div>
      );
    }
  };

  renderCardContent = () => {
    const {
      primaryTab,
      currentTab,
      totalPendingRequest,
      completed_trainings,
      whitelisted,
    } = this.props;
    const { can_create } = permissionsChecker(
      "Co Broke Center",
      this.props.data,
    );
    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const status_permissions =
      (status_id !== 2 && completed_trainings) || whitelisted;

    return (
      <>
        <Tab
          containerStyle={{ paddingLeft: 0 }}
          selectedSection={primaryTab}
          sections={PrimaryTabOptions}
          onSelectSection={(val) => {
            this.props.onChangeCobrokeHOC(
              val === "Project Partner" ? 3 : can_create ? 0 : 1,
              "currentTab",
            );
            this.props.onChangeCobrokeHOC(val, "primaryTab");
          }}
        />
        {this.renderDescription()}
        <div className="info-tabs mt-3">
          {TabOptions(primaryTab, status_permissions, can_create).map(
            (item) => (
              <button
                key={item.id}
                className={`at-tab__button ${currentTab === item.id ? "at-tab__selected" : ""}`}
                onClick={() => {
                  Promise.all([
                    this.props.onChangeCobrokeHOC(
                      +item.id > 2 ? "RecommendationEngine" : "CoBrokeCenter",
                      "requestMode",
                    ),
                  ]).then(() => {
                    this.props.onChangeCobrokeHOC(+item.id, "currentTab");
                  });
                }}
              >
                <AtlasIcon svgHref={item.icon} />
                {item.label === "Search Project Partner" &&
                window.innerWidth < 576
                  ? "Search"
                  : item.label}
                {item.label.indexOf("Received") > -1 &&
                  _.keys(totalPendingRequest).indexOf(item.type) > -1 &&
                  totalPendingRequest[item.type] !== 0 && (
                    <span className="at-cobroke__pending-badge">
                      {totalPendingRequest[item.type]}
                    </span>
                  )}
              </button>
            ),
          )}
        </div>
        {this.renderTabContent()}
      </>
    );
  };

  render = () => {
    const { completed_trainings, onLoadCobroke } = this.props;
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Co-broke Centre"}
          atlasIcon={"atlas-cobroke-centre"}
          showPABadge={role === "Personal Assistant"}
          renderAlertContent={
            <>
              {status_id !== 2 && !completed_trainings && (
                <AlertBox
                  mode={"danger"}
                  title={"Training not completed."}
                  containerClassName={status_id === 2 ? 'mb-3' : ''}
                  description={
                    "You have not completed your training. You are required to finish training before continuing. Please contact support if you have any questions."
                  }
                />
              )}
              {status_id === 2 && (
                <AlertBox
                  mode={"danger"}
                  description={
                    "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
                  }
                />
              )}
            </>
          }
        />
        <AtlasCard cardContent={this.renderCardContent()} />
        {this.props.showPropertyDetails && (
          <ViewPropertyContent
            {...this.props}
            selectedListing={this.props.selectedPropertyDetails}
            onClose={() =>
              this.props.onChangeCobrokeHOC(false, "showPropertyDetails")
            }
          />
        )}
        {onLoadCobroke && <LoadingModal />}
      </>
    );
  };
}

export default compose(SubsalesStatusHOC, CoBrokeHOC)(CoBrokeCenter);
