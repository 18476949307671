import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { GetFile } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      selectedPreview: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getPreview = (claim_id) =>
      GetFile(
        `/sub_sale_claims/${claim_id}/preview`,
        "claim_preview.pdf",
        () => {},
        this.getPreviewError,
        this.load,
      );
    getPreviewError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedPreview={this.state.selectedPreview}
            getPreview={this.getPreview}
            onLoadPreview={this.state.loading}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
