import React, { useEffect } from "react";

import AtlasCloseButton from "components/Button/prev";
import ViewSDKClient from "components/ViewSDKClient";

const ProofPreview = ({
  paymentProof,

  onClickUpload,
  onClose,
}) => {
  useEffect(() => {
    if (
      paymentProof.proof_of_payment &&
      paymentProof.proof_of_payment.includes("application/pdf")
    ) {
      const viewSDKClient = new ViewSDKClient({
        ...paymentProof,
        display_name: paymentProof.proof_of_payment_file_name,
        url: paymentProof.proof_of_payment,
      });
      viewSDKClient.ready().then(() => {
        /* Invoke file preview */
        /* By default the embed mode will be Full Window */
        viewSDKClient.previewFile("pdf-div", {});
      });
    }
  }, [paymentProof]);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h2 className="at-card__title">Proof of Payment</h2>
      </div>
      {paymentProof.proof_of_payment &&
        paymentProof.proof_of_payment.includes("image/") && (
          <img
            src={paymentProof.proof_of_payment}
            alt={paymentProof.proof_of_payment_file_name}
            style={{ width: "100%" }}
          />
        )}
      {paymentProof.proof_of_payment &&
        paymentProof.proof_of_payment.includes("application/pdf") && (
          <div id="pdf-div" className="at-attendee__pdf-preview" />
        )}
      <div className="mt-2">
        <button
          className="btn-new btn-new--primary"
          onClick={() => onClickUpload()}
        >
          Upload
        </button>
        <button
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClose()}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default ProofPreview;
