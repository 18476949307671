import { UPDATE_APP_SETTING } from "actions/type";

const initialState = {
  appSetting: {
    darkMode: false,
  },
};

export default function appSettingReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_APP_SETTING:
      return {
        ...state,
        appSetting: action.payload,
      };
    default:
      return state;
  }
}
