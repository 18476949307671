import { useEffect, useState } from "react";

import AtlasButton from "components/Button";
import ModuleFormHeader from "components/Form/header";
import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";
import RadioGroup from "components/RadioGroup";

const EditProfileModal = ({
  renData,
  detailData,
  onLoadAdminApproval,
  onClose,
  updatePendingNewREN,
}) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    if (detailData.id) {
      setValues(detailData);
    }
  }, [detailData]);

  const handleChange = (key, val) => {
    setValues((values) => ({
      ...values,
      [key]: val,
    }));
  };

  const renderFooter = () => (
    <div className={"d-flex align-item-center g-3"}>
      <AtlasButton
        className={"btn-new btn-new--success"}
        onClick={() => updatePendingNewREN(values)}
      >
        Update
      </AtlasButton>
      <AtlasButton
        className={"btn-new btn-new--outline-secondary"}
        onClick={onClose}
      >
        Close
      </AtlasButton>
    </div>
  );

  return (
    <ModalDialog
      title={"Edit Profile"}
      onClose={onClose}
      responsiveSize={"lg"}
      onLoad={onLoadAdminApproval}
      footer={renderFooter()}
    >
      <div className="at-form__content">
        <ModuleFormHeader
          title={"Profile"}
          className={"mb-3"}
          moduleIcon={"atlas-user"}
        />
        <div className={"form-row"}>
          {renData.slice(0, 5).map((field) => (
            <div
              key={field.label}
              className={`col-lg-${field.col?.[0] || 4} col-md-${field.col?.[1] || 6} mb-3`}
            >
              <label className={"at-form-input__title"}>{field.label}</label>
              <CustomFormInput
                containerClass={"mb-0"}
                value={values[field.value] || ""}
                onChangeValue={(val) => handleChange(field.value, val)}
              />
            </div>
          ))}
          {renData
            .slice(5)
            .filter((field) => field.value !== "via_recruitment_campaign")
            .map((field) => (
              <div key={field.label} className={"col-lg-4 col-md-6 mb-3"}>
                <label className={"at-form-input__title"}>
                  {field.label}
                </label>
                {field.type !== "radio" && (
                  <CustomFormInput
                    containerClass={"mb-0"}
                    value={values[field.value] || ""}
                    onChangeValue={(val) => handleChange(field.value, val)}
                  />
                )}
                {field.type === "radio" && (
                  <RadioGroup
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    checkedValue={values[field.value]}
                    selectedRadioValue={(val) => handleChange(field.value, val)}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </ModalDialog>
  );
};

export default EditProfileModal;
