import { Suspense, lazy, useEffect, useRef, useState } from "react";

import BackToTop from "components/Button/backToTop";
import AtlasCloseButton from "components/Button/prev";
import CustomCard from "components/Card";
import AtlasTabs from "components/Tab";

import withUpdateProject from "./actions";

import permissionsChecker from "utils/permissionsChecker";

const Details = lazy(() => import("./Details"));
const ProjectUnitTypes = lazy(() => import("./ProjectUnitTypes"));
const ProjectUnits = lazy(() => import("./ProjectUnits"));
const ReservationSettings = lazy(() => import("./ReservationSettings"));
const ReservationForm = lazy(() => import("./ReservationForm"));
const DiscountSchemes = lazy(() => import("./DiscountSchemes"));
const ServiceAgents = lazy(() => import("./ServiceAgents"));

const UpdateProject = ({
  data,
  selectedProject,
  updateProject,
  onLoadEdit,
  getAllProjects,
  onChangeProjectManagementHOC,
  getProject,
  onLoadProjects,
  updateSelectedProject,
}) => {
  const cardRef = useRef(null);
  const [activeTab, setActiveTab] = useState("Details");
  const [tabs, setTabs] = useState(["Details"]);

  useEffect(() => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    const { can_read: can_read_unit_type } = permissionsChecker(
      "Projects - Project Unit Types",
      data
    );
    const { can_read: can_read_unit } = permissionsChecker(
      "Projects - Project Units",
      data
    );
    const { can_read: can_read_setting } = permissionsChecker(
      "Entity Settings",
      data
    );
    const { can_read: can_read_discount } = permissionsChecker(
      "Projects - Discount Schemes",
      data
    );
    let otherTabs = [];

    if (role === "Super Admin") {
      otherTabs = [
        "Project Unit Types",
        "Project Units",
        "Reservation Settings",
        "Reservation Form",
        "Discount Schemes",
        "Service Agents",
      ];
    } else {
      if (can_read_unit_type) {
        otherTabs.push("Project Unit Types");
      }

      if (can_read_unit) {
        otherTabs.push("Project Units");
      }

      if (can_read_setting) {
        otherTabs.push("Reservation Settings", "Reservation Form");
      }

      if (can_read_discount) {
        otherTabs.push("Discount Schemes");
      }
    }

    setTabs((tabs) => [...tabs, ...otherTabs]);
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            data={data}
            selectedProject={selectedProject}
            onLoadProjects={onLoadProjects}
            onLoadEdit={onLoadEdit}
            onChangeProjectManagementHOC={onChangeProjectManagementHOC}
            getAllProjects={getAllProjects}
            onChange={updateSelectedProject}
            submitAction={updateProject}
          />
        );
      case "Service Agents":
        return <ServiceAgents selectedProject={selectedProject} />;
      case "Project Unit Types":
        return <ProjectUnitTypes selectedProject={selectedProject} />;
      case "Project Units":
        return <ProjectUnits selectedProject={selectedProject} />;
      case "Reservation Settings":
        return (
          <ReservationSettings
            getSelectedProject={getProject}
            selectedProject={selectedProject}
          />
        );
      case "Reservation Form":
        return (
          <ReservationForm
            getSelectedProject={getProject}
            selectedProject={selectedProject}
          />
        );
      case "Discount Schemes":
        return <DiscountSchemes selectedProject={selectedProject} />;
      default:
        return null;
    }
  };

  return (
    <CustomCard
      ref={cardRef}
      containerStyle={{ scrollBehavior: "smooth" }}
      className={"h-100 at-project-detail__main-card"}
      cardContent={
        <div className={"container at-projects__edit"}>
          <div className={"at-card__header"}>
            <AtlasCloseButton
              onClick={() =>
                onChangeProjectManagementHOC(false, "showEditDetailsDialog")
              }
            />
            <h4 className={"at-card__title"}>Edit Project</h4>
          </div>
          <AtlasTabs
            sections={tabs}
            selectedSection={activeTab}
            onSelectSection={setActiveTab}
          />
          <Suspense
            fallback={
              <div className={"at-loader my-4"} style={{ top: "auto" }} />
            }
          >
            {renderTabContent()}
          </Suspense>
          <BackToTop ref={cardRef} />
        </div>
      }
    />
  );
};

export default withUpdateProject(UpdateProject);
