import React, { useState } from "react";
import _ from "lodash";
import { SnackbarContent } from "@material-ui/core";
import { IoMdAlert } from "react-icons/io";

import CustomButton from "components/Button";

import ProcessSection from "./process";

export default function InitiatedProcessContent(props) {
  const {
    history,
    partyData,
    signingStatusId,
    refundStatusId,
    refundStatus,
    signingStatus,
    signingOption,
    refundFormData,
    selected_confirmation_form,
    menuExpand,

    eProcessPartyData,
    can_update,
    can_create,
    tempPercentage,
    tempRefundPercentage,

    getParty,
    validateForm,
    getSigningStatus,
    onChangePartyHOC,
    onChangeConfirmationHOC,
    downloadCompletedForm,
    downloadAckReceipt,
    renderNavButton,
  } = props;

  const [showProcessSection, setShowProcessSection] = useState(false);

  return (
    <>
      <div className="d-flex grid_gap-1 mb-3">
        {refundStatusId === `0` && (
          <>
            {can_update && (
              <button
                className={"btn-new btn-new--secondary"}
                onClick={() => onChangePartyHOC(true, "showRefundFormModal")}
              >
                Edit Refund Form
              </button>
            )}
            {can_create && (
              <button
                className={"btn-new btn-new--secondary"}
                disabled={
                  partyData.some((item) => item.data.length < 1) ||
                  selected_confirmation_form.is_claimed
                }
                onClick={() =>
                  validateForm(
                    {
                      form_id: refundFormData.id,
                      form_type: 2,
                      signing_type_id: signingOption,
                    },
                    "refund",
                  )
                }
              >
                Continue to confirm refund e-signing
              </button>
            )}
          </>
        )}
        {can_update && (refundStatusId === `3` || refundStatusId === `1`) && (
          <button
            diabled={selected_confirmation_form.is_claimed}
            className={"btn-new btn-new--outline-danger mr-10"}
            onClick={() =>
              onChangePartyHOC(true, "showResetRefundConfirmationModal")
            }
          >
            Reset refund e-signing process
          </button>
        )}
        {(signingStatusId === `3` || signingStatusId === `1`) &&
          !refundStatusId &&
          can_update && (
            <button
              className={"btn-new btn-new--outline-danger"}
              onClick={() =>
                onChangePartyHOC(true, "showResetConfirmationModal")
              }
            >
              Reset e-Signing process
            </button>
          )}
        {signingStatusId === `3` && !refundStatusId && can_create && (
          <CustomButton
            key={"confirmation-form-abort-button"}
            disabled={selected_confirmation_form.is_claimed}
            className={"btn-new btn-new--outline-danger"}
            tooltip={true}
            tooltipPosition={"top"}
            tooltipID={`confirmation-form-abort-button-tooltip`}
            tooltipChildren={
              <span>
                Abort the deal and request authorisation <br /> to
                refund/forfeit earnest deposit.
              </span>
            }
            onClick={() => onChangePartyHOC(true, "showAbortConfirmationModal")}
            children={"Abort the deal"}
          />
        )}
        {(refundStatusId === `2` || signingStatusId === `2`) && (
          <button
            className="btn-new btn-new--secondary"
            onClick={() =>
              downloadCompletedForm({
                formId:
                  signingStatusId === `2`
                    ? selected_confirmation_form.id
                    : refundFormData.id,
                formType: signingStatusId === `2` ? 1 : 2,
                filename: `${selected_confirmation_form.transaction_number}_e_process.pdf`,
              })
            }
          >
            {`Download ${refundStatusId === `2` ? "Refund" : "Confirmation"} Form`}
          </button>
        )}
        {(signingStatusId === "1" ||
          (signingStatusId === "3" && refundStatusId === "1")) && (
          <button
            className={"btn-new btn-new--secondary"}
            onClick={() => {
              getParty(selected_confirmation_form.id);
              getSigningStatus(selected_confirmation_form.id);
            }}
          >
            Check Status
          </button>
        )}
      </div>
      {signingStatusId === "3" && refundStatusId !== null && (
        <SnackbarContent
          style={{
            maxWidth: "100%",
            marginBottom: 20,
            backgroundColor: "#f63c3c",
            cursor: "pointer",
          }}
          onClick={() => setShowProcessSection(!showProcessSection)}
          message={
            <div className={"d-flex align-items-center"}>
              <IoMdAlert style={{ width: 40, marginRight: 10, minWidth: 20 }} />
              <div className={"d-flex"}>
                e-Signing process has been rejected. Click here for more
                information.
              </div>
            </div>
          }
        />
      )}
      {(refundStatusId === null || showProcessSection) && (
        <ProcessSection
          type={"confirmation"}
          status={signingStatus}
          can_create={can_create}
          tempPercentage={tempPercentage}
          refundFormId={refundFormData.id}
          selected_confirmation_form={selected_confirmation_form}
          eProcessPartyData={eProcessPartyData}
          onClickViewActivity={() => {
            Promise.all([onChangePartyHOC(1, "formTypeActivity")]).then(() => {
              onChangePartyHOC(true, "showSigningHistory");
            });
          }}
          getSigningStatus={getSigningStatus}
          getParty={getParty}
        />
      )}
      {refundStatusId !== null && (
        <SnackbarContent
          style={{
            maxWidth: "100%",
            marginBottom: 20,
            backgroundColor: "#f6b13c",
          }}
          message={
            <div className={"d-flex align-items-center"}>
              <IoMdAlert style={{ width: 40, marginRight: 10, minWidth: 20 }} />
              <div className={"d-flex"}>
                The e-Signing process to request the authorisation for
                refund/forfeit the earnest deposit has been started
              </div>
            </div>
          }
        />
      )}
      {refundStatusId !== null && (
        <ProcessSection
          type={"refund"}
          can_create={can_create}
          status={refundStatus}
          tempPercentage={tempRefundPercentage}
          refundFormId={refundFormData.id}
          selected_confirmation_form={selected_confirmation_form}
          eProcessPartyData={eProcessPartyData}
          getSigningStatus={getSigningStatus}
          getParty={getParty}
        />
      )}
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        {signingStatusId === `1` &&
          !refundStatusId &&
          can_update &&
          !selected_confirmation_form.is_wizard_form && (
            <button
              disabled={
                selected_confirmation_form.is_claimed ||
                !_.values(eProcessPartyData).some((val) => val.status_id === 2)
              }
              className={"btn-new btn-new--success"}
              onClick={() =>
                onChangePartyHOC(true, "showCompleteConfirmationModal")
              }
            >
              Complete e-Signing Process
            </button>
          )}
        {renderNavButton && renderNavButton()}
      </div>
    </>
  );
}
