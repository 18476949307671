import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";

import BackgroundImg from "assets/images/login_bg.jpg";
import IQILogo from "assets/images/iqi_logo.png";
import CheckIcon from "assets/images/check.png";
import WarningIcon from "assets/images/warning.png";

import { importRegistration } from "actions/registration";
import { getItem } from "utils/tokenStore";

class Feedback extends Component {
  state = {
    countDown: 10,
  };
  componentDidMount = () => {
    let registerData = [];
    let tempObj = {};
    let paramData = window.location.href.split("?");
    paramData = paramData[1];
    registerData = paramData ? paramData.split("&") : [];
    registerData.forEach((item) => {
      let temp = item.split("=");
      tempObj[temp[0]] = temp[1];
    });
    Promise.all([
      () => this.props.importRegistration({ message: tempObj }),
    ]).then(() => {
      setInterval(() => {
        if (this.state.countDown > 1) {
          this.setState((prevState) => ({
            countDown: prevState.countDown - 1,
          }));
        } else {
          window.location.href = this.paymentRedirect();
        }
      }, 1000);
    });
  };

  paymentRedirect = () => {
    const authToken =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
        : getItem("IQI_ATLAS_JWT_TOKEN");
    return authToken === null
      ? "/#/register/register-payment/"
      : "/#/register/payment/";
  };

  render = () => {
    const { match } = this.props;
    return (
      <div>
        <img
          src={BackgroundImg}
          alt={"LoginBackground"}
          style={{ width: "100vw", height: "100vh" }}
        />
        <div className="login-container">
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img src={IQILogo} alt={"IqiLogo"} style={{ width: "114px" }} />
          </div>
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card
              style={{
                padding: 15,
                paddingTop: 35,
                paddingBottom: 35,
                marginTop: 30,
                maxHeight: "80vh",
                overflowY: "auto",
                width: "70%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {match.params.remark === "success" && (
                <>
                  <img src={CheckIcon} style={{ marginBottom: "15px" }} />
                  <p>{"Payment completed successfully."}</p>
                </>
              )}
              {match.params.remark !== "success" && (
                <>
                  <img src={WarningIcon} style={{ marginBottom: "15px" }} />
                  <p>{"Failed to complete the payment process."}</p>
                </>
              )}
              <p className={"mt-1"}>
                {`You will be redirect to the payment page after ${this.state.countDown} seconds or click `}
                <a href={this.paymentRedirect()}>here</a>
                {`  manually.`}
              </p>
            </Card>
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, { importRegistration })(Feedback);
