import React, { useState, useEffect } from "react";

import TableHeader from "components/NewPaginatedTable/Header";
import TableContent from "components/NewPaginatedTable/Content";
import CustomPagination from "components/Pagination/New";

const SubsalesTable = ({ rowItem, columns, pagination }) => {
  const selectPerPageOption = 12;
  const [clickPageNumber, setClickPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState([1]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    processPagesData(rowItem, selectPerPageOption);
  }, [clickPageNumber]);

  useEffect(() => {
    setRowData(rowItem);
    processPagesData(rowItem, selectPerPageOption);
  }, [rowItem, columns]);

  const onClickPageNumber = (val) => setClickPageNumber(val);

  const processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let result = [];
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      let pageNumTmp = selectPerPageOption;
      let startIndex = 0;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
        startIndex = pageNumTmp - selectPerPageOption;
      }
      if (pageNumTmp > rowItem.length) {
        pageNumTmp = rowItem.length;
      }

      for (let i = startIndex; i < pageNumTmp; i++) {
        result.push(rowData[i]);
      }

      Promise.all([setRowData(result)]).then(() => {
        setTotalPages(pagesArrayData);
      });
    }
  };

  const renderPaginationText = () => {
    const processFloorPaginationCount = () => {
      let pageNumTmp = clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = selectPerPageOption;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowItem.length) {
        pageNumTmp = rowItem.length;
      }
      return pageNumTmp;
    };
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <b>
          {" "}
          {pagination ? processFloorPaginationCount(clickPageNumber) : ""}{" "}
        </b>
        to
        <b>
          {" "}
          {pagination
            ? processCeilingPaginationCount(selectPerPageOption)
            : ""}{" "}
        </b>
        from a total of
        <b> {rowItem ? rowItem.length : 0} </b>
        row
      </p>
    );
  };

  const renderPagination = () => {
    return (
      <CustomPagination
        totalPages={totalPages}
        updateCurrentPageNumber={(val) => onClickPageNumber(val)}
        currentPageNumber={clickPageNumber}
      />
    );
  };

  return (
    <div className="at-table at-table--set">
      <TableHeader columns={columns} enableSort={true} />
      {rowData &&
        rowData.length > 0 &&
        rowData.map((item, index) => (
          <TableContent columns={columns} index={index} rowData={item} />
        ))}
      <div className="at-pagination__cont">
        {renderPagination()}
        {renderPaginationText()}
      </div>
    </div>
  );
};

export default SubsalesTable;
