import React, { useState } from "react";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import RecommendationTable from "../components/RecommendationTable";

import permissionsChecker from "utils/permissionsChecker";

export default function RecommendationModalContent({
  data,
  onLoad,
  fullRecommendationList,

  updateCobrokeStatus,
  onClose,
}) {
  const { can_create } = permissionsChecker("Listing Centre", data);

  const { sideMenuExpand } = data.sideMenuReducer;
  const [showRequestConfirmation, onToggleConfirmation] = useState(false);
  const [selectedRCC, onChangeSelectedRCC] = useState(null);

  return (
    <>
      <div className={`${sideMenuExpand ? "menu-rcc-expand" : ""}`}>
        <div className={`at-card__header`}>
          <AtlasCloseButton onClick={() => onClose()} />
          <h4 className="at-card__title">Co-broke Recommendation List</h4>
        </div>
        <RecommendationTable
          rowData={fullRecommendationList}
          originalRowData={fullRecommendationList}
          showActionButton={can_create}
          onToggleConfirmation={onToggleConfirmation}
          onChangeSelectedRCC={onChangeSelectedRCC}
        />
      </div>
      <AtlasConfirmationDialog
        open={showRequestConfirmation}
        loading={onLoad}
        mode={"alert"}
        cardMessageStyle={{ textAlign: "left" }}
        message={
          <>
            <p>
              {`Atlas will send a Co-broke request to this agent 
                  with the details of this listing. The agent will receive a notification and you
                  will then have to wait for them to either accept or decline your request 
                  depending on whether they have a potential counterparty such as a buyer
                  or tenant for this listing.`}
            </p>
            <br />
            <p>
              Click <strong>{` Yes `}</strong> if you want to send this request
              to the agent for a potential Co-broke. Click{" "}
              <strong>{` No `}</strong> if you don’t want to.
            </p>
          </>
        }
        positiveAction={() => {
          updateCobrokeStatus(selectedRCC, 2);
          onToggleConfirmation(false);
        }}
        negativeAction={() => onToggleConfirmation(false)}
      />
      {onLoad && <LoadingModal />}
    </>
  );
}
