import React from "react";
import { Card, IconButton, Typography } from "@material-ui/core";

import checkValid from "utils/checkValid";
import { IoMdClose } from "react-icons/io";

const RENCard = ({
  item,
  mode,
  disabled,
  isAssignedAgent,
  index,
  onDeleteREN,
  onChangeRENPercentage,
}) => {
  const {
    agent_name,
    agent_email,
    agent_mobile_number,
    agent_team,
    percentage,
  } = item;
  return (
    <>
      <Card classes={{ root: "at-assigned-card" }}>
        {isAssignedAgent && (
          <span
            className="badge badge-pill badge-primary align-items-center mb-1 ml-n1"
            style={{ fontWeight: 500 }}
          >
            Primary Agent
          </span>
        )}
        <div className="d-flex align-items-center">
          <Typography variant="h6">{checkValid(agent_name)}</Typography>
          {mode === "edit" && (
            <IconButton
              className={"d-flex"}
              style={{ padding: 0, marginLeft: "auto", width: 22, height: 22 }}
              onClick={() => onDeleteREN(item)}
            >
              <IoMdClose style={{ color: "white" }} />
            </IconButton>
          )}
        </div>
        <Typography variant="subtitle1">{checkValid(agent_email)}</Typography>
        <Typography variant="subtitle1">
          {checkValid(agent_mobile_number)}
        </Typography>
        <Typography variant="subtitle1" className={"mb-2"}>
          {checkValid(agent_team)}
        </Typography>
        <div className="d-flex align-items-center">
          <input
            className={"at-assigned-card-input w-100"}
            disabled={disabled || mode === "view"}
            type="number"
            value={percentage}
            step={0.01}
            onChange={(e) =>
              onChangeRENPercentage(parseFloat(e.target.value), index)
            }
          />
          <Typography variant="subtitle1">%</Typography>
        </div>
      </Card>
    </>
  );
};
export default RENCard;
