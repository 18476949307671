import React, { useEffect, useState, useCallback } from "react";
import { MdFilterList } from "react-icons/md";
import { MenuItem } from "@material-ui/core";
import _ from "lodash";

import CustomTableContent from "components/NewTable/content";
import CustomTableHeader from "components/NewTable/header";
import ModalDialog from "components/Modal/ModalDialog";
import CustomPagination from "components/Pagination";
import AtSelectNew from "components/Select/new";
import EmptyState from "components/EmptyState";

import "stylesheets/components/table/index.scss";

const PersonalCampaignTable = ({
  mode = "desktop",
  className,
  rowData: initialRowData,
  columns,
  selectPerPageOption,
  actionColumnContent,
  hidePagination,
  pagination,
  hideSearch,
  searchParams,
  onChangeSearchParams,
  getListAPI,
  showResetButton,
  renderCheckBoxActions,
  renderAdditionalContent,
  actionAfterChangedPage,
  customEmptyState
}) => {
  const [rowData, setRowData] = useState([]);
  const [searchKeyword, setKeyWord] = useState("");
  const [clickPageNumber, setPageNum] = useState(1);
  const [totalPages, setTotalPage] = useState([1]);
  const [currentPageOption, onChangePerPage] = useState(10);
  const [headerRearrangeData, setHeaderRearrangeData] = useState({});
  const [currentPageData, setCurrentPageData] = useState([]);
  const [showSearchModal, onToggleSearchModal] = useState(false);

  useEffect(() => {
    selectPerPageOption && onChangePerPage(selectPerPageOption);
  }, []);

  useEffect(() => {
    setRowData(initialRowData);
    setPageNum(1);
    setKeyWord("");
    processPagesData(initialRowData, selectPerPageOption || 10);
  }, [initialRowData]);

  useEffect(() => {
    let tmp = rowData.filter((item, index) => {
      if (
        index + 1 > (clickPageNumber - 1) * currentPageOption &&
        index + 1 <= clickPageNumber * currentPageOption
      ) {
        return item;
      }
    });
    setCurrentPageData(tmp);
    actionAfterChangedPage &&
      actionAfterChangedPage({
        rowData,
        currentPageData: tmp,
        clickPageNumber,
        currentPageOption,
      });
  }, [clickPageNumber, currentPageOption, rowData]);

  const onChangeFilter = (index, value) => {
    let tmp = _.cloneDeep(searchParams);
    tmp[index].param = value;
    
    return onChangeSearchParams(tmp);
  };

  const renderInputForm = (item, index) => (
    <>
      {item.type === "select" && (
        <AtSelectNew
          value={item.param}
          onChange={(e) => onChangeFilter(index, e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options &&
            item.options.map((option) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.valueKey ? option[item.valueKey] : option.value}
                value={item.valueKey ? option[item.valueKey] : option.value}
                style={{ fontSize: 14 }}
              >
                {option.label}
              </MenuItem>
            ))}
        </AtSelectNew>
      )}
    </>
  );

  const resetTable = () => {
    let tmp = _.cloneDeep(searchParams);
    const currentYear = new Date().getFullYear()
    tmp.map((item) => {
      if (item.type === "select-multi") {
        item.param = [];
      } else if ( item.value === "year") {
        item.param = currentYear;
      } else {
        item.param = "";
      }
    });
    onChangeSearchParams(tmp);
    getListAPI(true,currentYear)
  };

  const renderSearchReset = () => (
    <div className="d-flex">
      {showResetButton && (
        <button
          className="btn-new btn-new--outline-secondary mr-10"
          onClick={() => resetTable()}
        >
          Reset
        </button>
      )}
      <button
        className="btn-new btn-new--secondary"
        onClick={() => {
          const year = _.find(searchParams,{ value: 'year'})['param']
          getListAPI(true,year)
        }}
      >
        Search
      </button>
    </div>
  );

  const renderDesktopMode = () => (
    <div className="at-table__search-cont at-desktop-view__controller mb-20">
      <div className="row">
        {searchParams &&
          searchParams.map((item, index) => {
            return (
              <div key={item.value} className={`col-md-${item.col || 6}`}>
                <label
                  style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}
                >
                  {item.label}
                </label>
                <br />
                {renderInputForm(item, index)}
              </div>
            );
          })}
          <div className={"col-md-4 align-self-end"}>
            {renderSearchReset()}
          </div>
      </div>
    </div>
  );

  const processPagesData = (rowData, pageOption) => {
    if (rowData.length > 0) {
      let paginationSections = rowData.length / pageOption;

      let pagesArrayData = [];
      if (paginationSections > Math.floor(paginationSections)) {
        paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      }

      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      setTotalPage(pagesArrayData);
    }
  };

  const debounceService = (searchVal) => {
    const status =
      searchParams.find((item) => item.value === "status").param || "";
    let result = _.filter(initialRowData, function (obj) {
      if (obj["agentName"] && obj["agentName"] !== null) {
        if (status === "") {
          return (
            obj["agentName"].toLowerCase().indexOf(searchVal.toLowerCase()) > -1
          );
        } else if (status && status === "1") {
          return (
            obj["agentName"].toLowerCase().indexOf(searchVal.toLowerCase()) >
              -1 && obj["isEligible"]
          );
        } else if (status && status === "2") {
          return (
            obj["agentName"].toLowerCase().indexOf(searchVal.toLowerCase()) >
              -1 && !obj["isEligible"]
          );
        }
      }
    });

    Promise.all([setRowData(result)]).then(() => {
      processPagesData(result, currentPageOption);
    });
  };

  const onClickRearrangeData = useCallback(
    (val) => {
      Promise.all([setHeaderRearrangeData(val)]).then(() => {
        if (val.columnValue) {
          let tempOrder = _.orderBy(
            rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (columnData !== "Infinity" && !isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(
                    "columnData.match(/^(d{2})-(d{2})-(d{4})$/)",
                    columnData,
                  );
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          );

          setRowData(tempOrder);
        }
      });
    },
    [rowData],
  );

  const renderTableContent = () => {
    if (currentPageData && currentPageData.length > 0) {
      return currentPageData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          columns={columns}
          index={index}
          headerRearrangeData={headerRearrangeData}
          actionColumnContent={actionColumnContent}
          rowData={rowItem}
        />
      ));
    } else if (hideSearch || !searchKeyword) {
      return (
        <EmptyState
          renderIcon={ customEmptyState?.icon }
          title={customEmptyState?.title || "You don't have any items"}
          description={customEmptyState?.description || ""}
        />
      );
    } else {
      return (
        <div className="at-table-row-cont">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p>No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
              <br />
            </li>
          </ul>
        </div>
      );
    }
  };

  const renderPaginationText = () => {
    const processFloorPaginationCount = () => {
      let pageNumTmp = clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * currentPageOption + 1;
      }
      return pageNumTmp;
    };

    const processCeilingPaginationCount = () => {
      let pageNumTmp = currentPageOption;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowData.length) {
        pageNumTmp = rowData.length;
      }
      return pageNumTmp;
    };

    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span>
          {" "}
          {pagination ? processFloorPaginationCount(clickPageNumber) : ""}{" "}
        </span>
        -
        <span>
          {" "}
          {pagination
            ? processCeilingPaginationCount(currentPageOption)
            : ""}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  return (
    <div className={`d-flex flex-column mb-20 ${className}`}>
      {searchParams && searchParams.length > 0 && (
        <div className="mb-10">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() => onToggleSearchModal(!showSearchModal)}
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          {renderDesktopMode()}
        </div>
      )}
      {renderAdditionalContent && renderAdditionalContent}
      <div className="at-table at-table--set">
        {renderCheckBoxActions && (
          <div className="at-table-head" style={{ display: "flex" }}>
            {renderCheckBoxActions()}
          </div>
        )}
        {rowData && rowData.length > 0 && (
          <CustomTableHeader
            mode={mode}
            onClickRearrangeData={onClickRearrangeData}
            columns={columns}
            rowData={rowData}
            actionColumnContent={actionColumnContent}
            currentPageData={currentPageData}
          />
        )}
        {renderTableContent()}
      </div>
      {!hidePagination && rowData.length > 0 && (
        <div className="at-pagination__cont">
          <CustomPagination
            totalPages={totalPages}
            chosenPageNumber={currentPageOption}
            onClickPageNumber={(val) => setPageNum(val)}
            currentPageNumber={clickPageNumber}
          />
          {renderPaginationText()}
        </div>
      )}
      {showSearchModal && (
        <ModalDialog
          title={"Filter & Search"}
          onClose={() => onToggleSearchModal(false)}
          cardClassName={"mb-0"}
          children={
            <div className="row">
              {searchParams &&
                searchParams.map((item, index) => {
                  if (!item.additional) {
                    return (
                      <div key={item.value} className={`col-md-6`}>
                        <label
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginBottom: 5,
                          }}
                        >
                          {item.label}
                        </label>
                        <br />
                        {renderInputForm(item, index)}
                      </div>
                    );
                  }
                })}
            </div>
          }
          footer={
            <div className="at-table__search-action">{renderSearchReset()}</div>
          }
        />
      )}
    </div>
  );
};

export default PersonalCampaignTable;
