import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { BsPencilSquare } from "react-icons/bs";

import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const UpdateTranslation = props => {
  const [translation, setTranslation] = useState({en: ""})

  useEffect(() => {
    props.getAllLocalisedSetting();
  }, [])

  useEffect(() => {
    if (props.selectedLocalised) {
      setTranslation({
        en: props.selectedLocalised.default_translation,
        ...props.selectedLocalised.localised_language
      })
    }
  }, [props.selectedLocalised])

  const onToggleUpdate = () => props.updateTranslation({
    default_translation: translation.en,
    translation_text_attributes: {
      translations: _.omitBy(translation, (value, key) => key === 'en')
    }
  })

  return (
    <ModalDialog
      title="Edit Translation"
      onLoad={props.onLoadLocalised}
      onClose={() => props.onChangeLocalisedHOC(false, "showUpdateModal")}
      children={
        <>
          <div className='d-flex flex-column g-2'>
            <p className='d-flex align-items-center'>
              <span className='at-form-input__title' style={{minWidth: 140}}>Module</span>
              {props.selectedLocalised?.module_name}
            </p>
            <p className='d-flex align-items-center'>
              <span className='at-form-input__title' style={{minWidth: 140}}>Key</span>
              {props.selectedLocalised?.key}
            </p>
          </div>
          <hr />
          <div className='grid-control'>
            <section className='grid-full-col d-flex align-items-center g-3'>
              <div className="at-badge-icon-sm at-badge-icon-warning mr-0">
                <BsPencilSquare />
              </div>
              <span className='fs-3 fw-600'>Edit</span>
            </section>
            <section className='grid-full-col d-flex align-items-center'>
              <h2 className='at-form-input__title' style={{minWidth: 140}}>Default(en)</h2>
              <CustomFormInput
                required={true}
                placeholder='Default Translation'
                containerClass='w-100'
                value={translation.en}
                onChangeValue={(val) => setTranslation({ ...translation, en: val })} />
            </section>
            {props.settings?.map((setting, index) => (
              <section key={index} className='grid-full-col d-flex align-items-center'>
                <h2 className='at-form-input__title' style={{minWidth: 140}}>{setting.language_name}({setting.language_code})</h2>
                <CustomFormInput
                  placeholder={setting.language_name}
                  containerClass='w-100'
                  value={translation[setting.language_code]}
                  onChangeValue={(val) => setTranslation({ ...translation, [setting.language_code]: val })} />
              </section>
            ))}
          </div>
        </>
      }
      footer={
        <div className={'d-flex align-items-center g-3'}>
          <button
            className="btn-new btn-new--success"
            onClick={() => onToggleUpdate()}>
            Update
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeLocalisedHOC(false, "showUpdateModal")}>
            Cancel
          </button>
        </div>
      } />
  )
}

export default UpdateTranslation