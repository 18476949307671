import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import CustomTypeahead from "components/Typeahead/new";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";

const InventoryForm = ({
  mode,
  groupTag,
  selectedInventory,

  onClose,
  getGroupTag,
  onClickCreate,
  onClickUpdate,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    group_list: [""],
  });

  useEffect(() => {
    getGroupTag();
    if (mode === "edit" && selectedInventory) {
      setFormData(selectedInventory);
    }
  }, []);

  const onChangeFormData = (val, context, index) => {
    let temp = _.cloneDeep(formData);
    if (context === "group_list") temp[context][index] = val;
    else temp[context] = val;
    setFormData(temp);
  };

  const renderCardContent = () => (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h4 className="at-card__title">
          {mode === "create" && "New Inventory"}
          {mode === "edit" && "Edit Inventory"}
        </h4>
      </div>
      <div className="grid-control">
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Inventory Name</h2>
          <CustomFormInput
            type="text"
            value={formData.name}
            placeholder={"Enter inventory name"}
            onChangeValue={(val) => onChangeFormData(val, "name")}
          />
        </section>
        <section className="grid-full-col">
          <div className="d-flex">
            <h2 className="at-form-input__title">Group List</h2>
            <CustomButton
              style={{ background: "#F0631D", borderRadius: "50%", padding: 4 }}
              onClick={() => {
                let temp = _.cloneDeep(formData);
                temp.group_list.push("");
                setFormData(temp);
              }}
              isIconButton={true}
              className={"ml-2"}
              children={
                <IoMdAdd style={{ height: 18, width: 18, color: "#FFFF" }} />
              }
              tooltip={true}
              tooltipPosition={"buttom"}
              tooltipID={"create"}
              tooltipChildren={"Add group list"}
            />
          </div>
          {formData.group_list.length > 0 &&
            formData.group_list.map((group, index) => (
              <div className="d-flex">
                <CustomTypeahead
                  typeaheadId={`inventory-group-list-${index}`}
                  selectedValue={group ? [group] : []}
                  options={groupTag || []}
                  labelKey={"name"}
                  onSearch={(val) => onChangeFormData(val, "group_list", index)}
                  onSelect={(val) =>
                    val &&
                    val.length > 0 &&
                    onChangeFormData(val[0].name, "group_list", index)
                  }
                  childrenHead={(rowItem) => (
                    <p className="d-flex align-items-center">{rowItem.name}</p>
                  )}
                />
                <CustomButton
                  className={"btn-new btn-new--danger ml-2"}
                  children={"Remove"}
                  onClick={() => {
                    if (formData.group_list.length > 1) {
                      let temp = _.cloneDeep(formData);
                      temp.group_list.splice(index, 1);
                      setFormData(temp);
                    }
                  }}
                />
              </div>
            ))}
        </section>
        <section className="grid-full-col d-flex">
          <CustomButton
            className={"btn-new btn-new--success mr-2"}
            children={mode === "create" ? "Create" : "Update"}
            onClick={() =>
              mode === "create"
                ? onClickCreate(formData)
                : onClickUpdate(formData)
            }
          />
          <CustomButton
            className={"btn-new btn-new--outline-secondary"}
            children={mode === "create" ? "Cancel" : "Close"}
            onClick={() => onClose()}
          />
        </section>
      </div>
    </>
  );

  return <CustomCard className={"h-100"} cardContent={renderCardContent()} />;
};

export default InventoryForm;
