import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";
import { MdFilterList } from "react-icons/md";

import Tabs from "components/Tab";
import CustomCard from "components/Card";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomToggle from "components/Button/toggle";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import PersonalSales from "../../Personal/MySales";

import {
  projectHeader,
  submittedSubsalesHeader,
  convertedSaleHeader,
  convertedRentHeader,
  convertedSubsalesHeader,
  combineSubsalesHeader,
  combineAllSubsaleHeader,
  combineRentHeader,
} from "./assets";
import { storeSelectedAgent } from "actions/sales";

import SalesHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import { initGA } from "utils/ga";

import "./index.scss";

const subsalesOptions = ["All", "Sale", "Rental"];

const statData = [
  {
    label: "Group Sales",
    value: "totalSales",
    icon: "moneys",
    color: "#14B8A6",
  },
  {
    label: "Team Project Sales",
    value: "totalProjectSales",
    icon: "building-4",
    color: "#6875F5",
  },
  {
    label: "Team Subsales Sales",
    value: "totalSubsaleSales",
    icon: "building-3",
    color: "#E74694",
  },
  {
    label: "Total Booked Sales (Agency Comm.)",
    value: "totalAgencyComm",
    icon: "percentage-circle",
    color: "#84CC16",
  },
];

const tabOptoins = [
  { label: "Project", icon: "atlas-building-4" },
  { label: "Subsales", icon: "atlas-building-3" },
];

const submittedUnitArray = {
  "1": "booked",  
  "2": "",
  "3": "converted"
}

const subSaleHeaderMap = {
  "1": {
    "All": submittedSubsalesHeader
  },
  "2": {
    "Sale": combineSubsalesHeader,
    "Rental": combineRentHeader,
    "All": combineAllSubsaleHeader
  },
  "3": {
    "Sale": convertedSaleHeader,
    "Rental": convertedRentHeader,
    "All": convertedSubsalesHeader
  }
}
class TeamSales extends Component {
  state = { showSearchModal: false };

  componentDidMount = () => {
    const startDate = this.props.searchParams[0].param;
    const endDate = this.props.searchParams[1].param;
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    initGA("/dashboard/team/team-sales");
    let isAdmin = ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) > -1;

    this.props.getTeamSales(
      isAdmin
        ? this.props.encoded_identity
        : tmpProfileReducer.encoded_identity,
      startDate,
      endDate,
    );
    this.props.onChangeSalesHOC(
      isAdmin ? `${this.props.team_name}'s Sales` : "Team Sales",
      "title",
    );
  };

  detailsClick = (val) => {
    const { role } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    if (["Super Admin", "Admin"].indexOf(role) > -1) {
      return this.props.viewPersonalSales(
        val.encodedMobilePhone,
        val.agentName,
        "OtherAgentSales",
      );
    }

    this.props.storeSelectedAgent(val);
    this.props.onChangeSalesHOC(true, "showAgentSales");
  };

  onClickHideAgentsWithoutSales() {
    Promise.all([
      this.props.onChangeSalesHOC(
        !this.props.showAgentsWithoutSales,
        "showAgentsWithoutSales",
      ),
    ]).then(() => {
      let tempProjectSale = _.cloneDeep(this.props.initialProjectSales)
      let tempCombineSubsale = _.cloneDeep(this.props.initialcombinedSubsales)
      let tempSubmittedSubsales = _.cloneDeep(this.props.initialSubmittedSubsales)
      if (this.props.showAgentsWithoutSales) {;
        let hideConbineSubsale;
        let hideSubmittedSubsales;
        let hideProjectSale;
        let filterElement = this.props.hideFunction(
          this.props.subsalesType,
        );
        hideConbineSubsale = tempCombineSubsale.filter(filterElement)
        tempSubmittedSubsales = this.props.processSubsalesSubmittedSales(tempSubmittedSubsales)
        hideSubmittedSubsales = _.filter(tempSubmittedSubsales, item => item.total_agency_comm !== 0)
        hideProjectSale = _.filter(tempProjectSale, item => item.totalProjectPersonalSales !== 0)
        this.props.onChangeSalesHOC(hideConbineSubsale, "combinedSubsales")
        this.props.onChangeSalesHOC(hideSubmittedSubsales, "submittedSubsales")
        this.props.onChangeSalesHOC(hideProjectSale, "projectSales")
      } else {
        tempSubmittedSubsales = this.props.processSubsalesSubmittedSales(tempSubmittedSubsales);
        this.props.onChangeSalesHOC(tempSubmittedSubsales, "submittedSubsales");
        this.props.onChangeSalesHOC(tempCombineSubsale, "combinedSubsales");
        this.props.onChangeSalesHOC(tempProjectSale, "projectSales");
      }
    });
  }

  onClickSubmit = (page, search) => {
    const startDate = this.props.searchParams[0].param;
    const endDate = this.props.searchParams[1].param;
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let isAdmin =
      ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) !== -1;

    this.props.onChangeSalesHOC(
      isAdmin ? `${this.props.team_name}'s Sales` : "Team Sales",
      "title",
    );
    this.props.getTeamSales(
      isAdmin
        ? this.props.encoded_identity
        : tmpProfileReducer.encoded_identity,
      startDate,
      endDate,
    );
  };

  onChangeSearchParams = (val) => {
    this.props.storeTeamSalesDate({
      startDate: val[0].param,
      endDate: val[1].param,
    });
    this.props.onChangeSalesHOC(val, "searchParams");
  };

  returnData = () => {
    if (this.props.view === "Project") return this.props.projectSales;
    else return this.props.combinedSubsales
  };

  returnHeader = () => {
    if (this.props.view === "Project") {
      return projectHeader;
    } else {
      return subSaleHeaderMap[this.props.showSubmittedUnitOnly][this.props.subsalesType] || subSaleHeaderMap["1"]["All"]
    }
  };

  renderSearchForm = () => (
    <div className="mb-3">
      <button
        className={
          "btn-new btn-new--outline-secondary at-mobile-view__controller"
        }
        style={{ width: "100%" }}
        onClick={() =>
          this.setState({ showSearchModal: !this.state.showSearchModal })
        }
      >
        <MdFilterList style={{ width: 20 }} />
        <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
          Filter & Search
        </h5>
      </button>
      <div className="at-table__search-cont at-desktop-view__controller ">
        <TableSearchParams
          mode={"desktop"}
          showResetButton={true}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) => this.onChangeSearchParams(val)}
          getListAPI={this.onClickSubmit}
        />
      </div>
      {this.state.showSearchModal && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={this.props.searchParams}
          showResetButton={true}
          onToggleSearchModal={() =>
            this.setState({ showSearchModal: !this.state.showSearchModal })
          }
          onChangeSearchParams={(val) => this.onChangeSearchParams(val)}
          getListAPI={this.onClickSubmit}
        />
      )}
    </div>
  );

  renderTabFilter = () => {
    if (this.props.view === "Subsales") {
      return (
        <div className="d-block w-100">
          <Tabs
            sections={subsalesOptions}
            containerStyle={{ marginBottom: 0, padding: "16px 16px 0px" }}
            selectedSection={this.props.subsalesType}
            onSelectSection={(val) => this.props.onChangeSubsalesType(val)}
          />
          <div className="d-flex align-item-center flex-wrap">
            <div className="at-sale__float-button-cont">
              <button
                className={`btn-float ${this.props.showSubmittedUnitOnly === "2" ? "btn-float-selected" : ""}`}
                onClick={() => this.props.onChangeSubsalesUnit("2")}
              > Show All Unit </button>
              <button
                className={`btn-float ${this.props.showSubmittedUnitOnly === "3" ? "btn-float-selected" : ""}`}
                onClick={() => this.props.onChangeSubsalesUnit("3")}
              > Only Show Converted Unit </button>
              <button
                className={`btn-float ${this.props.showSubmittedUnitOnly === "1" ? "btn-float-selected" : ""}`}
                onClick={() => this.props.onChangeSubsalesUnit("1")}
              > Only Show Booked Unit </button>
            </div>
            <div className="d-flex align-item-center pb-3 p-md-2">
              <CustomToggle
                className={"at-toggle__small my-auto"}
                currentState={!this.props.showAgentsWithoutSales}
                onToggleButton={() => this.onClickHideAgentsWithoutSales()}
              />
              <p className="at-hide-agent my-auto">
                Hide Agents Without Any Sales
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-item-center pb-3 p-md-2">
          <CustomToggle
            className={"at-toggle__small"}
            currentState={!this.props.showAgentsWithoutSales}
            onToggleButton={() => this.onClickHideAgentsWithoutSales()}
          />
          <p className="at-hide-agent">Hide Agents Without Any Sales</p>
        </div>
      );
    }
  };

  renderTabContent = () => {
    const isAll = this.props.showSubmittedUnitOnly === "2" && !["All"].includes(this.props.subsalesType)
    const unitType = submittedUnitArray[this.props.showSubmittedUnitOnly]
    const statDesc = `${isAll ? "All" : ""} ${this.props.subsalesType} ${unitType} unit`;
    return (
      <>
      {this.renderSearchForm()}
      <div className="at-team-sales__stat-cont mb-2">
        {statData.map((item, index) => {
          if ((this.props.view === "Project" && item.value === "totalProjectSales")
            || (this.props.view === "Subsales" && item.value === "totalSubsaleSales") 
            || (this.props.view === "Subsales" && item.value === "totalAgencyComm") 
            || (item.value === "totalSales")) {
            return (
              <div
                key={index}
                style={{ padding: 12 }}
                className="at-form__content d-flex align-items-flex-start w-100 mb-2">
                <div
                  className="at-card--stat__general-icon-cont"
                  style={{ backgroundColor: item.color }}>
                  <AtlasIcon svgHref={`atlas-${item.icon}`}/>
                </div>
                <div className="at-card--stat__general-category mb-0">
                  <p>{item.label}</p>
                  <span>{this.props.totalData[item.value]}</span>
                  {
                    this.props.view === "Subsales" && item.label === "Group Sales" &&
                    <p className="at-team-sales__stat-desc" style={{marginTop: 4}}>Include Project & Subsales</p>
                  }
                  {
                    this.props.view === "Subsales" && item.label !== "Group Sales" &&
                    <p className="at-team-sales__stat-desc" style={{marginTop: 4}}>{statDesc}</p>
                  }
                </div>
              </div>
            )
          }
          return null;
        })}
      </div>
      <CustomTable
        className={"team-sales__table"}
        selectPerPageOption={20}
        columns={this.returnHeader() || []}
        pagination={true}
        rowData={this.returnData() || []}
        actionColumnContent={[
          {
            name: "details",
            onClick: (rowData) => this.detailsClick(rowData),
          },
        ]}
        renderTabFilter={this.renderTabFilter}
      />
    </>
    )
  };

  renderAgentSearchTips = () => {
    return (
      <>
        <span className="fw-500">
          How to find top performers and view their sales summary
        </span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          Click on the Project or Subsales tab to find out your top performers.{" "}
          <br />
          You can click on the agent’s name to toggle their Dashboard. This
          enables you to view a summary of their personal sales based on the
          current year.
        </p>
      </>
    );
  };

  renderCardContent = () => {
    return (
      <>
        <Tabs
          sections={tabOptoins}
          selectedSection={this.props.view}
          onSelectSection={(val) => this.props.onChangeSalesHOC(val, "view")}
        />
        {this.renderTabContent()}
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={this.props.title}
          atlasIcon={"atlas-dollar-square"}
          description={`Manage and view your Team Sales. View your member’s personal and team sales based on their market category. 
            The Group sales shows your group accumulated sales of both Project and Subsales based on the date range that you’ve selected.`}
          showPABadge={role === "Personal Assistant"}
          infoBoxContent={this.renderAgentSearchTips}
          infoBoxInactiveContent={
            "How to find top performers and view their sales summary"
          }
          backButton={{
            onShow:
              ["Super Admin", "Admin"].indexOf(role) > -1 || this.props.onClose,
            onClick: () => this.props.onClose(),
          }}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showAgentSales && (
          <PersonalSales
            onClose={() => this.props.onChangeSalesHOC(false, "showAgentSales")}
          />
        )}
        {(this.props.onLoadSales || this.props.bookedUnitLoading) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default compose(
  connect(mapStateToProps, {
    storeSelectedAgent,
  }),
  SalesHOC,
  VersionCheckHOC,
)(TeamSales);

