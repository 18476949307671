import React, { Component } from "react";
import { connect } from "react-redux";

import { Post, Put, Get, Delete } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const ReaRenewalDetailsHOC = (WrappedComponent) => {
  class ReaRenewalDetailsHOCWrappedComponent extends Component {
    state = {
      loading: false,

      message: [],
      activities: [],
      documentList: {
        data: [],
        meta: [],
      },
      documentPages: {},
      selectedEvent: {},
      showEditEventModal: false,
      showCreateEventModal: false,
      showSubmitConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRenewalDetailsHOC = (val, context) =>
      this.setState({ [context]: val });

    createRenewalDetails = (dataToSubmit) =>
      Post(
        `/licence_renewal_applicants/${dataToSubmit.id}/licence_renewal_details`,
        dataToSubmit,
        this.createRenewalDetailsSuccess,
        this.createRenewalDetailsError,
        this.load,
      );
    createRenewalDetailsSuccess = (payload) => {
      this.props.getSelectedReaRenewalApplication(
        payload.licence_renewal_applicant_id,
      );
      this.setState({ showCreateEventModal: false });
      requestSuccess("Renwal Details created");
    };
    createRenewalDetailsError = (error) => requestError(error);

    updateRenewalDetails = (dataToSubmit) => {
      const { application_id, renewal_detail_id } = dataToSubmit;
      Put(
        `/licence_renewal_applicants/${application_id}/licence_renewal_details/${renewal_detail_id}`,
        dataToSubmit,
        this.updateRenewalDetailsSuccess,
        this.updateRenewalDetailsError,
        this.load,
      );
    };
    updateRenewalDetailsSuccess = (payload) => {
      this.props.getSelectedReaRenewalApplication(
        payload.licence_renewal_applicant_id,
      );
      this.setState({ showEditEventModal: false });
      requestSuccess("Renewal Details updated.");
    };
    updateRenewalDetailsError = (error) => requestError(error);

    submitToAdmin = (id) =>
      Put(
        `/licence_renewal_applicants/${id}/mark_as_processing`,
        {},
        this.submitToAdminSuccess,
        this.submitToAdminError,
        this.load,
      );
    submitToAdminSuccess = (payload) => {
      requestSuccess("Application has submitted for process.");
      this.props.getSelectedReaRenewalApplication(payload.id);
      this.setState({ showSubmitConfirmation: false });
    };
    submitToAdminError = (error) => requestError(error);

    getDocumentList = (id) =>
      Get(
        `/licence_renewal_applicants/${id}/licence_renewal_documents`,
        this.getDocumentListSuccess,
        this.getDocumentListError,
        this.load,
      );
    getDocumentListSuccess = (payload) => {
      let tempPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        documentList: payload,
        documentPages: tempPages,
      });
    };
    getDocumentListError = (error) => requestError(error);

    getActivity = (id) =>
      Get(
        `/licence_renewal_applicants/${id}/activities`,
        this.getActivitySuccess,
        this.getActivityError,
        this.load,
      );
    getActivitySuccess = (payload) => this.setState({ activities: payload });
    getActivityError = (error) => requestError(error);

    getMessage = (id) =>
      Get(
        `/licence_renewal_applicants/${id}/messages`,
        this.getMessageSuccess,
        this.getMessageError,
        this.load,
      );
    getMessageSuccess = (payload) => this.setState({ message: payload });
    getMessageError = (error) => requestError(error);

    putMessage = (dataToSubmit) =>
      Post(
        `/licence_renewal_applicants/${dataToSubmit.id}/messages`,
        dataToSubmit,
        () => this.putMessageSuccess(dataToSubmit.id),
        this.putMessageError,
        this.load,
      );
    putMessageSuccess = (id) => {
      requestSuccess("Message Sent");
      this.getMessage(id);
    };
    putMessageError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            message={this.state.message}
            activities={this.state.activities}
            documentList={this.state.documentList}
            documentPages={this.state.documentPages}
            onLoadRenewalDetail={this.state.loading}
            selectedEvent={this.state.selectedEvent}
            showEditEventModal={this.state.showEditEventModal}
            showCreateEventModal={this.state.showCreateEventModal}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            getMessage={this.getMessage}
            putMessage={this.putMessage}
            getActivity={this.getActivity}
            submitToAdmin={this.submitToAdmin}
            getDocumentList={this.getDocumentList}
            createRenewalDetails={this.createRenewalDetails}
            updateRenewalDetails={this.updateRenewalDetails}
            onChangeRenewalDetailsHOC={this.onChangeRenewalDetailsHOC}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {})(ReaRenewalDetailsHOCWrappedComponent);
};

export default ReaRenewalDetailsHOC;
