import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { MenuItem } from "@material-ui/core";
import { BiChevronDown } from "react-icons/bi";

import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";

import "./index.scss";

const searchUnitOptions = [
  { label: "Hours", value: "hours" },
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

const commentOptions = [
  { label: "Today", quantity: 1, unit: "days", startDate: Moment() },
  {
    label: "This Week",
    quantity: 1,
    unit: "weeks",
    startDate: Moment().startOf("isoweek"),
  },
  {
    label: "Last 24 Hours",
    quantity: 24,
    unit: "hours",
    startDate: Moment().subtract(24, "hours"),
  },
  {
    label: "Last 7 Days",
    quantity: 7,
    unit: "days",
    startDate: Moment().subtract(7, "days"),
  },
  {
    label: "Last 30 Days",
    quantity: 30,
    unit: "days",
    startDate: Moment().subtract(30, "days"),
  },
  {
    label: "Last 90 Days",
    quantity: 90,
    unit: "days",
    startDate: Moment().subtract(90, "days"),
  },
  {
    label: "Last 1 Year",
    quantity: 1,
    unit: "years",
    startDate: Moment().subtract(1, "years"),
  },
];

const DayRangePicker = ({
  endDateKey,
  startDateKey,
  initialValue,

  onChange,
}) => {
  const [interval, setInterval] = useState(initialValue || "Last 1 Year");
  const [quantity, setQuantity] = useState(1);
  const [searchUnit, setSearchUnit] = useState("years");
  const [showDropDown, setShowDropDown] = useState(false);

  const onSelectSuggestion = (param) => {
    let temp = `${startDateKey}=${param.startDate.toISOString()}&${endDateKey}=${Moment().toISOString()}`;
    onChange(temp);
    setQuantity(param.quantity);
    setSearchUnit(param.unit);
    setInterval(param.label);
    setShowDropDown(false);
  };

  const onToggleApply = () => {
    let temp = `${startDateKey}=${Moment().subtract(quantity, searchUnit).toISOString()}&${endDateKey}=${Moment().toISOString()}`;
    onChange(temp);
    setInterval(`Last ${quantity} ${searchUnit}`);
    setShowDropDown(false);
  };

  return (
    <div className="at-form-input" style={{ marginBottom: 0 }}>
      <input
        className="at-form-input__input"
        disabled={true}
        value={interval}
      />
      <div
        className="at-day-range__dropdown-toggle"
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <AtlasIcon svgHref={"atlas-calendar-2-linear"} />
        <BiChevronDown />
      </div>
      <div
        className={"at-party__dropbear--modal-root"}
        style={{ display: showDropDown ? "block" : "none" }}
      >
        <div className="at-day-range__input-cont row m-0">
          <div className="col-md-3 pl-0 pb-2">
            <div className="at-form-input">
              <span className="d-flex align-items-center at-form-input__input">
                Last
              </span>
            </div>
          </div>
          <div className="col-md-3 pl-0 pb-2">
            <div className="at-form-input">
              <input
                type={"number"}
                value={quantity}
                className="at-form-input__input"
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-4 pl-0 pb-2">
            <AtSelectNew
              value={searchUnit}
              onChange={(e) => setSearchUnit(e.target.value)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {searchUnitOptions.map((option) => (
                <MenuItem
                  classes={{ root: `at-select__dropdown-item` }}
                  key={option.value}
                  value={option.value}
                  style={{ fontSize: 14 }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </AtSelectNew>
          </div>
          <div className="col-md-2 pl-0 pb-2">
            <button
              className="btn-new btn-new--outline-primary"
              onClick={() => onToggleApply()}
            >
              Apply
            </button>
          </div>
        </div>
        <hr style={{ margin: "5px 0px" }} />
        <p
          style={{
            fontWeight: 600,
            fontSize: 14,
            marginLeft: 12,
            marginBottom: 10,
          }}
        >
          Commonly used
        </p>
        <div className="row m-0">
          {commentOptions.map((item, index) => (
            <div className="col-md-6">
              <button
                key={index}
                className={"at-day-range__suggested-btn"}
                onClick={() => onSelectSuggestion(item)}
              >
                {item.label}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DayRangePicker;
