import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";

import CustomCard from "components/Card";
import AtPaginatedTable from "components/NewPaginatedTable";
import RequestConfirmationForm from "./components/requestConfirmationForm";
import LoadingModal from "components/LoadingModal";
import permissionsChecker from "utils/permissionsChecker";

import BranchesHOC from "./actions/branches.js";
import RequestConfirmationFormHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";
import { getColorBadge } from "dictionary/badgeColor";

import "../../index.scss";

const statusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Approved", colorName: "Green" },
  { value: "Rejected", colorName: "Red" },
];

const ColumnData = [
  { header: "Branch", accessor: "branch_name" },
  { header: "Request Qty", accessor: "quantity" },
  {
    header: "Status",
    renderColumn: (rowData) => {
      const { colorName } = _.find(statusColor, { value: rowData.status });

      return (
        <div className="d-flex flex-column">
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            <span>{rowData.status}</span>
          </div>
          {rowData.status === "Rejected" && (
            <span className="at-status_badge-remarks">{rowData.remark}</span>
          )}
        </div>
      );
    },
  },
  { header: "Processed By", accessor: "process_by" },
  {
    header: "Reason",
    accessor: "reason",
  },
];

class ConfirmationFormRequest extends Component {
  componentDidMount = () => {
    this.props.getBranches();
    this.props.getConfirmationRequests(1, "");
    initGA("/dashboard/personal/forms/confirmation-form-request");
  };

  componentDidUpdate = (pp) => {
    if (
      this.props.showCreateRequestForm !== pp.showCreateRequestForm &&
      !this.props.showCreateRequestForm
    ) {
      this.props.resetCreateValue();
    }
  };

  onChange = (val, context) => this.setState({ [context]: val });

  renderForm = () => {
    const { branches } = this.props;
    return (
      <>
        <RequestConfirmationForm
          branches={branches}
          onChange={this.props.onChangeHOC}
          branch_id={this.props.branch_id}
          reason={this.props.reason}
          quantity={this.props.quantity}
          closeFormDialog={ this.props.closeFormDialog }
          postConfirmationRequest={this.props.postConfirmationRequest}
        />
        {this.props.onLoadConfirmationFormRequest && <LoadingModal />}
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Forms", this.props.data);
    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    return (
      <CustomCard
        className={`h-100`}
        containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
        cardContent={
          <>
            <AtPaginatedTable
              tableTitle={"Confirmation Form Request"}
              headerClass={"at-form_mngmt__table-header "}
              showCloseButton={true}
              onClickClose={() =>
                this.props.history.push(
                  `${tmpPath}/dashboard/personal/forms/confirmation-form`,
                )
              }
              showCreateButton={can_create}
              onClickCreate={() =>
                this.props.onChangeHOC(true, "showCreateRequestForm")
              }
              createTooltipContent={"New Request"}
              rowData={
                this.props.confirmationRequests.data
                  ? this.props.confirmationRequests.data
                  : []
              }
              meta={
                this.props.confirmationRequests.meta &&
                this.props.confirmationRequests.meta
              }
              searchParams={this.props.searchParamsReqeust}
              onChangeSearchParams={(val) =>
                this.props.onChangeConfirmationRequestsHOC(
                  val,
                  "searchParamsReqeust",
                )
              }
              getListAPI={this.props.getConfirmationRequests}
              totalPages={this.props.confirmationRequestsPages}
              columns={ColumnData}
            />
            {this.props.showCreateRequestForm && (
              <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
                {this.renderForm()}
              </Dialog>
            )}
            {this.props.onLoadConfirmationFormRequest && <LoadingModal />}
          </>
        }
      />
    );
  };
}

export default compose(
  BranchesHOC,
  VersionCheckHOC,
  RequestConfirmationFormHOC,
)(ConfirmationFormRequest);
