import { Img, interpolate, useCurrentFrame } from 'remotion';

const Logo = ({ configuration, isPortrait }) => {
  const frame = useCurrentFrame();

  const logoOpacity = interpolate(
    frame,
    [200, 300],
    [configuration?.logoOpacity, configuration?.logoOpacity],
    { extrapolateRight: 'clamp' }
  );

  return (
    <div
      style={{
        position: 'absolute',
        top: 8,
        opacity: logoOpacity,
        left: configuration?.logoPosition === 'left' ? 8 : 'auto',
        right: configuration?.logoPosition === 'right' ? 8 : 'auto',
        transform: isPortrait ? 'scale(1.9)' : 'none',
        transformOrigin: isPortrait ? configuration?.logoPosition === 'left' ? 'top left' : 'top right' : '50% 50% 0'
      }}
    >
      <Img src={configuration?.logo} width={120} height='auto' />
    </div>
  );
};

export default Logo;
