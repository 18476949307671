const TransparentBoxTemplate = ({ agent, configuration }) => {
  const detailStyle = {
    padding: 4,
    fontSize: 9,
    fontWeight: 600,
    color: '#fff',
  };

  const titleStyle = {
    color: '#ffc85f',
    fontSize: 7,
    fontWeight: 700,
    marginBottom: 1
  };

  return (
    <div style={{ minWidth: 280, width: 'max-content', border: '1px solid #fff' }}>
      <div style={{ display: 'flex' }}>
        <img
          alt={agent.display_name}
          src={agent.avatar_url}
          style={{
            width: 64,
            height: 64,
            maxWidth: 64,
            maxHeight: 64,
            display: 'block',
            objectFit: 'cover',
            objectPosition: 'top',
            borderRight: '1px solid #fff',
            backgroundColor: '#eee'
          }}
        />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div
            style={{
              ...detailStyle,
              flex: 1,
              borderBottom: '1px solid #fff',
              backgroundColor: `rgba(255, 255, 255, ${configuration?.agentDetailsOpacity})`
            }}
          >
            <p style={titleStyle}>{agent.license_number}</p>
            <p style={{ fontSize: 12, fontWeight: 600 }}>
              {agent.display_name}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              backgroundColor: `rgba(0, 0, 0, ${configuration?.agentDetailsOpacity})`
            }}
          >
            <div style={{ ...detailStyle, borderRight: '1px solid #fff' }}>
              <p style={titleStyle}>CONTACT NUMBER</p>
              {agent.mobile_contact_number}
            </div>
            <div style={{ ...detailStyle, flex: 1 }}>
              <p style={titleStyle}>EMAIL</p>
              {agent.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransparentBoxTemplate;
