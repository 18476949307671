import React from "react";

import CustomSelect from "components/Select";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const RequestConfirmationForm = ({
  branches,
  onChange,
  closeFormDialog,
  branch_id,
  reason,
  quantity,
  postConfirmationRequest,
}) => {
  return (
    <ModalDialog
      title={"Request Top Up for Confirmation Form Balances"}
      children={
        <div className="grid-control">
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Select your branch
            </h2>
            <CustomSelect
              required={true}
              customStyle={{ minWidth: 300 }}
              selectItems={branches}
              valueKey='id'
              currentlySelected={branch_id}
              updateSelect={(val) => val && onChange(val.id, "branch_id")}
            />
          </section>
          <br />
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Quantity needed
            </h2>
            <CustomFormInput
              type="number"
              value={quantity}
              onChangeValue={(val) => onChange(val, "quantity")}
              required={true}
              inputError={
                quantity && quantity <= 0 && "Quantity must be more than 0"
              }
            />
          </section>
          <br />
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Reason
            </h2>
            <AtlasTextarea
              className={"flex-1"}
              style={{ borderRadius: 8 }}
              value={reason}
              onChangeValue={(val) => onChange(val, "reason")}
              required={true}
              placeholder={"Reason"}
              rows={6}
            />
          </section>
        </div>
      }
      footer={
        <div className="d-flex g-3">
          <button
            className={`btn-new btn-new--primary`}
            disabled={!branch_id || !reason || !quantity}
            onClick={() =>
              postConfirmationRequest({
                branch_id: branch_id,
                reason: reason,
                quantity: quantity,
              })
            }
          >
            Send Request
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={closeFormDialog}
          >
            Cancel
          </button>
        </div>
      }
      onClose={() => closeFormDialog()}
    />
  );
};

export default RequestConfirmationForm;
