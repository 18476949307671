import React, { useCallback } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Card, CardContent, Grid } from "@material-ui/core";

import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import BatchTable from "./BatchTable";
import LoadingModal from "components/LoadingModal";
import OrderDetailsDialog from "./orderDetailModal";
import AtlasDialog from "components/Dialog";

import BatchOrderHOC from "../actions/order";
import ExportExcelHOC from "../actions/exportExcel";
import { getColorBadge } from "dictionary/badgeColor";

const SingleOrderStatus = [
  { label: "Pending", id: 0, colorName: "Yellow" },
  { label: "Processing", id: 1, colorName: "Blue" },
  { label: "Arrived", id: 2, colorName: "Green" },
  { label: "Collected", id: 3, colorName: "Green" },
  { label: "Rejected", id: 4, colorName: "Red" },
  { label: "Voided", id: 5, colorName: "Red" },
  { label: "Cancelled", id: 6, colorName: "Red" },
  { label: "To Pay", id: 7, colorName: "Cyan" },
];

const headerData = [
  { label: "Order Number", value: "order_number", width: "80%" },
  { label: "Agent", value: "agent", width: "150%" },
  {
    label: "Product",
    width: "150%",
    renderColumn: (rowItem) => (
      <>
        <span className="fw-600 fs-1 badge badge-pill py-1 px-2 bg-primary color-white inline-flex-align-center">
          {rowItem.product_type}
        </span>
        <p>{rowItem.product}</p>
        {rowItem.market_product.type_id !== 2 && <p>{rowItem.purchase_type}</p>}
        <p>{rowItem.title}</p>
      </>
    ),
  },
  {
    label: "Quantity",
    value: "quantity",
    width: "70%",
    containerClass: "text-lg-center",
  },
  {
    label: "Unit Price",
    value: "price",
    width: "80px",
    minWidth: "80px",
    contentClass: "text-lg-right",
  },
  {
    label: "Status",
    renderColumn: (rowItem) => {
      const { colorName = "" } =
        _.find(SingleOrderStatus, { id: rowItem.status_id }) || {};
      const { label } =
        _.find(SingleOrderStatus, { id: rowItem.status_id }) || {};
      return (
        <div
          className="at-status_badge m-0 py-1"
          style={{ ...getColorBadge(colorName) }}
        >
          {`${label}`}
        </div>
      );
    },
    width: "80%",
  },
];

const NameCardDetail = ({
  showOrderDetails,
  selectedOrder,
  onChangeOrderHOC,
  selectedBatch,
  onChangeNameCardMngmtHOC,
  searchParams,
  exportExcel,
  getSelectedBatch,
  getSelectedOrder,

  onLoadOrder,
  onLoadNameCardMngmt,
  onLoadExportExcel,
  selectedBatchOrder,
  selectedBatchOrderPage,
  onClose,
}) => {
  const renderCardContent = useCallback(() => {
    const {
      id = "N/A",
      type_id,
      created_at = "N/A",
      order_batch_items_count,
      by_user_name = "N/A",
    } = selectedBatch;
    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => onClose()} />
          <h4 className="at-card__title">Order Batch Items</h4>
          <button
            className={"btn-new btn-new--secondary"}
            style={{
              width: "fit-content",
              fontWeight: "400",
              fontSize: "14px",
            }}
            onClick={() => exportExcel(id)}
          >
            Export to Excel
          </button>
        </div>
        <Card className={"mb-20"}>
          <CardContent>
            <Grid container spacing={16}>
              <Grid item md={6} xs={12}>
                <p className={"mb-20"}>
                  <span className="at-namecard-mngmt-list-content_title">
                    Batch ID:{" "}
                  </span>
                  {id} / {type_id === 0 ? "Starter Kit" : "Re-Order"}
                </p>
                <p>
                  <span className="at-namecard-mngmt-list-content_title">
                    Total Items:{" "}
                  </span>
                  {order_batch_items_count || "N/A"}
                </p>
              </Grid>
              <Grid item md={6} xs={12}>
                <p className={"mb-20"}>
                  <span className="at-namecard-mngmt-list-content_title">
                    Export Date:{" "}
                  </span>
                  {created_at}
                </p>
                <p>
                  <span className="at-namecard-mngmt-list-content_title">
                    By:{" "}
                  </span>
                  {by_user_name || "N/A"}
                </p>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <BatchTable
          headerData={headerData}
          actionColumn={true}
          actionColumnContent={["details"]}
          rowData={selectedBatchOrder.data ? selectedBatchOrder.data : []}
          meta={selectedBatchOrder.meta}
          onClickView={(val) => getSelectedOrder(val.id)}
          searchParams={searchParams}
          onChangeOrderHOC={onChangeOrderHOC}
          onChangeSearchParams={(val) => onChangeOrderHOC(val, "searchParams")}
          getListAPI={(page, search) => getSelectedBatch(id, page, search)}
          totalPages={selectedBatchOrderPage}
        />
      </>
    );
  }, [selectedBatch, selectedBatchOrder, searchParams, selectedBatchOrderPage]);

  return (
    <>
      <CustomCard className={"h-100"} cardContent={renderCardContent()} />
      <AtlasDialog open={showOrderDetails}>
        <OrderDetailsDialog
          onClose={() => onChangeOrderHOC(false, "showOrderDetails")}
          onChangeOrderHOC={onChangeOrderHOC}
          selectedOrder={selectedOrder}
          onChangeNameCardMngmtHOC={onChangeNameCardMngmtHOC}
        />
      </AtlasDialog>
      {(onLoadNameCardMngmt || onLoadOrder || onLoadExportExcel) && (
        <LoadingModal />
      )}
    </>
  );
};

export default compose(BatchOrderHOC, ExportExcelHOC)(NameCardDetail);
