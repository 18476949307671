import React, { Component } from "react";

import TimelineComponent from "components/Timeline";
import LoadingModal from "components/LoadingModal";

import SubsalesActivity from "../../actions/activity";

class Activity extends Component {
  componentDidMount = () =>
    this.props.getActivities(this.props.selectedSale.id);

  render = () => {
    const { activities } = this.props;
    return (
      <div className="at-new-sales__timeline-cont ">
        {activities ? (
          activities.length > 0 ? (
            activities.map((item) => {
              return (
                <section key={item.id} className="at-new-sales__timeline">
                  <TimelineComponent
                    key={item.id}
                    content={item.content}
                    createdAt={item.created_at}
                  />
                </section>
              );
            })
          ) : (
            <div>No data presence.</div>
          )
        ) : (
          <div>No data presence.</div>
        )}
        {this.props.onLoadActivity && <LoadingModal />}
      </div>
    );
  };
}

export default SubsalesActivity(Activity);
