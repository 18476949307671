import React, { useEffect, useMemo, useState, useRef } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

import CustomButton from "components/Button";
import IOSButton from "components/Button/toggle";
import ModalDialog from "components/Modal/ModalDialog";

const SearchProjectName = ({
  projectList,
  searchParams,
  onLoadProject,

  onClose,
  getProjectListing,
  onChangeSearchParams,
}) => {
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [exclusiveOnly, setExclusiveOnly] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  useEffect(() => {
    if (!onLoadProject && inputRef.current) {
      inputRef.current.focus();
    }
  }, [onLoadProject]);

  const listItem = useMemo(() => {
    return _.filter(projectList, (item) => {
      return item.value.toLowerCase().includes(searchValue.toLowerCase());
    }).filter((item) => {
      if (exclusiveOnly) {
        return item.is_exclusive;
      } else {
        return true;
      }
    });
  }, [projectList]);

  const onChangeSearchValue = _.debounce((value) => {
    setSearchValue(value);
    getProjectListing(value, exclusiveOnly);
  }, 1000);

  const onToggleExclusiveOnly = () => {
    setExclusiveOnly(!exclusiveOnly);
    getProjectListing(searchValue, !exclusiveOnly);
  };

  const onClickSubmit = () => {
    let temp = _.cloneDeep(searchParams);
    temp[0].param = selectedProject.value;
    onChangeSearchParams(temp);
    onClose();
  };

  return (
    <ModalDialog
      title={"Search Project"}
      onClose={onClose}
      responsiveSize={"sm"}
      children={
        <>
          <p className="fs-2 mb-10">
            Enter a part of the project to filter and search for that project.
            All the matching projects are shown below.
          </p>
          <label className="at-form-input__title">Project Name</label>
          <div className="at-form-input">
            <input
              type="text"
              ref={inputRef}
              autoFocus={true}
              disabled={onLoadProject}
              className="at-form-input__input"
              placeholder="Type Project Name"
              onChange={(event) => onChangeSearchValue(event.target.value)}
            />
          </div>
          <div className="d-flex my-2">
            <IOSButton
              className={"at-toggle__secondary"}
              currentState={!exclusiveOnly}
              onToggleButton={() => onToggleExclusiveOnly()}
            />
            <p className="fs-2 mr-2">
              Only show projects with 'Project Closers'
            </p>
          </div>
          <div
            style={{
              maxHeight: 300,
              overflowY: "auto",
              border: "1px solid #e5e5e5",
              borderRadius: 8,
              padding: 10,
            }}
          >
            <PulseLoader
              sizeunit={"px"}
              size={8}
              className="ml-2"
              color={"#16232c"}
              loading={onLoadProject}
            />
            {listItem.map((item, index) => (
              <p
                key={index}
                className={`at-cobroke__project_name ${selectedProject === item ? "at-cobroke__project_name-selected" : ""}`}
                onClick={() => setSelectedProject(item)}
              >
                {item.value}
              </p>
            ))}
            {listItem.length === 0 && (
              <p className="at-form-input__title p-2 text-center">No results</p>
            )}
          </div>
        </>
      }
      footer={
        <div className="d-flex justify-content-end g-3">
          <CustomButton
            className="btn-new btn-new--outline-secondary"
            onClick={onClose}
            children={"Close"}
          />
          <CustomButton
            disabled={!onLoadProject && !selectedProject}
            className="btn-new btn-new--secondary"
            onClick={() => onClickSubmit()}
            children={"Select"}
          />
        </div>
      }
    />
  );
};

export default SearchProjectName;
