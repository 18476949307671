import React, { Component } from "react";
import _ from "lodash";
import Axios from "axios";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Delete } from "utils/axios";
import getDomainURL from "utils/getDomainURL";
import base64ToDownloadUrl from "utils/base64ToDownloadUrl";

const getType = (type_id) => {
  switch (type_id) {
    case 1:
      return "Official Receipt";
    case 2:
      return "Invoice";
    case 3:
      return "Manual Official Receipt";
    case 4:
      return "Manual Invoice";
    default:
      return "N/A";
  }
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showUploadModal: false,
      showDeleteConfirmation: false,
      showUploadLoading: false,
      uploadInProgress: false,

      docsFinance: [],
      selectedDeleteDoc: null,

      documents: [],
      tempUploadDocument: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeFinanceDocsHOC = (val, context) =>
      this.setState({ [context]: val });

    getFinanceDocs = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/finance_documents`,
        this.getFinanceDocsSuccess,
        this.getFinanceDocsError,
        this.load,
      );
    getFinanceDocsSuccess = (payload) =>
      this.setState({
        docsFinance: [
          ...payload.finance_documents.map((item) => ({
            ...item,
            url: item.document_url,
            doc_type: "finance doc",
          })),
          ...payload.pv_documents.map((item) => ({
            ...item,
            url: base64ToDownloadUrl(item.file),
            doc_type: "pv doc",
          })),
        ],
      });
    getFinanceDocsError = (error) => requestError(error);

    uploadFinanceDocs = (dataToSubmit) => {
      this.setState(
        {
          showUploadLoading: true,
          tempUploadDocument: dataToSubmit,
          uploadInProgress: true,
        },
        () => {
          dataToSubmit.map((data, index) => {
            Axios.post(
              `${getDomainURL()}/admin/sub_sale_claims/${this.props.selected_claim.id}/finance_documents`,
              data,
            )
              .then((res) => {
                let tempAllDocs = _.cloneDeep(this.state.tempUploadDocument);
                tempAllDocs[index] = {
                  ...data,
                  result: "success",
                  loading: false,
                };
                this.setState({ tempUploadDocument: tempAllDocs });
              })
              .catch(async (err) => {
                let tempAllDocs = _.cloneDeep(this.state.tempUploadDocument);
                tempAllDocs[index] = {
                  ...data,
                  result: "error",
                  messages: err.message,
                  loading: false,
                };
                this.setState({ tempUploadDocument: tempAllDocs });
              });
          });
          this.setState({ uploadInProgress: false });
        },
      );
    };
    uploadFinanceDocsSuccess = (payload) => {
      requestSuccess("Document uploaded successfully.");
      this.setState({ showUploadModal: false });
      this.getFinanceDocs(payload.sub_sale_claim_id);
    };
    uploadFinanceDocsError = (error) => requestError(error);

    deleteFinanceDocs = (claim_id, id) =>
      Delete(
        `/admin/sub_sale_claims/${claim_id}/finance_documents/${id}`,
        () => this.deleteFinanceDocsSucess(claim_id),
        this.deleteFinanceDocsError,
        this.load,
      );
    deleteFinanceDocsSucess = (claim_id) => {
      requestSuccess("Document deleted successfully.");
      this.setState({
        showDeleteConfirmation: false,
      });
      this.getFinanceDocs(claim_id);
    };
    deleteFinanceDocsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            documents={this.state.documents}
            uploadInProgress={this.state.uploadInProgress}
            docsFinance={this.state.docsFinance}
            onLoadDocsFinance={this.state.loading}
            showUploadModal={this.state.showUploadModal}
            selectedDeleteDoc={this.state.selectedDeleteDoc}
            showUploadLoading={this.state.showUploadLoading}
            tempUploadDocument={this.state.tempUploadDocument}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            getFinanceDocs={this.getFinanceDocs}
            uploadFinanceDocs={this.uploadFinanceDocs}
            deleteFinanceDocs={this.deleteFinanceDocs}
            onChangeFinanceDocsHOC={this.onChangeFinanceDocsHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
