import React, { useState } from "react";
import _ from "lodash";

import CustomTab from 'components/Tab';
import CustomButton from 'components/Button'
import AtlasTable from 'components/NewTable'
import AtlasIcon from 'components/Icon/atlasIcon'
import AtlasRadioGroup from 'components/RadioGroup'
import ModalDialog from "components/Modal/ModalDialog";
import CustomModuleHeader from 'components/Form/header'
import CustomFormInput from 'components/Input/formInput'

import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import { TicketPricingColumnData, JointTicketColumnData } from "../assets";

const editDataCheckingLib = [
  {
    value: "maximum_seat",
    onDisabled: (data) => {
      if (data.is_seat_limited) {
        return isEmptyValue(data.maximum_seat);
      }
      return false;
    },
  },
  {
    value: "is_seat_limited",
    onDisabled: (data) => isEmptyValue(data.is_seat_limited),
  },
  {
    value: "is_joint_ticket",
    onDisabled: (data) => isEmptyValue(data.is_joint_ticket),
  },
];

const BooleanRadio = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const UpdateTicket = ({
  data,
  onLoad,
  jointTicketsList,
  priceManagementList,
  selectedTicketTypeDetail,

  onClose,
  updateTicketType,
  onChangeEditEventHOC,
  getAvailableJointTickets,
}) => {
  const [ selectedTab, onChangeSelectedTab ] = useState( 'Ticket Details' )
  const { can_create, can_destroy } = permissionsChecker( 'Company Events', data )

  const onChangeUpdateTicketType = (value, context) => {
    let tmpTicketData = _.cloneDeep(selectedTicketTypeDetail);
    tmpTicketData[context] = value;
    onChangeEditEventHOC(tmpTicketData, "selectedTicketTypeDetail");
  };

  const renderTicketType = () => (
    <>
      <div className="grid-control">
        <section className="grid-full-col">
          <h4 className="at-form-input__title">Title</h4>
          <CustomFormInput
            type="text"
            required={ true }
            value={ selectedTicketTypeDetail[ 'title' ] }
            onChangeValue={ val => onChangeUpdateTicketType( val, 'title' ) } />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Type</h2>
          <p className="mt-2">{ selectedTicketTypeDetail[ 'type' ] }</p>
          </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Is Joint Ticket</h2>
          <AtlasRadioGroup
            horizontal={ true }
            checkedValue={ selectedTicketTypeDetail.is_joint_ticket }
            selectedRadioValue={ val => onChangeUpdateTicketType( val, 'is_joint_ticket' ) }
            options={ BooleanRadio } />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Is Seat Limited?</h2>
          <AtlasRadioGroup
            horizontal={ true }
            checkedValue={ selectedTicketTypeDetail.is_seat_limited }
            selectedRadioValue={ val => onChangeUpdateTicketType( val, 'is_seat_limited' ) }
            options={ BooleanRadio } />
        </section>
        {
          selectedTicketTypeDetail.is_seat_limited && (
            <section className="grid-full-col">
              <h4 className="at-form-input__title">Maximum Seat</h4>
              <CustomFormInput
                type="text"
                required={ true }
                value={ selectedTicketTypeDetail[ 'maximum_seat' ] }
                onChangeValue={ val => onChangeUpdateTicketType( val, 'maximum_seat' ) } />
            </section>
          )
        }
        <section className="grid-full-col">
          <h4 className="at-form-input__title">Remark</h4>
          <CustomFormInput
            type="textarea"
            value={ selectedTicketTypeDetail[ 'remarks' ] }
            onChangeValue={ val => onChangeUpdateTicketType( val, 'remarks' ) } />
        </section>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <CustomButton
          onClick={ updateTicketType }
          disabled={ editDataCheckingLib.some( item => item.onDisabled( selectedTicketTypeDetail ) )}
          className={ 'btn-new btn-new--success' }
          children={ 'Update' } />
        <CustomButton
          onClick={ onClose }
          className={ 'btn-new btn-new--outline-secondary' }
          children={ ' Close' } />
      </div>
    </>
  )

  const renderPricingManagement = () => (
    <AtlasTable
      hideSearch={ false }
      className={ 'mb-100' }
      columns={ TicketPricingColumnData }
      pagination={ true }
      rowData={ priceManagementList || [] }
      renderTabFilter={ () => (
        <div className='at-table__head-title'>
          <CustomModuleHeader
            title={ 'Pricing Management' }
            moduleIcon={ 'atlas-ticket' } />
          <button
            disabled={ !can_create }
            className="btn-new-sm btn-new--primary ml-2"
            onClick={ () => onChangeEditEventHOC( true, 'showCreateTicketPricing' ) } >
            <AtlasIcon svgHref={ 'atlas-add-linear' } />
            New Pricing
          </button>
        </div>
      ) }
      actionColumnContent={[
        {
          name: 'edit', 
          onClick: rowData => {
            onChangeEditEventHOC( rowData, 'selectedTicketPricing' )
            onChangeEditEventHOC( true, 'showUpdateTicketPricing' )

          }
        },
        {
          name: 'attach',
          onShow: () => can_create,
          onClick: rowData => {
            onChangeEditEventHOC( rowData, 'selectedTicketPricing' )
            getAvailableJointTickets( rowData.id )
            onChangeEditEventHOC( true, 'showAttachTicketPricing' )
          }
        },
        {
          name: 'delete', 
          color: '#F04438',
          onShow: () => can_destroy,
          onClick: rowData => {
            onChangeEditEventHOC( rowData.id, 'toBeDeletedId' )
            onChangeEditEventHOC( true, 'showDeletePricingTicketConfirmation' )
          }
        }
      ]}
    />
  )

  const renderJointTicket = () => (
    <AtlasTable
      hideSearch={ false }
      className={ 'mb-100' }
      columns={ JointTicketColumnData }
      pagination={ true }
      rowData={ jointTicketsList || [] }
      renderTabFilter={ () => (
        <div className='at-table__head-title'>
          <CustomModuleHeader
            title={ 'Joint Ticket Management' }
            moduleIcon={ 'atlas-ticket' } />
        </div>
      ) }
      actionColumnContent={[
        {
          name: 'delete', 
          color: '#F04438',
          onShow: () => can_destroy,
          onClick: rowData => {
            onChangeEditEventHOC( rowData.id, 'toBeDeletedId' )
            onChangeEditEventHOC( true, 'showDeleteAttachedTicketConfirmation' )
          }
        }
      ]}
    />
  )

  return (
    <ModalDialog
      title={ 'Edit Ticket Type' }
      onLoad={ onLoad }
      onClose={ onClose }
      fullWidth={ true }
      fullHeight={ true }
      children={
        <>
          <CustomTab
            sections={ [ 'Ticket Details', 'Pricing Management', 'Joint Ticket Management' ] }
            selectedSection={ selectedTab }
            onSelectSection={ val => onChangeSelectedTab( val ) } />
          { selectedTab === 'Ticket Details' && renderTicketType() }
          { selectedTab === 'Pricing Management' && renderPricingManagement() }
          { selectedTab === 'Joint Ticket Management' && renderJointTicket() }
        </>
      }
    />
  );
};

export default UpdateTicket;
