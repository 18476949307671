import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { MdFilterList } from "react-icons/md";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import AlertBox from "components/AlertBox";
import SearchParamsContent from "../NewPaginatedTable/SearchParams";
import TableContent from "./Content";

import { project as projectDictionary } from "dictionary";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    paginationSize: 10,
    showSearchParams: false,
  };

  componentDidMount = () => {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ paginationSize: 3, interval: 3 });
    } else {
      this.setState({ paginationSize: 10, interval: 10 });
    }
  };

  renderTableContent = () => {
    const { title, type, tenure } = projectDictionary;

    const processProjectParams = (param, props) => {
      let data = _.find(props, { id: param });

      return data ? data.name : "";
    };

    if (this.props.rowData && this.props.rowData.length > 0) {
      return (
        <ul className="at-project__container">
          {this.props.rowData.map((item) => {
            return (
              <TableContent
                {...item}
                listItem={item}
                key={item.id}
                onShowRemoveIcon={this.props.onShowRemoveIcon}
                onShowAddIcon={this.props.onShowAddIcon}
                actionsColumns={this.props.actionsColumns}
                keyRef={`ProjectListing-${item.id}`}
                onClickAdd={() => this.props.onClickAdd(item)}
                onClickRemove={() => this.props.onClickRemove(item)}
                onClickDetails={() => this.props.onClickDetails(item)}
                imgSrc={item.background_image_url}
                title={item.name}
                projectTitleType={processProjectParams(
                  item.project_title_id,
                  title,
                )}
                projectType={processProjectParams(item.type_id, type)}
                tenureType={processProjectParams(item.tenure_type_id, tenure)}
                measurement={`${item.min_built_up || "N/A"}-${item.max_built_up || "N/A"}`}
              />
            );
          })}
        </ul>
      );
    } else {
      return (
        <AlertBox
          description={
            this.props.nullMessage ||
            "There are no projects available. Please stay tune for more exciting projects."
          }
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = item.param !== ""
                ? item.withoutPrefix
                  ? tmp + `${item.value}=${item.param}&`
                  : tmp + `q[${item.value}]=${
                    item.type === "typeahead"
                    ? item.param.name
                    : item.param
                  }&`
                : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: this.state.paginationSize });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = item.param !== ""
                ? item.withoutPrefix
                  ? tmp + `${item.value}=${item.param}&`
                  : tmp + `q[${item.value}]=${
                    item.type === "typeahead"
                    ? item.param.name
                    : item.param
                  }&`
                : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
            this.setState({
              interval:
                Math.ceil(this.props.meta.prev / this.state.paginationSize) *
                this.state.paginationSize,
            });
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= this.state.paginationSize || !this.props.meta ? "disabled" : ""}`}
          onClick={() =>
            this.setState({
              interval: this.state.interval - this.state.paginationSize,
            })
          }
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - this.state.paginationSize
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp = item.param !== ""
                        ? item.withoutPrefix
                          ? tmp + `${item.value}=${item.param}&`
                          : tmp + `q[${item.value}]=${
                            item.type === "typeahead"
                            ? item.param.name
                            : item.param
                          }&`
                        : tmp;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + this.state.paginationSize });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = item.param !== ""
                ? item.withoutPrefix
                  ? tmp + `${item.value}=${item.param}&`
                  : tmp + `q[${item.value}]=${
                    item.type === "typeahead"
                    ? item.param.name
                    : item.param
                  }&`
                : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
            this.setState({
              interval:
                Math.ceil(this.props.meta.next / this.state.paginationSize) *
                this.state.paginationSize,
            });
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = item.param !== ""
                ? item.withoutPrefix
                  ? tmp + `${item.value}=${item.param}&`
                  : tmp + `q[${item.value}]=${
                    item.type === "typeahead"
                      ? item.param.name
                      : item.param
                  }&`
                : tmp;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    const { data, meta, searchParams, onChangeSearchParams, getListAPI } =
      this.props;
    const { sideMenuExpand } = data.sideMenuReducer;

    return (
      <div
        className={`at-table__options mb-10 at-project-table ${
          sideMenuExpand ? "menu-expand" : "menu-shrink"
        }`}
      >
        {searchParams.length > 0 && (
          <div className="mb-3">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() =>
                this.setState({
                  showSearchParams: !this.state.showSearchParams,
                })
              }
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller ">
              <SearchParamsContent
                mode={"desktop"}
                searchParams={searchParams}
                onChangeSearchParams={onChangeSearchParams}
                getListAPI={getListAPI}
              />
            </div>
          </div>
        )}
        <div>{this.renderTableContent()}</div>
        {meta && meta.count > 0 && (
          <div
            className="d-flex table-footer"
            style={{ flexDirection: "column" }}
          >
            {this.renderTablePagination()}
            {this.renderPaginationText()}
          </div>
        )}
        {this.state.showSearchParams && (
          <SearchParamsContent
            mode={"mobile"}
            searchParams={searchParams}
            onToggleSearchModal={() =>
              this.setState({ showSearchParams: !this.state.showSearchParams })
            }
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(PaginatedTable);
