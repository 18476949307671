import React, { useState, useEffect } from "react";
import _ from "lodash";
import Moment from "moment";
import ReactPlayer from "react-player";
import DatePicker from "react-datepicker";
import ReactHTMLParser from "react-html-parser";
import { FormHelperText } from "@material-ui/core";

import CustomCard from "components/Card";
import CKEditor from "components/CKEditor";
import AtlasDialog from "components/Dialog";
import CustomCheckbox from "components/Checkbox";
import TeaserImage from "components/TeaserImage";
import ShareButton from "components/Button/share";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";

import permissionsChecker from "utils/permissionsChecker";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DatepickerInput = ({ ...props }) => (
  <input type="text" style={{ backgroundColor: "#ffff" }} {...props} readOnly />
);

const shareMedia = [
  {
    id: 0,
    value: "facebook",
  },
  {
    id: 1,
    value: "twitter",
  },
  {
    id: 2,
    value: "linkedin",
  },
  {
    id: 3,
    value: "email",
  },
];

const Update = ({
  data,
  showPreviewModal,
  onChangeNewsFeedHOC,
  selectedNewsfeed,
  updateNewsfeed,

  onLoadNewsfeedManagement,
  onLoadTeaser,
}) => {
  const [editForm, onChangeEditForm] = useState({
    content: "",
    title: "",
    published: "draft",
    creator_name: "",
    is_new: false,
    publish_at: "",
    youtube_link: "",
    shareable_link: "",
    created_at: null,
    header_image: null,
    header_image_file_name: "",
    visible_to_agent: false,
    visible_to_associated_agent: false,
  });
  const [doc, setDoc] = useState({
    file: "",
    filename: "",
  });
  const { can_update } = permissionsChecker("Newsfeeds", data);

  useEffect(() => {
    onChangeEditForm({
      id: selectedNewsfeed.id,
      content: selectedNewsfeed.content,
      title: selectedNewsfeed.title,
      published: selectedNewsfeed.published,
      creator_name: selectedNewsfeed.creator_name,
      is_new: selectedNewsfeed.is_new,
      youtube_link: selectedNewsfeed.youtube_link,
      shareable_link: selectedNewsfeed.shareable_link,
      created_at: selectedNewsfeed.created_at,
      publish_at: selectedNewsfeed.publish_at,
      header_image: selectedNewsfeed.teaser_image_url,
      header_image_file_name: "",
      visible_to_agent: selectedNewsfeed.visible_to_agent,
      visible_to_associated_agent: selectedNewsfeed.visible_to_associated_agent,
    });
  }, []);

  const onChangeEditField = (key, val) => {
    let tmp = _.cloneDeep(editForm);
    tmp[key] = val;
    return onChangeEditForm(tmp);
  };

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          setDoc({
            file: e.target.result,
            filename: fileItems[0].file.name,
          });
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const renderShareButtons = () => {
    if (editForm.shareable_link) {
      return (
        <div className="mt-4">
          <p className="at-card__title mb-1">SHARE THIS ARTICLE</p>
          <div className="d-flex grid_gap-2">
            {shareMedia.map((item) => (
              <ShareButton
                containerClass={"at-share_btn_mono"}
                containerStyle={{ display: "inline-block" }}
                key={item.id}
                shareLink={editForm.shareable_link}
                media={item.value}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <AtlasDialog open={true}>
      <CustomCard
        className="h-100"
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton
                onClick={() => onChangeNewsFeedHOC(false, "showUpdateNewsfeed")}
              />
              <h4 className="at-card__title">{"Edit Newsfeed"}</h4>
            </div>
            <div className="grid-control grid_gap-x-3">
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Title
                </h2>
                <CustomFormInput
                  type="text"
                  value={editForm.title}
                  disabled={!can_update}
                  required={true}
                  onChangeValue={(val) => {
                    onChangeEditField("title", val);
                  }}
                  placeholder={"Enter your post title here."}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Teaser Image</h2>
                {(editForm.header_image || doc.file) && (
                  <TeaserImage
                    src={editForm.header_image || doc.file}
                    can_update={can_update}
                    containerStyle={{ marginTop: 20 }}
                    onRemoveImage={() => {
                      setDoc({ file: "", filename: "" });
                      onChangeEditField("header_image", null);
                    }}
                  />
                )}
                {!(editForm.header_image || doc.file) && (
                  <div className={"mt-20"}>
                    <FilePond
                      allowMultiple={false}
                      fullWidth
                      disabled={!can_update}
                      maxFiles={1}
                      acceptedFileTypes={["image/*"]}
                      onupdatefiles={(fileItems) => onChangeFile(fileItems)}
                    />
                    <FormHelperText>
                      Accepted file types: jpg, jpeg, png.( Required )
                    </FormHelperText>
                  </div>
                )}
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10">Content</h2>
                <CKEditor
                  data={editForm.content}
                  disabled={!can_update}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    onChangeEditField("content", data);
                  }}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Youtube Link
                </h2>
                <CustomFormInput
                  type="text"
                  value={editForm.youtube_link}
                  required={true}
                  onChangeValue={(val) =>
                    onChangeEditField("youtube_link", val)
                  }
                  placeholder={"Enter your post youtube link here."}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Shareable Link
                </h2>
                <CustomFormInput
                  type="text"
                  value={editForm.shareable_link}
                  required={true}
                  onChangeValue={(val) =>
                    onChangeEditField("shareable_link", val)
                  }
                  placeholder={"Enter your post shareable link here."}
                />
              </section>
              <section className="grid-half-col">
                <h2 className="at-form-input__title mr-10">Visible To Role</h2>
                <CustomCheckbox
                  disabled={!can_update}
                  content={"Agent"}
                  checked={editForm.visible_to_agent}
                  onChangeCheckboxValue={(e) =>
                    onChangeEditField("visible_to_agent", e.target.checked)
                  }
                />
                <CustomCheckbox
                  disabled={!can_update}
                  content={"Associated Agent"}
                  checked={editForm.visible_to_associated_agent}
                  onChangeCheckboxValue={(e) =>
                    onChangeEditField(
                      "visible_to_associated_agent",
                      e.target.checked,
                    )
                  }
                />
              </section>
              <section className="grid-half-col">
                <h2 className="at-form-input__title mr-10">Status</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  disabled={!can_update}
                  checkedValue={editForm.published}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeEditField("published", val)
                  }
                  options={[
                    { value: "draft", label: "Draft" },
                    { value: "published", label: "Published" },
                  ]}
                />
              </section>
              <section className="grid-half-col">
                <h2 className="at-form-input__title mr-10">Publish Date</h2>
                <DatePicker
                  placeholderText="Choose Date"
                  showYearDropdown={true}
                  disabled={!can_update}
                  className="form-control"
                  dateFormat="DD MMM YYYY"
                  customInput={<DatepickerInput />}
                  value={
                    editForm.publish_at
                      ? Moment(editForm.publish_at, "YYYY-MM-DD").format(
                          "DD MMM YYYY",
                        )
                      : null
                  }
                  onChange={(val) => onChangeEditField("publish_at", val)}
                />
              </section>
              <section className="grid-full-col d-flex">
                {can_update && (
                  <button
                    type={"button"}
                    className="btn-new btn-new--success mr-2"
                    onClick={() =>
                      updateNewsfeed({
                        ...editForm,
                        ...(doc.file && {
                          header_image: doc.file,
                          header_image_file_name: doc.filename,
                        }),
                        published: editForm.published === "published",
                      })
                    }
                  >
                    Update
                  </button>
                )}
                <button
                  type={"button"}
                  className="btn-new btn-new--secondary mr-2"
                  onClick={() => onChangeNewsFeedHOC(true, "showPreviewModal")}
                >
                  Preview
                </button>
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary"
                  onClick={() =>
                    onChangeNewsFeedHOC(false, "showUpdateNewsfeed")
                  }
                >
                  Close
                </button>
              </section>
            </div>
          </>
        }
      />
      {
        <AtlasDialog open={showPreviewModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <div style={{ position: "relative" }}>
                <AtlasCloseButton
                  containerStyle={{
                    position: "sticky",
                    marginBottom: "0.5rem",
                    top: 0,
                    left: 0,
                  }}
                  onClick={() => onChangeNewsFeedHOC(false, "showPreviewModal")}
                />
                <div style={{ maxWidth: 800, margin: "auto" }}>
                  {editForm.youtube_link && (
                    <ReactPlayer
                      url={editForm.youtube_link}
                      playing={true}
                      controls={true}
                      className={"at-newsfeed-video-player"}
                      style={{ backgroundColor: "black" }}
                    />
                  )}
                  {!editForm.youtube_link && (
                    <img
                      style={{
                        borderRadius: 5,
                        marginBottom: 10,
                        aspectRatio: "16/9",
                      }}
                      src={editForm.header_image}
                      alt={`teaser-image-${editForm.title}`}
                    />
                  )}
                  <h2 className="at-card__title mb-2">{editForm.title}</h2>
                  <p
                    className="text-secondary mb-20"
                    style={{ fontWeight: 500 }}
                  >
                    {Moment(editForm.created_at, "DD-MM-YYYY").format(
                      "DD MMM YYYY",
                    )}
                  </p>
                  {ReactHTMLParser(editForm.content)}
                  {renderShareButtons()}
                </div>
              </div>
            }
          />
        </AtlasDialog>
      }
      {(onLoadNewsfeedManagement || onLoadTeaser) && <LoadingModal />}
    </AtlasDialog>
  );
};

export default Update;
