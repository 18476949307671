import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Get, Delete, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const searchParams = [
  { label: "Full Name", value: "name_cont", type: "input", param: "" },
  {
    label: "Mobile Contact Number",
    value: "name_cont",
    type: "input",
    param: "",
  },
  { label: "Email", value: "name_cont", type: "input", param: "" },
];

const tickerSearchPrams = [
  {
    label: "Purchaser Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Purchaser Mobile Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  { label: "Ticket ID ", value: "id_eq", type: "input", param: "" },
  {
    label: "Assignee Email",
    value: "assignee_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Assignee Mobile Number",
    value: "assignee_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Ticket Status",
    value: "ticket_status",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Pending", value: "assignee_id_eq" },
      { label: "Assigned", value: "assignee_id_gt" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showEventModal: false,
      showVairantOptions: false,
      availableTicket: [],
      selectedSection: "Details",
      editProductForm: {
        name: "",
        price: null,
        unit_measurement: "",
        description: "",
        country_id: null,
        type_id: null,
        status_id: "1",
        has_variants: false,
        is_quantity_fixed: false,
        delivery_branch_selectable: false,
        photo: {},
        photo_file_name: "",
        is_complimentary: false,
        attached_product_id: null,
        attached_product_type: "",
        attached_product: {},
        product_options: [],
        product_variants: [],
        market_product_attached_items: [],
        market_product_attached_items_attributes: [],
      },

      searchParams: searchParams,
      tickerSearchPrams: tickerSearchPrams,
      preOrderNameList: {
        data: [],
        meta: {},
      },
      ticketPurchasers: {
        data: [],
        meta: {},
      },
      preOrderNameListPages: {},
      ticketPurchasersPages: {},
      showAddNameList: false,
      showTransferTicket: false,
      showRemoveNameList: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeEditProductHOC = (val, context) =>
      this.setState({ [context]: val });

    deleteProductSubItem = (productId, itemId, index) => {
      return Delete(
        `/admin/market_products/${productId}/market_product_attached_items/${itemId}`,
        (payload) => this.deleteProductSubItemSuccess(payload, index),
        this.deleteProductSubItemError,
        this.load,
      );
    };
    deleteProductSubItemSuccess = (payload, index) => {
      let tmp = { ...this.state.editProductForm };

      tmp.market_product_attached_items_attributes.splice(index, 1);
      if (payload && payload.message) {
        requestSuccess(payload.message);
      }
      this.setState({
        editProductForm: tmp,
      });
    };
    deleteProductSubItemError = (error) => requestError(error);

    getAvailableTicket = () =>
      Get(
        `/admin/market_products/available_attached_products?type_id=3`,
        this.getAvailableTicketSuccess,
        this.getAvailableTicketError,
        this.load,
      );
    getAvailableTicketSuccess = (payload) =>
      this.setState({
        showEventModal: true,
        availableTicket: payload.data,
      });
    getAvailableTicketError = (error) => requestError(error);

    getTicketPurchasers = async (id, page, search) => {
      if (
        search.includes("ticket_status") &&
        search.includes("assignee_id_eq")
      ) {
        search = search.replace("assignee_id_eq", "0");
        search = search.replace("ticket_status", "assignee_id_eq");
      } else if (
        search.includes("ticket_status") &&
        search.includes("assignee_id_gt")
      ) {
        search = search.replace("assignee_id_gt", "0");
        search = search.replace("ticket_status", "assignee_id_gt");
      }

      Get(
        `/admin/market_products/${id}/tickets?${search}page=${page}`,
        this.getTicketPurchasersSuccess,
        this.getTicketPurchasersError,
        this.load,
      );
    };
    getTicketPurchasersSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        ticketPurchasers: payload,
        ticketPurchasersPages: tempPages,
      });
    };
    getTicketPurchasersError = (error) => requestError(error);

    ticketTransfer = (id, ticket_id, user_id) =>
      Put(
        `/admin/market_products/${id}/tickets/${ticket_id}`,
        { user_id: user_id },
        this.ticketTransferSuccess,
        this.ticketTransferError,
        this.load,
      );
    ticketTransferSuccess = (payload) => {
      this.setState({ showTransferTicket: false });
      this.getTicketPurchasers(this.state.editProductForm.id, 1, "");
      requestSuccess("Ticket transferred");
    };
    ticketTransferError = (error) => requestError(error);

    attachTicket = (id, productId) =>
      Post(
        `/admin/market_products/${id}/add_attached_product`,
        {
          type_id: 3,
          attached_product_id: productId,
        },
        this.attachTicketSuccess,
        this.attachTicketError,
        this.load,
      );
    attachTicketSuccess = (payload) => {
      this.setState({ showEventModal: false });
      this.props.getSelectedProductMngmt(payload.id);
      requestSuccess("Ticket attached");
    };
    attachTicketError = (error) => requestError(error);

    removeAttachTicket = (id, productId) =>
      Delete(
        `/admin/market_products/${id}/remove_attached_product?type_id=3&attached_product_id=${productId}`,
        this.removeAttachTicketSuccess,
        this.removeAttachTicketError,
        this.load,
      );
    removeAttachTicketSuccess = (payload) => {
      this.props.getSelectedProductMngmt(payload.id);
      requestSuccess("Event ticket removed");
    };
    removeAttachTicketError = (error) => requestError(error);

    updateVariantOptions = (dataToSubmit) =>
      Put(
        `/admin/market_products/${dataToSubmit.id}/options`,
        { product_options_attributes: dataToSubmit.options },
        this.updateVariantOptionsSuccess,
        this.updateVariantOptionsError,
        this.load,
      );
    updateVariantOptionsSuccess = (payload) => {
      this.setState({ showVairantOptions: false });
      this.props.getSelectedProductMngmt(payload.id);
    };
    updateVariantOptionsError = (error) => requestError(error);

    getOrderNameList = (id, page, search) =>
      Get(
        `/admin/market_products/${id}/pre_order_users?${search}page=${page}`,
        this.getOrderNameListSuccess,
        this.getOrderNameListError,
        this.load,
      );
    getOrderNameListSuccess = (payload) => {
      let tempData = [];
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      tempData = _.map(payload.data, (item) => {
        delete item.user.id;
        return {
          ...item,
          ...item.user,
        };
      });

      this.setState({
        preOrderNameList: {
          data: tempData,
          meta: payload.meta,
        },
        preOrderNameListPages: tempPages,
      });
    };
    getOrderNameListError = (error) => requestError(error);

    createPreOrderName = (id, user_id) =>
      Post(
        `/admin/market_products/${id}/pre_order_users`,
        { user_id },
        this.createPreOrderNameSuccess,
        this.createPreOrderNameError,
        this.load,
      );
    createPreOrderNameSuccess = (payload) => {
      this.setState({ showAddNameList: false });
      this.getOrderNameList(payload.market_product_id, 1, "");
      requestSuccess("Pre order name created");
    };
    createPreOrderNameError = (error) => requestError(error);

    removePreOrderName = (id, user_id) =>
      Delete(
        `/admin/market_products/${id}/pre_order_users/${user_id}`,
        this.removePreOrderNameSuccess,
        this.removePreOrderNameError,
        this.load,
      );
    removePreOrderNameSuccess = (payload) => {
      this.getOrderNameList(this.state.editProductForm.id, 1, "");
      this.setState({ showRemoveNameList: false });
      requestSuccess(payload.message);
    };
    removePreOrderNameError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadEditProduct={this.state.loading}
          showEventModal={this.state.showEventModal}
          editProductForm={this.state.editProductForm}
          availableTicket={this.state.availableTicket}
          selectedSection={this.state.selectedSection}
          showVairantOptions={this.state.showVairantOptions}
          searchParams={this.state.searchParams}
          tickerSearchPrams={this.state.tickerSearchPrams}
          showAddNameList={this.state.showAddNameList}
          showTransferTicket={this.state.showTransferTicket}
          showRemoveNameList={this.state.showRemoveNameList}
          ticketPurchasers={this.state.ticketPurchasers}
          ticketPurchasersPages={this.state.ticketPurchasersPages}
          preOrderNameList={this.state.preOrderNameList}
          preOrderNameListPages={this.state.preOrderNameListPages}
          attachTicket={this.attachTicket}
          ticketTransfer={this.ticketTransfer}
          getOrderNameList={this.getOrderNameList}
          getTicketPurchasers={this.getTicketPurchasers}
          createPreOrderName={this.createPreOrderName}
          removeAttachTicket={this.removeAttachTicket}
          getAvailableTicket={this.getAvailableTicket}
          removePreOrderName={this.removePreOrderName}
          updateVariantOptions={this.updateVariantOptions}
          deleteProductSubItem={this.deleteProductSubItem}
          onChangeEditProductHOC={this.onChangeEditProductHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
