import { IconButton, Tooltip } from "@material-ui/core";
import { FaSignOutAlt } from "react-icons/fa";

import { getTranslation } from "../assets";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";

const WelcomeHeader = ({ currentModalSequence, sendLogoutRequest, tmpProfileReducer }) => {
  const language = tmpProfileReducer.country_id === 14 ? "jp" : "en"

  return (
    <div className={"welcome-header text-center mb-4"}>
      <div>
        <img src={JuwaiIQILogo} alt={"JuwaiLogo"} style={{ height: 200 }} />
        <span
          style={{
            fontSize: "26px",
            fontWeight: "600",
            display: "block",
          }}
        >
          {currentModalSequence === 0 && getTranslation("agent.profile.welcome1", language)}
          {currentModalSequence !== 0 && getTranslation("agent.profile.welcome2", language)}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          top: "30px",
          left: "50%",
          marginLeft: "150px",
        }}
      >
        <Tooltip title="Log out" placement="top">
          <IconButton onClick={() => sendLogoutRequest()}>
            <FaSignOutAlt style={{ width: 24 }} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default WelcomeHeader;
