import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const ContactHOC = (WrappedComponent) => {
  class ContactWrappedComponent extends Component {
    state = {
      contacts: [],
      showLoadingModal: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });

    getCurrentUserContactList = (sub_sale_claim_id) =>
      Get(
        `/admin/sub_sale_claims/${sub_sale_claim_id}/contacts/search_contact`,
        this.getCurrentUserContactListSuccess,
        this.getCurrentUserContactListError,
        this.load,
      );
    getCurrentUserContactListSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            label: item.full_name,
            value: item.id,
          };
          tmpArray.push(data);
        });
        return this.setState({ contacts: tmpArray });
      }
    };
    getCurrentUserContactListError = (error) => requestError(error);

    render = () => (
      <>
        <WrappedComponent
          {...this.props}
          contacts={this.state.contacts}
          showContactLoadingModal={this.state.showLoadingModal}
          getCurrentUserContactList={this.getCurrentUserContactList}
        />
      </>
    );
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(ContactWrappedComponent);
};

export default ContactHOC;
