import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import { AgreementType } from "./assets";

import EditAgreement from "./Edit";
import CreateAgreement from "./Create";

import LoadingModal from "components/LoadingModal";
import permissionsChecker from "utils/permissionsChecker";
import AgreementsHOC from "./actions/index";
import VersionCheckHOC from "actions/versionCheck";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Grey" },
  { value: "Suspended", colorName: "Red" },
  { value: "Deleted", colorName: "Red" },
  { value: "Resigned", colorName: "Grey" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Terminated", colorName: "Red" },
  { value: "Waiting Approval", colorName: "Yellow" },
];

const columnData = [
  { header: "Agreement Title", accessor: "title", searchFlag: "title" },
  {
    header: "Type",
    searchFlag: "type_id",
    renderColumn: ({ type_id }) =>
      _.find(AgreementType, { id: `${type_id}` })
        ? _.find(AgreementType, { id: `${type_id}` }).name
        : "N/A",
  },
  { header: "Version", accessor: "version", searchFlag: "version" },
  { header: "Publish Date", accessor: "publish_on", searchFlag: "publish_on" },
  {
    header: "Status",
    searchFlag: "status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

class Agreement extends Component {
  componentDidMount = () => this.props.getAgreements(1, "");

  render = () => {
    const {
      agreements,
      searchParams,
      getSelectedAgreement,
      onChangeAgreementHOC,
      getAgreements,
      agreementPages,
      onLoadAgreement,
    } = this.props;

    const { can_create, can_update } = permissionsChecker(
      "Agreements",
      this.props.data
    );

    return (
      <>
        <ModuleHeader
          title={`Agreements`}
          moduleIcon={"icon-agreement"}
          actionButton={[
            {
              label: "New Agreement",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeAgreementHOC(
                  true,
                  "showCreateAgreementModal"
                ),
            },
          ]}
        />
        <AtlasCard
          cardContent={
            <AtPaginatedTable
              rowData={agreements.data || []}
              meta={agreements.meta}
              columns={columnData}
              enableSort={true}
              actionColumnContent={[
                {
                  name: can_update ? "edit" : "view",
                  onClick: (rowData) => getSelectedAgreement(rowData.id),
                },
              ]}
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeAgreementHOC(val, "searchParams")
              }
              getListAPI={getAgreements}
              totalPages={agreementPages}
            />
          }
        />
        {this.props.showCreateAgreementModal && (
          <CreateAgreement
            countries={this.props.data.dictionaryReducer.countries}
            onLoadAgreement={this.props.onLoadAgreement}
            onClickSubmit={this.props.createAgreement}
            onCloseModal={() =>
              this.props.onChangeAgreementHOC(false, "showCreateAgreementModal")
            }
          />
        )}
        {this.props.showEditAgreementModal && (
          <EditAgreement
            showPreviewModal={this.props.showPreviewModal}
            previewData={this.props.previewData}
            selectedAgreement={this.props.selectedAgreement}
            previewAgreement={this.props.previewAgreement}
            onLoadAgreement={this.props.onLoadAgreement}
            onClickSubmit={this.props.updateAgreement}
            onCloseModal={() =>
              this.props.onChangeAgreementHOC(false, "showEditAgreementModal")
            }
            onChangeAgreementHOC={this.props.onChangeAgreementHOC}
          />
        )}
        {onLoadAgreement && <LoadingModal />}
      </>
    );
  };
}

export default compose(AgreementsHOC, VersionCheckHOC)(Agreement);
