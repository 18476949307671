import React from "react";
import _ from "lodash";

import { getColorBadge } from "dictionary/badgeColor";

export const columnData = [
  {
    header: "Renewal Year",
    accessor: "renewal_year",
  },
  {
    header: "Cut-off Date",
    accessor: "last_submit_date",
  },
  {
    header: "License Type",
    accessor: "licence_renewal_type",
  },
  {
    header: "Status",
    renderColumn: (rowData) => {
      const { colorName } = _.find(statusColor, { value: rowData.status });
      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

export const settingColumnData = [
  {
    header: "Renewal Year",
    accessor: "renewal_year",
  },
  {
    header: "Minimum CPD Requirement",
    accessor: "cpd_minimum_requirement",
  },
  {
    header: "Age Range (From)",
    accessor: "from_age",
  },
  {
    header: "Age Range (To)",
    accessor: "to_age",
  },
];

export const statusOptions = [
  { value: 1, label: "Open" },
  { value: 2, label: "Closed" },
];

export const typeOptions = [
  { value: 1, label: "REA" },
  { value: 2, label: "PEA" },
  { value: 3, label: "VE" },
];

export const REAFormData = [
  {
    label: "Renewal Year",
    type: "date",
    format: "YYYY",
    value: "renewal_year",
    showYearPicker: true,
  },
  {
    label: "Cut-off Date",
    type: "date",
    format: "DD-MM-YYYY",
    value: "last_submit_date",
    showYearPicker: false,
  },
  {
    label: "Status",
    type: "select",
    value: "status_id",
    options: statusOptions,
  },
  {
    label: "License Type",
    type: "select",
    value: "type_id",
    options: typeOptions,
  },
];

export const settingFormData = [
  {
    label: "Renewal Year",
    type: "date",
    format: "YYYY",
    value: "renewal_year",
    showYearPicker: true,
  },
  {
    label: "Minimum CPD Requirement",
    type: "number",
    value: "cpd_minimum_requirement",
  },
  {
    label: "Age Range (From)",
    type: "number",
    value: "from_age",
  },
  {
    label: "Age Range (To)",
    type: "number",
    value: "to_age",
  },
  {
    label: "Remarks",
    type: "textarea",
    value: "description",
  },
];

export const statusColor = [
  { value: "Open", colorName: "Green" },
  { value: "Closed", colorName: "Red" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Processing", colorName: "Blue" },
  { value: "Submit to Finance", colorName: "Violet" },
  { value: "Payment Made", colorName: "Cyan" },
  { value: "Board Approved", colorName: "Pink" },
  { value: "Tag Arrived", colorName: "Teal" },
  { value: "Tag Collected", colorName: "Green" },
  { value: undefined, colorName: "Grey" },
];
