import React, { Component } from "react";
import Moment from "moment";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomFormHeader from "components/Form/header";
import CustomSearch from "./search";

import SubmittedSubsalesHOC from "./actions";
import { numberWithCommas } from "utils/thousandSeparator";
import { initGA } from "utils/ga";
import "./index.scss";

const subsalesOptions = ["All", "Sale", "Rental"];

const columnData = [
  {
    header: "Type",
    accessor: "type",
    searchFlag: "type",
    columnStyle: { width: "50%" },
  },
  { header: "Form No.", accessor: "form_no", searchFlag: "form_no" },
  {
    header: "Submitted Date",
    searchFlag: "submission_date",
    renderColumn: ({ submission_date }) =>
      submission_date
        ? Moment(submission_date, "YYYY-MM-DD").format("DD MMM YYYY")
        : "N/A",
  },
  { header: "Agent", accessor: "agent", searchFlag: "agent" },
  {
    header: "Agency Comm.",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
    searchFlag: "agency_comm",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.agency_comm.toFixed(2))}</p>
    ),
  },
];

class SubsalesSubmittedSales extends Component {
  componentDidMount = () => {
    initGA("/dashboard/reports/subsales-booked-tracker");
    this.props.getSubsalesSubmittedSales();
  };

  onSelectStartDate = (val) =>
    this.props.onChangeSubmittedSubsalesHOC(
      val
        ? Moment(val).format("YYYY-MM-DD")
        : Moment()
            .year(new Date().getFullYear())
            .month(0)
            .date(1)
            .format("YYYY-MM-DD"),
      "startDate",
    );

  onSelectEndDate = (val) =>
    this.props.onChangeSubmittedSubsalesHOC(
      val
        ? Moment(val).format("YYYY-MM-DD")
        : Moment(new Date()).format("YYYY-MM-DD"),
      "endDate",
    );

  onClickSubmit = () =>
    this.props.getSubsalesSubmittedSales(
      this.props.startDate,
      this.props.endDate,
    );

  renderAgentSearchTips = () => {
    return (
      <>
        <span className="fw-500">How to manage report results</span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          Filter your report by selecting the date range and then, click Search.
          The date is based on the unit booking date.
          <br />
          You can also sort the report based on the market categories by
          clicking on the Sale or Rental only button.
          <br />
          To do a specific search on the report, simply select the category and
          enter the details that you are looking for.
        </p>
      </>
    );
  };

  renderTabFilter = () => (
    <div className="d-block w-100">
      <CustomFormHeader
        className={"at-submitted-sales-report__form-header"}
        title={
          <div className="d-flex grid_gap-2 align-items-center">
            Pending Commission
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => this.props.exportSubsalesSubmittedSales()}
            >
              <AtlasIcon
                svgHref={"atlas-xlsx-file"}
                style={{ width: 20, height: 20 }}
              />
              Export
            </button>
          </div>
        }
        moduleIcon={"atlas-money"}
      />
      <CustomTab
        sections={subsalesOptions}
        containerClass={"at-submitted-sales-report__form-tab"}
        selectedSection={this.props.subsalesType}
        onSelectSection={(val) =>
          Promise.all([
            this.props.onChangeSubmittedSubsalesHOC(val, "subsalesType"),
          ]).then(() => {
            this.props.onChangeSubsalesType();
          })
        }
      />
    </div>
  );

  renderCardContent = () => {
    const { submittedSubsales = [] } = this.props;
    return (
      <>
        <CustomSearch
          selectedEndDate={this.props.endDate}
          selectedStartDate={this.props.startDate}
          onSelectStartDate={(val) => this.onSelectStartDate(val)}
          onSelectEndDate={(val) => this.onSelectEndDate(val)}
          enableExport={submittedSubsales.length > 0}
          onClickReset={() => this.props.getSubsalesSubmittedSales()}
          onClickSubmit={() =>
            this.props.getSubsalesSubmittedSales(
              this.props.startDate,
              this.props.endDate,
            )
          }
        />
        <div className="at-form__content at-submitted-sales-report__group-sales">
          <div
            className="at-card--stat__general-icon-cont"
            style={{ backgroundColor: "#F79009" }}
          >
            <AtlasIcon svgHref={`atlas-home`} />
          </div>
          <div className="at-card--stat__general-category mb-0">
            <p>Total Agency Commission</p>
            <span>{this.props.totalAgencyComm}</span>
          </div>
        </div>
        <div className="at-card-container">
          <CustomTable
            className={"team-sales__table"}
            selectPerPageOption={12}
            columns={columnData}
            pagination={true}
            onChangeHOC={this.props.onChangeSubmittedSubsalesHOC}
            rowData={this.props.submittedSubsales || []}
            renderTabFilter={this.renderTabFilter}
          />
        </div>
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Subsales Booked Tracker"}
          atlasIcon={"atlas-cup"}
          showPABadge={role === "Personal Assistant"}
          description={`Track your group's pending Secondary Market sales here. The report below shows your group's pending commission claim units.`}
          infoBoxContent={this.renderAgentSearchTips}
          infoBoxInactiveContent={"How to manage report results"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadSubmittedSubsales && <LoadingModal />}
      </>
    );
  };
}

export default SubmittedSubsalesHOC(SubsalesSubmittedSales);
