import React, { Component } from "react";

import TreeViewChild from "./child";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/treeview/agent.scss";

class TreeView extends Component {
  renderContent = (contentData, layer, onClickPersonal, onClickTeam) => {
    const { data } = this.props;
    return (
      <ul className="tree-parent" style={{ paddingLeft: layer === 0 ? 0 : "" }}>
        {contentData.map((item) => {
          return (
            <TreeViewChild
              filter={this.props.filter}
              firstElementID={data && data.length > 0 ? data[0].agentID : null}
              refs={this.props.refs}
              toggleExpand={this.props.toggleExpand}
              currentSearchId={this.props.currentSearchId}
              searchResult={this.props.searchResult}
              key={item.mobileNumber}
              item={item}
              onClickPersonal={(val) => onClickPersonal(val)}
              onClickTeam={(val) => onClickTeam(val)}
              childContent={
                item.children.length > 0
                  ? this.renderContent(
                      item.children,
                      item.children[0].tier,
                      onClickPersonal,
                      onClickTeam,
                    )
                  : ""
              }
            />
          );
        })}
      </ul>
    );
  };

  render = () => {
    const { data, onClickPersonal, onClickTeam } = this.props;
    if (data.length > 0) {
      return this.renderContent(
        data,
        data[0].tier,
        onClickPersonal,
        onClickTeam,
      );
    } else {
      return (
        <EmptyState
          title={`No data is available`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    }
  };
}

export default TreeView;
