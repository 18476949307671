import { useEffect, useState } from "react";
import { compose } from "redux";
import Moment from "moment";
import { MenuItem } from "@material-ui/core";

import CustomButton from "components/Button";
import ProjectCard from "components/Card/projectCard";
import CustomCheckbox from "components/Checkbox";
import CustomFormInput from "components/Input/formInput";
import AtlasLoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtSelectNew from "components/Select/new";
import AtlasTabs from "components/Tab";
import CustomTypeahead from "components/Typeahead/new";
import CustomUpload from "components/Upload";

import withTownships from "./actions/townships";
import withSubareas from "./actions/subareas";

import {
  addressFields,
  descriptionTabs,
  detailsFields,
  mediaFields,
  optionFields,
  priceFields,
  specificationFields,
} from "./assets";

import permissionsChecker from "utils/permissionsChecker";

const DetailsForm = ({
  selectedProject,
  data,
  type,
  townships,
  subareas,
  onLoadCreate,
  onLoadEdit,
  onLoadProjects,
  onLoadTownships,
  onLoadSubareas,
  onChange: handleChange,
  onClose,
  getTownshipsByStateId,
  getSubareasByTownshipName,
  submitAction,
}) => {
  const [activeDescriptionTab, setActiveDescriptonTab] =
    useState("Description");
  const [canEdit, setCanEdit] = useState(false);
  const [states, setStates] = useState([]);

  const { country_id, state_id, township } = selectedProject;
  const { countries, currencies } = data.dictionaryReducer;
  const { can_create, can_update } = permissionsChecker("Project Centre", data);

  useEffect(() => {
    setCanEdit(type === "update" ? can_update : can_create);
  }, []);

  useEffect(() => {
    if (country_id) {
      setStates(countries.find((country) => country.id === country_id)?.states);
    }
  }, [country_id]);

  useEffect(() => {
    if (state_id) {
      getTownshipsByStateId(state_id);
    }
  }, [state_id]);

  useEffect(() => {
    if (township) {
      getSubareasByTownshipName(township);
    }
  }, [township]);

  const handleSubmit = () => {
    submitAction({
      ...selectedProject,
      completion_date: Moment(
        selectedProject.completion_date,
        "YYYY-MM-DD",
      ).format("DD MMM YYYY"),
    });
  };

  const renderInputType = (item) => {
    switch (item.type) {
      case "radio":
        return (
          <AtlasRadioGroup
            horizontal={true}
            containerClassName={"w-100"}
            options={item.options}
            checkedValue={selectedProject[item.key]}
            selectedRadioValue={(val) => handleChange(item.key, val)}
            disabled={!canEdit}
          />
        );
      case "select":
        return (
          <AtSelectNew
            value={selectedProject[item.key]}
            onChange={(e) => handleChange(item.key, e.target.value)}
            disabled={!canEdit}
            required={item.required}
          >
            {item.options?.map((option) => (
              <MenuItem key={option.id} value={option[item.valueKey || "id"]}>
                {option[item.labelKey || "label"]}
              </MenuItem>
            ))}
          </AtSelectNew>
        );
      case "typeahead":
        const checkName = (option) => selectedProject[item.key] === option.name;

        return (
          <CustomTypeahead
            typeaheadId={item.key}
            containerClassName={"m-0"}
            isLoading={item.isLoading}
            options={item.options || []}
            labelKey={"name"}
            filterBy={["name"]}
            selectedValue={
              item.options?.some(checkName)
                ? [item.options?.find(checkName)]
                : []
            }
            onSelect={(val) =>
              handleChange(item.key, val?.length > 0 ? val[0]?.name : "")
            }
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            disabled={!canEdit}
          />
        );
      default:
        return (
          <CustomFormInput
            containerClass={"mb-0"}
            type={item.type}
            placeholder={item.placeholder}
            value={selectedProject[item.key]}
            onChangeValue={(val) =>
              handleChange(
                item.key,
                item.type === "date" ? Moment(val).format("YYYY-MM-DD") : val,
              )
            }
            required={item.required}
            disabled={!canEdit}
          />
        );
    }
  };

  const renderInputFields = (fields) => (
    <div className={"form-row gy-3"}>
      {fields
        .filter((item) => !item.hidden)
        .map((item) => (
          <div
            key={item.label}
            className={`col-md-${item.span === 2 ? "12" : "6"}`}
          >
            <label className={"at-projects__input-label"}>{item.label}</label>
            {renderInputType(item)}
          </div>
        ))}
    </div>
  );

  const renderDetailsSection = () => {
    return (
      <ProjectCard icon={"building-4"} title={"Details"}>
        {renderInputFields(detailsFields(type))}
      </ProjectCard>
    );
  };

  const renderSpecificationSection = () => {
    return (
      <ProjectCard icon={"home"} title={"Specification"}>
        {renderInputFields(specificationFields)}
      </ProjectCard>
    );
  };

  const renderAddressSection = () => {
    return (
      <ProjectCard icon={"location"} title={"Address"}>
        {renderInputFields(
          addressFields(
            countries,
            states,
            townships,
            onLoadTownships,
            subareas,
            onLoadSubareas,
          ),
        )}
      </ProjectCard>
    );
  };

  const renderDescriptionSection = () => {
    return (
      <ProjectCard icon={"message-text-1"} title={"Description"}>
        <AtlasTabs
          containerClass={"at-projects__details__description-tabs"}
          sections={descriptionTabs.map((tab) => tab.label)}
          selectedSection={activeDescriptionTab}
          onSelectSection={setActiveDescriptonTab}
        />
        {descriptionTabs.map(
          (tab) =>
            activeDescriptionTab === tab.label && (
              <CustomFormInput
                key={tab.value}
                containerClass={"mb-0"}
                type={"textarea"}
                placeholder={tab.label}
                value={selectedProject[tab.value] || ""}
                onChangeValue={(val) => handleChange(tab.value, val)}
                disabled={!canEdit}
              />
            ),
        )}
      </ProjectCard>
    );
  };

  const renderPriceSection = () => {
    return (
      <ProjectCard icon={"dollar-circle"} title={"Price"}>
        {renderInputFields(
          priceFields(type === "update" && can_update, currencies),
        )}
      </ProjectCard>
    );
  };

  const renderOptionsSection = () => {
    return (
      <ProjectCard icon={"star-1"} title={"Options"}>
        {optionFields.map((item, index) => (
          <div
            key={item.key}
            className={index !== optionFields.length - 1 ? "mb-2" : ""}
          >
            <CustomCheckbox
              contentStyle={{ fontWeight: 500 }}
              checked={selectedProject[item.key]}
              content={item.label}
              onChangeCheckboxValue={(e) =>
                handleChange(item.key, e.target.checked)
              }
            />
          </div>
        ))}
      </ProjectCard>
    );
  };

  const renderMediaSection = () => {
    return (
      <ProjectCard icon={"gallery"} title={"Media"}>
        <div className={"form-row gy-3"}>
          {mediaFields.map((item) => (
            <div key={item.key} className={"col-sm-6 col-lg-4"}>
              <label
                className={"at-projects__input-label"}
                style={{ fontSize: 16 }}
              >
                {item.label}
              </label>
              <CustomUpload
                containerClass={"w-100 overflow-hidden"}
                containerStyle={{ height: 230 }}
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  height: "100%",
                }}
                imageUrl={selectedProject[item.key]}
                uploadImage={(val) => handleChange(item.key, val)}
                disabled={!canEdit}
              />
            </div>
          ))}
        </div>
      </ProjectCard>
    );
  };

  return (
    <div>
      {renderDetailsSection()}
      {renderSpecificationSection()}
      {renderAddressSection()}
      {renderDescriptionSection()}
      {renderPriceSection()}
      {renderOptionsSection()}
      {renderMediaSection()}
      {type === "create" && (
        <div className={"d-flex"}>
          <CustomButton
            className={"btn-new btn-new--primary"}
            onClick={handleSubmit}
          >
            Create
          </CustomButton>
          <CustomButton
            className={"btn-new btn-new--outline-secondary ml-2"}
            onClick={onClose}
          >
            Close
          </CustomButton>
        </div>
      )}
      {type === "update" && can_update && (
        <div className={"d-flex"}>
          <CustomButton
            className={"btn-new btn-new--success"}
            onClick={handleSubmit}
          >
            Update Details
          </CustomButton>
          <CustomButton
            className={"btn-new btn-new--outline-secondary ml-2"}
            onClick={onClose}
          >
            Close
          </CustomButton>
        </div>
      )}
      {(onLoadEdit || onLoadCreate || onLoadProjects) && <AtlasLoadingModal />}
    </div>
  );
};

export default compose(withTownships, withSubareas)(DetailsForm);
