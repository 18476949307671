import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";
import FileSaver from "file-saver";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showAppointmentLetterModal: false,
      showAppointmentEmailModal: false,

      selectedAppointmentLetter: {
        signed_appointment_datetime: null,
        lastTrainingDate: null,
        letterDate: null,
        offerDate: null,
        signed_date: null,
      },

      appointmentPreview: null,
      isAppointmentSigned: false,
      hideOfferDate: false,
      hideBranch: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeAppointmentLetterHOC = (val, context) =>
      this.setState({ [context]: val });

    downloadDocWithoutData = (id) =>
      Post(
        `/admin/users/${id}/download`,
        {},
        this.downloadDocWithoutDataSuccess,
        this.downloadDocWithoutDataError,
        this.load,
        { responseType: "blob" },
      );
    downloadDocWithoutDataSuccess = (payload) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ appointmentPreview: e.target.result });
      };
      reader.readAsDataURL(payload);
    };

    downloadDocWithoutDataError = (error) => requestError(error);

    sendEmailWithoutData = (dataToSubmit) =>
      Post(
        `/admin/users/${dataToSubmit.id}/deliver`,
        dataToSubmit,
        this.sendEmailWithDateSuccess,
        this.sendEmailWithDateError,
        this.load,
      );
    sendEmailWithDateSuccess = () => {
      requestSuccess("Email has been sent successfully.");
      this.setState({ showAppointmentEmailModal: false });
    };
    sendEmailWithDateError = (error) => requestError(error);

    downloadDocWithDate = ({
      signed_appointment_datetime,
      lastTrainingDate,
      ...dataToSubmit
    }) => {
      // choose either signed_appointment_datetime or lastTrainingDate
      if (signed_appointment_datetime !== "N/A" || lastTrainingDate !== "N/A") {
        Post(
          `/admin/users/${dataToSubmit.id}/download_with_dates`,
          {
            offer_date: dataToSubmit.offerDate,
            letter_date: dataToSubmit.letterDate,
            signed_date: dataToSubmit.signed_date,
            branch_id: dataToSubmit.branch_id,
          },
          this.downloadDocWithDateSuccess,
          this.downloadDocWithDateFailed,
          this.load,
          { responseType: "blob" },
        );
      } else {
        requestError("Please choose a date before downloading the docs.");
      }
    };
    downloadDocWithDateSuccess = async (payload) => {
      var blob = await new Blob([payload], { type: "application/pdf" });
      FileSaver.saveAs(blob, "NDA-Appointment");
    };
    downloadDocWithDateFailed = (error) => requestError(error);

    sendEmailWithDate = ({
      signed_appointment_datetime,
      lastTrainingDate,
      ...dataToSubmit
    }) => {
      // choose either signed_appointment_datetime or lastTrainingDate
      if (
        dataToSubmit.recipients &&
        (signed_appointment_datetime !== "N/A" || lastTrainingDate !== "N/A")
      ) {
        Post(
          `/admin/users/${dataToSubmit.id}/deliver_with_dates`,
          {
            id: dataToSubmit.id,
            offer_date: dataToSubmit.offerDate,
            letter_date: dataToSubmit.letterDate,
            signed_date: dataToSubmit.signed_date,
            recipients: dataToSubmit.recipients,
          },
          this.sendEmailWithDateSuccess,
          this.sendEmailWithDateError,
          this.load,
        );
      } else {
        requestError(
          "Please fill in a valid email and choose a date to send email.",
        );
      }
    };
    sendEmailWithDateSuccess = (payload) => requestSuccess(payload.message);
    sendEmailWithDateError = (error) => requestError(error);

    getAppointmentDate = (id) =>
      Get(
        `/admin/users/${id}/appointment_date`,
        this.getAppointmentDateSuccess,
        this.getAppointmentDateError,
        this.load,
      );
    getAppointmentDateSuccess = ({
      signed_appointment_datetime,
      last_training_datetime,
      letter_date,
      offer_date,
      signed_date,
    }) => {
      this.setState({
        selectedAppointmentLetter: {
          signed_appointment_datetime: signed_appointment_datetime
            ? Moment(signed_appointment_datetime).format("DD MMM YYYY")
            : "N/A",
          lastTrainingDate: last_training_datetime
            ? Moment(last_training_datetime).format("DD MMM YYYY")
            : "N/A",
          letterDate: letter_date
            ? Moment(letter_date).format("DD MMM YYYY")
            : "N/A",
          offerDate: offer_date
            ? Moment(offer_date).format("DD MMM YYYY")
            : "N/A",
          signed_date: signed_date
            ? Moment(signed_date).format("DD MMM YYYY")
            : "N/A",
        },
      });
    };
    getAppointmentDateError = (error) => requestError(error);

    checkDigitalSign = (id) => {
      this.checkDigitalSignSuccess(id, false);
      Get(
        `/admin/users/appointment_nda_checker?user_id=${id}`,
        this.checkDigitalSignSuccess,
        this.checkDigitalSignError,
        this.load,
      );
    };
    checkDigitalSignSuccess = (payload) =>
      this.setState({
        isAppointmentSigned: payload.is_signed,
        hideOfferDate: payload.hide_offer_date,
        hideBranch: payload.hide_branch,
      });
    checkDigitalSignError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showAppointmentEmailModal={this.state.showAppointmentEmailModal}
            showAppointmentLetterModal={this.state.showAppointmentLetterModal}
            isAppointmentSigned={this.state.isAppointmentSigned}
            hideOfferDate={this.state.hideOfferDate}
            hideBranch={this.state.hideBranch}
            onLoadAppointmentLetter={this.state.loading}
            appointmentPreview={this.state.appointmentPreview}
            selectedAppointmentLetter={this.state.selectedAppointmentLetter}
            downloadDocWithoutData={this.downloadDocWithoutData}
            sendEmailWithoutData={this.sendEmailWithoutData}
            sendEmailWithDate={this.sendEmailWithDate}
            checkDigitalSign={this.checkDigitalSign}
            downloadDocWithDate={this.downloadDocWithDate}
            getAppointmentDate={this.getAppointmentDate}
            onChangeAppointmentLetterHOC={this.onChangeAppointmentLetterHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
