import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CustomMultiInput from "components/Input/multi";

import isEmptyValue from "utils/isEmpty";

const VaraintOptions = ({
  onLoad,
  product_options,

  onClose,
  updateVariantOptions,
}) => {
  const [variantOptions, setVariantOptions] = useState([
    {
      name: "",
      product_option_values_attributes: [],
    },
  ]);

  useEffect(() => {
    if (product_options && product_options.length > 0) {
      setVariantOptions(product_options);
    }
  }, []);

  const onChangeVariantOptions = (val, index) => {
    let temp = _.cloneDeep(variantOptions);
    temp[index]["name"] = val;
    setVariantOptions(temp);
  };

  const onChangeOptionsAttribute = (val, index) => {
    let temp = _.cloneDeep(variantOptions);
    temp[index]["product_option_values_attributes"] = val;
    setVariantOptions(temp);
  };

  const onChangeRemove = (parentIndex, index) => {
    let temp = _.cloneDeep(variantOptions);
    if (parentIndex && index) {
      temp[parentIndex][index]["_destroy"] = true;
    } else {
      temp[parentIndex]["_destroy"] = true;
      temp[parentIndex].product_option_values_attributes = [];
    }
    setVariantOptions(temp);
  };

  const addNewVariantOptions = () => {
    let temp = _.cloneDeep(variantOptions);
    temp.push({
      name: "",
      product_option_values_attributes: [],
    });
    setVariantOptions(temp);
  };

  return (
    <ModalDialog
      title={"Update Variant Options"}
      onLoad={onLoad}
      onClose={onClose}
      children={
        <>
          {variantOptions &&
            variantOptions.map((item, index) => (
              <div key={`product-variant-option-${index}`}>
                <label className={"mb-5"}>
                  <strong>Option {index + 1}</strong>
                </label>
                <div className="d-flex justify-content-between">
                  <CustomFormInput
                    type="text"
                    value={item.name}
                    containerStyle={{ height: "fit-content" }}
                    onChangeValue={(val) => onChangeVariantOptions(val, index)}
                  />
                  <CustomMultiInput
                    type="text"
                    valueKey={"name"}
                    style={{ width: "100%" }}
                    containerStyle={{ width: "66%", minWidth: 200 }}
                    valueList={item.product_option_values_attributes || []}
                    onChangeValue={(val) =>
                      onChangeOptionsAttribute(val, index)
                    }
                  />
                </div>
              </div>
            ))}
          <hr />
          <div className="d-flex">
            <CustomButton
              className={"btn-new btn-new--outline-secondary mr-2"}
              children={"Add another option"}
              onClick={() => addNewVariantOptions()}
            />
            <CustomButton
              disabled={_.values(variantOptions).some((x) => !x || x === "")}
              className={"btn-new btn-new--secondary"}
              children={"Update variant list"}
              onClick={() => updateVariantOptions(variantOptions)}
            />
          </div>
        </>
      }
    />
  );
};

export default VaraintOptions;
