import React, { Component } from "react";
import { FormControl, TextField, Card } from "@material-ui/core";
import _ from "lodash";
import { compose } from "redux";

import Tabs from "components/Tab";
import CustomCard from "components/Card";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import NotificationsTab from "./components/notifications";

import SettingsHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";

import "./index.scss";

class Settings extends Component {
  componentDidMount = () => {
    this.props.getSetting();
    initGA("/dashboard/settings");
  };

  renderSettingsCardContent = () => {
    switch (this.props.tabContent) {
      case "Notifications":
        return <NotificationsTab {...this.props} />;
      default:
        return null;
    }
  };

  renderCardContent = () => {
    const { notify_of_new_announcement } = this.props.current_setting;
    return (
      <>
        <Tabs
          sections={["Notifications"]}
          selectedSection={this.props.tabContent}
          onSelectSection={(val) =>
            this.props.onChangeSettingsHOC(val, "tabContent")
          }
        />
        {this.renderSettingsCardContent()}
        <button
          className="btn-new btn-new--success mt-3"
          onClick={() =>
            this.props.updateSetting({
              notify_of_new_announcement: notify_of_new_announcement,
            })
          }
        >
          Save Settings
        </button>
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={"Settings"} atlasIcon={"atlas-setting-2"} />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadSetting && <LoadingModal />}
      </>
    );
  };
}

export default compose(SettingsHOC, VersionCheckHOC)(Settings);
