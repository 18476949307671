import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";

import "./index.scss";

const ProgressBar = ({
  mode,
  className,
  progress,
  stepList,
  tooltip,
  onClick,
  disabled,
  lineStyle,
  hideLabel,
  enableProgress,
  containerStyle,
  enableProgressive,
  renderStepIcon,
  renderStepLabel,
}) => {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    if (enableProgress) {
      const percentPerStep = 100 / (stepList.length - 1);
      const progressIndex = stepList.indexOf(progress);
      let progressBarWidth =
        progress === stepList[stepList.length]
          ? 100
          : percentPerStep * progressIndex;
      setProgressWidth(progressBarWidth);
    }
  }, [progress]);

  return (
    <div
      className={`${enableProgressive ? "at-step-idenitfier__progressive" : "d-flex justify-content-around"}  ${className || ""}`}
      style={{ position: "relative", ...containerStyle }}
    >
      {stepList.map((step, index) => {
        if (typeof step === "string") {
          let highlight =
            mode === "gradual" ? step <= progress : step === progress;

          return (
            <div
              key={index}
              className={"at-step-idenitfier__progress-button"}
              style={{
                ...(highlight && {
                  color: "#3B82F6",
                }),
              }}
            >
              <CustomButton
                className={`at-step-idenitfier__progress-number ${highlight ? "at-step-identifier__selected" : ""}`}
                disabled={disabled}
                onClick={() => onClick(step)}
                tooltip={tooltip}
                tooltipID={`Step-Indicator-${index}`}
                tooltipChildren={step}
                tooltipPosition={"top"}
                children={renderStepIcon ? renderStepIcon(step) : index + 1}
              />
              {!hideLabel && (
                <p style={{ minWidth: 50 }}>
                  {renderStepLabel ? renderStepLabel(step) : step}
                </p>
              )}
            </div>
          );
        } else {
          return (
            <div className={"at-step-idenitfier__progress-button"}>
              <span className="at-step-idenitfier__progress-number">
                {step.icon || `${index}`}
              </span>
              <p style={{ minWidth: 50 }}>{step.label}</p>
            </div>
          );
        }
      })}
      <div
        style={{ ...lineStyle }}
        className="at-step-idenitfier__progress-div"
      ></div>
      {enableProgress && (
        <div className="at-step-idenitfier__progress-div">
          <div
            style={{ width: `${progressWidth}%` }}
            className="at-step-idenitfier__progress-bar"
          ></div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
