import { PiBathtubFill, PiBedFill } from 'react-icons/pi';
import { RiRuler2Line } from 'react-icons/ri';

const PillsTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    marginRight: 4
  };

  return (
    <div
      style={{
        color: '#fff',
        marginLeft: configuration?.propertyDetailsPosition === 'left' ? -8 : 0,
        marginRight: configuration?.propertyDetailsPosition === 'left' ? 0 : -8
      }}
    >
      <div
        style={{
          padding:
            configuration?.propertyDetailsPosition === 'left'
              ? '8px 16px 8px 8px'
              : '8px 8px 8px 16px',
          backgroundColor: '#ea580c',
          borderRadius:
            configuration?.propertyDetailsPosition === 'left'
              ? '0 99px 99px 0'
              : '99px 0 0 99px',
          width: 'max-content',
          marginLeft:
            configuration?.propertyDetailsPosition === 'left' ? 0 : 'auto'
        }}
      >
        <p style={{ fontSize: 14, fontWeight: 700 }}>{unitType}</p>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 16,
          minWidth: '31.5%',
          width: 'max-content',
          padding:
            configuration?.propertyDetailsPosition === 'left'
              ? '8px 20px 8px 8px'
              : '8px 8px 8px 20px',
          backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`,
          borderRadius:
            configuration?.propertyDetailsPosition === 'left'
              ? '0 99px 99px 0'
              : '99px 0 0 99px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <p style={detailStyle}>
            <RiRuler2Line style={iconStyle} />
            <span>
              {property.min_built_up?.toLocaleString()} -{' '}
              {property.max_built_up?.toLocaleString()} Sqft
            </span>
          </p>
          <p style={detailStyle}>
            <PiBedFill style={iconStyle} />
            <span>{property.bedroom_display || 0} bedrooms</span>
          </p>
          <p style={detailStyle}>
            <PiBathtubFill style={iconStyle} />
            <span>{property.bathroom_display || 0} bathrooms</span>
          </p>
        </div>
        <div style={{ width: 1, height: 'auto', backgroundColor: '#fff' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {property.project_features?.slice(0, 3)?.map(feature => (
            <p key={feature.name} style={{ fontSize: 12, fontWeight: 600 }}>
              {feature.name?.trim()}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PillsTemplate;
