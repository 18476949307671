import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { storeLastView } from "actions/lastView";
import { Get, GetFile } from "utils/axios";
import { toQueryString  } from "utils/toQueryString";
import { requestError, requestSuccess } from "utils/requestHandler";
import { searchParams, startDate, endDate } from "./assets";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      rowData: {
        Personal: [],
        Company: [],
      },
      seletedData: {},
      searchParams: searchParams,
      selectedForm: "Confirmation Forms",
      type: "Personal",
      showDialog: false,
      showSearchContent: false,
      downloadParams: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeCddHOC = (val, context) => this.setState({ [context]: val });

    module_type = (type) => (type  === "Confirmation Forms") ? "ConfirmationForm" : "SubSaleClaim"

    resetCddReport = (type) => {
      const params = {
        start_date: startDate,
        end_date: endDate,
        module_type: this.module_type(type),
        to_download: false
      };
      Get(
        `/admin/analytics/cdd_report?${toQueryString(params)}`,
        this.resetCddReportSuccess,
        this.getCddReportError,
        this.load,
      )
    }
    resetCddReportSuccess = (payload) => {
      let tempSearch = _.cloneDeep(this.state.searchParams)
      let start_date = _.find(tempSearch, { value: "start_date" });
      let end_date = _.find(tempSearch, { value: "end_date" });
      let rowData = { Personal:[], Company:[] }
      let { data, ...downloadParams } = payload
      start_date.param = startDate
      end_date.param = endDate
      downloadParams.to_download = true
      rowData.Personal = payload.data.personal
      rowData.Company = payload.data.company
      this.setState({
        rowData: rowData,
        searchParams: tempSearch, 
        downloadParams: downloadParams 
      })
    }

    getCddReport = (type) => {
      const start_date = _.find(this.state.searchParams, { value: "start_date" });
      const end_date = _.find(this.state.searchParams, { value: "end_date" });
      const email = _.find(this.state.searchParams, { value: "email" });
      const params = {
        start_date: start_date.param,
        end_date: end_date.param,
        module_type: this.module_type(type),
        to_download: false
      };
      if (email.param) {
        params.email = email.param
      }
      Get(
        `/admin/analytics/cdd_report?${toQueryString(params)}`,
        this.getCddReportSuccess,
        this.getCddReportError,
        this.load,
      )
    }
    getCddReportSuccess = (payload) => {
      let tempSearch = _.cloneDeep(this.state.searchParams)
      let rowData = { Personal:[], Company:[] }
      let { data, ...downloadParams } = payload

      downloadParams.to_download = true
      rowData.Personal = payload.data.personal
      rowData.Company = payload.data.company

      this.setState({
        rowData: rowData,
        searchParams: tempSearch, 
        downloadParams: downloadParams 
      })
    };
    getCddReportError = (error) => requestError(error);

    downloadCddReport = () => {
      let params = _.cloneDeep(this.state.downloadParams)
      let paramsData = Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
      GetFile(
        `/admin/analytics/cdd_report?${paramsData}`,
        `CDD Report`,
        this.downloadCddReportSuccess,
        this.downloadCddReportError,
        this.load,
      )
    }
    downloadCddReportSuccess  = () => requestSuccess("Download successfully.");
    downloadCddReportError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getCddReport={this.getCddReport}
          resetCddReport={this.resetCddReport}
          downloadCddReport={this.downloadCddReport}
          onChangeCddHOC={this.onChangeCddHOC}
        />
      );
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
}

export default HOC;