import React from "react";
import ReactHtmlParser from "react-html-parser";

import AtlasIcon from "components/Icon/atlasIcon";
import Heading from "../../components/Heading";

const dataToRender = [
  {
    title: "Description",
    source: "background_image_url",
    description: "description",
    icon: "atlas-home",
  },
  {
    title: "Overview",
    source: "overview_image_url",
    description: "overview_description",
    icon: "atlas-document-text",
  },
  {
    title: "Project Features",
    source: "project_features_image_url",
    description: "features_description",
    icon: "atlas-flag",
  },
  {
    title: "Route Map",
    source: "map_image_url",
    description: "location_description",
    icon: "atlas-map",
  },
  {
    title: "Location Map",
    source: "hd_map_image_url",
    description: "hd_map_description",
    icon: "atlas-location",
  },
  {
    title: "Landing Page",
    source: "landing_page",
  },
];

const ProjectImages = ({ selectedProject }) => {
  const renderImageHeader = (desc, title, icon) => (
    <div className="col-lg-6 col-md-12 col-sm-12 at-image-title">
      <div className={`heading-title`}>
        <div className="at-badge-icon" style={{ backgroundColor: "#CBBFA0" }}>
          <AtlasIcon
            svgHref={icon}
            style={{ width: 16, height: 16, fill: "white" }}
          />
        </div>
        <h3 className="text-uppercase">{title}</h3>
      </div>
      <p className="text-black">
        {selectedProject[desc] ? ReactHtmlParser(selectedProject[desc]) : "N/A"}
      </p>
    </div>
  );

  const renderLandingPage = () => (
    <div className="at-image-title">
      <div className={`heading-title`}>
        <div className="at-badge-icon" style={{ backgroundColor: "#CBBFA0" }}>
          <AtlasIcon
            svgHref={"atlas-link-2-linear"}
            style={{ width: 16, height: 16, fill: "white" }}
          />
        </div>
        <h3 className="text-uppercase">Landing Page</h3>
      </div>
      {selectedProject.is_visible_on_web && (
        <div
          className="at-project__details-link"
          onClick={() => window.open(selectedProject.landing_page, "_blank")}
        >
          <AtlasIcon
            svgHref={`atlas-link-2-linear`}
            style={{ width: 16, height: 16, marginRight: 4, fill: "#2563EB" }}
          />
          {selectedProject.landing_page}
        </div>
      )}
      {!selectedProject.is_visible_on_web && (
        <span className="text-dark">
          The landing page is not available as this project cannot be publicly
          marked yet due to restrictions by the developer or regulation.
        </span>
      )}
    </div>
  );

  return (
    <div className={`project-image-block`}>
      {dataToRender.map((item, index) => (
        <div
          key={`ProjectDetailsPhoto-${index}`}
          className="container at-container"
        >
          <div
            className="row project-image-content"
            style={{ backgroundColor: index % 2 === 1 ? "#F3F4F6" : "#EFEDEB" }}
          >
            {item.source === "landing_page" && renderLandingPage()}
            {item.source !== "landing_page" && (
              <>
                {index % 2 === 1 &&
                  renderImageHeader(item.description, item.title, item.icon)}
                <div
                  className="col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0, minHeight: 300 }}
                >
                  {selectedProject[item.source] && (
                    <img
                      className="img-fluid"
                      src={selectedProject[item.source]}
                      alt={`ProjectDetailsPhoto-${index}`}
                    />
                  )}
                </div>
                {index % 2 !== 1 &&
                  renderImageHeader(item.description, item.title, item.icon)}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectImages;
