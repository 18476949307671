import React, { useState } from "react";
import { DialogContent, DialogActions } from "@material-ui/core";

import CustomCheckbox from "components/Checkbox";
import ElectronicTNCContent from "assets/electronic-tnc";

const NDAContent = ({ onClickClose, onClickAccept }) => {
  const [checkedTNC, onToggleTNC] = useState(false);
  return (
    <>
      <DialogContent>
        <ElectronicTNCContent />
      </DialogContent>
      <DialogActions className={"at-NDA-appointment-action"}>
        <CustomCheckbox
          content="I agree to use electronic records & signature"
          checked={checkedTNC}
          onChangeCheckboxValue={() => onToggleTNC(!checkedTNC, "checkedTNC")}
        />
        <div className="d-flex">
          <button
            disabled={!checkedTNC}
            className={"btn-new btn-new--success"}
            onClick={() => onClickAccept()}
          >
            Accept
          </button>
          <button
            className={"btn-new btn-new--danger ml-2"}
            onClick={() => onClickClose()}
          >
            Decline
          </button>
        </div>
      </DialogActions>
    </>
  );
};

export default NDAContent;
