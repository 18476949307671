import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Delete, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";
import { branches_my as branches } from "dictionary";

const ParkLicenseHOC = (WrappedComponent) => {
  class ParkLicenseWrappedComponent extends Component {
    state = {
      searchParams: [
        {
          label: "Name",
          value: "agent_registration_user_full_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Email",
          value: "reg_no",
          type: "input",
          param: "",
        },
        {
          label: "Mobile Number",
          value: "mobile_no",
          type: "input",
          param: "",
        },
        {
          label: "Team",
          value: "team",
          type: "input",
          param: "",
        },
        {
          label: "Branch",
          value: "branch",
          type: "select",
          param: "",
          options: branches,
        },
        {
          label: "Status",
          value: "status",
          type: "radio",
          param: "",
          options: [
            { value: "1", label: "Active" },
            { value: "0", label: "Inactive" },
          ],
        },
      ],

      loading: false,
      branches: [],
      licenseAgents: [],
      attachLicense: [],
      licenseRenewal: [],
      selectedParkLicense: {},

      showCreateModal: false,
      showTransferModal: false,
      showAgentListModal: false,
      showDeleteConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeParkLicenseHOC = (val, context) =>
      this.setState({ [context]: val });

    getBranchAttachLicense = (id) =>
      Get(
        `/admin/branches/${id}/licence_attach_branches`,
        this.getBranchAttachLicenseSuccess,
        this.getBranchAttachLicenseError,
        this.load,
      );
    getBranchAttachLicenseSuccess = (payload) =>
      this.setState({ attachLicense: payload });
    getBranchAttachLicenseError = (error) => requestError(error);

    getAllBranch = () =>
      Get(
        `/admin/branches/all`,
        this.getAllBranchSuccess,
        this.getAllBranchError,
        this.load,
      );
    getAllBranchSuccess = (payload) => this.setState({ branches: payload });
    getAllBranchError = (error) => requestError(error);

    viewLicenseAgent = (branch_id, license_id) =>
      Get(
        `/admin/branches/${branch_id}/licence_attach_branches/${license_id}`,
        this.viewLicenseAgentSuccess,
        this.viewLicenseAgentError,
        this.load,
      );
    viewLicenseAgentSuccess = (payload) => {
      let tempData = [];
      payload.licence_attach_branch_users.map((item) => {
        tempData.push({ ...item, ...item.user_info });
      });
      this.setState({
        licenseAgents: {
          ...payload,
          licence_attach_branch_users: tempData,
        },
        showAgentListModal: true,
      });
    };
    viewLicenseAgentError = (error) => requestError(error);

    createLicense = (dataToSubmit) =>
      Post(
        `/admin/branches/${dataToSubmit.branch_id}/licence_attach_branches`,
        {
          licence_number: dataToSubmit.licence_number,
        },
        this.createLicenseSuccess,
        this.createLicenseError,
        this.load,
      );
    createLicenseSuccess = (payload) => {
      requestSuccess("License renewal attached to branch");
      this.getBranchAttachLicense(payload.branch_id);
      this.setState({ showCreateModal: false });
    };
    createLicenseError = (error) => requestError(error);

    transferParkLicense = (branch_id, license_id, transfer_id) =>
      Put(
        `/admin/branches/${branch_id}/licence_attach_branches/${license_id}/transfer`,
        { new_branch_id: transfer_id },
        this.transferParkLicenseSuccess,
        this.transferParkLicenseError,
        this.load,
      );
    transferParkLicenseSuccess = () => {
      this.setState({ showTransferModal: false });
      this.getBranchAttachLicense(this.props.selectedBranch.id);
      requestSuccess("Park License transfer successful");
    };
    transferParkLicenseError = (error) => requestError(error);

    deleteParkLicense = (branch_id, license_id) =>
      Delete(
        `/admin/branches/${branch_id}/licence_attach_branches/${license_id}`,
        this.deleteParkLicenseSuccss,
        this.deleteParkLicenseError,
        this.load,
      );
    deleteParkLicenseSuccss = (payload) => {
      requestSuccess(payload.message);
      this.getBranchAttachLicense(this.props.selectedBranch.id);
      this.setState({ showDeleteConfirmation: false });
    };
    deleteParkLicenseError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadParkLicense={this.state.loading}
            getAllBranch={this.getAllBranch}
            createLicense={this.createLicense}
            viewLicenseAgent={this.viewLicenseAgent}
            deleteParkLicense={this.deleteParkLicense}
            transferParkLicense={this.transferParkLicense}
            getBranchAttachLicense={this.getBranchAttachLicense}
            onChangeParkLicenseHOC={this.onChangeParkLicenseHOC}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {})(ParkLicenseWrappedComponent);
};

export default ParkLicenseHOC;
