import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Delete } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showElectronicTNCModal: false,

      showDrawingBoard: false,
      showDeleteConfirmation: false,
      selectedDeleteId: null,
      signedDigitalSign: null,

      checkedTNC: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeDigitalSignHOC = (val, context) =>
      this.setState({
        [context]: val,
      });

    getDigitalSign = () =>
      Get(
        `/digital_signatures`,
        this.getDigitalSignSuccess,
        this.getDigitalSignError,
        this.load,
      );
    getDigitalSignSuccess = (payload) =>
      this.setState({ signedDigitalSign: payload });
    getDigitalSignError = (error) => requestError(error);

    createDigitalSign = (dataToSubmit) =>
      Post(
        `/digital_signatures`,
        {
          signature: dataToSubmit,
          signature_file_name: "iqi_digital_sign.png",
        },
        this.createDigitalSignSuccess,
        this.createDigitalSignError,
        this.load,
      );
    createDigitalSignSuccess = () => {
      this.getDigitalSign();
      requestSuccess("Digital signature have been created successfully.");
      this.setState({ showDrawingBoard: false });
    };
    createDigitalSignError = (error) => requestError(error);

    deleteDigitalSign = (id) =>
      Delete(
        `/digital_signatures/${id}`,
        this.deleteDigitalSignSuccess,
        this.deleteDigitalSignError,
        this.load,
      );
    deleteDigitalSignSuccess = (payload) => {
      this.getDigitalSign();
      requestSuccess("Digital signature has been deleted successfully.");
      this.setState({ showDeleteConfirmation: false });
    };
    deleteDigitalSignError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            checkedTNC={this.state.checkedTNC}
            onLoadDigitalSign={this.state.loading}
            selectedDeleteId={this.state.selectedDeleteId}
            showElectronicTNCModal={this.state.showElectronicTNCModal}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showDrawingBoard={this.state.showDrawingBoard}
            signedDigitalSign={this.state.signedDigitalSign}
            getDigitalSign={this.getDigitalSign}
            createDigitalSign={this.createDigitalSign}
            deleteDigitalSign={this.deleteDigitalSign}
            onChangeDigitalSignHOC={this.onChangeDigitalSignHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
