import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      reassignSubmissions: {},

      showDeleteConfirmation: false,
      showEditSampleModal: false,
      showCreateSampleModal: false,

      samples: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },
      selectedSample: null,
      samplePages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeSampleHOC = (val, context) => this.setState({ [context]: val });

    getSamples = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/samples?${search}page=${page}`,
        this.getSamplesSuccess,
        this.getSamplesError,
        this.load,
      );
    };
    getSamplesSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        samples: payload,
        samplePages: tempPages,
      });
    };
    getSamplesError = (error) => requestError(error);

    getSelectedSample = (id) =>
      Get(
        `/samples/${id}`,
        this.getSelectedSampleSuccess,
        this.getSelectedSampleError,
        this.load,
      );
    getSelectedSampleSuccess = (payload) =>
      this.setState({
        selectedSample: payload,
        showEditSampleModal: true,
      });
    getSelectedSampleError = (error) => requestError(error);

    createSample = (dataToSubmit) =>
      Post(
        `/samples`,
        dataToSubmit,
        this.createSampleSuccess,
        this.createSampleError,
        this.load,
      );
    createSampleSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSamples(currentPage, searchParams);
      this.setState({ showCreateSampleModal: false });

      requestSuccess(`Sample has been created successfully`);
    };
    createSampleError = (error) => requestError(error);

    updateSample = (dataToSubmit) =>
      Put(
        `/samples/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateSampleSuccess,
        this.updateSampleError,
        this.load,
      );
    updateSampleSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSamples(currentPage, searchParams);
      this.setState({ showEditSampleModal: false });

      requestSuccess(`Sample has been updated successfully`);
    };
    updateSampleError = (error) => requestError(error);

    deleteSample = (id) =>
      Delete(
        `/samples/${id}`,
        this.deleteSampleSuccess,
        this.deleteSampleError,
        this.load,
      );
    deleteSampleSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSamples(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });

      requestSuccess(`Sample has been deleteted successfully.`);
    };
    deleteSampleError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          samplePages={this.state.samplePages}
          samples={this.state.samples}
          searchParams={this.state.searchParams}
          selectedSample={this.state.selectedSample}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          showCreateSampleModal={this.state.showCreateSampleModal}
          showEditSampleModal={this.state.showEditSampleModal}
          onLoadSample={this.state.loading}
          onChangeSampleHOC={this.onChangeSampleHOC}
          getSamples={this.getSamples}
          getSelectedSample={this.getSelectedSample}
          createSample={this.createSample}
          updateSample={this.updateSample}
          deleteSample={this.deleteSample}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
