import React, { Component } from "react";

import CustomButton from "components/Button";
import SVGIcon from "components/Icon";

const AppThemeImage = ({
  tooltipID,
  onClickRemoveImage,
  imgSrc,
  tooltipChildren,
}) => {
  return (
    <div className="project-detail__edit-image-item">
      <CustomButton
        containerStyle={{ position: "absolute", right: 10 }}
        className={"flex-center at-btn--danger at-btn--icon circle"}
        children={
          <SVGIcon
            svgHref={"icon-cross"}
            className={"icon-size-1 icon-white svg-icon-cross"}
          />
        }
        tooltip={true}
        tooltipPosition={"top"}
        tooltipID={tooltipID}
        tooltipChildren={tooltipChildren}
        onClick={onClickRemoveImage}
      />
      <img className="m-2" src={imgSrc} alt={tooltipID} />
    </div>
  );
};

export default AppThemeImage;
