import { Component } from 'react';
import { connect } from 'react-redux';

import { getCountryDictionary } from 'actions/dictionary';
import { storeLastView } from 'actions/lastView';

import { Delete, Get, Post, Put } from 'utils/axios';
import { requestError, requestSuccess } from 'utils/requestHandler';

import { adminSearchParams, searchParams, videoConfiguration } from '../assets';

const withVideoCreator = WrappedComponent => {
  class VideoCreatorHOC extends Component {
    state = {
      onLoadVideoCreator: false,

      selectedVideo: {},
      selectedVideoTemplate: {},
      selectedProject: {},

      adminView: false,

      searchParams: searchParams(
        this.props?.data?.dictionaryReducer?.countries
      ),
      adminSearchParams: adminSearchParams(
        this.props?.data?.dictionaryReducer?.countries
      ),
      videoTemplates: {},
      videoTemplatePages: [],
      personalisedVideos: {},
      personalisedVideoPages: [],
      youtubeLink: null,

      showVideoPlayer: false,
      showCreateModal: false,
      showEditVideoSettings: false,
      showShareModal: false,
      showDeleteConfirmation: false
    };

    load = param => this.setState({ onLoadVideoCreator: param });

    onChangeVideoCreatorHOC = (key, val) => this.setState({ [key]: val });

    getAdminPersonalisedVideos = (page = 1, search = '') => {
      this.props.storeLastView({
        currentPage: page,
        searchParams: search
      });

      Get(
        `/admin/video_requests?${search}page=${page}`,
        this.getAdminPersonalisedVideosSuccess,
        this.getAdminPersonalisedVideosError,
        this.load
      );
    };
    getAdminPersonalisedVideosSuccess = payload => {
      const pages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        personalisedVideos: payload,
        personalisedVideoPages: pages
      });
    };
    getAdminPersonalisedVideosError = error => requestError(error);

    deleteAdminPersonalisedVideo = id =>
      Delete(
        `/admin/video_requests/${id}`,
        this.deleteAdminPersonalisedVideoSuccess,
        this.deleteAdminPersonalisedVideoError,
        this.load
      );
    deleteAdminPersonalisedVideoSuccess = payload => {
      requestSuccess(payload.message);
      const lastView = this.props.data.lastViewReducer.lastView;
      this.getAdminPersonalisedVideos(
        lastView.currentPage,
        lastView.searchParams
      );
    };
    deleteAdminPersonalisedVideoError = error => requestError(error);

    getPersonalisedVideos = (page = 1, search = '') => {
      this.props.storeLastView({
        currentPage: page,
        searchParams: search
      });

      Get(
        `/video_requests?${search}page=${page}`,
        this.getPersonalisedVideosSuccess,
        this.getPersonalisedVideosError,
        this.load
      );
    };
    getPersonalisedVideosSuccess = payload => {
      const pages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        pages.push(i);
      }
      this.setState({
        personalisedVideos: payload,
        personalisedVideoPages: pages
      });
    };
    getPersonalisedVideosError = error => requestError(error);

    deletePersonalisedVideo = id =>
      Delete(
        `/video_requests/${id}`,
        this.deletePersonalisedVideoSuccess,
        this.deletePersonalisedVideoError,
        this.load
      );
    deletePersonalisedVideoSuccess = payload => {
      requestSuccess(payload.message);
      const lastView = this.props.data.lastViewReducer.lastView;
      this.getPersonalisedVideos(lastView.currentPage, lastView.searchParams);
    };
    deletePersonalisedVideoError = error => requestError(error);

    getVideoTemplates = (page = 1, search = '') =>
      Get(
        `/video_templates?${search}page=${page}&per=12`,
        this.getVideoTemplatesSuccess,
        this.getVideoTemplatesError,
        this.load
      );
    getVideoTemplatesSuccess = payload => {
      const pages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        videoTemplates: payload,
        videoTemplatePages: pages
      });
    };
    getVideoTemplatesError = error => requestError(error);

    getSelectedProject = videoTemplate => {
      Get(
        `/projects/${videoTemplate.project_id}`,
        payload => this.getSelectedProjectSuccess(payload, videoTemplate),
        this.getSelectedProjectError,
        this.load
      );
    };
    getSelectedProjectSuccess = (payload, videoTemplate) => {
      this.setState({
        selectedProject: payload,
        selectedVideoTemplate: {
          ...videoTemplate,
          configuration: videoConfiguration(videoTemplate.type_id)
        },
        showEditVideoSettings: true
      });
    };
    getSelectedProjectError = error => requestError(error);

    createPersonalisedVideo = data => {
      Post(
        `/video_requests`,
        data,
        this.createPersonalisedVideoSuccess,
        this.createPersonalisedVideoError,
        this.load
      );
    };
    createPersonalisedVideoSuccess = () => {
      const lastView = this.props.data.lastViewReducer.lastView;
      this.getPersonalisedVideos(lastView.currentPage, lastView.searchParams);
      requestSuccess(
        'Video request was successfully created. This process may take several minute to process. Please check the status of the video request periodically.'
      );
      this.setState({
        showEditVideoSettings: false,
        showCreateModal: false
      });
    };
    createPersonalisedVideoError = error => requestError(error);

    updateDownloadCount = (id) => {
      Put(
        `/video_requests/${id}/download_completed`,
        {},
        () => {},
        requestError,
        this.load
      );
    };

    downloadVideo = async (selectedVideo, adminView) => {
      this.load(true);
      
      try {
        // Create blob url from video url
        const res = await fetch(selectedVideo.url);
        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedVideo.video_template?.title}.mp4`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

        // Update download count
        if (!adminView) {
          this.updateDownloadCount(selectedVideo.id);
        }
      } catch (error) {
        requestError(error);
      } finally {
        this.load(false);
      }
    };

    getYoutubeLink = id =>
      Get(
        `/youtube_sessions/new?video_id=${id}`,
        this.getYoutubeLinkSuccess,
        this.getYoutubeLinkError,
        this.load
      );
    getYoutubeLinkSuccess = res => this.setState({ youtubeLink: res.redirect_url });
    getYoutubeLinkError = error => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeVideoCreatorHOC={this.onChangeVideoCreatorHOC}
          getAdminPersonalisedVideos={this.getAdminPersonalisedVideos}
          getPersonalisedVideos={this.getPersonalisedVideos}
          getVideoTemplates={this.getVideoTemplates}
          getSelectedProject={this.getSelectedProject}
          getYoutubeLink={this.getYoutubeLink}
          createPersonalisedVideo={this.createPersonalisedVideo}
          updateDownloadCount={this.updateDownloadCount}
          downloadVideo={this.downloadVideo}
          deleteAdminPersonalisedVideo={this.deleteAdminPersonalisedVideo}
          deletePersonalisedVideo={this.deletePersonalisedVideo}
        />
      );
    };
  }
  const mapStateToProps = state => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary
  })(VideoCreatorHOC);
};

export default withVideoCreator;
