import React from "react";
import { Switch, Route } from "react-router-dom";

import LeaderTeamSales from "./LeaderTeamSales";
import GroupProjectSales from "./GroupProjectSales";
import SubsalesSubmittedSales from "./SubsalesSubmittedSales";
import CampaignTrackers from "./CampaignTrackers";

const Reports = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/dashboard/reports/group-project-sales"}
        component={GroupProjectSales}
      />
      <Route
        exact
        path={"/dashboard/reports/subsales-booked-tracker"}
        component={SubsalesSubmittedSales}
      />
      <Route
        exact
        path={"/dashboard/reports/leader-team-sales-ranking"}
        component={LeaderTeamSales}
      />
      <Route
        exact
        path={"/dashboard/reports/campaign-trackers"}
        component={CampaignTrackers}
      />
      <Route
        exact
        path={
          "/admin-impersonate/:profileId/dashboard/reports/group-project-sales"
        }
        component={GroupProjectSales}
      />
      <Route
        exact
        path={
          "/admin-impersonate/:profileId/dashboard/reports/subsales-booked-tracker"
        }
        component={SubsalesSubmittedSales}
      />
      <Route
        exact
        path={
          "/admin-impersonate/:profileId/dashboard/reports/leader-team-sales-ranking"
        }
        component={LeaderTeamSales}
      />
      <Route
        exact
        path={
          "/admin-impersonate/:profileId/dashboard/reports/campaign-trackers"
        }
        component={CampaignTrackers}
      />
    </Switch>
  );
};

export default Reports;
