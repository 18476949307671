import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { Card } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import { ToastContainer } from "react-toastify";

import SubsalesClaim from "./SubsalesClaim"
import FormDialog from "./SubsalesClaim/formDialog"
import { formTypes } from "./SubsalesClaim/assets/form"
import ConfirmationModal from "components/Modal/confirmation"

import JuwaiIqiLogo from "assets/images/juwai_iqi_logo.jpg";
import OppositeParticipantHOC from "./actions/oppositeParticipant";
import NationalityHOC from "actions/nationality";
import AccessCaseFileHOC from "./actions";
import BankHOC from "actions/banks";
import "./index.scss"

// Contact_type_id
// 1 => Owner/Landlord
// 2 => Purchaser/Tenant
// 3 => Owner/Landlord Lawyer
// 4 => Purchaser/Tenant Lawyer

// Participants id
// 1 => Owner/Landlord
// 2 => Purchaser/Tenant
// 3 => Internal Agent
// 4 => Referral

const ContactPartyTypes = [
  {
    id: 1,
    lawFirmId: 3,
    contactTypes: ["Owner", "Vendor", "Landlord"]
  },
  {
    id: 2,
    lawFirmId: 4,
    contactTypes: ["Purchaser", "Buyer", "Tenant"]
  }
]

class AccessCaseFile extends Component {
  state = {
    newTypeId: "",
    paticipantTypeId: ""
  };

  onChangeAccessCaseFile = (value, key) => this.setState({ [key]: value });

  componentDidMount = () => {
    const tmpPath = `${window.location.origin}/#/sub_sale_claims/request/`
    const encodedLink = window.location.href.substring(tmpPath.length, window.location.href.length)
    if(encodedLink) {
      this.props.onChangeOppositeParticipantHOC(encodedLink, "subsale_claim_id")
      this.props.onChangeOppositeParticipantHOC(true, "showPromptPassword")
    } else {
      this.props.onChangeAccessCaseFileHOC(true, "showInvalidLinkModal")
    }
    this.props.getNationalities();
  };

  renderEditParticpant = () => {
    if(this.props.showEditParticipant) {
      return (
        <FormDialog 
          {...this.props} 
          subsalesClaim={this.props.subsalesClaim}
          loading={this.props.onLoadParticipant}
          selectedContact={this.props.selectedParticipant}
          title={"Edit"}
          titleContext={["3", "4"].indexOf(this.props.selectedParticipant.contact_type_id) === -1
            ? this.props.subsalesClaim?.opposite_representative_party 
            : "Law Firm"
          }
          formTypes={["3", "4"].indexOf(this.props.selectedParticipant.contact_type_id) === -1 ? formTypes : [] }

          banks={this.props.banks}
          storedAddresses={this.props.data.addressReducer.address}
          nationalities={this.props.nationalities}
          onChangeOppositeParticipantHOC={this.props.onChangeOppositeParticipantHOC}
          onToggleDialog={() =>
            this.props.onChangeOppositeParticipantHOC(false, "showEditParticipant")
          }
          submitData={(data) => {
            this.props.updateOppositeParty(data)
          }}/>
      )
    }
  }
  
  renderCreateParticpant = () => {
    if(this.props.showCreateParticipant) {
      return (
        <FormDialog 
          {...this.props} 
          subsalesClaim={this.props.subsalesClaim}
          loading={this.props.onLoadParticipant}
          title={"Add"}
          titleContext={ ["3", "4"].indexOf(this.state.newTypeId) === -1 
            ? this.props.subsalesClaim.opposite_representative_party 
            : "Law Firm"
          }
          formTypes={["3", "4"].indexOf(this.state.newTypeId) === -1 ? formTypes : []}

          banks={this.props.banks}
          storedAddresses={this.props.data.addressReducer.address}
          nationalities={this.props.nationalities}
          onChangeOppositeParticipantHOC={this.props.onChangeOppositeParticipantHOC}
          onToggleDialog={() =>
            this.props.onChangeOppositeParticipantHOC(false, "showCreateParticipant")
          }
          submitData={(data) =>
            this.props.createOppositeParty({
              ...data,
              contact_type_id: this.state.newTypeId,
              type_id: ["1","3"].indexOf(this.state.newTypeId) > -1 ? 1 : 2
            })
          }/>
      )
    }
  }

  render = () => {
    return (
      <>
        <Card
          style={{
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            padding: 32,
            backgroundColor: "#F3F4F6",
          }}>
          <div 
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}>
            <img alt="IQI Juwai Logo" style={{width: 300, height: 160, objectFit: "contain", mixBlendMode: "multiply"}} src={ JuwaiIqiLogo }/>
            {
              this.props.showParticipantDetails && (
                <SubsalesClaim 
                  {...this.props}
                  ContactPartyTypes={ContactPartyTypes}
                  onChangeAccessCaseFile={this.onChangeAccessCaseFile}/>
              )
            }
          </div>
        </Card>
        { this.renderEditParticpant() }
        { this.renderCreateParticpant() }
        {this.props.onLoadParticipant && (
          <LoadingModal />
        )}
        <ConfirmationModal
          mode={"alert"}
          open={this.props.showInvalidLinkModal}
          loading={this.props.onLoadParticipant}
          message={"The page you are looking for doesn’t exist. You may have mistyped the address, or the page has been removed."}
          title={"Invalid Link"}
          modalClass="at-access_case_file-password_modal"
          hideAction={true}
        />
        <ConfirmationModal
          autoFocus={true}
          mode={ this.props.requestStatus === "error" ? "alert" : "lock" }
          message={ "You need a password to access the case file" }
          inputError={ this.props.statusMessage }
          open={this.props.showPromptPassword}
          loading={this.props.onLoadParticipant}
          inputType={"text"}
          onKeyPress={ val => {
            if(val) {
              this.props.getInfoDetailFromOppositeParty(this.props.subsale_claim_id, val) 
            } else {
              Promise.all([
                this.props.onChangeAccessCaseFileHOC("error", "requestStatus")
              ]).then(() => {
                this.props.onChangeAccessCaseFileHOC("Enter Password", "statusMessage")
              })
            }
          }}
          title={"Password required"}
          modalClass="at-access_case_file-password_modal"
          fieldLabel={"Enter Password"}
          positiveText={"Submit"}
          positiveAction={ val => {
            this.props.getInfoDetailFromOppositeParty(this.props.subsale_claim_id, val)
          }}
          isSensitiveInfo={true}/>
        <ConfirmationModal
          mode={ "info" }
          message={ "Thank you for updating, we will notify the respective agent. This action cannot be reversed; once you update, it cannot be edited later. Please confirm that the typed information is correct." }
          open={this.props.showUpdateConfirmation}
          loading={this.props.onLoadParticipant}
          inputType={"text"}
          title={"Update Confirmation"}
          positiveText={"Confirm"}
          positiveAction={ val => this.props.completeUpdate() }
          negativeText={"Cancel"}
          negativeAction={ () => {
            this.props.onChangeOppositeParticipantHOC(false, "showUpdateConfirmation")
          }}
          isSensitiveInfo={true}/>
        <ToastContainer autoClose={10000} style={{ minWidth: 350, zIndex: 9999 }} />
      </>
    );
  };
}

export default compose(
  BankHOC,
  NationalityHOC,
  AccessCaseFileHOC,
  OppositeParticipantHOC,
)(AccessCaseFile);
