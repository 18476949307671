export default function objToFormData(obj, prefix = null) {
  const data = new FormData();

  Object.keys(obj).forEach((keyName) => {
    if (obj[keyName] !== undefined) {
      data.append(prefix ? `${prefix}[${keyName}]` : keyName, obj[keyName]);
    }
  });

  return data;
}
