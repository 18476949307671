import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'

import Tabs from 'components/Tab';
import ModalDialog from 'components/Modal/ModalDialog';
import ConfirmationModal from 'components/Modal/delete'
import CreateTicketPricingModal from './createTicketPricing';
import UpdateTicketPricingModal from './updateTicketPricing';
import AttachJointTicketModal from './attachJointTicket';
import CreateTicketTypeModal from './createTicketType';
import UpdateTicketTypeModal from './updateTicketType';
import AttendeeList from '../components/attendeeList'
import TicketManagement from './ticketManagement'
import EventDetailsContent from './eventsDetails'
import ZoomSessionContent from './zoomSession'
import AttachmentContent from './attachments'
import EventTrainingForm from './form'
import TncModal from './tncModal';
import QRCode from './qrCode';

import EditHOC from "../actions/editEvent";

const tabOptions = [
  "Update Event",
  "Event Details",
  "Attachments",
  "Zoom Meeting",
  "Attendees",
];

const EditDialogContent = ({
  data,
  eventQr,
  toBeDeletedId,
  selectedTicketPricing,
  availableJointTickets,
  tncText,
  attachments,
  ticketTypeList,
  priceManagementList,
  jointTicketsList,
  showCreateTicketType,
  showUpdateTicketType,
  showCreateTicketPricing,
  showUpdateTicketPricing,
  showAttachTicketPricing,
  showDeleteAttachedTicketConfirmation,
  showDeletePricingTicketConfirmation,
  showDeleteTicketTypeConfirmation,
  showTncPreview,
  showTncEditor,
  selectedTicketTypeDetail,
  onChangeFieldEdit,
  onChangeEditEventHOC,
  onLoadEventTraining,
  onChangeEventHOC,
  branchRegions,
  onChangeFileEdit,
  onLoadExportUnitAdmin,
  selectedEvent,
  updateEvent,
  disapproveClick,
  onLoadEditEvent,
  getEventQrCode,
  getSelectedEvent,
  getAttachments,
  getTicketTypeDetail,
  createTicketType,
  updateTicketType,
  deleteSelectedTicketType,
  createTicketPricing,
  updateTicketPricing,
  deleteTicketPricing,
  getAvailableJointTickets,
  attachJointTicket,
  deletedAttachedJointTicket,
}) => {
  const [tabSections, onChangeTabSections] = useState(tabOptions);
  const [selectedSection, onChangeSection] = useState("Update Event");

  useEffect(() => {
    let temp = _.cloneDeep(tabOptions);
    if (selectedEvent.is_eligible_for_ims) {
      temp.push("QR Code");
    }
    if (selectedEvent.is_payment_needed) {
      temp.splice(1, 0, "Ticket Pricing");
    }
    onChangeTabSections(temp);
  }, [selectedEvent]);

  const onToggleSubmit = () => {
    let temp = _.cloneDeep(selectedEvent);
    temp.cut_off_date = temp.cut_off_date
      ? Moment(temp.cut_off_date).format("YYYY-MM-DD")
      : "";
    updateEvent(temp);
  };

  return (
    <>
      <ModalDialog
        title={ 'Edit Event' }
        onLoad={ onLoadEventTraining || onLoadEditEvent }
        fullHeight={ true }
        fullWidth={ true }
        onClose={ () => onChangeEventHOC( false, 'showEditEventDialog' ) }
        children={
          <>
            <Tabs
              sections={ tabSections }
              selectedSection={ selectedSection }
              onSelectSection={ val => onChangeSection( val ) }/>
            { selectedSection === "Update Event" && (
              <EventTrainingForm
                mode={ 'edit' }
                data={ data }

                submitEventForm={ () => onToggleSubmit() }
                type_id={ selectedEvent.type_id }
                branchRegions={ branchRegions }
                onChangeFile={ onChangeFileEdit }
                onChangeField={ onChangeFieldEdit }
                getSelectedEvent={ getSelectedEvent }
                onLoadExportUnitAdmin={ onLoadExportUnitAdmin }
                onChangeEditEventHOC={ onChangeEditEventHOC }
                onClose={ () => onChangeEventHOC( false, 'showEditEventDialog' ) }
                eventDetails={ selectedEvent }/>
              )
            }
            {
              selectedSection === 'Event Details' && (
                <EventDetailsContent
                  branchRegions={ branchRegions }
                  selectedEvent={ selectedEvent }
                  disapproveClick={ disapproveClick }
                  getSelectedEvent={ getSelectedEvent }
                  onChangeEventHOC={ onChangeEventHOC }/>
              )
            }
            { selectedSection === 'Ticket Pricing' && (
                <TicketManagement
                  data={ data }
                  ticketTypeList={ ticketTypeList }

                  getTicketTypeDetail={ getTicketTypeDetail }
                  onChangeEditEventHOC={ onChangeEditEventHOC } />
              )
            }
            {
              selectedSection === 'Attachments' && (
                <AttachmentContent
                  attachments={ attachments }
                  selectedEventId={ selectedEvent.id }
                  getAttachments={ getAttachments }
                />
              )
            }
            {
              selectedSection === "Zoom Meeting" && (
                <ZoomSessionContent 
                  data={ data }
                  zoomTabMode={ 'edit' }
                  selectedEvent={ selectedEvent }
                  getSelectedEvent={ getSelectedEvent }/> 
              ) 
            }
            {
              selectedSection === 'Attendees' && (
                <AttendeeList
                  getSelectedEvent={ getSelectedEvent }
                  selectedEvent={ selectedEvent }/>
              )
            }
            {
              selectedSection === 'QR Code' && (
                <QRCode
                  eventQr={ eventQr }
                  selectedEvent={ selectedEvent }
                  
                  getEventQrCode={ getEventQrCode }
                />
              )
            }
          </>
        } />
      { showCreateTicketType && (
         <CreateTicketTypeModal
          createTicketType={ createTicketType }
          onLoadTicketType={ onLoadEventTraining || onLoadEditEvent }
          onClose={ () => onChangeEditEventHOC( false, 'showCreateTicketType' ) } />
      ) }
      { showUpdateTicketType && (
        <UpdateTicketTypeModal
          data={ data }
          onLoad={ onLoadEventTraining || onLoadEditEvent }
          priceManagementList={ priceManagementList }
          jointTicketsList={ jointTicketsList }
          selectedTicketTypeDetail={ selectedTicketTypeDetail }
          deleteTicketPricing={ deleteTicketPricing }
          updateTicketType={ updateTicketType }
          getAvailableJointTickets={ getAvailableJointTickets }
          deletedAttachedJointTicket={ deletedAttachedJointTicket }
          onChangeEditEventHOC={ onChangeEditEventHOC }
          onClose={ () => onChangeEditEventHOC( false, 'showUpdateTicketType' ) } />
      ) }
      { showCreateTicketPricing && (
        <CreateTicketPricingModal
          data={data}
          onLoad={onLoadEventTraining || onLoadEditEvent}
          createTicketPricing={createTicketPricing}
          onClose={() => onChangeEditEventHOC(false, "showCreateTicketPricing")}
        />
      )}
      {showUpdateTicketPricing && (
        <UpdateTicketPricingModal
          onLoad={ onLoadEventTraining || onLoadEditEvent }
          selectedTicketPricing={ selectedTicketPricing }
          updateTicketPricing={ updateTicketPricing }
          onChange={ onChangeEditEventHOC }
          onClose={ () => onChangeEditEventHOC( false, 'showUpdateTicketPricing' ) } />
        )
      }
      { showTncPreview && (
        <ModalDialog
          title={ 'Terms and Conditions' }
          onLoad={ onLoadEventTraining || onLoadEditEvent }
          responsiveSize={ 'lg' }
          onClose={ () => onChangeEditEventHOC( false, 'showTncPreview' ) }
          children={
            <TncModal
              mode={ 'preview' }
              selectedEvent={ selectedEvent }
              onChange={ () => {} }
              onClose={ () => onChangeEditEventHOC( false, 'showTncPreview' ) } />
          } />
      ) }
      { showTncEditor && (
        <ModalDialog
          title={ 'Edit Terms and Conditions' }
          onLoad={ onLoadEventTraining || onLoadEditEvent }
          responsiveSize={ 'lg' }
          onClose={ () => onChangeEditEventHOC( false, 'showTncEditor' ) }
          children={
            <TncModal
              mode={ 'edit' }
              selectedEvent={ selectedEvent }
              onChange={ onChangeEventHOC }
              onClose={ () => onChangeEditEventHOC( false, 'showTncEditor' ) }
              onSave={ updateEvent }
              onClickPreview={ () => onChangeEditEventHOC( true, 'showTncPreview' ) } />
          } />
      ) }
      <AttachJointTicketModal
        attachJointTicket={attachJointTicket}
        selectedTicketPricing={selectedTicketPricing}
        availableJointTickets={availableJointTickets}
        showAttachTicketPricing={showAttachTicketPricing}
        onLoadEventTraining={onLoadEventTraining}
        onLoadEditEvent={onLoadEditEvent}
        onClose={() => onChangeEditEventHOC(false, "showAttachTicketPricing")}
      />
      <ConfirmationModal
        mode={ 'alert' }
        open={ showDeleteTicketTypeConfirmation }
        title={ 'Delete Ticket' }
        message={ 'Remove Ticket from the type list?' }
        loading={ onLoadEventTraining || onLoadEditEvent }
        positiveAction={ () => deleteSelectedTicketType( toBeDeletedId ) }
        negativeAction={ () => onChangeEditEventHOC( false, 'showDeleteTicketTypeConfirmation' ) }/>
      <ConfirmationModal
        mode={ 'alert' }
        open={ showDeletePricingTicketConfirmation }
        title={ 'Delete Ticket' }
        message={ 'Remove Ticket from the pricing list?' }
        loading={ onLoadEventTraining || onLoadEditEvent }
        positiveAction={ () => deleteTicketPricing( toBeDeletedId ) }
        negativeAction={ () => onChangeEditEventHOC( false, 'showDeletePricingTicketConfirmation' ) }/>
      <ConfirmationModal
        mode={ 'alert' }
        open={ showDeleteAttachedTicketConfirmation }
        title={ 'Delete Ticket' }
        message={ 'Remove Ticket from the joint list?' }
        loading={ onLoadEventTraining || onLoadEditEvent }
        positiveAction={ () => deletedAttachedJointTicket( toBeDeletedId ) }
        negativeAction={ () => onChangeEditEventHOC( false, 'showDeleteAttachedTicketConfirmation' ) }/>
    </>
  );
};

export default compose(EditHOC)(EditDialogContent);
