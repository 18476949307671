import React, { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";

const CaseFileHOC = (WrappedComponent) => {
  class CaseFileWrappedComponent extends Component {
    state = {
      openSnackBar: false,
      statusMessage: "",
      requestStatus: "",
      showInvalidLinkModal: false
    }

    onChangeAccessCaseFileHOC = (value, key) => this.setState({ [key]: value });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          openSnackBar={this.state.openSnackBar}
          statusMessage={this.state.statusMessage}
          requestStatus={this.state.requestStatus}
          showInvalidLinkModal={this.state.showInvalidLinkModal}
          onChangeAccessCaseFileHOC={this.onChangeAccessCaseFileHOC}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(CaseFileWrappedComponent);
}

export default CaseFileHOC;