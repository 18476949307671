import { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";
import CreateProject from "./Create";
import UpdateProject from "./Update";

import ProjectManagementHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { columnData } from "./assets";
import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";

class ProjectsManagement extends Component {
  componentDidMount = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role === "Project Manager") {
      this.props.getAllDictionaryData();
    }
    this.props.getCountryDictionary();
    this.props.getAllProjects(1, "");
    initGA("/dashboard/admin/project-management");
    let tempSearchParams = this.props.searchParams;
    let countryItem = _.find(tempSearchParams, { label: "Country" });
    let countryIndex = tempSearchParams.indexOf(countryItem);
    tempSearchParams[countryIndex].options =
      this.props.data.dictionaryReducer.countries;
    this.props.onChangeProjectManagementHOC(tempSearchParams, "searchParams");
  };

  componentDidUpdate = (pp) => {
    const { countries } = this.props.data.dictionaryReducer;
    let tempSearchParams = this.props.searchParams;
    let countryItem = _.find(tempSearchParams, { label: "Country" });
    let stateItem = _.find(tempSearchParams, { label: "State" });
    let countryIndex = this.props.searchParams.indexOf(countryItem);
    let stateIndex = this.props.searchParams.indexOf(stateItem);

    if (
      pp.searchParams[countryIndex].param !==
        this.props.searchParams[countryIndex].param &&
      this.props.searchParams[countryIndex].param !== ""
    ) {
      let states = _.find(countries, {
        id: this.props.searchParams[countryIndex].param,
      }).states;
      states = _.orderBy(states, ["name"], ["asc"]);
      tempSearchParams[stateIndex].options = states;
      tempSearchParams[stateIndex].param = "";
      this.props.onChangeProjectManagementHOC(tempSearchParams, "searchParams");
    }
  };

  renderNewProjectDialog = () => {
    return (
      <AtlasDialog open={true}>
        <CreateProject
          onLoadProjects={this.props.onLoadProjects}
          getProject={this.props.getProject}
          getAllProjects={this.props.getAllProjects}
          onChangeProjectManagementHOC={this.props.onChangeProjectManagementHOC}
        />
      </AtlasDialog>
    );
  };

  renderEditDetailsDialog = () => (
    <AtlasDialog open={true}>
      <UpdateProject
        getProject={this.props.getProject}
        getAllProjects={this.props.getAllProjects}
        onLoadProjects={this.props.onLoadProjects}
        onChangeProjectManagementHOC={this.props.onChangeProjectManagementHOC}
        selectedProject={this.props.selectedProject}
        updateSelectedProject={this.props.updateSelectedProject}
      />
    </AtlasDialog>
  );

  renderCardContent = () => {
    const { can_update } = permissionsChecker("Projects", this.props.data);

    return (
      <AtPaginatedTable
        rowData={this.props.projects.data || []}
        meta={this.props.projects.meta}
        columns={columnData}
        actionColumnContent={[
          {
            name: can_update ? "edit" : "view",
            onClick: (rowData) => this.props.getProject(rowData.id),
          },
        ]}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeProjectManagementHOC(val, "searchParams")
        }
        getListAPI={this.props.getAllProjects}
        totalPages={this.props.projectManagementPage}
      />
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Projects", this.props.data);

    return (
      <>
        <ModuleHeader
          title={`Projects`}
          atlasIcon={"atlas-building-4"}
          actionButton={[
            {
              label: "New Project",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeProjectManagementHOC(
                  true,
                  "showNewProjectDialog",
                ),
            },
          ]}
        />
        <CustomCard
          className={"at-projects__card"}
          cardContent={this.renderCardContent()}
        />
        {this.props.onLoadProjects && <LoadingModal />}
        {this.props.showNewProjectDialog && this.renderNewProjectDialog()}
        {this.props.showEditDetailsDialog && this.renderEditDetailsDialog()}
      </>
    );
  };
}

export default compose(
  ProjectManagementHOC,
  VersionCheckHOC,
)(ProjectsManagement);
