import React, { useMemo, useEffect } from "react";

import ModuleHeader from "components/ModuleHeader";
import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import AtlasCard from "components/Card";

import AppVersionHOC from "./actions";

export default AppVersionHOC(function AppVersion({
  getVersion,
  updateVersion,
  onChangeVersionHOC,

  app_version,
  onLoadVersion,
}) {
  useEffect(() => {
    getVersion();
  }, []);

  let inputError = useMemo(() => {
    if (app_version.length > 0) {
      return null;
    }
    return "Please do not leave the field empty.";
  }, [app_version]);

  return (
    <>
      <ModuleHeader title={`App Version`} moduleIcon={"icon-app-version"} />
      <AtlasCard
        className={"h-100"}
        cardContent={
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-xs-12">
                <CustomFormInput
                  type="text"
                  value={app_version}
                  onChangeValue={(val) =>
                    onChangeVersionHOC(val, "app_version")
                  }
                  required={true}
                  inputError={inputError}
                  placeholder={"Enter the current app version here."}
                />
              </div>
            </div>
            <div className="mt-20">
              <button
                disabled={inputError}
                className="btn-new btn-new--success"
                onClick={() => updateVersion(app_version)}
              >
                Update
              </button>
            </div>
          </>
        }
      />
      {onLoadVersion && <LoadingModal />}
    </>
  );
});
