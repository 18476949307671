import React from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import { statusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";

export const columnData = [
  {
    header: "Agent",
    renderColumn: (rowData) => (
      <>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          {rowData.user_full_name}
        </p>
        <p>
          <AtlasIcon
            svgHref={"atlas-sms"}
            style={{ width: 14, height: 14, fill: "#EA580C", marginRight: 10 }}
          />
          {rowData.user_email}
        </p>
        <p>
          <AtlasIcon
            svgHref={"atlas-call"}
            style={{ width: 14, height: 14, fill: "#EA580C", marginRight: 10 }}
          />
          {rowData.user_mobile_number}
        </p>
      </>
    ),
  },
  {
    header: "Apply Date",
    accessor: "rea_apply_date",
    columnStyle: { width: "60%" },
  },
  {
    header: "Submitted Hours",
    accessor: "total_cpd_hour",
    columnStyle: { width: "60%" },
  },
  {
    header: "Assigned Admin",
    accessor: "admin_full_name",
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "60%" },
    renderColumn: (rowData) => {
      const { colorName } = _.find(statusColor, {
        value: rowData.applicant_status_name,
      });
      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.applicant_status_name}
        </div>
      );
    },
  },
];

export const applicationColumnData = [
  {
    header: "Event Date",
    accessor: "cpd_event_date",
    searchFlag: "cpd_event_date",
  },
  {
    header: "Event Name",
    accessor: "cpd_event_name",
    searchFlag: "cpd_event_name",
  },
  {
    header: "Organiser",
    accessor: "organiser",
    searchFlag: "organiser",
  },
  {
    header: "Venue",
    accessor: "venue",
    searchFlag: "venue",
  },
  {
    header: "CPD Hour",
    accessor: "cpd_hour",
    searchFlag: "cpd_hour",
  },
];

export const documentType = [
  { label: "Bankruptcy Letter", value: "2", uploaded: false },
  { label: "Receipt", value: "3", uploaded: false },
  { label: "Form K", value: "4", uploaded: false },
  { label: "Licence Tag", value: "5", uploaded: false },
];

export const StatusValue = [
  {
    icon: "atlas-more-2",
    status: "Pending",
    value: "1",
    description: `Waiting for the agent to submit their renewal application. The agent and finance were notified via email to upload the required documents. (Finance – Bankruptcy letter, Agent – Attendance Certificates)`,
  },
  {
    icon: "atlas-document-text",
    status: "Processing",
    value: "2",
    description: `Review and submit the agent's CPD activities to BOVAEA. Wait for their approval before further submitting the renewal application. Once approved, click "Submit to Finance" for payment processing through the BIS system.`,
  },
  {
    icon: "atlas-money-3",
    status: "Payment",
    value: "3",
    description: `Finance admin shall make the payment through BIS and upload the receipt in the Documents section, followed by clicking "Payment Made.`,
  },
  {
    icon: "atlas-bank",
    status: "Final Approval",
    value: "4",
    description: `Pending BOVAEA Final Approval. The admin will verify the agent’s renewal application approval in the BIS system. If approved, click "Board Approved.`,
  },
  {
    icon: "atlas-verify",
    status: "Renewal Approved",
    value: "5",
    description: `BOVAEA has approved the renewal application. Currently waiting for the renewed tag to arrive. Once it arrives at the respective agent’s branch, the admin will click "Tag Arrived" to notify the agent that their tag is ready for pickup.`,
  },
  {
    icon: "atlas-personalcard",
    status: "Arrived",
    value: "6",
    description: `The renewed tag is now at the agent's designated branch, waiting for collection. Once the agent has collected their tag, click "Tag Collected."`,
  },
  {
    icon: "atlas-box-tick",
    status: "Collected",
    value: "7",
    description: `The agent has collected their tag.`,
  },
];
