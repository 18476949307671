import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { getAllDictionaryData } from "actions/dictionary";
import { requestSuccess, requestError } from "utils/requestHandler";
import { Get, Post } from "utils/axios";

export const SearchParams = ({
  teamList,
  branchRegion,
  initialSearchParams,
  setProjectNameModal,
}) => [
  {
    label: "Project Name",
    value: "project_id",
    param: initialSearchParams[0] ? initialSearchParams[0].param : "",
    col: 6,
    renderField: ({ item }) => (
      <div className="at-cobroke__project_name_input">
        <input
          className="at-form-input__input pl-3"
          placeholder={"Please fill in the project's name."}
          value={item.param || ""}
          onClick={() => setProjectNameModal(true)}
          onChange={() => setProjectNameModal(true)}
        />
        <button
          className="at-form-input__button"
          onClick={() => setProjectNameModal(true)}
        >
          ...
        </button>
      </div>
    ),
  },
  {
    label: "Team",
    value: "team_reference_id",
    type: "select",
    param: initialSearchParams[1] ? initialSearchParams[1].param : "",
    col: 3,
    options: teamList || [],
  },
  {
    label: "Location",
    value: "branch_region_reference_id",
    type: "select",
    param: initialSearchParams[2] ? initialSearchParams[2].param : "",
    col: 3,
    options: branchRegion || [],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      branchRegion: [],
      selectedAgent: {},
      projectPartner: [],
      searchParams: [],

      projectList: [],
      projectLoading: false,

      showRequestConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeProjectPartnerHOC = (val, context) =>
      this.setState({ [context]: val });

    getProjectPartner = (page, search) => {
      let tempSearch = "";
      if (!page) {
        tempSearch = search;
      } else {
        if (search) {
          this.state.searchParams.map((item) => {
            tempSearch =
              item.param && item.param !== ""
                ? tempSearch +
                  `q[${item.value}]=${
                    item.value === "project_id"
                      ? _.find(this.state.projectList, { value: item.param })
                        ? _.find(this.state.projectList, { value: item.param })
                            .atlas_id
                        : ""
                      : item.param
                  }&`
                : tempSearch;
          });
        }
      }
      this.props.storeLastView({ searchParams: tempSearch });

      Get(
        `/projects/suggested_partners?${tempSearch}`,
        this.getProjectPartnerSuccess,
        this.getProjectPartnerError,
        this.load,
      );
    };
    getProjectPartnerSuccess = (payload) => {
      let tempProjectPartner = {};
      Object.keys(payload).map((key) => {
        let temp = _.cloneDeep(payload[key]);
        if (Array.isArray(temp)) {
          temp = temp.map((item) => {
            return {
              ...item,
              display_name:
                item.display_name !== item.full_name ? item.display_name : "",
              total_likes: `${item.total_likes}`,
              avatar_url: item.agent_image,
              total_units_sold: `Units: ${item.total_units_sold}`,
            };
          });
        } else {
          temp = payload[key];
        }
        tempProjectPartner[key] = temp;
      });
      this.setState({ projectPartner: tempProjectPartner });
    };
    getProjectPartnerError = (error) => {
      if (typeof error === "string") {
        requestError(error);
      } else {
        let temp = [];
        Object.keys(error.errors).map((key) => {
          temp.push(`${key}: ${error.errors[key]}`);
        });
        requestError(temp);
      }
    };

    getProjectListing = (project_name, is_exclusive) =>
      Get(
        `/projects/active_project_listings?is_searching=1${project_name ? `&q[internal_name_cont]=${project_name}` : ""}${is_exclusive ? `&q[is_exclusive_true]=${is_exclusive}` : ""}`,
        this.getProjectListingSuccess,
        this.getProjectListingError,
        (param) => this.setState({ projectLoading: param }),
      );
    getProjectListingSuccess = (payload) => {
      let tempOptions = [];

      payload.projects.length > 0 &&
        payload.projects.map((item) => {
          tempOptions.push({
            name: item.name,
            atlas_id: item.id,
            value: item.internal_name,
            reference_id: item.reference_id,
            is_exclusive: item.is_exclusive,
          });
        });
      this.setState({ projectList: tempOptions });
    };
    getProjectListingError = (error) => requestError(error);

    getBranchRegions = () =>
      Get(
        `/branch_regions`,
        this.getBranchRegionsSuccess,
        this.getBranchRegionsError,
        () => {},
      );
    getBranchRegionsSuccess = (payload) => {
      let temp = payload.map((item) => ({
        ...item,
        label: item.name,
        value: item.reference_id,
      }));
      temp.sort((a, b) => b.label - a.label);
      this.setState({ branchRegion: temp });
    };
    getBranchRegionsError = (error) => requestError(error);

    createProjectPartnerRequest = (project_id) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/co_broke_requests`,
        {
          ...(project_id && {
            entity_id: project_id,
          }),
          agent_id: this.state.selectedAgent.agent_id,
          sender_id: id,
          status_id: 2,
          type: "ProjectPartnerRequest",
        },
        this.createProjectPartnerRequestSuccess,
        this.createProjectPartnerRequestError,
        this.load,
      );
    };
    createProjectPartnerRequestSuccess = () => {
      const { searchParams } = this.props.data.lastViewReducer.lastView;

      this.getProjectPartner(searchParams);
      this.setState({ showRequestConfirmation: false });
      requestSuccess("Request has been sent successfully.");
    };
    createProjectPartnerRequestError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          searchParams={this.state.searchParams}
          branchRegion={this.state.branchRegion}
          selectedAgent={this.state.selectedAgent}
          onLoadProject={this.state.projectLoading}
          projectList={this.state.projectList}
          onLoadProjectPartner={this.state.loading}
          projectPartner={this.state.projectPartner}
          showRequestConfirmation={this.state.showRequestConfirmation}
          getBranchRegions={this.getBranchRegions}
          getProjectPartner={this.getProjectPartner}
          getProjectListing={this.getProjectListing}
          onChangeProjectPartnerHOC={this.onChangeProjectPartnerHOC}
          createProjectPartnerRequest={this.createProjectPartnerRequest}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getAllDictionaryData,
  })(WithHOC);
};

export default HOC;
