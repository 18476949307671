import React, { Component } from "react";
import ReactModal from "react-modal";

import AtlasCloseButton from "components/Button/close";

const customStyles = {
  overlay: {
    zIndex: 5,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingRight: "40px",
  },
};

ReactModal.setAppElement("#root");

class SimpleModal extends Component {
  onClickCloseModal() {
    this.props.closeModal();
  }

  renderModalContent() {
    return this.props.modalContent;
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <div className="at-dropbear-panel">
          <div
            className="at-dropbear-panel__header"
            style={{ padding: 0, paddingTop: 10 }}
          >
            <AtlasCloseButton
              containerStyle={{ display: "flex", marginLeft: "auto" }}
              onClick={() => this.onClickCloseModal()}
            />
          </div>
          {this.renderModalContent()}
        </div>
      </ReactModal>
    );
  }
}

export default SimpleModal;
