import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlassProfileCardTable from "components/PaginatedProfileCardTable";
import PersonalSales from "../../../Personal/MySales";
import DetailsContent from "./Details";

import TeamMembersHOC from "./actions";
import { storeSelectedAgent } from "actions/sales";

import VersionCheckHOC from "actions/versionCheck";

const headerData = [
  {
    label: "Full Name",
    value: "agentName",
  },
  {
    label: "Branch",
    value: "branch",
  },
  {
    label: "Email",
    value: "email",
    icon: "atlas-sms",
  },
  {
    label: "Mobile Contact",
    value: "mobileNumber",
    icon: "atlas-call",
  },
  {
    label: "Date of Birth",
    value: "dob",
    icon: "atlas-cake",
  },
  {
    label: "Referrer",
    value: "groupLeaderName",
    icon: "atlas-frame",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Profile Image Url",
    value: "avatar_url",
  },
];

class TeamMembers extends Component {
  state = {
    showInfoBox: false,
  };

  componentDidMount = () => this.props.getTeamMembersList(1, "");

  detailsClick = (val) => {
    let url = window.location.href;
    if (url.indexOf("/admin/") > -1) {
      this.props.viewPersonalSales(
        val.encodedMobilePhone,
        val.agentName,
        "OtherAgentSales",
      );
    } else {
      this.props.storeSelectedAgent(val);
      this.props.onChangeHOC(true, "showAgentSales");
    }
  };

  renderCardContent = () => {
    return (
      <AtlassProfileCardTable
        className={"mb-100"}
        headerData={headerData}
        rowData={
          this.props.teamMembersList.data ? this.props.teamMembersList.data : []
        }
        meta={
          this.props.teamMembersList.meta && this.props.teamMembersList.meta
        }
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeHOC(val, "searchParams")
        }
        getListAPI={this.props.getTeamMembersList}
        onClickDetails={(val) => this.detailsClick(val)}
        renderAdditionalContent={
          <div
            className="d-flex align-items-center grid_gap-2 fw-600 fs-3 mb-3"
            style={{ color: "#111827" }}
          >
            <div
              className="at-badge-icon at-badge-icon-warning"
              style={{ width: 36, height: 36 }}
            >
              <AtlasIcon svgHref={"atlas-profile2user"} />
            </div>
            {this.props.teamMembersList.meta &&
              this.props.teamMembersList.meta.count}{" "}
            Members
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => this.props.exportTeamMembersList()}
            >
              <AtlasIcon
                svgHref={"atlas-xlsx-file"}
                style={{
                  width: 20,
                  height: 20,
                  fill: "#208E4D",
                }}
              />
              Export
            </button>
          </div>
        }
      />
    );
  };

  renderDetailsDialog = () => {
    let { avatar_url, first_image_url, second_image_url, third_image_url } =
      this.props.selectedTeamMember;
    return (
      <AtlasDialog open={this.props.showDetailsModal}>
        <DetailsContent
          {...this.props.selectedTeamMember}
          profileImagesData={[
            { isAvatarUrl: true, img: avatar_url },
            { isAvatarUrl: false, img: first_image_url },
            { isAvatarUrl: false, img: second_image_url },
            { isAvatarUrl: false, img: third_image_url },
          ]}
          onChangeHOC={this.props.onChangeHOC}
        />
      </AtlasDialog>
    );
  };

  renderAgentSearchTips = () => {
    return (
      <div>
        How to search and contact team members
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          Toggle the Filter & Search function to find your team member. <br />
          Click on the contact icons to get in touch with them directly via
          WhatsApp or email. <br />
          You can review your member’s sales summary by clicking on Detail.
        </p>
      </div>
    );
  };

  render = () => {
    const { role, status_id } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    return (
      <>
        {status_id === 2 && (
          <AlertBox
            mode={"danger"}
            description={
              "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
            }
          />
        )}
        <div className="at-form__content">
          <p className="fs-2 mb-3">
            Search and get in touch directly with your team members. <br />
            Got a call from an unknown self-claimed team member? If in doubt,
            search for their detail here to find out.
          </p>
          <CustomInfobox
            expand={this.state.showInfoBox}
            containerStyle={{ marginTop: 16 }}
            inactiveContent={"How to search and contact team members"}
            activeContent={this.renderAgentSearchTips()}
            onClickExpandInfo={(val) => this.setState({ showInfoBox: val })}
          />
        </div>
        {this.renderCardContent()}
        {this.renderDetailsDialog()}
        {this.props.showAgentSales && (
          <PersonalSales
            onClose={() => this.props.onChangeHOC(false, "showAgentSales")}
          />
        )}
        {this.props.onLoadTeamMembers && <LoadingModal />}
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default compose(
  connect(mapStateToProps, {
    storeSelectedAgent,
  }),
  TeamMembersHOC,
  VersionCheckHOC,
)(TeamMembers);
