import React, { useState } from "react";
import { IoMdDocument } from "react-icons/io";

import CustomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";

const IssueToModal = ({
  onClickClose,
  selected_claim,
  filename,
  file,
  selectedSubmission,
  uploadDocument,
}) => {
  const [issueTo, setIssueTo] = useState(0);

  const issueOption = (representative_id, sub_sale_type_id, external_agency_id) => { 
    let tnmp = [
      { id: 1, value: 1, label: sub_sale_type_id === 2 ? "Landlord" : "Vendor" },
      { id: 2, value: 2, label: sub_sale_type_id === 2 ? "Tenant" : "Purchaser" }
    ]
    if( external_agency_id && external_agency_id !== null ) {
      tnmp.push({ id: 3, value: 3, label: "Co-Agency" })
    }
    return tnmp;
  }

  return (
    <ModalDialog
      title={"Upload Document"}
      onClose={() => onClickClose()}
      children={
        <div className="grid-control">
          <section className="grid-full-col">
            <h2 className="at-form-input__title">Filename</h2>
            <p className="mb-20 fs-2">
              <IoMdDocument
                style={{ height: 18, width: 18, marginRight: 10 }}
              />
              {filename}
            </p>
          </section>
          <section className="grid-full-col mb-20">
            <h2 className="at-form-input__title">Issue To</h2>
            <CustomRadioGroup
              mode="old"
              checkedValue={issueTo}
              containerClassName={"mb-20"}
              options={issueOption(selected_claim.representative_id, selected_claim.sub_sale_type_id, selected_claim.external_agency_id)}
              selectedRadioValue={(val) => setIssueTo(val)}
            />
          </section>
        </div>
      }
      footer={
        <section className="grid-full-col d-flex">
          <button
            style={{ display: "inline-block", marginRight: "10px" }}
            className="btn-new btn-new--primary btn-lg"
            onClick={() => {
              Promise.all([
                uploadDocument(
                  selectedSubmission.id,
                  file,
                  filename,
                  issueTo,
                ),
              ]).then(() => {
                onClickClose();
              });
            }}
          >
            Upload
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClickClose()}
          >
            Close
          </button>
        </section>
      }
    />
  );
};

export default IssueToModal;
