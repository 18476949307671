import React, { Component } from "react";

import IconSprite from "assets/stylesheets/atlasIcon.svg";

class SideBarIcon extends Component {
  render() {
    const { id, className, svgHref, style, onClick, gradient } = this.props;
    const gradientId = Math.random().toString().slice(-6);
    const grandElement = gradient ? (
      <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform={`rotate(${gradient.deg})`}>
        {
          gradient.stops.map((stop, index) => (
            <stop key={index} offset={stop.offset} stopColor={stop.stopColor} />
          ))
        }
      </linearGradient>
    ) : (
      <linearGradient id={gradientId}>
        <stop offset="100%" stopColor="#8d8d8d" />
      </linearGradient>
    );

    return (
      <svg id={id} className={className} style={style} onClick={onClick}>
        <defs>
          { grandElement }
        </defs>
        <use
          id={id}
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
          fill={`url(#${gradientId})`}
        />
      </svg>
    );
  }
}

export default SideBarIcon;
