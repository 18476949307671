import React, { Component } from "react";
import _ from "lodash";
import { IconButton, Collapse, Grid } from "@material-ui/core";

import TableContent from "./Content";
import CustomButton from "components/Button";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";
import "../../index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchContent: false,
  };

  renderTableContent = () => {
    const { rowData = [] } = this.props;

    if (rowData && rowData.length > 0) {
      return rowData.map((item, index) => (
        <div className={"at-newsfeed-item_cont"}>
          <TableContent
            key={item.id}
            onClick={this.props.onClick}
            centerAlignColumns={this.props.centerAlignColumns}
            disableRowAction={this.props.disableRowAction}
            rowItem={item}
          />
        </div>
      ));
    } else {
      return (
        <div className="at-table-row-cont">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p>No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
            </li>
          </ul>
        </div>
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderLoadMore = () => (
    <CustomButton
      children={"Load More"}
      className={"btn-new btn-new--outline-secondary"}
      disabled={this.props.meta.page === this.props.meta.pages}
      onClick={() => this.props.getListAPI(this.props.meta.next)}
    />
  );

  render = () => {
    return (
      <>
        <div className="at-table at-table--set">
          <div className="at-newsfeed-grid_cont">
            {this.renderTableContent()}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {this.props.meta &&
            this.props.meta.count > 0 &&
            this.renderPaginationText()}
          {this.renderLoadMore()}
        </div>
      </>
    );
  };
}

export default PaginatedTable;
