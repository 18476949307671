import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { AiFillFileImage, AiOutlineClear, AiOutlineRedo } from "react-icons/ai";
import { FiHexagon, FiLifeBuoy } from "react-icons/fi";
import { FaShapes } from "react-icons/fa";

export default function Info() {
  return (
    <>
      <Card>
        <CardContent>
          <ul style={{ lineHeight: 2.2, marginBottom: 0 }}>
            <li>
              <AiOutlineClear style={{ width: 22, height: 22 }} /> - Remove all
              containers on the canvas.
            </li>
            <li>
              <AiFillFileImage style={{ width: 22, height: 22 }} /> - Set a
              background image on the canvas.
            </li>
            <li>
              <FaShapes style={{ width: 22, height: 22 }} /> - Add text/ image
              container on the canvas.
            </li>
            <li>
              <FiLifeBuoy style={{ width: 22, height: 22 }} /> - Free hand
              rotation on the canvas.
            </li>
            <li>
              <FiHexagon style={{ width: 22, height: 22 }} /> - Active Spanning
              45 degree rotation on the canvas.
            </li>
            <li>
              <AiOutlineRedo style={{ width: 22, height: 22 }} /> - Reset back
              zoom effects to original view on canvas.
            </li>
          </ul>
        </CardContent>
      </Card>
      <Card className="mt-3">
        <CardContent>
          <ul style={{ lineHeight: 2.2, marginBottom: 0 }}>
            <li>
              <div
                className="d-inline-block"
                style={{ width: 18, height: 18, background: "#f18e03" }}
              ></div>{" "}
              - <strong>Orange</strong> color container indicates text
              container.
            </li>
            <li>
              <div
                className="d-inline-block"
                style={{ width: 18, height: 18, background: "#7f7f7f" }}
              ></div>{" "}
              - <strong>Grey</strong> color container indicates image container.
            </li>
          </ul>
        </CardContent>
      </Card>
    </>
  );
}
