import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedEditAnnouncement: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeUpdateHOC = (val, context) => this.setState({ [context]: val });

    updateAnnouncement = (data) =>
      Put(
        `/admin/announcements/${data.id}`,
        data,
        this.updateAnnouncementSuccess,
        this.updateAnnouncementError,
        this.load,
      );
    updateAnnouncementSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.adminGetAnnouncements(currentPage, searchParams);

      requestSuccess("Announcement is updated successfully.");
      this.props.onChangeAnnouncementHOC(false, "showDetailsModal");
      this.props.onChangeAnnouncementHOC(false, "showEditModal");
    };

    updateAnnouncementError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadEdit={this.state.loading}
            selectedEditAnnouncement={this.state.selectedEditAnnouncement}
            onChangeUpdateHOC={this.onChangeUpdateHOC}
            updateAnnouncement={this.updateAnnouncement}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
