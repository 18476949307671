import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      nationalities: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getNationalities = () =>
      Get(
        `/nationalities`,
        this.getNationalitiesSuccess,
        this.getNationalitiesError,
        this.load,
      );
    getNationalitiesSuccess = (payload) => {
      let tmp = [];
      payload &&
        payload.map((item) => {
          let data = { ...item, label: item.name, value: item.name };
          tmp.push(data);
        });
      this.setState({ nationalities: tmp });
    };
    getNationalitiesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            nationalities={this.state.nationalities}
            onLoadNationalities={this.state.loading}
            getNationalities={this.getNationalities}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
