import React, { Component } from "react";
import _ from "lodash";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      admins: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeAdminHOC = (val, context) => this.setState({ [context]: val });

    getAdmins = () =>
      Get(
        `/admin/admin_roles/search?type_id=2`,
        this.getAdminsSuccess,
        this.getAdminsError,
        this.load,
      );
    getAdminsSuccess = (payload) => this.setState({ admins: payload });
    getAdminsError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          admins={this.state.admins}
          onLoadAdmin={this.state.loading}
          onChangeAdminHOC={this.onChangeAdminHOC}
          getAdmins={this.getAdmins}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
