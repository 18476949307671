import React, { Component } from "react";
import _ from "lodash";
import { Card } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import AtlasCard from "components/Card";
import CustomButton from "components/Button";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import TablePagination from "components/NewPaginatedTable/Pagination";
import MOTDManagementCard from "./components/motdCard";
import QouteOfTheDay from "./components/QouteOfTheDay";
import MOTDPreview from "./components/preview";
import AttachEventContent from "./attachEvent";

import MOTDHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";

class MOTDManagement extends Component {
  componentDidMount = () => {
    this.props.getMOTD(1);
  };

  renderDeleteConfirmation = () => {
    return (
      <ConfirmationModal
        open={this.props.showDeleteConfirmation}
        message={"Are you sure to delete this quote?"}
        title={"Delete quote"}
        showLoading={this.props.onLoadMOTD}
        positiveAction={() => {
          Promise.all([
            this.props.onChangeMOTDHOC(false, "showDeleteConfirmation"),
          ]).then(() => {
            this.props.deleteMOTD(this.props.selectedDeleteQuote);
          });
        }}
        negativeAction={() =>
          this.props.onChangeMOTDHOC(false, "showDeleteConfirmation")
        }
      />
    );
  };

  renderCardFooter = () => (
    <div className="d-flex flex-column align-items-center">
      {this.props.meta && this.props.meta.count > 0 && (
        <p className={`at-table__pagination-info`}>
          Showing
          <span> {this.props.meta && this.props.meta.from} </span>-
          <span> {this.props.meta && this.props.meta.to} </span>
          of
          <span> {this.props.meta && this.props.meta.count} </span>
          results
        </p>
      )}
      <TablePagination
        meta={this.props.meta}
        searchParams={[]}
        getListAPI={this.props.getMOTD}
        totalPages={this.props.MOTDPage}
      />
    </div>
  );

  renderCardContent = () => {
    const { can_update } = permissionsChecker("MOTD", this.props.data);

    return (
      <>
        <Card style={{ padding: "20px" }}>
          <MOTDManagementCard {...this.props} />
          <CustomButton
            disabled={this.props.bulkUpdate.length < 1 || !can_update}
            className={"btn-new btn-new--success m-3"}
            style={{ fontWeight: "400", fontSize: "14px" }}
            children={"Update All"}
            onClick={() => this.props.bulkUpdateMotd()}
          />
        </Card>
        {this.renderDeleteConfirmation()}
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("MOTD", this.props.data);

    return (
      <>
        <ModuleHeader
          title={`Message of the day`}
          atlasIcon={"atlas-messages-1"}
          actionButton={[
            {
              label: "New MOTD",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeMOTDHOC(true, "showCreateMOTDModal"),
            },
          ]}
        />
        <AtlasCard
          containerStyle={{ overflowX: "visible !important" }}
          cardFooter={this.renderCardFooter()}
          cardContent={this.renderCardContent()}
        />
        {this.props.showCreateMOTDModal && (
          <QouteOfTheDay
            newMOTD={this.props.newMOTD}
            onLoadMOTD={this.props.onLoadMOTD}
            putMOTD={this.props.putMOTD}
            onChangeMOTDHOC={this.props.onChangeMOTDHOC}
          />
        )}
        {this.props.showMOTDPreview && (
          <MOTDPreview
            onChangeMOTDHOC={this.props.onChangeMOTDHOC}
            previewMOTD={this.props.previewMOTD}
            onLoadMOTD={this.props.onLoadMOTD}
          />
        )}
        {this.props.showAttachEvent && (
          <AttachEventContent
            eventForm={this.props.eventForm}
            eventList={this.props.eventList}
            onLoadMOTD={this.props.onLoadMOTD}
            previewMOTD={this.props.previewMOTD}
            showAddAttachEvent={this.props.showAddAttachEvent}
            getEventList={this.props.getEventList}
            putAttachEvent={this.props.putAttachEvent}
            onChangeMOTDHOC={this.props.onChangeMOTDHOC}
            deleteAttachedEvent={this.props.deleteAttachedEvent}
          />
        )}
        {this.props.onLoadMOTD && <LoadingModal />}
      </>
    );
  };
}

export default MOTDHOC(MOTDManagement);
