import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";
import { refreshToken } from "actions/login";

const TutorialHOC = (WrappedComponent) => {
  class TutorialWrappedComponent extends Component {
    state = {
      tutorials: [],
      newTutorial: {
        title: "",
        category_id: "",
        document_file_name: "",
        document: "",
      },
      uploadedDocument: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getTutorials = () =>
      Get(
        `/tutorials`,
        this.getTutorialsSuccess,
        this.getTutorialsError,
        this.load,
      );
    getTutorialsSuccess = (payload) => this.setState({ tutorials: payload });
    getTutorialsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            getTutorials={this.getTutorials}
            tutorials={this.state.tutorials}
            onLoadTutorial={this.state.loading}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(TutorialWrappedComponent);
};

export default TutorialHOC;
