import React, { Component } from "react";
import { sha256 } from "js-sha256";

import LoadingModal from "components/LoadingModal";

import FPXIcon from "assets/ipay88/fpx.png";
import BoostIcon from "assets/ipay88/boost.png";
import GrabPayIcon from "assets/ipay88/grabpay.png";
import TNGIcon from "assets/ipay88/tng.png";
import MaybankQRPayIcon from "assets/ipay88/maybank_qrpay.png";
import ShopeePayIcon from "assets/ipay88/shopee_pay.png";
import VisaIcon from "assets/ipay88/visa.png";
import MastercardIcon from "assets/ipay88/master.png";

import "./index.scss";

class PaymentGateway extends Component {
  state = { loading: false };

  renderSubmitButton = () => {
    let tmp = "";
    if (!this.props.hideIcon) {
      switch (this.props.payment_id) {
        case 2:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon mr-10"
              onClick={() => this.setState({ loading: true })}
            >
              <img
                src={VisaIcon}
                alt="visa"
                className="display-inline-block icon mr-10"
              />
              <img
                src={MastercardIcon}
                alt="mastercard"
                className="display-inline-block icon"
              />
            </button>
          );
          break;
        case 16:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon mr-10 icon"
              onClick={() => this.setState({ loading: true })}
            >
              <img src={FPXIcon} alt="fpx" className="icon" />
            </button>
          );
          break;
        case 210:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon display-inline-block mr-10"
              onClick={() => this.setState({ loading: true })}
            >
              <img src={BoostIcon} alt="boost" style={{ width: "110px" }} />
            </button>
          );
          break;
        case 523:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon display-inline-block mr-10"
              onClick={() => this.setState({ loading: true })}
              style={{ padding: 0 }}
            >
              <img src={GrabPayIcon} alt="grab" style={{ width: "180px" }} />
            </button>
          );
          break;
        case 538:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon display-inline-block mr-10 icon"
              onClick={() => this.setState({ loading: true })}
            >
              <img src={TNGIcon} alt="tng" className="icon" />
            </button>
          );
          break;
        case 542:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon display-inline-block mr-10 icon"
              onClick={() => this.setState({ loading: true })}
            >
              <img src={MaybankQRPayIcon} alt="maybankQR" className="icon" />
            </button>
          );
          break;
        case 801:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-icon display-inline-block mr-10"
              onClick={() => this.setState({ loading: true })}
            >
              <img
                src={ShopeePayIcon}
                alt="shopee"
                style={{ width: "180px" }}
              />
            </button>
          );
          break;
        default:
          tmp = (
            <button
              disabled={this.props.disabled || false}
              className="btn-new btn-new--primary w-100"
              onClick={() => this.setState({ loading: true })}
            >
              Checkout
            </button>
          );
      }
    } else {
      tmp = (
        <button
          disabled={this.props.disabled || false}
          className="btn-new btn-new--primary w-100"
          onClick={() => this.setState({ loading: true })}
        >
          {this.props.buttonTitle || "Checkout"}
        </button>
      );
    }

    return tmp;
  };

  render = () => {
    const { first_name, last_name, full_name } = this.props.userInfo;
    const fullName =
      last_name && first_name ? `${last_name} ${first_name}` : full_name;

    return (
      <form
        className={`payment-form display-inline-block checkout-payment-btn ${this.props.fullWidth ? "w-100" : ""}`}
        method={"post"}
        name="ePayment"
        action="https://payment.ipay88.com.my/ePayment/entry.asp"
      >
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Merchant Code"}
          </label>
          <input
            readOnly="readonly"
            name={"MerchantCode"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={
              window.location.host.indexOf("atlas.iqiglobal.com") !== -1
                ? "M27432"
                : "M35354"
            }
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Payment ID"}
          </label>
          <input
            readOnly="readonly"
            name={"PaymentId"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={
              window.location.host.indexOf("atlas.iqiglobal.com") !== -1
                ? this.props.payment_id
                : " "
            }
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Ref No"}
          </label>
          <input
            readOnly="readonly"
            name={"RefNo"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={this.props.refNo}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Amount"}
          </label>
          <input
            readOnly="readonly"
            name={"Amount"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={
              window.location.host.indexOf("atlas.iqiglobal.com") !== -1
                ? this.props.amount
                : "1"
            }
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Currency"}
          </label>
          <input
            readOnly="readonly"
            name={"Currency"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={"MYR"}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Product Description"}
          </label>
          <input
            readOnly="readonly"
            name={"ProdDesc"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={this.props.productDesc || "N/A"}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Customer Name"}
          </label>
          <input
            required
            name={"UserName"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={`${fullName}`}
            placeholder="John Doe"
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Customer Email"}
          </label>
          <input
            required
            name={"UserEmail"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={this.props.userInfo.email}
            placeholder="user@gmail.com"
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Customer Phone"}
          </label>
          <input
            required
            name={"UserContact"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={this.props.userInfo.mobile_contact_number}
            placeholder="0123456789"
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Remark"}
          </label>
          <input
            readOnly="readonly"
            name={"Remark"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={this.props.remark}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Lang"}
          </label>
          <input
            readOnly="readonly"
            name={"Lang"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={"UTF-8"}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"SignatureType"}
          </label>
          <input
            readOnly="readonly"
            name={"SignatureType"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={"SHA256"}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"Signature"}
          </label>
          <input
            readOnly="readonly"
            name={"Signature"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={sha256(
              `${window.location.host.indexOf("atlas.iqiglobal.com") !== -1 ? "IeEvoQvuBY" : "YlZpMYxtcv"}${window.location.host.indexOf("atlas.iqiglobal.com") !== -1 ? "M27432" : "M35354"}${this.props.refNo}${window.location.host.indexOf("atlas.iqiglobal.com") !== -1 ? this.props.amountSHA256 : "1"}MYR`
            )}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"ResponseURL"}
          </label>
          <input
            readOnly="readonly"
            name={"ResponseURL"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={`https://${window.location.host.indexOf("atlas.iqiglobal.com") !== -1 ? "api" : "apitest"}.iqiglobal.com/api/payment_gateway/redirect`}
          />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: "14px", fontWeight: "500" }}>
            {"BackendURL"}
          </label>
          <input
            readOnly="readonly"
            name={"BackendURL"}
            className="form-control"
            style={{ width: "100%", height: "50px" }}
            value={`https://${window.location.host.indexOf("atlas.iqiglobal.com") !== -1 ? "api" : "apitest"}.iqiglobal.com/api/payment_gateway/callbacks`}
          />
        </div>
        {this.renderSubmitButton()}
        {this.state.loading && <LoadingModal />}
      </form>
    );
  };
}

export default PaymentGateway;
