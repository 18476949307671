import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { PulseLoader } from "react-spinners";
import ReactHTMLParser from "react-html-parser";

import AtlasButton from "components/Button";
import CKEditor from "components/CKEditor";

import permissionsChecker from "utils/permissionsChecker";
import SubsaleMessageHOC from "../actions/message";

import "stylesheets/containers/personal/subsales/components/message.scss";

class AtlasMessageTab extends Component {
  state = {
    messages: [],
    inputMessage: "",
    checkedPressEnterToSend: false,
  };

  componentDidMount = () => {
    this.props.getMessages(this.props.selected_claim.id);
    this.scrollToBottom();
  };

  componentDidUpdate = (pp) => {
    if (this.props.messages !== pp.messages) {
      this.scrollToBottom();
    }
    if (
      this.props.clearTypeBox !== pp.clearTypeBox &&
      this.props.clearTypeBox
    ) {
      this.setState({ inputMessage: "" });
    }
  };

  onChangeMessageValue = (val) => {
    if (val.indexOf("<br>") === 0) {
      this.setState({ inputMessage: "" });
    } else {
      this.setState({ inputMessage: val });
    }
  };

  scrollToBottom = () =>
    this.messagesEnd.scrollIntoView({ block: "nearest", inline: "start" });

  onChangeEnterCheckboxValue = (val) =>
    this.setState({ checkedPressEnterToSend: val });

  onClickSendMessage = () => {
    this.props.postMessage(this.props.selected_claim.id, {
      message: {
        content: this.state.inputMessage,
      },
    });
  };

  render = () => {
    const { onLoadMessage } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    return (
      <>
        <div className="chat-feed-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.props.messages &&
              this.props.messages.length > 0 &&
              this.props.messages.map((item) => {
                if (item.from_admin) {
                  return (
                    <div className="at-message__coversation-cont">
                      <div className="d-flex justify-content-between my-1">
                        <p
                          style={{
                            fontSize: 14,
                            color: "#1F2937",
                            fontWeight: 500,
                          }}
                        >
                          {item.sender_name}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#4B5563",
                            marginLeft: 10,
                          }}
                        >
                          {Moment(item.created_at)
                            .format("DD MMM YYYY HH:mm")
                            .toString()}
                        </p>
                      </div>
                      <div
                        style={{
                          background: "#F3F4F6",
                          borderRadius: "0px 12px 12px 12px",
                          padding: "12px 16px",
                        }}
                      >
                        <p
                          className={"at-message__text-content"}
                          style={{ color: "black" }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="at-message__coversation-cont ml-auto">
                      <div className="d-flex justify-content-between my-1">
                        <p
                          style={{
                            fontSize: 14,
                            color: "#1F2937",
                            fontWeight: 500,
                          }}
                        >
                          {item.sender_name}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#4B5563",
                            marginLeft: 10,
                          }}
                        >
                          {Moment(item.created_at)
                            .format("DD MMM YYYY HH:mm")
                            .toString()}
                        </p>
                      </div>
                      <div
                        style={{
                          background: "#2563EB",
                          borderRadius: "12px 0px 12px 12px",
                          padding: "12px 16px",
                        }}
                      >
                        <p
                          className={"at-message__text-content"}
                          style={{ color: "white" }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
        {can_update && (
          <div className="at-subsales_claims-message_editor mb-3">
            <CKEditor
              data={this.state.inputMessage}
              onChange={(event, editor) => {
                const data = editor.getData();

                this.onChangeMessageValue(data);
              }}
            />
          </div>
        )}
        {!onLoadMessage && (
          <AtlasButton
            className="btn-new btn-new--secondary"
            disabled={!can_create}
            children={"Send"}
            onClick={this.onClickSendMessage}
          />
        )}
        {onLoadMessage && (
          <PulseLoader
            className={"d-flex align-items-center"}
            sizeunit={"px"}
            size={10}
            color={"#16232c"}
            loading={onLoadMessage}
          />
        )}
      </>
    );
  };
}

export default SubsaleMessageHOC(AtlasMessageTab);
