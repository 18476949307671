import React, { Component } from "react";
import { DatePicker } from "element-react";
import { i18n } from "element-react";
import locale from "element-react/src/locale/lang/en";
import { FormHelperText } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";

import "element-theme-default";
import "stylesheets/components/form-group/input.scss";

class FormGroupInput extends Component {
  componentDidMount = () => i18n.use(locale);

  renderLeftIcon(param) {
    if (param) {
      return (
        <span className="input-group-addon input-group-prepend">{param}</span>
      );
    }
  }

  renderInput(type) {
    const {
      placeholder,
      value,
      onSelectDate,
      disabledFutureDate,
      disabledDate,
      className,
      error,
      errorMessage,
      required,
      calendarProps,
    } = this.props;

    if (type === "calendar") {
      return (
        <>
          <div
            className={`at-form-input w-100 ${disabledDate ? "at-form-input__disabled" : ""}`}
          >
            <AtlasIcon
              style={{ zIndex: 1 }}
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            {/* Date Format standard for this input(only) is dd-MM-yyyy (e.g. 06-08-2019) (case sensitive)*/}
            <DatePicker
              {...calendarProps}
              format="dd-MM-yyyy"
              required={required}
              className={`at-form-input__input ${className}`}
              placeholder={placeholder}
              value={value && value !== "" ? new Date(value) : value}
              onChange={(val) => {
                let tmp = "";
                if (val && val !== "") {
                  tmp = new Date(val);
                  tmp.setTime(tmp.getTime() + 3600 * 8 * 1000);
                }
                onSelectDate(tmp);
              }}
              isDisabled={disabledDate}
              style={{ zIndex: "9999" }}
              {...(disabledFutureDate && {
                disabledDate: (time) => time.getTime() >= Date.now(),
              })}
            ></DatePicker>
          </div>
          {
            (required || errorMessage) && (
              <div className="at-input_footer" style={ (required || errorMessage) ? { marginBottom: "1rem" } : {} }>
                { required && <div className="at-form-input__required">required *</div>}
                { errorMessage && <div className="at-form-input__label--error">errorMessage</div>}
              </div>
            )
          }
        </>
      );
    }
  }

  render() {
    const { type, addonLeftIcon, className, error } = this.props;
    const displayClass = (props) => {
      let classAssigned = "";
      if (props.hasIcon) {
        classAssigned = classAssigned + "input-group";
      }
      if (props.addonRightIcon) {
        classAssigned = classAssigned + "has-right-slot";
      }
      if (props.error) {
        classAssigned = classAssigned + "has-error";
      }
      if (props.focused) {
        classAssigned = classAssigned + "input-group-focus";
      }
      if (props.label) {
        classAssigned = classAssigned + "has-label";
      }
      if (!props.error && props.touched && props.hadError) {
        classAssigned = classAssigned + "has-success";
      }
      return classAssigned;
    };
    return (
      <div className={`${displayClass(this.props)} ${className || ""}`}>
        <div className="container--input">
          {this.renderLeftIcon(addonLeftIcon)}
          {this.renderInput(type)}
        </div>
      </div>
    );
  }
}

export default FormGroupInput;