import React, { Component } from "react";

import IconSprite from "assets/stylesheets/newSprite.svg";

class SvgIcon extends Component {
  render() {
    const { className, svgHref, style, onClick } = this.props;
    return (
      <svg className={className} style={style}>
        <use
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
          onClick={onClick}
        />
      </svg>
    );
  }
}

export default SvgIcon;
