import React, { useState } from "react";
import Moment from "moment";
import _, { set } from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/delete";
import CustomFormInput from "components/Input/formInput";

const NotesModal = ({
  data,
  onLoadCobroke,
  selectedCobroke,

  onClose,
  deleteNote,
  createNote,
  updateNote,
  getListAPI,
  setSelectedCobroke,
}) => {
  const { internal_notes } = selectedCobroke;
  const { currentPage, searchParams } = data.lastViewReducer.lastView;

  const [note, setNote] = useState("");
  const [selectedNote, setSelectedNote] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onToggleDeleteNote = () =>
    deleteNote(
      {
        note_id: selectedNote.id,
        cobroke_id: selectedCobroke.id,
      },
      () => {
        let temp = _.cloneDeep(selectedCobroke.internal_notes);
        let tempIndex = _.findIndex(temp, { id: selectedNote.id });
        temp.splice(tempIndex, 1);
        setSelectedCobroke({ ...selectedCobroke, internal_notes: temp });
        setShowDeleteConfirmation(false);
        getListAPI(currentPage, searchParams);
      },
    );

  const onToggleEdit = (param, val) => {
    setSelectedNote(param);
    let temp = _.cloneDeep(selectedCobroke.internal_notes);
    let tempIndex = _.findIndex(temp, { id: param.id });
    temp[tempIndex]["edit"] = val;
    setSelectedCobroke({
      ...selectedCobroke,
      internal_notes: temp,
    });
  };

  const onToggleUpdateNote = () =>
    updateNote(
      {
        id: selectedNote.id,
        note: selectedNote.note,
        cobroke_id: selectedCobroke.id,
      },
      (payload) => {
        let temp = _.cloneDeep(selectedCobroke.internal_notes);
        let tempIndex = _.findIndex(temp, { id: payload.id });
        temp[tempIndex] = payload;
        setSelectedCobroke({ ...selectedCobroke, internal_notes: temp });
        getListAPI(currentPage, searchParams);
      },
    );

  const onToggleCreateNote = () =>
    createNote(
      {
        cobroke_id: selectedCobroke.id,
        note: note,
      },
      (payload) => {
        let temp = _.cloneDeep(selectedCobroke.internal_notes);
        temp.unshift(payload);
        setSelectedCobroke({ ...selectedCobroke, internal_notes: temp });
        getListAPI(currentPage, searchParams);
        setNote("");
      },
    );

  return (
    <ModalDialog
      title={"Internal Notes"}
      children={
        <>
          <p className="at-form-input__title">Internal Notes</p>
          <CustomFormInput
            type="textarea"
            value={note}
            inputStyle={{ height: 50 }}
            onChangeValue={(val) => setNote(val)}
          />
          <CustomButton
            className={"btn-new btn-new--primary mt-2"}
            children={"Submit"}
            onClick={() => onToggleCreateNote()}
          />
          {internal_notes &&
            internal_notes.length > 0 &&
            internal_notes.map((item, index) => (
              <div key={index} className="mt-2">
                <hr />
                <p className="fs-1 mb-1" style={{ color: "#6B7280" }}>
                  {Moment(item.updated_at).format("DD MMM YYYY HH:mm")}
                </p>
                {item.edit ? (
                  <>
                    <CustomFormInput
                      type="textarea"
                      value={selectedNote.note}
                      inputStyle={{ height: 50 }}
                      onChangeValue={(val) =>
                        setSelectedNote({
                          ...selectedNote,
                          note: val,
                        })
                      }
                    />
                    <div className="d-flex">
                      <CustomButton
                        className={"btn-new btn-new--success mr-1"}
                        children={"Update"}
                        onClick={() => onToggleUpdateNote()}
                      />
                      <CustomButton
                        className={"btn-new btn-new--outline-secondary mr-1"}
                        children={"Cancel"}
                        onClick={() => onToggleEdit(item, false)}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <p className="fs-2" style={{ whiteSpace: "pre-line" }}>
                      {item.note}
                    </p>
                    <div className="d-flex align-items-center">
                      <CustomButton
                        className={"btn-new p-0 pr-3"}
                        children={
                          <>
                            <AtlasIcon
                              svgHref={"atlas-edit-linear"}
                              style={{
                                width: 14,
                                height: 14,
                                fill: "#6B7280",
                                marginRight: 10,
                              }}
                            />
                            Edit
                          </>
                        }
                        onClick={() => onToggleEdit(item, true)}
                      />
                      <CustomButton
                        className={"btn-new p-0 pr-3"}
                        children={
                          <>
                            <AtlasIcon
                              svgHref={"atlas-trash"}
                              style={{
                                width: 14,
                                height: 14,
                                fill: "#6B7280",
                              }}
                            />
                            Delete
                          </>
                        }
                        onClick={() => {
                          setSelectedNote(item);
                          setShowDeleteConfirmation(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          <ConfirmationModal
            title={"Delete Note"}
            loading={onLoadCobroke}
            open={showDeleteConfirmation}
            message={`Are you sure you want to delete this note ?`}
            positiveAction={() => onToggleDeleteNote()}
            negativeAction={() => setShowDeleteConfirmation(false)}
          />
        </>
      }
      onClose={onClose}
    />
  );
};

export default NotesModal;
