import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import CustomCard from "components/Card";
import CustomFormInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";
import CustomTextarea from "components/Input/textarea";
import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

import CreateAnnouncementHOC from "./actions/new";
import VersionCheckHOC from "actions/versionCheck";

class NewAnnouncement extends Component {
  onChangeField = (val, context) => {
    let temp = _.cloneDeep(this.props.newAnnouncement);
    temp[context] = val;
    this.props.onChangeNewAnnouncementHOC(temp, "newAnnouncement");
  };

  renderCardContent = () => {
    const { title, published, content } = this.props.newAnnouncement;
    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 10 }}
          onClick={() =>
            this.props.onChangeAnnouncementHOC(false, "showCreateModal")
          }
        />
        <h4 className="at-card__title">Create Announcement</h4>
        <hr />
        <form>
          <h3 className="at-form-input__title" required>
            Title
          </h3>
          <CustomFormInput
            type={"text"}
            value={title}
            onChangeValue={(val) => this.onChangeField(val, "title")}
            required={true}
            inputError={""}
            placeholder={"Enter your announcement title here"}
          />
          <CustomCheckbox
            labelStyle={{ marginBottom: "20px" }}
            checked={published}
            content={"Publish"}
            onChangeCheckboxValue={(val) =>
              this.onChangeField(val, "published")
            }
          />
          <br />
          <h3 className="at-form-input__title" required>
            Content
          </h3>
          <CustomTextarea
            value={content}
            placeholder={"Write your description here"}
            onChangeValue={(val) => this.onChangeField(val, "content")}
            required={true}
            inputError={""}
          />
          <div className="d-flex">
            <CustomButton
              className={"btn-new btn-new--primary at-btn--success mr-10"}
              onClick={() =>
                this.props.createAnnouncement(this.props.newAnnouncement)
              }
              children={"Create"}
            />
          </div>
        </form>
      </>
    );
  };

  render() {
    return (
      <>
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadNewAnnouncement && <LoadingModal />}
      </>
    );
  }
}

export default compose(CreateAnnouncementHOC, VersionCheckHOC)(NewAnnouncement);
