import React, { useEffect, useState } from "react";
import _ from "lodash";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

import CustomCheckbox from "components/Checkbox";

import { numberWithCommas } from "utils/thousandSeparator";

const chartConfig = {
  barPercentage: 0.5,
  barThickness: 15,
  maxBarThickness: 15,
  minBarLength: 8,
};

const ReportChart = ({ type, reportData }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [showConverted, setShowConverted] = useState(true);
  const [showUnconverted, setShowUnconverted] = useState(true);

  useEffect(() => {
    let tempLabels = [];
    let tempDataset = [];
    let tempDataset2 = [];
    let tempData = _.cloneDeep(reportData);
    if (showConverted && showUnconverted) {
      tempData.map((item) => {
        tempLabels.push(item.Agent);
        tempDataset.push(parseInt(item[unconvertedType().value]));
        tempDataset2.push(parseInt(item[convertedType().value]));
      });
      return setChartData({
        labels: tempLabels,
        datasets: [
          {
            label: unconvertedType().label,
            backgroundColor: "rgba(255,99,132,0.4)",
            hoverBackgroundColor: "rgba(250,99,132, 1)",
            ...chartConfig,
            data: tempDataset,
          },
          {
            label: convertedType().label,
            backgroundColor: "rgba(54,162,235,0.4)",
            hoverBackgroundColor: "rgba(54,162,235,1)",
            ...chartConfig,
            data: tempDataset2,
          },
        ],
      });
    } else if (showConverted && !showUnconverted) {
      tempData.sort(
        (a, b) => b[convertedType().value] - a[convertedType().value],
      );
      tempData.map((item) => {
        tempLabels.push(item.Agent);
        tempDataset.push(parseInt(item[convertedType().value]));
      });
      return setChartData({
        labels: tempLabels,
        datasets: [
          {
            label: convertedType().label,
            backgroundColor: "rgba(54,162,235,0.4)",
            hoverBackgroundColor: "rgba(54,162,235,1)",
            ...chartConfig,
            data: tempDataset,
          },
        ],
      });
    } else if (!showConverted && showUnconverted) {
      tempData.sort(
        (a, b) => b[unconvertedType().value] - a[unconvertedType().value],
      );
      tempData.map((item) => {
        tempLabels.push(item.Agent);
        tempDataset.push(parseInt(item[unconvertedType().value]));
      });
      return setChartData({
        labels: tempLabels,
        datasets: [
          {
            label: unconvertedType().label,
            backgroundColor: "rgba(255,99,132,0.4)",
            hoverBackgroundColor: "rgba(250,99,132, 1)",
            ...chartConfig,
            data: tempDataset,
          },
        ],
      });
    } else if (!showConverted && !showUnconverted) {
      return setChartData({ labels: [], datasets: [] });
    }
  }, [reportData, showConverted, showUnconverted]);

  const convertedType = () => {
    switch (type) {
      case "project":
        return { label: "Total Converted Amount", value: "TotalConverted" };
      case "sale":
        return {
          label: "Total Converted Amount (Sale)",
          value: "TotalSubsaleAgencyCommission",
        };
      case "rent":
        return {
          label: "Total Converted Amount (Rental)",
          value: "TotalRentalAgencyCommission",
        };
      case "all":
        return {
          label: "Total Converted Amount (Sale + Rental)",
          value: "TotalAgencyCommission",
        };
      default:
        return { label: "Total Converted Amount", value: "TotalConverted" };
    }
  };

  const unconvertedType = () => {
    switch (type) {
      case "project":
        return { label: "Total Sales Amount", value: "TotalValidSale" };
      case "sale":
        return {
          label: "Total Sales Amount (Sale)",
          value: "GrandTotalSubsaleAgencyCommission",
        };
      case "rent":
        return {
          label: "Total Sales Amount (Rental)",
          value: "GrandTotalRentalAgencyCommission",
        };
      case "all":
        return {
          label: "Total Sales Amount (Sale + Rental)",
          value: "GrandTotalAgencyCommission",
        };
      default:
        return { label: "Total Sales Amount", value: "TotalValidSale" };
    }
  };

  return (
    <>
      <div className="d-flex">
        <CustomCheckbox
          content={
            <div className="d-flex align-items-center">
              <span
                className="at-divider-dot"
                style={{ backgroundColor: "#fa6384" }}
              />
              {unconvertedType().label}
            </div>
          }
          checked={showUnconverted}
          onChangeCheckboxValue={() => setShowUnconverted(!showUnconverted)}
        />
        <CustomCheckbox
          content={
            <div className="d-flex align-items-center">
              <span
                className="at-divider-dot"
                style={{ backgroundColor: "#36A2EB" }}
              />
              {convertedType().label}
            </div>
          }
          checked={showConverted}
          onChangeCheckboxValue={() => setShowConverted(!showConverted)}
        />
      </div>
      <div
        style={{
          marginTop: 20,
          height: `${reportData.length > 0 ? chartData.labels.length * 50 + "px" : "50vh"}`,
        }}
      >
        <HorizontalBar
          data={{
            labels: chartData.labels,
            datasets: chartData.datasets,
          }}
          options={{
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  var label =
                    data.datasets[tooltipItem.datasetIndex].label || "";
                  return `${label}: ${numberWithCommas(tooltipItem.xLabel)}`;
                },
              },
            },
            legend: {
              display: false,
            },
            plugins: {
              datalabels: {
                anchor: "end",
                align: "end",
                formatter: (value, context) =>
                  numberWithCommas(context.dataset.data[context.dataIndex]),
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    callback: (value, index, values) => numberWithCommas(value),
                    beginAtZero: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    fontSize: 13,
                    callback: (label) => {
                      if (window.innerWidth > 1024 && label.length > 20) {
                        return `${label.substring(0, 20)}...`;
                      } else if (
                        window.innerWidth <= 1024 &&
                        label.length > 10
                      ) {
                        return `${label.substring(0, 10)}...`;
                      } else {
                        return label;
                      }
                    },
                  },
                },
              ],
            },
          }}
        />
      </div>
    </>
  );
};

export default ReportChart;
