import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      submissions: [],
      loading: false,
      showCloseConfirmation: false,
      isCreateSubmissionError: false,
      newSubmission: {
        type_id: "",
        description: "",
        claim_party_id: 0,
        issue_to_owner: false,
        issue_to_client: false,
        issue_to_agency: false,
      },
      selectedSubmission: {
        type_id: "",
        description: "",
        claim_party_id: 0,
      },
      showSubmitButton: true,
      showInformativeModal: false,
      showUpdateSubmission: false,
      showCreateSubmission: false,
      showConfirmCreateSubmission: false,
      showResubmitConfirmation: false,
      countDownTime: null,
      refundParticipants: [],
    };

    componentDidMount = () => {
      this.createCountDown = null;
    };

    load = (param) => this.setState({ loading: param });

    onChangeSubmissionHOC = (key, val) => this.setState({ [key]: val });

    onCloseCopyRemarkConfirmation = () => {
      this.setState({ 
        showCloseConfirmation: false,
        isCreateSubmissionError: false,
        showCreateSubmission: false
      });
    }

    getSubmissions = (id) =>
      Get(
        `/sub_sale_claims/${id}/submissions`,
        this.getSubmissionsSuccess,
        this.getSubmissionsError,
        this.load,
      );
    getSubmissionsSuccess = (payload) => {
      let tempData = _.map(payload.sub_sale_claim_submissions, (item) => ({
        ...item,
        submitted_date: item.submitted_date
          ? Moment(item.submitted_date, "DD-MM-YYYY HH:mm").format(
              "DD MMM YYYY HH:mm",
            )
          : "N/A",
      }));
      this.setState({ submissions: tempData });
    };
    getSubmissionsError = (error) => requestError(error);

    getSelectedSubmission = (claim_id, submission_id) =>
      Get(
        `/sub_sale_claims/${claim_id}/submissions/${submission_id}`,
        this.getSelectedSubmissionSuccess,
        this.getSelectedSubmissionError,
        this.load,
      );
    getSelectedSubmissionSuccess = (payload) =>
      this.setState({
        selectedSubmission: payload,
        showUpdateSubmission: true,
      });
    getSelectedSubmissionError = (error) => requestError(error);

    createSubmission = (id, data) => {
      let temp = _.cloneDeep(data);
      temp.type_id !== 1 && delete temp.claim_party_id;
      this.setState(
        {
          showSubmitButton: false,
          showConfirmCreateSubmission: false,
        },
        () => {
          Post(
            `/sub_sale_claims/${id}/submissions`,
            data,
            (payload) => this.createSubmissionSuccess(payload, id),
            error => this.createSubmissionError(error, data),
            this.load,
          );
        },
      );
    };
    createSubmissionSuccess = (payload, id) => {
      this.setState({
        showSubmitButton: true,
        showInformativeModal: [1, 2, 3, 4, 5].indexOf(payload.type_id) > -1,
        showCreateSubmission: false,
        showConfirmCreateSubmission: false,
        newSubmission: {
          type_id: "",
          description: "",
          claim_party_id: 0,
          issue_to_owner: false,
          issue_to_client: false,
          issue_to_agency: false,
        },
        countDownTime: 5
      });

      requestSuccess("Submission is created successfully.");
      this.createCountDown = setInterval(() => {
        let tempCountDown = _.cloneDeep(this.state.countDownTime);
        if (tempCountDown > 0) {
          this.setState({ countDownTime: tempCountDown - 1 });
        } else {
          clearInterval(this.createCountDown);
          this.setState({ countDownTime: null });
        }
      }, 1000);
      this.getSubmissions(id);
      this.props.getClaimsList();
    };
    createSubmissionError = (error, data) =>
      this.setState({ 
        showSubmitButton: true,
        isCreateSubmissionError: data?.description?.trim() && [2, 3, 5].indexOf(data.type_id) === -1 // Except refund and release submission, this flag used to determined whether to show "Copy Remark & Close" confirmation dialog
      }, () => requestError(error));

    resubmitSubmission = (claim_id, submission_id) =>
      Put(
        `/sub_sale_claims/${claim_id}/submissions/${submission_id}/resubmit`,
        {},
        (payload) =>
          this.resubmitSubmissionSuccess(payload, claim_id, submission_id),
        this.resubmitSubmissionError,
        this.load,
      );
    resubmitSubmissionSuccess = (payload, claim_id, submission_id) => {
      this.setState({
        showInformativeModal: [1, 2, 3, 4, 5].indexOf(payload.type_id) > -1,
        showUpdateSubmission: false,
        showResubmitConfirmation: false,
      });
      requestSuccess("Submission is resubmitted successfully.");
      this.getSubmissions(claim_id);
      this.props.getClaimsList();
    };
    resubmitSubmissionError = (error) => requestError(error);

    getRefundParticipants = (id, submission_type_id) =>
      Get(
        `/sub_sale_claims/${id}/refund_participants?submission_type_id=${submission_type_id}`,
        this.getRefundParticipantsSuccess,
        this.getRefundParticipantsError,
        this.load,
      );
    getRefundParticipantsSuccess = (payload) => this.setState({ refundParticipants: payload });
    getRefundParticipantsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeSubmissionHOC={this.onChangeSubmissionHOC}
            getSubmissions={this.getSubmissions}
            getSelectedSubmission={this.getSelectedSubmission}
            createSubmission={this.createSubmission}
            resubmitSubmission={this.resubmitSubmission}
            getRefundParticipants={this.getRefundParticipants}
            onCloseCopyRemarkConfirmation={this.onCloseCopyRemarkConfirmation}
            showCloseConfirmation={this.state.showCloseConfirmation}
            isCreateSubmissionError={this.state.isCreateSubmissionError}
            refundParticipants={this.state.refundParticipants}
            countDownTime={this.state.countDownTime}
            newSubmission={this.state.newSubmission}
            selectedSubmission={this.state.selectedSubmission}
            submissions={this.state.submissions}
            onLoadSubmissions={this.state.loading}
            showSubmitButton={this.state.showSubmitButton}
            showInformativeModal={this.state.showInformativeModal}
            showUpdateSubmission={this.state.showUpdateSubmission}
            showCreateSubmission={this.state.showCreateSubmission}
            showResubmitConfirmation={this.state.showResubmitConfirmation}
            showConfirmCreateSubmission={this.state.showConfirmCreateSubmission}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
