import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash"

import EditContent from "./components/editDialog";
import CreateContent from "./components/createDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasCard from "components/Card";
import AtlasDailog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";

import CoAgencyHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { label: "Pending", colorName: "Yellow", value: 1 },
  { label: "Active", colorName: "Green", value: 2 },
  { label: "Inactive", colorName: "Red", value: 3 },
]
const columnData = [
  { header: "Board Registration No.", accessor: "registration_number", columnStyle: { width: "50%" } },
  { header: "Agency Name", accessor: "name", columnStyle: { width: "50%" } },
  { header: "Address", accessor: "address", columnStyle: { width: "100%" } },
  { header: "Phone Number", accessor: "phone_number", columnStyle: { width: "50%" } },
  { header: "Email", accessor: "email", columnStyle: { width: "50%" } },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "40%" },
    renderColumn: (rowData) => {
      const { 
        label = "",
        colorName = ""
      } = _.find(StatusColor, { value: rowData.status_id }) || {};
      if(label) {
        return (
          <div
            className="at-status_badge at-status_badge-responsive"
            style={{
              ...getColorBadge(colorName),
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </div>
        )
      }
      return "N/A"
    }
  },
  {
    header: "SST Registered",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "40%" },
    renderColumn: (rowData) => (
      <p
        style={{ fontSize: 22 }}
        className={`text-center ${rowData.sst_registered ? "text-success" : "text-danger"}`}
      >
        {rowData.sst_registered ? "✓" : "×"}
      </p>
    ),
  },
];

class CoAgency extends Component {
  componentDidMount = () => this.props.getCoAgency(1, "");

  renderCardContent = () => {
    const { can_update } = permissionsChecker("Co-Agencies", this.props.data);

    return (
      <>
        <AtPaginatedTable
          rowData={this.props.coAgency.data || []}
          meta={this.props.coAgency.meta}
          columns={columnData}
          actionColumnContent={[
            {
              name: can_update ? "edit" : "view",
              onClick: (rowData) => this.props.getSelectedAgency(rowData.id),
            },
          ]}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeCoAgencyHOC(val, "searchParams")
          }
          getListAPI={this.props.getCoAgency}
          totalPages={this.props.coAgencyPages}
        />

        {/* create dialog */}
        <AtlasDailog open={this.props.showCreateDialog}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <CreateContent
                onLoadCoAgency={this.props.onLoadCoAgency}
                createAgency={this.props.createAgency}
                onClose={() =>
                  this.props.onChangeCoAgencyHOC(false, "showCreateDialog")
                }
              />
            }
          />
        </AtlasDailog>

        {/* edit dialog */}
        <AtlasDailog open={this.props.showEditDialog}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <EditContent
                permissionsChecker={permissionsChecker(
                  "Co-Agencies",
                  this.props.data,
                )}
                updateAgency={this.props.updateAgency}
                onLoadCoAgency={this.props.onLoadCoAgency}
                selectedAgency={this.props.selectedAgency}
                onClose={() =>
                  this.props.onChangeCoAgencyHOC(false, "showEditDialog")
                }
              />
            }
          />
        </AtlasDailog>
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Co-Agencies", this.props.data);

    return (
      <>
        <ModuleHeader
          title={`Co-Agencies`}
          moduleIcon={"icon-project-centre"}
          actionButton={[
            {
              label: "New Agency",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeCoAgencyHOC(true, "showCreateDialog"),
            },
          ]}
        />
        <AtlasCard cardContent={this.renderCardContent()} />
        {this.props.onLoadCoAgency && <LoadingModal />}
      </>
    );
  };
}

export default compose(CoAgencyHOC, VersionCheckHOC)(CoAgency);
