import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      fileItem: {},
      fileName: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeImportHOC = (val, context) => this.setState({ [context]: val });

    importExcel = (dataToSubmit) =>
      Post(
        `/admin/leads/import`,
        dataToSubmit,
        this.importExcelSuccess,
        this.importExcelError,
        this.load,
      );
    importExcelSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getLeads(currentPage, searchParams);

      this.props.onChangeLeadsHOC(false, "showImportModal");
      requestSuccess(
        `Total ${payload.total_imported} leads have been imported successfully`,
      );
    };
    importExcelError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            fileItem={this.state.fileItem}
            fileName={this.state.fileName}
            onLoadImport={this.state.loading}
            onChangeImportHOC={this.onChangeImportHOC}
            importExcel={this.importExcel}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
