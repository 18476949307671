import React, { useEffect, useState } from "react";
import _ from "lodash";

import Tabs from "components/Tab";
import TierContent from "components/Tier";
import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";

import defaultAvatar from "assets/images/av-male.jpg";
import leaderMedal1 from "assets/images/medal_1.png";
import leaderMedal2 from "assets/images/medal_2.png";
import leaderMedal3 from "assets/images/medal_3.png";
import leaderBoardLeft from "assets/images/project_leaderboard_left.png";
import leaderBoardRight from "assets/images/project_leaderboard_right.png";
import leaderBoardCenter from "assets/images/project_leaderboard_center.png";
import { numberWithCommas } from "utils/thousandSeparator";

const navButtons = [
  { value: "Sale", label: "Sale" },
  { value: "Rental", label: "Rental" },
];

const salesTypeOptions = [
  { value: "unit", label: "Unit" },
  { value: "amount", label: "Amount" },
];

const TopThreeAgents = [
  { id: 1, background: leaderBoardLeft, medal: leaderMedal2 },
  { id: 0, background: leaderBoardCenter, medal: leaderMedal1 },
  { id: 2, background: leaderBoardRight, medal: leaderMedal3 },
];

const SubsalesContent = ({
  commRate,
  fromTeam,
  isAdminView,
  selectedAgent,
  leaderBoardData,
  agentRanking,
  projectSalesContent,
  country_name,
}) => {
  const {
    id,
    booked_info,
    subsaleCancelledUnits,
    rentalCancelledUnits,
    subsaleCancelledUnitsAmount,
    rentalCancelledUnitsAmount,
    subsaleConvertedUnits,
    rentalConvertedUnits,
    subsaleConvertedUnitsAmount,
    rentalConvertedUnitsAmount,
    subsaleAgencyCommissionAmount,
    rentalAgencyCommissionAmount,
  } = selectedAgent;
  const sale = leaderBoardData.find(data => data.some(item => item.Type === "Subsale-Sale"));
  const rental = leaderBoardData.find(data => data.some(item => item.Type === "Subsale-Rental"));

  const [view, setView] = useState("Sale");
  const [salesType, setSalesType] = useState("unit");
  const [rankingView, setRankingView] = useState("sale");
  const [topPerformers, setTopPerformers] = useState(sale);

  useEffect(() => {
    if (rankingView === "sale") {
      setTopPerformers(sale);
    } else {
      setTopPerformers(rental);
    }
  }, [rankingView, sale, rental]);

  const renderCardContent = () => {
    const renderValue = (param) => {
      if (view === "Sale") {
        switch (param) {
          case "Cancelled Units":
            return subsaleCancelledUnits;
          case "Cancelled Units Amount":
            return subsaleCancelledUnitsAmount;
          case "Converted Units":
            return subsaleConvertedUnits;
          case "Converted Units Amount":
            return subsaleConvertedUnitsAmount;
          case "Estimated Booked Unit":
            return booked_info?.sale_booked_units ?? 0;
          case "Estimated Booked Amount":
            return booked_info?.sale_booked_amount ?? 0;
          default:
            return 0;
        }
      } else {
        switch (param) {
          case "Cancelled Units":
            return rentalCancelledUnits;
          case "Cancelled Units Amount":
            return rentalCancelledUnitsAmount;
          case "Converted Units":
            return rentalConvertedUnits;
          case "Converted Units Amount":
            return rentalConvertedUnitsAmount;
          case "Estimated Booked Unit":
            return booked_info?.rent_booked_units ?? 0;
          case "Estimated Booked Amount":
            return booked_info?.rent_booked_amount ?? 0;
          default:
            return 0;
        }
      }
    };

    return (
      <div className="grid-control" style={{ gridGap: 8 }}>
        <div className="grid-half-col d-flex">
          <div
            className={`at-card--stat__general-icon-cont`}
            style={{ backgroundColor: "#14B8A6" }}
          >
            <AtlasIcon svgHref={`atlas-building-3`} />
          </div>
          <div className="at-card--stat__general-category">
            <p>
              {salesType === "unit"
                ? "Converted Unit"
                : "Converted Unit Amount"}
            </p>
            <span>
              {renderValue(
                salesType === "unit"
                  ? "Converted Units"
                  : "Converted Units Amount",
              )}
            </span>
          </div>
        </div>
        <div className="grid-half-col d-flex">
          <div
            className={`at-card--stat__general-icon-cont`}
            style={{ backgroundColor: "#E74694" }}
          >
            <AtlasIcon svgHref={`atlas-forbidden-2`} />
          </div>
          <div className="at-card--stat__general-category">
            <p>
              {salesType === "unit"
                ? "Cancelled Unit"
                : "Cancelled Unit Amount"}
            </p>
            <span>
              {renderValue(
                salesType === "unit"
                  ? "Cancelled Units"
                  : "Cancelled Units Amount",
              )}
            </span>
          </div>
        </div>
        <div className="grid-half-col d-flex">
          <div
            className={`at-card--stat__general-icon-cont`}
            style={{ backgroundColor: "#3B82F6" }}
          >
            <AtlasIcon svgHref={`atlas-bookmark`} />
          </div>
          <div className="at-card--stat__general-category">
            <p>
              {salesType === "unit"
                ? "Estimated Booked Unit"
                : "Estimated Booked Unit Amount"}
            </p>
            <span>
              {renderValue(
                salesType === "unit"
                  ? "Estimated Booked Unit"
                  : "Estimated Booked Amount",
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAgencyCommission = () => (
    <>
      <div
        className={`at-card--stat__general-icon-cont`}
        style={{ backgroundColor: "#F59E0B" }}
      >
        <AtlasIcon svgHref={`atlas-home`} />
      </div>
      <div className="at-card--stat__general-category">
        <p>Total Agency Comm.</p>
        <span>
          {numberWithCommas(
            subsaleAgencyCommissionAmount + rentalAgencyCommissionAmount,
          )}
        </span>
      </div>
    </>
  );

  return (
    <>
      {!commRate && !fromTeam && !isAdminView && (
        <AlertBox
          containerClassName="mb-3"
          title={"No commision rate set."}
          description={
            "You don't have a secondary market commision rate set in Atlas. Please speak to your leader or ask them to contact Helpdesk with details of what your commision rate should be."
          }
        />
      )}
      <div className="at-statistic-subsales-container">
        {!fromTeam && (
          <div
            className="at-form__content py-0 px-2"
            style={{
              gridArea: "1 / 1 / 4 / 2",
              background: "linear-gradient(180deg, #7C3AED 0%, #4C1D95 100%)",
              height: "fit-content",
            }}
          >
            <label
              className="at-statistic_leaderboard-title"
              style={{
                background:
                  "linear-gradient( 270deg, #103DAB00 0%, #4D1D97 50%, #103DAB00 100% )",
              }}
            >
              SUBSALES
            </label>
            <p className="w-100 text-center text-white fs-3 fw-600 mt-3">
              Top Agents
            </p>
            <div className="d-flex justify-content-center my-3">
              <p
                style={{
                  fontSize: 12,
                  color: "white",
                  borderRadius: 25,
                  padding: "4px 8px",
                  backgroundColor: "#5818C6",
                }}
              >
                <AtlasIcon
                  svgHref={`atlas-location`}
                  style={{
                    width: 12,
                    height: 12,
                    fill: "white",
                    marginRight: 5,
                  }}
                />
                {country_name || "N/A"}
              </p>
              <p
                style={{
                  fontSize: 12,
                  color: "white",
                  borderRadius: 25,
                  padding: "4px 8px",
                  marginLeft: 10,
                  backgroundColor: "#5818C6",
                }}
              >
                <AtlasIcon
                  svgHref={`atlas-calendar-2`}
                  style={{
                    width: 12,
                    height: 12,
                    fill: "white",
                    marginRight: 5,
                  }}
                />
                {new Date().getFullYear()}
              </p>
            </div>
            <div
              className="d-flex align-items-center mb-3"
              style={{ backgroundColor: "#8B56E1", borderRadius: 8 }}
            >
              <button
                style={{ width: "50%" }}
                className={`btn-float-purple ${rankingView === "sale" ? "btn-float-purple-selected" : ""}`}
                onClick={() => setRankingView("sale")}
              >
                Sale
              </button>
              <button
                style={{ width: "50%" }}
                className={`btn-float-purple ${rankingView === "rental" ? "btn-float-purple-selected" : ""}`}
                onClick={() => setRankingView("rental")}
              >
                Rental
              </button>
            </div>
            {topPerformers && topPerformers.length > 0 && (
              <div className="at-statisic__leaderboard-bg">
                {TopThreeAgents.map((item, index) => {
                  if ([1,2,3].includes(topPerformers[item.id]?.rank) && index < 3) {
                    return (
                      <div
                        key={`leaderboard-${index}`}
                        className="at-statisic__leaderboard-cont"
                        style={{ backgroundImage: `url( ${item.background} )` }}
                      >
                        <div className="d-flex flex-column">
                          <img
                            className="at-statistic_leaderboard-avatar"
                            src={topPerformers[item.id].avatar_url || defaultAvatar}
                            alt="avatar"
                          />
                          <img
                            src={item.medal}
                            alt="medal-2"
                            style={{ width: 20, transform: "translateY( -10px )" }}
                          />
                          <label>{topPerformers[item.id]?.name ?? "-"}</label>
                        </div>
                      </div>
                    )
                  } else if (![1,2,3].includes(topPerformers[item.id]?.rank) && index < 3) {
                    return (
                      <div
                        key={`leaderboard-${index}`}
                        className="at-statisic__leaderboard-cont"
                        style={{ backgroundImage: `url( ${item.background} )` }}
                      >
                        <div className="d-flex flex-column">
                          <img
                            className="at-statistic_leaderboard-avatar"
                            src={defaultAvatar}
                            alt="avatar"
                          />
                          <img
                            src={item.medal}
                            alt="medal-2"
                            style={{ width: 20, transform: "translateY( -10px )" }}
                          />
                          <label>{"-"}</label>
                        </div>
                      </div>
                    )
                  }
                })}    
              </div>
            )}
            {
              (topPerformers && topPerformers.length > 0) && (
                <div style={{ overflowY: "auto", maxHeight: 500 }}>
                  {topPerformers.map((item, index) => {
                    if (![1,2,3].includes(item.rank)) {
                      return (
                        <div
                          key={`leaderboard-${index + 3}`}
                          className="at-statistic_leaderboard-agent"
                          style={{
                            backgroundColor: item.id === id ? "#F5F3FF" : "white",
                          }}
                        >
                          <img src={item.avatar_url || defaultAvatar} alt="avatar" />
                          <label>{item?.name ?? "-"}</label>
                          <div
                            className="d-flex align-items-center ml-auto text-white fw-600 px-2 py-1"
                            style={{
                              fontSize: 12,
                              background: "#6B7280",
                              borderRadius: 25,
                            }}
                          >
                            <AtlasIcon
                              svgHref={`atlas-medal`}
                              style={{
                                width: 12,
                                height: 12,
                                fill: "white",
                                marginRight: 5,
                              }}
                            />
                            {item.rank}
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              )
            }
          </div>
        )}
        {fromTeam && agentRanking && (
          <div style={{ gridArea: "1 / 1 / 2 / 2" }}>{agentRanking()}</div>
        )}
        <div
          className="at-form__content d-flex flex-column p-0"
          style={{
            gridArea: "1 / 2 / 2 / 3",
            background: `linear-gradient(117deg, #FFD6AD 0%, #F8C4B3 100%)`,
          }}
        >
          <TierContent selectedAgent={selectedAgent} />
        </div>
        {fromTeam && projectSalesContent && (
          <div style={{ gridArea: "2 / 1 / 3 / 2" }}>
            {projectSalesContent()}
          </div>
        )}
        <div
          className="at-form__content"
          style={{ gridArea: "2 / 2 / 3 / 3", height: "fit-content" }}
        >
          <Tabs
            sections={navButtons}
            selectedSection={view}
            onSelectSection={(val) => setView(val)}
          />
          <div className="d-flex align-items-center my-2">
            <h2 className="mb-0" style={{ flex: "1 0 0" }}>
              Subsales Sales {!fromTeam ? `in ${new Date().getFullYear()}` : ""}
            </h2>
            <div
              className="d-flex align-items-center"
              style={{ borderRadius: 8, border: "1px solid #D1D5DB" }}
            >
              {salesTypeOptions.map((item, index) => (
                <button
                  style={{
                    padding: "6px 10px",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: index === 0 ? "8px 0 0 8px" : "0 8px 8px 0",
                    borderLeft: index === 0 ? "none" : "1px solid #D1D5DB",
                    color: salesType === item.value ? "white" : "#1F2937",
                    backgroundColor:
                      salesType === item.value ? "#F0631D" : "white",
                  }}
                  onClick={() => setSalesType(item.value)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          {renderCardContent()}
          {fromTeam && (
            <>
              <hr />
              <div className="d-flex align-items-center">
                {renderAgencyCommission()}
              </div>
            </>
          )}
        </div>
        {!fromTeam && (
          <div
            className="at-form__content d-flex align-items-center"
            style={{ gridArea: "3 / 2 / 4 / 3", height: "fit-content" }}
          >
            {renderAgencyCommission()}
          </div>
        )}
      </div>
    </>
  );
};

export default SubsalesContent;
