import React from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

const FileTypeLibrary = [
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-csv-file"} onClick={onClick} />
    ),
    type: ".csv",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-pdf-file"} onClick={onClick} />
    ),
    type: ".pdf",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-xlsx-file"} onClick={onClick} />
    ),
    type: [".xsl", ".xlsx"],
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-doc-file"} onClick={onClick} />
    ),
    type: ".doc",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-rtf-file"} onClick={onClick} />
    ),
    type: ".rtf",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-ppt-file"} onClick={onClick} />
    ),
    type: ".ppt",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-txt-file"} onClick={onClick} />
    ),
    type: ".txt",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-zip-file"} onClick={onClick} />
    ),
    type: ".zip",
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-image-file"} onClick={onClick} />
    ),
    type: [".jpg", ".jpeg", ".png", ".bmp", ".raw", ".tiff"],
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-video-file"} onClick={onClick} />
    ),
    type: [".mp4", ".mpeg", ".flv", ".mkv"],
  },
  {
    renderIcon: (onClick) => (
      <AtlasIcon svgHref={"atlas-mp3-file"} onClick={onClick} />
    ),
    type: [".mp3", ".aux", ".wav"],
  },
];

const FileIcon = ({ title, onClick }) => {
  let icon = <AtlasIcon svgHref={"atlas-doc-file"} onClick={onClick} />;
  FileTypeLibrary.map((fileType) => {
    if (Array.isArray(fileType.type)) {
      fileType.type.map((fileFormat) => {
        if (title.toLowerCase().endsWith(fileFormat.toString())) {
          icon = fileType.renderIcon(onClick);
        }
      });
    } else {
      if (title.toLowerCase().endsWith(fileType.type.toString())) {
        icon = fileType.renderIcon(onClick);
      }
    }
  });
  return icon;
};

export default FileIcon;
