import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import CustomCard from "components/Card";
import PersonalInfo from "./PersonalInfo";

import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import WithProfileUpdate from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { refreshToken } from "actions/login";
import { compose } from "redux";
import { requestSuccess } from "utils/requestHandler";

import "stylesheets/containers/profile-update/index.scss";

class NameCardUpdate extends Component {
  state = {
    ajaxCallProgress: 0,

    updateAgentInfo: {
      name_card_attributes: {
        id: "",
        name_card_display_name: "",
        additional_display_name: "",
        include_profile_photo: false,
        license_number: "",
        photo: "",
      },
    },

    from: "",
    nameCardDisplayNameError: "",

    disableSubmit: true,
  };

  componentDidMount = () => {
    window.location.href.includes("/admin-impersonate")
      ? this.props.getCurrentSignInProfile()
      : this.props.getCurrentUserProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      ajaxCallProgress,
      ajaxSuccess,
      ajaxCallProgressImpersonate,
      ajaxSuccessImpersonate,
    } = this.props.data.ajaxStatusReducer;

    const isAjaxCallProgressChanged = window.location.href.includes(
      "/admin-impersonate",
    )
      ? ajaxCallProgressImpersonate !==
        prevProps.data.ajaxStatusReducer.ajaxCallProgressImpersonate
      : ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress;

    const isProfileReducerChanged = window.location.href.includes(
      "/admin-impersonate",
    )
      ? prevProps.data.currentSignInProfileReducer !==
        this.props.data.currentSignInProfileReducer
      : prevProps.data.profileReducer !== this.props.data.profileReducer;

    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const tmpAjaxCallProgress = window.location.href.includes(
      "/admin-impersonate",
    )
      ? ajaxCallProgressImpersonate
      : ajaxCallProgress;

    const tmpAjaxSuccess = window.location.href.includes("/admin-impersonate")
      ? ajaxSuccessImpersonate
      : ajaxSuccess;

    if (isAjaxCallProgressChanged) {
      this.props.onChangeProfileHOC(tmpAjaxCallProgress, "loading");

      if (!tmpAjaxCallProgress && tmpAjaxSuccess) {
        if (this.state.from === "submit edited user profile") {
          requestSuccess("Profile updated successfully.");
          this.setState({ from: "" });
        }
      }
    }

    if (prevState.updateAgentInfo !== this.state.updateAgentInfo) {
      if (tmpProfileReducer.role === "Agent") {
        return this.setState({
          disableSubmit: this.state.nameCardDisplayNameError,
        });
      }

      return this.setState({ disableSubmit: this.state.displayNameError });
    }

    if (isProfileReducerChanged) {
      const { name_card = {}, license_number } = window.location.href.includes(
        "/admin-impersonate",
      )
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let temp = _.cloneDeep(this.state.updateAgentInfo);

      temp = {
        ...temp,
        ...(tmpProfileReducer.role !== "Personal Assistant" &&
          name_card && {
            name_card_attributes: {
              id: name_card.id,
              include_profile_photo: name_card.include_profile_photo || false,
              name_card_display_name: name_card.name_card_display_name || "",
              designation: name_card.designation,
              additional_display_name: name_card.additional_display_name || "",
              photo: name_card.photo || "",
              agent_tag_number: name_card.agent_tag_number || "",
              qualification: name_card.qualification || "",
              mobile_number: name_card.mobile_number || "",
              real_estate_type_id: name_card.real_estate_type_id || "",
              license_number: license_number || "",
            },
          }),
      };
      return this.setState({
        updateAgentInfo: temp,
        nameCardDisplayNameError:
          name_card && name_card.name_card_display_name
            ? ""
            : "Please fill in the required field.",
      });
    }
  };

  onChangeUpdateAgent = (val, context, errorContext, childContext) => {
    let temp = _.cloneDeep(this.state.updateAgentInfo);

    if (childContext) {
      temp[context][childContext] = val;
    } else {
      temp[context] = val;
    }

    this.setState({
      updateAgentInfo: temp,
      ...(errorContext && {
        [errorContext]: val ? "" : "Please fill in the required field",
      }),
    });
  };

  submitImagesCheck = (dataToSubmit) => {
    if (
      dataToSubmit.name_card_attributes.photo &&
      dataToSubmit.name_card_attributes.photo.indexOf("base64") < 0
    ) {
      delete dataToSubmit.name_card_attributes.photo;
    }
    this.setState({ from: "submit edited user profile" }, () => {
      this.props.updateCurrentUserProfile(dataToSubmit);
    });
  };

  onClickUpdateProfileInfo = (e) => {
    let dataToSubmit = {
      name_card_attributes: this.state.updateAgentInfo.name_card_attributes,
    };
    this.submitImagesCheck(dataToSubmit);
  };

  render = () => (
    <PersonalInfo
      updateAgentInfo={this.state.updateAgentInfo}
      onChangeState={(key, val) => this.setState({ [key]: val })}
      nameCardDisplayNameError={this.state.nameCardDisplayNameError}
      disableSubmit={this.state.disableSubmit}
      onLoadUpdateProfile={this.props.onLoadUpdateProfile}
      onChangeProfileHOC={this.props.onChangeProfileHOC}
      onClickUpdateProfileInfo={this.onClickUpdateProfileInfo}
      onChangeUpdateAgent={this.onChangeUpdateAgent}
      onClickClose={this.props.onClickClose}
    />
  );
}

const mapStateToProps = (state) => ({ data: state });

export default compose(
  connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
    refreshToken,
  }),
  WithProfileUpdate,
  VersionCheckHOC,
)(NameCardUpdate);
