import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import _ from "lodash";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import MyUploadAdapter from "./UploadAdapter";
import AtlasDialog from "components/Dialog";

const MyCustomUploadAdapterPlugin = (editor) => {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
};

const Create = ({
  onChangeNewsFeedHOC,
  createNewsfeed,
  onLoadNewsfeedManagement,
}) => {
  const [createForm, onChangeCreateForm] = useState({
    title: "",
    content: "",
    youtube_link: "",
    published: false,
  });

  const onChangeNewsfeedData = (key, val) => {
    let tmp = _.cloneDeep(createForm);
    tmp[key] = val;
    return onChangeCreateForm(tmp);
  };

  return (
    <AtlasDialog open={true}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton
                onClick={() => onChangeNewsFeedHOC(false, "showCreateNewsfeed")}
              />
              <h4 slot="header" className="at-card__title mr-3">
                {"New Newsfeed"}
              </h4>
            </div>
            <div className="grid-control">
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Title
                </h2>
                <CustomFormInput
                  type="text"
                  value={createForm.title}
                  required={true}
                  onChangeValue={(val) => {
                    onChangeNewsfeedData("title", val);
                  }}
                  placeholder={"Enter your post title here."}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10">Content</h2>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    extraPlugins: [
                      function (editor) {
                        editor.plugins.get(
                          "FileRepository",
                        ).createUploadAdapter = (loader) => {
                          return new MyCustomUploadAdapterPlugin();
                        };
                      },
                    ],
                    link: {
                      addTargetToExternalLinks: true,
                      decorators: [
                        {
                          mode: "manual",
                          label: "Downloadable",
                          attributes: {
                            download: "download",
                          },
                        },
                      ],
                    },
                  }}
                  data={createForm.content}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    onChangeNewsfeedData("content", data);
                  }}
                  onBlur={(event, editor) => {
                    // console.log( 'Blur.', editor );
                  }}
                  onFocus={(event, editor) => {
                    // console.log( 'Focus.', editor );
                  }}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Youtube Link
                </h2>
                <CustomFormInput
                  type="text"
                  value={createForm.youtube_link}
                  required={true}
                  onChangeValue={(val) =>
                    onChangeNewsfeedData("youtube_link", val)
                  }
                  placeholder={"Enter your post youtube link here."}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mr-10" required>
                  Shareable Link
                </h2>
                <CustomFormInput
                  type="text"
                  value={createForm.shareable_link}
                  required={true}
                  onChangeValue={(val) =>
                    onChangeNewsfeedData("shareable_link", val)
                  }
                  placeholder={"Enter your post shareable link here."}
                />
              </section>
            </div>
            <div className={"d-flex mt-20"}>
              <button
                type={"button"}
                className="btn-new btn-new--success"
                onClick={() => createNewsfeed(createForm)}
              >
                Create
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary ml-2"
                onClick={() => onChangeNewsFeedHOC(false, "showCreateNewsfeed")}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />
      {onLoadNewsfeedManagement && <LoadingModal />}
    </AtlasDialog>
  );
};

export default Create;
