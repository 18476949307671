import React, { Component, Fragment } from "react";
import _ from "lodash";
import { HiChartBar } from "react-icons/hi";

import TableContent from "./component/content";
import ArrowContent from "./component/arrow";
import CustomPagination from "components/Pagination";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/table/index.scss";

const ClientHeaderData = [
  { label: "Client Name", value: "client_name", style: { width: "100%" } },
  {
    label: "Interested Count",
    value: "mark_count",
    className: "at-column-responsive_center",
  },
  {
    label: "Enquiry Count",
    value: "enquiry_count",
    className: "at-column-responsive_center",
  },
];

class Clients extends Component {
  state = {
    selectedSortingIndex: "",
    selectPerPageOption: 50,
    totalPaginationCount: 0,
    totalPages: [1],
    clickPageNumber: 1,
    rowData: [],
    initialRowData: [],
    headerRearrangeData: {},
  };

  componentDidMount = () =>
    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption,
        );
      },
    );

  componentDidUpdate = (pp) => {
    if (pp.rowData !== this.props.rowData && this.props.rowData) {
      this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
        },
        () => {
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption,
          );
        },
      );
    }
  };

  onClickRearrangeData = (val, index) =>
    this.setState({ headerRearrangeData: val }, () => {
      let columnHeader = _.find(ClientHeaderData, ["value", val.columnValue]);
      if (val.columnValue && !columnHeader) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);

              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      } else if (columnHeader) {
        this.setState({
          rowData: _.map(this.state.rowData, (item, itemIndex) => {
            if (itemIndex === index) {
              return {
                ...item,
                clients: _.orderBy(item.clients, [val.columnValue], val.mode),
              };
            }
            return item;
          }),
        });
      }
    });

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  renderPaginationText = () => {
    const { rowData } = this.props;

    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > this.state.rowData.length) {
        pageNumTmp = this.state.rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <span className={"at-presentation-text"}>
        Showing
        <b> {processFloorPaginationCount(this.state.clickPageNumber)} </b>
        to
        <b> {processCeilingPaginationCount(this.state.selectPerPageOption)} </b>
        from a total of
        <b> {rowData ? rowData.length : 0} </b>
        row
      </span>
    );
  };

  renderPagination = () => {
    return (
      <CustomPagination
        className={"at-sm-pagination-section"}
        totalPages={this.state.totalPages}
        chosenPageNumber={this.state.selectPerPageOption}
        onClickPageNumber={(val) => this.onClickPageNumber(val)}
        currentPageNumber={this.state.clickPageNumber}
      />
    );
  };

  render = () => {
    const { headerData } = this.props;
    return (
      <>
        <table
          style={{ width: "100%", borderRadius: "0.5rem" }}
          className="at-presentation-statistic_table"
        >
          <thead className="at-presentation-statistic_table-header_cont">
            <tr className="at-table-row at-table-head">
              {headerData &&
                headerData.map((header, index) => (
                  <td
                    key={index}
                    className={`at-table-row__item at-table__${index} ${header.className || ""}`}
                    style={{ ...(header.style || {}) }}>
                    <div className="d-flex align-items-center">
                      <span className="at-table-head__title">
                        {header.label}
                        <div
                          style={{
                            maxWidth: 0,
                            maxHeight: 0,
                            position: "relative",
                          }}>
                          <ArrowContent
                            columnContent={header.value}
                            onClickRearrangeData={this.onClickRearrangeData}
                          />
                        </div>
                      </span>
                    </div>
                  </td>
                ))}
            </tr>
          </thead>
          <tbody className="at-presentation-statistic_table-content_cont">
            {this.state.rowData.length > 0 &&
              this.state.rowData.map((row, index) => {
                return (
                  <Fragment key={`${index}`}>
                    {index + 1 >
                      (this.state.clickPageNumber - 1) *
                        this.state.selectPerPageOption &&
                      index + 1 <=
                        this.state.clickPageNumber *
                          this.state.selectPerPageOption && (
                        <TableContent
                          index={index}
                          selectedSortingIndex={this.state.selectedSortingIndex}
                          onSelectIndex={(index) =>
                            this.setState({ selectedSortingIndex: index })
                          }
                          rowItem={row}
                          headerData={headerData}
                          ClientHeaderData={ClientHeaderData}
                          headerRearrangeData={this.state.headerRearrangeData}
                          onClickRearrangeData={(val) =>
                            this.onClickRearrangeData(val, index)
                          }
                        />
                      )}
                  </Fragment>
                );
              })}
            {!(this.state.rowData.length > 0) && (
              <EmptyState
                title={`No data presence`}
                description={""}
                renderIcon={
                  <AtlasIcon
                    style={{ width: 25, height: 25 }}
                    svgHref="atlas-chart-1"
                  />
                }
              />
            )}
          </tbody>
        </table>
        {this.state.rowData.length > 0 && (
          <div className="at-pagination__cont">
            {this.renderPagination()}
            {this.renderPaginationText()}
          </div>
        )}
      </>
    );
  };
}

export default Clients;
