import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Grid } from "@material-ui/core";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import InputSteppers from "components/InputSteppers";
import ModalDialog from "components/Modal/ModalDialog";
import CustomConfirmationDialog from "components/Modal/confirmation";
import EventDetailsContent from "./eventDetailsContent";

import defaultImage from "assets/images/iqi_logo_only.png";

import "../index.scss";
import AtlasIcon from "components/Icon/atlasIcon";

class NameCardDialog extends Component {
  state = {
    quantity: 1,
    orderItem: {
      id: "",
      title: "",
      price: null,
    },
  };

  componentDidMount = () => {
    if (this.props.selectedProduct.has_variants) {
      this.setState({
        orderItem: {
          id: this.props.selectedProduct.product_variants[0].id,
          title: this.props.selectedProduct.product_variants[0].title,
          price: this.props.selectedProduct.product_variants[0].price,
        },
      });
    } else if (
      this.props.selectedProduct.attached_product_type !== "TicketType"
    ) {
      this.setState({ orderItem: this.props.selectedProduct });
    }

    const { ticket_pricings = [] } =
      this.props.selectedProduct?.attached_product || {};
    if (ticket_pricings && ticket_pricings.length === 1) {
      const ticketItem = ticket_pricings[0];
      this.setState({
        orderItem: {
          id: ticketItem.id,
          title: ticketItem.title,
          price: ticketItem.price,
        },
      });
    }
  };

  renderDialogContent = () => {
    const { onChangeProductCatalogHOC, selectedProduct } = this.props;

    const {
      photo_url = "",
      name = "",
      description = "",
      attached_product = {},
      attached_product_type,
      id,
      has_variants,
      product_variants,
      unit_measurement = "unit",
      price = "0.00",
      is_quantity_fixed,
      type_id,
    } = selectedProduct;

    const disabledAddToCart =
      attached_product?.["is_sold_out?"] ||
      Moment(attached_product?.event.cut_off_date).endOf("d").unix() <
        Moment().endOf("d").unix() ||
      (attached_product &&
        _.values(attached_product?.ticket_pricings).every(
          (item) => !item.still_selling,
        )) ||
      !_.isNumber(this.state.orderItem.id);

    return (
      <>
        <Grid container spacing={16} style={{ marginTop: 10 }}>
          <Grid item sm={4} xs={12}>
            <img
              className={"bg-white bd-rd-5 border-white"}
              style={{
                objectFit: "fit",
                height: "fit-content",
                width: "100%",
                marginBottom: 4,
              }}
              src={photo_url ? photo_url : defaultImage}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <CustomCard
              className={"h-100 bg-white p-0"}
              cardContent={
                <>
                  <h4 className="at-table_card-title">{`${name}`}</h4>
                  <p style={{ fontSize: 14, color: "#374151" }}>
                    {description}
                  </p>
                  <hr />
                  {attached_product_type === "TicketType" && (
                    <>
                      <EventDetailsContent selectedProduct={selectedProduct} />
                      <p className={"at-table_card-title"}>
                        Choose your ticket
                      </p>
                      {attached_product?.ticket_pricings.map((item, index) => {
                        if (item.still_selling) {
                          return (
                            <button
                              key={index}
                              className={`at-variation-box mr-10 ${item.id === this.state.orderItem.id ? "selected" : ""}`}
                              disabled={
                                item["is_sold_out?"] ||
                                Moment(
                                  attached_product?.event.cut_off_date,
                                ).unix() < Moment().unix()
                              }
                              onClick={() =>
                                this.setState({
                                  orderItem: {
                                    id: item.id,
                                    title: item.title,
                                    price: item.price,
                                  },
                                })
                              }
                            >
                              <p style={{ fontSize: 14, fontWeight: 500 }}>
                                {item.title}
                              </p>
                              {item["is_sold_out?"] && (
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: "red",
                                  }}
                                >
                                  Sold Out
                                </p>
                              )}
                              <p
                                style={{ fontSize: 14, color: "#C2410C" }}
                              >{`RM ${item.price.toFixed(2)}`}</p>
                            </button>
                          );
                        }
                      })}
                    </>
                  )}
                  {attached_product_type !== "TicketType" && (
                    <>
                      <p className={"at-table_card-title"}>Choose variation</p>
                      {has_variants && (
                        <div>
                          {product_variants.map((item, index) => (
                            <button
                              key={`product-variant-${index}`}
                              className={`at-variation-box mr-10 ${item.id === this.state.orderItem.id ? "selected" : ""}`}
                              disabled={
                                item["is_sold_out?"] ||
                                Moment(attached_product?.event.cut_off_date)
                                  .endOf("d")
                                  .unix() < Moment().endOf("d").unix()
                              }
                              onClick={() =>
                                this.setState({
                                  orderItem: {
                                    id: item.id,
                                    price: item.price,
                                    name: item.title,
                                    options: item.options,
                                  },
                                })
                              }
                            >
                              <p style={{ fontSize: 14, fontWeight: 500 }}>
                                {item.title}
                              </p>
                              <p
                                style={{ fontSize: 14, color: "#C2410C" }}
                              >{`RM ${item.price.toFixed(2)}`}</p>
                            </button>
                          ))}
                        </div>
                      )}
                      {!has_variants && (
                        <button
                          className={`at-variation-box mr-10 ${id === this.state.orderItem.id ? "selected" : ""}`}
                          onClick={() =>
                            this.setState({
                              orderItem: {
                                id: id,
                                price: price,
                                name: name,
                              },
                            })
                          }
                        >
                          <p style={{ fontSize: 14, fontWeight: 500 }}>
                            {`1 ${!_.isEmpty(unit_measurement) ? unit_measurement : "unit"}`}
                            {unit_measurement === "box" && ` (100pcs)`}
                          </p>
                          <p
                            style={{ fontSize: 14, color: "#C2410C" }}
                          >{`RM ${price.toFixed(2)}`}</p>
                        </button>
                      )}
                    </>
                  )}
                  <div className={"add-to-cart-action"}>
                    {!is_quantity_fixed && id && (
                      <div className={"d-flex flex-row align-items-center"}>
                        <InputSteppers
                          itemId={id}
                          containerStyle={{
                            borderRadius: 8,
                            width: 120,
                            border: "1px solid #6B7280",
                          }}
                          inputStyle={{ fontSize: 12 }}
                          value={this.state.quantity}
                          buttonClassName={"btn-new at-product__quantity-btn"}
                          negativeButtonContent={
                            <AtlasIcon svgHref={"atlas-minus-linear"} />
                          }
                          positiveButtonContent={
                            <AtlasIcon svgHref={"atlas-add-linear"} />
                          }
                          onChangeInputValue={(val) =>
                            this.setState({ quantity: val.quantity })
                          }
                          onClickPositiveButton={(val) =>
                            this.setState({ quantity: val.quantity })
                          }
                          onClickNegativeButton={(val) =>
                            this.setState({ quantity: val.quantity })
                          }
                        />
                      </div>
                    )}
                    <button
                      disabled={disabledAddToCart}
                      onClick={() => {
                        if ([1, 2].indexOf(type_id) > 0) {
                          onChangeProductCatalogHOC(
                            true,
                            "showAddToCartConfirmation",
                          );
                        } else {
                          this.props.addToCart(
                            this.props.selectedProduct,
                            this.state.quantity,
                            this.state.orderItem,
                          );
                          this.props.onChangeProductCatalogHOC(
                            false,
                            "showAddToCartConfirmation",
                          );
                        }
                      }}
                      className={"btn-new btn-new--primary mr-10"}
                    >
                      Add to Cart
                    </button>
                  </div>
                </>
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModalDialog
          title={"Product Details"}
          fullWidth={true}
          fullHeight={true}
          onClose={() =>
            this.props.onChangeProductCatalogHOC(false, "showNameCardModal")
          }
          children={this.renderDialogContent()}
        />
        <CustomConfirmationDialog
          open={this.props.showAddToCartConfirmation}
          loading={this.props.onLoadProductCatalog}
          message={`Please ensure that all name card information is correct before adding it to cart. Are you sure you want to proceed now?`}
          mode="warning"
          title={"Friendly Reminder"}
          positiveAction={() => {
            this.props.addToCart(
              this.props.selectedProduct,
              this.state.quantity,
              this.state.orderItem,
            );
            this.props.onChangeProductCatalogHOC(
              false,
              "showAddToCartConfirmation",
            );
          }}
          negativeAction={() =>
            this.props.onChangeProductCatalogHOC(
              false,
              "showAddToCartConfirmation",
            )
          }
        />
        {this.props.onLoadProductCatalog && <LoadingModal />}
      </>
    );
  };
}

export default NameCardDialog;
