import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      newAnnouncement: {
        title: "",
        published: false,
        content: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeNewAnnouncementHOC = (val, context) =>
      this.setState({ [context]: val });

    createAnnouncement = (dataToSubmit) =>
      Post(
        `/admin/announcements/`,
        dataToSubmit,
        this.createAnnouncementSuccess,
        this.createAnnouncementError,
        this.load,
      );
    createAnnouncementSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.adminGetAnnouncements(currentPage, searchParams);

      requestSuccess("New announcement created successfully.");
      this.props.onChangeAnnouncementHOC(false, "showCreateModal");
    };
    createAnnouncementError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadNewAnnouncement={this.state.loading}
            newAnnouncement={this.state.newAnnouncement}
            createAnnouncement={this.createAnnouncement}
            onChangeNewAnnouncementHOC={this.onChangeNewAnnouncementHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
