import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Moment from "moment";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IoMdCloseCircle, IoMdAlert } from "react-icons/io";
import { FaArrowCircleDown } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import IOSButton from "components/Button/toggle";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation";
import GenerateDescriptionModal from "../../../components/GenerateDescription";
import SubsalesTooltip from "../../../components/tooltip";
import CobrokeInfo from "./components/Cobroke";
import BasicInfo from "./components/BasicInfo";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import useOnScreen from "utils/hooks/useOnScreen";
import {
  categories,
  bathroom_options,
  bedroom_options,
  car_park_options,
  measurements,
  tenure_types,
  title_types,
  unit_types,
  furnishing_statuses,
  directions,
  occupancies,
  statuses,
  facilties
} from "dictionary/subsales";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const Details = (props) => {
  const {
    subsalesEntryCreationProps,
    isPublishedIQI,
    isPublishedJuwai,
    isPublishedEdge,
    descriptionType,
    publishListing,
    onChangeEditHOC,
    data,
    processUserSubsales,
    onCheckCheckboxValue,
    otherFacilities,
    onChangeValue,
    description,
    zh_description,
    availableDate,
    selectedStatus,
    publishIProperty,
    getLocalised,
    renderSpecialMessage,
    onChangeDetailsHOC,
    onClickUpdateDetails,
    onChangeSubsalesHOC,
  } = props;
  const { countries, currencies } = data.dictionaryReducer;
  const { can_update } = permissionsChecker("Listing Centre", data);
  const topSection = useRef();
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const onScreen = useOnScreen(topSection);

  const [tempStatus, setTempStatus] = useState(null);
  // store status before a confirmation by user.
  const { can_update: cobrokeUpdatePermission } = permissionsChecker(
    "Co Broke Center",
    data,
  );
  const [RTEMounted, setRTEMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRTEMounted(true);
    }, 1000);

    return () => {
      setRTEMounted(false);
    };
  }, []);

  useEffect(() => {
    processUserSubsales();
  }, [subsalesEntryCreationProps]);

  const onClickTop = () => {
    document
      .getElementById(`top-section`)
      .scrollIntoView({ behavior: "smooth", block: "bottom" });
  };

  const onClickBottom = () => {
    if (window.innerWidth > 992) {
      document
        .getElementById(`description-section`)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      document
        .getElementById(`partner-section`)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderChineseDesc = () => {
    return (
      renderSpecialMessage(getLocalised(
        "label.details.helptext.description.message_ch", 
        `Please take note that the following Chinese description is only
        applicable and will be shown in listings exported to juwai.com and will
        not apply to listings published in iqiglobal.com. There are three ways
        to export the Chinese description of your listing’s to Juwai. The first
        method will be prioritised, followed by the second, and third.


        The 2 methods are as follows:
        1. You can opt to write your own Chinese description for the listing
        which will overwrite the existing Chinese description when exporting
        your listing to Juwai.
        2. You may choose to translate your listings English description into
        the Chinese language via professional translation services provided by
        Juwai, on condition that you have subscribed to any of the Juwai
        packages. Should you wish to choose this method, you may leave the
        Chinese description blank.`
    )));
  }

  const renderEnglishDesc = () => {
    return (
      <p>
        {getLocalised(
          "label.details.helptext.description.message_en", 
          `Please write the description of your listing in English here. The
          listing will not be imported into Juwai if there are any Chinese
          characters in the description. Please use the Chinese description
          segment, if you wish to key in Chinese characters for your listing.`
        )}
      </p>
    );
  };

  const renderHiddenDesc = () => {
    return (
      <p>
        {renderSpecialMessage(getLocalised("label.details.helptext.description.message_hidden", 
          `This section is only visible to you, it will not appear on Co-Broke Centre, IQI Web, Juwai platform or any other partner platforms.
          <br />
          You can put your personal notes about this listing here.`
        ))}
      </p>
    );
  };

  const renderDesc = (type) => {
    switch (type) {
      case "English":
        return renderEnglishDesc();
      case "Chinese":
        return renderChineseDesc();
      case "Hidden":
        return renderHiddenDesc();
    }
  };

  const renderInfo = () => {
    return (
      <>
        <div className="mb-3" id="top-section" ref={topSection}>
          <p className="fs-2">
            {renderSpecialMessage(
                getLocalised(
                  "label.details.details.main_message", 
                  `Move your mouse pointer over the {icon_1} or {icon_2} or {icon_3} or {icon_4} 
                  beside the fields below to see how your input will reflect on the Subsales listing 
                  on both www.iqiglobal.com and www.juwai.com respectively.
                  <br/>
                  To see examples of how your listing will be published on the site, click on the buttons below.`
                ), 
                "icon"
              )
            }
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("IQI", "listingPreviewType");
              }}
            >
              <img style={{ width: 25, margin: "0px 5px" }} src={iqiLogo} />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("JUWAI", "listingPreviewType");
              }}
            >
              <img style={{ width: 25, margin: "0px 5px" }} src={juwaiLogo} />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("JUWAIASIA", "listingPreviewType");
              }}
            >
              <img
                style={{ width: 25, margin: "0px 5px" }}
                src={juwaiAsiaLogo}
              />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("EDGE", "listingPreviewType");
              }}
            >
              <img
                style={{ width: 25, margin: "0px 5px", borderRadius: "50%" }}
                src={edgePropLogo}
              />
            </a>
          </p>
        </div>
        <div className="row" style={{ padding: "0px 4px" }}>
          <div className="col-lg-8">
            <BasicInfo
              can_update={can_update}
              unit_types={unit_types}
              countries={countries}
              categories={categories}
              measurements={measurements}
              currencies={currencies ? currencies.name : []}
              tenure_types={tenure_types}
              title_types={title_types}
              furnishing_statuses={furnishing_statuses}
              directions={directions}
              occupancies={occupancies}
              bedroom_options={bedroom_options}
              bathroom_options={bathroom_options}
              car_park_options={car_park_options}
              {...props}
            />
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-weight"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.facilities.title", "Facilities")}
              </div>
              <div className="grid-control">
                <section className="grid-full-col">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h2 className="at-form-input__title">{getLocalised("label.details.facilities.title", "Facilities")}</h2>
                    <SubsalesTooltip
                      data={{
                        showToolTip: true,
                        showJuwaiToolTip: true,
                        showJuwaiAsiaToolTip: true,
                        tooltip: "facilities",
                      }}
                    />
                  </div>
                </section>
                {facilties.map((item, index) => (
                  <section key={index} className="grid-third-col">
                    <CustomCheckbox
                      getLocalised={getLocalised}
                      disabled={!can_update}
                      checked={props[item.props] || false}
                      content={item.name}
                      translation={item.key}
                      onChangeCheckboxValue={() =>
                        onCheckCheckboxValue(item.name)
                      }
                    />
                  </section>
                ))}
                <section className="grid-full-col mt-10">
                  <h2 className="at-form-input__title">{getLocalised("label.details.facilities.other_facilities", "Other Facilities")}</h2>
                  <CustomFormInput
                    type="text"
                    disabled={!can_update}
                    value={otherFacilities}
                    onChangeValue={(val) =>
                      onChangeValue("Other Facilities", val)
                    }
                    required={false}
                    placeholder={getLocalised("label.details.placeholder.facilities.other_facilities", "Enter your facilities here")}
                    />
                </section>
              </div>
            </div>

            <div className="at-form__content" id="description-section">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-message-text-1"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.description.title", "Description")}
              </div>
              <div className="at-listing__description-tab-cont">
                <button
                  className={
                    descriptionType === "English"
                      ? "at-listing__description-selected-tab"
                      : ""
                  }
                  onClick={() => onChangeEditHOC("English", "descriptionType")}
                >
                  {getLocalised("label.details.tab.description.en", "English")}
                </button>
                <button
                  className={
                    descriptionType === "Chinese"
                      ? "at-listing__description-selected-tab"
                      : ""
                  }
                  onClick={() => onChangeEditHOC("Chinese", "descriptionType")}
                >
                  {getLocalised("label.details.tab.description.zh", "Chinese")}
                </button>
                <button
                  className={
                    props.descriptionType === "Hidden"
                      ? "at-listing__description-selected-tab"
                      : ""
                  }
                  onClick={() =>
                    props.onChangeEditHOC("Hidden", "descriptionType")
                  }
                >
                  {getLocalised("label.details.tab.description.hidden", "Hidden")}
                </button>
              </div>
              {(props.descriptionType === "English" ||
                props.descriptionType === "Chinese") && (
                <div className="d-flex align-items-center">
                  <h2 className="at-form-input__title">
                    {props.descriptionType === "English"
                      ? getLocalised("label.details.description.subtitle_1_en", "Property Description in English")
                      : getLocalised("label.details.description.subtitle_1_zh", "Property Description in Chinese")}
                  </h2>
                  <SubsalesTooltip
                    data={{
                      showToolTip: true,
                      showJuwaiAsiaToolTip: true,
                      showJuwaiToolTip: true,
                      showEdgeTooltip: true,
                      tooltip: "description",
                    }}
                  />
                </div>
              )}
              {(props.descriptionType === "English" ||
                props.descriptionType === "Chinese") && (
                  <p className="fs-2 my-2">
                    {props.descriptionType === "English"
                      ? getLocalised("label.details.description.subtitle_2_en", "Please write the description of your listing in English here.")
                      : getLocalised("label.details.description.subtitle_2_zh", "Please write the description of your listing in Chinese here.")}
                  </p>
              )}
              <AlertBox
                mode={"info"}
                containerStyle={{ marginBottom: "8px" }}
                description={renderDesc(props.descriptionType)}
              />
              {(props.descriptionType === "English" ||
                props.descriptionType === "Chinese") && (
                <CustomButton
                  className={"btn-new btn-new--outline-secondary mb-2"}
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-ai-generation"}
                        style={{ width: 16, height: 16, fill: "#3B82F6" }}
                      />
                      {getLocalised("label.details.description.generate_description", "Generate Description")}
                    </>
                  }
                  onClick={() =>
                    props.onChangeEditHOC(true, "showGenerateModal")
                  }
                />
              )}

              {RTEMounted && descriptionType === "English" && (
                <CKEditor
                  // onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                  editor={ClassicEditor}
                  data={description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (can_update) {
                      onChangeValue("Description", data);
                    } else {
                      onChangeValue("Description", "");
                    }
                  }}
                  config={{
                    toolbar: [],
                    mediaEmbed: {
                      previewsInData: true,
                      providers: [
                        {
                          name: "youtube",
                          url: [
                            /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                            /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                            /^youtube\.com\/embed\/([\w-]+)/,
                            /^youtu\.be\/([\w-]+)/,
                          ],
                          html: (match) => {
                            const id = match[1];

                            return (
                              '<div style="aspect-ratio: 16/9;">' +
                              `<iframe src="https://www.youtube.com/embed/${id}" ` +
                              'style="position: relative; width: 100%; height: 100%;" ' +
                              'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                              "</iframe>" +
                              "</div>"
                            );
                          },
                        },
                      ],
                    },
                  }}
                  disabled={!can_update}
                />
              )}
              {RTEMounted && descriptionType === "Chinese" && (
                <CKEditor
                  editor={ClassicEditor}
                  data={zh_description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (can_update) {
                      onChangeValue("Chinese Description", data);
                    } else {
                      onChangeValue("Chinese Description", "");
                    }
                  }}
                  config={{
                    toolbar: [],
                    mediaEmbed: {
                      previewsInData: true,
                      providers: [
                        {
                          name: "youtube",
                          url: [
                            /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                            /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                            /^youtube\.com\/embed\/([\w-]+)/,
                            /^youtu\.be\/([\w-]+)/,
                          ],
                          html: (match) => {
                            const id = match[1];

                            return (
                              '<div style="aspect-ratio: 16/9;">' +
                              `<iframe src="https://www.youtube.com/embed/${id}" ` +
                              'style="position: relative; width: 100%; height: 100%;" ' +
                              'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                              "</iframe>" +
                              "</div>"
                            );
                          },
                        },
                      ],
                    },
                  }}
                />
              )}
              {RTEMounted && props.descriptionType === "Hidden" && (
                <CKEditor
                  editor={ClassicEditor}
                  data={props.hidden_description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (can_update) {
                      props.onChangeValue("Hidden Description", data);
                    } else {
                      props.onChangeValue("Hidden Description", "");
                    }
                  }}
                  config={{
                    toolbar: [],
                    mediaEmbed: {
                      previewsInData: true,
                      providers: [
                        {
                          name: "youtube",
                          url: [
                            /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                            /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                            /^youtube\.com\/embed\/([\w-]+)/,
                            /^youtu\.be\/([\w-]+)/,
                          ],
                          html: (match) => {
                            const id = match[1];

                            return (
                              '<div style="aspect-ratio: 16/9;">' +
                              `<iframe src="https://www.youtube.com/embed/${id}" ` +
                              'style="position: relative; width: 100%; height: 100%;" ' +
                              'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                              "</iframe>" +
                              "</div>"
                            );
                          },
                        },
                      ],
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-send-2"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.status.title", "Status")}
              </div>
              <div className="grid-control">
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{getLocalised("label.details.status.available_date", "Available Date")}</h2>
                  {can_update && (
                    <div className="at-form-input">
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        showYearDropdown={true}
                        className="at-form-input__input"
                        placeholderText={getLocalised("label.details.placeholder.status.available_date", "Select available date")}
                        value={availableDate || null}
                        onChange={(val) =>
                          onChangeDetailsHOC(
                            Moment(val).format("YYYY-MM-DD"),
                            "availableDate",
                          )
                        }
                      />
                    </div>
                  )}
                  {!can_update && (
                    <p className="fs-2">
                      {Moment(availableDate).format("DD/MM/YYYY")}
                    </p>
                  )}
                </section>
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{getLocalised("label.details.status.status", "Status")}</h2>
                  <AtlasRadioGroup
                    mode="old"
                    valueKey={"id"}
                    checkedValue={selectedStatus}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => {
                      selectedStatus === 1
                        ? setTempStatus(val)
                        : onChangeValue("Status", val);
                    }}
                    renderLabel={(val, label) => (
                      <>
                        <span>{label}</span>
                        {val === 4 && (
                          <Tooltip
                            placement={"top"}
                            title={getLocalised("label.details.helptext.status.delete", 
                              "Click this only if you are absolutely sure. Once updated, you are unable to change the status of this listing anymore."
                            )}
                          >
                            <IoMdAlert
                              className={"color-danger"}
                              style={{ width: 20, height: 20, marginLeft: 10 }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    options={_.filter(statuses, (item) => item.id !== 5)}
                    getLocalised={getLocalised}
                  />
                </section>
              </div>
            </div>

            {cobrokeUpdatePermission && (
              <CobrokeInfo can_update={can_update} {...props} />
            )}

            <div className="at-form__content" id="partner-section">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-global"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.partner_platform.title", "Partner Platform")}
                </div>
              {selectedStatus !== 1 && (
                <AlertBox
                  mode={"warning"}
                  containerStyle={{ marginBottom: "8px" }}
                  description= {getLocalised(
                    "label.details.helptext.partner_platform.message", 
                    "Please set the status to Active and save unsaved changes before clicking on the toggle below."
                  )}
                />
              )}
              <div>
                <IOSButton
                  className={"at-toggle__secondary mr-2"}
                  disabled={!can_update}
                  currentState={!isPublishedIQI}
                  onToggleButton={() =>
                    publishListing(
                      subsalesEntryCreationProps.id,
                      1,
                      !isPublishedIQI,
                    )
                  }
                />
                <div
                  style={{ width: 20, height: 20, marginRight: 10 }}
                  className={"svg-icon-info"}
                  children={<img src={iqiLogo} />}
                />
                <span className="at-form-input__title">IQI Website</span>
              </div>
              <div>
                <IOSButton
                  className={"at-toggle__secondary mr-2"}
                  disabled={!can_update}
                  currentState={!isPublishedJuwai}
                  onToggleButton={() =>
                    publishListing(
                      subsalesEntryCreationProps.id,
                      2,
                      !isPublishedJuwai,
                    )
                  }
                />
                <div
                  style={{ width: 20, height: 20, marginRight: 10 }}
                  className={"svg-icon-info"}
                  children={<img src={juwaiLogo} />}
                />
                <span className="at-form-input__title">Juwai Website</span>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  className={"svg-icon-info"}
                  children={<img src={juwaiAsiaLogo} />}
                />
                <span className="at-form-input__title">Juwai Asia</span>
              </div>
              {subsalesEntryCreationProps.country_id === 1 && (
                <div>
                  <IOSButton
                    className={"at-toggle__secondary mr-2"}
                    disabled={!can_update}
                    currentState={!isPublishedEdge}
                    onToggleButton={() =>
                      publishListing(
                        subsalesEntryCreationProps.id,
                        4,
                        !isPublishedEdge,
                      )
                    }
                  />
                  <div
                    style={{ width: 20, height: 20, marginRight: 10 }}
                    className={"svg-icon-info"}
                    children={
                      <img src={edgePropLogo} style={{ borderRadius: "50%" }} />
                    }
                  />
                  <span className="at-form-input__title">Edge Property</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderIPropertyConfrimation = () => {
    const {
      showPublishIPropertyConfirmation,
      onLoadSubsales,
      subsalesEntryCreationProps,
    } = props;
    return (
      <ConfirmationModal
        open={showPublishIPropertyConfirmation}
        message={
          "Publishing this listing to IProperty will consume your credits in IProperty subscription, Are you sure you want to proceed?"
        }
        mode={"alert"}
        loading={onLoadSubsales}
        positiveAction={() => publishIProperty(subsalesEntryCreationProps.id)}
        negativeAction={() =>
          onChangeSubsalesHOC(false, "showPublishIPropertyConfirmation")
        }
        getLocalised={getLocalised}
      />
    );
  };

  const renderStatusUpdate = (val) => {
    return (
      <ConfirmationModal
        maxWidth={"md"}
        mode={"alert"}
        open={tempStatus}
        message={
          <>
            <p>
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_1", "You are about to change status of an <strong>active</strong> listing. The following will occur:"))}
            </p>
            <p className={"mt-3"}>
              <IoMdCloseCircle
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_2", "All Co-broke agreements for this listing will be set to <strong>No Longer Available</strong>."))}
            </p>
            <p className={"mt-2"}>
              <IoMdAlert
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {getLocalised("label.details.status.delete.confirmation_3", "A notification will be sent to any existing Co-broke agents for this listing.")}
            </p>
            <p className={"mt-2"}>
              <IoMdAlert
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {getLocalised("label.details.status.delete.confirmation_4", "This listing will not show up on IQI Global, Juwai.com etc.")}
            </p>
          </>
        }
        positiveAction={() => {
          onChangeValue("Status", tempStatus);
          setTempStatus(null);
        }}
        negativeAction={() => setTempStatus(null)}
        getLocalised={getLocalised}
      />
    );
  };

  const renderScrollToPartner = () => {
    return (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        aria-label={"action-button"}
        placement={"top"}
        title={onScreen 
          ? getTranslation("scroll_to_bottom", selectedLocalised) 
          : getTranslation("scroll_to_top", selectedLocalised)
        }
      >
        <button
          className="at-listing__partner-platform-btn"
          style={{
            transform: onScreen ? "rotate( 0deg )" : "rotate( 180deg )",
          }}
          onClick={() => (onScreen ? onClickBottom() : onClickTop())}
        >
          <FaArrowCircleDown />
        </button>
      </DarkTooltip>
    );
  };

  const { onLoadSubsales, onLoadSubsalesEdit, showGenerateModal } = props;

  return (
    <div className="subsales-details">
      {renderInfo()}
      {renderIPropertyConfrimation()}
      {renderStatusUpdate()}
      {renderScrollToPartner()}
      <div className="at-modal_dialog-container-footer g-3">
        <button
          disabled={!can_update}
          className="btn-new btn-new--success"
          onClick={() => onClickUpdateDetails()}
        >
          {getTranslation("update", selectedLocalised)}
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeSubsalesHOC(false, "showEditModal")}
        >
          {getTranslation("close", selectedLocalised)}
        </button>
      </div>
      {showGenerateModal && (
        <GenerateDescriptionModal
          {...props}
          language={descriptionType}
          onChangeHOC={onChangeEditHOC}
          agentInfo={subsalesEntryCreationProps.agent_info}
        />
      )}
      {(onLoadSubsales || onLoadSubsalesEdit) && <LoadingModal />}
    </div>
  );
};

export default Details;
