import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ImYoutube } from 'react-icons/im';

import AtlasButton from 'components/Button';
import AtlasFormInput from 'components/Input/formInput';
import AtlasTextarea from 'components/Input/textarea';
import ModalDialog from 'components/Modal/ModalDialog';
import AtlasRadioGroup from 'components/RadioGroup';
import AtlasSelect from 'components/Select';
import AtlasTypeahead from 'components/Typeahead/new';
import AtlasUpload from 'components/Upload';

import isEmptyValue from 'utils/isEmpty';
import { inputFields } from './assets';

const UpdateVideoTemplate = ({
  onLoadVideoTemplates,
  onLoadProjectList,
  showUpdateVideoTemplate,
  selectedProject,
  projectList,
  data,
  can_update,
  selectedVideoTemplate,
  onChangeVideoTemplatesHOC,
  getProjectListing,
  getSelectedProject,
  updateVideoTemplate
}) => {
  const [values, setValues] = useState({
    type_id: 1,
    project_id: '',
    unit_type: '',
    title: '',
    description: '',
    country_id: '',
    status_id: 1,
    video: '',
    video_file_name: '',
    thumbnail: '',
    thumbnail_file_name: ''
  });
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (showUpdateVideoTemplate) {
      setValues(selectedVideoTemplate);
      setVideoUrl(selectedVideoTemplate.url);

      if (selectedVideoTemplate.project_id) {
        getSelectedProject(selectedVideoTemplate.project_id);
      }
    }
  }, [showUpdateVideoTemplate]);

  useEffect(() => {
    if (selectedVideoTemplate.type_id === 1) {
      setValues(values => ({
        ...values,
        country_id: !isEmptyValue(selectedProject)
          ? selectedProject.country_id
          : ''
      }));
    }
  }, [selectedProject]);

  const countries = data.dictionaryReducer?.countries;
  const visibleInputFields = inputFields(
    selectedVideoTemplate.version,
    'Update',
    values.type_id
  );

  const isInputDisabled = item => !can_update || item.disabled;

  const isButtonDisabled = () => {
    const requiredFields = ['project_id', 'unit_type', 'title', 'country_id', 'video'];

    if (values.type_id === 1) {
      return requiredFields.some(field => values[field] === '');
    } else {
      return requiredFields.slice(2).some(field => values[field] === '');
    }
  };

  const handleClose = async () => {
    await onChangeVideoTemplatesHOC('showUpdateVideoTemplate', false);
    await onChangeVideoTemplatesHOC('projectList', []);
    await onChangeVideoTemplatesHOC('selectedProject', {});
  };

  const handleChange = (key, val) => {
    setValues(values => ({ ...values, [key]: val }));
  };

  const handleTypeaheadChange = _.debounce(val => {
    onChangeVideoTemplatesHOC('selectedProject', {});

    return val.length > 0
      ? getProjectListing(val)
      : onChangeVideoTemplatesHOC('projectList', []);
  }, 1000);

  const uploadProps = item => {
    if (item.type === 'video') {
      return {
        videoUrl,
        uploadVideo: async (file, videoUrl) => {
          setValues(values => ({
            ...values,
            video: file,
            video_file_name: file.name
          }));
          setVideoUrl(videoUrl);
        }
      };
    } else {
      return {
        imageUrl: values[item.value] || values.thumbnail_url,
        uploadImage: (imageUrl, fileName) => {
          setValues(values => ({
            ...values,
            thumbnail: imageUrl,
            thumbnail_file_name: fileName,
            thumbnail_url:
              imageUrl === '' && fileName === '' ? '' : values.thumbnail_url
          }));
        }
      };
    }
  };

  const renderFooter = () => (
    <div className='d-flex'>
      <AtlasButton
        className='btn-new btn-new--success mr-10'
        onClick={() => updateVideoTemplate(values)}
        disabled={isButtonDisabled()}
      >
        Update
      </AtlasButton>
      <AtlasButton
        className='btn-new btn-new--outline-secondary'
        onClick={handleClose}
      >
        Close
      </AtlasButton>
    </div>
  );

  return (
    <ModalDialog
      title='Edit Video Template'
      fullWidth
      fullHeight
      responsiveSize=''
      contentClassName='at-video-templates__dialog-content'
      onClose={handleClose}
      onLoad={onLoadVideoTemplates}
      footer={renderFooter()}
    >
      <div className='form-row'>
        <div className='col-sm-12'>
          <label className='at-form-input__title'>Video Type</label>
          <p>{values.type_id === 1 ? 'Project' : 'General'}</p>
        </div>
        {visibleInputFields
          .filter(
            item => !item.hidden && !['video', 'thumbnail'].includes(item.value)
          )
          .map(item => (
            <div
              key={item.value}
              className={item.className || 'col-lg-4 col-sm-6'}
            >
              <label className='at-form-input__title' required={item.required}>
                {item.label}
              </label>
              {item.type === 'text' && (
                <AtlasFormInput
                  className='mb-0'
                  value={
                    item.value === 'country_id'
                      ? countries?.find(({ id }) => id === values[item.value])
                          ?.name
                      : values[item.value]
                  }
                  onChangeValue={val => handleChange(item.value, val)}
                  required={item.required}
                  disabled={isInputDisabled(item)}
                />
              )}
              {item.type === 'typeahead' && (
                <AtlasTypeahead
                  containerClassName='mt-0'
                  typeaheadId={item.value}
                  isLoading={onLoadProjectList}
                  options={projectList}
                  labelKey='internal_name'
                  filterBy={['internal_name']}
                  selectedValue={
                    !isEmptyValue(selectedProject) ? [selectedProject] : []
                  }
                  onSearch={handleTypeaheadChange}
                  onSelect={async val => {
                    if (val?.length > 0) {
                      const { id } = val[0];
                      await getSelectedProject(id);
                      await handleChange(item.value, id);
                    } else {
                      onChangeVideoTemplatesHOC('selectedProject', {});
                      handleChange(item.value, '');
                    }
                  }}
                  childrenHead={rowItem => <p>{rowItem.internal_name}</p>}
                  highlighterData={[
                    option => `Project ID: ${option.id}`,
                    <br />,
                    option => `Slug: ${option.slug}`
                  ]}
                  showClearButton={selectedProject?.id}
                  required
                  disabled={isInputDisabled(item)}
                />
              )}
              {item.type === 'textarea' && (
                <AtlasTextarea
                  style={{
                    cursor: isInputDisabled(item) ? 'not-allowed' : 'auto'
                  }}
                  value={values[item.value] || ''}
                  onChangeValue={val => handleChange(item.value, val)}
                  disabled={isInputDisabled(item)}
                />
              )}
              {item.type === 'select' && (
                <AtlasSelect
                  valueKey='id'
                  selectItems={countries}
                  currentlySelected={countries?.find(
                    ({ id }) => id === values[item.value]
                  )}
                  updateSelect={val => handleChange(item.value, val.id)}
                  required={item.required}
                  disabled={isInputDisabled(item)}
                />
              )}
              {item.type === 'radio' && (
                <AtlasRadioGroup
                  options={item.options}
                  checkedValue={values[item.value]}
                  selectedRadioValue={val => handleChange(item.value, val)}
                  disabled={isInputDisabled(item)}
                />
              )}
            </div>
          ))}
      </div>
      <div className='form-row'>
        {visibleInputFields.slice(10).map(item => (
          <div
            key={item.value}
            className={`col-lg-4 col-sm-6 ${item.className}`}
          >
            <label className='at-form-input__title' required={item.required}>
              {item.label}
            </label>
            {item.type === 'video' ? (
              <div className='at-video-templates__thumbnail-wrapper at-video-templates__video-thumbnail-wrapper'>
                <video src={videoUrl} className='at-video-templates__thumbnail'>
                  This browser does not support the video tag.
                </video>
                <div
                  className='at-video-templates__thumbnail-overlay'
                  onClick={() =>
                    onChangeVideoTemplatesHOC('showVideoPlayer', true)
                  }
                >
                  <ImYoutube className='at-video-templates__play-icon' />
                </div>
              </div>
            ) : (
              <AtlasUpload
                containerClass='at-video-templates__upload'
                containerStyle={{
                  pointerEvents: isInputDisabled(item) ? 'none' : 'auto'
                }}
                uploadType={item.type}
                description={item.description}
                accepts={item.accepts}
                style={{ width: '100%' }}
                {...uploadProps(item)}
                required={item.required}
                disabled={isInputDisabled(item)}
              />
            )}
          </div>
        ))}
      </div>
    </ModalDialog>
  );
};

export default UpdateVideoTemplate;
