export const edgePropStateMap = [
  {
    id: 1,
    state: "Kuala Lumpur",
    map_id: 6,
    longitude: 101.703697,
    latitude: 3.187308,
  },
  {
    id: 2,
    state: "Selangor",
    map_id: 10,
    longitude: 101.556915,
    latitude: 3.17303,
  },
  {
    id: 3,
    state: "Penang",
    map_id: 3,
    longitude: 100.257134,
    latitude: 5.393654,
  },
  {
    id: 4,
    state: "Johor",
    map_id: 8,
    longitude: 103.358727,
    latitude: 1.9344,
  },
  {
    id: 5,
    state: "Kedah",
    map_id: 2,
    longitude: 100.457942,
    latitude: 6.119423,
  },
  {
    id: 6,
    state: "Kelantan",
    map_id: 423,
    longitude: 102.241646,
    latitude: 5.906222,
  },
  {
    id: 7,
    state: "Labuan",
    map_id: 424,
    longitude: 115.221938,
    latitude: 5.309908,
  },
  {
    id: 8,
    state: "Melaka",
    map_id: 7,
    longitude: 102.264966,
    latitude: 2.231367,
  },
  {
    id: 9,
    state: "Negeri Sembilan",
    map_id: 5,
    longitude: 101.980323,
    latitude: 2.771243,
  },
  {
    id: 10,
    state: "Pahang",
    map_id: 9,
    longitude: 103.283528,
    latitude: 3.809262,
  },
  {
    id: 11,
    state: "Perak",
    map_id: 4,
    longitude: 101.112102,
    latitude: 4.555197,
  },
  {
    id: 12,
    state: "Perlis",
    map_id: 1,
    longitude: 100.196895,
    latitude: 6.438627,
  },
  {
    id: 13,
    state: "Putrajaya",
    map_id: 425,
    longitude: 101.594714,
    latitude: 2.976345,
  },
  {
    id: 14,
    state: "Sabah",
    map_id: 12,
    longitude: 116.812053,
    latitude: 5.412958,
  },
  {
    id: 15,
    state: "Sarawak",
    map_id: 13,
    longitude: 113.962543,
    latitude: 4.315275,
  },
  {
    id: 16,
    state: "Terengganu",
    map_id: 11,
    longitude: 103.095025,
    latitude: 5.256216,
  },
  {
    id: 17,
    state: "Other",
    map_id: 0,
    longitude: 106.782723,
    latitude: 3.378621,
  },
];

export const emojiOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const audienceOptions = [
  { value: "general", label: "General" },
  { value: "investors", label: "Investors" },
  { value: "working professionals", label: "Working Professionals" },
  { value: "home sellers", label: "Home Sellers" },
  { value: "renters", label: "Renters" },
  { value: "landlords", label: "Landlords" },
  { value: "developers", label: "Developers" },
  { value: "families", label: "Families" },
  { value: "retired", label: "Retired" },
  { value: "students", label: "Students" },
];

export const platformOptions = [
  { value: "general", label: "General" },
  { value: "facebook", label: "Facebook" },
  { value: "instagram", label: "Instagram" },
  { value: "twitter", label: "Twitter" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "youtube", label: "Youtube" },
  { value: "tiktok", label: "Tiktok" },
  { value: "snapchat", label: "Snapchat" },
  { value: "reddit", label: "Reddit" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "wechat", label: "Wechat" },
];

export const styleOptions = [
  { value: "casual", label: "Casual" },
  { value: "conversion focused", label: "Conversion Focused" },
  { value: "branding focused", label: "Branding Focused" },
  { value: "neutral", label: "Neutral" },
  { value: "fun", label: "Fun" },
  { value: "professional", label: "Professional" },
];

export const templateOptions = [
  {
    value: "template_1",
    label: "Template 1",
    title: "Visually Organised",
    description:
      "This template will include the information such as price, listing details, nearby and agent contact info.",
  },
  {
    value: "template_2",
    label: "Template 2",
    title: "Location Focused",
    description:
      "This template will description the listing in an organized manner with the listing details, amenities, transportation, price and agent contact info.",
  },
  {
    value: "template_3",
    label: "Template 3",
    title: "Bullet points",
    description:
      "This template break down the information in bullet point using the property details including amenities, easy access, agent contact info.",
  },
  {
    value: "template_4",
    label: "Template 4",
    title: "Minimal",
    description:
      "This template will minimize the description with the important property details and agent contact info only.",
  },
];
