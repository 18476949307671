import React, {useEffect, useState} from 'react'
import Moment from 'moment'
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from 'components/RadioGroup'

const DownloadCP58 = (props) => {
  const [countDown, setCountDown] = useState(false);

  useEffect(() => {
    if (props.cp58Year) {
      props.getCP58(props.cp58Year);
    }
  }, [props.cp58Year])

  const renderTime = ({ remainingTime }) => {
    return <p style={{ fontSize: 10, fontWeight: 500 }}>{remainingTime}</p>;
  };

  return (
    <div className="at-form__content at-pv--detail-info d-flex flex-column g-3" style={{padding: 20}}>
      <h1>CP58</h1>
      <p >
        {`CP58 is a form used by real estate agents in Malaysia to declare the commission they earn from property transactions. The form is updated every year, typically around the middle to end of March by Finance Department.`}
        <br/>
        {`For further enquiries regarding to previous or current year CP58 please contact `}
        <a style={{ color: '#2563EB', fontSize: 14 }} href='mailto:iqifinance@iqiglobal.com' target="__blank">iqifinance@iqiglobal.com</a>.
      </p>
      <CustomRadioGroup
        checkedValue={props.cp58Year}
        options={[
          {value: Moment().subtract(1, 'years').format('YYYY'), label: Moment().subtract(1, 'years').format('YYYY')},
          {value: Moment().subtract(2, 'years').format('YYYY'), label: Moment().subtract(2, 'years').format('YYYY')},
        ]}
        selectedRadioValue={(val) => props.onChangePaymentVoucherHOC(val, "cp58Year")}
      />
      {(props.cp58List && props.cp58List.length > 0) && (
        <>
          {props.cp58List.map((item, index) => (
            <div
              key={`download-cp58-${index}`}
              className={"at-download-cp58__document"}
            >
              <AtlasIcon svgHref={"atlas-document-text"} />
              {item.name}
            </div>
          ))}
          <CustomButton
            disabled={countDown}
            className={"btn-new btn-new--primary"}
            children={
              <>
                {countDown && (
                  <div className="mr-2">
                    <CountdownCircleTimer
                      isPlaying
                      size={20}
                      strokeWidth={2}
                      colors={["#ffffff"]}
                      duration={10}
                      initialRemainingTime={10}
                      onComplete={() => setCountDown(false)}
                      trailColor={[["#e4560f"]]}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                )}
                Download
              </>
            }
            onClick={() => props.downloadCP58(() => setCountDown(true))}
          />
        </>
      )}
    </div>
  )
}

export default DownloadCP58