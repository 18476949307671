import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FaArrowCircleDown } from "react-icons/fa";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import GenerateDescriptionModal from "./components/GenerateDescription";
import SubsalesListingPreview from "./components/subsalesListingPreview";
import CustomFacilitiesForm from "./components/facilitiesForm";
import CustomCobrokeForm from "./components/cobrokeForm";
import CustomStatusForm from "./components/statusForm";
import CustomBasicForm from "./components/basicForm";
import NewHOC from "./actions/new";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import useOnScreen from "utils/hooks/useOnScreen";
import useSelectedLocalised from 'utils/hooks/useSelectedLocalised';

import "./index.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const New = ({
  data,
  showNewModal,
  showGenerateModal,
  otherFacilities,
  propertyOptions,
  descriptionType,
  townshipArray,
  subAreaArray,
  dataBasicInfo,
  subsalesType,
  dataDetails,
  dataCobrokes,
  storeDetailsData,
  disableCreateButton,
  subsalesEntryCreationProps,
  autoSuggestOptions,
  autoSuggestLoading,
  dataFacilitiesInfo,
  openForInternalCoBroke,
  getLocalised,
  renderSpecialMessage,

  onLoadNewHOC,
  onLoadSubsales,
  onLoadNewSubsales,

  onChangeSubsalesHOC,
  onChangeNewHOC,
  onClickSubmitAllInfo,
  edgeAutoSuggest,
  getStateLocation,
  getSelectedTownShip,
  getSelectedSubArea,
  ...props
}) => {
  const bottomSection = useRef();
  const selectedLocalised = useSelectedLocalised();
  const onScreen = useOnScreen(bottomSection);
  const { can_create: cobrokeCreatePermission } = permissionsChecker(
    "Co Broke Center",
    data,
  );

  useEffect(() => {
    showNewModal && onChangeSubsalesHOC([], "townshipArray");
  }, [showNewModal]);

  const onClickTop = () => {
    document
      .getElementById(`top-section`)
      .scrollIntoView({ behavior: "smooth", block: "bottom" });
  };

  const onClickBottom = () => {
    document
      .getElementById(`bottom-section`)
      .scrollIntoView({ behavior: "smooth" });
  };

  const onChangeBasicFormField = (val, context) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp[context] = val;
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const renderBasicInfo = () => (
    <CustomBasicForm
      onChangeSubsalesHOC={onChangeSubsalesHOC}
      propertyOptions={propertyOptions}
      getStateLocation={getStateLocation}
      autoSuggestOptions={autoSuggestOptions}
      autoSuggestLoading={autoSuggestLoading}
      onChangeNewHOC={(val, context) => onChangeNewHOC(val, context)}
      getSelectedTownShip={(val) => getSelectedTownShip(val)}
      edgeAutoSuggest={edgeAutoSuggest}
      getSelectedSubArea={getSelectedSubArea}
      townshipArray={townshipArray}
      subAreaArray={subAreaArray}
      dataBasicInfo={dataBasicInfo}
      subsalesType={subsalesType}
      getLocalised={getLocalised}
    />
  );

  const renderStatusInfo = () => (
    <CustomStatusForm
      dataBasicInfo={dataBasicInfo}
      onChangeNewHOC={(val, context) => onChangeNewHOC(val, context)}
      getLocalised={getLocalised}
    />
  );

  const renderFacilitiesForm = () => (
    <CustomFacilitiesForm
      onChangeNewHOC={onChangeNewHOC}
      dataBasicInfo={dataBasicInfo}
      descriptionType={descriptionType}
      otherFacilities={otherFacilities}
      facilitiesStates={dataFacilitiesInfo}
      getLocalised={getLocalised}
      renderSpecialMessage={renderSpecialMessage}
    />
  );

  const renderCobrokeForm = () => (
    <CustomCobrokeForm
      dataCobrokes={dataCobrokes}
      dataBasicInfo={dataBasicInfo}
      openForInternalCoBroke={openForInternalCoBroke}
      onChangeNewHOC={onChangeNewHOC}
      getLocalised={getLocalised}
    />
  );

  const renderPartnerPlatform = () => (
    <div className="at-form__content" id="partner-section">
      <div className="at-subsales-listing__view-title mb-20">
        <AtlasIcon
          svgHref={"atlas-global"}
          style={{ fill: "#F0631D", marginRight: 10 }}
        />
        {getLocalised("label.details.partner_platform.title", "Partner Platform")}
      </div>
      <AlertBox
        mode={"info"}
        hideIcon={true}
        description={
          <>
            {renderSpecialMessage(getLocalised(
              "label.details.helptext.partner_platform.create_message",
              `After submit this listing, you can edit listing details and publish this listing to Juwai and EdgeProp platform.
                <br /> <br />
                *Juwai and the EdgeProp platform will only import your listing once per day at a set time. 
                We will notify you of the result once we got it.
              `
            ))}
          </>
        }
      />
    </div>
  );

  const renderScrollToPartner = () => {
    return (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        aria-label={"action-button"}
        placement={"top"}
        title={onScreen 
          ? getTranslation("scroll_to_bottom", selectedLocalised) 
          : getTranslation("scroll_to_top", selectedLocalised)
        }
      >
        <button
          className="at-listing__partner-platform-btn"
          style={{
            transform: onScreen ? "rotate( 180deg )" : "rotate( 0deg )",
          }}
          onClick={() => (onScreen ? onClickTop() : onClickBottom())}
        >
          <FaArrowCircleDown />
        </button>
      </DarkTooltip>
    );
  };

  const renderCardContent = () => (
    <div id="top-section">
      <div className="at-form__content">
        <p className="fs-2">
          {(
            renderSpecialMessage(
              getLocalised(
                "label.details.details.main_message", 
                `Move your mouse pointer over the {icon_1} or {icon_2} or {icon_3} or {icon_4} 
                beside the fields below to see how your input will reflect on the Subsales listing 
                on both www.iqiglobal.com and www.juwai.com respectively.
                <br/>
                To see examples of how your listing will be published on the site, click on the buttons below.`
              ), 
              "icon"
            )
          )}
          <a
            onClick={() => {
              onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
              onChangeSubsalesHOC("IQI", "listingPreviewType");
            }}
          >
            <img style={{ width: 25, margin: "0px 5px" }} src={iqiLogo} />
          </a>
          <a
            onClick={() => {
              onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
              onChangeSubsalesHOC("JUWAI", "listingPreviewType");
            }}
          >
            <img style={{ width: 25, margin: "0px 5px" }} src={juwaiLogo} />
          </a>
          <a
            onClick={() => {
              onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
              onChangeSubsalesHOC("JUWAIASIA", "listingPreviewType");
            }}
          >
            <img
              style={{ width: 25, margin: "0px 5px" }}
              src={juwaiAsiaLogo}
            />
          </a>
          <a
            onClick={() => {
              onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
              onChangeSubsalesHOC("EDGE", "listingPreviewType");
            }}
          >
            <img
              style={{ width: 25, margin: "0px 5px", borderRadius: "50%" }}
              src={edgePropLogo}
            />
          </a>
        </p>
      </div>
      <div className="row">
        <div className="col-md-8">
          {renderBasicInfo()}
          {renderFacilitiesForm()}
        </div>
        <div className="col-md-4">
          {renderStatusInfo()}
          {cobrokeCreatePermission && renderCobrokeForm()}
          {renderPartnerPlatform()}
        </div>
      </div>
      {onLoadNewHOC && <LoadingModal />}
    </div>
  );

  return (
    <>
      {renderCardContent()}
      {renderScrollToPartner()}
      <div id="bottom-section" ref={bottomSection}></div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          disabled={disableCreateButton}
          className="btn-new btn-new--success"
          onClick={(e) => {
            Promise.all([onChangeNewHOC(true, "disableCreateButton")]).then(
              () => {
                onClickSubmitAllInfo();
              },
            );
          }}
        >
          {getTranslation("submit", selectedLocalised)}
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeSubsalesHOC(false, "showNewModal")}
        >
          {getTranslation("cancel", selectedLocalised)}
        </button>
      </div>
      <SubsalesListingPreview
        onChangeSubsalesHOC={onChangeSubsalesHOC}
        {...props}
      />
      {showGenerateModal && (
        <GenerateDescriptionModal
          {...dataBasicInfo}
          language={descriptionType}
          getLocalised={getLocalised}
          onChangeHOC={onChangeNewHOC}
          agentInfo={data.profileReducer}
          onChangeValue={(context, val) =>
            onChangeBasicFormField(
              val,
              context === "Chinese Description"
                ? "zh_description"
                : "description",
            )
          }
        />
      )}
      {(onLoadSubsales || onLoadNewSubsales) && <LoadingModal />}
    </>
  );
};

export default NewHOC(New);
