import React, { Component } from "react";
import Slider from "react-slick";
import LoadingModal from "components/LoadingModal";
import { HiArrowLeft } from "react-icons/hi";

import LoginImage1 from "../../assets/images/login_img1.png";
import LoginImage2 from "../../assets/images/login_img2.png";
import IQILogoBlack from "../../assets/images/iqi-logo_black.png";
import IQILogoWhite from "../../assets/images/iqi-logo_white.png";
import SupportButtonGroups from "components/Button/supportGroups";
import ResetPasswordForm from "../../components/Form/resetPassword";
import ForgetPasswordHOC from "./actions";

import { initGA } from "utils/ga";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class ResetPassword extends Component {
  componentDidMount = () => {
    const resetToken = this.props.match.params.id;
    if (resetToken && resetToken !== "") {
      this.props.onChangeHOC(resetToken, "resetToken");
      this.props.validateResetToken(resetToken);
    }

    if (!resetToken && resetToken == "") {
      this.props.history.push("/login");
    }

    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/reset-password");
    }
  };

  onClickBackToLogin = () => this.props.history.push("/login");

  onClickForgetPassword = () => this.props.history.push("/forget-password");

  render = () => {
    return (
      <>
        <div
          className="d-flex flex-lg-nowrap flex-wrap"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div className="at-desktop-view__controller at-login__banner">
            <div className="at-login__banner-header">
              <img
                src={IQILogoWhite}
                alt={"IQI Logo White"}
                style={{ width: 70, margin: "20px 0px" }}
              />
              <p>Empowering IQI agents, streamlining real estate process</p>
            </div>
            <div className="at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img src={LoginImage2} alt={"Login Image 2"} />
              </Slider>
            </div>
          </div>
          <div className="login-container">
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <img
                src={IQILogoBlack}
                alt={"IQI Logo Black"}
                style={{ width: "114px" }}
              />
              <div className="at-login__back-to">
                <HiArrowLeft
                  style={{ width: 16, height: 16, marginRight: 10 }}
                />
                <p onClick={() => this.onClickBackToLogin()}>Back to Login</p>
              </div>
              {!this.props.showSuccessMessage && (
                <>
                  <h2>Reset Password</h2>
                  {this.props.resetTokenValid && (
                    <p style={{ padding: "0px 20px" }}>
                      Please enter your new password.
                    </p>
                  )}
                </>
              )}
              {this.props.showSuccessMessage && (
                <>
                  <h2>Password Changed</h2>
                  <div className="login-form-container m-auto">
                    <p style={{ fontSize: 14 }}>
                      Your password has been changed successfully. Please
                      proceed to sign in using the new password.
                    </p>
                    <div className="mt-4">
                      <button
                        className="btn-new btn-new--primary text-uppercase w-100"
                        onClick={() => this.onClickBackToLogin()}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!this.props.showSuccessMessage && (
              <div className="login-panel">
                {!this.props.onLoadResetPassword && (
                  <p
                    style={{
                      margin: 10,
                      color: !this.props.resetTokenValid ? "red" : "",
                    }}
                  >
                    {this.props.notificationMessage}
                  </p>
                )}
                {this.props.onLoadResetPassword && <LoadingModal />}
                <ResetPasswordForm
                  confirmedPassword={this.props.confirmedPassword}
                  resetTokenValid={this.props.resetTokenValid}
                  password={this.props.password}
                  onChangeHOC={(val, context) =>
                    this.props.onChangeHOC(val, context)
                  }
                  onClickSubmitPassword={() =>
                    this.props.onClickSubmitPassword()
                  }
                  onClickForgetPassword={() => this.onClickForgetPassword()}
                />
              </div>
            )}
            <div
              className="at-login__footer pt-lg-0"
              style={{ paddingTop: "4rem" }}
            >
              <p className="small">{`IQI Holdings Sdn Bhd (1018842-U)`}</p>
              <p className="small">
                {`26th -28th Floor,
                  Tower D, Millerz Square,
                  No. 357, Jalan Kelang Lama, 
                  58000 Kuala Lumpur,
                  Malaysia.
                  T: +60374506655`}
              </p>
              <p
                className="small"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.iqiglobal.com/privacy-policy",
                    "__blank",
                  )
                }
              >
                <u className="mx-2">Terms of use</u>•
                <u className="mx-2">Privacy Policy</u>
              </p>
            </div>
          </div>
        </div>
        <SupportButtonGroups />
      </>
    );
  };
}

export default ForgetPasswordHOC(ResetPassword);
