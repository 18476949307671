import React from "react";
import LoadingModal from "components/LoadingModal";

import CustomFormInput from "components/Input/formInput";

import ViewEditPasswordHOC from "./actions/viewEditPassword";

const ViewEditPasswordModal = ({
  selectedUser,
  newPassword,
  passwordError,
  password_confirmation,
  passwordConfirmationError,

  onChangeHOC,
  onChangeEditPasswordHOC,
  onSubmitNewPassword,
  onLoadEditPassword,
}) => {
  const onChangeNewPassword = (val) =>
    Promise.all([onChangeEditPasswordHOC(val, "newPassword")]).then(() => {
      if (!val) {
        return onChangeEditPasswordHOC(
          val ? "" : "Please fill in the password field.",
          "passwordError"
        );
      }
      onChangeEditPasswordHOC(
        val === password_confirmation
          ? ""
          : "Password and confirm password must be the same.",
        "passwordConfirmationError"
      );
      onChangeEditPasswordHOC(
        val === password_confirmation
          ? ""
          : "Password and confirm password must be the same.",
        "passwordError"
      );
    });

  const onChangeConfirmNewPassword = (val) =>
    Promise.all([onChangeEditPasswordHOC(val, "password_confirmation")]).then(
      () => {
        if (!val) {
          return onChangeEditPasswordHOC(
            val ? "" : "Please fill in the password field.",
            "passwordConfirmationError"
          );
        }
        onChangeEditPasswordHOC(
          val === newPassword
            ? ""
            : "Password and confirm password must be the same.",
          "passwordConfirmationError"
        );
        onChangeEditPasswordHOC(
          val === newPassword
            ? ""
            : "Password and confirm password must be the same.",
          "passwordError"
        );
      }
    );

  return (
    <>
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            New Password
          </h2>
          <CustomFormInput
            type="password"
            value={newPassword}
            containerStyle={{ maxWidth: 600 }}
            onChangeValue={(val) => onChangeNewPassword(val)}
            inputError={passwordError}
            errorMessagePlain
            placeholder={"Enter your new password"}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            Confirm New Password
          </h2>
          <CustomFormInput
            type="password"
            value={password_confirmation}
            containerStyle={{ maxWidth: 600 }}
            onChangeValue={(val) => onChangeConfirmNewPassword(val)}
            required={true}
            inputError={passwordConfirmationError}
            errorMessagePlain
            placeholder={"Confirm your new password"}
          />
        </section>
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          disabled={
            !newPassword ||
            !password_confirmation ||
            passwordError ||
            passwordConfirmationError
          }
          className="btn-new btn-new--success"
          onClick={() => onSubmitNewPassword(selectedUser.id)}
        >
          Update Password
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeHOC(false, "showDetailsModal")}
        >
          Close
        </button>
      </div>
      {onLoadEditPassword && <LoadingModal />}
    </>
  );
};

export default ViewEditPasswordHOC(ViewEditPasswordModal);
