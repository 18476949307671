import _ from "lodash";
import React, { useEffect, useState } from "react";

import LoadingModal from "components/LoadingModal";
import AtlasAutosuggest from "components/Autosuggest";
import ModalDialog from "components/Modal/ModalDialog";

const SetProject = ({
  projectList,
  onLoadCobroke,
  selectedCobroke,

  onClose,
  getProjectListing,
  updateCobrokeProject,
}) => {
  const [selectedProject, setSelectedProject] = useState("");

  useEffect(() => {
    getProjectListing();
  }, []);

  return (
    <>
      <ModalDialog
        title={"Set Co-broke Project"}
        children={
          <>
            <p style={{ fontSize: 14 }}>
              Please select a project that you would like to co-broke with.
            </p>
            <div className="grid-control mt-3" style={{ marginBottom: 200 }}>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Project Name</h2>
                <AtlasAutosuggest
                  placeholder={"Please fill in the project's name."}
                  value={selectedProject}
                  updateValue={(val) => setSelectedProject(val)}
                  options={projectList}
                />
              </section>
            </div>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              className="btn-new btn-new--primary"
              disabled={!selectedProject}
              onClick={() => {
                let temp = _.find(projectList, {
                  value: selectedProject,
                }).atlas_id;
                updateCobrokeProject(selectedCobroke.id, temp);
              }}
            >
              Submit
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        }
        onClose={() => onClose()}
      />
      {onLoadCobroke && <LoadingModal />}
    </>
  );
};

export default SetProject;
