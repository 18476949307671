import React from "react";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { FilePond } from "react-filepond";

import CustomFormInput from "components/Input/formInput";
import AtlasDialog from "components/Dialog";
import AtlasButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import AtlasCard from "components/Card";
import AtlasLoadingModal from "components/LoadingModal";
import AtSelectNew from "components/Select/new";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const New = ({
  data,
  document,
  onChange,
  tutorial_categories = [],
  onUploadDocument,
  onSubmit,
  onClose,
  onLoadTutorial,
}) => {
  return (
    <AtlasDialog open={true}>
      <AtlasCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton onClick={() => onClose()} />
              <h4 className="at-card__title">{"New Tutorial"}</h4>
            </div>
            <div className="grid-control">
              <section className="grid-full-col">
                <label className="at-form-input__title" required>
                  {"Title"}
                </label>
                <CustomFormInput
                  type="text"
                  placeholder={"e.g. Tutorial Title"}
                  value={data.title}
                  onChangeValue={(val) => onChange("title", val)}
                  required={true}
                />
              </section>
              <section className="grid-full-col">
                <label className="at-form-input__title">
                  Tutorial Category
                </label>
                <AtSelectNew
                  value={data.category_id}
                  onChange={(e) => onChange("category_id", e.target.value)}
                >
                  {tutorial_categories.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </AtSelectNew>
              </section>
              <section className="grid-full-col mt-2">
                <label className="at-form-input__title">Upload Document</label>
                <FilePond
                  value={document}
                  allowMultiple={false}
                  fullWidth
                  acceptedFileTypes={["application/pdf"]}
                  maxFiles={1}
                  onupdatefiles={(file) => onUploadDocument(file)}
                />
                <FormHelperText>Accept PDF file types only </FormHelperText>
              </section>
              <div className="d-flex mt-20">
                <AtlasButton
                  containerStyle={{ marginLeft: "auto" }}
                  className={"btn-new btn-new--success"}
                  onClick={onSubmit}
                  children={"Submit"}
                />
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
              </div>
            </div>
            {onLoadTutorial && <AtlasLoadingModal />}
          </>
        }
      />
    </AtlasDialog>
  );
};

export default New;
