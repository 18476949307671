import React, { Component } from "react";

import { requestError } from "utils/requestHandler";
import LoadingModal from "components/LoadingModal";

import { Get } from "utils/axios";

const InvoicingBranchesHOC = (WrappedComponent) => {
  class InvoicingBranchesComponent extends Component {
    state = {
      invoicing_branches: [],

      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    getInvoicingBranches = () =>
      Get(
        `/invoicing_branches`,
        this.getInvoicingBranchesSuccess,
        this.getInvoicingBranchesError,
        this.load,
      );
    getInvoicingBranchesSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            value: item.id,
            label: item.name,
          };
          tmpArray.push(data);
        });
        return this.setState({ invoicing_branches: tmpArray });
      }
    };
    getInvoicingBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            invoicing_branches={this.state.invoicing_branches}
            getInvoicingBranches={this.getInvoicingBranches}
            onLoadInvoicingBranches={this.state.loading}
          />

          {this.state.showLoadingModal && <LoadingModal />}
        </>
      );
    };
  }
  return InvoicingBranchesComponent;
};

export default InvoicingBranchesHOC;
