import React from "react";
import _ from "lodash";
import { Card } from "@material-ui/core";

import CustomDialog from "components/Dialog";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import CustomImageDisplay from "./image";
import SVGIcon from "components/Icon";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";

const QouteOfTheDay = ({ onChangeMOTDHOC, newMOTD, putMOTD, onLoadMOTD }) => {
  let fileInput = null;
  const fileInputRef = (element) => (fileInput = element);
  const focusFileInput = () => fileInput && fileInput.click();
  const onClickAddImage = () => focusFileInput();

  const onUploadImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let tmp = _.cloneDeep(newMOTD);
      let files = event.target.files;
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          tmp.push({
            image: e.target.result,
            image_file_name: files[key].name,
          });
          onChangeMOTDHOC(tmp, "newMOTD");
        };
        reader.readAsDataURL(event.target.files[key]);
      });
    }
  };

  const renderCardContent = () => (
    <>
      <div className="at-card__header">
        <AtlasCloseButton
          onClick={() => onChangeMOTDHOC(false, "showCreateMOTDModal")}
        />
        <h4 className="at-card__title">New MOTD</h4>
      </div>
      <Card style={{ padding: 16, marginBottom: 20 }}>
        <div className={"mb-10"}>
          <div className="d-flex align-items-center">
            <strong className="mr-3 d-flex align-items-center">
              Upload Image
            </strong>
            <span>(You are able to select multiple images at once)</span>
          </div>
          <input
            ref={fileInputRef}
            type={"file"}
            multiple={true}
            onChange={(event) => {
              onUploadImage(event);
            }}
            style={{ display: "none" }}
            accept="image/*"
          />
          <div
            className="project-detail__upload-photo flex-center bd-rd-2"
            onClick={() => {
              onClickAddImage();
            }}
          >
            <SVGIcon
              className="icon-size-4 mr-2 svg-icon-plus-circle"
              svgHref={"icon-plus-circle"}
            />
          </div>
          {!_.isEmpty(newMOTD) && (
            <div className="row">
              {newMOTD.map((item, index) => {
                return (
                  <div
                    className="at-add-motd__image-display col-sm-4"
                    key={index}
                  >
                    <CustomImageDisplay
                      imgSrc={item.image}
                      tooltipChildren={"Remove image"}
                      onClickRemoveImage={() => {
                        let temp = _.cloneDeep(newMOTD);
                        temp.splice(index, 1);
                        onChangeMOTDHOC(temp, "newMOTD");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="d-flex">
          <CustomButton
            disabled={_.isEmpty(newMOTD)}
            containerStyle={{ float: "right" }}
            className={"btn-new btn-new--success"}
            children={"Upload"}
            onClick={() => putMOTD()}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onChangeMOTDHOC(false, "showCreateMOTDModal")}
          >
            Cancel
          </button>
        </div>
      </Card>
    </>
  );

  return (
    <CustomDialog open={true}>
      <CustomCard className={`h-100`} cardContent={renderCardContent()} />
      {onLoadMOTD && <LoadingModal />}
    </CustomDialog>
  );
};

export default QouteOfTheDay;
