import React, { useState } from "react";
import {
  Card,
  Grid,
  Paper,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  FormLabel,
  Dialog,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import AtlasCloseButton from "components/Button/close";

const collarShirtImgSrc = [
  "mandarin_collar_side.png",
  "mandarin_collar_front.png",
  "mandarin_collar_back.png",
  "sizeChart.jpg",
];

const hashRedImgSrc = [
  "hash_red_side.png",
  "hash_red_front.png",
  "hash_red_back.png",
  "sizeChart.jpg",
];

const sizeOptions = [
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
];

const ShirtCardContent = ({
  shirtGender,
  shirtSize,
  shirtType,
  updateShirtSize,
  updateShirtGender,
}) => {
  const [showImageDialog, onShowImageModal] = useState(false);
  const [currentImage, onChangeCurrentImage] = useState("");
  let temp = shirtType === "Hash" ? hashRedImgSrc : collarShirtImgSrc;

  return (
    <Card style={{ maxWidth: 1000, marginBottom: 10 }}>
      <CardContent>
        <p className={"mb-10"} style={{ textDecoration: "underline" }}>
          {shirtType === "Hash"
            ? "Hashtag IQI T-shirt"
            : "Mandarin Collar Polo Orange"}
        </p>
        <Grid container spacing={16}>
          <Grid item md={4} xs={12}>
            <Carousel animation={"slide"} navButtonsAlwaysVisible={true}>
              {temp.map((item, index) => (
                <Paper key={index}>
                  <img
                    onClick={() => {
                      onShowImageModal(true);
                      onChangeCurrentImage(item);
                    }}
                    src={require(`assets/images/${item}`)}
                    style={{
                      borderRadius: 10,
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Paper>
              ))}
            </Carousel>
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl style={{ width: "100%", marginBottom: 10 }}>
              <FormLabel>Gender</FormLabel>
              <Select
                value={shirtGender}
                fullWidth
                autoWidth={true}
                input={<OutlinedInput labelWidth={0} />}
                onChange={(e) =>
                  updateShirtGender(e.target.value, "shirtGender")
                }
              >
                <MenuItem value={""}>Please choose an option</MenuItem>
                <MenuItem value={"M"}>Male</MenuItem>
                <MenuItem value={"F"}>Female</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              <FormLabel>T-Shirt size</FormLabel>
              <Select
                value={shirtSize}
                fullWidth
                autoWidth={true}
                input={<OutlinedInput labelWidth={0} />}
                onChange={(e) => updateShirtSize(e.target.value, "shirtSize")}
              >
                <MenuItem value={""}>Please choose an option</MenuItem>
                {sizeOptions.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {showImageDialog && (
          <Dialog open={true}>
            <AtlasCloseButton
              onClick={() => {
                onShowImageModal(false);
                onChangeCurrentImage("");
              }}
              containerStyle={{
                position: "absolute",
                right: 20,
                top: 10,
              }}
            />
            <img src={require(`assets/images/${currentImage}`)} />
          </Dialog>
        )}
      </CardContent>
    </Card>
  );
};

export default ShirtCardContent;
