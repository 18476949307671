import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { edgePropDataMapping } from "dictionary/subsales";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put } from "utils/axios";

const ListingHOC = (WrappedComponent) => {
  class ListingWrappedComponent extends Component {
    state = {
      autoSuggestOptions: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeListingHOC = (val, context) => this.setState({ [context]: val });

    edgeAutoSuggest = (query, formData) => {
      const { category_id, property_type } = formData;
      if (!_.isEmpty(property_type) && category_id) {
        Get(
          `/admin/sub_sales/suggest_address?query=${query}&category_id=${category_id}&property_type=${property_type}`,
          this.edgeAutoSuggestSuccess,
          this.edgeAutoSuggestError,
          (load) => this.setState({ loading: load }),
        );
      }
    };
    edgeAutoSuggestSuccess = (payload) => {
      this.setState({
        autoSuggestOptions: _.map(payload.data, (item) => {
          return {
            ...item,
            state_s_lower: _.find(edgePropDataMapping, { edgeProp: item.state_s_lower })?.atlas ?? item.state_s_lower,
            value: item.name_s_lower,
          };
        }),
      });
    };
    edgeAutoSuggestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            autoSuggestOptions={this.state.autoSuggestOptions}
            autoSuggestLoading={this.state.loading}
            onChangeListingHOC={this.onChangeListingHOC}
            edgeAutoSuggest={this.edgeAutoSuggest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(ListingWrappedComponent);
};

export default ListingHOC;
