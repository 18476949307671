import React, { Component } from "react";
import _ from "lodash";

import AtlasTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasLoadingModal from "components/LoadingModal";
import ListingMenu from "./Agents";

import UnitInfoHOC from "../../actions/unitInfo";
import { getColorBadge } from "dictionary/badgeColor";

import "stylesheets/containers/subsales-claims/index.scss";
import "bootstrap/dist/css/bootstrap.css";

// pending: set default value for empty state
const columnData = [
  { header: "Claim Type", accessor: "ClaimType" },
  { header: "Agent Name", accessor: "AgentName" },
  { header: "Agency Commission Portion", accessor: "AgencyCommissionPortion" },
];

const statusBadge = [
  { id: 1, value: "Booked", colorName: "Yellow" },
  { id: 2, value: "To-Invoice", colorName: "Blue" },
  { id: 3, value: "Billed", colorName: "Green" },
  { id: 4, value: "Cancelled", colorName: "Grey" },
];

const generalInformation = [
  {
    label: "Booking Date",
    value: "BookingDate",
    icon: "atlas-calendar",
  },
  {
    label: "Unit ID",
    value: "UnitID",
    icon: "atlas-tag",
  },
  {
    label: "Unit Number",
    value: "UnitNumber",
    icon: "atlas-home",
  },
  {
    label: "External Fee Amount",
    value: "ExternalFeeAmount",
    icon: "atlas-money-2",
  },
  {
    label: "Net Agency Commission",
    value: "NetAgencyCommission",
    icon: "atlas-money",
  },
  {
    label: "Subsales Form Number",
    value: "SubsaleFormNumber",
    icon: "atlas-building-3",
  },
];

const unitInfo = [
  {
    title: "Agents",
    content: [],
  },
  {
    title: "Buyers",
    content: [],
  },
];

const AgentInfoIcons = [
  {
    label: "Agent Name",
    icon: "atlas-profile-circle",
  },
  {
    label: "Email Address",
    icon: "atlas-sms",
  },
  {
    label: "Mobile Phone",
    icon: "atlas-mobile",
  },
  {
    label: "Admin Fee Amount",
    icon: "atlas-money",
  },
  {
    label: "Agency Commission Amount",
    icon: "atlas-percentage-circle",
  },
  {
    label: "Extra Collection Amount",
    icon: "atlas-money-4",
  },
  {
    label: "Stamping Fee Amount",
    icon: "atlas-receipt-text",
  },
];

const BuyersInfoIcons = [
  {
    label: "Name",
    icon: "atlas-user",
  },
  {
    label: "Refund",
    icon: "atlas-tag",
  },
];

const PropertyOwnerInfoIcons = [
  {
    label: "Name",
    icon: "atlas-building",
  },
  {
    label: "Refund",
    icon: "atlas-tag",
  },
];

const renderTableHeading = () => {
  return (
    <div className={`at-table_heading`}>
      <AtlasIcon
        className="at-icon_svg-table_heading-title"
        svgHref={"atlas-percentage-circle"}
      />
      <h5 className="fw-600">Submission Payouts</h5>
    </div>
  );
};

const Content = ({ data }) => {
  const { colorName = "" } =
    _.find(
      statusBadge,
      (item) => _.toLower(item.value) === _.toLower(data.Status),
    ) || {};

  return (
    <>
      <div
        className="at-form__content at-form_fields_cont at-unit_info-agent_cont"
        style={{
          backgroundImage: `url( 'https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/agent_card_bg.jpg' )`,
        }}
      >
        {/* Color Badge */}
        <div className="at-form_field-col-12">
          <p
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {data.Status}
          </p>
        </div>
        {generalInformation.map((item) => {
          return (
            <div className="at-form_field-col-6 at-unit_info-agent_detail">
              <AtlasIcon
                className="at-icon_svg-table_heading-title"
                svgHref={item.icon}
              />
              <div className="at-unit_info-agent-icon_title">
                <p className="at-form-input__title">{item.label}</p>
                <p className="at-unit_info-agent-detail_value">
                  {data[item.value] ?? "-"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="at-form__content-responsive at-unit_info-submission_payout">
        <AtlasTable
          hideSearch
          hidePagination
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={data.SubmissionPayouts || []}
          actionColumnContent={[]}
          renderExtraContent={renderTableHeading}
        />
      </div>
      <div className="at-form_fields_cont">
        <div className="at-form_field-col-12 at-form__content mb-0">
          <div className="at_form_header--cont mb-3">
            <AtlasIcon
              className="at-icon_svg-table_heading-title"
              svgHref={"atlas-money"}
            />
            <p className="mb-1">Agents</p>
          </div>
          <div className="at-form_fields_cont">
            {data.Agents &&
              data.Agents.map((item) => {
                return (
                  <ListingMenu
                    itemContColumn={6}
                    key={item["Mobile Number"]}
                    iconLib={AgentInfoIcons}
                    data={item}
                  />
                );
              })}
          </div>
        </div>
        <div className="at-form_field-col-6 at-form__content mb-0">
          <div className="at_form_header--cont mb-3">
            <AtlasIcon
              className="at-icon_svg-table_heading-title"
              svgHref={"atlas-user"}
            />
            <p className="mb-1">Purchasers</p>
          </div>
          <div className="at-form_fields_cont">
            {data.Buyers &&
              data.Buyers.map((item) => {
                return (
                  <ListingMenu
                    itemContColumn={12}
                    key={item.name}
                    iconLib={BuyersInfoIcons}
                    data={item}
                  />
                );
              })}
          </div>
        </div>
        <div className="at-form_field-col-6 at-form__content mb-0">
          <div className="at_form_header--cont mb-3">
            <AtlasIcon
              className="at-icon_svg-table_heading-title"
              svgHref={"atlas-home"}
            />
            <p className="mb-1">Property Owners</p>
          </div>
          <div className="at-form_fields_cont">
            {data.PropertyOwners &&
              data.PropertyOwners.map((item) => {
                return (
                  <ListingMenu
                    itemContColumn={12}
                    key={item.name}
                    iconLib={PropertyOwnerInfoIcons}
                    data={item}
                  />
                );
              })}
          </div>
        </div>
        <div className="at-form_field-col-12 at-form__content mb-0">
          <div className="at_form_header--cont mb-3">
            <AtlasIcon
              className="at-icon_svg-table_heading-title"
              svgHref={"atlas-receipt-item"}
            />
            <p className="mb-1">Payment Vouchers</p>
          </div>
          <div className="at-form__content at-form_field-list-horizontal">
            {data.PaymentVouchers &&
              data.PaymentVouchers.map((item) => {
                return <p>{item.PvNumber ?? "-"}</p>;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

class UnitInfo extends Component {
  componentDidMount = () =>
    this.props.getUnitInfo(this.props.selected_claim.id);

  render = () => {
    return (
      <div>
        {this.props.unit && !_.isEmpty(this.props.unit) ? (
          <Content data={this.props.unit} />
        ) : (
          <EmptyState
            title={"No Unit information is found"}
            renderIcon={<AtlasIcon svgHref={"atlas-buildings"} />}
          />
        )}
        {this.props.onLoadUnitInfo && <AtlasLoadingModal />}
      </div>
    );
  };
}

export default UnitInfoHOC(UnitInfo);
