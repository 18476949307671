import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Card } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";

import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import CustomTable from "components/NewTable";
import AtlasCloseButton from "components/Button/prev";
import AtlasCard from "components/Card";
import CustomUpload from "components/Upload";
import AtlasDialog from "components/Dialog";
import AlertBox from "components/AlertBox";
import ConfirmationModal from "components/Modal/delete";
import ModalDialog from "components/Modal/ModalDialog";

import UnlockHOC from "../actions/confirmationUnlock";
import SupportHOC from "../actions/supportingDocs";

import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

const ColumnData = [
  {
    header: "Date",
    searchFlag: "requested_datetime",
    accessor: "requested_datetime",
  },
  {
    header: "Reason",
    searchFlag: "reason",
    accessor: "reason",
  },
  {
    header: "Status",
    searchFlag: "status",
    accessor: "status",
  },
  {
    header: "Admin",
    searchFlag: "admin_display_name",
    accessor: "admin_display_name",
  },
];

const DocColumnData = [
  {
    header: "File Name",
    searchFlag: "document_file_name",
    accessor: "document_file_name",
  },
  {
    header: "Uploaded Date",
    searchFlag: "uploaded_datetime",
    accessor: "uploaded_datetime",
  },
];

const DetailsChecking = [
  { label: "Property Address", value: "property_address" },
  { label: "Purchaser/Tenant", value: "purchaserNTenant" },
  { label: "Earnest Deposit", value: "earnest_deposit_amount" },
  { label: "Confirmation Date", value: "confirmation_date" },
];

export const unlockRequestStatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Approved", colorName: "Green" },
  { value: "Rejected", colorName: "Blue" },
  { value: undefined, colorName: "Grey" },
];

class UnlockContent extends Component {
  componentDidUpdate = (pp) => {
    const { showCreateUnlockRequestModal, onChangeUnlockHOC } = this.props;

    if (
      pp.showCreateUnlockRequestModal !== showCreateUnlockRequestModal &&
      !showCreateUnlockRequestModal
    ) {
      onChangeUnlockHOC("", "createRequestReason");
    }
  };

  componentDidMount = () =>
    this.props.getUnlockRequest(this.props.selected_confirmation_form.id);

  onUploadDocs = (val, filename) => {
    const {
      selected_confirmation_form,
      selectedUnlockRequest,
      createSupportingDocs,
    } = this.props;

    let dataToSubmit = {
      document: val,
      document_file_name: filename,
    };
    return createSupportingDocs(
      dataToSubmit,
      selected_confirmation_form.id,
      selectedUnlockRequest.id,
    );
  };

  onClickDocDetails = (url) => window.open(url, "_blank");

  checkRequiredInfo = () => {
    return _.values(DetailsChecking).some((x) => {
      if (x.value === "purchaserNTenant") {
        if (!this.props[x.value]) {
          return true;
        }
      } else if (!this.props.selected_confirmation_form[x.value]) {
        return true;
      }
    });
  };

  renderUnlockCreateContent = () => {
    let {
      onLoadUnlock,
      selected_confirmation_form,
      createRequestReason,
      onChangeUnlockHOC,
      createUnlockRequest,
    } = this.props;
    const { can_create } = permissionsChecker("Forms", this.props.data);
    return (
      <>
        <h2 className="at-form-input__title">Reason</h2>
        <AtlasTextarea
          value={createRequestReason}
          onChangeValue={(val) => onChangeUnlockHOC(val, "createRequestReason")}
        />
        <div className="at-modal_dialog-container-footer">
          <CustomButton
            className={"btn-new btn-new--primary mr-3"}
            disabled={createRequestReason.length < 1 || !can_create}
            children={"Submit to admin"}
            onClick={() =>
              createUnlockRequest(
                selected_confirmation_form.id,
                createRequestReason,
              )
            }
          />
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() =>
              onChangeUnlockHOC(false, "showCreateUnlockRequestModal")
            }
          >
            Cancel
          </button>
        </div>
        {onLoadUnlock && <LoadingModal />}
      </>
    );
  };

  renderTableHeading = () => {
    const { can_create } = permissionsChecker("Forms", this.props.data);
    return (
      <div className="at-table_heading">
        <div className="d-flex w-100 grid_gap-2 align-items-center">
          <AtlasIcon
            className="at-icon_svg-table_heading-title"
            svgHref={"atlas-receive-square"}
          />
          <h5 className="fw-600">{"Unlock Download Request"}</h5>
          {can_create && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={
                <>
                  <SvgIcon
                    svgHref="confirmation-form_add"
                    style={{ width: 20, height: 20 }}
                  />
                  New Request
                </>
              }
              containerClass={"ml-2"}
              tooltip={false}
              onClick={() =>
                this.props.onChangeUnlockHOC(
                  true,
                  "showCreateUnlockRequestModal",
                )
              }
            />
          )}
        </div>
        <AlertBox
          title={""}
          containerClassName="w-100"
          description={
            <>
              <span>
                Create a new unlock download request to re-download the receipt.
                You may only download the file once after each approved request.
                <br />
                Please submit a new request if you need to change any of the
                information on the receipt.
              </span>
            </>
          }
        />
      </div>
    );
  };

  renderUnlockDetailsContent = () => {
    const { remark, reason, status, admin_display_name, requested_datetime } =
      this.props.selectedUnlockRequest;
    const { supportingDocs, onChangeSupportHOC, onChangeUnlockHOC } =
      this.props;
    const { can_create, can_destroy } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    const { colorName = "" } =
      _.find(unlockRequestStatusColor, { value: status }) || {};
    return (
      <>
        <div className="d-flex align-items-center flex-wrap mb-3">
          <AtlasCloseButton
            mode={"new"}
            containerStyle={{ marginRight: 8 }}
            onClick={() =>
              onChangeUnlockHOC(false, "showUnlockRequestDetailsModal")
            }
          />
          <h4 className="at-card__title">
            {"Confirmation Form Unlock Request"}
          </h4>
        </div>
        <div className="d-flex flex-column grid_gap-y-2">
          <section className={"at-form__content at-new_container-12"}>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Status: `}</label>
              <p
                className="at-status_badge"
                style={{ ...getColorBadge(colorName) }}
              >
                {status}
              </p>
            </div>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Date created: `}</label>
              <p>{requested_datetime}</p>
            </div>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Reason`}:</label>
              <p>{reason || "-"}</p>
            </div>
          </section>
          <section className="at-form__content at-new_container-12">
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Admin: `}</label>
              <p>{admin_display_name}</p>
            </div>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Admin Remarks:`}</label>
              <p>{remark || "-"}</p>
            </div>
          </section>
          {can_create && (
            <section className="at-form__content at-new_container-16">
              <div>
                <h2 className="at-card_content-title mb-2">Upload Documents</h2>
                <CustomUpload
                  uploadType={"docs"}
                  imageUrl={""}
                  uploadImage={(val, filename) =>
                    this.onUploadDocs(val, filename)
                  }
                />
              </div>
            </section>
          )}
          <CustomTable
            mode={"new"}
            className={"mb-100"}
            columns={DocColumnData}
            rowData={supportingDocs || []}
            actionColumnContent={[
              {
                name: "details",
                tooltipContent: "View File",
                onClick: (rowData) =>
                  this.onClickDocDetails(rowData.document_url),
              },
              ...(can_destroy
                ? [
                    {
                      name: "delete",
                      color: "#F04438",
                      onClick: (rowData) => {
                        onChangeSupportHOC(rowData.id, "selectedDeleteDocsId");
                        onChangeSupportHOC(true, "showDeleteConfirmationModal");
                      },
                    },
                  ]
                : []),
            ]}
            pagination={true}
            emptyStateMessage={"You don't have any documents"}
            renderExtraContent={() => (
              <div className={`at-table_heading`}>
                <AtlasIcon
                  className="at-icon_svg-table_heading-title"
                  svgHref="atlas-document-text"
                />
                <h2 className="at-card_content-title">Supporting Documents</h2>
              </div>
            )}
          />
        </div>
      </>
    );
  };

  render = () => {
    const {
      selected_confirmation_form,
      selectedUnlockRequest,
      unlockRequests,
      onChangeUnlockHOC,
      onChangeSupportHOC,
      getSelectedUnlockRequest,
      selectedDeleteDocsId,
      showCreateUnlockRequestModal,
      showUnlockRequestDetailsModal,
      deleteSupportingDocs,
      getSupportingDocs,
      downloadAckReceipt,
      showDeleteConfirmationModal,
      onChangeConfirmationHOC,
      onLoadUnlock,
      onLoadSupportDocs,
    } = this.props;

    return (
      <div>
        <AlertBox
          mode={"info"}
          title={""}
          containerClassName="mb-3"
          description={`Please double-check that you have entered all of the required information correctly on the Details tab before downloading the temporary receipt. The receipt can only be downloaded once; further downloads are not permitted.`}
        />
        <div className="at-form__content">
          {this.checkRequiredInfo() && (
            <AlertBox
              mode={"danger"}
              description={
                <span style={{ color: "#af1414" }}>
                  Please resolve the following missing information which is
                  marked red.
                </span>
              }
            />
          )}
          {selected_confirmation_form.temp_receipt_closed_editing && (
            <AlertBox
              mode={"danger"}
              containerClassName={this.checkRequiredInfo ? "mt-3" : ""}
              description={
                <span style={{ color: "#af1414" }}>
                  Your one-time download limit has been reached. Please submit
                  an unlock download request if you want to download the file
                  again.
                </span>
              }
            />
          )}
          {DetailsChecking.map((item, index) => (
            <div key={index} className="d-flex align-items-center mt-10">
              {((item.value === "purchaserNTenant" && this.props[item.value]) ||
                Boolean(selected_confirmation_form[item.value])) && (
                <BiCheckCircle
                  style={{ width: 25, height: 25, color: "#00d900" }}
                />
              )}
              {!(
                (item.value === "purchaserNTenant" && this.props[item.value]) ||
                Boolean(selected_confirmation_form[item.value])
              ) && (
                <BiXCircle
                  style={{ width: 25, height: 25, color: "#ff3131" }}
                />
              )}
              <p className="ml-2 fs-2">{item.label}</p>
            </div>
          ))}
          <div className="d-flex mt-3">
            <CustomButton
              disabled={
                selected_confirmation_form.temp_receipt_closed_editing ||
                this.checkRequiredInfo()
              }
              className={"btn-new btn-new--secondary"}
              children={"Download"}
              onClick={() => downloadAckReceipt()}
            />
            <CustomButton
              className={"btn-new btn-new--outline-secondary ml-2"}
              children={"Close"}
              onClick={() =>
                onChangeConfirmationHOC(false, "showConfirmationForm")
              }
            />
          </div>
        </div>
        <CustomTable
          mode={"new"}
          className={"mb-100"}
          columns={ColumnData}
          rowData={unlockRequests || []}
          actionColumnContent={[
            {
              name: "details",
              onClick: (rowData) => {
                getSelectedUnlockRequest(
                  selected_confirmation_form.id,
                  rowData.id,
                );
                getSupportingDocs(
                  this.props.selected_confirmation_form.id,
                  rowData.id,
                );
              },
            },
          ]}
          pagination={true}
          emptyStateMessage={"You don't have any download request yet"}
          emptyStateDescription={
            <>
              Click<b>New Request</b> to re-download the receipt
            </>
          }
          emptyStateIcon={<AtlasIcon svgHref={"atlas-receive-square"} />}
          renderExtraContent={this.renderTableHeading}
        />
        {showCreateUnlockRequestModal && (
          <ModalDialog
            title={"Create request to download Temp Receipt"}
            responsiveSize="lg"
            onClose={() =>
              onChangeUnlockHOC(false, "showCreateUnlockRequestModal")
            }
            children={this.renderUnlockCreateContent()}
          />
        )}
        <AtlasDialog open={showUnlockRequestDetailsModal}>
          <AtlasCard
            className={"h-100"}
            containerStyle={{ background: "#F3F4F6" }}
            cardContent={this.renderUnlockDetailsContent()}
          />
        </AtlasDialog>
        <ConfirmationModal
          open={showDeleteConfirmationModal}
          title={"Delete Supporting Document"}
          loading={onLoadUnlock}
          message={"Are you sure you want to delete this supporting document ?"}
          positiveAction={() =>
            deleteSupportingDocs(
              selected_confirmation_form.id,
              selectedUnlockRequest.id,
              selectedDeleteDocsId,
            )
          }
          negativeAction={() =>
            onChangeSupportHOC(false, "showDeleteConfirmationModal")
          }
        />
        {(onLoadUnlock || onLoadSupportDocs) && <LoadingModal />}
      </div>
    );
  };
}

export default compose(UnlockHOC, SupportHOC)(UnlockContent);
