import React from "react";

const AtlasViewFileModal = ({ selectedFile }) => {
  return (
    <>
      {selectedFile.type === "pdf" && (
        <iframe
          className={"w-100"}
          style={{ height: "80vh" }}
          src={selectedFile ? selectedFile.document : null}
        />
      )}
      {selectedFile.type === "image" && (
        <img
          style={{ width: "100%" }}
          src={selectedFile.document}
          alt={`${selectedFile.document}`}
        />
      )}
    </>
  );
};

export default AtlasViewFileModal;
