import React from "react";
import _ from "lodash";
import { Avatar } from "@material-ui/core";

import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { id: 0, label: "Pending", colorName: "Yellow" },
  { id: 1, label: "Payment Made", colorName: "Lime" },
  { id: 2, label: "Cancelled", colorName: "Red" },
  { id: 2, label: "Success", colorName: "Green" },
];

const ColumnData = [
  {
    header: "Service Type",
    searchFlag: "service_type",
    accessor: "service_type",
  },
  {
    header: "Content",
    searchFlag: "content",
    accessor: "content",
    columnStyle: { width: "150%" },
  },
  {
    header: "Status",
    searchFlag: "status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(
          StatusColor,
          (item) => item.label.toLowerCase() === rowData.status.toLowerCase(),
        ) || {};

      return (
        <div
          className="at-status_badge mt-1"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
  {
    header: "Provider Response",
    searchFlag: "provider_response",
    accessor: "provider_response",
  },
  {
    header: "Created Time",
    searchFlag: "created_at",
    accessor: "created_at",
  },
  {
    header: "Updated Time",
    searchFlag: "updated_at",
    accessor: "updated_at",
  },
];

const fieldData = [
  {
    label: "Recipient",
    value: "recipient",
    icon: "atlas-user",
  },
  {
    label: "Type",
    value: "type",
    icon: "atlas-send-2",
  },
  {
    label: "Code",
    value: "code",
    icon: "atlas-shield-tick",
  },
  {
    label: "Expired At",
    value: "updated_datetime",
    icon: "atlas-calendar",
  },
];

export default function Details({ selectedVerification }) {
  const { sms_logs = [] } = selectedVerification;

  return (
    <>
      <div className="row">
        {fieldData.map((item) => (
          <div
            className={`col-lg-3 col-md-4 col-sm-6 col-12 d-flex align-items-center`}
          >
            <Avatar
              style={{ width: 32, height: 32, backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={item.icon}
                style={{ width: 16, height: 16, fill: "#F0631D" }}
              />
            </Avatar>
            <div className="d-flex flex-column ml-2">
              <label className="at-form-label">{item.label}</label>
              <p className="fs-2">
                {selectedVerification[item.value] || "N/A"}
              </p>
            </div>
          </div>
        ))}
      </div>
      <CustomTable
        hideSearch={true}
        className={"mb-100 mt-20"}
        pagination={true}
        columns={ColumnData}
        rowData={sms_logs}
      />
    </>
  );
}
