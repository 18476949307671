import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import RECorporate from "./RECorporate";

class PersonalRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={"/dashboard/valuations/corporate"}
          component={RECorporate}
        />
        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/valuations/corporate"}
          component={RECorporate}
        />
      </Switch>
    );
  }
}

export default withRouter(PersonalRouter);
