export const HeaderData = [
  { label: "Checkbox", showName: false, classNameWord: "checkbox" },
  { label: "", showName: true, classNameWord: "favourite" },
  { label: "Name", showName: true, classNameWord: "title" },
  { label: "Modified", showName: true, classNameWord: "date" },
  { label: "File Size", showName: true, classNameWord: "size" },
];

export const PreviewPermissions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".bmp",
  ".raw",
  ".tiff",
  ".mp4",
  ".mpeg",
  ".mkv",
  ".mp3",
  ".aux",
  ".wav",
];
