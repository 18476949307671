import React from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AiOutlineExclamationCircle, AiFillCalendar } from "react-icons/ai";
import CustomCheckbox from "components/Checkbox";
import isEmptyValue from "utils/isEmpty";
import { getColorBadge } from "dictionary/badgeColor";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 16,
  },
}))(Tooltip);

export const StatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Processing", colorName: "Blue" },
  { value: "Arrived", colorName: "Green" },
  { value: "Collected", colorName: "Green" },
  { value: "Rejected", colorName: "Red" },
  { value: "Voided", colorName: "Red" },
  { value: "Cancelled", colorName: "Red" },
  { value: "To Pay", colorName: "Cyan" },
];

export const ColumnData = ({
  checkableData,
  checkedArray,
  can_update,

  onHandleCheckAllBox,
  onHandleCheckBox,
  onChangeItemCheckboxValue,
}) => {
  return [
    {
      header: () => (
        <span className="at-table-head-checkbox_responsive">
          <CustomCheckbox
            checked={
              checkedArray.length > 0 &&
              checkedArray.length === checkableData.length
            }
            onChangeCheckboxValue={() => onHandleCheckAllBox()}
          />
        </span>
      ),
      renderColumn: (rowData) => (
        <>
          {[3, 4, 6, 7].indexOf(rowData.status_id) === -1 && (
            <CustomCheckbox
              checked={_.includes(checkedArray, rowData.id)}
              onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
            />
          )}
        </>
      ),
      columnStyle: {
        width: "30%",
        display: !can_update && "none",
        gridColumn: "1/-1",
      },
    },
    {
      header: "Order Number",
      columnStyle: { width: "80%" },
      accessor: "order_number",
    },
    {
      header: "Agent",
      columnStyle: { width: "120%" },
      renderColumn: ({ market_order }) => (
        <>
          <p>{market_order.user.full_name || "N/A"}</p>
          <p>{market_order.user.mobile_contact_number || "N/A"}</p>
          <p>{market_order.user.email || "N/A"}</p>
          <p>{market_order.user.team_name || "N/A"}</p>
          <p>{market_order.user.branch_name || "N/A"}</p>
        </>
      ),
    },
    {
      header: "Product",
      columnStyle: { width: "150%" },
      renderColumn: (rowData) => {
        const {
          market_order,
          market_product,
          attached_items,
          tShirtSize,
          selected_market_product_attached_product: product,
          selected_market_product_attached_product_type: product_type,
        } = rowData;
        const { name, type_id } = market_product;

        const { payment_amount_type, delivery_branch_name } = market_order;

        return (
          <>
            <span className="fw-600 fs-1 badge badge-pill py-1 px-2 bg-primary color-white inline-flex-align-center">
              {rowData.product_type}
            </span>
            <p>{name || "N/A"}</p>
            {type_id === 1 && (
              <>
                {!isEmptyValue(attached_items) && (
                  <div className={"d-flex flex-column my-2 grid_gap-1"}>
                    {attached_items.map((item, index) => {
                      const {
                        id,
                        is_collected = false,
                        item_name = "N/A",
                      } = item;
                      return (
                        <CustomCheckbox
                          disabled={false}
                          checked={is_collected}
                          content={item_name}
                          onChangeCheckboxValue={(e) => {
                            let tmpMarketAttchedItems = [...attached_items];
                            tmpMarketAttchedItems[index].is_collected =
                              e.target.checked;
                            return onChangeItemCheckboxValue(
                              {
                                ...rowData,
                                attached_items: tmpMarketAttchedItems,
                              },
                              e.target.checked,
                              index,
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}
                <hr className="mt-1 mb-2" />
                <p>{`${payment_amount_type} ( ${tShirtSize ?? "N/A"} )`}</p>
                <p>{rowData.purchase_type}</p>
                <p>{`Collect from ${!isEmptyValue(delivery_branch_name) ? delivery_branch_name : "N/A"}`}</p>
              </>
            )}
            <p>{rowData.title}</p>
          </>
        );
      },
    },
    {
      header: "Quantity",
      columnStyle: { width: "50%" },
      renderColumn: (rowData) =>
        `${rowData.quantity} ${rowData.market_product.unit_measurement ?? ""}`,
    },
    {
      header: "Unit Price",
      columnStyle: { width: "100px", minWidth: "100px" },
      contentClass: "at-column-responsive_right",
      customClass: "at-column-responsive_right",
      accessor: "price",
    },
    {
      header: "Status",
      columnStyle: { width: "70%" },
      contentClass: "at-column-responsive_center",
      customClass: "at-column-responsive_center",
      renderColumn: (rowData) => {
        const { colorName = "" } =
          _.find(StatusColor, { value: rowData.status }) || {};

        return (
          <>
            <div
              className="at-status_badge"
              style={{ ...getColorBadge(colorName) }}
            >
              {rowData.status === "Rejected" && (
                <DarkTooltip
                  placement={"bottom"}
                  title={rowData.remark || "N/A"}
                >
                  <AiOutlineExclamationCircle
                    style={{
                      width: 17,
                      height: 17,
                      marginRight: 5,
                      color: getColorBadge(colorName).color,
                    }}
                  />
                </DarkTooltip>
              )}
              {rowData.status}
            </div>
            {rowData.status !== "Rejected" && rowData.resubmit_date && (
              <DarkTooltip
                placement={"bottom"}
                title={
                  rowData.resubmit_date
                    ? `Resubmit on: ${rowData.resubmit_date}`
                    : "N/A"
                }
              >
                <AiFillCalendar
                  style={{ width: 17, height: 17, marginLeft: 5 }}
                />
              </DarkTooltip>
            )}
            {rowData.status === "Collected" && rowData.collected_date && (
              <p>{`${Moment(rowData.collected_date, "YYYY-MM-DD").format("DD MMM YYYY")}`}</p>
            )}
          </>
        );
      },
    },
  ];
};

export const OrderIteamHeader = [
  {
    label: "PRODUCT",
    value: "product_name",
    containerStyle: { width: "55%" },
  },
  {
    label: "PRICE",
    value: "price",
    containerStyle: { width: "15%" },
  },
  {
    label: "QUANTITY",
    value: "quantity",
    containerClass: "text-lg-center",
    containerStyle: { width: "15%" },
  },
  {
    label: "TOTAL",
    value: "total_price",
    containerStyle: { width: "15%" },
  },
];
