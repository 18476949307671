import { useState } from "react";
import { MarvelDevices } from "react-css-devices";
import Frame from "react-frame-component";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";

import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasEmailModal from "components/Modal/email";

import withSendTestEmail from "actions/sendTestEmail";

const EmailPreview = ({
  email,
  selectedEmailTemplate,
  toggleEmailPreview,
  onLoadSendTestEmail,
  showEmailDialog,
  onChangeSendTestEmailHOC,
  onChangeEmailTemplatesHOC,
  sendTestEmail,
}) => {
  const { html_code, subject } = selectedEmailTemplate;

  const [view, setView] = useState("desktop");
  const [orientation, setOrientation] = useState("portrait");

  return (
    <div className="at-email-templates__form__card">
      <div className={"d-flex align-items-center g-3 mb-3"}>
        <div className="at-email-template__btn-float-group">
          <button
            className={`btn-float ${view === "desktop" ? "btn-float-selected" : ""}`}
            onClick={() => setView("desktop")}>
            <AtlasIcon svgHref={"atlas-monitor"} />
            Desktop
          </button>
          <button
            className={`btn-float ${view === "mobile" ? "btn-float-selected" : ""}`}
            onClick={() => setView("mobile")}>
            <AtlasIcon svgHref={"atlas-mobile"} />
            Mobile
          </button>
        </div>
        {view === "mobile" && (
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => setOrientation(orientation === "portrait" ? "landscape" : "portrait")}>
            <AtlasIcon svgHref={"atlas-rotate-right-linear"} style={{ width: 20, height: 20, marginRight: 4 }} />
            Rotate
          </AtlasButton>
        )}
      </div>

      {view === "desktop" && (
        <div className={"email-preview"}>
          <div className={"email-header"}>
            <p><span className="fw-600">From:</span> {email}</p>
            <p><span className="fw-600">Subject:</span> {subject}</p>
          </div>

          <div className={"email-body"}>
            {html_code && (
              <Frame className={"iframe"}>{ReactHtmlParser(html_code)}</Frame>
            )}
          </div>
        </div>
      )}
      {view !== "desktop" && (
        <div className={"device-wrapper"}>
          <MarvelDevices
            deviceName={"ipad"}
            orientation={orientation}
            transform={0.5}
          >
            <div className={"device-email-body"}>
              {html_code && (
                <Frame className={"iframe"}>
                  {ReactHtmlParser(html_code)}
                </Frame>
              )}
            </div>
          </MarvelDevices>
        </div>
      )}

      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          type={"button"}
          children={'Edit Content'}
          className={"btn-new btn-new--secondary"}
          onClick={toggleEmailPreview}
        />
        <AtlasButton
          type={"button"}
          children={'Send a Test'}
          className={"btn-new btn-new--secondary"}
          onClick={() => onChangeSendTestEmailHOC("showEmailDialog", true)}
        />
        <AtlasButton
          type={"button"}
          onClick={() => onChangeEmailTemplatesHOC("showUpdateModal", false)}
          children={'Close'}
          className={"btn-new btn-new--outline-secondary"}
        />
      </div>

      <AtlasEmailModal
        title={"Send a Test"}
        loading={onLoadSendTestEmail}
        showSendEmail={showEmailDialog}
        closeSendEmail={() =>
          onChangeSendTestEmailHOC("showEmailDialog", false)
        }
        submitEmail={(val) =>
          sendTestEmail({
            recipients: val,
            template_id: selectedEmailTemplate.id,
          })
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ email: state.profileReducer.email });

export default withSendTestEmail(connect(mapStateToProps)(EmailPreview));
