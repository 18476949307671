import React, { useEffect } from "react";
import { compose } from "redux";
import _ from "lodash";

import Tabs from "components/Tab";

import DetailTab from "./tabs/Details";
import PhotosTab from "./tabs/Photo";
import FloorPlanTab from "./tabs/FloorPlan";
import JuwaiSubsTab from "./tabs/JuwaiSubs";
import MediaLink from "./tabs/MediaLink";
import RecommendationSettings from "./tabs/RecommendationSettings";

import SubsalesListingPreview from "../components/subsalesListingPreview";

import DetailsHOC from "../actions/detail";
import EditHOC from "../actions/edit";

import "../index.scss";
import "stylesheets/containers/personal/subsales.scss";

const Edit = (props) => {
  const sections = [
    { id: "Details", label: props.getLocalised("label.details.details.title", "Details") },
    { id: "Photos", label: props.getLocalised("label.photos.title", "Photos") },
    { id: "Floor Plans", label: props.getLocalised("label.floor_plan.floor_plans.title", "Floor Plans") },
    { id: "Media Links", label: props.getLocalised("label.media_links.media_links.title", "Media Links") },
    { id: "Juwai Subscription", label: props.getLocalised("label.juwai_subscription.title", "Juwai Subscription") },
    { id: "Recommendation Settings", label: props.getLocalised("label.recommendation_settings.title", "Recommendation Settings") }
  ]
  
  const SubsalesType = [
    { value: 1, label: props.getLocalised('option.details.details.type_1', 'Sale') },
    { value: 2, label: props.getLocalised('option.details.details.type_2', 'Rental') }
  ];

  useEffect(() => {
    !props.showEditModal && props.onChangeEditHOC(props.getLocalised("label.details.details.title", "Details"), "selectedSection");
  }, [props.showEditModal]);

  useEffect(() => {
    const tmpSelectedSubsales = props.subsalesEntryCreationProps;
    let editSubsalesType = _.find(SubsalesType, {
      id: tmpSelectedSubsales.type_id,
    });
  }, [props.subsalesEntryCreationProps]);

  const renderCardContent = () => {
    if (props.selectedSection === "Details") {
      return <DetailTab {...props} />;
    } else if (props.selectedSection === "Photos") {
      return <PhotosTab {...props} />;
    } else if (props.selectedSection === "Floor Plans") {
      return <FloorPlanTab {...props} />;
    } else if (props.selectedSection === "Juwai Subscription") {
      return (
        <JuwaiSubsTab
          getLocalised={props.getLocalised}
          agent_juwai_account={
            props.subsalesEntryCreationProps.agent_juwai_account
          }
        />
      );
    }

    if (props.selectedSection === "Media Links") {
      return <MediaLink {...props} />;
    }

    if (props.selectedSection === "Recommendation Settings") {
      return <RecommendationSettings {...props} />;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <Tabs
          containerStyle={{ minWidth: "auto", marginRight: "8px" }}
          sections={sections}
          selectedSection={props.selectedSection}
          onSelectSection={(val) =>
            props.onChangeSubsalesHOC(val, "selectedSection")
          }
          getLocalised={props.getLocalised}
        />
      </div>
      {renderCardContent()}
      <SubsalesListingPreview {...props} />
    </>
  );
};

export default compose(EditHOC, DetailsHOC)(Edit);
