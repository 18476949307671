import React, { useEffect, useState } from "react";
import Moment from "moment";
import _ from "lodash";
import { FaUserTie } from "react-icons/fa";
import ReactHTMLParser from "react-html-parser";

import AtlasIcon from "components/Icon/atlasIcon";

const GeneralData = [
  {
    label: "Mobile",
    icon: <AtlasIcon svgHref={"atlas-mobile"} />,
    value: "mobile_contact_number",
  },
  { label: "Email", icon: <AtlasIcon svgHref={"atlas-sms"} />, value: "email" },
  {
    label: "Board Registration No.",
    icon: <AtlasIcon svgHref={"atlas-personalcard"} />,
    value: "license_number",
  },
  {
    label: "Birthday",
    icon: <AtlasIcon svgHref={"atlas-cake"} />,
    value: "date_of_birth",
  },
  {
    label: "Member since",
    icon: <AtlasIcon svgHref={"atlas-calendar"} />,
    value: "created_at",
  },
];

const BankData = [
  {
    label: "Bank Name",
    icon: <AtlasIcon svgHref={"atlas-bank"} />,
    value: "accountBank",
  },
  { label: "Holder Name", icon: <FaUserTie />, value: "accountName" },
  {
    label: "Account Number",
    icon: <AtlasIcon svgHref={"atlas-card"} />,
    value: "accountNumber",
  },
];

const UserInfoList = ({
  selectedUser,
  bankDetails,
  editPermission,

  onClickEditButton,
}) => {
  let { description, role, youtube_playlist_url } = selectedUser;

  const [fieldData, onChangeFieldata] = useState(GeneralData);
  
  useEffect(() => {
    if(role) {
      let tempFieldData = _.cloneDeep(fieldData);
      if (role !== "Agent") {
        let tempSplice = [10, 8, 7, 6, 5, 3, 2];
        if (role === "Associated Agent") {
          tempSplice.pop();
        }
        for (let index = 0; index < tempSplice.length; index++) {
          tempFieldData.splice(tempSplice[index], 1);
        }
      }
  
      if ((role === "Personal Assistant" || role === "Agent") && !tempFieldData?.some(field => field.value === "country_name")) {
        tempFieldData.splice(
          2,
          0,
          ...[
            {
              label: role === "Agent" ? "Referrer" : "Supervisor",
              icon: <AtlasIcon svgHref={"atlas-profile-circle"} />,
              value: role === "Personal Assistant" ? "supervisor" : "referrer_name",
            },
            {
              label: "Country",
              icon: <AtlasIcon svgHref={"atlas-global"} />,
              value: "country_name",
            },
            {
              label: "Branch",
              icon: <AtlasIcon svgHref={"atlas-location"} />,
              value: "branch_name",
            },
          ],
        );
      }
      onChangeFieldata(tempFieldData);
    }
  }, [role]);

  return (
    <div className={"at-form__content"}>
      <h4 className="d-flex align-items-center mb-3">
        About
        {editPermission && (
          <button
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClickEditButton()}
          >
            <AtlasIcon
              svgHref={"atlas-user-edit"}
              style={{ width: 20, height: 20 }}
            />
            Edit Profile
          </button>
        )}
      </h4>
      <ul className="at-card--profile-list">
        {fieldData.map((fieldItem) => (
          <li className="at-card--profile-item grid-3-col">
            <div className={"d-flex align-items-center"}>
              <div className="at-badge-icon-sm at-badge-icon-warning">
                {fieldItem.icon}
              </div>
              <div className="text-left">
                <label>{fieldItem.label}</label>
                <p className="justify-content-start">
                  {fieldItem.value === "created_at" &&
                    (selectedUser[fieldItem.value]
                      ? Moment(selectedUser[fieldItem.value]).format(
                          "DD MMM YYYY",
                        )
                      : "N/A")}
                  {fieldItem.value === "date_of_birth" &&
                    (selectedUser[fieldItem.value]
                      ? selectedUser[fieldItem.value]
                      : "N/A")}
                  {fieldItem.value !== "created_at" &&
                    fieldItem.value !== "date_of_birth" &&
                    (selectedUser[fieldItem.value] || "N/A")}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {role === "Agent" && (
        <>
          <hr />
          <h4 className="d-flex align-items-center mb-3">{`Bank Account`}</h4>
          <ul className="at-card--profile-list">
            {BankData.map((bankItem) => (
              <li className="at-card--profile-item grid-3-col">
                <div className={"d-flex align-items-center"}>
                  <div className="at-badge-icon-sm at-badge-icon-warning">
                    {bankItem.icon}
                  </div>
                  <div>
                    <label>{bankItem.label}</label>
                    <p className="justify-content-start">
                      {bankDetails[bankItem.value] || "N/A"}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      {role === "Agent" && (
        <>
          <hr />
          <h4 className="d-flex align-items-center mb-3">{`Biography`}</h4>
          {ReactHTMLParser(description)}
        </>
      )}
    </div>
  );
};

export default UserInfoList;
