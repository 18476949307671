import React, { Component } from "react";
import Moment from "moment";
import { compose } from "redux";
import _ from "lodash";

import SvgIcon from "components/Icon/new";
import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasSimpleTable from "components/NewTable/simple";
import CustomRadioGroup from "components/RadioGroup";
import LoadingModal from "components/LoadingModal";
import AtlasUploadedDocs from "./UploadedDocs";
import UploadBankDetail from "./uploadBankDetail";
import permissionsChecker from "utils/permissionsChecker";

import SubsaleDocumentHOC from "../../actions/document";
import SubsaleClaimPreviewHOC from "../../actions/preview";
import WithDocuments from "./actions";
import { document_types as DocDictionary } from "dictionary/subsales";

const relationshipOptions = subsalesTypeId => 
  subsalesTypeId === 1
  ? [
    { label: "Vendor", value: 1 },
    { label: "Purchaser", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Vendor/Purchaser's Sibling", value: 4 },
    { label: "Vendor/Purchaser's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]
  : [
    { label: "Landlord", value: 1 },
    { label: "Tenant", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Landlord/Tenant's Sibling", value: 4 },
    { label: "Landlord/Tenant's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]

const transferMethodRadioOptions = [
  {
    value: 2,
    label: "Cash",
  },
  {
    value: 1,
    label: "Cheque Deposit",
  },
  {
    value: 6,
    label: "Credit Card",
  },
  {
    value: 3,
    label: "Online Transfer",
  },
  {
    value: 5,
    label: "Foreign Transfer",
  },
];

const TransactionDetail = [
  { label: "Bank In To", value: "bank_name" },
  { label: "Amount", value: "amount" },
  { label: "Payment made by", value: "payer_type" },
  { label: "Transfer Method", value: "payment_method" },
  { label: "Reference", value: "reference_no" },
  { label: "Status", value: "status" },
  { label: "Admin", value: "user_display_name" },
  { label: "Remark", value: "remark" },
];

const ColumnData = subsalesTypeId => [
  {
    header: "Uploaded At",
    searchFlag: "created_at",
    accessor: "created_at",
    columnStyle: { width: "50%" },
  },
  {
    header: "File Name",
    searchFlag: "snapshot_file_name",
    accessor: "snapshot_file_name",
  },
  {
    header: "Document Type",
    searchFlag: "doc_type",
    accessor: "doc_type",
    columnStyle: { width: "180%" },
    renderColumn: (rowData) => {
      let bankInSlip = rowData.bank_in_detail;
      const {
        payment_method_id = "",
        payer_type_id = ""
      } = bankInSlip || {}
      const {label: payer_type_label = ""} = relationshipOptions(subsalesTypeId).find(option => option.value === payer_type_id) || {}
      const {label: payment_method_label = ""} = transferMethodRadioOptions.find(option => option.value === payment_method_id) || {}

      if (bankInSlip) {
        bankInSlip = {
          ...bankInSlip,
          payment_method: payment_method_label,
          payer_type: payer_type_label
        }
      }
      return (
        <>
          <p>{rowData.doc_type}</p>
          {bankInSlip && (
            <div className="at-form__content at-document__bank-in-slip-detail row">
              <p className="col-6 px-1">
                <p style={{ fontWeight: 600 }}>Payment Date: </p>
                {bankInSlip?.transacted_at
                  ? Moment(bankInSlip.transacted_at).format("DD MMM YYYY")
                  : "N/A"}
              </p>
              {TransactionDetail.map((detail) => (
                <p className="col-6 px-1">
                  <p style={{ fontWeight: 600 }}>{detail.label}: </p>
                  {bankInSlip?.[detail.value] || "N/A"}
                </p>
              ))}
            </div>
          )}
        </>
      );
    },
  },
];

class Document extends Component {
  state = {
    document_types: [],
    selectedDocType: 0,
    uploadedDocs: [],
    mandatory: [],
    showUploadBankSlip: false,
  };

  componentDidMount = () => {
    this.props.getDocuments(this.props.selected_claim.id);
    this.setState({ document_types: this.processDocTypes() });
  };

  componentDidUpdate = (pp) => {
    if (this.props.documents.length !== pp.documents.length) {
      let tmpDocTypes = this.state.document_types;
      let tmp = [];
      tmpDocTypes.map((item) => {
        let tmpData = { ...item };
        let tmpFlag = false;
        this.props.documents.map((child) => {
          if (item.name === child.doc_type) {
            tmpFlag = true;
          }
        });
        tmpData.uploaded = tmpFlag;
        tmp.push(tmpData);
      });
      return this.setState({ document_types: tmp });
    }
  };

  processDocTypes = () => {
    if (this.props.selected_claim.sub_sale_type_id === 1) {
      this.setState({ mandatory: [2, 4, 5] });
    } else if (this.props.selected_claim.form_type_id === 4) {
      this.setState({ mandatory: [4, 6] });
    } else {
      this.setState({ mandatory: [2, 12, 6, 4] });
    }
    let tmpData = [];
    DocDictionary.map((item) => {
      tmpData.push({
        ...item,
        value: item.id,
        label:
          item.id === 4
            ? "Bank In Slip (upload this to generate temporarily receipt)"
            : item.name,
      });
    });
    return tmpData;
  };

  selectedDocs = (id) =>
    this.setState({ selectedDocType: id }, () => {
      if (id === 4) {
        this.setState({ showUploadBankSlip: true });
      } else {
        this.pond.click();
      }
    });

  onUploadDocs = (e) => {
    this.setState({ uploadedDocs: [] });
    if (e.target.files && e.target.files.length > 0) {
      let files = e.target.files;
      Object.keys(e.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = _.cloneDeep(this.state.uploadedDocs);
          tmp.push({
            snapshot: e.target.result,
            snapshot_file_name: files[key].name,
            type_id: this.state.selectedDocType,
            done: false,
            success: false,
            errorMessage: "",
            loading: true,
          });
          this.setState({ uploadedDocs: tmp }, () => {
            if (tmp.length === files.length) {
              this.props.uploadMultipleDocuments(
                this.props.selected_claim.id,
                tmp,
              );
            }
          });
        };
        reader.readAsDataURL(e.target.files[key]);
      });
    }
  };

  onClickRemoveUploadedDocs = (id, doc_id) =>
    this.props.deleteDocument(this.props.selected_claim.id, doc_id);

  renderTableHeading = () => {
    return (
      <div className={`at-table_heading`}>
        <SvgIcon
          svgHref="confirmation-form_document-text"
          className="at-icon_svg-table_heading-title"
        />
        <h5 className="fw-600">Uploaded Document</h5>
        <button
          className={"btn-new btn-new--secondary"}
          onClick={() => this.props.getPreview(this.props.selected_claim.id)}
        >
          Preview
        </button>
        {/* // hide for temporary */}
        {/* {this.props.selected_claim.combined_pdf_url &&
          this.props.selected_claim.combined_pdf_url.length > 0 && (
            <button
              className={"btn-new btn-new--secondary"}
              onClick={() =>
                window.open(
                  this.props.selected_claim.combined_pdf_url,
                  "_blank",
                )
              }
              disabled={false}
            >
              {"View Combined Doc"}
            </button>
          )} */}
      </div>
    );
  };

  render = () => {
    let { documents } = this.props;
    const { can_destroy, can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    return (
      <>
        <div className="at-form__content">
          <div className="d-flex align-items-center fs-3 fw-500 mb-3">
            <div className="at-badge-icon-sm at-badge-icon-warning">
              <AtlasIcon svgHref={"atlas-document-text"} />
            </div>
            Documents
          </div>
          <AlertBox
            mode={"info"}
            containerClass="mb-3"
            description={
              <>
                <p
                  className={"subsaleclaim-doc-title"}
                  style={{ fontWeight: "400" }}
                >
                  Accepted file types: pdf, jpg, jpeg and png.
                </p>
                <p style={{ fontWeight: "400" }}>
                  You can select multiple documents for the same type.
                </p>
                <p style={{ fontWeight: "400" }}>
                  Please use CamScanner mobile app to snapshot your document to
                  convert into PDF.
                </p>
              </>
            }
          />
          <CustomRadioGroup
            mode={"old"}
            horizontal={false}
            containerClassName={"subsaleclaim-doc-doctypes-radio"}
            componentClassName={"at-document__radio-control"}
            checkedValue={this.state.selectedDocType}
            specialChecker={(uploaded) => (
              <AtlasIcon
                svgHref={"atlas-document-upload"}
                style={{ fill: uploaded && "#12B76A" }}
                className={"at-document__checker"}
              />
            )}
            options={this.state.document_types}
            selectedRadioValue={(val) => this.selectedDocs(val)}
            status={true}
            mandatory={this.state.mandatory}
            disabled={!can_create}
          />
          {this.state.selectedDocType > 0 && (
            <div style={{ display: "none" }}>
              <input
                ref={(ref) => (this.pond = ref)}
                type="file"
                accept="image/jpeg, image/jpg, image/png, application/pdf"
                multiple={true}
                onChange={(e) => this.onUploadDocs(e)}
                onClick={(e) => (e.target.value = null)}
              />
            </div>
          )}
        </div>
        <AtlasSimpleTable
          mode={"new"}
          rowData={documents}
          columns={ColumnData(this.props.selected_claim.sub_sale_type_id)}
          hideSearch={true}
          renderExtraContent={this.renderTableHeading}
          emptyStateMessage={"No uploaded docs are found."}
          actionColumnContent={[
            {
              name: "details",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 18, height: 18, fill: "#007bff" },
              onClick: (rowData) => window.open(rowData.snapshot_url, "_blank"),
            },
            {
              name: "delete",
              disabled: () => !can_destroy,
              onShow: (rowData) => rowData.type_id !== 4,
              iconClass: `at-table-new-icon-button ${!can_destroy ? "opacity-50" : ""}`,
              iconStyle: { width: 18, height: 18, fill: "#EB3134" },
              onClick: (rowData) =>
                this.onClickRemoveUploadedDocs(this.state.id, rowData.id),
            },
          ]}
        />
        <div
            className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}
          >
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() => this.props.onClickNav("Participants")}
          >
            <p>Participants</p>
          </button>
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() => this.props.onClickNav("Submit Claims")}
          >
            <p>Submission</p>
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onCloseForm()}
          >
            Close
          </button>
        </div>
        {this.state.showUploadBankSlip && (
          <UploadBankDetail
            onLoadOCR={this.props.onLoadOCR}
            OCRContent={this.props.OCRContent}
            selectedDocType={this.state.selectedDocType}
            onLoadDocuments={this.props.onLoadDocuments}
            selected_claim_id={this.props.selected_claim.id}
            subsalesTypeId={this.props.selected_claim.sub_sale_type_id}
            bankInSlipOCR={this.props.bankInSlipOCR}
            uploadMultipleDocuments={this.props.uploadMultipleDocuments}
            onClickClose={() => this.setState({ showUploadBankSlip: false })}
          />
        )}
        {this.props.showUploadedDocs && (
          <AtlasUploadedDocs
            showUploadedDocs={this.props.showUploadedDocs}
            uploadedDocs={this.props.uploadedDocs}
            closeUploadedDocsList={this.props.closeUploadedDocsList}
          />
        )}
        {(this.props.onLoadDocuments ||
          this.props.onLoadPreview ||
          this.props.onLoadMultipleDocs) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  SubsaleDocumentHOC,
  SubsaleClaimPreviewHOC,
  WithDocuments,
)(Document);
