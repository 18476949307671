import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";

import CustomSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasTextarea from "components/Input/textarea";
import CustomFormInput from "components/Input/formInput";
import CustomImageUploader from "components/Input/imageUpload";

const bankOptions = [
  { label: "MBB - Maybank 564164441931 IQI REALTY SDN. BHD.", value: 1 },
  { label: "PBB - Public Bank 3197608334 IQI REALTY SDN. BHD.", value: 6 },
];

const PaymentForm = (props) => {
  const [paymentProve, setPaymentProve] = useState({
    payment_date: "",
    bank_id: "",
    amount: "",
    reference: "",
  });
  const [doc, setDoc] = useState({});

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          setDoc({
            source: e.target.result,
            name: fileItems[0].file.name,
          });
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      setDoc({});
    }
  };

  const onToggleSubmit = () => {
    const data = {
      ...paymentProve,
      payment_slip: doc.source,
      payment_slip_file_name: doc.name,
      booking_id: props.selectedRoomBooking.id,
    };
    props.createManualPayment(data);
  }

  return (
    <>
      <div className="grid-control">
        <section className="grid-full-col">
          <label className="at-form-input__title" required>Payment Date</label>
          <div className="at-form-input">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              withPortal
              dateFormat={"DD-MM-YYYY"}
              className="at-form-input__input"
              maxDate={Moment()}
              placeholderText="Please enter date"
              value={paymentProve.payment_date}
              onChange={(val) => setPaymentProve({ ...paymentProve, payment_date: Moment(val).format("DD-MM-YYYY")})}
            />
          </div>
        </section>
        <section className="grid-full-col">
          <label className="at-form-input__title" required>Bank In To</label>
          <CustomSelect
            required={true}
            value={paymentProve.bank_id}
            placeholder={
              "Select the bank to which client has made the transfer"
            }
            onChange={(e) => setPaymentProve({ ...paymentProve, bank_id: e.target.value })}
          >
            {bankOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ fontSize: 14 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>Amount</h2>
          <CustomFormInput
            type={"number"}
            required={true}
            value={paymentProve.amount}
            placeholder={"Amount"}
            onChangeValue={(val) => setPaymentProve({ ...paymentProve, amount: parseFloat(val) })}
          />
        </section>
        <section className="grid-full-col">
          <label className="at-form-input__title" required>Reference</label>
          <AtlasTextarea
            className={"mb-0"}
            required={true}
            value={paymentProve.reference}
            placeholder={"Reference"}
            onChangeValue={(val) => setPaymentProve({ ...paymentProve, reference: val })}
            instruction={
              "Please state payment reference for (instant transfer, foreign transfer & IBG)"
            }
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>Receipt</h2>
          <CustomImageUploader
            fullWidth
            required={true}
            files={(doc.source && doc.name)
              ? [doc]
              : []
            }
            allowMultiple={false}
            acceptedFileTypes={["image/*"]}
            accept="image/*"
            maxFiles={1}
            onRemoveFile={() => setDoc({})}
            onChangeFile={(fileItems) => onChangeFile(fileItems)}
          />
        </section>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          className="btn-new btn-new--primary"
          disabled={_.values(paymentProve).some(param => param === "") || _.isEmpty(doc)}
          onClick={() => onToggleSubmit()}
        >
          Submit
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => props.onChangeRoomBooking(false, "showPaymentModal")}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default PaymentForm;