import React, { Component } from "react";
import _ from "lodash";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";

import ImportHOC from "../actions/import";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class ImportContent extends Component {
  render = () => {
    return (
      <ModalDialog
        title={"Import Leads"}
        onLoad={this.props.onLoadImport}
        onClose={() => this.props.onChangeLeadsHOC(false, "showImportModal")}
        children={
          <div className="at-input_upload-cont custom-excel_upload">
            <div className={`at-input_uploader-cont ${!_.isEmpty(this.props.fileItem) ? "at-input_uploaded" : ""}`}>
              <AtlasIcon svgHref={"atlas-document-upload"} />
              <FilePond
                acceptedFileTypes={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "application/vnd.ms-excel",
                  "text/csv",
                ]}
                labelIdle=" or drag and drop "
                allowMultiple={false}
                fullWidth
                maxFiles={1}
                className={`at-input_upload-file`}
                onremovefile={() => {
                  this.props.onChangeImportHOC({}, "fileItem");
                  this.props.onChangeImportHOC("", "fileName");
                }}
                onupdatefiles={(fileItems) => {
                  if (fileItems.length > 0 && fileItems[0].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.props.onChangeImportHOC(e.target.result, "fileItem");
                      this.props.onChangeImportHOC(
                        fileItems[0].file.name,
                        "fileName",
                      );
                    };
                    reader.readAsDataURL(fileItems[0].file);
                  } else {
                    this.props.onChangeImportHOC({}, "fileItem");
                    this.props.onChangeImportHOC("", "fileName");
                  }
                }}
              />
            </div>
          </div>
        }
        footer= {
          <div className="d-flex align-items-center g-3">
            <button
              disabled={Object.values(this.props.fileItem || {}) < 1}
              className={"btn-new btn-new--primary"}
              onClick={() =>
                this.props.importExcel({
                  file: this.props.fileItem,
                  file_name: this.props.fileName,
                })
              }
            >
              Submit Import
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() =>
                this.props.onChangeLeadsHOC(false, "showImportModal")
              }
            >
              Cancel
            </button>
          </div>
        } />
    );
  };
}

export default ImportHOC(ImportContent);
