import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactHTMLParser from "react-html-parser";
import _ from "lodash";
import { FiEdit } from "react-icons/fi";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/confirmation";
import PhotoSlider from "./PhotoSlider";
import RecommendSection from "./Recommend";

import defaultImage from "assets/images/iqi_logo_only.png";
import { featureProperty, detailsSection, publishPlatformLib } from "./assets";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import { numberWithCommas } from "utils/thousandSeparator";
import { getLocalisedDate, getLocalisedKeyPrefix, processLocalisedKey} from "utils/processLocalised";
import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const View = ({
  data,
  initialRecommendation,
  fullRecommendationList,
  mediaLink,
  status_permissions,
  subsalesEntryCreationProps = {},
  floorPlanListing,
  descriptionTab,
  showViewModal,

  onLoadFavourite,
  onLoadSubsales,
  onLoadView,
  onLoadMediaLink,
  onLoadCobroke,

  onChangeSubsalesHOC,
  onChangeDeleteHOC,
  duplicateSubsales,
  toggleFavourite,
  getMediaLinks,
  updateRCCCobrokeStatus,
  getLocalised,
  renderSpecialMessage
}) => {
  const {
    code,
    status,
    status_id,
    asking_price_cents,
    asking_price_currency,
    id,
    address,
    agent_info,
    property_name,
    images,
    description,
    zh_description,
    facilities = [],
    other_facilities,
    built_up,
    land_area,
    measurement,
    is_favourite,
    featureArray,
    published_to_iqi,
    exported_iqi_link,
    published_to_juwai,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    exported_juwai_asia_link,
    open_for_internal_co_broke,
    co_broke_settings,
  } = subsalesEntryCreationProps;
  const { can_read } = permissionsChecker("Co Broke Center", data);
  const { can_destroy } = permissionsChecker("Listing Centre", data);
  const can_delete = [0, 2, 4].includes(status_id) && can_destroy;
  
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [customStyle, setCustomStyle] = useState(`
    grid-template-areas: ${`
      "header"
      "gallery"
      ${open_for_internal_co_broke && fullRecommendationList.length > 0 && status_id === 1 ? '"recommendation"' : ""}
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
    `}
  `);

  const cobrokeSetting = [
    {
      label: getLocalised("label.details.co_broke.commission", "Commission"),
      value: "total_portion",
      icon: "atlas-percentage-circle",
      colorName: "Teal",
    },
    {
      label: getLocalised("label.details.co_broke.my_portion", "My Portion"),
      value: "my_portion",
      icon: "atlas-profile-circle",
      colorName: "Blue",
    },
    {
      label: getLocalised("label.details.co_broke.co_broke_agent_portion", "Co-broke Agent Portion"),
      value: "balance",
      icon: "atlas-shake-hand",
      colorName: "Fuchsia",
    },
  ];

  useEffect(() => {
    let tmpStyle = `
      "header"
      "gallery"
      ${open_for_internal_co_broke && fullRecommendationList.length > 0 && status_id === 1 ? '"recommendation"' : ""}
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
    `;
    if (!published_to_iqi || !exported_iqi_link) {
      if (
        !published_to_juwai ||
        (!exported_to_juwai && !juwai_link && !exported_juwai_asia_link)
      ) {
        tmpStyle = tmpStyle.replace(`"subsalesPublishedPlatforms"`, "");
      }
    }

    if (!_.some(facilities, { value: true })) {
      tmpStyle = tmpStyle.replace(`"facilities"`, "");
    }

    if (!_.some(featureArray, { value: true })) {
      tmpStyle = tmpStyle.replace(`"subsalesFeatures"`, "");
    }

    setCustomStyle(`grid-template-areas: ${tmpStyle}`);
  }, [subsalesEntryCreationProps]);

  useEffect(() => {
    if (showViewModal) {
      getMediaLinks(id);
    }
  }, [showViewModal]);

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ title, linkSrc, logoSrc }) => {
      return (
        <a
          href={linkSrc}
          target="_blank"
          className="at-subsales_listing_view-published_platforms-item"
        >
          <div className="d-flex align-items-center">
            <img src={logoSrc} />
            <span>{title}</span>
          </div>
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
        </a>
      );
    };

    return (
      ((published_to_iqi && exported_iqi_link) ||
        (published_to_juwai &&
          exported_to_juwai &&
          (juwai_link || exported_juwai_asia_link)) ||
        (exported_to_edgeprop && published_to_edge_prop)) && (
        <div className="at-subsales_listing__view-published_platforms">
          <div className="at-subsales-listing__view-title">
            <AtlasIcon
              svgHref={"atlas-global"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            {getLocalised("label.published_platforms.title", "Published Platforms")}
          </div>
          {publishPlatformLib(getTranslation, selectedLocalised).map((item, index) => {
            let tmpCheckingLink =
              subsalesEntryCreationProps[item.linkSrc] &&
              subsalesEntryCreationProps[item.linkSrc].length > 0 &&
              subsalesEntryCreationProps[item.published] &&
              (item.exported
                ? subsalesEntryCreationProps[item.exported]
                : true);
            return (
              tmpCheckingLink && (
                <LogoPlatform
                  {...item}
                  key={index}
                  linkSrc={subsalesEntryCreationProps[item.linkSrc]}
                />
              )
            );
          })}
        </div>
      )
    );
  };

  const renderStyling = () => {
    return (
      <style>
        {`.at-subsales_listing__view {
              ${customStyle}
            }

            @media (min-width: 992px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  ${
                    open_for_internal_co_broke &&
                    fullRecommendationList.length > 0 &&
                    status_id === 1
                      ? '"recommendation recommendation"'
                      : ""
                  }
                  "left right" !important;
              }
            }
          
            @media (min-width: 1200px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  ${
                    open_for_internal_co_broke &&
                    fullRecommendationList.length > 0 &&
                    status_id === 1
                      ? '"recommendation recommendation"'
                      : ""
                  }
                  "left left right"
                  "listingTeam listingTeam" !important;
              }
            }
          `}
      </style>
    );
  };

  return (
    <>
      <div className="at-subsales_listing__view">
        <div className="at-subsales_listing__view-header">
          <div className="at-subsales_listing__view-header-topleft">
            <div className="at-subsales_listing__view-header-topleft_content">
              <h6 className="at-subsales__code mb-3">{code}</h6>
              <p className="at-subsales_listing__view-address_title ml-2">
                {property_name}
              </p>
              {address && (
                <div className="d-flex align-items-center ml-2">
                  <AtlasIcon
                    svgHref={"atlas-location"}
                    style={{
                      minWidth: 14,
                      width: 14,
                      height: 14,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  <h6 className="at-subsales_listing__view-address_subtitle">{`${address}`}</h6>
                </div>
              )}
              <p className="at-subsales__price ml-2">
                <span className="mr-2" style={{ color: "#111827" }}>
                  {asking_price_currency}
                </span>
                {asking_price_cents && asking_price_cents > 0
                  ? numberWithCommas(asking_price_cents)
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="at-subsales_listing__view-header-right">
            <div className="at-subsales_listing__feature_property">
              {featureProperty.map((item, index) => (
                <div key={index} className="at-subsales_listing__feature-item">
                  <AtlasIcon svgHref={item.icon} style={{ fill: "#F0631D" }} />
                  <div>
                    <div className="at-subsales_listing_title">
                      {getLocalised(item.key, item.label)}
                    </div>
                    <div className="at-subsales_listing_content">
                      {subsalesEntryCreationProps[item.value]}
                    </div>
                  </div>
                </div>
              ))}
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-layer"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">{getLocalised("label.details.specification.built_up_utilised_area", "Built Up / Utilised Area")}</div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(built_up)} ${getLocalised(getLocalisedKeyPrefix(measurement), measurement)}`}</div>
                </div>
              </div>
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-miximize-3-linear"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">{getLocalised("label.details.specification.land_area", "Land Area")}</div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(land_area)} ${getLocalised(getLocalisedKeyPrefix(measurement), measurement)}`}</div>
                </div>
              </div>
            </div>
          </div>
          {status_permissions && (
            <div className="at-subsales_listing__view-header-button_cont">
              <button
                className="btn-new btn-new--secondary"
                onClick={() => onChangeSubsalesHOC(true, "showEditModal")}
              >
                <FiEdit style={{ color: "#FFFFFF" }} />
                {getTranslation("edit", selectedLocalised)}
              </button>
              <button
                className="btn-new btn-new--outline-secondary"
                onClick={() => setShowConfirmation(true)}
              >
                <AtlasIcon svgHref={"atlas-copy"} style={{ fill: "#3B82F6" }} />
                {getTranslation("duplicate", selectedLocalised)}
              </button>
              <button
                className={`btn-new btn-new--outline-secondary`}
                onClick={() => toggleFavourite(id)}
              >
                {is_favourite ? (
                  <AtlasIcon
                    svgHref={"atlas-heart"}
                    style={{ fill: "#F43F5E" }}
                  />
                ) : (
                  <AtlasIcon
                    svgHref={"atlas-heart-linear"}
                    style={{ fill: "#F43F5E" }}
                  />
                )}
                {getTranslation("favourite", selectedLocalised)}
              </button>
              <CustomButton
                containerClass="btn-new btn-new--outline-secondary"
                disabled={!can_delete}
                tooltip={!can_delete}
                enableHTML={true}
                tooltipChildren={getLocalised("action.helptext.delete", `To delete, please set the status to Inactive/Expired or Sold/Rent Out.`)}
                tooltipPosition={"top"}
                tooltipID={`subsales-view-delete-${id}`}
                children={
                  <BsTrash3Fill
                    style={{ color: can_delete ? "#F04438" : "#FECDCA" }}
                  />
                }
                onClick={() => {
                  onChangeSubsalesHOC(true, "showDeleteModal");
                  onChangeDeleteHOC(id, "selectedDeleteSubsaleId");
                  onChangeDeleteHOC(code, "selectedDeleteSubsaleCode");
                }}
              />
            </div>
          )}
        </div>
        {open_for_internal_co_broke &&
          fullRecommendationList.length > 0 &&
          status_id === 1 && (
            <RecommendSection
              data={data}
              onLoadSubsales={onLoadSubsales}
              onLoadCobroke={onLoadCobroke}
              initialRecommendation={initialRecommendation}
              fullRecommendationList={fullRecommendationList}
              updateCobrokeStatus={updateRCCCobrokeStatus}
            />
          )}
        {open_for_internal_co_broke &&
          fullRecommendationList.length > 0 &&
          status_id !== 1 && (
            <div className="at-subsales_listing__view-recommendation at-form__content">
              <p className="at-subsales_listing__view-recommendation_title">
                Co-broke Recommendations
              </p>
              <p className="at-card__description">
                The listing is not active. To use the recommendation engine you
                have to first make the listing active. Click on the{" "}
                <strong>{` Edit `}</strong> button above and change the status.
              </p>
            </div>
          )}
        <div className="at-subsales_listing__view-left_column">
          <div className="at-subsales_listing__view-photo_slider_cont">
            <PhotoSlider
              id={id}
              images={images && images.length > 0 ? images : defaultImage}
              floorPlanListing={floorPlanListing}
              getLocalised={getLocalised}
            />
          </div>
          <div className="at-subsales_listing__view-description">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-send-2"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              {getLocalised("label.details.description.title", "Description")}
            </div>
            <div className="at-subsales_listing__view-description_tab">
              <button
                className={
                  descriptionTab === "Eng"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeSubsalesHOC("Eng", "descriptionTab")}
              >
                {getLocalised("label.details.tab.description.en", "English")}
              </button>
              <button
                className={
                  descriptionTab === "Zh"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeSubsalesHOC("Zh", "descriptionTab")}
              >
                {getLocalised("label.details.tab.description.zh", "Chinese")}
              </button>
            </div>
            <div className="at-subsales_listing__view-description-content">
              {descriptionTab === "Eng" && (
                <div
                  className={`at-subsales_listing__view-description_eng at-subsales_listing_view-selected_description`}
                >
                  {description ? ReactHTMLParser(description) : "N/A"}
                </div>
              )}
              {descriptionTab === "Zh" && (
                <div
                  className={`at-subsales_listing__view-description_zh at-subsales_listing_view-selected_description`}
                >
                  {zh_description ? ReactHTMLParser(zh_description) : "N/A"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="at-subsales_listing__view-right_column">
          <div className="at-subsales_listing__view-details">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-note-text"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              {getLocalised("label.details.details.title", "Details")}
            </div>
            {detailsSection.map((item, index) => {
              const { colorName = "" } =
                _.find(StatusColor, { value: status }) || {};

              return (
                <div
                  key={index}
                  className="at-subsales-listing__view-details-item"
                >
                  <label>
                    <AtlasIcon svgHref={item.icon} />
                    {getLocalised(item.key, item.label)}
                  </label>
                  <p>
                    {item.value === "status" ? (
                      <div
                        className="at-status_badge mt-1"
                        style={{ ...getColorBadge(colorName) }}
                      >
                        {getLocalised(`option.statuses.${status.replace(/[-\s\/]/g, '_').toLowerCase()}`, status)}
                      </div>
                    ) : item.value !== "status" && (item.label !== "Available Date" && item.label !== "Updated On") ? (
                      getLocalised(`${getLocalisedKeyPrefix(item.label)}.${processLocalisedKey(subsalesEntryCreationProps[item.value])}`, subsalesEntryCreationProps[item.value]) || "N/A"
                    ) : item.value !== "status" && (item.label === "Available Date" || item.label === "Updated On") ? (
                      getLocalisedDate(subsalesEntryCreationProps[item.value], data.languageReducer.language)
                    ) : null}
                  </p>
                </div>
              );
            })}
          </div>
          {featureArray && _.some(featureArray, { value: true }) && (
            <div className="at-subsales_listing__view-features">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-building-3"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Features
              </div>
              {featureArray.map((item, index) => (
                <div
                  key={index}
                  className="at-subsales-listing__view-details-item"
                >
                  <label>
                    <AtlasIcon svgHref={item.icon} />
                    {item.label}
                  </label>
                  {item.value}
                </div>
              ))}
            </div>
          )}
          {facilities && _.some(facilities, { value: true }) && (
            <div className="at-subsales_listing__view-facilities">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-building-4"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.facilities.title", "Facilities")}
              </div>
              <ul className="mb-10">
                {facilities.map(
                  (item, index) =>
                    item.value && 
                    <li key={index}>
                      {getLocalised(`option.facilities.${processLocalisedKey(item.label)}`, item.label)}
                    </li>,
                )}
              </ul>
              {other_facilities && (
                <div className="mt-3">
                  <div className="at-subsales-listing__view-title">
                    <AtlasIcon
                      svgHref={"atlas-note-text"}
                      style={{ fill: "#F0631D", marginRight: 10 }}
                    />
                    {getLocalised("label.details.facilities.other_facilities", "Other Facilities")}
                  </div>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    {other_facilities}
                  </p>
                </div>
              )}
            </div>
          )}
          {mediaLink.length > 0 && (
            <div className="at-subsales_listing__view-media-link">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-video-play"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.media_links.media_links.title", "Media Links")}
              </div>
              <ul>
                {mediaLink.map((item, index) => (
                  <li key={index}>
                    <a href={item.url} target="_blank">
                      <span className="mr-1">{item.title}</span>
                      <AtlasIcon svgHref={"atlas-link-2-linear"} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {status_id === 1 && renderPublishingPlatforms()}
          {can_read && co_broke_settings && (
            <div className="at-subsales-listing__view-cobroke-settings">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.co_broke.co_broke_settings", "Cobroke Settings")}
              </div>
              <div
                className="d-flex align-items-center justify-content-between mt-2"
                style={{ height: 180 }}
              >
                {cobrokeSetting.map((item, index) => (
                  <div
                    key={index}
                    className="at-subsales-listing__view-cobroke-settings-item"
                  >
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ ...getColorBadge(item.colorName) }}
                    />
                    <label>{item.label}</label>
                    {co_broke_settings && co_broke_settings[item.value]
                      ? `${co_broke_settings[item.value]} ${co_broke_settings.co_broke_portion_by === "amount" ? asking_price_currency : "%"}`
                      : "N/A"}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="at-form__content">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-profile-circle"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              {getLocalised("label.details.co_broke.listing_agent", "Listing Agent")}
            </div>
            <div className="d-flex align-items-center">
              <img
                src={agent_info.avatar_url}
                style={{
                  width: 56,
                  height: 56,
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
              <div className="ml-3">
                <div className="fw-600" style={{ fontSize: 16 }}>
                  {agent_info.full_name}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-profile2user"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent_info.team_name}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-sms"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent_info.email}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-call"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent_info.mobile_contact_number}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                }
                containerStyle={{ width: "32%" }}
                onClick={() => window.open(`mailto:${agent_info.email}`)}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-sms-edit"}
                      style={{ width: 16, height: 16, fill: "#3B82F6" }}
                    />
                    {getTranslation("email", selectedLocalised)}
                  </>
                }
              />
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                }
                containerStyle={{ width: "32%" }}
                onClick={() =>
                  window.open(
                    `https://wa.me/${agent_info.mobile_contact_number}`,
                    "_blank",
                  )
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-whatsapp"}
                      style={{ width: 16, height: 16, fill: "#12B76A" }}
                    />
                    {getTranslation("message", selectedLocalised)}
                  </>
                }
              />
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                }
                containerStyle={{ width: "32%" }}
                onClick={() =>
                  window.open(`tel:${agent_info.mobile_contact_number}`)
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-call-calling"}
                      style={{ width: 16, height: 16, fill: "#3B82F6" }}
                    />
                    {getTranslation("call", selectedLocalised)}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={showConfirmation}
        title={getLocalised("label.duplicate.title", "Duplicate Listing")}
        message={
          <>
            {getLocalised(
              "label.duplicate.message_1",
              `With clicking the Duplicate button, you are going to create a new listing
              with the details in this listing except the following:`
            )}
            <br />
            <div className="text-left mt-10" style={{ lineHeight: "150%" }}>
              {renderSpecialMessage(getLocalised(
                "label.duplicate.message_2",
                `1. The status will be set to Draft for the new listing.
                <br />
                2. You will need to set the available date for the new listing.
                <br />
                3. The new listing will not publish to any platform until you set
                it to.`
              ))}
            </div>
          </>
        }
        loading={onLoadView}
        positiveText={getTranslation("duplicate", selectedLocalised)}
        positiveAction={() => {
          Promise.all([setShowConfirmation(false)]).then(() => {
            duplicateSubsales(subsalesEntryCreationProps.id);
          });
        }}
        negativeText={getTranslation("cancel", selectedLocalised)}
        negativeAction={() => setShowConfirmation(false)}
      />
      {(onLoadFavourite ||
        onLoadSubsales ||
        onLoadView ||
        onLoadCobroke ||
        onLoadMediaLink) && <LoadingModal />}
      {renderStyling()}
    </>
  );
};

export default View;
