import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      updateLoading: false,
      showCreateModal: false,

      showEditModal: false,
      showSelectLinkDialog: false,

      selectedEventZoom: {},
      zoomLinks: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeZoomHOC = (val, context) => this.setState({ [context]: val });

    getEventZoomSession = (id) =>
      Get(
        `/admin/events/${id}/zoom_sessions`,
        this.getEventZoomSessionSuccess,
        this.getEventZoomSessionError,
        this.load,
      );
    getEventZoomSessionSuccess = (payload) =>
      this.setState({
        selectedEventZoom: payload
          ? {
              ...payload,
              host_video: payload.host_video ? "On" : "Off",
              participant_video: payload.participant_video ? "On" : "Off",
              end_datetime: Moment(payload.end_datetime).format(
                "YYYY-MM-DD LT",
              ),
              start_datetime: Moment(payload.start_datetime).format(
                "YYYY-MM-DD LT",
              ),
            }
          : null,
      });
    getEventZoomSessionError = (error) => requestError(error);

    createEventZoomSession = (id, dataToSubmit) => {
      Post(
        `/admin/events/${id}/zoom_sessions`,
        dataToSubmit,
        (payload) => this.createEventZoomSessionSuccess(id, payload),
        this.createEventZoomSessionError,
        this.load,
      );
    };
    createEventZoomSessionSuccess = (id) => {
      this.getEventZoomSession(id);
      requestSuccess("Zoom Session is created successfully.");
      this.setState({ showCreateModal: false });
    };
    createEventZoomSessionError = (error) => requestError(error);

    editEventZoomSession = (id, dataToSubmit) =>
      Put(
        `/admin/events/${id}/zoom_sessions`,
        dataToSubmit,
        (payload) => this.editEventZoomSessionSuccess(id, payload),
        this.editEventZoomSessionError,
        this.load,
      );
    editEventZoomSessionSuccess = (id) => {
      this.getEventZoomSession(id);
      requestSuccess("Zoom Session is created successfully.");
      this.setState({ showEditModal: false });
    };
    editEventZoomSessionError = (error) => requestError(error);

    updateAttendance = (id) =>
      Post(
        `/admin/events/${id}/zoom_sessions/update_attendance_list`,
        {},
        (payload) => this.updateAttendanceSuccess(id, payload),
        this.updateAttendanceError,
        (val) => this.setState({ updateLoading: val }),
      );
    updateAttendanceSuccess = (id, payload) => {
      this.setState((prevState) => ({
        selectedEventZoom: {
          ...prevState.selectedEventZoom,
          process_status: payload.process_status,
        },
      }));
      requestSuccess(
        "The update of attendance of this training is in processing. Please click. Refresh processing status button to get the latest processing status.",
      );
    };
    updateAttendanceError = (error) => requestError(error);

    refreshProcessingStatus = (id) =>
      Get(
        `/admin/events/${id}/zoom_sessions/check_process_status`,
        this.refreshProcessingStatusSuccess,
        this.refreshProcessingStatusError,
        this.load,
      );
    refreshProcessingStatusSuccess = (payload) =>
      this.setState((prevState) => ({
        selectedEventZoom: {
          ...prevState.selectedEventZoom,
          process_status: payload.process_status,
        },
      }));
    refreshProcessingStatusError = (error) => requestError(error);

    getStartZoomLink = (id) =>
      Get(
        `/admin/events/${id}/zoom_sessions/get_start_urls`,
        this.getStartZoomLinkSuccess,
        this.getStartZoomLinkError,
        this.load,
      );
    getStartZoomLinkSuccess = (payload) =>
      this.setState({
        zoomLinks: payload,
        showSelectLinkDialog: true,
      });
    getStartZoomLinkError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            zoomLinks={this.state.zoomLinks}
            showSelectLinkDialog={this.state.showSelectLinkDialog}
            onLoadZoom={this.state.loading}
            onLoadZoomUpdate={this.state.updateLoading}
            showEditModal={this.state.showEditModal}
            showCreateModal={this.state.showCreateModal}
            selectedEventZoom={this.state.selectedEventZoom}
            getStartZoomLink={this.getStartZoomLink}
            onChangeZoomHOC={this.onChangeZoomHOC}
            getEventZoomSession={this.getEventZoomSession}
            updateAttendance={this.updateAttendance}
            refreshProcessingStatus={this.refreshProcessingStatus}
            createEventZoomSession={this.createEventZoomSession}
            editEventZoomSession={this.editEventZoomSession}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
