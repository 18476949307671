import React, { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/alert-box/index.scss";

const AlertBox = ({
  containerClass = "",
  mode, //info, danger, warning, success
  title,
  hideIcon,
  iconStyle,
  className,
  contentStyle,
  description,
  disabledClose,
  containerStyle,
  containerClassName = "",
}) => {
  const [onShow, setOnShow] = useState(true);

  return (
    <div style={{ position: "relative" }} className={containerClassName}>
      {onShow && (
        <div
          className={`at-alert-box__container ${containerClass} ${mode ? `at-alert-box__container-${mode}` : ""}`}
          style={{ ...containerStyle }}>
          {!hideIcon && (
            <BsInfoCircleFill
              className={"at-alert-box__icon"}
              style={{ ...iconStyle }}
            />
          )}
          <div
            className={`text-left mr-3 ${className || ""}`}
            style={{ ...contentStyle }}>
            {title && (
              <p style={{ fontWeight: 500, marginBottom: 4 }}>{title}</p>
            )}
            <span>{description || ""}</span>
          </div>
          {!disabledClose && (
            <div
              className={"at-alert-box__close"}
              onClick={() => setOnShow(false)}>
              <AtlasIcon svgHref={"atlas-X"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AlertBox;
