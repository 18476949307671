import _ from 'lodash';
import Moment from 'moment';
import { ImYoutube } from 'react-icons/im';

import DefaultThumbnail from 'assets/images/default_thumbnail.jpg';
import AtlasIcon from 'components/Icon/atlasIcon';
import { getColorBadge } from 'dictionary/badgeColor';

const StatusColor = [
  { value: 1, colorName: 'Grey' },
  { value: 2, colorName: 'Green' }
];

export const searchParams = countries => [
  {
    type: 'input',
    label: 'Title',
    value: 'title_cont',
    param: ''
  },
  {
    type: 'input',
    label: 'Project',
    value: 'project_name_or_project_internal_name_cont',
    param: ''
  },
  {
    type: 'radio',
    label: 'Status',
    value: 'status_id_eq',
    options: [
      { value: '', label: 'All' },
      { value: '2', label: 'Published' },
      { value: '1', label: 'Draft' }
    ],
    param: ''
  },
  {
    type: 'select',
    label: 'Country',
    value: 'country_id_eq',
    options: countries,
    param: ''
  },
  {
    type: 'date',
    label: 'Start Created Date',
    value: 'created_at_dategteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'date',
    label: 'End Created Date',
    value: 'created_at_datelteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'radio',
    label: 'Video Type',
    value: 'type_id_eq',
    options: [
      { value: '', label: 'All' },
      { value: 1, label: 'Project' },
      { value: 2, label: 'General' }
    ],
    param: ''
  }
];

export const columnData = ({ onChangeVideoTemplatesHOC }) => [
  {
    header: 'Thumbnail',
    columnClass: 'at-video-templates__thumbnail-column',
    contentClass: 'at-video-templates__thumbnail-column',
    renderColumn: data => (
      <div className='at-video-templates__thumbnail-wrapper'>
        <img
          alt={data.title}
          src={data.thumbnail_url || DefaultThumbnail}
          className='at-video-templates__thumbnail'
        />
        {data.url && (
          <div
            className='at-video-templates__thumbnail-overlay'
            onClick={async () => {
              await onChangeVideoTemplatesHOC('selectedVideoTemplate', data);
              await onChangeVideoTemplatesHOC('showVideoPlayer', true);
            }}
          >
            <ImYoutube className='at-video-templates__play-icon' />
          </div>
        )}
      </div>
    )
  },
  {
    header: 'Title / Project',
    renderColumn: rowData => (
      <>
        <div className='d-flex align-items-center' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-firstline'
            className='at-video-templates__title-icon'
          />
          <p>{rowData.title || '-'}</p>
        </div>
        <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-building-4'
            className='at-video-templates__title-icon'
          />
          <p>
            {(rowData.version === 2 && rowData.type_id) === 2 ? 'General' : rowData.project_name || '-'}
          </p>
        </div>
        {(rowData.version === 2 && rowData.type_id === 1) && (
          <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
            <AtlasIcon
              svgHref='atlas-home'
              className='at-video-templates__title-icon'
            />
            <p>{rowData.unit_type || '-'}</p>
          </div>
        )}
      </>
    )
  },
  {
    header: 'Download / Created Count',
    columnStyle: { width: '50%' },
    renderColumn: ({ download_count, create_count }) => (
      <>
        <div className='d-flex align-items-center mb-1' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-receive-square'
            style={{ width: 16, height: 16, fill: '#F0631D' }}
          />
          <p>{download_count || 0}</p>
        </div>
        <div className='d-flex align-items-center mb-1' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-edit-linear'
            style={{ width: 16, height: 16, fill: '#F0631D' }}
          />
          <p>{create_count || 0}</p>
        </div>
      </>
    )
  },
  {
    header: 'Created At',
    renderColumn: ({ created_at }) => Moment(created_at).format('DD MMM YYYY'),
    columnStyle: { width: '50%' }
  },
  {
    header: () => (
      <span className='at-table-head__title text-center'>Status</span>
    ),
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
    renderColumn: ({ status_id }) => {
      const { colorName = '' } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className='at-status_badge'
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? 'Draft' : 'Published'}
        </div>
      );
    },
    columnStyle: { width: '70%' }
  }
];

export const inputFields = (version, activeModal, typeId) => [
  {
    type: 'radio',
    className: 'col-sm-12',
    options: [
      { value: 1, label: 'Project' },
      { value: 2, label: 'General' }
    ],
    label: 'Video Type',
    value: 'type_id',
    hidden: activeModal === 'Update'
  },
  {
    type: version === 1 ? 'text' : 'typeahead',
    label: 'Project',
    value: version === 1 ? 'project_name' : 'project_id',
    required: version === 2,
    disabled: version === 1,
    hidden: typeId === 2
  },
  {
    type: 'text',
    label: 'Unit Type',
    value: 'unit_type',
    required: version === 2 && typeId === 1,
    hidden: version === 1 || typeId === 2
  },
  {
    type: 'text',
    className: typeId === 2 && 'col-sm-12',
    label: 'Title',
    value: 'title',
    required: true
  },
  {
    type: 'textarea',
    className: 'col-sm-12',
    label: 'Description',
    value: 'description'
  },
  {
    type: 'text',
    label: 'Template File Name',
    value: 'mg_filename',
    disabled: true,
    hidden: version === 2
  },
  {
    type: version === 2 && typeId === 1 ? 'text' : 'select',
    label: 'Country',
    value: 'country_id',
    required: activeModal === 'Create',
    disabled: version === 2 && typeId === 1
  },
  {
    type: 'radio',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' }
    ],
    label: 'Exclude Logo',
    value: 'exclude_logo',
    hidden: version === 2
  },
  {
    type: 'radio',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' }
    ],
    label: 'Exclude Property Details',
    value: 'exclude_property_details',
    hidden: version === 2
  },
  {
    type: 'radio',
    options: [
      { value: 1, label: 'Draft' },
      { value: 2, label: 'Published' }
    ],
    label: 'Status',
    value: 'status_id',
    required: true
  },
  {
    type: 'video',
    className: 'mb-sm-0',
    label: 'Final Video',
    value: 'video',
    description: (
      <>
        <span>MP4 or WEBM</span>
        <br />
        <span className='d-inline-block mt-1'>(Video size: 1920 x 1080 or 1080 x 1920)</span>
      </>
    ),
    accepts: 'video/mp4, video/webm',
    required: activeModal === 'Create'
  },
  {
    type: 'image',
    className: 'mb-0',
    label: 'Thumbnail',
    value: 'thumbnail',
    description: (
      <>
        <span>PNG, JPG or JPEG</span>
        <br />
        <span className='d-inline-block mt-1'>(Image size: min. 640 x 480)</span>
      </>
    ),
    accepts: 'image/png, image/jpeg'
  }
];
