import React, { Component, createRef } from "react";
import _ from "lodash";
import { compose } from "redux";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import CustomTable from "components/NewTable";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import AtlasReassignDialog from "./components/Modal/reassignModal";
import permissionsChecker from "utils/permissionsChecker";
import Tabs from "./components/Tabs";

import AgentHOC from "./action/agent";
import PendingApprovalHOC from "./action";
import VersionCheckHOC from "actions/versionCheck";
import { ColumnData } from "./assets";

const agentStatus = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Approved" },
  { value: 3, label: "Rejected" },
];

class PendingApproval extends Component {
  componentDidMount = () => {
    this.cardRef = createRef();
    this.props.getPendingNewREN();
  };

  renderCheckBoxActions = () => {
    const { can_update } = permissionsChecker(
      "New Agent Approvals",
      this.props.data,
    );
    const stickToTop =
      this.cardRef?.current?.getBoundingClientRect()?.top < -200 &&
      window.innerWidth < 768;

    return (
      <>
        {can_update && this.props.selectedStatus === 1 && (
          <Tabs
            stickToTop={stickToTop}
            checkedArray={this.props.checkedArray}
            currentPageData={this.props.currentPageData}
            onClickSelectAll={() => {
              let checkableData = [];
              this.props.currentPageData.map((item) => {
                if (item.status !== "Approved") {
                  checkableData.push(item);
                }
              });
              this.props.onClickSelectAll(checkableData);
            }}
            onClickTabApproval={() => this.props.onClickTabApproval()}
            onClickTabAssignDirectLeader={() =>
              this.props.onClickTabAssignDirectLeader()
            }
          />
        )}
      </>
    );
  };

  renderCardContent = () => {
    const { can_update } = permissionsChecker(
      "New Agent Approvals",
      this.props.data,
    );

    const renderCount = (val) => {
      if (val === 1) {
        return this.props.pendingCount;
      } else if (val === 2) {
        return this.props.approvedCount;
      } else if (val === 3) {
        return this.props.rejectedCount;
      }
    };

    return (
      <>
        <CustomTab
          sections={agentStatus}
          selectedSection={
            _.find(agentStatus, { value: this.props.selectedStatus }).label
          }
          renderLabel={(item) => (
            <span className="d-flex align-items-center justify-content-center">
              {item.label}
              <div>{renderCount(item.value)}</div>
            </span>
          )}
          onSelectSection={(val) =>
            Promise.all([
              this.props.onChangePendingApprovalHOC(
                _.find(agentStatus, { label: val }).value,
                "selectedStatus",
              ),
            ]).then(() => {
              this.props.getPendingNewREN();
            })
          }
        />
        <CustomTable
          pagination={true}
          columns={ColumnData({
            can_update: can_update,
            checkedArray: this.props.checkedArray,
            selectedStatus: this.props.selectedStatus,
            onHandleCheckBox: this.props.onHandleCheckBox,
            onClickSelectAll: this.props.onClickSelectAll,
          })}
          actionAfterChangedPage={({ currentPageData }) => {
            this.props.onClickDeselectAll();
            this.props.onChangePendingApprovalHOC(
              currentPageData,
              "currentPageData",
            );
          }}
          renderCheckBoxActions={this.renderCheckBoxActions}
          rowData={this.props.pendingApprovals || []}
        />
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"New Agent Approvals"}
          atlasIcon={"atlas-profile-add"}
          showPABadge={role === "Personal Assistant"}
        />
        <CustomCard ref={this.cardRef} cardContent={this.renderCardContent()} />
        <AtlasConfirmationDialog
          open={this.props.showApprovalConfirmationModal}
          mode={"check"}
          title={"Approve Agent"}
          loading={this.props.onLoadApproval}
          message={"Are you sure to approve all the selected new REN?"}
          positiveText={"Approve"}
          negativeText={"Cancel"}
          positiveAction={() => this.props.approveNewRENS()}
          negativeAction={() => this.props.closeModal()}
        />
        <AtlasReassignDialog
          open={this.props.showReassignDirectConfirmationModal}
          header={"Reassign Referrer"}
          message={"Enter mobile number to search"}
          agent={this.props.agent}
          onLoadAgent={this.props.onLoadAgent}
          searchAgents={(param) => this.props.searchAgents(param)}
          reassign={(param) => this.props.reassignDirectLeader(param)}
          onChangeAgentHOC={this.props.onChangeAgentHOC}
          closeModal={this.props.closeModal}
        />
        {this.props.onLoadPendingApproval && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  VersionCheckHOC,
  PendingApprovalHOC,
  AgentHOC,
)(PendingApproval);
