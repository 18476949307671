import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showSelectedFeed: false,
      showVideoPlayer: false,
      selectedVideo: "",

      newsFeedData: [],
      newsFeedMeta: {},
      newsFeedPages: [],
      selectedFeed: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeNewsFeedHOC = (val, context) => this.setState({ [context]: val });

    getNewsFeed = (page) => {
      let temp = {
        currentPage: page,
      };
      this.props.storeLastView(temp);

      Get(
        `/newsfeeds?page=${page ? page : 1}`,
        this.getNewsFeedSuccess,
        this.getNewsFeedError,
        this.load,
      );
    };
    getNewsFeedSuccess = (payload) => {
      let tempPages = [];
      let tempData = _.cloneDeep(this.state.newsFeedData);

      payload.data.map((item) => {
        if (!_.find(tempData, (newsfeed) => newsfeed.id === item.id)) {
          tempData.push(item);
        }
      });

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        newsFeedData: tempData,
        newsFeedMeta: payload.meta,
        newsFeedPages: tempPages,
      });
    };
    getNewsFeedError = (error) => requestError(error);

    getSelectedFeed = (id) =>
      Get(
        `/newsfeeds/${id}`,
        this.getSelectedFeedSuccess,
        this.getSelectedFeedError,
        this.load,
      );
    getSelectedFeedSuccess = (payload) => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.setState(
        {
          selectedFeed: payload,
          showSelectedFeed: true,
        },
        () => this.getNewsFeed(currentPage),
      );
    };
    getSelectedFeedError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          selectedFeed={this.state.selectedFeed}
          selectedVideo={this.state.selectedVideo}
          onLoadNewsFeed={this.state.loading}
          showVideoPlayer={this.state.showVideoPlayer}
          showSelectedFeed={this.state.showSelectedFeed}
          newsFeedPages={this.state.newsFeedPages}
          newsFeedData={this.state.newsFeedData}
          newsFeedMeta={this.state.newsFeedMeta}
          getNewsFeed={this.getNewsFeed}
          getSelectedFeed={this.getSelectedFeed}
          onChangeNewsFeedHOC={this.onChangeNewsFeedHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
