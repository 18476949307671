import React, { Component, Fragment } from "react";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import { getTranslation } from "assets/translation";
import withSelectedLocalised from 'actions/selectedLocalisedHOC';

import "react-bootstrap-typeahead/css/Typeahead.css";

class CustomTypeahead extends Component {
  state = {
    isSearching: false
  }
  
  componentDidUpdate = (prevProps) => {
    const isLoadingChanged = prevProps.isLoading !== this.props.isLoading && !this.props.isLoading;
    const isOptionsChanged = prevProps.options !== this.props.options;
    const isDisabledChanged = prevProps.disabled !== this.props.disabled;
    if (isLoadingChanged && isOptionsChanged && this.state.isSearching) {
      this.typeahead?.focus();
      this.setState({ isSearching: false });
    }
    if (isDisabledChanged && isOptionsChanged && this.state.isSearching) {
      this.typeahead?.focus();
      this.setState({ isSearching: false });
    }
  }

  _renderMenuItemChildren = (option, props) => {
    const { childrenHead, highlighterData, typeaheadId } = this.props;

    return [
      <div key={typeaheadId} className="fs-2">{childrenHead(option, props)}</div>,
      <p style={{ fontSize: 11 }}>
        {highlighterData ? this.renderHighlighter(option, props) : []}
      </p>,
    ];
  };

  renderRequired = (param, language) => {
    if (param) {
      return <div className="at-form-input__required">{getTranslation('required', language)}</div>;
    }
  };

  renderHelpText = (param) => {
    if (param) {
      return (
        <span
          style={{
            color: "#4B5563",
            fontSize: 12,
            position: "absolute",
            top: 4,
          }}>
          {param}
        </span>
      );
    }
  };

  renderHighlighter = (option, props) => {
    const { highlighterData = [] } = this.props;

    let tempHighlighter = _.map(highlighterData, (item, index) => (
      <Fragment
        key={`${index}${props.text ? `-${props.text.trim()}` : "-empty"}`}>
        {typeof item === "function" && (
          <Highlighter search={props.text}>{item(option)}</Highlighter>
        )}
        {typeof item !== "function" && item}
      </Fragment>
    ));

    return tempHighlighter;
  };

  render() {
    const {
      isLoading,
      options,
      multiple,
      selectedValue,
      filterBy = [],
      disabled,
      className,
      helpText,
      required,
      typeaheadId,
      labelKey,
      placeholder,
      showClearButton,
      wrapperClassName = "",
      containerClassName = "",
      containerStyle = {},
      footerStyle = {},
      onClear,
      onSelect,
      onSearch = () => {},
      language, // Added language prop
    } = this.props;
    return (
      <div className={`position-relative ${wrapperClassName}`}>
        <div
          className={`at-form-input ${disabled ? " at-form-input__disabled" : " "} ${containerClassName}`}
          style={{
            ...containerStyle,
          }}
        >
          <Typeahead
            isLoading={isLoading}
            ref={(ref) => (this.typeahead = ref)}
            className={`${className || ""} w-100`}
            inputProps={{
              style: { border: 0, fontFamily: "Inter" },
              className: "at-form-input__input",
            }}
            id={typeaheadId || "atlas-typeahead"}
            selected={selectedValue}
            multiple={multiple}
            options={options}
            placeholder={placeholder}
            onChange={(selected) => onSelect(selected)}
            labelKey={labelKey}
            onInputChange={(val) => {
              this.setState({ isSearching: true });
              onSearch(val);
            }}
            renderMenuItemChildren={this._renderMenuItemChildren}
            filterBy={filterBy}
            disabled={disabled || isLoading}
          />
          {(!disabled && showClearButton) && (
            <div className="input-group-append">
              <CustomButton
                style={{ width: 25, height: 25 }}
                children={
                  <AtlasIcon
                    svgHref={"atlas-X"}
                    style={{ width: 14, height: 14, color: "#9ca3af" }}
                  />
                }
                tooltip={false}
                onClick={() => {
                  let emptyState = {};
                  if (filterBy && filterBy[0]) {
                    filterBy.map((item) => {
                      emptyState[item] = "";
                    });
                  }
                  if (this.typeahead) {
                    this.typeahead.clear();
                  }
                  return onClear ? onClear(emptyState) : onSelect(emptyState);
                }}
              />
            </div>
          )}
        </div>
        <div
          className="at-input_footer"
          style={{ ...((helpText || required) ? { marginBottom: "1rem" } : {}), ...footerStyle }}
        >
          {this.renderHelpText(helpText)}
          {this.renderRequired(required, language)}
        </div>
      </div>
    );
  }
}

export default withSelectedLocalised(CustomTypeahead);