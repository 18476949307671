import { FCM_STORE_USER_TOKEN } from "actions/type";

let initialState = {
  userToken: "",
};

export default function firebaseReducer(state = initialState, action) {
  switch (action.payload) {
    case FCM_STORE_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      };
    default:
      return state;
  }
}
