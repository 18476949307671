import { Component } from "react";

import { Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const SendTestEmailHOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      recipients: "",
      showEmailDialog: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSendTestEmailHOC = (key, val) => this.setState({ [key]: val });

    sendTestEmail = (data) =>
      Post(
        `/admin/email_templates/email_testing`,
        data,
        this.sendTestEmailSuccess,
        this.sendTestEmailError,
        this.load,
      );
    sendTestEmailSuccess = () => {
      requestSuccess("Email was sent successfully!");
      this.setState({
        receipients: "",
        showEmailDialog: false,
      });
    };
    sendTestEmailError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadSendTestEmail={this.state.loading}
          recipients={this.state.recipients}
          showEmailDialog={this.state.showEmailDialog}
          onChangeSendTestEmailHOC={this.onChangeSendTestEmailHOC}
          sendTestEmail={this.sendTestEmail}
        />
      );
    };
  }

  return WithHOC;
};

export default SendTestEmailHOC;
