import {
  CREATE_NEW_USER,
  STORE_REGISTER_PATH_FROM,
  STORE_PAYMENT_ID,
} from "actions/type";

let initialState = {
  newUser: {
    id: "",
    email: "",
    mobile_contact_number: "",
    payment_status: "",
    message: {},
  },
  pathFrom: "",
  paymentID: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };
    case STORE_REGISTER_PATH_FROM:
      return {
        ...state,
        pathFrom: action.payload,
      };
    case STORE_PAYMENT_ID:
      return {
        ...state,
        paymentID: action.payload,
      };
    default:
      return state;
  }
};
