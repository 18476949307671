import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post, Get } from "utils/axios";
import { currency } from "dictionary/subsales";

export const SearchParams = ({ countries, currencies }) => [
  {
    label: "Project Name",
    value: "name_or_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Developer",
    value: "developer_cont",
    type: "input",
    param: "",
  },
  {
    label: "Country",
    value: "country_id_eq",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "select",
    placeholder: "Please choose a country first.",
    param: "",
    options: [],
  },
  {
    label: "Currency",
    value: "currency",
    type: "select",
    withoutPrefix: true,
    options: currencies.name,
    param: "",
    col: 12,
  },
  {
    label: "Project Price Greater Than",
    value: "min_price",
    type: "input",
    withoutPrefix: true,
    param: "",
  },
  {
    label: "Project Price Less Than",
    value: "max_price",
    type: "input",
    withoutPrefix: true,
    param: "",
  },
  {
    label: "Bedroom No. Greater than",
    value: "min_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Bedroom No. Less than",
    value: "max_bedroom_eq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Greater than",
    value: "min_built_up_eq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Less than",
    value: "max_built_up_eq",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      estatetProjectSearchParams: SearchParams(
        this.props.data.dictionaryReducer
      ),
      estateProjects: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      estateProjectPages: [],

      estateProjectNavData: {
        currentPage: 1,
        searchParams: "",
      },
      showRemoveConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeEstateProjectHOC = (val, context) =>
      this.setState({ [context]: val });

    getEstateProject = (presentationId, page, search) => {
      this.setState(
        {
          estateProjectNavData: {
            currentPage: page,
            searchParams: search,
          },
        },
        () => {
          const { role } =
            window.location.href.indexOf("/admin-impersonate") > -1
              ? this.props.data.currentSignInProfileReducer
              : this.props.data.profileReducer;

          return Get(
            `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates/get_projects?${search}page=${page}`,
            this.getEstateProjectSuccess,
            this.getEstateProjectError,
            this.load
          );
        }
      );
    };
    getEstateProjectSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        estateProjects: payload,
        estateProjectPages: tmpTotalPages,
      });
    };
    getEstateProjectError = (error) => requestError(error);

    createProject = (presentationId, project_id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Post(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates`,
        {
          type_id: 2,
          project_id,
        },
        () => this.createProjectSuccess(presentationId),
        this.createProjectError,
        this.load
      );
    };
    createProjectSuccess = (presentationId) => {
      const { currentPage: estatePage, searchParams: estateSearchParams } =
        this.state.estateProjectNavData;
      const {
        currentPage: presentationPage,
        searchParams: presentationSearch,
      } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Project added successfully.");
      this.props.getProjects();
      this.props.getSelectedPresentation(presentationId);
      this.props.getPresentation(presentationPage, presentationSearch);
      this.getEstateProject(presentationId, estatePage, estateSearchParams);
    };
    createProjectError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            estateProjects={this.state.estateProjects}
            showRemoveConfirmation={this.state.showRemoveConfirmation}
            estateProjectPages={this.state.estateProjectPages}
            estatetProjectSearchParams={this.state.estatetProjectSearchParams}
            onLoadEstate={this.state.loading}
            getEstateProject={this.getEstateProject}
            createProject={this.createProject}
            onChangeEstateProjectHOC={this.onChangeEstateProjectHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
