import { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Put } from "utils/axios";
import objToFormData from "utils/objToFormData";
import { requestError, requestSuccess } from "utils/requestHandler";

const withUpdateProject = (WrappedComponent) => {
  class UpdateProjectHOC extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    updateProject = (val) => {
      let data = _.cloneDeep(val);

      data.display_name_type && data.display_name_type === "internal"
        ? (data.show_internal_name_as_display_name = true)
        : (data.show_internal_name_as_display_name = false);

      delete data.display_name_type;

      if (data.project_image && data.project_image.indexOf("data:image") < 0) {
        delete data.project_image;
      }
      if (
        data.background_image &&
        data.background_image.indexOf("data:image") < 0
      ) {
        delete data.background_image;
      }
      if (
        data.overview_image &&
        data.overview_image.indexOf("data:image") < 0
      ) {
        delete data.overview_image;
      }
      if (
        data.project_features_image &&
        data.project_features_image.indexOf("data:image") < 0
      ) {
        delete data.project_features_image;
      }
      if (data.map_image && data.map_image.indexOf("data:image") < 0) {
        delete data.map_image;
      }
      if (data.hd_map_image && data.hd_map_image.indexOf("data:image") < 0) {
        delete data.hd_map_image;
      }

      Put(
        `/admin/projects/${val.id}`,
        objToFormData(data, "project"),
        this.updateProjectSuccess,
        this.updateProjectError,
        this.load,
      );
    };
    updateProjectSuccess = (payload) => {
      requestSuccess("Project was updated successfully!");
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getProject(payload.id);
      this.props.getAllProjects(currentPage, searchParams);
    };
    updateProjectError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadEdit={this.state.loading}
          updateProject={this.updateProject}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(UpdateProjectHOC);
};

export default withUpdateProject;
