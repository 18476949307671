import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import ReactHTMLParser from "react-html-parser";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      selectedAgent: {},
      searchAgentStatusMessage: "",
      requests: [],
      loading: false,

      email: "",
    };

    onChangeLeadAgentHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    searchAgent = (type, email) => {
      let url = "";
      let temp = { search: email };

      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      switch (role) {
        case "Agent":
        case "Associated Agent":
        case "Personal Assistant":
          url = `/leads/search`;
          break;

        default:
          url = `/admin/leads/search`;
          temp = { search: email, type: type };
          break;
      }

      Post(
        url,
        temp,
        this.searchAgentUserSuccess,
        this.searchAgentUserError,
        (val) => this.setState({ onLoadAgentPulse: val }),
      );
    };
    searchAgentUserSuccess = (payload) =>
      this.setState({
        selectedAgent: payload,
        searchAgentStatusMessage: "",
      });
    searchAgentUserError = (error) =>
      this.setState({ searchAgentStatusMessage: ReactHTMLParser(error) });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            email={this.state.email}
            selectedAgent={this.state.selectedAgent}
            onLoadAgentPulse={this.state.onLoadAgentPulse}
            onLoadAgent={this.state.loading}
            searchAgentStatusMessage={this.state.searchAgentStatusMessage}
            searchAgent={this.searchAgent}
            onChangeLeadAgentHOC={this.onChangeLeadAgentHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
