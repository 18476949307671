import React, { Component } from "react";
import _ from "lodash";
import { FaUserTie } from "react-icons/fa";
import { MenuItem } from "@material-ui/core";

import AtlasTable from "components/NewPaginatedTable";
import ContactFormDialog from "../components/formDialog";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import CustomDropdown from "components/Button/dropdown";
import AtlasIcon from "components/Icon/atlasIcon";

import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import { oppositeInfoStatusBadges } from "containers/Dashboard/Administrator/SubsalesClaimsBeta/assets";
import "stylesheets/containers/subsales-claims/index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {["3", "4"].indexOf(rowData.contact_type_id) === -1 && (
          <>
            {rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-building-company"}/>
                <div className={"ml-1"}>
                  {rowData.company_name}
                  <br />({rowData.company_registration_no})
                  <br />
                  {rowData.full_name}
                </div>
              </>
            )}
            {!rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-icon-user"}/>
                {rowData.full_name}
              </>
            )}
          </>
        )}
        {["3", "4"].indexOf(rowData.contact_type_id) > -1 && (
          <>
            <FaUserTie />
            {rowData.company_name}
            <br />({rowData.company_registration_no})
          </>
        )}
      </div>
    ),
  },
  { header: "NRIC/Passport No.", accessor: "nric" },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];

class OwnerLawyer extends Component {
  state = {
    showConfirmationModal: false,
    toRemoveItem: {},
    newTypeId: ""
  };

  componentDidMount = () => {
    const { id: opposite_claim_id } =
      this.props.selected_claim.opposite_claim ?? {};

    this.props.getAssignedContactType({
      claim_id: this.props.selected_claim.id,
      type_id: 1,
      isPrimaryClaim: true,
    });

    if (opposite_claim_id) {
      this.props.getAssignedContactType({
        claim_id: opposite_claim_id,
        type_id: 1,
        isPrimaryClaim: false,
      });
    }
  };
  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        1,
        this.state.toRemoveItem.id,
      );
    });

  onClickEditProfile = (id, data) => {
    this.props.getSelectedEntity(id, data.type_id, data.id);
  };

  updateSelectedEntity = (data) =>
    this.props.updateSelectedEntity(
      data,
      this.props.selected_claim.id,
      1,
      data.id,
    );

  onClickRemoveOwnerCard = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  renderEditOwnerDialog = () => {
    let tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 2 && this.props.selectedContact.contact_type_id === 1;

    if (this.props.showEditEntityDialog) {
      return (
        <ContactFormDialog
          title={"Edit"}
          banks={this.props.banks}
          formTypes={
            this.props.selectedContact.contact_type_id === 1
              ? this.props.formTypes
              : []
          }
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          loading={this.props.onLoadContactType}
          titleContext={
            this.props.selectedContact.contact_type_id === 1
              ? this.props.selected_claim.sub_sale_type_id === 2
                ? "Landlord"
                : "Vendor"
              : "Law Firm"
          }
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showEditEntityDialog")
          }
          onChangeValue={this.props.onChangeEditContact}
          submitData={ (data) => this.props.submitUpdatedData(this.props.selected_claim.id, data) }
          storedAddresses={this.props.data.addressReducer.address}
          nationalities={this.props.nationalities}
          selectedClaim={this.props.selected_claim}
          selectedContact={this.props.selectedContact}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderNewOwnerDialog = () => {
    const tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 2 && this.state.newTypeId === "1";

    if (this.props.showNewEntityDialog) {
      return (
        <ContactFormDialog
          title={"Add"}
          banks={this.props.banks}
          loading={this.props.onLoadContactType}
          selectedClaim={this.props.selected_claim}
          titleContext={this.state.newTypeId === "1" 
            ? this.props.selected_claim.sub_sale_type_id === 2
              ? "Landlord"
              : "Vendor" 
            : "Law Firm"
          }
          formTypes={this.state.newTypeId === "1" ? this.props.formTypes : []}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showNewEntityDialog")
          }
          submitData={(newContact) => {
            let tmpNewContact = {
              ...newContact,
              contact_type_id: this.state.newTypeId,
              type_id: 1,
            };
            this.props.createEntity(
              tmpNewContact,
              this.props.selected_claim.id,
              1,
            );
          }}
          nationalities={this.props.nationalities}
          storedAddresses={this.props.data.addressReducer.address}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderTableHeading = (representative_id, can_create) => {
    const { 
      form_type_id,
      opposite_info_status, 
      info_provided_by_opposite_agent,
      opposite_representative_party 
    } = this.props.selected_claim ?? {};

    let tmpOppositeRepresentativeParty = opposite_representative_party?.toLowerCase() ?? ""

    const { colorName = "" } =
      _.find(
        oppositeInfoStatusBadges,
        item => item.value === opposite_info_status,
      ) || {};
      
    if (can_create || (representative_id < 3 && representative_id !== null)) {
      return (
        <>
          {
            (representative_id === 2 && form_type_id !== 4) && (
              <div className={`at-table_heading flex-column align-items-start grid_gap-0 mb-lg-0 mb-3`}>
                <CustomCheckbox
                  labelClassname={"at-party-checkbox"}
                  disabled={true}
                  content={ <p className="at-form-input__title fw-500 mb-0">Let {tmpOppositeRepresentativeParty} or representative to add & update {tmpOppositeRepresentativeParty} details</p> }
                  checked={this.props.selected_claim.info_provided_by_opposite_agent}
                  onChangeCheckboxValue={() => {}}
                />
                {
                  opposite_info_status !== null && (
                    <div className="ml-4 pl-1">
                      <p className="at-form-input__title fw-500 mb-1">Representative Updated Status</p>
                      <div>
                        <span
                          style={{ ...getColorBadge(colorName) }}
                          className={`at-status_badge`}
                        >
                          {opposite_info_status}
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
          <div className={`at-table_heading g-2`}>
            {can_create && (
              <>
                {this.renderDropDown(this.props.selected_claim.sub_sale_type_id === 2 ? "Landlord" : "Vendor")}
                <button
                  className="btn-new btn-new--primary"
                  onClick={() => {
                    this.props.onChangeContactTypeHOC(
                      true,
                      "showNewEntityDialog",
                    );
                    this.setState({ newTypeId: "3" });
                  }}
                >
                  Add Law Firm
                </button>
              </>
            )}
          </div>
        </>
      );
    }
  };

  onClickSelectFromContacts = (party_title) => {
    this.props.updateSelectedParticipantTypeId(1)
    this.props.updateSelectedTitle(party_title)
    this.props.onChangeClaimsHOC("showAssignContactModal", true)
  }

  renderDropDown = (party_title) => {
    return (
      <CustomDropdown label={`Add ${party_title}`}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts(party_title)}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.props.onChangeContactTypeHOC(
              true,
              "showNewEntityDialog",
            );
            this.setState({ newTypeId: "1" });
          }}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }

  render = () => {
    const { owners } = this.props;
    const { can_create, can_destroy, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const { 
      representative_id,
      info_provided_by_opposite_agent,
    } = this.props.selected_claim ?? {};

    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={owners || []}
          actionColumnContent={[
            {
              name: "info",
              onShow: () => !can_update,
              onClick: (rowData) =>
                this.onClickEditProfile(
                  this.props.selected_claim.id,
                  rowData,
                  true
                )
            },
            {
              name: "edit",
              onShow: () => can_update,
              onClick: (rowData) =>
                this.onClickEditProfile(
                  this.props.selected_claim.id,
                  rowData,
                  true,
                )
            },
            {
              name: "delete",
              onShow: () => can_destroy,
              onClick: (rowData) => this.onClickRemoveOwnerCard(rowData),
            }
          ]}
          renderExtraContent={() =>
            this.renderTableHeading(representative_id, can_create)
          }
        />
        {this.renderNewOwnerDialog()}
        {this.renderEditOwnerDialog()}
        {this.props.onLoadContactType && <LoadingModal />}
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
      </>
    );
  };
}

export default (OwnerLawyer);
