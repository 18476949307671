import React, { useRef, useState } from "react";
import { IoMdDocument } from "react-icons/io";
import { Tooltip } from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import LoadingModal from "components/LoadingModal";

import AtlasCloseButton from "components/Button/prev";
import AtlasDialog from "components/Dialog";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UploadContent = ({
  context,
  onClose,
  fileData,
  onUpload,
  showLoading,
}) => {
  let pond = useRef(null);
  const [showUploadedContent, onToggleUploaded] = useState(false);

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onUpload(e.target.result, fileItems[0].file.name);
      };
      pond.current.removeFile();
      reader.readAsDataURL(fileItems[0].file);
    }
  };

  return (
    <>
      <AtlasCloseButton
        containerStyle={{ marginBottom: 10 }}
        onClick={() => onClose()}
      />
      <h4 className="at-card__title">{`Upload ${context}`}</h4>
      <hr />
      {fileData && fileData !== "" && fileData.indexOf("https") > -1 && (
        <div
          className={"at-uploaded-container"}
          onClick={() => onToggleUploaded(true)}
        >
          {fileData.indexOf("pdf") > -1 ? (
            <IoMdDocument />
          ) : (
            <img src={fileData} />
          )}
          <div>
            <p>This is your uploaded file</p>
            <p>Click to view your previous upload.</p>
          </div>
        </div>
      )}
      {/* <FilePond
        ref={ ref => pond.current = ref }
        acceptedFileTypes={ [ 'image/*', 'application/pdf' ] }
        allowMultiple={ false }
        fullWidth
        maxFiles={ 1 }
        onupdatefiles={ fileItems => onChangeFile( fileItems )}/> */}
      <AtlasDialog open={showUploadedContent}>
        <AtlasCloseButton
          containerStyle={{ position: "absolute", right: 25, top: 25 }}
          onClick={() => onToggleUploaded(false)}
        />
        {fileData.indexOf("pdf") > -1 ? (
          <iframe
            className={"w-100 h-100"}
            src={fileData}
            frameborder="0"
          ></iframe>
        ) : (
          <img style={{ objectFit: "contain" }} src={fileData} />
        )}
      </AtlasDialog>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default UploadContent;
