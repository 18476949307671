import { Tenure } from "../assets/index.js";

const subsaleForms = [
  {
    label: "Commencement date",
    value: "commencement_date",
    type: "date",
    dateFormat: "YYYY-MM-DD",
    required: true
  },
  {
    label: "Expiring date",
    value: "expiring_date",
    type: "date",
    dateFormat: "YYYY-MM-DD",
    required: true
  },
  {
    label: "Appointment period (days)",
    value: "appoint_day",
    type: "text",
    required: true
  },
  {
    label: "Purchase price (RM)",
    value: "payment_amount",
    type: "currency",
    required: true
  },
  {
    label: "Type of property",
    value: "property_type",
    type: "text",
    required: true
  },
  {
    label: "Location of property",
    value: "property_address",
    type: "text",
    required: true
  },
  {
    label: "Tenure",
    value: "tenure_type_id",
    type: "select",
    options: Tenure,
    required: true
  },
  {
    label: "With or without vacant posession",
    value: "vacant_possession",
    type: "text",
    required: true
  },
  {
    label: "Others (Requirement)",
    value: "others",
    type: "text",
    required: false
  },
  {
    label: "Professional fee (RM)",
    value: "professional_fee",
    type: "currency",
    required: true
  },
  {
    label: "Professional fee (%)",
    value: "percentage_of_purchase_price",
    type: "number",
    required: true
  },
  {
    label: "SST (%)",
    value: "government_tax",
    type: "number",
    required: true
  },
];

export default subsaleForms;
