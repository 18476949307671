import React, {useState } from "react";
import _ from "lodash";

import Tabs from "components/Tab";
import CustomUpload from "components/Upload";
import CustomButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import DefaultImage from "assets/images/default_image.png";

const LandmarkDetail = ({
  selectedCountry,
  bulkUpdate,

  updateCountry,
  batchUpdateState,
  onChangeLandmarkHOC,
}) => {
  const [selectedSection, updateSelectedSection] = useState("Country");

  const onChangeField = (val, context) => {
    let tmp = _.cloneDeep(selectedCountry);
    tmp[context] = val;
    onChangeLandmarkHOC(tmp, "selectedCountry");
  };

  const onChangeStateName = (val, id) => {
    let tmp = _.cloneDeep(selectedCountry);
    let stateIndex = _.findIndex(tmp.states, (state) => state.id === id);
    let state = tmp.states[stateIndex];
    state.name = val;
    onChangeLandmarkHOC(tmp, "selectedCountry");
    addChangeState(id);
  };

  const addChangeState = (id) => {
    if (!_.includes(bulkUpdate, id)) {
      let tmp = _.cloneDeep(bulkUpdate);
      tmp.push(id);
      onChangeLandmarkHOC(tmp, "bulkUpdate");
    }
  };

  const renderEditStateLandmark = () => {
    return (
      <div className="grid-control">
        <section className="grid-full-col">
          <h4 className="at-landmark-mgmt__title">State's Landmark</h4>
        </section>
        <section className="grid-full-col at-landmark-mgmt__state-box">
          {selectedCountry.states.map((state, index) => (
            <div className="at-landmark-mgmt__state-container">
              <CustomUpload
                containerStyle={{border: "none", width: '100%'}}
                imageUrl={
                  state.landmark_image_url
                    ? state.landmark_image_url
                    : state.landmark_image
                      ? state.landmark_image
                      : DefaultImage
                }
                uploadImage={(image, filename) => {
                  delete state.landmark_image_url;
                  state.landmark_image = image;
                  state.landmark_image_file_name = filename;
                  addChangeState(state.id);
                }}
              />
              <CustomFormInput
                type="text"
                value={state.name}
                className={'w-100 mt-auto'}
                onChangeValue={(val) => onChangeStateName(val, state.id)}
              />
            </div>
          ))}
        </section>
      </div>
    );
  };

  const renderEditCountryLandmark = () => {
    return (
      <div className="grid-control">
        <section className="grid-full-col">
          <h4 className="at-landmark-mgmt__title">Country's Landmark</h4>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Country</h2>
          <CustomFormInput
            value={selectedCountry.name}
            onChangeValue={(val) => onChangeField(val, "name")}
            disabled={"true"}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Landmark Image</h2>
          <CustomUpload
            imageUrl={
              selectedCountry.landmark_image_url
                ? selectedCountry.landmark_image_url
                : selectedCountry.landmark_image
            }
            uploadImage={(image, filename) => {
              let tmp = _.cloneDeep(selectedCountry);
              delete tmp.landmark_image_url;
              tmp.landmark_image = image;
              tmp.landmark_image_file_name = filename;
              onChangeLandmarkHOC(tmp, "selectedCountry");
            }}
          />
        </section>
      </div>
    );
  };

  const renderCardContent = () => {
    return (
      <>
        <Tabs
          sections={['Country', 'State']}
          selectedSection={selectedSection}
          onSelectSection={(val) => updateSelectedSection(val)} />
        {selectedSection === "Country" && renderEditCountryLandmark()}
        {selectedSection === "State" && renderEditStateLandmark()}
      </>
    );
  };

  return (
    <ModalDialog
      title={"Edit Landmark"}
      fullWidth={true}
      fullHeight={true}
      onClose={() => onChangeLandmarkHOC(false, "showDetailsModal")}
      children={renderCardContent()}
      footer={
        <div className="d-flex align-items-center g-3">
          {selectedSection === "Country" && (
            <CustomButton
              className={"btn-new btn-new--success"}
              disabled={!selectedCountry.landmark_image}
              onClick={() => updateCountry()}
              children={"Update"}
            />
          )}
          {selectedSection === "State" && (
            <CustomButton
              className={"btn-new btn-new--success"}
              disabled={bulkUpdate.length < 1}
              onClick={() => {
                let dataToSubmit = [];
                bulkUpdate.map((state_id) => {
                  let state = _.find(selectedCountry.states, { id: state_id });
                  dataToSubmit.push({
                    id: state.id,
                    name: state.name,
                    ...(state.landmark_image && {
                      landmark_image: state.landmark_image,
                      landmark_image_file_name: state.landmark_image_file_name,
                    }),
                  });
                });
                batchUpdateState(dataToSubmit);
              }}
              children={"Update"}
            />
          )}
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onChangeLandmarkHOC(false, "showDetailsModal")}
          >
            Close
          </button>
        </div>
      } />
  )
};

export default LandmarkDetail;
