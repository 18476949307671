import React, { Component } from "react";

import AtlasDialog from "components/Dialog";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import MemberTable from "./MemberTable";
import TeamCampaignTable from "./PersonalCampaignTable";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasIcon from "components/Icon/atlasIcon";
import SalesEligibility from "./Modal/SalesEligibility";

import { numberWithCommas } from "utils/thousandSeparator";
import {
  MemberSearchParams,
  qualifiedMemberColumn,
  teamColumnData,
} from "../assets";

import "../index.scss";

class TeamCampaigns extends Component {
  renderQualifiedMember = () => {
    const {
      memberSearchParams,
      selectPromotion,
      selectedPromotion,
      onChangeCampaignTrackerHOC,
      showMemberSaleEligibility,
      membersEligibility,
      getMemberSaleEligibility,
    } = this.props;

    return (
      <>
        <div className="at-card__header mb-12">
          <AtlasCloseButton
            containerClass={"btn-close-project-sale"}
            onClick={() => {
              onChangeCampaignTrackerHOC("", "eligibilityStatus");
              onChangeCampaignTrackerHOC(
                MemberSearchParams,
                "memberSearchParams",
              );
              onChangeCampaignTrackerHOC(false, "showTeamEligibility");
            }}
          />
          <h4 className="at-card__title">
            {`${selectPromotion.promotionName}`}
          </h4>
        </div>
        {membersEligibility && membersEligibility[0] && (
          <div className="at-target__box mb-20">
            <div className="at-target__cont">
              <div>
                <AtlasIcon
                  svgHref={"atlas-cup"}
                  style={{ fill: "#F79009", width: 20, height: 20 }}
                />
              </div>
              <div>
                <div
                  style={{ fontWeight: 400, fontSize: 14, color: "#1F2937" }}
                >
                  Campaign Target
                </div>
                <div style={{ fontWeight: 600, fontSize: 16 }}>
                  {membersEligibility[0].campaignTarget}
                </div>
              </div>
            </div>
          </div>
        )}
        <MemberTable
          className={"team-sales__table"}
          columns={qualifiedMemberColumn}
          rowData={membersEligibility}
          searchParams={memberSearchParams}
          onChangeSearchParams={(val) =>
            onChangeCampaignTrackerHOC(val, "memberSearchParams")
          }
          showResetButton={true}
          pagination={true}
          actionColumnContent={[
            {
              name: "info",
              onClick: (rowData) => {
                getMemberSaleEligibility(
                  rowData.agentId,
                  selectPromotion.promotionId,
                );
                onChangeCampaignTrackerHOC(
                  {
                    ...selectPromotion,
                    campaignTarget: numberWithCommas(
                      rowData.targetValue.toFixed(2),
                    ),
                    currentProgress: numberWithCommas(
                      rowData.accumulatedValue.toFixed(2),
                    ),
                  },
                  "selectPromotion",
                );
              },
            },
          ]}
        />
        {showMemberSaleEligibility && (
          <SalesEligibility
            selectedPromotion={selectedPromotion}
            selectPromotion={selectPromotion}
            onClickClose={() =>
              onChangeCampaignTrackerHOC(false, "showMemberSaleEligibility")
            }
          />
        )}
      </>
    );
  };

  render() {
    const {
      teamSummary,
      showTeamEligibility,
      showTeamCampaignTerm,
      getMemberEligibility,
      onChangeCampaignTrackerHOC,
      getYearTeamSummary,
      selectPromotion,
      searchParams,
    } = this.props;

    return (
      <>
        <p className="at-form__content fs-2 my-3">
          The tracker provides you with an overview of campaign goals, enabling
          you to track team member performance and monitor their progress as
          they strive to achieve the set targets.
        </p>
        <TeamCampaignTable
          className={"team-sales__table"}
          columns={teamColumnData(onChangeCampaignTrackerHOC)}
          rowData={teamSummary}
          pagination={true}
          searchParams={searchParams}
          getListAPI={getYearTeamSummary}
          onChangeSearchParams={(val) =>
            onChangeCampaignTrackerHOC(val, "searchParams")
          }
          showResetButton={true}
          actionColumnContent={[
            {
              name: "info",
              title: "check details",
              onShow: (rowData) => true,
              disable: (rowData) => false,
              onClick: (rowData) => {
                onChangeCampaignTrackerHOC(rowData, "selectPromotion");
                getMemberEligibility(rowData.promotionId);
              },
            },
          ]}
          customEmptyState={{
            icon: <AtlasIcon style={{ fill: '#6B7280' }} svgHref={'icon-bold-gift'} />,
            title: 'You don\'t have any items yet'
          }}
        />
        <AtlasDialog open={showTeamEligibility}>
          <CustomCard
            cardContent={this.renderQualifiedMember()}
            containerStyle={{ minHeight: "-webkit-fill-available" }}
          />
        </AtlasDialog>
        {showTeamCampaignTerm && (
          <ModalDialog
            title={"Campaign Terms & Conditions"}
            maxWidth="md"
            onClose={() =>
              onChangeCampaignTrackerHOC(false, "showTeamCampaignTerm")
            }
          >
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              {selectPromotion.promotionName}
            </span>
            <div
              style={{ margin: "20px 0" }}
              dangerouslySetInnerHTML={{
                __html: selectPromotion.termsAndConditions,
              }}
            ></div>
            <div className="d-flex flex-wrap mt-10">
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={() =>
                  onChangeCampaignTrackerHOC(false, "showTeamCampaignTerm")
                }
              >
                Close
              </button>
            </div>
          </ModalDialog>
        )}
      </>
    );
  }
}

export default TeamCampaigns;
