import { numberWithCommas } from "utils/thousandSeparator";

export const subsalesHeader = (type) => [
  {
    header: "Agent",
    accessor: "Agent",
    searchFlag: "Agent",
    columnClass: "mt-0",
  },
  {
    header: "Type",
    columnClass: "mt-0",
    renderColumn: () => {
      switch (type) {
        case "sale":
          return "Sale";
        case "rent":
          return "Rental";
        case "all":
          return "Overall Subsales";
      }
    },
  },
  {
    header: "Converted Sales",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right mt-0",
    renderColumn: ({
      TotalSubsaleAgencyCommission,
      TotalRentalAgencyCommission,
      TotalAgencyCommission,
    }) => {
      switch (type) {
        case "sale":
          return (
            TotalSubsaleAgencyCommission &&
            numberWithCommas(TotalSubsaleAgencyCommission.toFixed(2))
          );
        case "rent":
          return (
            TotalRentalAgencyCommission &&
            numberWithCommas(TotalRentalAgencyCommission.toFixed(2))
          );
        case "all":
          return (
            TotalAgencyCommission &&
            numberWithCommas(TotalAgencyCommission.toFixed(2))
          );
      }
    },
  },
  {
    header: "Total Sales",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right mt-0",
    renderColumn: ({
      GrandTotalSubsaleAgencyCommission,
      GrandTotalRentalAgencyCommission,
      GrandTotalAgencyCommission,
    }) => {
      switch (type) {
        case "sale":
          return (
            GrandTotalSubsaleAgencyCommission &&
            numberWithCommas(GrandTotalSubsaleAgencyCommission.toFixed(2))
          );
        case "rent":
          return (
            GrandTotalRentalAgencyCommission &&
            numberWithCommas(GrandTotalRentalAgencyCommission.toFixed(2))
          );
        case "all":
          return (
            GrandTotalAgencyCommission &&
            numberWithCommas(GrandTotalAgencyCommission.toFixed(2))
          );
      }
    },
  },
];

export const projectHeader = [
  {
    header: "Agent",
    accessor: "Agent",
    searchFlag: "Agent",
    columnClass: "mt-0",
  },
  {
    header: "Converted Sales",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right mt-0",
    renderColumn: ({ TotalConverted }) =>
      numberWithCommas(TotalConverted.toFixed(2)),
  },
  {
    header: "Total Sales",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right mt-0",
    renderColumn: ({ TotalValidSale }) =>
      numberWithCommas(TotalValidSale.toFixed(2)),
  },
];
