import React, { Component } from "react";
import _ from "lodash";

import AtPaginatedTable from "components/NewPaginatedTable";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import RequestDetails from "./Details";

import { StatusColor } from "../../../assets";
import { getColorBadge } from "dictionary/badgeColor";
import RequestHOC from "../../../actions/request";

const columnData = [
  {
    header: "Request Date",
    accessor: "submission_date",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
  {
    header: "Assigned To",
    accessor: `assigned_admin`,
  },
];

class Request extends Component {
  componentDidMount = () => this.props.getRequests(this.props.selectedSale.id);

  renderCardContent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <AtPaginatedTable
          showPABadge={role === "Personal Assistant"}
          tableTitle={""}
          rowData={this.props.requests.data || []}
          meta={this.props.requests.meta}
          hideThematicBreak={true}
          showCloseButton={false}
          showCreateButton={false}
          columns={columnData}
          renderExtraContent={() => (
            <p className="at-card__description mt-10 mb-10">
              You only can create request after admin approved the project sale
            </p>
          )}
          actionColumnContent={[
            {
              name: "view",
              onClick: (rowData) =>
                this.props.getSelectedRequest(
                  this.props.selectedSale.id,
                  rowData.id,
                ),
            },
          ]}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeRequestHOC("searchParams", val)
          }
          getListAPI={this.props.getRequests}
          totalPages={this.props.requestsPages}
        />
      </>
    );
  };

  render = () => {
    const { onLoadRequests } = this.props;

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        {this.renderCardContent()}
        <AtlasDialog open={this.props.showRequestDetails}>
          <CustomCard
            className={"h-100"}
            cardContent={<RequestDetails {...this.props} role={role} />}
          />
        </AtlasDialog>
        {(onLoadRequests || this.props.onLoadPreview) && <LoadingModal />}
      </>
    );
  };
}

export default RequestHOC(Request);
