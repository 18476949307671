import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import Tab from "components/Tab/new";
import DuoTab from "components/Tab/duo";
import AlertBox from "components/AlertBox";
import PartyContent from "../../components/partyContent";
import FormModalContent from "../../components/formModalContent";
import TempSubrentAuthorisationForm from "./tempSubrentAuthorisationForm";
import TempSubsaleAuthorisationForm from "./tempSubsaleAuthorisationForm";
import EProcessContent from "./eProcessContent";
import AtlasEmailModal from "components/Modal/email";
import SvgIcon from "components/Icon/new";
import FormChart from "components/Chart/form";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTypeahead from "components/Typeahead/new";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import LoadingModal from "components/LoadingModal";

import permissionsChecker from "utils/permissionsChecker";
import isEmptyValue from "utils/isEmpty";
import PartyHOC from "../actions/party";

const SigningTabs = ["e-Signing", "Manual Signing"];

const NavTabs = [
  {
    label: "Participants",
    icon: <SvgIcon svgHref="confirmation-form_participants" />,
  },
  { label: "T&C", icon: <SvgIcon svgHref="confirmation-form_document-text" /> },
  {
    label: "Contract Signing",
    icon: <SvgIcon svgHref="confirmation-form_esign" />,
  },
];

const partyDictionary = [
  {
    id: 1,
    subsalesTitle: "Purchaser",
    subrentTitle: "Tenant",
  },
  {
    id: 2,
    subsalesTitle: "Purchaser's Representative",
    subrentTitle: "Tenant's Representative",
  },
];

const RadioOptions = [
  { label: "Create New Confirmation Form", value: "1" },
  { label: "Select Existing Confirmation Form", value: "2" },
  { label: "Select Existing Physical Form", value: "3" },
];

class EditContent extends Component {
  state = {
    selectedSection: "Participants",
    toSubmitData: {},
    formBondType: "",
    selectedForm: {},
  };
  componentDidMount = () => {
    const { selectedAuthorisationForm } = this.props;

    // check if e_process is not null then, call api
    this.props.tempEProcess &&
      this.props.getSigningStatus(selectedAuthorisationForm.id);

    if(this.state.selectedSection === 'Participants') {
      this.props.getParty(selectedAuthorisationForm.id);
      this.props.getCurrentFormContactList(selectedAuthorisationForm.id);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.formBondType !== this.state.formBondType) {
      this.setState({ selectedForm: "" });
    }

    if (prevState.selectedSection !== this.state.selectedSection) {
      const { selectedAuthorisationForm } = this.props;

      if([ "Participants", "Contract Signing" ].indexOf(this.state.selectedSection) > -1) {
        this.props.getParty(selectedAuthorisationForm.id);

        if(this.state.selectedSection === 'Participants') {
          this.props.getCurrentFormContactList(selectedAuthorisationForm.id);
        }
      }
    }
  };

  onCloseSelectForm = () => {
    this.props.onChangeAuthorisationHOC(false, "showSelectForm");
    this.setState({
      formBondType: "",
      selectedForm: {},
    });
  };

  submitEmail = (recipient) => {
    this.props.sendAuthorisationForm(this.props.selectedAuthorisationForm.id, {
      recipients: recipient,
    });
  };

  renderTabContent = () => {
    return (
      <Tab
        sections={NavTabs}
        selectedSection={this.state.selectedSection}
        onSelectSection={(val) => this.setState({ selectedSection: val })}
      />
    );
  };

  renderContractParticipants = () => {
    return (
      <PartyContent
        {...this.props}
        form_type={"authorisation"}
        partyDictionary={partyDictionary}
        selected_form={this.props.selectedAuthorisationForm}
        renderNavButton={() =>
          this.renderNavButton(this.state.selectedSection)
        }
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
      />
    );
  };

  renderSigningTabContent = () => {
    const { id: authorisationId, transaction_number } =
      this.props.selectedAuthorisationForm;
    const { selectedSigningTab } = this.props;
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { sideMenuExpand } = this.props.data.sideMenuReducer;
    return (
      <>
        {selectedSigningTab === "Manual Signing" && (
          <>
            <AlertBox
              title={""}
              containerClassName="mb-3"
              containerStyle={{
                backgroundColor: "#FFFAEA",
                borderColor: "#FCB122",
                marginBottom: "1rem",
              }}
              iconStyle={{ color: "#F79008" }}
              description={`Please make sure that you have saved the data by clicking the "Save Terms & Conditions" button in T&C before proceeding any actions below.`}
            />
            <div className="at-form__content">
              <h5 className="at-card_content-title mb-3">{"Manual Signing"}</h5>
              <p className="fs-2">
                You can offer your client two options when signing the
                confirmation form:
              </p>
              <p className="fs-2">
                1. You can print out the confirmation form and cross out any
                inapplicable phrases or mistakes before your client signs the
                printed form.
              </p>
              <p className="fs-2">
                2. Should your client wish to use a digital signature for the
                confirmation form, you will need to attach a screenshot of the
                client sending you the confirmation form (via E-mail, WhatsApp,
                WeChat or any other platform) as proof of submission for claims.
              </p>
              <br />
              <p className="fs-2">
                Download Adobe Fill & Sign mobile app to sign PDF documents
                digitally.
              </p>
              <div className="d-flex flex-wrap grid_gap-1 mt-2">
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    this.props.downloadAuthorisationForm(
                      authorisationId,
                      `${transaction_number}.pdf`,
                    )
                  }
                >
                  {`Download Authorisation Form`}
                </button>
                {can_update && (
                  <button
                    className="btn-new btn-new--secondary"
                    onClick={() =>
                      this.props.onChangeAuthorisationHOC(true, "showSendEmail")
                    }
                  >
                    {`Send Authorisation Form`}
                  </button>
                )}
              </div>
            </div>
            <div
              className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}
            >
              {this.renderNavButton(this.state.selectedSection)}
            </div>
          </>
        )}
        {selectedSigningTab === "e-Signing" && (
          <EProcessContent
            {...this.props}
            renderNavButton={() =>
              this.renderNavButton(this.state.selectedSection)
            }
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          />
        )}
      </>
    );
  };

  renderFormContent = (switchMode) => {
    const { closedEditing } = this.props
    const { type_id } = this.props.selectedAuthorisationForm;

    return (
      <>
        {type_id === 1 && (
          <TempSubsaleAuthorisationForm
            switchMode={switchMode}
            closedEditing={closedEditing}
            onChange={(title, val = "") =>
              this.props.onChangeAuthorisationFormValue(title, val)
            }
            onTriggerSpecialConditionModal={this.onTriggerSpecialConditionModal}
            storedAddresses={this.props.data.addressReducer.address}
            data={this.props.selectedAuthorisationForm}
          />
        )}
        {type_id !== 1 && (
          <TempSubrentAuthorisationForm
            switchMode={switchMode}
            onChange={(title, val = "") =>
              this.props.onChangeAuthorisationFormValue(title, val)
            }
            closedEditing={closedEditing}
            onTriggerSpecialConditionModal={this.onTriggerSpecialConditionModal}
            storedAddresses={this.props.data.addressReducer.address}
            data={this.props.selectedAuthorisationForm}
          />
        )}
      </>
    );
  };

  renderContractSigning = () => {
    return (
      <>
        <div className="at-form__content at-new_container-20">
          <h5 className="at-card_content-title">{"Contract Signing"}</h5>
          <p className="fs-2">
            How do you want to sign this contract? Choose only one method.
          </p>
        </div>
        <DuoTab
          sections={SigningTabs}
          selectedSection={this.props.selectedSigningTab}
          onSelectSection={(val) =>
            this.props.onChangePartyHOC(val, "selectedSigningTab")
          }
        />
        {this.renderSigningTabContent()}
      </>
    );
  };

  renderNavButton = (selectedTab) => {
    let tmpTabs = _.cloneDeep(NavTabs);
    let tmpIndex = tmpTabs.findIndex((item) => item.label === selectedTab);
    let lastIndex =
      tmpTabs.findIndex((item) => item.label === "Contract Signing") + 1;

    return (
      <>
        {tmpIndex - 1 >= 0 && (
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() =>
              this.setState({ selectedSection: tmpTabs[tmpIndex - 1].label })
            }
          >
            <p>{tmpTabs[tmpIndex - 1].label}</p>
          </button>
        )}
        {tmpIndex + 1 < lastIndex && (
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() =>
              this.setState({ selectedSection: tmpTabs[tmpIndex + 1].label })
            }
          >
            <p>{tmpTabs[tmpIndex + 1].label}</p>
          </button>
        )}
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() =>
            this.props.onChangeAuthorisationHOC(
              false,
              "showEditAuthorisationForm",
            )
          }
        >
          Close
        </button>
      </>
    );
  };

  renderFormsChart = () => {
    const { forms_chart, user_id, id } =
      this.props.selectedAuthorisationForm;

    return (
      forms_chart !== null && (
        <FormChart
          history={this.props.history}
          infoData={{ id: id }}
          currentFormType={"Authorisation Form"}
          forms_chart={forms_chart}
          onClickButton={(data) => {
            this.props.onChangeAuthorisationHOC(true, "showSelectForm");
            this.setState({
              toSubmitData: {
                ...data,
                user_id: user_id,
              },
            });
          }}
          onClickDetach={(data) => {
            this.setState({
              toSubmitData: data,
            });
            this.props.onChangeAuthorisationHOC(true, "showDetachConfirmation");
          }}
        />
      )
    );
  };

  renderClosingFormModal = () => {
    const { formBondType, selectedForm } = this.state;
    const { user_id, type_id } = this.props.selectedAuthorisationForm;

    if (this.props.showSelectForm) {
      return (
        <ModalDialog
          title={"Submit Authorisation Form"}
          fullHeight={false}
          onClose={() => this.onCloseSelectForm()}
          children={
            <div className="at-authorz_form-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose an option to submit authorisation form:
              </p>
              <AtlasRadioGroup
                horizontal={false}
                mode="old"
                checkedValue={formBondType}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => {
                  if (val === "2") {
                    this.props.getUnattachedConfirmationForms(
                      "AuthorisationForm",
                      user_id,
                      type_id,
                    );
                  } else if (val === "3") {
                    this.props.getUnattachedSubsalesForms(user_id, type_id);
                  }
                  this.setState({ formBondType: val });
                }}
                options={RadioOptions}
              />
              {formBondType === "2" && (
                <div className="mt-3 mb-4" style={{ height: 250 }}>
                  <h2 className="at-form-input__title mr-10">Form Number</h2>
                  <CustomTypeahead
                    showClearButton
                    typeaheadId={"unclaimedConfirmationForm"}
                    options={this.props.unclaimedConfirmationForms}
                    selectedValue={
                      this.state.selectedForm &&
                      _.find(this.props.unclaimedConfirmationForms, {
                        id: this.state.selectedForm.id,
                      })
                        ? [
                            _.find(this.props.unclaimedConfirmationForms, {
                              id: this.state.selectedForm.id,
                            }),
                          ]
                        : []
                    }
                    labelKey={"label"}
                    filterBy={["label", "property_address"]}
                    onSelect={(val) => {
                      if (val && val.length > 0) {
                        return this.setState({ selectedForm: val[0] });
                      }
                      return this.setState({ selectedForm: "" });
                    }}
                    childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                    highlighterData={[
                      (option) => option.property_address,
                    ]}
                  />
                </div>
              )}
              {formBondType === "3" && (
                <div className="mt-3 mb-4" style={{ height: 250 }}>
                  <h2 className="at-form-input__title mr-10">Form Number</h2>
                  <CustomTypeahead
                    showClearButton
                    typeaheadId={"unclaimedSubsalesForm"}
                    options={this.props.unclaimedSubsalesForms}
                    selectedValue={
                      this.state.selectedForm &&
                      _.find(this.props.unclaimedSubsalesForms, {
                        id: this.state.selectedForm.id,
                      })
                        ? [
                            _.find(this.props.unclaimedSubsalesForms, {
                              id: this.state.selectedForm.id,
                            }),
                          ]
                        : []
                    }
                    labelKey={"label"}
                    filterBy={["label"]}
                    onSelect={(val) => {
                      if (val && val.length > 0) {
                        return this.setState({ selectedForm: val[0] });
                      }
                      return this.setState({ selectedForm: {} });
                    }}
                    childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                    highlighterData={[
                      (option) => option.property_address,
                    ]}
                  />
                </div>
              )}
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                {formBondType && (
                  <button
                    disabled={
                      formBondType === "1"
                        ? false
                        : isEmptyValue(this.state.selectedForm)
                    }
                    className="btn-new btn-new--primary"
                    onClick={() => {
                      let tmpformBondType = _.cloneDeep(formBondType);
                      let tmpselectedForm = _.cloneDeep(selectedForm);
                      if (formBondType !== "1") {
                        this.setState({
                          formBondType: "",
                          selectedForm: {},
                        });
                      }
                      let tmpData = _.cloneDeep(this.state.toSubmitData);
                      formBondType === "1"
                        ? this.props.onChangeAuthorisationHOC(
                            true,
                            "showSubmitConfirmation",
                          )
                        : this.props.submitForm(
                            tmpData.form_id,
                            "AuthorisationForm",
                            {
                              submission_method_id: tmpformBondType,
                              attached_form_id: tmpselectedForm.id,
                            },
                          );
                    }}
                  >
                    Submit
                  </button>
                )}
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => this.onCloseSelectForm()}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        />
      );
    }
  };

  render = () => {
    const {
      e_process,
      tmp_claimed,
      description,
      transaction_number,
    } = this.props.selectedAuthorisationForm;
    const { closedEditing } = this.props

    const { status_id } = e_process ?? {}
    const tmpEProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))  

    const { associatedForms = [], associatedTransNumber = [] } = this.state.toSubmitData;
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const tmpTransactionNumber = `${!isEmptyValue(transaction_number) ? `/ ${transaction_number}` : ""}`;

    return (
      <>
        <FormModalContent
          title={`Edit Form ${tmpTransactionNumber}`}
          formType={"authorisation form"}
          can_update={can_update}
          closedEditing={closedEditing}
          eProcessDisabledChecking={tmpEProcessDisabledChecking}
          tmp_claimed={tmp_claimed}
          description={description}
          selectedSection={this.state.selectedSection}
          closeFormModal={() =>
            this.props.onChangeAuthorisationHOC(
              false,
              "showEditAuthorisationForm",
            )
          }
          saveTnC={() => this.props.updateAuthorisationForm()}
          formContent={(switchMode) => this.renderFormContent(switchMode)}
          partyContent={this.renderContractParticipants()}
          signingContent={this.renderContractSigning()}
          tabContent={this.renderTabContent()}
          chartContent={this.renderFormsChart()}
          propertyContent={""}
          renderNavButton={() =>
            this.renderNavButton(this.state.selectedSection)
          }
          menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          toggleClaimed={this.props.claimedAuthorisationForm}
          loading={this.props.onLoadAuthorisation}
          onChangeField={this.props.onChangeAuthorisationFormValue}
        />
        <AtlasEmailModal
          title={"Send Authorisation Form"}
          loading={this.props.onLoadAuthorisation}
          showSendEmail={this.props.showSendEmail}
          closeSendEmail={() =>
            this.props.onChangeAuthorisationHOC(false, "showSendEmail")
          }
          submitEmail={(recipients) => this.submitEmail(recipients)}
        />
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmation}
          title={"Closing Deal"}
          message={
            "Are you sure you want to submit Authorisation Form to Confirmation Form?"
          }
          loading={this.props.onLoadAuthorisation || this.props.onLoadForms}
          positiveText={"Submit"}
          icon={<AtlasIcon svgHref={"svg-document-home"} />}
          positiveAction={() =>
            this.props.submitForm(
              this.state.toSubmitData.form_id,
              "AuthorisationForm",
              { submission_method_id: this.state.formBondType },
            )
          }
          negativeText={"Cancel"}
          negativeAction={() =>
            this.props.onChangeAuthorisationHOC(false, "showSubmitConfirmation")
          }
        />
        <ConfirmationModal
          mode={"info"}
          open={this.props.showDetachConfirmation}
          title={`Detach ${associatedForms[1]}`}
          message={`Are you sure you want to detach ${associatedTransNumber[1]} from ${associatedTransNumber[0]}?`}
          loading={this.props.onLoadAuthorisation || this.props.onLoadForms}
          positiveText={"Detach"}
          icon={<AtlasIcon svgHref={"svg-document-home"} />}
          positiveAction={() => {
            let tmpData = _.cloneDeep(this.state.toSubmitData);
            tmpData.type_id = this.props.selectedAuthorisationForm.type_id;
            this.props.detachForm(tmpData);
          }}
          negativeText={"Cancel"}
          negativeAction={() =>
            this.props.onChangeAuthorisationHOC(false, "showDetachConfirmation")
          }
        />
        {this.renderClosingFormModal()}
        {(this.props.onLoadAuthorisation || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(PartyHOC)(EditContent);
