import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

import defaultImage from "assets/images/iqi_logo_only.png";

const SliderArrow = ({ className, onClick }) => {
  return (
    <div
      className={`${className} at-subsales__slick-slider-arrow`}
      onClick={onClick}
    />
  );
};

const horizontalImageConfig = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const verticalImageConfig = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};

const settings = {
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  centerPadding: "60px",
  swipeToSlide: true,
  lazyLoad: true,
  className: "at-subsales__slick-initialized",
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />,
};

const ImageItem = ({ index, item }) => {
  let imageRef = useRef(null);

  const [isHorizontalImage, setOrientation] = useState(false);

  useEffect(() => {
    if (imageRef && imageRef.current) {
      imageRef.current.naturalWidth > imageRef.current.naturalHeight &&
        setOrientation(true);
    }
  }, []);

  return (
    <img
      className={`at-subsales-slider_image`}
      src={item || defaultImage}
      onError={(e) => {
        if (e.target) {
          e.target.src = defaultImage;
        }
      }}
      ref={imageRef}
      style={isHorizontalImage ? horizontalImageConfig : verticalImageConfig}
      alt={`Project Slideshow Image ${index}`}
    />
  );
};

const PhotoSlider = ({ id, images = [] }) => {
  return (
    <div className="at-subsales-photo_slider">
      <Slider {...settings}>
        {images.length > 0 ? (
          images.map((item, index) => {
            return (
              <div
                className="at-subsales-slider_item"
                key={`img-${id}-${index}`}
              >
                <div className="at-subsales__image">
                  <div className="at-subsales__img-cont">
                    <ImageItem item={item} index={index} />
                    <div
                      className="at-subsales__gradient_foreground"
                      style={{
                        backgroundImage: `url(${item || defaultImage})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="at-subsales-slider_item" key={`img-${id}-0`}>
            <div className="at-subsales__image">
              <div className="at-subsales__img-cont">
                <img
                  className={`at-subsales-slider_image`}
                  style={{
                    backgroundColor: "#FFFFFFCC",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={defaultImage}
                />
                <div
                  className="at-subsales__gradient_foreground"
                  style={{ backgroundImage: `url(${defaultImage})` }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
};

export default PhotoSlider;
