import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post, Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      importRecords: {},
      importPages: [],

      importExcel: null,
      onLoadStatusId: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeImportHOC = (val, context) => this.setState({ [context]: val });

    importIpropertyData = (dataToSubmit) =>
      Post(
        `/sub_sales/import_iproperty_excel?locale=${this.props.data.languageReducer.language}`,
        dataToSubmit,
        this.importIpropertyDataSuccess,
        this.importIpropertyDataError,
        this.load,
      );
    importIpropertyDataSuccess = () => {
      requestSuccess(this.props.getLocalised(
        "success.import_iproperty",
        `The importation of listings is now processing, please click on the 'CheckStatus' button to check if all the listing has been successfully imported`,
      ));
      this.getImportStatus(1, "");
      this.setState({ importExcel: null });
    };
    importIpropertyDataError = (error) => requestError(error);

    getImportStatus = (page, search) =>
      Get(
        `/import_statuses?${search}page=${page}&locale=${this.props.data.languageReducer.language}`,
        this.getImportStatusSuccess,
        this.getImportStatusError,
        this.load,
      );
    getImportStatusSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        importRecords: payload,
        importPages: tmpTotalPages,
      });
    };
    getImportStatusError = (error) => requestError(error);

    checkStatus = (id) =>
      Get(
        `/import_statuses/${id}/check_status?locale=${this.props.data.languageReducer.language}`,
        this.checkStatusSuccess,
        this.checkStatusError,
        (val) => this.setState({ onLoadStatusId: val ? id : null }),
      );
    checkStatusSuccess = (payload) => {
      let temp = _.cloneDeep(this.state.importRecords);
      let tempIndex = _.findIndex(temp.data, { id: payload.id });

      temp.data[tempIndex] = payload;
      this.setState({ importRecords: temp });
    };
    checkStatusError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            importExcel={this.state.importExcel}
            onLoadImport={this.state.loading}
            importRecords={this.state.importRecords}
            importPages={this.state.importPages}
            onLoadStatusId={this.state.onLoadStatusId}
            checkStatus={this.checkStatus}
            onChangeImportHOC={this.onChangeImportHOC}
            importIpropertyData={this.importIpropertyData}
            getImportStatus={this.getImportStatus}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
