import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import ModalDialog from 'components/Modal/ModalDialog';
import CustomUpload from 'components/Input/upload';
import CustomFormInput from 'components/Input/formInput';
import CustomButton from 'components/Button';

export default function CreateAttachment({
  createAttachment,
  onLoad,
  onClose,
}) {

  const [ attachementData, onChangeeAttachmentData ] = useState({
    title: '' ,
    image: '',
    image_file_name: ''
  })
  
  const onChangeValue = ( value, context ) => onChangeeAttachmentData( prev => ({
    ... prev,
    [ context ]: value
  }) )

  return (
    <ModalDialog
      title={ `Create Attachment` }
      responsiveSize={ 'md' }
      onLoading={ onLoad }
      onClose={ () => onClose() }
      children={
        <div className='grid-control'>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Title</h2>
            <CustomFormInput
              type="text"
              required={ true }
              multiple={ false }
              lineStyle={{ display: 'none' }}
              value={ attachementData.title }
              onChangeValue={ val => onChangeValue( val, 'title' ) }
              placeholder={ 'Enter button label' } />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Attachment</h2>
            <CustomUpload
              required={ true }
              multiple={ false }
              style={{ width: '100%' }}
              accepts={ 'application/*, image/*' }
              imageUrl={ attachementData.image }
              uploadImage={( files ) => {
                onChangeeAttachmentData( prev => ({
                  ... prev,
                  image: files[ 0 ].source,
                  image_file_name: files[ 0 ].name
                }) )
              }}
            />
          </section>
        </div>
      }
      footer={
        <div className='d-flex g-3'>
          <CustomButton
              disabled={ _.values( attachementData ).some( item => item === '' || _.isEmpty( item ) ) }
              className={ `btn-new btn-new--primary` }
              children={ 'Create' }
              onClick={ () => createAttachment( attachementData ) }
            />
            <CustomButton
              className='btn-new btn-new--outline-secondary'
              children={ 'Close' }
              onClick={ () => onClose() }
            />
        </div>
      } />
  )
}
