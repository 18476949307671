import React, { Component } from "react";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put } from "utils/axios";

const Payouts = (WrappedComponent) => {
  class PayoutsHOC extends Component {
    state = {
      payouts: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangePayoutsHOC = (key, val) => this.setState({ [key]: val });

    getPayouts = (submission_id) =>
      Get(
        `/admin/submissions/${submission_id}/payouts`,
        this.getPayoutsSuccess,
        this.getPayoutsError,
        this.load,
      );
    getPayoutsSuccess = (payload) =>
      this.setState({ payouts: payload.submission_payouts });
    getPayoutsError = (error) => requestError(error);

    updatePayouts = (submission_id, data) =>
      Put(
        `/admin/submissions/${submission_id}/payouts`,
        data,
        () => this.updatePayoutsSuccess(submission_id),
        this.updatePayoutsError,
        this.load,
      );
    updatePayoutsSuccess = (submission_id) => {
      requestSuccess(
        "Payouts updated successfully. Please remember to combine the documents for the updates.",
      );
      this.getPayouts(submission_id);
    };
    updatePayoutsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            payouts={this.state.payouts}
            getPayouts={this.getPayouts}
            updatePayouts={this.updatePayouts}
            onChangePayoutsHOC={this.onChangePayoutsHOC}
            onLoadPayouts={this.state.loading}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(PayoutsHOC);
};

export default Payouts;
