import { forwardRef } from "react";

const Card = forwardRef(
  (
    {
      className,
      title,
      subtitle,
      imageContent,
      cardContent,
      cardFooter,
      maxHeight,
      enableMinHeight,
      containerStyle,
    },
    ref,
  ) => {
    const renderHeight = () => {
      let moduleHeader = document.getElementsByClassName(
        "at_module_header--cont",
      );
      let height = moduleHeader[0] ? moduleHeader[0].offsetHeight : 0;
      return `calc(100vh - ${height}px - 60px)`;
    };

    return (
      <div
        className={`at-card ${className || ""}`}
        ref={ref}
        style={{
          display: maxHeight ? "initial" : "flex",
          minHeight: enableMinHeight ? renderHeight() : "initial",
          overflowX: "auto",
          ...containerStyle,
        }}
      >
        {imageContent && <div className="card-image">{imageContent}</div>}
        {(title || subtitle) && (
          <div className="at-card__header">
            <h4 className="at-card__title mb-20">{title}</h4>
            <p className="card-category">{subtitle}</p>
          </div>
        )}
        <div>{cardContent}</div>
        {cardFooter && <div className="at-card__footer">{cardFooter}</div>}
      </div>
    );
  },
);

export default Card;
