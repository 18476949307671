import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showRecipientsSelectModal: false,
      refundPartyInfo: {
        name: "",
        nric: "",
        mobile_phone: "",
        address: "",
      },
      refundFormData: {
        id: null,
        type_id: "",
        abort_reason: "",
        refund_party_id: "",
        refund_for_buyer: false,
        refund_for_seller: false,
        refund_details: {
          buyer: [],
          seller: [],
        },
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeRefundHOC = (val, context) => this.setState({ [context]: val });

    updateRefundForm = (dataToSubmit) =>
      Put(
        `/forms/refund_forms/${dataToSubmit.id}`,
        dataToSubmit,
        () => this.updateRefundFormSuccess(dataToSubmit.form_id),
        this.updateRefundFormError,
        this.load,
      );
    updateRefundFormSuccess = (id) => {
      requestSuccess("Refund Form updated successfully.");
      this.getRefundForm(id);
    };
    updateRefundFormError = (error) => requestError(error);

    getRefundForm = (id) =>
      Get(
        `/forms/refund_forms/by_confirmation_form?confirmation_form_id=${id}`,
        this.getRefundFormSuccess,
        this.getRefundFormError,
        this.load,
      );
    getRefundFormSuccess = (payload) => {
      let tempPartyInfo = _.cloneDeep(this.state.refundPartyInfo);
      let temp = _.find(this.props.eProcessPartyData, {
        id: payload.refund_party_id,
      });

      if (temp) {
        tempPartyInfo = {
          name: temp.full_name,
          nric: temp.identification_number,
          mobile_phone: temp.mobile_contact_number,
          address: temp.current_address,
        };
      }

      this.setState({
        refundFormData: {
          ...payload,
          refund_details: {
            seller: payload.refund_sellers,
            buyer: payload.refund_buyers,
          },
        },
        refundPartyInfo: tempPartyInfo,
      });
    };
    getRefundFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadRefund={this.state.loading}
            refundFormData={this.state.refundFormData}
            refundPartyInfo={this.state.refundPartyInfo}
            showRecipientsSelectModal={this.state.showRecipientsSelectModal}
            getRefundForm={this.getRefundForm}
            onChangeRefundHOC={this.onChangeRefundHOC}
            updateRefundForm={this.updateRefundForm}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
