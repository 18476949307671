import { AUTH_ADMIN_LOGIN, AUTH_ADMIN_LOGOUT } from "actions/type";

let initialState = {
  current_sign_in_user: "",
  current_sign_in_token: "",
  current_sign_in_refresh_token: "",
  login_id: "",
  prefix_path: "",
};

export default function adminSignInReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_ADMIN_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ADMIN_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
