import React from "react";
import _ from "lodash";
import { Card } from "@material-ui/core";
import { PulseLoader } from "react-spinners";

import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import CustomInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";

import ProfileImage1 from "assets/images/av-male.jpg";

import AgentHOC from "../actions/agent";

const AssignDialog = ({
  data,
  email,
  loading,
  context,
  selectedAgent,
  onLoadAgentPulse,
  searchAgentStatusMessage,

  onClose,
  searchAgent,
  assignAgent,
  onChangeLeadAgentHOC,
}) => {
  const party = context === "agent" ? "Lead" : "Admin";
  const { id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const onClickSearchAgent = () =>
    Promise.all([onChangeLeadAgentHOC({}, "selectedAgent")]).then(() =>
      searchAgent(context, email),
    );

  return (
    <ModalDialog
      title={`Assign ${party}`}
      onLoad={loading}
      children={
        <>
          <p className="fs-2 mb-20" style={{ color: "#1F2937" }}>
            {context === "agent"
              ? "Assign selected Lead to agent below."
              : "Assign selected Lead to admin below."}
          </p>
          <h2 className="at-form-input__title">
            {_.capitalize(context)} Email Address / Phone Number
          </h2>
          <CustomInput
            type="email"
            className="mb-10"
            value={email}
            placeholder={"e.g. name@email.com or 60123456789"}
            onChangeValue={(val) => onChangeLeadAgentHOC(val, "email")}
          />
          {onLoadAgentPulse ? (
            <PulseLoader sizeunit={"px"} size={15} />
          ) : (
            <>
              {searchAgentStatusMessage && (
                <AlertBox
                  mode={"danger"}
                  description={searchAgentStatusMessage}
                />
              )}
              <div className="d-flex mt-10 mb-10 g-3">
                <CustomButton
                  className={"btn-new btn-new--secondary"}
                  onClick={() => onClickSearchAgent()}
                  children={"Search"}
                />
              </div>
            </>
          )}
          {!_.isEmpty(selectedAgent) && (
            <Card
              className={"row"}
              style={{ padding: 15, maxWidth: 600, margin: 0 }}
            >
              <div
                className="col-lg-4 col-12 mb-10 p-0"
                style={{ textAlign: "center" }}
              >
                <img
                  alt={`agents`}
                  src={
                    selectedAgent.profile_photo_url
                      ? selectedAgent.profile_photo_url
                      : ProfileImage1
                  }
                  style={{
                    borderRadius: "50%",
                    width: 120,
                    height: 120,
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    if (e.target) {
                      e.target.src = ProfileImage1;
                    }
                  }}
                />
              </div>
              <div className={"col-lg-8 col-12"}>
                <p style={{ marginBottom: 5 }}>{selectedAgent.full_name}</p>
                <p style={{ marginBottom: 5 }}>{selectedAgent.email}</p>
                <p style={{ marginBottom: 5 }}>
                  {selectedAgent.mobile_contact_number}
                </p>
                {selectedAgent.id === id && (
                  <p className="at-form-input__title text-danger">Lead Owner</p>
                )}
              </div>
            </Card>
          )}
        </>
      }
      footer={
        selectedAgent.id !== id && (
          <div className="d-flex g-3">
            <CustomButton
              className={"btn-new btn-new--primary"}
              onClick={() => assignAgent(selectedAgent.id)}
              tooltipTitle={"Click to assign this user into the lead."}
              tooltipPlacement={"right"}
              children={`Assign ${context}`}
            />
            <CustomButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClose()}
              children={"Cancel"}
            />
          </div>
        )
      }
      onClose={onClose}
    />
  );
};

export default AgentHOC(AssignDialog);
