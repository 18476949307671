import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      unit: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getUnitInfo = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/unit_info`,
        this.getUnitInfoSuccess,
        this.getUnitInfoError,
        this.load,
      );
    getUnitInfoSuccess = (payload) => {
      if (payload && !_.isEmpty(payload)) {
        let unit = {};
        let tmpAgents = [];
        payload.Agents &&
          payload.Agents.length > 0 &&
          payload.Agents.map((item) => {
            let tmp = {
              "Agent Name": item.AgentName,
              "Email Address": item.EmailAddress,
              "Mobile Phone": item.MobilePhone,
              "Admin Fee Amount": item.AdminFeeAmount,
              "Agency Commission Amount": item.AgencyCommissionAmount,
              "Extra Collection Amount": item.ExtraCollectionAmount,
              "Stamping Fee Amount": item.StampingFeeAmount,
            };
            tmpAgents.push(tmp);
          });
        unit = {
          ...payload,
          Agents: tmpAgents,
          BookingDate: Moment(payload.BookingDate).format("DD MMM YYYY"),
        };
        this.setState({ unit });
      }
    };
    getUnitInfoError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            getUnitInfo={this.getUnitInfo}
            onLoadUnitInfo={this.state.loading}
            unit={this.state.unit}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
