import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { StatusLib } from "../assets";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: [],
      loading: false,
      showPreviewModal: false,

      reassignSubmissions: {},
      previewData: "",

      showDeleteConfirmation: false,
      showEditAgreementModal: false,
      showCreateAgreementModal: false,

      agreements: {},
      selectedAgreement: null,
      agreementPages: [],
    };

    onChangeAgreementHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    getAgreements = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/agreements?${search}page=${page}`,
        this.getAgreementsSuccess,
        this.getAgreementsError,
        this.load
      );
    };
    getAgreementsSuccess = (payload) => {
      let tempPages = [];
      let tempData = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      payload.data.map((item) => {
        tempData.push({
          ...item,
          publish_on: item.publish_on
            ? Moment(item.publish_on).format("DD MMM YYYY")
            : null,
          status: _.find(StatusLib, { id: `${item.status_id}` }).name || "N/A",
        });
      });
      this.setState({
        agreements: {
          ...payload,
          data: tempData,
        },
        agreementPages: tempPages,
      });
    };
    getAgreementsError = (error) => requestError(error);

    getSelectedAgreement = (id) =>
      Get(
        `/admin/agreements/${id}`,
        this.getSelectedAgreementSuccess,
        this.getSelectedAgreementError,
        this.load
      );
    getSelectedAgreementSuccess = (payload) =>
      this.setState({
        selectedAgreement: {
          ...payload,
          publish_on: payload.publish_on
            ? Moment(payload.publish_on).format("DD MMM YYYY")
            : null,
          status_id: `${payload.status_id}`,
        },
        showEditAgreementModal: true,
      });
    getSelectedAgreementError = (error) => requestError(error);

    createAgreement = (dataToSubmit) =>
      Post(
        `/admin/agreements`,
        dataToSubmit,
        this.createAgreementSuccess,
        this.createAgreementError,
        this.load
      );
    createAgreementSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAgreements(currentPage, searchParams);
      this.setState({ showCreateAgreementModal: false });
      requestSuccess(`Agreement has been created successfully.`);
    };
    createAgreementError = (error) => requestError(error);

    updateAgreement = (dataToSubmit) =>
      Put(
        `/admin/agreements/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateAgreementSuccess,
        this.updateAgreementError,
        this.load
      );
    updateAgreementSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAgreements(currentPage, searchParams);

      requestSuccess(`Agreement has been updated successfully.`);
    };
    updateAgreementError = (error) => requestError(error);

    previewAgreement = (id) =>
      Get(
        `/admin/agreements/${id}/preview`,
        this.previewAgreementSuccess,
        this.previewAgreementError,
        this.load
      );
    previewAgreementSuccess = (payload) => {
      this.setState(
        {
          previewData: payload,
        },
        () => this.setState({ showPreviewModal: true })
      );
    };
    previewAgreementError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            previewData={this.state.previewData}
            showPreviewModal={this.state.showPreviewModal}
            agreementPages={this.state.agreementPages}
            searchParams={this.state.searchParams}
            agreements={this.state.agreements}
            selectedAgreement={this.state.selectedAgreement}
            showCreateAgreementModal={this.state.showCreateAgreementModal}
            showEditAgreementModal={this.state.showEditAgreementModal}
            onLoadAgreement={this.state.loading}
            onChangeAgreementHOC={this.onChangeAgreementHOC}
            getAgreements={this.getAgreements}
            getSelectedAgreement={this.getSelectedAgreement}
            createAgreement={this.createAgreement}
            updateAgreement={this.updateAgreement}
            previewAgreement={this.previewAgreement}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
