import { getItem } from "utils/tokenStore";

const token =
  window.location.href.indexOf("/admin-impersonate") > -1
    ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
    : getItem("IQI_ATLAS_JWT_TOKEN");

export const AssociatedAgentRoutingData = [
  {
    id: 1,
    name: "Dashboard",
    key: "label.sidemenu.dashboard",
    icon: "atlas-grid-5",
    navigateTo: "/dashboard/statistic",
    type: "menu",
  },
  {
    name: "Team Hub",
    key: "label.sidemenu.team_hub",
    icon: "atlas-profile2user",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        id: [5, 23, 8],
        name: "My Team",
        key: "label.sidemenu.team_hub.my_team",
        icon: "atlas-profile-circle",
        navigateTo: "/dashboard/team/my-team",
      },
      {
        id: 4,
        name: "Team Sales",
        label: "label.sidemenu.team_hub.team_sales",
        icon: "atlas-dollar-square",
        navigateTo: "/dashboard/team/team-sales",
      },
      {
        id: 6,
        name: "Project Sales",
        label: "label.sidemenu.team_hub.project_sales",
        icon: "atlas-project-sales",
        navigateTo: "/dashboard/team/project-sales",
      },
      {
        id: 10,
        name: "New Agent Approvals",
        label: "label.sidemenu.team_hub.new_agent_approvals",
        icon: "atlas-profile-add",
        navigateTo: "/dashboard/team/pending-approval",
      },
    ],
  },
  {
    name: "Real Estate Radar",
    key: "label.sidemenu.real_estate_radar",
    icon: "atlas-home-hastag",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        id: 3,
        name: "Project Centre",
        key: "label.sidemenu.real_estate_radar.project_centre",
        icon: "atlas-building-4",
        navigateTo: "/dashboard/project-listing",
        type: "menu",
      },
      {
        id: 14,
        name: "Listing Centre",
        key: "label.sidemenu.real_estate_radar.listing_centre",
        icon: "atlas-building-3",
        navigateTo: "/dashboard/personal/subsales",
      },
      {
        id: 17,
        name: "IQI Drive",
        key: "label.sidemenu.real_estate_radar.iqi_drive",
        icon: "atlas-folder",
        navigateTo: "/dashboard/iqi-drive/list",
      },
      {
        id: 30,
        name: "Co-broke Centre",
        key: "label.sidemenu.real_estate_radar.cobroke_centre",
        icon: "atlas-cobroke-centre",
        navigateTo: "/dashboard/personal/cobroke",
      },
      {
        id: 27,
        name: "Valuations",
        key: "label.sidemenu.real_estate_radar.valuations",
        icon: "atlas-valuations",
        navigateTo: "/dashboard/valuations/corporate",
      },
      {
        id: 13,
        label: "SUBSALES",
        key: "label.sidemenu.real_estate_radar.subsales",
      },
      {
        id: 13,
        name: "Appointment Letters",
        key: "label.sidemenu.real_estate_radar.appointment_letters",
        icon: "atlas-appointment-letter",
        navigateTo: "/dashboard/personal/forms/appointment-letter",
      },
      {
        id: 13,
        name: "Confirmation Forms",
        key: "label.sidemenu.real_estate_radar.confirmation_forms",
        icon: "atlas-confirmation-form",
        navigateTo: "/dashboard/personal/forms/confirmation-form",
      },
      {
        id: 13,
        name: "Co-broke Letters",
        key: "label.sidemenu.real_estate_radar.cobroke_letters",
        icon: "atlas-cobroke-letter",
        navigateTo: "/dashboard/personal/forms/cobroke-letter",
      },
      {
        id: 13,
        name: "Authorisation Forms to Secure Property",
        key: "label.sidemenu.real_estate_radar.authorisation_forms_to_secure_property",
        icon: "atlas-authorisation-form",
        navigateTo: "/dashboard/personal/forms/authorisation-form",
      },
      {
        id: 15,
        name: "Subsales Claims",
        key: "label.sidemenu.real_estate_radar.subsales_claims",
        icon: "atlas-subsales-claims",
        navigateTo: "/dashboard/personal/subsales-claims",
      },
    ],
  },
  {
    name: "Engagement Hub",
    key: "label.sidemenu.engagement_hub",
    icon: "atlas-user-square",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        id: [28, 29, 46, 13],
        label: "MARKETING",
        key: "label.sidemenu.engagement_hub.marketing",
      },
      {
        id: 28,
        name: "Presentation Centre",
        key: "label.sidemenu.engagement_hub.presentation_centre",
        icon: "atlas-presentation-chart",
        navigateTo: "/dashboard/presentation-centre",
      },
      {
        id: 29,
        name: "Video Creator",
        key: "label.sidemenu.engagement_hub.video_creator",
        icon: "atlas-video-play",
        navigateTo: "/dashboard/video-creator",
      },
      {
        id: 46,
        name: "Graphic Studio",
        key: "label.sidemenu.engagement_hub.graphic_studio",
        icon: "atlas-gallery-edit",
        navigateTo: "/dashboard/graphic-studio",
      },
      {
        id: 13,
        name: "Requests Form",
        key: "label.sidemenu.engagement_hub.requests_form",
        icon: "atlas-request-form",
        href: `https://agentforms.iqiglobal.com/atlas-agentforms-login?token=${token}`,
      },
      {
        id: [16, 21],
        label: "CRM",
        key: "label.sidemenu.engagement_hub.crm",
      },
      {
        id: 16,
        name: "My Contacts",
        key: "label.sidemenu.engagement_hub.my_contacts",
        icon: "atlas-my-contact",
        navigateTo: "/dashboard/personal/contacts",
      },
      {
        id: 21,
        name: "Leads",
        key: "label.sidemenu.engagement_hub.leads",
        icon: "atlas-personalcard",
        navigateTo: "/dashboard/personal/lead-management",
      },
    ],
  },
  {
    name: "Financial Hub",
    key: "label.sidemenu.financial_hub",
    icon: "atlas-moneys",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        id: 12,
        name: "Payment Vouchers",
        key: "label.sidemenu.financial_hub.payment_vouchers",
        icon: "atlas-receipt-item",
        navigateTo: "/dashboard/personal/payment-vouchers",
      },
    ],
  },
  {
    id: 19,
    name: "IQI Calendar",
    key: "label.sidemenu.iqi_calendar",
    icon: "atlas-calendar",
    navigateTo: "/dashboard/iqi-calendar",
  },
  {
    id: 2,
    name: "Global Network",
    key: "label.sidemenu.global_network",
    icon: "atlas-global",
    navigateTo: "/dashboard/global-network",
  },
  {
    id: 56,
    name: "License Renewal",
    key: "label.sidemenu.license_renewal",
    icon: "atlas-personalcard",
    navigateTo: "/dashboard/personal/rea-renewal",
  },
  {
    name: "Learning Hub",
    key: "label.sidemenu.learning_hub",
    icon: "atlas-book-1",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        id: 26,
        name: "My Trainings",
        key: "label.sidemenu.learning_hub.my_trainings",
        icon: "atlas-book",
        navigateTo: "/dashboard/training-centre/my-training",
      },
      {
        id: 18,
        name: "IQI Academy",
        key: "label.sidemenu.learning_hub.iqi_academy",
        icon: "atlas-teacher",
        navigateTo: "",
        href: `https://academy.iqiglobal.com/atlas-academy-login?token=${token}`,
      },
      {
        id: 20,
        name: "Tutorials",
        key: "label.sidemenu.learning_hub.tutorials",
        icon: "atlas-bookmark-2",
        navigateTo: "/dashboard/tutorials",
      },
    ],
  },
  {
    name: "Store Hub",
    key: "label.sidemenu.store_hub",
    icon: "atlas-shop",
    type: "accordion",
    children: [
      {
        id: 24,
        name: "Atlas Store",
        key: "label.sidemenu.store_hub.atlas_store",
        icon: "atlas-bag",
        navigateTo: "/marketing-centre/products-catalog",
      },
      {
        id: 22,
        name: "IQI Store",
        key: "label.sidemenu.store_hub.iqi_store",
        icon: "atlas-shopping-card",
        navigateTo: "",
        href: "https://store.iqiglobal.com/",
      },
    ],
  },
];
