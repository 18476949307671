import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { FaBuilding } from "react-icons/fa";

import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "../ListingTable";
import EstateDetails from "./Details";

import ProjectHOC from "./actions/estate";
import EstateDetailsHOC from "./actions/details";

class EditListing extends Component {
  componentDidMount = () =>
    this.props.getEstateListing(this.props.selectedPresentation.id, 1, "");

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.estatetListingSearchParams);
    let countryIndex = _.findIndex(temp, { value: "country_id_eq" });
    let stateIndex = _.findIndex(temp, { value: "state_id_eq" });

    if (
      pp.estatetListingSearchParams[countryIndex] &&
      temp[countryIndex] &&
      pp.estatetListingSearchParams[countryIndex].param !==
        temp[countryIndex].param
    ) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: temp[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      this.props.onChangeEstateListingHOC(temp, "estatetListingSearchParams");
    }
  };

  render = () => {
    const {
      showEstateDetailsModal,

      selectedEstateDetails,
      estateListings,
      estateListingPages,
      estateListingNavData = {
        currentPage: 1,
        searchParams: "",
      },
      selectedPresentation,
      onLoadEstate,
      onLoadListing,
      onLoadPresentation,
      onLoadEstateDetails,

      estatetListingSearchParams,
    } = this.props;

    const { id: presentationId } = selectedPresentation;

    return (
      <>
        <div className="position-relative bg-primary badge px-3 py-2 mb-2">
          <span className={"mr-1 fw-600 text-white"}>
            Selected Listing Count
          </span>
          <FaBuilding style={{ width: 16, height: 16, color: "#ffff" }} />
          <span
            className="position-absolute badge badge-danger rounded-circle"
            style={{ top: -10, right: -10, fontSize: 12 }}
          >
            {selectedPresentation.total_listings}
          </span>
        </div>
        <AtPaginatedTable
          rowData={estateListings.data || []}
          actionTypes={["remove", "add"]}
          onClickDetails={(val) =>
            this.props.getSelectedEstate(
              val.id,
              this.props.selectedPresentation.id,
            )
          }
          searchParamsTitle={
            "Search for your preferred listings using the filters below and add the listing into your presentation."
          }
          meta={estateListings.meta && estateListings.meta}
          onClickAdd={(val) => this.props.createListing(presentationId, val.id)}
          onClickRemove={(val) =>
            this.props.removeListing(selectedPresentation.id, val.id, () => {
              this.props.getEstateListing(
                selectedPresentation.id,
                estateListingNavData.currentPage,
                estateListingNavData.searchParams,
              );
            })
          }
          searchParams={estatetListingSearchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeEstateListingHOC(
              val,
              "estatetListingSearchParams",
            )
          }
          getListAPI={(page, search) =>
            this.props.getEstateListing(
              this.props.selectedPresentation.id,
              page,
              search,
            )
          }
          totalPages={estateListingPages}
        />
        {showEstateDetailsModal && (
          <ModalDialog
            title={"Listing Details"}
            onLoad={
              onLoadPresentation ||
              onLoadListing ||
              onLoadEstate ||
              onLoadEstateDetails
            }
            fullWidth={true}
            fullHeight={true}
            children={
              <EstateDetails selectedEstateDetails={selectedEstateDetails} />
            }
            onClose={() =>
              this.props.onChangeEstateDetailsHOC(
                false,
                "showEstateDetailsModal",
              )
            }
          />
        )}
      </>
    );
  };
}

export default compose(EstateDetailsHOC, ProjectHOC)(EditListing);
