import React, { useEffect } from "react";

import CustomButton from "components/Button";

const QRCode = ({
  eventQr,
  selectedEvent,

  getEventQrCode,
}) => {
  useEffect(() => {
    getEventQrCode(selectedEvent.id);
  }, []);

  return (
    <>
      {eventQr && (
        <div className="text-center">
          <img src={eventQr} alt={"event-qr-code"} />
          <CustomButton
            className="btn-new btn-new--secondary m-auto mb-10"
            onClick={() => getEventQrCode(selectedEvent.id)}
            children={"Re-Generate"}
          />
        </div>
      )}
    </>
  );
};

export default QRCode;
