import React, { useState } from "react";
import _ from "lodash";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import StepIndicator from "components/StepIndicator";
import AtlasPrevButton from "components/Button/prev";
import ConfirmationModal from "components/Modal/confirmation";
import ReaRenewalDetails from "./containers/details";
import ReaRenewalDocument from "./containers/document";
import ReaRenewalMessage from "./containers/message";
import ReaRenewalActivity from "./containers/activity";
import { StatusValue } from "./assets";
import { statusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";
import defaultAvatar from "assets/images/av1.png";

const profileDetail = {
  section1: [
    {
      label: "Job Title",
      value: "user_job_title_name",
      icon: "atlas-personalcard",
    },
    { label: "Email", value: "user_email", icon: "atlas-sms" },
  ],
  section2: [
    { label: "Branch", value: "user_branch_name", icon: "atlas-location" },
    { label: "Team", value: "user_team_name", icon: "atlas-profile2user" },
  ],
  section3: [
    { label: "Mobile Number", value: "user_mobile_number", icon: "atlas-call" },
    {
      label: "Via Recruitment Campaign",
      value: "via_recruitment_campaign",
      icon: "atlas-search-status-1",
    },
  ],
};

const renewalDetail = (selectedApplication, selectedRenewal) => [
  {
    label: "Renewal Year",
    value: selectedRenewal.renewal_year,
    icon: "atlas-calendar-2",
  },
  {
    label: "Prior CPD Hours",
    value: selectedRenewal.cpd_minimum_requirement,
    icon: "atlas-clock",
  },
  {
    label: "Status",
    value: selectedApplication.applicant_status_name,
    icon: "atlas-timer",
  },
];

const RenewalDetail = ({
  data,
  message,
  totalCPD,
  activities,
  selectedRenewal,
  uploadedDocuments,
  selectedApplication,
  onLoadRenewalApplication,

  showGenerateFormD,
  showFinanceConfirmation,
  showPaymentConfirmation,
  showApproveConfirmation,
  showArrivedConfirmation,
  showCollectionConfirmation,

  markApplicationSubmitFinance,
  markAsPaymentMade,
  markApplicationApprove,
  markApplicationAsArrived,
  markApplicationTagCollect,

  getMessage,
  putMessage,
  getActivity,
  generateFormD,
  getUploadedDocument,
  createUploadDocument,
  removeUploadedDocument,
  onChangeRenewalDetailsHOC,
}) => {
  const {
    admin_id,
    user_avatar_url,
    user_full_name,
    applicant_status_name,
    application_status_id,
  } = selectedApplication;
  const { id, role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const step = `${application_status_id}`;
  const processPermissions = admin_id === id || role === "Super Admin";

  const [tabContent, setTabContent] = useState("Details");

  const renderStepIcon = (item, current) => {
    const { icon } = _.find(StatusValue, { value: item });

    return (
      <AtlasIcon svgHref={icon} style={current ? { fill: "#3B82F6" } : {}} />
    );
  };

  const renderTabContent = () => {
    switch (tabContent) {
      case "Details":
        return (
          <ReaRenewalDetails
            totalCPD={totalCPD}
            selectedRenewal={selectedRenewal}
            selectedApplication={selectedApplication}
          />
        );
      case "Documents":
        return (
          <ReaRenewalDocument
            selectedRenewal={selectedRenewal}
            uploadedDocuments={uploadedDocuments}
            showGenerateFormD={showGenerateFormD}
            selectedApplication={selectedApplication}
            onLoadRenewalApplication={onLoadRenewalApplication}
            generateFormD={generateFormD}
            getUploadedDocument={getUploadedDocument}
            createUploadDocument={createUploadDocument}
            removeUploadedDocument={removeUploadedDocument}
            onChangeRenewalDetailsHOC={onChangeRenewalDetailsHOC}
          />
        );
      case "Message":
        return (
          <ReaRenewalMessage
            message={message}
            selectedRenewal={selectedRenewal}
            selectedApplication={selectedApplication}
            getMessage={getMessage}
            putMessage={putMessage}
          />
        );
      case "Activity":
        return (
          <ReaRenewalActivity
            activities={activities}
            selectedApplication={selectedApplication}
            getActivity={getActivity}
          />
        );
    }
  };

  const renderCardContent = () => (
    <>
      <div
        className={"at-card--profile_card bg-white m-0"}
        style={{
          borderRadius: 8,
          overflow: "hidden",
          filter:
            "drop-shadow(rgba(0, 0, 0, 0.08) 0px 1px 1px) drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 1px) drop-shadow(rgba(16, 24, 40, 0.1) 0px 1px 3px)",
        }}>
        <div
          className={"at-card--profile_card-img py-3"}
          style={{
            background: "#F18E05",
            zIndex: 99,
          }}>
          <img
            onError={(e) => {
              if (e.target) {
                e.target.src = defaultAvatar;
              }
            }}
            src={
              user_avatar_url && !_.includes(user_avatar_url, "missing.png")
                ? user_avatar_url
                : defaultAvatar
            }
          />
        </div>
        <div className={"at-card--profile_card-top bg-primary"}>
          <h4 className="at-card__title" style={{ color: "white" }}>
            {user_full_name || "N/A"}
          </h4>
          <p>{user_full_name || "display name"}</p>
        </div>
        <div className="d-flex flex-wrap at-card--profile_card-btm">
          {Object.keys(profileDetail).map((key) => (
            <div key={`at-rea-${key}`} style={{ minWidth: 250, width: "33%" }}>
              {profileDetail[key].map((item, index) => (
                <div
                  className="d-flex align-items-center mb-3 grid_gap-2"
                  key={index}>
                  <div
                    className="at-renewal-details__icon-badge"
                    style={{ backgroundColor: "#FFEDD5" }}>
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ fill: "#EA580C" }}
                    />
                  </div>
                  <div>
                    <label
                      style={{
                        color: "#374151",
                        fontSize: 12,
                        fontWeight: 500,
                      }}>
                      {item.label}
                    </label>
                    <p
                      style={{
                        color: "#111827",
                        fontSize: 14,
                        fontWeight: 500,
                      }}>
                      {selectedApplication[item.value] || "N/A"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <hr style={{ margin: 0 }} />
        <div
          className="d-flex flex-wrap at-card--profile_card-btm"
          style={{ backgroundColor: "#F9FAFB" }}>
          {renewalDetail(selectedApplication, selectedRenewal).map(
            (item, index) => (
              <div
                style={{
                  minWidth: 250,
                  width: "33%",
                  minHeight: 70,
                  background: "transparent",
                }}>
                <div
                  className="d-flex align-items-center mb-3 grid_gap-2"
                  key={index}>
                  <div
                    className="at-renewal-details__icon-badge"
                    style={{ backgroundColor: "#DBEAFE" }}>
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ fill: "#EA580C" }}
                    />
                  </div>
                  <div>
                    <label
                      style={{
                        color: "#374151",
                        fontSize: 12,
                        fontWeight: 500,
                      }}>
                      {item.label}
                    </label>
                    <p
                      style={{
                        color: "#111827",
                        fontSize: 14,
                        fontWeight: 500,
                      }}>
                      {selectedApplication[item.value] || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
      <div className="at-form__content mt-3 grid_gap-2">
        <div
          className="d-flex align-items-center grid_gap-2"
          style={{ fontSize: 16, fontWeight: 500 }}
        >
          <div
            className="at-renewal-details__icon-badge"
            style={{ backgroundColor: "#FFEDD5" }}
          >
            <AtlasIcon svgHref={"atlas-timer"} style={{ fill: "#EA580C" }} />
          </div>
          Status
        </div>
        <div className="at-renewal-details__step-indicator">
          <StepIndicator
            mode={"gradual"}
            progress={step}
            stepList={["1", "2", "3", "4", "5", "6", "7"]}
            onClick={() => {}}
            enableProgress={true}
            renderStepLabel={(item) =>
              _.find(StatusValue, { value: item }).status
            }
            renderStepIcon={(item) => {
              if (item === step && item !== "7") {
                return (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      width: 32,
                      height: 32,
                      background: "white",
                      borderRadius: "50%",
                    }}
                  >
                    {renderStepIcon(item, true)}
                  </div>
                );
              } else {
                return renderStepIcon(item);
              }
            }}
          />
        </div>
        {application_status_id !== 7 && (
          <>
            <hr />
            <div
              className="d-flex align-items-center grid_gap-2"
              style={{ fontSize: 16, fontWeight: 500 }}
            >
              <div
                className="at-renewal-details__icon-badge"
                style={{ backgroundColor: "#DBEAFE" }}
              >
                <AtlasIcon
                  svgHref={
                    _.find(StatusValue, { value: `${application_status_id}` })
                      .icon
                  }
                  style={{ fill: "#2563EB" }}
                />
              </div>
              <div>
                <label style={{ color: "#2563EB", fontSize: 12 }}>
                  {applicant_status_name}
                </label>
                <p style={{ color: "#1F2937", fontSize: 14 }}>
                  {
                    _.find(StatusValue, { value: `${application_status_id}` })
                      .description
                  }
                </p>
              </div>
            </div>
            {processPermissions && (
              <>
                {application_status_id === 2 && (
                  <AtlasButton
                    className="btn-new btn-new--success mt-3"
                    style={{ marginLeft: 40 }}
                    children={"Submit To Finance"}
                    onClick={() =>
                      onChangeRenewalDetailsHOC(true, "showFinanceConfirmation")
                    }
                  />
                )}
                {application_status_id === 3 && (
                  <AtlasButton
                    className="btn-new btn-new--success mt-3"
                    style={{ marginLeft: 40 }}
                    children={"Payment Made"}
                    onClick={() =>
                      onChangeRenewalDetailsHOC(true, "showPaymentConfirmation")
                    }
                  />
                )}
                {application_status_id === 4 && (
                  <AtlasButton
                    className="btn-new btn-new--success mt-3"
                    style={{ marginLeft: 40 }}
                    children={"Board Approved"}
                    onClick={() =>
                      onChangeRenewalDetailsHOC(true, "showApproveConfirmation")
                    }
                  />
                )}
                {application_status_id === 5 && (
                  <AtlasButton
                    className="btn-new btn-new--success mt-3"
                    style={{ marginLeft: 40 }}
                    children={"Tag as Arrived"}
                    onClick={() =>
                      onChangeRenewalDetailsHOC(true, "showArrivedConfirmation")
                    }
                  />
                )}
                {application_status_id === 6 && (
                  <AtlasButton
                    className="btn-new btn-new--success mt-3"
                    style={{ marginLeft: 40 }}
                    children={"Tag as Collected"}
                    onClick={() =>
                      onChangeRenewalDetailsHOC(
                        true,
                        "showCollectionConfirmation",
                      )
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <Tab
        sections={["Details", "Documents", "Message", "Activity"]}
        selectedSection={tabContent}
        onSelectSection={(val) => setTabContent(val)}
      />
      {renderTabContent()}
    </>
  );

  return (
    <>
      {renderCardContent()}
      {/* Submit To Finance */}
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showFinanceConfirmation}
        title={"Submitted to Finance"}
        message={`Please click “Confirm” button to submit to finance now.`}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() =>
          markApplicationSubmitFinance(
            selectedRenewal.id,
            selectedApplication.id,
          )
        }
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showFinanceConfirmation")
        }
      />
      {/* Payment Made */}
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showPaymentConfirmation}
        title={"Payment Made"}
        message={`The payment to the Board has been made by the finance department.`}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() =>
          markAsPaymentMade(selectedRenewal.id, selectedApplication.id)
        }
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showPaymentConfirmation")
        }
      />
      {/* Board Approved */}
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showApproveConfirmation}
        title={"Board Approved"}
        message={`Board has approved the tag application.`}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() =>
          markApplicationApprove(selectedRenewal.id, selectedApplication.id)
        }
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showApproveConfirmation")
        }
      />
      {/* Arrived */}
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showArrivedConfirmation}
        title={"Arrived"}
        message={`REA license tag has arrived?`}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() =>
          markApplicationAsArrived(selectedRenewal.id, selectedApplication.id)
        }
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showArrivedConfirmation")
        }
      />
      {/* Collected */}
      <ConfirmationModal
        loading={onLoadRenewalApplication}
        open={showCollectionConfirmation}
        title={"Collected"}
        message={`REA has collected their license tag.`}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() =>
          markApplicationTagCollect(selectedRenewal.id, selectedApplication.id)
        }
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showCollectionConfirmation")
        }
      />
    </>
  );
};

export default RenewalDetail;
