import React, { useState, useRef, useCallback, useEffect } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
import { FormHelperText } from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";

import CutsomTabs from "components/Tab";
import AtlasButton from "components/Button";
import TeaserImage from "components/TeaserImage";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";
import AtlasTable from "components/NewPaginatedTable";
import CustomTable from "components/NewTable";

import AgentSearchHOC from "actions/agentSearch";
import { addAttendeeFieldData } from "../assets";
import DefaultImage from "assets/images/av-male.jpg";
import isEmptyValue from "utils/isEmpty";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";
import AtlasIcon from "components/Icon/atlasIcon";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const columnData = [
  {
    header: "Transfer Date",
    accessor: "created_at",
    searchFlag: "created_at",
    columnStyle: { width: "100%" },
  },
  {
    header: "From",
    searchFlag: "from_who",
    renderColumn: (rowData) => (
      <>
        <p>
          <AtlasIcon svgHref={'atlas-ticket'} style={{width: 16, height: 16, fill: '#F0631D', marginRight: 8}} />
          {rowData?.from_who?.ticket_title}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-user'} style={{width: 16, height: 16, fill: '#3B82F6', marginRight: 8}} />
          {rowData?.from_who?.agent}
        </p>
      </>
    )
  },
  {
    header: "To",
    searchFlag: "to_who",
    renderColumn: (rowData) => (
      <>
        {console.log(rowData)}
        <p>
          <AtlasIcon svgHref={'atlas-ticket'} style={{width: 16, height: 16, fill: '#F0631D', marginRight: 8}} />
          {rowData?.to_who?.ticket_title}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-user'} style={{width: 16, height: 16, fill: '#3B82F6', marginRight: 8}} />
          {rowData?.to_who?.agent}
        </p>
      </>
    )
  },
  {
    header: "By",
    accessor: "by_who",
    searchFlag: "by_who",
  }
];

const AddAttandee = ({
  mode,
  agentList,
  onLoadAgent,
  selectedEvent,
  onLoadAttendee,
  selectedAttendee,
  transferHistories,
  eventTicketPricing,
  onLoadTransferTicket,

  onClose,
  putAttendee,
  updateAttendee,
  getTransferHistories,
  onClickRemove,
  onClickCheckIn,
  onClickCheckout,
  onClickTransfer,
  onClickTransferEvent,
  onClickReject,
  getAgentsBySearch,
  onChangeAgentHOC,
}) => {
  const [formData, setFormData] = useState({
    agent: "",
    payment_method_id: 0,
    is_approved: false,
    status_id: 0,
    ticket_pricing_id: "",
    modifiedImage: false,
  });
  const [bankInSlip, setBankSlip] = useState({});
  const [selectedSection, setSelectedSection] = useState("Details");

  useEffect(() => {
    if (mode === "update") {
      getTransferHistories(selectedAttendee.id);
      if (
        selectedAttendee.proof_of_payment_url &&
        selectedAttendee.payment_method_id === 2
      ) {
        setBankSlip({
          proof_of_payment: selectedAttendee.proof_of_payment_url,
        });
      }
      setFormData(selectedAttendee);
    }
  }, [selectedAttendee]);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const onChangeFieldData = (val, context) => {
    let temp = _.cloneDeep(formData);
    if (context === "ticket_pricing_id") temp[context] = Number(val);
    else temp[context] = val;
    setFormData(temp);
  };

  const onChangeFile = (fileItems) => {
    let temp = {};

    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          temp.proof_of_payment = e.target.result;
          temp.proof_of_payment_file_name = fileItems[0].file.name;
          onChangeFieldData(true, "modifiedImage");
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    }
    return setBankSlip(temp);
  };

  return (
    <>
      {mode === "update" && (
        <CutsomTabs
          sections={["Details", "Transfer Histories"]}
          selectedSection={selectedSection}
          onSelectSection={(val) => {
            if (val === "Transfer Histories") {
              getTransferHistories(selectedAttendee.id);
            }
            setSelectedSection(val);
          }}
        />
      )}
      {selectedSection === "Details" && (
        <>
          <div className={"row"}>
            {addAttendeeFieldData(selectedEvent).map((item, index) => (
              <div key={index} className="col-md-12">
                {item.type === "typeahead" && (
                  <>
                    {mode === "create" && (
                      <>
                        <div className="d-flex">
                          <h2 className="at-form-input__title mr-10">
                            {item.label}
                          </h2>
                          <PulseLoader
                            sizeunit={"px"}
                            size={10}
                            color={"#16232c"}
                            loading={onLoadAgent}
                          />
                        </div>
                        <CustomTypeahead
                          disabled={onLoadAgent}
                          typeaheadId={"attendee-agent"}
                          options={agentList || []}
                          selectedValue={
                            formData[item.value] ? [formData[item.value]] : []
                          }
                          labelKey={"full_name"}
                          onSearch={(val) => {
                            onChangeFieldData(null, item.value);
                            onChangeTypeaheadSearch(val);
                          }}
                          onSelect={(val) => (val && val.length > 0) && onChangeFieldData(val[0], item.value)}
                          filterBy={[
                            "full_name",
                            "email",
                            "mobile_contact_number",
                          ]}
                          helpText={"eg. full name, email, mobile number"}
                          childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                          highlighterData={[
                            (option) => `Team: ${option.team_name}`,
                            <br />,
                            (option) =>
                              `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                          ]}
                        />
                      </>
                    )}
                    {mode === "update" && (
                      <div className="at-attendee__agent-detail">
                        <div className="d-flex w-100 g-3">
                          <img 
                            alt={"attendee-profile-pic"}
                            src={
                              (formData.agent.avatar_url && !_.includes(formData.agent.avatar_url, "missing.png"))
                                ? formData.agent.avatar_url
                                : DefaultImage
                            } />
                          <UserInfo
                            name={formData.agent.full_name}
                            email={formData.agent.email}
                            team_name={formData.agent.team_name}
                            phone_number={formData.agent.mobile_contact_number} />
                        </div>
                        <div className="at-attendee__check-in-out">
                          <div className="d-flex flex-column">
                            Check In Time
                            {selectedAttendee.clock_in_time !== "N/A"
                              ? <span>{ `${selectedAttendee.clock_in_time}`}</span>
                              : <AtlasButton
                                  className="btn-new btn-new--secondary mt-1"
                                  children={"Check In"}
                                  onClick={() => onClickCheckIn(selectedAttendee)} />
                            }
                          </div>
                          <div className="d-flex flex-column">
                            Check Out Time
                            {selectedAttendee.clock_out_time !== "N/A"
                              ? <span>{ `${selectedAttendee.clock_out_time}`}</span>
                              : <AtlasButton
                                  className="btn-new btn-new--secondary mt-1"
                                  children={"Check Out"}
                                  disabled={selectedAttendee.clock_out_time !== "N/A"}
                                  onClick={() => onClickCheckout(selectedAttendee)} />
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {item.type === "radio" && (
                  <>
                    <h2 className="at-form-input__title mr-10">{item.label}</h2>
                    <CustomRadioGroup
                      checkedValue={formData[item.value]}
                      containerClassName={"w-100"}
                      horizontal={true}
                      options={item.options}
                      selectedRadioValue={(val) =>
                        onChangeFieldData(val, item.value)
                      }
                    />
                    {item.value === "payment_method_id" &&
                      formData[item.value] === 2 && (
                        <div className="mt-3">
                          <h2 className="at-form-input__title mr-10">
                            Payment Proof
                          </h2>
                          {bankInSlip.proof_of_payment && (
                            <TeaserImage
                              src={bankInSlip.proof_of_payment}
                              can_update={true}
                              containerStyle={{ marginTop: 20 }}
                              onRemoveImage={() => setBankSlip({})}
                            />
                          )}
                          {!bankInSlip.proof_of_payment && (
                            <div className={"mt-20"}>
                              <FilePond
                                allowMultiple={false}
                                fullWidth
                                maxFiles={1}
                                acceptedFileTypes={["image/*"]}
                                onupdatefiles={(fileItems) =>
                                  onChangeFile(fileItems)
                                }
                              />
                              <FormHelperText>
                                Accepted file types: jpg, jpeg, png.( Required )
                              </FormHelperText>
                            </div>
                          )}
                        </div>
                      )}
                  </>
                )}
                {item.type === "table" && (
                  <div className="mt-3">
                    <h2 className="at-form-input__title mr-10">{item.label}</h2>
                    <AtlasTable
                      columns={item.column({
                        mode,
                        formData,
                        onChangeFieldData,
                      })}
                      searchParams={[]}
                      rowData={eventTicketPricing || []}
                      meta={null}
                      totalPages={[]}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="at-modal_dialog-container-footer flex-wrap g-3">
            <AtlasButton
              className={`btn-new btn-new--${mode === "create" ? "primary" : "success"}`}
              children={<span className="text-capitalize">{mode}</span>}
              onClick={() => {
                if (mode === "create") {
                  putAttendee({
                    event_id: selectedEvent.id,
                    user_id: formData.agent.id,
                    payment_method_id: formData.payment_method_id,
                    ticket_pricing_id: formData.ticket_pricing_id,
                    is_approved: formData.is_approved,
                    status_id: formData.status_id,
                    ...(formData.payment_method_id === 2 &&
                      formData.modifiedImage &&
                      bankInSlip),
                  });
                } else {
                  updateAttendee({
                    user_id: formData.id,
                    payment_method_id: formData.payment_method_id,
                    ticket_pricing_id: formData.ticket_pricing_id,
                    is_approved: formData.is_approved,
                    status_id: formData.status_id,
                    ...(formData.payment_method_id === 2 &&
                      formData.modifiedImage &&
                      bankInSlip),
                  });
                }
              }}
            />
            {mode === "update" && (
              <>
                <AtlasButton
                  className="btn-new btn-new--secondary"
                  disabled={selectedAttendee.clock_in_time !== "N/A" || selectedAttendee.clock_out_time !== "N/A"}
                  children={"Transfer"}
                  onClick={() => onClickTransfer(selectedAttendee)} />
                <AtlasButton
                  className="btn-new btn-new--danger"
                  disabled={selectedAttendee.is_approved || selectedAttendee.payment_method_id === 1
                    || isEmptyValue(selectedAttendee.payment_method_id)}
                  children={"Reject"}
                  onClick={() => onClickReject(selectedAttendee)} />              
                <AtlasButton
                  className="btn-new btn-new--danger"
                  disabled={!(selectedAttendee.payment_method_id === 0 || (selectedAttendee.payment_method_id === 2 && !selectedAttendee.proof_of_payment_url))
                    || selectedAttendee.is_approved}
                  children={"Delete"}
                  onClick={() => onClickRemove(selectedAttendee)} />
              </>
            )}
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              children={
                <>
                  {mode === "create" && "Cancel"}
                  {mode === "update" && "Close"}
                </>
              }
              onClick={() => onClose()}
            />
          </div>
        </>
      )}
      {selectedSection === "Transfer Histories" && (
        <CustomTable
          className={"mb-100"}
          columns={columnData}
          pagination={true}
          actionLabel={"Statement of Approval"}
          rowData={transferHistories || []}
          actionColumnContent={[{
            name: "statement of approval",
            onClick: (rowData) => window.open(rowData.statement_of_approval_url, '_blank'),
          }]}
        />
      )}
      {onLoadAttendee && onLoadTransferTicket && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(AddAttandee);
