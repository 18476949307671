import { useState } from "react";

import Tabs from "components/Tab";
import ModalDialog from "components/Modal/ModalDialog";
import Activity from "./Activity";
import Summary from "./Summary";

const Preview = ({
  onLoadEmailCampaigns,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC
}) => {
  const [activeTab, setActiveTab] = useState("Summary");

  const handleClose = () => {
    setActiveTab("Summary");
    onChangeEmailCampaignsHOC("showPreviewModal", false);
    onChangeEmailCampaignsHOC("selectedCampaignDialog", {});
  };

  return (
    <ModalDialog
      title={"Summary"}
      onLoad={onLoadEmailCampaigns}
      fullWidth={true}
      fullHeight={true}
      onClose={handleClose}
      children={
        <div className={"at-email-campaigns__preview-campaign"}>
          <Tabs
            containerClass={"at-email-campaigns__tabs"}
            sections={["Summary", "Activity"]}
            selectedSection={activeTab}
            onSelectSection={setActiveTab}
          />

          {activeTab === "Summary" && (
            <Summary selectedEmailCampaign={selectedEmailCampaign} />
          )}

          {activeTab === "Activity" && (
            <Activity selectedEmailCampaign={selectedEmailCampaign} />
          )}
        </div>
      } />
  );
};

export default Preview;
