import React, { Component } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";

import { Type } from "../../components/options";

class Create extends Component {
  updateNewPaperForm = (key, val) => {
    let tmp = _.cloneDeep(this.props.newPaperForm);
    tmp[key] = val;
    if (key === "form_type_id") {
      tmp.tracking_number = "";
    }
    return this.props.onChangePaperFormHOC(tmp, "newPaperForm");
  };

  render = () => {
    return (
      <>
        <AtlasCloseButton
          onClick={() =>
            this.props.onChangePaperFormHOC(false, "showCreateDialog")
          }
        />
        <h4 className="at-card__title">{"New Form"}</h4>
        <hr />
        <div className="grid-control">
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Form Number"}</h2>
            <CustomFormInput
              type={"text"}
              disabled={
                this.props.newPaperForm.form_type_id === "2" ||
                this.props.newPaperForm.form_type_id === "3"
              }
              value={this.props.newPaperForm.tracking_number}
              onChangeValue={(val) =>
                this.updateNewPaperForm("tracking_number", val)
              }
            />
            <button
              className="btn-new btn-new--secondary"
              onClick={() => {
                this.props.newPaperForm.form_type_id === "3"
                  ? this.props.generateRenewalFormNumber()
                  : this.props.generateFormNumber();
              }}
            >
              Generate form number
            </button>
          </section>
          <section className="grid-half-col">
            {this.props.newPaperForm.form_type_id === "2" && (
              <>
                <h4 className="at-form-input__title" style={{ fontSize: 16 }}>
                  Choose a Co-Agency
                </h4>
                <CustomTypeahead
                  typeaheadId={"agencies"}
                  options={this.props.agency}
                  selectedValue={
                    this.props.coAgencyId &&
                    _.find(this.props.agency, { id: this.props.coAgencyId })
                      ? [
                          _.find(this.props.agency, {
                            id: this.props.coAgencyId,
                          }),
                        ]
                      : []
                  }
                  labelKey={"label"}
                  filterBy={["label"]}
                  onSelect={(val) => {
                    if (val && val.length > 0) {
                      this.props.onChangePaperFormHOC(val[0].id, "coAgencyId");
                      this.updateNewPaperForm("agency_id", val[0].id);
                    }
                  }}
                  childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                  highlighterData={[
                    (option) =>
                      `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
                    <br />,
                    (option) => option.address,
                  ]}
                />
              </>
            )}
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Form Type"}</h2>
            <AtlasRadioGroup
              horizontal={true}
              containerClassName={"w-100"}
              checkedValue={this.props.newPaperForm.form_type_id}
              selectedRadioValue={(val) =>
                this.updateNewPaperForm("form_type_id", val)
              }
              options={[
                { value: "2", lable: "Co-agency Form" },
                { value: "3", lable: "Renewal Form" },
              ]}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              {"Type"}
            </h2>
            <CustomSelect
              selectItems={Type}
              required={true}
              updateSelect={(val) => this.updateNewPaperForm("type_id", val.id)}
            />
          </section>
          <section className="grid-full-col">
            <CustomButton
              disabled={this.props.disableSubmitButton}
              className={"btn-new btn-new--primary"}
              children={"Create Now"}
              onClick={() => {
                this.props.createPaperForm(this.props.newPaperForm);
                this.props.onChangePaperFormHOC(true, "disableSubmitButton");
              }}
            />
          </section>
        </div>
        {this.props.onLoadPaperForms && <LoadingModal />}
      </>
    );
  };
}

export default Create;
