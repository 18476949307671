import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Post, GetFile } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showEmailDraftModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeDraftHOC = (val, context) => this.setState({ [context]: val });

    downloadDraftEmail = (id, filename, include_inventory) =>
      GetFile(
        `/forms/confirmation_forms/${id}/download_draft${include_inventory ? '?include_signature_in_inventory_checklist=true' : '' }`,
        filename,
        () => {},
        this.downloadDraftEmailError,
        this.load,
      );
    downloadDraftEmailError = (error) => requestError(error);

    sendDraftEmail = (dataToSubmit, form_id, include_inventory) =>
      Post(
        `/forms/confirmation_forms/${form_id}/deliver_draft${include_inventory ? '?include_signature_in_inventory_checklist=true' : '' }`,
        { form_contact_ids: dataToSubmit },
        this.sendDraftEmailSuccess,
        this.sendDraftEmailError,
        this.load,
      );
    sendDraftEmailSuccess = () => {
      requestSuccess(
        "Draft email was sent successfully to all selected recipients.",
      );
      this.setState({
        showEmailDraftModal: false,
      });
    };
    sendDraftEmailError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadDraftEmail={this.state.loading}
            showEmailDraftModal={this.state.showEmailDraftModal}
            onChangeDraftHOC={this.onChangeDraftHOC}
            downloadDraftEmail={this.downloadDraftEmail}
            sendDraftEmail={this.sendDraftEmail}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
