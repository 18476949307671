import React from "react";
import _ from "lodash";

import AtInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";

const SearchContent = ({
  mode,
  searchParams,
  onChangeSearchParams,
  onToggleSearchModal,
  getResultAPI,
}) => {
  const onChangeValue = (value, index) => {
    let tmp = _.cloneDeep(searchParams);
    tmp[index].param = value;
    return onChangeSearchParams(tmp, value);
  };

  const renderInputForms = () => (
    <div className={`row w-100`}>
      {searchParams &&
        searchParams.map((item, index) => {
          return (
            <div
              key={item.value}
              className={`col-md-${item.col ? item.col : 6} mb-0`}
            >
              <label className="at-form-input__title">{item.label}</label>
              <br />
              {item.type === "input" && (
                <AtInput
                  index={index}
                  value={item.param}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      let tmp = "";
                      searchParams.map((item, index) => {
                        tmp =
                          (item.param && item.param) !== ""
                            ? tmp + `${item.value}=${item.param}`
                            : tmp;

                        if (searchParams.length !== index + 1) {
                          tmp = tmp + "&";
                        }
                      });
                      tmp && getResultAPI(tmp);
                    }
                  }}
                  onChangeValue={(val) => onChangeValue(val, index)}
                />
              )}
            </div>
          );
        })}
    </div>
  );

  const renderButtons = () => (
    <div className={`d-flex`}>
      <button
        className="btn-new btn-new--secondary mb-0 mt-auto"
        onClick={() => {
          let tmp = "";
          searchParams.map((item, index) => {
            tmp =
              (item.param && item.param) !== ""
                ? tmp + `${item.value}=${item.param}`
                : tmp;

            if (searchParams.length !== index + 1) {
              tmp = tmp + "&";
            }
          });
          tmp && getResultAPI(tmp);
        }}
      >
        Search
      </button>
    </div>
  );

  const renderMobileView = () => (
    <ModalDialog
      title={"Filter & Search"}
      onClose={() => onToggleSearchModal(false)}
      children={renderInputForms()}
      footer={<div className="at-table__search-action">{renderButtons()}</div>}
    />
  );

  const renderDesktopView = () => (
    <div className="at-form__content grid-control">
      <div className="grid-half-col d-flex g-3">
        {renderInputForms()}
        {renderButtons()}
      </div>
    </div>
  );

  return (
    <>
      {mode === "desktop" && renderDesktopView()}
      {mode === "mobile" && renderMobileView()}
    </>
  );
};

export default SearchContent;
