import React, { useState, useCallback } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { PulseLoader } from "react-spinners";

import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";

import AgentSearchHOC from "actions/agentSearch";

import { Status, Type } from "./options";

const Create = ({
  agentList,
  onLoadPaperForms,
  onLoadAgent,

  onChangeAgentHOC,
  createPaperForm,
  getAgentsBySearch,
  onClose,
}) => {
  const [createForm, onChangeCreateForm] = useState({
    user: null,
    region_id: "",
    tracking_number: "",
    type_id: "",
    status_id: "",
    remark: "",
    date_taken: "",
    form_type_id: "1",
  });

  const onChangeCreateField = (key, val) => {
    let tmp = _.cloneDeep(createForm);
    tmp[key] = val;

    onChangeCreateForm(tmp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <>
      <div className="grid-control" style={{ gridGap: 16 }}>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Form Type"}</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={createForm.form_type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) =>
              onChangeCreateField("form_type_id", val)
            }
            options={[{ value: "1", label: "Physical Form" }]}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Type"}</h2>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            checkedValue={createForm.type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeCreateField("type_id", val)}
            options={Type}
          />
        </section>
        <section className="grid-half-col">
          <div className="d-flex">
            <h2 className="at-form-input__title">Select an Agent</h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={onLoadAgent}
            typeaheadId={"internal_agent"}
            options={agentList ? agentList : []}
            selectedValue={createForm.user ? [createForm.user] : []}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            labelKey={"full_name"}
            onSearch={(val) => {
              onChangeCreateField("user", null);
              onChangeTypeaheadSearch(val);
            }}
            onSelect={(val) =>
              val && val.length > 0 && onChangeCreateField("user", val[0])
            }
            helpText={"e.g. Full Name, Email, Mobile Number"}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Form Number"}</h2>
          <CustomFormInput
            type={"text"}
            disabled={
              createForm.form_type_id === "2" || createForm.form_type_id === "3"
            }
            value={createForm.tracking_number}
            onChangeValue={(val) => onChangeCreateField("tracking_number", val)}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Date Taken"}</h2>
          <div className={`at-form-input`}>
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              placeholderText="e.g. 06-08-2019"
              showYearDropdown={true}
              className="at-form-input__input"
              value={createForm.date_taken || null}
              onChange={(val) =>
                onChangeCreateField(
                  "date_taken",
                  Moment(val).format("DD-MM-YYYY"),
                )
              }
            />
          </div>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Status"}</h2>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            checkedValue={createForm.status_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeCreateField("status_id", val)}
            options={Status}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">{"Remark"}</h2>
          <CustomTextarea
            rows={5}
            value={createForm.remark}
            onChangeValue={(val) => onChangeCreateField("remark", val)}
            placeholder={"Remark"}
          />
        </section>
        <section className="at-modal_dialog-container-footer">
          <button
            type={"button"}
            className={"btn-new btn-new--primary"}
            onClick={() =>
              createPaperForm({
                ...createForm,
                user_id: createForm.user ? createForm.user.id : null,
              })
            }
          >
            Create Now
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </section>
      </div>
      {onLoadPaperForms && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(Create);
