import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";

import { Get, GetFile } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      pvLoading: false,
      logsLoading: false,
      selectedTab: "Payment Vouchers",

      cp58List: [],
      cp58Year: Moment().subtract(1, 'years').format('YYYY'),
      downloadLogs: [],

      showDetailModal: false,
      showDownloadModal: false,
      showDownloadConfirmation: false,
      paymentVoucher: [],
      selectedVoucher: {},
    };

    pvLoad = (param) => this.setState({ pvLoading: param });
    logsLoad = (param) => this.setState({ logsLoading: param });

    onChangePaymentVoucherHOC = (val, context) =>
      this.setState({ [context]: val });

    getDownloadLogs = () =>
      Get(
        `/download_logs?type_id=1`,
        this.getDownloadLogsSuccess,
        this.getDownloadLogsError,
        this.logsLoad,
      );
    getDownloadLogsSuccess = (payload) => {
      let data = _.cloneDeep(payload);
      let tempArray = [];
      for (let i = 0; i < data.length; i++) {
        let temp = {
          ...data[i],
          created_at: Moment(data[i].created_at).format("YYYY-MM-DD"),
          pv_start_date: data[i].query_date_detail.start_date,
          pv_end_date: data[i].query_date_detail.end_date,
        };
        tempArray.push(temp);
      }
      this.setState({ downloadLogs: tempArray });
    };
    getDownloadLogsError = (error) => requestError(error);

    bulkExport = (start_date, end_date) =>
      Get(
        `/connector/payment_vouchers/bulk_export?start_date=${start_date}&end_date=${end_date}`,
        this.bulkExportSuccess,
        this.bulkExportError,
        this.logsLoad,
      );
    bulkExportSuccess = (success) => {
      requestSuccess(success.message);
      this.getDownloadLogs();
      this.setState({ selectedTab: "Download History" });
    };
    bulkExportError = (error) => requestError(error);

    getPaymentVoucher = (id, start_date, end_date) => {
      let endpoint = `/connector/payment_vouchers?identity_id=${id}&start_date=${start_date}&end_date=${end_date}`;

      if (start_date === undefined || end_date === undefined) {
        endpoint = `/connector/payment_vouchers?identity_id=${id}`;
      }

      Get(
        endpoint,
        this.getPaymentVoucherSuccess,
        this.getPaymentVoucherError,
        this.pvLoad,
      );
    };
    getPaymentVoucherSuccess = (payload) =>
      this.setState({ paymentVoucher: payload });
    getPaymentVoucherError = (error) => requestError(error);

    getSelectedVoucher = (encodedID, pvNumber) => {
      Get(
        `/connector/payment_vouchers/${pvNumber}?identity_id=${encodedID}`,
        this.getSelectedVoucherSuccess,
        this.getSelectedVoucherError,
        this.pvLoad,
      );
    };
    getSelectedVoucherSuccess = (payload) =>
      this.setState({
        selectedVoucher: payload,
        showDetailModal: true,
      });
    getSelectedVoucherError = (error) => requestError(error);

    getCP58 = (year) => Get(
      `/profiles/cp58?year=${year}`,
      this.getCP58Success,
      this.getCP58Error,
      this.pvLoad
    );
    getCP58Success = (payload) => {
      if (payload.length > 0) {
        this.setState({
          cp58List: payload,
          showDownloadModal: true,
        });
      } else {
        this.setState({ cp58List: [] });
      }
    };
    getCP58Error = (error) => {
      this.setState({ cp58List: [] });
      requestError(error);
    }

    downloadCP58 = (onSuccess) => {
      const { full_name } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      GetFile(
        `/profiles/cp58?download=1&year=${this.state.cp58Year}`,
        `${full_name.replaceAll(" ", "_")}_cp58.zip`,
        () => this.downloadCP58Success(onSuccess),
        this.downloadCP58Error,
        this.pvLoad
      );
    };
    downloadCP58Success = (onSuccess) => {
      onSuccess();
      requestSuccess("CP58 downloaded successful");
    };
    downloadCP58Error = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadPV={this.state.pvLoading}
          onLoadLogs={this.state.logsLoading}

          requestError={requestError}
          onChangePaymentVoucherHOC={this.onChangePaymentVoucherHOC}
          bulkExport={this.bulkExport}
          getCP58={this.getCP58}
          downloadCP58={this.downloadCP58}
          getSelectedVoucher={this.getSelectedVoucher}
          getPaymentVoucher={this.getPaymentVoucher}
          getDownloadLogs={this.getDownloadLogs}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
