import React, { useState } from "react";
import Moment from "moment";
import _ from "lodash";

import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import FormHeader from "components/Form/header";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";

import { leadsOptions } from "../utils";
import { AgentLeadType } from "dictionary/lead";

const NotificationCheckBoxes = [
  { label: "Email", value: "email" },
  { label: "App notification", value: "app" },
  { label: "System notification", value: "system" },
];

const EditFacebookLeads = ({
  onLoadLeadSetting,
  selectedEditLeadForm,

  onChangeEditLead,
  onChangeLeadSetting,
  updateNotificationSetting,
}) => {
  const [notificationSetting, setNotificationSetting] = useState(
    selectedEditLeadForm.notification_settings,
  );

  const onCheckCheckboxValue = (value) => {
    let temp = _.cloneDeep(notificationSetting);
    temp.notify_via.indexOf(value) > -1
      ? _.remove(temp.notify_via, (o) => o === value)
      : (temp.notify_via = [...temp.notify_via, value]);
    setNotificationSetting(temp);
  };

  return (
    <ModalDialog
      title={"Edit Lead Form"}
      onLoad={onLoadLeadSetting}
      children={
        <>
          <div className="d-flex align-items-center w-100 mb-2">
            <AtlasIcon
              svgHref={"atlas-facebook"}
              className={"at-lead-setting__facebook-icon"}
            />
            <div className="w-100">
              <h2
                className="at-form-input__title w-100"
                style={{ wordBreak: "break-all" }}
              >
                {selectedEditLeadForm.form_name || ""}
              </h2>
              <p style={{ fontSize: 12, fontWeight: 400, color: "#374151" }}>
                Created at:{" "}
                {selectedEditLeadForm.created_at
                  ? Moment(selectedEditLeadForm.created_at).format(
                      "DD MMM YYYY, HH:mm",
                    )
                  : ""}
              </p>
            </div>
          </div>
          <hr />
          {Object.keys(selectedEditLeadForm.column_mapping).map(
            (key, index) => {
              let temp = _.find(selectedEditLeadForm?.questions ?? [], {
                key: key,
              });

              return (
                <div
                  key={`column-mapping-${index}`}
                  className="mb-1 p-1 "
                  style={{ backgroundColor: "#F6F7F8", borderRadius: 8 }}
                >
                  <div
                    key={`column-mapping-${index}`}
                    className="row d-flex align-items-center mx-1"
                  >
                    <div className="col-sm-4 d-flex align-items-center pr-0">
                      <span className="fs-2">{temp?.label ?? key}</span>
                      <AtlasIcon
                        svgHref={"atlas-arrow-right-1-linear"}
                        style={{ width: 16, height: 16, marginLeft: "auto" }}
                      />
                    </div>
                    <div className="col-sm-8 pr-0">
                      <CustomSelect
                        className={"w-100"}
                        selectItems={
                          key === "type_id" ? AgentLeadType : leadsOptions
                        }
                        placeholder={"Select"}
                        updateSelect={(field) =>
                          onChangeEditLead(field.value, key)
                        }
                        currentlySelected={_.find(
                          key === "type_id" ? AgentLeadType : leadsOptions,
                          { value: selectedEditLeadForm.column_mapping[key] },
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            },
          )}
          <div className="at-form__content mt-20">
            <FormHeader
              title={"Notification"}
              moduleIcon={"atlas-notification-bing"}
            />
            <p className="fs-2">
              Atlas will send you notification when there is a new leads come in
              from this form. Choose how you would like to receive those
              notifications.
            </p>
            {NotificationCheckBoxes.map((checkbox, index) => (
              <CustomCheckbox
                key={`checkbox-${index}`}
                labelClassname={"mt-20"}
                content={checkbox.label}
                disabled={false}
                checked={
                  notificationSetting.notify_via.indexOf(checkbox.value) > -1
                }
                onChangeCheckboxValue={(e) =>
                  onCheckCheckboxValue(checkbox.value)
                }
              />
            ))}
          </div>
        </>
      }
      footer={
        <div className="d-flex">
          <CustomButton
            className="btn-new btn-new--primary mr-2"
            children={"Update"}
            onClick={() =>
              updateNotificationSetting({
                entity_id: selectedEditLeadForm.id,
                ...notificationSetting,
              })
            }
          />
          <CustomButton
            className="btn-new btn-new--outline-secondary"
            children={"Cancel"}
            onClick={() => onChangeLeadSetting(false, "showEditLeadForm")}
          />
        </div>
      }
      onClose={() => onChangeLeadSetting(false, "showEditLeadForm")}
    />
  );
};

export default EditFacebookLeads;
