import React, { useEffect } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreatePermittedItem from "./Create";
import UpdatePermittedItem from "./Update";

import permissionsChecker from "utils/permissionsChecker";
import WithPermittedItems from "./actions";

const columnData = [
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Value",
    renderColumn: ({ link, project_name }) => (
      <>
        {link && <p>{link}</p>}
        {project_name && <p>{project_name}</p>}
      </>
    ),
    columnStyle: { width: "300%" },
  },
];

const PermittedItems = (props) => {
  const {
    data,
    onLoadPermittedItem,
    searchParamsPermittedItem,
    permittedItems,
    showCreatePermittedItem,
    showUpdatePermittedItem,
    selectedPermissionGroup,

    getPermittedItems,
    onChangePermittedItemsHOC,
  } = props;
  const { can_update } = permissionsChecker("Permission Groups", data);

  useEffect(() => {
    if (!showCreatePermittedItem) {
      onChangePermittedItemsHOC("newPermittedItem", {
        permission_group_id: selectedPermissionGroup.id,
        type_id: null,
        project_id: "",
        link: "",
      });
    }
  }, [showCreatePermittedItem]);

  useEffect(() => {
    let tmp = _.cloneDeep(searchParamsPermittedItem);
    tmp[1] = {
      ...tmp[1],
      param: "",
    };
    onChangePermittedItemsHOC("searchParamsPermittedItem", tmp);
  }, [searchParamsPermittedItem[0].param]);

  const generateTotalPages = () => {
    let tmp = [];
    if (permittedItems.meta.pages > 0) {
      for (let i = 0; i < permittedItems.meta.pages; i++) {
        tmp.push(i);
      }
    }
    return tmp;
  };

  const renderCheckBoxActions = () => (
    <div className='at-table__head-title'>
      <h4>Permitted Items</h4>
      {can_update && (
        <button
          className={"btn-new btn-new--primary ml-2"}
          onClick={() => {
            onChangePermittedItemsHOC("showCreatePermittedItem", true);
            onChangePermittedItemsHOC("selectedType", 1);
          }}
        >
          <AtlasIcon svgHref={"atlas-add-linear"} />
          New Item
        </button>
      )}
    </div>
  );

  return (
    <>
      <AtPaginatedTable
        columns={columnData}
        rowData={permittedItems.data || []}
        meta={permittedItems.meta}
        renderCheckBoxActions={renderCheckBoxActions}
        actionColumnContent={
          can_update
            ? [
                {
                  name: "edit",
                  onClick: (rowData) => {
                    let tmp = {
                      id: rowData.id,
                      type_id: rowData.type_id,
                      link: rowData.link,
                      project_id: rowData.project_id,
                    };
                    onChangePermittedItemsHOC("selectedType", rowData.type_id);
                    onChangePermittedItemsHOC("selectedPermittedItem", tmp);
                    onChangePermittedItemsHOC("showUpdatePermittedItem", true);
                  },
                },
                {
                  name: "delete",
                  color: "#F04438",
                  onClick: (rowData) => {
                    onChangePermittedItemsHOC("toRemoveID", rowData.id);
                    onChangePermittedItemsHOC("showConfirmDeleteModal", true);
                  },
                },
              ]
            : []
        }
        searchParams={searchParamsPermittedItem}
        onChangeSearchParams={(val) => {
          let tmp = _.cloneDeep(val);
          if (val[0].param) {
            tmp[1] = {
              label:
                tmp[0].param === "1"
                  ? "IQI drive value"
                  : "Project listing value",
              value:
                tmp[0].param === "1"
                  ? "link_cont"
                  : "project_internal_name_or_project_name_cont",
              type: "input",
              param: (tmp[1] && tmp[1].param) || "",
            };
          } else {
            delete tmp[1];
          }
          onChangePermittedItemsHOC("searchParamsPermittedItem", tmp);
        }}
        getListAPI={getPermittedItems}
        totalPages={generateTotalPages()}
      />
      {showCreatePermittedItem && <CreatePermittedItem {...props} />}
      {showUpdatePermittedItem && <UpdatePermittedItem {...props} />}
      {onLoadPermittedItem && <LoadingModal />}
    </>
  );
};

export default WithPermittedItems(PermittedItems);
