import Axios from "axios";
import Cookies from "js-cookie";

import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess,
  beginAjaxCallToken,
  ajaxCallTokenSuccess,
  ajaxCallTokenError,
} from "./ajax";
import { AUTH_USER } from "./type";
import { storeItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";

export function login(credential, language) {
  const { email, password } = credential
  return (dispatch) => {
    dispatch(beginAjaxCall(false));
    Axios.post(`${getDomainURL()}/authenticate?locale=${language}`, {
      email,
      password,
      encode_type: "JWT",
    })
      .then((response) => {
        Cookies.set("IQI_ATLAS_TOKEN_EXPIRY_TIME", response.data.expire_time);
        dispatch(authUserSuccess(response.data));
        storeItem("IQI_ATLAS_JWT_TOKEN", response.data.auth_token);
        storeItem("IQI_ATLAS_REFRESH_TOKEN", response.data.refresh_token);
        dispatch(ajaxCallSuccess("Login Success!"));
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
      });
  };
}

const authUserSuccess = (payload) => ({
  type: AUTH_USER,
  payload,
});

export const refreshToken = () => (dispatch) => {
  dispatch(beginAjaxCallToken());
  Axios.post(`${getDomainURL()}/tokens/refresh`, {
    refresh_token: localStorage.getItem("IQI_ATLAS_REFRESH_TOKEN"),
  })
    .then((response) => {
      Cookies.set("IQI_ATLAS_TOKEN_EXPIRY_TIME", response.data.expire_time);
      Promise.all([
        localStorage.setItem("IQI_ATLAS_JWT_TOKEN", response.data.access_token),
        localStorage.setItem(
          "IQI_ATLAS_REFRESH_TOKEN",
          response.data.refresh_token,
        ),
      ]).then(() => {
        dispatch(ajaxCallTokenSuccess());
        dispatch(authUserSuccess(response.data));
      });
    })
    .catch((error) => {
      dispatch(ajaxCallTokenError());
      let alerted = localStorage.getItem("IQI_ATLAS_OUTDATED_TOKEN") || "";
      if (
        error.response &&
        (error.response.data.message === "Token not found" ||
          error.response.data.message === "Token expired")
      ) {
        Cookies.remove("IQI_ATLAS_TOKEN_EXPIRY_TIME");
        localStorage.removeItem("IQI_ATLAS_JWT_TOKEN");
        localStorage.removeItem("IQI_ATLAS_REFRESH_TOKEN");
        if (alerted !== "yes") {
          alert(
            "Due to inactivity, your session has expired, please login again.",
          );
          localStorage.setItem("IQI_ATLAS_OUTDATED_TOKEN", "yes");
        }
        window.location.reload();
      }
    });
};

export function mobileSSO(mobileToken) {
  return (dispatch) => {
    dispatch(beginAjaxCall(false));
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `JWT ${mobileToken}`,
    };
    Axios.post(`${getDomainURL()}/tokens/single_sign_on`)
      .then((response) => {
        Cookies.set("IQI_ATLAS_TOKEN_EXPIRY_TIME", response.data.expire_time);
        dispatch(authUserSuccess(response.data));
        storeItem("IQI_ATLAS_JWT_TOKEN", response.data.access_token);
        storeItem("IQI_ATLAS_REFRESH_TOKEN", response.data.refresh_token);
        dispatch(ajaxCallSuccess("Login Success!"));
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
      });
  };
}
