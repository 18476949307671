import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";

import CustomCard from "components/Card";
import AtPaginatedTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";
import DetailsDisplay from "./Details";
import EditModal from "./Edit";
import CreateModal from "./New";
import ConfirmationModal from "components/Modal/delete";

import AnnouncementHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { SecConfigData } from "utils/secConfigData";
import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Created By",
    accessor: "creator_name",
  },
  {
    header: "Published",
    accessor: "published",
  },
];

class Announcement extends Component {
  state = {
    SecPermissions: [],
    tableActionPermissions: [],
  };

  componentDidMount = () => {
    initGA("/dashboard/announcement");
    this.secConfigurations();
    this.props.adminGetAnnouncements(1, "");

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role === "Admin" || role === "Super Admin") {
      let temp = _.cloneDeep(this.props.searchParams);
      temp.push({
        label: "Is Published ?",
        value: "published_true",
        type: "radio",
        param: "",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
      });
      this.props.onChangeAnnouncementHOC(temp, "searchParams");
    }
  };

  secConfigurations = () => {
    let roleSecConfig = _.find(SecConfigData, {
      role: window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer.role
        : this.props.data.profileReducer.role,
    });
    let currentModuleSecConfig = _.find(
      roleSecConfig.accessDashboardComponent,
      { name: "Announcement" },
    );
    let tableActionPermissions = [];
    currentModuleSecConfig.access.map((item) => {
      if (item === "view") {
        return tableActionPermissions.push("details");
      } else if (item === "update") {
        return tableActionPermissions.push("edit");
      } else if (item === "delete") {
        return tableActionPermissions.push("delete");
      }
    });
    this.setState({
      SecPermissions: currentModuleSecConfig.access,
      tableActionPermissions: tableActionPermissions,
    });
  };

  renderCardContent = () => {
    return (
      <>
        <AtPaginatedTable
          tableTitle={"Announcement"}
          createTooltipContent={"New announcement"}
          rowData={this.props.adminAnnouncements.data || []}
          meta={this.props.adminAnnouncements.meta}
          showCloseButton={false}
          showCreateButton={this.state.SecPermissions.indexOf("create") > -1}
          onClickCreate={() =>
            this.props.onChangeAnnouncementHOC(true, "showCreateModal")
          }
          columns={columnData}
          actionColumnContent={[
            {
              name: "details",
              onClick: (rowData) =>
                this.props.getSelectedAnnouncement(rowData.id, "details"),
            },
            {
              name: "edit",
              onClick: (rowData) =>
                this.props.getSelectedAnnouncement(rowData.id, "edit"),
            },
            {
              name: "delete",
              color: "#F04438",
              onClick: (rowData) => {
                this.props.onChangeAnnouncementHOC(
                  true,
                  "showDeleteConfirmationModal",
                );
                this.props.onChangeAnnouncementHOC(
                  rowData.id,
                  "selectedDeleteId",
                );
              },
            },
          ]}
          getListAPI={this.props.adminGetAnnouncements}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeAnnouncementHOC(val, "searchParams")
          }
          totalPages={this.props.adminAnnouncementsPage}
        />
        <Dialog
          open={this.props.showDetailsModal}
          fullWidth={true}
          maxWidth={"xl"}
        >
          <DetailsDisplay
            onLoadAnnouncement={this.props.onLoadAnnouncement}
            selectedAnnouncement={this.props.selectedAnnouncement}
            data={this.props.data}
            onChangeAnnouncementHOC={this.props.onChangeAnnouncementHOC}
            updateShowEditModal={this.props.updateShowEditModal}
            deleteAnnouncement={this.props.deleteAnnouncement}
          />
        </Dialog>
        <Dialog
          open={this.props.showEditModal}
          fullWidth={true}
          maxWidth={"xl"}
        >
          <EditModal
            onLoadAnnouncement={this.props.onLoadAnnouncement}
            selectedAnnouncement={this.props.selectedAnnouncement}
            onChangeAnnouncementHOC={this.props.onChangeAnnouncementHOC}
            adminGetAnnouncements={this.props.adminGetAnnouncements}
          />
        </Dialog>
        <Dialog
          maxWidth={"xl"}
          fullWidth={true}
          open={this.props.showCreateModal}
        >
          <CreateModal
            onChangeAnnouncementHOC={this.props.onChangeAnnouncementHOC}
            adminGetAnnouncements={this.props.adminGetAnnouncements}
          />
        </Dialog>
      </>
    );
  };

  render = () => {
    return (
      <>
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadAnnouncement && <LoadingModal />}
        <ConfirmationModal
          open={this.props.showDeleteConfirmationModal}
          title={"Delete Announcement"}
          loading={this.props.onLoadAnnouncement}
          message={"Are you sure to delete this announcement ?"}
          positiveAction={() =>
            this.props.deleteAnnouncement(this.props.selectedDeleteId)
          }
          negativeAction={() =>
            this.props.onChangeAnnouncementHOC(
              false,
              "showDeleteConfirmationModal",
            )
          }
          positiveText={"Yes"}
          negativeText={"No"}
        />
      </>
    );
  };
}

export default compose(AnnouncementHOC, VersionCheckHOC)(Announcement);
