import React, { Component } from "react";
import _ from "lodash";
import { AiFillThunderbolt } from "react-icons/ai";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import RequestTable from "../../components/RequestTable";
import AtlasSimpleTable from "components/NewTable/simple";

import PayoutsHOC from "./actions/payouts";
import { statusColor } from "dictionary/subsales";
import { getColorBadge } from "dictionary/badgeColor";

import "stylesheets/containers/subsales-claims/index.scss";

const getRecipients = (representative_id, sub_sale_type_id, external_agency_id) => {
  let tmp = [];
  if ([1, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_owner", label: sub_sale_type_id === 1 ? "Vendor" : "Landlord" });
  }
  if ([2, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_client", label: sub_sale_type_id === 1 ? "Purchaser" : "Tenant" });
  }

  if (external_agency_id && external_agency_id !== null) {
    tmp.push({ value: "issue_to_agency", label: "Co-Agency" });
  }

  return tmp;
};

const ClaimTypeOptions = [
  { id: 1, name: "Claim Commission", value: 1, label: "Claim Commission" },
  { id: 2, name: "Refund", value: 2, label: "Refund" },
  { id: 3, name: "Forfeit", value: 3, label: "Forfeit" },
  { id: 4, name: "Renewal", value: 4, label: "Renewal" },
  { id: 5, name: "Release", value: 5, label: "Release" },
  { id: 6, name: "Cancel Form", value: 6, label: "Cancel Form" },
  { id: 7, name: "Request Invoice", value: 7, label: "Request Invoice" },
  {
    id: 8,
    name: "Request Official Receipt",
    value: 8,
    label: "Request Official Receipt",
  },
  {
    id: 9,
    name: "Request TA Stamping",
    value: 9,
    label: "Request TA Stamping",
  },
];

const ClaimParty = sub_sale_type_id => [
  {
    id: 1,
    value: 1,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
  },
  {
    id: 2,
    value: 2,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
  },
];

const refundHeaderData = [
  {
    header: "Name",
    accessor: "participant_name",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Amount",
    accessor: "amount",
  },
  {
    header: "Remark",
    accessor: "remark",
  },
];

class Update extends Component {
  state = {
    header: [
      {
        label: "Name",
        value: "document_file_name",
      },
      {
        label: "Issue To",
        value: "issue_to_string",
      },
    ],
    issueToArray: [],
  };

  componentDidMount = () => {
    this.initComponent();
    if (this.props.selectedSubmission.type_id === 1) {
      this.props.getPayouts(this.props.selectedSubmission.id);
    }
  };

  componentDidUpdate = (pp) => {
    if (
      pp.selectedSubmission.type_id !== this.props.selectedSubmission.type_id
    ) {
      this.initComponent();
    }
  };

  initComponent = () => {
    if ([7, 8].indexOf(this.props.selectedSubmission.type_id > -1)) {
      this.setState({
        issueToArray: getRecipients(
          this.props.selected_claim.representative_id,
          this.props.selected_claim.sub_sale_type_id,
          this.props.selected_claim.external_agency_id
        )
      });
    }
  };

  downloadClick = (val) => window.open(val.url, "_blank");

  onChangeSubmission = (val, context) => {
    let tmp = _.cloneDeep(this.props.selectedSubmission);
    tmp[context] = val;
    return this.props.onChangeSubmissionHOC("selectedSubmission", tmp);
  };

  onChangeType = (val) => {
    let tmp = _.cloneDeep(this.props.selectedSubmission);
    tmp.type_id = val;
    tmp.issue_to_owner = false;
    tmp.issue_to_client = false;
    tmp.issue_to_agency = false;

    if (val === 1) {
      this.props.getPayouts(this.props.selectedSubmission.id);
    }
    return this.props.onChangeSubmissionHOC("selectedSubmission", tmp);
  };

  renderDocuments = () => {
    return (
      <>
        <hr />
        <RequestTable
          headerData={this.state.header}
          pagination={true}
          totalPaginationCount={0}
          actionColumn={true}
          actionColumnContent={["view-doc"]}
          viewDocTooltip={"Download"}
          rowData={this.props.documents}
          originalRowData={this.props.documents}
          onClickViewDoc={(val) => this.downloadClick(val)}
          searchWithField={"name"}
          searchPlaceholder={"name"}
          renderExtraContent={() => (
            <div className="at-table_heading">
              <AtlasIcon
                svgHref="atlas-document-text"
                className="at-icon_svg-table_heading-title"
              />
              <h5 className="fw-600">{"Uploaded Documents"}</h5>
            </div>
          )}
          rowKey={"id"}
        />
      </>
    );
  };

  render = () => {
    const { colorName = "" } =
      _.find(statusColor, { value: this.props.selectedSubmission.status }) ||
      {};
    const { refund_details } = this.props.selectedSubmission;

    const { label: typeLabel = "" } =
      _.find(ClaimTypeOptions, {
        value: this.props.selectedSubmission.type_id,
      }) || {};
    const { label: partyLabel = "" } =
      _.find(ClaimParty(this.props.selectedSubmission.sub_sale_type_id), {
        value: this.props.selectedSubmission.claim_party_id,
      }) || {};

    return (
      <>
        <ModalDialog
          title={"Update Submission"}
          onClose={() =>
            this.props.onChangeSubmissionHOC("showUpdateSubmission", false)
          }
          fullWidth={true}
          children={
            <>
              <form
                className="grid-control"
                onSubmit={(e) => e.preventDefault()}
              >
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{"Submission Type"}</h2>
                  <p className="at-radio__content">
                    {this.props.selectedSubmission.is_urgent && (
                      <AiFillThunderbolt
                        style={{
                          width: "1.5rem",
                          height: "fit-content",
                          color: "red",
                        }}
                      />
                    )}
                    {typeLabel || "N/A"}
                  </p>
                </section>
                {this.props.selectedSubmission.type_id &&
                  this.props.selectedSubmission.type_id === 1 && (
                    <section className="grid-full-col">
                      <h2 className="at-form-input__title">{"Claim From"}</h2>
                      <p className="at-radio__content">{partyLabel || "N/A"}</p>
                    </section>
                  )}
                <section className="grid-third-col">
                  <h4 className={"at-form-input__title"}>{"Status"}</h4>
                  <div
                    className="at-status_badge"
                    style={{
                      ...getColorBadge(colorName),
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${this.props.selectedSubmission.status}`}
                  </div>
                </section>
                <section className="grid-third-col">
                  <h4 className={"at-form-input__title"}>{`Assigned Admin`}</h4>
                  <p>
                    {this.props.selectedSubmission.admin &&
                      this.props.selectedSubmission.admin}
                    {!this.props.selectedSubmission.admin &&
                      ([7, 8].includes(this.props.selectedSubmission.type_id) &&
                      this.props.selectedSubmission.status_id === 1 ? (
                        <div
                          className="at-status_badge at-subsales_claims-no-admin-assigned"
                          style={{ fontSize: 12 }}
                        >
                          {this.props.selectedSubmission.type_id === 7
                            ? "Your invoice is in process now"
                            : "Your receipt is in process now"}
                        </div>
                      ) : (
                        "N/A"
                      ))}
                  </p>
                </section>
                {[6, 7, 8, 9].indexOf(this.props.selectedSubmission.type_id) ===
                  -1 && (
                  <section className="grid-third-col">
                    <h4
                      className={"at-form-input__title"}
                    >{`Reviewing Admin`}</h4>
                    <p>{this.props.selectedSubmission.senior_admin}</p>
                  </section>
                )}
                {[7, 8].indexOf(this.props.selectedSubmission.type_id) !==
                  -1 && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">Issue To</h2>
                    {this.state.issueToArray.map((item) => (
                      <CustomCheckbox
                        disabled={true}
                        checked={this.props.selectedSubmission[item.value]}
                        onChangeCheckboxValue={(event) =>
                          this.onChangeSubmission(
                            event.target.checked,
                            item.value,
                          )
                        }
                        content={item.label}
                      />
                    ))}
                  </section>
                )}
                {!_.isEmpty(refund_details) && (
                  <section className="grid-full-col">
                    <h4
                      className={"at-form-input__title"}
                    >{`Refund Details`}</h4>
                    <AtlasSimpleTable
                      className={"mb-100"}
                      columns={refundHeaderData}
                      rowData={refund_details}
                      hideSearch={true}
                      pagination={true}
                      emptyStateMessage="You don't have any participants."
                    />
                  </section>
                )}
                {_.isEmpty(refund_details) && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Remark"}</h2>
                    <AtlasTextarea
                      rows={5}
                      value={this.props.selectedSubmission.description}
                      onChangeValue={(val) =>
                        this.onChangeSubmission(val, "description")
                      }
                      disabled={true}
                    />
                  </section>
                )}
              </form>
              {[7, 8, 9].indexOf(this.props.selectedSubmission.type_id) !==
                -1 && this.renderDocuments()}
            </>
          }
        />
        {(this.props.onLoadSubmissions || this.props.onLoadPayouts) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default PayoutsHOC(Update);
