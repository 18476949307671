import React, { useState } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { FaRegCalendarAlt, FaInfoCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye, AiFillPhone } from "react-icons/ai";
import { BiGroup } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomButton from "components/Button";
import ListingContent from "./listing";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Expired", colorName: "Red" },
  { value: "Inactive", colorName: "Red" },
  { value: "Published", colorName: "Green" },
];
const badgeClass = (status) => {
  const { colorName = "" } = _.find(StatusColor, { value: status }) || {};
  return getColorBadge(colorName);
};

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

const TableContent = ({ rowItem, can_update, onClickStats, onClickEdit }) => {
  const [currentTab, onSwitchTab] = useState("Properties");
  return (
    <div className="at-presentation-card">
      <div className="at-presentation-card__header-cont">
        <div className="at-presentation-card__title-cont">
          <div style={{ width: "85%", display: "flex" }}>
            <span
              style={{
                zIndex: 1,
                height: "1.5rem",
                ...badgeClass(rowItem.status),
              }}
              className={"at-status_badge"}
            >
              {rowItem.status}
            </span>
            <span className="at-presentation-card__header-transaction_number">
              {rowItem.transaction_number}
            </span>
          </div>
          <div className="at-presentation-card__header-action">
            {can_update && (
              <DarkTooltip
                classes={{ tooltip: "tooltip-arrow top" }}
                aria-label={"action-button"}
                placement={"top"}
                title={"Edit"}
              >
                <FiEdit onClick={() => onClickEdit(rowItem)} />
              </DarkTooltip>
            )}
            {!can_update && (
              <DarkTooltip
                classes={{ tooltip: "tooltip-arrow top" }}
                aria-label={"action-button"}
                placement={"top"}
                title={"View"}
              >
                <FaInfoCircle onClick={() => onClickEdit(rowItem)} />
              </DarkTooltip>
            )}
          </div>
        </div>
        <h4 className="fs-3 fw-600 text-white mb-2">{rowItem.title}</h4>
        <div className="at-presentation-card__header-desc">
          <div>
            <div className={"at-presentation-info"}>
              <DarkTooltip
                classes={{ tooltip: "tooltip-arrow top" }}
                aria-label={"action-button"}
                placement={"top"}
                title={"Expiry Date"}
              >
                <FaRegCalendarAlt />
              </DarkTooltip>
              <span>{rowItem.expiry_date}</span>
            </div>
            <div className={"at-presentation-info"}>
              <DarkTooltip
                classes={{ tooltip: "tooltip-arrow top" }}
                aria-label={"action-button"}
                placement={"top"}
                title={"View Counts"}
              >
                <AiOutlineEye />
              </DarkTooltip>
              <span>{rowItem.viewed_count}</span>
            </div>
          </div>
          <CustomButton
            className={"btn-new btn-new--primary"}
            children={
              <>
                <AtlasIcon
                  style={{
                    width: 16,
                    height: 16,
                    fill: "white",
                  }}
                  svgHref={"atlas-chart-1"}
                />
                Statistics
              </>
            }
            tooltip={false}
            tooltipChildren={<span>Detail</span>}
            tooltipPosition={"bottom"}
            tooltipID={`statistic-detail`}
            onClick={() => onClickStats(rowItem)}
          />
        </div>
      </div>
      {window.location.href.indexOf("/admin/") > -1 && (
        <div className={"at-presentation-card__agent-cont"}>
          <div className="at-presentation-card__agent-info-cont">
            <div className="at-presentation-card__name">
              <span>{rowItem.user_info.full_name || "N/A"}</span>
            </div>
            <div className="at-presentation-card__info">
              <BiGroup />
              <span>{rowItem.user_info.team_name || "N/A"}</span>
            </div>
            <div className="at-presentation-card__info">
              <AiFillPhone />
              <span>{rowItem.user_info.mobile_contact_number || "N/A"}</span>
            </div>
            <div className="at-presentation-card__info">
              <MdEmail />
              <span>{rowItem.user_info.email || "N/A"}</span>
            </div>
          </div>
        </div>
      )}
      <div className="at-presentation-card__content">
        <div className="at-presentation-card__content-nav">
          <button
            data-tab={currentTab === "Properties" ? "Properties" : ""}
            className={`
              at-presentation-card__content-tabs 
              ${currentTab === "Properties" ? "at-presentation-card__content-active-tabs" : ""}`}
            onClick={() => onSwitchTab("Properties")}
          >
            Properties
          </button>
          <button
            data-tab={currentTab === "Clients" ? "Clients" : ""}
            className={`
              at-presentation-card__content-tabs 
              ${currentTab === "Clients" ? "at-presentation-card__content-active-tabs" : ""}`}
            onClick={() => onSwitchTab("Clients")}
          >
            Clients
          </button>
        </div>
        <ListingContent
          context={currentTab}
          rowData={
            currentTab === "Properties" ? rowItem.estates : rowItem.clients
          }
        />
      </div>
    </div>
  );
};

export default TableContent;
