import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { Post, Put, Get, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { getAllDictionaryData } from "actions/dictionary";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateModal: false,
      showUpdateModal: false,
      showDeleteConfirmation: false,

      branches: [],
      meetingRoom: [],
      meetingRoomMeta: {},
      meetingRoomPage: "",

      searchParams: [
        {
          label: "Meeting Room Name",
          value: `name_cont`,
          type: "input",
          param: "",
        },
        {
          label: "Branch",
          value: "branch_id_eq",
          type: "select",
          param: "",
          options: [],
        },
        {
          label: "Capacity",
          value: `capacity_eq`,
          type: "input",
          param: "",
        },
        {
          label: "Availability",
          value: "status_id_eq",
          type: "radio",
          param: "",
          options: [
            { label: "Available", value: 1 },
            { label: "Hidden", value: 2 },
          ],
        },
      ],
      selectedMeetingRoom: {},
    };

    load = (param) => this.setState({ loading: param });
    onChangeMeetingHubHOC = (val, context) => this.setState({ [context]: val });

    getBranches = () =>
      Get(
        `/branches?[q][branch_selections_type_id_eq]=4`,
        this.getBranchesSuccess,
        this.getBranchesError,
        this.load,
      );
    getBranchesSuccess = (payload) =>
      this.setState({
        branches: payload?.map((branch) => ({
          ...branch,
          label: branch.name,
          value: branch.id,
        })),
      });
    getBranchesError = (error) => requestError(error);

    getMeetingRoom = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/meeting_rooms?${search}page=${page}`,
        this.getMeetingRoomSuccess,
        this.getMeetingRoomError,
        this.load,
      );
    }
    getMeetingRoomSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        meetingRoom: payload.data,
        meetingRoomMeta: payload.meta,
        meetingRoomPage: tempPages,
      });
    };
    getMeetingRoomError = (error) => requestError(error);

    getSelectedMeetingRoom = (id) =>
      Get(
        `/admin/meeting_rooms/${id}`,
        this.getSelectedMeetingRoomSuccess,
        this.getSelectedMeetingRoomError,
        this.load,
      );
    getSelectedMeetingRoomSuccess = (payload) =>
      this.setState({
        selectedMeetingRoom: payload?.meeting_room,
        showUpdateModal: true,
      });
    getSelectedMeetingRoomError = (error) => requestError(error);

    createMeetingRoom = (dataToSubmit) =>
      Post(
        "/admin/meeting_rooms",
        {
          meeting_room: {
            ...dataToSubmit,
            operating_start_time: Moment(
              dataToSubmit.operating_start_time,
            ).format("hh:mmA"),
            operating_end_time: Moment(dataToSubmit.operating_end_time).format(
              "hh:mmA",
            ),
          },
        },
        this.createMeetingRoomSuccess,
        this.createMeetingRoomError,
        this.load,
      );
    createMeetingRoomSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.setState({ showCreateModal: false });
      requestSuccess("Meeting Room has been created successfully");
      this.getMeetingRoom(currentPage, searchParams);
    };
    createMeetingRoomError = (error) => requestError(error);

    updateMeetingRoom = (dataToSubmit) =>
      Put(
        `/admin/meeting_rooms/${dataToSubmit.id}`,
        {
          meeting_room: {
            ...dataToSubmit,
            operating_start_time: Moment(
              dataToSubmit.operating_start_time,
            ).format("hh:mmA"),
            operating_end_time: Moment(dataToSubmit.operating_end_time).format(
              "hh:mmA",
            ),
          },
        },
        this.updateMeetingRoomSuccess,
        this.updateMeetingRoomError,
        this.load,
      );
    updateMeetingRoomSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Meeting Room has been updated successfully.");
      this.getMeetingRoom(currentPage, searchParams);
      this.getSelectedMeetingRoom(payload.id);
    };
    updateMeetingRoomError = (error) => requestError(error);

    deleteMeetingRoom = (id) =>
      Delete(
        `/admin/meeting_rooms/${id}`,
        this.deleteMeetingRoomSuccess,
        this.deleteMeetingRoomError,
        this.load,
      );
    deleteMeetingRoomSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.setState({ showDeleteConfirmation: false });
      requestSuccess("Meeting Room has been deleted successfully.");
      this.getMeetingRoom(currentPage, searchParams);
    };
    deleteMeetingRoomError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadMeetingHub={this.state.loading}
            getBranches={this.getBranches}
            getMeetingRoom={this.getMeetingRoom}
            createMeetingRoom={this.createMeetingRoom}
            updateMeetingRoom={this.updateMeetingRoom}
            deleteMeetingRoom={this.deleteMeetingRoom}
            getSelectedMeetingRoom={this.getSelectedMeetingRoom}
            onChangeMeetingHubHOC={this.onChangeMeetingHubHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getAllDictionaryData
  })(WithHOC);
};

export default HOC;
