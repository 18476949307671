import React, { useState, useEffect } from "react";

import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";

export default function EmailAppointment({
  open,
  onLoadAppointmentLetter,
  onCloseModal,
  onClickSubmit,
  selectedUserId,
}) {
  useEffect(() => {
    !open && onChangeRecipients("");
  }, [open]);

  const [recipients, onChangeRecipients] = useState("");

  return (
    <>
      {open && (
        <ModalDialog
          title={"Send Email"}
          onClose={() => onCloseModal()}
          children={
            <>
              <h2 className="at-form-input__title">Email Recipients</h2>
              <CustomFormInput
                type={"text"}
                value={recipients}
                onChangeValue={(val) => onChangeRecipients(val)}
              />
              <AtlasButton
                disabled={!recipients}
                className="btn-new btn-new--success mt-20"
                children={"Send"}
                onClick={() =>
                  onClickSubmit({
                    recipients: recipients,
                    id: selectedUserId,
                  })
                }
              />
              {onLoadAppointmentLetter && <LoadingModal />}
            </>
          }
        />
      )}
    </>
  );
}
