import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { Get, Post, Put, GetFile, Delete } from "utils/axios";
import isEmptyValue from "utils/isEmpty";
import { requestError, requestSuccess } from "utils/requestHandler";

const attendeeSearchParams = [
  {
    label: "User Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
    options: [],
  },
  {
    label: "Mobile Contact Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "select",
    param: "",
    options: [
      { id: 0, label: "Pending", value: "0" },
      { id: 1, label: "Absent", value: "1" },
      { id: 2, label: "Present", value: "2" },
      { id: 3, label: "Resit", value: "3" },
    ],
  },
  {
    label: "Is Approved",
    value: "is_approved_true",
    type: "radio",
    param: "",
    options: [
      { id: 0, label: "Yes", value: "true" },
      { id: 1, label: "No", value: "false" },
    ],
  },
  {
    label: "Payment Method",
    value: "payment_method_id_eq",
    type: "radio",
    param: "",
    options: [
      { id: 0, label: "All", value: "" },
      { id: 1, label: "None", value: "0" },
      { id: 2, label: "Payment Gateway", value: "1" },
      { id: 3, label: "Bank In", value: "2" },
      { id: 4, label: "Cash", value: "3" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      updateLoading: false,
      summaryLoading: false,
      searchParams: attendeeSearchParams,
      selectedAttendee: {
        agent: {},
      },
      paymentProof: {
        proof_of_payment: "",
        proof_of_payment_file_name: "",
      },

      attendeeList: [],
      eventTicketPricing: [],

      attendeePages: null,
      uploadedAttendeeListFile: null,
      attendeePaymentDetail: {},
      showAddAttendeeDialog: false,
      showEditAttendeeDialog: false,
      showRemoveConfirmation: false,
      showAttendeePaymentDialog: false,
      showApproveConfirmation: false,
      showPaymentProofPreview: false,
      showCheckInConfirmation: false,
      showCheckOutConfirmation: false,
      showUploadAttendeeDialog: false,
      showRejectModal: false,

      attendeeSummary: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeAttendeeHOC = (val, context) => this.setState({ [context]: val });

    getAttendeeList = (event_id, page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/events/${event_id}/attendee_list?${search}page=${page}&pagination=true`,
        (payload) => this.getAttendeeListSuccess(event_id, payload),
        this.getAttendeeListError,
        this.load,
      );
    };
    getAttendeeListSuccess = (event_id, payload) => {
      let tmp = [];
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          if (item.user_id) {
            let data = {
              ...item,
              id: item.id,
              event_id: event_id,
              user_name: item.agent.full_name,
              email: item.agent.email,
              mobile_contact_number: item.agent.mobile_contact_number,
              team: item.agent.team,
              user_id: item.user_id,
              join_duration_minute: item.join_duration_minute
                ? `${item.join_duration_minute} mins`
                : "-",
              clock_in_time: !isEmptyValue(item.clock_in_time)
                ? Moment(item.clock_in_time, "DD-MM-YYYY HH:mm").format(
                    "DD MMM YYYY HH:mm",
                  )
                : "N/A",
              clock_out_time: !isEmptyValue(item.clock_out_time)
                ? Moment(item.clock_out_time, "DD-MM-YYYY HH:mm").format(
                    "DD MMM YYYY HH:mm",
                  )
                : "N/A",
              approval_status: item.is_approved
                ? "Approved"
                  : (item.reject_reason
                    ? "Rejected"
                    : "Pending"),
            };
            tmp.push(data);
          }
        });
      this.setState({
        attendeeList: {
          data: tmp,
          meta: payload.meta,
        },
        attendeePages: tmpTotalPages,
      });
    };
    getAttendeeListError = (error) => requestError(error);

    getSelectedAttendee = (id) =>
      Get(
        `/admin/user_events/${id}`,
        this.getSelectedAttendeeSuccess,
        this.getSelectedAttendeeError,
        this.load,
      );
    getSelectedAttendeeSuccess = (payload) =>
      this.setState({
        selectedAttendee: {
          ...payload,
          clock_in_time: !isEmptyValue(payload.clock_in_time)
            ? Moment(payload.clock_in_time, "DD-MM-YYYY HH:mm").format(
                "DD MMM YYYY HH:mm",
              )
            : "N/A",
          clock_out_time: !isEmptyValue(payload.clock_out_time)
            ? Moment(payload.clock_out_time, "DD-MM-YYYY HH:mm").format(
                "DD MMM YYYY HH:mm",
              )
            : "N/A",
        },
      });
    getSelectedAttendeeError = (error) => requestError(error);

    // clock in at detail modal
    checkIn = (id, referrer_code) =>
      Put(
        `/admin/user_events/${id}/check_in`,
        { referrer_code: referrer_code },
        (payload) => this.checkInSuccess(payload),
        this.checkInError,
        this.load,
      );
    checkInSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(
        this.state.selectedAttendee.event_id,
        currentPage,
        searchParams,
      );
      this.getSelectedAttendee(payload.id);
      requestSuccess("Check in success!");
    };
    checkInError = (error) => requestError(error);

    // clock out at detail modal
    checkOut = (id, referrer_code) =>
      Put(
        `/admin/user_events/${id}/check_out`,
        { referrer_code: referrer_code },
        (payload) => this.checkOutSuccess(payload),
        this.checkOutError,
        this.load,
      );
    checkOutSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(
        this.state.selectedAttendee.event_id,
        currentPage,
        searchParams,
      );
      this.getSelectedAttendee(payload.id);
      requestSuccess("Check out success!");
    };
    checkOutError = (error) => requestError(error);

    exportAttendee = (dataToSubmit) =>
      GetFile(
        `/admin/events/${dataToSubmit.id}/attendee_list/download`,
        `${dataToSubmit.name}_Event_Attendee_Export.xls`,
        () => {},
        this.exportAttendeeError,
        this.load,
      );
    exportAttendeeError = (error) => requestError(error);

    uploadAttendeeList = (id) => {
      let tmpFile = {
        import_data: this.state.uploadedAttendeeListFile.source,
        import_file_name: this.state.uploadedAttendeeListFile.name,
      };
      Post(
        `/admin/events/${id}/attendee_list/upload`,
        tmpFile,
        (payload) => this.uploadAttendeeListSuccess(id, payload),
        this.uploadAttendeeListError,
        this.load,
      );
    };
    uploadAttendeeListSuccess = (id, payload) => {
      this.getAttendeeList(id, 1, "");
      requestSuccess(payload.message);
      this.setState({ showUploadAttendeeDialog: false });
    };
    uploadAttendeeListError = (error) => requestError(error);

    getAttendeeSummary = (id) =>
      Get(
        `/admin/events/${id}/total_attendee`,
        this.getAttendeeSummarySuccess,
        this.getAttendeeSummaryError,
        (val) => this.setState({ summaryLoading: val }),
      );
    getAttendeeSummarySuccess = (payload) =>
      this.setState({ attendeeSummary: payload });
    getAttendeeSummaryError = (error) => requestError(error);

    getEventTicketPricing = (id) =>
      Get(
        `/admin/events/${id}/ticket_pricings`,
        this.getEventTicketPricingSuccess,
        this.getEventTicketPricingError,
        this.load,
      );
    getEventTicketPricingSuccess = (payload) =>
      this.setState({ eventTicketPricing: payload });
    getEventTicketPricingError = (error) => requestError(error);

    regradeAttendance = (event_id) =>
      Put(
        `/admin/events/${event_id}/zoom_sessions/regrade_attendance`,
        {},
        (payload) => this.regradeAttendanceSuccess(event_id, payload),
        this.regradeAttendanceError,
        this.load,
      );
    regradeAttendanceSuccess = (event_id, payload) => {
      this.getAttendeeSummary(event_id);
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(event_id, currentPage, searchParams);
      requestSuccess(payload.message);
    };
    regradeAttendanceError = (error) => requestError(error);

    uploadPaymentProof = (attendee_id, event_id) =>
      Post(
        `/admin/user_events/${attendee_id}/upload_payment_proof`,
        this.state.paymentProof,
        () => this.uploadPaymentProofSuccess(event_id),
        this.uploadPaymentProofError,
        this.load,
      );
    uploadPaymentProofSuccess = (event_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(event_id, currentPage, searchParams);

      this.setState({
        showPaymentProofPreview: false,
        paymentProof: {
          proof_of_payment: "",
          proof_of_payment_file_name: "",
        },
      });
      requestSuccess("Proof of payment uploaded.");
    };
    uploadPaymentProofError = (error) => requestError(error);

    removeAttendee = (attendee_id, event_id) =>
      Delete(
        `/admin/user_events/${attendee_id}`,
        () => this.removeAttendeeSuccess(event_id),
        this.removeAttendeeError,
        this.load,
      );
    removeAttendeeSuccess = (event_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(event_id, currentPage, searchParams);

      requestSuccess("Attendee succesfully removed.");
    };
    removeAttendeeError = (error) => requestError(error);

    getAttendeePayment = (id) =>
      Get(
        `/admin/user_events/${id}/payments`,
        this.getAttendeePaymentSuccess,
        this.getAttendeePaymentError,
        this.load,
      );
    getAttendeePaymentSuccess = (payload) => {
      let tmpAttendeePaymentDetail = [];
      payload.map((item) =>
        item.payments.map((paymentItem) => {
          tmpAttendeePaymentDetail.push(paymentItem);
        }),
      );
      this.setState({
        attendeePaymentDetail: tmpAttendeePaymentDetail,
        showAttendeePaymentDialog: true,
      });
    };
    getAttendeePaymentError = (error) => requestError(error);

    getTransferHistories = (attendee_id) =>
      Get(
        `/admin/user_events/${attendee_id}/histories`,
        this.getTransferHistoriesSuccess,
        this.getTransferHistoriesError,
        this.load,
      );
    getTransferHistoriesSuccess = (payload) => {
      this.setState({
        transferHistories: payload.map((item) => ({
          ...item,
          created_at: !_.isEmpty(item.created_at)
            ? Moment(item.created_at, "YYYY-MM-DDTHH:mm").format(
                "DD MMM YYYY HH:mm",
              )
            : "",
        })),
      });
    };
    getTransferHistoriesError = (error) => requestError(error);

    rejectRegistration = (attendee_id, reason, event_id) =>
      Put(
        `/admin/user_events/${attendee_id}/reject`,
        { reason: reason },
        () => this.rejectRegistrationSuccess(event_id),
        this.rejectRegistrationError,
        this.load,
      );
    rejectRegistrationSuccess = (event_id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("The registration is rejected successfully.");
      this.setState({ showRejectModal: false });

      this.getAttendeeList(event_id, currentPage, searchParams);
      this.getTransferHistories(this.state.selectedAttendee.id);
    };
    rejectRegistrationError = (error) => requestError(error);

    putAttendee = (dataToSubmit) =>
      Post(
        `/admin/user_events`,
        dataToSubmit,
        this.putAttendeeSuccess,
        this.putAttendeeError,
        this.load,
      );
    putAttendeeSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(payload.event_id, currentPage, searchParams);
      this.setState({ showAddAttendeeDialog: false });
      requestSuccess("Attendee has been added");
    };
    putAttendeeError = (error) => requestError(error);

    updateAttendee = (dataToSubmit) =>
      Put(
        `/admin/user_events/${dataToSubmit.user_id}`,
        dataToSubmit,
        this.updateAttendeeSuccess,
        this.updateAttendeeError,
        this.load,
      );
    updateAttendeeSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAttendeeList(payload.event_id, currentPage, searchParams);
      this.setState({ showEditAttendeeDialog: false });
      requestSuccess("Attendee Updated");
    };
    updateAttendeeError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadAttendee={this.state.loading}
            checkIn={this.checkIn}
            checkOut={this.checkOut}
            putAttendee={this.putAttendee}
            updateAttendee={this.updateAttendee}
            regradeAttendance={this.regradeAttendance}
            uploadPaymentProof={this.uploadPaymentProof}
            getAttendeeSummary={this.getAttendeeSummary}
            getSelectedAttendee={this.getSelectedAttendee}
            getTransferHistories={this.getTransferHistories}
            getEventTicketPricing={this.getEventTicketPricing}
            exportAttendee={this.exportAttendee}
            removeAttendee={this.removeAttendee}
            rejectRegistration={this.rejectRegistration}
            getAttendeeList={this.getAttendeeList}
            getAttendeePayment={this.getAttendeePayment}
            uploadAttendeeList={this.uploadAttendeeList}
            onChangeAttendeeHOC={this.onChangeAttendeeHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
