import React, { useEffect, useState, useCallback, useRef } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomPagination from "components/Pagination";
import CustomTableContent from "./content";
import CustomTableHeader from "./header";

import "stylesheets/components/table/index.scss";

const CustomTable = ({
  emptyStateMessage,
  emptyStateIcon,
  mode,
  className,
  actionLabel,
  emptyMessage,
  emptyDescription,
  rowData: initialRowData,
  columns,
  selectPerPageOption,
  actionColumnContent,
  hidePagination,
  pagination,
  hideSearch,
  emptyStateDescription,
  renderTabFilter,
  additionalFilter,
  renderCheckBoxActions,
  renderAdditionalContent,
  renderExtraContent,
  actionAfterChangedPage,
  actionColumStyle,
  resetRef = true // Set to false when table has input fields that allow changes to the rowData so searchKeyword should not be reset
}) => {
  const initialRowDataRef = useRef(initialRowData);
  const [maxActionLength, onSetMaxActionLength] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchKeyword, setKeyWord] = useState("");
  const [clickPageNumber, setPageNum] = useState(1);
  const [totalPages, setTotalPage] = useState([1]);
  const [currentPageOption, onChangePerPage] = useState(10);
  const [searchWithField, onChangeSeachWithField] = useState("");
  const [searchWithFieldDisplay, onChangeSeachWithFieldDisplay] = useState("");
  const [headerRearrangeData, setHeaderRearrangeData] = useState({});
  const [currentPageData, setCurrentPageData] = useState([]);

  useEffect(() => {
    selectPerPageOption && onChangePerPage(selectPerPageOption);
  }, []);

  useEffect(() => {
    if (resetRef) {
      initialRowDataRef.current = initialRowData;
    }

    if (initialRowDataRef.current === initialRowData) {
      setRowData(initialRowData);
      processPagesData(initialRowData, selectPerPageOption || 10);

      let tmpMaxLength = 0;
      initialRowData.forEach(row => {
        let tmpLength = actionColumnContent?.filter(action => (!action.onShow || (action.onShow && action.onShow(row))))?.length;
        if (tmpMaxLength < tmpLength) {
          tmpMaxLength = tmpLength;
        }
      });
      onSetMaxActionLength(tmpMaxLength);

      if (clickPageNumber === 1) {
        setPageNum(1);
      }
    } else {
      debounceService(searchKeyword);
    }
  }, [initialRowData, resetRef]);

  useEffect(() => {
    if (initialRowDataRef.current === initialRowData) {
      for (let index = 0; index < columns.length; index++) {
        if (!columns[index].hideFromSearch) {
          onChangeSeachWithField(columns[index].searchFlag);
          onChangeSeachWithFieldDisplay(columns[index].header);

          break;
        }
      }
    }
  }, [initialRowData]);

  useEffect(() => {
    if (!_.isEmpty(rowData)) {
      let tmp = rowData.filter((item, index) => {
        if (
          index + 1 > (clickPageNumber - 1) * currentPageOption &&
          index + 1 <= clickPageNumber * currentPageOption
        ) {
          return item;
        }
      });

      setCurrentPageData(tmp);
      actionAfterChangedPage &&
        actionAfterChangedPage({
          rowData,
          currentPageData: tmp,
          clickPageNumber: clickPageNumber,
          currentPageOption,
        });
      } else {
        setCurrentPageData([]);
      }
  }, [clickPageNumber, currentPageOption, rowData]);

  const processPagesData = (rowData, pageOption) => {
    if (rowData.length > 0) {
      let paginationSections = rowData.length / pageOption;

      let pagesArrayData = [];
      if (paginationSections > Math.floor(paginationSections)) {
        paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      }

      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }

      setTotalPage(pagesArrayData);
    }
  };

  const debounceService = (searchVal) => {
    let result = _.filter(initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });

    Promise.all([setRowData(result)]).then(() => {
      processPagesData(result, currentPageOption);
    });
  };

  const onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    setKeyWord(searchVal);
    setPageNum(1);

    if (searchVal === "") {
      setRowData(initialRowData);
      processPagesData(initialRowData, currentPageOption);
    } else {
      debounceService(searchVal);
    }
  };

  const onClickRearrangeData = useCallback(
    (val) => {
      Promise.all([setHeaderRearrangeData(val)]).then(() => {
        if (val.columnValue) {
          let tempOrder = _.orderBy(
            rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (columnData !== "Infinity" && !isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(
                    "columnData.match(/^(d{2})-(d{2})-(d{4})$/)",
                    columnData,
                  );
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          );

          setRowData(tempOrder);
        }
      });
    },
    [rowData],
  );

  const renderTableContent = () => {
    if (currentPageData && currentPageData.length > 0) {
      return currentPageData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          columns={columns}
          maxActionLength={maxActionLength}
          index={index}
          actionLabel={actionLabel}
          headerRearrangeData={headerRearrangeData}
          actionColumnContent={actionColumnContent}
          actionColumStyle={actionColumStyle}
          rowData={rowItem}
        />
      ));
    } else if (!searchKeyword) {
      return (
        <EmptyState
          title={emptyStateMessage || `You don't have any items`}
          description={emptyStateDescription || ""}
          renderIcon={
            emptyStateIcon || <AtlasIcon svgHref="atlas-document-text" />
          }
        />
        // <>
        //   <CustomTableHeader
        //     mode={mode}
        //     actionLabel={actionLabel}
        //     onClickRearrangeData={onClickRearrangeData}
        //     columns={columns}
        //     rowData={rowData}
        //     actionColumnContent={actionColumnContent}
        //     currentPageData={currentPageData}
        //   />
        //   <div className="at-table-row-cont">
        //     <EmptyState
        //       title={emptyStateTitle || `You don't have any items`}
        //       renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        //     />
        //   </div>
        // </>
      );
    } else {
      return (
        <div className="at-table-row-cont">
          <EmptyState
            title={emptyMessage || `You don't have any items`}
            description={emptyDescription || ""}
            renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
          />
        </div>
      );
    }
  };

  const renderPaginationText = () => {
    const processFloorPaginationCount = () => {
      let pageNumTmp = clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * currentPageOption + 1;
      }
      return pageNumTmp;
    };

    const processCeilingPaginationCount = () => {
      let pageNumTmp = currentPageOption;
      if (clickPageNumber > 1) {
        pageNumTmp = clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > rowData.length) {
        pageNumTmp = rowData.length;
      }
      return pageNumTmp;
    };

    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span>
          {" "}
          {pagination ? processFloorPaginationCount(clickPageNumber) : ""}{" "}
        </span>
        -
        <span>
          {" "}
          {pagination
            ? processCeilingPaginationCount(currentPageOption)
            : ""}{" "}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  return (
    <div className={`d-flex flex-column ${className}`}>
      {renderAdditionalContent && renderAdditionalContent}
      {renderExtraContent && renderExtraContent()}
      <div className="at-table at-table--set">
        {renderTabFilter && (
          <div
            className="at-table-head"
            style={{ display: "flex", padding: 0 }}
          >
            {renderTabFilter()}
          </div>
        )}
        {renderCheckBoxActions && (
          <div className="at-table-head" style={{ display: "flex" }}>
            {renderCheckBoxActions()}
          </div>
        )}
        {!hideSearch && (
          <div className="at-table-head" style={{ display: "flex" }}>
            <div className="grid-control w-100">
              <div className="grid-half-col">
                <div className="at-select__search-cont">
                  <CustomSelect
                    className="at-select_new-interface"
                    selectItems={
                      _.filter(columns, (item) => !item.hideFromSearch) || []
                    }
                    labelKey={"header"}
                    valueKey={"searchFlag"}
                    currentlySelected={{ searchFlag: searchWithField }}
                    updateSelect={(val) => {
                      onChangeSeachWithField(val.searchFlag);
                      onChangeSeachWithFieldDisplay(val.header);
                    }}
                  />
                  <input
                    type="search"
                    key={"searchInput"}
                    value={searchKeyword}
                    className={"at-table__search-input"}
                    placeholder={`Search with ${searchWithFieldDisplay}`}
                    onChange={(event) =>
                      onChangeSearchValue(event.target.value)
                    }
                  />
                </div>
              </div>
              {additionalFilter && (
                <div className="grid-half-col">{additionalFilter}</div>
              )}
            </div>
          </div>
        )}
        {(rowData && rowData.length > 0) && (
          <CustomTableHeader
            mode={mode}
            maxActionLength={maxActionLength}
            actionLabel={actionLabel}
            onClickRearrangeData={onClickRearrangeData}
            columns={columns}
            rowData={rowData}
            actionColumnContent={actionColumnContent}
            currentPageData={currentPageData}
            actionColumStyle={actionColumStyle}
          />
        )}
        {renderTableContent()}
      </div>
      {!hidePagination && rowData.length > 0 && (
        <div className="at-pagination__cont">
          <CustomPagination
            totalPages={totalPages}
            chosenPageNumber={currentPageOption}
            onClickPageNumber={(val) => setPageNum(val)}
            currentPageNumber={clickPageNumber}
          />
          {renderPaginationText()}
        </div>
      )}
    </div>
  );
};

export default CustomTable;