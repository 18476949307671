import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";

const dataArray = [
  {
    label: "Built Up",
    upper_value: "max_built_up",
    lower_value: "min_built_up",
    icon: "miximize-3-linear",
  },
  { label: "Land Area", value: "land_area", icon: "format-square" },
  {
    label: "Bathroom",
    upper_value: "max_bathroom",
    lower_value: "min_bathroom",
    icon: "bathroom",
  },
  {
    label: "Bedroom",
    upper_value: "max_bedroom",
    lower_value: "min_bedroom",
    icon: "bedroom",
  },
  { label: "Max Unit", value: "max_unit", icon: "chart" },
  { label: "Latitude", value: "latitude", icon: "global-linear" },
  { label: "Longitude", value: "longitude", icon: "global-linear" },
];

const Specification = ({ selectedProject }) => {
  return (
    <div className="at-project-listing__project-specifications">
      <div className="at-project__detail-header">
        <AtlasIcon svgHref="atlas-house-2" />
        <h1 style={{ fontSize: 18, fontWeight: 600 }}>Specification</h1>
      </div>
      {dataArray.map((item, index) => (
        <div key={index} className={`at-project__details-item`}>
          <AtlasIcon svgHref={`atlas-${item.icon}`} />
          <label>{item.label}</label>
          {item.value && (
            <span>
              {selectedProject[item.value]
                ? selectedProject[item.value]
                : `N/A`}
            </span>
          )}
          {item.upper_value && item.lower_value && (
            <span>
              {selectedProject[item.lower_value]
                ? selectedProject[item.lower_value]
                : `N/A`}
              {" - "}
              {selectedProject[item.upper_value]
                ? selectedProject[item.upper_value]
                : `N/A`}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Specification;
