import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put, Post, Delete } from "utils/axios";
import { refreshToken } from "actions/login";

const SettingsHOC = (WrappedComponent) => {
  class SettingsWrappedComponent extends Component {
    state = {
      current_setting: {
        notify_by_system_notification: false,
        notify_by_email: false,
        notify_of_new_announcement: false,
        confirmation_forms_maximum: 5,
        confirmation_form_balance: 3,
        iproperty_profile_url: "",
      },
      loading: false,

      showCreateApplicationConfirmation: false,
      showDeleteApplicationConfirmation: false,

      tabContent: "Notifications",
      partnerAccounts: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeSettingsHOC = (val, context) => this.setState({ [context]: val });

    handleChange = (context, val) => {
      let tmp = this.state.current_setting;
      tmp[context] = val;
      return this.setState({ current_setting: tmp });
    };

    submitUpdate = () => {
      let data = { ...this.state.current_setting };
      return this.updateSetting(data);
    };

    getSetting = () => {
      if (window.location.hash.indexOf("/admin/") > -1) {
        let { id } = this.props.data.adminUserReducer.selectedUser;
        Get(
          `/admin/users/${id}/setting`,
          this.getAdminSettingSuccess,
          this.getAdminSettingError,
          this.load,
        );
      } else {
        Get(
          `/setting`,
          this.getSettingSuccess,
          this.getSettingError,
          this.load,
        );
      }
    };
    getSettingSuccess = (payload) =>
      this.setState({ current_setting: { ...payload } });
    getSettingError = () => requestError("Failed to obtain setting.");

    getAdminSettingSuccess = (payload) =>
      this.setState({ current_setting: { ...payload } });
    getAdminSettingError = () => requestError("Failed to obtain setting.");

    updateSetting = (data) => {
      if (window.location.hash.indexOf("/admin/") > -1) {
        let { id } = this.props.data.adminUserReducer.selectedUser;
        Put(
          `/admin/users/${id}/setting`,
          data,
          this.updateSettingSuccess,
          this.updateSettingError,
          this.load,
        );
      } else {
        Put(
          `/setting`,
          data,
          this.updateSettingSuccess,
          this.updateSettingError,
          this.load,
        );
      }
    };
    updateSettingSuccess = () =>
      requestSuccess("Setting updated successfully.");
    updateSettingError = () =>
      requestError("Failed to update setting. Please try again later.");

    getPartnerAcc = () =>
      Get(
        `/partner_accounts`,
        this.getPartnerAccSuccess,
        this.getPartnerAccError,
        this.load,
      );
    getPartnerAccSuccess = (payload) =>
      this.setState({ partnerAccounts: payload });
    getPartnerAccError = (error) => requestError(error);

    savePartnerAcc = (dataToSubmit) =>
      Post(
        `/partner_accounts`,
        dataToSubmit,
        this.savePartnerAccSuccess,
        this.savePartnerAccError,
        this.load,
      );
    savePartnerAccSuccess = () => {
      this.getPartnerAcc();
      requestSuccess("New record was added successfully.");
      this.setState({ showCreateApplicationConfirmation: false });
    };
    savePartnerAccError = (error) => requestError(error);

    deletePartnerAcc = (id) =>
      Delete(
        `/partner_accounts/${id}`,
        this.deletePartnerAccSuccess,
        this.deletePartnerAccError,
        this.load,
      );
    deletePartnerAccSuccess = () => {
      this.getPartnerAcc();
      this.setState({ showDeleteApplicationConfirmation: false });
      requestSuccess("The record has been deleted successfully.");
    };
    deletePartnerAccError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            partnerAccounts={this.state.partnerAccounts}
            onLoadSetting={this.state.loading}
            tabContent={this.state.tabContent}
            showCreateApplicationConfirmation={
              this.state.showCreateApplicationConfirmation
            }
            showDeleteApplicationConfirmation={
              this.state.showDeleteApplicationConfirmation
            }
            current_setting={this.state.current_setting}
            onChangeSettingsHOC={this.onChangeSettingsHOC}
            handleChange={this.handleChange}
            submitUpdate={this.submitUpdate}
            getSetting={this.getSetting}
            deletePartnerAcc={this.deletePartnerAcc}
            getPartnerAcc={this.getPartnerAcc}
            savePartnerAcc={this.savePartnerAcc}
            updateSetting={this.updateSetting}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(SettingsWrappedComponent);
};

export default SettingsHOC;
