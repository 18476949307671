import React from "react";
import _ from "lodash";

const AdditionalSearch = ({
  searchParams,
  getListAPI,
  onChangeSearchParams,
}) => {
  const onToggleSearch = (index, value) => {
    let tmp = "";
    let search = _.cloneDeep(searchParams);
    search[index].param = value;
    search.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}`
          : tmp;
    });
    getListAPI(1, tmp);
    return onChangeSearchParams(search);
  };

  return (
    <>
      {searchParams &&
        searchParams.map((item, index) => {
          if (item.additional) {
            return (
              <div className="d-flex">
                {item.options.map((option) => (
                  <button
                    className={`at-table-search__additional-btn ${item.param === option.value && "at-table-search__additional--selected"}`}
                    onClick={() => onToggleSearch(index, option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            );
          }
        })}
    </>
  );
};

export default AdditionalSearch;
