import { useRef, useState } from "react";
import { Avatar } from "@material-ui/core";

import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from 'components/Modal/ModalDialog';
import AgentDetailsSettings from './AgentDetailsSettings';
import AgentVideo from './AgentVideo';
import LogoSettings from './LogoSettings'
import PropertyDetailsSettings from './PropertyDetailsSettings'
import RemotionVideoPlayer from './RemotionVideoPlayer'

const EditVideoSettings = ({
  onLoadVideoCreator,
  selectedVideoTemplate,
  selectedProject,
  agent,
  onChangeVideoCreatorHOC,
  setShowConfirmationModal
}) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const playerRef = useRef(null);

  const handleClose = () => {
    onChangeVideoCreatorHOC('showEditVideoSettings', false)
  }

  const onChangeVideoConfiguration = (key, val) => {
    onChangeVideoCreatorHOC('selectedVideoTemplate', {
      ...selectedVideoTemplate,
      configuration: {
        ...selectedVideoTemplate.configuration,
        [key]: val
      }
    });

    playerRef.current?.pause();
    playerRef.current?.seekTo(0);
  };
  
  const onBulkChangeVideoConfiguration = (obj) => {
    onChangeVideoCreatorHOC('selectedVideoTemplate', {
      ...selectedVideoTemplate,
      configuration: {
        ...selectedVideoTemplate.configuration,
        ...obj
      }
    });

    playerRef.current?.pause();
    playerRef.current?.seekTo(0);
  };

  const renderCardContent = () => (
    <>
      <div className='d-flex bg-white pb-3 p-lg-3 at-video-creator__video-settings-header'>
        <Avatar
          style={{
            marginRight: 16,
            backgroundColor: "#FFEDD5",
            width: 32,
            height: 32,
            minWidth: 32,
            minHeight: 32
          }}
        >
          <AtlasIcon
            svgHref='atlas-building'
            style={{ width: 16, height: 16, fill: "#F0631D" }}
          />
        </Avatar>
        <div className="flex-fill">
          <h4 className='project-detail__title mb-1 text-capitalize' style={{ color: '#111827' }}>
            {selectedVideoTemplate.title}
          </h4>
          <p className='mb-0' style={{ color: '#111827', fontWeight: 400 }}>
            {selectedVideoTemplate.description || 'N/A'}
          </p>
        </div>
      </div>
      <div className="form-row pb-lg-3 pl-lg-3 pr-lg-3">
        <div className="col-lg-4 mb-3 mb-lg-0">
          <LogoSettings
            configuration={selectedVideoTemplate.configuration}
            onChangeVideoConfiguration={onChangeVideoConfiguration}
          />
          {selectedVideoTemplate.type_id === 1 && (
            <PropertyDetailsSettings
              configuration={selectedVideoTemplate.configuration}
              isPortrait={isPortrait}
              onChangeVideoConfiguration={onChangeVideoConfiguration}
              onBulkChangeVideoConfiguration={onBulkChangeVideoConfiguration}
            />
          )}
          <AgentDetailsSettings
            configuration={selectedVideoTemplate.configuration}
            isPortrait={isPortrait}
            onChangeVideoConfiguration={onChangeVideoConfiguration}
            onBulkChangeVideoConfiguration={onBulkChangeVideoConfiguration}
          />
        </div>
        <div className="col-lg-8 mb-0">
          <div className="at-video-creator__video-container">
            <RemotionVideoPlayer
              id='AgentVideo'
              ref={playerRef}
              className='at-video-creator__video-player'
              component={() => (
                <AgentVideo
                  selectedVideoTemplate={selectedVideoTemplate}
                  property={selectedProject}
                  agent={agent}
                  isPortrait={isPortrait}
                />
              )}
              url={selectedVideoTemplate.url}
              setIsPortrait={setIsPortrait}
            />
          </div>
        </div>
      </div>
    </>
  );

  const renderFooter = () => (
    <div className='d-flex'>
      <AtlasButton
        className='btn-new btn-new--primary mr-10'
        onClick={() => setShowConfirmationModal(true)}
      >
        Create
      </AtlasButton>
      <AtlasButton
        className='btn-new btn-new--outline-secondary'
        onClick={handleClose}
      >
        Cancel
      </AtlasButton>
    </div>
  )

  return (
    <ModalDialog
      title='Edit Video Settings'
      fullWidth
      fullHeight
      responsiveSize=''
      contentClassName='at-video-creator__dialog-content p-lg-0'
      onLoad={onLoadVideoCreator}
      onClose={handleClose}
      footer={renderFooter()}
    >
      {renderCardContent()}
    </ModalDialog>
  );
};

export default EditVideoSettings;
