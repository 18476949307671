import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import ReactModal from "react-modal";

import CustomButton from "components/Button";
import SVGIcon from "components/Icon";

import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";

const customStyles = {
  overlay: {
    zIndex: 1301, // because material ui dialog is having 1300 as z index
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    paddingRight: "40px",
  },
};

ReactModal.setAppElement("#root");

class StatusModal extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate = (pp) => {
    if (this.props.isOpen !== pp.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  };

  onClickCloseModal() {
    this.props.onClickClose();
    this.setState({ isOpen: false });
  }

  renderLottieIcon(type) {
    if (type === "positive") {
      return (
        <IoMdCheckmarkCircle
          style={{ width: 50, marginRight: 15, color: "green" }}
        />
      );
    }
    if (type === "warning-custom") {
      return (
        <IoMdCloseCircle style={{ width: 50, marginRight: 15, color: "red" }} />
      );
    }
  }

  renderModalContent() {
    const {
      message,
      onClick,
      type,
      buttonStyle,
      onClickCancel,
      positiveText,
      negativeText,
    } = this.props;
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.renderLottieIcon(type)}
          {ReactHtmlParser(message)}
        </div>
        {type !== "warning" && (
          <CustomButton
            containerStyle={{ float: "right" }}
            style={{ ...buttonStyle }}
            className={"btn-new btn-new--success"}
            children={positiveText || "OK"}
            onClick={onClick}
          />
        )}
        {type === "warning" && (
          <div style={{ float: "right" }}>
            <CustomButton
              containerStyle={{ display: "inline-block" }}
              className={"btn-new btn-new--success mr-10"}
              children={positiveText || "OK"}
              onClick={onClick}
            />
            <CustomButton
              containerStyle={{ display: "inline-block" }}
              className={"btn-new btn-new--outline-secondary"}
              style={{ ...buttonStyle }}
              children={negativeText || "Cancel"}
              onClick={onClickCancel}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <ReactModal
        isOpen={this.state.isOpen}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <div className="at-dropbear-panel">
          <div
            className="at-dropbear-panel__header"
            style={{ padding: 0, paddingTop: 10 }}
          >
            <CustomButton
              className={`at-dropbear-panel__header-close`}
              children={
                <SVGIcon className="svg-icon-cross" svgHref={"icon-cross"} />
              }
              tooltip={false}
              onClick={() => this.onClickCloseModal()}
            />
          </div>
          {this.renderModalContent()}
        </div>
      </ReactModal>
    );
  }
}

export default StatusModal;
