import React, { Component } from "react";
import _ from "lodash";

import { Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      searchedAgents: null,
      searchValue: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeAgentHOC = (val, context) => this.setState({ [context]: val });

    getInternalAgent = (dataToSubmit) =>
      Post(
        `/agents/search`,
        dataToSubmit,
        this.getInternalAgentSuccess,
        this.getInternalAgentError,
        this.load,
      );
    getInternalAgentSuccess = (payload) =>
      this.setState({
        searchedAgents: {
          user_id: payload.id,
          agent_name: payload.full_name,
          agent_email: payload.email,
          agent_mobile_number: payload.mobile_contact_number,
          teamName: payload.team_name,
          avatar_url: payload.avatar_url,
          percentage: 0,
        },
      });
    getInternalAgentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadAgent={this.state.loading}
            searchValue={this.state.searchValue}
            searchedAgents={this.state.searchedAgents}
            onChangeAgentHOC={this.onChangeAgentHOC}
            getInternalAgent={this.getInternalAgent}
            requestSuccess={requestSuccess}
            requestError={requestError}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
