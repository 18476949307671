import React, { useState } from "react";
import LoadingModal from "components/LoadingModal";
import { MenuItem } from "@material-ui/core";
import _ from "lodash";

import AtSelectNew from "components/Select/new";

const ReassignAgentModal = ({
  admins,
  onLoadReassign,
  onClickReassign,
  closeModal,
}) => {
  const [selectedAdmin, onChangeAdmin] = useState(null);

  return (
    <>
      <p className="fs-2">{"Please select an admin to reassign."}</p>
      <h2 className="at-form-input__title mt-3">Admin</h2>
      <AtSelectNew
        value={selectedAdmin}
        onChange={(e) => onChangeAdmin(e.target.value)}
        style={{ display: "flex", flexDirection: "column", marginBottom: 200 }}
      >
        {admins &&
          admins.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              classes={{ root: `at-select__dropdown-item` }}
            >
              {item.name}
            </MenuItem>
          ))}
      </AtSelectNew>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          disabled={!selectedAdmin}
          className="btn-new btn-new--primary"
          onClick={() => onClickReassign(selectedAdmin)}
        >
          Reassign
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
      {onLoadReassign && <LoadingModal />}
    </>
  );
};

export default ReassignAgentModal;
