import React, { Component } from "react";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import ActivityContent from "./Activity";
import MessageContent from "./Message";
import SalesForm from "../saleForms";
import RequestContent from "./Request";

class DetailContent extends Component {
  state = {
    tabsContent: "Details",
  };

  renderTabContent = () => {
    const { selectedSale } = this.props;

    return (
      <>
        {this.state.tabsContent === "Details" && (
          <SalesForm
            {...this.props}
            selectedFormData={selectedSale}
            onClose={() =>
              this.props.onChangeSalesHOC(false, "showDetailDialog")
            }
          />
        )}
        {this.state.tabsContent === "Requests" && (
          <RequestContent {...this.props} />
        )}
        {this.state.tabsContent === "Messages" && (
          <MessageContent {...this.props} />
        )}
        {this.state.tabsContent === "Activity" && (
          <ActivityContent {...this.props} />
        )}
      </>
    );
  };

  render = () => {
    const { project_name = "N/A", unit_number = "N/A" } =
      this.props.selectedSale;

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton
            containerClass={"btn-close-project-sale"}
            onClick={() =>
              this.props.onChangeSalesHOC(false, "showDetailDialog")
            }
          />
          <h4 className="at-card__title">{`Project Sale - ${project_name || "N/A"} - ${unit_number || "N/A"}`}</h4>
        </div>
        <Tab
          sections={["Details", "Requests", "Messages", "Activity"]}
          selectedSection={this.state.tabsContent}
          onSelectSection={(val) => this.setState({ tabsContent: val })}
        />
        <CustomCard cardContent={this.renderTabContent()} />
      </>
    );
  };
}

export default DetailContent;
