import React, { useState } from "react";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";

import AtlasIcon from "components/Icon/atlasIcon";

import defaultAvatar from "assets/images/av-male.jpg";
import leaderMedal1 from "assets/images/medal_1.png";
import leaderMedal2 from "assets/images/medal_2.png";
import leaderMedal3 from "assets/images/medal_3.png";
import leaderBoardLeft from "assets/images/project_leaderboard_left.png";
import leaderBoardRight from "assets/images/project_leaderboard_right.png";
import leaderBoardCenter from "assets/images/project_leaderboard_center.png";
import { numberWithCommas } from "utils/thousandSeparator";
import LoadingModal from "components/LoadingModal";

const chartText = {
  id: "text-center",
  afterDatasetsDraw(chart, args, options) {
    const { ctx, data, height, width } = chart;
    const isZero =
      data.datasets[0].data[1] === 0 || data.datasets[0].data[1] === "NaN";

    ctx.save();
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "bold 12px sans-serif";
    ctx.fillStyle = "#1F2937";
    if (isZero) {
      ctx.fillText(`0%`, width / 2, height / 2);
    } else {
      const dataValue = data.datasets[0].data[1];
      ctx.fillText(`${dataValue}%`, width / 2, height / 2);
    }
  },
};

const emptyDoughnut = {
  id: "emptyDoughnut",
  afterDraw(chart, args, options) {
    const { color, width, radiusDecrease } = options;
    const isZero = _.values(chart.data.datasets[0].data).every(
      (item) => item === 0 || item === "NaN",
    );

    if (isZero) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
};

const salesTypeOptions = [
  { value: "unit", label: "Unit" },
  { value: "amount", label: "Amount" },
];

const TopThreeAgents = [
  { id: 1, background: leaderBoardLeft, medal: leaderMedal2 },
  { id: 0, background: leaderBoardCenter, medal: leaderMedal1 },
  { id: 2, background: leaderBoardRight, medal: leaderMedal3 },
];

const ProjectSalesContent = ({ fromTeam, selectedAgent, leaderBoardData, country_name }) => {
  const {
    id,
    projectBookedUnits,
    projectBookedUnitsAmount,
    projectCancelledUnits,
    projectcancelledUnitsAmount,
    projectConvertedUnits,
    projectConvertedUnitsAmount,
  } = selectedAgent;

  const projectUnit = leaderBoardData.find(data => data.some(item => item.Type === "Project"));
  const [salesType, setSalesType] = useState("unit");

  const renderCancelUnit = () => (
    <>
      <div
        className={`at-card--stat__general-icon-cont`}
        style={{ backgroundColor: "#DB2777" }}
      >
        <AtlasIcon svgHref={`atlas-forbidden-2`} />
      </div>
      <div className="at-card--stat__general-category">
        <p>
          {salesType === "unit" ? "Cancelled Unit" : "Cancelled Unit Amount"}
        </p>
        <span>
          {numberWithCommas(
            salesType === "unit"
              ? projectCancelledUnits
              : projectcancelledUnitsAmount,
          )}
        </span>
      </div>
    </>
  );

  return (
    <>
      <div
        className="at-statistic-project-container"
        style={{ display: fromTeam ? "block" : "" }}
      >
        {!fromTeam && (
          <div
            className="at-form__content py-0 px-2"
            style={{
              gridArea: "1 / 1 / 4 / 2",
              background: "linear-gradient(180deg, #2563EB 0%, #1E3A8A 100%)",
              height: "fit-content",
            }}
          >
            <label
              className="at-statistic_leaderboard-title"
              style={{
                background:
                  "linear-gradient( 270deg, #103DAB00 0%, #103DAB 50%, #103DAB00 100% )",
              }}
            >
              PROJECT
            </label>
            <p className="w-100 text-center text-white fs-3 fw-600 mt-3">
              Top Agents
            </p>
            <div className="d-flex justify-content-center my-3">
              <p
                style={{
                  fontSize: 12,
                  color: "white",
                  borderRadius: 25,
                  padding: "4px 8px",
                  backgroundColor: "#0B44C8",
                }}
              >
                <AtlasIcon
                  svgHref={`atlas-location`}
                  style={{
                    width: 12,
                    height: 12,
                    fill: "white",
                    marginRight: 5,
                  }}
                />
                {country_name || "N/A"}
              </p>
              <p
                style={{
                  fontSize: 12,
                  color: "white",
                  borderRadius: 25,
                  padding: "4px 8px",
                  marginLeft: 10,
                  backgroundColor: "#0B44C8",
                }}
              >
                <AtlasIcon
                  svgHref={`atlas-calendar-2`}
                  style={{
                    width: 12,
                    height: 12,
                    fill: "white",
                    marginRight: 5,
                  }}
                />
                {new Date().getFullYear()}
              </p>
            </div>
            {projectUnit && projectUnit.length > 0 && (
              <div className="at-statisic__leaderboard-bg">
                {TopThreeAgents.map((item, index) => (
                  <div
                    key={`leaderboard-${index}`}
                    className="at-statisic__leaderboard-cont"
                    style={{ backgroundImage: `url( ${item.background} )` }}
                  >
                    <div className="d-flex flex-column">
                      <img
                        className="at-statistic_leaderboard-avatar"
                        src={projectUnit[item.id].avatar_url || defaultAvatar}
                        alt="avatar"
                      />
                      <img
                        src={item.medal}
                        alt="medal-2"
                        style={{ width: 20, transform: "translateY( -10px )" }}
                      />
                      <label>{projectUnit[item.id]?.name ?? "-"}</label>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {projectUnit && projectUnit.length > 3 && (
              <div style={{ overflowY: "auto", maxHeight: 500 }}>
                {projectUnit.slice(3).map((item, index) => (
                  <div
                    key={`leaderboard-${index + 3}`}
                    className="at-statistic_leaderboard-agent"
                    style={{
                      backgroundColor: item.id === id ? "#EFF6FF" : "white",
                    }}
                  >
                    <img src={item.avatar_url || defaultAvatar} alt="avatar" />
                    <label>{item?.name ?? "-"}</label>
                    <div
                      className="d-flex align-items-center ml-auto text-white fw-600 px-2 py-1"
                      style={{
                        fontSize: 12,
                        background: "#6B7280",
                        borderRadius: 25,
                      }}
                    >
                      <AtlasIcon
                        svgHref={`atlas-medal`}
                        style={{
                          width: 12,
                          height: 12,
                          fill: "white",
                          marginRight: 5,
                        }}
                      />
                      {item.rank}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="at-form__content" style={{ gridArea: "1 / 2 / 3 / 3" }}>
          <div className="d-flex align-items-center mb-3">
            <h2 className="mb-0" style={{ flex: "1 0 0" }}>
              Project Sales {!fromTeam ? `in ${new Date().getFullYear()}` : ""}
            </h2>
            <div
              className="d-flex align-items-center"
              style={{ borderRadius: 8, border: "1px solid #D1D5DB" }}
            >
              {salesTypeOptions.map((item, index) => (
                <button
                  style={{
                    padding: "6px 10px",
                    fontSize: 14,
                    fontWeight: 500,
                    borderRadius: index === 0 ? "8px 0 0 8px" : "0 8px 8px 0",
                    borderLeft: index === 0 ? "none" : "1px solid #D1D5DB",
                    color: salesType === item.value ? "white" : "#1F2937",
                    backgroundColor:
                      salesType === item.value ? "#F0631D" : "white",
                  }}
                  onClick={() => setSalesType(item.value)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className="d-flex">
            <div
              className={`at-card--stat__general-icon-cont`}
              style={{ backgroundColor: "#6875F5" }}
            >
              <AtlasIcon svgHref={`atlas-building-4`} />
            </div>
            <div className="at-card--stat__general-category">
              <p>Total Sales {salesType === "amount" ? "Amount" : "Unit"}</p>
              <span>
                {salesType === "unit"
                  ? numberWithCommas(projectBookedUnits + projectConvertedUnits)
                  : numberWithCommas(
                      projectBookedUnitsAmount + projectConvertedUnitsAmount,
                    )}
              </span>
            </div>
          </div>
          <hr />
          <h3>SALES BY TYPE</h3>
          <div className="d-flex flex-wrap">
            <div>
              <div className="at-card--stat__general-category ml-0">
                <p>
                  <div
                    className="at-card--stat-dot"
                    style={{ backgroundColor: "#648FFF" }}
                  ></div>
                  {salesType === "unit"
                    ? "Total Booked Unit"
                    : "Total Booked Unit Amount"}
                </p>
                <span>
                  {numberWithCommas(
                    salesType === "unit"
                      ? projectBookedUnits
                      : projectBookedUnitsAmount,
                  )}
                </span>
              </div>
              <div className="at-card--stat__general-category ml-0">
                <p>
                  <div
                    className="at-card--stat-dot"
                    style={{ backgroundColor: "#FFB000" }}
                  ></div>
                  {salesType === "unit"
                    ? "Total Converted Unit"
                    : "Total Converted Unit Amount"}
                </p>
                <span>
                  {numberWithCommas(
                    salesType === "unit"
                      ? projectConvertedUnits
                      : projectConvertedUnitsAmount,
                  )}
                </span>
              </div>
            </div>
            <div className="at-card--stat__doughnut-cont">
              <Doughnut
                data={{
                  labels:
                    salesType === "unit"
                      ? ["Booked Units", "Converted Units"]
                      : ["Booked Units Amount", "Converted Units Amount"],
                  datasets: [
                    {
                      data:
                        salesType === "unit"
                          ? [
                              projectBookedUnits
                                ? (
                                    (projectBookedUnits /
                                      (projectBookedUnits +
                                        projectConvertedUnits)) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              projectConvertedUnits
                                ? (
                                    (projectConvertedUnits /
                                      (projectBookedUnits +
                                        projectConvertedUnits)) *
                                    100
                                  ).toFixed(2)
                                : 0,
                            ]
                          : [
                              projectBookedUnitsAmount
                                ? (
                                    (projectBookedUnitsAmount /
                                      (projectBookedUnitsAmount +
                                        projectConvertedUnitsAmount)) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              projectConvertedUnitsAmount
                                ? (
                                    (projectConvertedUnitsAmount /
                                      (projectBookedUnitsAmount +
                                        projectConvertedUnitsAmount)) *
                                    100
                                  ).toFixed(2)
                                : 0,
                            ],
                      backgroundColor: ["#648FFF", "#FFB000"],
                      hoverBorderColor: ["#648FFF", "#FFB000"],
                      hoverBorderWidth: 2,
                    },
                  ],
                }}
                options={{
                  legend: { display: false },
                  tooltips: { enabled: false },
                  cutoutPercentage: 80,
                  plugins: {
                    datalabels: { display: false },
                    emptyDoughnut: {
                      color: "#648FFF",
                      width: 8,
                      radiusDecrease: 20,
                    },
                  },
                }}
                plugins={[chartText, emptyDoughnut]}
              />
            </div>
          </div>
          {fromTeam && (
            <>
              <hr />
              <div className="d-flex align-items-center">
                {renderCancelUnit()}
              </div>
            </>
          )}
        </div>
        {!fromTeam && (
          <div
            className="at-form__content d-flex align-items-center"
            style={{ gridArea: "3 / 2 / 4 / 3", height: "fit-content" }}
          >
            {renderCancelUnit()}
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectSalesContent;
