import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import _ from "lodash";

import { Get, Post, Delete, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";
import CustomTypeahead from "components/Typeahead/new";

import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = ({ branches }) => [
  {
    label: "Agent Full Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Branch",
    value: "user_branch_id_eq",
    param: "",
    renderField: ({ searchParams, index, item, onChangeSearchParams }) => (
      <CustomTypeahead
        selectedValue={
          _.find(branches, { id: item.param })
            ? [_.find(branches, { id: item.param })]
            : []
        }
        typeaheadId={"branch"}
        options={branches || []}
        filterBy={["name"]}
        labelKey={"name"}
        onSelect={(val) => {
          if (val && val.length > 0) {
            let tmp = _.cloneDeep(searchParams);
            tmp[index].param = val[0].id;
            return onChangeSearchParams(tmp);
          }
        }}
        childrenHead={(rowItem) => <p>{rowItem.name}</p>}
      />
    ),
  },
  {
    label: "Start Date from",
    value: "event_start_date_time_dategteq",
    type: "date",
    param: "",
  },
  {
    label: "Start Date to",
    value: "event_start_date_time_datelteq",
    type: "date",
    param: "",
  },
  {
    label: "Event Training Type",
    value: "event_training_type_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { label: "Orientation", value: "1" },
      { label: "Project", value: "2" },
      { label: "Subsales", value: "3" },
      { label: "Digital Empowerment", value: "5" },
    ],
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Pending" },
      { value: "1", label: "Absent" },
      { value: "2", label: "Present" },
      { value: "3", label: "Resit" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateDialog: false,
      showExportDialog: false,
      showEditDialog: false,

      searchParams: searchParams({ branches: [] }),
      trainings: {},
      branches: [],
      trainingsPages: null,
      selectedAttendance: null,

      showDeleteConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeTrainingHOC = (val, context) => this.setState({ [context]: val });

    getTrainings = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/trainings?${search}page=${page}`,
        this.getTrainingsSuccess,
        this.getTrainingsError,
        this.load,
      );
    };
    getTrainingsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      payload.data &&
        payload.data.map((item) => {
          tempData.push({
            ...item,
            join_duration_minute: item.join_duration_minute + " minutes",
            training_start_date_time: Moment(
              item.training_start_date_time,
            ).format("DD MMM YYYY HH:mm"),
          });
        });

      this.setState({
        trainings: {
          data: tempData,
          meta: payload.meta,
        },
        trainingsPages: tmpTotalPages,
      });
    };
    getTrainingsError = (error) => requestError(error);

    updateAttendance = (id, dataToSubmit) =>
      Put(
        `/admin/trainings/${id}`,
        dataToSubmit,
        this.updateAttendanceSuccess,
        this.updateAttendanceError,
        this.load,
      );
    updateAttendanceSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getTrainings(currentPage, searchParams);

      requestSuccess(payload.message);
      this.setState({ showEditDialog: false });
    };
    updateAttendanceError = (error) => requestError(error);

    deleteTraining = (id) =>
      Delete(
        `/admin/trainings/${id}`,
        this.deleteTrainingSuccess,
        this.deleteTrainingError,
        this.load,
      );
    deleteTrainingSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getTrainings(currentPage, searchParams);

      requestSuccess(payload.message);
      this.setState({ showDeleteConfirmation: false });
    };
    deleteTrainingError = (error) => requestError(error);

    createAttendance = (dataToSubmit) =>
      Post(
        `/admin/trainings`,
        dataToSubmit,
        this.createAttendanceSuccess,
        this.createAttendanceError,
        this.load,
      );
    createAttendanceSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getTrainings(currentPage, searchParams);

      requestSuccess(payload.message);
      this.setState({ showCreateDialog: false });
    };
    createAttendanceError = (error) => requestError(error);

    getBranches = () =>
      Get(
        `/branches`,
        this.getBranchesSuccess,
        this.getBranchesError,
        this.load,
      );
    getBranchesSuccess = (payload) =>
      this.setState({
        branches: payload,
        searchParams: searchParams({ branches: payload }),
      });
    getBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showEditDialog={this.state.showEditDialog}
            selectedAttendance={this.state.selectedAttendance}
            branches={this.state.branches}
            trainings={this.state.trainings}
            showCreateDialog={this.state.showCreateDialog}
            showExportDialog={this.state.showExportDialog}
            trainingsPages={this.state.trainingsPages}
            searchParams={this.state.searchParams}
            onLoadTrainings={this.state.loading}
            getTrainings={this.getTrainings}
            getBranches={this.getBranches}
            createAttendance={this.createAttendance}
            deleteTraining={this.deleteTraining}
            updateAttendance={this.updateAttendance}
            onChangeTrainingHOC={this.onChangeTrainingHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
