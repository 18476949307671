import _ from 'lodash';
import Moment from 'moment';
import { ImYoutube } from 'react-icons/im';

import DefaultThumbnail from 'assets/images/default_thumbnail.jpg';
import AtlasIcon from 'components/Icon/atlasIcon';
import { getColorBadge } from 'dictionary/badgeColor';

const StatusColor = [
  { value: 1, colorName: 'Green' },
  { value: 2, colorName: 'Blue' }
];

const statuses = [
  { value: '', label: 'All' },
  { value: '1', label: 'Processing' },
  { value: '2', label: 'Ready' }
];

const videoTypes = [
  { value: '', label: 'All' },
  { value: 1, label: 'Project' },
  { value: 2, label: 'General' }
];

export const videoConfiguration = typeId => ({
  hideIqiLogo: false,
  logo: 'https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/iqi_logo_dark.png',
  logoPosition: 'right',
  logoOpacity: 1,
  hidePropertyDetails: typeId === 2,
  propertyDetailsTemplateId: 1,
  propertyDetailsPosition: 'left',
  propertyDetailsOpacity: 0.5,
  agentDetailsTemplateId: 1,
  agentDetailsPosition: 'left',
  agentDetailsOpacity: 0.5
});

export const searchParams = countries => [
  {
    type: 'input',
    label: 'Title',
    value: 'video_template_title_cont',
    param: ''
  },
  {
    type: 'input',
    label: 'Project',
    value: 'project_name_or_project_internal_name_cont',
    param: ''
  },
  {
    type: 'radio',
    label: 'Status',
    value: 'status_id_eq',
    options: statuses,
    param: ''
  },
  {
    type: 'select',
    label: 'Country',
    value: 'video_template_country_id_eq',
    options: countries,
    param: ''
  },
  {
    type: 'date',
    label: 'Start Created Date',
    value: 'created_at_dategteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'date',
    label: 'End Created Date',
    value: 'created_at_datelteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'radio',
    label: 'Video Type',
    value: 'video_template_type_id_eq',
    options: videoTypes,
    param: ''
  }
];

export const adminSearchParams = countries => [
  {
    type: 'input',
    label: 'Title',
    value: 'video_template_title_cont',
    param: ''
  },
  {
    type: 'input',
    label: 'Project',
    value: 'project_name_or_project_internal_name_cont',
    param: ''
  },
  {
    type: 'input',
    label: 'Agent Email',
    value: 'user_email_cont',
    param: ''
  },
  {
    type: 'select',
    label: 'Country',
    value: 'video_template_country_id_eq',
    options: countries,
    param: ''
  },
  {
    type: 'date',
    label: 'Start Request Date',
    value: 'created_at_gteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'date',
    label: 'End Request Date',
    value: 'created_at_lteq',
    format: 'dd-MM-yyyy',
    param: ''
  },
  {
    type: 'radio',
    label: 'Status',
    value: 'status_id_eq',
    options: statuses,
    param: ''
  },
  {
    type: 'radio',
    label: 'Video Type',
    value: 'video_template_type_id_eq',
    options: videoTypes,
    param: ''
  }
];

export const columnData = ({ onChangeVideoCreatorHOC }) => [
  {
    header: 'Thumbnail',
    columnClass: 'at-video-creator__thumbnail-column',
    contentClass: 'at-video-creator__thumbnail-column',
    renderColumn: data => (
      <div className='at-video-creator__thumbnail-wrapper'>
        <img
          src={data.video_template?.thumbnail_url || DefaultThumbnail}
          alt={data.video_template?.title}
          className='at-video-creator__thumbnail'
        />
        {data.url && (
          <div
            className='at-video-creator__thumbnail-overlay'
            onClick={async () => {
              await onChangeVideoCreatorHOC('selectedVideo', data);
              await onChangeVideoCreatorHOC('showVideoPlayer', true);
            }}
          >
            <ImYoutube className='at-video-creator__play-icon' />
          </div>
        )}
        {data.is_update_available && (
          <div
            className='at-video-creator__outdate_tag'
            onClick={async () => {
              await onChangeVideoCreatorHOC('selectedVideo', data);
              await onChangeVideoCreatorHOC('showUpdateConfirmation', true);
            }}
          >
            Updated version available
          </div>
        )}
      </div>
    )
  },
  {
    header: 'Title / Project',
    renderColumn: ({ video_template, project }) => (
      <>
        <div className='d-flex align-items-center' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-firstline'
            className='at-video-creator__title-icon'
          />
          <p>{video_template?.title || '-'}</p>
        </div>
        <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-building-4'
            className='at-video-creator__title-icon'
          />
          <p>
            {video_template?.version === 2 && video_template?.type_id === 2
              ? 'General'
              : project?.internal_name || '-'}
          </p>
        </div>
        {video_template.version === 2 && video_template.type_id === 1 && (
          <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
            <AtlasIcon
              svgHref='atlas-home'
              className='at-video-creator__title-icon'
            />
            <p>{video_template?.unit_type || '-'}</p>
          </div>
        )}
      </>
    )
  },
  {
    header: 'Description',
    renderColumn: ({ video_template }) => video_template?.description || '-'
  },
  {
    header: 'Created At',
    renderColumn: ({ created_at }) =>
      Moment(created_at).format('DD MMM YYYY HH:mm'),
    columnStyle: { width: '50%' }
  },
  {
    header: () => (
      <span className=' at-table-head__title text-center'>Status</span>
    ),
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
    renderColumn: ({ status_id }) => {
      const { colorName = '' } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className='at-status_badge'
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? 'Processing' : 'Ready'}
        </div>
      );
    }
  }
];

export const adminColumnData = ({ onChangeVideoCreatorHOC }) => [
  {
    header: 'Thumbnail',
    columnClass: 'at-video-creator__thumbnail-column',
    contentClass: 'at-video-creator__thumbnail-column',
    renderColumn: data => (
      <div className='at-video-creator__thumbnail-wrapper'>
        <img
          src={data.video_template?.thumbnail_url || DefaultThumbnail}
          alt={data.video_template?.title}
          className='at-video-creator__thumbnail'
        />
        {data.url && (
          <div
            className='at-video-creator__thumbnail-overlay'
            onClick={async () => {
              await onChangeVideoCreatorHOC('selectedVideo', data);
              await onChangeVideoCreatorHOC('showVideoPlayer', true);
            }}
          >
            <ImYoutube className='at-video-creator__play-icon' />
          </div>
        )}
      </div>
    )
  },
  {
    header: 'Title / Project',
    renderColumn: ({ video_template, project }) => (
      <>
        <div className='d-flex align-items-center' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-firstline'
            className='at-video-creator__title-icon'
          />
          <p>{video_template?.title || '-'}</p>
        </div>
        <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
          <AtlasIcon
            svgHref='atlas-building-4'
            className='at-video-creator__title-icon'
          />
          <p>
            {video_template?.version === 2 && video_template?.type_id === 2
              ? 'General'
              : project?.internal_name || '-'}
          </p>
        </div>
        {video_template?.version === 2 && video_template?.type_id === 1 && (
          <div className='d-flex align-items-center mt-1' style={{ gap: 8 }}>
            <AtlasIcon
              svgHref='atlas-home'
              className='at-video-creator__title-icon'
            />
            <p>{video_template?.unit_type || '-'}</p>
          </div>
        )}
      </>
    ),
    columnStyle: { width: '150%' }
  },
  {
    header: 'Request At',
    renderColumn: ({ created_at }) =>
      Moment(created_at).format('DD MMM YYYY HH:mm'),
    columnStyle: { width: '50%' }
  },
  {
    header: 'Agent',
    renderColumn: ({ agent }) => agent?.full_name,
    columnStyle: { width: '50%' }
  },
  {
    header: () => (
      <span className='at-table-head__title text-center'>Status</span>
    ),
    contentClass: 'at-column-responsive_center',
    customClass: 'at-column-responsive_center',
    renderColumn: ({ status_id }) => {
      const { colorName = '' } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className='at-status_badge'
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? 'Processing' : 'Ready'}
        </div>
      );
    }
  }
];
