import _ from "lodash";

import { STORE_SUBSALES_ADDRESS } from "actions/type";

// typeahead data structure

let initialState = {
  address: [],
};

const checkData = (storedAddresses, payload) => {
  let existed = false;
  storedAddresses.filter((val) => {
    let tmpVal = val.value.toLowerCase();
    let tmpPayload = payload.toLowerCase();
    if (tmpVal.indexOf(tmpPayload) > -1) {
      existed = true;
    }
  });
  return existed;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SUBSALES_ADDRESS:
      let tmp = _.cloneDeep(state.address);
      let data = {
        label: action.payload,
        value: action.payload,
      };
      if (state.address.length >= 15) {
        tmp.shift();
        let existed = checkData(state.address, action.payload);
        !existed && tmp.push(data);
      } else {
        let existed = checkData(state.address, action.payload);
        !existed && tmp.push(data);
      }
      return {
        ...state,
        address: tmp,
      };
    default:
      return state;
  }
};
