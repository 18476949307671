import React from "react";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";
import SVGIcon from "components/Icon";

const ReferralError = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={JuwaiIQILogo}
        alt={"JuwaiLogo"}
        style={{ height: 200, marginTop: 80 }}
      />
      <p
        style={{
          fontSize: 24,
          lineHeight: "130%",
          fontWeight: "600",
          display: "block",
          position: "relative",
          top: -20,
        }}
      >
        IQI Agent Signup
      </p>
      <div
        style={{
          width: 44,
          height: 44,
          margin: "auto",
          borderRadius: 999,
          background: "#FEE4E2",
          display: "flex",
          alignItems: "flex-start",
          gap: 8,
          marginTop: 60,
        }}
      >
        <SVGIcon
          style={{ flex: "auto", padding: 8, fill: "#F04438" }}
          svgHref={"icon-bold-danger"}
        />
      </div>
      <p
        style={{
          fontSize: 24,
          lineHeight: "130%",
          fontWeight: "600",
          color: "#F12711",
          display: "block",
          position: "relative",
          marginTop: 16,
        }}
      >
        Invalid Referral Link
      </p>
      <p
        style={{
          fontSize: 16,
          lineHeight: "150%",
          fontWeight: "500",
          color: "#374151",
          display: "block",
          position: "relative",
          marginTop: 16,
        }}
      >
        Please get the correct referral link from the leader.
      </p>
    </div>
  );
};

export default ReferralError;
