import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { IoIosClose } from "react-icons/io";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CustomCheckbox from "components/Checkbox";
import CustomBtnDatePicker from "components/Button/datepicker"
import AtlasIcon from "components/Icon/atlasIcon";

import TenancyAgreementHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const headerData = ({
  can_update,
  filteredListData = [],
  selectedTaList,
  onClickSelectAll,
  onClickCheckBox,
  updateTenancyAgreement,
  onCopy,
}) => [
  {
    header: () => (
      <span className="at-table-head-checkbox_responsive">
        <DarkTooltip
          classes={{ tooltip: "tooltip-arrow left" }}
          placement={"right"}
          enterTouchDelay={0}
          title={
            (filteredListData.length === selectedTaList.length && selectedTaList.length !== 0)
              ? "Deselect All"
              : "Select All"
          }>
          <CustomCheckbox
            labelStyle={{ marginBottom: 10 }}
            checked={
              (filteredListData.length === selectedTaList.length && filteredListData.length !== 0)
            }
            disabled={filteredListData.length === 0 || !can_update}
            onChangeCheckboxValue={() => onClickSelectAll()}
          />
        </DarkTooltip>
      </span>
    ),
    renderColumn: (rowData) => !rowData.is_billed && (
      <CustomCheckbox
        disabled={!can_update}
        checked={_.includes(selectedTaList, rowData.id)}
        onChangeCheckboxValue={() => onClickCheckBox(rowData.id)}
      />
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnClass: "at-table--checkbox-column mt-0",
    columnStyle: { width: "10%", gridColumn: "1/-1", },
  },
  { header: "Form No.", 
    columnClass: "at-table-head__hide-mobile mt-0",
    renderColumn: (rowData) => {
      return (
        <>
          {
            !rowData.is_billed && (
              <CustomCheckbox
                labelClassname={"at-table--checkbox-mobile mt-2"}
                disabled={!can_update}
                checked={_.includes(selectedTaList, rowData.id)}
                onChangeCheckboxValue={() => onClickCheckBox(rowData.id)}
              />
            )
          }
          <p>{rowData.confirmation_form_number}</p>
          {
            (rowData.unit_id || rowData.unit_id > 0)  && (
              <DarkTooltip
                enterTouchDelay={50}
                classes={{ tooltip: "tooltip-arrow top" }}
                placement={"top"}
                title={"Unit ID"}>
                <span
                  className="badge badge-primary copy_unitID-clipboard d-block mt-1"
                  data-clipboard-text={rowData.unit_id}
                  onClick={() => {
                    navigator.clipboard.writeText(rowData.unit_id);
                    onCopy()
                  }}
                  style={{
                    fontSize: "0.75rem",
                    width: "max-content",
                    cursor: "pointer",
                  }}>
                  {rowData.unit_id}
                </span>
              </DarkTooltip>
            )
          }
        </>
      )
    } 
  },
  {
    header: "Law Firm",
    accessor: "law_firm_name",
    columnClass: "mt-0",
  },
  { header: "Rental Price", accessor: "net_price", columnStyle: { width: "60%" }, columnClass: "mt-0" },
  { header: "Fee", accessor: "fee", columnStyle: { width: "60%", } },
  {
    header: "Claim Approval Date",
    accessor: "claim_approved_date",
    columnClass: "mt-0",
  },
  { header: "Agreement Date", accessor: "agreement_date", columnClass: "mt-0" },
  { 
    header: "Billed", 
    columnClass: "mt-0",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "60%" },
    renderColumn: (rowData) => {
      return (
        <>
          {
            rowData.is_billed && (
              <AtlasIcon
                svgHref={"atlas-tick-circle"}
                style={{ width: 20, height: 20, fill: "#039855", color: "#039855" }}
              />
            )
          }
          {
            !rowData.is_billed && (
              <AtlasIcon
                svgHref={"atlas-close-circle"}
                style={{ width: 20, height: 20, fill: "#D92D20", color: "#D92D20" }}
              />
            )
          }
        </>
      );
    }, 
  },
  { header: "Billing Date",
    columnClass: "mt-0",
    renderColumn: (rowData) => {
      return (
        <div className="d-flex align-items-center">
          <span>{rowData.billing_date || "-"}</span>
          {
            rowData.is_billed && (
              <CustomBtnDatePicker
                disabled={!can_update}
                maxDate={Moment(new Date())}
                onAccept={ val => {
                  updateTenancyAgreement(rowData.id, {
                    is_billed: true,
                    billing_date: val ? val.format("YYYY-MM-DD") : "" 
                  })
              }}/>
            )
          }
        </div>
      )
    }, 
   },
];

class TenancyAgreementManagement extends Component {
  state = {
    showDatePicker: false,
    isBulkData: false,
    copyStatus: false,
  }
  componentDidMount = () => {
    initGA("/dashboard/admin/tenancy-agreements");

    this.props.getTenancyAgreementList(1, "");
    this.props.getLawFirmPanel()
  };

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={this.state.copyStatus}
      autoHideDuration={6000}
      onClose={() => this.setState({ copyStatus: !this.state.copyStatus })}>
      <SnackbarContent
        message={
          <span>Data copied into clipboard. You can now paste the data.</span>
        }
        action={
          <button
            onClick={() => this.setState({ copyStatus: !this.state.copyStatus })}>
            <IoIosClose style={{ color: "#eee", width: 25, height: 25 }} />
          </button>
        }
      />
    </Snackbar>
  );

  renderTableHeading = () => {
    const { can_update } = permissionsChecker("Tenancy Agreements", this.props.data);
    const filteredListData = this.props.tenancyAgreementsList.data?.filter(item => !item.is_billed) || [];
    return (
      <>
        <div
          className={"at-mobile-view__controller at-table--checkbox-actions mb-0"}>
          <div className="d-flex align-items-center">
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow left" }}
              placement={"right"}
              enterTouchDelay={0}
              title={
                (filteredListData.length === this.props.selectedTaList.length && this.props.selectedTaList.length !== 0)
                  ? "Deselect All"
                  : "Select All"
              }>
              <CustomCheckbox
                labelClassname="my-0"
                content={
                  (filteredListData.length === this.props.selectedTaList.length && this.props.selectedTaList.length !== 0)
                    ? "Deselect All"
                    : "Select All"
                }
                labelStyle={{ marginBottom: 10 }}
                checked={
                  (filteredListData.length === this.props.selectedTaList.length && filteredListData.length !== 0)
                }
                disabled={filteredListData.length === 0 || !can_update}
                onChangeCheckboxValue={() => this.props.onClickSelectAll()}
              />
            </DarkTooltip>
            <button className="btn-new btn-new--secondary ml-2" 
              disabled={!can_update || this.props.selectedTaList.length === 0}
              onClick={ () => this.setState({ showDatePicker: true })}>
              Billed
            </button>
          </div>
        </div>
        <div className="at-table_heading at-table_heading-responsive at-table_heading-responsive-mt d-flex align-items-center"
          style={{
            borderTopLeftRadius: "0.6rem",
            borderTopRightRadius: "0.6rem"
          }}>
          <button className="btn-new btn-new--secondary" 
            disabled={!can_update || this.props.selectedTaList.length === 0}
            onClick={ () => this.setState({ showDatePicker: true })}>
            Billed
          </button>
        </div>
      </>
    );
  }

  render = () => {
    const { can_update } = permissionsChecker("Tenancy Agreements", this.props.data);
    
    return (
      <>
        <ModuleHeader
          title={"Tenancy Agreements"}
          atlasIcon={"svg-iconsax-clipboard-text"}
          actionButton={[]}/>
        <CustomCard
          cardContent={
            <AtPaginatedTable
              wrapperClassName={"at-tenancy_agreement-table"}
              columns={headerData({
                can_update,
                showDatePicker: this.state.showDatePicker,
                filteredListData: this.props.tenancyAgreementsList.data?.filter(item => !item.is_billed) || [],
                selectedTaList: this.props.selectedTaList,
                onClickSelectAll: this.props.onClickSelectAll,
                onClickCheckBox: this.props.onClickCheckBox,
                updateTenancyAgreement: this.props.updateTenancyAgreement,
                onCopy: () => this.setState({ copyStatus: true }),
              })}
              rowData={this.props.tenancyAgreementsList.data || []}
              meta={this.props.tenancyAgreementsList.meta && this.props.tenancyAgreementsList.meta}
              searchParams={this.props.searchParams || []}
              onChangeSearchParams={(val) => this.props.onChangeTenancyAgreementHOC(val, "searchParams")}
              getListAPI={(page, search) => this.props.getTenancyAgreementList(page, search)}
              totalPages={this.props.totalPages}
              renderExtraContent={ () => this.renderTableHeading() }
              renderAdditionalFilterActions={ () => (
                <button
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      if(item.param) {
                        if(item.value) {
                          if(item.withoutPrefix) {
                            tmp = tmp + `${item.value}=${
                              item.type === "typeahead"
                                ? item.param.name
                                : item.type === "date_time"
                                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                                  : item.param
                            }&`
                          } else {
                            tmp = tmp + `q[${item.value}]=${
                              item.type === "typeahead"
                                ? item.param.name
                                : item.type === "date_time"
                                  ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                                  : item.param
                            }&`
                          }
                        } else {
                          tmp = tmp + `q${item.param}&`
                        }
                      }
                    });
                    this.props.exportTenancyAgreement(tmp)}}>
                  <AtlasIcon
                    svgHref={"atlas-xlsx-file"}
                    style={{ width: 20, height: 20 }}
                  />
                  Export
                </button>
              )}
            />
          }
        />
        {
          this.state.showDatePicker && (
            <LocalizationProvider 
              dateAdapter={AdapterMoment}
              adapterLocale="en"
              moment={Moment}>
              <MobileDatePicker
                disabled={!can_update}
                maxDate={Moment(new Date())}
                className={"d-none"}
                open={this.state.showDatePicker}
                onAccept={ val => {
                  this.props.updateBulkTenancyAgreements({ids: this.props.selectedTaList, billing_date: val ? val.format("YYYY-MM-DD") : ""})
                }}
                onClose={ () => this.setState({ showDatePicker: false})}
              />
            </LocalizationProvider>
          )
        }
        {this.renderSnackBar()}
        {(this.props.onLoad || this.props.onLoadUpdate) && <LoadingModal />}
      </>
    );
  };
}

export default compose(TenancyAgreementHOC)(TenancyAgreementManagement);
