import React, { Component } from "react";

import Moment from "moment";
import { Get } from "utils/axios";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { requestError } from "utils/requestHandler";
import { numberWithCommas } from "utils/thousandSeparator";

const searchParams = [
  {
    label: "Project Name",
    value: "project_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Unit Number ",
    value: "unit_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Booking Date From",
    value: "booking_date_dategteq",
    type: "date",
    param: "",
  },
  {
    label: "Booking Date To",
    value: "booking_date_datelteq",
    type: "date",
    param: "",
  },
  {
    label: "Buyer",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "2", label: "Submitted" },
      { value: "3", label: "Approved" },
      { value: "4", label: "Rejected" },
      { value: "5", label: "Cancelled" },
      { value: "6", label: "Ready to Invoice" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showViewDialog: false,
      cobrokeSearchParams: searchParams,
      cobrokeSale: {
        data: [],
        meta: [],
      },
      cobrokeSalePage: [],
      selectedCobroke: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeCobrokeSaleHOC = (val, context) =>
      this.setState({ [context]: val });

    getCobrokeSale = (page, search) =>
      Get(
        `/project_sales/co_broke?${search}page=${page}`,
        this.getCobrokeSaleSuccess,
        this.getCobrokeSaleError,
        this.load,
      );
    getCobrokeSaleSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        cobrokeSale: {
          data: payload.data,
          meta: payload.meta,
        },
        cobrokeSalePage: tmpTotalPages,
      });
    };
    getCobrokeSaleError = (error) => requestError(error);

    getSelectedCobrokeSale = (id) =>
      Get(
        `/project_sales/${id}/show_co_broke`,
        this.getSelectedCobrokeSaleSuccess,
        this.getSelectedCobrokeSaleError,
        this.load,
      );
    getSelectedCobrokeSaleSuccess = (payload) => {
      let temp = {
        ...payload,
        booking_date: payload.booking_date
          ? Moment(payload.booking_date).format("DD MMM YYYY")
          : null,
        purchase_price: numberWithCommas(payload.purchase_price),
        nett_price: numberWithCommas(payload.nett_price),
        status_id: `${payload.status_id}`,
        type_id: `${payload.type_id}`,
        booking_form: payload.booking_form_url,
        project_sale_agents_attributes: payload.sale_agents,
        remark: payload.remark ? payload.remark : "N/A",
      };
      this.setState({
        selectedCobroke: temp,
        showViewDialog: true,
      });
    };
    getSelectedCobrokeSaleError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onCobrokeLoad={this.state.loading}
            cobrokeSale={this.state.cobrokeSale}
            cobrokeSalePage={this.state.cobrokeSalePage}
            cobrokeSearchParams={this.state.cobrokeSearchParams}
            selectedCobroke={this.state.selectedCobroke}
            showViewDialog={this.state.showViewDialog}
            onChangeCobrokeSaleHOC={this.onChangeCobrokeSaleHOC}
            getSelectedCobrokeSale={this.getSelectedCobrokeSale}
            getCobrokeSale={this.getCobrokeSale}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
