
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment"

import { Post, Get, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";
import { getAllDictionaryData } from "actions/dictionary";

const OppositeParticipantHOC = (WrappedComponent) => {
  class OppositeParticipantWrappedComponent extends Component {
    state = {
      loading: false,
      subsalesClaim: {},
      selectedParticipant: {},
      subsale_claim_id: "",
      showParticipantDetails: false,
      showPromptPassword: false,
      showUpdateConfirmation: false,
      showEditParticipant: false,
      showCreateParticipant: false,
      infoPassword: ""
    }

    load = param => this.setState({ loading: param });

    onChangeOppositeParticipantHOC = (value, key) => this.setState({ [key]: value });

    getInfoDetailFromOppositeParty = (sub_sale_claim_id, password) => {
      Get(
        `/sub_sale_claims/${sub_sale_claim_id}/opposite_participants/claim?password=${password}`,
        (payload) => this.getInfoDetailFromOppositePartySuccess(payload, password),
        this.getInfoDetailFromOppositePartyError,
        this.load,
      );
    };
    getInfoDetailFromOppositePartySuccess = (payload, password) => {
      const {
        property_location = {},
        user = {},
        read_only_participants = []
      } = payload
      this.props.getAllDictionaryData()
      this.props.getBanks()
      this.setState({
        subsalesClaim: {
          ...payload,
          agreement_date: Moment(payload.agreement_date, "YYYY-MM-DD").isValid()
            ? Moment(payload.agreement_date, "YYYY-MM-DD").format("DD-MM-YYYY")
            : payload.agreement_date,
          booking_date: Moment(payload.booking_date, "YYYY-MM-DD").isValid()
            ? Moment(payload.booking_date, "YYYY-MM-DD").format("DD-MM-YYYY")
            : payload.booking_date,
          confirmation_form_number: payload.confirmation_form_number,
          earnest_deposit_received_amount: payload.earnest_deposit_received_amount,
          is_locked: payload.is_locked,
          net_price: payload.net_price,
          opposite_info_status: payload.opposite_info_status,

          property_location: {
            address: property_location?.address,
            bathrooms: property_location?.bathrooms,
            bedrooms: property_location?.bedrooms,
            built_up: property_location?.built_up,
            category_id: property_location?.category_id,
            country_id: property_location?.country_id,
            land_area: property_location?.land_area,
            postcode: property_location?.postcode,
            property_name: property_location?.property_name,
            property_type_id: property_location?.property_type_id,
            state_id: property_location?.state_id,
            tenure_type_id: property_location?.tenure_type_id,
            township_id: property_location?.township_id,
            unit_no: property_location?.unit_no,
            unit_type_id: property_location?.unit_type_id,
          },
          
          sub_sale_type_id: payload.sub_sale_type_id,
          read_only_participants: read_only_participants.map( party => {
            return {
              ...party,
              contact_type_id: party.contact_type_id
                ? `${party.contact_type_id}`
                : ""
            }
          }),
          representative_id: payload.representative_id,
          representative_party: payload.representative_party,

          user: {
            avatar_url: user.avatar_url,
            email: user.email,
            full_name: user.full_name,
            mobile_contact_number: user.mobile_contact_number,
            team_name: user.team_name,
          }
        }, 
        showParticipantDetails: true,
        showPromptPassword: false,
        infoPassword: password
      }, () => {
        this.getOppositePartyList(this.state.subsale_claim_id, password)
      })
      Promise.all([
        this.props.onChangeAccessCaseFileHOC("", "statusMessage")
      ]).then(() => {
        this.props.onChangeAccessCaseFileHOC("success", "requestStatus")
      })
    };
    getInfoDetailFromOppositePartyError = (error) => {
      Promise.all([
        this.props.onChangeAccessCaseFileHOC(
          error
            ? ReactHtmlParser(error)
            : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
        , "statusMessage")
      ]).then(() => {
        this.props.onChangeAccessCaseFileHOC("error", "requestStatus")
      })
    }

    completeUpdate = (data) => {
      Put(
        `/sub_sale_claims/${this.state.subsale_claim_id}/opposite_participants/complete_update`,
        { password: this.state.infoPassword },
        (payload) => this.completeUpdateSuccess(payload),
        this.completeUpdateError,
        this.load,
      );
    };
    completeUpdateSuccess = (payload) => {
      this.setState({showUpdateConfirmation: false})
      this.getInfoDetailFromOppositeParty(this.state.subsale_claim_id, this.state.infoPassword)
      requestSuccess("Info updated successfully.");
    }
    completeUpdateError = (error) => requestError(error);

    getOppositePartyList = (sub_sale_claim_id, password) => {
      Get(
        `/sub_sale_claims/${sub_sale_claim_id}/opposite_participants?password=${password}`,
        (payload) => this.getOppositePartyListSuccess(payload),
        this.getOppositePartyListError,
        this.load,
      );
    };
    getOppositePartyListSuccess = (payload) => {
      this.setState({
        subsalesClaim: {
          ...this.state.subsalesClaim,
          opposite_participants: payload
        }
      })
    };
    getOppositePartyListError = (error) => requestError(error);
    
    getOppositePartyDetails = (id) => {
      Get(
        `/sub_sale_claims/${this.state.subsale_claim_id}/opposite_participants/${id}?password=${this.state.infoPassword}`,
        (payload) => this.getOppositePartyDetailsSuccess(payload),
        this.getOppositePartyDetailsError,
        this.load,
      );
    };
    getOppositePartyDetailsSuccess = (payload) => {
      let tmp = {
        ...payload,
        date_of_birth: Moment(payload.date_of_birth, "YYYY-MM-DD").isValid()
          ? Moment(payload.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
          : payload.date_of_birth,
        contact_type_id: payload.contact_type_id
          ? `${payload.contact_type_id}`
          : "",
        contactNumber: payload.contact_number
      }
      
      this.setState({
        selectedParticipant: tmp, 
        showEditParticipant: true
      })
    };
    getOppositePartyDetailsError = (error) => requestError(error);
    
    createOppositeParty = (data) => {
      Post(
        `/sub_sale_claims/${this.state.subsale_claim_id}/opposite_participants`,
        { 
          password: this.state.infoPassword,
          ...data
        },
        (payload) => this.createOppositePartySuccess(payload),
        this.createOppositePartyError,
        this.load,
      );
    };
    createOppositePartySuccess = (payload) => {
      requestSuccess("Party is created successfully.");
      this.getOppositePartyList(this.state.subsale_claim_id, this.state.infoPassword)
      this.getOppositePartyDetails(payload.id)
      this.setState({ showCreateParticipant: false })
    }
    createOppositePartyError = (error) => requestError(error);
    
    updateOppositeParty = (data) => {
      let tmpInfoCopies = { ...data.copies_info }
      Put(
        `/sub_sale_claims/${this.state.subsale_claim_id}/opposite_participants/${this.state.selectedParticipant.id}`,
        { 
          password: this.state.infoPassword,
          ...data
        },
        (payload) => this.updateOppositePartySuccess(payload),
        this.updateOppositePartyError,
        this.load,
      );
    };
    updateOppositePartySuccess = (payload) => {
      this.getOppositePartyDetails(payload.id)
      this.getOppositePartyList(this.state.subsale_claim_id, this.state.infoPassword)
      requestSuccess("Party is updated successfully.")
    }
    updateOppositePartyError = (error) => requestError(error);
    
    render = () => (
      <WrappedComponent
        {...this.props}
        onLoadParticipant={this.state.loading}
        subsale_claim_id={this.state.subsale_claim_id}
        subsalesClaim={this.state.subsalesClaim}
        selectedParticipant={this.state.selectedParticipant}
        infoPassword={this.state.infoPassword}
        showParticipantDetails={this.state.showParticipantDetails}
        showPromptPassword={this.state.showPromptPassword}
        showUpdateConfirmation={this.state.showUpdateConfirmation}
        showEditParticipant={this.state.showEditParticipant}
        showCreateParticipant={this.state.showCreateParticipant}
        onChangeOppositeParticipantHOC={this.onChangeOppositeParticipantHOC}
        getInfoDetailFromOppositeParty={this.getInfoDetailFromOppositeParty}
        completeUpdate={this.completeUpdate}
        getOppositePartyList={this.getOppositePartyList}
        getOppositePartyDetails={this.getOppositePartyDetails}
        createOppositeParty={this.createOppositeParty}
        updateOppositeParty={this.updateOppositeParty}
      />
    )
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getAllDictionaryData
  })(OppositeParticipantWrappedComponent);
  
}

export default OppositeParticipantHOC;