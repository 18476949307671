import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import isEmptyValue from "utils/isEmpty";
import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";

const statusOption = [
  { id: 0, label: "Pending", value: "0" },
  { id: 1, label: "Processing", value: "1" },
  { id: 2, label: "Arrived", value: "2" },
  { id: 3, label: "Collected", value: "3" },
  { id: 4, label: "Rejected", value: "4" },
  { id: 5, label: "Voided", value: "5" },
  { id: 6, label: "Cancelled", value: "6" },
  { id: 7, label: "To Pay", value: "7" },
];

const productTypeDictionary = [
  { id: 1, label: "Starter Kit" },
  { id: 2, label: "Name Card" },
  { id: 3, label: "Event Ticket" },
  { id: 4, label: "Regular" },
];

const searchParams = [
  {
    label: "Order Number",
    value: "market_order_order_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "market_order_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "market_order_user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Branch",
    value: "market_order_user_branch_id_eq",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "Delivery Branch",
    value: "delivery_branch_id_eq",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "Product Name",
    value: "market_product_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Product Type",
    value: "market_product_type_id_eq",
    type: "select",
    param: "",
    options: [
      { id: 1, label: "Starter Kit", value: "1" },
      { id: 2, label: "Name Card", value: "2" },
      { id: 3, label: "Event Ticket", value: "3" },
      { id: 4, label: "Regular", value: "4" },
    ],
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [{ value: "", label: "All" }, ...statusOption],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      requests: [],
      loading: false,
      selectedId: "",

      showRejectModal: false,
      showCancelModal: false,
      showConfirmationModal: false,
      showDetailsModal: false,

      orders: {},
      selectedOrder: {},
      orderPage: null,
      checkedArray: [],
      chosenStatusId: null,

      checkableData: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeOrderManagementHOC = (val, context) =>
      this.setState({ [context]: val });

    getOrders = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/market_order_items?${search}page=${page}`,
        this.getOrdersSuccess,
        this.getOrdersError,
        this.load,
      );
    };
    getOrdersSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      let tempCheckable = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.length > 0 &&
        payload.data.map((item) => {
          let tempPurchaseType = "N/A";
          if (item.payment_method === "Coupon") {
            tempPurchaseType = "Redeemed by Coupon";
          }
          if (item.payment_method === "Shopping Cart") {
            tempPurchaseType = "Purchase by Cart";
          }
          if (item.status_id !== 3 && item.status_id !== 4) {
            tempCheckable.push(item);
          }
          let tempType = _.find(productTypeDictionary, {
            id: item.market_product.type_id,
          });
          let tempStatus = _.find(statusOption, { id: item.status_id });

          const renderTitle = () => {
            switch (item.market_product.type_id) {
              case 1:
                return "";
              case 2:
                return !isEmptyValue(
                  item.selected_market_product_attached_product,
                )
                  ? item.selected_market_product_attached_product.options.map(
                      (option, index) =>
                        `${option.value}${index + 1 !== item.selected_market_product_attached_product.options.length ? " - " : ""}`,
                    )
                  : "";
              case 3:
                return !isEmptyValue(
                  item.selected_market_product_attached_product,
                )
                  ? item.selected_market_product_attached_product.title
                  : "";
            }
          };

          tempData.push({
            ...item,
            title: renderTitle(),
            order_number: item.market_order.order_number,
            purchase_type: tempPurchaseType,
            product_type: tempType ? tempType.label : "",
            status: tempStatus ? tempStatus.label : "",
            tShirtSize: item.payload ? item.payload.t_shirt_size : "",
            price: item.price.toFixed(2),
          });
        });
      payload.data = tempData;

      this.setState({
        orders: payload,
        orderPage: tmpTotalPages,
        checkedArray: [],
        checkableData: tempCheckable,
      });
    };
    getOrdersError = (error) => requestError(error);

    getSelectedOrder = (id) =>
      Get(
        `/admin/market_order_items/${id}`,
        (payload) => this.getSelectedOrderSuccess(payload, id),
        this.getSelectedOrderError,
        this.load,
      );
    getSelectedOrderSuccess = (payload, id) =>
      this.setState({
        selectedOrder: {
          ...payload,
          selectedId: id,
          user_branch_name: payload.user.branch_name,
          collected_date:
            payload.market_order_items.length > 0
              ? payload.market_order_items[0].collected_date
              : "",
          user_via_recruitment_campaign_text:
            payload.user_via_recruitment_campaign ? "Yes" : "No",
          market_order_items: payload.market_order_items.map((item) => ({
            ...item,
            total_price: item.price * item.quantity,
          })),
        },
        showDetailsModal: true,
      });
    getSelectedOrderError = (error) => requestError(error);

    updateOrderStatus = (data, tempRemark) => {
      let url = "";
      let temp = [];
      data.map((item) => {
        temp.push({ id: item });
      });
      let dataToSubmit = { item_ids: temp };

      switch (this.state.chosenStatusId) {
        case 0:
          url = "/admin/market_order_items/pending";
          break;
        case 1:
          url = "/admin/market_order_items/processing";
          break;
        case 2:
          url = "/admin/market_order_items/arrive";
          break;
        case 3:
          url = "/admin/market_order_items/collect";
          break;
        case 4:
          url = "/admin/market_order_items/reject";
          dataToSubmit = { item_ids: temp, remark: tempRemark };
          break;
        case 5:
          url = "/admin/market_order_items/void";
          dataToSubmit = { item_ids: temp, remark: tempRemark };
          break;

        default:
          return "";
      }

      Post(
        url,
        dataToSubmit,
        this.updateOrderStatusSuccess,
        this.updateOrderStatusError,
        this.load,
      );
    };
    updateOrderStatusSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getOrders(currentPage, searchParams);
      requestSuccess(`Selected order items' status updated successfully.`);

      this.setState({
        checkedArray: [],
        showConfirmationModal: false,
        showRejectModal: false,
        showCancelModal: false,
      });
    };
    updateOrderStatusError = (error) => requestError(error);

    updateMarketOrderItemCollectStatus = (data, value, index) => {
      return Put(
        `/admin/market_order_attached_items/${data.attached_items[index].id}/${value ? "mark_as_collected" : "mark_as_uncollected"}`,
        {},
        this.updateMarketOrderItemCollectStatusSuccess,
        this.updateMarketOrderItemCollectStatusError,
        this.load,
      );
    };
    updateMarketOrderItemCollectStatusSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getOrders(currentPage, searchParams);
      requestSuccess(payload.message);
    };
    updateMarketOrderItemCollectStatusError = (error) => {
      this.getSelectedOrder(this.state.selectedOrder.selectedId);
      requestError(error);
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          orders={this.state.orders}
          checkableData={this.state.checkableData}
          showDetailsModal={this.state.showDetailsModal}
          showCancelModal={this.state.showCancelModal}
          showRejectModal={this.state.showRejectModal}
          showConfirmationModal={this.state.showConfirmationModal}
          chosenStatusId={this.state.chosenStatusId}
          checkedArray={this.state.checkedArray}
          selectedOrder={this.state.selectedOrder}
          orderPage={this.state.orderPage}
          searchParams={this.state.searchParams}
          onLoadOrderManagement={this.state.loading}
          getOrders={this.getOrders}
          updateOrderStatus={this.updateOrderStatus}
          getSelectedOrder={this.getSelectedOrder}
          onChangeOrderManagementHOC={this.onChangeOrderManagementHOC}
          updateMarketOrderItemCollectStatus={
            this.updateMarketOrderItemCollectStatus
          }
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
