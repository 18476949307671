import React from "react";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import AtlasRadioGroup from "components/RadioGroup";
import AlertBox from "components/AlertBox";
import CustomSelect from "components/Select";
import AtlasSelect from "components/Select/new";
import { co_broker_representative_types } from "dictionary/subsales";
import "stylesheets/containers/subsales-claims/index.scss";

const ExternalAgent = ({
  from,
  data: dataReducer,
  banks,
  modalForm,
  enableValidateBank,
  permissionsChecker,
  agencies,
  selected_claim,
  onToggleClose,
  onChangeUpdateSelectedClaim,
  onChangeClaimsHOC,
  updateSelectedClaim,
}) => {
  const { can_update } = permissionsChecker;

  let selectedAgency = _.find(agencies, {
    id: selected_claim.external_agency_id,
  });
  let tmpDictionaryRepresentative = co_broker_representative_types(selected_claim.sub_sale_type_id).map(
    (item) => ({ ...item, value: item.id, label: item.name }),
  );
  let tmpSelectedAgency = selectedAgency ? [selectedAgency] : [];

  const renderSaveButton = data => (
    <CustomButton
      className={"btn-new btn-new--success"}
      tooltip={false}
      onClick={() =>
        updateSelectedClaim(
          data.id,
          data,
          false
        )
      }
      children={"Save Changes"}
    />
  )

  const renderExternalAgentInfoFields = data => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? dataReducer.currentSignInProfileReducer
      : dataReducer.profileReducer;

    return (
      <div className="at-form__content grid-control mb-0" style={{ gridGap: 16 }}>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Agency"}</h2>
          <CustomTypeahead
            placeholder="Enter Agency"
            typeaheadId={"agencies"}
            disabled={!can_update}
            options={agencies}
            selectedValue={tmpSelectedAgency}
            labelKey={(option) => `${option.label}`}
            onSelect={(val) => {
              if (val && val.length > 0) {
                onChangeUpdateSelectedClaim("external_agency_id", val[0].id);
              } else {
                onChangeUpdateSelectedClaim("external_agency_id", "");
              }
            }}
            childrenHead={(rowItem, index) => <p key={index}>{rowItem.label}</p>}
            highlighterData={[
              (option) =>
                `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
              <br />,
              (option) => option.address,
            ]}
            showClearButton={true}
            onClear={() => {
              let tmp = _.cloneDeep(data);
              tmp.external_agency_fee_amount = 0;
              tmp.external_agency_bank_id = 0;
              tmp.external_agency_bank_account_number = "";
              tmp.external_agency_representative_id = "";
              tmp.external_agency_id = "";
              return onChangeClaimsHOC("selected_claim", tmp);
            }}
          />
        </section>
        {tmpDictionaryRepresentative &&
          tmpDictionaryRepresentative.length > 0 && (
            <section className="grid-half-col">
              <h2 className="at-form-input__title">{"Represents"}</h2>
              <AtlasRadioGroup
                checkedValue={data.external_agency_representative_id}
                selectedRadioValue={(val) =>
                  onChangeUpdateSelectedClaim(
                    "external_agency_representative_id",
                    val,
                  )
                }
                options={tmpDictionaryRepresentative}
                // Coagency typed form not allowed to change representative once new form has been created
                disabled={(selected_claim.form_type_id === 3 && role !== "Super Admin") || !can_update} 
              />
            </section>
        )}
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Agency Bank Name"}</h2>
          <AtlasSelect
            rootClass={(enableValidateBank && !(selected_claim.external_agency_bank_id > 0)) ? "at-form-input--error" : ""}
            inputError={(enableValidateBank && !(selected_claim.external_agency_bank_id > 0)) ? "Cannot be empty" : ""}
            value={selected_claim.external_agency_bank_id || ""}
            disabled={!can_update}
            onChange={(e) => onChangeUpdateSelectedClaim("external_agency_bank_id", e.target.value || 0)}>
            <MenuItem
              classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
              key={0}
              value={""}>
              Select Agency Bank Name
            </MenuItem>
            {banks?.map((item) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.id}
                value={item.id}
              >
                {item.name} - {item.country?.name ?? "N/A"}
              </MenuItem>
            ))}
          </AtlasSelect>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">
            {"Agency Bank Account Number"}
          </h2>
          <AtlasInput
            type="text"
            placeholder="Enter Agency Bank Account Number"
            inputError={(enableValidateBank && selected_claim.external_agency_bank_account_number === "") ? "Cannot be empty" : ""}
            disabled={!can_update}
            value={`${data.external_agency_bank_account_number}`}
            onChangeValue={(val) =>
              onChangeUpdateSelectedClaim(
                "external_agency_bank_account_number",
                val,
              )
            }
          />
        </section>
        {(!modalForm && can_update ) && (
          <section className="grid-full-col">
            {renderSaveButton(data)}
          </section>
        )}
      </div>
    )
  } 
  const renderOppositeClaimInfoFields = data => {
    if( data !== null ) {
      const {
        external_agency_id,
        external_agency_representative_id,
        external_agency_bank_id,
        external_agency_fee_amount,
        external_agency_bank_account_number
      } = data
  
      const { label: externalAgentLabel = '' } = agencies.find(({ id }) => id === external_agency_id) || {};
      const { label: externalAgenRepresentativeLabel = '' } = tmpDictionaryRepresentative.find( ({id}) => id === external_agency_representative_id ) || {}
      const { name: bankLabel = '' } = banks.find(({ id }) => id === external_agency_bank_id) || {};

      return (
        <div className="at-form__content mt-3">
          <div className="at-subsales_claims-agency-fee-cont-disabled grid-control" style={{ display: 'grid', gridGap: 16 }}>
            <section className="grid-half-col">
              <h2 className="at-form-input__title">{"Agency"}</h2>
              <p>{externalAgentLabel || '-'}</p>
            </section>
            {tmpDictionaryRepresentative &&
              tmpDictionaryRepresentative.length > 0 && (
                <section className="grid-half-col">
                  <h2 className="at-form-input__title">{"Represents"}</h2>
                  <p>{externalAgenRepresentativeLabel || '-'}</p>
                </section>
            )}
            <section className="grid-half-col">
              <h2 className="at-form-input__title">{"Agency Bank Name"}</h2>
              <p>{bankLabel || '-'}</p>
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title">
                {"Agency Bank Account Number"}
              </h2>
              <p>{external_agency_bank_account_number || '-'}</p>
            </section>
          </div>
        </div>
      )
    }
    return ''
  } 
  
  return (
    <>
      {renderExternalAgentInfoFields(selected_claim)}
      {!modalForm && renderOppositeClaimInfoFields(selected_claim.opposite_claim)}
      <AlertBox
        mode={"info"}
        containerClass="my-3"
        description={
          <>
            Search and select the Agency from the dropdown list (If it’s not available, ask your branch admin to add the co-agency). 
            Then, choose who the co-agent represents. <b>Enter the Agency Bank details if IQI needs to pay a commission or issue a refund to the co-agency.</b> Lastly, click “Save Changes” to complete.
          </>
        }
      />
      {(modalForm && can_update ) && (
        <div className="at-modal_dialog-container-footer g-3">
          {renderSaveButton(selected_claim)}
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => onToggleClose(from === "Agency Fee")}>
            Close
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(ExternalAgent);
