import React, { Component } from "react";
import _ from "lodash";
import { GetFileWithoutSave, GetFile, Put, Get } from "utils/axios";

import { getItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedFile: {},
      specialRemarks: [],
      showPreviewModal: false,
      downloadedDocFile: {},
      showDownloadPreviewModal: false
    };

    load = (param) => this.setState({ loading: param });
    onChangeAgreementHOC = (key, val) => this.setState({ [key]: val });

    getSpecialRemarks = () => Get(
      `/rental_special_remarks`,
      this.getSpecialRemarksSuccess,
      this.getSpecialRemarksError,
      this.load,
    )
    getSpecialRemarksSuccess = (response) => this.setState({ specialRemarks: response });
    getSpecialRemarksError = (error) => requestError(error);

    getStampingFeeDoc = () => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);

      this.setState({ loading: true });
      return fetch(
        `${getDomainURL()}/nextcloud/directories/679?file_path=projects-lite/Secondary%20Market/Stamping%20Fee%20Schedule.pdf&download=1`,
        { headers },
      ).then((response) => {
        if (response.status === 200) {
          response.blob().then((blobby) => {
            let url = URL.createObjectURL(blobby);
            let tmp = {
              display_name: "Stamping Fee Schedule.pdf",
              file_type: ".pdf",
              url: url,
              link: `${getDomainURL()}/nextcloud/directories/679?file_path=projects-lite/Secondary%20Market/Stamping%20Fee%20Schedule.pdf&download=1`
            };
            this.setState({
              loading: false,
              showPreviewModal: true,
              selectedFile: tmp,
            });
          });
        } else {
          this.load(false)
          requestError(response.statusText);
        }
      })
      .catch((error) => {
        requestError("Failed to download file. Please try again later.");
      });
    }

    updateTenancyAgreement = (id, dataToSubmit) => Put(
      `/admin/forms/confirmation_forms/${id}/update_tenancy_agreement_info`,
      dataToSubmit,
      this.updateTenancyAgreementSuccess,
      this.updateTenancyAgreementError,
      this.load,
    )
    updateTenancyAgreementSuccess = (response) => {
      this.props.getConfirmationForm(response.id)
      requestSuccess("Tenancy Agreement updated successfully");
    }
    updateTenancyAgreementError = (error) => requestError(error);

    downloadTenancyAgreement = (id, form_number) => GetFile(
      `/admin/forms/confirmation_forms/${id}/download_tenancy_agreement`,
      `Tenancy_Agreement_${form_number}`,
      () => {},
      this.downloadTenancyAgreementError,
      this.load,
    )
    downloadTenancyAgreementError = (error) => requestError(error);

    previewTenancyAgreement = (id, form_number) => GetFileWithoutSave(
      `/admin/forms/confirmation_forms/${id}/download_tenancy_agreement`,
      `Tenancy_Agreement_${form_number}`,
      payload => {
        let tmp = {
          display_name: `Tenancy_Agreement_${form_number}`,
          file_type: ".pdf",
          url: URL.createObjectURL(payload.blob)
        };
        this.setState({downloadedDocFile: tmp, showDownloadPreviewModal: true});
      },
      this.previewTenancyAgreementError,
      this.load,
    )
    previewTenancyAgreementError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadTenancyAgreement={this.state.loading}

            getStampingFeeDoc={this.getStampingFeeDoc}
            getSpecialRemarks={this.getSpecialRemarks}
            onChangeAgreementHOC={this.onChangeAgreementHOC}
            updateTenancyAgreement={this.updateTenancyAgreement}
            downloadTenancyAgreement={this.downloadTenancyAgreement}
            previewTenancyAgreement={this.previewTenancyAgreement}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
