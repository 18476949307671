import _ from "lodash";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Red" },
];

export const columnData = [
  {
    header: "Project Name",
    accessor: "name",
    columnStyle: { width: "135%" },
  },
  {
    header: "Internal Name",
    accessor: "internal_name",
    columnStyle: { width: "135%" },
  },
  {
    header: "Developer",
    accessor: "developer",
  },
  {
    header: "Title",
    accessor: "project_title",
    columnStyle: { width: "80%" },
  },
  {
    header: "Slug",
    accessor: "slug",
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];
