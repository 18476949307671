import React from "react";

import SVGIcon from "./components/Icon";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import TooltipWrapper from "./components/Tooltip";

import { numberWithCommas } from "utils/thousandSeparator";

const SpecsData = [
  {
    propsName: "bedrooms_label",
    label: "Bedrooms",
    icon: `svg-icon-bed`,
    iconName: "icon-bed",
  },
  {
    propsName: "bathrooms_label",
    label: "Bathrooms",
    icon: `svg-icon-bathroom`,
    iconName: "icon-bathroom",
  },
  {
    propsName: "carparks_label",
    label: "Car Parks",
    icon: `svg-icon-carpark`,
    iconName: "icon-carpark",
  },
];

const PropertyListingContent = ({
  id,
  listing_id,
  property_name,
  address,
  price,
  type,
  category,
  property,
  currency,
  min_price,
  max_price,
  min_build_up,
  max_build_up,

  updated_on,
  measurement,
  land_area,
  built_up,

  getPropertyDetails,

  ...props
}) => {
  const renderSpecs = () => {
    return (
      <div className="at-subsales__specs">
        <div className="at-subsales__props-features">
          {SpecsData.map((item) => (
            <SVGIcon
              itemID={`${id}-${props[item.propsName]}-${item.propsName}`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`${item.icon} mr-2`}
              iconName={item.iconName}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">
                    {item.label}
                  </span>
                </>
              }
              children={
                <span className="fw-400">{props[item.propsName] || "N/A"}</span>
              }
            />
          ))}
        </div>
        <div className={`at-subsales__props-built_up__land_area`}>
          <SVGIcon
            itemID={`${id}-${measurement}-builtUp`}
            containerClassName={`d-flex at-subsales__icon`}
            tooltip={true}
            iconClassName={`svg-icon-squarefeet mr-2`}
            iconName={`icon-squarefeet`}
            tooltipLabel={
              <span className="at-subsales_tooltip-title">
                Built Up / Land Area
              </span>
            }
            children={
              props.property_type === "project" ? (
                <span className="fw-400">{`${min_build_up ? numberWithCommas(min_build_up) : "0"} - ${max_build_up ? numberWithCommas(max_build_up) : "0"}`}</span>
              ) : (
                <span className="fw-400">
                  {built_up ? numberWithCommas(built_up) + " " : "N/A"}
                  {land_area
                    ? " / " + numberWithCommas(land_area) + " " + measurement
                    : "/ N/A"}
                </span>
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="at-subsales__details-information m-0">
      <TooltipWrapper
        title={property_name}
        child={
          <h5
            style={{ width: "fit-content" }}
            className="fw-600 at-subsales__title"
          >
            {`${property_name}`}
          </h5>
        }
      />
      <TooltipWrapper
        title={address}
        child={
          <div
            style={{ width: "fit-content" }}
            className="d-flex align-items-center"
          >
            <AtlasIcon
              svgHref={"atlas-location"}
              style={{
                minWidth: 14,
                width: 14,
                height: 14,
                fill: "#F0631D",
                marginRight: 10,
              }}
            />
            <h6 className="at-subsales__address">{`${address}`}</h6>
          </div>
        }
      />
      <p className="at-subsales__price">
        {props.property_type === "project" && (
          <>
            <span className="mr-2" style={{ color: "#111827" }}>
              {currency}
            </span>
            {min_price && min_price > 0 ? numberWithCommas(min_price) : "0"}
            <span className="mr-2" style={{ color: "#111827" }}>
              - {currency}
            </span>
            {max_price && max_price > 0 ? numberWithCommas(max_price) : "0"}
          </>
        )}
        {props.property_type === "listing" && (
          <>
            <span className="mr-2" style={{ color: "#111827" }}>
              {currency}
            </span>
            {price && price > 0 ? numberWithCommas(price) : "N/A"}
          </>
        )}
      </p>
      <div className="at-subsales-props__cont">
        {renderSpecs()}
        <div className="at-subsales__info">
          {type && type !== "N/A" && (
            <TooltipWrapper title={"Type"} child={<span>{type}</span>} />
          )}
          {category && category !== "N/A" && (
            <TooltipWrapper
              title={"Property Type"}
              child={<span>{category}</span>}
            />
          )}
          {property && property !== "N/A" && (
            <TooltipWrapper
              title={"Property Type"}
              child={<span>{property}</span>}
            />
          )}
        </div>
      </div>
      <CustomButton
        className="btn-new-sm btn-new--outline-secondary"
        style={{ padding: "6px 12px", fontSize: 14 }}
        onClick={() => getPropertyDetails(listing_id)}
        children={
          <>
            <AtlasIcon
              svgHref={"atlas-info-circle"}
              style={{
                width: 16,
                height: 16,
                marginRight: 10,
                fill: "#3B82F6",
              }}
            />
            {props.property_type === "project"
              ? "Project Details"
              : "Listing Details"}
          </>
        }
      />
    </div>
  );
};

export default PropertyListingContent;
