import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { getBranches } from "actions/branch";
import { Get, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      branchArray: this.props.data.branchReducer.branch,
      loading: false,

      showCreateModal: false,
      showDetailModal: false,
      showDeleteModal: false,
      showEditModal: false,

      personalAssistantsList: [],
      selectedPersonalAssistant: {},
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    getPAList = () =>
      Get(
        `/personal_assistants`,
        this.getPAListSuccess,
        this.getPAListFailed,
        this.load,
      );
    getPAListSuccess = (payload) =>
      this.setState({ personalAssistantsList: payload || [] });
    getPAListFailed = (error) => requestError(error);

    deletePA = (id) =>
      Delete(
        `/personal_assistants/${id}`,
        this.deletePASuccess,
        this.deletePAError,
        this.load,
      );
    deletePASuccess = (payload) => {
      this.setState({ showDeleteModal: false });
      this.getPAList();
      requestSuccess(payload.message);
    };
    deletePAError = (error) => requestError(error);

    load = (param) => this.setState({ loading: param });

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            branchArray={this.state.branchArray}
            onLoadMyPersonalAssistant={this.state.loading}
            showCreateModal={this.state.showCreateModal}
            showEditModal={this.state.showEditModal}
            showDeleteModal={this.state.showDeleteModal}
            showDetailModal={this.state.showDetailModal}
            personalAssistantsList={this.state.personalAssistantsList}
            selectedPersonalAssistant={this.state.selectedPersonalAssistant}
            onChangeHOC={this.onChangeHOC}
            deletePA={this.deletePA}
            getPAList={this.getPAList}
          />
        </Fragment>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getBranches,
  })(WithHOC);
};

export default HOC;
