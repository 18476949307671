import React, { Component } from "react";
import _ from "lodash";
import { FormControl } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import ItemList from "./itemList";
import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import CustomTextarea from "components/Input/textarea";
import CustomImageUploader from "components/Input/imageUpload.js";

import CreateProductHOC from "../actions/create";
import isEmptyValue from "utils/isEmpty";

const typeDictionary = [
  { id: "1", label: "Starter Kit" },
  { id: "2", label: "Name Card" },
  { id: "3", label: "Event Ticket" },
  { id: "4", label: "Regular" },
];

const statusDictionary = [
  { id: "1", label: "Active" },
  { id: "2", label: "Inactive" },
];

class CreateDialog extends Component {
  pond = null;

  componentDidMount = () => {
    let tempCountry = _.find(this.props.data.dictionaryReducer.countries, {
      name: "Malaysia",
    });
    this.props.onChangeCreateProductHOC(
      {
        ...this.props.createNewProduct,
        country_id: tempCountry,
      },
      "createNewProduct",
    );
  };

  onAddSubItem = () => {
    const tmp = _.cloneDeep(this.props.createNewProduct);
    tmp.market_product_attached_items_attributes.push({ item_name: "" });
    this.props.onChangeCreateProductHOC(tmp, "createNewProduct");
  };

  onCheckCreateButton = (product) => {
    let tmpProduct = _.cloneDeep(product);

    delete tmpProduct.photo;
    delete tmpProduct.photo_file_name;
    delete tmpProduct.description;

    return _.values(tmpProduct).some((productValue) => {
      if (Array.isArray(productValue)) {
        if (productValue.length === 0) {
          return false;
        }
        return productValue.some((item) => {
          return _.values(item).some((value) => isEmptyValue(value));
        });
      }

      return isEmptyValue(productValue);
    });
  };

  onChangeCreateData = (val, context) => {
    let temp = _.cloneDeep(this.props.createNewProduct);
    if (context === "has_variants" && val) {
      temp.price = "0";
    } else if (context === "type_id" && ["1", "3"].indexOf(val) > -1) {
      temp.has_variants = false;
    }
    temp[context] = val;
    this.props.onChangeCreateProductHOC(temp, "createNewProduct");
  };

  onChangeFile = (fileItems) => {
    let temp = _.cloneDeep(this.props.createNewProduct);

    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          temp.photo_file_name = fileItems[0].file.name;
          temp.photo = e.target.result;
          this.props.onChangeCreateProductHOC(temp, "createNewProduct");
        };

        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      temp.photo = "";
      temp.photo_file_name = "";
      this.props.onChangeCreateProductHOC(temp, "createNewProduct");
    }
  };

  onRemoveFile = () => {
    let temp = _.cloneDeep(this.props.createNewProduct);
    temp.photo = "";
    temp.photo_file_name = "";

    this.props.onChangeCreateProductHOC(temp, "createNewProduct");
  };

  render = () => {
    const {
      createNewProduct,
      createNewProduct: {
        name,
        price,
        unit_measurement,
        country_id,
        type_id,
        description,
        status_id,
        photo,
        photo_file_name,
        has_variants,
        allow_pre_order,
        is_quantity_fixed,
        is_complimentary,
        delivery_branch_selectable,
        market_product_attached_items_attributes = [],
      },
      createProduct,
      onLoadProductMngmt,
    } = this.props;

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => this.props.onClose()} />
          <h4 className="at-card__title">New Product</h4>
        </div>
        <div className={"row"}>
          <div className={"col-lg-6"}>
            <label className="at-form-input__title" required>
              {"Name"}
            </label>
            <CustomFormInput
              type="text"
              value={name}
              onChangeValue={(val) => this.onChangeCreateData(val, "name")}
              required={true}
              placeholder={"Enter product name here"}
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Country"}
            </label>
            <CustomSelect
              className="mb-30 w-100"
              required={true}
              selectItems={this.props.data.dictionaryReducer.countries}
              currentlySelected={country_id}
              updateSelect={(val) => this.onChangeCreateData(val, "country_id")}
              placeholder={"Please choose a country"}
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Unit Price"}
            </label>
            {!has_variants && (
              <CustomFormInput
                type="number"
                value={price}
                onChangeValue={(val) => this.onChangeCreateData(val, "price")}
                required={true}
                placeholder={"Enter product's price here"}
              />
            )}
            {has_variants && (
              <p style={{ fontSize: 14, fontWeight: 500, color: "#D92D20" }}>
                You may setup the price in the variants list
              </p>
            )}
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Unit Measurement"}
            </label>
            <CustomFormInput
              type="text"
              value={unit_measurement}
              onChangeValue={(val) =>
                this.onChangeCreateData(val, "unit_measurement")
              }
              required={true}
              placeholder={"Enter product's unit measurement here"}
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Type"}
            </label>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              checkedValue={type_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                this.onChangeCreateData(val, "type_id")
              }
              options={typeDictionary}
            />
            <CustomCheckbox
              labelStyle={{ marginBottom: 10, display: "flex" }}
              checked={is_complimentary}
              content={"Complimentary (FOC)/For New REN"}
              onChangeCheckboxValue={(e) =>
                this.onChangeCreateData(e.target.checked, "is_complimentary")
              }
            />
            <CustomCheckbox
              labelStyle={{ marginBottom: 10, display: "flex" }}
              checked={is_quantity_fixed}
              content={"Fixed order quantity"}
              onChangeCheckboxValue={(e) =>
                this.onChangeCreateData(e.target.checked, "is_quantity_fixed")
              }
            />
            {type_id === "2" && (
              <CustomCheckbox
                labelStyle={{ marginBottom: 10, display: "flex" }}
                checked={has_variants}
                content={"This product has multiple options."}
                onChangeCheckboxValue={(e) =>
                  this.onChangeCreateData(e.target.checked, "has_variants")
                }
              />
            )}
            {type_id === "4" && (
              <CustomCheckbox
                labelStyle={{ marginBottom: 10, display: "flex" }}
                checked={allow_pre_order}
                content={"Pre-Order"}
                onChangeCheckboxValue={(e) =>
                  this.onChangeCreateData(e.target.checked, "allow_pre_order")
                }
              />
            )}
            <CustomCheckbox
              labelStyle={{ marginBottom: 20, display: "flex" }}
              checked={delivery_branch_selectable}
              content={"Delivery branch is required"}
              onChangeCheckboxValue={(e) =>
                this.onChangeCreateData(
                  e.target.checked,
                  "delivery_branch_selectable",
                )
              }
            />
          </div>
          <div className={"col-lg-6 mt-2"}>
            <label className={"at-form-input__title"} required>
              {"Description"}
            </label>
            <CustomTextarea
              required={true}
              rows="3"
              type="text"
              value={description}
              onChangeValue={(value) =>
                this.onChangeCreateData(value, "description")
              }
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div className={"col-lg-6 mt-2 mb-20"}>
            <FormControl style={{ width: "100%" }}>
              <label className={"at-form-input__title"} required>
                Photo
              </label>
              <CustomImageUploader
                fullWidth
                required={true}
                ref={(ref) => (this.pond = ref)}
                files={
                  photo
                    ? [
                        {
                          source: photo,
                          name: photo_file_name,
                        },
                      ]
                    : []
                }
                allowMultiple={false}
                acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                accept="image/png, image/jpeg, image/jpg"
                maxFiles={1}
                onRemoveFile={this.onRemoveFile}
                onChangeFile={(fileItems) => this.onChangeFile(fileItems)}
              />
            </FormControl>
          </div>
          {type_id && type_id === "1" && (
            <div className="col-lg-6 pl-lg-5 pt-3">
              <div className="d-flex p-1 grid_gap-2">
                <CustomButton
                  className="btn-new btn-new--primary"
                  onClick={this.onAddSubItem}
                  disabled={false}
                  children={
                    <>
                      <IoMdAdd
                        style={{ height: 18, width: 18, marginRight: 5 }}
                      />
                      Add new sub-item
                    </>
                  }
                />
              </div>
              <div>
                {market_product_attached_items_attributes.map((item, index) => (
                  <ItemList
                    key={index}
                    disabled={false}
                    onClickDelete={(e) => {
                      const tmp = _.cloneDeep(
                        market_product_attached_items_attributes,
                      );
                      tmp.splice(index, 1);
                      this.onChangeCreateData(
                        tmp,
                        "market_product_attached_items_attributes",
                      );
                    }}
                    onChangeText={(e) => {
                      const tmp = _.cloneDeep(
                        market_product_attached_items_attributes,
                      );
                      tmp[index].item_name = e;
                      this.onChangeCreateData(
                        tmp,
                        "market_product_attached_items_attributes",
                      );
                    }}
                    value={item.item_name}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="col-lg-6 mt-5 mb-10">
            <label className={"at-form-input__title"} required>
              {"Status"}
            </label>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              checkedValue={status_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                this.onChangeCreateData(val, "status_id")
              }
              options={statusDictionary}
            />
          </div>
        </div>
        <div className="d-flex mt-20 g-3">
          <button
            disabled={this.onCheckCreateButton(this.props.createNewProduct)}
            className={"btn-new btn-new--success"}
            onClick={() => createProduct(createNewProduct)}
          >
            Create
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onClose()}
          >
            Cancel
          </button>
        </div>
        {onLoadProductMngmt && <LoadingModal />}
      </>
    );
  };
}

export default CreateProductHOC(CreateDialog);
