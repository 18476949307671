import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import CustomCard from "components/Card";
import CustomTable from "components/NewTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomSearchForm from "components/Form/search";

import SalesHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";
import { columnData } from "./asset";

import "./index.scss";

const statData = [
  {
    label: "Total Unit",
    value: "totalUnits",
    icon: "buildings-2",
    color: "#D946EF",
  },
  {
    label: "Total Converted Unit",
    value: "totalConvertedUnits",
    icon: "building-4",
    color: "#6875F5",
  },
  {
    label: "Total Cancelled Unit",
    value: "totalCancelledUnits",
    icon: "forbidden-2",
    color: "#E74694",
  },
  {
    label: "Total Booked Unit",
    value: "totalBookedUnits",
    icon: "bookmark",
    color: "#06B6D4",
  },
  {
    label: "Total Sales Amount",
    value: "totalSalesAmount",
    icon: "moneys",
    color: "#14B8A6",
  },
  {
    label: "Total Converted Sales Amount",
    value: "totalConvertedSalesAmount",
    icon: "money-receive",
    color: "#84CC16",
  },
];

class ProjectSales extends Component {
  componentDidMount = () => {
    initGA("/dashboard/team/project-sales");
    this.loadProjectSales();
  };

  loadProjectSales = () => {
    const { team_name } = this.props;

    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    let isAdmin = ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) > -1;

    this.props.onChangeSalesHOC(
      isAdmin ? `${team_name}'s Project Sales` : "Project Sales",
      "title",
    );
    this.props.getProjectSales(
      isAdmin
        ? this.props.encoded_identity
        : tmpProfileReducer.encoded_identity,
      this.props.startDate,
      this.props.endDate,
    );
  };

  onSelectStartDate = (val) => this.props.onChangeSalesHOC(val, "startDate");

  onSelectEndDate = (val) => this.props.onChangeSalesHOC(val, "endDate");

  renderAgentSearchTips = () => {
    return (
      <>
        <span className="fw-500">
          How can I filter the team project sales report by date range
        </span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          You can filter the report by date and click Search, to get your team
          project sales report for the selected period of time.
        </p>
      </>
    );
  };

  renderCardContent = () => {
    return (
      <>
        <div className="at-form__content mb-3" style={{ padding: 15 }}>
          <CustomSearchForm
            showHideAgentsCheckbox={false}
            onClickSubmit={() => this.loadProjectSales()}
            selectedStartDate={this.props.startDate}
            selectedEndDate={this.props.endDate}
            onSelectStartDate={(val) => this.onSelectStartDate(val)}
            onSelectEndDate={(val) => this.onSelectEndDate(val)}
          />
        </div>
        <div className="at-team-project-sales_stat-cont">
          {statData.map((item, index) => (
            <div key={index}>
              <div
                style={{ padding: 12 }}
                className="at-form__content d-flex align-items-center mb-0 h-100 w-100"
              >
                <div
                  className="at-card--stat__general-icon-cont"
                  style={{ backgroundColor: item.color }}
                >
                  <AtlasIcon svgHref={`atlas-${item.icon}`} />
                </div>
                <div className="at-card--stat__general-category mb-0">
                  <p>{item.label}</p>
                  <span>{this.props.totalData[item.value]}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="at-card-container">
          <CustomTable
            className={"team-sales__table"}
            columns={columnData}
            pagination={true}
            rowData={this.props.projectSales || []}
          />
        </div>
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={this.props.title}
          atlasIcon={"atlas-project-sales"}
          description={`This report helps you to summarise your team project performance. Which will allow you to analyse your team focus, 
            strength and weakness in different type of project and target market. `}
          showPABadge={role === "Personal Assistant"}
          infoBoxContent={this.renderAgentSearchTips}
          infoBoxInactiveContent={
            "How can I filter the team project sales report by date range"
          }
          backButton={{
            onShow: ["Super Admin", "Admin"].indexOf(role) > -1,
            onClick: () => this.props.clickBack(),
          }}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadSales && <LoadingModal />}
      </>
    );
  };
}

export default compose(SalesHOC, VersionCheckHOC)(ProjectSales);
