import React, { Component } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import CustomTableHeader from "./header";
import CustomTableContent from "./content";

import { modulesPermissions } from "../permissionLists";
import "stylesheets/components/table/index.scss";
import "../index.scss";

class CustomTable extends Component {
  constructor(p) {
    super();
    this.state = {
      searching: false,
      searchKeyword: "",
      selectPerPageOption: 10,
      showAllTableList: false,
      selectHeaderData: "",
      sortStatus: "",
      paginationInitialCount: 1,
      totalPaginationCount: 0,
      totalPages: [1],
      clickPageNumber: 1,
      headerRearrangeData: {},
      rowData: [],
      initialRowData: [],
      searchWithField: "",
      searchWithFieldDisplay: "",
    };
    this.debounceService = _.debounce(this.debounceService, 300);
  }

  componentDidUpdate = (prevProps) => {
    let listNumbers =
      this.props.rowData.length > 0 ? this.props.rowData.length : 10;

    if (this.props.rowData.length !== prevProps.rowData.length) {
      this.setState({
        showAllTableList: this.props.showAllTableList,
      });
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
          selectPerPageOption: this.state.showAllTableList ? listNumbers : 10,
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption
          )
      );
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;

        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          item &&
            Object.keys(item).map((key) => {
              if (tmp[key] !== item[key]) {
                dataUpdated = true;
              }
            });
        });
        if (dataUpdated) {
          this.setState({
            showAllTableList: this.props.showAllTableList,
          });
          return this.setState(
            {
              initialRowData: this.props.rowData,
              selectPerPageOption: this.state.showAllTableList
                ? listNumbers
                : 10,
            },
            () => {
              if (this.state.searchKeyword === "") {
                this.setState(
                  {
                    rowData: this.props.rowData,
                  },
                  () =>
                    this.processPagesData(
                      this.props.rowData,
                      this.state.selectPerPageOption
                    )
                );
              } else {
                this.debounceService(this.state.searchKeyword);
              }
            }
          );
        }
      }
    }
  };

  componentDidMount = async () => {
    let listNumbers =
      this.props.rowData.length > 0 ? this.props.rowData.length : 10;

    await this.setState({
      showAllTableList: this.props.showAllTableList,
    });

    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
        selectPerPageOption: this.state.showAllTableList ? listNumbers : 10,
        searchWithField: this.props.searchWithField
          ? this.props.searchWithField
          : this.props.headerData[0].value,
        searchWithFieldDisplay: this.props.searchWithField
          ? this.props.searchPlaceholder
          : this.props.headerData[0].label,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption
        );
      }
    );
  };

  debounceService = (searchVal) => {
    const { searchWithField } = this.state;
    let result = _.filter(this.state.initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });
    return this.setState(
      {
        rowData: result,
        searching: false,
      },
      () =>
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption
        )
    );
  };

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    return this.setState(
      {
        searchKeyword: searchVal,
      },
      () => {
        if (this.state.searchKeyword === "") {
          this.setState(
            {
              rowData: this.state.initialRowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption
              )
          );
        } else {
          this.debounceService(searchVal);
        }
      }
    );
  };

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode
          ),
        }));
      }
    });

  selectSearchHeader = (val) =>
    this.setState({
      searchWithField: val.value,
      searchWithFieldDisplay: val.label,
    });

  renderTableContent = () => {
    const { headerData, selectedPermissionGroup } = this.props;
    if (this.state.rowData) {
      return this.state.rowData.map((rowItem, index) => {
        return (
          <CustomTableContent
            selectedPermissionGroup={selectedPermissionGroup}
            key={index}
            headerData={headerData}
            headerRearrangeData={this.state.headerRearrangeData}
            actionColumnContent={this.props.actionColumnContent}
            modulesPermissions={modulesPermissions}
            rowItem={rowItem ? rowItem : {}}
            onToggleCheckBox={this.props.onToggleCheckBox}
          />
        );
      });
    }
  };

  render = () => {
    const { headerData, actionColumn, actionColumnContent } = this.props;

    return (
      <>
        {this.props.rowData.length === 0 && this.props.rowData ? (
          <div>No record found</div>
        ) : (
          <div className="at-permission-group__permission-table">
            <div className="grid-control w-100">
              <div className="grid-half-col">
                <div className="at-select__search-cont mt-0 mb-3">
                  <CustomSelect
                    className="at-select_new-interface"
                    selectItems={this.props.searchParams}
                    updateSelect={(val) => this.selectSearchHeader(val)}
                    placeholder={
                      this.props.searchPlaceholder
                        ? this.props.searchPlaceholder
                        : headerData[0].label
                    }
                  />
                  <input
                    type="search"
                    key={"searchInput"}
                    value={this.state.searchKeyword}
                    className={"at-table__search-input"}
                    placeholder={`Search with ${this.state.searchWithFieldDisplay}`}
                    onChange={(event) =>
                      this.onChangeSearchValue(event.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="at-table at-table--set">
              <CustomTableHeader
                data={headerData}
                actionColumn={actionColumn}
                actionColumnContent={actionColumnContent}
                onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
              />
              {this.renderTableContent()}
            </div>
          </div>
        )}
      </>
    );
  };
}

export default CustomTable;
