import React, { useEffect, useState, useRef } from "react";
import Moment from "moment";
import _ from "lodash";
import { HiCheck } from "react-icons/hi";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";

import CustomButton from "components/Button";
import CustomSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import StepIndicator from "components/StepIndicator";
import AtlasTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import CustomRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";

const bankOptions = [
  { label: "MBB - Maybank 564164441931 IQI REALTY SDN. BHD.", value: 1 },
  { label: "PBB - Public Bank 3197608334 IQI REALTY SDN. BHD.", value: 6 },
];

const relationshipOptions = subsalesTypeId => 
  subsalesTypeId === 1
  ? [
    { label: "Vendor", value: 1 },
    { label: "Purchaser", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Vendor/Purchaser's Sibling", value: 4 },
    { label: "Vendor/Purchaser's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]
  : [
    { label: "Landlord", value: 1 },
    { label: "Tenant", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Landlord/Tenant's Sibling", value: 4 },
    { label: "Landlord/Tenant's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]

const transferMethodRadioOptions = [
  {
    value: 2,
    label: "Cash",
  },
  {
    value: 1,
    label: "Cheque Deposit",
  },
  {
    value: 6,
    label: "Credit Card",
  },
  {
    value: 3,
    label: "Online Transfer",
  },
  {
    value: 5,
    label: "Foreign Transfer",
  },
];

const UploadBankDetail = ({
  onLoadOCR,
  OCRContent,
  onLoadDocuments,
  selectedDocType,
  selected_claim_id,
  subsalesTypeId,

  onClickClose,
  bankInSlipOCR,
  uploadMultipleDocuments,
}) => {
  const [step, setStep] = useState("1");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bankSlip, setBankSlip] = useState({
    date: "",
    amount: 0,
    bank_id: "",
    reference_no: "",
    snapshot: "",
    snapshot_file_name: "",
    payer_type_id: "", 
    payer_type_other: "", 
    payment_method_id: "",
  });

  useEffect(() => {
    if (!_.isEmpty(OCRContent)) {
      let temp = _.cloneDeep(bankSlip);
      temp = {
        ...temp,
        date: "",
        payment_method_id: OCRContent.payment_method_id > 0 
          ? OCRContent.payment_method_id 
          : "",
        amount: OCRContent.amount[0],
        bank_id: OCRContent.beneficiary.details[0]
          ? _.find(bankOptions, { label: OCRContent.beneficiary.details[0] })
              .value
          : "",
        reference_no: OCRContent.remark.toString().replace(",", ", "),
        
      };
      setBankSlip(temp);
    }
  }, [OCRContent]);

  const onChangeField = (context, val) => {
    let tmp = _.cloneDeep(bankSlip);
    tmp[context] = val;
    setBankSlip(tmp);
  };

  const uploadFile = (fileItems) => {
    if (fileItems) {
      Object.keys(fileItems).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = _.cloneDeep(bankSlip);
          tmp.snapshot = e.target.result;
          tmp.snapshot_file_name = fileItems[key].name;
          setBankSlip(tmp);
          bankInSlipOCR({ file: e.target.result }, () => setStep("2"));
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  const reUploadDocument = () => {
    setStep("1");
    setBankSlip({
      date: "",
      amount: 0,
      bank_id: "",
      reference_no: "",
      snapshot: "",
      snapshot_file_name: "",
    });
  };

  let filePond = useRef(null);

  return (
    <>
      <ModalDialog
        title={"Upload Bank in Slip"}
        onLoad={onLoadOCR}
        onClose={onClickClose}
        children={
          <div className="at-bankslip__container">
            <StepIndicator
              mode={"gradual"}
              progress={step}
              hideLabel={true}
              stepList={["1", "2"]}
              onClick={() => {}}
              lineStyle={step === "2" ? { backgroundColor: "#3B82F6" } : {}}
              containerStyle={{ width: 300, margin: "auto" }}
              renderStepIcon={(item) => {
                if (item === step) {
                  return (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        background: "#3B82F6",
                        borderRadius: "50%",
                      }}
                    ></div>
                  );
                } else if (item < step) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#3B82F6",
                        borderRadius: "50%",
                      }}
                    >
                      <HiCheck style={{ width: 32, height: 32, padding: 6 }} />
                    </div>
                  );
                }
              }}
            />
            {step === "1" && (
              <>
                <div
                  className="d-flex align-items-center grid_gap-2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  <div className="at-badge-icon at-badge-icon-primary">
                    <AtlasIcon svgHref={"atlas-receipt-1"} />
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        lineHeight: "150%",
                        color: "#1D4ED8",
                      }}
                    >
                      Step 1
                    </p>
                    Upload File
                  </div>
                </div>
                <p
                  style={{ color: "#374151", fontSize: 14, margin: "16px 0px" }}
                >
                  Upload a clear scan or picture of the transaction/bank-in slip
                  and Atlas will use new AI technology to automatically fill in
                  the details. Click Upload File and upload the PDF or image
                  file.
                </p>
                <div className="at-bankslip__upload">
                  <div className="at-badge-icon at-badge-icon-warning m-auto">
                    <AtlasIcon svgHref={"atlas-receipt-1"} />
                  </div>
                  {(bankSlip.snapshot && bankSlip.snapshot_file_name) && (
                    <p
                      style={{
                        color: "#111827",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {bankSlip.snapshot_file_name}
                    </p>
                  )}
                  {!(bankSlip.snapshot && bankSlip.snapshot_file_name) && (
                    <>
                      <div>
                        <p
                          style={{
                            color: "#111827",
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          Single Transaction Bank in Slip
                        </p>
                        <p style={{ color: "#374151", fontSize: 14 }}>
                          PNG, JPG, JPEG, JFIF or PDF
                        </p>
                      </div>
                      <input
                        ref={(ref) => (filePond = ref)}
                        type="file"
                        style={{ display: "none" }}
                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                        onChange={(e) => uploadFile(e.target.files)}
                      />
                      <button
                        className="btn-new btn-new--secondary m-auto"
                        onClick={() => filePond.click()}
                      >
                        Upload a file
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
            {step === "2" && (
              <>
                <div
                  className="d-flex align-items-center grid_gap-2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  <div className="at-badge-icon at-badge-icon-primary">
                    <AtlasIcon svgHref={"atlas-task-square"} />
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        lineHeight: "150%",
                        color: "#1D4ED8",
                      }}
                    >
                      Step 2
                    </p>
                    Payment Information
                  </div>
                </div>
                <p
                  style={{ color: "#374151", fontSize: 14, margin: "16px 0px" }}
                >
                  Kindly check the auto-filled information and edit as needed if
                  any of it is incorrect.
                </p>
                <div className="mb-3">
                  <label className="at-form-input__title" required>
                    Uploaded File
                  </label>
                  <div
                    className="d-flex align-items-center grid_gap-2"
                    style={{ fontSize: 16, fontWeight: 500 }}
                  >
                    <div className="at-badge-icon at-badge-icon-warning">
                      <AtlasIcon svgHref={"atlas-receipt-1"} />
                    </div>
                    <p style={{ fontSize: 12, lineHeight: "150%", textWrapMode: "wrap" }}>
                      {bankSlip.snapshot_file_name}
                    </p>
                  </div>
                </div>
                <div style={{ display: "grid", gridGap: 10, gridTemplateColumns: "100%" }}>
                  <section>
                    <label className="at-form-input__title" required>
                      Payment Date
                    </label>
                    <div className="at-form-input">
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        withPortal
                        dateFormat={"DD-MM-YYYY"}
                        className="at-form-input__input"
                        maxDate={Moment()}
                        placeholderText="Please enter date"
                        value={bankSlip.date}
                        onChange={(val) =>
                          onChangeField(
                            "date",
                            Moment(val).format("DD-MM-YYYY"),
                          )
                        }
                      />
                    </div>
                  </section>
                  <section>
                    <label className="at-form-input__title" required>
                      Bank In To
                    </label>
                    <CustomSelect
                      attrClass="mb-0"
                      required={true}
                      value={bankSlip.bank_id}
                      placeholder={
                        "Select the bank to which client has made the transfer"
                      }
                      onChange={(e) => onChangeField("bank_id", e.target.value)}
                    >
                      {bankOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ fontSize: 14 }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </section>
                  <section>
                    <label className="at-form-input__title" required>
                      Amount
                    </label>
                    <CustomTypeahead
                      typeaheadId={"bankslip_amount"}
                      options={OCRContent.amount || []}
                      selectedValue={bankSlip.amount ? [bankSlip.amount] : []}
                      onSearch={(val) => onChangeField("amount", val)}
                      onSelect={(val) =>
                        val && val.length > 0 && onChangeField("amount", val[0])
                      }
                      childrenHead={(rowItem) => <p>{rowItem}</p>}
                    />
                  </section>
                  <section>
                    <label className="at-form-input__title" required>
                      Payment Made By (Relationship to Beneficiary)
                    </label>
                    <CustomSelect
                      attrClass="mb-0"
                      required={bankSlip.payer_type_id !== 6}
                      value={bankSlip.payer_type_id}
                      onChange={(e) => onChangeField("payer_type_id", e.target.value)}
                    >
                      {relationshipOptions(subsalesTypeId).map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ fontSize: 14 }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </section>
                  {bankSlip.payer_type_id === 6 && (
                    <section>
                      <CustomFormInput
                        footerClass="mb-0"
                        type="text"
                        value={bankSlip.payer_type_other}
                        onChangeValue={(val) => onChangeField("payer_type_other", val)}
                        required={true}
                        inputError={""}
                        placeholder={"Enter relationship"}
                      />
                    </section>
                  )}
                  <section>
                    <label className="at-form-input__title" required>
                      Transfer Method
                    </label>
                    <CustomRadioGroup
                      checkedValue={bankSlip.payment_method_id}
                      options={transferMethodRadioOptions}
                      selectedRadioValue={(val) => onChangeField("payment_method_id", val)}
                    />
                  </section>
                  <section>
                    <label className="at-form-input__title">
                      Reference (optional)
                    </label>
                    <AtlasTextarea
                      className={"mb-0"}
                      required={false}
                      value={bankSlip.reference_no}
                      onChangeValue={(val) =>
                        onChangeField("reference_no", val)
                      }
                      instruction={
                        "Please state payment reference for (instant transfer, foreign transfer & IBG)"
                      }
                    />
                  </section>
                </div>
              </>
            )}
          </div>
        }
        footer={
          <div className="d-flex g-3">
            <CustomButton
              disabled={ _.toPairs(bankSlip).some(([key, value]) => {
                if (key !== "reference_no") {
                  const relationshipBankHolderFilter = ((key === "payer_type_other") && (bankSlip["payer_type_id"] === 6))
                  if (relationshipBankHolderFilter || key !== "payer_type_other") {
                    return (value === "" || value === 0 || value === undefined)
                  }
                } 
                return false
              })}
              className={"btn-new btn-new--primary"}
              children={"Submit"}
              onClick={() => setShowConfirmation(true)}
            />
            {bankSlip.snapshot && bankSlip.snapshot_file_name && (
              <button
                className="btn-new btn-new--secondary"
                onClick={() => reUploadDocument()}
              >
                Re-upload
              </button>
            )}
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClickClose()}
            >
              Close
            </button>
          </div>
        }
      />
      <ConfirmationModal
        open={showConfirmation}
        title={"Confirm uploading bank in slip?"}
        message={
          <div>
            <p className="fs-2">
              Kindly ensure that only (1) bank-in slip is attached per upload.
            </p>
            <br />
            <p className="fs-2">
              If you have more than 1 slip, please repeat the step to upload
              another after upload this has been successfully submitted for
              review.
            </p>
            <br />
            <p className="fs-2">Are you sure you want to continue upload now?</p>
          </div>
        }
        loading={onLoadDocuments}
        positiveAction={() =>
          Promise.all([
            uploadMultipleDocuments(selected_claim_id, [
              {
                ...bankSlip,
                transacted_at: Moment(
                  bankSlip.date,
                  "DD-MM-YYYY HH:mm",
                ).toString(),
                type_id: selectedDocType,
                done: false,
                success: false,
                errorMessage: "",
                loading: true,
              },
            ]),
          ]).then(() => {
            onClickClose();
          })
        }
        negativeAction={() => setShowConfirmation(false)}
      />
      {onLoadOCR && <LoadingModal />}
    </>
  );
};

export default UploadBankDetail;
