import React from "react";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";

import AtlasIcon from "components/Icon/atlasIcon";
import CampaignTrackerStat from "./campaignTrackerStat";

import { numberWithCommas } from "utils/thousandSeparator";
import "react-circular-progressbar/dist/styles.css";

const teamSizeProgress = {
  path: {
    stroke: "#6875F5",
    strokeLinecap: "round",
  },
  trail: {
    stroke: "#D1D5DB",
    strokeLinecap: "butt",
  },
  text: {
    fill: "#1F2937",
    fontSize: "20px",
    fontWeight: "600",
  },
};

const chartText = {
  id: "text-center",
  afterDatasetsDraw(chart, args, options) {
    const { ctx, data, active, height, width } = chart;
    const isZero = _.values(chart.data.datasets[0].data).every(
      (item) => item === 0 || item === "NaN",
    );

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#1F2937";
    ctx.font = "bold 12px sans-serif";
    ctx.save();
    if (isZero) {
      ctx.fillText(`0%`, width / 2, height / 2);
    } else if (active && active.length) {
      const dataValue = data.datasets[0].data[active[0]._index];
      const label = data.labels[active[0]._index];
      const labelArray = label.split(" ");
      ctx.fillText(`${dataValue}%`, width / 2, height / 2 - 15);
      labelArray.forEach((word, index) => {
        ctx.fillText(word, width / 2, height / 2 + index * 10);
      });
    } else {
      ctx.fillText("Total", width / 2, height / 2 - 5);
      ctx.fillText("Team Sales", width / 2, height / 2 + 5);
    }
  },
};

const emptyDoughnut = {
  id: "emptyDoughnut",
  afterDraw(chart, args, options) {
    const { color, width, radiusDecrease } = options;
    const isZero = _.values(chart.data.datasets[0].data).every(
      (item) => item === 0 || item === "NaN",
    );

    if (isZero) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
};

const GeneralDataContent = ({
  viewTeamSale,
  selectedAgent,
  personalSummary,
  renderAgentDetails,
  onChangeCampaignTrackerHOC,
  getSelectedPromotion,
}) => {
  const {
    agentScore,
    numberOfAgents,
    numberOfAgentsSoldUnit,

    totalTeamRentalSales,
    totalTeamSubsaleSales,
    totalTeamProjectSales,
    totalLeaderAndTeamSalesAmount,
  } = selectedAgent;

  const renderIMS = (gridArea) => (
    <div
      className={`at-form__content d-flex align-items-center`}
      style={{ gridArea: gridArea }}
    >
      <div
        className={`at-card--stat__general-icon-cont`}
        style={{ backgroundColor: "#F59E0B" }}
      >
        <AtlasIcon svgHref={`atlas-star-1`} />
      </div>
      <div className="at-card--stat__general-category">
        <p>IMS</p>
        <span>{agentScore}</span>
      </div>
    </div>
  );

  const renderTeamSize = (gridArea) => (
    <div className={`at-form__content d-flex`} style={{ gridArea: gridArea }}>
      <div
        className={`at-card--stat__general-icon-cont`}
        style={{ backgroundColor: "#6875F5" }}
      >
        <AtlasIcon svgHref={`atlas-profile2user`} />
      </div>
      <div>
        <div className="at-card--stat__general-category">
          <p>Team Size</p>
          <span>{numberOfAgents}</span>
        </div>
        <div className="at-card--stat__general-category">
          <p>
            <div
              className="at-card--stat-dot"
              style={{ backgroundColor: "#648FFF" }}
            ></div>
            Active Agent
          </p>
          <span>{numberOfAgentsSoldUnit}</span>
        </div>
      </div>
      <div style={{ width: 60, height: 60, marginLeft: "auto", marginTop: 40 }}>
        <CircularProgressbar
          styles={teamSizeProgress}
          value={(numberOfAgentsSoldUnit / numberOfAgents) * 100}
          text={
            numberOfAgentsSoldUnit && numberOfAgents
              ? `${Math.round((numberOfAgentsSoldUnit / numberOfAgents) * 100)}%`
              : "0%"
          }
        />
      </div>
    </div>
  );

  if (!viewTeamSale && renderAgentDetails) {
    return (
      <div
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "repeat(1, auto)",
        }}
        className="at-statistic-general-container"
      >
        {renderAgentDetails("1 / 1 / 2 / 2")}
        {renderIMS("1 / 2 / 2 / 3")}
        {renderTeamSize("1 / 3 / 2 / 4")}
      </div>
    );
  } else {
    return (
      <div className="at-statistic-general-container">
        {renderIMS("1 / 1 / 2 / 2")}
        {renderTeamSize("2 / 1 / 3 / 2")}
        {viewTeamSale && (
          <>
            <div
              className="at-form__content"
              style={{ gridArea: "3 / 1 / 4 / 2" }}
            >
              <h2>Team Sales in {new Date().getFullYear()}</h2>
              <div className="d-flex">
                <div
                  className={`at-card--stat__general-icon-cont`}
                  style={{ backgroundColor: "#14B8A6" }}
                >
                  <AtlasIcon svgHref={`atlas-buildings-2`} />
                </div>
                <div className="at-card--stat__general-category">
                  <p>Total Team Sales (including personal sales)</p>
                  <span>{numberWithCommas(totalLeaderAndTeamSalesAmount)}</span>
                </div>
              </div>
              <hr />
              <h3>TEAM SALES BY TYPE</h3>
              <div className="d-flex flex-wrap">
                <div>
                  <div className="at-card--stat__general-category ml-0">
                    <p>
                      <div
                        className="at-card--stat-dot"
                        style={{ backgroundColor: "#648FFF" }}
                      ></div>
                      Team Project Sales
                    </p>
                    <span>
                      {numberWithCommas(totalTeamProjectSales)} (
                      {totalLeaderAndTeamSalesAmount
                        ? (
                            (totalTeamProjectSales /
                              totalLeaderAndTeamSalesAmount) *
                            100
                          )?.toFixed(2)
                        : 0}
                      %)
                    </span>
                  </div>
                  <div className="at-card--stat__general-category ml-0">
                    <p>
                      <div
                        className="at-card--stat-dot"
                        style={{ backgroundColor: "#FFB000" }}
                      ></div>
                      Total Team Subsales - Sale
                    </p>
                    <span>
                      {numberWithCommas(totalTeamSubsaleSales)} (
                      {totalLeaderAndTeamSalesAmount
                        ? (
                            (totalTeamSubsaleSales /
                              totalLeaderAndTeamSalesAmount) *
                            100
                          )?.toFixed(2)
                        : 0}
                      %)
                    </span>
                  </div>
                  <div className="at-card--stat__general-category ml-0">
                    <p>
                      <div
                        className="at-card--stat-dot"
                        style={{ backgroundColor: "#DC267F" }}
                      ></div>
                      Total Team Subsales - Rental
                    </p>
                    <span>
                      {numberWithCommas(totalTeamRentalSales)} (
                      {totalLeaderAndTeamSalesAmount
                        ? (
                            (totalTeamRentalSales /
                              totalLeaderAndTeamSalesAmount) *
                            100
                          )?.toFixed(2)
                        : 0}
                      %)
                    </span>
                  </div>
                </div>
                <div className="at-card--stat__doughnut-cont">
                  <Doughnut
                    data={{
                      labels: [
                        "Project Sales",
                        "Subsales - Sale",
                        "Subsales - Rental",
                      ],
                      datasets: [
                        {
                          data: [
                            (
                              (totalTeamProjectSales /
                                totalLeaderAndTeamSalesAmount) *
                              100
                            ).toFixed(2),
                            (
                              (totalTeamSubsaleSales /
                                totalLeaderAndTeamSalesAmount) *
                              100
                            ).toFixed(2),
                            (
                              (totalTeamRentalSales /
                                totalLeaderAndTeamSalesAmount) *
                              100
                            ).toFixed(2),
                          ],
                          backgroundColor: ["#648FFF", "#FFB000", "#DC267F"],
                          hoverBorderColor: ["#648FFF", "#FFB000", "#DC267F"],
                          hoverBorderWidth: 2,
                        },
                      ],
                    }}
                    options={{
                      legend: { display: false },
                      tooltips: { enabled: false },
                      cutoutPercentage: 80,
                      plugins: {
                        datalabels: { display: false },
                        emptyDoughnut: {
                          color: "#14B8A6",
                          width: 8,
                          radiusDecrease: 20,
                        },
                      },
                    }}
                    plugins={[chartText, emptyDoughnut]}
                  />
                </div>
              </div>
            </div>
            <div style={{ gridArea: "1 / 2 / 4 / 3" }}>
              <CampaignTrackerStat
                className={"mb-0"}
                personalSummary={personalSummary}
                onChangeCampaignTrackerHOC={onChangeCampaignTrackerHOC}
                getSelectedPromotion={getSelectedPromotion}
              />
            </div>
          </>
        )}
      </div>
    );
  }
};

export default GeneralDataContent;
