import React from "react";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import Attendance from "./attendance";
import Stats from "./stat.js";

import ExportHOC from "../../actions/export";
import "../../index.scss";

const ExportFile = ({
  onLoadTrainings,
  onLoadExport,
  onCloseModal,
  ...props
}) => {
  return (
    <>
      <AtlasCloseButton
        containerStyle={{ marginBottom: 10 }}
        onClick={() => onCloseModal()}
      />
      <Attendance onLoadExport={onLoadExport} {...props} />
      <Stats {...props} />
      {(onLoadTrainings || onLoadExport) && <LoadingModal />}
    </>
  );
};

export default ExportHOC(ExportFile);
