import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";

import Tabs from "components/Tab";
import CustomCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomFormHeader from "components/Form/header";
import CustomTable from "./components/subsalesTable";
import ReportSearch from "./components/search";
import ReportChart from "./components/chart";

import WithReports from "./actions";
import WithSubsales from "./actions/subsales";
import VersionCheckHOC from "actions/versionCheck";
import { projectHeader, subsalesHeader } from "./assets";
import { initGA } from "utils/ga";

import "chartjs-plugin-datalabels";
import "./index.scss";

const subsalesOptions = [
  {
    id: 0,
    label: "All",
    value: "all",
  },
  {
    id: 1,
    label: "Sale",
    value: "sale",
  },
  {
    id: 2,
    label: "Rental",
    value: "rent",
  },
];

class Reports extends Component {
  componentDidMount = () => {
    initGA("/dashboard/reports/top-agent-group-sales");
    this.props.getBranches();
    this.props.getProjectReports();
    this.props.getSubsalesReports();
  };

  renderProjectContent = () => {
    const {
      projectData,
      subsalesData,
      startDate,
      endDate,
      searchParams,
      view,
      visual,
      exportBtn,
      exportProjectReports,
    } = this.props;

    return (
      <div className="at-form__content mt-3">
        <CustomFormHeader
          title={
            <div className="d-flex grid_gap-2 align-items-center">
              Top Agents Group Sales - Project
              {exportBtn(projectData, subsalesData, view) && (
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() =>
                    exportProjectReports(startDate, endDate, searchParams, view)
                  }
                >
                  <AtlasIcon
                    svgHref={"atlas-xlsx-file"}
                    style={{ width: 20, height: 20 }}
                  />
                  Export
                </button>
              )}
            </div>
          }
          moduleIcon={"atlas-cup"}
        />
        {visual === "chart" && (
          <div className="mt-3">
            <ReportChart type={"project"} reportData={projectData || []} />
          </div>
        )}
        {visual !== "chart" && (
          <div className="mt-3">
            <CustomTable
              rowItem={_.isEmpty(projectData) ? [] : projectData}
              columns={projectHeader}
              pagination={true}
            />
          </div>
        )}
      </div>
    );
  };

  renderSubsalesContent = () => {
    const {
      subsalesData,
      subsalesType,
      allSubsalesData,
      visual,
      projectData,
      startDate,
      endDate,
      searchParams,
      view,
      exportBtn,
      exportProjectReports,
      onChangeSubsalesReportsHOC,
    } = this.props;

    let tempData =
      subsalesType === "all"
        ? allSubsalesData.total_ranking
        : subsalesType === "sale"
          ? allSubsalesData.sale_ranking
          : allSubsalesData.rent_ranking;

    return (
      <div className="at-form__content mt-3">
        <CustomFormHeader
          title={
            <div className="d-flex grid_gap-2 align-items-center">
              Top Agents Group Sales - Subsales
              {exportBtn(projectData, subsalesData, view) && (
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() =>
                    exportProjectReports(startDate, endDate, searchParams, view)
                  }
                >
                  <AtlasIcon
                    svgHref={"atlas-xlsx-file"}
                    style={{ width: 20, height: 20 }}
                  />
                  Export
                </button>
              )}
            </div>
          }
          moduleIcon={"atlas-cup"}
        />
        <div className="at-report__subsales-options">
          {subsalesOptions.map((item, index) => (
            <button
              key={`subsales-type-${index}`}
              className={
                subsalesType === item.value
                  ? "at-report__subsales-selected"
                  : ""
              }
              onClick={() =>
                onChangeSubsalesReportsHOC("subsalesType", item.value)
              }
            >
              {item.label}
            </button>
          ))}
        </div>
        {visual === "chart" && (
          <div className="mt-2">
            <ReportChart type={subsalesType} reportData={tempData} />
          </div>
        )}
        {visual !== "chart" && (
          <div className="mt-10">
            <CustomTable
              rowItem={_.isEmpty(subsalesData) ? [] : subsalesData}
              columns={subsalesHeader(subsalesType)}
              pagination={true}
            />
          </div>
        )}
      </div>
    );
  };

  renderAgentSearchTips = () => {
    const { view } = this.props;
    return (
      <>
        <span className="fw-500">
          How to manage search results and using chart view
        </span>
        {view === "Project" && (
          <p style={{ lineHeight: "150%", marginTop: 10 }}>
            Manage your search by selecting the date range and branch, then
            click Submit.
            <br />
            You can also sort your report on the Chart view option to show Total
            Sales or Total Converted only. To do so simply click on the graph
            legend/label to remove the option that you do not need.
          </p>
        )}
        {view !== "Project" && (
          <p style={{ lineHeight: "150%", marginTop: 10 }}>
            Manage your search by selecting the date range and branch, then
            click Submit.
            <br />
            If you want to review the report based on the market category, click
            on the Sale or Rental only button to filter accordingly.
            <br />
            You can also sort your report on the Chart view option to show Total
            Sales or Total Converted only. To do so simply click on the graph
            legend/label to remove the option that you do not need.
          </p>
        )}
      </>
    );
  };

  renderCardContent = () => {
    const {
      view,
      onChangeProjectReportsHOC,
      visual,
      startDate,
      endDate,
      getProjectReports,
      getSubsalesReports,
      searchParams,
    } = this.props;

    return (
      <div className={"w-100"}>
        <Tabs
          sections={[
            { icon: "atlas-building-3", label: "Project" },
            { icon: "atlas-building-4", label: "Subsales" },
          ]}
          selectedSection={view}
          onSelectSection={(val) => onChangeProjectReportsHOC("view", val)}
        />
        <ReportSearch
          onChangeProjectReportsHOC={onChangeProjectReportsHOC}
          visual={visual}
          startDate={startDate}
          endDate={endDate}
          getProjectReports={getProjectReports}
          getSubsalesReports={getSubsalesReports}
          searchParams={searchParams}
        />
        {view === "Project"
          ? this.renderProjectContent()
          : this.renderSubsalesContent()}
        <div style={{ height: 100 }}></div>
      </div>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Top Agent Group Sales"}
          atlasIcon={"atlas-cup"}
          showPABadge={role === "Personal Assistant"}
          description={
            <>
              The reports below show the Top 50 Agents in your group based on
              their market category. <br />
              Select the Project or Subsales category tabs to find out.
              <br />
              {this.props.view === " Project"
                ? `Below shows your group's top project performers. The report is based on the booking date and accumulated nett price portion of their sales.`
                : `Below shows your group's top subsales performers. The report is based on the total accumulated agency commission collected by the agent.`}
            </>
          }
          infoBoxInactiveContent={
            "How to manage search results and using chart view"
          }
          infoBoxContent={this.renderAgentSearchTips}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadReports && <LoadingModal />}
      </>
    );
  };
}

export default compose(WithReports, WithSubsales, VersionCheckHOC)(Reports);
