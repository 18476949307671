import React, { Component } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import CustomDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import InventoryForm from "./form";

import InventoryHOC from "./actions";

const columnData = [
  { header: "Inventory Name", accessor: "name" },
  {
    header: "Group List",
    renderColumn: (rowData) => {
      let temp = "";
      if (rowData.group_list && rowData.group_list.length > 0) {
        rowData.group_list.map((group, index) => {
          temp =
            temp +
            group +
            `${index + 1 === rowData.group_list.length ? "" : ", "}`;
        });
      }
      return temp;
    },
  },
];

class InventoryChecklist extends Component {
  componentDidMount = () => this.props.getInventoryList(1, "");

  renderCardContent = () => (
    <AtPaginatedTable
      columns={columnData}
      searchParams={[]}
      rowData={this.props.inventoryList.data || []}
      meta={this.props.inventoryList.meta || null}
      getListAPI={this.props.getInventoryList}
      totalPages={this.props.inventoryPages}
      actionColumnContent={[
        {
          name: "edit",
          onClick: (rowData) =>
            Promise.all([this.props.getSelectedInventory(rowData.id)]).then(
              () => {
                this.props.onChangeInventoryHOC(true, "showEditInventory");
              },
            ),
        },
        {
          name: "delete",
          color: "#F04438",
          onClick: (rowData) =>
            Promise.all([this.props.getSelectedInventory(rowData.id)]).then(
              () => {
                this.props.onChangeInventoryHOC(true, "showDeleteInventory");
              },
            ),
        },
      ]}
    />
  );

  render = () => {
    return (
      <>
        <ModuleHeader
          title={`Inventory Checklist`}
          moduleIcon={"icon-checklist"}
          actionButton={[
            {
              label: "New Inventory",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeInventoryHOC(true, "showCreateInventory"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <CustomDialog open={this.props.showCreateInventory}>
          <InventoryForm
            mode={"create"}
            groupTag={this.props.groupTag}
            getGroupTag={this.props.getGroupTag}
            onClickCreate={this.props.createInventory}
            onClose={() =>
              this.props.onChangeInventoryHOC(false, "showCreateInventory")
            }
          />
        </CustomDialog>
        <CustomDialog open={this.props.showEditInventory}>
          <InventoryForm
            mode={"edit"}
            groupTag={this.props.groupTag}
            selectedInventory={this.props.selectedInventory}
            getGroupTag={this.props.getGroupTag}
            onClickUpdate={this.props.updateInventory}
            onClose={() =>
              this.props.onChangeInventoryHOC(false, "showEditInventory")
            }
          />
        </CustomDialog>
        <ConfirmationModal
          title={"Delete Inventory"}
          loading={this.props.onLoadInventory}
          open={this.props.showDeleteInventory}
          message={`Are you sure you want to remove this inventory ?`}
          positiveAction={() =>
            this.props.deleteInventory(this.props.selectedInventory.id)
          }
          negativeAction={() =>
            this.props.onChangeInventoryHOC(false, "showDeleteInventory")
          }
        />
        {this.props.onLoadInventory && <LoadingModal />}
      </>
    );
  };
}

export default InventoryHOC(InventoryChecklist);
