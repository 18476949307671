import React, { Component } from "react";
import { FaEdit } from "react-icons/fa";

import CustomButton from "components/Button";
import SVGIcon from "components/Icon";

import { numberWithCommas } from "utils/thousandSeparator";
import { Tooltip } from "@material-ui/core";

class Content extends Component {
  state = {
    radioChecked: false,
  };

  toggleExpandTableRow() {
    this.setState((prevState) => ({
      radioChecked: !prevState.radioChecked,
    }));
  }

  onClickDetailsActions = (param) => this.props.detailsClick(param);

  onClickApprove = (param) => this.props.approveClick(param);

  onClickDisapprove = (param) => this.props.disapproveClick(param);

  onClickEditActions = (param) => this.props.editClick(param);

  onClickDeleteActions = (param) => this.props.deleteClick(param);

  onClickUpdateFixedActions = (param) => this.props.updateFixedClick(param);

  onClickUpdateCalendarActions = (param) =>
    this.props.updateCalendarClick(param);

  onClickUpdateAcceptedActions = (param) =>
    this.props.updateAcceptedClick(param);

  onClickSendEmailActions = (param) => this.props.sendEmail(param);

  onClickDownloadTempReceiptActions = (param) =>
    this.props.downloadClick(param);

  checkInClick = (param) => this.props.checkInClick(param);

  checkOutClick = (param) => this.props.checkOutClick(param);

  onClickViewDoc = (param) => this.props.onClickViewDoc(param);

  transferTicket = (param) => this.props.transferTicket(param);

  onClickNotify = (param) => this.props.sendNotification(param);

  renderActionContent(content) {
    const {
      rowKey,
      actionColumnContent,
      sendEmailDocType,
      downloadDocType,
      rowItem,
      viewDocTooltip,
    } = this.props;
    const tempActionColumnContent = [];
    actionColumnContent.map((item) => {
      return item !== "delete" ? tempActionColumnContent.push(item) : {};
    });
    actionColumnContent.includes("delete") &&
      tempActionColumnContent.push("delete");

    return tempActionColumnContent.map((item) => {
      if (item === "details") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <SVGIcon
                svgHref={"icon-info"}
                className="svg-icon-info"
                style={{ width: 20, height: 20 }}
              />
            }
            tooltip={true}
            tooltipChildren={<span>Detail</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickDetailsActions(content)}
          />
        );
      }
      if (item === "edit") {
        return (
          <CustomButton
            disabled={this.props.disabledEdit}
            key={item}
            className={"at-table-icon-button"}
            children={
              <FaEdit style={{ width: 20, height: 20, color: "#007bff" }} />
            }
            tooltip={true}
            tooltipChildren={<span>Edit</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickEditActions(content)}
          />
        );
      }
      if (item === "delete") {
        return (
          <CustomButton
            disabled={this.props.disabledDelete}
            containerStyle={{
              ...(this.props.disabledDelete && { opacity: 0.3 }),
            }}
            key={item}
            className={"at-table-icon-button"}
            children={
              <div className="svg-icon-info">
                <SVGIcon
                  svgHref={"icon-bin"}
                  className="svg-icon-info--danger"
                  style={{ width: 20, height: 20 }}
                />
              </div>
            }
            tooltip={true}
            tooltipChildren={<span>Delete</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickDeleteActions(content)}
          />
        );
      }
      if (item === "view-doc") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <div className="svg-icon-info">
                <SVGIcon
                  svgHref={"icon-doc"}
                  className="svg-icon-info"
                  style={{ width: 20, height: 20 }}
                />
              </div>
            }
            tooltip={true}
            tooltipChildren={
              viewDocTooltip ? (
                <span>{viewDocTooltip}</span>
              ) : (
                <span>View Doc</span>
              )
            }
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickViewDoc(content)}
          />
        );
      }
      if (item === "update-fixed") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <div className="svg-icon-info">
                <SVGIcon
                  svgHref={"icon-tick"}
                  className="svg-icon-info"
                  style={{ width: 20, height: 20 }}
                />
              </div>
            }
            tooltip={true}
            tooltipChildren={<span>Mark as fixed</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateFixedActions(content)}
          />
        );
      }
      if (item === "update-scheduled") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <div className="svg-icon-info">
                <SVGIcon
                  svgHref={"icon-calendar"}
                  className="svg-icon-info"
                  style={{ width: 20, height: 20 }}
                />
              </div>
            }
            tooltip={true}
            tooltipChildren={<span>Mark as scheduled</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateCalendarActions(content)}
          />
        );
      }
      if (item === "update-accepted") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <SVGIcon className="svg-icon-info" svgHref={"icon-good"} />
            }
            tooltip={true}
            tooltipChildren={<span>Mark as accepted</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateAcceptedActions(content)}
          />
        );
      }
      if (item === "download") {
        return (
          <Tooltip placement={"top"} title={"Click to download."}>
            <a
              className={"at-table-icon-button"}
              target={"_blank"}
              href={content.document_link}
            >
              <SVGIcon
                className="svg-icon-info"
                svgHref={"icon-download-doc"}
              />
            </a>
          </Tooltip>
        );
      }
      if (item === "download-temp-receipt") {
        if (downloadDocType.indexOf(content.doc_type) > -1) {
          return (
            <CustomButton
              key={item}
              className={"at-table-icon-button"}
              children={
                <SVGIcon className="svg-icon-info" svgHref={"icon-download"} />
              }
              tooltip={true}
              tooltipChildren={<span>Download Temp Receipt</span>}
              tooltipPosition={"bottom"}
              tooltipID={`details-${content[rowKey]}-${item}`}
              onClick={() => this.onClickDownloadTempReceiptActions(content)}
            />
          );
        }
      }
      if (item === "send-email") {
        if (sendEmailDocType.indexOf(content.doc_type) > -1) {
          return (
            <CustomButton
              key={item}
              className={"at-table-icon-button"}
              children={
                <SVGIcon className="svg-icon-info" svgHref={"icon-envelope"} />
              }
              tooltip={true}
              tooltipChildren={<span>Email</span>}
              tooltipPosition={"bottom"}
              tooltipID={`details-${content[rowKey]}-${item}`}
              onClick={() => this.onClickSendEmailActions(content)}
            />
          );
        }
      }
      if (item === "approve") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Approve "}
            disabled={this.props.rowItem.is_approved === "Yes"}
            tooltip={true}
            tooltipChildren={<span>Approve</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickApprove(content)}
          />
        );
      }
      if (item === "check-in") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Clock In "}
            disabled={rowItem.clock_in_time}
            tooltip={true}
            tooltipChildren={<span>Clock In</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.checkInClick(content)}
          />
        );
      }
      if (item === "check-out") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Clock Out "}
            disabled={rowItem.clock_out_time}
            tooltip={true}
            tooltipChildren={<span>Clock Out</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.checkOutClick(content)}
          />
        );
      }
      if (item === "transfer") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Transfer "}
            disabled={false}
            tooltip={true}
            tooltipChildren={<span>Transfer Ticket</span>}
            tooltipPosition={"bottom"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.transferTicket(content)}
          />
        );
      }
      if (item === "notify") {
        return (
          <CustomButton
            key={item}
            className={"at-table-icon-button"}
            children={
              <SVGIcon className="svg-icon-info" svgHref={"icon-email"} />
            }
            tooltip={true}
            tooltipChildren={<span>Resend</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickNotify(rowItem.id)}
          />
        );
      }
    });
  }

  renderMobileAction = (content) => {
    return (
      <div className="at-table-row__mobile-action">
        {this.renderActionContent(content)}
      </div>
    );
  };

  renderTableContentGrid(
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
  ) {
    const { booleanColumn, thousandSeparatorColumn, actionColumnContent } =
      this.props;
    const contentProcess = (param) => {
      if (booleanColumn === columnValue) {
        if (content === 0) {
          return "No";
        } else {
          return "Yes";
        }
      } else if (columnValue === "status_id") {
        if (content === 1) {
          return "Completed";
        } else if (content === 2) {
          return "Cancelled";
        }
      } else {
        if (thousandSeparatorColumn) {
          if (thousandSeparatorColumn.indexOf(columnValue) > -1) {
            param = numberWithCommas(param);
          }
        }
        return param;
      }
    };
    const renderHighlightColumn = (param) => {
      if (param) {
        if (param.columnValue === columnValue) {
          return "grid-top-object";
        }
      }
    };
    return (
      <>
        {index !== "actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{
              width: columnWidth ? columnWidth : "100%",
            }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            <p>{contentProcess(content)}</p>
          </li>
        )}
        {index === "actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__actions ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{
              width: `${40 + actionColumnContent.length * 30}%`,
            }}
          >
            <span className="at-table-row__item-label">Actions</span>
            {this.renderActionContent(content)}
          </li>
        )}
      </>
    );
  }

  render() {
    const { headerData, actionColumn, rowItem, secondLayerRow } = this.props;
    return (
      <div className="at-table-row-cont">
        <div
          className="at-table__height-control"
          style={{ height: this.state.radioChecked ? "370px" : "initial" }}
        >
          <ul className="at-table-row grid-controller">
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                columnItem.value,
                rowItem[columnItem.value],
                columnItem.label,
                columnItem.width,
              );
            })}
            {actionColumn
              ? this.renderTableContentGrid("actions", "Actions", rowItem)
              : ""}
          </ul>
          {secondLayerRow &&
            rowItem.sub_sale_claim_submissions &&
            rowItem.sub_sale_claim_submissions.length > 0 && (
              <div
                className="at-table-row grid-controller"
                style={{
                  padding: "15px",
                  paddingLeft: "30px",
                  flexDirection: "column",
                }}
              >
                <h6>Claim Submissions</h6>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>Submitted Date</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Admin</th>
                  </tr>
                  {rowItem.sub_sale_claim_submissions &&
                    rowItem.sub_sale_claim_submissions.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.submitted_date}</td>
                          <td>{item.status}</td>
                          <td>{item.type}</td>
                          <td>{item.admin}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            )}
        </div>
        {actionColumn && this.renderMobileAction(rowItem)}
      </div>
    );
  }
}

export default Content;
