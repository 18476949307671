import React from "react";
import { FiEdit } from "react-icons/fi";
import { MoonLoader } from "react-spinners";
import { GiDominoMask } from "react-icons/gi";
import { AiOutlineExport } from "react-icons/ai";
import { FaShareAlt, FaCheckSquare } from "react-icons/fa";
import { IoMdDocument, IoMdCheckmarkCircleOutline } from "react-icons/io";
import {
  BsFillImageFill,
  BsInfoCircleFill,
  BsTrash3Fill,
} from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";
import AtButton from "components/Button";
import SVGIcon from "components/Icon";

const processIconType = (param, style) => {
  switch (param) {
    case "summary":
    case "details":
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "duplicate":
      return (
        <AtlasIcon
          svgHref={"atlas-copy"}
          style={{ width: 18, height: 18, fill: "#3B82F6" }}
        />
      );
    case "edit":
      return <FiEdit style={{ width: 18, height: 18, color: "#007bff" }} />;
    case "download":
      return (
        <SVGIcon className="svg-icon-info" svgHref={"icon-download-doc"} />
      );
    case "release":
      return <SVGIcon className="svg-icon-info" svgHref={"icon-unlock"} />;
    case "reserve":
      return (
        <IoMdDocument style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "approve":
      return (
        <FaCheckSquare style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "delete":
      return (
        <BsTrash3Fill style={{ width: 18, height: 18, color: "#F04438" }} />
      );
    case "share":
      return <FaShareAlt style={{ width: 16, height: 16, color: "#007BFF" }} />;
    case "preview":
      return (
        <BsFillImageFill style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "select":
      return (
        <IoMdCheckmarkCircleOutline
          style={{ width: 20, height: 20, color: "#007BFF" }}
        />
      );
    case "check":
      return (
        <MoonLoader
          sizeunit={"px"}
          size={30}
          color={"#16232C"}
          loading={true}
        />
      );
    case "export to ERP":
      return (
        <AiOutlineExport style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "impersonate":
      return (
        <GiDominoMask style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "transfer":
      return (
        <AtlasIcon
          svgHref={"atlas-arrow-swap-horizontal-linear"}
          style={{ width: 18, height: 18, fill: "#007bff" }}
        />
      );
    case "payment proof":
      return (
        <AtlasIcon
          svgHref={"atlas-receipt-text"}
          style={{ width: 18, height: 18, fill: "#007bff" }}
        />
      );
    default:
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#3B82F6" }} />
      );
  }
};

const TableContent = ({ index, rowData, maxActionLength, columns, actionColumnContent }) => {
  const getTooltipContent = (tooltipContent) => {
    return typeof tooltipContent !== "function"
      ? tooltipContent
      : tooltipContent(rowData);
  };

  return (
    <div
      className={`at-table-row-cont at-table-row-cont-${index % 2 ? "odd" : "even"}`}
      key={`row-${index}`}
    >
      <div className="at-table__height-control">
        <ul className="at-table-row grid-controller">
          {columns.map((columnData, columnIndex) => {
            return (
              <li
                key={columnIndex}
                style={{ width: "100%", ...columnData.columnStyle }}
                className={`at-table-row__item at-table__${columnIndex} 
                    ${
                      columnData.columnClass &&
                      typeof columnData.columnClass === "function"
                        ? columnData.columnClass(rowData)
                        : columnData.columnClass || ""
                    }`}
              >
                <span className="at-table-row__item-label">
                  {typeof columnData.header === "string" && (
                    <span className="at-table-head__title">
                      {columnData.header}
                    </span>
                  )}
                  {typeof columnData.header === "function" &&
                    columnData.header()}
                </span>
                {columnData && columnData.accessor && (
                  <div className={`${columnData.contentClass || ""}`}>
                    {columnData.accessor && (
                      <span>{rowData[columnData.accessor] || "N/A"}</span>
                    )}
                  </div>
                )}
                {columnData && columnData.renderColumn && (
                  <div
                    className={`w-100 at-custom-column ${columnData.customClass || ""}`}
                  >
                    {columnData.renderColumn &&
                      columnData.renderColumn(rowData, index)}
                  </div>
                )}
              </li>
            );
          })}
          {actionColumnContent && actionColumnContent.length > 0 && (
            <li
              className="at-table-row__item at-table__actions"
              style={{
                width: "100%",
                minWidth: (maxActionLength * 60) < 100
                  ? 100 
                  : (maxActionLength * 60),
                maxWidth: (maxActionLength * 70),
              }}
            >
              {actionColumnContent.map((action, actionIndex) => {
                if (
                  !action.onShow ||
                  (action.onShow && action.onShow(rowData))
                ) {
                  return (
                    <AtButton
                      key={actionIndex}
                      disabled={action.disabled && action.disabled(rowData)}
                      className={`at-table-icon-button ${action.iconClass || ""}`}
                      style={{ color: action.color || "#007bff" }}
                      children={
                        action.renderChildren &&
                        typeof action.renderChildren === "function"
                          ? action.renderChildren(rowData)
                          : processIconType(action.name, action.iconStyle)
                      }
                      tooltip={true}
                      tooltipChildren={
                        <span
                          className={
                            getTooltipContent(action.tooltipContent)
                              ? ""
                              : "text-capitalize"
                          }
                        >
                          {getTooltipContent(action.tooltipContent) ||
                            action.name}
                        </span>
                      }
                      tooltipPosition={"top"}
                      tooltipID={`details-${action.name}-${rowData.id}`}
                      onClick={() => action.onClick(rowData, index)}
                    />
                  );
                }
              })}
            </li>
          )}
        </ul>
      </div>
      {actionColumnContent && actionColumnContent.length > 0 && (
        <div className="at-table-row__mobile-action">
          {actionColumnContent.map((action, actionIndex) => {
            if (!action.onShow || (action.onShow && action.onShow(rowData))) {
              return (
                <AtButton
                  key={actionIndex}
                  disabled={action.disabled && action.disabled(rowData)}
                  className={`${action.iconClass || ""}`}
                  style={{ color: action.color || "#007bff" }}
                  children={
                    <>
                      {processIconType(action.name, action.iconStyle)}
                      <span className="text-capitalize ml-2">
                        {action.tooltipContent || action.name}
                      </span>
                    </>
                  }
                  onClick={() => action.onClick(rowData, index)}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default TableContent;
