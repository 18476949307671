import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, CardContent, Grid } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import { FabricProvider } from "context/fabric";

import HelpInfoContent from "./Form/helpInfo";
import TitleDescContent from "./Form/titleDesc";
import FabricContent from "./Form/fabric";

const CreateForm = ({
  category,
  showLoading,

  onClickSubmit,
  onClickClose,
  selectedTemplate,
}) => {
  const [editFormData, onChangeFormData] = useState({
    title: "",
    type_id: 1,
    status_id: 1,
    description: "",
    category_list: [],
  });
  const [sampleGraphic, setSampleGraphic] = useState(null);

  useEffect(() => {
    onChangeFormData({
      id: selectedTemplate.id,
      title: selectedTemplate.title,
      status_id: selectedTemplate.status_id,
      type_id: selectedTemplate.type_id,
      description: selectedTemplate.description,
      category_list: selectedTemplate.category_list,
    });
    setSampleGraphic(selectedTemplate.sample_url || null);
  }, [selectedTemplate]);

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSampleGraphic(e.target.result);
      };
      reader.readAsDataURL(fileItems[0].file);
    } else {
      setSampleGraphic(null);
    }
  };

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(editFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClickClose()} />
        <h5 className="at-card__title">{"Edit Graphics Template"}</h5>
      </div>
      <FabricProvider>
        <Grid container spacing={16} className={"mb-3"}>
          <Grid item lg={9} md={8} sm={7} xs={12}>
            <Card className={"mt-2 mb-3"}>
              <CardContent>
                <TitleDescContent
                  context={"edit"}
                  category={category}
                  formData={editFormData}
                  sampleGraphic={sampleGraphic}
                  onChangeFile={onChangeFile}
                  onChangeField={onChangeField}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={5} xs={12}>
            <HelpInfoContent />
          </Grid>
        </Grid>
        <FabricContent
          context={"edit"}
          initialJSONData={selectedTemplate.content}
          formData={editFormData}
          sampleGraphic={sampleGraphic}
          onClickSubmit={onClickSubmit}
          onClickClose={onClickClose}
        />
      </FabricProvider>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
