import React, { useEffect } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import CustomFormInput from "components/Input/formInput";

import { user } from "dictionary";
import WithHOC from '../actions/bookingLimit'
import permissionsChecker from "utils/permissionsChecker";

const BookingLimit = props => {
  const { can_update } = permissionsChecker("Meeting Hub - Room Management", props.data);

  useEffect(() => {
    props.getBookingLimit()
  }, [])

  return (
    <div className="at-form__content d-flex flex-column g-3">
      <h2 style={{ fontSize: 18, fontWeight: 600, color: '#111827' }}>Booking Limit</h2>
      <div className="d-flex flex-column g-2">
        <p className="at-form-input__title">
          The maximum limit for booking a meeting room within 1 week
        </p>
        {props.bookingLimit?.map((item, index) => (
          <div className="d-flex align-items-center g-3">
            <h2 className="at-form-input__title fw-400 mb-0" style={{ minWidth: 80 }}>{_.find(user.roles, { value: item.role_id })?.label}</h2>
            <CustomFormInput
              type={"number"}
              value={item.value}
              disabled={!can_update}
              placeholder={"Capacity"}
              containerStyle={{ width: "100%" }}
              onChangeValue={(val) => {
                let temp = _.cloneDeep(props.bookingLimit);
                temp[index].value = val;
                props.onChangeBookingLimit(temp, "bookingLimit");
              }}
            />
          </div>
        ))}
      </div>
      {(can_update && (
        <CustomButton
          className="btn-new btn-new--success mt-3"
          onClick={() => props.updateBookingLimit()}
        >
          Update
        </CustomButton>
      ))}
    </div>
  )
}

export default WithHOC(BookingLimit);