import React, { Component } from "react";
import AtlasTable from "../components/SimpleTable";

import SimilarPropertiesHOC from "../actions/similarProperties";

const headerData = [
  {
    label: "Confirmation Form Number",
    value: `confirmation_form_number`,
    width: "30%",
  },
  {
    label: "Property Address",
    value: "property_address",
    width: "30%",
  },
  {
    label: "Booking Date",
    value: "booking_date",
    width: "40%",
  },
  {
    label: "Submitted By",
    value: "submitted_by",
    width: "20%",
  },
  {
    label: "Claim Submissions",
    value: "sub_sale_claim_submissions",
    width: "150%",
  },
];

const secondLayerHeaderData = [
  {
    label: "Submission Date",
    value: "submitted_date",
  },
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Senior Admin",
    value: "senior_admin",
  },
];

class SubsaleClaimSimilarProperties extends Component {
  componentDidMount = () =>
    this.props.getSimilarProperties(this.props.selected_claim.id, 1, "");

  render = () => {
    const {
      getSimilarProperties,
      selected_claim,
      onLoadSimilarProperties,
      onChangeSimilarPropertiesHOC,
      searchURL,
      search,
      totalPages,
      showSearchContent,
    } = this.props;
    const { meta: propMeta, data: propData } = this.props.similarProperties;
    return (
      <>
        <AtlasTable
          rowKey={"id"}
          headerData={headerData}
          secondLayerHeaderData={secondLayerHeaderData}
          rowData={propData || []}
          meta={propMeta}
          secondLayerRow={true}
          pagination={true}
          totalPages={totalPages}
          getSimilarProperties={getSimilarProperties}
          selected_claim={selected_claim}
          onLoadSimilarProperties={onLoadSimilarProperties}
          onChangeSimilarPropertiesHOC={onChangeSimilarPropertiesHOC}
          searchURL={searchURL}
          search={search}
          showSearchContent={showSearchContent}
        />
      </>
    );
  };
}

export default SimilarPropertiesHOC(SubsaleClaimSimilarProperties);
