import React from "react";
import { Tooltip } from "@material-ui/core";
import { IoMdDocument } from "react-icons/io";

const DocumentElement = ({ doc, onClickOpenDoc, docNameProps }) => {
  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      <Tooltip title={"Click to view uploaded document."} placement={"top"}>
        <div style={{ position: "relative " }}>
          <IoMdDocument
            style={{ maxHeight: 100, cursor: "pointer" }}
            onClick={() => onClickOpenDoc()}
          />
        </div>
      </Tooltip>
      <p style={{ fontSize: "0.8em" }}>
        {docNameProps ? doc[docNameProps] : doc.document_file_name}
      </p>
    </div>
  );
};

export default DocumentElement;
