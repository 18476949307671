import { PiBathtubFill, PiBedFill } from 'react-icons/pi';
import { RiRuler2Line } from 'react-icons/ri';

const BlackPillsTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    marginRight: 4
  };

  return (
    <div style={{ color: '#fff' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          marginBottom: 8
        }}
      >
        <div
          style={{
            width: 4,
            minWidth: 4,
            height: 24,
            borderRadius: '0 4px 4px 0',
            backgroundColor: '#ea580c'
          }}
        />
        <p style={{ fontSize: 12, fontWeight: 700 }}>{unitType}</p>
      </div>
      <div style={{ display: 'flex',  alignItems: 'center', gap: 8 }}>
        <div
          style={{
            position: 'relative',
            padding: '8px 8px 8px 10px',
            borderRadius: 8,
            backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`,
            minWidth: '15.25%',
            width: 'max-content'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              width: 4,
              minWidth: 4,
              height: '80%',
              borderRadius: '0 4px 4px 0',
              backgroundColor: '#2563eb'
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <p style={detailStyle}>
              <RiRuler2Line style={iconStyle} />
              <span>
                {property.min_built_up?.toLocaleString()} -{' '}
                {property.max_built_up?.toLocaleString()} Sqft
              </span>
            </p>
            <p style={detailStyle}>
              <PiBedFill style={iconStyle} />
              <span>{property.bedroom_display || 0} bedrooms</span>
            </p>
            <p style={detailStyle}>
              <PiBathtubFill style={iconStyle} />
              <span>{property.bathroom_display || 0} bathrooms</span>
            </p>
          </div>
        </div>
        <div
          style={{
            position: 'relative',
            padding: '8px 8px 8px 10px',
            borderRadius: 8,
            backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`,
            minWidth: '15.25%',
            width: 'max-content'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              width: 4,
              minWidth: 4,
              height: '80%',
              borderRadius: '0 4px 4px 0',
              backgroundColor: '#a855f7'
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {property.project_features?.slice(0, 3)?.map(feature => (
              <p key={feature.name} style={detailStyle}>
                {feature.name?.trim()}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackPillsTemplate;
