import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";
import { MdFilterList } from "react-icons/md";
import { RiCheckDoubleLine } from "react-icons/ri";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import TablePagination from "components/NewPaginatedTable/Pagination";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import AdditionalSearch from "components/NewPaginatedTable/components/additionalSearch";
import NotificationDetailsModal from "components/Modal/notificationDetails";
import NotificationIcon from "./icons";

import NotificationHOC from "actions/notification";
import VersionCheckHOC from "actions/versionCheck";

class Notification extends Component {
  state = {
    showSearchContent: false,
  };

  componentDidMount = () => this.props.getNotifications(1, "");

  renderNotifications = () => (
    <>
      <div className="at-form__content d-flex justify-content-between align-items-end mb-10">
        <AdditionalSearch
          searchParams={this.props.searchParams}
          getListAPI={this.props.getNotifications}
          onChangeSearchParams={(val) =>
            this.props.onChangeNotificationHOC(val, "searchParams")
          }
        />
        <span
          className="at-notification__dropbear__link my-auto"
          onClick={() => this.props.markAllNotificationAsRead()}
        >
          <RiCheckDoubleLine
            style={{ width: 20, height: 20, marginRight: 5 }}
          />
          Mark All as Read
        </span>
      </div>
      {this.props.notifications &&
        this.props.notifications.data &&
        this.props.notifications.data.length > 0 && (
          <div className="at-notification__body">
            {this.props.notifications.data.map((item, index) => (
              <div
                key={`Notifications-${index}`}
                style={{ cursor: "pointer" }}
                className={`at-notification__dropdown-panel ${item.read ? "" : "info-read"}`}
                onClick={() => this.props.getSelectedNotification(item.id)}
              >
                <NotificationIcon iconType={item.notification.type} />
                <div className="w-100">
                  <p style={{ color: "#F0631D" }}>{item.notification.type}</p>
                  <h1 className="at-notification__dropdown-title">
                    {item.notification.title}
                  </h1>
                  <p>{item.notification.created_at}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      {!(
        this.props.notifications &&
        this.props.notifications.data &&
        this.props.notifications.data.length > 0
      ) && (
        <div className="at-notification__empty-state">
          <p>There are no notification.</p>
        </div>
      )}
    </>
  );

  renderCardContent = () => {
    const {
      notifications,
      showDetailModal,
      onChangeNotificationHOC,
      selectedNotification,
    } = this.props;

    const { meta } = notifications;

    return (
      <>
        <div style={{ maxWidth: 1000, margin: "auto" }}>
          <div className="mb-10">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() =>
                this.setState({
                  showSearchContent: !this.state.showSearchContent,
                })
              }
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller ">
              <TableSearchParams
                mode={"desktop"}
                searchParams={this.props.searchParams}
                onChangeSearchParams={(val) =>
                  this.props.onChangeNotificationHOC(val, "searchParams")
                }
                getListAPI={this.props.getNotifications}
              />
            </div>
          </div>
          {this.renderNotifications()}
          {meta && meta.count > 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TablePagination
                meta={meta}
                searchParams={this.props.searchParams}
                getListAPI={this.props.getNotifications}
                totalPages={this.props.notificationsPage}
              />
              <p className={`at-table__pagination-info`}>
                Showing
                <span> {meta && meta.from} </span>-
                <span> {meta && meta.to} </span>
                of
                <span> {meta && meta.count} </span>
                results
              </p>
            </div>
          )}
        </div>
        {this.state.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            searchParams={this.props.searchParams}
            onToggleSearchModal={() =>
              this.setState({ showSearchContent: false })
            }
            onChangeSearchParams={(val) =>
              this.props.onChangeNotificationHOC(val, "searchParams")
            }
            getListAPI={this.props.getNotifications}
          />
        )}
        {showDetailModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"md"}>
            <NotificationDetailsModal
              closeDialog={() =>
                onChangeNotificationHOC(false, "showDetailModal")
              }
              selectedNotification={selectedNotification}
            />
          </Dialog>
        )}
        {this.state.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            searchParams={this.props.searchParams}
            onToggleSearchModal={() =>
              this.setState({
                showSearchContent: !this.state.showSearchContent,
              })
            }
            onChangeSearchParams={(val) =>
              this.props.onChangeNotificationHOC(val, "searchParams")
            }
            getListAPI={this.props.getNotifications}
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={"Notifications"} moduleIcon={`icon-bell`} />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadNotification && <LoadingModal />}
      </>
    );
  };
}

export default compose(NotificationHOC, VersionCheckHOC)(Notification);
