import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { storeLastView } from "actions/lastView";
import { Get, Delete } from "utils/axios";

const searchParams = [
  {
    label: "Announcement Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Creator",
    value: "creator_display_name_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      announcements: [],
      selectedAnnouncement: {},
      adminAnnouncements: [],
      adminAnnouncementsPage: null,
      loading: false,

      showDetailsModal: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteConfirmationModal: false,
      searchParams: searchParams,
      selectedDeleteId: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeAnnouncementHOC = (val, context) =>
      this.setState({ [context]: val });

    adminGetAnnouncements = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/announcements?${search}page=${page}`,
        this.adminGetAnnouncementsSuccess,
        this.adminGetAnnouncementsError,
        this.load,
      );
    };
    adminGetAnnouncementsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.length > 0 &&
        payload.data.map((item) => {
          tmp.push({
            ...item,
            published: item.published ? "Yes" : "No",
          });
        });
      payload.data = tmp;
      return this.setState({
        adminAnnouncementsPage: tmpTotalPages,
        adminAnnouncements: payload,
      });
    };
    adminGetAnnouncementsError = (error) => requestError(error);

    getSelectedAnnouncement = (id, type) =>
      Get(
        `/admin/announcements/${id}`,
        (payload) => this.getSelectedAnnouncementSuccess(payload, type),
        this.getSelectedAnnouncementError,
        this.load,
      );
    getSelectedAnnouncementSuccess = (payload, type) =>
      this.setState({
        selectedAnnouncement: payload,
        showDetailsModal: type === "details",
        showEditModal: type === "edit",
      });
    getSelectedAnnouncementError = (error) => requestError(error);

    deleteAnnouncement = (id) =>
      Delete(
        `/admin/announcements/${id}`,
        this.deleteAnnouncementSuccess,
        this.deleteAnnouncementError,
        this.load,
      );
    deleteAnnouncementSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.adminGetAnnouncements(currentPage, searchParams);
      this.setState({
        showDetailsModal: false,
        showDeleteConfirmationModal: false,
      });
      requestSuccess(payload.message);
    };
    deleteAnnouncementError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadAnnouncement={this.state.loading}
            showCreateModal={this.state.showCreateModal}
            adminAnnouncements={this.state.adminAnnouncements}
            searchParams={this.state.searchParams}
            selectedAnnouncement={this.state.selectedAnnouncement}
            adminAnnouncementsPage={this.state.adminAnnouncementsPage}
            showDetailsModal={this.state.showDetailsModal}
            showEditModal={this.state.showEditModal}
            selectedDeleteId={this.state.selectedDeleteId}
            showDeleteConfirmationModal={this.state.showDeleteConfirmationModal}
            onChangeAnnouncementHOC={this.onChangeAnnouncementHOC}
            getSelectedAnnouncement={this.getSelectedAnnouncement}
            deleteAnnouncement={this.deleteAnnouncement}
            adminGetAnnouncements={this.adminGetAnnouncements}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
