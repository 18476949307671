import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Get, Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Full Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Mobile Contact",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      publishedTrainings: [],
      searchParams: searchParams,
      trainingParticipants: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      trainingParticipantsPages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeBulkHOC = (val, context) => this.setState({ [context]: val });

    getEventParticipants = (id, page, search) =>
      Get(
        `/admin/exams/user_events_of_event?event_id=${id}&${search}page=${page}`,
        this.getEventParticipantsSuccess,
        this.getEventParticipantsError,
        this.load,
      );
    getEventParticipantsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        trainingParticipants: payload,
        trainingParticipantsPages: tmpTotalPages,
      });
    };
    getEventParticipantsError = (error) => requestError(error);

    getPublishedTrainings = () =>
      Get(
        `/admin/exams/all_published_trainings`,
        this.getPublishedTrainingsSuccess,
        this.getPublishedTrainingsError,
        this.load,
      );
    getPublishedTrainingsSuccess = (payload) => {
      let temp = [];
      payload.map((item) => {
        temp.push({
          ...item,
          end_date_time: Moment(item.end_date_time).format("YYYY-MM-DD"),
        });
      });
      this.setState({ publishedTrainings: temp });
    };
    getPublishedTrainingsError = (error) => requestError(error);

    bulkUpdate = (dataToSubmit) =>
      Put(
        `/admin/exams/bulk_update`,
        dataToSubmit,
        this.bulkUpdateSuccess,
        this.bulkUpdateError,
        this.load,
      );
    bulkUpdateSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getExams(currentPage, searchParams);
      requestSuccess(payload.message);
      this.props.onCloseModal();
    };
    bulkUpdateError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            onLoadBulkUpdate={this.state.loading}
            trainingParticipants={this.state.trainingParticipants}
            trainingParticipantsPages={this.state.trainingParticipantsPages}
            publishedTrainings={this.state.publishedTrainings}
            onChangeBulkHOC={this.onChangeBulkHOC}
            bulkUpdate={this.bulkUpdate}
            getEventParticipants={this.getEventParticipants}
            getPublishedTrainings={this.getPublishedTrainings}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
