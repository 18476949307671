import React, { useEffect, useState } from "react";
import { Chip, Card, TextField } from "@material-ui/core";
import _ from "lodash";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import AtlasFormInput from "components/Input/formInput";

import ProfileImage1 from "assets/images/av-male.jpg";

const InternalAgent = ({
  title,
  formData,
  allFormData,

  disabled,
  selectedAgent,

  onClose,
  submitData,
  setNewAgentContact,
  searchAgent,
  onChangeValue,
}) => {
  const { commission_amount, referral_sources } = formData;

  const [states, setStates] = useState({
    searchInternalAgentContact: "",
    inputError: "",
  });

  const onChange = (context, value) => {
    setStates((prevState) => ({
      ...prevState,
      [context]: value,
    }));
  };

  const { searchInternalAgentContact, inputError } = states;

  useEffect(() => {
    if (!_.isEmpty(selectedAgent)) {
      setNewAgentContact((prev) => ({
        ...prev,
        referral_id: selectedAgent.id,
      }));
    }
  }, [selectedAgent]);

  const search = () => {
    let tmp = _.find(allFormData, {
      contact_type_id: 3,
      internal_agent: {
        mobile_number: searchInternalAgentContact,
      },
    });

    if (tmp) {
      onChange("inputError", "User is already assigned.");
    } else {
      searchAgent(searchInternalAgentContact);
      onChange("inputError", "");
    }
  };

  const onChangeIsSelected = (index) => {
    let temp = _.cloneDeep(formData);
    temp.referral_sources[index].is_selected =
      !temp.referral_sources[index].is_selected;
    setNewAgentContact(temp);
  };

  const onChangeReferralSources = (index, val) => {
    let temp = _.cloneDeep(formData);
    temp.referral_sources[index].amount = val;
    setNewAgentContact(temp);
  };

  const renderAgentProfile = ({
    avatar_url,
    full_name,
    email,
    mobile_number,
    team,
  }) => {
    return (
      <div className={`row no-gutters`} style={{ gridGap: "1rem" }}>
        <div
          className="col-lg-4 col-md-12 mb-10 p-0"
          style={{ textAlign: "center" }}
        >
          <img
            alt={`Internal Agent`}
            src={avatar_url ? avatar_url : ProfileImage1}
            onError={(e) => {
              if (e.target) {
                e.target.src = ProfileImage1;
              }
            }}
            style={{
              borderRadius: "50%",
              width: 120,
              height: 120,
              objectFit: "cover",
            }}
          />
        </div>
        {
          <div className={"col-lg-7 col-12 mt-10"}>
            <h6 className="at-subsales_claims-profile_name">{`${full_name}`}</h6>
            <p>{`${email}`}</p>
            <p>{`${mobile_number}`}</p>
            <p>{team ? `${team} team` : ""}</p>
          </div>
        }
      </div>
    );
  };

  const createReferralInternalAgent = () => (
    <>
      <div className="at-form_field-col-12">
        <AlertBox
          mode={"info"}
          containerClassName="mb-3"
          description={
            "Search for internal agent (If you have cobroke with IQI agent, add the agent by searching his mobile number)"
          }
        />
        <div className="at-form_field-col-12">
          <AtlasFormInput
            inputError={inputError}
            className={"mb-10"}
            type={"text"}
            value={`${searchInternalAgentContact}`}
            onChangeValue={(val) => onChange("searchInternalAgentContact", val)}
            placeholder={"e.g. 60123456789"}
          />
          <button
            className="btn-new btn-new--secondary mb-3"
            disabled={_.isEmpty(searchInternalAgentContact)}
            onClick={() => search()}
          >
            Search
          </button>
        </div>
        <div className="at-form_field-col-12">
          {!_.isEmpty(selectedAgent) && (
            <Card className="profile-card" style={{ gridGap: "1rem" }}>
              {renderAgentProfile({
                avatar_url: selectedAgent.avatar_url || "",
                full_name: selectedAgent.full_name || "",
                email: selectedAgent.email || "",
                mobile_number: selectedAgent.mobile_contact_number || "",
                team: selectedAgent.team_name || "",
              })}
              <button
                style={{ marginLeft: "auto" }}
                disabled={disabled}
                className="btn-new btn-new--primary d-block"
                onClick={() => {
                  submitData({
                    ...formData,
                    referral_type: "User",
                  });
                }}
              >
                Assign as Referral
              </button>
            </Card>
          )}
        </div>
      </div>
    </>
  );

  const updateInternalAgent = () => (
    <>
      <section className="at-form_field-col-12">
        <Chip className="mb-2" color={"primary"} label={"Internal Agent"} />
        <Card className={"profile-card no-gutters"}>
          {renderAgentProfile({
            avatar_url: formData.internal_agent.avatar_url || "",
            full_name: formData.internal_agent.full_name || "",
            email: formData.internal_agent.email || "",
            mobile_number: formData.internal_agent.mobile_number || "",
            team: formData.internal_agent.team || "",
          })}
        </Card>
      </section>
      <section className="at-form_field-col-6">
        <h2 className="at-form-input__title">Referral Fee Amount (RM)</h2>
        <AtlasFormInput
          type="text"
          disabled={disabled}
          value={`${commission_amount}`}
          onChangeValue={(val) => onChangeValue("commission_amount", val)}
        />
      </section>
      {!_.isNaN(commission_amount) &&
        commission_amount > 0 &&
        title !== "Add" &&
        commission_amount && (
          <section className="at-subsales_claims-commission_deduct-cont at-form_field-col-6">
            <h2 className="at-form-input__title">
              {"Deduct referral fee from (RM)"}
            </h2>
            {referral_sources &&
              referral_sources.length > 0 &&
              referral_sources.map((item, index) => (
                <div
                  key={index}
                  className="w-auto at-subsales_claims-commission_deduct-list p-0 d-inline-flex"
                >
                  <CustomCheckbox
                    labelClassname={"mr-10"}
                    content={item.internal_agent_display_name}
                    checked={item.is_selected}
                    onChangeCheckboxValue={() => onChangeIsSelected(index)}
                  />
                  <TextField
                    disabled={disabled}
                    value={item.amount}
                    onChange={(e) =>
                      onChangeReferralSources(index, e.target.value)
                    }
                  />
                </div>
              ))}
            {!(referral_sources && referral_sources.length > 0) && (
              <div>No referrals found.</div>
            )}
            <p>
              Note: Referral commission should not be more than 20% of
              commission received.
            </p>
          </section>
        )}
      <section className="at-modal_dialog-footer d-flex grid_gap-1" style={{left: 0}}>
        <button
          className="btn-new btn-new--success"
          onClick={() =>
            submitData({
              ...formData,
              referral_sources_attributes: formData.referral_sources,
            })
          }
        >
          Update
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={onClose}
        >
          Close
        </button>
      </section>
    </>
  );

  return (
    <>
      {title === "Add" && createReferralInternalAgent()}
      {title === "Edit" && updateInternalAgent()}
    </>
  );
};

export default InternalAgent;
