import React, { useState } from "react";
import _ from "lodash";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import TableContent from "./content";
import TableSearchParams from "./searchParam";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";
import "./styles.scss";
import "react-tabs/style/react-tabs.css";

const PaginatedTable = ({
  rowData,
  onClickEdit,
  searchParams,
  getListAPI,
  totalPages,
  meta,
  onChangeSearchParams,
}) => {
  const [interval, setInterval] = useState(10);

  const clearFilterAction = () => {
    let tmp = _.cloneDeep(searchParams);
    tmp.map((item) => {
      item.param = "";
    });
    onChangeSearchParams(tmp);
    getListAPI(1, "");
  };

  const renderTableContent = () => {
    const isSearching = searchParams.some((item) => item.param);

    if (rowData && rowData.length > 0) {
      return (
        <div className="at-table_card">
          {rowData.map((item, index) => (
            <TableContent
              key={`product_catalog-content-${index}`}
              data={item}
              onClickEdit={onClickEdit}
            />
          ))}
        </div>
      );
    }

    return (
      <>
        {!isSearching && (
          <EmptyState
            title={`No products available`}
            description={`Please stay tune for more exciting products`}
            renderIcon={<AtlasIcon svgHref="atlas-note" />}
          />
        )}
        {isSearching && (
          <div className="at-empty-state">
            <ul className="at-table-row grid-controller">
              <li className="at-table-row__item at-table-row__item-empty">
                <p>No result found</p>
                <span>
                  Try to adjust your search or filter options to find something
                  you're looking for
                </span>
                <br />
                {clearFilterAction && (
                  <button onClick={clearFilterAction}>Clear Filters</button>
                )}
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };

  const renderPaginationText = () => {
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  const renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            getListAPI(1, tmp);
            setInterval(10);
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${interval <= 10 || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(interval);
            setInterval(tmp - 10);
          }}
        >
          ...
        </button>
        {totalPages &&
          totalPages.map((item) => {
            if (item + 1 <= interval && item + 1 > interval - 10) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${meta && meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    searchParams.map((item) => {
                      tmp = tmp + `q[${item.value}]=${item.param}&`;
                    });
                    getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && interval >= meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(interval);
            setInterval(tmp + 10);
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            getListAPI(meta.pages, tmp);
            setInterval(meta.pages);
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  return (
    <div className="at-product_catalog-table">
      <TableSearchParams
        searchParams={searchParams}
        onChangeSearchParams={onChangeSearchParams}
        getListAPI={(page, search) => {
          getListAPI(page, search);
        }}
      />
      {renderTableContent()}
      {meta && meta.count > 0 && (
        <div className="at-pagination__cont">
          {renderTablePagination()}
          {renderPaginationText()}
        </div>
      )}
    </div>
  );
};

export default PaginatedTable;
