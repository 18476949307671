import { LeadType } from "dictionary/lead";
import { priorityTypes } from "../assets";

export const Sources = [
  {
    value: 1,
    label: "IQI Web",
  },
  {
    value: 2,
    label: "Juwai",
  },
  {
    value: 3,
    label: "Digital Marketing",
  },
];

export const StatusOptions = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Contacted & Pending Response",
  },
  {
    value: 3,
    label: "Completed",
  },
  {
    value: 4,
    label: "Not Interested",
  },
  {
    value: 5,
    label: "Scheduled Interview",
  },
  {
    value: 6,
    label: "Interviewed",
  },
  {
    value: 7,
    label: "Hired",
  },
  {
    value: 8,
    label: "Pending",
  },
  {
    value: 9,
    label: "Not Qualified",
  },
  {
    value: 10,
    label: "Reconnect",
  },
  {
    value: 11,
    label: "Keep In View",
  },
];

export const superAdminSearchParams = (id) => [
  {
    label: "Name",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "buyer_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact Number",
    value: "buyer_phone_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Admin Email",
    value: "admin_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Creation Date from",
    value: "created_at_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Assign Date from",
    value: "assign_date_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Inquiry Date from",
    value: "inquiry_date_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Creation Date to",
    value: "created_at_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Assign Date to",
    value: "assign_date_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Inquiry Date to",
    value: "inquiry_date_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Type",
    value: "type_id_eq",
    type: "select",
    param: "",
    options: LeadType,
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "select",
    param: "-",
    options: StatusOptions,
  },
  {
    label: "Assigned Admin",
    value: "admin_id_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Assigned to me", value: `${id}` },
    ],
  },
  {
    label: "Assign Status",
    value: "",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "[user_id_not_eq]=0&", label: "Agent assigned" },
      { value: "[user_id_eq]=0&", label: "Agent unassigned" },
    ],
  },
  {
    label: "Priority",
    value: "priority_id_eq",
    type: "radio",
    param: "",
    options: priorityTypes,
  },
];

export const adminSearchParams = (id) => [
  {
    label: "Name",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "buyer_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact Number",
    value: "buyer_phone_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Creation Date from",
    value: "created_at_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Assign Date from",
    value: "assign_date_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Inquiry Date from",
    value: "inquiry_date_dategteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Creation Date to",
    value: "created_at_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Assign Date to",
    value: "assign_date_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Inquiry Date to",
    value: "inquiry_date_datelteq",
    type: "date",
    col: 4,
    param: "",
  },
  {
    label: "Type",
    value: "type_id_eq",
    type: "select",
    param: "",
    options: LeadType,
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "select",
    param: "-",
    options: StatusOptions,
  },
  {
    label: "Assigned Admin",
    value: "admin_id_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Assigned to me", value: `${id}` },
    ],
  },
  {
    label: "Assign Status",
    value: "",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "[user_id_not_eq]=0&", label: "Agent assigned" },
      { value: "[user_id_eq]=0&", label: "Agent unassigned" },
    ],
  },
  {
    label: "Priority",
    value: "priority_id_eq",
    type: "radio",
    param: "",
    options: priorityTypes,
  },
];

export const searchParams = [
  {
    label: "Name",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "buyer_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact Number",
    value: "buyer_phone_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Assign Date from",
    value: "assign_date_dategteq",
    type: "date",
    param: "",
  },
  {
    label: "Inquiry Date from",
    value: "inquiry_date_dategteq",
    type: "date",
    param: "",
  },
  {
    label: "Assign Date to",
    value: "assign_date_datelteq",
    type: "date",
    param: "",
  },
  {
    label: "Inquiry Date to",
    value: "inquiry_date_datelteq",
    type: "date",
    param: "",
  },
  {
    label: "Type",
    value: "type_id_eq",
    type: "select",
    param: "",
    options: LeadType.filter((item) => item.label !== "Partnership"),
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "select",
    param: "-",
    options: StatusOptions,
  },
  {
    label: "Source",
    value: "lead_source_cont",
    type: "input",
    param: "",
  },
  {
    label: "Priority",
    value: "priority_id_eq",
    type: "radio",
    param: "",
    options: priorityTypes,
  },
];

export const leadsOptions = [
  { value: "additional_comments", label: "Additional Comments" },
  { value: "budget", label: "Budget" },
  { value: "buyer_email", label: "Email" },
  { value: "buyer_name", label: "Name" },
  { value: "buyer_phone_number", label: "Phone Number" },
  { value: "buyer_wechat", label: "Wechat" },
  { value: "close_date", label: "Close Date" },
  { value: "current_city", label: "Current City" },
  { value: "current_country", label: "Current Country" },
  { value: "current_state", label: "Current State" },
  { value: "initial_comments", label: "Initial Comments" },
  { value: "language", label: "Language" },
  { value: "motivation", label: "Motivation" },
  { value: "property_category", label: "Property Category" },
  { value: "purchase_timing", label: "Purchase Timing" },
  { value: "remark", label: "Remark" },
  { value: "target_city", label: "Target City" },
  { value: "target_country", label: "Target Country" },
  { value: "target_state", label: "Target State" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
];
