import React, { useState } from "react";
import _ from "lodash";
import { FormControl, FormGroup, FormLabel } from "@material-ui/core";

import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";

const EmailDraftContent = ({
  formId,
  fromTypeId,
  includeInventory,
  eProcessPartyData = [],
  sendDraftEmail,
  showLoading,
  onClose,
}) => {
  const [selectedRecipients, onChangeRecipients] = useState([]);
  const [showIncludeChecklist, setShowIncludeChecklist] = useState(false);
  
  return (
    <>
      <ModalDialog
        title={"Draft Email"}
        onClose={onClose}
        children={
          <>
            <FormControl className={"w-100"}>
              <FormLabel className={"mb-10"}>
                Send a draft version of confirmation form to the selected
                recipients.
              </FormLabel>
              <FormGroup>
                {eProcessPartyData.map((partyData) => (
                  <CustomCheckbox
                    content={`${partyData.full_name} (${partyData.email})`}
                    checked={selectedRecipients.indexOf(partyData.id) !== -1}
                    onChangeCheckboxValue={() => {
                      let temp = _.cloneDeep(selectedRecipients);
                      let tempIndex = temp.indexOf(partyData.id);
                      if (tempIndex === -1) {
                        temp.push(partyData.id);
                      } else {
                        temp.splice(tempIndex, 1);
                      }
                      onChangeRecipients(temp);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              className={"btn-new btn-new--primary"}
              disabled={selectedRecipients.length < 1}
              onClick={() => {
                if (fromTypeId === 2 && includeInventory) {
                  setShowIncludeChecklist(true);
                  return;
                }
                sendDraftEmail(selectedRecipients, formId)
              }}
            >
              Send Now
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        }
      />
      <ConfirmationModal
        open={showIncludeChecklist}
        title={"Send Email for Review"}
        message={
          <>
            You’ve chosen to include the inventory checklist with the confirmation form. <br />
            Should the draft also include client consent to use the e-signature process for the inventory list?
            <ul className={'text-left fs-2 px-4'} style={{listStyleType: 'disc'}}>
              <li>Yes, include e-signature: The agreement will reflect the e-signing of both documents.</li>
              <li>No, follow standard signing: The agreement will maintain the standard procedure of separate signing for the inventory list during property handover.</li>
            </ul>
          </>
        }
        onClose={() => setShowIncludeChecklist(false)}
        positiveText={"Yes, include e-signature"}
        positiveAction={() => sendDraftEmail(selectedRecipients, formId, true)}
        negativeText={"No, follow standard signing"}
        negativeAction={() => sendDraftEmail(selectedRecipients, formId)} />
      {showLoading && <LoadingModal />}
    </>
  );
};

export default EmailDraftContent;
