import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IconButton } from "@material-ui/core";

import "./index.scss";

const AtlasCloseButton = ({
  onClick,
  buttonStyle,
  containerStyle,
  containerClass,
  closeIconStyle,
  closeIconColor,
  disabled,
}) => {
  return (
    <div
      style={{
        ...containerStyle,
      }}
      className={`${containerClass || ""}`}
    >
      <IconButton
        className={"at-close-responsive"}
        style={{
          display: "flex",
          borderRadius: "50%",
          backgroundColor: "#F18E05",
          padding: "0px 7px",
          height: 30,
          ...buttonStyle,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <AiOutlineClose
          style={{ width: 16, ...closeIconStyle }}
          color={closeIconColor ? closeIconColor : "#FFFF"}
        />
      </IconButton>
    </div>
  );
};

export default AtlasCloseButton;
