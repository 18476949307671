import React, { Component } from "react";
import { compose } from "redux";
import Moment from "moment";

import CustomTable from "components/NewTable";
import DeleteConfirmationModal from "components/Modal/delete";
import ConfirmationModal from "components/Modal/confirmation";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasIcon from "components/Icon/atlasIcon";
import PaymentModal from "../MeetingRooms/components/PaymentModal";
import RefundTerms from "./RefundTerms";

import EditModal from "./edit";
import ViewModal from "./view";

import MyBookingsHOC from "../actions/myBookings";
import VersionCheckHOC from "actions/versionCheck";
import { MyBookingColumnData } from "./assets";

class MyBookings extends Component {
  renderDeleteModal = () => {
    const {
      onLoadBooking,
      selectedBooking,
      deleteMyBooking,
      onChangeMyBooking,
    } = this.props;

    return (
      <DeleteConfirmationModal
        open={true}
        loading={onLoadBooking}
        title={"Remove Booking"}
        message={"Are you sure you want to remove this room reservation?"}
        positiveAction={() => deleteMyBooking(selectedBooking)}
        negativeAction={() =>
          onChangeMyBooking(false, "showConfirmDeleteModal")
        }
      />
    );
  };

  renderRefundTermsModal = () => {
    return (
      <ModalDialog
        title={"Refund terms and conditions"}
        maxWidth={"md"}
        children={<RefundTerms/>}
        onClose={() =>
          this.props.onChangeMyBooking(false, "showRefundTerms")
        }
      />
    )
  }

  renderPaymentFailedModal = () => {
    return (
      <ModalDialog
        children={
          <div className="d-flex flex-column g-3">
            <div className={"text-center"}>
              <div className="at-confirmation_modal-icon">
                <AtlasIcon
                  svgHref={"atlas-danger"}
                  style={{ fill: "#F04438", backgroundColor: "#FEE4E2" }}
                />
              </div>
              <br />
              <h4 className="fs-4 fw-600">Booking Payment Fail</h4>
              <p className={"fs-2 mt-10 mb-20"}>The booking payment has failed. Please try again or contact our support team for assistance.</p>
            </div>
            <button
              className="btn-new btn-new--primary w-100"
              onClick={() => this.props.onChangeMyBooking(false, "showPaymentFailedModal")}>
              OK
            </button>
          </div>
        }
      />
    )
  }

  renderCardContent = () => {
    const { 
      myBookingLists, 
      onChangeMyBooking, 
      getSelectedBooking 
    } = this.props;

    return (
      <>
        <CustomTable
          hideSearch={true}
          selectPerPageOption={20}
          pagination={true}
          columns={MyBookingColumnData}
          rowData={myBookingLists}
          emptyStateTitle={`You don't have any bookings`}
          actionColumStyle={{ minWidth: 140 }}
          actionColumnContent={[
            {
              name: "view",
              onClick: (rowData) => getSelectedBooking(rowData.id),
              onShow: (rowData) => rowData.meeting_room_type_id === 2
            },
            {
              name: "edit",
              disabled: (rowData) => {
                let allowableEditTime = Moment(rowData.start_time).subtract(
                  15,
                  "minutes"
                );
                return allowableEditTime.isBefore(Moment());
              },
              onClick: (rowData) => {
                onChangeMyBooking(rowData, "selectedBooking");
                onChangeMyBooking(true, "showEditMyBookingModal");
              },
              onShow: (rowData) => rowData.meeting_room_type_id === 1
            },
            {
              name: "delete",
              disabled: (rowData) => {
                let allowableEditTime = Moment(rowData.start_time).subtract(
                  15,
                  "minutes"
                );
                return allowableEditTime.isBefore(Moment());
              },
              onClick: (rowData) => {
                onChangeMyBooking(rowData, "selectedBooking");
                onChangeMyBooking(true, "showConfirmDeleteModal");
              },
              onShow: (rowData) => rowData.meeting_room_type_id === 1
            },
          ]}
        />
      </>
    );
  };

  render = () => {
    const {
      selectedBooking,
      calendarEvent,
      disableUpdate,
      onLoadBooking,
      showPaymentModal,
      showBookingDetails,
      showEditMyBookingModal,
      showConfirmDeleteModal,
      showPaymentConfirmation,
      showRefundTerms,
      showPaymentFailedModal,
      updateMyBooking,
      onChangeMyBooking,
      getCalendarEvent,
      makePayment,
    } = this.props;

    return (
      <>
        {this.renderCardContent()}
        {showEditMyBookingModal && (
          <EditModal
            calendarEvent={calendarEvent}
            getCalendarEvent={getCalendarEvent}
            disableUpdate={disableUpdate}
            updateMyBooking={updateMyBooking}
            onChangeMyBooking={onChangeMyBooking}
            selectedBooking={selectedBooking}
          />
        )}
        {showBookingDetails && (
          <ViewModal
            selectedBooking={selectedBooking}
            onChangeMyBooking={onChangeMyBooking}
          />
        )}
        {showConfirmDeleteModal && this.renderDeleteModal()}
        {showRefundTerms && this.renderRefundTermsModal()}
        {showPaymentFailedModal && this.renderPaymentFailedModal()}
        {showPaymentModal && (
          <PaymentModal
            data={this.props.data}
            selectedRoom={{ title: selectedBooking.meeting_room_name }}
            paymentDetails={this.props.paymentDetails}
            makePayment={this.props.makePayment}
            cancelPayment={this.props.cancelPayment}
          />
        )}
        <ConfirmationModal
          open={showPaymentConfirmation}
          loading={onLoadBooking}
          title={"Proceed To Payment"}
          message={
            "Click on “Confirm” button you will be redirected to the payment page."
          }
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() => {
            onChangeMyBooking(
              {
                ...selectedBooking,
                order_id: selectedBooking.order_info?.id,
                paymemt_id: selectedBooking.order_info?.id,
                payment_desc: `Booking Payment for Business Lounge`,
                amount: selectedBooking.order_info?.total_amount || 0,
              },
              "paymentDetails"
            );
            makePayment({
              provider: "Ipay88",
              type: "Booking",
              amount: selectedBooking.order_info?.total_amount || 0,
              description: `Booking Payment for Business Lounge`,
              user_id: selectedBooking.user_id,
              order_id: selectedBooking.order_info?.id || "",
              product_id: selectedBooking.id || "",
            });
          }}
          negativeAction={() =>
            onChangeMyBooking(false, "showPaymentConfirmation")
          }
        />
        {onLoadBooking && <LoadingModal />}
      </>
    );
  };
}

export default compose(MyBookingsHOC, VersionCheckHOC)(MyBookings);
