import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";

const MotdOverlay = ({ onChangeMOTDHOC, previewMOTD, onLoadMOTD }) => {
  const [timer, setTimer] = useState("");

  const onChangeMOTD = (context, val) => {
    let temp = _.cloneDeep(previewMOTD);
    temp[context] = val;
    onChangeMOTDHOC(temp, "previewMOTD");
  };

  const playFinish = (duration) => {
    if (duration) {
      let tmp = setTimeout(() => {
        previewMOTD.audio.pause();
        onChangeMOTD("play", false);
      }, duration);
      setTimer(tmp);
    } else {
      clearTimeout(timer);
    }
  };

  const onClickPlay = () => {
    previewMOTD.audio.play();
    onChangeMOTD("play", true);
    playFinish(previewMOTD.audio.duration * 1000);
  };

  const onClickPause = () => {
    previewMOTD.audio.pause();
    onChangeMOTD("play", false);
    playFinish();
  };

  const skipAndClose = () => {
    previewMOTD.audio.pause();
    onChangeMOTD("play", false);
    onChangeMOTDHOC(false, "showMOTDPreview");
    playFinish();
  };

  const renderCardContent = () => (
    <div className="m-auto">
      <img
        className="at-motd-mngm__preview-image"
        src={previewMOTD.image_url}
        alt={previewMOTD.name}
      />
      {previewMOTD.audio_url && (
        <div className="at-add-motd__preview-play-btn-container">
          <CustomButton
            className={"at-btn at-template__motd-play-btn"}
            children={previewMOTD.play ? "PAUSE" : "PLAY"}
            onClick={() => (previewMOTD.play ? onClickPause() : onClickPlay())}
          />
        </div>
      )}
      <CustomButton
        containerStyle={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        className={"at-btn at-template__motd-close-btn "}
        children={previewMOTD.play ? "Skip and close" : "Close"}
        onClick={() => skipAndClose()}
      />
    </div>
  );

  return (
    <div className="at-motd-mngm__preview-overlay">
      {renderCardContent()}
      {onLoadMOTD && <LoadingModal />}
    </div>
  );
};

export default MotdOverlay;
