import React, { Component } from "react";
import { compose } from "redux";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import UpdateTeamDetails from "./Edit";
import CustomTable from "./Table";

import WithTeams from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";
import "./index.scss";

const ColumnData = [
  {
    header: "Code",
    searchFlag: "name",
    accessor: "name",
  },
  {
    header: "Display Name",
    searchFlag: "display_name",
    accessor: "display_name",
  },
];

class TeamsManagement extends Component {
  componentDidMount() {
    this.props.getTeams();
    initGA("/dashboard/admin/team-management");
  }

  render = () => (
    <>
      <ModuleHeader title={"Teams"} moduleIcon={"icon-team"} />
      <CustomCard
        cardContent={
          <CustomTable
            columns={ColumnData}
            pagination={true}
            rowData={this.props.teams || []}
            actionColumnContent={[
              {
                name: "edit",
                onClick: (rowData) => this.props.getSelectedTeam(rowData.id),
              },
            ]}
          />
        }
      />
      {this.props.showUpdateTeam && <UpdateTeamDetails {...this.props} />}
      {this.props.onLoadTeams && <LoadingModal />}
    </>
  );
}

export default compose(WithTeams, VersionCheckHOC)(TeamsManagement);
