import React, { useState } from "react";
import Moment from "moment";
import _ from "lodash";
import { MenuItem, Collapse } from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import DatePicker from "react-datepicker";

import AtSelectNew from "components/Select/new";
import AtInput from "components/Input/formInput";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";
import DayRangePicker from "components/DayRangePicker";

const SearchContent = ({
  mode,
  searchLabel,
  searchParams,
  showResetButton = true,

  getListAPI,
  resetListAPI,
  renderSearchMode,
  onToggleSearchModal,
  onChangeSearchParams,
}) => {
  const [showMoreFilter, onToggleShow] = useState(false);

  const onChangeFilter = (index, value) => {
    let tmp = _.cloneDeep(searchParams);
    tmp[index].param = value;
    return onChangeSearchParams(tmp);
  };

  const renderInputForm = (item, index) => (
    <>
      {item.type === "input" && (
        <AtInput
          className={"mb-0"}
          value={item.param}
          placeholder={item.label}
          style={{ marginBottom: 0 }}
          onChangeValue={(value) => onChangeFilter(index, value)}
        />
      )}
      {item.type === "select" && (
        <AtSelectNew
          value={item.param}
          onChange={(e) => onChangeFilter(index, e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options &&
            item.options.map((option) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.valueKey ? option[item.valueKey] : option.value}
                value={item.valueKey ? option[item.valueKey] : option.value}
                style={{ fontSize: 14 }}
              >
                {option.label}
              </MenuItem>
            ))}
        </AtSelectNew>
      )}
      {item.type === "radio" && (
        <div className={"d-flex flex-wrap g-2"}>
          {item.options &&
            item.options.map((option, optionIndex) => {
              return (
                <button
                  key={`paginated_table-radio_option-${optionIndex}`}
                  className={`btn-radio ${item.param === option.value ? "btn-radio-selected" : "btn-radio-outline"}`}
                  onClick={() => onChangeFilter(index, option.value)}
                >
                  {option.label}
                </button>
              );
            })}
        </div>
      )}
      {item.type === "typeahead" && (
        <CustomTypeahead
          filterBy={item.filterBy}
          showClearButton={item.showClearButton}
          containerClassName={"mt-0"}
          typeaheadId={`paginated-typeahead-${item.value}`}
          placeholder={item.placeholder || item.label}
          options={item.options || []}
          containerStyle={{ ...item.containerStyle, marginBottom: 0 }}
          selectedValue={item.param ? [item.param] : []}
          labelKey={item.labelKey}
          onSearch={(val) => onChangeFilter(index, val)}
          onSelect={(val) => {
            if (val && val.length > 0) {
              onChangeFilter(index, val[0]);
            }
          }}
          childrenHead={(rowItem) =>
            item.typeaheadChildren ? item.typeaheadChildren(rowItem) : false
          }
        />
      )}
      {item.type === "select-multi" && (
        <AtSelectNew
          value={item.param}
          multiple={true}
          onChange={(e) => onChangeFilter(index, e.target.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options &&
            item.options.map((option) => (
              <MenuItem
                classes={{ root: `at-select__dropdown-item` }}
                key={item.valueKey ? option[item.valueKey] : option.value}
                value={item.valueKey ? option[item.valueKey] : option.value}
                style={{ fontSize: 14 }}
              >
                {option.label}
              </MenuItem>
            ))}
        </AtSelectNew>
      )}
      {item.renderField &&
        item.renderField({
          item,
          searchParams: searchParams,
          index: index,
          onChangeSearchParams: onChangeSearchParams,
        })}
      {item.type === "day-range" && (
        <DayRangePicker
          searchParams={searchParams}
          endDateKey={item.endDateKey || "end_date"}
          startDateKey={item.startDateKey || "start_date"}
          initialValue={item.initialValue}
          onChange={(val) => onChangeFilter(index, val)}
        />
      )}
      {item.type === "date" && (
        <div className="at-form-input">
          <AtlasIcon
            className={"at-form-input__calendar"}
            svgHref={"atlas-calendar-2-linear"}
          />
          <DatePicker
            withPortal
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dateFormat={item.format || "YYYY-MM-DD"}
            className="at-form-input__input"
            value={item.param}
            selected={ 
              item.param && item.param !== ""
              ? Moment(item.param, item.format || "YYYY-MM-DD").toDate()
              : item.param
            }
            placeholderText={item.label}
            onChange={(val) => {
              onChangeFilter(
                index,
                Moment(val).format(item.format || "YYYY-MM-DD"),
              )
            }}
          />
        </div>
      )}
    </>
  );

  const renderSearchReset = () => (
    <div className="d-flex">
      {showResetButton && (
        <button
          className="btn-new btn-new--outline-secondary mr-10"
          onClick={() => {
            let tmp = _.cloneDeep(searchParams);
            tmp.map((item) => {
              if (item.type === "select-multi") {
                item.param = [];
              } else if (item.type === "date_time") {
                item.param = Moment();
              } else {
                item.param = "";
              }
            });
            onChangeSearchParams(tmp);
            resetListAPI();

            if (mode === "mobile") {
              onToggleSearchModal(false);
            }
          }}
        >
          Reset
        </button>
      )}
      <button
        className="btn-new btn-new--secondary"
        onClick={() => {
          let tmp = "";
          searchParams.map((item) => {
            tmp =
              item.param && item.param !== ""
                ? item.value
                  ? tmp +
                    `q[${item.value}]=${
                      item.type === "typeahead"
                        ? item.param.name
                        : item.type === "date_time"
                          ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                          : item.param
                    }&`
                  : tmp + `q${item.param}&`
                : tmp;
          });
          getListAPI(1, tmp);

          if (mode === "mobile") {
            onToggleSearchModal(false);
          }
        }}
      >
        {searchLabel || "Search"}
      </button>
    </div>
  );

  const renderDesktopMode = () => (
    <>
      <div className={"row"}>
        {renderSearchMode && (
          <div className="col-md-12">{renderSearchMode}</div>
        )}
        {searchParams &&
          searchParams.map((item, index) => {
            if (index < 3 && !item.additional) {
              return (
                <div key={item.value} className={`col-md-${item.col || 4}`}>
                  <label
                    style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}
                  >
                    {item.label}
                  </label>
                  <br />
                  {renderInputForm(item, index)}
                </div>
              );
            }
          })}
      </div>
      {searchParams && searchParams.length > 3 && (
        <Collapse in={showMoreFilter} timeout="auto" unmountOnExit>
          <div className={"row"} style={{ marginBottom: 16 }}>
            {searchParams &&
              searchParams.map((item, index) => {
                if (index > 2 && !item.additional) {
                  return (
                    <div key={item.value} className={`col-md-${item.col || 4}`}>
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {item.label}
                      </label>
                      <br />
                      {renderInputForm(item, index)}
                    </div>
                  );
                }
              })}
          </div>
        </Collapse>
      )}
      <div className="d-flex mt-1">
        {searchParams && searchParams.length > 3 && (
          <button
            className="btn-new mr-2"
            style={{ color: "#2563EB" }}
            onClick={() => onToggleShow(!showMoreFilter)}
          >
            {showMoreFilter && "Hide Filter"}
            {!showMoreFilter && "More Filter"}
            <BsChevronDown
              className="at-table-search__chevron"
              style={{ transform: showMoreFilter ? "rotateZ(-180deg)" : "" }}
            />
          </button>
        )}
        {renderSearchReset()}
      </div>
    </>
  );

  return (
    <>
      {mode === "desktop" && renderDesktopMode()}
      {mode === "mobile" && (
        <ModalDialog
          title={"Filter & Search"}
          onClose={() => onToggleSearchModal(false)}
          children={
            <div className="row mb-10">
              {renderSearchMode && (
                <div className="col-md-12">{renderSearchMode}</div>
              )}
              {searchParams &&
                searchParams.map((item, index) => {
                  if (!item.additional && item.value) {
                    return (
                      <div
                        key={item.value}
                        className={`col-md-${item.col ? item.col : 6} mb-3`}
                      >
                        <label
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: 5,
                          }}
                        >
                          {item.label}
                        </label>
                        <br />
                        {renderInputForm(item, index)}
                      </div>
                    );
                  }
                })}
            </div>
          }
          footer={
            <div className="at-table__search-action">{renderSearchReset()}</div>
          }
        />
      )}
      {!mode && (
        <>
          <div className="form-row gy-3 mb-10">
            {searchParams &&
              searchParams.map((item, index) => {
                if (!item.additional) {
                  return (
                    <div
                      key={item.value}
                      className={`col-md-${item.col ? item.col : 6} mb-3`}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {item.label}
                      </label>
                      <br />
                      {renderInputForm(item, index)}
                    </div>
                  );
                }
              })}
          </div>
          {renderSearchReset()}
        </>
      )}
    </>
  );
};

export default SearchContent;
