import React, { Component, createRef } from "react";
import _, { flatMap, flatten, wrap } from "lodash";
import { compose } from "redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import DetailsModal from "./components/detailsDialog";
import ActionTab from "./components/Tab";

import AdminOrderManagementHOC from "./actions";
import OrderDownloadHOC from "./actions/download";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

import { ColumnData } from "./assets";

const statusOption = [
  { id: 0, label: "Pending" },
  { id: 1, label: "Processing" },
  { id: 2, label: "Arrived" },
  { id: 3, label: "Collected" },
];

class AdminOrderManagement extends Component {
  componentDidMount = () => {
    this.cardRef = createRef();
    this.props.getOrders(1, "");

    let temp = _.cloneDeep(this.props.searchParams);
    let delivery_branch_index = _.findIndex(temp, { label: "Delivery Branch" });
    let agent_branch_index = _.findIndex(temp, { label: "Agent Branch" });
    temp[delivery_branch_index].options = this.props.data.branchReducer.branch;
    temp[agent_branch_index].options = this.props.data.branchReducer.branch;

    this.props.onChangeOrderManagementHOC(temp, "searchParams");
  };

  onHandleCheckAllBox = () => {
    const { orders } = this.props;
    let tempOrders = orders.data.filter((item) => {
      return item.status_id !== 4 && item.status_id !== 3;
    });
    let tempNewChecked = [];

    if (this.props.checkedArray.length < tempOrders.length) {
      tempOrders.map((item) => {
        tempNewChecked.push(item.id);
      });
    } else {
      tempNewChecked = [];
    }
    this.props.onChangeOrderManagementHOC(tempNewChecked, "checkedArray");
  };

  onHandleCheckBox = (id) => {
    let tempIndex = this.props.checkedArray.indexOf(id);
    let temp = _.cloneDeep(this.props.checkedArray);

    tempIndex === -1 ? temp.push(id) : temp.splice(tempIndex, 1);

    this.props.onChangeOrderManagementHOC(temp, "checkedArray");
  };

  onCheckItemCheckBoxValue = (data, value, index) => {
    this.props.updateMarketOrderItemCollectStatus(data, value, index);
  };

  renderCardContent = () => {
    const {
      checkedArray,
      checkableData,
      orders,
      showCancelModal,
      chosenStatusId,
      getSelectedOrder,
      searchParams,
      getOrders,
      orderPage,
      onLoadOrderManagement,
      showConfirmationModal,
      showRejectModal,
      updateOrderStatus,
      onChangeOrderManagementHOC,
    } = this.props;

    let tempStatus = _.find(statusOption, { id: chosenStatusId });
    const { can_update } = permissionsChecker(
      "Atlas Store Orders",
      this.props.data,
    );

    return (
      <>
        <AtPaginatedTable
          columns={ColumnData({
            checkedArray,
            checkableData,
            can_update,
            onHandleCheckBox: this.onHandleCheckBox,
            onHandleCheckAllBox: this.onHandleCheckAllBox,
            onChangeItemCheckboxValue: this.onCheckItemCheckBoxValue,
          })}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeOrderManagementHOC(val, "searchParams")
          }
          rowData={orders.data ? orders.data : []}
          meta={orders.meta && orders.meta}
          getListAPI={getOrders}
          totalPages={orderPage}
          renderCheckBoxActions={can_update && this.renderCheckBoxActions}
          actionColumnContent={[
            {
              name: "view",
              onClick: (rowData) => getSelectedOrder(rowData.id),
            },
          ]}
        />
        <ConfirmationModal
          title={"Convert Coupon"}
          mode={"alert"}
          open={showConfirmationModal}
          loading={onLoadOrderManagement}
          message={`Are you sure you want to mark these selected items' status as ${tempStatus ? tempStatus.label : ""} ?`}
          positiveAction={() => updateOrderStatus(checkedArray)}
          negativeAction={() =>
            onChangeOrderManagementHOC(false, "showConfirmationModal")
          }
        />
        <ConfirmationModal
          mode={"alert"}
          title={"Convert Coupon"}
          loading={onLoadOrderManagement}
          open={showRejectModal}
          fieldLabel={"Reject Remark"}
          message={`Are you sure you want to mark these selected items' status as Rejected ?`}
          positiveAction={(val) => updateOrderStatus(checkedArray, val)}
          negativeAction={() =>
            onChangeOrderManagementHOC(false, "showRejectModal")
          }
        />
        <ConfirmationModal
          mode={"alert"}
          title={"Convert Coupon"}
          loading={onLoadOrderManagement}
          open={showCancelModal}
          fieldLabel={"Void Remark"}
          message={`Are you sure you want to mark these selected items' status as Voided ?`}
          positiveAction={(val) => updateOrderStatus(checkedArray, val)}
          negativeAction={() =>
            onChangeOrderManagementHOC(false, "showCancelModal")
          }
        />
      </>
    );
  };

  renderCheckBoxActions = () => {
    const {
      data,
      onChangeOrderManagementHOC,
      checkedArray,
      checkableData,
      onClickDownloadExcel,
    } = this.props;

    return (
      <div className="at-table--checkbox-actions">
        <div
          style={{ padding: 2 }}
          className="at-mobile-view__controller mb-1 mr-1"
        >
          <CustomCheckbox
            contentStyle={{ fontWeight: 500 }}
            labelClassname={"at-table-tab-checkbox_responsive"}
            disabled={checkableData.length === 0}
            content={
              checkedArray.length === checkableData.length &&
              checkableData !== 0
                ? "Deselect All"
                : "Select All"
            }
            checked={
              checkedArray.length > 0 &&
              checkedArray.length === checkableData.length
            }
            onChangeCheckboxValue={() => this.onHandleCheckAllBox()}
          />
        </div>
        <div style={{ display: "flex",flexDirection: "row",flexWrap: "wrap", justifyContent: "flex-start",width: "100%"}}>
          <ActionTab
            checkedArray={checkedArray}
            checkableData={checkableData}
            onClickDownloadExcel={onClickDownloadExcel}
            onClickCancel={(val) => {
              onChangeOrderManagementHOC(true, "showCancelModal");
              onChangeOrderManagementHOC(val, "chosenStatusId");
            }}
            onClickReject={(val) => {
              onChangeOrderManagementHOC(true, "showRejectModal");
              onChangeOrderManagementHOC(val, "chosenStatusId");
            }}
            onChangeItemStatus={(val) => {
              onChangeOrderManagementHOC(true, "showConfirmationModal");
              onChangeOrderManagementHOC(val, "chosenStatusId");
            }}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      showDetailsModal,
      selectedOrder,
      onLoadOrderDownload,
      onLoadOrderManagement,
      onChangeOrderManagementHOC,
    } = this.props;
    return (
      <>
        <ModuleHeader title={`Orders`} moduleIcon={"icon-cart"} />
        <CustomCard ref={this.cardRef} cardContent={this.renderCardContent()} />
        {(onLoadOrderManagement || onLoadOrderDownload) && <LoadingModal />}
        <AtlasDialog open={showDetailsModal}>
          <DetailsModal
            onLoad={onLoadOrderManagement}
            onClose={() =>
              onChangeOrderManagementHOC(false, "showDetailsModal")
            }
            onCheckItemCheckBoxValue={this.onCheckItemCheckBoxValue}
            selectedOrder={selectedOrder}
          />
        </AtlasDialog>
      </>
    );
  };
}

export default compose(
  AdminOrderManagementHOC,
  OrderDownloadHOC,
  VersionCheckHOC,
)(AdminOrderManagement);
