import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import { RiArrowDownSFill } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { AiOutlineUnorderedList } from "react-icons/ai";

import AtlasDialog from "components/Dialog";
import AtlasIcon from "components/Icon/atlasIcon";
import RecommendationCard from "components/Card/RecommendationCard";
import EmptyRecommendationCard from "components/Card/RecommendationCard/empty";
import AtlasCard from "components/Card";
import RecommendationList from "./RCCModalContent";
import permissionsChecker from "utils/permissionsChecker";
import AtlasConfirmationDialog from "components/Modal/confirmation";

import useOnScreen from "utils/hooks/useOnScreen";

export default function Recommend({
  data,
  onLoadSubsales,
  onLoadCobroke,
  initialRecommendation = [],
  fullRecommendationList,
  updateCobrokeStatus,
}) {
  const [showCollapse, onToggleCollapse] = useState(true);
  const [showFullRecommendation, onToggleRecommendation] = useState(false);

  return (
    <>
      <div className="at-subsales_listing__view-recommendation at-form__content">
        <div className="d-flex align-items-center">
          <div className="at-subsales-listing__view-title">
            <AtlasIcon
              svgHref={"atlas-shake-hand"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            Co-broke Recommendations Found
          </div>
          <RiArrowDownSFill
            style={{
              width: 20,
              height: 20,
              color: "#3B82F6",
              marginLeft: 10,
              cursor: "pointer",
              transform: `rotate(${showCollapse ? 0 : 180}deg)`,
            }}
            onClick={() => onToggleCollapse((prev) => !prev)}
          />
        </div>
        <Collapse in={showCollapse} timeout="auto" unmountOnExit>
          <p className="at-card__description mt-20">
            The Atlas Co-broke Recommendation Engine has found the following
            agents who have executed similar transactions to the property in
            this listing. The potential co-broke agents may have other buyers or
            tenants for a similar property. Review their details and click
            <span className="fw-600">Send Request</span> to send them a request
            and see if they are able to co-broke with you on this listing.
            <br />
            You can review the status and details in co-broke center.
          </p>
          <ScrollContent
            data={data}
            showCollapse={showCollapse}
            onLoad={onLoadCobroke || onLoadSubsales}
            fullRecommendationList={fullRecommendationList}
            initialRecommendation={initialRecommendation}
            updateCobrokeStatus={updateCobrokeStatus}
            onToggleRecommendation={onToggleRecommendation}
          />
        </Collapse>
      </div>
      <AtlasDialog open={showFullRecommendation}>
        <AtlasCard
          className={"h-100"}
          cardContent={
            <RecommendationList
              data={data}
              onLoad={onLoadCobroke || onLoadSubsales}
              updateCobrokeStatus={updateCobrokeStatus}
              fullRecommendationList={fullRecommendationList}
              onClose={() => onToggleRecommendation(false)}
            />
          }
        />
      </AtlasDialog>
    </>
  );
}

const ScrollContent = ({
  data,
  onLoad,
  showCollapse,
  fullRecommendationList,
  initialRecommendation,
  onToggleRecommendation,
  updateCobrokeStatus,
}) => {
  const { can_create } = permissionsChecker("Listing Centre", data);

  const [showPrevNav, onShowLeftNav] = useState(false);
  const [showNextNav, onShowRighNav] = useState(false);
  const [showConfirmation, onToggleConfirmation] = useState(false);
  const [selectedRCC, onChangeSelectedRCC] = useState(null);

  const recommedationScroll = useRef(null);
  const firstElementRef = useRef(null);
  const lastElementRef = useRef(null);

  const isShowingFirst = useOnScreen(firstElementRef);
  const isShowingLast = useOnScreen(lastElementRef);

  useEffect(() => {
    onShowRighNav(!isShowingFirst);
  }, [isShowingFirst]);

  useEffect(() => {
    onShowLeftNav(!isShowingLast);
  }, [isShowingLast]);

  return (
    <>
      <div className="at-subsales_listing__view-recommendation_body">
        <div
          className="at-subsales_listing__view-recommendation_body-scroll"
          ref={recommedationScroll}
        >
          {initialRecommendation.map((item, index) => (
            <>
              {index === 0 && (
                <RecommendationCard
                  key={item.id}
                  showActionButton={can_create}
                  refProps={firstElementRef}
                  onClickRequest={() => {
                    onChangeSelectedRCC(item.cobrokeRequestId);
                    onToggleConfirmation(true);
                  }}
                  {...item}
                />
              )}
              {index !== 0 && (
                <RecommendationCard
                  key={item.id}
                  showActionButton={can_create}
                  onClickRequest={() => {
                    onChangeSelectedRCC(item.cobrokeRequestId);
                    onToggleConfirmation(true);
                  }}
                  {...item}
                />
              )}
            </>
          ))}
          <EmptyRecommendationCard
            refProps={lastElementRef}
            onClick={() => onToggleRecommendation(true)}
            totalRecommendation={fullRecommendationList.length}
          />
        </div>
        <button
          className={"btn-new btn-new--primary mt-3"}
          onClick={() => onToggleRecommendation(true)}
        >
          View All {fullRecommendationList.length} Recommendations
        </button>
      </div>
      {showCollapse && (
        <>
          <IoIosArrowDropleftCircle
            className={`${showPrevNav ? "d-inital" : "d-none"}`}
            style={{
              position: "absolute",
              width: 30,
              top: "10%",
              right: 10,
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onClick={() => (recommedationScroll.current.scrollLeft += 800)}
          />
          <IoIosArrowDropleftCircle
            className={`${showNextNav ? "d-inital" : "d-none"}`}
            style={{
              position: "absolute",
              width: 30,
              top: "10%",
              left: 20,
              cursor: "pointer",
            }}
            onClick={() => (recommedationScroll.current.scrollLeft += -800)}
          />
        </>
      )}
      <AtlasConfirmationDialog
        open={showConfirmation}
        mode={"alert"}
        loading={onLoad}
        cardMessageStyle={{ textAlign: "left" }}
        message={
          <>
            <p>
              {`Atlas will send a Co-broke request to this agent 
                  with the details of this listing. The agent will receive a notification and you
                  will then have to wait for them to either accept or decline your request 
                  depending on whether they have a potential counterparty such as a buyer
                  or tenant for this listing.`}
            </p>
            <br />
            <p>
              Click <strong>{` Yes `}</strong> if you want to send this request
              to the agent for a potential Co-broke. Click{" "}
              <strong>{` No `}</strong> if you don’t want to.
            </p>
          </>
        }
        positiveAction={() => {
          updateCobrokeStatus(selectedRCC, 2, "view");
          onToggleConfirmation(false);
        }}
        negativeAction={() => onToggleConfirmation(false)}
      />
    </>
  );
};
