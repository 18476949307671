import moment from "moment";
import { useEffect, useState } from "react";
import { FiClock } from "react-icons/fi";

import SvgIcon from "components/Icon";

const Activity = ({ selectedEmailCampaign }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const sortedActivities = [...selectedEmailCampaign.activities].reverse();

    if (
      selectedEmailCampaign.activities &&
      selectedEmailCampaign.activities.length > 0
    ) {
      setActivities(sortedActivities);
    }
  }, []);

  const renderActivityTitle = (status) => {
    switch (status) {
      case 1:
        return "Draft";
      case 2:
        return "Scheduled";
      case 3:
        return "Running";
      case 4:
        return "Sent";
      default:
        return null;
    }
  };

  const renderActivityText = (activity) => {
    switch (activity.status) {
      case 1:
        return "modified";
      case 2:
        return `scheduled for ${moment(activity.scheduled_at).format("DD-MM-YYYY HH:mm")}`;
      case 3:
        return "run";
      case 4:
        return "sent";
      default:
        return null;
    }
  };

  if (activities.length === 0) {
    return <p className={"text-center"}>There are no activities to show</p>;
  }

  return (
    <div className={"at-email-campaigns__timeline"}>
      {activities &&
        activities.map((activity) => (
          <div key={activity.id} className={"d-flex"}>
            <div className={"timeline-point"}>
              <SvgIcon className="svg-icon-bell" svgHref="icon-bell" />
            </div>

            <div className={"card"}>
              <div className={"card-header"}>
                <strong>
                  {activity.status && renderActivityTitle(activity.status)}
                </strong>

                <div className={"d-flex align-items-center text-muted"}>
                  <FiClock className={"clock-icon"} />
                  <small>
                    {moment(activity.updated_at).format("MMM D HH:mm")}
                  </small>
                </div>
              </div>

              <div className={"card-body"}>
                <p>
                  The campaign <strong>{activity.campaign_name}</strong> has
                  been{" "}
                  <strong>{activity && renderActivityText(activity)}</strong> by{" "}
                  <strong>{activity.updator}</strong>.
                </p>

                {activity.changes &&
                  activity.changes.filter((change) => change !== null).length >
                    0 && (
                    <ul className={"at-email-campaigns__activity-changes mt-3"}>
                      {activity.changes
                        .filter((change) => change !== null)
                        .map((change) => (
                          <li
                            key={change}
                            className={
                              "at-email-campaigns__activity-change-item"
                            }
                          >
                            <strong>{change}</strong>
                          </li>
                        ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Activity;
