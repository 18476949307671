import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";
import { TypeLib } from "./asset";

const searchParams = (category) => [
  {
    label: "Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Category",
    value: "category",
    type: "select",
    param: "",
    options: category,
  },
  {
    label: "Type",
    value: "graphic_template_type_id_eq",
    type: "radio",
    param: "",
    options: TypeLib,
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      reassignSubmissions: {},

      showDeleteConfirmation: false,
      showEditGraphicModal: false,
      showCreateGraphicModal: false,
      showShareModal: false,

      searchParams: [],
      graphics: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },
      template: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },
      selectedTemplate: null,
      selectedGraphic: null,
      categories: [],
      graphicPages: [],
      templatePages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeGraphicHOC = (val, context) => this.setState({ [context]: val });

    getCategories = () =>
      Get(
        `/tags?type=categories`,
        this.getCategoriesSuccess,
        this.getCategoriesError,
        this.load,
      );
    getCategoriesSuccess = (payload) => {
      let options = _.map(payload, (item) => {
        return {
          ...item,
          label: item.name,
          value: item.name,
        };
      });

      this.setState({
        categories: payload,
        searchParams: searchParams(options),
      });
    };
    getCategoriesError = (error) => requestError(error);

    getGraphicsTemplate = (page, search) => {
      Get(
        `/graphic_templates?${search}page=${page}&per=30`,
        payload => this.getGraphicsTemplateSuccess(payload, page),
        this.getGraphicsTemplateError,
        this.load,
      );
    };
    getGraphicsTemplateSuccess = (payload, page) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      let tempData = _.map(payload.data, (item) => {
        let tempObj = _.map(item.content.objects, (objectChild) => {
          return {
            ...(objectChild.shapeType === "img"
              ? {
                  ...objectChild,
                  hoverCursor: "pointer",
                  selectable: false,
                }
              : {
                  ...objectChild,
                  objects: _.map(objectChild.objects, (objChild) => ({
                    ...objChild,
                    fill: objChild.type === "textbox" ? "black" : "transparent",
                  })),
                }),
          };
        });

        return {
          ...item,
          content: {
            ...item.content,
            objects: tempObj,
          },
          clipPath: item.content.objects,
        };
      });
      this.setState({
        template: page === 1 ? tempData : [...this.state.template, ...tempData],
        templatePages: tempPages,
      });
    };
    getGraphicsTemplateError = (error) => requestError(error);

    getSelectedTemplate = (id) =>
      Get(
        `/graphic_templates/${id}`,
        this.getSelectedTemplateSuccess,
        (error) => {
          this.setState({ selectedTemplate: null });
        },
        this.load,
      );
    getSelectedTemplateSuccess = (payload) => {
      let tempObj = _.map(payload.content.objects, (objectChild) => {
        return {
          ...(objectChild.shapeType === "img"
            ? {
                ...objectChild,
                hoverCursor: "pointer",
                selectable: false,
              }
            : {
                ...objectChild,
                objects: _.map(objectChild.objects, (objChild) => ({
                  ...objChild,
                  fill: objChild.type === "textbox" ? "black" : "transparent",
                })),
              }),
        };
      });

      this.setState({
        selectedTemplate: {
          ...payload,
          content: {
            ...payload.content,
            objects: tempObj,
          },
          clipPath: payload.content.objects,
        },
      });
    };

    getGraphics = (page, search) => {
      let tempSearch = _.replace(search, "q[category]", "category");
      let temp = {
        currentPage: page,
        searchParams: tempSearch,
      };
      this.props.storeLastView(temp);
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;
      Get(
        `/${["Super Admin", "Admin"].indexOf(role) > -1 ? "admin/" : ""}graphic_requests?${tempSearch}page=${page}`,
        this.getGraphicsSuccess,
        this.getGraphicsError,
        this.load,
      );
    };
    getGraphicsSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        graphics: payload,
        graphicPages: tempPages,
      });
    };
    getGraphicsError = (error) => requestError(error);

    getSelectedGraphic = (id) =>
      Get(
        `/graphic_requests/${id}`,
        this.getSelectedGraphicSuccess,
        this.getSelectedGraphicError,
        this.load,
      );
    getSelectedGraphicSuccess = (payload) => {
      let temp = {};
      let tempClipPathID = [];
      _.map(payload.content.objects, (objChild) => {
        objChild.clipPath && tempClipPathID.push(objChild.clipPath.id);
      });

      temp = {
        ...payload,
        content: {
          ...payload.content,
          clipPathID: tempClipPathID,
        },
      };
      this.setState({
        selectedGraphic: temp,
        selectedTemplate: payload.graphic_template,
      });
    };
    getSelectedGraphicError = (error) => requestError(error);

    getAdminSelectedGraphic = (id) =>
      Get(
        `/admin/graphic_requests/${id}`,
        this.getAdminSelectedGraphicSuccess,
        this.getAdminSelectedGraphicError,
        this.load,
      );
    getAdminSelectedGraphicSuccess = (payload) => {
      let temp = {};
      let tempClipPathID = [];
      _.map(payload.content.objects, (objChild) => {
        objChild.clipPath && tempClipPathID.push(objChild.clipPath.id);
      });

      temp = {
        ...payload,
        content: {
          ...payload.content,
          clipPathID: tempClipPathID,
        },
      };
      this.setState({
        selectedGraphic: temp,
        selectedTemplate: payload.graphic_template,
      });
    };
    getAdminSelectedGraphicError = (error) => requestError(error);

    createGraphic = (dataToSubmit) =>
      Post(
        `/graphic_requests`,
        dataToSubmit,
        this.createGraphicsuccess,
        this.createGraphicError,
        this.load,
      );
    createGraphicsuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getGraphics(currentPage, searchParams);
      this.setState({ showCreateGraphicModal: false });

      requestSuccess(`Graphics has been created successfully`);
    };
    createGraphicError = (error) => requestError(error);

    updateGraphic = (dataToSubmit) =>
      Put(
        `/graphic_requests/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateGraphicsuccess,
        this.updateGraphicError,
        this.load,
      );
    updateGraphicsuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getGraphics(currentPage, searchParams);

      let tempClipPathID = [];
      _.map(payload.content.objects, (objChild) => {
        objChild.clipPath && tempClipPathID.push(objChild.clipPath.id);
      });

      this.setState({
        selectedGraphic: {
          ...payload,
          content: {
            ...payload.content,
            clipPathID: tempClipPathID,
          },
        },
      });

      requestSuccess(`Graphics has been updated successfully`);
    };
    updateGraphicError = (error) => requestError(error);

    updateDownloadCount = (id) =>
      Put(
        `/graphic_requests/${id}/download_completed`,
        {},
        () => {},
        () => {},
        this.load,
      );

    deleteGraphic = (id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      Delete(
        `/${["Super Admin", "Admin"].indexOf(role) > -1 ? "admin/" : ""}graphic_requests/${id}`,
        this.deleteGraphicSuccess,
        this.deleteGraphicError,
        this.load,
      );
    };
    deleteGraphicSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getGraphics(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });

      requestSuccess(`Graphics has been deleteted successfully.`);
    };
    deleteGraphicError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          categories={this.state.categories}
          graphicPages={this.state.graphicPages}
          graphics={this.state.graphics}
          template={this.state.template}
          searchParams={this.state.searchParams}
          selectedGraphic={this.state.selectedGraphic}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          showCreateGraphicModal={this.state.showCreateGraphicModal}
          showShareModal={this.state.showShareModal}
          showEditGraphicModal={this.state.showEditGraphicModal}
          onLoadGraphic={this.state.loading}
          selectedTemplate={this.state.selectedTemplate}
          getCategories={this.getCategories}
          getSelectedTemplate={this.getSelectedTemplate}
          getGraphicsTemplate={this.getGraphicsTemplate}
          onChangeGraphicHOC={this.onChangeGraphicHOC}
          getGraphics={this.getGraphics}
          getSelectedGraphic={this.getSelectedGraphic}
          getAdminSelectedGraphic={this.getAdminSelectedGraphic}
          updateDownloadCount={this.updateDownloadCount}
          createGraphic={this.createGraphic}
          updateGraphic={this.updateGraphic}
          deleteGraphic={this.deleteGraphic}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
