import { AiFillMail } from 'react-icons/ai';
import { PiPhoneFill } from 'react-icons/pi';

const BlackBoxTemplate = ({ agent, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    backgroundColor: '#ff6341',
    color: '#000',
    padding: 2,
    marginRight: 4,
    borderRadius: 99
  };

  return (
    <div
      style={{
        backgroundColor: `rgba(0, 0, 0, ${configuration?.agentDetailsOpacity})`,
        padding: 8,
        color: '#fff',
        minWidth: '28.5%',
        width: 'max-content'
      }}
    >
      <div style={{ display: 'flex', gap: 8 }}>
        <div style={{ position: 'relative' }}>
          <img
            alt={agent.display_name}
            src={agent.avatar_url}
            style={{
              width: 68,
              height: 68,
              minWidth: 68,
              minHeight: 68,
              objectFit: 'cover',
              objectPosition: 'top',
              backgroundColor: '#eee'
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: -4,
              right: -4,
              width: 8,
              height: 46,
              backgroundColor: '#ff6341'
            }}
          />
        </div>
        <div>
          <p style={{ fontSize: 10, fontWeight: 400, marginBottom: 2 }}>
            {agent.license_number}
          </p>
          <p style={{ fontSize: 14, fontWeight: 700, marginBottom: 8 }}>
            {agent.display_name}
          </p>
          <p style={{ ...detailStyle, marginBottom: 4 }}>
            <PiPhoneFill style={iconStyle} />
            {agent.mobile_contact_number}
          </p>
          <p style={detailStyle}>
            <AiFillMail style={iconStyle} />
            {agent.email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlackBoxTemplate;
