import React, { useState } from "react";

import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const Create = ({
  selectedBranch,
  onLoadParkLicense,

  createLicense,
  onChangeParkLicenseHOC,
}) => {
  const [newParkLicense, setNewParkLicense] = useState("");

  const renderCardContent = () => (
    <>
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            License No.
          </h2>
          <CustomFormInput
            type={"text"}
            value={newParkLicense}
            onChangeValue={(val) => setNewParkLicense(val)}
            placeholder={"e.g. E12382, PE29382"}
          />
        </section>
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <AtlasButton
          className="btn-new btn-new--primary"
          children={"Submit"}
          onClick={() =>
            createLicense({
              branch_id: selectedBranch.id,
              licence_number: newParkLicense,
            })
          }
        />
        <AtlasButton
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() => onChangeParkLicenseHOC(false, "showCreateModal")}
        />
      </div>
    </>
  );

  return (
    <ModalDialog
      title={"Create License Record"}
      maxWidth="lg"
      onLoad={onLoadParkLicense}
      children={renderCardContent()}
      onClose={() => onChangeParkLicenseHOC(false, "showCreateModal")}
    />
  );
};

export default Create;
