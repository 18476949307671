import { AiFillMail } from 'react-icons/ai';
import { PiPhoneFill } from 'react-icons/pi';

const TrapeziumsTemplate = ({ agent, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    backgroundColor: '#000',
    color: '#fff',
    padding: 2,
    marginRight: 4,
    borderRadius: 99
  };

  return (
    <div>
      <div
        style={{
          marginTop: -4,
          display: 'inline-flex',
          position: 'relative',
          bottom: -4,
          left: 22
        }}
      >
        <div
          style={{
            zIndex: 2,
            padding: '8px 12px',
            backgroundColor: '#0ddfb2'
          }}
        >
          <p
            style={{
              color: '#000',
              fontSize: 12,
              fontWeight: 600
            }}
          >
            Contact for more information
          </p>
        </div>
        <div
          style={{
            zIndex: 2,
            borderTop: '32px solid #0ddfb2',
            borderRight: '20px solid transparent'
          }}
        />
      </div>
      <br />
      <div style={{ display: 'inline-flex' }}>
        <div
          style={{
            borderBottom: `76px solid rgba(0, 0, 0, ${configuration?.agentDetailsOpacity})`,
            borderLeft: '44px solid transparent'
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            backgroundColor: `rgba(0, 0, 0, ${configuration?.agentDetailsOpacity})`
          }}
        >
          <div style={{ padding: 8 }}>
            <p style={{ fontSize: 10, marginBottom: 1 }}>
              {agent.license_number}
            </p>
            <p style={{ fontSize: 12, fontWeight: 700, marginBottom: 6 }}>
              {agent.display_name}
            </p>
            <p style={{ ...detailStyle, marginBottom: 2 }}>
              <PiPhoneFill style={iconStyle} />
              {agent.mobile_contact_number}
            </p>
            <p style={detailStyle}>
              <AiFillMail style={iconStyle} />
              {agent.email}
            </p>
          </div>
          <img
            alt={agent.display_name}
            src={agent.avatar_url}
            style={{
              width: 60,
              height: 60,
              objectFit: 'cover',
              objectPosition: 'top',
              margin: 8,
              backgroundColor: '#eee'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TrapeziumsTemplate;
