import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AiFillTag, AiFillHome } from "react-icons/ai";
import { FaBuilding, FaBath, FaBed, FaRulerCombined } from "react-icons/fa";
import { IoIosConstruct, IoMdPin } from "react-icons/io";
import { MdDateRange, MdAdd } from "react-icons/md";
import { RiNumbersFill } from "react-icons/ri";
import { BiArea } from "react-icons/bi";
import { BsDash } from "react-icons/bs";
import { TiInfoLarge } from "react-icons/ti";
import { IconButton, Tooltip } from "@material-ui/core";

import TooltipWrapper from "components/TooltipWrapper";

import { numberWithCommas } from "utils/thousandSeparator";

import "stylesheets/components/card/blackberry.scss";

const specificationTop = [
  {
    name: "Project Tenure",
    icon: <AiFillTag />,
    value: "tenureType",
  },
  {
    name: "Project Title",
    icon: <AiFillHome />,
    value: "projectTitleType",
  },
  {
    name: "Project Type",
    icon: <FaBuilding />,
    value: "projectType",
  },
];

const specificationMiddle = [
  {
    name: "Developer",
    icon: <IoIosConstruct />,
    value: "developer",
  },
  {
    name: "Land Area",
    icon: <FaRulerCombined />,
    value: "land_area",
  },
  {
    name: "Est. Completion Date",
    icon: <MdDateRange />,
    value: "completion_date",
  },
  {
    name: "Location",
    icon: <IoMdPin />,
    value: "location",
  },
];

const specificationBottom = [
  {
    name: "Max. Unit",
    icon: <RiNumbersFill />,
    value: "max_unit",
  },
  {
    name: "No. of Bedrooms",
    icon: <FaBed />,
    value: "bedroom_display",
  },
  {
    name: "Built Up Area",
    icon: <BiArea />,
    value: "measurement",
  },
  {
    name: "No. of Bathrooms",
    icon: <FaBath />,
    value: "bathroom_display",
  },
];

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

class CustomCard extends Component {
  onHandleActionVisibility = (flagProp) => {
    let tempReplace = flagProp.replace(/!/g, "");

    return flagProp.indexOf("!") > -1
      ? !this.props.listItem[tempReplace]
      : this.props.listItem[tempReplace];
  };

  renderActionSections = (action) => {
    const {
      onClickDetails,
      onClickAdd,
      onClickRemove,

      onShowRemoveIcon,
      onShowAddIcon,
    } = this.props;

    switch (action) {
      case "details":
        return (
          <IconButton
            classes={{ root: "bg-primary" }}
            style={{ padding: 4 }}
            onClick={onClickDetails}
          >
            <TiInfoLarge className={"color-white"} style={{ width: 18 }} />
          </IconButton>
        );

      case "remove": {
        if (this.onHandleActionVisibility(onShowRemoveIcon)) {
          return (
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              aria-label={"action-button"}
              placement={"top"}
              title={"Remove from listing"}
            >
              <IconButton
                classes={{ root: "bg-danger" }}
                style={{ padding: 4 }}
                onClick={onClickRemove}
              >
                <BsDash className={"color-white"} style={{ width: 18 }} />
              </IconButton>
            </DarkTooltip>
          );
        }

        return false;
      }

      case "add": {
        if (this.onHandleActionVisibility(onShowAddIcon)) {
          return (
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              aria-label={"action-button"}
              placement={"top"}
              title={"Add to listing"}
            >
              <IconButton
                classes={{ root: "bg-success" }}
                style={{ padding: 4 }}
                onClick={onClickAdd}
              >
                <MdAdd className={"color-white"} style={{ width: 18 }} />
              </IconButton>
            </DarkTooltip>
          );
        }
        return false;
      }

      default:
        break;
    }
  };

  renderSpecification = (item) => {
    const { keyRef } = this.props;

    const processValue = (val) => {
      if (this.props[val]) {
        if (
          val === "land_area" ||
          val === "max_unit" ||
          val === "measurement"
        ) {
          return numberWithCommas(this.props[val]);
        } else {
          return this.props[val];
        }
      } else {
        return "N/A";
      }
    };

    return (
      <TooltipWrapper
        title={<span>{item.name}</span>}
        child={
          <span className={`at-blackberry__spec-list_item-${item.value}`}>
            {item.icon}
            <span className="at-blackberry__spec-text">
              {processValue(item.value)}
            </span>
          </span>
        }
      />
    );
  };

  render = () => {
    const {
      keyRef,
      imgSrc,
      title,
      internal_name,
      max_price_cents,
      net_price_currency,
      net_price_cents,
      available_units,
      total_units,
      onClickDetails,
      actionsColumns = [],
    } = this.props;

    return (
      <li className="at-blackberry__item">
        <div className="at-blackberry__image">
          <div className="at-blackberry__img-cont" onClick={onClickDetails}>
            <img
              src={imgSrc}
              alt={`${keyRef}-img`}
              style={{ cursor: "pointer" }}
            />
            {total_units > 0 && (
              <div className="at-blackberry__available">
                <p>{`${available_units}/${total_units} available`}</p>
              </div>
            )}
          </div>
        </div>
        <div className="at-blackberry__details-cont">
          <div className="at-blackberry__details-cont-top">
            <div className="fw-600 at-blackberry__title">{`${internal_name}`}</div>
          </div>
          <div className="at-blackberry__title-cont">
            <h6 className="at-blackberry__title__text">{title || "N/A"}</h6>
          </div>
          <div className={`d-flex flex-wrap at-blackberry-specs_cont-bottom`}>
            {specificationBottom.map((item) => this.renderSpecification(item))}
          </div>
          <div className="at-blackberry__bottom">
            <h6 className="at-blackberry__title__price">
              <label>{net_price_currency}</label>
              <span>{`  ${numberWithCommas(net_price_cents)} -  `}</span>
              <label>{net_price_currency}</label>
              <span>{` ${numberWithCommas(max_price_cents)}`}</span>
            </h6>
            <div className={`d-flex flex-wrap at-blackberry-specs_cont-top`}>
              {specificationTop.map((item) => this.renderSpecification(item))}
            </div>
          </div>
          <div className="at-blackberry__actions">
            {actionsColumns.length > 0 &&
              actionsColumns.map((action) => this.renderActionSections(action))}
          </div>
        </div>
      </li>
    );
  };
}

export default CustomCard;
