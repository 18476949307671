export const confirmation_form_request = {
  statuses: [
    { id: 1, name: "Pending" },
    { id: 2, name: "Approved" },
    { id: 3, name: "Rejected" },
  ],
};

export const project = {
  status: [
    { value: 1, id: 1, name: "Active", label: "Active" },
    { value: 2, id: 2, name: "Inactive", label: "Inactive" },
    { value: 3, id: 3, name: "Expired", label: "Expired" },
    { value: 4, id: 4, name: "Deleted", label: "Deleted" },
  ],
  tenure: [
    { value: 1, id: 1, name: "Freehold", label: "Freehold" },
    { value: 2, id: 2, name: "Leasehold", label: "Leasehold" },
  ],
  title: [
    { value: 1, id: 1, name: "Commercial", label: "Commercial" },
    { value: 2, id: 2, name: "Residential", label: "Residential" },
  ],
  type: [
    { value: 1, id: 1, name: "Highrise", label: "Highrise" },
    { value: 2, id: 2, name: "Landed", label: "Landed" },
    { value: 3, id: 3, name: "Lowrise", label: "Lowrise" },
  ],
  measurement: [
    { value: 1, id: 1, name: "Square Feet", label: "Square Feet (sqft)" },
    { value: 2, id: 2, name: "Square Meter", label: "Square Meter (sqm)" },
  ]
};

export const user = {
  roles: [
    {
      value: 3,
      label: "Super Admin",
    },
    {
      value: 4,
      label: "Admin",
    },
    {
      value: 5,
      label: "Agent",
    },
    {
      value: 6,
      label: "Personal Assistant",
    },
    {
      value: 7,
      label: "Associated Agent",
    },
    {
      value: 8,
      label: "Project Manager",
    },
  ],
  statuses: [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Inactive",
    },
    {
      value: 3,
      label: "Suspended",
    },
    {
      value: 4,
      label: "Deleted",
    },
    {
      value: 5,
      label: "Resigned",
    },
    {
      value: 6,
      label: "Pending",
    },
    {
      value: 7,
      label: "Terminated",
    },
    {
      value: 8,
      label: "Waiting Approval",
    },
  ],
};

export const contact = {
  types: [
    {
      id: 1,
      name: "Personal",
    },
    {
      id: 2,
      name: "Company",
    },
  ],
  tags: [
    {
      id: 1,
      name: "Seller",
    },
    {
      id: 2,
      name: "Landlord",
    },
    {
      id: 3,
      name: "Buyer",
    },
    {
      id: 4,
      name: "Tenant",
    },
    {
      id: 5,
      name: "Agent",
    },
    {
      id: 6,
      name: "Referral",
    },
  ],
};

export const board_registration_options = [
  { id: 1, name: "REN" },
  { id: 2, name: "PEA" },
  { id: 3, name: "REA" },
  { id: 4, name: "PV" },
  { id: 5, name: "VE" },
];

export const invoicing_branches = [
  {
    contact_details:
      "2-1-41 One Square, Tingkat Mahsuri 1, 11900 Bayan Lepas, Pulau Pinang | t. +6 04 637 0156 | e. hello@iqirealty.com   www.iqirealty.com",
    id: 3,
    value: 3,
    branch_id: 4,
    name: "IQI Realty Sdn Bhd (Penang)",
    label: "IQI Realty Sdn Bhd (Penang)",
    registration_number: "E(1)1584/3",
  },
  {
    contact_details:
      "16-2, Jalan 31/70A, Desa Sri Hartamas, 50480 Kuala Lumpur | T: +6 03 2300 1813 | E: hello@iqirealty.com     www.iqirealty.com",
    id: 4,
    value: 4,
    branch_id: 5,
    name: "IQI Realty Sdn Bhd (Sri Hartamas)",
    label: "IQI Realty Sdn Bhd (Sri Hartamas)",
    registration_number: "E(1)1584/4",
  },
  {
    contact_details:
      "Lot 9-3, 3rd Floor, Medan Klang Lama 28, Jalan Klang Lama, 58000 Kuala Lumpur | T: +6 03 7453 5155 | e:  hello@iqirealty.com   www.iqirealty.com",
    id: 5,
    value: 5,
    branch_id: 48,
    name: "IQI Realty Sdn Bhd (HQ)",
    label: "IQI Realty Sdn Bhd (HQ)",
    registration_number: "E(1)1584",
  },
  {
    contact_details:
      "B-3-7 Block B, Pacific Place Commercial Centre, Jalan PJU 1a/4, Ara Damansara, 47301 Petaling Jaya, Selangor   T: +6 03 2181 2820    hello@iqirealty.com",
    id: 6,
    value: 6,
    branch_id: 8,
    name: "IQI Realty Sdn Bhd (Ara Damansara)",
    label: "IQI Realty Sdn Bhd (Ara Damansara)",
    registration_number: "E(1)1584/7",
  },
  {
    contact_details:
      "D-1-5, Megan Avenue 1, 189 Jalan Tun Razak, 50400 Kuala Lumpur  | T: +603 2181 3903  | e. hello@iqirealty.com   www.iqirealty.com",
    id: 7,
    value: 7,
    branch_id: 7,
    name: "IQI Realty Sdn Bhd (Megan Avenue)",
    label: "IQI Realty Sdn Bhd (Megan Avenue)",
    registration_number: "E(1) 1584/5",
  },
  {
    contact_details:
      "No. 6, Jalan Pertama, Pusat Perdagangan Danga Utama, 81300 Johor Bahru, Johor  | Tel: +607 557 5155  | e. hello@iqirealty.com   www.iqirealty.com",
    id: 8,
    value: 8,
    branch_id: 9,
    name: "IQI Realty Sdn Bhd (Johor)",
    label: "IQI Realty Sdn Bhd (Johor)",
    registration_number: "E(1)1584/6",
  },
  {
    contact_details:
      "16, Jalan Lagenda 1, Taman 1 Lagenda, Melaka Tengah, 75400 Melaka | t. 06 288 9090   | e. hello@iqirealty.com   www.iqirealty.com",
    id: 9,
    value: 9,
    branch_id: 10,
    name: "IQI Realty Sdn Bhd (Melaka)",
    label: "IQI Realty Sdn Bhd (Melaka)",
    registration_number: "E(1)1584/8",
  },
  {
    contact_details:
      "Block D, Unit 3, 5th Floor, (D-05-03), Aeropod Commercial Square, Jalan Aeropod,Jalan Kepayan, 88200 Kota Kinabalu, Sabah | t. +6 08 830 1126   | e. hello@iqirealty.com   www.iqirealty.com",
    id: 10,
    value: 10,
    branch_id: 12,
    name: "IQI Realty Sdn Bhd (KK)",
    label: "IQI Realty Sdn Bhd (KK)",
    registration_number: "E(1)1584/9",
  },
  {
    contact_details:
      "1st Floor, Lot 3, Block A1, Saradise Kuching off Jalan Stutong, 93350 Kuching, Sarawak | T: +6 08 226 8010 | e:  hello@iqirealty.com   www.iqirealty.com",
    id: 11,
    value: 11,
    branch_id: 13,
    name: "IQI Realty Sdn Bhd (Kuching)",
    label: "IQI Realty Sdn Bhd (Kuching)",
    registration_number: "E(1)1584/10",
  },
  {
    contact_details:
      "10-2, Jalan Setia Indah Y, Seksyen U13/Y Setia Alam, 40170 Shah Alam, Selangor | T: +6 03 7453 5155 | e:  hello@iqirealty.com   www.iqirealty.com",
    id: 12,
    value: 12,
    branch_id: "",
    name: "IQI Realty Sdn Bhd (Shah Alam)",
    label: "IQI Realty Sdn Bhd (Shah Alam)",
    registration_number: "E(1)1584/11",
  },
  {
    contact_details: null,
    id: 30,
    value: 30,
    branch_id: 22,
    name: "IQI Realty Sdn Bhd (Kuantan)",
    label: "IQI Realty Sdn Bhd (Kuantan)",
    registration_number: "E(1)1584/12",
  },
  {
    contact_details: null,
    id: 31,
    value: 31,
    branch_id: 15,
    name: "IQI Realty Sdn Bhd (Sibu)",
    label: "IQI Realty Sdn Bhd (Sibu)",
    registration_number: "E(1)1584/15",
  },
  {
    contact_details:
      "2-19B1, 2-19B2 JALAN KAMARUDDIN ISA PUSAT PERDAGANGAN KEPAYANG 31400 IPOH, PERAK",
    id: 32,
    value: 32,
    branch_id: 14,
    name: "IQI Realty Sdn Bhd (Ipoh)",
    label: "IQI Realty Sdn Bhd (Ipoh)",
    registration_number: "E (1) 1584/14",
  },
  {
    contact_details:
      "133G, JLN PUSAT KOMERSIAL SAUJANA 3 HEIGHTS, PUSAT KOMERSIAL SAUJANA, SEREMBAN 2,70300 NEGERI SEMBILAN DK | T: +6 06-6020015 | e:  hello@iqirealty.com   www.iqirealty.com",
    id: 33,
    value: 33,
    branch_id: 33,
    name: "IQI Realty Sdn Bhd (Seremban)",
    label: "IQI Realty Sdn Bhd (Seremban)",
    registration_number: "E(1)1584/13",
  },
  {
    contact_details:
      " LOT 1933,1ST FLOOR, JALAN MS 2/1,MARINA SQUARE 2, MARINA PARKCITY, 98000 MIRI SARAWAK",
    id: 34,
    value: 34,
    branch_id: 34,
    name: "IQI Realty Sdn Bhd (Miri)",
    label: "IQI Realty Sdn Bhd (Miri)",
    registration_number: "E (1) 1584/16",
  },
  {
    contact_details: "-",
    id: 35,
    value: 35,
    branch_id: 37,
    name: "IQI Realty Sdn Bhd (Putrajaya)",
    label: "IQI Realty Sdn Bhd (Putrajaya)",
    registration_number: "E (1) 1584/11",
  },
  {
    contact_details:
      "WISMA TERATAI, PT210-PT212, 2ND FLOOR, JALAN HAMZAH, SEKSYEN 19, 15050 KOTA BHARU, KELANTAN | T: +6 09 275 0010 | e:  hello@iqirealty.com   www.iqirealty.com",
    id: 36,
    value: 36,
    branch_id: 40,
    name: "IQI Realty Sdn Bhd (Kelantan)",
    label: "IQI Realty Sdn Bhd (Kelantan)",
    registration_number: "E (1) 1584/17",
  },
];

export const branches_my = [
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 1,
    country_id: 1,
    id: 2,
    is_registration_fee_required: true,
    name: "Medan28",
    reference_id: 1,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 2,
    country_id: 1,
    id: 4,
    is_registration_fee_required: true,
    name: "Penang",
    reference_id: 3,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 1,
    country_id: 1,
    id: 5,
    is_registration_fee_required: true,
    name: "SriHartamas",
    reference_id: 4,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 1,
    country_id: 1,
    id: 7,
    is_registration_fee_required: true,
    name: "MeganAve",
    reference_id: 6,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 1,
    country_id: 1,
    id: 8,
    is_registration_fee_required: true,
    name: "Ara Damansara",
    reference_id: 7,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 3,
    country_id: 1,
    id: 9,
    is_registration_fee_required: true,
    name: "Johor",
    reference_id: 8,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 5,
    country_id: 1,
    id: 10,
    is_registration_fee_required: true,
    name: "Malacca",
    reference_id: 9,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 6,
    country_id: 1,
    id: 12,
    is_registration_fee_required: true,
    name: "KK",
    reference_id: 11,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 7,
    country_id: 1,
    id: 13,
    is_registration_fee_required: true,
    name: "Kuching",
    reference_id: 12,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 8,
    country_id: 1,
    id: 14,
    is_registration_fee_required: true,
    name: "Ipoh",
    reference_id: 13,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 9,
    country_id: 1,
    id: 15,
    is_registration_fee_required: true,
    name: "Sibu",
    reference_id: 14,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 15,
    country_id: 1,
    id: 22,
    is_registration_fee_required: true,
    name: "Kuantan",
    reference_id: 21,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 25,
    country_id: 1,
    id: 33,
    is_registration_fee_required: true,
    name: "Seremban",
    reference_id: 1030,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 26,
    country_id: 1,
    id: 34,
    is_registration_fee_required: true,
    name: "Miri",
    reference_id: 1031,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 29,
    country_id: 1,
    id: 37,
    is_registration_fee_required: true,
    name: "Putrajaya",
    reference_id: 1035,
    registration_fee_currency: "",
  },
  {
    active: true,
    agent_registration_fee: 188,
    bank_account_number: "26423600009110",
    bank_id: 4,
    bank_owner_name: "IQI HOLDINGS SDN BHD",
    branch_region_id: 30,
    country_id: 1,
    id: 40,
    is_registration_fee_required: true,
    name: "Kelantan",
    reference_id: 1040,
    registration_fee_currency: "",
  },
];
