import React, { useState } from "react";
import { Grid, Divider } from "@material-ui/core";

import VisaLogo from "assets/images/visa-logo-png-transparent.png";
import MasterCardLogo from "assets/images/mastercard-logo.png";

const View = ({ data, onChangeNewTransaction }) => {
  const [changePaymentMethod, updateChangePaymentMethod] = useState(false);
  return (
    <form
      method="get"
      action={
        window.location.host.indexOf("atlas.iqiglobal.com") !== -1
          ? "https://securepay.e-ghl.com/IPG/Payment.aspx"
          : "https://test2pay.ghl.com/IPGSG/Payment.aspx"
      }
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Amount"}
            </label>
            <input
              readOnly="readonly"
              name={"Amount"}
              className="form-control"
              style={{ width: "100%", height: "50px" }}
              value={data["Amount"]}
              onChange={(e) => onChangeNewTransaction("Amount", e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Payment Method"}
            </label>
            <select
              name={"PymtMethod"}
              className="form-control"
              style={{ width: "100%", height: "50px" }}
              value={data["PymtMethod"]}
              onChange={(e) => {
                if (changePaymentMethod) {
                  onChangeNewTransaction("PymtMethod", e.target.value);
                }
              }}
              readOnly="readonly"
            >
              <option value="CC">{"Credit/Debit Card(Coming Soon)"}</option>
              <option value="DD">{"Bank Transfer"}</option>
            </select>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={VisaLogo} style={{ width: "50px", height: "50px" }} />
              <img
                src={MasterCardLogo}
                style={{ width: "45px", height: "42px", paddingTop: "10px" }}
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"CurrencyCode"}
            </label>
            <input
              readOnly="readonly"
              name={"CurrencyCode"}
              className="form-control"
              style={{ width: "100%", height: "50px" }}
              value={data["CurrencyCode"]}
              onChange={(e) =>
                onChangeNewTransaction("CurrencyCode", e.target.value)
              }
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="form-group" hidden>
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Transaction Type"}
            </label>
            <input
              readOnly="readonly"
              name={"TransactionType"}
              className="form-control"
              style={{ width: "100%", height: "50px" }}
              value={data["TransactionType"]}
              onChange={(e) =>
                onChangeNewTransaction("TransactionType", e.target.value)
              }
            />
          </div>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: "20px", marginTop: "20px" }} />

      <div className="form-group" hidden>
        <label>{"ServiceID"}</label>
        <input
          name={"ServiceID"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["ServiceID"]}
          onChange={(e) => onChangeNewTransaction("ServiceID", e.target.value)}
        />
      </div>
      <div className="form-group" hidden>
        <label>{"PaymentID"}</label>
        <input
          name={"PaymentID"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["PaymentID"]}
          onChange={(e) => onChangeNewTransaction("PaymentID", e.target.value)}
        />
      </div>
      <div className="form-group" hidden>
        <label>{"OrderNumber"}</label>
        <input
          name={"OrderNumber"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["OrderNumber"]}
          onChange={(e) =>
            onChangeNewTransaction("OrderNumber", e.target.value)
          }
        />
      </div>
      <div className="form-group" hidden>
        <label>{"PaymentDesc"}</label>
        <input
          name={"PaymentDesc"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["PaymentDesc"]}
          onChange={(e) =>
            onChangeNewTransaction("PaymentDesc", e.target.value)
          }
        />
      </div>
      <div className="form-group" hidden>
        <label>{"MerchantName"}</label>
        <input
          name={"MerchantName"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["MerchantName"]}
          onChange={(e) =>
            onChangeNewTransaction("MerchantName", e.target.value)
          }
        />
      </div>
      <div className="form-group" hidden>
        <label>{"MerchantCallbackURL"}</label>
        <input
          name={"MerchantCallbackURL"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["MerchantCallbackURL"]}
          onChange={(e) =>
            onChangeNewTransaction("MerchantCallbackURL", e.target.value)
          }
        />
      </div>
      <div className="form-group" hidden>
        <label>{"MerchantReturnURL"}</label>
        <input
          name={"MerchantReturnURL"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["MerchantReturnURL"]}
          onChange={(e) =>
            onChangeNewTransaction("MerchantReturnURL", e.target.value)
          }
        />
      </div>

      <div className="form-group" hidden>
        <label>{"CustIP"}</label>
        <input
          name={"CustIP"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["CustIP"]}
          onChange={(e) => onChangeNewTransaction("CustIP", e.target.value)}
        />
      </div>

      <Grid container>
        <Grid item md={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Name (as on bank card)"}
            </label>
            <input
              required
              type={"text"}
              name={"CustName"}
              className="form-control"
              style={{ width: "100%" }}
              value={data["CustName"]}
              onChange={(e) =>
                onChangeNewTransaction("CustName", e.target.value)
              }
            />
          </div>
        </Grid>
        <Grid item md={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Email"}
            </label>
            <input
              required
              type={"email"}
              name={"CustEmail"}
              className="form-control"
              style={{ width: "100%" }}
              value={data["CustEmail"]}
              onChange={(e) =>
                onChangeNewTransaction("CustEmail", e.target.value)
              }
            />
          </div>
        </Grid>
        <Grid item md={12}>
          <div className="form-group">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {"Phone"}
            </label>
            <input
              required
              type={"text"}
              name={"CustPhone"}
              className="form-control"
              style={{ width: "100%" }}
              value={data["CustPhone"]}
              onChange={(e) =>
                onChangeNewTransaction("CustPhone", e.target.value)
              }
            />
          </div>
        </Grid>
      </Grid>

      <div className="form-group" hidden>
        <label>{"PageTimeout"}</label>
        <input
          name={"PageTimeout"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["PageTimeout"]}
          onChange={(e) =>
            onChangeNewTransaction("PageTimeout", e.target.value)
          }
        />
      </div>
      <div className="form-group" hidden>
        <label>{"HashValue"}</label>
        <input
          name={"HashValue"}
          className="form-control"
          style={{ width: "100%" }}
          value={data["HashValue"]}
          onChange={(e) => onChangeNewTransaction("HashValue", e.target.value)}
        />
      </div>
      <section className="grid-full-col">
        <button className={"btn at-btn--primary"} type={"Submit"}>
          Submit Information
        </button>
      </section>
    </form>
  );
};

export default View;
