import React from "react";
import Moment from "moment";

import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";

const columnData = [
  {
    header: "Event Date",
    renderColumn: (rowData) =>
      Moment(rowData.event.start_date_time).format("DD MMM YYYY"),
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Event",
    renderColumn: (rowData) => rowData.event.name,
  },
  {
    header: "Ticket Type",
    accessor: "type",
  },
];

const EventList = ({
  selectedProduct,
  availableTicket,

  attachTicket,
  onClickCloseModal,
}) => {
  return (
    <ModalDialog
      title={"Event Ticket Types"}
      onClose={onClickCloseModal}
      children={
        <AtPaginatedTable
          columns={columnData}
          rowData={availableTicket}
          searchParams={[]}
          meta={[]}
          totalPages={[]}
          actionColumnContent={[
            {
              name: "select",
              onClick: (rowData) =>
                attachTicket(selectedProduct.id, rowData.id),
            },
          ]}
        />
      }
    />
  );
};

export default EventList;
