import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      transferType: 1,
      eventList: [],
      showTransferTicket: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeTransferTicketHOC = (key, val) => this.setState({ [key]: val });

    transferTicketRequest = (attendee_id, data) =>
      Put(
        `/admin/user_events/${attendee_id}/transfer`,
        data,
        this.transferTicketRequestSuccess,
        this.transferTicketRequestError,
        this.load,
      );
    transferTicketRequestSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess("Transfer ticket request is completed successfully.");
      this.setState({ showTransferTicket: false }, () => {
        this.props.getAttendeeSummary(this.props.selectedEvent.id);
        this.props.onChangeAttendeeHOC(false, "showEditAttendeeDialog");
        this.props.getAttendeeList(
          this.props.selectedAttendee.event_id,
          currentPage,
          searchParams,
        );
      });
    };
    transferTicketRequestError = (error) => requestError(error);

    getEventBySearch = (val) =>
      Get(
        `/admin/events/search?name=${val}&type=1`,
        this.getEventBySearchSuccess,
        this.getEventBySearchError,
        this.load,
      );
    getEventBySearchSuccess = (payload) => {
      let tmp = [];
      if (payload && payload.length > 0) {
        payload.map((item) => {
          let formatedEventDate = Moment(
            item.start_date_time,
            "YYYY-MM-DD",
          ).format("DD MMM YYYY");
          tmp.push({
            ...item,
            value: item.id,
            label: `${item.name} - ${formatedEventDate}`,
            eventDate: formatedEventDate,
          });
        });
      }
      return this.setState({ eventList: tmp });
    };
    getEventBySearchError = (error) => requestError(error);

    transferTicketEventRequest = (attendee_id, data) =>
      Put(
        `/admin/user_events/${attendee_id}/event_transfer`,
        { new_event_id: data },
        this.transferTicketEventRequestSuccess,
        this.transferTicketEventRequestError,
        this.load,
      );
    transferTicketEventRequestSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess("Transfer event ticket request is successfully.");
      this.setState({ showTransferTicket: false }, () => {
        this.props.getAttendeeSummary(this.props.selectedEvent.id);
        this.props.onChangeAttendeeHOC(false, "showEditAttendeeDialog");
        this.props.getAttendeeList(
          this.props.selectedAttendee.event_id,
          currentPage,
          searchParams,
        );
      });
    };
    transferTicketEventRequestError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onChangeTransferTicketHOC={this.onChangeTransferTicketHOC}
          transferTicketRequest={this.transferTicketRequest}
          transferTicketEventRequest={this.transferTicketEventRequest}
          getEventBySearch={this.getEventBySearch}
          onLoadTransferTicket={this.state.loading}
          showTransferTicket={this.state.showTransferTicket}
          transferType={this.state.transferType}
          eventList={this.state.eventList}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
