import React from "react";

import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import CustomTextarea from "components/Input/textarea";

import RejectHOC from "../../actions/reject";

const CheckboxField = [
  { label: "Payment Proof issue", value: "has_payment_issue" },
  { label: "Resignation Letter issue", value: "has_resignation_letter_issue" },
  { label: "Other issue", value: "has_other_issue" },
];

const ConfirmationModal = ({
  onLoadReject,
  rejectReason,

  rejectNewRENS,
  onChangeRejectHOC,
  onChangeApprovalHOC,

  ...fieldProps
}) => {
  return (
    <>
      <p>Are you sure to reject all the selected new REN?</p>
      <h2 className="at-form-input__title mt-3">Reject Reason</h2>
      <CustomTextarea
        rows="10"
        type="text"
        value={rejectReason}
        placeholder={"Enter reject reason here."}
        onChangeValue={(value) => onChangeRejectHOC(value, "rejectReason")}
        style={{ width: "100%", display: "block" }}
      />
      <div style={{ display: "grid", marginTop: 10 }}>
        {CheckboxField.map((item) => (
          <CustomCheckbox
            checked={fieldProps[item.value]}
            content={item.label}
            onChangeCheckboxValue={e => onChangeRejectHOC(e.target.checked, item.value)}
          />
        ))}
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          className="btn-new btn-new--primary"
          onClick={() => rejectNewRENS()}
        >
          Reject
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeApprovalHOC(false, "showRejectDialog")}
        >
          Cancel
        </button>
      </div>
      {onLoadReject && <LoadingModal />}
    </>
  );
};

export default RejectHOC(ConfirmationModal);
