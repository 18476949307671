import Axios from "axios";

import { GET_ALL_BRANCHES, GET_INVOICING_BRANCHES } from "./type";
import {
  beginAjaxCallBackground,
  ajaxCallErrorBackground,
  ajaxCallSuccessBackground,
} from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export const getBranches = () => {
  return (dispatch) => {
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const token = getItem(tokenName);
    Axios.defaults.headers = {
      common: {
        Authorization: `JWT ${token}`,
      },
      "Access-Control-Allow-Origin": "*",
    };
    dispatch(beginAjaxCallBackground(true));

    Axios.get(`${getDomainURL()}/branches`)
      .then((res) => {
        dispatch(getBranchesSuccess(res.data));
        dispatch(ajaxCallSuccessBackground());
      })
      .catch((err) => {
        dispatch(ajaxCallErrorBackground(err, () => dispatch(getBranches())));
      });
  };
};

const getBranchesSuccess = (payload) => ({
  type: GET_ALL_BRANCHES,
  payload,
});

export const getInvoicingBranches = () => (dispatch) => {
  dispatch(beginAjaxCallBackground(true));
  Axios.get(`${getDomainURL()}/invoicing_branches`)
    .then((res) => {
      dispatch(getInvoicingBranchesSuccess(res.data));
      dispatch(ajaxCallSuccessBackground());
    })
    .catch((err) => {
      dispatch(
        ajaxCallErrorBackground(err, () => dispatch(getInvoicingBranches())),
      );
    });
};

const getInvoicingBranchesSuccess = (payload) => ({
  type: GET_INVOICING_BRANCHES,
  payload,
});
