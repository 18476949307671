import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";

import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showDeleteConfirmation: false,
      showCreateMOTDModal: false,
      showMOTDPreview: false,
      showAttachEvent: false,
      showAddAttachEvent: false,

      MOTD: [],
      newMOTD: [],
      previewMOTD: {},
      meta: [],
      MOTDPage: "",
      eventForm: {
        event: "",
        label: "",
      },
      eventList: [],
      bulkUpdate: [],
      selectedDeleteQuote: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeMOTDHOC = (val, context) => this.setState({ [context]: val });

    getMOTD = (page) => {
      this.props.storeLastView({ currentPage: page });
      Get(
        `/admin/quote_audios?page=${page}`,
        this.getMOTDSuccess,
        this.getMOTDError,
        this.load,
      );
    };
    getMOTDSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      let tempData = payload.data;
      for (let i = 0; i < tempData.length; i++) {
        tempData[i].play = false;
      }

      this.setState({
        MOTD: tempData,
        meta: payload.meta,
        MOTDPage: tmpTotalPages,
      });
    };
    getMOTDError = (error) => requestError(error);

    updateMOTD = (dataToSubmit) => {
      Put(
        `/admin/quote_audios/${dataToSubmit.id}/upload_audio`,
        {
          audio: dataToSubmit.audio,
          audio_file_name: dataToSubmit.audio_file_name,
        },
        this.updateMOTDSuccess,
        this.updateMOTDError,
        this.load,
      );
    };
    updateMOTDSuccess = (payload) => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.getMOTD(currentPage);
      requestSuccess("Audio has been uploaded successfully.");
    };
    updateMOTDError = (error) => requestError(error);

    bulkUpdateMotd = () =>
      Put(
        `/admin/quote_audios/bulk_update`,
        {
          quotes: this.state.bulkUpdate,
        },
        this.bulkUpdateMotdSuccess,
        this.bulkUpdateMotdError,
        this.load,
      );
    bulkUpdateMotdSuccess = () => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.setState({ bulkUpdate: [] });
      this.getMOTD(currentPage);
      requestSuccess("Quotes has been updated successfully.");
    };
    bulkUpdateMotdError = (error) => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.setState({ bulkUpdate: [] }, () => {
        this.getMOTD(currentPage);
        requestError(error);
      });
    };

    putMOTD = () =>
      Post(
        `/admin/quote_audios`,
        {
          quotes: this.state.newMOTD,
        },
        this.putMOTDSuccess,
        this.putMOTDError,
        this.load,
      );
    putMOTDSuccess = () => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.setState(
        {
          newMOTD: [],
          showCreateMOTDModal: false,
        },
        () => {
          this.getMOTD(currentPage);
        },
      );
      requestSuccess("Quote images has been uploaded successfully.");
    };
    putMOTDError = (error) => requestError(error);

    deleteMOTD = () =>
      Delete(
        `/admin/quote_audios/${this.state.selectedDeleteQuote}`,
        this.deleteMOTDSuccess,
        this.deleteMOTDError,
        this.load,
      );
    deleteMOTDSuccess = () => {
      const { currentPage } = this.props.data.lastViewReducer.lastView;
      this.getMOTD(currentPage);
      requestSuccess("Quote has been deleted successfully.");
    };
    deleteMOTDError = (error) => requestError(error);

    getEventList = (search) =>
      Get(
        `/admin/events/search?name=${search}`,
        this.getEventListSuccess,
        this.getEventListError,
        this.load,
      );
    getEventListSuccess = (payload) => this.setState({ eventList: payload });
    getEventListError = (error) => requestError(error);

    putAttachEvent = (id, dataToSubmit) =>
      Post(
        `/admin/quote_audios/${id}/attach_event`,
        dataToSubmit,
        this.putAttachEventSuccess,
        this.putAttachEventError,
        this.load,
      );
    putAttachEventSuccess = (payload) => {
      this.setState({
        previewMOTD: payload,
        showAddAttachEvent: false,
        eventForm: {
          event: "",
          label: "",
        },
      });
      this.getMOTD(1);
      requestSuccess("Event Attached");
    };
    putAttachEventError = (error) => requestError(error);

    deleteAttachedEvent = (id, event_id) =>
      Delete(
        `/admin/quote_audios/${id}/remove_event/${event_id}`,
        this.deleteAttachedEventSuccess,
        this.deleteAttachedEventError,
        this.load,
      );
    deleteAttachedEventSuccess = (payload) => {
      this.setState({ previewMOTD: payload });
      this.getMOTD(1);
      requestSuccess("Attached Event Deleted");
    };
    deleteAttachedEventError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            MOTD={this.state.MOTD}
            meta={this.state.meta}
            newMOTD={this.state.newMOTD}
            bulkUpdate={this.state.bulkUpdate}
            eventForm={this.state.eventForm}
            eventList={this.state.eventList}
            MOTDPage={this.state.MOTDPage}
            previewMOTD={this.state.previewMOTD}
            selectedDeleteQuote={this.selectedDeleteQuote}
            showCreateMOTDModal={this.state.showCreateMOTDModal}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showMOTDPreview={this.state.showMOTDPreview}
            showAttachEvent={this.state.showAttachEvent}
            showAddAttachEvent={this.state.showAddAttachEvent}
            onLoadMOTD={this.state.loading}
            onChangeMOTDHOC={this.onChangeMOTDHOC}
            getMOTD={this.getMOTD}
            updateMOTD={this.updateMOTD}
            deleteMOTD={this.deleteMOTD}
            putMOTD={this.putMOTD}
            getEventList={this.getEventList}
            putAttachEvent={this.putAttachEvent}
            deleteAttachedEvent={this.deleteAttachedEvent}
            bulkUpdateMotd={this.bulkUpdateMotd}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
