import React, { useMemo } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";

const UpdatePermittedItem = ({
  selectedType,
  projectLists,
  selectedPermittedItem,
  onChangePermittedItemsHOC,
  getProjectListings,
  updatePermittedItem,
  onLoadPermittedItem,
}) => {
  const memoizedValue = useMemo(() => {
    let tmp = _.find(projectLists, { id: selectedPermittedItem.project_id });

    return tmp ? [tmp] : [];
  }, [selectedPermittedItem]);

  const onChangeUpdatePermittedItem = (key, value) => {
    let tmp = _.cloneDeep(selectedPermittedItem);
    tmp = {
      ...tmp,
      [key]: value,
    };
    onChangePermittedItemsHOC("selectedPermittedItem", tmp);
  };

  const onChangeTypeaheadSearch = _.debounce(
    (val) => onHandleTypeaheadSearch(val),
    1000,
  );

  const onHandleTypeaheadSearch = (val) => {
    onChangeUpdatePermittedItem("project_id", null);

    return val.length > 0
      ? getProjectListings(val)
      : onChangePermittedItemsHOC("projectLists", []);
  };

  const onClickClose = () => {
    onChangePermittedItemsHOC("showUpdatePermittedItem", false);
    onChangeUpdatePermittedItem("project_id", "");
  };

  const renderRadio = () => {
    return (
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <label className="at-form-input__title">Type</label>
          <CustomRadioGroup
            checkedValue={selectedType}
            containerClassName={"w-100"}
            disabled={true}
            options={[
              { label: "IQI Drive", value: 1 },
              { label: "Project Listing", value: 2 },
            ]}
            selectedRadioValue={(val) => {
              onChangePermittedItemsHOC("selectedType", val);
              if (val === 1) {
                onChangeUpdatePermittedItem("project_id", "");
              } else if (val === 2) {
                onChangeUpdatePermittedItem("link", "");
              }
            }}
          />
        </section>
        <section className="grid-full-col">
          <label className="at-form-input__title" required>
            Link
          </label>
          {selectedType === 1 && (
            <CustomFormInput
              type="text"
              required={true}
              inputError={
                selectedPermittedItem.link ? "" : "The entry cannot be empty."
              }
              placeholder={"Copy a link from IQI Drive and paste over here"}
              value={selectedPermittedItem.link}
              onChangeValue={(val) => {
                onChangeUpdatePermittedItem("link", val);
              }}
            />
          )}
          {selectedType === 2 && (
            <CustomTypeahead
              typeaheadId={"project"}
              options={projectLists || []}
              filterBy={["label"]}
              labelKey={"label"}
              onSelect={(val) => {
                if (val && val.length > 0) {
                  onChangeUpdatePermittedItem("project_id", val[0].id);
                }
              }}
              selectedValue={memoizedValue}
              childrenHead={(rowItem) => <p>{rowItem.label}</p>}
              disabled={onLoadPermittedItem}
              showClearButton={true}
              onClear={() => onChangeUpdatePermittedItem("project_id", null)}
              onSearch={(val) => onChangeTypeaheadSearch(val)}
              highlighterData={[(option) => `${option.name}`]}
            />
          )}
        </section>
      </form>
    );
  };

  return (
    <>
      <ModalDialog
        title={"Update Permitted Item"}
        onClose={() => onClickClose()}
        responsiveSize="lg"
        children={renderRadio()}
        footer={
          <div className="d-flex">
            <button
              className={`btn-new btn-new--success`}
              disabled={
                (selectedType === 2 && !selectedPermittedItem.project_id) ||
                (selectedType === 1 && !selectedPermittedItem.link)
              }
              type={"button"}
              onClick={() => {
                if (selectedType === 2 && selectedPermittedItem.project_id) {
                  updatePermittedItem();
                } else if (selectedType === 1 && selectedPermittedItem.link) {
                  updatePermittedItem();
                }
              }}
            >
              Update
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary ml-2"
              onClick={() => onClickClose()}
            >
              Close
            </button>
          </div>
        }
      />
      {onLoadPermittedItem && <LoadingModal />}
    </>
  );
};

export default UpdatePermittedItem;
