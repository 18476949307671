import React from "react";
import _ from "lodash";
import Moment from "moment";

import UserInfo from "components/NewPaginatedTable/components/UserInfo";
import AtlasIcon from "components/Icon/atlasIcon";
import PropertyInfo from "../propertyInfo";

import { getColorBadge } from "dictionary/badgeColor";

const badgeColor = (id) => {
  switch (id) {
    case 2:
      return getColorBadge("Blue");
    case 3:
      return getColorBadge("Green");
    case 4:
      return getColorBadge("Red");
    case 5:
      return getColorBadge("Red");
    default:
      return getColorBadge("Grey");
  }
};

const SentPartnerColumn = ({
  getProjectDetails,
  setSelectedCobroke,
  onChangeCobrokeHOC,
}) => [
  {
    header: "Agent",
    columnStyle: { width: "120%" },
    columnClass: "at-cobroke__agent-column",
    renderColumn: ({ agent_info, agents_team_name, status_id, status }) => (
      <>
        <span
          className={`at-status_badge mb-10`}
          style={{
            fontSize: 14,
            whiteSpace: "initial",
            ...badgeColor(status_id),
          }}
        >
          {status}
        </span>
        <UserInfo
          name={agent_info?.full_name ?? "N/A"}
          team_name={agents_team_name || "N/A"}
          email={agent_info?.email ?? "N/A"}
          phone_number={agent_info?.phone_number ?? "N/A"}
        />
      </>
    ),
  },
  {
    header: "Property",
    columnStyle: { width: "120%" },
    renderColumn: (rowData) => {
      if (rowData.property_name) {
        return (
          <PropertyInfo
            {...rowData}
            property_type={"project"}
            getPropertyDetails={getProjectDetails}
          />
        );
      } else {
        return (
          <button
            className="btn-new-sm btn-new--secondary"
            style={{ padding: "6px 12px", fontSize: 14 }}
            onClick={() => {
              setSelectedCobroke(rowData);
              onChangeCobrokeHOC(true, "showSelectProject");
            }}
          >
            <AtlasIcon
              svgHref={"atlas-building-4"}
              style={{ width: 16, height: 16, marginRight: 5, fill: "white" }}
            />
            Set Project
          </button>
        );
      }
    },
  },
  {
    header: "Date",
    columnStyle: { width: "50%" },
    columnClass: "at-cobroke__agent-column align-items-center fs-2",
    renderColumn: (rowData) => (
      <>
        <p className="fw-600 my-1">Requested at</p>
        <p className="fs-2 mb-2">{rowData.requested_at}</p>
        <p className="fw-600 my-1">Updated at</p>
        <p className="fs-2">{rowData.updated_at}</p>
      </>
    ),
  },
  {
    header: "Action",
    columnStyle: { width: "80%" },
    columnClass:
      "at-cobroke__agent-column align-items-start at-project-partner__action",
    renderColumn: (rowData) => {
      const { project_sale_cycle } = rowData;
      const requestStatus = () => {
        if (project_sale_cycle) {
          switch (project_sale_cycle.status_id) {
            case 5:
              return getColorBadge("Green");
            case 6:
              return getColorBadge("Red");
            case 7:
              return getColorBadge("Grey");
            default:
              return "";
          }
        } else {
          return getColorBadge("Grey");
        }
      };

      return (
        <>
          <div className="d-flex">
            {!_.isEmpty(project_sale_cycle) && (
              <div className="w-100">
                {project_sale_cycle.status_id === 1 && (
                  <button
                    className="btn-new-sm btn-new--secondary"
                    style={{ padding: "6px 12px", fontSize: 14 }}
                    onClick={() =>
                      Promise.all([setSelectedCobroke(rowData)]).then(() => {
                        onChangeCobrokeHOC(true, "showSetAppointment");
                      })
                    }
                  >
                    <AtlasIcon
                      svgHref={"atlas-calendar-2"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "white",
                        marginRight: 5,
                      }}
                    />
                    Set Appointment
                  </button>
                )}
                {project_sale_cycle.status_id === 2 && (
                  <>
                    <label>Appointment Date</label>
                    <p>
                      <AtlasIcon
                        svgHref={"atlas-calendar-2"}
                        style={{ width: 16, height: 16, marginRight: 5 }}
                      />
                      Set At{" "}
                      {Moment(project_sale_cycle.appointment_date).format(
                        "DD MMM YYYY HH:mm",
                      )}
                    </p>
                    <button
                      className="btn-new-sm btn-new--secondary"
                      onClick={() =>
                        Promise.all([setSelectedCobroke(rowData)]).then(() => {
                          onChangeCobrokeHOC(true, "showSetAppointment");
                        })
                      }
                    >
                      <AtlasIcon
                        svgHref={"atlas-calendar-edit"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "white",
                          marginRight: 5,
                        }}
                      />
                      Edit
                    </button>
                    <label>Next Stage</label>
                    <p>Presentation & Sales Closing</p>
                    <button
                      className="btn-new-sm btn-new--primary"
                      style={{ marginTop: 5 }}
                      onClick={() =>
                        Promise.all([setSelectedCobroke(rowData)]).then(() => {
                          onChangeCobrokeHOC(true, "showRatePartner");
                        })
                      }
                    >
                      <AtlasIcon
                        svgHref={"atlas-verify"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "white",
                          marginRight: 5,
                        }}
                      />
                      Done and Rate
                    </button>
                  </>
                )}
                {[5, 6, 7].includes(project_sale_cycle.status_id) && (
                  <div
                    style={{ ...requestStatus() }}
                    className={"at-status_badge"}
                  >
                    <AtlasIcon
                      svgHref={"atlas-building-4"}
                      style={{ width: 16, height: 16, marginRight: 5 }}
                    />
                    {project_sale_cycle.status}
                  </div>
                )}
              </div>
            )}
            {rowData.status_id === 2 && (
              <div className="w-100">
                <button
                  style={{ color: "#D92D20" }}
                  className="btn-new-sm btn-new--outline-secondary"
                  onClick={() =>
                    Promise.all([setSelectedCobroke(rowData)]).then(() => {
                      onChangeCobrokeHOC(true, "showCancellation");
                    })
                  }
                >
                  Cancel Request
                </button>
              </div>
            )}
            {rowData.status_id === 4 && (
              <div className="w-100">
                <label>Reject Reason</label>
                <p className="m-0">{rowData.reject_reason}</p>
              </div>
            )}
            {rowData.status_id === 5 && (
              <div className="w-100">
                <label>Cancellation Reason</label>
                <p className="m-0">{rowData.reject_reason}</p>
              </div>
            )}
          </div>
          {project_sale_cycle?.status_id !== 4 && <hr />}
          <div className="d-flex flex-wrap">
            <button
              className="btn-new-sm btn-new--secondary mr-1 mb-1"
              onClick={() =>
                Promise.all([setSelectedCobroke(rowData)]).then(() => {
                  onChangeCobrokeHOC(true, "showNotesModal");
                })
              }
            >
              <AtlasIcon
                svgHref={"atlas-note-add"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "white",
                }}
              />
              Notes
            </button>
            <button
              className="btn-new-sm btn-new--outline-secondary mr-1 mb-1"
              onClick={() =>
                Promise.all([setSelectedCobroke(rowData)]).then(() => {
                  onChangeCobrokeHOC(true, "showActivity");
                })
              }
            >
              Activity
            </button>
            {[5, 6, 7].includes(project_sale_cycle?.status_id) && (
              <button
                className="btn-new-sm btn-new--outline-secondary mb-1"
                onClick={() =>
                  Promise.all([setSelectedCobroke(rowData)]).then(() => {
                    onChangeCobrokeHOC(true, "showRatePartner");
                  })
                }
              >
                Rate
              </button>
            )}
          </div>
        </>
      );
    },
  },
];

export default SentPartnerColumn;
