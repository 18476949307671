import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";

export const featureProperty = [
  {
    label: "Beds",
    value: "bedrooms_label",
    icon: "atlas-bedroom",
  },
  {
    label: "Baths",
    value: "bathrooms_label",
    icon: "atlas-bathroom",
  },
  {
    label: "Car Parks",
    value: "carparks_label",
    icon: "atlas-car",
  },
];

export const detailsSection = [
  {
    label: "Status",
    value: "status",
    icon: "atlas-frame-2",
  },
  {
    label: "Available Date",
    value: "formatted_available_date",
    icon: "atlas-calendar-2",
  },
  {
    label: "Updated On",
    value: "updated_on",
    icon: "atlas-calendar-edit",
  },
  {
    label: "Type",
    value: "type",
    icon: "atlas-building-3",
  },
  {
    label: "Listing Category",
    value: "category",
    icon: "atlas-buildings",
  },
  {
    label: "Property Type",
    value: "property",
    icon: "atlas-house",
  },
];

export const publishPlatformLib = [
  {
    title: "IQI Website",
    tooltipTitle: "Click to view this listing on www.iqiglobal.com.",
    linkSrc: "exported_iqi_link",
    published: "published_to_iqi",
    exported: "",
    style: {},
    logoSrc: iqiLogo,
  },
  {
    title: "Juwai Website",
    tooltipTitle: "Click to view this listing on www.juwai.com.",
    linkSrc: "juwai_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    style: {},
    logoSrc: juwaiLogo,
  },
  {
    title: "Juwai Asia",
    tooltipTitle: "Click to view this listing on www.juwai.asia.",
    linkSrc: "exported_juwai_asia_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    style: {},
    logoSrc: juwaiAsiaLogo,
  },
  {
    tooltipTitle: "Click to view this listing on www.edgeprop.my.",
    title: "Edge Property",
    linkSrc: "edge_prop_url",
    published: "published_to_edge_prop",
    exported: "exported_to_edgeprop",
    style: { borderRadius: "50%", overflow: "hidden" },
    logoSrc: edgePropLogo,
  },
];
