import React, { Component } from "react";
import Moment from "moment";

import { FaUserTie } from "react-icons/fa";
import { MdPublic, MdShare } from "react-icons/md";
import SVGIcon from "components/Icon";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";

import defaultImage from "assets/images/av-male.jpg";

class Details extends Component {
  renderCardContent = () => {
    const {
      profileImagesData,
      created_at,
      titleDisplay,
      role,
      mobile_contact_number,
      home_contact_number,
      work_contact_number,
      country_name,
      branch_region_name,
      email,
      branch_name,
      full_name,
    } = this.props;
    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 20 }}
          onClick={() => this.props.onChangeHOC(false, "showDetailsModal")}
        />
        <h4 className="at-card__title">Team Member Details</h4>
        <hr />
        <div className="at-card--profile-imgs">
          {profileImagesData
            ? profileImagesData.map((item, index) => {
                if (item && item.img) {
                  return (
                    <div
                      key={index}
                      style={{
                        border: item.isAvatarUrl && "5px solid #F18E05",
                      }}
                      className="at-card--profile__img-cont"
                    >
                      <img
                        src={
                          item.img.indexOf("av1") > -1 ? defaultImage : item.img
                        }
                        className="profile-img"
                        alt={`Profile Image ${index}`}
                      />
                    </div>
                  );
                }
              })
            : ""}
        </div>
        <h3 className="at-card--profile__title mb-10">{full_name}</h3>
        <ul className="at-card--profile-list">
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <SVGIcon className="svg-icon-mail" svgHref={`icon-mail`} />
              <h2 className="at-card--profile-item-title">Email</h2>
            </div>
            {email && <p>{email}</p>}
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <SVGIcon className="svg-icon-time" svgHref={`icon-time`} />
              <h2 className="at-card--profile-item-title">Member since</h2>
            </div>
            {created_at && <p>{Moment(created_at).format("YYYY-MM-DD")}</p>}
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <FaUserTie style={{ width: "22px", height: "22px" }} />
              <h2 className="at-card--profile-item-title">Role</h2>
            </div>
            <p>{titleDisplay ? titleDisplay : role}</p>
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <SVGIcon className="svg-icon-mobile" svgHref={`icon-mobile`} />
              <h2 className="at-card--profile-item-title">Mobile</h2>
            </div>
            <p>{mobile_contact_number ? mobile_contact_number : ""}</p>
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <SVGIcon className="svg-icon-house" svgHref={`icon-house`} />
              <h2 className="at-card--profile-item-title">Home</h2>
            </div>
            <p>{home_contact_number ? home_contact_number : ""}</p>
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <SVGIcon className="svg-icon-phone" svgHref={`icon-phone`} />
              <h2 className="at-card--profile-item-title">Work</h2>
            </div>
            {work_contact_number && <p>{work_contact_number}</p>}
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <MdPublic style={{ width: "22px", height: "22px" }} />
              <h2 className="at-card--profile-item-title">Country</h2>
            </div>
            {country_name && <p>{country_name}</p>}
          </li>
          <li className="at-card--profile-item grid-3-col">
            <div className="at-card--profile-header">
              <MdShare style={{ width: "22px", height: "22px" }} />
              <h2 className="at-card--profile-item-title">Region</h2>
            </div>
            {branch_region_name && <p>{branch_region_name}</p>}
          </li>
          <li
            style={{ borderBottom: "1px solid #ccdde8" }}
            className="at-card--profile-item grid-3-col"
          >
            <div className="at-card--profile-header">
              <MdShare style={{ width: "22px", height: "22px" }} />
              <h2 className="at-card--profile-item-title">Branch</h2>
            </div>
            {branch_name && <p>{branch_name}</p>}
          </li>
        </ul>
      </>
    );
  };

  render = () => {
    return (
      <CustomCard className={"h-100"} cardContent={this.renderCardContent()} />
    );
  };
}

export default Details;
