import React, { Component } from "react";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import StatContent from "./components/statContent";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "./components/PresentationTable";
import CreateForm from "./components/Create.js";
import EditForm from "./components/Edit.js";

import PresentationHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";
import "./index.scss";

const searchParams = (role) => [
  {
    label: "Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Code",
    value: "transaction_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Draft" },
      { value: "1", label: "Published" },
      { value: "2", label: "Inactive" },
      { value: "3", label: "Expired" },
    ],
  },
  ...(["Super Admin", "Admin"].indexOf(role) > -1
    ? [
        {
          label: "Agent Email",
          value: "user_email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Agent Mobile Number",
          value: "user_mobile_contact_number_cont",
          type: "input",
          param: "",
        },
      ]
    : []),
];

class PresentationCentre extends Component {
  componentDidMount = () => {
    initGA("/dashboard/presentation-centre");
    this.props.getCountryDictionary();
    this.props.getPresentation(1, "");

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role) {
      this.props.onChangePresentationHOC(searchParams(role), "searchParams");
    }
  };

  componentDidUpdate = (prevProps) => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { role: prevRole } = window.location.href.includes("/admin-impersonate",)
      ? prevProps.data.currentSignInProfileReducer
      : prevProps.data.profileReducer;

    if (prevRole !== role) {
      this.props.onChangePresentationHOC(searchParams(role), "searchParams");
    }
  };

  renderCardContent = () => {
    const { presentations, data, presentationPages } = this.props;
    const { can_update, can_destroy } = permissionsChecker(
      "Presentation Centre",
      data,
    );

    return (
      <AtPaginatedTable
        actionColumnContent={["edit"]}
        onClickStats={(presentationData) => {
          Promise.all([
            this.props.onChangePresentationHOC(
              presentationData,
              "selectedPresentation",
            ),
          ]).then(() => {
            this.props.onChangePresentationHOC(true, "showStatModal");
          });
        }}
        onClickEdit={(val) => this.props.getSelectedPresentation(val.id)}
        rowData={presentations.data}
        meta={presentations.meta}
        totalPages={presentationPages}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangePresentationHOC(val, "searchParams")
        }
        getListAPI={this.props.getPresentation}
        can_update={can_update}
        can_destroy={can_destroy}
      />
    );
  };

  render = () => {
    const {
      data,
      showStatModal,
      showCreateModal,
      showDetailsModal,
      onLoadPresentation,
      selectedPresentation,
    } = this.props;

    const { can_create } = permissionsChecker("Presentation Centre", data);
    const { role } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Presentation Centre"}
          atlasIcon={"atlas-presentation-chart"}
          showPABadge={role === "Personal Assistant"}
          description={
            <>
              Create and share presentations for your clients with new projects,
              your own listings or even co-broke using other agents listings all
              in a single online presentation. You can view important statistics
              such as how many times the presentation has been opened, which
              properties have been shortlisted and of course you will receive
              all enquiries directly in the Lead CRM.
              <br />
              <br />
              To get started, create a new presentation by clicking on the{" "}
              <strong> Add </strong> icon or edit an existing one.
            </>
          }
          actionButton={[
            {
              label: "New Presentation",
              onShow: ["Super Admin", "Admin"].indexOf(role) < 0 && can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangePresentationHOC(true, "showCreateModal"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {showCreateModal && (
          <ModalDialog
            title={"New Presentation"}
            onClose={() =>
              this.props.onChangePresentationHOC(false, "showCreateModal")
            }
            children={
              <CreateForm
                onLoadPresentation={onLoadPresentation}
                onClickClose={() =>
                  this.props.onChangePresentationHOC(false, "showCreateModal")
                }
                onClickSubmit={this.props.createPresentation}
              />
            }
          />
        )}
        {showDetailsModal && (
          <ModalDialog
            title={"Edit Presentation"}
            fullHeight={true}
            onClose={() =>
              this.props.onChangePresentationHOC(false, "showDetailsModal")
            }
            children={
              <EditForm
                {...this.props}
                onClickClose={() =>
                  this.props.onChangePresentationHOC(false, "showDetailsModal")
                }
                onClickSubmit={this.props.updatePresentation}
              />
            }
          />
        )}
        {showStatModal && (
          <ModalDialog
            title={"Statistics"}
            fullHeight={true}
            onClose={() =>
              this.props.onChangePresentationHOC(false, "showStatModal")
            }
            children={
              <StatContent
                selectedPresentation={selectedPresentation}
                onClickClose={() =>
                  this.props.onChangePresentationHOC(false, "showStatModal")
                }
              />
            }
          />
        )}
        {onLoadPresentation && <LoadingModal />}
      </>
    );
  };
}

export default PresentationHOC(PresentationCentre);
