import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import ForgetPassword from "./containers/ForgetPassword";
import ResetPassword from "./containers/ResetPassword";
import Register from "./containers/Register";
import RegistrationPayment from "./containers/RegistrationPayment";
import Payment from "./containers/Dashboard/Payment";
import Dashboard from "./containers/Dashboard";
import EghlPaymentGateway from "./containers/EghlPaymentGateway";
import iPay88Feedback from "./containers/iPay88/Feedback";
import StoreyPlan from "./containers/StoreyPlan";
import NDAForm from "./containers/NDA";
import CaseFileAccess from "./containers/AccessCaseFile";

import { getItem, storeItem } from "utils/tokenStore";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (getItem("IQI_ATLAS_JWT_TOKEN") !== null)
        return <Component {...props} />;
      else {
        storeItem("LAST_VIEW_URL", window.location.href);
        return <Redirect to="/login" />;
      }
    }}
  />
);

function CustomRouter({ location }) {
  return (
    <Switch>
      <Route exact path="/login" component={Login} initialPath />
      <Route exact path="/forget-password" component={ForgetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="/case-file-access" component={CaseFileAccess} />
      <Route path="/sub_sale_claims/request" component={CaseFileAccess} />
      <Route path="/reset-password/token=:id" component={ResetPassword} />

      <Route exact path="/register" component={Register} />
      <Route path="/register/ref=:id" component={Register} />
      <Route
        path="/register/register-payment/"
        component={RegistrationPayment}
      />
      <Route path="/register/payment/" component={Payment} />
      <Route
        path="/eghl-payment-gateway/:remark"
        component={EghlPaymentGateway}
      />

      <Route path="/ipay88/feedback/:remark" component={iPay88Feedback} />

      <Route exact path="/storey-plan" component={StoreyPlan} />
      <Route
        path="/admin-impersonate/:profileId/storey-plan"
        component={StoreyPlan}
      />

      <Route exact path="/nda-form" component={NDAForm} />

      <PrivateRoute path={"/"} component={Dashboard} />

      <Redirect from={"/"} to={"/login"} />

      <Route
        exact
        path="/admin-impersonate/:profileId/register"
        component={Register}
      />
      <Route
        path="/admin-impersonate/:profileId/register/ref=:id"
        component={Register}
      />
      <Route
        path="/admin-impersonate/:profileId/register/register-payment/"
        component={RegistrationPayment}
      />
      <Route
        path="/admin-impersonate/:profileId/register/payment/"
        component={Payment}
      />
      <Route
        path="/admin-impersonate/:profileId/eghl-payment-gateway/:remark"
        component={EghlPaymentGateway}
      />
      <Route
        path="/admin-impersonate/:profileId/ipay88/feedback/:remark"
        component={iPay88Feedback}
      />
      <Route
        exact
        path="/admin-impersonate/:profileId/nda-form"
        component={NDAForm}
      />
      <PrivateRoute
        path={"/admin-impersonate/:profileId"}
        component={Dashboard}
      />
      <Redirect from={"/admin-impersonate/:profileId"} to={"/login"} />
    </Switch>
  );
}

export default withRouter(CustomRouter);
