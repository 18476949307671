import React, { useEffect, Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import SVGIcon from "components/Icon";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/ProjectTable";
import ModalDialog from "components/Modal/ModalDialog";
import DetailsContent from "./Details";

import "./index.scss";
import { initGA } from "utils/ga";

import ProjectListingHOC from "./actions";
import DocumentHOC from "./actions/document";
import VisualisationHOC from "./actions/visualisation";
import VideoHOC from "./actions/video";
import DonwloadHOC from "./actions/download";
import VersionCheckHOC from "actions/versionCheck";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

class ProjectListing extends Component {
  componentDidMount = () => {
    initGA("/dashboard/project-listing");
    this.props.getCountryDictionary();
    this.props.getAllStates()
    this.props.getAllTownShips()
    this.props.getProjectListings(1, "");
    let tempSearchParams = this.props.searchParams;
    let countryItem = _.find(tempSearchParams, { label: "Country" });
    let countryIndex = tempSearchParams.indexOf(countryItem);
    tempSearchParams[countryIndex].options = this.props.data.dictionaryReducer.countries;
    this.props.onChangeProjectHOC(tempSearchParams, "searchParams");
    this.debounceService = _.debounce(this.debounceService, 300);
  };

  onClickCard = (item) => {
    this.props.getSelectedProject(item.id);
    this.props.getVideos(item.id);
    this.props.getDocuments(item.id);
    this.props.getVisualisations(item.id);
  };
  
  onChangeSearchParams = (val) => {
    const { countries } = this.props.data.dictionaryReducer;
    let countryIndex = _.findIndex(this.props.searchParams, { label: "Country" });
    let stateIndex = _.findIndex(this.props.searchParams, { label: "State" });
    let townIndex = _.findIndex(this.props.searchParams, { label: "Area"})
    if (val[countryIndex].param
      && ((typeof(val[countryIndex].param) === "string" && (val[countryIndex].param !== this.props.searchParams[countryIndex].param)
       || typeof(val[countryIndex].param) === "object" && (val[countryIndex].param.id !== this.props.searchParams[countryIndex].param.id)))
    ) {
      let states = typeof(val[countryIndex].param) === "string"
        ? _.find(countries, { name: val[countryIndex].param })?.states || []
        : val[countryIndex].param?.states || [];
      states = _.orderBy(states, ["name"], ["asc"]);
      val[stateIndex].options = states;
      val[stateIndex].param = "";
      val[townIndex].options = [];
      val[townIndex].param = "";
    }

    if (val[stateIndex].param
      && (typeof(val[stateIndex].param) === "object" && (val[stateIndex].param.id !== this.props.searchParams[stateIndex].param.id))
    ) {
      val[townIndex].options = [];
      val[townIndex].param = "";
      this.props.getSelectedTownShip(typeof(val[stateIndex].param) === "string" ? val[stateIndex].param : val[stateIndex].param?.id);
    }

    if (val[townIndex].param && (val[stateIndex].param)) {
      this.debounceService(val[townIndex].param);
    }

    this.props.onChangeProjectHOC(val, "searchParams");
  }

  debounceService = (searchVal) => {
    this.props.getAllTownShips(searchVal);
  };

  renderCardContent = () => {
    return (
      <>
        <AtPaginatedTable
          actionsColumns={["details"]}
          rowData={this.props.projects.data ? this.props.projects.data : []}
          meta={this.props.projects.meta && this.props.projects.meta}
          onClickDetails={(val) => this.onClickCard(val)}
          searchParams={this.props.searchParams}
          onChangeSearchParams={this.onChangeSearchParams}
          getListAPI={this.props.getProjectListings}
          totalPages={this.props.totalPages}
        />
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader title={"Project Centre"} atlasIcon={"atlas-building-4"} />
        <CustomCard
          className="at-card--profile"
          cardContent={this.renderCardContent()}
        />
        {this.props.showProjectDetails && (
          <ModalDialog
            title={
              <>
                Project Details
                {role === "Personal Assistant" && (
                  <DarkTooltip
                    style={{ position: "absolute", top: 15, left: 70 }}
                    placement={"right"}
                    title={`You are acting on behalf of your superior in this section.`}
                  >
                    <div>
                      <SVGIcon
                        style={{ fill: "#f18e03", width: 26, height: 26 }}
                        svgHref={"icon-superior"}
                      />
                    </div>
                  </DarkTooltip>
                )}
              </>
            }
            fullWidth={true}
            fullHeight={true}
            onLoad={this.props.onLoadProject}
            onClose={() =>
              this.props.onChangeProjectHOC(false, "showProjectDetails")
            }
            children={
              <DetailsContent
                selectedProject={this.props.selectedProject}
                getSelectedProject={this.props.getSelectedProject}
                documents={this.props.documents}
                onDownloadFile={this.props.onDownloadFile}
                closeDetailsDialog={() =>
                  this.props.onChangeProjectHOC(false, "showProjectDetails")
                }
                visualisations={this.props.visualisations}
                videos={this.props.videos}
              />
            }
          />
        )}
        {this.props.onLoadProject && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  VersionCheckHOC,
  ProjectListingHOC,
  VideoHOC,
  DocumentHOC,
  VisualisationHOC,
  DonwloadHOC,
)(ProjectListing);
