import React, { Component } from "react";
import { compose } from "redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateDialog from "./Create";
import EditDialog from "./Edit";

import SampleMngmtHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

const columnData = [
  {
    header: "Sample Code",
    accessor: "code",
    columnStyle: {
      width: "70%",
    },
  },
  {
    header: "Agent",
    columnStyle: {
      width: "160%",
    },
    renderColumn: (rowData) => {
      let tmpAgentInfo = rowData.agent.split("\n");
      return tmpAgentInfo.map((item) => <p>{item}</p>);
    },
  },
  {
    header: "Status",
    accessor: "status", // status has to be in the last place
  },
];

class SampleMngmt extends Component {
  componentDidMount = () => {};

  renderCardContent = () => {
    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    return (
      <AtPaginatedTable
        tableTitle={"Sample"}
        createTooltipContent={"New sample"}
        showCreateButton={can_create}
        showCloseButton={false}
        onClickCreate={() =>
          this.props.onChangeSampleHOC(true, "showCreateSampleModal")
        }
        columns={columnData}
        helpText={`Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus esse dicta ab, rerum voluptates tenetur, dolores veniam nobis distinctio sint 
        ea enim nisi iure, facilis amet rem harum doloremque dignissimos quod. Veniam rem ullam id minus. Maxime, optio ducimus accusamus ipsam nisi numquam 
        ullam ab minus, libero modi tempora repudiandae.`}
        actionColumnContent={[
          ...(can_update
            ? [
                {
                  name: "edit",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) =>
                    this.props.getSelectedSample(rowData.id),
                },
              ]
            : []),
          ...(can_destroy
            ? [
                {
                  name: "delete",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) => {
                    this.props.onChangeSampleHOC(rowData, "selectedSample");
                    this.props.onChangeSampleHOC(
                      true,
                      "showDeleteConfirmation",
                    );
                  },
                },
              ]
            : []),
        ]}
        rowData={this.props.samples.data ? this.props.samples.data : []}
        meta={this.props.samples.meta && this.props.samples.meta}
        onChangeSearchParams={(val) =>
          this.props.onChangeSampleHOC(val, "searchParams")
        }
        searchParams={[]}
        getListAPI={this.props.getSampleMngmt}
        totalPages={[]}
      />
    );
  };

  render = () => {
    const {
      deleteSample,
      selectedSample,
      showEditSampleModal,
      showCreateSampleModal,
      onChangeSampleHOC,
      onLoadSample,
      showDeleteConfirmation,
    } = this.props;
    return (
      <>
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={showCreateSampleModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <CreateDialog
                onClickSubmit={() => {}}
                showLoading={onLoadSample}
                onClickClose={() =>
                  onChangeSampleHOC(false, "showCreateSampleModal")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showEditSampleModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <EditDialog
                selectedSample={selectedSample}
                showLoading={onLoadSample}
                onClickSubmit={() => {}}
                onClickClose={() =>
                  onChangeSampleHOC(false, "showEditSampleModal")
                }
              />
            }
          />
        </AtlasDialog>
        <ConfirmationModal
          title={"Delete Sample"}
          loading={onLoadSample}
          open={showDeleteConfirmation}
          message={`Are you sure you want to remove this sample ?`}
          positiveAction={() => deleteSample(selectedSample.id)}
          negativeAction={() =>
            onChangeSampleHOC(false, "showDeleteConfirmation")
          }
        />
        {onLoadSample && <LoadingModal />}
      </>
    );
  };
}

export default compose(SampleMngmtHOC, VersionCheckHOC)(SampleMngmt);
