import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import CustomRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";
import AtlasCloseButton from "components/Button/prev";

import "stylesheets/components/select/material.scss";

const NewContactForm = ({
  title,
  onClose,
  onChange,
  profileTypeOptions,
  contactTags,
  banks,
  type_id,
  tags,
  full_name,
  identity_number,
  address,
  company,
  occupation,
  email,
  mobile_number,
  home_telephone_number,
  office_telephone_number,
  bank_id,
  bank_account_number,
  payable_name,
  remark,
  ren_tag,
  has_representative,
  representative_name,
  representative_contact_number,
}) => {
  return (
    <>
      <AtlasCloseButton
        containerStyle={{ marginBottom: 10 }}
        onClick={onClose}
      />
      <h5 className="at-card__title">{title}</h5>
      <hr />
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <CustomRadioGroup
            checkedValue={type_id}
            containerClassName={"mb-20"}
            options={profileTypeOptions}
            selectedRadioValue={(val) => onChange("type_id", val)}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Please select a contact type</h2>
          {contactTags.map((item, index) => {
            return (
              <div key={index}>
                <CustomCheckbox
                  content={item.name}
                  checked={tags ? tags.indexOf(item.id) > -1 : false}
                  onChangeCheckboxValue={(e) => onChange("tags", item.id)}
                />
              </div>
            );
          })}
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Full Name</h2>
          <CustomFormInput
            type="text"
            value={full_name}
            onChangeValue={(val) => onChange("full_name", val)}
            required={false}
            inputError={""}
            placeholder={"enter full name"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">NRIC/Passport No.</h2>
          <CustomFormInput
            type="text"
            value={identity_number}
            onChangeValue={(val) => onChange("identity_number", val)}
            required={false}
            inputError={""}
            placeholder={"enter nric or passport no."}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Address</h2>
          <CustomFormInput
            type="text"
            value={address}
            onChangeValue={(val) => onChange("address", val)}
            required={false}
            inputError={""}
            placeholder={"enter address"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Company</h2>
          <CustomFormInput
            type="text"
            value={company}
            onChangeValue={(val) => onChange("company", val)}
            required={false}
            inputError={""}
            placeholder={"enter company"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Occupation</h2>
          <CustomFormInput
            type="text"
            value={occupation}
            onChangeValue={(val) => onChange("occupation", val)}
            required={false}
            inputError={""}
            placeholder={"enter occupation"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Email</h2>
          <CustomFormInput
            type="text"
            value={email}
            onChangeValue={(val) => onChange("email", val)}
            required={false}
            inputError={""}
            placeholder={"enter email"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Mobile Number</h2>
          <CustomFormInput
            type="text"
            value={mobile_number}
            onChangeValue={(val) => onChange("mobile_number", val)}
            required={false}
            inputError={""}
            placeholder={"enter mobile number"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Home Tel Number</h2>
          <CustomFormInput
            type="text"
            value={home_telephone_number}
            onChangeValue={(val) => onChange("home_telephone_number", val)}
            required={false}
            inputError={""}
            placeholder={"enter home number"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Office Tel Number</h2>
          <CustomFormInput
            type="text"
            value={office_telephone_number}
            onChangeValue={(val) => onChange("office_telephone_number", val)}
            required={false}
            inputError={""}
            placeholder={"enter office number"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Bank Name</h2>
          <Select
            classes={{ root: "material-select-container" }}
            value={bank_id}
            onChange={(e) => onChange("bank_id", e.target.value)}
          >
            {banks &&
              banks.length > 0 &&
              banks.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name} - {item.country?.name}
                  </MenuItem>
                );
              })}
          </Select>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Bank Account Number</h2>
          <CustomFormInput
            type="text"
            value={bank_account_number}
            onChangeValue={(val) => onChange("bank_account_number", val)}
            required={false}
            inputError={""}
            placeholder={"enter bank account number"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Bank Payable Name</h2>
          <CustomFormInput
            type="text"
            value={payable_name}
            onChangeValue={(val) => onChange("payable_name", val)}
            required={false}
            inputError={""}
            placeholder={"enter bank payable name"}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Remark</h2>
          <CustomFormInput
            type="text"
            value={remark}
            onChangeValue={(val) => onChange("remark", val)}
            required={false}
            inputError={""}
            placeholder={"enter remark"}
          />
        </section>

        <section className="grid-full-col">
          <h2 className="at-form-input__title">Ren Tag</h2>
          <CustomFormInput
            type="text"
            value={ren_tag ? ren_tag : ""}
            onChangeValue={(val) => onChange("ren_tag", val)}
            required={false}
            inputError={""}
            placeholder={
              "Is this a property agent? If so, add the agent/REG tag"
            }
          />
        </section>
        <section className="grid-full-col">
          <CustomCheckbox
            content={"Does this contact has representative or agent?"}
            checked={has_representative}
            onChangeCheckboxValue={(e) =>
              onChange("has_representative", e.target.checked)
            }
          />
        </section>
        {has_representative && (
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Representative Name</h2>
            <CustomFormInput
              type="text"
              value={representative_name}
              onChangeValue={(val) => onChange("representative_name", val)}
              required={false}
              inputError={""}
              placeholder={"enter representative name"}
            />
          </section>
        )}
        {has_representative && (
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Representative Contact Number</h2>
            <CustomFormInput
              type="text"
              value={representative_contact_number}
              onChangeValue={(val) =>
                onChange("representative_contact_number", val)
              }
              required={false}
              inputError={""}
              placeholder={"enter representative contact number"}
            />
          </section>
        )}
      </form>
    </>
  );
};

export default NewContactForm;
