import { PiBathtubFill, PiBedFill } from 'react-icons/pi';
import { RiRuler2Line } from 'react-icons/ri';

const TrapeziumsTemplate = ({ property, unitType, configuration }) => {
  const detailStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  };

  const iconStyle = {
    width: 12,
    height: 12,
    marginRight: 4
  };

  return (
    <div>
      <div style={{ display: 'inline-flex', position: 'relative', bottom: -8 }}>
        <div
          style={{
            padding: '8px 12px',
            backgroundColor: '#0065ed',
            zIndex: 2
          }}
        >
          <p style={{ color: '#fff', fontSize: 12, fontWeight: 700 }}>
            {unitType}
          </p>
        </div>
        <div
          style={{
            zIndex: 2,
            borderTop: '32px solid #0065ed',
            borderRight: '20px solid transparent'
          }}
        />
      </div>
      <br />
      <div style={{ display: 'inline-flex', zIndex: 1, color: '#fff' }}>
        <div
          style={{
            display: 'flex',
            gap: 8,
            padding: '14px 28px 8px 8px',
            backgroundColor: `rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <p style={detailStyle}>
              <RiRuler2Line style={iconStyle} />
              <span>
                {property.min_built_up?.toLocaleString()} -{' '}
                {property.max_built_up?.toLocaleString()} Sqft
              </span>
            </p>
            <p style={detailStyle}>
              <PiBedFill style={iconStyle} />
              <span>{property.bedroom_display || 0} bedrooms</span>
            </p>
            <p style={detailStyle}>
              <PiBathtubFill style={iconStyle} />
              <span>{property.bathroom_display || 0} bathrooms</span>
            </p>
          </div>
          <div style={{ width: 1, height: 'auto', backgroundColor: '#fff' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {property.project_features?.slice(0, 3)?.map(feature => (
              <p key={feature.name} style={detailStyle}>
                {feature.name?.trim()}
              </p>
            ))}
          </div>
        </div>
        <div
          style={{
            borderTop: `66px solid rgba(0, 0, 0, ${configuration?.propertyDetailsOpacity})`,
            borderRight: '40px solid transparent'
          }}
        />
      </div>
    </div>
  );
};

export default TrapeziumsTemplate;
