import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Dialog } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AssignContactContent from "components/AssignContactContent";
import CreateContactContent from "components/CreateAssignContact";
import InternalAgentContent from "components/AssignInternalAgent";
import EditContactContent from "./editAssignContact";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import AtlasDeleteConfirmationDialog from "components/Modal/delete";
import PartyTable from "../PartyTable";

import NationalityHOC from "actions/nationality";
import permissionsChecker from "utils/permissionsChecker";

const tableHeader = [
  {
    label: "Full Name",
    value: "participants_name",
    width: "160%",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "NRIC/Passport No.",
    value: "identification_number",
    width: "70%",
  },
  {
    label: "Mobile No.",
    value: "mobile_contact_number",
    width: "70%",
  },
];

class PartyContent extends Component {
  state = {
    selectedPartyId: null,
    selectedTitle: "",
    selectedDeleteContact: null,
  };

  componentDidMount = () => {
    this.props.getNationalities()
  };

  onChangeEditField = (val, context) => {
    let temp = _.cloneDeep(this.props.selectedContact);
    temp[context] = val;
    this.props.onChangePartyHOC(temp, "selectedContact");
  };

  onClickCreate = (val, partyId, title) => {
    this.props.onChangePartyHOC(true, val);
    this.setState({
      selectedPartyId: partyId,
      selectedTitle: title,
    });
  };

  renderInternalContent = () => (
    <InternalAgentContent
      selectedTitle={this.state.selectedTitle}
      selectedPartyId={this.state.selectedPartyId}
      selected_form={this.props.selected_form}
      assignParty={this.props.assignParty}
      nationalities={this.props.nationalities}
      getInternalAgent={this.props.getInternalAgent}
      onLoadParty={this.props.onLoadParty}
      onLoadInternalAgents={this.props.onLoadInternalAgents}
      onChangePartyHOC={this.props.onChangePartyHOC}
    />
  );

  renderAssignContactContent = () => (
    <AssignContactContent
      contacts={this.props.contacts}
      assignContact={this.props.assignContact}
      onLoadParty={this.props.onLoadParty}
      nationalities={this.props.nationalities}
      selected_form={this.props.selected_form}
      onChangePartyHOC={this.props.onChangePartyHOC}
      selectedPartyId={this.state.selectedPartyId}
      selectedTitle={this.state.selectedTitle}
    />
  );

  renderCreateContactContent = () => (
    <CreateContactContent
      data={this.props.data}
      form_type={this.props.form_type}
      onLoadParty={this.props.onLoadParty}
      selectedPartyId={this.state.selectedPartyId}
      selected_form={this.props.selected_form}
      createParty={this.props.createParty}
      onChangePartyHOC={this.props.onChangePartyHOC}
      nationalities={this.props.nationalities}
      getCurrentUserContactList={this.props.getCurrentUserContactList}
      selectedTitle={this.state.selectedTitle}
    />
  );

  renderEditContent = () => {
    const { can_update } = permissionsChecker(
      "Forms",
      this.props.data,
    );
    const { e_process } = this.props.selected_form;
    const { status_id = 0 } = e_process ?? {}

    return (
      <EditContactContent
        title={this.state.selectedTitle}
        data={this.props.data}
        can_update={can_update}
        form_type={this.props.form_type}
        onLoadParty={this.props.onLoadParty}
        selected_form={this.props.selected_form}
        signingStatusId={status_id}
        nationalities={this.props.nationalities}
        onClose={() => this.props.onChangePartyHOC(false, "showEditPartyModal")}
        selectedContact={this.props.selectedContact}
        onChangePartyHOC={this.props.onChangePartyHOC}
        updateDueDiligence={this.props.updateDueDiligence}
        updatePartyContact={this.props.updatePartyContact}
        downloadCompletedForm={this.props.downloadCompletedForm}
        getSelectedFormContact={this.props.getSelectedFormContact}
      />
    );
  }

  render = () => {
    const {
      mode,
      form_type,
      closedEditing,
      selected_form,
      partyData,
      partyDictionary,
      showEditPartyModal,
      onChangePartyHOC,
      assignParty,
      deletePartyContact,
      showDeletePartyModal,
      showAssignMyselfModal,
      showCreateContactModal,
      showAssignContactModal,
      showAssignInternalModal,
      showAssignSuperiorModal,
      onLoadParty,
      renderNavButton,
    } = this.props;
    const { type_id, isLocked, is_claimed, is_buyer_claimed, is_owner_claimed, e_process } = selected_form;
    const { status_id } = e_process ?? {}
    const eProcessAllowedChecking = e_process === null || (e_process !== null && status_id === 0)
    const tmpIsClaimed = form_type !== "confirmation"
      ? isLocked 
      : (is_claimed || is_buyer_claimed || is_owner_claimed)

    let tempActionContent = [];
    const { can_update, can_destroy, can_create } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    if (eProcessAllowedChecking && !tmpIsClaimed) {
      if( can_update ) {
        tempActionContent.push({
          name: "edit",
          onShow: content => content.contactable_type_id === 1,
          disabled: closedEditing,
          onClick: (val, title) => {
            Promise.all([
              onChangePartyHOC(
                {
                  ...val,
                  signee_type_id: `${val.signee_type_id}`,
                },
                "selectedContact",
              ),
              this.setState({selectedTitle: title})
            ]).then(() => {
              onChangePartyHOC(true, "showEditPartyModal");
            });
          },
        })
      } else {
        tempActionContent.push({
          name: "details",
          onShow: content => content.contactable_type_id === 1 ,
          onClick: (val, title) => {
            Promise.all([
              onChangePartyHOC(
                {
                  ...val,
                  signee_type_id: `${val.signee_type_id}`,
                },
                "selectedContact",
              ),
              this.setState({selectedTitle: title})
            ]).then(() => {
              onChangePartyHOC(true, "showEditPartyModal");
            });
          },
        })
      }
      can_destroy &&
        tempActionContent.push({
          name: "delete",
          onClick: (val) => {
            this.setState({ selectedDeleteContact: val });
            this.props.onChangePartyHOC(true, "showDeletePartyModal");
          },
        });
    } else {
      tempActionContent.push({
        name: "details",
        onShow: content => content.contactable_type_id === 1,
        onClick: (val, title) => {
          Promise.all([
            onChangePartyHOC(
              {
                ...val,
                signee_type_id: `${val.signee_type_id}`,
              },
              "selectedContact",
            ),
            this.setState({selectedTitle: title})
          ]).then(() => {
            onChangePartyHOC(true, "showEditPartyModal");
          });
        },
      });
    }
    return (
      <>
        {partyData &&
          partyData.map((item, index) => {
            // appointment and authorisation's agent party doesnt have action button
            let isAgentParty = form_type !== "confirmation" && item.id % 2 === 0;
            let temp = _.find(partyDictionary, { id: item.id });
            let tempTitle = type_id === 1 ? temp.subsalesTitle : temp.subrentTitle;

            return (
              <div key={item.id} className="mb-3">
                <PartyTable
                  mode={mode}
                  key={item.id}
                  index={index}
                  title={tempTitle}
                  form_type={form_type}
                  partyDictionary={partyDictionary}
                  headerData={tableHeader}
                  actionColumn={!isAgentParty && tempActionContent.length > 0}
                  actionColumnContent={isAgentParty ? [] : tempActionContent}
                  rowData={item.data}
                  partyId={item.id}
                  hideSearch={true}
                  showCreate={
                    // check if the esigning has been started or not and permission
                    can_create &&
                    !tmpIsClaimed &&
                    eProcessAllowedChecking &&
                    !isAgentParty
                  }
                  onClickAssignSuperior={() =>
                    this.onClickCreate(
                      "showAssignSuperiorModal",
                      item.id,
                      tempTitle,
                    )
                  }
                  onClickAssignMyself={() =>
                    this.onClickCreate(
                      "showAssignMyselfModal",
                      item.id,
                      tempTitle,
                    )
                  }
                  onClickAssignContact={() =>
                    this.onClickCreate(
                      "showAssignContactModal",
                      item.id,
                      tempTitle,
                    )
                  }
                  onClickCreateContact={() =>
                    this.onClickCreate(
                      "showCreateContactModal",
                      item.id,
                      tempTitle,
                    )
                  }
                  onClickAssignInternal={() =>
                    this.onClickCreate(
                      "showAssignInternalModal",
                      item.id,
                      tempTitle,
                    )
                  }
                  rowKey={"id"}/>
              </div>
            );
          })}
        <div className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
          {renderNavButton && renderNavButton()}
        </div>
        <AtlasDeleteConfirmationDialog
          open={showDeletePartyModal}
          loading={onLoadParty}
          title="Delete Record"
          mode={"alert"}
          message={"Are you sure to delete this record?"}
          positiveAction={() =>
            deletePartyContact(
              this.state.selectedDeleteContact.form_id,
              this.state.selectedDeleteContact.id,
            )
          }
          negativeAction={() =>
            this.props.onChangePartyHOC(false, "showDeletePartyModal")
          }/>
        <AtlasConfirmationDialog
          open={showAssignMyselfModal}
          loading={onLoadParty}
          message={`Are you sure to assign yourself as ${this.state.selectedTitle}?`}
          positiveAction={() =>
            assignParty(
              {
                party_id: this.state.selectedPartyId,
                agent_id: window.location.href.includes("/admin-impersonate")
                  ? this.props.data.currentSignInProfileReducer.id
                  : this.props.data.profileReducer.id,
              },
              selected_form.id,
              this.state.selectedTitle,
            )
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showAssignMyselfModal")
          }
        />
        <AtlasConfirmationDialog
          open={showAssignSuperiorModal}
          loading={onLoadParty}
          mode={"alert"}
          message={`Are you sure to assign your superior as ${this.state.selectedTitle}?`}
          positiveAction={() =>
            assignParty(
              {
                party_id: this.state.selectedPartyId,
                agent_id: window.location.href.includes("/admin-impersonate")
                  ? this.props.data.currentSignInProfileReducer.superior_id
                  : this.props.data.profileReducer.superior_id,
              },
              selected_form.id,
              this.state.selectedTitle,
            )
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showAssignSuperiorModal")
          }/>
        {showAssignInternalModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"lg"}>
            {this.renderInternalContent()}
          </Dialog>
        )}
        {showEditPartyModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"lg"}>
            {this.renderEditContent()}
          </Dialog>
        )}
        {showCreateContactModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"lg"}>
            {this.renderCreateContactContent()}
          </Dialog>
        )}
        {showAssignContactModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"lg"}>
            {this.renderAssignContactContent()}
          </Dialog>
        )}
        {onLoadParty && <LoadingModal />}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(NationalityHOC(PartyContent));
