import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomButton from "components/Button";
import AtlasTable from "components/NewTable";
import AlertBox from "components/AlertBox";
import DetailModal from "./detailModal";
import RejectModal from "./rejectModal";

import UnlockHOC from "../actions/confirmationUnlock";
import SupportHOC from "../actions/supportingDocs";

import "stylesheets/containers/profile/index.scss";

const ColumnData = [
  {
    header: "Date",
    searchFlag: "requested_datetime",
    accessor: "requested_datetime",
  },
  {
    header: "Reason",
    searchFlag: "reason",
    accessor: "reason",
  },
  {
    header: "Status",
    searchFlag: "status",
    accessor: "status",
  },
  {
    header: "Admin",
    searchFlag: "admin_display_name",
    accessor: "admin_display_name",
  },
];

const DetailsChecking = [
  { label: "Property Address", value: "property_address" },
  { label: "Purchaser/Tenant", value: "purchaserNTenant" },
  { label: "Earnest Deposit", value: "earnest_deposit_amount" },
  { label: "Confirmation Date", value: "confirmation_date" },
];

class UnlockContent extends Component {
  componentDidUpdate = (pp) => {
    if (
      pp.showRejectRequestModal !== this.props.showRejectRequestModal &&
      !this.props.showRejectRequestModal
    ) {
      this.props.onChangeUnlockHOC("", "rejectReason");
    }
  };

  componentDidMount = () =>
    this.props.getUnlockRequest(this.props.selectedConfirmationForm.id);

  checkRequiredInfo = () => {
    return _.values(DetailsChecking).some((x) => {
      if (x.value === "purchaserNTenant") {
        if (!this.props[x.value]) {
          return true;
        }
      } else if (!this.props.selectedConfirmationForm[x.value]) {
        return true;
      }
    });
  };

  renderTableHeading = () => {
    const { selectedConfirmationForm } = this.props;
    return (
      <div className={`at-table_heading`}>
        <div className="d-flex w-100 grid_gap-2 align-items-center">
          <AtlasIcon
            className="at-icon_svg-table_heading-title"
            svgHref={"atlas-receive-square"}
          />
          <h5 className="fw-600">{"Temp. Receipt Request"}</h5>
        </div>
        <AlertBox
          title={""}
          containerClassName="w-100"
          description={
            <>
              <span>
                Create a new unlock download request to re-download the receipt.
                You may only download the file once after each approved request.
              </span>
              {selectedConfirmationForm.temp_receipt_closed_editing && (
                <>
                  <br />
                  <span>You have downloaded the temporary receipt once.</span>
                </>
              )}
              {!selectedConfirmationForm.temp_receipt_closed_editing && (
                <>
                  <br />
                  <span>
                    Please submit a new request if you need to change any of the
                    information on the receipt.
                  </span>
                </>
              )}
            </>
          }
        />
      </div>
    );
  };

  render = () => {
    const {
      unlockRequests,
      showRejectRequestModal,
      showUnlockRequestDetailsModal,
      selectedUnlockRequest,
      onChangeUnlockHOC,
      downloadAckReceipt,
      getSelectedUnlockRequest,
      getSupportingDocs,
      selectedConfirmationForm,
      onChangeConfirmationFormHOC,
      onLoadUnlock,
      onLoadSupport,
      supportingDocs,
      updateUnlockRequest,
      rejectReason,
    } = this.props;

    const { id, transaction_number } = selectedConfirmationForm;

    return (
      <>
        <AlertBox
          mode={"info"}
          title={""}
          containerClassName="mb-3"
          description={`Please double-check that you have entered all of the required information correctly on the Details tab before downloading the temporary receipt. The receipt can only be downloaded once; further downloads are not permitted.`}
        />
        <div className="at-form__content">
          {this.checkRequiredInfo() && (
            <AlertBox
              mode={"danger"}
              description={
                <span style={{ color: "#af1414" }}>
                  Please resolve the following missing information which is
                  marked red.
                </span>
              }
            />
          )}
          {DetailsChecking.map((item, index) => (
            <div key={index} className="d-flex align-items-center mt-10">
              {((item.value === "purchaserNTenant" && this.props[item.value]) ||
                Boolean(selectedConfirmationForm[item.value])) && (
                <BiCheckCircle
                  style={{ width: 25, height: 25, color: "#00d900" }}
                />
              )}
              {!(
                (item.value === "purchaserNTenant" && this.props[item.value]) ||
                Boolean(selectedConfirmationForm[item.value])
              ) && (
                <BiXCircle
                  style={{ width: 25, height: 25, color: "#ff3131" }}
                />
              )}
              <p className="ml-2 fs-2">{item.label}</p>
            </div>
          ))}
          <div className="d-flex mt-3">
            <CustomButton
              disabled={
                selectedConfirmationForm.temp_receipt_closed_editing ||
                this.checkRequiredInfo()
              }
              className={"btn-new btn-new--secondary"}
              children={"Download"}
              onClick={() =>
                downloadAckReceipt(
                  id,
                  `${transaction_number}_Acknowledgement_Receipt.pdf`,
                )
              }
            />
            <CustomButton
              className={"btn-new btn-new--outline-secondary ml-2"}
              children={"Close"}
              onClick={() =>
                onChangeConfirmationFormHOC("showEditModal", false)
              }
            />
          </div>
        </div>
        <AtlasTable
          mode={"new"}
          columns={ColumnData}
          pagination={true}
          actionColumnContent={[
            {
              name: "details",
              onClick: (rowData) => {
                getSelectedUnlockRequest(
                  selectedConfirmationForm.id,
                  rowData.id,
                );
                getSupportingDocs(selectedConfirmationForm.id, rowData.id);
              },
            },
          ]}
          rowData={unlockRequests}
          emptyStateMessage={"You don’t have any download request yet"}
          emptyStateDescription={
            <>
              Click<b>New Request</b> to re-download the receipt
            </>
          }
          emptyStateIcon={<AtlasIcon svgHref={"atlas-receive-square"} />}
          renderExtraContent={this.renderTableHeading}
        />
        <DetailModal
          supportingDocs={supportingDocs}
          open={showUnlockRequestDetailsModal}
          onChangeUnlockHOC={onChangeUnlockHOC}
          updateUnlockRequest={updateUnlockRequest}
          data={this.props.data}
          selectedConfirmationForm={selectedConfirmationForm}
          selectedUnlockRequest={selectedUnlockRequest}
        />
        <RejectModal
          open={showRejectRequestModal}
          rejectReason={rejectReason}
          onChangeUnlockHOC={onChangeUnlockHOC}
          updateUnlockRequest={updateUnlockRequest}
          selectedUnlockRequest={selectedUnlockRequest}
          selectedConfirmationForm={selectedConfirmationForm}
        />

        {(onLoadUnlock || onLoadSupport) && <LoadingModal />}
      </>
    );
  };
}

export default compose(UnlockHOC, SupportHOC)(UnlockContent);
