import React from 'react'
import _ from 'lodash'
import { ImWhatsapp, ImTelegram } from 'react-icons/im'

import CustomButton from 'components/Button';
import AtlasIcon from 'components/Icon/atlasIcon'

import { requestWarning } from 'utils/requestHandler';
import 'stylesheets/components/share/index.scss'

const Share = ({
  mediaURL,
  shareLink,
  encodedLink,
  downloadTarget,
  downloadTooltip,
  onClickDownload,
  showElement,
}) => {

  const shareComponents = [
    {
      title: 'Whatsapp',
      background: '#12B76A',
      children: <ImWhatsapp style={{ width: 20, height: 20, color: 'white' }} />,
      tooltipChildren: 'Share in WhatsApp',
      tooltipID: 'share-whatsapp',
      onClick: () => window.open( `https://api.whatsapp.com/send/?phone&text=${ encodedLink ? encodedLink : shareLink }`, '_blank' )
    },
    {
      title: 'Telegram',
      background: '#60A5FA',
      children: <ImTelegram style={{ width: 20, height: 20, color: 'white' }} />,
      tooltipChildren: 'Share in Telegram',
      tooltipID: 'share-telegram',
      onClick: () => window.open( `https://t.me/share/url?url=${ encodedLink ? encodedLink : shareLink }`, '_blank' )
    },
    {
      title: 'Download',
      background: '#FF8E00',
      children: <AtlasIcon svgHref={ 'atlas-import-2' } style={{ width: 20, height: 20, fill: 'white' }} />,
      tooltipChildren: downloadTooltip || 'Download',
      tooltipID: 'download-media',
      onClick: () => onClickDownload( mediaURL )
    },
    {
      title: 'Open in New Tab',
      background: '#6875F5',
      children: <AtlasIcon svgHref={ 'atlas-export-3-linear' } style={{ width: 20, height: 20, fill: 'white' }} />,
      tooltipChildren: 'Open in New Tab',
      tooltipID: 'open-new-tab',
      onClick: () =>  window.open( shareLink, '_blank' )
    },
    {
      title: 'Copy Link',
      background: '#3B82F6',
      children: <AtlasIcon svgHref={ 'atlas-link-2-linear' } style={{ width: 20, height: 20, fill: 'white' }} />,
      tooltipChildren: 'Copy Link',
      tooltipID: 'copy-url',
      onClick: () =>  onClickCopy()
    }
  ]

  const onClickCopy = () => {
    navigator.clipboard.writeText( shareLink )
    requestWarning( 'Link Copied' )
  }

  return(
    <div className={ `at-event-training__share` }>
      {
        shareComponents.map(( item, index ) => {
          if( _.includes( showElement, item.title )) {
            return (
              <div className='at-share-modal__button-container' key={ index }
                onClick={() => {
                  if( item.title === 'Download' ) {
                    onClickDownload( mediaURL )
                  } else {
                    item.onClick()
                  }
                }}>
                { 
                  item.title === 'Download' && (
                    <a
                      href={ mediaURL }
                      download={ true }
                      children={ item.children }
                      target={ downloadTarget || '_blank' }
                      className={ 'at-share-modal__button mb-2' }
                      style={{ backgroundColor: item.background }} />
                  )
                }
                {
                  item.title !== 'Download' && (
                    <CustomButton
                      containerClass={ 'at-share-modal__button mb-2' }
                      containerStyle={{ backgroundColor: item.background }}
                      children={ item.children } 
                      tooltip={ true }
                      tooltipChildren={ item.tooltipChildren }
                      tooltipPosition={ 'top' }
                      tooltipID={ item.tooltipID } />
                  )
                }
                {
                  item.showContent && item.showContent()
                }
                { item.title }
              </div>
            )
          }
        })
      }
    </div>
  )
}

export default Share