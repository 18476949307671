import React, { useMemo } from "react";
import _ from "lodash";
import { Card, CardHeader } from "@material-ui/core";
import ReactHTMLParser from "react-html-parser";
import { AiOutlineClose } from "react-icons/ai";

import defaultImage from "assets/images/iqi_logo_only.png";

const typeLib = [
  {
    id: 1,
    src: "event_notification",
  },
  {
    id: 2,
    src: "reports_notification",
  },
  {
    id: 3,
    src: "reminder_notifcations",
  },
  {
    id: 4,
    src: "new_ren_notifications",
  },
  {
    id: 5,
    src: "subsales_ notifcations",
  },
  {
    id: 6,
    src: "video_creator",
  },
  {
    id: 7,
    src: "project_sales",
  },
  {
    id: 9,
    src: "cobroke-request",
  },
];

const NotificationDetails = ({
  selectedNotification,

  closeDialog,
}) => {
  const { type_id, content, title } = selectedNotification;

  const imgSrc = useMemo(() => {
    return _.find(typeLib, { id: type_id });
  }, [type_id]);

  return (
    <Card classes={{ root: "overflow-auto" }}>
      <CardHeader
        style={{ background: "#F9FAFB", borderBottom: "1px solid #D1D5DB" }}
        action={
          <button
            className="btn-new btn-new--outline-secondary ml-auto"
            onClick={() => closeDialog()}
          >
            <AiOutlineClose
              style={{ width: 14, height: 14, color: "#374151" }}
            />
          </button>
        }
        title={<h2 className="at-card__title">{selectedNotification.title}</h2>}
      />
      <img
        className={"at-notification-modal_img"}
        style={{ width: "100%", maxHeight: 180, objectFit: "cover" }}
        src={imgSrc ? require(`assets/images/${imgSrc.src}.jpg`) : defaultImage}
      />
      <div className={"mt-20 mb-20"} style={{ padding: "10px 20px 80px" }}>
        {ReactHTMLParser(content)}
      </div>
      <div
        style={{
          background: "#F9FAFB",
          borderTop: "1px solid #D1D5DB",
          padding: 10,
        }}
      >
        <button
          className="btn-new btn-new--outline-secondary m-auto"
          onClick={closeDialog}
        >
          Close
        </button>
      </div>
    </Card>
  );
};

export default NotificationDetails;
