import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import CustomButton from "components/Button";
import ProgressBar from "./progressBar";

const Payment = ({
  selectedProject,
  selectedUnit,

  uploadPaymentSlip,
  getProjectUnits,
  onClose,
}) => {
  const [paymentSlip, setPaymentSlip] = useState({
    payment_slip: "",
    payment_slip_file_name: "",
  });
  const [timeLeft, setTimeLeft] = useState(0);
  const [initialTime, setInitialTime] = useState(0);

  const formatRemainingTime = (time) => {
    const hours =
      Math.floor((time / 3600) % 24) < 10
        ? `0${Math.floor((time / 3600) % 60)}`
        : Math.floor((time / 3600) % 24);
    const minutes =
      Math.floor((time / 60) % 60) < 10
        ? `0${Math.floor((time / 60) % 60)}`
        : Math.floor((time / 60) % 60);
    const seconds =
      Math.floor(time % 60) < 10
        ? `0${Math.floor(time % 60)}`
        : Math.floor(time % 60);

    return `${hours}:${minutes}:${seconds}`;
  };

  const calculateTimeLimit = (setting) => {
    let multiplier = 0;
    switch (setting.unit) {
      case "seconds":
        multiplier = 1000;
        break;
      case "minutes":
        multiplier = 60000;
        break;
      case "hours":
        multiplier = 3600000;
        break;
    }
    return Number(setting.time) * multiplier;
  };

  useEffect(() => {
    if (
      selectedProject.reservation_time_limit &&
      selectedProject.reservation_time_limit.settings.has_time_limit
    ) {
      let timeLimit = calculateTimeLimit(
        selectedProject.reservation_time_limit.settings.payment_slip,
      );
      let reserveTime = new Date(
        selectedUnit.reservations_attributes
          ? selectedUnit.reservations_attributes.reservation_time
          : selectedUnit.reservation_date,
      );
      let difference = reserveTime - +new Date();
      difference = timeLimit + difference;
      setInitialTime(timeLimit);
      if (difference > 0) setTimeLeft(difference);
      else onClose();
    }
  }, [selectedUnit]);

  const onUploadDocs = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let files = e.target.files;
      Object.keys(e.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = {
            payment_slip: e.target.result,
            payment_slip_file_name: files[key].name,
          };
          setPaymentSlip(tmp);
        };
        reader.readAsDataURL(e.target.files[key]);
      });
    }
  };

  const renderInputFooter = () => (
    <div className={"d-flex pb-3 g-3"}>
      <CustomButton
        disabled={_.values(paymentSlip).some((x) => x === "")}
        className={"btn-new btn-new--primary"}
        children={"Upload"}
        onClick={() => uploadPaymentSlip(paymentSlip, selectedUnit)}
      />
      <button
        type={"button"}
        className="btn-new btn-new--outline-secondary"
        onClick={() => onClose()}
      >
        Cancel
      </button>
    </div>
  );

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Time Out</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining time</div>
        <div className="value">{formatRemainingTime(remainingTime)}</div>
      </div>
    );
  };

  let filePond = useRef(null);

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: 600,
        margin: "auto",
        maxHeight: "80vh",
      }}
    >
      <div className="my-4">
        <h3 className="at-card__title mb-3 mr-0">{`Reservation of Unit ${selectedUnit.unit_no}`}</h3>
        <ProgressBar progress={"Payment Details"} has_time_limit={true} />
      </div>
      {timeLeft > 0 && (
        <div
          className={"at-storey-plan__timer-container"}
          style={{ margin: "auto" }}
        >
          <CountdownCircleTimer
            isPlaying
            size={140}
            strokeWidth={8}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            isLinearGradient={true}
            duration={initialTime / 1000}
            initialRemainingTime={timeLeft / 1000}
            colorsTime={[
              initialTime / 1000,
              initialTime / 1000 / 2,
              initialTime / 1000 / 3,
              0,
            ]}
            onComplete={() => {
              if (getProjectUnits) {
                getProjectUnits();
              }
              onClose();
            }}
            trailColor={[["#dbdbdb"]]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      )}
      <div className="at-storey-plan__confirmation-text">
        <p>
          To confirm the reservation of this unit, you are required to upload
          valid proof of payment within the time limit shown. This could be in
          the form of a good quality and readble image of a correctly completed
          cheque, bank-in or transfer slip.{" "}
        </p>
        <br />
        <p>
          Click on the upload button and then select the payment proof file. Use
          either PDF or JPG/PNG files.
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "500", color: "Green", fontSize: 18 }}>
            ✓{" "}
          </span>
          Correct amount and valid payment proof
        </p>
        <p>
          <span style={{ fontWeight: "500", color: "Green", fontSize: 18 }}>
            ✓{" "}
          </span>
          Good quality readable image
        </p>
        <p>
          <span style={{ fontWeight: "500", color: "Green", fontSize: 18 }}>
            ✓{" "}
          </span>
          If in the form of a cheque, submit the exact same cheque to the
          developer/stakeholder.
        </p>
        <p>
          <span style={{ fontWeight: "500", color: "red", fontSize: 18 }}>
            ✗{" "}
          </span>
          Do not reuse cheques. If an invalid payment proof is found, the unit
          reservation and booking maybe cancelled.
        </p>
      </div>
      <div className="my-3">
        <h2 className="at-project-unit__reservation_title mb-0">
          Payment Slip
        </h2>
        {paymentSlip.payment_slip_file_name && (
          <label style={{ float: "left" }}>
            Filename: {paymentSlip.payment_slip_file_name}
          </label>
        )}
        <button
          className="btn-new btn-new--secondary w-100"
          onClick={() => filePond.click()}
        >
          Add File
        </button>
        <div style={{ display: "none" }}>
          <input
            ref={(ref) => (filePond = ref)}
            type="file"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            multiple={false}
            onChange={(e) => onUploadDocs(e)}
          />
        </div>
      </div>
      {renderInputFooter()}
    </div>
  );
};

export default Payment;
