import React, { useState, useEffect, useRef } from "react";
import Moment from "moment";
import ReactHTMLParser from "react-html-parser";

import CKEditor from "components/CKEditor";
import AtlasButton from "components/Button";

const ReaMessage = ({
  message,
  selectedRenewal,
  selectedApplication,

  getMessage,
  putMessage,
}) => {
  let messagesEnd = useRef(null);

  const [inputMessage, setInputMessage] = useState("");

  const scrollToBottom = () =>
    messagesEnd.scrollIntoView({ block: "nearest", inline: "start" });

  const onClickSendMessage = () =>
    Promise.all([
      putMessage({
        id: selectedApplication.id,
        message: {
          content: inputMessage,
        },
      }),
    ]).then(() => {
      this.setState({ inputMessage: "" });
    });

  useEffect(() => {
    getMessage(selectedApplication.id);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  return (
    <>
      <div className="chat-feed-container">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message &&
            message.length > 0 &&
            message.map((item) => {
              if (!item.from_admin) {
                return (
                  <div>
                    <div
                      style={{
                        background: "#F2F0F0",
                        borderRadius: "20px",
                        margin: "1px auto",
                        maxWidth: "425px",
                        padding: "8px 14px",
                        width: "-webkit-fit-content",
                        float: "left",
                      }}
                    >
                      <p
                        className={"at-message__text-content"}
                        style={{ color: "black" }}
                      >
                        {ReactHTMLParser(item.content)}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#383535",
                          marginTop: "10px",
                          marginLeft: "auto",
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        {`${item.sender_name} - ${Moment(item.created_at).format("DD MMM YYYY HH:mm").toString()}`}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        background: "rgb(0,132,255)",
                        borderRadius: "20px",
                        margin: "1px auto",
                        maxWidth: "425px",
                        padding: "8px 14px",
                        width: "-webkit-fit-content",
                        float: "right",
                      }}
                    >
                      <div>
                        <p
                          className={"at-message__text-content"}
                          style={{ color: "white" }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginTop: "10px",
                            marginLeft: "auto",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {`${item.sender_name} - ${Moment(item.created_at).format("DD MMM YYYY HH:mm").toString()}`}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            messagesEnd = el;
          }}
        ></div>
      </div>
      <div className="at-subsales_claims-message_editor mb-4">
        <CKEditor
          data={inputMessage}
          onChange={(event, editor) => {
            const data = editor.getData();
            setInputMessage(data);
          }}
        />
      </div>
      <AtlasButton
        className="btn-new btn-new--success mt-3"
        children={"Send"}
        onClick={() => onClickSendMessage()}
      />
    </>
  );
};

export default ReaMessage;
