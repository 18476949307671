import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      leadTypes: [],
      countryOptions: [],
    };

    load = (param) => this.setState({ loading: param });

    getCountrySelection = () =>
      Get(
        `/registrations/countries`,
        this.getCountrySelectionSuccess,
        this.getCountrySelectionError,
        this.load,
      );
    getCountrySelectionSuccess = (payload) => {
      let tempOptions = [];
      payload &&
        payload.map((item) => {
          tempOptions.push({
            ...item,
            label: `${item.name} +${item.code}`,
          });
        });
      this.setState({ countryOptions: tempOptions });
    };
    getLeadTypesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            countryOptions={this.state.countryOptions}
            getCountrySelection={this.getCountrySelection}
            getLeadTypes={this.getLeadTypes}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
