import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiFillTag, AiFillHome } from "react-icons/ai";
import { BsTrash3Fill } from "react-icons/bs";
import { FaBuilding, FaBath, FaBed, FaRulerCombined } from "react-icons/fa";
import { IoIosConstruct, IoMdPin } from "react-icons/io";
import { MdDateRange, MdAdd } from "react-icons/md";
import { RiNumbersFill } from "react-icons/ri";
import { BiArea } from "react-icons/bi";
import { IconButton, Tooltip } from "@material-ui/core";

import iqiLogo from "assets/images/iqi_logo_only.png";
import TooltipWrapper from "components/TooltipWrapper";
import { numberWithCommas } from "utils/thousandSeparator";

const specificationBottom = [
  {
    name: "Project Tenure",
    icon: <AiFillTag />,
    value: "tenureType",
  },
  {
    name: "Project Title",
    icon: <AiFillHome />,
    value: "projectTitleType",
  },
  {
    name: "Project Type",
    icon: <FaBuilding />,
    value: "projectType",
  },
];

const specificationMiddle = [
  {
    name: "Developer",
    icon: <IoIosConstruct />,
    value: "developer",
  },
  {
    name: "Land Area",
    icon: <FaRulerCombined />,
    value: "land_area",
  },
  {
    name: "Est. Completion Date",
    icon: <MdDateRange />,
    value: "completion_date",
  },
  {
    name: "Location",
    icon: <IoMdPin />,
    value: "location",
  },
];

const specificationTop = [
  {
    name: "Max. Unit",
    icon: <RiNumbersFill />,
    value: "max_unit",
    unit: "units",
  },
  {
    name: "Built Up Area",
    icon: <BiArea />,
    value: "measurement",
    unit: "sqft",
  },
  {
    name: "No. of Bedrooms",
    icon: <FaBed />,
    value: "bedroom_display",
    unit: "beds",
  },
  {
    name: "No. of Bathrooms",
    icon: <FaBath />,
    value: "bathroom_display",
    unit: "baths",
  },
];

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

class CustomCard extends Component {
  onHandleActionVisibility = (flagProp) => {
    let tempReplace = flagProp.replace(/!/g, "");

    return flagProp.indexOf("!") > -1
      ? !this.props.rowItem[tempReplace]
      : this.props.rowItem[tempReplace];
  };

  renderActionSections = (action) => {
    const {
      rowItem,
      onClickDetails,
      onClickAdd,
      onClickRemove,

      onShowRemoveIcon,
      onShowAddIcon,
    } = this.props;

    switch (action) {
      case "details":
        return (
          <DarkTooltip
            classes={{ tooltip: "tooltip-arrow top" }}
            aria-label={"action-button"}
            placement={"top"}
            title={"See estate details"}
          >
            <IconButton
              classes={{ root: "btn-new--secondary" }}
              onClick={() => onClickDetails(rowItem)}
            >
              <BsInfoCircleFill className={"color-white"} />
            </IconButton>
          </DarkTooltip>
        );

      case "remove": {
        if (this.onHandleActionVisibility(onShowRemoveIcon)) {
          return (
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              aria-label={"action-button"}
              placement={"top"}
              title={"Remove from listing"}
            >
              <IconButton
                classes={{ root: "bg-danger" }}
                onClick={() => onClickRemove(rowItem)}
              >
                <BsTrash3Fill className={"color-white"} />
              </IconButton>
            </DarkTooltip>
          );
        }

        return false;
      }

      case "add": {
        if (this.onHandleActionVisibility(onShowAddIcon)) {
          return (
            <DarkTooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              aria-label={"action-button"}
              placement={"top"}
              title={"Add to listing"}
            >
              <IconButton
                classes={{ root: "btn-new--primary" }}
                onClick={() => onClickAdd(rowItem)}
              >
                <MdAdd className={"color-white"} />
              </IconButton>
            </DarkTooltip>
          );
        }
        return false;
      }

      default:
        break;
    }
  };

  renderSpecification = (item) => {
    const { keyRef } = this.props;

    const processValue = (val) => {
      if (this.props.rowItem[val]) {
        if (
          val === "land_area" ||
          val === "max_unit" ||
          val === "measurement"
        ) {
          return numberWithCommas(this.props.rowItem[val]);
        } else {
          return this.props.rowItem[val];
        }
      } else {
        return "N/A";
      }
    };

    return (
      <TooltipWrapper
        title={<span>{item.name}</span>}
        child={
          <span className={`at-projects__spec-list_item-${item.value}`}>
            {item.icon}
            <span className="at-projects__spec-text">
              {processValue(item.value)}
            </span>
            {item.unit && (
              <span className="at-projects__spec-unit">{item.unit}</span>
            )}
          </span>
        }
      />
    );
  };

  render = () => {
    const {
      rowItem,
      keyRef,

      onClickDetails,
      actionsColumns = [],
    } = this.props;
    const {
      imgSrc,
      title,
      internal_name,
      location,
      max_price_cents,
      net_price_currency,
      net_price_cents,
      available_units,
      total_units,
    } = rowItem;

    return (
      <li className="at-projects__item">
        <div className="at-projects__image">
          <div className="at-projects__img-cont" onClick={onClickDetails}>
            <img
              src={imgSrc}
              alt={`${keyRef}-img`}
              style={{ cursor: "pointer" }}
              onError={(e) => {
                if (e.target) {
                  e.target.src = iqiLogo;
                }
              }}
            />
            {total_units > 0 && (
              <div className="at-projects__available">
                <p>{`${available_units}/${total_units} available`}</p>
              </div>
            )}
          </div>
        </div>
        <div className="at-projects__details-cont">
          <div className="at-projects__info">
            <div className="at-projects__title-cont">
              <h6 className="at-projects__title__text">{title || "N/A"}</h6>
            </div>
            <div className="at-projects__details-cont-top">
              <div className="at-projects__title">{`${internal_name}`}</div>
            </div>
            <div className="at-projects__location">
              <IoMdPin />
              <p>{location || "N/A"}</p>
            </div>
            <h6 className="at-projects__title__price">
              <label>{net_price_currency}</label>
              <span>{`  ${numberWithCommas(net_price_cents)} -  `}</span>
              <label>{net_price_currency}</label>
              <span>{` ${numberWithCommas(max_price_cents)}`}</span>
            </h6>
          </div>
          <div className="at-projects-specs_cont" style={{ marginTop: "auto" }}>
            <div className={`d-flex flex-wrap at-projects-specs_cont-top`}>
              {specificationTop.map((item) => this.renderSpecification(item))}
            </div>
            <div className="at-projects__bottom">
              <div className={`d-flex flex-wrap at-projects-specs_cont-bottom`}>
                {specificationBottom.map((item) =>
                  this.renderSpecification(item),
                )}
              </div>
            </div>
          </div>
          <div className="at-projects__actions">
            {actionsColumns.length > 0 &&
              actionsColumns.map((action) => this.renderActionSections(action))}
          </div>
        </div>
      </li>
    );
  };
}

export default CustomCard;
