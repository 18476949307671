import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";

import { Get, Put, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: true,

      paymentDetails: {},
      myBookingLists: [],
      calendarEvent: [],
      selectedBooking: {},
      disableUpdate: false,
      showPaymentModal: false,
      showPaymentFailedModal: false,
      showBookingDetails: false,
      showConfirmDeleteModal: false,
      showEditMyBookingModal: false,
      showPaymentConfirmation: false,
      showRefundTerms: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeMyBooking = (val, context) => this.setState({ [context]: val });

    componentDidMount = () => {
      this.getMyBookings();
    };

    bigCalendarViewObj = (booking) => {
      let tmp = [];
      booking.map((item) => {
        tmp.push({
          title: item.event_subject,
          start: Moment(item.start_time).toDate(),
          end: Moment(item.end_time).toDate(),
        });
      });
      return tmp;
    };

    getCalendarEvent = (id, date) =>
      Get(
        `/meeting_rooms/${id}?booking_date=${date}`,
        this.getCalendarEventSuccess,
        this.getCalendarEventError,
        this.load,
      );
    getCalendarEventSuccess = (payload) => {
      let tmp = [];
      if (payload.bookings && payload.bookings.length > 0) {
        tmp = this.bigCalendarViewObj(payload.bookings);
      }
      this.setState({ calendarEvent: tmp });
    };
    getCalendarEventError = (error) => requestError(error);

    getMyBookings = () =>
      Get(
        "/bookings",
        this.getMyBookingsSucccess,
        this.getMyBookingsFailed,
        this.load,
      );
    getMyBookingsSucccess = (payload) => {
      let tmp = [];
      if (payload.data && payload.data.length > 0) {
        payload.data.map((item) => {
          tmp.push({
            ...item,
            date: Moment(item.start_time).format("DD MMM YYYY"),
            startTime: Moment(item.start_time).format("h:mmA"),
            endTime: Moment(item.end_time).format("h:mmA"),
          });
        });
        tmp.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
      }
      this.setState({ myBookingLists: tmp });
    };
    getMyBookingsFailed = (error) => requestError(error);

    getSelectedBooking = (id) => 
      Get(
        `/bookings/${id}`,
        this.getSelectedBookingSuccess,
        this.getSelectedBookingError,
        this.load,
      );
    getSelectedBookingSuccess = (payload) => this.setState({
      selectedBooking: payload,
      showBookingDetails: true,
    });
    getSelectedBookingError = (error) => requestError(error);

    deleteMyBooking = ({ id }) => {
      Put(
        `/bookings/${id}`,
        { status_id: 4 },
        this.deleteMyBookingSuccess,
        this.deleteMyBookingError,
        this.load,
      );
    };
    deleteMyBookingSuccess = (payload) => {
      this.getMyBookings();
      this.setState({ showConfirmDeleteModal: false });
      requestSuccess("Booking has been deleteted successfully.");
    };
    deleteMyBookingError = (error) => requestError(error);

    updateMyBooking = ({
      id,
      event_subject,
      start_time,
      end_time,
      duration,
    }) => {
      Put(
        `/bookings/${id}`,
        {
          event_subject: event_subject,
          start_time: start_time,
          end_time: end_time,
          duration: duration,
        },
        this.updateMyBookingSuccess,
        this.updateMyBookingError,
        this.load,
      );
    };
    updateMyBookingSuccess = (payload) => {
      this.setState({ showEditMyBookingModal: false });
      this.getMyBookings();
      requestSuccess("Booking has been updated successfully.");
    };
    updateMyBookingError = (error) => requestError(error);

    makePayment = (data) =>
      Post(
        `/payments`,
        data,
        this.makePaymentSuccess,
        this.makePaymentError,
        this.load,
      );
    makePaymentSuccess = (payload) => {
      this.setState({
        paymentDetails: {
          ...this.state.paymentDetails,
          ...payload,
        },
        showPaymentModal: true,
        showPaymentConfirmation: false
      });
    };
    makePaymentError = (error) => requestError(error);

    cancelPayment = () =>
      Delete(
        `/payments/${this.state.paymentDetails.payment_id}`,
        this.cancelPaymentSuccess,
        this.cancelPaymentError,
        this.load,
      );
    cancelPaymentSuccess = () => this.setState({ showPaymentModal: false, showPaymentFailedModal: true });
    cancelPaymentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedBooking={this.state.selectedBooking}
            myBookingLists={this.state.myBookingLists}
            calendarEvent={this.state.calendarEvent}
            onLoadBooking={this.state.loading}
            disableUpdate={this.state.disableUpdate}
            paymentDetails={this.state.paymentDetails}
            showPaymentModal={this.state.showPaymentModal}
            showBookingDetails={this.state.showBookingDetails}
            showEditMyBookingModal={this.state.showEditMyBookingModal}
            showConfirmDeleteModal={this.state.showConfirmDeleteModal}
            showPaymentConfirmation={this.state.showPaymentConfirmation}
            showRefundTerms={this.state.showRefundTerms}
            showPaymentFailedModal={this.state.showPaymentFailedModal}
            getCalendarEvent={this.getCalendarEvent}
            getMyBookings={this.getMyBookings}
            makePayment={this.makePayment}
            cancelPayment={this.cancelPayment}
            deleteMyBooking={this.deleteMyBooking}
            updateMyBooking={this.updateMyBooking}
            getSelectedBooking={this.getSelectedBooking}
            onChangeMyBooking={this.onChangeMyBooking}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
