import Axios from "axios";

import { GET_DATA_DICTIONARY, GET_COUNTRY_DICTIONARY } from "./type";
import {
  beginAjaxCallBackground,
  ajaxCallErrorBackground,
  ajaxCallSuccessBackground,
} from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export function getAllDictionaryData() {
  return (dispatch, getState) => {
    dispatch(beginAjaxCallBackground(true));
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const token = getItem(tokenName);
    Axios.defaults.headers = {
      common: {
        Authorization: `JWT ${token}`,
      },
      "Access-Control-Allow-Origin": "*",
    };
    Axios.get(`${getDomainURL()}/data_dictionary`)
      .then((response) => {
        dispatch(getAllDictionaryDataSuccess(response.data));
        dispatch(ajaxCallSuccessBackground());
      })
      .catch((error) => {
        dispatch(ajaxCallErrorBackground(error));
      });
  };
}

function getAllDictionaryDataSuccess(payload) {
  return {
    type: GET_DATA_DICTIONARY,
    payload,
  };
}

export function getCountryDictionary() {
  return (dispatch, getState) => {
    dispatch(beginAjaxCallBackground(true));
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const token = getItem(tokenName);
    Axios.defaults.headers = {
      common: {
        Authorization: `JWT ${token}`,
      },
      "Access-Control-Allow-Origin": "*",
    };
    Axios.get(`${getDomainURL()}/countries`)
      .then((response) => {
        dispatch(getCountryDictionarySuccess(response.data));
        dispatch(ajaxCallSuccessBackground());
      })
      .catch((error) => {
        dispatch(ajaxCallErrorBackground(error));
      });
  };
}

function getCountryDictionarySuccess(payload) {
  return {
    type: GET_COUNTRY_DICTIONARY,
    payload,
  };
}
