import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import isEmptyValue from "utils/isEmpty";
import defaultImage from "assets/images/iqi_logo_only.png";

import "./content.scss";

const statusDictionary = [
  { id: 0, label: "Pending" },
  { id: 1, label: "Processing" },
  { id: 2, label: "Arrived" },
  { id: 3, label: "Collected" },
  { id: 4, label: "Rejected" },
];

class CustomCard extends Component {
  render() {
    const {
      key,
      quantity,
      price,
      status_id,
      selected_market_product_attached_product: product,
      selected_market_product_attached_product_type: product_type,
    } = this.props.data;

    const { name, type_id, photo_url, description } =
      this.props.data.market_product;

    const {
      name_card_display_name,
      mobile_number,
      additional_display_name,
      designation,
      license_number,
      qualification,
      include_profile_photo,
      t_shirt_gender,
      t_shirt_size,
      updated_at,
    } = this.props.data.payload || {};

    let tempStatus = _.find(statusDictionary, { id: status_id });
    const tmpTicketTitle =
      !isEmptyValue(product) && product_type === "TicketPricing"
        ? ` (${product.title})`
        : "";
    const tmpVariantTitle =
      !isEmptyValue(product) && product.options
        ? product.options.map(
            (option, index) =>
              `${option.value}${index + 1 !== product.options.length ? " - " : ""}`,
          )
        : "";

    return (
      <li className={`at-namecard-mngmt__item`} key={key}>
        <div className="at-namecard-mngmt__title-cont">
          <div
            className="d-flex"
            style={{ minWidth: "100%", justifyContent: "space-between" }}
          >
            <div className="at-namecard-mngmt-title">
              {`${name} `}
              <span
                style={{ color: "#F18E05", fontSize: 18 }}
              >{` x ${quantity}`}</span>
              <p style={{ fontSize: 14, fontWeight: 500, color: "#9CA3AF" }}>
                {tmpTicketTitle}
                {tmpVariantTitle}
              </p>
            </div>
            <div
              style={{
                marginTop: 5,
                fontSize: 14,
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >{`RM ${price.toFixed(2)}`}</div>
          </div>
        </div>
        <div className="at-namecard-mngmt__details-cont at-shadow_box-sm at-namecard-mngmt__details-bg-cont color-black bd-rd-5">
          <div className="row no-gutters px-2">
            <div className="at-namecard-mngmt__image col-sm-12 col-md-4 col-lg-3">
              <img
                className="at-namecard-mngmt__img-cont bg-white bd-rd-5 border-white justify-content-center"
                style={{ objectFit: "cover" }}
                src={photo_url || defaultImage}
              />
            </div>
            <div className="at-namecard-mngmt-table-content col-sm-12 col-md-8 col-lg-9">
              <p className={"fw-400"}>{description}</p>
              <hr />
              {(type_id === 1 || type_id === 2) && (
                <>
                  <div className="row">
                    <div className={"col-sm-12 col-md-12 col-lg-6"}>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Name on card:
                        </span>
                      </p>
                      <p style={{ marginBottom: 15 }}>
                        {`${name_card_display_name || "N/A"}`}{" "}
                      </p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Name in local language:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${additional_display_name || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Additional Mobile Number:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${mobile_number || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Designation:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${designation || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Title of Academy Achievement:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${qualification || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Include profile photo:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${include_profile_photo ? "Yes" : "No"}`}</p>
                    </div>
                    <div className={"col-md-12 col-lg-6"}>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          T-shirt Gender:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${t_shirt_gender || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          T-shirt Size:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${t_shirt_size || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          License Number:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${license_number || "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Status:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${tempStatus ? tempStatus.label : "N/A"}`}</p>
                      <p style={{ marginBottom: 5 }}>
                        <span className="at-namecard-mngmt-list-content_title">
                          Redeem Date:
                        </span>
                      </p>
                      <p
                        style={{ marginBottom: 15 }}
                      >{` ${updated_at ? Moment(updated_at).format("DD MMM YYYY") : "N/A"}`}</p>
                    </div>
                  </div>
                  {include_profile_photo && (
                    <a
                      onClick={() => this.props.onClickViewNameCard()}
                      style={{
                        color: "#F18E05",
                        display: "block",
                        fontSize: 12,
                        marginTop: 15,
                        textDecoration: "underline",
                      }}
                    >
                      Click here to view namecard photo.
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default CustomCard;
