import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import Menu from "./containers/Menu";
import ModuleHeader from "components/ModuleHeader";
import AppointmentLetter from "./containers/AppointmentLetter/index";
import ConfirmationForm from "./containers/Confirmation";
import RequestConfirmationForm from "./containers/ConfirmationRequest";
import CobrokeLetter from "./containers/CobrokeLetter";
import PaperForm from "./containers/PaperForm";
import AuthorisationForm from "./containers/AuthorisationForm/index";

import "stylesheets/containers/personal/appointment-letter.scss";

class Forms extends Component {
  renderCardContent = () => {
    return (
      <div className="at-card-container">
        <Switch>
          <Route path={"/dashboard/personal/forms/menu"} component={Menu} />
          <Route
            path={"/dashboard/personal/forms/appointment-letter"}
            component={AppointmentLetter}
          />
          <Route
            path={"/dashboard/personal/forms/confirmation-form"}
            component={ConfirmationForm}
          />
          <Route
            path={"/dashboard/personal/forms/confirmation-form-request"}
            component={RequestConfirmationForm}
          />
          <Route
            path={"/dashboard/personal/forms/cobroke-letter"}
            component={CobrokeLetter}
          />
          <Route
            path={"/dashboard/personal/forms/paper-form"}
            component={PaperForm}
          />
          <Route
            path={"/dashboard/personal/forms/authorisation-form"}
            component={AuthorisationForm}
          />

          <Route
            path={"/admin-impersonate/:profileId/dashboard/personal/forms/menu"}
            component={Menu}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/appointment-letter"
            }
            component={AppointmentLetter}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/confirmation-form"
            }
            component={ConfirmationForm}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/confirmation-form-request"
            }
            component={RequestConfirmationForm}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/cobroke-letter"
            }
            component={CobrokeLetter}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/paper-form"
            }
            component={PaperForm}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/dashboard/personal/forms/authorisation-form"
            }
            component={AuthorisationForm}
          />
        </Switch>
      </div>
    );
  };

  render = () => {
    const is_menu = window.location.href.includes("menu");

    return (
      <>
        {is_menu && (
          <ModuleHeader title={"My Forms"} moduleIcon={"icon-forms"} />
        )}
        {this.renderCardContent()}
      </>
    );
  };
}

export default withRouter(Forms);
