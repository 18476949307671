import { useState } from "react";

import Tab from "components/Tab";
import ModalDialog from "components/Modal/ModalDialog";
import Design from "./Design";
import Setup from "./Setup";

const Update = ({
  categories,
  onLoadEmailTemplates,
  selectedEmailTemplate,

  updateEmailTemplate,
  onChangeEmailTemplatesHOC,
}) => {
  const [selectedSection, setSelectedSection] = useState('Setup');

  return (
    <ModalDialog
      title={"Edit Email Template"}
      onLoad={onLoadEmailTemplates}
      loaderContainerClass={"at-email-template__loader"}
      onClose={() => onChangeEmailTemplatesHOC("showUpdateModal", false)}
      fullWidth={true}
      fillHeight={true}
      children={
        <>
          <Tab
            sections={["Setup", "Design"]}
            selectedSection={selectedSection}
            onSelectSection={setSelectedSection}
          />
          {selectedSection === 'Setup' && (
            <Setup
              categories={categories}
              selectedEmailTemplate={selectedEmailTemplate}

              updateEmailTemplate={updateEmailTemplate}
              onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
            />
          )}
          {selectedSection === 'Design' && (
            <Design
              selectedEmailTemplate={selectedEmailTemplate}

              updateEmailTemplate={updateEmailTemplate}
              onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
            />
          )}
        </>
      } />
  );
};

export default Update;
