import React from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";

import { getColorBadge } from "dictionary/badgeColor";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

export const ApprovalStatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Rejected", colorName: "Red" },
  { value: "Approved", colorName: "Green" },
  { value: "Referrer Transfer", colorName: "Yellow" },
];

export const StatusColor = [
  { value: "Approved", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "YES", colorName: "Green" },
  { value: "NO", colorName: "Red" },
];

export const ColumnData = ({
  can_update,
  checkedArray,
  selectedStatus,

  onHandleCheckBox,
  onClickSelectAll,
}) => [
  ...(selectedStatus === 1
    ? [
        {
          hideFromSearch: true,
          hideSort: true,
          header: (currentPageData) => {
            let checkableData = [];
            currentPageData.map((item) => {
              if (item.status !== "Approved") {
                checkableData.push(item);
              }
            });

            return (
              <span className="at-table-head-checkbox_responsive">
                <DarkTooltip
                  classes={{ tooltip: "tooltip-arrow left" }}
                  placement={"right"}
                  enterTouchDelay={0}
                  title={
                    checkedArray.length === checkableData.length &&
                    checkableData.length !== 0
                      ? "Deselect All"
                      : "Select All"
                  }
                >
                  <CustomCheckbox
                    disabled={checkableData.length === 0 && !can_update}
                    checked={
                      checkedArray.length === checkableData.length &&
                      checkableData.length !== 0
                    }
                    onChangeCheckboxValue={() =>
                      onClickSelectAll(checkableData)
                    }
                  />
                </DarkTooltip>
              </span>
            );
          },
          renderColumn: (rowData) => (
            <>
              {rowData.status !== "Approved" && (
                <CustomCheckbox
                  disabled={!can_update}
                  checked={_.includes(checkedArray, rowData.id)}
                  onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
                />
              )}
            </>
          ),
          columnClass: "at-table--checkbox-column",
          columnStyle: {
            width: "10%",
            gridColumn: "1/-1",
            backgroundColor: "unset",
          },
        },
      ]
    : []),
  {
    header: "Agent",
    searchFlag: "agent",
    columnStyle: { width: "140%" },
    columnClass: "at-table-head__hide-mobile",
    renderColumn: (rowData) => (
      <>
        {rowData.status !== "Approved" && (
          <CustomCheckbox
            disabled={!can_update}
            checked={_.includes(checkedArray, rowData.id)}
            labelClassname={"at-table--checkbox-mobile"}
            onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
          />
        )}
        <UserInfo
          name={rowData.full_name || "N/A"}
          team_name={rowData.team_name || "N/A"}
          email={rowData.email || "N/A"}
          phone_number={rowData.mobile_contact_number || "N/A"}
        />
      </>
    ),
  },
  {
    header: "Job Title",
    searchFlag: "job_title",
    accessor: "job_title",
    columnStyle: { width: "80%" },
  },
  {
    header: "Date",
    searchFlag: "created_at",
    accessor: "created_at",
    columnStyle: { width: "80%" },
  },
  {
    header: "Profile Status",
    searchFlag: "agent_completed_profile",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const colorName =
        rowData.agent_completed_profile === "YES" ? "Green" : "Red";

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.agent_completed_profile === "YES" ? (
            <AtlasIcon
              svgHref={"atlas-tick-circle"}
              style={{ width: 18, height: 18, marginRight: 4 }}
            />
          ) : (
            <AtlasIcon
              svgHref={"atlas-close-circle"}
              style={{ width: 18, height: 18, marginRight: 4 }}
            />
          )}
          {rowData.agent_completed_profile === "YES"
            ? "Complete"
            : "Incomplete"}
        </div>
      );
    },
  },
  {
    header: "Assigned Admin",
    searchFlag: "assignee_full_name",
    accessor: "assignee_full_name",
  },
  {
    header: "Admin Status",
    searchFlag: "status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(ApprovalStatusColor, { value: rowData.assignee_side_status }) ||
        {};

      return (
        <>
          <div
            className="at-status_badge mt-1"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData.assignee_side_status}
          </div>
          {rowData.remark && (
            <div
              style={{
                backgroundColor: "#1F2937",
                borderRight: "2px solid #F04438",
              }}
            >
              {rowData.remark}
            </div>
          )}
        </>
      );
    },
  },
  {
    header: "Status",
    searchFlag: "status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];
