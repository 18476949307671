import React from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CustomCheckbox from "components/Checkbox";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

export const ColumnData = ({
  selectedSessions,
  selectAllSessionIdLength,

  onClickSelectAll,
  onChangeCheckBox,
}) => [
  {
    header: () => (
      <span className="at-table-head-checkbox_responsive">
        <DarkTooltip
          classes={{ tooltip: "tooltip-arrow left" }}
          placement={"right"}
          enterTouchDelay={0}
          title={
            selectedSessions.length > 0 &&
            selectedSessions.length == selectAllSessionIdLength
              ? "Deselect All"
              : "Select All"
          }
        >
          <CustomCheckbox
            disabled={selectAllSessionIdLength === 0}
            checked={
              selectedSessions.length > 0 &&
              selectedSessions.length == selectAllSessionIdLength
            }
            onChangeCheckboxValue={() => onClickSelectAll()}
          />
        </DarkTooltip>
      </span>
    ),
    renderColumn: (rowData) => (
      <CustomCheckbox
        checked={_.includes(selectedSessions, rowData.id)}
        onChangeCheckboxValue={() => onChangeCheckBox(rowData.id)}
      />
    ),
    columnClass: "at-table--checkbox-column",
    columnStyle: { width: "10%", gridColumn: "1/-1" },
  },
  {
    header: "User",
    renderColumn: (rowData) => (
      <>
        <CustomCheckbox
          labelClassname={"at-table--checkbox-mobile"}
          checked={_.includes(selectedSessions, rowData.id)}
          onChangeCheckboxValue={() => onChangeCheckBox(rowData.id)}
        />
        <UserInfo
          name={rowData.user || "N/A"}
          email={rowData.email || "N/A"}
          hideRedirect={true}
        />
      </>
    ),
    columnStyle: { width: "120%" },
  },
  {
    header: "Location",
    renderColumn: (rowData) => (
      <>
        <p>{rowData.ip_address}</p>
        <p>{rowData.location}</p>
      </>
    ),
  },
  {
    header: "Platform Details",
    renderColumn: (rowData) => (
      <>
        <p>{rowData.browser_detail}</p>
        <p>{rowData.platform_detail}</p>
      </>
    ),
    columnStyle: { width: "70%" },
  },
  { header: "Origin", accessor: "origin" },
  {
    header: "Created At",
    renderColumn: (rowData) => (
      <p>
        {rowData.created_at
          ? Moment(rowData.created_at).format("DD MMM YYYY HH:mm")
          : "N/A"}
      </p>
    ),
  },
];
