import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      invoicing_branch_id: null,
      type_id: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCreateConfirmationHOC = (val, context) =>
      this.setState({ [context]: val });

    createConfirmationForm = (dataToSubmit) =>
      Post(
        `/forms/confirmation_forms`,
        dataToSubmit,
        this.createConfirmationFormSuccess,
        this.createConfirmationFormError,
        this.load,
      );
    createConfirmationFormSuccess = (payload) => {
      this.props.onChangeConfirmationHOC(false, "showCreationForm");
      this.props.onChangeConfirmationHOC(false, "disableSubmitButton");
      this.props.getConfirmationForms(1, "");
      this.props.getConfirmationForm(payload.id);
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
    };
    createConfirmationFormError = (error) => {
      this.props.onChangeConfirmationHOC(false, "disableSubmitButton");
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadCreateConfirmation={this.state.loading}
            invoicing_branch_id={this.state.invoicing_branch_id}
            type_id={this.state.type_id}
            onChangeCreateConfirmationHOC={this.onChangeCreateConfirmationHOC}
            createConfirmationForm={this.createConfirmationForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
