import React, { Component } from "react";
import { compose } from "redux";
import { AiOutlineExport } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateAttendanceContent from "./components/Create";
import ExportFile from "./components/ExportAttendance";
import EditAttendanceContent from "./components/Edit";

import TrainingHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Present", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Reset", colorName: "Yellow" },
  { value: "Absent", colorName: "Red" },
];

const columnData = [
  {
    header: "Date",
    accessor: "training_start_date_time",
    columnStyle: { width: "60%" },
  },
  {
    header: "Training Type",
    accessor: "event_training_type",
    columnStyle: { width: "80%" },
  },
  {
    header: "Agent",
    renderColumn: (rowData) => {
      const { full_name, email, mobile_contact_number, team } = rowData.agent;
      return (
        <>
          <p>{full_name || "N/A"}</p>
          <p>{email || "N/A"}</p>
          <p>{mobile_contact_number || "N/A"}</p>
          <p>{team || "N/A"}</p>
        </>
      );
    },
  },
  {
    header: "Join Duration",
    accessor: "join_duration_minute",
    columnStyle: { width: "70%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyling: { width: "50%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.attendance_status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.attendance_status}
        </div>
      );
    },
  },
];

class TrainingMngmt extends Component {
  componentDidMount = () => {
    Promise.all([this.props.getBranches()]).then(() => {
      this.props.getTrainings(1, "");
    });
  };

  renderCardContent = () => {
    const {
      data,
      branches,
      trainings,
      searchParams,
      trainingsPages,
      deleteTraining,
      onLoadTrainings,
      showDeleteConfirmation,
    } = this.props;
    const { can_update, can_destroy } = permissionsChecker(
      "Training Attendances",
      data,
    );

    return (
      <>
        <AtPaginatedTable
          rowData={trainings.data ? trainings.data : []}
          meta={trainings.meta && trainings.meta}
          columns={columnData}
          actionColumnContent={
            can_update || can_destroy
              ? [
                  {
                    name: "edit",
                    onShow: () => can_update,
                    onClick: (rowData) => {
                      this.props.onChangeTrainingHOC(
                        rowData,
                        "selectedAttendance",
                      );
                      this.props.onChangeTrainingHOC(true, "showEditDialog");
                    },
                  },
                  {
                    name: "delete",
                    color: "#F04438",
                    onShow: () => can_destroy,
                    onClick: (rowData) => {
                      this.props.onChangeTrainingHOC(
                        true,
                        "showDeleteConfirmation",
                      );
                      this.props.onChangeTrainingHOC(
                        rowData,
                        "selectedAttendance",
                      );
                    },
                  },
                ]
              : []
          }
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeTrainingHOC(val, "searchParams")
          }
          getListAPI={this.props.getTrainings}
          totalPages={trainingsPages}
        />

        <AtlasDialog open={this.props.showCreateDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <CreateAttendanceContent
                onLoadTrainings={onLoadTrainings}
                createAttendance={this.props.createAttendance}
                onCloseModal={() =>
                  this.props.onChangeTrainingHOC(false, "showCreateDialog")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={this.props.showEditDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <EditAttendanceContent
                onLoadTrainings={onLoadTrainings}
                selectedAttendance={this.props.selectedAttendance}
                updateAttendance={this.props.updateAttendance}
                onCloseModal={() =>
                  this.props.onChangeTrainingHOC(false, "showEditDialog")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={this.props.showExportDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <ExportFile
                branches={branches}
                onCloseModal={() =>
                  this.props.onChangeTrainingHOC(false, "showExportDialog")
                }
              />
            }
          />
        </AtlasDialog>
        <ConfirmationModal
          title={"Delete Training Record"}
          loading={onLoadTrainings}
          open={showDeleteConfirmation}
          message={`Are you sure you want to delete this training record ?`}
          positiveAction={() =>
            deleteTraining(this.props.selectedAttendance.id)
          }
          negativeAction={() =>
            this.props.onChangeTrainingHOC(false, "showDeleteConfirmation")
          }
        />
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker(
      "Training Attendances",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={"Training Attendances"}
          moduleIcon={"icon-training"}
          actionButton={[
            {
              label: "New Attendance",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeTrainingHOC(true, "showCreateDialog"),
            },
            {
              label: "Export",
              onShow: true,
              className: "btn-new btn-new--secondary",
              icon: <AiOutlineExport style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeTrainingHOC(true, "showExportDialog"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadTrainings && <LoadingModal />}
      </>
    );
  };
}

export default compose(TrainingHOC, VersionCheckHOC)(TrainingMngmt);
