import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";

const searchParams = [
  {
    label: "Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Published",
    value: "published_eq",
    type: "radio",
    param: "",
    options: [
      {
        id: 0,
        value: "",
        label: "All",
      },
      {
        id: 1,
        value: "true",
        label: "Yes",
      },
      {
        id: 2,
        value: "false",
        label: "No",
      },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newsFeed: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedNewsfeed: {},

      showCreateNewsfeed: false,
      showUpdateNewsfeed: false,
      showPreviewModal: false,
      searchParams: searchParams,
      newsFeedPages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeNewsFeedHOC = (val, key) => this.setState({ [key]: val });

    getNewsfeeds = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/newsfeeds?${search}page=${page}`,
        this.getNewsfeedsSuccess,
        this.getNewsfeedsError,
        this.load,
      );
    };
    getNewsfeedsSuccess = (payload) => {
      let tempPages = [];
      let tempData = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      payload.data &&
        payload.data.map((item) => {
          let tempVisible = "";
          if (item.visible_to_agent && item.visible_to_associated_agent)
            tempVisible = "Agent, Associated Agent";
          else if (item.visible_to_agent) tempVisible = "Agent";
          else if (item.visible_to_associated_agent)
            tempVisible = "Associated Agent";

          tempData.push({
            ...item,
            isPublished: item.published ? "Yes" : "No",
            visible_to_agent_role: tempVisible,
            publish_at: item.publish_at
              ? Moment(item.publish_at, "YYYY-MM-DD").format("DD MMM YYYY")
              : "N/A",
          });
        });

      this.setState({
        newsFeed: {
          data: tempData,
          meta: payload.meta,
        },
        newsFeedPages: tempPages,
      });
    };
    getNewsfeedsError = (error) => requestError(error);

    getSelectedNewsfeed = (id) =>
      Get(
        `/admin/newsfeeds/${id}`,
        this.getSelectedNewsfeedSuccess,
        this.getSelectedNewsfeedError,
        this.load,
      );
    getSelectedNewsfeedSuccess = (payload) =>
      this.setState({
        selectedNewsfeed: {
          ...payload,
          published: payload.published ? "published" : "draft",
        },
        showUpdateNewsfeed: true,
      });
    getSelectedNewsfeedError = (error) => requestError(error);

    createNewsfeed = (data) =>
      Post(
        `/admin/newsfeeds`,
        data,
        this.createNewsfeedSuccess,
        this.createNewsfeedError,
        this.load,
      );
    createNewsfeedSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getNewsfeeds(currentPage, searchParams);
      this.getSelectedNewsfeed(payload.id);

      requestSuccess("New newsfeed created successfully.");
      this.setState({ showCreateNewsfeed: false });
    };
    createNewsfeedError = (error) => requestError(error);

    updateNewsfeed = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      if (!temp.header_image || temp.header_image.indexOf("https://") > -1) {
        delete temp.header_image_file_name;
        delete temp.header_image;
      }

      Put(
        `/admin/newsfeeds/${dataToSubmit.id}`,
        temp,
        this.updateNewsfeedSuccess,
        this.updateNewsfeedError,
        this.load,
      );
    };
    updateNewsfeedSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getNewsfeeds(currentPage, searchParams);

      requestSuccess("Newsfeed is updated successfully.");
    };
    updateNewsfeedError = (error) => requestError(error);

    render = () => (
      <WrappedComponent
        {...this.props}
        newsFeed={this.state.newsFeed}
        newsFeedPages={this.state.newsFeedPages}
        searchParams={this.state.searchParams}
        selectedNewsfeed={this.state.selectedNewsfeed}
        onLoadNewsfeedManagement={this.state.loading}
        showCreateNewsfeed={this.state.showCreateNewsfeed}
        showUpdateNewsfeed={this.state.showUpdateNewsfeed}
        showPreviewModal={this.state.showPreviewModal}
        onChangeNewsFeedHOC={this.onChangeNewsFeedHOC}
        getNewsfeeds={this.getNewsfeeds}
        getSelectedNewsfeed={this.getSelectedNewsfeed}
        createNewsfeed={this.createNewsfeed}
        updateNewsfeed={this.updateNewsfeed}
      />
    );
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
