import React, { Component } from "react";
import _ from "lodash";
import { Put } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    updateCartItem = (dataToSubmit) =>
      Put(
        `/market_carts/${dataToSubmit.id}`,
        dataToSubmit,
        () => this.updateCartItemSuccess(dataToSubmit),
        this.updateCartItemError,
        () => {},
      );
    updateCartItemSuccess = (data) => {
      let temp = _.cloneDeep(this.props.cartItems);
      let index = _.findIndex(temp, { id: data.id });
      temp[index].quantity = data.quantity;

      this.props.updateCartItemQuantity(temp);
    };
    updateCartItemError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            updateCartItem={this.updateCartItem}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
