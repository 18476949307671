import React, { Component } from "react";
import _ from "lodash";

import { Get, Put } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      documentation: [],

      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeUploadHOC = (value, context) => this.setState({ [context]: value });

    getProofImage = (id) =>
      Get(
        `/admin/registrations/${id}/documentation/view`,
        (payload) => this.getProofImageSuccess(payload),
        this.getProofImageFailed,
        this.load,
      );
    getProofImageSuccess = (payload) => {
      this.setState({
        documentation: {
          payment_proof: payload.payment_proof_url,
          resignation_letter: payload.resignation_letter_url,
          avatar: payload.ren_picture_url,
          ic_back: payload.ic_back_url,
          ic_front: payload.ic_front_url,
          bank_account_statement: payload.bank_account_statement_url,
          ssm_copy: payload.ssm_copy_url,
          education_certificate: payload.education_certificate_url,
        },
      });
    };
    getProofImageFailed = (error) => requestError(error);

    onUploadImage = (id, dataToSubmit) => {
      let tempSubmit = {};
      _.keys(dataToSubmit).map((item) => {
        if (dataToSubmit[item] && typeof dataToSubmit[item] === "object") {
          tempSubmit[item] = dataToSubmit[item].document;
          tempSubmit[`${item}_file_name`] =
            dataToSubmit[item].document_file_name;
        }
      });

      Put(
        `/admin/registrations/${id}/documentation/upload`,
        tempSubmit,
        (payload) => this.onUploadImageSuccess(payload),
        this.onUploadImageFailed,
        this.load,
      );
    };
    onUploadImageSuccess = () => {
      this.getProofImage(this.props.detailData.agent_registration_id);
      requestSuccess("The image is uploaded successfully!");
    };
    onUploadImageFailed = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          documentation={this.state.documentation}
          onLoadUpload={this.state.loading}
          onChangeUploadHOC={this.onChangeUploadHOC}
          getProofImage={this.getProofImage}
          onUploadImage={this.onUploadImage}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
