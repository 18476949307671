import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import Moment from "moment";

import ModalDialog from "components/Modal/ModalDialog";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import FormChart from "components/Chart/form";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasIcon from "components/Icon/atlasIcon";

import AgentSearchHOC from "actions/agentSearch";
import isEmptyValue from "utils/isEmpty";

import { Status, Type } from "./options";

const RepresentativeInfo = type_id => [
  {
    id: 1,
    label: `Represent ${ type_id === "1" ? 'Landlord' : 'Vendor' }`,
    value: 1,
  },
  {
    id: 2,
    label: `Represent ${ type_id === "1" ? 'Tenant' : 'Purchaser' }`,
    value: 2,
  },
];

const Update = ({
  history,
  data,
  showSelectForm,
  showSubmitConfirmation,
  showDetachConfirmation,
  agentList,
  permissionsChecker,
  selectedPaperForm,
  onLoadPaperForms,
  onLoadAgent,
  onLoadForms,

  onChangeAgentHOC,
  onChangePaperFormHOC,
  updatePaperForm,
  detachForm,
  getAgentsBySearch,
  submitForm,
  onClose,
}) => {
  const [toSubmitData, onChangeSubmitData] = useState({});

  const [updateForm, onChangeUpdateForm] = useState({
    user: null,
    region_id: "",
    tracking_number: "",
    type_id: "",
    status_id: "1",
    remark: "",
    date_taken: "",
    form_type_id: "1",
    agency_id: "",
    forms_chart: [],
  });
  const { can_update } = permissionsChecker;

  const onChangeUpdateField = (key, val) => {
    let tmp = _.cloneDeep(updateForm);
    tmp[key] = val;

    onChangeUpdateForm(tmp);
  };

  useEffect(() => {
    onChangeUpdateForm(selectedPaperForm);
  }, []);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const renderSubmitFormModal = () => {
    if (showSelectForm) {
      return (
        <ModalDialog
          title={"Submit Physical Form"}
          fullHeight={false}
          onClose={() => onChangePaperFormHOC("showSelectForm", false)}
          children={
            <>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Representative"}</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={toSubmitData.representative_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) => {
                    onChangeSubmitData({
                      ...toSubmitData,
                      representative_id: val,
                    });
                  }}
                  options={RepresentativeInfo(selectedPaperForm.type_id)}
                />
              </section>
              <button
                disabled={!toSubmitData.representative_id}
                className="btn-new btn-new--primary mt-3"
                onClick={() => {
                  onChangePaperFormHOC("showSubmitConfirmation", true);
                }}
              >
                Submit
              </button>
            </>
          }
        />
      );
    }
  };

  const tmpTrackingNumber = `${!isEmptyValue(updateForm.tracking_number) ? `/ ${updateForm.tracking_number}` : ""}`;
  const { associatedForms = [], associatedTransNumber = [] } = toSubmitData;

  const { id, user_id } = selectedPaperForm;
  const { sideMenuExpand } = data.sideMenuReducer
  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h4 className="at-card__title">{`Edit Form ${tmpTrackingNumber}`}</h4>
      </div>
      {updateForm.forms_chart !== null && (
        <section className="grid-full-col">
          <FormChart
            history={history}
            infoData={{id: id}}
            currentFormType={"Physical Form"}
            forms_chart={updateForm.forms_chart}
            onClickButton={(data) => {
              if (updateForm.forms_chart[0].form_no === null) {
                onChangePaperFormHOC("showSelectForm", true);
              } else {
                onChangeSubmitData({
                  ...toSubmitData,
                  representative_id: updateForm.forms_chart[0].form_no.includes(
                    "ALSP",
                  )
                    ? 2
                    : 1,
                });
                onChangePaperFormHOC("showSubmitConfirmation", true);
              }
              onChangeSubmitData({
                ...data,
                user_id: user_id,
              });
            }}
            onClickDetach={(data) => {
              onChangeSubmitData(data);
              onChangePaperFormHOC("showDetachConfirmation", true);
            }}
          />
        </section>
      )}
      <div className="at-form__content grid-control" style={{ gridGap: 16 }}>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Form Type"}</h2>
          <p>{updateForm.form_type}</p>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Type"}</h2>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            disabled={!can_update}
            checkedValue={updateForm.type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeUpdateField("type_id", val)}
            options={Type}
          />
        </section>
        <section className="grid-half-col">
          <div className="d-flex">
            <h2 className="at-form-input__title">Select an Agent</h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={onLoadAgent || !can_update}
            typeaheadId={"internal_agent"}
            options={agentList ? agentList : []}
            selectedValue={updateForm.user ? [updateForm.user] : []}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            labelKey={"full_name"}
            onSearch={(val) => {
              onChangeUpdateField("user", null);
              onChangeTypeaheadSearch(val);
            }}
            onSelect={(val) =>
              val && val.length > 0 && onChangeUpdateField("user", val[0])
            }
            helpText={"e.g. Full Name, Email, Mobile Number"}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Form Number"}</h2>
          <CustomFormInput
            type={"text"}
            disabled={true}
            value={updateForm.tracking_number}
            onChangeValue={(val) => onChangeUpdateField("tracking_number", val)}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Date Taken"}</h2>
          <div className={`at-form-input`}>
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              placeholderText="e.g. 06-08-2019"
              showYearDropdown={true}
              className="at-form-input__input"
              disabled={!can_update}
              value={updateForm.date_taken || null}
              onChange={(val) =>
                onChangeUpdateField(
                  "date_taken",
                  Moment(val).format("DD-MM-YYYY"),
                )
              }
            />
          </div>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Status"}</h2>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            disabled={!can_update}
            checkedValue={updateForm.status_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeUpdateField("status_id", val)}
            options={Status}
          />
        </section>
        {updateForm.form_type_id === "2" && (
          <section className="grid-half-col">
            <h4 className="at-form-input__title" style={{ fontSize: 16 }}>
              Choose a Co-Agency
            </h4>
            <p>{updateForm.agency_name}</p>
          </section>
        )}
        <section className="grid-full-col">
          <h2 className="at-form-input__title">{"Remark"}</h2>
          <CustomTextarea
            rows={5}
            value={updateForm.remark}
            disabled={!can_update}
            onChangeValue={(val) => onChangeUpdateField("remark", val)}
            placeholder={"Remark"}
          />
        </section>
      </div>
      <div className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}>
        {can_update && (
          <button
            className={"btn-new btn-new--success"}
            type={"button"}
            onClick={() =>
              updatePaperForm({
                ...updateForm,
              })
            }
          >
            Update
          </button>
        )}
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClose()}
        >
          Close
        </button>
      </div>
      {renderSubmitFormModal()}
      {onLoadPaperForms && <LoadingModal />}
      <ConfirmationModal
        mode={"info"}
        open={showSubmitConfirmation}
        title={"Closing Deal"}
        message={
          "Are you sure you want to submit Physical Form to Claim Form? This will mark this form as submitted and no longer be edited."
        }
        loading={onLoadPaperForms || onLoadForms}
        positiveText={"Submit"}
        icon={<AtlasIcon svgHref={"svg-document-home"} />}
        positiveAction={() =>
          submitForm(toSubmitData.id, "PhysicalForm", {
            representative_id: toSubmitData.representative_id,
          })
        }
        negativeText={"Cancel"}
        negativeAction={() =>
          onChangePaperFormHOC("showSubmitConfirmation", false)
        }
      />
      <ConfirmationModal
        mode={"info"}
        open={showDetachConfirmation}
        title={`Detach ${associatedForms[1]}`}
        message={`Are you sure you want to detach ${associatedTransNumber[1]} from ${associatedTransNumber[0]}?`}
        loading={onLoadPaperForms || onLoadForms}
        positiveText={"Detach"}
        icon={<AtlasIcon svgHref={"svg-document-home"} />}
        positiveAction={() => {
          let tmpData = _.cloneDeep(toSubmitData);
          tmpData.type_id = selectedPaperForm.type_id;
          detachForm(tmpData);
        }}
        negativeText={"Cancel"}
        negativeAction={() =>
          onChangePaperFormHOC("showDetachConfirmation", false)
        }
      />
    </>
  );
};

export default AgentSearchHOC(Update);
