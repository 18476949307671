import React from "react";
import { numberWithCommas } from "utils/thousandSeparator";

export const columnData = [
  {
    header: "Project name",
    searchFlag: "projectName",
    accessor: "projectName",
  },
  {
    header: "Total unit",
    searchFlag: "totalUnits",
    accessor: "totalUnits",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  {
    header: "Converted unit",
    searchFlag: "convertedUnits",
    accessor: "convertedUnits",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  {
    header: "Cancelled unit",
    searchFlag: "cancelledUnits",
    accessor: "cancelledUnits",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  {
    header: "Booked unit",
    searchFlag: "bookedUnits",
    accessor: "bookedUnits",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  {
    header: "Sales amount",
    searchFlag: "totalSalesAmount",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    renderColumn: (row) => <p>{numberWithCommas(row.totalSalesAmount, 2)}</p>,
  },
  {
    header: "Converted sales amount",
    searchFlag: "totalConvertedSalesAmount",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedSalesAmount, 2)}</p>
    ),
  },
];
