import React, { Component } from "react";
import { compose } from "redux";

import AtlasCard from "components/Card";
import AtlasTable from "components/NewTable";
import ModuleHeader from "components/ModuleHeader";
import AtlasLoadingModal from "components/LoadingModal";

import TutorialHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Title",
    accessor: "title",
    searchFlag: "title",
    columnStyle: { width: "200%" },
  },
  {
    header: "Category",
    accessor: "category",
    searchFlag: "category",
    columnStyle: { width: "70%" },
  },
];

class Tutorial extends Component {
  componentDidMount = () => {
    this.props.getTutorials();
    initGA("/dashboard/tutorials");
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={"Tutorials"} atlasIcon={"atlas-bookmark-2"} />
        <AtlasCard
          cardContent={
            <AtlasTable
              className={"mb-100"}
              columns={columnData}
              pagination={true}
              rowData={this.props.tutorials || []}
              actionColumnContent={[
                {
                  name: "download",
                  onClick: (rowData) =>
                    window.open(rowData.document_url, "_blank"),
                },
              ]}
            />
          }
        />
        {this.props.onLoadTutorial && <AtlasLoadingModal />}
      </>
    );
  };
}

export default compose(TutorialHOC, VersionCheckHOC)(Tutorial);
