import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import { SnackbarContent } from "@material-ui/core";
import { compose } from "redux";

import SvgIcon from "components/Icon/new";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasSimpleTable from "components/NewTable/simple";
import AtlasLoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import ConfrimationModal from "components/Modal/confirmation";
import UploadBankDetail from "./uploadBankDetail";
import permissionsChecker from "utils/permissionsChecker";

import SubsalesDocumentsHOC from "../actions/document";
import SubsalesClaimsPreviewHOC from "../actions/preview";
import { document_types as DocLib } from "dictionary/subsales";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const relationshipOptions = subsalesTypeId => 
  subsalesTypeId === 1
  ? [
    { label: "Vendor", value: 1 },
    { label: "Purchaser", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Vendor/Purchaser's Sibling", value: 4 },
    { label: "Vendor/Purchaser's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]
  : [
    { label: "Landlord", value: 1 },
    { label: "Tenant", value: 2 },
    { label: "Agent", value: 3 },
    { label: "Landlord/Tenant's Sibling", value: 4 },
    { label: "Landlord/Tenant's Spouse", value: 5 },
    { label: "Others", value: 6 }
  ]

const transferMethodRadioOptions = [
  {
    value: 2,
    label: "Cash",
  },
  {
    value: 1,
    label: "Cheque Deposit",
  },
  {
    value: 6,
    label: "Credit Card",
  },
  {
    value: 3,
    label: "Online Transfer",
  },
  {
    value: 5,
    label: "Foreign Transfer",
  },
];

const TransactionDetail = [
  { label: "Bank In To", value: "bank_name" },
  { label: "Amount", value: "amount" },
  { label: "Payment made by", value: "payer_type" },
  { label: "Transfer Method", value: "payment_method" },
  { label: "Reference", value: "reference_no" },
  { label: "Status", value: "status" },
  { label: "Admin", value: "user_display_name" },
  { label: "Remark", value: "remark" },
];

const ColumnData = subsalesTypeId => [
  {
    header: "Uploaded At",
    searchFlag: "created_at",
    accessor: "created_at",
    columnStyle: { width: "50%" },
  },
  {
    header: "File Name",
    searchFlag: "snapshot_file_name",
    accessor: "snapshot_file_name",
  },
  {
    header: "Document Type",
    searchFlag: "doc_type",
    accessor: "doc_type",
    columnStyle: { width: "180%" },
    renderColumn: (rowData) => {
      let bankInSlip = rowData.bank_in_detail;

      const {
        payment_method_id = "",
        payer_type_id = ""
      } = bankInSlip || {}

      const {label: payer_type_label = ""} = relationshipOptions(subsalesTypeId).find(option => option.value === payer_type_id) || {}
      const {label: payment_method_label = ""} = transferMethodRadioOptions.find(option => option.value === payment_method_id) || {}

      if (bankInSlip) {
        bankInSlip = {
          ...bankInSlip,
          payment_method: payment_method_label,
          payer_type: payer_type_label
        }
      }

      return (
        <>
          <p>{rowData.doc_type}</p>
          {bankInSlip && (
            <div className="at-form__content at-document__bank-in-slip-detail row">
              <p className="col-6 px-1">
                <p style={{ fontWeight: 600 }}>Payment Date: </p>
                {bankInSlip?.transacted_at
                  ? Moment(bankInSlip.transacted_at).format("DD MMM YYYY")
                  : "N/A"}
              </p>
              {TransactionDetail.map((detail) => (
                <p className="col-6 px-1">
                  <p style={{ fontWeight: 600 }}>{detail.label}: </p>
                  {bankInSlip?.[detail.value] || "N/A"}
                </p>
              ))}
            </div>
          )}
        </>
      );
    },
  }
];

class SubsaleClaimDocument extends Component {
  state = {
    showConfirmationModal: false,
    document_types: [],
    selectedDocType: 0,
    showDeleteConfirmationModal: false,
    toRemoveDocID: 0,
    selectedDocument: {},
    showUploadBankSlip: false,
    showConfirmRejectModal: false,
    fileItem: {},
    fileName: "",
  };

  componentDidMount = () => {
    this.props.getDocuments(this.props.selected_claim.id);
    this.setState({ document_types: this.processDocTypes() });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.fileName !== this.state.fileName &&
      this.state.fileName !== ""
    ) {
      let dataToSubmit = {
        type_id: this.state.selectedDocType,
        snapshot: this.state.fileItem,
        snapshot_file_name: this.state.fileName,
      };
      this.props.uploadDocument(this.props.selected_claim.id, dataToSubmit);
    }

    if (this.props.documents.length !== prevProps.documents.length) {
      let tmpDocTypes = this.state.document_types;
      let tmp = [];
      tmpDocTypes.map((item) => {
        let tmpData = { ...item };
        let tmpFlag = false;
        this.props.documents.map((child) => {
          if (item.name === child.doc_type) {
            tmpFlag = true;
          }
        });
        tmpData.uploaded = tmpFlag;
        tmp.push(tmpData);
      });
      return this.setState({ document_types: tmp });
    }
  };

  processDocTypes = () => {
    if (this.props.selected_claim.sub_sale_type_id === 1) {
      this.setState({ mandatory: [2, 4, 5] });
    } else if (this.props.selected_claim.form_type_id === 4) {
      this.setState({ mandatory: [4, 6] });
    } else {
      this.setState({ mandatory: [2, 12, 6, 4] });
    }
    let tmpData = [];
    DocLib.map((item) => {
      tmpData.push({
        ...item,
        value: item.id,
        label:
          item.id === 4
            ? "Bank In Slip (upload this to generate temporarily receipt)"
            : item.name,
      });
    });
    return tmpData;
  };

  onClickCombineDoc = () =>
    this.props.combineDocuments(this.props.selected_claim.id, () =>
      this.props.getSelectedClaim(this.props.selected_claim.id),
    );

  onRejectClaim = (status) =>
    this.setState(
      { showConfirmRejectModal: false },
      () =>
        status === "yes" &&
        this.props.rejectSubsaleClaim(this.props.selected_claim.id),
    );

  selectedDocs = (id) => {
    if (id === 4) {
      this.setState({
        selectedDocument: {},
        showUploadBankSlip: true,
      });
    } else {
      setTimeout(() => {
        this.pond.browse();
      }, 400);
    }
    this.setState({ selectedDocType: id });
  };

  onUploadDocs = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          fileItem: e.target.result,
          fileName: fileItems[0].file.name,
        });
      };
      reader.readAsDataURL(fileItems[0].file);
    } else {
      this.setState({
        fileItem: {},
        fileName: "",
      });
    }
  };

  onClickRemoveUploadedDocs = (id, doc_id) => {
    this.setState({
      toRemoveDocID: doc_id,
      showDeleteConfirmationModal: true,
    });
  };

  confirmDeleteDoc = () =>
    this.setState(
      {
        showDeleteConfirmationModal: false,
      },
      () =>
        this.props.deleteDocument(
          this.props.selected_claim.id,
          this.state.toRemoveDocID,
        ),
    );

  cancelDeleteDoc = () => this.setState({ showDeleteConfirmationModal: false });

  renderTableHeading = () => {
    let { documents = [], selected_claim } = this.props;
    const { can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    return (
      <div className={`at-table_heading`}>
        <SvgIcon
          svgHref="confirmation-form_document-text"
          className="at-icon_svg-table_heading-title"
        />
        <h5 className="fw-600">Uploaded Document</h5>
        {can_update && (
          <button
            className={"btn-new btn-new--secondary"}
            onClick={this.onClickCombineDoc}
            disabled={documents ? documents.length <= 0 : false}
          >
            {"Combine Docs"}
          </button>
        )}
        {selected_claim.combined_pdf_url &&
          selected_claim.combined_pdf_url.length > 0 && (
            <button
              className={"btn-new btn-new--secondary"}
              onClick={() =>
                window.open(selected_claim.combined_pdf_url, "_blank")
              }
              disabled={false}
            >
              {"View Combined Doc"}
            </button>
        )}
      </div>
    );
  };

  render = () => {
    let { documents = [], selected_claim } = this.props;
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { can_create, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const menuExpand = this.props.data.sideMenuReducer.sideMenuExpand;

    return (
      <div>
        <div className="at-form__content">
          <div className="d-flex grid_gap-2 mb-3">
            <SvgIcon
              svgHref="confirmation-form_document-text"
              className="at-icon_svg-table_heading-title"
            />
            <h5 className="fw-600">Documents</h5>
          </div>
          {can_create && (
            <div>
              <AlertBox
                mode={"info"}
                containerClassName="mb-3"
                description={
                  <>
                    <p
                      className={"subsaleclaim-doc-title"}
                      style={{ fontWeight: "400" }}
                    >
                      Accepted file types: pdf, jpg, jpeg and png.
                    </p>
                    <p style={{ fontWeight: "400" }}>
                      You can select multiple documents for the same type.
                    </p>
                    <p style={{ fontWeight: "400" }}>
                      Please use CamScanner mobile app to snapshot your document
                      to convert into PDF.
                    </p>
                  </>
                }
              />
              <CustomRadioGroup
                mode={"old"}
                horizontal={false}
                componentClassName={"at-document__radio-control"}
                checkedValue={this.state.selectedDocType}
                options={this.state.document_types}
                specialChecker={(uploaded) => (
                  <AtlasIcon
                    svgHref={"atlas-document-upload"}
                    style={{ fill: uploaded && "#12B76A" }}
                    className={"at-document__checker"}
                  />
                )}
                selectedRadioValue={(val) => this.selectedDocs(val)}
                status={true}
                mandatory={this.state.mandatory}
                disabled={false}
              />
              {this.state.selectedDocType > 0 && (
                <div style={{ display: "none" }}>
                  <FilePond
                    acceptedFileTypes={[
                      "image/jpg",
                      "image/jepg",
                      "image/png",
                      "application/pdf",
                    ]}
                    ref={(ref) => (this.pond = ref)}
                    allowMultiple={false}
                    labelIdle={"Click here to upload documents"}
                    fullWidth
                    onupdatefiles={(fileItems) => this.onUploadDocs(fileItems)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <AtlasSimpleTable
          mode={"new"}
          hideSearch={true}
          columns={ColumnData(this.props.selected_claim.sub_sale_type_id)}
          rowData={documents || []}
          actionColumnContent={[
            {
              name: "details",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, fill: "#007BFF" },
              onClick: (rowData) => window.open(rowData.snapshot_url, "_blank"),
            },
            {
              name: "edit",
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, fill: "#007BFF" },
              onShow: (rowData) => rowData.type_id === 4 && rowData.bank_in_detail,
              disabled: (rowData) => role === "Admin" && rowData.bank_in_detail.status_id !== 1,
              disabledTooltipContent: (
                <div style={{ maxWidth: 100 }}>
                  Not allow to edit due to the transaction is already marked as
                  cancelled or received'
                </div>
              ),
              onClick: (rowData) =>
                this.setState({
                  selectedDocument: rowData,
                  showUploadBankSlip: true,
                }),
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: (rowData) =>
                can_destroy &&
                (rowData.type_id === 4
                  ? rowData.bank_in_detail
                    ? role === "Super Admin"
                    : true
                  : true),
              iconStyle: { width: 20, height: 20, fill: "#EB3134" },
              iconClass: `at-table-new-icon-button ${!can_destroy ? "opacity-50" : ""}`,
              onClick: (rowData) =>
                this.onClickRemoveUploadedDocs(this.state.id, rowData.id),
            },
          ]}
          renderExtraContent={this.renderTableHeading}
          emptyStateMessage={"No uploaded docs are found."}/>
        {documents.length > 0 && selected_claim.missing_documents_count > 0 && (
          <SnackbarContent
            style={{ backgroundColor: "#D93335" }}
            message={`${selected_claim.missing_documents_count} documents are missing in combined PDF.`}
          />
        )}
        {(this.props.onLoadDocuments ||
          this.props.onLoadSubsaleClaims ||
          this.props.onLoadPreview) && <AtlasLoadingModal />}
        <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() => this.props.onClickNav("Participants")}
          >
            <p>Participants</p>
          </button>
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() => this.props.onClickNav("Submit Claims")}
          >
            <p>Submission</p>
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onCloseForm()}
          >
            Close
          </button>
        </div>
        <ConfrimationModal
          open={this.state.showDeleteConfirmationModal}
          message={"Are you sure to remove this document?"}
          fieldLabel={"Remove Reason"}
          positiveAction={this.confirmDeleteDoc}
          negativeAction={this.cancelDeleteDoc}
        />
        <ConfrimationModal
          open={this.state.showConfirmRejectModal}
          message={"Are you sure to reject this claim?"}
          fieldLabel={"Reject Reason"}
          positiveAction={() => this.onRejectClaim("yes")}
          negativeAction={() => this.onRejectClaim("no")}
        />
        {this.state.showUploadBankSlip && (
          <UploadBankDetail
            onLoadOCR={this.props.onLoadOCR}
            OCRContent={this.props.OCRContent}
            selectedDocType={this.state.selectedDocType}
            selectedDocument={this.state.selectedDocument}
            subsalesTypeId={this.props.selected_claim.sub_sale_type_id}
            selected_claim_id={this.props.selected_claim.id}
            onLoadSubsaleClaims={this.props.onLoadSubsaleClaims}
            bankInSlipOCR={this.props.bankInSlipOCR}
            uploadDocument={this.props.uploadDocument}
            updateBankInSlip={this.props.updateBankInSlip}
            onClickClose={() =>
              this.setState({
                selectedDocument: {},
                showUploadBankSlip: false,
              })
            }
          />
        )}
      </div>
    );
  };
}

export default compose(
  SubsalesDocumentsHOC,
  SubsalesClaimsPreviewHOC,
)(SubsaleClaimDocument);
