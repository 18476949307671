import Axios from "axios";

import { getItem } from "utils/tokenStore";

import {
  CURRENT_SIGN_IN_PROFILE,
  UPDATE_CURRENT_SIGN_IN_PROFILE,
} from "./type";
import {
  beginAjaxCallImpersonate,
  ajaxCallErrorImpersonate,
  ajaxCallSuccessImpersonate,
} from "./ajax";
import getDomainURL from "utils/getDomainURL";

export function getCurrentSignInProfile() {
  return (dispatch) => {
    dispatch(beginAjaxCallImpersonate(true));
    Axios.get(`${getDomainURL()}/profiles/me`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `JWT ${getItem("IQI_ATLAS_JWT_AGENT_TOKEN")}`,
      },
    })
      .then((response) => {
        dispatch(getCurrentSignInProfileSuccess(response.data));
        dispatch(ajaxCallSuccessImpersonate());
      })
      .catch((error) => {
        dispatch(
          ajaxCallErrorImpersonate(error, () =>
            dispatch(getCurrentSignInProfile())
          )
        );
      });
  };
}

function getCurrentSignInProfileSuccess(payload) {
  return {
    type: CURRENT_SIGN_IN_PROFILE,
    payload,
  };
}

export function updateCurrentSignInProfile(params) {
  let user = new FormData();
  if (params) {
    Object.keys(params).map((key) => {
      if (typeof params[key] === "object") {
        Object.keys(params[key]).map((childObj) => {
          user.append(`${key}[${childObj}]`, params[key][childObj]);
        });
      } else {
        user.append(`${key}`, params[key]);
      }
    });
  }
  return (dispatch) => {
    dispatch(beginAjaxCallImpersonate(true, true));
    Axios.put(`${getDomainURL()}/profiles/me`, user)
      .then((response) => {
        dispatch(updateCurrentSignInProfileSuccess(response.data));
        dispatch(ajaxCallSuccessImpersonate());
      })
      .catch((error) => {
        dispatch(
          ajaxCallErrorImpersonate(error, () =>
            dispatch(updateCurrentSignInProfile(params))
          )
        );
      });
  };
}

export function updateCurrentSignInProfileSuccess(payload) {
  return {
    type: UPDATE_CURRENT_SIGN_IN_PROFILE,
    payload,
  };
}
