import React, { Component } from "react";
import { IoMdAdd, IoIosRemove } from "react-icons/io";

import CustomButton from "components/Button";

import "stylesheets/components/infobox/index.scss";

class Infobox extends Component {
  state = {
    expand: false,
    expandDetail: false,
  };

  expandInfo() {
    this.props.onClickExpandInfo(!this.props.expand);
  }

  renderTextbox() {
    const { expand, inactiveContent, activeContent } = this.props;

    return (
      <div onClick={() => this.expandInfo()} className="at-infobox__textbox">
        {expand && activeContent}
        {!expand && <span className="fw-500">{inactiveContent}</span>}
      </div>
    );
  }

  render() {
    const { expand, containerStyle, expandIcon, collapseIcon, expandStyle } =
      this.props;
    return (
      <div className="at-infobox" style={{ ...containerStyle }}>
        <CustomButton
          containerClass="position-relative"
          className="at-infobox__expand"
          style={{ ...expandStyle }}
          onClick={() => this.expandInfo()}
          children={
            <div className="at-infobox__plus-minus">
              {expand
                ? collapseIcon || <IoIosRemove />
                : expandIcon || <IoMdAdd />}
            </div>
          }
        />
        <div>{this.renderTextbox()}</div>
      </div>
    );
  }
}

export default Infobox;
