import React, { Component } from "react";
import { Card, CardContent } from "@material-ui/core";
import _ from "lodash";
import { TiTick } from "react-icons/ti";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasSimpleTable from "../components/Table";

import RegisterTrainingHOC from "../actions/registerTraining";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Present", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Reset", colorName: "Yellow" },
  { value: "Absent", colorName: "Red" },
  { value: "Resit", colorName: "Grey" },
];

const myTrainingHeaderData = [
  { label: "Training Name", value: "event_name" },
  { label: "Start time", value: "start_datetime", width: "60%" },
  { label: "End time", value: "end_datetime", width: "60%" },
  {
    label: "Status",
    value: "status",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
    containerStyle: { width: "50%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        colorName && (
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData.status}
          </div>
        )
      );
    },
  },
];

const trainingDetails = [
  { label: "Training Name", value: "topic" },
  { label: "Start time", value: "start_datetime" },
  { label: "End time", value: "end_datetime" },
];

class MyTrainingContent extends Component {
  renderUpcomingContent = () => {
    const {
      upcomingTraining,
      onLoadRegisterTraining,
      showConfirmationModal,
      onChangeRegisterHOC,
    } = this.props;

    return (
      <AtlasSimpleTable
        headerData={trainingDetails}
        actionColumn={true}
        renderTableTitle={() => (
          <div className="training-table__header">
            <div className="at-badge-icon-sm at-badge-icon-warning mr-2">
              <AtlasIcon svgHref={"atlas-calendar-input"} />
            </div>
            <h4 className={"fs-4 fw-600"}>Upcoming Training</h4>
          </div>
        )}
        renderNoData={() => (
          <>
            <div className="at-badge-icon-lg at-badge-icon-light-gray my-3 mx-auto">
              <AtlasIcon svgHref={"atlas-calendar-input"} />
            </div>
            <p style={{ marginBottom: 8 }}>No upcoming training available</p>
            <span>Please stay tuned for more trainings to come</span>
          </>
        )}
        actionColumnContent={["register"]}
        onLoadRegisterTraining={onLoadRegisterTraining}
        showConfirmationModal={showConfirmationModal}
        onChangeRegisterHOC={onChangeRegisterHOC}
        rowData={upcomingTraining ? upcomingTraining : []}
      />
    );
  };
  render = () => {
    const {
      myTrainingCompletion,
      myTrainingData,
      selectedTraining,

      onLoadMyTraining,
      onLoadRegisterTraining,
    } = this.props;
    return (
      <>
        <div className="at-form__content">
          <p className="fs-2">
            Here is a link to Zoom's test meeting page. You can use this page to
            test your internet connection, familiarize yourself with Zoom's
            video conferencing features, and adjust your audio and video before
            joining any training.
          </p>
          <button
            className="btn-new btn-new--outline-secondary d-flex align-items-center mt-3"
            onClick={() => window.open("https://zoom.us/test", "_blank")}
          >
            <img
              className="mr-2"
              src={"https://st1.zoom.us/zoom.ico"}
              style={{ width: 16, height: 16 }}
            />
            <span style={{ fontSize: 12, color: "#0A5DFF", fontWeight: 600 }}>
              {"Join a Test Meeting | Zoom"}
            </span>
          </button>
        </div>
        {!myTrainingCompletion && this.renderUpcomingContent()}
        <AtlasSimpleTable
          className={"my-3"}
          headerData={myTrainingHeaderData}
          actionColumn={false}
          renderTableTitle={() => (
            <div className="training-table__header">
              <div className="at-badge-icon-sm at-badge-icon-warning mr-2">
                <AtlasIcon svgHref={"atlas-calendar-approve"} />
              </div>
              <h4 className={"fs-4 fw-600"}>Registered Training</h4>
            </div>
          )}
          renderNoData={() => (
            <>
              <div className="at-badge-icon-lg at-badge-icon-light-gray my-3 mx-auto">
                <AtlasIcon svgHref={"atlas-calendar-approve"} />
              </div>
              <p>No training was attended yet.</p>
            </>
          )}
          actionColumnContent={[]}
          onClickJoin={(val) => window.open(val, "_blank")}
          rowData={myTrainingData || []}
        />
        {myTrainingCompletion && (
          <Card className={"mb-3"}>
            <CardContent>
              <div className={"d-flex"}>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#6bc259",
                    borderRadius: "50%",
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TiTick style={{ color: "white", width: 17, height: 17 }} />
                </div>
                <h2
                  className="at-form-input__title mt-10"
                  style={{ textDecoration: "underlined" }}
                >
                  Congratulations! You have successfully completed all
                  trainings!
                </h2>
              </div>
            </CardContent>
          </Card>
        )}
        <ConfirmationModal
          title={"Register Training"}
          loading={this.props.onLoadRegisterTraining}
          open={this.props.showConfirmationModal}
          message={`Are you sure you want to register this training?`}
          positiveAction={() => {
            this.props.registerTraining(
              selectedTraining.zoom_meeting_id,
              selectedTraining.event_id,
            );
            this.props.onChangeRegisterHOC(false, "showConfirmationModal");
          }}
          negativeAction={() =>
            this.props.onChangeRegisterHOC(false, "showConfirmationModal")
          }
        />
        {(onLoadRegisterTraining || onLoadMyTraining) && <LoadingModal />}
      </>
    );
  };
}

export default RegisterTrainingHOC(MyTrainingContent);
