import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";
import { BsCheck } from "react-icons/bs";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtlasButton from "components/Button";
import AtlasTable from "components/NewTable";
import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import StepIndicator from "components/StepIndicator";
import AtlasCloseButton from "components/Button/prev";
import ConfirmationModal from "components/Modal/confirmation";
import ReaEvent from "./event";
import ReaMessageTab from "./message";
import ReaDocumnetTab from "./document";
import ReaActivityTab from "./activity";

import { columnData } from "./assets";
import ReaRenewalDetailsHOC from "./actions";
import { getColorBadge } from "dictionary/badgeColor";
import "./index.scss";

const StatusValue = [
  {
    status: "Pending",
    value: "1",
    icon: "atlas-more-2",
    description: `Add the events you attended in the Events tab below, then click "Submit to Admin" to submit your renewal application. Be sure to upload the certificate of attendance for each event added.`,
  },
  {
    status: "Processing",
    value: "2",
    icon: "atlas-user-tick",
    description: `The admin is currently reviewing your renewal application. Once this review is complete, it will be sent to BOVAEA for approval.`,
  },
  {
    status: "Board Approval",
    value: "3",
    icon: "atlas-bank",
    description: `Waiting for BOVAEA final approval.`,
  },
  {
    status: "Renewal Approved",
    value: "4",
    icon: "atlas-verify",
    description: `Your renewal application has been approved. Kindly wait for your new tag to reach your designated branch.`,
  },
  {
    status: "Tag Arrived",
    value: "5",
    icon: "atlas-personalcard",
    description: `Your new tag has arrived. Kindly collect the new and return the old tag at your respective branch.`,
  },
  {
    status: "Tag Collected",
    value: "6",
    icon: "atlas-box-tick",
    description: `You have collected your tag.`,
  },
];

const ReaRenewalDetails = ({
  onLoadRenewalDetail,
  selectedRenewal,
  statusColor,
  message,
  activities,
  documentList,
  documentPages,
  selectedEvent,
  showEditEventModal,
  showCreateEventModal,
  showSubmitConfirmation,

  getMessage,
  putMessage,
  getActivity,
  submitToAdmin,
  getDocumentList,
  onChangeRenewalHOC,
  createRenewalDetails,
  updateRenewalDetails,
  onChangeRenewalDetailsHOC,
}) => {
  const {
    total_cpd_hour,
    licence_renewal,
    application_status_id,
    applicant_status_name,
    licence_renewal_status,
    licence_renewal_details,
  } = selectedRenewal;
  const [step, setStep] = useState(`${application_status_id}`);
  const [tabContent, setTabContent] = useState("Events");
  const [showInfoBox, onToggleInfoBox] = useState(false);

  useEffect(() => {
    switch (application_status_id) {
      case 1:
        return setStep("1");
      case 2:
      case 3:
        return setStep("2");
      case 4:
        return setStep("3");
      case 5:
        return setStep("4");
      case 6:
        return setStep("5");
      case 7:
        return setStep("6");
    }
  }, [application_status_id]);

  const renderDetailsContent = () => {
    const { colorName } = _.find(statusColor, { value: applicant_status_name });

    return (
      <>
        <div className="at-form__content grid_gap-2 m-auto row">
          <section className="col-md-4 d-flex align-items-center grid_gap-2">
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={"atlas-calendar-2"}
                style={{ fill: "#EA580C" }}
              />
            </div>
            <div>
              <label style={{ color: "#374151", fontSize: 12 }}>
                Renewal Year
              </label>
              <p style={{ color: "#111827", fontSize: 14, fontWeight: 500 }}>
                {licence_renewal.renewal_year}
              </p>
            </div>
          </section>
          <section className="col-md-4 d-flex align-items-center grid_gap-2">
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon svgHref={"atlas-clock"} style={{ fill: "#EA580C" }} />
            </div>
            <div>
              <label style={{ color: "#374151", fontSize: 12 }}>
                Accumulated CPD Hours
              </label>
              <p style={{ color: "#111827", fontSize: 14, fontWeight: 500 }}>
                {total_cpd_hour}
              </p>
            </div>
          </section>
          <section className="col-md-4 d-flex align-items-center grid_gap-2">
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon
                svgHref={"atlas-calendar-2"}
                style={{ fill: "#EA580C" }}
              />
            </div>
            <div>
              <label style={{ color: "#374151", fontSize: 12 }}>
                Renewal Status
              </label>
              <div
                className="at-status_badge"
                style={{ ...getColorBadge(colorName) }}
              >
                {applicant_status_name}
              </div>
            </div>
          </section>
        </div>
        <div className="at-form__content grid_gap-2 mt-4">
          <div
            className="d-flex align-items-center grid_gap-2"
            style={{ fontSize: 16, fontWeight: 500 }}
          >
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#FFEDD5" }}
            >
              <AtlasIcon svgHref={"atlas-timer"} style={{ fill: "#EA580C" }} />
            </div>
            Status
          </div>
          <div className="at-renewal-details__step-indicator">
            <StepIndicator
              mode={"gradual"}
              progress={step}
              enableProgress={true}
              stepList={["1", "2", "3", "4", "5", "6"]}
              onClick={() => {}}
              renderStepLabel={(item) =>
                _.find(StatusValue, { value: item }).status
              }
              renderStepIcon={(item) => {
                const icons = _.find(StatusValue, { value: item })?.icon;
                if (item === step) {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: 32,
                        height: 32,
                        background: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <AtlasIcon svgHref={icons} style={{ fill: "#3B82F6" }} />
                    </div>
                  );
                } else {
                  return <AtlasIcon svgHref={icons} />;
                }
              }}
            />
          </div>
          <hr />
          <div
            className="d-flex align-items-center grid_gap-2"
            style={{ fontSize: 16, fontWeight: 500 }}
          >
            <div
              className="at-renewal-details__icon-badge"
              style={{ backgroundColor: "#DBEAFE" }}
            >
              <AtlasIcon
                svgHref={
                  _.find(StatusValue, { value: step })?.icon ?? "atlas-more-2"
                }
                style={{ fill: "#2563EB" }}
              />
            </div>
            <div>
              <label style={{ color: "#2563EB", fontSize: 12 }}>
                {_.find(StatusValue, { value: step })?.status ?? "-"}
              </label>
              <p style={{ color: "#1F2937", fontSize: 14 }}>
                {_.find(StatusValue, { value: step })?.description ?? "-"}
              </p>
            </div>
          </div>
          {application_status_id === 1 && (
            <AtlasButton
              className="btn-new btn-new--primary mt-3"
              style={{ marginLeft: 40 }}
              disabled={licence_renewal_details.length === 0}
              children={"Submit to Admin"}
              onClick={() =>
                onChangeRenewalDetailsHOC(true, "showSubmitConfirmation")
              }
            />
          )}
        </div>
        {licence_renewal.licence_renewal_setting.length > 0 && (
          <CustomInfobox
            expand={showInfoBox}
            expandIcon={<BsCheck />}
            collapseIcon={<BsCheck />}
            inactiveContent={
              "Guidelines for Renewal (Credit Hours Requirements)"
            }
            onClickExpandInfo={onToggleInfoBox}
            activeContent={
              <>
                Guidelines for Renewal (Credit Hours Requirements)
                <div className="mt-3">
                  {licence_renewal.licence_renewal_setting.map(
                    (item, index) => (
                      <p
                        key={index}
                        style={{ color: "#374151", fontWeight: 500, margin: 0 }}
                      >
                        {item.description}
                      </p>
                    ),
                  )}
                </div>
              </>
            }
          />
        )}
      </>
    );
  };

  const renderEventsTab = () => (
    <AtlasTable
      columns={columnData}
      hideSearch={true}
      hidePagination={true}
      emptyStateMessage={`You don't have any event yet`}
      emptyStateDescription={`Click New Events to create event`}
      rowData={selectedRenewal.licence_renewal_details || []}
      renderCheckBoxActions={() => (
        <div className="d-flex align-items-center my-3 ml-3 grid_gap-2">
          <div
            className="at-renewal-details__icon-badge"
            style={{ backgroundColor: "#FFEDD5" }}
          >
            <AtlasIcon svgHref={"atlas-note-add"} style={{ fill: "#EA580C" }} />
          </div>
          <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
            Events
          </p>
          <AtlasButton
            className="btn-new btn-new--primary ml-2"
            onClick={() =>
              onChangeRenewalDetailsHOC(true, "showCreateEventModal")
            }
            children={
              <>
                <IoMdAdd style={{ height: 18, width: 18 }} />
                New Event
              </>
            }
          />
        </div>
      )}
      actionColumnContent={[
        {
          name: "edit",
          onClick: (rowData) =>
            Promise.all([
              onChangeRenewalDetailsHOC(rowData, "selectedEvent"),
            ]).then(() => {
              onChangeRenewalDetailsHOC(true, "showEditEventModal");
            }),
        },
        {
          name: "view-doc",
          disabled: (rowData) => rowData.licence_image.includes("missing"),
          onClick: (rowData) => window.open(rowData.licence_image, "_blank"),
        },
      ]}
    />
  );

  const renderTabContent = () => {
    switch (tabContent) {
      case "Events":
        return renderEventsTab();
      case "Documents":
        return (
          <ReaDocumnetTab
            documentList={documentList}
            documentPages={documentPages}
            selectedRenewal={selectedRenewal}
            getDocumentList={getDocumentList}
          />
        );
      case "Message":
        return (
          <ReaMessageTab
            message={message}
            selectedRenewal={selectedRenewal}
            getMessage={getMessage}
            putMessage={putMessage}
          />
        );
      case "Activity":
        return (
          <ReaActivityTab
            activities={activities}
            getActivity={getActivity}
            selectedRenewal={selectedRenewal}
          />
        );
    }
  };

  const renderCardContent = () => {
    const { colorName } = _.find(statusColor, {
      value: licence_renewal_status,
    });

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton
            onClick={() => onChangeRenewalHOC(false, "showRenewalDetails")}
          />
          <h2 className="at-card__title">
            REA Renewal - {licence_renewal.renewal_year}
          </h2>
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {licence_renewal_status}
          </div>
        </div>
        {renderDetailsContent()}
        <Tab
          containerClass={"mt-3"}
          sections={["Events", "Documents", "Message", "Activity"]}
          selectedSection={tabContent}
          onSelectSection={(val) => setTabContent(val)}
        />
        {renderTabContent()}
      </>
    );
  };

  return (
    <>
      <CustomCard className="h-100" cardContent={renderCardContent()} />
      <ConfirmationModal
        mode={"info"}
        open={showSubmitConfirmation}
        title={"Submit to Admin"}
        message={
          "Please ensure that all the event and certificates have been uploaded before proceeding."
        }
        showLoading={onLoadRenewalDetail}
        negativeText={"Cancel"}
        positiveText={"Confirm"}
        positiveAction={() => submitToAdmin(selectedRenewal.id)}
        negativeAction={() =>
          onChangeRenewalDetailsHOC(false, "showSubmitConfirmation")
        }
      />
      {showCreateEventModal && (
        <ReaEvent
          mode={"Create"}
          onClose={() =>
            onChangeRenewalDetailsHOC(false, "showCreateEventModal")
          }
          submitData={(dataToSubmit) =>
            createRenewalDetails({
              id: selectedRenewal.id,
              ...dataToSubmit,
            })
          }
        />
      )}
      {showEditEventModal && (
        <ReaEvent
          mode={"Edit"}
          selectedEvent={selectedEvent}
          onClose={() => onChangeRenewalDetailsHOC(false, "showEditEventModal")}
          submitData={(dataToSubmit) =>
            updateRenewalDetails({
              application_id: selectedRenewal.id,
              renewal_detail_id: dataToSubmit.id,
              ...dataToSubmit,
            })
          }
        />
      )}
      {onLoadRenewalDetail && <LoadingModal />}
    </>
  );
};

export default ReaRenewalDetailsHOC(ReaRenewalDetails);
