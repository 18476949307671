import React from "react";
import AtlasCloseButton from "components/Button/prev";

import CustomTable from "components/NewTable";
import { UnclaimedColumn } from "../asset";

const UnclaimedList = ({ data = [], closeDialog }) => {
  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={closeDialog} />
        <h5 className="at-card__title">{"Unclaimed Confirmation Form"}</h5>
      </div>
      {data.length > 0 && (
        <CustomTable
          columns={UnclaimedColumn}
          hideSearch={true}
          pagination={true}
          rowData={data}
        />
      )}
      {data.length < 1 && (
        <h4 className="at-card__title mb-20">{"No data found."}</h4>
      )}
    </>
  );
};

export default UnclaimedList;
