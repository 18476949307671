import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";

import CustomButton from "components/Button";
import AtlasTable from 'components/NewTable';
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTypeahead from "components/Typeahead/new";
import CustomTextarea from "components/Input/textarea";
import CustomFormInput from "components/Input/formInput";
import CustomDatepicker from "components/Input/datetimepicker";

import { getColorBadge } from "dictionary/badgeColor";

const durationOptions = [
  { label: "Mins", value: 30 },
  { label: "Mins", value: 60 },
  { label: "Mins", value: 90 },
  { label: "Mins Max", value: 120 },
];

const bookingStatus = [
  { label: "Confirmed", value: 1, disabled: false },
  { label: "Cancelled", value: 4, disabled: false },
];

const StatusColor = [
  { value: "Confirmed", colorName: "Green" },
  { value: "In Use", colorName: "Yellow" },
  { value: "Completed", colorName: "Cyan" },
  { value: "Pending Approval", colorName: "Yellow" },
  { value: "Rejected", colorName: "Red" },
  { value: "Cancelled", colorName: "Red" },
];

const columnData = [
  {
    header: 'Payment Information',
    accessor: 'created_at',
    searchFlag: 'created_at',
    renderColumn: rowData => (
      <>
        <p>
          <AtlasIcon svgHref={'atlas-calendar'} style={{width: 16, height: 16, fill: '#F0631D', marginRight: 4}} />
          {Moment(rowData.created_at).format('DD MMM YYYY')}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-bank'} style={{width: 16, height: 16, fill: '#F0631D', marginRight: 4}} />
          {rowData.bank}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-message-text'} style={{width: 16, height: 16, fill: '#F0631D', marginRight: 4}} />
          {rowData.reference}
        </p>
      </>
    )
  },
  {
    header: "Amount",
    accessor: "amount",
    searchFlag: "amount",
    columnStyle: { width: "40%", fontSize: 14 },
    renderColumn: (rowData) => `RM ${rowData.amount}`
  }
]

const MeetingRoomForm = ({
  mode,
  meetingRooms,
  calendarEvent,
  selectedRoomBooking,

  onClose,
  getCalendarEvent,
  createRoomBooking,
  updateRoomBooking,
  onChangeRoomBooking,
}) => {
  const [meetingRoom, setMeetingRoom] = useState({
    operating_start_time: "08:00 AM",
    operating_end_time: "08:00 PM",
  });
  const [remark, setRemark] = useState("");
  const [formData, onChangeFormData] = useState({
    meeting_room_id: "",
    start_time: "",
    end_time: "",
    event_subject: "",
    duration: "",
    add_ons: [],
  });
  const { colorName = "" } =
    _.find(StatusColor, { value: selectedRoomBooking?.status }) || {};

  const getPayload = () => {
    let temp = _.cloneDeep(formData);
    if (temp.add_ons?.length > 0) {
      temp.booking_add_ons_attributes = temp.add_ons.map((item) => {
        return {
          id: item.id || null,
          add_on_id: item.add_on_id,
          quantity: item.quantity || 1,
          ...item._destroy && { _destroy: true },
        };
      });
      delete temp.add_ons;
    }
    if (remark) {
      temp.remark = remark;
    }
    return temp;
  }

  useEffect(() => {
    if (selectedRoomBooking && mode === "update") {
      onChangeFormData({
        id: selectedRoomBooking.id,
        status_id: selectedRoomBooking.status_id,
        event_subject: selectedRoomBooking.event_subject,
        meeting_room_id: selectedRoomBooking.meeting_room_id,
        meeting_room_type_id: selectedRoomBooking.meeting_room_type_id,
        meeting_room_name: selectedRoomBooking.meeting_room_name,
        meeting_room_location: selectedRoomBooking.meeting_room_location,
        meeting_room_capacity: selectedRoomBooking.meeting_room_capacity,
        start_time: selectedRoomBooking.start_time,
        end_time: selectedRoomBooking.end_time,
        duration: selectedRoomBooking.duration,
        add_ons: selectedRoomBooking.add_ons || [],
      });
      setRemark(selectedRoomBooking.remark);
    }
  }, [selectedRoomBooking, mode]);

  useEffect(() => {
    if (formData.meeting_room_id) {
      const room = _.find(meetingRooms, (room) => room.id === formData.meeting_room_id)
      setMeetingRoom(room);
    }
  }, [formData.meeting_room_id, meetingRooms]);

  useEffect(() => {
    if (formData.start_time) {
      getCalendarEvent(formData.meeting_room_id, Moment(formData.start_time).format("YYYY-MM-DD"),);
    }
  }, [formData.start_time]);

  return (
    <>
      <div className="row px-3">
        <div className="col-md-6 pr-2">
          <div className="grid-control">
            {(selectedRoomBooking?.meeting_room_type_id === 2 && mode === 'update') && (
              <section className="grid-full-col at-meeting-room__business-lounge">
                <div
                  className="at-status_badge"
                  style={{ ...getColorBadge(colorName) }}
                >
                  {selectedRoomBooking?.status}
                </div>
                {
                  selectedRoomBooking?.status === "Rejected" && (
                    <div className="at-booking-reject-form">
                      { meetingRoom?.rejection_reason || "N/A" } 
                    </div>
                  )
                }
                <div className='d-flex flex-column mt-2'>
                  <h2>{selectedRoomBooking?.meeting_room_name}</h2>
                  <div className='d-flex align-items-center g-3'>
                    <p>
                      <AtlasIcon svgHref={'atlas-location'} style={{marginRight: 4}} />
                      {selectedRoomBooking?.meeting_room_location}
                    </p>
                    <p>
                      <AtlasIcon svgHref={'atlas-user'} style={{marginRight: 4}} />
                      {selectedRoomBooking?.meeting_room_capacity} seats
                    </p>
                  </div>
                </div>
              </section>
            )}
            {(meetingRoom?.meeting_room_type_id !== 2 || mode === 'create') && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title" required>
                  Meeting Room
                </h2>
                <CustomTypeahead
                  id={"meeting_room_id"}
                  options={meetingRooms}
                  valueKey={"id"}
                  labelKey={"name"}
                  filterBy={["name"]}
                  placeholder={"Select Room"}
                  childrenHead={(rowItem) => <p>{rowItem.name}</p>}
                  selectedValue={
                    formData.meeting_room_id && meetingRooms.length > 0
                      ? [
                          _.find(
                            meetingRooms,
                            (room) => room.id === formData.meeting_room_id,
                          ),
                        ]
                      : ""
                  }
                  onSelect={(val) => {
                    if (val?.length > 0)
                      onChangeFormData({
                        ...formData,
                        meeting_room_id: val[0].id,
                      });
                    else onChangeFormData({ ...formData, meeting_room_id: "" });
                  }}
                />
              </section>
            )}
            <section className="grid-full-col">
              <h2 className="at-form-input__title" required>
                Event Subject
              </h2>
              <CustomFormInput
                type={"text"}
                required={true}
                value={formData.event_subject}
                placeholder={"Event Subject"}
                onChangeValue={(val) =>
                  onChangeFormData({ ...formData, event_subject: val })
                }
              />
            </section>
            {(selectedRoomBooking?.meeting_room_type_id === 1 && mode === 'update') && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title">
                  Status
                </h2>
                <div className={"d-flex flex-wrap g-2"}>
                {
                  bookingStatus.map((option, optionIndex) => {
                    return (
                      <button
                        key={`booking_status-${optionIndex}`}
                        className={`btn-radio ${option.value === formData.status_id ? "btn-radio-selected" : "btn-radio-outline"}`}
                        onClick={() => onChangeFormData({ ...formData, status_id: option.value })}
                      >
                        {option.label}
                      </button>
                    );
                  })
                }
                </div>
              </section>
            )}
            <section className="grid-half-col">
              <h2 className="at-form-input__title" required>
                Start Date Time
              </h2>
              <div className="at-form-input">
                <CustomDatepicker
                  id={"start_date_time"}
                  type="datetime-local"
                  value={formData.start_time}
                  shouldDisableTime={(date) => {
                    if (meetingRoom) {
                      const operatingStartTime = Moment(date).set({
                        hour: Moment(
                          meetingRoom.operating_start_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_start_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      });
                      const operatingEndTime = Moment(date).set({
                        hour: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      });
                      return (
                        Moment(date) < operatingStartTime ||
                        Moment(date) > operatingEndTime
                      );
                    }
                    return (
                      Moment(date) <
                        Moment(date).set({ hour: 9, minute: 0, second: 0 }) ||
                      Moment(date) >
                        Moment(date).set({ hour: 20, minute: 0, second: 0 })
                    );
                  }}
                  minDateTime={meetingRoom?.meeting_room_type_id === 2
                    ? new Date(Moment().add(14, "days").startOf('days'))
                    : (formData.meeting_room_id
                      ? (new Date() > new Date(Moment(meetingRoom.operating_start_time, "hh:mm a"))
                        ? Moment()
                        : new Date(Moment(meetingRoom.operating_start_time, "hh:mm a")))
                      : Moment())
                  }
                  maxDateTime={meetingRoom?.meeting_room_type_id === 1
                    ? new Date(Moment().add(7, "days").endOf('days'))
                    : ''}
                  minutesStep={30}
                  onChange={(val) => {
                    if (Moment(val).isValid()) {
                      onChangeFormData({...formData,start_time: val,end_time: "",})
                    } else {
                      onChangeFormData({...formData,start_time: "",end_time: "",})
                    }
                  }}
                />
              </div>
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title" required>
                End Date Time
              </h2>
              <div className={`at-form-input ${meetingRoom?.meeting_room_type_id === 1 ? 'at-form-input__disabled' : ''}`}>
                <CustomDatepicker
                  id={"end_date_time"}
                  type="datetime-local"
                  textFieldClass={
                    `at-custom-datetimepicker-root at-form-input 
                    ${meetingRoom?.meeting_room_type_id === 1 ? 'disabled' : ''}`
                  }
                  value={formData.end_time}
                  minDateTime={formData.start_time || new Date()}
                  disabled={meetingRoom?.meeting_room_type_id === 1}
                  shouldDisableTime={(date) => {
                    if (meetingRoom?.meeting_room_type_id === 2) {
                      const endTime = Moment(date).set({
                        hour: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      });
                      return Moment(date) > endTime
                    }
                  }}
                  onChange={(val) => {
                    if (Moment(val).isValid()) {
                      let temp = _.cloneDeep(formData);
                      temp["end_time"] = val;
                      temp["duration"] = Moment(val).diff(
                        Moment(formData.start_time),
                        "minutes",
                      );
                      onChangeFormData(temp);
                    }
                  }}
                />
              </div>
            </section>
            {meetingRoom?.meeting_room_type_id === 1 && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title" required>
                  Meeting Duration
                </h2>
                <div className={"d-flex flex-wrap g-2"}>
                  {durationOptions.map((option, optionIndex) => {
                    let withinDuration = true;

                    if (formData.start_time !== "") {
                      let meetingEndTime = Moment(formData.start_time).set({
                        hour: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      });
                      if (
                        Moment(formData.start_time)
                          .add(option.value, "minutes")
                          .isSameOrBefore(meetingEndTime)
                      ) {
                        withinDuration = false;
                      }
                    }
                    return (
                      <button
                        key={`paginated_table-radio_option-${optionIndex}`}
                        className={`btn-radio ${formData.duration === option.value ? "btn-radio-selected" : "btn-radio-outline"} `}
                        style={{
                          textAlign: "center",
                          padding: "0px 12px",
                          width: 98,
                          height: 68,
                        }}
                        disabled={withinDuration}
                        onClick={() => {
                          let temp = _.cloneDeep(formData);
                          temp["end_time"] = Moment(formData.start_time).add(
                            option.value,
                            "minutes",
                          );
                          temp["duration"] = option.value;
                          onChangeFormData(temp);
                        }}
                      >
                        <div>
                          <div className="at-card-booking-form_duration_value">
                            {option.value}
                          </div>
                          <div className="at-card-booking-form_duration_label">
                            {option.label}
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </section>
            )}
            {meetingRoom?.meeting_room_type_id === 2 && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Admin Remarks</h2>
                <CustomTextarea
                  rows="3"
                  type="text"
                  value={remark}
                  placeholder={'Remarks'}
                  onChangeValue={val => setRemark(val)}
                  style={{ width: "100%", display: "block" }}
                />
              </section>
            )}
          </div>
        </div>
        <div className="col-md-6 pr-3">
          <div className="at-calendar-cont">
            <div className="at-calendar-header_date">
              {formData.start_time
                ? Moment(formData.start_time).format("DD MMM YYYY")
                : Moment().format("DD MMM YYYY")}
            </div>
            <Calendar
              localizer={momentLocalizer(Moment)}
              defaultView="day"
              toolbar={false}
              date={
                formData.start_time ? Moment(formData.start_time) : Moment()
              }
              onNavigate={() => {}}
              min={
                formData.start_time
                  ? Moment(formData.start_time).set({
                      hour: Moment(
                        meetingRoom.operating_start_time,
                        "hh:mm a",
                      ).format("HH"),
                      minute: Moment(
                        meetingRoom.operating_start_time,
                        "hh:mm a",
                      ).format("mm"),
                      second: 0,
                    })
                  : Moment().set({
                      hour: Moment(
                        meetingRoom.operating_start_time,
                        "hh:mm a",
                      ).format("HH"),
                      minute: Moment(
                        meetingRoom.operating_start_time,
                        "hh:mm a",
                      ).format("mm"),
                      second: 0,
                    })
              }
              max={
                formData.start_time
                  ? Moment(formData.start_time)
                      .set({
                        hour: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      })
                      .add(1, "hours")
                  : Moment()
                      .set({
                        hour: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          meetingRoom.operating_end_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      })
                      .add(1, "hours")
              }
              events={formData.start_time ? calendarEvent : []}
              startAccessor="start"
              endAccessor="end"
            />
          </div>
        </div>
        {meetingRoom?.meeting_room_type_id === 2 && (
          <>
            <div className="col-md-6 pr-3">
              <div className="at-card-booking-form_pricing">
                <h2 className="at-card-booking-form_room_name mb-1">Pricing</h2>
                {(meetingRoom?.business_lounge_pricings && meetingRoom?.business_lounge_pricings.length > 0) && meetingRoom?.business_lounge_pricings.map((item, index) => (
                  <div key={index}>
                    <div className="d-flex align-items-center g-2">
                      <AtlasIcon svgHref={"atlas-money"} />
                      <label>{item.name}</label>
                    </div>
                    <span style={{minWidth: '30%'}}>RM {item.price}</span>
                  </div>
                ))}
                <div>
                  <label className="w-100 text-right">Price Amount</label>
                  <span style={{minWidth: '30%'}} className="fw-700">RM {meetingRoom.business_lounge_pricings?.reduce((acc, item) => {
                    return acc + item.price
                  }, 0)}</span>
                </div>
                {meetingRoom?.add_ons?.length > 0 && (
                  <>
                    <h2 className="at-card-booking-form_room_name mt-3 mb-1">Add On</h2>
                    {meetingRoom?.add_ons?.map((addon, index) => {
                      const addOnData = formData.add_ons?.find((item) => item.add_on_id === addon.id)

                      return (
                        <div>
                          <CustomCheckbox
                            content={
                              <>
                                <label className="w-100">{addon.name}</label>
                                {addon.description && <span>{addon.description}</span>}
                              </>
                            }
                            checked={addOnData
                              ? !addOnData?._destroy
                              : false}
                            onChangeCheckboxValue={(e) => {
                              let temp = _.cloneDeep(formData);
                              if (e.target.checked) {
                                if (addOnData?.id) {
                                  temp.add_ons = temp.add_ons.map((item) => {
                                    if (item.add_on_id === addon.id) {
                                      delete item._destroy;
                                    }
                                    return item;
                                  });
                                } else {
                                  temp.add_ons.push({add_on_id: addon.id, price: addon.price, quantity: 1});
                                }
                              } else {
                                if (addOnData?.id) {
                                  temp.add_ons = temp.add_ons.map((item) => {
                                    if (item.add_on_id === addon.id) {
                                      item._destroy = true;
                                    }
                                    return item;
                                  });
                                } else {
                                  temp.add_ons = temp.add_ons.filter((item) => item.add_on_id !== addon.id);
                                }
                              }
                              onChangeFormData({...formData, add_ons: temp.add_ons})
                            }}
                          />
                          <span className="d-flex align-items-center g-2" style={{minWidth: '30%'}}>
                            RM {addon.price}
                            {addon.quantity_required && (
                              <CustomFormInput
                                type="number"
                                className={"mt-2"}
                                containerStyle={{ width: 100 }}
                                value={addOnData?.quantity}
                                placeholder="Quantity"
                                onChangeValue={(val) => {
                                  if (val <= meetingRoom.capacity) {
                                    let temp = _.cloneDeep(formData);
                                    temp.add_ons = temp.add_ons.map((item) => {
                                      if (item.add_on_id === addon.id) {
                                        item.quantity = val;
                                      }
                                      return item;
                                    });
                                    onChangeFormData({...formData, add_ons: temp.add_ons})
                                  }
                                }}
                              />
                            )}
                          </span>
                        </div>
                      )
                    })}
                    <div>
                      <label className="w-100 text-right">Add On Amount</label>
                      <span style={{minWidth: '30%'}} className="fw-700">RM {formData.add_ons?.reduce((acc, item) => {
                        if (item._destroy) {
                          return acc
                        } else {
                          if (item.quantity)
                            return acc + (item.price * item.quantity)
                          else
                            return acc + item.price
                        }
                      }, 0) || 0}</span>
                    </div>
                  </>
                )}
                <div>
                  <label className="fs-3 fw-600 w-100 text-right" style={{color: '#F0631D'}}>Total Summary</label>
                  <span className="fs-3 fw-600" style={{minWidth: '30%',color: '#F0631D'}}>RM 
                    {(formData.add_ons?.reduce((acc, item) => {
                      if (item._destroy) {
                        return acc
                      } else {
                        if (item.quantity)
                          return acc + (item.price * item.quantity)
                        else
                          return acc + item.price
                      }
                    }, 0) || 0) +
                    meetingRoom.business_lounge_pricings?.reduce((acc, item) => {return acc + item.price}, 0)}
                  </span>
                </div>
              </div>
            </div>
            {mode === 'update' && (
              <div className="col-md-6 pr-3">
                <AtlasTable
                  columns={columnData}
                  rowData={selectedRoomBooking.payments || []}
                  hideSearch={true}
                  hidePagination={true}
                  actionColumnContent={[
                    {
                      name: "view-receipt",
                      onShow: rowData => !rowData.is_payment_gateway,
                      onClick: rowData => window.open(rowData.payment_slip, "_blank"),
                    }
                  ]}
                  renderCheckBoxActions={() => (
                    <div className="at-meeting-room__table-title">
                      <h4>Payment History</h4>
                      <CustomButton
                        className="btn-new btn-new--primary"
                        onClick={() => onChangeRoomBooking(true, "showPaymentModal")}
                        children={
                          <>
                            <AtlasIcon svgHref={"atlas-add-linear"} />
                            New Payment
                          </>
                        } />
                    </div>
                  )}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        {
          (mode === "update" && selectedRoomBooking.status === "Pending Approval") && (
            <>
              <CustomButton 
                className={"btn-new btn-new--secondary"}
                onClick={() => onChangeRoomBooking(true, "showApproveModal")}
              > 
                <AtlasIcon svgHref={'atlas-tick-circle'} style={{width: 20, height: 20, fill: 'white'}} />
                Approve
              </CustomButton>
              <CustomButton 
                className={"btn-new btn-new--danger"}
                onClick={() => onChangeRoomBooking(true, "showRejectModal")}
              >
                <AtlasIcon svgHref={'atlas-close-circle'} style={{width: 20, height: 20, fill: 'white'}} />
                Reject
              </CustomButton>
            </>
          )
        }
        <CustomButton
          className={`btn-new btn-new--${mode === "create" ? "primary" : "success"}`}
          disabled={_.values(formData).some((val) => !val )}
          children={mode.substring(0, 1).toUpperCase() + mode.substring(1)}
          onClick={() => {
            let payloadData = getPayload();
            if (mode === "create") {
              createRoomBooking(payloadData)
            } else {
              updateRoomBooking(payloadData)
            }
          }}
        />
        <CustomButton
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() => onClose()}
        />
      </div>
    </>
  );
};

export default MeetingRoomForm;
