import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      myExamData: [],
      myExampage: null,
      myCurrentExam: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeMyExamHOC = (val, context) => this.setState({ [context]: val });

    getMyExam = () =>
      Get(`/exams`, this.getMyExamSuccess, this.getMyExamError, this.load);
    getMyExamSuccess = (payload) =>
      this.setState({ myExamData: payload.exams });
    getMyExamError = (error) => requestError(error);

    getCurrentExam = () =>
      Get(
        `/exams/current`,
        this.getCurrentExamSuccess,
        this.getCurrentExammError,
        this.load,
      );
    getCurrentExamSuccess = (payload) =>
      this.setState({ myCurrentExam: payload });
    getCurrentExammError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadExam={this.state.loading}
            myExamData={this.state.myExamData}
            myExampage={this.state.myExampage}
            myCurrentExam={this.state.myCurrentExam}
            getMyExam={this.getMyExam}
            getCurrentExam={this.getCurrentExam}
            onChangeMyExamHOC={this.onChangeMyExamHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
