import React, { useState } from 'react'
import _ from 'lodash'

import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import ModalDialog from "components/Modal/ModalDialog";
import CustomUpload from "components/Input/upload";

const ImportTranslationList = props => {
  const [formData, setFormData] = useState({
    module: "",
    translation_list_file: null,
    overwrite_translation: false,
  })

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0) {
      setFormData({ ...formData, translation_list_file: fileItems[0] })
    } else {
      setFormData({ ...formData, translation_list_file: null })
    }
  }

  const onToggleSubmit = () => {
    const submitData = new FormData()
    submitData.append('module_id', formData.module?.value)
    submitData.append('file', formData.translation_list_file, formData.translation_list_file.name)
    submitData.append('overwrite_translation', formData.overwrite_translation)
    props.importTranslationList(submitData)
  }

  return (
    <ModalDialog
      title="Import Translation List"
      onLoad={props.onLoadLocalised}
      onClose={() => props.onChangeLocalisedHOC(false, "showImportTranslation")}
      children={
        <div className='grid-control'>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Module</h2>
            <CustomSelect
              required={true}
              emptyOption={true}
              placeholder='Module'
              selectItems={props.modules}
              currentlySelected={formData.module}
              updateSelect={(val) => setFormData({ ...formData, module: val })} />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Translation List File</h2>
            <CustomUpload
              multiple={false}
              accepts={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
              className="at-localised-center__upload-list"
              labelText={'CSV, XLS, XLSX'}
              returnRawFiles={true}
              imageUrl={formData.translation_list_file}
              uploadImage={(files) => onChangeFile(files)}
            />
          </section>
          <section className='grid-full-col'>
            <CustomCheckbox
              content={
                <div className='d-flex flex-column'>
                  <span className='fw-500'>Overwrite Translation</span>
                  <span>If the translation already exists, it will be overwrite with the one in the uploaded file.</span>
                </div>
              }
              checked={formData.overwrite_translation}
              onChangeCheckboxValue={event => setFormData({ ...formData, overwrite_translation: event.target.checked })} />
          </section>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--primary"
            disabled={_.values(formData).some(val => val === "" || val === null)}
            onClick={() => onToggleSubmit()}>
            Submit
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeLocalisedHOC(false, "showImportTranslation")}>
            Cancel
          </button>
        </div>
      } />
  )
}

export default ImportTranslationList