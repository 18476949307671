import React from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Pending Payment", colorName: "Grey" },
  { value: "Confirmed", colorName: "Green" },
  { value: "In Use", colorName: "Yellow" },
  { value: "Completed", colorName: "Cyan" },
  { value: "Pending Approval", colorName: "Yellow" },
  { value: "Rejected", colorName: "Red" },
  { value: "Cancelled", colorName: "Red" },
];

export const MyBookingColumnData = [
  {
    header: "Meeting Room",
    accessor: "roomName",
    searchFlag: "roomName",
    renderColumn: (rowData) => {
      return (
        <div className="d-flex align-items-center g-2 flex-wrap">
          <img
            alt={`meeting-room-image-${rowData.id}`}
            src={rowData.meeting_room_image_url}
            style={{ width: 60, height: 60, minWidth: 60, borderRadius: 8 }}
          />
          <div className="d-flex flex-column at-table-col__meeting-room-info">
            <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>
              {rowData.meeting_room_name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                columnGap: 12,
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <div className="at-card--meeting__detail">
                <AtlasIcon
                  svgHref={"atlas-location"}
                  style={{ marginRight: 2 }}
                />
                {rowData.meeting_room_location}
              </div>
              <div className="at-card--meeting__detail">
                <AtlasIcon svgHref={"atlas-user"} style={{ marginRight: 2 }} />
                {rowData.meeting_room_capacity} seats
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    header: "Event Subject",
    accessor: "event_subject",
    searchFlag: "event_subject",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_left",
    customClass: "at-column-responsive_left",
    renderColumn: (rowData) => {
      return (
        <>
          <div className="at-mybooking-subject-col">
            <span style={{fontSize: 14, fontWeight: 500}}>{rowData.event_subject}</span>
            {
              (rowData.meeting_room_type_id === 2 && rowData.purpose) && (
                <div className="at-booking-subject-purpose-row">
                  <AtlasIcon
                    svgHref={"atlas-message-2"}
                    style={{marginRight: 10 }}
                  />
                  <span>{rowData.purpose}</span>
                </div>
              )
            }
          </div>
          <div className="at-mobile-subject-col">
            <span style={{fontSize: 14, fontWeight: 500}}>{rowData.event_subject}</span>
          </div>
        </>
      );
    }
  },
  {
    header: "Date Time",
    accessor: "date",
    searchFlag: "date",
    columnStyle: { width: "60%" },
    renderColumn: (rowData) => (
      <div className="d-flex flex-column">
        <span>{rowData.date}</span>
        <span>
          {rowData.startTime} - {rowData.endTime}
        </span>
      </div>
    ),
  },
  {
    header: "Room Type",
    accessor: "meeting_room_type_id",
    searchFlag: "meeting_room_type_id",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      return (
        <div
          className="at-status_badge"
          style={{
            ...getColorBadge(
              rowData?.meeting_room_type_id === 2 
                ? "Orange" 
                : "Blue"
            ),
          }}
        >
          {rowData?.meeting_room_type ?? "N/A"}
        </div>
      );
    },
  },
  {
    header: "Status",
    accessor: "status",
    searchFlag: "status",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <>
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData?.status ?? "N/A"}
          </div>
          {
            rowData?.status === "Rejected" && (
              <div className="at-booking-reject-box">
                {rowData?.rejection_reason ? rowData.rejection_reason : "N/A" } 
              </div>
            )
          }
        </>
      );
    },
  },
];
