import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { storeLastView } from "actions/lastView";
import { Get, GetFile } from "utils/axios";
import { requestError } from "utils/requestHandler";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const formattedMonth =
  currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
const last_day = new Date(currentYear, currentMonth, 0).getDate();

const searchParams = [
  {
    label: "Date From",
    value: "start_date",
    type: "date",
    param: `01-${formattedMonth}-${currentYear}`,
    format: "DD-MM-YYYY",
  },
  {
    label: "Date To",
    value: "end_date",
    type: "date",
    param: `${last_day}-${formattedMonth}-${currentYear}`,
    format: "DD-MM-YYYY",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      showSearchContent: false,
      loading: false,
      rejected_amla: [],
      claim_submitted_count: 0,
      claim_rejected_count: 0,
      buyer_count: 0,
      owner_count: 0,
    };

    load = (param) => this.setState({ loading: param });
    onChangeAmlaReportHOC = (val, context) => this.setState({ [context]: val });

    getAmlaReport = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");
      Get(
        `/admin/analytics/amla_report?${tmp}`,
        this.getAmlaReportSuccess,
        this.getAmlaReportError,
        this.load,
      );
    };
    getAmlaReportSuccess = (payload) => {
      this.setState({
        claim_submitted_count: payload.claim_count,
        claim_rejected_count: payload.rejected_amla.claim_count,
        buyer_count: payload.rejected_amla.buyer_count,
        owner_count: payload.rejected_amla.owner_count,
        rejected_amla: payload.rejected_amla.data,
      });
    };
    getAmlaReportError = (error) => requestError(error);

    downloadReport = () => {
      let start_date = _.find(this.state.searchParams, { value: "start_date" });
      let end_date = _.find(this.state.searchParams, { value: "end_date" });
      GetFile(
        `/admin/analytics/amla_report?start_date=${start_date.param}&end_date=${end_date.param}&to_download=true`,
        `AMLA Report (${start_date.param} - ${end_date.param})`,
        () => {},
        this.downloadReportError,
        this.load,
      );
    };
    downloadReportError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            getAmlaReport={this.getAmlaReport}
            downloadReport={this.downloadReport}
            onChangeAmlaReportHOC={this.onChangeAmlaReportHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
