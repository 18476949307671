import React, { Component } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import NameCardModal from "./components/nameCardDialog";
import ProductTable from "./components/Table";

import permissionsChecker from "utils/permissionsChecker";
import ProductCatalogHOC from "./actions";

import "../index.scss";

class ProductCatalog extends Component {
  componentDidMount = () => this.props.getProductCatalog(1, "");

  renderCardContent = () => {
    return (
      <ProductTable
        history={this.props.history}
        onClickClose={() => this.props.history.goBack()}
        cardType={"product"}
        rowData={this.props.products.data || []}
        meta={this.props.products.meta && this.props.products.meta}
        onClickEdit={(val) => {
          this.props.onChangeProductCatalogHOC(val, "selectedProduct");
          this.props.onChangeProductCatalogHOC(true, "showNameCardModal");
        }}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeProductCatalogHOC(val, "searchParams")
        }
        getListAPI={this.props.getProductCatalog}
        totalPages={this.props.productPage}
        cartItem={this.props.data.myCartReducer.cartItem}
        onChangeProductCatalogHOC={this.props.onChangeProductCatalogHOC}
      />
    );
  };

  render = () => {
    const { showNameCardModal, onLoadProductCatalog } = this.props;
    const { can_create } = permissionsChecker("Atlas Store", this.props.data);

    return (
      <>
        {this.renderCardContent()}
        {showNameCardModal && (
          <NameCardModal
            {...this.props}
            can_create={can_create}
            data={this.props.data}
          />
        )}
        {onLoadProductCatalog && <LoadingModal />}
      </>
    );
  };
}

export default ProductCatalogHOC(ProductCatalog);
