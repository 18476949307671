import React, { Component } from "react";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post } from "utils/axios";

const CreateHOC = (WrappedComponent) => {
  class LeadsWrappedComponent extends Component {
    state = {
      loading: false,
      submittedData: {},
    };

    onChangeHOC = (value, context) => {
      this.setState({
        [context]: value,
      });
    };
    load = (param) => this.setState({ loading: param });

    // index page
    createLeads = (data) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let isAdmin = ["Admin", "Super Admin"].indexOf(role) > -1;

      Post(
        `${isAdmin ? "/admin" : ""}/leads`,
        data,
        this.createLeadsSuccess,
        this.createLeadsError,
        this.load,
      );
    };
    createLeadsSuccess = (payload) => {
      requestSuccess("The Lead is created successfully");
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.onChangeLeadsHOC(false, "showCreateModal");
      this.props.getSelectedLead(payload.id);
      this.props.getLeads(currentPage, searchParams);
    };
    createLeadsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onCreateLoading={this.state.loading}
            onChangeHOC={this.onChangeHOC}
            createLeads={this.createLeads}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(LeadsWrappedComponent);
};

export default CreateHOC;
