import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { getCountryDictionary, getAllDictionaryData } from "actions/dictionary";
import { storeLastView } from "actions/lastView";

import { project as ProjectDictionary } from "dictionary/index";
import { Get } from "utils/axios";
import isEmptyValue from "utils/isEmpty";
import { refreshToken } from "actions/login";
import { requestError } from "utils/requestHandler";

const searchParams = [
  {
    label: "Project Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Internal Name",
    value: "internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Developer",
    value: "developer_cont",
    type: "input",
    param: "",
  },
  {
    label: "Country",
    value: "country_id_eq",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "City / Area",
    value: "township_cont",
    type: "input",
    param: "",
  },
  {
    label: "Sub Area",
    value: "sub_area_cont",
    type: "input",
    param: "",
  },
  {
    label: "Slug",
    value: "slug_cont",
    type: "input",
    param: "",
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "select",
    placeholder: "Please choose a country first.",
    param: "",
    options: [],
  },
  {
    label: "Measurement Unit",
    value: "measurement_id_eq",
    type: "select",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Square Feet" },
      { value: "2", label: "Square Meter" },
    ],
  },
  {
    label: "Show Exclusive Projects Only",
    value: "is_exclusive_eq",
    type: "radio",
    param: "",
    options: [
      { value: "true", label: "Yes" },
      { value: "", label: "No" },
    ],
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    placeholder: "Please choose a country first.",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Active" },
      { value: "2", label: "Inactive" },
      { value: "3", label: "Expired" },
      { value: "4", label: "Deleted" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      projects: [],
      selectedProject: {},
      loading: false,

      showNewProjectDialog: false,
      showDetailsDialog: false,
      showEditDetailsDialog: false,
      showConfirmationModal: false,

      searchParams: searchParams,
      projectManagementPage: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeProjectManagementHOC = (val, context) =>
      this.setState({ [context]: val });

    updateSelectedProject = (context, val) => {
      let tmp = _.cloneDeep(this.state.selectedProject);
      tmp[context] = val;

      return this.setState({ selectedProject: tmp });
    };

    getAllProjects = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/projects?${search}page=${page}`,
        this.getAllProjectsSuccess,
        this.getAllProjectsError,
        this.load,
      );
    };
    getAllProjectsSuccess = (payload) => {
      const { countries } = this.props.data.dictionaryReducer;
      let tmpData = [];
      let tmpTotalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach((item) => {
          let dataCountry = _.find(countries, { id: item.country_id });
          let dataProjectTitle = _.find(ProjectDictionary.title, {
            id: item.project_title_id,
          });
          let dataStatus = _.find(ProjectDictionary.status, {
            id: item.status_id,
          });
          let tmp = {
            ...item,
            sub_area: !isEmptyValue(item.sub_area) ? item.sub_area : "",
            country: dataCountry ? dataCountry.name : "",
            project_title: dataProjectTitle ? dataProjectTitle.name : "",
            status: dataStatus ? dataStatus.name : "",
          };
          tmpData.push(tmp);
        });
      }
      payload.data = tmpData;
      return this.setState({
        projects: payload,
        projectManagementPage: tmpTotalPages,
      });
    };
    getAllProjectsError = (error) => requestError(error);

    getProject = (id) =>
      Get(
        `/admin/projects/${id}`,
        this.getProjectSuccess,
        this.getProjectError,
        this.load,
      );
    getProjectSuccess = (payload) => {
      let tmp = {};
      const {
        project_title_id,
        type_id,
        tenure_type_id,
        status_id,
        country_id,
        state_id,
        key_features,
        project_features,
        sub_area,
      } = payload;

      const { countries = [] } = this.props.data.dictionaryReducer;

      let dataProjectTitle = _.find(ProjectDictionary.title, {
        id: project_title_id,
      });
      let dataProjectType = _.find(ProjectDictionary.type, { id: type_id });
      let dataTenureType = _.find(ProjectDictionary.tenure, {
        id: tenure_type_id,
      });
      let dataStatus = _.find(ProjectDictionary.status, { id: status_id });
      let dataCountry = _.find(countries, { id: country_id });
      let dataState = dataCountry
        ? _.find(dataCountry.states, { id: state_id })
        : {};

      tmp = {
        ...payload,
        project_units: payload.project_units || [],
        completion_date: Moment(payload.completion_date, "DD MMM YYYY").format(
          "YYYY-MM-DD",
        ),
        project_title: dataProjectTitle ? dataProjectTitle.name : "",
        project_type: dataProjectType ? dataProjectType.name : "",
        tenure_type: dataTenureType ? dataTenureType.name : "",
        status: dataStatus ? dataStatus.name : "",
        country: dataCountry ? dataCountry.name : "",
        state: dataState ? dataState.name : "",
        sub_area: !isEmptyValue(sub_area) ? sub_area : "",
        key_features: key_features || [],
        project_features: project_features || [],
        background_image: payload.background_image_url,
        overview_image: payload.overview_image_url,
        project_features_image: payload.project_features_image_url,
        map_image: payload.map_image_url,
        hd_map_image: payload.hd_map_image_url,
        project_image: payload.project_image_url,
        display_name_type: payload.show_internal_name_as_display_name
          ? "internal"
          : "project",
        slug: payload.slug || "",
      };

      this.setState({
        selectedProject: tmp,
        showEditDetailsDialog: true,
      });
    };
    getProjectError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          searchParams={this.state.searchParams}
          projects={this.state.projects}
          selectedProject={this.state.selectedProject}
          onLoadProjects={this.state.loading}
          projectManagementPage={this.state.projectManagementPage}
          showNewProjectDialog={this.state.showNewProjectDialog}
          showEditDetailsDialog={this.state.showEditDetailsDialog}
          getAllProjects={this.getAllProjects}
          getProject={this.getProject}
          onChangeProjectManagementHOC={this.onChangeProjectManagementHOC}
          updateSelectedProject={this.updateSelectedProject}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getAllDictionaryData,
    getCountryDictionary,
    refreshToken,
    storeLastView,
  })(WithHOC);
};

export default HOC;
