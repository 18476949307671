import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import ExamEditHOC from "../actions/edit";

const examData = [
  { label: "Agent Name", value: "user_full_name" },
  { label: "Contact Number", value: "user_contact_number" },
  { label: "Email", value: "user_email" },
  { label: "Exam Date", value: "start_date" },
];

const EditExamContent = ({
  onCloseModal,
  selectedExam,
  onLoadEdit,
  updateExamResult,
}) => {
  const [status, toggleStatus] = useState("Fail");

  useEffect(() => {
    toggleStatus(selectedExam.status);
  }, []);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onCloseModal()} />
        <h4 className="at-card__title">{`Edit Exam Result`}</h4>
      </div>
      <Card style={{ padding: 15 }}>
        <div className="grid-control mb-20">
          {examData.map((item, index) => {
            return (
              <section className="grid-half-col" key={index}>
                <h2 className="at-form-input__title">{item.label}</h2>
                <p>{`${selectedExam[item.value]}`}</p>
              </section>
            );
          })}
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Exam status</h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={status}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => toggleStatus(val)}
              options={[
                { value: "Pass", label: "Pass" },
                { value: "Fail", label: "Fail" },
              ]}
            />
          </section>
        </div>
        <div className="d-flex">
          <AtlasButton
            className="btn-new btn-new--success"
            children={"Update"}
            onClick={() => {
              updateExamResult(selectedExam.id, {
                status_id: status === "Fail" ? 2 : 1,
              });
            }}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onCloseModal()}
          >
            Close
          </button>
        </div>
      </Card>
      {onLoadEdit && <LoadingModal />}
    </>
  );
};

export default ExamEditHOC(EditExamContent);
