import React from "react";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";

import "stylesheets/containers/subsales-claims/index.scss";

const stampingOptions = [
  {
    value: true,
    label: "Stamping fee by IQI",
  },
  {
    value: false,
    label: "Stamping fee by Agent",
  },
];

const renderPartyCard = ({
  representative_id,
  sub_sale_type_id,
  claim_status,
  can_update,
  owner_stamping_fee_amount,
  owner_total_collection_amount,
  owner_admin_fee_amount,
  owner_extra_collection_amount,
  buyer_total_collection_amount,
  buyer_stamping_fee_amount,
  buyer_admin_fee_amount,
  buyer_extra_collection_amount,
  sales_tax_percentage,
  onChange,
  SSTFormula
}) => {
  return (
    <div className="at-form_field-col-6 at-subsales_claims-agency-fee-cont p-3">
      <div className="d-flex grid_gap-1 mb-3">
        <AtlasIcon
          className="svg-icon-chevron"
          svgHref={
            representative_id === 1 ? "svg-user-landlord" : "svg-user-tenant"
          }
        />
        <h2 className="at-card__title">
          {representative_id === 1 
            ? sub_sale_type_id === 2 ? `Landlord` : `Vendor` 
            : sub_sale_type_id === 2 ? `Tenant` : `Purchaser`
          }
        </h2>
      </div>
      <div className="at-form_fields_cont">
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Stamping Fee (RM)`}</h2>
          <CustomFormInput
            type="number"
            disabled={
              !can_update ||
              (claim_status !== "Draft" && claim_status !== "Rejected")
            }
            value={`${representative_id === 1 ? owner_stamping_fee_amount : buyer_stamping_fee_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_stamping_fee_amount"
                  : "buyer_stamping_fee_amount",
                val,
              )
            }
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`SST amount for total disbursement (RM)`}</h2>
          {representative_id === 1 && (
            <CustomFormInput
              type="number"
              disabled={true}
              value={Math.abs(
                owner_total_collection_amount -
                  owner_total_collection_amount / SSTFormula,
              ).toFixed(2)}
            />
          )}
          {representative_id === 2 && (
            <CustomFormInput
              type="number"
              value={Math.abs(
                buyer_total_collection_amount -
                  buyer_total_collection_amount / SSTFormula,
              ).toFixed(2)}
              disabled={true}
            />
          )}
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Admin Fee (RM)`}</h2>
          <CustomFormInput
            type="number"
            value={`${representative_id === 1 ? owner_admin_fee_amount : buyer_admin_fee_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_admin_fee_amount"
                  : "buyer_admin_fee_amount",
                val,
              )
            }
            disabled={
              !can_update ||
              (claim_status !== "Draft" && claim_status !== "Rejected")
            }
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">{`Extra / Short Collection (RM)`}</h2>
          <CustomFormInput
            type="number"
            value={
              representative_id === 1
                ? owner_extra_collection_amount
                : buyer_extra_collection_amount
            }
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_extra_collection_amount"
                  : "buyer_extra_collection_amount",
                val,
              )
            }
            disabled={
              !can_update ||
              (claim_status !== "Draft" && claim_status !== "Rejected")
            }
          />
        </div>
        <div className="at-form_field-col-12">
          <h2 className="at-form-input__title">
            {`
              Total Collection on disbursement of TA ( inclusive ${sales_tax_percentage}% SST ): `}
          </h2>
          <CustomFormInput
            type="number"
            disabled={
              !can_update ||
              (claim_status !== "Draft" && claim_status !== "Rejected")
            }
            value={`${representative_id === 1 ? owner_total_collection_amount : buyer_total_collection_amount}`}
            onChangeValue={(val) =>
              onChange(
                representative_id === 1
                  ? "owner_total_collection_amount"
                  : "buyer_total_collection_amount",
                val,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

const StampingFee = ({
  selected_claim,
  onChangeUpdateSelectedClaim,
  can_update,
}) => {
  const SSTFormula = (selected_claim?.sales_tax_percentage / 100) + 1;

  const {
    representative_id
  } = selected_claim;

  const renderFooter = ({
    can_update,
    intended_tenure_years,
    stamping_fee_by_company,
    onChange
  }) => {
    return (
      <div className="at-form_field-col-12">
        <CustomRadioGroup
          containerClassName="mb-3"
          disabled={!can_update}
          checkedValue={stamping_fee_by_company}
          options={stampingOptions}
          selectedRadioValue={(val) => onChange("stamping_fee_by_company", val)}
          status={true}
        />
        <div className="col-md-6 p-0 mb-0">
          <h2 className="at-form-input__title">{`Intended Tenure Years`}</h2>
          <CustomFormInput
            type="number"
            value={`${intended_tenure_years}`}
            onChangeValue={(val) => onChange("intended_tenure_years", val)}
            disabled={!can_update}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="at-form_fields_cont">
        {renderPartyCard({
          ...selected_claim,
          isPrimaryClaim: true,
          can_update,
          onChange: onChangeUpdateSelectedClaim,
          representative_id: [null, 3].indexOf(representative_id) > -1 
            ? 1 
            : representative_id,
          SSTFormula: SSTFormula
        })}
        {renderPartyCard({
          ...selected_claim,
          isPrimaryClaim: true,
          can_update,
          onChange: onChangeUpdateSelectedClaim,
          representative_id: [null, 3].indexOf(representative_id) > -1 
            ? 2 
            : representative_id === 2
              ? 1
              : 2,
          SSTFormula: SSTFormula
        })}
        {renderFooter({
          ...selected_claim,
          can_update,
          onChange: onChangeUpdateSelectedClaim
        })}
      </div>
    </>
  );
};

export default StampingFee;