import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  FormControlLabel,
  SnackbarContent,
  FormLabel,
  Grid,
} from "@material-ui/core";

import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";

import ImportExcelHOC from "../actions/importExcel";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
);

class ImportExcelModal extends Component {
  state = {
    selectedBranch: [],
    excelFile: null,
    excelFileName: "",
  };

  render = () => {
    const { selectedBranch, excelFile, excelFileName } = this.state;
    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => this.props.onClose()} />
          <h4 className="at-card__title">Import Excel</h4>
        </div>
        <FormLabel>Branch Selection</FormLabel>
        <Grid container spacing={16}>
          {this.props.data.branchReducer.branch &&
            this.props.data.branchReducer.branch.length > 0 &&
            this.props.data.branchReducer.branch.map((item) => {
              return (
                <Grid key={item.id} item md={3} sm={6} xs={12}>
                  <CustomCheckbox
                    checked={this.state.selectedBranch.indexOf(item.id) > -1}
                    content={item.name}
                    onChangeCheckboxValue={() => {
                      let tmp = _.cloneDeep(this.state.selectedBranch);
                      if (tmp.indexOf(item.id) > -1) {
                        tmp.splice(tmp.indexOf(item.id), 1);
                      } else {
                        tmp.push(item.id);
                      }
                      this.setState({ selectedBranch: tmp });
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
        <FilePond
          acceptedFileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
          ]}
          labelIdle={"Please upload your excel."}
          allowMultiple={false}
          fullWidth
          maxFiles={1}
          onupdatefiles={(fileItems) => {
            if (fileItems.length > 0 && fileItems[0].file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                this.setState({
                  excelFile: e.target.result,
                  excelFileName: fileItems[0].file.name,
                });
              };
              reader.readAsDataURL(fileItems[0].file);
            } else {
              this.setState({ excelFile: {}, excelFileName: "" });
            }
          }}
        />
        <SnackbarContent
          style={{ maxWidth: "100%", marginBottom: 10 }}
          message={
            "Please select at least one branch before submitting the excel file."
          }
        />
        <div className="d-flex mt-3 g-3">
          <button
            type={"button"}
            disabled={selectedBranch.length < 1 || !excelFile}
            className="btn-new btn-new--success"
            onClick={() =>
              this.props.importExcel(selectedBranch, excelFile, excelFileName)
            }
          >
            Submit
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onClose()}
          >
            Close
          </button>
        </div>
        {this.props.onLoadImport && <LoadingModal />}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
  ImportExcelHOC,
  connect(mapStateToProps),
)(ImportExcelModal);
