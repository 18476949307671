import { MenuItem } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import AtlasButton from "components/Button";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasSelect from "components/Select/new";
import AlertBox from "components/AlertBox";
import FormInputs from "components/WelcomeModal/components/formInputs";
import CustomFormInput from "./formInput";

import { getTranslation } from "../assets";

const boardRegistrationType = [
  { id: "none", label: "Select your license type" },
  { id: 0, label: "None" },
  { id: 1, label: "REN" },
  { id: 2, label: "PEA" },
  { id: 3, label: "REA" },
  { id: 4, label: "PV" },
  { id: 5, label: "VE" },
];

const ContactDetails = ({
  nricVerificationMessage,
  onLoadNRICAvailability,
  nricAvailability,
  onChangeNRIC,
  selectedDirectLeader,
  confirmedEmailVerification,
  confirmedEmail,
  confirmedEmailVerificationMessage,
  mobileAvailabilityMessage,
  emailAvailabilityMessage,
  emailAvailability,
  mobileAvailability,
  onLoadEmailAvailability,
  onLoadMobileAvailability,
  onChangeMobileContact,
  onChangeEmailAddress,
  onChangeConfirmedEmail,
  onChangeLicenseType,
  onChangeCountryId,
  firstNameVerificationErrorMessage,
  lastNameVerificationErrorMessage,
  nameSequence,
  onChangeField,
  onChangeFirstName,
  onChangeLastName,
  onClickBackStep,
  onClickNextStep,
  countryOptions,
  branch_options,
  createNewAgent,
  getBranchInfo,
  onChangeHOC,
}) => {
  const {
    country_id,
    agent_registration_attributes,
    is_signed_nda_form,
    first_name,
    last_name,
    mobile_contact_number,
    board_registration_type_id,
    board_registration_number,
    branch_id,
    is_japan_team,
    is_malaysia_agent
  } = createNewAgent;
  const language = is_japan_team ? "jp" : "en";

  const [showAirlineModal, onToggleAirlineModal] = useState(false);

  const tempCountryCode = useMemo(() => {
    const temp = countryOptions.find((country) => country.id === country_id);
    return temp ? temp.code : "";
  }, [country_id]);

  useEffect(() => {
    if (is_malaysia_agent) {
      onChangeCountryId(1);
      getBranchInfo(1);
    } else if (is_japan_team) {
      onChangeCountryId(14);
      getBranchInfo(14);
    }
  }, [createNewAgent.is_malaysia_agent]);

  useEffect(() => {
    if (is_japan_team && branch_options.length > 0) {
      onChangeField(branch_options?.[0].id, 'branch_id');
    }
  }, [branch_options])

  const isAssociatedAgent = createNewAgent.is_malaysia_agent
    ? board_registration_type_id === "none" ||
      (board_registration_type_id !== 0 && board_registration_number === "")
    : false;

  const proceedChecking =
    !is_signed_nda_form ||
    !agent_registration_attributes.notify_resignation ||
    !mobileAvailability ||
    !emailAvailability ||
    first_name === "" ||
    last_name === "" ||
    isAssociatedAgent ||
    branch_id === 0 ||
    firstNameVerificationErrorMessage !== "" ||
    lastNameVerificationErrorMessage !== "" ||
    !confirmedEmailVerification ||
    onLoadEmailAvailability ||
    onLoadMobileAvailability ||
    !nricAvailability ||
    (!nameSequence && !is_japan_team);

  const renderBoardRegistrationPrefix = () => {
    const prefixes = ["REN", "PEA", "E", "PV", "V"];
    return prefixes[board_registration_type_id - 1] || "";
  };

  const onChange = (val, id) => {
    if (id === "notify_resignation") {
      onChangeField(
        {
          ...createNewAgent.agent_registration_attributes,
          [id]: val,
        },
        "agent_registration_attributes"
      );
    } else if (id === "nameSequence") {
      onChangeHOC(val, id);
    } else {
      onChangeField(val, id);
    }
  };

  const inputConfig = [
    {
      type: "custom-form-input",
      label: createNewAgent.is_malaysia_agent
        ? `First Name (Based on NRIC)`
        : getTranslation("agent.signup.first_name", language),
      id: "first_name",
      onChange: ({ val, id }) => onChangeFirstName(val),
      errorMessage: firstNameVerificationErrorMessage,
      hidden: is_japan_team,
    },
    {
      type: "custom-form-input",
      label: createNewAgent.is_malaysia_agent
        ? `Last Name (Based on NRIC)`
        : getTranslation("agent.signup.last_name", language),
      id: "last_name",
      onChange: ({ val, id }) => onChangeLastName(val),
      errorMessage: lastNameVerificationErrorMessage,
      hidden: !is_japan_team
    },
    {
      type: "custom-form-input",
      label: createNewAgent.is_malaysia_agent
        ? `First Name (Based on NRIC)`
        : getTranslation("agent.signup.first_name", language),
      id: "first_name",
      onChange: ({ val, id }) => onChangeFirstName(val),
      errorMessage: firstNameVerificationErrorMessage,
      hidden: !is_japan_team
    },
    {
      type: "custom-form-input",
      label: createNewAgent.is_malaysia_agent
        ? `Last Name (Based on NRIC)`
        : getTranslation("agent.signup.last_name", language),
      id: "last_name",
      onChange: ({ val, id }) => onChangeLastName(val),
      errorMessage: lastNameVerificationErrorMessage,
      hidden: is_japan_team
    },
    {
      type: "custom-form-input",
      label: '氏名（ローマ字）',
      id: "furigana",
      hidden: !is_japan_team,
    },
    {
      type: "select",
      label: createNewAgent.is_malaysia_agent
        ? `Please choose your full name as per NRIC`
        : `Please choose your full name as per Identification document`,
      id: "nameSequence",
      options: [
        { id: "F+S", value: `${first_name} ${last_name}` },
        { id: "S+F", value: `${last_name} ${first_name}` },
      ],
      hidden: !(first_name && last_name) || is_japan_team,
    },
    {
      type: "custom-form-input",
      label: getTranslation("agent.signup.email", language),
      id: "email",
      onChange: ({ val }) => onChangeEmailAddress(val.toLowerCase()),
      isLoading: onLoadEmailAvailability,
      successMessage: emailAvailability && emailAvailabilityMessage,
      errorMessage: !emailAvailability && emailAvailabilityMessage,
    },
    {
      type: "custom-form-input",
      label: getTranslation("agent.signup.confirm_email", language),
      id: "confirmedEmail",
      onChange: ({ val }) => onChangeConfirmedEmail(val.toLowerCase()),
      successMessage:
        confirmedEmail.length > 0 &&
        confirmedEmailVerification &&
        confirmedEmailVerificationMessage,
      errorMessage:
        confirmedEmail.length > 0 &&
        !confirmedEmailVerification &&
        confirmedEmailVerificationMessage,
    },
    {
      type: "custom-output",
      label: getTranslation("agent.signup.mobile_no", language),
      content: () => (
        <>
          <div className={"form-row"}>
            <div className="col-12 fs-2 mb-1 px-1">
              {getTranslation("agent.signup.mobile_no_helptext", language)}
            </div>
            <div className={"col-sm-4 col-12 mb-sm-0 mb-2"}>
              <AtlasSelect
                value={createNewAgent.is_malaysia_agent ? 1 : country_id}
                onChange={(e) => {
                  onChangeCountryId(e.target.value);
                  getBranchInfo(e.target.value);
                }}
                disabled={createNewAgent.is_malaysia_agent}
              >
                {countryOptions
                  .filter((option) =>
                    createNewAgent.is_malaysia_agent
                      ? option.id === 1
                      : option.id !== 1
                  )
                  .map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.label}
                    </MenuItem>
                  ))}
              </AtlasSelect>
            </div>
            <div className={"col-sm-8 col-12 mb-0"}>
              <CustomFormInput
                type={"number"}
                placeholder={getTranslation("agent.signup.mobile_no_placeholder", language)}
                value={mobile_contact_number}
                onChange={(val) => {
                  onChangeMobileContact(val);
                }}
                isLoading={onLoadMobileAvailability}
                successMessage={mobileAvailability && mobileAvailabilityMessage}
                errorMessage={!mobileAvailability && mobileAvailabilityMessage}
              />
            </div>
          </div>

          {country_id && mobile_contact_number && (
            <AlertBox
              containerClassName={"mt-2"}
              mode={"warning"}
              description={
                <>
                  {getTranslation('agent.signup.your_mobile_number', language)}{" "}
                  <span className="register-mobile-no-alert-box">
                    {tempCountryCode}
                    {mobile_contact_number}
                  </span>{" "}
                  {getTranslation('agent.signup.your_mobile_number2', language)}
                </>
              }
              disabledClose
            />
          )}
        </>
      ),
    },
    {
      type: "select",
      label: getTranslation("agent.sigup.branch", language),
      id: "branch_id",
      disabled: is_japan_team,
      options: branch_options.map((option) => {
        return { ...option, value: option.name };
      }),
    },
    {
      type: "custom-form-input",
      label: createNewAgent.is_malaysia_agent
        ? `Identification Card number`
        : getTranslation("agent.signup.identity_number", language),
      id: "identification_number",
      isLoading: onLoadNRICAvailability,
      onChange: ({ val }) => onChangeNRIC(val.replace(/[^$0-9a-zA-Z]/g, "")),
      successMessage:
        nricVerificationMessage.length > 0 &&
        nricAvailability &&
        nricVerificationMessage,
      errorMessage:
        nricVerificationMessage.length > 0 &&
        !nricAvailability &&
        nricVerificationMessage,
    },
    {
      type: "custom-output",
      label: `License No.`,
      hidden: !is_malaysia_agent || is_japan_team,
      content: () => (
        <>
          <div className={"form-row"}>
            <div className={"col-4"}>
              <AtlasSelect
                value={board_registration_type_id}
                onChange={(e) => onChangeLicenseType(e.target.value)}
              >
                {boardRegistrationType.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    disabled={type.id === "none"}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </AtlasSelect>
            </div>
            <div className={"col-8"}>
              <CustomFormInput
                type={"number"}
                maxLength={
                  board_registration_type_id === 1
                    ? 5
                    : board_registration_type_id === 2
                      ? 4
                      : board_registration_type_id === 3
                        ? 4
                        : board_registration_type_id === 4
                          ? 4
                          : board_registration_type_id === 5
                            ? 4
                            : 0
                }
                prefix={renderBoardRegistrationPrefix()}
                value={board_registration_number}
                onChange={(val) =>
                  onChangeField(val, "board_registration_number")
                }
                disabled={
                  board_registration_type_id === 0 ||
                  board_registration_type_id === "none"
                }
                isLoading={onLoadMobileAvailability}
              />
            </div>
          </div>
          <small className={"form-text mt-2"}>
            Please select your license type and enter your license number.
            <br />
            Example: REN12345, REN is the license type, and 12345 is the license
            number.
            <br />
            Kindly choose 'None' as the license type if you currently don't have
            one or are unsure about your license number.
          </small>
        </>
      ),
    },
    {
      type: "checkbox",
      label: (
        <>
          {getTranslation('agent.signup.terms_section1', language)}&nbsp;
          <Link
            to={"/nda-form"}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#000" }}
          >
            NDA
          </Link>
          &nbsp;{getTranslation('agent.signup.terms_section2', language)}
        </>
      ),
      id: "is_signed_nda_form",
    },
  ];

  return (
    <div className={"container pt-4"} style={{ maxWidth: 768 }}>
      <div className={"container pt-4 px-0"}>
        <FormInputs
          payload={{
            ...createNewAgent,
            confirmedEmail: confirmedEmail,
            nameSequence: nameSequence,
          }}
          inputConfig={inputConfig}
          onChange={onChange}
        />
        <FormInputs
          payload={createNewAgent.agent_registration_attributes}
          inputConfig={[
            {
              type: "checkbox",
              label: getTranslation("agent.signup.terms2", language),
              labelClassname: 'align-items-start',
              id: "notify_resignation",
            },
          ]}
          onChange={onChange}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className={"g-3 mt-4"}
        >
          <AtlasButton
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => onClickBackStep()}
          >
            {getTranslation("back", language)}
          </AtlasButton>
          <AtlasButton
            className={"btn-new btn-new--primary"}
            onClick={() => onClickNextStep()}
            disabled={proceedChecking}
          >
            {getTranslation("next", language)}
          </AtlasButton>
        </div>
      </div>
      <ConfirmationModal
        open={showAirlineModal}
        title={"Confirmation"}
        message={
          <>
            <p>
              This Recruitment Campaign is only eligible for people who are able
              to show employment proof of an airline industry company.
            </p>
            <p className={"mt-10"}>
              Choose yes if you are/were an airline industry employee.
            </p>
          </>
        }
        positiveAction={() => {
          onChangeField(
            {
              ...createNewAgent.agent_registration_attributes,
              via_recruitment_campaign: true,
            },
            "agent_registration_attributes"
          );
          onToggleAirlineModal(false);
        }}
        negativeAction={() => onToggleAirlineModal(false)}
      />
    </div>
  );
};

export default ContactDetails;
