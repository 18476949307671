import React, { useState, useMemo, useCallback } from "react";
import { PulseLoader } from "react-spinners";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import CustomUpload from "components/Upload";
import CustomButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import CustomInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";

import AgentSearchHOC from "actions/agentSearch";
import isEmptyValue from "utils/isEmpty";

import "stylesheets/containers/admin/global-network-management/new.scss";

const VisibleOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const CreateContent = ({
  agentList,
  onLoadGlobalNetwork,
  onLoadAgent,

  onClose,
  onChangeAgentHOC,
  createBranch,
  getAgentsBySearch,
}) => {
  const [createForm, onChangeCreateForm] = useState({
    name: "",
    flag_image: null,
    user: null,
    code: "",
    language: "",
    language_code: "",
    position: "",
    to_show: "",
    reference_id: "",
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createForm);
    temp[context] = val;

    onChangeCreateForm(temp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const onDisableSubmit = useMemo(() => {
    const Obj = Object.entries(createForm);

    return Obj.some(
      ([key, value]) =>
        ["reference_id", "position", "language", "language_code", "user"].indexOf(
          key,
        ) === -1 && isEmptyValue(value),
    );
  }, [createForm]);

  return (
    <ModalDialog
      title={"New Global Network"}
      onLoad={onLoadGlobalNetwork}
      onClose={onClose}
      children={
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={16}>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title" required>
                  Country Code
                </h2>
                <CustomInput
                  value={createForm.code}
                  required={true}
                  onChangeValue={(val) => onChangeField(val, "code")}
                  placeholder={"Enter code"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">ERP ID</h2>
                <CustomInput
                  value={createForm.reference_id}
                  required={false}
                  onChangeValue={(val) => onChangeField(val, "reference_id")}
                  placeholder={"Enter ERP ID"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title" required>
                  Country Name
                </h2>
                <CustomInput
                  value={createForm.name}
                  required={true}
                  onChangeValue={(val) => onChangeField(val, "name")}
                  placeholder={"Select Country name"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item mb-3">
                <h2 className="at-form-input__title">
                  <span className={"mr-10"}>Person in charge</span>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </h2>
                <CustomTypeahead
                  disabled={onLoadAgent}
                  typeaheadId={"internal_agent"}
                  options={agentList ? agentList : []}
                  selectedValue={createForm.user ? [createForm.user] : []}
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    onChangeTypeaheadSearch(val);
                    onChangeField(null, "user");
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && onChangeField(val[0], "user")
                  }
                  onClear={() => onChangeField(null, "user")}
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                  showClearButton={true}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Language</h2>
                <CustomInput
                  value={createForm.language}
                  onChangeValue={(val) => onChangeField(val, "language")}
                  placeholder={"Enter language"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Language Code</h2>
                <CustomInput
                  value={createForm.language_code}
                  onChangeValue={(val) => onChangeField(val, "language_code")}
                  placeholder={"Enter language code"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Position</h2>
                <CustomInput
                  type="number"
                  value={createForm.position}
                  onChangeValue={(val) => onChangeField(val, "position")}
                  placeholder={"Enter position"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Visible</h2>
                <AtlasRadioGroup
                  required={false}
                  disabled={false}
                  horizontal={true}
                  checkedValue={createForm.to_show}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) => onChangeField(val, "to_show")}
                  options={VisibleOptions}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Flag Image</h2>
                <CustomUpload
                  imageUrl={createForm.flag_image}
                  uploadImage={(val) => onChangeField(val, "flag_image")}
                />
              </section>
            </Grid>
          </Grid>
        </form>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <CustomButton
            className={"btn-new btn-new--primary"}
            disabled={onDisableSubmit}
            onClick={() =>
              createBranch({
                name: createForm.name,
                flag_image: createForm.flag_image,
                user_id: createForm.user?.id ?? null,
                language: createForm.language,
                language_code: createForm.language_code,
                position: createForm.position,
                to_show: createForm.to_show,
                reference_id: createForm.reference_id,
              })
            }
            children={"Submit"}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      } />
  );
};

export default AgentSearchHOC(CreateContent);
