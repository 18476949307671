import React, { useState } from "react";
import { Typography, Card, CardContent } from "@material-ui/core";
import { PulseLoader } from "react-spinners";
import _ from "lodash";
import { compose } from "redux";

import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";

import InternalAgentHOC from "./action";
import AgentSearchHOC from "actions/agentSearch";

const AssignInternalAgent = ({
  data,
  agentList,
  selectedTitle,
  selectedPartyId,
  searchedAgents,
  searchValue,
  selected_form,
  assignParty,
  getInternalAgent,
  onLoadParty,
  onLoadInternalAgents,
  onLoadAgent,

  onChangePartyHOC,
  onChangeInternalHOC,
  getAgentsBySearch,
  onChangeAgentHOC
}) => {
  const [selectedAgent, onSelectAgent] = useState(null)
  const { role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const onHandleTypeaheadSearch = (val) => {
    onSelectAgent(null)
    return val.length > 0
      ? getAgentsBySearch(val)
      : onChangeAgentHOC([], "agentList");
  };

  const onChangeTypeaheadSearch = _.debounce(
    (val) => onHandleTypeaheadSearch(val),
    600,
  );

  return (
    <>
      <ModalDialog
        containerClasses={{ paperScrollPaper: 'at-modal_dialog-overflow_visible' }}
        title={"Assign from Internal Agents"}
        onClose={() => onChangePartyHOC(false, "showAssignInternalModal")}
        children={
          <>
            <div className={"row"}>
              <div className="col-12">
                <h2 className="at-form-input__title">
                  {"Search for internal agent"}
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </h2>
                {
                  [ "Super Admin", "Admin" ].indexOf(role) > -1 && (
                    <CustomTypeahead
                      wrapperClassName="mb-30"
                      disabled={onLoadAgent}
                      typeaheadId={"internal_agent"}
                      options={agentList || []}
                      selectedValue={
                        selectedAgent ? [selectedAgent] : []
                      }
                      labelKey={"full_name"}
                      helpText={"e.g. Full Name, Email, Mobile Number"}
                      filterBy={["full_name", "email", "mobile_contact_number"]}
                      onSearch={(val) => onChangeTypeaheadSearch(val)}
                      onSelect={(val) =>
                        (val && val.length > 0) && onSelectAgent(val[0])
                      }
                      childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                      highlighterData={[
                        (option) => `Team: ${option.team_name}`,
                        <br />,
                        (option) =>
                          `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                      ]}
                    />
                  )
                }
                {
                  [ "Super Admin", "Admin" ].indexOf(role) === -1 && (
                    <>
                      <CustomFormInput
                        containerClass={"mt-2"}
                        type={"number"}
                        value={`${searchValue}`}
                        onChangeValue={(val) =>
                          onChangeInternalHOC(val, "searchValue")
                        }
                        placeholder={`Please fill in Mobile Number to search`}
                      />
                      <button
                        disabled={searchValue.length < 1}
                        className="btn-new btn-new--secondary mt-10"
                        onClick={() =>
                          getInternalAgent({ mobile_contact_number: searchValue })
                        }
                      >
                        Search
                      </button>
                      {searchedAgents && (
                        <div className="col-12">
                          <section
                            className="grid-full-col"
                            style={{ paddingTop: 16 }}
                          >
                            <Card>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                  {searchedAgents[0].full_name || "N/A"}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                  {searchedAgents[0].mobile_contact_number}
                                </Typography>
                                <button
                                  className="btn-new btn-new--primary mt-3"
                                  onClick={() =>
                                    assignParty(
                                      {
                                        party_id: selectedPartyId,
                                        agent_id: searchedAgents[0].id,
                                      },
                                      selected_form.id,
                                      selectedTitle,
                                    )
                                  }
                                >
                                  {`Assign as ${selectedTitle}`}
                                </button>
                              </CardContent>
                            </Card>
                          </section>
                        </div>
                      )}
                    </>
                  )
                }        
              </div>
              {
                [ "Super Admin", "Admin" ].indexOf(role) > -1 && (
                  <div className="at-modal_dialog-container-footer">
                    <button
                      disabled={!selectedAgent?.id}
                      className="btn-new btn-new--primary"
                      onClick={() =>
                        assignParty(
                          {
                            party_id: selectedPartyId,
                            agent_id: selectedAgent.id,
                          },
                          selected_form.id,
                          selectedTitle,
                        )
                      }
                    >
                      {`Assign as ${selectedTitle}`}
                    </button>
                  </div>
                )
              }
            </div>
          </>
        }
      />
      {(onLoadParty || onLoadInternalAgents) && <LoadingModal />}
    </>
  );
};

export default compose(AgentSearchHOC, InternalAgentHOC)(AssignInternalAgent);
