import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { dateValidation } from "utils/checkNull";
import { Get, GetFile, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { getAllDictionaryData } from "actions/dictionary";
import { storeSubsalesAddress } from "actions/subsales/address";
import { refreshToken } from "actions/login";
import { storeLastView } from "actions/lastView";
import { getInvoicingBranches } from "actions/branch";
import { edgePropDataMapping } from "dictionary/subsales";

const searchParams = [
  {
    label: "Agent Name/Email",
    value: "user_full_name_or_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Form Number",
    value: "transaction_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Submitted",
    value: "is_claimed_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const initialPropertyData = {
  unit_no: "",
  address: "",
  name_s_lower: "",
  property_name: "",
  postcode: "",
  built_up: 0,
  land_area: 0,
  category_id: "",
  property_type_id: "",
  tenure_type_id: "",
  unit_type_id: "",
  area_s_lower: "",
  state_s_lower: "",
  street_name_s_lower: "",
  address: "",
  country_id: "",
  id: "",
  source_id: "",
  source_type: "",
  state_id: "",
  township_id: "",
};

const AppointmentLetterHOC = (WrappedComponent) => {
  class AppointmentLetterWrappedComponent extends Component {
    state = {
      selectedPropertyData: initialPropertyData,
      townshipsList: [],
      autoSuggestLoading: false,
      autoSuggestOptions: [],
      appointment_letters: [],
      agentList: [],
      contactList: [],
      appointmentLetterPage: null,
      searchParams: searchParams,
      selected_appointment_letter: {
        appointment_date: null,
        basis_id: 1,
        day_to_remind_after_appointed: 0,
        id: 0,
        invoicing_branch_id: null,
        owners_address: "",
        owners_contact: "",
        owners_name: "",
        owners_nric: "",
        property_address: "",
        remind_me: false,
        reminder_sent_at: null,
        rent_appointment: null,
        stake_holder_address: "",
        stake_holder_bank_account_number: "",
        stake_holder_bank_id: 0,
        stake_holder_company: "",
        stake_holder_contact_number: "",
        stake_holder_id: 1,
        stake_holder_registration_number: "",
        transaction_number: "",
        type_id: 1,
        user_id: 0,
        valid_from: null,
        valid_to: null,
        duration_of_tenancy: "",
        max_fee: null,
        duration_type_id: 1,
        balance_deposit_percentage: 0,
        balance_pay_within_another_days: 0,
        balance_pay_within_days: 0,
        chargeable_interest_percentage: 0,
        consideration_price: 0,
        earnest_deposit_percentage: 0,
        fee_payable_amount: 0,
        id: 0,
        property_location: {},
        land_and_buildings_fee_percentage: 0,
        sales_tax_percentage: 6,
        rental_amount: 0,
        is_claimed: false,
        tmp_claimed: false,
      },
      disableSubmitButton: false,
      showSendEmail: false,
      showCreationForm: false,
      showEditApptLetter: false,
      showLoadingModal: false,
      showSubmitConfirmation: false,
      showDetachConfirmation: false,
      showSelectForm: false,

      createInvoiceId: null,
      createTypeId: null,
      tempEProcess: null
    };

    onChangeAppointmentHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeApptLetterValue = (title, value) => {
      let tmp = _.cloneDeep(this.state.selected_appointment_letter);
  
      if (title === "stake_holder_id" || title === "basis_id") {
        tmp[title] = parseInt(value, 10);
      } else {
        tmp[title] = value;
      }
      this.setState({ selected_appointment_letter: tmp });
    };

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmation: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    saveTnC = () => {
      if (this.state.selected_appointment_letter.owners_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selected_appointment_letter.owners_address,
        );
      }
      if (this.state.selected_appointment_letter.property_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selected_appointment_letter.property_address,
        );
      }
      if (this.state.selected_appointment_letter.stake_holder_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selected_appointment_letter.stake_holder_address,
        );
      }
      this.updateAppointmentLetter(this.state.selected_appointment_letter);
    };

    load = (param) => this.setState({ showLoadingModal: param });

    getCurrentFormContactList = (form_id) =>
      Get(
        `/admin/forms/form_contacts/search_contact?type_id=3&form_id=${form_id}`,
        (payload) => this.setState({ contactList: payload }),
        this.getCurrentFormContactListError,
        this.load,
      );
    getCurrentFormContactListError = (error) => requestError(error);

    updateAppointmentLetter = (dataToSubmit) =>
      Put(
        `/admin/forms/appointment_letters/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateAppointmentLetterSuccess,
        this.updateAppointmentLetterError,
        this.load,
      );
    updateAppointmentLetterSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_appointment", "rent_appointment"].find((type) => {
        return tmpPayload[type] && tmpPayload[type] !== null;
      });
      let tmp_selected_appointment_letter = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: tmpPayload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmp_selected_appointment_letter.sale_appointment;
      delete tmp_selected_appointment_letter.rent_appointment;

      this.setState({
        selected_appointment_letter: { ...tmp_selected_appointment_letter },
      });
      this.getAppointmentLetters(currentPage, searchParams);
      this.getAppointmentLetter(payload.id);
      requestSuccess("Appointment letter updated successfully.");
    };
    updateAppointmentLetterError = (error) => requestError(error);

    claimedAppointmentLetter = () =>
      Put(
        `/admin/forms/appointment_letters/${this.state.selected_appointment_letter.id}/toggle_is_claimed`,
        {},
        this.claimedAppointmentLetterSuccess,
        this.claimedAppointmentLetterError,
        this.load,
      );
    claimedAppointmentLetterSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      Promise.all([
        this.getAppointmentLetters(currentPage, searchParams),
        this.getAppointmentLetter(this.state.selected_appointment_letter.id),
      ]).then(() => {
        requestSuccess(
          this.state.selected_appointment_letter.is_claimed
            ? "Appointment letter has marked as submitted to claim."
            : "Appointment letter has marked as pending to claim.",
        );
      });
    };
    claimedAppointmentLetterError = (error) => {
      let tmp = {
        ...this.state.selected_appointment_letter,
        tmp_claimed: this.state.selected_appointment_letter.is_claimed,
      };
      this.onChangeAppointmentHOC(tmp, "selected_appointment_letter");
      requestError(error);
    };

    getAppointmentLetters = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/forms/appointment_letters?${search}page=${page}`,
        this.getAppointmentLettersSuccess,
        this.getAppointmentLettersError,
        this.load,
      );
    };
    getAppointmentLettersSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }

      payload.data &&
        payload.data.map((item) => {
          let data = {
            ...item,
            appointment_date: dateValidation(
              item.appointment_date,
              "YYYY-MM-DD",
              "DD MMM YYYY",
            ),
          };
          tmp.push(data);
        });

      this.setState({
        appointment_letters: {
          data: tmp,
          meta: payload.meta,
        },
        appointmentLetterPage: tmpTotalPages,
      });
    };
    getAppointmentLettersError = (error) => requestError(error);

    getAppointmentLetter = (id) =>
      Get(
        `/admin/forms/appointment_letters/${id}`,
        this.getAppointmentLetterSuccess,
        this.getAppointmentLetterError,
        this.load,
      );
    getAppointmentLetterSuccess = (payload) => {
      const tmpIsLocked = _.some(payload?.forms_chart || [], form => {
        if(Array.isArray(form)) {
          return _.some(form || [], nestedForm => nestedForm.type === "Claim Form" && nestedForm.id !== null )
        } else {
          return form.type === "Claim Form" && form.id !== null
        }
      });
      // Only lock for editing the Appointment letter after agent submit for claim form      

      this.setState({ selected_appointment_letter: payload }, () => {
        const tmpPayload = _.cloneDeep(payload);
        let tmpType = ["sale_appointment", "rent_appointment"].find((type) => {
          return tmpPayload[type] && tmpPayload[type] !== null;
        });

        if(tmpPayload.type_id === 1) {
          tmpPayload.sale_appointment.consideration_price = parseFloat(tmpPayload?.sale_appointment?.consideration_price ?? 0)?.toFixed(2)
          tmpPayload.sale_appointment.fee_payable_amount = parseFloat(tmpPayload?.sale_appointment?.fee_payable_amount ?? 0)?.toFixed(2)
        } else if(tmpPayload.type_id === 2) {
          tmpPayload.rent_appointment.rental_amount = parseFloat(tmpPayload?.rent_appointment?.rental_amount ?? 0)?.toFixed(2)
          tmpPayload.rent_appointment.advanced_rental_amount = parseFloat(tmpPayload?.rent_appointment?.advanced_rental_amount ?? 0)?.toFixed(2)
          tmpPayload.rent_appointment.security_deposit_amount = parseFloat(tmpPayload?.rent_appointment?.security_deposit_amount ?? 0)?.toFixed(2)
          tmpPayload.rent_appointment.utility_deposit_amount = parseFloat(tmpPayload?.rent_appointment?.utility_deposit_amount ?? 0)?.toFixed(2)
        }
        
        let tmp_selected_appointment_letter = {
          ...tmpPayload,
          ...tmpPayload[tmpType],
          id: tmpPayload.id,
          isLocked: tmpIsLocked,
          tmp_claimed: tmpPayload.is_claimed,
        };

        delete tmp_selected_appointment_letter.sale_appointment;
        delete tmp_selected_appointment_letter.rent_appointment;

        return this.setState({
          selected_appointment_letter: tmp_selected_appointment_letter,
          tempEProcess: payload.e_process,
          showEditApptLetter: true,
          selectedPropertyData: tmp_selected_appointment_letter.property_location || initialPropertyData,
        });
      });
    };
    getAppointmentLetterError = (error) => requestError(error);

    downloadAppointmentLetter = (id, filename) =>
      GetFile(
        `/admin/forms/appointment_letters/${id}/download?with_data=true`,
        filename,
        () => {},
        this.downloadAppointmentLetterError,
        this.load,
      );
    downloadAppointmentLetterError = (error) => requestError(error);

    sendAppointmentLetter = (id, recipient) =>
      Post(
        `/admin/forms/appointment_letters/${id}/deliver`,
        recipient,
        this.sendAppointmentLetterSuccess,
        this.sendAppointmentLetterError,
        this.load,
      );
    sendAppointmentLetterSuccess = () =>
      requestSuccess("Appointment letter sent successfully.", () =>
        this.setState({ showSendEmail: false }),
      );
    sendAppointmentLetterError = (error) => requestError(error);

    getAgentsBySearch = (val) =>
      Get(
        `/admin/users/search?type=1&name=${val}`,
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ agentList: payload.agent_selections });
    getAgentsBySearchError = (error) => requestError(error);

    createAppointmentLetter = (dataToSubmit) =>
      Post(
        `/admin/forms/appointment_letters`,
        dataToSubmit,
        this.createAppointmentLetterSuccess,
        this.createAppointmentLetterError,
        this.load,
      );
    createAppointmentLetterSuccess = (payload) => {
      this.setState({
        showCreationForm: false,
        disableSubmitButton: false,
      });
      this.getAppointmentLetter(payload.id);
    };
    createAppointmentLetterError = (error) => {
      requestError(error);
      this.setState({ disableSubmitButton: false });
    };

    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        townshipsList: _.map(payload, (town) => ({
          ...town,
          value: town.name,
        })),
      });
    getSelectedTownShipError = (error) => requestError(error);

    edgeAutoSuggest = (query, formData) => {
      const { category_id, property_type } = formData;
      if (!_.isEmpty(property_type) && category_id) {
        Get(
          `/admin/sub_sales/suggest_address?query=${query}&category_id=${category_id}&property_type=${property_type}`,
          this.edgeAutoSuggestSuccess,
          this.edgeAutoSuggestError,
          (load) => this.setState({ autoSuggestLoading: load }),
        );
      }
    };
    edgeAutoSuggestSuccess = (payload) => {
      this.setState({
        autoSuggestOptions: _.map(payload.data, (item) => {
          return {
            ...item,
            state_s_lower: _.find(edgePropDataMapping, { edgeProp: item.state_s_lower })?.atlas ?? item.state_s_lower,
            value: item.name_s_lower,
          };
        }),
      });
    };
    edgeAutoSuggestError = (error) => requestError(error);

    updatePropertyLocation = (data) => {
      const { selected_appointment_letter } = this.state;
      Put(
        `/admin/forms/appointment_letters/${selected_appointment_letter.id}/property_location`,
        data,
        this.updatePropertyLocationSuccess,
        this.updatePropertyLocationError,
        (load) => this.setState({ loading: load }),
      );
    };
    updatePropertyLocationSuccess = (id) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.getAppointmentLetter(this.state.selected_appointment_letter.id)
      this.getAppointmentLetters(currentPage, searchParams)
      requestSuccess("Property updated successsfully");
    }
    updatePropertyLocationError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedPropertyData={this.state.selectedPropertyData}
            townshipsList={this.state.townshipsList}
            autoSuggestOptions={this.state.autoSuggestOptions}
            tempEProcess={this.state.tempEProcess}
            agentList={this.state.agentList}
            contactList={this.state.contactList}
            createInvoiceId={this.state.createInvoiceId}
            createTypeId={this.state.createTypeId}
            showSendEmail={this.state.showSendEmail}
            showCreationForm={this.state.showCreationForm}
            showEditApptLetter={this.state.showEditApptLetter}
            onLoadApptLetter={this.state.showLoadingModal}
            autoSuggestLoading={this.state.autoSuggestLoading}
            searchParams={this.state.searchParams}
            disableSubmitButton={this.state.disableSubmitButton}
            appointment_letters={this.state.appointment_letters}
            confirmation_forms={this.state.confirmation_forms}
            selected_appointment_letter={this.state.selected_appointment_letter}
            appointmentLetterPage={this.state.appointmentLetterPage}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            showSelectForm={this.state.showSelectForm}
            showDetachConfirmation={this.state.showDetachConfirmation}
            onChangeAppointmentHOC={this.onChangeAppointmentHOC}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            getAppointmentLetters={this.getAppointmentLetters}
            getAppointmentLetter={this.getAppointmentLetter}
            claimedAppointmentLetter={this.claimedAppointmentLetter}
            downloadAppointmentLetter={this.downloadAppointmentLetter}
            sendAppointmentLetter={this.sendAppointmentLetter}
            onChangeApptLetterValue={this.onChangeApptLetterValue}
            createAppointmentLetter={this.createAppointmentLetter}
            getCurrentFormContactList={this.getCurrentFormContactList}
            getSelectedTownShip={this.getSelectedTownShip}
            edgeAutoSuggest={this.edgeAutoSuggest}
            updatePropertyLocation={this.updatePropertyLocation}
            getAgentsBySearch={this.getAgentsBySearch}
            saveTnC={this.saveTnC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeSubsalesAddress,
    getInvoicingBranches,
    getAllDictionaryData,
    refreshToken,
    storeLastView,
  })(AppointmentLetterWrappedComponent);
};

export default AppointmentLetterHOC;
