import React from "react";
import { MdFilterList } from "react-icons/md";

import TableSearchParams from "../../CustomerDueDillegence/components/CustomSearchParam";

const SearchParams = ({
  searchParams,
  showSearchContent,
  onChangeAmlaReportHOC,
  getListAPI,
}) => {
  return (
    <>
      <button
        className={
          "btn-new btn-new--outline-secondary at-mobile-view__controller"
        }
        style={{ width: "100%" }}
        onClick={() =>
          onChangeAmlaReportHOC(!showSearchContent, "showSearchContent")
        }
      >
        <MdFilterList style={{ width: 20 }} />
        <h5 style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
          Filter & Search
        </h5>
      </button>
      <div className="at-table__search-cont at-desktop-view__controller ">
        <TableSearchParams
          mode={"desktop"}
          showResetButton={false}
          showDownloadButton={true}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeAmlaReportHOC(val, "searchParams")
          }
          onToggleSearchModal={(val) =>
            onChangeAmlaReportHOC(val, "showSearchContent")
          }
          getListAPI={(page, search) => getListAPI(search)}
        />
      </div>
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          showResetButton={false}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeAmlaReportHOC(val, "searchParams")
          }
          onToggleSearchModal={(val) =>
            onChangeAmlaReportHOC(val, "showSearchContent")
          }
          getListAPI={(page, search) => getListAPI(search)}
        />
      )}
    </>
  );
};

export default SearchParams;
