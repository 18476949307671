import { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import Spinner from "components/LoadingModal";
import DeleteConfirmationDialog from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateEmailCampaign from "./Create";
import PreviewEmailCampaign from "./Preview";
import UpdateEmailCampaign from "./Update";

import { columnData } from "./assets";
import permissionsChecker from "utils/permissionsChecker";
import withEmailCampaigns from "./actions";

import "./index.scss";

const EmailCampaigns = ({
  data,
  onLoadEmailCampaigns,
  emailCampaigns,
  emailCampaignsPages,
  searchParams,
  newEmailCampaign,
  selectedEmailCampaign,
  originalEmailCampaign,
  showCreateModal,
  showUpdateModal,
  showPreviewModal,
  showDeleteModal,
  showSuspendModal,

  onChangeEmailCampaignsHOC,
  getEmailCampaigns,
  getSelectedEmailCampaign,
  createEmailCampaign,
  updateEmailCampaign,
  deleteEmailCampaign,
  suspendEmailCampaign,
}) => {
  const { can_create, can_update, can_destroy } = permissionsChecker("Email Campaigns", data);

  useEffect(() => {
    getEmailCampaigns();
  }, []);

  const renderCreateDialog = () => (
    <CreateEmailCampaign
      newEmailCampaign={newEmailCampaign}
      onLoadEmailCampaigns={onLoadEmailCampaigns}

      createEmailCampaign={createEmailCampaign}
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
    />
  );

  const renderUpdateDialog = () => (
    <UpdateEmailCampaign
      onLoadEmailCampaigns={onLoadEmailCampaigns}
      selectedEmailCampaign={selectedEmailCampaign}
      originalEmailCampaign={originalEmailCampaign}

      updateEmailCampaign={updateEmailCampaign}
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
    />
  );

  const renderPreviewDialog = () => (
    <PreviewEmailCampaign
      onLoadEmailCampaigns={onLoadEmailCampaigns}
      selectedEmailCampaign={selectedEmailCampaign}
      
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
    />
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={showDeleteModal}
      title={"Delete email campaign"}
      message={"Are you sure you want to delete this campaign?"}
      negativeAction={() =>
        onChangeEmailCampaignsHOC("showDeleteModal", false)
      }
      positiveText={"Yes"}
      positiveAction={deleteEmailCampaign}
    />
  );

  const renderSuspendDialog = () => (
    <DeleteConfirmationDialog
      open={showSuspendModal}
      title={"Suspend email campaign"}
      message={"Are you sure you want to suspend this campaign?"}
      negativeAction={() =>
        onChangeEmailCampaignsHOC("showSuspendModal", false)
      }
      positiveText={"Yes"}
      positiveAction={suspendEmailCampaign}
    />
  );

  return (
    <>
      <ModuleHeader
        atlasIcon={"atlas-sms-edit"}
        title={"Email Campaigns"}
        description={"Keep subscribers engaged by sharing your latest news, promoting your bestselling products, or announcing an upcoming event."}
        actionButton={[
          {
            label: "New Email Campaign",
            onShow: can_create,
            className: "btn-new btn-new--primary",
            icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
            onClick: () => onChangeEmailCampaignsHOC("showCreateModal", true)
          },
        ]}
      />
      <CustomCard cardContent={
        <AtPaginatedTable
          columns={columnData()}
          getListAPI={getEmailCampaigns}
          rowData={emailCampaigns.data}
          meta={emailCampaigns.meta}
          totalPages={emailCampaignsPages}
          searchParams={searchParams}
          onChangeSearchParams={(val) => onChangeEmailCampaignsHOC("searchParams", val)}
          actionColumnContent={[
            {
              name: "summary",
              onClick: (rowData) => Promise.all([
                getSelectedEmailCampaign(rowData.id)
              ]).then(() => {
                onChangeEmailCampaignsHOC("showPreviewModal", true);
              })
            },
            {
              name: "edit",
              onShow: (rowData) => rowData?.status === "draft" && can_update,
              onClick: (rowData) => Promise.all([
                getSelectedEmailCampaign(rowData.id)
              ]).then(() => {
                onChangeEmailCampaignsHOC("showUpdateModal", true);
              })
            },
            {
              name: "suspend",
              onShow: (rowData) => rowData?.status === "scheduled" && can_update,
              onClick: (rowData) => Promise.all([
                onChangeEmailCampaignsHOC("selectedId", rowData.id)
              ]).then(() => {
                onChangeEmailCampaignsHOC("showSuspendModal", true);
              })
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: (rowData) => rowData?.status === "draft" && can_destroy,
              onClick: (rowData) => Promise.all([
                onChangeEmailCampaignsHOC("selectedId", rowData.id)
              ]).then(() => {
                onChangeEmailCampaignsHOC("showDeleteModal", true);
              })
            },
          ]}
        />
      } />
      {showCreateModal && renderCreateDialog()}
      {showUpdateModal && renderUpdateDialog()}
      {showPreviewModal && renderPreviewDialog()}
      {renderDeleteDialog()}
      {renderSuspendDialog()}
      {onLoadEmailCampaigns && <Spinner />}
    </>
  );
};

export default withEmailCampaigns(EmailCampaigns);
