import React, { Component } from "react";

import ModalDialog from "components/Modal/ModalDialog";
import AtlasTextarea from "components/Input/textarea";

class RejectModal extends Component {
  render = () => {
    return (
      <ModalDialog
        title={"Reject Reason"}
        onClose={() =>
          this.props.onChangeSubmissionHOC("showRejectSubmissionReason", false)
        }
        children={
          <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">{"Reason"}</h2>
              <AtlasTextarea
                value={this.props.rejectReason}
                onChangeValue={(val) =>
                  this.props.onChangeSubmissionHOC("rejectReason", val)
                }
              />
            </section>
            <section className="grid-full-col d-flex">
              <button
                style={{ display: "inline-block", marginRight: "10px" }}
                className="btn-new btn-new--danger"
                onClick={() => this.props.onSubmit()}
                disabled={this.props.rejectReason.length < 1}
              >
                Reject The Submission Now
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary ml-2"
                onClick={() =>
                  this.props.onChangeSubmissionHOC(
                    "showRejectSubmissionReason",
                    false,
                  )
                }
              >
                Close
              </button>
            </section>
          </form>
        }
      />
    );
  };
}

export default RejectModal;
