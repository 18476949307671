export const getTranslation = (key, language) => {
  const translation = translationDictionary.find((translation) => translation.key === key);
  if (translation) {
    return translation[language || 'en'];
  }
  return key;
}

export const translationDictionary = [
  // Login Page
  {
    key: "label.sidebar.message",
    en: "Empowering IQI agents, streamlining real estate process",
    ja: "IQIエージェントの力を高め、不動産プロセスを効率化する",
    zh: "赋能IQI Agent，简化房地产交易",
    ms: "Memberdayakan ejen IQI, memperlancar proses hartanah",
  },
  {
    key: "label.title",
    en: "Sign in to IQI ATLAS",
    ja: "IQI ATLASにサインイン",
    zh: "登录 Atlas",
    ms: "Log masuk ke IQI ATLAS",
  },
  {
    key: "label.email",
    en: "Email",
    ja: "メール",
    zh: "电子邮件",
    ms: "Emel",
  },
  {
    key: "label.password",
    en: "Password",
    ja: "パスワード",
    zh: "密码",
    ms: "Kata Laluan",
  },
  {
    key: "label.remember_me",
    en: "Remember me",
    ja: "私を覚えてますか",
    zh: "记住我",
    ms: "Ingat saya",
  },
  {
    key: "label.forgot_password",
    en: "Forgot Password",
    ja: "パスワードをお忘れですか",
    zh: "忘记密码",
    ms: "Lupa Kata Laluan",
  },
  {
    key: "label.forgot_password.description",
    en: "Please enter your email address to recover your password.",
    ja: "パスワードを回復するにはメールアドレスを入力してください。",
    zh: "请输入您的电子邮件地址以恢复密码。",
    ms: "Sila masukkan alamat emel anda untuk memulihkan kata laluan anda.",
  },
  {
    key: "label.email_password_empty",
    en: "Email or password cannot be empty",
    ja: "メールアドレスまたはパスワードは空にできません",
    zh: "电子邮件或密码不能为空",
    ms: "Emel atau kata laluan tidak boleh kosong",
  },
  {
    key: "label.email_invalid",
    en: "Email is invalid!",
    ja: "メールアドレスが無効です！",
    zh: "电子邮件无效！",
    ms: "Emel tidak sah!",
  },
  {
    key: "label.password_invalid",
    en: "Password is invalid!",
    ja: "パスワードが無効です!",
    zh: "密码无效！",
    ms: "Kata laluan tidak sah!",
  },
  {
    key: "action.sign_in",
    en: "Sign In",
    ja: "サインイン",
    zh: "登录",
    ms: "Log Masuk",
  },
  {
    key: "action.back_to_sign_in",
    en: "Back to Sign In",
    ja: "ログインに戻る",
    zh: "返回登录",
    ms: "Kembali ke Log Masuk",
  },
  {
    key: "label.terms_of_use",
    en: "Terms of use",
    ja: "利用規約",
    zh: "使用条款",
    ms: "Terma penggunaan",
  },
  {
    key: "label.privacy_policy",
    en: "Privacy policy",
    ja: "プライバシーポリシー",
    zh: "隐私政策",
    ms: "Dasar Privasi",
  },
  {
    key: "label.helptext.iqi_support",
    en: "IQI Support",
    ja: "IQI サポート",
    zh: "IQI 客服",
    ms: "Pusat Bantuan IQI",
  },
  {
    key: "label.helptext.whatsapp",
    en: "Please click here to contact our support.",
    ja: "サポートにお問い合わせするにはここをクリックしてください。",
    zh: "请点击此处联系我们的客服。",
    ms: "Sila klik di sini untuk menghubungi pusat bantuan kami.",
  },
  {
    key: "label.helptext.telegram",
    en: "Talk to the IQI Global Bot!",
    ja: "IQI Global Botと話す！",
    zh: "与IQI Global Bot交谈！",
    ms: "Berkomunikasi dengan Bot Global IQI!",
  },
  {
    key: "label.helptext.phone",
    en: "Call to Helpdesk",
    ja: "ヘルプデスクに電話する",
    zh: "致电客服",
    ms: "Hubungi Pusat Bantuan",
  },
  {
    key: "label.phone_confirmation.title",
    en: "Helpdesk",
    ja: "ヘルプデスク",
    zh: "客服",
    ms: "Pusat Bantuan",
  },
  {
    key: "label.phone_confirmation.description",
    en : "An exclusive and premier support line for IQI warriors in all your real estate queries. Click the call button below to talk to one of our Exclusive Care Service team. This service is available between 10am until 10pm everyday including public holidays.",
    ja: "不動産に関するすべての疑問に対するIQI戦士のための専用でプレミアムなサポートライン。下の電話ボタンをクリックして、専用ケアサービスチームの1人と話すことができます。このサービスは、祝日を含む毎日10時から22時まで利用可能です。",
    zh: "IQI战士的独家和首选支持热线，用于解决您的所有房地产问题。点击下面的呼叫按钮，与我们的专属客服团队之一交谈。此服务每天从上午10点到晚上10点，包括公共假期。",
    ms: "Barisan sokongan eksklusif dan premier untuk pejuang IQI dalam semua pertanyaan hartanah anda. Klik butang panggilan di bawah untuk bercakap dengan salah seorang daripada pasukan Perkhidmatan Jagaan Eksklusif kami. Perkhidmatan ini disediakan antara jam 10 pagi hingga 10 malam setiap hari termasuk cuti umum.",
  },
  {
    key: "label.helptext.email",
    en: "Send email to IQI Support",
    ja: "IQIサポートにメールを送信",
    zh: "发送电子邮件至IQI客服",
    ms: "Hantar emel ke Pusat Bantuan IQI",
  },

  // Forget Password Page
  {
    key: "label.reset_link_sent.title",
    en: "Reset Link Sent",
    ja: "リセットリンクが送信されました",
    zh: "重置链接已发送",
    ms: "Pautan Set Semula Dihantar",
  },
  {
    key: "label.reset_link_sent.message",
    en: "An email containing new password reset link has been sent to your email {{email}}. Please check your inbox.",
    ja: "新しいパスワードリセットリンクを含むメールがあなたのメールアドレス{{email}}に送信されました。受信トレイを確認してください。",
    zh: "包含新密码重置链接的电子邮件已发送到您的电子邮件{{email}}。请检查您的收件箱。",
    ms: "Emel yang mengandungi pautan set semula kata laluan baru telah dihantar ke emel anda {{email}}. Sila semak peti masuk anda.",
  },
  {
    key: "label.reset_link_sent.message_2",
    en: "If you did not receive the email, check that the email address that entered is correct, check you spam folder or contact Atlas support to recover your account.",
    ja: "メールが届かない場合は、入力したメールアドレスが正しいかどうかを確認し、スパムフォルダを確認するか、アトラスサポートに連絡してアカウントを回復してください。",
    zh: "如果您没有收到电子邮件，请检查输入的电子邮件地址是否正确，检查您的垃圾邮件文件夹或联系Atlas支持以恢复您的帐户。",
    ms: "Jika anda tidak menerima emel, semak alamat emel yang dimasukkan adalah betul, semak folder spam anda atau hubungi sokongan Atlas untuk memulihkan akaun anda.",
  },
  {
    key: "label.cooldown_message",
    en: "{{cooldownTiming}} seconds away from the next submit.",
    ja: "次の送信まで{{cooldownTiming}}秒。",
    zh: "距离下一次提交还有{{cooldownTiming}}秒。",
    ms: "{{cooldownTiming}} saat lagi untuk hantar semula.",
  },
  {
    key: "label.search_menu_keyword",
    en: "Search menu keyword",
    ja: "キーワード検索",
    zh: "搜索关键词",
    ms: "Cari kata kunci menu",
  },
  {
    key: "label.wrong_email",
    en: "Invalid email, please input a correct email address.",
    ja: "無効なメールアドレスです。正しいメールアドレスを入力してください。",
    zh: "无效的电子邮件，请输入正确的电子邮件地址。",
    ms: "Emel tidak sah, sila masukkan alamat emel yang betul.",
  },

  // Common
  {
    key: "required",
    en: "required *",
    ja: "*必须",
    zh: "必需 *",
    ms: "diperlukan *",
  },
  {
    key: "click_to_upload",
    en: "Click to upload",
    ja: "クリックしてアップロード",
    zh: "点击上传",
    ms: "Klik untuk muat naik",
  },
  {
    key: "or_drag_and_drop",
    en: " or drag and drop",
    ja: "またはドラッグアンドドロップ",
    zh: "或拖放",
    ms: "atau seret dan lepas",
  },
  {
    key: "back_to_previous_page",
    en: "Back To Previous Page",
    ja: "前のページに戻る",
    zh: "返回上一页",
    ms: "Kembali ke Halaman Sebelumnya",
  },
  {
    key: "training_not_completed",
    en: "Training not completed.",
    ja: "トレーニングが完了していません。",
    zh: "培训未完成。",
    ms: "Latihan tidak selesai.",
  },
  {
    key: "required_to_finish_training",
    en: "You have not completed your training. You are required to finish training before continuing. Please contact support if you have any questions.",
    ja: "トレーニングを修了していない。続行する前にトレーニングを終了する必要があります。ご不明な点はサポートまでお問い合わせください。",
    zh: "您尚未完成培训。您需要完成培训后才能继续。如有任何疑问，请联系支持。",
    ms: "Anda belum menyelesaikan latihan anda. Anda dikehendaki menyelesaikan latihan sebelum meneruskan. Sila hubungi sokongan jika anda mempunyai sebarang pertanyaan.",
  },
  {
    key: "account_inactive",
    en: "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions.",
    ja: "あなたのアカウントはアクティブではなく、一時的にモジュールにアクセスできません。ご不明な点はサポートまでお問い合わせください。",
    zh: "您的帐户处于非活动状态，暂时无法访问该模块。如有任何疑问，请联系支持。",
    ms: "Akaun anda tidak aktif dan sementara tidak dapat mengakses modul. Sila hubungi sokongan jika anda mempunyai sebarang pertanyaan.",
  },
  {
    key: "remove_image",
    en: "Remove Image",
    ja: "画像を削除",
    zh: "删除图片",
    ms: "Buang Imej",
  },
  {
    key: "view_listing_iqi",
    en: "Click to view this listing on www.iqiglobal.com.",
    ja: "www.iqiglobal.comでこのリストを表示するにはクリックしてください。",
    zh: "点击在 www.iqiglobal.com 上查看房源。",
    ms: "Klik untuk melihat senarai ini di www.iqiglobal.com.",
  },
  {
    key: "view_listing_juwai",
    en: "Click to view this listing on www.juwai.com.",
    ja: "www.juwai.comでこのリストを表示するにはクリックしてください。",
    zh: "点击在 www.juwai.com 上查看房源。",
    ms: "Klik untuk melihat senarai ini di www.juwai.com.",
  },
  {
    key: "view_listing_juwai_asia",
    en: "Click to view this listing on www.juwai.asia.",
    ja: "www.juwai.asiaでこのリストを表示するにはクリックしてください。",
    zh: "点击在 www.juwai.asia 上查看房源。",
    ms: "Klik untuk melihat senarai ini di www.juwai.asia.",
  },
  {
    key: "view_listing_edgeprop",
    en: "Click to view this listing on www.edgeprop.my.",
    ja: "www.edgeprop.myでこのリストを表示するにはクリックしてください。",
    zh: "点击在 www.edgeprop.my 上查看房源。",
    ms: "Klik untuk melihat senarai ini di www.edgeprop.my.",
  },

  // Common - actions
  {
    key: "all",
    en: "All",
    ja: "すべて",
    zh: "全部",
    ms: "Semua",
  },
  {
    key: "back",
    en: "Back",
    ja: "戻る",
    zh: "返回",
    ms: "Kembali",
  },
  {
    key: "call",
    en: "Call",
    ja: "電話",
    zh: "呼叫",
    ms: "Panggilan",
  },
  {
    key: "cancel",
    en: "Cancel",
    ja: "キャンセル",
    zh: "取消",
    ms: "Batal",
  },
  {
    key: "close",
    en: "Close",
    ja: "閉じる",
    zh: "关闭",
    ms: "Tutup",
  },
  {
    key: "confirm",
    en: "Confirm",
    ja: "確認",
    zh: "确认",
    ms: "Sah",
  },
  {
    key: "create",
    en: "Create",
    ja: "作成",
    zh: "创建",
    ms: "Cipta",
  },
  {
    key: "delete",
    en: "Delete",
    ja: "削除",
    zh: "删除",
    ms: "Padam",
  },
  {
    key: "details",
    en: "Details",
    ja: "詳細",
    zh: "详情",
    ms: "Butiran",
  },
  {
    key: "duplicate",
    en: "Duplicate",
    ja: "複製",
    zh: "复制",
    ms: "Duplikasi",
  },
  {
    key: "edit",
    en: "Edit",
    ja: "編集",
    zh: "编辑",
    ms: "Edit",
  },
  {
    key: "email",
    en: "Email",
    ja: "メール",
    zh: "电子邮件",
    ms: "Emel",
  },
  {
    key: "favourite",
    en: "Favourite",
    ja: "お気に入り",
    zh: "收藏",
    ms: "Kegemaran",
  },
  {
    key: "generate",
    en: "Generate",
    ja: "生成",
    zh: "生成",
    ms: "Jana",
  },
  {
    key: "hide_filter",
    en: "Hide Filter",
    ja: "フィルターを隠す",
    zh: "隐藏筛选",
    ms: "Sembunyikan Penapis",
  },
  {
    key: "message",
    en: "Message",
    ja: "メッセージ",
    zh: "消息",
    ms: "Mesej",
  },
  {
    key: "more_filter",
    en: "More Filter",
    ja: "さらに絞り込む",
    zh: "更多筛选",
    ms: "Penapis Lanjut",
  },
  {
    key: "no",
    en: "No",
    ja: "いいえ",
    zh: "否",
    ms: "Tidak",
  },
  {
    key: "reset",
    en: "Reset",
    ja: "リセット",
    zh: "重置",
    ms: "Set Semula",
  },
  {
    key: "save",
    en: "Save",
    ja: "保存",
    zh: "保存",
    ms: "Simpan",
  },
  {
    key: "scroll_to_bottom",
    en: "Scroll to Bottom",
    ja: "下までスクロール",
    zh: "滚动到底部",
    ms: "Skrol ke bawah",
  },
  {
    key: "scroll_to_top",
    en: "Scroll to Top",
    ja: "トップにスクロール",
    zh: "滚动到顶部",
    ms: "Skrol ke atas",
  },
  {
    key: "search",
    en: "Search",
    ja: "検索",
    zh: "搜索",
    ms: "Cari",
  },
  {
    key: "submit",
    en: "Submit",
    ja: "提出",
    zh: "提交",
    ms: "Hantar",
  },
  {
    key: "update",
    en: "Update",
    ja: "更新",
    zh: "更新",
    ms: "Kemaskini",
  },
  {
    key: "upload",
    en: "Upload",
    ja: "アップロード",
    zh: "上传",
    ms: "Muat Naik",
  },
  {
    key: "yes",
    en: "Yes",
    ja: "はい",
    zh: "是",
    ms: "Ya",
  },

  // Shared
  {
    key: "label.sidemenu.dashboard",
    en: "Dashboard",
    ja: "ダッシュボード",
    zh: "控制面板",
    ms: "Pusat Kawalan",
  },
  {
    key: "label.sidemenu.iqi_calendar",
    en: "IQI Calendar",
    ja: "IQI カレンダー",
    zh: "IQI 日历",
    ms: "Kalendar IQI",
  },
  {
    key: "label.sidemenu.global_network",
    en: "Global Network",
    ja: "グローバルネットワーク",
    zh: "全球伙伴",
    ms: "Rangkaian Global",
  },
  {
    key: "label.sidemenu.license_renewal",
    en: "License Renewal",
    ja: "ライセンス更新",
    zh: "更新执照",
    ms: "Pembaharuan Lesen",
  },
  {
    key: "label.sidemenu.learning_hub.iqi_academy",
    en: "IQI Academy",
    ja: "IQI アカデミー",
    zh: "IQI 学堂",
    ms: "Akademi IQI",
  },
  {
    key: "label.sidemenu.learning_hub.tutorials",
    en: "Tutorials",
    ja: "チュートリアル",
    zh: "Atlas 教程",
    ms: "Tutorial",
  },
  {
    key: "label.sidemenu.store_hub.iqi_store",
    en: "IQI Store",
    ja: "IQI ストア",
    zh: "IQI 商店",
    ms: "Produk IQI",
  },
  {
    key: "label.sidemenu.iqi_platforms.jiqi_cafe",
    en: "JIQI's Cafe",
    ja: "JIQIのカフェ",
    zh: "JIQI's Cafe",
    ms: "Kafe JIQI",
  },

  // Sidemenu - Agent
  {
    key: "label.sidemenu.team_hub",
    en: "Team Hub",
    ja: "チームハブ",
    zh: "团队中心",
    ms: "Pengurusan Pasukan",
  },
  {
    key: "label.sidemenu.team_hub.my_pa",
    en: "My PA",
    ja: "私のPA",
    zh: "我的私人助理",
    ms: "PA Saya",
  },
  {
    key: "label.sidemenu.team_hub.my_team",
    en: "My Team",
    ja: "私のチーム",
    zh: "我的团队",
    ms: "Pasukan Saya",
  },
  {
    key: "label.sidemenu.team_hub.team_sales",
    en: "Team Sales",
    ja: "チームセールス",
    zh: "团队业绩",
    ms: "Jualan Pasukan",
  },
  {
    key: "label.sidemenu.team_hub.project_sales",
    en: "Project Sales",
    ja: "プロジェクトセールス",
    zh: "新项目业绩",
    ms: "Jualan Projek",
  },
  {
    key: "label.sidemenu.team_hub.new_agent_approvals",
    en: "New Agent Approvals",
    ja: "新しいエージェントの承認",
    zh: "待批准新成员",
    ms: "Kelulusan Ejen Baru",
  },
  {
    key: "label.sidemenu.team_hub.meeting_hub",
    en: "Meeting Hub",
    ja: "ミーティングハブ",
    zh: "会议室",
    ms: "Bilik Mesyuarat",
  },
  {
    key: "label.sidemenu.insights_reports",
    en: "Insights & Reports",
    ja: "インサイトとレポート",
    zh: "分析与报告",
    ms: "Maklumat & Laporan",
  },
  {
    key: "label.sidemenu.insights_reports.analytics_centre",
    en: "Analytics Centre",
    ja: "アナリティクスセンター",
    zh: "分析中心",
    ms: "Pusat Analisa",
  },
  {
    key: "label.sidemenu.insights_reports.campaign_tracker",
    en: "Campaign Tracker",
    ja: "キャンペーントラッカー",
    zh: "Campaign 进度",
    ms: "Pencapaian Kempen",
  },
  {
    key: "label.sidemenu.insights_reports.team",
    en: "TEAM",
    ja: "チーム",
    zh: "团队",
    ms: "PASUKAN",
  },
  {
    key: "label.sidemenu.insights_reports.top_agent_group_sales",
    en: "Top Agent Group Sales",
    ja: "トップエージェントグループセールス",
    zh: "团队顶级成员业绩",
    ms: "Senarai Ejen Teratas Pasukan",
  },
  {
    key: "label.sidemenu.insights_reports.subsales_booked_tracker",
    en: "Subsales Booked Tracker",
    ja: "サブセールスブックトラッカー",
    zh: "二手房交易进度",
    ms: "Status Tuntutan Subsale",
  },
  {
    key: "label.sidemenu.insights_reports.leader_team_sales_ranking",
    en: "Leader Team Sales Ranking",
    ja: "リーダーチームセールスランキング",
    zh: "领导团队业绩排名",
    ms: "Kedudukan Penjualan Pasukan Ketua",
  },
  {
    key: "label.sidemenu.insights_reports.underperforming_members",
    en: "Underperforming Members",
    ja: "パフォーマンスの低いメンバー",
    zh: "业绩不佳成员",
    ms: "Ahli Berprestasi Rendah",
  },
  {
    key: "label.sidemenu.insights_reports.recruitment_growth_targets",
    en: "Recruitment Growth Targets",
    ja: "採用成長目標",
    zh: "团队增长目标",
    ms: "Sasaran Pertumbuhan Pengambilan",
  },
  {
    key: "label.sidemenu.insights_reports.personal",
    en: "PERSONAL",
    ja: "個人",
    zh: "个人",
    ms: "PERIBADI",
  },
  {
    key: "label.sidemenu.insights_reports.my_sales",
    en: "My Sales",
    ja: "私のセールス",
    zh: "我的业绩",
    ms: "Jualan Saya",
  },
  {
    key: "label.sidemenu.real_estate_radar",
    en: "Real Estate Radar",
    ja: "不動産レーダー",
    zh: "房地产资讯",
    ms: "Radar Hartanah",
  },
  {
    key: "label.sidemenu.real_estate_radar.project_centre",
    en: "Project Centre",
    ja: "プロジェクトセンター",
    zh: "新房产项目中心",
    ms: "Pusat Projek",
  },
  {
    key: "label.sidemenu.real_estate_radar.listing_centre",
    en: "Listing Centre",
    ja: "リスティングセンター",
    zh: "二手房中心",
    ms: "Pusat Listing",
  },
  {
    key: "label.sidemenu.real_estate_radar.iqi_drive",
    en: "IQI Drive",
    ja: "IQI ドライブ",
    zh: "IQI 网盘",
    ms: "Pengkalan Data IQI ",
  },
  {
    key: "label.sidemenu.real_estate_radar.co_broke_centre",
    en: "Co-broke Centre",
    ja: "共同ブロークセンター",
    zh: "Co-broke 中心",
    ms: "Pusat Ko-Agency",
  },
  {
    key: "label.sidemenu.real_estate_radar.valuations",
    en: "Valuations",
    ja: "評価",
    zh: "估值中心",
    ms: "Penilaian",
  },
  {
    key: "label.sidemenu.real_estate_radar.subsales",
    en: "SUBSALES",
    ja: "サブセールス",
    zh: "二手房资讯",
    ms: "SUBJUALAN",
  },
  {
    key: "label.sidemenu.real_estate_radar.appointment_letters",
    en: "Appointment Letters",
    ja: "任命書",
    zh: "Appointment Letters",
    ms: "Appointment Letters",
  },
  {
    key: "label.sidemenu.real_estate_radar.confirmation_forms",
    en: "Confirmation Forms",
    ja: "確認フォーム",
    zh: "Confirmation Forms",
    ms: "Confirmation Forms",
  },
  {
    key: "label.sidemenu.real_estate_radar.co_broke_letters",
    en: "Co-broke Letters",
    ja: "共同ブロークレター",
    zh: "Co-broke Letters",
    ms: "Co-broke Letters",
  },
  {
    key: "label.sidemenu.real_estate_radar.authorisation_forms_to_secure_property",
    en: "Authorisation Forms to Secure Property",
    ja: "物件を確保するための承認フォーム",
    zh: "Authorisation Forms to Secure Property",
    ms: "Authorisation Forms to Secure Property",
  },
  {
    key: "label.sidemenu.real_estate_radar.subsales_claims",
    en: "Subsales Claims",
    ja: "サブセールスクレーム",
    zh: "Subsales Claims",
    ms: "Subsales Claims",
  },
  {
    key: "label.sidemenu.engagement_hub",
    en: "Engagement Hub",
    ja: "エンゲージメントハブ",
    zh: "销售中心",
    ms: "Pusat Interaksi",
  },
  {
    key: "label.sidemenu.engagement_hub.marketing",
    en: "MARKETING",
    ja: "マーケティング",
    zh: "营销",
    ms: "PEMASARAN"
  },
  {
    key: "label.sidemenu.engagement_hub.presentation_centre",
    en: "Presentation Centre",
    ja: "プレゼンテーションセンター",
    zh: "演示中心",
    ms: "Pusat Pembentangan",
  },
  {
    key: "label.sidemenu.engagement_hub.video_creator",
    en: "Video Creator",
    ja: "ビデオクリエーター",
    zh: "视频创作平台",
    ms: "Pembuat Video",
  },
  {
    key: "label.sidemenu.engagement_hub.graphic_studio",
    en: "Graphic Studio",
    ja: "グラフィックスタジオ",
    zh: "宣传模板平台",
    ms: "Studio Grafik",
  },
  {
    key: "label.sidemenu.engagement_hub.requests_form",
    en: "Requests Form",
    ja: "リクエストフォーム",
    zh: "申请表格",
    ms: "Borang Permohonan",
  },
  {
    key: "label.sidemenu.engagement_hub.crm",
    en: "CRM",
    ja: "CRM",
    zh: "客户管理中心",
    ms: "CRM",
  },
  {
    key: "label.sidemenu.engagement_hub.my_contacts",
    en: "My Contacts",
    ja: "私の連絡先",
    zh: "我的联络人",
    ms: "Kenalan Saya",
  },
  {
    key: "label.sidemenu.engagement_hub.leads",
    en: "Leads",
    ja: "リード",
    zh: "客户群",
    ms: "Prospek",
  },
  {
    key: "label.sidemenu.financial_hub",
    en: "Financial Hub",
    ja: "ファイナンシャルハブ",
    zh: "财务中心",
    ms: "Pusat Kewangan",
  },
  {
    key: "label.sidemenu.financial_hub.payment_vouchers",
    en: "Payment Vouchers",
    ja: "支払バウチャー",
    zh: "Payment Vouchers",
    ms: "Baucar Pembayaran",
  },
  {
    key: "label.sidemenu.financial_hub.new_project_sales",
    en: "New Project Sales",
    ja: "新しいプロジェクトの販売",
    zh: "新项目业绩",
    ms: "Jualan Projek Baharu",
  },
  {
    key: "label.sidemenu.learning_hub",
    en: "Learning Hub",
    ja: "ラーニングハブ",
    zh: "学习中心",
    ms: "Pusat Pembelajaran",
  },
  {
    key: "label.sidemenu.learning_hub.my_trainings",
    en: "My Trainings",
    ja: "私のトレーニング",
    zh: "我的课程",
    ms: "Latihan Saya",
  },
  {
    key: "label.sidemenu.store_hub",
    en: "Store Hub",
    ja: "ストアハブ",
    zh: "商店中心",
    ms: "Pusat Produk",
  },
  {
    key: "label.sidemenu.store_hub.atlas_store",
    en: "Atlas Store",
    ja: "アトラスストア",
    zh: "Atlas商店",
    ms: "Produk Atlas",
  },
  {
    key: "label.sidemenu.store_hub.jiqi_cafe",
    en: "JIQI's Cafe",
    ja: "JIQIのカフェ",
    zh: "JIQI's Cafe",
    ms: "Kafe JIQI",
  },
  
  // Sidemenu - Admin
  {
    key: "label.sidemenu.user_management",
    en: "User Management",
    ja: "ユーザー管理",
    zh: "用户管理",
    ms: "Pengurusan Pengguna",
  },
  {
    key: "label.sidemenu.user_management.users",
    en: "Users",
    ja: "ユーザー",
    zh: "用户",
    ms: "Pengguna",
  },
  {
    key: "label.sidemenu.user_management.teams",
    en: "Teams",
    ja: "チーム",
    zh: "团队",
    ms: "Pasukan",
  },
  {
    key: "label.sidemenu.user_management.permission_groups",
    en: "Permission Groups",
    ja: "権限グループ",
    zh: "权限组",
    ms: "Kumpulan Kebenaran",
  },
  {
    key: "label.sidemenu.user_management.new_agent_approvals",
    en: "New Agent Approvals",
    ja: "新しいエージェントの承認",
    zh: "待批准新成员",
    ms: "Kelulusan Ejen Baharu",
  },
  {
    key: "label.sidemenu.user_management.admin_roles",
    en: "Admin Roles",
    ja: "管理者の役割",
    zh: "管理员角色",
    ms: "Peranan Pentadbir",
  },
  {
    key: "label.sidemenu.user_management.sessions",
    en: "Sessions",
    ja: "セッション",
    zh: "会话",
    ms: "Sesi",
  },
  {
    key: "label.sidemenu.user_management.otp_verification",
    en: "OTP Verification",
    ja: "OTP認証",
    zh: "OTP验证",
    ms: "Pengesahan OTP",
  },
  {
    key: "label.sidemenu.user_management.license_renewal",
    en: "License Renewal",
    ja: "ライセンス更新",
    zh: "更新执照",
    ms: "Pembaharuan Lesen",
  },
  {
    key: "label.sidemenu.user_management.agreements",
    en: "Agreements",
    ja: "契約",
    zh: "协议",
    ms: "Perjanjian",
  },
  {
    key: "label.sidemenu.branch_management",
    en: "Branch Management",
    ja: "支店管理",
    zh: "分行管理",
    ms: "Pengurusan Cawangan",
  },
  {
    key: "label.sidemenu.branch_management.branches",
    en: "Branches",
    ja: "支店",
    zh: "分行",
    ms: "Cawangan",
  },
  {
    key: "label.sidemenu.branch_management.qr_based_door_entry",
    en: "QR Based Door Entry",
    ja: "QRコードによるドアエントリー",
    zh: "基于二维码的门禁",
    ms: "Kemasukan Pintu Berasaskan QR",
  },
  {
    key: "label.sidemenu.branch_management.meeting_rooms",
    en: "Meeting Rooms",
    ja: "会議室",
    zh: "会议室",
    ms: "Bilik Mesyuarat",
  },
  {
    key: "label.sidemenu.branch_management.meeting_hub",
    en: "Meeting Hub",
    ja: "ミーティングハブ",
    zh: "会议室",
    ms: "Bilik Mesyuarat",
  },
  {
    key: "label.sidemenu.event_management",
    en: "Event Management",
    ja: "イベント管理",
    zh: "事件管理",
    ms: "Pengurusan Acara",
  },
  {
    key: "label.sidemenu.event_management.events",
    en: "Events",
    ja: "イベント",
    zh: "事件",
    ms: "Pengurusan Acara",
  },
  {
    key: "label.sidemenu.event_management.iqi_calendar",
    en: "IQI Calendar",
    ja: "IQI カレンダー",
    zh: "IQI 日历",
    ms: "Kalendar IQI",
  },
  {
    key: "label.sidemenu.subsales_management",
    en: "Subsales Management",
    ja: "サブセールス管理",
    zh: "次销售管理",
    ms: "Pengurusan Subjualan",
  },
  {
    key: "label.sidemenu.subsales_management.listing_centre",
    en: "Listing Centre",
    ja: "リスティングセンター",
    zh: "二手房中心",
    ms: "Pusat Listing",
  },
  {
    key: "label.sidemenu.subsales_management.confirmation_forms_requests",
    en: "Confirmation Forms Requests",
    ja: "確認フォームのリクエスト",
    zh: "确认表单请求",
    ms: "Permintaan Borang Pengesahan",
  },
  {
    key: "label.sidemenu.subsales_management.agent_subsales_accesibility",
    en: "Agent's Subsales Accesibility",
    ja: "エージェントのサブセールスアクセス",
    zh: "代理的次销售可访问性",
    ms: "Kebolehcapaian Subjualan Ejen",
  },
  {
    key: "label.sidemenu.subsales_management.co_agencies",
    en: "Co-Agencies",
    ja: "共同代理店",
    zh: "合作代理",
    ms: "Co-agencies",
  },
  {
    key: "label.sidemenu.subsales_management.tenancy_agreements",
    en: "Tenancy Agreements",
    ja: "賃貸契約",
    zh: "租赁协议",
    ms: "Perjanjian Penyewaan",
  },
  {
    key: "label.sidemenu.subsales_management.inventory_checklist",
    en: "Inventory Checklist",
    ja: "在庫チェックリスト",
    zh: "库存清单",
    ms: "Senarai Semak Inventori",
  },
  {
    key: "label.sidemenu.subsales_management.subsales",
    en: "SUBSALES",
    ja: "サブセールス",
    zh: "次销售",
    ms: "SUBJUALAN",
  },
  {
    key: "label.sidemenu.subsales_management.subsales_claims",
    en: "Subsales Claims",
    ja: "サブセールスクレーム",
    zh: "Subsales Claims",
    ms: "Subsales Claims",
  },
  {
    key: "label.sidemenu.subsales_management.appointment_letters",
    en: "Appointment Letters",
    ja: "任命書",
    zh: "Appointment Letters",
    ms: "Appointment Letters",
  },
  {
    key: "label.sidemenu.subsales_management.confirmation_forms",
    en: "Confirmation Forms",
    ja: "確認フォーム",
    zh: "Confirmation Forms",
    ms: "Confirmation Forms",
  },
  {
    key: "label.sidemenu.subsales_management.co_broke_letters",
    en: "Co-broke Letters",
    ja: "共同ブロークレター",
    zh: "Co-broke Letters",
    ms: "Co-broke Letters",
  },
  {
    key: "label.sidemenu.subsales_management.authorisation_forms_to_secure_property",
    en: "Authorisation Forms to Secure Property",
    ja: "物件を確保するための承認フォーム",
    zh: "Authorisation Forms to Secure Property",
    ms: "Authorisation Forms to Secure Property",
  },
  {
    key: "label.sidemenu.subsales_management.physical_forms",
    en: "Physical Forms",
    ja: "物理フォーム",
    zh: "Physical Forms",
    ms: "Physical Forms",
  },
  {
    key: "label.sidemenu.global_network_management",
    en: "Global Network Management",
    ja: "グローバルネットワーク管理",
    zh: "全球伙伴管理",
    ms: "Pengurusan Rangkaian Global",
  },
  {
    key: "label.sidemenu.global_network_management.global_network",
    en: "Global Network",
    ja: "グローバルネットワーク",
    zh: "全球伙伴",
    ms: "Rangkaian Global",
  },
  {
    key: "label.sidemenu.global_network_management.landmark_management",
    en: "Landmark Management",
    ja: "ランドマーク管理",
    zh: "地标管理",
    ms: "Pengurusan Mercu Tanda",
  },
  {
    key: "label.sidemenu.global_network_management.localised_centre",
    en: "Localised Centre",
    ja: "ローカライズセンター",
    zh: "本地化中心",
    ms: "Pusat Setempat",
  },
  {
    key: "label.sidemenu.marketing_centre",
    en: "Marketing Centre",
    ja: "マーケティングセンター",
    zh: "营销中心",
    ms: "Pusat Pemasaran",
  },
  {
    key: "label.sidemenu.marketing_centre.leads",
    en: "Leads",
    ja: "リード",
    zh: "客户群",
    ms: "Prospek",
  },
  {
    key: "label.sidemenu.marketing_centre.presentation_centre",
    en: "Presentation Centre",
    ja: "プレゼンテーションセンター",
    zh: "演示中心",
    ms: "Pusat Persembahan",
  },
  {
    key: "label.sidemenu.marketing_centre.video_templates",
    en: "Video Templates",
    ja: "ビデオテンプレート",
    zh: "视频模板",
    ms: "Templat Video",
  },
  {
    key: "label.sidemenu.marketing_centre.personalised_videos",
    en: "Personalised Videos",
    ja: "パーソナライズされたビデオ",
    zh: "个性化视频",
    ms: "Video Diperibadikan",
  },
  {
    key: "label.sidemenu.marketing_centre.graphic_templates",
    en: "Graphic Templates",
    ja: "グラフィックテンプレート",
    zh: "图形模板",
    ms: "Templat Grafik",
  },
  {
    key: "label.sidemenu.marketing_centre.graphic_studio",
    en: "Graphic Studio",
    ja: "グラフィックスタジオ",
    zh: "宣传模板平台",
    ms: "Studio Grafik",
  },
  {
    key: "label.sidemenu.marketing_centre.email_templates",
    en: "Email Templates",
    ja: "メールテンプレート",
    zh: "电子邮件模板",
    ms: "Templat E-mel",
  },
  {
    key: "label.sidemenu.marketing_centre.email_campaigns",
    en: "Email Campaigns",
    ja: "メールキャンペーン",
    zh: "电子邮件活动",
    ms: "Kempen E-mel",
  },
  {
    key: "label.sidemenu.atlas_store",
    en: "Atlas Store",
    ja: "アトラスストア",
    zh: "Atlas商店",
    ms: "Kedai Atlas",
  },
  {
    key: "label.sidemenu.atlas_store.products",
    en: "Products",
    ja: "製品",
    zh: "产品",
    ms: "Produk",
  },
  {
    key: "label.sidemenu.atlas_store.orders",
    en: "Orders",
    ja: "注文",
    zh: "订单",
    ms: "Pesanan",
  },
  {
    key: "label.sidemenu.atlas_store.coupons",
    en: "Coupons",
    ja: "クーポン",
    zh: "优惠券",
    ms: "Kupon",
  },
  {
    key: "label.sidemenu.atlas_store.name_card_batches",
    en: "Name Card Batches",
    ja: "名刺バッチ",
    zh: "名片批次",
    ms: "Kelompok Kad Nama",
  },
  {
    key: "label.sidemenu.project_management",
    en: "Project Management",
    ja: "プロジェクト管理",
    zh: "项目管理",
    ms: "Pengurusan Projek",
  },
  {
    key: "label.sidemenu.project_management.project_centre",
    en: "Project Centre",
    ja: "プロジェクトセンター",
    zh: "新房产项目中心",
    ms: "Pusat Projek",
  },
  {
    key: "label.sidemenu.project_management.projects",
    en: "Projects",
    ja: "プロジェクト",
    zh: "项目",
    ms: "Projek",
  },
  {
    key: "label.sidemenu.project_management.project_sales",
    en: "Project Sales",
    ja: "プロジェクトセールス",
    zh: "新项目业绩",
    ms: "Jualan Projek",
  },
  {
    key: "label.sidemenu.training_management",
    en: "Training Management",
    ja: "トレーニング管理",
    zh: "培训管理",
    ms: "Pengurusan Latihan",
  },
  {
    key: "label.sidemenu.training_management.training_attendances",
    en: "Training Attendances",
    ja: "トレーニング出席",
    zh: "培训出席",
    ms: "Kehadiran Latihan",
  },
  {
    key: "label.sidemenu.training_management.current_exam",
    en: "Current Exam",
    ja: "現在の試験",
    zh: "当前考试",
    ms: "Peperiksaan Semasa",
  },
  {
    key: "label.sidemenu.training_management.exam_results",
    en: "Exam Results",
    ja: "試験結果",
    zh: "考试成绩",
    ms: "Keputusan Peperiksaan",
  },
  {
    key: "label.sidemenu.training_management.tutorials",
    en: "Tutorials",
    ja: "チュートリアル",
    zh: "Atlas 教程",
    ms: "Tutorial",
  },
  {
    key: "label.sidemenu.analytics_centre",
    en: "Analytics Centre",
    ja: "アナリティクスセンター",
    zh: "分析中心",
    ms: "Pusat Analisa",
  },
  {
    key: "label.sidemenu.analytics_centre.user_activities",
    en: "User Activities",
    ja: "ユーザーアクティビティ",
    zh: "用户活动",
    ms: "Aktiviti Pengguna",
  },
  {
    key: "label.sidemenu.analytics_centre.admin_activities",
    en: "Admin Activities",
    ja: "管理者の活動",
    zh: "管理员活动",
    ms: "Aktiviti Pentadbir",
  },
  {
    key: "label.sidemenu.analytics_centre.secondary_market",
    en: "Secondary Market",
    ja: "セカンダリーマーケット",
    zh: "二级市场",
    ms: "Pasaran Sekunder",
  },
  {
    key: "label.sidemenu.due_diligence_hub",
    en: "Due Diligence Hub",
    ja: "デューデリジェンスハブ",
    zh: "尽职调查中心",
    ms: "Hab Usaha Wajar",
  },
  {
    key: "label.sidemenu.due_diligence_hub.customer_due_diligence",
    en: "Customer Due Diligence",
    ja: "顧客デューデリジェンス",
    zh: "客户尽职调查",
    ms: "Usaha Wajar Pelanggan",
  },
  {
    key: "label.sidemenu.due_diligence_hub.amla_exceptions",
    en: "AMLA Exceptions",
    ja: "AMLA例外",
    zh: "AMLA例外",
    ms: "Pengecualian AMLA",
  },
  {
    key: "label.sidemenu.valuations",
    en: "Valuations",
    ja: "評価",
    zh: "估值中心",
    ms: "Penilaian",
  },
  {
    key: "label.sidemenu.valuations.real_estate_corporate",
    en: "Real Estate/Corporate",
    ja: "不動産/企業",
    zh: "房地产/企业",
    ms: "Hartanah/Korporat",
  },
  {
    key: "label.sidemenu.dashboard_management",
    en: "Dashboard Management",
    ja: "ダッシュボード管理",
    zh: "控制面板管理",
    ms: "Pengurusan Papan Pemuka",
  },
  {
    key: "label.sidemenu.dashboard_management.newsfeeds",
    en: "Newsfeeds",
    ja: "ニュースフィード",
    zh: "新闻提要",
    ms: "Suapan Berita",
  },
  {
    key: "label.sidemenu.dashboard_management.motd",
    en: "MOTD",
    ja: "MOTD",
    zh: "MOTD",
    ms: "MOTD",
  },
  {
    key: "label.sidemenu.app_management",
    en: "App Management",
    ja: "アプリ管理",
    zh: "应用管理",
    ms: "Pengurusan Apl",
  },
  {
    key: "label.sidemenu.app_management.app_themes",
    en: "App Themes",
    ja: "アプリテーマ",
    zh: "应用主题",
    ms: "Tema Apl",
  },
  {
    key: "label.sidemenu.app_management.app_version",
    en: "App Version",
    ja: "アプリバージョン",
    zh: "应用版本",
    ms: "Versi Apl",
  },
  {
    key: "label.sidemenu.api_keys",
    en: "API Keys",
    ja: "APIキー",
    zh: "API密钥",
    ms: "Kunci API",
  },
  {
    key: "label.sidemenu.iqi_platforms",
    en: "IQI Platforms",
    ja: "IQI プラットフォーム",
    zh: "IQI 平台",
    ms: "Platform IQI",
  },
  {
    key: "label.sidemenu.iqi_platforms.iqi_drive",
    en: "IQI Drive",
    ja: "IQI ドライブ",
    zh: "IQI 网盘",
    ms: "Pengkalan Data IQI ",
  },
  {
    key: "label.sidemenu.iqi_platforms.iqi_store",
    en: "IQI Store",
    ja: "IQI ストア",
    zh: "IQI 商店",
    ms: "Produk IQI",
  },
  {
    key: "label.sidemenu.iqi_platforms.iqi_academy",
    en: "IQI Academy",
    ja: "IQI アカデミー",
    zh: "IQI 学堂",
    ms: "Akademi IQI",
  },
];