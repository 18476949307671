import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      passwordConfirmationError: "",
      emailError: "",
      newPersonalAssistant: {
        full_name: "",
        display_name: "",
        email: "",
        mobile_contact_number: "",
        password: "",
        password_confirmation: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeCreateHOC = (val, context) => this.setState({ [context]: val });

    createNewPA = (val) =>
      Post(
        `/personal_assistants`,
        val,
        this.createNewPASuccess,
        this.createNewPAError,
        this.load,
      );
    createNewPASuccess = () => {
      this.props.getPAList();
      requestSuccess("New Personal Assistant created successfully.");
      this.props.onChangeHOC(false, "showCreateModal");

      this.setState({
        newPersonalAssistant: {
          full_name: "",
          display_name: "",
          email: "",
          mobile_contact_number: "",
          password: "",
          password_confirmation: "",
        },
      });
    };
    createNewPAError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            emailError={this.state.emailError}
            onLoadCreatePA={this.state.loading}
            newPersonalAssistant={this.state.newPersonalAssistant}
            passwordConfirmationError={this.state.passwordConfirmationError}
            onChangeCreateHOC={this.onChangeCreateHOC}
            createNewPA={this.createNewPA}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
