import { Grid, MenuItem } from "@material-ui/core";

import AtlasFormInput from "components/Input/formInput";
import AtlasSelect from "components/Select/new";
import AtlasRadioGroup from "components/RadioGroup";
import CustomUpload from "components/Input/upload";
import CustomDatepicker from "components/Input/datepicker";
import CustomCheckbox from "components/Checkbox";

import { replaceNumeric } from "utils/replaceNumeric";
import CustomFormInput from "containers/Register/components/formInput";

const FormInput = ({ payload = [], config = {}, index, onChange, language }) => {
  switch (config.type) {
    case "date":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <CustomDatepicker
            value={ config.valueCustom
              ? config.valueCustom({ id: config.id, val: payload,})
              : payload?.[config.id]
            }
            onChange={(val) => { config.onChange
              ? config.onChange({ id: config.id, val })
              : onChange(val, config.id);
            }}
          />
        </Grid>
      );
    case "select":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <AtlasSelect
            value={ config.valueCustom
              ? config.valueCustom({ id: config.id, val: payload })
              : payload?.[config.id]
            }
            disabled={config.disabled}
            onChange={(e) => {config.onChange
              ? config.onChange({id: config.id, val: e.target.value})
              : onChange(e.target.value, config.id);
            }}
            language={language}
            required={config.required}
          >
            {config.options?.length === 0 && (
              <MenuItem value={0}>None</MenuItem>
            )}
            {config.options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </AtlasSelect>
        </Grid>
      );
    case "radio":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <br />
          <AtlasRadioGroup
            horizontal={true}
            options={config.options}
            checkedValue={ config.valueCustom
              ? config.valueCustom({id: config.id, val: payload})
              : payload?.[config.id]
            }
            selectedRadioValue={(val) => config.onChange
              ? config.onChange({ id: config.id, val })
              : onChange(val, config.id)
            }
          />
        </Grid>
      );
    case "checkbox":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          <CustomCheckbox
            content={config.label}
            checked={ config.valueCustom
              ? config.valueCustom({id: config.id, val: payload})
              : payload?.[config.id]
            }
            labelClassname={config.labelClassname}
            onChangeCheckboxValue={() => {
              const currentValue = config.valueCustom
                ? config.valueCustom({ id: config.id, val: payload })
                : payload?.[config.id];
              const newValue = !currentValue;
              if (config.onChange) {
                config.onChange({ id: config.id, val: newValue });
              } else {
                onChange(newValue, config.id);
              }
            }}
          />
        </Grid>
      );
    case "file":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <CustomUpload
            required={config.required}
            multiple={false}
            language={language}
            labelText={config.labelText}
            accepts={config.accepts || "image/*"}
            containerClass={config.className || ""}
            hideLabelDragDrop={config.hideLabelDragDrop}
            imageUrl={
              config.valueCustom
                ? config.valueCustom({
                    id: config.id,
                    val: payload,
                  })
                : payload?.[config.id]
            }
            uploadImage={(val, key) =>
              config.onChange
                ? config.onChange({ id: config.id, val })
                : onChange(val, config.id)
            }
          />
        </Grid>
      );
    case "custom-form-input":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <CustomFormInput
            value={
              config.valueCustom
                ? config.valueCustom({
                    id: config.id,
                    val: payload,
                  })
                : payload?.[config.id]
            }
            onChange={(val) =>
              config.onChange
              ? config.onChange({ id: config.id, val })
              : onChange(val, config.id)
            }
            language={language}
            required={config.required}
            isLoading={config.isLoading}
            successMessage={config.successMessage}
            errorMessage={config.errorMessage}
          />
        </Grid>
      );
    case "custom-output":
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          {config.content()}
        </Grid>
      );
    default:
      return (
        <Grid key={index} item md={config.span || 12} xs={12}>
          {config.label && <label className={"at-profile__title mb-1"}>{config.label}</label>}
          <AtlasFormInput
            type={config.type || "text"}
            className={"mb-0"}
            containerStyle={{
              ...config.containerStyle,
              ...config.type !== "textarea"
                ? { containerStyle: { height: 40 } }
                : {}
            }}
            inputStyle={{ fontSize: 14 }}
            placeholder={config.placeholder}
            value={
              config.valueCustom
                ? config.valueCustom({
                    id: config.id,
                    val: payload,
                  })
                : payload?.[config.id]
            }
            onChangeValue={(val) =>
              config.onChange
                ? config.onChange({ id: config.id, val })
                : onChange(
                    config.isReplaceNumeric ? replaceNumeric(val) : val,
                    config.id
                  )
            }
            language={language}
            disabled={config.disabled}
            required={config.required}
          />
        </Grid>
      );
  }
};

export default FormInput;
