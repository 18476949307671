import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import AtCardContent from "components/Card/gooseberry";

import ProfileImage from "assets/images/av-male.jpg";
import NoPhoto from "assets/images/no_photo_available.png";
import "stylesheets/components/table/index.scss";

const CustomTable = ({
  className,
  rowData: initialRowData,
  actionColumnContent,
}) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  return (
    <div className={`d-flex flex-column mb-3 ${className}`}>
      <div className={`at-global_network-listing`}>
        {rowData?.map((item, index) => {
            if (item) {
              return (
                <div
                  key={`GlobalNetwork-${index}-${item.id}`}
                  className="at-global_network-cont"
                >
                  <AtCardContent
                    rowItem={{
                      ...item,
                      countryLogo:
                        item.flag_image_url.indexOf("missing") > 0
                          ? NoPhoto
                          : item.flag_image_url,
                      profileImage:
                        item.avatar_url.indexOf("missing") > 0
                          ? ProfileImage
                          : item.avatar_url,
                    }}
                    tooltipID={`GlobalNetwork-${index}`}
                    actionColumnContent={actionColumnContent}
                  />
                </div>
              );
            }
          })}
      </div>
      {!(rowData && rowData.length > 0) && (
        <div className="at-empty-state">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p>No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
