import React from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { RiUser3Fill } from "react-icons/ri";
import { GrUserAdmin } from "react-icons/gr";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";

import { getColorBadge } from "dictionary/badgeColor";

export const LeadTypeColor = [
  { value: "Investment", colorName: "Teal" },
  { value: "Rental", colorName: "Fuchsia" },
  { value: "Resale", colorName: "Violet" },
  { value: "Recruitment", colorName: "Cyan" },
  { value: "Service Provider", colorName: "Violet" },
  { value: "Enquiry", colorName: "Fuchsia" },
  { value: "Renovation", colorName: "Lime" },
  { value: undefined, colorName: "Grey" },
];

export const StatusColor = [
  { value: "New", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Keep in View", colorName: "Blue" },
  { value: "Interviewed", colorName: "Violet" },
  { value: "Reconnect", colorName: "Yellow" },
  { value: "Completed", colorName: "Green" },
  { value: "Hired", colorName: "Green" },
  { value: "Contacted & Pending Response", colorName: "Blue" },
  { value: "Not Qualified", colorName: "Red" },
  { value: "Scheduled Interview", colorName: "Yellow" },
  { value: "Deleted", colorName: "Red" },
  { value: "Not Interested", colorName: "Grey" },
];

export const priorityTypes = [
  { label: "Urgent", value: 1, colorName: "Red" },
  { label: "High", value: 2, colorName: "Yellow" },
  { label: "Normal", value: 3, colorName: "Green" },
  { label: "Low", value: 4, colorName: "Blue" },
];

export const SuperAdminHeaderData = (props) => [
  CheckboxColumn(props),
  {
    header: "Leads",
    renderColumn: (rowData) => (
      <>
        <CustomCheckbox
          labelClassname={"at-table--checkbox-mobile"}
          checked={_.includes(props.checkedArray, rowData.id)}
          onChangeCheckboxValue={() => props.onHandleCheckBox(rowData.id)}
        />
        <UserInfo
          name={rowData.buyer_name || "N/A"}
          email={rowData.buyer_email || "N/A"}
          phone_number={rowData.buyer_phone_number || "N/A"}
        />
      </>
    ),
    columnStyle: { width: "150%" },
    columnClass: "at-table-head__hide-mobile",
  },
  {
    header: "Type",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(LeadTypeColor, { value: rowData.type }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.type || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Priority",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(priorityTypes, { value: rowData.priority_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName || "Grey") }}
        >
          {rowData.priority || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Creation Date",
    accessor: "creation_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Inquiry Date",
    accessor: "inquiry_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Assign Date",
    accessor: "assign_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Asignee",
    renderColumn: (rowData) => (
      <>
        <div className="d-flex flex-wrap">
          <Tooltip title={"Admin"} placement={"top"}>
            <GrUserAdmin style={{ width: 16, height: 16, marginRight: 5 }} />
          </Tooltip>
          <span>{rowData.admin_name || "N/A"}</span>
        </div>
        <div className="d-flex mt-1 flex-wrap">
          <Tooltip title={"Agent"}>
            <RiUser3Fill style={{ width: 16, height: 16, marginRight: 5 }} />
          </Tooltip>
          <span>{rowData.agent_name || "N/A"}</span>
        </div>
      </>
    ),
    columnStyle: { width: "150%" },
  },
  {
    header: "Source",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) =>
      rowData.lead_source.length > 0 && (
        <div className="at-lead-table__source">
          {rowData.lead_source.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      ),
  },
  {
    header: "Status",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

export const AdminHeaderData = (props) => [
  CheckboxColumn(props),
  {
    header: "Leads",
    renderColumn: (rowData) => (
      <>
        <CustomCheckbox
          labelClassname={"at-table--checkbox-mobile"}
          checked={_.includes(props.checkedArray, rowData.id)}
          onChangeCheckboxValue={() => props.onHandleCheckBox(rowData.id)}
        />
        <UserInfo
          name={rowData.buyer_name || "N/A"}
          email={rowData.buyer_email || "N/A"}
          phone_number={rowData.buyer_phone_number || "N/A"}
        />
      </>
    ),
    columnStyle: { width: "150%" },
    columnClass: "at-table-head__hide-mobile",
  },
  {
    header: "Type",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(LeadTypeColor, { value: rowData.type }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.type || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Priority",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(priorityTypes, { value: rowData.priority_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName || "Grey") }}
        >
          {rowData.priority || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Creation Date",
    accessor: "creation_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Inquiry Date",
    accessor: "inquiry_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Assign Date",
    accessor: "assign_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Source",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) =>
      rowData.lead_source.length > 0 && (
        <div className="at-lead-table__source">
          {rowData.lead_source.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      ),
  },
  {
    header: "Agent",
    accessor: "agent_name",
    columnStyle: { width: "150%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

export const AgentHeaderData = (props) => [
  CheckboxColumn(props),
  {
    header: "Leads",
    renderColumn: (rowData) => (
      <>
        <CustomCheckbox
          labelClassname={"at-table--checkbox-mobile"}
          checked={_.includes(props.checkedArray, rowData.id)}
          onChangeCheckboxValue={() => props.onHandleCheckBox(rowData.id)}
        />
        <UserInfo
          name={rowData.buyer_name || "N/A"}
          email={rowData.buyer_email || "N/A"}
          phone_number={rowData.buyer_phone_number || "N/A"}
        />
      </>
    ),
    columnStyle: { width: "150%" },
    columnClass: "at-table-head__hide-mobile",
  },
  {
    header: "Type",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(LeadTypeColor, { value: rowData.type }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.type || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Priority",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(priorityTypes, { value: rowData.priority_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName || "Grey") }}
        >
          {rowData.priority || "N/A"}
        </div>
      );
    },
  },
  {
    header: "Inquiry Date",
    accessor: "inquiry_date",
    columnStyle: { width: "60%" },
  },
  {
    header: "Assign Date",
    accessor: "assign_date",
    columnStyle: { width: "60%" },
  },
  {
    header: "Source",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) =>
      rowData.lead_source.length > 0 && (
        <div className="at-lead-table__source">
          {rowData.lead_source.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      ),
  },
  {
    header: "Status",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

const CheckboxColumn = ({
  leadData,
  can_update,
  checkedArray,
  onHandleCheckBox,
  onHandleCheckAllBox,
}) => ({
  header: () => (
    <span className="at-table-head-checkbox_responsive">
      <CustomCheckbox
        checked={
          checkedArray.length > 0 && checkedArray.length === leadData.length
        }
        onChangeCheckboxValue={() => onHandleCheckAllBox()}
      />
    </span>
  ),
  renderColumn: (rowData) => (
    <CustomCheckbox
      checked={_.includes(checkedArray, rowData.id)}
      onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
    />
  ),
  columnStyle: { width: "20%" },
  columnClass: can_update ? "at-table--checkbox-column" : "d-none",
  columnStyle: { width: "10%", gridColumn: "1/-1" },
});
