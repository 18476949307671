import Axios from "axios";

import { FCM_STORE_USER_TOKEN } from "actions/type";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem, storeItem } from "utils/tokenStore";

export function storeFirebaseUserToken(firebaseToken) {
  return (dispatch) => {
    const tokenName = window.location.href.includes("/admin-impersonate")
      ? "IQI_ATLAS_JWT_AGENT_TOKEN"
      : "IQI_ATLAS_JWT_TOKEN";
    const token = getItem(tokenName);
    Axios.defaults.headers = {
      common: {
        Authorization: `JWT ${token}`,
      },
      "Access-Control-Allow-Origin": "*",
    };
    dispatch(beginAjaxCall());
    Axios.post(`${getDomainURL()}/profiles/token`, {
      firebase_token: firebaseToken,
    })
      .then((response) => {
        storeItem("IQI_ATLAS_FIREBASE_TOKEN", firebaseToken);
        dispatch(storeFirebaseUserTokenSuccess(response.data));
        dispatch(ajaxCallSuccess());
      })
      .catch((error) => {
        dispatch(
          ajaxCallError(error, () =>
            dispatch(storeFirebaseUserToken(firebaseToken)),
          ),
        );
      });
  };
}

function storeFirebaseUserTokenSuccess(payload) {
  return {
    type: FCM_STORE_USER_TOKEN,
    payload,
  };
}
