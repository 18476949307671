import React, { Component } from "react";
import _ from "lodash";

import CustomTableHeader from "./components/header";
import CustomTableContent from "./components/content";

class SimpleTable extends Component {
  state = {
    rowData: [],
    headerRearrangeData: {},
  };

  componentDidMount = () => this.setState({ rowData: this.props.rowData });

  componentDidUpdate = (pp) => {
    pp.rowData !== this.props.rowData &&
      this.setState({
        rowData: this.props.rowData,
      });
  };

  renderTableContent = () => {
    const {
      rowData,
      headerData,
      actionColumn,
      rowKey,
      actionColumnContent,
      booleanColumn,
      thousandSeparatorColumn,
      sendEmailDocType,
      downloadDocType,
      disabledDelete,
      onClickJoin,
      renderNoData,
      onLoadRegisterTraining,
      showConfirmationModal,
      onChangeRegisterHOC,
    } = this.props;
    if (rowData && rowData.length > 0) {
      return (
        rowData &&
        this.state.rowData.map((rowItem, index) => {
          return (
            <CustomTableContent
              key={index}
              disabledDelete={disabledDelete}
              headerData={headerData}
              actionColumn={actionColumn}
              actionColumnContent={actionColumnContent}
              rowItem={rowItem}
              detailsClick={(param) => this.props.detailsClick(param)}
              deleteClick={(param) => this.props.deleteClick(param)}
              sendEmail={(param) => this.props.sendDocClick(param)}
              downloadClick={(param) => this.props.downloadClick(param)}
              rowKey={rowKey}
              headerRearrangeData={this.state.headerRearrangeData}
              booleanColumn={booleanColumn}
              thousandSeparatorColumn={thousandSeparatorColumn}
              sendEmailDocType={sendEmailDocType}
              showConfirmationModal={showConfirmationModal}
              onChangeRegisterHOC={onChangeRegisterHOC}
              onLoadRegisterTraining={onLoadRegisterTraining}
              onClickJoin={onClickJoin}
              downloadDocType={downloadDocType}
            />
          );
        })
      );
    } else {
      return (
        <div className="at-table-row-cont mb-0">
          <ul className="at-table-row grid-controller">
            <li
              className="at-table-row__item at-table-row__item-empty training-table__no-data"
              style={{ padding: 20 }}
            >
              {renderNoData()}
            </li>
          </ul>
        </div>
      );
    }
  };

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      }
    });

  render = () => {
    const {
      className,
      headerData,
      actionColumn,
      renderTableTitle,
      actionColumnContent,
      actionColumnLabel,
    } = this.props;
    return (
      <div className={`${className}`}>
        <div className="at-table at-table--set">
          {renderTableTitle && (
            <div
              className="at-table-head"
              style={{ display: "flex", padding: 0 }}
            >
              {renderTableTitle()}
            </div>
          )}
          <CustomTableHeader
            data={headerData}
            actionColumnLabel={actionColumnLabel}
            actionColumnContent={actionColumnContent}
            actionColumn={actionColumn}
            onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
          />
          {this.renderTableContent()}
        </div>
      </div>
    );
  };
}

export default SimpleTable;
