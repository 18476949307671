import React, { Component } from "react";
import { ClipLoader } from "react-spinners";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

class UploadStatusModal extends Component {
  render = () => {
    const { uploadInProgress, tempUploadDocument } = this.props;

    return (
      <>
        <h2 className={"at-card__title"}>Uploaded Docs</h2>
        <div className={"mt-20"}>
          {tempUploadDocument.map((item) => (
            <div
              style={{
                border: "1px solid",
                padding: 10,
              }}
            >
              <div className={"d-flex justify-content-between"}>
                <p>{item.document_file_name}</p>
                {item.loading && (
                  <ClipLoader
                    sizeunit={"px"}
                    size={20}
                    color={"#16232c"}
                    loading={item.loading}
                  />
                )}
                {item.result === "error" && (
                  <AiFillCloseCircle style={{ width: 25, color: "#D93335" }} />
                )}
                {item.result === "success" && (
                  <AiFillCheckCircle style={{ width: 25, color: "#2ECC71" }} />
                )}
              </div>
              {item.messages && (
                <p style={{ fontStyle: "italic", fontSize: 12 }}>
                  {item.messages}
                </p>
              )}
            </div>
          ))}
        </div>
        <button
          disabled={uploadInProgress}
          onClick={() => this.props.onClose()}
          className={"btn-new btn-new--secondary mt-20"}
        >
          Close
        </button>
      </>
    );
  };
}

export default UploadStatusModal;
