import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { GetFile } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    exportStats = (dataToSubmit) => {
      GetFile(
        `/admin/quote_audio_stats/export?date_from=${dataToSubmit.start_date}&date_to=${dataToSubmit.end_date}`,
        `MTOD_Statistic_${dataToSubmit.start_date}_${dataToSubmit.end_date}.xls`,
        () => {},
        this.exportStatsError,
        this.load,
      );
    };
    exportStatsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadExport={this.state.loading}
            exportStats={this.exportStats}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
