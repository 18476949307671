import React from "react";
import Slider from "react-slick";

import defaultImage from "assets/images/iqi_logo_only.png";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} at-presentation__slick-slider-arrow `}
      style={{ ...style, right: 10 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} at-presentation__slick-slider-arrow `}
      style={{ ...style, left: 10 }}
      onClick={onClick}
    />
  );
}

const settings = {
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  centerPadding: "60px",
  swipeToSlide: true,
  lazyLoad: true,
  className: "at-presentation__slick-initialized",
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

const PhotoSlider = ({ id, images = [], content }) => {
  return (
    <Slider {...settings}>
      {images.length > 0 &&
        images.map((item, index) => (
          <div
            className="at-presentation-slider_item"
            key={`img-${id}-${index}`}
          >
            <div className="at-presentation__image">
              <div className="at-presentation__img-cont">
                <img
                  style={{ width: "100%" }}
                  className={`at-presentation-slider_image`}
                  src={item ? item : defaultImage}
                  alt={`ProjectSlideshowImage-${index}`}
                />
                <div className="at-presentation__gradient_foreground"></div>
              </div>
            </div>
            {content}
          </div>
        ))}
      {images.length < 1 && (
        <div className="at-presentation-slider_item" key={`img-${id}-0`}>
          <div className="at-presentation__image">
            <div className="at-presentation__img-cont">
              <img
                className={`at-presentation-slider_image`}
                src={defaultImage}
              />
              <div className="at-presentation__gradient_foreground"></div>
            </div>
          </div>
          {content}
        </div>
      )}
    </Slider>
  );
};

export default PhotoSlider;
