import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";

import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const FieldData = [
  { label: "Name", value: "name" },
  { label: "Description", value: "description" },
];

const Create = ({
  onLoadPermissionGroupUsers,
  onClose,

  createPermissionGroup,
}) => {
  const [inputErrors, setInputErrors] = useState({
    name: "",
    description: "",
  });

  const onChangeInputErrors = useCallback(
    _.debounce((context, value) => onChangePermissionErrors(context, value)),
    750,
    [inputErrors],
  );
  const onChangePermissionErrors = (context, value) => {
    setInputErrors((prev) => ({
      ...prev,
      [context]: !value ? "The entry cannot be empty." : "",
    }));
  };

  const [createData, onChangeCreate] = useState({
    name: "",
    description: "",
  });

  const disableSubmit = useMemo(() => {
    return _.values(createData).some((val) => !val);
  }, [createData]);

  const onChangeField = useCallback(
    (key, val) => {
      let tmp = _.cloneDeep(createData);
      tmp[key] = val;

      onChangeCreate(tmp);
    },
    [createData],
  );

  return (
    <ModalDialog
      title={"New Permission Group"}
      onLoad={onLoadPermissionGroupUsers}
      responsiveSize="lg"
      onClose={() => onClose()}
      children={
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          {FieldData.map((field) => (
            <section key={`${field.value}_field`} className="grid-full-col">
              <h2 className="at-form-input__title" required>
                {field.label}
              </h2>
              <CustomFormInput
                type="text"
                required={true}
                inputError={inputErrors[field.value]}
                placeholder={`Enter ${field.label}`}
                value={createData[field.value]}
                onChangeValue={(val) => {
                  onChangeField([field.value], val);
                  onChangeInputErrors([field.value], val);
                }}
              />
            </section>
          ))}
        </form>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            className="btn-new btn-new--primary"
            disabled={disableSubmit}
            onClick={() => createPermissionGroup(createData)}
          >
            Create
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
};

export default Create;
