import React, { Component } from "react";

import CustomCheckbox from "components/Checkbox";

import "stylesheets/components/textarea/index.scss";

class CustomTextarea extends Component {
  onChangeValue(event) {
    this.props.onChangeValue(event.target.value);
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (this.props.checkedPressEnterToSend) {
        this.props.onEnterToSend(this.props.value);
      }
    }
  }

  renderInstruction = (instruction, required) => {
    if (instruction) {
      return <div className="at-form-input__instruction" style={required ? { width: "calc(100% - 70px)" } : {}}>{instruction}</div>;
    }
  }

  renderErrorMessage = (error) => {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  }

  renderRequired = (param) => {
    if (param) {
      return <div className="at-form-input__required">required *</div>;
    }
  }

  renderPressEnterCheckbox = () =>{
    const {
      checkedPressEnterToSend,
      checkboxInstruction,
      onChangeEnterCheckboxValue,
    } = this.props;
    return (
      <CustomCheckbox
        labelStyle={{
          position: "absolute",
          marginTop: "-28px",
        }}
        checked={checkedPressEnterToSend}
        content={checkboxInstruction}
        onChangeCheckboxValue={(e) =>
          onChangeEnterCheckboxValue(e.target.checked)
        }
      />
    );
  }

  render() {
    const {
      required,
      value,
      disabled,
      inputError,
      placeholder,
      className,
      containerClassName = "",
      containerStyle,
      instruction,
      pressEnterToSend,
      style,
      rows = 2,
    } = this.props;
    return (
      <div
        style={{ position: "relative", ...containerStyle }}
        className={`${containerClassName} ${inputError ? "at-select--error" : ""} ${instruction ? "mb-20" : ""}`}
        onKeyDown={(e) => this.onKeyPress(e)}>
        <textarea
          rows={rows}
          className={`at-text_area ${className || ""}`}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          value={value}
          onChange={(event) => this.onChangeValue(event)}
        />
        {this.renderRequired(required)}
        {this.renderErrorMessage(inputError)}
        {this.renderInstruction(instruction, required)}
        {pressEnterToSend && this.renderPressEnterCheckbox()}
      </div>
    );
  }
}

export default CustomTextarea;