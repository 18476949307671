import React, { Component } from "react";
import ReactModal from "react-modal";
import { compose } from "redux";
import { Dialog, DialogContent } from "@material-ui/core";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import CustomTable from "components/Table";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomCloseButton from "components/Button/prev";

import VersionCheckHOC from "actions/versionCheck";
import PerformanceHOC from "./actions";

import { initGA } from "utils/ga";

const tableData = [
  {
    label: "Name",
    value: "agentName",
    columnClass: "mt-0",
  },
  {
    label: "Email",
    value: "email",
    columnClass: "mt-0",
  },
  {
    label: "Mobile Number",
    value: "mobileNumber",
    columnClass: "mt-0",
  },
  {
    label: "REN tag",
    value: "hasRenTag",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center mt-0",
  },
  {
    label: "Branch",
    value: "branch",
    columnClass: "mt-0",
  },
  {
    label: "Member Since",
    value: "dateJoined",
    columnClass: "mt-0",
  },
];

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingRight: "40px",
  },
};

const tabOptions = ["3 Months", "6 Months"];

class TeamPerformance extends Component {
  componentDidMount = () => {
    const { team_name, encoded_identity } = this.props;
    initGA("/dashboard/team/team-performance");
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate"
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.onChangePerformanceHOC(
      ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) > -1
        ? `${team_name}'s Underperforming Members`
        : "Underperforming Members",
      "title"
    );

    this.props.getTeamPerformance(
      ["Super Admin", "Admin"].indexOf(tmpProfileReducer.role) > -1
        ? encoded_identity
        : tmpProfileReducer.encoded_identity
    );
  };

  detailsClick = (val) => {
    Promise.all([
      this.props.onChangePerformanceHOC(val, "selectedPerformance"),
    ]).then(() => {
      this.props.onChangePerformanceHOC(true, "showDetailsModal");
    });
  };

  renderDetailsModalContent() {
    const renderInfo = (param) => {
      return <span style={{ marginLeft: 10 }}>{`${param}`}</span>;
    };
    return (
      <div className="at-dropbear-panel__body">
        <div
          className="at-dropbear-panel__main"
          style={{ flexDirection: "column" }}
        >
          <div className="text-row">
            <b>{`Name: `}</b>
            {this.props.selectedPerformance
              ? renderInfo(this.props.selectedPerformance.agentName)
              : ""}
          </div>
          <div className="text-row">
            <b>{`Date Joined: `}</b>
            {this.props.selectedPerformance
              ? renderInfo(this.props.selectedPerformance.dateJoined)
              : ""}
          </div>
          <div className="text-row">
            <b>{`Email: `}</b>
            {this.props.selectedPerformance
              ? renderInfo(this.props.selectedPerformance.email)
              : ""}
          </div>
          <div className="text-row">
            <b>{`Mobile Number: `}</b>
            {this.props.selectedPerformance
              ? renderInfo(this.props.selectedPerformance.mobileNumber)
              : ""}
          </div>
        </div>
      </div>
    );
  }

  renderDetailsModal() {
    /* The agent details modal */
    const renderTitle = () => {
      let info = this.props.selectedPerformance;
      if (info) {
        return `${info.agentName}'s Contact and Information`;
      }
    };
    return (
      <ReactModal
        isOpen={this.props.showDetailsModal}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <div className="at-dropbear-panel">
          <div
            className="at-dropbear-panel__header"
            style={{ padding: 0, paddingTop: 10 }}
          >
            <CustomCloseButton
              onClick={() =>
                this.props.onChangePerformanceHOC(false, "showDetailsModal")
              }
              containerClass={`at-dropbear-panel__header-close`}
            />
            <h1 className="at-dropbear-panel__title" style={{ color: "#000" }}>
              {renderTitle()}
            </h1>
          </div>
        </div>
      </ReactModal>
    );
  }

  renderAgentSearchTips = () => {
    return (
      <>
        <span className="fw-500">
          How to view sales summary and contact team members?
        </span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          You can click on their name to view their sales summary for the
          current year. <br />
          And if you want to get in touch, click on the WhatsApp icon to give
          them a buzz.
        </p>
      </>
    );
  };

  renderCardContent() {
    const { performanceData, toggleView } = this.props;

    return (
      <>
        <CustomTab
          sections={tabOptions}
          selectedSection={this.props.toggleView}
          onSelectSection={(val) =>
            this.props.onChangePerformanceHOC(val, "toggleView")
          }
        />
        {toggleView === "3 Months" && (
          <div className="at-card-container">
            <CustomTable
              className={"mb-100"}
              headerData={tableData}
              pagination={true}
              rowData={performanceData[3] ? performanceData[3] : []}
              originalRowData={performanceData[3] ? performanceData[3] : []}
              detailsClick={(val) => this.detailsClick(val)}
              searchWithField={"agentName"}
              searchPlaceholder={"Agent Name"}
              rowKey={"agentID"}
              mobileColumn={"mobileNumber"}
              booleanColumn={"hasRenTag"}
            />
          </div>
        )}

        {toggleView === "6 Months" && (
          <div className="at-card-container">
            <CustomTable
              className={"mb-100"}
              headerData={tableData}
              pagination={true}
              rowData={performanceData[6] ? performanceData[6] : []}
              originalRowData={performanceData[6] ? performanceData[6] : []}
              detailsClick={(val) => this.detailsClick(val)}
              searchWithField={"agentName"}
              searchPlaceholder={"Agent Name"}
              rowKey={"agentID"}
              mobileColumn={"mobileNumber"}
              booleanColumn={"hasRenTag"}
            />
          </div>
        )}

        <Dialog
          open={this.props.showDetailModal}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <DialogContent>{this.renderDetailsModal()}</DialogContent>
        </Dialog>
      </>
    );
  }

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={this.props.title}
          atlasIcon={"atlas-trend-down"}
          description={`View a summary of your team members who have not had any sales over the past 3 to 6 month.`}
          showPABadge={role === "Personal Assistant"}
          infoBoxContent={this.renderAgentSearchTips}
          infoBoxInactiveContent={
            "How to view sales summary and contact team members?"
          }
          backButton={{
            onShow: ["Super Admin", "Admin"].indexOf(role) > -1,
            onClick: () => this.props.clickBack(),
          }}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadPerformance && <LoadingModal />}
      </>
    );
  };
}

export default compose(VersionCheckHOC, PerformanceHOC)(TeamPerformance);
