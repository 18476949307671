import Moment from "moment";
import DatePicker from "react-datepicker";
import { BiCalendarAlt } from "react-icons/bi";

const DatepickerInput = ({ ...props }) => (
  <div className={"at-form-input register-datepicker-form-control"}>
    <input type={"text"} {...props} readOnly />
    <BiCalendarAlt />
  </div>
);

const CustomDatepicker = ({ value, onChange }) => {
  const dateFormat = "DD MMM YYYY";

  return (
    <DatePicker
      popperProps={{ positionFixed: true }}
      showYearDropdown={true}
      className="react-datepicker-popper"
      popperClassName="react-datepicker-wrapper"
      dateFormat={dateFormat}
      style={{ zIndex: 2 }}
      maxDate={new Date(Moment().subtract(18, "years"))}
      customInput={<DatepickerInput />}
      value={value}
      onChange={(val) => onChange(Moment(val).format(dateFormat))}
    />
  );
};

export default CustomDatepicker;
