import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { Bar } from "react-chartjs-2";
import { BiTable } from "react-icons/bi";
import { FaRegChartBar } from "react-icons/fa";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomSearch from "../components/search";

import { numberWithCommas } from "utils/thousandSeparator";

const intervalOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const columnData = [
  { header: "Date", accessor: "date", searchFlag: "date" },
  { header: "Web", accessor: "web", searchFlag: "web" },
  { header: "Android", accessor: "android", searchFlag: "android" },
  { header: "IOS", accessor: "ios", searchFlag: "ios" },
];

const SearchParams = ({ country, dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          param: `start_date=${Moment().subtract(1, "years").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Country",
    value: "country",
    param: { name: "Malaysia" },
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    typeaheadId: "country_typeahead",
    showClearButton: true,
    options: country,
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "View By",
    value: "interval",
    type: "radio",
    param: "month",
    options: intervalOptions,
  },
];
const NewAgent = ({
  data,
  newAgent,

  getNewAgent,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      country: data.dictionaryReducer.countries,
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getNewAgent(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        country: data.dictionaryReducer.countries,
        dateRangeMode,
      }),
    );

    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  return (
    <>
      <div className="mb-10">
        <CustomSearch
          searchParams={searchParams}
          dateRangeMode={dateRangeMode}
          showSearchContent={showSearchContent}
          getListAPI={getNewAgent}
          setSearchParams={setSearchParams}
          setDateRangeMode={setDateRangeMode}
          setShowSearchContent={setShowSearchContent}
        />
      </div>
      <div className="d-flex mb-10 justify-content-end">
        <button
          className={`at-table-search__additional-btn ${visual === "chart" ? "at-table-search__additional--selected" : ""}`}
          onClick={() => setVisual("chart")}
          children={<FaRegChartBar style={{ width: "20px", height: "20px" }} />}
        />
        <button
          onClick={() => setVisual("table")}
          className={`at-table-search__additional-btn ${visual === "table" ? "at-table-search__additional--selected" : ""}`}
          children={<BiTable style={{ width: "20px", height: "20px" }} />}
        />
      </div>
      {newAgent.data && newAgent.data.length > 0 && visual === "table" && (
        <CustomTable
          pagination={true}
          hideSearch={true}
          rowData={newAgent.data}
          columns={columnData}
        />
      )}
      {newAgent.data && newAgent.data.length > 0 && visual === "chart" && (
        <div style={{ height: `${window.innerHeight * 0.55}px`}}>
          <Bar
            data={newAgent.chart}
            options={{
              plugins: {
                datalabels: window.innerWidth < 820 ? false : {
                  anchor: "end",
                  align: "end",
                  formatter: (value) => numberWithCommas(value),
                }
              },
              maintainAspectRatio: false,
              aspectRatio: 0.2,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: 14,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 12,
                      callback: (value) => numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />
        </div>
      )}
      {!(newAgent.data && newAgent.data.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={
            visual === "chart" ? (
              <AtlasIcon svgHref="atlas-chart-3" />
            ) : (
              <AtlasIcon svgHref="atlas-document-text" />
            )
          }
        />
      )}
    </>
  );
};

export default NewAgent;
