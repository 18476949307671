import { forwardRef, useEffect, useState } from 'react';
import ReactLottie from 'react-lottie';

import { getVideoMetadata } from '@remotion/media-utils';
import { Player } from '@remotion/player';
import { continueRender, delayRender, prefetch } from 'remotion';

import JiqiLoadingLottie from 'assets/lottie/jiqi_loading.json';

import 'stylesheets/components/loader/index.scss';

const RemotionVideoPlayer = (
  {
    id = '',
    className = '',
    component,
    url,
    width = 900,
    fps = 30,
    controls = true,
    autoPlay,
    setIsPortrait
  },
  ref
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(120);
  const [height, setHeight] = useState(837);
  const [handle] = useState(() => delayRender());

  useEffect(() => {
    const prefetchVideo = async () => {
      const { free, waitUntilDone } = await prefetch(url, {
        cache: true,
        network: true,
        priority: 'high'
      });

      setIsLoading(true);

      try {
        const {
          aspectRatio,
          durationInSeconds,
          width: videoWidth,
          height: videoHeight
        } = await getVideoMetadata(url);

        setIsPortrait(videoHeight > videoWidth);
        setDuration(Math.round(durationInSeconds * 30));
        setHeight(Math.ceil(width / aspectRatio));
        continueRender(handle);
      } catch (err) {
        console.error(`Error fetching video template metadata: ${err}`);
      }

      try {
        await waitUntilDone();
      } catch (err) {
        console.error({ err });
      } finally {
        setIsLoading(false);
      }

      return () => {
        free();
      };
    };

    if (url) {
      prefetchVideo();
    }
  }, [url]);

  if (isLoading) {
    return (
      <div className='at-loader my-4' style={{ top: 'auto' }}>
        <ReactLottie
          options={{
            loop: true,
            autoplay: true,
            animationData: JiqiLoadingLottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
        />
      </div>
    );
  }

  return (
    <Player
      id={id}
      ref={ref}
      className={className}
      component={component}
      durationInFrames={duration}
      compositionWidth={width}
      compositionHeight={height}
      fps={fps}
      autoPlay={autoPlay}
      controls={controls}
    />
  );
};

export default forwardRef(RemotionVideoPlayer);
