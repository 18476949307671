import React, { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { IoMdHelpCircle } from "react-icons/io";

import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CountryCodeContent from "components/country_code";

const FieldData = [
  { label: "full name", value: "full_name", input_type: "text" },
  { label: "email", value: "email", input_type: "text" },
  {
    label: "mobile contact",
    value: "mobile_contact_number",
    input_type: "number",
  },
  { label: "current address", value: "current_address", input_type: "text" },
];

const CreateForm = ({
  showLoading,
  permissionsChecker,
  selectedClients,

  onClickClose,
  onClickSubmit,
}) => {
  const [createFormData, onChangeFormData] = useState({
    full_name: "",
    email: "",
    mobile_contact_number: "",
    current_address: "",
  });
  const { can_update } = permissionsChecker;

  const [showCountryCodeModal, onToggleCountryCode] = useState(false);

  useEffect(() => {
    onChangeFormData(selectedClients);
  }, []);

  let diabledSubmit = useMemo(() => {
    return _.keys(createFormData).some(
      (key) =>
        key !== "current_address" &&
        (!createFormData[key] || createFormData[key].length < 1),
    );
  }, [createFormData]);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  return (
    <>
      <ModalDialog
        title={"Edit Client"}
        onClose={onClickClose}
        children={
          <>
            <div className={"grid-control"}>
              {FieldData.map((item) => (
                <section key={item.value} className="grid-full-col">
                  <div className="d-flex">
                    <h2
                      className="at-form-input__title text-capitalize"
                      required
                    >
                      {item.label}
                    </h2>
                    {item.value === "mobile_contact_number" && (
                      <Tooltip
                        title={
                          "You may refer the country code provided by clicking this button."
                        }
                      >
                        <IoMdHelpCircle
                          style={{
                            cursor: "pointer",
                            marginLeft: 8,
                            width: 20,
                            height: 20,
                          }}
                          onClick={() => onToggleCountryCode(true)}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <CustomFormInput
                    type={item.input_type}
                    numericOnly={true}
                    disabled={!can_update}
                    value={createFormData[item.value]}
                    required={true}
                    onChangeValue={(val) => onChangeField(val, item.value)}
                    placeholder={`Please enter client's ${item.label}`}
                  />
                </section>
              ))}
            </div>
          </>
        }
        footer={
          <div className="d-flex">
            {can_update && (
              <button
                disabled={diabledSubmit}
                onClick={() => onClickSubmit(createFormData)}
                className={"btn-new btn-new--success mr-10"}
              >
                Update
              </button>
            )}
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClickClose()}
            >
              Close
            </button>
          </div>
        }
      />
      {showCountryCodeModal && (
        <CountryCodeContent onClose={() => onToggleCountryCode(false)} />
      )}
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
