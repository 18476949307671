import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import AtlasIcon from "components/Icon/atlasIcon";
import SideBarIcon from "components/Icon/sideBarIcon";
import color from "@material-ui/core/colors/amber";

import { getTranslation } from "assets/translation";

class Accordion extends Component {
  state = {
    isOpen: false,
  };

  componentDidMount = () => {
    const { item } = this.props;
    const currentNavigationURL = this.props.history.location.pathname;
    item.children &&
      item.children.map((content) => {
        if (
          content.navigateTo &&
          currentNavigationURL.endsWith(content.navigateTo)
        ) {
          return this.setState({
            isOpen: true,
          });
        } else return "";
      });

    if (item.open) {
      this.setState({ isOpen: true });
    }
  };

  componentDidUpdate = (prevProp, prevState) => {
    if (
      prevState.isOpen !== this.state.isOpen &&
      this.props.item !== prevProp.item
    ) {
      if (
        this.props.item.children &&
        this.props.item.children.some((item) =>
          window.location.href.endsWith(item.navigateTo)
        )
      ) {
        this.setState({ isOpen: true });
      }
    }

    if (prevProp.searchKeyword !== this.props.searchKeyword) {
      this.setState({ isOpen: true });
    }
  };

  onToggleMenuList = () =>
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));

  onClickMenuItem = (param, parent) => {
    this.props.onSeletedMenu("active_link_menu", "");
    this.props.onSeletedMenu("activeParentMenu", parent);
    if (param.name === "IQI Academy") {
      this.props.getSSOToken("https://academy.iqiglobal.com");
    } else {
      if (param.navigateTo) {
        this.props.history.push(`${this.props.idPath}${param.navigateTo}`);
        this.props.onCloseMenu();
      } else {
        window.open(param.href, "_blank");
      }
    }
  };

  activeChildMenu = (param) => {
    if (param && typeof param === "string") {
      return this.props.history.location.pathname.endsWith(param);
    } else {
      return (
        _.findIndex(param, (item) =>
          this.props.history.location.pathname.endsWith(item)
        ) > -1
      );
    }
  };

  renderMenuListItems = (item, parent) => {
    const { cartCount, data } = this.props;
    const isActiveChildMenu = this.activeChildMenu(
      item.contentURL || item.navigateTo
    );

    const highlightSelected = (param) => {
      if (param && typeof param === "string") {
        return this.props.history.location.pathname.endsWith(param)
          ? "router-link-exact-active active"
          : "";
      } else {
        return _.findIndex(param, (item) =>
          this.props.history.location.pathname.endsWith(item)
        ) > -1
          ? "router-link-exact-active active"
          : "";
      }
    };

    return (
      <li key={`${item.name?.trim() || item.name}`} className="at-sidebar__list-item">
        {item.label && (
          <span className="at-sidebar__link-label">{getTranslation(item.key, data.languageReducer.language) || item.label}</span>
        )}
        {!item.label && (
          <button
            className={`at-sidebar__child-cont ${highlightSelected(item.contentURL || item.navigateTo)}`}
            onClick={() => this.onClickMenuItem(item, parent)}
          >
            {isActiveChildMenu && (
              <AtlasIcon svgHref={item.icon} className={"at-sidebar__icon"} />
            )}
            {!isActiveChildMenu && (
              <SideBarIcon
                svgHref={item.icon}
                className={"at-sidebar__icon"}
                gradient={item.gradient} 
              />
            )}
            <span
              className={"at-sidebar__name"}
              style={{ color: isActiveChildMenu ? "#fff" : "#d1d5db" }}
            >
              {`${getTranslation(item.key, data.languageReducer.language) || item.name}  `}
              {item.name === "My Cart" && cartCount > 0 && (
                <span
                  style={{
                    fontSize: 10,
                    background: "#F18E05",
                    borderRadius: "50%",
                    padding: "1px 5px",
                  }}
                >
                  {`${cartCount}`}
                </span>
              )}
            </span>
          </button>
        )}
      </li>
    );
  };

  render = () => {
    const { item, itemToShow, searchKeyword, selectionParentMenu, data } = this.props;
    const highlightSelectedMenuItems = (param) => param ? "at-sidebar--open" : "";

    let tempChildren = typeof itemToShow === "boolean" ? item.children : itemToShow;

    const { role } = data?.currentSignInProfileReducer;

    if (tempChildren.length > 0) {
      tempChildren = tempChildren.filter(
        (child) =>
          !(role === "Associated Agent" && child.name === "Analytics Centre")
      );
    }

    if (role === "Associated Agent" && tempChildren.length === 0) {
      return <></>;
    }

    return (
      <div
        key={item.name?.trim() || item.name}
        className={`at-sidebar__dropdown ${highlightSelectedMenuItems(this.props.expand)}`}
      >
        <div
          onClick={() => {
            let currentSelectionParentMenu = _.cloneDeep(selectionParentMenu);
            if (searchKeyword.length > 0) {
              if (this.props.expand) {
                currentSelectionParentMenu = currentSelectionParentMenu.filter(
                  (menu) => menu !== item.name
                );
              } else {
                currentSelectionParentMenu.push(item.name);
              }
              this.props.onSeletedMenu(
                "selectionParentMenu",
                currentSelectionParentMenu
              );
            } else {
              this.props.onSeletedMenu(
                "selectionParentMenu",
                this.props.expand ? [] : [item.name]
              );
            }
          }}
        >
          <label
            className={`at-sidebar__parent-cont ${this.props.activeParentMenu === item.name ? "active" : ""}`}
          >
            <SideBarIcon
              svgHref={item.icon}
              className={"at-sidebar__icon"}
              gradient={item.gradient}
            />
            <span className={"at-sidebar__name"}>{getTranslation(item.key, data.languageReducer.language) || item.name}</span>
            <div className="at-sidebar__chevron-cont">
              <i className="fa fa-chevron-down at-sidebar__chevron"></i>
            </div>
          </label>
        </div>
        <ul className="at-new-sidebar__list">
          {tempChildren
            .filter(child => !(role === "Associated Agent" && child.name === "Analytics Centre"))
            .map((child) => this.renderMenuListItems(child, item.name))}
        </ul>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(Accordion);
