import React from "react";

import MySales from "containers/Dashboard/Personal/MySales";
import Statistics from "containers/Dashboard/Statistic";
import HierarchySales from "containers/Dashboard/Team/MyTeam/HierarchySales";
import PaymentVoucher from "containers/Dashboard/Personal/PaymentVouchers";
import TeamSales from "containers/Dashboard/Team/TeamSales";
import ProjectSales from "containers/Dashboard/Team/ProjectSales";
import TeamPerformance from "containers/Dashboard/Team/TeamPerformance";

const DetailsSection = ({
  selectedUser,
  showDetailsSection,
  clickBack,
  otherClickBack,
  viewPersonalSales,
  selectedOtherAgent,
}) => {
  const { role, display_name, encoded_identity, team_name } = selectedUser;

  return (
    <>
      {showDetailsSection === "Stats" && (
        <Statistics
          selectedUserRole={role}
          display_name={display_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
      {showDetailsSection === "MySales" && (
        <MySales
          display_name={display_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
      {showDetailsSection === "OtherAgentSales" && (
        <MySales
          display_name={selectedOtherAgent.display_name}
          encoded_identity={selectedOtherAgent.encoded_identity}
          clickBack={otherClickBack}
        />
      )}
      {showDetailsSection === "HierarchySales" && (
        <HierarchySales
          display_name={display_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
      {showDetailsSection === "PV" && (
        <PaymentVoucher
          display_name={display_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
      {showDetailsSection === "TeamSales" && (
        <TeamSales
          team_name={team_name}
          encoded_identity={encoded_identity}
          onClose={clickBack}
          viewPersonalSales={viewPersonalSales}
        />
      )}
      {showDetailsSection === "ProjectSales" && (
        <ProjectSales
          team_name={team_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
      {showDetailsSection === "TeamPerformance" && (
        <TeamPerformance
          team_name={team_name}
          encoded_identity={encoded_identity}
          clickBack={clickBack}
        />
      )}
    </>
  );
};

export default DetailsSection;
