import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { FaRegChartBar } from "react-icons/fa";
import { BiTable } from "react-icons/bi";
import { HorizontalBar } from "react-chartjs-2";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomSearch from "../components/search";

import { numberWithCommas } from "utils/thousandSeparator";

const columnData = [
  { header: "Publish At", accessor: "publish_at", searchFlag: "publish_at" },
  { header: "Title", accessor: "title", searchFlag: "title" },
  { header: "View Count", accessor: "views_count", searchFlag: "views_count" },
];

const SearchParams = ({ dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          col: 6,
          initialValue: "Last 30 Days",
          param: `start_date=${Moment().subtract(30, "days").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Sort By",
    value: "sort_by",
    type: "radio",
    col: dateRangeMode ? 4 : 6,
    param: "views_count",
    options: [
      { label: "Sort by View Count", value: "views_count" },
      { label: "Sort by Published At", value: "publish_at" },
    ],
  },
];

const NewsfeedStats = ({
  newsfeedStats,

  getNewsfeedStats,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp + `q[${item.value}]=${item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getNewsfeedStats(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        dateRangeMode,
      }),
    );

    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  return (
    <>
      <div className="mb-10">
        <CustomSearch
          searchParams={searchParams}
          dateRangeMode={dateRangeMode}
          showSearchContent={showSearchContent}
          getListAPI={getNewsfeedStats}
          setSearchParams={setSearchParams}
          setDateRangeMode={setDateRangeMode}
          setShowSearchContent={setShowSearchContent}
        />
      </div>
      <div className="d-flex mb-10 justify-content-end">
        <button
          className={`at-table-search__additional-btn ${visual === "chart" ? "at-table-search__additional--selected" : ""}`}
          onClick={() => setVisual("chart")}
          children={<FaRegChartBar style={{ width: "20px", height: "20px" }} />}
        />
        <button
          onClick={() => setVisual("table")}
          className={`at-table-search__additional-btn ${visual === "table" ? "at-table-search__additional--selected" : ""}`}
          children={<BiTable style={{ width: "20px", height: "20px" }} />}
        />
      </div>
      {newsfeedStats.data &&
        newsfeedStats.data.length > 0 &&
        visual === "table" && (
          <CustomTable
            pagination={true}
            hideSearch={true}
            rowData={newsfeedStats.data}
            columns={columnData}
          />
        )}
      {newsfeedStats.data &&
        newsfeedStats.data.length > 0 &&
        visual === "chart" && (
          <div style={{ height: `${window.innerHeight * 0.55}px`}}>
            <HorizontalBar
              data={newsfeedStats.chart}
              options={{
                tooltips: {
                  callbacks: {
                    title: (tooltipItem, data) => {
                      return data.labels[tooltipItem[0].index] || "";
                    },
                  },
                },
                plugins: {
                  datalabels: {
                    anchor: "end",
                    align: "end",
                    formatter: (value) => numberWithCommas(value),
                  },
                },
                maintainAspectRatio: false,
                aspectRatio: 0.2,
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 12,
                        callback: (value) => numberWithCommas(value),
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontSize: 12,
                        callback: (label) => {
                          if (window.innerWidth > 1024 && label.length > 30) {
                            return `${label.substring(0, 30)}...`;
                          } else if (
                            window.innerWidth <= 1024 &&
                            label.length > 10
                          ) {
                            return `${label.substring(0, 10)}...`;
                          } else {
                            return label;
                          }
                        },
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        )}
      {!(newsfeedStats.data && newsfeedStats.data.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={
            visual === "chart" ? (
              <AtlasIcon svgHref="atlas-chart-3" />
            ) : (
              <AtlasIcon svgHref="atlas-document-text" />
            )
          }
        />
      )}
    </>
  );
};

export default NewsfeedStats;
