import React, { Component } from "react";
import _ from "lodash";
import { Delete, Get, Post, Put } from "utils/axios";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { updateCartItemQuantity } from "actions/myCart";
import { getMyCart } from "actions/myCart";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      paymentDetails: {
        amount: 0,
        cust_ip: "",
        merchant_name: "",
        order_number: "",
        payment_desc: "",
        payment_id: "",
        service_id: "",
        order_id: "",
      },
      checkoutDetails: {
        message: "",
        order_id: "",
        order_number: "",
        total_amount: "",
      },
      showCheckoutModal: false,
      showDeleteConfirmation: false,
      showProfileUpdate: false,
      showCheckoutConfirmation: false,
      showCancelConfirmation: false,

      cartItems: [],
      checkedArray: [],
      from: "",
      deliveryBranchId: null,
    };

    componentDidUpdate = (pp) => {
      let { ajaxCallProgress } = this.props.data.ajaxStatusReducer;
      if (ajaxCallProgress !== pp.data.ajaxStatusReducer.ajaxCallProgress) {
        this.load(!ajaxCallProgress === 0);
      }
    };

    load = (param) => this.setState({ loading: param });

    onChangeMyCartHOC = (val, context) => this.setState({ [context]: val });

    getMyCartItems = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.setState({ from: "GET_CART" });
      this.props.storeLastView(temp);
      this.props.getMyCart(page, search);
    };

    checkCheckoutOrders = () =>
      Post(
        `/market_orders`,
        this.state.deliveryBranchId
          ? { delivery_branch_id: this.state.deliveryBranchId }
          : {},
        this.checkCheckoutOrdersSuccess,
        this.checkCheckoutOrdersError,
        this.load,
      );
    checkCheckoutOrdersSuccess = (payload) => {
      this.setState({
        checkoutDetails: payload,
        showCheckoutConfirmation: false,
        showCheckoutModal: true,
      });
    };
    checkCheckoutOrdersError = (error) => {
      requestError(error);
      this.props.history.goBack();
    };

    getCheckoutPayments = (data) => {
      let tmpRedoPaymentDetail = this.props.history.location.state
        ? _.cloneDeep(this.props.history.location.state.paymentDetails)
        : {};

      const tmpData = !window.location.href.includes("/redo-payment")
        ? {
            ...data,
            order_id: data.product_id,
          }
        : {
            ...tmpRedoPaymentDetail,
            order_id: tmpRedoPaymentDetail.product_id,
          };

      return Post(
        `/payments`,
        tmpData,
        (payload) => this.getCheckoutPaymentsSuccess(payload, tmpData),
        this.getCheckoutPaymentsError,
        this.load,
      );
    };
    getCheckoutPaymentsSuccess = (payload, data) =>
      this.setState({
        paymentDetails: {
          ...payload,
          amount: data.amount,
          order_id: data.order_id,
        },
      });
    getCheckoutPaymentsError = (error) => {
      this.setState({ showCheckoutModal: false });
      requestError(error);
      this.props.history.goBack();
    };

    cancelPayment = () =>
      Delete(
        `/payments/${this.state.paymentDetails.payment_id}`,
        this.cancelPaymentSuccess,
        this.cancelPaymentError,
        this.load,
      );
    cancelPaymentSuccess = () => {
      const tmpPath =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.adminSignInReducer.prefix_path
          : "";

      this.setState({ showCheckoutModal: false });
      this.props.history.push({
        pathname: `${tmpPath}/marketing-centre/order-history`,
        state: { id: this.state.paymentDetails.order_id },
      });
    };
    cancelPaymentError = (error) => requestError(error);

    updateDeliveryBranch = ({ order_id }) => {
      !this.state.showDeliveryBranchField
        ? this.updateDeliveryBranchSuccess()
        : Put(
            `/market_orders/${order_id}`,
            { delivery_branch_id: this.state.deliveryBranchId },
            this.updateDeliveryBranchSuccess,
            this.updateDeliveryBranchError,
            this.load,
          );
    };
    updateDeliveryBranchSuccess = (payload) =>
      this.setState({ showCheckoutModal: true });
    updateDeliveryBranchError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            orderNumber={this.state.checkoutDetails.order_number}
            checkoutDetails={this.state.checkoutDetails}
            cartItems={this.state.cartItems}
            onLoadMyCart={this.state.loading}
            checkedArray={this.state.checkedArray}
            paymentDetails={this.state.paymentDetails}
            deliveryBranchId={this.state.deliveryBranchId}
            showCheckoutModal={this.state.showCheckoutModal}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showProfileUpdate={this.state.showProfileUpdate}
            showCheckoutConfirmation={this.state.showCheckoutConfirmation}
            showCancelConfirmation={this.state.showCancelConfirmation}
            cancelPayment={this.cancelPayment}
            getMyCartItems={this.getMyCartItems}
            checkCheckoutOrders={this.checkCheckoutOrders}
            getCheckoutPayments={this.getCheckoutPayments}
            onChangeMyCartHOC={this.onChangeMyCartHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    updateCartItemQuantity,
    storeLastView,
    getMyCart,
  })(WithHOC);
};

export default HOC;
