import React from "react";

import DeleteModal from "../../Delete";
import DeleteHOC from "../../actions/delete";
import permissionsChecker from "utils/permissionsChecker";

const Settings = (props) => {
  const {
    data,

    onChangeSubsalesHOC,
    onChangeDeleteHOC,
    subsalesEntryCreationProps,
  } = props;

  const { can_destroy } = permissionsChecker("Listing Centre", data);

  return (
    <>
      <section className="at-form__content">
        <strong className="mb-2 fs-4">Settings</strong>
        <hr style={{ marginBottom: 20 }} />
        {can_destroy && (
          <div
            className="row"
            style={{
              border: "2px solid red",
              borderRadius: 5,
              padding: 5,
            }}
          >
            <div className="col-md-10">
              <p className="at-form-input__title">Delete this listing</p>
              <p>
                Once you delete a listing, there is no going back, Please be
                certain.
              </p>
            </div>
            <div className="col-md-2" style={{ textAlign: "right" }}>
              <button
                className="btn-new btn-new--danger mb-10 mt-10"
                onClick={() => {
                  onChangeSubsalesHOC(true, "showDeleteModal");
                  onChangeDeleteHOC(
                    subsalesEntryCreationProps.id,
                    "selectedDeleteSubsaleId",
                  );
                  onChangeDeleteHOC(
                    subsalesEntryCreationProps.code,
                    "selectedDeleteSubsaleCode",
                  );
                }}
              >
                Delete
              </button>
            </div>
          </div>
        )}
        {!can_destroy && (
          <p style={{ color: "red" }}>
            You do not have the access right for this setting
          </p>
        )}
      </section>
      <DeleteModal {...props} />
    </>
  );
};

export default DeleteHOC(Settings);
