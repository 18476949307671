import React, { Component } from "react";
import { compose } from "redux";
import Moment from "moment";
import { Dialog, DialogActions, IconButton, Tooltip } from "@material-ui/core";
import { FaSignOutAlt } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import DrawingBoard from "components/DrawingBoard";
import LoadingModal from "components/LoadingModal";

import WithLogout from "actions/logout";
import AppointmentLetterHOC from "./action";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";
import { getTranslation } from "./assets";
import {
  renderGeneralContent,
  renderCanadaContent,
  renderBilingualAgreement
} from "./agreements";

class AppointmentLetterModal extends Component {

  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    
    if (tmpProfileReducer.country_id === 14) {
      this.props.checkDigitalSign();
    }
  };

  renderAgreementContent = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    switch (tmpProfileReducer.country_id) {
      case 3:
        return renderCanadaContent();
      case 14:
        if (this.props.showJapanAgreement) {
          return this.renderJapanAgreement();
        } else {
          return renderBilingualAgreement({currentDate: this.props.currentDate});
        }
      default:
        return renderGeneralContent({currentDate: this.props.currentDate});
    }
  }

  renderJapanAgreement = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const language = tmpProfileReducer?.country_id === 14 ? "jp" : "en";
    const iframeRef = React.createRef();

    return (
      <>
        <iframe
          className={"w-100"}
          style={{ border: 'none', height: '100vh' }}
          ref={iframeRef}
          srcDoc={this.props.japanNDAAgreement}
          onLoad={() => {}}
        />
        <DialogActions
          className={"g-3 my-4"}
          style={{ display: "flex", justifyContent: "start" }}>
          <div className={"d-flex flex-column align-items-start g-3"}>
            <p className="fw-500">署する</p>
            <CustomCheckbox
              content={getTranslation("agent.appointment.checkbox_text", language)}
              checked={this.props.is_signed_japan_appointment_letter}
              onChangeCheckboxValue={() => this.props.onChangeHOC(!this.props.is_signed_japan_appointment_letter, "is_signed_japan_appointment_letter")}
            />
            {!this.props.signature && (
              <AtlasButton
                className={"btn-new btn-new--primary"}
                onClick={() => this.props.onChangeHOC(true, "showDrawingBoard")}
                disabled={!this.props.is_signed_japan_appointment_letter}
              >
                <AtlasIcon svgHref={"atlas-pen-add"} style={{ width: 18, height: 18, marginRight: 8, fill: "white" }} />
                サインはこちら
              </AtlasButton>
            )}
            {this.props.signature && (
              <>
                <div className="position-relative">
                  <img src={this.props.signature} alt={"signature"}
                  style={{ width: 300, borderRadius: 8, border: "1px solid #D1D5DB" }} />
                  <AtlasButton
                    className={`at-table-icon-button`}
                    onClick={() => this.props.onChangeHOC(null, "signature")}
                    style={{ position: "absolute", top: 8, right: 8 }}>
                    <BsTrash3Fill style={{ width: 18, height: 18, color: "#F04438" }} />
                  </AtlasButton>
                </div>
                <AtlasButton
                  className={"btn-new btn-new--primary"}
                  onClick={() => {
                    const headerElement = document.getElementsByClassName("welcome-header")?.[0]
                    headerElement.scrollIntoView({ behavior: "smooth" })
                    this.props.createDigitalSign(this.props.signature)
                  }}
                  disabled={!this.props.is_signed_japan_appointment_letter}
                >
                  {getTranslation("submit", language)}
                </AtlasButton>
              </>
            )}
          </div>
        </DialogActions>
      </>
    )
  }

  renderAgentAppointmentBoardContent = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const language = country_id === 14 ? "jp" : "en";

    return (
      <>
        <div className={"welcome-header text-center mb-4"}>
          <div>
            <img src={JuwaiIQILogo} alt={"JuwaiLogo"} style={{ height: 200 }} />
            <span
              style={{
                fontSize: "26px",
                fontWeight: "600",
                display: "block",
              }}
            >
              {getTranslation("agent.appointment.registration_approved", language)}
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "50%",
              marginLeft: "150px",
            }}
          >
            <Tooltip title="Log out" placement="top">
              <IconButton onClick={this.props.sendLogoutRequest}>
                <FaSignOutAlt style={{ width: 24 }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div class="welcome-header" style={{ marginTop: 44, marginBottom: 44 }}>
          <hr style={{ width: "100%" }} />
          <div className="welcome-stepper-icon-container">
            <div className="welcome-stepper-board">
              <AtlasIcon
                svgHref={"person-check-fill"}
                className={"welcome-stepper-atlas-icon"}
              />
            </div>
          </div>
        </div>

        <div className={"container pt-4"} style={{ maxWidth: 1000 }}>
          {this.renderAgreementContent()}
          {(country_id !== 14 || !this.props.showJapanAgreement) && (
            <>
              <CustomCheckbox
                content={country_id === 14
                    ? (
                      <div className="d-flex flex-column">
                        <p>
                          私は上記の利用規約を理解し、同意しました。
                          <strong>
                            {` ${Moment(this.props.currentDate, "DD-MM-YYYY").format("DD MMM YYYY")}`}
                          </strong>。 
                        </p>
                        <p>
                          I hereby acknowledge and accept that I have read and understood the above 
                          terms and conditions on 
                          <strong>
                            {` ${Moment(this.props.currentDate, "DD-MM-YYYY").format("DD MMM YYYY")}`}
                          </strong>.
                        </p>
                      </div>
                    )
                    : "I've acknowledge that I have read and agree to the above Terms and Conditions"}
                labelClassname={"ml-0 mt-4"}
                checked={this.props.is_signed_appointment_letter}
                onChangeCheckboxValue={() =>
                  this.props.onChangeHOC(
                    !this.props.is_signed_appointment_letter,
                    "is_signed_appointment_letter"
                  )
                }
              />
              <DialogActions
                className={"g-3 my-4"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={"d-flex align-items-center g-3"}>
                  <AtlasButton
                    className={"btn-new btn-new--primary"}
                    onClick={() => this.props.onSubmitAppointmentLetter()}
                    disabled={!this.props.is_signed_appointment_letter}
                  >
                    {getTranslation("submit", language)}
                  </AtlasButton>
                </div>
              </DialogActions>
            </>
          )}
        </div>
      </>
    );
  };

  render = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const language = country_id === 14 ? "jp" : "en";

    return (
      <Dialog fullScreen={true} open={true}>
        {this.renderAgentAppointmentBoardContent()}
        <DrawingBoard
          paperClass={"at-digital-sign_modal"}
          canvasClass={"at-profile__digital-canvas"}
          open={this.props.showDrawingBoard}
          title={getTranslation("agent.appointment.drawing_board_title", language)}
          negativeButtonLabel={getTranslation("clear", language)}
          positiveButtonLabel={getTranslation("agent.appointment.drawing_board_adopt", language)}
          onClickClose={() => this.props.onChangeHOC(false, "showDrawingBoard")}
          onClickSubmit={(val) => {
            this.props.onChangeHOC(false, "showDrawingBoard")
            this.props.onChangeHOC(val, "signature");
          }}
        />
        {(this.props.onLoadAppointmentLetter && this.props.onLoadAgreement) && <LoadingModal />}
      </Dialog>
    );
  };
}

export default compose(
  AppointmentLetterHOC,
  WithLogout
)(AppointmentLetterModal);
