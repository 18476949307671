import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import EmailValidator from "email-validator";
import { compose } from "redux";

import ModalDialog from "components/Modal/ModalDialog";
import AtlasImageEditor from "components/ImageEditor/index";
import ProfileContent from "./Profile";
import NameCardContent from "./NameCard";

import VersionCheckHOC from "actions/versionCheck";
import AgentSearchHOC from "actions/agentSearch";

import "stylesheets/containers/profile-update/index.scss";

class Edit extends Component {
  state = {
    showImageEditor: false,
    loading: false,
    userInfo: {},
    id: "",
    display_name: "",
    full_name: "",
    email: "",
    team_id: "",
    race_id: "",
    mobile_contact_number: "",
    job_title_display_name: "",
    country_id: "",
    role_id: "",
    slug: "",
    status_id: "",
    user_branches: [],
    description: "",
    avatar_url: "",
    identification_number: "",
    current_address: "",
    youtube_playlist_url: "",
    wechat_id: "",
    facebook_url: "",
    telegram_url: "",
    instagram_url: "",
    board_registration_type_id: "",
    board_registration_number: "",
    license_number: "",
    date_of_birth: null,
    agent_registration_attributes: {
      id: "",
      emergency_person_name: "",
      emergency_contact_number: "",
    },
    name_card_attributes: {
      id: "",
      name_card_display_name: "",
      additional_display_name: "",
      include_profile_photo: false,
      photo: "",
      agent_tag_number: null,
      qualification: null,
      real_estate_type_id: null,
    },

    displayNameError: "",
    fullNameError: "",
    emailError: "",

    referrer: null,

    selectedEdit: null,

    tempFile: null,
    // store original file, one before editing.
  };

  componentDidMount = () => {
    if (this.state.userInfo !== this.props.selectedUser) {
      let {
        id,
        slug,
        display_name,
        referrer,
        full_name,
        email,
        team_id,
        race_id,
        mobile_contact_number,
        job_title_display_name,
        country_id,
        role_id,
        status_id,
        description,
        avatar_url,
        branch_id,
        user_branches,
        agent_registration_id,
        agent_emergency_contact_number,
        agent_emergency_person_name,
        name_card,
        agent_status_id,
        payment_status_id,
        identification_number,
        current_address,
        youtube_playlist_url,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        date_of_birth,
        license_number,
        board_registration_type_id,
        board_registration_number,
      } = this.props.selectedUser;

      this.setState({
        displayNameError: display_name
          ? ""
          : "Please fill in the required field",
        emailError: email ? "" : "Please fill in the required field",
        fullNameError: full_name ? "" : "Please fill in the required field",
        slug,
        youtube_playlist_url,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        identification_number,
        userInfo: this.props.selectedUser,
        id,
        display_name: display_name || "",
        full_name: full_name || "",
        email: email || "",
        team_id,
        race_id: race_id || "",
        mobile_contact_number: mobile_contact_number || "",
        job_title_display_name: job_title_display_name || "",
        country_id: country_id || "",
        role_id,
        status_id,
        branch_id,
        user_branches,
        date_of_birth,
        description: description || "",
        avatar_url,
        current_address,
        license_number,
        board_registration_type_id: board_registration_type_id || "",
        board_registration_number: board_registration_number || "",
        referrer:
          referrer && referrer.id
            ? {
                ...referrer,
                team_name: referrer.team,
                mobile_contact_number: referrer.mobile_number,
              }
            : null,
        agent_registration_attributes: {
          id: agent_registration_id,
          emergency_person_name: agent_emergency_person_name || "",
          emergency_contact_number: agent_emergency_contact_number || "",
          status_id: agent_status_id,
          payment_status_id: payment_status_id,
        },
        name_card_attributes: {
          ...name_card,
          name_card_display_name:
            (name_card && name_card.name_card_display_name) || "",
          additional_display_name:
            (name_card && name_card.additional_display_name) || "",
          agent_tag_number: (name_card && name_card.agent_tag_number) || "",
          qualification: (name_card && name_card.qualification) || "",
          mobile_number: (name_card && name_card.mobile_number) || "",
        },
      });

      this.props.getBranchByCountry(country_id);
    }
    this.onChangeTypeaheadSearch = _.debounce((val) => {
      this.setState({ referrer: null });
      val.length > 0
        ? this.props.getAgentsBySearch(val)
        : this.props.onChangeAgentHOC([], "agentList");
    }, 500);
  };

  componentDidUpdate = (_, ps) => {
    if (
      ps.selectedEdit !== this.state.selectedEdit &&
      this.state.selectedEdit
    ) {
      this.setState({
        showImageEditor: true,
        loading: false,
      });
    }
    if (
      ps.showImageEditor !== this.state.showImageEditor &&
      !this.state.showImageEditor
    ) {
      this.setState({ selectedEdit: null });
    }
  };

  onChangeState = (val, context, errorContext, childContext) => {
    let tempVal = val;

    if (childContext) {
      tempVal = _.cloneDeep(this.state[context]);
      tempVal[childContext] = val;
    }
    this.setState({
      [context]: tempVal,
      ...(errorContext && {
        [errorContext]: val ? "" : "Please fill in the required field",
      }),
    });
  };

  onChangeSubBranch = (selectedBranch) =>
    this.setState({ user_branches: selectedBranch });

  onRemoveSubBranch = (branch) => {
    let temp = _.cloneDeep(this.state.user_branches);
    let branchIndex = _.findIndex(temp, (item) => item === branch);
    temp.splice(branchIndex, 1);
    this.setState({ user_branches: temp });
  };

  onHandleAppendImage = (file) => {
    this.props.showTab === "Profile"
      ? this.onChangeState(file, "avatar_url")
      : this.onChangeState(file, "name_card_attributes", null, "photo");
  };

  onChangeEmail = (email) =>
    this.setState({
      email,
      emailError: !EmailValidator.validate(email) ? "Invalid email." : "",
    });

  submitImagesCheck = (dataToSubmit) => {
    const { photo } = dataToSubmit.name_card_attributes;
    if (this.state.avatar_url && this.state.avatar_url.indexOf("https") < 0) {
      dataToSubmit = {
        ...dataToSubmit,
        avatar: this.state.avatar_url,
      };
    }
    if (
      this.state.role_id !== 5 ||
      (this.state.role_id === 5 && photo && photo.indexOf("base64") < 0)
    ) {
      delete dataToSubmit.name_card_attributes.photo;
    }
    this.props.updateUser(this.state.id, dataToSubmit);
  };

  onClickUpdateProfileInfo = (e) => {
    let dataToSubmit = {
      slug: this.state.slug,
      full_name: this.state.full_name,
      display_name: this.state.display_name,
      mobile_contact_number: this.state.mobile_contact_number,
      direct_leader_id: this.state.referrer ? this.state.referrer.id : null,
      description: this.state.description,
      email: this.state.email,
      team_id: this.state.team_id,
      race_id: this.state.race_id,
      role_id: this.state.role_id,
      status_id: this.state.status_id,
      branch_id: this.state.branch_id,
      user_branches: this.state.user_branches,
      current_address: this.state.current_address,
      country_id: this.state.country_id,
      identification_number: this.state.identification_number,
      agent_registration_attributes: this.state.agent_registration_attributes,
      name_card_attributes: this.state.name_card_attributes,
      board_registration_type_id: this.state.board_registration_type_id,
      board_registration_number: this.state.board_registration_number,
      youtube_playlist_url: this.state.youtube_playlist_url || "",
      ...([5, 7].indexOf(this.state.role_id) > -1 && {
        date_of_birth: this.state.date_of_birth,
        wechat_id: this.state.wechat_id,
        facebook_url: this.state.facebook_url,
        telegram_url: this.state.telegram_url,
        instagram_url: this.state.instagram_url,
      }),
    };

    if (
      this.state.displayNameError.length > 0 ||
      this.state.emailError.length > 0 ||
      this.state.fullNameError.length > 0
    ) {
      // further checking required
    } else {
      this.submitImagesCheck(dataToSubmit);
    }
  };

  renderCardContent = () => {
    return (
      <>
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          {this.props.showTab === "Profile" && (
            <ProfileContent
              {...this.state}
              profileRole={this.props.profileRole}
              branches={this.props.branches}
              onLoadAgent={this.props.onLoadAgent}
              agentList={this.props.agentList}
              selectedUser={this.props.selectedUser}
              branchesByCountry={this.props.branchesByCountry}
              dictionaryReducer={this.props.data.dictionaryReducer}
              onChangeTypeaheadSearch={this.onChangeTypeaheadSearch}
              onChangeSubBranch={this.onChangeSubBranch}
              onRemoveSubBranch={this.onRemoveSubBranch}
              onChangeEmail={this.onChangeEmail}
              onChangeState={this.onChangeState}
            />
          )}
          {this.props.showTab === "Name Card" && (
            <NameCardContent
              {...this.state}
              onChangeState={this.onChangeState}
            />
          )}
        </form>
        <div className={"at-modal_dialog-container-footer g-3"}>
          <button
            className="btn-new btn-new--success"
            onClick={(e) => this.onClickUpdateProfileInfo(e)}
          >
            Update
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.closeEditForm()}
          >
            Close
          </button>
        </div>
        {this.state.showImageEditor && (
          <ModalDialog
            title={"Edit Image"}
            onLoad={this.state.loading || this.props.onLoadUsers}
            onClose={() => this.setState({ showImageEditor: false })}
            children={
              <AtlasImageEditor
                aspectRatio={this.state.showTab === "Profile" ? 1 : 9 / 16}
                onClickSave={(file) => {
                  this.setState({ showImageEditor: false }, () => {
                    this.onHandleAppendImage(file);
                  });
                }}
                onClickProceedWithoutCropping={() => {
                  this.setState({ showImageEditor: false }, () => {
                    this.onHandleAppendImage(this.state.tempFile);
                  });
                }}
                onClickClose={() => this.setState({ showImageEditor: false })}
                imagePath={this.state.selectedEdit}
                imageFile={"temp"}
                ImageEditorTheme={{ "header.display": "none" }}
                ImageEditorMenu={["crop"]}
              />
            }
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <ModalDialog
        title={this.props.showTab === "Profile" ? "Edit Profile" : "Name Card"}
        onLoad={this.state.loading || this.props.onLoadUsers}
        fullWidth={true}
        fullHeight={true}
        onClose={() => this.props.closeEditForm()}
        children={this.renderCardContent()}
      />
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
  connect(mapStateToProps),
  AgentSearchHOC,
  VersionCheckHOC
)(Edit);
