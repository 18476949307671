import React from "react";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";

import checkValid from "utils/checkValid";
import ProfileImage1 from "assets/images/av-male.jpg";

import "../index.scss";

const RENCard = ({
  data,
  mode,
  item,
  index,
  owner,
  onDeleteREN,
  onChangeRENPercentage,
}) => {
  const {
    agent_name,
    agent_email,
    user_id,
    avatar_url,
    agent_mobile_number,
    agent_team,
    percentage,
  } = item;

  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  return (
    <div className={"at-assign-card"}>
      <div className="at-assign-card-cont">
        <div className="d-flex align-items-center">
          <img
            alt={`Cobroker`}
            className={"at-assign-avatar"}
            src={avatar_url || ProfileImage1}
            onError={(e) => {
              if (e.target) {
                e.target.src = ProfileImage1;
              }
            }}
          />
          <div>
            {tmpProfileReducer.id === item.user_id && (
              <Chip
                label={"Myself"}
                classes={{ root: "at-assign-badge" }}
                style={{ backgroundColor: "#FFEDD5", color: "#C2410C" }}
              />
            )}
            {owner === item.agent_name && (
              <Chip
                label={"Submitter"}
                classes={{ root: "at-assign-badge" }}
                style={{ backgroundColor: "#DBEAFE", color: "#1D4ED8" }}
              />
            )}
            <label>{checkValid(agent_name)}</label>
          </div>
        </div>
        <p>
          <AtlasIcon svgHref={"atlas-sms"} />
          {checkValid(agent_email)}
        </p>
        <p>
          <AtlasIcon svgHref={"atlas-call"} />
          {checkValid(agent_mobile_number)}
        </p>
        <p>
          <AtlasIcon svgHref={"atlas-profile2user"} />
          {checkValid(agent_team)}
        </p>
        <p className="at-card--profile-item-title mt-2 mb-10">Percentage(%)</p>
        <CustomFormInput
          type="number"
          value={percentage}
          onChangeValue={(val) => onChangeRENPercentage(parseFloat(val), index)}
          disabled={mode !== "edit"}
        />
        <div className="d-flex mb-10 mt-10">
          {mode === "edit" && user_id !== tmpProfileReducer.id && (
            <button
              className="btn-new btn-new--outline-secondary w-100"
              onClick={() => onDeleteREN(item)}
            >
              <AtlasIcon
                svgHref={"atlas-trash"}
                style={{ fill: "#F04438", margin: "auto" }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(RENCard);
