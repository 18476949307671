import React, { Component } from "react";

import AtlasSimpleTable from "components/NewTable/simple";
import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";

import HistoryHOC from "../actions/history";

const ColumnData = [
  {
    header: "No",
    searchFlag: "count",
    accessor: "count",
  },
  {
    header: "Downloaded Date",
    searchFlag: "downloaded_datetime",
    accessor: "downloaded_datetime",
  },
];

class DownloadContent extends Component {
  componentDidMount = () =>
    this.props.getDownloadHistory(this.props.selectedConfirmationForm.id);

  render = () => {
    const { selectedReceiptHistory, selectedConfirmationHistory, selectedTaHistory } = this.props;

    return (
      <>
        <AtlasSimpleTable
          mode={"new"}
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "download",
              onClick: (rowData) => window.open(rowData.document_link, "_blank"),
            },
          ]}
          rowData={selectedConfirmationHistory}
          renderExtraContent={() => (
            <div className={`at-table_heading`}>
              <AtlasIcon
                className="at-icon_svg-table_heading-title"
                svgHref={"atlas-receive-square"}/>
              <h5 className="fw-600">{"Confirmation Form Downloaded"}</h5>
            </div>
          )}
          emptyStateMessage="You don't have any form downloaded"
          emptyStateIcon={<AtlasIcon svgHref={"atlas-receive-square"} />}
        />
        <AtlasSimpleTable
          mode={"new"}
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "download",
              onClick: (rowData) => window.open(rowData.document_link, "_blank"),
            },
          ]}
          rowData={selectedReceiptHistory}
          renderExtraContent={() => (
            <div className={`at-table_heading`}>
              <AtlasIcon
                className="at-icon_svg-table_heading-title"
                svgHref={"atlas-receive-square"}/>
              <h5 className="fw-600">{"Temp Receipt Downloaded"}</h5>
            </div>
          )}
          emptyStateMessage="You don't have any form downloaded"
          emptyStateIcon={<AtlasIcon svgHref={"atlas-receive-square"} />}
        />
        <AtlasSimpleTable
          mode={"new"}
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "download",
              onClick: (rowData) => window.open(rowData.document_link, "_blank"),
            },
          ]}
          rowData={selectedTaHistory}
          renderExtraContent={() => (
            <div className={`at-table_heading`}>
              <AtlasIcon
                className="at-icon_svg-table_heading-title"
                svgHref={"atlas-receive-square"}/>
              <h5 className="fw-600">{"Tenancy Agreement Downloaded"}</h5>
            </div>
          )}
          emptyStateMessage="You don't have any form downloaded"
          emptyStateIcon={<AtlasIcon svgHref={"atlas-receive-square"} />}
        />
        {this.props.onLoadHistory && <LoadingModal />}
      </>
    );
  };
}

export default HistoryHOC(DownloadContent);
