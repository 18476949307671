import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      createNewProduct: {
        name: "",
        price: null,
        unit_measurement: "",
        description: "",
        country_id: null,
        type_id: null,
        status_id: null,
        has_variants: false,
        is_quantity_fixed: false,
        delivery_branch_selectable: false,
        photo: "",
        photo_file_name: "",
        is_pre_order: false,
        is_complimentary: false,
        market_product_attached_items_attributes: [],
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeCreateProductHOC = (val, context) =>
      this.setState({ [context]: val });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          createNewProduct={this.state.createNewProduct}
          onLoadCreateProduct={this.state.loading}
          onChangeCreateProductHOC={this.onChangeCreateProductHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
