import React, { useState, useMemo } from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";

const CreateForm = ({
  showLoading,

  onClickClose,
  onClickSubmit,
}) => {
  const FieldData = [
    { label: "full name", value: "full_name", input_type: "text" },
    { label: "email", value: "email", input_type: "text" },
    {
      label: "mobile contact",
      value: "mobile_contact_number",
      input_type: "number",
    },
    { label: "current address", value: "current_address", input_type: "text" },
  ];

  const [createFormData, onChangeFormData] = useState({
    full_name: "",
    email: "",
    mobile_contact_number: "",
    current_address: "",
  });

  let diabledSubmit = useMemo(() => {
    return _.keys(createFormData).some(
      (key) =>
        key !== "current_address" &&
        (!createFormData[key] || createFormData[key].length < 1),
    );
  }, [createFormData]);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  return (
    <>
      <AtlasCloseButton
        containerStyle={{ marginBottom: 10 }}
        onClick={() => onClickClose()}
      />
      <h5 className="at-card__title">{"Edit Sample"}</h5>
      <hr />
      <div className={"grid-control"}>
        {FieldData.map((item) => (
          <section key={item.value} className="grid-full-col">
            <div className="d-flex">
              <h2 className="at-form-input__title text-capitalize">
                {item.label}
              </h2>
            </div>
            <CustomFormInput
              type={item.input_type}
              numericOnly={item.input_type === "number"}
              value={createFormData[item.value]}
              required={true}
              onChangeValue={(val) => onChangeField(val, item.value)}
              placeholder={`Please enter sample's ${item.label}`}
            />
          </section>
        ))}
      </div>
      <div className="d-flex mt-20 g-3">
        <button
          disabled={diabledSubmit}
          onClick={() => onClickSubmit(createFormData)}
          className={"btn-new btn-new--success"}
        >
          Create
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClickClose()}
        >
          Close
        </button>
      </div>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
