import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import SVGIcon from "components/Icon";

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

const Icon = ({
  containerClassName,
  containerStyle,
  itemID,
  iconClassName,
  iconName,
  tooltipLabel,
  children,
}) => {
  return (
    <DarkTooltip
      enterTouchDelay={0}
      classes={{ tooltip: "tooltip-arrow top" }}
      key={itemID}
      placement={"top"}
      title={tooltipLabel}
    >
      <div
        className={containerClassName}
        style={containerStyle}
        data-tip={"tooltip"}
        data-for={itemID}
      >
        <SVGIcon
          style={{ width: 14, height: 14 }}
          className={iconClassName}
          svgHref={iconName}
        />
        {children}
      </div>
    </DarkTooltip>
  );
};

export default Icon;
