import React, { useEffect } from "react";
import Moment from "moment";
import _ from "lodash";
import { useState } from "react";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasFormGroup from "components/FormGroup/input";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";
import AgentSearchHOC from "actions/agentSearch";

const Update = ({
  agentList,
  selectedAgentAccessibility,
  onLoadSubsalesAccessibility,

  onClose,
  getAgentsBySearch,
  updateSubsalesAccessibility,
}) => {
  const [selectedAgent, onChangeAgent] = useState(null);
  const [validUntil, onChangeValidUntil] = useState(
    selectedAgentAccessibility.expiry_date,
  );

  useEffect(() => {
    getAgentsBySearch(selectedAgentAccessibility.agent_full_name);
  }, [selectedAgentAccessibility.agent_full_name]);

  useEffect(() => {
    let agent = _.find(agentList, {
      full_name: selectedAgentAccessibility.agent_full_name,
    });
    onChangeAgent(agent);
  }, [agentList]);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h4 className="at-card__title">{"Edit Agent"}</h4>
      </div>
      <div className={"at-subsale-accessibility__date-picker"}>
        <h2 className="at-form-input__title mr-10">Search for agent</h2>
        <CustomTypeahead
          disabled={true}
          typeaheadId={"internal_agent"}
          options={agentList ? agentList : []}
          selectedValue={selectedAgent ? [selectedAgent] : []}
          filterBy={["full_name", "email", "mobile_contact_number"]}
          labelKey={"full_name"}
          helpText={"e.g. Full Name, Email, Mobile Number"}
          childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
          highlighterData={[
            (option) => `Team: ${option.team_name}`,
            <br />,
            (option) =>
              `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
          ]}
        />
      </div>
      <div className={"at-subsale-accessibility__date-picker"}>
        <h2 className="at-form-input__title mt-10 mr-10">Valid Until</h2>
        <AtlasFormGroup
          type={"calendar"}
          placeholder={"Select date"}
          className={"w-100 mt-1"}
          value={validUntil}
          onSelectDate={(val) => {
            onChangeValidUntil(
              val && val !== null ? Moment(val).format("YYYY-MM-DD") : "",
            );
          }}
        />
      </div>
      <div className={"d-flex mt-20"}>
        <CustomButton
          disabled={!selectedAgent || !validUntil}
          className={"btn-new btn-new--success"}
          children={"Submit"}
          onClick={() =>
            updateSubsalesAccessibility(selectedAgentAccessibility.id, {
              user_id: selectedAgent.id,
              expiry_date: validUntil,
            })
          }
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
      {onLoadSubsalesAccessibility && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(Update);
