import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import Subsales from "containers/Dashboard/Subsales";
import CobrokeCenter from "containers/Dashboard/CobrokeCenter";

import AtlasForms from "containers/Dashboard/Forms";
import SubsalesClaims from "containers/Dashboard/SubsalesClaims";
import LeadManagement from "../LeadManagement";

import MySales from "./MySales";
import MyContact from "./Contact";
import ReaRenewal from "./ReaRenewal";
import ReportNewSales from "./ReportNewSales";
import PaymentVouchers from "./PaymentVouchers";
import PersonalAssistant from "./PersonalAssistant";

class PersonalRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={"/dashboard/personal/my-sales"}
          component={MySales}
        />
        <Route
          exact
          path={"/dashboard/personal/payment-vouchers"}
          component={PaymentVouchers}
        />

        <Route
          exact
          path={"/dashboard/personal/subsales"}
          component={Subsales}
        />
        <Route
          exact
          path={"/dashboard/personal/cobroke"}
          component={CobrokeCenter}
        />

        <Route
          path={"/dashboard/personal/subsales-claims"}
          component={SubsalesClaims}
        />

        <Route path={"/dashboard/personal/forms"} component={AtlasForms} />

        <Route
          path={"/dashboard/personal/personal-assistant"}
          component={PersonalAssistant}
        />
        <Route
          exact
          path={"/dashboard/personal/rea-renewal"}
          component={ReaRenewal}
        />

        <Route
          exact
          path={"/dashboard/personal/contacts"}
          component={MyContact}
        />
        <Route
          exact
          path={"/dashboard/personal/lead-management"}
          component={LeadManagement}
        />

        <Route
          exact
          path={"/dashboard/personal/report-new-sales"}
          component={ReportNewSales}
        />

        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/personal/my-sales"}
          component={MySales}
        />
        <Route
          exact
          path={
            "/admin-impersonate/:profileId/dashboard/personal/payment-vouchers"
          }
          component={PaymentVouchers}
        />

        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/personal/subsales"}
          component={Subsales}
        />
        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/personal/cobroke"}
          component={CobrokeCenter}
        />

        <Route
          path={
            "/admin-impersonate/:profileId/dashboard/personal/subsales-claims"
          }
          component={SubsalesClaims}
        />

        <Route
          path={"/admin-impersonate/:profileId/dashboard/personal/forms"}
          component={AtlasForms}
        />

        <Route
          path={
            "/admin-impersonate/:profileId/dashboard/personal/personal-assistant"
          }
          component={PersonalAssistant}
        />
        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/personal/rea-renewal"}
          component={ReaRenewal}
        />

        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/personal/contacts"}
          component={MyContact}
        />
        <Route
          exact
          path={
            "/admin-impersonate/:profileId/dashboard/personal/lead-management"
          }
          component={LeadManagement}
        />

        <Route
          exact
          path={
            "/admin-impersonate/:profileId/dashboard/personal/report-new-sales"
          }
          component={ReportNewSales}
        />
      </Switch>
    );
  }
}

export default withRouter(PersonalRouter);
