import CustomCheckbox from "components/Checkbox";
import _ from "lodash";

export const columnData = props => [
  CheckboxColumn(props),
  {
    header: "List",
    renderColumn: (rowData) => (
      <>
        <CustomCheckbox
          labelClassname={"at-table--checkbox-mobile"}
          checked={props.checkedArray.includes(rowData.id)}
          onChangeCheckboxValue={() => props.onHandleCheckBox(rowData.id)}
        />
        <p>{rowData.name}</p>
      </>
    ),
  },
  {
    header: "Recipients",
    columnStyle: { width: "25%" },
    accessor: "sum_users",
    customClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
  },
];

const CheckboxColumn = ({
  checkedArray = [],
  emailListsData = [],
  onHandleCheckBox,
  onHandleCheckAllBox,
}) => ({
  header: () => (
    <span className="at-table-head-checkbox_responsive">
      <CustomCheckbox
        checked={
          checkedArray.length > 0 &&
          checkedArray.length === emailListsData.length
        }
        onChangeCheckboxValue={onHandleCheckAllBox}
      />
    </span>
  ),
  renderColumn: (rowData) => (
    <CustomCheckbox
      checked={_.includes(checkedArray, rowData.id)}
      onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
    />
  ),
  columnStyle: { width: "20%" },
  columnClass: "at-table--checkbox-column",
  columnStyle: { width: "10%", gridColumn: "1/-1" },
});