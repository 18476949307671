import React from "react";

import PersonalTable from "./PersonalCampaignTable"
import ModalDialog from "components/Modal/ModalDialog";
import SalesEligibility from "./Modal/SalesEligibility";
import AtlasIcon from "components/Icon/atlasIcon";

import { personalColumnData } from "../assets";
import "../index.scss";

export default function PersonalCampaigns({
  showSaleEligibility,
  showPersonCampaignTerm,
  searchParams,
  getYearPersonalSummary,
  selectedPromotion,
  selectPromotion,
  getSelectedPromotion,
  personalSummary,
  onChangeCampaignTrackerHOC,
}) {
  return (
    <>
      <div className="at-form__content fs-2 my-3">
        The tracker provides you with an overview of campaign goals, enabling
        you to monitor your progress and performance while striving to achieve
        the set targets.
      </div>
      <PersonalTable
        columns={personalColumnData(onChangeCampaignTrackerHOC)}
        rowData={personalSummary}
        pagination={true}
        searchParams={searchParams}
        onChangeSearchParams={(val) =>
          onChangeCampaignTrackerHOC(val, "searchParams")
        }
        showResetButton={true}
        getListAPI={getYearPersonalSummary}
        actionColumnContent={[
          {
            name: "info",
            title: "check details",
            onShow: (rowData) => true,
            disable: (rowData) => false,
            onClick: (rowData) => {
              onChangeCampaignTrackerHOC(rowData, "selectPromotion");
              getSelectedPromotion(rowData.promotionId, false);
            },
          },
        ]}
        customEmptyState={{
          icon: <AtlasIcon style={{ fill: '#6B7280' }} svgHref={'icon-bold-gift'} />,
          title: 'You don\'t have any items yet'
        }}
      />
      {showSaleEligibility && (
        <SalesEligibility
          selectedPromotion={selectedPromotion}
          selectPromotion={selectPromotion}
          onClickClose={() =>
            onChangeCampaignTrackerHOC(false, "showSaleEligibility")
          }
        />
      )}
      {showPersonCampaignTerm && (
        <ModalDialog
          title={"Campaign Terms & Conditions"}
          onClose={() =>
            onChangeCampaignTrackerHOC(false, "showPersonCampaignTerm")
          }
          maxWidth="md"
          children={
            <>
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                {selectPromotion.promotionName}
              </span>
              <div
                style={{ margin: "20px 0" }}
                dangerouslySetInnerHTML={{
                  __html: selectPromotion.termsAndConditions,
                }}
              ></div>
              <div className="d-flex flex-wrap mt-10">
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary"
                  onClick={() =>
                    onChangeCampaignTrackerHOC(false, "showPersonCampaignTerm")
                  }
                >
                  Close
                </button>
              </div>
            </>
          }
        />
      )}
    </>
  );
}
