import React from "react";
import ReactHtmlParser from "react-html-parser";

import AtlasIcon from "components/Icon/atlasIcon";
import Heading from "./heading";

const dataToRender = [
  {
    title: "Description",
    source: "background_image_url",
    description: "description",
    icon: "atlas-home",
  },
  {
    title: "Overview",
    source: "overview_image_url",
    description: "overview_description",
    icon: "atlas-document-text",
  },
  {
    title: "Project Features",
    source: "project_features_image_url",
    description: "features_description",
    icon: "atlas-flag",
  },
  {
    title: "Route Map",
    source: "map_image_url",
    description: "location_description",
    icon: "atlas-map",
  },
  {
    title: "Location Map",
    source: "hd_map_image_url",
    description: "hd_map_description",
    icon: "atlas-location",
  },
  {
    title: "Landing Page",
    source: "landing_page",
    icon: "atlas-link-2-linear",
  },
];

const ProjectImages = ({ selectedProject }) => {
  const renderImageHeader = (desc, title, icon) => (
    <div className="col-lg-6 col-md-12 col-sm-12 at-image-title">
      <div className={`heading-title`}>
        <div className="at-badge-icon" style={{ backgroundColor: "#CBBFA0" }}>
          <AtlasIcon
            svgHref={icon}
            style={{ width: 16, height: 16, fill: "white" }}
          />
        </div>
        <h3 className="text-uppercase">{title}</h3>
      </div>
      <p className="text-black">
        {selectedProject[desc] ? ReactHtmlParser(selectedProject[desc]) : "N/A"}
      </p>
    </div>
  );

  const renderLandingPage = () => (
    <div className={`section-wrapper video-section`}>
      <Heading icon={"atlas-link-2-linear"}>
        <span>Landing Page</span>
      </Heading>
      <div className="icon-list icon-list-wrap">
        <ul>
          <li className={"icon-list-items"}>
            <span className="icon-list-icon">
              <i className="fa fa-angle-right"></i>
            </span>
            <a
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() =>
                window.open(selectedProject.landing_page, "_blank")
              }
            >
              {selectedProject.landing_page}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className={`project-image-block`}>
      {dataToRender.map((item, index) => (
        <div
          key={`ProjectDetailsPhoto-${index}`}
          className="container at-container"
        >
          <div
            className="row align-items-center project-image-content"
            style={{ backgroundColor: index % 2 === 1 ? "#F3F4F6" : "#EFEDEB" }}
          >
            {item.source === "landing_page" && renderLandingPage()}
            {item.source !== "landing_page" && (
              <>
                {index % 2 === 1 &&
                  renderImageHeader(item.description, item.title, item.icon)}
                <div
                  className="col-lg-6 col-md-12 col-sm-12"
                  style={{ padding: 0, minHeight: 300 }}
                >
                  {selectedProject[item.source] && (
                    <img
                      className="img-fluid"
                      src={selectedProject[item.source]}
                      alt={`ProjectDetailsPhoto-${index}`}
                    />
                  )}
                </div>
                {index % 2 !== 1 &&
                  renderImageHeader(item.description, item.title, item.icon)}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectImages;
