import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";

import TableHeader from "./Header";
import TableContent from "components/NewPaginatedTable/Content";
import TablePagination from "components/NewPaginatedTable/Pagination";
import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

const PaginatedTable = ({
  meta,
  rowData: initialRowData,
  columns,
  sorting,
  searchParams,
  getListAPI,
  totalPages,
  actionColumnContent,
  enableSort,
  headerClass,
  onClickSortAction,
  clearFilterAction,
}) => {
  const [rowData, setRowData] = useState(initialRowData);

  useEffect(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  const onClickRearrangeData = useCallback(
    (val) => {
      let sortColumn = 0;
      let sortOrder = val.mode === "asc" ? 1 : val.mode === "desc" ? 2 : 0;
      switch (val.columnValue) {
        case "bookingDate":
          sortColumn = 1;
          break;
        case "address":
          sortColumn = 2;
          break;
        case "netPrice":
          sortColumn = 3;
          break;
        case "propertyTypeId":
          sortColumn = 4;
          break;
      }
      onClickSortAction(sortColumn, sortOrder);
    },
    [rowData],
  );

  return (
    <div className="at-table__options">
      <div className="at-table at-table--set">
        <TableHeader
          columns={columns}
          rowData={rowData}
          meta={meta}
          sorting={sorting}
          enableSort={enableSort}
          headerClass={headerClass}
          actionColumnContent={actionColumnContent}
          onClickRearrangeData={onClickRearrangeData}
        />
        {rowData &&
          rowData.length > 0 &&
          rowData.map((item, index) => (
            <TableContent
              key={index}
              columns={columns}
              index={index}
              actionColumnContent={actionColumnContent}
              rowData={item}
            />
          ))}
        {!(rowData && rowData.length > 0) && (
          <div className="at-empty-state">
            <ul className="at-table-row grid-controller">
              <li className="at-table-row__item at-table-row__item-empty">
                <p className="text-center">No result found</p>
                <span>
                  Try to adjust your search or filter options to find something
                  you're looking for
                </span>
                <br />
                {clearFilterAction && (
                  <button onClick={() => clearFilterAction()}>
                    Clear Filters
                  </button>
                )}
              </li>
            </ul>
          </div>
        )}
        {meta && meta.count > 0 && (
          <div className="at-pagination__cont">
            <TablePagination
              meta={meta}
              searchParams={searchParams}
              getListAPI={getListAPI}
              totalPages={totalPages}
            />
            <p className={`at-table__pagination-info`}>
              Showing
              <span> {meta && meta.from} </span>
              to
              <span> {meta && meta.to} </span>
              from a total of
              <span> {meta && meta.count} </span>
              row
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginatedTable;
