import Moment from "moment";
import { numberWithCommas } from "utils/thousandSeparator";

export const ProjectData = (teamView) => [
  {
    header: "Project Name",
    searchFlag: "projectName",
    accessor: "projectName",
  },
  {
    header: "Unit No.",
    searchFlag: "unitNumber",
    columnClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    accessor: "unitNumber",
  },
  {
    header: "Booking Date",
    searchFlag: "bookingDateShort",
    accessor: "bookingDateShort",
  },
  {
    header: "Estimated Comm.",
    searchFlag: "estimatedTotalCommission",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    renderColumn: (rowData) =>
      numberWithCommas(rowData.estimatedTotalCommission, 2),
    columnStyle: { width: "60%" },
  },
  {
    header: "Paid Comm.",
    searchFlag: "paidTotalCommission",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    renderColumn: (rowData) => numberWithCommas(rowData.paidTotalCommission, 2),
    columnStyle: { width: "50%" },
  },
  {
    header: "Pending Comm.",
    searchFlag: "pendingTotalCommission",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    renderColumn: (rowData) =>
      numberWithCommas(rowData.pendingTotalCommission, 2),
    columnStyle: { width: "60%" },
  },
  ...(!teamView
    ? [
        {
          header: "Buyer",
          accessor: "buyers",
          searchFlag: "buyers",
        },
      ]
    : []),
];

export const SubsalesData = (teamView) => [
  {
    header: "Type",
    searchFlag: "projectName",
    accessor: "projectName",
    columnStyle: { width: "50%" },
  },
  {
    header: "Form No.",
    searchFlag: "unitNumber",
    accessor: "unitNumber",
  },
  {
    header: "Submission Date",
    searchFlag: "bookingDateShort",
    renderColumn: ({ bookingDateShort }) =>
      bookingDateShort
        ? Moment(bookingDateShort, "DD-MM-YYYY").format("DD MMM YYYY")
        : "N/A",
  },
  {
    header: "Agency Commission",
    searchFlag: "grossCommission",
    columnClass: "text-right",
    contentClass: "at-column-responsive_right",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => numberWithCommas(rowData.grossCommission, 2),
  },
  ...(!teamView
    ? [
        {
          header: "Paid Commission",
          searchFlag: "paidTotalCommission",
          columnClass: "text-right",
          contentClass: "at-column-responsive_right",
          renderColumn: (rowData) =>
            numberWithCommas(rowData.paidTotalCommission, 2),
          columnStyle: { width: "70%" },
        },
        {
          header: "Buyer",
          accessor: "buyers",
          searchFlag: "buyers",
        },
      ]
    : []),
];

export const submittedUnitData = [
  {
    header: "Type",
    searchFlag: "type",
    accessor: "type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Form No.",
    searchFlag: "form_no",
    accessor: "form_no",
  },
  {
    header: "Submission Date",
    searchFlag: "submission_date",
    renderColumn: ({ submission_date }) =>
      submission_date
        ? Moment(submission_date, "YYYY-MM-DD").format("DD MMM YYYY")
        : "N/A",
  },
  {
    header: "Agency Commission",
    searchFlag: "agency_comm",
    columnClass: "text-right",
    contentClass: "at-column-responsive_right",
    renderColumn: (rowData) => numberWithCommas(rowData.agency_comm, 2),
    columnStyle: { width: "70%" },
  },
];

export const allSubsalesColumn = [
  {
    header: "Type",
    searchFlag: "type",
    accessor: "type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Form No.",
    searchFlag: "form_no",
    accessor: "form_no",
  },
  {
    header: "Submission Date",
    searchFlag: "submission_date",
    renderColumn: ({ submission_date }) =>
      submission_date
        ? Moment(submission_date, "YYYY-MM-DD").format("DD MMM YYYY")
        : "N/A",
  },
  {
    header: "Agency Commission",
    searchFlag: "agency_comm",
    columnClass: "text-right",
    contentClass: "at-column-responsive_right",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => numberWithCommas(rowData.agency_comm, 2),
  },
  {
    header: "Paid Commission",
    searchFlag: "paidTotalCommission",
    columnClass: "text-right",
    contentClass: "at-column-responsive_right",
    renderColumn: (rowData) =>
      rowData.paidTotalCommission
        ? numberWithCommas(rowData.paidTotalCommission, 2)
        : "N/A",
    columnStyle: { width: "70%" },
  },
  {
    header: "Buyer",
    accessor: "buyers",
    searchFlag: "buyers",
  },
];
