import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";

import AtlasButton from "components/Button";
import AtlasSelect from "components/Select/new";
import ModalDialog from "components/Modal/ModalDialog";

const Transfer = ({
  branches,
  selectedBranch,
  onLoadParkLicense,
  selectedParkLicense,

  transferParkLicense,
  onChangeParkLicenseHOC,
}) => {
  const [transferBranch, setTransferBranch] = useState("");

  return (
    <ModalDialog
      title={"Transfer Branch"}
      onLoad={onLoadParkLicense}
      children={
        <>
          <p className="at-form-input__title">
            Transfer License Number {selectedParkLicense.licence_number} to the
            selected branch.
          </p>
          <AtlasSelect
            value={transferBranch}
            onChange={(e) => setTransferBranch(e.target.value)}
          >
            {branches &&
              branches.length > 0 &&
              branches.map((item) => {
                return (
                  <MenuItem
                    classes={{ root: `at-select__dropdown-item` }}
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </AtlasSelect>
          <div className="at-modal_dialog-container-footer g-3">
            <AtlasButton
              className="btn-new btn-new--success"
              children={"Submit"}
              onClick={() =>
                transferParkLicense(
                  selectedBranch.id,
                  selectedParkLicense.id,
                  transferBranch,
                )
              }
            />
            <AtlasButton
              className="btn-new btn-new--outline-secondary"
              children={"Cancel"}
              onClick={() => onChangeParkLicenseHOC(false, "showTransferModal")}
            />
          </div>
        </>
      }
      onClose={() => onChangeParkLicenseHOC(false, "showTransferModal")}
    />
  );
};

export default Transfer;
