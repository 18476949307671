export const Tenure = [
  {
    id: 1,
    name: "Freehold",
    value: 1,
  },
  {
    id: 2,
    name: "Leasehold",
    value: 2,
  },
];
