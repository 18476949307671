import React, { Component } from "react";
import { connect } from "react-redux";
import { Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

import _ from "lodash";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showEditConfirmationModal: false,
      contentType: "Form",
    };

    load = (param) => this.setState({ loading: param });

    onChangeEditHOC = (val, context) => this.setState({ [context]: val });

    unlockConfirmationLetter = (id) =>
      Put(
        `/admin/forms/confirmation_forms/${id}/unseal_form`,
        { id: id },
        (payload) => this.unlockConfirmationLetterSuccess(payload, id),
        this.unlockConfirmationLetterError,
        this.load,
      );
    unlockConfirmationLetterSuccess = (payload, id) => {
      this.props.getConfirmationForm(id);
      this.setState({
        showEditConfirmationModal: false,
      });
      requestSuccess(payload.message);
    };
    unlockConfirmationLetterError = (error) => {
      this.setState({
        showEditConfirmationModal: false,
      });
      requestError(error);
    };

    updatePropertyListing = (propertyData) =>
      Put(
        `/admin/forms/confirmation_forms/${this.props.selectedConfirmationForm.id}/property_location`,
        propertyData,
        () => this.updatePropertyListingSuccess(this.props.selectedConfirmationForm.id),
        this.updatePropertyListingError,
        this.load,
      );
    updatePropertyListingSuccess = id => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.props.getConfirmationForm(id);
      this.props.getConfirmationForms(currentPage, searchParams)
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
      requestSuccess("Data updated successfully.");
    };
    updatePropertyListingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadEdit={this.state.loading}
            showEditConfirmationModal={this.state.showEditConfirmationModal}
            onChangeEditHOC={this.onChangeEditHOC}
            updatePropertyListing={this.updatePropertyListing}
            unlockConfirmationLetter={this.unlockConfirmationLetter}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
