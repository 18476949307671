import _ from "lodash";
import React, { useState, useEffect } from "react";
import { FormHelperText } from "@material-ui/core";

import UserCard from "components/Card/user";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation";
import AtPaginatedTable from "components/NewPaginatedTable";

import { getColorBadge } from "dictionary/badgeColor";
import { checkNull } from "utils/checkNull";

const columnData = ({
  can_update,
  setSelectedTicket,
  onChangeOrderHistoryHOC,
}) => [
  { header: "Ticket ID", accessor: "id", columnStyle: { width: "50%" } },
  {
    header: "Ticket",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => (
      <div className="d-flex flex-column g-2">
        <p>{rowData.ticket_pricing.title}</p>
        <div
          className="at-status_badge mb-10"
          style={{
            ...getColorBadge(rowData.status === "Pending" ? "Yellow" : "Blue"),
          }}
        >
          {rowData.status}
        </div>
      </div>
    ),
  },
  {
    header: "Event",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => rowData.event.name,
  },
  {
    header: "Assign To",
    renderColumn: (rowData) => {
      const { assignee } = rowData;

      if (checkNull(assignee) && rowData.status === "Assigned") {
        return (
          <>
            <p className={"fw-500"}>{assignee?.full_name ?? "N/A"}</p>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-sms"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {assignee?.email ?? "N/A"}
            </div>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-call"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {assignee?.mobile_contact_number ?? "N/A"}
            </div>
          </>
        );
      } else {
        return (
          <div className="d-flex flex-column g-2">
            <CustomButton
              style={{ fontSize: 12, padding: "6px 12px" }}
              className={"btn-new btn-new--secondary"}
              children={"Transfer"}
              disabled={!can_update}
              onClick={() => {
                setSelectedTicket(rowData);
                onChangeOrderHistoryHOC(true, "showTicketTransfer");
              }}
            />
          </div>
        );
      }
    },
  },
];

const ManageTicket = ({
  data,
  can_update,
  searchAgent,
  ticketPurchasers,
  ticketPurchasersPages,
  onLoadOrderHistory,

  showTicketTransfer,

  ticketTransfer,
  getAgentsBySearch,
  getTicketPurchasers,
  onChangeOrderHistoryHOC,
}) => {
  const [searchMobile, setSearchMobile] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showTransferConfirmation, setShowTransferConfirmation] =
    useState(false);
  const { role, is_superior } = window.location.href.includes(
    "/admin-impersonate",
  )
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const enablePASearch = role === "Personal Assistant" || is_superior;

  useEffect(() => {
    getTicketPurchasers("1");
  }, []);

  useEffect(() => {
    setSearchMobile("");
    onChangeOrderHistoryHOC({}, "searchAgent");
  }, [showTicketTransfer]);

  const onToggleTransferTicket = () => {
    setShowTransferConfirmation(false);
    ticketTransfer(selectedTicket.id, searchAgent.id);
  };

  return (
    <ModalDialog
      title={"Manage Ticket"}
      fullWidth={true}
      fullHeight={true}
      onClose={() => onChangeOrderHistoryHOC(false, "showManageTicket")}
      children={
        <>
          <AtPaginatedTable
            columns={columnData({
              can_update,
              setSelectedTicket,
              onChangeOrderHistoryHOC,
            })}
            rowData={ticketPurchasers.data}
            meta={ticketPurchasers.meta}
            searchParams={[]}
            getListAPI={(page) => getTicketPurchasers(page)}
            totalPages={ticketPurchasersPages}
          />
          {showTicketTransfer && (
            <ModalDialog
              title={`Transfer Ticket ${selectedTicket.id}`}
              onLoad={onLoadOrderHistory}
              onClose={() =>
                onChangeOrderHistoryHOC(false, "showTicketTransfer")
              }
              children={
                <>
                  <h2 className="at-form-input__title">
                    Transfer to the selected agent
                    {is_superior ? "/personal assistant" : ""} below
                  </h2>
                  <CustomFormInput
                    type="number"
                    value={searchMobile}
                    onChangeValue={(val) => setSearchMobile(val)}
                    placeholder={"e.g. 60128398273"}
                  />
                  <FormHelperText className="mb-3">
                    Search by recipient’s mobile number registered in Atlas
                  </FormHelperText>
                  <button
                    className="btn-new btn-new--secondary"
                    onClick={() =>
                      getAgentsBySearch(searchMobile, enablePASearch)
                    }
                  >
                    Search
                  </button>
                  {!_.isEmpty(searchAgent) && (
                    <UserCard
                      cardStyles={{ marginTop: 20 }}
                      selectedAgent={searchAgent}
                    />
                  )}
                </>
              }
              footer={
                <div className="d-flex justify-content-end g-3 p-3">
                  <CustomButton
                    className={"btn-new btn-new--success"}
                    disabled={_.isEmpty(searchAgent)}
                    children={"Transfer Now"}
                    onClick={() => setShowTransferConfirmation(true)}
                  />
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary"}
                    children={"Cancel"}
                    onClick={() =>
                      onChangeOrderHistoryHOC(false, "showTicketTransfer")
                    }
                  />
                </div>
              }
            />
          )}
          <ConfirmationModal
            loading={onLoadOrderHistory}
            open={showTransferConfirmation}
            message={`You are about to transfer the ticket to another person. Please be advised that once transferred, you won't be able to manage this ticket anymore.`}
            positiveAction={() => onToggleTransferTicket()}
            negativeAction={() => setShowTransferConfirmation(false)}
          />
          {onLoadOrderHistory && <LoadingModal />}
        </>
      }
    />
  );
};

export default ManageTicket;
