import React, { useEffect, useState } from "react";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import MeetinRooms from "../../Team/MeetingHub/MeetingRooms";
import MeetingRoomBooking from "./RoomBooking";
import MeetingRoomCalendar from "./Calendar";

import permissionsChecker from "utils/permissionsChecker";
import MeetingRoomHOC from "./actions";
import "./index.scss";

const MeetingRoom = (props) => {
  const { can_read: hubRead } = permissionsChecker("Meeting Hub", props.data);
  const { can_read: bookingRead } = permissionsChecker("Meeting Hub - Booking History", props.data);

  const [tabOptions, setTabOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("Reservation");

  useEffect(() => {
    let temp = []
    if (hubRead) {
      temp = ["Reservation", "Calendar"]
    }
    if (bookingRead) {
      temp.push("Booking History")
      if (!hubRead) {
        setSelectedSection("Booking History")
      }
    }
    setTabOptions(temp)
    props.getBranches()
  }, [])

  return (
    <>
      <ModuleHeader title={"Meeting Hub"} atlasIcon={"atlas-people-linear"} />
      <CustomCard
        cardContent={
          <>
            <CustomTab
              sections={tabOptions}
              selectedSection={selectedSection}
              onSelectSection={setSelectedSection}
            />
            {hubRead && selectedSection === "Reservation" && <MeetinRooms branches={props.branches} />}
            {bookingRead && selectedSection === "Booking History" && <MeetingRoomBooking branches={props.branches} />}
            {hubRead && selectedSection === "Calendar" && <MeetingRoomCalendar branches={props.branches} />}
          </>
        }
      />
      {props.onLoadMeetingRoom && <LoadingModal />}
    </>
  );
};

export default MeetingRoomHOC(MeetingRoom);
