export const RepaymentDetail = [
  {
    label: "Repayment Term",
    type: "select",
    value: "repayment_term",
    options: [
      { value: 1, label: "1 year" },
      { value: 3, label: "3 year" },
      { value: 5, label: "5 year" },
      { value: 7, label: "7 year" },
      { value: 9, label: "9 year" },
      { value: 10, label: "10 year" },
      { value: 12, label: "12 year" },
      { value: 15, label: "15 year" },
      { value: 20, label: "20 year" },
    ],
    className: "col-md-6 mb-20",
  },
  {
    label: "Repayment Currency",
    type: "select",
    value: "repayment_currency",
    className: "col-md-6",
  },
  {
    label: "Annual Interest (%)",
    type: "text",
    numericOnly: true,
    placeholder: "Annual Interest (%)",
    value: "interest_rate",
    maxLength: 4,
    className: "col-md-6",
  },
  {
    label: "Down Payment Percentage (%)",
    type: "text",
    numericOnly: true,
    placeholder: "Down Payment Percentage (%)",
    value: "down_payment_percentage",
    maxLength: 4,
    className: "col-md-6",
  },
  {
    label: "Monthly Installment",
    type: "text",
    numericOnly: true,
    disabled: true,
    placeholder: "Monthly installment",
    value: "monthly_installment",
    className: "col-md-6",
  },
  {
    label: "Total Payment",
    type: "text",
    numericOnly: true,
    placeholder: "Total Payment",
    value: "total_payment",
    disabled: true,
    className: "col-md-6",
  },
];

export const BuyerDetail = [
  {
    label: "Full Name",
    type: "text",
    placeholder: "Buyer Full Name",
    value: "buyer_full_name",
    className: "col-md-12",
  },
  {
    label: "Identity Card/Passport No.",
    type: "select",
    value: "identity_type",
    options: [
      { label: "MyKad", value: "MyKad" },
      { label: "Passport", value: "Passport" },
    ],
    className: "col-md-2",
  },
  {
    label: "",
    type: "text",
    placeholder: "Buyer Identity Card Number",
    value: "ic_number",
    className: "col-md-4",
  },
  {
    label: "Email",
    type: "text",
    placeholder: "Buyer Email",
    value: "email",
    className: "col-md-6",
  },
  {
    label: "Phone Number",
    type: "number",
    numericOnly: true,
    placeholder: "Buyer Contact Phone Number",
    value: "contact_no",
    className: "col-md-6",
  },
  {
    label: "Gender",
    type: "select",
    value: "gender",
    options: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
    ],
    className: "col-md-6",
  },
  {
    label: "Nationality",
    type: "text",
    placeholder: "Buyer Nationality",
    value: "nationality",
    className: "col-md-6",
  },
  {
    label: "Address",
    type: "text",
    placeholder: "Buyer Address",
    value: "address",
    className: "col-md-12",
  },
  {
    label: "Country",
    type: "select",
    value: "country_id",
    className: "col-md-6 mb-30",
  },
  {
    label: "State",
    type: "select",
    placeholder: "Buyer State",
    value: "state_id",
    placeholder: "Please choose a country first.",
    className: "col-md-6",
  },
  {
    label: "Postcode",
    type: "text",
    numericOnly: true,
    placeholder: "Buyer Poscode",
    value: "postcode",
    className: "col-md-6",
  },
  "File Attachment",
  {
    label: "IC/Passport Front Photo",
    type: "doc",
    value: "ic_front",
    required: true,
    className: "col-md-6 mb-20",
  },
  {
    label: "IC/Passport Back Photo",
    type: "doc",
    value: "ic_back",
    required: true,
    className: "col-md-6 mb-20",
  },
];

export const PaymentSlip = [
  {
    label: "Payment Slip",
    type: "doc",
    value: "payment_slip",
    required: true,
    className: "col-md-6 mb-20",
  },
];
