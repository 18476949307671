import React, { Component } from "react";

import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      qrSetting: {
        settings: {
          radius: 0.5,
        },
      },
      selectedBranch: 16,
      auditLogs: [],
      auditLogsPage: [],
    };

    load = (param) => this.setState({ loading: param });
    onChangeQREntryHOC = (val, context) => this.setState({ [context]: val });

    getRadiusSetting = () =>
      Get(
        `/branches/${this.state.selectedBranch}/settings`,
        this.getRadiusSettingSuccess,
        this.getRadiusSettingError,
        this.load,
      );
    getRadiusSettingSuccess = (payload) =>
      this.setState(
        {
          qrSetting: payload ? payload : { settings: { radius: 0.5 } },
        },
        () => this.getAuditLogs(1, ""),
      );
    getRadiusSettingError = (error) => requestError(error);

    getAuditLogs = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/branches/${this.state.selectedBranch}/audits?${search}page=${page}`,
        this.getAuditLogsSuccess,
        this.getAuditLogsError,
        this.load,
      );
    };
    getAuditLogsSuccess = (payload) => {
      let tmpTotalPages = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      this.setState({
        auditLogs: payload,
        auditLogsPage: tmpTotalPages,
      });
    };
    getAuditLogsError = (error) => requestError(error);

    updateRadiusSetting = () => {
      if (this.state.qrSetting && this.state.qrSetting.id) {
        Put(
          `/entity_settings/${this.state.qrSetting.id}`,
          {
            settings: {
              radius: this.state.qrSetting.settings.radius,
            },
          },
          this.updateRadiusSettingSuccess,
          this.updateRadiusSettingError,
          this.load,
        );
      } else {
        Post(
          `/entity_settings`,
          {
            entity_id: this.state.selectedBranch,
            entity_type: "Branch",
            key: "branch.radius",
            settings: {
              radius: this.state.qrSetting.settings.radius,
            },
          },
          this.updateRadiusSettingSuccess,
          this.updateRadiusSettingError,
          this.load,
        );
      }
    };

    updateRadiusSettingSuccess = () =>
      requestSuccess("QR Door Entry Radius Updated.");
    updateRadiusSettingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadQR={this.state.loading}
            qrSetting={this.state.qrSetting}
            selectedBranch={this.state.selectedBranch}
            auditLogs={this.state.auditLogs}
            auditLogsPage={this.state.auditLogsPage}
            getRadiusSetting={this.getRadiusSetting}
            getAuditLogs={this.getAuditLogs}
            updateRadiusSetting={this.updateRadiusSetting}
            onChangeQREntryHOC={this.onChangeQREntryHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
