import React, { Component } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

import CustomSelect from "components/Select";
import CustomTableHeader from "./components/header";
import CustomTableContent from "./components/content";

import "stylesheets/components/table/index.scss";

class CustomTable extends Component {
  state = {
    searching: false,
    searchKeyword: "",
    selectHeaderData: "",
    totalPaginationCount: 0,
    totalPages: [1],
    clickPageNumber: 1,
    headerRearrangeData: {},
    rowData: [],
    initialRowData: [],
    searchWithField: "",
    searchWithFieldDisplay: "",
  };

  componentDidMount = () =>
    (this.debounceService = _.debounce(this.debounceService, 300));

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData.length !== prevProps.rowData.length) {
      return this.setState({
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
      });
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;
        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          Object.keys(item).map((key) => {
            if (tmp[key] !== item[key]) {
              dataUpdated = true;
            }
          });
        });
        if (dataUpdated) {
          return this.setState(
            {
              rowData: this.props.rowData,
              initialRowData: this.props.rowData,
            },
            () => {
              this.debounceService(this.state.searchKeyword);
            },
          );
        }
      }
    }
  };

  componentDidMount = () =>
    this.setState({
      rowData: this.props.rowData,
      initialRowData: this.props.rowData,
      searchWithField: this.props.searchWithField
        ? this.props.searchWithField
        : this.props.headerData[0].value,
      searchWithFieldDisplay: this.props.searchWithField
        ? this.props.searchPlaceholder
        : this.props.headerData[0].label,
    });

  debounceService = (searchVal) => {
    const { searchWithField } = this.state;
    let result = _.filter(this.state.initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });
    return this.setState({
      rowData: result,
      searching: false,
    });
  };

  onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    return this.setState(
      {
        searchKeyword: searchVal,
      },
      () => {
        if (this.state.searchKeyword === "") {
          this.setState({
            rowData: this.state.initialRowData,
          });
        } else {
          this.debounceService(searchVal);
        }
      },
    );
  };

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      }
    });

  selectSearchHeader = (val) =>
    this.setState({
      searchWithField: val.value,
      searchWithFieldDisplay: val.label,
    });

  renderTableContent = () => {
    if (this.state.rowData) {
      return this.state.rowData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          headerData={this.props.headerData}
          onToggleCheckBox={this.props.onToggleCheckBox}
          rowItem={rowItem}
          headerRearrangeData={this.state.headerRearrangeData}
        />
      ));
    }
  };

  render = () => {
    const { className, headerData, actionColumn } = this.props;
    return (
      <div className={`mb-20 ${className}`}>
        <div className="at-table at-table--set">
          <div className="at-table-head d-flex">
            <div className="grid-control w-100">
              <div className="grid-half-col">
                <div className="at-select__search-cont">
                  <CustomSelect
                    className="at-select_new-interface"
                    selectItems={
                      _.filter(this.props.headerData, (o) => {
                        return o.label === "Module Name";
                      }) || []
                    }
                    placeholder={
                      this.props.searchPlaceholder
                        ? this.props.searchPlaceholder
                        : headerData[0].label
                    }
                    updateSelect={(val) => this.selectSearchHeader(val)}
                  />
                  <input
                    type="search"
                    key={"searchInput"}
                    value={this.state.searchKeyword}
                    className={"at-table__search-input"}
                    disabled={this.state.searching}
                    placeholder={`Search with ${this.state.searchWithFieldDisplay}`}
                    onChange={(event) =>
                      this.onChangeSearchValue(event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <PulseLoader
              className={"mt-10"}
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={this.state.searching}
            />
          </div>
          <CustomTableHeader
            data={headerData}
            actionColumn={actionColumn}
            onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
          />
          {this.renderTableContent()}
        </div>
        <div className="at-modal_dialog-container-footer g-3">
          {this.state.rowData.length > 0 && (
            <button
              onClick={() => this.props.onClickSubmit()}
              className={"btn-new btn-new--success"}
            >
              Save
            </button>
          )}
          <button
            onClick={() => this.props.onClose()}
            className={"btn-new btn-new--outline-secondary"}
          >
            Close
          </button>
        </div>
      </div>
    );
  };
}

export default CustomTable;
