import React from "react";
import _ from "lodash";

import { getColorBadge } from "dictionary/badgeColor";
import { numberWithCommas } from "utils/thousandSeparator";

const StatusColor = [
  { value: "Completed", colorName: "Green" },
  { value: "Processing", colorName: "Yellow" },
  { value: "Error", colorName: "Red" },
];

export const downloadLogsHeader = [
  {
    header: "Download Date",
    searchFlag: "created_at",
    accessor: "created_at",
  },
  {
    header: "PV Start Date",
    searchFlag: "pv_start_date",
    accessor: "pv_start_date",
  },
  {
    header: "PV End Date",
    searchFlag: "pv_end_date",
    accessor: "pv_end_date",
  },
  {
    header: "Status",
    searchFlag: "status",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

export const ColumnData = [
  {
    header: "PV No.",
    searchFlag: "pvNumber",
    accessor: "pvNumber",
    columnStyle: { width: "60%" },
  },
  {
    header: "PV Date",
    searchFlag: "pvDate",
    accessor: "pvDate",
    columnStyle: { width: "60%" },
  },
  {
    header: "Paid For",
    searchFlag: "pvDescription",
    accessor: "pvDescription",
  },
  {
    header: "Payment Type",
    searchFlag: "pvCommType",
    accessor: "pvCommType",
    columnStyle: { width: "50%" },
  },
  {
    header: "Paid Amount",
    searchFlag: "pvAmount",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
    accessor: "pvAmount",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => numberWithCommas(rowData.pvAmount),
  },
];
