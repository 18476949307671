import React from "react";
import _ from "lodash";
import Moment from "moment";
import CurrencyInput from "react-currency-input-field";

import AtlasFormGroup from "components/FormGroup/input";
import CustomFormInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";

import {
  DurationTypeRadioOptions,
  Stakeholders,
  AppointmentBasisOptions,
} from "../assets";

const subrentForms = [
  {
    label: "Property address",
    value: "property_address",
    col: "full",
    type: "autosuggest",
    disabled: true,
    required: true,
  },
  {
    label: "Appointment date",
    value: "appointment_date",
    type: "date",
    required: true,
  },
  {
    label: "Monthly rental (RM)",
    value: "rental_amount",
    type: "currency",
    required: true,
  },
  {
    label: "Intended tenure of tenancy (years)",
    value: "intended_tenure_years",
    type: "number",
    required: true,
  },
  {
    label: "Renewal of tenure of tenancy (years)",
    value: "renewal_tenure_years",
    type: "number",
    required: false,
  },
  {
    label: "Period of advanced rental (months)",
    value: "advanced_rental_months",
    type: "number",
    required: true,
  },
  {
    label: "Amount of advanced rental (RM)",
    value: "advanced_rental_amount",
    type: "currency",
    automated: true,
    required: true,
  },
  {
    label: "Period of refundable security deposit (months)",
    value: "security_deposit_months",
    type: "number",
    required: true,
  },
  {
    label: "Amount of refundable security deposit (RM)",
    value: "security_deposit_amount",
    type: "currency",
    automated: true,
    required: true,
  },
  {
    label: "Amount of refundable utility deposit (RM)",
    value: "utility_deposit_amount",
    type: "currency",
    required: true,
  },
  {
    label: "Condition",
    value: "conditions",
    type: "text",
    required: false,
  },
  {
    label: "Type of duration",
    value: "duration_type_id",
    type: "radio",
    required: true,
    options: DurationTypeRadioOptions,
    contents: [
      {
        id: 1,
        show: ({ duration_type_id }) =>
          duration_type_id === 1 || duration_type_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const {
            duration_type_id,
            duration_of_tenancy,
            max_fee,
            fee_payable_amount,
            sales_tax_percentage,
          } = data;
          return (
            <ul className="at-form_mngmt-collapsed_cont">
              {duration_type_id === 2 && (
                <>
                  <li className="at-form_mngmt-form_collapsed">
                    <h2 className="at-form-input__title">
                      Duration of tenancy (years):{" "}
                    </h2>
                    <div className="at-form-input">
                      <input
                        className="at-form-input__input"
                        type={"number"}
                        inputmode={"decimal"}
                        pattern={"d*"}
                        value={duration_of_tenancy}
                        onChange={(e) =>
                          onChange("duration_of_tenancy", e.target.value)
                        }
                        disabled={!can_update}
                      />
                    </div>
                  </li>
                  <li className="at-form_mngmt-form_collapsed">
                    <h2 className="at-form-input__title">
                      Maximum Fee (months):{" "}
                    </h2>
                    <div className="at-form-input">
                      <input
                        className="at-form-input__input"
                        type={"number"}
                        inputmode={"decimal"}
                        pattern={"d*"}
                        value={max_fee}
                        onChange={(e) => onChange("max_fee", e.target.value)}
                        disabled={!can_update}
                      />
                    </div>
                  </li>
                </>
              )}
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title" required>
                  Payable amount of full professional fee (RM):
                </h2>
                <div className={`at-form-input ${!can_update ? "at-form-input__disabled" : ""}`}>
                  <CurrencyInput
                    required
                    className="at-form-input__input"
                    style={{ paddingLeft: "0.2rem" }}
                    name={"fee_payable_amount"}
                    defaultValue={1000}
                    value={fee_payable_amount || ""}
                    disabled={!can_update}
                    onValueChange={(value, name) => {
                      onChange("fee_payable_amount", value);
                    }}
                  />
                </div>
                <div className="at-input_footer" 
                  style={{ marginBottom: "1rem" }}>
                  <div className="at-form-input__required">required *</div>
                </div>
              </li>
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title" required>
                  Sales and services tax (%):{" "}
                </h2>
                <div className="at-form-input at-form-input__disabled">
                  <input
                    required
                    type={"number"}
                    className="at-form-input__input"
                    value={sales_tax_percentage}
                    onChange={() => {}}
                    disabled={true}
                  />
                </div>
                <div className="at-input_footer" 
                  style={{ marginBottom: "1rem" }}>
                  <div className="at-form-input__required">required *</div>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
  },
  {
    label: "Basis of appointment",
    value: "basis_id",
    type: "radio",
    required: true,
    options: AppointmentBasisOptions,
    contents: [
      {
        id: 1,
        show: ({ basis_id }) => basis_id === 1,
        renderContent: ({ data, can_update, onChange }) => {
          const { valid_from = null, valid_to = null } = data;
          return (
            <ul className="at-form_mngmt-collapsed_cont">
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Valid from</h2>
                <AtlasFormGroup
                  type={"calendar"}
                  placeholder={"Select date"}
                  value={valid_from}
                  onSelectDate={(val) => {
                    onChange(
                      "valid_from",
                      val && val !== null
                        ? Moment(val).format("YYYY-MM-DD")
                        : null,
                    );
                  }}
                  disabledDate={!can_update}
                />
              </li>
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Valid to</h2>
                <AtlasFormGroup
                  type={"calendar"}
                  placeholder={"Select date"}
                  value={valid_to}
                  onSelectDate={(val) => {
                    onChange(
                      "valid_to",
                      val && val !== null
                        ? Moment(val).format("YYYY-MM-DD")
                        : null,
                    );
                  }}
                  disabledDate={!can_update}
                />
              </li>
            </ul>
          );
        },
      },
    ],
  },
  {
    label: "Party to be authorized as a stakeholder",
    value: "stake_holder_id",
    type: "radio",
    required: true,
    options: Stakeholders,
    contents: [
      {
        id: 1,
        show: ({ stake_holder_id }) => stake_holder_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const {
            stake_holder_company = "",
            stake_holder_registration_number = "",
          } = data;

          return (
            <ul className="at-form_mngmt-collapsed_cont">
              <li className="at-form_mngmt-form_collapsed">
                <h5 className="at-form-input__title" required> Lawyer Company Name: </h5>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_company || ""}
                  onChangeValue={(val) => onChange("stake_holder_company", val)}
                  disabled={!can_update}
                />
              </li>
              <li className="at-form_mngmt-form_collapsed">
                <h5 className="at-form-input__title" required>
                  Lawyer Company Registration Number:
                </h5>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_registration_number || ""}
                  onChangeValue={(val) =>
                    onChange("stake_holder_registration_number", val)
                  }
                  disabled={!can_update}
                />
              </li>
            </ul>
          );
        },
      },
    ],
  },
  {
    type: "custom",
    renderCustomForm: ({ data, can_update, onChange }) => {
      const { day_to_remind_after_appointed = "", remind_me = "" } = data;
      return (
        <li
          className="at-form_mngmt-form_list grid-full-col"
          style={{ width: "100%", alignItems: "center" }}
        >
          <CustomCheckbox
            labelStyle={{ alignItems: "center" }}
            checked={remind_me}
            disabled={!can_update}
            content={
              <span>
                Remind me about this instruction after
                <input
                  type={"text"}
                  disabled={!can_update}
                  className="subsales-reminder-days-input"
                  value={day_to_remind_after_appointed}
                  onChange={(e) =>
                    onChange("day_to_remind_after_appointed", e.target.value)
                  }
                />
                days.
              </span>
            }
            onChangeCheckboxValue={(e) =>
              onChange("remind_me", e.target.checked)
            }
          />
        </li>
      );
    },
  },
];

export default subrentForms;
