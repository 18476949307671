import React from "react";
import AtlasIcon from "components/Icon/atlasIcon";

export const TabOptions = (section, status_permissions, can_create) =>
  section === "Project Partner"
    ? [
        {
          id: 3,
          label: "Search Project Partner",
          type: null,
          icon: "atlas-search-normal",
        },
        {
          id: 1,
          label: "Sent Requests",
          type: null,
          icon: "atlas-building-send",
        },
        {
          id: 2,
          label: "Received Requests",
          type: "partner_request",
          icon: "atlas-building-receive",
        },
      ]
    : [
        ...(status_permissions && can_create
          ? [
              {
                id: 0,
                label: "Search Listing",
                type: null,
                icon: "atlas-search-normal",
              },
            ]
          : []),
        {
          id: 1,
          label: "Sent Requests",
          type: null,
          icon: "atlas-request-send",
        },
        {
          id: 2,
          label: "Received Requests",
          type: "co_broke_center",
          icon: "atlas-request-receive",
        },
        {
          id: 3,
          label: "Sent Recommendations",
          type: null,
          icon: "atlas-recommendation-send",
        },
        {
          id: 4,
          label: "Received Recommendations",
          type: "recommendation_engine",
          icon: "atlas-recommendation-receive",
        },
      ];

export const counterInstruction = [
  {
    label: "Existing contact",
    icon: <AtlasIcon svgHref={"atlas-user-square-linear"} />,
  },
  {
    label: "Find Project Partner in Atlas",
    icon: <AtlasIcon svgHref={"atlas-search-normal"} />,
  },
  {
    label: "Send request",
    icon: <AtlasIcon svgHref={"atlas-send-square-2"} />,
  },
  {
    label: "Co-Broke request accepted",
    icon: <AtlasIcon svgHref={"atlas-shake-hand"} />,
  },
  {
    label: "Appt & sales process",
    icon: <AtlasIcon svgHref={"atlas-calendar-2"} />,
  },
  {
    label: "Close sale & co-broke commission",
    icon: <AtlasIcon svgHref={"atlas-verify"} />,
  },
];

export const percentageGuideline = [
  { label: "Lead/Contact", percent: "40%" },
  { label: "Appointment", percent: "20%" },
  { label: "Presentation/Closing", percent: "40%" },
];

export const PrimaryTabOptions = [
  { icon: "atlas-project-partner", label: "Project Partner" },
  { icon: "atlas-building-3", label: "Secondary Market" },
];
