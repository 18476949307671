import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showPhotoTabModal: false,

      uploadedFloorPlans: [],
      floorPlanListing: [],
      tempImgSrc: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeFloorPlanHOC = (val, context) => this.setState({ [context]: val });

    // get floor plans photos for a listing
    getFloorPlans = (id) =>
      Get(
        `/admin/sub_sales/${id}/floorplans?locale=${this.props.data.languageReducer.language}`,
        this.getFloorPlansSuccess,
        this.getFloorPlansError,
        this.load,
      );
    getFloorPlansSuccess = (payload) =>
      this.setState({ uploadedFloorPlans: payload }, () => {
        let tmp = payload.map((i) => i.large_image_url);
        this.setState({ floorPlanListing: tmp });
        this.state.from === "UPLOAD_FLOOR_PLAN" &&
          this.filterUploadedFloorPlans();
      });
    getFloorPlansError = (error) => requestError(error && error);

    // get Township array based on state
    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) => {
      let temp = [];
      payload.map((item) => {
        let tempObj = { value: item.name };
        temp.push(tempObj);
      });
      this.setState({ townshipArray: temp });
    };
    getSelectedTownShipError = (error) => requestError(error && error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            tempImgSrc={this.state.tempImgSrc}
            onLoadFloorPlans={this.state.loading}
            showPhotoTabModal={this.state.showPhotoTabModal}
            uploadedFloorPlans={this.state.uploadedFloorPlans}
            floorPlanListing={this.state.floorPlanListing}
            getFloorPlans={this.getFloorPlans}
            onChangeFloorPlanHOC={this.onChangeFloorPlanHOC}
          />
        </Fragment>
      );
    };
  }
  return WithHOC;
};

export default HOC;
