import React, { Component } from "react";
import _ from "lodash";

import { Post, Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      searchedAgents: null,
      searchValue: "",
      agentList: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeAgentHOC = (val, context) => this.setState({ [context]: val });

    getInternalAgent = (dataToSubmit) =>
      Post(
        `/admin/users/search`,
        dataToSubmit,
        this.getInternalAgentSuccess,
        this.getInternalAgentError,
        this.load,
      );
    getInternalAgentSuccess = (payload) =>
      this.setState({ searchedAgents: payload.agent_selections });
    getInternalAgentError = (error) => requestError(error);

    getAgentsBySearch = (val) =>
      Get(
        `/admin/users/search?type=1&name=${val}`,
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ agentList: payload.agent_selections });
    getAgentsBySearchError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadAgent={this.state.loading}
          searchValue={this.state.searchValue}
          searchedAgents={this.state.searchedAgents}
          agentList={this.state.agentList}
          onChangeAgentHOC={this.onChangeAgentHOC}
          getAgentsBySearch={this.getAgentsBySearch}
          getInternalAgent={this.getInternalAgent}
          requestError={requestError}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
