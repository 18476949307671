import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { MenuItem } from "@material-ui/core";
import { FaRegChartBar } from "react-icons/fa";
import { BiTable } from "react-icons/bi";
import { Bar } from "react-chartjs-2";
import { MdFilterList } from "react-icons/md";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtSelectNew from "components/Select/new";
import IOSButton from "components/Button/toggle";
import AtlasIcon from "components/Icon/atlasIcon";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";

import { numberWithCommas } from "utils/thousandSeparator";

const intervalOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const columnData = [
  {
    header: "Rank",
    accessor: "rangking",
    searchFlag: "rangking",
    columnStyle: { width: "50%" },
  },
  {
    header: "Activity",
    accessor: "activity",
    searchFlag: "activity",
    columnStyle: { width: "150%" },
  },
  {
    header: "Total Hit",
    accessor: "total_hits",
    searchFlag: "total_hits",
    columnStyle: { width: "60%" },
  },
  {
    header: "Total Unique User",
    accessor: "total_unique_users",
    searchFlag: "total_unique_users",
    columnStyle: { width: "60%" },
  },
];

const SearchParams = ({ country, dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          param: `start_date=${Moment().subtract(1, "years").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Country",
    value: "country",
    param: { name: "Malaysia" },
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    typeaheadId: "country_typeahead",
    showClearButton: true,
    options: country,
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "View By",
    value: "interval",
    type: "radio",
    param: "month",
    options: intervalOptions,
  },
];

const TopActivity = ({
  data,
  topActivities,
  activityFilter,
  activityOptions,
  selectedTopActivity,

  getTopActivities,
  processTopActivity,
  onChangeAnalyticHOC,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      country: data.dictionaryReducer.countries,
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getTopActivities(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        country: data.dictionaryReducer.countries,
        dateRangeMode,
      }),
    );
    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  const renderSearchMode = () => (
    <div className="at-analytic-centre__mode-toggle">
      Quick Select
      <IOSButton
        className={"ml-2"}
        currentState={!dateRangeMode}
        onToggleButton={() => setDateRangeMode(!dateRangeMode)}
      />
      Date Range
    </div>
  );

  return (
    <>
      <div className="mb-10">
        <button
          className={
            "btn-new btn-new--outline-secondary at-mobile-view__controller"
          }
          style={{ width: "100%" }}
          onClick={() => setShowSearchContent((prevState) => !prevState)}
        >
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5>
        </button>
        <div className="at-table__search-cont at-desktop-view__controller ">
          <TableSearchParams
            mode={"desktop"}
            showResetButton={false}
            searchParams={searchParams}
            renderSearchMode={renderSearchMode()}
            onChangeSearchParams={setSearchParams}
            onToggleSearchModal={() =>
              setShowSearchContent((prevState) => !prevState)
            }
            getListAPI={(page, search) => getTopActivities(search)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center mb-10">
        <div style={{ width: 300, marginRight: 10 }}>
          <AtSelectNew
            value={activityFilter}
            style={{ display: "flex", flexDirection: "column" }}
            onChange={(e) =>
              Promise.all([
                onChangeAnalyticHOC(e.target.value, "activityFilter"),
              ]).then(() => {
                processTopActivity(topActivities);
              })
            }
          >
            {activityOptions.length > 0 &&
              activityOptions.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
          </AtSelectNew>
        </div>
        <div className="d-flex">
          <button
            className={`at-table-search__additional-btn ${visual === "chart" && "at-table-search__additional--selected"}`}
            onClick={() => setVisual("chart")}
            children={
              <FaRegChartBar style={{ width: "20px", height: "20px" }} />
            }
          />
          <button
            onClick={() => setVisual("table")}
            className={`at-table-search__additional-btn ${visual === "table" && "at-table-search__additional--selected"}`}
            children={<BiTable style={{ width: "20px", height: "20px" }} />}
          />
        </div>
      </div>
      {topActivities && topActivities.length > 0 && visual === "table" && (
        <CustomTable
          hidePagination={true}
          hideSearch={true}
          rowData={selectedTopActivity.data}
          columns={columnData}
        />
      )}
      {topActivities && topActivities.length > 0 && visual === "chart" && (
        <div style={{ height: `${window.innerHeight * 0.7}px`}}>
          <Bar
            data={selectedTopActivity.chart}
            options={{
              plugins: {
                datalabels: window.innerWidth < 820 ? false : {
                  anchor: "end",
                  align: "end",
                  formatter: (value) => numberWithCommas(value),
                }
              },
              maintainAspectRatio: false,
              aspectRatio: 0.2,
              scales: {
                xAxes: [
                  window.innerWidth < 820 ? 
                  {
                    ticks: {
                      fontSize: 11,
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90,
                    },
                  } :
                  {
                    ticks: {
                      fontSize: 14,
                      maxRotation: 90,
                      minRotation: 90,
                      align: "start",
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 10,
                      callback: (value) => numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />  
        </div>
      )}
      {!(topActivities && topActivities.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={
            visual === "chart" ? (
              <AtlasIcon svgHref="atlas-chart-3" />
            ) : (
              <AtlasIcon svgHref="atlas-document-text" />
            )
          }
        />
      )}
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          showResetButton={false}
          searchParams={searchParams}
          renderSearchMode={renderSearchMode()}
          onChangeSearchParams={setSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={(page, search) => getTopActivities(search)}
        />
      )}
    </>
  );
};

export default TopActivity;
