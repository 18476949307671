import React, { Component } from "react";

import LoadingModal from "components/LoadingModal";
import ReassignTable from "../components/ReassignTable";
import ConfirmationModal from "components/Modal/confirmation";
import RejectConfirmationModal from "components/Modal/confirmation";

import ReassignHOC from "../actions/reassign";

const headerData = [
  {
    label: "Form Number",
    value: `confirmation_form_number`,
    width: "100%",
  },
  {
    label: "Submission Type",
    value: "submission_type",
    width: "80%",
  },
  {
    label: "Type",
    value: "type",
    width: "80%",
  },
  {
    label: "From",
    value: `from_admin`,
    width: "130%",
  },
  {
    label: "To",
    value: "to_admin",
    width: "130%",
  },
  {
    label: "Status",
    value: "status",
    width: "80%",
  },
];

class ReassignSubmission extends Component {
  componentDidMount = () => this.props.getReassignSubmissions(1);

  render = () => {
    const {
      reassignPages,
      selectedSubmissionId,
      reassignSubmissions,
      getReassignSubmissions,
      onLoadSubsaleClaims,
      onLoadReassign,
      showApproveModal,
      showRejectModal,
      showResubmitModal,

      onClose,
      onChangeReassignHOC,
    } = this.props;

    return (
      <>
        <ReassignTable
          tableTitle={`Reassign Submissions`}
          onClickClose={onClose}
          headerData={headerData}
          actionColumn={true}
          totalPages={reassignPages}
          actionColumnContent={["approve", "resubmit", "reject"]}
          onClickApprove={(data) => {
            onChangeReassignHOC(true, "showApproveModal");
            onChangeReassignHOC(data.id, "selectedSubmissionId");
          }}
          onClickResubmit={(data) => {
            onChangeReassignHOC(true, "showResubmitModal");
            onChangeReassignHOC(data.id, "selectedSubmissionId");
          }}
          onClickReject={(data) => {
            onChangeReassignHOC(true, "showRejectModal");
            onChangeReassignHOC(data.id, "selectedSubmissionId");
          }}
          rowData={reassignSubmissions.data}
          meta={reassignSubmissions ? reassignSubmissions.meta : {}}
          searchParams={[]}
          onChangeSearchParams={() => {}}
          getListAPI={getReassignSubmissions}
        />
        <ConfirmationModal
          open={showApproveModal}
          mode={"alert"}
          loading={onLoadSubsaleClaims || onLoadReassign}
          message={`Are your sure to approve this submission reassignment ?`}
          positiveAction={() =>
            this.props.approveReassignSubmissions(selectedSubmissionId)
          }
          negativeAction={() => onChangeReassignHOC(false, "showApproveModal")}
        />
        <ConfirmationModal
          open={showResubmitModal}
          mode={"alert"}
          loading={onLoadSubsaleClaims || onLoadReassign}
          message={`Are your sure to resubmit this submission reassignment ?`}
          positiveAction={() =>
            this.props.resubmitReassignSubmissions(selectedSubmissionId)
          }
          negativeAction={() => onChangeReassignHOC(false, "showResubmitModal")}
        />
        <RejectConfirmationModal
          open={showRejectModal}
          mode={"alert"}
          fieldLabel={"Reject Reason"}
          loading={onLoadSubsaleClaims || onLoadReassign}
          message={"Are your sure to reject this submission reassignment"}
          positiveAction={(val) =>
            this.props.rejectReassignSubmissions({
              remark: val,
              id: selectedSubmissionId,
            })
          }
          negativeAction={() => onChangeReassignHOC(false, "showRejectModal")}
        />
        {(onLoadSubsaleClaims || onLoadReassign) && <LoadingModal />}
      </>
    );
  };
}

export default ReassignHOC(ReassignSubmission);
