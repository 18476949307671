import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";
import Moment from "moment";
import {
  types,
  categories,
  statuses,
  measurements,
  occupancies,
  furnishing_statuses,
  tenure_types,
} from "dictionary/subsales";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showEstateDetailsModal: false,
      selectedEstateDetails: null,
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    load = (param) => this.setState({ loading: param });

    onChangeEstateDetailsHOC = (val, context) =>
      this.setState({ [context]: val });

    getSelectedEstate = (id, presentationId) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      Get(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates/listing_info?id=${id}`,
        this.getSelectedEstateSuccess,
        this.getSelectedEstateError,
        this.load,
      );
    };
    getSelectedEstateSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);
      let tmpType = _.find(types, { id: tmp.type_id });
      let tmpCategory = _.find(categories, { id: tmp.category_id });
      let tempStatus = _.find(statuses, { id: tmp.status_id });
      let tmpCatGroupPropertyType = {};
      if (tmpCategory) {
        tmpCategory.group_types.map((child) => {
          return child.children.map((childItem) => {
            if (childItem.id === tmp.property_type_id) {
              tmpCatGroupPropertyType = childItem;
            }
          });
        });
      }
      let tempFurnishing = _.find(furnishing_statuses, {
        id: tmp.furnishing_status_id,
      });
      let tempMeasurement = _.find(measurements, { id: tmp.measurement_id });
      let tempOccupancy = _.find(occupancies, { id: tmp.occupancy_id });
      let tempTenure = _.find(tenure_types, { id: tmp.tenure_id });

      let featureArray = [
        {
          label: "Occupancy",
          value:
            tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
              ? tempOccupancy.name
              : "N/A",
        },
        {
          label: "Furnishing",
          value:
            tempFurnishing && tempFurnishing.name.toLowerCase() !== "unknown"
              ? tempFurnishing.name
              : "N/A",
        },
        {
          label: "Tenure",
          value:
            tempTenure && tempTenure.name.toLowerCase() !== "unknown"
              ? tempTenure.name
              : "N/A",
        },
      ];

      let facilitiesArray = [
        { label: "All Day Security", value: tmp.all_day_security },
        { label: "Business Centre", value: tmp.business_centre },
        { label: "Parking", value: tmp.parking },
        { label: "Gymnasium", value: tmp.gymnasium },
        { label: "Jogging Track", value: tmp.jogging_track },
        { label: "Playground", value: tmp.playground },
        { label: "Cafeteria", value: tmp.cafetria },
        { label: "Club House", value: tmp.club_house },
        { label: "Jacuzzi", value: tmp.jacuzzi },
        { label: "Mini Market", value: tmp.mini_market },
        { label: "Nursery", value: tmp.nursery },
        { label: "Salon", value: tmp.salon },
        { label: "Sauna", value: tmp.sauna },
        { label: "Squash Court", value: tmp.squash_court },
        { label: "Tennis Court", value: tmp.tennis_court },
        { label: "Swimming Pool", value: tmp.swimming_pool },
        { label: "Wading Pool", value: tmp.wading_pool },
        { label: "BBQ", value: tmp.bbq },
      ];

      this.setState({
        showEstateDetailsModal: true,
        selectedEstateDetails: {
          ...tmp,
          car_parks: tmp.car_parks || "N/A",
          bedrooms: tmp.bedrooms || "N/A",
          bathrooms: tmp.bathrooms || "N/A",
          built_up: tmp.built_up || "N/A",
          land_area: tmp.land_area || "N/A",
          facilities: facilitiesArray,
          featureArray: featureArray,
          type: tmpType && tmpType.name ? tmpType.name : "N/A",
          property:
            tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
              ? tmpCatGroupPropertyType.name
              : "N/A",
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
          formatted_available_date:
            tmp && tmp.available_date
              ? Moment(tmp.available_date).format("DD MMM YYYY")
              : "N/A",
          updated_on: tmp.updated_at
            ? Moment(tmp.updated_at).format("DD MMM YYYY")
            : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",

          township: payload.township ? payload.township : "",
          sub_area: payload.sub_area ? payload.sub_area : "",
        },
      });
    };
    getSelectedEstateError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadEstateDetails={this.state.loading}
            selectedEstateDetails={this.state.selectedEstateDetails}
            showEstateDetailsModal={this.state.showEstateDetailsModal}
            onChangeEstateDetailsHOC={this.onChangeEstateDetailsHOC}
            getSelectedEstate={this.getSelectedEstate}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
