import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FormHelperText } from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import PaymentForm from "containers/iPay88";
import CustomCard from "components/Card";
import CustomButton from "components/Button";
import CustomDialog from "components/Dialog";

import isEmptyValue from "utils/isEmpty";
import iPayLogo from "assets/ipay88/ipay88.png";
import ModalDialog from "components/Modal/ModalDialog";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CheckoutOrder = ({
  open,
  title,
  cartItems,
  data,
  paymentDetails,
  checkoutDetails,

  onClose,
  cancelPayment,
  getCheckoutPayments,
  onClickUpload,
}) => {
  const { payment_desc, payment_id, amount } = paymentDetails;

  const countDown = 600;
  const userInfo = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const [paymentMethod, setPaymentMethod] = useState("1");
  const [paymentProof, setPaymentProof] = useState({});

  let tempRemarks = "Atlas Store order for ";
  cartItems.map((item, index) => {
    if (item.type_id === 3) {
      const tmpTicketTitle = !isEmptyValue(
        item.selected_market_product_attached_product,
      )
        ? item.selected_market_product_attached_product
        : "";
      tempRemarks += `${item.name}${tmpTicketTitle ? `(${tmpTicketTitle.title})` : ""} x ${item.quantity}${cartItems.length - 1 === index ? "" : ", "}`;
    } else if (item.type_id === 2) {
      let tmpProductTitle = "";
      item.title.map((text) => (tmpProductTitle += text));
      tempRemarks += `${item.name} (${tmpProductTitle}) x ${item.quantity}${cartItems.length - 1 === index ? "" : ", "}`;
    } else {
      tempRemarks += `${item.name}`;
    }
  });

  const validateTicketValue = (ticketPrice, attached_product) =>
    attached_product ? ticketPrice : 0;

  useEffect(() => {
    if (open) {
      let tempQuantity = 0;
      let tempAmount = 0;

      cartItems.map((item) => {
        const {
          data = { ticket_price: 0 },
          price = 0,
          market_product: { type_id = "", attached_product = "" },
        } = item;

        tempQuantity += item.quantity;

        tempAmount +=
          item.quantity *
          (type_id === 3
            ? validateTicketValue(data.ticket_price, attached_product)
            : price);
      });

      let tmpPaymentData = {
        provider: "Ipay88",
        type: "MarketOrder",
        amount: checkoutDetails.total_amount,
        description: tempRemarks,
        user_id: userInfo.id,
        product_id: checkoutDetails.order_id,
      };
      getCheckoutPayments(tmpPaymentData);
    }
  }, [open]);

  const formatRemainingTime = (time) => {
    const minutes =
      Math.floor((time / 60) % 60) < 10
        ? `0${Math.floor((time / 60) % 60)}`
        : Math.floor((time / 60) % 60);
    const seconds =
      Math.floor(time % 60) < 10
        ? `0${Math.floor(time % 60)}`
        : Math.floor(time % 60);

    return `${minutes}:${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <p style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}>
          Time Out
        </p>
      );
    }

    return (
      <p style={{ fontSize: 14, fontWeight: 500 }}>
        {formatRemainingTime(remainingTime)}
      </p>
    );
  };

  return (
    <ModalDialog
      title={"Payment"}
      fullWidth={true}
      fullHeight={true}
      children={
        <>
          <div
            className="position-absolute bg-white"
            style={{ top: 10, right: 10 }}
          >
            <CountdownCircleTimer
              isPlaying
              size={window.innerWidth < 767 ? 60 : 80}
              strokeWidth={window.innerWidth < 767 ? 4 : 6}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              isLinearGradient={true}
              duration={countDown}
              initialRemainingTime={countDown}
              colorsTime={[countDown / 10, countDown / 5, countDown / 3, 0]}
              onComplete={() => cancelPayment()}
              trailColor={[["#dbdbdb"]]}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          <div className="d-flex flex-column align-items-center">
            {paymentMethod === "1" && (
              <div className="payment-container mx-4 mt-1">
                <img
                  src={iPayLogo}
                  className="ipay-logo"
                  style={{ width: "20%", minWidth: 100 }}
                />
                <div className="ipay-frame d-flex flex-wrap justify-content-center">
                  <FormHelperText className="my-4 w-100 text-center">
                    {title ||
                      "Choose a payment method. You will then be redirected to a secure page to complete the payment."}
                  </FormHelperText>
                  <PaymentForm
                    payment_id={2}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={16}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={210}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={523}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={538}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={542}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                  <PaymentForm
                    payment_id={801}
                    productDesc={payment_desc}
                    userInfo={userInfo}
                    refNo={`${payment_id}`}
                    amount={amount.toString()}
                    amountSHA256={amount.toString().replace(".", "")}
                    remark={tempRemarks}
                  />
                </div>
              </div>
            )}
            {paymentMethod === "2" && (
              <div className="at-event__checkout-bank-in m-4">
                <p>Upload proof of payment</p>
                <FormHelperText className="mb-2">
                  Please upload a good quality picture of the payment transfer
                  proof. Ensure that the transaction number, recipient and
                  amount is clearly visible.
                </FormHelperText>
                <FilePond
                  acceptedFileTypes={["image/*", "application/pdf"]}
                  labelIdle={"Click here to upload the proof of payment"}
                  allowMultiple={false}
                  maxFiles={1}
                  fullWidth
                  onremovefile={() => setPaymentProof({})}
                  onupdatefiles={(fileItems) => {
                    if (fileItems.length > 0 && fileItems[0].file) {
                      const reader = new FileReader();
                      try {
                        reader.onload = (e) => {
                          let temp = {
                            file: e.target.result,
                            filename: fileItems[0].file.name,
                          };
                          setPaymentProof(temp);
                        };
                        reader.readAsDataURL(fileItems[0].file);
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }}
                />
                <FormHelperText>
                  Accepted file types: jpg, jpeg, png, pdf.(Required)
                </FormHelperText>
                <CustomButton
                  className={"btn btn-primary mt-2"}
                  disabled={_.isEmpty(paymentProof)}
                  style={{ float: "right", color: "white" }}
                  children={"Submit"}
                  onClick={onClickUpload}
                />
              </div>
            )}
            <CustomButton
              className="btn-new btn-new--danger mt-4"
              onClick={() => onClose()}
              children={"Cancel Payment"}
            />
          </div>
        </>
      }
    />
  );
};

export default CheckoutOrder;
