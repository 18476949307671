import React from "react";
import { FaFolder } from "react-icons/fa";
import _ from "lodash";
import { AiOutlineCopy, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdCached } from "react-icons/md";
import { Tooltip } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import AtDriveDropDownMenu from "./dropdown";
import FileTypeIcon from "./fileTypeIcon";

import { numberWithCommas } from "utils/thousandSeparator";

const searchQueryPropNames = [
  {
    key: "fileid",
    value: "id",
  },
  {
    key: "last_modified",
    value: "last_modified",
  },
  {
    key: "resource_type",
    value: "resource_type",
  },
  {
    key: "size",
    value: "size",
  },
  {
    key: "href",
    value: "href",
  },
  {
    key: "display_name",
    value: "display_name",
  },
  {
    key: "path",
    value: "path",
  },
];

const DriveTableContent = ({
  index,
  type,
  clipboardURL,
  clipboardName,
  is_favourite,
  title,
  last_modified,
  fileSize,
  metadata,
  downloadList = [],
  showFavouriteFunction,
  showCopyUrlFunction,
  showResetCacheFunction,

  onChangeCheckboxValue,
  onClickContent,
  onClickFavourite,
  onClickCopy,
  clearDriveCache,
}) => {
  const renderIcon = (type, onClickContent) => {
    if (type === "directory") {
      return (
        <AtlasIcon
          svgHref={"atlas-folder-2"}
          className="at-drive_table-row_item-icon_folder"
          onClick={onClickContent}
        />
      );
    } else {
      return <FileTypeIcon title={title} onClick={onClickContent} />;
    }
  };

  const getMemorySize = (fileSize) => {
    const prefixes = ["KB", "MB", "GB"];
    let quotient = Math.floor((`${fileSize}`.length - 1) / 3);

    switch (quotient) {
      case 0:
      case 1:
        return `${numberWithCommas(fileSize / 10 ** 3, 2)}KB`;
      case 2:
      case 3:
        return `${numberWithCommas(fileSize / 10 ** (3 * quotient), 2)}${prefixes[quotient - 1]}`;
      default:
        return `${numberWithCommas(fileSize / 10 ** (3 * 3), 2)}GB`;
    }
  };

  return (
    <div className={`at-drive_table-row_cont at-drive_table-row_cont_${index}`}>
      <div className={"at-drive_table_height-control"}>
        <ul
          className={`
           at-drive_table-row drive_grid-controller 
           ${type === "directory" ? "at-grid_drive-directory" : ""}`}
        >
          {/* Column 0: */}
          <li className="at-drive_table-row__item-checkbox">
            {metadata.resource_type === "file" && (
              <CustomCheckbox
                checked={_.findIndex(downloadList, { id: metadata.id }) > -1}
                onChangeCheckboxValue={(e) => onChangeCheckboxValue(metadata)}
              />
            )}
          </li>

          {/* Column 1: */}
          {showFavouriteFunction && (
            <li
              className={`at-drive_table-row__item-favourite`}
              onClick={() => {
                let searchQuery = "";
                let tmpId = -1;
                searchQueryPropNames.map((item, index) => {
                  searchQuery =
                    searchQuery +
                    `${item.key}=${metadata[item.value] || ""}${searchQueryPropNames.length !== index && "&"}`;
                  if (item.value === "id") {
                    tmpId = metadata[item.value];
                  }
                });
                onClickFavourite(searchQuery, tmpId);
              }}
            >
              <Tooltip
                classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                placement={"top"}
                title={"Mark as favourite"}
              >
                {is_favourite ? (
                  <AtlasIcon
                    svgHref={"atlas-heart"}
                    style={{ width: 16, height: 16, fill: "#F43F5E" }}
                  />
                ) : (
                  <AtlasIcon
                    svgHref={"atlas-heart-linear"}
                    style={{ width: 16, height: 16, fill: "#F43F5E" }}
                  />
                )}
              </Tooltip>
            </li>
          )}

          {/* // Column 2: */}
          <li className={`at-drive_table-row__item-title`}>
            <div className="at-drive_table-row_item-icon">
              {renderIcon(type, onClickContent)}
            </div>
            <Tooltip
              classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
              placement={"top-start"}
              title={title}
            >
              <p>
                <span style={{ cursor: "pointer" }} onClick={onClickContent}>
                  {title}
                </span>
              </p>
            </Tooltip>
          </li>

          {/* Column 3 & 4:  */}
          <li className={`at-drive_table-row__item-date`}>
            <Tooltip
              classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
              placement={"top"}
              title={last_modified}
            >
              <p>{last_modified}</p>
            </Tooltip>
          </li>
          <li className={`at-drive_table-row__item-size`}>
            <Tooltip
              classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
              placement={"top-start"}
              title={getMemorySize(fileSize)}
            >
              <p>{getMemorySize(fileSize)}</p>
            </Tooltip>
          </li>
          {(showCopyUrlFunction || showResetCacheFunction) && (
            <li className={`at-drive_table-row__item-actions`}>
              {showCopyUrlFunction && (
                <Tooltip
                  classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                  placement={"top"}
                  title={"Copy"}
                >
                  <button
                    className={`${clipboardName ? clipboardName : "copy_url-clipboard"}`}
                    style={{ marginRight: 10 }}
                    data-clipboard-text={JSON.stringify(clipboardURL)}
                    onClick={onClickCopy}
                  >
                    <AiOutlineCopy style={{ width: "25px", height: "30px" }} />
                  </button>
                </Tooltip>
              )}
              {showResetCacheFunction && (
                <Tooltip
                  classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                  placement={"top"}
                  title={"Reset Cache"}
                >
                  <button
                    style={{ marginRight: 10 }}
                    onClick={() => clearDriveCache(metadata.path)}
                  >
                    <MdCached style={{ width: "25px", height: "30px" }} />
                  </button>
                </Tooltip>
              )}
            </li>
          )}

          {/* Column 5 for responsive */}
          {(showCopyUrlFunction || showResetCacheFunction) && (
            <li
              className={`at-drive_table-row__item-actions_responsive  
                  ${!showCopyUrlFunction ? "at-drive-action-only_favourite" : ""}`}
            >
              <AtDriveDropDownMenu
                title={title}
                metadata={metadata}
                searchQueryPropNames={searchQueryPropNames}
                clipboardURL={clipboardURL}
                clipboardName={clipboardName}
                showCopyUrlFunction={showCopyUrlFunction}
                showResetCacheFunction={showResetCacheFunction}
                clearDriveCache={() => clearDriveCache(metadata.path)}
                onClickCopy={onClickCopy}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DriveTableContent;
