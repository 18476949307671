import React from "react";
import Moment from "moment";

import AtlasCard from "components/Card";
import AtlasTable from "components/NewTable";
import AtlasCloseButton from "components/Button/close";
import JsonPrettifier from "components/JsonPrettifier";
import isEmptyValue from "utils/isEmpty";

const columnData = [
  {
    header: "Created At",
    accessor: "created_at",
    searchFlag: "created_at",
    columnStyle: { width: "15%" },
    renderColumn: (rowData) => (
      <p>{Moment(rowData.created_at).format("DD MMM YYYY")}</p>
    ),
  },
  {
    header: "Description",
    accessor: "description",
    searchFlag: "description",
    columnStyle: { width: "15%" },
  },
  {
    header: "Paid amount / Pricing",
    accessor: "pricing",
    hideFromSearch: true,
    searchFlag: "pricing",
    columnStyle: { width: "15%" },
    renderColumn: (rowData) => (
      <span>
        {`${isEmptyValue(rowData.amount) ? "N/A" : rowData.amount} 
          / 
          ${isEmptyValue(rowData.pricing) ? "N/A" : rowData.pricing}`}
      </span>
    ),
  },
  {
    header: "Gateway",
    accessor: "gateway",
    searchFlag: "gateway",
    columnStyle: { width: "15%" },
  },
  {
    header: "Request IP",
    accessor: "request_ip",
    searchFlag: "request_ip",
    columnStyle: { width: "20%" },
  },
  {
    header: "Response",
    accessor: "response",
    searchFlag: "response",
    renderColumn: (rowData) => (
      <p>
        {!isEmptyValue(rowData.response) &&
          JsonPrettifier(JSON.parse(rowData.response))}
        {isEmptyValue(rowData.response) && "N/A"}
      </p>
    ),
    columnStyle: { width: "50%" },
  },
  {
    header: "Status",
    accessor: "status",
    searchFlag: "status",
    columnStyle: { width: "15%" },
  },
];

const PaymentDetail = ({ paymentDetail, onClose }) => {
  return (
    <AtlasCard
      className={"h-100"}
      cardContent={
        <>
          <div className="d-flex">
            <h2 className="at-form-input__title">Payment Details</h2>
            <AtlasCloseButton
              containerStyle={{ display: "flex", marginLeft: "auto" }}
              onClick={() => onClose()}
            />
          </div>
          <hr />
          <AtlasTable
            className={"mb-100"}
            columns={columnData}
            rowData={paymentDetail || []}
            pagination={true}
          />
        </>
      }
    />
  );
};

export default PaymentDetail;
