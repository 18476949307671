import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class DocumentWrappedComponent extends Component {
    state = {
      documents: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getDocuments = (project_id) =>
      Get(
        `/projects/${project_id}/project_documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) =>
      this.setState({ documents: payload.project_documents });
    getDocumentsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadDocuments={this.state.loading}
            documents={this.state.documents}
            getDocuments={this.getDocuments}
          />
        </>
      );
    };
  }
  return DocumentWrappedComponent;
};

export default HOC;
