import React, { useEffect, useState, useMemo } from "react";
import { Collapse, IconButton } from "@material-ui/core";
import _ from "lodash";
import { connect } from "react-redux";
import { FaExclamationCircle } from "react-icons/fa";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import AlertBox from "components/AlertBox";
import DueDiligenceContent from "./dueDeligenceContent";
import FormInput from "./formInput";
import { companyFormData, personalFormData } from "./assets/form";
import {
  personalForms,
  companyForms,
} from "./assets/form";

import TownShipHOC from "actions/township";
import { requestError } from "utils/requestHandler";
import { occupationOptions } from "./assets/dueDeligence";

const EditParticipant = ({
  loading,
  title,
  banks,
  titleContext,
  subsalesClaim,
  nationalities,
  formTypes,

  township,
  reduxData,
  storedAddresses,
  selectedContact,

  onToggleDialog,
  submitData,
  getSelectedTownShip,
  onChangeOppositeParticipantHOC,
}) => {
  const showDueDiligence = title === "Edit" && ["1", "2"].includes(selectedContact.contact_type_id) && titleContext !== "Referral";

  const [newContact, setNewContact] = useState({
    id: "",
    full_name: "",
    nric: "",
    address: "",
    contact_number: "",
    bank_id: 0,
    bank_account_number: "",
    type_id: "",
    tax_identification_no: "",
    company_registration_no: "",
    sensitive_info_by_opposite_claim: false,
    payable_name: "",
    country: "",
    town: "",
    state: "",
    commission_amount: 0,
    date_of_birth: null,
    postcode: "",
    gender: "",
    nationality: "",
    referral_sources: [],
    contact_type_id: "",
    ...titleContext !== "Referral" 
      ? { is_company: "" }
      : { 
        email: "",
        nationality_id: ""
      }  
    });

  const [newContactInternalAgent, setNewAgentContact] = useState({
    id: "",
    type_id: "",
    full_name: "",
    email: "",
    nric: "",
    address: "",
    referral_id: "",
    referral_type: "",
    contact_number: "",
    nationality_id: "",
    bank_id: 0,
    payable_name: "",
    bank_account_number: "",
    internal_agent: {},
    commission_amount: 0,
    referral_sources: {},
  });
  const [formTypeValue, setFormTypeValue] = useState(
    titleContext === "Referral"
      ? "1"
      : titleContext === "Law Firm"
        ? "2"
        : false,
  );

  const [expandDueDiligence, onToggleexpandDueDiligence] = useState(true);
  const [doc, setDoc] = useState({
    identity_copy: {},
    supporting_document: {},
    ssm_copy: {},
  });

  const tempFormData = useMemo(() => {
    return !formTypeValue || formTypeValue === "1"
      ? personalForms
      : companyForms;
  }, [formTypeValue]);

  const initializeComponent = () => {
    if (selectedContact) {
      let tempField = _.cloneDeep(newContact);
      if(titleContext === "Law Firm") {
        tempField = companyFormData
      } else if (titleContext !== "Referral") {
        tempField = selectedContact.is_company
          ? companyFormData
          : personalFormData;
      }
      let tempData = {};
      
      Object.keys(tempField).map((key) => {
        if (key === "due_diligence_information") {
          tempData["due_diligence_information"] = {};
          Object.keys(tempField["due_diligence_information"]).map((item) => {
            if (item === "declared_bankrupt") {
              tempData["due_diligence_information"][item] = selectedContact[item];
            } else if (item === "due_diligence_type_id") {
              tempData["due_diligence_information"][item] = selectedContact[item] || 1;
            } else {
              tempData["due_diligence_information"][item] = selectedContact[item] || "";
            }
          });
        } else {
          tempData[key] = selectedContact[key] || (["commission_amount"].includes(key) ? 0 : "");
        }
      });

      if(titleContext === "Law Firm") {
        tempData.is_company = true
      } else if(titleContext !== "Referral") {
        tempData.contact_type_id = selectedContact.contact_type_id
        tempData.is_company = selectedContact.is_company
      } else {
        tempData.contact_type_id = selectedContact.contact_type_id
      }

      setNewContact(tempData);
      setDoc({ identity_copy: {}, supporting_document: {}, ssm_copy: {} });

      let tmpFormTypeValue = ""
      if(titleContext === "Referral") {
        tmpFormTypeValue = `${selectedContact.contact_type_id}`
      } else if(titleContext === "Law Firm") {
        tmpFormTypeValue = true
      } else {
        tmpFormTypeValue = selectedContact.is_company
      }
      setFormTypeValue(tmpFormTypeValue);

      if (titleContext === "Referral") {
        let tempData = {};
        Object.keys(newContactInternalAgent).map((key) => {
          tempData[key] = selectedContact[key] || "";
        });
        setNewAgentContact(tempData);
      }
    }
  } 
  
  useEffect(() => {
    if (titleContext === "Law Firm") {
      onChangeNewContact("is_company", true);
    } else if (titleContext !== "Referral") {
      onChangeNewContact("is_company", false);
    } else {
      onChangeNewContact("contact_type_id", "1");
    }

    initializeComponent()
  }, []);
  
  useEffect(() => {
    initializeComponent()
  }, [selectedContact]);

  useEffect(() => {
    if (titleContext === "Referral") {
      onChangeNewContact("contact_type_id", formTypeValue);
    } else if (titleContext === "Law Firm") {
      onChangeNewContact("is_company", true);
    } else {
      onChangeNewContact("is_company", formTypeValue);
    }
  }, [formTypeValue]);

  const onChangeNewContact = (context, value) => {
    if (titleContext === "Referral" && formTypeValue === "3") {
      setNewAgentContact((prev) => ({
        ...prev,
        [context]: value,
      }));
    } else {
      setNewContact((prev) => ({
        ...prev,
        [context]: value,
      }));
    }
  };

  const renderRadioForm = () => {
    if (formTypes && formTypes.length > 0) {
      return (
        <div className="at-form_field-col-12">
          <AtlasRadioGroup
            horizontal={true}
            disabled={
              title === "Edit" &&
              (newContact.form_contact_id || titleContext === "Referral" || selectedContact.contactable_id || selectedContact.contactable_type)
            }
            checkedValue={formTypeValue}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => setFormTypeValue(val)}
            options={formTypes}/>
        </div>
      );
    }
  };

  const onToggleSubmit = () => {
    let temp = _.cloneDeep(newContact);

    delete temp?.due_diligence_information;
    let tmpDueDiligence = _.cloneDeep(newContact.due_diligence_information);
    delete tmpDueDiligence?.copies_info;

    if(titleContext === "Referral") {
      delete temp.nationality
    }

    submitData({
      ...temp,
      referral_sources_attributes: newContact.referral_sources,
      ...(showDueDiligence && {
        ...tmpDueDiligence,
        ...(selectedContact.is_company
          ? doc.ssm_copy.file &&
            doc.ssm_copy.file_name && {
              ssm_copy: doc.ssm_copy.file,
              ssm_copy_file_name: doc.ssm_copy.file_name,
            }
          : doc.identity_copy.file &&
            doc.identity_copy.file_name && {
              identity_copy: doc.identity_copy.file,
              identity_copy_file_name: doc.identity_copy.file_name,
            }),
        ...(doc.supporting_document.file &&
          doc.supporting_document.file_name && {
            supporting_document: doc.supporting_document.file,
            supporting_document_file_name: doc.supporting_document.file_name,
          }),
      }),
    });
  };

  const renderPersonalOrCompanyForm = () => {
    return (
      <>
        {formTypeValue !== "3" &&
          tempFormData.map((formMeta) => (
            <FormInput
              key={formMeta.value}
              formMeta={formMeta}
              formData={{
                ...newContact,
                title,
                titleContext,
                disabledEdit: false,

                data: reduxData,
                storedAddresses,
                claim_status: "",
                banks: banks,
                township: township,
                onChangeValue: onChangeNewContact,
                getSelectedTownShip: getSelectedTownShip,
              }}/>
          ))}
        {showDueDiligence && (
          <div
            className="bg-white p-3 w-100"
            style={{ borderRadius: 8, border: "1px solid #D5DDE3" }}>
            <div className="d-flex align-items-center w-100">
              <button
                className={`btn w-100 p-0 text-left ${showDueDiligence ? "mb-3" : ""}`}
                onClick={() => onToggleexpandDueDiligence(!expandDueDiligence)}>
                <h5 style={{ fontSize: 18, fontWeight: 500 }}>
                  {selectedContact.due_diligence_type_id === 0 && (
                    <FaExclamationCircle
                      style={{
                        width: 20,
                        height: 20,
                        color: "red",
                        marginRight: 10,
                      }}
                    />
                  )}
                  Anti-Money Laundering Act Compliance
                  <IconButton style={{ color: "#F18E06", padding: 5, marginLeft: 10 }}>
                    {expandDueDiligence ? (
                      <AtlasIcon
                        style={{ width: 20, height: 20, color: "#3C82F6" }}
                        svgHref={"atlas-vuesax-arrow-circle-up"}/>
                    ) : (
                      <AtlasIcon
                        style={{ width: 20, height: 20, color: "#3C82F6" }}
                        svgHref={"atlas-vuesax-arrow-circle-down"}/>
                    )}
                  </IconButton>
                </h5>
              </button>
            </div>
            <Collapse in={expandDueDiligence} timeout="auto" unmountOnExit>
              <DueDiligenceContent
                doc={doc}
                isView={false}
                disabledEdit={false}
                newContact={newContact}
                titleContext={titleContext}
                selectedContact={selectedContact}
                subsalesClaim={subsalesClaim}
                setDoc={setDoc}
                onChangeNewContact={onChangeNewContact}
                onChangeOppositeParticipantHOC={onChangeOppositeParticipantHOC}/>
            </Collapse>
          </div>
        )}
      </>
    );
  };

  const renderFooter = () => {
    let checkNationality = true 
    if(titleContext === "Referral") {
      checkNationality = _.find(nationalities, {
        value: newContact.nationality_id,
      });
    } else {
      checkNationality = _.find(nationalities, {
        value: newContact.nationality,
      });
    }
    const checkOccupation = newContact?.due_diligence_information?.occupation !== "" 
      ? _.find(occupationOptions, {
        value: newContact?.due_diligence_information?.occupation ?? "",
      })
      : true;
    const onSubmit = () => {
      if (
        title === "Edit" &&
        !newContact?.is_company &&
        !["Referral", "Law Firm"].includes(titleContext)
      ) {
        if (!checkNationality) {
          requestError("Please select nationality from the list");
        } else if (
          !checkOccupation &&
          newContact?.due_diligence_information?.due_diligence_type_id === 1
        ) {
          requestError("Please select occupation from the list");
        } else {
          onToggleSubmit();
        }
      } else {
        onToggleSubmit();
      }
    };

    if (formTypeValue !== "3") {
      return (
        formTypeValue !== "3" && (
          <section className="d-flex grid_gap-1">
            <button
              className={`btn-new ${title === "Add" ? "btn-new--primary" : "btn-new--success"}`}
              disabled={checkRequiredValue}
              onClick={() => onSubmit()}>
              {title === "Add" ? "Add Now" : "Update"}
            </button>
            <button
              className="btn-new btn-new--outline-secondary"
              onClick={() => onToggleDialog()}>
              {title === "Add" ? "Cancel" : "Close"}
            </button>
          </section>
        )
      );
    }
    return "";
  };

  const checkRequiredValue = useMemo( () => {
    return (!formTypeValue || formTypeValue === "1")
      ? personalForms.some( form => {
        if(form.type !== "radio" && ((typeof form.required === "function" && form.required({titleContext}) || (typeof form.required !== "function" && form.required)))) { 
          if(form.checkBoxValue === 'sensitive_info_by_opposite_claim') {
            return !newContact.sensitive_info_by_opposite_claim && newContact[form.value] === "" 
          } else {
            return newContact[form.value] === ""
          }
        }
        return false
      })
      : companyForms.some( form => {
        if(form.type !== "radio" && ((typeof form.required === "function" && form.required({titleContext})) || (typeof form.required !== "function" && form.required))) { 
          if(form.checkBoxValue === 'sensitive_info_by_opposite_claim') {
            return !newContact.sensitive_info_by_opposite_claim && newContact[form.value] === ""
          } else {
            return newContact[form.value] === ""
          }
        }
        return false
      });
  
  }, [newContact])

  return (
    <>
      <ModalDialog
        contentClassName={"overflow-auto"}
        cardClassName={"overflow-visible"}
        title={`${title} ${titleContext}`}
        onClose={() => onToggleDialog(false)}
        children={
          <div className="d-flex flex-column grid_gap-2">
            {
              newContact.contact_type_id !== "3" && (
                <p className="fs-2">
                  Submission of { titleContext?.toLowerCase() } information, along with AMLA details, is required to comply with Bank Negara policy. 
                  Incomplete submissions may lead to transaction delays or rejection.
                </p>
              )
            }
            {
              titleContext !== "Law Firm" && (
                <AlertBox
                  title={""}
                  disabledClose={true}
                  containerClassName="w-100"
                  mode={"info"}
                  description={
                    <>
                      <p>
                        The contact information you enter here will be held privately and not disclosed to any other parties.
                      </p>
                      <p>
                        This will protect your interest and your client.
                      </p>
                    </>
                  }/>
              )
            }
            <form
              className="at-form__content"
              onSubmit={(e) => e.preventDefault()}>
              <div className="at-form__label mb-3">
                <h2 className="at-form__label-title">Participant Details</h2>
              </div>
              <div className="at-form_fields_cont">
                {renderRadioForm()}
                {renderPersonalOrCompanyForm()}
              </div>
            </form>
          </div>
        }
        footer={renderFooter()}
      />
      {loading && <LoadingModal/>}
    </>
  );
};

const mapStateToProps = (state) => ({ reduxData: state });
export default connect(mapStateToProps)(TownShipHOC(EditParticipant));
