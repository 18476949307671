import React from "react";
import { BsTelegram } from "react-icons/bs";

import AtlasIcon from "components/Icon/atlasIcon";

export default function AgentQR({ can_update, selectedUser, onChangeQRHOC }) {
  const { identity_qr_code_image_url, iqi_profile_page } = selectedUser;

  return (
    <div className="d-flex flex-column align-items-center">
      <img
        style={{ width: 250, height: 250 }}
        src={identity_qr_code_image_url}
      />
      <div className="d-flex flex-row align-items-center mt-3 g-2">
        <button
          className="btn-new at-profile__public-profile-action"
          onClick={() => window.open(iqi_profile_page, "_blank")}
        >
          <AtlasIcon
            svgHref={"atlas-link-2-linear"}
            style={{ backgroundColor: "#3B82F6", borderRadius: "50%" }}
          />
          Open Agent Profile
        </button>
        <button
          className="btn-new at-profile__public-profile-action"
          onClick={() => {
            window.open(
              `https://api.whatsapp.com/send?text=Hi.%20Click%20this%20to%20see%20my%20global%20real%20estate%20listings%20and%20my%20profile%0A${iqi_profile_page}.`,
            );
          }}
        >
          <AtlasIcon
            svgHref={"atlas-whatsapp"}
            style={{ backgroundColor: "#12B76A", borderRadius: "50%" }}
          />
          Share To Whatsapp
        </button>
        <button
          className="btn-new at-profile__public-profile-action"
          onClick={() => {
            window.open(
              `https://telegram.me/share/url?url=Hi.%20Click%20this%20to%20see%20my%20global%20real%20estate%20listings%20and%20my%20profile%0A${iqi_profile_page}.`,
            );
          }}
        >
          <BsTelegram
            style={{ backgroundColor: "#25A3E0", borderRadius: "50%" }}
          />
          Share To Telegram
        </button>
      </div>
      <button
        disabled={!can_update}
        className="btn-new btn-new--secondary w-100 mt-3"
        onClick={() => onChangeQRHOC(true, "showRegenConfirmation", "profile")}
      >
        Re-generate QR Code
      </button>
    </div>
  );
}
