import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { storeLastView } from "actions/lastView";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { numberWithCommas } from "utils/thousandSeparator";
import { MemberSearchParams } from "./assets";

const generateYearOpt = (startYear) => {
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = currentYear; i >= startYear; i--) {
    yearOptions.push({ value: i, label: i });
  }
  return yearOptions;
}

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      searchParams: [
        {
          label : "Year",
          value: "year",
          type: "select",
          param: new Date().getFullYear(),
          col: 4,
          params: "",
          options: generateYearOpt(2023)
        }
      ],
      showSaleEligibility: false,
      showTeamEligibility: false,
      showMemberSaleEligibility: false,
      showPersonCampaignTerm: false,
      showTeamCampaignTerm: false,
      selectedTab: "Personal",
      eligibilityStatus: "",
      selectPromotion: "",
      selectedPromotion: [],
      personalSummary: [],
      teamSummary: [],
      totalMembersEligibility: [],
      membersEligibility: [],
      memberSearchParams: MemberSearchParams,
    };

    load = (param) => this.setState({ loading: param });
    onChangeCampaignTrackerHOC = (val, context) =>
      this.setState({ [context]: val });
    onChangeEligibilityStatus = (val) => {
      let temp = _.filter(this.state.totalMembersEligibility, (item) => {
        return item.eligible === val;
      });
      this.setState({
        membersEligibility: temp,
        eligibilityStatus: val,
      });
    };

    getYearPersonalSummary = (from_dashboard,year) => {
      Get(
        `/campaigns/personal_summary?from_dashboard=${from_dashboard}&year=${year}`,
        this.getPersonalSummarySuccess,
        this.getPersonalSummaryError,
        this.load,
      );
    }

    getPersonalSummary = (from_dashboard) =>
      Get(
        `/campaigns/personal_summary?from_dashboard=${from_dashboard}`,
        this.getPersonalSummarySuccess,
        this.getPersonalSummaryError,
        this.load,
      );
    getPersonalSummarySuccess = (payload) => {
      payload.map((item) => {
        item.currentProgress = numberWithCommas(
          item.accumulatedValue.toFixed(2),
        );
        item.campaignTarget = numberWithCommas(item.targetValue.toFixed(2));
        item.progressRatio = item.accumulatedValue / item.targetValue || 0.0;
        item.eligible = item.isEligible ? "YES" : "NO";
      });
      this.setState({ personalSummary: payload });
    };
    getPersonalSummaryError = (error) => requestError(error);


    getYearTeamSummary = (from_dashboard,year) => {
      Get(
        `/campaigns/team_summary?from_dashboard=${from_dashboard}&year=${year}`,
        this.getTeamSummarySuccess,
        this.getTeamSummaryError,
        this.load,
      );
    }

    getTeamSummary = () =>
      Get(
        `/campaigns/team_summary`,
        this.getTeamSummarySuccess,
        this.getTeamSummaryError,
        this.load,
      );
    getTeamSummarySuccess = (payload) =>
      this.setState({ teamSummary: payload });
    getTeamSummaryError = (error) => requestError(error);

    getMemberSaleEligibility = (agentId, promotionId) =>
      Get(
        `/campaigns/sales_eligibility?agent_id=${agentId}&promotion_id=${promotionId}`,
        this.getMemberSaleEligibilitySuccess,
        this.getMemberSaleEligibilityError,
        this.load,
      );
    getMemberSaleEligibilitySuccess = (payload) => {
      let temp = _.cloneDeep(payload.data);
      temp.map((item) => {
        item.submissionDate = Moment(item.submissionDate).format("DD MMM YYYY");
        item.saleAmount = numberWithCommas(item.spaPrice.toFixed(2));
        item.netPricePort = numberWithCommas(item.netPricePortion.toFixed(2));
        item.amountUnallocated = numberWithCommas(
          item.amountLeft.toFixed(2) || 0.0,
        );
        item.amountAllocated = numberWithCommas(
          item.allocatedAmount.toFixed(2) || 0.0,
        );
        item.agentPercent =
          item.agentPercentage !== null ? item.agentPercentage.toFixed(1) : 0.0;
        item.agencyComm = numberWithCommas(
          item.agencyCommission !== null
            ? item.agencyCommission.toFixed(2)
            : 0.0,
        );
        item.agencyCommPort = numberWithCommas(
          item.agencyCommissionPortion !== null
            ? item.agencyCommissionPortion.toFixed(2)
            : 0.0,
        );
      });
      this.setState({
        selectedPromotion: temp,
        showMemberSaleEligibility: true,
      });
    };
    getMemberSaleEligibilityError = (error) => requestError(error);

    getSelectedPromotion = (promotionId, from_dashboard) =>
      Get(
        `/campaigns/sales_eligibility?promotion_id=${promotionId}&from_dashboard=${from_dashboard}`,
        this.getSelectedPromotionSuccess,
        this.getSelectedPromotionError,
        this.load,
      );
    getSelectedPromotionSuccess = (payload) => {
      let temp = _.cloneDeep(payload.data);
      temp.map((item) => {
        item.submissionDate = Moment(item.submissionDate).format("DD MMM YYYY");
        item.saleAmount = numberWithCommas(item.spaPrice.toFixed(2));
        item.percentage = numberWithCommas(item.percentage);
        item.netPricePort = numberWithCommas(item.netPricePortion.toFixed(2));
        item.amountUnallocated = numberWithCommas(item.amountLeft.toFixed(2));
        item.amountAllocated = numberWithCommas(
          item.allocatedAmount.toFixed(2),
        );
        item.agentPercent =
          item.agentPercentage !== null ? item.agentPercentage.toFixed(1) : 0.0;
        item.agencyComm = numberWithCommas(
          item.agencyCommission !== null
            ? item.agencyCommission.toFixed(2)
            : 0.0,
        );
        item.agencyCommPort = numberWithCommas(
          item.agencyCommissionPortion !== null
            ? item.agencyCommissionPortion.toFixed(2)
            : 0.0,
        );
      });
      this.setState({
        selectedPromotion: temp,
        showSaleEligibility: true,
      });
    };
    getSelectedPromotionError = (error) => requestError(error);

    getMemberEligibility = (promotionId) =>
      Get(
        `/campaigns/team_members?promotion_id=${promotionId}`,
        this.getMemberEligibilitySuccess,
        this.getMemberEligibilityError,
        this.load,
      );
    getMemberEligibilitySuccess = (payload) => {
      let temp = _.cloneDeep(payload.data);
      temp.map((item) => {
        item.currentProgress = numberWithCommas(
          item.accumulatedValue.toFixed(2),
        );
        item.campaignTarget = numberWithCommas(item.targetValue.toFixed(2));
        item.progressRatio = item.accumulatedValue / item.targetValue || 0.0;
        item.eligible = item.isEligible ? "YES" : "NO";
      });
      this.setState({
        totalMembersEligibility: temp,
        showTeamEligibility: true,
        membersEligibility: temp,
      });
    };
    getMemberEligibilityError = (error) => requestError(error);

    resetParamsYear = () => {
      let searchParams = this.state.searchParams;
      const indexYear = _.findIndex(searchParams,{ value: "year" });
      searchParams[indexYear].param = new Date().getFullYear();
      this.setState({ searchParams });
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getYearPersonalSummary={this.getYearPersonalSummary}
          onLoadCampaign={this.state.loading}
          getPersonalSummary={this.getPersonalSummary}
          getTeamSummary={this.getTeamSummary}
          getYearTeamSummary={this.getYearTeamSummary}
          getMemberEligibility={this.getMemberEligibility}
          onChangeCampaignTrackerHOC={this.onChangeCampaignTrackerHOC}
          onChangeEligibilityStatus={this.onChangeEligibilityStatus}
          getSelectedPromotion={this.getSelectedPromotion}
          getMemberSaleEligibility={this.getMemberSaleEligibility}
          resetParamsYear={this.resetParamsYear}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
