import React from "react";
import { Dialog } from "@material-ui/core";

import AtlasCloseButton from "components/Button/close";
import CustomCard from "components/Card";

const Preview = ({ open, onClickClose, previewFile }) => {
  return (
    <Dialog fullScreen={true} open={open}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <AtlasCloseButton
              containerStyle={{
                position: "absolute",
                marginTop: -10,
                right: 20,
              }}
              onClick={onClickClose}
            />
            <iframe
              src={previewFile}
              style={{
                width: "100%",
                height: "90vh",
                marginTop: 30,
              }}
            ></iframe>
          </>
        }
      />
    </Dialog>
  );
};

export default Preview;
