import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Delete, Get, GetFile, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const partyDictionary = [
  {
    id: 1,
    subsalesTitle: "Purchaser",
    subrentTitle: "Tenant",
  },
  {
    id: 2,
    subsalesTitle: "Purchaser's Representative",
    subrentTitle: "Tenant's Representative",
  },
  {
    id: 3,
    subsalesTitle: "Vendor",
    subrentTitle: "Landlord",
  },
  {
    id: 4,
    subsalesTitle: "Vendor's Representative",
    subrentTitle: "Landlord's Representative",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showAssignContactModal: false,
      showAssignInternalModal: false,
      showCreateContactModal: false,
      showRefundFormModal: false,
      showEditPartyModal: false,
      showDeletePartyModal: false,
      showEsigningTipsModal: false,

      signingStatus: null,
      partyData: [],
      eProcessPartyData: [],
      eProcessContentId: 1,

      refundStatus: null,
      refundStatusId: null,
      signingStatusId: "0",

      selectedContact: null,
      selectedSection: "Property",
      selectedSigningTab: "e-Signing",
    };

    load = (param) => this.setState({ loading: param });

    onChangePartyHOC = (val, context) => this.setState({ [context]: val });

    getParty = (id) =>
      Get(
        `/admin/forms/form_contacts?type_id=1&form_id=${id}`,
        this.getPartySuccess,
        this.getPartyError,
        this.load,
      );
    getPartySuccess = (payload) => {
      let tempBuyerData = [];
      let tempSellerData = [];
      let tempBuyerAgentData = [];
      let tempSellerAgentData = [];
      let tempOriginalData = [];

      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let temp = _.find(partyDictionary, { id: item.party_id });

          let tempData = {
            ...item,
            title:
              this.props.selectedConfirmationForm.type_id === 1
                ? temp.subsalesTitle
                : temp.subrentTitle,
            form_contact_id: item.id,
            participants_name:
              item.signee_type_id === 1
                ? [item.company_name, `(${item.company_registration_no})`, item.full_name]
                : item.full_name,
            date_of_birth: Moment(item.date_of_birth, "YYYY-MM-DD").isValid()
              ? Moment(item.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
              : item.date_of_birth
          };
          tempOriginalData.push(tempData);
          switch (item.party_id) {
            case 1:
              tempBuyerData.push(tempData);
              break;
            case 2:
              tempBuyerAgentData.push(tempData);
              break;

            case 3:
              tempSellerData.push(tempData);
              break;

            default:
              tempSellerAgentData.push(tempData);
              break;
          }
        });

      let tempParty = [
        {
          id: 1,
          data: tempBuyerData,
        },
        {
          id: 2,
          data: tempBuyerAgentData,
        },
        {
          id: 3,
          data: tempSellerData,
        },
        {
          id: 4,
          data: tempSellerAgentData,
        },
      ];

      this.setState({
        partyData: tempParty,
        eProcessPartyData: tempOriginalData,
      });
      this.props.onChangeConfirmationFormHOC(
        "purchaserNTenant",
        tempBuyerData.length > 0,
      );
    };
    getPartyError = (error) => requestError(error);

    createParty = (dataToSubmit, id) =>
      Post(
        `/admin/forms/form_contacts?type_id=1&form_id=${id}`,
        dataToSubmit,
        this.createPartySuccess,
        this.createPartyError,
        this.load,
      );
    createPartySuccess = (payload) => {
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id);
      this.props.getCurrentFormContactList(payload.form_id);
      requestSuccess("New contact created successfully.");
      this.setState({
        showCreateContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: [1, 3].includes(payload.party_id),
      });
    };
    createPartyError = (error) => requestError(error);

    getSigningStatus = (id) =>
      Get(
        `/admin/e_processes/check_status?form_type=1&form_id=${id}`,
        this.getSigningStatusSuccess,
        this.getSigningStatusError,
        this.load,
      );
    getSigningStatusSuccess = (payload) =>
      this.setState({
        refundStatus: payload.refund_status,
        refundStatusId:
          payload.refund_status_id !== null
            ? `${payload.refund_status_id}`
            : payload.refund_status_id,
        signingStatusId: `${payload.status_id}`,
        signingStatus: payload.status,
        eProcessContentId: payload.status_id === 0 ? 1 : 2,
      });
    getSigningStatusError = (error) => requestError(error);

    downloadCompletedForm = ({ formId, formType, filename }) =>
      GetFile(
        `/admin/e_processes/download?form_id=${formId}&form_type=${formType}`,
        filename,
        () => {},
        this.downloadCompletedFormError,
        this.load,
      );
    downloadCompletedFormError = (error) => requestError(error);

    deletePartyContact = (formId, contactId) =>
      Delete(
        `/admin/forms/form_contacts/${contactId}?type_id=1&form_id=${formId}`,
        () => this.deletePartyContactSuccess(formId),
        this.deletePartyContactError,
        this.load,
      );
    deletePartyContactSuccess = (id) => {
      this.getParty(id);
      this.setState({ showDeletePartyModal: false });
      this.props.getConfirmationForm(id);
      requestSuccess("Record deleted successfully.");
    };
    deletePartyContactError = (error) => requestError(error);

    updatePartyContact = (dataToSubmit) =>
      Put(
        `/admin/forms/form_contacts/${dataToSubmit.id}?type_id=1`,
        dataToSubmit,
        this.updatePartyContactSuccess,
        this.updatePartyContactError,
        this.load,
      );
    updatePartyContactSuccess = (payload) => {
      this.setState({
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
        },
      });
      requestSuccess("Contact's information is updated successfully.");
      this.getParty(payload.form_id);
    };
    updatePartyContactError = (error) => requestError(error);

    assignParty = (dataToSubmit, id, title) =>
      Post(
        `/admin/forms/form_contacts/assign_agent?type_id=1&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignPartySuccess(payload, title),
        this.assignPartyError,
        this.load,
      );
    assignPartySuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.setState({
        showAssignInternalModal: false,
        showAssignMyselfModal: false,
        showAssignSuperiorModal: false,
      });
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id)
    };
    assignPartyError = (error) => requestError(error);

    assignContact = (dataToSubmit, id, title) =>
      Post(
        `/admin/forms/form_contacts/assign_contact?type_id=1&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignContactSuccess(payload, title),
        this.assignContactError,
        this.load,
      );
    assignContactSuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.props.getConfirmationForm(payload.form_id);
      this.setState({ 
        showAssignContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: [1, 3].includes(payload.party_id) 
      });
      this.getParty(payload.form_id);
    };
    assignContactError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedContact={this.state.selectedContact}
            selectedSection={this.state.selectedSection}
            selectedSigningTab={this.state.selectedSigningTab}
            refundStatus={this.state.refundStatus}
            signingStatus={this.state.signingStatus}
            signingStatusId={this.state.signingStatusId}
            refundStatusId={this.state.refundStatusId}
            onLoadParty={this.state.loading}
            partyData={this.state.partyData}
            eProcessPartyData={this.state.eProcessPartyData}
            eProcessContentId={this.state.eProcessContentId}
            showAssignContactModal={this.state.showAssignContactModal}
            showAssignInternalModal={this.state.showAssignInternalModal}
            showCreateContactModal={this.state.showCreateContactModal}
            showDeletePartyModal={this.state.showDeletePartyModal}
            showEditPartyModal={this.state.showEditPartyModal}
            showSigningHistory={this.state.showSigningHistory}
            showRefundFormModal={this.state.showRefundFormModal}
            showEsigningTipsModal={this.state.showEsigningTipsModal}
            getParty={this.getParty}
            createParty={this.createParty}
            updatePartyContact={this.updatePartyContact}
            assignContact={this.assignContact}
            assignParty={this.assignParty}
            downloadCompletedForm={this.downloadCompletedForm}
            getSigningStatus={this.getSigningStatus}
            deletePartyContact={this.deletePartyContact}
            onChangePartyHOC={this.onChangePartyHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
