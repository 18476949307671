import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import localForage from "localforage";

const persistConfig = {
  key: "atlas-web",
  storage: localForage,
  blacklist: [
    "ajaxStatusReducer",
    "globalNetworkReducer",
    "birthdayReducer",
    "recruitmentReducer",
    "eventReducer",
    "contactReducer",
    "forgetPasswordReducer",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState) {
  // return createStore(
  //   rootReducer,
  //   initialState,
  //   applyMiddleware( thunk, reduxImmutableStateInvariant())
  // );
  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(thunk, reduxImmutableStateInvariant())),
  );
}
