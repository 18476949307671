import { STORE_SELECTED_SUBSALES, STORE_WATERMARK } from "actions/type";

let initialState = {
  selectedSubsales: {},
  storedWaterMark: {},
};

export default function subsalesReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_SELECTED_SUBSALES:
      return {
        ...state,
        selectedSubsales: action.payload,
      };
    case STORE_WATERMARK:
      return {
        ...state,
        storedWaterMark: action.payload,
      };

    default:
      return state;
  }
}
