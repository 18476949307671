import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import _ from "lodash";
import ReactLottie from "react-lottie";
import ReactHTMLParser from "react-html-parser";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";

import AtlasCard from "components/Card";
import CustomButton from "components/Button";

import LoadingIcon from "assets/lottie/loading.json";

import "./style.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingIcon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ModalUploadedDocs = ({ uploadedDocs, closeUploadedDocsList }) => {
  return (
    <Dialog open={true} classes={{ paper: "atlas-dialog-custom" }}>
      <AtlasCard
        className={"atlas-card-custom"}
        cardContent={
          <>
            <div className="d-flex mb-20">
              <h4 className="at-card__title">{"Uploaded Docs"}</h4>
            </div>
            <Table>
              {uploadedDocs.map((item, index) => {
                if (item.loading && !item.done) {
                  return (
                    <tr
                      key={index}
                      className="d-flex atlas-upload_status_modal-list"
                    >
                      <div style={{ width: "90%" }}>
                        <p>{item.snapshot_file_name}</p>
                        <div className="atlas-upload_status_modal-list-status">
                          {item.success
                            ? ""
                            : ReactHTMLParser(item.errorMessage)}
                        </div>
                      </div>
                      <div style={{ width: 30, height: 30 }}>
                        <ReactLottie
                          width={30}
                          height={30}
                          style={{ overflow: "visible" }}
                          options={defaultOptions}
                        />
                      </div>
                    </tr>
                  );
                }
              })}
              {uploadedDocs.map((item, index) => {
                if (!item.loading && item.done) {
                  if (!item.success) {
                    return (
                      <tr
                        key={index}
                        className="d-flex atlas-upload_status_modal-list"
                      >
                        <div style={{ width: "90%" }}>
                          <p>{item.snapshot_file_name}</p>
                          <div className="atlas-upload_status_modal-list-status">
                            {item.success
                              ? ""
                              : ReactHTMLParser(item.errorMessage)}
                          </div>
                        </div>
                        <div style={{ width: 30, height: 30 }}>
                          <AiFillCloseCircle
                            style={{ fontSize: "10px" }}
                            color="red"
                          />
                        </div>
                      </tr>
                    );
                  }
                }
              })}
              {uploadedDocs.map((item, index) => {
                if (!item.loading && item.done) {
                  if (item.success) {
                    return (
                      <tr
                        key={index}
                        className="d-flex atlas-upload_status_modal-list"
                      >
                        <div style={{ width: "90%" }}>
                          <p>{item.snapshot_file_name}</p>
                          <div className="atlas-upload_status_modal-list-status">
                            {item.success
                              ? ""
                              : ReactHTMLParser(item.errorMessage)}
                          </div>
                        </div>
                        <div style={{ width: 30, height: 30 }}>
                          <AiFillCheckCircle
                            style={{ fontSize: "10px" }}
                            color="green"
                          />
                        </div>
                      </tr>
                    );
                  }
                }
              })}
            </Table>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {_.findIndex(uploadedDocs, { loading: true }) < 0 && (
                <CustomButton
                  className={"btn-new btn-new--secondary mt-10"}
                  children={"Close"}
                  disabled={false}
                  onClick={() => closeUploadedDocsList()}
                />
              )}
            </div>
          </>
        }
      />
    </Dialog>
  );
};

export default ModalUploadedDocs;
