import React, { Component } from "react";
import { IoMdAdd } from "react-icons/io";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import EditClientForm from "./Edit";
import CreateClientForm from "./create";
import AssignContactForm from "./Assign";

import ClientHOC from "./actions";
import permissionsChecker from "utils/permissionsChecker";
import { requestError } from "utils/requestHandler";

import "./index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowItem) => (
      <p className="fw-500">
        <AtlasIcon
          svgHref={"atlas-frame"}
          style={{ width: 20, height: 20, fill: "#EE6D4D", marginRight: 8 }}
        />
        {rowItem.full_name}
      </p>
    ),
  },
  { header: "Email Address", accessor: "email" },
  { header: "Mobile Number", accessor: "mobile_contact_number" },
];

class Clients extends Component {
  handleOnClose = (event) => {
    if (this.dropDownRef.contains && !this.dropDownRef.contains(event.target)) {
      this.props.onChangeClientsHOC(false, "showCreateDropDown");
    }
  };

  componentDidMount = () => {
    this.props.getClients(this.props.selectedPresentation.id, 1);
    document.addEventListener("click", this.handleOnClose, true);
  };

  render = () => {
    const {
      data,
      selectedPresentation,
      showClientsCreateModal,
      showClientsEditModal,
      showClientsDeleteModal,
      showAssignContactModal,
      showCreateDropDown,
      selectedClients,

      onLoadPresentation,
      onLoadClients,
      clientPages,
      clients,
    } = this.props;

    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Presentation Centre",
      data,
    );

    const { role } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    return (
      <>
        <div className={"d-flex align-items-center flex-wrap grid_gap-1"}>
          <ModuleFormHeader
            title={"Client Management"}
            moduleIcon={"atlas-frame"}
          />
          {["Admin", "Super Admin"].indexOf(role) === -1 && can_create && (
            <div style={{ position: "relative" }}>
              <CustomButton
                className={"btn-new btn-new--primary ml-3"}
                onClick={() =>
                  this.props.onChangeClientsHOC(
                    !showCreateDropDown,
                    "showCreateDropDown",
                  )
                }
                children={
                  <>
                    <IoMdAdd
                      style={{
                        height: 18,
                        width: 18,
                        color: "#FFFF",
                      }}
                    />
                    Add Client
                  </>
                }
                tooltip={false}
              />
              <div
                ref={(ref) => (this.dropDownRef = ref)}
                className={"at-presentation__dropbear--modal-root"}
                style={{
                  top: 40,
                  display: showCreateDropDown ? "block" : "none",
                }}
              >
                <div className={`at-presentation__dropbear--list`}>
                  <button
                    onClick={() =>
                      this.props.onChangeClientsHOC(
                        true,
                        "showClientsCreateModal",
                      )
                    }
                  >
                    Create Client
                  </button>
                  <button
                    onClick={() =>
                      this.props.onChangeClientsHOC(
                        true,
                        "showAssignContactModal",
                      )
                    }
                  >
                    Assign from Contact
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <p className="fs-2 mt-2">
          This is the list of clients that this presentation is available for.
          Add, edit to delete using your Atlas contacts list.
        </p>
        <div className="at-heading_card_table">
          <AtPaginatedTable
            rowData={clients.data || []}
            meta={clients.meta}
            showCloseButton={false}
            showCreateButton={false}
            columns={columnData}
            customEmptyState={{
              title: "You don't have any clients yet",
              description: (
                <>
                  Click <b>New Client</b> to create client
                </>
              ),
            }}
            actionColumnContent={[
              {
                name: can_update ? "edit" : "view",
                onClick: (rowData) =>
                  this.props.getSelectedClients(
                    selectedPresentation.id,
                    rowData.id,
                  ),
              },
              {
                name: "delete",
                color: "#F04438",
                onShow: () => can_destroy,
                onClick: (rowData) => {
                  Promise.all([
                    this.props.onChangeClientsHOC(
                      true,
                      "showClientsDeleteModal",
                    ),
                  ]).then(() => {
                    this.props.onChangeClientsHOC(rowData, "selectedClients");
                  });
                },
              },
            ]}
            searchParams={[]}
            getListAPI={(page) =>
              this.props.getClients(selectedPresentation.id, page)
            }
            totalPages={clientPages}
          />
        </div>
        {showClientsCreateModal && (
          <CreateClientForm
            showLoading={onLoadClients || onLoadPresentation}
            onClickClose={() =>
              this.props.onChangeClientsHOC(false, "showClientsCreateModal")
            }
            onClickSubmit={(dataToSubmit) =>
              this.props.createClients(selectedPresentation.id, dataToSubmit)
            }
          />
        )}
        {showClientsEditModal && (
          <EditClientForm
            permissionsChecker={permissionsChecker("Presentation Centre", data)}
            showLoading={onLoadClients || onLoadPresentation}
            selectedClients={selectedClients}
            onClickClose={() =>
              this.props.onChangeClientsHOC(false, "showClientsEditModal")
            }
            onClickSubmit={(dataToSubmit) =>
              this.props.updateClients(selectedPresentation.id, dataToSubmit)
            }
          />
        )}
        {showAssignContactModal && (
          <AssignContactForm
            clientsData={clients.data}
            showLoading={onLoadClients || onLoadPresentation}
            onClickClose={() =>
              this.props.onChangeClientsHOC(false, "showAssignContactModal")
            }
            onClickSubmit={(val) => {
              let clientsData = [...clients.data] || [];
              if (clientsData.some((item) => item.contact_id === val)) {
                requestError("User is already assigned");
              } else {
                this.props.assignContact(selectedPresentation.id, val);
              }
            }}
          />
        )}
        <AtlasConfirmationDialog
          open={showClientsDeleteModal}
          mode={"alert"}
          showLoading={onLoadClients || onLoadPresentation}
          message={"Are you sure to delete the selected client ?"}
          positiveAction={() =>
            this.props.deleteClient(selectedPresentation.id, selectedClients.id)
          }
          negativeAction={() =>
            this.props.onChangeClientsHOC(false, "showClientsDeleteModal")
          }
        />
        {onLoadClients && <LoadingModal />}
      </>
    );
  };
}

export default ClientHOC(Clients);
