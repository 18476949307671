import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { refreshToken } from "actions/login";

const StatsHOC = (WrappedComponent) => {
  class StatsWrappedComponent extends Component {
    state = {
      loading: false,

      view: "Newsfeed",
    };

    load = (param) => this.setState({ loading: param });

    onChangeStatHOC = (val, context) => this.setState({ [context]: val });
    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            view={this.state.view}
            onLoadStats={this.state.loading}
            onChangeStatHOC={this.onChangeStatHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(StatsWrappedComponent);
};

export default StatsHOC;
