import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "assets/translation";
import useSelectedLocalised from "utils/hooks/useSelectedLocalised";

import "stylesheets/components/form-input/index.scss";
import "./index.scss";

const FormInput = (props) => {
  const inputRef = useRef(null);
  const language = useSelectedLocalised();
  const [isFocused, setIsFocused] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [value, setValue] = useState(props.value || "");

  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.autoFocus]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChangeValue = (event) => {
    const newValue = event.target.value;
    if (!isComposing) {
      if (props.maxLength) {
        if (newValue.length <= props.maxLength) {
          setValue(newValue);
          props.onChangeValue(newValue);
        }
      } else {
        setValue(newValue);
        props.onChangeValue(newValue);
      }
    } else {
      setValue(newValue);
    }
  };

  const onCompositionStart = () => {
    setIsComposing(true);
  };

  const onCompositionEnd = (event) => {
    setIsComposing(false);
    const newValue = event.target.value;
    setValue(newValue);
    props.onChangeValue(newValue);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (props.checkedPressEnterToSend) {
        props.onEnterToSend(props.value);
      }
    }
  };

  const renderSuccessMessage = (message) => {
    if (message) {
      return (
        <div className="at-form-input__label at-form-input__label--success">
          {message}
        </div>
      );
    }
  };

  const renderErrorMessage = (error) => {
    if (error) {
      return (
        <div
          className={
            props.errorMessagePlain
              ? `invalid-feedback`
              : `at-form-input__label at-form-input__label--error`
          }
        >
          {error}
        </div>
      );
    }
  };

  const renderInstruction = (message) => {
    if (message) {
      return (
        <div
          className="at-form-input__label at-form-input__label--success"
          style={{ background: "#c2c2c2", left: 0, right: "auto" }}
        >
          {message}
        </div>
      );
    }
  };

  const renderHelpText = (message) => {
    if (message) {
      return <div className="at-form-input__help_text">{message}</div>;
    }
  };

  const renderSearchIcon = (type) => {
    if (type === "search") {
      return (
        <AtlasIcon
          className={"at-form-input__search-icon"}
          svgHref={"atlas-search-normal"}
        />
      );
    }
  };

  const renderCloseButton = (showClearButton) => {
    if (showClearButton) {
      return (
        <AtlasIcon
          className={"at-form-input__close-icon"}
          svgHref={"atlas-X"}
          onClick={() => props.onChangeValue("")}
        />
      );
    }
  };

  const renderNewClearButton = (showClearButton) => {
    if (showClearButton) {
      return (
        <button
          className={"btn-new btn-new--outline-secondary"}
          style={{
            minWidth: 60,
            width: 60,
          }}
          children={"Clear"}
          onClick={() => props.onChangeValue("")}
        />
      );
    }
  };

  const renderRequired = (param) => {
    if (param) {
      return <div className="at-form-input__required">{getTranslation("required", language)}</div>;
    }
  };

  const renderPrefix = (param) => {
    if (param) {
      return <span style={{ fontSize: 14 }}>{param}</span>;
    }
  };

  const renderFooter = () => {
    const {
      required,
      footerClass,
      inputSuccess,
      inputError,
      instruction,
      helpText,
      additionalContent,
      showClearButton,
      clearButtonMode,
    } = props;

    if (
      required ||
      inputSuccess ||
      inputError ||
      instruction ||
      helpText ||
      additionalContent
    ) {
      return (
        <div
          className={`at-input_footer ${footerClass || ""}`}
          style={{ 
            marginBottom: "1rem",
            ...(showClearButton && clearButtonMode === "New") 
            ? {
              top: 41,
              right: 0,
              position: "absolute"
            }
            : {}
          }}>
          {renderRequired(required)}
          {renderSuccessMessage(inputSuccess)}
          {renderErrorMessage(inputError)}
          {renderInstruction(instruction)}
          {renderHelpText(helpText)}
          {additionalContent && additionalContent}
        </div>
      );
    }
    return "";
  };

  const {
    style,
    autoFocus,
    calendarProps,
    type,
    required,
    prefix,
    inputError,
    disabled,
    placeholder,
    className,
    containerClass,
    inputStyle,
    containerStyle,
    numericOnly,
    maxLength,
    onChangeFocus,
    onChangeBlur,
    showClearButton,
    clearButtonMode,
  } = props;

  return (
    <div
      className={`${containerClass || ""}`}
      style={{
        ...containerStyle,
        ...(showClearButton && clearButtonMode === "New"
          ? {
              display: "flex",
              flexWrap: "wrap",
              gridGap: 12,
              position: "relative",
            }
          : {}),
      }}
    >
      <div
        style={{
          ...style,
          ...(showClearButton && clearButtonMode === "New"
            ? {
                minWidth: "calc(100% - 60px - 12px)",
                width: "calc(100% - 60px - 12px)",
              }
            : {}),
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`
          at-form-input
          ${type || ""}
          ${className || ""}
          ${isFocused ? "input-focused" : ""}
          ${inputError ? "at-form-input--error" : ""}
          ${disabled ? "at-form-input__disabled" : ""}
        `}
      >
        {renderPrefix(prefix)}
        {type !== "textarea" && (
          <input
            {...calendarProps}
            autoFocus={autoFocus}
            type={type}
            ref={inputRef}
            {...(numericOnly && {
              inputmode: "decimal",
              pattern: "[0-9]*",
            })}
            style={{ ...inputStyle }}
            onKeyDown={onKeyPress}
            className={"at-form-input__input"}
            value={value}
            onChange={(event) => onChangeValue(event)}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={onCompositionEnd}
            placeholder={placeholder}
            required={required}
            maxLength={maxLength}
            disabled={disabled}
            onWheel={(e) => e.target.blur()}
            onBlur={() => onChangeBlur && onChangeBlur()}
            onFocus={() => onChangeFocus && onChangeFocus()}
            step={0.01}
          />
        )}
        {type === "textarea" && (
          <textarea
            ref={inputRef}
            style={{ ...inputStyle }}
            className={`at-form-input__textarea`}
            value={value}
            onKeyDown={onKeyPress}
            onChange={(event) => onChangeValue(event)}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={onCompositionEnd}
            placeholder={placeholder}
            required={required}
            maxLength={maxLength}
            disabled={disabled}
            onBlur={() => onChangeBlur && onChangeBlur()}
            onFocus={() => onChangeFocus && onChangeFocus()}
          />
        )}
        {clearButtonMode !== "New" && renderCloseButton(showClearButton)}
        {renderSearchIcon(type)}
      </div>
      {clearButtonMode === "New" && renderNewClearButton(showClearButton)}
      {renderFooter()}
    </div>
  );
};

export default FormInput;