import React, { Component } from "react";
import {
  Card,
  Grid,
  Tooltip,
  Typography,
  FormControl,
  FormHelperText,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import _ from "lodash";
import { AiFillFilePdf } from "react-icons/ai";
import { BiCreditCard } from "react-icons/bi";
import { RiUserStarLine } from "react-icons/ri";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";
import AlertBox from "components/AlertBox";
import AtlasButton from "components/Button";
import PaymentForm from "containers/iPay88";

import iPayLogo from "assets/ipay88/ipay88.png";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";

import WithPayments from "../action/Payment";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./selection.scss";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class SelectionPage extends Component {
  componentDidMount = () => {
    if (this.props.branch_id) {
      this.props.getPrice(1, this.props.branch_id);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.branch_id !== this.props.branch_id) {
      this.props.getPrice(1, this.props.branch_id);
    }
  }

  renderPaymentContent = () => {
    const {
      message,
      user_id,
      tabContentType,
      payment_proof,
      uploadedPaymentUrl,
      paymentMethodId,
      agent_via_recruitment_campaign,
      showPaymentGateway,
      onClickSubmitPaymentProof,
      onChangeHOC,
      onChangeFile,
    } = this.props;
    return (
      <>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: agent_via_recruitment_campaign
                ? "flex-start"
                : "center",
              marginBottom: 10,
            }}
          >
            {showPaymentGateway &&
              !agent_via_recruitment_campaign &&
              (paymentMethodId === 2 || paymentMethodId === 0) && (
                <button
                  className={`at-event__checkout-payment-option ${tabContentType === "Online Payment" ? "at-event__checkout-payment-option-selected" : ""}`}
                  onClick={() => {
                    onChangeHOC("Online Payment", "tabContentType");
                  }}
                >
                  <BiCreditCard style={{ width: 50, height: 25 }} />
                  Online/Credit Card
                </button>
              )}
            {!agent_via_recruitment_campaign &&
              (paymentMethodId === 1 || paymentMethodId === 0) && (
                <button
                  style={{ marginLeft: 10 }}
                  className={`at-event__checkout-payment-option ${tabContentType === "Bank in" ? "at-event__checkout-payment-option-selected" : ""}`}
                  onClick={() => {
                    onChangeHOC("Bank in", "tabContentType");
                  }}
                >
                  <FaRegMoneyBillAlt style={{ width: 50, height: 25 }} />
                  Bank-In
                </button>
              )}
            {agent_via_recruitment_campaign && (
              <button
                style={{ marginLeft: 10 }}
                className={`at-event__checkout-payment-option ${tabContentType === "Recruitment Campaign" ? "at-event__checkout-payment-option-selected" : ""}`}
                onClick={() => {
                  onChangeHOC("Recruitment Campaign", "tabContentType");
                }}
              >
                <RiUserStarLine style={{ width: 50, height: 20 }} />
                Recruitment Campaign
              </button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          {(showPaymentGateway && tabContentType === "Online Payment") && (
            <>
              <div className="col-md-12 payment-container">
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Instructions
                </Typography>
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: 16,
                  }}
                >
                  1. Click on your preferred payment method below.
                </Typography>
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: 16,
                  }}
                >
                  2. You will then be redirected to iPay88 to make a secure
                  payment.
                </Typography>
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: 16,
                  }}
                >
                  3. Please do not close the page until the transaction is
                  complete.
                </Typography>
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: 16,
                  }}
                >
                  4. You will be redirected back to this page upon completion of
                  the payment process.
                </Typography>
                <hr />
                <img
                  alt="ipay88"
                  src={iPayLogo}
                  className="ipay-logo"
                  style={{ width: "20%", minWidth: 100 }}
                />
                <div className="ipay-frame d-flex flex-wrap justify-content-center">
                  <FormHelperText className="my-4 w-100 text-center">
                    Choose a payment method. You will then be redirected to a
                    secure page to complete the payment.
                  </FormHelperText>

                  <PaymentForm
                    payment_id={2}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={16}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={210}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={523}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={538}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={542}
                    productDesc={"IQI agent registration."}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                  <PaymentForm
                    payment_id={801}
                    userInfo={message}
                    refNo={this.props.refNo}
                    amount={this.props.amountString}
                    amountSHA256={this.props.amountString.replace(".", "")}
                    remark={"Payment to IQI for agent registration."}
                  />
                </div>
              </div>
              <Typography
                style={{
                  marginTop: 10,
                  textTransform: "none",
                  fontWeight: "300",
                  fontSize: 14,
                  marginLeft: 6,
                }}
              >
                Click to understand more about the{" "}
                <a
                  onClick={() => onChangeHOC(true, "showPolicyRefundModal")}
                  style={{ textDecoration: "underline" }}
                >
                  refund policy
                </a>{" "}
                and{" "}
                <a
                  onClick={() => onChangeHOC(true, "showPrivacyPolicyModal")}
                  style={{ textDecoration: "underline" }}
                >
                  privacy policy.
                </a>
              </Typography>
            </>
          )}
          {(tabContentType === "Bank in" && uploadedPaymentUrl)&& (
            <Grid item md={3} xs={12}>
              {uploadedPaymentUrl.indexOf(".pdf") === -1 && (
                <Tooltip title="Click to view" placement="top">
                  <Card>
                    <img
                      alt="payment proof"
                      src={uploadedPaymentUrl}
                      onClick={() => onChangeHOC(true, "showPaymentProofModal")}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </Card>
                </Tooltip>
              )}
              {uploadedPaymentUrl.indexOf(".pdf") > -1 && (
                <Tooltip title="Click to view pdf" placement="top">
                  <AiFillFilePdf
                    style={{
                      width: 150,
                      height: 150,
                      color: "#f18e03",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(uploadedPaymentUrl, "_blank")}
                  />
                </Tooltip>
              )}
            </Grid>
          )}
          {tabContentType === "Bank in" && (
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <FilePond
                  ref={(ref) => (this.pond = ref)}
                  allowMultiple={false}
                  fullWidth
                  labelIdle={"Click here to upload the proof of payment"}
                  maxFiles={1}
                  acceptedFileTypes={["image/*", "application/pdf"]}
                  onupdatefiles={(fileItems) => {
                    onChangeFile(fileItems, "Bank In");
                  }}
                />
                <FormHelperText>
                  Accepted file types: jpg, jpeg, png.( Required )
                </FormHelperText>
                <Typography
                  style={{
                    marginTop: 10,
                    textTransform: "none",
                    fontWeight: "300",
                    fontSize: 14,
                  }}
                >
                  Click to understand more about the{" "}
                  <a
                    onClick={() => onChangeHOC(true, "showPolicyRefundModal")}
                    style={{ textDecoration: "underline" }}
                  >
                    refund policy
                  </a>{" "}
                  and{" "}
                  <a
                    onClick={() => onChangeHOC(true, "showPrivacyPolicyModal")}
                    style={{ textDecoration: "underline" }}
                  >
                    privacy policy.
                  </a>
                </Typography>
              </FormControl>
              <DialogActions
                className={"g-3 mt-4 mb-4 d-flex justify-content-center"}
              >
                <div className={"d-flex align-items-center g-3"}>
                  <AtlasButton
                    className={"btn-new btn-new--primary"}
                    onClick={() => {
                      this.pond.removeFile();
                      onClickSubmitPaymentProof(
                        user_id,
                        this.props.amountString,
                        "Bank In"
                      );
                    }}
                    disabled={_.isEmpty(payment_proof || paymentMethodId === 2)}
                  >
                    Upload
                  </AtlasButton>
                </div>
              </DialogActions>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  render = () => {
    const {
      message,
      history,
      paymentMethod,
      paymentMethodId,
      agent_via_recruitment_campaign,
      currencyCode,
      amountString,
      bankOwnerName,
      bankAccountNumber,
      bankName,
    } = this.props;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    return (
      <Dialog
        fullScreen={true}
        classes={{ paper: "dialog_paper", root: "dialog_root" }}
        open={true}
      >
        <div className={"welcome-header text-center mb-4"}>
          <div>
            <img src={JuwaiIQILogo} alt={"JuwaiLogo"} style={{ height: 200 }} />
            <span
              style={{
                fontSize: "26px",
                fontWeight: "600",
                display: "block",
              }}
            >
              {this.props.currentModalSequence === 0 && "Welcome to IQI Atlas"}
              {this.props.currentModalSequence !== 0 && "Agent Registration Fee"}
            </span>
          </div>
        </div>
        <div class="welcome-header" style={{ marginTop: 44, marginBottom: 44 }}>
          <hr style={{ width: "100%" }} />
          <div className="welcome-stepper-icon-container">
            <div className="welcome-stepper-board">
              <AtlasIcon
                svgHref={"cash-stack"}
                className={"welcome-stepper-atlas-icon"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: "24px",
            fontWeight: "600",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {(paymentMethodId !== null && paymentMethodId !== 0)
            ? "Payment Successful!"
            : "Payment"}
        </div>

        <div className={"container pt-4 mb-4"} style={{ maxWidth: 768 }}>
          <Grid container spacing={16}>
            {paymentMethodId === 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    textDecoration: "underlined",
                    textTransform: "none",
                  }}
                >
                  You’re almost there! One more step to complete your
                  registration.
                </Typography>
                {!agent_via_recruitment_campaign && (
                  <>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: "300",
                        fontSize: 16,
                      }}
                    >
                      {`To complete your application, you must pay a registration fee. You may do so by using your credit/debit card or via online banking to make an online payment of ${currencyCode || ""} ${amountString ? Number(amountString).toFixed(2) : 0.00 } to ${bankOwnerName} using bank account number ${bankAccountNumber} ( ${bankName} ).`}
                    </Typography>
                    <br />
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: "300",
                        fontSize: 16,
                      }}
                    >
                      {`If you have transferred the registration fee directly to the bank account, please upload your bank slip so our team can check and update your payment status.`}
                    </Typography>
                  </>
                )}
                <br />
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: "300",
                    fontSize: 16,
                  }}
                >
                  In the meantime, we have created a temporary account for you
                  in IQI Atlas. Account details are sent to your registered
                  mobile device. You can now access a limited set of features on
                  IQI Atlas. Be sure to settle your payment within 24 hours of
                  your registration to enjoy the complete IQI Atlas experience.
                  Please do so to avoid account deletion.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <AlertBox
                mode={"info"}
                description={
                  <>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      Email: {message?.email ?? ""}
                    </Typography>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      Primary Mobile Number: {message?.mobile_contact_number ?? ""}
                    </Typography>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      Payment Status: {paymentMethod}
                    </Typography>
                  </>
                }
                disabledClose
              />
              {!_.isNil(paymentMethodId) && paymentMethodId !== 0 && (
                <>
                  <div
                    style={{
                      border: "3px solid #5cb85c",
                      borderRadius: 10,
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      - Our admin will verify your payment shortly.
                    </Typography>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      - Next, you may proceed to complete your profile while
                      waiting for the approval process.
                    </Typography>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 16,
                      }}
                    >
                      - Kindly retrieve your log-in details from your email or
                      SMS.
                    </Typography>
                  </div>
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: 30,
                    }}
                  >
                    <AtlasButton
                      className={"btn-new btn-new--primary"}
                      onClick={() =>
                        tmpPath
                          ? history.push(`${tmpPath}/dashboard/profile`)
                          : history.push(`/login`)
                      }
                    >
                      Bring me to complete my profile
                    </AtlasButton>
                  </div>
                </>
              )}
            </Grid>
            {paymentMethodId === 0 && this.renderPaymentContent()}
          </Grid>
        </div>
        {this.props.onLoading && <LoadingModal/>}   
      </Dialog>
    );
  };
}

export default WithPayments(SelectionPage);
