export const BadgeColor = [
  {
    label: "Orange",
    color: "#C2410C",
    fill: "#C2410C",
    backgroundColor: "#FFEDD5",
    borderColor: "#FDBA74",
  },
  {
    label: "Grey",
    color: "#374051",
    fill: "#374051",
    backgroundColor: "#F3F4F6",
    borderColor: "#D1D5DB",
  },
  {
    label: "Green",
    color: "#037B4C",
    fill: "#037B4C",
    backgroundColor: "#D1FADF",
    borderColor: "#6BE9A6",
  },
  {
    label: "Blue",
    color: "#1C4ED8",
    fill: "#1C4ED8",
    backgroundColor: "#DBE9FE",
    borderColor: "#92C5FD",
  },
  {
    label: "Yellow",
    color: "#B44708",
    fill: "#B44708",
    backgroundColor: "#FEF1C6",
    borderColor: "#FFC84B",
  },
  {
    label: "Red",
    color: "#B42419",
    fill: "#B42419",
    backgroundColor: "#F4E3DF",
    borderColor: "#FDA29B",
  },
  {
    label: "Lime",
    color: "#4C7C0F",
    fill: "#4C7C0F",
    backgroundColor: "#EBFDCA",
    borderColor: "#BDF363",
  },
  {
    label: "Teal",
    color: "#0F766E",
    fill: "#0F766E",
    backgroundColor: "#CCFBF1",
    borderColor: "#5EEAD4",
  },
  {
    label: "Cyan",
    color: "#0D7490",
    fill: "#0D7490",
    backgroundColor: "#D0FAFE",
    borderColor: "#65E8F9",
  },
  {
    label: "Teal",
    color: "#12766E",
    fill: "#12766E",
    backgroundColor: "#CBFBF1",
    borderColor: "#5FE9D5",
  },
  {
    label: "Pink",
    color: "#BE175D",
    fill: "#BE175D",
    backgroundColor: "#FCE6F3",
    borderColor: "#F8A8D4",
  },
  {
    label: "Fuchsia",
    color: "#A21CAF",
    fill: "#A21CAF",
    backgroundColor: "#FAE7FF",
    borderColor: "#F0ABFC",
  },
  {
    label: "Violet",
    color: "#6D28D9",
    fill: "#6D28D9",
    backgroundColor: "#EDE9FE",
    borderColor: "#C3B6FD",
  },
];

export const getColorBadge = (colorName) => {
  return BadgeColor.find((item) => item.label === colorName) || BadgeColor[0];
};
