import React, { useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import CutsomTable from "components/NewTable";
import CutsomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";

const columnData = ({
  checkedJointTicket,
  onChangeCheckedTicket
}) => [
  { header: "", searchFlag: "",
    columnStyle: { width: '20%' },
    columnClass: 'at-table--checkbox-column at-table-head__hide-mobile',
    renderColumn: rowData => (
      <CutsomRadioGroup
        mode={"old"}
        checkedValue={
          checkedJointTicket || (rowData["is_attached?"] ? rowData.id : "")
        }
        selectedRadioValue={onChangeCheckedTicket}
        options={[{ label: "", value: rowData.id }]}
      />
    ),
  },
  {
    header: "Event Name",
    searchFlag: "event",
    renderColumn: (rowData) => rowData?.event?.name,
  },
  { header: "Event Name", searchFlag: "event" , renderColumn: rowData => (
    <>
      <CutsomRadioGroup
        mode={ 'old' }
        containerClassName={ 'at-table--radio-mobile ' }
        checkedValue={ checkedJointTicket || (rowData['is_attached?'] ? rowData.id : '' ) }
        selectedRadioValue={ onChangeCheckedTicket }
        options={ [{ label: '', value: rowData.id }] } />
      <div className="d-flex flex-column">
        { rowData?.event?.name }
      </div>
    </>
  ) },
  { header: "Pricing Title", accessor: "title", searchFlag: "title" },
  {
    header: "Price",
    searchFlag: "price",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => `RM ${rowData.price}`,
  },
];

const CreateTicketPricing = ({
  attachJointTicket,
  availableJointTickets,
  showAttachTicketPricing,
  onLoadEventTraining,
  onLoadEditEvent,

  onClose,
}) => {
  const [checkedJointTicket, onChangeCheckedTicket] = useState("");

  const onToggleClose = () => {
    onChangeCheckedTicket("");
    onClose();
  };

  return (
    <>
      {showAttachTicketPricing && (
        <ModalDialog
          title={ 'Select Secondary Ticket To Joint Ticket' }
          onClose={ onToggleClose }
          onLoad={ ( onLoadEventTraining || onLoadEditEvent ) }
          children={
            <CutsomTable
              columns={columnData({
                checkedJointTicket,
                onChangeCheckedTicket,
              })}
              rowData={availableJointTickets}
              hideSearch={true}
              hidePagination={true}
            />
          }
          footer={
            <div className="d-flex g-3">
              <CustomButton
                onClick={ () => attachJointTicket({ attached_ticket_pricing_id : checkedJointTicket }) }
                disabled={ !checkedJointTicket }
                className={ 'btn-new btn-new--primary' }
                children={ 'Attach' } />
              <CustomButton
                onClick={ onToggleClose }
                className={ 'btn-new btn-new--outline-secondary' }
                children={ 'Cancel' } />
            </div>
          }
        />
      )}
    </>
  );
};

export default CreateTicketPricing;
