import { Card } from '@material-ui/core';
import { useState } from 'react';
import { MdFilterList } from 'react-icons/md';

import AtlasButton from 'components/Button';
import EmptyState from 'components/EmptyState';
import AtlasIcon from 'components/Icon/atlasIcon';
import AtlasConfirmationDialog from 'components/Modal/confirmation';
import ModalDialog from 'components/Modal/ModalDialog';
import TablePagination from 'components/NewPaginatedTable/Pagination';
import TableSearchParams from 'components/NewPaginatedTable/SearchParams';
import VideoPlayer from 'components/VideoPlayer';
import EditVideoSettings from './EditVideoSettings';

import DefaultThumbnail from 'assets/images/default_thumbnail.jpg';
import { videoConfiguration } from '../assets';

const CreateVideoRequest = ({
  onLoadVideoCreator,
  videoTemplates,
  showEditVideoSettings,
  selectedVideoTemplate,
  selectedProject,
  data,
  onChangeVideoCreatorHOC,
  videoTemplatePages,
  getVideoTemplates,
  getSelectedProject,
  createPersonalisedVideo
}) => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [videoVersion, setVideoVersion] = useState(0);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [searchParams, setSearchParams] = useState([
    {
      type: 'input',
      label: 'Title',
      value: 'title_cont',
      param: ''
    },
    {
      type: 'input',
      label: 'Project',
      value: 'project_internal_name_cont',
      param: ''
    },
    {
      type: 'select',
      label: 'Country',
      value: 'country_id_eq',
      param: '',
      options: data.dictionaryReducer?.countries
    },
    {
      type: 'radio',
      label: 'Video Type',
      value: 'type_id_eq',
      options: [
        { value: '', label: 'All' },
        { value: 1, label: 'Project' },
        { value: 2, label: 'General' }
      ],
      param: ''
    }
  ]);

  const { meta } = videoTemplates;

  const handleClose = () => {
    onChangeVideoCreatorHOC('showCreateModal', false);
  };

  const renderVideoTemplates = () => {
    if (videoTemplates.data?.length > 0) {
      return (
        <div className='form-row'>
          {videoTemplates.data?.map((template, index) => (
            <div className='col-md-6 col-xl-4' key={index}>
              <Card
                className='position-relative overflow-hidden'
                style={{ borderRadius: '0.5rem', backdropFilter: 'blur(0px)' }}
              >
                <div className='at-video-creator__video-buttons'>
                  <AtlasButton
                    className='at-video-creator__play-btn'
                    tooltip
                    tooltipChildren='Preview Video'
                    tooltipPosition='top'
                    tooltipID={`video-preview-${index}`}
                    onClick={() => {
                      setShowVideoPlayer(true);
                      setVideoURL(template.url);
                    }}
                  >
                    <AtlasIcon
                      svgHref='atlas-play-circle'
                      style={{ width: 20, height: 20, fill: '#3B82F6' }}
                    />
                    {template.duration}
                  </AtlasButton>
                  <AtlasButton
                    className='at-video-creator__process-btn'
                    tooltip
                    tooltipChildren='Add to process list'
                    tooltipPosition='top'
                    tooltipID={`video-request-${index}`}
                    onClick={async () => {
                      setVideoVersion(template.version);

                      if (template.version === 1) {
                        onChangeVideoCreatorHOC('selectedVideoTemplate', template);
                        setShowConfirmationModal(true);
                      } else if (template.version === 2) {
                        if (template.type_id === 1) {
                          getSelectedProject(template);
                        } else {
                          await onChangeVideoCreatorHOC('selectedVideoTemplate', {
                            ...template,
                            configuration: videoConfiguration(template.type_id)
                          });
                          await onChangeVideoCreatorHOC('showEditVideoSettings', true);
                        }
                      }
                    }}
                  >
                    <AtlasIcon
                      svgHref='atlas-additem'
                      style={{ width: 20, height: 20, fill: '#FFF' }}
                    />
                  </AtlasButton>
                </div>
                <img
                  src={template.thumbnail_url || DefaultThumbnail}
                  alt={`thumbnail-${index}`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    aspectRatio: '16 / 9'
                  }}
                />
                <div className='at-video-creator__video-details'>
                  <h6 className='mb-1'>{template.title}</h6>
                  <p className='mb-0'>{template.description || 'N/A'}</p>
                </div>
              </Card>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <EmptyState
          title={`You don't have any videos`}
          renderIcon={<AtlasIcon svgHref='atlas-video-play' />}
        />
      );
    }
  };

  const renderCardContent = () => (
    <>
      <p className='at-video-creator__help-container mb-4'>
        To get started, search for an available video template by the project
        name or title. When you have found the template you want to be
        personalised, select it and then click on the Add icon. Atlas will then
        generate your personalised video - when the video is ready, you will get
        a notification.
      </p>
      {searchParams.length > 0 && (
        <div className='mb-4'>
          <button
            className={
              'btn-new btn-new--outline-secondary at-mobile-view__controller'
            }
            style={{ width: '100%' }}
            onClick={() => setShowSearchModal(!showSearchModal)}
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          <div
            className='at-table__search-cont at-desktop-view__controller'
            style={{ boxShadow: 'none' }}
          >
            <TableSearchParams
              mode='desktop'
              searchParams={searchParams}
              onChangeSearchParams={setSearchParams}
              getListAPI={getVideoTemplates}
            />
          </div>
        </div>
      )}
      {renderVideoTemplates()}
      <div className='at-pagination__cont pb-0'>
        <TablePagination
          meta={meta}
          searchParams={searchParams}
          getListAPI={getVideoTemplates}
          totalPages={videoTemplatePages}
        />
        {meta && meta.count > 0 && (
          <p className='at-table__pagination-info'>
            Showing
            <span> {meta?.from} </span>-<span> {meta?.to} </span>
            of
            <span> {meta?.count} </span>
            results
          </p>
        )}
      </div>
      {showSearchModal && (
        <TableSearchParams
          mode='mobile'
          searchParams={searchParams}
          onToggleSearchModal={setShowSearchModal}
          onChangeSearchParams={setSearchParams}
          getListAPI={getVideoTemplates}
        />
      )}
    </>
  );

  const renderConfirmation = () => (
    <AtlasConfirmationDialog
      open={showConfirmationModal}
      modalClass='at-video-creator__confirmation-modal'
      containerStyle={{ maxWidth: 480 }}
      iconContainerClassName='my-0'
      title='New Video Request'
      message='Click proceed to generate personalised video using this template. The video processing might take several minutes. Please check the status periodically.'
      positiveText='Proceed'
      positiveAction={async () => {
        if (videoVersion === 1) {
          createPersonalisedVideo({ video_template_id: selectedVideoTemplate.id });
        } else {
          createPersonalisedVideo({
            video_template_id: selectedVideoTemplate.id,
            configuration: selectedVideoTemplate.configuration
          });
        }

        setShowConfirmationModal(false);
      }}
      negativeText='Cancel'
      negativeAction={() => setShowConfirmationModal(false)}
    />
  );

  return (
    <ModalDialog
      title='New Video Request'
      fullWidth
      fullHeight
      responsiveSize=''
      contentClassName='at-video-creator__dialog-content mb-0'
      onLoad={onLoadVideoCreator}
      onClose={handleClose}
    >
      {renderCardContent()}
      {showEditVideoSettings && (
        <EditVideoSettings
          onLoadVideoCreator={onLoadVideoCreator}
          selectedVideoTemplate={selectedVideoTemplate}
          selectedProject={selectedProject}
          agent={data.profileReducer}
          onChangeVideoCreatorHOC={onChangeVideoCreatorHOC}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      {showVideoPlayer && (
        <VideoPlayer
          videoURL={videoURL}
          onClickClose={() => setShowVideoPlayer(false)}
        />
      )}
      {renderConfirmation()}
    </ModalDialog>
  );
};

export default CreateVideoRequest;
