import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      unclaimedConfirmationForms: [],
      unclaimedSubsalesForms: [],
    };

    load = (param) => this.setState({ loading: param });

    submitForm = (id, formType, dataToSubmit) => {
      if (formType === "ConfirmationForm") {
        return Post(
          `/admin/e_processes/submit_claim?form_id=${id}`,
          {
            representative_id: dataToSubmit.representative_id,
            user_id: dataToSubmit.user_id,
          },
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      } else if (formType === "PhysicalForm") {
        return Post(
          `/admin/sub_sales_forms/${id}/submit`,
          { representative_id: dataToSubmit.representative_id },
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      } else if (formType === "AppointmentLetter") {
        return Post(
          `/admin/forms/appointment_letters/${id}/submit`,
          dataToSubmit,
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      } else if (formType === "AuthorisationForm") {
        return Post(
          `/admin/forms/authorisation_forms/${id}/submit`,
          dataToSubmit,
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      }
    };
    submitFormSuccess = ({ formType, form_id, id }) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess("Form is submitted successfully");
      if (formType === "AppointmentLetter") {
        this.props.getAppointmentLetter(id);
        this.props.getAppointmentLetters(currentPage, searchParams);
      } else if (formType === "AuthorisationForm") {
        this.props.getSelectedAuthorisationForms(id);
        this.props.getAuthorisationForms(currentPage, searchParams);
      } else if (formType === "ConfirmationForm") {
        this.props.getConfirmationForm(id);
        this.props.getConfirmationForms(currentPage, searchParams);
      } else if (formType === "Physical Form") {
        this.props.getPaperForms(currentPage, searchParams);
        this.props.getSelectedPaperForm(id);
      }

      if (this.props.closeSubmitConfirmation) {
        this.props.closeSubmitConfirmation();
      }
    };
    submitFormError = (error) => {
      requestError(error);
    };

    detachForm = (dataToSubmit) => {
      if (dataToSubmit.form_type === "Confirmation Form") {
        return Put(
          `/admin/forms/confirmation_forms/${dataToSubmit.confirmation_form_id}/detach_form`,
          { module_type: dataToSubmit.module_type },
          (payload) => this.detachFormSuccess(dataToSubmit),
          this.detachFormError,
          this.load,
        );
      } else if(dataToSubmit.form_type === "Physical Form") {
        return Put(
          `/admin/forms/sub_sales_forms/${dataToSubmit.physical_form_id}/detach_form`,
          {},
          (payload) => this.detachFormSuccess(dataToSubmit),
          this.detachFormError,
          this.load,
        );
      } else if(dataToSubmit.form_type === "Claim Form") {
        return Put(
          `/admin/sub_sale_claims/${dataToSubmit.claim_id}/detach_appointment_letter`,
          {},
          (payload) => this.detachFormSuccess(dataToSubmit),
          this.detachFormError,
          this.load,
        );
      }
    };
    detachFormSuccess = ({ module_type, form_id, id }) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess("Form is detached successfully");
      let tmpId = "";

      if (
        ["AppointmentLetter", "AuthorisationForm"].indexOf(module_type) > -1
      ) {
        tmpId = form_id;
      } else {
        tmpId = id;
      }

      if (module_type === "AppointmentLetter") {
        this.props.getAppointmentLetter(tmpId);
        this.props.getAppointmentLetters(currentPage, searchParams);
      } else if (module_type === "AuthorisationForm") {
        this.props.getSelectedAuthorisationForms(tmpId);
        this.props.getAuthorisationForms(currentPage, searchParams);
      } else if (module_type === "ConfirmationForm") {
        this.props.getConfirmationForm(tmpId);
        this.props.getConfirmationForms(currentPage, searchParams);
      }

      if (this.props.closeSubmitConfirmation) {
        this.props.closeSubmitConfirmation();
      }
    };
    detachFormError = (error) => {
      requestError(error);
    };

    getUnattachedConfirmationForms = (module_type, user_id, type_id) =>
      Get(
        `/admin/forms/confirmation_forms/unattached?module_type=${module_type}&user_id=${user_id}&type_id=${type_id}`,
        this.getUnattachedConfirmationFormsSuccess,
        this.getUnattachedConfirmationFormsError,
        this.load,
      );
    getUnattachedConfirmationFormsSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.transaction_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedConfirmationForms: tmp });
      }
    };
    getUnattachedConfirmationFormsError = (error) => requestError(error);

    getUnattachedSubsalesForms = (user_id, type_id) =>
      Get(
        `/admin/forms/sub_sales_forms/unattached?user_id=${user_id}&type_id=${type_id}`,
        this.getUnattachedSubsalesFormsSuccess,
        this.getUnattachedSubsalesFormsError,
        this.load,
      );
    getUnattachedSubsalesFormsSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.tracking_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedSubsalesForms: tmp });
      }
    };
    getUnattachedSubsalesFormsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadForms={this.state.loading}
            unclaimedConfirmationForms={this.state.unclaimedConfirmationForms}
            unclaimedSubsalesForms={this.state.unclaimedSubsalesForms}
            submitForm={this.submitForm}
            detachForm={this.detachForm}
            getUnattachedConfirmationForms={this.getUnattachedConfirmationForms}
            getUnattachedSubsalesForms={this.getUnattachedSubsalesForms}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
