import React, { useState } from "react";
import _ from "lodash";

import Tab from "components/Tab";
import ParkedLicense from "./ParkLicense";
import DetailsSection from "./Details";

const Update = ({
  banks,
  countries,
  branchRegions,
  selectedBranch,

  onClickSubmit,
  onChangeBranchHOC,
}) => {
  const [tabContent, setTabContent] = useState("Details");

  return (
    <>
      <Tab
        sections={["Details", "License Records"]}
        selectedSection={tabContent}
        onSelectSection={(val) => setTabContent(val)}
      />
      {tabContent === "Details" && (
        <DetailsSection
          mode={"update"}
          banks={banks}
          selectedBranch={selectedBranch}
          branchRegions={branchRegions}
          countries={countries}
          onClickSubmit={onClickSubmit}
          onChangeBranchHOC={onChangeBranchHOC}
        />
      )}
      {tabContent === "License Records" && (
        <ParkedLicense selectedBranch={selectedBranch} />
      )}
    </>
  );
};

export default Update;
