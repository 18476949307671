import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import Moment from "moment";

import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestError } from "utils/requestHandler";
import { getItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showSearchTips: false,
      subsalesType: "All",
      initialSubmittedSubsales: [],
      submittedSubsales: [],
      startDate: Moment()
        .year(new Date().getFullYear())
        .month(0)
        .date(1)
        .format("YYYY-MM-DD"),
      endDate: Moment(new Date()).format("YYYY-MM-DD"),
      totalAgencyComm: 0,
      totalEstimatedComm: 0,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSubmittedSubsalesHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeSubsalesType = () => {
      let temp = _.cloneDeep(this.state.initialSubmittedSubsales);
      switch (this.state.subsalesType) {
        case "Rental":
          return this.setState({
            submittedSubsales: _.filter(temp, { type: "Rental" }),
          });
        case "Sale":
          return this.setState({
            submittedSubsales: _.filter(temp, { type: "Sale" }),
          });
        default:
          return this.setState({ submittedSubsales: temp });
      }
    };

    getSubsalesSubmittedSales = (startDate, endDate) => {
      let endpoint = `/downlines_sub_sale_submitted_sales/report`;
      if (startDate && endDate) {
        endpoint = `/downlines_sub_sale_submitted_sales/report?q[booking_date_gteq]=${startDate}&q[booking_date_lteq]=${endDate}`;
      }

      Get(
        endpoint,
        this.getSubsalesSubmittedSalesSuccess,
        this.getSubsalesSubmittedSalesError,
        this.load,
      );
    };
    getSubsalesSubmittedSalesSuccess = (payload) => {
      this.setState({
        submittedSubsales: payload.sales,
        initialSubmittedSubsales: payload.sales,
      });
    };
    getSubsalesSubmittedSalesError = (error) => requestError(error);

    exportSubsalesSubmittedSales = () => {
      const { startDate, endDate } = this.state;
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      this.load(true);

      return fetch(
        `${getDomainURL()}/downlines_sub_sale_submitted_sales/export_report?q[booking_date_gteq]=${startDate}&q[booking_date_lteq]=${endDate}`,
        { headers },
      )
        .then((response) => {
          return response.blob();
        })
        .then((blobby) => {
          return this.setState({ loading: false }, () => {
            return FileSaver.saveAs(blobby, "Subsales");
          });
        })
        .catch((error) => {
          requestError("Failed to download file. Please try again later.");
        });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadSubmittedSubsales={this.state.loading}
          showSearchTips={this.state.showSearchTips}
          subsalesType={this.state.subsalesType}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          submittedSubsales={this.state.submittedSubsales}
          totalAgencyComm={this.state.totalAgencyComm}
          totalEstimatedComm={this.state.totalEstimatedComm}
          onChangeSubsalesType={this.onChangeSubsalesType}
          getSubsalesSubmittedSales={this.getSubsalesSubmittedSales}
          exportSubsalesSubmittedSales={this.exportSubsalesSubmittedSales}
          onChangeSubmittedSubsalesHOC={this.onChangeSubmittedSubsalesHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
