import React, { Component } from "react";
import { Get } from "utils/axios";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      products: [],
    };

    load = (param) => this.setState({ loading: param });

    getProductList = () =>
      Get(
        `/admin/market_products/active_products`,
        this.getProductListSuccess,
        this.getProductListError,
        this.load,
      );
    getProductListSuccess = (payload) => this.setState({ products: payload });
    getProductListError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          products={this.state.products}
          onLoadProduct={this.state.loading}
          getProductList={this.getProductList}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
