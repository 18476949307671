import React, { Component } from "react";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";

class PaymentGatewayFeedback extends Component {
  renderCardContent = () => (
    <>
      {window.location.href.indexOf("success") > -1 && (
        <div className="d-flex flex-column align-items-center">
          <div className="at-confirmation_modal-icon">
            <AtlasIcon
              svgHref={"atlas-tick-circle"}
              style={{ fill: "#12B76A", backgroundColor: "#D1FADF" }}
            />
          </div>
          <h4 className="at-card__title mt-3">Payment Success</h4>
          <p className="p-3 fs-2 text-center">
            {"Payment completed successfully."}
          </p>
        </div>
      )}
      {window.location.href.indexOf("success") === -1 && (
        <div className="d-flex flex-column align-items-center">
          <div className="at-confirmation_modal-icon">
            <AtlasIcon
              svgHref={"atlas-danger"}
              style={{ fill: "#B42318", backgroundColor: "#FEF3F2" }}
            />
          </div>
          <h4 className="at-card__title mt-3">Payment Unsuccessful</h4>
          <p className="p-3 fs-2 text-center">
            {
              "Failed to complete the payment process. Please review your payment details and try again."
            }
          </p>
        </div>
      )}
      <button
        className="btn-new btn-new--primary w-100 mt-3"
        onClick={this.props.onClose}
      >
        OK
      </button>
    </>
  );

  render = () => {
    return (
      <Dialog
        classes={{
          root: "at-confirmation_modal-responsive",
          paper: "at-confirmation_modal-paper",
        }}
        maxWidth={"sm"}
        open={this.props.open}
      >
        <AtlasCard
          className={"at-confirmation_card-responsive bg-white"}
          cardContent={this.renderCardContent()}
        />
      </Dialog>
    );
  };
}

export default PaymentGatewayFeedback;
