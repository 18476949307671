import React, { useState, useEffect } from "react";
import { debounce } from "lodash";

import DuoTab from "components/Tab/duo";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";

import "../index.scss";

const FormTabs = ["Contract View", "Form View"];

const EditFormModalContent = ({
  title,
  menuExpand,
  loading,
  tabContent,
  can_update,
  closedEditing,
  eProcessDisabledChecking,
  selectedSection,
  propertyContent,
  partyContent,
  formContent,
  formType,
  chartContent = "",
  isClaimed,
  signingContent,
  description,
  renderNavButton,
  closeFormModal,
  saveTnC,
  onChangeEdit,
  onChangeField,
}) => {
  const [switchMode, setSwitchMode] = useState(
    window.innerWidth > 1024 ? "Contract View" : "Form View",
  );

  useEffect(() => {
    const onChangeViewMode = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 1024) {
        if (switchMode === "Contract View") {
          setSwitchMode("Form View");
        }
      } else if (screenWidth > 1024) {
        if (switchMode === "Form View") {
          setSwitchMode("Contract View");
        }
      }
    };

    const debounceOnChangeView = debounce(onChangeViewMode, 400);

    window.addEventListener("resize", debounceOnChangeView);
    return () => window.removeEventListener("resize", debounceOnChangeView);
  }, [window.innerWidth]);

  const renderFormContent = () => {
    return (
      <>
        <div className="at-form__content at-new_container-20">
          <h5 className="at-card_content-title text-capitalize">{formType}</h5>
          <p className="at-content-subtitle">
            Enter the details of the form below. ‘Form View’ provides a
            simplified version of the contract allowing you to quickly enter
            details on your mobile phone.{" "}
          </p>
        </div>
        <DuoTab
          sections={FormTabs}
          selectedSection={switchMode}
          onSelectSection={(val) => {
            setSwitchMode(val);
            if (title.includes("Sale") && formType === "appointment letter") {
              onChangeEdit(
                "onAutoCalculateEarnestDepositPercentage",
                switchMode === "Contract View",
              );
            }
          }}
        />
        <div
          className="at-form__content at-form-tnc bg-white mb-3 p-3"
          style={{ borderRadius: "0.375rem" }}
        >
          {formContent(switchMode)}
          <div className="mt-3">
            <h2 className="at-form-input__title text-left">Remark:</h2>
            <CustomFormInput
              type={"textarea"}
              disabled={closedEditing}
              value={description}
              onChangeValue={(val) => onChangeField("description", val)}
              style={{ width: "100%", maxWidth: "100%", display: "block" }}
            />
          </div>
          <div
            className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}
          >
            <button
              className={`btn-new btn-new--success `}
              disabled={!can_update || eProcessDisabledChecking || isClaimed}
              onClick={saveTnC}
            >
              Save Terms {"&"} Conditions
            </button>
            {renderNavButton()}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton
          containerClass={"btn-close-edit-form"}
          onClick={closeFormModal}
        />
        <h5 className="at-card__title">{title}</h5>
      </div>
      {chartContent}
      {tabContent}
      {selectedSection === "Property" && propertyContent}
      {selectedSection === "Participants" && partyContent}
      {selectedSection === "T&C" && renderFormContent()}
      {selectedSection === "Contract Signing" && signingContent}
      {loading && <LoadingModal />}
    </>
  );
};

export default EditFormModalContent;
