import React, { Component } from "react";
import { IoMdAdd } from "react-icons/io";
import { compose } from "redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateDialog from "./Create";
import EditDialog from "./Edit";

import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import TemplateMngmtHOC from "./actions";
import { columnData } from "./asset";

class TemplateMngmt extends Component {
  componentDidMount = () => {
    this.props.getCategories();
    this.props.getTemplates(1, "");
  };

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Graphic Templates",
      this.props.data,
    );

    return (
      <AtPaginatedTable
        columns={columnData}
        rowData={this.props.templates.data ? this.props.templates.data : []}
        actionColumnContent={[
          ...(can_update
            ? [
                {
                  name: "edit",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) =>
                    this.props.getSelectedTemplate(rowData.id),
                },
              ]
            : []),
          ...(can_destroy
            ? [
                {
                  name: "delete",
                  color: "#F04438",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) => {
                    this.props.onChangeTemplateHOC(rowData, "selectedTemplate");
                    this.props.onChangeTemplateHOC(
                      true,
                      "showDeleteConfirmation",
                    );
                  },
                },
              ]
            : []),
        ]}
        meta={this.props.templates.meta && this.props.templates.meta}
        onChangeSearchParams={(val) =>
          this.props.onChangeTemplateHOC(val, "searchParams")
        }
        searchParams={this.props.searchParams}
        getListAPI={this.props.getTemplates}
        totalPages={this.props.templatePages}
      />
    );
  };

  render = () => {
    const {
      category,
      deleteTemplate,
      createTemplate,
      updateTemplate,
      selectedTemplate,
      showEditTemplateModal,
      showCreateTemplateModal,
      onChangeTemplateHOC,
      onLoadTemplate,
      showDeleteConfirmation,
    } = this.props;
    const { can_create } = permissionsChecker(
      "Graphic Templates",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={`Graphic Templates`}
          moduleIcon={"icon-graphic-studio"}
          description={
            <>
              All of the templates available for agents to use in the Graphics
              Studio are displayed below. To create a new template, click on the
              "+" icon. You can edit existing templates by clicking on the Edit
              icon beside the template
              <br />
              <br />
              To make the template visible to agents, click on the Edit template
              button and select the "Published" option.
            </>
          }
          actionButton={[
            {
              label: "New Template",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeTemplateHOC(true, "showCreateTemplateModal"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={showCreateTemplateModal}>
          <CustomCard
            className={"h-100"}
            containerStyle={{ overflowX: "hidden" }}
            cardContent={
              <CreateDialog
                category={category}
                onClickSubmit={createTemplate}
                showLoading={onLoadTemplate}
                onClickClose={() =>
                  onChangeTemplateHOC(false, "showCreateTemplateModal")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showEditTemplateModal}>
          <CustomCard
            className={"h-100"}
            containerStyle={{ overflowX: "hidden" }}
            cardContent={
              <EditDialog
                category={category}
                selectedTemplate={selectedTemplate}
                showLoading={onLoadTemplate}
                onClickSubmit={updateTemplate}
                onClickClose={() =>
                  onChangeTemplateHOC(false, "showEditTemplateModal")
                }
              />
            }
          />
        </AtlasDialog>
        <ConfirmationModal
          title={"Delete Template"}
          loading={onLoadTemplate}
          open={showDeleteConfirmation}
          message={`Are you sure you want to remove this template ?`}
          positiveAction={() => deleteTemplate(selectedTemplate.id)}
          negativeAction={() =>
            onChangeTemplateHOC(false, "showDeleteConfirmation")
          }
        />
        {onLoadTemplate && <LoadingModal />}
      </>
    );
  };
}

export default compose(TemplateMngmtHOC, VersionCheckHOC)(TemplateMngmt);
