import React, { Component } from "react";
import _ from "lodash";
import {
  Grid,
  SnackbarContent,
} from "@material-ui/core";
import { IoMdAlert } from "react-icons/io";
import { MdLiveHelp } from "react-icons/md";

import PartyCard from "components/Card/partyCard";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ESigningTips from "./tips";
import RefundForm from "./refundForm";
import ProcessSection from "./process";
import ESigningContent from "./history";

const tenancyArray = [
  { label: "Tenancy Agreement (Residential)", value: 1 },
  { label: "Tenancy Agreement (Residential for Expat)", value: 2 },
  { label: "Tenancy Agreement (Commercial)", value: 3 },
];

class EprocessContent extends Component {
  renderPendingProcessContent = () => {
    const { menuExpand, eProcessPartyData, onChangePartyHOC, renderNavButton } =
      this.props;

    return (
      <>
        {eProcessPartyData.length > 0 && (
          <div className="party-card_cont mb-3">
            {eProcessPartyData.map((partyData, index) => (
              <PartyCard
                key={index}
                containerClass={"party-card-new"}
                type={"confirmation"}
                index={index}
                data={partyData}
              />
            ))}
          </div>
        )}
        {eProcessPartyData.length < 1 && (
          <h5 className="at-form-input__title">
            Incomplete contract participants for each section to start the
            e-Signing process.
          </h5>
        )}
        <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
          {renderNavButton && renderNavButton()}
        </div>
      </>
    );
  };

  renderInitiatedProcessContent = () => {
    const {
      menuExpand,
      eProcessPartyData,
      refundStatus,
      signingStatus,
      signingStatusId,
      refundStatusId,
      onChangePartyHOC,
      refundFormData,
      selectedConfirmationForm,

      downloadCompletedForm,
      renderNavButton,
    } = this.props;

    const { id, transaction_number } = selectedConfirmationForm;

    let temp = _.filter(eProcessPartyData, (item) => item.status_id === 2);
    let tempRefund = _.filter(
      eProcessPartyData,
      (item) => item.refund_status_id === 2,
    );

    let tempPercentage = eProcessPartyData.length > 0 ? ((temp.length / eProcessPartyData.length) * 100) : 100;
    let tempRefundPercentage = eProcessPartyData.length > 0 ? ((tempRefund.length / eProcessPartyData.length) * 100) : 100;

    return (
      <>
        <div className="d-flex flex-wrap grid_gap-1 mb-3">
          {refundStatusId === `0` && (
            <button
              className={"btn-new btn-new--secondary"}
              onClick={() => onChangePartyHOC(true, "showRefundFormModal")}>
              View Refund Form
            </button>
          )}
          {(refundStatusId === `2` ||
            signingStatusId === `2`) && (
            <button
              className="btn-new btn-new--secondary"
              onClick={() =>
                downloadCompletedForm({
                  formId: signingStatusId === `2` ? id : refundFormData.id,
                  formType: signingStatusId === `2` ? 1 : 2,
                  filename: `${transaction_number}_e_process.pdf`,
                })
              }>
              {`Download ${refundStatusId === `2` ? "Refund" : "Confirmation"} Form`}
            </button>
          )}
          {(signingStatusId === "1" ||
            (signingStatusId === "3" && refundStatusId === "1")) && (
            <button
              className={"btn-new btn-new--secondary"}
              onClick={() => {
                this.props.getParty(this.props.selectedConfirmationForm.id);
                this.props.getSigningStatus(this.props.selectedConfirmationForm.id);
              }}>
              Check Status
            </button>
          )}
        </div>
        <Grid item md={12} xs={12}>
          <ProcessSection
            type={"confirmation"}
            status={signingStatus}
            tempPercentage={tempPercentage}
            selectedConfirmationForm={selectedConfirmationForm}
            eProcessPartyData={eProcessPartyData}
            onClickViewActivity={() =>
              this.props.onChangePartyHOC(true, "showSigningHistory")
            }
            onClickRefresh={() => {
              this.props.getParty(this.props.selectedConfirmationForm.id);
              this.props.getSigningStatus(this.props.selectedConfirmationForm.id);
            }}
          />
          {refundStatusId !== null && (
            <SnackbarContent
              style={{
                maxWidth: "100%",
                marginBottom: 20,
                backgroundColor: "#f6b13c",
              }}
              message={
                <div className={"d-flex align-items-center"}>
                  <IoMdAlert style={{ width: 40, marginRight: 10 }} />
                  <div className={"d-flex"}>
                    The e-Signing process to request the authorisation for
                    refund/forfeit the earnest deposit has been started
                  </div>
                </div>
              }
            />
          )}
          {refundStatusId !== null && (
            <ProcessSection
              type={"refund"}
              status={refundStatus}
              tempPercentage={tempRefundPercentage}
              selectedConfirmationForm={selectedConfirmationForm}
              eProcessPartyData={eProcessPartyData}
            />
          )}
          <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
            {renderNavButton && renderNavButton()}
          </div>
        </Grid>
      </>
    );
  };

  render = () => {
    const {
      banks,
      onLoadParty,
      eProcessContentId,
      eProcessPartyData,
      onLoadDraftEmail,
      onLoadConfirmationForm,
      onChangePartyHOC,
      selectedConfirmationForm,
      refundPartyInfo,
      refundFormData,
      onLoadRefund,
      getRefundForm,
      onChangeRefundHOC,

      showEsigningTipsModal,
      showSigningHistory,
      showRefundFormModal,
    } = this.props;
    return (
      <>
        <CustomButton
          containerClass={"mb-3"}
          className={"at-tip__button"}
          children={
            <>
              <MdLiveHelp />
              e-Signing Process Explain
            </>
          }
          onClick={() => onChangePartyHOC(true, "showEsigningTipsModal")}
        />
        {eProcessContentId === 1 && this.renderPendingProcessContent()}
        {eProcessContentId === 2 && this.renderInitiatedProcessContent()}
        {(onLoadConfirmationForm || onLoadParty) && <LoadingModal />}
        <AtlasDialog open={showSigningHistory}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <ESigningContent
                formId={selectedConfirmationForm.id}
                onChangePartyHOC={onChangePartyHOC}
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showRefundFormModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <RefundForm
                banks={banks}
                formId={selectedConfirmationForm.id}
                eProcessPartyData={eProcessPartyData}
                refundPartyInfo={refundPartyInfo}
                refundFormData={refundFormData}
                onLoadRefund={onLoadRefund}
                getRefundForm={getRefundForm}
                onChangeRefundHOC={onChangeRefundHOC}
                onClickClose={() =>
                  this.props.onChangePartyHOC(false, "showRefundFormModal")
                }
              />
            }
          />
        </AtlasDialog>
        {showEsigningTipsModal && (
          <ESigningTips
            onClose={() => onChangePartyHOC(false, "showEsigningTipsModal")}
          />
        )}
        {onLoadDraftEmail && <LoadingModal />}
      </>
    );
  };
}

export default EprocessContent;
