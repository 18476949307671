import { useState } from "react";
import { Card } from "@material-ui/core";

import EmailEditor from "./EmailEditor";
import EmailPreview from "./EmailPreview";

const Design = ({
  selectedEmailTemplate,
  
  updateEmailTemplate,
  onChangeEmailTemplatesHOC
}) => {
  const [showEmailPreview, setShowEmailPreview] = useState(true);

  const toggleEmailPreview = () => setShowEmailPreview(!showEmailPreview);

  return (
    <>
      {showEmailPreview && (
        <EmailPreview
          selectedEmailTemplate={selectedEmailTemplate}
          toggleEmailPreview={toggleEmailPreview}
          onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
        />
      )}
      {!showEmailPreview && (
        <EmailEditor
          selectedEmailTemplate={selectedEmailTemplate}
          toggleEmailPreview={toggleEmailPreview}
          updateEmailTemplate={updateEmailTemplate}
        />
      )}
    </>
  );
};

export default Design;
