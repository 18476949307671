import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ImCoinDollar } from "react-icons/im";
import { IoIosWallet } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import TooltipWrapper from "components/TooltipWrapper";
import AtlasIcon from "components/Icon/atlasIcon";

import isEmptyValue from "utils/isEmpty";
import { getColorBadge } from "dictionary/badgeColor";
import defaultImage from "assets/images/iqi_logo_only.png";

import "../../index.scss";

const SingleOrderStatus = [
  { label: "Pending", id: 0, colorName: "Yellow" },
  { label: "Processing", id: 1, colorName: "Violet" },
  { label: "Arrived", id: 2, colorName: "Green" },
  { label: "Collected", id: 3, colorName: "Yellow" },
  { label: "Rejected", id: 4, colorName: "Red" },
  { label: "Voided", id: 5, colorName: "Red" },
  { label: "Cancelled", id: 6, colorName: "Red" },
  { label: "To Pay", id: 7, colorName: "Cyan" },
];

const CustomContent = ({
  toPayId,
  data,
  headerData,
  can_create,
  can_update,
  statusDictionary,
  onChangeOrderHistoryHOC,
  onToggleResubmit,
  redoPayment,
}) => {
  const [viewMode, setViewMode] = useState(
    window.innerWidth >= 768 ? "desktop" : "mobile",
  );
  const [showItems, setShowItems] = useState(true);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => setViewMode(window.innerWidth >= 768 ? "desktop" : "mobile"),
      false,
    );
    const pathname = window.location.href;
    const checkoutStatusChecking =
      pathname.indexOf("/fail?") > -1 || pathname.indexOf("/success?") > -1;
    const tmpId = pathname.split("order_id=")[1];

    if (
      toPayId === data.id ||
      (checkoutStatusChecking && `${data.id}` === tmpId)
    ) {
      setShowItems(true);
    }
  }, []);

  const renderDesktopView = () => {
    return (
      <div className="at-order_history__container-desktop">
        <ul className="at-order_history-table_content_cont">
          {headerData.map((header) => (
            <li
              key={header.label.trim().replace(" ", "")}
              className={`at-order_history-table_content ${header.columnClass || ""}`}
              style={{ width: "25%", ...header.style }}
            >
              {header.value && <p>{data[header.value]}</p>}
              {header.renderColumn &&
                header.renderColumn({
                  data,
                  showItems,
                  can_create,
                  onToggleExpand: () =>
                    setShowItems((prevShowItems) => !prevShowItems),
                  onChangeOrderHistoryHOC,
                })}
            </li>
          ))}
          <li className="at-order_history-table_content at-order_history-table_content_action">
            <button
              style={{
                visibility: data.status_id === 0 ? "visible" : "hidden",
              }}
              disabled={!can_create || data.status_id !== 0}
              className={"btn-new-sm btn-new--primary d-inline-block py-2 mr-2"}
              onClick={() => redoPayment(data)}
            >
              <AtlasIcon
                svgHref={"atlas-wallet-add-1"}
                style={{ width: 20, height: 20, fill: "white", marginRight: 5 }}
              />
              Pay
            </button>
            <button
              style={{
                height: "fit-content",
                visibility: data.status_id === 0 ? "visible" : "hidden",
              }}
              disabled={!can_update || data.status_id !== 0}
              className={
                "btn-new btn-new--outline-danger d-inline-block py-2 mr-2"
              }
              onClick={() =>
                Promise.all([
                  onChangeOrderHistoryHOC(data, "selectedOrderHistory"),
                ]).then(() =>
                  onChangeOrderHistoryHOC(
                    true,
                    "showCancelPaymentConfirmation",
                  ),
                )
              }
            >
              Cancel
            </button>
          </li>
        </ul>
        <div
          className={`at-order_history-tickets_cont ${showItems ? "at-show" : ""}`}
        >
          {renderItems()}
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    const {
      key,
      status_id,
      order_number = "",
      total_amount = 0,
      market_order_items = [],
      created_at,
    } = data;

    const { colorName = "", label } =
      _.find(statusDictionary, { id: status_id }) || {};

    let tempTotalPrice = 0;

    if (market_order_items.length > 0) {
      market_order_items.map((item) => {
        tempTotalPrice = +item.price * item.quantity;
      });
    }

    return (
      <div className="at-order_history__container-mobile" key={key}>
        <div className="at-order_history-table_content_cont w-100">
          <div className="at-order_history-top_content">
            <div className="d-flex align-items-start">
              <AtlasIcon
                svgHref={"atlas-arrow-circle-down"}
                className={`${showItems ? "at-chevron_up" : `at-chevron_down`}`}
                onClick={() => setShowItems((prevShowItems) => !prevShowItems)}
              />
              <div className="d-flex flex-column">
                <label>#{order_number}</label>
                <p style={{ color: "#6B7280" }}>{created_at}</p>
              </div>
              <span
                className="at-status_badge"
                style={{ marginLeft: 8, ...getColorBadge(colorName) }}
              >
                {label}
              </span>
            </div>
            <div className="d-flex g-3 mt-2" style={{ marginLeft: 28 }}>
              <p>
                <AtlasIcon
                  svgHref={"atlas-box"}
                  className="at-order_history-icon"
                />
                {`${market_order_items.reduce((acc, item) => acc + item.quantity, 0)} items`}
              </p>
              <p>
                <AtlasIcon
                  svgHref={"atlas-coin-1"}
                  className="at-order_history-icon"
                />
                {`RM ${total_amount.toFixed(2)}`}
              </p>
            </div>
            {status_id === 0 && (
              <div className="d-flex g-3 mt-3">
                <button
                  disabled={!can_create}
                  className={"btn-new-sm btn-new--primary w-100"}
                  onClick={() => redoPayment(data)}
                >
                  <AtlasIcon
                    svgHref={"atlas-wallet-add-1"}
                    style={{
                      width: 20,
                      height: 20,
                      fill: "white",
                      marginRight: 5,
                    }}
                  />
                  Pay
                </button>
                <button
                  disabled={!can_update}
                  className={"btn-new-sm btn-new--outline-danger w-100"}
                  onClick={() =>
                    Promise.all([
                      onChangeOrderHistoryHOC(data, "selectedOrderHistory"),
                    ]).then(() =>
                      onChangeOrderHistoryHOC(
                        true,
                        "showCancelPaymentConfirmation",
                      ),
                    )
                  }
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div
            className={`at-order_history-tickets_cont ${showItems ? "at-show" : ""}`}
          >
            {renderItems()}
          </div>
        </div>
      </div>
    );
  };

  const renderItems = () => {
    const { market_order_items = [] } = data;

    return (
      <ul className="at-atlas_store_ticket-container">
        {market_order_items.map((item, index) => {
          const {
            price,
            remark,
            quantity,
            status_id,
            can_manage_tickets,
            selected_market_product_attached_product: product,
            selected_market_product_attached_product_type: product_type,
          } = item;

          const { name, type_id, photo_url, unit_measurement } =
            item.market_product;

          const ticket_title =
            !isEmptyValue(product) && product_type === "TicketPricing"
              ? ` (${product.title})`
              : "";
          const product_variant_title =
            !isEmptyValue(product) && product.options
              ? product.options.map(
                  (option, index) =>
                    `${option.value}${index + 1 !== product.options.length ? " - " : ""}`,
                )
              : "";
          const { colorName = "", label } =
            _.find(SingleOrderStatus, { id: status_id }) || {};

          return (
            <li
              key={index}
              className={`at-atlas_store_ticket_table-item_cont at-coupon__item`}
            >
              <div className="at-atlas_store_ticket_table-item_left">
                <div className="top"></div>
                <div className="rip"></div>
                <div className="bottom"></div>
              </div>
              <div className="at-atlas_store_ticket_table-item_right"></div>
              <div className="at-atlas_store_ticket_table-content">
                <img
                  className={"at-order_history-item-img"}
                  style={{ objectFit: "cover" }}
                  src={photo_url || defaultImage}
                />
                <div className="at-coupon__details-cont">
                  <h4 style={{ fontSize: 14 }}>{`${name}${ticket_title}`}</h4>
                  {product_type === "ProductVariant" && (
                    <p>{product_variant_title}</p>
                  )}
                  <p style={{ color: "#C2410C" }}>{`RM ${price}`}</p>
                  <p
                    style={{ color: "#6B7280" }}
                  >{`${quantity} x ${!_.isEmpty(unit_measurement) ? unit_measurement : "unit"}`}</p>
                  {status_id === 3 && product_type === "TicketPricing" && (
                    <button
                      style={{ fontSize: 12 }}
                      className={"btn-new-sm btn-new--primary py-2 ml-auto"}
                      onClick={() =>
                        Promise.all([
                          onChangeOrderHistoryHOC(item, "selectedItem"),
                        ]).then(() => {
                          onChangeOrderHistoryHOC(true, "showManageTicket");
                        })
                      }
                    >
                      {can_manage_tickets ? "Manage Ticket" : "View"}
                    </button>
                  )}
                  {(status_id !== 3 || product_type !== "TicketPricing") && (
                    <p
                      className="at-order-status_badge"
                      style={{ ...getColorBadge(colorName) }}
                    >
                      {status_id === 4 && remark && (
                        <TooltipWrapper
                          title={remark}
                          child={
                            <AiOutlineExclamationCircle
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 5,
                                color: getColorBadge(colorName).color,
                              }}
                            />
                          }
                        />
                      )}
                      {label}
                    </p>
                  )}
                  {[1, 2].indexOf(type_id) > -1 && status_id === 4 && (
                    <button
                      style={{ fontSize: 12 }}
                      className={"btn-new-sm btn-new--primary py-2 mr-2"}
                      onClick={() => onToggleResubmit(item)}
                    >
                      Resubmit
                    </button>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {viewMode === "desktop" && renderDesktopView()}
      {viewMode === "mobile" && renderMobileView()}
    </>
  );
};

export default CustomContent;
