import React, { Component } from "react";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import UploadDocsImage from "assets/images/file.png";

import "stylesheets/components/upload/index.scss";
import "./index.scss";

class Upload extends Component {
  state = {
    imageUrl: "",
  };

  fileInput = null;

  fileInputRef = (element) => {
    this.fileInput = element;
  };

  componentDidMount = () => {
    if(!this.state.imageUrl && this.props.imageUrl) {
      this.setState({
        imageUrl: this.props.imageUrl,
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageUrl: this.props.imageUrl,
      });
    }

    return true;
  }

  handleChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (this.props.uploadType === "video") {
        this.props.uploadVideo(file, URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        const filename = file.name;
        reader.onload = (e) => {
          this.setState({ imageUrl: e.target.result }, () => this.props.uploadImage(e.target.result, filename));
        };
        reader.readAsDataURL(file);
      };
    };
  }

  removeImage = () => {
    this.setState({ imageUrl: "" }, () => {
      if (this.props.uploadType === "video") {
        this.props.uploadVideo("", "");
      } else {
        this.props.uploadImage("", "");
      }
    });
  }

  render() {
    const {
      containerClass = "",
      containerStyle,
      className = "",
      uploadType,
      disabled,
      accepts,
      style,
      description,
      required
    } = this.props;

    return (
      <>
        {(uploadType !== "docs" && !this.props.imageUrl && !this.props.videoUrl) && (
          <div
            className={`at-image-upload ${containerClass}`}
            style={{
              width: "100%",
              height: 196,
              textAlign: "center",
              ...containerStyle,
            }}
            onClick={() => this.fileInput?.click()}
          >
            <input
              disabled={disabled}
              id={"fileInputID"}
              ref={this.fileInputRef}
              type={"file"}
              onClick={() =>
                (document.getElementById("fileInputID").value = "")
              }
              onChange={this.handleChange}
              style={{ display: "none" }}
              accept={accepts || "application/pdf,image/png,image/jpeg"}
            />
            <EmptyState
              renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
              title={"Click to upload"}
              description={description || "PNG, JPG, JPEG"}
            />
          </div>
        )}
        {(uploadType === "docs" || this.props.imageUrl || this.props.videoUrl) && (
          <div className="welcome-header">
            <div
              className={`at-image-upload ${containerClass}`}
              style={{
                width: "100%",
                height: 196,
                textAlign: "center",
                ...containerStyle,
              }}
              onClick={() => this.fileInput?.click()}
            >
              <input
                disabled={disabled}
                id={"fileInputID"}
                ref={this.fileInputRef}
                type={"file"}
                onClick={() =>
                  (document.getElementById("fileInputID").value = "")
                }
                onChange={this.handleChange}
                style={{ display: "none" }}
                accept={accepts || "application/pdf,image/png,image/jpeg"}
              />
              {uploadType === "docs"
                ? (
                  <img
                    className={className}
                    style={{
                      marginRight: 0,
                      ...style,
                    }}
                    alt="Upload Docs"
                    src={UploadDocsImage}
                  />
                )
                : (this.state.imageUrl
                    ? (
                      <img
                        className={className}
                        style={{
                          marginRight: 0,
                          objectPosition: "top",
                          objectFit: "contain",
                          height: "100%",
                          ...style,
                        }}
                        alt="Profile Avatar"
                        src={this.state.imageUrl}
                      />
                    )
                    : (this.props.videoUrl
                        ? (
                          <video
                            src={this.props.videoUrl}
                            className={className}
                            style={{
                              width: '100%',
                              height: 'auto',
                              aspectRatio: '16 / 9',
                              backgroundColor: '#000',
                              ...style
                            }}
                          >
                            This browser does not support the video tag.
                          </video>
                        ) 
                        : (
                          <EmptyState
                            renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
                            title={"Click to upload"}
                            description={description || "PNG, JPG, JPEG"}
                          />
                        )
                    )
                )
              }
            </div>
            <div
              className="upload-icon-container"
              style={{ position: "absolute" }}
            >
              <AtlasIcon
                svgHref={"atlas-close-circle"}
                className={"upload-atlas-icon"}
                style={{ cursor: "pointer" }}
                onClick={this.removeImage}
              />
            </div>
          </div>
        )}
        {required && (
          <div className="at-input_footer">
            <div className="at-form-input__required">required *</div>
          </div>
        )}
      </>
    );
  }
}

export default Upload;