import React, { Component } from "react";

import { requestError } from "utils/requestHandler";
import LoadingModal from "components/LoadingModal";

import { Get } from "utils/axios";

const BranchesHOC = (WrappedComponent) => {
  class BranchesComponent extends Component {
    state = {
      branches: [],

      showLoadingModal: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });

    getBranches = () =>
      Get(
        `/branches`,
        this.getBranchesSuccess,
        this.getBranchesError,
        this.load,
      );
    getBranchesSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            value: item.id,
            label: item.name,
          };
          tmpArray.push(data);
        });
        return this.setState({ branches: tmpArray });
      }
    };
    getBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            branches={this.state.branches}
            getBranches={this.getBranches}
          />
          {this.state.showLoadingModal && <LoadingModal />}
        </>
      );
    };
  }
  return BranchesComponent;
};

export default BranchesHOC;
