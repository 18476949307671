import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    savePaymentApproval = (data) =>
      Post(
        `/payments`,
        data,
        this.savePaymentApprovalSuccess,
        this.savePaymentApprovalError,
        this.load,
      );
    savePaymentApprovalSuccess = (payload) => {};
    savePaymentApprovalError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            savePaymentApproval={this.savePaymentApproval}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
