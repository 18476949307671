import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import AtlasButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

import ExportHOC from "./actions/export";

const DatepickerInput = ({ ...props }) => (
  <input
    type="text"
    style={{ cursor: "pointer", width: "100%", backgroundColor: "#ffff" }}
    width={"100%"}
    {...props}
    readOnly
  />
);

const Statistics = ({ exportStats, onClose, onLoadExport }) => {
  const [dataToSubmit, setDataToSubmit] = useState({
    start_date: "",
    end_date: "",
  });

  const disableExport = useMemo(() => {
    return _.values(dataToSubmit).some((item) => !item);
  }, [dataToSubmit]);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h4 className="at-card__title">Export Statistics</h4>
      </div>
      <div className={"at-card mb-20"}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div style={{ minWidth: 300 }}>
              <label>From Date</label>
              <div className="at-export-datepicker">
                <DatePicker
                  dateFormat={"DD MMM YYYY"}
                  className="form-control at-training-datepicker mt-10"
                  value={dataToSubmit.start_date}
                  customInput={<DatepickerInput />}
                  onChange={(val) => {
                    setDataToSubmit((prev) => ({
                      ...prev,
                      start_date: Moment(val).format("DD MMM YYYY"),
                    }));
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ minWidth: 300 }}>
              <label>To Date</label>
              <div className="at-export-datepicker">
                <DatePicker
                  dateFormat={"DD MMM YYYY"}
                  className="form-control at-training-datepicker mt-10"
                  value={dataToSubmit.end_date}
                  customInput={<DatepickerInput />}
                  onChange={(val) => {
                    setDataToSubmit((prev) => ({
                      ...prev,
                      end_date: Moment(val).format("DD MMM YYYY"),
                    }));
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="d-flex mt-20">
          <AtlasButton
            disabled={disableExport}
            className="btn-new btn-new--success"
            children={"Export"}
            onClick={() => {
              exportStats(dataToSubmit);
            }}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </div>
      {onLoadExport && <LoadingModal />}
    </>
  );
};

export default ExportHOC(Statistics);
