import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { getMyCart } from "actions/myCart";
import { Get, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Product Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      searchParams: searchParams,
      showNameCardModal: false,

      showAddToCartConfirmation: false,

      products: [],
      productPage: null,
      selectedProduct: {
        price: 0,
        description: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeProductCatalogHOC = (val, context) =>
      this.setState({ [context]: val });

    getProductCatalog = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/market_products?${search}page=${page}`,
        this.getProductCatalogSuccess,
        this.getProductCatalogError,
        this.load,
      );
    };
    getProductCatalogSuccess = (payload) => {
      let temp = [];
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }

      temp = payload.data.map((item) => {
        return {
          ...item,
          ...(item.product_variants && {
            product_variants: item.product_variants.map((variant) => {
              return {
                ...variant,
                title: variant.options.map(
                  (option, index) =>
                    `${option.value}${index + 1 !== variant.options.length ? " - " : ""}`,
                ),
              };
            }),
          }),
        };
      });

      this.setState({
        products: {
          data: temp,
          meta: payload.meta,
        },
        productPage: tmpTotalPages,
      });
    };
    getProductCatalogError = (error) => requestError(error);

    addToCart = (selectedProduct, value, orderItem) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      const renderData = () => {
        if (selectedProduct.type_id === 3) {
          return {
            ticket_pricing_id: orderItem.id != 0 ? orderItem.id : null,
            ticket_title: orderItem.title != "" ? orderItem.title : null,
            ticket_price: orderItem.price != null ? orderItem.price : null,
          };
        } else if (selectedProduct.type_id === 2) {
          return { product_variant: orderItem };
        } else {
          return "";
        }
      };

      let temp = {
        quantity: value,
        market_product_id: selectedProduct.id,
        user_id: id,
        data: renderData(),
        selected_market_product_attached_product_id: orderItem.id,
      };
      Post(
        `/market_carts`,
        temp,
        this.addToCartSuccess,
        this.addToCartError,
        this.load,
      );
    };
    addToCartSuccess = (payload) => {
      this.props.getMyCart(1, "");

      this.setState({
        showAddToCartConfirmation: false,
        showNameCardModal: false,
      });
      requestSuccess(payload.message);
    };
    addToCartError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            products={this.state.products}
            searchParams={this.state.searchParams}
            productPage={this.state.productPage}
            selectedProduct={this.state.selectedProduct}
            showNameCardModal={this.state.showNameCardModal}
            showAddToCartConfirmation={this.state.showAddToCartConfirmation}
            onLoadProductCatalog={this.state.loading}
            addToCart={this.addToCart}
            onChangeProductCatalogHOC={this.onChangeProductCatalogHOC}
            getProductCatalog={this.getProductCatalog}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getMyCart,
  })(WithHOC);
};

export default HOC;
