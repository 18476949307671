import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import { RiArrowDownSFill } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { AiOutlineUnorderedList } from "react-icons/ai";

import AtlasDialog from "components/Dialog";
import RecommendationCard from "components/Card/RecommendationCard";
import EmptyRecommendationCard from "components/Card/RecommendationCard/empty";
import AtlasCard from "components/Card";
import RecommendationList from "./RCCModalContent";

import useOnScreen from "utils/hooks/useOnScreen";

export default function Recommend({
  data,
  initialRecommendation = [],
  fullRecommendationList,
}) {
  const [showCollapse, onToggleCollapse] = useState(true);
  const [showFullRecommendation, onToggleRecommendation] = useState(false);

  return (
    <>
      <div className="at-subsales_listing__view-recommendation at-form__content">
        <p className="at-subsales_listing__view-recommendation_title">
          Co-broke Recommendations Found
          <RiArrowDownSFill
            style={{ transform: `rotate(${showCollapse ? 0 : 180}deg)` }}
            onClick={() => onToggleCollapse((prev) => !prev)}
          />
        </p>
        <p className="at-card__description">
          The Atlas Co-broke Recommendation Engine has found the following
          agents who have executed similar transactions to the property in this
          listing. The potential co-broke agents may have other buyers or
          tenants for a similar property. Review their details and click
          <strong>{` Send Request `}</strong>
          to send them a request and see if they are able to co-broke with you
          on this listing.
        </p>
        <p className="at-card__description mt-10 mb-10">
          Click the
          <strong>{` View All `}</strong>
          button to view all of the co-broke recommendations.
        </p>
        <Collapse in={showCollapse} timeout="auto" unmountOnExit>
          <ScrollContent
            showCollapse={showCollapse}
            fullRecommendationList={fullRecommendationList}
            initialRecommendation={initialRecommendation}
            onToggleRecommendation={onToggleRecommendation}
          />
        </Collapse>
      </div>
      <AtlasDialog open={showFullRecommendation}>
        <AtlasCard
          className={"h-100"}
          cardContent={
            <RecommendationList
              data={data}
              fullRecommendationList={fullRecommendationList}
              onClose={() => onToggleRecommendation(false)}
            />
          }
        />
      </AtlasDialog>
    </>
  );
}

const ScrollContent = ({
  showCollapse,
  fullRecommendationList,
  initialRecommendation,
  onToggleRecommendation,
}) => {
  const [showPrevNav, onShowLeftNav] = useState(false);
  const [showNextNav, onShowRighNav] = useState(false);

  const recommedationScroll = useRef(null);
  const firstElementRef = useRef(null);
  const lastElementRef = useRef(null);

  const isShowingFirst = useOnScreen(firstElementRef);
  const isShowingLast = useOnScreen(lastElementRef);

  useEffect(() => {
    onShowRighNav(!isShowingFirst);
  }, [isShowingFirst]);

  useEffect(() => {
    onShowLeftNav(!isShowingLast);
  }, [isShowingLast]);

  return (
    <>
      <div className="at-subsales_listing__view-recommendation_body">
        <div
          className="at-subsales_listing__view-recommendation_body-scroll"
          ref={recommedationScroll}
        >
          {initialRecommendation.map((item, index) => (
            <>
              {index === 0 && (
                <RecommendationCard
                  key={item.id}
                  showActionButton={false}
                  refProps={firstElementRef}
                  {...item}
                />
              )}
              {index !== 0 && (
                <RecommendationCard
                  key={item.id}
                  showActionButton={false}
                  {...item}
                />
              )}
            </>
          ))}
          <EmptyRecommendationCard
            refProps={lastElementRef}
            onClick={() => onToggleRecommendation(true)}
            totalRecommendation={fullRecommendationList.length}
          />
        </div>
        <button
          className={"btn-new btn-new--primary mt-3"}
          onClick={() => onToggleRecommendation(true)}
        >
          View All
          <i
            style={{ fontWeight: 600, fontSize: 18 }}
          >{` ${fullRecommendationList.length} `}</i>
          Recommendations
          <AiOutlineUnorderedList style={{ width: 15, marginLeft: 10 }} />
        </button>
      </div>
      {showCollapse && (
        <>
          <IoIosArrowDropleftCircle
            className={`color-primary ${showPrevNav ? "d-inital" : "d-none"}`}
            style={{
              position: "absolute",
              width: "2.5rem",
              top: "10%",
              right: 10,
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onClick={() => (recommedationScroll.current.scrollLeft += 800)}
          />
          <IoIosArrowDropleftCircle
            className={`color-primary ${showNextNav ? "d-inital" : "d-none"}`}
            style={{
              position: "absolute",
              width: "2.5rem",
              top: "10%",
              left: 20,
              cursor: "pointer",
            }}
            onClick={() => (recommedationScroll.current.scrollLeft += -800)}
          />
        </>
      )}
    </>
  );
};
