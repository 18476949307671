import React, { Component } from "react";

import AtlasTextarea from "components/Input/textarea";
import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";

class RejectModal extends Component {

  render = () => {
    const {
      open,
      onLoadUnlock,
      rejectReason,
      onChangeUnlockHOC,
      updateUnlockRequest,
      selectedUnlockRequest,
      selectedConfirmationForm,
    } = this.props;

    if (open) {
      return (
        <ModalDialog
          title={'Reject Confirmation Form Unlock Request'}
          onLoad={onLoadUnlock}
          onClose={() => onChangeUnlockHOC(false, "showRejectRequestModal")}
          children={
            <>
              <h2 className="at-form-input__title">Reject reason</h2>
              <AtlasTextarea
                value={rejectReason}
                onChangeValue={(val) => onChangeUnlockHOC(val, "rejectReason")} />
            </>
          }
          footer={
            <div className="d-flex align-items-center g-3">
              <AtlasButton
                className={"btn-new btn-new--primary"}
                children={"Submit"}
                onClick={() =>
                  updateUnlockRequest(
                    {
                      status_id: 3,
                      remark: selectedUnlockRequest.remark,
                      reject_reason: rejectReason,
                    },
                    selectedConfirmationForm.id,
                    selectedUnlockRequest.id,
                  )
                } />
              <AtlasButton
                className={"btn-new btn-new--outline-secondary"}
                children={"Cancel"}
                onClick={() => onChangeUnlockHOC(false, "showRejectRequestModal")} />
            </div>
          } />
      )
    }
  };
}

export default RejectModal;
