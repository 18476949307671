import React, { useState, useEffect } from "react";
import _ from "lodash";
import { MenuItem, Collapse, IconButton } from "@material-ui/core";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import CustomFormInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import AtlasBackButton from "components/Button/prev";
import CustomCard from "components/Card";

import { numberWithCommas } from "utils/thousandSeparator";

const DetailField = [
  { label: "Block Name", value: "block_name" },
  { label: "Unit Type", value: "unit_type" },
  { label: "Floor No.", value: "floor_no" },
  { label: "Floor Size.", value: "floor_size" },
  { label: "Built Up Size.", value: "built_up" },
  { label: "Bathroom No.", value: "bathroom" },
  { label: "Bedroom No.", value: "bedroom" },
  { label: "Selling Price", value: "price" },
];

const BuyerField = [
  { label: "Full Name", value: "buyer_full_name" },
  { label: "Address", value: "address" },
  { label: "Identity Type", value: "identity_type" },
  { label: "Identity No.", value: "ic_number" },
  { label: "Email", value: "email" },
  { label: "Contact No.", value: "contact_no" },
  { label: "Postcode", value: "postcode" },
  { label: "Country", value: "country" },
];

const AdditionalBuyer = [
  { label: "Name", value: "email" },
  { label: "Address", value: "address" },
  { label: "Identity Type", value: "identity_type" },
  { label: "Identity No.", value: "ic_number" },
  { label: "Email", value: "email" },
  { label: "Contact No.", value: "email" },
];

const AgentField = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Contact No.", value: "phone_number" },
  { label: "Team", value: "team" },
];

const LoanField = [
  {
    label: "Repayment Term",
    type: "select",
    value: "repayment_term",
    options: [
      { value: 1, label: "1 year" },
      { value: 3, label: "3 year" },
      { value: 5, label: "5 year" },
      { value: 7, label: "7 year" },
      { value: 9, label: "9 year" },
      { value: 10, label: "10 year" },
      { value: 12, label: "12 year" },
      { value: 15, label: "15 year" },
      { value: 20, label: "20 year" },
    ],
    className: "col-md-6 mb-20",
  },
  {
    label: "Repayment Currency",
    type: "select",
    value: "repayment_currency",
    className: "col-md-6",
  },
  {
    label: "Annual Interest (%)",
    type: "text",
    numericOnly: true,
    placeholder: "Annual Interest (%)",
    value: "interest_rate",
    maxLength: 4,
    className: "col-md-6",
  },
  {
    label: "Down Payment Percentage (%)",
    type: "text",
    numericOnly: true,
    placeholder: "Down Payment Percentage (%)",
    value: "down_payment_percentage",
    maxLength: 4,
    className: "col-md-6",
  },
  {
    label: "Monthly Installment",
    type: "text",
    numericOnly: true,
    disabled: true,
    placeholder: "Monthly installment",
    value: "monthly_installment",
    className: "col-md-6",
  },
  {
    label: "Total Payment",
    type: "text",
    numericOnly: true,
    placeholder: "Total Payment",
    value: "total_payment",
    disabled: true,
    className: "col-md-6",
  },
];

const UnitDetail = ({
  data,
  selectedUnit,
  selectedProject,
  selectedReservation,

  getReservation,
  onClose,
}) => {
  const [showCalculator, setShowCalculator] = useState(true);
  const [loanAttribute, setLoanAttribute] = useState({
    total_payment: 0,
    monthly_payment: 0,
    down_payment_percentage: 0,
    interest_rate: 0,
    repayment_currency: 0,
    repayment_term: 1,
  });

  useEffect(() => {
    let currencyIndex = _.findIndex(LoanField, (item) => {
      return item.value === "repayment_currency";
    });
    LoanField[currencyIndex].options = data.dictionaryReducer.currencies.name;

    if (
      selectedUnit.reservations_attributes &&
      selectedUnit.reservations_attributes.id
    ) {
      getReservation(selectedUnit);
    }
  }, []);

  useEffect(() => {
    let downPayment =
      selectedUnit.price * (loanAttribute.down_payment_percentage / 100);
    let totalPayment = selectedUnit.price - downPayment;
    let paymentYear = loanAttribute.repayment_term * 12;
    let interest = loanAttribute.interest_rate / 100 / 12;
    let x = Math.pow(1 + interest, paymentYear);
    let monthly = (totalPayment * x * interest) / (x - 1);
    setLoanAttribute({
      ...loanAttribute,
      monthly_installment: monthly ? numberWithCommas(monthly.toFixed(2)) : "0",
      total_payment:
        monthly && paymentYear
          ? numberWithCommas((monthly * paymentYear).toFixed(2))
          : "0",
    });
  }, [
    loanAttribute.repayment_term,
    loanAttribute.down_payment_percentage,
    loanAttribute.interest_rate,
  ]);

  const onChangeFields = (context, val) => {
    let temp = _.cloneDeep(loanAttribute);
    temp[context] = val;
    setLoanAttribute(temp);
  };

  const StatusColor = () => {
    switch (selectedUnit.reservations_attributes.status) {
      case "Available":
        return "#57cf57";
      case "Reserved":
        return "#ffdf05";
      case "Booked":
        return "#ff6767";
      case "Sold":
        return "#ff6767";
      default:
        return "#878787";
    }
  };

  return (
    <>
      {onClose && (
        <AtlasBackButton
          containerStyle={{
            position: "absolute",
            left: 10,
            top: 10,
          }}
          onClick={() => onClose()}
        />
      )}
      <h3 className="at-card__title text-center">{`Unit Details ${selectedUnit.unit_no}`}</h3>
      <hr />
      <CustomCard
        containerStyle={{ backgroundColor: "white" }}
        cardContent={
          <div className="text-center">
            <img
              style={{ height: 200 }}
              src={selectedUnit.image_url}
              alt={selectedUnit.title}
            />
            <div
              className="at-project-unit__detail-status"
              style={{ backgroundColor: StatusColor() }}
            >
              {selectedUnit.reservation_status}
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="at-project-unit__detail">
                  Project:
                  <span>{selectedProject.internal_name}</span>
                </p>
              </div>
              {DetailField.map((item) => (
                <div className="col-md-6">
                  <p className="at-project-unit__detail">
                    {item.label}
                    <span>
                      {item.value === "price"
                        ? numberWithCommas(selectedUnit[item.value])
                        : selectedUnit[item.value]}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        }
      />
      {selectedUnit.reservations_attributes &&
        selectedUnit.reservations_attributes.status !== "Available" && (
          <CustomCard
            containerStyle={{ backgroundColor: "white", marginTop: 20 }}
            cardContent={
              <>
                <h5 style={{ fontSize: 18, fontWeight: 500 }}>
                  Buyer Information
                </h5>
                <div className="row px-2 m-3">
                  {BuyerField.map((item) => (
                    <div className="col-md-6">
                      <p className="at-project-unit__detail">
                        {item.label}
                        <span>{selectedReservation[item.value]}</span>
                      </p>
                    </div>
                  ))}
                  {selectedReservation.buyers &&
                    selectedReservation.buyers.length > 0 && (
                      <div className="col-md-6">
                        {AdditionalBuyer.map((item, index) => (
                          <p
                            className="at-project-unit__detail"
                            style={{ display: "block" }}
                          >
                            {`Additional Buyer ${index + 1}`}
                            <div>
                              <p
                                style={{
                                  display: "flex",
                                  fontWeight: 500,
                                  fontSize: 12,
                                }}
                              >
                                {item.label}
                                <span>
                                  {selectedReservation.buyers[item.value]}
                                </span>
                              </p>
                            </div>
                          </p>
                        ))}
                      </div>
                    )}
                  <div className="col-md-6">
                    <p
                      className="at-project-unit__detail"
                      style={{ display: "block" }}
                    >
                      Agent Information
                      {selectedReservation.agent &&
                        AgentField.map((item) => (
                          <div>
                            <p
                              style={{
                                display: "flex",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              {item.label}
                              <span>
                                {selectedReservation.agent[item.value]}
                              </span>
                            </p>
                          </div>
                        ))}
                    </p>
                  </div>
                </div>
              </>
            }
          />
        )}
      <CustomCard
        containerStyle={{
          backgroundColor: "white",
          marginTop: 20,
        }}
        cardContent={
          <>
            <button
              className={"btn my-3 w-100 p-0 text-left"}
              onClick={() => setShowCalculator(!showCalculator)}
            >
              <h5 style={{ fontSize: 18, fontWeight: 500 }}>
                {"Loan Calculator"}
                <IconButton
                  style={{ color: "#f18e06", padding: 5, marginLeft: 10 }}
                >
                  {showCalculator ? (
                    <MdKeyboardArrowUp size={"16px"} />
                  ) : (
                    <MdKeyboardArrowDown size={"16px"} />
                  )}
                </IconButton>
              </h5>
            </button>
            <Collapse in={showCalculator} timeout="auto" unmountOnExit>
              <div className="row px-2">
                {LoanField.map((item) => (
                  <>
                    {(item.type === "text" || item.type === "number") && (
                      <div className={item.className}>
                        <h2 className="at-project-unit__reservation_title">
                          {item.label}
                        </h2>
                        <CustomFormInput
                          type={item.type}
                          value={
                            loanAttribute[item.value]
                              ? loanAttribute[item.value]
                              : ""
                          }
                          numericOnly={item.numericOnly}
                          disabled={item.disabled}
                          maxLength={item.maxLength}
                          onChangeValue={(val) =>
                            onChangeFields(item.value, val)
                          }
                          required={true}
                          inputError={item.error}
                          placeholder={item.placeholder}
                        />
                      </div>
                    )}
                    {item.type === "select" && (
                      <div className={item.className}>
                        {item.label && (
                          <h2 className="at-project-unit__reservation_title mb-2">
                            {item.label}
                          </h2>
                        )}
                        <AtSelectNew
                          required={true}
                          value={loanAttribute[item.value]}
                          placeholder={item.placeholder ? item.placeholder : ""}
                          onChange={(e) =>
                            onChangeFields(item.value, e.target.value)
                          }
                        >
                          {item.options &&
                            item.options.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  style={{ width: "90%", fontSize: 14 }}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                        </AtSelectNew>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </Collapse>
          </>
        }
      />
    </>
  );
};

export default UnitDetail;
