import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const searchParams = [];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      requests: [],
      loading: false,

      selectedRequest: {
        type_id: "",
        description: "",
        claim_party_id: 0,
      },
      showRequestDetails: false,
      showConfirmCreateRequest: false,
      requestPages: null,
    };

    componentDidMount = () => {
      this.isPAFlag =
        this.props.data.profileReducer.role === "Personal Assistant";
    };

    load = (param) => this.setState({ loading: param });

    onChangeRequestHOC = (key, val) => this.setState({ [key]: val });

    getRequests = (id) =>
      Get(
        `/admin/project_sales/${id}/requests`,
        this.getRequestsSuccess,
        this.getRequestsError,
        this.load,
      );
    getRequestsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        submission_date: Moment(item.submission_date).format("DD MMM YYYY"),
      }));
      this.setState({
        requests: {
          meta: payload.meta,
          data: tempData,
        },
        requestsPages: tmpTotalPages,
      });
    };
    getRequestsError = (error) => requestError(error);

    getSelectedRequest = (sales_id, request_id) =>
      Get(
        `/admin/project_sales/${sales_id}/requests/${request_id}`,
        this.getSelectedRequestSuccess,
        this.getSelectedRequestError,
        this.load,
      );
    getSelectedRequestSuccess = (payload) =>
      this.setState({
        selectedRequest: payload,
        showRequestDetails: true,
      });
    getSelectedRequestError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onChangeRequestHOC={this.onChangeRequestHOC}
          getRequests={this.getRequests}
          getSelectedRequest={this.getSelectedRequest}
          searchParams={this.state.searchParams}
          requestsPages={this.state.requestPages}
          selectedRequest={this.state.selectedRequest}
          requests={this.state.requests}
          onLoadRequests={this.state.loading}
          showRequestDetails={this.state.showRequestDetails}
          showConfirmCreateRequest={this.state.showConfirmCreateRequest}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
