import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AiFillThunderbolt } from "react-icons/ai";
import _ from "lodash";

import NewSvgIcon from "components/Icon/new";
import SVGIcon from "components/Icon";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import InformativeModal from "components/Modal/informative";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasTable from "components/NewTable";
import CreateSubmission from "./Create";
import UpdateSubmission from "./Update";

import { statusColor } from "dictionary/subsales";
import { getColorBadge } from "dictionary/badgeColor";

import SubmissionHOC from "./actions";
import DocumentsHOC from "./actions/documents";
import permissionsChecker from "utils/permissionsChecker";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const headerData = [
  {
    header: "Type",
    accessor: "type",
    renderColumn: (rowData) => {
      const { type, is_urgent } = rowData;

      return (
        <>
          {is_urgent && (
            <AiFillThunderbolt style={{ width: "1.5rem", color: "red" }} />
          )}
          {type}
        </>
      );
    },
  },
  {
    header: "Status",
    accessor: "status",
    columnClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => {
      const { status = "" } = rowData;
      const { colorName = "" } = _.find(statusColor, { value: status }) || {};

      return (
        <div
          className="at-status_badge at-status_badge-responsive"
          style={{
            ...getColorBadge(colorName),
            whiteSpace: "nowrap",
          }}
        >
          {status}
        </div>
      );
    },
  },
  {
    header: "Admin",
    hideSort: true,
    renderColumn: (rowData) => {
      const { admin, type_id, status_id, senior_admin } = rowData;
      return (
        <>
          {!admin && [7, 8].includes(type_id) && status_id === 1 && (
            <div className="at-status_badge at-subsales_claims-no-admin-assigned">
              {type_id === 7
                ? "Your invoice is in process now"
                : "Your receipt is in process now"}
            </div>
          )}
          {!(!admin && [7, 8].includes(type_id) && status_id === 1) && (
            <>
              <div className="d-flex align-items-center mb-5">
                <DarkTooltip
                  enterTouchDelay={50}
                  classes={{ tooltip: "tooltip-arrow top" }}
                  placement={"top"}
                  title={"Assigned Admin"}
                >
                  <div className="at-nested_table-admin-label">A</div>
                </DarkTooltip>
                {admin || "N/A"}
              </div>
              <div className="d-flex align-items-center">
                <DarkTooltip
                  enterTouchDelay={50}
                  classes={{ tooltip: "tooltip-arrow top" }}
                  placement={"top"}
                  title={"Reviewing Admin"}
                >
                  <div className="at-nested_table-admin-label">R</div>
                </DarkTooltip>
                {senior_admin || "N/A"}
              </div>
            </>
          )}
        </>
      );
    },
  },
  {
    header: "Submission Date",
    accessor: "submitted_date",
  },
];

class Submission extends Component {
  componentDidMount = () =>
    this.props.getSubmissions(this.props.selected_claim.id);

  detailsClick = (val) => {
    if (val.status === "Rejected") {
      this.props.onChangeSubmissionHOC("showResubmitConfirmation", true);
      this.props.onChangeSubmissionHOC("selectedSubmission", val);
    } else {
      this.props.getSelectedSubmission(this.props.selected_claim.id, val.id);
      this.props.getDocuments(val.id);
    }
  };

  renderCountDownText = () => {
    const { countDownTime } = this.props;

    if (countDownTime && countDownTime > 0) {
      return (
        <p>
          {`Please wait for ${countDownTime} seconds cooling-off period for creating another submission.`}
        </p>
      );
    }
  };

  renderTableHeading = () => {
    const { can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { commRate } = this.props;

    return (
      <div className={`at-table_heading`}>
        <div className="d-flex grid_gap-2 align-items-center flex-wrap">
          <NewSvgIcon
            svgHref="confirmation-form_document-text"
            className="at-icon_svg-table_heading-title"
          />
          <h5 className={`fw-600`} style={{ maxWidth: "fit-content" }}>
            My Claims
          </h5>
          {role === "Personal Assistant" && (
            <DarkTooltip
              placement={"bottom"}
              title={`You are acting on behalf of your superior in this section.`}
            >
              <SVGIcon
                style={{ fill: "#f18e03", width: 26, height: 30 }}
                svgHref={"icon-superior"}
              />
            </DarkTooltip>
          )}
          {can_create && !this.props.countDownTime && (
            <CustomButton
              className={
                "btn-new btn-new--primary subsaleclaim-claimform-submitclaims-create"
              }
              children={
                <>
                  <IoMdAdd style={{ height: 18, width: 18 }} />
                  Fill in My Claim
                </>
              }
              containerStyle={{ display: "inline", maxWidth: "fit-content" }}
              onClick={() =>
                this.props.onChangeSubmissionHOC("showCreateSubmission", true)
              }
            />
          )}
        </div>
        {this.renderCountDownText()}
        <AlertBox
          title={"Finance Payment Processing Time"}
          containerClassName="w-100"
          containerClass="mb-0"
          description={`For all payout requests, including claim commissions, refunds, releases, and forfeits, please take note that the bank may require 2 to 3 working days to process and deposit the funds into the recipient's account after the status is updated to "Paid" or "Completed."`}
        />
        {!commRate && (
          <AlertBox
            title={"No commision rate set."}
            containerClassName="mb-0"
            description={
              "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
            }
          />
        )}
        <AlertBox
          mode={"info"}
          containerClassName="w-100"
          containerClass="mb-0"
          description={
            <>
              <p style={{ fontWeight: 400, color: "#212529" }}>
                If your submission is rejected by the Admin, please find out the
                reason in the email sent to you, the notification, the
                information in the Messages or Activity tab, as you may be
                missing certain information or documents.
              </p>
              <p style={{ fontWeight: 400, color: "#212529" }}>
                Once you have added in the missing content, you can click on the
                "Resubmit" button in the same submission that has been rejected,
                to notify the admin that your claim information is ready and
                complete.
              </p>
            </>
          }
        />
      </div>
    );
  };

  renderCardContent = () => {
    return (
      <>
        <div>
          <AtlasTable
            className={"mb-100"}
            columns={headerData}
            rowData={this.props.submissions}
            hideSearch={true}
            pagination={true}
            renderExtraContent={this.renderTableHeading}
            emptyStateMessage="You don't have any claims."
            actionColumnContent={[
              {
                name: "details",
                tooltipChildren: (rowData) =>
                  rowData.status === "Rejected" ? "Resubmit" : "Details",
                renderChildren: (rowData) => (
                  <AtlasIcon
                    className="svg-icon-chevron"
                    style={{ color: "#007BFF" }}
                    svgHref={
                      rowData.status === "Rejected"
                        ? "svg-react_icon-goto_file"
                        : "svg-react_icon-file_search"
                    }
                  />
                ),
                onClick: (rowData) => this.detailsClick(rowData),
              },
            ]}
          />
        </div>
        <div
          className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() => this.props.onClickNav("Documents")}>
            <p>Documents</p>
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onCloseForm()}>
            Close
          </button>
        </div>
      </>
    );
  };

  render = () => {
    const { can_update, can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    return (
      <>
        {this.renderCardContent()}
        {this.props.showCreateSubmission && (
          <CreateSubmission
            showSubmitButton={this.props.showSubmitButton}
            getRefundParticipants={this.props.getRefundParticipants}
            onChangeSubmissionHOC={this.props.onChangeSubmissionHOC}
            showCloseConfirmation={this.props.showCloseConfirmation}
            isCreateSubmissionError={this.props.isCreateSubmissionError}
            newSubmission={this.props.newSubmission}
            onLoadSubmissions={this.props.onLoadSubmissions}
            onCloseCopyRemarkConfirmation={this.props.onCloseCopyRemarkConfirmation}
            refundParticipants={this.props.refundParticipants}
            createSubmission={this.props.createSubmission}
            selected_claim={this.props.selected_claim}
            showConfirmCreateSubmission={this.props.showConfirmCreateSubmission}
          />
        )}
        {this.props.showUpdateSubmission && (
          <UpdateSubmission
            can_update={can_update}
            can_create={can_create}
            onChangeSubmissionHOC={this.props.onChangeSubmissionHOC}
            selectedSubmission={this.props.selectedSubmission}
            onLoadSubmissions={this.props.onLoadSubmissions}
            documents={this.props.documents}
            selected_claim={this.props.selected_claim}
            role={role}
            downloadDocument={this.props.downloadDocument}
          />
        )}
        <InformativeModal
          open={this.props.showInformativeModal}
          title={"Notice"}
          renderIcon={
            <div className="at-confirmation_modal-icon">
              <AtlasIcon
                svgHref={"atlas-info-circle-linear"}
                style={{ fill: "#1D4ED8", backgroundColor: "#EFF6FF" }}
              />
            </div>
          }
          cardMessageStyle={{ maxWidth: 500, margin: "auto" }}
          message={
            "Your claim will be processed in 7 working days upon approval (Processing status)"
          }
          buttonOnClick={() =>
            this.props.onChangeSubmissionHOC("showInformativeModal", false)
          }
        />
        <ConfirmationModal
          mode={"alert"}
          open={this.props.showResubmitConfirmation}
          message={
            "Kindly ensure all the required information and documents are updated before resubmitting the claim. Resubmit now?"
          }
          positiveAction={() =>
            this.props.resubmitSubmission(
              this.props.selected_claim.id,
              this.props.selectedSubmission.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeSubmissionHOC("showResubmitConfirmation", false)
          }
        />
        {(this.props.onLoadSubmissions || this.props.onLoadPreview) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(SubmissionHOC, DocumentsHOC)(Submission);
