import React, { useState } from "react";
import {
  FormLabel,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";

import ContactHOC from "./actions/contact";
import { useEffect } from "react";

const AssignContactContent = ({
  contacts,
  showLoading,
  onClickSubmit,
  onClickClose,
  getCurrentUserContactList,
  onLoadContact,
}) => {
  const [selectedContactId, onChangeContactId] = useState("");

  useEffect(() => {
    getCurrentUserContactList();
  }, []);
  return (
    <>
      <ModalDialog
        title={"Assign Client from Contacts"}
        onClose={onClickClose}
        children={
          <>
            <div className={"d-flex mb-20"}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel>Contacts</FormLabel>
                {contacts.length <= 0 && (
                  <p>No contact available, please create new client.</p>
                )}
                {contacts.length > 0 && (
                  <Select
                    value={selectedContactId}
                    autoWidth={true}
                    input={<OutlinedInput labelWidth={0} />}
                    onChange={(event) => onChangeContactId(event.target.value)}
                  >
                    {contacts.map((item, index) => (
                      <MenuItem key={`${item.id}-${index}`} value={item.id}>
                        {`${item.full_name} - ${item.email}`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
          </>
        }
        footer={
          contacts.length > 0 && (
            <div className="d-flex g-3">
              <CustomButton
                disabled={!selectedContactId}
                className="btn-new btn-new--success"
                onClick={() => onClickSubmit(selectedContactId)}
              >
                {`Assign`}
              </CustomButton>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={() => onClickClose()}
              >
                Cancel
              </button>
            </div>
          )
        }
      />
      {(onLoadContact || showLoading) && <LoadingModal />}
    </>
  );
};

export default ContactHOC(AssignContactContent);
