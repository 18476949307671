export const modulesPermissions = [
  {
    moduleName: "Dashboard",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Global Network",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Project Centre",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Campaign Tracker",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Performance",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Members",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "My Hierarchy",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Marketing Centre",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Top Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Project Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Birthdays",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Recruitment Growth Target",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "New Agent Approvals",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "My Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Payment Vouchers",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Forms",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Listing Centre",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Subsales Claims",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "My Contacts",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "IQI Drive",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "IQI Academy",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Company Events",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Tutorials",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Leads",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "IQI Stores",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Valuations",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Presentation Centre",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Video Creator",
    checkboxes: ["can_create", "can_read", "can_destroy"],
  },
  {
    moduleName: "Co Broke Center",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Graphic Studios",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Projects - Reservations",
    checkboxes: ["can_create", "can_read"],
  },
  {
    moduleName: "Meeting Hub",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
];
