import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { PulseLoader } from "react-spinners";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";

import AgentSearchHOC from "actions/agentSearch";

const StatusLib = [
  { id: 0, value: "Pending", label: "Pending" },
  { id: 1, value: "Absent", label: "Absent" },
  { id: 2, value: "Present", label: "Present" },
  { id: 3, value: "Resit", label: "Resit" },
];

const EditAttendanceContent = ({
  agentList,
  onLoadAgent,
  selectedAttendance,
  onCloseModal,
  onLoadTrainings,
  updateAttendance,
}) => {
  const [status, onChangeStatus] = useState(0);

  useEffect(() => {
    onChangeStatus(selectedAttendance.status_id);
  }, []);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onCloseModal()} />
        <h4 className="at-card__title">Edit Attendance</h4>
      </div>
      <Grid container spacing={16}>
        <Grid item md={6} xs={12}>
          <div className="d-flex">
            <h2 className="at-form-input__title mr-10">Select an Agent</h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={true}
            typeaheadId={"internal_agent"}
            options={agentList ? agentList : []}
            selectedValue={
              selectedAttendance.agent ? [selectedAttendance.agent] : []
            }
            labelKey={"full_name"}
            helpText={"e.g. Full Name, Email, Mobile Number"}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <label className={"mt-10 mb-10 d-block"}>Training</label>
          <i>{selectedAttendance.event_name}</i>
        </Grid>
        <Grid item md={6} xs={12}>
          <label className={"mt-10"}>Status</label>
          <AtlasRadioGroup
            valueKey={"id"}
            horizontal={true}
            checkedValue={status}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeStatus(parseInt(val))}
            options={StatusLib}
          />
        </Grid>
      </Grid>
      <div className="d-flex  mt-20">
        <AtlasButton
          disabled={!status}
          className="btn-new btn-new--success"
          children={"Update"}
          onClick={() => {
            updateAttendance(selectedAttendance.id, { status_id: status });
          }}
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onCloseModal()}
        >
          Close
        </button>
      </div>
      {onLoadTrainings && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(EditAttendanceContent);
