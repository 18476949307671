import React, { Fragment } from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AiFillThunderbolt } from "react-icons/ai";

import IOSButton from "components/Button/toggle";

import { statusColor } from "dictionary/subsales";
import { getColorBadge } from "dictionary/badgeColor";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const secondLayerHeader = [
  {
    label: "Submission Date",
    value: "submitted_date",
    columnStyle: { width: "25%" },
  },
  {
    label: "Approval Date",
    value: "approved_date",
    columnStyle: { width: "25%" },
  },
  {
    label: "Admin",
    value: "admin",
    columnStyle: { width: "50%" },
  },
];

const renderSecondLayerContent = (row, index) => (
  <ul key={index} className="at-nested_table-content_row">
    {secondLayerHeader &&
      secondLayerHeader.map((header) => {
        return (
          <li
            key={header.value}
            className={`at-nested_table-row_item ${row[header.value] ? "" : "at-table-column_responsive_null_display"}`}
            style={{ width: "100%", ...header.columnStyle }}
          >
            <span className="at-nested_table-item_label">{header.label}</span>
            <span>
              {header.value === "admin" && (
                <>
                  {!row.admin &&
                    ["Request Invoice", "Request Official Receipt"].includes(
                      row.type,
                    ) &&
                    row.status === "Submitted" && (
                      <div className="at-status_badge at-subsales_claims-no-admin-assigned">
                        {row.type === "Request Invoice"
                          ? "Your invoice is in process now"
                          : "Your receipt is in process now"}
                      </div>
                    )}
                  {!(
                    !row.admin &&
                    ["Request Invoice", "Request Official Receipt"].includes(
                      row.type,
                    ) &&
                    row.status === "Submitted"
                  ) && (
                    <>
                      <div className="d-flex align-items-center mb-5">
                        <DarkTooltip
                          enterTouchDelay={50}
                          classes={{ tooltip: "tooltip-arrow top" }}
                          placement={"top"}
                          title={"Assigned Admin"}
                        >
                          <div className="at-nested_table-admin-label">A</div>
                        </DarkTooltip>
                        {row.admin || "N/A"}
                      </div>
                      <div className="d-flex align-items-center">
                        <DarkTooltip
                          enterTouchDelay={50}
                          classes={{ tooltip: "tooltip-arrow top" }}
                          placement={"top"}
                          title={"Reviewing Admin"}
                        >
                          <div className="at-nested_table-admin-label">R</div>
                        </DarkTooltip>
                        {row.senior_admin || "N/A"}
                      </div>
                    </>
                  )}
                </>
              )}
              {header.value === "approved_date" &&
                (row["approved_date"]
                  ? Moment(row["approved_date"], "YYYY-MM-DD").format(
                      "DD MMM YYYY",
                    )
                  : "N/A")}
              {!["admin", "approved_date", "status"].includes(header.value) &&
                (row[header.value] || "N/A")}
            </span>
          </li>
        );
      })}
  </ul>
);

export const ColumnData = (props) => {
  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? props.data.currentSignInProfileReducer
    : props.data.profileReducer;

  const represenstativeBadgeInfo = [
    {
      id: 1,
      desc: "Represent Vendor/Landlord",
      badgeColor: "Yellow",
    },
    {
      id: 2,
      desc: "Represent Purchase/Tenant",
      badgeColor: "Green",
    },
    {
      id: 3,
      desc: "Represent Both",
      badgeColor: "Blue",
    },
  ];
  return [
    {
      header: "Form No.",
      columnStyle: { width: "40%", minWidth: 90 },
      columnClass: "align-items-start",
      renderColumn: ({
        confirmation_form_number,
        representative_id,
        unit_id,
        case_closed,
        sub_sale_type_id,
        id,
      }) => {
        const { badgeColor } =
          _.find(represenstativeBadgeInfo, { id: representative_id }) || {};
        return (
          <>
            {confirmation_form_number && <p>{confirmation_form_number}</p>}
            <DarkTooltip
              enterTouchDelay={50}
              classes={{ tooltip: "tooltip-arrow top" }}
              placement={"top"}
              title={"Unit ID"}
            >
              <span
                className="badge badge-primary copy_unitID-clipboard d-block mt-10 mb-10"
                data-clipboard-text={unit_id}
                onClick={props.onClickCopy}
                style={{
                  fontSize: "0.75rem",
                  width: "max-content",
                  cursor: "pointer",
                }}
              >
                {unit_id}
              </span>
            </DarkTooltip>
            {representative_id && representative_id !== null && (
              <div
                className="at-status_badge text-center mt-1 mb-2"
                style={{ ...getColorBadge(badgeColor) }}
              >
                {representative_id !== 3 &&
                  sub_sale_type_id === 1 &&
                  (representative_id === 1
                    ? "Represent Vendor"
                    : "Represent Purchaser")}
                {representative_id !== 3 &&
                  sub_sale_type_id === 2 &&
                  (representative_id === 1
                    ? "Represent Landlord"
                    : "Represent Tenant")}
                {representative_id === 3 && "Represent Both"}
              </div>
            )}
            {tmpProfileReducer.role === "Super Admin" && (
              <IOSButton
                className={{ root: "mt-2" }}
                currentState={case_closed}
                onToggleButton={() => props.changeClaimsState(id)}
              />
            )}
            {tmpProfileReducer.role === "Admin" && (
              <span
                className="badge badge-pill color-white mt-2"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  backgroundColor: case_closed ? "#FB404B" : "#87CB16",
                }}
              >
                {case_closed ? "Close" : "Open"}
              </span>
            )}
          </>
        );
      },
    },
    {
      header: "Property Address",
      columnStyle: { width: "90%" },
      columnClass: (rowData) =>
        `align-items-start ${rowData.property_address ? "" : "at-table-column_responsive_null_display"}`,
      accessor: "property_address",
    },
    {
      header: "Booking Date",
      columnStyle: { width: "30%", minWidth: 60 },
      columnClass: (rowData) =>
        `align-items-start ${rowData.booking_date ? "" : "at-table-column_responsive_null_display"}`,
      accessor: "booking_date",
    },
    {
      header: "Submitted By",
      columnStyle: { width: "30%" },
      columnClass: (rowData) =>
        `align-items-start ${rowData.submitted_by ? "" : "at-table-column_responsive_null_display"}`,
      accessor: "submitted_by",
    },
    {
      header: "Claim Submissions",
      columnStyle: { width: "150%" },
      contentClass: "at-column-nested_table h-100",
      columnClass: "at-column-nested_table align-items-start",
      renderColumn: (rowData) => (
        <>
          {rowData.sub_sale_claim_submissions &&
            rowData.sub_sale_claim_submissions.length > 0 && (
              <div className="at-nested_table-second_layer">
                <div className="at-nested_table-content">
                  {rowData["sub_sale_claim_submissions"] &&
                    rowData["sub_sale_claim_submissions"].map((row, index) => {
                      const { colorName = "" } =
                        _.find(statusColor, { value: row.status }) || {};
                      return (
                        <Fragment key={index}>
                          <div
                            className="d-flex align-items-center"
                            style={{ padding: "10px 5px 0px 5px" }}
                          >
                            {row.is_urgent && (
                              <AiFillThunderbolt
                                style={{
                                  width: 16,
                                  height: 16,
                                  color: "red",
                                  marginRight: 10,
                                }}
                              />
                            )}
                            <p style={{ fontWeight: 600, marginRight: 10 }}>
                              {row.type || ""}
                            </p>
                            <div
                              className="at-status_badge"
                              style={{
                                ...getColorBadge(colorName),
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.status}
                            </div>
                          </div>
                          {renderSecondLayerContent(row, index)}
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            )}
          {!(
            rowData.sub_sale_claim_submissions &&
            rowData.sub_sale_claim_submissions.length > 0
          ) && (
            <div className="at-nested_table-second_layer h-100">
              <div className="at-nested_table-content">
                <p style={{ fontWeight: 600, marginRight: 10 }}>
                  No Claim Submissions
                </p>
              </div>
            </div>
          )}
        </>
      )
    }
  ];
};

export const RepresentativeInfo = showRepresentBoth => [
  {
    id: 1,
    label: "Represent Landlord/Vendor",
    value: 1,
  },
  {
    id: 2,
    label: "Represent Tenant/Purchaser",
    value: 2,
  },
  ...(showRepresentBoth
    ? [
        {
          id: 3,
          label: "Represent Both",
          value: 3,
        },
      ]
    : []),
];

export const unit_types = [
  {
    id: 1,
    name: "Corner",
    value: 1,
    label: "Corner",
  },
  {
    id: 2,
    name: "Duplex",
    value: 2,
    label: "Duplex",
  },
  {
    id: 3,
    name: "Endlot",
    value: 3,
    label: "Endlot",
  },
  {
    id: 4,
    name: "Intermediate",
    value: 4,
    label: "Intermediate",
  },
  {
    id: 5,
    name: "Penthouse",
    value: 5,
    label: "Penthouse",
  },
  {
    id: 6,
    name: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    id: 7,
    name: "Triplex",
    value: 7,
    label: "Triplex",
  },
  {
    id: 8,
    name: "Unknown",
    value: 8,
    label: "Unknown",
  },
  {
    id: 9,
    name: "SOHO",
    value: 9,
    label: "SOHO",
  },
];

export const subsaleRepresentativeBadge = [
  {
    id: 1,
    value: 1,
    label: "Represent Vendor",
    badgeColor: "Yellow",
  },
  {
    id: 2,
    value: 2,
    label: "Represent Purchaser",
    badgeColor: "Green",
  },
  {
    id: 3,
    value: 3,
    label: "Represent Both",
    badgeColor: "Blue",
  },
];

export const subrentRepresentativeBadge = [
  {
    id: 1,
    value: 1,
    label: "Represent Landlord",
    badgeColor: "Yellow",
  },
  {
    id: 2,
    value: 2,
    label: "Represent Tenant",
    badgeColor: "Green",
  },
  {
    id: 3,
    value: 3,
    label: "Represent Both",
    badgeColor: "Blue",
  },
];

export const representativeBadge = [
  {
    id: 1,
    value: 1,
    label: "Landlord / Vendor",
    badgeColor: "Yellow",
  },
  {
    id: 2,
    value: 2,
    label: "Tenant / Purchaser",
    badgeColor: "Green",
  },
];

export const oppositeInfoStatusBadges = [
  {
    id: 1,
    value: "Updated",
    colorName: "Yellow",
  },
  {
    id: 2,
    value: "Pending",
    colorName: "Blue",
  },
  {
    id: 3,
    value: "Completed",
    colorName: "Green",
  }
];