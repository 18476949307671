import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put, Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import { adminLogin } from "actions/adminSignIn";
import { getCountryDictionary } from "actions/dictionary";
import { getCurrentUserProfile } from "actions/profile";
import { user as UserDictionary } from "dictionary/index";
import { getAllDictionaryData } from "actions/dictionary";

const searchParams = ({
  dictionaryReducer,
  profileReducer,
  currentSignInProfileReducer,
}) => {
  const { team } = dictionaryReducer;
  const { roles, statuses } = UserDictionary;
  const { role } = window.location.href.includes("/admin-impersonate")
    ? currentSignInProfileReducer
    : profileReducer;

  let tempRole = [];
  let tempStatus = [];

  roles.map((item) => {
    role !== "Admin"
      ? tempRole.push({
          ...item,
          value: `${item.value}`,
        })
      : item.value !== 3 &&
        item.value !== 4 &&
        tempRole.push({
          ...item,
          value: `${item.value}`,
        });
  });
  statuses.map((item) =>
    tempStatus.push({
      ...item,
      value: `${item.value}`,
    }),
  );

  return [
    {
      label: "Full Name",
      value: "full_name_cont",
      type: "input",
      param: "",
    },
    {
      label: "Email",
      value: "email_cont",
      type: "input",
      param: "",
    },
    {
      label: "Mobile",
      value: "mobile_contact_number_cont",
      type: "input",
      param: "",
    },
    {
      label: "Mobile on name card",
      value: "name_card_mobile_number_cont",
      type: "input",
      param: "",
    },
    {
      label: "Team",
      value: "team_id_eq",
      type: "select",
      param: "",
      options: team,
    },

    {
      label: "Branch",
      value: "branch_id_eq",
      type: "select",
      param: "",
      options: [],
    },
    {
      label: "Role",
      value: "role_id_eq",
      type: "radio",
      param: "",
      col: 12,
      options: tempRole,
    },
    {
      label: "Status",
      value: "status_id_eq",
      type: "radio",
      param: "",
      col: 12,
      options: tempStatus,
    },
  ];
};

const UsersHOC = (WrappedComponent) => {
  class UsersWrappedComponent extends Component {
    state = {
      users: [],
      selectedUser: {},
      loading: false,

      showDetailsModal: false,
      showEditUserForm: false,
      showCopySnackBar: false,
      showCreateAdminModal: false,

      usersPages: null,
      searchParams: searchParams(this.props.data),
      selectedUnitAdminProfile: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    getAllUsers = (page, search) => {
      this.props.storeLastView({
        currentPage: page,
        searchParams: search,
      });
      Get(
        `/admin/users?${search}page=${page}`,
        this.getAllUsersSuccess,
        this.getAllUsersError,
        this.load,
      );
    };
    getAllUsersSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        users: payload,
        usersPages: tmpTotalPages,
      });
    };
    getAllUsersError = (error) => requestError(error);

    getSelectedUser = (id, type) =>
      Get(
        `/admin/users/${id}`,
        (payload) => this.getSelectedUserSuccess(payload, type),
        this.getSelectedUserError,
        this.load,
      );
    getSelectedUserSuccess = (payload, type) => {
      this.setState(
        {
          selectedUser: {
            ...payload,
            created_at: payload.created_at
              ? Moment(payload.created_at).format("DD MMM YYYY")
              : "N/A",
            date_of_birth: payload.date_of_birth
              ? Moment(payload.date_of_birth, "DD-MM-YYYY").format(
                  "DD MMM YYYY",
                )
              : "N/A",
          },
          showDetailsModal: true,
        },
        () => {
          this.getUnitAdminProfile(
            this.state.selectedUser.role_id,
            this.state.selectedUser.encoded_identity,
          );
        },
      );
    };
    getSelectedUserError = (error) => requestError(error);

    updateUser = (id, params) => {
      let tmpFormData = new FormData();
      Object.keys(params).map((key) => {
        if (key === "agent_registration_attributes") {
          Object.keys(params[key]).map((agentRegAttr) => {
            tmpFormData.append(
              `[${key}][${agentRegAttr}]`,
              params[key][agentRegAttr],
            );
          });
        } else if (key === "name_card_attributes") {
          Object.keys(params[key]).map((nameCardAttr) => {
            tmpFormData.append(
              `[${key}][${nameCardAttr}]`,
              params[key][nameCardAttr],
            );
          });
        } else if (key === "user_branches") {
          params[key].map((branch) => {
            tmpFormData.append(`[${key}][]`, branch);
          });
        } else {
          tmpFormData.append(`[${key}]`, params[key]);
        }
      });

      return Put(
        `/admin/users/${id}`,
        tmpFormData,
        this.updateUserSuccess,
        this.updateUserError,
        this.load,
      );
    };
    updateUserSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getAllUsers(currentPage, searchParams || "");
      requestSuccess("Profile updated successfully! ");
      this.setState({ showEditUserForm: false });
      this.getSelectedUser(this.state.selectedUser.id, "close edit user");
    };
    updateUserError = (error) => requestError(error);

    getUnitAdminProfile = (role_id, id) =>
      Get(
        `/connector/profile?identity_id=${id}&role_id=${role_id}`,
        this.getUnitAdminProfileSuccess,
        this.getUnitAdminProfileError,
        this.load,
      );
    getUnitAdminProfileSuccess = (payload) =>
      this.setState({ selectedUnitAdminProfile: payload });
    getUnitAdminProfileError = (error) => requestError(error);

    uploadAgentFile = (id, dataToSubmit) =>
      Put(
        `/admin/registrations/${id}`,
        dataToSubmit,
        this.uploadAgentFileSuccess,
        this.uploadAgentFileError,
        this.load,
      );
    uploadAgentFileSuccess = (payload) => {
      this.setState({ selectedUser: payload });
      requestSuccess("Document uploaded successfully.");
    };
    uploadAgentFileError = (error) => requestError(error);

    createNewAdmin = (val) =>
      Post(
        `/admin/users/create_admin`,
        val,
        this.createNewAdminSuccess,
        this.createNewAdminError,
        this.load,
      );
    createNewAdminSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAllUsers(currentPage, searchParams);

      requestSuccess("New user created successfully.");
      this.setState({ showCreateAdminModal: false });
    };
    createNewAdminError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            users={this.state.users}
            usersPages={this.state.usersPages}
            selectedUser={this.state.selectedUser}
            onLoadUsers={this.state.loading}
            showCreateAdminModal={this.state.showCreateAdminModal}
            showCopySnackBar={this.state.showCopySnackBar}
            showDetailsModal={this.state.showDetailsModal}
            showEditUserForm={this.state.showEditUserForm}
            selectedUnitAdminProfile={this.state.selectedUnitAdminProfile}
            getAllUsers={this.getAllUsers}
            onChangeHOC={this.onChangeHOC}
            updateUser={this.updateUser}
            createNewAdmin={this.createNewAdmin}
            uploadAgentFile={this.uploadAgentFile}
            getSelectedUser={this.getSelectedUser}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    adminLogin,
    getCurrentUserProfile,
    getCountryDictionary,
    getAllDictionaryData,
    storeLastView,
    refreshToken,
  })(UsersWrappedComponent);
};

export default UsersHOC;
