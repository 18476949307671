import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const SubsaleAdminHOC = (WrappedComponent) => {
  class SubsalesAdminWrappedComponent extends Component {
    state = {
      admins: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    getAdmins = () =>
      Get(
        `/admin/admins`,
        this.getAdminsSuccess,
        this.getAdminsError,
        this.load,
      );
    getAdminsSuccess = (payload) => {
      let tempData = [];

      payload.map((item) =>
        tempData.push({
          ...item,
          name: item.display_name,
        }),
      );

      this.setState({ admins: tempData });
    };
    getAdminsError = (error) => requestError(error);

    render = () => (
      <>
        <WrappedComponent
          {...this.props}
          admins={this.state.admins}
          onLoadAdmins={this.state.loading}
          getAdmins={this.getAdmins}
        />
      </>
    );
  }
  return SubsalesAdminWrappedComponent;
};

export default SubsaleAdminHOC;
