import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      commissionStats: {},
    };

    load = (param) => this.setState({ loading: param });

    getCommStats = (encoded_mobile) =>
      Get(
        encoded_mobile
          ? `/connector/commission_statistics?identity_id=${encoded_mobile}`
          : `/connector/commission_statistics`,
        this.getCommStatsSuccess,
        this.getCommStatsError,
        this.load,
      );
    getCommStatsSuccess = (payload) =>
      this.setState({ commissionStats: payload });
    getCommStatsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadCommision={this.state.loading}
            commissionStats={this.state.commissionStats}
            getCommStats={this.getCommStats}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
