import React, { useEffect } from "react";

export default function Info() {
  return (
    <>
      <p className={"fs-2 fw-500 mt-2 mb-2"}>
        Create and customise your graphics here by doing the following:
      </p>
      <ol style={{ fontSize: 14, lineHeight: 2.2, paddingLeft: 15 }}>
        <li>Enter a title so that you can easily find your image later on.</li>
        <li>
          Choose a template from the many templates that are available. You can
          use the filtering tools to narrow down your selection.
        </li>
        <li>Your chosen template will be displayed in the graphics canvas.</li>
      </ol>
    </>
  );
}
