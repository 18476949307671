import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";

import AtlasCard from "components/Card";
import CustomTable from "components/NewTable";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/confirmation";
import CreateForm from "./Create";
import EditForm from "./Edit";

import MyPersonalAssistantHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Red" },
];

const ColumnData = [
  {
    header: "Full Name",
    searchFlag: "full_name",
    accessor: "full_name",
  },
  {
    header: "Email",
    searchFlag: "email",
    accessor: "email",
  },
  {
    header: "Mobile Contact",
    searchFlag: "mobile_contact_number",
    accessor: "mobile_contact_number",
    columnStyle: {width: '60%'}
  },
  {
    header: "Status",
    searchFlag: "status",
    columnStyle: {width: '60%'},
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

class MyPersonalAssistant extends Component {
  componentDidMount = () => {
    this.props.getBranches();
    this.props.getPAList();
  };

  onToggleModal = (val, context) => {
    this.props.onChangeHOC(val, "selectedPersonalAssistant");
    this.props.onChangeHOC(true, context);
  };

  renderCardContent = () => {
    const { personalAssistantsList = [] } = this.props;
    return (
      <CustomTable
        className={"mb-100"}
        emptyStateMessage="No personal assistant found"
        pagination={true}
        columns={ColumnData}
        rowData={personalAssistantsList}
        actionColumnContent={[
          {
            name: "edit",
            onClick: (rowData) => this.onToggleModal(rowData, "showEditModal"),
          },
          {
            name: "delete",
            color: "#F04438",
            onClick: (rowData) => {
              Promise.all([
                this.props.onChangeHOC(rowData, "selectedPersonalAssistant"),
              ]).then(() => {
                this.props.onChangeHOC(true, "showDeleteModal");
              });
            },
          },
        ]}
      />
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"My Personal Assistant"}
          atlasIcon={"atlas-tag-user"}
          actionButton={[
            {
              label: "New Personal Assistant",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () => this.props.onChangeHOC(true, "showCreateModal"),
            },
          ]}
        />
        <AtlasCard cardContent={this.renderCardContent()} />
        {this.props.showCreateModal && <CreateForm {...this.props} />}
        {this.props.showEditModal && <EditForm {...this.props} />}
        <ConfirmationModal
          mode={"alert"}
          open={this.props.showDeleteModal}
          title={"Remove Personal Assistant"}
          message={"Are you sure to remove this personal assistant ?"}
          loading={this.props.onLoadMyPersonalAssistant}
          positiveAction={() =>
            this.props.deletePA(this.props.selectedPersonalAssistant.id)
          }
          negativeAction={() =>
            this.props.onChangeHOC(false, "showDeleteModal")
          }
        />
        {this.props.onLoadMyPersonalAssistant && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  MyPersonalAssistantHOC,
  VersionCheckHOC,
)(MyPersonalAssistant);
