import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreatePermissionGroupUser from "./Create";
import UpdatePermissionGroupUser from "./Update";

import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";
import VersionCheckHOC from "actions/versionCheck";
import WithPermissionGroupUsers from "./actions";
import "./index.scss";

const columnData = [
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Description",
    accessor: "description",
  },
];

class PermissionGroupUsers extends Component {
  componentDidMount = () => {
    initGA("/dashboard/superadmin/permission-group-user-new");
    this.props.getPermissionGroups(1, "");
  };
  renderContent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const { can_update } = permissionsChecker(
      "Permission Groups",
      this.props.data,
    );

    return (
      <>
        <AtPaginatedTable
          rowData={this.props.permissionGroups.data || []}
          meta={this.props.permissionGroups.meta}
          columns={columnData}
          actionColumnContent={[
            {
              name: can_update ? "edit" : "view",
              onClick: (rowData) =>
                this.props.getSelectedPermissionGroup(rowData.id),
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: () => role === "Super Admin",
              onClick: (rowData) => {
                this.props.onChangePermissionGroupUsersHOC(
                  "toRemoveID",
                  rowData.id,
                );
                this.props.onChangePermissionGroupUsersHOC(
                  "showConfirmDeleteModal",
                  true,
                );
              },
            },
          ]}
          getListAPI={this.props.getPermissionGroups}
          searchParams={this.props.searchParamsPermission}
          onChangeSearchParams={(val) =>
            this.props.onChangePermissionGroupUsersHOC(
              "searchParamsPermission",
              val,
            )
          }
          totalPages={this.props.permissionPage}
        />
        {this.props.showCreatePermissionGroup && (
          <CreatePermissionGroupUser
            {...this.props}
            onClose={() =>
              this.props.onChangePermissionGroupUsersHOC(
                "showCreatePermissionGroup",
                false,
              )
            }
          />
        )}
        {this.props.showUpdatePermissionGroup && (
          <UpdatePermissionGroupUser {...this.props} />
        )}
        {this.props.onLoadPermissionGroupUsers && <LoadingModal />}
      </>
    );
  };

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Permission Groups"}
          atlasIcon={"atlas-key"}
          actionButton={[
            {
              label: "New Permission Group",
              onShow: role === "Super Admin",
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangePermissionGroupUsersHOC(
                  "showCreatePermissionGroup",
                  true,
                ),
            },
          ]}
        />
        <CustomCard cardContent={this.renderContent()} />
      </>
    );
  };
}

export default compose(
  WithPermissionGroupUsers,
  VersionCheckHOC,
)(PermissionGroupUsers);
