import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const FontManager = () => {
  const language = useSelector((state) => state.languageReducer.language);

  useEffect(() => {
    const head = document.head;
    let link = document.createElement('link');
    link.rel = 'stylesheet';

    if (language === 'en') {
      link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap';
    } else if (language === 'ja' || language === 'zh') {
      link.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+TC:wght@100..900&family=Noto+Serif+SC:wght@200..900&display=swap';
    } else {
      link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap';
    }

    const existingLinks = document.querySelectorAll('link[rel="stylesheet"][href*="fonts.googleapis.com"]');
    existingLinks.forEach(existingLink => head.removeChild(existingLink));

    const firstStylesheet = head.querySelector('link[rel="stylesheet"]');
    if (firstStylesheet) {
      head.insertBefore(link, firstStylesheet);
    } else {
      head.appendChild(link);
    }

    return () => {
      head.removeChild(link);
    };
  }, [language]);

  return null;
};

export default FontManager;