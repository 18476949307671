import React, { PureComponent } from "react";
import Moment from "moment";
import ReactHTMLParser from "react-html-parser";

import CustomButton from "components/Button";
import SVGIcon from "components/Icon";

import "stylesheets/containers/personal/subsales/components/timeline.scss";

class Timeline extends PureComponent {
  calcDateDifference(param) {
    if (param) {
      return (
        <>
          {`${Moment(param).format("DD MMM YYYY HH:mm")}`},
          {` ${Moment(param).fromNow()}`}
        </>
      );
    }
  }

  render() {
    const { content, createdAt, messageLogs, onClickLoadMore } = this.props;
    return (
      <>
        <div className="timeline-block">
          <div className="d-flex flex-column">
            <div className="timeline-point">
              <SVGIcon className="svg-icon-bell" svgHref="icon-bell" />
            </div>
            <div className="timeline-notification_line" />
          </div>
          <div className="timeline-content">
            <div className="timeline-content-card">
              <div className="timeline-content-header">
                {this.calcDateDifference(createdAt)}
              </div>
              <div className="timeline-content-body">
                {ReactHTMLParser(content) || content}
                {messageLogs && (
                  <CustomButton
                    containerClass={"d-flex justify-content-center"}
                    className={"btn-new btn-new--outline-secondary"}
                    children={"Detail"}
                    onClick={() => onClickLoadMore()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Timeline;