import React, { Component } from "react";
import _ from "lodash";

import StatusModal from "../components/statusModal";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      selectedDirectLeader: {},
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      directLeaderPhoneNumber: "",
    };

    load = (param) => this.setState({ loading: param });

    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    onChangeDirectLeaderHOC = (val, context) =>
      this.setState({ [context]: val });

    getDirectLeader = (phone_number) =>
      Post(
        `/registrations/search_referrer/${phone_number}`,
        "",
        this.getDirectLeaderSuccess,
        this.getDirectLeaderError,
        this.load,
      );
    getDirectLeaderSuccess = (payload) =>
      this.setState({ selectedDirectLeader: payload });
    getDirectLeaderError = (error) => this.requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeDirectLeaderHOC={this.onChangeDirectLeaderHOC}
            getDirectLeader={this.getDirectLeader}
            directLeaderPhoneNumber={this.state.directLeaderPhoneNumber}
            selectedDirectLeader={this.state.selectedDirectLeader}
            onLoadDirectLeader={this.state.loading}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            positiveText={"Close"}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClickClose={this.state.onClickStatusModalButton}
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
