import React, { Component } from "react";
import { connect } from "react-redux";
import { Get } from "utils/axios";
import { refreshToken } from "actions/login";

const SubsalesStatus = (WrappedComponent) => {
  class SubsalesStatusHOC extends Component {
    state = {
      loading: false,
      commLoading: false,
      subsalesStatus: {},
      commRate: true,
      whitelisted: false,
      completed_trainings: true,
    };

    load = (param) => this.setState({ loading: param });

    getCommRate = () =>
      Get(
        `/profiles/status_permissions`,
        this.getCommRateSuccess,
        this.getCommRateError,
        (param) => this.setState({ commLoading: param }),
      );
    getCommRateSuccess = (payload) =>
      this.setState({
        commRate: payload.subsale_comm_rate_set,
        completed_trainings: payload.completed_trainings,
        whitelisted: payload.whitelisted,
      });
    getCommRateError = () => {};

    getSubsalesStatus = () =>
      Get(
        `/profiles/sub_sales/status`,
        this.getSubsalesStatusSuccess,
        this.getSubsalesStatusError,
        this.load,
      );
    getSubsalesStatusSuccess = (payload) =>
      this.setState({ subsalesStatus: payload });
    getSubsalesStatusError = (error) => {};

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          subsalesStatus={this.state.subsalesStatus}
          commRate={this.state.commRate}
          whitelisted={this.state.whitelisted}
          completed_trainings={this.state.completed_trainings}
          onLoadSubsalesStatus={this.state.loading}
          onLoadCommRate={this.state.commLoading}
          getCommRate={this.getCommRate}
          getSubsalesStatus={this.getSubsalesStatus}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(SubsalesStatusHOC);
};

export default SubsalesStatus;
