import React from "react";

import CustomFormInput from "components/Input/formInput";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import CustomTypeahead from "components/Typeahead/new";
import SubsalesTooltip from "../../../../components/tooltip";

import "../../../../index.scss";

const MeasurementInfo = ({
  bedroom_options,
  selectedBedroomOption,
  bathroom_options,
  selectedBathroomOption,
  directions,
  selectedDirection,
  measurements,
  selectedMeasurement,
  onChangeValue,
  builtUp,
  landArea,
  
  getLocalised,
  car_park_options,
  selectedCarparkOption,
}) => {
  return (
    <div className="grid-control mt-20">
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.specification.measurement_unit", "Measurement Unit")}
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              showEdgeTooltip: true,
              toolTip: "measurementUnit",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-3 w-100"
          selectItems={measurements}
          currentlySelected={selectedMeasurement}
          updateSelect={(val) => onChangeValue("Measurement Unit", val)}
          getLocalised={getLocalised}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised("label.details.specification.built_up_utilised_area", "Built-up / Utilised Area")}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: false,
              showEdgeTooltip: true,
              toolTip: "builtUp",
            }}
          />
        </div>
        <CustomFormInput
          type="number"
          value={builtUp}
          onChangeValue={(val) => onChangeValue("Built Up", val)}
          required={true}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised("label.details.specification.land_area", "Land Area")}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              toolTip: "landArea",
            }}
          />
        </div>
        <CustomFormInput
          type="number"
          value={landArea}
          onChangeValue={(val) => onChangeValue("Land Area", val)}
          required={true}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.specification.carparks", "Carparks")}
          </h2>
          <SubsalesTooltip
            data={{
              showJuwaiToolTip: true,
              toolTip: "carPark",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-3 w-100"
          selectItems={car_park_options}
          currentlySelected={selectedCarparkOption}
          updateSelect={(val) => onChangeValue("Carparks", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.specification.bedrooms", "Bedrooms")}
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              showEdgeTooltip: true,
              toolTip: "bedRoom",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-3 w-100"
          selectItems={bedroom_options}
          currentlySelected={selectedBedroomOption}
          updateSelect={(val) => onChangeValue("Bedrooms", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.specification.bathrooms", "Bathrooms")}
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              showEdgeTooltip: true,
              toolTip: "bathRoom",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-3 w-100"
          selectItems={bathroom_options}
          currentlySelected={selectedBathroomOption}
          updateSelect={(val) => onChangeValue("Bathrooms", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.specification.direction", "Direction")}
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              toolTip: "direction",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-3 w-100"
          selectItems={directions}
          currentlySelected={selectedDirection}
          updateSelect={(val) => onChangeValue("Direction", val)}
          getLocalised={getLocalised}
        />
      </section>
    </div>
  );
};

export default MeasurementInfo;
