import { project as ProjectDictionary } from "dictionary";

export const detailsFields = (type) => [
  {
    label: "Reference ID",
    type: "number",
    key: "reference_id",
  },
  {
    label: "Project display name in website",
    type: "radio",
    options: [
      { value: "project", label: "Project Name" },
      { value: "internal", label: "Internal Name" },
    ],
    key: "display_name_type",
  },
  {
    label: "Project Name",
    key: "name",
    required: true,
  },
  {
    label: "Internal Name",
    placeholder: "Won't be displayed on the website",
    key: "internal_name",
    required: true,
  },
  {
    label: "Display Subtitle",
    key: "display_subtitle",
  },
  {
    label: "Developer",
    key: "developer",
  },
  {
    label: "Phase",
    type: "number",
    placeholder: "Total phases for this project",
    key: "phase",
  },
  {
    label: "Slug",
    key: "slug",
  },
  {
    label: "Project Title",
    type: "select",
    options: ProjectDictionary.title,
    key: "project_title_id",
    required: true,
  },
  {
    label: "Project Type",
    type: "select",
    options: ProjectDictionary.type,
    key: "type_id",
    required: true,
  },
  {
    label: "Tenure Type",
    type: "select",
    options: ProjectDictionary.tenure,
    key: "tenure_type_id",
    required: true,
  },
  {
    label: "Status",
    type: "select",
    options: ProjectDictionary.status,
    key: "status_id",
    hidden: type !== "update",
  },
  {
    label: "Expected Completion Date",
    type: "date",
    key: "completion_date",
  },
  {
    label: "Telegram Link",
    key: "telegram_link",
  },
];

export const specificationFields = [
  {
    label: "Measurement Unit",
    type: "select",
    key: "measurement_id",
    options: ProjectDictionary.measurement,
  },
  {
    label: "Land area",
    type: "number",
    key: "land_area",
  },
  {
    label: "Min built up",
    type: "number",
    key: "min_built_up",
  },
  {
    label: "Max built up",
    type: "number",
    key: "max_built_up",
  },
  {
    label: "Max unit",
    type: "number",
    key: "max_unit",
  },
  {
    label: "Bathroom Display",
    key: "bathroom_display",
  },
  {
    label: "Min of Bathroom",
    type: "number",
    key: "min_bathroom",
  },
  {
    label: "Max of Bathroom",
    type: "number",
    key: "max_bathroom",
  },
  {
    label: "Bedroom Display",
    key: "bedroom_display",
  },
  {
    label: "Min of Bedroom",
    type: "number",
    key: "min_bedroom",
  },
  {
    label: "Max of Bedroom",
    type: "number",
    key: "max_bedroom",
  },
];

export const addressFields = (
  countries,
  states,
  townships,
  onLoadTownships,
  subareas,
  onLoadSubareas,
) => [
  {
    label: "Country",
    type: "select",
    options: countries,
    key: "country_id",
    labelKey: "name",
  },
  {
    label: "State",
    type: "select",
    options: states,
    key: "state_id",
    labelKey: "name",
  },
  {
    label: "Area/City",
    type: "typeahead",
    options: townships,
    key: "township",
    isLoading: onLoadTownships,
  },
  {
    label: "Sub Area",
    type: "typeahead",
    options: subareas,
    key: "sub_area",
    isLoading: onLoadSubareas,
  },
  {
    label: "Latitude",
    type: "number",
    key: "latitude",
  },
  {
    label: "Longitude",
    type: "number",
    key: "longitude",
  },
  {
    label: "Location",
    key: "location",
    span: 2,
  },
  {
    label: "Google Map",
    type: "textarea",
    placeholder: "Embed code from Google Maps",
    key: "gmap",
    span: 2,
  },
];

export const descriptionTabs = [
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Features Description",
    value: "features_description",
  },
  {
    label: "Location Description",
    value: "location_description",
  },
  {
    label: "Overview Description",
    value: "overview_description",
  },
  {
    label: "Title Description",
    value: "title_description",
  },
];

export const priceFields = (required, currencies) => [
  {
    label: "Min Price",
    type: "number",
    key: "net_price_cents",
    required,
  },
  {
    label: "Max Price",
    type: "number",
    key: "max_price_cents",
    required,
  },
  {
    label: "Currency",
    type: "select",
    options: currencies?.name,
    key: "net_price_currency",
    valueKey: "value",
  },
];

export const optionFields = [
  {
    label: "Set as a featured project",
    type: "checkbox",
    key: "is_featured",
  },
  {
    label: "Set as an exclusive project",
    type: "checkbox",
    key: "is_exclusive",
  },
  {
    label: "Enable Live Sales feature",
    type: "checkbox",
    key: "has_live_sales",
  },
  {
    label: "Visible on Web Portal",
    type: "checkbox",
    key: "is_visible_on_web",
  },
];

export const mediaFields = [
  {
    label: "Background Image",
    type: "image",
    key: "background_image",
  },
  {
    label: "Overview Image",
    type: "image",
    key: "overview_image",
  },
  {
    label: "Project Features Image",
    type: "image",
    key: "project_features_image",
  },
  {
    label: "Route Map Image",
    type: "image",
    key: "map_image",
  },
  {
    label: "Location Map Image",
    type: "image",
    key: "hd_map_image",
  },
];
