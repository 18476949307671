import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { AiOutlineExport } from "react-icons/ai";

import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ExportDialog from "./exportStat";

import MOTDStatisticHOC from "./actions";

class MOTDStatistic extends Component {
  componentDidMount = () => this.props.getMOTDLatest();

  renderCardContent = () => (
    <div style={{ position: "relative", height: "70vh" }}>
      <Bar
        data={this.props.latestStats || []}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) =>
                `${data.datasets[tooltipItems.datasetIndex].label}: ` +
                tooltipItems.yLabel,
            },
          },
        }}
      />
    </div>
  );

  render = () => {
    return (
      <>
        <ModuleHeader
          title={`MOTD Statistic`}
          moduleIcon={"icon-motd-statistic"}
          actionButton={[
            {
              label: "Export",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: (
                <AiOutlineExport
                  style={{ height: 18, width: 18, marginRight: 10 }}
                />
              ),
              onClick: () =>
                this.props.onChangeMOTDStatisticHOC(true, "showExportDialog"),
            },
          ]}
        />
        <AtlasCard className={"h-100"} cardContent={this.renderCardContent()} />
        <AtlasDialog open={this.props.showExportDialog}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <ExportDialog
                onClose={() =>
                  this.props.onChangeMOTDStatisticHOC(false, "showExportDialog")
                }
              />
            }
          />
        </AtlasDialog>
        {this.props.onLoadMotdStatistic && <LoadingModal />}
      </>
    );
  };
}

export default MOTDStatisticHOC(MOTDStatistic);
