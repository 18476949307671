import { Dialog } from "@material-ui/core";
import { connect } from "react-redux";

import "stylesheets/components/dialog/index.scss";

const AtlasDialog = ({
  open,
  style,
  children,
  paperClassName,
  className,
  fullScreen = true,
  withFloatPanel,
  data,
}) => {
  const { sideMenuExpand } = data.sideMenuReducer;

  return (
    <Dialog
      disableEnforceFocus={true}
      open={open}
      fullScreen={fullScreen}
      className={`${sideMenuExpand ? "menu-expand" : "w-100"} at-dialog ${withFloatPanel ? "at-dialog-floating_panel" : ""} ${className || ""}`}
      style={{ ...style }}
      classes={{ paper: paperClassName || "at-dialog-paper" }}
    >
      {children}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(AtlasDialog);