import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const BranchRegionHOC = (WrappedComponent) => {
  class BranchRegionWrappedComponent extends Component {
    state = {
      branchRegions: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getBranchRegions = () =>
      Get(
        `/branch_regions`,
        this.getBranchRegionsSuccess,
        this.getBranchRegionsError,
        this.load,
      );
    getBranchRegionsSuccess = (payload) =>
      this.setState({ branchRegions: payload });
    getBranchRegionsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            branchRegions={this.state.branchRegions}
            getBranchRegions={this.getBranchRegions}
            onLoadBranchRegions={this.state.loading}
          />
        </>
      );
    };
  }
  return BranchRegionWrappedComponent;
};

export default BranchRegionHOC;
