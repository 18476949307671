import { Dialog } from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { AiFillFileImage, AiOutlineClear, AiOutlineRedo } from "react-icons/ai";
import { FaShapes } from "react-icons/fa";
import { FiHexagon, FiLifeBuoy } from "react-icons/fi";

import CustomCard from "components/Card";
import ShapeModalContent from "./shapeModal";

import { useFabric } from "context/fabric";
import { requestSuccess } from "utils/requestHandler";

const Fabric = ({
  context,
  formData,
  initialJSONData,
  sampleGraphic,

  onClickSubmit,
  onClickClose,
}) => {
  const filePond = useRef(null);

  const {
    canvas,
    resizeCanvas,
    addShapeClipPath,
    setBGImg,
    adminToolEvents,
    resetZoomView,
    setIsSpanRotation,
    isSpanRotation,
  } = useFabric();

  const [showAddShape, onToggleShape] = useState(false);
  const disabledSubmit = useMemo(() => {
    return formData.title && formData.description;
  }, [formData]);

  const HEIGHT_OFFSET = 66;

  useEffect(() => {
    initialJSONData &&
      canvas &&
      canvas.loadFromJSON(initialJSONData, () => {
        adminToolEvents();
        canvas.renderAll();
      });

    resizeCanvas(formData.type_id, HEIGHT_OFFSET);
  }, [canvas]);

  useEffect(() => {
    if (canvas && formData) {
      if (formData.type_id === 3) {
        canvas.setDimensions({ width: 1754, height: 1754 });
      } else {
        canvas.setDimensions({ width: 1240, height: 1754 });
      }

      resizeCanvas(formData.type_id, HEIGHT_OFFSET);
    }
  }, [formData.type_id]);

  useEffect(() => {
    const handleResize = () => {
      if (canvas && formData.type_id) {
        resizeCanvas(formData.type_id, HEIGHT_OFFSET);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const iconProps = {
    className: "d-block mr-3 bg-dark color-white p-1",
    style: { width: 35, height: 35, cursor: "pointer", borderRadius: 5 },
  };

  const onChangeFilePond = (fileItems) => {
    if (fileItems) {
      Object.keys(fileItems).forEach((key) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setBGImg(e.target.result);
          canvas.setZoom(1);
          adminToolEvents();
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  return (
    <>
      <input
        multiple={false}
        type="file"
        className={"d-none"}
        ref={filePond}
        onChange={(e) => onChangeFilePond(e.target.files)}
        accept="image/png, image/jpg, image/jpeg"
      />
      <div
        id={"canvas-editor"}
        className="d-flex flex-column align-items-center opacity-0 opacity-md-100 p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", height: 800 }}
      >
        <div className="bg-white p-2 d-inline-flex mb-3">
          <AiFillFileImage
            {...iconProps}
            onClick={() => filePond.current.click()}
          />
          <FaShapes {...iconProps} onClick={() => onToggleShape(true)} />
          {isSpanRotation && (
            <FiLifeBuoy
              {...iconProps}
              onClick={() => setIsSpanRotation(false)}
            />
          )}
          {!isSpanRotation && (
            <FiHexagon {...iconProps} onClick={() => setIsSpanRotation(true)} />
          )}
          <AiOutlineRedo {...iconProps} onClick={resetZoomView} />
          <AiOutlineClear
            className="d-block bg-dark color-white p-1"
            style={{
              width: 35,
              height: 35,
              borderRadius: 5,
              cursor:
                canvas && canvas._objects.length > 0
                  ? "pointer"
                  : "not-allowed",
              filter: `brightness(${canvas && canvas._objects.length > 0 ? "1" : "0.5"})`,
            }}
            onClick={() => {
              canvas._objects.length > 0 &&
                canvas.remove(...canvas.getObjects());
            }}
          />
        </div>
        <div
          className={"fabric-canvas-wrapper"}
          style={{ transform: "scale( 0.4 )", transformOrigin: "top center" }}
        >
          <canvas id="canvas"></canvas>
        </div>
      </div>
      <div className="d-flex mt-20">
        <button
          disabled={!disabledSubmit || !sampleGraphic}
          onClick={() => {
            resetZoomView();
            onClickSubmit({
              ...formData,
              content: canvas.toJSON(["id", "shapeType", "shapeLabel"]),
              thumbnail: canvas.toDataURL({ format: "png", quality: 1 }),
              sample: sampleGraphic,
            });
          }}
          className={"btn-new btn-new--success"}
        >
          {context === "create" ? "Create" : "Update"}
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={onClickClose}
        >
          {context === "create" ? "Cancel" : "Close"}
        </button>
      </div>
      <Dialog maxWidth={"md"} open={showAddShape}>
        <CustomCard
          cardContent={
            <ShapeModalContent
              onClickAdd={({ shape, label, type }) => {
                addShapeClipPath(canvas._objects.length, shape, label, type);
                onToggleShape(false);
                requestSuccess("Container added successfuly.");
              }}
              onClickClose={() => onToggleShape(false)}
            />
          }
        />
      </Dialog>
    </>
  );
};

export default Fabric;
