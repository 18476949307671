import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      unclaimedConfirmationForms: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeConfirmationFormHOC = ( value, context ) => this.setState({ [context]: value })

    getUnclaimConfirmationForms = (representative_id = "") => {
      let url = `/forms/confirmation_forms/unclaimed?representative_id=${representative_id}`;
      Get(
        url,
        this.getUnclaimConfirmationFormsSuccess,
        this.getUnclaimConfirmationFormsError,
        this.load,
      );
    };
    getUnclaimConfirmationFormsSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.transaction_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedConfirmationForms: tmp });
      }
    };
    getUnclaimConfirmationFormsError = (error) => {
      this.setState({ unclaimedConfirmationForms: [] });
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            unclaimedConfirmationForms={this.state.unclaimedConfirmationForms}
            onLoadConfirmationForms={this.state.loading}
            onChangeConfirmationFormHOC={this.onChangeConfirmationFormHOC}
            getUnclaimConfirmationForms={this.getUnclaimConfirmationForms}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
