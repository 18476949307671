import React, { useState } from "react";
import { MdFilterList } from "react-icons/md";

import TableSearchParams from "components/NewPaginatedTable/SearchParams";

const SearchParams = ({
  searchParams,

  getListAPI,
  onChangeSearchParams,
}) => {
  const [showSearchContent, setShowSearchContent] = useState(false);

  return (
    <>
      <button
        className={
          "btn-new btn-new--outline-secondary at-mobile-view__controller mb-3"
        }
        style={{ width: "100%" }}
        onClick={() => setShowSearchContent((prevState) => !prevState)}
      >
        <MdFilterList style={{ width: 20 }} />
        <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
          Filter & Search
        </h5>
      </button>
      <div className="at-table__search-cont at-desktop-view__controller mb-3">
        <TableSearchParams
          mode={"desktop"}
          searchParams={searchParams}
          onChangeSearchParams={onChangeSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={(page, search) => getListAPI(search)}
        />
      </div>
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          onChangeSearchParams={onChangeSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={(page, search) => getListAPI(search)}
        />
      )}
    </>
  );
};

export default SearchParams;
