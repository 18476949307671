import React, { Component } from "react";
import { Card } from "@material-ui/core";
import _ from "lodash";

import AtlasCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasTextarea from "components/Input/textarea";
import CustomTable from "components/NewTable";
import AtlasButton from "components/Button";
import AtlasDialog from "components/Dialog";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

import { getColorBadge } from "dictionary/badgeColor";

const ColumnData = [
  {
    header: "File Name",
    searchFlag: "document_file_name",
    accessor: "document_file_name",
    columnStyle: { width: "70%" },
  },
  {
    header: "Created Date",
    searchFlag: "uploaded_datetime",
    accessor: "uploaded_datetime",
  },
];

export const unlockRequestStatusColor = [
  { value: "Pending", colorName: "Yellow" },
  { value: "Approved", colorName: "Green" },
  { value: "Rejected", colorName: "Blue" },
  { value: undefined, colorName: "Grey" },
];

class DetailsModal extends Component {
  onChangeRequestInfo = (val, context) => {
    let temp = this.props.selectedUnlockRequest;
    temp[context] = val;
    this.props.onChangeUnlockHOC(temp, "selectedUnlockRequest");
  };

  onClickDocDetails = (url) => window.open(url, "_blank");

  renderModalContent = () => {
    const {
      remark,
      status_id,
      reason,
      status,
      admin_display_name,
      requested_datetime,
    } = this.props.selectedUnlockRequest;
    const {
      supportingDocs,
      onChangeUnlockHOC,
      updateUnlockRequest,
      selectedUnlockRequest,
      selectedConfirmationForm,
    } = this.props;

    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { colorName = "" } =
      _.find(unlockRequestStatusColor, { value: status }) || {};
    return (
      <div>
        <div className="d-flex align-items-center flex-wrap grid_gap-1 mb-3">  
          <AtlasCloseButton
            containerStyle={{ marginBottom: 8 }}
            onClick={() =>
              onChangeUnlockHOC(false, "showUnlockRequestDetailsModal")
            }
          />
          <h4 className="at-card__title">{"Confirmation Form Unlock Request"}</h4>
        </div>
        <div className="at-form__content d-flex flex-column grid_gap-y-2">
          <section className={"at-form__content at-new_container-12"}>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Status: `}</label>
              <p
                className="at-status_badge"
                style={{ ...getColorBadge(colorName) }}
              >
                {status}
              </p>
            </div>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Admin: `}</label>
              <p>{admin_display_name}</p>
            </div>
            <div className="at-confirmation_form-unlock_request_info">
              <label className="at-form-input__title">{`Date created: `}</label>
              <p>{requested_datetime}</p>
            </div>
          </section>
          <section className="at-form__content">
            <h2 className="at-form-input__title">Reason</h2>
            <p>{reason || "-"}</p>
          </section>
          <section className="at-form__content">
            <h2 className="at-form-input__title">Admin Remarks</h2>
            <p>{remark || "-"}</p>
          </section>
          <section className={"d-flex flex-wrap grid_gap-1 mb-3"}>
            <AtlasButton
              className={"btn-new btn-new--success"}
              children={"Approve"}
              disabled={
                selectedUnlockRequest.admin_id !== tmpProfileReducer.id ||
                status_id !== 1
              }
              onClick={() =>
                updateUnlockRequest(
                  { status_id: 2, remark },
                  selectedConfirmationForm.id,
                  selectedUnlockRequest.id,
                )
              }
            />
            <AtlasButton
              className={"btn-new btn-new--danger"}
              children={"Reject"}
              disabled={
                selectedUnlockRequest.admin_id !== tmpProfileReducer.id ||
                status_id !== 1
              }
              onClick={() => onChangeUnlockHOC(true, "showRejectRequestModal")}
            />
          </section>
          <CustomTable
            columns={ColumnData}
            pagination={true}
            rowData={supportingDocs || []}
            actionColumnContent={[
              {
                name: "details",
                onClick: (rowData) =>
                  this.onClickDocDetails(rowData.document_url),
              },
            ]}
            renderExtraContent={() => (
              <div className={`at-table_heading`}>
                <AtlasIcon
                  className="at-icon_svg-table_heading-title"
                  svgHref="atlas-document-text"
                />
                <h2 className="at-form-input__title mt-10">
                  Supporting Documents
                </h2>
              </div>
            )}
          />
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <>
        <AtlasDialog open={this.props.open}>
          <AtlasCard className="h-100" cardContent={this.renderModalContent()} />
        </AtlasDialog>
        {this.props.onLoadUnlock && <LoadingModal />}
      </>
    );
  };
}

export default DetailsModal;
