import Axios from "axios";
import Cookies from "js-cookie";

import {
  beginAjaxCallImpersonate,
  ajaxCallErrorImpersonate,
  ajaxCallSuccessImpersonate,
  beginAjaxCallToken,
  ajaxCallTokenSuccess,
  ajaxCallTokenError,
} from "./ajax";
import { AUTH_ADMIN_LOGIN } from "./type";
import { storeItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";

export function adminLogin({ email, id }) {
  return (dispatch) => {
    dispatch(beginAjaxCallImpersonate(false));
    Axios.post(`${getDomainURL()}/admin/users/impersonate`, {
      email,
    })
      .then((response) => {
        storeItem("IQI_ATLAS_JWT_AGENT_TOKEN", response.data.auth_token);
        storeItem("IQI_ATLAS_REFRESH_AGENT_TOKEN", response.data.refresh_token);
        storeItem("IQI_ATLAS_IMPERSONATOR_EMAIL", response.data.impersonator_email);

        Cookies.set(
          "IQI_ATLAS_AGENT_TOKEN_EXPIRY_TIME",
          response.data.expire_time
        );
        dispatch(
          authUserSuccess({
            login_id: id,
            prefix_path: `/admin-impersonate/${id}`,
            current_sign_in_token: response.data.auth_token,
            current_sign_in_token_expiry_time: response.data.expire_time,
            current_sign_in_refresh_token: response.data.refresh_token,
            current_sign_in_impersonator_email: response.data.impersonator_email,
          })
        );

        dispatch(ajaxCallSuccessImpersonate("Login Success!"));
      })
      .catch((error) => {
        dispatch(ajaxCallErrorImpersonate(error));
      });
  };
}

const authUserSuccess = (payload) => ({
  type: AUTH_ADMIN_LOGIN,
  payload,
});

export const refreshAgentToken = () => (dispatch) => {
  dispatch(beginAjaxCallToken());
  Axios.post(`${getDomainURL()}/tokens/refresh`, {
    refresh_token: localStorage.getItem("IQI_ATLAS_REFRESH_AGENT_TOKEN"),
  })
    .then((response) => {
      Cookies.set(
        "IQI_ATLAS_AGENT_TOKEN_EXPIRY_TIME",
        response.data.expire_time
      );
      Promise.all([
        localStorage.setItem(
          "IQI_ATLAS_JWT_AGENT_TOKEN",
          response.data.access_token
        ),
        localStorage.setItem(
          "IQI_ATLAS_REFRESH_AGENT_TOKEN",
          response.data.refresh_token
        ),
      ]).then(() => {
        dispatch(ajaxCallTokenSuccess());
        dispatch(authUserSuccess(response.data));
      });
    })
    .catch((error) => {
      dispatch(ajaxCallTokenError());
      let alerted =
        localStorage.getItem("IQI_ATLAS_OUTDATED_AGENT_TOKEN") || "";
      if (
        error.response &&
        (error.response.data.message === "Token not found" ||
          error.response.data.message === "Token expired")
      ) {
        Cookies.remove("IQI_ATLAS_AGENT_TOKEN_EXPIRY_TIME");
        localStorage.removeItem("IQI_ATLAS_JWT_AGENT_TOKEN");
        localStorage.removeItem("IQI_ATLAS_REFRESH_AGENT_TOKEN");
        localStorage.removeItem("IQI_ATLAS_IMPERSONATOR_EMAIL");
        if (alerted !== "yes") {
          alert(
            "Due to inactivity, your session has expired, please login again."
          );
          localStorage.setItem("IQI_ATLAS_OUTDATED_AGENT_TOKEN", "yes");
        }
        window.location.reload();
      }
    });
};
