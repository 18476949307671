import React, { Component } from "react";
import _ from "lodash";
import { Grid, Card, Snackbar } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import AtlasCloseButton from "components/Button/prev";
import AtlasDialog from "components/Dialog";
import checkValid from "utils/checkValid";
import DocumentElement from "./documentElement";
import { StatusColor } from "../../../assets";
import { getColorBadge } from "dictionary/badgeColor";

const ClaimTypeOptions = [
  { id: 1, name: "Booking Form", value: 1, uploadLabel: "Booking Form upload" },
  {
    id: 2,
    name: "Extension",
    value: 2,
    uploadLabel: "Extension Form document_url upload",
  },
  {
    id: 3,
    name: "Add Name",
    value: 3,
    uploadLabel: "Add Name Form/IC COLOUR copy upload",
  },
  {
    id: 4,
    name: "Change Unit",
    value: 4,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 5,
    name: "Cancellation",
    value: 5,
    uploadLabel: "Cancellation Form/Rejection Letter upload",
  },
  {
    id: 6,
    name: "EPF",
    value: 6,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 7,
    name: "Deferment",
    value: 7,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 8,
    name: "Reimbursement",
    value: 8,
    uploadLabel: "Letter of Authorization Form upload",
  },
  {
    id: 9,
    name: "Change Contact Details",
    value: 9,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 10,
    name: "Others",
    value: 10,
    uploadLabel: "Others (optional for document)",
  },
];

class Detail extends Component {
  state = {
    selectedDocument: {
      document: "",
      document_file_name: "",
    },
    showDocumentModal: false,
    selectedRequestDetail: {
      remark: "",
      admin_remark: "",
      documents: [],
      type_id: null,
    },
  };

  componentDidMount = () =>
    this.setState({ selectedRequestDetail: this.props.selectedRequest });

  onChangeRequest = (val, context) => {
    let tmp = _.cloneDeep(this.state.selectedRequestDetail);
    tmp[context] = val;

    return this.setState({ selectedRequestDetail: tmp });
  };

  renderDocumentContent = () => {
    const { selectedRequestDetail } = this.state;
    let tempUploadTitle = _.find(ClaimTypeOptions, {
      id: selectedRequestDetail.type_id,
    });
    let tempNonDestroyAgent = _.filter(
      selectedRequestDetail.documents,
      (item) => item._destroy !== "1",
    );

    return (
      <>
        <h2 className="at-form-input__title">
          {tempUploadTitle ? tempUploadTitle.uploadLabel : "Upload"}
        </h2>
        {tempNonDestroyAgent.length > 0 && (
          <Card style={{ padding: 15 }}>
            <Grid spacing={16} container>
              {tempNonDestroyAgent.map((doc, index) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DocumentElement
                      doc={doc}
                      docNameProps={"file_name"}
                      onClickOpenDoc={() =>
                        this.setState({
                          showDocumentModal: true,
                          selectedDocument: doc,
                        })
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        )}
      </>
    );
  };

  render = () => {
    const { selectedSale } = this.props;
    let { selectedRequestDetail } = this.state;
    const { colorName = "" } =
      _.find(StatusColor, { value: selectedRequestDetail.status }) || {};

    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 10 }}
          tooltip={false}
          disabled={true}
          onClick={() =>
            this.props.onChangeRequestHOC("showRequestDetails", false)
          }
        />
        <h4 className="at-card__title p-0">{"Edit Request"}</h4>
        <hr />
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Request Status"}</h2>
            <div
              className="at-status_badge"
              style={{ ...getColorBadge(colorName) }}
            >
              {selectedRequestDetail.status}
            </div>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Assigned To"}</h2>
            <i>{selectedSale.assigned_admin}</i>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Request Type"}</h2>
            <i>{selectedRequestDetail.type}</i>
          </section>
          <section className="grid-full-col">
            {this.renderDocumentContent()}
          </section>
          <section className="grid-full-col mt-20">
            <h2 className="at-form-input__title">{"Remark"}</h2>
            <AtlasTextarea
              value={selectedRequestDetail.remark}
              disabled={true}
              onChangeValue={(val) => this.onChangeRequest(val, "remark")}
            />
          </section>
          <section className="grid-full-col mt-20">
            <h2 className="at-form-input__title">{"Admin Remark"}</h2>
            <AtlasTextarea
              value={selectedRequestDetail.admin_remark}
              disabled={true}
              onChangeValue={(val) =>
                this.onChangeNewRequest(val, "admin_remark")
              }
            />
          </section>
          <section className="grid-full-col">
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() =>
                this.props.onChangeRequestHOC("showRequestDetails", false)
              }
            >
              Close
            </button>
          </section>
        </form>
        <AtlasDialog open={this.state.showDocumentModal}>
          <AtlasCloseButton
            onClick={() => this.setState({ showDocumentModal: false })}
            containerStyle={{
              position: "absolute",
              right: 35,
              top: 80,
            }}
          />
          {checkValid(this.state.selectedDocument.url).indexOf("pdf") > -1 && (
            <iframe
              className={"w-100 h-100"}
              src={this.state.selectedDocument.url}
            ></iframe>
          )}
          {checkValid(this.state.selectedDocument.url).indexOf("pdf") ===
            -1 && (
            <img
              src={this.state.selectedDocument.url}
              alt="booking form image"
            />
          )}
        </AtlasDialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ showSnackbar: false })}
          message="Maximum file number can be 5 only."
        />
        {this.props.onLoadRequests && <LoadingModal />}
      </>
    );
  };
}

export default Detail;
