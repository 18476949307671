import React, { Component } from "react";
import Moment from "moment";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import EmptyState from "components/EmptyState";
import TimelineComponent from "components/Timeline";
import AtlasCloseButton from "components/Button/close";
import AtPaginatedTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";

import SubsalesActivity from "../actions/activity";

const columnData = [
  {
    header: "Updated At",
    renderColumn: (rowData) =>
      Moment(rowData.updated_at).format("DD MMM YYYY hh:mm"),
  },
  { header: "Mobile No", accessor: "mobile_number" },
  { header: "Status", accessor: "status" },
];

class Activity extends Component {
  state = {
    selectedActivity: {},
    showMessageLog: false,
  };

  componentDidMount = () =>
    this.props.getActivities(this.props.selected_claim.id);

  renderCardContent = () => (
    <>
      <div className="d-flex">
        <h2 className="at-form-input__title">Message Logs</h2>
        <AtlasCloseButton
          containerStyle={{ display: "flex", marginLeft: "auto" }}
          onClick={() => this.setState({ showMessageLog: false })}
        />
      </div>
      <hr />
      <AtPaginatedTable
        columns={columnData}
        rowData={this.state.selectedActivity.message_logs || []}
        meta={null}
        searchParams={[]}
        totalPages={[]}
      />
    </>
  );

  render = () => {
    const { activities } = this.props;
    return (
      <div className="at-subsales_claims-timeline-cont">
        {activities &&
          activities.length > 0 &&
          activities.map((item) => {
            return (
              <section key={item.id} className="at-subsales_claims-timeline">
                <TimelineComponent
                  key={item.id}
                  content={item.content}
                  messageLogs={
                    item.message_logs && item.message_logs.length > 0
                  }
                  onClickLoadMore={() =>
                    this.setState({
                      selectedActivity: item,
                      showMessageLog: true,
                    })
                  }
                  createdAt={item.created_at}
                />
              </section>
            );
          })}
        {!(activities && activities.length > 0) && (
          <EmptyState title={"No data presence"} />
        )}
        <Dialog
          maxWidth={"xl"}
          classes={{ paper: "m-0" }}
          open={this.state.showMessageLog}
        >
          <AtlasCard
            containerStyle={{ minWidth: 300, width: "80vw" }}
            cardContent={this.renderCardContent()}
          />
        </Dialog>
        {this.props.onLoadActivity && <LoadingModal />}
      </div>
    );
  };
}

export default SubsalesActivity(Activity);
