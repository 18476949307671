import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import { Get, Post } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      motd: {},
      showMOTD: false,
      currentDate: Moment(new Date()).format("YYYY-MM-DD"),
    };

    load = (param) => this.setState({ loading: param });

    onChangeMOTDModalHOC = (val, context) => this.setState({ [context]: val });

    getMOTD = () => {
      if (
        !this.props.data.motdReducer.lastOpenDateTime ||
        this.props.data.motdReducer.lastOpenDateTime != this.state.currentDate
      ) {
        Get(
          `/quote_audios/quote_today?platform_id=1&current_date=${this.state.currentDate}`,
          this.getMOTDSuccess,
          this.getMOTDError,
          this.load,
        );
      }
    };
    getMOTDSuccess = (payload) => {
      if (!_.isEmpty(payload)) {
        let temp = _.cloneDeep(payload);
        temp["audio"] = new Audio(temp.audio_url);

        this.setState(
          {
            showMOTD: true,
            motd: temp,
          },
          () => this.props.updateLastOpenMotd(this.state.currentDate),
        );
      }
    };
    getMOTDError = (error) => this.setState({ showMOTD: false });

    getMOTDTrigger = () =>
      Get(
        `/quote_audios/view_quote?platform_id=1&current_date=${this.state.currentDate}`,
        this.getMOTDTriggerSuccess,
        this.getMOTDTriggerError,
        this.load,
      );
    getMOTDTriggerSuccess = (payload) => {
      let temp = _.cloneDeep(payload);
      temp["audio"] = new Audio(temp.audio_url);
      this.setState({
        showMOTD: true,
        motd: temp,
      });
    };
    getMOTDTriggerError = (error) => this.setState({ showMOTD: false });

    updateMOTDActivity = (action) =>
      Post(
        `/quote_audios/activity?current_date=${this.state.currentDate}&platform_id=1&activity_id=${action}`,
        {},
        this.updatMOTDActivitySuccess,
        this.updateMOTDActivityError,
        this.load,
      );
    updatMOTDActivitySuccess = () => {};
    updateMOTDActivityError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadMOTD={this.state.loading}
          showMOTD={this.state.showMOTD}
          motd={this.state.motd}
          getMOTD={this.getMOTD}
          updateMOTDActivity={this.updateMOTDActivity}
          getMOTDTrigger={this.getMOTDTrigger}
          onChangeMOTDModalHOC={this.onChangeMOTDModalHOC}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
