import _ from "lodash";

import { GET_ALL_AGENCIES } from "actions/type";

let initialState = {
  agencies: [],
};

export default function agencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AGENCIES:
      let tmpAgencies = _.cloneDeep(action.payload);
      let tmpDataStore = [];
      tmpAgencies.map((item) => {
        return tmpDataStore.push({
          ...item,
          label: item.name,
        });
      });
      return {
        ...state,
        agencies: tmpDataStore,
      };
    default:
      return state;
  }
}
