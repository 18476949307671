import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Route, Redirect, Switch } from "react-router-dom";
import { IoMdCart } from "react-icons/io";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import PaymentGatewayFeedback from "./PaymentGatewayFeedback";
import ProductCatalog from "./ProductCatalog";
import OrderHistory from "./OrderHistory";
import MyCoupon from "./MyCoupon";
import MyCart from "./MyCart";

import { storeLastView } from "actions/lastView";
import { getMyCart } from "actions/myCart";

const tabOptions = [
  {
    label: "Product Catalog",
    redirect_url: "/marketing-centre/products-catalog",
  },
  {
    label: "My Coupons",
    redirect_url: "/marketing-centre/my-coupon",
  },
  {
    label: "Order History",
    redirect_url: "/marketing-centre/order-history",
  },
];

const getSelectedTab = () => {
  let selected_tab = tabOptions.find(
    (item) => window.location.href.indexOf(item.redirect_url) > 0,
  );
  return selected_tab ? selected_tab.label : "Product Catalog";
};

const MarketingCenter = ({
  data,
  history,

  getMyCart,
}) => {
  let unlisten = "";
  const { role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const prefix_path = history.location.pathname.includes("admin-impersonate")
    ? `/${window.location.hash.split("/").slice(1, 3).join("/")}`
    : "";

  useEffect(() => {
    unlisten = history.listen((location, action) => {
      const { searchParams = "", currentPage = 0 } =
        data.lastViewReducer.lastView;
      getMyCart(currentPage, searchParams);
    });

    return () => unlisten && unlisten();
  }, []);

  const renderCardContent = () => (
    <div className="at-table__options mb-10">
      <div className="at-marketing_centre-tab_container">
        <CustomTab
          sections={tabOptions}
          selectedSection={getSelectedTab()}
          onSelectSection={(val) => {
            let temp = tabOptions.find((item) => item.label === val);
            history.push(prefix_path + temp.redirect_url);
          }}
        />
        <Switch>
          <Route
            path={"/marketing-centre/products-catalog"}
            component={ProductCatalog}
          />
          <Route path={"/marketing-centre/my-coupon"} component={MyCoupon} />
          <Route
            path={"/marketing-centre/order-history"}
            component={OrderHistory}
          />
          <Route path={"/marketing-centre/my-cart"} component={MyCart} />
          <Route
            path={"/marketing-centre/order-history/payment-feedback/:status"}
            component={PaymentGatewayFeedback}
          />
          <Redirect
            exact
            from={"/marketing-centre"}
            to={"/marketing-centre/products-catalog"}
          />

          <Route
            path={
              "/admin-impersonate/:profileId/marketing-centre/products-catalog"
            }
            component={ProductCatalog}
          />
          <Route
            path={"/admin-impersonate/:profileId/marketing-centre/my-coupon"}
            component={MyCoupon}
          />
          <Route
            path={
              "/admin-impersonate/:profileId/marketing-centre/order-history"
            }
            component={OrderHistory}
          />
          <Route
            path={"/admin-impersonate/:profileId/marketing-centre/my-cart"}
            component={MyCart}
          />
          <Route
            path="/admin-impersonate/:profileId/marketing-centre/payment-feedback/:status"
            component={PaymentGatewayFeedback}
          />
          <Redirect
            exact
            from={"/admin-impersonate/:profileId/marketing-centre"}
            to={
              "/admin-impersonate/:profileId/marketing-centre/products-catalog"
            }
          />
        </Switch>
      </div>
    </div>
  );

  return (
    <>
      <ModuleHeader
        title={"Atlas Store"}
        atlasIcon={"atlas-bag"}
        showPABadge={role === "Personal Assistant"}
        actionButton={[
          {
            label: (
              <>
                My Cart
                <div className="at-my_cart-badge">
                  {data.myCartReducer.totalCount || 0}
                </div>
              </>
            ),
            onShow: true,
            hideTooltip: true,
            icon: (
              <IoMdCart
                style={{ width: "20px", height: "20px", marginRight: 5 }}
              />
            ),
            onClick: () =>
              history.push(`${prefix_path}/marketing-centre/my-cart`),
          },
        ]}
      />
      <CustomCard cardContent={renderCardContent()} />
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, {
  storeLastView,
  getMyCart,
})(MarketingCenter);
