import React, { Component } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

import CustomSelect from "components/Select";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTableHeader from "./components/header";
import CustomPagination from "components/Pagination";
import CustomTableContent from "./components/content";

import "stylesheets/components/table/index.scss";

class CustomTable extends Component {
  constructor(p) {
    super();
    this.state = {
      searching: false,
      searchKeyword: "",
      selectPerPageOption: 10,
      selectHeaderData: "",
      sortStatus: "",
      paginationInitialCount: 1,
      totalPaginationCount: 0,
      totalPages: [1],
      clickPageNumber: 1,
      headerRearrangeData: {},
      rowData: [],
      initialRowData: [],
      searchWithField: "",
      searchWithFieldDisplay: "",
    };
    this.debounceService = _.debounce(this.debounceService, 300);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.rowData !== prevProps.rowData) {
      return this.setState(
        {
          rowData: this.props.rowData,
          initialRowData: this.props.rowData,
          clickPageNumber: 1,
          searchKeyword: "",
        },
        () =>
          this.processPagesData(
            this.state.rowData,
            this.state.selectPerPageOption
          )
      );
    } else {
      if (this.props.rowData.length > 0) {
        let dataUpdated = false;
        this.props.rowData.map((item, index) => {
          let tmp = prevProps.rowData[index];
          Object.keys(item).map((key) => {
            if (tmp[key] !== item[key]) {
              dataUpdated = true;
            }
          });
        });
        if (dataUpdated) {
          return this.setState(
            {
              rowData: this.props.rowData,
              initialRowData: this.props.rowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption
              )
          );
        }
      }
    }

    if (this.props.headerData !== prevProps.headerData) {
      this.setState({
        searchWithField: this.props.headerData[0].value,
        searchWithFieldDisplay: this.props.headerData[0].label,
      });
    }
  };

  componentDidMount = () => {
    this.handleResize();
    this.setState(
      {
        rowData: this.props.rowData,
        initialRowData: this.props.rowData,
        searchWithField: this.props.searchWithField
          ? this.props.searchWithField
          : this.props.headerData[0].value,
        searchWithFieldDisplay: this.props.searchWithField
          ? this.props.searchPlaceholder
          : this.props.headerData[0].label,
        selectPerPageOption: this.props.selectPerPageOption
          ? this.props.selectPerPageOption
          : 10,
      },
      () => {
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption
        );
      }
    );

    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    return () => window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ selectPerPageOption: 3 });
    } else {
      this.setState({ selectPerPageOption: this.props.selectPerPageOption });
    }
    this.processPagesData(this.state.rowData, this.state.selectPerPageOption);
  };

  debounceService = (searchVal) => {
    const { searchWithField } = this.state;
    let result = _.filter(this.state.initialRowData, function (obj) {
      if (obj[searchWithField] && obj[searchWithField] !== null) {
        if (typeof obj[searchWithField] === "number") {
          let tmpObjSearchWithFieldStr = obj[searchWithField].toString();
          return tmpObjSearchWithFieldStr.indexOf(searchVal) > -1;
        } else {
          return obj[searchWithField].toLowerCase().indexOf(searchVal) > -1;
        }
      }
    });
    return this.setState(
      {
        rowData: result,
        searching: false,
      },
      () =>
        this.processPagesData(
          this.state.rowData,
          this.state.selectPerPageOption
        )
    );
  };

  processPagesData = (rowData, pageOption) => {
    if (rowData) {
      let paginationSections = rowData.length / pageOption;
      let pagesArrayData = [];
      paginationSections = (Math.floor(paginationSections) + 1).toFixed(0);
      for (let item = 1; item <= paginationSections; item++) {
        pagesArrayData.push(item);
      }
      return this.setState({
        currentPageNumber: 1,
        totalPaginationCount: paginationSections,
        totalPages: pagesArrayData,
      });
    }
  };

  onChangeSearchValue = (val) => {
    let searchVal = val.toLowerCase();
    return this.setState(
      {
        searchKeyword: searchVal,
      },
      () => {
        if (this.state.searchKeyword === "") {
          this.setState(
            {
              rowData: this.state.initialRowData,
            },
            () =>
              this.processPagesData(
                this.state.rowData,
                this.state.selectPerPageOption
              )
          );
        } else {
          this.debounceService(searchVal);
        }
      }
    );
  };

  onClickPageNumber = (val) => this.setState({ clickPageNumber: val });

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData !== undefined && columnData !== null) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode
          ),
        }));
      }
    });

  selectSearchHeader = (val) =>
    this.setState({
      searchWithField: val.value,
      searchWithFieldDisplay: val.label,
    });

  renderTableContent = () => {
    const {
      headerData,
      actionColumn,
      rowKey,
      actionColumnContent,
      booleanColumn,
      mobileColumn,
      thousandSeparatorColumn,
      viewDocTooltip,
      secondLayerRow,
      disabledDelete,
      clickContent,
      disabledEdit,
    } = this.props;
    if (this.state.rowData && this.state.rowData.length > 0) {
      return this.state.rowData.map((rowItem, index) => {
        if (
          index + 1 >
            (this.state.clickPageNumber - 1) * this.state.selectPerPageOption &&
          index + 1 <=
            this.state.clickPageNumber * this.state.selectPerPageOption
        ) {
          return (
            <CustomTableContent
              key={index}
              headerData={headerData}
              clickContent={clickContent}
              actionColumn={actionColumn}
              actionColumnContent={actionColumnContent}
              rowItem={rowItem}
              viewDocTooltip={viewDocTooltip}
              disabledDelete={disabledDelete}
              disabledEdit={disabledEdit}
              approveClick={(param) => this.props.approveClick(param)}
              detailsClick={(param) => this.props.detailsClick(param)}
              onClickViewDoc={(param) => this.props.onClickViewDoc(param)}
              editClick={(param) => this.props.editClick(param)}
              deleteClick={(param) => this.props.deleteClick(param)}
              updateFixedClick={(param) => this.props.updateFixedClick(param)}
              updateCalendarClick={(param) =>
                this.props.updateCalendarClick(param)
              }
              updateAcceptedClick={(param) =>
                this.props.updateAcceptedClick(param)
              }
              checkInClick={(param) => this.props.checkInClick(param)}
              checkOutClick={(param) => this.props.checkOutClick(param)}
              transferTicket={(param) => this.props.transferTicket(param)}
              downloadClick={this.props.downloadClick}
              rowKey={rowKey}
              headerRearrangeData={this.state.headerRearrangeData}
              booleanColumn={booleanColumn}
              mobileColumn={mobileColumn}
              thousandSeparatorColumn={thousandSeparatorColumn}
              secondLayerRow={secondLayerRow}
            />
          );
        }
      });
    } else if (!this.state.searchKeyword) {
      return (
        <EmptyState
          title={"No Underperforming Members Found"}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      );
    } else {
      return (
        <div className="at-table-row-cont">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p className="mx-auto">No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
            </li>
          </ul>
        </div>
      );
    }
  };

  renderPaginationText = () => {
    const { pagination, hidePagination, rowData } = this.props;
    const processFloorPaginationCount = () => {
      let pageNumTmp = this.state.clickPageNumber;
      if (pageNumTmp > 1) {
        pageNumTmp = (pageNumTmp - 1) * this.state.selectPerPageOption + 1;
      }
      return pageNumTmp;
    };
    const processCeilingPaginationCount = () => {
      let pageNumTmp = this.state.selectPerPageOption;
      if (this.state.clickPageNumber > 1) {
        pageNumTmp = this.state.clickPageNumber * pageNumTmp;
      }
      if (pageNumTmp > this.state.rowData.length) {
        pageNumTmp = this.state.rowData.length;
      }
      return pageNumTmp;
    };
    return (
      <p
        className={`at-table__pagination-info ${hidePagination ? "d-none" : ""}`}
      >
        Showing
        <span className="mx-1">
          {pagination
            ? processFloorPaginationCount(this.state.clickPageNumber)
            : ""}
        </span>
        -
        <span className="mx-1">
          {pagination
            ? processCeilingPaginationCount(this.state.selectPerPageOption)
            : ""}
        </span>
        of
        <span> {rowData ? rowData.length : 0} </span>
        results
      </p>
    );
  };

  renderPagination = () => {
    return (
      <CustomPagination
        totalPages={this.state.totalPages}
        chosenPageNumber={this.state.selectPerPageOption}
        onClickPageNumber={(val) => this.onClickPageNumber(val)}
        currentPageNumber={this.state.clickPageNumber}
      />
    );
  };

  render = () => {
    const {
      className,
      headerData,
      actionColumn,
      actionColumnContent,
      additionalFilter,
      renderTabFilter,
    } = this.props;
    return (
      <div className={`d-flex flex-column mb-20 ${className}`}>
        <div className="at-table at-table--set">
          {renderTabFilter && (
            <div
              className="at-table-head"
              style={{ display: "flex", padding: 0 }}
            >
              {renderTabFilter()}
            </div>
          )}
          {!this.props.hideSearch && (
            <div className="at-table-head" style={{ display: "flex" }}>
              <div className="grid-control w-100">
                <div className="grid-half-col">
                  <div className="at-select__search-cont">
                    <CustomSelect
                      className="at-select_new-interface"
                      selectItems={headerData}
                      labelKey={"label"}
                      valueKey={"value"}
                      currentlySelected={{ value: this.state.searchWithField }}
                      updateSelect={(val) => this.selectSearchHeader(val)}
                    />
                    <input
                      type="search"
                      key={"searchInput"}
                      value={this.state.searchKeyword}
                      className={"at-table__search-input"}
                      placeholder={`Search with ${this.state.searchWithFieldDisplay}`}
                      onChange={(event) =>
                        this.onChangeSearchValue(event.target.value)
                      }
                      disabled={this.state.searching}
                    />
                  </div>
                </div>
                {additionalFilter && (
                  <div className="grid-half-col">{additionalFilter}</div>
                )}
              </div>
              <PulseLoader
                className={"mt-10"}
                sizeunit={"px"}
                size={10}
                color={"#16232c"}
                loading={this.state.searching}
              />
            </div>
          )}
          <CustomTableHeader
            data={headerData}
            actionColumn={actionColumn}
            actionColumnContent={actionColumnContent}
            onClickRearrangeData={this.onClickRearrangeData}
          />
          {this.renderTableContent()}
          {this.state.rowData && this.state.rowData.length > 0 && (
            <div className="at-pagination__cont">
              {this.renderPagination()}
              {this.renderPaginationText()}
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default CustomTable;
