import React from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";

import AtlasIcon from "components/Icon/atlasIcon";

const ReportSearch = ({
  selectedEndDate,
  selectedStartDate,
  onSelectStartDate,
  onSelectEndDate,

  onClickReset,
  onClickSubmit,
}) => {
  return (
    <div className={"at-form__content"}>
      <div className="row">
        <div className="col-12 col-md-4">
          <label className="at-form-input__title">Start date</label>
          <div className="at-form-input mb-0">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              showYearDropdown
              dateFormat={"yyyy-MM-DD"}
              className="at-form-input__input"
              value={selectedStartDate}
              selected={selectedStartDate ? new Date(selectedStartDate) : ""}
              placeholderText={"Select start date"}
              maxDate={new Date()}
              onChange={(val) => onSelectStartDate(val)}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label className="at-form-input__title">End date</label>
          <div className="at-form-input mb-0">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              showYearDropdown
              dateFormat={"yyyy-MM-DD"}
              className="at-form-input__input"
              value={selectedEndDate}
              placeholderText={"Select end date"}
              maxDate={new Date()}
              onChange={(val) => onSelectEndDate(val)}
            />
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex align-items-end mt-2 g-3">
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => {
              onSelectEndDate("");
              onSelectStartDate("");
              onClickReset();
            }}
          >
            Reset
          </button>
          <button
            className="btn-new btn-new--secondary"
            onClick={() => onClickSubmit()}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportSearch;
