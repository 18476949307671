import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { FaRegChartBar } from "react-icons/fa";
import { BiTable } from "react-icons/bi";
import { Bar } from "react-chartjs-2";
import { MdFilterList } from "react-icons/md";

import CustomTable from "components/NewTable";
import IOSButton from "components/Button/toggle";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";

import { numberWithCommas } from "utils/thousandSeparator";
import { actionList } from "../assets";

const intervalOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const columnData = [
  {
    header: "Date",
    accessor: "date",
    searchFlag: "date",
    columnStyle: { width: "80%" },
  },
  {
    header: "Action",
    accessor: "action",
    searchFlag: "action",
    columnStyle: { width: "150%" },
  },
  {
    header: "Total Hits",
    accessor: "total_hits",
    searchFlag: "total_hits",
    columnStyle: { width: "60%" },
  },
  {
    header: "Unique Users",
    accessor: "unique_users",
    searchFlag: "unique_users",
    columnStyle: { width: "60%" },
  },
];

const SearchParams = ({ country, actionList, dateRangeMode }) => [
  ...(dateRangeMode
    ? [
        {
          label: "Date From",
          value: "start_date",
          param: "",
          type: "date",
        },
        {
          label: "Date To",
          value: "end_date",
          param: "",
          type: "date",
        },
      ]
    : [
        {
          label: "Interval",
          value: "",
          param: `start_date=${Moment().subtract(1, "years").toISOString()}&end_date=${Moment().toISOString()}`,
          type: "day-range",
        },
      ]),
  {
    label: "Country",
    value: "country",
    param: { name: "Malaysia" },
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    typeaheadId: "country_typeahead",
    showClearButton: true,
    options: country,
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Activity",
    value: "query",
    param: { label: "NEXTCLOUD DIRECTORIES VIEW", name: 1 },
    type: "typeahead",
    labelKey: "label",
    filterBy: ["label"],
    typeaheadId: "activity_typeahead",
    showClearButton: true,
    options: actionList,
    typeaheadChildren: (rowItem) => <p>{rowItem.label}</p>,
  },
  {
    label: "View By",
    value: "interval",
    type: "radio",
    param: "month",
    options: intervalOptions,
  },
];

const SearchActivity = ({
  data,
  searchActivity,

  getSearchActivity,
}) => {
  const [visual, setVisual] = useState("chart");
  const [dateRangeMode, setDateRangeMode] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [searchParams, setSearchParams] = useState(
    SearchParams({
      country: data.dictionaryReducer.countries,
      actionList,
      dateRangeMode,
    }),
  );

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getSearchActivity(tmp);
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(
      SearchParams({
        country: data.dictionaryReducer.countries,
        actionList,
        dateRangeMode,
      }),
    );

    temp.map((item) => {
      item.param = _.find(searchParams, { value: item.value })
        ? _.find(searchParams, { value: item.value }).param
        : "";
    });
    setSearchParams(temp);
  }, [dateRangeMode]);

  const renderSearchMode = () => (
    <div className="at-analytic-centre__mode-toggle">
      Quick Select
      <IOSButton
        className={"ml-2"}
        currentState={!dateRangeMode}
        onToggleButton={() => setDateRangeMode(!dateRangeMode)}
      />
      Date Range
    </div>
  );

  return (
    <>
      <div className="mb-10">
        <button
          className={
            "btn-new btn-new--outline-secondary at-mobile-view__controller"
          }
          style={{ width: "100%" }}
          onClick={() => setShowSearchContent((prevState) => !prevState)}
        >
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5>
        </button>
        <div className="at-table__search-cont at-desktop-view__controller ">
          <TableSearchParams
            mode={"desktop"}
            showResetButton={false}
            searchParams={searchParams}
            renderSearchMode={renderSearchMode()}
            onChangeSearchParams={setSearchParams}
            onToggleSearchModal={() =>
              setShowSearchContent((prevState) => !prevState)
            }
            getListAPI={(page, search) => getSearchActivity(search)}
          />
        </div>
      </div>
      <div className="d-flex mb-10 justify-content-end">
        <button
          className={`at-table-search__additional-btn ${visual === "chart" && "at-table-search__additional--selected"}`}
          onClick={() => setVisual("chart")}
          children={<FaRegChartBar style={{ width: "20px", height: "20px" }} />}
        />
        <button
          onClick={() => setVisual("table")}
          className={`at-table-search__additional-btn ${visual === "table" && "at-table-search__additional--selected"}`}
          children={<BiTable style={{ width: "20px", height: "20px" }} />}
        />
      </div>
      {visual === "table" && (
        <CustomTable
          hideSearch={true}
          pagination={true}
          rowData={searchActivity.data}
          columns={columnData}
        />
      )}
      {visual === "chart" && (
        <div style={{ height: `${window.innerHeight * 0.55}px`}}>
          <Bar
            data={searchActivity.chart}
            options={{
              plugins: {

                datalabels: window.innerWidth < 820 ? false : {
                  anchor: "end",
                  align: "end",
                  formatter: (value) => numberWithCommas(value),
                }
              },
              maintainAspectRatio: false,
              aspectRatio: 0.1,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: 8,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 8,
                      callback: (value) => numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />
          <div className="text-center">
            {searchParams[searchParams.length - 2].label}
          </div>
        </div>
      )}
      {showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          showResetButton={false}
          searchParams={searchParams}
          renderSearchMode={renderSearchMode()}
          onChangeSearchParams={setSearchParams}
          onToggleSearchModal={() =>
            setShowSearchContent((prevState) => !prevState)
          }
          getListAPI={(page, search) => getSearchActivity(search)}
        />
      )}
    </>
  );
};

export default SearchActivity;
