import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";
import { Get, GetFile, Put } from "utils/axios";

const searchParams = [
  {
    label: "Unit ID",
    value: "sub_sale_claim_unit_id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Form No.",
    value: "sub_sale_claim_confirmation_form_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Rental Price",
    value: "sub_sale_claim_net_price_gteq",
    type: "input",
    param: "",
  },
  {
    label: "Law Firm",
    value: "panel_law_firm_id_eq",
    type: "select",
    options: [],
    param: "",
  },
  {
    label: "Claim Approval Date From",
    value: "claim_approved_date_gteq",
    type: "date",
    param: "",
  },
  {
    label: "Claim Approval Date To",
    value: "claim_approved_date_lteq",
    type: "date",
    param: "",
  },
  {
    label: "Agreement Date",
    value: "sub_sale_claim_agreement_date_eq",
    type: "date",
    param: "",
  },
  {
    label: "Billing Date",
    value: "billing_date_eq",
    type: "date",
    param: "",
  },
  {
    label: "Billed",
    value: "is_billed_eq",
    type: "radio",
    param: "",
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
];

const initialExportParams = {
  start_date: Moment().format("YYYY-MM-DD"),
  end_date: Moment().format("YYYY-MM-DD")
}

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      tenancyAgreementsList: {},
      loading: false,
      onLoadUpdate: false,
      showExportDialog: false,
      exportParams: initialExportParams,
      selectedTaList: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeTenancyAgreementHOC = (val, context) => this.setState({ [context]: val });

    onClickSelectAll = () => {
      const tmpTenancyAgreementsListData = []
      this.state.tenancyAgreementsList?.data?.forEach(item => !item.is_billed && tmpTenancyAgreementsListData.push(item.id))
      if (this.state.selectedTaList.length !== tmpTenancyAgreementsListData.length) {
        this.setState({ selectedTaList: tmpTenancyAgreementsListData });
      } else {
        this.setState({ selectedTaList: [] });
      }
    };

    onClickCheckBox = (param) => {
      let tmp = _.cloneDeep(this.state.selectedTaList);
      if (this.state.selectedTaList.indexOf(param) > -1) {
        tmp.splice(this.state.selectedTaList.indexOf(param), 1);
      } else {
        tmp.push(param);
      }
      this.setState({ selectedTaList: tmp });
    };
    
    onCloseExportModal = () => this.setState({ showExportDialog: false, exportParams: initialExportParams });

    getLawFirmPanel = () => Get(
        `/admin/panel_law_firms`,
        this.getLawFirmPanelSuccess,
        this.getLawFirmPanelError,
        this.load,
      );
    getLawFirmPanelSuccess = (payload) => {
      let tmp = _.cloneDeep(this.state.searchParams) || [];
      let index = tmp.findIndex((item) => item.value === "panel_law_firm_id_eq");
      tmp[index].options = payload.map((item) => ({
        ...item,
        label: item.name,
        value: item.id
      }))
      
      this.setState({
        searchParams: tmp,
      });
    };
    getLawFirmPanelError = (error) => requestError(error);

    getTenancyAgreementList = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/tenancy_agreement_downloads?${search}page=${page}`,
        this.getTenancyAgreementListSuccess,
        this.getTenancyAgreementListError,
        this.load,
      );
    };
    getTenancyAgreementListSuccess = (payload) => {
      let tempPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      let tmpData = payload.data.map((item) => {
        return {
          ...item,
          claim_approved_date: item?.claim_approved_date
            ? Moment(item.claim_approved_date).format("DD MMM YYYY")
            : item.claim_approved_date,
          billing_date: item?.billing_date
            ? Moment(item.billing_date).format("DD MMM YYYY")
            : item.billing_date,
          law_firm_name: item?.panel_law_firm?.name,
          law_firm_area_type_id: item?.panel_law_firm?.area_type_id,
          agreement_date: item?.sub_sale_claim?.agreement_date
            ? Moment(item?.sub_sale_claim?.agreement_date).format("DD MMM YYYY")
            : item.agreement_date,
          confirmation_form_number: item?.sub_sale_claim?.confirmation_form_number,
          net_price: item?.sub_sale_claim?.net_price?.toFixed(2) || item?.sub_sale_claim?.net_price,
          fee: item?.fee?.toFixed(2) || item?.fee,
          unit_id: item?.sub_sale_claim?.unit_id
        };
      });

      this.setState({
        tenancyAgreementsList: {
          ...payload,
          data: tmpData,
        },
        totalPages: tempPages
      });
    };
    getTenancyAgreementListError = (error) => requestError(error);
    
    getSelectedTenancyAgreement = (id) =>
      Get(
        `/admin/tenancy_agreement_downloads/${id}`,
        this.getSelectedTenancyAgreementSuccess,
        this.getSelectedTenancyAgreementError,
        this.load,
      );
    getSelectedTenancyAgreementSuccess = (payload) => {
      this.setState({
        selectedTenancyAgreement: payload,
      });
    };
    getSelectedTenancyAgreementError = (error) => requestError(error);

    updateTenancyAgreement = (id, data) => {
      Put(
        `/admin/tenancy_agreement_downloads/${id}`,
        {
          "is_billed": data.is_billed,
          "billing_date": data.billing_date ? Moment(data.billing_date).format("YYYY-MM-DD") : "" // format: "2024-09-09"
        },
        this.updateTenancyAgreementSuccess,
        this.updateTenancyAgreementError,
        val => this.setState({ onLoadUpdate: val })
      );
    };
    updateTenancyAgreementSuccess = () => {
      requestSuccess("Details updated successfully.");
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getTenancyAgreementList(currentPage, searchParams);
    };
    updateTenancyAgreementError = error => requestError(error);
    
    exportTenancyAgreement = (searchParams) => {
      GetFile(
        `/admin/tenancy_agreement_downloads/download?${searchParams}`,
        "Tenancy_Agreement",
        this.exportTenancyAgreementSuccess,
        this.exportTenancyAgreementError,
        val => this.setState({ onLoadUpdate: val })
      );
    };
    exportTenancyAgreementSuccess = () => {};
    exportTenancyAgreementError = error => requestError(error);

    updateBulkTenancyAgreements = (data) => {
      Put(
        `/admin/tenancy_agreement_downloads/bulk_update`,
        {
          ids: data.ids,
          billing_date: data.billing_date
        },
        this.updateBulkTenancyAgreementsSuccess,
        this.updateBulkTenancyAgreementsError,
        val => this.setState({ onLoadUpdate: val })
      );
    };
    updateBulkTenancyAgreementsSuccess = () => {
      requestSuccess("Details updated successfully.");
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.setState({ selectedTaList: [] });
      this.getTenancyAgreementList(currentPage, searchParams);
    };
    updateBulkTenancyAgreementsError = error => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoad={this.state.loading}
          exportParams={this.state.exportParams}
          totalPages={this.state.totalPages}
          onLoadUpdate={this.state.onLoadUpdate}
          tenancyAgreementsList={this.state.tenancyAgreementsList}
          selectedTenancyAgreement={this.state.selectedTenancyAgreement}
          searchParams={this.state.searchParams}
          showExportDialog={this.state.showExportDialog}
          selectedTaList={this.state.selectedTaList}
          onClickSelectAll={this.onClickSelectAll}
          onClickCheckBox={this.onClickCheckBox}
          onChangeTenancyAgreementHOC={this.onChangeTenancyAgreementHOC}
          updateTenancyAgreement={this.updateTenancyAgreement}
          updateBulkTenancyAgreements={this.updateBulkTenancyAgreements}
          getTenancyAgreementList={this.getTenancyAgreementList}
          getLawFirmPanel={this.getLawFirmPanel}
          exportTenancyAgreement={this.exportTenancyAgreement}
          onCloseExportModal={this.onCloseExportModal}
          getselectedTenancyAgreement={this.getselectedTenancyAgreement}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
