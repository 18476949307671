import React from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { IoIosClose } from "react-icons/io";

const AtlasSnackbar = ({ open, handleClose, message, buttonStyle }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        message={<span>{message}</span>}
        action={
          <button onClick={handleClose}>
            <IoIosClose
              style={{ color: "#eee", width: 25, height: 25, ...buttonStyle }}
            />
          </button>
        }
      />
    </Snackbar>
  );
};

export default AtlasSnackbar;
