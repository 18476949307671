import React, { PureComponent } from "react";
import Moment from "moment";
import { SnackbarContent } from "@material-ui/core";

import SVGIcon from "components/Icon";

import "stylesheets/containers/personal/subsales/components/timeline.scss";

class OrderActivity extends PureComponent {
  calcDateDifference = (param) => {
    if (param) {
      return (
        <>
          {`${Moment(param).format("DD MMM YYYY HH:mm")}`},
          {` ${Moment(param).fromNow()}`}
        </>
      );
    }
  };

  render = () => {
    const { activities } = this.props;
    return (
      <div>
        {activities &&
          activities.length > 0 &&
          activities.map((item) => {
            const { custom_changes, created_at } = item;
            return (
              <section key={item.id} className="timeline p-1 h-auto">
                <div className="timeline-block">
                  <div className="notification-icon-disable-mobile">
                    <div className="timeline-point">
                      <SVGIcon className="svg-icon-bell" svgHref="icon-bell" />
                    </div>
                  </div>
                  <div className="timeline-content w-100">
                    <div className="timeline-content-card w-100">
                      <div className="timeline-content-header">
                        {this.calcDateDifference(created_at)}
                      </div>
                      <div className="timeline-content-body">
                        {custom_changes !== null &&
                          custom_changes.map((item, index) => (
                            <>
                              <p>{item}</p>
                              {index < custom_changes.length - 1 && <hr />}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        {!(activities && activities.length > 0) && (
          <SnackbarContent
            style={{ maxWidth: "100%", margin: "1rem" }}
            message={"No data presence."}
          />
        )}
      </div>
    );
  };
}

export default OrderActivity;
