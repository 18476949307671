import React, { useCallback, useState } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";

import AgentSearchHOC from "actions/agentSearch";

const CreateUser = ({
  agentList,
  onLoadUsers,
  onLoadAgent,
  createUserList,

  onClose,
  getAgentsBySearch,
  onChangeAgentHOC,
}) => {
  const [selectedAgent, onChangeAgent] = useState(null);
  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <ModalDialog
      title={"Add User"}
      onLoad={onLoadUsers}
      responsiveSize="lg"
      onClose={onClose}
      children={
        <>
          <div className="d-flex">
            <h2 className="at-form-input__title mb-2 mr-10">
              Search for agent and admin
            </h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={onLoadAgent}
            typeaheadId={"internal_agent"}
            containerStyle={{ marginBottom: 200 }}
            options={agentList || []}
            onSelect={(val) => val && val.length > 0 && onChangeAgent(val[0])}
            selectedValue={selectedAgent ? [selectedAgent] : []}
            filterBy={["full_name", "email", "mobile_contact_number"]}
            labelKey={"full_name"}
            onSearch={(val) => {
              onChangeAgent(null);
              onChangeTypeaheadSearch(val);
            }}
            helpText={"Search with full name, email or mobile number"}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <button
            type={"button"}
            className="btn-new btn-new--primary"
            disabled={!selectedAgent}
            onClick={() => createUserList(selectedAgent)}
          >
            Assign
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
};

export default AgentSearchHOC(CreateUser);
