export const occupationOptions = [
  { value: "Accounting", label: "Accounting" },
  {
    value: "Administration & Office Support",
    label: "Administration & Office Support",
  },
  { value: "Advertising, Arts & Media", label: "Advertising, Arts & Media" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Call Centre & Customer Service",
    label: "Call Centre & Customer Service",
  },
  {
    value: "Community Services & Development",
    label: "Community Services & Development",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consulting & Strategy", label: "Consulting & Strategy" },
  { value: "Design & Architechture", label: "Design & Architechture" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "Farming, Animals & Conservation",
    label: "Farming, Animals & Conservation",
  },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Government & Defence", label: "Government & Defence" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
  {
    value: "Human Resources & Recruitment",
    label: "Human Resources & Recruitment",
  },
  {
    value: "Information & Communication Technology",
    label: "Information & Communication Technology",
  },
  { value: "Insurance & Superannuation", label: "Insurance & Superannuation" },
  { value: "Legal", label: "Legal" },
  {
    value: "Manufacturing, Transport & Logistics",
    label: "Manufacturing, Transport & Logistics",
  },
  { value: "Marketing & Communications", label: "Marketing & Communications" },
  { value: "Mining, Resources & Energy", label: "Mining, Resources & Energy" },
  { value: "Real Estate & Property", label: "Real Estate & Property" },
  { value: "Retail & Consumer Products", label: "Retail & Consumer Products" },
  { value: "Retired", label: "Retired" },
  { value: "Sales", label: "Sales" },
  { value: "Science & Technology", label: "Science & Technology" },
  { value: "Sport & Recreation", label: "Sport & Recreation" },
  { value: "Trades & Services", label: "Trades & Services" },
  { value: "Unemployed", label: "Unemployed" },
];

export const personalDDFieldData = [
  {
    value: "occupation",
    title: "Occupation",
    placeholder: "",
    required: true,
    type: "autosuggest",
    options: occupationOptions,
  },
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "employer_name",
    title: "Name of Employer or if self-employed, Nature of Business",
    placeholder: "",
    required: true,
    type: "text",
  },
  {
    value: "salary_range_id",
    title: "Monthly Income",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Select a range", disabled: true },
      { value: "8", label: "None" },
      { value: "1", label: "Below RM5000" },
      { value: "2", label: "RM5,001 - RM10,000" },
      { value: "3", label: "RM10,001 - RM20,000" },
      { value: "4", label: "RM20,001 - RM30,000" },
      { value: "5", label: "RM30,001 - RM40,000" },
      { value: "6", label: "RM40,001 - RM50,000" },
      { value: "7", label: "Above RM50,000" },
    ],
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "declared_bankrupt",
    title:
      "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
  {
    value: "identity_copy_url",
    title: "IC / Passport",
    placeholder: "",
    required: true,
    type: "document",
  }
];

export const companyDDFieldData = [
  {
    value: "business_type_id",
    title: "Business Type",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Choose a type", disabled: true },
      { value: "1", label: "Sole Proprietorship" },
      { value: "2", label: "Partnership" },
      { value: "3", label: "Limited Liability Partnership" },
      { value: "4", label: "Public Company" },
      { value: "5", label: "Private Limited Company" },
      { value: "6", label: "Trust" },
      { value: "7", label: "Club/Society/Charity" },
      { value: "8", label: "N/A" },
    ],
  },
  {
    value: "nature_of_business",
    title: "Nature of Company",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Select an occupation", disabled: true },
      { value: "Accounting", label: "Accounting" },
      {
        value: "Administration & Office Support",
        label: "Administration & Office Support",
      },
      {
        value: "Advertising, Arts & Media",
        label: "Advertising, Arts & Media",
      },
      {
        value: "Banking & Financial Services",
        label: "Banking & Financial Services",
      },
      {
        value: "Call Centre & Customer Service",
        label: "Call Centre & Customer Service",
      },
      {
        value: "Community Services & Development",
        label: "Community Services & Development",
      },
      { value: "Construction", label: "Construction" },
      { value: "Consulting & Strategy", label: "Consulting & Strategy" },
      { value: "Design & Architechture", label: "Design & Architechture" },
      { value: "Education & Training", label: "Education & Training" },
      { value: "Engineering", label: "Engineering" },
      {
        value: "Farming, Animals & Conservation",
        label: "Farming, Animals & Conservation",
      },
      { value: "Food and Beverage", label: "Food and Beverage" },
      { value: "Government & Defence", label: "Government & Defence" },
      { value: "Healthcare & Medical", label: "Healthcare & Medical" },
      { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
      {
        value: "Human Resources & Recruitment",
        label: "Human Resources & Recruitment",
      },
      {
        value: "Information & Communication Technology",
        label: "Information & Communication Technology",
      },
      {
        value: "Insurance & Superannuation",
        label: "Insurance & Superannuation",
      },
      { value: "Legal", label: "Legal" },
      {
        value: "Manufacturing, Transport & Logistics",
        label: "Manufacturing, Transport & Logistics",
      },
      {
        value: "Marketing & Communications",
        label: "Marketing & Communications",
      },
      {
        value: "Mining, Resources & Energy",
        label: "Mining, Resources & Energy",
      },
      { value: "Real Estate & Property", label: "Real Estate & Property" },
      {
        value: "Retail & Consumer Products",
        label: "Retail & Consumer Products",
      },
      { value: "Sales", label: "Sales" },
      { value: "Science & Technology", label: "Science & Technology" },
      { value: "Sport & Recreation", label: "Sport & Recreation" },
      { value: "Trades & Services", label: "Trades & Services" },
      { value: "N/A", label: "N/A" },
    ],
  },
  {
    value: "nationality",
    title: "Country of Incorporation",
    placeholder: "Select Country of incorporation",
    required: true,
    type: "autosuggest",
  },
  {
    value: "ssm_copy_url",
    title: "Annual Return / SSM",
    placeholder: "",
    required: true,
    type: "document",
  },
  {
    value: "directors",
    title: "Name of Directors",
    placeholder: "",
    required: true,
  },
  {
    value: "shareholders",
    title: "Shareholders",
    placeholder: "",
    required: true,
  },
  {
    value: "senior_management",
    title: "Name of Senior Management (CEO,CFO,CTO)",
    placeholder: "",
    required: true,
    type: "text",
  },
  {
    value: "beneficial_owners",
    title: "Name of Beneficial Owners through other means",
    placeholder: "",
    required: true,
    type: "text",
  },
  {
    value: "ownership_type",
    title: "Type of Ownership/Control/Relationship (optional)",
    placeholder: "",
    type: "text",
  },
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "declared_bankrupt",
    title:
      "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
];

export const declineFieldData = [
  {
    value: "supporting_document_url",
    title: "Supporting Document",
    placeholder: "",
    helpText:
      "i.e. screenshot of WhatsApp/Email conversation where client declined to share information",
    type: "document",
  },
];

export const personalDDFormData = {
  occupation: "",
  due_diligence_type_id: "",
  financing_method_id: "",
  signing_link: "",
  salary_range_id: "",
  employer_name: "",
  copies_info: {},
  transaction_purpose_id: "",
  declared_bankrupt: false,
};

export const companyDDFormData = {
  due_diligence_type_id: "",
  financing_method_id: "",
  signing_link: "",
  nature_of_business: "",
  beneficial_owners: "",
  business_type_id: "",
  copies_info: {},
  directors: [],
  shareholders: [],
  ownership_type: "",
  senior_management: "",
  nationality: "",
  transaction_purpose_id: "",
  declared_bankrupt: false,
};
