import { MenuItem } from '@material-ui/core';
import Slider from 'react-input-slider';

import ProjectCard from 'components/Card/projectCard';
import AtlasRadioGroup from 'components/RadioGroup';
import AtlasSelect from 'components/Select/new';

const AgentDetailsSettings = ({
  configuration,
  isPortrait,
  onChangeVideoConfiguration,
  onBulkChangeVideoConfiguration
}) => {
  return (
    <ProjectCard
      icon='user'
      title='Agent Details Settings'
      containerClass='mb-0'
    >
      <div className='mb-3'>
        <label className='at-form-input__title'>Template</label>
        <AtlasSelect
          value={configuration?.agentDetailsTemplateId}
          onChange={e => onChangeVideoConfiguration('agentDetailsTemplateId', parseInt(e.target.value))}
        >
          <MenuItem value={1}>White box</MenuItem>
          <MenuItem value={2}>Transparent box</MenuItem>
          <MenuItem value={3}>Trapeziums</MenuItem>
          <MenuItem value={4}>Pills</MenuItem>
          <MenuItem value={5}>Black box</MenuItem>
          <MenuItem value={6}>Black pills</MenuItem>
        </AtlasSelect>
      </div>
      <div className='mb-3'>
        <label className='at-form-input__title'>Background transparency</label>
        <div>
          <Slider
            styles={{
              active: { backgroundColor: '#3B82F6' },
              thumb: {
                border: '1px solid #9ca3af',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
              }
            }}
            x={configuration?.agentDetailsOpacity * 100}
            onChange={({ x }) => onChangeVideoConfiguration('agentDetailsOpacity', x / 100)}
            xstep={10}
          />
        </div>
        <p>{configuration?.agentDetailsOpacity * 100}%</p>
      </div>
      <div>
        <label className='at-form-input__title'>Position</label>
        <AtlasRadioGroup
          options={[
            { label: 'Left', value: 'left' },
            { label: 'Right', value: 'right' }
          ]}
          checkedValue={configuration?.agentDetailsPosition}
          selectedRadioValue={val => {
            if (isPortrait && configuration?.hidePropertyDetails) {
              onBulkChangeVideoConfiguration({
                propertyDetailsPosition: val,
                agentDetailsPosition: val
              });
            } else {
              onChangeVideoConfiguration('agentDetailsPosition', val);
            }
          }}
          disabled={isPortrait && !configuration?.hidePropertyDetails}
        />
      </div>
    </ProjectCard>
  );
};

export default AgentDetailsSettings;
