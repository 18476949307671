import React, { Component } from "react";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      agencies: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    getAgencies = () =>
      Get(
        `/agencies`,
        this.getAgenciesSuccess,
        this.getAgenciesError,
        this.load,
      );
    getAgenciesSuccess = (payload) => {
      let tmp = [];
      payload.map((item) => {
        tmp.push({
          ...item,
          label: item.name,
        });
      });
      return this.setState({ agencies: tmp });
    };
    getAgenciesError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          agencies={this.state.agencies}
          onLoadAgency={this.state.loading}
          getAgencies={this.getAgencies}
        />
      );
    };
  }

  return WithHOC;
};

export default HOC;
