import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/close";

const DrawingBoard = ({
  open,
  title,
  paperClass,
  loading,
  canvasClass,
  onClickSubmit,
  onClickClose,
  negativeButtonLabel,
  positiveButtonLabel,
}) => {
  const canvasRef = useRef(null);
  const [signedImage, setSignedImage] = useState("");
  if (canvasRef.current) {
  }

  useEffect(() => {
    if (!open) {
      setSignedImage("");
    }
  }, [open]);

  return (
    <Dialog maxWidth={"xl"} open={open} classes={{ paper: paperClass }}>
      <DialogTitle>
        <div className="d-flex">
          <span>{title}</span>
          <AtlasCloseButton
            containerStyle={{ display: "flex", marginLeft: "auto" }}
            onClick={() => onClickClose()}
          />
        </div>
      </DialogTitle>
      <DialogContent className={canvasClass}>
        <SignaturePad
          canvasProps={{ className: "h-100 w-100" }}
          ref={canvasRef}
          onEnd={() => {
            setSignedImage(canvasRef.current.getCanvas().toDataURL());
          }}
        />
      </DialogContent>
      <DialogActions>
        <button
          disabled={signedImage === ""}
          className="btn-new btn-new--secondary"
          onClick={() => {
            setSignedImage("");
            canvasRef.current.clear();
          }}
        >
          {negativeButtonLabel || 'Clear'}
        </button>
        <button
          disabled={signedImage === ""}
          className="btn-new btn-new--secondary"
          onClick={() => {
            onClickSubmit(signedImage);
          }}
        >
          {positiveButtonLabel || "Create"}
        </button>
      </DialogActions>
      {loading && <LoadingModal />}
    </Dialog>
  );
};

export default DrawingBoard;
