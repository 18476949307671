import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import { PulseLoader } from "react-spinners";
import {
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import DatePicker from "react-datepicker";

import CustomCheckbox from "components/Checkbox";
import CustomTypeahead from "components/Typeahead/new";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";

import ProductHOC from "../actions/product";
import AgentSearchHOC from "actions/agentSearch";

const DatepickerInput = ({ ...props }) => (
  <input type="text" style={{ backgroundColor: "#ffff" }} {...props} readOnly />
);

const CreateDialog = ({
  products,
  agentList,
  onLoadProduct,
  onLoadCoupon,
  onLoadAgent,

  onClose,
  onChangeAgentHOC,
  getProductList,
  getAgentsBySearch,
  createCoupon,
}) => {
  const [createCouponData, onChangeCreateCouponData] = useState({
    market_product_id: null,
    expiry_date: null,
    user: null,
  });
  const [hasExpiryDate, onToggleExpiryDate] = useState(false);

  useEffect(() => {
    getProductList();
  }, []);

  const onChangeCreateData = (val, context) => {
    let temp = _.cloneDeep(createCouponData);
    temp[context] = val;

    onChangeCreateCouponData(temp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h4 className="at-card__title">New Coupon</h4>
      </div>
      <div className={"row"}>
        <div className="col-md-12 mb-20">
          <div className="d-flex">
            <h2 className="at-form-input__title mr-10">Select an Agent</h2>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadAgent}
            />
          </div>
          <CustomTypeahead
            disabled={onLoadAgent}
            typeaheadId={"internal_agent"}
            options={agentList || []}
            selectedValue={createCouponData.user ? [createCouponData.user] : []}
            labelKey={"full_name"}
            onSearch={(val) => {
              onChangeCreateData(null, "user");
              onChangeTypeaheadSearch(val);
            }}
            onSelect={(val) =>
              val && val.length > 0 && onChangeCreateData(val[0], "user")
            }
            filterBy={["full_name", "email", "mobile_contact_number"]}
            helpText={"e.g. Full Name, Email, Mobile Number"}
            childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
            highlighterData={[
              (option) => `Team: ${option.team_name}`,
              <br />,
              (option) =>
                `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
            ]}
          />
        </div>
      </div>
      <ExpansionPanel expanded={true} classes={{ root: "coupon-mngmt" }}>
        <ExpansionPanelSummary
          classes={{
            root: "panel-summary panel-disabled",
            content: "panel-summary-content",
          }}
        >
          <h2 className="at-form-input__title">{`Coupon Details`}</h2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 20 }} className={"row"}>
          <div className={"col-md-6"}>
            <label className={"mb-5"}>{"Product to assign"}</label>
            <CustomTypeahead
              typeaheadId={"product"}
              options={products || []}
              filterBy={["name"]}
              labelKey={"name"}
              onSelect={(val) =>
                val && val.length > 0
                  ? onChangeCreateData(val[0].id, "market_product_id")
                  : null
              }
              selectedValue={
                _.find(products, { id: createCouponData.market_product_id })
                  ? [
                      _.find(products, {
                        id: createCouponData.market_product_id,
                      }),
                    ]
                  : []
              }
              childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            />
          </div>
          <div className={"col-md-6 mt-auto"}>
            {hasExpiryDate && (
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <label className={"mb-5"}>{"Expiry Date"}</label>
                <DatePicker
                  showYearDropdown={true}
                  dateFormat="DD MMM YYYY"
                  className="form-control"
                  customInput={<DatepickerInput />}
                  value={createCouponData.expiry_date}
                  onChange={(val) =>
                    onChangeCreateData(
                      Moment(val).format("DD MMM YYYY"),
                      "expiry_date",
                    )
                  }
                />
              </FormControl>
            )}
            <CustomCheckbox
              labelStyle={{ marginBottom: 10 }}
              checked={hasExpiryDate}
              content={"This coupon has expiry date."}
              onChangeCheckboxValue={(e) =>
                onToggleExpiryDate(e.target.checked)
              }
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className="d-flex mt-3 g-3">
        <button
          disabled={
            !createCouponData.market_product_id ||
            !createCouponData.user ||
            (hasExpiryDate && !createCouponData.expiry_date)
          }
          className={"btn-new btn-new--success"}
          onClick={() =>
            createCoupon({
              ...createCouponData,
              hasExpiryDate: hasExpiryDate,
            })
          }
        >
          Create
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
      {(onLoadProduct || onLoadCoupon) && <LoadingModal />}
    </>
  );
};

export default compose(ProductHOC, AgentSearchHOC)(CreateDialog);
