import React from "react";
import _ from "lodash";
import { RiGroup2Fill } from "react-icons/ri";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { FiCheckCircle, FiEdit } from "react-icons/fi";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import TooltipWrapper from "../components/Tooltip";
import PropertyInfo from "../propertyInfo";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: "Not Sent", colorName: "Grey" },
  { value: "Sent", colorName: "Blue" },
  { value: "Accepted", colorName: "Teal" },
  { value: "Declined", colorName: "Red" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Revoked/Cancelled", colorName: "Red" },
  { value: undefined, colorName: "Grey" },
];

const SentColumnData = ({
  can_update,
  currentTab,
  disabled,

  onChangeCobrokeHOC,
  setSelectedCobroke,
  setConfirmationMode,
  onToggleConfirmationModal,
  onToggleConclude,
  onToggleReviseCommission,
  getPropertyDetails,
  downloadCobroke,
}) => [
  {
    header: "Agent",
    columnClass: "at-cobroke__agent-column ",
    renderColumn: ({
      agent_info,
      agents_team_name,
      status_id,
      status,
      deal_concluded,
    }) => {
      const { colorName = "" } = _.find(StatusColor, { value: status }) || {};

      return (
        <>
          <div
            className="at-status_badge mb-10"
            style={{ ...getColorBadge(colorName) }}
          >
            {status_id === 3 && deal_concluded && (
              <TooltipWrapper
                title={"This Co-broke has been concluded"}
                child={
                  <FiCheckCircle
                    style={{
                      width: 15,
                      height: 15,
                      marginRight: 7,
                      color: "#ffff",
                    }}
                  />
                }
              />
            )}
            {status}
          </div>
          <p className={"fw-600"}>{agent_info.name || "N/A"}</p>
          <div className="fs-2">
            <RiGroup2Fill
              style={{
                width: 16,
                height: 16,
                fill: "#F0631D",
                marginRight: 10,
              }}
            />
            {agents_team_name || "N/A"}
          </div>
          {([3, 4].includes(status_id) || status === "Pending") && (
            <>
              <div className="at-table__text-ellipsis fs-2">
                <AtlasIcon
                  svgHref={"atlas-sms"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 10,
                  }}
                />
                <span>{agent_info.email || "N/A"}</span>
                <AtlasIcon
                  svgHref={"atlas-sms-edit"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#3B82F6" }}
                  onClick={() =>
                    (window.location = `mailto:${agent_info.email}`)
                  }
                />
              </div>
              <div className="at-table__text-ellipsis fs-2">
                <AtlasIcon
                  svgHref={"atlas-call"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 10,
                  }}
                />
                <span>{`+${agent_info.phone_number}` || "N/A"}</span>
                <AtlasIcon
                  svgHref={"atlas-whatsapp"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#12B76A" }}
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${agent_info.phone_number}`,
                    )
                  }
                />
                <AtlasIcon
                  svgHref={"atlas-call"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#3B82F6" }}
                  onClick={() => window.open(`tel:${agent_info.phone_number}`)}
                />
              </div>
            </>
          )}
        </>
      );
    },
  },
  {
    header: "Property",
    columnClass: "align-items-center",
    renderColumn: (rowData) => (
      <PropertyInfo
        {...rowData}
        property_type={"listing"}
        getPropertyDetails={getPropertyDetails}
      />
    ),
  },
  {
    header: "",
    columnClass: "align-items-center",
    renderColumn: (rowData) => {
      const {
        currency,
        co_broke_portion,
        original_co_broke_portion,
        status_id,
        deal_concluded,
        revised_commission_count,
      } = rowData;

      const {
        total_portion = null,
        my_portion,
        balance = null,
        co_broke_portion_by = "percentage",
      } = co_broke_portion;

      return (
        <>
          <div className="at-cobroke__revise-cont">
            <div className="d-flex align-items-center fs-2">
              <div
                className="at-cobroke__revise-icon"
                style={{
                  backgroundColor: getColorBadge("Teal").backgroundColor,
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-percentage-circle"}
                  style={{ fill: getColorBadge("Teal").fill }}
                />
              </div>
              Commission
              <div className="d-flex ml-auto">
                <i className="fw-600 mr-3">
                  <s>
                    {status_id === 6 &&
                      currentTab === 1 &&
                      original_co_broke_portion &&
                      `${original_co_broke_portion.total_portion} ${original_co_broke_portion.co_broke_portion_by === "amount" ? currency : "%"}`}
                    {status_id === 6 &&
                      currentTab === 1 &&
                      !original_co_broke_portion &&
                      "N/A"}
                  </s>
                </i>
                <i className="fw-600">
                  {total_portion
                    ? `${total_portion} ${co_broke_portion_by === "amount" ? currency : "%"}`
                    : "N/A"}
                </i>
              </div>
            </div>
            <div className="d-flex align-items-center fs-2">
              <div
                className="at-cobroke__revise-icon"
                style={{
                  backgroundColor: getColorBadge("Blue").backgroundColor,
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-profile-circle"}
                  style={{ fill: getColorBadge("Blue").fill }}
                />
              </div>
              Listing Portion
              <div className="d-flex ml-auto">
                <i className="fw-600 mr-3">
                  <s>
                    {status_id === 6 &&
                      currentTab === 1 &&
                      original_co_broke_portion &&
                      `${original_co_broke_portion.my_portion} ${original_co_broke_portion.co_broke_portion_by === "amount" ? currency : "%"}`}
                    {status_id === 6 &&
                      currentTab === 1 &&
                      !original_co_broke_portion &&
                      "N/A"}
                  </s>
                </i>
                <i className="fw-600">
                  {my_portion
                    ? `${my_portion} ${co_broke_portion_by === "amount" ? currency : "%"}`
                    : "N/A"}
                </i>
              </div>
            </div>
            <div className="d-flex align-items-center fs-2">
              <div
                className="at-cobroke__revise-icon"
                style={{
                  backgroundColor: getColorBadge("Fuchsia").backgroundColor,
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{ fill: getColorBadge("Fuchsia").fill }}
                />
              </div>
              Co-broke Portion
              <div className="d-flex ml-auto">
                <i className="fw-600 mr-3">
                  <s>
                    {status_id === 6 &&
                      currentTab === 1 &&
                      original_co_broke_portion &&
                      `${original_co_broke_portion.balance} ${original_co_broke_portion.co_broke_portion_by === "amount" ? currency : "%"}`}
                    {status_id === 6 &&
                      currentTab === 1 &&
                      !original_co_broke_portion &&
                      "N/A"}
                  </s>
                </i>
                <i className="fw-600">
                  {balance
                    ? `${balance} ${co_broke_portion_by === "amount" ? currency : "%"}`
                    : "N/A"}
                </i>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end mt-2">
            <i>Revised: {revised_commission_count}/3</i>
            {revised_commission_count > 0 && status_id === 3 && (
              <MdCheckCircle
                style={{ width: 14, color: "green", marginLeft: 10 }}
              />
            )}
            {revised_commission_count > 0 && status_id === 4 && (
              <MdCancel style={{ width: 14, color: "red", marginLeft: 10 }} />
            )}
            {[3, 4].includes(status_id) &&
              currentTab === 3 &&
              revised_commission_count < 3 &&
              !deal_concluded && (
                <button
                  className={"btn-new btn-new--outline-secondary"}
                  disabled={disabled}
                  onClick={() => {
                    setSelectedCobroke(rowData);
                    onToggleReviseCommission(true);
                  }}
                >
                  <FiEdit className={"color-white"} style={{ width: 14 }} />
                </button>
              )}
          </div>
        </>
      );
    },
  },
  {
    header: "Requested at",
    columnClass: "align-items-center",
    columnStyle: { width: "50%" },
    accessor: "requested_at",
  },
  {
    header: "Action",
    columnClass: "align-items-start",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => (
      <>
        <div className="d-flex flex-wrap">
          {rowData.status_id === 3 &&
            !rowData.deal_concluded &&
            currentTab === 3 && (
              <button
                className="btn-new-sm btn-new--primary mb-1 mr-1"
                style={{ fontSize: 14, padding: "6px 12px" }}
                onClick={() => {
                  onToggleConclude();
                  setSelectedCobroke(rowData);
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 5,
                    fill: "#3B82F6",
                  }}
                />
                Conclude the Deal
              </button>
            )}
          {rowData.status_id === 6 && currentTab === 1 && (
            <div className="d-flex">
              <CustomButton
                disabled={disabled}
                style={{ fontSize: 14, padding: "6px 12px" }}
                className={"btn-new btn-new--success mb-1 mr-1"}
                tooltip={true}
                tooltipChildren={"Accept Revised Commission Rate"}
                onClick={() => {
                  setSelectedCobroke(rowData);
                  setConfirmationMode("accept");
                  onToggleConfirmationModal(true);
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-tick-circle"}
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 5,
                    fill: "#ffff",
                  }}
                />
                Accept
              </CustomButton>
              <CustomButton
                disabled={disabled}
                style={{ fontSize: 14, padding: "6px 12px" }}
                className="btn-new-sm btn-new--danger mb-1 mr-1"
                tooltip={true}
                tooltipChildren={"Reject Revised Commission Rate"}
                onClick={() => {
                  setSelectedCobroke(rowData);
                  setConfirmationMode("reject");
                  onToggleConfirmationModal(true);
                }}
              >
                <AtlasIcon
                  svgHref={"atlas-close-circle"}
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 5,
                    fill: "#ffff",
                  }}
                />
                Reject
              </CustomButton>
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap">
          <button
            className="btn-new-sm btn-new--secondary mr-1 mb-1"
            style={{ fontSize: 14, padding: "6px 12px" }}
            onClick={() =>
              Promise.all([setSelectedCobroke(rowData)]).then(() => {
                onChangeCobrokeHOC(true, "showNotesModal");
              })
            }
          >
            <AtlasIcon
              svgHref={"atlas-note-add"}
              style={{ width: 16, height: 16, fill: "white" }}
            />
            Notes
          </button>
          {rowData.status_id === 3 && rowData.deal_concluded && (
            <button
              className="btn-new-sm btn-new--outline-secondary"
              style={{ padding: "8px" }}
              onClick={() => downloadCobroke(rowData.id)}
            >
              <AtlasIcon
                svgHref={"atlas-receive-square"}
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 5,
                  fill: "#3B82F6",
                }}
              />
            </button>
          )}
        </div>
      </>
    ),
  },
];

export default SentColumnData;
