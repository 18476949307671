import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Tooltip } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import DeleteConfirmation from "components/Modal/delete";
import ContactForm from "./Form";
import ContactDetails from "./View"

import ContactHOC from "./actions";
import BankHOC from "actions/banks";
import NationalityHOC from "actions/nationality";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Full Name",
    customClass: "d-flex",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {rowData.type_id === 1 ? (
          <>
            <Tooltip title={"Personal"}>
              <AtlasIcon svgHref={"atlas-icon-user"}/>
            </Tooltip>
            <span className={"ml-1"}>{rowData.full_name || ""}</span>
          </>
        ) : (
          <>
            <Tooltip title={"Company"}>
              <AtlasIcon svgHref={"atlas-building-company"}/>
            </Tooltip>
            <div className={"ml-1"}>
              <p>{rowData.company && rowData.company}</p>
              <p>{rowData.company_registration_number && rowData.company_registration_number}</p>
              <p>{rowData.full_name || "-"}</p>
            </div>
          </>
        )}
      </div>
    ),
    searchFlag: "full_name",
  },
  { 
    header: "Email", 
    renderColumn: (rowData) => (
      <>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", minWidth: 0 }}>
          <span className="at-table__text-ellipsis">
            {rowData.email || "-"}
          </span>
          {rowData.email && (
            <AtlasIcon
              svgHref={"atlas-sms-edit"}
              className={"btn-new--social-media at-desktop-view__controller"}
              style={{ fill: "#3B82F6", borderRadius: "50px", width: "35px", height: "35px", padding: "8px", flexShrink: 0 }}
              onClick={() => (window.location = `mailto:${rowData.email}`)}
            />
          )}
        </div>
      </>
    ),
    searchFlag: "email",
  },
  {
    header: "NRIC/Passport No.",
    accessor: "identification_number",
    columnStyle: { width: "50%" },
    searchFlag: "identification_number",
  },
  {
    header: "Mobile Number",
    renderColumn: (rowData) => (
      <>
        <div className="d-flex align-items-center flex-nowrap">
          <span className="at-table__text-ellipsis at-table_mobile-text">{rowData.mobile_contact_number || "-"}</span>
          {rowData.mobile_contact_number && (
            <div className="d-flex flex-nowrap">
              <AtlasIcon
                svgHref={"atlas-call"}
                className={"btn-new--social-media at-desktop-view__controller"}
                style={{ fill: "#3B82F6", borderRadius: "50px", width: "35px", height: "35px", padding: "8px" }}
                onClick={() => window.open(`tel:${rowData.mobile_contact_number}`)}
              />
              <AtlasIcon
                svgHref={"atlas-whatsapp"}
                className={"btn-new--social-media at-desktop-view__controller"}
                style={{ fill: "#12B76A", borderRadius: "50px", width: "35px", height: "35px", padding: "8px" }}
                onClick={() => window.open(`https://api.whatsapp.com/send?phone=${rowData.mobile_contact_number}`)}
              />
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-center at-mobile-view__controller mt-2 g-2">
          {rowData.email && (
            <CustomButton
              className={"btn-new btn-new--outline-secondary w-100"}
              containerStyle={{ minWidth: 80, flex: "1 1 0", wordBreak: "normal" }}
              onClick={() => window.open(`mailto:${rowData.email}`)}
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-sms-edit"}
                    style={{ width: 16, height: 16, fill: "#3B82F6" }}
                  />
                  Email
                </>
              }
            />
          )}
          {rowData.mobile_contact_number && (
            <>
              <CustomButton
                className={"btn-new btn-new--outline-secondary w-100"}
                containerStyle={{ minWidth: 80, flex: "1 1 0", wordBreak: "normal" }}
                onClick={() => window.open(`tel:${rowData.mobile_contact_number}`)}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-call-calling"}
                      style={{ width: 16, height: 16, fill: "#3B82F6" }}
                    />
                    Call
                  </>
                }
              />
              <CustomButton
                className={"btn-new btn-new--outline-secondary w-100"}
                containerStyle={{ minWidth: 110, flex: "1 1 0", wordBreak: "normal" }}
                onClick={() =>
                  window.open(`https://wa.me/${rowData.mobile_contact_number}`, "_blank")
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-whatsapp"}
                      style={{ width: 16, height: 16, fill: "#12B76A" }}
                    />
                    Message
                  </>
                }
              />
            </>
          )}
        </div>
      </>
    ),
    searchFlag: "mobile_contact_number",
  },
];

class Contact extends Component {
  componentDidMount = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getContacts(1, "");
    this.props.getBanks(country_id);
    this.props.getNationalities();
    initGA("/dashboard/personal/contacts");
  };

  renderCardContent = () => {
    const { can_destroy } = permissionsChecker("My Contacts", this.props.data);

    return (
      <>
        <AtPaginatedTable
          columns={columnData}
          rowData={this.props.contacts.data ? this.props.contacts.data : []}
          meta={this.props.contacts.meta && this.props.contacts.meta}
          enableSort={true}
          actionColumnContent={[
            {
              name: "view",
              onClick: (rowData) => this.props.getSelectedContact(rowData.id, "view"),
            },
            {
              name: "edit",
              onClick: (rowData) => this.props.getSelectedContact(rowData.id),
            },
            ...(can_destroy
              ? [
                  {
                    name: "delete",
                    color: "#dc3545",
                    onClick: (rowData) => {
                      this.props.onChangeContactHOC(rowData, "selectedContact");
                      this.props.onChangeContactHOC(
                        true,
                        "showDeleteConfirmation",
                      );
                    },
                  },
                ]
              : []),
          ]}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeContactHOC(val, "searchParams")
          }
          getListAPI={this.props.getContacts}
          totalPages={this.props.contactPages}
        />
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("My Contacts", this.props.data);
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"My Contacts"}
          atlasIcon={"atlas-my-contact"}
          showPABadge={role === "Personal Assistant"}
          containerStyle={{ gridGap: "0.5rem" }}
          actionButton={[
            {
              label: "New Contact",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeContactHOC(true, "showCreateContactModal"),
            },
            {
              label: "Export",
              onShow: true,
              className: "btn-new btn-new--outline-secondary",
              icon: (
                <AtlasIcon
                  svgHref={"atlas-xlsx-file"}
                  style={{ height: 18, width: 18 }}
                />
              ),
              onClick: () =>
                this.props.onChangeContactHOC(true, "showExportConfirmation"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showViewContactModal && (
          <ModalDialog
            title={"Contact Details"}
            onClose={() =>
              this.props.onChangeContactHOC(false, "showViewContactModal")
            }
            removeFooter={true}
            children={
              <ContactDetails
                {...this.props}
                data={this.props.data}
                banks={this.props.banks}
                onLoadContact={this.props.onLoadContact}
                selectedContact={this.props.selectedContact}
                onClickClose={() =>
                  this.props.onChangeContactHOC(false, "showViewContactModal")
                }
              />
            }
          />
        )}
        {this.props.showEditContactModal && (
          <ModalDialog
            title={"Edit Contact"}
            onClose={() =>
              this.props.onChangeContactHOC(false, "showEditContactModal")
            }
            fullWidth={true}
            children={
              <ContactForm
                context={"edit"}
                data={this.props.data}
                banks={this.props.banks}
                can_update={
                  permissionsChecker("My Contacts", this.props.data).can_update
                }
                nationalities={this.props.nationalities}
                onLoadContact={this.props.onLoadContact}
                selectedContact={this.props.selectedContact}
                onClickClose={() =>
                  this.props.onChangeContactHOC(false, "showEditContactModal")
                }
                onClickSubmit={this.props.updateContact}
              />
            }
          />
        )}
        {this.props.showCreateContactModal && (
          <ModalDialog
            title={"New Contact"}
            onClose={() =>
              this.props.onChangeContactHOC(false, "showCreateContactModal")
            }
            fullWidth={true}
            children={
              <ContactForm
                context={"new"}
                data={this.props.data}
                banks={this.props.banks}
                can_create={true}
                nationalities={this.props.nationalities}
                onLoadContact={this.props.onLoadContact}
                onClickClose={() =>
                  this.props.onChangeContactHOC(false, "showCreateContactModal")
                }
                onClickSubmit={this.props.createContact}
              />
            }
          />
        )}
        <DeleteConfirmation
          open={this.props.showDeleteConfirmation}
          title={"Delete Contact"}
          message={"Are you sure to delete this contact?"}
          loading={this.props.onLoadContact}
          positiveAction={() =>
            this.props.deleteContact(this.props.selectedContact.id)
          }
          negativeAction={() =>
            this.props.onChangeContactHOC(false, "showDeleteConfirmation")
          }
        />
        <ConfirmationModal
          open={this.props.showExportConfirmation}
          title={"Export My Contacts"}
          message={
            "Click confirm button to proceed to export contact to excel."
          }
          loading={this.props.onLoadContact}
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() => this.props.exportContact()}
          negativeAction={() =>
            this.props.onChangeContactHOC(false, "showExportConfirmation")
          }
        />
        {this.props.onLoadContact && <LoadingModal />}
      </>
    );
  };
}

export default compose(NationalityHOC, BankHOC, ContactHOC, VersionCheckHOC)(Contact);
