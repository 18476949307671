import React, { Component, createRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { compose } from "redux";
import { connect } from "react-redux";
import HtmlParser from "react-html-parser";

import LoadingOverlay from "components/LoadingModal/overlay";

import LoginForm from "../../components/Form/login";
import LoginImage1 from "../../assets/images/login_img1.png";
import LoginImage2 from "../../assets/images/login_img2.png";
import IQILogoBlack from "../../assets/images/iqi-logo_black.png";
import IQILogoWhite from "../../assets/images/iqi-logo_white.png";
import SupportButtonGroups from "components/Button/supportGroups";
import AtlasIcon from 'components/Icon/atlasIcon'
import CustomButton from "components/Button";

import { getTranslation } from "assets/translation";
import { initGA } from "utils/ga";
import { getItem } from "utils/tokenStore";
import withLocalisation from 'actions/localisation';
import { getAllDictionaryData } from "actions/dictionary";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { getBranches } from "actions/branch";
import { getAgencies } from "actions/agency";
import { login, mobileSSO } from "actions/login";
import { getColorBadge } from "dictionary/badgeColor";
import { setLanguage } from "reducers/language";

import "./index.scss";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class Login extends Component {
  state = {
    showDropDown: false,
    errorPresence: false,
    errorMessage: "",
    showLoadingOverlay: false,
    ajaxCallProgress: 0,
    ajaxErrorMessage: "",
    from: "",
    error: null,
  };
  parentRef = createRef()
  localisedDropDownRef = createRef()

  componentDidMount = () => {
    this.props.getLocalisedSetting()
    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/");
    }
    if (getItem("IQI_ATLAS_JWT_TOKEN") !== null) {
      this.props.history.push("/dashboard/statistic");
    }
    if (this.props.location.search) {
      let query = new URLSearchParams(this.props.location.search);
      let token = query.get("token");
      if (token && token.length > 0) {
        localStorage.setItem("IQI_ATLAS_OUTDATED_TOKEN", "no");
        this.setState(
          {
            from: "ON_LOGIN",
          },
          () => this.props.mobileSSO(atob(token)),
        );
      }
    }
    document.title = "IQI Atlas";
    document.addEventListener("click", this.handleOnClose, true)
  };

  componentDidUpdate = (prevProps) => {
    let { ajaxCallProgress, ajaxSuccess, ajaxError, ajaxErrorMessage } =
      this.props.data.ajaxStatusReducer;
    if (
      ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress
    ) {
      if (ajaxCallProgress === 0) {
        if (ajaxError) {
          this.setState({ showLoadingOverlay: false });
          this.ajaxError(HtmlParser(ajaxErrorMessage));
        }
        if (ajaxSuccess) {
          if (this.state.from === "ON_LOGIN") {
            this.setState(
              { showLoadingOverlay: true, from: "LOGIN_SUCCESS" },
              () => {
                window.location.href.includes("/admin-impersonate")
                  ? this.props.getCurrentSignInProfile()
                  : this.props.getCurrentUserProfile();
              },
            );
          }
          if (this.state.from === "LOGIN_SUCCESS") {
            this.setState({ showLoadingOverlay: true }, () => {
              this.props.history.push("/dashboard/statistic");
            });
          }
        }
      } else {
        this.setState({ showLoadingOverlay: true });
      }
    }

    if (this.props.language !== prevProps.language) {
      this.props.setLanguage(this.props.language);
    }

    document.removeEventListener("click", this.handleOnClose)
  };

  ajaxError = (message) => this.setState({ errorMessage: message });

  onClickForgetPassword = () => this.props.history.push("/forget-password");

  checkErrorPresence = (errorPresence, errorMessage) =>
    this.setState({
      errorPresence: errorPresence,
      errorMessage: errorMessage,
    });

  emailPasswordCheckPassed = (val, credentials) => {
    if (val) {
      localStorage.setItem("IQI_ATLAS_OUTDATED_TOKEN", "no");
      this.setState(
        {
          from: "ON_LOGIN",
        },
        () => this.props.login(credentials, this.props.data.languageReducer.language),
      );
    }
  };

  handleOnClose = (event) => {
    const parent = this.parentRef.current;
    const checkbox = document.querySelector('.at-checkbox__input');
    const checkboxLabel = document.querySelector('.at-checkbox');
  
    if (
      parent &&
      !parent.contains(event.target) &&
      (!checkbox || !checkbox.contains(event.target)) &&
      (!checkboxLabel || !checkboxLabel.contains(event.target))
    ) {
      this.setState({ showDropDown: false });
    }
  };
  
  onClickDropdownItem = (param, reference) => {
    if (reference === this.localisedDropDownRef) {
      this.setState({ showDropDown: false })
      this.props.setLanguage(param.language_code);
    }
  }
  
  renderLocalisedOptions = (ref) => {
    return (
      <div
        ref={ref}
        className={"at-language__dropdown"}
        style={{
          display: this.state.showDropDown === ref ? "block" : "none",
          position: "absolute",
        }}>
        <div className={`at-party__dropbear--list`}>
          {ref === this.localisedDropDownRef ? (
            this.props.settings?.map((setting, index) => (
              <button 
                key={index} 
                onClick={() => this.onClickDropdownItem(setting, ref)}
                style={this.props.data.languageReducer.language?.toLowerCase() === setting.language_code?.toLowerCase() ? { backgroundColor: "#f3f4f6" } : null}>
                {`${setting.language_name}`}
              </button>
            ))
          ) : null}
        </div>
      </div>
    );
  }

  render = () => {
    const language = this.props.data.languageReducer.language || "en";
    return (
      <>
        <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
          <div className="at-desktop-view__controller at-login__banner">
            <div className="at-login__banner-header">
              <img
                src={IQILogoWhite}
                alt={"IQI Logo White"}
                style={{ width: 70, margin: "20px 0px" }}
              />
              <p>{getTranslation("label.sidebar.message", language)}</p>
            </div>
            <div className="at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img
                  src={LoginImage2}
                  alt={"Login Image 2"}
                  style={{ maxWidth: 250 }}
                />
              </Slider>
            </div>
          </div>
          <div className="login-container">
            <div className="at-login__header">
              <div ref={this.parentRef} className="at-login-header-language__container d-flex justify-content-end">
                <div className="at-language_badge d-flex align-items-center">
                  <CustomButton
                    id={'language-dropdown'}
                    className="at-topbar-opt__btn mx-2"
                    children={<AtlasIcon svgHref={"atlas-global"}/>}
                    onClick={() => this.setState({ showDropDown: this.state.showDropDown === this.localisedDropDownRef ? false : this.localisedDropDownRef })}
                    tooltip={this.state.showDropDown !== this.localisedDropDownRef}
                    tooltipChildren={
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>
                          Language
                        </span>
                        <div
                          className="at-status_badge"
                          style={{ ...getColorBadge("Blue") }}
                        >
                          BETA
                        </div>
                      </div>
                    }
                    tooltipPosition={"bottom"}
                    tooltipID={"TopbarLanguageDropdown"}
                  />
                  <p className="small">{language.toUpperCase()}</p>
                </div>
                {this.renderLocalisedOptions(this.localisedDropDownRef)}
              </div>
              <img
                src={IQILogoBlack}
                alt={"IQI Logo Black"}
                style={{ width: "114px" }}
              />
              <h2>{getTranslation("label.title", language)}</h2>
            </div>
            <div className="login-panel">
              <LoginForm
                {...this.props}
                language={language}
                errorMessage={this.state.errorMessage}
                errorPresence={(errorPresence, errorMessage) =>
                  this.checkErrorPresence(errorPresence, errorMessage)
                }
                emailPasswordCheckPassed={(val, credentials) =>
                  this.emailPasswordCheckPassed(val, credentials)
                }
                onClickForgetPassword={() => this.onClickForgetPassword()}
              />
            </div>
            <div className="at-mobile-view__controller at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img src={LoginImage2} alt={"Login Image 2"} />
              </Slider>
            </div>
            <div className="at-login__footer">
              <p className="small">{`IQI Holdings Sdn Bhd (1018842-U)`}</p>
              <p className="small" style={{ whiteSpace: "pre-line" }}>
                {`26th -28th Floor, Tower D, Millerz Square, No. 357, Jalan Kelang Lama, 58000 Kuala Lumpur, Malaysia. \nT: +60374506655`}
              </p>
              <p
                className="small"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.iqiglobal.com/privacy-policy",
                    "__blank",
                  )
                }
              >
                <u className="mx-2">{getTranslation("label.terms_of_use", language)}</u>•
                <u className="mx-2">{getTranslation("label.privacy_policy", language)}</u>
              </p>
            </div>
          </div>
        </div>
        <LoadingOverlay showLoadingOverlay={this.state.showLoadingOverlay} />
        <SupportButtonGroups language={language} />
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default 
compose(
  connect(mapStateToProps, {
    login,
    mobileSSO,
    getAllDictionaryData,
    getCurrentUserProfile,
    getCurrentSignInProfile,
    getBranches,
    getAgencies,
    setLanguage
  }),
  withLocalisation
)(Login);
