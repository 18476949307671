/*
Sample DataSet
newTransaction: {
      TransactionType: 'SALE',
      PymtMethod: 'CC',
      ServiceID: 'IQH',
      PaymentID: 'PY20191031P0000028',
      OrderNumber: 'OR20191031O0000028',
      PaymentDesc: 'Testing payment activity for Atlas IQI',
      MerchantName: 'IQI Atlas',
      MerchantCallbackURL: 'https://atlastest.iqiglobal.com/api/payment_gateway/callbacks',
      MerchantReturnURL: 'https://atlas-payment-gateway.surge.sh/#/payment-gateway-return',
      Amount: '100.00',
      CurrencyCode: 'MYR',
      CustIP: '',
      CustName: 'Shi Hao',
      CustEmail: 'shihao@iqiglobal.com',
      CustPhone: '0167761753',
      PageTimeout: '2500',
      HashValue: ''
    }

`iqh12345${ tmp.ServiceID }${ tmp.PaymentID }${ tmp.MerchantReturnURL }${ tmp.MerchantCallbackURL }${ tmp.Amount }${ tmp.CurrencyCode }${ tmp.CustIP }${ tmp.PageTimeout }`
*/

import React, { Component, Fragment } from "react";
import {
  Card,
  Typography,
  CardContent,
  Divider,
  Paper,
  Button,
} from "@material-ui/core";
import { sha256, sha224 } from "js-sha256";
import _ from "lodash";

import BackgroundImg from "assets/images/login_bg.jpg";
import IQILogo from "assets/images/iqi_logo.png";
import VisaLogo from "assets/images/visa-logo-png-transparent.png";
import MasterCardLogo from "assets/images/mastercard-logo.png";
import AtlasLoadingOverlay from "components/LoadingModal/overlay";
import View from "./View";

import PaymentHOC from "./actions";

import { initGA } from "utils/ga";

import "stylesheets/components/form-input/index.scss";

class PaymentGateway extends Component {
  state = {
    status: "start",
    newTransaction: {
      TransactionType: "SALE",
      PymtMethod: "DD",
      ServiceID: "IQH",
      PaymentID: "PY20191031P0000029",
      OrderNumber: "OR20191031O0000029",
      PaymentDesc: "Agent registration for Atlas IQI",
      MerchantName: "IQI Atlas",
      MerchantCallbackURL:
        "https://atlastest.iqiglobal.com/api/payment_gateway/callbacks",
      MerchantReturnURL:
        "https://api.iqiglobal.com/api/payment_gateway/redirect",
      // MerchantReturnURL: window.location.host.indexOf( 'atlas.iqiglobal.com' ) !== -1 ? 'https://atlas.iqiglobal.com/#/payment-gateway/respond' : 'http://localhost:3005/#/payment-gateway/respond',
      Amount: "550.00",
      CurrencyCode: "MYR",
      CustIP: "",
      CustName: "",
      CustEmail: "",
      CustPhone: "",
      PageTimeout: "2500",
      HashValue: "",
    },
  };

  componentDidMount = () => {
    // 1 - pending
    // 2 - success
    // 3 - failed
    let { remark } = this.props.match.params;
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";
    if (remark === "start") {
      // call api to get service ID, payment ID, order number, custIP
      if (!this.props.location.state) {
        this.props.history.push({ pathname: `${tmpPath}/dashboard/profile/` });
      } else {
        this.props.storePathFrom(this.props.location.state.from);
        if (this.props.location.state.from === "/register/register-payment/") {
          this.props.getServiceInfo(
            this.props.data.registerReducer.newUser.agent_registration_id,
            this.props.data.registerReducer.newUser.id,
          );
        } else {
          this.props.getServiceInfo(
            tmpProfileReducer.agent_registration_id,
            tmpProfileReducer.id,
          );
        }
      }
    } else if (remark === "respond") {
      // redirect to proper page
      if (this.props.location.search === "") {
        // call api
        if (
          this.props.data.registerReducer.pathFrom ===
          "/register/register-payment/"
        ) {
          this.props.getServiceStatus(
            this.props.data.registerReducer.paymentID,
            this.props.data.registerReducer.newUser.agent_registration_id,
          );
        } else {
          this.props.getServiceStatus(
            this.props.data.registerReducer.paymentID,
            tmpProfileReducer.agent_registration_id,
          );
        }
      } else if (this.props.location.search === "?success") {
        this.setState({ status: "success" });
      } else {
        this.setState({ status: "cancelled" });
      }
    }
  };

  componentDidUpdate = (pp) => {
    if (this.props.onLoadPayment !== pp.onLoadPayment) {
      if (!this.props.onLoadPayment) {
        let { remark } = this.props.match.params;
        if (remark === "start") {
          let {
            service_id,
            payment_id,
            merchant_callback_url,
            cust_ip,
            order_number,
            merchant_name,
          } = this.props.info;
          this.props.storePaymentID(payment_id);
          let tmpNewTransaction = this.state.newTransaction;
          tmpNewTransaction.ServiceID = service_id;
          tmpNewTransaction.PaymentID = payment_id;
          tmpNewTransaction.MerchantCallbackURL = merchant_callback_url;
          tmpNewTransaction.CustIP = cust_ip;
          tmpNewTransaction.OrderNumber = order_number;
          tmpNewTransaction.MerchantName = merchant_name;
          this.setState({ newTransaction: tmpNewTransaction }, () => {
            let tmp = tmpNewTransaction;
            let password =
              window.location.host.indexOf("atlas.iqiglobal.com") !== -1
                ? "95HOyYUe"
                : "iqh12345";
            let toHashKey = `${password}${tmp.ServiceID}${tmp.PaymentID}${tmp.MerchantReturnURL}${tmp.MerchantCallbackURL}${tmp.Amount}${tmp.CurrencyCode}${tmp.CustIP}${tmp.PageTimeout}`;
            tmp.HashValue = sha256(toHashKey);
            this.setState({ newTransaction: tmpNewTransaction });
          });
        } else {
          if (this.props.status === 2) {
            this.setState({ status: "success" });
          }
          if (this.props.status === 3) {
            this.setState({ status: "fail" });
          }
        }
      }
    }
  };

  onChangeNewTransaction = (title, val) => {
    let tmp = this.state.newTransaction;
    tmp[title] = val;
    return this.setState({ newTransaction: tmp });
  };

  render = () => {
    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    return (
      <div>
        <img
          src={BackgroundImg}
          alt={"LoginBackground"}
          style={{ width: "100vw", height: "100vh" }}
        />
        <div className="login-container">
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img src={IQILogo} alt={"IqiLogo"} style={{ width: "114px" }} />
          </div>
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card
              style={{
                padding: 15,
                marginTop: 30,
                maxHeight: "80vh",
                overflowY: "auto",
                width: "70%",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
                variant={"h5"}
              >
                {"Payment Gateway"}
              </Typography>
              <CardContent>
                {this.state.status === "start" && (
                  <Fragment>
                    <View
                      data={this.state.newTransaction}
                      onChangeNewTransaction={this.onChangeNewTransaction}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      {" "}
                      Back
                    </Button>
                  </Fragment>
                )}
                {this.state.status === "pending" && (
                  <Fragment>
                    <Paper style={{ padding: "15px" }}>
                      <Typography
                        variant={"subtitle1"}
                        style={{ textTransform: "none" }}
                      >
                        {"Payment pending, please try again!"}
                      </Typography>
                      <Button
                        variant="contained"
                        style={{ marginTop: 20 }}
                        color="secondary"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${tmpPath}/dashboard/profile/`,
                          })
                        }
                      >
                        Back to Payment Selection
                      </Button>
                    </Paper>
                  </Fragment>
                )}
                {this.state.status === "success" && (
                  <Fragment>
                    <Paper style={{ padding: "15px" }}>
                      <Typography
                        variant={"subtitle1"}
                        style={{ textTransform: "none" }}
                      >
                        {"Payment success!"}
                      </Typography>
                      <Button
                        variant="contained"
                        style={{ marginTop: 20 }}
                        color="secondary"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${tmpPath}/dashboard/profile/`,
                          })
                        }
                      >
                        Back to Payment Selection
                      </Button>
                    </Paper>
                  </Fragment>
                )}
                {this.state.status === "fail" && (
                  <Fragment>
                    <Paper style={{ padding: "15px" }}>
                      <Typography
                        variant={"subtitle1"}
                        style={{ textTransform: "none" }}
                      >
                        {
                          "We’re sorry, but it looks like something went wrong and we were unable to collect the required payment. Please try again."
                        }
                      </Typography>
                      <Button
                        variant="contained"
                        style={{ marginTop: 20 }}
                        color="secondary"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${tmpPath}/dashboard/profile/`,
                          })
                        }
                      >
                        Back to Payment Selection
                      </Button>
                    </Paper>
                  </Fragment>
                )}
                {this.state.status === "cancelled" && (
                  <Paper style={{ padding: "15px" }}>
                    <Typography
                      variant={"subtitle1"}
                      style={{ textTransform: "none" }}
                    >
                      {
                        "We’re sorry, but it looks like something went wrong and we were unable to collect the required payment. Click on the OK button below, login to IQI Atlas with the details you should have received and try to make the payment again."
                      }
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ marginTop: 20 }}
                      color="secondary"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${tmpPath}/dashboard/profile/`,
                        })
                      }
                    >
                      OK
                    </Button>
                  </Paper>
                )}
              </CardContent>
            </Card>
            <AtlasLoadingOverlay
              showLoadingOverlay={this.props.onLoadPayment}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default PaymentHOC(PaymentGateway);
