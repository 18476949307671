import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomProgressBar from "./components/progressBar";

export const MemberSearchParams = [
  {
    label: "Name",
    value: "agent_name",
    type: "input",
    param: "",
  },
  {
    label: "Status of Eligibility",
    value: "status",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Qualified" },
      { value: "2", label: "Pre-Qualified" },
    ],
  },
];

export const projectColumn = [
  {
    header: "Project",
    accessor: "projectName",
    searchFlag: "projectName",
    columnStyle: { width: "150%" },
  },
  {
    header: "Unit",
    accessor: "unitNumber",
    searchFlag: "unitNumber",
    columnStyle: { width: "80%" },
  },
  {
    header: "Units Sold",
    accessor: "percentage",
    searchFlag: "percentage",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
  },
  {
    header: "Net Price Portion",
    accessor: "netPricePort",
    searchFlag: "netPricePort",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Unallocated Amount",
    accessor: "amountUnallocated",
    searchFlag: "amountUnallocated",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Allocated Amount",
    accessor: "amountAllocated",
    searchFlag: "amountAllocated",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_right mr-2",
    renderColumn: (rowData) => {
      return (
        <p className={'at-column-responsive_right'}>
          <span> 
            {(rowData.amountAllocated === undefined || rowData.amountAllocated === null)
              ? "N/A" 
              : rowData.amountAllocated 
            } 
          </span>
        </p>
      )
    }
  },
];

export const subSaleColumn = [
  {
    header: "Form No.",
    accessor: "subsaleFormNo",
    searchFlag: "subsaleFormNo",
    columnStyle: { width: "80%" },
  },
  {
    header: "Submission Date",
    accessor: "submissionDate",
    searchFlag: "submissionDate",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_center",
  },
  {
    header: "Agent Percentage",
    accessor: "agentPercent",
    searchFlag: "agentPercent",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_center",
  },
  {
    header: "Agency Commission",
    accessor: "agencyComm",
    searchFlag: "agencyComm",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Agency Commission Portion",
    accessor: "agencyCommPort",
    searchFlag: "agencyCommPort",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_right mr-2",
    renderColumn: (rowData) => {
      return (
        <p className={'at-column-responsive_right'}>
          <span> 
            {(rowData.agencyCommPort === undefined || rowData.agencyCommPort === null) 
              ? "N/A" 
              : rowData.agencyCommPort 
            } 
          </span>
        </p>
      )
    }
  },
];

export const personalColumnData = (onChangeCampaignTrackerHOC) => [
  {
    header: "Type",
    searchFlag: "promotionName",
    columnStyle: { width: "150%" },
    renderColumn: (rowData) => (
      <>
        <span style={{ fontSize: 14, fontWeight: 500 }}>
          {rowData.promotionName}
        </span>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
            marginBottom: 5,
            color: "#2563EB",
            textAlign: "left",
          }}
          onClick={() => {
            onChangeCampaignTrackerHOC(rowData, "selectPromotion");
            onChangeCampaignTrackerHOC(true, "showPersonCampaignTerm");
          }}
        >
          <AtlasIcon
            svgHref={"atlas-info-circle-fill"}
            style={{ width: 14, height: 14, marginRight: 4, fill: "#3B82F6" }}
          />
          <span style={{ fontSize: 14 }}> Term & Conditions </span>
        </button>
      </>
    ),
  },
  {
    header: "Current Progress",
    accessor: "currentProgress",
    searchFlag: "accumulatedValue",
    hideFromSearch: true,
    columnStyle: { width: "60%" },
    customClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Campaign Target",
    accessor: "campaignTarget",
    searchFlag: "targetValue",
    hideFromSearch: true,
    customClass: "at-column-responsive_right",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Status of Eligibility",
    searchFlag: "progressRatio",
    renderColumnSytle: { display: "contents" },
    hideFromSearch: true,
    columnClass: "at-compaign-tacker__progress-column",
    contentClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <>
        {rowData.eligible === "YES" && (
          <div className="mt-1" style={{ width: "100%" }}>
            <div className="at-status__bar">
              <AtlasIcon
                svgHref={"atlas-tick-circle"}
                style={{ fill: "#039855", minWidth: 24, width: 24, height: 24 }}
              />
            </div>
          </div>
        )}
        {rowData.eligible === "NO" && (
          <CustomProgressBar
            totalValue={parseFloat(rowData.targetValue)}
            data={{
              id: "sales-progress-ratio",
              name: "Status Eligibility",
              barColor: "#F0631D",
              backgroundColor: "#D1D5DB",
              value: parseFloat(rowData.accumulatedValue),
            }}
          />
        )}
      </>
    ),
  },
];

export const qualifiedMemberColumn = [
  {
    header: "Name",
    accessor: "agentName",
    searchFlag: "agentName",
    hideFromSearch: false,
    columnStyle: { width: "150%" },
  },
  {
    header: "Current Progress",
    accessor: "currentProgress",
    searchFlag: "accumulatedValue",
    hideFromSearch: true,
    columnStyle: { width: "60%" },
    customClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
  },
  {
    header: "Status of Eligibility",
    searchFlag: "progressRatio",
    renderColumnSytle: { display: "contents" },
    hideFromSearch: true,
    columnClass: "at-compaign-tacker__progress-column",
    contentClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <>
        {rowData.eligible === "YES" && (
          <div style={{ width: "100%" }}>
            <div className="at-status__bar">
              <AtlasIcon
                svgHref={"atlas-tick-circle"}
                style={{ fill: "#039855", minWidth: 24, width: 24, height: 24 }}
              />
            </div>
          </div>
        )}
        {rowData.eligible === "NO" && (
          <CustomProgressBar
            totalValue={parseFloat(rowData.targetValue)}
            data={{
              id: "sales-progress-ratio",
              name: "Progress Status",
              barColor: "#F0631D",
              backgroundColor: "#D1D5DB",
              value: parseFloat(rowData.accumulatedValue),
            }}
          />
        )}
      </>
    ),
  },
];

export const teamColumnData = (onChangeCampaignTrackerHOC) => [
  {
    header: "Type",
    accessor: "promotionName",
    searchFlag: "promotionName",
    columnStyle: { width: "150%" },
    renderColumn: (rowData) => (
      <>
        <span style={{ fontSize: 14, fontWeight: 500 }}>
          {rowData.promotionName}
        </span>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
            marginBottom: 5,
            color: "#2563EB",
            textAlign: "left",
          }}
          onClick={() => {
            onChangeCampaignTrackerHOC(rowData, "selectPromotion");
            onChangeCampaignTrackerHOC(true, "showTeamCampaignTerm");
          }}
        >
          <AtlasIcon
            svgHref={"atlas-info-circle-fill"}
            style={{ width: 14, height: 14, marginRight: 4, fill: "#3B82F6" }}
          />
          <span style={{ fontSize: 14 }}> Term & Conditions </span>
        </button>
      </>
    ),
  },
  {
    header: "Agent With Sales",
    accessor: "agentsWithSalesCount",
    searchFlag: "agentsWithSalesCount",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
  },
  {
    header: "Eligible Agents",
    accessor: "eligibleAgentsCount",
    searchFlag: "eligibleAgentsCount",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
  },
];