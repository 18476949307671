import { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const withEmailCampaigns = (WrappedComponent) => {
  class EmailCampaignsHOC extends Component {
    state = {
      onLoadEmailCampaigns: false,
      emailCampaignDialog: "",
      emailCampaigns: [],
      emailCampaignsPages: [],
      searchParams: [
        {
          label: "Campaign Name",
          value: "name",
          type: "input",
          param: "",
        },
        {
          label: "Status",
          value: "status",
          type: "radio",
          col: 8,
          param: "",
          options: [
            { value: "", label: "All" },
            { value: "1", label: "Draft" },
            { value: "2", label: "Scheduled" },
            { value: "3", label: "Running" },
            { value: "4", label: "Sent" },
          ],
        },
      ],
      newEmailCampaign: {},
      selectedEmailCampaign: {},
      originalEmailCampaign: {},
      selectedId: 0,

      showCreateModal: false,
      showUpdateModal: false,
      showPreviewModal: false,
      showDeleteModal: false,
      showSuspendModal: false,
    };

    load = (param) => this.setState({ onLoadEmailCampaigns: param });

    onChangeEmailCampaignsHOC = (key, val) => this.setState({ [key]: val });

    getEmailCampaigns = (page = 1, query = "") => {
      if (query !== "") {
        query = query.replace("[name]", "").replace("q[status]", "status");
      }

      this.props.storeLastView({ page, query });

      Get(
        `/admin/email_campaigns?${query}page=${page}`,
        this.getEmailCampaignsSuccess,
        this.getEmailCampaignsError,
        this.load,
      );
    };
    getEmailCampaignsSuccess = (data) => {
      const pages = [];

      for (let i = 0; i < data.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        emailCampaigns: data,
        emailCampaignsPages: pages,
      });
    };
    getEmailCampaignsError = (error) => requestError(error);

    getSelectedEmailCampaign = (id) =>
      Get(
        `/admin/email_campaigns/${id}`,
        this.getSelectedEmailCampaignSuccess,
        this.getSelectedEmailCampaignError,
        this.load,
      );
    getSelectedEmailCampaignSuccess = (data) => {
      this.setState({ selectedEmailCampaign: data, originalEmailCampaign: data });
    };
    getSelectedEmailCampaignError = (error) => requestError(error);

    createEmailCampaign = (data) =>
      Post(
        `/admin/email_campaigns`,
        data,
        this.createEmailCampaignSuccess,
        this.createEmailCampaignError,
        this.load,
      );
    createEmailCampaignSuccess = (data) => {
      requestSuccess("Email campaign was created successfully!");

      this.setState({
        emailCampaignDialog: "update",
        newEmailCampaign: {},
        selectedEmailCampaign: {},
        originalEmailCampaign: {},
        showCreateModal: false,
        showUpdateModal: true,
      });
      this.getEmailCampaigns();
      this.getSelectedEmailCampaign(data.id);
    };
    createEmailCampaignError = (error) => requestError(error);

    updateEmailCampaign = (data) =>
      Put(
        `/admin/email_campaigns/${this.state.selectedEmailCampaign.id}`,
        data,
        this.updateEmailCampaignSuccess,
        this.updateEmailCampaignError,
        this.load,
      );
    updateEmailCampaignSuccess = (data) => {
      requestSuccess("Email campaign was updated successfully!");
      this.getSelectedEmailCampaign(data.id);
      this.getEmailCampaigns();
    };
    updateEmailCampaignError = (error) => requestError(error);

    deleteEmailCampaign = () =>
      Delete(
        `/admin/email_campaigns/${this.state.selectedId}`,
        this.deleteEmailCampaignSuccess,
        this.deleteEmailCampaignError,
        this.load,
      );
    deleteEmailCampaignSuccess = () => {
      requestSuccess("Email campaign was deleted successfully!");

      this.getEmailCampaigns();
      this.setState({
        selectedId: 0,
        emailCampaignDialog: "",
      });
      this.state.showDeleteModal = false;
    };
    deleteEmailCampaignError = (error) => {
      requestError(error);

      this.setState({
        selectedId: 0,
        emailCampaignDialog: "",
      });
    };

    suspendEmailCampaign = () =>
      Post(
        `/admin/email_campaigns/${this.state.selectedId}/suspend`,
        {},
        this.suspendEmailCampaignSuccess,
        this.suspendEmailCampaignError,
        this.load,
      );
    suspendEmailCampaignSuccess = () => {
      requestSuccess("Email campaign was suspended successfully!");

      this.getEmailCampaigns();
      this.setState({
        selectedId: 0,
        emailCampaignDialog: "",
      });
    };
    suspendEmailCampaignError = (error) => {
      requestError(error);

      this.setState({
        selectedId: 0,
        emailCampaignDialog: "",
      });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeEmailCampaignsHOC={this.onChangeEmailCampaignsHOC}
          getEmailCampaigns={this.getEmailCampaigns}
          getSelectedEmailCampaign={this.getSelectedEmailCampaign}
          createEmailCampaign={this.createEmailCampaign}
          updateEmailCampaign={this.updateEmailCampaign}
          deleteEmailCampaign={this.deleteEmailCampaign}
          suspendEmailCampaign={this.suspendEmailCampaign}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(EmailCampaignsHOC);
};

export default withEmailCampaigns;
