import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";

import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import { settingFormData } from "./assets";

const RenewalSettings = ({
  mode,
  selectedRenewalSetting,

  submitData,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    renewal_year: "",
    cpd_minimum_requirement: "",
    from_age: "",
    to_age: "",
    description: "",
  });

  useEffect(() => {
    if (selectedRenewalSetting) {
      setFormData({
        ...selectedRenewalSetting,
        renewal_year: `${selectedRenewalSetting.renewal_year}`,
      });
    }
  }, [selectedRenewalSetting]);

  const onChangeFieldData = (val, context) => {
    let temp = _.cloneDeep(formData);
    temp[context] = val;
    setFormData(temp);
  };

  return (
    <ModalDialog
      title={
        mode === "Create"
          ? "New REA Renewal Setting"
          : "Update REA Renewal Setting"
      }
      onClose={onClose}
      children={
        <div className="row mb-10">
          {settingFormData.map((item, index) => (
            <div key={index} className={`col-md-12`}>
              <label className="at-form-label">{item.label}</label>
              <br />
              {item.type === "date" && (
                <div className="at-form-input">
                  <AtlasIcon
                    className={"at-form-input__calendar"}
                    svgHref={"atlas-calendar-input"}
                  />
                  <DatePicker
                    showYearDropdown
                    dateFormat={item.format}
                    showYearPicker={item.showYearPicker}
                    className="at-form-input__input"
                    value={formData[item.value]}
                    onChange={(val) =>
                      onChangeFieldData(
                        Moment(val).format(item.format),
                        item.value,
                      )
                    }
                  />
                  <div className="at-form-input__required">required *</div>
                </div>
              )}
              {item.type !== "date" && (
                <CustomFormInput
                  required={true}
                  type={item.type}
                  value={formData[item.value]}
                  onChangeValue={(val) => onChangeFieldData(val, item.value)}
                  placeholder={item.label}
                />
              )}
            </div>
          ))}
        </div>
      }
      footer={
        <div className={`d-flex align-items-center g-3`}>
          <AtlasButton
            disabled={_.values(formData).some((x) => !x || x === "")}
            className={"btn-new btn-new--primary"}
            onClick={() => submitData(formData)}
            children={mode}
          />
          <AtlasButton
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => onClose()}
            children={mode === "Create" ? "Cancel" : "Close"}
          />
        </div>
      }
    />
  );
};

export default RenewalSettings;
