import React, { Component, Suspense } from "react";
import Moment from "moment";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";

const DayList = React.lazy(() => import("./dayList"));

class MonthList extends Component {
  state = {
    currentDisplayMonth: Moment().clone().startOf("month"),
    dateToday: Moment(),
    currentDisplayEventsData: [],
    maxIconCounts: 1,
  };

  componentDidMount = () => {
    this.setState({
      currentDisplayMonth: this.props.currentDisplayMonth,
      dateToday: this.props.dateToday,
    });
    this.renderDayList();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({
        currentDisplayMonth: this.props.currentDisplayMonth,
        dateToday: this.props.dateToday,
      });
    }

    if (
      prevProps.storeForArrangedEventData !==
      this.props.storeForArrangedEventData
    ) {
      this.renderDayList();
    }
  };

  onSelectEvent = (val) => {
    this.props.onClickEventDetails(val);
  };

  renderDayList = () => {
    const { data, storeForArrangedEventData, currentDisplayMonth } = this.props;

    let tmpCurrentDisplayEventsData = [];

    const nextYear = Moment().add(1, "years").year();
    const currentMonth = Moment().month();
    const currentYear = Moment().year();

    const nonBirthdayFilter = (eventDate, compareDate) => {
      let tmpDate = Moment(eventDate).clone().startOf("date");

      if (tmpDate) {
        return tmpDate.year() === compareDate.year();
      }
      return false;
    };

    const durationFilter = (month, year) =>
      (month >= currentMonth && year === currentYear) ||
      (month <= currentMonth && year === nextYear);

    let dayStart = Moment().date();

    for (
      let m = Moment().month(), y = currentYear;
      durationFilter(m, y);
      m = (m + 1) % 12
    ) {
      let tmpMonthData = storeForArrangedEventData[m];

      if (tmpMonthData && tmpMonthData.length > 0) {
        const maxDate = Moment().year(y).month(m).endOf("month").date();
        let filterDate =
          Moment().month() === m && Moment().year() === y ? dayStart : 0;

        for (let d = filterDate; d <= maxDate; d++) {
          let tmpDailyEvents = tmpMonthData[d];

          let tmpFilteredDailyEvents = _.filter(tmpDailyEvents, (item) => {
            let tmpCompareDate = Moment()
              .year(y)
              .month(m)
              .date(d)
              .startOf("date");

            return (
              item.type === "birthday" ||
              (item.type !== "birthday" &&
                nonBirthdayFilter(item.date, tmpCompareDate))
            );
          });

          if (tmpFilteredDailyEvents && tmpFilteredDailyEvents.length > 0) {
            tmpCurrentDisplayEventsData.push(
              <DayList
                key={`${Moment().year(y).month(m).date(d).unix()}`}
                data={data}
                date={Moment().year(y).month(m).date(d)}
                currentDisplayMonth={currentDisplayMonth}
                rowItem={tmpFilteredDailyEvents}
                onClickEventDetails={(val) => this.onSelectEvent(val)}
              />,
            );
          }
        }
      }
      if (m === 11) {
        y++;
      }
    }

    this.setState({ currentDisplayEventsData: tmpCurrentDisplayEventsData });
  };

  render = () => {
    const { currentDisplayEventsData } = this.state;

    return (
      <Suspense fallback={<LoadingModal />}>
        {currentDisplayEventsData.length > 0 && currentDisplayEventsData}
      </Suspense>
    );
  };
}

export default MonthList;
