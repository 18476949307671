export const getTranslation = (key, language) => {
  const translation = japaneseTranslation.find((translation) => translation.key === key);
  if (translation) {
    return translation[language];
  }
  return key;
}

export const japaneseTranslation = [
  {
    key: "yes",
    en: "Yes",
    jp: "はい",
  },
  {
    key: "no",
    en: "No",
    jp: "いいえ",
  },
  {
    key: "full_name",
    en: "Full Name",
    jp: "フルネーム",
  },
  {
    key: "email",
    en: "Email",
    jp: "Eメール",
  },
  {
    key: "mobile_contact_number",
    en: "Contact Number",
    jp: "連絡先番号",
  },
  {
    key: "identification_number",
    en: "ID/NRIC",
    jp: "ID/NRIC",
  },
  {
    key: "referrer_name",
    en: "Referrer Name",
    jp: "参照元名",
  },
  {
    key: "gender",
    en: "Gender",
    jp: "性別"
  },
  {
    key: "date_of_birth",
    en: "Date of Birth",
    jp: "生年月日",
  },
  {
    key: "marital_status",
    en: "Marital Status",
    jp: "配偶者の有無",
  },
  {
    key: "branch_name",
    en: "Branch Name",
    jp: "支店名",
  },
  {
    key: "team",
    en: "Team",
    jp: "チーム",
  },
  {
    key: "next",
    en: "Next",
    jp: "続く",
  },
  {
    key: "back",
    en: "Back",
    jp: "戻る",
  },
  {
    key: "step",
    en: "Step",
    jp: "ステップ",
  },
  {
    key: "submit",
    en: "Submit",
    jp: "提出する",
  },
  {
    key: "successfull",
    en: "Successful!",
    jp: "成功！",
  },
  {
    key: "ok",
    en: "Ok",
    jp: "わかりました",
  },
  {
    key: "pending",
    en: "Pending",
    jp: "保留中",
  },
  {
    key: "approved",
    en: "Approved",
    jp: "承認された",
  },
  {
    key: "rejected",
    en: "Rejected",
    jp: "拒否されました",
  },
  {
    key: "referrr_transfer",
    en: "Referrer Transfer",
    jp: "紹介者の転送",
  },
  {
    key: "matrix_transfer",
    en: "Matrix Transfer",
    jp: "マトリックス転送",
  },
  {
    key: "agent.profile.welcome2",
    en: "IQI Atlas Profile",
    jp: "IQI アトラスへようこそ",
  },
  {
    key: "agent.profile.welcome1",
    en: "Welcome to IQI Atlas",
    jp: "IQI アトラスへようこそ",
  },
  {
    key: "agent.profile.approval_status",
    en: "Approval Status",
    jp: "承認状況",
  },
  {
    key: "agent.profile.welcome_message1",
    en: "Well done! You did a fantastic job coming to this stage.",
    jp: "お疲れさまでした！これで情報入力は完了しました。"
  },
  {
    key: "agent.profile.welcome_message2",
    en: "Now, you have to make sure that all the status below have been approved and completed.",
    jp: "この後、プロフィール登録が承認されるまで今しばらくお待ちください。\n通常、24時間以内には済むプロセスです。"
  },
  {
    key: "agent.profile.admin_approval_status",
    en: "Admin Approval Status",
    jp: "管理者の承認ステータス",
  },
  {
    key: "agent.profile.referrer_approval_status",
    en: "Referrer Approval Status",
    jp: "紹介者の承認ステータス",
  },
  {
    key: "agent.profile.footer_message1",
    en: "You may come back later and click on the button below to check your registration approval status.",
    jp: "後程このページから登録承認状況を確認する事ができます。"
  },
  {
    key: "agent.profile.footer_message2",
    en: "You will be redirect to the Atlas platform if your registration approval has been approved by Admin and Referrer.",
    jp: "登録が管理者と紹介者によって承認されたますと、Atlasプラットフォームにリダイレクトされます。"
  },
  {
    key: "agent.profile.check_approval_status",
    en: "Check Approval Status",
    jp: "承認状況を確認する",
  }
]