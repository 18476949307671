import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { FaSignOutAlt } from "react-icons/fa";

import {
  Dialog,
  Button,
  Typography,
  Tooltip,
  Card,
  CardContent,
  Grid,
  FormControl,
  FormHelperText,
  CardHeader,
  CardActions,
  SnackbarContent,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AlertBox from "components/AlertBox";
import CustomUpload from "components/Input/upload";
import AtlasIcon from "components/Icon/atlasIcon";

import TrainingStatusModalHOC from "./actions/index";
import WithLogout from "actions/logout";

import { getColorBadge } from "dictionary/badgeColor";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";
import { getTranslation } from "./assets"

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const statusDictionary = [
  { id: 1, label: "pending" },
  { id: 2, label: "approved" },
  { id: 3, label: "rejected" },
  { id: 4, label: "referrer_transfer" },
  { id: 5, label: "matrix_transfer" },
];

const statusColor = [
  { id: 1, colorName: "Yellow" },
  { id: 2, colorName: "Green" },
  { id: 3, colorName: "Red" },
];

const rejectReason = [
  { label: "Payment Proof", value: "has_payment_issue" },
  { label: "Resignation Letter", value: "has_resignation_letter_issue" },
];

class TrainingStatusModal extends Component {
  componentDidMount = () => {
    const { id, branch_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getApprovalStatus();
    this.props.getPaymentStatus(id);
    this.props.getBankInformation(branch_id);
  };

  onClickSignOut = () => this.props.sendLogoutRequest();

  onClickGetStatus = () => {
    window.location.href.includes("/admin-impersonate")
      ? this.props.getCurrentSignInProfile()
      : this.props.getCurrentUserProfile();

    this.props.onChangeTrainingStatusHOC("profileReducer", "from");
  };

  componentDidUpdate = (prevProps) => {
    let { ajaxCallProgress } = this.props.data.ajaxStatusReducer;
    if (
      ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress
    ) {
      if (ajaxCallProgress !== 0 && this.props.from === "profileReducer") {
        this.props.onChangeTrainingStatusHOC(true, "loading");
      } else if (ajaxCallProgress === 0) {
        this.props.onChangeTrainingStatusHOC(false, "loading");
      }
    }
  };

  renderUploadLetterContent = () => {
    const { onChangeFile, resignationLetter, onClickSubmitResignationLetter } = this.props;

    return (
      <Card className={"mt-10"}>
        <CardHeader title={"Reupload resignation letter"} />
        <CardContent>
          <CustomUpload
            multiple={false}
            accepts={"image/*, application/pdf, image/heic"}
            hideLabelDragDrop={true}
            returnRawFiles={true}
            labelText={"JPG, JPEG, PNG, PDF"}
            uploadImage={fileItems => onChangeFile(
              fileItems,
              "resignationLetter",
              "resignationLetterFileName",
              "resignationLetterType"
            )}
          />
          <FormHelperText>
            Accepted file types: jpg, jpeg, png, pdf.( Required )
          </FormHelperText>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            disabled={_.isEmpty(resignationLetter)}
            onClick={() => {
              onClickSubmitResignationLetter();
              this.pond.removeFile();
            }}
          >
            Upload
          </Button>
        </CardActions>
      </Card>
    );
  };

  renderUploadProofContent = () => {
    const { onChangeFile, paymentProof, onClickSubmitPaymentProof } = this.props;
    
    return (
      <Card className={"mt-10"}>
        <CardHeader title={"Reupload proof of payment"} />
        <CardContent>
          <CustomUpload
            multiple={false}
            accepts={"image/*, application/pdf, image/heic"}
            hideLabelDragDrop={true}
            returnRawFiles={true}
            labelText={"JPG, JPEG, PNG, PDF"}
            uploadImage={fileItems => onChangeFile(
              fileItems,
              "paymentProof",
              "paymentProofFileName",
              "paymentProofFileType"
            )}
          />
          <FormHelperText>
            Accepted file types: jpg, jpeg, png, pdf.( Required )
          </FormHelperText>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            disabled={_.isEmpty(paymentProof)}
            onClick={() => {
              onClickSubmitPaymentProof();
              this.pond.removeFile();
            }}
          >
            Upload
          </Button>
        </CardActions>
      </Card>
    );
  };

  renderUploadEmploymentProof = () => {
    const { onChangeFile, employmentProof, onClickSubmitPaymentProof } = this.props;

    return (
      <Card className={"mt-10"}>
        <CardHeader title={"Reupload proof of employment"} />
        <CardContent>
          <CustomUpload
            multiple={false}
            accepts={"image/*, application/pdf, image/heic"}
            hideLabelDragDrop={true}
            returnRawFiles={true}
            labelText={"JPG, JPEG, PNG, PDF"}
            uploadImage={fileItems => onChangeFile(
              fileItems,
              "employmentProof",
              "employmentProofFileName",
              "employmentProofFileType"
            )}
          />
          <FormHelperText>
            Accepted file types: jpg, jpeg, png, pdf.( Required )
          </FormHelperText>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            disabled={_.isEmpty(employmentProof)}
            onClick={() => {
              onClickSubmitPaymentProof();
              this.pond.removeFile();
            }}
          >
            Upload
          </Button>
        </CardActions>
      </Card>
    );
  };

  renderUploadTextContent = () => {
    const { approvalStatusContent } = this.props;
    const { payment_method_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <p>
          You may need to resolve one or more of the following issues before the 
          admin can approve your registration:
        </p>
        <ol type={1}>
          {(approvalStatusContent.has_payment_issue && payment_method_id !== 3) && (
              <li>
                Your proof of payment may be incomplete or missing information.
                Please upload this again.
              </li>
            )}
          {(approvalStatusContent.has_payment_issue && payment_method_id === 3) && (
              <li>
                Your proof of employment may be incomplete or missing
                information. Please upload this again.
              </li>
            )}
          {approvalStatusContent.has_resignation_letter_issue && (
            <li>You may need to upload your resignation letter.</li>
          )}
          {approvalStatusContent.has_other_issue && (
            <li>
              Please refer to "Remark from Admin" for instruction and send the requested 
              document to <a href="mailto:helpdesk@iqiglobal.com" target="_blank">helpdesk@iqiglobal.com</a>
            </li>
          )}
        </ol>
        <p>
          Once you have resolved the issues, our admin will go through your 
          documents once again. Be sure to come back and check your approval status
        </p>
      </>
    );
  };

  renderUploadContent = () => {
    const { tabContentType, approvalStatusContent, onChangeTrainingStatusHOC } =
      this.props;
    const { payment_method_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    return (
      <div className="d-flex flex-column mb-4">
        <AlertBox
          mode={"danger"}
          containerClassName={"mb-3"}
          description={this.renderUploadTextContent()}
        />
        {_.values(rejectReason).some((item) => approvalStatusContent[item.value]) && ( 
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              padding: "10px 0px 10px 0px",
              marginBottom: 10,
            }}
          >
            {approvalStatusContent.has_payment_issue &&
              payment_method_id !== 3 && (
                <button
                  style={{ marginLeft: 10 }}
                  className={`at-btn ${tabContentType === "Payment Proof" ? "at-btn--secondary" : ""}`}
                  onClick={() =>
                    onChangeTrainingStatusHOC("Payment Proof", "tabContentType")
                  }
                >
                  Payment Proof
                </button>
              )}
            {approvalStatusContent.has_payment_issue &&
              payment_method_id === 3 && (
                <button
                  style={{ marginLeft: 10 }}
                  className={`at-btn ${tabContentType === "Proof Of Employment" ? "at-btn--secondary" : ""}`}
                  onClick={() =>
                    onChangeTrainingStatusHOC(
                      "Proof Of Employment",
                      "tabContentType"
                    )
                  }
                >
                  Proof Of Employment
                </button>
              )}
            {approvalStatusContent.has_resignation_letter_issue && (
              <button
                style={{ marginLeft: 10 }}
                className={`at-btn ${tabContentType === "Resignation Letter" ? "at-btn--secondary" : ""}`}
                onClick={() =>
                  onChangeTrainingStatusHOC(
                    "Resignation Letter",
                    "tabContentType"
                  )
                }
              >
                Resignation Letter
              </button>
            )}
          </div>
        )}
        {tabContentType === "Payment Proof" && this.renderUploadProofContent()}
        {tabContentType === "Proof Of Employment" && this.renderUploadEmploymentProof()}
        {tabContentType === "Resignation Letter" && this.renderUploadLetterContent()}
      </div>
    );
  };

  render = () => {
    let { bankDetail, approvalStatusContent, currencyCode } = this.props;

    const { country_id, role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const language = country_id == 14 ? "jp" : "en";

    return (
      <>
        <Dialog
          fullScreen={true}
          classes={{ paper: "dialog_paper", root: "dialog_root" }}
          open={true}
        >
          <div className={"welcome-header text-center mb-4"}>
            <div>
              <img
                src={JuwaiIQILogo}
                alt={"JuwaiLogo"}
                style={{ height: 200 }}
              />
              <span
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  display: "block",
                }}
              >
                {this.props.currentModalSequence === 0 && getTranslation("agent.profile.welcome1", language)}
                {this.props.currentModalSequence !== 0 && getTranslation("agent.profile.welcome2", language)}
              </span>
            </div>
            <div
              style={{
                position: "absolute",
                top: "30px",
                left: "50%",
                marginLeft: "150px",
              }}
            >
              {this.props.currentModalSequence !== 0 && (
                <Tooltip title="Log out" placement="top">
                  <IconButton onClick={() => this.onClickSignOut()}>
                    <FaSignOutAlt style={{ width: 24 }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div
            class="welcome-header"
            style={{ marginTop: 44, marginBottom: 44 }}
          >
            <hr style={{ width: "100%" }} />
            <div className="welcome-stepper-icon-container">
              <div className="welcome-stepper-board">
                <AtlasIcon
                  svgHref={"person-check-fill"}
                  className={"welcome-stepper-atlas-icon"}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "600",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {getTranslation("agent.profile.approval_status", language)}
          </div>

          <div className={"container pt-4"} style={{ maxWidth: 768, whiteSpace: "pre-wrap" }}>
            <p>
              {getTranslation("agent.profile.welcome_message1", language)}
              <br />
              {getTranslation("agent.profile.welcome_message2", language)}
            </p>
            <div className={"container pt-4 px-0"}>
              <Grid container spacing={16}>
                <Grid item md={5} xs={12}>
                  <label
                    className={"at-profile__title"}
                    style={{ fontSize: 16 }}
                  >
                    {getTranslation("agent.profile.admin_approval_status", language)}:{" "}
                  </label>
                </Grid>
                <Grid item md={7} xs={12}>
                  <div
                    className="at-status_badge mt-1"
                    style={{
                      ...getColorBadge(
                        _.find(statusColor, {
                          id: parseInt(approvalStatusContent.admin_check_status)
                        })?.colorName
                      ),
                    }}
                  >
                    {getTranslation(_.find(statusDictionary,
                        {id: parseInt(approvalStatusContent.admin_check_status)}
                      )?.label, language)}
                  </div>
                </Grid>
                <Grid item md={5} xs={12}>
                  <label
                    className={"at-profile__title"}
                    style={{ fontSize: 16 }}
                  >
                    {getTranslation("agent.profile.referrer_approval_status", language)}:{" "}
                  </label>
                </Grid>
                <Grid item md={7} xs={12}>
                  <div
                    className="at-status_badge mt-1"
                    style={{
                      ...getColorBadge(
                        _.find(statusColor, {
                          id: parseInt(approvalStatusContent.leader_check_status),
                        })?.colorName
                      ),
                    }}>
                    {getTranslation(_.find(statusDictionary,
                        {id: parseInt(approvalStatusContent.leader_check_status)}
                      )?.label, language)}
                  </div>
                </Grid>
                {country_id !== 14 && (
                  <>
                    {role !== "Associated Agent" && (
                      <>
                        <Grid item md={5} xs={12}>
                          <label
                            className={"at-profile__title"}
                            style={{ fontSize: 16 }}
                          >
                            Registration Fee:{" "}
                          </label>
                        </Grid>
                        <Grid item md={7} xs={12}>
                          <p>
                            {`${currencyCode || ""} ${approvalStatusContent.registration_fee || "0.00"}`}
                          </p>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <label
                            className={"at-profile__title"}
                            style={{ fontSize: 16 }}
                          >
                            Payment Method:
                          </label>
                        </Grid>
                        <Grid item md={7} xs={12}>
                          <p>{approvalStatusContent.payment_method || "N/A"}</p>
                        </Grid>
                      </>
                    )}
                    <Grid item md={5} xs={12}>
                      <label
                        className={"at-profile__title"}
                        style={{ fontSize: 16 }}
                      >
                        Remark from Admin:
                      </label>
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <p>{approvalStatusContent.admin_remark || "N/A"}</p>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
            {(role !== "Associated Agent" && country_id === 1 && bankDetail) && (
              <AlertBox
                mode={"info"}
                containerStyle={{ marginTop: 20 }}
                title={"Gentle Reminder"}
                description={
                  <>
                    All payments should be made directly to IQI HOLDINGS SDN BHD
                    with the account below.
                    <br />
                    BANK NAME: {bankDetail.bank_name || "N/A"}
                    <br />
                    ACC NO: {bankDetail.bank_account_number || "N/A"}
                  </>
                }
                disabledClose
              />
            )}
            <Typography variant={"body1"} style={{ marginTop: 20 }}>
              {getTranslation("agent.profile.footer_message1", language)}
              <br />
              {getTranslation("agent.profile.footer_message2", language)}
            </Typography>
            <DialogActions
              className={"g-3 mt-4 mb-4"}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className={"d-flex align-items-center g-3"}>
                <AtlasButton
                  className={"btn-new btn-new--primary"}
                  onClick={() => {
                    this.onClickGetStatus();
                    this.props.getApprovalStatus();
                  }}
                >
                  {getTranslation("agent.profile.check_approval_status", language)}
                </AtlasButton>
              </div>
            </DialogActions>
            {this.props.approvalStatusContent.admin_check_status === 3 &&
              this.renderUploadContent()}
            {this.props.onLoadTrainingStatus && (
              <LoadingModal showLoadingOverlay={true} />
            )}
          </div>
        </Dialog>
      </>
    );
  };
}

export default compose(TrainingStatusModalHOC, WithLogout)(TrainingStatusModal);
