import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { MenuItem, Collapse, IconButton, Tooltip } from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FiCheckCircle, FiAlertCircle } from "react-icons/fi";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdRemoveCircle,
} from "react-icons/md";
import {
  FaDollarSign,
  FaBath,
  FaBed,
  FaRulerCombined,
  FaUserPlus,
} from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";

import CustomButton from "components/Button";
import AtSelectNew from "components/Select/new";
import CustomCheckbox from "components/Checkbox";
import CustomFormInput from "components/Input/formInput";
import ProgressBar from "./progressBar";

import { numberWithCommas } from "utils/thousandSeparator";
import { BuyerDetail, PaymentSlip, RepaymentDetail } from "../assets";

const BuyerInfo = ({
  data,
  selectedUnit,
  selectedProject,
  selectedReservation,

  createReservation,
  getProjectUnits,
  getReservation,
  onClose,
}) => {
  const { id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const filePond = useRef([]);
  const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [buyerField, setBuyerField] = useState(BuyerDetail);
  const [repaymentField, setRepaymentField] = useState(RepaymentDetail);
  const [country, setCountry] = useState(1);
  const [readInstruction, setReadInstruction] = useState(false);
  const [reservation, setReservation] = useState({
    buyer_full_name: "",
    email: "",
    identity_type: "MyKad",
    ic_number: "",
    contact_no: "",
    nationality: "",
    address: "",
    gender: "",
    postcode: "",
    state_id: "",
    country_id: "",
    ic_front: "",
    ic_back: "",
    buyers_attributes: [],
    onShow: true,
    agent_id: id,
  });
  const [timeLeft, setTimeLeft] = useState(0);
  const [initialTime, setInitialTime] = useState(0);

  useEffect(() => {
    let temp = _.cloneDeep(buyerField);
    let countryIndex = _.findIndex(temp, (item) => {
      return item.value === "country_id";
    });
    temp[countryIndex].options = data.dictionaryReducer.countries;
    setBuyerField(temp);

    if (
      selectedProject.payment_settings &&
      selectedProject.payment_settings.settings.finance_by_developer
    ) {
      let repayment = _.cloneDeep(RepaymentDetail);
      let currencyIndex = _.findIndex(repayment, (item) => {
        return item.value === "repayment_currency";
      });
      repayment[currencyIndex].options = data.dictionaryReducer.currencies.name;
      setRepaymentField(repayment);
    }

    if (selectedUnit.reservation_status === "Reserved") {
      getReservation(selectedUnit);
    }
  }, []);

  useEffect(() => {
    if (
      selectedProject.payment_settings &&
      selectedProject.payment_settings.settings.finance_by_developer
    ) {
      setReservation({
        ...reservation,
        total_payment: 0,
        interest_rate: 0,
        down_payment_percentage: "",
        repayment_currency: "",
        monthly_installment: "",
        repayment_term: 1,
      });
    }

    if (
      !selectedProject.reservation_time_limit ||
      !selectedProject.reservation_time_limit.settings.has_time_limit
    ) {
      setReservation({
        ...reservation,
        payment_slip: reservation.payment_slip ? reservation.payment_slip : "",
      });
    }
  }, [buyerField]);

  useEffect(() => {
    if (
      selectedProject.reservation_time_limit &&
      selectedProject.reservation_time_limit.settings.has_time_limit
    ) {
      let timeLimit = calculateTimeLimit(
        selectedProject.reservation_time_limit.settings.buyer_info,
      );
      let reserveTime = new Date(
        selectedUnit.reservations_attributes
          ? selectedUnit.reservations_attributes.reservation_time
          : selectedUnit.reservation_date,
      );
      let difference = reserveTime - +new Date();
      difference = timeLimit + difference;
      setInitialTime(timeLimit);
      if (difference > 0) setTimeLeft(difference);
      else onClose();
    }
  }, [selectedUnit]);

  useEffect(() => {
    setReservation({
      ...reservation,
      payment_slip: selectedReservation.payment_slip_url,
    });
  }, [selectedReservation]);

  useEffect(() => {
    if (reservation.country_id) {
      let temp = _.cloneDeep(buyerField);
      let stateIndex = _.findIndex(temp, (item) => {
        return item.value === "state_id";
      });
      let countrySelected = _.findIndex(
        data.dictionaryReducer.countries,
        (item) => {
          return item.value === reservation.country_id;
        },
      );
      temp[stateIndex].options =
        data.dictionaryReducer.countries[countrySelected].states;
      setBuyerField(temp);
    }
  }, [reservation.country_id, country]);

  useEffect(() => {
    let downPayment =
      selectedUnit.price * (reservation.down_payment_percentage / 100);
    let totalPayment = selectedUnit.price - downPayment;
    let paymentYear = reservation.repayment_term * 12;
    let interest = reservation.interest_rate / 100 / 12;
    let x = Math.pow(1 + interest, paymentYear);
    let monthly = (totalPayment * x * interest) / (x - 1);
    setReservation({
      ...reservation,
      monthly_installment: monthly ? numberWithCommas(monthly.toFixed(2)) : "0",
      total_payment:
        monthly && paymentYear
          ? numberWithCommas((monthly * paymentYear).toFixed(2))
          : "0",
    });
  }, [
    reservation.repayment_term,
    reservation.down_payment_percentage,
    reservation.interest_rate,
  ]);

  const formatRemainingTime = (time) => {
    const hours =
      Math.floor((time / 3600) % 24) < 10
        ? `0${Math.floor((time / 3600) % 60)}`
        : Math.floor((time / 3600) % 24);
    const minutes =
      Math.floor((time / 60) % 60) < 10
        ? `0${Math.floor((time / 60) % 60)}`
        : Math.floor((time / 60) % 60);
    const seconds =
      Math.floor(time % 60) < 10
        ? `0${Math.floor(time % 60)}`
        : Math.floor(time % 60);

    return `${hours}:${minutes}:${seconds}`;
  };

  const calculateTimeLimit = (setting) => {
    let multiplier = 0;
    switch (setting.unit) {
      case "seconds":
        multiplier = 1000;
        break;
      case "minutes":
        multiplier = 60000;
        break;
      case "hours":
        multiplier = 3600000;
        break;
    }
    return Number(setting.time) * multiplier;
  };

  const onChangeFields = (context, val) => {
    if (context === "email") {
      let temp = _.cloneDeep(buyerField);
      let emailIndex = _.findIndex(temp, (item) => {
        return item.value === "email";
      });
      if (emailRegEx.test(val)) {
        temp[emailIndex].error = null;
        setBuyerField(temp);
      } else {
        temp[emailIndex].error = "Invalid email address.";
        setBuyerField(temp);
      }
    }
    let tmp = _.cloneDeep(reservation);
    tmp[context] = val;
    setReservation(tmp);
  };

  const onUploadDocs = (e, val, onChangeFields) => {
    if (e.target.files && e.target.files.length > 0) {
      let files = e.target.files;
      Object.keys(e.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = {
            snapshot: e.target.result,
            snapshot_file_name: files[key].name,
          };
          onChangeFields(val, tmp);
        };
        reader.readAsDataURL(e.target.files[key]);
      });
    }
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Time Out</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining time</div>
        <div className="value">{formatRemainingTime(remainingTime)}</div>
      </div>
    );
  };

  const renderInputContent = (target, field, onChangeFields) => {
    return (
      <div className="row px-2">
        {field.map((item, index) =>
          typeof item === "string" ? (
            <div style={{ width: "100%", padding: "0px 10px", marginTop: 10 }}>
              <h5 style={{ fontWeight: 500 }}>{item}</h5>
              <hr />
            </div>
          ) : (
            <div className={item.className}>
              {(item.type === "text" || item.type === "number") && (
                <>
                  <h2 className="at-project-unit__reservation_title">
                    {item.label}
                  </h2>
                  <CustomFormInput
                    type={item.type}
                    value={target[item.value] ? target[item.value] : ""}
                    numericOnly={item.numericOnly}
                    disabled={item.disabled}
                    maxLength={item.maxLength}
                    onChangeValue={(val) => onChangeFields(item.value, val)}
                    required={true}
                    inputError={item.error}
                    placeholder={item.placeholder}
                  />
                </>
              )}
              {item.type === "select" && (
                <>
                  {item.label && (
                    <h2 className="at-project-unit__reservation_title mb-2">
                      {item.label}
                    </h2>
                  )}
                  <AtSelectNew
                    required={true}
                    value={target[item.value]}
                    placeholder={item.placeholder ? item.placeholder : ""}
                    onChange={(e) => onChangeFields(item.value, e.target.value)}
                  >
                    {item.options &&
                      item.options.map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{ width: "90%", fontSize: 14 }}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                  </AtSelectNew>
                </>
              )}
              {item.type === "doc" && (
                <>
                  {item.required && _.isEmpty(target[item.value]) && (
                    <>
                      <div className="d-flex">
                        <h2 className="at-project-unit__reservation_title mb-2">
                          {item.label}
                        </h2>
                        <Tooltip placement={"top"} title={"Required"}>
                          <FiAlertCircle
                            style={{
                              width: 18,
                              height: 18,
                              color: "red",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </div>
                      <button
                        className="btn-new btn-new--secondary w-100"
                        onClick={() => filePond.current[index].click()}
                      >
                        Add File
                      </button>
                      <div style={{ display: "none" }}>
                        <input
                          ref={(ref) => (filePond.current[index] = ref)}
                          type="file"
                          accept="image/jpeg, image/jpg, image/png, application/pdf"
                          multiple={true}
                          onChange={(e) =>
                            onUploadDocs(e, item.value, onChangeFields)
                          }
                        />
                      </div>
                    </>
                  )}
                  {!_.isEmpty(target[item.value]) && (
                    <>
                      <div className="d-flex">
                        <h2 className="at-project-unit__reservation_title mb-2">
                          {item.label}
                        </h2>
                        <FiCheckCircle
                          style={{
                            width: 18,
                            height: 18,
                            color: "green",
                            marginLeft: 5,
                          }}
                        />
                      </div>
                      <button
                        className="btn d-flex align-items-center px-0"
                        onClick={() => {
                          if (typeof target[item.value] === "object")
                            window.open(target[item.value].snapshot);
                          else window.open(target[item.value]);
                        }}
                      >
                        <IoMdDocument
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <p>
                          {typeof target[item.value] === "object"
                            ? target[item.value].snapshot_file_name
                            : item.value}
                        </p>
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          ),
        )}
      </div>
    );
  };

  const renderBuyerSection = () => {
    const onClickAddBuyer = () => {
      let temp = _.cloneDeep(reservation);
      temp.onShow = false;
      temp.buyers_attributes.push({
        buyer_full_name: "",
        email: "",
        identity_type: "MyKad",
        ic_number: "",
        contact_no: "",
        nationality: "",
        address: "",
        gender: "",
        postcode: "",
        state_id: "",
        country_id: "",
        ic_front: "",
        ic_back: "",
        onShow: false,
      });
      setReservation(temp);
    };

    const onClickRemoveBuyer = (index) => {
      let temp = _.cloneDeep(reservation);
      temp.buyers_attributes.splice(index, 1);
      setReservation(temp);
    };

    const onClickShowBuyer = (index) => {
      let temp = _.cloneDeep(reservation);
      if (index >= 0)
        temp.buyers_attributes[index].onShow =
          !temp.buyers_attributes[index].onShow;
      else temp.onShow = !temp.onShow;
      setReservation(temp);
    };

    const renderBuyerContent = (buyer, onChange, index) => (
      <div className="at-form__content">
        <div className="d-flex w-100">
          <button
            className={"btn my-3 w-100 p-0 text-left"}
            onClick={() => onClickShowBuyer(index)}
          >
            <h5 style={{ fontSize: 18, fontWeight: 500 }}>
              {index >= 0 ? `Buyer ${index + 2}` : "Main Buyer"}
              <IconButton
                style={{ color: "#f18e06", padding: 5, marginLeft: 10 }}
              >
                {buyer.onShow ? (
                  <MdKeyboardArrowUp size={"16px"} />
                ) : (
                  <MdKeyboardArrowDown size={"16px"} />
                )}
              </IconButton>
            </h5>
          </button>
          {index >= 0 && (
            <IconButton
              className="at-storey-plan__remove-user-button"
              onClick={() => onClickRemoveBuyer(index)}
            >
              <MdRemoveCircle />
            </IconButton>
          )}
        </div>
        <Collapse in={buyer.onShow} timeout="auto" unmountOnExit>
          {renderInputContent(buyer, buyerField, onChange)}
        </Collapse>
      </div>
    );

    return (
      <div>
        <div style={{ width: "100%", padding: "0px 10px", display: "flex" }}>
          <h4 style={{ fontWeight: 500 }}>Buyer Detail</h4>
          <button
            type={"button"}
            className={"btn btn-outline-info at-storey-plan__add-user-button"}
            onClick={() => onClickAddBuyer()}
          >
            <FaUserPlus />
            Add Buyer
          </button>
        </div>
        <hr />
        {renderBuyerContent(reservation, onChangeFields)}
        {reservation.buyers_attributes.length > 0 &&
          reservation.buyers_attributes.map((buyer, index) => {
            const onChangeBuyerDetail = (context, val) => {
              let temp = _.cloneDeep(reservation);
              if (context === "country_id") setCountry(val);
              temp.buyers_attributes[index][context] = val;
              setReservation(temp);
            };

            return renderBuyerContent(buyer, onChangeBuyerDetail, index);
          })}
      </div>
    );
  };

  const renderInputFooter = () => {
    const checkBuyerAttributes = () => {
      if (reservation.buyers_attributes.length > 0) {
        for (let i = 0; i < reservation.buyers_attributes.length; i++) {
          let buyer = reservation.buyers_attributes[i];
          if (_.values(buyer).some((x) => x === "")) return true;
        }
      } else {
        return false;
      }
    };

    const onClickReservation = () => {
      let temp = _.cloneDeep(reservation);
      if (reservation.buyers_attributes.length > 0) {
        for (let i = 0; i < reservation.buyers_attributes.length; i++) {
          let buyer = reservation.buyers_attributes[i];
          temp.buyers_attributes[i] = {
            ...buyer,
            ic_front: buyer.ic_front.snapshot,
            ic_front_file_name: buyer.ic_front.snapshot_file_name,
            ic_back: buyer.ic_back.snapshot,
            ic_back_file_name: buyer.ic_back.snapshot_file_name,
          };
        }
      }
      if (selectedUnit.reservations_attributes.has_submitted_payment) {
        delete temp.payment_slip;
      }
      createReservation(temp, selectedUnit);
    };

    return (
      <div className={"d-flex pb-3 g-3"}>
        <CustomButton
          disabled={
            _.values(reservation).some((x) => x === "") ||
            !readInstruction ||
            !emailRegEx.test(reservation.email) ||
            checkBuyerAttributes()
          }
          className={"btn-new btn-new--success"}
          children={"Book"}
          onClick={() => onClickReservation()}
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: 1000, margin: "auto", maxHeight: "80vh" }}>
      <div className="my-4">
        <h3 className="at-card__title text-center mb-3 mr-0">{`Reservation of Unit ${selectedUnit.unit_no}`}</h3>
        <ProgressBar
          progress={"Buyer"}
          has_time_limit={
            selectedProject.reservation_time_limit &&
            selectedProject.reservation_time_limit.settings.has_time_limit
          }
        />
      </div>
      <div className="d-flex flex-wrap">
        <div className="d-flex flex-wrap justify-content-center">
          <img
            style={{ height: 200 }}
            src={selectedUnit.image_url}
            alt={selectedUnit.title}
          />
          <div className="at-project-unit__reservation_unit">
            <p style={{ fontSize: 18, fontWeight: 500 }}>
              {selectedUnit.title}
            </p>
            <p>
              <FaDollarSign /> {selectedUnit.price} (
              {selectedProject.net_price_currency})
            </p>
            <p>
              <FaBath /> {selectedUnit.bathroom}
            </p>
            <p>
              <FaBed /> {selectedUnit.bedroom}
            </p>
            <p>
              <FaRulerCombined />{" "}
              {selectedUnit.floor_size + selectedUnit.floor_size_unit}
            </p>
          </div>
        </div>
        {timeLeft > 0 && (
          <div className={"at-storey-plan__timer-container"}>
            <CountdownCircleTimer
              isPlaying
              size={140}
              strokeWidth={8}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              isLinearGradient={true}
              duration={initialTime / 1000}
              initialRemainingTime={timeLeft / 1000}
              colorsTime={[
                initialTime / 1000,
                initialTime / 1000 / 2,
                initialTime / 1000 / 3,
                0,
              ]}
              onComplete={() => {
                if (getProjectUnits) {
                  getProjectUnits();
                }
                onClose();
              }}
              trailColor={[["#dbdbdb"]]}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
        )}
      </div>
      {renderBuyerSection()}
      {selectedProject.payment_settings &&
        selectedProject.payment_settings.settings.finance_by_developer && (
          <>
            <h4 style={{ fontWeight: 500, marginTop: 10 }}>Repayment Detail</h4>
            <hr />
            {renderInputContent(reservation, repaymentField, onChangeFields)}
          </>
        )}
      <h4 style={{ fontWeight: 500, marginTop: 10 }}>Payment Detail</h4>
      <hr />
      {renderInputContent(reservation, PaymentSlip, onChangeFields)}
      <CustomCheckbox
        checked={readInstruction}
        onChangeCheckboxValue={() => setReadInstruction(!readInstruction)}
        content={
          "I confirm that all the information is correct to the best of my knowledge and that it matches the identity/passport details exactly."
        }
      />
      {renderInputFooter()}
    </div>
  );
};

export default BuyerInfo;
