import { GET_ALL_BRANCHES, GET_INVOICING_BRANCHES } from "actions/type";

let initialState = {
  branch: [],
  invoicingBranch: [],
};

export default function branchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BRANCHES:
      return {
        ...state,
        branch: action.payload.map((item) => {
          let data = {
            ...item,
            value: item.id,
            label: item.name,
          };
          return data;
        }),
      };
    case GET_INVOICING_BRANCHES:
      return {
        ...state,
        invoicingBranch: action.payload.map((item) => {
          let data = {
            ...item,
            value: item.id,
            label: item.name,
          };
          return data;
        }),
      };
    default:
      return state;
  }
}
