import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import SVGIcon from "components/Icon";
import AtlasButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomImageUploader from "components/Input/imageUpload";

import { status } from "../../assets";

const FieldData = (context, categories) => {
  const data = [
    {
      label: "Template Name",
      value: "name",
      type: "text",
      placeholder: "Template Name",
    },
    {
      label: "Subject Line",
      value: "subject",
      type: "text",
      placeholder: "Email Subject",
    },
    {
      label: "Categories",
      value: "categories",
      type: "typeahead",
      placeholder: "Categories",
      options: categories,
    },
    {
      label: "Attachment",
      value: "attachment",
      type: "file",
    },
  ];

  if (context === "edit") {
    data.push({
      label: "Status",
      value: "status_id",
      type: "radio",
      options: status,
    });
  }

  return data;
};

const Form = ({ values, categories, attachment, context, handleChange }) => {

  const onChangeFile = (context, fileItems) => {
    let temp = {
      source: "",
      name: "",
      size: "",
    };

    if (fileItems.length > 0 && fileItems[0].file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          temp = {
            source: e.target.result,
            name: fileItems[0].file.name,
            size: fileItems[0].file.size,
          };
          handleChange(context, temp)
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div className={"grid-control"}>
      {FieldData(context, categories).map((item) => (
        <section
          key={item.value}
          className={`mb-sm-0 grid-full-col`}
        >
          <div className={"d-flex align-items-center"}>
            <h2
              className={"at-form-input__title text-capitalize"}
              required={item.type === "text"}
            >
              {item.label}
            </h2>
          </div>

          {item.type === "text" && (
            <CustomFormInput
              type={"text"}
              value={values[item.value]}
              required={true}
              onChangeValue={(val) => handleChange(item.value, val)}
              placeholder={item.placeholder}
            />
          )}

          {item.type === "typeahead" && (
            <Typeahead
              id={`${context}-email-template__categories`}
              className={"at-email-templates__typeahead"}
              multiple={true}
              placeholder={item.placeholder}
              options={item.options}
              selected={values[item.value] || []}
              onChange={(val) =>
                handleChange(item.value, val && val.length > 0 ? val : "")
              }
              renderMenuItemChildren={(option) => <p>{option}</p>}
              allowNew={true}
              newSelectionPrefix={"Add a new category: "}
            />
          )}

          {item.type === "file" && (
            <>
              {attachment?.url && (
                <AtlasButton
                  type={"button"}
                  className={"at-table-icon-button"}
                  tooltip={true}
                  tooltipID={"view-file"}
                  tooltipPosition={"top"}
                  tooltipChildren={"View file"}
                  onClick={() => {
                    const a = document.createElement("a");
                    a.href = attachment.url;
                    a.target = "_blank";
                    a.click();
                  }}
                >
                  <SVGIcon
                    className={"svg-icon-info"}
                    svgHref={"icon-info"}
                  />
                </AtlasButton>
              )}
              {!attachment?.url && (
                <CustomImageUploader
                  fullWidth
                  required={true}
                  files={attachment
                    ? [attachment]
                    : []}
                  allowMultiple={false}
                  maxFileSize={4000000}
                  allowImageValidateSize={true}
                  acceptedFileTypes={['image/gif', 'image/jpeg', 'image/jpg', 'image/png']}
                  accept="image/*"
                  maxFiles={1}
                  onChangeFile={(fileItems) => onChangeFile(item.value, fileItems)}
                  onRemoveFile={() => handleChange(item.value, null)} />
              )}
            </>
          )}

          {item.type === "radio" && (
            <AtlasRadioGroup
              valueKey={"value"}
              horizontal={true}
              checkedValue={values[item.value]}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => handleChange(item.value, val)}
              options={item.options}
            />
          )}
        </section>
      ))}
    </div>
  );
};

export default Form;
