import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { Dialog, DialogContent } from "@material-ui/core";
import Lightbox from "react-awesome-lightbox";

import AtlasDialog from "components/Dialog";
import CustomTab from "components/Tab";
import CrossButton from "components/Button/close";
import LoadingModal from "components/LoadingModal";

import ProjectUnits from "./containers/units";
import ProjectDocs from "./containers/documents";
import ProjectOverview from "./containers/overview";
import ProjectDetailsFooter from "./containers/footer";
import ProjectDetailNFeatures from "./containers/detailsFeatures";
import ProjectPartnerContent from "../../CobrokeCenter/components/ProjectPartnerContent";

import { storeIQIDriveData } from "actions/iqidrive";
import permissionsChecker from "utils/permissionsChecker";

import "../projects.scss";
import "./viewer.css";

const script = `<script src="https://unpkg.com/s3bubble-javascript/s3bubble.min.js"></script>`;

const link = `<link rel="stylesheet" href="/scss/style.css"></link>
  <link rel="stylesheet" href="/scss/font-awesome.css"></link>
  <link rel="stylesheet" href="/scss/components/common/index.css"></link>
  <link rel="stylesheet" href="/scss/components/pages/index.css"></link>
  <link rel="stylesheet" href="/scss/components/sections/index.css"></link>
  <link rel="stylesheet" href="/scss/index.css"></link>

  <!--Preload-->
  <link rel="preload" href="/assets/fonts/fontawesome-webfont.woff2?v=4.7.0" as="font" crossorigin>
  <link rel="preload" href="/assets/fonts/opal-icon.woff2" as="font" crossorigin>

  <!--Google font-->
  <link href="https://fonts.googleapis.com/" rel="preconnect" crossorigin>
  <link href="https://fonts.googleapis.com/css?family=Marcellus+SC%7CMontserrat:300,400,500,600,700&display=swap" rel="stylesheet">

  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.12.0/css/all.css">
  <link rel="stylesheet" href="https://unpkg.com/s3bubble-javascript/s3bubble.min.css" ' />`;

class Details extends Component {
  state = {
    dataCode: "",
    openVideo: false,
    openImage: false,
    imageEnlarge: "",
    view: "Overview",
    navigationButton: [
      "Overview",
      "Details & Features",
      "Project Resources",
      "Find a Project Partner",
    ],
  };

  componentDidMount = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { can_read } = permissionsChecker(
      "Projects - Reservations",
      this.props.data
    );
    const { can_read: cobrokeRead } = permissionsChecker(
      "Co Broke Center",
      this.props.data
    );
    let temp = _.cloneDeep(this.state.navigationButton);

    if (
      this.props.hideProjectPartner ||
      !cobrokeRead ||
      ["Super Admin", "Admin"].includes(role)
    ) {
      temp = temp.filter((item) => item !== "Find a Project Partner");
    }

    if (
      this.props.selectedProject.total_units > 0 &&
      this.props.selectedProject.has_live_sales &&
      !this.state.navigationButton.includes("Units") &&
      can_read
    ) {
      temp.push("Units");
    }

    this.setState({ navigationButton: temp });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.documents !== this.props.documents &&
      this.props.documents?.length === 0
    ) {
      this.setState({
        navigationButton: this.state.navigationButton.filter(
          (item) => item !== "Project Resources"
        ),
      });
    }
  };

  toggleVideo = (code) =>
    this.setState((prevState) => ({
      openVideo: !prevState.openVideo,
      dataCode: code ? code : "",
    }));

  renderFrameHeight = () => {
    let iframe = document.getElementById("project-frame");
    iframe.height = iframe.contentWindow.document.body.scrollHeight + 100;
  };

  renderVideoDialog = () => (
    <Dialog
      classes={{ paper: "at-project-video-paper" }}
      style={{ padding: 20 }}
      open={this.state.openVideo}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <CrossButton
        containerClass={"at-close-video__button"}
        containerStyle={{ position: "absolute", right: 0 }}
        onClick={() => this.setState({ openVideo: false })}
      />
      <DialogContent
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Frame
          className={`frame-container at-video__frame__container`}
          initialContent={`<!DOCTYPE html><html style="background-color: black" class="at-project-video"><head>${link}</head><body><div id="video-container"></div>${'<script src="https://unpkg.com/s3bubble-javascript/s3bubble.min.js"></script>'}</body></html>`}
          mountTarget={"#video-container"}
        >
          <FrameContextConsumer>
            {
              // Callback is invoked with iframe's window and document instances
              ({ document, window }) => {
                return (
                  <div
                    className="s3bubble at-project-video"
                    data-code={this.state.dataCode}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                );
              }
            }
          </FrameContextConsumer>
        </Frame>
      </DialogContent>
    </Dialog>
  );

  renderImageDialog = () => (
    <AtlasDialog open={this.state.openImage}>
      <Lightbox
        image={this.state.imageEnlarge}
        title={"Project Unit Image"}
        buttonAlign={"center"}
        onClose={() => {
          this.setState({ openImage: false });
        }}
      />
    </AtlasDialog>
  );

  render = () => {
    const { selectedProject } = this.props;

    return (
      <div className={`detail-content-block`}>
        <div className="at-project__detail-title">
          <h4>{selectedProject.internal_name}</h4>
          <p>{selectedProject.location}</p>
        </div>
        <CustomTab
          sections={this.state.navigationButton}
          containerClass={"at-project-details__tab"}
          selectedSection={this.state.view}
          onSelectSection={(view) => this.setState({ view })}
        />
        {(this.state.view === "Overview" ||
          this.state.view === "Details & Features") && (
          <Frame
            className={`frame-container`}
            id={"project-frame"}
            contentDidMount={this.renderFrameHeight}
            contentDidUpdate={this.renderFrameHeight}
            style={{ width: "100%", border: "none" }}
            initialContent={`<!DOCTYPE html><html><head>${link}</head><body><div id="project-container"></div>${script}</body></html>`}
            mountTarget={"#project-container"}
          >
            <FrameContextConsumer>
              {
                // Callback is invoked with iframe's window and document instances
                () => {
                  return (
                    <>
                      <div className="project-detail-container">
                        {this.state.view === "Overview" && (
                          <ProjectOverview
                            selectedProject={this.props.selectedProject}
                          />
                        )}
                        {this.state.view === "Details & Features" && (
                          <ProjectDetailNFeatures
                            videos={this.props.videos}
                            visualisations={this.props.visualisations}
                            selectedProject={this.props.selectedProject}
                          />
                        )}
                      </div>
                      <ProjectDetailsFooter />
                    </>
                  );
                }
              }
            </FrameContextConsumer>
          </Frame>
        )}
        {this.state.view === "Project Resources" && (
          <ProjectDocs
            documents={this.props.documents}
            onDownloadFile={this.props.onDownloadFile}
            storeIQIDriveData={this.props.storeIQIDriveData}
          />
        )}
        {this.state.view === "Find a Project Partner" && (
          <div className="at-project-details__frame-cont">
            <ProjectPartnerContent
              selectedProject={this.props.selectedProject}
            />
          </div>
        )}
        {this.state.view === "Units" && (
          <div className="at-project-details__frame-cont">
            <ProjectUnits selectedProject={selectedProject} />
          </div>
        )}
        {this.props.onLoadProject && <LoadingModal />}
        {this.renderVideoDialog()}
        {this.renderImageDialog()}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, {
  storeIQIDriveData,
})(Details);
