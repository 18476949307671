import React from "react";

import juwaiLogo from "assets/images/juwai_logo_only.png";

const JuwaiSubs = ({ agent_juwai_account, getLocalised }) => {
  return (
    <section className="at-form__content">
      <div className="at-subsales-listing__view-title mb-20">
        <img
          className="at-subsales-listing__view-title-img"
          src={juwaiLogo}
          alt="Juwai Asia"
          style={{ marginRight: 10 }}
        />
        {getLocalised("label.juwai_subscription.your_juwai_account_details.title", "Your Juwai Account Details")}
      </div>
      <hr />
      {!agent_juwai_account && (
        <>
          <p className={"mb-10"}>
            {getLocalised(
              "label.juwai_subscription.your_juwai_account_details.message_1", 
              `You have not yet subscribed to any of the Juwai packages. 
              Therefore your listings will be rejected when exporting them to the Juwai website`
            )}
          </p>
          <p className={"mb-10"}>
            {getLocalised(
              "label.juwai_subscription.your_juwai_account_details.message_2", 
              `If you have recently subscribed to a Juwai package, 
              please take note that it will take 24 hours for Atlas to import your Juwai account here.`
            )}
          </p>
        </>
      )}
      {agent_juwai_account && (
        <>
          <p 
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.status", "Status")}: ${agent_juwai_account.status}`}</p>
          <p
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.package", "Package")}: ${agent_juwai_account.package_name}`}</p>
          <p
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.active_total_quota", "Active total quota")}: ${agent_juwai_account.active_total_quota}`}</p>
          <p
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.total_listings", "Total listings")}: ${agent_juwai_account.total_listings}`}</p>
          <p
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.total_published_listing", "Total published listing")}: ${agent_juwai_account.total_published_listings}`}</p>
          <p
            className={"mb-10"}
          >{`${getLocalised("label.juwai_subscription.your_juwai_account_details.exp_date", "Account expiration date")}: ${agent_juwai_account.formated_expiration_date}`}</p>
        </>
      )}
    </section>
  );
};

export default JuwaiSubs;
