import React, { Component } from "react";
import _ from "lodash";

import CustomCard from "components/Card";
import CustomSelect from "components/Select";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomTable from "components/NewPaginatedTable";

import QRDoorEntryHOC from "./actions";
import { columnData, radiusOptions } from "./assets";

class QRDoorEntry extends Component {
  componentDidMount = () => {
    const { role, branch_id } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role === "Super Admin") {
      this.props.getRadiusSetting();
    } else if (role === "Admin" && branch_id) {
      Promise.all([
        this.props.onChangeQREntryHOC(branch_id, "selectedBranch"),
      ]).then(() => {
        this.props.getRadiusSetting();
      });
    }
  };

  onChangeRadius = (val) => {
    let temp = _.cloneDeep(this.props.qrSetting);
    temp.settings.radius = val.value;
    this.props.onChangeQREntryHOC(temp, "qrSetting");
  };

  renderCheckBoxActions = () => (
    <div className='at-table__head-title'>
      <h4>Audit Logs</h4>
    </div>
  );

  renderCardContent = () => {
    const { role, branch_id } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <div
          className="at-form__content d-flex flex-column"
          style={{ gap: 16 }}
        >
          <h5 style={{ fontWeight: 600, fontSize: 18, color: "#111827" }}>
            Set Radius for QR Based Door Entry
          </h5>
          {(role === "Super Admin" || (role === "Admin" && branch_id)) && (
            <>
              <div className="row">
                {role === "Super Admin" && (
                  <div className="col-md-6 mb-0">
                    <h2 className="at-form-input__title">Branch</h2>
                    <CustomSelect
                      selectItems={this.props.data.branchReducer.branch}
                      valueKey={"id"}
                      placeholder={"Select Branch"}
                      updateSelect={(val) => {
                        Promise.all([
                          this.props.onChangeQREntryHOC(
                            val.id,
                            "selectedBranch",
                          ),
                        ]).then(() => {
                          this.props.getRadiusSetting();
                        });
                      }}
                    />
                  </div>
                )}
                <div className="col-md-6 mb-0">
                  <h2 className="at-form-input__title">Radius(KM)</h2>
                  <CustomSelect
                    selectItems={radiusOptions}
                    currentlySelected={_.find(
                      radiusOptions,
                      (radius) =>
                        radius.value === this.props.qrSetting.settings.radius,
                    )}
                    valueKey={"value"}
                    updateSelect={(val) => this.onChangeRadius(val)}
                  />
                </div>
              </div>
              <button
                className="btn-new btn-new--success"
                style={{ width: "fit-content" }}
                onClick={() => this.props.updateRadiusSetting()}
              >
                Update
              </button>
            </>
          )}
          {!(role === "Super Admin" || (role === "Admin" && branch_id)) && (
            <p>You do not have any branch.</p>
          )}
        </div>
        {(role === "Super Admin" || (role === "Admin" && branch_id)) && (
          <CustomTable
            className={"mt-3"}
            columns={columnData}
            showCloseButton={false}
            rowData={this.props.auditLogs.data}
            meta={this.props.auditLogs.meta}
            searchParams={[]}
            getListAPI={this.props.getAuditLogs}
            totalPages={this.props.auditLogsPage}
            renderCheckBoxActions={this.renderCheckBoxActions}
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader title={`QR Based Door Entry`} moduleIcon={"icon-qr"} />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadQR && <LoadingModal />}
      </>
    );
  };
}

export default QRDoorEntryHOC(QRDoorEntry);
