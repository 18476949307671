import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Collapse } from "@material-ui/core";
import { ScaleLoader } from "react-spinners";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";

import { getTranslation } from "assets/translation";
import { numberWithCommas } from "utils/thousandSeparator";
import GenerateDescriptionHOC from "../actions/generateDescription";
import {
  emojiOptions,
  audienceOptions,
  platformOptions,
  styleOptions,
  templateOptions,
} from "../assets";

const GenerateDescriptionModal = ({
  type_id,
  address,
  language,
  landArea,
  agentInfo,
  askingPrice,
  selectedBedroom,
  selectedBathroom,
  selectedCurrency,
  selectedMeasurement,
  selectedBedroomOption,
  selectedBathroomOption,

  getLocalised,
  generateDescription,
  onLoadGenerateDescription,

  onChangeValue,
  onChangeHOC,
  aiGenerateDescription,
}) => {
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const [listingFilter, setListingFilter] = useState({
    target_audience: "general",
    target_platform: "general",
    style: "casual",
    country: "my",
    template: "template_1",
    language: language.toLowerCase(),
    emojis: false,
  });
  const [showListingFilter, setShowListyingFilter] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const filterOptions = [
    {
      value: "target_audience",
      label: getLocalised("label.details.ai_description.target_audience", "Target Audience"),
      options: audienceOptions,
    },
    {
      value: "target_platform",
      label: getLocalised("label.details.ai_description.target_platform", "Target Platform"),
      options: platformOptions,
    },
    { value: "style", label: getLocalised("label.details.ai_description.style", "Style"), options: styleOptions },
    { value: "emojis", label: getLocalised("label.details.ai_description.include_emoji", "Include Emoji"), options: emojiOptions },
  ];
  
  useEffect(() => {
    if (generateDescription) {
      setShowListyingFilter(false);
    }
  }, [generateDescription]);

  const onToggleGenerate = () => {
    let temp = {
      sale_rent: type_id === 1 ? "sale" : "rent",
      address: address,
      price: askingPrice,
      floor_size: landArea,
      agent: {
        name: agentInfo?.full_name ?? "",
        ren: agentInfo?.ren_no ?? "",
        phone_number: agentInfo?.mobile_contact_number ?? "",
      },
      bedrooms: (selectedBedroom || selectedBedroomOption)?.name ?? "0",
      bathrooms: (selectedBathroom || selectedBathroomOption)?.name ?? "0",
      listing_filter: listingFilter,
    };

    aiGenerateDescription(temp);
  };

  const scrollToRight = () => {
    let element = document.querySelector(
      ".at-listing__generated_template-cont",
    );
    element.scrollTo({
      left: element.scrollWidth,
      behavior: "smooth",
    });
  };

  const scrollToLeft = () => {
    let element = document.querySelector(
      ".at-listing__generated_template-cont",
    );
    element.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  };

  const onChangeListingFilter = (val, key) => {
    let temp = _.cloneDeep(listingFilter);
    temp[key] = val;
    setListingFilter(temp);
  };

  const renderModalContent = () => (
    <div className="mb-4" style={{ maxWidth: 1000 }}>
      <p className="fs-2">
        {getLocalised (
          "label.details.ai_description.subtitle_1",
          `Select your preferred description style and the generator will create
          listing description using AI. You still can edit the generated content
          according to your needs after paste to the description field.`
        )}
      </p>
      <br />
      <p className="at-form-input__title" style={{ color: "#3b82f6" }}>
        {selectedLocalised === "en" ? getLocalised("label.details.ai_description.subtitle_2", "Create description for") : ""}
        {address ? ` ${address}; ` : ""}
        {askingPrice
          ? `${selectedCurrency?.value ?? ""} ${numberWithCommas(askingPrice)}; `
          : ""}
        {landArea ? `${landArea} ${selectedMeasurement?.label ?? ""}; ` : ""}
        {selectedBedroomOption ? `${selectedBedroomOption.name} bedroom; ` : ""}
        {selectedBathroomOption
          ? `${selectedBathroomOption.name} bathroom; `
          : ""}
        {selectedBedroom ? `${selectedBedroom.name} bedroom; ` : ""}
        {selectedBathroom ? `${selectedBathroom.name} bathrooms; ` : ""}
        {selectedLocalised === "ja" ? getLocalised("label.details.ai_description.subtitle_2", "Create description for") : ""}
      </p>
      <div
        className="at-form__content mt-4"
        style={{ backgroundColor: "#F9FAFB" }}
      >
        <h2
          className="d-flex align-items-center fw-600 fs-3"
          style={{ cursor: "pointer" }}
          onClick={() => setShowListyingFilter(!showListingFilter)}
        >
          {getLocalised("label.details.ai_description.description_style", "AI Description Style")}
          <AtlasIcon
            svgHref={"atlas-edit-linear"}
            style={{ width: 20, height: 20, fill: "#3B82F6", marginLeft: 10 }}
          />
          <AtlasIcon
            svgHref={"atlas-arrow-down"}
            style={{
              width: 20,
              height: 20,
              fill: "#3B82F6",
              marginLeft: "auto",
              transform: `rotate(${showListingFilter ? 0 : 180}deg)`,
            }}
          />
        </h2>
        <Collapse in={showListingFilter} timeout={"auto"} unmountOnExit>
          <div className="grid-control" style={{ gridGap: 10 }}>
            {filterOptions.map((item, index) => (
              <section
                className="grid-full-col mt-3 pt-3"
                style={{ borderTop: "1px solid #D1D5DB" }}
                key={index}
              >
                <h2 className="at-form-input__title fs-2">{item.label}</h2>
                <CustomRadioGroup
                  horizontal={true}
                  options={item.options}
                  checkedValue={listingFilter[item.value]}
                  selectedRadioValue={(val) =>
                    onChangeListingFilter(val, item.value)
                  }
                  getLocalised={getLocalised}
                />
              </section>
            ))}
            <section
              className="grid-full-col mt-3 pt-3 position-relative"
              style={{ borderTop: "1px solid #D1D5DB" }}
            >
              <h2 className="at-form-input__title fs-2">{getLocalised("label.details.ai_description.content_template", "Content Template")}</h2>
              <div className="at-listing__generated_template-cont">
                {templateOptions.map((item, index) => (
                  <div
                    key={`template_${index}`}
                    onClick={() =>
                      onChangeListingFilter(item.value, "template")
                    }
                  >
                    <p
                      className={`at-listing__generated_template ${listingFilter.template === item.value ? "at-listing__selected-template" : ""}`}
                    >
                      <span className="at-form-input__title">{getLocalised(item.titleKey, item.title)}</span>
                      <br />
                      <span className="fs-2">{getLocalised(item.descriptionKey, item.description)}</span>
                    </p>
                  </div>
                ))}
              </div>
              <CustomButton
                containerClass={"position-absolute"}
                containerStyle={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: 0,
                }}
                children={
                  <AtlasIcon
                    svgHref={"atlas-arrow-circle-left"}
                    style={{ width: 20, height: 20 }}
                  />
                }
                onClick={() => scrollToLeft()}
              />
              <CustomButton
                containerClass={"position-absolute"}
                containerStyle={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 0,
                }}
                children={
                  <AtlasIcon
                    svgHref={"atlas-arrow-circle-right"}
                    style={{ width: 20, height: 20 }}
                  />
                }
                onClick={() => scrollToRight()}
              />
            </section>
          </div>
        </Collapse>
      </div>
      {onLoadGenerateDescription && (
        <section className="grid-full-col d-flex">
          <ScaleLoader
            className="m-auto"
            sizeunit={"px"}
            size={100}
            width={8}
            color={"#F0631D"}
            loading={true}
          />
        </section>
      )}
      {!onLoadGenerateDescription && generateDescription && (
        <div className="mb-4">
          <h2 className="fw-600 fs-3 mb-2">{getLocalised("label.details.ai_description.generated_description", "Generated Description")}</h2>
          <p
            className="fs-2 p-4"
            style={{
              borderRadius: 8,
              whiteSpace: "pre-wrap",
              border: "1px solid #BEC3CB",
              backgroundColor: "#F6F7F8",
            }}
          >
            {generateDescription}
          </p>
        </div>
      )}
    </div>
  );

  const renderFooter = () => (
    <div className="d-flex">
      {generateDescription && (
        <>
          <CustomButton
            disabled={onLoadGenerateDescription}
            className={"btn-new btn-new--primary mr-2"}
            children={"Use Text"}
            onClick={() => setShowConfirmation(true)}
          />
          <CustomButton
            disabled={onLoadGenerateDescription}
            className={"btn-new btn-new--secondary mr-2"}
            children={
              <>
                <AtlasIcon
                  svgHref={"atlas-ai-generation"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "white",
                  }}
                />
                {getLocalised("label.details.ai_description.regenerate", "Regenerate")}
              </>
            }
            onClick={() => onToggleGenerate()}
          />
        </>
      )}
      {!generateDescription && (
        <CustomButton
          disabled={onLoadGenerateDescription}
          className={"btn-new btn-new--secondary mr-2"}
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-ai-generation"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "white",
                }}
              />
              {getTranslation("generate", selectedLocalised)}
            </>
          }
          onClick={() => onToggleGenerate()}
        />
      )}
      <CustomButton
        disabled={onLoadGenerateDescription}
        className={"btn-new btn-new--outline-secondary"}
        children={getTranslation("close", selectedLocalised)}
        onClick={() => onChangeHOC(false, "showGenerateModal")}
      />
    </div>
  );

  return (
    <>
      <ModalDialog
        title={getLocalised("label.details.ai_description.title", "AI Listing Description Generator")}
        children={renderModalContent()}
        footer={renderFooter()}
        onClose={() => onChangeHOC(false, "showGenerateModal")}
      />
      <ConfirmationModal
        open={showConfirmation}
        title={"Use Description Text"}
        message={
          "Use the generated description text will overwrite existing content!"
        }
        positiveText={"OK"}
        negativeText={"Cancel"}
        positiveAction={() => {
          let temp = `<p>${generateDescription}`;
          temp = temp.replace(/\n/g, "</p><p>");
          setShowConfirmation(false);
          onChangeValue(
            language === "English" ? "Description" : "Chinese Description",
            temp,
          );
          onChangeHOC(false, "showGenerateModal");
        }}
        negativeAction={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default GenerateDescriptionHOC(GenerateDescriptionModal);
