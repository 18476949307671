import React, { useEffect, useState } from "react";
import { RiGroup2Fill } from "react-icons/ri";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import TooltipWrapper from "components/TooltipWrapper";
import ModalDialog from "components/Modal/ModalDialog";

import DefaultImage from "assets/images/av-male.jpg";

const dealOptions = [
  { label: "Successful sale", value: 5 },
  { label: "Unsuccessful sale", value: 6 },
  { label: "No Sales", value: 7 },
];

const RatePartner = ({
  currentTab,
  onLoadCobroke,
  selectedCobroke,

  onClose,
  onChangeCobrokeHOC,
  updateProjectSalesCycle,
}) => {
  const {
    agent_info,
    property_name,
    project_location,
    agents_team_name,
    project_sale_cycle,
  } = selectedCobroke;

  const [isView, setIsView] = useState(false);
  const [rating, setRating] = useState("");
  const [comment, setComment] = useState("");
  const [dealStatus, setDealStatus] = useState("");

  useEffect(() => {
    if ([5, 6, 7].includes(project_sale_cycle.status_id)) {
      if (
        (currentTab !== 2 &&
          project_sale_cycle.rating &&
          project_sale_cycle.comment) ||
        (currentTab === 2 &&
          project_sale_cycle.request_project_partner_rating &&
          project_sale_cycle.partner_comment)
      )
        setIsView(true);
    }
  }, []);

  const renderRatingContent = (context, role) => {
    let rating = project_sale_cycle[context];

    if (rating) {
      return (
        <div className="px-3">
          <div
            className={`at-project-partner__rate-partner-${rating.toLowerCase()}-selected`}
          >
            {rating === "Liked" && <AtlasIcon svgHref={"atlas-like-1"} />}
            {rating === "Disliked" && <AtlasIcon svgHref={"atlas-dislike"} />}
          </div>
          <label className="p-0">Comment</label>
          <p className="at-card__description">
            {context === "rating"
              ? project_sale_cycle.comment || "-"
              : project_sale_cycle.partner_comment || "-"}
          </p>
        </div>
      );
    } else {
      return (
        <div className="px-3">
          <p style={{ fontSize: 14 }}>No Rating</p>
          {role === "My Rating" && (
            <button
              className="btn-new-sm btn-new--primary"
              style={{ padding: "6px 12px", fontSize: 14, marginTop: 5 }}
              onClick={() => setIsView(false)}
            >
              <AtlasIcon
                svgHref={"atlas-verify"}
                style={{ width: 16, height: 16, fill: "white", marginRight: 5 }}
              />
              Rate Now
            </button>
          )}
        </div>
      );
    }
  };

  const onClickSubmit = () => {
    if (currentTab === 2) {
      updateProjectSalesCycle({
        partner_comment: comment,
        request_partner_rating: rating,
        status_id: dealStatus || selectedCobroke.project_sale_cycle.status_id,
        id: selectedCobroke.project_sale_cycle.id,
      });
    } else {
      updateProjectSalesCycle({
        rating: rating,
        comment: comment,
        status_id: dealStatus || selectedCobroke.project_sale_cycle.status_id,
        id: selectedCobroke.project_sale_cycle.id,
      });
    }
  };

  return (
    <>
      <ModalDialog
        title={"Rate Project Partner"}
        children={
          <>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <div className="at-cobroke__request-confirmation-detail-card ">
                  <label>Project Partner</label>
                  <hr />
                  <div className={"d-flex flex-wrap"}>
                    <img
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: "cover",
                        borderRadius: "50%",
                        margin: "0px 20px",
                      }}
                      src={agent_info.avatar || DefaultImage}
                      alt="avatar image"
                      onError={(e) => {
                        if (e.target) {
                          e.target.src = DefaultImage;
                        }
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <p className="fw-600 mb-1">{agent_info.name || "N/A"}</p>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Team`}
                          child={
                            <RiGroup2Fill
                              style={{ width: "1.2rem", marginRight: 7 }}
                            />
                          }
                        />
                        <span>{agents_team_name || "N/A"}</span>
                      </div>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Email`}
                          child={
                            <AtlasIcon
                              svgHref={"atlas-sms"}
                              style={{ width: 16, height: 16, marginRight: 5 }}
                            />
                          }
                        />
                        <span>{agent_info.email || "N/A"}</span>
                      </div>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Mobile`}
                          child={
                            <AtlasIcon
                              svgHref={"atlas-call"}
                              style={{ width: 16, height: 16, marginRight: 5 }}
                            />
                          }
                        />
                        <span>{agent_info.phone_number || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-3">
                <div className="at-cobroke__request-confirmation-detail-card ">
                  <label>Co-broke Project</label>
                  <hr />
                  {property_name && (
                    <div className="ml-3">
                      <p className="fw-600">{property_name}</p>
                      <p
                        className="d-flex align-items-center mt-1"
                        style={{ fontSize: 12 }}
                      >
                        <AtlasIcon
                          svgHref={"atlas-location"}
                          style={{ width: 16, height: 16, marginRight: 5 }}
                        />
                        {project_location}
                      </p>
                    </div>
                  )}
                  {!property_name && (
                    <div className="d-flex align-items-start">
                      <div className={"at-cobroke__request-no-property"}>
                        <AtlasIcon svgHref={"atlas-building-4"} />
                      </div>
                      <div>
                        <p className="fw-600">No project yet</p>
                        <button
                          className="btn-new-sm btn-new--secondary mt-2"
                          style={{ padding: "6px 12px", fontSize: 14 }}
                          onClick={() => {
                            onClose();
                            onChangeCobrokeHOC(true, "showSelectProject");
                          }}
                        >
                          <AtlasIcon
                            svgHref={"atlas-building-4"}
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 5,
                              fill: "white",
                            }}
                          />
                          Set Project
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!(isView && [5, 6, 7].includes(project_sale_cycle.status_id)) && (
              <>
                <div
                  className="d-flex align-items-center my-2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  <div className="at-badge-icon at-badge-icon-orange">
                    <AtlasIcon svgHref={"atlas-building-4"} />
                  </div>
                  Sales Status
                </div>
                <div className="d-flex flex-wrap">
                  {dealOptions.map((option, index) => {
                    return (
                      <button
                        key={index}
                        className={`btn-radio ${option.value === dealStatus ? "btn-radio-selected" : "btn-radio-outline"} mr-2 mb-2`}
                        onClick={() => setDealStatus(option.value)}
                      >
                        {option.label}
                      </button>
                    );
                  })}
                </div>
              </>
            )}
            <div
              className="d-flex align-items-center my-2"
              style={{ fontSize: 16, fontWeight: 500 }}
            >
              <div className="at-badge-icon at-badge-icon-primary">
                <AtlasIcon svgHref={"atlas-star-1"} />
              </div>
              Review
            </div>
            {!isView && (
              <p style={{ fontSize: 14, fontWeight: 500 }}>
                Do you like your{" "}
                {currentTab === 2 ? "Reqeust Agent" : "Project Partner"}{" "}
                Performance?
              </p>
            )}
            <p style={{ color: "#374151", fontSize: 12 }}>
              Only "Likes" will be visible and shown to other agents. Any
              dislikes will be kept anonymously and only use to improve the
              service
            </p>
            {isView && (
              <>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <div
                      className="at-cobroke__request-confirmation-detail-card"
                      style={{
                        marginTop: 10,
                        backgroundColor: "transparent",
                        border: "1px solid #D1D5DB",
                      }}
                    >
                      <label>My Rating</label>
                      <hr />
                      {renderRatingContent(
                        currentTab === 2
                          ? "request_project_partner_rating"
                          : "rating",
                        "My Rating",
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <div
                      className="at-cobroke__request-confirmation-detail-card"
                      style={{
                        marginTop: 10,
                        backgroundColor: "transparent",
                        border: "1px solid #D1D5DB",
                      }}
                    >
                      <label>
                        {currentTab === 2
                          ? "Request Agent Rating"
                          : "Project Partner Rating"}
                      </label>
                      <hr />
                      {renderRatingContent(
                        currentTab === 2
                          ? "rating"
                          : "request_project_partner_rating",
                        "Partner Rating",
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {!isView && (
              <>
                <div className="d-flex mt-2">
                  <button
                    className={`at-project-partner__rate-partner-liked${rating === 1 ? "-selected" : ""}`}
                    diabled={isView}
                    onClick={() => setRating(1)}
                  >
                    <AtlasIcon svgHref={"atlas-like-1"} />
                  </button>
                  <button
                    className={`at-project-partner__rate-partner-disliked${rating === 2 ? "-selected" : ""}`}
                    diabled={isView}
                    onClick={() => setRating(2)}
                  >
                    <AtlasIcon svgHref={"atlas-dislike"} />
                  </button>
                </div>
                <p className="my-2" style={{ fontSize: 14, fontWeight: 500 }}>
                  Comment (Optional)
                </p>
                <AtlasTextarea
                  value={comment}
                  diabled={isView}
                  placeholder={"Write your comment"}
                  onChangeValue={(val) => setComment(val)}
                />
              </>
            )}
          </>
        }
        onClose={onClose}
        footer={
          <div className="d-flex">
            {!isView && (
              <button
                disabled={isView || rating === ""}
                className="btn-new btn-new--primary mr-2"
                onClick={() => onClickSubmit()}
              >
                Submit
              </button>
            )}
            <button
              onClick={onClose}
              className="btn-new btn-new--outline-secondary"
            >
              {isView ? "Close" : "Cancel"}
            </button>
          </div>
        }
      />
      {onLoadCobroke && <LoadingModal />}
    </>
  );
};

export default RatePartner;
