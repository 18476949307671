import React, { useState, useEffect } from "react";
import _ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableSort = ({
  data,
  grid,
  disabled,
  background,
  dragBackground,
  containerClass,
  containerStyle,
  contentClass,
  contentStyle,
  renderContent,
  onChangeOrder,
}) => {
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    setDataArray(data);
  }, [data]);

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver
      ? dragBackground || "lightblue"
      : background || "lightgrey",
    padding: grid,
    width: "fit-content",
    ...(containerStyle && { ...containerStyle }),
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: background || "lightgrey",

    ...(isDragging && {
      borderRadius: 5,
      border: "2px solid grey",
      background: "#F3F4F6",
    }),

    // styles we need to apply on draggables
    ...draggableStyle,
    ...(contentStyle && { ...contentStyle }),
  });

  const onDragEnd = (result, list) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let temp = reorder(list, result.source.index, result.destination.index);
    onChangeOrder(temp);
    setDataArray(temp);
  };

  return (
    <DragDropContext onDragEnd={(event) => onDragEnd(event, dataArray)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={containerClass}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {dataArray.map((item, index) => (
              <Draggable
                key={item.draggableId}
                draggableId={item.draggableId}
                index={index}
                isDragDisabled={disabled}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={contentClass}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    {renderContent(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableSort;
