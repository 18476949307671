import React, { useEffect } from "react";
import Moment from "moment";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmation from "components/Modal/delete";
import ConfirmationModal from "components/Modal/confirmation";
import AtPaginatedTable from "components/NewPaginatedTable";
import DuplicatedModal from "./Update";
import UpdateModal from "./Update";
import CreateModal from "./Create";

import permissionsChecker from "utils/permissionsChecker";
import PublicHolidayHOC from "../actions/holiday";

const columnData = [
  { header: "Name", accessor: "name" },
  {
    header: "Date",
    renderColumn: (rowData) => Moment(rowData.date).format("DD MMM YYYY"),
  },
  { header: "Country", accessor: "country" },
  { header: "Region", accessor: "region" },
]

const PublicHoliday = props => {
  const { can_update, can_create, can_destroy } = permissionsChecker("Public Holiday", props.data);

  useEffect(() => {
    props.getHolidays(1, "");
  }, []);

  const renderCheckBoxActions = () => (
    <div className="at-meeting-room__table-title">
      <h4>Public Holiday</h4>
      {can_create && (
        <button
          className="btn-new-sm btn-new--primary ml-2"
          onClick={() => props.onChangeHolidayHOC(true, "showCreateModal")}
        >
          <AtlasIcon svgHref={"atlas-add-linear"} />
          New Holiday
        </button>
      )}
    </div>
  );

  return (
    <>
      <AtPaginatedTable
        columns={columnData}
        rowData={props.holidays || []}
        meta={props.holidayMeta}
        actionColumnContent={[
          {
            name: "duplicate",
            onShow: () => can_create,
            onClick: (rowData) => 
              Promise.all([
                props.onChangeHolidayHOC(rowData, "selectedHoliday"),
              ]).then(() =>
                props.onChangeHolidayHOC(true, "showDuplicateConfirmation"),
              ),
          },
          {
            name: "edit",
            onShow: () => can_update,
            onClick: (rowData) => 
              Promise.all([
                props.onChangeHolidayHOC(rowData, "selectedHoliday"),
              ]).then(() => props.onChangeHolidayHOC(true, "showUpdateModal")),
          },
          {
            name: "delete",
            onShow: () => can_destroy,
            onClick: (rowData) =>
              Promise.all([
                props.onChangeHolidayHOC(rowData, "selectedHoliday"),
              ]).then(() =>
                props.onChangeHolidayHOC(true, "showDeleteConfirmation"),
              ),
          },
        ]}
        searchParams={props.searchParams}
        onChangeSearchParams={(val) =>
          props.onChangeHolidayHOC(val, "searchParams")
        }
        getListAPI={props.getHolidays}
        totalPages={props.holidayPage}
        renderCheckBoxActions={renderCheckBoxActions}
      />
      {props.showCreateModal && <CreateModal {...props} />}
      {props.showUpdateModal && (
        <UpdateModal
          {...props}
          mode={"update"}
          title={"Edit Public Holiday"}
          onClose={() => props.onChangeHolidayHOC(false, "showUpdateModal")}
          onToggleSubmit={(data) => props.updateHoliday(data)}
        />
      )}
      {props.showDuplicateModal && (
        <DuplicatedModal
          {...props}
          mode={"duplicate"}
          title={"New Public Holiday"}
          onClose={() => props.onChangeHolidayHOC(false, "showDuplicateModal")}
          onToggleSubmit={(data) => props.createHoliday([data])}
        />
      )}
      <ConfirmationModal
        title={"Duplicate Holiday"}
        loading={props.onLoadPublicHoliday}
        open={props.showDuplicateConfirmation}
        message={`Are you sure you want to duplicate this holiday?`}
        positiveText={"Duplicate"}
        negativeText={"Cancel"}
        positiveAction={() => {
          props.onChangeHolidayHOC(true, "showDuplicateModal");
          props.onChangeHolidayHOC(false, "showDuplicateConfirmation");
        }}
        negativeAction={() =>
          props.onChangeHolidayHOC(false, "showDuplicateConfirmation")
        }
      />
      <DeleteConfirmation
        title={"Delete Public Holiday"}
        loading={props.onLoadPublicHoliday}
        open={props.showDeleteConfirmation}
        message={`Are you sure you want to remove this public holiday?`}
        positiveAction={() => props.deleteHoliday(props.selectedHoliday.id)}
        negativeAction={() =>
          props.onChangeHolidayHOC(false, "showDeleteConfirmation")
        }
      />
      {props.onLoadPublicHoliday && <LoadingModal />}
    </>
  );
};

export default PublicHolidayHOC(PublicHoliday);
