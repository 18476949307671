import Axios from "axios";

import {
  CREATE_NEW_USER,
  STORE_REGISTER_PATH_FROM,
  STORE_PAYMENT_ID,
} from "./type";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";

export const createNewUser = (data) => (dispatch) => {
  const tokenName = window.location.href.includes("/admin-impersonate")
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const token = getItem(tokenName);
  Axios.defaults.headers = {
    common: {
      Authorization: `JWT ${token}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  dispatch(beginAjaxCall(false));
  Axios.post(`${getDomainURL()}/registrations`, data)
    .then((res) => {
      dispatch(createNewUserSuccess(res.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error, () => dispatch(createNewUser(data))));
    });
};

const createNewUserSuccess = (payload) => ({
  type: CREATE_NEW_USER,
  payload,
});

export const importRegistration = (payload) => ({
  type: CREATE_NEW_USER,
  payload,
});

export const storePathFrom = (payload) => ({
  type: STORE_REGISTER_PATH_FROM,
  payload,
});

export const storePaymentID = (payload) => ({
  type: STORE_PAYMENT_ID,
  payload,
});
