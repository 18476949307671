import { AUTH_USER } from "actions/type";

let initialState = {
  data: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
