import React, { useEffect, useRef } from "react";
import { FormControl, FormHelperText } from "@material-ui/core";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import ImportTable from "components/NewPaginatedTable";

import ImportHOC from "./actions/import";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ColumnData = [
  { header: "Uploaded time", accessor: "uploaded_time", width: "" },
  { header: "File name", accessor: "file_name", width: "150%" },
  { header: "Status", accessor: "status", width: "80%" },
  { header: "Total Success", accessor: "total_success", width: "80%" },
  { header: "Total Fail", accessor: "total_fail", width: "80%" },
];

const ImportExcel = ({
  onClose,
  onChangeImportHOC,
  checkStatus,
  importIpropertyData,
  getImportStatus,
  onLoadStatusId,

  importRecords,
  importExcel,
  onLoadImport,
  importPages,
}) => {
  let pond = useRef(null);

  useEffect(() => {
    getImportStatus(1, "");
  }, []);

  const onChangeImportFile = (fileItems) => {
    if (fileItems && fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onChangeImportHOC(
          {
            file: e.target.result,
            file_name: fileItems[0].file.name,
          },
          "importExcel",
        );
      };
      reader.readAsDataURL(fileItems[0].file);
    } else {
      onChangeImportHOC(null, "importExcel");
    }
  };

  return (
    <>
      <AtlasCloseButton
        containerStyle={{ marginBottom: 10 }}
        onClick={() => onClose()}
      />
      <h4 className="at-card__title">Import iProperty Listing</h4>
      <hr />
      <FormControl style={{ width: "100%" }}>
        <FilePond
          ref={(ref) => (pond.current = ref)}
          value={importExcel}
          allowMultiple={false}
          fullWidth
          acceptedFileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
          ]}
          onremovefile={() => onChangeImportHOC(null, "importExcel")}
          maxFiles={1}
          onupdatefiles={(fileItems) => onChangeImportFile(fileItems)}
        />
        <FormHelperText>Accept excel file types only </FormHelperText>
      </FormControl>
      <div className="d-flex mt-20 mb-3">
        <button
          disabled={!importExcel}
          className="btn-new btn-new--success text-uppercase d-flex ml-auto"
          onClick={() => {
            importIpropertyData(importExcel);
            pond.current.removeFile();
          }}
        >
          Submit
        </button>
      </div>
      <ImportTable
        columns={ColumnData}
        actionColumnContent={[
          {
            name: "check",
            title: "Check Status",
            onShow: (rowData) => onLoadStatusId !== rowData.id,
            disabled: (rowData) => rowData.status_id === 1,
            onClick: (rowData) => checkStatus(rowData.id),
          },
        ]}
        rowActionNeedChecking={true}
        rowData={importRecords.data ? importRecords.data : []}
        meta={importRecords.meta ? importRecords.meta : {}}
        searchParams={[]}
        onChangeSearchParams={() => {}}
        onLoadStatusId={onLoadStatusId}
        getListAPI={getImportStatus}
        totalPages={importPages}
      />
      {onLoadImport && <LoadingModal />}
    </>
  );
};

export default ImportHOC(ImportExcel);
