import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";

import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showDetailsModal: false,
      allCountry: {
        data: [],
        meta: [],
      },
      bulkUpdate: [],
      allCountryPage: "",
      selectedCountry: { id: "" },
    };

    load = (param) => this.setState({ loading: param });

    onChangeLandmarkHOC = (val, context) => this.setState({ [context]: val });

    getAllCountry = (page = 1, search = "") => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/all_countries?${search}page=${page}`,
        this.getAllCountrySuccess,
        this.getAllCountryError,
        this.load,
      );
    };
    getAllCountrySuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        allCountry: payload,
        allCountryPage: tmpTotalPages,
      });
    };
    getAllCountryError = (error) => requestError(error);

    getSelectedCountry = (id = "") =>
      Get(
        `/admin/all_countries/${id}`,
        this.getSelectedCountrySuccess,
        this.getSelectedCountryError,
        this.load,
      );
    getSelectedCountrySuccess = (payload) =>
      this.setState({ selectedCountry: payload });
    getSelectedCountryError = (error) => requestError(error);

    updateCountry = () =>
      Put(
        `/admin/all_countries/${this.state.selectedCountry.id}`,
        {
          name: this.state.selectedCountry.name,
          ...(this.state.selectedCountry.landmark_image && {
            landmark_image: this.state.selectedCountry.landmark_image,
            landmark_image_file_name:
              this.state.selectedCountry.landmark_image_file_name,
          }),
        },
        this.updateCountrySuccess,
        this.updateCountryError,
        this.load,
      );
    updateCountrySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getAllCountry(currentPage, searchParams);
      this.setState({ selectedCountry: payload });
      requestSuccess("Country's Landmark Updated.");
    };
    updateCountryError = (error) => requestError(error);

    batchUpdateState = (dataToSubmit) =>
      Post(
        `/admin/all_countries/${this.state.selectedCountry.id}/states`,
        {
          data: dataToSubmit,
        },
        this.batchUpdateStateSuccess,
        this.batchUpdateStateError,
        this.load,
      );
    batchUpdateStateSuccess = (payload) => {
      this.setState({ bulkUpdate: [] });
      requestSuccess("State's Landmark Updated");
    };
    batchUpdateStateError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadLandmark={this.state.loading}
            allCountry={this.state.allCountry}
            allCountryPage={this.state.allCountryPage}
            selectedCountry={this.state.selectedCountry}
            showDetailsModal={this.state.showDetailsModal}
            bulkUpdate={this.state.bulkUpdate}
            getAllCountry={this.getAllCountry}
            updateCountry={this.updateCountry}
            batchUpdateState={this.batchUpdateState}
            getSelectedCountry={this.getSelectedCountry}
            onChangeLandmarkHOC={this.onChangeLandmarkHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
