import React, { useState } from "react";
import { RiGroup2Fill } from "react-icons/ri";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import TooltipWrapper from "components/TooltipWrapper";

import DefaultImage from "assets/images/av-male.jpg";
import { percentageGuideline } from "../assets";

const FieldData = [
  { label: "Commission", value: "total_portion" },
  { label: "Listing Agent Portion ", value: "my_portion" },
  { label: "Co-broke Agent Portion", value: "balance" },
];

const SendRequestDetails = (listingData) => {
  const { country_id } = listingData;
  return (
    <>
      <p>
        By submitting this request, you agree to the following terms and
        conditions:
      </p>
      <ol type={"1"}>
        <li>
          That you have a client who is interested in the above mentioned
          property.
        </li>
        <li>
          Upon the successful transaction of the Property by you and the Listing
          Agent, the professional fee would be shared on the basis shown above
          subject to the full commission being received.
        </li>
        <li>
          Should the transaction be aborted by the Purchaser/Tenant after the
          Earnest Deposit has been paid, 50% of the agreed fees shall be shared
          equally between the Vendor and both agents.
        </li>
        <li>
          This internal co-broke agreement shall be valid from the time it is
          accepted by the Listing Agent until a period of 30 days or that the
          listing is removed by the Listing Agent for any reason such as it
          being sold or rented.
        </li>
        {country_id === 1 && (
          <li>
            All other terms shall be in accordance with Standard 7.2.4 of the
            Valuers, Appraisers and Estate Agents and Property Managers Act.
          </li>
        )}
        <li>
          Each agent agrees not to contact the respective client directly unless
          consent is granted whilst this internal co-broke agreement is still in
          force.
        </li>
        <li>The rights of the Listing Agent shall be respected.</li>
      </ol>
      <p>
        You agree that all of the above statements are true and accurate and
        that you agree to abide by the terms and conditions of this Internal
        Co-Broke Agreement.
      </p>
      <p>
        Atlas will send this co-broke request to the Listing Agent and if the
        Listing Agent agrees, this Agreement will be in full force.
      </p>
      <p>
        Click Submit, if you want to submit this Co-broke request to this agent.
        Click
        <strong>{` Cancel `}</strong>, if you do not want to proceed.
      </p>
    </>
  );
};

const ReceivedRequestDetails = ({ listing_country_id }) => {
  return (
    <>
      <p>
        You have received a co-broke request for the above property. Click Yes,
        if you want to accept the co-broke request.
      </p>
      <p className={"mt-2"}>
        By agreeing to this request, you agree to the following terms and
        conditions:
      </p>
      <ol type={"1"}>
        <li>
          That you agree to this Internal Co-broke Agreement with the agent and
          property listed above.
        </li>
        <li>
          Upon the successful transaction of the Property by you and the
          co-broke agent, the professional fee would be shared on the basis
          shown above subject to the full commission being received
        </li>
        <li>
          Should the transaction be aborted by the Purchaser/Tenant after the
          Earnest Deposit has been paid, 50% of the agreed fees shall be shared
          equally between the Vendor and both agents.
        </li>
        <li>
          This internal co-broke agreement shall be valid from the time it is
          accepted by you until a period of 30 days or that the listing is
          removed by you for any reason such as it being sold or rented
        </li>
        <li>
          Each agent agrees not to contact the respective client directly unless
          consent is granted whilst this internal co-broke agreement is still in
          force.
        </li>
        {listing_country_id === 1 && (
          <li>
            All other terms shall be in accordance with Standard 7.2.4 of the
            Valuers, Appraisers and Estate Agents and Property Managers Act.
          </li>
        )}
      </ol>
      <p className={"mt-2"}>
        By accepting this co-broke request, you agree that all of the above
        statements are true and accurate and you agree to the Terms and
        Conditions of this Internal Co-Broke Agreement. Click No to decline the
        co-broke request
      </p>
    </>
  );
};

export default function ConfirmationModal({
  onLoad,
  context,
  primaryTab,
  selectedListing,

  onClickClose,
  onClickSubmit,
  onChangeCobrokeHOC,
}) {
  const [acceptanceCheckbox, onCheckAcceptance] = useState(false);
  const {
    agent_info,
    property_name,
    project_location,
    address,
    code,
    co_broke_settings,
  } = selectedListing;

  return (
    <>
      <ModalDialog
        title={"Co-broke Request Confirmation"}
        onClose={onClickClose}
        children={
          <>
            <div className="row">
              <div
                className={`${primaryTab === "Project Partner" ? "col-sm-6" : "col-12"} mb-3`}
              >
                <div className="at-cobroke__request-confirmation-detail-card ">
                  <label>
                    {primaryTab === "Project Partner"
                      ? "Co-broke Agent"
                      : "Request Agent"}
                  </label>
                  <hr />
                  <div className={"d-flex flex-wrap"}>
                    <img
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: "cover",
                        borderRadius: "50%",
                        margin: "0px 20px",
                      }}
                      src={agent_info.avatar_url || DefaultImage}
                      alt="avatar image"
                      onError={(e) => {
                        if (e.target) {
                          e.target.src = DefaultImage;
                        }
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <p className="fw-600 mb-1">
                        {agent_info.full_name || "N/A"}
                      </p>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Team`}
                          child={
                            <RiGroup2Fill
                              style={{ width: "1.2rem", marginRight: 7 }}
                            />
                          }
                        />
                        <span>{agent_info.team_name || "N/A"}</span>
                      </div>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Email`}
                          child={
                            <AtlasIcon
                              svgHref={"atlas-sms"}
                              style={{ width: 16, height: 16, marginRight: 5 }}
                            />
                          }
                        />
                        <span>{agent_info.email || "N/A"}</span>
                      </div>
                      <div className="cobroke_agent-info">
                        <TooltipWrapper
                          title={`Mobile`}
                          child={
                            <AtlasIcon
                              svgHref={"atlas-call"}
                              style={{ width: 16, height: 16, marginRight: 5 }}
                            />
                          }
                        />
                        <span>{agent_info.mobile_contact_number || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {primaryTab === "Project Partner" && (
                <div className="col-sm-6 mb-3">
                  <div className="at-cobroke__request-confirmation-detail-card ">
                    <label>Co-broke Project</label>
                    <hr />
                    {property_name && (
                      <div className="ml-3">
                        <p className="fw-600">{property_name}</p>
                        <p
                          className="d-flex align-items-center mt-1"
                          style={{ fontSize: 12 }}
                        >
                          <AtlasIcon
                            svgHref={"atlas-location"}
                            style={{ width: 16, height: 16, marginRight: 5 }}
                          />
                          {address || project_location}
                        </p>
                        {code && (
                          <p
                            className="at-status_badge"
                            style={{
                              backgroundColor: "#DBEAFE",
                              color: "#1D4ED8",
                              border: "1px solid #93C5FD",
                            }}
                          >
                            {code}
                          </p>
                        )}
                      </div>
                    )}
                    {!property_name && (
                      <div className="d-flex align-items-start">
                        <div className={"at-cobroke__request-no-property"}>
                          <AtlasIcon svgHref={"atlas-building-4"} />
                        </div>
                        <div>
                          <p className="fw-600">No project yet</p>
                          <button
                            className="btn-new-sm btn-new--secondary mt-2"
                            style={{ padding: "6px 12px", fontSize: 14 }}
                            onClick={() => {
                              onClickClose();
                              onChangeCobrokeHOC(true, "showSelectProject");
                            }}
                          >
                            <AtlasIcon
                              svgHref={"atlas-building-4"}
                              style={{
                                width: 16,
                                height: 16,
                                marginRight: 5,
                                fill: "white",
                              }}
                            />
                            Set Project
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {primaryTab === "Project Partner" && (
              <div className="card mb-3 p-3 shadow bg-white rounded border-0">
                <h5 className={"d-flex justify-content-center fw-600"}>
                  Recommended Commission Sharing Guidelines
                </h5>
                <div className="d-flex align-items-center justify-content-around my-2">
                  {percentageGuideline.map((item, index) => (
                    <div
                      key={`percent-guideline-${index}`}
                      className={"at-project-partner__commision-percent"}
                    >
                      <p>{item.percent}</p>
                      {item.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {primaryTab === "Secondary Market" && (
              <div className="card mb-3 p-3 shadow bg-white rounded border-0">
                {FieldData.map((data) => (
                  <div
                    className="d-flex justify-content-between flex-wrap mb-1"
                    key={data.value}
                  >
                    <strong>{`${data.label}:`}</strong>
                    <span>
                      {co_broke_settings && co_broke_settings[data.value]
                        ? `${co_broke_settings[data.value]}${co_broke_settings.co_broke_portion_by === "percentage" ? " %" : ` ${selectedListing.asking_price_currency || ""}`}`
                        : "N/A"}
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="card mb-2 p-3 shadow bg-white rounded border-0">
              {context === "received" &&
                ReceivedRequestDetails(selectedListing)}
              {context === "send" && SendRequestDetails(selectedListing)}
            </div>
            <hr />
            <CustomCheckbox
              checked={acceptanceCheckbox}
              onChangeCheckboxValue={() =>
                onCheckAcceptance(!acceptanceCheckbox)
              }
              content={`By signing up, you agree to the Terms of Service, Privacy Policy,  NDA  and cookie policy.`}
            />
          </>
        }
        footer={
          <div className="d-flex">
            <button
              type={"button"}
              disabled={!acceptanceCheckbox}
              className="btn-new btn-new--primary mr-2"
              onClick={() => onClickSubmit(selectedListing)}
            >
              {primaryTab === "Project Partner" ? "Accept Co-broke" : "Submit"}
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClickClose()}
            >
              Cancel
            </button>
          </div>
        }
      />
      {onLoad && <LoadingModal />}
    </>
  );
}
