import React, { Component } from "react";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";

import CustomCard from "components/Card";
import CustomContactForm from "components/Form/newContact";
import { contact as ContactDictionary } from "dictionary/index";

import { Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const profileTypeOptions = [
  {
    value: 1,
    label: "Personal",
  },
  {
    value: 2,
    label: "Company",
  },
];

const ContactHOC = (WrappedComponent) => {
  class ContactWrappedComponent extends Component {
    state = {
      contacts: [],
      contact_info: {
        id: 0,
        type_id: 1,
        tags: [],
        full_name: "",
        identity_number: 0,
        address: "",
        company: "",
        occupation: "",
        bank_id: 1,
        bank_account_number: 0,
        payable_name: "",
        remark: "",
        ren_tag: "",
        has_representative: false,
        representative_name: "",
        representative_contact_number: "",
      },
      contactFormTitle: "",
      showContactModal: false,
      showLoadingModal: false,
    };

    onUpdateContact = (title, val) => {
      let tmp = this.state.contact_info;
      if (title === "tags") {
        if (tmp["tags"].indexOf(val) > -1) {
          _.remove(tmp["tags"], (o) => o === val);
        } else {
          tmp["tags"].push(val);
        }
      } else {
        tmp[title] = val;
      }
      return this.setState({ contactInfo: tmp });
    };
    onSubmitContact = () => {
      if (this.state.contact_info.id === 0) {
        this.createContact(this.state.contact_info);
      } else {
        this.updateContact(this.state.contact_info);
      }
    };

    onTriggerContactModal = (title) => {
      if (title === "New Contact") {
        this.setState({
          contactFormTitle: title,
          showContactModal: true,
          contact_info: {
            id: 0,
            type_id: 1,
            tags: [],
            full_name: "",
            identity_number: 0,
            address: "",
            company: "",
            occupation: "",
            bank_id: 1,
            bank_account_number: 0,
            payable_name: "",
            remark: "",
            ren_tag: "",
            has_representative: false,
            representative_name: "",
            representative_contact_number: "",
          },
        });
      } else {
        this.setState({ contactFormTitle: title });
      }
    };
    onCloseContactForm = () => this.setState({ showContactModal: false });
    onChangeContactInfo = (title, val) => this.onUpdateContact(title, val);

    load = (param) => this.setState({ showLoadingModal: param });

    getCurrentUserContactList = () =>
      Get(
        `/contacts/search`,
        this.getCurrentUserContactListSuccess,
        this.getCurrentUserContactListError,
        this.load,
      );
    getCurrentUserContactListSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            label: item.full_name,
            value: item.id,
          };
          tmpArray.push(data);
        });
        return this.setState({ contacts: tmpArray });
      }
    };
    getCurrentUserContactListError = (error) => requestError(error);

    getUserContactList = (form_id) =>
      Get(
        `/admin/forms/confirmation_forms/${form_id}/party_search`,
        this.getUserContactListSuccess,
        this.getUserContactListError,
        this.load,
      );
    getUserContactListSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmpArray = [];
        payload.map((item) => {
          let data = {
            ...item,
            label: item.full_name,
            value: item.id,
          };
          tmpArray.push(data);
        });
        return this.setState({ contacts: tmpArray });
      }
    };
    getUserContactListError = (error) => requestError(error);

    createContact = (dataToSubmit) =>
      Post(
        `/contacts`,
        dataToSubmit,
        this.createContactSuccess,
        this.createContactError,
        this.load,
      );
    createContactSuccess = () =>
      requestSuccess("Contact created successfully.");
    createContactError = (error) => requestError(error);

    updateContact = (dataToSubmit) =>
      Put(
        `/contacts/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateContactSuccess,
        this.updateContactError,
        this.load,
      );
    updateContactSuccess = () =>
      requestSuccess("Update contact completed successfully.");
    updateContactError = (error) => requestError(error);

    renderContactModal = () => {
      return (
        <Dialog
          maxWidth={"lg"}
          fullWidth={true}
          open={this.state.showContactModal}>
          <CustomCard
            cardContent={
              <>
                <CustomContactForm
                  title={this.state.contactFormTitle}
                  onClose={this.onCloseContactForm}
                  onChange={this.onChangeContactInfo}
                  profileTypeOptions={profileTypeOptions}
                  contactTags={ContactDictionary.tags}
                  {...this.state.contact_info}
                />
                <div className="d-flex">
                  <button
                    type={"button"}
                    className="btn-new btn-new--success"
                    style={{ marginLeft: 0, marginTop: "15px" }}
                    onClick={
                      this.props.adminView
                        ? this.onSubmitContact
                        : this.onAgentSubmitContact
                    }>
                    Submit
                  </button>
                </div>
              </>
            }
          />
        </Dialog>
      );
    };

    render = () => (
      <>
        <WrappedComponent
          {...this.props}
          contacts={this.state.contacts}
          contact_info={this.state.contact_info}
          showContactLoadingModal={this.state.showLoadingModal}
          onSubmitContact={this.onSubmitContact}
          onUpdateContact={this.onUpdateContact}
          getCurrentUserContactList={this.getCurrentUserContactList}
          getUserContactList={this.getUserContactList}
          getSelectedContact={this.getSelectedContact}
          createContact={this.createContact}
          onTriggerContactModal={this.onTriggerContactModal}
        />

        {this.renderContactModal()}
      </>
    );
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(ContactWrappedComponent);
};

export default ContactHOC;
