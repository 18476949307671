export const Region = [
  {
    id: 1,
    value: "North",
    label: "North",
  },
  {
    id: 2,
    value: "Central",
    label: "Central",
  },
  {
    id: 3,
    value: "South",
    label: "South",
  },
];

export const Status = [
  {
    id: 0,
    value: "Taken",
    label: "Taken",
  },
  {
    id: 1,
    value: "Submitted",
    label: "Submitted",
  },
  {
    id: 2,
    value: "Lost",
    label: "Lost",
  },
  {
    id: 3,
    value: "Cancelled",
    label: "Cancelled",
  },
];

export const Type = [
  {
    id: "1",
    value: "Rental",
    label: "Rental",
  },
  {
    id: "2",
    value: "Sale",
    label: "Sale",
  },
];

export const FormType = [
  {
    id: "1",
    value: "Digital Form",
    label: "Digital Form",
  },
  {
    id: "2",
    value: "Other Form",
    label: "Other Form",
  },
  {
    id: "3",
    value: "Co-Agency Form",
    label: "Co-Agency Form",
  },
  {
    id: "4",
    value: "Internal Co-broke",
    label: "Internal Co-broke",
  },
];
