import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Put } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showConfirmationModal: false,

      editPersonalAssistant: {
        id: "",
        full_name: "",
        mobile_contact_number: "",
        status_id: 1,
        display_name: "",
        email: "",
      },
      displayNameError: "",
      fullNameError: "",
      emailError: "",
      contactError: "",
      selectedSection: "Profile",
    };

    load = (param) => this.setState({ loading: param });

    onClickUpdatePersonalAssistant = (e) => {
      if (this.state.displayNameError || this.state.emailError) {
        requestError(
          "Please make sure all valid informations are filled in the form.",
        );
      } else {
        this.submitImagesCheck(this.state.editPersonalAssistant);
      }
    };

    submitImagesCheck = (dataToSubmit) => {
      this.updatePA(dataToSubmit.id, dataToSubmit);
    };

    updatePA = (id, dataToSubmit) =>
      Put(
        `/personal_assistants/${id}`,
        dataToSubmit,
        () => this.updatePASuccess(id),
        this.updatePAError,
        this.load,
      );
    updatePASuccess = async (paID) => {
      await this.props.getPAList();
      let tmpSelectedPA = _.find(
        this.props.personalAssistantsList,
        (item) => item.id === paID,
      );

      let {
        id,
        full_name,
        mobile_contact_number,
        status_id,
        display_name,
        email,
      } = tmpSelectedPA;

      let temp = {
        id: id,
        full_name: full_name,
        mobile_contact_number: mobile_contact_number,
        status_id: status_id,
        display_name: display_name,
        email: email,
      };
      if (tmpSelectedPA) {
        await this.onChangeEditHOC(temp, "selectedPersonalAssistant");
      } else {
        await this.props.onChangeHOC(false, "showEditModal");
      }
      requestSuccess("Personal Assistant info updated successfully.");
    };
    updatePAError = (error) => requestError(error && error);

    onChangeEditHOC = (val, context) => this.setState({ [context]: val });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showConfirmationModal={this.state.showConfirmationModal}
            onLoadEditPA={this.state.loading}
            displayNameError={this.state.displayNameError}
            emailError={this.state.emailError}
            fullNameError={this.state.fullNameError}
            contactError={this.state.contactError}
            editPersonalAssistant={this.state.editPersonalAssistant}
            selectedSection={this.state.selectedSection}
            onClickUpdatePersonalAssistant={this.onClickUpdatePersonalAssistant}
            onChangeEditHOC={this.onChangeEditHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
