import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Get, Put, Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";
import isEmptyValue from "utils/isEmpty";

const searchParams = [];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      selectedItem: {},
      resubmitOrder: {},
      selectedOrderHistory: {},

      showManageTicket: false,
      showProfileUpdate: false,
      showTicketTransfer: false,
      showPaymentFeedback: false,
      showResubmitConfirmation: false,
      showCancelPaymentConfirmation: false,

      orders: {},
      ordersPage: {},
      searchAgent: {},
      searchParams: searchParams,
      repayOrder: {},
      ticketPurchasers: {
        data: [],
        meta: {},
      },
      ticketPurchasersPages: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeOrderHistoryHOC = (val, context) =>
      this.setState({ [context]: val });

    getOrderHistory = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/market_orders?${search}page=${page}`,
        this.getOrderHistorySuccess,
        this.getOrderHistoryError,
        this.load,
      );
    };
    getOrderHistorySuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        created_at: item.created_at
          ? Moment(item.created_at, "DD-MM-YYYY").format("DD MMM YYYY")
          : "N/A",
        paid_at: item.paid_at
          ? Moment(item.paid_at, "DD-MM-YYYY").format("DD MMM YYYY")
          : "N/A",
      }));
      this.setState({
        orders: {
          data: tempData,
          meta: payload.meta,
        },
        ordersPage: tmpTotalPages,
      });
    };
    getOrderHistoryError = (error) => requestError(error);

    redoPayment = (data) => {
      Get(
        `/market_orders/${data.id}/validate`,
        (payload) => this.redoPaymentSuccess(payload, data),
        this.redoPaymentError,
        this.load,
      );
    };
    redoPaymentSuccess = (payload, data) => {
      const { id, user_id, market_order_items = {}, total_amount } = data;

      const tmpPath =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.adminSignInReducer.prefix_path
          : "";

      let tempRemarks = "Atlas Store order for ";
      market_order_items.map((item, index) => {
        const tmpOrderTitle = !isEmptyValue(
          item.selected_market_product_attached_product,
        )
          ? item.selected_market_product_attached_product
          : "";
        if (
          item.selected_market_product_attached_product_type ===
          "ProductVariant"
        ) {
          let title = tmpOrderTitle.options.map((option) => `${option.value}`);
          tempRemarks += `${item.market_product.name}(${title}) x ${item.quantity}${market_order_items.length - 1 === index ? "" : ", "}`;
        } else {
          tempRemarks += `${item.market_product.name}${tmpOrderTitle ? `(${tmpOrderTitle.title})` : ""} x ${item.quantity}${market_order_items.length - 1 === index ? "" : ", "}`;
        }
      });

      let tmpData = {
        amount: total_amount,
        description: tempRemarks,
        product_id: id,
        provider: "Ipay88",
        type: "MarketOrder",
        user_id: user_id,
      };

      this.props.history.push({
        pathname: `${tmpPath}/marketing-centre/my-cart/redo-payment`,
        state: {
          paymentDetails: tmpData,
          showCheckoutModal: true,
        },
      });
    };
    redoPaymentError = (error) => requestError(error);

    cancelPayment = (id) =>
      Put(
        `/market_orders/${id}/cancel`,
        {},
        this.cancelPaymentSuccess,
        this.cancelPaymentError,
        this.load,
      );
    cancelPaymentSuccess = (payload) => {
      const { currentPage = 0, searchParams = "" } =
        this.props.data.lastViewReducer.lastView;
      this.getOrderHistory(currentPage, searchParams);

      this.setState({ showCancelPaymentConfirmation: false });
      requestSuccess("The order has been cancelled successfully.");
    };
    cancelPaymentError = (error) => requestError(error);

    getAgentsBySearch = (search, is_superior) =>
      Post(
        `/agents/search`,
        {
          mobile_contact_number: search,
          ...(is_superior ? { include: "personal_assistant" } : {}),
        },
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ searchAgent: payload });
    getAgentsBySearchError = (error) => requestError(error);

    getTicketPurchasers = (page) =>
      Get(
        `/market_order_items/${this.state.selectedItem.id}/tickets?page=${page}`,
        this.getTicketPurchasersSuccess,
        this.getTicketPurchasersError,
        this.load,
      );
    getTicketPurchasersSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        ticketPurchasers: payload,
        ticketPurchasersPages: tempPages,
      });
    };
    getTicketPurchasersError = (error) => requestError(error);

    ticketTransfer = (ticket_id, user_id) =>
      Put(
        `/market_order_items/${this.state.selectedItem.id}/tickets/${ticket_id}`,
        { user_id: user_id },
        this.ticketTransferSuccess,
        this.ticketTransferError,
        this.load,
      );
    ticketTransferSuccess = (payload) => {
      this.setState({ showTicketTransfer: false });
      this.getTicketPurchasers(1);
      requestSuccess("Ticket transferred");
    };
    ticketTransferError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          orders={this.state.orders}
          ordersPage={this.state.ordersPage}
          repayOrder={this.state.repayOrder}
          searchAgent={this.state.searchAgent}
          selectedItem={this.state.selectedItem}
          searchParams={this.state.searchParams}
          resubmitOrder={this.state.resubmitOrder}
          ticketPurchasers={this.state.ticketPurchasers}
          ticketPurchasersPages={this.state.ticketPurchasersPages}
          showManageTicket={this.state.showManageTicket}
          showProfileUpdate={this.state.showProfileUpdate}
          showTicketTransfer={this.state.showTicketTransfer}
          showPaymentFeedback={this.state.showPaymentFeedback}
          selectedOrderHistory={this.state.selectedOrderHistory}
          showResubmitConfirmation={this.state.showResubmitConfirmation}
          showCancelPaymentConfirmation={
            this.state.showCancelPaymentConfirmation
          }
          onLoadOrderHistory={this.state.loading}
          redoPayment={this.redoPayment}
          cancelPayment={this.cancelPayment}
          ticketTransfer={this.ticketTransfer}
          getOrderHistory={this.getOrderHistory}
          getAgentsBySearch={this.getAgentsBySearch}
          getTicketPurchasers={this.getTicketPurchasers}
          onChangeOrderHistoryHOC={this.onChangeOrderHistoryHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
