import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      assignModal: false,
      selectedUserType: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeLeadUpdateHOC = (val, context) => this.setState({ [context]: val });

    updateSelectedLead = (id, dataToSubmit) => {
      let url = "";
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      switch (role) {
        case "Agent":
        case "Associated Agent":
        case "Personal Assistant":
          url = `/leads/${id}`;
          break;

        default:
          url = `/admin/leads/${id}`;
          break;
      }
      Put(
        url,
        dataToSubmit,
        this.updateSelectedLeadSuccess,
        this.updateSelectedLeadError,
        this.load,
      );
    };
    updateSelectedLeadSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getLeads(currentPage, searchParams);

      this.props.onChangeLeadsHOC(payload, "selectedLead");
      this.props.onChangeLeadsHOC(false, "showQuickUpdateModal");
      requestSuccess("Selected lead had been updated successfully");
    };
    updateSelectedLeadError = (error) => requestError(error);

    assignAgent = (id, dataToSubmit) => {
      let url = "";
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      switch (role) {
        case "Agent":
        case "Associated Agent":
        case "Personal Assistant":
          url = `/leads/${id}/assign`;
          break;

        default:
          url = `/admin/leads/${id}/assign`;
          break;
      }
      Put(
        url,
        dataToSubmit,
        this.assignAgentSuccess,
        this.assignAgentError,
        this.load,
      );
    };
    assignAgentSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getLeads(currentPage, searchParams);
      this.props.getSelectedLead(this.props.selectedLead.id);
      requestSuccess(
        "The lead has been assigned to the selected agent successfully.",
      );
      this.setState({
        assignModal: false,
        selectedUserType: null,
      });
      this.props.onChangeLeadsHOC(false, "selected");
    };
    assignAgentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            assignModal={this.state.assignModal}
            selectedUserType={this.state.selectedUserType}
            onLoadUpdate={this.state.loading}
            assignAgent={this.assignAgent}
            onChangeLeadUpdateHOC={this.onChangeLeadUpdateHOC}
            updateSelectedLead={this.updateSelectedLead}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
