import React, { useEffect } from "react";

import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateForm from "./Create";

import WithUser from "./actions";

const columnData = [
  {
    header: "Full Name",
    accessor: "user_full_name",
  },
  {
    header: "Email",
    accessor: "user_email",
  },
  {
    header: "Contact Number",
    accessor: "user_mobile_contact_number",
  },
  {
    header: "Team",
    accessor: "user_team_display_name",
  },
];

const Users = (props) => {
  const {
    userLists,
    userPages,
    searchParams,
    onLoadUsers,
    showCreateUser,

    getUserLists,
    createUserList,
    onChangeUsersHOC,
  } = props;

  useEffect(() => getUserLists(1, ""), []);

  const renderCheckBoxActions = () => (
    <div className='at-table__head-title'>
      <h4>Users</h4>
      <button
        className={"btn-new btn-new--primary ml-2"}
        onClick={() => onChangeUsersHOC("showCreateUser", true)}
      >
        <AtlasIcon svgHref={"atlas-add-linear"} />
        Assign User
      </button>
    </div>
  );

  return (
    <>
      <AtPaginatedTable
        columns={columnData}
        rowData={userLists.data || []}
        meta={userLists.meta}
        renderCheckBoxActions={renderCheckBoxActions}
        actionColumnContent={[
          {
            name: "delete",
            color: "#F04438",
            onClick: (rowData) => {
              onChangeUsersHOC("toRemoveID", rowData.id);
              onChangeUsersHOC("showConfirmDeleteModal", true);
            },
          },
        ]}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeUsersHOC("searchParams", val)}
        getListAPI={getUserLists}
        totalPages={userPages}
      />
      {showCreateUser && (
        <CreateForm
          onClose={() => onChangeUsersHOC("showCreateUser", false)}
          createUserList={createUserList}
          onLoadUsers={onLoadUsers}
        />
      )}
      {onLoadUsers && <LoadingModal />}
    </>
  );
};

export default WithUser(Users);
