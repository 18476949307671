import React, { useState, useMemo, useEffect, useCallback } from "react";
import { PulseLoader } from "react-spinners";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import CustomUpload from "components/Upload";
import CustomButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import CustomInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";

import AgentSearchHOC from "actions/agentSearch";
import isEmptyValue from "utils/isEmpty";

import "stylesheets/containers/admin/global-network-management/new.scss";

const VisibleOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const GlobalNetworkForm = ({
  agentList,
  onLoadGlobalNetwork,
  selectedBranch,
  onLoadAgent,

  onClose,
  onChangeAgentHOC,
  getAgentsBySearch,
  updateBranch,
}) => {
  const [updateForm, onChangeUpdateForm] = useState({
    name: "",
    flag_image: null,
    user: null,
    code: selectedBranch.code,
    language: selectedBranch.language,
    language_code: selectedBranch.language_code,
    position: selectedBranch.position,
    to_show: selectedBranch.to_show,
    reference_id: selectedBranch.reference_id,
  });

  useEffect(() => {
    onChangeUpdateForm({
      id: selectedBranch.id,
      user: selectedBranch.user,
      flag_image: selectedBranch.flag_image_url,
      name: selectedBranch.name,
      code: selectedBranch.code,
      language: selectedBranch.language,
      language_code: selectedBranch.language_code,
      position: selectedBranch.position,
      to_show: selectedBranch.to_show,
      reference_id: selectedBranch.reference_id,
    });
  }, []);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(updateForm);
    temp[context] = val;

    onChangeUpdateForm(temp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const onDisableUpdate = useMemo(() => {
    const Obj = Object.entries(updateForm);
    return Obj.some(
      ([key, value]) =>
        ["reference_id", "position", "language", "language_code", "user"].indexOf(
          key,
        ) === -1 && isEmptyValue(value),
    );
  }, [updateForm]);

  return (
    <ModalDialog
      title={"Edit Global Network"}
      onLoad={onLoadGlobalNetwork}
      onClose={onClose}
      children={
        <form style={{ maxWidth: "55rem" }} onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={16}>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title" required>
                  Country Code
                </h2>
                <CustomInput
                  value={updateForm.code}
                  required={true}
                  onChangeValue={(val) => onChangeField(val, "code")}
                  placeholder={"Select Country code"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">ERP ID</h2>
                <CustomInput
                  value={updateForm.reference_id}
                  required={false}
                  onChangeValue={(val) => onChangeField(val, "reference_id")}
                  placeholder={"Enter ERP ID"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title" required>
                  Country Name
                </h2>
                <CustomInput
                  value={updateForm.name}
                  required={true}
                  onChangeValue={(val) => onChangeField(val, "name")}
                  placeholder={"Select Country name"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item mb-3">
                <h2 className="at-form-input__title">
                  <span className={"mr-10"}>Person in charge</span>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </h2>
                <CustomTypeahead
                  disabled={onLoadAgent}
                  typeaheadId={"internal_agent"}
                  options={agentList ? agentList : []}
                  selectedValue={updateForm.user ? [updateForm.user] : []}
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    onChangeField(null, "user");
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && onChangeField(val[0], "user")
                  }
                  onClear={() => onChangeField(null, "user")}
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                  showClearButton={true}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Language</h2>
                <CustomInput
                  value={updateForm.language}
                  onChangeValue={(val) => onChangeField(val, "language")}
                  placeholder={"Enter language"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Language Code</h2>
                <CustomInput
                  value={updateForm.language_code}
                  onChangeValue={(val) => onChangeField(val, "language_code")}
                  placeholder={"Enter language code"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Position</h2>
                <CustomInput
                  type="number"
                  value={updateForm.position}
                  onChangeValue={(val) => onChangeField(val, "position")}
                  placeholder={"Enter position"}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Visible</h2>
                <AtlasRadioGroup
                  disabled={false}
                  required={false}
                  horizontal={true}
                  checkedValue={updateForm.to_show}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) => onChangeField(val, "to_show")}
                  options={VisibleOptions}
                />
              </section>
            </Grid>
            <Grid item md={6} xs={12}>
              <section className="at-global-network__item">
                <h2 className="at-form-input__title">Flag Image</h2>
                <CustomUpload
                  imageUrl={updateForm.flag_image}
                  uploadImage={(val) => onChangeField(val, "flag_image")}
                />
              </section>
            </Grid>
          </Grid>
        </form>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <CustomButton
            className={"btn-new btn-new--success"}
            disabled={onDisableUpdate}
            onClick={() =>
              updateBranch({
                id: updateForm.id,
                name: updateForm.name,
                flag_image: updateForm.flag_image,
                user_id: updateForm.user?.id ?? null,
                language: updateForm.language,
                language_code: updateForm.language_code,
                position: updateForm.position,
                to_show: updateForm.to_show,
                reference_id: updateForm.reference_id,
              })
            }
            children={"Update"}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      } />
  );
};

export default AgentSearchHOC(GlobalNetworkForm);
