import React, { Component } from "react";
import _ from "lodash";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import SVGIcon from "components/Icon";
import LoadingModal from "components/LoadingModal";

import { SecConfigData } from "utils/secConfigData";

import VersionCheckHOC from "actions/versionCheck";

import "stylesheets/containers/announcement/details.scss";

class Details extends Component {
  state = {
    SecPermissions: [],
  };
  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let roleSecConfig = _.find(SecConfigData, { role: tmpProfileReducer.role });
    let currentModuleSecConfig = _.find(
      roleSecConfig.accessDashboardComponent,
      { name: "Announcement" },
    );
    return this.setState({ SecPermissions: currentModuleSecConfig.access });
  };

  renderCardContent = () => {
    const { title, published, creator_name, created_at, content, id } =
      this.props.selectedAnnouncement;
    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 10 }}
          onClick={() =>
            this.props.onChangeAnnouncementHOC(false, "showDetailsModal")
          }
        />
        <h4 className="at-card__title">Announcement Details</h4>
        <hr />
        <div className="at-announcement__header mb-2">
          <h4 className="at-card__subtitle at-announcement__title mb-2">
            {" "}
            {title}{" "}
          </h4>
          <div
            className={`at-announcement__publish-status ${published ? "" : "not-published"}`}
          >
            <div className="at-announcement__publish-tick">
              <SVGIcon className="svg-icon-tick" svgHref="icon-tick" />
            </div>
            <p>{published ? "Published" : "Not Published"}</p>
          </div>
        </div>

        <p className="at-announcement__creator">by {creator_name}</p>
        <p className="at-announcement__date mb-2">Created on: {created_at}</p>

        <p className="at-announcement__content">{content}</p>

        <div className="d-flex">
          {this.state.SecPermissions.indexOf("update") > -1 && (
            <CustomButton
              children={"Edit"}
              className={"btn-new btn-new--primary at-btn--secondary mr-10"}
              onClick={() => {
                this.props.onChangeAnnouncementHOC(false, "showDetailsModal");
                this.props.onChangeAnnouncementHOC(true, "showEditModal");
              }}
            />
          )}
          {this.state.SecPermissions.indexOf("delete") > -1 && (
            <CustomButton
              children={"Delete"}
              className={"btn-new btn-new--primary at-btn--danger"}
              onClick={() => {
                this.props.onChangeAnnouncementHOC(id, "selectedDeleteId");
                this.props.onChangeAnnouncementHOC(
                  true,
                  "showDeleteConfirmationModal",
                );
              }}
            />
          )}
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div>
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadAnnouncement && <LoadingModal />}
      </div>
    );
  };
}

export default VersionCheckHOC(Details);
