import React from "react";

const RefundTerms = () => {
  return (
    <>
      <ul>
        <li style={{marginBottom: 16}}>
          A refundable deposit is required to secure your booking.
        </li>
        <li style={{marginBottom: 16}}>
          The full deposit will be returned within 14 days after the conclusion 
          of the event, provided that there is no damage (beyond normal wear 
          and tear) to the premises or equipment. In the event of damage such 
          as broken or malfunctioning equipment due to misuse, missing or lost 
          venue inventory (e.g., microphone, chairs), marks or scratches on 
          walls, doors, or furniture or excessive dirt or staining of flooring, 
          carpets, furniture, or linens, the Company reserves the right to use 
          the deposit to cover the cost of repairs or replacement.
        </li>
        <li style={{marginBottom: 16}}>
          In the event of any damage, the replacement cost or the equipment's fair market 
          value, whichever is higher, will be charged.
        </li>
        <li>
          Refunds will be made to the bank account registered under your booking with 
          Atlas. To ensure a smooth refund process, please verify the accuracy of your 
          account information.
        </li>
      </ul>
    </>
  );
};

export default RefundTerms;
