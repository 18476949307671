import React, { Component } from "react";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";

import CustomTab from "components/Tab";
import AtlasCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtlasSnackbar from "components/Snackbar";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";

import ViewEditPasswordModal from "../ViewEditPassword";
import AppointmentLetterModal from "../AppointmentLetter";
import UploadModalContent from "./uploadContent";
import DetailsSection from "./detailsSection";
import ProfileCard from "./profileCard";
import AgentQRContent from "./agentQr";
import RemarkContent from "./Remark";

import permissionsChecker from "utils/permissionsChecker";
import WithQR from "../actions/qr";
import AppointmentLetterHOC from "../actions/appointmentLetter";

import "../index.scss";

class DetailsModal extends Component {
  state = {
    selectedSection: "Profile",
    showReferalAgent: false,
    showAgentQR: false,
    showIQIID: false,
    showMoreInfo: false,
    showDetailsSection: false,
    selectedOtherAgent: {},

    showPaymentProofModal: false,
    showResignationModal: false,
    showEmploymentProofModal: false,
    showViewEditPasswordModal: false,
  };

  componentDidMount = () =>
    this.props.getAppointmentDate(this.props.selectedUser.id);

  viewPersonalSalesOtherAgent = (
    encoded_identity,
    display_name,
    detailsSection
  ) =>
    this.setState({
      showDetailsSection: detailsSection,
      selectedOtherAgent: {
        encoded_identity,
        display_name,
      },
    });

  toggleCopySnackBar = (val) => {
    navigator.clipboard.writeText(val);
    this.props.onChangeHOC(true, "showCopySnackBar");
  };

  processReferralLink = (val) =>
    window.location.host.indexOf("atlas.iqiglobal.com") !== -1
      ? `https://atlas.iqiglobal.com/#/register/ref=${val}`
      : `http://${window.location.host}/#/register/ref=${val}`;

  renderAppointmentLetterModal = () => (
    <ModalDialog
      title={"Appointment Letter"}
      onLoad={this.props.onLoadAppointmentLetter}
      onClose={() =>
        this.props.onChangeAppointmentLetterHOC(
          false,
          "showAppointmentLetterModal"
        )
      }
      children={
        <AppointmentLetterModal
          onClickClose={() =>
            this.props.onChangeAppointmentLetterHOC(
              false,
              "showAppointmentLetterModal"
            )
          }
          selectedAppointmentLetter={this.props.selectedAppointmentLetter}
          userBranchID={this.props.selectedUser.branch_id}
          selectedUserId={this.props.selectedUser.id}
          downloadDocWithDate={this.props.downloadDocWithDate}
          sendEmailWithDate={this.props.sendEmailWithDate}
          hideOfferDate={this.props.hideOfferDate}
          hideBranch={this.props.hideBranch}
          branches={this.props.branches}
        />
      }
    />
  );

  renderIQIQR = () => {
    const { qr_code_image_url } = this.props.selectedUser;
    const { can_update } = permissionsChecker("Users", this.props.data);

    return (
      <ModalDialog
        title={"My IQI ID"}
        maxWidth={"md"}
        onClose={() => this.setState({ showIQIID: false })}
        children={
          <div className="d-flex flex-column align-items-center">
            <img
              src={qr_code_image_url}
              alt="qr code image_ url"
              style={{ width: 250, height: 250 }}
            />
            <button
              disabled={!can_update}
              className="btn-new btn-new--secondary w-100 mt-3"
              onClick={() =>
                this.props.onChangeQRHOC(
                  true,
                  "showRegenConfirmation",
                  "iqi-id"
                )
              }
            >
              Re-generate QR Code
            </button>
          </div>
        }
      />
    );
  };

  renderReferralContent = () => {
    let tempCode = this.processReferralLink(
      this.props.selectedUser.referrer_code
    );

    return (
      <div className={"d-flex flex-column align-items-center"}>
        <p style={{ fontSize: 14, color: "#1F2937", wordBreak: "break-all" }}>
          {tempCode}
        </p>
        <button
          className="btn-new btn-new--primary mt-3"
          onClick={() => this.toggleCopySnackBar(tempCode)}
        >
          <AtlasIcon
            svgHref={"atlas-copy"}
            style={{ width: 20, height: 20, marginRight: 8, fill: "white" }}
          />
          Copy Link
        </button>
      </div>
    );
  };

  renderProfilContent = () => {
    const {
      selectedUser,
      onClickNameCard,
      onClickEditButton,
      onClickBackButton,
      isAppointmentSigned,
      selectedUnitAdminProfile,
      showAppointmentEmailModal,
      onLoadAppointmentLetter,
      sendEmailWithoutData,
      downloadDocWithoutData,
      appointmentPreview,
    } = this.props;

    return (
      <ProfileCard
        data={this.props.data}
        profileRole={
          window.location.href.includes("/admin-impersonate")
            ? this.props.data.currentSignInProfileReducer.role
            : this.props.data.profileReducer.role
        }
        isAppointmentSigned={isAppointmentSigned}
        selectedUser={selectedUser}
        onLoadAppointmentLetter={onLoadAppointmentLetter}
        showAppointmentEmailModal={showAppointmentEmailModal}
        bankDetails={selectedUnitAdminProfile}
        onClickNameCard={onClickNameCard}
        onClickEditButton={onClickEditButton}
        appointmentPreview={appointmentPreview}
        downloadDocWithoutData={downloadDocWithoutData}
        sendEmailWithoutData={sendEmailWithoutData}
        onClickBackButton={onClickBackButton}
        onChangeAppointmentLetterHOC={this.props.onChangeAppointmentLetterHOC}
        onClickChangePassWord={() =>
          this.setState({ showViewEditPasswordModal: true })
        }
        onChangeState={(val, context) => this.setState({ [context]: val })}
        onClickAppointmentLetter={() =>
          this.props.checkDigitalSign(selectedUser.id)
        }
      />
    );
  };

  render = () => {
    const { onLoadQR, showRegenConfirmation } = this.props;
    const { can_update } = permissionsChecker("Users", this.props.data);
    const { id, payment_proof, employment_proof, resignation_letter_url } =
      this.props.selectedUser;

    return (
      <>
        <CustomTab
          sections={["Profile", "Remark", "Change Password"]}
          selectedSection={this.state.selectedSection}
          onSelectSection={(val) => this.setState({ selectedSection: val })}
        />
        {this.state.selectedSection === "Profile" && this.renderProfilContent()}
        {this.state.selectedSection === "Remark" && (
          <RemarkContent {...this.props.selectedUser} />
        )}
        {this.state.selectedSection === "Change Password" && (
          <ViewEditPasswordModal
            selectedUser={this.props.selectedUser}
            onChangeHOC={this.props.onChangeHOC}
          />
        )}
        {this.props.showAppointmentLetterModal &&
          this.renderAppointmentLetterModal()}
        {this.state.showIQIID && this.renderIQIQR()}
        {this.state.showAgentQR && (
          <ModalDialog
            title={"My Public Profile"}
            maxWidth={"md"}
            onClose={() => this.setState({ showAgentQR: false })}
            children={
              <AgentQRContent
                can_update={can_update}
                selectedUser={this.props.selectedUser}
                onChangeQRHOC={this.props.onChangeQRHOC}
              />
            }
          />
        )}
        {this.state.showReferalAgent && (
          <ModalDialog
            title={"Referral Link"}
            maxWidth={"md"}
            onClose={() => this.setState({ showReferalAgent: false })}
            children={this.renderReferralContent()}
          />
        )}
        {onLoadQR && <LoadingModal />}
        <ConfirmationModal
          title={"Re-generate QR Code"}
          loading={onLoadQR}
          open={showRegenConfirmation}
          message={`Are you sure you want to regenerate QR Code?`}
          positiveAction={() => this.props.generateQR(id)}
          negativeAction={() =>
            this.props.onChangeQRHOC(false, "showRegenConfirmation")
          }
        />
        <AtlasDialog open={this.state.showResignationModal}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <UploadModalContent
                context={"Resignation Letter"}
                fileData={resignation_letter_url}
                onUpload={(file, fileName) =>
                  this.props.uploadAgentFile(id, {
                    resignation_letter: file,
                    resignation_letter_file_name: fileName,
                  })
                }
                onClose={() => this.setState({ showResignationModal: false })}
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={this.state.showPaymentProofModal}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <UploadModalContent
                context={"Payment Proof"}
                fileData={payment_proof}
                onUpload={(file, fileName) =>
                  this.props.uploadAgentFile(id, {
                    payment_proof: file,
                    payment_proof_file_name: fileName,
                  })
                }
                onClose={() => this.setState({ showPaymentProofModal: false })}
              />
            }
          />
        </AtlasDialog>
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={this.state.showEmploymentProofModal}
        >
          <AtlasCard
            className={"h-100"}
            cardContent={
              <UploadModalContent
                context={"Employment Proof"}
                fileData={employment_proof}
                onUpload={(file, fileName) => {
                  this.props.uploadAgentFile(id, {
                    employment_proof: file,
                    employment_proof_file_name: fileName,
                  });
                }}
                onClose={() =>
                  this.setState({ showEmploymentProofModal: false })
                }
              />
            }
          />
        </Dialog>
        <AtlasDialog
          paperClassName={"dialog-paper"}
          open={this.state.showDetailsSection}
        >
          <DetailsSection
            showDetailsSection={this.state.showDetailsSection}
            selectedUser={this.props.selectedUser}
            clickBack={() => this.setState({ showDetailsSection: false })}
            otherClickBack={() =>
              this.setState({ showDetailsSection: "TeamSales" })
            }
            onClickShowDetailsScreen={(val) =>
              this.setState({ showDetailsSection: val })
            }
            viewPersonalSales={this.viewPersonalSalesOtherAgent}
            selectedOtherAgent={this.state.selectedOtherAgent}
          />
        </AtlasDialog>
        <AtlasSnackbar
          open={this.props.showCopySnackBar}
          handleClose={() => this.props.onChangeHOC(false, "showCopySnackBar")}
          message={"Data copied into clipboard. You can now paste the data."}
        />
      </>
    );
  };
}

export default compose(AppointmentLetterHOC, WithQR)(DetailsModal);
