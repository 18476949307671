import React from "react";

import ModalDialog from "./ModalDialog";
import LoadingModal from "components/LoadingModal";

const InformativeModal = ({
  open,
  loading,
  message,
  title,
  renderIcon,

  additionalActions,
  buttonOnClick,
  buttonText,

  cardMessageStyle,
  disabledClose,
  cardStyle,
}) => {
  const renderFooter = () => (
    <div className="d-flex justify-content-center">
      <button
        disabled={disabledClose}
        style={{ width: "fit-content" }}
        className="btn-new btn-new--outline-secondary"
        onClick={() => buttonOnClick()}
      >
        {buttonText || "Close"}
      </button>
      {additionalActions &&
        additionalActions.map((action, index) => (
          <button
            key={index}
            disabled={action.disabled}
            style={{ width: "fit-content", ...action.style }}
            className={action.className}
            onClick={action.onClick}
          >
            {action.label}
          </button>
        ))}
    </div>
  );

  const renderCardContent = () => {
    return (
      <>
        <div style={{ textAlign: "center", ...cardStyle }}>
          {renderIcon}
          <br />
          <p className={"mt-10 mb-10"} style={{ ...cardMessageStyle }}>
            {message}
          </p>
        </div>
        {loading && <LoadingModal />}
      </>
    );
  };
  return (
    <>
      {open && (
        <ModalDialog
          title={title || ""}
          children={renderCardContent()}
          footer={renderFooter()}
          onClose={buttonOnClick}
        />
      )}
    </>
  );
};

export default InformativeModal;
