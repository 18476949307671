import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { refreshToken } from "actions/login";
import objToFormData from "utils/objToFormData";
import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import isEmptyValue from "utils/isEmpty";

const searchParams = [
  {
    label: "Country Name",
    value: "name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact No",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
];

const GlobalNetworkHOC = (WrappedComponent) => {
  class GlobalNetworkWrappedComponent extends Component {
    state = {
      showEditGlobalNetworkModal: false,
      showCreateGlobalNetworkModal: false,
      branches: [],
      loading: false,

      selectedBranch: {},
      searchParams: searchParams,
      searchValue: "",
      country: "",
      flag_image: "",
      selectedPersonInCharge: "",
      countryInputError: "",
      selectedPersonValue: "",
      flagImageChange: false,

      showConfirmationModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeGlobalNetworkHOC = (value, context) =>
      this.setState({ [context]: value });

    getSelectedBranch = (id) =>
      Get(
        `/admin/global_networks/${id}`,
        this.getSelectedBranchSuccess,
        this.getSelectedBranchError,
        this.load,
      );
    getSelectedBranchSuccess = (payload) =>
      this.setState({
        showEditGlobalNetworkModal: true,
        selectedBranch: payload,
      });
    getSelectedBranchError = (error) => requestError(error);

    updateBranch = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);

      if (dataToSubmit.flag_image.indexOf("https://") > -1) {
        delete temp.flag_image;
      }

      Put(
        `/admin/global_networks/${dataToSubmit.id}`,
        objToFormData(temp, "country"),
        this.updateBranchSuccess,
        this.updateBranchFailed,
        this.load,
      );
    };
    updateBranchSuccess = () => {
      requestSuccess("Branch info updated successfully.");
      this.setState({ showEditGlobalNetworkModal: false });
      this.getGlobalNetwork();
    };
    updateBranchFailed = (error) => requestError(error && error);

    createBranch = (dataToSubmit) =>
      Post(
        `/admin/global_networks`,
        objToFormData(dataToSubmit, "country"),
        this.createBranchSuccess,
        this.createBranchError,
        this.load,
      );
    createBranchSuccess = () => {
      requestSuccess("New branch created successfully.");
      this.setState({ showCreateGlobalNetworkModal: false });
      this.getGlobalNetwork();
    };
    createBranchError = (error) => requestError(error && error);

    removeBranch = (id) =>
      Delete(
        `/admin/global_networks/${id}`,
        this.removeBranchSuccess,
        this.removeBranchError,
        this.load,
      );
    removeBranchSuccess = () => {
      this.getGlobalNetwork();
      this.setState({ showConfirmationModal: false });
      requestSuccess("Branch removed successfully");
    };
    removeBranchError = (error) => requestError(error && error);

    getGlobalNetwork = (search) => {
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      const adminPath =
        role.toLowerCase().indexOf("admin") > -1 ? `/admin` : ``;
      return Get(
        `${adminPath}/global_networks${search ? `?${search}` : ""}`,
        this.getGlobalNetworkSuccess,
        this.getGlobalNetworkError,
        this.load,
      );
    };
    getGlobalNetworkSuccess = (payload) => {
      let tmpData = payload.map((item) => {
        let tmpItem = _.cloneDeep(item);
        delete tmpItem.user;
        delete tmpItem.name;
        return {
          ...tmpItem,
          country: item.name,
          name: !isEmptyValue(item.user) ? item.user.display_name : "N/A",
          email: !isEmptyValue(item.user) ? item.user.email : "N/A",
          phoneNumber: !isEmptyValue(item.user)
            ? item.user.mobile_contact_number
            : "N/A",
          avatar_url: !isEmptyValue(item.user) ? item.user.avatar_url : "N/A",
        };
      });

      this.setState({ branches: tmpData });
    };
    getGlobalNetworkError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          branches={this.state.branches}
          searchParams={this.state.searchParams}
          showConfirmationModal={this.state.showConfirmationModal}
          showCreateGlobalNetworkModal={this.state.showCreateGlobalNetworkModal}
          showEditGlobalNetworkModal={this.state.showEditGlobalNetworkModal}
          selectedBranch={this.state.selectedBranch}
          getSelectedBranch={this.getSelectedBranch}
          createBranch={this.createBranch}
          updateBranch={this.updateBranch}
          removeBranch={this.removeBranch}
          onChangeGlobalNetworkHOC={this.onChangeGlobalNetworkHOC}
          getGlobalNetwork={this.getGlobalNetwork}
          onLoadGlobalNetwork={this.state.loading}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
  })(GlobalNetworkWrappedComponent);
};

export default GlobalNetworkHOC;
