import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import _ from "lodash";

import { Post, Put, Get, Delete } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";

const ReaRenewalManagementHOC = (WrappedComponent) => {
  class ReaRenewalManagementWrappedComponent extends Component {
    state = {
      searchParams: [
        {
          label: "Renewal Year",
          value: "renewal_year_eq",
          type: "input",
          param: "",
        },
        {
          label: "Cut-off Date",
          value: "last_submit_date_gteq",
          type: "date",
          param: "",
        },
        {
          label: "Status",
          value: "status_id_eq",
          type: "radio",
          param: "",
          options: [
            { value: "1", label: "Open" },
            { value: "2", label: "Closed" },
          ],
        },
      ],

      loading: false,
      reaRenewal: {},
      reaRenewalPage: [],
      selectedRenewal: {},
      selectedSection: "License Renewal",

      renewalSettings: {},
      renewalSettingsPage: [],
      selectedRenewalSetting: {},

      showCreateRenewal: false,
      showUpdateRenewal: false,
      showRenewalDetails: false,

      showCreateRenewalSetting: false,
      showUpdateRenewalSetting: false,
      showDeleteRenewalSetting: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRenewalManagementHOC = (val, context) =>
      this.setState({ [context]: val });

    getReaRenewals = (page, search) =>
      Get(
        `/admin/licence_renewals?${search}page=${page}`,
        this.getReaRenewalsSuccess,
        this.getReaRenewalsError,
        this.load,
      );
    getReaRenewalsSuccess = (payload) => {
      let temp = [];
      let tempPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      temp = payload.data.map((item) => {
        return {
          ...item,
          last_submit_date: item.last_submit_date
            ? Moment(item.last_submit_date, "DD/MM/YYYY").format("DD MMM YYYY")
            : "",
          status: item.status ? _.capitalize(item.status) : "",
        };
      });

      this.setState({
        reaRenewal: {
          data: temp,
          meta: payload.meta,
        },
        reaRenewalPage: tempPages,
      });
    };
    getReaRenewalsError = (error) => requestError(error);

    getSelectedReaRenewal = (id, search, modal) =>
      Get(
        `/admin/licence_renewals/${id}${search ? `?${search}` : ""}`,
        (payload) => this.getSelectedReaRenewalSuccess(payload, modal),
        this.getSelectedReaRenewalError,
        this.load,
      );
    getSelectedReaRenewalSuccess = (payload, modal) =>
      this.setState({
        selectedRenewal: {
          ...payload,
          status: payload.status ? _.capitalize(payload.status) : "",
        },
        [modal]: true,
      });
    getSelectedReaRenewalError = (error) => requestError(error);

    getSelectedRenewal = (search) =>
      Get(
        `/admin/licence_renewals/${this.state.selectedRenewal.id}/licence_renewal_applicants?${search}`,
        this.getSelectedRenewalSuccess,
        this.getSelectedRenewalError,
        this.load,
      );
    getSelectedRenewalSuccess = (payload) =>
      this.setState({
        selectedRenewal: {
          ...this.state.selectedRenewal,
          licence_renewal_applicants: payload.data,
        },
      });
    getSelectedRenewalError = (error) => requestError(error);

    createReaRenewal = (dataToSubmit) =>
      Post(
        `/admin/licence_renewals`,
        {
          ...dataToSubmit,
          age_of_senior_rea: Number(dataToSubmit.age_of_senior_rea),
          cpd_minimum_requirement: Number(dataToSubmit.cpd_minimum_requirement),
        },
        this.createReaRenewalSuccess,
        this.createReaRenewalError,
        this.load,
      );
    createReaRenewalSuccess = () => {
      this.getReaRenewals(1, "");
      this.setState({ showCreateRenewal: false });
      requestSuccess("New REA Renewal created.");
    };
    createReaRenewalError = (error) => requestError(error);

    updateReaRenewal = (dataToSubmit) =>
      Put(
        `/admin/licence_renewals/${dataToSubmit.id}`,
        {
          ...dataToSubmit,
          age_of_senior_rea: Number(dataToSubmit.age_of_senior_rea),
          cpd_minimum_requirement: Number(dataToSubmit.cpd_minimum_requirement),
        },
        this.updateReaRenewalSuccess,
        this.updateReaRenewalError,
        this.load,
      );
    updateReaRenewalSuccess = (payload) => {
      this.getSelectedReaRenewal(payload.id);
      this.setState({ showUpdateRenewal: false });
      requestSuccess("REA Renewal updated.");
    };
    updateReaRenewalError = (error) => requestError(error);

    getRenewalSettings = (page, search) =>
      Get(
        `/admin/licence_renewal_settings?${search}page=${page}`,
        this.getRenewalSettingsSuccess,
        this.getRenewalSettingsError,
        this.load,
      );
    getRenewalSettingsSuccess = (payload) => {
      let tempPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        renewalSettings: payload,
        renewalSettingsPage: tempPages,
      });
    };
    getRenewalSettingsError = (error) => requestError(error);

    createRenewalSettings = (dataToSubmit) =>
      Post(
        `/admin/licence_renewal_settings`,
        dataToSubmit,
        this.createRenewalSettingsSuccess,
        this.createRenewalSettingsError,
        this.load,
      );
    createRenewalSettingsSuccess = () => {
      this.getRenewalSettings(1, "");
      this.setState({ showCreateRenewalSetting: false });
      requestSuccess("New REA Renewal Setting created.");
    };
    createRenewalSettingsError = (error) => requestError(error);

    updateRenewalSettings = (dataToSubmit) =>
      Put(
        `/admin/licence_renewal_settings/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateRenewalSettingSuccess,
        this.updateRenewalSettingError,
        this.load,
      );
    updateRenewalSettingSuccess = () => {
      this.getRenewalSettings(1, "");
      this.setState({ showUpdateRenewalSetting: false });
      requestSuccess("REA Renewal Setting updated.");
    };
    updateRenewalSettingError = (error) => requestError(error);

    deleteRenewalSettings = (id) =>
      Delete(
        `/admin/licence_renewal_settings/${id}`,
        this.deleteRenewalSettingsSuccess,
        this.deleteRenewalSettingsError,
        this.load,
      );
    deleteRenewalSettingsSuccess = () => {
      this.getRenewalSettings(1, "");
      this.setState({ showDeleteRenewalSetting: false });
      requestSuccess("REA Renewal Setting deleted.");
    };
    deleteRenewalSettingsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadReaRenewal={this.state.loading}
            reaRenewal={this.state.reaRenewal}
            reaRenewalPage={this.state.reaRenewalPage}
            renewalSettings={this.state.renewalSettings}
            renewalSettingsPage={this.state.renewalSettingsPage}
            searchParams={this.state.searchParams}
            selectedSection={this.state.selectedSection}
            selectedRenewal={this.state.selectedRenewal}
            selectedRenewalSetting={this.state.selectedRenewalSetting}
            showCreateRenewal={this.state.showCreateRenewal}
            showUpdateRenewal={this.state.showUpdateRenewal}
            showRenewalDetails={this.state.showRenewalDetails}
            showCreateRenewalSetting={this.state.showCreateRenewalSetting}
            showUpdateRenewalSetting={this.state.showUpdateRenewalSetting}
            showDeleteRenewalSetting={this.state.showDeleteRenewalSetting}
            getReaRenewals={this.getReaRenewals}
            createReaRenewal={this.createReaRenewal}
            updateReaRenewal={this.updateReaRenewal}
            getSelectedRenewal={this.getSelectedRenewal}
            getRenewalSettings={this.getRenewalSettings}
            createRenewalSettings={this.createRenewalSettings}
            updateRenewalSettings={this.updateRenewalSettings}
            deleteRenewalSettings={this.deleteRenewalSettings}
            getSelectedReaRenewal={this.getSelectedReaRenewal}
            onChangeRenewalManagementHOC={this.onChangeRenewalManagementHOC}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {})(ReaRenewalManagementWrappedComponent);
};

export default ReaRenewalManagementHOC;
