import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./assets/stylesheets/light-bootstrap-dashboard.scss";
import "./stylesheets/index.scss";
import CustomRouter from "./router";
import FontManager from "./components/FontManager";
import { LocalizationProvider } from "context/localisation";
// import registerServiceWorker from './registerServiceWorker';
import { InitServiceWorker } from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

import configureStore from "./store/config";
import { initializeFirebase } from "./utils/firebase";

const store = configureStore();
const persistor = persistStore(store);

// not possible for remotion to be placed double root
// with the app root tree view
// import { registerRoot } from "remotion";
// import { RemotionRoot } from "./remotion/Root";
//
// registerRoot(RemotionRoot);

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <FontManager />
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider>
        <HashRouter>
          <CustomRouter />
        </HashRouter>
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
);

initializeFirebase();
InitServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
