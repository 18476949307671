import React, { Component } from "react";

import PermissionsTable from "./Table";
import LoadingModal from "components/LoadingModal";

const HeaderData = [
  { label: "Module Name", value: "module_name", width: "100%" },
  { label: "Create", value: "can_create", width: "16%" },
  { label: "Read", value: "can_read", width: "16%" },
  { label: "Update", value: "can_update", width: "16%" },
  { label: "Delete", value: "can_destroy", width: "16%" },
];

class Permissions extends Component {
  render = () => {
    return (
      <>
        <PermissionsTable
          headerData={HeaderData}
          className={"mb-100"}
          pagination={true}
          rowData={this.props.permissions || []}
          originalRowData={this.props.permissions || []}
          onClose={this.props.onClose}
          onClickSubmit={() =>
            this.props.onChangeEditHOC(true, "showConfirmationModal")
          }
          onToggleCheckBox={this.props.onToggleCheckBox}
        />
        {this.props.onLoadPermission && <LoadingModal />}
      </>
    );
  };
}

export default Permissions;
