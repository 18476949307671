import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { BsTelegram } from "react-icons/bs";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import AtlasSnackbar from "components/Snackbar";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ElectricalTNCContent from "./components/electricalContent";
import AppointmentDialog from "./AppointmentLetter";
import ProfileCard from "./components/profileCard";
import NDAContent from "./components/ndaContent";

import ProfileUpdate from "../ProfileUpdate";
import ChangePassword from "../ProfileUpdate/ChangePassword";
import DigitalSignContent from "../ProfileUpdate/DigitalSign";

import { initGA } from "utils/ga";
import ProfileHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import "stylesheets/containers/profile/index.scss";

class Profile extends Component {
  componentDidMount = () => {
    initGA("/dashboard/profile");

    if (window.location.href.includes("signNDA")) {
      this.props.onChangeProfileHOC(true, "showForceNDAModal");
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.data.profileReducer !== this.props.data.profileReducer) {
      const { encoded_identity, role_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      this.props.getUnitAdminProfile(encoded_identity, role_id);
    }
  };

  toggleCopySnackBar = (val) => {
    navigator.clipboard.writeText(val);
    this.props.onChangeProfileHOC(
      !this.props.showCopySnackBar,
      "showCopySnackBar"
    );
  };

  renderIQIQR = () => {
    const { qr_code_image_url } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div className="d-flex flex-column align-items-center">
        <img src={qr_code_image_url} style={{ width: 250, height: 250 }} />
      </div>
    );
  };

  renderAgentQR = () => {
    const { identity_qr_code_image_url, iqi_profile_page } =
      window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    return (
      <div className="d-flex flex-column align-items-center">
        <img
          style={{ width: 250, height: 250 }}
          src={identity_qr_code_image_url}
        />
        <div className="d-flex flex-row align-items-center mt-3 g-2">
          <button
            className="btn-new at-profile__public-profile-action"
            onClick={() => window.open(iqi_profile_page, "_blank")}
          >
            <AtlasIcon
              svgHref={"atlas-link-2-linear"}
              style={{ backgroundColor: "#3B82F6", borderRadius: "50%" }}
            />
            Open Agent Profile
          </button>
          <button
            className="btn-new at-profile__public-profile-action"
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?text=Hi.%20Click%20this%20to%20see%20my%20global%20real%20estate%20listings%20and%20my%20profile%0A${iqi_profile_page}.`
              );
            }}
          >
            <AtlasIcon
              svgHref={"atlas-whatsapp"}
              style={{ backgroundColor: "#12B76A", borderRadius: "50%" }}
            />
            Share To Whatsapp
          </button>
          <button
            className="btn-new at-profile__public-profile-action"
            onClick={() => {
              window.open(
                `https://telegram.me/share/url?url=Hi.%20Click%20this%20to%20see%20my%20global%20real%20estate%20listings%20and%20my%20profile%0A${iqi_profile_page}.`
              );
            }}
          >
            <BsTelegram
              style={{ backgroundColor: "#25A3E0", borderRadius: "50%" }}
            />
            Share To Telegram
          </button>
        </div>
      </div>
    );
  };

  renderReferralContent = () => {
    const { referrer_code } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const referralLink =
      window.location.host.indexOf("atlas.iqiglobal.com") !== -1
        ? `https://atlas.iqiglobal.com/#/register/ref=${referrer_code}`
        : `http://${window.location.host}/#/register/ref=${referrer_code}`;

    return (
      <div className={"d-flex flex-column align-items-center"}>
        <p style={{ fontSize: 14, color: "#1F2937", wordBreak: "break-all" }}>
          {referralLink}
        </p>
        <button
          className="btn-new btn-new--primary mt-3"
          onClick={() => this.toggleCopySnackBar(referralLink)}
        >
          <AtlasIcon
            svgHref={"atlas-copy"}
            style={{ width: 20, height: 20, marginRight: 8, fill: "white" }}
          />
          Copy Link
        </button>
      </div>
    );
  };

  render = () => {
    const {
      showNDAModal,
      showIQIID,
      showCopySnackBar,
      showAgentQR,
      showReferalAgent,
      showForceNDAModal,
      showDownloadModal,
      showProfileUpdate,
      showNameCardUpdate,
      showAppointmentLetterModal,
      showDrawingBoard,
      selectedAgent,
      selectedSection,
      showElectricalTNC,
      NDAgreement,
      cp58List,
      cp58Year,

      history,
      onLoadAppointmentLetter,
      appointmentLetterRecipient,
      appointmentModalContentTypeId,

      getCP58,
      sendEmail,
      downloadDoc,
      downloadCP58,
      checkDigitalSign,
      onChangeProfileHOC,
    } = this.props;

    const { id, role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const sections =
      role === "Agent"
        ? ["Profile", "Digital Signature", "Change Password"]
        : ["Profile", "Change Password"];

    return (
      <>
        <ModuleHeader title={"Profile"} atlasIcon={"atlas-frame"} />
        <CustomCard
          cardContent={
            <>
              <CustomTab
                sections={sections}
                selectedSection={selectedSection}
                onSelectSection={(val) =>
                  onChangeProfileHOC(val, "selectedSection")
                }
              />
              {selectedSection === "Profile" && (
                <ProfileCard
                  data={this.props.data}
                  history={history}
                  cp58Year={cp58Year}
                  cp58List={cp58List}
                  selectedAgent={selectedAgent}
                  showDownloadModal={showDownloadModal}
                  onLoadAppointmentLetter={onLoadAppointmentLetter}
                  onClickAppointmentLetter={() => {
                    this.props.onChangeProfileHOC(
                      true,
                      "showAppointmentLetterModal"
                    );
                  }}
                  getCP58={getCP58}
                  downloadCP58={downloadCP58}
                  checkDigitalSign={checkDigitalSign}
                  onChangeProfileHOC={this.props.onChangeProfileHOC}
                />
              )}
              {selectedSection === "Digital Signature" && (
                <DigitalSignContent />
              )}
              {selectedSection === "Change Password" && <ChangePassword />}
            </>
          }
        />
        <AtlasSnackbar
          open={showCopySnackBar}
          handleClose={() =>
            this.props.onChangeProfileHOC(false, "showCopySnackBar")
          }
          message={"Data copied into clipboard. You can now paste the data."}
        />
        <AppointmentDialog
          data={this.props.data}
          onLoadAppointmentLetter={onLoadAppointmentLetter}
          appointmentLetterRecipient={appointmentLetterRecipient}
          appointmentModalContentTypeId={appointmentModalContentTypeId}
          showAppointmentLetterModal={showAppointmentLetterModal}
          sendEmail={sendEmail}
          downloadDoc={downloadDoc}
          onChangeProfileHOC={onChangeProfileHOC}
        />
        {showElectricalTNC && (
          <ModalDialog
            title={"Electrical T&C"}
            maxWidth={"md"}
            children={
              <ElectricalTNCContent
                onClickAccept={() => {
                  Promise.all([onChangeProfileHOC(false, "showElectricalTNC")]);
                  onChangeProfileHOC(true, "showNDAModal");
                }}
                onClickClose={() =>
                  this.props.onChangeProfileHOC(false, "showElectricalTNC")
                }
              />
            }
            onClose={() => onChangeProfileHOC(false, "showElectricalTNC")}
          />
        )}
        {showNDAModal && (
          <ModalDialog
            title={"NDA Agreement"}
            maxWidth={"lg"}
            fullHeight={true}
            fullWidth={true}
            responsiveSize="lg"
            children={
              <NDAContent
                content={NDAgreement}
                onLoadAppointmentLetter={onLoadAppointmentLetter}
                elementId={`agent-signature-${id}`}
                contractId={`agent-signature2-${id}`}
                onClickClose={() =>
                  this.props.onChangeProfileHOC(false, "showNDAModal")
                }
                onClickSubmitSign={this.props.createDigitalSign}
              />
            }
            onClose={() => onChangeProfileHOC(false, "showNDAModal")}
          />
        )}
        {showAgentQR && (
          <ModalDialog
            title={"My Public Profile"}
            maxWidth={"md"}
            children={this.renderAgentQR()}
            onClose={() => onChangeProfileHOC(false, "showAgentQR")}
          />
        )}
        {showIQIID && (
          <ModalDialog
            title={"My IQI ID"}
            maxWidth={"md"}
            children={this.renderIQIQR()}
            onClose={() => onChangeProfileHOC(false, "showIQIID")}
          />
        )}
        {showReferalAgent && (
          <ModalDialog
            title={"My Referral Link"}
            maxWidth={"md"}
            children={this.renderReferralContent()}
            onClose={() => onChangeProfileHOC(false, "showReferalAgent")}
          />
        )}
        {showProfileUpdate && (
          <ModalDialog
            title={"Edit Profile"}
            fullWidth={true}
            fullHeight={true}
            children={
              <ProfileUpdate
                from="Profile"
                onClose={() => onChangeProfileHOC(false, "showProfileUpdate")}
              />
            }
            onClose={() => onChangeProfileHOC(false, "showProfileUpdate")}
          />
        )}
        {showNameCardUpdate && (
          <ModalDialog
            title={"Name Card"}
            fullWidth={true}
            fullHeight={true}
            children={
              <ProfileUpdate
                from="NameCard"
                onClose={() => onChangeProfileHOC(false, "showNameCardUpdate")}
              />
            }
            onClose={() => onChangeProfileHOC(false, "showNameCardUpdate")}
          />
        )}
        {showForceNDAModal && (
          <ModalDialog
            responsiveSize="sm"
            maxWidth="sm"
            children={
              <div className="d-flex flex-column justify-content-center">
                <div className="at-confirmation_modal-icon">
                  <AtlasIcon
                    svgHref={"atlas-info-circle"}
                    style={{ fill: "#3B82F6", backgroundColor: "#DBEAFE" }}
                  />
                </div>
                <span className={"fs-2 mt-10 mb-20 text-center"}>
                  To ensure seamless access to Atlas, please sign your
                  Appointment Letter. This requirement applies to both new and
                  existing agents. Take a moment to review and sign your{" "}
                  <b>REN/PEA Appointment Letter</b> to proceed.
                </span>
                <button
                  className="btn-new btn-new--primary w-100 mt-3"
                  onClick={() => {
                    onChangeProfileHOC(false, "showForceNDAModal");
                    checkDigitalSign();
                  }}
                >
                  Continue
                </button>
              </div>
            }
          />
        )}
        {this.props.data.ajaxStatusReducer.ajaxCallProgress > 0 && (
          <LoadingModal />
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default compose(
  connect(mapStateToProps),
  ProfileHOC,
  VersionCheckHOC
)(Profile);
