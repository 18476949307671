import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import CustomCard from "components/Card";
import CustomFormInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";
import CustomTextarea from "components/Input/textarea";
import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";

import EditAnnouncementHOC from "./actions/edit";
import VersionCheckHOC from "actions/versionCheck";

class Edit extends Component {
  componentDidMount = () =>
    this.props.onChangeUpdateHOC(
      this.props.selectedAnnouncement,
      "selectedEditAnnouncement",
    );

  onChangeField = (val, context) => {
    let temp = _.cloneDeep(this.props.selectedEditAnnouncement);
    temp[context] = val;
    this.props.onChangeUpdateHOC(temp, "selectedEditAnnouncement");
  };

  onClickSubmitUpdatedAnnouncement = () => {
    const { id, title, content, published } =
      this.props.selectedEditAnnouncement;
    let dataToSubmit = {
      id: id,
      announcement: {
        title: title,
        content: content,
        published: published,
      },
    };
    this.props.updateAnnouncement(dataToSubmit);
  };

  renderCardContent = () => {
    const { title, content, published } = this.props.selectedEditAnnouncement;
    return (
      <>
        <AtlasCloseButton
          containerStyle={{ marginBottom: 10 }}
          onClick={() =>
            this.props.onChangeAnnouncementHOC(false, "showEditModal")
          }
        />
        <h4 className="at-card__title mb-20">Edit Announcement</h4>
        <hr />
        <form onSubmit={(e) => e.preventDefault()}>
          <h3 className="at-form-input__title" required>
            Title
          </h3>
          <CustomFormInput
            type={"text"}
            value={title}
            onChangeValue={(val) => this.onChangeField(val, "title")}
            required={true}
            inputError={""}
            placeholder={"Enter your announcement title here"}
          />
          <CustomCheckbox
            labelStyle={{ marginBottom: "20px" }}
            checked={published}
            content={"Publish"}
            onChangeCheckboxValue={(val) =>
              this.onChangeField(val, "published")
            }
          />
          <br />
          <h3 className="at-form-input__title" required>
            Content
          </h3>
          <CustomTextarea
            value={content}
            placeholder={"Write your description here"}
            onChangeValue={(val) => this.onChangeField(val, "content")}
            required={true}
            inputError={""}
          />
          <div className={`d-flex`}>
            <CustomButton
              className={"btn-new btn-new--primary at-btn--success mr-10"}
              onClick={() => this.onClickSubmitUpdatedAnnouncement()}
              children={"Update"}
            />
          </div>
        </form>
      </>
    );
  };

  render = () => {
    return (
      <>
        <CustomCard cardContent={this.renderCardContent()} />
        {(this.props.onLoadAnnouncement || this.props.onLoadEdit) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(EditAnnouncementHOC, VersionCheckHOC)(Edit);
