import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const resetBooking = {
  meeting_room_id: "",
  event_subject: "",
  start_time: "",
  end_time: "",
  duration: 0,
  add_ons: []
};

export const searchParams = (branches) => {
  return [
    {
      label: "Date Time",
      value: "search_from",
      type: "date_time",
      default: Moment(),
      minDate: Moment(),
      param: Moment(),
      param: "",
    },
    {
      label: "Location",
      value: "branch_id_eq",
      type: "select",
      param: "",
      options: branches,
    },
    {
      label: "Status",
      value: "status_eq",
      type: "radio",
      param: "Available",
      options: [
        { value: "All", label: "All" },
        { value: "Available", label: "Available" },
        { value: "In Use", label: "In Use" },
      ],
    },
  ];
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showBookingForm: false,
      showPaymentModal: false,
      showTermsAndConditions: false,
      showCreateBookingModal: false,
      showPaymentConfirmation: false,
      showPaymentFailedModal: false,
      disableBook: true,
      showEditModal: false,
      roomList: [],
      businessList: [],
      calendarEvent: [],
      selectedRoom: {},
      paymentDetails: {
        amount: 0,
        cust_ip: "",
        merchant_name: "",
        order_number: "",
        payment_desc: "",
        payment_id: "",
        service_id: "",
        order_id: "",
      },
      newBooking: {
        is_have_read_terms_and_conditions: false,
        meeting_room_id: "",
        event_subject: "",
        purpose: "",
        start_time: "",
        end_time: "",
        duration: 0,
        add_ons: [],
      },
      searchParams: searchParams(this.props.branches),
    };

    load = (param) => this.setState({ loading: param });

    onChangeMeetingHOC = (val, context) => this.setState({ [context]: val });

    setSearchDate = () => {
      let temp = _.cloneDeep(this.state.searchParams);
      let searchDateIndex = _.findIndex(temp, { label: "Date Time" });
      if (!temp[searchDateIndex].param) {
        temp[searchDateIndex].param = Moment().format("YYYY-MM-DDTHH:mm");
      }
      this.setState({ searchParams: temp });
    };

    componentDidMount = () => {
      this.setSearchDate();
      this.getMeetingRoom();
    };

    dayViewCalendarObj = (
      bookings,
      startDate,
      operating_start_time,
      operating_end_time,
    ) => {
      let calendarLog = [];
      let currentTime = startDate.set({
        hour: Moment(operating_start_time, "hh:mm a").format("HH"),
        minute: Moment(operating_start_time, "hh:mm a").format("mm"),
        second: 0,
      });
      let calendarEndTime = Moment(startDate)
        .set({
          hour: Moment(operating_end_time, "hh:mm a").format("HH"),
          minute: Moment(operating_end_time, "hh:mm a").format("mm"),
          second: 0,
        })
        .subtract(30, "minute");

      for (let i = 0; i < bookings.length; i++) {
        if (i === 0 && Moment(bookings[i].start_time) > currentTime) {
          while (currentTime < Moment(bookings[i].start_time)) {
            let tmp = _.cloneDeep(currentTime);
            calendarLog.push({
              subject_event: "",
              start_time: tmp,
              end_time: Moment(tmp)
                .add(30, "minute")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              status: "Available",
            });
            currentTime.add(30, "minute");
          }
        }
        calendarLog.push({
          ...bookings[i],
          formattedStartTime: Moment(bookings[i].start_time).format("h:mmA"),
          formattedEndTime: Moment(bookings[i].end_time).format("h:mmA"),
        });
        currentTime = Moment(bookings[i].end_time);
        if (i < bookings.length - 1) {
          let nextTime = Moment(bookings[i + 1].start_time);
          while (currentTime < nextTime && currentTime < calendarEndTime) {
            let tmp = currentTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
            calendarLog.push({
              subject_event: "",
              start_time: tmp,
              end_time: Moment(tmp)
                .add(30, "minute")
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              status: "Available",
            });
            currentTime.add(30, "minute");
          }
        }
      }
      if (
        bookings.length > 0 &&
        Moment(bookings[bookings.length - 1].start_time).isSame(currentTime)
      ) {
        currentTime = Moment(bookings[bookings.length - 1].end_time);
      }
      while (currentTime.isSameOrBefore(calendarEndTime)) {
        let tmp = currentTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        calendarLog.push({
          subject_event: "",
          start_time: tmp,
          end_time: Moment(tmp)
            .add(30, "minute")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          status: "Available",
        });
        currentTime.add(30, "minute");
      }
      return calendarLog;
    };

    bigCalendarViewObj = (booking) => {
      let tmp = [];
      booking.map((item) => {
        tmp.push({
          title: item.event_subject,
          start: Moment(item.start_time).toDate(),
          end: Moment(item.end_time).toDate(),
        });
      });
      return tmp;
    };

    filterMeetingRoom = (id, search) =>
      Get(
        `/meeting_rooms?${search || "q[status_eq]=Available&"}identity_id=${id}`,
        this.filterMeetingRoomSucccess,
        this.filterMeetingRoomFailed,
        this.load,
      );

    filterMeetingRoomSucccess = (payload) => {
      let tmp = [];
      if (payload.data && payload.data.length > 0) {
        payload.data.map((item) => {
          let bookings = _.cloneDeep(item.bookings);
          let temp = _.cloneDeep(this.state.searchParams);
          let searchDateIndex = _.findIndex(temp, { value: "search_from" });
          let startDate =
            temp[searchDateIndex].param === ""
              ? Moment()
              : Moment(temp[searchDateIndex].param);
          let calendarLogs = this.dayViewCalendarObj(
            bookings,
            startDate,
            item.operating_start_time,
            item.operating_end_time,
          );
          tmp.push({
            ...item,
            roomEvent: calendarLogs,
          });
        });
      }
      this.setState({
        businessList: tmp.filter((item) => item.meeting_room_type_id === 2 ),
        roomList: tmp.filter((item) => item.meeting_room_type_id === 1 ),
      });
    };
    filterMeetingRoomFailed = (error) => requestError(error);

    getMeetingRoom = () =>
      Get(
        "/meeting_rooms?q[status_eq]=Available",
        this.getMeetingRoomSucccess,
        this.getMeetingRoomFailed,
        this.load,
      );

    getMeetingRoomSucccess = (payload) => {
      let tmp = [];
      if (payload.data && payload.data.length > 0) {
        payload.data.map((item) => {
          let bookings = _.cloneDeep(item.bookings);
          let calendarLogs = this.dayViewCalendarObj(
            bookings,
            Moment(),
            item.operating_start_time,
            item.operating_end_time,
          );
          tmp.push({
            ...item,
            roomEvent: calendarLogs,
          });
        });
      }
      this.setState({
        businessList: tmp.filter((item) => item.meeting_room_type_id === 2 ),
        roomList: tmp.filter((item) => item.meeting_room_type_id === 1 ),
      });
    };
    getMeetingRoomFailed = (error) => requestError(error);

    createBooking = (submitData) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;
      Post(
        `/${["Super Admin", "Admin"].indexOf(role) > -1 ? "admin/" : ""}bookings`,
        {
          ...submitData,
          end_time: Moment(submitData.end_time).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
          ),
          start_time: Moment(submitData.start_time).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
          ),
        },
        this.createBookingSuccess,
        this.createBookingError,
        this.load,
      );
    }
    createBookingSuccess = (payload) => {
      let tmp = "";
      this.state.searchParams.map((item) => {
        tmp =
          item.param && item.param !== ""
            ? item.value
              ? tmp +
                `q[${item.value}]=${
                  item.type === "typeahead"
                    ? item.param.name
                    : item.type === "date_time"
                      ? Moment(item.param).format("YYYY-MM-DDTHH:mm")
                      : item.param
                }&`
              : tmp + `q${item.param}&`
            : tmp;
      });
      this.filterMeetingRoom("", tmp);
      this.setState({
        newBooking: resetBooking,
        disableBook: true,
        paymentDetails: {
          ...this.state.paymentDetails,
          order_id: payload.order_info?.id || "",
          payment_id: payload.order_info?.id || "",
          payment_desc: `Booking Payment for Business Lounge`,
          amount: payload.order_info?.total_amount || 0,
        },
        showCreateBookingModal: false,
      }, () => {
        if (!_.isEmpty(payload.order_info)) {
          this.makePayment({
            provider: "Ipay88",
            type: "Booking",
            amount: payload.order_info?.total_amount || 0,
            description: `Booking Payment for Business Lounge`,
            user_id: payload.user_id,
            order_id: payload.order_info?.id || "",
            product_id: payload.id|| "",
          })
        }
      });
      requestSuccess("New Meeting Booked successfully.");
    };
    createBookingError = (error) => requestError(error);

    getCalendarEvent = (id, date) =>
      Get(
        `/meeting_rooms/${id}?booking_date=${date}`,
        this.getCalendarEventSuccess,
        this.getCalendarEventError,
        this.load,
      );
    getCalendarEventSuccess = (payload) => {
      let tmp = [];
      if (payload.bookings && payload.bookings.length > 0) {
        tmp = this.bigCalendarViewObj(payload.bookings);
      }
      this.setState({ calendarEvent: tmp });
    };
    getCalendarEventError = (error) => requestError(error);

    onCloseBookingForm = () => {
      this.setState({
        disableBook: true,
        showCreateBookingModal: false,
        newBooking: resetBooking,
      });
    };

    makePayment = (data) =>
      Post(
        `/payments`,
        data,
        this.makePaymentSuccess,
        this.makePaymentError,
        this.load,
      );
    makePaymentSuccess = (payload) => {
      this.setState({
        paymentDetails: {
          ...this.state.paymentDetails,
          ...payload,
        },
        showPaymentModal: true
      });
    };
    makePaymentError = (error) => requestError(error);

    cancelPayment = () =>
      Delete(
        `/payments/${this.state.paymentDetails.payment_id}`,
        this.cancelPaymentSuccess,
        this.cancelPaymentError,
        this.load,
      );
    cancelPaymentSuccess = () => this.setState({ showPaymentModal: false, showPaymentFailedModal: true });
    cancelPaymentError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            roomList={this.state.roomList}
            businessList={this.state.businessList}
            calendarEvent={this.state.calendarEvent}
            selectedRoom={this.state.selectedRoom}
            newBooking={this.state.newBooking}
            searchParams={this.state.searchParams}
            paymentDetails={this.state.paymentDetails}
            showBookingForm={this.state.showBookingForm}
            showPaymentModal={this.state.showPaymentModal}
            showCreateBookingModal={this.state.showCreateBookingModal}
            showPaymentConfirmation={this.state.showPaymentConfirmation}
            showPaymentFailedModal={this.state.showPaymentFailedModal}
            onLoadMeetingRooms={this.state.loading}
            disableBook={this.state.disableBook}
            showEditModal={this.state.showEditModal}
            showTermsAndConditions={this.state.showTermsAndConditions}
            filterMeetingRoom={this.filterMeetingRoom}
            createBooking={this.createBooking}
            makePayment={this.makePayment}
            cancelPayment={this.cancelPayment}
            getCalendarEvent={this.getCalendarEvent}
            onCloseBookingForm={this.onCloseBookingForm}
            onChangeMeetingHOC={this.onChangeMeetingHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
