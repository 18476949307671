import React, { Component } from "react";
import _ from "lodash";
import { persistStore } from "redux-persist";
import { connect } from "react-redux";
import { requestError } from "utils/requestHandler";

import { Delete } from "utils/axios";
import { getItem, clearItem } from "utils/tokenStore";

const TemplateHOC = (WrappedComponent) => {
  class TemplateWrappedComponent extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    sendLogoutRequest = () =>
      Delete(
        `/logout?firebase_token=${getItem("IQI_ATLAS_FIREBASE_TOKEN")}`,
        this.sendLogoutRequestSuccess,
        this.sendLogoutRequestError,
        this.load
      );
    sendLogoutRequestSuccess = () => {
      document.cookie = "";
      persistStore(this.props).purge();
      clearItem("IQI_ATLAS_JWT_TOKEN");
      clearItem("IQI_ATLAS_REFRESH_TOKEN");
      clearItem("IQI_ATLAS_FIREBASE_TOKEN");
      clearItem("IQI_ATLAS_JWT_AGENT_TOKEN");
      clearItem("IQI_ATLAS_REFRESH_AGENT_TOKEN");
      this.props.history.push("/login");
    };
    sendLogoutRequestError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          sendLogoutRequest={this.sendLogoutRequest}
          onLoadLogoutRequest={this.state.loading}
        />
      );
    };
  }
  return connect()(TemplateWrappedComponent);
};

export default TemplateHOC;
