import CustomCheckbox from "components/Checkbox";
import CustomFormInput from "components/Input/formInput";
import CustomUpload from "components/Upload";

import { convertImgToBase64URL } from "utils/imgBase64";

const Photo =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/namecard-template-photo.png";
const NonePick =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/namecard-template.png";

export default function NameCard({
  name_card_attributes,
  mobile_contact_number,
  job_title_display_name,
  license_number,

  onChangeState,
}) {
  const {
    photo,
    include_profile_photo,
    name_card_display_name,
    additional_display_name,
    mobile_number,
    qualification,
  } = name_card_attributes;

  return (
    <>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">Sample of Name Card</h2>
        {!include_profile_photo && (
          <img
            src={NonePick}
            alt="none-pick"
            style={{ width: 531, paddingBottom: 16 }}
          />
        )}
        {include_profile_photo && (
          <img
            src={Photo}
            alt="namecard-template"
            style={{ width: 531, paddingBottom: 16 }}
          />
        )}
        <h2 className="at-form-input__title mt-10">Namecard Option</h2>
        <CustomCheckbox
          content={"Photo"}
          checked={include_profile_photo}
          onChangeCheckboxValue={() =>
            onChangeState(
              !include_profile_photo,
              "name_card_attributes",
              null,
              "include_profile_photo",
            )
          }
        />
      </section>
      <section className="grid-half-col">
        {include_profile_photo && (
          <>
            <h2 className="at-form-input__title">
              Click to upload an image for Name Card.
            </h2>
            <CustomUpload
              accepts={"image/png,image/jpg,image/jpeg"}
              className="at-profile__main-avatar"
              imageUrl={photo}
              uploadImage={(fileVal) => {
                onChangeState(fileVal, "tempFile");
                onChangeState(true, "loading");

                convertImgToBase64URL(
                  fileVal,
                  (val) => onChangeState(val, "selectedEdit"),
                  "img/png",
                );
              }}
            />
          </>
        )}
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">Name on card</h2>
        <CustomFormInput
          type="text"
          value={name_card_display_name}
          onChangeValue={(val) =>
            onChangeState(
              val,
              "name_card_attributes",
              null,
              "name_card_display_name",
            )
          }
          required={true}
          placeholder={"Enter your namecard display name"}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">
          Name in local language ( optional )
        </h2>
        <CustomFormInput
          type="text"
          value={additional_display_name}
          onChangeValue={(val) =>
            onChangeState(
              val,
              "name_card_attributes",
              null,
              "additional_display_name",
            )
          }
          placeholder={"李安安, इरफान खान, Phúc Dũng"}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">Primary Mobile Number</h2>
        <CustomFormInput
          type="number"
          value={mobile_contact_number}
          disabled={true}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">
          Additional Mobile Number( optional )
        </h2>
        <CustomFormInput
          type="number"
          value={`${mobile_number}`}
          onChangeValue={(val) =>
            onChangeState(val, "name_card_attributes", null, "mobile_number")
          }
          placeholder={"Enter your namecard display name in chinese"}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">
          Title of Academy Achievement (
          {50 - (qualification ? qualification.length : 0)}/50)
        </h2>
        <CustomFormInput
          type="text"
          value={qualification || ""}
          onChangeValue={(val) => {
            if (val.length < 51) {
              onChangeState(val, "name_card_attributes", null, "qualification");
            }
          }}
          placeholder={"BSc Engineering, BA Imperial College, MA Finance"}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">Designation</h2>
        <CustomFormInput
          type="text"
          value={job_title_display_name || ""}
          disabled={true}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title">Board Reg. No</h2>
        <CustomFormInput
          type="text"
          value={license_number || ""}
          disabled={true}
        />
      </section>
    </>
  );
}
