import React from 'react'

import AtlasTable from 'components/NewTable'
import AtlasIcon from 'components/Icon/atlasIcon'
import CustomModuleHeader from 'components/Form/header'

import { ticketTypeColumnData } from "../assets";
import permissionsChecker from "utils/permissionsChecker";

const TicketManagement = ({
  data,
  ticketTypeList,

  getTicketTypeDetail,
  onChangeEditEventHOC,
}) => {
  const { can_create, can_update, can_destroy } = permissionsChecker(
    "Company Events",
    data,
  );

  return (
    <>
      <AtlasTable
        hideSearch={ false }
        className={ 'mb-100' }
        columns={ ticketTypeColumnData }
        pagination={ true }
        rowData={ ticketTypeList || [] }
        renderTabFilter={ () => (
          <div className='at-table__head-title flex-wrap g-3'>
            <CustomModuleHeader
              title={ 'Ticket Management' }
              moduleIcon={ 'atlas-ticket' } />
            <button
              disabled={ !can_create }
              className="btn-new btn-new--primary"
              onClick={ () => onChangeEditEventHOC( true, 'showCreateTicketType' ) }>
              <AtlasIcon svgHref={ 'atlas-add-linear' } style={{ marginRight: 0 }} />
              New Ticket
            </button>
          </div>
        ) }
        actionColumnContent={[
          {
            name: "edit",
            onShow: () => can_update,
            onClick: (rowData) => getTicketTypeDetail(rowData.id),
          },
          {
            name: "delete",
            color: "#F04438",
            onShow: () => can_destroy,
            onClick: (rowData) => {
              onChangeEditEventHOC(true, "showDeleteTicketTypeConfirmation");
              onChangeEditEventHOC(rowData.id, "toBeDeletedId");
            },
          },
        ]}
      />
    </>
  );
};

export default TicketManagement;
