import { TOGGLE_SIDE_MENU } from "actions/type";

let initialState = {
  sideMenuExpand: true,
};

export default function sideMenuReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuExpand: action.payload,
      };
    default:
      return state;
  }
}
