import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get, Post } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      showRedeemConfirmation: false,
      showRedeemNameCardDialog: false,

      showProfileUpdate: false,
      searchParams: searchParams,
      myCoupons: {},
      myCouponsPage: null,
      selectedCoupon: {
        market_product: {},
      },
      can_update: false,

      post_wip: false, // indicate if an post api is in processing
    };

    load = (param) => this.setState({ loading: param });

    onChangeMyCouponHOC = (val, context) => this.setState({ [context]: val });

    getMyCoupons = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/market_coupons?${search}page=${page}`,
        this.getMyCouponsSuccess,
        this.getMyCouponsError,
        this.load,
      );
    };
    getMyCouponsSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.map((item) => {
        tempData.push({
          ...item,
          productTypeId: item.market_product.type_id,
        });
      });
      payload.data = this.setState({
        myCoupons: {
          data: tempData,
          meta: payload.meta,
        },
        myCouponsPage: tmpTotalPages,
      });
    };
    getMyCouponsError = (error) => requestError(error);

    getSelectedCoupon = (id) =>
      Get(
        `/market_coupons/${id}`,
        this.getSelectedCouponSuccess,
        this.getSelectedCouponError,
        this.load,
      );
    getSelectedCouponSuccess = (payload) =>
      this.setState({
        selectedCoupon: payload,
        showRedeemNameCardDialog: true,
      });
    getSelectedCouponError = (error) => requestError(error);

    redeemCoupon = ({ id, shirtSize, shirtGender, branch_id }) => {
      if (this.state.post_wip) return;

      this.setState(
        {
          post_wip: true,
        },
        () => {
          Post(
            `/market_coupons/${id}/redeem`,
            {
              id: id,
              t_shirt_size: shirtSize,
              t_shirt_gender: shirtGender,
              delivery_branch_id: branch_id,
            },
            this.redeemCouponSuccess,
            this.redeemCouponError,
            this.load,
          );
        },
      );
    };
    redeemCouponSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getMyCoupons(currentPage, searchParams);
      this.setState({
        post_wip: false,
        showRedeemConfirmation: false,
        showRedeemNameCardDialog: false,
      });
      requestSuccess(payload.message);
    };
    redeemCouponError = (error) => {
      requestError(error);
      this.setState({ post_wip: false });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            post_wip={this.state.post_wip}
            can_update={this.state.can_update}
            onLoadMyCoupon={this.state.loading}
            myCoupons={this.state.myCoupons}
            myCouponsPage={this.state.myCouponsPage}
            searchParams={this.state.searchParams}
            selectedCoupon={this.state.selectedCoupon}
            showProfileUpdate={this.state.showProfileUpdate}
            showRedeemConfirmation={this.state.showRedeemConfirmation}
            showRedeemNameCardDialog={this.state.showRedeemNameCardDialog}
            getMyCoupons={this.getMyCoupons}
            redeemCoupon={this.redeemCoupon}
            getSelectedCoupon={this.getSelectedCoupon}
            onChangeMyCouponHOC={this.onChangeMyCouponHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
