export const PaymentTypes = [
  {
    id: 2,
    name: "Cheque",
    label: "Cheque",
    value: 2,
  },
  {
    id: 6,
    name: "Bank Transfer",
    label: "Bank Transfer",
    value: 6,
  },
  {
    id: 1,
    name: "Cash",
    label: "Cash",
    value: 1,
  },
];

export const DisbursementCost = [
  {
    id: 0,
    label:
      "Tenant to bear all costs incurred in the preparation and stamping of the Tenancy Agreement.",
  },
  {
    id: 2,
    label:
      "Landlord to bear all costs incurred in the preparation and stamping of the Tenancy Agreement.",
  },
  {
    id: 1,
    label:
      "Both parties sharing equally for all costs incurred in the preparation of the Tenancy Agreement.",
  },
];
