import React from "react";
import _ from "lodash";
import { Line } from "react-chartjs-2";

import { numberWithCommas } from "utils/thousandSeparator";

const OverridingsFigureChart = ({ data }) => {
  const processData = () => {
    let tmpX = [];
    let tmpYFigure = [];
    data.overridings &&
      data.overridings.map((child) => {
        Object.keys(child).map((key) => {
          tmpX.push(key);
          tmpYFigure.push(child[key]);
        });
      });
    return {
      tmpX: _.reverse(tmpX),
      tmpYFigure: _.reverse(tmpYFigure),
    };
  };
  const result = (canvas) => {
    return {
      labels: processData().tmpX,
      datasets: [
        {
          label: "Overridings Figures",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "#DC267F59",
          borderColor: "#DC267F",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          borderWidth: 2,
          pointRadius: 3,
          pointBorderColor: "#DC267F",
          pointBackgroundColor: "#DC267F",
          pointBorderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#DC267F",
          pointHoverBorderColor: "#DC267F",
          pointHoverBorderWidth: 2,
          pointHitRadius: 10,
          data: processData().tmpYFigure,
          responsive: true,
          maintainAspectRatio: true,
        },
      ],
    };
  };
  return (
    <div className="grid-half-col">
      <div className="at-form__content mb-0">
        <h2
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#111827",
            marginBottom: 16,
          }}
        >
          Overridings Figure Data
        </h2>
        <div style={{ position: "relative", height: "50vh", marginTop: 20 }}>
          <Line
            data={result}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: { display: false },
              plugins: {
                datalabels: {
                  display: false,
                },
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) =>
                    "Overridings Figures: " +
                    numberWithCommas(
                      data.datasets[0].data[tooltipItems.index].toFixed(0),
                    ),
                },
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      borderDash: [4, 2],
                      color: "#BDBDBD",
                    },
                    ticks: {
                      callback: (value, index, values) =>
                        numberWithCommas(value),
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OverridingsFigureChart;
