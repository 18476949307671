import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import {
  Dialog,
  Grid,
  MenuItem,
  Paper,
  FormControl,
  IconButton,
} from "@material-ui/core";
import {
  BsFillBookmarkStarFill,
  BsFillCalendar2Fill,
  BsGeoAltFill,
  BsFillPersonFill,
  BsFillCalendarXFill,
  BsFillFileEarmarkTextFill,
  BsFillTicketPerforatedFill,
} from "react-icons/bs";
import { AiOutlineZoomIn } from "react-icons/ai";
import Carousel from "react-material-ui-carousel";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import CustomSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import AtlasCloseButton from "components/Button/prev";
import CustomConfirmationDialog from "components/Modal/confirmation";

import DeliveryBranch from "../actions/branch";
import { getColorBadge } from "dictionary/badgeColor";
import defaultImage from "assets/images/iqi_logo_only.png";

import "../index.scss";

const eventData = [
  { label: "Event", value: "name", icon: <BsFillBookmarkStarFill /> },
  { label: "Date and Time", value: "date_time", icon: <BsFillCalendar2Fill /> },
  {
    label: "Registration Ends",
    value: "cut_off_date",
    icon: <BsFillCalendarXFill />,
  },
  { label: "Venue", value: "location", icon: <BsGeoAltFill /> },
  { label: "Speaker", value: "speaker", icon: <BsFillPersonFill /> },
  {
    label: "Description",
    value: "description",
    icon: <BsFillFileEarmarkTextFill />,
  },
  {
    label: "Entry Pass",
    value: "entry_pass",
    icon: <BsFillTicketPerforatedFill />,
  },
  {
    label: "Branch Region",
    value: "branch_region",
    icon: <AtlasIcon svgHref={"atlas-building-4"} />,
  },
];
const nameCardData = [
  { header: "Name", value: "name_card_display_name" },
  { header: "Name in local language", value: "additional_display_name" },
  { header: "Email", value: "email" },
  { header: "Primary Mobile Number", value: "mobile_contact_number" },
  { header: "Branch Name", value: "branch_name" },
  { header: "License Number", value: "license_number" },
  { header: "Include profile photo", value: "include_profile_photo" },
  { header: "Designation", value: "designation" },
  { header: "Additional Mobile Number", value: "name_card_mobile" },
  { header: "Title of Academy Achievement", value: "qualification" },
];

const collarShirtImgSrc = [
  "mandarin_collar_side.png",
  "mandarin_collar_front.png",
  "mandarin_collar_back.png",
];

const hashRedImgSrc = [
  "hash_red_side.png",
  "hash_red_front.png",
  "hash_red_back.png",
];

const sizeOptions = [
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
];

const defaultPhoto =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/default_namecard.png";
const redeemedStamp =
  "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/redeemed_stamp.png";

class ProductDetails extends Component {
  state = {
    confirmedCheckbox: false,
    productCardContent: "Name Card",
    shirtSize: "",
    shirtGender: "",
    deliveryBranchId: "",
    showImageDialog: false,
    currentImage: "",
    showOriginalPhoto: false,
  };

  componentDidMount = () => this.props.getDeliveryBranches();

  componentDidUpdate = (pp) => {
    if (pp.open !== this.props.open && !this.props.open) {
      this.setState({
        confirmedCheckbox: false,
        productCardContent: "Name Card",
        shirtSize: "",
        shirtGender: "",
        deliveryBranchId: "",
      });
    }
  };

  renderCouponBadge = (code) => {
    return (
      <div className="d-flex align-items-center g-2 mb-2">
        {this.props.selectedCoupon.is_used && (
          <div
            className="at-status_badge"
            style={{ ...getColorBadge("Green") }}
          >
            <AtlasIcon
              svgHref={"atlas-ticket-star"}
              style={{ width: 16, height: 16, marginRight: 4 }}
            />
            <span className="at-badge_title">Redeemed</span>
          </div>
        )}
        <div className="at-status_badge" style={{ ...getColorBadge("Blue") }}>
          <span className="at-badge_title">Coupon no: {code}</span>
        </div>
      </div>
    );
  };

  renderDivider = () => {
    return (
      <div className="at-ticket_divider">
        <div className="at-ticket_notch"></div>
        <div className="at-ticket_notch at-ticket_notch--right"></div>
      </div>
    );
  };

  renderEventTicketDetails = () => {
    const {
      market_product = {},
      code,
      expiry_date,
      redeem_date,
      is_used,
    } = this.props.selectedCoupon;

    const { name, description, photo_url, attached_product } = market_product;

    const { event = {} } = attached_product;
    let tempData = {
      name: event.name,
      date_time: `${Moment(event.start_date_time).format("DD MMM YYYY LT")} - ${Moment(event.end_date_time).format("DD MMM YYYY LT")}`,
      cut_off_date: Moment(event.cut_off_date).format("DD MMM YYYY"),
      location: event.location,
      location_url: event.location_url,
      speaker: event.speaker,
      description: event.description,
      entry_pass: event.entry_pass,
      branch_region: event.branch_region_name.name,
    };

    return (
      <Grid container spacing={16}>
        <Grid item md={5} xs={12}>
          <img
            style={{ objectFit: "cover", width: "100%", borderRadius: 8 }}
            src={photo_url || defaultImage}
            alt={"Event Poster"}
          />
        </Grid>
        <Grid item container md={7} xs={12}>
          <Grid item xs={12}>
            <div className="at-ticket_card">
              <div className="at-coupon__title-cont">
                <div>
                  {this.renderCouponBadge(code)}
                  <h4>{`${name}`}</h4>
                  <p className="mb-3">{description}</p>
                </div>
                {is_used && (
                  <img
                    className="at-redeemed-stamp"
                    src={redeemedStamp}
                    alt={"Redeemeed Stamp"}
                  />
                )}
              </div>
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <label className="at-form-input__title">Expiry date</label>
                  <p className="fs-2 color-danger">
                    {!_.isEmpty(expiry_date) ? expiry_date : "N/A"}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <label className="at-form-input__title">Redeemed on</label>
                  <p className="fs-2">
                    {!_.isEmpty(redeem_date) ? redeem_date : "-"}
                  </p>
                </Grid>
              </Grid>
            </div>
            {this.renderDivider()}
            <div className="bg-white at-ticket_card">
              {eventData.map((item, index) => {
                return (
                  <div key={index} className="event-details">
                    {item.value === "cut_off_date" && (
                      <>
                        <div className="event-details-icon">{item.icon}</div>
                        <div style={{ width: "90%" }}>
                          <label style={{ maxWidth: 150 }}>{item.label}</label>
                          <br />
                          <span
                            style={{ color: "red" }}
                          >{`${tempData[item.value]}`}</span>
                        </div>
                      </>
                    )}
                    {item.value !== "cut_off_date" && (
                      <>
                        <div className="event-details-icon">{item.icon}</div>
                        <div style={{ width: "90%" }}>
                          <label style={{ maxWidth: 150 }}>{item.label}</label>
                          <br />
                          <span>{`${tempData[item.value]}`}</span>
                          {item.value === "location" && (
                            <>
                              <br />
                              <span
                                className="fw-500"
                                style={{ color: "#2563EB", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(tempData.location_url, "__blank")
                                }
                              >
                                Location link
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderNameCardDetails = () => {
    const {
      market_product = {},
      market_order_item = {},
      code,
      expiry_date,
      redeem_date,
      payment_amount_type,
      is_used,
    } = this.props.selectedCoupon;

    const { name, description, photo_url, type_id } = market_product;

    const payload =
      !_.isEmpty(market_order_item) && !_.isEmpty(market_order_item.payload)
        ? market_order_item.payload
        : {};
    const delivery_branch_name =
      !_.isEmpty(market_order_item) &&
      !_.isEmpty(market_order_item.delivery_branch_name)
        ? market_order_item.delivery_branch_name
        : "N/A";

    const {
      name_card,
      email,
      branch_name,
      license_number,
      mobile_contact_number,
    } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let tempNameCard = {
      ...name_card,
      include_profile_photo: name_card?.include_profile_photo ? "Yes" : "No",
      email: email,
      branch_name: branch_name,
      license_number: license_number,
      mobile_contact_number: mobile_contact_number,
      name_card_mobile: name_card?.mobile_number ?? "",
    };
    let showNameCardPhoto =
      name_card?.include_profile_photo && name_card?.photo;

    let tempImages =
      payment_amount_type === 2 ? collarShirtImgSrc : hashRedImgSrc;

    return (
      <Grid container spacing={16}>
        {type_id === 2 && (
          <Grid item xs={12} {...(type_id === 2 && { sm: 4 })}>
            <img
              className={"bg-white bd-rd-5"}
              style={{
                objectFit: "cover",
                height: "200px",
                width: "100%",
                marginBottom: 4,
              }}
              src={photo_url || defaultImage}
            />
          </Grid>
        )}
        <Grid
          item
          containe
          className={"grid_gap-2"}
          xs={12}
          {...(type_id === 2 && { sm: 8 })}
        >
          <Grid item xs={12}>
            <div className="at-coupon__title-cont">
              <div>
                {this.renderCouponBadge(code)}
                <h4>{name}</h4>
                <p className="mb-3">{description}</p>
              </div>
              {is_used && (
                <img
                  className="at-redeemed-stamp"
                  src={redeemedStamp}
                  alt={"Redeemeed Stamp"}
                />
              )}
            </div>
            {[1, 2].includes(type_id) > -1 && (
              <AlertBox
                mode={"info"}
                description={
                  <>
                    You can only redeem your Name Card after completing the new
                    REN training.
                    {type_id === 1 && (
                      <p className="mb-0">
                        Please fill up all the fields on the T-Shirt tab.
                      </p>
                    )}
                  </>
                }
              />
            )}
            {type_id === 3 && (
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <p className="fs-2">Expiry date</p>
                  <p className="fs-2 color-danger">
                    {!_.isEmpty(expiry_date) ? expiry_date : "N/A"}
                  </p>
                </Grid>
                {is_used && (
                  <Grid item xs={6}>
                    <p className="fs-2">Redeemed on</p>
                    <p className="fs-2">
                      {!_.isEmpty(expiry_date) ? redeem_date : "N/A"}
                    </p>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="at-form__content">
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <ModuleFormHeader
                    title={<h4>Name Card</h4>}
                    className={"mb-2"}
                    moduleIcon={"atlas-personalcard"}
                  />
                  <p className="mb-3">
                    If the information above is incorrect, please update your
                    name card first before ordering
                  </p>
                  <hr className="my-0" />
                </Grid>
                <Grid item md={5} xs={12}>
                  <h4 className="mb-3">Name card photo</h4>
                  <div
                    style={{
                      position: "relative",
                      width: 220,
                      marginBottom: 20,
                      borderRadius: 10,
                    }}
                  >
                    {showNameCardPhoto && (
                      <IconButton
                        style={{
                          position: "absolute",
                          bottom: 7,
                          right: 7,
                          padding: 3,
                          backgroundColor: "black",
                          opacity: 0.8,
                        }}
                        onClick={() =>
                          this.setState({ showOriginalPhoto: true })
                        }
                      >
                        <AiOutlineZoomIn style={{ width: 14, fill: "white" }} />
                      </IconButton>
                    )}
                    <img
                      src={showNameCardPhoto ? name_card.photo : defaultPhoto}
                      style={{ objectFit: "contain", borderRadius: 10 }}
                    />
                  </div>
                </Grid>
                <Grid item md={7} xs={12}>
                  <Grid container>
                    {nameCardData.map((item, index) => (
                      <Grid key={index} classes={{ root: "py-0" }} item xs={12}>
                        <label className="at-form-input__title">
                          {item.header === "License Number"
                            ? tempNameCard.real_estate_type || "Board Reg. No."
                            : item.header}
                        </label>
                        <p
                          style={{ marginBottom: 12 }}
                        >{`${tempNameCard[item.value] || "-"}`}</p>
                      </Grid>
                    ))}
                  </Grid>
                  {this.props.onShowProfileUpdate && !is_used && (
                    <button
                      onClick={this.props.onShowProfileUpdate}
                      className={"btn-new btn-new--secondary"}
                    >
                      Edit info
                    </button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {type_id === 1 && (
            <Grid item {...(type_id === 2 && { md: 9, sm: 8 })} xs={12}>
              <div className="at-form__content">
                <ModuleFormHeader
                  title={<h4>T-Shirt</h4>}
                  className={"mb-2"}
                  moduleIcon={"atlas-tshirt"}
                />
                <hr className="mb-3" />
                <Grid container spacing={16}>
                  <Grid item md={4} xs={12}>
                    <h4 className={"mb-3"}>
                      {payment_amount_type === 2
                        ? "Mandarin Collar Polo Orange"
                        : "Hashtag IQI T-shirt"}
                    </h4>
                    <Carousel
                      animation={"slide"}
                      navButtonsAlwaysVisible={true}
                      navButtonsProps={{ style: { borderRadius: 0 } }}
                    >
                      {tempImages.map((item, index) => (
                        <Paper key={index}>
                          <img
                            onClick={() => {
                              this.setState({
                                showImageDialog: true,
                                currentImage: item,
                              });
                            }}
                            src={require(`assets/images/${item}`)}
                            style={{
                              borderRadius: 10,
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          />
                        </Paper>
                      ))}
                    </Carousel>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <h4 className={"mb-3"}>T-Shirt Variant</h4>
                    <FormControl style={{ width: "100%", marginBottom: 16 }}>
                      <label
                        className={"at-form-input__title"}
                        style={{ marginBottom: 6 }}
                      >
                        Gender
                      </label>
                      {!is_used && (
                        <CustomSelect
                          value={this.state.shirtGender}
                          onChange={(e) =>
                            this.setState({ shirtGender: e.target.value })
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 300,
                          }}
                        >
                          <MenuItem value={""}>
                            Please choose an option
                          </MenuItem>
                          <MenuItem value={"M"}>Male</MenuItem>
                          <MenuItem value={"F"}>Female</MenuItem>
                        </CustomSelect>
                      )}
                      {is_used && (
                        <p>
                          {!_.isEmpty(payload.t_shirt_gender)
                            ? payload.t_shirt_gender
                            : "N/A"}
                        </p>
                      )}
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 16 }}>
                      <label
                        className={"at-form-input__title"}
                        style={{ marginBottom: 6 }}
                      >
                        T-Shirt size
                      </label>
                      {!is_used && (
                        <CustomSelect
                          value={this.state.shirtSize}
                          onChange={(e) =>
                            this.setState({ shirtSize: e.target.value })
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 300,
                          }}
                        >
                          <MenuItem value={""}>
                            Please choose an option
                          </MenuItem>
                          {sizeOptions.map((item) => {
                            return (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </CustomSelect>
                      )}
                      {is_used && (
                        <p>
                          {!_.isEmpty(payload.t_shirt_size)
                            ? payload.t_shirt_size
                            : "N/A"}
                        </p>
                      )}
                    </FormControl>
                    <img
                      className="ml-n1 mt-4 w-100"
                      src={require(`assets/images/sizeChart.jpg`)}
                    />
                  </Grid>
                </Grid>
                {this.state.showImageDialog && (
                  <Dialog open={true}>
                    <AtlasCloseButton
                      onClick={() => {
                        this.setState({
                          showImageDialog: false,
                          currentImage: "",
                        });
                      }}
                      containerStyle={{
                        position: "absolute",
                        right: 20,
                        top: 10,
                      }}
                    />
                    <img
                      src={require(`assets/images/${this.state.currentImage}`)}
                    />
                  </Dialog>
                )}
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className="at-form__content">
              <ModuleFormHeader
                title={<h4>Collection</h4>}
                className={"mb-2"}
                moduleIcon={"atlas-truck"}
              />
              <hr className="mb-3" />
              <p className="mb-3">
                I will collect the starter kit at the selected branch office
                below:
              </p>
              <FormControl style={{ width: "100%", marginBottom: 16 }}>
                <label
                  className={"at-form-input__title"}
                  style={{ marginBottom: 6 }}
                >
                  Branch office
                </label>
                {!is_used && this.props.deliveryBranches.length > 0 && (
                  <CustomSelect
                    value={this.state.deliveryBranchId}
                    onChange={(e) =>
                      this.setState({ deliveryBranchId: e.target.value })
                    }
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 300,
                    }}
                  >
                    {this.props.deliveryBranches.map((branch) => {
                      return (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.name}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                )}
                {!is_used && this.props.deliveryBranches.length <= 0 && (
                  <p>No branch available</p>
                )}
                {is_used && <p>{delivery_branch_name}</p>}
              </FormControl>
              {!is_used && (
                <CustomCheckbox
                  content={
                    "With the checkbox being checked, I have confirmed the information above for my Name Card is correct and agreed to proceed the ordering"
                  }
                  checked={this.state.confirmedCheckbox}
                  onChangeCheckboxValue={(e) =>
                    this.setState({ confirmedCheckbox: e.target.checked })
                  }
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderDialogContent = () => {
    const { selectedCoupon } = this.props;

    const { market_product } = selectedCoupon;
    return (
      <div className="name-card__container">
        {market_product &&
          [1, 2].includes(market_product.type_id) &&
          this.renderNameCardDetails()}
        {market_product &&
          market_product.type_id === 3 &&
          this.renderEventTicketDetails()}
        <div className="at-modal_dialog-container-footer g-3">
          {this.props.can_update && !selectedCoupon.is_used && (
            <button
              disabled={
                !(market_product && market_product.type_id === 3) &&
                (!this.state.confirmedCheckbox ||
                  this.state.shirtGender === "" ||
                  this.state.shirtSize === "")
              }
              onClick={() =>
                this.props.onChangeMyCouponHOC(true, "showRedeemConfirmation")
              }
              className={"btn-new btn-new--primary"}
            >
              Redeem Now
            </button>
          )}
          <button
            onClick={() =>
              this.props.onChangeMyCouponHOC(false, "showRedeemNameCardDialog")
            }
            className={"btn-new btn-new--outline-secondary"}
          >
            {selectedCoupon.is_used ? "Close" : "Cancel"}
          </button>
        </div>
      </div>
    );
  };

  render = () => {
    const {
      onLoadMyCoupon,
      showRedeemConfirmation,
      onChangeMyCouponHOC,
      redeemCoupon,
      selectedCoupon,
      onLoadBranches,
    } = this.props;
    let temp = {
      ...selectedCoupon,
      ...(selectedCoupon.market_product.attached_product_type !==
        "TicketType" && {
        shirtSize: this.state.shirtSize,
        shirtGender: this.state.shirtGender,
        branch_id: this.state.deliveryBranchId,
      }),
    };

    const { name_card } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let showNameCardPhoto =
      name_card?.include_profile_photo && name_card?.photo;

    return (
      <>
        {this.renderDialogContent()}
        <CustomConfirmationDialog
          open={showRedeemConfirmation}
          loading={onLoadMyCoupon}
          message={
            selectedCoupon.market_product.attached_product_type === "TicketType"
              ? "Are you sure you want to proceed now ?"
              : `Please kindly confirm your Name Card information is correct before you click "Yes" to proceed ? Are you sure you want to proceed now ?`
          }
          mode="info"
          title={"Redeem Coupon"}
          positiveAction={() => redeemCoupon(temp)}
          negativeAction={() =>
            onChangeMyCouponHOC(false, "showRedeemConfirmation")
          }
        />
        {this.state.showOriginalPhoto && (
          <Dialog open={true}>
            <AtlasCloseButton
              onClick={() => {
                this.setState({ showOriginalPhoto: false });
              }}
              containerStyle={{
                position: "absolute",
                right: 20,
                top: 10,
              }}
            />
            <img
              style={{ minWidth: 500, minHeight: 500 }}
              src={showNameCardPhoto ? name_card.photo : defaultPhoto}
            />
          </Dialog>
        )}
        {(onLoadMyCoupon || onLoadBranches) && <LoadingModal />}
      </>
    );
  };
}

export default DeliveryBranch(ProductDetails);
