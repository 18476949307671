import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import AtlasButton from "components/Button";
import CustomTypeahead from "components/Typeahead/new";

const DatepickerInput = ({ ...props }) => (
  <input
    type="text"
    style={{ cursor: "pointer", width: "100%", backgroundColor: "#ffff" }}
    width={"100%"}
    {...props}
    readOnly
  />
);

const Attendance = ({ branches, onLoadExport, exportTraining }) => {
  const [dataToSubmit, setDataToSubmit] = useState({
    start_date: "",
    end_date: "",
    branch_id: null,
  });

  let tmpBranches = useMemo(() => {
    return [{ id: "0", name: "All", active: true }, ...branches];
  }, [branches]);

  return (
    <div className={"at-card bg-white mb-20"}>
      <h4 className="at-card__title">Export Attendance</h4>
      <hr className={"w-100"} />
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4} key={"start_date"}>
          <div style={{ minWidth: 300 }}>
            <label>Start Date from</label>
            <div className="at-export-datepicker">
              <DatePicker
                dateFormat={"DD MMM YYYY"}
                className="form-control at-training-datepicker mt-10"
                value={dataToSubmit.start_date}
                customInput={<DatepickerInput />}
                onChange={(val) => {
                  setDataToSubmit((prev) => ({
                    ...prev,
                    start_date: Moment(val).format("DD MMM YYYY"),
                  }));
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} key={"end_date"}>
          <div style={{ minWidth: 300 }}>
            <label>Start Date To</label>
            <div className="at-export-datepicker">
              <DatePicker
                dateFormat={"DD MMM YYYY"}
                className="form-control at-training-datepicker mt-10"
                value={dataToSubmit.end_date}
                customInput={<DatepickerInput />}
                onChange={(val) => {
                  setDataToSubmit((prev) => ({
                    ...prev,
                    end_date: Moment(val).format("DD MMM YYYY"),
                  }));
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label>Branches</label>
          <CustomTypeahead
            typeaheadId={"branches"}
            disabled={onLoadExport}
            selectedValue={
              _.find(tmpBranches, { id: dataToSubmit.branch_id })
                ? [_.find(tmpBranches, { id: dataToSubmit.branch_id })]
                : []
            }
            options={tmpBranches || []}
            filterBy={["name"]}
            labelKey={"name"}
            onSelect={(val) => {
              setDataToSubmit((prev) => ({
                ...prev,
                branch_id: val && val.length > 0 ? val[0].id : "",
              }));
            }}
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
          />
        </Grid>
      </Grid>
      <AtlasButton
        disabled={!dataToSubmit.branch_id}
        className="btn-new btn-new--success mt-20"
        children={"Export"}
        onClick={() => exportTraining(dataToSubmit)}
      />
    </div>
  );
};

export default Attendance;
