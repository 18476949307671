import React from "react";
import _ from "lodash";

import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";
import AtlasIcon from "components/Icon/atlasIcon";
import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";

import roundUp from "utils/roundUp";
import { getColorBadge } from "dictionary/badgeColor";
import "stylesheets/containers/subsales-claims/index.scss";

const checkNull = (param) => (param ? param : 0);

const ownerAgentOptions = (party_sales_tax_on_party_id, sub_sale_type_id) => [
  {
    value: 1,
    label: sub_sale_type_id === 2 ? "Landlord" : "Vendor",
  },
  {
    value: 2,
    label: "Agent",
  },
  ...(party_sales_tax_on_party_id === 3)
    ? [{
      value: 3,
      label: "Co-Agency",
    }] 
    : []
];

const buyerAgentOptions = (party_sales_tax_on_party_id, sub_sale_type_id) => [
  {
    value: 1,
    label: sub_sale_type_id === 2 ? "Tenant" : "Purchaser" ,
  },
  {
    value: 2,
    label: "Agent",
  },
  ...(party_sales_tax_on_party_id === 3)
    ? [{
      value: 3,
      label: "Co-Agency",
    }] 
    : []
];

const coAgentOptions = (representative_id, external_agency_representative_id, sub_sale_type_id) => {
  if (![1, 2].includes(representative_id)) {
    return [
      {
        value: 2,
        label: "Agent",
      },
      {
        value: 3,
        label: "Co-Agency",
      }
    ]
  } else {
    return [
      {
        value: 1,
        label: external_agency_representative_id === 1 
          ? (sub_sale_type_id === 2 ? "Landlord" : "Vendor")
          : (sub_sale_type_id === 2 ? "Tenant" : "Purchaser")
      },
      {
        value: 2,
        label: "Agent",
      }, 
      {
        value: 3,
        label: "Co-Agency",
      }
    ]
  }
}

const representativeBadge = (representative_id, sub_sale_type_id) => {
  let representativeLabel = ""
  let badgeColor = ""
  switch (representative_id) {
    case 1:
      representativeLabel = sub_sale_type_id === 2 ? "Landlord" : "Vendor";
      badgeColor = "Yellow"
      break;
    case 2:
      representativeLabel = sub_sale_type_id === 2 ? "Tenant" : "Purchaser";
      badgeColor = "Green"
      break;
    default:
      break;
  }

  if (representativeLabel === "") return null
  return (
    <div
      className="at-status_badge text-center"
      style={{ ...getColorBadge(badgeColor) }}
    >
      Represent {representativeLabel}
    </div>
  )
}

const partyRepresentations = (sub_sale_type_id) => [
  {
    label: `Paid by ${sub_sale_type_id === 2 ? 'Landlord' : 'Vendor'}`,
    icon: 'svg-user-landlord',
    fee_amount_value: 'owner_agency_fee_amount',
    party_sales_tax_on_party_id_value: 'owner_sales_tax_on_party_id',
    agentOptions: (party_sales_tax_on_party_id) => ownerAgentOptions(party_sales_tax_on_party_id, sub_sale_type_id),
    value: 1
  },
  {
    label: `Paid by ${sub_sale_type_id === 2 ? 'Tenant' : 'Purchaser'}`,
    icon: 'svg-user-tenant',
    fee_amount_value: 'buyer_agency_fee_amount',
    party_sales_tax_on_party_id_value: 'buyer_sales_tax_on_party_id',
    agentOptions: (party_sales_tax_on_party_id) => buyerAgentOptions(party_sales_tax_on_party_id, sub_sale_type_id),
    value: 2
  },
  {
    label: '',
    icon: '',
    fee_amount_value: 'revised_co_agency_fee_amount',
    party_sales_tax_on_party_id_value: 'co_agency_sales_tax_on_party_id',
    agentOptions: [],
    value: 3
  },
  {
    label: '',
    icon: '',
    fee_amount_value: '',
    party_sales_tax_on_party_id_value: '',
    agentOptions: [],
    value: ''
  },
  {
    label: '',
    icon: '',
    fee_amount_value: '',
    party_sales_tax_on_party_id_value: '',
    agentOptions: [],
    value: null
  }
]

const renderEditCoAgency = props => {
  const {
    selected_claim,
    claim_status,
    isPrimaryClaim,
    can_update,
    onChange,
    stakeholder_type_id,
    revised_co_agency_fee_amount,
    SSTFormula
  } = props

  const getFeeAmountHelpText = stakeholder_type_id => {
    switch(stakeholder_type_id) {
      case 1:
        return "*Total Amount to pay to Co-Agency"
      case 2:
        return "*Total Amount to claim from Co-Agency"
      default:
        return ""
    }
  }

  return (
    <>
      <div className="mb-3">
        <h2 className="at-form-input__title">{`Select the agency who is the stakeholder:`}</h2>
        <div className="mt-1 mb-2 d-flex flex-wrap grid_gap-1 align-items-center">
          <CustomRadioGroup
            mode="old"
            horizontal={false}
            containerClassName={
              "subsaleclaim-claimform-agencyfees-buyer-feeradio"
            }
            options={[
              {
                label: "IQI",
                value: 1
              },
              {
                label:" Co-Agency",
                value: 2
              }
            ]}
            checkedValue={selected_claim.stakeholder_type_id}
            selectedRadioValue={(val) => onChange("stakeholder_type_id", val)}
            disabled={
              isPrimaryClaim
                ? !can_update ||
                  (claim_status !== "Draft" && claim_status !== "Rejected")
                : true
            }/>
        </div>
      </div>
      <div style={{marginBottom: "2rem"}}>
        <h2 className="at-form-input__title">{"Fee Amount (RM)"}</h2>
        <CustomFormInput
          className={"subsaleclaim-claimform-agencyfees-landlord-feepercent"}
          type="number"
          footerClass={"at-input_footer-responsive"}
          onChangeValue={ val => onChange("revised_co_agency_fee_amount", val ? roundUp(val, 4) : 0) }
          helpText={<p style={{ color: "red", fontSize: 12 }}>{getFeeAmountHelpText(selected_claim.stakeholder_type_id)}</p>}
          value={`${selected_claim.revised_co_agency_fee_amount}`}
        />
      </div>
      {
        (stakeholder_type_id === 2 && !selected_claim.is_co_agency_sst_registered) && (
          <>
            <div className="mb-3">
              <h2 className="at-form-input__title">{"SST Amount (RM)"}</h2>
              <CustomFormInput
                className={"subsaleclaim-claimform-agencyfees-landlord-feepercent"}
                placeholder={"Amount - RM"}
                type="number"
                value={`${
                    ( revised_co_agency_fee_amount -
                      revised_co_agency_fee_amount / SSTFormula
                    )?.toFixed(2)
                }`}
                disabled={true}
              />
            </div>
          <h2 className="at-form-input__title subsaleclaim-claimform-agencyfees">
            {`Net Agency Fee (RM): ${(+revised_co_agency_fee_amount - (revised_co_agency_fee_amount - revised_co_agency_fee_amount / SSTFormula)?.toFixed(4))?.toFixed(4)}`}
          </h2>
          </>
        )
      }
    </>
  )
}

const renderEditPartyInfo = props => {
  const {
    selected_claim,
    external_agency_id,
    isExternalAgent,
    representative_id,
    sub_sale_type_id,
    sales_tax_percentage,
    claim_status,
    form_type_id,
    isPrimaryClaim,
    can_update,
    onChange,
    SST,
    SSTFormula
  } = props

  const { 
    label, 
    icon,
    agentOptions = [],
    fee_amount_value,
    party_sales_tax_on_party_id_value
  } = partyRepresentations( sub_sale_type_id).find(item => isExternalAgent ? item.value === 3 : item.value === representative_id) || {}

  const tmpFeeAmount = props[fee_amount_value] ?? 0
  const displayFeeAmount = props[fee_amount_value] || 0.00

  const tmpPartySalesTaxOnPartyId = props[party_sales_tax_on_party_id_value] || ''

  const renderOptions = () => {
    // Co-Agency not in use for temporary
    if (fee_amount_value === 'revised_co_agency_fee_amount')
      return coAgentOptions(selected_claim.representative_id, representative_id, sub_sale_type_id)
    if (typeof agentOptions === 'function')
      return agentOptions(tmpPartySalesTaxOnPartyId)
    else
      return agentOptions
  }

  return (
    <>
      {(icon || label) && (
        <div className="d-flex grid_gap-1 mb-3">
          <AtlasIcon
            className="svg-icon-chevron"
            svgHref={icon}
          />
          <h2 className="at-card__title">
            {label}
          </h2>
        </div>
      )}
      <div style={{marginBottom: "2rem"}}>
        <h2 className="at-form-input__title">{`Fee Amount (RM)`}</h2>
        <CustomFormInput
          className={"subsaleclaim-claimform-agencyfees-buyer-feeinput"}
          placeholder={"RM"}
          type="number"
          helpText={<p style={{ color: "red", fontSize: 12 }}>*Enter Commission Amount (excl. SST)</p>}
          value={tmpFeeAmount}
          onChangeValue={ val => onChange(fee_amount_value, roundUp(val, 4)) }
          disabled={
            isPrimaryClaim
              ? !can_update ||
                (claim_status !== "Draft" && claim_status !== "Rejected")
              : true
          }
        />
      </div>
      <div className="mb-3">
        <h2 className="at-form-input__title">{"SST Amount (RM)"}</h2>
        <div className="mb-2 d-flex flex-wrap grid_gap-1 align-items-center">
          <span style={{ display: "inline-block" }}>
            {sales_tax_percentage}% on
          </span>
          <CustomRadioGroup
            containerClassName={
              "subsaleclaim-claimform-agencyfees-buyer-feeradio"
            }
            options={renderOptions()}
            checkedValue={tmpPartySalesTaxOnPartyId}
            selectedRadioValue={(val) =>
              onChange(
                party_sales_tax_on_party_id_value,
                val,
              )
            }
            horizontal={true}
            disabled={
              isPrimaryClaim
                ? !can_update ||
                  (claim_status !== "Draft" && claim_status !== "Rejected")
                : true
            }
          />
        </div>
        <CustomFormInput
          className={"subsaleclaim-claimform-agencyfees-landlord-feepercent"}
          placeholder={"Amount - RM"}
          type="number"
          value={`${
            tmpPartySalesTaxOnPartyId === 1
              ? (displayFeeAmount * SST)?.toFixed(2)
              : tmpPartySalesTaxOnPartyId === 2
                ? (
                    displayFeeAmount -
                    displayFeeAmount / SSTFormula
                  )?.toFixed(2)
                : (0)?.toFixed(2)
          }`}
          disabled={true}
        />
      </div>
    </>
  );
};

const AgencyFee = ({
  selected_claim,
  can_update,
  setShowExternalAgent,
  onChangeUpdateSelectedClaim,
}) => {
  const {
    representative_id,
    opposite_claim = {},
    external_agency_id,
    external_agency_representative_id
  } = selected_claim;

  const SST = selected_claim?.sales_tax_percentage / 100;
  const SSTFormula = (selected_claim?.sales_tax_percentage / 100) + 1

  const onChangeOppositeClaim = (context, value) => {
    const tmp_opposite_claim = _.cloneDeep(opposite_claim);
    tmp_opposite_claim[context] = value;
    onChangeUpdateSelectedClaim("opposite_claim", value);
  };

  const renderTotalAgencyFee = ({
    owner_agency_fee_amount,
    owner_sales_tax_on_party_id,
    buyer_agency_fee_amount,
    buyer_sales_tax_on_party_id,
    revised_co_agency_fee_amount,
    stakeholder_type_id,
    is_co_agency_sst_registered
  }) => {

    return (
      <div className="grid-full-col">
        <div className="at-subsaleclaim-claimform-total_agency_fees">
          {
            external_agency_id > 0 && (
              <>
                <div className="at-form_fields_cont">
                  <div className="at-form_field-col-6 fs-2">
                    <h2 className={"subsaleclaim-claimform-agencyfees-totalamount"}>
                      <AtlasIcon svgHref="svg-iconsax-money" />
                      {`IQI Agency Fee (RM): `}
                      {
                        (owner_sales_tax_on_party_id === 3 || buyer_sales_tax_on_party_id === 3) 
                          ? "Not Applicable"
                          : (
                            <>
                              {
                                stakeholder_type_id === 1 
                                ? `${(
                                  parseFloat(checkNull(owner_agency_fee_amount)) +
                                  (owner_sales_tax_on_party_id === 1
                                    ? checkNull(owner_agency_fee_amount) * SST
                                    : 0
                                  ) +
                                  parseFloat(checkNull(buyer_agency_fee_amount)) +
                                  (buyer_sales_tax_on_party_id === 1
                                    ? checkNull(buyer_agency_fee_amount) * SST
                                    : 0
                                  ) -
                                  parseFloat(checkNull(revised_co_agency_fee_amount))
                                )?.toFixed(2)}`
                                : parseFloat(checkNull(revised_co_agency_fee_amount))?.toFixed(2)
                              }
                            </>
                          )
                      }
                    </h2>
                  </div>
                  <div className="at-form_field-col-6 fs-2">
                    <h2 className={"subsaleclaim-claimform-agencyfees-totalamount"}>
                      <AtlasIcon svgHref="svg-iconsax-money" />
                      {`Co-Agency Fee (RM): `}
                      {
                        (owner_sales_tax_on_party_id === 3 || buyer_sales_tax_on_party_id === 3) 
                          ? "Not Applicable"
                          : (
                            <>
                              {
                                stakeholder_type_id === 2
                                ? `${(
                                  parseFloat(checkNull(owner_agency_fee_amount)) +
                                  (owner_sales_tax_on_party_id === 1
                                    ? checkNull(owner_agency_fee_amount) * SST
                                    : 0
                                  ) +
                                  parseFloat(checkNull(buyer_agency_fee_amount)) +
                                  (buyer_sales_tax_on_party_id === 1
                                    ? checkNull(buyer_agency_fee_amount) * SST
                                    : 0
                                  ) -
                                  parseFloat(checkNull(revised_co_agency_fee_amount))
                                )?.toFixed(2)}`
                                : parseFloat(checkNull(revised_co_agency_fee_amount))?.toFixed(2)
                              } 
                            </>
                          )
                      }
                    </h2>
                  </div>
                </div>
                <hr className="my-2"/>
              </>
            )
          }
          <div className="fs-2">
            <h2 className={"subsaleclaim-claimform-agencyfees-totalamount"}>
              <AtlasIcon svgHref="svg-iconsax-money" />
              {`Total Agency Fee (RM): `}
                {`${(
                  parseFloat(checkNull(owner_agency_fee_amount)) +
                  (owner_sales_tax_on_party_id === 1
                    ? checkNull(owner_agency_fee_amount) * SST
                    : 0
                  ) +
                  parseFloat(checkNull(buyer_agency_fee_amount)) +
                  (buyer_sales_tax_on_party_id === 1
                    ? checkNull(buyer_agency_fee_amount) * SST
                    : 0
                  )
                )?.toFixed(2)}`}
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const getFeeData = () => {
    let tmp = {};
    if (representative_id === null || representative_id === 3) {
      tmp.owner_agency_fee_amount = selected_claim.owner_agency_fee_amount;
      tmp.owner_sales_tax_on_party_id = selected_claim.owner_sales_tax_on_party_id;
      tmp.buyer_agency_fee_amount = selected_claim.buyer_agency_fee_amount;
      tmp.buyer_sales_tax_on_party_id = selected_claim.buyer_sales_tax_on_party_id;
      tmp.co_agency_sales_tax_on_party_id = selected_claim.co_agency_sales_tax_on_party_id;
      tmp.revised_co_agency_fee_amount = selected_claim.revised_co_agency_fee_amount;  
    } else if (representative_id && representative_id !== null) {
      if (representative_id === 1) {
        tmp.owner_agency_fee_amount = selected_claim.owner_agency_fee_amount;
        tmp.owner_sales_tax_on_party_id = selected_claim.owner_sales_tax_on_party_id;
        tmp.buyer_agency_fee_amount = opposite_claim.buyer_agency_fee_amount;
        tmp.buyer_sales_tax_on_party_id = opposite_claim.buyer_sales_tax_on_party_id;
        tmp.co_agency_sales_tax_on_party_id = selected_claim.co_agency_sales_tax_on_party_id;
        tmp.revised_co_agency_fee_amount = selected_claim.revised_co_agency_fee_amount;  
      } else if (representative_id === 2) {
        tmp.owner_agency_fee_amount = opposite_claim.owner_agency_fee_amount;
        tmp.owner_sales_tax_on_party_id = opposite_claim.owner_sales_tax_on_party_id;
        tmp.buyer_agency_fee_amount = selected_claim.buyer_agency_fee_amount;
        tmp.buyer_sales_tax_on_party_id = selected_claim.buyer_sales_tax_on_party_id;
        tmp.co_agency_sales_tax_on_party_id = selected_claim.co_agency_sales_tax_on_party_id;
        tmp.revised_co_agency_fee_amount = selected_claim.revised_co_agency_fee_amount;  
      }
      return tmp;
    }
  };

  const renderFeeAmount = props => {
    const {
      external_agency_id,
      isExternalAgent,
      representative_id,
      sub_sale_type_id
    } = props

    const { 
      fee_amount_value,
      party_sales_tax_on_party_id_value
    } = partyRepresentations(sub_sale_type_id).find(item => isExternalAgent ? item.value === 3 : item.value === representative_id) || {}

    const tmpFeeAmount = props[fee_amount_value] || 0.00

    const tmpPartySalesTaxOnPartyId = props?.[party_sales_tax_on_party_id_value] || ''
  
    return (
      <div className="d-flex flex-wrap flex-column grid_gap-2">
        {tmpPartySalesTaxOnPartyId === 1 && (
          <h2 className="at-form-input__title subsaleclaim-claimform-agencyfees">{`Net Agency Fee (RM): ${
            (+tmpFeeAmount + tmpFeeAmount * SST)?.toFixed(4)}`}</h2>
        )}
        {tmpPartySalesTaxOnPartyId === 2 && (
          <h2 className="at-form-input__title subsaleclaim-claimform-agencyfees">
            {`Net Agency Fee (RM): ${(+tmpFeeAmount - (tmpFeeAmount - tmpFeeAmount / SSTFormula)?.toFixed(4))?.toFixed(4)}`}
          </h2>
        )}
        {tmpPartySalesTaxOnPartyId === 3 && (
          <h2 className="at-form-input__title subsaleclaim-claimform-agencyfees">
            {`Net Agency Fee (RM): ${(+tmpFeeAmount + 0)?.toFixed(4)}`}
          </h2>
        )}
      </div>
    );
  };

  // Joint claim no need validation
  const isJointClaim = [null, 3].indexOf(selected_claim.representative_id) > -1
  const hasExternalAgency = external_agency_id > 0
  
  return (
    <>
      <AlertBox
        mode={"info"}
        containerClass="mb-3"
        description={
          <>
            {
              isJointClaim && (
                <>
                  <p className="fs-2">Enter the total commission received from each applicable party and indicate the party responsible for the SST.</p>
                  <p className="fs-2">For <b>External Co-Agency cases</b>, click "Add Co-Agency". You will need to select the party to claim from (the Stakeholder). And enter the total "Fee Amount".</p>
                </>
              )
            }
            {
              !isJointClaim && (
                <p className="fs-2">Enter the total commission received from your represented party and indicate the party responsible for the SST.</p>
              )
            }
          </>
        }
      />
      <div className="grid-control grid_gap-2">
        {/* Joint Claim */}
        {(representative_id === null || representative_id === 3) && (
          <>
            <div className="grid-half-col at-subsales_claims-agency-fee-cont">
              {renderEditPartyInfo({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                representative_id: 1,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
                SST: SST,
                SSTFormula: SSTFormula
              })}
              {renderFeeAmount({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                representative_id: 1,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
              })}
            </div>
            <div className="grid-half-col at-subsales_claims-agency-fee-cont">
              {renderEditPartyInfo({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                representative_id: 2,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
                SST: SST,
                SSTFormula: SSTFormula
              })}
              {renderFeeAmount({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                representative_id: 2,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
              })}
            </div>
            <div className="grid-half-col at-subsales_claims-agency-fee-cont">
              <div className="d-flex justify-content-between">
                <div className="d-flex grid_gap-1 mb-3">
                  <AtlasIcon
                    className="svg-icon-chevron"
                    svgHref={'atlas-user-add'}
                  />
                  <h2 className="at-card__title">Co-Agency</h2>
                </div>
                {representativeBadge(external_agency_representative_id, selected_claim.sub_sale_type_id)}
              </div>
              {
                hasExternalAgency && (
                  renderEditCoAgency({
                    ...selected_claim,
                    selected_claim: selected_claim,
                    can_update,
                    representative_id: external_agency_representative_id,
                    isPrimaryClaim: true,
                    onChange: onChangeUpdateSelectedClaim,
                    SSTFormula: SSTFormula
                  })
                )
              }
              {
                !hasExternalAgency && (
                  <div className="d-flex flex-column g-3">
                    <AlertBox
                      disabledClose={true}
                      description={`To enable the co-agency field, click here to add Co-Agency details and select the representing party.`}
                    />
                    <button
                      className="btn-new btn-new--secondary"
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowExternalAgent(true)}>
                      Add Co-Agency
                    </button>
                  </div>
                )
              }
            </div>
            {renderTotalAgencyFee({ ...selected_claim })}
          </>
        )}
        {/* Separate Claim: can edit co-agency fee only, cannot Add Co-Agency */}
        {[1, 2].indexOf(representative_id) > -1 && (
          <>
            <div className="grid-half-col at-subsales_claims-agency-fee-cont">
              {renderEditPartyInfo({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
                SST: SST,
                SSTFormula: SSTFormula
              })}
              {renderFeeAmount({
                ...selected_claim,
                selected_claim: selected_claim,
                can_update,
                isPrimaryClaim: true,
                onChange: onChangeUpdateSelectedClaim,
              })}
            </div>
            {
              // Edit CoAgency if external_agency_id has value
              hasExternalAgency && (
                <div className="grid-half-col at-subsales_claims-agency-fee-cont">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex grid_gap-1 mb-3">
                      <AtlasIcon
                        className="svg-icon-chevron"
                        svgHref={'atlas-user-add'}
                      />
                      <h2 className="at-card__title">Co-Agency</h2>
                    </div>
                    {representativeBadge(external_agency_representative_id, selected_claim.sub_sale_type_id)}
                  </div>
                  {
                    renderEditCoAgency({
                      isExternalAgent: true,
                      ...selected_claim,
                      selected_claim: selected_claim,
                      can_update,
                      representative_id: external_agency_representative_id,
                      isPrimaryClaim: true,
                      onChange: onChangeUpdateSelectedClaim,
                      SSTFormula: SSTFormula
                    })
                  }
                </div>
              )
            }
            {opposite_claim &&
              opposite_claim !== null &&
              [1, 2].indexOf(opposite_claim.representative_id) > -1 && (
                <div className="grid-half-col at-subsales_claims-agency-fee-cont">
                  {renderEditPartyInfo({
                    ...opposite_claim,
                    isPrimaryClaim: false,
                    onChange: onChangeOppositeClaim,
                    SST: SST,
                    SSTFormula: SSTFormula
                  })}
                </div>
            )}
            {renderTotalAgencyFee({ ...selected_claim })}
          </>
        )}
      </div>
    </>
  );
};

export default AgencyFee;