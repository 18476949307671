import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { PulseLoader } from "react-spinners";
import ReactHTMLParser from "react-html-parser";

import CKEditor from "components/CKEditor";
import AtlasButton from "components/Button";

import permissionsChecker from "utils/permissionsChecker";
import SubsaleMessageHOC from "../../actions/messages";

import "stylesheets/containers/personal/subsales/components/message.scss";

class AtlasMessageTab extends Component {
  state = {
    messages: [],
    inputMessage: "",
    checkedPressEnterToSend: false,
  };

  textarea = "";

  componentDidMount = () => {
    this.props.getMessages(this.props.selectedSale.id);
    this.scrollToBottom();
  };

  componentDidUpdate = (pp) => {
    if (this.props.messages !== pp.messages) {
      this.scrollToBottom();
    }
    if (
      this.props.clearTypeBox !== pp.clearTypeBox &&
      this.props.clearTypeBox
    ) {
      this.textarea.innerHTML = "";
    }
  };

  onChangeMessageValue = (val) => {
    if (val.indexOf("<br>") === 0) {
      this.setState({ inputMessage: "" });
    } else {
      this.setState({ inputMessage: val });
    }
  };

  scrollToBottom = () =>
    this.messagesEnd.scrollIntoView({ block: "nearest", inline: "start" });

  onChangeEnterCheckboxValue = (val) =>
    this.setState({ checkedPressEnterToSend: val });

  onClickSendMessage = () => {
    this.props.postMessage(this.props.selectedSale.id, {
      content: this.state.inputMessage,
    });
  };

  render = () => {
    const { onLoadMessage } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    return (
      <>
        <div className="chat-feed-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.props.messages &&
              this.props.messages.length > 0 &&
              this.props.messages.map((item) => {
                if (item.from_admin) {
                  return (
                    <div>
                      <div
                        style={{
                          background: "#F2F0F0",
                          borderRadius: "20px",
                          margin: "1px auto",
                          maxWidth: "425px",
                          padding: "8px 14px",
                          width: "-webkit-fit-content",
                          float: "left",
                        }}
                      >
                        <p
                          style={{
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: "300",
                            margin: 0,
                          }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#383535",
                            marginTop: "10px",
                            marginLeft: "auto",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {`${item.sender_name} - ${Moment(item.created_at).format("YYYY-MM-DD HH:mm").toString()}`}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <div
                        style={{
                          background: "rgb(0,132,255)",
                          borderRadius: "20px",
                          margin: "1px auto",
                          maxWidth: "425px",
                          padding: "8px 14px",
                          width: "-webkit-fit-content",
                          float: "right",
                        }}
                      >
                        <p
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "300",
                            margin: 0,
                          }}
                        >
                          {ReactHTMLParser(item.content)}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginTop: "10px",
                            marginLeft: "auto",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {`${item.sender_name} - ${Moment(item.created_at).format("YYYY-MM-DD HH:mm").toString()}`}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
        {can_update && (
          <div className="at-subsales_claims-message_editor mb-3">
            <CKEditor
              data={this.state.inputMessage}
              onChange={(event, editor) => {
                const data = editor.getData();

                this.onChangeMessageValue(data);
              }}
            />
          </div>
        )}
        <div className="at-modal_dialog-container-footer">
          <AtlasButton
            className="btn-new btn-new--secondary"
            disabled={!can_create || onLoadMessage}
            children={"Send"}
            onClick={this.onClickSendMessage}
          />
          {onLoadMessage && (
            <PulseLoader
              className={"d-flex align-items-center"}
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onLoadMessage}
            />
          )}
        </div>
      </>
    );
  };
}

export default SubsaleMessageHOC(AtlasMessageTab);
