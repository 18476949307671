import React, { Component } from "react";
import _ from "lodash";

import StatusModal from "../components/statusModal";

const ResendVerificationHOC = (WrappedComponent) => {
  class ResendVerificationWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",

      verificationOtpTimeOut: 60,
      verificationOtpIntervalId: "",
      verificationTacTimeOut: 60,
      verificationTacIntervalId: "",
    };

    load = (param) => this.setState({ loading: param });

    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    verificationOtpTimeOutCounting = () => {
      this.state.verificationOtpTimeOut !== 0
        ? this.setState({
            verificationOtpTimeOut: this.state.verificationOtpTimeOut - 1,
          })
        : this.stopOtpCountDown();
    };
    verificationTacTimeOutCounting = () => {
      this.state.verificationTacTimeOut !== 0
        ? this.setState({
            verificationTacTimeOut: this.state.verificationTacTimeOut - 1,
          })
        : this.stopTacCountDown();
    };

    resetTacCountDown = () => this.setState({ verificationTacTimeOut: 60 });

    resetOtpCountDown = () => this.setState({ verificationOtpTimeOut: 60 });

    stopOtpCountDown = () =>
      clearInterval(this.state.verificationOtpIntervalId);

    stopTacCountDown = () =>
      clearInterval(this.state.verificationTacIntervalId);

    resendOtpCountDownStarts = () => {
      var temp = setInterval(this.verificationOtpTimeOutCounting, 1000);
      this.setState({ verificationOtpIntervalId: temp });
    };

    resendTacCountDownStarts = () => {
      var temp = setInterval(this.verificationTacTimeOutCounting, 1000);
      this.setState({ verificationTacIntervalId: temp });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            verificationOtpTimeOut={this.state.verificationOtpTimeOut}
            verificationTacTimeOut={this.state.verificationTacTimeOut}
            resendTacCountDownStarts={this.resendTacCountDownStarts}
            resendOtpCountDownStarts={this.resendOtpCountDownStarts}
            resetOtpCountDown={this.resetOtpCountDown}
            resetTacCountDown={this.resetTacCountDown}
            stopOtpCountDown={this.stopOtpCountDown}
            stopTacCountDown={this.stopTacCountDown}
            verificationOtpTimeOutCounting={this.verificationOtpTimeOutCounting}
            verificationTacTimeOutCounting={this.verificationTacTimeOutCounting}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClick={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  return ResendVerificationWrappedComponent;
};

export default ResendVerificationHOC;
