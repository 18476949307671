import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError } from "utils/requestHandler";

const AnnouncementHOC = (WrappedComponent) => {
  class AnnouncementWrappedComponent extends Component {
    state = {
      announcements: [],
      selectedAnnouncement: {},
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getAnnouncements = () =>
      Get(
        `/admin/announcements/current`,
        this.getAnnouncementsSuccess,
        this.getAnnouncementsError,
        this.load,
      );
    getAnnouncementsSuccess = (payload) => {
      let tmp = [];
      if (payload && payload.length > 0) {
        payload.map((item) => {
          tmp.push({
            ...item,
            published: item.published ? "Yes" : "No",
          });
        });
      }
      return this.setState({ announcements: tmp });
    };
    getAnnouncementsError = (error) => requestError(error);

    getSelectedAnnouncement = (id, type) =>
      Get(
        `/admin/announcements/${id}`,
        (payload) => this.getSelectedAnnouncementSuccess(payload, type),
        this.getSelectedAnnouncementError,
        this.load,
      );
    getSelectedAnnouncementSuccess = (payload, type) =>
      this.setState({
        selectedAnnouncement: payload,
        showDetailsModal: type === "details",
        showEditModal: type === "edit",
      });
    getSelectedAnnouncementError = (error) => requestError(error);

    markAnnouncementAsRead = (id) => {
      let data = { ids: [id] };
      Put(
        `/admin/announcements/mark_read`,
        data,
        this.markAnnouncementAsReadSuccess,
        this.markAnnouncementAsReadError,
        this.load,
      );
    };
    markAnnouncementAsReadSuccess = () => {};
    markAnnouncementAsReadError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          announcements={this.state.announcements}
          selectedAnnouncement={this.state.selectedAnnouncement}
          onLoadAnnouncement={this.state.loading}
          getAnnouncements={this.getAnnouncements}
          getSelectedAnnouncement={this.getSelectedAnnouncement}
          markAnnouncementAsRead={this.markAnnouncementAsRead}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(AnnouncementWrappedComponent);
};

export default AnnouncementHOC;
