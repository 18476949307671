import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    resendContract = (dataToSubmit) =>
      Post(
        `/admin/e_processes/resend_email`,
        dataToSubmit,
        this.resendContractSuccess,
        this.resendContractError,
        this.load,
      );
    resendContractSuccess = (payload) => requestSuccess(payload.message);
    resendContractError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadResend={this.state.loading}
            resendContract={this.resendContract}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
