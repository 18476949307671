import React, { Component } from "react";
import _ from "lodash";
import { Card, MenuItem } from "@material-ui/core";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import CustomRadioGroup from "components/RadioGroup";
import AtInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import TableContent from "./content";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import "../../index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
  };

  renderTableHeader = () => {
    return (
      <ul className="d-flex at-order_history-table_header_cont">
        {this.props.headerData.map((header) => (
          <li
            key={header.label.trim().replace(" ", "")}
            className={`at-order_history-table_header ${header.contentClass || ""}`}
            style={{ width: "25%", ...header.style }}
          >
            {header.label}
          </li>
        ))}
        <li className="at-order_history-table_header at-order_history-table_header_action">
          Actions
        </li>
      </ul>
    );
  };

  renderTableContent = () => {
    if (this.props.rowData && this.props.rowData.length > 0) {
      return this.props.rowData.map((item) => (
        <TableContent
          key={item.id}
          data={item}
          toPayId={this.props.toPayId}
          headerData={this.props.headerData}
          can_create={this.props.can_create}
          can_update={this.props.can_update}
          statusDictionary={this.props.statusDictionary}
          onChangeOrderHistoryHOC={this.props.onChangeOrderHistoryHOC}
          redoPayment={this.props.redoPayment}
          onToggleResubmit={this.props.onToggleResubmit}
          onClickDetails={this.props.onClickDetails}
        />
      ));
    }

    return (
      <EmptyState
        title={`No orders available`}
        renderIcon={<AtlasIcon svgHref="atlas-note" />}
      />
    );
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>
        to
        <span> {meta && meta.to} </span>
        from a total of
        <span> {meta && meta.count} </span>
        row
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp = tmp + `q[${item.value}]=${item.param}&`;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param}&`;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    return (
      <div className="at-table__options mb-10">
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="row mb-3">
            {this.props.searchParams.map((item, index) => {
              return (
                <div key={item.value} className="col-md-6">
                  <label>{item.label}</label>
                  {item.type === "input" && (
                    <AtInput
                      value={item.param}
                      onChangeValue={(value) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = value;
                        return this.props.onChangeSearchParams(tmp);
                      }}
                    />
                  )}
                  {item.type === "select" && (
                    <AtSelectNew
                      placeholder={item.placeholder ? item.placeholder : ""}
                      value={item.param}
                      onChange={(e) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = e.target.value;
                        return this.props.onChangeSearchParams(tmp);
                      }}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {item.options &&
                        item.options.length > 0 &&
                        item.options.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              style={{ width: "100%", fontSize: 14 }}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                    </AtSelectNew>
                  )}
                  {item.type === "radio" && (
                    <CustomRadioGroup
                      checkedValue={item.param}
                      containerClassName={"mb-20"}
                      options={item.options}
                      selectedRadioValue={(e) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = e.target.value;
                        return this.props.onChangeSearchParams(tmp);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="d-flex" style={{ marginBottom: 15 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                let tmp = "";
                this.props.searchParams.map((item) => {
                  tmp = tmp + `q[${item.value}]=${item.param}&`;
                });
                this.setState({ interval: 10 });
                this.props.getListAPI(1, tmp);
              }}
            >
              Search
            </button>
            <button
              className="btn btn-danger"
              style={{ marginLeft: 10 }}
              onClick={() => {
                let tmp = _.cloneDeep(this.props.searchParams);
                tmp.map((item) => {
                  item.param = "";
                });
                this.setState({ interval: 10 });
                this.props.onChangeSearchParams(tmp);
                this.props.getListAPI(1, "");
              }}
            >
              Reset
            </button>
          </div>
        )}
        <Card className="at-order_history-table_cont">
          {this.renderTableHeader()}
          {this.renderTableContent()}
        </Card>
        {this.props.meta && this.props.meta.count > 0 && (
          <div
            className="at-pagination__cont"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {this.renderTablePagination()}
            {this.renderPaginationText()}
          </div>
        )}
      </div>
    );
  };
}

export default PaginatedTable;
