import React, { Component } from "react";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import EditContent from "./component/Edit";

import ReportNewSalesHOC from "./actions";
import { numberWithCommas } from "utils/thousandSeparator";
import { StatusColor } from "./assets";
import { getColorBadge } from "dictionary/badgeColor";

const ColumnData = [
  {
    header: "Date",
    accessor: "booking_date",
    columnStyle: { width: "70%" },
  },
  {
    header: "Project",
    accessor: "project_name",
    columnStyle: { width: "120%" },
  },
  {
    header: "Unit Number",
    accessor: "unit_number",
    columnStyle: { width: "70%" },
  },
  {
    header: "Agent",
    renderColumn: (rowData) => (
      <>
        {rowData.agent.full_name && <p>{rowData.agent.full_name}</p>}
        {rowData.agent.email && <p>{rowData.agent.email}</p>}
        {rowData.agent.mobile_number && <p>{rowData.agent.mobile_number}</p>}
      </>
    ),
  },
  {
    header: "Buyer",
    accessor: "buyer_name",
    columnStyle: { width: "90%" },
  },
  {
    header: "Net Price",
    contentClass: "at-column-responsive_right",
    customClass: "at-column-responsive_right",
    renderColumn: (rowData) => (
      <p className="text-lg-right">{numberWithCommas(rowData.nett_price)}</p>
    ),
    columnStyle: { width: "80%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
    columnStyle: { width: "110%" },
  },
];

class AdminSalesManagement extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getReportSales(1, "");
  };

  renderCardContent = () => {
    return (
      <AtPaginatedTable
        columns={ColumnData}
        actionColumnContent={[
          {
            name: "edit",
            onClick: (rowData) => this.props.getSelectedSales(rowData.id),
          },
        ]}
        rowData={this.props.reportSales.data ? this.props.reportSales.data : []}
        meta={this.props.reportSales.meta && this.props.reportSales.meta}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeSalesHOC(val, "searchParams")
        }
        getListAPI={this.props.getReportSales}
        totalPages={this.props.reportPage}
      />
    );
  };

  render = () => {
    const { onLoadSales, onLoadRegion, showDetailDialog } = this.props;

    return (
      <>
        <ModuleHeader
          title={`Project Sales`}
          moduleIcon={"icon-my-sales"}
          description={`Add and manage your new project sales here. When you’ve done a new project sale,you should report it as quickly as possible by clicking the “new” icon above. Enter all the details for the unit, upload a copy of the booking form andsubmit for the admin team to approve.`}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={showDetailDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={<EditContent {...this.props} />}
          />
        </AtlasDialog>
        {(onLoadSales || onLoadRegion) && <LoadingModal />}
      </>
    );
  };
}

export default ReportNewSalesHOC(AdminSalesManagement);
