import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedLanguage: "",

      showCreateModal: false,
      showUpdateModal: false,
    }

    load = (param) => this.setState({ loading: param });
    onChangeLocalisedSettingHOC = (val, context) => this.setState({ [context]: val });

    createLocalisedSetting = dataToSubmit => Post(
      `/admin/localization_settings`,
      dataToSubmit,
      this.createLocalisedSettingSuccess,
      this.createLocalisedSettingError,
      this.load
    )
    createLocalisedSettingSuccess = (payload) => {
      requestSuccess('Setting created successfully');
      this.setState({ showCreateModal: false });
      this.props.getAllLocalisedSetting();
    }
    createLocalisedSettingError = (error) => requestError(error);

    updateLocalisedSetting = dataToSubmit => Put(
      `/admin/localization_settings/${this.state.selectedLanguage.id}`,
      dataToSubmit,
      this.updateLocalisedSettingSuccess,
      this.updateLocalisedSettingError,
      this.load
    )
    updateLocalisedSettingSuccess = (payload) => {
      requestSuccess('Setting updated successfully');
      this.setState({ showUpdateModal: false });
      this.props.getAllLocalisedSetting();
    }
    updateLocalisedSettingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadLocalisedSetting={this.state.loading}

            createLocalisedSetting={this.createLocalisedSetting}
            updateLocalisedSetting={this.updateLocalisedSetting}
            onChangeLocalisedSettingHOC={this.onChangeLocalisedSettingHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
