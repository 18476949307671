import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import { FormControl } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import { registerPlugin } from "react-filepond";

import CustomTab from "components/Tab";
import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTextarea from "components/Input/textarea";
import CustomFormInput from "components/Input/formInput";
import CustomImageUploader from "components/Input/imageUpload";
import VariantOptionsContent from "./variantOptions";
import TicketPurchaserContent from "./ticketPurchaser";
import EventListModal from "./eventList";
import PreOrderContent from "./preOrder";
import ItemList from "./itemList";

import AgentHOC from "actions/agentSearch";
import EditProductHOC from "../actions/edit";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import isEmptyValue from "utils/isEmpty";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const typeDictionary = [
  { id: "1", label: "Starter Kit" },
  { id: "2", label: "Name Card" },
  { id: "3", label: "Event Ticket" },
  { id: "4", label: "Regular" },
];

const statusDictionary = [
  { id: "1", label: "Active" },
  { id: "2", label: "Inactive" },
];

class EditDialog extends Component {
  componentDidMount = () => {
    this.props.onChangeEditProductHOC(
      this.props.selectedProduct,
      "editProductForm",
    );
  };

  componentDidUpdate = (pp) => {
    if (pp.selectedProduct !== this.props.selectedProduct) {
      this.props.onChangeEditProductHOC(
        this.props.selectedProduct,
        "editProductForm",
      );
    }
  };

  onAddSubItem = () => {
    const tmp = _.cloneDeep(this.props.editProductForm);
    tmp.market_product_attached_items_attributes.push({ item_name: "" });
    this.props.onChangeEditProductHOC(tmp, "editProductForm");
  };

  onCheckUpdateButton = () => {
    let tmpEditProduct = _.cloneDeep(this.props.editProductForm);
    delete tmpEditProduct.photo_file_name;
    delete tmpEditProduct.market_product_attached_items;
    delete tmpEditProduct.market_product_attached_items_attributes;
    delete tmpEditProduct.attached_product_id;
    delete tmpEditProduct.attached_product_type;
    if (tmpEditProduct.type_id !== "3") {
      delete tmpEditProduct.attached_product;
    }

    return _.values(tmpEditProduct).some((productValue) => {
      if (Array.isArray(productValue)) {
        if (productValue.length === 0) {
          return false;
        }
        return productValue.some((item) => {
          return _.values(item).some((value) => isEmptyValue(value));
        });
      }
      return isEmptyValue(productValue);
    });
  };

  onChangeEditData = (val, context) => {
    let temp = _.cloneDeep(this.props.editProductForm);
    if (context === "has_variants" && val) {
      temp.price = "0";
    } else if (context === "type_id" && ["1", "3"].indexOf(val) > -1) {
      temp.has_variants = false;
    }
    temp[context] = val;
    this.props.onChangeEditProductHOC(temp, "editProductForm");
  };

  onChangeVariantPrice = (val, index) => {
    let temp = _.cloneDeep(this.props.editProductForm.product_variants);
    temp[index]["price"] = val;
    this.onChangeEditData(temp, "product_variants");
  };

  onChangeFile = (fileItems) => {
    let temp = _.cloneDeep(this.props.editProductForm);

    if (fileItems.length > 0 && fileItems[0].file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          temp.photo_file_name = fileItems[0].file.name;
          temp.photoSource = e.target.result;
          this.props.onChangeEditProductHOC(temp, "editProductForm");
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      temp.photoSource = "";
      temp.photo_file_name = "";
      this.props.onChangeEditProductHOC(temp, "editProductForm");
    }
  };

  onRemoveFile = () => {
    let temp = _.cloneDeep(this.props.editProductForm);

    temp.photoSource = "";
    temp.photo_file_name = "";
    this.props.onChangeEditProductHOC(temp, "editProductForm");
  };

  render = () => {
    const {
      selectedProduct,
      editProductForm,
      editProductForm: {
        id,
        name,
        price,
        unit_measurement,
        photoSource,
        country_id,
        type_id,
        description,
        status_id,
        has_variants,
        delivery_branch_selectable,
        allow_pre_order,
        is_quantity_fixed,
        is_complimentary = false,
        photo_file_name,
        attached_product,
        attached_product_id,
        attached_product_type,
        product_options,
        product_variants,
        market_product_attached_items_attributes = [],
      },
      updateProduct,
      selectedSection,
      updateVariantOptions,
      onLoadProductMngmt,
    } = this.props;
    const { can_update } = this.props.permissionsChecker;

    const renderProductDetail = () => (
      <>
        <div className={"row"}>
          <div className={"col-lg-6"}>
            <label className="at-form-input__title" required>
              {"Name"}
            </label>
            <CustomFormInput
              type="text"
              value={name}
              disabled={!can_update}
              onChangeValue={(val) => this.onChangeEditData(val, "name")}
              required={true}
              placeholder={"Enter product name here"}
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Country"}
            </label>
            <CustomSelect
              className="mb-30 w-100"
              required={true}
              disabled={!can_update}
              selectItems={this.props.data.dictionaryReducer.countries}
              currentlySelected={country_id}
              updateSelect={(val) => this.onChangeEditData(val, "country_id")}
              placeholder={"Please choose a country"}
            />
          </div>
          <div className={"col-lg-6"}>
            <label className={"at-form-input__title"} required>
              {"Unit Price"}
            </label>
            {!has_variants && (
              <CustomFormInput
                type="number"
                value={price}
                disabled={!can_update}
                onChangeValue={(val) => this.onChangeEditData(val, "price")}
                required={true}
                placeholder={"Enter product's price here"}
              />
            )}
            {has_variants && (
              <p style={{ fontSize: 14, fontWeight: 500, color: "#D92D20" }}>
                You may setup the price in the variants list
              </p>
            )}
          </div>
          <div className={"col-lg-6"}>
            <label className="at-form-input__title" required>
              {"Unit Measurement"}
            </label>
            <CustomFormInput
              type="text"
              value={unit_measurement}
              onChangeValue={(val) =>
                this.onChangeEditData(val, "unit_measurement")
              }
              required={true}
              placeholder={"Enter product's unit measurement here"}
            />
          </div>
          <div className={"col-lg-6 mb-10"}>
            <label className={"at-form-input__title"} required>
              {"Type"}
            </label>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              checkedValue={type_id}
              disabled={!can_update}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                this.onChangeEditData(val, "type_id")
              }
              options={typeDictionary}
            />
            <CustomCheckbox
              labelStyle={{ marginBottom: 10, display: "flex" }}
              checked={is_complimentary}
              disabled={!can_update}
              content={"Complimentary (FOC)/For New REN"}
              onChangeCheckboxValue={(e) =>
                this.onChangeEditData(e.target.checked, "is_complimentary")
              }
            />
            <CustomCheckbox
              labelStyle={{ marginBottom: 10, display: "flex" }}
              checked={is_quantity_fixed}
              disabled={!can_update}
              content={"Fixed order quantity"}
              onChangeCheckboxValue={(e) =>
                this.onChangeEditData(e.target.checked, "is_quantity_fixed")
              }
            />
            {type_id === "2" && (
              <CustomCheckbox
                labelStyle={{ marginBottom: 10, display: "flex" }}
                checked={has_variants}
                content={"This product has multiple options."}
                onChangeCheckboxValue={(e) =>
                  this.onChangeEditData(e.target.checked, "has_variants")
                }
              />
            )}
            {type_id === "4" && (
              <CustomCheckbox
                labelStyle={{ marginBottom: 10, display: "flex" }}
                checked={allow_pre_order}
                content={"Pre-Order"}
                onChangeCheckboxValue={(e) =>
                  this.onChangeEditData(e.target.checked, "allow_pre_order")
                }
              />
            )}
            <CustomCheckbox
              labelStyle={{ marginBottom: 20, display: "flex" }}
              checked={delivery_branch_selectable}
              content={"Delivery branch is required"}
              onChangeCheckboxValue={(e) =>
                this.onChangeEditData(
                  e.target.checked,
                  "delivery_branch_selectable",
                )
              }
            />
            {type_id === "2" && has_variants && (
              <div className={"at-form__content"}>
                <label className={"at-form-input__title"}>
                  {"Variant List"}
                </label>
                <hr />
                {product_variants &&
                  product_variants.map((item, index) => (
                    <div
                      key={`product-variant-${index}`}
                      className={
                        "d-flex justify-content-between align-items-center mb-1"
                      }
                    >
                      <p style={{ fontSize: 14, fontWeight: 500 }}>
                        {item.options.map(
                          (option, index) =>
                            `${option.value}${index + 1 !== item.options.length ? "/" : ""}`,
                        )}
                      </p>
                      <CustomFormInput
                        type="text"
                        value={item.price}
                        disabled={!can_update}
                        containerStyle={{ marginBottom: 0, padding: 5 }}
                        style={{ height: 30, width: 60 }}
                        onChangeValue={(val) =>
                          this.onChangeVariantPrice(val, index)
                        }
                        placeholder={"Price"}
                      />
                    </div>
                  ))}
                {product_variants && product_variants.length === 0 && (
                  <p style={{ fontSize: 14, fontWeight: 500 }}>
                    No variant listed.
                  </p>
                )}
                <CustomButton
                  className={"btn-new-sm btn-new--outline-secondary mt-10"}
                  children={"Edit Variant Options"}
                  onClick={() =>
                    this.props.onChangeEditProductHOC(
                      true,
                      "showVairantOptions",
                    )
                  }
                />
              </div>
            )}
          </div>
          {type_id && type_id === "3" && (
            <div className={"col-lg-6 mb-20"}>
              <h3 className="at-form-input__title">Attached Ticket Type</h3>
              {attached_product_type === "TicketType" &&
                attached_product !== null && (
                  <div className="at-product__ticket">
                    <p>
                      <span>Event Date</span>
                      {Moment(attached_product?.event.start_date_time).format(
                        "DD MMM YYYY",
                      )}
                    </p>
                    <p>
                      <span>Event Name</span>
                      {attached_product?.event.name}
                    </p>
                    <p>
                      <span>Ticket Type</span>
                      {attached_product?.type}
                    </p>
                    <CustomButton
                      containerClass={"w-100 m-2"}
                      className="btn-new btn-new--danger"
                      onClick={() =>
                        this.props.removeAttachTicket(id, attached_product_id)
                      }
                      children={"Remove"}
                    />
                  </div>
                )}
              {(attached_product_type === null ||
                attached_product === null) && (
                <CustomButton
                  containerClass={"mt-2"}
                  className="btn-new btn-new--secondary"
                  onClick={() => this.props.getAvailableTicket()}
                  children={"Attach Event Ticket"}
                />
              )}
            </div>
          )}
          <div className={"col-lg-6 mb-10"}>
            <label className={"at-form-input__title"} required>
              {"Description"}
            </label>
            <CustomTextarea
              required={true}
              rows="3"
              type="text"
              disabled={!can_update}
              value={description}
              onChangeValue={(value) =>
                this.onChangeEditData(value, "description")
              }
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div className={"col-lg-6 mb-20"}>
            <FormControl style={{ width: "100%" }}>
              <p className={"at-form-input__title"} required>
                Photo
              </p>
              <CustomImageUploader
                fullWidth
                keys={{
                  source: "source",
                  name: "name",
                }}
                required={true}
                files={
                  photoSource
                    ? [
                        {
                          source: photoSource,
                          name: photo_file_name,
                        },
                      ]
                    : []
                }
                ref={(ref) => (this.pond = ref)}
                allowMultiple={false}
                acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                accept="image/png, image/jpeg, image/jpg"
                maxFiles={1}
                onChangeFile={(fileItems) => this.onChangeFile(fileItems)}
                onRemoveFile={this.onRemoveFile}
              />
            </FormControl>
          </div>
          {type_id && type_id === "1" && (
            <div className="col-lg-6 pl-lg-5 pt-3">
              <div className="d-flex p-1 grid_gap-2">
                <CustomButton
                  className={"btn-new btn-new--primary"}
                  onClick={this.onAddSubItem}
                  disabled={!can_update}
                  children={
                    <>
                      <IoMdAdd
                        style={{ height: 18, width: 18, marginRight: 5 }}
                      />
                      Add new sub-item
                    </>
                  }
                />
              </div>
              <div>
                {market_product_attached_items_attributes.map((item, index) => (
                  <ItemList
                    key={index}
                    disabled={!can_update}
                    onClickDelete={() => {
                      const tmp = _.cloneDeep(this.props.editProductForm);
                      if (
                        tmp.market_product_attached_items_attributes[index] &&
                        tmp.market_product_attached_items_attributes[index].id
                      ) {
                        this.props.deleteProductSubItem(
                          tmp.id,
                          tmp.market_product_attached_items_attributes[index]
                            .id,
                          index,
                        );
                      } else {
                        tmp.market_product_attached_items_attributes.splice(
                          index,
                          1,
                        );
                        this.props.onChangeEditProductHOC(
                          tmp,
                          "editProductForm",
                        );
                      }
                    }}
                    onChangeText={(value) => {
                      const tmp = _.cloneDeep(this.props.editProductForm);
                      const { market_product_attached_items_attributes } = tmp;
                      if (
                        !market_product_attached_items_attributes[index]
                          .edited &&
                        market_product_attached_items_attributes[index].id
                      ) {
                        tmp.market_product_attached_items_attributes[
                          index
                        ].edited = true;
                      }
                      tmp.market_product_attached_items_attributes[
                        index
                      ].item_name = value;
                      this.props.onChangeEditProductHOC(tmp, "editProductForm");
                    }}
                    value={item.item_name}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={"col-lg-6 mt-3"}>
            <label className={"at-form-input__title"}>{"Status"}</label>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              disabled={!can_update}
              checkedValue={status_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                this.onChangeEditData(val, "status_id")
              }
              options={statusDictionary}
            />
          </div>
        </div>
        <div className="d-flex mt-20">
          {can_update && (
            <button
              // disabled={ this.onCheckUpdateButton() }
              className={"btn-new btn-new--success"}
              onClick={() => {
                let tmp = {
                  ...editProductForm,
                  market_product_attached_items_attributes:
                    editProductForm.market_product_attached_items_attributes
                      .filter((item) => {
                        return (item.id && item.edited) || !item.id;
                      })
                      .map((item) => {
                        delete item.edited;
                        return item;
                      }),
                };
                updateProduct(tmp);
              }}
            >
              Update
            </button>
          )}
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => this.props.onClose()}
          >
            Close
          </button>
        </div>
      </>
    );

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => this.props.onClose()} />
          <h4 className="at-card__title">Edit Product</h4>
        </div>
        {type_id === "4" && allow_pre_order && (
          <CustomTab
            sections={["Details", "Pre-Order Namelist"]}
            selectedSection={selectedSection}
            onSelectSection={(val) =>
              this.props.onChangeEditProductHOC(val, "selectedSection")
            }
          />
        )}
        {type_id === "3" && (
          <CustomTab
            sections={["Details", "Purchasers"]}
            selectedSection={selectedSection}
            onSelectSection={(val) =>
              this.props.onChangeEditProductHOC(val, "selectedSection")
            }
          />
        )}
        {selectedSection === "Details" && renderProductDetail()}
        {selectedSection === "Pre-Order Namelist" && (
          <PreOrderContent
            agentList={this.props.agentList}
            onLoadAgent={this.props.onLoadAgent}
            searchParams={this.props.searchParams}
            showAddNameList={this.props.showAddNameList}
            showRemoveNameList={this.props.showRemoveNameList}
            onLoadEditProduct={this.props.onLoadEditProduct}
            editProductForm={this.props.editProductForm}
            preOrderNameList={this.props.preOrderNameList}
            preOrderNameListPages={this.props.preOrderNameListPages}
            onLoadProductMngmt={this.props.onLoadProductMngmt}
            onChangeAgentHOC={this.props.onChangeAgentHOC}
            getOrderNameList={this.props.getOrderNameList}
            getAgentsBySearch={this.props.getAgentsBySearch}
            createPreOrderName={this.props.createPreOrderName}
            removePreOrderName={this.props.removePreOrderName}
            onChangeEditProductHOC={this.props.onChangeEditProductHOC}
          />
        )}
        {selectedSection === "Purchasers" && (
          <TicketPurchaserContent
            agentList={this.props.agentList}
            onLoadAgent={this.props.onLoadAgent}
            tickerSearchPrams={this.props.tickerSearchPrams}
            showTransferTicket={this.props.showTransferTicket}
            editProductForm={this.props.editProductForm}
            ticketPurchasers={this.props.ticketPurchasers}
            onLoadEditProduct={this.props.onLoadEditProduct}
            ticketPurchasersPages={this.props.ticketPurchasersPages}
            ticketTransfer={this.props.ticketTransfer}
            onChangeAgentHOC={this.props.onChangeAgentHOC}
            getAgentsBySearch={this.props.getAgentsBySearch}
            getTicketPurchasers={this.props.getTicketPurchasers}
            onChangeEditProductHOC={this.props.onChangeEditProductHOC}
          />
        )}
        {this.props.showEventModal && (
          <EventListModal
            selectedProduct={selectedProduct}
            availableTicket={this.props.availableTicket}
            attachTicket={this.props.attachTicket}
            onClickCloseModal={() =>
              this.props.onChangeEditProductHOC(false, "showEventModal")
            }
          />
        )}
        {this.props.showVairantOptions && (
          <VariantOptionsContent
            onLoad={onLoadProductMngmt}
            product_options={product_options}
            onClose={() =>
              this.props.onChangeEditProductHOC(false, "showVairantOptions")
            }
            updateVariantOptions={(val) =>
              updateVariantOptions({ id: selectedProduct.id, options: val })
            }
          />
        )}
        {onLoadProductMngmt && <LoadingModal />}
      </>
    );
  };
}

export default compose(EditProductHOC, AgentHOC)(EditDialog);
