import React, { Component } from "react";

import "./index.scss";

class InputStepper extends Component {
  render = () => {
    const {
      value,
      inputStepperDiabled,
      itemId,
      inputStyle,
      buttonStyle,
      buttonClassName,
      positiveButtonContent,
      negativeButtonContent,
      onClickPositiveButton,
      onClickNegativeButton,
      onChangeInputValue,
      containerStyle,
    } = this.props;
    return (
      <div className={"d-flex flex-row"} style={containerStyle}>
        <button
          disabled={inputStepperDiabled || value < 2}
          style={buttonStyle}
          className={buttonClassName}
          onClick={() =>
            onClickNegativeButton({ id: itemId, quantity: value - 1 })
          }
        >
          {negativeButtonContent}
        </button>
        <input
          disabled={inputStepperDiabled}
          className="at-input__disabled-arrow"
          style={{ maxWidth: 30, textAlign: "center", ...inputStyle }}
          value={value}
          type={"number"}
          onChange={(e) => {
            let temp = e.target.value;
            onChangeInputValue({
              id: itemId,
              quantity: temp.length < 1 ? 0 : parseInt(e.target.value),
            });
          }}
        />
        <button
          disabled={inputStepperDiabled}
          style={buttonStyle}
          className={buttonClassName}
          onClick={() =>
            onClickPositiveButton({ id: itemId, quantity: value + 1 })
          }
        >
          {positiveButtonContent}
        </button>
      </div>
    );
  };
}

export default InputStepper;
