import React, { Component } from "react";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Put } from "utils/axios";

const QR = (WrappedComponent) => {
  class WithQR extends Component {
    state = {
      loading: false,
      type_id: "",
      showRegenConfirmation: false,

      onClickStatusModalButton: "",
      onClickCancelModal: "",
    };

    onChangeQRHOC = (val, context, typeId) =>
      this.setState({
        [context]: val,
        type_id: typeId,
      });

    load = (param) => this.setState({ loading: param });

    generateQR = (user_id) =>
      Put(
        `/admin/users/${user_id}/qr_codes/generate`,
        { type_id: this.state.type_id },
        () => this.generateQRSuccess(user_id),
        this.generateQRError,
        this.load,
      );
    generateQRSuccess = (user_id) => {
      this.props.getSelectedUser(user_id);
      requestSuccess("QR generated successfully!");
      this.setState({
        showRegenConfirmation: false,
        type_id: "",
      });
    };
    generateQRError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadQR={this.state.loading}
            showRegenConfirmation={this.state.showRegenConfirmation}
            generateQR={this.generateQR}
            onChangeQRHOC={this.onChangeQRHOC}
          />
        </>
      );
    };
  }
  return WithQR;
};

export default QR;
