import React, { Component } from "react";
import _ from "lodash";
import { MdClose } from "react-icons/md";

import CustomCheckbox from "components/Checkbox";

import { modulesPermissions } from "../../../permissionLists";

class Content extends Component {
  renderTableContentGrid(
    index,
    content,
    columnLabel,
    columnValue,
    rowItem,
    width,
  ) {
    let currentModule = _.find(modulesPermissions, {
      moduleName: rowItem.module_name,
    });
    let disabledFlag =
      currentModule &&
      currentModule.checkboxes &&
      currentModule.checkboxes.indexOf(columnValue) === -1;
    let mobileView = window.innerWidth < 768;

    return (
      <li
        key={index}
        className={`at-table-row__item at-table__${index}`}
        style={{
          width: mobileView && columnValue !== "module_name" ? "45%" : width,
          ...(mobileView && columnValue !== "module_name"
            ? { paddingTop: "0px" }
            : {}),
        }}
      >
        {(columnValue === "module_name" || !mobileView) && (
          <span className="at-table-row__item-label">{columnLabel}</span>
        )}
        {columnValue === "module_name" && <p>{content}</p>}
        {columnValue !== "module_name" && !disabledFlag && (
          <CustomCheckbox
            checked={content}
            content={mobileView ? columnLabel : ""}
            onChangeCheckboxValue={() =>
              this.props.onToggleCheckBox(columnValue, rowItem.id, !content)
            }
          />
        )}
        {columnValue !== "module_name" && disabledFlag && (
          <>
            <MdClose style={{ width: 20 }} />
            {mobileView && <span>{columnLabel}</span>}
          </>
        )}
      </li>
    );
  }

  render = () => {
    const { headerData, rowItem } = this.props;
    let mobileView = window.innerWidth < 768;

    return (
      <div className="at-table-row-cont">
        <div className="at-table__height-control">
          <ul
            className={`at-table-row ${mobileView ? "d-flex flex-wrap" : ""}`}
          >
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                rowItem[columnItem.value],
                columnItem.label,
                columnItem.value,
                rowItem,
                columnItem.width,
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
}

export default Content;
