import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { GetFile } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    exportTraining = (dataToSubmit) =>
      GetFile(
        `/admin/trainings/export?branch_id=${dataToSubmit.branch_id}&start_date=${dataToSubmit.start_date}&end_date=${dataToSubmit.end_date}`,
        `TrainingAttendance_${dataToSubmit.branch_id}_${dataToSubmit.start_date}_${dataToSubmit.end_date}.xls`,
        () => {},
        this.exportTrainingError,
        this.load,
      );
    exportTrainingError = (error) => requestError(error);

    exportStats = (dataToSubmit) =>
      GetFile(
        `/admin/trainings/get_statistic?start_date=${dataToSubmit.start_date}&end_date=${dataToSubmit.end_date}`,
        `AttendanceStatistics_${dataToSubmit.start_date}_${dataToSubmit.end_date}.csv`,
        () => {},
        this.exportStatsError,
        this.load,
      );
    exportStatsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadExport={this.state.loading}
            exportTraining={this.exportTraining}
            exportStats={this.exportStats}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
