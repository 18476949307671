import React from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import AtlasMultiSelect from "components/Select/multi";
import CustomRadioGroup from "components/RadioGroup";
import SubsalesTooltip from "../../../../components/tooltip";
import "../../../../index.scss";

const BasicInfo = ({
  selectedCategory,
  selectedListingCategory,
  tmpPropertyTypes,
  selectedPropertyType,
  type_id,
  unit_types,
  selectedUnitType,
  code,
  tenure_types,
  selectedTenureType,

  title_types,
  selectedTitleType,
  furnishing_statuses,
  selectedFurnishingStatus,
  occupancies,
  selectedOccupancies,

  getLocalised,
  onChangeValue,
  categories,
}) => {

  const subsaleTypeOptions = [
    { value: 1, label: getLocalised('option.details.details.type_1', 'Sale') },
    { value: 2, label: getLocalised('option.details.details.type_2', 'Rental') }
  ];

  return (
    <div className="grid-control mt-10">
      <section className="grid-full-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title">{getLocalised('label.details.details.code', 'Listing Code')}: {code}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "code",
            }}
          />
        </div>
      </section>
      <section className="grid-full-col">
        <h2 className="at-form-input__title">{getLocalised('label.details.details.type', 'Type')}</h2>
        <CustomRadioGroup
          getLocalised={getLocalised}
          checkedValue={type_id ? type_id : ""}
          options={subsaleTypeOptions}
          selectedRadioValue={(val) => onChangeValue("Type", val)}
        />
      </section>
      <section className="grid-full-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title">{getLocalised('label.details.details.listing_category', 'Listing Category')}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "listingCategory",
            }}
          />
        </div>
        <CustomRadioGroup
          getLocalised={getLocalised}
          checkedValue={selectedCategory ? selectedCategory.id : ""}
          options={categories}
          labelKey={"name"}
          selectedRadioValue={(val) => selectedListingCategory(val)}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>{getLocalised('label.details.details.property_type', 'Property Type')}</h2>
        <AtlasMultiSelect
          required={true}
          getLocalised={getLocalised}
          selectType={"section"}
          className="mb-30 w-100"
          selectItems={tmpPropertyTypes ? tmpPropertyTypes : []}
          updateSelect={(val) => onChangeValue("Property Types", val)}
          currentlySelected={selectedPropertyType}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised('label.details.details.unit_type', 'Unit Type')}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "unitType",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={unit_types}
          currentlySelected={selectedUnitType}
          updateSelect={(val) => onChangeValue("Unit Type", val)}
          getLocalised={getLocalised}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised('label.details.details.tenure', 'Tenure')}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showEdgeTooltip: true,
              toolTip: "tenure",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={tenure_types}
          currentlySelected={selectedTenureType}
          updateSelect={(val) => onChangeValue("Tenure", val)}
          getLocalised={getLocalised}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>{getLocalised('label.details.details.title_type', 'Title Type')}</h2>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={title_types}
          currentlySelected={selectedTitleType}
          updateSelect={(val) => onChangeValue("Title Type", val)}
          getLocalised={getLocalised}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised('label.details.details.furnishing', 'Furnishing')}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showEdgeTooltip: true,
              toolTip: "furnishing",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={furnishing_statuses}
          currentlySelected={selectedFurnishingStatus}
          updateSelect={(val) => onChangeValue("Furnishing Status", val)}
          getLocalised={getLocalised}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>{getLocalised('label.details.details.occupancy', 'Occupancy')}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "occupancies",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={occupancies}
          currentlySelected={selectedOccupancies}
          updateSelect={(val) => onChangeValue("Occupancies", val)}
          getLocalised={getLocalised}
        />
      </section>
    </div>
  );
};

export default BasicInfo;
