import React, { useEffect } from "react";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";

import JIQICafeHOC from "./actions";
import JIQICafeImage from "assets/images/JIQI_Cafe.png";
import "./index.scss";

const JIQICafe = (props) => {
  useEffect(() => {
    props.getMenu();
  }, []);

  return (
    <>
      <ModuleHeader title="JIQI Cafe" atlasIcon={"atlas-jiqi-cafe"} />
      <CustomCard
        cardContent={
          <div className="at-form__content at-jiqi-cafe">
            <img src={JIQICafeImage} alt="JIQI Cafe" />
            <div className="d-flex flex-column">
              <p>Need a break from work?</p>
              <p>
                Caffeinate with the touch of a button at our 26th floor JIQI
                Cafe, now open inside Millerz Square HQ.
              </p>
              <p>Latte, cappuccinos and more by tapping "View Menu"!</p>
            </div>
            <button
              className="btn-new btn-new--primary"
              onClick={() => window.open(props.menuURL, "__blank")}
            >
              View Menu
            </button>
          </div>
        }
      />
    </>
  );
};

export default JIQICafeHOC(JIQICafe);
