import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Dialog } from "@material-ui/core";
import { IoMdAlert } from "react-icons/io";
import { AiFillBell, AiOutlineClose } from "react-icons/ai";
import { FaRegBell, FaExclamationCircle } from "react-icons/fa";

import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import ReviseCommissionModal from "./ReviseCommissionModal";
import CancellationModal from "./CancellationModal";
import SetAppointmentModal from "./SetAppointment";
import RateProjectPartner from "./RatePartner";
import ProjectDetails from "./ProjectDetails";
import SetProjectModal from "./SetProject";
import NotesModal from "./NotesModal";
import Activity from "./Activity";

import defaultSearch from "../assets/searchParams";
import SentColumnData from "../assets/sentColumnData";
import SentPartnerColumn from "../assets/sentPartner";
import { categories as CategoriesDictionary } from "dictionary/subsales";
import permissionsChecker from "utils/permissionsChecker";

const countryIndex = 2;
const stateIndex = 3;

export default function SentRequest({
  data,
  primaryTab,
  currentTab,
  projectList,
  showActivity,
  showNotesModal,
  showRatePartner,
  showCancellation,
  showSelectProject,
  showConcludeModal,
  showSetAppointment,
  showProjectDetails,
  onLoadCobroke,
  completed_trainings,
  whitelisted,

  createNote,
  updateNote,
  deleteNote,
  downloadCobroke,
  concludeCobroke,
  getProjectListing,
  onChangeCobrokeHOC,
  getPropertyDetails,
  sentCobrokeRequests,
  updateCobrokeStatus,
  updateCommissionRate,
  updateCobrokeProject,
  getSentCobrokeRequest,
  getSentPartnerRequest,
  updateReviceCommStatus,
  updateProjectSalesCycle,
  sentCobrokeRequestsPages,
}) {
  const { id, status_id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const { can_update } = permissionsChecker("Co Broke Center", data);
  const [selectedCobroke, setSelectedCobroke] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showReviseCommission, onToggleReviseCommission] = useState(false);
  const [showConfirmationModal, onToggleConfirmationModal] = useState(false);
  const [showReviseConfirmation, onToggleReviseConfirmation] = useState(false);
  const [confirmationMode, setConfirmationMode] = useState("");
  const [searchParams, onChangeSearchParams] = useState(
    defaultSearch(data.dictionaryReducer.countries),
  );
  const columnData = (param) =>
    primaryTab === "Project Partner"
      ? SentPartnerColumn(param)
      : SentColumnData(param);
  const getSentRequest = (page, search) =>
    primaryTab === "Project Partner"
      ? getSentPartnerRequest(id, page, search)
      : getSentCobrokeRequest(id, page, search);

  useEffect(() => {
    let tempGroup = [];

    CategoriesDictionary.map(({ group_types }) => {
      group_types.map((item) => {
        tempGroup.push(...item.children);
      });
    });
    onChangeSearchParams(
      defaultSearch(data.dictionaryReducer.countries, tempGroup),
    );
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(searchParams);

    if (searchParams.length > 0 && temp[countryIndex].param) {
      let selectedCountry = _.find(data.dictionaryReducer.countries, {
        name: temp[countryIndex].param,
      });
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      onChangeSearchParams(temp);
    }
  }, [searchParams[countryIndex].param]);

  useEffect(() => {
    if (primaryTab === "Project Partner") {
      onChangeCobrokeHOC("ProjectCentre", "requestMode");
    } else {
      onChangeCobrokeHOC("CoBrokeCenter", "requestMode");
    }

    onChangeCobrokeHOC(
      {
        data: [],
        meta: [],
      },
      "sentCobrokeRequests",
    );
    getSentRequest(1, "");
  }, [currentTab, primaryTab]);

  const getProjectDetails = (project_id) => {
    setSelectedProjectId(project_id);
    onChangeCobrokeHOC(true, "showProjectDetails");
  };

  const submitUpdateProject = (cobroke_id, project_id) => {
    const { currentPage, searchParams } = data.lastViewReducer.lastView;

    Promise.all([updateCobrokeProject(cobroke_id, project_id)]).then(() => {
      getSentRequest(currentPage, searchParams);
    });
  };

  const submitProjectSalesCycle = (dataToSubmit) => {
    const { currentPage, searchParams } = data.lastViewReducer.lastView;

    Promise.all([updateProjectSalesCycle(dataToSubmit)]).then(() => {
      getSentRequest(currentPage, searchParams);
    });
  };

  const renderDescription = () => {
    if (primaryTab === "Secondary Market") {
      return (
        <>
          {currentTab !== 1 && (
            <div className="at-form__content at-cobroke__tab-description">
              You have sent the following request through the Cobroke Centre.
              You can review those details below. If a potential co-broke
              request was accepted by an agent, click on the item to view
              contact details for the agent and to get in touch with them
              directly.
            </div>
          )}
          {currentTab === 1 && (
            <div className="at-form__content at-cobroke__tab-description">
              You have sent the following request through the Co-broke Centre.
              You can review those details below. If the listing agent accepted
              your potential co-broke request, click on their contact details to
              get in touch with them directly.
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      {renderDescription()}
      <AtPaginatedTable
        showCreateButton={false}
        columns={columnData({
          getProjectDetails,
          setConfirmationMode,
          onToggleConfirmationModal,
          onToggleReviseCommission,
          setSelectedCobroke,
          onToggleConclude: () => onChangeCobrokeHOC(true, "showConcludeModal"),
          disabled: !(status_id !== 2 && completed_trainings) && !whitelisted,
          onChangeCobrokeHOC,
          getPropertyDetails,
          downloadCobroke,
          currentTab,
          can_update,
        })}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeSearchParams(val)}
        meta={sentCobrokeRequests.meta}
        rowData={sentCobrokeRequests.data}
        totalPages={sentCobrokeRequestsPages}
        getListAPI={getSentRequest}
      />
      <Dialog
        open={showReviseCommission}
        classes={{ paper: "at-cobroke-dialog-md" }}
      >
        <ReviseCommissionModal
          selectedCobroke={selectedCobroke}
          setCobroke={(val) => setSelectedCobroke(val)}
          onToggleReviseConfirmation={onToggleReviseConfirmation}
          onClickClose={() => onToggleReviseCommission(false)}
        />
      </Dialog>
      {showActivity && (
        <Activity
          selectedCobroke={selectedCobroke}
          onClose={() => onChangeCobrokeHOC(false, "showActivity")}
        />
      )}
      {showSelectProject && (
        <SetProjectModal
          projectList={projectList}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          getProjectListing={getProjectListing}
          updateCobrokeProject={submitUpdateProject}
          onClose={() => onChangeCobrokeHOC(false, "showSelectProject")}
        />
      )}
      {showSetAppointment && (
        <SetAppointmentModal
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
          updateProjectSalesCycle={submitProjectSalesCycle}
        />
      )}
      {showRatePartner && (
        <RateProjectPartner
          currentTab={currentTab}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
          updateProjectSalesCycle={submitProjectSalesCycle}
          onClose={() => onChangeCobrokeHOC(false, "showRatePartner")}
        />
      )}
      {showNotesModal && (
        <NotesModal
          data={data}
          onLoadCobroke={onLoadCobroke}
          selectedCobroke={selectedCobroke}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getListAPI={getSentRequest}
          setSelectedCobroke={(val) => setSelectedCobroke(val)}
          onClose={() => onChangeCobrokeHOC(false, "showNotesModal")}
        />
      )}
      {showCancellation && (
        <CancellationModal
          title={"Cancel Project Partner Request"}
          message={
            "Enter the reason for cancelling this project partner request."
          }
          onClose={() => onChangeCobrokeHOC(false, "showCancellation")}
          onSubmit={(val) =>
            updateCobrokeStatus(selectedCobroke.id, 5, "", val)
          }
        />
      )}
      {showProjectDetails && (
        <ProjectDetails
          project_id={selectedProjectId}
          showProjectDetails={showProjectDetails}
          onChangeCobrokeHOC={onChangeCobrokeHOC}
        />
      )}
      <ConfirmationModal
        mode={"alert"}
        open={showReviseConfirmation}
        loading={onLoadCobroke}
        message={
          <div className="at-cobroke__revise-confirmation">
            You are about to change the commission proportion of this cobroke
            request. The followings will occur:
            <p>
              <FaExclamationCircle />
              This request status will be set to Pending.
            </p>
            <p>
              <FaRegBell />A notification will be sent to the Request Sender.
            </p>
            <p>
              <FaExclamationCircle />
              Please wait for the Request Sender to either accept or decline the
              new commission proportion.
            </p>
          </div>
        }
        positiveAction={() =>
          Promise.all([updateCommissionRate(selectedCobroke)]).then(() => {
            onToggleReviseConfirmation(false);
          })
        }
        negativeAction={() => onToggleReviseConfirmation(false)}
      />
      <ConfirmationModal
        mode={"alert"}
        open={showConfirmationModal}
        loading={onLoadCobroke}
        message={
          <div className="at-cobroke__revise-confirmation">
            You are about to{" "}
            <b>{confirmationMode === "accept" ? "Accept" : "Reject"}</b> the
            revised commission offered for this co-broke request. The followings
            will occur:
            <p>
              <FaRegBell style={{ width: 14, height: 14, marginRight: 10 }} />
              This request status will be set to{" "}
              <b>{confirmationMode === "accept" ? "Accepted" : "Decline"}</b>.
            </p>
            {confirmationMode === "reject" && (
              <p>
                <FaExclamationCircle
                  style={{ width: 14, height: 14, marginRight: 10 }}
                />
                The accepted Internal Co-broke Agreement will be
                revoked/cancelled.
              </p>
            )}
            {confirmationMode === "accept" && (
              <p>
                <FaExclamationCircle
                  style={{ width: 14, height: 14, marginRight: 10 }}
                />
                The agreed revision of the commission rate will be updated in
                the Internal Cobroke Agreement.
              </p>
            )}
          </div>
        }
        positiveAction={() =>
          Promise.all([
            updateReviceCommStatus(
              selectedCobroke.id,
              confirmationMode === "accept" ? 3 : 4,
            ),
          ]).then(() => {
            onToggleConfirmationModal(false);
          })
        }
        negativeAction={() => onToggleConfirmationModal(false)}
      />
      <ConfirmationModal
        open={showConcludeModal}
        maxWidth={"md"}
        mode={"alert"}
        message={
          <>
            <p>
              You are about to conclude the deal with this Co-broke agent. The
              following will occur:
            </p>
            <p className={"mt-3"}>
              <IoMdAlert
                className={"color-warning"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              This listing status will be set to <strong>Sold/Rent Out</strong>.
            </p>
            <p className={"mt-2"}>
              <AiOutlineClose
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              All other Co-broke agreement will be set to{" "}
              <strong>No Longer Available</strong>
            </p>
            <p className={"mt-2"}>
              <AiFillBell
                className={"color-warning"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              A notification will be sent to any existing Co-broke agents for
              this listing.
            </p>
          </>
        }
        positiveAction={() => concludeCobroke(selectedCobroke.id)}
        negativeAction={() => onChangeCobrokeHOC(false, "showConcludeModal")}
      />
      {onLoadCobroke && <LoadingModal />}
    </>
  );
}
