import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import Moment from "moment";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import CustomDialog from "components/Dialog";
import CustomTable from "components/NewTable";
import CustomButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";
import ConfirmationModal from "components/Modal/delete";
import CustomFormInput from "components/Input/formInput";

const columnData = [
  {
    header: "Date",
    searchFlag: "start_datetime",
    renderColumn: (rowData) =>
      Moment(rowData.attached_event.start_datetime).format("DD MMM YYYY"),
  },
  {
    header: "Event Name",
    searchFlag: "event_name",
    renderColumn: (rowData) => rowData.attached_event.event_name,
  },
  {
    header: "Buttom Label",
    accessor: "button_label",
    searchFlag: "button_label",
  },
];

const AttachEvent = ({
  eventList,
  eventForm,
  onLoadMOTD,
  previewMOTD,
  showAddAttachEvent,

  getEventList,
  putAttachEvent,
  onChangeMOTDHOC,
  deleteAttachedEvent,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState("");

  useEffect(() => {
    getEventList("");
  }, []);

  const onChangeFieldData = (val, context) => {
    let temp = _.cloneDeep(eventForm);
    temp[context] = val;
    onChangeMOTDHOC(temp, "eventForm");
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0 ? getEventList(val) : onChangeMOTDHOC([], "eventList");
    }, 600),
    [],
  );

  const renderCreateModal = () => (
    <CustomDialog open={showAddAttachEvent}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton
                onClick={() => onChangeMOTDHOC(false, "showAddAttachEvent")}
              />
              <h2 className="at-card__title">Add Attach Event</h2>
            </div>
            <div className="grid-control">
              <section className="grid-half-col mb-10">
                <h2 className="at-form-input__title">Event Name</h2>
                <CustomTypeahead
                  disabled={onLoadMOTD}
                  typeaheadId={"attach_event_list"}
                  options={eventList || []}
                  selectedValue={eventForm.event ? [eventForm.event] : []}
                  labelKey={"name"}
                  onSearch={(val) => {
                    onChangeFieldData("", "event");
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && onChangeFieldData(val[0], "event")
                  }
                  filterBy={["name"]}
                  childrenHead={(rowItem) => <p>{rowItem.name}</p>}
                />
              </section>
              <section className="grid-half-col mb-10">
                <h2 className="at-form-input__title">Button Label</h2>
                <CustomFormInput
                  type="text"
                  value={eventForm.label}
                  onChangeValue={(val) => onChangeFieldData(val, "label")}
                  placeholder={"Enter button label"}
                />
              </section>
              <section className="grid-full-col d-flex">
                <CustomButton
                  disabled={_.values(eventForm).some(
                    (item) => item === "" || _.isEmpty(item),
                  )}
                  className="btn-new btn-new--secondary"
                  children={"Attach Now"}
                  onClick={() =>
                    putAttachEvent(previewMOTD.id, {
                      event_id: eventForm.event.id,
                      button_label: eventForm.label,
                    })
                  }
                />
                <CustomButton
                  className="btn-new btn-new--outline-secondary ml-2"
                  children={"Close"}
                  onClick={() => onChangeMOTDHOC(false, "showAddAttachEvent")}
                />
              </section>
            </div>
          </>
        }
      />
    </CustomDialog>
  );

  return (
    <CustomDialog open={true}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton
                onClick={() => onChangeMOTDHOC(false, "showAttachEvent")}
              />
              <h2 className="at-card__title">Attach Event</h2>
              <CustomButton
                containerStyle={{ marginLeft: "auto" }}
                className={"btn-new btn-new--primary"}
                children={
                  <>
                    <IoMdAdd
                      style={{ height: 18, width: 18, margiRight: 10 }}
                    />
                    Add Event
                  </>
                }
                onClick={() => onChangeMOTDHOC(true, "showAddAttachEvent")}
              />
            </div>
            <CustomTable
              className={"mb-100"}
              columns={columnData}
              pagination={true}
              hideSearch={true}
              rowData={previewMOTD.quote_audio_attached_events || []}
              actionColumnContent={[
                {
                  name: "delete",
                  color: "#F04438",
                  onClick: (rowData) =>
                    Promise.all([setDeleteEvent(rowData.id)]).then(() => {
                      setShowDelete(true);
                    }),
                },
              ]}
            />
          </>
        }
      />
      <ConfirmationModal
        open={showDelete}
        message={"Are you sure to delete this attached event?"}
        title={"Delete attached event"}
        showLoading={onLoadMOTD}
        positiveAction={() => {
          Promise.all([setShowDelete(false)]).then(() => {
            deleteAttachedEvent(previewMOTD.id, deleteEvent);
          });
        }}
        negativeAction={() => setShowDelete(false)}
      />
      {renderCreateModal()}
    </CustomDialog>
  );
};

export default AttachEvent;
