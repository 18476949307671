import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Delete } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedDeleteId: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeDeleteHOC = (val, context) => this.setState({ [context]: val });

    deleteCartItem = (id) =>
      Delete(
        `/market_carts/${id}`,
        this.deleteCartItemSuccess,
        this.deleteCartItemError,
        this.load,
      );
    deleteCartItemSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState({ showDeleteConfirmation: false });
      this.props.onChangeMyCartHOC(false, "showDeleteConfirmation");
      requestSuccess(payload.message);
      this.props.getMyCartItems(currentPage, searchParams);
    };
    deleteCartItemError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadDelete={this.state.loading}
            selectedDeleteId={this.state.selectedDeleteId}
            onChangeDeleteHOC={this.onChangeDeleteHOC}
            deleteCartItem={this.deleteCartItem}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
