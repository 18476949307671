import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtlasEmailModal from "components/Modal/email";
import ModuleFormHeader from "components/Form/header";
import AtlasCloseButton from "components/Button/prev";
import AtPaginatedTable from "components/NewPaginatedTable";
import PreviewCobrokeLetterModal from "components/Modal/previewCobrokeLetterModal";
import CobrokeLetter from "./components/tempCobrokeLetter";

import CobrokeLetterHOC from "./actions";
import AgencyHOC from "./actions/agency";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

import { initGA } from "utils/ga";
import { columnData } from "./asset";
import isEmptyValue from "utils/isEmpty";

class CobrokeLetterForm extends Component {
  componentDidMount = () => {
    this.initComponent();
    initGA("/dashboard/admin/forms/cobroke-letter");
  };

  initComponent = () => {
    this.props.getCobrokeLetters(1, "");
    this.props.getAgencies();
  };

  submitEmail = (recipient) =>
    this.props.sendCobrokeLetter(this.props.selectedCobrokeLetter.id, {
      recipients: recipient,
    });

  renderPreviewCobrokeLetterModal = () => {
    return (
      <>
        {this.props.showPreviewCobrokeLetterModal && (
          <PreviewCobrokeLetterModal
            open={this.props.showPreviewCobrokeLetterModal}
            onClickClose={this.props.onChangeCobrokeHOC}
            {...this.props.initialSelectedCobrokeLetter}
          />
        )}
      </>
    );
  };

  renderDetailCobrokeLetterForm = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { id } = this.props.selectedCobrokeLetter;
    const tmpId = `${!isEmptyValue(id) ? `/ ${id}` : ""}`;

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton
            containerClass={"btn-close-edit-cobroke"}
            onClick={() =>
              this.props.onChangeCobrokeHOC(
                false,
                "showEditCobrokeLetterDialog",
              )
            }
          />
          <h4 className="at-card__title">{`${this.props.selectedCobrokeLetter.closed_editing ? "View" : "Edit"} Letter ${tmpId}`}</h4>
        </div>
        {this.props.agencies.length > 0 && (
          <CobrokeLetter
            newForm={false}
            can_update={can_update}
            onLoadCobrokeLetter={this.props.onLoadCobrokeLetter}
            onClose={() =>
              this.props.onChangeCobrokeHOC(
                false,
                "showEditCobrokeLetterDialog",
              )
            }
            agencies={this.props.agencies}
            invoicing_branches={this.props.data.branchReducer.invoicingBranch}
            onChange={this.props.onChangeEditCobrokeLetterVal}
            onSave={() =>
              this.props.updateCobrokeLetter(this.props.selectedCobrokeLetter)
            }
            {...this.props.selectedCobrokeLetter}
            storedAddresses={this.props.data.addressReducer.address}
          />
        )}

        <div className="at-form__content mt-3">
          <ModuleFormHeader
            title={"Actions"}
            className={"mb-3"}
            moduleIcon={"atlas-more-circle"}
          />
          <p className={"d-block mb-3"}>
            Please make sure that you have saved the data by clicking the "Save" button in T&C before proceeding any actions below.
          </p>
          <div className="form-action">
            <button
              className="btn-new btn-new--primary"
              onClick={() =>
                this.props.onChangeCobrokeHOC(
                  true,
                  "showPreviewCobrokeLetterModal",
                )
              }
            >
              {`Preview Cobroke Letter`}
            </button>
            <button
              className="btn-new btn-new--secondary"
              onClick={() =>
                this.props.downloadCobrokeLetter(
                  this.props.selectedCobrokeLetter.id,
                )
              }
            >
              {`Download Co-broke Letter`}
            </button>
            {can_update && (
              <button
                className="btn-new btn-new--secondary"
                onClick={() =>
                  this.props.onChangeCobrokeHOC(true, "showSendEmail")
                }
              >
                {`Send Cobroke Letter`}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  render = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);

    return (
      <>
        <ModuleHeader
          title={"Cobroke Letters"}
          atlasIcon={"atlas-cobroke-letter"}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={this.props.cobrokeLetters.data || []}
              meta={this.props.cobrokeLetters.meta}
              columns={columnData}
              actionColumnContent={[
                {
                  name: can_update ? "edit" : "view",
                  onClick: (rowData) => this.props.getCobrokeLetter(rowData.id),
                },
              ]}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeCobrokeHOC(val, "searchParams")
              }
              getListAPI={this.props.getCobrokeLetters}
              totalPages={this.props.cobrokeLettersPage}
            />
          }
        />
        <AtlasEmailModal
          title={"Send Cobroke Letter"}
          loading={this.props.onLoadCobrokeLetter}
          showSendEmail={this.props.showSendEmail}
          email={this.props.selectedCobrokeLetter.email}
          closeSendEmail={() =>
            this.props.onChangeCobrokeHOC(false, "showSendEmail")
          }
          submitEmail={(recipients) => this.submitEmail(recipients)}
        />
        <AtlasDialog open={this.props.showEditCobrokeLetterDialog}>
          <CustomCard cardContent={this.renderDetailCobrokeLetterForm()} />
        </AtlasDialog>
        {this.renderPreviewCobrokeLetterModal()}
        {(this.props.onLoadCobrokeLetter || this.props.onLoadAgencies) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  CobrokeLetterHOC,
  AgencyHOC,
  VersionCheckHOC,
)(CobrokeLetterForm);
