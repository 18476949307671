import React from 'react'
import _ from "lodash";
import Moment from 'moment'

import AlertBox from 'components/AlertBox'
import AtlasTable from 'components/NewTable'
import ModalDialog from 'components/Modal/ModalDialog'
import AtlasIcon from 'components/Icon/atlasIcon'

import { getColorBadge } from "dictionary/badgeColor";

import './index.scss'

const columnData = [
  {
    header: 'Payment Information',
    accessor: 'created_at',
    searchFlag: 'created_at',
    renderColumn: rowData => (
      <>
        <p>
          <AtlasIcon svgHref={'atlas-calendar'} className='at-booking-detail-icon' style={{marginRight: 4}} />
          {Moment(rowData.created_at).format('DD MMM YYYY')}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-bank'} className='at-booking-detail-icon' style={{marginRight: 4}} />
          {rowData.bank}
        </p>
        <p>
          <AtlasIcon svgHref={'atlas-message-text'} className='at-booking-detail-icon' style={{marginRight: 4}} />
          {rowData.reference}
        </p>
      </>
    )
  },
  {
    header: "Amount",
    accessor: "amount",
    searchFlag: "amount",
    columnStyle: { width: "40%", fontSize: 14 },
    renderColumn: (rowData) => `RM ${rowData.amount}`
  }
]

const StatusColor = [
  { value: "Confirmed", colorName: "Green" },
  { value: "In Use", colorName: "Yellow" },
  { value: "Completed", colorName: "Cyan" },
  { value: "Pending Approval", colorName: "Yellow" },
  { value: "Rejected", colorName: "Red" },
  { value: "Cancelled", colorName: "Red" },
];

const ViewBooking = props => {

  const { colorName = "" } =
    _.find(StatusColor, { value: props.selectedBooking?.status }) || {};

  return(
    <ModalDialog
      title={"Business Lounge Details"}
      fullWidth={true}
      fullHeight={true}
      onClose={() => props.onChangeMyBooking(false, "showBookingDetails")}
      children={
        <>
          <AlertBox
            mode={'info'}
            description={
              <>
                Please contact <a style={{color: '#2563EB'}} href='mailto:@iqiglobal.com' target='_blank'>event@iqiglobal.com</a> for any changes and requirement.
                <p>View <button onClick={()=> props.onChangeMyBooking(true, "showRefundTerms")} style={{color: '#2563EB'}} >refund terms and conditions</button>.</p>
              </>
            } />
          <div className='at-booking-detail__cont'>
            <div className='at-form__content d-flex flex-column g-3 mb-0' style={{gridArea: '1 / 1 / 1'}}>
              <div className='d-flex flex-column'>
                <div
                  className="at-status_badge mb-2"
                  style={{ ...getColorBadge(colorName) }}
                >
                  {props.selectedBooking?.status}
                </div>
                {
                  props.selectedBooking?.status === "Rejected" && (
                    <div className="at-booking-reject-form mt-0 mb-2">
                      { props.selectedBooking?.rejection_reason 
                        ? props.selectedBooking.rejection_reason 
                        : "N/A" 
                      } 
                    </div>
                  )
                }
                <h2 className="mt-1">{props.selectedBooking?.meeting_room_name}</h2>
                <div className='d-flex align-items-center g-3'>
                  <p>
                    <AtlasIcon svgHref={'atlas-location'} className='at-booking-detail-icon' style={{marginRight: 4}} />
                    {props.selectedBooking?.meeting_room_location}
                  </p>
                  <p>
                    <AtlasIcon svgHref={'atlas-user'} className='at-booking-detail-icon' style={{marginRight: 4}} />
                    {props.selectedBooking?.meeting_room_capacity} seats
                  </p>
                </div>
              </div>
              <div className='d-flex flex-column' style={{gap: 12}}>
                <div className='d-flex align-items-center'>
                  <div className="at-badge-icon-sm at-badge-icon-warning">
                    <AtlasIcon svgHref={"atlas-calendar"} />
                  </div>
                  <div className='d-flex flex-column'>
                    <label>Event Subject</label>
                    <p>{props.selectedBooking?.event_subject}</p>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <div className="at-badge-icon-sm at-badge-icon-warning">
                    <AtlasIcon svgHref={"atlas-calendar"} />
                  </div>
                  <div className='d-flex flex-column'>
                    <label>Event Purpose</label>
                    <p>{props.selectedBooking?.purpose || "N/A"}</p>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <div className="at-badge-icon-sm at-badge-icon-warning">
                    <AtlasIcon svgHref={"atlas-tag"} />
                  </div>
                  <div className='d-flex flex-column'>
                    <label>Date Time</label>
                    <p>{(props.selectedBooking?.start_time && props.selectedBooking?.end_time)
                      ? `${Moment(props.selectedBooking?.start_time).format('DD MMM YYYY, hh:mmA')} - ${Moment(props.selectedBooking?.end_time).format('hh:mmA')}`
                      : '-'
                    }</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='at-card-booking-form_pricing' style={{gridArea: '1 / 2 / 3 '}}>
              <h2>Pricing</h2>
              {(props.selectedBooking?.pricing && props.selectedBooking?.pricing.length > 0) && props.selectedBooking?.pricing.map((item, index) => (
                <div key={index}>
                  <div className="d-flex align-items-center g-2">
                    <AtlasIcon svgHref={"atlas-money"} />
                    <label>{item.name}</label>
                  </div>
                  <span style={{ width: '20%' }}>RM {item.price}</span>
                </div>
              ))}
              {(props.selectedBooking?.pricing && props.selectedBooking?.pricing.length > 0) && (
                <div>
                  <label className="w-100 text-right">Price Amount</label>
                  <span style={{minWidth: '20%'}} className="fw-700">RM {props.selectedBooking.pricing.reduce((acc, item) => { return acc + item.price}, 0)}</span>
                </div>
              )}
              {(props.selectedBooking?.add_ons && props.selectedBooking?.add_ons.length > 0) && (
                <>
                  <h2 className='mt-3'>Add On</h2>
                  {props.selectedBooking?.add_ons.map((addon, index) => (
                  <div key={index}>
                    <div className='d-flex flex-column g-2'>
                      <label className="w-100">{addon.name}</label>
                      {addon.description && <span>{addon.description}</span>}
                    </div>
                    <span style={{ width: '20%' }}>
                      RM {addon.price}
                      {addon.quantity > 1 && ` x ${addon.quantity}`}
                    </span>
                  </div>
                  ))}
                  <div>
                    <label className="w-100 text-right">Add On Amount</label>
                    <span style={{minWidth: '20%'}} className="fw-700">RM {props.selectedBooking?.add_ons?.reduce((acc, item) => {
                      if (item.quantity)
                        return acc + (item.price * item.quantity)
                      else
                        return acc + item.price
                    }, 0)}</span>
                  </div>
                </>
              )}
              <div>
                <label className="fs-3 fw-600 w-100 text-right" style={{color: '#F0631D'}}>Total Summary</label>
                <span className="fs-3 fw-600" style={{minWidth: '20%',color: '#F0631D'}}>RM {props.selectedBooking.order_info?.total_amount}</span>
              </div>
            </div>

            <div style={{gridArea: '3 / 2 / 3 / 3'}}>
              <AtlasTable
                columns={columnData}
                rowData={props.selectedBooking.payments || []}
                hideSearch={true}
                hidePagination={true}
                actionColumnContent={[
                  {
                    name: "view-receipt",
                    onShow: rowData => !rowData.is_payment_gateway,
                    onClick: rowData => window.open(rowData.payment_slip, "_blank"),
                  }
                ]}
                renderCheckBoxActions={() => (
                  <h2 style={{padding: '12px 16px'}}>Payment History</h2>
                )}
              />
            </div>
          </div>
          {props.selectedBooking.status_id === 5 && (
            <div className='at-modal_dialog-container-footer'>
              <button
                className='btn-new btn-new--primary'
                onClick={() => props.onChangeMyBooking(true, "showPaymentConfirmation")}
              >
                Make Payment
              </button>
            </div>
          )}
        </>
      } />
  )
}

export default ViewBooking