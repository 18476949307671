import React, { Component } from "react";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import _ from "lodash";
import Moment from "moment";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, GetFile, Put } from "utils/axios";
import { storeSubsalesAddress } from "actions/subsales/address";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Agent Name/Email",
    value: "user_full_name_or_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agency",
    value: "agency_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
];

const CobrokeLetterHOC = (WrappedComponent) => {
  class CobrokeLetterWrappedComponent extends Component {
    state = {
      cobrokeLetters: [],
      cobrokeLettersPage: null,
      disableSubmitButton: false,
      selectedCobrokeLetter: {
        full_name: "",
        email: "",
        contact_number: "",
        ren_tag: "",
      },
      initialSelectedCobrokeLetter: {
        full_name: "",
        email: "",
        contact_number: "",
        ren_tag: "",
      },
      showEditCobrokeLetterDialog: false,
      showConfirmationModal: false,
      showPreviewCobrokeLetterModal: false,
      showSendEmail: false,
      loading: false,

      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCobrokeHOC = (val, context) => this.setState({ [context]: val });

    onChangeEditCobrokeLetterVal = (context, val) => {
      let tmp = _.cloneDeep(this.state.selectedCobrokeLetter);
      if (context === "selected_agency" && val.length > 0) {
        tmp.agency_id = val[0].id;
      }
      tmp[context] = val;
      return this.setState({ selectedCobrokeLetter: tmp });
    };

    getCobrokeLetters = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/forms/co_broke_letters?${search}page=${page}`,
        this.getCobrokeLettersSuccess,
        this.getCobrokeLettersError,
        this.load,
      );
    };
    getCobrokeLettersSuccess = (payload) => {
      let tmpData = [];
      let tmpTotalPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let data = {
            ...item,
            letter_date: item.letter_date
              ? Moment(item.letter_date).format("DD MMM YYYY")
              : "N/A",
            agency_name: item.agency_name,
            invoicing_branch_name: item.branch_name,
          };
          tmpData.push(data);
        });

      this.setState({
        cobrokeLetters: {
          data: tmpData,
          meta: payload.meta,
        },
        cobrokeLettersPage: tmpTotalPages,
      });
    };
    getCobrokeLettersError = (error) => requestError(error);

    getCobrokeLetter = (id) =>
      Get(
        `/admin/forms/co_broke_letters/${id}`,
        this.getCobrokeLetterSuccess,
        this.getCobrokeLetterError,
        this.load,
      );
    getCobrokeLetterSuccess = (payload) => {
      let tmpSelectedAgency = [];
      tmpSelectedAgency.push({
        ...payload.agency,
        label: `${payload.agency.name} - ${payload.agency.address}`,
        value: payload.agency.id,
      });

      let temp = {
        ...payload,
        letter_date: payload.letter_date
          ? Moment(payload.letter_date).format("DD MMM YYYY")
          : null,
        valid_from: payload.valid_from
          ? Moment(payload.valid_from).format("DD MMM YYYY")
          : "N/A",
        valid_to: payload.valid_to
          ? Moment(payload.valid_to).format("DD MMM YYYY")
          : "N/A",
        selected_agency: tmpSelectedAgency,
      };
      this.setState({
        selectedCobrokeLetter: temp,
        initialSelectedCobrokeLetter: temp,
        showEditCobrokeLetterDialog: true,
      });
    };
    getCobrokeLetterError = (error) => requestError(error);

    downloadCobrokeLetter = (id) =>
      GetFile(
        `/admin/forms/co_broke_letters/${id}/download`,
        `CobrokeLetter-${id}.pdf`,
        () => {},
        this.downloadCobrokeLetterError,
        this.load,
      );
    downloadCobrokeLetterError = (error) => requestError(error);

    sendCobrokeLetter = (id, recipient) =>
      Post(
        `/admin/forms/co_broke_letters/${id}/deliver`,
        recipient,
        this.sendCobrokeLetterSuccess,
        this.sendCobrokeLetterError,
        this.load,
      );
    sendCobrokeLetterSuccess = () => {
      this.setState({ showSendEmail: false });
      requestSuccess("Cobroke Letter sent successfully.");
    };
    sendCobrokeLetterError = (error) => requestError(error);

    updateCobrokeLetter = (data) =>
      Put(
        `/admin/forms/co_broke_letters/${data.id}`,
        data,
        this.updateCobrokeLetterSuccess,
        this.updateCobrokeLetterError,
        this.load,
      );
    updateCobrokeLetterSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getCobrokeLetter(payload.id);
      this.getCobrokeLetters(currentPage, searchParams);
      requestSuccess("Update cobroke letter success!");
    };
    updateCobrokeLetterError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showConfirmationModal={this.state.showConfirmationModal}
            showPreviewCobrokeLetterModal={
              this.state.showPreviewCobrokeLetterModal
            }
            showSendEmail={this.state.showSendEmail}
            searchParams={this.state.searchParams}
            cobrokeLettersPage={this.state.cobrokeLettersPage}
            isAssociatedAgent={this.state.isAssociatedAgent}
            cobrokeLetters={this.state.cobrokeLetters}
            initialSelectedCobrokeLetter={
              this.state.initialSelectedCobrokeLetter
            }
            selectedCobrokeLetter={this.state.selectedCobrokeLetter}
            onLoadCobrokeLetter={this.state.loading}
            showEditCobrokeLetterDialog={this.state.showEditCobrokeLetterDialog}
            disableSubmitButton={this.state.disableSubmitButton}
            updateCobrokeLetter={this.updateCobrokeLetter}
            onChangeCobrokeHOC={this.onChangeCobrokeHOC}
            getCobrokeLetters={this.getCobrokeLetters}
            getCobrokeLetter={this.getCobrokeLetter}
            sendCobrokeLetter={this.sendCobrokeLetter}
            downloadCobrokeLetter={this.downloadCobrokeLetter}
            onChangeEditCobrokeLetterVal={this.onChangeEditCobrokeLetterVal}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeSubsalesAddress,
    refreshToken,
    storeLastView,
  })(CobrokeLetterWrappedComponent);
};

export default CobrokeLetterHOC;
