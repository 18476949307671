import _ from "lodash";
import { PulseLoader } from "react-spinners";
import React, { useState, useEffect, useCallback } from "react";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import ConfirmationModal from "components/Modal/confirmation";
import AtPaginatedTable from "components/NewPaginatedTable";

import { getColorBadge } from "dictionary/badgeColor";
import { checkNull } from "utils/checkNull";

const columnData = [
  {
    header: "Purchase By",
    renderColumn: ({ user }) => (
      <>
        <p className={"fw-500"}>{user.full_name || "N/A"}</p>
        <div className="fs-2">
          <AtlasIcon
            svgHref={"atlas-sms"}
            style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 10 }}
          />
          {user.email || "N/A"}
        </div>
        <div className="fs-2">
          <AtlasIcon
            svgHref={"atlas-call"}
            style={{ width: 16, height: 16, fill: "#F0631D", marginRight: 10 }}
          />
          {`+${user.mobile_contact_number}` || "N/A"}
        </div>
      </>
    ),
  },
  { header: "Ticket ID", accessor: "id", columnStyle: { width: "70%" } },
  {
    header: "Ticket",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => (
      <div className="d-flex flex-column g-2">
        <p>{rowData.ticket_pricing.title}</p>
        <div
          className="at-status_badge mb-10"
          style={{
            ...getColorBadge(rowData.status === "Pending" ? "Yellow" : "Blue"),
          }}
        >
          {rowData.status}
        </div>
      </div>
    ),
  },
  { header: "Event", renderColumn: (rowData) => rowData.event.name },
  {
    header: "Assign To",
    renderColumn: ({ assignee }) => {
      if (checkNull(assignee)) {
        return (
          <>
            <p className={"fw-500"}>{assignee?.full_name ?? "N/A"}</p>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-sms"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {assignee?.email ?? "N/A"}
            </div>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-call"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {assignee?.mobile_contact_number ?? "N/A"}
            </div>
          </>
        );
      } else {
        return "N/A";
      }
    },
  },
];

const TicketPurchaserList = ({
  agentList,
  onLoadAgent,
  editProductForm,
  ticketPurchasers,
  tickerSearchPrams,
  showTransferTicket,
  ticketPurchasersPages,
  onLoadEditProduct,

  ticketTransfer,
  onChangeAgentHOC,
  getAgentsBySearch,
  getTicketPurchasers,
  onChangeEditProductHOC,
}) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showTransferConfirmation, setShowTransferConfirmation] =
    useState(false);

  useEffect(() => {
    getAgentsBySearch("");
    getTicketPurchasers(editProductForm.id, "1", "");
  }, []);

  useEffect(() => {
    setSelectedAgent(null);
  }, [showTransferTicket]);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 1000),
    [],
  );

  const onToggleTransferTicket = () => {
    setShowTransferConfirmation(false);
    ticketTransfer(editProductForm.id, selectedTicket.id, selectedAgent.id);
  };

  return (
    <>
      <AtPaginatedTable
        columns={columnData}
        rowData={ticketPurchasers.data}
        meta={ticketPurchasers.meta}
        searchParams={tickerSearchPrams}
        actionColumnContent={[
          {
            name: "transfer",
            onClick: (rowData) => {
              setSelectedTicket(rowData);
              onChangeEditProductHOC(true, "showTransferTicket");
            },
          },
        ]}
        getListAPI={(page, search) =>
          getTicketPurchasers(editProductForm.id, page, search)
        }
        onChangeSearchParams={(val) =>
          onChangeEditProductHOC(val, "tickerSearchPrams")
        }
        totalPages={ticketPurchasersPages}
      />
      {showTransferTicket && (
        <ModalDialog
          title={`Transfer Ticket ${selectedTicket.id}`}
          onLoad={onLoadEditProduct}
          onClose={() => onChangeEditProductHOC(false, "showTransferTicket")}
          children={
            <div className="grid-control grid_gap-2">
              <section className="grid-full-col">
                <div className="d-flex">
                  <h2 className="at-form-input__title">
                    Transfer to the selected agent/personal assistant below
                  </h2>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </div>
                <CustomTypeahead
                  typeaheadId={"internal_agent"}
                  options={agentList || []}
                  selectedValue={selectedAgent ? [selectedAgent] : []}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    setSelectedAgent(null);
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && setSelectedAgent(val[0])
                  }
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  helpText={
                    "Search by recipient’s full name, mobile number, or email address registered in Atlas"
                  }
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) => `Role: ${option.role}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                />
              </section>
            </div>
          }
          footer={
            <div
              className="d-flex justify-content-end g-3 p-3"
              style={{ marginTop: 100 }}
            >
              <CustomButton
                className={"btn-new btn-new--success"}
                disabled={!selectedAgent}
                children={"Transfer Now"}
                onClick={() => setShowTransferConfirmation(true)}
              />
              <CustomButton
                className={"btn-new btn-new--outline-secondary"}
                children={"Cancel"}
                onClick={() =>
                  onChangeEditProductHOC(false, "showTransferTicket")
                }
              />
            </div>
          }
        />
      )}
      <ConfirmationModal
        loading={onLoadEditProduct}
        open={showTransferConfirmation}
        message={`You are about to transfer this ticket to another person, which will overwrite the existing assignee. Please ensure that the details of the new assignee are correct before clicking 'Yes'.`}
        positiveAction={() => onToggleTransferTicket()}
        negativeAction={() => setShowTransferConfirmation(false)}
      />
      {onLoadEditProduct && <LoadingModal />}
    </>
  );
};

export default TicketPurchaserList;
