import React, { Component } from "react";
import _ from "lodash";
import { Grid, Card, Snackbar } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import DocumentElement from "./documentElement";

import checkValid from "utils/checkValid";
import { StatusColor } from "../../../assets";
import { getColorBadge } from "dictionary/badgeColor";

const ClaimTypeOptions = [
  { id: 1, name: "Booking Form", value: 1, uploadLabel: "Booking Form upload" },
  {
    id: 2,
    name: "Extension",
    value: 2,
    uploadLabel: "Extension Form document_url upload",
  },
  {
    id: 3,
    name: "Add Name",
    value: 3,
    uploadLabel: "Add Name Form/IC COLOUR copy upload",
  },
  {
    id: 4,
    name: "Change Unit",
    value: 4,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 5,
    name: "Cancellation",
    value: 5,
    uploadLabel: "Cancellation Form/Rejection Letter upload",
  },
  {
    id: 6,
    name: "EPF",
    value: 6,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 7,
    name: "Deferment",
    value: 7,
    uploadLabel: "Loan Document (LO copy with letterhead is needed) upload",
  },
  {
    id: 8,
    name: "Reimbursement",
    value: 8,
    uploadLabel: "Letter of Authorization Form upload",
  },
  {
    id: 9,
    name: "Change Contact Details",
    value: 9,
    uploadLabel: "Change Unit Form upload",
  },
  {
    id: 10,
    name: "Others",
    value: 10,
    uploadLabel: "Others (optional for document)",
  },
];

class Update extends Component {
  state = {
    selectedDocument: {
      document: "",
      document_file_name: "",
    },
    showDocumentModal: false,
    updateSelectedRequest: {
      remark: "",
      documents: [],
      type_id: null,
    },
    tempUploadFile: [],
  };

  componentDidMount = () =>
    this.setState({ updateSelectedRequest: this.props.selectedRequest });

  onChangeRequest = (val, context) => {
    let tmp = _.cloneDeep(this.state.updateSelectedRequest);
    tmp[context] = val;

    return this.setState({ updateSelectedRequest: tmp });
  };

  onChangeDoc = (fileItems) => {
    let tmp = _.cloneDeep(this.state.tempUploadFile);

    if (
      tmp.length +
        Object.keys(fileItems).length +
        this.state.updateSelectedRequest.documents.length >
      5
    ) {
      return this.setState({ showSnackbar: true });
    }

    fileItems &&
      Object.keys(fileItems).map((key) => {
        let reader = new FileReader();
        try {
          reader.onload = (e) => {
            this.setState({
              tempUploadFile: [
                ...this.state.tempUploadFile,
                {
                  document: e.target.result,
                  document_file_name: fileItems[key].name,
                },
              ],
            });
          };
          reader.readAsDataURL(fileItems[key]);
        } catch (e) {
          console.log(e);
        }
      });
  };
  onRemoveDoc = (index) => {
    let tempDoc = _.cloneDeep(this.state.tempUploadFile);
    _.remove(tempDoc, (_, docIndex) => index === docIndex);

    this.setState({ tempUploadFile: tempDoc });
  };

  renderDocumentContent = () => {
    const { updateSelectedRequest, tempUploadFile } = this.state;
    let tempUploadTitle = _.find(ClaimTypeOptions, {
      id: updateSelectedRequest.type_id,
    });

    return (
      <>
        <h2 className="at-form-input__title mb-2">
          {tempUploadTitle ? tempUploadTitle.uploadLabel : "Upload"}
        </h2>
        <input
          multiple={true}
          type="file"
          className={"d-none"}
          ref={(ref) => (this.pond = ref)}
          onChange={(e) => this.onChangeDoc(e.target.files)}
          accept="application/pdf, image/*"
        />
        {updateSelectedRequest.documents.length < 5 &&
          updateSelectedRequest.status_id === 2 && (
            <button
              className="btn-new btn-new--secondary mb-10"
              onClick={() => this.pond.click()}
            >
              Add Document
            </button>
          )}
        {updateSelectedRequest.documents.length + tempUploadFile.length > 0 && (
          <Card style={{ padding: 15 }}>
            <Grid spacing={16} container>
              {updateSelectedRequest.documents.map((doc) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DocumentElement
                      doc={doc}
                      isUnsaved={false}
                      showRemoveButton={false}
                      docNameProps={"file_name"}
                      onClickOpenDoc={() =>
                        this.setState({
                          showDocumentModal: true,
                          selectedDocument: doc,
                        })
                      }
                    />
                  </Grid>
                );
              })}
              {this.state.tempUploadFile.map((doc, index) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DocumentElement
                      doc={doc}
                      docIndex={index}
                      isUnsaved={true}
                      showRemoveButton={true}
                      onClickOpenDoc={() =>
                        this.setState({
                          showDocumentModal: true,
                          selectedDocument: {
                            url: doc.document,
                            file_name: doc.document_file_name,
                          },
                        })
                      }
                      onRemoveDoc={() => this.onRemoveDoc(index)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        )}
        {updateSelectedRequest.documents.length + tempUploadFile.length < 1 && (
          <i className={"d-block"}>
            Please drop your document to be upload. 5 files is the maximum
            quantity for upload.
          </i>
        )}
      </>
    );
  };

  render = () => {
    const { selectedSale, showResubmitConfirmation } = this.props;
    let { updateSelectedRequest } = this.state;
    const { colorName = "" } =
      _.find(StatusColor, { value: updateSelectedRequest.status }) || {};

    return (
      <>
        <ModalDialog
          title={"Edit Request"}
          onClose={() =>
            this.props.onChangeRequestHOC("showUpdateRequest", false)
          }
          children={
            <form
              className="at-form__content grid-control"
              onSubmit={(e) => e.preventDefault()}
            >
              <section className="grid-half-col">
                <h2 className="at-form-input__title">{"Request Status"}</h2>
                <div
                  className="at-status_badge"
                  style={{ ...getColorBadge(colorName) }}
                >
                  {updateSelectedRequest.status}
                </div>
              </section>
              <section className="grid-half-col">
                <h2 className="at-form-input__title">{"Assigned To"}</h2>
                <i>{selectedSale.assigned_admin}</i>
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title mb-2">{"Request Type"}</h2>
                <Grid container spacing={16} style={{ marginTop: 0 }}>
                  {ClaimTypeOptions.map((item) => {
                    return (
                      <Grid
                        key={item.id}
                        item
                        md={4}
                        xs={12}
                        style={{ padding: "0px 8px" }}
                      >
                        <input
                          className="at-radio__input"
                          type="radio"
                          value={item.value}
                          checked={item.value === updateSelectedRequest.type_id}
                        />
                        <label className="at-radio__label" onClick={() => {}}>
                          <div className="at-radio__checker"></div>
                          <p className="at-radio__content">{item.name}</p>
                        </label>
                      </Grid>
                    );
                  })}
                </Grid>
              </section>
              <section className="grid-full-col">
                {this.renderDocumentContent()}
              </section>
              <section className="grid-full-col mt-20">
                <h2 className="at-form-input__title">{"Remark"}</h2>
                <AtlasTextarea
                  value={updateSelectedRequest.remark}
                  onChangeValue={(val) => this.onChangeRequest(val, "remark")}
                  disabled={updateSelectedRequest.status_id !== 2}
                />
              </section>
            </form>
          }
          footer={
            <div className="d-flex g-3">
              {this.state.updateSelectedRequest.status_id === 2 && (
                <button
                  className="btn-new btn-new--success"
                  onClick={() =>
                    this.props.onChangeRequestHOC(
                      "showResubmitConfirmation",
                      true,
                    )
                  }
                >
                  Resubmit
                </button>
              )}
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={() =>
                  this.props.onChangeRequestHOC("showUpdateRequest", false)
                }
              >
                {"Close"}
              </button>
            </div>
          }
        />
        {this.state.showDocumentModal && (
          <ModalDialog
            title={this.state.selectedDocument.file_name}
            onClose={() => this.setState({ showDocumentModal: false })}
            children={
              <>
                {checkValid(this.state.selectedDocument.url).indexOf("pdf") >
                  -1 && (
                  <iframe
                    className={"w-100 h-100"}
                    src={this.state.selectedDocument.url}
                  ></iframe>
                )}
                {checkValid(this.state.selectedDocument.url).indexOf("pdf") ===
                  -1 && (
                  <img
                    src={this.state.selectedDocument.url}
                    alt="booking form image"
                  />
                )}
              </>
            }
          />
        )}
        <ConfirmationModal
          title={"Resubmit Request"}
          mode={"alert"}
          open={showResubmitConfirmation}
          loading={this.props.onLoadRequests}
          message={`Are you sure you want to resubmit the request ?`}
          positiveAction={() =>
            this.props.resubmitRequest(selectedSale.id, {
              id: this.state.updateSelectedRequest.id,
              remark: this.state.updateSelectedRequest.remark,
              type_id: this.state.updateSelectedRequest.type_id,
              project_sale_request_documents_attributes:
                this.state.tempUploadFile,
            })
          }
          negativeAction={() =>
            this.props.onChangeRequestHOC("showResubmitConfirmation", false)
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ showSnackbar: false })}
          message="Maximum file number can be 5 only."
        />
        {this.props.onLoadRequests && <LoadingModal />}
      </>
    );
  };
}

export default Update;
