import React, { Component } from "react";

import CustomButton from "components/Button";

import { numberWithCommas } from "utils/thousandSeparator";
import AtlasIcon from "components/Icon/atlasIcon";

class Content extends Component {
  state = {
    radioChecked: false,
  };

  toggleExpandTableRow() {
    this.setState((prevState) => ({
      radioChecked: !prevState.radioChecked,
    }));
  }

  renderActionContent(content) {
    const { actionColumnContent } = this.props;
    const tempActionColumnContent = [];
    actionColumnContent.map((item) => {
      return item !== "delete" ? tempActionColumnContent.push(item) : {};
    });
    actionColumnContent.includes("delete") &&
      tempActionColumnContent.push("delete");

    return tempActionColumnContent.map((item) => {
      if (item === "register") {
        return (
          <button
            className={"btn-new btn-new--primary my-1 w-100"}
            style={{
              fontWeight: "400",
              fontSize: "14px",
              wordBreak: "keep-all",
            }}
            children={"Register"}
            onClick={() =>
              Promise.all([
                this.props.onChangeRegisterHOC(content, "selectedTraining"),
              ]).then(() => {
                this.props.onChangeRegisterHOC(true, "showConfirmationModal");
              })
            }
          />
        );
      }
    });
  }

  renderTableContentGrid(
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
    styling,
    columnClass,
  ) {
    const { booleanColumn, thousandSeparatorColumn, actionColumnContent } =
      this.props;
    const contentProcess = (param) => {
      if (booleanColumn === columnValue) {
        if (content === 0) {
          return "No";
        } else {
          return "Yes";
        }
      } else {
        if (thousandSeparatorColumn) {
          if (thousandSeparatorColumn.indexOf(columnValue) > -1) {
            param = numberWithCommas(param);
          }
        }
        return param;
      }
    };
    const renderHighlightColumn = (param) => {
      if (param) {
        if (param.columnValue === columnValue) {
          return "grid-top-object";
        }
      }
      return "";
    };
    return (
      <>
        {index !== "Actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} 
              ${renderHighlightColumn(this.props.headerRearrangeData)}
              ${columnClass || ""}
              `}
            style={{ width: columnWidth ? columnWidth : "100%", ...styling }}
          >
            {!(columnValue === "status" && this.props.rowItem.isRegistered) && (
              <span className="at-table-row__item-label">{columnLabel}</span>
            )}
            {(columnValue !== "status" ||
              (columnValue === "status" && !this.props.rowItem.isRegistered)) &&
              typeof content !== "function" && <p>{contentProcess(content)}</p>}
            {typeof content === "function" && content(this.props.rowItem)}
            {columnValue === "status" && this.props.rowItem.isRegistered && (
              <CustomButton
                key={index}
                className={"btn-new btn-new--secondary mb-2"}
                style={{ fontWeight: "400", fontSize: "14px" }}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-video"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "white",
                        marginRight: 6,
                      }}
                    />
                    Join
                  </>
                }
                disabled={this.props.rowItem.join_link.length < 1}
                onClick={() =>
                  this.props.onClickJoin(this.props.rowItem.join_link)
                }
              />
            )}
          </li>
        )}
        {index === "Actions" && (
          <>
            <li
              key={index}
              className={`at-table-row__item at-table__actions ${renderHighlightColumn(this.props.headerRearrangeData)}`}
              style={{ minWidth: 110, width: "30%", maxWidth: 150 }}
            >
              <span className="at-table-row__item-label">{columnLabel}</span>
              {this.renderActionContent(content)}
            </li>
            <li
              key={index}
              style={{ margin: 0 }}
              className={`at-table-row__mobile-action ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            >
              {this.renderActionContent(content)}
            </li>
          </>
        )}
      </>
    );
  }

  render = () => {
    const { headerData, actionColumn, rowItem, secondLayerRow } = this.props;
    return (
      <div className="at-table-row-cont">
        <div
          className="at-table__height-control"
          style={{ height: this.state.radioChecked ? "370px" : "initial" }}
        >
          <ul className="at-table-row">
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                columnItem.value,
                columnItem.renderColumn || rowItem[columnItem.value],
                columnItem.label,
                columnItem.width,
                columnItem.containerStyle,
                columnItem.columnClass,
              );
            })}
            {actionColumn
              ? this.renderTableContentGrid("Actions", "Actions", rowItem)
              : ""}
          </ul>
        </div>
      </div>
    );
  };
}

export default Content;
