import React from "react";
import { Tooltip } from "@material-ui/core";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";

const SubsalesTooltip = ({ data }) => {
  return (
    <>
      {data.showToolTip && (
        <Tooltip
          classes={{ popper: "popper", tooltip: "tool-tip" }}
          placement={"top-start"}
          title={
            <img
              src={require(`assets/images/subsaleImage/${data.toolTip}.jpg`)}
            />
          }
        >
          <button
            style={{ width: 20, height: 20, marginLeft: 10 }}
            className={"svg-icon-info btn-tooltip"}
            children={<img src={iqiLogo} />}
          />
        </Tooltip>
      )}
      {data.showJuwaiToolTip && (
        <Tooltip
          classes={{ popper: "popper", tooltip: "tool-tip" }}
          placement={"top-start"}
          title={
            <img
              src={require(`assets/images/juwaiImages/${data.toolTip}.jpg`)}
            />
          }
        >
          <button
            style={{ width: 20, height: 20, marginLeft: 10 }}
            className={"svg-icon-info btn-tooltip"}
            children={<img src={juwaiLogo} />}
          />
        </Tooltip>
      )}
      {data.showJuwaiAsiaToolTip && (
        <Tooltip
          classes={{ popper: "popper", tooltip: "tool-tip" }}
          placement={"top-start"}
          title={
            <img
              src={require(`assets/images/juwaiAsiaImages/${data.toolTip}.jpg`)}
            />
          }
        >
          <button
            style={{ width: 20, height: 20, marginLeft: 10 }}
            className={"svg-icon-info btn-tooltip"}
            children={<img src={juwaiAsiaLogo} />}
          />
        </Tooltip>
      )}
      {data.showEdgeTooltip && (
        <Tooltip
          classes={{ popper: "popper", tooltip: "tool-tip" }}
          placement={"top-start"}
          title={
            <img
              src={require(`assets/images/edgeImages/${data.toolTip}.png`)}
            />
          }
        >
          <button
            style={{ width: 20, height: 20, marginLeft: 10 }}
            className={"svg-icon-info btn-tooltip"}
            children={
              <img src={edgePropLogo} style={{ borderRadius: "50%" }} />
            }
          />
        </Tooltip>
      )}
    </>
  );
};

export default SubsalesTooltip;
