import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";
import { requestSuccess, requestError } from "utils/requestHandler";
import { Post, Get, Put, GetFile, Delete } from "utils/axios";
import isEmptyValue from "utils/isEmpty";

import {
  types,
  categories,
  statuses,
  measurements,
  occupancies,
  furnishing_statuses,
  tenure_types,
} from "dictionary/subsales";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCancellation: false,
      showProjectDetails: false,
      showPropertyDetails: false,
      showConcludeModal: false,
      showSetAppointment: false,
      showSelectProject: false,
      showRatePartner: false,
      showNotesModal: false,
      showActivity: false,

      projectList: [],
      reassignSubmissions: {},
      currentTab: 3,
      primaryTab: "Project Partner",

      cobrokeRequests: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      sentCobrokeRequests: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      selectedPropertyDetails: {},
      requestMode: "CoBrokeCenter",

      cobrokeRequestPages: [1],
      sentCobrokeRequestsPages: [1],
      totalPendingRequest: 0,
      totalRequestCount: 0,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCobrokeHOC = (val, context) => this.setState({ [context]: val });

    getProjectPartnerError = (error) => requestError(error);

    getProjectListing = () =>
      Get(
        `/projects/active_project_listings?is_searching=1`,
        this.getProjectListingSuccess,
        this.getProjectListingError,
        (param) => this.setState({ projectLoading: param }),
      );
    getProjectListingSuccess = (payload) => {
      let tempOptions = [];

      payload.projects.length > 0 &&
        payload.projects.map((item) => {
          tempOptions.push({
            name: item.name,
            atlas_id: item.id,
            value: item.internal_name,
            reference_id: item.reference_id,
          });
        });
      this.setState({ projectList: tempOptions });
    };
    getProjectListingError = (error) => requestError(error);

    getRequestCount = (id) =>
      Get(
        `/co_broke_requests/agents/${id}/total_pending`,
        this.getPendingRequestSuccess,
        this.getPendingRequestError,
        this.load,
      );
    getPendingRequestSuccess = ({ pending, total }) =>
      this.setState({
        totalPendingRequest: pending,
        totalRequestCount: total,
      });
    getPendingRequestError = (error) => requestError(error);

    getReceivedCobrokeRequest = (id, page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/co_broke_requests/received/${id}?q[request_from_eq]=${this.state.requestMode}&${search}page=${page}`,
        this.getReceivedCobrokeRequestSuccess,
        this.getReceivedCobrokeRequestError,
        this.load,
      );
    };
    getReceivedCobrokeRequestSuccess = ({ data, meta }) => {
      let tempPages = [];
      let tmpCatGroupPropertyType = {};
      let tempData = _.map(data, ({ request_sender, ...item }) => {
        let tempMeasurement = _.find(measurements, { id: item.measurement });
        let tmpCategory = _.find(categories, { id: item.listing_category_id });
        if (tmpCategory) {
          tmpCategory.group_types.map((child) => {
            return child.children.map((childItem) => {
              if (childItem.id === item.property_type_id) {
                tmpCatGroupPropertyType = childItem;
              }
            });
          });
        }
        return {
          ...item,
          agent_info: {
            email: request_sender.email,
            avatar_url: request_sender.avatar,
            full_name: request_sender.name,
            mobile_contact_number: request_sender.phone_number,
            team_name: item.agents_team_name,
          },
          status:
            item.listing_status_id !== 1
              ? item.status_id === 3
                ? item.status
                : "No longer Available"
              : item.status,
          status_id:
            item.listing_status_id !== 1
              ? item.status_id === 3
                ? item.status_id
                : -1
              : item.status_id,
          request_sender: request_sender,
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          property:
            tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
              ? tmpCatGroupPropertyType.name
              : "N/A",
          code: item.property_code,
          address: item.property_address || "N/A",
          co_broke_settings: item.co_broke_portion
            ? _.isArray(item.co_broke_portion)
              ? item.co_broke_portion[item.revised_commission_count] || {}
              : item.co_broke_portion
            : item.original_co_broke_portion || {},
          co_broke_portion: item.co_broke_portion
            ? _.isArray(item.co_broke_portion)
              ? item.co_broke_portion[item.revised_commission_count] || {}
              : item.co_broke_portion
            : item.original_co_broke_portion || {},
          original_co_broke_portion: _.isArray(item.co_broke_portion)
            ? item.revised_commission_count === 1
              ? item.original_co_broke_portion
              : item.co_broke_portion[item.revised_commission_count - 1]
            : item.original_co_broke_portion,
          requested_at: Moment(item.requested_at).format("DD MMM YYYY"),
        };
      });
      for (let index = 0; index < meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        cobrokeRequests: {
          data: tempData,
          meta,
        },
        cobrokeRequestPages: tempPages,
      });
    };
    getReceivedCobrokeRequestError = (error) => requestError(error);

    getSentCobrokeRequest = (id, page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/co_broke_requests/sent/${id}?&q[request_from_eq]=${this.state.requestMode}&${search}page=${page}`,
        this.getSentCobrokeRequestSuccess,
        this.getSentCobrokeRequestError,
        this.load,
      );
    };
    getSentCobrokeRequestSuccess = ({ data, meta }) => {
      let tempPages = [];
      let tmpCatGroupPropertyType = {};
      let tempData = _.map(data, ({ agent, ...item }) => {
        let tempMeasurement = _.find(measurements, { id: item.measurement });
        let tmpCategory = _.find(categories, { id: item.listing_category_id });
        if (tmpCategory) {
          tmpCategory.group_types.map((child) => {
            return child.children.map((childItem) => {
              if (childItem.id === item.property_type_id) {
                tmpCatGroupPropertyType = childItem;
              }
            });
          });
        }

        return {
          ...item,
          agent_info: {
            email: agent.email,
            avatar_url: agent.avatar,
            full_name: agent.name,
            mobile_contact_number: agent.phone_number,
          },
          status:
            item.listing_status_id !== 1
              ? item.status_id === 3
                ? item.status
                : "No longer Available"
              : item.status,
          status_id:
            item.listing_status_id !== 1
              ? item.status_id === 3
                ? item.status_id
                : -1
              : item.status_id,
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          property:
            tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
              ? tmpCatGroupPropertyType.name
              : "N/A",
          code: item.property_code,
          address: item.property_address || "N/A",
          co_broke_settings: item.co_broke_portion
            ? _.isArray(item.co_broke_portion)
              ? item.co_broke_portion[item.revised_commission_count] || {}
              : item.co_broke_portion
            : item.original_co_broke_portion || {},
          co_broke_portion: item.co_broke_portion
            ? _.isArray(item.co_broke_portion)
              ? item.co_broke_portion[item.revised_commission_count] || {}
              : item.co_broke_portion
            : item.original_co_broke_portion || {},
          original_co_broke_portion: _.isArray(item.co_broke_portion)
            ? item.revised_commission_count === 1
              ? item.original_co_broke_portion
              : item.co_broke_portion[item.revised_commission_count - 1]
            : item.original_co_broke_portion,
          requested_at: Moment(item.requested_at).format("DD MMM YYYY"),
        };
      });
      for (let index = 0; index < meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        sentCobrokeRequests: {
          data: tempData,
          meta,
        },
        sentCobrokeRequestsPages: tempPages,
      });
    };
    getSentCobrokeRequestError = (error) => requestError(error);

    getReceivedPartnerRequest = (id, page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/co_broke_requests/received_partner_requests/${id}?${search}page=${page}`,
        this.getReceivedPartnerRequestSuccess,
        this.getReceivedPartnerRequestError,
        this.load,
      );
    };
    getReceivedPartnerRequestSuccess = ({ data, meta }) => {
      let tmpPages = [];
      for (let i = 0; i < meta.pages; i++) {
        tmpPages.push(i);
      }

      let tempData = _.map(data, (item) => {
        return {
          ...item,
          co_broke_portion: {},
          project_sale_cycle: !isEmptyValue(item.project_sale_cycle)
            ? item.project_sale_cycle
            : {},
          agent_info: item.request_sender,
          address: item.project_address,
          listing_id: item.entity_id,
          type: item.project_type,
          currency: item.project_currency,
          bedrooms_label:
            item.min_bedrooms && item.max_bedrooms
              ? `${item.min_bedrooms} - ${item.max_bedrooms}`
              : item.min_bedrooms || item.max_bedrooms || "-",
          bathrooms_label:
            item.min_bathrooms && item.max_bathrooms
              ? `${item.min_bathrooms} - ${item.max_bathrooms}`
              : item.min_bathrooms || item.max_bathrooms || "-",
          carparks_label:
            item.min_carparks && item.max_carparks
              ? `${item.min_carparks} - ${item.max_carparks}`
              : item.min_carparks || item.max_carparks || "-",
          measurement: item.project_measurement,
          category: item.tenure_type,
          property: item.project_title,
          requested_at: Moment(item.requested_at).format("DD MMM YYYY"),
        };
      });

      this.setState({
        cobrokeRequests: {
          data: tempData,
          meta: meta,
        },
        cobrokeRequestPages: tmpPages,
      });
    };
    getReceivedPartnerRequestError = (error) => requestError(error);

    getSentPartnerRequest = (id, page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/co_broke_requests/sent_partner_requests/${id}?${search}page=${page}`,
        this.getSentPartnerRequestSuccess,
        this.getSentPartnerRequestError,
        this.load,
      );
    };
    getSentPartnerRequestSuccess = ({ data, meta }) => {
      let tmpPages = [];
      for (let i = 0; i < meta.pages; i++) {
        tmpPages.push(i);
      }

      let tempData = _.map(data, (item) => {
        return {
          ...item,
          co_broke_portion: {},
          agent_info: item.agent,
          address: item.project_address,
          listing_id: item.entity_id,
          price: item.project_price,
          type: item.project_type,
          currency: item.project_currency,
          built_up: item.min_build_up,
          land_area: item.max_build_up,
          bedrooms_label:
            item.min_bedrooms && item.max_bedrooms
              ? `${item.min_bedrooms} - ${item.max_bedrooms}`
              : item.min_bedrooms || item.max_bedrooms || "-",
          bathrooms_label:
            item.min_bathrooms && item.max_bathrooms
              ? `${item.min_bathrooms} - ${item.max_bathrooms}`
              : item.min_bathrooms || item.max_bathrooms || "-",
          carparks_label:
            item.min_carparks && item.max_carparks
              ? `${item.min_carparks} - ${item.max_carparks}`
              : item.min_carparks || item.max_carparks || "-",
          measurement: item.project_measurement,
          land_area: item.project_land_area,
          category: item.tenure_type,
          property: item.project_title,
          requested_at: Moment(item.requested_at).format("DD MMM YYYY"),
        };
      });

      this.setState({
        sentCobrokeRequests: {
          data: tempData,
          meta: meta,
        },
        sentCobrokeRequestsPages: tmpPages,
      });
    };
    getSentPartnerRequestError = (error) => requestError(error);

    updateCobrokeStatus = (id, status_id, requestMode, reject_reason) =>
      Put(
        `/co_broke_requests/${id}`,
        {
          status_id,
          request_from: requestMode,
          reject_reason: reject_reason,
        },
        this.updateCobrokeStatusSuccess,
        this.updateCobrokeStatusError,
        this.load,
      );
    updateCobrokeStatusSuccess = () => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getRequestCount(id);
      if (this.state.primaryTab === "Project Partner") {
        this.setState({ showCancellation: false });
        this.getReceivedPartnerRequest(id, currentPage, searchParams);
      } else {
        this.getReceivedCobrokeRequest(id, currentPage, searchParams);
      }
    };
    updateCobrokeStatusError = (error) => requestError(error);

    getPropertyDetails = (id) =>
      Get(
        `/sub_sales/${id}/co_broke_details`,
        this.getPropertyDetailsSuccess,
        this.getPropertyDetailsError,
        this.load,
      );
    getPropertyDetailsSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);

      let tmpType = _.find(types, { id: tmp.type_id });
      let tmpCategory = _.find(categories, { id: tmp.category_id });
      let tempStatus = _.find(statuses, { id: tmp.status_id });
      let tmpCatGroupPropertyType = {};
      if (tmpCategory) {
        tmpCategory.group_types.map((child) => {
          return child.children.map((childItem) => {
            if (childItem.id === tmp.property_type_id) {
              tmpCatGroupPropertyType = childItem;
            }
          });
        });
      }
      let tempFurnishing = _.find(furnishing_statuses, {
        id: tmp.furnishing_status_id,
      });
      let tempMeasurement = _.find(measurements, { id: tmp.measurement_id });
      let tempOccupancy = _.find(occupancies, { id: tmp.occupancy_id });
      let tempTenure = _.find(tenure_types, { id: tmp.tenure_id });

      let featureArray = [
        {
          label: "Occupancy",
          value:
            tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
              ? tempOccupancy.name
              : "N/A",
        },
        {
          label: "Furnishing",
          value:
            tempFurnishing && tempFurnishing.name.toLowerCase() !== "unknown"
              ? tempFurnishing.name
              : "N/A",
        },
        {
          label: "Tenure",
          value:
            tempTenure && tempTenure.name.toLowerCase() !== "unknown"
              ? tempTenure.name
              : "N/A",
        },
      ];

      let facilitiesArray = [
        { label: "All Day Security", value: tmp.all_day_security },
        { label: "Business Centre", value: tmp.business_centre },
        { label: "Parking", value: tmp.parking },
        { label: "Gymnasium", value: tmp.gymnasium },
        { label: "Jogging Track", value: tmp.jogging_track },
        { label: "Playground", value: tmp.playground },
        { label: "Cafeteria", value: tmp.cafetria },
        { label: "Club House", value: tmp.club_house },
        { label: "Jacuzzi", value: tmp.jacuzzi },
        { label: "Mini Market", value: tmp.mini_market },
        { label: "Nursery", value: tmp.nursery },
        { label: "Salon", value: tmp.salon },
        { label: "Sauna", value: tmp.sauna },
        { label: "Squash Court", value: tmp.squash_court },
        { label: "Tennis Court", value: tmp.tennis_court },
        { label: "Swimming Pool", value: tmp.swimming_pool },
        { label: "Wading Pool", value: tmp.wading_pool },
        { label: "BBQ", value: tmp.bbq },
      ];

      this.setState({
        showPropertyDetails: true,
        selectedPropertyDetails: {
          ...tmp,
          car_parks: tmp.car_parks || "N/A",
          bedrooms: tmp.bedrooms || "N/A",
          bathrooms: tmp.bathrooms || "N/A",
          built_up: tmp.built_up,
          land_area: tmp.land_area,
          facilities: facilitiesArray,
          featureArray: featureArray,
          type: tmpType && tmpType.name ? tmpType.name : "N/A",
          property:
            tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
              ? tmpCatGroupPropertyType.name
              : "N/A",
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
          available_date: tmp.available_date
            ? Moment(tmp.available_date).format("DD MMM YYYY")
            : "N/A",
          updated_on: tmp.updated_on
            ? Moment(tmp.updated_on, "DD-MM-YYYY").format("DD MMM YYYY")
            : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",

          township: payload.township ? payload.township : "",
          sub_area: payload.sub_area ? payload.sub_area : "",
        },
      });
    };
    getPropertyDetailsError = (error) => requestError(error);

    updateCommissionRate = (dataToSubmit) =>
      Put(
        `/co_broke_requests/${dataToSubmit.id}/update_co_broke_portion`,
        {
          co_broke_portion: dataToSubmit.co_broke_portion,
        },
        this.updateCommissionRateSuccess,
        this.updateCommissionRateError,
        this.load,
      );
    updateCommissionRateSuccess = (payload) => {
      let temp = _.cloneDeep(
        this.state.requestMode === "RecommendationEngine"
          ? this.state.sentCobrokeRequests
          : this.state.cobrokeRequests,
      );
      let cobrokeIndex = _.findIndex(
        temp.data,
        (item) => item.id === payload.id,
      );
      temp.data[cobrokeIndex].co_broke_portion =
        payload.co_broke_portion[payload.revised_commission_count];
      temp.data[cobrokeIndex].status = payload.status;
      temp.data[cobrokeIndex].status_id = payload.status_id;
      temp.data[cobrokeIndex].revised_commission_count =
        payload.revised_commission_count;
      this.setState(
        this.state.requestMode === "RecommendationEngine"
          ? { sentCobrokeRequests: temp }
          : { cobrokeRequests: temp },
      );
      requestSuccess("Commission Rate Revised");
    };
    updateCommissionRateError = (error) => requestError(error);

    updateReviceCommStatus = (id, dataToSubmit) =>
      Put(
        `/co_broke_requests/${id}/update_revised_commission_request`,
        {
          status_id: dataToSubmit,
        },
        this.updateReviceCommStatusSuccess,
        this.updateReviseCommStatusError,
        this.load,
      );
    updateReviceCommStatusSuccess = (payload) => {
      let temp = _.cloneDeep(
        this.state.requestMode === "RecommendationEngine"
          ? this.state.cobrokeRequests
          : this.state.sentCobrokeRequests,
      );
      let cobrokeIndex = _.findIndex(
        temp.data,
        (item) => item.id === payload.id,
      );
      temp.data[cobrokeIndex].status = payload.status;
      temp.data[cobrokeIndex].status_id = payload.status_id;
      this.setState(
        this.state.requestMode === "RecommendationEngine"
          ? { cobrokeRequests: temp }
          : { sentCobrokeRequests: temp },
      );
      requestSuccess("Status Updated");
    };
    updateReviseCommStatusError = (error) => requestError(error);

    updateProjectSalesCycle = (dataToSubmit) =>
      Put(
        `/project_sale_cycles/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateProjectSalesCycleSuccess,
        this.updateProjectSalesCycleError,
        this.load,
      );
    updateProjectSalesCycleSuccess = (payload) => {
      this.setState({
        showRatePartner: false,
        showSetAppointment: false,
      });
      requestSuccess("Project Sales Cycle Updated");
    };
    updateProjectSalesCycleError = (error) => requestError(error);

    updateCobrokeProject = (cobroke_id, project_id) =>
      Put(
        `/co_broke_requests/${cobroke_id}`,
        { entity_id: project_id },
        this.updateCobrokeProjectSuccess,
        this.updateCobrokeProjectError,
        this.load,
      );
    updateCobrokeProjectSuccess = () => {
      this.setState({ showSelectProject: false });
      requestSuccess("Cobroke project updated");
    };
    updateCobrokeProjectError = (error) => requestError(error);

    concludeCobroke = (id) =>
      Put(
        `/co_broke_requests/${id}/conclude_deal`,
        "",
        this.concludeCobrokeSuccess,
        this.concludeCobrokeError,
        this.load,
      );
    concludeCobrokeSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      if (this.state.requestMode === "RecommendationEngine") {
        this.getSentCobrokeRequest(
          window.location.href.includes("/admin-impersonate")
            ? this.props.data.currentSignInProfileReducer.id
            : this.props.data.profileReducer.id,
          currentPage,
          searchParams,
        );
      } else {
        this.getReceivedCobrokeRequest(
          window.location.href.includes("/admin-impersonate")
            ? this.props.data.currentSignInProfileReducer.id
            : this.props.data.profileReducer.id,
          currentPage,
          searchParams,
        );
      }
      requestSuccess("The Co-broke has been concluded successfully.");
      this.setState({ showConcludeModal: false });
    };
    concludeCobrokeError = (error) => requestError(error);

    downloadCobroke = (id, filename) =>
      GetFile(
        `/co_broke_requests/${id}/download_agreement`,
        filename,
        () => {},
        this.downloadCobrokeError,
        this.load,
      );
    downloadCobrokeError = (error) => requestError(error);

    createNote = (dataToSubmit, onSuccess) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/co_broke_requests/${dataToSubmit.cobroke_id}/internal_notes`,
        {
          internal_note: {
            user_id: id,
            note: dataToSubmit.note,
          },
        },
        (payload) => this.createNoteSuccess(payload, onSuccess),
        this.createNoteError,
        this.load,
      );
    };
    createNoteSuccess = (payload, onSuccess) => {
      requestSuccess("Note created");
      onSuccess(payload);
    };
    createNoteError = (error) => requestError(error);

    updateNote = (dataToSubmit, onSuccess) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Put(
        `/co_broke_requests/${dataToSubmit.cobroke_id}/internal_notes/${dataToSubmit.id}`,
        {
          internal_note: {
            user_id: id,
            note: dataToSubmit.note,
          },
        },
        (payload) => this.updateNoteSuccess(payload, onSuccess),
        this.updateNoteError,
        this.load,
      );
    };
    updateNoteSuccess = (payload, onSuccess) => {
      requestSuccess("Note updated");
      onSuccess(payload);
    };
    updateNoteError = (error) => requestError(error);

    deleteNote = ({ cobroke_id, note_id }, onSuccess) =>
      Delete(
        `/co_broke_requests/${cobroke_id}/internal_notes/${note_id}`,
        () => this.deleteNoteSuccess(onSuccess),
        this.deleteNoteError,
        this.load,
      );
    deleteNoteSuccess = (onSuccess) => {
      requestSuccess("Note deleted");
      onSuccess();
    };
    deleteNoteError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadCobroke={this.state.loading}
          createNote={this.createNote}
          updateNote={this.updateNote}
          deleteNote={this.deleteNote}
          concludeCobroke={this.concludeCobroke}
          downloadCobroke={this.downloadCobroke}
          getProjectListing={this.getProjectListing}
          getPropertyDetails={this.getPropertyDetails}
          updateCobrokeStatus={this.updateCobrokeStatus}
          updateCommissionRate={this.updateCommissionRate}
          updateCobrokeProject={this.updateCobrokeProject}
          getSentPartnerRequest={this.getSentPartnerRequest}
          updateProjectSalesCycle={this.updateProjectSalesCycle}
          getReceivedPartnerRequest={this.getReceivedPartnerRequest}
          updateReviceCommStatus={this.updateReviceCommStatus}
          onChangeCobrokeHOC={this.onChangeCobrokeHOC}
          getReceivedCobrokeRequest={this.getReceivedCobrokeRequest}
          getRequestCount={this.getRequestCount}
          getSentCobrokeRequest={this.getSentCobrokeRequest}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
