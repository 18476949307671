import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import SubsaleClaimForm from "./components/form";
import AtlasConfirmationModal from "components/Modal/confirmation";
import LoadingModal from "components/LoadingModal";

import ListingHOC from "../../actions/listing";

class ClaimForm extends Component {
  state = {
    toRemovePaymentID: 0,
    showPreviewClaimModal: false,
    showConfirmationModal: false,
    unsavedChanges: false,
  };

  componentDidMount = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getBanks(country_id);
  };

  onClickPreviewClaimForm = () =>
    this.setState({ showPreviewClaimModal: true });

  onCloseClaimPreviewModal = () =>
    this.setState({ showPreviewClaimModal: false });

  render = () => {
    const {
      banks,
      agencies,
      selected_claim,

      onChangeUpdateSelectedClaim,
      updateSelectedClaim,
      onChangeSubsaleClaimsHOC,
    } = this.props;
    return (
      <>
        <SubsaleClaimForm
          {...this.props}
          banks={banks}
          agencies={agencies}
          selected_claim={selected_claim}
          unsavedChanges={this.state.unsavedChanges}
          storedAddresses={this.props.data.addressReducer.address}
          onClickSaveInfo={this.onClickSaveInfo}
          onClickPreviewClaimForm={this.onClickPreviewClaimForm}
          onChangeUpdateSelectedClaim={(key, val) => {
            onChangeUpdateSelectedClaim(key, val);
            this.setState({ unsavedChanges: true });
          }}
          onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
          onClickNav={this.props.onClickNav}
          updateSelectedClaim={(id, dataToSubmit, enableExternalAgentBankValidation) => {
            updateSelectedClaim(id, dataToSubmit, enableExternalAgentBankValidation);
            this.setState({ unsavedChanges: false });
          }}
          onCloseForm={() => this.props.onCloseForm()}
        />
        <AtlasConfirmationModal
          open={this.state.showConfirmationModal}
          message={
            "Are you sure to submit this claim? Once submitted, no information edit is allowed."
          }
          positiveAction={this.confirmSubmitClaim}
          negativeAction={this.cancelSubmitClaim}
        />
        {this.props.onLoadClaims && <LoadingModal />}
      </>
    );
  };
}

export default compose(ListingHOC)(ClaimForm);
