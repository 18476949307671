export const eventDataKey = [
  {
    key: "title",
    value: "name",
  },
  {
    key: "id",
    value: "id",
  },
  {
    key: "date",
    value: "start_date_time",
  },
  {
    key: "open_for_registration",
    value: "open_for_registration",
  },
  {
    key: "branch_region_id",
    value: "branch_region_id",
  },
  {
    key: "is_approved",
    value: "is_approved",
  },
  {
    key: "registration_status",
    value: "registration_status",
  },
  {
    key: "start_date_time",
    value: "start_date_time",
  },
  {
    key: "end_date_time",
    value: "end_date_time",
  },
  {
    key: "image",
    value: "event_photo_url",
  },
  {
    key: "location",
    value: "location",
  },
  {
    key: "speaker",
    value: "speaker",
  },
  {
    key: "type",
    value: "type",
  },
  {
    key: "is_registered",
    value: "is_registered",
  },
  {
    key: "is_payment_needed",
    value: "is_payment_needed",
  },
  {
    key: "is_limited_seat",
    value: "is_limited_seat",
  },
  {
    key: "type_id",
    value: "type_id",
  },
  {
    key: "training_type_id",
    value: "training_type_id",
  },
];

export const birthdayDataKey = [
  {
    key: "title",
    value: "agentName",
  },
  {
    key: "email",
    value: "email",
  },
  {
    key: "key",
    value: "mobileNumber",
  },
  {
    key: "date",
    value: "dob",
  },
  {
    key: "type",
    value: "type",
  },
  {
    key: "id",
    value: "agentID",
  },
  {
    key: "avatar_url",
    value: "avatar_url",
  },
  {
    key: "tier",
    value: "tier",
  },
  {
    key: "referer",
    value: "groupLeaderName",
  },
  {
    key: "branch",
    value: "branch",
  },
];

export const eventListKeys = (trainingType) => {
  const tmp = [4, 6].indexOf(trainingType) !== -1 ? "Fee" : "Price";

  return [
    "Start Time",
    "End Time",
    "Register By",
    "Venue",
    "Location",
    "Speaker",
    "Branch Region",
    "People Attending",
    "Description",
    tmp,
    "Entry Pass",
  ];
};

export const birthdayListKeys = [
  "Event",
  "Date",
  "Email",
  "Mobile Number",
  "Tier",
  "Referrer",
  "Branch",
];
