import React, { useState, useCallback, useEffect } from "react";
import { RiArrowUpDownLine } from "react-icons/ri";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";

const ArrowContent = ({
  onClickRearrangeData,
  columnContent,
  sorting,
  rowData,
}) => {
  const [sortStatus, setSortStatus] = useState("");

  useEffect(() => {
    let temp = 0;
    switch (columnContent) {
      case "bookingDate":
        temp = 1;
        break;
      case "address":
        temp = 2;
        break;
      case "netPrice":
        temp = 3;
        break;
      case "propertyTypeId":
        temp = 4;
        break;
    }
    if (sorting.column && sorting.column !== temp) {
      setSortStatus("");
    }
  }, [sorting]);

  let onToggleSortMode = useCallback(() => {
    let temp =
      sortStatus === "asc" ? "desc" : sortStatus === "desc" ? "" : "asc";
    setSortStatus(temp);
    onClickRearrangeData({
      mode: temp,
      columnValue: columnContent,
    });
  }, [columnContent, sortStatus, rowData]);

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode()}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode()}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode()}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
};

const TableHeader = ({
  columns,
  rowData,
  sorting,
  enableSort,
  headerClass,
  actionColumnContent,
  onClickRearrangeData,
}) => {
  return (
    <ul className={`at-table-row at-table-head  ${headerClass || ""}`}>
      {columns.map(
        ({ searchFlag, header, columnStyle, contentClass }, index) => (
          <li
            style={{
              width: "100%",
              ...columnStyle,
            }}
            key={header}
            className={`at-table-row__item at-table__${index} ${contentClass || ""}`}
          >
            <div className="d-flex align-items-center">
              {typeof header === "string" && (
                <span className="at-table-head__title">
                  {header}
                  {enableSort && (
                    <div style={{ maxWidth: 0, maxHeight: 0, position: "relative" }}>
                      <ArrowContent
                        sorting={sorting}
                        columnContent={searchFlag}
                        rowData={rowData}
                        onClickRearrangeData={onClickRearrangeData}
                      />
                    </div>
                  )}
                </span>
              )}
              {typeof header === "function" && header()}
              
            </div>
          </li>
        ),
      )}
      {actionColumnContent && actionColumnContent.length > 0 && (
        <li
          style={{ width: `${actionColumnContent.length * 35}%` }}
          className="at-table-row__item at-table__actions"
        >
          <span className="at-table-head__title">Actions</span>
        </li>
      )}
    </ul>
  );
};

export default TableHeader;
