import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Delete, Get, GetFile, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const partyDictionary = [
  {
    id: 3,
    subsalesTitle: "Vendor",
    subrentTitle: "Landlord",
  },
  {
    id: 4,
    subsalesTitle: "Vendor's Representative",
    subrentTitle: "Landlord's Representative",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedSigningTab: "e-Signing",
      selectedContact: {},

      showAssignContactModal: false,
      showAssignInternalModal: false,
      showCreateContactModal: false,
      showRefundFormModal: false,
      showEditPartyModal: false,
      showDeletePartyModal: false,
      showDownloadFormDialog: false,

      signingStatus: null,
      partyData: [],
      eProcessPartyData: [],
      eProcessContentId: 1,

      signingStatusId: "0",
    };

    load = (param) => this.setState({ loading: param });

    onChangePartyHOC = (val, context) => this.setState({ [context]: val });

    getParty = (id) =>
      Get(
        `/admin/forms/form_contacts?type_id=3&form_id=${id}`,
        this.getPartySuccess,
        this.getPartyError,
        this.load,
      );
    getPartySuccess = (payload) => {
      let tempSellerData = [];
      let tempSellerAgentData = [];

      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let temp = _.find(partyDictionary, { id: item.party_id });
          let tempData = {
            ...item,
            participants_name:
              item.signee_type_id === 1
                ? [item.company_name, `(${item.company_registration_no})`, item.full_name]
                : item.full_name,
            title:
              this.props.selected_appointment_letter.type_id === 1
                ? temp.subsalesTitle
                : temp.subrentTitle,
            form_contact_id: item.id,
            copies_info: item.copies_info ?? { identity_copy_url: null },
            date_of_birth: Moment(item.date_of_birth, "YYYY-MM-DD").isValid()
              ? Moment(item.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
              : item.date_of_birth
          };
          switch (item.party_id) {
            case 3:
              tempSellerData.push(tempData);
              break;

            default:
              tempSellerAgentData.push(tempData);
              break;
          }
        });

      let tempParty = [
        {
          id: 3,
          data: tempSellerData,
        },
        {
          id: 4,
          data: tempSellerAgentData,
        },
      ];

      this.setState({
        partyData: tempParty,
        eProcessPartyData: [...tempSellerData, ...tempSellerAgentData],
      });
    };
    getPartyError = (error) => requestError(error);

    getSigningStatus = (id) =>
      Get(
        `/admin/e_processes/check_status?form_type=3&form_id=${id}`,
        this.getSigningStatusSuccess,
        this.getSigningStatusError,
        this.load,
      );
    getSigningStatusSuccess = (payload) =>
      this.setState({
        signingStatusId: `${payload.status_id}`,
        signingStatus: payload.status,
        eProcessContentId: payload.status_id === 0 ? 1 : 2,
      });
    getSigningStatusError = (error) => requestError(error);

    downloadCompletedForm = (formId, filename) =>
      GetFile(
        `/admin/e_processes/download?form_id=${formId}&form_type=3`,
        filename,
        () => {},
        this.downloadCompletedFormError,
        this.load,
      );
    downloadCompletedFormError = (error) => requestError(error);

    deletePartyContact = (formId, contactId) =>
      Delete(
        `/admin/forms/form_contacts/${contactId}?type_id=3&form_id=${formId}`,
        () => this.deletePartyContactSuccess(formId),
        this.deletePartyContactError,
        this.load,
      );
    deletePartyContactSuccess = (id) => {
      this.getParty(id);
      this.setState({ showDeletePartyModal: false });
      requestSuccess("Record deleted successfully.");
    };
    deletePartyContactError = (error) => requestError(error);

    updatePartyContact = (dataToSubmit) =>
      Put(
        `/admin/forms/form_contacts/${dataToSubmit.id}?type_id=3`,
        dataToSubmit,
        this.updatePartyContactSuccess,
        this.updatePartyContactError,
        this.load,
      );
    updatePartyContactSuccess = (payload) => {
      requestSuccess("Contact's information is updated successfully.");
      this.getParty(payload.form_id);
    };
    updatePartyContactError = (error) => requestError(error);

    createParty = (dataToSubmit, id) =>
      Post(
        `/admin/forms/form_contacts?type_id=3&form_id=${id}`,
        dataToSubmit,
        this.createPartySuccess,
        this.createPartyError,
        this.load,
      );
    createPartySuccess = (payload) => {
      this.getParty(payload.form_id);
      this.props.getAppointmentLetter(payload.form_id);
      this.props.getCurrentFormContactList(payload.form_id);
      requestSuccess("New contact created successfully.");
      this.setState({
        showCreateContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: [1, 3].includes(payload.party_id),
      });
    };
    createPartyError = (error) => requestError(error);

    assignContact = (dataToSubmit, id, title) =>
      Post(
        `/admin/forms/form_contacts/assign_contact?type_id=3&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignContactSuccess(payload, title),
        this.assignContactError,
        this.load,
      );
    assignContactSuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.setState({ 
        showAssignContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: true,
      });
      this.getParty(payload.form_id);
    };
    assignContactError = (error) => requestError(error);

    assignParty = (dataToSubmit, id, title) =>
      Post(
        `/admin/forms/form_contacts/assign_agent?type_id=3&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignPartySuccess(payload, title),
        this.assignPartyError,
        this.load,
      );
    assignPartySuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.setState({
        showAssignInternalModal: false,
        showAssignMyselfModal: false,
        showAssignSuperiorModal: false,
      });
      this.props.getAppointmentLetter(payload.form_id);
      this.getParty(payload.form_id);
    };
    assignPartyError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          signingStatus={this.state.signingStatus}
          signingStatusId={this.state.signingStatusId}
          selectedContact={this.state.selectedContact}
          onLoadParty={this.state.loading}
          partyData={this.state.partyData}
          eProcessPartyData={this.state.eProcessPartyData}
          eProcessContentId={this.state.eProcessContentId}
          selectedSigningTab={this.state.selectedSigningTab}
          showDownloadFormDialog={this.state.showDownloadFormDialog}
          showAssignContactModal={this.state.showAssignContactModal}
          showAssignInternalModal={this.state.showAssignInternalModal}
          showCreateContactModal={this.state.showCreateContactModal}
          showDeletePartyModal={this.state.showDeletePartyModal}
          showEditPartyModal={this.state.showEditPartyModal}
          showSigningHistory={this.state.showSigningHistory}
          showRefundFormModal={this.state.showRefundFormModal}
          getParty={this.getParty}
          createParty={this.createParty}
          updatePartyContact={this.updatePartyContact}
          assignContact={this.assignContact}
          assignParty={this.assignParty}
          downloadCompletedForm={this.downloadCompletedForm}
          getSigningStatus={this.getSigningStatus}
          deletePartyContact={this.deletePartyContact}
          onChangePartyHOC={this.onChangePartyHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
