import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showDetailsModal: false,
      performanceData: {},
      selectedPerformance: {},
      title: "",
      toggleView: "3 Months",
      showSearchTips: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangePerformanceHOC = (val, context) =>
      this.setState({ [context]: val });

    getTeamPerformance = (id) => {
      Get(
        `/connector/users/performance?identity_id=${id}`,
        this.getTeamPerformanceSuccess,
        this.getTeamPerformanceError,
        this.load,
      );
    };
    getTeamPerformanceSuccess = (payload) => {
      let temp3Month = _.map(payload["3"], (item) => ({
        ...item,
        dateJoined: item.dateJoinedMediumFormat
          ? Moment(item.dateJoinedMediumFormat).format("DD MMM YYYY")
          : "N/A",
      }));
      let temp6Month = _.map(payload["6"], (item) => ({
        ...item,
        dateJoined: item.dateJoinedMediumFormat
          ? Moment(item.dateJoinedMediumFormat).format("DD MMM YYYY")
          : "N/A",
      }));
      this.setState({
        performanceData: {
          3: temp3Month,
          6: temp6Month,
        },
      });
    };
    getTeamPerformanceError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          title={this.state.title}
          performanceData={this.state.performanceData}
          onLoadPerformance={this.state.loading}
          showDetailsModal={this.state.showDetailsModal}
          selectedPerformance={this.state.selectedPerformance}
          toggleView={this.state.toggleView}
          showSearchTips={this.state.showSearchTips}
          onChangePerformanceHOC={this.onChangePerformanceHOC}
          getTeamPerformance={this.getTeamPerformance}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
