import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get, GetFile } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = (data) => {
  let tempBranch = _.map(data.branchReducer.branch, (branch) => {
    return {
      ...branch,
      value: branch.id,
    };
  });

  return [
    {
      label: "Full Name",
      value: "full_name_cont",
      type: "input",
      param: "",
    },
    {
      label: "Display Name",
      value: "display_name_cont",
      type: "input",
      param: "",
    },
    {
      label: "Email",
      value: "email_cont",
      type: "input",
      param: "",
    },
    {
      label: "Mobile Number",
      value: "mobile_contact_number_cont",
      type: "input",
      param: "",
    },
    {
      label: "Branch",
      value: "branch_id_eq",
      type: "select",
      param: "",
      options: tempBranch,
    },
    {
      label: "Status",
      value: "status_id_eq",
      type: "radio",
      col: 8,
      param: "",
      options: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "2" },
        { label: "Waiting Approval", value: "8" },
      ],
    },
  ];
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showDetailsModal: false,
      searchParams: searchParams(this.props.data),

      teamMembersList: {},
      showSearchTips: false,
      selectedTeamMember: {},
      showAgentSales: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    getTeamMembersList = (page, query) =>
      Get(
        `/team_members?${query}page=${page}`,
        this.getTeamMembersListSuccess,
        this.getTeamMembersListError,
        this.load,
      );
    getTeamMembersListSuccess = (payload) => {
      let tmpData = payload.data.map((item) => ({
        ...item,
        dob: item.dob ? Moment(item.dob).format("DD-MMM-YYYY") : "N/A",
      }));

      this.setState({
        teamMembersList: {
          ...payload,
          data: tmpData,
        },
      });
    };
    getTeamMembersListError = (error) => requestError(error);

    getSelectedTeamMember = (id) =>
      Get(
        `/team_members/member?encoded_identity=${id}`,
        this.getSelectedTeamMemberSuccess,
        this.getSelectedTeamMemberError,
        this.load,
      );
    getSelectedTeamMemberSuccess = (payload) =>
      this.setState({
        selectedTeamMember: payload,
        showDetailsModal: true,
      });
    getSelectedTeamMemberError = (error) => requestError(error && error);

    exportTeamMembersList = () =>
      GetFile(
        `/team_members.xls`,
        "Team_Members_List.xls",
        this.exportTeamMembersListSuccess,
        this.exportTeamMembersListError,
        this.load,
      );
    exportTeamMembersListSuccess = () =>
      requestSuccess("Exported Successfully");
    exportTeamMembersListError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadTeamMembers={this.state.loading}
          selectedTeamMember={this.state.selectedTeamMember}
          teamMembersList={this.state.teamMembersList}
          showDetailsModal={this.state.showDetailsModal}
          searchParams={this.state.searchParams}
          showAgentSales={this.state.showAgentSales}
          showSearchTips={this.state.showSearchTips}
          onChangeHOC={this.onChangeHOC}
          getTeamMembersList={this.getTeamMembersList}
          exportTeamMembersList={this.exportTeamMembersList}
          getSelectedTeamMember={this.getSelectedTeamMember}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
