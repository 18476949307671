import React, { Component, useState, useCallback } from "react";

const ArrowContent = ({ onClickRearrangeData, columnContent, rowData }) => {
  const [sortStatus, setSortStatus] = useState("");

  let onToggleSortMode = useCallback(() => {
    let temp =
      sortStatus === "asc" ? "desc" : sortStatus === "desc" ? "" : "asc";
    setSortStatus(temp);
    onClickRearrangeData({
      mode: temp,
      columnValue: columnContent,
    });
  }, [columnContent, sortStatus, rowData]);

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("desc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("asc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
};

class TableHeader extends Component {
  onClickRearrangeData({ mode, columnValue }) {
    this.props.onClickRearrangeData({ mode, columnValue });
  }

  render() {
    const { data, actionColumn, actionColumnContent, actionColumnLabel } =
      this.props;
    return (
      <ul className="at-table-row at-table-head">
        {data.map((item, index) => {
          return (
            <li
              style={{ width: item.width || "100%", ...item.containerStyle }}
              key={item.label}
              className={`at-table-row__item at-table__${index} ${item.contentClass || ""}`}
            >
              <div className="d-flex align-items-center">
                <span className="at-table-head__title">
                  {item.label}
                  <div style={{ maxWidth: 0, maxHeight: 0, position: "relative" }}>
                    <ArrowContent
                      columnContent={item.value}
                      onClickRearrangeData={(headerData) =>
                        this.onClickRearrangeData(headerData)
                      }
                    />
                  </div>
                </span>
              </div>
            </li>
          );
        })}
        {actionColumn ? (
          <li
            style={{ minWidth: 110, width: "30%", maxWidth: 150 }}
            className="at-table-row__item at-table__actions"
          >
            <span className="at-table-head__title">
              {actionColumnLabel ? actionColumnLabel : "Actions"}
            </span>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
}

export default TableHeader;
