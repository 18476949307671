import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Get, Post, Put, Delete } from "utils/axios";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const searchParams = [
  {
    label: "Coupon Code",
    value: "code_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent  Mobile Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateDialog: false,
      showEditDialog: false,
      showDeleteConfirmation: false,

      coupons: {},
      couponPage: null,
      searchParams: searchParams,
      selectedCoupon: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeCouponMngmtHOC = (val, context) =>
      this.setState({ [context]: val });

    getCouponMngmt = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/market_coupons?${search}page=${page}`,
        this.getCouponMngmtSuccess,
        this.getCouponMngmtError,
        this.load,
      );
    };
    getCouponMngmtSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.length > 0 &&
        payload.data.map((item) => {
          const { user, market_product, expiry_date } = item;
          tempData.push({
            ...item,
            expiry_date: expiry_date
              ? Moment(expiry_date, "DD-MM-YYYY").format("DD MMM YYYY")
              : null,
            product: market_product ? market_product.name : "",
            isUsed: item.is_used ? "Yes" : "No",
            agent:
              (user.full_name || "N/A") +
              "\n" +
              (user.mobile_contact_number || "N/A") +
              "\n" +
              (user.email || "N/A") +
              "\n" +
              (user.team_name || "N/A"),
          });
        });
      payload.data = tempData;
      this.setState({ coupons: payload, couponPage: tmpTotalPages });
    };
    getCouponMngmtError = (error) => requestError(error);

    getSelectedCoupon = (id) =>
      Get(
        `/admin/market_coupons/${id}`,
        this.getSelectedCouponSuccess,
        this.getSelectedCouponError,
        this.load,
      );
    getSelectedCouponSuccess = (payload) => {
      const { id, user, expiry_date, market_product } = payload;
      let temp = {
        id: id,
        expiry_date: expiry_date
          ? Moment(expiry_date, "DD-MM-YYYY").format("DD MMM YYYY")
          : null,
        market_product_id: market_product ? market_product.id : "",
        user: user,
      };
      this.setState({
        selectedCoupon: temp,
        showEditDialog: true,
      });
    };
    getSelectedCouponError = (error) => requestError(error);

    createCoupon = (dataToSubmit) =>
      Post(
        `/admin/market_coupons`,
        {
          id: dataToSubmit.id,
          expiry_date: dataToSubmit.hasExpiryDate
            ? Moment(dataToSubmit.expiry_date, "DD MMM YYYY").format(
                "YYYY-MM-DD",
              )
            : null,
          market_product_id: dataToSubmit.market_product_id,
          user_id: dataToSubmit.user.id,
        },
        this.createCouponSuccess,
        this.createCouponError,
        this.load,
      );
    createCouponSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getCouponMngmt(currentPage, searchParams);
      requestSuccess("New coupon created successfully");
      this.setState({ showCreateDialog: false });
    };
    createCouponError = (error) => requestError(error);

    updateCoupon = (dataToSubmit) => {
      let temp = {
        id: dataToSubmit.id,
        expiry_date: dataToSubmit.hasExpiryDate
          ? Moment(dataToSubmit.expiry_date, "DD MMM YYYY").format("YYYY-MM-DD")
          : null,
        market_product_id: dataToSubmit.market_product_id,
        user_id: dataToSubmit.user.id,
      };
      Put(
        `/admin/market_coupons/${temp.id}`,
        temp,
        this.updateCouponSuccess,
        this.updateCouponError,
        this.load,
      );
    };
    updateCouponSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getCouponMngmt(currentPage, searchParams);

      this.getSelectedCoupon(payload.id);
      requestSuccess("Coupon information updated successfully.");
    };
    updateCouponError = (error) => requestError(error);

    deleteCoupon = (id) =>
      Delete(
        `/admin/market_coupons/${id}`,
        this.deleteCouponSuccess,
        this.deleteCouponError,
        this.load,
      );
    deleteCouponSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getCouponMngmt(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });

      requestSuccess(payload.message);
    };
    deleteCouponError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadCoupon={this.state.loading}
          coupons={this.state.coupons}
          selectedCoupon={this.state.selectedCoupon}
          couponPage={this.state.couponPage}
          searchParams={this.state.searchParams}
          showCreateDialog={this.state.showCreateDialog}
          showEditDialog={this.state.showEditDialog}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          getCouponMngmt={this.getCouponMngmt}
          createCoupon={this.createCoupon}
          updateCoupon={this.updateCoupon}
          deleteCoupon={this.deleteCoupon}
          getSelectedCoupon={this.getSelectedCoupon}
          onChangeCouponMngmtHOC={this.onChangeCouponMngmtHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
