import React, { useState } from "react";
import { IconButton, Collapse } from "@material-ui/core";
import { IoIosArrowUp } from "react-icons/io";

import ArrowContent from "./arrow";
import AtlasIcon from "components/Icon/atlasIcon";

const IconStyling = {
  height: "20px",
  width: "20px",
  minWidth: "20px",
  marginRight: 7,
  fill: "#EE6D4D",
};

export default function TableContent({
  index,
  rowItem,
  headerData,
  ClientHeaderData,
  selectedSortingIndex,
  headerRearrangeData,
  onClickRearrangeData,
  onSelectIndex,
}) {
  const [isExpand, onToggleExpand] = useState(false);

  const renderHighlightColumn = (param, columnValue) => {
    if (param) {
      if (param.columnValue === columnValue && index === selectedSortingIndex) {
        return "grid-top-object";
      }
    }
    return "";
  };

  const renderCollapsedTable = () => {
    return (
      <Collapse in={isExpand} timeout="auto" unmountOnExit>
        <table
          className="at-presentation-statistic_table-second_layer"
          style={{
            width: "100%",
            borderRadius: 0,
          }}
        >
          <thead>
            <tr
              className="at-table-row at-table-head"
              style={{ borderRadius: 0, padding: 0 }}
            >
              {ClientHeaderData.map((header, nestedIndex) => (
                <td
                  className={`at-table-row__item at-table__${nestedIndex} ${header.className || ""}`}
                  style={{
                    width: "100%",
                    borderRadius: 0,
                    backgroundColor: "#F3F4F6",
                    ...(header.style || {}),
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="at-table-head__title">{header.label}</span>
                    <div
                      style={{
                        maxWidth: 0,
                        maxHeight: 0,
                        position: "relative",
                      }}
                    >
                      <ArrowContent
                        columnContent={header.value}
                        onClickRearrangeData={(val) => {
                          onClickRearrangeData(val);
                          onSelectIndex(index);
                        }}
                      />
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowItem.clients.length > 0 &&
              rowItem.clients.map((client) => (
                <div className="at-table-row-cont">
                  <tr className="at-table-row grid-controller">
                    {ClientHeaderData.map(
                      (
                        {
                          style = {},
                          label: headerLabel,
                          value: headerValue,
                          className = "",
                        },
                        index,
                      ) => (
                        <td
                          key={`${headerValue}-${index}`}
                          className={`at-table-row__item at-table__${index} ${renderHighlightColumn(headerRearrangeData, headerValue)} ${className}`}
                          style={{ width: "100%", style }}
                        >
                          <span className="at-table-row__item-label">
                            {headerLabel}
                          </span>
                          <p>
                            {index === 0 && client.type_id !== 1 && (
                              <AtlasIcon
                                svgHref={"atlas-frame"}
                                style={{
                                  fill: "#EE6D4D",
                                  marginRight: 7,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            )}
                            {index === 0 && client.type_id === 1 && (
                              <AtlasIcon
                                svgHref={"atlas-profile-remove"}
                                style={{
                                  fill: "#3F82F4",
                                  marginRight: 7,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            )}
                            {client[headerValue]}
                          </p>
                        </td>
                      ),
                    )}
                  </tr>
                </div>
              ))}
          </tbody>
        </table>
      </Collapse>
    );
  };

  return (
    <div className="at-table-row-cont">
      <tr
        className="at-table-row grid-controller"
        aria-disabled={rowItem.clients && rowItem.clients.length === 0}
        onClick={() => rowItem.clients.length > 0 && onToggleExpand(!isExpand)}
        style={{
          ...(rowItem.clients && rowItem.clients.length === 0
            ? { cursor: "pointer" }
            : {}),
        }}
      >
        {headerData &&
          headerData.map(
            (
              {
                label: headerLabel,
                value: headerValue,
                style = {},
                className = "",
              },
              index,
            ) => (
              <td
                key={`${headerValue}-${index}`}
                className={`at-table-row__item at-table__${index} ${className}`}
                style={{ ...(isExpand ? style : {}) }}
              >
                {headerValue === "estate_name" &&
                  rowItem.clients.length > 0 && (
                    <IconButton
                      classes={{ root: "at-presentation-stat-collapse_button" }}
                      style={{
                        transform: isExpand ? "rotate(0deg)" : "rotate(180deg)",
                        alignSelf: "center",
                      }}
                      aria-expanded={isExpand}
                      aria-label="show more"
                    >
                      <IoIosArrowUp style={{ color: "#FFFF" }} />
                    </IconButton>
                  )}
                <span className="at-table-row__item-label">{headerLabel}</span>

                {headerValue === "estate_name" &&
                  ["Listing", "Project"].indexOf(rowItem.estate_type) > -1 && (
                    <p className="d-flex align-items-center">
                      {rowItem.estate_type === "Listing" && (
                        <AtlasIcon
                          svgHref={"atlas-building-3"}
                          style={{ verticalAlign: "bottom", ...IconStyling }}
                        />
                      )}
                      {rowItem.estate_type === "Project" && (
                        <AtlasIcon
                          svgHref={"atlas-building-4"}
                          style={{ ...IconStyling }}
                        />
                      )}
                      <p style={{ paddingTop: "0.2rem", fontSize: 14 }}>
                        {rowItem[headerValue]}
                      </p>
                    </p>
                  )}
                {headerValue !== "estate_name" && <p>{rowItem[headerValue]}</p>}
              </td>
            ),
          )}
      </tr>
      {renderCollapsedTable()}
    </div>
  );
}
