import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import { Get, Post, Delete } from "utils/axios";

import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";

const searchParams = () => {
  return [
    {
      label: "App Name",
      value: "app_name_cont",
      type: "input",
      col: 6,
      param: "",
    },
    {
      label: "API Key",
      value: "token_cont",
      type: "input",
      col: 6,
      param: "",
    },
  ];
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      allApiKeys: [],
      toBeDeletedItem: {},
      showCreateApiKey: false,
      showDeleteApiKey: false,
      loading: false,

      totalPages: [],
      searchParams: searchParams(),
    };

    load = (param) => this.setState({ loading: param });

    onChangeApiKeysHOC = (val, context) => {
      this.setState({ [context]: val });
    };

    getApiKeys = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/api_keys?${search}page=${page}`,
        this.getApiKeysSuccess,
        this.getApiKeysError,
        this.load,
      );
    };
    getApiKeysSuccess = (payload) => {
      let tempPages = [];
      let tempData = payload.data.map((item) => ({
        ...item,
        created_at: item.created_at
          ? moment(item.created_at).format("DD MMM YYYY")
          : "",
      }));
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      return this.setState({
        allApiKeys: {
          ...payload,
          data: tempData,
        },
        totalPages: tempPages,
      });
    };
    getApiKeysError = (error) => requestError(error);

    createApiKey = (data) =>
      Post(
        `/admin/api_keys?app_name=${data.app_name}`,
        data,
        this.createApiKeySuccess,
        this.createApiKeyError,
        this.load,
      );
    createApiKeySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("API Key is created successfully!");
      this.getApiKeys(currentPage, searchParams);
      return this.setState({
        showCreateApiKey: false,
      });
    };
    createApiKeyError = (error) => requestError(error);

    deleteApiKey = (id) =>
      Delete(
        `/admin/api_keys/${id}`,
        this.deleteApiKeySuccess,
        this.deleteApiKeyError,
        this.load,
      );
    deleteApiKeySuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getApiKeys(currentPage, searchParams);
      this.setState({ showDeleteApiKey: false });
      requestSuccess("The API Key is deleted successfully!");
    };
    deleteApiKeyError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          searchParams={this.state.searchParams}
          showCreateApiKey={this.state.showCreateApiKey}
          showDeleteApiKey={this.state.showDeleteApiKey}
          allApiKeys={this.state.allApiKeys}
          toBeDeletedItem={this.state.toBeDeletedItem}
          totalPages={this.state.totalPages}
          onLoadApiKey={this.state.loading}
          getApiKeys={this.getApiKeys}
          createApiKey={this.createApiKey}
          deleteApiKey={this.deleteApiKey}
          onChangeApiKeysHOC={this.onChangeApiKeysHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
