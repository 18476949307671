import React, { useState } from "react";

import CustomButton from "components/Button";
import CustomTextarea from "components/Input/textarea";

const RejectForm = ({
  selectedRoomBooking,
  rejectRoomBooking,
  onChangeRoomBooking,
}) => {
  const [rejectReason, setRejectReason] = useState("")
  return (
    <>
      <div className="grid-control">
        <section className="grid-full-col">
          <label className="at-form-input__title">Reject Reason</label>
          <CustomTextarea
            rows="9"
            type="text"
            placeholder="Enter reject reason here"
            onChangeValue={val => setRejectReason(val)}
            style={{ width: "100%", display: "block" }}
          />
        </section>
      </div>
      <div className="at-modal_dialog-container-footer">
        <CustomButton
          className={"btn-new btn-new--primary mr-2"}
          children={"Reject"}
          onClick={() => rejectRoomBooking(selectedRoomBooking.id, rejectReason)}
        />
        <CustomButton
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() => onChangeRoomBooking(false, "showRejectModal")}
        />
      </div>
    </>
  )
}

export default RejectForm