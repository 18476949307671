import React from "react";

import { numberWithCommas } from "utils/thousandSeparator";

const fixedHeader = [
  {
    header: "Name",
    accessor: "agentName",
    searchFlag: "agentName",
  },
  {
    header: "Title",
    accessor: "jobTitle",
    searchFlag: "jobTitle",
  },
  {
    header: "Referrer",
    accessor: "groupLeaderName",
    searchFlag: "groupLeaderName",
  },
];

const AllConvertedHeader = [
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedConvertedPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllConvertedSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllSubsalesGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedConvertedGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllConvertedSubsalesGroupSales, 2)}</p>
    ),
  },
]

const fixedConvertedHeader = [
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedConvertedPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalCombinedGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalCombinedConvertedGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalCombinedConvertedGroupSales, 2)}</p>
    ),
  },
]

const fixedBookedSaleHeader = [
  {
    header: "Booked Sales (Agency Comm.)",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
    searchFlag: "total_agency_comm",
    renderColumn: (row) => <p>{numberWithCommas(row.total_agency_comm, 2)}</p>,
  },
];

export const projectHeader = [
  ...fixedHeader,
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalProjectPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalProjectPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedProjectPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedProjectPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalProjectGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalProjectGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedProjectGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedProjectGroupSales, 2)}</p>
    ),
  },
];

export const submittedSubsalesHeader = [
  {
    header: "Name",
    accessor: "agentName",
    searchFlag: "agentName",
  },
  { header: "Title", accessor: "jobTitle", searchFlag: "jobTitle" },
  { header: "Referrer", accessor: "groupLeaderName", searchFlag: "groupLeaderName" },
  ...fixedBookedSaleHeader,
];

export const convertedSubsalesHeader = [
  ...fixedHeader,
  ...AllConvertedHeader,
];

export const combineSubsalesHeader = [
  ...fixedHeader,
  ...fixedConvertedHeader,
  ...fixedBookedSaleHeader,
];

export const combineAllSubsaleHeader = [
  ...fixedHeader,
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalAllSubsalesPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalAllConvertedSubsalesPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllConvertedSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalAllSubsalesGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllSubsalesGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalAllConvertedSubsalesGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalAllConvertedSubsalesGroupSales, 2)}</p>
    ),
  },
  ...fixedBookedSaleHeader,
];

export const combineRentHeader = [
  ...fixedHeader,
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalRentalPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalRentalPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedRentalPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedRentalPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalRentalGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalRentalGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedRentalGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedRentalGroupSales, 2)}</p>
    ),
  },
  ...fixedBookedSaleHeader,
];

export const convertedSaleHeader = [
  ...fixedHeader,
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalSubsalesPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedSubsalesPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedSubsalesPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalSubsalesGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalSubsalesGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedSubsalesGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedSubsalesGroupSales, 2)}</p>
    ),
  },
];

export const convertedRentHeader = [
  ...fixedHeader,
  {
    header: "Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalRentalPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalRentalPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Converted Personal sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedRentalPersonalSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedRentalPersonalSales, 2)}</p>
    ),
  },
  {
    header: "Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalRentalGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalRentalGroupSales, 2)}</p>
    ),
  },
  {
    header: "Converted Group sales",
    columnClass: "at-column-responsive_right",
    contentClass: "at-column-responsive_right",
    searchFlag: "totalConvertedRentalGroupSales",
    renderColumn: (row) => (
      <p>{numberWithCommas(row.totalConvertedRentalGroupSales, 2)}</p>
    ),
  },
];