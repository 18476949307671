import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

const UpdateHoliday = (props) => {
  const countries = props.data.dictionaryReducer.countries;
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((item) => item.id === props.selectedHoliday?.country_id),
  );
  const [formData, setFormData] = useState({
    id: props.selectedHoliday?.id,
    name: props.selectedHoliday?.name,
    date: props.selectedHoliday?.date,
    country_id: props.selectedHoliday?.country_id ?? "",
    state_ids: props.selectedHoliday?.state_ids ?? [],
    showRegion: false,
  });

  const onChangeData = (key, value) => {
    const temp = _.cloneDeep(formData);
    temp[key] = value;
    setFormData(temp);
  };

  const onChangeCountry = (country) => {
    let temp = _.cloneDeep(formData);
    temp = {
      ...temp,
      country_id: country.id,
      state_ids: country?.states?.map((item) => item.id),
    };
    setFormData(temp);
    setSelectedCountry(country);
  };

  return (
    <ModalDialog
      title={props.title}
      fullWidth={true}
      onClose={() => props.onClose()}
      children={
        <>
          <div className="grid-control">
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Name</h2>
              <CustomFormInput
                type="text"
                value={formData?.name}
                className="w-100"
                onChangeValue={(val) => onChangeData("name", val)}
                placeholder={"Name"}
              />
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title">Date</h2>
              <div className="at-form-input w-100">
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-2-linear"}
                />
                <DatePicker
                  value={formData?.date}
                  className="at-form-input__input"
                  onChange={(date) =>
                    onChangeData("date", Moment(date).format("DD/MM/YYYY"))
                  }
                  dateFormat={"dd/MM/yyyy"}
                  showYearDropdown={true}
                  placeholderText={"Date"}
                />
              </div>
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title">Country</h2>
              <CustomSelect
                className={"at-datepicker__select"}
                selectItems={countries}
                updateSelect={(item) => onChangeCountry(item)}
                currentlySelected={selectedCountry}
              />
            </section>
          </div>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              content={"All Regions"}
              checked={_.values(selectedCountry?.states)?.every((item) =>
                formData?.state_ids?.includes(item.id),
              )}
              onChangeCheckboxValue={(e) => {
                if (e.target.checked) {
                  onChangeData(
                    "state_ids",
                    selectedCountry?.states.map((item) => item.id),
                  );
                } else {
                  onChangeData("state_ids", []);
                }
              }}
            />
            <CustomButton
              className="btn-new align-items-center"
              style={{ color: "#2563EB" }}
              onClick={() => onChangeData("showRegion", !formData.showRegion)}
              children={
                <div className="d-flex align-items-center">
                  {formData.showRegion ? "Hide" : "Show"} All
                  {formData.showRegion ? (
                    <BsChevronUp
                      style={{
                        width: 16,
                        height: 16,
                        marginLeft: 8,
                        color: "#2563EB",
                      }}
                    />
                  ) : (
                    <BsChevronDown
                      style={{
                        width: 16,
                        height: 16,
                        marginLeft: 8,
                        color: "#2563EB",
                      }}
                    />
                  )}
                </div>
              }
            />
          </div>
          <div style={{ minHeight: 200 }}>
            {formData.showRegion && (
              <div className="d-flex align-items-center flex-wrap g-3">
                {!_.isEmpty(selectedCountry) &&
                  selectedCountry?.states.map((item, index) => (
                    <CustomCheckbox
                      key={index}
                      content={item.name}
                      checked={formData.state_ids?.includes(item.id)}
                      onChangeCheckboxValue={(e) => {
                        if (e.target.checked) {
                          onChangeData("state_ids", [
                            ...formData.state_ids,
                            item.id,
                          ]);
                        } else {
                          onChangeData(
                            "state_ids",
                            formData.state_ids.filter(
                              (state) => state !== item.id,
                            ),
                          );
                        }
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
        </>
      }
      footer={
        <div className="d-flex g-3">
          <CustomButton
            children={props.mode === "update" ? "Update" : "Submit"}
            className={`btn-new btn-new--${props.mode === "update" ? "success" : "primary"}`}
            onClick={() => props.onToggleSubmit(formData)}
          />
          <CustomButton
            children="Close"
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onClose()}
          />
        </div>
      }
    />
  );
};

export default UpdateHoliday;
