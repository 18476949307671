import React, { Component } from "react";
import { connect } from "react-redux";
import { Get, Post } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      unclaimedConfirmationForms: [],
      unclaimedSubsalesForms: [],
    };

    load = (param) => this.setState({ loading: param });

    submitForm = (id, formType, dataToSubmit) => {
      if (formType === "ConfirmationForm") {
        return Post(
          `/e_processes/submit_claim?form_id=${id}`,
          { representative_id: dataToSubmit.representative_id },
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      } else if (formType === "AppointmentLetter") {
        return Post(
          `/forms/appointment_letters/${id}/submit`,
          dataToSubmit,
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      } else if (formType === "AuthorisationForm") {
        return Post(
          `/forms/authorisation_forms/${id}/submit`,
          dataToSubmit,
          (payload) =>
            this.submitFormSuccess({
              ...dataToSubmit,
              id: id,
              formType: formType,
            }),
          this.submitFormError,
          this.load,
        );
      }
    };
    submitFormSuccess = ({ formType, form_id, id }) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess("Form is submitted successfully");

      if (formType === "AppointmentLetter") {
        this.props.getAppointmentLetter(id);
        this.props.getAppointmentLetters(currentPage, searchParams);
      } else if (formType === "AuthorisationForm") {
        this.props.getSelectedAuthorisationForms(id);
        this.props.getAuthorisationForms(currentPage, searchParams);
      } else if (formType === "ConfirmationForm") {
        this.props.getConfirmationForm(id);
        this.props.getConfirmationForms(currentPage, searchParams);
      }

      if (this.props.closeSubmitConfirmation) {
        this.props.closeSubmitConfirmation();
      }
    };
    submitFormError = (error) => requestError(error)

    getUnattachedConfirmationForms = (module_type, type_id) =>
      Get(
        `/forms/confirmation_forms/unattached?module_type=${module_type}&type_id=${type_id}`,
        this.getAttachedConfirmationFormsSuccess,
        this.getAttachedConfirmationFormsError,
        this.load,
      );
    getAttachedConfirmationFormsSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.transaction_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedConfirmationForms: tmp });
      }
    };
    getAttachedConfirmationFormsError = (error) => requestError(error);

    getUnattachedSubsalesForms = (type_id) =>
      Get(
        `/forms/sub_sales_forms/unattached?type_id=${type_id}`,
        this.getUnattachedSubsalesFormsSuccess,
        this.getUnattachedSubsalesFormsError,
        this.load,
      );
    getUnattachedSubsalesFormsSuccess = (payload) => {
      if (payload && payload.length > 0) {
        let tmp = [];
        payload.map((item) => {
          tmp.push({
            ...item,
            label: item.tracking_number,
            value: item.id,
          });
        });
        this.setState({ unclaimedSubsalesForms: tmp });
      }
    };
    getUnattachedSubsalesFormsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadForms={this.state.loading}
            unclaimedConfirmationForms={this.state.unclaimedConfirmationForms}
            unclaimedSubsalesForms={this.state.unclaimedSubsalesForms}
            getUnattachedConfirmationForms={this.getUnattachedConfirmationForms}
            getUnattachedSubsalesForms={this.getUnattachedSubsalesForms}
            searchUnattachedForm={this.searchUnattachedForm}
            submitForm={this.submitForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
