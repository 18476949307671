import React, { useState, useCallback } from "react";

export default function ArrowContent({
  onClickRearrangeData,
  columnContent,
  rowData,
}) {
  const [sortStatus, setSortStatus] = useState("");

  let onToggleSortMode = useCallback(() => {
    let temp =
      sortStatus === "asc" ? "desc" : sortStatus === "desc" ? "" : "asc";
    setSortStatus(temp);
    onClickRearrangeData({
      mode: temp,
      columnValue: columnContent,
    });
  }, [columnContent, sortStatus, rowData]);

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("desc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("asc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
}
