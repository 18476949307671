import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      selectedSection: "My Trainings",
      myTrainingData: [],
      myTrainingCompletion: false,
      upcomingTraining: [],
      can_create: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeMyTrainingHOC = (val, context) => this.setState({ [context]: val });

    getMyTraining = () =>
      Get(
        `/trainings`,
        this.getMyTrainingSuccess,
        this.getMyTrainingError,
        this.load,
      );
    getMyTrainingSuccess = (payload) => {
      const { user_zoom_links, trainings } = payload;
      let tempRegistered = [];
      let tempTraining = [];

      trainings.length > 0 &&
        trainings.map((item) => {
          tempTraining.push({
            ...item,
            end_datetime:
              item.end_datetime && item.end_datetime !== null
                ? Moment(item.end_datetime).format("DD MMM YYYY hh:mm A")
                : "N/A",
            start_datetime:
              item.start_datetime && item.start_datetime !== null
                ? Moment(item.start_datetime).format("DD MMM YYYY hh:mm A")
                : "N/A",
          });
        });

      user_zoom_links.length > 0 &&
        user_zoom_links.map((item) => {
          tempRegistered.push({
            ...item,
            isRegistered: true,
            event_name: item.meeting_topic,
            end_datetime: Moment(item.end_datetime).format(
              "DD MMM YYYY hh:mm A",
            ),
            start_datetime: Moment(item.start_datetime).format(
              "DD MMM YYYY hh:mm A",
            ),
          });
        });
      this.setState({
        myTrainingData: [...tempTraining, ...tempRegistered],
      });
    };
    getMyTrainingError = (error) => requestError(error);

    // check if all training has completed or not
    getTrainingCompletionStatus = () =>
      Get(
        `/trainings/completion_status`,
        this.getTrainingCompletionStatusSuccess,
        this.getTrainingCompletionStatusError,
        this.load,
      );
    getTrainingCompletionStatusSuccess = (payload) => {
      payload
        ? this.setState({ myTrainingCompletion: payload })
        : this.getUpcomingTraining();
    };
    getTrainingCompletionStatusError = (error) => requestError(error);

    // get upcoming training
    getUpcomingTraining = () =>
      Get(
        `/trainings/upcoming_trainings`,
        this.getUpcomingTrainingSuccess,
        this.getUpcomingTrainingError,
        this.load,
      );
    getUpcomingTrainingSuccess = (payload) => {
      let temp = [];
      const { event_zoom_details } = payload;

      !_.isEmpty(payload) &&
        event_zoom_details.length > 0 &&
        event_zoom_details.map((item) => {
          if (!_.isEmpty(item)) {
            temp.push({
              ...item,
              end_datetime: Moment(item.end_datetime).format(
                "DD MMM YYYY hh:mm A",
              ),
              start_datetime: Moment(item.start_datetime).format(
                "DD MMM YYYY hh:mm A",
              ),
            });
          }
        });
      this.setState({ upcomingTraining: temp });
    };
    getUpcomingTrainingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            can_create={this.state.can_create}
            selectedSection={this.state.selectedSection}
            onLoadMyTraining={this.state.loading}
            upcomingTraining={this.state.upcomingTraining}
            myTrainingCompletion={this.state.myTrainingCompletion}
            myTrainingData={this.state.myTrainingData}
            getMyTraining={this.getMyTraining}
            getUpcomingTraining={this.getUpcomingTraining}
            getUpcomingRegisteredTraining={this.getUpcomingRegisteredTraining}
            getTrainingCompletionStatus={this.getTrainingCompletionStatus}
            onChangeMyTrainingHOC={this.onChangeMyTrainingHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
