import BlackBoxTemplate from './BlackBoxTemplate';
import BlackPillsTemplate from './BlackPillsTemplate';
import PillsTemplate from './PillsTemplate';
import TransparentBoxTemplate from './TransparentBoxTemplate';
import TrapeziumsTemplate from './TrapeziumsTemplate';
import WhiteBoxTemplate from './WhiteBoxTemplate';

const AgentDetails = props => {
  const { configuration } = props;

  switch (configuration?.agentDetailsTemplateId) {
    case 1:
      return <WhiteBoxTemplate {...props} />;
    case 2:
      return <TransparentBoxTemplate {...props} />;
    case 3:
      return <TrapeziumsTemplate {...props} />;
    case 4:
      return <PillsTemplate {...props} />;
    case 5:
      return <BlackBoxTemplate {...props} />;
    case 6:
      return <BlackPillsTemplate {...props} />;
    default:
      return null;
  }
};

export default AgentDetails;
