import React from "react";
import _ from "lodash";

import AtTable from "../Table/index";

const headerData = [
  {
    label: "Module Name",
    value: "module_name",
    width: "300%",
  },
  {
    label: "Create",
    value: "can_create",
  },
  {
    label: "Read",
    value: "can_read",
  },
  {
    label: "Update",
    value: "can_update",
  },
  {
    label: "Delete",
    value: "can_destroy",
  },
];

const Permissions = ({
  selectedPermissionGroup,
  permissionGroupModules = selectedPermissionGroup.permission_group_modules.sort(
    (a, b) =>
      a.module_name > b.module_name ? 1 : b.module_name > a.module_name ? -1 : 0
  ),

  onClose,
  onChangePermissionGroupUsersHOC,
  updateSelectedPermissionGroup,
}) => {
  //Change Permission of selected Module
  const onToggleCheckBox = (recordID, accessKey, accessVal) => {
    let tmp = _.cloneDeep(selectedPermissionGroup);
    let index = _.findIndex(tmp.permission_group_modules, (item) => {
      return item.id === recordID;
    });
    tmp.permission_group_modules[index][accessKey] = !accessVal;
    onChangePermissionGroupUsersHOC("selectedPermissionGroup", tmp);
  };

  return (
    <>
      <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col pt-20">
          <AtTable
            headerData={headerData}
            selectedPermissionGroup={selectedPermissionGroup}
            rowData={permissionGroupModules ? permissionGroupModules : []}
            originalRowData={
              permissionGroupModules ? permissionGroupModules : []
            }
            pagination={true}
            searchParams={[
              {
                label: "Module Name",
                value: "module_name",
                type: "input",
                param: "",
              },
            ]}
            toggleSearchSelect={false}
            showAllTableList={true}
            onToggleCheckBox={onToggleCheckBox}
            onChangeHOC={onChangePermissionGroupUsersHOC}
            searchWithField={""}
            searchPlaceholder={""}
            rowKey={""}
          />
        </section>
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          className="btn-new btn-new--success"
          onClick={() => updateSelectedPermissionGroup()}
        >
          Update Permission
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default Permissions;
