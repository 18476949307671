import React, { useState } from "react";
import _ from "lodash";
import { Card, CardContent, Grid } from "@material-ui/core";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import { FabricProvider } from "context/fabric";

import HelpInfoContent from "./Form/helpInfo";
import TitleDescContent from "./Form/titleDesc";
import FabricContent from "./Form/fabric";

const CreateForm = ({
  category,
  showLoading,

  onClickSubmit,
  onClickClose,
}) => {
  const [createFormData, onChangeFormData] = useState({
    title: "",
    type_id: 1,
    description: "",
    category_list: [],
  });
  const [sampleGraphic, setSampleGraphic] = useState(null);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  const onChangeFile = (fileItems) => {
    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSampleGraphic(e.target.result);
      };
      reader.readAsDataURL(fileItems[0].file);
    } else {
      setSampleGraphic(null);
    }
  };

  return (
    <>
      <div className="at-card__header mb-3">
        <AtlasCloseButton onClick={() => onClickClose()} />
        <h5 className="at-card__title">{"New Template"}</h5>
      </div>
      <p className={"at-card__description mb-3"}>
        <span className="d-block mb-2 mt-2">
          To get started, enter a short but descriptive title for this template
          followed by a detailed description.
        </span>
        <span className="d-block mb-2">
          Next you need to setup the graphics themselves. Start by clicking on
          the "Add Background" button to add a background image. You now need to
          add containers for the content that agents will upload themselves such
          as images or text. Click on the "Add Container" icon, choose the type
          of content, add a label for the container and then click the Add
          button. You can then move, resize and rotate the container on the
          background to position it correctly.
        </span>
        <span>Once you're done, click on the Create button.</span>
      </p>
      <FabricProvider>
        <Grid container spacing={16} className={"mb-3"}>
          <Grid item lg={9} md={8} sm={7} xs={12}>
            <Card>
              <CardContent>
                <TitleDescContent
                  context={"create"}
                  category={category}
                  formData={createFormData}
                  onChangeFile={onChangeFile}
                  sampleGraphic={sampleGraphic}
                  onChangeField={onChangeField}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={5} xs={12}>
            <HelpInfoContent />
          </Grid>
        </Grid>
        <FabricContent
          context={"create"}
          sampleGraphic={sampleGraphic}
          formData={createFormData}
          onClickSubmit={onClickSubmit}
          onClickClose={onClickClose}
        />
      </FabricProvider>
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
