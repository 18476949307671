import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { FaBuilding } from "react-icons/fa";

import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "../ProjectTable";
import DetailsContent from "./Details";

import ProjectHOC from "./actions/estate";
import ProjectDetailsHOC from "./actions/details";

class Editproject extends Component {
  componentDidMount = () =>
    this.props.getEstateProject(this.props.selectedPresentation.id, 1, "");

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.estatetProjectSearchParams);
    let countryIndex = _.findIndex(temp, { value: "country_id_eq" });
    let stateIndex = _.findIndex(temp, { value: "state_id_eq" });

    if (
      pp.estatetProjectSearchParams[countryIndex].param !==
      temp[countryIndex].param
    ) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: temp[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";

      this.props.onChangeEstateProjectHOC(temp, "estatetProjectSearchParams");
    }
  };

  onClickCard = (estateId) => {
    this.props.getSelectedProject(estateId);
    this.props.getVideos(estateId);
    this.props.getDocuments(estateId);
    this.props.getVisualisations(estateId);
  };

  render = () => {
    const {
      estateProjects,
      estateProjectPages,
      estateProjectsNavData = {
        currentPage: 1,
        searchParams: "",
      },
      selectedPresentation,
      onLoadEstate,
      onLoadProjectDetails,
      onLoadProject,
      onLoadPresentation,
      estatetProjectSearchParams,

      showProjectDetails,
    } = this.props;

    const { id: presentationId } = selectedPresentation;

    return (
      <>
        <div className="position-relative bg-primary badge px-3 py-2 mb-2">
          <span className={"mr-1 fw-600 text-white"}>
            Selected Project Count
          </span>
          <FaBuilding style={{ width: 16, height: 16, color: "#ffff" }} />
          <span
            className="position-absolute badge badge-danger rounded-circle"
            style={{ top: -10, right: -10, fontSize: 12 }}
          >
            {selectedPresentation.total_projects}
          </span>
        </div>
        <AtPaginatedTable
          actionsColumns={["details", "remove", "add"]}
          onShowRemoveIcon={"estate"}
          onShowAddIcon={"!estate"}
          onClickDetails={(val) => this.onClickCard(val.id)}
          onClickAdd={(val) => this.props.createProject(presentationId, val.id)}
          onClickRemove={(val) =>
            this.props.removeProject(selectedPresentation.id, val.id, () => {
              this.props.getEstateProject(
                selectedPresentation.id,
                estateProjectsNavData.currentPage,
                estateProjectsNavData.searchParams,
              );
            })
          }
          rowData={estateProjects.data || []}
          meta={estateProjects.meta && estateProjects.meta}
          searchParams={estatetProjectSearchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeEstateProjectHOC(
              val,
              "estatetProjectSearchParams",
            )
          }
          getListAPI={(page, search) =>
            this.props.getEstateProject(selectedPresentation.id, page, search)
          }
          totalPages={estateProjectPages}
        />
        {showProjectDetails && (
          <ModalDialog
            title={"Project Details"}
            fullWidth={true}
            fullHeight={true}
            children={
              <DetailsContent
                selectedProject={this.props.selectedProject}
                documents={this.props.documents}
                onDownloadFile={this.props.onDownloadFile}
                visualisations={this.props.visualisations}
                videos={this.props.videos}
              />
            }
            onClose={() =>
              this.props.onChangeProjectDetailsHOC(false, "showProjectDetails")
            }
          />
        )}
        {(onLoadEstate ||
          onLoadProject ||
          onLoadProjectDetails ||
          onLoadPresentation) && <LoadingModal />}
      </>
    );
  };
}

export default compose(ProjectDetailsHOC, ProjectHOC)(Editproject);
