import React from "react";

export const columnData = [
  {
    header: "ID",
    accessor: "id",
    columnStyle: { width: "30%" },
  },
  {
    header: "Letter Date",
    accessor: "letter_date",
    columnStyle: { width: "50%" },
  },
  {
    header: "Type",
    accessor: "type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Agent",
    renderColumn: ({ agent }) => (
      <>
        <p>{agent.full_name || "N/A"}</p>
        <p>{agent.mobile_contact_number || "N/A"}</p>
        <p>{agent.email || "N/A"}</p>
        <p>{agent.team_name || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Agency Name",
    renderColumn: ({ agency_name, agency_address }) => (
      <>
        <strong className={"mb-2"}>{agency_name || "N/A"}</strong>
        <p>{agency_address || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Property Address",
    accessor: "property_address",
    columnStyle: { width: "170%" },
  },
];
