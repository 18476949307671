import React, { Component } from "react";
import _ from "lodash";
import { Post } from "utils/axios";
import { connect } from "react-redux";
import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WithComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    approveNewRENS = () => {
      let tmp = {};
      let tmpArray = [];
      this.props.selectedUsers.map((item) => tmpArray.push(item));
      tmp.approval_ids = tmpArray;
      tmp.status_id = 2;
      Post(
        `/admin/registrations/approve`,
        tmp,
        this.approveNewRENSSuccess,
        this.approveNewRENSFailed,
        this.load,
      );
    };
    approveNewRENSSuccess = (payload) => {
      requestSuccess(payload.message);
      this.props.onChangeApprovalHOC([], "selectedUsers");
      this.props.onChangeApprovalHOC(false, "showConfirmationModal");

      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getPendingNewREN(currentPage, searchParams);
    };
    approveNewRENSFailed = (error) => requestError(error);

    render = () => {
      return (
        <WithComponent
          {...this.props}
          onLoadApproval={this.state.loading}
          approveNewRENS={this.approveNewRENS}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
