import React, { Component } from "react";
import _ from "lodash";
import { Post } from "utils/axios";

import { requestSuccess, requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    importExcel = (branchIds, fileItem, fileName) =>
      Post(
        `/admin/market_order_items/import`,
        {
          branch_ids: branchIds,
          import_file_name: fileName,
          import_data: fileItem,
        },
        this.importExcelSuccess,
        this.importExcelError,
        this.load,
      );
    importExcelSuccess = (payload) => {
      this.props.onChangeNameCardMngmtHOC(false, "showImportExcelModal");
      requestSuccess(payload.message);
    };
    importExcelError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadImport={this.state.loading}
          importExcel={this.importExcel}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
