import React, { Component, useMemo } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dialog, Card, CardHeader } from "@material-ui/core";
import ReactHTMLParser from "react-html-parser";
import { AiOutlineClose } from "react-icons/ai";

import LoadingModal from "components/LoadingModal";

import NotificationHOC from "actions/notification";
import VersionCheckHOC from "actions/versionCheck";

import defaultImage from "assets/images/iqi_logo_only.png";

const typeLib = [
  {
    id: 1,
    src: "event_notification",
  },
  {
    id: 2,
    src: "reports_notification",
  },
  {
    id: 3,
    src: "reminder_notifcations",
  },
  {
    id: 4,
    src: "new_ren_notifications",
  },
  {
    id: 5,
    src: "subsales_ notifcations",
  },
  {
    id: 6,
    src: "video_creator",
  },
  {
    id: 7,
    src: "project_sales",
  },
  {
    id: 9,
    src: "cobroke-request",
  },
];

class Details extends Component {
  componentDidMount = () => {
    let temp = window.location.href.indexOf("/details/");
    let notificationId = "";
    if (temp > -1) {
      notificationId = window.location.href.substring(
        temp + 9,
        window.location.href.length,
      );
      this.props.getSelectedNotification(notificationId);
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    const tmpPath = window.location.href.includes("/admin-impersonate")
      ? this.props.prefix_path
      : "";
    this.props.history.push(`${tmpPath}/dashboard/notification`);
  };

  render = () => {
    const { type_id, title, content } = this.props.selectedNotification;

    const imgSrc = () => {
      let temp = _.find(typeLib, { id: type_id });
      if (temp) {
        return require(`assets/images/${temp.src}.jpg`);
      } else {
        return defaultImage;
      }
    };

    return (
      <>
        <Dialog
          maxWidth={"md"}
          classes={{ paper: "m-0" }}
          open={this.props.selectedNotification}
        >
          <Card classes={{ root: "overflow-auto" }}>
            <CardHeader
              style={{
                background: "#F9FAFB",
                borderBottom: "1px solid #D1D5DB",
              }}
              action={
                <button
                  className="btn-new btn-new--outline-secondary ml-auto"
                  onClick={() => this.closeDialog()}
                >
                  <AiOutlineClose
                    style={{ width: 14, height: 14, color: "#374151" }}
                  />
                </button>
              }
              title={<h2 className="at-card__title">{title}</h2>}
            />
            <img
              className={"at-notification-modal_img"}
              style={{ width: "100%", maxHeight: 180, objectFit: "cover" }}
              src={imgSrc()}
            />
            <div
              className={"mt-20 mb-20"}
              style={{ padding: "10px 20px 80px" }}
            >
              {ReactHTMLParser(content)}
            </div>
            <div
              style={{
                background: "#F9FAFB",
                borderTop: "1px solid #D1D5DB",
                padding: 10,
              }}
            >
              <button
                className="btn-new btn-new--outline-secondary m-auto"
                onClick={() => this.closeDialog()}
              >
                Close
              </button>
            </div>
          </Card>
        </Dialog>
        {this.props.onLoadNotification && <LoadingModal />}
      </>
    );
  };
}

export default compose(NotificationHOC, VersionCheckHOC)(Details);
