import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { setLanguage } from 'reducers/language'
import _ from 'lodash'

import AtlasIcon from 'components/Icon/atlasIcon'
import CustomButton from "components/Button";
import permissionsChecker from 'utils/permissionsChecker'

import { getColorBadge } from "dictionary/badgeColor";

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (language) => dispatch(setLanguage(language)),
})

class LanguageButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropDown: false,
    }
    this.parentRef = createRef()
    this.localisedDropDownRef = createRef()
    this.modifyDropDownRef = createRef()
  }

  componentDidMount() {
    this.props.getAgentLocalisedSetting()
    document.addEventListener("click", this.handleOnClose, true)
  }
  
  componentWillUnmount() {
    document.removeEventListener("click", this.handleOnClose)
  }

  handleOnClose = (event) => {
    const parent = this.parentRef.current
    if (parent && !parent.contains(event.target)) {
      this.setState({ showDropDown: false })
    }
  }

  onClickDropdownItem = (param, reference) => {
    if (reference === this.localisedDropDownRef) {
      this.setState({ showDropDown: false })
      this.props.changeLanguage(param.language_code)
      if (this.props.role === "Super Admin" || this.props.role === "Admin") {
        this.props.getModuleKeys(14, param.language_code)
      } else {
        this.props.getAgentModuleKeys(14, param.language_code)
      }
    } else {
      Promise.all([
        this.props.onChangeHOC(param, "selectedTranslation")
      ]).then(() => {
        this.setState({ showDropDown: false })
        this.props.onChangeHOC(true, "showBulkUpdate")
      })
    }
  }

  renderLocalisedOptions = (ref) => {
    return (
      <div
        ref={ref}
        className={"at-party__dropbear--modal-root"}
        style={{
          display: this.state.showDropDown === ref ? "block" : "none",
          ...(ref === this.modifyDropDownRef ? {} : { left: "auto" }),
          ...(window.innerWidth <= 767 ? { transform: "none" } : {}),
          right: "0px"
        }}>
        <div className={`at-party__dropbear--list`}>
          {ref === this.localisedDropDownRef ? (
            this.props.settings?.map((setting, index) => (
              <button 
                key={index} 
                onClick={() => this.onClickDropdownItem(setting, ref)}
                style={this.props.selectedLocalised.toLowerCase() === setting.language_code.toLowerCase() ? { backgroundColor: "#f3f4f6" } : null}>
                {`${setting.language_name}`}
              </button>
            ))
          ) : ref === this.modifyDropDownRef ? (
            this.props.settings.map((setting, index) => (
              <button key={index} onClick={() => this.onClickDropdownItem(setting, ref)}>
                {`${setting.language_name}`}
              </button>
            ))
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    const { can_update } = permissionsChecker("Accessible Translation", this.props.data)
    return (
      <div className="d-flex align-items-center" ref={this.parentRef}>
        {!this.props.hideLanguageButton && (
          <>
            <div className="d-flex flex-column">
              <CustomButton
                id={'language-dropdown'}
                className="at-topbar-opt__btn mx-2"
                children={<AtlasIcon svgHref={"atlas-global"}/>}
                onClick={() => this.setState({ showDropDown: this.state.showDropDown === this.localisedDropDownRef ? false : this.localisedDropDownRef })}
                tooltip={this.state.showDropDown !== this.localisedDropDownRef}
                tooltipChildren={
                  <div className='d-flex align-items-center'>
                    <span className='mr-2'>
                      Language
                    </span>
                    <div
                      className="at-status_badge"
                      style={{ ...getColorBadge("Blue") }}
                    >
                      BETA
                    </div>
                  </div>
                }
                tooltipPosition={"bottom"}
                tooltipID={"TopbarLanguageDropdown"}
              />
            </div>
            {this.renderLocalisedOptions(this.localisedDropDownRef)}
          </>
        )}
        {(can_update && !this.props.hideUpdateButton) && (
          <button
            id={'bulk-update-btn'}
            className="btn-new position-relative"
            onClick={() => this.setState({ showDropDown: this.state.showDropDown === this.modifyDropDownRef ? false : this.modifyDropDownRef })}>
            <AtlasIcon svgHref={"atlas-global-edit"} style={{ width: 18, height: 18 }} />
            {this.renderLocalisedOptions(this.modifyDropDownRef)}
          </button>
        )}
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(LanguageButton)