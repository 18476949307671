import React, { Component } from "react";
import ReactLottie from "react-lottie";

import MaterialLoadingLottie from "assets/lottie/material_loading.json";

import "stylesheets/components/loader/index.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: MaterialLoadingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class BackgroundLoader extends Component {
  state = {
    isPaused: false,
  };

  render() {
    const { showLoadingOverlay } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          right: "15px",
          bottom: "15px",
          width: "18vw",
          height: "10vh",
          display: showLoadingOverlay ? "block" : "none",
          background: "rgba(0,0,0,0.8)",
          pointerEvents: "none",
          zIndex: 7,
        }}
      >
        <ReactLottie options={defaultOptions} isPaused={this.state.isPaused} />
      </div>
    );
  }
}

export default BackgroundLoader;
