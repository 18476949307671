import React, { useState, useEffect, useCallback } from "react";

import NameCardContent from "./NameCardContent";
import AtlasImageEditor from "components/ImageEditor";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";

import { convertImgToBase64URL } from "utils/imgBase64";

const PersonalInfo = ({
  disableSubmit,
  updateAgentInfo,
  nameCardDisplayNameError,
  onLoadUpdateProfile,

  onChangeState,
  onClickUpdateProfileInfo,
  onChangeUpdateAgent,
  onClickClose,
  onChangeProfileHOC,
}) => {
  const [showEditor, onToggleEditor] = useState(false);
  const [selectedEdit, onChangeEdit] = useState(null);
  const [tempFile, onChangeFile] = useState(null);
  // store original file, one before editing.

  useEffect(() => {
    if (selectedEdit) {
      onToggleEditor(true);
      onChangeProfileHOC(false, "loading");
    }
  }, [selectedEdit]);

  useEffect(() => {
    !showEditor && onChangeEdit(null);
  }, [showEditor]);

  const onHandleEdit = useCallback((file) => {
    onChangeProfileHOC(true, "loading");
    onChangeFile(file);
    convertImgToBase64URL(file, (val) => onChangeEdit(val), "img/png");
  }, []);

  let onHandleAppendImage = useCallback((file) => {
    onChangeUpdateAgent(file, "name_card_attributes", null, "photo");
  }, []);

  return (
    <>
      <form
        className="at-profile__container grid-control"
        onSubmit={(e) => e.preventDefault()}
      >
        <NameCardContent
          updateAgentInfo={updateAgentInfo}
          nameCardDisplayNameError={nameCardDisplayNameError}
          onChangeUpdateAgent={onChangeUpdateAgent}
          onHandleEdit={onHandleEdit}
          onChangeState={onChangeState}
        />
        <section className="grid-full-col d-flex g-3">
          <button
            type={"button"}
            disabled={disableSubmit}
            className="btn-new btn-new--success btn-wd"
            style={{ marginLeft: 5 }}
            onClick={(e) => onClickUpdateProfileInfo(e)}
          >
            Update
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={onClickClose}
          >
            Close
          </button>
        </section>
      </form>
      <AtlasDialog open={showEditor}>
        <AtlasImageEditor
          aspectRatio={9 / 16}
          onClickSave={(file) => {
            onToggleEditor(false);
            onHandleAppendImage(file);
          }}
          onClickProceedWithoutCropping={() => {
            onToggleEditor(false);
            onHandleAppendImage(tempFile);
          }}
          onClickClose={() => onToggleEditor(false)}
          imagePath={selectedEdit}
          imageFile={"temp"}
          ImageEditorTheme={{
            "header.display": "none",
            "submenu.normalIcon.color": "#8a8a8a",
            "submenu.activeIcon.color": "#e9e9e9",
          }}
          ImageEditorMenu={["crop"]}
        />
      </AtlasDialog>
      {onLoadUpdateProfile ? <LoadingModal /> : <></>}
    </>
  );
};

export default PersonalInfo;
