import React, { Component } from "react";
import { connect } from "react-redux";

import { Post, Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const newBookingLimit = [
  { role_id: 4, value: 0 },
  { role_id: 5, value: 0 },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      bookingLimit: [],
    }

    load = (param) => this.setState({ loading: param });
    onChangeBookingLimit = (param, key) => this.setState({ [key]: param });

    getBookingLimit = () => Get(
      `/admin/meeting_rooms/booking_limit`,
      this.getBookingLimitSuccess,
      this.getBookingLimitError,
      this.load
    )
    getBookingLimitSuccess = payload => this.setState({ 
      bookingLimit: payload.limits.length === 0 
        ? newBookingLimit
        : payload.limits 
    })
    getBookingLimitError = error => requestError(error)

    updateBookingLimit = () => Post(
      `/admin/meeting_rooms/booking_limit`,
      { limit: this.state.bookingLimit },
      this.updateBookingLimitSuccess,
      this.updateBookingLimitError,
      this.load
    )
    updateBookingLimitSuccess = payload => {
      requestSuccess('Booking limit updated successfully')
      this.getBookingLimit()
    }
    updateBookingLimitError = error => requestError(error)

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          getBookingLimit={this.getBookingLimit}
          updateBookingLimit={this.updateBookingLimit}
          onChangeBookingLimit={this.onChangeBookingLimit}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;