import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { MdFilterList } from "react-icons/md";

import AtlasDialog from "components/Dialog";
import CustomButton from "components/Button";
import CustomInfobox from "components/Infobox";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomToggle from "components/Button/toggle";
import CustomInput from "components/Input/formInput";
import AgentTreeView from "components/TreeView/agent";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import PersonalSales from "../../../Personal/MySales";
import TeamSales from "../../TeamSales";

import HierarchyHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";

import "stylesheets/containers/team/hierarchy-sales/index.scss";

const checkboxFilter = ["Projects", "Sales", "Rent"];

const headerData = [
  { label: "Agent" },
  { label: "Contact" },
  { label: "Personal Sales" },
  { label: "Group Sales" },
  { label: "" },
];

class HierarchySales extends Component {
  state = {
    showInfoBox: false,
    showAgentSales: false,
    showTeamSales: false,
    showSearchModal: false,
  };

  componentDidMount = () => {
    this.refs = {};
    this.offsetTop = document.getElementById("search-content").offsetTop;

    const url = window.location.href;

    if (url.indexOf("/admin/") !== -1) {
      const { encoded_identity = "", display_name } =
        this.props.data.profileReducer;
      this.props.onChangeHOC(`${display_name}'s Hierarchy`, "title");
      this.props.onChangeHOC(true, "adminView");
      this.props.onChangeHOC(encoded_identity, "selectedUserIdentity");
      this.props.getHierarchy(encoded_identity, "");
    } else {
      const { encoded_identity = "" } = window.location.href.includes(
        "/admin-impersonate",
      )
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.props.onChangeHOC("My Hierarchy", "title");
      this.props.onChangeHOC(encoded_identity, "selectedUserIdentity");
      this.props.getHierarchy(encoded_identity, "");
    }
  };

  componentDidUpdate = (pp) => {
    const { searchResult } = this.props;
    if (pp.searchResult !== searchResult && searchResult.length > 0) {
      this.props.refs[searchResult[0]].scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
      this.props.onChangeHOC(searchResult[0], "currentSearchId");
    }
    this.treeHeight = document.getElementById("agent-tree").offsetHeight;
  };

  onClickToggleAll = (val) => {
    const {
      hideAgentsWithoutSales,
      initialExpandedHierarchy,
      initialCollapsedHierarchy,
      expandedsWithoutSalesHierarchy,
      collapsesWithoutSalesHierarchy,
    } = this.props;

    let temp =
      val === "expand" ? initialExpandedHierarchy : initialCollapsedHierarchy;

    let tempWithoutSales =
      val === "expand"
        ? expandedsWithoutSalesHierarchy
        : collapsesWithoutSalesHierarchy;

    this.props.onChangeHOC(val, "expandMode");
    this.props.onChangeHOC(
      hideAgentsWithoutSales ? tempWithoutSales : temp,
      "hierarchyData",
    );
  };

  onClickNavigateToPersonalSales = (val) => {
    this.props.storeSelectedAgent(val);
    this.setState({ showAgentSales: true });
  };

  onClickNavigateToTeamSales = () => {
    this.setState({ showTeamSales: true });
  };

  onClickCheckbox = (val) => {
    let tmp = _.cloneDeep(this.props.filter);
    if (_.includes(this.props.filter, val)) {
      tmp = _.remove(tmp, (item) => {
        return item !== val;
      });
    } else {
      tmp.push(val);
    }
    this.props.onChangeHOC(tmp, "filter");
  };

  onClickScroll = (id) => {
    this.props.onChangeHOC(id, "currentSearchId");
    let tempUpperLevels = this.props.findUpperLevels(id);

    this.props.toggleExpand(tempUpperLevels, true, () => {
      this.props.refs[id].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    });
  };

  renderCardContainer = () => {
    const { searchResult, scrollTop, searchIndex } = this.props;
    let refsLength = Object.values(this.props.refs || {}).length;
    let tempFlag = document.documentElement.clientHeight - 62 - 110;
    // 62 is the top nav bar 110 is the height of search form

    return (
      <>
        <div
          id={"search-content"}
          className="at-hierarchy__filter-cont mt-1"
          style={{
            position:
              this.treeHeight > tempFlag && scrollTop >= this.offsetTop
                ? "fixed"
                : "static",
            top: 62,
          }}
        >
          <div
            className="d-flex align-items-center fw-600 fs-3 mb-10 grid_gap-2"
            style={{ color: "#111827" }}
          >
            <div
              className="at-badge-icon at-badge-icon-warning"
              style={{ width: 36, height: 36 }}
            >
              <AtlasIcon svgHref={"atlas-profile2user"} />
            </div>
            {refsLength} Members
          </div>
          <div className="at-hierarchy__filter">
            <CustomInput
              type={"search"}
              containerStyle={{ height: 40, gridColumnEnd: "span 6" }}
              placeholder="Agent's name"
              value={this.props.searchAgent}
              onChangeValue={(val) => this.props.onChangeValueSearch(val)}
            />
            <CustomButton
              disabled={searchResult.length < 1}
              tooltip={true}
              tooltipPosition={"top"}
              tooltipChildren={`Previous`}
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => {
                let temp =
                  searchIndex < 1 ? searchResult.length - 1 : searchIndex - 1;
                this.props.onChangeHOC(temp, "searchIndex");
                this.onClickScroll(searchResult[temp]);
              }}
              children={
                <AtlasIcon
                  svgHref={"atlas-arrow-left-1-linear"}
                  style={{ width: 16, height: 16 }}
                />
              }
            />
            <CustomButton
              disabled={searchResult.length < 1}
              tooltip={true}
              tooltipPosition={"top"}
              tooltipChildren={`Next`}
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => {
                let temp =
                  searchIndex === searchResult.length - 1 ? 0 : searchIndex + 1;
                this.props.onChangeHOC(temp, "searchIndex");
                this.onClickScroll(searchResult[temp]);
              }}
              children={
                <AtlasIcon
                  svgHref={"atlas-arrow-right-1-linear"}
                  style={{ width: 16, height: 16 }}
                />
              }
            />
            <CustomButton
              tooltip={true}
              tooltipPosition={"top"}
              tooltipChildren={`Expand`}
              className={"btn-new btn-new--secondary"}
              containerStyle={{ display: "flex", gridColumnEnd: "span 3" }}
              onClick={() => this.onClickToggleAll("expand")}
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-expand"}
                    style={{ width: 16, height: 16, fill: "white" }}
                  />
                  <span className="ml-2">Expand</span>
                </>
              }
            />
            <CustomButton
              tooltip={true}
              tooltipPosition={"top"}
              tooltipChildren={`Collapse`}
              className={"btn-new btn-new--secondary"}
              containerStyle={{ display: "flex", gridColumnEnd: "span 3" }}
              onClick={() => this.onClickToggleAll("collapse")}
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-collapse"}
                    style={{ width: 16, height: 16, fill: "white" }}
                  />
                  <span className="ml-2">Collapse</span>
                </>
              }
            />
          </div>
        </div>
        <div className="at-hierarchy__head">
          {headerData.map((item, index) => {
            return (
              <div
                key={`header-${index}-${item.label}`}
                className={`at-hierarchy-row__item`}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        <div
          id={"agent-tree"}
          className="at-card-container at-hierarchy__container"
        >
          <AgentTreeView
            filter={this.props.filter}
            currentSearchId={this.props.currentSearchId}
            data={this.props.hierarchyData}
            refs={this.props.refs}
            searchResult={searchResult}
            toggleExpand={(val) => this.props.toggleExpand([val])}
            onClickPersonal={
              this.props.adminView
                ? () => {}
                : (val) => this.onClickNavigateToPersonalSales(val)
            }
            onClickTeam={
              this.props.adminView
                ? () => {}
                : (val) => this.onClickNavigateToTeamSales(val)
            }
          />
        </div>
      </>
    );
  };

  renderSearchForm = () => {
    const { encoded_identity = "" } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <div className="mb-3">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() =>
              this.setState({ showSearchModal: !this.state.showSearchModal })
            }
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          <div className="at-table__search-cont at-desktop-view__controller ">
            <TableSearchParams
              mode={"desktop"}
              showResetButton={true}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeHOC(val, "searchParams")
              }
              getListAPI={(page, search) =>
                this.props.getHierarchy(encoded_identity, search)
              }
            />
          </div>
          {this.state.showSearchModal && (
            <TableSearchParams
              mode={"mobile"}
              searchParams={this.props.searchParams}
              showResetButton={true}
              onToggleSearchModal={() =>
                this.setState({ showSearchModal: !this.state.showSearchModal })
              }
              onChangeSearchParams={(val) =>
                this.props.onChangeHOC(val, "searchParams")
              }
              getListAPI={(page, search) =>
                this.props.getHierarchy(encoded_identity, search)
              }
            />
          )}
        </div>
      </>
    );
  };

  renderAgentSearchTips = () => {
    return (
      <>
        <span className="fw-500">
          How to navigate agent management and sales summaries
        </span>
        <p style={{ lineHeight: "150%", marginTop: 10 }}>
          Control your search according to date range, market type Project,
          Sales and/or Rent and agent’s name. The number beside an agent’s name
          indicates the number of sub-agent they manage. <br />
          For a quick glance on their sales summary hover onto the agent’s name
          to view. Or you can click on the Personal or Group sales icon to check
          in details.
        </p>
      </>
    );
  };

  renderCardContent = () => (
    <>
      {this.renderSearchForm()}
      <div className="at-hierarchy__control-panel">
        <div className="at-form-input__title mr-3" style={{ color: "#1F2937" }}>
          <CustomToggle
            className={"at-toggle__small"}
            currentState={this.props.hideAgentsWithoutSales}
            onToggleButton={() =>
              this.props.onChangeCheckboxHideAgentsWithoutSales(
                !this.props.hideAgentsWithoutSales,
              )
            }
          />
          Show agents with Sales only
        </div>
        <div className="d-flex at-form-input__title">
          {checkboxFilter.map((item, index) => (
            <CustomCheckbox
              key={index}
              labelStyle={{ marginRight: 10 }}
              contentStyle={{ fontSize: 14, fontWeight: 500 }}
              labelClassname={"align-items-center"}
              checked={_.includes(this.props.filter, item)}
              content={item}
              onChangeCheckboxValue={() => this.onClickCheckbox(item)}
            />
          ))}
        </div>
      </div>
      {this.renderCardContainer()}
    </>
  );

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        {this.props.adminView && (
          <ModuleHeader
            title={this.props.title}
            moduleIcon={"icon-hierarchy"}
            description={`Here is a summary that shows your entire team structure. The list helps you to recognise your team’s recruitment 
                growth and summarise sales contribution by each team and individual member.`}
            showPABadge={role === "Personal Assistant"}
            infoBoxInactiveContent={
              "How to navigate agent management and sales summaries"
            }
            infoBoxContent={this.renderAgentSearchTips}
            backButton={{
              onShow: true,
              onClick: () => this.props.clickBack(),
            }}
          />
        )}
        <div className="at-form__content">
          <p className="fs-2">
            Here is a summary that shows your entire team structure. <br />
            The list helps you to recognise your team’s recruitment growth and
            summarise sales contribution by each team and individual member.
          </p>
          <CustomInfobox
            expand={this.state.showInfoBox}
            containerStyle={{ marginTop: 16 }}
            inactiveContent={
              "How to navigate agent management and sales summaries"
            }
            activeContent={this.renderAgentSearchTips()}
            onClickExpandInfo={(val) => this.setState({ showInfoBox: val })}
          />
        </div>
        {this.renderCardContent()}
        <AtlasDialog open={this.state.showTeamSales}>
          <TeamSales onClose={() => this.setState({ showTeamSales: false })} />
        </AtlasDialog>
        {this.state.showAgentSales && (
          <PersonalSales
            onClose={() => this.setState({ showAgentSales: false })}
          />
        )}
        {this.props.onLoadHierarchy && <LoadingModal />}
      </>
    );
  };
}

export default compose(HierarchyHOC, VersionCheckHOC)(HierarchySales);
