import { useEffect } from 'react';
import { FaFileDownload } from "react-icons/fa";
import { IoIosRefresh, IoMdAdd } from 'react-icons/io';
import { compose } from 'redux';

import AlertBox from 'components/AlertBox';
import CustomButton from 'components/Button';
import CustomCard from 'components/Card';
import LoadingModal from 'components/LoadingModal';
import RejectConfirmationModal from 'components/Modal/delete';
import ModuleHeader from 'components/ModuleHeader';
import PaginatedTable from 'components/NewPaginatedTable';
import ShareModal from 'components/Share';
import VideoPlayer from 'components/VideoPlayer';
import CreateVideoRequest from './Create';

import SubsalesStatusHOC from 'actions/subsalesStatus';
import withVideoCreator from './actions';

import { adminColumnData, columnData } from './assets';

import { initGA } from 'utils/ga';
import permissionsChecker from 'utils/permissionsChecker';

import './index.scss';

const VideoCreator = props => {
  const {
    onLoadVideoCreator,
    data,
    location,
    personalisedVideos,
    selectedVideo,
    adminSearchParams,
    searchParams,
    personalisedVideoPages,
    whitelisted,
    youtubeLink,
    showCreateModal,
    showShareModal,
    showVideoPlayer,
    showDeleteConfirmation,

    onChangeVideoCreatorHOC,
    getCommRate,
    getCountryDictionary,
    getAdminPersonalisedVideos,
    getPersonalisedVideos,
    getVideoTemplates,
    getYoutubeLink,
    updateDownloadCount,
    downloadVideo,
    deleteAdminPersonalisedVideo,
    deletePersonalisedVideo
  } = props;

  const adminView = location.pathname.includes('/admin/');
  const { role, status_id } = location.pathname.includes('/admin-impersonate')
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const { can_create, can_update, can_destroy } = permissionsChecker(
    'Video Creator',
    data
  );
  const status_permissions = status_id !== 2 || whitelisted;

  useEffect(() => {
    initGA('/dashboard/video-creator');
    getCountryDictionary();
    getCommRate();
    getVideoList();
  }, []);

  const getVideoList = refresh => {
    const { currentPage, searchParams } = data.lastViewReducer.lastView;

    if (adminView) {
      refresh
        ? getAdminPersonalisedVideos(currentPage, searchParams)
        : getAdminPersonalisedVideos();
    } else {
      refresh
        ? getPersonalisedVideos(currentPage, searchParams)
        : getPersonalisedVideos();
    }
  };

  const renderCardContent = () => {
    return (
      <PaginatedTable
        rowData={personalisedVideos.data}
        meta={personalisedVideos.meta}
        columns={adminView ? adminColumnData(props) : columnData(props)}
        searchParams={adminView ? adminSearchParams : searchParams}
        onChangeSearchParams={val =>
          adminView
            ? onChangeVideoCreatorHOC('adminSearchParams', val)
            : onChangeVideoCreatorHOC('searchParams', val)
        }
        getListAPI={
          adminView ? getAdminPersonalisedVideos : getPersonalisedVideos
        }
        totalPages={personalisedVideoPages}
        actionColumnContent={
          can_update || can_destroy
            ? [
                {
                  name: 'share',
                  onClick: async rowData => {
                    await onChangeVideoCreatorHOC('selectedVideo', rowData);
                    await getYoutubeLink(rowData.id);
                    await onChangeVideoCreatorHOC('showShareModal', true);
                  },
                  disabled: ({ status_id }) => status_id === 1
                },
                {
                  name: 'delete',
                  color: '#F04438',
                  onClick: async rowData => {
                    await onChangeVideoCreatorHOC('selectedVideo', rowData);
                    await onChangeVideoCreatorHOC(
                      'showDeleteConfirmation',
                      true
                    );
                  },
                  disabled: ({ status_id }) =>
                    adminView ? status_id !== 1 : status_id === 1 && can_destroy
                }
              ]
            : []
        }
      />
    );
  };

  const renderDeleteConfirmation = () => {
    return (
      <RejectConfirmationModal
        open={showDeleteConfirmation}
        message='Are you sure to delete this video?'
        title='Delete Personalised Video'
        positiveAction={() => {
          onChangeVideoCreatorHOC('showDeleteConfirmation', false);

          if (adminView) {
            deleteAdminPersonalisedVideo(selectedVideo.id);
          } else {
            deletePersonalisedVideo(selectedVideo.id);
          }
        }}
        negativeAction={() =>
          onChangeVideoCreatorHOC('showDeleteConfirmation', false)
        }
      />
    );
  };

  return (
    <>
      <ModuleHeader
        title={adminView ? 'Personalised Videos' : 'Video Creator'}
        atlasIcon='atlas-video-play'
        showPABadge={role === 'Personal Assistant'}
        description={
          !adminView && (
            <>
              Create and share personalised videos of projects with your photo
              and contact details automatically added by Atlas. You can then
              share the videos in your messaging apps or social media marketing
              platforms to attract potential clients. <br />
              Click on the Add icon to start creating your video. <br />
              Once your video has been generated and the status shows ready, use
              the Share icon to download or share the video.
            </>
          )
        }
        renderAlertContent={
          status_id === 2 && (
            <AlertBox
              mode='danger'
              description='Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions.'
            />
          )
        }
        actionButton={[
          {
            label: 'New Video',
            onShow: !adminView && can_create && status_permissions,
            className: 'btn-new btn-new--primary',
            icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
            onClick: () => {
              onChangeVideoCreatorHOC('showCreateModal', true);
              getVideoTemplates();
            },
            hideTooltip: true
          },
          {
            label: 'Refresh',
            onShow: true,
            className: `btn-new btn-new--${adminView ? 'primary' : 'secondary'}`,
            icon: <IoIosRefresh style={{ height: 18, width: 18 }} />,
            onClick: () => getVideoList(true),
            hideTooltip: true
          }
        ]}
      />
      <CustomCard cardContent={renderCardContent()} />
      {showCreateModal && <CreateVideoRequest {...props} />}
      {showShareModal && (
        <ShareModal
          title='Share Video'
          responsizeSive=''
          dialogContentClassName='at-video-creator__dialog-content mb-0'
          youtubeLink={youtubeLink}
          mediaURL={selectedVideo.url}
          shareLink={selectedVideo.shared_link}
          showElement={[
            'Whatsapp',
            'Telegram',
            'Facebook',
            'Download',
            'Copy Link',
            'Youtube'
          ]}
          customDownloadButton={selectedVideo.video_template?.version === 2 && (
            <CustomButton
              containerClass='at-share-modal__button mb-2'
              containerStyle={{ backgroundColor: '#FF8E00' }}
              tooltip
              tooltipChildren="Download Video"
              tooltipPosition="top"
              tooltipID="download-media"
              onClick={() => downloadVideo(selectedVideo, adminView)}
            >
              <FaFileDownload style={{ width: 25, height: 25, color: "white" }} />
            </CustomButton>
          )}
          onClickDownload={() => !adminView && updateDownloadCount(selectedVideo.id)}
          onClickClose={() => onChangeVideoCreatorHOC('showShareModal', false)}
        />
      )}
      {showVideoPlayer && (
        <VideoPlayer
          videoURL={selectedVideo.url}
          onClickClose={() => onChangeVideoCreatorHOC('showVideoPlayer', false)}
        />
      )}
      {showDeleteConfirmation && renderDeleteConfirmation()}
      {onLoadVideoCreator && <LoadingModal />}
    </>
  );
};

export default compose(SubsalesStatusHOC, withVideoCreator)(VideoCreator);
