import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const AdminActivitiesHOC = (WrappedComponent) => {
  class HOCWrappedComponent extends Component {
    state = {
      loading: false,
      impersonateActivity: {
        data: [],
        meta: {},
      },
      selectedData: {},
      showDetailsModal: false,
    };

    load = (param) => this.setState({ loading: param });

    onAdminActivitiesState = (data) => this.setState(data);

    getImpersonateActivity = (page, search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/impersonate_activity?${tmp}page=${page}`,
        this.getImpersonateActivitySuccess,
        this.getImpersonateActivityError,
        this.load
      );
    };
    getImpersonateActivitySuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        submission_date: Moment(item.submission_date).format("DD MMM YYYY"),
      }));
      this.setState({
        impersonateActivity: {
          meta: payload.meta,
          data: tempData,
        },
        requestsPages: tmpTotalPages,
      });
    };
    getImpersonateActivityError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadAnalysis={this.state.loading}
            selectedData={this.state.selectedData}
            showDetailsModal={this.state.showDetailsModal}
            getImpersonateActivity={this.getImpersonateActivity}
            onAdminActivitiesState={this.onAdminActivitiesState}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(HOCWrappedComponent);
};

export default AdminActivitiesHOC;
