import React, { Component } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import SVGIcon from "components/Icon";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateRequest from "./Create";
import UpdateRequest from "./Update";

import RequestHOC from "../../../actions/request";
import { StatusColor } from "../../../assets";
import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";

const columnData = [
  {
    header: "Request Date",
    accessor: "submission_date",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
  {
    header: "Assigned To",
    accessor: `assigned_admin`,
  },
];

class Request extends Component {
  componentDidMount = () =>
    this.props.getRequests(this.props.selectedSale.id, 1, "");

  renderCardContent = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    return (
      <>
        <div className="d-flex align-items-center mb-10">
          <h4
            className={`at-card__title mr-10`}
            style={{ maxWidth: "fit-content" }}
          >
            Requests
          </h4>
          {role === "Personal Assistant" && (
            <Tooltip
              placement={"bottom"}
              title={`You are acting on behalf of your superior in this section.`}
            >
              <div className={"mr-10"}>
                <SVGIcon
                  style={{ fill: "#f18e03", width: 26, height: 30 }}
                  svgHref={"icon-superior"}
                />
              </div>
            </Tooltip>
          )}
          {[`3`, `6`].indexOf(this.props.selectedSale.status_id) > -1 && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              onClick={() =>
                this.props.onChangeRequestHOC("showCreateRequest", true)
              }
              children={
                <>
                  <IoMdAdd
                    style={{ width: 18, height: 18, color: "#FFFFFF" }}
                  />
                  New Request
                </>
              }
            />
          )}
        </div>
        <p className={"at-card__description mt-10"}>
          You only can create request after admin approved the project sale
        </p>
        <hr />
        <div className="at-card-container">
          <AtPaginatedTable
            rowData={this.props.requests.data || []}
            meta={this.props.requests.meta}
            showCloseButton={false}
            showCreateButton={false}
            columns={columnData}
            actionColumnContent={[
              {
                name: "details",
                onClick: (rowData) =>
                  this.props.getSelectedRequest(
                    this.props.selectedSale.id,
                    rowData.id,
                  ),
              },
            ]}
            searchParams={[]}
            getListAPI={(page, search) =>
              this.props.getRequests(this.props.selectedSale.id, page, search)
            }
            totalPages={this.props.requestsPages}
          />
        </div>
      </>
    );
  };

  render = () => {
    const { onLoadRequests } = this.props;
    const { can_update, can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    return (
      <>
        {this.renderCardContent()}
        {this.props.showCreateRequest && <CreateRequest {...this.props} />}
        {this.props.showUpdateRequest && (
          <UpdateRequest
            {...this.props}
            can_update={can_update}
            can_create={can_create}
            role={role}
          />
        )}
        {(onLoadRequests || this.props.onLoadPreview) && <LoadingModal />}
      </>
    );
  };
}

export default RequestHOC(Request);
