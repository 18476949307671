export const replaceJSX = (str, replacement) => {
  const regex = /{{(.*?)}}/g;
  const parts = str.split(regex);
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      const key = part.trim();
      return replacement[key] !== undefined ? replacement[key] : `{{${key}}}`;
    }
    return part;
  });
};