import { useRef } from "react";

import BackToTop from "components/Button/backToTop";
import AtlasCloseButton from "components/Button/prev";
import CustomCard from "components/Card";
import DetailsForm from "../components/DetailsForm";

import withCreateProject from "./actions";

const CreateProject = ({
  data,
  onLoadProjects,
  onLoadCreate,
  newProject,
  onChangeCreateHOC,
  onChangeProjectManagementHOC,
  createProject,
}) => {
  const cardRef = useRef(null);

  const handleChange = (context, val) => {
    onChangeCreateHOC({ ...newProject, [context]: val }, "newProject");
  };

  return (
    <CustomCard
      ref={cardRef}
      containerStyle={{ scrollBehavior: "smooth" }}
      cardContent={
        <div className={"container"}>
          <div className={"at-card__header"}>
            <AtlasCloseButton
              onClick={() =>
                onChangeProjectManagementHOC(false, "showNewProjectDialog")
              }
            />
            <h4 className={"at-card__title"}>New Project</h4>
          </div>
          <DetailsForm
            type={"create"}
            data={data}
            onLoadProjects={onLoadProjects}
            onLoadCreate={onLoadCreate}
            selectedProject={newProject}
            onClose={() =>
              onChangeProjectManagementHOC(false, "showNewProjectDialog")
            }
            onChange={handleChange}
            submitAction={createProject}
          />
          <BackToTop ref={cardRef} />
        </div>
      }
    />
  );
};

export default withCreateProject(CreateProject);
