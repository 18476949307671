import React, { Component } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import ReaRenewalDetails from "./Details";
import ReaRenewalForm from "./form";

import ReaRenewalManagementHOC from "./actions";
import { columnData, settingColumnData } from "./assets";

import "./index.scss";
import RenewalSettings from "./renewalSetting";

class ReaRenewalManagement extends Component {
  componentDidMount = () => {
    this.props.getReaRenewals(1, "");
    this.props.getRenewalSettings(1, "");
  };

  renderCardContent = () => {
    const { role } = window.location.href.includes( '/admin-impersonate' ) 
      ? this.props.data.currentSignInProfileReducer 
      : this.props.data.profileReducer
    
    return  (
      <div className='at-rea-mngm__table'>
        {
          role === 'Super Admin' && (
            <Tab
              sections={ [ 'License Renewal', 'Settings' ] }
              selectedSection={ this.props.selectedSection }
              onSelectSection={ val => this.props.onChangeRenewalManagementHOC( val, 'selectedSection' ) } />
          )
        }
        {
          this.props.selectedSection === 'Settings' && (
            <>
              <AlertBox
                mode={ 'info' }
                description={ 'Create a New REA Renewal Setting for every renewal year to meet the minimum CDP requirements mandated by the Board of Valuers for Senior REAs' }
                disabledClose={ true } />
              <CustomTable
                columns={ settingColumnData }
                rowData={ this.props.renewalSettings.data || [] }
                meta={ this.props.renewalSettings.meta || [] }
                searchParams={ this.props.searchParams }
                renderCheckBoxActions={ () => (
                  <div className='at-table__head-title'>
                    <div className='at-badge-icon at-badge-icon-warning mr-0' >
                      <AtlasIcon svgHref={ 'atlas-setting-2' } />
                    </div>
                    <h4>REA Renewal Setting</h4>
                    <CustomButton
                      className={ 'btn-new btn-new--primary' }
                      style={{ padding: '6px 10px' }}
                      children={
                        <>
                          <AtlasIcon svgHref={ 'atlas-add-linear' } style={{ width: 20, height: 20, fill: 'white', marginRight: 4 }} />
                          New
                        </>
                      }
                      onClick={ () => this.props.onChangeRenewalManagementHOC( true, 'showCreateRenewalSetting' ) } />
                  </div>
                )}
                actionColumnContent={[
                  { 
                    name: 'edit', 
                    onClick: rowData => Promise.all([
                      this.props.onChangeRenewalManagementHOC( rowData, 'selectedRenewalSetting' )
                    ]).then( () => this.props.onChangeRenewalManagementHOC( true, 'showUpdateRenewalSetting' ) )
                  },
                  { 
                    name: 'delete', 
                    color: '#F04438',
                    onClick: rowData => Promise.all([
                      this.props.onChangeRenewalManagementHOC( rowData, 'selectedRenewalSetting' )
                    ]).then( () => this.props.onChangeRenewalManagementHOC( true, 'showDeleteRenewalSetting' ) )
                  }
                ]}
                totalPages={ this.props.renewalSettingsPage || [] }
                getListAPI={ this.props.getRenewalSettings }
              />
            </>
          )
        }
        {this.props.selectedSection === "License Renewal" && (
          <CustomTable
            enableSort={true}
            columns={columnData}
            rowData={this.props.reaRenewal.data || []}
            meta={this.props.reaRenewal.meta || []}
            searchParams={this.props.searchParams}
            onChangeSearchParams={(val) =>
              this.props.onChangeRenewalManagementHOC(val, "searchParams")
            }
            actionColumnContent={[
              {
                name: "details",
                onClick: (rowData) =>
                  this.props.getSelectedReaRenewal(
                    rowData.id,
                    "",
                    "showRenewalDetails",
                  ),
              },
              {
                name: "edit",
                onClick: (rowData) =>
                  this.props.getSelectedReaRenewal(
                    rowData.id,
                    "",
                    "showUpdateRenewal",
                  ),
              },
            ]}
            totalPages={this.props.reaRenewalPage || []}
            getListAPI={this.props.getReaRenewals}
          />
        )}
      </div>
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"License Renewal Management"}
          atlasIcon={"atlas-personalcard"}
          actionButton={[
            {
              label: "New License Renewal",
              onShow: this.props.selectedSection === "License Renewal",
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeRenewalManagementHOC(
                  true,
                  "showCreateRenewal",
                ),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showRenewalDetails && (
          <ReaRenewalDetails
            selectedRenewal={this.props.selectedRenewal}
            getSelectedRenewal={this.props.getSelectedRenewal}
            getSelectedReaRenewal={this.props.getSelectedReaRenewal}
            onChangeRenewalManagementHOC={
              this.props.onChangeRenewalManagementHOC
            }
          />
        )}
        {this.props.showCreateRenewal && (
          <ReaRenewalForm
            mode={"Create"}
            submitData={(dataToSubmit) =>
              this.props.createReaRenewal(dataToSubmit)
            }
            onClose={() =>
              this.props.onChangeRenewalManagementHOC(
                false,
                "showCreateRenewal",
              )
            }
          />
        )}
        {this.props.showUpdateRenewal && (
          <ReaRenewalForm
            mode={"Update"}
            selectedRenewal={this.props.selectedRenewal}
            submitData={(dataToSubmit) =>
              this.props.updateReaRenewal(dataToSubmit)
            }
            onClose={() =>
              this.props.onChangeRenewalManagementHOC(
                false,
                "showUpdateRenewal",
              )
            }
          />
        )}
        {this.props.showCreateRenewalSetting && (
          <RenewalSettings
            mode={"Create"}
            submitData={(dataToSubmit) =>
              this.props.createRenewalSettings(dataToSubmit)
            }
            onClose={() =>
              this.props.onChangeRenewalManagementHOC(
                false,
                "showCreateRenewalSetting",
              )
            }
          />
        )}
        {this.props.showUpdateRenewalSetting && (
          <RenewalSettings
            mode={"Update"}
            selectedRenewalSetting={this.props.selectedRenewalSetting}
            submitData={(dataToSubmit) =>
              this.props.updateRenewalSettings(dataToSubmit)
            }
            onClose={() =>
              this.props.onChangeRenewalManagementHOC(
                false,
                "showUpdateRenewalSetting",
              )
            }
          />
        )}
        <ConfirmationModal
          mode={"alert"}
          open={this.props.showDeleteRenewalSetting}
          title={"Delete Renewal Setting"}
          message={"Are you sure you want to delete this renewal setting?"}
          showLoading={this.props.onLoadReaRenewal}
          positiveAction={() =>
            this.props.deleteRenewalSettings(
              this.props.selectedRenewalSetting.id,
            )
          }
          negativeAction={() =>
            this.props.onChangeRenewalManagementHOC(
              false,
              "showDeleteRenewalSetting",
            )
          }
        />
        {this.props.onLoadReaRenewal && <LoadingModal />}
      </>
    );
  };
}

export default ReaRenewalManagementHOC(ReaRenewalManagement);
