import { getItem } from "utils/tokenStore";
import getDomainURL from "utils/getDomainURL";
import { requestError } from "utils/requestHandler";

class UploadAdapter {
  constructor(props) {
    this.loader = props;
    this.url = `${getDomainURL()}/media_stores`;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    const token =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
        : getItem("IQI_ATLAS_JWT_TOKEN");
    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Authorization", `JWT ${token}`);
  }

  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      if ([404, 422].indexOf(xhr.status) > 0) {
        if (response.message) {
          requestError(response.message);
        }

        if (!response || response.error) {
          return reject(
            response && response.error
              ? response.error.message
              : genericErrorText,
          );
        }
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      if (xhr.status === 200) {
        resolve({
          default: response.original,
        });
      }
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          loader.uploadTotal = e.total;
          loader.uploaded = e.loaded;
        }
      });
    }
  }

  _sendRequest() {
    const data = new FormData();
    this.loader.file.then((result) => {
      data.append("image_file_name", result.name);
      data.append("image_content_type", result.type);
      data.append("image", result);
      this.xhr.send(data);
    });
  }
}

export default UploadAdapter;
