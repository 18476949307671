import React from "react";
import AtlasIcon from "components/Icon/atlasIcon";

import StatisticsTable from "../StatsTable";

const headerData = [
  { label: "Property Name", value: "estate_name" },
  {
    label: "Total Interested Count",
    value: "mark_total",
    className: "at-column-responsive_center at-table-row-cont__divider",
  },
  {
    label: "Total Enquiries",
    value: "enquiry_total",
    className: "at-column-responsive_center at-table-row-cont__divider",
  },
];

const CardData = [
  {
    unit: "Views",
    color: "#06B6D4",
    icon: "eye",
    renderValue: ({ selectedPresentationStat }) =>
      selectedPresentationStat?.viewed_count || "0",
  },
  {
    unit: "Clients",
    color: "#EE6D4D",
    icon: "frame",
    renderValue: ({ selectedPresentationStat }) =>
      selectedPresentationStat?.clients_count || "0",
  },
  {
    unit: "Leads",
    color: "#3F82F4",
    icon: "profile-remove",
    renderValue: ({ selectedPresentationStat }) =>
      selectedPresentationStat?.leads_count || "0",
  },
];

const PresentationStats = ({ selectedPresentationStat }) => {
  return (
    <>
      <h4 className="fs-4 fw-600">Presentation Statistics</h4>
      <p className="fs-2 my-3">
        Understanding the usage pattern of your presentations will help you to
        understand your client better. This page shows the total views for this
        presentation as well as the total number of times each property in the
        presentation was either added a favourite or an enquiry sent.
      </p>
      <div className="at-presentation-statistic_showcase">
        {CardData.map((item, index) => (
          <div
            key={index}
            style={{ padding: 12, marginBottom: 12 }}
            className="at-form__content d-flex align-items-center w-100"
          >
            <div
              style={{
                backgroundColor: item.color,
                width: "3.5rem",
                height: "3.5rem",
                borderRadius: "1rem",
                padding: "0.6rem",
              }}
            >
              <AtlasIcon
                svgHref={`atlas-${item.icon}`}
                style={{ fill: "white" }}
              />
            </div>
            <div className="ml-3">
              <label>{item.unit}</label>
              <p>{item.renderValue({ selectedPresentationStat })}</p>
            </div>
          </div>
        ))}
      </div>
      <StatisticsTable
        headerData={headerData}
        actionColumn={false}
        actionColumnContent={[]}
        rowData={selectedPresentationStat.estates || []}
      />
    </>
  );
};

export default PresentationStats;
