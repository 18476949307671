import { IQI_DRIVE_GET_DIRECTORY, STORE_IQI_DRIVE_DATA } from "actions/type";
import Moment from "moment";
import { sortBy } from "lodash";

let initialState = {
  directory: {},
  data: {},
};

export default function iqiDriveReducer(state = initialState, action) {
  switch (action.type) {
    case IQI_DRIVE_GET_DIRECTORY:
      const tmpPayload = action && action.payload;

      let tmpDirectoryContent =
        tmpPayload &&
        action.payload.contents.map((item) => {
          let tmpDisplayName = item.display_name;
          let indexOfDot = tmpDisplayName.lastIndexOf(".");
          let tmpFileType;
          if (indexOfDot !== -1) {
            tmpFileType = tmpDisplayName.substr(
              indexOfDot,
              tmpDisplayName.length - 1,
            );
          } else {
            tmpFileType = "";
          }

          return {
            ...item,
            last_modified: `${Moment(item.last_modified).format("DD MMM YYYY")}`,
            last_modified_unix: Moment(item.last_modified).unix(),
            file_type: tmpFileType,
          };
        });
      tmpDirectoryContent = sortBy(tmpDirectoryContent, [
        "display_name",
        "file_type",
        "resource_type",
      ]);
      return {
        ...state,
        directory: {
          ...tmpPayload,
          contents: tmpDirectoryContent,
        },
      };

    case STORE_IQI_DRIVE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
