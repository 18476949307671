import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";

export const featureProperty = [
  {
    label: "Beds",
    value: "bedrooms_label",
    icon: "atlas-bedroom",
  },
  {
    label: "Baths",
    value: "bathrooms_label",
    icon: "atlas-bathroom",
  },
  {
    label: "Car Parks",
    value: "carparks_label",
    icon: "atlas-car",
  },
];

export const detailsSection = [
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Available Date",
    value: "formatted_available_date",
  },
  {
    label: "Updated On",
    value: "updated_on",
  },
  {
    label: "Type",
    value: "type",
  },
  {
    label: "Listing Category",
    value: "category",
  },
  {
    label: "Property Type",
    value: "property",
  },
];

export const publishPlatformLib = [
  {
    title: "IQI Website",
    tooltipTitle: "Click to view this listing on www.iqiglobal.com.",
    linkSrc: "exported_iqi_link",
    published: "published_to_iqi",
    exported: "",
    logoSrc: iqiLogo,
  },
  {
    title: "Juwai Website",
    tooltipTitle: "Click to view this listing on www.juwai.com.",
    linkSrc: "juwai_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    logoSrc: juwaiLogo,
  },
  {
    title: "Juwai Asia",
    tooltipTitle: "Click to view this listing on www.juwai.asia.",
    linkSrc: "exported_juwai_asia_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    logoSrc: juwaiAsiaLogo,
  },
];
