import React, { Component } from "react";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";
import { MdFilterList } from "react-icons/md";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import CustomButton from "components/Button";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";
import AlertBox from "components/AlertBox";
import TableSearchParams from "../NewPaginatedTable/SearchParams";
import TableContent from "./Content";

import { getColorBadge } from "dictionary/badgeColor";
import defaultAvatar from "assets/images/av-male.jpg";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";
import "./index.scss";

const statusBadge = [
  {
    id: 1,
    value: "ACTIVE",
    colorName: "Green",
  },
  {
    id: 2,
    value: "DELETED",
    colorName: "Red",
  },
  {
    id: 3,
    value: "TERMINATED",
    colorName: "Grey",
  },
  {
    id: 4,
    value: "RESIGNED",
    colorName: "Blue",
  },
  {
    id: 5,
    value: "INACTIVE",
    colorName: "Grey",
  },
  {
    id: 6,
    value: "UNDER OBSERVATION",
    colorName: "Yellow",
  },
];

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchContent: false,
  };

  renderTableContent = () => {
    const isSearching =
      this.props.searchParams &&
      this.props.searchParams.some((item) => item.param);

    if (this.props.rowData && this.props.rowData.length > 0) {
      return (
        <div className="at-profile__card-grid-controller">
          {this.props.rowData.map((item) => {
            const { colorName = "" } =
              _.find(
                statusBadge,
                (i) => i.value.toLowerCase() === item.status.toLowerCase(),
              ) || {};

            return (
              <div className="d-flex justify-content-center align-items-center at-profile__card__table-cont">
                {item && item.status && (
                  <span
                    style={{ ...getColorBadge(colorName) }}
                    className={`at-profile__card__table-status`}
                  >
                    {item.status}
                  </span>
                )}
                {item && item.total_likes && (
                  <span className="at-profile__card__table-like">
                    <AtlasIcon svgHref={"atlas-like-1"} />
                    {item.total_likes}
                  </span>
                )}
                <div className="at-profile-closer-image">
                  {item && item.is_project_closer && (
                    <span className="project-closer-badge">Project Closer</span>
                  )}
                  <img
                    className="at-profile__card__table-image"
                    src={item.avatar_url || defaultAvatar}
                    onError={(e) => {
                      if (e.target) {
                        e.target.src = defaultAvatar;
                      }
                    }}
                  />
                </div>
                <TableContent
                  key={item.id}
                  centerAlignColumns={this.props.centerAlignColumns}
                  actionColumnContent={this.props.actionColumnContent}
                  disableRowAction={this.props.disableRowAction}
                  headerData={this.props.headerData}
                  rowItem={item}
                  actionColumn={this.props.actionColumn}
                  onClickPaymentProof={this.props.onClickPaymentProof}
                  onClickDetails={this.props.onClickDetails}
                  onClickDelete={this.props.onClickDelete}
                  onClickUnlock={this.props.onClickUnlock}
                  onClickDuplicate={this.props.onClickDuplicate}
                  onClickSendRequest={this.props.onClickSendRequest}
                  onClickEdit={this.props.onClickEdit}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <EmptyState
          title={"No result found"}
          renderIcon={<AtlasIcon svgHref="atlas-profile2user" />}
        />
      );
    }
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    let totalPages = [];
    if (this.props.meta && this.props.meta.last) {
      for (let pg = 0; pg < this.props.meta.last; pg++) {
        totalPages.push(pg);
      }
    }

    return (
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {totalPages &&
          totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams &&
                      this.props.searchParams.map((item) => {
                        tmp =
                          item.param !== ""
                            ? tmp + `q[${item.value}]=${item.param}&`
                            : tmp;
                      });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.last) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.last) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.last) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.last, tmp);
            this.setState({ interval: this.props.meta.last });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    return (
      <>
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="at-profile__card__table__options">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() =>
                this.setState((prevState) => ({
                  showSearchContent: !prevState.showSearchContent,
                }))
              }
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller ">
              <TableSearchParams
                mode={"desktop"}
                searchParams={this.props.searchParams}
                onChangeSearchParams={this.props.onChangeSearchParams}
                getListAPI={this.props.getListAPI}
              />
            </div>
          </div>
        )}
        {this.props.display_message && (
          <AlertBox
            mode={"info"}
            description={<span> {this.props.display_message} </span>}
          />
        )}
        {this.props.renderAdditionalContent &&
          this.props.renderAdditionalContent}
        <div className="at-profile__card__table at-profile__card__table--set">
          {this.renderTableContent()}
        </div>
        {this.props.meta && this.props.meta.count > 0 && (
          <div className="at-pagination__cont">
            {this.renderTablePagination()}
            {this.renderPaginationText()}
          </div>
        )}
        {this.state.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            searchParams={this.props.searchParams}
            onToggleSearchModal={() =>
              this.setState((prevState) => ({
                showSearchContent: !prevState.showSearchContent,
              }))
            }
            onChangeSearchParams={this.props.onChangeSearchParams}
            getListAPI={this.props.getListAPI}
          />
        )}
      </>
    );
  };
}

export default PaginatedTable;
