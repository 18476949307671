import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import heic2any from "heic2any";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "assets/translation";
import { requestError } from "utils/requestHandler";
import imageResizer from "utils/imageResizer";
import useSelectedLocalised from 'utils/hooks/useSelectedLocalised';

import "stylesheets/components/upload/index.scss";
import "./index.scss";
import "./upload.scss";

const ImageTypeLibrary = [
  "jpeg",
  "jpg",
  "png",
  "webp",
  "tiff",
  "heic",
  "heif",
  "svg",
  "ico",
];

const Upload = (props) => {
  const fileInput = useRef(null);
  const language = useSelectedLocalised();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setUploadedFiles(props.files || []);
  }, [props.files]);

  const getMemorySize = (fileSize) => {
    return fileSize / 1000 > 1000
      ? `${(fileSize / 10 ** 6).toFixed(2)}MB`
      : `${(fileSize / 1000).toFixed(2)}KB`;
  };

  const onUploadImage = (event) => {
    let sourceFiles = event.target.files;
    let tempFiles = _.cloneDeep(uploadedFiles);

    if (sourceFiles && sourceFiles.length > 0) {
      Object.keys(sourceFiles).map((key) => {
        if (sourceFiles[key].type === "image/heic") {
          const reader = new FileReader();
          reader.onload = async (e) => {
            let temp = {};
            const blob = await heic2any({ blob: sourceFiles[key] });
            const filename = sourceFiles[key].name.replace("heic", "jpeg").replace("HEIC", "JPEG");
            const convertedFile = new File([blob], filename, { type: "image/jpeg" });
            const options = [520, 400, "JPEG", 100, 0];
            await imageResizer(convertedFile, options, (uri) => {
              temp = {
                name: filename,
                source: uri,
                changeImage: true,
                size: sourceFiles[key].size,
                type: 'image/jpeg',
              };
              tempFiles.push(temp);
              setUploadedFiles(tempFiles);
              if (props.uploadImage) {
                props.uploadImage(props.returnRawFiles ? sourceFiles : tempFiles);
              }
            });
          };
          reader.readAsArrayBuffer(sourceFiles[key]);
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            let temp = {};
            if (sourceFiles[key].type.includes("image") && props.imageRestriction) {
              let image = new Image();
              image.src = e.target.result;
              image.onload = () => {
                if (image.height > 640 || image.width > 480) {
                  temp = {
                    name: sourceFiles[key].name,
                    source: e.target.result,
                    changeImage: true,
                    size: sourceFiles[key].size,
                    type: sourceFiles[key].type,
                  };
                  tempFiles.push(temp);
                  setUploadedFiles(tempFiles);
                  if (props.uploadImage) {
                    props.uploadImage(props.returnRawFiles ? sourceFiles : tempFiles);
                  }
                } else {
                  requestError("Image size is too small");
                }
              };
            } else {
              temp = {
                name: sourceFiles[key].name,
                source: e.target.result,
                changeImage: true,
                size: sourceFiles[key].size,
                type: sourceFiles[key].type,
              };
              tempFiles.push(temp);
              setUploadedFiles(tempFiles);
              if (props.uploadImage) {
                props.uploadImage(props.returnRawFiles ? sourceFiles : tempFiles);
              }
            }
          };
          reader.readAsDataURL(sourceFiles[key]);
        }
      });
    }
  };

  const renderRequired = (param) => {
    if (param) {
      return (
        <div
          className="at-form-input__required position-static"
          style={{ textAlign: "right" }}
        >
          {getTranslation('required', language)}
        </div>
      );
    }
  };

  const onClickRemoveFile = (item, index) => {
    if (props.onRemoveFile) {
      props.onRemoveFile(item, index);
    } else {
      let tmpFiles = _.cloneDeep(uploadedFiles);
      tmpFiles.splice(index, 1);
      setUploadedFiles(tmpFiles);
      const tmpValue = tmpFiles.length !== 0 ? tmpFiles : [{ source: "", name: "" }];
      props.uploadImage && props.uploadImage(tmpValue);
    }
  };

  const {
    hideLabelDragDrop,
    required = false,
    multiple = true,
    containerClass = "",
    containerStyle,
    style,
    disabled,
    accepts,
    labelText,
    hidePreview,
  } = props;

  return (
    <div
      className={`at-input_upload-cont ${containerClass}`}
      style={{
        textAlign: "center",
        ...containerStyle,
      }}
    >
      {((!multiple && uploadedFiles?.length === 0) || multiple) && (
        <div
          className={`at-input_uploader-cont ${uploadedFiles.length > 0 ? "at-file_uploaded" : ""}`}
          style={{ ...style }}
        >
          <AtlasIcon svgHref={"atlas-document-upload"} />
          <label className="at-input_upload-label">
            <span className="at-input_upload-highlight">{getTranslation('click_to_upload', language)}</span>
            {!hideLabelDragDrop 
              ? getTranslation("or_drag_and_drop", language) 
              : ""
            }
          </label>
          {labelText && <p className="fs-2 fw-400 text-muted">{labelText}</p>}
          <input
            className={"at-input_uploader-file at-input_upload-file"}
            multiple={multiple}
            disabled={disabled}
            id={"fileInputID"}
            ref={fileInput}
            type={"file"}
            onClick={() => document.getElementById("fileInputID").value = ""}
            onChange={(event) => onUploadImage(event)}
            accept={
              accepts || "application/pdf,image/png,image/jpg,image/jpeg"
            }
          />
        </div>
      )}
      {uploadedFiles?.length === 0 && renderRequired(required)}
      {!hidePreview && (
        <Grid container className="at-file_item-files_cont">
          {uploadedFiles?.map((item, index) => {
            const isImage =
              typeof item.source === "string"
                ? ImageTypeLibrary.some(
                    (imgTypeItem) =>
                      item.source
                        .toLowerCase()
                        .includes(`data:image/${imgTypeItem}`) ||
                      item.source.toLowerCase().includes(`.${imgTypeItem}`)
                  )
                : false;
            return (
              item.source && (
                <Grid
                  item
                  key={`at-file_item-${index}`}
                  xs={12}
                  className={`at-file_item-cont mb-1`}
                  style={{ ...(isImage && { height: 240 }) }}
                >
                  <div className="at-file_item-overlay" />
                  <div className="at-file_item-action">
                    <button
                      className="at-file_item-btn_close"
                      onClick={() => onClickRemoveFile(item, index)}
                    >
                      <MdClose />
                    </button>
                    <div>
                      <p className="at-file_item-file_name">{item.name}</p>
                      {item.size && (
                        <p className="fs-2">
                          {getMemorySize(item.size)}
                        </p>
                      )}
                    </div>
                    <div className="at-file_item-action-overlay"></div>
                  </div>
                  {isImage && <img src={item.source} />}
                </Grid>
              )
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Upload;