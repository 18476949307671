import React, { Component } from "react";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const Agents = (WrappedComponent) => {
  class AgentsHOC extends Component {
    state = {
      loading: false,

      internal_agents: [],
      agentList: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeAgentsHOC = (value, context) => {
      this.setState({ [context]: value });
    };

    getAgentsBySearch = (val) =>
      Get(
        `/admin/users/search?type=1&name=${val}`,
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ agentList: payload.agent_selections });
    getAgentsBySearchError = (error) => requestError(error);

    getIQIInternalAgentList = () =>
      Get(
        `/agents`,
        this.getIQIInternalAgentListSuccess,
        this.getIQIInternalAgentListError,
        this.load,
      );
    getIQIInternalAgentListSuccess = (payload) => {
      let tmpPayload = payload.agent_selections.map((item) => ({
        ...item,
        label: item.full_name,
        value: item.id,
      }));
      return this.setState({ internal_agents: tmpPayload });
    };
    getIQIInternalAgentListError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onChangeAgentsHOC={this.onChangeAgentsHOC}
            getAgentsBySearch={this.getAgentsBySearch}
            getIQIInternalAgentList={this.getIQIInternalAgentList}
            onLoadInternalAgents={this.state.loading}
            internal_agents={this.state.internal_agents}
            agentList={this.state.agentList}
          />
        </>
      );
    };
  }
  return AgentsHOC;
};

export default Agents;
