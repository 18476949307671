import React, { useEffect } from "react";
import { BiHelpCircle } from "react-icons/bi";

import ProgressBar from "./progressBar";

const Confirmation = ({
  selectedUnit,
  selectedProject,

  putReservation,
  onChangeStoreyPlanHOC,
}) => {
  useEffect(() => {
    if (
      selectedProject.reservation_time_limit &&
      selectedProject.reservation_time_limit.settings.has_time_limit
    ) {
      if (
        selectedUnit.reservation_status === "Reserved" &&
        !selectedUnit.reservations_attributes.has_submitted_payment
      ) {
        onChangeStoreyPlanHOC("progress", "PaymentSlip");
      } else if (
        selectedUnit.reservation_status === "Reserved" &&
        selectedUnit.reservations_attributes.has_submitted_payment
      ) {
        onChangeStoreyPlanHOC("progress", "BuyerInfo");
      }
    } else {
      onChangeStoreyPlanHOC("progress", "BuyerInfo");
    }
  }, []);

  const processMessage = () => {
    if (
      selectedProject.reservation_time_limit &&
      selectedProject.reservation_time_limit.settings.has_time_limit
    ) {
      return (
        <>
          <p>
            You are about to confirm the reservation of unit{" "}
            <span>{selectedUnit.unit_no}</span>.
          </p>
          <ul>
            <li>
              After you click "Confirm Reservation", you will have to submit
              proof of payment of the booking fee within
              <span>
                {" "}
                {
                  selectedProject.reservation_time_limit.settings.payment_slip
                    .time
                }
              </span>
              <span>
                {" "}
                {
                  selectedProject.reservation_time_limit.settings.payment_slip
                    .unit
                }
              </span>
              .
            </li>
            <li>
              If you fail to submit valid proof within the time limit, the unit
              will be automatically released.
            </li>
          </ul>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div style={{ textAlign: "center", maxWidth: 600, margin: "auto" }}>
        <BiHelpCircle
          style={{ width: 120, color: "#878683", marginBottom: 10 }}
        />
        <br />
        <h3 className="at-card__title mb-3 mr-0">{`Reservation of Unit ${selectedUnit.unit_no}`}</h3>
        <ProgressBar progress={"Reserve"} has_time_limit={true} />
        <p className={"at-storey-plan__confirmation-text"}>
          {processMessage()}
        </p>
        <div className={`d-flex justify-content-center mt-5 flex-wrap`}>
          <button
            className="at-btn at-btn--lg at-btn--success"
            style={{ marginRight: "20px" }}
            onClick={() => putReservation(selectedUnit)}
          >
            Confirm Reservation
          </button>
          <button
            style={{ marginRight: "20px" }}
            className="at-btn at-btn--lg at-btn--danger"
            onClick={() => onChangeStoreyPlanHOC("showReserveModal", false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
