import React, { Component } from "react";
import ReactModal from "react-modal";
import ReactLottie from "react-lottie";

import LoadingIcon from "assets/lottie/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingIcon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const customStyles = {
  overlay: {
    zIndex: 1301, // because material ui dialog is having 1300 as z index
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingRight: "40px",
  },
};

ReactModal.setAppElement("#root");

class LoadingModal extends Component {
  state = {
    isOpen: false,
    isPaused: false,
  };

  componentDidUpdate = (pp) => {
    if (this.props.isOpen !== pp.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  };

  onClickCloseModal() {
    this.setState({ isOpen: false });
  }

  renderLottieIcon(type) {
    return (
      <ReactLottie
        width={200}
        height={200}
        style={{ overflow: "visible" }}
        options={defaultOptions}
        isPaused={this.state.isPaused}
      />
    );
  }

  renderModalContent() {
    const { message, type } = this.props;
    return (
      <div>
        <div style={{ marginBottom: "10px" }}>
          {this.renderLottieIcon(type)}
        </div>
        <div>
          {message
            ? message.indexOf("\n") > -1
              ? message.split("\n").map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))
              : message
            : ""}
        </div>
        <hr />
      </div>
    );
  }

  render() {
    return (
      <ReactModal isOpen={this.state.isOpen} style={customStyles}>
        <div className="at-dropbear-panel">{this.renderModalContent()}</div>
      </ReactModal>
    );
  }
}

export default LoadingModal;
