import React, { Component } from "react";
import _ from "lodash";

import AtInput from "components/Input/formInput";
import "./styles.scss";

const getSearchParams = (searchParams) => {
  let tmp = "";
  searchParams.map((item) => {
    tmp = item.param !== "" ? tmp + `q[${item.value}]=${item.param}&` : tmp;
  });
  return tmp.trim();
};

const SearchContent = ({ searchParams, onChangeSearchParams, getListAPI }) => {
  const renderSearchParams = () => {
    return (
      <>
        <div className="search-bar">
          {searchParams &&
            searchParams.map((item, index) => {
              return (
                <div key={item.value} className="w-100">
                  {item.type === "input" && (
                    <AtInput
                      value={item.param}
                      placeholder={item.label}
                      onChangeValue={(value) => {
                        let tmp = _.cloneDeep(searchParams);
                        tmp[index].param = value;
                        return onChangeSearchParams(tmp);
                      }}
                    />
                  )}
                </div>
              );
            })}
          {searchParams && searchParams.length > 0 && (
            <div>
              <button
                disabled={!getSearchParams(searchParams)}
                className="btn-new btn-new--secondary"
                onClick={() => {
                  let tmp = getSearchParams(searchParams);
                  tmp && getListAPI(1, tmp);
                }}
              >
                Search
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return renderSearchParams();
};

export default SearchContent;
