import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";
import { GetFile } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeDraftHOC = (val, context) => this.setState({ [context]: val });

    downloadDraftEmail = (id, filename) =>
      GetFile(
        `/admin/forms/confirmation_forms/${id}/download_draft`,
        filename,
        () => {},
        this.downloadDraftEmailError,
        this.load,
      );
    downloadDraftEmailError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadDraftEmail={this.state.loading}
            onChangeDraftHOC={this.onChangeDraftHOC}
            downloadDraftEmail={this.downloadDraftEmail}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
