import React, { useState } from "react";
import { MdFilterList } from "react-icons/md";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import SvgIcon from "components/Icon/new";
import EmptyState from "components/EmptyState";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import TableContent from "./content";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

const PaginatedTable = ({
  meta,
  rowData,
  alertBox,
  totalPages,
  can_destroy,
  searchParams,
  sideMenuExpand,
  status_permissions,
  totalPendingRequest,

  onToggleCobroke,
  onClickCard,
  onClickEdit,
  onClickDelete,
  onClickFavourite,
  onChangeSearchParams,
  getListAPI,
  getLocalised,
  getFilterKey,
  renderSpecialMessage,
}) => {
  const [states, setStates] = useState({
    interval: 10,
    showSearchContent: false,
  });

  const renderTableContent = () => {
    if (rowData && rowData.length > 0) {
      return (
        <ul className="at-subsale_listing__container no-gutters">
          {rowData.map((item, index) => {
            return (
              <TableContent
                key={item.id}
                data={item}
                index={index}
                can_destroy={can_destroy}
                onClickEdit={onClickEdit}
                onClickCard={onClickCard}
                onClickDelete={onClickDelete}
                onClickFavourite={onClickFavourite}
                status_permissions={status_permissions}
                sideMenuExpand={sideMenuExpand}
                getLocalised={getLocalised}
              />
            );
          })}
        </ul>
      );
    } else {
      return (
        <EmptyState
          title={getLocalised("label.empty_listing.title", "No Listings Available")}
          description={getLocalised("label.empty_listing.description", "Please create a listing to show it here.")}
          renderIcon={<SvgIcon svgHref={"icon-listing-centre"} />}
        />
      );
    }
  };

  const renderPaginationText = () => {
    const param = {
      from: meta && meta.from,
      to: meta && meta.to,
      count: meta && meta.count,
    }
    return (
      <p className={`at-table__pagination-info`}>
        {renderSpecialMessage(
          getLocalised(
            "label.show_pagination_result_length",
            `Showing
            <span> {{from}} </span>-<span> {{to}} </span>
            of
            <span> {{count}} </span>
            results`
          ),
          "item",
          param
        )}
      </p>
    );
  };

  const renderTablePagination = () => {
    return (
      <div className="at-pagination">
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param.name ?? item.param}&`;
            });
            getListAPI(1, tmp);
            setStates({
              ...states,
              interval: 10,
            });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === 1) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param.name ?? item.param}&`;
            });
            getListAPI(meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${states.interval <= 10 || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = states.interval;
            setStates({
              ...states,
              interval: tmp - 10,
            });
          }}
        >
          ...
        </button>
        {totalPages &&
          totalPages.map((item) => {
            if (
              item + 1 <= states.interval &&
              item + 1 > states.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${meta && meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    searchParams.map((item) => {
                      tmp = tmp + `q[${item.value}]=${item.param.name ?? item.param}&`;
                    });
                    getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && states.interval >= meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = states.interval;
            setStates({
              ...states,
              interval: tmp + 10,
            });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param.name ?? item.param}&`;
            });
            getListAPI(meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(meta && meta.page === meta.pages) || !meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            searchParams.map((item) => {
              tmp = tmp + `q[${item.value}]=${item.param.name ?? item.param}&`;
            });
            getListAPI(meta.pages, tmp);
            setStates({
              ...states,
              interval: meta.pages,
            });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  return (
    <div className="at-table__options mb-10">
      <div className="mb-3">
        <button
          className={
            "btn-new btn-new--outline-secondary at-mobile-view__controller"
          }
          style={{ width: "100%" }}
          onClick={() =>
            setStates({ showSearchContent: !states.showSearchContent })
          }
        >
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            {getLocalised("label.filter_and_search", "Filter & Search")}
          </h5>
        </button>
        <div className="at-table__search-cont at-desktop-view__controller ">
          <TableSearchParams
            mode={"desktop"}
            searchParams={searchParams}
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
            getLocalised={getLocalised}
            getFilterKey={getFilterKey}
          />
        </div>
      </div>
      <div>{renderTableContent()}</div>
      {meta && meta.count > 0 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {renderTablePagination()}
          {renderPaginationText()}
        </div>
      )}
      {states.showSearchContent && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          onToggleSearchModal={() =>
            setStates({ showSearchContent: !states.showSearchContent })
          }
          onChangeSearchParams={onChangeSearchParams}
          getListAPI={getListAPI}
          getLocalised={getLocalised}
          getFilterKey={getFilterKey}
        />
      )}
    </div>
  );
};

export default PaginatedTable;
