import React from "react";
import AtlasIcon from "components/Icon/atlasIcon";

import AtPaginatedTable from "components/NewPaginatedTable";

const columnData = [
  {
    header: "Client Name",
    renderColumn: (rowData) => (
      <>
        <AtlasIcon
          svgHref={"atlas-frame"}
          style={{ fill: "#EE6D4D", marginRight: 7, width: 20, height: 20 }}
        />
        {rowData.presentation_client_name}
      </>
    ),
  },
  {
    header: "Currency",
    accessor: "currency",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      <p>
        <AtlasIcon
          svgHref={"atlas-frame"}
          style={{ fill: "#EE6D4D", marginRight: 7, width: 20, height: 20 }}
        />
        {rowData.currency}
      </p>;
    },
  },
  {
    header: "Deposit",
    accessor: "deposit",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
  },
  {
    header: "Loan Rate",
    accessor: "rate",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  {
    header: "Term",
    accessor: "loan_term",
    contentClass: "at-column-responsive_center",
    columnClass: "at-column-responsive_center",
  },
  { header: "Created Datetime", accessor: "created_at" },
];

export default function PresentationMortgage({
  mortgagePages,
  presentationMortgage,
  getPresentationMortgage,
}) {
  return (
    <AtPaginatedTable
      tableTitle={"Mortgage Calculator Usage Statistics"}
      rowData={presentationMortgage.data || []}
      meta={presentationMortgage.meta}
      columns={columnData}
      hideThematicBreak={true}
      renderExtraContent={() => (
        <p className="fs-2 mt-10">
          View the usage statistics and data points of the mortgage calculator
          in this presentation. The table shows the values that a client
          entered each time they used the mortgage calculator built into the
          online presentation.
        </p>
      )}
      actionColumnContent={[]}
      searchParams={[]}
      getListAPI={getPresentationMortgage}
      totalPages={mortgagePages || []}
    />
  );
}
