import React, { Component } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      township: [],
    };

    load = (param) => this.setState({ loading: param });

    // get township based on state
    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        township: _.map(payload, (town) => {
          return {
            ...town,
            value: town.name,
          };
        }),
      });
    getSelectedTownShipError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadTownShip={this.state.loading}
          township={this.state.township}
          getSelectedTownShip={this.getSelectedTownShip}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
