import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import _ from "lodash";
import { compose } from "redux";
import { PulseLoader } from "react-spinners";

import AtlasCobrokerCard from "../components/cobrokerCard";
import CustomTypeahead from "components/Typeahead/new";
import DeleteConfirmationModal from "components/Modal/delete";
import permissionsChecker from "utils/permissionsChecker";
import {
  subsaleRepresentativeBadge,
  subrentRepresentativeBadge,
} from "../../../assets";
import { getColorBadge } from "dictionary/badgeColor";
import ContactTypeHOC from "../../../actions/contactType";
import AgentSearchHOC from "actions/agentSearch";

import "stylesheets/containers/subsales-claims/index.scss";

const representativeEntity = (representative_id, sub_sale_type_id) => {
  if (representative_id !== null) {
    const tmpRepresentative =
      sub_sale_type_id === 1
        ? _.find(subsaleRepresentativeBadge, { value: representative_id })
        : _.find(subrentRepresentativeBadge, { value: representative_id });
    return tmpRepresentative || {};
  }

  return {};
}

class InternalAgent extends Component {
  state = {
    inputError: "",
    showConfirmationModal: false,
    toRemoveItem: {},

    selectedAgent: null,
    showSnackbar: false,
  };

  componentDidMount = () => {
    const { id: opposite_claim_id } = this.props.selected_claim.opposite_claim ?? {};
    this.props.getAssignedContactType({
      claim_id: this.props.selected_claim.id,
      type_id: 3,
      isPrimaryClaim: true,
    });
    if (opposite_claim_id) {
      this.props.getAssignedContactType({
        claim_id: opposite_claim_id,
        type_id: 3,
        isPrimaryClaim: false,
      });
    }

    this.onChangeTypeaheadSearch = _.debounce(
      (val) => this.onHandleTypeaheadSearch(val),
      600,
    );
  };

  onHandleTypeaheadSearch = (val) => {
    this.setState({ selectedAgent: null });

    return val.length > 0
      ? this.props.getAgentsBySearch(val)
      : this.props.onChangeAgentHOC([], "agentList");
  };

  componentDidUpdate = (pp) => {
    if (this.props.internal_cobrokers.length !== pp.internal_cobrokers.length) {
      this.props.updateInternalAgentCount(this.props.internal_cobrokers.length);
    }
  };

  onChangeValue = (context, value) =>
    this.setState({
      [context]: value,
      inputError: "",
    });

  assign = (data) => {
    let tmp = {
      co_broker: {
        commission_type_id: 0,
        commission: 0,
        extra_collection_amount: 0,
        co_broker_id: data.id,
        is_internal: true,
      },
    };
    let existing = _.find(this.props.internal_cobrokers, {
      co_broker: {
        user: {
          id: data.id,
        },
      },
    });

    if (existing) {
      this.setState({ showSnackbar: true });
    } else {
      this.setState({ selectedAgent: null }, () => {
        this.props.createEntity(tmp, this.props.selected_claim.id, 3);
      });
    }
  };

  onClickSaveCobrokerCard = (data, updatedValues) => {
    let tmp = {
      co_broker: {
        ...data,
        ...updatedValues,
      },
    };
    return this.props.updateSelectedEntity(
      tmp,
      this.props.selected_claim.id,
      3,
      data.id,
    );
  };

  onClickRemoveCobrokerCard = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        this.state.toRemoveItem.type_id,
        this.state.toRemoveItem.id,
      );
    });


  render = () => {
    const {
      agencies,
      agentList,
      internal_cobrokers,
      opposite_claim_internal_cobrokers,
    } = this.props;

    const { can_update, can_destroy } = permissionsChecker("Subsales Claims", this.props.data);
    const { sub_sale_type_id, representative_id, opposite_claim } = this.props.selected_claim;
    const { representative_id: opposite_representative_id } = this.props.selected_claim.opposite_claim ?? {};
    const { label: representativeLabel, badgeColor: claimBadgeColor } = representativeEntity(representative_id, sub_sale_type_id);
    const { label: oppositeRepresentativeLabel, badgeColor: oppositeClaimBadgeColor } = representativeEntity(opposite_representative_id, sub_sale_type_id);
     
    return (
      <div className="at-form__content">
        <div className="d-flex">
          <h2 className="at-form-input__title">Assign Internal Agent</h2>
          <PulseLoader
            sizeunit={"px"}
            size={10}
            color={"#16232c"}
            loading={this.props.onLoadAgent}
          />
        </div>
        <CustomTypeahead
          wrapperClassName="mb-30"
          disabled={this.props.onLoadAgent || !can_update}
          typeaheadId={"internal_agent"}
          options={agentList || []}
          selectedValue={
            this.state.selectedAgent ? [this.state.selectedAgent] : []
          }
          labelKey={"full_name"}
          helpText={"e.g. Full Name, Email, Mobile Number"}
          filterBy={["full_name", "email", "mobile_contact_number"]}
          onSearch={(val) => this.onChangeTypeaheadSearch(val)}
          onSelect={(val) =>
            val && val.length > 0 && this.setState({ selectedAgent: val[0] })
          }
          childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
          highlighterData={[
            (option) => `Team: ${option.team_name}`,
            <br />,
            (option) =>
              `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
          ]}
        />
        <button
          disabled={!this.state.selectedAgent}
          className="btn-new btn-new--secondary mt-3 d-block"
          onClick={() => this.assign(this.state.selectedAgent)}
        >
          Assign
        </button>
        {
          (representative_id === null || representative_id === 3) && (
            <div className="row mt-3">
              {internal_cobrokers.map((item, index) => (
                <div key={index} className="col-lg-6 col-xl-4">
                  <AtlasCobrokerCard
                    isOppositeClaim={false}
                    can_destroy={can_destroy}
                    key={item.id}
                    data={item}
                    agencies={agencies}
                    onClickRemoveCobrokerCard={this.onClickRemoveCobrokerCard}
                    onClickSaveCobrokerCard={this.onClickSaveCobrokerCard}
                    disableEdit={!can_update}
                  />
                </div>
              ))}
            </div>
          )
        }
        {
          (!(representative_id === null || representative_id === 3) &&
          (internal_cobrokers && internal_cobrokers.length > 0)) && (
            <>
              {
                opposite_claim === null && (
                  <div className="row mt-3">
                    {internal_cobrokers.map((item, index) => (
                      <div key={index} className="col-lg-6 col-xl-4">
                        <AtlasCobrokerCard
                          isOppositeClaim={false}
                          can_destroy={can_destroy}
                          key={item.id}
                          data={item}
                          agencies={agencies}
                          onClickRemoveCobrokerCard={this.onClickRemoveCobrokerCard}
                          onClickSaveCobrokerCard={this.onClickSaveCobrokerCard}
                          disableEdit={!can_update}
                        />
                      </div>
                    ))}
                  </div>
                )
              }
              {
                (opposite_claim && opposite_claim !== null) && (
                  <div className="mt-3 at-form__content at-party-internal_agents-cont">
                    <div className="d-flex flex-wrap grid_gap-2 mb-3">
                      <div
                        className="at-status_badge text-center"
                        style={{ ...getColorBadge(claimBadgeColor) }}>
                        {representativeLabel}
                      </div>
                    </div>
                    <div className="row mt-3">
                      {internal_cobrokers.map((item, index) => (
                        <div key={index} className="col-lg-6 col-xl-4">
                          <AtlasCobrokerCard
                            isOppositeClaim={false}
                            can_destroy={can_destroy}
                            key={item.id}
                            data={item}
                            agencies={agencies}
                            onClickRemoveCobrokerCard={this.onClickRemoveCobrokerCard}
                            onClickSaveCobrokerCard={this.onClickSaveCobrokerCard}
                            disableEdit={!can_update}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              }
            </>
        )}
        {opposite_claim_internal_cobrokers &&
          opposite_claim_internal_cobrokers.length > 0 && (
            <div className="mt-3 at-form__content at-party-internal_agents-cont">
              <div className="d-flex flex-wrap grid_gap-2 mb-3">
                <div
                  className="at-status_badge text-center"
                  style={{ ...getColorBadge(oppositeClaimBadgeColor) }}>
                  {oppositeRepresentativeLabel}
                </div>
              </div>
              <div className="row mt-3">
                {opposite_claim_internal_cobrokers.map((item, index) => (
                  <div key={index} className="col-lg-6 col-xl-4">
                    <AtlasCobrokerCard
                      can_destroy={false}
                      key={item.id}
                      data={item}
                      agencies={agencies}
                      isOppositeClaim={true}
                      onClickRemoveCobrokerCard={this.onClickRemoveCobrokerCard}
                      onClickSaveCobrokerCard={this.onClickSaveCobrokerCard}
                      disableEdit={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showSnackbar}
          autoHideDuration={2000}
          onClose={() => this.setState({ showSnackbar: false })}
          message="User is already assigned."
        />
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
      </div>
    );
  };
}

export default compose(AgentSearchHOC, ContactTypeHOC)(InternalAgent);
