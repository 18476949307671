import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import ReactHTMLParser from "react-html-parser";
import { SketchPicker } from "react-color";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomUpload from "components/Input/upload";
import CustomImageDisplay from "../../components/image";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import CustomDraggableSort from "components/DraggableSort";
import SubsalesTooltip from "../../components/tooltip";
import UploadModalContent from "../../components/UploadModalContent";
import ConfirmationModal from "components/Modal/delete";
import AlertBox from "components/AlertBox";
import AtlasRadioGroup from "components/RadioGroup";
import Tabs from "components/Tab";
import EmptyState from "components/EmptyState";
import PhotoModal from "components/PhotoModal";

import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import PhotoHOC from "../../actions/photo";

import "../../index.scss";

const Photo = ({
  data,
  from,
  subsalesEntryCreationProps,
  uploaded_image,
  watermark,
  watermark_content,
  watermark_color,
  publishImages,
  privateImages,
  onLoadPhoto,
  showPhotoTabModal,
  tempImgSrc,
  selectedImages,
  showSubsalesUpload,
  showSubsalesDeleteImage,
  uploadingSubsalesPhoto,

  onChangeSubsalesHOC,
  onChangePhotoHOC,
  getSubsalesPhotos,
  getLocalised,
  onClickUploadImage,
  updateImageSequence,
  onClickRemoveImage,
  onClickSetPrimary,
  onClickSetPhotoStatus,
  requestSuccess,
  selectedImageSection,
  status_id,
}) => {
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const { can_create, can_destroy, can_update } = permissionsChecker(
    "Listing Centre",
    data,
  );

  const ImageTabSections = [
    { id: "Published", key: "label.photos.upload_images.published", label: "Published", icon: "atlas-global" },
    { id: "Private", key: "label.photos.upload_images.private", label: "Private", icon: "atlas-lock-circle" },
  ];
  const StatusId = [
    { id: 1, key: "option.photos.upload_images.as_private", label: "As Private", value: 0 },
    { id: 2, key: "option.photos.upload_images.as_published", label: "As Published", value: 1 },
  ];
  const [enableRearrage, setEnableRearrage] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    const { storedWaterMark } = data.subsalesReducer;

    onChangePhotoHOC(subsalesEntryCreationProps.id, "id");
    if (storedWaterMark && Object.keys(storedWaterMark).length > 0) {
      onChangePhotoHOC(
        storedWaterMark.watermarkColor ? storedWaterMark.watermarkColor : "",
        "watermark_color",
      );
      onChangePhotoHOC(
        storedWaterMark.watermarkContent
          ? storedWaterMark.watermarkContent
          : "",
        "watermark_content",
      );
    }
    getSubsalesPhotos(subsalesEntryCreationProps.id);
  }, []);

  useEffect(() => {
    let { ajaxCallProgress, ajaxErrorMessage, ajaxError, ajaxSuccess } =
      data.ajaxStatusReducer;

    if (ajaxCallProgress) {
      onChangePhotoHOC(true, "loading");
    } else {
      onChangePhotoHOC(false, "loading");
      if (ajaxError && from === "UPLOAD_IMAGE") {
        getSubsalesPhotos(subsalesEntryCreationProps.id);
        onChangePhotoHOC(
          ReactHTMLParser(ajaxErrorMessage),
          "photoSnackbarContent",
        );
        onChangePhotoHOC(true, "showPhotoSnackbar");
      }
      if (ajaxSuccess && from === "UPLOAD_IMAGE") {
        onChangePhotoHOC("", "from");
        onChangePhotoHOC([], "uploaded_image");
        getSubsalesPhotos(subsalesEntryCreationProps.id);
        requestSuccess(this.props.getLocalised("success.upload_photo" ,"All photos uploaded successfully!"));
      }
    }
  }, [data.ajaxStatusReducer.ajaxCallProgress]);

  const updateRearrangedImages = () => {
    updateImageSequence();
    setEnableRearrage(false);
  };

  const onChangeCheckbox = (id) => {
    if (selectedImages.includes(id)) {
      let index = selectedImages.indexOf(id);
      let temp = _.cloneDeep(selectedImages);
      temp.splice(index, 1);
      onChangePhotoHOC(temp, "selectedImages");
    } else {
      let temp = _.cloneDeep(selectedImages);
      temp.push(id);
      onChangePhotoHOC(temp, "selectedImages");
    }
  };

  const onClickRemoveFile = (index) => {
    let temp = _.cloneDeep(uploaded_image);
    temp.splice(index, 1);
    onChangePhotoHOC(temp, "uploaded_image");
  };

  const onChangePhoto = async (images) => {
    const uploadedImages = images.map((item) => ({
      file: item.source || item.file,
      name: item.name,
    }));
    await onChangePhotoHOC(uploadedImages, "uploaded_image");
    await onChangePhotoHOC(1, "status_id");
  };

  const renderUploadFields = () => {
    return (
      <>
        <CustomUpload
          multiple={true}
          disabled={!can_create}
          accepts={"image/*"}
          hidePreview={true}
          imageRestriction={true}
          files={uploaded_image}
          style={{
            backgroundColor: "#F9FAFB",
            border: "1px dashed #D1D5DB",
            marginBottom: 12,
          }}
          labelText={"PNG, JPG, JPEG or GIF (min. 640 x 480)"}
          uploadImage={(val) => onChangePhoto(val)}
          language={data.languageReducer.language}
        />
        {uploaded_image && uploaded_image.length > 0 && (
          <div className="row">
            {uploaded_image.map((item, index) => (
              <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
                <CustomImageDisplay
                  showRemoveButton={true}
                  onClickShowImage={() => {}}
                  imgStyle={{ width: "100%" }}
                  showPrimaryLabel={false}
                  showSetPrimaryButton={false}
                  key={`Photo-${index}`}
                  tooltipID={`Photo-${index}`}
                  imgSrc={item.file && item.file}
                  tooltipChildren={getTranslation("remove_image", data.languageReducer.language)}
                  onClickRemoveImage={() => onClickRemoveFile(index)}
                />
              </div>
            ))}
          </div>
        )}
        <div className={"row"}>
          <div className={"col-12 pl-1"}>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={uploaded_image.length > 0 ? status_id : ""}
              disabled={!uploaded_image.length > 0}
              options={StatusId}
              selectedRadioValue={(val) => onChangePhotoHOC(val, "status_id")}
              getLocalised={getLocalised}
            />
          </div>

          <div className={"col-12 pl-1"}>
            <CustomCheckbox
              disabled={!can_create}
              checked={watermark}
              content={getLocalised("option.photos.upload_images.apply_watermark", "Apply Watermark")}
              onChangeCheckboxValue={(e) =>
                onChangePhotoHOC(e.target.checked, "watermark")
              }
              getLocalised={getLocalised}
            />
          </div>
          {watermark && (
            <div className={"col-12"} style={{ padding: 0 }}>
              <p className="mb-2 at-form-input__title"> {getLocalised("label.photos.upload_images.watermark_text", "Watermark Text")} </p>
              <textarea
                style={{ height: "100px" }}
                value={watermark_content}
                onChange={(e) =>
                  onChangePhotoHOC(e.target.value, "watermark_content")
                }
              ></textarea>
            </div>
          )}
          {watermark && (
            <div className={"col-lg-3 col-sm-12"}>
              <SketchPicker
                width={"100%"}
                style={{ color: "red" }}
                triangle={"hide"}
                color={watermark_color}
                onChangeComplete={(color) =>
                  onChangePhotoHOC(color.hex, "watermark_color")
                }
              />
            </div>
          )}
        </div>
        <div className="d-flex g-3">
          <button
            disabled={!can_create || !uploaded_image.length > 0}
            className="btn-new btn-new--primary"
            onClick={() => {
              onClickUploadImage();
            }}
          >
            {getTranslation("upload", selectedLocalised)}
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onChangeSubsalesHOC(false, "showEditModal")}
          >
            {getTranslation("close", selectedLocalised)}
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-gallery-add"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.photos.upload_images.title", "Upload Images")}
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showJuwaiToolTip: true,
              showJuwaiAsiaToolTip: true,
              showEdgeTooltip: true,
              tooltip: "subsalesImages",
            }}
          />
        </div>
        <AlertBox
          mode={"info"}
          containerClassName={"mb-3"}
          description={getLocalised("label.photos.upload_images.description", 
            `At least one image is required for the listing to be available on portals. 
            If no images have been uploaded, the listing will not be visible.
            You can select multiple images at the same time.`
          )}
        />
        <p className="my-2 at-form-input__title"> {getLocalised("label.floor_plan.form.upload_image", "Upload Image(s)")}</p>
        {renderUploadFields()}
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-gallery"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          {getLocalised("label.photos.uploaded_image.title", "Uploaded Images")}
        </div>
        <p className="fs-2">{getLocalised("label.photos.upload_images.subtitle_1", "View and manage your listing images.")}</p>
        <Tabs
          disabled={enableRearrage}
          sections={ImageTabSections}
          containerClass={"mt-20"}
          selectedSection={selectedImageSection}
          onSelectSection={(val) => {
            onChangePhotoHOC(val, "selectedImageSection");
            onChangePhotoHOC([], "selectedImages");
          }}
          getLocalised={getLocalised}
        />
        {((selectedImageSection === "Private" && privateImages.length < 1) ||
          (selectedImageSection === "Published" && publishImages.length < 1)) && (
          <EmptyState
            title={selectedImageSection === "Published" 
              ? getLocalised("label.photos.upload_images.no_published_images_uploaded", `You don't have any published images yet`)
              : getLocalised("label.photos.upload_images.no_private_images_uploaded", `You don't have any private images yet`)
            }
            renderIcon={<AtlasIcon svgHref="atlas-gallery" />}
          />
        )}
        {((selectedImageSection === "Private" && privateImages.length > 0) ||
          (selectedImageSection === "Published" && publishImages.length > 0)) && (
          <>
            <div className="d-flex flex-wrap">
              {selectedImageSection === "Published" && (
                <CustomButton
                  className="btn-new btn-new--secondary mb-2 mr-2"
                  tooltip={true}
                  tooltipChildren={
                    enableRearrage ? (
                      <span>{getLocalised("helptext.photos.upload_images.update_image_sequence", "Update new arragement.")}</span>
                    ) : (
                      <span style={{ maxWidth: 200, display: "flex" }}>
                        {getLocalised("helptext.photos.upload_images.image_sequence", 
                          `Drag and drop to arrange the sequence of the image
                          displayed on the IQI Global Website.`
                        )}
                      </span>
                    )
                  }
                  tooltipPosition={"top"}
                  tooltipID={`listing-centre-rearrage-photo`}
                  onClick={() =>
                    enableRearrage
                      ? updateRearrangedImages()
                      : setEnableRearrage(true)
                  }
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-arrange-circle"}
                        style={{
                          width: 18,
                          height: 18,
                          fill: "white",
                        }}
                      />
                      {enableRearrage ? getTranslation("update", selectedLocalised) : getLocalised("label.photos.upload_images.image_sequence", "Image Sequence")}
                    </>
                  }
                />
              )}
              <CustomButton
                className="btn-new btn-new--outline-secondary mb-2"
                disabled={false}
                tooltip={true}
                tooltipChildren={
                  selectedImages.length > 0 ? (
                    <span>{getLocalised("helptext.photos.upload_images.delete_checked_image", "Delete checked images")}</span>
                  ) : (
                    <span>{getLocalised("helptext.photos.upload_images.delete_image" ,"Check mark the image to delete")}</span>
                  )
                }
                tooltipPosition={"top"}
                tooltipID={`listing-centre-delete-photo`}
                onClick={() => {
                  if (selectedImages.length > 0) {
                    setShowDeleteConfirmation(true);
                  }
                }}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-trash"}
                      style={{
                        width: 18,
                        height: 18,
                        fill: selectedImages.length > 0 ? "#F04438" : "#FECDCA",
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                    />
                  </>
                }
              />
            </div>
            <CustomDraggableSort
              data={
                selectedImageSection === "Private"
                  ? privateImages
                  : publishImages
              }
              gird={0}
              background={"#FFFFFF"}
              disabled={!enableRearrage}
              containerClass={enableRearrage ? "m-auto w-100" : "row "}
              containerStyle={{ width: "auto" }}
              contentClass={
                enableRearrage ? "" : "col-md-6 col-lg-6 col-xl-4 col-x"
              }
              onChangeOrder={(val) =>
                onChangePhotoHOC(
                  val,
                  `${selectedImageSection === "Private" ? "privateImages" : "publishImages"}`,
                )
              }
              renderContent={(item, index) => (
                <CustomImageDisplay
                  showCheckBox={can_destroy}
                  checked={selectedImages.includes(item.id)}
                  imgStyle={{
                    width: "100%",
                    ...(enableRearrage && { maxWidth: 500, margin: "auto" }),
                  }}
                  showPrimaryLabel={selectedImageSection === "Published"}
                  showSetPrimaryButton={
                    can_update && selectedImageSection === "Published"
                  }
                  key={`Photo-Subsales-${index}`}
                  tooltipID={`Photo-Subsales-${index}`}
                  imgSrc={item.photo ? item.photo : item.large_image_url}
                  tooltipChildren={getTranslation("remove_image", data.languageReducer.language)}
                  onClickShowImage={(val) => {
                    onChangePhotoHOC(true, "showPhotoTabModal");
                    onChangePhotoHOC(val, "tempImgSrc");
                  }}
                  onChangeChecbox={() => onChangeCheckbox(item.id)}
                  onClickSetPrimary={() => onClickSetPrimary(item.id)}
                  onClickSetPublish={() => onClickSetPhotoStatus(item.id, 1)}
                  onClickSetPrivate={() => onClickSetPhotoStatus(item.id, 0)}
                  isPrimary={item.is_primary}
                  isPrivate={item.status_id === 0}
                  showSetPublishButton={
                    can_update && selectedImageSection === "Private"
                  }
                  getLocalised={getLocalised}
                />
              )}
            />
          </>
        )}
      </div>
      {showSubsalesUpload && (
        <UploadModalContent
          uploadingFiles={uploadingSubsalesPhoto}
          getLocalised={getLocalised}
          onClose={() => onChangePhotoHOC(false, "showSubsalesUpload")}
        />
      )}
      {showSubsalesDeleteImage && (
        <UploadModalContent
          title={getLocalised("label.deleting_image.title", "Deleting Image")}
          uploadingFiles={uploadingSubsalesPhoto}
          getLocalised={getLocalised}
          onClose={() => onChangePhotoHOC(false, "showSubsalesDeleteImage")}
        />
      )}
      <PhotoModal
        showPhotoTabModal={showPhotoTabModal}
        tempImgSrc={tempImgSrc}
        togglePhotoTabModal={() => onChangePhotoHOC(false, "showPhotoTabModal")}
      />
      <ConfirmationModal
        title={getLocalised("label.delete_photo.confirmation.title", "Delete Photo")}
        loading={onLoadPhoto}
        open={showDeleteConfirmation}
        selectedLocalised={data.languageReducer.language}
        message={getLocalised("label.delete_photo.confirmation.message", "Are you sure you want to remove selected photo?")}
        positiveAction={() =>
          Promise.all([onClickRemoveImage(selectedImages)]).then(() => {
            setShowDeleteConfirmation(false);
          })
        }
        negativeAction={() => setShowDeleteConfirmation(false)}
      />
    </>
  );
};

export default PhotoHOC(Photo);
