import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      adminOptions: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeReassignHOC = (val, context) => this.setState({ [context]: val });

    getAdminRoles = (id) =>
      Get(
        `/admin/admin_roles/search?type_id=${id}`,
        this.getAdminRolesSuccess,
        this.getAdminRolesError,
        this.load,
      );
    getAdminRolesSuccess = (payload) =>
      this.setState({ adminOptions: payload });
    getAdminRolesError = (error) => requestError(error);

    submitReassign = (dataToSubmit) =>
      Post(
        `/admin/reassign_submission_requests`,
        dataToSubmit,
        this.submitReassignSuccess,
        this.submitReassignError,
        this.load,
      );
    submitReassignSuccess = () => {
      requestSuccess(`Request has been sent successfully`);
      this.props.getPendingReassign();
      this.props.onClose();
      this.props.closeUpdateModal();
    };
    submitReassignError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            adminOptions={this.state.adminOptions}
            onLoadReassign={this.state.loading}
            onChangeReassignHOC={this.onChangeReassignHOC}
            submitReassign={this.submitReassign}
            getAdminRoles={this.getAdminRoles}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
