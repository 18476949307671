import React, { Component } from "react";
import _ from "lodash";

import ModalDialog from "components/Modal/ModalDialog";
import LoadingModal from "components/LoadingModal";
import NameCardUpdate from "../NameCardUpdate";
import CouponTable from "./components/Table";
import Detail from "./components/detail";

import permissionsChecker from "utils/permissionsChecker";
import MyCouponHOC from "./actions";

class MyCoupon extends Component {
  componentDidMount = () => this.props.getMyCoupons(1, "");

  renderCardContent = () => {
    const { can_update } = permissionsChecker("Atlas Store", this.props.data);
    return (
      <CouponTable
        can_update={can_update}
        rowData={this.props.myCoupons.data ? this.props.myCoupons.data : []}
        meta={this.props.myCoupons.meta && this.props.myCoupons.meta}
        onClickRedeem={(val) => this.props.getSelectedCoupon(val.id)}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeMyCouponHOC(val, "searchParams")
        }
        getListAPI={this.props.getMyCoupons}
        totalPages={this.props.myCouponsPage}
      />
    );
  };
  render = () => {
    const { can_update } = permissionsChecker("Atlas Store", this.props.data);
    const {
      history,
      onLoadMyCoupon,
      showRedeemConfirmation,
      onChangeMyCouponHOC,
      showRedeemNameCardDialog,
      showProfileUpdate,
      redeemCoupon,
      selectedCoupon,
    } = this.props;
    return (
      <>
        {this.renderCardContent()}
        {showRedeemNameCardDialog && (
          <ModalDialog
            onClose={() =>
              onChangeMyCouponHOC(false, "showRedeemNameCardDialog")
            }
            onLoad={onLoadMyCoupon}
            title="Coupon Details"
          >
            <Detail
              history={history}
              can_update={can_update}
              data={this.props.data}
              onLoadMyCoupon={onLoadMyCoupon}
              showRedeemConfirmation={showRedeemConfirmation}
              onChangeMyCouponHOC={onChangeMyCouponHOC}
              onShowProfileUpdate={() =>
                onChangeMyCouponHOC(true, "showProfileUpdate")
              }
              redeemCoupon={redeemCoupon}
              selectedCoupon={selectedCoupon}
            />
          </ModalDialog>
        )}
        {showProfileUpdate && (
          <ModalDialog
            onClose={() => onChangeMyCouponHOC(false, "showProfileUpdate")}
            title="Personal Info"
          >
            <NameCardUpdate
              onClickClose={() =>
                onChangeMyCouponHOC(false, "showProfileUpdate")
              }
            />
          </ModalDialog>
        )}
        {onLoadMyCoupon && <LoadingModal />}
      </>
    );
  };
}

export default MyCouponHOC(MyCoupon);
