import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import SVGIcon from "components/Icon";

const DarkTooltip = withStyles((theme, a) => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 14,
  },
}))(Tooltip);

class Icon extends Component {
  render() {
    const {
      containerClassName,
      containerStyle,
      tooltip,
      itemID,
      iconClassName,
      iconName,
      tooltipLabel,
      children,
    } = this.props;
    return (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        key={itemID}
        placement={"top"}
        title={tooltipLabel}
      >
        <div
          className={containerClassName}
          style={containerStyle}
          data-tip={"tooltip"}
          data-for={itemID}
        >
          <SVGIcon className={iconClassName} svgHref={iconName} />
          {children}
        </div>
      </DarkTooltip>
    );
  }
}

export default Icon;
