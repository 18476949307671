import React, { useState } from "react";

import RecommendationTable from "../components/RecommendationTable";
import AtlasCloseButton from "components/Button/close";

export default function RecommendationModalContent({
  data,
  fullRecommendationList,

  onClose,
}) {
  const { sideMenuExpand } = data.sideMenuReducer;

  return (
    <>
      <div className={`${sideMenuExpand ? "menu-rcc-expand" : ""}`}>
        <div className={`d-flex`}>
          <h4 className="at-card__title">{"Co-broke Recommendation List"}</h4>
          <AtlasCloseButton
            containerStyle={{ display: "flex", marginLeft: "auto" }}
            onClick={() => onClose()}
          />
        </div>
        <hr />
        <RecommendationTable
          rowData={fullRecommendationList}
          originalRowData={fullRecommendationList}
        />
      </div>
    </>
  );
}
