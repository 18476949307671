import React, { Component } from "react";

import Tab from "components/Tab";
import SummaryContent from "../summary";
import ActivityContent from "./Activity";
import MessageContent from "./Message";
import SalesForm from "../saleForms";
import RequestContent from "./Request";

class EditContent extends Component {
  state = {
    tabsContent: "Details",
  };

  renderTabContent = () => {
    const { selectedSale, branchRegion } = this.props;
    return (
      <>
        {this.state.tabsContent === "Details" &&
          [`1`, `4`].indexOf(selectedSale.status_id) === -1 && (
            <SummaryContent
              summaryMode={"view"}
              formData={selectedSale}
              branchRegion={branchRegion}
            />
          )}
        {this.state.tabsContent === "Details" &&
          [`1`, `4`].indexOf(selectedSale.status_id) > -1 && (
            <SalesForm
              {...this.props}
              formMode={"edit"}
              selectedFormData={selectedSale}
              onClose={() =>
                this.props.onChangeSalesHOC(false, "showEditDialog")
              }
              onClickSubmit={this.props.updateProjectSales}
            />
          )}
        {this.state.tabsContent === "Requests" && (
          <RequestContent {...this.props} />
        )}
        {this.state.tabsContent === "Messages" && (
          <MessageContent {...this.props} />
        )}
        {this.state.tabsContent === "Activity" && (
          <ActivityContent {...this.props} />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <Tab
          sections={["Details", "Requests", "Messages", "Activity"]}
          selectedSection={this.state.tabsContent}
          onSelectSection={(val) => this.setState({ tabsContent: val })}
        />
        {this.renderTabContent()}
      </>
    );
  };
}

export default EditContent;
