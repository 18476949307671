import React, { useState } from "react";
import _ from "lodash";
import { BsX } from "react-icons/bs";

const MultipleBadgeInput = ({
  type,
  style,
  maxLength,
  placeholder,
  containerStyle,
  valueKey,
  valueList,
  disabled,
  onChangeValue,
}) => {
  const [inputValue, onChangeInputVallue] = useState("");

  const onSubmit = () => {
    let temp = _.cloneDeep(valueList);
    if (valueKey) {
      temp.push({ [valueKey]: inputValue });
    } else {
      temp.push(inputValue);
    }
    onChangeValue(temp);
    onChangeInputVallue("");
  };

  const onRemove = (index) => {
    let temp = _.cloneDeep(valueList);
    temp.splice(index, 1);
    onChangeValue(temp);
  };

  return (
    <div
      className="at-form-input"
      style={{ display: "block", ...containerStyle }}
    >
      <div className="d-flex w-100">
        {valueList.map((item, index) => (
          <div
            key={`badge-input-${index}`}
            className="at-status_badge mr-1"
            style={{
              borderColor: "#3B82F6",
              color: "#3B82F6",
              backgroundColor: "#FFF",
            }}
          >
            {valueKey ? item[valueKey] : item}
            <BsX
              style={{
                width: 14,
                height: 14,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={() => onRemove(index)}
            />
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center w-100">
        <input
          type={type}
          style={{ order: 0, height: 30, ...style }}
          className={`at-form-input__input`}
          value={inputValue}
          onChange={(event) => onChangeInputVallue(event.target.value)}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
        />
        <button
          className="btn-new-sm btn-new--outline-secondary"
          onClick={() => onSubmit()}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default MultipleBadgeInput;
