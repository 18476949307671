import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";

import UserEditForm from "./Edit";
import DetailsModal from "./Details";
import CreateAdminForm from "./Create";

import UsersHOC from "./actions/index.js";
import BranchHOC from "./actions/branch";
import BranchRegionHOC from "actions/branchRegion";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";

import { initGA } from "utils/ga";
import { getItem } from "utils/tokenStore";
import { requestError } from "utils/requestHandler";
import { getColorBadge } from "dictionary/badgeColor";
import ModalDialog from "components/Modal/ModalDialog";

export const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Inactive", colorName: "Grey" },
  { value: "Suspended", colorName: "Red" },
  { value: "Deleted", colorName: "Red" },
  { value: "Resigned", colorName: "Grey" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Terminated", colorName: "Red" },
  { value: "Waiting Approval", colorName: "Yellow" },
];

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <UserInfo
        name={rowData.full_name || "N/A"}
        email={rowData.email || "N/A"}
        phone_number={rowData.mobile_contact_number || "N/A"}
        hideRedirect={true}
      />
    ),
    columnStyle: { width: "130%" },
  },
  {
    header: "Team",
    accessor: "team_name",
    columnStyle: { width: "50%" },
  },
  {
    header: "Role",
    accessor: "role",
    columnStyle: { width: "50%" },
  },
  {
    header: "Branch",
    renderColumn: (rowData) => (
      <>
        <p>{rowData.branch_name || "N/A"}</p>
        <p>{rowData.country_name || "N/A"}</p>
      </>
    ),
    columnStyle: { width: "50%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
  },
];

class UsersManagement extends Component {
  state = {
    from: "",
    showTab: "Profile",
    onLoadImpersonate: false,
  };

  componentDidMount = () => {
    const { role: userRole } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    if (userRole !== "Agent") {
      this.props.getBranchRegions();
      this.props.getBranch();
      this.props.getAllUsers(1, "");
    } else {
      this.props.history.push(`${tmpPath}/dashboard/profile`);
    }
    this.props.getCountryDictionary();
    this.props.getAllDictionaryData();
    initGA("/dashboard/admin/user-management");
  };

  componentDidUpdate = (prevProps) => {
    const {
      ajaxCallProgressImpersonate,
      ajaxSuccessImpersonate,
      ajaxErrorImpersonate,
      ajaxErrorMessageImpersonate,
    } = this.props.data.ajaxStatusReducer;

    if (
      ajaxCallProgressImpersonate !==
      prevProps.data.ajaxStatusReducer.ajaxCallProgressImpersonate
    ) {
      if (
        this.state.from === "admin_login" &&
        ajaxCallProgressImpersonate === 0
      ) {
        setTimeout(() => {
          this.setState({ onLoadImpersonate: false });
          if (ajaxSuccessImpersonate) {
            const popUp = window.open(
              `${window.location.origin}/#/admin-impersonate/${this.props.data.adminSignInReducer.login_id}/dashboard/profile`,
              "_blank",
            );
            try {
              popUp.focus();
            } catch (e) {
              alert(
                "Pop-up Blocker is enabled! Please disable your pop-up blocker.",
              );
            }
          }
          if (ajaxErrorImpersonate) {
            requestError(ajaxErrorMessageImpersonate);
          }
          this.setState({ from: "" });
        }, 1500);
      }
    }
  };

  renderUserEditDialog = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <UserEditForm
        profileRole={role}
        users={this.props.users}
        showTab={this.state.showTab}
        branches={this.props.branches}
        onLoadUsers={this.props.onLoadUsers}
        internalAgents={this.props.internalAgents}
        branchesByCountry={this.props.branchesByCountry}
        getBranchByCountry={this.props.getBranchByCountry}
        selectedUser={this.props.selectedUser}
        updateUser={this.props.updateUser}
        closeEditForm={() => this.props.onChangeHOC(false, "showEditUserForm")}
      />
    );
  };

  renderNewAdminDialog = () => (
    <CreateAdminForm
      onClose={() => this.props.onChangeHOC(false, "showCreateAdminModal")}
      createNewAdmin={this.props.createNewAdmin}
      onLoadUsers={this.props.onLoadUsers}
    />
  );

  renderUserDetailsDialog = () => {
    return (
      <ModalDialog
        title={"User Details"}
        onLoad={this.props.onLoadUsers}
        onClose={() => this.props.onChangeHOC(false, "showDetailsModal")}
        fullWidth={true}
        fullHeight={true}
        children={
          <DetailsModal
            branches={this.props.branches}
            selectedUser={this.props.selectedUser}
            selectedUnitAdminProfile={this.props.selectedUnitAdminProfile}
            uploadAgentFile={this.props.uploadAgentFile}
            showCopySnackBar={this.props.showCopySnackBar}
            onChangeHOC={this.props.onChangeHOC}
            onClickBackButton={() =>
              this.props.onChangeHOC(false, "showDetailsModal")
            }
            onClickEditButton={() => {
              this.setState({ showTab: "Profile" });
              this.props.onChangeHOC(true, "showEditUserForm");
            }}
            onClickNameCard={() => {
              this.setState({ showTab: "Name Card" });
              this.props.onChangeHOC(true, "showEditUserForm");
            }}
            getSelectedUser={this.props.getSelectedUser}
          />
        }
      />
    );
  };

  renderCardContent = () => {
    const { onLoadAgent, onLoadRegion, onLoadUsers, onLoadBranch } = this.props;
    const { can_update } = permissionsChecker("Users", this.props.data);

    const { onLoadImpersonate } = this.state;
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <AtPaginatedTable
          rowData={this.props.users.data || []}
          meta={this.props.users.meta}
          columns={columnData}
          actionColumnContent={[
            {
              name: "details",
              onClick: (rowData) =>
                this.props.getSelectedUser(rowData.id, "details"),
            },
            {
              name: "impersonate",
              onShow: (rowData) => {
                const superAdminCheck =
                  tmpProfileReducer.role === "Super Admin" &&
                  ["Super Admin"].indexOf(rowData.role) === -1;
                const adminCheck =
                  tmpProfileReducer.role === "Admin" &&
                  ["Admin", "Super Admin"].indexOf(rowData.role) === -1 &&
                  can_update;

                return (
                  (superAdminCheck || adminCheck) &&
                  !window.location.href.includes("/admin-impersonate")
                );
              },
              disabled: rowData => ["Active", "Waiting Approval", "Pending", "Inactive"].indexOf(rowData.status) === -1,
              tooltipContent: "Impersonate User",
              onClick: (rowData) => {
                const isImpersonated = getItem("IQI_ATLAS_JWT_AGENT_TOKEN");
                if (isImpersonated === null) {
                  localStorage.setItem("IQI_ATLAS_OUTDATED_AGENT_TOKEN", "no");
                  this.props.adminLogin({
                    id: rowData.id,
                    email: rowData.email,
                  });
                  this.setState({
                    from: "admin_login",
                    onLoadImpersonate: true,
                  });
                } else {
                  requestError(
                    "You are currently impersonating another user. Please stop impersonating first.",
                  );
                }
              },
            },
          ]}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeHOC(val, "searchParams")
          }
          getListAPI={this.props.getAllUsers}
          totalPages={this.props.usersPages}
        />
        {(onLoadBranch ||
          onLoadAgent ||
          onLoadRegion ||
          onLoadUsers ||
          onLoadImpersonate) && <LoadingModal />}
      </>
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Users", this.props.data);

    return (
      <>
        <ModuleHeader
          title={"Users"}
          moduleIcon={"icon-personal"}
          actionButton={[
            {
              label: "New Admin",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeHOC(true, "showCreateAdminModal"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showDetailsModal && this.renderUserDetailsDialog()}
        {this.props.showEditUserForm && this.renderUserEditDialog()}
        {this.props.showCreateAdminModal && this.renderNewAdminDialog()}
      </>
    );
  };
}

export default compose(
  UsersHOC,
  BranchRegionHOC,
  BranchHOC,
  VersionCheckHOC,
)(UsersManagement);
