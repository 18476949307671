import { Component } from "react";
import { IoMdAdd } from "react-icons/io";
import { compose } from "redux";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import ShareModal from "components/Share";
import CreateDialog from "./Create";
import EditDialog from "./Edit";

import VersionCheckHOC from "actions/versionCheck";
import { initGA } from "utils/ga";
import permissionsChecker from "utils/permissionsChecker";
import GraphicMngmtHOC from "./actions";
import { columnData } from "./asset";
import "./index.scss";

class GraphicMngmt extends Component {
  componentDidMount = () => {
    initGA("/dashboard/graphic-studio");
    this.props.getCategories();
    this.props.getGraphics(1, "");
  };

  componentDidUpdate = (pp) => {
    if (
      pp.showEditGraphicModal !== this.props.showEditGraphicModal &&
      !this.props.showEditGraphicModal
    ) {
      this.props.onChangeGraphicHOC(null, "selectedTemplate");
    }
  };

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Graphic Studio",
      this.props.data,
    );

    const { role } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
    let isAdmin = ["Super Admin", "Admin"].indexOf(role) > -1;

    return (
      <AtPaginatedTable
        columns={columnData(isAdmin)}
        actionColumnContent={[
          ...(isAdmin || (!isAdmin && can_update)
            ? [
                {
                  name: isAdmin ? "view" : "edit",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) => {
                    Promise.all([
                      isAdmin
                        ? this.props.getAdminSelectedGraphic(rowData.id)
                        : this.props.getSelectedGraphic(rowData.id),
                    ]).then(() => {
                      this.props.onChangeGraphicHOC(
                        true,
                        "showEditGraphicModal",
                      );
                    });
                  },
                },
              ]
            : []),
          {
            name: "share",
            onClick: (rowData) => {
              Promise.all([
                this.props.onChangeGraphicHOC(rowData, "selectedGraphic"),
              ]).then(() => {
                this.props.updateDownloadCount(rowData.id);
                this.props.onChangeGraphicHOC(true, "showShareModal");
              });
            },
          },
          ...(can_destroy
            ? [
                {
                  name: "delete",
                  color: "#F04438",
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) => {
                    Promise.all([
                      this.props.onChangeGraphicHOC(rowData, "selectedGraphic"),
                    ]).then(() => {
                      this.props.onChangeGraphicHOC(
                        true,
                        "showDeleteConfirmation",
                      );
                    });
                  },
                },
              ]
            : []),
        ]}
        rowData={this.props.graphics.data ? this.props.graphics.data : []}
        meta={this.props.graphics.meta && this.props.graphics.meta}
        onChangeSearchParams={(val) =>
          this.props.onChangeGraphicHOC(val, "searchParams")
        }
        searchParams={this.props.searchParams}
        getListAPI={this.props.getGraphics}
        totalPages={this.props.graphicPages}
      />
    );
  };

  render = () => {
    const {
      deleteGraphic,
      selectedGraphic,
      showShareModal,
      showEditGraphicModal,
      showCreateGraphicModal,
      onChangeGraphicHOC,
      onLoadGraphic,
      updateDownloadCount,
      showDeleteConfirmation,
    } = this.props;

    const { can_create } = permissionsChecker(
      "Graphic Studio",
      this.props.data,
    );
    const { role } =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Graphic Studio"}
          atlasIcon={"atlas-gallery-edit"}
          showPABadge={role === "Personal Assistant"}
          description={
            <>
              <span className="d-block mb-2">
                Using the Graphics Studio, you can create and customise
                professionally crafted images for yourself such as flyers for
                properties, IQI Property Assistant flyers and more.
              </span>
              <span className="d-block mb-2">
                Your existing graphics are shown below. To create a new one,
                click on the "+" icon, enter a title for your new image and then
                choose a template from the many templates available. Customise
                it and then click create.
              </span>
              <span>
                To share your images, click on the download icon beside an
                image, choose the type of file you want to save and then save
                the file.
              </span>
            </>
          }
          actionButton={[
            {
              label: "New Graphic",
              onShow:
                !["Super Admin", "Admin"].indexOf(role) > -1 && can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18 }} />
              ),
              onClick: () =>
                this.props.onChangeGraphicHOC(true, "showCreateGraphicModal"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {showCreateGraphicModal && (
          <ModalDialog
            title={"New Graphic"}
            onClose={() => onChangeGraphicHOC(false, "showCreateGraphicModal")}
            children={
              <CreateDialog
                template={this.props.template}
                categories={this.props.categories}
                onClickSubmit={this.props.createGraphic}
                showLoading={onLoadGraphic}
                onChangeGraphicHOC={this.props.onChangeGraphicHOC}
                getGraphicsTemplate={this.props.getGraphicsTemplate}
                onClickClose={() =>
                  onChangeGraphicHOC(false, "showCreateGraphicModal")
                }
              />
            }
          />
        )}
        {showEditGraphicModal && (
          <ModalDialog
            title={"Edit Graphic"}
            onClose={() => onChangeGraphicHOC(false, "showEditGraphicModal")}
            children={
              <EditDialog
                data={this.props.data}
                selectedGraphic={selectedGraphic}
                showLoading={onLoadGraphic}
                selectedTemplate={this.props.selectedTemplate}
                onClickSubmit={(val) =>
                  this.props.updateGraphic({
                    id: selectedGraphic.id,
                    ...val,
                  })
                }
                updateDownloadCount={updateDownloadCount}
                onChangeGraphicHOC={this.props.onChangeGraphicHOC}
                getSelectedTemplate={this.props.getSelectedTemplate}
                onClickClose={() =>
                  onChangeGraphicHOC(false, "showEditGraphicModal")
                }
              />
            }
          />
        )}
        {showShareModal && (
          <ShareModal
            title={"Share Graphic"}
            mediaURL={this.props.selectedGraphic.thumbnail_url}
            shareLink={this.props.selectedGraphic.thumbnail_url}
            downloadTooltip={"Download Image"}
            showElement={[
              "Whatsapp",
              "Telegram",
              "Facebook",
              "Download",
              "Copy Link",
            ]}
            onClickClose={() =>
              this.props.onChangeGraphicHOC(false, "showShareModal")
            }
          />
        )}
        <ConfirmationModal
          title={"Delete Graphics"}
          loading={onLoadGraphic}
          open={showDeleteConfirmation}
          message={`Are you sure you want to remove this graphic ?`}
          positiveAction={() => deleteGraphic(selectedGraphic.id)}
          negativeAction={() =>
            onChangeGraphicHOC(false, "showDeleteConfirmation")
          }
        />
        {onLoadGraphic && <LoadingModal />}
      </>
    );
  };
}

export default compose(GraphicMngmtHOC, VersionCheckHOC)(GraphicMngmt);
