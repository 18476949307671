import React, { Component } from "react";
import FileSaver from "file-saver";

import getDomainURL from "utils/getDomainURL";
import { getItem } from "utils/tokenStore";
import { requestError } from "utils/requestHandler";

const DonwloadHOC = (WrappedComponent) => {
  class DownloadComponent extends Component {
    state = { downloading: false };

    onDownloadFile = (item) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";
      let token = getItem(tokenName);
      let headers = new Headers();
      headers.append("Authorization", `JWT ${token}`);
      return this.setState({ downloading: true }, async () => {
        const result = await fetch(
          `${getDomainURL()}/nextcloud/directories/${item.ids[item.ids.length - 1]}?file_path=${item.path}&download=1`,
          { headers },
        );
        if (result.ok) {
          const resultBlob = await result.blob();
          return this.setState({ downloading: false }, () => {
            FileSaver.saveAs(resultBlob, item.display_name);
          });
        } else {
          requestError("Sorry, the file or folder no longer exist.");
        }
      });
    };
    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onDownloadFile={this.onDownloadFile}
          />
        </>
      );
    };
  }
  return DownloadComponent;
};

export default DonwloadHOC;
