import _ from "lodash";
import Moment from "moment";

export const venueOptions = [
  { value: 1, label: "Physical Training" },
  { value: 2, label: "Online Training" },
];

export const columnData = [
  {
    header: "Event Date",
    searchFlag: "cpd_event_date",
    renderColumn: (rowData) =>
      Moment(rowData.cpd_event_date).format("DD MMM YYYY"),
  },
  {
    header: "Event Name",
    accessor: "cpd_event_name",
    searchFlag: "cpd_event_name",
  },
  {
    header: "Organiser",
    accessor: "organiser",
    searchFlag: "organiser",
  },
  {
    header: "Venue",
    accessor: "venue_name",
    searchFlag: "venue",
  },
  {
    header: "CPD Hours",
    accessor: "cpd_hour",
    searchFlag: "cp_hour",
  },
];

export const newEventInputField = [
  { label: "Event Date", value: "cpd_event_date", type: "date" },
  { label: "Event Name", value: "cpd_event_name", type: "text" },
  { label: "Organiser", value: "organiser", type: "text" },
  {
    label: "Training Type",
    value: "venue_id",
    type: "select",
    options: venueOptions,
  },
  { label: "CPD Hours", value: "cpd_hour", type: "number" },
];

export const documentType = [
  { label: "Bankruptcy Letter", value: "2", uploaded: false },
  { label: "Receipt", value: "3", uploaded: false },
  { label: "Form K", value: "4", uploaded: false },
  { label: "Licence Tag", value: "5", uploaded: false },
];
