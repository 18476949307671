import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import {
  types as SubsalesType,
  categories as CategoriesDictionary,
  edgePropDataMapping
} from "dictionary/subsales";
import { Get, Post } from "utils/axios";
import permissionsChecker from "utils/permissionsChecker";
import { requestError, requestSuccess } from "utils/requestHandler";

const DefaultCobrokeSetting = {
  co_broke_portion_by: "percentage",
  total_portion: 0,
  my_portion: 0,
  balance: 0,
};

const NewubsalesHOC = (WrappedComponent) => {
  class NewSubsalesWrappedComponent extends Component {
    constructor(props) {
      super(props);

      const countryId = window.location.href.includes("/admin-impersonate")
        ? this.props.data?.currentSignInProfileReducer?.country_id
        : this.props.data?.profileReducer?.country_id;

      const country = _.find(this.props.data.dictionaryReducer.countries, { id: countryId });

      this.state = {
        requests: [],

        showLoadingModal: false,
        showGenerateModal: false,
        disableCreateButton: false,

        descriptionType: "English",
        openForInternalCoBroke: permissionsChecker(
          "Co Broke Center",
          this.props.data,
        ).can_create,
        dataBasicInfo: {
          subsalesType: "",
          property_name: "",
          propertyNameError: "",
          address: "",
          addressError: "",
          hiddenAddress: "",
          hiddenAddressError: "",
          selectedListingCategory: "",
          highriseName: "",
          highriseNameError: "",
          postalCode: "",
          postalCodeError: "",
          township: "",
          townshipError: "",
          description: "",
          zh_description: "",
          hidden_description: "",
          availableDate: null,
          setReminderAvailableDate: false,
          setReminderDaysAmount: 0,

          tmpStates: country?.states ?? [],
          tmpPropertyTypes: [],
          selectedCountry: country ?? {},
          selectedBedroom: {},
          selectedBathroom: {},
          selectedCarpark: {},
          selectedTenure: {},
          selectedTitleType: {},
          selectedFurnishing: {},
          selectedDirection: {},
          selectedOccupied: {},

          selectedPropertyType: {},
          selectedUnitType: {},
          selectedMeasurement: {},
          selectedState: {},
          builtUp: "",
          landArea: "",
          sub_area: "",
          askingPrice: "",
          askingPricePerMeasurementUnit: "",
          asking_price_currency: "",
          youtubeLink: "",
          monthlyMaintenanceFee: 0,
          isMonthlyMaintainanceFeePerMeasurementUnit: false,
          selectedCurrency: {
            value: country?.currency ?? "",
            label: country?.currency ?? "",
          },
          status_id: 0,
          edge_prop_asset_id: "",
        },
        dataCobrokes: DefaultCobrokeSetting,
        dataFacilitiesInfo: [],
        autoSuggestOptions: [],
        autoSuggestLoading: false,
        otherFacilities: "",
      };
    }

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeNewHOC = (val, context) =>
      this.setState({
        [context]: val,
        ...(context === "openForInternalCoBroke" &&
          !val && {
            dataCobrokes: DefaultCobrokeSetting,
          }),
      });

    //store Details Data
    storeDetailsData = (data) => this.setState({ dataDetails: data });

    // process basic info before create
    processBasicInfo = () => {
      const {
        subsalesType,
        selectedListingCategory,
        property_name,
        selectedPropertyType,
        selectedUnitType,
        address,
        hiddenAddress,
        selectedCountry,
        selectedState,
        postalCode,
        highriseName,
        township,
        sub_area,
        availableDate,
        selectedMeasurement,
        builtUp,
        landArea,
        askingPrice,
        askingPricePerMeasurementUnit,
        selectedCurrency,
        monthlyMaintenanceFee,
        youtubeLink,
        isMonthlyMaintainanceFeePerMeasurementUnit,
        description,
        status_id,
        edge_prop_asset_id,
        zh_description,
        hidden_description,
        selectedBedroom,
        selectedBathroom,
        selectedCarpark,
        selectedTenure,
        selectedTitleType,
        selectedFurnishing,
        selectedDirection,
        selectedOccupied,
      } = this.state.dataBasicInfo;
      let context = "";
      subsalesType === "sell" ? (context = "Sub-Sale") : (context = "Sub-Rent");
      let selectedType = _.find(SubsalesType, { name: context });
      let selectedCategory = _.find(CategoriesDictionary, {
        id: selectedListingCategory,
      });
      let dataToSubmit = {
        hidden_address: hiddenAddress,
        youtube_link: youtubeLink,
        monthly_maintainance_fee_cents: parseInt(monthlyMaintenanceFee),
        is_monthly_maintainance_fee_per_measurement_unit:
          isMonthlyMaintainanceFeePerMeasurementUnit,
        property_name: property_name,
        type_id: selectedType.id,
        category_id: selectedCategory && selectedCategory.id,
        property_type_id: selectedPropertyType?.id ?? "",
        unit_type_id: selectedUnitType?.id ?? "",
        address: address,
        country_id: selectedCountry?.id ?? "",
        state_id: selectedState?.id ?? "",
        postal_code: postalCode || "",
        high_rise_name: highriseName,
        township: township,
        sub_area,
        status_id: status_id,
        edge_prop_asset_id: edge_prop_asset_id,
        available_date: availableDate,
        measurement_id: selectedMeasurement?.id ?? "",
        built_up: builtUp,
        land_area: landArea,
        asking_price_cents: askingPrice,
        asking_price_per_measurement_unit_cents: askingPricePerMeasurementUnit,
        asking_price_currency: selectedCurrency?.value ?? "",
        description: description || "",
        zh_description: zh_description || "",
        hidden_description: hidden_description || "",
        bedrooms: selectedBedroom?.id ?? "",
        bathrooms: selectedBathroom?.id ?? "",
        car_parks: selectedCarpark?.id ?? "",
        tenure_id: selectedTenure?.id ?? "",
        title_type_id: selectedTitleType?.id ?? "",
        furnishing_status_id: selectedFurnishing?.id ?? "",
        direction_id: selectedDirection?.id ?? "",
        occupancy_id: selectedOccupied?.id ?? "",
      };
      return dataToSubmit;
    };

    processFaciInfo = () => {
      let temp = {};

      _.map(this.state.dataFacilitiesInfo, (item) => {
        temp[item] = true;
      });

      return temp;
    };

    //submit to create
    onClickSubmitAllInfo = (val) => {
      let dataToSubmit = {
        listing: {
          ...val,
          ...this.processBasicInfo(),
          ...this.processFaciInfo(),
          open_for_internal_co_broke: this.state.openForInternalCoBroke,
          ...(this.state.otherFacilities && {
            other_facilities: this.state.otherFacilities,
          }),
          ...(this.state.openForInternalCoBroke && {
            co_broke_settings: this.state.dataCobrokes,
          }),
        },
      };

      if (
        this.state.openForInternalCoBroke &&
        _.values(this.state.dataCobrokes).some((val) => !val)
      ) {
        this.setState({ disableCreateButton: false });
        return requestError(
          this.props.getLocalised("error.fill_up_co_broke_commission", "Please fill up the Co-broke Commission to proceed"),
        );
      }

      this.createSubsales(dataToSubmit);
    };

    //create by calling create api
    createSubsales = (dataToSubmit) => {
      Post(
        `/sub_sales?locale=${this.props.data.languageReducer.language}`,
        dataToSubmit,
        this.createSubsalesSuccess,
        this.createSubsalesError,
        this.load,
      );
    };
    createSubsalesSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
      requestSuccess(this.props.getLocalised("success.create_subsales", "Subsales record created successfully."));
      this.props.onChangeSubsalesHOC(false, "showNewModal");
      Promise.all([
        this.props.getSelectedTownShip(payload.state_id),
        this.props.getSelectedSubsales(payload.id),
      ]).then(() => {
        this.props.onChangeSubsalesHOC(true, "showEditModal");
        if (payload.status_id === 1) {
          this.props.onChangeSubsalesHOC("Photos", "selectedSection");
        } else {
          this.props.onChangeSubsalesHOC("Details", "selectedSection");
        }
      });
      this.setState({ disableCreateButton: false });
    };
    createSubsalesError = (error) => {
      this.setState({ disableCreateButton: false });
      requestError(error);
    };

    edgeAutoSuggest = (query) => {
      const { selectedPropertyType, selectedListingCategory } =
        this.state.dataBasicInfo;

      if (!_.isEmpty(selectedPropertyType) && selectedListingCategory) {
        Get(
          `/sub_sales/suggest_address?query=${query}&category_id=${selectedListingCategory}&property_type=${selectedPropertyType.name}&locale=${this.props.data.languageReducer.language}`,
          this.edgeAutoSuggestSuccess,
          this.edgeAutoSuggestError,
          (load) => this.setState({ autoSuggestLoading: load }),
        );
      }
    };
    edgeAutoSuggestSuccess = (payload) => {
      this.setState({
        autoSuggestOptions: _.map(payload.data, (item) => {
          return {
            ...item,
            state_s_lower: _.find(edgePropDataMapping, { edgeProp: item.state_s_lower })?.atlas ?? item.state_s_lower,
            value: item.name_s_lower,
          };
        }),
      });
    };
    edgeAutoSuggestError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            otherFacilities={this.state.otherFacilities}
            openForInternalCoBroke={this.state.openForInternalCoBroke}
            onLoadNewSubsales={this.state.showLoadingModal}
            descriptionType={this.state.descriptionType}
            dataDetails={this.state.dataDetails}
            dataBasicInfo={this.state.dataBasicInfo}
            dataCobrokes={this.state.dataCobrokes}
            onLoadNewHOC={this.state.showLoadingModal}
            showGenerateModal={this.state.showGenerateModal}
            autoSuggestOptions={this.state.autoSuggestOptions}
            autoSuggestLoading={this.state.autoSuggestLoading}
            dataFacilitiesInfo={this.state.dataFacilitiesInfo}
            disableCreateButton={this.state.disableCreateButton}
            onChangeNewHOC={this.onChangeNewHOC}
            edgeAutoSuggest={this.edgeAutoSuggest}
            storeDetailsData={this.storeDetailsData}
            onClickSubmitAllInfo={this.onClickSubmitAllInfo}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(NewSubsalesWrappedComponent);
};

export default NewubsalesHOC;
