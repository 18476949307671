import React, { Component } from "react";
import { compose } from "redux";
import { MdFilterList } from "react-icons/md";
import Moment from "moment";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleFormHeader from "components/Form/header";
import ConfirmationModal from "components/Modal/confirmation";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import ModalDialog from "components/Modal/ModalDialog";
import PhotoSlider from "components/PhotoSlider";
import PaymentModal from "./components/PaymentModal";
import CreateForm from "./create";

import MeetingRoomHOC, { searchParams } from "../actions/meetingRooms";
import VersionCheckHOC from "actions/versionCheck";
import TickCircle from "assets/images/tick-circle.svg";

class MeetingRooms extends Component {
  state = {
    showSearchModal: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.branches !== this.props.branches) {
      this.props.onChangeMeetingHOC(
        searchParams(this.props.branches),
        "searchParams",
      );
    }
  };

  onToggleBooking = () => {
    let dataToSubmit = _.cloneDeep(this.props.newBooking);
    dataToSubmit.booking_add_ons_attributes = dataToSubmit.add_ons
    dataToSubmit.booking_add_ons_attributes.map(item => {
      delete item.price;
      return item;
    })
    delete dataToSubmit.add_ons;
    this.props.createBooking(dataToSubmit);
  };

  renderEmptyState = () => {
    return (
      <>
        <p className="at-card--meeting-room-header"> Meeting Rooms </p>
        <div className="at-table-row-cont">
          <ul className="at-table-row grid-controller">
            <li className="at-table-row__item at-table-row__item-empty">
              <p>No result found</p>
              <span>
                Try to adjust your search or filter options to find something
                you're looking for
              </span>
              <br />
            </li>
          </ul>
        </div>
      </>
    );
  };

  renderRoomList = (data) => (
    <ul
      id="roomListing"
      className="at-subsale_listing__container no-gutters"
      style={{ gridGap: "0.75rem" }}
    >
      {data.map((item, index) => {
        return (
          <li key={index} className={`at-subsales__item`}>
            <PhotoSlider
              id={`photo-${item.id}`}
              images={[item.image_url]}
            />
            {data.meeting_room_type_id === 1 && (
              <div
                className="at-card--meeting-room-status"
                style={{
                  background:
                    item.status.name === "Available" ? "#12B76A" : "#F04438",
                }}
              >
                <div className="fw-500">
                  {item.status.name === "Available" ? "Available" : "In use"}
                </div>
                <div style={{ fontSize: 12 }}>
                  until {Moment(item.status.until).format("h:mmA")}
                  {Moment().isSame(Moment(item.status.until), "day")
                    ? "Today"
                    : ""}
                </div>
              </div>
            )}
            <div style={{ padding: 12, background: "white" }}>
              <div
                style={{ fontSize: 18, fontWeight: 600, marginBottom: 4 }}
              >
                {" "}
                {item.name}{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 16,
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <div className="at-card--meeting__detail">
                  <AtlasIcon svgHref={"atlas-location"} />
                  {item.location}
                </div>
                <div className="at-card--meeting__detail">
                  <AtlasIcon svgHref={"atlas-user"} />
                  {item.capacity} seats
                </div>
              </div>
              <div className="at-card--meeting__detail">
                <AtlasIcon
                  svgHref={"atlas-clock"}
                  style={{ fill: "#3B82F6" }}
                />
                {item.operating_start_time} - {item.operating_end_time}
              </div>

              {item.meeting_room_type_id === 1 && (
                <div className="at-card--meeting-room_day_view_block">
                  {item.roomEvent &&
                    item.roomEvent.length > 0 &&
                    item.roomEvent.map((item, index) => {
                      return (
                        <div key={`rev-${index}`} className="d-flex">
                          <div className="at-day-view-booking-time">
                            <div>
                              {Moment(item.start_time).format("h:mmA")}
                            </div>
                            <div>{Moment(item.end_time).format("h:mmA")}</div>
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="at-day-view-div_line">
                              <div className="at-day-view-div_dot"></div>
                            </div>
                          </div>
                          <div
                            className="at-day-view-booking-name"
                            style={{
                              color:
                                item.status === "Confirmed"
                                  ? "#111827"
                                  : "#12B76A",
                            }}
                          >
                            {item.status === "Confirmed"
                              ? item.event_subject
                              : "Available"}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}

              <button
                className="btn-new btn-new--primary w-100"
                style={{ marginTop: 16, opacity: 1 }}
                onClick={() => {
                  let temp = {
                    ...item,
                    title: item.name,
                    date: item.status.until,
                  };
                  this.props.onChangeMeetingHOC(temp, "selectedRoom");
                  this.props.onChangeMeetingHOC(true, "showCreateBookingModal");
                }}
              >
                <img
                  src={TickCircle}
                  style={{ width: "20px", height: "20px" }}
                />
                <p className="at-card--meeting-room_booking-caption">
                  {item.status.name === "Available"
                    ? "Book Now"
                    : "Book For Later"}
                </p>
              </button>
            </div>
          </li>
        );
      })}
    </ul>
  )

  renderSearchForm = () => {
    const { encoded_identity = "" } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <div className="mb-3">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() =>
              this.setState({ showSearchModal: !this.state.showSearchModal })
            }
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Find Room
            </h5>
          </button>
          <div className="at-table__search-cont at-desktop-view__controller ">
            <TableSearchParams
              mode={"desktop"}
              searchLabel={"Find Room"}
              showResetButton={true}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeMeetingHOC(val, "searchParams")
              }
              getListAPI={(page, search) =>
                this.props.filterMeetingRoom(encoded_identity, search)
              }
            />
          </div>
          {this.state.showSearchModal && (
            <TableSearchParams
              mode={"mobile"}
              searchLabel={"Find Room"}
              searchParams={this.props.searchParams}
              showResetButton={true}
              onToggleSearchModal={() =>
                this.setState({ showSearchModal: !this.state.showSearchModal })
              }
              onChangeSearchParams={(val) =>
                this.props.onChangeMeetingHOC(val, "searchParams")
              }
              getListAPI={(page, search) =>
                this.props.filterMeetingRoom(encoded_identity, search)
              }
            />
          )}
        </div>
      </>
    );
  };

  renderBookingForm = () => {
    const {
      newBooking,
      calendarEvent,
      onChangeMeetingHOC,
      selectedRoom,
      disableBook,
      onCloseBookingForm,
      getCalendarEvent,
      createBooking,
      showTermsAndConditions,
    } = this.props;

    return (
      <>
        <ModalDialog
          title={selectedRoom.meeting_room_type_id === 1
            ? "Book Meeting Room "
            : "Book Business Lounge"
          }
          fullWidth={true}
          onClose={() => onCloseBookingForm()}
          children={
            <CreateForm
              calendarEvent={calendarEvent}
              newBooking={newBooking}
              onChangeMeetingHOC={onChangeMeetingHOC}
              selectedRoom={selectedRoom}
              date={selectedRoom.date}
              getCalendarEvent={getCalendarEvent}
              showTermsAndConditions={showTermsAndConditions}
            />
          }
          footer={
            <div className="d-flex g-3">
              <button
                type={"button"}
                disabled={disableBook}
                className="btn-new btn-new--primary"
                onClick={() => {
                  if (selectedRoom.meeting_room_type_id === 1) {
                    onChangeMeetingHOC(true, "disableBook");
                    this.onToggleBooking()
                  } else {
                    onChangeMeetingHOC(true, "showPaymentConfirmation");
                  }
                }}
              >
                {this.props.selectedRoom?.meeting_room_type_id === 1
                  ? 'Book Meeting Room'
                  : 'Book Business Lounge'
                }
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={() => onCloseBookingForm()}
              >
                Close
              </button>
            </div>
          }
        />
        <ConfirmationModal
          open={this.props.showPaymentConfirmation}
          loading={this.props.onLoadMeetingRooms}
          title={"Proceed To Payment"}
          message={"Click on “Confirm” button you will be redirected to the payment page."}
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() => {
            this.onToggleBooking()
            onChangeMeetingHOC(true, "disableBook");
          }}
          negativeAction={() => this.props.onChangeMeetingHOC(false, "showPaymentConfirmation")}
        />
      </>
    );
  };

  renderPaymentFailedModal = () => {
    return (
      <ModalDialog
        children={
          <div className="d-flex flex-column g-3">
            <div className={"text-center"}>
              <div className="at-confirmation_modal-icon">
                <AtlasIcon
                  svgHref={"atlas-danger"}
                  style={{ fill: "#F04438", backgroundColor: "#FEE4E2" }}
                />
              </div>
              <br />
              <h4 className="fs-4 fw-600">Booking Payment Fail</h4>
              <p className={"fs-2 mt-10 mb-20"}>The booking payment has failed. Please try again or contact our support team for assistance.</p>
            </div>
            <button
              className="btn-new btn-new--primary w-100"
              onClick={() => this.props.onChangeMeetingHOC(false, "showPaymentFailedModal")}>
              OK
            </button>
          </div>
        }
      />
    )
  }

  render = () => {
    const { roomList, businessList } = this.props;
    return (
      <>
        {this.renderSearchForm()}
        {businessList && businessList.length > 0 && (
          <>
            <ModuleFormHeader
              title={"Business Lounge"}
              className={"mb-3"}
              moduleIcon={"atlas-presentation-chart"}
            />
            {this.renderRoomList(businessList)}
          </>
        )}
        {roomList && roomList.length > 0 && (
          <>
            <ModuleFormHeader
              title={"Meeting Rooms"}
              className={"my-3"}
              moduleIcon={"atlas-people-linear"}
            />
            {this.renderRoomList(roomList)}
          </>
        )}
        {!(roomList && roomList.length > 0) && this.renderEmptyState()}
        {this.props.showCreateBookingModal && this.renderBookingForm()}
        {this.props.showPaymentFailedModal && this.renderPaymentFailedModal()}
        {this.props.showPaymentModal && (
          <PaymentModal
            data={this.props.data}
            selectedRoom={this.props.selectedRoom}
            paymentDetails={this.props.paymentDetails}

            makePayment={this.props.makePayment}
            cancelPayment={this.props.cancelPayment}
          />
        )}
        {this.props.onLoadMeetingRooms && <LoadingModal />}
      </>
    );
  };
}

export default compose(MeetingRoomHOC, VersionCheckHOC)(MeetingRooms);
