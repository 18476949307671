import React from "react";
import Moment from "moment";
import AtlasSelect from "components/Select/new";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";

import AtlasIcon from "components/Icon/atlasIcon";
import LodaingModal from "components/LoadingModal";
import CustomTypeahead from "components/Typeahead/new";
import CustomRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import AtlasCheckbox from "components/Checkbox";
import AtlasAutosuggest from "components/Autosuggest";
import ModuleFormHeader from "components/Form/header";

const commission_types = [
  {
    value: 1,
    label: "Percentage",
  },
  {
    value: 2,
    label: "Amount",
  },
  {
    value: 3,
    label: "No commission sharing",
  },
];

const subsalesType = [
  { id: 1, label: "Sale", value: 1 },
  { id: 2, label: "Rental", value: 2 },
];

const SSTOptions = [
  { label: "6%", value: 6 },
  { label: "8%", value: 8 },
];

const TempCobrokeLetter = ({
  can_update,
  onChange,
  onSave,
  onClose,
  invoicing_branches,
  agencies,
  selected_agency,
  type_id,
  invoicing_branch_id,
  letter_date,
  property_address,
  commission_type_id,
  valid_from,
  valid_to,
  agent_commission,
  co_broker_commission,
  full_name,
  email,
  contact_number,
  ren_tag,
  use_display_name,
  sales_tax_percentage,
  show_professional_fee_percentage,
  professional_fee_percentage,

  storedAddresses,

  onLoadCobrokeLetter,
}) => {
  const conAgentInfo = [
    {
      title: "Full Name",
      label: "full_name",
      value: full_name,
    },
    {
      title: "Email",
      label: "email",
      value: email,
    },
    {
      title: "Contact Number",
      label: "contact_number",
      value: contact_number,
    },
    {
      title: "Ren Tag",
      label: "ren_tag",
      value: ren_tag,
    },
  ];

  return (
    <>
      <div className="at-form__content">
        <ModuleFormHeader
          title={"Letter Details"}
          className={"mb-3"}
          moduleIcon={"atlas-document-text"}
        />
        <div className="at-form_fields_cont">
          <div className="at-form_field-col-6">
            <div className="mb-3">
              <h2 className="at-form-input__title">Select Agency</h2>
              <div>
                <CustomTypeahead
                  typeaheadId={"agencies"}
                  options={agencies}
                  selectedValue={selected_agency}
                  labelKey={"label"}
                  disabled={!can_update}
                  filterBy={["label"]}
                  onSelect={(val) => onChange("selected_agency", val)}
                  childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                  highlighterData={[(option) => option.address]}
                />
              </div>
            </div>
            <div className="mb-3">
              <h2 className="at-form-input__title">Select a branch</h2>
              <div>
                <AtlasSelect
                  value={invoicing_branch_id}
                  disabled={!can_update}
                  onChange={(e) =>
                    onChange("invoicing_branch_id", e.target.value)
                  }
                >
                  {invoicing_branches &&
                    invoicing_branches.length > 0 &&
                    invoicing_branches.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </AtlasSelect>
              </div>
            </div>
            <div className="mb-3">
              <h2 className="at-form-input__title">Property Address</h2>
              <div>
                <AtlasAutosuggest
                  placeholder={"Property Address"}
                  inputStyle={{ height: 40, borderColor: "#e0e0e0" }}
                  value={property_address}
                  disabled={!can_update}
                  updateValue={(val) => onChange("property_address", val)}
                  options={storedAddresses}
                />
              </div>
            </div>
            <div>
              <h2 className="at-form-input__title">Commission Types</h2>
              <div>
                <CustomRadioGroup
                  checkedValue={commission_type_id}
                  disabled={!can_update}
                  options={commission_types}
                  selectedRadioValue={(val) =>
                    onChange("commission_type_id", val)
                  }
                />
                {commission_type_id === 1 && (
                  <div
                    className={"at-form_comm_cont mt-3 text-left"}
                    style={{ backgroundColor: '#f3f4f6'}}
                  >
                    <p>
                      shared on basis as below based on the full commission
                      received on the Rental/Sale price
                    </p>
                    <div className={"at-form_comm_breakdown_agent-cont fs-2"}>
                      <input
                        type={"number"}
                        className="agent-comm-input"
                        value={agent_commission}
                        onChange={(e) =>
                          onChange("agent_commission", e.target.value)
                        }
                        disabled={!can_update}
                        placeholder={"0"}
                      />
                      % (Agent Commission)
                    </div>
                    <div className={"at-form_comm_breakdown_cobroke-cont fs-2"}>
                      <input
                        type={"number"}
                        className="agent-comm-input"
                        value={co_broker_commission}
                        onChange={(e) =>
                          onChange("co_broker_commission", e.target.value)
                        }
                        disabled={!can_update}
                        placeholder={"0"}
                      />
                      % (Co-broke Commission)
                    </div>
                  </div>
                )}
                {commission_type_id === 2 && (
                  <>
                    <div cl>
                      <h2 className="at-form-input__title mt-3">SST</h2>
                      <CustomRadioGroup
                        checkedValue={sales_tax_percentage}
                        options={SSTOptions}
                        selectedRadioValue={(val) =>
                          onChange("sales_tax_percentage", val)
                        }
                        disabled={!can_update}
                      />
                    </div>
                    <div
                      className={"at-form_comm_cont mt-3 text-left"}
                      style={{ backgroundColor: "#f3f4f6" }}
                    >
                      <p className={"mt-10"}>
                        paid RM
                        <input
                          type={"number"}
                          className="agency-fees-input ml-1"
                          value={co_broker_commission}
                          onChange={(e) =>
                            onChange("co_broker_commission", e.target.value)
                          }
                          disabled={!can_update}
                        />{" "}
                        to your agency plus {sales_tax_percentage}% SST on the
                        fee.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="at-form_field-col-6">
            <div className="mb-20">
              <h2 className="at-form-input__title">Type</h2>
              <div>
                <CustomRadioGroup
                  checkedValue={type_id}
                  disabled={!can_update}
                  options={subsalesType}
                  selectedRadioValue={(val) => onChange("type_id", val)}
                />
              </div>
            </div>
            <div className="mb-3">
              <h2 className="at-form-input__title">Letter Date</h2>
              <div
                className={`at-form-input ${!can_update ? "at-form-input__disabled" : ""}`}
              >
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-input"}
                />
                <DatePicker
                  className="at-form-input__input"
                  value={letter_date}
                  disabled={!can_update}
                  onChange={(val) =>
                    onChange("letter_date", Moment(val).format("DD MMM YYYY"))
                  }
                  placeholderText={"DD MMM YYYY"}
                />
              </div>
            </div>
            <div className="mb-3">
              <h2 className="at-form-input__title">Valid From</h2>
              <div className="d-flex">
                <div
                  className={`at-form-input mb-0 ${!can_update ? "at-form-input__disabled" : ""}`}
                >
                  <AtlasIcon
                    className={"at-form-input__calendar"}
                    svgHref={"atlas-calendar-input"}
                  />
                  <DatePicker
                    className="at-form-input__input"
                    value={valid_from}
                    disabled={!can_update}
                    onChange={(val) =>
                      onChange("valid_from", Moment(val).format("DD MMM YYYY"))
                    }
                    placeholderText={"DD MMM YYYY"}
                  />
                </div>
                {can_update && (
                  <button
                    className="btn-new-sm btn-new--outline-secondary ml-2"
                    onClick={() => onChange("valid_from", "")}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
            <div>
              <h2 className="at-form-input__title">Valid To</h2>
              <div className="d-flex">
                <div
                  className={`at-form-input mb-0 ${!can_update ? "at-form-input__disabled" : ""}`}
                >
                  <AtlasIcon
                    className={"at-form-input__calendar"}
                    svgHref={"atlas-calendar-input"}
                  />
                  <DatePicker
                    className="at-form-input__input"
                    value={valid_to}
                    disabled={!can_update}
                    onChange={(val) =>
                      onChange("valid_to", Moment(val).format("DD MMM YYYY"))
                    }
                    placeholderText={"DD MMM YYYY"}
                  />
                </div>
                {can_update && (
                  <button
                    className="btn-new-sm btn-new--outline-secondary ml-2"
                    onClick={() => onChange("valid_to", "")}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
          </div>
          {[1, 2].indexOf(commission_type_id) > -1 && (
            <div className="at-form_field-col-12 at-form_comm-desktop_col">
              {commission_type_id === 1 && (
                <div className={"at-form_comm_cont-desktop text-left"}
                  style={{ backgroundColor: '#f3f4f6'}}>
                  <p>
                    shared on basis as below based on the full commission
                    received on the Rental/Sale price
                  </p>
                  <div className={"at-form_comm_breakdown_agent-cont fs-2"}>
                    <input
                      type={"number"}
                      className="agent-comm-input"
                      value={agent_commission}
                      onChange={(e) =>
                        onChange("agent_commission", e.target.value)
                      }
                      disabled={!can_update}
                      placeholder={"0"}
                    />
                    % (Agent Commission)
                  </div>
                  <div className={"at-form_comm_breakdown_cobroke-cont fs-2"}>
                    <input
                      type={"number"}
                      className="agent-comm-input"
                      value={co_broker_commission}
                      onChange={(e) =>
                        onChange("co_broker_commission", e.target.value)
                      }
                      disabled={!can_update}
                      placeholder={"0"}
                    />
                    % (Co-broke Commission)
                  </div>
                </div>
              )}
              {commission_type_id === 2 && (
                <div
                  className={
                    "at-form_comm_cont-desktop text-left max-width-grey"
                  }
                >
                  <p>
                    paid RM
                    <input
                      type={"number"}
                      className="agency-fees-input ml-1"
                      value={co_broker_commission}
                      onChange={(e) =>
                        onChange("co_broker_commission", e.target.value)
                      }
                      disabled={!can_update}
                    />{" "}
                    to your agency plus {sales_tax_percentage}% SST on the fee.
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="at-form_field-col-12 d-flex flex-column mb-0">
            <AtlasCheckbox
              content={
                "Use my display name in the letter ( Default is using full name )"
              }
              checked={use_display_name}
              disabled={!can_update}
              onChangeCheckboxValue={(event) =>
                onChange("use_display_name", event.target.checked)
              }
            />
          </div>
          {type_id === 1 && commission_type_id !== 3 && (
            <div className="at-form_field-col-12 d-flex flex-column mb-0">
              <AtlasCheckbox
                labelStyle={{ alignItems: "center" }}
                disabled={!can_update}
                checked={show_professional_fee_percentage}
                onChangeCheckboxValue={(event) =>
                  onChange(
                    "show_professional_fee_percentage",
                    event.target.checked,
                  )
                }
                content={
                  <>
                    Show Professional Fee Percentage
                    {show_professional_fee_percentage &&
                      commission_type_id !== 3 && (
                        <span className={"ml-3"}>
                          {" "}
                          Valid to{" "}
                          <input
                            style={{ width: 50, maxWidth: 100 }}
                            type={"number"}
                            className="agency-fees-input"
                            value={`${professional_fee_percentage}`}
                            onChange={(e) =>
                              onChange(
                                "professional_fee_percentage",
                                parseFloat(e.target.value),
                              )
                            }
                            disabled={!can_update}
                          />
                        </span>
                      )}
                  </>
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="at-form__content" style={{ borderRadius: "0.375rem" }}>
        <ModuleFormHeader
          title={"Co-Agent Info"}
          className={"mb-3"}
          moduleIcon={"atlas-user-tie"}
        />
        <div className="at-form_fields_cont">
          {conAgentInfo.map((item) => {
            return (
              <div className="at-form_field-col-6">
                <h2 className="at-form-input__title">{item.title}</h2>
                <CustomFormInput
                  type="text"
                  value={item.value}
                  onChangeValue={(val) => onChange(item.label, val)}
                  disabled={!can_update}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="d-flex mt-3">
        {can_update && (
          <button
            className={`btn-new btn-new--success mr-10`}
            onClick={() => onSave()}
          >
            Save
          </button>
        )}
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onClose()}
        >
          Close
        </button>
      </div>

      {onLoadCobrokeLetter && <LodaingModal />}
    </>
  );
};

export default TempCobrokeLetter;
