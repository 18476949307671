import React from "react";

import ModalDialog from "components/Modal/ModalDialog";

import "./index.scss";

const ESigningTips = ({ onClose }) => {
  return (
    <ModalDialog
      title={"e-Signing Process Explain"}
      onClose={onClose}
      children={
        <>
          <ul className={"at-tip__list mb-20"}>
            <li>
              The e-signing process begins by sending a link-embedded email to
              the first participant in the signing order.
            </li>
            <li>
              After the first participant has completed the e-signing, Atlas
              will then send the next email to the subsequent participants until
              the last participant signs.
            </li>
            <li>
              The process is complete when all involved parties have signed the
              contract. And Atlas will email all involved participants a digital
              copy of the signed contract.
            </li>
            <li>
              If one of the contract participants rejects the signing due to
              misaligned terms and conditions of the contract, or, if any
              circumstances occur to abort the case, the e-Signing process will
              be paused. And the following action may be taken accordingly,
            </li>
            <ul className="at-tip__list-sub">
              <li>
                Update/Change contract details: You may reset the status by
                clicking on “Reset e-Signing process”. Then, update the details
                and save the form accordingly before restarting the e-Signing
                process.
              </li>
              <li>
                Abort case: If both parties agreed to abort the case, you can
                start the e-signing process to authorise the refund/forfeit of
                the earnest deposit by clicking on “Abort the deal”.
              </li>
            </ul>
            <li>
              Semi e-Signing is a combination of both manual signing and
              electronic signing. It might happen if the participant is a senior
              or a person who may have trouble signing using a device, or simply
              wants to sign on a printed copy out of security.
            </li>
            <ul className="at-tip__list-sub">
              <li>
                If any of the contract participants request to ink-sign
                physically on a printed copy in the middle of the e-signing
                process, you can click on “Complete e-Signing Process” to end
                the e-signing process. Then, continue to download and print the
                partially signed form for the remaining participants to sign
                physically.
              </li>
            </ul>
          </ul>
          <h5 className="at-tip__list-title mb-10 mt-20">
            {"e-Signing Process Status: "}
          </h5>
          <div
            className="status-tip-div grid-control"
            style={{ gridGap: "5px 5px" }}
          >
            <p className="grid-half-col">
              <strong>Pending: </strong>
              Waiting for e-Signing email.
            </p>
            <p className="grid-half-col">
              <strong>Sent: </strong>
              e-Signing email has been sent.
            </p>
            <p className="grid-half-col">
              <strong>Signed: </strong>
              e-Signing has been adopted and signed.
            </p>
            <p className="grid-half-col">
              <strong>Declined: </strong>
              Declined to sign due to some reason.
            </p>
            <p className="grid-half-col">
              <strong>Completed: </strong>
              e-Signing process is completed.
            </p>
            <p className="grid-half-col">
              <strong>Manual Signing: </strong>
              To sign physically on the printed copy.
            </p>
          </div>
        </>
      }
      footer={
        <button
          className={"btn-new btn-new--outline-secondary"}
          children={"Close"}
          onClick={onClose}
        />
      }
    />
  );
};

export default ESigningTips;
