import React, { Component } from "react";
import _ from "lodash";

import ConfirmationModal from "components/Modal/delete";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Post, Get, Put, Delete } from "utils/axios";

const searchParamsPermittedItem = [
  {
    label: "Type Name",
    value: "type_id_eq",
    type: "radio",
    col: 6,
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "IQI Drive", value: "1" },
      { label: "Project Listing", value: "2" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      showCreatePermittedItem: false,
      showUpdatePermittedItem: false,

      loading: false,
      searchParamsPermittedItem: searchParamsPermittedItem,

      showConfirmationModal: false,

      selectedType: 1,
      newPermittedItem: {
        permission_group_id: this.props.selectedPermissionGroup.id,
        type_id: null,
        project_id: "",
        link: "",
      },
      selectedPermittedItem: {
        id: "",
        permission_group_id: "",
        type_id: "",
        project_id: null,
        link: "",
        type: "",
      },
      projectLists: [],
      agentLists: [],

      showConfirmDeleteModal: false,
      toRemoveID: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangePermittedItemsHOC = (key, val) => {
      this.setState({
        [key]: val,
      });
    };

    //Not API
    getSelectedPermittedItem = (val) => {
      this.setState({
        selectedPermittedItem: val,
      });
    };

    getProjectListings = (name) => {
      Get(
        `/projects/active_project_listings?is_searching=1&q[internal_name_cont]=${name}`,
        this.getProjectListingsSuccess,
        this.getProjectListingsError,
        this.load,
      );
    };
    getProjectListingsSuccess = ({ projects = [] }) => {
      let tmp = [];
      if (projects && projects.length > 0) {
        projects.map((item) => {
          tmp.push({
            id: item.id,
            label: item.internal_name,
            name: item.name,
            value: item.id,
          });
        });
      }
      this.setState({
        projectLists: tmp,
      });
    };
    getProjectListingsError = (errorMessage) => requestError(errorMessage);

    createPermittedItem = () => {
      const { selectedPermissionGroup } = this.props;
      let data = {
        ...this.state.newPermittedItem,
        permission_group_id: selectedPermissionGroup.id,
      };
      Post(
        `/admin/permission_groups/${selectedPermissionGroup.id}/permitted_items`,
        data,
        this.createPermittedItemSuccess,
        this.createPermittedItemError,
        this.load,
      );
    };
    createPermittedItemSuccess = () => {
      this.setState({ showCreatePermittedItem: false });
      requestSuccess("The permitted item is submitted successfully.");
      let tmpNewPermittedItem = {
        type_id: null,
        link: "",
        project_id: null,
      };
      this.onChangePermittedItemsHOC("newPermittedItem", tmpNewPermittedItem);

      let tmpSearchParam = "";
      this.state.searchParamsPermittedItem.map((item) => {
        tmpSearchParam = tmpSearchParam + `q[${item.value}]=${item.param}&`;
      });
      this.props.getPermittedItems(1, this.props.tmpSearchParam);
    };
    createPermittedItemError = (errorMessage) => requestError(errorMessage);

    updatePermittedItem = () => {
      let tmpPGID = _.cloneDeep(this.props.selectedPermissionGroup.id);
      let tmpPermittedItem = _.cloneDeep(this.state.selectedPermittedItem);
      let data = {
        project_id: null,
        link: "",
      };
      data.link = tmpPermittedItem.link;
      data.project_id = tmpPermittedItem.project_id;

      Put(
        `/admin/permission_groups/${tmpPGID}/permitted_items/${tmpPermittedItem.id}`,
        data,
        this.updatePermittedItemSuccess,
        this.updatePermittedItemError,
        this.load,
      );
    };
    updatePermittedItemSuccess = () => {
      let tmpSearchParam = "";
      this.state.searchParamsPermittedItem.map((item) => {
        tmpSearchParam = tmpSearchParam + `q[${item.value}]=${item.param}&`;
      });
      this.props.getPermittedItems(1, tmpSearchParam);
      this.setState({ showUpdatePermittedItem: false });
      requestSuccess("The permitted item is updated successfully.");
    };
    updatePermittedItemError = (errorMessage) => {
      requestError(errorMessage);
    };

    deletePermittedItem = () =>
      Delete(
        `/admin/permission_groups/${this.props.selectedPermissionGroup.id}/permitted_items/${this.state.toRemoveID}`,
        this.deletePermittedItemSuccess,
        this.deletePermittedItemError,
        this.load,
      );
    deletePermittedItemSuccess = (successMessage) => {
      let tmpSearchParam = "";
      this.state.searchParamsPermittedItem.map((item) => {
        tmpSearchParam = tmpSearchParam + `q[${item.value}]=${item.param}&`;
      });
      this.onChangePermittedItemsHOC("toRemoveID", "");
      this.props.getPermittedItems(1, tmpSearchParam);
      requestSuccess("The permitted item is deleted successfully.");
      this.onChangePermittedItemsHOC("showConfirmDeleteModal", false);
    };
    deletePermittedItemError = (errorMessage) => requestError(errorMessage);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadPermittedItem={this.state.loading}
            projectLists={this.state.projectLists}
            selectedType={this.state.selectedType}
            newPermittedItem={this.state.newPermittedItem}
            selectedPermittedItem={this.state.selectedPermittedItem}
            showCreatePermittedItem={this.state.showCreatePermittedItem}
            showUpdatePermittedItem={this.state.showUpdatePermittedItem}
            searchParamsPermittedItem={this.state.searchParamsPermittedItem}
            getProjectListings={this.getProjectListings}
            createPermittedItem={this.createPermittedItem}
            updatePermittedItem={this.updatePermittedItem}
            onChangePermittedItemsHOC={this.onChangePermittedItemsHOC}
          />

          <ConfirmationModal
            open={this.state.showConfirmDeleteModal}
            loading={this.state.loading}
            message={"Are you sure to remove the selected record?"}
            positiveAction={() => {
              this.deletePermittedItem();
            }}
            negativeAction={() =>
              this.setState({ showConfirmDeleteModal: false })
            }
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
