import React, { Component } from "react";
import _ from "lodash";
import { Put, Get } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      app_version: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeVersionHOC = (val, context) => this.setState({ [context]: val });

    getVersion = () => {
      Get(
        `/admin/app_versions/system`,
        this.getVersionSuccess,
        this.getVersionError,
        this.load,
      );
    };
    getVersionSuccess = ({ app_version }) => this.setState({ app_version });
    getVersionError = (error) => requestError(error);

    updateVersion = (val) =>
      Put(
        `/admin/app_versions/system`,
        { app_version: val },
        this.updateVersionSuccess,
        this.updateVersionError,
        this.load,
      );
    updateVersionSuccess = () => {
      requestSuccess("App version updated successfully.");
      this.getVersion();
    };
    updateVersionError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            app_version={this.state.app_version}
            onLoadVersion={this.state.loading}
            onChangeVersionHOC={this.onChangeVersionHOC}
            getVersion={this.getVersion}
            updateVersion={this.updateVersion}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
