import React, { Component, Fragment } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";

const PhotoHOC = (WrappedComponent) => {
  class PhotoWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showPhotoTabModal: false,

      subsalesPhotos: [],
      tempImgSrc: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangePhotoHOC = (val, context) => this.setState({ [context]: val });

    // get subsales photos for this listing
    getSubsalesPhotos = (id) =>
      Get(
        `/admin/sub_sales/${id}/photos?locale=${this.props.data.languageReducer.language}`,
        this.getSubsalesPhotosSuccess,
        this.getSubsalesPhotosError,
        this.load,
      );
    getSubsalesPhotosSuccess = (payload) => {
      let images = payload;
      images.sort(function (a, b) {
        return b.is_primary - a.is_primary;
      });
      this.setState({ subsalesPhotos: images });
    };
    getSubsalesPhotosError = (error) => requestError(error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            tempImgSrc={this.state.tempImgSrc}
            showPhotoTabModal={this.state.showPhotoTabModal}
            onLoadPhoto={this.state.loading}
            subsalesPhotos={this.state.subsalesPhotos}
            getSubsalesPhotos={this.getSubsalesPhotos}
            onChangePhotoHOC={this.onChangePhotoHOC}
          />
        </Fragment>
      );
    };
  }
  return PhotoWrappedComponent;
};

export default PhotoHOC;
