import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateDialog from "./components/Create";
import EditDialog from "./components/Edit";

import CouponMngmtHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

import "./index.scss";

export const StatusColor = [
  { value: "Yes", colorName: "Green" },
  { value: "No", colorName: "Red" },
];

const columnData = [
  {
    header: "Coupon Code",
    accessor: "code",
    columnStyle: {
      width: "70%",
    },
  },
  {
    header: "Agent",
    columnStyle: {
      width: "160%",
    },
    renderColumn: (rowData) => {
      let tmpAgentInfo = rowData.agent.split("\n");
      return tmpAgentInfo.map((item) => <p>{item}</p>);
    },
  },
  {
    header: "Product",
    accessor: "product",
    columnStyle: {
      width: "80%",
    },
  },
  {
    header: "Expiry",
    accessor: "expiry_date",
    columnStyle: {
      width: "70%",
    },
  },
  {
    header: "Redeemed",
    columnClass: "text-center",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: {
      width: "50%",
    },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.isUsed }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.isUsed}
        </div>
      );
    },
  },
];

class CouponMngmt extends Component {
  componentDidMount = () => this.props.getCouponMngmt(1, "");

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Atlas Store Coupons",
      this.props.data,
    );

    return (
      <AtPaginatedTable
        columns={columnData}
        actionColumnContent={
          can_update || can_destroy
            ? [
                {
                  name: "edit",
                  onShow: () => can_update,
                  disabled: (rowData) => rowData.is_used,
                  onClick: (rowData) =>
                    this.props.getSelectedCoupon(rowData.id),
                },
                {
                  name: "delete",
                  color: "#F04438",
                  disabled: (rowData) => rowData.is_used,
                  onShow: () => can_destroy,
                  onClick: (rowData) => {
                    this.props.onChangeCouponMngmtHOC(
                      rowData,
                      "selectedCoupon",
                    );
                    this.props.onChangeCouponMngmtHOC(
                      true,
                      "showDeleteConfirmation",
                    );
                  },
                },
              ]
            : []
        }
        rowData={this.props.coupons.data ? this.props.coupons.data : []}
        meta={this.props.coupons.meta && this.props.coupons.meta}
        onChangeSearchParams={(val) =>
          this.props.onChangeCouponMngmtHOC(val, "searchParams")
        }
        searchParams={this.props.searchParams}
        getListAPI={this.props.getCouponMngmt}
        totalPages={this.props.couponPage}
      />
    );
  };

  render = () => {
    const {
      createCoupon,
      updateCoupon,
      deleteCoupon,
      selectedCoupon,
      showEditDialog,
      showCreateDialog,
      onChangeCouponMngmtHOC,
      onLoadCoupon,
      showDeleteConfirmation,
    } = this.props;
    const { can_create } = permissionsChecker(
      "Atlas Store Coupons",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={`Coupons`}
          moduleIcon={"icon-coupons"}
          actionButton={[
            {
              label: "New Coupon",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeCouponMngmtHOC(true, "showCreateDialog"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog open={showCreateDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <CreateDialog
                onLoadCoupon={onLoadCoupon}
                createCoupon={createCoupon}
                onClose={() =>
                  onChangeCouponMngmtHOC(false, "showCreateDialog")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showEditDialog}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <EditDialog
                selectedCoupon={selectedCoupon}
                onLoadCoupon={onLoadCoupon}
                updateCoupon={updateCoupon}
                onClose={() => onChangeCouponMngmtHOC(false, "showEditDialog")}
              />
            }
          />
        </AtlasDialog>
        <ConfirmationModal
          title={"Delete Coupon"}
          loading={onLoadCoupon}
          open={showDeleteConfirmation}
          message={`Are you sure you want to remove this coupon ?`}
          positiveAction={() => deleteCoupon(selectedCoupon.id)}
          negativeAction={() =>
            onChangeCouponMngmtHOC(false, "showDeleteConfirmation")
          }
        />
        {onLoadCoupon && <LoadingModal />}
      </>
    );
  };
}

export default compose(CouponMngmtHOC, VersionCheckHOC)(CouponMngmt);
