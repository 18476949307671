import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { getCurrentUserProfile } from "actions/profile";
import { getAllDictionaryData } from "actions/dictionary";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { storeLastView } from "actions/lastView";

import { Get, Post, Put, GetFile } from "utils/axios";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Form Number",
    value: "transaction_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Tenant/Purchase Name",
    value: "tenant_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Submitted",
    value: "is_claimed_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const AuthorisationFormHOC = (WrappedComponent) => {
  class AuthorisationFormWrappedComponent extends Component {
    state = {
      authorisationForm: [],
      authorisationFormPage: null,
      selectedAuthorisationForm: {},
      disableSubmitButton: false,

      showSendEmail: false,
      showCreationForm: false,
      showEditAuthorisationForm: false,

      loading: false,

      searchParams: searchParams,
      showSubmitConfirmation: false,
      showSelectForm: false,

      createInvoiceId: null,
      createTypeId: null,
      tempEProcess: null,
    };

    componentDidMount = () =>
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();

    load = (param) => this.setState({ loading: param });

    onChangeAuthorisationHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeAuthorisationFormValue = (context, val) => {
      let tmp = _.cloneDeep(this.state.selectedAuthorisationForm);
      tmp[context] = val;
      return this.setState({ selectedAuthorisationForm: tmp });
    };

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmation: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    getAuthorisationForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/forms/authorisation_forms?${search}page=${page}`,
        this.getAuthorisationFormsSuccess,
        this.getAuthorisationFormsError,
        this.load,
      );
    };
    getAuthorisationFormsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.map((item) => {
          item.type = item.type_id === 1 ? "Sale" : "Rental";
        });
      this.setState({
        authorisationForm: payload,
        authorisationFormPage: tmpTotalPages,
      });
    };
    getAuthorisationFormsError = (error) => requestError(error);

    getSelectedAuthorisationForms = (id) =>
      Get(
        `/forms/authorisation_forms/${id}`,
        this.getSelectedAuthorisationFormsSuccess,
        this.getSelectedAuthorisationFormsError,
        this.load,
      );
    getSelectedAuthorisationFormsSuccess = (payload) => {
      const tmpIsLocked = _.some(payload?.forms_chart || [], form => {
        if(Array.isArray(form)) {
          return _.some(form || [], nestedForm => nestedForm.type === "Claim Form" && nestedForm.id !== null )
        } else {
          return form.type === "Claim Form" && form.id !== null
        }
      });
      // Only lock for editing the Appointment letter after agent submit for claim form

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_authorisation", "rent_authorisation"].find(
        (type) => {
          return tmpPayload[type] && tmpPayload[type] !== null;
        },
      );

      tmpPayload.payment_amount = parseFloat(tmpPayload?.payment_amount ?? 0)?.toFixed(2)
      tmpPayload.professional_fee = parseFloat(tmpPayload?.professional_fee ?? 0)?.toFixed(2)

      let tmpSelectedAuthorisationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        isLocked: tmpIsLocked,
        id: tmpPayload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmpSelectedAuthorisationForm.sale_authorisation;
      delete tmpSelectedAuthorisationForm.rent_authorisation;

      this.setState({
        tempEProcess: payload.e_process,
        selectedAuthorisationForm: tmpSelectedAuthorisationForm,
        showEditAuthorisationForm: true,
      });
    };
    getSelectedAuthorisationFormsError = (error) => requestError(error);

    createAuthorisationForm = (dataToSubmit) =>
      Post(
        `/forms/authorisation_forms`,
        dataToSubmit,
        this.createAuthorisationFormSuccess,
        this.createAuthorisationFormError,
        this.load,
      );

    createAuthorisationFormSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getAuthorisationForms(currentPage, searchParams);
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
      this.getSelectedAuthorisationForms(payload.id);

      requestSuccess("Authorisation form created successfully.");
      this.setState({
        showCreationForm: false,
        disableSubmitButton: false,
      });
    };

    createAuthorisationFormError = (error) => {
      requestError(error ? error : "");
      this.setState({ disableSubmitButton: false });
    };

    updateAuthorisationForm = () => {
      let tmp = _.cloneDeep(this.state.selectedAuthorisationForm);
      tmp.is_claimed = tmp.tmp_claimed;
      delete tmp.tmp_claimed;

      Put(
        `/forms/authorisation_forms/${this.state.selectedAuthorisationForm.id}`,
        tmp,
        this.updateAuthorisationFormSuccess,
        this.updateAuthorisationFormError,
        this.load,
      );
    };
    updateAuthorisationFormSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_authorisation", "rent_authorisation"].find(
        (type) => {
          return tmpPayload[type] && tmpPayload[type] !== null;
        },
      );
      let tmpSelectedAuthorisationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: tmpPayload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmpSelectedAuthorisationForm.sale_authorisation;
      delete tmpSelectedAuthorisationForm.rent_authorisation;

      this.setState({
        tempEProcess: payload.e_process,
        selectedAuthorisationForm: tmpSelectedAuthorisationForm,
      });

      this.getAuthorisationForms(currentPage, searchParams);
      requestSuccess("Authorisation form updated successfully.");
    };
    updateAuthorisationFormError = (error) => requestError(error);

    claimedAuthorisationForm = () =>
      Put(
        `/forms/authorisation_forms/${this.state.selectedAuthorisationForm.id}/toggle_is_claimed`,
        {},
        this.claimedAuthorisationFormSuccess,
        this.claimedAuthorisationFormError,
        this.load,
      );
    claimedAuthorisationFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      let tmp = {
        ...this.state.selectedAuthorisationForm,
        is_claimed: true,
        tmp_claimed: true,
      };
      this.onChangeAuthorisationHOC(tmp, "selectedAuthorisationForm");
      this.getAuthorisationForms(currentPage, searchParams);
      requestSuccess("Authorisation Form mark as claimed");
    };
    claimedAuthorisationFormError = (error) => {
      let tmp = {
        ...this.state.selectedAuthorisationForm,
        is_claimed: false,
        tmp_claimed: false,
      };
      this.onChangeAuthorisationHOC(tmp, "selectedAuthorisationForm");
      requestError(error);
    };

    downloadAuthorisationForm = (id, filename) =>
      GetFile(
        `/forms/authorisation_forms/${id}/download?with_data=true`,
        filename,
        () => {},
        this.downloadAuthorisationFormError,
        this.load,
      );
    downloadAuthorisationFormError = (error) => requestError(error);

    sendAuthorisationForm = (id, recipient) =>
      Post(
        `/forms/authorisation_forms/${id}/deliver`,
        recipient,
        this.sendAuthorisationFormSuccess,
        this.sendAuthorisationFormError,
        this.load,
      );
    sendAuthorisationFormSuccess = (payload) => {
      requestSuccess(payload.message);
      this.setState({ showSendEmail: false });
    };
    sendAuthorisationFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            tempEProcess={this.state.tempEProcess}
            createInvoiceId={this.state.createInvoiceId}
            createTypeId={this.state.createTypeId}
            showSendEmail={this.state.showSendEmail}
            searchParams={this.state.searchParams}
            authorisationFormPage={this.state.authorisationFormPage}
            authorisationForm={this.state.authorisationForm}
            selectedAuthorisationForm={this.state.selectedAuthorisationForm}
            showCreationForm={this.state.showCreationForm}
            disableSubmitButton={this.state.disableSubmitButton}
            showEditAuthorisationForm={this.state.showEditAuthorisationForm}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            showSelectForm={this.state.showSelectForm}
            onLoadAuthorisation={this.state.loading}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            onChangeAuthorisationHOC={this.onChangeAuthorisationHOC}
            getAuthorisationForms={this.getAuthorisationForms}
            getSelectedAuthorisationForms={this.getSelectedAuthorisationForms}
            createAuthorisationForm={this.createAuthorisationForm}
            updateAuthorisationForm={this.updateAuthorisationForm}
            claimedAuthorisationForm={this.claimedAuthorisationForm}
            downloadAuthorisationForm={this.downloadAuthorisationForm}
            onChangeAuthorisationFormValue={this.onChangeAuthorisationFormValue}
            sendAuthorisationForm={this.sendAuthorisationForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
    getAllDictionaryData,
    refreshToken,
    storeLastView,
  })(AuthorisationFormWrappedComponent);
};

export default AuthorisationFormHOC;
