import React, { useState } from "react";
import DefaultImage from "assets/images/av-male.jpg";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import TransactionContent from "./transaction";

import "./index.scss";
import "./transaction.scss";

export default function RecommendationCard({
  key,
  refProps,
  mode,
  status,
  status_id,
  transaction_info,
  full_name = "N/A",
  team_name = "N/A",
  email = "N/A",
  phone_number = "N/A",

  last_transacted = "N/A",
  total_transaction,
  showActionButton,
  avatar_url,

  onClickRequest,
}) {
  const [showTransactionInfo, onSetTransaction] = useState(false);

  return (
    <div
      key={key}
      ref={refProps}
      className={`at-recommendation-card ${mode === "grid" ? "at-recommendation-card-grid" : ""}`}
    >
      {!showTransactionInfo && (
        <>
          <div className="at-recommendation-card-transaction">
            <img
              class={"at-recommendation-card-transaction__avatar"}
              src={avatar_url || DefaultImage}
              onError={(e) => {
                if (e.target) {
                  e.target.src = DefaultImage;
                }
              }}
            />
            <div>
              <div className="at-recommendation-card__transaction-total">
                <h4 style={{ fontSize: total_transaction > 99 ? 18 : 20 }}>
                  {total_transaction}
                </h4>
                <div>
                  <p>Matched</p>
                  <p>Properties</p>
                </div>
              </div>
              <CustomButton
                className={"btn-new-sm btn-new--outline-secondary"}
                style={{ fontSize: 12 }}
                onClick={() => onSetTransaction(true)}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-house-2"}
                      style={{ width: 14, height: 14, marginRight: 10 }}
                    />
                    Transactions
                    <AtlasIcon
                      svgHref={"atlas-arrow-right-1-linear"}
                      style={{
                        width: 14,
                        height: 14,
                        fill: "#3B82F6",
                        marginLeft: 10,
                      }}
                    />
                  </>
                }
              />
            </div>
          </div>
          <div className="at-recommendation-card-content">
            <div className="at-form-input__title">{full_name}</div>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-profile2user"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {team_name}
            </div>
            <div style={{ display: status_id === 3 ? "initial" : "none" }}>
              <div className="at-table__text-ellipsis fs-2">
                <AtlasIcon
                  svgHref={"atlas-sms"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 10,
                  }}
                />
                <span>{email}</span>
                <AtlasIcon
                  svgHref={"atlas-sms-edit"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#3B82F6" }}
                  onClick={() => (window.location = `mailto:${email}`)}
                />
              </div>
              <div className="at-table__text-ellipsis fs-2">
                <AtlasIcon
                  svgHref={"atlas-call"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 10,
                  }}
                />
                <span>{phone_number}</span>
                <AtlasIcon
                  svgHref={"atlas-whatsapp"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#12B76A" }}
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${phone_number}`,
                    )
                  }
                />
                <AtlasIcon
                  svgHref={"atlas-call"}
                  className={"btn-new--social-media"}
                  style={{ fill: "#3B82F6" }}
                  onClick={() => window.open(`tel:${phone_number}`)}
                />
              </div>
            </div>
            <div className="fs-2">
              <AtlasIcon
                svgHref={"atlas-calendar-2"}
                style={{
                  width: 16,
                  height: 16,
                  fill: "#F0631D",
                  marginRight: 10,
                }}
              />
              {last_transacted}
            </div>
          </div>
          <div className="mt-auto">
            {[1, 2].indexOf(status_id) > -1 && (
              <>
                {showActionButton && (
                  <button
                    disabled={status_id === 2}
                    className={`w-100 btn-new ${status_id === 1 ? "btn-new--secondary" : "btn-new--outline-secondary"}`}
                    onClick={() => onClickRequest()}
                  >
                    <AtlasIcon
                      svgHref={"atlas-shake-hand"}
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        fill: status_id === 1 ? "white" : "#9CA3AF",
                      }}
                    />
                    {status_id === 1 ? "Send Request" : "Request Sent"}
                  </button>
                )}
                {!showActionButton && <span>{`Request Not Sent`}</span>}
              </>
            )}
            {[1, 2].indexOf(status_id) < 0 && (
              <p
                className="at-recommendation-card-status"
                style={{ color: status_id === 3 ? "#039855" : "#D92D20" }}
              >
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 10,
                    fill: status_id === 3 ? "#039855" : "#D92D20",
                  }}
                />
                {`Request ${status}`}
              </p>
            )}
          </div>
        </>
      )}
      {showTransactionInfo && (
        <TransactionContent
          full_name={full_name}
          transaction_info={transaction_info}
          onSetTransaction={onSetTransaction}
        />
      )}
    </div>
  );
}
