import React, { useEffect } from "react";
import { BiTable } from "react-icons/bi";

import AtPaginatedTable from "components/NewPaginatedTable";
import ReserveModal from "containers/StoreyPlan/Reservation";
import AtlasDialog from "components/Dialog";
import CustomCard from "components/Card";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import UnitDetailModal from "./unitDetail";

import UnitsHOC from "../../actions/units";
import permissionsChecker from "utils/permissionsChecker";
import { numberWithCommas } from "utils/thousandSeparator";

const columnData = (getSelectedUnit, selectedProject) => [
  {
    header: "Unit",
    searchFlag: "title",
    renderColumn: (rowData) => (
      <button
        style={{
          color: "orange",
          fontWeight: 600,
          padding: 5,
        }}
        onClick={() => getSelectedUnit(rowData?.id)}
      >
        {rowData?.title}
      </button>
    ),
  },
  {
    header: `Selling Price (${selectedProject.net_price_currency})`,
    searchFlag: "price",
    renderColumn: ({ price }) => {
      return numberWithCommas(price);
    },
  },
  {
    header: "Size",
    searchFlag: "floor_size",
    renderColumn: ({ floor_size, floor_size_unit }) => {
      return floor_size + floor_size_unit;
    },
  },
  {
    header: "Status",
    searchFlag: "reservation_status",
    renderColumn: ({ reservations_attributes }) => {
      switch (reservations_attributes.status) {
        case "Available":
          return (
            <p style={{ color: "#57cf57", fontSize: 14, fontWeight: 600 }}>
              {reservations_attributes?.status}
            </p>
          );
        case "Reserved":
          return (
            <p style={{ color: "#ffdf05", fontSize: 14, fontWeight: 600 }}>
              {reservations_attributes?.status}
            </p>
          );
        case "Booked":
          return (
            <p style={{ color: "#ff6767", fontSize: 14, fontWeight: 600 }}>
              {"Sold"}
            </p>
          );
        case "Sold":
          return (
            <p style={{ color: "#ff6767", fontSize: 14, fontWeight: 600 }}>
              {reservations_attributes?.status}
            </p>
          );
        default:
          return (
            <p style={{ color: "#878787", fontSize: 14, fontWeight: 600 }}>
              {reservations_attributes?.status}
            </p>
          );
      }
    },
    columnStyle: { width: "70%" },
  },
];

const tab = [
  { label: "All", color: "#d7d7d7" },
  { label: "Available", color: "#91e191" },
  { label: "Reserved", color: "#fff190" },
  { label: "Sold", color: "#ff6767" },
  { label: "My Units", color: "#ffe4b3" },
];

const calculateTimeLimit = (setting) => {
  let multiplier = 0;
  switch (setting.unit) {
    case "seconds":
      multiplier = 1000;
      break;
    case "minutes":
      multiplier = 60000;
      break;
    case "hours":
      multiplier = 3600000;
      break;
    default:
      break;
  }
  return Number(setting.time) * multiplier;
};

const ProjectUnits = ({
  data,
  onLoadUnit,
  units,
  unitsPage,
  showReserveModal,
  showUnitDetailModal,
  selectedUnit,
  selectedTab,
  available_unit,
  reserved_unit,
  total_unit,
  sold,
  my_units,
  selectedProject,
  selectedReservation,
  progress,
  searchParams,
  getProjectUnits,
  getReservationForm,
  createReservation,
  putReservation,
  getReservation,
  uploadPaymentSlip,
  getLiveSales,
  establishConnection,
  getSelectedUnit,
  onChangeUnitHOC,
}) => {
  const { can_create, can_read } = permissionsChecker(
    "Projects - Reservations",
    data
  );
  const { role, id, superior_id } = window.location.href.includes(
    "/admin-impersonate"
  )
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  useEffect(() => {
    establishConnection();
    getProjectUnits(1, "");
    getLiveSales();
  }, []);

  const returnValue = (val) => {
    switch (val) {
      case "All":
        return total_unit;
      case "Available":
        return available_unit;
      case "Reserved":
        return reserved_unit;
      case "Sold":
        return sold;
      case "My Units":
        return my_units;
      default:
        break;
    }
  };

  const onClickTab = (val) => {
    let search = "";
    if (val === "Available") {
      search = `q[reservation_status_in]=Available&`;
    } else if (val === "Sold") {
      search = `q[reservation_status_in][]=Booked&q[reservation_status_in][]=Sold&`;
    } else if (val === "My Units") {
      search = `q[reservation_status_in][]=Sold&q[reservation_status_in][]=Reserved&q[reservation_status_in][]=Booked&q[reservations_agent_id_eq]=${role === "Personal Assistant" ? superior_id : id}&`;
    } else if (val !== "All") {
      search = `q[reservation_status_eq]=${val}&`;
    }
    let tmp = search;
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp +
              `q[${item.value}]=${item.type === "typeahead" ? item.param.name : item.param}&`
            : tmp + `q${item.param}`
          : tmp;
    });
    onChangeUnitHOC("selectedTab", val);
    getProjectUnits(1, tmp);
  };

  const reservationPermission = (rowData) => {
    let isTimeOut = false;
    let reservationAgent = role === "Personal Assistant" ? superior_id : id;
    if (rowData.reservations_attributes.status === "Reserved") {
      if (
        selectedProject.reservation_time_limit &&
        selectedProject.reservation_time_limit.settings.has_time_limit
      ) {
        let timeLimit = calculateTimeLimit(
          rowData.reservations_attributes.has_submitted_payment
            ? selectedProject.reservation_time_limit.settings.buyer_info
            : selectedProject.reservation_time_limit.settings.payment_slip
        );
        let reserveTime = new Date(
          rowData.reservations_attributes
            ? rowData.reservations_attributes.reservation_time
            : rowData.reservation_date
        );
        let difference = reserveTime - +new Date();
        difference = timeLimit + difference;
        if (difference > 0) isTimeOut = false;
        else isTimeOut = true;
      }
    }

    if (
      role !== "Super Admin" &&
      can_create &&
      !isTimeOut &&
      (rowData.reservations_attributes.status === "Available" ||
        (rowData.reservations_attributes.status === "Reserved" &&
          rowData.reservations_attributes.agent_id === reservationAgent))
    )
      return false;
    else return true;
  };

  const renderExtraContent = () => (
    <div className={`at-table_heading`}>
      <div className="d-flex flex-wrap">
        <div className="at-project-unit__tab-cont">
          {tab.map((item, index) => (
            <button
              className={`at-project-unit__tab ${selectedTab === item.label && "at-project-unit__tab-selected"}`}
              onClick={() => onClickTab(item.label)}
              style={{ backgroundColor: item.color }}
            >
              <p style={{ fontSize: 12 }}>{item.label}</p>
              <span>{returnValue(item.label)}</span>
            </button>
          ))}
        </div>
        <CustomButton
          containerClass={"at-project-unit__storey-plan"}
          className={"btn"}
          disable={can_create}
          children={
            <>
              <BiTable style={{ width: 18, height: 18, marginRight: 10 }} />
              Storey Plan
            </>
          }
          onClick={() => {
            window.location.href.includes("/admin-impersonate")
              ? window.open(
                  `#/admin-impersonate/${id}/storey-plan?project_id=${selectedProject.id}`,
                  "_blank"
                )
              : window.open(
                  `#/storey-plan?project_id=${selectedProject.id}`,
                  "_blank"
                );
          }}
        />
      </div>
    </div>
  );

  const renderHelpText = () => (
    <div className="at-project-unit__help-text mb-4">
      <p className="at-card__description">
        All the units in this development and their associated status is shown
        in the live sales table. If you prefer a sales chart/storey plan view,
        click on the 'Storey Plan' button (
        <BiTable style={{ width: 18, height: 18, marginRight: 10 }} />
        Storey Plan).
      </p>
      <br />
      <p className="at-card__description">
        Ready to book a unit? Get started by selecting the unit below. You can
        use the search and filter feature to narrow down the selection.
      </p>
      <br />
      <p className="at-card__description">
        To reserve a unit, do the following:
      </p>
      <br />
      <ul>
        <li>Click on the unit that your client/s has selected.</li>
        <li>
          Some projects allow you to quickly reserve a unit with proof of
          payment of the booking fee. If this project allows it, upload the
          proof of payment such as a good quality image of the cheque, bank-in
          slip, etc.{" "}
        </li>
        <li>
          You may need to complete the booking by providing full information
          about the buyers within a specific time-limit. A timer will indicate
          how much time is left for the booking to be completed afterwhich the
          reservation will be released.
        </li>
      </ul>
    </div>
  );

  return (
    <>
      {renderHelpText()}
      <AtPaginatedTable
        rowData={units?.data ?? []}
        meta={units?.meta ?? null}
        columns={columnData(getSelectedUnit, selectedProject)}
        actionColumnContent={[
          {
            name: "reserve",
            onShow: (rowData) =>
              ["Available", "Reserved"].includes(
                rowData.reservations_attributes?.status
              ),
            disabled: (rowData) => reservationPermission(rowData),
            onClick: (rowData) => getSelectedUnit(rowData.id, "reservation"),
          },
          {
            name: "download",
            onShow: (rowData) =>
              ["Booked", "Sold"].includes(
                rowData.reservations_attributes?.status
              ),
            disabled: (rowData) => {
              let reservationAgent =
                role === "Personal Assistant" ? superior_id : id;
              if (
                rowData.reservations_attributes?.agent_id ===
                  reservationAgent &&
                can_read
              )
                return false;
              else if (role === "Super Admin") return false;
              else if (role === "Project Manager" && can_read) return false;
              else return true;
            },
            onClick: (rowData) =>
              getReservationForm(
                rowData.id,
                rowData.reservations_attributes?.id,
                rowData.unit_no
              ),
          },
        ]}
        enableSort={true}
        renderExtraContent={() => renderExtraContent()}
        searchParams={searchParams}
        onChangeSearchParams={(val) => onChangeUnitHOC("searchParams", val)}
        getListAPI={getProjectUnits}
        totalPages={unitsPage}
      />

      <AtlasDialog open={showUnitDetailModal}>
        <CustomCard
          className={"h-100"}
          cardContent={
            <UnitDetailModal
              data={data}
              selectedUnit={selectedUnit}
              selectedProject={selectedProject}
              selectedReservation={selectedReservation}
              getReservation={getReservation}
              onClose={() => onChangeUnitHOC("showUnitDetailModal", false)}
            />
          }
        />
      </AtlasDialog>
      <AtlasDialog open={showReserveModal}>
        <ReserveModal
          data={data}
          onLoad={onLoadUnit}
          progress={progress}
          selectedProject={selectedProject}
          selectedUnit={selectedUnit}
          selectedReservation={selectedReservation}
          putReservation={putReservation}
          uploadPaymentSlip={uploadPaymentSlip}
          createReservation={createReservation}
          getReservation={getReservation}
          onChangeStoreyPlanHOC={onChangeUnitHOC}
          getProjectUnits={() => {
            const { currentPage, searchParams } = data.lastViewReducer.lastView;
            getProjectUnits(currentPage, searchParams);
          }}
          onClose={() => onChangeUnitHOC("showReserveModal", false)}
        />
      </AtlasDialog>
      {onLoadUnit && <LoadingModal />}
    </>
  );
};

export default UnitsHOC(ProjectUnits);
