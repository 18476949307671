import Moment from "moment";

import UserInfo from "components/NewPaginatedTable/components/UserInfo";

export const actionList = [
  { label: "APPOINTMENT LETTERS LIST", name: 18 },
  { label: "APPOINTMENT LETTERS UNCLAIMED", name: 14 },
  { label: "APPOINTMENT LETTER VIEW", name: 27 },
  { label: "ASSIGN LEADS", name: 39 },
  { label: "ATLAS GPT LISTING DESCRIPTION", name: 59 },
  { label: "AUDIO QUOTES VIEW", name: 5 },
  { label: "CONFIRMATION FORMS LIST", name: 11 },
  { label: "CONFIRMATION FORMS VIEW", name: 21 },
  { label: "CONFIRMATION FORM DOWNLOAD", name: 30 },
  { label: "CONTACTS SEARCH", name: 15 },
  { label: "CO BROKE REQUEST CONCLUDE DEAL", name: 35 },
  { label: "CO BROKE REQUEST DOWNLOAD AGREEMENT", name: 37 },
  { label: "CREATE APPOINTMENT LETTER", name: 34 },
  { label: "CREATE CONFIRMATION FORM", name: 33 },
  { label: "CREATE CO BROKE REQUEST", name: 36 },
  { label: "CREATE REFUND FORMS", name: 38 },
  { label: "CREATE SUB SALE CLAIMS", name: 17 },
  { label: "CREATE VIDEO REQUEST", name: 44 },
  { label: "DOWNLOADED VIDEO", name: 45 },
  { label: "EVENTS LIST", name: 22 },
  { label: "EVENT VIEW", name: 31 },
  { label: "EXAMS LIST", name: 16 },
  { label: "FB LEAD FORM BINDING WITH ATLAS", name: 60 },
  { label: "GRAPHIC REQUESTS LIST", name: 51 },
  { label: "GRAPHIC REQUEST CREATE", name: 52 },
  { label: "GRAPHIC REQUEST UPDATE", name: 53 },
  { label: "GRAPHIC REQUEST DOWNLOAD COMPLETED", name: 55 },
  { label: "LEADS LIST", name: 32 },
  { label: "LEADS VIEW", name: 41 },
  { label: "LEADS COMING FROM FACEBOOK", name: 61 },
  { label: "LIVE SALES CHART", name: 54 },
  { label: "MY MARKET CARTS", name: 3 },
  { label: "NEWSFEED LIST", name: 4 },
  { label: "NEWSFEED VIEW", name: 58 },
  { label: "NEXTCLOUD DIRECTORIES LIST", name: 8 },
  { label: "NEXTCLOUD DIRECTORIES SEARCH", name: 24 },
  { label: "NEXTCLOUD DIRECTORIES VIEW", name: 1 },
  { label: "NEXTCLOUD FAVOURITE DIRECTORIES", name: 19 },
  { label: "PAYMENT VOUCHERS LIST", name: 9 },
  { label: "PAYMENT VOUCHER VIEW", name: 26 },
  { label: "PRESENTATIONS LIST", name: 46 },
  { label: "PRESENTATION VIEW", name: 47 },
  { label: "PRESENTATION UPDATE", name: 48 },
  { label: "PRESENTATION CREATE", name: 49 },
  { label: "PRESENTATION DELIVER", name: 50 },
  { label: "PROFILES VIEW", name: 2 },
  { label: "PROJECTS LIST", name: 10 },
  { label: "PROJECT SALE CYCLE UPDATE", name: 43 },
  { label: "PROJECTS VIEW", name: 28 },
  { label: "QR DOOR ENTRY VERIFY AGENT", name: 40 },
  { label: "SALES DATE SUMMARY", name: 6 },
  { label: "SEARCH PROJECT PARTNER", name: 42 },
  { label: "SUB SALES CLAIMS VIEW", name: 12 },
  { label: "SUB SALES LIST", name: 23 },
  { label: "SUB SALES VIEW", name: 29 },
  { label: "SUB SALE CLAIMS LIST", name: 7 },
  { label: "TEAM MEMBERS LIST", name: 25 },
  { label: "TRAININGS LIST", name: 13 },
  { label: "TRAINING REGISTRATIONS", name: 20 },
  { label: "WEB PRESENTATION VIEW", name: 56 },
  { label: "WEB PRESENTATION ENQUIRY", name: 57 },
];

export const columnData = [
  {
    header: "Admin",
    columnStyle: { width: "140%" },
    customClass: "mb-5",
    searchFlag: "agent",
    renderColumn: (rowData) => (
      <UserInfo
        email={rowData.impersonator_email || "N/A"}
        hideRedirect={true}
      />
    ),
  },
  {
    header: "Agent",
    columnStyle: { width: "140%" },
    customClass: "mb-5",
    searchFlag: "agent",
    renderColumn: (rowData) => (
      <UserInfo
        name={rowData.name || "N/A"}
        team_name={rowData.team || "N/A"}
        email={rowData.email || "N/A"}
        location={rowData.branch || "N/A"}
        phone_number={rowData.mobile_number || "N/A"}
        hideRedirect={true}
      />
    ),
  },
  {
    header: "Activity",
    accessor: "activity",
  },
  {
    header: "Date Time",
    renderColumn: (rowData) =>
      rowData.action_at
        ? Moment(rowData.action_at).format("DD MMM YYYY hh:mm a")
        : "N/A",
  },
];
