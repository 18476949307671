import React from "react";

import AtlasCloseButton from "components/Button/close";
import { Dialog } from "@material-ui/core";

import iqiSubsalePreviewImg from "assets/images/subsaleImage/subsalesListingPreview.jpg";
import juwaiSubsalePreviewImg from "assets/images/juwaiImages/subsalesListingPreview.jpg";
import juwaiAsiaSubsalePreviewImg from "assets/images/juwaiAsiaImages/subsalesListingPreview.png";
import edgeSubsalePreviewImg from "assets/images/edgeImages/subsalesListingPreview.png";

const SubsalesListingPreview = ({
  showSubsalesListingPreviewModal,
  listingPreviewType,

  onChangeSubsalesHOC,
}) => {
  const renderPreviewImage = () => {
    switch (listingPreviewType) {
      case "IQI":
        return iqiSubsalePreviewImg;
      case "JUWAI":
        return juwaiSubsalePreviewImg;
      case "JUWAIASIA":
        return juwaiAsiaSubsalePreviewImg;
      case "EDGE":
        return edgeSubsalePreviewImg;
    }
  };

  return (
    <Dialog
      open={showSubsalesListingPreviewModal}
      fullWidth={true}
      onClose={() =>
        onChangeSubsalesHOC(false, "showSubsalesListingPreviewModal")
      }
    >
      <AtlasCloseButton
        containerStyle={{ position: "absolute", right: 15, top: 15 }}
        onClick={() =>
          onChangeSubsalesHOC(false, "showSubsalesListingPreviewModal")
        }
      />
      <img
        src={renderPreviewImage()}
        style={{ width: "100%", height: "100%" }}
      />
    </Dialog>
  );
};

export default SubsalesListingPreview;
