import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { Get, Delete, Post, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Full Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateModal: false,
      showDeleteModal: false,
      showUpdateModal: false,

      searchParams: searchParams,

      subsalesAccessibilityLists: [],
      subsalesAccessibilityPages: null,
      selectedAgentAccessibility: {},
      selectedAgentId: null,
      query: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeSubsalesAccessibilityHOC = (val, context) =>
      this.setState({ [context]: val });

    // get all subsales accessibility
    getSubsalesAccessibility = (page, query) => {
      let temp = {
        currentPage: page,
        searchParams: query,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/sub_sale_accessibilities?${query}page=${page}`,
        this.getSubsalesAccessibilitySuccess,
        this.getSubsalesAccessibilityError,
        this.load,
      );
    };
    getSubsalesAccessibilitySuccess = (payload) => {
      let tmpTotalPages = [];
      let tempPayloadData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data.length > 0 &&
        payload.data.map((item) => {
          tempPayloadData.push({
            ...item,
            created_datetime: item.created_datetime
              ? Moment(item.created_datetime, "DD-MM-YYYY, LT").format(
                  "DD MMMM YYYY[\n]LT",
                )
              : "N/A",
            expiry_date: item.expiry_date
              ? Moment(item.expiry_date, "YYYY-MM-DD").format("DD MMMM YYYY")
              : "N/A",
            agent_team_name: item.agent_team.display_name,
          });
        });
      payload.data = tempPayloadData;

      this.setState({
        subsalesAccessibilityLists: payload,
        subsalesAccessibilityPages: tmpTotalPages,
      });
    };
    getSubsalesAccessibilityError = (error) => requestError(error);

    // delete selected subsales accessibility
    deleteSubsalesAccessibility = (id) =>
      Delete(
        `/admin/sub_sale_accessibilities/${id}`,
        this.deleteSubsalesAccessibilitySuccess,
        this.deleteSubsalesAccessibilityError,
        this.load,
      );
    deleteSubsalesAccessibilitySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getSubsalesAccessibility(currentPage, searchParams);
      this.setState({ showDeleteModal: false });
      requestSuccess(payload.message);
    };
    deleteSubsalesAccessibilityError = (error) => requestError(error);

    // createnew  subsales accessibility
    createSubsalesAccessibility = (agent, date) =>
      Post(
        `/admin/sub_sale_accessibilities`,
        {
          user_id: agent.id,
          expiry_date: date,
        },
        this.createSubsalesAccessibilitySuccess,
        this.createSubsalesAccessibilityError,
        this.load,
      );
    createSubsalesAccessibilitySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAccessibility(currentPage, searchParams);
      this.setState({ showCreateModal: false });
      requestSuccess(payload.message);
    };
    createSubsalesAccessibilityError = (error) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAccessibility(currentPage, searchParams);
      requestError(error);
    };

    updateSubsalesAccessibility = (id, dataToSubmit) =>
      Put(
        `/admin/sub_sale_accessibilities/${id}`,
        dataToSubmit,
        this.updateSubsalesAccessibilitySuccess,
        this.updateSubsalesAccessibilityError,
        this.load,
      );
    updateSubsalesAccessibilitySuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAccessibility(currentPage, searchParams);
      this.setState({ showUpdateModal: false });
      requestSuccess("The sale accessibility has been updated successfully");
    };
    updateSubsalesAccessibilityError = (error) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAccessibility(currentPage, searchParams);
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedAgentId={this.state.selectedAgentId}
            showCreateModal={this.state.showCreateModal}
            showUpdateModal={this.state.showUpdateModal}
            showDeleteModal={this.state.showDeleteModal}
            searchParams={this.state.searchParams}
            onLoadSubsalesAccessibility={this.state.loading}
            selectedAgentAccessibility={this.state.selectedAgentAccessibility}
            subsalesAccessibilityPages={this.state.subsalesAccessibilityPages}
            subsalesAccessibilityLists={this.state.subsalesAccessibilityLists}
            onChangeSubsalesAccessibilityHOC={
              this.onChangeSubsalesAccessibilityHOC
            }
            deleteSubsalesAccessibility={this.deleteSubsalesAccessibility}
            createSubsalesAccessibility={this.createSubsalesAccessibility}
            updateSubsalesAccessibility={this.updateSubsalesAccessibility}
            getSubsalesAccessibility={this.getSubsalesAccessibility}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
