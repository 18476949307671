import React, { useEffect } from "react";
import { Dialog, Tooltip } from "@material-ui/core";
import { AiOutlineDownload } from "react-icons/ai";

import AtlasCloseButton from "components/Button/close";
import LoadingModal from "components/LoadingModal";
import CustomCard from "components/Card";
import ViewSDKClient from "components/ViewSDKClient";

const Preview = ({
  showPreviewModal,
  onClosePreviewModal,
  onDownloadFile,
  selectedFile,
  downloading,
}) => {
  useEffect(() => {
    if (selectedFile.file_type === ".pdf") {
      const viewSDKClient = new ViewSDKClient({
        ...selectedFile,
        url: selectedFile.link,
      });
      viewSDKClient.ready().then(() => {
        /* Invoke file preview */
        /* By default the embed mode will be Full Window */
        viewSDKClient.previewFile("pdf-div", {});
      });
    }
  }, [selectedFile]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      fullScreen={true}
      open={showPreviewModal}
    >
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <style>
              {`
                html,
                body {
                    overscroll-behavior-y: contain;
                }
              `}
            </style>
            {downloading && (
              <LoadingModal
                message={`Please wait for the downloading to complete. \n Downloading file can take a while, depending on the file's size and internet connection speed.`}
              />
            )}
            <AtlasCloseButton
              containerStyle={{
                position: "absolute",
                marginTop: -10,
                right: 20,
              }}
              onClick={onClosePreviewModal}
            />
            {selectedFile.file_type !== ".pdf" && (
              <>
                <Tooltip
                  classes={{ tooltipPlacementTop: "at-drive-tooltip" }}
                  placement={"top"}
                  title={"Download"}
                >
                  <button
                    className="btn"
                    style={{
                      position: "absolute",
                      marginTop: -10,
                      right: "70px",
                      height: "2rem",
                      padding: "0.2rem 0.6rem",
                    }}
                    onClick={onDownloadFile}
                  >
                    <AiOutlineDownload />
                  </button>
                </Tooltip>
                <iframe
                  src={selectedFile.url}
                  title={selectedFile.display_name}
                  style={{
                    width: "100%",
                    height: "90vh",
                    marginTop: 30,
                  }}
                  onLoad={(e) => {
                    const style = document.createElement("style");
                    style.textContent = `
                      img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                      }
                    `;
                    e.target.contentDocument.head.appendChild(style);
                  }}
                ></iframe>
              </>
            )}
            {selectedFile.file_type === ".pdf" && (
              <div
                id="pdf-div"
                style={{ marginTop: "2rem", height: "93vh" }}
                className="full-window-div mx-n3"
              />
            )}
          </>
        }
      />
    </Dialog>
  );
};

export default Preview;
