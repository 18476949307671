import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";

const SettingForm = props => {
  const [formData, setFormData] = useState({
    language_name: "",
    language_code: "",
    status_id: 1,
  })

  useEffect(() => {
    if (props.selectedLanguage) {
      setFormData({
        language_name: props.selectedLanguage.language_name,
        language_code: props.selectedLanguage.language_code,
        status_id: props.selectedLanguage.status_id,
      })
    }
  }, [props.selectedLanguage])

  const onToggleSubmit = () => {
    if (props.selectedLanguage) {
      props.updateLocalisedSetting(formData)
    } else {
      props.createLocalisedSetting(formData)
    }
  }

  return (
    <ModalDialog
      title={props.selectedLanguage ? "Edit Language" : "New Language"}
      onLoad={props.onLoadLocalisedSetting}
      onClose={() => props.onClose()}
      children={
        <div className='grid-control'>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Name</h2>
            <CustomFormInput
              required={true}
              placeholder='Name'
              value={formData.language_name}
              onChangeValue={(val) => setFormData({ ...formData, language_name: val })} />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Language Code</h2>
            <CustomFormInput
              required={true}
              placeholder='Language Code'
              value={formData.language_code}
              onChangeValue={(val) => setFormData({ ...formData, language_code: val })} />
          </section>
          <section className='grid-full-col'>
            <h2 className='at-form-input__title' required>Language Code</h2>
            <CustomRadioGroup
              checkedValue={formData.status_id}
              options={[
                { label: "Active", value: 1 },
                { label: "Inactive", value: 2 },
              ]}
              selectedRadioValue={(val) => setFormData({ ...formData, status_id: val })}/>
          </section>
        </div>
      }
      footer={
        <div className='d-flex align-items-center g-3'>
          <button
            className={`btn-new btn-new--${props.selectedLanguage ? "success" : "primary"}`}
            disabled={_.values(formData).some(val => val === "")}
            onClick={() => onToggleSubmit()}>
            {props.selectedLanguage ? "Update" : "Create"}
          </button>
          <button
            className='btn-new btn-new--outline-secondary'
            onClick={() => props.onClose()}>
            {props.selectedLanguage ? "Cancel" : "Close"}
          </button>
        </div>
      } />
  )
}

export default SettingForm