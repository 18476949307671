import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";

import AlertBox from "components/AlertBox";
import AtlasFormInput from "components/Input/formInput";
import { oppositeInfoStatusBadges } from "containers/Dashboard/SubsalesClaims/assets"
import CustomShareButton from "components/Share/buttons";

import { getColorBadge } from "dictionary/badgeColor";
import { requestSuccess } from "utils/requestHandler";

import TownShipHOC from "actions/township";

const FormDialog = ({
  representative_title,
  loading,
  infoPassword,
  onChangeInfoPassword,
  opposite_info_status,
  opposite_info_link,
  selected_claim,
  updateOppositeInfoProvided,
  onToggleDialog,
  onClickShareEmail,
}) => {
  const lowerCaseRepresentativeTitle = representative_title?.toLowerCase() ?? "N/A"
  const { colorName = "" } =
  _.find(
    oppositeInfoStatusBadges,
    item => item.value === opposite_info_status,
  ) || {};
  
  return (
    <>
      <ModalDialog
        title={`Share Link to Representative`}
        onClose={() => onToggleDialog(false)}
        children={
          <>
            {
              opposite_info_status !== null && (
                <div className="at-form-input__title fw-500 mb-3">
                  <p className="at-form-info__title">Update Status</p>
                  <div>
                    <span
                      style={{ ...getColorBadge(colorName) }}
                      className={`at-status_badge`}
                    >
                      {opposite_info_status}
                    </span>
                  </div>
                </div>
              )
            }
            <p></p>
            <div className="at-form__content">
              <ol start="1" className="pl-4">
                <li>
                  <p className="fs-2">A link will be generated to be sent to the {lowerCaseRepresentativeTitle} or the representative.</p>
                  <p className="fs-2">Before that you will need to set a password to protect the link.</p>
                </li>
              </ol>
              <div className="d-flex flex-wrap grid_gap-1 align-items-end">
                <div>
                  <h2 className={`at-form-input__title`}>Password</h2>
                  <AtlasFormInput
                    type="text"
                    containerStyle={{ width: 300 }}
                    placeholder="Minimum 6 characters "
                    value={`${infoPassword || ""}`}
                    onChangeValue={(val) => onChangeInfoPassword(val)}
                  />
                </div>
                <button className="btn-new btn-new--success" 
                  onClick={()=> {
                    updateOppositeInfoProvided( selected_claim.id,{
                      info_provided_by_opposite_agent: true,
                      opposite_info_password: infoPassword
                    })
                  }}>Update</button>
                <button 
                  className="btn-new btn-new--secondary" 
                  onClick={()=> {
                    navigator.clipboard.writeText(infoPassword);
                    requestSuccess("Copied successfully")
                  }}>
                  Copy
                </button>
              </div>
            </div>
            <div className="at-form__content">
              <ol start="2" className="pl-4">
                <li>
                  <p className="fs-2">Share the link to your {lowerCaseRepresentativeTitle} or the representative via your preferred method.</p>
                  <p className="fs-2">Send them the password to open the protected link.</p>
                </li>
              </ol>
              <CustomShareButton
                disabledButtons={!opposite_info_link || opposite_info_link === null}
                shareLink={opposite_info_link}
                showElement={["Email", "Copy Link"]}
                onClickShareEmail={onClickShareEmail}
              />
            </div>
            <div className="at-form__content">
              <ol start="3" className="pl-4">
                <li>
                  <p className="fs-2">
                    You will be notified by an email and notification in Atlas when the updated status is changed to “Updated”.
                  </p>
                  <p className="fs-2">
                    Additionally you can come back to the participant section to check the status.
                  </p>
                </li>
              </ol>
              <AlertBox
                mode={"info"}
                description={`In order to protect the benefit of the other party representative, you will not able to see the mobile/office contact number that updated by your ${lowerCaseRepresentativeTitle} or the representative.`}/>
            </div>
          </>
        }
      />
      {loading && <LoadingModal />}
    </>
  );
};

const mapStateToProps = (state) => ({ reduxData: state });
export default connect(mapStateToProps)(TownShipHOC(FormDialog));
