import React, { useState } from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ShareModal from "components/Share";
import PartyCard from "components/Card/partyCard";

import ResendContractHOC from "../../../../actions/resendContract";

const ProcessSection = ({
  status,
  can_create,
  tempPercentage,
  onLoadResend,
  eProcessPartyData = [],
  selectedAuthorisationForm,
  onClickViewActivity,
  onClickRefresh,
  resendContract,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [recipient, setRecipient] = useState("");

  return (
    <div className={"mb-20"}>
      <div className="at-form__content d-flex flex-column grid_gap-2">
        <div className="d-flex flex-wrap grid_gap-1">
          <h5
            style={{ fontWeight: 500, height: "fit-content", margin: "auto 0" }}
          >
            {"Recipients in the signing process"}
          </h5>
          <button
            className={"btn-new btn-new--outline-grey color-black"}
            onClick={() => onClickViewActivity()}
          >
            View Activity
          </button>
        </div>
        <div className={"d-flex align-items-center"}>
          <Tooltip title={"Percentage of signed recipients"}>
            <div
              className={"d-flex justify-content-center align-items-center"}
              style={{ position: "relative", width: 40 }}>
              <CircularProgressbar
                styles={{
                  path: {
                    stroke: "#F0631D",
                    strokeLinecap: "square",
                  },
                  trail: {
                    opacity: 0,
                  }
                }}
                value={tempPercentage}
              />
              <Typography
                className="at-eprocess-percentage-text"
                variant="caption"
                color="textSecondary">
                {`${parseInt(tempPercentage)}%`}
              </Typography>
            </div>
          </Tooltip>
          <strong
            style={{marginLeft: 10}}>
            e-Signing for authorisation form is now
            <span style={{ textTransform: "lowercase" }}>{` ${status}`}</span>
          </strong>
          <AtlasIcon svgHref={"atlas-tick-circle"} style={{ fill: "#12B76A", width: 24, height: 24, marginLeft: "0.5rem" }} />
        </div>
        <h5 className={"at-card__description fs-2"}>
          Signing Order is set as below:
        </h5>
      </div>
      {eProcessPartyData.length > 0 && (
        <div className="party-card_cont mb-3">
          {eProcessPartyData.map((partyData, index) => (
            <PartyCard
              containerClass={"party-card-new"}
              key={index}
              index={index}
              type={"authorisation"}
              data={partyData}
              can_create={can_create}
              resendContract={() =>
                resendContract({
                  form_id: selectedAuthorisationForm.id,
                  form_type: 4,
                  form_contact_id: partyData.form_contact_id,
                })
              }
              onClickShare={(val) => {
                setShareLink(val.signing_link);
                setRecipient(val.full_name);
                setShowShareModal(true);
              }}
              onClickRefresh={onClickRefresh}
            />
          ))}
        </div>
      )}
      {}
      {showShareModal && (
        <ShareModal
          title={"Share the e-signing link"}
          shareLink={shareLink}
          containerClass={"at-share-modal"}
          containerStyle={{minHeight: "initial"}}
          encodedLink={`Hi ${recipient}, here is your Digital Authorisation Form for property at ${selectedAuthorisationForm.property_address}\n ${encodeURIComponent(shareLink)}`}
          showElement={["Whatsapp", "Telegram", "Open in New Tab"]}
          onClickClose={() => setShowShareModal(false)}
        />
      )}
      {onLoadResend && <LoadingModal />}
    </div>
  );
};

export default ResendContractHOC(ProcessSection);
