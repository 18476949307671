import Axios from "axios";

import {
  BEGIN_AJAX_CALL,
  AJAX_CALL_ERROR,
  AJAX_CALL_SUCCESS,
  BEGIN_AJAX_CALL_IMPERSONATE,
  AJAX_CALL_ERROR_IMPERSONATE,
  AJAX_CALL_SUCCESS_IMPERSONATE,
  BEGIN_AJAX_CALL_BG,
  AJAX_CALL_SUCCESS_BG,
  AJAX_CALL_ERROR_BG,
  BEGIN_AJAX_CALL_DRIVE,
  AJAX_CALL_SUCCESS_DRIVE,
  AJAX_CALL_ERROR_DRIVE,
  BEGIN_AJAX_CALL_TOKEN,
  AJAX_CALL_TOKEN_SUCCESS,
  AJAX_CALL_TOKEN_ERROR,
} from "./type";
import { getItem } from "utils/tokenStore";
import { OutdatedToken } from "Sec/RefreshToken";

function interceptorConfigurations(interceptors, formdata) {
  // if( interceptors && formdata ) {
  //   Axios.interceptors.request.use( config => {
  //     const token = getItem( 'IQI_ATLAS_JWT_TOKEN' );
  //     if ( token === null || !token ) return config;
  //     config.headers = {
  //       'Content-Type': 'multipart/form-data',
  //       common: {
  //         Authorization: `JWT ${ localStorage.getItem( 'IQI_ATLAS_JWT_TOKEN' ) }`
  //       },
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //     return config;
  //   });
  // }
  // if( interceptors ) {
  //   Axios.interceptors.request.use( config => {
  //     const token = getItem( 'IQI_ATLAS_JWT_TOKEN' );
  //     if ( token === null || !token ) return config;
  //     config.headers = {
  //       'Content-Type': 'application/json',
  //       common: {
  //         Authorization: `JWT ${ localStorage.getItem( 'IQI_ATLAS_JWT_TOKEN' ) }`
  //       },
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //     return config;
  //   });
  // }
}

function configureErrorMessage(payload) {
  let tmpPayload = "";
  if (payload.response) {
    if (payload.message === "Network Error") {
      tmpPayload =
        "Oops, something is wrong, please send a feedback to your admin";
    }
    if (payload.response.status === 500) {
      tmpPayload =
        "Oops, something is wrong, please send a feedback to your admin";
    } else if (payload.response.status === 422) {
      tmpPayload = payload.response.data.message;
    } else if (payload.response.status === 403) {
      tmpPayload = payload.response.data.message;
    } else {
      tmpPayload = payload.response.data.message;
    }
  } else {
    if (payload.data) {
      tmpPayload = payload.data.message;
    } else {
      tmpPayload =
        "You might be disconnected from the internet because the system is unable to access to the server.";
    }
  }
  return tmpPayload;
}

export function beginAjaxCall(interceptors, formdata) {
  // interceptorConfigurations( interceptors, formdata );
  return {
    type: BEGIN_AJAX_CALL,
  };
}

export function ajaxCallError(payload, next) {
  let tmpPayload = "";
  tmpPayload = configureErrorMessage(payload);
  return {
    type: AJAX_CALL_ERROR,
    payload: tmpPayload,
  };
}

export function ajaxCallSuccess(payload) {
  return {
    type: AJAX_CALL_SUCCESS,
    payload,
  };
}

export function beginAjaxCallImpersonate(interceptors, formdata) {
  // interceptorConfigurations( interceptors, formdata );
  return {
    type: BEGIN_AJAX_CALL_IMPERSONATE,
  };
}

export function ajaxCallErrorImpersonate(payload, next) {
  let tmpPayload = "";
  tmpPayload = configureErrorMessage(payload);
  return {
    type: AJAX_CALL_ERROR_IMPERSONATE,
    payload: tmpPayload,
  };
}

export function ajaxCallSuccessImpersonate(payload) {
  return {
    type: AJAX_CALL_SUCCESS_IMPERSONATE,
    payload,
  };
}

export function beginAjaxCallBackground(interceptors, formdata) {
  interceptorConfigurations(interceptors, formdata);
  return {
    type: BEGIN_AJAX_CALL_BG,
  };
}

export function ajaxCallSuccessBackground(payload) {
  return {
    type: AJAX_CALL_SUCCESS_BG,
    payload,
  };
}

export function ajaxCallErrorBackground(payload, next) {
  let tmpPayload = "";
  tmpPayload = configureErrorMessage(payload);
  return {
    type: AJAX_CALL_ERROR_BG,
    payload: tmpPayload,
  };
}

export const beginAjaxCallIQIDrive = () => {
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const token = getItem(tokenName);
  Axios.defaults.headers = {
    common: {
      Authorization: `JWT ${token}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  return { type: BEGIN_AJAX_CALL_DRIVE };
};

export const ajaxCallSuccessDrive = (payload) => ({
  type: AJAX_CALL_SUCCESS_DRIVE,
  payload,
});

export const ajaxCallErrorDrive = (payload) => {
  let tmpPayload = "";
  tmpPayload = configureErrorMessage(payload);
  return {
    type: AJAX_CALL_ERROR_DRIVE,
    payload: tmpPayload,
  };
};

export const beginAjaxCallToken = () => ({ type: BEGIN_AJAX_CALL_TOKEN });

export const ajaxCallTokenSuccess = () => ({ type: AJAX_CALL_TOKEN_SUCCESS });

export const ajaxCallTokenError = () => ({ type: AJAX_CALL_TOKEN_ERROR });
