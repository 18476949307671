import React, { useContext } from 'react';
import { LocalizationContext } from 'context/localisation';

const withLocalization = (WrappedComponent) => {
  return (props) => {
    const {
      moduleKeys,
      setModuleKeys,
      loading,
      setLoading,
      settings,
      setSettings,
      moduleKeysToEdit,
      setModuleKeysToEdit,
      load,
      onChangeLocalisation,
      getLocalised,
      getFilterKey,
      defaultParse,
      renderSpecialMessage,
      getLocalisedSetting,
      getAllLocalisedSetting,
      getAgentLocalisedSetting,
      getModuleKeys,
      getAgentModuleKeys,
      permissibleBulkUpdate
    } = useContext(LocalizationContext);

    return (
      <WrappedComponent
        {...props}
        moduleKeys={moduleKeys}
        setModuleKeys={setModuleKeys}
        loading={loading}
        setLoading={setLoading}
        settings={settings}
        setSettings={setSettings}
        moduleKeysToEdit={moduleKeysToEdit}
        setModuleKeysToEdit={setModuleKeysToEdit}
        load={load}
        onChangeLocalisation={onChangeLocalisation}
        getLocalised={getLocalised}
        getFilterKey={getFilterKey}
        defaultParse={defaultParse}
        renderSpecialMessage={renderSpecialMessage}
        getLocalisedSetting={getLocalisedSetting}
        getAllLocalisedSetting={getAllLocalisedSetting}
        getAgentLocalisedSetting={getAgentLocalisedSetting}
        getModuleKeys={getModuleKeys}
        getAgentModuleKeys={getAgentModuleKeys}
        permissibleBulkUpdate={permissibleBulkUpdate}
      />
    );
  };
};

export default withLocalization;