import React, { useState } from "react";
import _ from "lodash";
import { MdAdd } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import ConfirmationModal from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import ModalDialog from "components/Modal/ModalDialog";

const columnData = (props) => [
  {
    header: "Item",
    renderColumn: (rowData, itemIndex) => (
      <input
        value={rowData.name}
        placeholder={"Item Name"}
        disabled={props.closedEditing}
        className={"at-form_mngmt__inventory-content"}
        onChange={(e) =>
          props.onChangeItem(e.target.value, itemIndex, props.index, "name")
        }
      />
    ),
  },
  {
    header: "Quantity",
    columnStyle: {
      width: "80%",
      minWidth: 150,
    },
    renderColumn: (rowData, itemIndex) => (
      <div className="d-flex">
        <button
          disabled={props.closedEditing || rowData.quantity === 1}
          className="at-form_mngmt__inventory-content-button"
          onClick={() => {
            let temp = _.cloneDeep(rowData.quantity);
            temp = Number(temp) - 1;
            props.onChangeItem(temp, itemIndex, props.index, "quantity");
          }}
        >
          -
        </button>
        <input
          value={rowData.quantity}
          placeholder={"Quantity"}
          disabled={true}
          className={"at-form_mngmt__inventory-content text-center mx-2"}
        />
        <button
          disabled={props.closedEditing}
          className="at-form_mngmt__inventory-content-button"
          onClick={() => {
            let temp = _.cloneDeep(rowData.quantity);
            temp = Number(temp) + 1;
            props.onChangeItem(temp, itemIndex, props.index, "quantity");
          }}
        >
          +
        </button>
      </div>
    ),
  },
  {
    header: "Remark",
    renderColumn: (rowData, itemIndex) => (
      <input
        value={rowData.remark}
        placeholder={"Remarks"}
        disabled={props.closedEditing}
        className={"at-form_mngmt__inventory-content"}
        onChange={(e) =>
          props.onChangeItem(e.target.value, itemIndex, props.index, "remark")
        }
      />
    ),
  },
];

const InventoryList = ({
  menuExpand,
  closedEditing,
  inventoryItems,
  selectedConfirmationForm,

  updateInventoryList,
  onChange,
  renderNavButton,
}) => {
  const { id, include_inventory, inventory_groups } =
    selectedConfirmationForm;

  const [deleteItem, setDeleteItem] = useState({});
  const [showInvetoryItmes, setShowInventoryItems] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onChangeItem = (val, itemIndex, groupIndex, context) => {
    let temp = _.cloneDeep(inventory_groups);
    temp[groupIndex]["items"][itemIndex][context] = val;
    onChange("inventory_groups", temp);
  };

  const onChangeName = (val, groupIndex) => {
    let temp = _.cloneDeep(inventory_groups);
    temp[groupIndex]["name"] = val;
    onChange("inventory_groups", temp);
  };

  const checkItem = (inventory, item) => {
    let titleIndex = _.findIndex(inventory_groups, (x) => {
      return x.name === inventory;
    });
    if (titleIndex > -1) {
      let itemIndex = _.findIndex(inventory_groups[titleIndex].items, (x) => {
        return x.name === item;
      });
      if (itemIndex > -1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const onFocusNewGroup = (index) =>
    setTimeout(() => {
      let newElement = document.getElementById(
        `inventory-group-name-${index - 1}`,
      );
      if (newElement) newElement.focus();
    }, 500);

  const renderTableHeading = () => {
    return (
      <div
        className="at-table-inventory_table-heading"
        showItems={`${include_inventory && inventory_groups && inventory_groups.length > 0}`}
      >
        <span className="at-table-inventory_table-heading_icon">
          <AtlasIcon svgHref={"svg-iconsax-lamp"} />
        </span>
        <span className="at-new_header-title-3">Inventory</span>
        {!closedEditing && (
          <div className="d-flex grid_gap-1">
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={"+ Add From Checklist"}
              onClick={() => setShowInventoryItems(true)}
            />
            <CustomButton
              className={"btn-new btn-new--primary"}
              onClick={() => {
                let temp = _.cloneDeep(inventory_groups);
                temp.push({
                  name: "",
                  items: [
                    {
                      name: "",
                      quantity: 1,
                      remark: "",
                    },
                  ],
                });
                onChange("inventory_groups", temp);
                onFocusNewGroup(temp.length);
              }}
              children={"+ Add New Group"}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="at-form__content d-flex flex-column grid_gap-1">
        <div>
          <input
            type={"checkbox"}
            id={"include_inventory"}
            name={"include_inventory"}
            style={{ marginRight: 8, marginTop: "0.15rem" }}
            checked={include_inventory}
            onChange={(e) => onChange("include_inventory", e.target.checked)}
            disabled={closedEditing}
          />
          <span htmlFor={"extra_condition"} className="fs-2">Include Inventory Checklist</span>
        </div>
        {include_inventory && (
          <AlertBox
            mode={"info"}
            containerClass="mb-0"
            title={""}
            description={
              <>
                The checklist will be added after the Terms and Conditions of
                the form.
                <br />
                Select "Add from Checklist" or "Add New Group" to begin listing
                the inventories.
              </>
            }
          />
        )}
      </div>
      {include_inventory && (
        <>
          {renderTableHeading()}
          {inventory_groups && inventory_groups.length > 0 && (
            <div className="at-form_mngmt__inventory-table admin">
              {inventory_groups.map((group, index) => (
                <div
                  className="at-form_mngmt__inventory-table_group_cont"
                  key={index}
                >
                  <div className="at-form_mngmt__inventory-header">
                    <div className={"at-form_mngmt__inventory-content"}>
                      <SvgIcon svgHref="confirmation-form_input-edit" />
                      <input
                        id={`inventory-group-name-${index}`}
                        value={group.name}
                        placeholder="Group Name"
                        style={{ color: "white", maxWidth: 300 }}
                        onChange={(e) => onChangeName(e.target.value, index)}
                      />
                    </div>
                    <CustomButton
                      onClick={() =>
                        Promise.all([setDeleteItem(index)]).then(() => {
                          setShowDeleteConfirmation(true);
                        })
                      }
                      disabled={closedEditing}
                      tooltip={true}
                      tooltipChildren={<span>Remove Group</span>}
                      tooltipPosition={"top"}
                      tooltipID={`confirmation-inventory-${index}`}
                      isIconButton={true}
                      children={
                        <IoMdCloseCircleOutline style={{ color: "white" }} />
                      }
                    />
                  </div>
                  <AtPaginatedTable
                    key={index}
                    columns={columnData({
                      index,
                      closedEditing,
                      group: group.name,

                      onChangeItem,
                      onChangeName,
                    })}
                    rowData={group.items || []}
                    hideHeader={false}
                    headerClass={"at-form_mngmt__inventory-table-header"}
                    meta={null}
                    searchParams={[]}
                    actionColumnContent={
                      !closedEditing
                        ? [
                          {
                            name: "delete",
                            color: "#F04438",
                            onShow: content => !closedEditing,
                            onClick: (rowData, itemIndex) => {
                              let temp = _.cloneDeep(inventory_groups);
                              temp[index]["items"].splice(itemIndex, 1);
                              if (temp[index]["items"].length === 0) {
                                temp.splice(index, 1);
                              }
                              onChange("inventory_groups", temp);
                            },
                          },
                        ]
                      : []
                  }
                  />
                  {!closedEditing && (
                    <div
                      className="at-table-row-cont at-form_mngmt__inventory-action_footer">
                      <ul className="at-table-row">
                        <li className="at-table-row__item">
                          <button
                            className="btn-new btn-new--secondary"
                            onClick={() => {
                              let temp = _.cloneDeep(inventory_groups);
                              temp[index].items.push({
                                name: "",
                                quantity: 1,
                                remark: "",
                              });
                              onChange("inventory_groups", temp);
                            }}
                          >
                            <MdAdd
                              style={{ width: 18, height: 18, color: "white" }}
                            />
                            Add Item
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        <button
          disabled={closedEditing}
          className={`btn-new btn-new--success`}
          onClick={() =>
            updateInventoryList({
              id: id,
              inventory_groups: inventory_groups,
              include_inventory: include_inventory,
            })
          }
        >
          Save Changes
        </button>
        {renderNavButton && renderNavButton()}
      </div>
      {showInvetoryItmes && (
        <ModalDialog
          title={"Inventory Checklist Items"}
          onClose={() => setShowInventoryItems(false)}
          children={
            <>
              <p className="fs-2 mb-3">
                Tick the box next to the item that applies to the area's
                inventories. Click X to close the list once you're finished.
              </p>
              {inventoryItems &&
                inventoryItems.length > 0 &&
                inventoryItems.map((inventory, index) => (
                  <div
                    key={`at-form_mngmt__inventory-checklist_items-cont-${index}`}
                    className="at-form__content d-flex flex-column grid_gap-2"
                    style={{ padding: 20 }}
                  >
                    <h2 className="at-form-input__title fs-4 mb-0">
                      {inventory.name}
                    </h2>
                    <div className="d-flex flex-wrap grid_gap-2">
                      {inventory.items.length > 0 &&
                        inventory.items.map((item, itemIndex) => (
                          <CustomCheckbox
                            key={`at-form_mngmt__inventory-checklist_item-${itemIndex}`}
                            labelClassname="m-0"
                            content={item}
                            checked={checkItem(inventory.name, item)}
                            onChangeCheckboxValue={(e) => {
                              let titleIndex = _.findIndex(
                                inventory_groups,
                                (x) => {
                                  return x.name === inventory.name;
                                },
                              );
                              if (e.target.checked) {
                                if (titleIndex > -1) {
                                  let temp = _.cloneDeep(inventory_groups);
                                  temp[titleIndex].items.push({
                                    name: item,
                                    quantity: 1,
                                    remark: "",
                                  });
                                  onChange("inventory_groups", temp);
                                } else {
                                  let temp = _.cloneDeep(inventory_groups);
                                  temp.push({
                                    name: inventory.name,
                                    items: [
                                      {
                                        name: item,
                                        quantity: 1,
                                        remark: "",
                                      },
                                    ],
                                  });
                                  onChange("inventory_groups", temp);
                                }
                              } else {
                                if (titleIndex > -1) {
                                  let removeItemIndex = _.findIndex(
                                    inventory_groups[titleIndex].items,
                                    (x) => {
                                      return x.name === item;
                                    },
                                  );
                                  if (removeItemIndex > -1) {
                                    let temp = _.cloneDeep(inventory_groups);
                                    temp[titleIndex]["items"].splice(
                                      removeItemIndex,
                                      1,
                                    );
                                    if (temp[titleIndex]["items"].length < 1) {
                                      temp.splice(titleIndex, 1);
                                    }
                                    onChange("inventory_groups", temp);
                                  }
                                }
                              }
                            }}
                          />
                        ))}
                    </div>
                  </div>
                ))}
            </>
          }
        />
      )}
      <ConfirmationModal
        title={"Delete Inventory Group"}
        loading={false}
        open={showDeleteConfirmation}
        message={`Are you sure you want to remove this inventory group ?`}
        positiveAction={() => {
          let temp = _.cloneDeep(inventory_groups);
          temp.splice(deleteItem, 1);
          onChange("inventory_groups", temp);
          setShowDeleteConfirmation(false);
        }}
        negativeAction={() => setShowDeleteConfirmation(false)}
      />
    </>
  );
};

export default InventoryList;
