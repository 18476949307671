import React from "react";

const typeLib = [
  {
    id: 0,
    value: "",
    label: "All",
  },
  {
    id: 2,
    value: "[status_id_eq]=2&",
    label: "Sent",
  },
  {
    id: 3,
    value: "[status_id_eq]=3&",
    label: "Approved",
  },
  {
    id: 4,
    value: "[status_id_eq]=4&",
    label: "Declined",
  },
  {
    id: 5,
    value: "[status_id_eq]=5&",
    label: "Revoked/Cancelled",
  },
  {
    id: 6,
    value: "[status_id_not_eq]=3&q[listing_status_id_not_eq]=1&",
    label: "No Longer Available",
  },
];

const searchParams = (countries = [], categoriesDic = []) => [
  {
    label: "Property Name",
    value: "listing_property_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Type",
    value: "listing_property_type_name_cont",
    type: "typeahead",
    param: "",
    typeaheadId: "proptype_typeahead",
    options: categoriesDic,
    filterBy: ["name"],
    labelKey: "name",
    typeaheadChildren: (rowItem) => <p>{rowItem.name}</p>,
  },
  {
    label: "Country",
    value: "listing_country_name_cont",
    type: "select",
    param: "",
    valueKey: "name",
    options: countries,
  },
  {
    label: "State",
    value: "listing_state_name_cont",
    type: "select",
    param: "",
    options: [],
    valueKey: "name",
    placeholder: "Please choose a country first.",
  },
  {
    label: "Type",
    value: "listing_type_id_eq",
    type: "radio",
    param: "",
    col: 6,
    options: [
      {
        value: "1",
        label: "Sub-Sale",
      },
      {
        value: "2",
        label: "Sub-Rent",
      },
    ],
  },
  {
    label: "Status",
    value: "",
    type: "radio",
    param: "",
    col: 12,
    options: typeLib,
  },
];

export default searchParams;
