const subrentForms = [
  {
    label: "Commencement date",
    value: "commencement_date",
    type: "date",
    dateFormat: "YYYY-MM-DD",
    required: true
  },
  {
    label: "Expiring date",
    value: "expiring_date",
    type: "date",
    dateFormat: "YYYY-MM-DD",
    required: true
  },
  {
    label: "Appointment period (days)",
    value: "appoint_day",
    type: "text",
    required: true
  },
  {
    label: "Rental price (RM)",
    value: "payment_amount",
    type: "currency",
    required: true
  },
  {
    label: "Type of property",
    value: "property_type",
    type: "text",
    required: true
  },
  {
    label: "Location of property",
    value: "property_address",
    type: "text",
    required: true
  },
  {
    label: "Number of rooms and baths",
    value: "number_of_rooms_and_baths",
    type: "text",
    required: false
  },
  {
    label: "Others (Requirement)",
    value: "others",
    type: "text",
    required: false
  },
  {
    label: "Professional fee amount (RM)",
    value: "professional_fee",
    type: "currency",
    required: true
  },
  {
    label: "Duration of rental (months)",
    value: "month_of_rental",
    type: "number",
    required: true
  },
];

export default subrentForms;
