import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateNewsfeedModal from "./Create";
import UpdateNewsfeedModal from "./Update";

import WithNewsfeedManagements from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Yes", colorName: "Green" },
  { value: "No", colorName: "Red" },
];

const columnData = [
  {
    header: "Title",
    accessor: "title",
    columnStyle: { width: "170%" },
  },
  {
    header: "Publish At",
    accessor: "publish_at",
    columnStyle: { width: "80%" },
  },
  {
    header: "Visible To Roles",
    accessor: "visible_to_agent_role",
    columnStyle: { width: "100%" },
  },
  {
    header: "Views Count",
    renderColumn: ({ views_count = 0 }) => <p>{views_count}</p>,
    columnStyle: { width: "100%" },
  },
  {
    header: "Published",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "100%" },
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.isPublished }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.isPublished}
        </div>
      );
    },
  },
];

class NewsfeedsManagement extends Component {
  componentDidMount = () => this.props.getNewsfeeds(1, "");

  render = () => {
    const { can_create, can_update } = permissionsChecker(
      "Newsfeeds",
      this.props.data,
    );
    return (
      <>
        <ModuleHeader
          title={`Newsfeeds`}
          atlasIcon={"atlas-document-text"}
          actionButton={[
            {
              label: "New Newsfeed",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangeNewsFeedHOC(true, "showCreateNewsfeed"),
            },
          ]}
        />
        <CustomCard
          cardContent={
            <>
              <AtPaginatedTable
                rowData={this.props.newsFeed.data || []}
                meta={this.props.newsFeed.meta}
                columns={columnData}
                actionColumnContent={[
                  {
                    name: can_update ? "edit" : "view",
                    onClick: (rowData) =>
                      this.props.getSelectedNewsfeed(rowData.id),
                  },
                ]}
                searchParams={this.props.searchParams}
                onChangeSearchParams={(val) =>
                  this.props.onChangeNewsFeedHOC(val, "searchParams")
                }
                getListAPI={this.props.getNewsfeeds}
                totalPages={this.props.newsFeedPages}
              />
              {this.props.showCreateNewsfeed && (
                <CreateNewsfeedModal {...this.props} />
              )}
              {this.props.showUpdateNewsfeed && (
                <UpdateNewsfeedModal {...this.props} />
              )}
            </>
          }
        />
        {this.props.onLoadNewsfeedManagement && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  WithNewsfeedManagements,
  VersionCheckHOC,
)(NewsfeedsManagement);
