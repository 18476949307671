import React, { Fragment } from "react";

import "stylesheets/components/form/index.scss";

const CustomForm = ({
  formHeader,
  formSection,
  alertMessage,
  actionColumns,
}) => {
  return (
    <>
      {formHeader && formHeader()}
      <div>
        {formSection.map((form, formIndex) => {
          if (!form.formShow || (form.formShow && form.formShow())) {
            return (
              <div key={formIndex} className="at-form__content">
                <div key={formIndex} className="mb-3">
                  <span className="at-form__label">
                    <div className="at-form__label-icon">{form.formIcon}</div>
                    <div>
                      <p className="at-form__label-title">{form.formTitle}</p>
                      <p className="at-form__label-descrption">
                        {form.formDescription || ""}
                      </p>
                    </div>
                  </span>
                </div>
                <div>{form.formContent}</div>
              </div>
            );
          }
        })}
        {alertMessage && alertMessage}
        {actionColumns && (
          <div className="d-flex p-0 grid_gap-2">
            {actionColumns.map((actions, actionIndex) => {
              if (!actions.onShow || (actions.onShow && actions.onShow())) {
                return (
                  <button
                    key={actionIndex}
                    className={`${actions.className || "btn-new btn-new--primary mr-2"}`}
                    onClick={actions.onClick}
                  >
                    {actions.label}
                  </button>
                );
              }
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomForm;