import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";

import { requestSuccess, requestError } from "utils/requestHandler";

import { storeSubsalesClaimsMessage } from "actions/subsales/message";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      messages: [],
      showLoadingModal: false,

      clearTypeBox: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });

    getMessages = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/messages`,
        this.getMessagesSuccess,
        this.getMessagesError,
        this.load,
      );
    getMessagesSuccess = (payload) => {
      let temp = _.orderBy(payload, (o) => o.created_at, "asc");
      this.setState({ messages: temp, clearTypeBox: false });
    };
    getMessagesError = (error) => requestError(error);
    postMessage = (id, dataToSubmit) => {
      Post(
        `/admin/sub_sale_claims/${id}/messages`,
        dataToSubmit,
        this.postMessageSuccess,
        this.postMessageError,
        this.load,
      );
    };
    postMessageSuccess = (payload) =>
      this.setState({ clearTypeBox: true }, () => {
        this.getMessages(payload.source_id);
      });
    postMessageError = (error) => requestError(error);

    deleteMessage = (dataToSubmit) =>
      Delete(
        `/admin/sub_sale_claims/${dataToSubmit.source_id}/messages/${dataToSubmit.id}`,
        (payload) => this.deleteMessageSuccess(payload, dataToSubmit.source_id),
        this.deleteMessageError,
        this.load,
      );
    deleteMessageSuccess = (payload, source_id) => {
      this.getMessages(source_id);
      requestSuccess(payload.message);
    };
    deleteMessageError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            clearTypeBox={this.state.clearTypeBox}
            messages={this.state.messages}
            getMessages={this.getMessages}
            postMessage={this.postMessage}
            deleteMessage={this.deleteMessage}
            onLoadMessages={this.state.showLoadingModal}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeSubsalesClaimsMessage,
  })(WithHOC);
};

export default HOC;
