import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";

import { Post, Get, GetFile, Delete, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { partyDictionary } from "../assets/index";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedSigningTab: "e-Signing",
      selectedSection: "Property",
      selectedContact: null,
      formTypeActivity: 1,

      showStartProcessModal: false,
      showStartRefundProccessModal: false,
      showEditPartyModal: false,
      showDeletePartyModal: false,
      showSigningHistory: false,
      showAssignMyselfModal: false,
      showCreateContactModal: false,
      showAssignContactModal: false,
      showAssignInternalModal: false,
      showAssignSuperiorModal: false,
      showResetConfirmationModal: false,
      showCompleteConfirmationModal: false,
      showResetRefundConfirmationModal: false,
      showAbortConfirmationModal: false,
      showRefundFormModal: false,
      showEsigningTipsModal: false,
      showSubmitClaimConfirmationModal: false,

      signingStatus: null,
      refundStatus: null,
      refundStatusId: null,
      signingStatusId: `0`,

      partyData: [],
      eProcessPartyData: [],
      eProcessInitialPartyData: [],
      eProcessContentId: 1,

      signingOption: "0",
      signingType: "1",
    };

    load = (param) => this.setState({ loading: param });

    onChangePartyHOC = (val, context) => this.setState({ [context]: val });

    getParty = (id) =>
      Get(
        `/forms/form_contacts?type_id=1&form_id=${id}`,
        this.getPartySuccess,
        this.getPartyError,
        this.load,
      );
    getPartySuccess = (payload) => {
      let tempBuyerData = [];
      let tempSellerData = [];
      let tempBuyerAgentData = [];
      let tempSellerAgentData = [];
      let tempOriginalData = [];

      payload?.map((item) => {
        let temp = _.find(partyDictionary, { id: item.party_id });

        let tempData = {
          ...item,
          title:
            this.props.selected_confirmation_form.type_id === 1
              ? temp.subsalesTitle
              : temp.subrentTitle,
          date_of_birth: Moment(item.date_of_birth, "YYYY-MM-DD").isValid()
            ? Moment(item.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
            : item.date_of_birth,
          form_contact_id: item.id,
          draggableId: item.id.toString(),
          participants_name:
            item.signee_type_id === 1
              ? [item.company_name, `(${item.company_registration_no})`, item.full_name]
              : item.full_name
        };
        tempOriginalData.push(tempData);
        switch (item.party_id) {
          case 1:
            tempBuyerData.push(tempData);
            break;
          case 2:
            tempBuyerAgentData.push(tempData);
            break;

          case 3:
            tempSellerData.push(tempData);
            break;

          default:
            tempSellerAgentData.push(tempData);
            break;
        }
      });

      if (tempOriginalData.length > 0 && tempOriginalData[0].party_id === 3) {
        this.setState({ signingType: "2" });
      } else {
        this.setState({ signingType: "1" });
      }

      let tempParty = [
        {
          id: 1,
          data: tempBuyerData,
        },
        {
          id: 2,
          data: tempBuyerAgentData,
        },
        {
          id: 3,
          data: tempSellerData,
        },
        {
          id: 4,
          data: tempSellerAgentData,
        },
      ];

      this.setState({
        partyData: tempParty,
        eProcessPartyData: tempOriginalData,
        eProcessInitialPartyData: tempOriginalData,
      });
      this.props.onChangeConfirmationHOC(
        tempBuyerData.length > 0,
        "purchaserNTenant",
      );
      this.props.onChangeConfirmationHOC(
        tempBuyerData.length || 1,
        "totalBuyerCount",
      );
      this.props.onChangeConfirmationHOC(
        tempSellerData.length || 1,
        "totalSellerCount",
      );
    };
    getPartyError = (error) => requestError(error);

    createParty = (dataToSubmit, id) =>
      Post(
        `/forms/form_contacts?type_id=1&form_id=${id}`,
        dataToSubmit,
        this.createPartySuccess,
        this.createPartyError,
        this.load,
      );
    createPartySuccess = (payload) => {
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id);
      this.props.getCurrentUserContactList()
      requestSuccess("New contact created successfully.");
      this.setState({
        showCreateContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: [1, 3].includes(payload.party_id)
      });
    };
    createPartyError = (error) => requestError(error);

    getSelectedFormContact = (formId, contactId) =>
      Get(
        `/forms/form_contacts/${contactId}?type_id=1&form_id=${formId}`,
        this.getSelectedFormContactSuccess,
        this.getSelectedFormContactError,
        this.load,
      );
    getSelectedFormContactSuccess = (payload) =>
      this.setState({
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
        },
      });
    getSelectedFormContactError = (error) => requestError(error);

    // delete party contact
    deletePartyContact = (formId, contactId) =>
      Delete(
        `/forms/form_contacts/${contactId}?type_id=1&form_id=${formId}`,
        () => this.deletePartyContactSuccess(formId),
        this.deletePartyContactError,
        this.load,
      );
    deletePartyContactSuccess = (id) => {
      this.getParty(id);
      this.setState({ showDeletePartyModal: false });
      this.props.getConfirmationForm(id);
      requestSuccess("Record deleted successfully.");
    };
    deletePartyContactError = (error) => requestError(error);

    updatePartyContact = (dataToSubmit) => {
      delete dataToSubmit.due_diligence_information;
      delete dataToSubmit.copies_info;

      Put(
        `/forms/form_contacts/${dataToSubmit.id}?type_id=1`,
        dataToSubmit,
        this.updatePartyContactSuccess,
        this.updatePartyContactError,
        this.load,
      );
    };
    updatePartyContactSuccess = (payload) => {
      requestSuccess("Contact's information is updated successfully.");
      this.setState({
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
        },
      });
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id);
    };
    updatePartyContactError = (error) => requestError(error);

    assignParty = (dataToSubmit, id, title) =>
      Post(
        `/forms/form_contacts/assign_agent?type_id=1&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignPartySuccess(payload, title),
        this.assignPartyError,
        this.load,
      );
    assignPartySuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.setState({
        showAssignInternalModal: false,
        showAssignMyselfModal: false,
        showAssignSuperiorModal: false,
      });
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id);
    };
    assignPartyError = (error) => requestError(error);

    assignContact = (dataToSubmit, id, title) =>
      Post(
        `/forms/form_contacts/assign_contact?type_id=1&form_id=${id}`,
        dataToSubmit,
        (payload) => this.assignContactSuccess(payload, title),
        this.assignContactError,
        this.load,
      );
    assignContactSuccess = (payload, title) => {
      requestSuccess(`New ${title} is assigned successfully`);
      this.setState({ 
        showAssignContactModal: false,
        selectedContact: {
          ...payload,
          signee_type_id: `${payload.signee_type_id}`,
          due_diligence_information: payload.due_diligence_information || {},
        },
        showEditPartyModal: [1, 3].includes(payload.party_id), 
      });
      this.getParty(payload.form_id);
      this.props.getConfirmationForm(payload.form_id);
    };
    assignContactError = (error) => requestError(error);

    initiateSigningProcess = (dataToSubmit) =>
      Post(
        `/e_processes/start`,
        dataToSubmit,
        () => this.initiateSigningProcessSuccess(dataToSubmit.form_id),
        this.initiateSigningProcessError,
        this.load,
      );
    initiateSigningProcessSuccess = (form_id) => {
      requestSuccess("E-process has been started successfully.");
      this.setState({
        eProcessContentId: 2,
        showStartProcessModal: false,
        showStartRefundProccessModal: false,
      });
      this.props.getConfirmationForm(form_id)
      this.getParty(this.props.selected_confirmation_form.id);
      this.getSigningStatus(this.props.selected_confirmation_form.id);
    };
    initiateSigningProcessError = (error) => requestError(error);

    getSigningStatus = (id) =>
      Get(
        `/e_processes/check_status?form_type=1&form_id=${id}`,
        this.getSigningStatusSuccess,
        this.getSigningStatusError,
        this.load,
      );
    getSigningStatusSuccess = (payload) =>
      this.setState({
        refundStatus: payload.refund_status,
        signingStatus: payload.status,

        refundStatusId:
          payload.refund_status_id !== null
            ? `${payload.refund_status_id}`
            : payload.refund_status_id,
        signingStatusId: `${payload.status_id}`,
        eProcessContentId: payload.status_id === 0 ? 1 : 2,
      });
    getSigningStatusError = (error) => requestError(error);

    resetSigning = (dataToSubmit) =>
      Put(
        `/e_processes/reset_e_process`,
        dataToSubmit,
        (payload) => this.resetSigningSuccess(payload, dataToSubmit.form_id),
        this.resetSigningError,
        this.load,
      );
    resetSigningSuccess = (payload, id) => {
      this.getParty(id);
      this.getSigningStatus(id);
      requestSuccess(payload.message);
      this.props.getConfirmationForm(id)
      this.setState({
        showResetConfirmationModal: false,
        showResetRefundConfirmationModal: false,
      });
    };
    resetSigningError = (error) => requestError(error);

    createRefundForm = (id) => {
      Post(
        `/forms/refund_forms?confirmation_form_id=${id}`,
        {},
        () => this.createRefundFormSuccess(id),
        this.createRefundFormError,
        this.load,
      );
    };
    createRefundFormSuccess = (id) => {
      this.getParty(id);
      this.props.getConfirmationForm(id)
      this.getSigningStatus(id);

      requestSuccess("Refund form created successfully.");
      this.setState({
        showRefundFormModal: true,
        showAbortConfirmationModal: false,
      });
    };
    createRefundFormError = (error) => requestError(error);

    completeEsigning = (dataToSubmit) =>
      Put(
        `/e_processes/complete`,
        dataToSubmit,
        (payload) =>
          this.completeEsigningSuccess(payload, dataToSubmit.form_id),
        this.completeEsigningError,
        this.load,
      );
    completeEsigningSuccess = (payload, form_id) => {
      this.setState({ showCompleteConfirmationModal: false });
      this.getParty(form_id);
      this.getSigningStatus(form_id);
      this.props.getConfirmationForm(form_id)

      requestSuccess(payload.message);
    };
    completeEsigningError = (error) => requestError(error);

    submitToClaim = (id) => {
      Post(
        `/e_processes/submit_claim?form_id=${id}`,
        {},
        this.submitToClaimSuccess,
        this.submitToClaimError,
        this.load,
      );
    };
    submitToClaimSuccess = (payload) => {
      const { selected_confirmation_form } = this.props;
      const tmpPath =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.adminSignInReducer.prefix_path
          : "";
      this.props.getConfirmationForm(selected_confirmation_form.id);
      this.setState({
        showSubmitClaimConfirmationModal: false,
      });
      requestSuccess(`Click on the 'Ok' button to view the created claim.`);
      this.props.history.push({
        pathname: `${tmpPath}/dashboard/personal/subsales-claims`,
        state: {
          claim_id: payload.claim_id,
        },
      });
    };
    submitToClaimError = (error) => requestError(error);

    downloadCompletedForm = ({ formId, formType, filename }) =>
      GetFile(
        `/e_processes/download?form_id=${formId}&form_type=${formType}`,
        filename,
        () => {},
        this.downloadCompletedFormError,
        this.load,
      );
    downloadCompletedFormError = (error) => requestError(error);

    validateForm = (dataToSubmit, mode) =>
      Post(
        `/e_processes/validate`,
        dataToSubmit,
        () => this.validateFormSuccess(mode),
        this.validateFormError,
        this.load,
      );
    validateFormSuccess = (mode) => {
      if (mode === "confirmation")
        this.setState({ showStartProcessModal: true });
      else this.setState({ showStartRefundProccessModal: true });
    };
    validateFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedContact={this.state.selectedContact}
            selectedSection={this.state.selectedSection}
            formTypeActivity={this.state.formTypeActivity}
            selectedSigningTab={this.state.selectedSigningTab}
            refundStatus={this.state.refundStatus}
            signingStatus={this.state.signingStatus}
            signingStatusId={this.state.signingStatusId}
            refundStatusId={this.state.refundStatusId}
            onLoadParty={this.state.loading}
            signingType={this.state.signingType}
            signingOption={this.state.signingOption}
            partyData={this.state.partyData}
            eProcessContentId={this.state.eProcessContentId}
            showSigningHistory={this.state.showSigningHistory}
            eProcessPartyData={this.state.eProcessPartyData}
            eProcessInitialPartyData={this.state.eProcessInitialPartyData}
            showRefundFormModal={this.state.showRefundFormModal}
            showStartRefundProccessModal={
              this.state.showStartRefundProccessModal
            }
            showSubmitClaimConfirmationModal={
              this.state.showSubmitClaimConfirmationModal
            }
            showAbortConfirmationModal={this.state.showAbortConfirmationModal}
            showStartProcessModal={this.state.showStartProcessModal}
            showDeletePartyModal={this.state.showDeletePartyModal}
            showEditPartyModal={this.state.showEditPartyModal}
            showEsigningTipsModal={this.state.showEsigningTipsModal}
            showAssignMyselfModal={this.state.showAssignMyselfModal}
            showCreateContactModal={this.state.showCreateContactModal}
            showAssignContactModal={this.state.showAssignContactModal}
            showAssignInternalModal={this.state.showAssignInternalModal}
            showAssignSuperiorModal={this.state.showAssignSuperiorModal}
            showResetConfirmationModal={this.state.showResetConfirmationModal}
            showCompleteConfirmationModal={
              this.state.showCompleteConfirmationModal
            }
            showResetRefundConfirmationModal={
              this.state.showResetRefundConfirmationModal
            }
            getParty={this.getParty}
            createParty={this.createParty}
            assignParty={this.assignParty}
            validateForm={this.validateForm}
            resetSigning={this.resetSigning}
            assignContact={this.assignContact}
            submitToClaim={this.submitToClaim}
            createRefundForm={this.createRefundForm}
            getSigningStatus={this.getSigningStatus}
            completeEsigning={this.completeEsigning}
            deletePartyContact={this.deletePartyContact}
            updatePartyContact={this.updatePartyContact}
            updateDueDiligence={this.updateDueDiligence}
            downloadCompletedForm={this.downloadCompletedForm}
            getSelectedFormContact={this.getSelectedFormContact}
            initiateSigningProcess={this.initiateSigningProcess}
            onChangePartyHOC={this.onChangePartyHOC}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
