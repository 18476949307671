import React, { useCallback, useState } from "react";
import _ from "lodash";

import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";

const FieldData = ({ passwordError }) => [
  { label: "Full name", value: "full_name" },
  { label: "Display Name", value: "display_name" },
  { label: "Email", value: "email" },
  { label: "Primary Mobile Number", value: "mobile_contact_number" },
  { label: "Password", value: "password", error: passwordError },
  {
    label: "Password Confirmation",
    value: "password_confirmation",
    error: passwordError,
  },
];

const RoleOptions = [
  { value: 4, label: "Admin" },
  { value: 8, label: "Project Manager" },
];

const Create = ({ onLoadUsers, onClose, createNewAdmin }) => {
  const [passwordError, onChangeError] = useState("");
  const [createData, onChangeData] = useState({
    role_id: "",
    full_name: "",
    display_name: "",
    email: "",
    mobile_contact_number: "",
    password: "",
    password_confirmation: "",
  });

  const onChangeCreateFields = useCallback(
    (val, context) => {
      let temp = _.cloneDeep(createData);
      temp[context] = val;
      onChangeData(temp);

      if (["password", "password_confirmation"].indexOf(context) > -1) {
        onChangeError(
          temp.password_confirmation !== temp.password &&
            temp.password.length > 0 &&
            temp.password_confirmation.length > 0
            ? "Password and confirm password must be the same."
            : "",
        );
      }
    },
    [createData],
  );

  return (
    <ModalDialog
      title={"New Admin"}
      onLoad={onLoadUsers}
      responsiveSize="md"
      onClose={() => onClose()}
      children={
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Role"}</h2>
            <CustomRadioGroup
              checkedValue={createData.role_id}
              horizontal={true}
              options={RoleOptions}
              selectedRadioValue={(val) => onChangeCreateFields(val, "role_id")}
            />
          </section>
          {FieldData({ passwordError }).map((field) => (
            <section className="grid-half-col">
              <h2 className="at-form-input__title" required>
                {field.label}
              </h2>
              <CustomFormInput
                type="text"
                value={createData[field.value]}
                onChangeValue={(val) => onChangeCreateFields(val, field.value)}
                required={true}
                inputError={field.error || ""}
                placeholder={`Please fill in ${field.label}`}
              />
            </section>
          ))}
        </form>
      }
      footer={
        <div className="d-flex g-3">
          <button
            type={"button"}
            disabled={_.some(createData, (item) => !item) || passwordError}
            className="btn-new btn-new--primary"
            onClick={() => createNewAdmin(createData)}
          >
            Create
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
};

export default Create;
