import React from "react";

import AtlasIcon from "components/Icon/atlasIcon";

const NotificationIcon = ({ iconType }) => {
  switch (iconType) {
    case "Event":
      return (
        <div
          style={{ backgroundColor: "#FCE7F3" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon svgHref={"atlas-calendar-2"} style={{ fill: "#DB2777" }} />
        </div>
      );
    case "Report":
      return (
        <div
          style={{ backgroundColor: "#E0F2FE" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-document-text"}
            style={{ fill: "#0284C7" }}
          />
        </div>
      );
    case "Sub-sales":
      return (
        <div
          style={{ backgroundColor: "#FEF3C7" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon svgHref={"atlas-building-3"} style={{ fill: "#D97706" }} />
        </div>
      );
    case "Project Sales":
      return (
        <div
          style={{ backgroundColor: "#FEF3C7" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon svgHref={"atlas-building-4"} style={{ fill: "#D97706" }} />
        </div>
      );
    case "Presentation":
      return (
        <div
          style={{ backgroundColor: "#E0E7FF" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-presentation-chart"}
            style={{ fill: "#4F46E5" }}
          />
        </div>
      );
    case "Marketing Centre":
      return (
        <div
          style={{ backgroundColor: "#E0E7FF" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon svgHref={"atlas-magicpen"} style={{ fill: "#4F46E5" }} />
        </div>
      );
    case "New REN Registration":
      return (
        <div
          style={{ backgroundColor: "#CCFBF1" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-user-circle-add"}
            style={{ fill: "#0D9488" }}
          />
        </div>
      );
    case "Co Broke Request":
      return (
        <div
          style={{ backgroundColor: "#CCFBF1" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-profile2user"}
            style={{ fill: "#0D9488" }}
          />
        </div>
      );
    case "Payment Voucher":
      return (
        <div
          style={{ backgroundColor: "#CCFBF1" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-receipt-text"}
            style={{ fill: "#0D9488" }}
          />
        </div>
      );
    default:
      return (
        <div
          style={{ backgroundColor: "#E0F2FE" }}
          className="at-notification__dropdown-badge"
        >
          <AtlasIcon
            svgHref={"atlas-notification"}
            style={{ fill: "#0284C7" }}
          />
        </div>
      );
  }
};

export default NotificationIcon;
