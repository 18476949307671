import React, { useState, useEffect } from "react";
import { Select, ListSubheader, MenuItem } from '@material-ui/core';
import "./multi.scss";

import { getTranslation } from "assets/translation";
import useSelectedLocalised from "utils/hooks/useSelectedLocalised";

const CustomSelect = (props) => {
  const language = useSelectedLocalised();
  const [collapsed, setCollapsed] = useState(true);
  const [currentlySelected, setCurrentlySelected] = useState("");

  useEffect(() => {
    if (props.currentlySelected) {
      setCurrentlySelected(props.currentlySelected.label || "");
    }
  }, [props.currentlySelected]);

  const toggleDropdown = () => {
    setCollapsed(true);
  };

  const selectItem = (item) => {
    setCurrentlySelected(item?.label || "");
    props.updateSelect(item);
  };

  const onClickSelectItems = () => {
    setCollapsed(!collapsed);
  };

  const renderErrorMessage = (error) => {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  };

  const renderRequired = (param) => {
    if (param) {
      return <div className="at-form-input__required">{getTranslation("required", language)}</div>;
    }
  };

  const renderNewSelectItems = () => {
    const { selectItems, selectType, getLocalised } = props;
    if (selectType === "section") {
      return selectItems.map(item => (
        [
          <ListSubheader 
            key={item.name} 
            disableSticky
            className="at-select__dropdown-title">
            {getLocalised ? getLocalised(item.key, item.name) : item.name}
          </ListSubheader>, 
          item.children.map(child => (
            <MenuItem 
              key={child.id} 
              value={child.label}
              className="at-multi_select-dropdown_item"
              onClick={() => selectItem(child)}>
              {getLocalised ? getLocalised(child.key, child.label) : child.label}
            </MenuItem>
          ))
        ]
      ));
    } else {
      return selectItems.map((item) => (
        <MenuItem
          key={item.value}
          value={item.label}
          onClick={() => selectItem(item)}>
          {getLocalised ? getLocalised(item.key, item.label) : item.label}
        </MenuItem>
      ));
    }
  };

  const {
    className,
    removeError,
    required,
    disabled,
    inputError,
    selectType,
    selectItems,
    placeholder = ""
  } = props;

  const checkEmptyObj = (currentlySelected !== null && typeof currentlySelected === "object") && (Object.keys(currentlySelected)?.length === 0 || !currentlySelected.value);

  return (
    <div
      tabIndex="1"
      className={`at-select ${className} ${disabled ? "at-select--disabled" : ""}`}
      onChange={removeError}>
      <div className='position-relative'>
        <Select 
          defaultValue={""} 
          disabled={disabled}
          value={currentlySelected || placeholder}
          className={`at-select__selected-item ${(!currentlySelected || checkEmptyObj) ? "at-select__selected-placeholder" : ""}`}
          classes={{root: "at-multi_select-input_wrapper"}}
          MenuProps={{ 
            classes: {
              paper: "at-multi_select-dropdown"
            }
          }}
          onChange={ e => {
            let tmpSelected = {
              label: "",
              name: "",
              id: "",
              value: ""
            };
            if(selectType === "section") {
              let targetItem = selectItems.find(item => item.children.find(child => child.label === e.target.value));
              if(targetItem && targetItem.children) {
                tmpSelected = targetItem?.children?.find(child => child?.label === e.target.value) || tmpSelected;
              }
            } else {
              tmpSelected = selectItems?.find(item => item?.label === e.target.value) || tmpSelected;
            }
            selectItem(tmpSelected);
          }} 
          id="grouped-select">
          <MenuItem 
            value={props.placeholder}
            className="at-multi_select-dropdown_item at-select__selected-placeholder"
            onClick={() => selectItem({
              label: "",
              name: "",
              id: "",
              value: ""
            })}>
            {props.placeholder || ""}
          </MenuItem>
          {renderNewSelectItems()}
        </Select>
      </div>
      <div
        className="at-input_footer"
        style={ (inputError || required) ? { marginBottom: "1rem" } : {} }>
        {renderRequired(required)}
        {renderErrorMessage(inputError)}
      </div>
    </div>
  );
};

export default CustomSelect;