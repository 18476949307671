import React, { useEffect, useState } from "react";
import { Chip, Card, TextField, Snackbar } from "@material-ui/core";
import { PulseLoader } from "react-spinners";

import _ from "lodash";

import CustomCheckbox from "components/Checkbox";
import CustomTypeahead from "components/Typeahead/new";
import AtlasFormInput from "components/Input/formInput";

import ProfileImage1 from "assets/images/av-male.jpg";

const InternalAgent = ({
  title,
  formData,
  allFormData,
  disabled,
  agentList,
  onLoadAgent,

  onClose,
  onChangeValue,
  submitData,
  getAgentsBySearch,
  setNewAgentContact,
  onChangeAgentHOC,
}) => {
  const [states, setStates] = useState({
    selectedAgent: null,
    showSnackbar: false,
  });
  const { selectedAgent, showSnackbar } = states;

  const { commission_amount, referral_sources } = formData;

  const onChange = (state) => {
    setStates((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  useEffect(() => {
    if (selectedAgent) {
      setNewAgentContact((prev) => ({
        ...prev,
        referral_id: selectedAgent.id,
        full_name: selectedAgent.full_name || "",
        team: selectedAgent.team || "",
        mobile_number: selectedAgent.mobile_contact_number || "",
        email: selectedAgent.email || "",
        avatar_url: selectedAgent.avatar_url || "",
        role: selectedAgent.role || "",
      }));
    }
  }, [selectedAgent]);

  const debounceService = _.debounce(
    (val) => onHandleTypeaheadSearch(val),
    600,
  );
  const onHandleTypeaheadSearch = (val) => {
    onChange({ selectedAgent: null });

    return val.length > 0
      ? getAgentsBySearch(val)
      : onChangeAgentHOC([], "agentList");
  };

  const assign = () => {
    let existing = _.find(allFormData, {
      referral_id: selectedAgent.id,
    });

    if (existing) {
      onChange({ showSnackbar: true });
    } else {
      submitData({
        ...formData,
        referral_type: "User",
      });
    }
  };

  const onChangeIsSelected = (index) => {
    let temp = _.cloneDeep(formData);
    temp.referral_sources[index].is_selected =
      !temp.referral_sources[index].is_selected;
    setNewAgentContact(temp);
  };

  const onChangeReferralSources = (index, val) => {
    let temp = _.cloneDeep(formData);
    temp.referral_sources[index].amount = val;
    setNewAgentContact(temp);
  };

  const renderAgentProfile = ({
    full_name,
    email,
    avatar_url,
    mobile_number,
    team,
  }) => (
    <div className={`row no-gutters`} style={{ gridGap: "1rem" }}>
      <div
        className="col-12 col-md-4 mb-10 p-0"
        style={{ textAlign: "center" }}
      >
        <img
          alt={`Internal Agent`}
          src={avatar_url || ProfileImage1}
          style={{
            borderRadius: "50%",
            maxWidth: 160,
            maxHeight: 160,
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      {
        <div className={"col-12 col-md-7 mt-10 ml-2"}>
          <h6 className="at-subsales_claims-profile_name">{`${full_name}`}</h6>
          <p>{`${email}`}</p>
          <p>{`${mobile_number}`}</p>
          <p>{team ? `${team} team` : ""}</p>
        </div>
      }
    </div>
  );

  const createReferralInternalAgent = () => (
    <div className="at-form_field-col-12">
      <div className="d-flex">
        <h2 className="at-form-input__title">Assign Internal Agent</h2>
        <PulseLoader
          sizeunit={"px"}
          size={10}
          color={"#16232c"}
          loading={onLoadAgent}
        />
      </div>
      <CustomTypeahead
        wrapperClassName="mb-30"
        disabled={onLoadAgent}
        typeaheadId={"internal_agent"}
        options={agentList ? agentList : []}
        selectedValue={selectedAgent ? [selectedAgent] : []}
        filterBy={["full_name", "email", "mobile_contact_number"]}
        labelKey={"full_name"}
        onSearch={(val) => debounceService(val)}
        onSelect={(val) =>
          val && val.length > 0 && onChange({ selectedAgent: val[0] })
        }
        helpText={"e.g. Full Name, Email, Mobile Number"}
        childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
        highlighterData={[
          (option) => `Team: ${option.team_name}`,
          <br />,
          (option) =>
            `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
        ]}
      />
      <div className="at-modal_dialog-footer" style={{left: 0}}>
        <button
          disabled={!selectedAgent}
          className="btn-new btn-new--primary d-block"
          onClick={() => assign()}
        >
          Assign as Referral
        </button>
      </div>
    </div>
  );

  const updateInternalAgent = () => (
    <>
      <section className="at-form_field-col-12">
        <Chip color={"primary"} label={"Internal Agent"} />
      </section>
      <section className="at-form_field-col-12">
        <Card className={"profile-card no-gutters"}>
          {renderAgentProfile(formData.internal_agent)}
        </Card>
      </section>
      <section className="at-form_field-col-6">
        <h2 className="at-form-input__title">Referral Fee Amount (RM)</h2>
        <AtlasFormInput
          type="text"
          disabled={disabled}
          value={`${commission_amount}`}
          onChangeValue={(val) => onChangeValue("commission_amount", val)}
        />
      </section>
      {!_.isNaN(commission_amount) &&
        commission_amount > 0 &&
        title !== "Add" &&
        commission_amount && (
          <section className="at-subsales_claims-commission_deduct-cont at-form_field-col-6">
            <h2 className="at-form-input__title">
              {"Deduct referral fee from (RM)"}
            </h2>
            {referral_sources &&
              referral_sources.length > 0 &&
              referral_sources.map((item, index) => (
                <div
                  key={index}
                  className="w-auto at-subsales_claims-commission_deduct-list p-0 d-inline-flex"
                >
                  <CustomCheckbox
                    labelClassname={"mr-10"}
                    content={item.internal_agent_display_name}
                    checked={item.is_selected}
                    onChangeCheckboxValue={() => onChangeIsSelected(index)}
                  />
                  <TextField
                    disabled={disabled}
                    value={item.amount}
                    onChange={(e) =>
                      onChangeReferralSources(index, e.target.value)
                    }
                  />
                </div>
              ))}
            {!(referral_sources && referral_sources.length > 0) && (
              <div>No referrals found.</div>
            )}
            <p>
              Note: Referral commission should not be more than 20% of
              commission received.
            </p>
          </section>
        )}
      <section className="at-modal_dialog-footer d-flex grid_gap-1" style={{left: 0}}>
        <button
          className="btn-new btn-new--success"
          onClick={() =>
            submitData({
              ...formData,
              referral_sources_attributes: formData.referral_sources,
            })
          }
        >
          Update
        </button>
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={onClose}
        >
          Close
        </button>
      </section>
    </>
  );

  return (
    <>
      {title === "Add" && createReferralInternalAgent()}
      {title === "Edit" && updateInternalAgent()}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => onChange({ showSnackbar: false })}
        message="User is already assigned."
      />
    </>
  );
};

export default InternalAgent;
