import React, { Component } from "react";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaEdit, FaInfoCircle } from "react-icons/fa";

import SVGIcon from "components/Icon";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import { numberWithCommas } from "utils/thousandSeparator";

class Content extends Component {
  state = {
    radioChecked: false,
  };

  toggleExpandTableRow() {
    this.setState((prevState) => ({
      radioChecked: !prevState.radioChecked,
    }));
  }

  onClickDetailsActions = (param) => this.props.detailsClick(param);

  onClickApprove = (param) => this.props.approveClick(param);

  onClickDisapprove = (param) => this.props.disapproveClick(param);

  onClickEditActions = (param) => this.props.editClick(param);

  onClickDeleteActions = (param) => this.props.deleteClick(param);

  onClickUpdateFixedActions = (param) => this.props.updateFixedClick(param);

  onClickUpdateCalendarActions = (param) =>
    this.props.updateCalendarClick(param);

  onClickUpdateAcceptedActions = (param) =>
    this.props.updateAcceptedClick(param);

  onClickSendEmailActions = (param) => this.props.sendEmail(param);

  onClickDownloadTempReceiptActions = (param) =>
    this.props.downloadClick(param);

  checkInClick = (param) => this.props.checkInClick(param);

  checkOutClick = (param) => this.props.checkOutClick(param);

  onClickViewDoc = (param) => this.props.onClickViewDoc(param);

  transferTicket = (param) => this.props.transferTicket(param);

  renderPaymentMethod = (param) => {
    switch (param) {
      case 1:
        return "Cheque Deposit";
      case 2:
        return "Cash Deposit";
      case 3:
        return "Instant Transfer";
      case 4:
        return "IBG";
      case 5:
        return "Foreign Transfer";
    }
  };

  renderActionContent(content) {
    const {
      rowKey,
      actionColumnContent,
      sendEmailDocType,
      downloadDocType,
      rowItem,
      viewDocTooltip,
    } = this.props;
    const tempActionColumnContent = [];
    actionColumnContent.map((item) => {
      return item !== "delete" ? tempActionColumnContent.push(item) : {};
    });
    actionColumnContent.includes("delete") &&
      tempActionColumnContent.push("delete");

    return tempActionColumnContent.map((item) => {
      if (item === "details") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={
              <BsInfoCircleFill
                style={{ width: 20, height: 20, color: "#3B82F6" }}
              />
            }
            tooltip={true}
            tooltipChildren={<span>Detail</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickDetailsActions(content)}
          />
        );
      }
      if (item === "edit") {
        return (
          <CustomButton
            disabled={this.props.disabledEdit}
            key={item}
            className={"svg-icon-info"}
            children={<FaEdit />}
            tooltip={true}
            tooltipChildren={<span>Edit</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickEditActions(content)}
          />
        );
      }
      if (item === "delete") {
        return (
          <CustomButton
            disabled={this.props.disabledDelete}
            containerStyle={{
              ...(this.props.disabledDelete && { opacity: 0.3 }),
            }}
            key={item}
            className={"svg-icon-info"}
            children={
              <SVGIcon className="svg-icon-info--danger" svgHref={"icon-bin"} />
            }
            tooltip={true}
            tooltipChildren={<span>Delete</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickDeleteActions(content)}
          />
        );
      }
      if (item === "view-doc") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={<SVGIcon className="svg-icon-doc" svgHref={"icon-doc"} />}
            tooltip={true}
            tooltipChildren={
              viewDocTooltip ? (
                <span>{viewDocTooltip}</span>
              ) : (
                <span>View Doc</span>
              )
            }
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickViewDoc(content)}
          />
        );
      }
      if (item === "update-fixed") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={
              <SVGIcon className="svg-icon-tick" svgHref={"icon-tick"} />
            }
            tooltip={true}
            tooltipChildren={<span>Mark as fixed</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateFixedActions(content)}
          />
        );
      }
      if (item === "update-scheduled") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={
              <SVGIcon className="svg-icon-info" svgHref={"icon-calendar"} />
            }
            tooltip={true}
            tooltipChildren={<span>Mark as scheduled</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateCalendarActions(content)}
          />
        );
      }
      if (item === "update-accepted") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={
              <SVGIcon className="svg-icon-info" svgHref={"icon-good"} />
            }
            tooltip={true}
            tooltipChildren={<span>Mark as accepted</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickUpdateAcceptedActions(content)}
          />
        );
      }
      if (item === "download") {
        return (
          <CustomButton
            key={item}
            className={"svg-icon-info"}
            children={
              <SVGIcon
                className="svg-icon-info"
                svgHref={"icon-download-doc"}
              />
            }
            tooltip={true}
            tooltipChildren={<span>Click to download</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.props.downloadClick(content)}
          />
        );
      }
      if (item === "download-temp-receipt") {
        if (downloadDocType.indexOf(content.doc_type) > -1) {
          return (
            <CustomButton
              key={item}
              className={"svg-icon-info"}
              children={
                <SVGIcon className="svg-icon-info" svgHref={"icon-download"} />
              }
              tooltip={true}
              tooltipChildren={<span>Download Temp Receipt</span>}
              tooltipPosition={"top"}
              tooltipID={`details-${content[rowKey]}-${item}`}
              onClick={() => this.onClickDownloadTempReceiptActions(content)}
            />
          );
        }
      }
      if (item === "send-email") {
        if (sendEmailDocType.indexOf(content.doc_type) > -1) {
          return (
            <CustomButton
              key={item}
              className={"svg-icon-info"}
              children={
                <SVGIcon className="svg-icon-info" svgHref={"icon-envelope"} />
              }
              tooltip={true}
              tooltipChildren={<span>Email</span>}
              tooltipPosition={"top"}
              tooltipID={`details-${content[rowKey]}-${item}`}
              onClick={() => this.onClickSendEmailActions(content)}
            />
          );
        }
      }
      if (item === "approve") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Approve "}
            disabled={this.props.rowItem.is_approved === "Yes"}
            tooltip={true}
            tooltipChildren={<span>Approve</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.onClickApprove(content)}
          />
        );
      }
      if (item === "check-in") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Clock In "}
            disabled={rowItem.clock_in_time}
            tooltip={true}
            tooltipChildren={<span>Clock In</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.checkInClick(content)}
          />
        );
      }
      if (item === "check-out") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Clock Out "}
            disabled={rowItem.clock_out_time}
            tooltip={true}
            tooltipChildren={<span>Clock Out</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.checkOutClick(content)}
          />
        );
      }
      if (item === "transfer") {
        return (
          <CustomButton
            key={item}
            className={"btn-new btn-new--primary mb-2"}
            children={" Transfer "}
            disabled={false}
            tooltip={true}
            tooltipChildren={<span>Transfer Ticket</span>}
            tooltipPosition={"top"}
            tooltipID={`details-${content[rowKey]}-${item}`}
            onClick={() => this.transferTicket(content)}
          />
        );
      }
    });
  }

  renderTableContentGrid(
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
    styling,
    columnClass,
  ) {
    const {
      booleanColumn,
      actionOnShow,
      thousandSeparatorColumn,
      actionColumnContent,
      transactionColumn,
      mobileColumn,
      clickContent,
    } = this.props;

    const contentProcess = (param) => {
      if (booleanColumn === columnValue) {
        if (content[columnValue] === 0) {
          return (
            <AtlasIcon
              svgHref={"atlas-close-circle"}
              style={{ width: 18, height: 18, fill: "#F04438" }}
            />
          );
        } else {
          return (
            <AtlasIcon
              svgHref={"atlas-tick-circle"}
              style={{ width: 18, height: 18, fill: "#12B76A" }}
            />
          );
        }
      }
      if (transactionColumn === columnValue) {
        if (content[columnValue] === 4) {
          return (
            <>
              <span style={{ fontWeight: 500 }}>Transacted At:</span>
              <p>
                {content.transacted_at
                  ? Moment(content.transacted_at).format("DD MMM YYYY hh:mm a")
                  : "N/A"}
              </p>
              <span style={{ fontWeight: 500 }}>Payment Method:</span>
              <p>
                {content.payment_method_id
                  ? this.renderPaymentMethod(content.payment_method_id)
                  : "N/A"}
              </p>
              <span style={{ fontWeight: 500 }}>Bank:</span>
              <p>{content.bank_name ? content.bank_name : "N/A"}</p>
              <span style={{ fontWeight: 500 }}>Amount:</span>
              <p>{content.amount ? content.amount : "N/A"}</p>
              <span style={{ fontWeight: 500 }}>Reference:</span>
              <p>{content.reference ? content.reference : "N/A"}</p>
            </>
          );
        } else {
          return null;
        }
      }
      if (
        thousandSeparatorColumn &&
        thousandSeparatorColumn.indexOf(columnValue) > -1
      ) {
        param = numberWithCommas(param);
      }
      if (clickContent && clickContent.indexOf(columnValue) > -1) {
        return (
          <Tooltip
            placement={"right"}
            title={<span style={{ fontSize: 14 }}>Click to view</span>}
          >
            <button
              className="at-table-row__item-label-click"
              style={{ textAlign: "left" }}
              onClick={() => this.onClickDetailsActions(content)}
            >
              <FaInfoCircle
                style={{ width: 17, height: 17, marginRight: 10 }}
              />
              {param}
            </button>
          </Tooltip>
        );
      }
      if (mobileColumn === columnValue) {
        return (
          <>
            {param}
            <AtlasIcon
              svgHref={"atlas-whatsapp"}
              className={"btn-new--social-media"}
              style={{ fill: "#12B76A", borderRadius: "50%" }}
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=${param}`,
                  "_blank",
                )
              }
            />
          </>
        );
      }
      return param;
    };
    const renderHighlightColumn = (param) => {
      if (param && param.columnValue === columnValue) {
        return "grid-top-object";
      }
      return "";
    };
    return (
      <>
        {index !== "Actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} 
              ${renderHighlightColumn(this.props.headerRearrangeData)}
              ${
                columnClass && typeof columnClass === "function"
                  ? columnClass(content)
                  : columnClass || ""
              }`}
            style={{ width: columnWidth ? columnWidth : "100%", ...styling }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            <p style={{ width: "fit-content", display: "contents" }}>
              {contentProcess(content[columnValue])}
            </p>
          </li>
        )}
        {index === "Actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__actions ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{ width: `${actionColumnContent.length * 70}%` }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            {(!actionOnShow || (actionOnShow && actionOnShow(content))) &&
              this.renderActionContent(content)}
          </li>
        )}
      </>
    );
  }

  render = () => {
    const { headerData, actionColumn, rowItem, secondLayerRow } = this.props;
    return (
      <div className="at-table-row-cont">
        <div
          className="at-table__height-control"
          style={{ height: this.state.radioChecked ? "370px" : "initial" }}
        >
          <ul className="at-table-row grid-controller">
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                columnItem.value,
                rowItem,
                columnItem.label,
                columnItem.width,
                columnItem.containerStyle,
                columnItem.columnClass,
              );
            })}
            {actionColumn
              ? this.renderTableContentGrid("Actions", "Actions", rowItem)
              : ""}
          </ul>
          {secondLayerRow &&
            rowItem.sub_sale_claim_submissions &&
            rowItem.sub_sale_claim_submissions.length > 0 && (
              <div
                className="at-table-row grid-controller"
                style={{
                  padding: "15px",
                  paddingLeft: "30px",
                  flexDirection: "column",
                }}
              >
                <h6>Claim Submissions</h6>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>Submitted Date</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Admin</th>
                  </tr>
                  {rowItem.sub_sale_claim_submissions &&
                    rowItem.sub_sale_claim_submissions.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.submitted_date}</td>
                          <td>{item.status}</td>
                          <td>{item.type}</td>
                          <td>{item.admin}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            )}
        </div>
      </div>
    );
  };
}

export default Content;