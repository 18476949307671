import React, { Component } from "react";
import numeral from "numeral";

import AtlasIcon from "components/Icon/atlasIcon";
import EmptyState from "components/EmptyState";

const colorBar = ["#84C4FF", "#F6C930", "#F1A6F7", "#F1A6F7"];

class CampaignTrackerStat extends Component {
  render = () => {
    let {
      className,
      personalSummary,
      maxHeight,
      onChangeCampaignTrackerHOC,
      getSelectedPromotion,
    } = this.props;

    return (
      <div className="at-campaign_stat__container">
        <div className={`at-form__content at-campaign__card ${className}`}>
          <p className="campaign_stat__label"> Campaign Tracker {new Date().getFullYear()}</p>
          {personalSummary &&
            personalSummary.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "-webkit-center",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex at-campaign-stats-bar">
                      <div
                        style={{
                          width:
                            item.accumulatedValue > 0
                              ? `calc(100%/${Number(item.targetValue * 10)}*${Number(item.accumulatedValue * 10)})`
                              : "0px",
                          background: item.isEligible
                            ? "#12B76A"
                            : colorBar[index % 3],
                          content: "",
                          height: 60,
                        }}
                      ></div>
                    </div>
                    <div className="at-campaign-stats-bar__label">
                      <div>
                        <div className="at-campaign-stats-bar__label-title">
                          <span> {item.promotionName} </span>
                          <div className="at-campaign-stats-bar__label-ratio">
                            {" "}
                            {numeral(item.accumulatedValue).format(
                              "0.0a",
                            )} / {numeral(item.targetValue).format("0.0a")}{" "}
                          </div>
                        </div>
                        <div className="at-campaign-stats-bar__percent">
                          {item.isEligible ? (
                            <AtlasIcon
                              svgHref={"atlas-tick-circle"}
                              style={{
                                width: "24px",
                                height: "24px",
                                fill: "white",
                              }}
                            />
                          ) : (
                            <span>
                              {" "}
                              {item.progressRatio === Infinity
                                ? 100
                                : item.progressRatio === 0
                                  ? 0
                                  : (item.progressRatio * 100).toFixed(2)}
                              %{" "}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="at-campaign-stats-bar__info"
                      onClick={() => {
                        onChangeCampaignTrackerHOC(item, "selectPromotion");
                        onChangeCampaignTrackerHOC(
                          false,
                          "showSaleEligibility",
                        );
                        getSelectedPromotion(item.promotionId, true);
                      }}
                    >
                      <AtlasIcon
                        svgHref={"atlas-info-circle-fill"}
                        style={{
                          width: 20,
                          height: 21,
                          marginRight: 4,
                          fill: "#374151",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ clear: "both" }} />
                </>
              );
            })}
          {!(personalSummary && personalSummary.length > 0) && (
            <EmptyState
              title={`No data is available`}
              renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
            />
          )}
        </div>
      </div>
    );
  };
}

export default CampaignTrackerStat;
