import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Get, Post, Delete } from "utils/axios";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showDeleteConfirmationModal: false,

      supportingDocs: [],
      selectedDeleteDocsId: null,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSupportHOC = (val, context) => this.setState({ [context]: val });

    // get supporting docs for a request
    getSupportingDocs = (confirmationId, requestId) =>
      Get(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}/documents`,
        this.getSupportingDocsSuccess,
        this.getSupportingDocsError,
        this.load,
      );
    getSupportingDocsSuccess = (payload) =>
      this.setState({ supportingDocs: payload });
    getSupportingDocsError = (error) => requestError(error);

    // create new supporting docs
    createSupportingDocs = (val, confirmationId, requestId) =>
      Post(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}/documents`,
        val,
        this.createSupportingDocsSuccess,
        this.createSupportingDocsError,
        this.load,
      );
    createSupportingDocsSuccess = () => {
      this.getSupportingDocs(
        this.props.selected_confirmation_form.id,
        this.props.selectedUnlockRequest.id,
      );
      requestSuccess("Document uploaded successfully.");
    };
    createSupportingDocsError = (error) => requestError(error);

    // delete supporting docs
    deleteSupportingDocs = (confirmationId, requestId, docId) =>
      Delete(
        `/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}/documents/${docId}`,
        this.deleteSupportingDocsSuccess,
        this.deleteSupportingDocsError,
        this.load,
      );
    deleteSupportingDocsSuccess = () => {
      this.getSupportingDocs(
        this.props.selected_confirmation_form.id,
        this.props.selectedUnlockRequest.id,
      );
      this.setState({
        showDeleteConfirmationModal: false,
      });
      requestSuccess("Document deleted succesfully.");
    };
    deleteSupportingDocsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadSupportDocs={this.state.loading}
            supportingDocs={this.state.supportingDocs}
            selectedDeleteDocsId={this.state.selectedDeleteDocsId}
            showDeleteConfirmationModal={this.state.showDeleteConfirmationModal}
            getSupportingDocs={this.getSupportingDocs}
            onChangeSupportHOC={this.onChangeSupportHOC}
            createSupportingDocs={this.createSupportingDocs}
            deleteSupportingDocs={this.deleteSupportingDocs}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
