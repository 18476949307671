import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Post } from "utils/axios";

const GenerateDescriptionHOC = (WrappedComponent) => {
  class HOCWrappedComponent extends Component {
    state = {
      loading: false,
      generateDescription: "",
    };

    aiGenerateDescription = (dataToSubmit) =>
      Post(
        `/sub_sales/generate_description?locale=${this.props.data.languageReducer.language}`,
        dataToSubmit,
        this.aiGenerateDescriptionSuccess,
        this.aiGenerateDescriptionError,
        (load) => this.setState({ loading: load }),
      );
    aiGenerateDescriptionSuccess = (payload) => {
      if (payload.data && !_.isEmpty(payload.data)) {
        this.setState({
          generateDescription: payload.data,
        });
      }
    };
    aiGenerateDescriptionError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            generateDescription={this.state.generateDescription}
            onLoadGenerateDescription={this.state.loading}
            aiGenerateDescription={this.aiGenerateDescription}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps)(HOCWrappedComponent);
};

export default GenerateDescriptionHOC;
