import React from "react";
import { AiFillIdcard } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";

import defaultAvatar from "assets/images/av-male.jpg";

const CardData = [
  {
    label: "Name",
    icon: <FaUserAlt style={{ width: 17, height: 17 }} />,
    value: "full_name",
  },
  {
    label: "ID/NRIC Number",
    icon: <AiFillIdcard style={{ width: 17, height: 17 }} />,
    value: "identification_number",
  },
  {
    label: "Email",
    icon: <MdEmail style={{ width: 17, height: 17 }} />,
    value: "email",
  },
  {
    label: "Mobile",
    icon: <ImMobile style={{ width: 17, height: 17 }} />,
    value: "phone_number",
  },
];

export default function AgentInfo({ selectedData }) {
  return (
    <div
      className="at-form__content"
      style={{
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.7)",
        padding: 16,
        width: "max-content",
        height: "max-content",
        left: "-250%",
        zIndex: 999,
      }}
    >
      <div className="d-flex justify-content-center">
        <img
          src={selectedData.avatar_url}
          style={{
            height: 120,
            width: 120,
            objectFit: "cover",
            borderRadius: "50%",
            marginBottom: 10,
          }}
          alt="agent avatar photo"
          onError={(e) => {
            if (e.target) {
              e.target.src = defaultAvatar;
            }
          }}
        />
      </div>
      {CardData.map((field) => (
        <div className={"mb-2 d-flex align-items-center text-white"}>
          {field.icon}
          <div style={{ fontSize: 13, marginLeft: 10, textAlign: "left" }}>
            <strong style={{ opacity: 0.6 }}>{field.label}</strong>
            <span className="mt-1 d-block">
              {selectedData[field.value] || "N/A"}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
