import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Card } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { BsTrash3Fill } from "react-icons/bs";
import { MdPauseCircleOutline } from "react-icons/md";
import {
  IoIosAddCircle,
  IoMdPlayCircle,
  IoIosImage,
  IoMdMailOpen,
} from "react-icons/io";

import CustomButton from "components/Button";

import permissionsChecker from "utils/permissionsChecker";

const DatepickerInput = ({ ...props }) => (
  <input type="text" style={{ backgroundColor: "#ffff" }} {...props} readOnly />
);

class MOTDManagementCard extends Component {
  state = {
    audio: new Audio(),
    is_playing: false,
    audioUpload: {},
    timer: "",
  };

  fileInput = null;
  fileInputRef = (element) => (this.fileInput = element);
  focusFileInput = () => this.fileInput && this.fileInput.click();
  onClickAddAudio = (item_id) => {
    this.setState({
      audioUpload: {
        id: item_id,
      },
    });
    this.focusFileInput();
  };

  onChangeMOTD = (index, context, val) => {
    let temp = _.cloneDeep(this.props.MOTD);
    let temp2 = temp[index];
    temp2[context] = val;
    temp[index] = temp2;
    this.props.onChangeMOTDHOC(temp, "MOTD");
  };

  onUploadAudio = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = event.target.files;
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let temp = _.cloneDeep(this.state.audioUpload);
          Promise.all([
            (temp["audio"] = e.target.result),
            (temp["audio_file_name"] = files[key].name),
          ]).then(() => {
            this.props.updateMOTD(temp, () => {
              this.setState({ audioUpload: {} });
              event.target.files = null;
            });
          });
        };
        reader.readAsDataURL(event.target.files[key]);
      });
    }
  };

  setTimer = (index, duration, status) => {
    if (status === "set") {
      let tmp = setTimeout(() => {
        this.state.audio.pause();
        this.onChangeMOTD(index, "play", false);
        this.setState({ is_playing: false });
      }, duration);
      this.setState({ timer: tmp });
    } else {
      clearTimeout(this.state.timer);
    }
  };

  onClickPlayAudio = (audioURL, index) => {
    if (!this.state.is_playing) {
      this.onChangeMOTD(index, "play", true);
      this.setState(
        {
          audio: new Audio(audioURL),
          is_playing: true,
        },
        () => {
          Promise.all([this.state.audio.play()]).then(() => {
            this.setTimer(index, this.state.audio.duration * 1000, "set");
          });
        },
      );
    }
  };

  onClickPauseAudio = (index) => {
    this.onChangeMOTD(index, "play", false);
    this.state.audio.pause();
    this.setState({ is_playing: false });
    this.setTimer();
  };

  onChangeQuoteDate = (index, item_id, val) => {
    this.onChangeMOTD(
      index,
      "published_date",
      Moment(val).format("YYYY-MM-DD"),
    );
    let temp = _.cloneDeep(this.props.bulkUpdate);
    let updateObject = {
      id: item_id,
      published_date: Moment(val).format("YYYY-MM-DD"),
    };
    temp.push(updateObject);
    this.props.onChangeMOTDHOC(temp, "bulkUpdate");
  };

  render() {
    const { can_update, can_destroy } = permissionsChecker(
      "MOTD",
      this.props.data,
    );

    return (
      <div className="row">
        {this.props.MOTD.map((item, index) => {
          return (
            <div className="col-lg-6 at-motd-mngm__card" key={index}>
              <Card
                className={`at-motd-mngm__card-content mt-2 mx-2 ${item.is_expired ? "at-motd-mngn__card-expired" : ""}`}
              >
                <div className="d-flex">
                  <input
                    ref={this.fileInputRef}
                    type={"file"}
                    onClick={(event) => (event.target.value = null)}
                    onChange={(event) => {
                      this.onUploadAudio(event);
                    }}
                    style={{ display: "none" }}
                    accept=".mp3,audio/*"
                  />
                  <CustomButton
                    style={{
                      color: "#F18E05",
                      backgroundColor: "#FFFF",
                      borderRadius: "50%",
                    }}
                    disabled={!can_update}
                    containerStyle={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      top: "30%",
                      left: "0",
                      width: "100%",
                    }}
                    onClick={() => this.onClickAddAudio(item.id)}
                    className={"d-flex"}
                    children={
                      <IoIosAddCircle
                        style={{ width: "50px", height: "50px" }}
                      />
                    }
                    tooltip={true}
                    tooltipPosition={"bottom"}
                    tooltipID={"add"}
                    tooltipChildren={"Add Audio"}
                    additionalContent={
                      item.audio_url && (
                        <CustomButton
                          style={{
                            color: "#eabb4e",
                            backgroundColor: "#FFFF",
                            borderRadius: "50%",
                          }}
                          containerStyle={{ marginLeft: "20px" }}
                          onClick={() =>
                            item.play
                              ? this.onClickPauseAudio(index)
                              : this.onClickPlayAudio(item.audio_url, index)
                          }
                          className={"d-flex"}
                          children={
                            item.play ? (
                              <MdPauseCircleOutline
                                style={{ width: "50px", height: "50px" }}
                              />
                            ) : (
                              <IoMdPlayCircle
                                style={{ width: "50px", height: "50px" }}
                              />
                            )
                          }
                          tooltip={false}
                        />
                      )
                    }
                  />
                  <img
                    className="at-motd-mngm__card-image"
                    src={item.image_url}
                    alt={index}
                  />
                </div>
                <div className="d-flex py-3">
                  <div className="m-auto d-flex" style={{ width: "300px" }}>
                    <DatePicker
                      showYearDropdown={true}
                      dateFormat="DD MMM YYYY"
                      className={`form-control ${item.published_date ? "" : "at-motd-mngm__card-empty-date-picker"}`}
                      minDate={new Date()}
                      disabled={item.is_expired || !can_update}
                      customInput={<DatepickerInput />}
                      value={item.published_date || "Select Date"}
                      onChange={(val) =>
                        this.onChangeQuoteDate(index, item.id, val)
                      }
                    />
                    <CustomButton
                      containerStyle={{
                        display: "flex",
                        margin: "0 0 0 auto",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        Promise.all([
                          this.props.onChangeMOTDHOC(
                            {
                              ...item,
                              audio: new Audio(item.audio_url),
                            },
                            "previewMOTD",
                          ),
                        ]).then(() => {
                          this.props.onChangeMOTDHOC(true, "showAttachEvent");
                        });
                      }}
                      style={{ color: "#ffda79", borderColor: "#ffda79" }}
                      isIconButton={true}
                      disabled={item.is_expired}
                      className={"d-flex at-motd-mngn__card-option-btn"}
                      children={<IoMdMailOpen />}
                      tooltip={true}
                      tooltipPosition={"bottom"}
                      tooltipID={"attach"}
                      tooltipChildren={"Attach Event"}
                    />
                    <CustomButton
                      containerStyle={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        Promise.all([
                          this.props.onChangeMOTDHOC(
                            {
                              ...item,
                              audio: new Audio(item.audio_url),
                            },
                            "previewMOTD",
                          ),
                        ]).then(() => {
                          this.props.onChangeMOTDHOC(true, "showMOTDPreview");
                        });
                      }}
                      style={{ color: "#63BF71", borderColor: "#63BF71" }}
                      isIconButton={true}
                      className={"d-flex at-motd-mngn__card-option-btn"}
                      children={<IoIosImage />}
                      tooltip={true}
                      tooltipPosition={"bottom"}
                      tooltipID={"preview"}
                      tooltipChildren={"Preview MOTD"}
                    />
                    <CustomButton
                      containerStyle={{ display: "flex", alignItems: "center" }}
                      style={{ color: "red", borderColor: "red" }}
                      onClick={() => {
                        Promise.all([
                          this.props.onChangeMOTDHOC(
                            item.id,
                            "selectedDeleteQuote",
                          ),
                        ]).then(() => {
                          this.props.onChangeMOTDHOC(
                            true,
                            "showDeleteConfirmation",
                          );
                        });
                      }}
                      isIconButton={true}
                      disabled={item.is_expired || !can_destroy}
                      className={"d-flex at-motd-mngn__card-option-btn"}
                      children={<BsTrash3Fill />}
                      tooltip={true}
                      tooltipPosition={"bottom"}
                      tooltipID={"delete"}
                      tooltipChildren={"Delete MOTD"}
                    />
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}

export default MOTDManagementCard;
