import React, { useState, useCallback } from "react";
import _ from "lodash";
import { Dialog } from "@material-ui/core";
import Moment from "moment";

import LoadingModal from "components/LoadingModal";
import AtCustomTab from "components/Tab";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import OrderTable from "./OrderTable";
import AtCustomActivity from "./activity";

import isEmptyValue from "utils/isEmpty";
import { OrderIteamHeader } from "../assets";
import defaultAvatar from "assets/images/av-male.jpg";
import "./detailsDialog.scss";

const DetailsDialog = ({
  selectedOrder,
  onClose,
  onLoad,
  onCheckItemCheckBoxValue,
}) => {
  const [showNameCardModal, setShowNameCardModal] = useState(false);
  const [selectedSection, onSelectSection] = useState("Order Items");

  const renderCardContent = useCallback(() => {
    let {
      market_order_items = [],
      audits = [],
      order_number = "N/A",
      created_at = "N/A",
      total_amount = 0,
      collected_date,
      user,
    } = selectedOrder;

    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton onClick={() => onClose()} />
          <h4 className="at-card__title">Order Details</h4>
        </div>
        <CustomCard
          className="at-section_card p-0"
          cardContent={
            <>
              <div className={"at-card--profile_card bg-white m-0"}>
                <div
                  className={"at-card--profile_card-img pt-3"}
                  style={{ background: "#F18E05" }}
                >
                  <img
                    onError={(e) => {
                      if (e.target) {
                        e.target.src = defaultAvatar;
                      }
                    }}
                    src={
                      user.avatar_url &&
                      !_.includes(user.avatar_url, "missing.png")
                        ? user.avatar_url
                        : defaultAvatar
                    }
                  />
                </div>
                <div className={"at-card--profile_card-top bg-primary"}>
                  <h4 className="at-card__title" style={{ color: "white" }}>
                    {user.full_name || "N/A"}
                  </h4>
                  <p>{user.full_name || "N/A"}</p>
                </div>
                <div
                  className={"at-card--profile_card-btm"}
                  style={{ maxWidth: "82rem", paddingBottom: 8}}
                >
                  <p>{user.job_title || "N/A"}</p>
                  <p>{user.email}</p>
                  <p>{user.branch_name}</p>
                  <p>{user.team_name} Team </p>
                  <p>{user.mobile_contact_number}</p>
                  <p>
                    {" "}
                    Via Recruitment Campaign:{" "}
                    {user.via_recruitment_campaign_text ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </>
          }
        />
        <div style={{ display: "flex" }}>
          <h2 className="at-form-input__title py-4 px-2">
            {`Order No.`}
            <div className="at-order-number ml-2">{order_number}</div>
          </h2>
          <h2 className="at-form-input__title py-4 px-2">
            {`Order Date`}
            <div className="at-order-number ml-2">{`${
              isEmptyValue(created_at)
                ? created_at
                : Moment(created_at, "DD-MM-YYYY").format("DD MMM YYYY")
            }`}</div>
          </h2>
          {collected_date && (
            <h2 className="at-form-input__title py-4 px-2">
              {`Collection Date`}
              <div className="at-order-number ml-2">{`${Moment(collected_date, "YYYY-MM-DD").format("DD MMM YYYY")}`}</div>
            </h2>
          )}
        </div>
        <AtCustomTab
          containerClass="mx-n3 mx-xl-0"
          sections={["Order Items", "Activity"]}
          selectedSection={selectedSection}
          onSelectSection={onSelectSection}
        />
        {selectedSection === "Order Items" && (
          <CustomCard
            className="bg-white p-0 m-xl-0 mx-n3 mt-0 p-xl-4"
            cardContent={
              <div className="at-order__order-item">
                <OrderTable
                  headerData={OrderIteamHeader}
                  rowData={market_order_items}
                  isPriceData={["price", "total_price"]}
                  subtotalValue={total_amount}
                  originalRowData={market_order_items}
                  onCheckItemCheckBoxValue={onCheckItemCheckBoxValue}
                  onClickViewNameCard={() => setShowNameCardModal(true)}
                />
              </div>
            }
          />
        )}
        {selectedSection === "Activity" && (
          <CustomCard
            className="at-order-activity bg-white p-0 m-xl-0 mx-n3 mt-0 p-xl-4"
            cardContent={<AtCustomActivity activities={audits} />}
          />
        )}
      </>
    );
  }, [selectedOrder, selectedSection]);

  return (
    <>
      <CustomCard
        className={`h-100 at-order__order-detail_cont`}
        cardContent={renderCardContent()}
      />
      <Dialog open={showNameCardModal}>
        <CustomCard
          containerStyle={{ minHeight: 300, minWidth: 300, padding: 0 }}
          cardContent={
            <>
              <AtlasCloseButton
                containerStyle={{ position: "absolute", top: 10, right: 10 }}
                onClick={() => setShowNameCardModal(false)}
              />
              <img
                src={selectedOrder.name_card_photo_url}
                style={{ width: "100%", height: "100%" }}
              />
            </>
          }
        />
      </Dialog>
      {onLoad && <LoadingModal />}
    </>
  );
};

export default DetailsDialog;
