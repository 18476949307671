import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";
import AtlasIcon from "components/Icon/atlasIcon";

const Agents = ({ iconLib = [], hideKeys, data, itemContColumn = 6 }) => {
  return (
    <div
      className={`at-unit_info-form_field ${hideKeys ? "at-form_field-list" : "at-form__content"} mb-0 at-form_fields_cont at-form_field-col-${itemContColumn}`}
    >
      {Object.keys(data).map((key) => {
        const { icon = "" } =
          _.find(iconLib, (item) => item.label === key) || {};
        return (
          <div
            key={key}
            className={`at-unit_info-form_field-item ${hideKeys ? "at-form_field-item_list" : "at-form_fields_cont"} at-form_field-col-12`}
          >
            {(iconLib.length > 0 || !hideKeys) && (
              <div className="at-form_field-col-5 at-form_field-icon_title">
                {iconLib.length > 0 && icon && (
                  <AtlasIcon
                    className="at-icon_svg-listing-title"
                    svgHref={icon}
                  />
                )}
                {iconLib.length > 0 && !icon && (
                  <div className="at-icon_svg-listing-empty_title"></div>
                )}
                {!hideKeys && <p className="at-form-input__title">{key}</p>}
              </div>
            )}
            <p className={`at-form_field-col-${hideKeys ? "12" : "7"}`}>
              {data[key] || "-"}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Agents;
