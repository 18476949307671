import React, { Component } from "react";
import _ from "lodash";
import Axios from "axios";
import { connect } from "react-redux";

import { Post } from "utils/axios";
import getDomainURL from "utils/getDomainURL";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      uploadedDocs: [
        {
          snapshot: "",
          snapshot_file_name: "",
          type_id: "",
          done: false,
          success: false,
          errorMessage: "",
          loading: true,
        },
      ],
      OCRContent: {},
      uploadedDocsCount: 0,
      showUploadedDocs: false,
      loading: false,
      onLoadOCR: false,
    };

    onChangeUploadedDocsHOC = (key, val) => {
      let tmp = _.cloneDeep(this.state.uploadedDocs);
      tmp[key] = val;
      return this.setState({ uploadedDocs: tmp });
    };

    bankInSlipOCR = (dataToSubmit, onSuccess) =>
      Post(
        `/ocr/bank_slip`,
        dataToSubmit,
        (payload) => this.bankInSlipOCRSuccess(payload, onSuccess),
        this.bankInSlipOCRError,
        (param) => this.setState({ onLoadOCR: param }),
      );
    bankInSlipOCRSuccess = (payload, onSuccess) => {
      let temp = _.cloneDeep(payload.data[0]);
      if (temp.payment_method.length > 0) {
        temp.remark = temp.payment_method.concat(temp.reference);
      } else {
        temp.remark = temp.reference;
      }

      this.setState({ OCRContent: temp }, () => onSuccess());
    };
    bankInSlipOCRError = (error) => requestError(error);

    closeUploadedDocsList = () => {
      this.setState(
        {
          uploadedDocs: [
            {
              snapshot: "",
              snapshot_file_name: "",
              type_id: "",
              done: false,
              success: false,
              errorMessage: "",
              loading: true,
            },
          ],
          uploadedDocsCount: 0,
          showUploadedDocs: false,
        },
        () => {
          this.props.getDocuments(this.props.selected_claim.id);
        },
      );
    };

    uploadMultipleDocuments = (id, dataToSubmit) => {
      if (dataToSubmit && dataToSubmit.length > 0) {
        this.setState(
          { loading: true, showUploadedDocs: true, uploadedDocs: dataToSubmit },
          async () => {
            for (let index = 0; index < dataToSubmit.length; index++) {
              try {
                {
                  await Axios.post(
                    `${getDomainURL()}/sub_sale_claims/${id}/documents`,
                    dataToSubmit[index],
                  );

                  let tmpUploadedDocsCount = _.cloneDeep(
                    this.state.uploadedDocsCount,
                  );
                  let tmpUploadedDocs = _.cloneDeep(this.state.uploadedDocs);
                  tmpUploadedDocs[index] = {
                    ...tmpUploadedDocs[index],
                    done: true,
                    success: true,
                    errorMessage: "",
                    loading: false,
                  };
                  this.setState({
                    uploadedDocsCount: tmpUploadedDocsCount + 1,
                    uploadedDocs: tmpUploadedDocs,
                  });
                }
              } catch (error) {
                let tmpUploadedDocsCount = _.cloneDeep(
                  this.state.uploadedDocsCount,
                );
                let tmpUploadedDocs = _.cloneDeep(this.state.uploadedDocs);
                tmpUploadedDocs[index] = {
                  ...tmpUploadedDocs[index],
                  done: true,
                  success: false,
                  errorMessage: error.response
                    ? error.response.data.message
                    : "You might be disconnected from the internet because the system is unable to access to the server.",
                  loading: false,
                };
                this.setState({
                  uploadedDocsCount: tmpUploadedDocsCount + 1,
                  uploadedDocs: tmpUploadedDocs,
                });
              }
            }
            this.setState({ loading: false });
          },
        );
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadOCR={this.state.onLoadOCR}
          OCRContent={this.state.OCRContent}
          uploadedDocs={this.state.uploadedDocs}
          showUploadedDocs={this.state.showUploadedDocs}
          onLoadMultipleDocs={this.state.loading}
          bankInSlipOCR={this.bankInSlipOCR}
          onChangeUploadedDocsHOC={this.onChangeUploadedDocsHOC}
          uploadMultipleDocuments={this.uploadMultipleDocuments}
          closeUploadedDocsList={this.closeUploadedDocsList}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
