import React, { useEffect, useState, useCallback } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AtButton from "components/Button";
import AtlasCloseButton from "components/Button/prev";
import AtlasTextarea from "components/Input/textarea";
import CustomFormInput from "components/Input/formInput";
import CustomDialog from "components/Dialog";
import CustomCard from "components/Card";
import SVGIcon from "components/Icon";
import UnclaimedList from "./unclaimedList";
import { confirmation_form_request } from "dictionary/index";

const RequestConfirmationForm = ({
  data,
  branches,
  permissionsChecker,
  selected_request,
  current_status_id,

  closeFormDialog,
  onClickSend,
}) => {
  const [formData, onChangeForm] = useState({});
  const [showUnclaimedListDialog, onToggleUnclaimed] = useState(false);
  const { can_update } = permissionsChecker;
  const { role } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  useEffect(() => {
    onChangeForm({
      id: selected_request.id,
      status_id: selected_request.status_id,
      branch_id: selected_request.branch_id,
      reason: selected_request.reason,
      quantity: selected_request.quantity,
      current_balance: selected_request.current_balance,
      unclaimed_quantity: selected_request.unclaimed_quantity,
      remark: selected_request.remark,
      agent_name: selected_request.agent_name,
      confirmation_forms: selected_request.confirmation_forms,
    });
  }, []);

  const onChangeField = useCallback(
    (val, context) =>
      onChangeForm({
        ...formData,
        [context]: val,
      }),
    [formData],
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={closeFormDialog} />
        <h5 className="at-card__title">
          {"Request for Confirmation Form - Admin"}
        </h5>
      </div>
      <div className="grid-control">
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Agent Name</h2>
          <CustomFormInput
            type="text"
            disabled={true}
            value={formData.agent_name}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Branch</h2>
          <Select
            value={formData.branch_id}
            disabled={!can_update}
            onChange={(e) => onChangeField(e.target.value, "branch_id")}
          >
            {branches &&
              branches.length > 0 &&
              branches.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            Reason
          </h2>
          <AtlasTextarea
            className={"flex-1"}
            value={formData.reason}
            onChangeValue={(val) => onChangeField(val, "reason")}
            required={true}
            placeholder={"reason"}
            disabled={true}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Current Balance</h2>
          <p>{formData.current_balance}</p>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Unclaimed Quantity</h2>
          <span className="d-flex">
            <p style={{ lineHeight: "2.1" }}>{formData.unclaimed_quantity}</p>
            {current_status_id < 2 && can_update && (
              <AtButton
                containerStyle={{ marginLeft: "10px" }}
                className={`at-table-icon-button`}
                children={
                  <SVGIcon className="svg-icon-info" svgHref={"icon-info"} />
                }
                tooltip={true}
                tooltipChildren={
                  <span>View Unclaimed Confirmation Forms Lists</span>
                }
                tooltipPosition={"top"}
                tooltipID={`unclaimed-quantity`}
                onClick={() => onToggleUnclaimed(true)}
              />
            )}
          </span>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            Request Quantity
          </h2>
          <CustomFormInput
            type="number"
            value={formData.quantity}
            onChangeValue={(val) =>
              onChangeField(parseInt(val, 10), "quantity")
            }
            required={true}
            disabled={!can_update && role !== "Admin"}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Status</h2>
          <p>{selected_request.status}</p>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Remark</h2>
          <AtlasTextarea
            className={"flex-1"}
            value={formData.remark}
            disabled={!can_update && role !== "Admin"}
            onChangeValue={(val) => onChangeField(val, "remark")}
            placeholder={"remark"}
          />
        </section>
      </div>
      <div className="my-3">
        {current_status_id < 2 && can_update && (
          <>
            <div className="d-flex flex-wrap grid_gap-2">
              <button
                className={"btn-new btn-new--success"}
                onClick={() => {
                  formData.status_id = 2;
                  onClickSend(formData.id, formData);
                }}
              >
                Approve
              </button>
              <button
                className={"btn-new btn-new--danger"}
                onClick={() => {
                  formData.status_id = 3;
                  onClickSend(formData.id, formData);
                }}
              >
                Reject
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={closeFormDialog}
              >
                Close
              </button>
            </div>
          </>
        )}
        {current_status_id > 1 && (
          <div className="d-flex">
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={closeFormDialog}
            >
              Close
            </button>
          </div>
        )}
      </div>
      <CustomDialog open={showUnclaimedListDialog}>
        <CustomCard
          className={"h-100"}
          cardContent={
            <UnclaimedList
              data={formData.confirmation_forms}
              closeDialog={() => onToggleUnclaimed(false)}
            />
          }
        />
      </CustomDialog>
    </>
  );
};

export default RequestConfirmationForm;
