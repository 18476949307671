import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: true,
      branches: [],
    };

    load = (param) => this.setState({ loading: param });

    getBranches = () =>
      Get(
        `/branches?[q][branch_selections_type_id_eq]=4`,
        this.getBranchesSuccess,
        this.getBranchesError,
        this.load,
      );
    getBranchesSuccess = (payload) =>
      this.setState({
        branches: payload?.map((branch) => ({
          ...branch,
          label: branch.name,
          value: branch.id,
        })),
      });
    getBranchesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadMeetingHub={this.state.loading}
            getBranches={this.getBranches}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
