import React, { Component, Fragment } from "react";
import _ from "lodash";

import { requestError } from "utils/requestHandler";

import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      supportingDocs: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeSupportHOC = (val, context) => this.setState({ [context]: val });

    // get supporting docs for a request
    getSupportingDocs = (confirmationId, requestId) =>
      Get(
        `/admin/forms/confirmation_forms/${confirmationId}/unlock_requests/${requestId}/documents`,
        this.getSupportingDocsSuccess,
        this.getSupportingDocsError,
        this.load,
      );
    getSupportingDocsSuccess = (payload) =>
      this.setState({ supportingDocs: payload });
    getSupportingDocsError = (error) => requestError(error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            onLoadSupport={this.state.loading}
            supportingDocs={this.state.supportingDocs}
            getSupportingDocs={this.getSupportingDocs}
            onChangeSupportHOC={this.onChangeSupportHOC}
          />
        </Fragment>
      );
    };
  }
  return WithHOC;
};

export default HOC;
