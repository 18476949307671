import React, { Component } from "react";
import _ from "lodash";
import { MdFilterList } from "react-icons/md";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import SvgIcon from "components/Icon/new";
import EmptyState from "components/EmptyState";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import TableContent from "./Content";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";
import "./index.scss";

class PaginatedTable extends Component {
  state = {
    interval: 10,
    showSearchContent: false,
  };

  componentDidMount = () => {
    let tmp = _.cloneDeep(this.props.searchParams);
    tmp.map((item) => {
      item.param = "";
    });
    this.props.onChangeSearchParams(tmp);
  };

  renderTableContent = () => {
    return this.props.rowData.map((item) => (
      <TableContent
        rowItem={item}
        can_update={this.props.can_update}
        onClickStats={this.props.onClickStats}
        onClickEdit={this.props.onClickEdit}
      />
    ));
  };

  renderPaginationText = () => {
    const { meta } = this.props;
    return (
      <p className={`at-table__pagination-info`}>
        Showing
        <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
        of
        <span> {meta && meta.count} </span>
        results
      </p>
    );
  };

  renderTablePagination = () => {
    return (
      <div className="at-pagination" style={{ marginTop: "15px" }}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(1, tmp);
            this.setState({ interval: 10 });
          }}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === 1) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page - 1, tmp);
          }}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.interval <= 10 || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp - 10 });
          }}
        >
          ...
        </button>
        {this.props.totalPages &&
          this.props.totalPages.map((item) => {
            if (
              item + 1 <= this.state.interval &&
              item + 1 > this.state.interval - 10
            ) {
              return (
                <button
                  key={`Pagination-${item}`}
                  className={`at-pagination__button ${this.props.meta && this.props.meta.page === item + 1 ? "active" : ""}`}
                  onClick={() => {
                    let tmp = "";
                    this.props.searchParams.map((item) => {
                      tmp =
                        item.param !== ""
                          ? tmp + `q[${item.value}]=${item.param}&`
                          : tmp;
                    });
                    this.props.getListAPI(item + 1, tmp);
                  }}
                >
                  {item + 1}
                </button>
              );
            }
          })}
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.state.interval >= this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = _.cloneDeep(this.state.interval);
            this.setState({ interval: tmp + 10 });
          }}
        >
          ...
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.page + 1, tmp);
          }}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${(this.props.meta && this.props.meta.page === this.props.meta.pages) || !this.props.meta ? "disabled" : ""}`}
          onClick={() => {
            let tmp = "";
            this.props.searchParams.map((item) => {
              tmp =
                item.param !== ""
                  ? tmp + `q[${item.value}]=${item.param}&`
                  : tmp;
            });
            this.props.getListAPI(this.props.meta.pages, tmp);
            this.setState({ interval: this.props.meta.pages });
          }}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };

  render = () => {
    const { meta, rowData, searchParams, onChangeSearchParams, getListAPI } =
      this.props;

    return (
      <div className="at-table__options">
        {searchParams.length > 0 && (
          <div className="mb-3">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() =>
                this.setState((prevState) => ({
                  showSearchContent: !prevState.showSearchContent,
                }))
              }
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller ">
              <TableSearchParams
                mode={"desktop"}
                searchParams={searchParams}
                onChangeSearchParams={onChangeSearchParams}
                getListAPI={getListAPI}
              />
            </div>
          </div>
        )}
        {rowData && rowData.length > 0 && (
          <div className="at-presentation-table-content">
            {this.renderTableContent()}
          </div>
        )}
        {!(rowData && rowData.length > 0) && (
          <EmptyState
            renderIcon={<SvgIcon svgHref={"icon-slides"} />}
            title={"No presentation found at the moment, please create one."}
          />
        )}
        {rowData && rowData.length > 0 && (
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 16 }}
          >
            {this.renderTablePagination()}
            {meta && meta.count > 0 && this.renderPaginationText()}
          </div>
        )}
        {this.state.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            searchParams={searchParams}
            onToggleSearchModal={() =>
              this.setState((prevState) => ({
                showSearchContent: !prevState.showSearchContent,
              }))
            }
            onChangeSearchParams={onChangeSearchParams}
            getListAPI={getListAPI}
          />
        )}
      </div>
    );
  };
}

export default PaginatedTable;
