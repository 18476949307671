import React from "react";
import Moment from "moment";

export const columnData = [
  {
    header: "Date",
    renderColumn: (rowData) =>
      Moment(rowData.created_at).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    header: "User",
    accessor: "username",
  },
  {
    header: "Action",
    renderColumn: (rowData) => rowData.action.toUpperCase(),
  },
  {
    header: "Comment",
    renderColumn: (rowData) =>
      rowData.comment
        ? `${rowData.comment?.longitude}, ${rowData.comment?.latitude}`
        : "N/A",
  },
  {
    header: "Audited Changes",
    renderColumn: (rowData) =>
      rowData.audited_changes ? (
        <>
          <p>
            <b>Timestamp: </b>
            {Moment(rowData.audited_changes?.timestamp).format(
              "DD MMM YYYY hh:mm:ss",
            )}
          </p>
          <p>
            <b>Branch Name: </b>
            {rowData.audited_changes?.branch_name}
          </p>
          <p>
            <b>Status: </b>
            {rowData.audited_changes?.status}
          </p>
        </>
      ) : (
        "N/A"
      ),
  },
];

export const radiusOptions = [
  { value: 0.3, label: "0.3KM" },
  { value: 0.5, label: "0.5KM" },
  { value: 1.0, label: "1.0KM" },
  { value: 1.5, label: "1.5KM" },
  { value: 2.0, label: "2.0KM" },
  { value: 3.0, label: "3.0KM" },
  { value: 5.0, label: "5.0KM" },
  { value: 10.0, label: "10.0KM" },
];
