import React from "react";
import { FiCheckCircle } from "react-icons/fi";

import CustomButton from "components/Button";
import ProgressBar from "./progressBar";

const CompleteModal = ({
  selectedUnit,
  selectedProject,

  onClose,
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: 600,
        margin: "auto",
        maxHeight: "80vh",
      }}
    >
      <FiCheckCircle
        style={{ width: 120, color: "#878683", marginBottom: 10 }}
      />
      <br />
      <h3 className="at-card__title mb-3 mr-0">{`Reservation of Unit ${selectedUnit.unit_no}`}</h3>
      <ProgressBar
        progress={"Complete"}
        has_time_limit={
          selectedProject.reservation_time_limit &&
          selectedProject.reservation_time_limit.settings.has_time_limit
        }
      />
      <p className={"at-storey-plan__confirmation-text"}>
        Congratulations! Your booking has been successfully submitted and will
        be reviewed.
      </p>
      <div className={"d-flex justify-content-center pb-3"}>
        <CustomButton
          className={"btn-new btn-new--success"}
          children={"OK"}
          onClick={() => onClose()}
        />
      </div>
    </div>
  );
};

export default CompleteModal;
