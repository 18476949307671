import React, { useState, useEffect } from "react";
import ReactHTMLParser from "react-html-parser";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { IoIosLink } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import PhotoSlider from "./PhotoSlider";
import CustomButton from "components/Button";

import { numberWithCommas } from "utils/thousandSeparator";
import { getColorBadge } from "dictionary/badgeColor";
import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import defaultImage from "assets/images/iqi_logo_only.png";
import defaultAvatar from "assets/images/av-male.jpg";

import { featureProperty, detailsSection, publishPlatformLib } from "./assets";

const cobrokeSetting = [
  {
    label: "Commission",
    value: "total_portion",
    icon: "atlas-percentage-circle",
    colorName: "Teal",
  },
  {
    label: "My Portion ",
    value: "my_portion",
    icon: "atlas-profile-circle",
    colorName: "Blue",
  },
  {
    label: "Co-broke Agent Portion",
    value: "balance",
    icon: "atlas-shake-hand",
    colorName: "Fuchsia",
  },
];

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const Details = ({ selectedEstateDetails }) => {
  const {
    code,
    floorplans,
    asking_price_amount,
    currency,
    id,
    status,
    address,
    property_name,
    images,
    description,
    zh_description,
    facilities,
    other_facilities,
    land_area,
    built_up,
    measurement,
    featureArray,
    published_to_iqi,
    exported_iqi_link,
    published_to_juwai,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    exported_juwai_asia_link,

    user: agent,
  } = selectedEstateDetails;

  const [descriptionTab, onChangeDescTab] = useState("Eng");
  const [customStyle, setCustomStyle] = useState({
    gridTemplateAreas: `
      "header"
      "gallery"
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
    `,
  });

  useEffect(() => {
    let tmpStyle = `
      "header"
      "gallery"
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "cobrokeSettings"
    `;
    if (!published_to_iqi || !exported_iqi_link) {
      if (
        !published_to_juwai ||
        (!exported_to_juwai && !juwai_link && !exported_juwai_asia_link)
      ) {
        tmpStyle = tmpStyle.replace(`"subsalesPublishedPlatforms"`, "");
      }
    }

    if (!_.some(facilities, { value: true })) {
      tmpStyle = tmpStyle.replace(`"facilities"`, "");
    }

    if (!_.some(featureArray, { value: true })) {
      tmpStyle = tmpStyle.replace(`"subsalesFeatures"`, "");
    }

    setCustomStyle({ gridTemplateAreas: tmpStyle });
  }, [selectedEstateDetails]);

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ title, linkSrc, logoSrc }) => {
      return (
        <a
          href={linkSrc}
          target="_blank"
          className="at-subsales_listing_view-published_platforms-item"
        >
          <div className="d-flex align-items-center">
            <img src={logoSrc} />
            <span>{title}</span>
          </div>
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
        </a>
      );
    };

    return (
      ((published_to_iqi && exported_iqi_link) ||
        (published_to_juwai &&
          exported_to_juwai &&
          (juwai_link || exported_juwai_asia_link)) ||
        (exported_to_edgeprop && published_to_edge_prop)) && (
        <div className="at-subsales_listing__view-published_platforms">
          <div className="at-subsales-listing__view-title">
            <AtlasIcon
              svgHref={"atlas-global"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            Published Platforms
          </div>
          {publishPlatformLib.map((item, index) => {
            let tmpCheckingLink =
              selectedEstateDetails[item.linkSrc] &&
              selectedEstateDetails[item.linkSrc].length > 0 &&
              selectedEstateDetails[item.published] &&
              (item.exported ? selectedEstateDetails[item.exported] : true);
            return (
              tmpCheckingLink && (
                <LogoPlatform
                  {...item}
                  key={index}
                  linkSrc={selectedEstateDetails[item.linkSrc]}
                />
              )
            );
          })}
        </div>
      )
    );
  };

  return (
    <>
      <div className="at-subsales_listing__view">
        <div className="at-subsales_listing__view-header">
          <div className="at-subsales_listing__view-header-topleft">
            <div className="at-subsales_listing__view-header-topleft_content">
              <h6 className="at-subsales__code mb-3">{code}</h6>
              <p className="at-subsales_listing__view-address_title">
                {property_name}
              </p>
              {address && (
                <div className="d-flex align-items-center">
                  <AtlasIcon
                    svgHref={"atlas-location"}
                    style={{
                      minWidth: 14,
                      width: 14,
                      height: 14,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  <h6 className="at-subsales_listing__view-address_subtitle">{`${address}`}</h6>
                </div>
              )}
              <span className="at-subsales__price">
                <span className="mr-2" style={{ color: "#111827" }}>
                  {currency}
                </span>
                {`${asking_price_amount && asking_price_amount > 0 ? numberWithCommas(asking_price_amount) : "N/A"}`}
              </span>
            </div>
          </div>
          <div className="at-subsales_listing__view-header-right">
            <div className="at-subsales_listing__feature_property">
              {featureProperty.map((item, index) => (
                <div key={index} className="at-subsales_listing__feature-item">
                  <AtlasIcon svgHref={item.icon} style={{ fill: "#F0631D" }} />
                  <div>
                    <div className="at-subsales_listing_title">
                      {item.label}
                    </div>
                    <div className="at-subsales_listing_content">
                      {selectedEstateDetails[item.value]}
                    </div>
                  </div>
                </div>
              ))}
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-layer"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">
                    Built Up / Land Area
                  </div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(built_up)} ${measurement}`}</div>
                </div>
              </div>
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-miximize-3-linear"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">Land Area</div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(land_area)} ${measurement}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="at-subsales_listing__view-left_column">
          <div className="at-subsales_listing__view-photo_slider_cont">
            <PhotoSlider
              id={id}
              images={images && images.length > 0 ? images : defaultImage}
              floorPlanListing={floorplans}
            />
          </div>
          <div className="at-subsales_listing__view-description">
            <header className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-send-2"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              Description
            </header>
            <div className="at-subsales_listing__view-description_tab">
              <button
                className={
                  descriptionTab === "Eng"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeDescTab("Eng")}
              >
                English
              </button>
              <button
                className={
                  descriptionTab === "Zh"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeDescTab("Zh")}
              >
                Chinese
              </button>
            </div>
            <div className="at-subsales_listing__view-description-content">
              {descriptionTab === "Eng" && (
                <div
                  className={`at-subsales_listing__view-description_eng at-subsales_listing_view-selected_description`}
                >
                  {typeof description === "string" && description
                    ? ReactHTMLParser(description)
                    : "N/A"}
                </div>
              )}
              {descriptionTab === "Zh" && (
                <div
                  className={`at-subsales_listing__view-description_zh at-subsales_listing_view-selected_description`}
                >
                  {zh_description ? ReactHTMLParser(zh_description) : "N/A"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="at-subsales_listing__view-right_column">
          <div className="at-subsales_listing__view-details">
            <header className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-note-text"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              Details
            </header>
            <div style={{ width: "100%" }}>
              {detailsSection.map((item, index) => {
                const { colorName = "" } =
                  _.find(StatusColor, { value: status }) || {};

                return (
                  <div
                    key={index}
                    className="at-subsales-listing__view-details-item"
                  >
                    <label>
                      <AtlasIcon svgHref={item.icon} />
                      {item.label}
                    </label>
                    {item.value === "status" && (
                      <p>
                        <div
                          className="at-status_badge mt-1"
                          style={{ ...getColorBadge(colorName) }}
                        >
                          {status}
                        </div>
                      </p>
                    )}
                    {item.value !== "status" && (
                      <p>{selectedEstateDetails[item.value] || "N/A"}</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {featureArray && _.some(featureArray, { value: true }) && (
            <div className="at-subsales_listing__view-features">
              <header>Features</header>
              <table style={{ width: "100%" }}>
                <tbody>
                  {featureArray.map((item) => (
                    <tr>
                      <th>{item.label}</th>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {facilities && _.some(facilities, { value: true }) && (
            <div className="at-subsales_listing__view-facilities">
              <header className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-building-4"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Facilities
              </header>
              <ul>
                {facilities.map((item) => item.value && <li>{item.label}</li>)}
              </ul>
              {other_facilities && (
                <div className="at-subsales_listing__view-other_facilities">
                  <div className="at-subsales-listing__view-title">
                    <AtlasIcon
                      svgHref={"atlas-note-text"}
                      style={{ fill: "#F0631D", marginRight: 10 }}
                    />
                    Other Facilities
                  </div>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    {other_facilities}
                  </p>
                </div>
              )}
            </div>
          )}
          {renderPublishingPlatforms()}
          <div className="at-form__content">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-profile-circle"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              Listing Agent
            </div>
            <div className="d-flex align-items-center">
              <img
                src={agent.avatar_url}
                style={{
                  width: 56,
                  height: 56,
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
              <div className="ml-3">
                <div className="fw-600" style={{ fontSize: 16 }}>
                  {agent.full_name}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-profile2user"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent.team_name}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-sms"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent.email}
                </div>
                <div className="fs-2">
                  <AtlasIcon
                    svgHref={"atlas-call"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  {agent.mobile_contact_number}
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between mt-2"
              style={{ gridArea: "agentContact" }}
            >
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary w-100 d-flex flex-wrap justify-content-center grid_gap-y-1 h-100"
                }
                containerStyle={{ width: "30%" }}
                onClick={() => window.open(`mailto:${agent.email}`)}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-sms-edit"}
                      style={{ width: 16, height: 16, fill: "#3B82F6" }}
                    />
                    Email
                  </>
                }
              />
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary w-100 d-flex flex-wrap justify-content-center grid_gap-y-1 h-100"
                }
                containerStyle={{ width: "30%" }}
                onClick={() =>
                  window.open(
                    `https://wa.me/${agent.mobile_contact_number}`,
                    "_blank",
                  )
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-sms"}
                      style={{ width: 16, height: 16, fill: "#12B76A" }}
                    />
                    Message
                  </>
                }
              />
              <CustomButton
                className={
                  "btn-new btn-new--outline-secondary w-100 d-flex flex-wrap justify-content-center grid_gap-y-1 h-100"
                }
                containerStyle={{ width: "30%" }}
                onClick={() =>
                  window.open(`tel:${agent.mobile_contact_number}`)
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-call-calling"}
                      style={{ width: 16, height: 16, fill: "#3B82F6" }}
                    />
                    Call
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
