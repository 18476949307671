import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError } from "utils/requestHandler";

const searchParams = [
  {
    label: "Agent Full name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Quiz ID",
    value: "source_id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Start Date",
    value: "start_date_gteq",
    type: "date",
    param: "",
  },
  {
    label: "End Date",
    value: "start_date_lteq",
    type: "date",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 6,
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Pending" },
      { value: "1", label: "Pass" },
      { value: "2", label: "Fail" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showBulkUpdate: false,
      showUpdateModal: false,

      searchParams: searchParams,
      exams: {},
      examPages: null,
      selectedExam: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeExamHOC = (val, context) => this.setState({ [context]: val });

    getExams = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/exams?${search}page=${page}`,
        this.getExamsSuccess,
        this.getExamsError,
        this.load,
      );
    };
    getExamsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        start_date: Moment(item.start_date).format("DD MMM YYYY"),
      }));
      this.setState({
        exams: {
          data: tempData,
          meta: payload.meta,
        },
        examPages: tmpTotalPages,
      });
    };
    getExamsError = (error) => requestError(error);

    getSelectedExam = (id) =>
      Get(
        `/admin/exams/${id}`,
        this.getSelectedExamSuccess,
        this.getSelectedExamError,
        this.load,
      );
    getSelectedExamSuccess = (payload) =>
      this.setState({
        selectedExam: {
          ...payload,
          start_date: Moment(payload.start_date).format("DD MMM YYYY"),
        },
        showUpdateModal: true,
      });
    getSelectedExamError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            exams={this.state.exams}
            selectedExam={this.state.selectedExam}
            showUpdateModal={this.state.showUpdateModal}
            showBulkUpdate={this.state.showBulkUpdate}
            searchParams={this.state.searchParams}
            examPages={this.state.examPages}
            onLoadExam={this.state.loading}
            getExams={this.getExams}
            getSelectedExam={this.getSelectedExam}
            onChangeExamHOC={this.onChangeExamHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
