import React, { Component } from "react";

import CustomFormInput from "components/Input/formInput";

import { getTranslation } from "assets/translation";

class ForgetPassword extends Component {
  render = () => {
    const { cooldownTiming, email, language } = this.props;
    return (
      <div className="login-form-container">
        <h4 className="at-form-input__title">{getTranslation("label.email", language)}</h4>
        <CustomFormInput
          type="text"
          value={email}
          onChangeValue={(val) => this.props.onChangeEmail(val)}
          placeholder={getTranslation("label.email", language)}
        />
        {!cooldownTiming && (
          <div className="mt-4">
            <button
              className="btn-new btn-new--primary text-uppercase w-100"
              onClick={() => this.props.onClickSubmitEmail()}
            >
              {getTranslation("submit", language)}
            </button>
          </div>
        )}
        {cooldownTiming > 0 && (
          <p className="title text-right">
            {getTranslation("label.cooldown_message", language).replace("{{cooldownTiming}}", cooldownTiming)}
          </p>
        )}
      </div>
    );
  };
}

export default ForgetPassword;
