import React from "react";
import CurrencyInput from "react-currency-input-field";

import SVGIcon from "components/Icon";

const AtCurrecyInput = ({
  id,
  className = "",
  style = {},
  allowDecimals = true,
  allowNegativeValue,
  step = 0.1,
  context,
  value = "",
  type,
  required,
  inputSuccess,
  inputError,
  instruction,
  disabled,
  placeholder = "",

  onChangeFocus,
  onChangeBlur,
  onChange,
}) => {
  const renderSuccessMessage = (message) => {
    if (message) {
      return (
        <div className="at-form-input__label at-form-input__label--success">
          {message}
        </div>
      );
    }
  };

  const renderErrorMessage = (error) => {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  };

  const renderInstruction = (message) => {
    if (message) {
      return (
        <div
          className="at-form-input__label at-form-input__label--success"
          style={{ background: "#6B7280", left: 0, right: "auto" }}
        >
          {message}
        </div>
      );
    }
  };

  const renderSearchIcon = (type) => {
    if (type === "search") {
      return (
        <SVGIcon
          className={"at-form-input__search-icon"}
          svgHref={"icon-search"}
        />
      );
    }
  };

  const renderRequired = (param) => {
    if (param) {
      return <div className="at-form-input__required">required *</div>;
    }
  };

  return (
    <div>
      <div className={`at-form-input ${disabled ? "at-form-input__disabled" : ""}`}>
        <CurrencyInput
          id={id || `atlas-input-${context}`}
          className={`
            at-form-input__input
            ${className}
            ${inputError ? "at-form-input--error" : ""}
          `}
          style={style}
          name={context}
          allowNegativeValue={allowNegativeValue}
          placeholder={placeholder}
          defaultValue={1000}
          decimalsLimit={2}
          allowDecimals={allowDecimals}
          step={step}
          disabled={disabled}
          value={value}
          onBlur={() => onChangeBlur && onChangeBlur()}
          onFocus={() => onChangeFocus && onChangeFocus()}
          onValueChange={(value, name) => onChange(value, name)}
        />
        {renderSearchIcon(type)}
      </div>
      <div className="at-input_footer" 
        style={(
          required
          || inputSuccess
          || inputError
        ) || instruction
          ? { marginBottom: "1rem" } 
          : {}}>
        {renderRequired(required)}
        {renderSuccessMessage(inputSuccess)}
        {renderErrorMessage(inputError)}
        {renderInstruction(instruction)}
      </div>
    </div>
  );
};

export default AtCurrecyInput;
