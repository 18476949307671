import React, { Component } from "react";
import { compose } from "redux";
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { IoMdAlert } from "react-icons/io";
import ReactHTMLParser from "react-html-parser";

import CustomUpload from "components/Input/upload";
import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import TransferTicketForm from "./transferTicker";
import PaymentProofPreview from "./proofPreview";
import AttendeeContent from "./attendeeDetails";
import PaymentDetail from "./paymentDetail";
import AtlasButton from "components/Button";
import AttendeeSummary from "./attendeeSummary";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import DeleteConfirmation from "components/Modal/delete";
import UserInfo from "components/NewPaginatedTable/components/UserInfo";
import RejectConfirmationModal from "./rejectModal";

import EventAttendeeHOC from "../actions/attendee";
import TransferHOC from "../actions/transfer";
import permissionsChecker from "utils/permissionsChecker";
import isEmptyValue from "utils/isEmpty";
import { getColorBadge } from "dictionary/badgeColor";

export const StatusColor = [
  { value: "Present", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Reset", colorName: "Red" },
  { value: "Absent", colorName: "Red" },
  { value: "Approved", colorName: "Green" },
  { value: "Rejected", colorName: "Red" },
];

const headerData = ({ onClickUpload, onClickShowPayment }) => [
  {
    header: "User",
    columnStyle: { width: "120%" },
    renderColumn: (rowData) => (
      <UserInfo
        name={rowData.user_name}
        email={rowData.email}
        phone_number={rowData.mobile_contact_number}
        team_name={rowData.team}
      />
    ),
  },
  {
    header: "Clock in/out time",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => (
      <>
        <p>
          <AtlasIcon svgHref={ 'atlas-clock-1-linear' }  style={{ width: 14, height: 14, marginRight: 4, fill: '#3B82F6' }} />
          {rowData.clock_in_time}
        </p>
        <p>
          <AtlasIcon svgHref={ 'atlas-clock' } style={{ width: 14, height: 14, marginRight: 4, fill: '#3B82F6' }} />
          {rowData.clock_out_time}
        </p>
      </>
    ),
  },
  {
    header: "Join Duration",
    accessor: "join_duration_minute",
    columnStyle: { width: "50%" },
  },
  {
    header: "Payment Method",
    columnStyle: { width: "90%" },
    renderColumn: (rowData) => {
      const { payment_method, payment_method_id, proof_of_payment_url } =
        rowData;
      return (
        <div className="at-attendee-payment">
          <p>{payment_method}</p>
          {!(payment_method_id === 2 && !proof_of_payment_url) && (
            <AtlasButton
              disabled={![1, 2].includes(payment_method_id)}
              containerStyle={{ marginRight: "5px" }}
              className={"at-attendee-payment__btn"}
              children={<AtlasIcon svgHref={"atlas-receipt-text"} />}
              tooltip={true}
              tooltipChildren={<span>Payment Proof</span>}
              tooltipPosition={"top"}
              tooltipID={`details-payment`}
              onClick={() => onClickShowPayment(rowData)}
            />
          )}
          {payment_method_id === 2 && !proof_of_payment_url && (
            <AtlasButton
              className={"at-attendee-payment__btn"}
              children={<AtlasIcon svgHref={"atlas-receipt-text"} />}
              tooltip={true}
              tooltipChildren={<span>Upload Payment Slip</span>}
              tooltipPosition={"top"}
              tooltipID={`details-upload`}
              onClick={() => onClickUpload(rowData)}
            />
          )}
        </div>
      );
    },
  },
  {
    header: "Approval Status",
    columnStyle: { width: "70%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { reject_reason = "" } = !isEmptyValue(rowData.remark)
        ? rowData.remark
        : { reject_reason: "" };

      const { colorName = "" } = _.find(StatusColor, { value: rowData.approval_status }) || {};

      return (
        <>
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {rowData.approval_status}
        </div>
          {!rowData.is_approved && reject_reason && (
            <Tooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              enterTouchDelay={50}
              placement="top"
              title={<p>{ReactHTMLParser(reject_reason)}</p>}
            >
              <IoMdAlert
                className={"svg-icon-info"}
                style={{ fill: "#F8E634" }}
              />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    header: "Attendance Status",
    columnStyle: { width: "70%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } = _.find(StatusColor, { value: rowData.attendance_status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.attendance_status}
        </div>
      );
    },
  },
];

class AttendeeList extends Component {
  filepond = null;

  componentDidMount = () => {
    this.props.getSelectedEvent(this.props.selectedEvent.id);
    this.props.getAttendeeList(this.props.selectedEvent.id, 1, "");
    this.props.getAttendeeSummary(this.props.selectedEvent.id);
    this.props.getEventTicketPricing(this.props.selectedEvent.id);
  };

  renderUploadPaymentProof = () => {
    const onChangePaymentProof = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        let files = e.target.files;
        Object.keys(e.target.files).map((key) => {
          let reader = new FileReader();
          reader.onload = (e) => {
            let tmp = {
              proof_of_payment: e.target.result,
              proof_of_payment_file_name: files[key].name,
            };
            this.props.onChangeAttendeeHOC(tmp, "paymentProof");
            this.props.onChangeAttendeeHOC(true, "showPaymentProofPreview");
          };
          reader.readAsDataURL(e.target.files[key]);
        });
      }
    };

    return (
      <input
        style={{ display: "none" }}
        type="file"
        ref={(ref) => (this.filePond = ref)}
        accept="application/pdf, image/*"
        onChange={(e) => onChangePaymentProof(e)}
      />
    );
  };

  renderConfirmationModal = () => {
    const {
      onLoadAttendee,
      selectedAttendee,
      selectedEvent,
      showRemoveConfirmation,
      showCheckInConfirmation,
      showCheckOutConfirmation,

      removeAttendee,
      checkIn,
      checkOut,
      onChangeAttendeeHOC,
    } = this.props;

    const { full_name } = !_.isEmpty(this.props.selectedAttendee.agent)
      ? this.props.selectedAttendee.agent
      : { full_name: "" };

    return (
      <>
        <DeleteConfirmation
          maxWidth={"xl"}
          mode={"alert"}
          open={showRemoveConfirmation}
          title={"Delete Attendee"}
          message={"Are you sure you want to remove the attendee?"}
          loading={onLoadAttendee}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showRemoveConfirmation"),
            ]).then(() => removeAttendee(selectedAttendee.id, selectedEvent.id))
          }
          negativeAction={() =>
            onChangeAttendeeHOC(false, "showRemoveConfirmation")
          }
        />
        <ConfirmationModal
          maxWidth={"xl"}
          open={showCheckInConfirmation}
          loading={onLoadAttendee}
          title={"Check In"}
          message={
            <p>Are you sure you want check in for {full_name}?</p>
          }
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showCheckInConfirmation"),
            ]).then(() => checkIn( selectedAttendee.id, selectedAttendee.agent.referrer_code))
          }
          negativeAction={() => onChangeAttendeeHOC(false, "showCheckInConfirmation")}
        />
        <ConfirmationModal
          maxWidth={"xl"}
          loading={onLoadAttendee}
          open={showCheckOutConfirmation}
          title={"Check Out"}
          message={
            <p>Are you sure you want to check out for {full_name}?</p>
          }
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showCheckOutConfirmation"),
            ]).then(() => checkOut( selectedAttendee.id, selectedAttendee.agent.referrer_code))
          }
          negativeAction={() => onChangeAttendeeHOC(false, "showCheckOutConfirmation")}
        />
      </>
    );
  };

  renderAttendeeDialog = () => {
    const {
      uploadAttendeeList,
      uploadedAttendeeListFile,
      onChangeAttendeeHOC,
      onLoadAttendee,
    } = this.props;

    return (
      <>
        <CustomUpload
          multiple={false}
          accepts={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
          imageRestriction={false}
          files={uploadedAttendeeListFile}
          style={{ backgroundColor: "#F9FAFB", border: "1px dashed #D1D5DB" }}
          labelText={"XLXS"}
          onRemoveFile={() => onChangeAttendeeHOC([], "uploadedAttendeeListFile")}
          uploadImage={(fileItems) => onChangeAttendeeHOC(fileItems, "uploadedAttendeeListFile")} />
        <div className="at-modal_dialog-container-footer g-3">
          <button
            className={"btn-new btn-new--primary"}
            disabled={!uploadedAttendeeListFile}
            onClick={() => uploadAttendeeList(this.props.selectedEvent.id)}
          >
            Upload
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() =>
              onChangeAttendeeHOC(false, "showUploadAttendeeDialog")
            }
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  render = () => {
    const {
      eventList,
      attendeeList,
      selectedAttendee,
      attendeePages,
      searchParams,
      summaryLoading,
      putAttendee,
      updateAttendee,
      eventTicketPricing,
      getAttendeeSummary,
      getAttendeeList,
      getEventBySearch,
      attendeeSummary,
      attendeePaymentDetail,
      showTransferTicket,
      transferType,
      showAttendeePaymentDialog,
      onChangeTransferTicketHOC,
      transferTicket,
      transferHistories,
      transferTicketRequest,
      transferTicketEventRequest,
      selectedEvent,
      onChangeAttendeeHOC,
      showRejectModal,
      getAttendeePayment,
      getSelectedAttendee,
      showPaymentProofPreview,
      showAddAttendeeDialog,
      showEditAttendeeDialog,
      showUploadAttendeeDialog,
      exportAttendee,
      paymentProof,
      uploadPaymentProof,
      getTransferHistories,
      regradeAttendance,
      rejectRegistration,
      onLoadAttendee,
      onLoadTransferTicket,
    } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );

    return (
      <>
        <AttendeeSummary
          selectedEvent={selectedEvent}
          summaryLoading={summaryLoading}
          attendeeSummary={attendeeSummary}
          getAttendeeSummary={getAttendeeSummary}
        />
        <AtPaginatedTable
          columns={headerData({
            onClickUpload: (val) =>
              Promise.all([getSelectedAttendee(val.id)]).then(() =>
                this.filePond.click(),
              ),
            onClickShowPayment: (val) =>
              val.payment_method_id === 1
                ? getAttendeePayment(val.id)
                : window.open(val.proof_of_payment_url, "_blank"),
          })}
          rowData={attendeeList.data || []}
          meta={attendeeList.meta || {}}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeAttendeeHOC(val, "searchParams")
          }
          getListAPI={(page, search) =>
            getAttendeeList(selectedEvent.id, page, search)
          }
          totalPages={attendeePages}
          renderCheckBoxActions={ () => (
            <div className='at-table__head-title'>
              <div className="d-flex align-items-center flex-wrap g-2">
                <div className='at-badge-icon-sm at-badge-icon-warning' >
                  <AtlasIcon svgHref={ 'atlas-profile2user' } />
                </div>
                <h4>Attendees</h4>
                {can_create && (
                  <AtlasButton
                    className={"btn-new btn-new--primary"}
                    children={
                      <>
                        <AtlasIcon svgHref={ 'atlas-add-linear' } style={{ width: 20, height: 20, fill: 'white', marginRight: 0 }} />
                        Add Attendee
                      </>
                    }
                    onClick={() => onChangeAttendeeHOC(true, "showAddAttendeeDialog")}
                  />
                )}
                <AtlasButton
                  className={"btn-new btn-new--secondary"}
                  children={
                    <>
                      <AtlasIcon svgHref={ 'atlas-import-2-linear' } style={{ width: 20, height: 20, fill: 'white', marginRight: 0 }} />
                      Export
                    </>
                  }
                  onClick={() => exportAttendee(selectedEvent)}
                />
                {can_create && (
                  <AtlasButton
                    className={"btn-new btn-new--secondary"}
                    children={
                      <>
                        <AtlasIcon svgHref={ 'atlas-document-upload' } style={{ width: 20, height: 20, fill: 'white', marginRight: 0 }} />
                        Upload Attendee List
                      </>
                    }
                    onClick={() => onChangeAttendeeHOC(true, "showUploadAttendeeDialog")}
                  />
                )}
                {can_update && (
                  <AtlasButton
                    className={"btn-new btn-new--secondary"}
                    children={
                      <>
                        <AtlasIcon svgHref={ 'atlas-edit-2' } style={{ width: 20, height: 20, fill: 'white', marginRight: 0 }} />
                        Regrade Attendance
                      </>
                    }
                    onClick={() => regradeAttendance(selectedEvent.id)}
                  />
                )}
              </div>
            </div>
          )}
          actionColumnContent={
            can_update
              ? [
                  {
                    name: "edit",
                    onClick: (rowData) =>
                      Promise.all([getSelectedAttendee(rowData.id)]).then(() =>
                        onChangeAttendeeHOC(true, "showEditAttendeeDialog"),
                      ),
                  },
                  {
                    name: "delete",
                    color: "#F04438",
                    disabled: (rowData) =>
                      !(
                        rowData.payment_method_id === 0 ||
                        (rowData.payment_method_id === 2 &&
                          !rowData.proof_of_payment_url)
                      ) || rowData.is_approved === "Yes",
                    onClick: (rowData) =>
                      Promise.all([getSelectedAttendee(rowData.id)]).then(() =>
                        onChangeAttendeeHOC(true, "showRemoveConfirmation"),
                      ),
                  },
                ]
              : []
          }
        />
        {this.renderUploadPaymentProof()}

        {showAddAttendeeDialog && (
          <ModalDialog
            title={"Add Attendee"}
            onClose={() => onChangeAttendeeHOC(false, "showAddAttendeeDialog")}
            children={
              <AttendeeContent
                mode={"create"}
                selectedEvent={selectedEvent}
                onLoadAttendee={onLoadAttendee}
                eventTicketPricing={eventTicketPricing}
                putAttendee={putAttendee}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onClose={() => onChangeAttendeeHOC(false, "showAddAttendeeDialog")} />
            } />
        )}
        {showEditAttendeeDialog && (
          <ModalDialog
            title={"Update Attendee"}
            fullHeight={true}
            fullWidth={true}
            onClose={() => onChangeAttendeeHOC(false, "showEditAttendeeDialog")}
            children={
              <AttendeeContent
                mode={"update"}
                selectedEvent={selectedEvent}
                onLoadAttendee={onLoadAttendee}
                selectedAttendee={selectedAttendee}
                transferHistories={transferHistories}
                eventTicketPricing={eventTicketPricing}
                onLoadTransferTicket={onLoadTransferTicket}
                updateAttendee={updateAttendee}
                getTransferHistories={getTransferHistories}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onClose={() => onChangeAttendeeHOC(false, "showEditAttendeeDialog")}
                onClickRemove={() => onChangeAttendeeHOC(true, "showRemoveConfirmation")}
                onClickCheckIn={() => onChangeAttendeeHOC(true, "showCheckInConfirmation")}
                onClickCheckout={() => onChangeAttendeeHOC(true, "showCheckOutConfirmation")}
                onClickReject={() => onChangeAttendeeHOC(true, "showRejectModal")}
                onClickTransfer={() => onChangeTransferTicketHOC("showTransferTicket", true)}
              />
            } />
        )}
        {showUploadAttendeeDialog && (
          <ModalDialog
            title={"Upload Attendee"}
            onClose={() =>
              onChangeAttendeeHOC(false, "showUploadAttendeeDialog")
            }
            children={this.renderAttendeeDialog()}
          />
        )}
        {showPaymentProofPreview && (
          <ModalDialog 
            title={"Payment Proof"}
            onClose={() => onChangeAttendeeHOC(false, "showPaymentProofPreview")}
            children={
              <PaymentProofPreview
                paymentProof={paymentProof}
                onClickUpload={() =>
                  Promise.all([
                    onChangeAttendeeHOC(false, "showPaymentProofPreview"),
                  ]).then(() =>
                    uploadPaymentProof(selectedAttendee.id, selectedEvent.id),
                  )
                }
                onClose={() =>
                  onChangeAttendeeHOC(false, "showPaymentProofPreview")
                }
              />
            }/>
        )}
        {showTransferTicket && (
          <ModalDialog
            title={"Event Transfer"}
            onClose={() => onChangeTransferTicketHOC("showTransferTicket", false)}
            children={
              <TransferTicketForm
                transferType={transferType}
                eventList={eventList}
                onChangeTransferTicketHOC={onChangeTransferTicketHOC}
                transferTicket={transferTicket}
                selectedAttendee={selectedAttendee}
                transferTicketRequest={transferTicketRequest}
                transferTicketEventRequest={transferTicketEventRequest}
                getEventBySearch={getEventBySearch}
                getAttendeeSummary={getAttendeeSummary}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onLoadTransferTicket={onLoadTransferTicket}
              />
            } />
        )}
        {showRejectModal && (
          <ModalDialog
            title={"Reject Attendee"}
            onClose={() => onChangeAttendeeHOC(false, "showRejectModal")}
            children={
              <RejectConfirmationModal
                confirmReject={(reason) => rejectRegistration(selectedAttendee.id, reason, selectedAttendee.event_id)}
                onClose={() => onChangeAttendeeHOC(false, "showRejectModal")}
              />
            } />
        )}
        {this.renderConfirmationModal()}
        {showAttendeePaymentDialog && (
          <ModalDialog
            title={"Payment Details"}
            containerClasses={{paperScrollPaper: "at-modal_dialog-md-fit"}}
            onClose={() => onChangeAttendeeHOC(false, "showAttendeePaymentDialog")}
            children={
              <PaymentDetail
                open={showAttendeePaymentDialog}
                attendeePaymentDetail={attendeePaymentDetail}
                onClickClose={() =>
                  onChangeAttendeeHOC(false, "showAttendeePaymentDialog")
                }
              />
            }/>
        )}
        {(onLoadAttendee || onLoadTransferTicket) && <LoadingModal />}
      </>
    );
  };
}

export default compose(EventAttendeeHOC, TransferHOC)(AttendeeList);
