import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import EditAuthorisationContent from "./components/editContent";
import GenerateAuthorisationForm from "../../components/generateForm";

import AuthorisationHOC from "./actions";
import InvoicingBranchesHOC from "../../actions/invoicingBranches";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesStatusHOC from "actions/subsalesStatus";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";
import FormHOC from "../../actions";
import { getColorBadge } from "dictionary/badgeColor";

const columnData = [
  { header: "Form Number", accessor: "transaction_number" },
  {
    header: "Type",
    accessor: "type",
    columnStyle: { width: "60%" },
  },
  {
    header: "Tenant/Purchaser",
    accessor: "tenant_name",
    columnStyle: { width: "140%" },
  },
  {
    header: "Remark",
    renderColumn: (rowData) => <p>{rowData.description || ""}</p>,
    columnStyle: { width: "160%" },
  },
  {
    header: "Submitted",
    renderColumn: ({ is_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_claimed ? "Green" : "Red") }}
      >
        {is_claimed ? "Yes" : "No"}
      </div>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "80%" },
  },
];

class AuthorisationForm extends Component {
  componentDidMount = () => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.props.getCommRate();
    this.props.getAllDictionaryData()
    this.props.getInvoicingBranches();
    this.props.getAuthorisationForms(1, "");
    initGA("/dashboard/personal/forms/authorisation-form");

    const id = this.props.history?.location?.state?.id;

    if (id) {
      this.props.getSelectedAuthorisationForms(id);
    }
  };

  componentDidUpdate = (pp) => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (
      this.props.showCreationForm !== pp.showCreationForm &&
      !this.props.showCreationForm
    ) {
      const temp = _.find(this.props.invoicing_branches, {
        branch_id: tmpProfileReducer.branch_id,
      });
      if (temp) {
        this.props.onChangeAuthorisationHOC(temp.id, "createInvoiceId");
      } else {
        this.props.onChangeAuthorisationHOC(null, "createInvoiceId");
      }
      this.props.onChangeAuthorisationHOC(null, "createTypeId");
    }
  };

  selectSubsaleType = (params) => {
    this.props.onChangeAuthorisationHOC(
      params === "Sale" ? "1" : "2",
      "createTypeId",
    );
  };

  generateAuthorisationForm = () => {
    const dataToSubmit = {
      invoicing_branch_id: this.props.createInvoiceId,
      type_id: this.props.createTypeId,
    };
    this.props.onChangeAuthorisationHOC(true, "disableSubmitButton");
    return this.props.createAuthorisationForm(dataToSubmit);
  };

  renderEditAuthorisationForm = () => {
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { isLocked, e_process } = this.props.selectedAuthorisationForm;

    const { status_id } = e_process ?? {}

    const eProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))

    return (
      <AtlasDialog
        withFloatPanel={true}
        open={this.props.showEditAuthorisationForm}
      >
        <CustomCard
          containerStyle={{ background: "#F3F4F6" }}
          className="h-100"
          cardContent={<EditAuthorisationContent 
            {...this.props}
            closedEditing={ !can_update || isLocked || eProcessDisabledChecking }
          />}
        />
      </AtlasDialog>
    );
  }

  renderGenerateForm = () => {
    return (
      <ModalDialog
        title={"New Authorisation Form"}
        onClose={() =>
          this.props.onChangeAuthorisationHOC(false, "showCreationForm")
        }
        children={
          <GenerateAuthorisationForm
            type={this.props.createTypeId}
            loading={this.props.onLoadAuthorisation}
            onSelectBranch={(val) =>
              this.props.onChangeAuthorisationHOC(val.id, "createInvoiceId")
            }
            selectSubsaleType={(val) =>
              this.props.onChangeAuthorisationHOC(val, "createTypeId")
            }
            invoicingBranch={this.props.invoicing_branches}
            invoicing_branch_id={this.props.createInvoiceId}
            disableSubmitButton={
              !this.props.createInvoiceId ||
              !this.props.createTypeId ||
              this.props.disableSubmitButton
            }
            generateForm={this.generateAuthorisationForm}
            onClickCloseFormGenerator={() =>
              this.props.onChangeAuthorisationHOC(false, "showCreationForm")
            }
            submitButtonText={"Create Now"}
          />
        }
      />
    );
  };

  renderAlertContent = () => {
    const { data, commRate, completed_trainings } = this.props;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    if (
      (status_id !== 2 && !completed_trainings) ||
      (status_id !== 2 && !commRate) ||
      status_id === 2
    ) {
      return (
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={"Training not completed."}
              containerClassName={ 'mb-3' }
              description={
                "You have not completed your training. You are required to finish training before accessing Appointment Letter, Confirmation Form, Cobroke Letter & Authorisation Forms To Secure Property. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              title={"No commision rate set."}
              containerClassName={ status_id === 2 ? 'mb-3' : '' }
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const {
      data,
      whitelisted,
      searchParams,
      authorisationForm,
      completed_trainings,
      authorisationFormPage,
      onChangeAuthorisationHOC,
      getAuthorisationForms,
    } = this.props;

    const { can_create } = permissionsChecker("Forms", data);
    const { role, status_id } = window.location.href.includes("/admin-impersonate",)
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    const status_permissions =
      (status_id !== 2 && completed_trainings) || whitelisted;

    return (
      <>
        <ModuleHeader
          title={"Authorisation Forms To Secure Property"}
          atlasIcon={"atlas-authorisation-form"}
          showPABadge={role === "Personal Assistant"}
          backButton={{
            onClick: () => this.props.history.goBack(),
          }}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeAuthorisationHOC(true, "showCreationForm"),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={authorisationForm.data || []}
              meta={authorisationForm.meta}
              columns={columnData}
              actionColumnContent={
                status_permissions
                  ? [
                      {
                        name: "edit",
                        onClick: (rowData) =>
                          this.props.getSelectedAuthorisationForms(rowData.id),
                      },
                    ]
                  : []
              }
              searchParams={searchParams}
              onChangeSearchParams={(val) =>
                onChangeAuthorisationHOC(val, "searchParams")
              }
              getListAPI={getAuthorisationForms}
              totalPages={authorisationFormPage}
            />
          }
        />
        {this.props.showCreationForm && this.renderGenerateForm()}
        {this.renderEditAuthorisationForm()}
        {(this.props.onLoadAuthorisation || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AuthorisationHOC,
  FormHOC,
  InvoicingBranchesHOC,
  SubsalesStatusHOC,
  VersionCheckHOC,
)(AuthorisationForm);
