import React from "react";
import Moment from "moment";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import TimelineComponent from "components/Timeline";
import ModalDialog from "components/Modal/ModalDialog";

const columnData = [
  {
    header: "Date",
    searchFlag: "created_at",
    renderColumn: (rowData) => (
      <p>{Moment(rowData.created_at).format("DD MMM YYYY, HH:mm")}</p>
    ),
  },
  {
    header: "Remote Address",
    accessor: "remote_address",
    searchFlag: "remote_address",
  },
  {
    header: "Comment",
    searchFlag: "comment",
    renderColumn: (rowData) => {
      if (rowData.comment && rowData.comment.length > 0) {
        return rowData.comment.map((item, index) => <p key={index}>{item}</p>);
      } else {
        return "N/A";
      }
    },
  },
];

const Activity = ({
  selectedCobroke,

  onClose,
}) => {
  const { activities } = selectedCobroke;

  return (
    <ModalDialog
      title={"Activity"}
      onClose={onClose}
      children={
        <div className="at-cobroke__timeline-cont">
          {activities &&
            activities.length > 0 &&
            activities.map((item) => {
              let content = "";
              if (item.activities && item.activities.length > 0) {
                item.activities.map((message) => {
                  content = content + `${message} <br/>`;
                });
              }

              return (
                <section key={item.id} className="at-cobroke__timeline">
                  <TimelineComponent
                    key={item.id}
                    content={content}
                    createdAt={item.created_date}
                  />
                </section>
              );
            })}
          {!(activities && activities.length > 0) && (
            <EmptyState
              title={`You don't have any activity`}
              renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
            />
          )}
        </div>
      }
    />
  );
};

export default Activity;
