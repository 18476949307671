import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";
import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { numberWithCommas } from "utils/thousandSeparator";
import roundUp from "utils/roundUp";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      reassignSubmissions: {},

      title: "",
      selectedSales: null,
      projectSales: [],
      initialTeamSales: [],
      totalData: {},
      showAgentSales: false,
      startDate: Moment()
        .year(new Date().getFullYear())
        .month(0)
        .date(1)
        .format("YYYY-MM-DD"),
      endDate: Moment(new Date()).format("YYYY-MM-DD"),
      showAgentsWithoutSales: false,
      showSearchTips: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSalesHOC = (val, context) => this.setState({ [context]: val });

    getProjectSales = (encodedID, startDate, endDate) => {
      let endpoint = `/connector/projects/date_summary?identity_id=${encodedID}&start_date=${startDate}&end_date=${endDate}`;

      if (startDate === undefined) {
        endpoint = `/connector/projects/date_summary?identity_id=${encodedID}`;
      }

      Get(
        endpoint,
        this.getProjectSalesSuccess,
        this.getProjectSalesError,
        this.load,
      );
    };
    getProjectSalesSuccess = (payload) =>
      this.setState(
        {
          projectSales: payload,
        },
        () => this.processTotalSalesData(),
      );
    getProjectSalesError = (error) => requestError(error);

    processTotalSalesData() {
      let tmpTotalUnits = 0;
      let tmpTotalConvertedUnits = 0;
      let tmpTotalCancelledUnits = 0;
      let tmpTotalBookedUnits = 0;
      let tmpTotalSalesAmount = 0;
      let tmpTotalConvertedSalesAmount = 0;
      this.state.projectSales.map((item) => {
        tmpTotalUnits = tmpTotalUnits + parseFloat(item.totalUnits);
        tmpTotalConvertedUnits =
          tmpTotalConvertedUnits + parseFloat(item.convertedUnits);
        tmpTotalCancelledUnits =
          tmpTotalCancelledUnits + parseFloat(item.cancelledUnits);
        tmpTotalBookedUnits =
          tmpTotalBookedUnits + parseFloat(item.bookedUnits);
        tmpTotalSalesAmount =
          tmpTotalSalesAmount + parseFloat(item.totalSalesAmount);
        tmpTotalConvertedSalesAmount =
          tmpTotalConvertedSalesAmount +
          parseFloat(item.totalConvertedSalesAmount);
      });
      let tmpTotalProjectSales = [];
      tmpTotalProjectSales.push(numberWithCommas(roundUp(tmpTotalUnits, 2)));
      tmpTotalProjectSales.push(
        numberWithCommas(roundUp(tmpTotalConvertedUnits, 2)),
      );
      tmpTotalProjectSales.push(
        numberWithCommas(roundUp(tmpTotalCancelledUnits, 2)),
      );
      tmpTotalProjectSales.push(
        numberWithCommas(roundUp(tmpTotalBookedUnits, 2)),
      );
      tmpTotalProjectSales.push(
        numberWithCommas(tmpTotalSalesAmount.toFixed(2)),
      );
      tmpTotalProjectSales.push(
        numberWithCommas(tmpTotalConvertedSalesAmount.toFixed(2)),
      );

      this.setState({
        totalData: {
          totalUnits: numberWithCommas(roundUp(tmpTotalUnits, 2)),
          totalConvertedUnits: numberWithCommas(
            roundUp(tmpTotalConvertedUnits, 2),
          ),
          totalCancelledUnits: numberWithCommas(
            roundUp(tmpTotalCancelledUnits, 2),
          ),
          totalBookedUnits: numberWithCommas(roundUp(tmpTotalBookedUnits, 2)),
          totalSalesAmount: numberWithCommas(tmpTotalSalesAmount.toFixed(2)),
          totalConvertedSalesAmount: numberWithCommas(
            tmpTotalConvertedSalesAmount.toFixed(2),
          ),
        },
      });
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          title={this.state.title}
          projectSales={this.state.projectSales}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onLoadSales={this.state.loading}
          totalData={this.state.totalData}
          showSearchTips={this.state.showSearchTips}
          onChangeSalesHOC={this.onChangeSalesHOC}
          getProjectSales={this.getProjectSales}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
