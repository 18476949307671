import React, { Component } from "react";
import _ from "lodash";
import { Grid, Card, Dialog } from "@material-ui/core";
import { MdLiveHelp } from "react-icons/md";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomButton from "components/Button";
import PartyCard from "components/Card/partyCard";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtConfirmationDialog from "components/Modal/confirmation";
import ESigningTips from "./tips";
import RefundForm from "../refundForm";
import ESigningContent from "./history";
import CustomConfirmationDialog from "../confirmationModal";
import InitiatedProcessContent from "./initiatedProcessContent";

import permissionsChecker from "utils/permissionsChecker";

const tenancyArray = [
  { label: "Tenancy Agreement (Residential)", value: 1 },
  { label: "Tenancy Agreement (Residential for Expat)", value: 2 },
  { label: "Tenancy Agreement (Commercial)", value: 3 },
];

class EprocessContent extends Component {
  componentDidMount = () => {
    this.onChangeSigningType(this.props.signingType);
  };

  onChangeSigningType = (val) => {
    let tempParty = _.cloneDeep(this.props.eProcessInitialPartyData);
    let tempBuyer = [];
    let tempSeller = [];
    tempParty.map((item) => {
      if (["Buyer", "Buyer's representative"].includes(item.party)) {
        tempBuyer.push(item);
        tempBuyer.sort((x, y) => (x.party === "Buyer" ? -1 : 1));
      } else {
        tempSeller.push(item);
        tempSeller.sort((x, y) => (x.party === "Seller" ? -1 : 1));
      }
    });
    if (val === "1") {
      tempParty = tempBuyer.concat(tempSeller);
    } else {
      tempParty = tempSeller.concat(tempBuyer);
    }
    tempParty.map((party, index) => (party.position = index + 1));
    this.props.onChangePartyHOC(tempParty, "eProcessInitialPartyData");
    this.props.onChangePartyHOC(tempParty, "eProcessPartyData");
    this.props.onChangePartyHOC(val, "signingType");
  };

  renderPendingProcessContent = () => {
    const {
      selected_confirmation_form,
      eProcessInitialPartyData = [],
      partyData,
      signingType,
      signingOption,
      onChangePartyHOC,
    } = this.props;
    const { can_create } = permissionsChecker("Forms", this.props.data);

    return (
      <>
        {eProcessInitialPartyData.length > 0 && (
          <>
            <Grid container spacing={16}>
              <Grid item md={12} xs={12}>
                <div className="party-card_cont mb-3">
                  {eProcessInitialPartyData.map((partyData, index) => (
                    <PartyCard
                      containerClass={"party-card-new"}
                      type={"confirmation"}
                      key={index}
                      index={index}
                      data={partyData}
                    />
                  ))}
                </div>
                {can_create && (
                  <>
                    <div className="at-form__content">
                      <AtlasRadioGroup
                        mode={"old"}
                        horizontal={false}
                        checkedValue={signingOption}
                        containerClassName={"w-100"}
                        selectedRadioValue={(val) =>
                          this.props.onChangePartyHOC(val, "signingOption")
                        }
                        options={[
                          {
                            value: "0",
                            label:
                              "I want to sign this contract in the e-signing platform.",
                          },
                          {
                            value: "1",
                            label:
                              "I want to apply my adopted digital signature in my profile in this e-signing process automatically.",
                          },
                        ]}
                      />
                    </div>
                    {(selected_confirmation_form.is_claimed ||
                      partyData.some((item) => item.data.length < 1) ||
                      selected_confirmation_form.download_count > 0) && (
                      <AlertBox
                        title={
                          "To start e-signing process, please resolve the requirements below first"
                        }
                        containerStyle={{
                          backgroundColor: "#FFFAEA",
                          borderColor: "#FCB122",
                        }}
                        iconStyle={{ color: "#F79008" }}
                        description={
                          <div className="at-eprocess__error-message">
                            {selected_confirmation_form.is_claimed && (
                              <p>* Form is claimed</p>
                            )}
                            {!selected_confirmation_form.is_claimed &&
                              partyData.some((item) => item.data.length < 1) &&
                              partyData.map((party) => {
                                if (party.data.length < 1) {
                                  switch (party.id) {
                                    case 1:
                                      return (
                                        <p>
                                          * { 
                                            selected_confirmation_form.type_id === 1 
                                              ? 'Purchaser'
                                              : 'Tenant'
                                          } is missing
                                        </p>
                                      );
                                    case 2:
                                      return (
                                        <p>
                                          * {
                                            selected_confirmation_form.type_id === 1 
                                              ? 'Purchaser'
                                              : 'Tenant'
                                          }'s Representative is missing
                                        </p>
                                      );
                                    case 3:
                                      return (
                                        <p>* { 
                                            selected_confirmation_form.type_id === 1 
                                              ? 'Vendor'
                                              : 'Landlord'
                                          } is missing</p>
                                      );
                                    case 4:
                                      return (
                                        <p>
                                          * {
                                            selected_confirmation_form.type_id === 1 
                                              ? 'Vendor'
                                              : 'Landlord'
                                          }'s Representative is missing
                                        </p>
                                      );
                                    default: return ''
                                  }
                                }
                              })}
                            {selected_confirmation_form.download_count > 0 && (
                              <p>
                                You have chosen manual download for this form
                              </p>
                            )}
                          </div>
                        }
                      />
                    )}
                    <div
                      className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}>
                      <button
                        className={"btn-new btn-new--primary"}
                        disabled={
                          partyData.some((item) => item.data.length < 1) ||
                          selected_confirmation_form.is_claimed ||
                          selected_confirmation_form.download_count > 0
                        }
                        onClick={() =>
                          this.props.validateForm(
                            {
                              form_type: 1,
                              form_id: selected_confirmation_form.id,
                              signing_type_id: signingOption,
                              sign_type_id: signingType,
                            },
                            "confirmation",
                          )
                        }>
                        Start E-Signing
                      </button>
                      {this.props.renderNavButton &&
                        this.props.renderNavButton()}
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {eProcessInitialPartyData.length < 1 && (
          <AlertBox
            title={""}
            containerStyle={{ marginBottom: 10 }}
            description={`Please add the contract participants for each section before start the e-Signing process`}
          />
        )}
      </>
    );
  };

  renderInitiatedProcessContent = () => {
    const { eProcessPartyData } = this.props;

    const { can_create, can_update } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    let temp = _.filter(eProcessPartyData, (item) => item.status_id === 2);
    let tempRefund = _.filter(
      eProcessPartyData,
      (item) => item.refund_status_id === 2,
    );

    let tempPercentage = eProcessPartyData.length > 0 ? ((temp.length / eProcessPartyData.length) * 100) : 100;
    let tempRefundPercentage = eProcessPartyData.length > 0 ? ((tempRefund.length / eProcessPartyData.length) * 100) : 100;

    return (
      <>
        <Grid container spacing={16}>
          <Grid item md={12} xs={12}>
            <InitiatedProcessContent
              {...this.props}
              menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
              can_update={can_update}
              can_create={can_create}
              tempPercentage={tempPercentage}
              tempRefundPercentage={tempRefundPercentage}
              renderNavButton={this.props.renderNavButton}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  renderModalContent = () => {
    const {
      onChangePartyHOC,
      resetSigning,

      banks,
      signingOption,
      signingType,
      formTypeActivity,
      eProcessPartyData,
      selected_confirmation_form,

      onLoadRefund,
      onLoadParty,
      onLoadConfirmationForm,
      showSigningHistory,
      showRefundFormModal,
      showEsigningTipsModal,
      showStartProcessModal,
      showResetConfirmationModal,
      showAbortConfirmationModal,
      showCompleteConfirmationModal,
      showSubmitClaimConfirmationModal,
      showResetRefundConfirmationModal,
      showStartRefundProccessModal,

      refundPartyInfo,
      refundFormData,
      showRecipientsSelectModal,

      getRefundForm,
      completeEsigning,
      updateRefundForm,
      onChangeRefundHOC,
    } = this.props;
    return (
      <>
        {/* Submit Claim */}
        <AtConfirmationDialog
          title={"Submit to Claim"}
          loading={onLoadConfirmationForm || onLoadParty}
          open={showSubmitClaimConfirmationModal}
          message={
            <>
              <p className={"mb-10"}>
                Click Yes to create a new claim form with this confirmation form
                number. All the information will be imported into the claim
                form.
              </p>
              <p>Do you want to submit now ?</p>
            </>
          }
          positiveAction={() =>
            this.props.submitToClaim(selected_confirmation_form.id)
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showSubmitClaimConfirmationModal")
          }
        />

        {/* Abort Confirmation Form EProcess */}
        <AtConfirmationDialog
          title={"Abort e-Signing Process"}
          loading={onLoadConfirmationForm || onLoadParty}
          open={showAbortConfirmationModal}
          message={
            <>
              <p className={"mb-10"}>
                Click Yes to abort the deal and prepare the form to request all
                the involved parties to sign the authorisation form to
                refund/forfeit earnest deposit.
              </p>
              <p>
                Authorization Letter to refund the earnest deposit will be shown
              </p>
              <p>Abort the deal now ?</p>
            </>
          }
          positiveAction={() =>
            this.props.createRefundForm(selected_confirmation_form.id)
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showAbortConfirmationModal")
          }
        />

        {/* Restart Refund Form EProcess */}
        <AtConfirmationDialog
          title={"Reset refund e-Signing process"}
          loading={onLoadConfirmationForm || onLoadParty}
          open={showResetRefundConfirmationModal}
          message={
            <>
              <p className={"mb-10"}>
                If you choose to reset the process, all the already signed
                participants will have to sign again.
              </p>
              <p>Are you sure?</p>
            </>
          }
          positiveAction={() =>
            resetSigning({
              form_id: selected_confirmation_form.id,
              form_type: 2,
            })
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showResetRefundConfirmationModal")
          }
        />

        {/* Complete Confirmation Form EProcess */}
        <AtConfirmationDialog
          context={"confirmation"}
          title={"Complete e-Signing process"}
          loading={onLoadConfirmationForm || onLoadParty}
          open={showCompleteConfirmationModal}
          message={`Click yes to complete the e-Signing and download the form in PDF for the rest of the party to sign on the print out copy.`}
          positiveAction={() =>
            completeEsigning({
              form_id: selected_confirmation_form.id,
              form_type: 1,
            })
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showCompleteConfirmationModal")
          }
        />

        {/* Restart Confirmation Form EProcess */}
        <AtConfirmationDialog
          context={"confirmation"}
          title={"Reset e-Signing process"}
          loading={onLoadConfirmationForm || onLoadParty}
          open={showResetConfirmationModal}
          message={
            <>
              If you choose to reset the process, all the already signed
              participants will have to sign again.
              <br /> <br />
              Are you sure?
            </>
          }
          positiveAction={() =>
            resetSigning({
              form_id: selected_confirmation_form.id,
              form_type: 1,
            })
          }
          negativeAction={() =>
            onChangePartyHOC(false, "showResetConfirmationModal")
          }
        />

        {/* Confirmation to initiate refund eprocess */}
        {
          showStartRefundProccessModal && (
            <CustomConfirmationDialog
              context={"refund"}
              formId={selected_confirmation_form.id}
              open={true}
              signingType={signingType}
              onChangePartyHOC={onChangePartyHOC}
              showLoading={onLoadConfirmationForm || onLoadParty || onLoadRefund}
              eProcessPartyData={eProcessPartyData}
              refundPartyInfo={refundPartyInfo}
              refundFormData={refundFormData}
              getRefundForm={getRefundForm}
              message={
                "Please ensure that all the information are correct before proceeding. Are you sure you want to proceed now ?"
              }
              positiveAction={() => {
                this.props.initiateSigningProcess({
                  form_id: refundFormData.id,
                  form_type: 2,
                  signing_type_id: signingOption,
                });
                this.props.getParty(this.props.selected_confirmation_form.id);
              }}
              negativeAction={() =>
                this.props.onChangePartyHOC(false, "showStartRefundProccessModal")
              }
            />
          )
        }

        {/* Confirmation to initiate confirmation eprocess */}
        <CustomConfirmationDialog
          context={"initiate"}
          open={showStartProcessModal}
          showLoading={onLoadConfirmationForm || onLoadParty || onLoadRefund}
          type_id={selected_confirmation_form.type_id}
          include_inventory={selected_confirmation_form.include_inventory}
          signingType={signingType}
          eProcessPartyData={eProcessPartyData}
          onChangePartyHOC={onChangePartyHOC}
          message={
            "Please ensure that all the information are correct before proceeding. Are you sure you want to proceed now ?"
          }
          positiveAction={param => {
            this.props.initiateSigningProcess({
              form_id: selected_confirmation_form.id,
              form_type: 1,
              include_signature_in_inventory_checklist: param.checkedIncludeInventory,
              signing_type_id: signingOption,
              sign_type_id: signingType,
              participants: eProcessPartyData,
            });
            this.props.getParty(this.props.selected_confirmation_form.id);
          }}
          negativeAction={() =>
            onChangePartyHOC(false, "showStartProcessModal")
          }
        />

        {/* Signing History */}
        <AtlasDialog open={showSigningHistory}>
          <CustomCard
            className={"h-100"}
            containerStyle={{ background: "#F3F4F6" }}
            cardContent={
              <ESigningContent
                formId={selected_confirmation_form.id}
                formType={formTypeActivity}
                onChangePartyHOC={onChangePartyHOC}
              />
            }
          />
        </AtlasDialog>

        {/* Refund Form */}
        <AtlasDialog open={showRefundFormModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <RefundForm
                mode={"edit"}
                banks={banks}
                formId={selected_confirmation_form.id}
                eProcessPartyData={eProcessPartyData}
                refundPartyInfo={refundPartyInfo}
                refundFormData={refundFormData}
                showRecipientsSelectModal={showRecipientsSelectModal}
                onLoadRefund={onLoadRefund}
                getRefundForm={getRefundForm}
                updateRefundForm={updateRefundForm}
                onChangeRefundHOC={onChangeRefundHOC}
                onClickClose={() =>
                  onChangePartyHOC(false, "showRefundFormModal")
                }
              />
            }
          />
        </AtlasDialog>
        {showEsigningTipsModal && (
          <ESigningTips
            onClose={() => onChangePartyHOC(false, "showEsigningTipsModal")}
          />
        )}
      </>
    );
  };

  render = () => {
    const {
      onLoadParty,
      onLoadDraftEmail,
      onLoadConfirmationForm,

      eProcessContentId,
      onChangePartyHOC,
    } = this.props;

    return (
      <>
        <CustomButton
          containerClass={"mb-3"}
          className={"at-tip__button"}
          children={
            <>
              <MdLiveHelp />
              e-Signing Process Explain
            </>
          }
          onClick={() => onChangePartyHOC(true, "showEsigningTipsModal")}
        />
        {eProcessContentId === 1 && this.renderPendingProcessContent()}
        {eProcessContentId === 2 && this.renderInitiatedProcessContent()}
        {(onLoadConfirmationForm || onLoadParty) && <LoadingModal />}

        {/* ConfirmationModal */}
        {this.renderModalContent()}
        {onLoadDraftEmail && <LoadingModal />}
      </>
    );
  };
}

export default EprocessContent;
