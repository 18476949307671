import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      regions: [],
      loading: false,

      reassignSubmissions: {},

      currentPage: 1,
      reassignPages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeReassignHOC = (val, context) => this.setState({ [context]: val });

    getReassignSubmissions = (page) => {
      this.setState({ currentPage: page });
      Get(
        `/admin/reassign_submission_requests?page=${page}`,
        this.getReassignSubmissionsSuccess,
        this.getReassignSubmissionsError,
        this.load,
      );
    };
    getReassignSubmissionsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        reassignSubmissions: payload,
        reassignPages: tmpTotalPages,
      });
    };
    getReassignSubmissionsError = (error) => requestError(error);

    approveReassignSubmissions = (id) =>
      Put(
        `/admin/reassign_submission_requests/${id}/approve`,
        "",
        this.approveReassignSubmissionsSuccess,
        this.approveReassignSubmissionsError,
        this.load,
      );
    approveReassignSubmissionsSuccess = () => {
      this.getReassignSubmissions(this.state.currentPage);
      this.props.getPendingReassign();
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.props.getSubmittedSubsaleClaims(currentPage, searchParams);

      requestSuccess(`Submission has been reassigned successfully.`);
      this.setState({ showApproveModal: false });
    };
    approveReassignSubmissionsError = (error) => requestError(error);

    rejectReassignSubmissions = (dataToSubmit) => {
      Put(
        `/admin/reassign_submission_requests/${dataToSubmit.id}/reject`,
        { to_admin_remark: dataToSubmit.remark },
        this.rejectReassignSubmissionsSuccess,
        this.rejectReassignSubmissionsError,
        this.load,
      );
    };
    rejectReassignSubmissionsSuccess = () => {
      this.getReassignSubmissions(this.state.currentPage);
      this.props.getPendingReassign();

      requestSuccess(`Reassign submission has been rejected successfully.`);
      this.setState({ showRejectModal: false });
    };
    rejectReassignSubmissionsError = (error) => requestError(error);

    resubmitReassignSubmissions = (id) =>
      Put(
        `/admin/reassign_submission_requests/${id}/resubmit`,
        "",
        this.resubmitReassignSubmissionsSuccess,
        this.resubmitReassignSubmissionsError,
        this.load,
      );
    resubmitReassignSubmissionsSuccess = () => {
      this.getReassignSubmissions(this.state.currentPage);
      this.props.getPendingReassign();

      requestSuccess(`Reassign submission has been resubmitted successfully.`);
      this.setState({ showResubmitModal: false });
    };
    resubmitReassignSubmissionsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            reassignPages={this.state.reassignPages}
            reassignSubmissions={this.state.reassignSubmissions}
            showApproveModal={this.state.showApproveModal}
            showRejectModal={this.state.showRejectModal}
            showResubmitModal={this.state.showResubmitModal}
            onLoadReassign={this.state.loading}
            selectedSubmissionId={this.state.selectedSubmissionId}
            getAdminRoles={this.getAdminRoles}
            getReassignSubmissions={this.getReassignSubmissions}
            onChangeReassignHOC={this.onChangeReassignHOC}
            approveReassignSubmissions={this.approveReassignSubmissions}
            rejectReassignSubmissions={this.rejectReassignSubmissions}
            resubmitReassignSubmissions={this.resubmitReassignSubmissions}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
