import { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";
import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const withEmailLists = (WrappedComponent) => {
  class EmailListsHOC extends Component {
    state = {
      onLoadEmailLists: false,
      emailListDialog: "",
      emailLists: [],
      emailListsPages: [],
      searchParams: [
        {
          label: "Email List Name",
          value: "name_cont",
          type: "input",
          param: "",
        },
      ],
      selectedEmailList: {},
      teamsList: [],
      countriesList: [],
      branchesList: [],
      selectedId: 0,
    };

    load = (param) => this.setState({ onLoadEmailLists: param });

    onChangeEmailListsHOC = (key, val) => this.setState({ [key]: val });

    getEmailLists = (page = 1, query = "") => {
      this.props.storeLastView({ page, query });

      Get(
        `/admin/email_lists?${query}page=${page}`,
        this.getEmailListsSuccess,
        this.getEmailListsError,
        this.load,
      );
    };
    getEmailListsSuccess = (data) => {
      const pages = [];

      for (let i = 0; i < data.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        emailLists: data,
        emailListsPages: pages,
      });
    };
    getEmailListsError = (error) => requestError(error);

    getSelectedEmailList = (id) =>
      Get(
        `/admin/email_lists/${id}`,
        this.getSelectedEmailListSuccess,
        this.getSelectedEmailListError,
        this.load,
      );
    getSelectedEmailListSuccess = (data) =>
      this.setState({ selectedEmailList: data });
    getSelectedEmailListError = (error) => requestError(error);

    getTeamsList = () =>
      Get(
        `/admin/email_lists/team_lists`,
        this.getTeamsListSuccess,
        this.getTeamsListError,
        this.load,
      );
    getTeamsListSuccess = (data) => {
      this.setState({
        teamsList: data.team.map((team) => ({
          id: team.id,
          name: team.display_name,
        })),
      });
    };
    getTeamsListError = (error) => requestError(error);

    getCountriesList = () =>
      Get(
        `/admin/email_lists/country_lists`,
        this.getCountriesListSuccess,
        this.getCountriesListError,
        this.load,
      );
    getCountriesListSuccess = (data) =>
      this.setState({ countriesList: data.countries });
    getCountriesListError = (error) => requestError(error);

    getBranchesList = () =>
      Get(
        `/admin/email_lists/branch_lists`,
        this.getBranchesListSuccess,
        this.getBranchesListError,
        this.load,
      );
    getBranchesListSuccess = (data) =>
      this.setState({ branchesList: data.branches });
    getBranchesListError = (error) => requestError(error);

    checkEmailList = (data) =>
      Post(
        `/admin/email_lists`,
        data,
        this.checkEmailListSuccess,
        this.checkEmailListError,
        this.load,
      );
    checkEmailListSuccess = (data) => {
      if (data.message === "successful") {
        requestSuccess(`You have queried ${data.sum_users} users!`);
      } else {
        requestError("Condition is invalid!");
      }
    };
    checkEmailListError = (error) => requestError(error);

    createEmailList = (data) =>
      Post(
        `/admin/email_lists`,
        data,
        this.createEmailListSuccess,
        this.createEmailListError,
        this.load,
      );
    createEmailListSuccess = () => {
      requestSuccess("Email list was created successfully!");
      this.setState({ emailListDialog: "" });
      this.getEmailLists();
    };
    createEmailListError = (error) => requestError(error);

    updateEmailList = (id, data) =>
      Put(
        `/admin/email_lists/${id}`,
        data,
        this.updateEmailListSuccess,
        this.updateEmailListError,
        this.load,
      );
    updateEmailListSuccess = () => {
      requestSuccess("Email list was updated successfully!");
      this.setState({ emailListDialog: "" });
      this.getEmailLists();
    };
    updateEmailListError = (error) => requestError(error);

    deleteEmailList = () =>
      Delete(
        `/admin/email_lists/${this.state.selectedId}`,
        this.deleteEmailListSuccess,
        this.deleteEmailListError,
        this.load,
      );
    deleteEmailListSuccess = () => {
      requestSuccess("Email list was deleted successfully!");
      this.setState({ emailListDialog: "" });
      this.getEmailLists();
    };
    deleteEmailListError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeEmailListsHOC={this.onChangeEmailListsHOC}
          getEmailLists={this.getEmailLists}
          getTeamsList={this.getTeamsList}
          getCountriesList={this.getCountriesList}
          getBranchesList={this.getBranchesList}
          getSelectedEmailList={this.getSelectedEmailList}
          checkEmailList={this.checkEmailList}
          createEmailList={this.createEmailList}
          updateEmailList={this.updateEmailList}
          deleteEmailList={this.deleteEmailList}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({ state });

  return connect(mapStateToProps, { storeLastView })(EmailListsHOC);
};

export default withEmailLists;
